import { ThemeProps } from 'styles';

import { buttonSharedStyles } from '../shared.styles';

export const commandBarButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    ...buttonSharedStyles(theme),
    bar: {
      height: 40,
      paddingBottom: 18.5,
      paddingTop: 18.5,
      '&:active': {
        color: colors.backgroundMain,
        backgroundColor: colors.secondary,
        '& .ms-Button-icon, & .ms-Button-menuIcon': {
          color: colors.backgroundMain,
        },
      },
    },
    rootExpanded: {
      ...buttonSharedStyles(theme).commandExpanded,
      borderRadius: 0,
      '& .ms-Button-icon': {
        color: colors.textLink,
      },
    },
  };
};
