import { PrimaryButton, StandardSearch, TextTitleSecondary } from 'components/ions';
import { ActionType } from 'components/ions/index';
import { ISearchBox } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { searchHeaderStyles } from './SearchHeader.styles';

/**
 * Props for the Search Header
 * @prop {string} title - Title for the Search Header
 * @prop {string} searchPlaceholder - placeholder text for the search input
 * @prop {sting} searchFocusPlaceholder - optional different placeholder text for the search input when focused
 * @prop {boolean} searchAutoFocus - whether the search input should be autoFocused
 * @prop {boolean} searchDisabled - whether the search input and button should be disabled
 * @prop {string} searchValue - value for the search input
 * @prop {boolean} searchError - indicate if there is an error to display for the search input
 * @prop {string} searchErrorMessage - error message to display when error
 * @prop {boolean} searchDebounce - defaults to true - indicates if user input into the search input should be debounced on onChange
 * @prop {string} buttonText - text for the search button
 * @prop {boolean} buttonDisabled - whether the search button should be disabled
 * @prop {function} onFocus - function to call when search input gains focus
 * @prop {function} onBlur - function to call when search input loses focus
 * @prop {function} onSearch - function to call when pressing 'enter' in search input or pressing search button
 * @prop {function} onChange - function to call when search input value changes
 * @prop {function} onClearSearch - function to call when 'x' button in search input is pressed
 */
export interface SearchHeaderProps {
  title: string;
  dataAutomationId?: string;
  searchComponentRef?: React.RefObject<ISearchBox>;
  searchPlaceholder: string;
  searchFocusPlaceholder?: string;
  searchAutoFocus?: boolean;
  searchDisabled?: boolean;
  searchValue?: string;
  searchError?: boolean;
  searchErrorMessage?: string;
  searchDebounce?: boolean;
  buttonText: string;
  buttonDisabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onSearch?: (input: string) => void;
  onChange?: (input: string) => void;
  onClearSearch?: (event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>) => void;
}

type Props = SearchHeaderProps & WithStyles<typeof searchHeaderStyles>;

export const SearchHeaderUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const [currentValue, setCurrentValue] = React.useState<string>('');

  const handleOnSearch = () => {
    if (currentValue) {
      props.onSearch && props.onSearch(currentValue);
    }
  };

  const handleOnChange = (input?: string) => {
    const newInput = input || '';
    setCurrentValue(newInput);
    props.onChange && props.onChange(newInput);
  };

  const handleOnClearSearch = () => {
    setCurrentValue('');
    props.onClearSearch && props.onClearSearch();
  };

  return (
    <div className={classes.searchHeader}>
      <TextTitleSecondary addClass={classes.title} dataAutomationId="searchHeaderTitle">
        {props.title}
      </TextTitleSecondary>
      <div className={classes.searchContainer}>
        <div className={classes.searchBox} data-automation-id="searchHeaderSearchBox">
          <StandardSearch
            autoFocus={props.searchAutoFocus}
            componentRef={props.searchComponentRef}
            debounce={false}
            disabled={props.searchDisabled}
            errorMessage={props.searchErrorMessage}
            focusPlaceholder={props.searchFocusPlaceholder}
            isError={props.searchError}
            placeholder={props.searchPlaceholder}
            value={props.searchValue}
            onBlur={props.onBlur}
            onChange={handleOnChange}
            onClear={handleOnClearSearch}
            onFocus={props.onFocus}
            onSearch={handleOnSearch}
          />
        </div>
        <span className={classes.spacer} />
        <div className={classes.searchButton} data-automation-id="searchHeaderSearchButton">
          <PrimaryButton
            dataAutomationId={props.dataAutomationId}
            disabled={props.buttonDisabled || props.searchDisabled}
            text={props.buttonText}
            onClick={handleOnSearch}
          />
        </div>
      </div>
    </div>
  );
};

export const SearchHeader = withStyles(searchHeaderStyles)(SearchHeaderUnstyled);
