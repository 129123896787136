import { MediumIcon, SecondaryButton, TextBody, TextBodySmall } from 'components';
import { Processing } from 'features/app/selectors';
import { SelectableTile } from 'features/components';
import { BodyProps } from 'features/proposal/components/Wizards/shared';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { OrganizationSummary } from 'services/customer/types';

import { getSelectableOrganizations } from '../shared';
import { parentAccountDialogStyles } from './ParentAccountDialog.styles';

export const ParentAccountHeaderString = () => i18next.t('quote::Parent account');

export interface ParentAccountBodyProps {
  eligibleParentAccounts?: OrganizationSummary[];
  processingAddresses: Processing;
  hasSharedDiscounts: boolean;
  leadOrgOnQuote?: string;
}

type Props = ParentAccountBodyProps &
  BodyProps<string | undefined> &
  WithStyles<typeof parentAccountDialogStyles>;

export const ParentAccountBodyUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const [selectedParentAccountId, setSelectedParentAccountId] = React.useState<string | undefined>(
    props.initialData || undefined
  );
  const { t } = useTranslation();

  const onSelectParentAccount = (organizationId: string) => {
    setSelectedParentAccountId(organizationId);
    props.onValid(organizationId);
  };

  const selectableOrganizations = props.eligibleParentAccounts
    ? getSelectableOrganizations(props.eligibleParentAccounts, props.processingAddresses)
    : false;

  const removeDiscountsMessage = () => {
    return (
      <div data-automation-id="parentAccountRemoveDiscountsMessage">
        <div className={classes.infoHeader}>
          <MediumIcon addClass={classes.infoIcon} iconName="Info" />
          <TextBody addClass={classes.infoHeaderText}>{t('quote::Please note')}</TextBody>
        </div>
        <div className={classes.infoBody}>
          <TextBodySmall addClass={classes.infoText}>
            {t(
              'quote::The shared discount(s) which you previously added to the quote are no longer applicable as they were negotiated by the previous parent, and will be removed from the quote.'
            )}
          </TextBodySmall>
          <div className={classes.infoSpacing}>
            <TextBodySmall addClass={classes.infoText}>
              {t(
                'quote::Once you save these changes, if the new parent has available discounts, you will be able to add them to the quote.'
              )}
            </TextBodySmall>
          </div>
        </div>
      </div>
    );
  };

  const notifyMessage = () => {
    return (
      <div data-automation-id="parentAccountNotifyMessage">
        <div className={classes.infoHeader}>
          <MediumIcon addClass={classes.infoIcon} iconName="Info" />
          <TextBody addClass={classes.infoHeaderText}>
            {t('quote::Parent account notification')}
          </TextBody>
        </div>
        <div className={classes.infoBody}>
          <TextBodySmall addClass={classes.infoText}>
            {t(
              'quote::When you publish the quote, the parent account will be notified that an affiliate account has been associated with them. They will be instructed to contact you if they have any questions.'
            )}
          </TextBodySmall>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.description}>
        <TextBody>
          {t(
            'quote::Below are the available parent accounts. Since the billing account you are creating is an affiliate, you must select a parent.'
          )}
        </TextBody>
      </div>
      {selectableOrganizations && (
        <div className={classes.body}>
          <div className={classes.options}>
            <SelectableTile
              addFlexContainerClassName={classes.flexContainer}
              dataAutomationId="selectOrganizationTile"
              label={t('quote::Eligible parent accounts')}
              loading={props.processingAddresses.loading}
              organizationsInfo={selectableOrganizations}
              selectedKey={selectedParentAccountId || undefined}
              onSelect={onSelectParentAccount}
            />
          </div>
          <div className={classes.notifications}>
            {props.hasSharedDiscounts &&
              selectedParentAccountId !== props.leadOrgOnQuote &&
              removeDiscountsMessage()}
            {notifyMessage()}
          </div>
        </div>
      )}
    </>
  );
};

export const ParentAccountBody = withStyles(parentAccountDialogStyles)(ParentAccountBodyUnstyled);

export const ParentAccountFooterButtons = (
  isValid: boolean,
  onOverviewButtonClick: () => void,
  onBackButtonClick: () => void
) => {
  return [
    <SecondaryButton
      dataAutomationId="parentAccountDialogBack"
      key="Back"
      text={i18next.t('quote::Back')}
      onClick={onBackButtonClick}
    />,
    <SecondaryButton
      dataAutomationId="parentAccountDialogBackToOverview"
      disabled={!isValid}
      key="overview"
      text={i18next.t('quote::Back to overview')}
      onClick={onOverviewButtonClick}
    />,
  ];
};
