import { ThemeProps, sharedStyles } from 'styles';

export const ApproverSelectorStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  errorMessage: {
    width: 506,
  },
  selectorContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: 24,
    maxHeight: 61,
  },
  selectorLevel: {
    paddingLeft: 24,
    paddingTop: 35,
  },
  approversContainer: {
    marginBottom: 24,
  },
  label: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  textTertiary: { color: theme.palette.textTertiary },
  autopopulatesSelectorLevel: {
    paddingLeft: 24,
    paddingTop: 6,
  },
  autopopulateContainer: {
    width: '100%',
    paddingBottom: 12,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '& .ms-TextField': {
      width: 294,
    },
  },
  autopopulateLabelContainer: {
    width: '100%',
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '& .ms-TextField': {
      width: 294,
    },
  },
  chevron: {
    minWidth: 14,
    width: 14,
    height: 14,
    padding: 10,
    '& i': {
      fontSize: theme.fonts.fontSizes.medium,
    },
  },
  showmoreLabel: {
    paddingLeft: 2,
    width: 272,
  },
  showmoreLabelLevel: {
    paddingLeft: 24,
  },
});
