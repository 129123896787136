import { Autosuggest, LinkButton, Persona, Suggestion, TextBody } from 'components';
import { PersonaAtomProps } from 'components/atoms';
import { ContactSuggestion, ContactSuggestionRow } from 'components/molecules/Autosuggest';
import { User, QuoteStatus, UserGroup } from 'generated/graphql';
import { Icon, PersonaSize } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { SEARCH_USER, UPDATE_MS_CONTACT, GET_QUOTE_GENERAL_INFO } from './queries';

import { GeneralStyles } from './General.styles';

export interface MsContactProps {
  msContact?: User | null;
  status: QuoteStatus;
  isQuoteReadOnly: boolean;
  assignedTo: UserGroup;
  etag: string;
  quoteId: string;
}

// #region MSContactPersona component
const msContactPersonaStyles = (theme: ThemeProps) => ({
  primaryText: { color: theme.palette.textTertiary, fontStyle: 'italic' },
});

type MSContactPersonaProps = { msContactName?: string } & WithStyles<typeof msContactPersonaStyles>;

const renderContactIcon = () => <Icon iconName="Contact" />;

const MSContactPersonaUnstyled: React.FC<MSContactPersonaProps> = props => {
  const { t } = useTranslation();
  const personaProps: PersonaAtomProps = {
    id: 'ms-contact',
    name: props.msContactName,
    size: PersonaSize.size32,
  };

  if (!props.msContactName) {
    personaProps.name = t('quote::no contact');
    personaProps.styles = { primaryText: props.classes.primaryText };
    personaProps.onRenderInitials = renderContactIcon;
  }

  return <Persona {...personaProps} />;
};

const MSContactPersona = withStyles(msContactPersonaStyles)(MSContactPersonaUnstyled);

type Props = MsContactProps & WithStyles<typeof GeneralStyles>;

const MsContactUnStyled: React.FC<Props> = props => {
  const { classes, msContact, isQuoteReadOnly, status, assignedTo, etag, quoteId } = props;
  const { t } = useTranslation();

  const [displaySearch, setDisplaySearch] = React.useState<boolean>(false);
  const [msContactSelected, setMSContactSelected] = React.useState<string>('');
  const [showProgressBar, setShowProgressBar] = React.useState<boolean>(false);
  const [queryString, setQueryString] = React.useState<string>('');

  const { data, loading: searchUserLoading, error } = useQuery(SEARCH_USER, {
    variables: { text: queryString },
    skip: !queryString.trim(),
  });

  const [updateMsContactGQL] = useMutation(UPDATE_MS_CONTACT, {
    refetchQueries: () => [{ query: GET_QUOTE_GENERAL_INFO, variables: { id: quoteId } }],
  });

  const contactSuggestions: User[] = data && data.searchUser;

  if (
    displaySearch &&
    ((msContactSelected !== '' && msContactSelected === oc(msContact).userPrincipalName('')) ||
      error)
  ) {
    setDisplaySearch(false);
    setMSContactSelected('');
    setShowProgressBar(false);
  }

  const msContactLabel = (
    <TextBody addClass={classes.emphasisText}>{t('quote::Microsoft contact')}</TextBody>
  );
  const msContactsResults: Suggestion<ContactSuggestion>[] =
    contactSuggestions &&
    contactSuggestions.map(contact => {
      const displayName = contact.displayName || '';
      const userPrincipalName = contact.userPrincipalName || '';
      const contactSuggestion: ContactSuggestion = {
        text: displayName,
        secondaryText: contact.mail,
      };
      const ariaLabel = contact.mail
        ? `${t('quote::Name:')} ${displayName}, ${t('quote::email:')} ${contact.mail}`
        : `${t('quote::Name:')} ${displayName}`;
      return {
        key: userPrincipalName || '',
        value: contactSuggestion,
        ariaLabel,
      };
    });

  const handleUpdateContact = (contact: Suggestion<ContactSuggestion>) => {
    updateMsContactGQL({
      variables: {
        input: {
          id: quoteId,
          etag,
        },
        msContact: contact.key,
      },
    });
    setMSContactSelected(contact.key);
    setShowProgressBar(true);
  };

  const handleSearchContacts = (query: string) => {
    setQueryString(query);
  };

  const onRenderContactsRow = (suggestion: Suggestion<ContactSuggestion>) => {
    return <ContactSuggestionRow suggestion={suggestion} />;
  };

  const cancelSearchLink = (
    <LinkButton
      addClass={classes.cancelSearchLink}
      displayText={t('quote::cancel')}
      onClick={() => {
        setDisplaySearch(false);
      }}
    />
  );

  const msContactSearch: JSX.Element = (
    <div className={classes.msContactSearchSection}>
      <Autosuggest
        addClass={classes.msContactSearch}
        autoFocus
        dataAutomationId="contactSearch"
        icon={{ iconName: 'Search' }}
        isLoading={searchUserLoading}
        placeholder={t('quote::Search')}
        showProgressBar={showProgressBar}
        strings={{
          loading: t('quote::Loading'),
          notFound: t('quote::Cannot find Microsoft contact'),
          listAriaLabel: t('quote::Search Results'),
        }}
        suggestions={msContactsResults || []}
        onRenderRow={onRenderContactsRow}
        onSearch={handleSearchContacts}
        onSelect={handleUpdateContact}
      />
      {displaySearch && cancelSearchLink}
    </div>
  );

  const viewMSContactSearch: JSX.Element = (
    <div data-automation-id="viewMSContactSearch">
      {msContactLabel}
      {msContactSearch}
    </div>
  );

  const viewMSContact: JSX.Element = (
    <div className={classes.msContact} data-automation-id="viewMSContact">
      {msContactLabel}
      <div>
        <MSContactPersona msContactName={oc(msContact).displayName('')} />
        {(!isQuoteReadOnly ||
          (status === QuoteStatus.Draft && assignedTo !== UserGroup.Customer)) && (
          <LinkButton
            addClass={classes.link}
            dataAutomationId="generalChangeContact"
            displayText={t('quote::change contact')}
            onClick={() => {
              setDisplaySearch(true);
            }}
          />
        )}
      </div>
    </div>
  );

  const msContactSectionView: JSX.Element = displaySearch ? viewMSContactSearch : viewMSContact;
  return msContactSectionView;
};

export const MsContact = withStyles(GeneralStyles)(MsContactUnStyled) as React.FC<MsContactProps>;
