import { ThemeProps, sharedStyles } from 'styles';

export const newProposalDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  closeButtonNoTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '23px 15px 3px 23px',
  },
  errorFooter: {
    ...sharedStyles(theme).customDialogFooter,
    justifyContent: 'center',
  },
  footer: {
    ...sharedStyles(theme).customDialogFooter,
    justifyContent: 'flex-end',
  },
  iconContainer: {
    ...sharedStyles(theme).customDialogIconContainer,
    marginBottom: 15,
  },
  text: {
    fontSize: 16,
  },
});
