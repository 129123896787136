import { TextBodySmall, TextTitle } from 'components';
import { mergeClassNames } from 'components/utilities';
import { getValueOrUndefined, isDemoTenant } from 'features-apollo/quote/components/utils';
import { TenantScenario } from 'features-apollo/quote/components/Wizards';
import { OrganizationSimple } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { organizationInformationStyles } from '../OrganizationInformation.styles';
import { CustomerCommandMenu, CustomLabels, MenuOptions } from './CustomerCommandMenu';

export interface OrganizationInformationProps {
  companyName?: string | null;
  organizationEditable?: boolean;
  isQuoteLegacy?: boolean;
  leadOrganizationName?: string;
  organization: OrganizationSimple;
  readOnly?: boolean;
  menuButtonAriaLabel?: string;
  menuOptions?: MenuOptions;
  /**
   * Customize the button label per menu option
   */
  customLabels?: CustomLabels;
  /**
   * Defines a custom string per scenario.
   */
  specificScenario?: TenantScenario;
  onRemove?: () => void;
}

type Props = OrganizationInformationProps & WithStyles<typeof organizationInformationStyles>;

const OrganizationInformationHeaderUnstyled: React.FC<Props> = props => {
  const { classes, isQuoteLegacy, organization } = props;
  const { t } = useTranslation();

  const organizationTradeName = organization.tradeName;
  const isDemoTenantId = organization.account.tenants.some(tenant => isDemoTenant(tenant.tenantId));

  const name = (
    <div className={classes.nameContainer}>
      <TextTitle
        addClass={mergeClassNames([classes.title, classes.companyName])}
        dataAutomationId="companyName"
        title={getValueOrUndefined(props.companyName)}
      >
        {props.companyName || t('quote::Unknown name')}
      </TextTitle>
      {!isQuoteLegacy && (
        <>
          <div className={classes.divider} />
          <CustomerCommandMenu
            addClass={classes.menuButton}
            customAriaLabel={props.menuButtonAriaLabel}
            customLabels={props.customLabels}
            disabled={props.readOnly || isQuoteLegacy}
            isDemoTenant={isDemoTenantId}
            menuOptions={props.menuOptions}
            organizationEditable={props.organizationEditable}
            specificScenario={props.specificScenario}
            onRemove={props.onRemove}
          />
        </>
      )}
    </div>
  );

  const tradeName = organizationTradeName && (
    <TextBodySmall
      addClass={classes.text}
      style={{ whiteSpace: 'nowrap' }}
      title={t('quote::Doing business as {{organizationTradeName}}', { organizationTradeName })}
    >{`dba ${organizationTradeName}`}</TextBodySmall>
  );

  const affiliate = !!props.leadOrganizationName && (
    <TextBodySmall
      addClass={classes.text}
      dataAutomationId="customerAffiliateMessage"
      title={props.leadOrganizationName}
    >
      {t('quote::Affiliate of {{parentAccountName}}', {
        parentAccountName: props.leadOrganizationName,
      })}
    </TextBodySmall>
  );

  const draftMessage = !props.specificScenario && !organization.agreementSigned && !isQuoteLegacy && (
    <TextBodySmall addClass={classes.text} dataAutomationId="customerDraftMessage">
      {t('quote::This billing account is in draft status until validated by the customer.')}
    </TextBodySmall>
  );

  return (
    <div className={classes.header}>
      {name}
      {tradeName}
      {affiliate}
      {draftMessage}
    </div>
  );
};

export const OrganizationInformationHeader = withStyles(organizationInformationStyles)(
  OrganizationInformationHeaderUnstyled
);
