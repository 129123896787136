import { ICalloutContentStyles } from 'office-ui-fabric-react';
import { ThemeProps } from 'styles';

export const calloutFabricStyles = (minWidth: number): Partial<ICalloutContentStyles> => {
  return {
    calloutMain: {
      minWidth,
    },
    root: {
      borderWidth: 0,
    },
  };
};

export const calloutCardStyles = (theme: ThemeProps) => {
  return {
    calloutPaddingStyles: {
      padding: '24px 16px',
      height: '100%',
    },
    footerStyles: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.backgroundCommon,
      height: 46,
    },
    buttonBarStyles: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    buttonSpacerStyles: {
      flexBasis: '10px',
    },
    closeButtonStyles: {
      position: 'relative',
      top: '-10px',
      left: '7px',
    },
    headerStyles: {
      marginTop: '-6px',
      marginBottom: 8,
    },
    subHeaderStyles: {
      marginTop: '-9px',
      '& div': {
        '& span': {
          color: `${theme.palette.textTertiary} !important`,
        },
      },
    },
    headerFlexContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    layer: {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
    },
    spinner: {
      paddingRight: 36,
    },
  };
};
