//@ts-nocheck
import { QuoteData } from 'features-apollo/quote/types';
import {
  AgreementType,
  Audience,
  Availability,
  BillingGroup,
  CatalogAction,
  Currency,
  GqlLanguage,
  Motion,
  OrganizationSimple,
  OrganizationType,
  ProductAudience,
  QuoteMutationInput,
  QuoteStatus,
  Sku,
  Term,
  TermBillingPlan,
  TermDuration,
  TransactionModel,
  UserGroup,
} from 'generated/graphql';

import { catalogContext } from './testUtils';

enum mockTermComponentType {
  termDuration = 'termDuration',
  billingPlan = 'billingPlan',
}

export const mockTermComponent: Record<mockTermComponentType, TermBillingPlan | TermDuration> = {
  termDuration: {
    __typename: 'TermDuration',
    description: 'duration description',
    duration: 'P1Y',
    title: 't1_component',
  },
  billingPlan: {
    __typename: 'TermBillingPlan',
    description: 'billing plan description',
    billingPeriod: 'P1Y',
    title: 'b1_component',
  },
};

enum mockTermType {
  generic = 'generic',
  withoutPrice = 'withoutPrice',
}

export const mockTerm: Record<mockTermType, Term> = {
  generic: {
    price: { amount: 10, currency: Currency.Usd, __typename: 'Price' },
    components: [mockTermComponent.termDuration],
    termId: 't1',
    description: '1 Month Subscription',
    units: 'test',
    __typename: 'Term',
    availabilityId: 'mock-ignore',
    catalogContext,
    productId: 'mock-ignore',
    skuId: 'mock-ignore',
  },
  withoutPrice: {
    price: null,
    components: [mockTermComponent.termDuration],
    termId: 't1',
    __typename: 'Term',
    description: '1 Month Subscription',
    units: 'test',
    availabilityId: 'mock-ignore',
    catalogContext,
    productId: 'mock-ignore',
    skuId: 'mock-ignore',
  },
};
enum mockAvailabilityType {
  generic = 'generic',
  withMeter = 'withMeter',
  withoutTermPrice = 'withoutTermPrice',
}

const mockedAvailability: Availability = {
  availabilityId: 'a1',
  terms: [mockTerm.generic],
  term: mockTerm.generic,
  price: { currency: Currency.Usd, amount: 10, __typename: 'Price' },
  isTrial: false,
  actions: [CatalogAction.Purchase],
  __typename: 'Availability',
  meterType: null,
  productId: 'genericProductIdFromMock',
  catalogContext,
  skuId: 'genericSkuIdFromMock',
  isAutoRenewable: false,
};

export const mockAvailability: Record<mockAvailabilityType, Availability> = {
  generic: {
    ...mockedAvailability,
    availabilityId: 'a1',
    terms: [mockTerm.generic],
    price: { currency: Currency.Usd, amount: 10, __typename: 'Price' },
    isTrial: false,
    __typename: 'Availability',
    meterType: null,
    productId: 'genericProductIdFromMock',
    catalogContext,
    skuId: 'genericSkuIdFromMock',
  },
  withoutTermPrice: {
    ...mockedAvailability,
    availabilityId: 'a1',
    isTrial: false,
    terms: [mockTerm.withoutPrice],
    meterType: null,
    price: null,
    catalogContext,
    __typename: 'Availability',
    productId: 'genericProductIdFromMock',
    skuId: 'genericSkuIdFromMock',
  },
  withMeter: {
    ...mockedAvailability,
    availabilityId: 'a2',
    isTrial: false,
    meterType: 'm1',
    price: null,
    __typename: 'Availability',
    productId: 'genericProductIdFromMock',
    skuId: 'genericSkuIdFromMock',
    catalogContext,
    terms: [mockTerm.generic],
  },
};

export const mockedSku: Sku = {
  title: 'Large',
  skuId: '001',
  productId: 'mockedProductId',
  catalogContext,
  __typename: 'Sku',
  isPrivate: false,
  availability: null,
  description: null,
  duration: null,
  filters: null,
  quantity: null,
  availabilities: [
    {
      ...mockAvailability.generic,
      isTrial: true,

      terms: [
        {
          ...mockTerm.generic,
          units: 'P1M',
          termId: 't1',
          description: '1 Month Trial to 1 Month Subscription',
          price: { amount: 0, currency: Currency.Usd, __typename: 'Price' },
        },
        {
          ...mockTerm.generic,
          termId: 't2',
          units: 'P1M',
          description: '1 Month Trial to 1 Year Subscription',
          price: { amount: 0, currency: Currency.Usd, __typename: 'Price' },
        },
      ],
    },
    {
      ...mockAvailability.generic,
      isTrial: true,

      terms: [
        {
          ...mockTerm.generic,
          units: 'P1M',
          termId: 't3',
          description: '1 Month Subscription',
          price: { amount: 0, currency: Currency.Usd, __typename: 'Price' },
        },
        {
          ...mockTerm.generic,
          termId: 't4',
          units: 'P1Y',
          description: '1 Year Subscription',
          price: { amount: 0, currency: Currency.Usd, __typename: 'Price' },
        },
      ],
    },
  ],
};

export const mockQuote: QuoteMutationInput = { etag: 'test_etag', id: 'test_quote_id' };
export const mockLineItemId = 'test_line_item_id';
const mockedBillingGroupProfile: BillingGroup = {
  accountId: '123456',
  billTo: { companyName: 'name', country: 'US' },
  currency: Currency.Usd,
  id: '987456',
  purchaseOrderNumber: '654123',
  purchaseContext: 'direct',
  organizationId: '',
  roleAssignments: [],
};

const mockedOrganizationSimple: OrganizationSimple = {
  id: '123',
  account: {
    description: null,
    primaryTenantId: '',
    externalIds: ['123'],
    id: 'test_id',
    tenants: [{ tenantId: '123', tenantName: null, __typename: 'AccountTenant' }],
    assets: [
      {
        productId: '123',
        subscriptionId: '123',
        __typename: 'Asset',
        id: '123',
        quantity: 1,
        state: '123',
        attributes: [],
        accountId: '123',
        beneficiaryOrganization: { id: '123', accountId: '123' },
      },
    ],
    cloudScope: null,
    purchaseMotion: null,
    __typename: 'Account',
  },
  accountId: '123',
  address: {
    country: 'US',
    companyName: null,
    addressLastLine: null,
    addressLine1: null,
    addressLine2: null,
    addressLine3: null,
    city: null,
    email: null,
    name: null,
    phoneNumber: null,
    postalCode: null,
    region: null,
    marketInfo: null,
    __typename: 'Address',
  },
  assets: [
    {
      productId: '123',
      subscriptionId: '123',
      __typename: 'Asset',
      id: '123',
      quantity: 1,
      state: '123',
      attributes: [],
      accountId: '123',
      beneficiaryOrganization: { id: '123', accountId: '123' },
    },
  ],
  name: '',
  type: OrganizationType.Individual,
  version: 1,
  agreementSigned: null,
  audience: null,
  credit: null,
  language: null,
  languageInfo: null,
  lastInvoiceDate: null,
  tradeName: null,
  vatId: null,
  customerTag: null,
  billingGroups: { edges: [{ node: mockedBillingGroupProfile }] },
  __typename: 'OrganizationSimple',
};

export const completeMockQuote: QuoteData = {
  __typename: 'Quote',
  crmLead: null,
  modifiedApiVersion: 'test',
  languageInfo: {
    __typename: 'LanguageInfo',
    gqlCode: GqlLanguage.EnUs,
    code: 'US',
    display: '',
    twoLetterCode: 'US',
  },
  agreementType: AgreementType.Modern,
  agreements: { edges: [], pageInfo: null, __typename: 'ModernAgreementActualConnection' },
  assignedTo: UserGroup.Field,
  audience: Audience.Commercial,
  billingCurrency: 'USD',
  canShareLeadDiscounts: true,
  clouds: [],
  etag: '123',
  fgoe: false,
  transactOnBehalf: false,
  id: '123',
  lineItems: [],
  market: 'US',
  messages: [],
  motion: Motion.Direct,
  name: 'abc',
  productAudience: ProductAudience.DirectCommercial,
  readOnly: false,
  recommendations: [],
  revision: 1,
  approval: null,
  soldTo: {
    quoteId: '123',
    organization: mockedOrganizationSimple,
    __typename: 'SoldToSimple',
    dealEstimate: 1000,
    monthlyCreditLimit: 1500,
  },
  endCustomer: mockedOrganizationSimple,
  status: QuoteStatus.Active,
  totalPrice: 12345,
  transactionModel: TransactionModel.ToCustomer,
  version: '1',
  continuationToken: null,
  endCustomerInvitedUser: null,
  expirationDate: null,
  invitedUser: null,
  languages: null,
  leadOrganization: null,
  modifiedDate: null,
  msContact: null,
  soldToInvitedUser: null,
  vlAgreement: null,
  vlAgreementNumber: null,
  exchangeRateDate: null,
};
