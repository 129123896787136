import { TokenAuthorities } from 'services/utils';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface UserPreferencesConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Int]: 'https://uiconfigurations.int.l2o.microsoft.com/v1/configurations',
  [Environment.Prod]: 'https://uiconfigurations.l2o.microsoft.com/v1/configurations',
};

export function getTokenAuthority(environment: Environment) {
  return environment === Environment.Prod
    ? TokenAuthorities.UserPreferenceProd
    : TokenAuthorities.UserPreferenceInt;
}
