import { ThemeProps } from 'styles';

import { AnimationStyles } from '@uifabric/styling';

export const salesStyles = (theme: ThemeProps) => {
  const enrollmentNumberFieldLabel = {
    display: 'unset',
  };

  return {
    contactEmailVerifyMessageStyle: {
      paddingTop: '32px',
      paddingLeft: '12px',
    },
    crmIdRemove: {
      paddingTop: 34.55,
    },
    field: {
      width: 356,
    },
    crmIdLinkAnimate: {
      paddingTop: 4,
      ...AnimationStyles.slideDownIn20,
      animationDelay: '100ms',
    },
    crmIdLink: {
      paddingTop: 4,
    },
    errorIcon: {
      color: theme.palette.dangerActive,
      paddingRight: 4,
    },
    messageSpacing: {
      lineHeight: '1.5',
      letterSpacing: '.25px',
    },
    newline: {
      display: 'block',
    },
    shimmer: {
      marginBottom: 8,
    },
    shimmerContainer: {
      paddingTop: 8,
      marginBottom: 14,
    },
    shimmerSection: {
      marginBottom: 24,
    },
    enrollmentNumberFieldLabelContainer: {
      padding: '5px 0px',
      fontSize: theme.fonts.fontSizes.medium,
    },
    enrollmentNumberFieldLabel,
    enrollmentNumberFieldLabelDisabled: {
      ...enrollmentNumberFieldLabel,
      color: theme.palette.textDisabled,
    },
    enrollmentComboBoxRowStyle: {
      paddingTop: 6,
      paddingBottom: 6,
    },
    enrollmentExpirationDateStyle: {
      color: theme.palette.textTertiary,
    },
    enterEnrollmentNumberStyle: {
      color: theme.palette.textLink,
    },
    infoButton: {
      height: 16,
    },
    spinnerStyle: {
      paddingTop: 28,
      paddingLeft: 10,
    },
    verifiedText: {
      paddingTop: 34,
      paddingLeft: 13,
      width: 'max-content',
    },
  };
};
