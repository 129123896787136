import { sharedStyles as dialogSharedStyles } from 'features/proposal/components/Dialogs/shared.styles';
import { sharedStyles, ThemeProps } from 'styles';

export const tenantWizardStyles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  ...sharedStyles,
  body: {
    overflowY: 'hidden',
  },
});
