import {
  Dropdown,
  ICalloutContentStyles,
  IDropdownStyleProps,
  IDropdownStyles,
  ResponsiveMode,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { DeepPartial } from 'redux';

import { DropdownAtomProps } from './dropdown.types';
import { dropdownAtomStyles } from './DropdownAtom.styles';

type Props = DropdownAtomProps & WithStyles<typeof dropdownAtomStyles>;

export const DropdownUnstyledAtom = (props: Props) => {
  const { classes } = props;

  const getStyles = (stylesProps: IDropdownStyleProps): DeepPartial<IDropdownStyles> => ({
    callout: classes.callout,
    dropdownItemSelected: classes.highlight,
    title: stylesProps.disabled
      ? classes.titleDisabled
      : stylesProps.isOpen
      ? classes.titleIsOpen
      : classes.title,
    dropdownItems: classes.dropdownItems,
    dropdownItem: classes.dropdownItem,
    dropdownDivider: classes.dropdownDivider,
    ...props.styles,
  });

  const calloutContentStyles: Partial<ICalloutContentStyles> = {
    calloutMain: classes.calloutMain,
  };

  return (
    <Dropdown
      {...props}
      calloutProps={{
        styles: calloutContentStyles,
        calloutMaxHeight: props.maxHeight,
        ...props.calloutProps,
      }}
      responsiveMode={ResponsiveMode.unknown}
      styles={getStyles}
    />
  );
};

export const DropdownAtom = withStyles(dropdownAtomStyles)(DropdownUnstyledAtom);
