import { Environment as CwaEnvironment } from 'features/app/config/CwaConfig';
import { Environment as AccountExtensionsEnvironment } from 'services/account-extensions/config';
import { Environment as AccountEnvironment } from 'services/account/config';
import { Environment as AddressValidationEnvironment } from 'services/address-validation/config';
import { Environment as AgreementEnvironment } from 'services/agreement/config';
import { Environment as ApolloEnvironment } from 'services/apollo/config';
import { Environment as ApprovalEnvironment } from 'services/approval/config';
import { Environment as BD360Environment } from 'services/bd360/config';
import { Environment as BillingGroupEnvironment } from 'services/billinggroup/config';
import { Environment as CatalogEnvironment, FieldsTemplate } from 'services/catalog/config';
import { Environment as ComplianceControlEnvironment } from 'services/ccf/config';
import { Environment as ConstraintsEnvironment } from 'services/constraints/config';
import { Environment as CreditEnvironment } from 'services/credit/config';
import { Environment as CRMEnvironment } from 'services/crm/config';
import { Environment as CustomerEnvironment } from 'services/customer/config';
import { Environment as EdgeSearchEnvironment } from 'services/edge-search/config';
import { Environment as EdgeEnvironment } from 'services/edge/config';
import { Environment as EmpowermentEnvironment } from 'services/empowerment/config';
import { Environment as ExternalUserEnvironment } from 'services/externaluser/config';
import { DefaultFlightMode, Environment as FlightsEnvironment } from 'services/flights/config';
import { Flight } from 'services/flights/flightList';
import { Environment as LDSSEnvironment } from 'services/ldss/config';
import * as LocalStorage from 'services/local-storage-service';
import loggerService from 'services/logger-service';
import { Environment as NotificationEnvironment } from 'services/notification/config';
import { Environment as PricingScopeEnvironment } from 'services/pricingscope/config';
import { Environment as ProjectEnvironment } from 'services/project/config';
import { Environment as ProposalEnvironment } from 'services/proposal/config';
import { Environment as PurchaseEnvironment } from 'services/purchase/config';
import { Environment as SignUpEnvironment } from 'services/sign-up/config';
import { isTestHeaderConfig } from 'services/types';
import { Environment as UserPreferencesEnvironment } from 'services/user-preferences/config';
import { AadEnvironment } from 'services/utils';

import { AppConfig, AppEnvironment } from './type';

let controlTowerFlights = LocalStorage.get<Flight[]>('localflights');
const useTestHeaderInStable =
  controlTowerFlights && controlTowerFlights.includes(Flight.noTestHeaderTesting) ? false : true;

export const localConfig: AppConfig = {
  aad: AadEnvironment.Int,
  account: { environment: AccountEnvironment.Prod, useTestHeader: true },
  accountExtensions: { environment: AccountExtensionsEnvironment.Prod, useTestHeader: true },
  addressValidation: { environment: AddressValidationEnvironment.Prod },
  agreement: { environment: AgreementEnvironment.PreProd, useTestHeader: true },
  app: AppEnvironment.Local,
  appInsightsKey: '14f5e1a2-3b1b-4678-85e2-82b2862a0767',
  approval: { environment: ApprovalEnvironment.Prod, useTestHeader: true },
  bd360: { environment: BD360Environment.Int },
  billinggroup: { environment: BillingGroupEnvironment.Prod, useTestHeader: true },
  businessStore: ProposalEnvironment.Int,
  catalog: { environment: CatalogEnvironment.Prod, actionFilter: FieldsTemplate.Details },
  ccf: { environment: ComplianceControlEnvironment.Prod },
  constraints: { environment: ConstraintsEnvironment.Prod, useTestHeader: true },
  credit: { environment: CreditEnvironment.Prod },
  crm: { environment: CRMEnvironment.Prod },
  customer: { environment: CustomerEnvironment.Prod, useTestHeader: true },
  cwa: { environment: CwaEnvironment.Prod },
  edge: { environment: EdgeEnvironment.Prod, useTestHeader: true },
  edgeSearch: { environment: EdgeSearchEnvironment.Prod, useTestHeader: true },
  empowerment: { environment: EmpowermentEnvironment.Prod, useTestHeader: true },
  externaluser: { environment: ExternalUserEnvironment.Prod },
  flights: {
    environment: FlightsEnvironment.Prod,
    defaultFlightMode: DefaultFlightMode.Prod,
  },
  ldss: { environment: LDSSEnvironment.Prod, useTestHeader: true },
  notifications: { environment: NotificationEnvironment.Prod },
  permissions: {
    oneOf: ['View', 'Update'],
  },
  pricingscope: { environment: PricingScopeEnvironment.Prod, useTestHeader: true },
  project: { environment: ProjectEnvironment.Prod, useTestHeader: true },
  proposal: { environment: ProposalEnvironment.Prod, useTestHeader: true },
  purchase: { environment: PurchaseEnvironment.Prod, useTestHeader: true },
  signUp: { environment: SignUpEnvironment.Prod },
  translation: { pseudoLoc: true },
  userPreferences: { environment: UserPreferencesEnvironment.Prod },
  apollo: {
    environment: ApolloEnvironment.Int,
    useTestHeader: true,
    useEnvironmentOverrides: false,
  },
};

export const latestConfig: AppConfig = {
  ...localConfig,
  app: AppEnvironment.Latest,
  appInsightsKey: 'a06ba5a6-407f-450c-b6e2-3b9639d88595',
  apollo: {
    environment: ApolloEnvironment.Int,
    useTestHeader: true,
    useEnvironmentOverrides: false,
  },
  businessStore: ProposalEnvironment.Int,
  flights: {
    environment: FlightsEnvironment.Prod,
    defaultFlightMode: DefaultFlightMode.Latest,
  },
  translation: { pseudoLoc: false },
};

export const stableConfig: AppConfig = {
  ...localConfig,
  apollo: {
    environment: ApolloEnvironment.Insider,
    useTestHeader: useTestHeaderInStable,
    useEnvironmentOverrides: false,
  },
  app: AppEnvironment.Stable,
  appInsightsKey: 'b4736731-9c06-4782-8d03-5ced3de0ac89',
  businessStore: ProposalEnvironment.Int,
  flights: {
    environment: FlightsEnvironment.Prod,
    defaultFlightMode: DefaultFlightMode.Stable,
  },
  translation: { pseudoLoc: false },
  // test header overrides
  account: {
    ...localConfig.account,
    useTestHeader: useTestHeaderInStable,
  },
  accountExtensions: {
    ...localConfig.accountExtensions,
    useTestHeader: useTestHeaderInStable,
  },
  agreement: {
    ...localConfig.agreement,
    useTestHeader: useTestHeaderInStable,
  },
  approval: {
    ...localConfig.approval,
    useTestHeader: useTestHeaderInStable,
  },
  // BD360 does not use Test Headers, so we need to call their Int any time we are using Test Headers to insure LEs do not get assigned to work on test Quotes
  bd360: {
    environment: useTestHeaderInStable ? BD360Environment.Int : BD360Environment.Prod,
  },
  billinggroup: {
    ...localConfig.billinggroup,
    useTestHeader: useTestHeaderInStable,
  },
  constraints: {
    ...localConfig.constraints,
    useTestHeader: useTestHeaderInStable,
  },
  customer: {
    ...localConfig.customer,
    useTestHeader: useTestHeaderInStable,
  },
  edge: {
    ...localConfig.edge,
    useTestHeader: useTestHeaderInStable,
  },
  edgeSearch: {
    ...localConfig.edgeSearch,
    useTestHeader: useTestHeaderInStable,
  },
  empowerment: {
    ...localConfig.empowerment,
    useTestHeader: useTestHeaderInStable,
  },

  ldss: {
    ...localConfig.ldss,
    useTestHeader: useTestHeaderInStable,
  },
  pricingscope: {
    ...localConfig.pricingscope,
    useTestHeader: useTestHeaderInStable,
  },
  project: {
    ...localConfig.project,
    useTestHeader: useTestHeaderInStable,
  },
  proposal: {
    ...localConfig.proposal,
    useTestHeader: useTestHeaderInStable,
  },
  purchase: {
    ...localConfig.purchase,
    environment: PurchaseEnvironment.Prod,
    useTestHeader: useTestHeaderInStable,
  },
};

/**
 * point to pre-prod environment of l2o services
 * todo: leaving ldss as int environment does not work
 */
export const testConfig: AppConfig = {
  ...localConfig,
  apollo: {
    environment: ApolloEnvironment.Int,
    useTestHeader: true, // should be true because we point other prod services
    useEnvironmentOverrides: true,
  },
  approval: { environment: ApprovalEnvironment.Int, useTestHeader: true },
  agreement: { environment: AgreementEnvironment.PreProd, useTestHeader: true },
  empowerment: { environment: EmpowermentEnvironment.Int, useTestHeader: true },
  proposal: { environment: ProposalEnvironment.Int, useTestHeader: true },
  app: AppEnvironment.Test,
  appInsightsKey: '28edcc35-1bca-4fb5-bcfe-d572f5bbc3e3',
  businessStore: ProposalEnvironment.Int,
  translation: { pseudoLoc: false },
};

export const prodConfig: AppConfig = {
  aad: AadEnvironment.Prod,
  account: { environment: AccountEnvironment.Prod, useTestHeader: false },
  accountExtensions: { environment: AccountExtensionsEnvironment.Prod, useTestHeader: false },
  addressValidation: { environment: AddressValidationEnvironment.Prod },
  agreement: { environment: AgreementEnvironment.Prod, useTestHeader: false },
  app: AppEnvironment.Prod,
  appInsightsKey: '8c561b70-ad3b-47ae-9538-8eefef36966c',
  approval: { environment: ApprovalEnvironment.Prod, useTestHeader: false },
  bd360: { environment: BD360Environment.Prod },
  billinggroup: { environment: BillingGroupEnvironment.Prod, useTestHeader: false },
  businessStore: ProposalEnvironment.Prod,
  catalog: { environment: CatalogEnvironment.Prod, actionFilter: FieldsTemplate.Details },
  ccf: { environment: ComplianceControlEnvironment.Prod },
  constraints: { environment: ConstraintsEnvironment.Prod, useTestHeader: false },
  credit: { environment: CreditEnvironment.Prod },
  crm: { environment: CRMEnvironment.Prod },
  customer: { environment: CustomerEnvironment.Prod, useTestHeader: false },
  cwa: { environment: CwaEnvironment.Prod },
  edge: { environment: EdgeEnvironment.Prod, useTestHeader: false },
  edgeSearch: { environment: EdgeSearchEnvironment.Prod, useTestHeader: false },
  empowerment: { environment: EmpowermentEnvironment.Prod, useTestHeader: false },
  externaluser: { environment: ExternalUserEnvironment.Prod },
  flights: {
    environment: FlightsEnvironment.Prod,
    defaultFlightMode: DefaultFlightMode.Prod,
  },
  ldss: { environment: LDSSEnvironment.Prod, useTestHeader: false },
  notifications: { environment: NotificationEnvironment.Prod },
  permissions: {
    oneOf: ['View', 'Update'],
  },
  pricingscope: { environment: PricingScopeEnvironment.Prod, useTestHeader: false },
  project: { environment: ProjectEnvironment.Prod, useTestHeader: false },
  proposal: { environment: ProposalEnvironment.Prod, useTestHeader: false },
  purchase: { environment: PurchaseEnvironment.Prod, useTestHeader: false },
  signUp: { environment: SignUpEnvironment.Prod },
  translation: { pseudoLoc: false },
  userPreferences: { environment: UserPreferencesEnvironment.Prod },
  apollo: {
    environment: ApolloEnvironment.Prod,
    useTestHeader: false,
    useEnvironmentOverrides: false,
  },
};

export const pilotConfig: AppConfig = {
  ...prodConfig,
  aad: AadEnvironment.Pilot,
  appInsightsKey: '77d4c3d5-a021-4ccd-bda9-f43db932000a',
};

export const demoConfig: AppConfig = {
  ...stableConfig,
  aad: AadEnvironment.Prod,
  app: AppEnvironment.Prod,
  appInsightsKey: '8c561b70-ad3b-47ae-9538-8eefef36966c',
  crm: { environment: CRMEnvironment.Int },
  flights: {
    ...prodConfig.flights,
    defaultFlightMode: DefaultFlightMode.Demo,
  },
};

export const insiderConfig: AppConfig = {
  ...prodConfig,
  appInsightsKey: '77d4c3d5-a021-4ccd-bda9-f43db932000a',
  apollo: {
    environment: ApolloEnvironment.Insider,
    useTestHeader: false,
    useEnvironmentOverrides: false,
  },
  flights: {
    ...prodConfig.flights,
    defaultFlightMode: DefaultFlightMode.Insider,
  },
  permissions: {
    allOf: ['Insider'],
    oneOf: ['View', 'Update'],
  },
};

export const hasDangerousConfig = (appConfig: AppConfig): boolean => {
  type AppConfigKeys = keyof AppConfig;

  return Object.keys(appConfig).some(key => {
    const config = appConfig[key as AppConfigKeys];

    return isTestHeaderConfig(config) && !config.useTestHeader;
  });
};

export function getConfigByEnvironment(): AppConfig {
  switch (window.location.hostname) {
    case 'localhost':
      return { ...localConfig, translation: { pseudoLoc: false } };
    case 'local.c3.mepla.microsoft.com':
      return localConfig;
    case 'latest.cosmos.mepla.microsoft.com':
    case 'latest.c3.mepla.microsoft.com':
    case 'mepla-test.azure-api.net':
    case 'quotecenter-test.microsoft.com':
      return latestConfig;
    case 'stable.cosmos.mepla.microsoft.com':
    case 'stable.c3.mepla.microsoft.com':
      return stableConfig;
    case 'quotecenter-int.microsoft.com':
      return testConfig;
    case 'pilot.quotecenter.microsoft.com':
      return pilotConfig;
    case 'demo.quotecenter.microsoft.com':
      return demoConfig;
    case 'insider.quotecenter.microsoft.com':
      return insiderConfig;
    case 'quotecenter.microsoft.com':
    case 'quotecenter-ppe.microsoft.com':
    case 'prod.c3.mepla.microsoft.com':
      return prodConfig;
    default:
      const e = `Unknown environment:${window.location.hostname}`;
      const error = new Error(e);
      loggerService.error({ error });
      throw error;
  }
}

export const quoteCenterProdUrl = 'https://quotecenter.microsoft.com';
export const quoteCenterDemoUrl = 'https://demo.quotecenter.microsoft.com';
export const quoteCenterLatestUrl = 'https://latest.c3.mepla.microsoft.com';
