import cloneDeep from 'clone-deep';
import { getFlightIsEnabled } from 'features/app/selectors';
import { isQuantityOwnershipConstraintEnabledOnProduct } from 'features/catalog/selectors';
import { openConfigCard, updateProposalAsync } from 'features/proposal/actions';
import { ProductListLineItem } from 'features/proposal/components/List/ProductList/types';
import { quantityFormatter } from 'features/proposal/components/ProposalList';
import { ConnectedSeatsCardContainer as SeatsInput } from 'features/proposal/components/SeatsCard';
import { getActiveProposal, getProductLineItems } from 'features/proposal/selectors';
import { getOpenCard } from 'features/proposal/selectors/views';
import { CardType, OpenCard } from 'features/proposal/types';
import { isCardOpen } from 'features/proposal/utils';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { LineItem } from 'services/proposal/types';
import { RootState } from 'store/types';

import { productListQuantityStyles } from './ProductListQuantity.styles';

export const mapStateToProps = (state: RootState, ownprops: ProductListQuantityProps) => {
  return {
    openCard: getOpenCard(state),
    isQuantityOwnershipConstraintEnabledOnProduct: isQuantityOwnershipConstraintEnabledOnProduct(
      state,
      ownprops.item.productId
    ),
    proposal: getActiveProposal(state),
    lineItemsList: getProductLineItems(state),
    modernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
  };
};
const dispatchProps = {
  onProposalUpdate: updateProposalAsync.request,
  onClickShowCard: (openCard: OpenCard) => {
    return openConfigCard(openCard);
  },
};
export interface ProductListQuantityProps {
  item: ProductListLineItem;
  lineItemsList?: LineItem[];
}

type Props = ProductListQuantityProps &
  WithStyles<typeof productListQuantityStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

export const ProductListQuantityUnStyled: React.FC<Props> = props => {
  let quantityToDisplay = 0;
  if (props.item.quantity) {
    quantityToDisplay = props.item.quantity;
  }
  const seatsCardTarget = React.useRef<HTMLInputElement>(null);
  const configCardActive = isCardOpen(props.item.id, CardType.Quantity, props.openCard);

  const renderSeatsInputLink = () => {
    return (
      <div>
        {configCardActive && (
          <SeatsInput
            lineItemId={props.item.id}
            productId={props.item.productId}
            quantity={quantityToDisplay.toString()}
            target={seatsCardTarget}
          />
        )}
        <div className={props.classes.container}>
          <input
            className={props.classes.numberInput}
            disabled={false}
            readOnly={true}
            ref={seatsCardTarget}
            value={quantityToDisplay}
            width={100}
            onFocus={() => {
              props.onClickShowCard({
                lineItemId: props.item.id,
                type: CardType.Quantity,
              });
              seatsCardTarget && seatsCardTarget.current && seatsCardTarget.current.blur();
            }}
          />
        </div>
      </div>
    );
  };
  const { t } = useTranslation();

  const renderSeatsInputText = () => {
    let onUpdate;
    if (props.proposal) {
      onUpdate = (value: number): void => {
        const newProposal = cloneDeep(props.proposal);
        const targetLineItem = newProposal.lineItems.find(
          lineItem => lineItem.id === props.item.id
        );
        if (targetLineItem && targetLineItem.quantity !== value) {
          targetLineItem.quantity = value;
          props.onProposalUpdate({
            proposal: newProposal,
            proposalId: props.proposal.id,
            etag: props.proposal.etag,
          });
        }
      };
    }
    return quantityFormatter(
      50,
      props.item.quantity,
      onUpdate,
      props.item.minQuantity,
      props.item.maxQuantity,
      t('quote::Min Qty'),
      t('quote::Max Qty'),
      props.item.disableQuantityEdit,
      props.item.disableQuantityEdit
        ? t('quote::Configure product for quantity rules to apply')
        : undefined
    );
  };
  const renderSeatsInput = () => {
    if (props.isQuantityOwnershipConstraintEnabledOnProduct && props.modernOfficeEnabled) {
      return renderSeatsInputLink();
    }
    return renderSeatsInputText();
  };

  return renderSeatsInput();
};

export const ProductListQuantityUnconnected = withStyles(productListQuantityStyles)(
  ProductListQuantityUnStyled
) as React.FC<ProductListQuantityProps>;

export const ProductListQuantity = connect(
  mapStateToProps,
  dispatchProps
)(ProductListQuantityUnconnected);
