// Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license

// tslint:disable:max-line-length

import { IIconOptions, IIconSubset, registerIcons } from '@uifabric/styling';

export function initializeIcons(baseUrl: string = '', options?: IIconOptions): void {
  const subset: IIconSubset = {
    style: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontStyle: 'normal',
      fontWeight: 'normal',
      speak: 'none',
    },
    fontFace: {
      fontFamily: `"FabricMDL2Icons"`,
      src: `url('${baseUrl}fabric-icons-869c5c96.woff') format('woff')`,
    },
    icons: {
      TemporaryUser: '\uEE58',
      SortUp: '\uEE68',
      SortDown: '\uEE69',
      CompanyDirectory: '\uEF0D',
      ProfileSearch: '\uEF35',
      FabricMovetoFolder: '\uF0A5',
      FabricNewFolder: '\uF0AB',
      StatusCircleCheckmark: '\uF13E',
      StatusCircleQuestionMark: '\uF142',
      PublishContent: '\uF0D4',
      GroupedList: '\uEF74',
      RecurringEvent: '\uEF5D',
      DocumentManagement: '\uEFFC',
      SkypeCheck: '\uEF80',
      Shield: '\uEA18',
      ErrorBadge: '\uEA39',
      CircleRing: '\uEA3A',
      PeopleAdd: '\uEA15',
      BarChartVertical: '\uE9EC',
      Processing: '\uE9F5',
      HourGlass: '\uEA03',
      AddToShoppingList: '\uEA9A',
      CalculatorPercentage: '\uE94C',
      ChevronRightMed: '\uE974',
      Info: '\uE946',
      ClearNight: '\uE9C2',
      Sunny: '\uE9BD',
      DateTime: '\uEC92',
      Org: '\uECA6',
      OpenFolderHorizontal: '\uED25',
      AddTo: '\uECC8',
      Blocked2: '\uECE4',
      Running: '\uEADA',
      AllCurrency: '\uEAE4',
      StatusErrorFull: '\uEB90',
      Heart: '\uEB51',
      HeartFill: '\uEB52',
      GUID: '\uF52B',
      DrillDownSolid: '\uF533',
      AccountManagement: '\uF55C',
      DeleteRows: '\uF64F',
      NavigateBack: '\uF2DD',
      CircleAdditionSolid: '\uF2E4',
      DocumentApproval: '\uF28B',
      TeamsLogo: '\uF27B',
      SignOut: '\uF3B1',
      LikeSolid: '\uF3BF',
      DislikeSolid: '\uF3C0',
      TestBeaker: '\uF3A5',
      TestBeakerSolid: '\uF3A6',
      BusinessHoursSign: '\uF310',
      AsteriskSolid: '\uF34D',
      Share: '\uE72D',
      Back: '\uE72B',
      Remove: '\uE738',
      Forward: '\uE72A',
      More: '\uE712',
      Settings: '\uE713',
      Mail: '\uE715',
      Search: '\uE721',
      Send: '\uE724',
      CheckMark: '\uE73E',
      ChevronRight: '\uE76C',
      ContactInfo: '\uE779',
      Contact: '\uE77B',
      Cancel: '\uE711',
      KeyboardClassic: '\uE765',
      Up: '\uE74A',
      Down: '\uE74B',
      Delete: '\uE74D',
      Cloud: '\uE753',
      Sad: '\uE757',
      Add: '\uE710',
      ChevronUp: '\uE70E',
      HomeDropdown: '\uE427',
      Airplane: '\uE709',
      ChevronDown: '\uE70D',
      Edit: '\uE70F',
      Copy: '\uE8C8',
      Sort: '\uE8CB',
      Permissions: '\uE8D7',
      OpenInNewWindow: '\uE8A7',
      Rename: '\uE8AC',
      Dislike: '\uE8E0',
      Like: '\uE8E1',
      RadioBullet: '\uE915',
      Clock: '\uE917',
      AddFriend: '\uE8FA',
      Tag: '\uE8EC',
      Accept: '\uE8FB',
      Calendar: '\uE787',
      Warning: '\uE7BA',
      ShoppingCart: '\uE7BF',
      Undo: '\uE7A7',
      RevToggleKey: '\uE845',
      View: '\uE890',
      Clear: '\uE894',
      Help: '\uE897',
      LocationCircle: '\uE80E',
      Home: '\uE80F',
      History: '\uE81C',
      DependencyAdd: '\uE344',
      ProductList: '\uE31E',
      Attach: '\uE723',
      OpenFile: '\uE8E5',
      Refresh: '\uE72C',
      Download: '\ue896',
      UserRemove: '\uF69B',
      CloudUpload: '\uEC8E',
    },
  };

  registerIcons(subset, options);
}
