import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { ApprovalComments, ApprovalCommentsDetails } from './ApprovalComments';
import { RequiredApproval, RequiredApprovalsPerPolicy } from './RequiredApproval';

interface ApprovalDetailsStyles {
  /**
   * Classname for the DOM element wrapping the comments' section
   */
  commentContainer?: string;
}

interface ApprovalDetailsProps {
  /**
   * List of props used for the RequiredApproval component
   */
  requiredApprovals: RequiredApprovalsPerPolicy[];
  /**
   * Displays after required approval details
   */
  commentsDetails?: ApprovalCommentsDetails;
  /**
   * Collapse comments that are longer than five text lines
   */
  enableShowMore?: boolean;
  /**
   * Custom styles for elements in the DOM
   */
  styles?: ApprovalDetailsStyles;
}

const styles = (theme: ThemeProps) => ({
  policiesContainer: { '& > *': { marginTop: 16 } },
  title: { fontSize: theme.fonts.fontSizes.large, fontWeight: theme.fonts.fontWeights.semiBold },
});

type Props = ApprovalDetailsProps & WithStyles<typeof styles>;

const ApprovalDetailsUnstyled: React.FC<Props> = props => {
  const { classes, styles } = props;

  const policies = props.requiredApprovals.map(requiredApproval => (
    <RequiredApproval key={requiredApproval.policy} {...requiredApproval} />
  ));

  const comments = props.commentsDetails ? (
    <ApprovalComments {...props.commentsDetails} enableShowMore={props.enableShowMore} />
  ) : null;

  return (
    <div>
      <div className={classes.policiesContainer}>{policies}</div>
      <div className={oc(styles).commentContainer()}>{comments}</div>
    </div>
  );
};

export const ApprovalDetails = withStyles(styles)(ApprovalDetailsUnstyled);
