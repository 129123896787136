import { NavLinkInternal } from 'components/ions/Links';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { FontSizes } from 'styles';

export interface CwaLinkProps {
  tpid: string;
  fontSize?: keyof FontSizes;
  className?: string;
}

export const CwaLink = (props: CwaLinkProps) => {
  const { tpid, ...linkProps } = props;
  const { t } = useTranslation();

  return (
    <NavLinkInternal
      {...linkProps}
      dataAutomationId="view-customer-link"
      to={routes.customer.agreementsForId(tpid)}
    >
      {t('quote::view customer')}
    </NavLinkInternal>
  );
};
