import { CopyButton, TextTitleSecondary } from 'components/ions';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ShowMore } from '../ShowMore';

const styles = {
  innerRow: { display: 'flex', flexDirection: 'column' },
  marginTop10: { marginTop: 10 },
  marginTop5: { marginTop: 5 },
  spacing: { marginTop: 30, width: '100%' },
};

export interface CopyButtonBlockProps {
  copiedText: string;
  showMoreText: string;
  showLessText: string;
  items: string[];
  titleText: string;
  width: number;
}

type Props = CopyButtonBlockProps & WithStyles<typeof styles>;

const CopyButtonBlockUnstyled: React.FC<Props> = props => {
  const { classes, items, titleText, width } = props;
  const numberToShow = 3;
  if (items.length > numberToShow) {
    const preview = items.slice(0, numberToShow);
    const showMore = items.slice(numberToShow, items.length);

    const previewBlock = (
      <div className={classes.marginTop10}>
        {preview.map((id: string, key: number) => (
          <div key={key}>
            <CopyButton ariaLabel={id} copiedText={props.copiedText} text={id} textWidth={width} />
          </div>
        ))}
      </div>
    );
    const showMoreBlock = (
      <div>
        {showMore.map((id: string, key: number) => (
          <div key={key}>
            <CopyButton ariaLabel={id} copiedText={props.copiedText} text={id} textWidth={width} />
          </div>
        ))}
      </div>
    );
    return (
      <div className={classes.innerRow}>
        <div className={classes.spacing}>
          <TextTitleSecondary>{titleText}</TextTitleSecondary>
          <ShowMore
            changeLinkText={props.showLessText}
            id={titleText}
            initialLinkText={props.showMoreText}
            linkClass={classes.marginTop5}
            linkId="showMoreLink"
            moreMessage={showMoreBlock}
            previewMessage={previewBlock}
            textId="showMoreText"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.innerRow}>
      <div className={classes.spacing}>
        <TextTitleSecondary>{titleText}</TextTitleSecondary>
        <div className={classes.marginTop10}>
          {items.map((id: string, key: number) => (
            <div key={key}>
              <CopyButton
                ariaLabel={id}
                copiedText={props.copiedText}
                text={id}
                textWidth={width}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const CopyButtonBlock = withStyles(styles)(CopyButtonBlockUnstyled);
