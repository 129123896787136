import axios from 'axios';
import { serviceifyAddressOrganizationUpdate } from 'components/utilities/address';
import { PageRequest, PageResponse } from 'services/types';
import { getCV, TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader, getTestHeader, validatePageRequest } from '../utils';
import { CustomerConfig, endpoints } from './config';
import {
  AddTaxId,
  GetOrganizationRequest,
  OrganizationSummary,
  RecipientSummary,
  RemoveTaxId,
  RemoveTaxIdRequest,
  UpdateOrganizationAddress,
  UpdateOrganizationRequest,
  UpdateTradeName,
} from './types';

async function getHeaders(config: CustomerConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
    'ms-cv': getCV(),
    'api-version': '2019-05-31',
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function getOrganizations(
  request: PageRequest,
  config: CustomerConfig
): Promise<PageResponse<OrganizationSummary>> {
  validatePageRequest(request);
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = request.nextLink
    ? `${host}${request.nextLink}`
    : `${host}/accounts/${request.id}/organizations`;
  const response = await axios.get<PageResponse<OrganizationSummary>>(url, { headers });
  return response.data;
}

export async function getOrganization(
  request: GetOrganizationRequest,
  config: CustomerConfig
): Promise<OrganizationSummary> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const params = { includeAddress: true };
  const url = `${host}/accounts/${request.accountId}/organizations/${request.id}`;
  const response = await axios.get<OrganizationSummary>(url, { params, headers });
  return response.data;
}

export async function getRecipients(
  request: PageRequest,
  config: CustomerConfig
): Promise<PageResponse<RecipientSummary>> {
  validatePageRequest(request);
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = request.nextLink
    ? `${host}${request.nextLink}`
    : `${host}/accounts/${request.id}/recipients`;
  const response = await axios.get<PageResponse<RecipientSummary>>(url, { headers });
  return response.data;
}

export async function updateTradeName(
  updateRequest: UpdateOrganizationRequest<UpdateTradeName>,
  config: CustomerConfig
): Promise<OrganizationSummary> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/accounts/${updateRequest.accountId}/organizations/${updateRequest.id}/updatelegalentityinfo`;
  const response = await axios.post<OrganizationSummary>(url, updateRequest.request, { headers });
  return response.data;
}

export async function updateOrganizationAddress(
  updateRequest: UpdateOrganizationRequest<UpdateOrganizationAddress>,
  config: CustomerConfig
): Promise<OrganizationSummary> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/accounts/${updateRequest.accountId}/organizations/${updateRequest.id}/updatelegalentityaddress`;
  const serviceifiedRequest: UpdateOrganizationRequest<UpdateOrganizationAddress> = {
    ...updateRequest,
    request: {
      ...updateRequest.request,
      address: serviceifyAddressOrganizationUpdate(updateRequest.request.address),
    },
  };
  const response = await axios.post<OrganizationSummary>(url, serviceifiedRequest.request, {
    headers,
  });
  return response.data;
}

export async function addTaxId(
  add: AddTaxId,
  config: CustomerConfig
): Promise<OrganizationSummary> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/accounts/${add.accountId}/organizations/${add.organizationId}/addtaxid`;
  const request = {
    ...add.request,
    type: 'vat_id',
  };
  const response = await axios.post<OrganizationSummary>(url, request, { headers });
  return response.data;
}

export async function removeTaxId(
  remove: RemoveTaxId,
  config: CustomerConfig
): Promise<OrganizationSummary> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/accounts/${remove.accountId}/organizations/${remove.organizationId}/removetaxid`;
  const request: RemoveTaxIdRequest = remove.request;
  const response = await axios.post<OrganizationSummary>(url, request, { headers });
  return response.data;
}
