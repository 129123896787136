import { OrganizationFragment, TestHeaderFragment } from 'features/customer/fragments';
import gql from 'graphql-tag';

export const InvitedUserFragment = gql`
  fragment invitedUserFragment on InvitedUser {
    tid
    oid
  }
`;

export const BillToFragment = gql`
  fragment billToFragment on BillTo {
    accountId
    billingGroupId
    isChargeOnPurchase
    externalPoNumbers
    billOnPurchase
  }
`;

export const BusinessContactFragment = gql`
  fragment businessContactFragment on BusinessContact {
    id
    version
    roleType
  }
`;

export const customPriceFragment = gql`
  fragment customPriceFragment on CustomPrice {
    id
    version
  }
`;

export const PricingContextFragment = gql`
  fragment pricingContext on PricingContext {
    catalogId
    market
    languages
    billingCurrency
    exchangeRateDate
  }
`;

export const CrmLeadOldFragment = gql`
  fragment crmLeadOldFragment on CrmLeadOld {
    id
    contactId
    crmId
    entityName
    vlAgreementNumbers
    type
    salesAccountId
  }
`;

export const AccountTenantFragment = gql`
  fragment accountTenantFragment on AccountTenant {
    tenantId
    tenantName
  }
`;

export const AccountFragment = gql`
  fragment accountFragment on Account {
    id
    description
    purchaseMotion
    cloudScope
    primaryTenantId
    tenants {
      ...accountTenantFragment
    }
  }
  ${AccountTenantFragment}
`;

export const CreditFragment = gql`
  fragment creditFragment on Credit {
    currency
    decision
    reason
    creditLine
    remainingBalance
    creditRiskScore
  }
`;

export const QuoteCustomerFragment = gql`
  fragment quoteCustomerFragment on QuoteCustomer {
    account {
      ...accountFragment
    }
    organization {
      ...organizationFragment
    }
    credit {
      ...creditFragment
    }
  }
  ${AccountFragment}
  ${OrganizationFragment}
  ${CreditFragment}
`;

export const UserPreferencesFragment = gql`
  fragment userPreferencesFragment on UserPreferences {
    theme
    dialogsViewed
  }
`;

export const ExtendedPropertiesFragment = gql`
  fragment extendedPropertiesFramgnet on ExtendedProperties {
    isMsxPrimaryQuote
    vlAgreementNumbersForMigration
    soldToLegalEntityId
    userPreferences {
      ...userPreferencesFragment
    }
  }
  ${UserPreferencesFragment}
`;

export const TidOidFragment = gql`
  fragment tidOidFragment on TidOid {
    tid
    oid
  }
`;

export const MuidFragment = gql`
  fragment muidFragment on Muid {
    muid
  }
`;

// User ID is a union type that returns either a TidOid or Muid
// The heuristic fragment matcher cannot accurately match fragments with unions or interfaces.
// TODO: Investigate GQL Code Gen and IntrospectionFragmentMatcher to allow for accurate fragment matching with unions and interfaces
export const UserIdFragment = gql`
  fragment userIdFragment on UserId {
    ... on Muid {
      ...muidFragment
    }
    ... on TidOid {
      ...tidOidFragment
    }
  }
  ${TidOidFragment}
  ${MuidFragment}
`;

export const StartEndDatesFragment = gql`
  fragment startEndDatesFragment on StartEndDates {
    startDate
    endDate
  }
`;

export const NegotiationReason = gql`
  enum NegotiationReason {
    Compete
    Aggregation
    FrameworkAgreement
    LicensingProgramConflict
    BudgetConstraints
    LocalMarketDynamics
    SeedingOrDeploymentRamp
    PriceIncreases
    Unknown
  }
`;

//TODO: Add the following back to proposal fragment when API is fixed - 12/30/19
// version, priceSource,negotiatedReason, inviteduser.email
export const ProposalHeaderFragment = gql`
  fragment proposalHeader on QuoteHeader {
    status
    priceSource
    assignedToGroup
    readOnly
    createdApiVersion
    createdBy
    createdDate
    modifiedApiVersion
    modifiedBy
    modifiedDate
    minimumApiVersionSupported
    submittedDate
    lastPricedDate
    expirationDate
    testHeaderData {
      ...testHeaderFragment
    }
    approvalId
    name
    description
    msContact
    guestUserId {
      ...userIdFragment
    }
    invitedUser {
      ...invitedUserFragment
    }
    transactionType
    estimatedDealValue
    totalPrice
    billTo {
      ...billToFragment
    }
    negotiationReason
    businessContacts {
      ...businessContactFragment
    }
    pricingContext {
      ...pricingContext
    }
    crmLead {
      ...crmLeadOldFragment
    }
    soldToCustomer {
      ...quoteCustomerFragment
    }
    endCustomer {
      ...quoteCustomerFragment
    }
    extendedProperties {
      ...extendedPropertiesFramgnet
    }
  }
  ${TestHeaderFragment}
  ${InvitedUserFragment}
  ${BillToFragment}
  ${BusinessContactFragment}
  ${PricingContextFragment}
  ${CrmLeadOldFragment}
  ${QuoteCustomerFragment}
  ${ExtendedPropertiesFragment}
  ${UserIdFragment}
`;

export const BillingPlanDetailFragment = gql`
  fragment billingPlanDetailFragment on BillingPlanDetail {
    cycleCount
    cycleDuration
    markupMultiplier
  }
`;

export const RecipientFragment = gql`
  fragment recipientFragment on Recipient {
    accountId
    organizationId
    recipientId
  }
`;

export const PurchaseRecordLineItemFragment = gql`
  fragment purchaseRecordLineItemFragment on PurchaseRecordLineItem {
    id {
      id
      low
      high
    }
    currentPrice
    targetPrice
    refundPolicy
    remainingTermUnits
  }
`;

export const PurchaseRecordFragment = gql`
  fragment purchaseRecordFragment on PurchaseRecord {
    purchaseRecordId
    purchaseRecordLineItems {
      ...purchaseRecordLineItemFragment
    }
  }
  ${PurchaseRecordLineItemFragment}
`;

export const ProductIdentifierFragment = gql`
  fragment productIdentifierFragment on ProductIdentifier {
    productId
    skuId
    availabilityId
    transactingAvailabilityId
    action
    availabilityTermId
    productFamily
    productType
  }
`;

export const ChargePriceFragment = gql`
  fragment chargePriceFragment on ChargePrice {
    proratedPrice
    price
  }
`;

export const PriceDetailFragment = gql`
  fragment priceDetailFragment on PriceDetail {
    quantity
    discountedPrice
    chargePrice {
      ...chargePriceFragment
    }
    estimatedTotalChargePrice {
      ...chargePriceFragment
    }
  }
  ${ChargePriceFragment}
`;

export const LineItemDiscountFragment = gql`
  fragment lineItemDiscountFragment on Discount {
    ruleType
    applicability
    discountPercentage
    priceGuaranteeDate
    pricingPolicy
  }
`;

export const PricingRulesFragment = gql`
  fragment pricingRulesFragment on PricingRule {
    id
    affirmation
    priceGuaranteed
    affirmationParameters {
      key
      value
      type
    }
  }
`;

export const ChargeFragment = gql`
  fragment chargeFragment on Charge {
    targetQuantity
    pricingCurrency
    exchangeRate
    listPrice
    priceDetails {
      ...priceDetailFragment
    }
    customPrice {
      ...customPriceFragment
    }
    futureGuaranteedPrice {
      type
      value
    }
    pricingRules {
      ...pricingRulesFragment
    }
  }
  ${customPriceFragment}
  ${PriceDetailFragment}
  ${PricingRulesFragment}
`;

export const PurchaseInstructionFragment = gql`
  fragment purchaseInstructionFragment on PurchaseInstruction {
    isAutoRenew
    purchaseTermUnits
    termStartDate
    termEndDate
    assetId
    purchaseTargetPrice
    purchaseTargetTermUnits
    priorPurchase {
      purchaseRecordId
      purchaseRecordLineItem {
        id
        low
        high
      }
    }
  }
`;

export const LineItemuserPreferencesFragment = gql`
  fragment lineItemUserPreferencesFragment on LineItemUserPreferences {
    availabilityId
    duration
    location
    term
    skuTitle
    availabilityTermId
    productType
    conditions {
      type
      name
      value
    }
  }
`;

export const LineItemExtendedPropertiesFragment = gql`
  fragment lineItemExtendedPropertiesFragment on LineItemExtendedProperties {
    parentProductId
    oMSPromocode
    subscriptionIdsForMigration
    userPreferences {
      ...lineItemUserPreferencesFragment
    }
  }
  ${LineItemuserPreferencesFragment}
`;

export const CatalogClaimsFragment = gql`
  fragment catalogClaimsFragment on CatalogClaims {
    pricingAudience
    nationalCloud
    agreementType
  }
`;

export const ParticipantFragment = gql`
  fragment participantFragment on Participant {
    accountId
    profileId
    role
  }
`;

export const AssetToFragment = gql`
  fragment assetToFragment on AssetTo {
    projectId
    accountId
    organizationId
  }
`;

export const AddressIdentifierFragment = gql`
  fragment addressIdentifierFragment on AddressIdentifier {
    accountId
    organizationId
    businessLocationId
    businessLocationVersion
  }
`;

export const ModernAgreementFragment = gql`
  fragment modernAgreementFragment on ModernAgreementDocument {
    documentId
    documentDisplayUri
    documentDownloadUri
  }
`;

export const LineItemFragment = gql`
  fragment lineItemFragment on LineItemOld {
    id
    status
    purchaseId
    extendedPrice
    billingPlanDetail {
      ...billingPlanDetailFragment
    }
    recipient {
      ...recipientFragment
    }
    refund {
      purchaseRecordInfoForRefund {
        ...purchaseRecordFragment
      }
    }
    productName
    productIdentifier {
      ...productIdentifierFragment
    }
    charge {
      ...chargeFragment
    }
    discount {
      ...lineItemDiscountFragment
    }
    purchaseInstruction {
      ...purchaseInstructionFragment
    }
    externalReference {
      id
      url
    }
    extendedProperties {
      ...lineItemExtendedPropertiesFragment
    }
    catalogClaims {
      ...catalogClaimsFragment
    }
    recurrenceProcessor
    customerIntent
    reasonCode
    purchaseProviderName
    fulfillmentReferenceData
    supplementalTermReferenceData {
      name
      value
    }
    isReadyForPricing
    participants {
      ...participantFragment
    }
    assetTo {
      ...assetToFragment
    }
    groups
    AddressIdentifier {
      ...addressIdentifierFragment
    }
  }

  ${BillingPlanDetailFragment}
  ${LineItemDiscountFragment}
  ${RecipientFragment}
  ${PurchaseRecordFragment}
  ${ProductIdentifierFragment}
  ${PurchaseInstructionFragment}
  ${LineItemExtendedPropertiesFragment}
  ${CatalogClaimsFragment}
  ${ParticipantFragment}
  ${AssetToFragment}
  ${AddressIdentifierFragment}

  ${ChargeFragment}
`;

export const QuoteFragment = gql`
  fragment quoteFragment on QuoteOld {
    id
    revision
    continuationToken
    lineItems {
      ...lineItemFragment
    }
    agreement {
      ...modernAgreementFragment
    }
    header {
      ...proposalHeader
    }
    etag
  }
  ${LineItemFragment}
  ${ModernAgreementFragment}
  ${ProposalHeaderFragment}
`;

export const QuoteFragmentNoLineItems = gql`
  fragment quoteFragmentNoLineItems on QuoteOld {
    id
    revision
    continuationToken

    agreement {
      ...modernAgreementFragment
    }
    header {
      ...proposalHeader
    }
    etag
  }

  ${ModernAgreementFragment}
  ${ProposalHeaderFragment}
`;

export const QuoteHeaderPublishWithdrawFragment = gql`
  fragment quoteHeaderPublishWithdraw on QuoteHeader {
    status
    readOnly
    assignedToGroup
    modifiedApiVersion
    modifiedDate
    expirationDate
    testHeaderData {
      ...testHeaderFragment
    }
  }
  ${TestHeaderFragment}
`;

export const QuotePublishWithdrawFragment = gql`
  fragment quotePublishWithdrawFragment on QuoteOld {
    revision
    etag
    header {
      ...quoteHeaderPublishWithdraw
    }
  }
  ${QuoteHeaderPublishWithdrawFragment}
`;
