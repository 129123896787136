import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

const styles = {};

type Props = WithStyles<typeof styles>;

const ComingSoonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <h3>Coming Soon</h3>
    </div>
  );
};

export const ComingSoon = withStyles(styles)(ComingSoonUnstyled);
