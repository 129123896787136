import axios from 'axios';
import { TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader, getCV, getTestHeader } from '../utils';
import { EmpowermentConfig, endpoints } from './config';
import {
  EmpowermentFilter,
  EmpowermentsResponse,
  EmpowermentValidation,
  EmpowermentValidationRequest,
} from './types';

async function getHeaders(config: EmpowermentConfig): Promise<Record<string, string>> {
  const token = await getAuthHeader(TokenAuthorities.L2O);
  const headers: Record<string, string> = {
    authorization: token,
    'x-ms-correlation-id': createGuid(),
    'MS-CV': getCV(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

async function getPostHeaders(config: EmpowermentConfig): Promise<Record<string, string>> {
  let headers = await getHeaders(config);
  headers['Content-Type'] = 'application/json; charset=utf-8';
  return headers;
}

function getFilterParams(filter: EmpowermentFilter): Record<string, string> {
  return {
    empowermentLevel: filter.empowermentLevel,
    'market[hierarchyLevel]': filter.market.hierarchyLevel,
    'market[marketCode]': filter.market.marketCode,
    orderBy: 'email',
    policy: filter.policy,
    searchMatchType: 'ByMarketThenByEmpowermentLevel',
  };
}

export async function loadEmpowerments(
  filter: EmpowermentFilter,
  config: EmpowermentConfig
): Promise<EmpowermentsResponse> {
  const headers = await getHeaders(config);

  const params = getFilterParams(filter);

  const url = endpoints[config.environment];

  const response = await axios.get<EmpowermentsResponse>(url, { params, headers });
  return response.data;
}

export async function validateEmpowerment(
  validate: EmpowermentValidationRequest,
  config: EmpowermentConfig
): Promise<EmpowermentValidation> {
  const headers = await getPostHeaders(config);

  const url = `${endpoints[config.environment]}/validations`;

  const response = await axios.post<EmpowermentValidation>(url, [validate], { headers });
  return response.data;
}
