import { CompoundButtonAtom, CompoundButtonAtomProps } from 'components/atoms';
import React from 'react';

export interface CompoundButtonProps extends CompoundButtonAtomProps {
  addClass?: string;
  dataAutomationId?: string;
}

export const CompoundButton: React.FC<CompoundButtonProps> = props => (
  <CompoundButtonAtom {...props} className={props.addClass}>
    {props.children}
  </CompoundButtonAtom>
);
