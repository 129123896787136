import { ActionType, IconButton, TextBodySmall } from 'components/ions';
import { IButton } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { sharedStyles, ThemeProps } from 'styles';

export interface EditLabelProps {
  ariaLabel: string;
  iconName?: string;
  hideIcon?: boolean;
  buttonId?: string;
  label: string;
  onClick?: (
    event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>,
    item?: IButton
  ) => boolean | void;
  disabled?: boolean;
  required?: boolean;
  dataAutomationId?: string;
}

const styles = (theme: ThemeProps) => {
  const { fonts } = theme;
  return {
    button: {
      minWidth: 16,
      height: 32,
      '&:active, &:hover:active, &:hover, &:focus, &:visited': {
        backgroundColor: 'transparent',
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        '& i.ms-Button-icon': {
          color: theme.palette.textDisabled,
        },
      },
      '& i.ms-Button-icon': {
        ...sharedStyles(theme).link,
        fontSize: fonts.fontSizes.medium,
      },
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      height: 14,
    },
    defaultStyle: {
      color: theme.palette.textTertiary,
    },
    disabled: {
      color: theme.palette.textDisabled,
    },
    label: {
      fontSize: fonts.fontSizes.small,
    },
  };
};

type Props = EditLabelProps & WithStyles<typeof styles>;

const EditLabelUnStyled: React.FC<Props> = props => {
  const { classes } = props;
  const textStyles = () => {
    if (props.disabled) return classes.disabled;
    return classes.defaultStyle;
  };

  const label = (
    <div className={classes.container}>
      <TextBodySmall addClass={textStyles()} required={props.required && !props.disabled}>
        {props.label}
      </TextBodySmall>
      {!props.hideIcon && props.onClick && (
        <IconButton
          addClass={classes.button}
          ariaLabel={props.ariaLabel}
          dataAutomationId={props.dataAutomationId}
          disabled={props.disabled}
          iconName={props.iconName || 'Edit'}
          id={props.buttonId}
          onClick={props.onClick}
        />
      )}
    </div>
  );

  return props.children ? (
    <div>
      {label}
      {props.children}
    </div>
  ) : (
    label
  );
};

export const EditLabel = withStyles(styles)(EditLabelUnStyled);
