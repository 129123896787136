import {
  ChoiceGroup,
  ErrorMessage,
  InfoButton,
  LinkEmail,
  MediumIcon,
  SecondaryButton,
  TextBody,
  TextBodyAsterisk,
  TextBodySmall,
  VerificationField,
} from 'components';
import { getFlightIsEnabled } from 'features/app/selectors';
import * as customerActions from 'features/customer/actions';
import {
  getCustomerAccount,
  getTenantNames,
  getTenants,
  processingVerifyTenant,
} from 'features/customer/selectors';
import { TenantProfile } from 'features/customer/types';
import { BodyProps as WizardBodyProps } from 'features/proposal/components/Wizards/shared';
import { getAccountId } from 'features/proposal/selectors';
import i18next from 'i18n';
import { DirectionalHint, IChoiceGroupOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { TenantName } from 'services/externaluser/types';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { ViralTenantMessage } from '../Partials';
import { invalidTenantFormatMessage, onValidateTenant } from '../shared';
import { choiceGroupStyles, otherOptionStyles, tenantStyles } from './Tenant.styles';

export const TenantHeaderString = (hasExistingTenants: boolean, changingTenant: boolean) => {
  if (hasExistingTenants) {
    if (changingTenant) {
      return i18next.t('quote::Change tenant');
    } else {
      return i18next.t('quote::Select tenant');
    }
  } else {
    return i18next.t('quote::Add tenant');
  }
};

export interface TenantData extends TenantProfile {
  userSearchInput?: string;
}

export interface TenantBodyProps extends WizardBodyProps<TenantData | null | undefined> {
  changingTenant?: boolean;
  workAccount?: string;
  onChangeTenantChoice?: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => void;
}

const mapStateToProps = (state: RootState, props: TenantBodyProps) => {
  const accountId = getAccountId(state);
  const account = accountId ? getCustomerAccount(state, accountId) : null;
  const existingTenants = (account && account.externalIds) || [];
  const preventAdditionalTenant = getFlightIsEnabled(state, Flight.noAdditionalTenant);
  const verifyingTenant = processingVerifyTenant(state);
  const tenantsAccounts = state.customer.tenantAccounts;
  const hasExistingTenants = !!existingTenants.length;
  const { initialData } = props;

  let initialSelectedTenant;
  let initialCurrentValue;

  if (initialData) {
    const selectedExistingTenant = existingTenants.find(obj => obj === initialData.tenantId);

    // The user previous selection was one of the tenants already associated in the account
    if (selectedExistingTenant) {
      initialSelectedTenant = selectedExistingTenant;
    } // The user selected to use an existing tenant that is not associated with any account yet
    else if (initialData.userSearchInput) {
      initialSelectedTenant = 'Other';
      initialCurrentValue = initialData.userSearchInput;
    }
  } // The user selected to sign up for a new tenant
  else if (props.initialData === null) {
    initialSelectedTenant = 'New';
  }

  return {
    accountId,
    // externalIds on account are tenants
    existingTenants,
    hasExistingTenants,
    initialCurrentValue,
    initialSelectedTenant,
    preventAdditionalTenant,
    tenants: getTenants(state),
    tenantNames: getTenantNames(state, existingTenants),
    tenantsAccounts,
    verifyingTenant,
    verifiedTenantName: (verifiedTenantId: string) => getTenantNames(state, [verifiedTenantId]),
  };
};

const dispatchProps = {
  searchTenants: (value: string) => customerActions.verifyTenantAsync.request(value),
};

export type TenantBodyStateProps = typeof dispatchProps &
  ReturnType<typeof mapStateToProps> &
  TenantBodyProps;

type BodyProps = TenantBodyStateProps & WithStyles<typeof tenantStyles>;

export const TenantBodyUnstyled: React.FC<BodyProps> = props => {
  const {
    accountId,
    classes,
    hasExistingTenants,
    initialCurrentValue,
    preventAdditionalTenant,
    verifyingTenant,
    verifiedTenantName,
    tenants,
    existingTenants,
    tenantNames,
    searchTenants,
    tenantsAccounts,
    onChangeTenantChoice,
    onValid,
    onInvalid,
    initialData,
    initialSelectedTenant,
  } = props;
  const { t } = useTranslation();

  const [verifiedTenant, setVerifiedTenant] = React.useState<TenantProfile | undefined>();
  const [lastVerified, setLastVerified] = React.useState<string | undefined>(initialCurrentValue);
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(initialCurrentValue);
  const [tenantChoice, setTenantChoice] = React.useState<string | undefined>(() => {
    if (initialSelectedTenant) {
      onValid(initialData);
      return initialSelectedTenant;
    } else {
      onValid(null);
      return 'New';
    }
  });
  const [viralError, setViralError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [tenantInUse, setTenantInUse] = React.useState<boolean>(false);

  let optionKeys: string[];
  if (hasExistingTenants) {
    optionKeys = [...existingTenants, 'Other'];
  } else {
    optionKeys = ['Other', 'New'];
  }

  const tenantNotFound = t('quote::This tenant does not exist or is not valid.');
  const viralTenantMsg = t(
    'quote::This tenant is unmanaged and you will need to select "No tenant exists" so the customer can create a new tenant on signup.'
  );
  const friendlyTenantMsg = t(
    'quote::This tenant is not valid. Please reach out to the customer to confirm their Azure or Office tenant.'
  );

  const tenantInUseMessage = props.changingTenant ? (
    <Trans ns="quote">
      We found the tenant but it is in use by a different account. We recommend you use one of the
      tenants above which are already associated with this account. If you feel you absolutely need
      to use this new tenant, contact{' '}
      <LinkEmail
        displayText={t('quote::Quote Center Support')}
        email="qcsupport@microsoft.com"
        emailSubject={t('quote::Tenant in use')}
      />{' '}
      for additional guidance.
    </Trans>
  ) : (
    <Trans ns="quote">
      We found the tenant but it is in use by a different account and tenants may only be used by a
      single account. Please try a different tenant; for example, try searching with the domain you
      used when creating the account. If you feel you absolutely need to use this tenant, contact{' '}
      <LinkEmail displayText={t('quote::Quote Center Support')} email="qcsupport@microsoft.com" />{' '}
      for additional guidance.
    </Trans>
  );

  const tenantInUseError = (
    <div className={classes.errorMessage}>
      <ErrorMessage mainMessage={t('quote::Tenant in use')}>{tenantInUseMessage}</ErrorMessage>
    </div>
  );

  React.useEffect(() => {
    if (tenantChoice === 'New') {
      onValid(null);
      setErrorMessage(undefined);
    } else if (tenantChoice === 'Other') {
      onInvalid();
      if (!currentValue || verifyingTenant.loading) {
        setErrorMessage(undefined);
      } else if (tenants && lastVerified && currentValue === lastVerified) {
        const tenantResult = tenants[lastVerified];
        if (!tenantResult || !tenantResult.tenantId) {
          setErrorMessage(tenantNotFound);
          setViralError(false);
        } else if (tenantResult.isConsumer) {
          setErrorMessage(friendlyTenantMsg);
          setViralError(false);
        } else if (tenantResult.isViral) {
          setViralError(true);
        } else {
          const tenantAccounts = tenantsAccounts[tenantResult.tenantId];
          const tenantAssociatedToCurrentAccount =
            tenantAccounts && tenantAccounts.find(account => account.id === accountId);

          if (!oc(tenantAccounts).length(0) || tenantAssociatedToCurrentAccount) {
            onValid({ ...tenantResult, userSearchInput: lastVerified });
            setErrorMessage(undefined);
            setTenantInUse(false);
            setVerifiedTenant(tenantResult);
          } else {
            setTenantInUse(true);
          }
        }
      }
    }
  }, [
    accountId,
    currentValue,
    verifyingTenant.loading,
    tenantsAccounts,
    tenantNotFound,
    tenantChoice,
    tenants,
    searchTenants,
    onValid,
    onInvalid,
    lastVerified,
    viralTenantMsg,
    friendlyTenantMsg,
    optionKeys,
  ]);

  const handleOnChangeTenantChoice = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined
  ) => {
    if (option) {
      setTenantChoice(option.key);
      if (option.key !== 'New' && option.key !== 'Other') {
        onValid({
          tenantId: option.key,
          isViral: false,
          isConsumer: false,
        });
        setTenantInUse(false);
      }
    }
    onChangeTenantChoice && onChangeTenantChoice();
  };

  const addTenantText = (
    <div>
      <div className={classes.paragraph}>
        <TextBodySmall addClass={classes.secondaryText}>
          {t(
            'quote::Identify which tenant the customer would like to use to manage their commerce experience with Microsoft, such as accepting quotes, signing agreements, and managing billing.'
          )}
        </TextBodySmall>
      </div>
      <div className={classes.paragraph2}>
        <TextBodySmall addClass={classes.secondaryText}>
          {t(
            'quote::If the customer is new to online services and does not have a tenant, they will be required to create one during checkout.'
          )}
        </TextBodySmall>
      </div>
    </div>
  );

  const selectTenantText = (
    <TextBodySmall addClass={classes.secondaryText}>
      {t(
        `quote::Select the tenant the customer uses to manage their commerce experience with Microsoft, such as accepting quotes, signing agreements, and managing billing. It's recommended they use the same tenant for all such activity.`
      )}
    </TextBodySmall>
  );

  const workAccountWarning = (
    <div className={classes.warning}>
      <MediumIcon addClass={classes.warningIcon} iconName="Warning" />
      <div>
        <TextBodySmall addClass={`${classes.secondaryText} ${classes.warningText}`}>
          {t(
            'quote::The work account may not exist on the newly selected tenant, and you may need to enter a new work account.'
          )}
        </TextBodySmall>
      </div>
    </div>
  );

  const verifiedTenantList = (
    <div className={classes.verifiedListContainer}>
      <div className={classes.listRow}>
        <TextBodySmall addClass={classes.secondaryText}>
          {t('quote::Verified tenant ID')}
        </TextBodySmall>
        <span className={classes.verifiedHeaderSpacer} />
        <TextBodySmall addClass={classes.secondaryText}>
          {t('quote::Verified tenant name')}
        </TextBodySmall>
      </div>
      <div>
        {verifiedTenant && (
          <div className={classes.listRow} key={verifiedTenant.tenantId}>
            <TextBody>{verifiedTenant.tenantId}</TextBody>
            <span className={classes.verifiedListSpacer} />
            <TextBody>
              {verifiedTenantName(verifiedTenant.tenantId || '')[0].tenantDisplayName}
            </TextBody>
          </div>
        )}
      </div>
    </div>
  );
  const lastVerifiedAndCurrentIsSame = lastVerified === currentValue;

  const verificationMessage = () => {
    if (
      !verifyingTenant.loading &&
      !tenantInUse &&
      verifiedTenant &&
      verifiedTenant.tenantId &&
      lastVerifiedAndCurrentIsSame
    ) {
      return verifiedTenantList;
    }
  };
  const tenantVerification = (addClass: string, label?: string) => (
    <div className={addClass}>
      <VerificationField
        buttonDisabled={tenantChoice !== 'Other'}
        buttonText={t('quote::Verify')}
        dataAutomationId="tenantVerification"
        errorMessage={errorMessage || ''}
        isError={!!errorMessage}
        isLoading={verifyingTenant.loading}
        isVerified={!!verifiedTenant}
        lastVerified={lastVerified}
        textboxAutoFocus
        textboxLabel={label}
        textboxPlaceholder={t('quote::Enter the tenant domain or ID')}
        textboxValue={currentValue}
        validationErrorMessage={invalidTenantFormatMessage()}
        verifiedStatusText={t('quote::Verified')}
        onChangeDebounced={(value?: string) => {
          setCurrentValue(value);
          setErrorMessage(undefined);
          setTenantChoice('Other');
          setTenantInUse(false);
          setViralError(false);
        }}
        onValidate={onValidateTenant}
        onVerify={(value: string) => {
          setVerifiedTenant(undefined);
          setErrorMessage(undefined);
          onInvalid();
          searchTenants(value);
          setLastVerified(value);
          setTenantInUse(false);
          setViralError(false);
        }}
      />
      {verificationMessage()}

      {viralError && lastVerifiedAndCurrentIsSame && (
        <div className={classes.viralTenantError}>
          <ViralTenantMessage maxHeight="200px" />
        </div>
      )}
      {tenantInUse && tenantInUseError}
    </div>
  );

  const buildExistingTenantOptions = () => {
    if (hasExistingTenants) {
      const existingOptions: IChoiceGroupOption[] = tenantNames.map((tenant: TenantName) => ({
        key: tenant.tenantId,
        text: tenant.tenantId,
        // eslint-disable-next-line react/display-name
        onRenderLabel: () => {
          return (
            <div className={classes.existingTenantLabel}>
              <div className={classes.existingTenantIDLabel}>
                <TextBody>{tenant.tenantId}</TextBody>
              </div>
              <span className={classes.existingTenantSpacerLabel} />
              <TextBody>{tenant.tenantDisplayName}</TextBody>
            </div>
          );
        },
      }));
      !preventAdditionalTenant &&
        existingOptions.push({
          key: 'Other',
          text: t('quote::Other tenant'),
          styles: otherOptionStyles,
          // eslint-disable-next-line react/display-name
          onRenderLabel: () => {
            return (
              <div className={classes.existingTenantLabel}>
                <TextBody>{t('quote::Other tenant')}</TextBody>
              </div>
            );
          },
          // eslint-disable-next-line react/display-name
          onRenderField: (
            props?: IChoiceGroupOption,
            render?: (props?: IChoiceGroupOption) => JSX.Element | null
          ) => {
            return (
              <div>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {render && render(props)}
                {tenantVerification(classes.pickerVerification)}
              </div>
            );
          },
        });

      return existingOptions;
    }
  };

  const existingTenantHeader = (
    <div className={classes.existingTenantHeader}>
      <TextBodySmall addClass={classes.secondaryText}>
        {t('quote::Tenants associated with the account')}
      </TextBodySmall>
      {!preventAdditionalTenant && (
        <InfoButton
          ariaLabel={t('quote::Open information about modern account')}
          calloutProps={{
            closeButtonAriaLabel: t('Close'),
            directionalHint: DirectionalHint.bottomCenter,
            headline: t('quote::Modern Account'),
            maxWidth: 304,
          }}
          id="tenant-info-button"
        >
          <TextBody>
            {t(
              'quote::A Modern Account has one or more Billing Accounts. The tenant the customer uses to manage their commercial activity with Microsoft is stored on the Account. This is the list of all of the customer tenants which have been associated with their Modern Account; it may not be all the tenants they have.'
            )}
          </TextBody>
        </InfoButton>
      )}
      <span className={classes.existingTenantHeaderSpacer} />
      <TextBodySmall addClass={classes.secondaryText}>{t('quote::Tenant name')}</TextBodySmall>
    </div>
  );

  const tenantOptions = [
    {
      key: 'New',
      text: t('quote::No tenant exists, set up as new'),
    },
    {
      key: 'Other',
      text: t('quote::Existing tenant'),
      // eslint-disable-next-line react/display-name
      onRenderLabel: () => {
        const TenantTextBody = tenantChoice === 'Other' ? TextBodyAsterisk : TextBody;
        return (
          <div className={classes.existingTenantLabel}>
            <TenantTextBody required>{t('quote::Existing tenant')}</TenantTextBody>
          </div>
        );
      },
      // eslint-disable-next-line react/display-name
      onRenderField: (
        props?: IChoiceGroupOption,
        render?: (props?: IChoiceGroupOption) => JSX.Element | null
      ) => {
        return (
          <div>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {render && render(props)}
            {tenantVerification(classes.pickerVerification)}
          </div>
        );
      },
    },
  ];

  const tenantPicker = (
    <div className={hasExistingTenants ? '' : classes.choiceGroupNew}>
      {hasExistingTenants && existingTenantHeader}
      <ChoiceGroup
        dataAutomationId="tenantChoiceGroup"
        options={buildExistingTenantOptions() || tenantOptions}
        required
        selectedKey={tenantChoice}
        styles={choiceGroupStyles}
        onChange={handleOnChangeTenantChoice}
      />
    </div>
  );

  if (hasExistingTenants) {
    const showWarning =
      props.workAccount &&
      ((initialData && tenantChoice !== initialData.tenantId) || tenantChoice === 'Other');
    return (
      <>
        <div className={classes.existingListHeight}>
          {selectTenantText}
          {tenantPicker}
          {!preventAdditionalTenant && showWarning && workAccountWarning}
        </div>
      </>
    );
  } else {
    return (
      <div>
        {addTenantText}
        {tenantPicker}
      </div>
    );
  }
};

export const TenantFooterButtons = (onButtonClick: () => void, buttonDisabled: boolean) => {
  return [
    <SecondaryButton
      dataAutomationId="tenantNextButton"
      disabled={buttonDisabled}
      key="tenant-next"
      text={i18next.t('quote::Next')}
      onClick={onButtonClick}
    />,
  ];
};

export const TenantBodyStyled = withStyles(tenantStyles)(TenantBodyUnstyled);
export const TenantBody = connect(mapStateToProps, dispatchProps)(TenantBodyStyled);
