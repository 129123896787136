import { CommandButtonAtom } from 'components/atoms';
import {
  IButtonStyles,
  IContextualMenuItemStyles,
  IContextualMenuStyles,
  IMenuItemStyles,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { buildButtonMenu, ButtonMenuProps, ButtonSharedProps } from '../ButtonSharedProps';
import { headerButtonStyles } from './HeaderButton.styles';

export interface HeaderButtonProps extends ButtonSharedProps {
  id?: string;
  menuProps?: ButtonMenuProps[];
  primary?: boolean;
  menuId: string;
  iconName: string;
  ariaLabel?: string;
}

type Props = HeaderButtonProps & WithStyles<typeof headerButtonStyles>;

const HeaderButtonUnstyled: React.FC<Props> = (props: Props) => {
  const buttonStyle: Partial<IButtonStyles> = {
    rootExpanded: props.classes.expanded,
  };
  const primaryStyle: Partial<IButtonStyles> = {
    rootExpanded: props.classes.primaryExpanded,
  };
  const menuItemStyle: Partial<IContextualMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  const dropdownStyles: Partial<IContextualMenuStyles> = {
    root: props.classes.menuRoot,
    container: props.classes.menuList,
    subComponentStyles: {
      callout: {
        root: props.classes.calloutRoot,
        calloutMain: props.classes.calloutMenu,
      },
      menuItem: menuItemStyle,
    },
  };
  const menuItemStyles: Partial<IMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  return (
    <CommandButtonAtom
      addClass={
        props.primary
          ? `${props.classes.primary} ${props.classes.iconButton} ${props.classes.chevron}`
          : `${props.classes.command} ${props.classes.iconButton} ${props.classes.chevron}`
      }
      ariaLabel={props.ariaLabel}
      disabled={props.disabled}
      iconProps={{ iconName: props.iconName }}
      id={props.id}
      menuProps={
        props.menuProps && props.menuId
          ? buildButtonMenu(props.menuProps, dropdownStyles, menuItemStyles, props.menuId)
          : undefined
      }
      styles={props.primary ? primaryStyle : buttonStyle}
      onClick={props.onClick}
    />
  );
};

HeaderButtonUnstyled.defaultProps = {
  primary: false,
};

export const HeaderButton = withStyles(headerButtonStyles)(HeaderButtonUnstyled) as React.FC<
  HeaderButtonProps
>;
