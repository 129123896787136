import { SecondaryButton } from 'components/ions';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { TextBody } from '../Text';

const selectionStyles = (theme: ThemeProps) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
});

export interface SelectionSectionProps {
  addButtonClass?: string;
  addClass?: string;
  buttonText: string;
  disabled?: boolean;
  title?: string;
  onClick?: () => void;
  dataAutomationId?: string;
}

type Props = SelectionSectionProps & WithStyles<typeof selectionStyles>;
const SelectionSectionUnstyled: React.FC<Props> = props => {
  const leftSide = (props.title || props.children) && (
    <div className={props.classes.leftSide}>
      {props.title && <TextBody addClass={props.classes.title}>{props.title}</TextBody>}
      {props.children}
    </div>
  );

  return (
    <div className={`${props.classes.container} ${props.addClass}`}>
      {leftSide}
      <SecondaryButton
        addClass={props.addButtonClass}
        dataAutomationId={`${props.dataAutomationId}Button`}
        disabled={props.disabled}
        text={props.buttonText}
        onClick={props.onClick}
      />
    </div>
  );
};

export const SelectionSection = withStyles(selectionStyles)(SelectionSectionUnstyled) as React.FC<
  SelectionSectionProps
>;
