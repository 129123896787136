import * as React from 'react';
import {
  ProgressIndicator,
  IStyleFunctionOrObject,
  IProgressIndicatorStyleProps,
  IProgressIndicatorStyles,
} from 'office-ui-fabric-react';

/**
 * Progress Indicator Atom props
 * @prop {string} ariaValueText   - Text alternative of the progress status, used by screen readers for reading the value of the progress.
 * @prop {number} barHeight       - Height of the ProgressIndicator
 * @prop {React.ReactNode} description - Text describing or supplementing the operation. May be a string or React virtual elements.
 * @prop {React.ReactNode} label  - Label to display above the control. May be a string or React virtual elements.
 * @prop {number} percentComplete - Percentage of the operation's completeness, numerically between 0 and 1.
 *                                  If this is not set, the indeterminate progress animation will be shown instead.
 * @prop {boolean} progressHidden - Whether or not to hide the progress state.
 * @prop {IStyleFunctionOrObject} styles - Call to provide customized styling that will layer on top of the variant rules.
 */
export interface ProgressIndicatorProps {
  ariaValueText?: string;
  barHeight?: number;
  description?: React.ReactNode;
  label?: React.ReactNode;
  percentComplete?: number;
  progressHidden?: boolean;
  styles?: Partial<IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles>>;
}

/**
 * Progress Indicator from Office Fabric UI
 * @see {@link https://developer.microsoft.com/en-us/fabric#/controls/web/progressindicator} for more information
 */
export const ProgressIndicatorAtom: React.FC<ProgressIndicatorProps> = props => {
  return (
    <ProgressIndicator
      ariaValueText={props.ariaValueText}
      barHeight={props.barHeight}
      description={props.description}
      label={props.label}
      percentComplete={props.percentComplete}
      progressHidden={props.progressHidden}
      styles={props.styles}
    />
  );
};
