import { sharedStyles, ThemeProps } from 'styles';

import { DropdownAtomProps } from './dropdown.types';

const getDefaultStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  const title = {
    ...sharedStyles(theme).roundedCorners,
  };

  return {
    highlight: {
      background: palette.lineItemHighlight,
      '&.is-checked .ms-Checkbox-label i.ms-Checkbox-checkmark': {
        color: palette.lightColor,
      },
    },
    title,
    titleDisabled: {
      ...title,
      backgroundColor: theme.palette.backgroundCommon,
      border: `1px solid ${theme.palette.backgroundDivider} !important`,
    },
    dropdownItems: {
      '& .ms-Checkbox-checkbox': {
        ...sharedStyles(theme).roundedCorners,
        marginRight: 8,
      },
    },
    dropdownItem: {
      height: 32,
    },

    dropdownDivider: {
      margin: '0px 16px',
      color: palette.backgroundDivider,
    },
  };
};

const getStyle2 = (theme: ThemeProps) => {
  const defaultStyles = getDefaultStyles(theme);
  const title = {
    border: `1px solid ${theme.palette.backgroundDivider}`,
  };

  return {
    title: {
      ...defaultStyles.title,
      ...title,
    },
    titleDisabled: {
      ...defaultStyles.titleDisabled,
      ...title,
    },
    titleIsOpen: {
      ...defaultStyles.title,
      ...title,
      background: `${theme.palette.backgroundStandout} 0% 0% no-repeat padding-box`,
    },
    callout: {
      marginTop: 3, // Odd number to substract the width of the border
      '& .ms-Callout-main': { borderRadius: 2 },
    },
    calloutMain: {
      border: `1px solid ${theme.palette.backgroundDivider}`,
    },
  };
};

export const dropdownAtomStyles = (theme: ThemeProps) => {
  const style1 = getDefaultStyles(theme);
  const style2 = getStyle2(theme);

  return {
    ...getDefaultStyles(theme),
    title: (props: DropdownAtomProps) =>
      props.stylesPredefined === 'style2' ? style2.title : style1.title,
    titleDisabled: (props: DropdownAtomProps) =>
      props.stylesPredefined === 'style2' ? style2.titleDisabled : style1.titleDisabled,
    titleIsOpen: (props: DropdownAtomProps) =>
      props.stylesPredefined === 'style2' ? style2.titleIsOpen : undefined,
    callout: (props: DropdownAtomProps) =>
      props.stylesPredefined === 'style2' ? style2.callout : undefined,
    calloutMain: (props: DropdownAtomProps) =>
      props.stylesPredefined === 'style2' ? style2.calloutMain : undefined,
  };
};
