import { DetailsListAtom, DetailsListAtomProps } from 'components/atoms/DetailsList';
import { mergeClassNames } from 'components/utilities';
import { useResizeAfterFirstRender } from 'hooks';
import {
  ConstrainMode,
  DetailsListLayoutMode,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IRenderFunction,
  ITooltipHostProps,
  ScrollablePane,
  ScrollbarVisibility,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';

import { listContainerDivStyles, stickyHeaderStyles } from './StickyHeaderDetailsList.styles';

/**
 * Props for StickyHeaderDetailsList Component
 *
 * @prop {string} [ariaLabelForSelectionColumn] - An ARIA label for the name of the selection column, for localization.
 * @prop {CheckboxVisibility}  checkboxVisibility - Controls the visibility of selection check box. *
 * @prop {IColumn[]} columns - Given column definitions. If none are provided, default columns will be created based on the item's properties.
 * @prop {number | string} height - height of the List.
 * @prop {string} [id] -id.
 * @prop {any[]} items -  The items to render.
 * @prop {boolean} [removeRowSeparators] - This would remove the row separators between rows
 * @prop {boolean} [shouldResizeAfterFirstRender] - Used to remove unnecessary horizontal bars that appear on Edge & Firefox because of the resize observer
 * @prop {Selection} [selection] - Optional selection model to track selection state.
 * @prop {SelectionMode}  selectionMode - Controls how/if the details list manages selection. Options include none, single, multiple.
 * @prop {function} [onRenderDetailsFooter] - An Override For DetailsFooter, useful for showing Errors or Shimmer.
 *
 */
export interface StickyHeaderDetailsListProps extends DetailsListAtomProps {
  height: number | string;
  id?: string;
  removeRowSeparators?: boolean;
  shouldResizeAfterFirstRender?: boolean;
  fetchMore?: () => React.ReactNode;
  stylesOverride?: string;
  onRenderRow?: IRenderFunction<IDetailsRowProps>;
}

type Props = StickyHeaderDetailsListProps & WithStyles<typeof stickyHeaderStyles>;

export const StickyHeaderDetailsListUnstyled: React.FC<Props> = (props: Props) => {
  useResizeAfterFirstRender(props.shouldResizeAfterFirstRender);
  const style = listContainerDivStyles(props.height) as React.CSSProperties;
  const stickyOnRenderHeaderCreator = () => {
    const onRenderDetailsHeader = (
      headerProps?: IDetailsHeaderProps,
      defaultRender?: IRenderFunction<IDetailsHeaderProps>
    ) => {
      if (!defaultRender || !headerProps) {
        loggerService.error({
          error: new Error(
            'OnRenderDetailsHeader defaultRender or headerProps is undefined or null'
          ),
        });
      }
      const onRenderColumnHeaderToolTip = (tooltipHostProps?: ITooltipHostProps) => (
        <TooltipHost {...tooltipHostProps} />
      );
      const headerClass = props.stylesOverride || props.classes.header;
      return (
        <>
          <Sticky
            isScrollSynced
            stickyClassName={headerClass}
            stickyPosition={StickyPositionType.Header}
          >
            {defaultRender &&
              defaultRender({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...headerProps!,
                onRenderColumnHeaderTooltip: onRenderColumnHeaderToolTip,
              })}
          </Sticky>
          {props.onRenderDetailsHeader ? props.onRenderDetailsHeader() : null}
        </>
      );
    };
    return onRenderDetailsHeader;
  };
  const mergedClasses = props.removeRowSeparators
    ? mergeClassNames([props.classes.header, props.classes.removeRowSeparators])
    : props.classes.header;
  const mainclass = props.stylesOverride || mergeClassNames([props.mainClass, mergedClasses]);
  return (
    <div id={props.id} style={style}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsListAtom
          ariaLabelForSelectionColumn={props.ariaLabelForSelectionColumn}
          checkboxVisibility={props.checkboxVisibility}
          columns={props.columns}
          constrainMode={ConstrainMode.unconstrained}
          items={props.items}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          mainClass={mainclass}
          selection={props.selection}
          selectionMode={props.selectionMode}
          onRenderDetailsFooter={props.onRenderDetailsFooter}
          onRenderDetailsHeader={stickyOnRenderHeaderCreator()}
          onRenderMissingItem={props.fetchMore}
          onRenderRow={props.onRenderRow}
        />
      </ScrollablePane>
    </div>
  );
};

export const StickyHeaderDetailsList = withStyles(stickyHeaderStyles)(
  StickyHeaderDetailsListUnstyled
) as React.FC<StickyHeaderDetailsListProps>;
