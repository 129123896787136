import { LinkButton } from 'components/ions';
import { SoldToSimple } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps, FontSizes } from 'styles';

import { RequestCreditIncreaseDialog } from './';

interface RequestCreditIncreaseButtonProps {
  disableLink?: boolean;
  size?: keyof FontSizes;
  dataAutomationId?: string;
  annualDealEstimate: string;
  billingCurrency: string;
  minimumCreditLine: string;
  soldTo?: SoldToSimple;
  quoteId: string;
  etag: string;
}

export const RequestCreditIncreaseButton: React.FC<RequestCreditIncreaseButtonProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const dialogProps: DialogProps = {
    providedDialog: (
      <RequestCreditIncreaseDialog
        billingCurrency={props.billingCurrency}
        quoteId={props.quoteId}
      />
    ),
  };

  const handleOnClick = () => {
    context.openDialog(dialogProps);
  };

  return (
    <LinkButton
      dataAutomationId={props.dataAutomationId}
      disabled={props.disableLink}
      displayText={t('quote::request credit increase')}
      id="requestCreditIncrease"
      size={props.size || 'medium'}
      onClick={handleOnClick}
    />
  );
};
