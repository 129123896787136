import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { animated, useSpring } from 'react-spring';
import { oc } from 'ts-optchain';
import { TextBody, TextBodySmall, MediumIcon, PrimaryButton, TextTitle } from 'components';
import { LabelList, LabelListItem } from 'components/ions/CuratedText/LabelList/LabelList';
import { buildNumber } from 'App';
import { QuoteStatus, AgreementType } from 'generated/graphql';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { supportTicketPanelStyles } from './SupportTicketPanelContent.styles';
import { SupportLinkType } from 'features/app/components/HelpPanel/SupportResources';
import loggerService from 'services/logger-service';

const TIMEOUT = 4000;

type Props = WithStyles<typeof supportTicketPanelStyles>;

const SupportTicketPanelContentUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const activeQuote = React.useContext(ActiveQuoteContext);
  const proposal = activeQuote && activeQuote.activeQuote;
  const noneText = t('none');
  const copyRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
  const [showCopied, setCopied] = React.useState(false);
  const spring = useSpring({ opacity: showCopied ? 1 : 0, width: 200 });

  let timer: NodeJS.Timeout;
  const handleCopyClick = () => {
    clearTimeout(timer);
    if (copyRef.current) {
      //Copies contents of table within 'copyRef' div
      const range = document.createRange();
      range.selectNodeContents(copyRef.current);
      const select = window.getSelection();
      select && select.removeAllRanges();
      select && select.addRange(range);
      document.execCommand('copy');
    }
    setCopied(!showCopied);
    timer = setTimeout(() => {
      setCopied(false);
    }, TIMEOUT);
  };
  const isLegacyQuote = oc(proposal).agreementType() === AgreementType.Legacy;

  const copyItems: LabelListItem[] = [
    { name: t('Quote ID'), value: oc(proposal).id(noneText) },
    { name: t('Quote name'), value: oc(proposal).name(noneText) },
    {
      name: t('Quote status'),
      value: oc(proposal).status(QuoteStatus.Unknown),
    },
    {
      name: t('MCAPI account name'),
      value: oc(proposal).soldTo.organization.account.description(noneText),
    },
    {
      name: t('Billing account name'),
      value: oc(proposal).soldTo.organization.address.companyName(noneText),
    },
    //TODO: kaderbez need name to be added to tenant simple
    //{ name: t('Tenant name'), value: oc(proposal).soldToInvitedUser.tenant.name(noneText) },
    {
      name: t('Tenant ID'),
      value: (!isLegacyQuote && oc(proposal).soldToInvitedUser.tenant.id(noneText)) || noneText,
    },
    { name: t('Enrollment Number'), value: oc(proposal).vlAgreementNumber(noneText) },
    { name: t('Build Number'), value: buildNumber },
  ];

  return (
    <div className={classes.container}>
      <Trans ns="common">
        <div className={classes.header}>
          <div className={classes.titleBlock}>
            <TextTitle addClass={classes.title}>*** MUST READ ***</TextTitle>
          </div>
        </div>
        <TextBody addClass={classes.body}>
          We are in the process of integrating with a new support ticketing system. This is a work
          in progress so, to insure your success, please follow these instructions.
        </TextBody>
        <TextBody addClass={classes.subtitle}>Launching the Support Ticket wizard</TextBody>
        <TextBody addClass={classes.body}>
          Use the Open Wizard button below to launch the New Support Ticket wizard. The wizard will
          open in a new browser tab and the following information will be copied to the clipboard.
          This is important because you will need to paste the information into the description
          field of the ticket later on.
        </TextBody>
        <blockquote className={classes.blockquote}>
          <TextBodySmall addClass={classes.tableTitle}>
            Information to paste into the ticket description
          </TextBodySmall>
          <div ref={copyRef}>
            <LabelList items={copyItems} />
          </div>
        </blockquote>
        <TextBody addClass={classes.bodyWarning}>
          If this is about a quote, open the quote first and start the ticket from within the editor
          so you get the proper information, otherwise you will need to fill it out by hand.
        </TextBody>
        <div className={classes.copyContainer}>
          <PrimaryButton
            addClass={classes.button}
            dataAutomationId="openWizardButton"
            id="openWizardButton"
            text={t('Open Wizard')}
            onClick={() => {
              loggerService.log(
                { name: 'Support link clicked' },
                {
                  displayText: t('Open Wizard'),
                  linkType: SupportLinkType.External,
                }
              );
              handleCopyClick();
              window.open(
                'http://support.microsoft.com/supportforbusiness/productselection?sapId=ec54ac11-f6e5-4b40-19b0-1493d0651919',
                '_blank'
              );
            }}
          />
          <animated.div className={classes.animation} style={{ ...spring }}>
            <MediumIcon iconName="Checkmark" />
            <TextBodySmall addClass={classes.animation}>information copied</TextBodySmall>
          </animated.div>
        </div>
        <TextBody addClass={classes.subtitle}>Wizard Step 1</TextBody>
        <TextBody addClass={classes.body}>
          The first two fields have been filled out for you; leave them as is. Select values from
          the third and forth, to categorize the issue you are having.
        </TextBody>
        <TextBody addClass={classes.subtitle}>Wizard Step 2</TextBody>
        <ol className={classes.list}>
          <li>Fill out the first three fields as appropriate.</li>
          <li>
            In the description field, use Ctrl-V or right click Paste, to paste in the information
            above.
          </li>
          <li>Complete the description by telling us, fully, what the issue is.</li>
        </ol>
        <TextBody addClass={classes.subtitle}>Wizard Step 3</TextBody>
        <TextBody addClass={classes.body}>Ignore this step, click on through.</TextBody>
        <TextBody addClass={classes.subtitle}>Wizard Step 4</TextBody>
        <TextBody addClass={classes.body}>
          Check and make sure the time zone is set to your time zone.
        </TextBody>
        <TextBody addClass={classes.subtitle}>Wizard Step 5</TextBody>
        <TextBody addClass={classes.body}>
          Ignore this step. If you can&apos;t click on through, you may need to enter a phone number
          in the contact information.
        </TextBody>
        <TextBody addClass={classes.subtitle}>Wizard Step 6</TextBody>
        <TextBody addClass={classes.body}>Click the Submit button. You should be all set.</TextBody>
      </Trans>
    </div>
  );
};

export const SupportTicketPanelContent = withStyles(supportTicketPanelStyles)(
  SupportTicketPanelContentUnstyled
);
