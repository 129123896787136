import { NotificationItem, ShowMoreDialog, TextTitle } from 'components';
import { LabelListItem } from 'components/ions/CuratedText/LabelList/LabelList';
import {
  DetailsStyles,
  renderAddOns,
  renderDiscounts,
  renderLabelList,
  renderNotifications,
} from 'features-apollo/quote/components/DetailsPane';
import {
  ExistingDiscount,
  ExistingDiscountLabels,
} from 'features-apollo/quote/components/ExistingDiscountsList';
import { AddOn } from 'features/proposal/components/AddOns';
import { Quote } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { EcifDetailsProps, EcifLineItemDetails } from './EcifLineItemDetails';

export interface PurchaseLineItemDetails {
  id?: string;
  description: string;
  strippedDescription: string;
  productName: string;
  configurations: LabelListItem[];
  notifications: NotificationItem[];
  addOns?: AddOn[];
  imageUri: string;
  publisherLink?: React.ReactNode;
  ecifData?: EcifDetailsProps;
  quote?: Quote;
  discounts?: ExistingDiscount[];
  discountLabels: ExistingDiscountLabels;
}

type Props = PurchaseLineItemDetails & WithStyles<typeof DetailsStyles>;

const PurchaseLineItemUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    productName,
    description,
    imageUri,
    publisherLink,
    strippedDescription,
    notifications,
    addOns,
    configurations,
    ecifData,
    quote,
    discounts,
    discountLabels,
  } = props;
  const { t } = useTranslation();

  const dialogProps = {
    props: {
      id: 'description-dialog-box',
      dialogClose: React.useContext(DialogContext).closeDialog,
      headingText: productName,
      bodyText: description,
      imageUrl: imageUri,
      actionLink: publisherLink,
      dataAutomationId: 'descriptionDialogBox',
    },
    hasImage: imageUri ? true : false,
    isCustom: false,
  };

  let bodyContent = (
    <>
      {renderLabelList(configurations, classes.detailsPaneSection)}
      {addOns && renderAddOns(classes, addOns)}
      {/* {renderLabelList(conditions, classes.detailsPaneSection)} */}
      {(discounts && discounts.length && renderDiscounts(classes, discountLabels, discounts)) ||
        null}
      {renderNotifications(notifications, classes, quote)}
    </>
  );

  if (ecifData) {
    bodyContent = <EcifLineItemDetails {...ecifData} notifications={notifications} />;
  }

  return (
    <>
      <div className={classes.heading}>
        <div>
          <TextTitle>{productName}</TextTitle>
        </div>
        <div className={classes.description} data-automation-id="productDescription">
          <ShowMoreDialog
            dataAutomationId="fullDescriptionLink"
            dialogProps={dialogProps}
            id="Description"
            linkId="full-description-link"
            linkText={t('quote::full description')}
            maxLines={5}
            text={strippedDescription}
          />
        </div>
      </div>
      <div className={classes.sectionDetails}>{bodyContent}</div>
    </>
  );
};

export const PurchaseLineItemDetails = withStyles(DetailsStyles)(PurchaseLineItemUnstyled);
