import { Currency } from 'features/proposal/supported-currencies';
/* eslint-disable @typescript-eslint/no-use-before-define */
import { ProductFilter, ProductFilterContext } from 'microsoft-commerce-product-filters';
import moment from 'moment';
import { Product } from 'services/catalog/types';
import loggerService from 'services/logger-service';
import {
  CreateLineItemsRequest,
  LineItem,
  PricingInstructionCondition,
  Proposal,
  ProposalUpdateRequest,
  RequestLineItem,
} from 'services/proposal/types';
import { oc } from 'ts-optchain';

import {
  buildConditions,
  filterOnDurations,
  filterOnLocations,
  getActionValue,
  getAvailabilityForTermId,
  getFirstPurchaseAvailabilities,
  getTermUnits,
  hasAvailabilityConsumeAction,
  hasDiscount,
  hasMultipleSkus,
  isAccessPass,
  isNegotiatedTerm,
  isPasses,
  isSupportOffer,
  isUnfilterableProduct,
  removePrivateAndNonPurchasableConsumableSkus,
} from './ConfigCardBusinessLogic';
import {
  CreateLineItemProperties,
  HydratedProduct,
  QualifyingSkuAvailability,
  SkuRow,
} from './types';

export const createLineItemCopiesRequestWithCurrentLineItem = (
  createLineItems: CreateLineItemProperties[],
  currentLineItem: LineItem,
  hydratedProduct: HydratedProduct
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const productFilterContext = new ProductFilterContext(hydratedProduct as any);
  const request: Partial<RequestLineItem>[] = createLineItems.map(
    (lineItem: CreateLineItemProperties) => {
      filterOnLocations(productFilterContext, lineItem.location);
      lineItem.duration &&
        filterOnDurations(productFilterContext, hydratedProduct, lineItem.duration);
      const conditions = buildConditions(
        productFilterContext.productFilters,
        hydratedProduct,
        productFilterContext.qualifyingSkuAvailabilities,
        lineItem.duration,
        lineItem.location
      );
      const skuCount = hasMultipleSkus(productFilterContext.qualifyingSkuAvailabilities)
        ? removePrivateAndNonPurchasableConsumableSkus(
            productFilterContext.qualifyingSkuAvailabilities,
            hydratedProduct as Product
          ).length
        : undefined;
      // cannot just update location
      const userPreferences = lineItem.location
        ? {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

            duration: lineItem.duration,
            location: lineItem.location,
            skuCount,
            conditions: conditions.length ? conditions : undefined,
          }
        : {};
      return {
        ...currentLineItem,
        isReadyForPricing: true,
        id: undefined,
        extendedProperties: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          userPreferences: JSON.stringify(userPreferences) as any,
        },
      };
    }
  );
  return request;
};

export const createLineItemsRequestWithProperties = (
  proposal: Proposal,
  createLineItems: CreateLineItemProperties[],
  updatedLineItem: LineItem,
  hydratedProduct: HydratedProduct
) => {
  const lineItemCopies: Partial<RequestLineItem>[] = createLineItemCopiesRequestWithCurrentLineItem(
    createLineItems,
    updatedLineItem,
    hydratedProduct
  );

  const request: CreateLineItemsRequest = {
    etag: proposal.etag,
    lineItems: lineItemCopies,
    proposalId: proposal.id,
  };
  return request;
};

export const createLineItemsRequest = (
  proposal: Proposal,
  createLineItems: Partial<RequestLineItem>[]
) => {
  const request: CreateLineItemsRequest = {
    etag: proposal.etag,
    lineItems: createLineItems,
    proposalId: proposal.id,
  };
  return request;
};

export const addAccessPassConfiguration = (
  lineItem: LineItem,
  proposal: Proposal,
  createAndHydrateLineItems: (request: CreateLineItemsRequest) => void,
  currency: Currency,
  selectedListKey: string,
  recurringCheckEnabled?: boolean,
  selectedSkuRow?: SkuRow
) => {
  const productId: string[] = selectedListKey.split('/');
  const isValid = productId.length === 3;
  if (isValid) {
    const updatedLineItem: LineItem = {
      ...lineItem,
      productIdentifier: {
        ...lineItem.productIdentifier,
        productId: productId[0],
        skuId: productId[1],
        availabilityId: productId[2],
        action: 'purchase',
      },
      pricingCurrency: currency,
      isReadyForPricing: true,
      purchaseInstruction: { ...lineItem.purchaseInstruction, isAutoRenew: recurringCheckEnabled },
      extendedProperties: {
        userPreferences: {
          ...(lineItem.extendedProperties && lineItem.extendedProperties.userPreferences),
          skuTitle: (selectedSkuRow && selectedSkuRow.Title) || undefined,
        },
      },
    };
    if (updatedLineItem.extendedProperties && updatedLineItem.extendedProperties.userPreferences) {
      const lineItemForCreation = {
        ...updatedLineItem,
        id: undefined,
        extendedProperties: {
          ...updatedLineItem.extendedProperties,
          userPreferences: JSON.stringify(
            updatedLineItem.extendedProperties.userPreferences
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any,
        },
      };
      const request = createLineItemsRequest(proposal, [lineItemForCreation]);
      createAndHydrateLineItems(request);
    }
  } else {
    loggerService.error({ error: new Error('invalid product identifier') });
  }
};

export const addSkuLevelConfiguration = (
  lineItem: LineItem,
  proposal: Proposal,
  action: string,
  selectedListKey: string,
  selectedSkuRow: SkuRow,
  createAndHydrateLineItems: (request: CreateLineItemsRequest) => void,
  currency: Currency,
  recurringCheckEnabled?: boolean,
  selectedDuration?: string,
  selectedLocation?: string,
  selectedTerm?: string,
  selectedTermDuration?: string
) => {
  //sku level
  const productId: string[] = selectedListKey.split('/');
  const isValid = productId.length >= 2;
  let availabilityId = productId.length >= 3 ? productId[2] : undefined;
  let termId;
  if (selectedSkuRow.Availabilities.length === 1 && availabilityId === undefined) {
    availabilityId = selectedSkuRow.Availabilities[0].AvailabilityId;
  }
  if (
    selectedSkuRow.Availabilities.length &&
    selectedSkuRow.Availabilities[0].Terms &&
    selectedSkuRow.Availabilities[0].Terms.length === 1
  ) {
    termId = selectedSkuRow.Availabilities[0].Terms[0].TermId;
  }
  if (isValid) {
    const updatedLineItem: LineItem = {
      ...lineItem,
      productIdentifier: {
        ...lineItem.productIdentifier,
        productId: productId[0],
        skuId: productId[1],
        availabilityId,
        availabilityTermId: termId,
        action,
      },
      pricingCurrency: currency,
      isReadyForPricing: !!availabilityId && action !== 'consume',
      purchaseInstruction: {
        ...lineItem.purchaseInstruction,
        isAutoRenew: recurringCheckEnabled,
      },
      extendedProperties: {
        ...lineItem.extendedProperties,
        userPreferences: {
          ...oc(lineItem).extendedProperties.userPreferences(),
          duration: selectedDuration,
          location: selectedLocation,
          term: selectedTerm,
          termDurationKey: selectedTermDuration,
          skuTitle: selectedSkuRow.Title || undefined,
          conditions: undefined,
        },
      },
    };
    if (updatedLineItem.extendedProperties && updatedLineItem.extendedProperties.userPreferences) {
      const lineItemForCreation = {
        ...updatedLineItem,
        id: undefined,
        extendedProperties: {
          ...updatedLineItem.extendedProperties,
          userPreferences: JSON.stringify(
            updatedLineItem.extendedProperties.userPreferences
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any,
        },
      };
      const request = createLineItemsRequest(proposal, [lineItemForCreation]);
      createAndHydrateLineItems(request);
    }
  } else {
    loggerService.error({ error: new Error('invalid product identifier') });
  }
};

export const addFilterableConfiguration = (
  hydratedProduct: HydratedProduct,
  productFilters: ProductFilter[],
  lineItem: LineItem,
  lineItems: CreateLineItemProperties[],
  proposal: Proposal,
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  createAndHydrateLineItems: (request: CreateLineItemsRequest) => void,
  currency: Currency,
  selectedDurationKey?: string,
  selectedLocationKey?: string,
  selectedListKey?: string,
  selectedSkuRow?: SkuRow,
  recurringCheckEnabled?: boolean,
  selectedTermKey?: string,
  selectedTermsDuration?: string
) => {
  const action = getActionValue(productFilters, selectedDurationKey);
  if (selectedListKey && selectedSkuRow) {
    //sku level
    return addSkuLevelConfiguration(
      lineItem,
      proposal,
      action,
      selectedListKey,
      selectedSkuRow,
      createAndHydrateLineItems,
      currency,
      recurringCheckEnabled,
      selectedDurationKey,
      selectedLocationKey,
      selectedTermKey,
      selectedTermsDuration
    );
  } else {
    const conditions = buildConditions(
      productFilters,
      hydratedProduct,
      qualifyingSkuAvailabilities,
      selectedDurationKey,
      selectedLocationKey
    );
    const productId = hydratedProduct.ProductId;
    const qualifyingSkuCount = removePrivateAndNonPurchasableConsumableSkus(
      qualifyingSkuAvailabilities,
      hydratedProduct as Product
    );
    const skuCount =
      qualifyingSkuCount && qualifyingSkuCount.length > 1 ? qualifyingSkuCount.length : undefined;
    const updatedLineItem: LineItem = {
      ...lineItem,
      productIdentifier: {
        ...lineItem.productIdentifier,
        productId,
        skuId: undefined,
        availabilityId: undefined,
        action,
      },
      pricingCurrency: currency,
      purchaseInstruction: {
        ...lineItem.purchaseInstruction,
        isAutoRenew: recurringCheckEnabled,
      },
      extendedProperties: {
        ...lineItem.extendedProperties,
        userPreferences: {
          ...oc(lineItem).extendedProperties.userPreferences(),
          duration: selectedDurationKey,
          location: selectedLocationKey,
          skuCount,
          term: undefined,
          termDurationKey: undefined,
          skuTitle: undefined,
          conditions: conditions.length ? conditions : undefined,
        },
      },
    };
    const lineItemsToCreate = lineItems.concat({
      location: selectedLocationKey || '',
      duration: selectedDurationKey || '',
    });
    const request = createLineItemsRequestWithProperties(
      proposal,
      lineItemsToCreate,
      updatedLineItem,
      hydratedProduct
    );
    createAndHydrateLineItems(request);
  }
};

export const addUnfilterableConfiguration = (
  hydratedProduct: HydratedProduct,
  lineItem: LineItem,
  proposal: Proposal,
  selectedSkuRow: SkuRow,
  createAndHydrateLineItems: (request: CreateLineItemsRequest) => void,
  currency: Currency,
  selectedListKey: string,
  selectedTermsKey?: string,
  selectedTermsDuration?: string,
  recurringCheckEnabled?: boolean,
  isANegotiatedTerm?: boolean
) => {
  let action = 'purchase';
  const productId: string[] = selectedListKey.split('/');
  const isValid = productId.length >= 2;
  const availabilityForTerm =
    selectedTermsKey && getAvailabilityForTermId(selectedSkuRow.Availabilities, selectedTermsKey);
  let availabilityId =
    selectedSkuRow.Availabilities.length === 1 && productId.length >= 3
      ? productId[2]
      : availabilityForTerm && availabilityForTerm.AvailabilityId;
  let conditions: PricingInstructionCondition[] = [];
  if (selectedSkuRow.Availabilities && selectedSkuRow.Availabilities.length && !availabilityId) {
    const purchaseAvailability = getFirstPurchaseAvailabilities(selectedSkuRow.Availabilities);
    availabilityId = purchaseAvailability && purchaseAvailability.AvailabilityId;
  }
  if (availabilityId) {
    const units = getTermUnits(availabilityId, selectedSkuRow.Availabilities, selectedTermsKey);
    if (units && !isSupportOffer(hydratedProduct)) {
      const condition: PricingInstructionCondition = {
        type: 'equalAny',
        name: 'TermUnits',
        value: [units],
      };
      conditions = [condition];
    }
    const availability =
      selectedSkuRow.Availabilities &&
      selectedSkuRow.Availabilities.find(avail => avail.AvailabilityId === availabilityId);
    action = availability && hasAvailabilityConsumeAction(availability) ? 'consume' : 'purchase';
  }

  if (isValid) {
    const updatedLineItem: LineItem = {
      ...lineItem,
      supplementalTermReferenceData: isANegotiatedTerm
        ? `${lineItem.productIdentifier.productType}:${selectedSkuRow.Title}`
        : undefined,
      productIdentifier: {
        ...lineItem.productIdentifier,
        productId: productId[0],
        skuId: productId[1],
        availabilityId,
        availabilityTermId: selectedTermsKey || undefined,
        action,
      },
      pricingCurrency: currency,
      isReadyForPricing: true,
      purchaseInstruction: { ...lineItem.purchaseInstruction, isAutoRenew: recurringCheckEnabled },
      extendedProperties: {
        ...lineItem.extendedProperties,
        userPreferences: {
          ...(lineItem.extendedProperties && lineItem.extendedProperties.userPreferences),
          duration: undefined,
          location: undefined,
          term: selectedTermsKey,
          termDurationKey: selectedTermsDuration,
          skuTitle: selectedSkuRow.Title || undefined,
          conditions: conditions.length ? conditions : undefined,
          availabilityTermId: selectedTermsKey,
        },
      },
    };
    if (updatedLineItem.extendedProperties && updatedLineItem.extendedProperties.userPreferences) {
      const lineItemForCreation = {
        ...updatedLineItem,
        id: undefined,
        extendedProperties: {
          ...updatedLineItem.extendedProperties,
          userPreferences: JSON.stringify(
            updatedLineItem.extendedProperties.userPreferences
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any,
        },
      };
      const request = createLineItemsRequest(proposal, [lineItemForCreation]);
      createAndHydrateLineItems(request);
    }
  } else {
    loggerService.error({ error: new Error('invalid product identifier') });
  }
};

export const addConfiguration = (
  filters: ProductFilter[],
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  hydratedProduct: Product,
  lineItem: LineItem,
  createLineItems: CreateLineItemProperties[],
  proposal: Proposal,
  createAndHydrateLineItems: (request: CreateLineItemsRequest) => void,
  updateProposal: (proposalRequest: ProposalUpdateRequest) => void,
  selectedDurationKey?: string,
  selectedLocationKey?: string,
  selectedTermsKey?: string,
  selectedTermsDuration?: string,
  selectedStartDateKey?: string,
  selectedListKey?: string,
  selectedSkuRow?: SkuRow,
  recurringCheckEnabled?: boolean
) => {
  const isUnfilterable = isUnfilterableProduct(
    filters,
    qualifyingSkuAvailabilities,
    hydratedProduct
  );
  const currency = oc(proposal).header.pricingContext.billingCurrency('USD');
  const previouslyDiscounted = hasDiscount(lineItem);
  let startDate = undefined;
  if (selectedStartDateKey && isPasses(hydratedProduct)) {
    if (selectedStartDateKey !== 'order placement') {
      startDate = moment.utc(selectedStartDateKey).toDate();
    }
    lineItem.purchaseInstruction = { ...lineItem.purchaseInstruction, termStartDate: startDate };
  }
  const newLineItem: LineItem = {
    ...lineItem,
    startDate: !previouslyDiscounted ? lineItem.startDate : undefined,
    endDate: !previouslyDiscounted ? lineItem.endDate : undefined,
    duration: !previouslyDiscounted ? lineItem.duration : undefined,
    pricingInstruction: !previouslyDiscounted ? lineItem.pricingInstruction : undefined,
  };

  const createLineItemsWithUpdatedDuration = createLineItems.map(createLineItem => ({
    location: createLineItem.location,
    duration: selectedDurationKey,
  }));
  if (isAccessPass(hydratedProduct)) {
    selectedListKey &&
      addAccessPassConfiguration(
        newLineItem,
        proposal,
        createAndHydrateLineItems,
        currency,
        selectedListKey,
        undefined,
        selectedSkuRow
      );
  } else if (isUnfilterable) {
    const isANegotiatedTerm = isNegotiatedTerm(hydratedProduct);
    selectedSkuRow &&
      selectedListKey &&
      addUnfilterableConfiguration(
        hydratedProduct,
        newLineItem,
        proposal,
        selectedSkuRow,
        createAndHydrateLineItems,
        currency,
        selectedListKey,
        selectedTermsKey || selectedDurationKey,
        selectedTermsDuration,
        recurringCheckEnabled,
        isANegotiatedTerm
      );
  } else {
    addFilterableConfiguration(
      hydratedProduct,
      filters,
      newLineItem,
      createLineItemsWithUpdatedDuration,
      proposal,
      qualifyingSkuAvailabilities,
      createAndHydrateLineItems,
      currency,
      selectedDurationKey,
      selectedLocationKey,
      selectedListKey,
      selectedSkuRow,
      recurringCheckEnabled,
      selectedTermsKey,
      selectedTermsDuration
    );
  }
};
//#endregion
