import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  TextBody,
  TextBodyLarge,
  XLargeIcon,
} from 'components/ions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { DialogStatusFooter, DialogStatusType } from '../Shared';
import { styles as submittedStatusStyles } from '../Shared/DialogStatus.styles';

const styles = (theme: ThemeProps) => {
  return {
    ...submittedStatusStyles(theme),
    body: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    footer: {
      marginTop: 12,
    },
    explanationMessage: {
      marginBottom: 12,
    },
    message: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    successMessage: {
      marginTop: 84,
    },
  };
};

interface OwnProps {
  height: number;
  width: number;
  closeDialog: () => void;
  onShowExplanation: () => void;
}
export type Props = OwnProps & WithStyles<typeof styles>;

const UnsavedCommentViewFeature: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  const Header = (
    <DialogHeader
      closeButtonClass={classes.customDialogCloseButton}
      closeButtonContainerClass={classes.customDialogCloseButtonNoTitle}
      dataAutomationId="unsavedComment"
      dialogClose={props.closeDialog}
    />
  );

  const Body = (
    <div className={`${classes.customDialogBody} ${classes.body}`}>
      <div className={`${classes.message} ${classes.successMessage}`}>
        <div className={`${classes.customDialogIconContainer} ${classes.customDialogSuccessIcon}`}>
          <XLargeIcon addClass={classes.customDialogIcon} iconName="Accept" />
        </div>
        <div>
          <TextBody addClass={classes.text}>
            {t('quote::The quote has successfully been submitted for approval.')}
          </TextBody>
        </div>
      </div>
      <div className={`${classes.message}`}>
        <TextBodyLarge addClass={classes.explanationMessage}>
          {t('quote::We were unable to save the entered explanation.')}
        </TextBodyLarge>
        <PrimaryButton
          dataAutomationId="showExplanationButton"
          text={t('quote::Show explanation')}
          onClick={props.onShowExplanation}
        />
      </div>
    </div>
  );

  const Footer = (
    <DialogStatusFooter
      addClass={classes.footer}
      closeDialog={props.closeDialog}
      status={DialogStatusType.Success}
    />
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={props.height}
      width={props.width}
    />
  );
};

export const UnsavedCommentView = withStyles(styles)(UnsavedCommentViewFeature);
