import axios from 'axios';
import { TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader, getCV, getTestHeader } from '../utils';
import { EdgeSearchConfig, endpoints } from './config';
import { OrganizationSearchResponse } from './types';

async function getHeaders(config: EdgeSearchConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'MS-CorrelationId': createGuid(),
    'MS-CV': getCV(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function searchOrganizations(
  searchString: string,
  config: EdgeSearchConfig,
  size?: number
): Promise<OrganizationSearchResponse> {
  const headers = await getHeaders(config);
  const url = endpoints[config.environment];
  const params = {
    organization: searchString,
    size: size !== undefined ? size : 10,
    excludedOrganizationSubType: 'pcn',
  };
  const response = await axios.get<OrganizationSearchResponse>(url, { params, headers });
  return response.data;
}
