import { Environment } from 'services/proposal/config';

// --- Regex
export const emailIsValid = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // eslint-disable-line no-useless-escape
export const emailIsValidStrict = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line no-useless-escape

/**
 * Links available to redirect user to open quote in Business Store site
 */
export const shareLinkBusinessStore = {
  [Environment.Prod]:
    'https://businessstore.microsoft.com/store/quotelanding?QuoteID={quoteId}&flagset=quote',
  [Environment.Int]:
    'https://businessstore.microsoft.com/en-us/store/organizationreview?QuoteID={quoteId}?fromquotelanding=true&flagset=quote&setflight=wsfb_testheader',
};
