import { AddLineItemMutationStatus } from 'features-apollo/ActiveQuoteContext';
import { getDiscountSummaryShort } from 'features-apollo/quote/components/DiscountCard/utils';
import {
  getPurchaseProductQuantityBoundaries,
  isPurchaseProductQuantifiable,
} from 'features-apollo/quote/components/Lists/utils';
import {
  hasAnyLineItemErrorMessage,
  isLineItemProductFailedToLoad,
} from 'features-apollo/quote/selectors/lineItem';
import { getFlightIsEnabled } from 'features/app/selectors';
import { clearItemSelection, toggleItemSelection } from 'features/proposal/actions';
import { getLastSelectedId, getSelectedIds } from 'features/proposal/selectors';
import { DiscountLineItem, LineItem, MonetaryLineItem, PurchaseLineItem } from 'generated/graphql';
import { useClearSelectionOnUnmount } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { AddSharedDiscountsButton, DeleteLineItemsButton } from '../ActionBarButtons';
import { QuoteList, QuoteListSection } from '../QuoteList';
import {
  DiscountRow,
  LineItemRowProps,
  ProductLineItemContentAlignment,
  PurchaseRow,
} from '../Rows';
import { MonetaryRow } from '../Rows/MonetaryRow/MonetaryRow';
import { LoadingRow } from '../Rows/QuoteListRow';
import { QuoteListColumn } from '../types';
import { ProductListFooter } from './ProductListFooter/ProductListFooter';
import { configCardIsOpen } from './selectors';
import { getProductListRowMessage, isLineItemWarningState } from './utils';

export const PRODUCTS_SECTION_ID = 'product-section';
export const SHARED_DISCOUNTS_SECTION_ID = 'shared-discounts-list';

export interface ProductListProps {
  /**
   * Function that updates the quantity to the new one
   */
  updateQuantity?: (newQuantity: number, lineItemId: string) => void;
  /**
   * Products found in quote's line items
   */
  productsLineItems: (PurchaseLineItem | DiscountLineItem | MonetaryLineItem)[];
  /**
   * Shared discounts added to the quote
   */
  sharedDiscountsLineItems: DiscountLineItem[];
  /**
   * Identifies quote for agreement preview and delete line items.
   */
  quoteId: string;
  /**
   * Currency of all price information in quote
   */
  billingCurrency: string;
  /**
   * Total price of quote
   */
  totalPrice: number;
  /**
   * Exchange rate for Azure products currency
   */
  exchangeRate?: number;
  /**
   * Date of exchange rate availability.
   */
  exchangeDate?: Date;
  /**
   * Disables all features except view agreements.
   */
  isQuoteReadOnly: boolean;
  /**
   * Hides action bar
   */
  isQuoteHRDD: boolean;
  /**
   * Whether the agreement type in the quote is legacy or not
   */
  isQuoteLegacy: boolean;
  /**
   * Use to displays shimmers while waiting for line items to load
   */
  loading?: boolean;
  /**
   * Parent organization that provides the shared discounts
   */
  parentOrganizationName?: string;
  /**
   * Whether there are discounts available to be shared and added to the quote
   */
  canShareLeadDiscounts: boolean;
  /**
   * Enables the drag-and-drop feature
   */
  dndEnabled?: boolean;
  /**
   * Detects is draggind event is happening.
   * TODO: jepagan - check if this can be detect independently from list component.
   */
  isDragEvent?: boolean;
  /**
   * Function to execute when the delete button is pressed
   */
  onDelete?: (ids: string[]) => void;
  /**
   * Function to execute when add shared discounts button is pressed
   */
  onAddSharedDiscounts?: () => void;
  /** indicates if a new row item is being added */
  addLineItemStatus?: AddLineItemMutationStatus;
}

const contentAlignment: ProductLineItemContentAlignment = {
  name: { flex: 3 },
  quantity: { alignText: 'center' },
  listPrice: { alignText: 'flex-end' },
  customerPrice: { alignText: 'flex-end' },
  discount: { alignText: 'flex-start' },
  amount: { alignText: 'flex-end' },
  configCardButton: { flex: 6 },
  discountConfigCardButton: { flex: 2 },
};

/**
 * Needed for selection feature
 * TODO - jepagan: explore if this feature can be independent from lists
 *
 * @param state redux store
 */
export const mapStateToProps = (state: RootState) => {
  return {
    selectedIds: getSelectedIds(state),
    lastSelectedId: getLastSelectedId(state),
    enableNonRegionalSelectionForMeters: getFlightIsEnabled(
      state,
      Flight.allowUnspecifiedOptionInLocationFilters
    ),
    configurationCardIsOpen: configCardIsOpen(state),
  };
};

/**
 * Needed for selection feature
 * TODO - jepagan: explore if this feature can be independent from lists
 */
const dispatchProps = {
  toggleItemSelection,
  clearItemSelection,
};

type Props = ProductListProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const ProductList: React.FC<Props> = props => {
  const { t } = useTranslation();
  useClearSelectionOnUnmount(props.clearItemSelection);

  const actionBarButtons: JSX.Element[] = [
    <DeleteLineItemsButton
      isQuoteReadOnly={props.isQuoteReadOnly}
      key="delete-line-items-btn"
      quoteId={props.quoteId}
      onClick={props.onDelete}
    />,
  ];

  if (props.canShareLeadDiscounts) {
    actionBarButtons.push(
      <AddSharedDiscountsButton
        canShareLeadDiscounts={props.canShareLeadDiscounts}
        isQuoteReadOnly={props.isQuoteReadOnly}
        key="add-shared-discounts-btn"
        quoteId={props.quoteId}
        onClick={props.onAddSharedDiscounts}
      />
    );
  }

  const columnsHeaders: QuoteListColumn[] = [
    {
      id: 'name-column-header',
      content: t('quote::Name'),
      ...contentAlignment.name,
    },
    {
      id: 'quantity-column-header',
      content: t('quote::Quantity'),
      ...contentAlignment.quantity,
    },
    {
      id: 'list-price-column-header',
      content: t('quote::List'),
      ...contentAlignment.listPrice,
    },
    {
      id: 'customer-price-column-header',
      content: t('quote::Customer'),
      ...contentAlignment.customerPrice,
    },
    {
      id: 'discount-column-header',
      content: t('quote::Discount'),
      ...contentAlignment.discount,
    },
    {
      id: 'amount-column-header',
      content: t('quote::Amount'),
      ...contentAlignment.amount,
    },
  ];

  const itemsList: LineItem[] = [...props.productsLineItems, ...props.sharedDiscountsLineItems];

  const productsRows = props.productsLineItems.map((productLineItem, index) => {
    const lineItemRowProps: LineItemRowProps = {
      configuration: oc(productLineItem).configurationSummary.display(),
      dataAutomationId: productLineItem.title || undefined,
      configurable: productLineItem.isConfigurable,
      errored: hasAnyLineItemErrorMessage(productLineItem),
      isInWarnState: isLineItemWarningState(productLineItem),
      message: getProductListRowMessage(productLineItem),
      productTitle: productLineItem.title,
      productUnavailable: isLineItemProductFailedToLoad(productLineItem),
      quoteId: props.quoteId,
      readOnly: props.isQuoteReadOnly,
    };

    switch (productLineItem.__typename) {
      case 'PurchaseLineItem': {
        return (
          <PurchaseRow
            {...lineItemRowProps}
            amount={productLineItem.amount}
            contentAlignment={contentAlignment}
            currency={props.billingCurrency}
            customerPrice={productLineItem.customerPrice}
            discountable={oc(productLineItem).product.isPotentiallyDiscountable(false)}
            discountConfiguration={getDiscountSummaryShort(productLineItem)}
            isReadyForPricing={productLineItem.isReadyForPricing}
            itemIds={itemsList.map(item => item.id)}
            key={index}
            lastSelectedId={props.lastSelectedId}
            lineItem={productLineItem}
            lineItemId={productLineItem.id}
            listPrice={{
              price: productLineItem.listPrice,
              currency: productLineItem.pricingCurrency || props.billingCurrency,
            }}
            oneTimeDiscount={productLineItem.oneTimeDiscount}
            quantifiable={isPurchaseProductQuantifiable(productLineItem)}
            quantity={productLineItem.quantity}
            quantityBoundaries={getPurchaseProductQuantityBoundaries(productLineItem)}
            selectedIds={props.selectedIds}
            updateQuantity={props.updateQuantity}
            onCleared={props.clearItemSelection}
            onSelected={props.toggleItemSelection}
          />
        );
      }
      case 'MonetaryLineItem':
        return (
          <MonetaryRow
            {...lineItemRowProps}
            amount={productLineItem.purchaseTermUnits ? +productLineItem.purchaseTermUnits : null}
            contentAlignment={contentAlignment}
            currency={props.billingCurrency}
            itemIds={itemsList.map(item => item.id)}
            key={index}
            lastSelectedId={props.lastSelectedId}
            lineItem={productLineItem}
            lineItemId={productLineItem.id}
            selectedIds={props.selectedIds}
            onCleared={props.clearItemSelection}
            onSelected={props.toggleItemSelection}
          />
        );
      case 'DiscountLineItem': {
        return (
          <DiscountRow
            {...lineItemRowProps}
            contentAlignment={contentAlignment}
            discount={oc(productLineItem).discount.percentage()}
            discountConfiguration={getDiscountSummaryShort(productLineItem)}
            itemIds={itemsList.map(item => item.id)}
            key={index}
            lastSelectedId={props.lastSelectedId}
            lineItem={productLineItem}
            lineItemId={productLineItem.id}
            selectedIds={props.selectedIds}
            onCleared={props.clearItemSelection}
            onSelected={props.toggleItemSelection}
          />
        );
      }
      default:
        return null;
    }
  });

  if (props.addLineItemStatus && props.addLineItemStatus.loading) {
    for (let i = 0; i < oc(props).addLineItemStatus.loadingCount(1); i++) {
      productsRows.push(<LoadingRow id={`loadingLineItem-${i}`} />);
    }
  }

  const sharedDiscountRows = props.sharedDiscountsLineItems.map((sharedDiscountLineItem, index) => {
    const errored = hasAnyLineItemErrorMessage(sharedDiscountLineItem);
    const message = getProductListRowMessage(sharedDiscountLineItem);
    const productUnavailable = isLineItemProductFailedToLoad(sharedDiscountLineItem);

    return (
      <DiscountRow
        configurable={sharedDiscountLineItem.isConfigurable}
        configuration={oc(sharedDiscountLineItem).configurationSummary.display()}
        contentAlignment={contentAlignment}
        discount={oc(sharedDiscountLineItem).discount.percentage()}
        discountConfiguration={getDiscountSummaryShort(sharedDiscountLineItem)}
        errored={errored}
        itemIds={itemsList.map(item => item.id)}
        key={index}
        lastSelectedId={props.lastSelectedId}
        lineItem={sharedDiscountLineItem}
        lineItemId={sharedDiscountLineItem.id}
        message={message}
        productTitle={sharedDiscountLineItem.title}
        productUnavailable={productUnavailable}
        quoteId={props.quoteId}
        readOnly
        selectedIds={props.selectedIds}
        onCleared={props.clearItemSelection}
        onSelected={props.toggleItemSelection}
      />
    );
  });

  const productsSection = productsRows.length ? (
    <QuoteListSection dataAutomationId={PRODUCTS_SECTION_ID}>{productsRows}</QuoteListSection>
  ) : null;

  const sharedDiscountsSection = sharedDiscountRows.length ? (
    <QuoteListSection
      dataAutomationId={SHARED_DISCOUNTS_SECTION_ID}
      description={
        props.parentOrganizationName && props.parentOrganizationName.trim()
          ? t(
              'quote::The shared discounts below were previously negotiated by the parent account, {{parentOrganizationName}}, and the expiration date cannot be adjusted. To offer these shared discounts to the affiliate, or to apply additional discounts, there should be alignment with the Microsoft account team for the parent account, including email support from the commercial executive for any discounts being offered to the affiliate.',
              { parentOrganizationName: props.parentOrganizationName }
            )
          : t(
              'quote::The shared discounts below were previously negotiated by the parent account and the expiration date cannot be adjusted. To offer these shared discounts to the affiliate, or to apply additional discounts, there should be alignment with the Microsoft account team for the parent account, including email support from the commercial executive for any discounts being offered to the affiliate.'
            )
      }
      title={t('quote::Shared discounts')}
    >
      {sharedDiscountRows}
    </QuoteListSection>
  ) : null;

  const footer =
    productsRows.length || sharedDiscountRows.length ? (
      <ProductListFooter
        currency={props.billingCurrency}
        exchangeDate={props.exchangeDate}
        exchangeRate={props.exchangeRate}
        isQuoteLegacy={props.isQuoteLegacy}
        totalPrice={props.totalPrice}
      />
    ) : (
      undefined
    );

  const watermark = [
    t('quote::To add a product to the quote, drag or click on a product in the Product finder.'),
    t('quote::All changes are automatically saved.'),
  ];

  return (
    <QuoteList
      actionBarButtons={actionBarButtons}
      columnsHeaders={columnsHeaders}
      configurationCardIsOpen={props.configurationCardIsOpen}
      dndEnabled={props.dndEnabled}
      footer={footer}
      isDragEvent={props.isDragEvent}
      isQuoteHRDD={props.isQuoteHRDD}
      isQuoteReadOnly={props.isQuoteReadOnly}
      loading={props.loading}
      showWatermark={!productsSection}
      watermark={watermark}
      onClearSelection={props.clearItemSelection}
    >
      {productsSection}
      {sharedDiscountsSection}
    </QuoteList>
  );
};

/**
 * UI representation of terms found in line items and available features for them
 */
export const ProductListConnected = connect(mapStateToProps, dispatchProps)(ProductList);
