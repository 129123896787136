import React, { FC, PropsWithChildren, ReactNode } from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { SupportedTheme } from 'styles/theme';

const styles = (theme: SupportedTheme) => ({
  app: {
    backgroundColor: theme.palette.backgroundCommon,
    display: 'flex',
    flexGrow: 1,
    height: 0,
  },
  aside: {
    backgroundColor: theme.palette.backgroundMain,
    borderRight: `1px solid ${theme.palette.backgroundDivider}`,
    boxShadow: `-2px 18px 18px ${theme.palette.shadowColor}`,
    zIndex: theme.zIndex.sideBar,
  },
  main: {
    overflow: 'auto',
    flexGrow: 1,
    display: 'flex',
  },
});

interface Props extends WithStyles<typeof styles> {
  name: string;
  aside?: ReactNode;
}

const WorkAreaLayout: FC<Props> = (props: PropsWithChildren<Props>) => (
  <div className={props.classes.app}>
    <aside className={props.classes.aside}>{props.aside}</aside>
    <main className={props.classes.main} id="PrimaryArea">
      {props.children}
    </main>
  </div>
);

export default withStyles(styles)(WorkAreaLayout);
