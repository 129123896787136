import { TextBodySmall, TextTitleSecondary } from 'components';
import { FocusZone } from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export interface QuoteListSectionProps {
  /**
   * Display on header section
   */
  title?: string;
  /**
   * Display below the title. Can be use to describe section content.
   */
  description?: string;
  /**
   * Identifies the content container
   */
  dataAutomationId?: string;
}

const styles = (theme: ThemeProps) => {
  return {
    headerSection: {
      padding: '40px 16px 14px',
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    },
    description: {
      display: 'block',
      maxWidth: 550,
    },
  };
};

type Props = QuoteListSectionProps & WithStyles<typeof styles>;

const QuoteListSectionUnstyled: React.FC<Props> = props => {
  const title =
    props.title && props.title.trim() ? (
      <TextTitleSecondary>{props.title}</TextTitleSecondary>
    ) : null;

  const description =
    props.description && props.description.trim() ? (
      <TextBodySmall addClass={props.classes.description}>{props.description}</TextBodySmall>
    ) : null;

  const content = (
    <FocusZone data-automation-id={props.dataAutomationId}>{props.children}</FocusZone>
  );

  if (title || description) {
    return (
      <>
        <div className={props.classes.headerSection}>
          {title}
          {description}
        </div>
        {content}
      </>
    );
  } else {
    return content;
  }
};

export const QuoteListSection = withStyles(styles)(QuoteListSectionUnstyled) as React.FC<
  QuoteListSectionProps
>;
