import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { Suggestion } from './Autosuggest';

//TODO: cameneks, find a way to improve the focus box on firefox too, it does not include the padding currently.
const autoSuggestListRowStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    row: {
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      textAlign: 'left',
      '&:hover, &:focus': {
        backgroundColor: palette.backgroundCommon,
      },
    },
  };
};

export interface AutosuggestListRowProps<T> {
  onClickHandler: (index: number) => void;
  componentRef?: React.RefObject<HTMLButtonElement>;
  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onRenderRow: (suggestion: Suggestion<T>) => JSX.Element;
  suggestion: Suggestion<T>;
  key: string;
  index: number;
  tabIndex: number;
  dataAutomationId?: string;
}

type Props<T> = AutosuggestListRowProps<T> & WithStyles<typeof autoSuggestListRowStyles>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AutosuggestListRowUnstyled = <T extends any>(props: Props<T>) => {
  const onClick = () => {
    props.onClickHandler(props.index);
  };
  return (
    <button
      aria-label={props.suggestion.ariaLabel}
      className={props.classes.row}
      data-automation-id={props.dataAutomationId}
      key={props.key}
      ref={props.componentRef}
      tabIndex={props.tabIndex}
      onClick={onClick}
      onKeyDown={props.onKeyDown}
    >
      {props.onRenderRow(props.suggestion)}
    </button>
  );
};

export const AutosuggestListRow = withStyles(autoSuggestListRowStyles)(
  AutosuggestListRowUnstyled
) as <T>(props: AutosuggestListRowProps<T>) => JSX.Element;
