import { ButtonMenuProps, IconMenuButton, ShowMore } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { referralSummaryDetailStyles } from './ReferralSummaryDetail.styles';

interface Props {
  subscriptions: string[];
  showLessText: string;
  showMoreText: string;
  titleText: string;
  disabled?: boolean;
}

interface SubscriptionBlockProps {
  subscription: string;
  disabled?: boolean;
  classes: { divider: string; dotsButton: string; flexRow: string; subscriptionInput: string };
}

type SubsriptionCopyProps = WithStyles<typeof referralSummaryDetailStyles> & Props;

const SubscriptionCopyBlock: React.FC<SubscriptionBlockProps> = props => {
  const { subscription, classes } = props;
  const { t } = useTranslation();
  const inputRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);

  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
  };

  const openCustomerPortal = {
    key: 'open-customer-portal',
    icon: 'cloud',
    onClick: () => {
      const link = `https://caicustomers.microsoftonline.com/#subscription/${subscription}`;
      window.open(link, '_blank');
    },
    text: t('quote::Open in C+AI Customer Portal'),
    dataAutomationId: `openCustomerProfile-${subscription}`,
  };

  const copySubscription = {
    key: 'copy-subscription-id',
    icon: 'copy',
    onClick: () => {
      handleCopyClick();
    },
    text: t('quote::Copy subscription ID'),
    dataAutomationId: `copySubscriptionID-${subscription}`,
  };

  const buttons: ButtonMenuProps[] = [openCustomerPortal, copySubscription];

  return (
    <div className={classes.flexRow}>
      <input
        className={classes.subscriptionInput}
        readOnly
        ref={inputRef}
        title={subscription}
        type="text"
        value={subscription}
      />
      <div className={classes.divider} />
      <IconMenuButton
        addClass={classes.dotsButton}
        dataAutomationId={`subscriptionIdMenu-${subscription}`}
        disabled={props.disabled}
        iconName="More"
        menuId="subscription-menu"
        menuProps={buttons}
      />
    </div>
  );
};

const ReferralSubscriptionsUnstyled: React.FC<SubsriptionCopyProps> = props => {
  const { subscriptions, disabled } = props;
  const maxNumber = 10;
  const numberToShow = 5;

  if (subscriptions && subscriptions.length > maxNumber) {
    const preview = subscriptions.slice(0, numberToShow);
    const showMore = subscriptions.slice(numberToShow, subscriptions.length);

    const previewBlock = (
      <>
        {preview.map(subscription => (
          <SubscriptionCopyBlock
            classes={props.classes}
            disabled={disabled}
            key={subscription}
            subscription={subscription}
          />
        ))}
      </>
    );

    const showMoreBlock = (
      <>
        {showMore.map(subscription => (
          <SubscriptionCopyBlock
            classes={props.classes}
            disabled={disabled}
            key={subscription}
            subscription={subscription}
          />
        ))}
      </>
    );

    return (
      previewBlock &&
      showMoreBlock && (
        <ShowMore
          changeLinkText={props.showLessText}
          id={props.titleText}
          initialLinkText={props.showMoreText}
          linkId="showMoreLink"
          moreMessage={showMoreBlock}
          previewMessage={previewBlock}
          textId="showMoreText"
        />
      )
    );
  } else {
    const result =
      subscriptions &&
      subscriptions.map(subscription => (
        <SubscriptionCopyBlock
          classes={props.classes}
          disabled={disabled}
          key={subscription}
          subscription={subscription}
        />
      ));
    return <>{result}</>;
  }
};

export const ReferralSubscriptions = withStyles(referralSummaryDetailStyles)(
  ReferralSubscriptionsUnstyled
);
