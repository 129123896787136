import {
  ChoiceGroup,
  ErrorMessage,
  InfoButton,
  LinkEmail,
  MediumIcon,
  SecondaryButton,
  TextBody,
  TextBodySmall,
  VerificationField,
} from 'components';
import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import { WizardBodyProps } from 'features-apollo/quote/components/Wizards/shared';
import { AccountTenant, TenantProfile, TenantType } from 'generated/graphql';
import i18next from 'i18next';
import { DirectionalHint, IChoiceGroupOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import { useLazyQuery } from '@apollo/react-hooks';

import { GetTenant } from '../queries';
import { invalidTenantFormatMessage, onValidateTenant, ViralTenantMessage } from '../Shared';
import { choiceGroupStyles, otherOptionStyles, tenantStyles } from './Tenant.styles';

export const TenantHeaderString = (hasExistingTenants: boolean, changingTenant: boolean) => {
  if (hasExistingTenants) {
    if (changingTenant) {
      return i18next.t('quote::Change tenant');
    } else {
      return i18next.t('quote::Select tenant');
    }
  } else {
    return i18next.t('quote::Add tenant');
  }
};

export interface TenantData extends AccountTenant {
  userSearchInput?: string;
}

export interface TenantBodyProps extends WizardBodyProps<TenantData> {
  accountId: string;
  accountTenants: AccountTenant[];
  changingTenant?: boolean;
  dataAutomationId?: string;
  preventAdditionalTenant: boolean;
  workAccount?: string;
  onChangeTenantChoice?: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => void;
}

type Props = TenantBodyProps & WithStyles<typeof tenantStyles>;

const TenantBodyUnstyled: React.FC<Props> = props => {
  const {
    accountId,
    classes,
    accountTenants,
    initialData,
    preventAdditionalTenant,
    onChangeTenantChoice,
    onInvalid,
    onValid,
  } = props;
  const { t } = useTranslation();
  const OTHER_OPTION = 'other';

  //#region extract props from Account
  // const existingTenantIds = accountTenants.map(tenant => {
  //   return tenant.tenantId;
  // });
  const hasExistingTenants = !!accountTenants.length;

  let initialSelectedTenant: string | undefined;
  let initialCurrentValue;

  if (initialData) {
    const selectedExistingTenant = accountTenants.find(
      tenant => tenant.tenantId === initialData.tenantId
    );
    // The user's previous selection was one of the tenants already associated in the Account
    if (selectedExistingTenant) {
      initialSelectedTenant = selectedExistingTenant.tenantId;
    } // The user selected to use an existing tenant that is not associated with any account yet
    else if (initialData.userSearchInput) {
      initialSelectedTenant = OTHER_OPTION;
      initialCurrentValue = initialData.userSearchInput;
    }
  }
  //#endregion

  const [verifiedTenant, setVerifiedTenant] = React.useState<AccountTenant | undefined>();
  const [lastVerified, setLastVerified] = React.useState<string | undefined>(initialCurrentValue);
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(initialCurrentValue);
  const [tenantChoice, setTenantChoice] = React.useState<string | undefined>(() => {
    if (initialData) {
      onValid(initialData);
    }
    return initialSelectedTenant;
  });
  const [viralError, setViralError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [tenantInUse, setTenantInUse] = React.useState<boolean>(false);

  // let optionKeys: string[];
  // if (hasExistingTenants) {
  //   optionKeys = [...existingTenantIds, OTHER_OPTION];
  // } else {
  //   optionKeys = [OTHER_OPTION, NEW_OPTION];
  // }

  //#region Messages
  const tenantNotFound = t('quote::This tenant does not exist or is not valid.');
  const friendlyTenantMsg = t(
    'quote::This tenant is not valid. Please reach out to the customer to confirm their Azure or Office tenant.'
  );

  const tenantInUseMessage = props.changingTenant ? (
    <Trans ns="quote">
      We found the tenant, but it is in use by a different account. We recommend you use one of the
      tenants above which are already associated with this account. If you feel you absolutely need
      to use this new tenant, contact{' '}
      <LinkEmail
        displayText={t('quote::Quote Center Support')}
        email="qcsupport@microsoft.com"
        emailSubject={t('quote::Tenant in use')}
      />{' '}
      for additional guidance.
    </Trans>
  ) : (
    <Trans ns="quote">
      We found the tenant, but it is in use by a different account and tenants may only be used by a
      single account. Please try a different tenant; for example, try searching with the domain you
      used when creating the account. If you feel you absolutely need to use this tenant, contact{' '}
      <LinkEmail displayText={t('quote::Quote Center Support')} email="qcsupport@microsoft.com" />{' '}
      for additional guidance.
    </Trans>
  );

  const tenantInUseError = (
    <div className={classes.errorMessage}>
      <ErrorMessage mainMessage={t('quote::Tenant in use')}>{tenantInUseMessage}</ErrorMessage>
    </div>
  );

  const addTenantText = (
    <div>
      <div className={classes.paragraph}>
        <TextBodySmall addClass={classes.secondaryText}>
          {t(
            `quote::Identify which tenant the customer would like to use to manage their commerce experience with Microsoft such as accepting quotes, signing agreements and managing billing. If your customer doesn't have a tenant, contact Quote Center support for assistance.`
          )}
        </TextBodySmall>
      </div>
    </div>
  );

  const selectTenantText = (
    <TextBodySmall addClass={classes.secondaryText}>
      {t(
        `quote::Select the tenant the customer uses to manage their commerce experience with Microsoft, such as accepting quotes, signing agreements, and managing billing. It's recommended they use the same tenant for all such activity.`
      )}
    </TextBodySmall>
  );

  const workAccountWarning = (
    <div className={classes.warning}>
      <MediumIcon addClass={classes.warningIcon} iconName="Warning" />
      <div>
        <TextBodySmall addClass={`${classes.secondaryText} ${classes.warningText}`}>
          {t(
            'quote::The work account may not exist on the newly selected tenant, and you may need to enter a new work account.'
          )}
        </TextBodySmall>
      </div>
    </div>
  );
  //#endregion

  const [searchTenant, { loading: queryLoading }] = useLazyQuery<{ getTenant: TenantProfile }>(
    GetTenant,
    {
      onCompleted: data => {
        const tenantData = data.getTenant;
        const tenantAccount = getValueOrUndefined(tenantData.account);
        const tenantAssociatedToCurrentAccount = oc(tenantAccount).id() === accountId;
        if (!tenantData) {
          setErrorMessage(tenantNotFound);
          setViralError(false);
        } else if (tenantData.tenantType === TenantType.Consumer) {
          setErrorMessage(friendlyTenantMsg);
          setViralError(false);
        } else if (tenantData.tenantType === TenantType.Viral) {
          setViralError(true);
        } else {
          if (tenantAssociatedToCurrentAccount || !tenantAccount) {
            let tenant;
            if (tenantAccount) {
              tenant = tenantAccount.tenants.find(
                (tenant: AccountTenant) => tenant.tenantId === tenantData.tenantId
              );
            } else {
              tenant = { tenantId: tenantData.tenantId, tenantName: tenantData.tenantDisplayName };
            }
            onValid({ ...tenant, userSearchInput: lastVerified });
            setErrorMessage(undefined);
            setTenantInUse(false);
            setVerifiedTenant(tenant);
          } else {
            setTenantInUse(true);
          }
        }
      },
      onError: () => {
        setErrorMessage(tenantNotFound);
        setViralError(false);
      },
    }
  );

  const handleOnChangeTenantChoice = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined
  ) => {
    if (option) {
      setTenantChoice(option.key);
      if (option.key !== OTHER_OPTION) {
        onValid({
          tenantId: option.key,
        });
        setTenantInUse(false);
      }
    }
    onChangeTenantChoice && onChangeTenantChoice();
  };

  const verifiedTenantList = (
    <div className={classes.verifiedListContainer}>
      <div className={classes.listRow}>
        <TextBodySmall addClass={classes.secondaryText}>
          {t('quote::Verified tenant ID')}
        </TextBodySmall>
        <span className={classes.verifiedHeaderSpacer} />
        <TextBodySmall addClass={classes.secondaryText}>
          {t('quote::Verified tenant name')}
        </TextBodySmall>
      </div>
      <div>
        {verifiedTenant && (
          <div className={classes.listRow} key={verifiedTenant.tenantId}>
            <TextBody>{verifiedTenant.tenantId}</TextBody>
            <span className={classes.verifiedListSpacer} />
            <TextBody>{verifiedTenant.tenantName}</TextBody>
          </div>
        )}
      </div>
    </div>
  );
  const lastVerifiedAndCurrentIsSame = lastVerified === currentValue;

  const verificationMessage = () => {
    if (
      !queryLoading &&
      !tenantInUse &&
      verifiedTenant &&
      verifiedTenant.tenantId &&
      lastVerifiedAndCurrentIsSame
    ) {
      return verifiedTenantList;
    }
  };

  const tenantVerification = (addClass: string, label?: string, required?: boolean) => (
    <div className={addClass}>
      <VerificationField
        buttonDisabled={tenantChoice !== OTHER_OPTION}
        buttonText={t('quote::Verify')}
        dataAutomationId="tenantVerification"
        errorMessage={errorMessage}
        isError={!!errorMessage}
        isLoading={queryLoading}
        isVerified={!!verifiedTenant}
        lastVerified={lastVerified}
        textboxAutoFocus
        textboxLabel={label}
        textboxPlaceholder={t('quote::Enter the tenant domain or ID')}
        textboxRequired={required}
        textboxValue={currentValue}
        validationErrorMessage={invalidTenantFormatMessage()}
        verifiedStatusText={t('quote::Verified')}
        onChangeDebounced={(value?: string) => {
          setCurrentValue(value);
          setErrorMessage(undefined);
          setTenantChoice(OTHER_OPTION);
          setTenantInUse(false);
          setViralError(false);
        }}
        onValidate={onValidateTenant}
        onVerify={(value: string) => {
          setVerifiedTenant(undefined);
          setErrorMessage(undefined);
          onInvalid();
          searchTenant({ variables: { tenant: value } });
          setLastVerified(value);
          setTenantInUse(false);
          setViralError(false);
        }}
      />
      {verificationMessage()}

      {viralError && lastVerifiedAndCurrentIsSame && (
        <div className={classes.viralTenantError}>
          <ViralTenantMessage maxHeight="200px" />
        </div>
      )}
      {tenantInUse && tenantInUseError}
    </div>
  );

  const buildExistingTenantOptions = () => {
    const existingOptions: IChoiceGroupOption[] = accountTenants.map((tenant: AccountTenant) => ({
      key: tenant.tenantId,
      text: tenant.tenantId,
      // eslint-disable-next-line react/display-name
      onRenderLabel: () => {
        return (
          <div className={classes.existingTenantLabel}>
            <div className={classes.existingTenantIDLabel}>
              <TextBody>{tenant.tenantId}</TextBody>
            </div>
            <span className={classes.existingTenantSpacerLabel} />
            <TextBody>{tenant.tenantName}</TextBody>
          </div>
        );
      },
    }));
    !preventAdditionalTenant &&
      existingOptions.push({
        key: OTHER_OPTION,
        text: t('quote::Other tenant'),
        styles: otherOptionStyles,
        // eslint-disable-next-line react/display-name
        onRenderLabel: () => {
          return (
            <div className={classes.existingTenantLabel}>
              <TextBody>{t('quote::Other tenant')}</TextBody>
            </div>
          );
        },
        // eslint-disable-next-line react/display-name
        onRenderField: (
          props?: IChoiceGroupOption,
          render?: (props?: IChoiceGroupOption) => JSX.Element | null
        ) => {
          return (
            <div>
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              {render && render(props)}
              {tenantVerification(classes.pickerVerification)}
            </div>
          );
        },
      });

    return existingOptions;
  };

  const existingTenantHeader = (
    <div className={classes.existingTenantHeader}>
      <TextBodySmall addClass={classes.secondaryText}>
        {t('quote::Tenants associated with the account')}
      </TextBodySmall>
      {!preventAdditionalTenant && (
        <InfoButton
          ariaLabel={t('quote::Open information about modern account')}
          calloutProps={{
            closeButtonAriaLabel: t('Close'),
            directionalHint: DirectionalHint.bottomCenter,
            headline: t('quote::Modern Account'),
            maxWidth: 304,
          }}
          id="tenant-info-button"
        >
          <TextBody>
            {t(
              'quote::A Modern Account has one or more Billing Accounts. The tenant the customer uses to manage their commercial activity with Microsoft is stored on the Account. This is the list of all of the customer tenants which have been associated with their Modern Account; it may not be all the tenants they have.'
            )}
          </TextBody>
        </InfoButton>
      )}
      <span className={classes.existingTenantHeaderSpacer} />
      <TextBodySmall addClass={classes.secondaryText}>{t('quote::Tenant name')}</TextBodySmall>
    </div>
  );

  const tenantPicker = (
    <div className={hasExistingTenants ? '' : classes.choiceGroupNew}>
      {hasExistingTenants ? (
        <>
          {existingTenantHeader}
          <ChoiceGroup
            dataAutomationId="tenantChoiceGroup"
            options={buildExistingTenantOptions()}
            required
            selectedKey={tenantChoice}
            styles={choiceGroupStyles}
            onChange={handleOnChangeTenantChoice}
          />
        </>
      ) : (
        tenantVerification(classes.pickerVerification, t('quote::Existing tenant'), true)
      )}
    </div>
  );

  if (hasExistingTenants) {
    const showWarning =
      props.workAccount &&
      ((initialData && tenantChoice !== initialData.tenantId) || tenantChoice === OTHER_OPTION);
    return (
      <>
        <div className={classes.existingListHeight}>
          {selectTenantText}
          {tenantPicker}
          {!preventAdditionalTenant && showWarning && workAccountWarning}
        </div>
      </>
    );
  } else {
    return (
      <div>
        {addTenantText}
        {tenantPicker}
      </div>
    );
  }
};

export const TenantFooterButtons = (onButtonClick: () => void, buttonDisabled: boolean) => {
  return [
    <SecondaryButton
      dataAutomationId="tenantNextButton"
      disabled={buttonDisabled}
      key="tenant-next"
      text={i18next.t('quote::Next')}
      onClick={onButtonClick}
    />,
  ];
};

export const TenantBody = withStyles(tenantStyles)(TenantBodyUnstyled);
