import { ThemeProps } from 'styles';

export const favoriteButtonsSharedStyles = (theme: ThemeProps) => ({
  button: {
    border: `1px solid transparent`,
    padding: 0,
    position: 'relative',
    zIndex: 1,
    '&, &:hover, &:focus': {
      backgroundColor: 'transparent',
      borderRadius: 2,
      color: theme.palette.text,
      height: '100%',
      '& i': {
        color: theme.palette.text,
        fontSize: theme.fonts.fontSizes.medium,
      },
    },
    '&:hover': {
      borderColor: theme.palette.primary,
    },
  },
  hideIcon: {
    '& i': {
      opacity: 0.3,
    },
    '&:hover, &:focus': {
      '& .ms-Button-icon': {
        opacity: 1,
      },
    },
  },
  buttonContainer: {
    backgroundColor: 'transparent',
    height: '100%',
    position: 'absolute',
    right: 0,
  },
});
