import i18next from 'i18next';
import { ProductAttributeValue, SkuLocalizedProperty } from 'services/catalog/types';
import { ThemeKey } from 'styles';
import { FavoriteButton } from 'features/components/Favorites';
import { CatalogPageProduct } from 'features/catalog/types';
import * as React from 'react';
import { getUnique } from 'services/utils';

export const buildTagsString = (tags: ProductAttributeValue[]) =>
  tags.map(attr => attr.Name).join(', ');

export const buildTableProps = (skus: SkuLocalizedProperty[], t: i18next.TFunction) => {
  // TODO: masivaia - remove this once Cayman fixes duplicates bug
  const uniqueSkus = getUnique<SkuLocalizedProperty>(skus, sku => sku.SkuTitle);
  return {
    firstColumnName: t('home::Version'),
    secondColumnName: t('home::Description'),
    tableRows: uniqueSkus.map(sku => {
      return {
        name: sku.SkuTitle,
        description: sku.SkuDescription,
      };
    }),
  };
};

export const renderFavoriteButton = (
  product: CatalogPageProduct,
  classes: Record<string, string>,
  displayLabel?: boolean
) => (
  <FavoriteButton
    addButtonClass={classes.favoriteButton}
    addContainerClass={classes.favoriteButtonContainer}
    displayLabel={displayLabel}
    product={{
      productId: product.id,
      productName: product.title,
      productIdentifier: product.identifier,
    }}
    stopPropagation
  />
);

export const defaultImage = (theme: ThemeKey) =>
  theme === ThemeKey.light ? '/images/ImageNotFound_Light' : '/images/ImageNotFound_Dark';
