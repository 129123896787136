import {
  Autosuggest,
  ChoiceGroup,
  ComboBox,
  Dialog,
  InfoButton,
  Label,
  LinkButton,
  PrimaryButton,
  SectionSeparator,
  Suggestion,
  SystemError,
  TextBody,
  TextBodySmall,
  TextboxStandard,
  VerificationField,
} from 'components';
import { NotFound } from 'components/molecules/Autosuggest/NotFound';
import {
  AddressSuggestion,
  AddressSuggestionRow,
} from 'features/proposal/components/Dialogs/OrganizationDialogs/FindOrganizationDialog/AddressSuggestionRow';
import {
  getTranslatedMarketsAlphabetically,
  isReferralMarket,
  Market,
  MarketType,
} from 'features/proposal/supported-markets';
import { crmIDRegExp } from 'features/proposal/utils';
import { AzureType } from 'generated/graphql';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { proposalNameLengthLimit } from 'services/proposal/config';
import { oc } from 'ts-optchain';

import { useLazyQuery } from '@apollo/react-hooks';

import { ItemType, ReferralDialogCrmLead } from './CreateReferralDialog';
import { styles } from './CreateReferralView.styles';
import { SearchOrganizations } from './Queries';
import { ReferralType } from './types';
import { getFlightIsEnabled } from 'features/app/selectors';
import { Flight } from 'services/flights/flightList';
import { connect } from 'react-redux';
import { RootState } from 'store/types';

export interface ReferralOrganization {
  orgId: string;
  accountId: string;
  name: string;
}

export interface CreateReferralViewProps {
  create: () => void;
  canCreateReferral: boolean;
  crmIdField: {
    set: (crmId: string) => void;
    value?: string;
    errorMessage?: string;
    isVerified?: boolean;
    lastVerified?: string;
    onVerifyCrmId: () => void;
  };
  marketField: {
    setMarket: (market: Market) => void;
    value?: Market;
    unsupportedMarketError?: boolean;
    setUnsupportedMarketError: (hasError: boolean) => void;
    setUserChangedTheMarket: (changed: boolean) => void;
    userChangedTheMarket?: boolean;
  };
  organizationSearch: {
    setSelectedOrganization: (org: ReferralOrganization) => void;
    resetSelectedOrganizations: () => void;
    selectedOrganization?: ReferralOrganization;
  };
  itemTypeField: { set: (type: ItemType) => void; value?: ItemType };
  referralTypeField: { set: (referralType: AzureType) => void; value: AzureType };
  nameField: {
    set: (name: string) => void;
    value?: string;
    errorMessage?: string;
    setNameIsAutoPopulated: (value: boolean) => void;
  };
  crmLead: { data?: ReferralDialogCrmLead; loading: boolean; callFailed?: boolean };
}

export const dialogDimensions = {
  height: 542,
  width: 395,
};

const mapStateToProps = (state: RootState) => {
  return {
    allMarketClaimsEnabled: getFlightIsEnabled(state, Flight.allMarketsForClaims),
  };
};

export type Props = CreateReferralViewProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps>;

export interface Organization {
  accountId: string;
  address: {
    companyName?: string;
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    city: string;
    country: string;
    region: string;
  };
  name: string;
  id: string;
  tradeName: string;
  lastInvoiceDate: string | null;
}

const createSuggestion = (organizationItem: Organization) => {
  const suggestion: Suggestion<AddressSuggestion> = {
    ariaLabel: organizationItem.name,
    key: organizationItem.id,
    value: {
      accountId: organizationItem.accountId,
      address: {
        addressLine1: organizationItem.address.addressLine1,
        addressLine2: organizationItem.address.addressLine2,
        addressLine3: organizationItem.address.addressLine3,
        city: organizationItem.address.city,
        country: organizationItem.address.country,
        region: organizationItem.address.region,
      },
      lastPurchaseDateTime: organizationItem.lastInvoiceDate || undefined,
      name: organizationItem.name,
      organizationId: organizationItem.id,
      tradeName: organizationItem.tradeName,
    },
  };
  return suggestion;
};

export const CreateReferralViewUnstyled: React.FC<Props> = (props: Props) => {
  const {
    classes,
    crmIdField,
    crmLead,
    marketField,
    referralTypeField,
    nameField,
    itemTypeField,
    organizationSearch,
    canCreateReferral,
  } = props;
  const { t } = useTranslation();

  const onRenderRow = (suggestion: Suggestion<AddressSuggestion>) => {
    return <AddressSuggestionRow suggestion={suggestion} />;
  };

  const [searchOrganization, { loading: organizationSearchLoading, data, error }] = useLazyQuery<{
    searchOrganizations: Organization[];
    //TODO: cm, Putting no cache for now, there is a problem with the autosuggest when things are chacked I am looking for a good way to fix it
  }>(SearchOrganizations, { errorPolicy: 'ignore', fetchPolicy: 'no-cache' });
  const organizations = oc(data).searchOrganizations([]);

  const suggestions: Suggestion<AddressSuggestion>[] = organizations.map(organizationItem =>
    createSuggestion(organizationItem)
  );

  const isReferral = itemTypeField.value === ItemType.referral;
  const isClaim = itemTypeField.value === ItemType.claim;
  const searchError = (
    <SystemError
      addClass={classes.systemError}
      mainMessage={t('Sorry, the look up for the billing account failed.')}
    />
  );
  const billingAccountSearch = (
    <div className={classes.billingAccount}>
      <Autosuggest
        dataAutomationId="billingAccountLookup"
        error={error && searchError}
        icon={{ iconName: 'Search' }}
        isLoading={organizationSearchLoading}
        notFound={<NotFound primaryText={t('No results found')} />}
        placeholder={t('Search by Company Name or Tenant ID')}
        required
        strings={{
          listAriaLabel: t('Find billing account'),
          loading: t('Loading'),
          notFound: t('No results found'),
        }}
        suggestions={suggestions}
        textboxLabel={t('Billing account')}
        onRenderRow={onRenderRow}
        onSearch={(search: string) => {
          searchOrganization({
            variables: {
              query: search,
            },
          });
        }}
        onSelect={(suggestion: Suggestion<AddressSuggestion>) => {
          organizationSearch.setSelectedOrganization({
            orgId: suggestion.value.organizationId,
            accountId: suggestion.value.accountId,
            name: suggestion.value.name,
          });
        }}
      />
    </div>
  );

  const renderMarketLabel = () => (
    <div>
      <Label className={classes.marketLabel} htmlFor="referral-market-dropdownwrapper">
        {t('Market')}
      </Label>
      <InfoButton
        ariaLabel={t('Open information about markets for referrals')}
        calloutProps={{
          closeButtonAriaLabel: t('Close'),
          headline: t('Available markets for referrals'),
          maxWidth: 288,
        }}
        id="market-info-button"
      >
        <TextBody>
          {t(
            'Currently, only markets that use the US Dollar (USD) are available for the creation of referrals'
          )}
        </TextBody>
      </InfoButton>
    </div>
  );

  const typeChoice = (
    <div>
      <ChoiceGroup
        dataAutomationId="createReferral-typeChoice"
        label=""
        options={[
          { key: ItemType.referral, text: t('Referral'), ariaLabel: t('Referral') },
          { key: ItemType.claim, text: t('Claim'), ariaLabel: t('Claim') },
        ]}
        selectedKey={itemTypeField.value}
        styles={{
          flexContainer: classes.itemTypeFlexContainer,
        }}
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
          option?: IChoiceGroupOption
        ) => {
          if (option) {
            if (option.key === ItemType.claim) {
              itemTypeField.set(ItemType.claim);
            } else {
              itemTypeField.set(ItemType.referral);
            }
          }
        }}
      />
    </div>
  );

  const referralTypeChoices = (
    <div className={classes.referalType}>
      <ChoiceGroup
        dataAutomationId="createReferral-referralTypeChoices"
        label="Referral Type"
        options={[
          { key: 'Trial', text: t('Trial'), ariaLabel: t('Trial') },
          { key: 'Paid', text: t('Paid'), ariaLabel: t('Paid') },
        ]}
        selectedKey={referralTypeField.value}
        styles={{
          flexContainer: classes.referralChoiceGroupFlexContainer,
        }}
        onChange={(ev: unknown, option?: IChoiceGroupOption) => {
          if (option) {
            if (option.key === ReferralType.Trial) {
              referralTypeField.set(AzureType.Trial);
            } else {
              referralTypeField.set(AzureType.Paid);
            }
          }
        }}
      />
    </div>
  );

  const loadedBillingAccount = (
    <div className={classes.loadedBillingAccount}>
      <div>
        <TextBodySmall addClass={classes.tertiaryText}>{t('Billing account')}</TextBodySmall>
      </div>
      <div>
        <TextBody dataAutomationId="createReferralBillingAccountName">
          {oc(organizationSearch).selectedOrganization.name('')}
        </TextBody>
      </div>
      <div>
        <LinkButton
          dataAutomationId="createReferralChangeBillingAccount"
          displayText={t('change billing account')}
          onClick={() => {
            organizationSearch.resetSelectedOrganizations();
          }}
        />
      </div>
    </div>
  );

  const marketDropdownOptions = marketField.unsupportedMarketError
    ? [
        { key: 'select market', text: t('Select market') },
        ...getTranslatedMarketsAlphabetically(
          t,
          MarketType.referrals,
          props.allMarketClaimsEnabled
        ),
      ]
    : getTranslatedMarketsAlphabetically(t, MarketType.referrals, props.allMarketClaimsEnabled);
  const selectedMarketOption = marketField.unsupportedMarketError
    ? 'select market'
    : marketField.value;

  const marketCombobox = (
    <ComboBox
      className={classes.marketDropdown}
      disabled={!!crmLead.loading}
      errorMessage={
        marketField.unsupportedMarketError
          ? t('Assigned market on the CRM ID is not supported, select new')
          : undefined
      }
      id="referral-market-dropdown"
      maxHeight={250}
      options={marketDropdownOptions}
      selectedKey={selectedMarketOption}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (
          option &&
          ((!props.allMarketClaimsEnabled && isReferralMarket(option.key as Market)) ||
            props.allMarketClaimsEnabled)
        ) {
          if (option.key !== 'select market') {
            marketField.setUserChangedTheMarket(true);
            marketField.setMarket(option.key as Market);
            marketField.setUnsupportedMarketError(false);
          }
        }
      }}
      onRenderLabel={renderMarketLabel}
    />
  );

  const nameTextBox = (
    <TextboxStandard
      autoFocus
      dataAutomationId={isReferral ? 'referral-name' : 'claim-name'}
      errorMessage={nameField.errorMessage}
      label={isReferral ? t('Referral name') : t('Claim name')}
      required
      value={nameField.value}
      onChange={(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newName?: string) => {
        newName !== undefined && nameField.set(newName.substring(0, proposalNameLengthLimit));
        nameField.setNameIsAutoPopulated(false);
      }}
    />
  );
  const crmIdView = (
    <div className={classes.crmIdContainer}>
      <VerificationField
        allowTryAgain={!!crmLead.callFailed}
        buttonText={t('Try again')}
        dataAutomationId="crmIdVerificationField"
        debounceValue={0}
        errorMessage={crmIdField.errorMessage}
        errorMessageStyle={classes.crmIdErrorStyle}
        forceSyncTextBoxValueWithInternalValue={true}
        hideButton={!crmLead.callFailed}
        isError={!!crmIdField.errorMessage}
        isLoading={!!crmLead.loading}
        isVerified={crmIdField.isVerified}
        lastVerified={crmIdField.lastVerified}
        spinnerDataAutomationId={t('Loading')}
        textboxClassName={classes.crmIdWidth}
        textboxLabel={t('CRM ID')}
        textboxPlaceholder={t('Enter CRM ID')}
        textboxValue={crmIdField.value}
        validationErrorMessage={t('Enter a valid CRM ID')}
        verifiedStatusText={t('Verified')}
        verifyOnBlur
        onChange={(value?: string) => {
          value !== undefined && crmIdField.set(value);
        }}
        onValidate={(value: string) => crmIDRegExp.test(value)}
        onVerify={crmIdField.onVerifyCrmId}
      />
    </div>
  );

  return (
    <Dialog
      {...dialogDimensions}
      footerButtons={
        <PrimaryButton
          dataAutomationId={isReferral ? 'createReferralButton' : 'createClaimButton'}
          disabled={!canCreateReferral}
          text={isReferral ? t('Create referral') : t('Create claim')}
          onClick={() => {
            props.create();
          }}
        />
      }
      title={t('Create...')}
    >
      <div>
        {typeChoice}
        <SectionSeparator addClass={classes.sectionSeparator} />
        {nameTextBox}
        {crmIdView}
        {isClaim && !!organizationSearch.selectedOrganization && loadedBillingAccount}
        {isClaim && !organizationSearch.selectedOrganization && billingAccountSearch}
        {isReferral && referralTypeChoices}
        {isReferral && marketCombobox}
      </div>
    </Dialog>
  );
};

export const CreateReferralView = connect(mapStateToProps)(
  withStyles(styles)(CreateReferralViewUnstyled)
);
