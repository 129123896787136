import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { Trans, useTranslation } from 'react-i18next';
import { HelpSection } from './HelpSection';
import { ThemeProps } from 'styles';
import { LinkButton, LinkExternal } from 'components/ions';

const styles = (theme: ThemeProps) => ({
  helpPanelFooter: {
    borderTop: `1px solid ${theme.palette.textDisabled}`,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
  },
  link: {
    fontSize: theme.fonts.fontSizes.medium,
  },
});

type Props = {
  onSupportResourcesClick: () => void;
} & WithStyles<typeof styles>;

const HelpPanelFooterUnstyled: React.FC<Props> = props => {
  const { classes, onSupportResourcesClick } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.helpPanelFooter}>
      <HelpSection title={t('Get additional help')}>
        <Trans>
          Go to{' '}
          <LinkButton
            addClass={classes.link}
            displayText={t('Support Resources')}
            key="support-resources"
            onClick={onSupportResourcesClick}
          />{' '}
          to find the right alias to ask a question or open a support ticket.
        </Trans>
      </HelpSection>
      <HelpSection title={t('Provide feedback')}>
        <Trans>
          Feedback or ideas for improvement? Let us know by visiting{' '}
          <LinkExternal
            addClass={classes.link}
            displayText={t('User Voice')}
            href="https://microsoftit.uservoice.com/forums/601144-quote-centre"
            key="user-voice"
          />
          .
        </Trans>
      </HelpSection>
    </div>
  );
};

export const HelpPanelFooter = withStyles(styles)(HelpPanelFooterUnstyled);
