import { SecondaryButton } from 'components/ions';
import { openPublishWizardDialog } from 'features/proposal/components/Wizards';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

const mapStateToProps = (state: RootState) => ({
  disablePublish: selectors.disablePublish(state),
  isAnyBlockingNotification: selectors.isAnyBlockingNotification(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const PublishButtonFeature: React.FC<Props> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const openDialog = () => {
    openPublishWizardDialog(context);
  };

  return (
    <SecondaryButton
      dataAutomationId="publishButton"
      disabled={props.disablePublish || props.isAnyBlockingNotification}
      iconName="PublishContent"
      id="publish-button"
      text={t('quote::Publish')}
      onClick={openDialog}
    />
  );
};

export const PublishButton = connect(mapStateToProps)(PublishButtonFeature);
