import * as appSelectors from 'features/app/selectors';
import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import { buildCreditRequest, getMinimumCredit } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { Success } from '../Shared';
import { AnnualDealEstimate, RequestCreditLine, RequestCreditLineView } from './';

const dialogDimension = {
  height: 424,
  width: 506,
};

const mapStateToProps = (state: RootState) => ({
  proposal: selectors.getActiveProposal(state),
  error: selectors.requestCreditLineProcessing(state).error,
  organizationId: selectors.getOrganizationId(state),
  accountId: selectors.getAccountId(state),
  isCreditLineLoading: selectors.requestCreditLineProcessing(state).loading,
  minCreditLineDivisor: appSelectors.getMinCreditLineDivisor(state),
  isSafelistedCreditCheckEnabled: appSelectors.getFlightIsEnabled(
    state,
    Flight.safelistedCreditCheck
  ),
});

const dispatchProps = {
  requestCreditLine: (request: RequestCreditLine) =>
    actions.requestCreditLineAsync.request(request),
  updateAnnualDealEstimate: (request: AnnualDealEstimate, proposal: Proposal) =>
    actions.updateAnnualDealEstimate(request, proposal),
};

interface RequestCreditLineDialogProps {
  annualDealEstimate: string;
  minimumCreditLine: string;
  setCustomerFooterAnnualDealEstimate?: (value: string) => void;
}

type Props = RequestCreditLineDialogProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

export const RequestCreditLineDialogFeature: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [requested, setRequested] = React.useState<boolean>(false);
  const [annualDealEstimate, setAnnualDealEstimate] = React.useState<string>(
    props.annualDealEstimate
  );
  const [minimumCreditLine, setMinimumCreditLine] = React.useState<string>(props.minimumCreditLine);

  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const dispatchRequestCreditLine = (annualDealEstimate: string, minimumCreditLine: string) => {
    if (props.setCustomerFooterAnnualDealEstimate) {
      props.setCustomerFooterAnnualDealEstimate(annualDealEstimate);
    }

    setAnnualDealEstimate(annualDealEstimate);
    setMinimumCreditLine(minimumCreditLine);

    if (props.organizationId && props.accountId) {
      const requestCreditLine: RequestCreditLine = buildCreditRequest(
        props.proposal,
        props.organizationId,
        props.accountId,
        props.minCreditLineDivisor,
        Number(annualDealEstimate),
        minimumCreditLine,
        props.isSafelistedCreditCheckEnabled
      );
      props.requestCreditLine(requestCreditLine);
      props.updateAnnualDealEstimate(
        {
          annualDealEstimate: Number(annualDealEstimate),
          minimumCreditLine: Number(minimumCreditLine),
        },
        props.proposal
      );
    } else {
      loggerService.error({
        error: new Error('Missing organizationId or accountId or both that is not expected.'),
      });
    }
    setRequested(true);
  };

  if (!requested) {
    const isDealEstimateAdded = !!+annualDealEstimate;
    let minCreditLine = minimumCreditLine;
    if (isDealEstimateAdded && (!minimumCreditLine || minimumCreditLine === '0')) {
      minCreditLine = getMinimumCredit(Number(annualDealEstimate), props.minCreditLineDivisor);
    }
    return (
      <RequestCreditLineView
        {...dialogDimension}
        annualDealEstimate={annualDealEstimate}
        minimumCreditLine={minCreditLine}
        onRequestCreditLine={dispatchRequestCreditLine}
      />
    );
  } else if (props.isCreditLineLoading) {
    return (
      <Processing {...dialogDimension} message1={t('quote::The credit line is being requested.')} />
    );
  } else if (props.error) {
    return (
      <Fail
        {...dialogDimension}
        closeDialog={closeDialog}
        dataAutomationId="requestCreditLineFail"
        message={t('quote::Sorry, the "Request Credit" action failed.')}
        onTryAgainClick={() => dispatchRequestCreditLine(annualDealEstimate, minimumCreditLine)}
      />
    );
  } else {
    return (
      <Success
        message={t('quote::You have successfully requested a credit line.')}
        {...dialogDimension}
        closeDialog={closeDialog}
        dataAutomationId="requestCreditLineSuccess"
      />
    );
  }
};

export const RequestCreditLineDialog = connect(
  mapStateToProps,
  dispatchProps
)(RequestCreditLineDialogFeature);
