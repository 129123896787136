import { Approval, ApprovalLevelState, ApprovalStatus, ApprovalSummary } from './types';

export const invalidApprovalTransitionCode = '3626';
export const unathorizedActionCode = '3403';
export const approvalNotWithdrawable = 'QUOTE_NOT_WITHDRAWABLE';
export const eTagMissmatchCode = '2613';
export const quoteIsNotSubmitableCode = '30006';

/**
 * Gets the quotes ids where the user is part of the approvers. 
 @param{boolean} pendingApprovalsOnly to specify that we only want to get the quotes for the pending approvals. 
*/
export function getQuoteIdsFromApprovalSummaries(
  approvals: ApprovalSummary[],
  email?: string,
  pendingApprovalsOnly?: boolean
): string[] {
  if (!email) {
    return [];
  }
  const quoteIds = approvals.reduce((ids: string[], approval) => {
    if (
      approval.context.contextType.includes('Quote') &&
      approval.status !== ApprovalStatus.Withdrawn
    ) {
      const quoteId = getQuoteIdFromApprovalSummary(approval);
      const shouldGetQuoteForApproval =
        !!quoteId && (!pendingApprovalsOnly || isUsersTurnToApprove(approval, email));
      if (shouldGetQuoteForApproval) {
        ids.push(quoteId);
      }
    }
    return ids;
  }, []);
  // it still keeps the order of the array https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
  const deduped = [...new Set(quoteIds)];
  // currently we want the limit to 60 quoteIds in a GET call (~1850 characters, 2000 is the safe limit), for approvals
  const limit = Math.min(deduped.length, 60);
  return deduped.slice(0, limit);
}

export function getQuoteIdFromApprovalSummary(approval: ApprovalSummary): string {
  if (!approval.context.contextType.includes('Quote') || !approval.context.contextId) return '';

  const matches = approval.context.contextId.match(/i:(.*?)\//);
  if (!matches || matches.length < 1) return '';

  return matches[1];
}

export function isUsersTurnToApprove(approval: ApprovalSummary | Approval, email: string) {
  const sortedApprovalLevels = approval.approvalLevels.sort((a, b) => a.order - b.order);
  const firstSubmittedApprovalLevel = sortedApprovalLevels.find(
    level => level.state === ApprovalLevelState.submitted
  );
  const isUsersTurn = firstSubmittedApprovalLevel
    ? firstSubmittedApprovalLevel.approvers.some(
        approver => approver.emailAddress.toLowerCase() === email.toLowerCase()
      )
    : false;
  const isApprovalDone =
    approval.status === ApprovalStatus.Approved || approval.status === ApprovalStatus.Rejected;
  return isUsersTurn && !isApprovalDone;
}
