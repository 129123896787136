import { ILabelProps, Label } from 'office-ui-fabric-react';
import React from 'react';

export interface LabelAtomProps extends ILabelProps {
  className?: string;
  htmlFor?: string;
}

export const LabelAtom: React.FC<LabelAtomProps> = props => {
  return <Label {...props} />;
};
