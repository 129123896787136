import { ComboBox, TextBody } from 'components';
import { BodyProps as WizardBodyProps } from 'features/proposal/components/Wizards/shared';
import { getComboBoxInvoiceLanguages } from 'features/proposal/supported-invoice-languages';
import { Language } from 'features/proposal/supported-languages';
import { getLanguages, Market } from 'features/proposal/supported-markets';
import i18next from 'i18n';
import { IComboBox, IComboBoxOption, PrimaryButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { invoicingLanguagStyles } from './InvoiceLanguageDialog.styles';

// TODO: masivaia sync with PM team to decid how to save invoice language

export interface InvoiceLanguage {
  market: Market;
  language: Language;
}

export const InvoiceLanguageHeaderString = () => i18next.t('quote::Invoice language');

type BodyProps = WizardBodyProps<InvoiceLanguage> & WithStyles<typeof invoicingLanguagStyles>;

const InvoiceLanguageBodyUnstyled: React.FC<BodyProps> = props => {
  const { classes, initialData, onValid } = props;
  const { t } = useTranslation();
  const defaultLanguage = getLanguages((initialData && initialData.market) || 'US')[0];
  const [currentValue, setCurrentValue] = React.useState<Language>(
    (initialData && initialData.language) || defaultLanguage
  );
  const languageComboBoxOptions = getComboBoxInvoiceLanguages(
    (initialData && initialData.market) || 'US'
  );
  const onInvoiceLanguageChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option) {
      const language = option.key as Language;
      onValid({ language: language, market: (initialData && initialData.market) || 'US' });
      setCurrentValue(language);
    }
  };
  return (
    <div>
      <TextBody addClass={classes.secondaryText}>
        {t(
          'quote::The invoice language determines the language your customer`s invoice will be in. Unfortunately, there is currently an issue with the system that prevents this from being changed once the billing account has been created.'
        )}
      </TextBody>
      <div className={classes.textSpacer} />
      <TextBody addClass={classes.secondaryText}>
        {t(
          'quote::Please make sure you select the language your customer wants before creating the billing account.'
        )}
      </TextBody>
      <div className={classes.comboBoxStyles}>
        <ComboBox
          id="invoicing-language-input"
          label={t('quote::Invoice language')}
          options={languageComboBoxOptions}
          required={true}
          selectedKey={currentValue || defaultLanguage}
          onChange={onInvoiceLanguageChange}
        />
      </div>
    </div>
  );
};

export const InvoiceLanguageBody = withStyles(invoicingLanguagStyles)(InvoiceLanguageBodyUnstyled);

export const InvoiceLanguageFooterButton = (
  onPrimaryButtonClick: () => void,
  isValid?: boolean
) => {
  return [
    <PrimaryButton
      data-automation-id="invoiceLanguageSaveButton"
      disabled={!isValid}
      key="Save"
      text={i18next.t('quote::Save')}
      onClick={onPrimaryButtonClick}
    />,
  ];
};
