export type Services = typeof import('./index').default;

export interface PageRequest {
  id: string;
  accountId?: string;
  // relative link to the next page of results
  nextLink?: string | null;
}

export interface PageResponse<T> {
  value: T[];
  '@nextLink': string | null;
}

export interface ExternalReference {
  id: string;
  url: string;
}

/**
 * @param   {T}       FilterField enum
 * @returns {operation: 'matches' | '!=' | '='} where the values for types '!=' and '=' are case sensitive
 */
export type Filter<T> =
  | { filters: Filter<T>[]; operation: 'and' | 'or' }
  | { filterField: T; value: string; operation: 'matches' | '!=' | '=' };

export interface PatchCommand {
  op: 'add' | 'remove' | 'replace';
  path: string;
  target?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

export interface TestHeader {
  contact: string;
  retention: Date;
  scenarios: string;
}

export interface TestHeaderConfig {
  useTestHeader: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTestHeaderConfig = (arg: any): arg is TestHeaderConfig => {
  return arg.useTestHeader !== undefined;
};
