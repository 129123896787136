import axios, { AxiosError } from 'axios';
import { TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader } from '../utils';
import { endpoints, ExternalUserConfig } from './config';
import {
  ExternalUser,
  GetTenantNames,
  Tenant,
  TenantName,
  VerifyUserRequest,
  GetTenantUpns,
  TenantUpnResponse,
} from './types';

async function getHeaders() {
  return {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
  };
}

export async function validateUser(
  request: VerifyUserRequest,
  config: ExternalUserConfig
): Promise<ExternalUser[]> {
  const headers = await getHeaders();
  const url = `${endpoints[config.environment]}/verifyemailtenant`;
  const response = await axios.post<ExternalUser[]>(url, request, { headers });
  return response.data;
}

export async function getTenantProfile(
  tenant: string,
  config: ExternalUserConfig
): Promise<Tenant | undefined> {
  const headers = await getHeaders();
  const url = `${endpoints[config.environment]}/viraltenantcheck`;
  const params = { domain: tenant };
  try {
    const response = await axios.get<Tenant>(url, { params, headers });
    return response.data;
  } catch (exception) {
    const error = exception as AxiosError;
    if (error.response && error.response.status === 400) {
      return;
    }
    throw exception;
  }
}

export async function getTenantNames(
  request: GetTenantNames,
  config: ExternalUserConfig
): Promise<TenantName[]> {
  const headers = await getHeaders();
  const url = `${endpoints[config.environment]}/tenantdisplayname`;
  const response = await axios.post<TenantName[]>(url, request, { headers });
  return response.data;
}

export async function getTenantAdmins(
  request: GetTenantNames,
  config: ExternalUserConfig
): Promise<TenantName[]> {
  const headers = await getHeaders();
  const url = `${endpoints[config.environment]}/tenantadmins`;
  const response = await axios.post<TenantName[]>(url, request, { headers });
  return response.data;
}

export async function getTenantUpn(
  request: GetTenantUpns,
  config: ExternalUserConfig
): Promise<TenantUpnResponse> {
  const headers = await getHeaders();
  const url = `${endpoints[config.environment]}/upn`;
  const response = await axios.post<TenantUpnResponse>(
    url,
    {
      tenantId: request.tenantId,
      objectId: request.objectId,
    },
    { headers }
  );
  return response.data;
}
