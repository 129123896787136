import { Dialog, PrimaryButton, SecondaryButton, TextBody } from 'components';
import { Dimensions, Fail, Processing } from 'features/components/dialogs';
import {
  GET_QUOTERECOMMENDATIONS,
  DELETE_FAVORITEGROUP,
} from 'features-apollo/quote/components/queries';
import { CatalogContext, Query } from 'generated/graphql';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ProductGroup } from 'services/user-preferences/types';
import { ThemeProps } from 'styles';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';
import { useMutation } from '@apollo/react-hooks';

const dimensions: Dimensions = {
  height: 220,
  width: 424,
};

const styles = (theme: ThemeProps) => ({
  texboxInstructions: {
    color: theme.palette.textTertiary,
    paddingLeft: 8,
  },
});

interface DeleteProductGroupDialogProps {
  elementIdToFocusOnDismiss?: string;
  groupName: string;
  quoteId?: string;
  favoritesProductGroups?: Record<string, ProductGroup>;
  catalogContext?: CatalogContext;
}

type Props = DeleteProductGroupDialogProps & WithStyles<typeof styles>;
export const DeleteProductGroupDialogUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const { groupName } = props;

  const [isProductGroupDeleted, setIsProductGroupDeleted] = React.useState<boolean | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState<boolean | undefined>();

  const [deleteProductGroup, { error, loading }] = useMutation(DELETE_FAVORITEGROUP, {
    onCompleted: data => {
      if (data && data.deleteFavoriteGroup) {
        setIsProductGroupDeleted(
          !data.deleteFavoriteGroup.some((group: { name: string }) => group.name === groupName)
        );
      }
    },
  });

  React.useEffect(() => {
    if (loading && !isProductGroupDeleted) {
      setIsLoading(true);
    } else if (!loading && isProductGroupDeleted) {
      setIsLoading(false);
    }
  }, [loading, isProductGroupDeleted]);

  const handleClose = () => {
    context.closeDialog();

    // Refocus
    if (props.elementIdToFocusOnDismiss) {
      const element = document.getElementById(props.elementIdToFocusOnDismiss);
      element && element.focus();
    }
  };

  // #region adding product states
  if (groupName) {
    if (isLoading) {
      return <Processing {...dimensions} message1={t('Deleting product group')} />;
    } else if (error) {
      return (
        <Fail
          {...dimensions}
          closeDialog={handleClose}
          message={t('Unable to delete product group')}
        />
      );
    } else if (isProductGroupDeleted) {
      handleClose();
      return null;
    }
  }
  // #endregion

  const buildOptimisticResponse = (name: string) => {
    return {
      deleteFavoriteGroup: [
        {
          items: [],
          name: name,
          __typename: 'RecommendationGroup',
        },
      ],
      __typename: 'Mutation',
    };
  };

  return (
    <Dialog
      {...dimensions}
      closeDialog={handleClose}
      footerButtons={[
        groupName && (
          <PrimaryButton
            dataAutomationId="productGroupDialogDeleteGroup"
            disabled={!groupName}
            key="delete-group"
            text={t('Delete group')}
            onClick={() => {
              deleteProductGroup({
                variables: {
                  input: {
                    groupName: groupName,
                    catalogContext: props.catalogContext,
                  },
                },
                optimisticResponse: buildOptimisticResponse(groupName),
                update: (useApolloClient, { data: { deleteFavoriteGroup } }) => {
                  // Read from cached
                  const cached = useApolloClient.readQuery<Query>({
                    query: GET_QUOTERECOMMENDATIONS,
                    variables: { id: props.quoteId, input: props.catalogContext },
                  });

                  // Create update object
                  if (
                    cached &&
                    cached.getQuote &&
                    cached.getQuote.recommendations &&
                    deleteFavoriteGroup
                  ) {
                    cached.getQuote.recommendations = cached.getQuote.recommendations.filter(
                      rec => rec.name !== groupName
                    );
                  }

                  useApolloClient.writeQuery({
                    query: GET_QUOTERECOMMENDATIONS,
                    variables: { id: props.quoteId, input: props.catalogContext },
                    data: cached,
                  });

                  setIsLoading(false);
                  handleClose();
                },
              });
            }}
          />
        ),
        <SecondaryButton
          autoFocus
          dataAutomationId="productGroupDialogCancelDeleteGroup"
          key="cancel-delete-group"
          text={t('Cancel')}
          onClick={handleClose}
        />,
      ]}
      title={t('Delete product grouping')}
    >
      <TextBody>
        <Trans ns="quote">
          By deleting the group, named &quot;{{ groupName }}&quot;, will delete all of the products
          in this group as well. Are you sure you want to delete this group?
        </Trans>
      </TextBody>
    </Dialog>
  );
};

const DeleteProductGroupDialog = withStyles(styles)(DeleteProductGroupDialogUnstyled);

export const openDeleteProductGroupDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: DeleteProductGroupDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <DeleteProductGroupDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
