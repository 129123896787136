import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => ({
  calendar: {
    '& button': {
      width: '100%',
      position: 'relative',
      top: 8.5,
      '& .ms-Button-textContainer': {
        textAlign: 'left',
      },
    },
  },
  calendarNoDate: {
    '& button': {
      width: '100%',
      position: 'relative',
      top: 8.5,
      '& .ms-Button-textContainer': {
        textAlign: 'left',
      },
      '& .ms-Button-label': {
        fontStyle: theme.fonts.fontStyles.italic,
        color: theme.palette.textTertiary,
      },
    },
  },
  content: {
    paddingTop: 20,
  },
  dates: {
    paddingTop: 20,
    display: 'inline-flex',
  },
  dateContainer: {
    marginRight: 20,
    height: 67,
    width: 180,
    '& label': {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
  },
  dateLabel: {
    fontWeight: theme.fonts.fontWeights.semiBold,
    position: 'relative',
    top: 6,
    '& + span': {
      fontWeight: theme.fonts.fontWeights.semiBold,
      position: 'relative',
      top: 6,
    },
  },
  errorMessage: {
    color: theme.palette.dangerText,
    paddingLeft: 105,
  },
});
