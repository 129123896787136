import { ThemeProps } from 'styles/theme';
import { TilesProps } from './Tiles';

export const tileStyles = (theme: ThemeProps) => {
  const { palette } = theme;

  return {
    tileSection: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    tile: (props: TilesProps) => {
      return {
        flexGrow: 1,
        backgroundColor: palette.backgroundStandout,
        minHeight: props.heightFitToContent ? 'fit-content' : '140px',
        marginRight: '16px',
        marginBottom: '16px',
      };
    },
    tileContent: {
      padding: '16px',
    },
    innerPadding: {
      padding: '4px',
    },
    emphasisText: {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    border: {
      border: `1px solid ${theme.palette.backgroundDivider}`,
    },
  };
};
