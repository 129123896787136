import React, { useState } from 'react';
import withStyles, { WithStyles } from 'react-jss';

const styles = {
  span: {
    position: 'relative',
  },
  dlg: {
    border: 'solid 1px red',
    position: 'absolute',
    background: 'white',
  },
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithStyles<typeof styles> {}

const ConfigureProduct: React.FC<Props> = ({ classes }: Props) => {
  const [show, setShow] = useState(false);

  return (
    <span className={classes.span}>
      <button
        onClick={() => {
          setShow(!show);
        }}
      >
        Configure
      </button>
      {show && <span className={classes.dlg}>Configuring discount...</span>}
    </span>
  );
};

export default withStyles(styles)(ConfigureProduct);
