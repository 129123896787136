import { getConfigByEnvironment } from 'features/app/config';
import { compose } from 'redux';
import { isFlight } from 'services/flights/flightList';
import { getFlightsFromUrl } from 'services/flights/utils';
import { getLocalStorageState } from 'store/middleware/localStorageMiddleware';
import { RootState } from 'store/types';
import { createStandardAction } from 'typesafe-actions';

export const composeEnhancers =
  // (process.env.NODE_ENV === 'development' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export function createAsyncAction(request: string, success: string, failure: string) {
  return <T1, T2, T3>() => ({
    request: createStandardAction(request)<T1>(),
    success: createStandardAction(success)<T2>(),
    failure: createStandardAction(failure)<T3>(),
  });
}

export const isDevBuild = process.env.NODE_ENV !== 'production';

const applyUrlFlightsToState = (state: Partial<RootState>) => {
  if (!state.app || !state.app.flights) {
    return;
  }

  const flights = state.app.flights;

  const env = getConfigByEnvironment().app;
  const urlFlights = getFlightsFromUrl(env);

  Object.keys(urlFlights).forEach(key => {
    if (isFlight(key)) {
      const flight = urlFlights[key];
      if (flight !== undefined) {
        flights[key] = flight;
      }
    }
  });
};

export const getDevInitialState = () => {
  const nextState: Partial<RootState> = {
    ...getLocalStorageState(),
  };

  //override localStorage flights with url flights
  applyUrlFlightsToState(nextState);

  return nextState;
};
