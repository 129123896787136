import { TextBody } from 'components';
import { EditLabel } from 'components/molecules/EditLabel/EditLabel';
import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import {
  openVatIdDialog,
  VatIdLinkButton,
} from 'features-apollo/quote/components/Dialogs/VatId/VatIdDialog';
import { GetMarketInfo } from 'features-apollo/quote/components/Dialogs/VatId/Queries';
import { defaultMarket, Market } from 'features/proposal/supported-markets';
import { MarketInfo, OrganizationIdentifierInput } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext, FontSizes } from 'styles';
import { oc } from 'ts-optchain';

import { useQuery } from '@apollo/react-hooks';

import { organizationInformationStyles } from '../OrganizationInformation.styles';

export interface OrganizationInformationVatIDProps {
  readOnly?: boolean;
  organizationIdentifier: OrganizationIdentifierInput;
  vatId?: string;
  market?: Market;
  quoteId?: string;
}

type Props = OrganizationInformationVatIDProps & WithStyles<typeof organizationInformationStyles>;

const OrganizationInformationVatIDUnstyled: React.FC<Props> = props => {
  const { classes, organizationIdentifier, vatId, market = defaultMarket, quoteId } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const { data } = useQuery<{ getMarketInfo: MarketInfo }>(GetMarketInfo, {
    variables: { market },
  });

  const requiresVatId = oc(data).getMarketInfo.requiresVatId(false);

  const vatIdProps = organizationIdentifier && {
    organizationIdentifier,
    size: 'medium' as keyof FontSizes,
    initialVatId: getValueOrUndefined(vatId),
    quoteId,
  };

  const openIdDialog = vatIdProps ? () => openVatIdDialog(context, vatIdProps) : undefined;

  const displayVatId = () => {
    if (vatId) {
      return <TextBody dataAutomationId="vatIdentificationNumber">{vatId}</TextBody>;
    } else if (props.readOnly) {
      return <TextBody addClass={classes.readOnlyVatId}>{t('quote::none')}</TextBody>;
    } else if (vatIdProps) {
      return <VatIdLinkButton {...vatIdProps} />;
    }
  };

  return requiresVatId ? (
    <div>
      <EditLabel
        ariaLabel={t('quote::VAT identification number')}
        hideIcon={props.readOnly || !vatIdProps}
        label={t('quote::VAT identification number')}
        onClick={openIdDialog}
      />
      {displayVatId()}
    </div>
  ) : null;
};

export const OrganizationInformationVatID = withStyles(organizationInformationStyles)(
  OrganizationInformationVatIDUnstyled
);
