import { Application, PermissionInstance, UserPermissions } from './types';

const getConstraints = (pi: PermissionInstance): string[] => {
  const environmentConstraintInstances = pi.ConstraintInstances.find(
    ci => ci.Constraint.Name === 'Environment'
  );

  const constraintsValue = environmentConstraintInstances
    ? environmentConstraintInstances.ConstraintAttributeInstances.length === 1
      ? environmentConstraintInstances.ConstraintAttributeInstances[0].Value
      : environmentConstraintInstances.Constraint.ConstraintAttributes.length === 1
      ? environmentConstraintInstances.Constraint.ConstraintAttributes[0].DefaultValue
      : '[]'
    : '[]';

  return JSON.parse(constraintsValue);
};

export const getUserPermissions = (response: Application[]): UserPermissions => {
  let normalizedResponse: UserPermissions = { permissions: [], roles: [] };
  normalizedResponse.roles = response.map(app => app.Name);
  response.forEach(app => {
    app.PermissionInstances.forEach(pi => {
      const constraints = getConstraints(pi);
      const targetPermission =
        normalizedResponse.permissions &&
        normalizedResponse.permissions.find(p => p.name === pi.Name);

      targetPermission
        ? constraints.forEach(
            (c: string) =>
              !targetPermission.constraints.includes(c) && targetPermission.constraints.push(c)
          )
        : normalizedResponse.permissions &&
          normalizedResponse.permissions.push({ name: pi.Name, constraints });
    });
  });

  return normalizedResponse;
};

/* eslint-disable @typescript-eslint/camelcase */
const participantMap: Record<string, string> = {
  Microsoft_Field_Sales: 'Msfieldsales',
  Microsoft_Inside_Sales_Corporation: 'Msinsidesalescorp',
  Microsoft_Inside_Sales_Demand_Response: 'msinsidesalesdemandresponse',
};

type originatingChannel = 'Field' | 'insideSalesCorp' | 'insideSalesDemandResponse';

const originatingChannelMap: Record<string, originatingChannel> = {
  Microsoft_Field_Sales: 'Field',
  Microsoft_Inside_Sales_Corporation: 'insideSalesCorp',
  Microsoft_Inside_Sales_Demand_Response: 'insideSalesDemandResponse',
};
/* eslint-enable @typescript-eslint/camelcase */

export const getParticipants = (roles: string[]) => {
  const participantIds = roles.map(role => participantMap[role]).filter(role => role);
  const participants = participantIds.map(participant => ({
    accountId: 'org:0da8640c-6f35-4292-9294-879f8f123742',
    profileId: 'PROFILE_ID',
    role: participant,
  }));
  return participants;
};

const reversePriority: originatingChannel[] = [
  'insideSalesDemandResponse',
  'insideSalesCorp',
  'Field',
];

export const getOriginatingChannel = (roles: string[]) => {
  const channels = roles.map(role => originatingChannelMap[role]).filter(channel => !!channel);
  let response = 'Field';
  reversePriority.forEach(channel => {
    if (channels.includes(channel)) {
      response = channel;
    }
  });
  return response;
};
