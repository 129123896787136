import { getFlightIsEnabled } from 'features/app/selectors';
import { ProductFamilySearchRequest } from 'features/catalog';
import * as actions from 'features/catalog/actions';
import { getCatalogConfig } from 'features/catalog/selectors';
import { getUser } from 'features/user/selectors';
import { User } from 'features/user/types';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, SimpleEffect, spawn } from 'redux-saga/effects';
import { api } from 'services';
import {
  CatalogCallConfig,
  NationalCloud,
  searchProductsByFamilyPageSize,
} from 'services/catalog/api';
import {
  Product,
  ProductFamily,
  ProductResult,
  ProductSearchByFamilyResponse,
  ProductSearchRequest,
  ProductSearchResponse,
  ProductSearchSuccess,
  TermSearchRequest,
} from 'services/catalog/types';
import { getStringifiedKeyFromConfig } from 'services/catalog/utils';
import { Flight } from 'services/flights/flightList';
import { Channel, Product as RecoProduct } from 'services/reco/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

export function* loadProducts(productIds: string[]): SagaIterator {
  const gqlPhase2Enabled = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.graphqlPhase2)
  );
  if (gqlPhase2Enabled) {
    return;
  }
  yield put(actions.loadProductsAsync.request(productIds));
  try {
    const config1: CatalogCallConfig = yield select(getCatalogConfig);
    const cloud = oc(config1).nationalCloud(NationalCloud.Global);
    const config: CatalogCallConfig = {
      ...config1,
      nationalCloud: Array.isArray(cloud) ? (cloud[0] as NationalCloud) : cloud,
    };
    const key = getStringifiedKeyFromConfig(config);
    const fromState: Record<string, Product> = yield select(
      (state: RootState) => state.catalog.products.entities.indexed[key]
    );
    if (fromState) {
      productIds = productIds.filter(id => !fromState[id]);
    }
    const failedFromState: Record<string, Product> = yield select(
      (state: RootState) => state.catalog.products.failed[key]
    );
    if (failedFromState) {
      productIds = productIds.filter(id => !failedFromState[id]);
    }
    if (!productIds.length) {
      yield put(actions.loadProductsAsync.success({ products: [], productKey: key, failed: [] }));
      return;
    }
    const user: User = yield select((state: RootState) => state.user.current);

    // Gets product result
    const products: ProductResult = yield call(api.catalog.loadProducts, productIds, user, config);

    // Write product result to state
    yield put(actions.loadProductsAsync.success(products));

    // Check product result for 'add on' products
    if (products && products.products.length) {
      let addOnProductIds: string[] = [];
      products.products.forEach(product => {
        oc(product)
          .DisplaySkuAvailabilities[0].Sku.Properties.ConstraintsData.PrerequisiteSkus.MustHaveAny(
            []
          )
          .forEach(
            mha => !addOnProductIds.includes(mha.ProductId) && addOnProductIds.push(mha.ProductId)
          );
      });
      // Recursively load 'add on' products
      if (addOnProductIds.length) {
        yield call(loadProducts, addOnProductIds);
      }
    }
    return products;
  } catch (err) {
    yield put(
      actions.loadProductsAsync.failure({
        message: t('error::Error hydrating products: {productIds}', {
          productIds: productIds,
        }),
        exception: err,
      })
    );
  }
}

function* spawnCatalogCallWithChannel(channel: Channel) {
  yield spawn(
    loadProducts,
    channel.Items.map(product => product.Id)
  );
}

function* getTermsChannel() {
  const searchTermsEnabled: boolean = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.termsFinderSearch)
  );
  const loadCustomTerms: boolean = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.customTerms)
  );
  const customTermId = '0RDCKN523H17';

  if (searchTermsEnabled) {
    const user: User = yield select(getUser);
    const config1: CatalogCallConfig = yield select(getCatalogConfig);
    const cloud = oc(config1).nationalCloud(NationalCloud.Global);
    const config: CatalogCallConfig = {
      ...config1,
      nationalCloud: Array.isArray(cloud) ? (cloud[0] as NationalCloud) : cloud,
    };
    const request: TermSearchRequest = {
      query: '*',
      top: 25,
    };
    const response: ProductSearchResponse = yield call(
      api.catalog.searchTerms,
      request,
      user,
      config
    );

    const terms: RecoProduct[] = response.products.map(term => {
      return { Id: term.ProductId };
    });
    let termsToReturn: RecoProduct[];
    if (!loadCustomTerms) {
      termsToReturn = terms.filter(term => term.Id.toLowerCase() !== customTermId.toLowerCase());
    } else {
      termsToReturn = terms;
    }

    const termsChannel: Channel = {
      name: 'StandardTerms',
      Title: 'Standard',
      Items: termsToReturn,
    };

    return termsChannel;
  } else {
    const staticChannel: Channel = api.recoStatic.loadChannel(loadCustomTerms);
    return staticChannel;
  }
}

export function* loadReco() {
  try {
    const fromState: Record<string, Channel> = yield select(
      (state: RootState) => state.catalog.recommendations.byChannel
    );
    if (Object.keys(fromState).length) {
      yield all(
        Object.values(fromState).map((channel: Channel) =>
          call(spawnCatalogCallWithChannel, channel)
        )
      );
      return;
    }
    yield put(actions.loadRecoAsync.request());
    const calls: SimpleEffect<unknown, unknown>[] = [];
    calls.push(call(api.reco.loadChannel, 'AzureEssentials'));
    calls.push(call(getTermsChannel));
    calls.push(call(api.ecifStatic.loadChannel));
    const results: Channel[] = yield all(calls);
    const newCalls: SimpleEffect<unknown, unknown>[] = [];
    results.forEach(channel => {
      newCalls.push(call(spawnCatalogCallWithChannel, channel));
    });
    newCalls.push(put(actions.loadRecoAsync.success(results)));
    yield all(newCalls);
  } catch (err) {
    yield put(
      actions.loadRecoAsync.failure({
        message: t('error::Error loading reco channel.'),
        exception: err,
      })
    );
  }
}

export function* searchProductsByFamily(
  { productFamilyNames, query, serviceFamily }: ProductFamilySearchRequest,
  skip: number = 0,
  retry: number = 1,
  config?: CatalogCallConfig
) {
  const catalogConfig: CatalogCallConfig = config ? config : yield select(getCatalogConfig);
  const user: User = yield select((state: RootState) => state.user.current);
  const disableProductInclusionFilter: boolean = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.disableProductInclusionFilter)
  );
  const enableBlendedAutosuggest: boolean = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.enableBlendedAutosuggest)
  );

  try {
    yield put(
      actions.searchProductsByFamilyAsync.request({ productFamilyNames, query, serviceFamily })
    );
    let nextSkip = skip;
    let products: Product[] = [];
    let productFamiliesWithMoreResults: ProductFamily[] = productFamilyNames;

    const getCalls = (skip: number) =>
      productFamiliesWithMoreResults.map(productFamilyName =>
        call(
          api.catalog.searchProductsByFamily,
          {
            productFamilyName,
            query,
            skip,
            top: searchProductsByFamilyPageSize,
            serviceFamily,
          },
          user,
          catalogConfig,
          disableProductInclusionFilter,
          enableBlendedAutosuggest
        )
      );

    const getResultsFromResponses = (responses: ProductSearchByFamilyResponse[]) => {
      productFamiliesWithMoreResults = [];
      responses.forEach(response => {
        products = products.concat(response.products);
        if (response.hasMorePages) {
          productFamiliesWithMoreResults.push(response.productFamilyName);
        }
      });
    };

    for (let i = 0; i < retry && !products.length && productFamiliesWithMoreResults.length; i++) {
      const responses: ProductSearchByFamilyResponse[] = yield all(getCalls(nextSkip));
      nextSkip = nextSkip + searchProductsByFamilyPageSize;
      getResultsFromResponses(responses);
    }

    const searchResult: ProductSearchSuccess = {
      nextSkip,
      products,
      productFamiliesWithMoreResults,
    };

    yield put(actions.searchProductsByFamilyAsync.success(searchResult));
    return searchResult;
  } catch (err) {
    yield put(
      actions.searchProductsByFamilyAsync.failure({
        message: t('error::Error searching catalog for products by family names.'),
        exception: err,
      })
    );
  }
}

export function* filterFavoriteProductForLegacy(request: ProductSearchRequest) {
  const catalogConfig: CatalogCallConfig = yield select(getCatalogConfig);
  const disableProductInclusionFilter: boolean = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.disableProductInclusionFilter)
  );
  const user: User = yield select((state: RootState) => state.user.current);
  const { products, productFamiliesWithMoreResults }: ProductSearchResponse = yield call(
    api.catalog.searchProducts,
    request,
    user,
    catalogConfig,
    disableProductInclusionFilter
  );
  if (products.length) {
    yield put(actions.filterFavoriteProductsForLegacyAsync.success(products));
  } else if (productFamiliesWithMoreResults.length) {
    const searchProductByFamilyNamesResponse: ProductSearchSuccess = yield call(
      searchProductsByFamily,
      { productFamilyNames: productFamiliesWithMoreResults, query: request.query },
      searchProductsByFamilyPageSize
    );
    yield put(
      actions.filterFavoriteProductsForLegacyAsync.success(
        searchProductByFamilyNamesResponse.products
      )
    );
  }
}
