import {
  ActionBar,
  BarButton,
  BarSearch,
  BarSearchProps,
  MediumIcon,
  ProgressIndicator,
  SmallIcon,
  StatusIndicatorClosed,
  StatusIndicatorExpired,
  StatusIndicatorOpen,
  StatusIndicatorTransacted,
  StatusIndicatorUnknown,
  TextWatermark,
} from 'components';
import { TextBodySmall } from 'components/ions';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { getAppEnvironment } from 'features-apollo/app/selectors';
import { useGetUserPermissions } from 'features-apollo/user/hooks';
import { getUserMail } from 'features-apollo/user/selectors';
import { openCreateReferralDialog } from 'features/components/dialogs/Referral/CreateReferralDialog';
import { DeleteReferralBarButton } from 'features/proposal/components/Dialogs/DeleteReferralDialog/DeleteReferralButton';
import { isQuoteExpiringSoon } from 'features/proposal/utils';
import { getPathWithQueryParamsRemoved } from 'features/utils/deepLinkUtils';
import { OrderDirection, Permission, Referral, ReferralStatus } from 'generated/graphql';
import i18next from 'i18n';
import { ISearchBox } from 'office-ui-fabric-react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchProposalsSortField } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles/DialogueProvider';
import { oc } from 'ts-optchain';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { KeyCodes } from '@uifabric/utilities';

import { CloseReferralBarButton } from '../Dialogs/CloseReferralDialog/CloseReferralButton';
import { GetReferral, GetReferrals } from './Queries';
import { ReferralSummaryDetail } from './ReferralSummaryDetail';
import { referralSummaryListStyles } from './ReferralSummaryList.styles';

export interface SortQuery {
  orderField: string;
  orderDirection: string;
}

interface ReferralSummaryListProps {
  filter: string;
  searchComponentRef?: React.RefObject<ISearchBox>;
}

const mapStateToProps = (state: RootState) => ({
  environment: getAppEnvironment(state),
  userMail: getUserMail(state) || '',
});

type Props = ReferralSummaryListProps &
  WithStyles<typeof referralSummaryListStyles> &
  ReturnType<typeof mapStateToProps>;

const renderStatus = (state: string): JSX.Element => {
  switch (state) {
    case ReferralStatus.Draft:
      return <StatusIndicatorOpen text={i18next.t('Open')} />;
    case ReferralStatus.Transacted:
      return <StatusIndicatorTransacted text={i18next.t(state)} />;
    case ReferralStatus.Closed:
      return <StatusIndicatorClosed text={i18next.t(state)} />;
    case ReferralStatus.Expired:
      return <StatusIndicatorExpired text={i18next.t(state)} />;
    case ReferralStatus.Unknown:
    default:
      return (
        <StatusIndicatorUnknown>
          <Trans ns="home">Unknown</Trans>
        </StatusIndicatorUnknown>
      );
  }
};

let handleDelete = () => {
  return;
};

const setHandleDelete = (callback: () => void) => {
  handleDelete = callback;
};

const ReferralSummaryListUnconnected: React.FC<Props> = props => {
  const { filter, searchComponentRef, classes } = props;
  const { t } = useTranslation('quote');
  const history = useHistory();
  const { permissions } = useGetUserPermissions(props.userMail, props.environment);

  let crmId = new URLSearchParams(window.location.search.toLowerCase()).get('crmid') || undefined;
  const referralIdFromUrl = new URLSearchParams(window.location.search).get('id');
  crmId = crmId && crmId.toUpperCase();

  const [referrals, setReferrals] = React.useState<Referral[]>([]);
  const [referralsToDisplay, setReferralsToDisplay] = React.useState<Referral[]>([]);
  const [referral, setReferral] = React.useState<Referral | undefined>(undefined);
  const [activeReferralId, setActiveReferralId] = React.useState<string | undefined>(
    referral && referral.id
  );
  const [searchTerm, setSearchTerm] = React.useState('');
  const [sortQuery, setSortQuery] = React.useState<SortQuery>({
    orderField: SearchProposalsSortField.ModifiedDate,
    orderDirection: OrderDirection.Desc,
  });
  const refetchVariables = {
    query: searchTerm,
    options: { referralFilter: filter, sort: sortQuery },
  };
  const dialogContext = React.useContext(DialogContext);

  const { loading, error, refetch } = useQuery<{ getReferrals: Referral[] }>(GetReferrals, {
    variables: {
      query: searchTerm.trim(),
      options: { referralFilter: filter, sort: sortQuery },
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setReferrals(data && data.getReferrals);
    },
  });

  const hasReferralEditPermissions = permissions && permissions.includes(Permission.ReferralUpdate);
  React.useEffect(() => {
    if (!!crmId && !referralIdFromUrl && hasReferralEditPermissions) {
      openCreateReferralDialog(
        dialogContext,
        { query: refetch, variables: refetchVariables },
        crmId
      );
      history.replace(
        getPathWithQueryParamsRemoved(window.location.href, ['crmid', 'link', 'referrer'])
      );
    }
  }, [
    crmId,
    dialogContext,
    history,
    refetch,
    refetchVariables,
    referralIdFromUrl,
    hasReferralEditPermissions,
  ]);

  const [getReferral, { loading: referralLoading }] = useLazyQuery(GetReferral, {
    onCompleted: data => {
      setReferral(data.getReferral);
    },
  });

  useEffect(() => {
    const filteredReferralsToDisplay =
      filter === 'Active'
        ? referrals.filter(
            summary =>
              summary.status !== ReferralStatus.Closed && summary.status !== ReferralStatus.Expired
          )
        : filter === 'Closed'
        ? referrals.filter(summary => summary.status === ReferralStatus.Closed)
        : referrals.filter(summary => summary.status === ReferralStatus.Expired);
    setReferralsToDisplay(filteredReferralsToDisplay);
    if (filteredReferralsToDisplay && filteredReferralsToDisplay.length) {
      getReferral({ variables: { id: filteredReferralsToDisplay[0].id } });
      setActiveReferralId(filteredReferralsToDisplay[0].id);
    } else {
      setReferral(undefined);
      setActiveReferralId('');
    }
  }, [getReferral, referrals, filter]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    const target = e.target as HTMLElement;

    switch (e.keyCode) {
      case KeyCodes.up:
        e.preventDefault();
        const previousSibling = target.previousElementSibling as HTMLElement;
        if (previousSibling) previousSibling.focus();
        else {
          const lastSibling = (target.parentElement as HTMLElement).lastChild as HTMLElement;
          if (lastSibling) lastSibling.focus();
        }
        break;
      case KeyCodes.down:
        e.preventDefault();
        const nextSibling = target.nextElementSibling as HTMLElement;
        if (nextSibling) nextSibling.focus();
        else {
          const firstSibling = (target.parentElement as HTMLElement).firstChild as HTMLElement;
          if (firstSibling) firstSibling.focus();
        }
        break;
      case KeyCodes.space:
        e.preventDefault();
        target.click();
        break;
      case KeyCodes.enter:
        const link = target.querySelector('a');
        if (link) link.click();
        break;
      case KeyCodes.del:
        handleDelete();
        break;
    }
  };

  const onSearch = (value?: string) => {
    const newValue = value ? value.replace(/'/g, '') : '';
    setSearchTerm(newValue);
  };

  const onClearSearch = () => {
    setSearchTerm('');
  };

  const handleSort = (field: SearchProposalsSortField) => {
    return () => {
      let direction = sortQuery.orderDirection;
      if (direction === OrderDirection.Asc || field === SearchProposalsSortField.ModifiedDate) {
        direction = OrderDirection.Desc;
      } else {
        direction = OrderDirection.Asc;
      }
      const newSortQuery = {
        orderDirection: direction,
        orderField: field,
      };
      setSortQuery(newSortQuery);
    };
  };

  const emptyMessage = t('quote::No referrals found. Create a new referral or search for one.');
  const errorMessage = t('quote::Unable to load referrals');

  const getSortIcon = (sortField: SearchProposalsSortField) => {
    if (sortQuery.orderField !== sortField) {
      return <SmallIcon iconName="Sort" />;
    }
    if (sortQuery.orderDirection === OrderDirection.Asc) {
      return <SmallIcon iconName="SortUp" />;
    } else {
      return <SmallIcon iconName="SortDown" />;
    }
  };

  const errorWatermark = (
    <>
      <SmallIcon addClass={classes.errorBadge} iconName="StatusErrorFull" />
      <TextBodySmall>{errorMessage}</TextBodySmall>
    </>
  );

  const emptyWatermark = <TextWatermark>{emptyMessage}</TextWatermark>;

  const listWatermark = (
    <tr className="no-results">
      <td colSpan={4}>{error ? errorWatermark : emptyWatermark}</td>
    </tr>
  );

  const tableBodyWithContents =
    !!referralsToDisplay.length &&
    referralsToDisplay.map(referral => {
      const expirationDate = new Date(referral.expirationDate);
      const isExpiringSoon = isQuoteExpiringSoon(expirationDate);
      const closedDate = new Date(referral.modifiedDate);
      const crmId = referral.crmLead && referral.crmLead.id;
      return (
        <tr
          className={activeReferralId === referral.id ? 'active' : ''}
          data-automation-id={referral.id}
          key={referral.id}
          tabIndex={activeReferralId === referral.id ? 0 : -1}
          onClick={() => {
            getReferral({ variables: { id: referral.id } });
            setActiveReferralId(referral.id);
          }}
          onKeyDown={onKeyDown}
        >
          <td>{referral.name}</td>
          <td>{crmId && crmId.toUpperCase()}</td>
          <td className={classes.expirationDateCell}>
            {convertDateToFormattedString(
              referral.status === ReferralStatus.Closed ? closedDate : expirationDate,
              LocaleDateFormat.ll
            )}
            {/* TODO: Find out if expiringSoon is relevant for referrals */}
            {isExpiringSoon && referral.status !== ReferralStatus.Closed && (
              <div className={classes.warningIconContainer}>
                <MediumIcon iconName="Warning" />
              </div>
            )}
          </td>
          <td>{renderStatus(referral.status)}</td>
        </tr>
      );
    });

  const finishedAndHaveContent = !loading && !error && !!referralsToDisplay.length;
  const finishedEmpty = !loading && !referralsToDisplay.length;
  const finishedWithErrors = !loading && error;

  let tableBody = undefined;
  if (finishedAndHaveContent) {
    tableBody = tableBodyWithContents;
  } else if (finishedEmpty || finishedWithErrors) {
    tableBody = listWatermark;
  }

  const canEdit =
    hasReferralEditPermissions &&
    referral &&
    referral.msContact &&
    referral.msContact.mail === props.userMail;

  const canDelete =
    !referralLoading &&
    canEdit &&
    referral &&
    referral.status !== ReferralStatus.Closed &&
    referral.status !== ReferralStatus.Unknown;

  const canClose =
    !referralLoading && canEdit && referral && referral.status === ReferralStatus.Transacted;

  return (
    <div className={classes.list}>
      <div className={classes.table}>
        <div className={classes.bar}>
          <ActionBar grow={true} hideSeparator={true}>
            <BarButton
              ariaLabel={t('quote::New claim')}
              dataAutomationId="CreateReferralButton"
              iconName="Add"
              text={t('quote::New claim')}
              onClick={() =>
                openCreateReferralDialog(dialogContext, {
                  query: refetch,
                  variables: refetchVariables,
                })
              }
            />
            {filter !== 'Closed' && (
              <DeleteReferralBarButton
                dataAutomationId="deleteReferralButton"
                disabled={!canDelete}
                getReferralsVariables={refetchVariables}
                provideCallback={setHandleDelete}
                referral={referral}
                refetchReferrals={refetch}
              />
            )}
            {filter === 'Active' && (
              <CloseReferralBarButton
                dataAutomationId="CloseReferralButton"
                disabled={!canClose}
                referral={referral}
                refetchReferrals={{ query: refetch, variables: refetchVariables }}
              />
            )}
            <SummaryListBarSearch
              ariaLabel={t('quote::Search for {{item}}', {
                item: t('quote::Referrals').toLocaleLowerCase(),
              })}
              componentRef={searchComponentRef}
              dataAutomationId="summaryListBarSearch"
              defaultValue={searchTerm}
              focusPlaceholder={t(
                'quote::Search for referrals by name, CRM ID, MS contact, and more...'
              )}
              placeholder={t('quote::Search')}
              onChangeDebounced={onSearch}
              onClear={onClearSearch}
              onSearch={onSearch}
            />
          </ActionBar>
        </div>

        <table>
          <thead>
            <tr>
              <th>{t('quote::Name')}</th>
              <th>{t('quote::CRM ID')}</th>
              <th
                className={`sortable ${sortQuery.orderField ===
                  SearchProposalsSortField.ExpirationDate && 'sorted'}`}
                onClick={handleSort(SearchProposalsSortField.ExpirationDate)}
              >
                {filter === 'Active'
                  ? t('quote::Good until')
                  : filter === 'Closed'
                  ? t('quote::Closed on')
                  : t('quote::Expired on')}
                {getSortIcon(SearchProposalsSortField.ExpirationDate)}
              </th>
              <th>{t('quote::Status')}</th>
            </tr>
            <tr>
              <td className="progress" colSpan={4}>
                {loading && <ProgressIndicator />}
              </td>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>
      </div>

      <div className={`${classes.detail} ${activeReferralId ? classes.detailActive : ''}`}>
        {activeReferralId && (
          <ReferralSummaryDetail
            closed={filter === 'Closed'}
            needsRepair={oc(referral).needsRepair(false)}
            referralId={activeReferralId}
          />
        )}
      </div>
    </div>
  );
};

export const ReferralSummaryList = connect(mapStateToProps)(
  withStyles(referralSummaryListStyles)(ReferralSummaryListUnconnected)
);

// TODO:This is a hacky optimization, The main reason for this is if setState is in the SummaryList, summarylist renders on every keypress and that makes the input laggy
// With putting here only this component re-renders and not the whole list on every keypress, it get rids of a noticeable lag
interface SummaryListBarSearchProps extends BarSearchProps {
  defaultValue: string;
}

const SummaryListBarSearch = (props: SummaryListBarSearchProps) => {
  const [search, setSearch] = React.useState<string | undefined>(props.defaultValue);
  const onChange = (value?: string) => {
    const newValue = value ? value.replace(/'/g, '') : '';
    setSearch(newValue);
  };

  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referralId = urlParams.get('id');
    if (!!referralId) {
      props.onSearch && props.onSearch(referralId);
      setSearch(referralId);
      history.replace(
        getPathWithQueryParamsRemoved(window.location.href, ['id', 'link', 'referrer'])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, history]);

  const onClear = () => {
    setSearch(undefined);
    props.onClear && props.onClear();
  };
  const { defaultValue, ...rest } = props;

  return <BarSearch {...rest} value={search} onChange={onChange} onClear={onClear} />;
};
