import { TextboxAtomProps } from 'components/atoms';
import { LinkExternal, TextboxStandard, TextboxWithProgress } from 'components/ions';
import { getCRMConfig } from 'features/app/selectors';
import { getCRMLead } from 'features/customer/selectors';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import { sharedStyles } from 'features/proposal/shared.styles';
import { Market, modernMarkets } from 'features/proposal/supported-markets';
import { flexSectionCreator, isProposalReadOnly } from 'features/proposal/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { externalLinkMSX } from 'services/crm/config';
import * as LocalStorage from 'services/local-storage-service';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles/theme';
import { oc } from 'ts-optchain';

import { KeyCodes } from '@uifabric/utilities';

import { RemoveCrmIdButton } from '../../Dialogs/RemoveCrmIdDialog/RemoveCrmIdButton';
import { salesStyles } from './Sales.styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles,
  ...salesStyles(theme),
});

export enum CrmIdSteps {
  Search,
  Processing,
  Success,
}

export enum CrmIdErrorStates {
  InvalidMarket,
  CrmIdNotFound,
  InvalidCrmId,
  AddingCrmIdError,
  Valid,
}

export interface SalesHeaderProps {
  crmId?: string;
  proposal: Proposal;
}

export interface SalesHeaderStateProps extends SalesHeaderProps {
  loadingCrmId: boolean;
  isCRMIdMarketValid?: boolean;
  onSearchCrmId: (request: string, id: string) => void;
  errorAddingCRMId: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    loadingCrmId: selectors.addingCRMId(state),
    isCRMIdMarketValid: selectors.isCRMIdMarketValid(state),
    errorAddingCRMId: selectors.errorAddingCRMId(state),
    getCRMLead: (crmId: string) => getCRMLead(state, crmId),
    crmConfig: getCRMConfig(state),
  };
};

type Props = SalesHeaderStateProps & ReturnType<typeof mapStateToProps> & WithStyles<typeof styles>;

const dispatchProps = {
  onSearchCrmId: (request: string, id: string) =>
    actions.addNewCrmId.request({ crmId: request, proposalId: id }),
};

const SalesHeaderUnstyled: React.FC<Props> = ({
  crmId,
  proposal,
  classes,
  loadingCrmId,
  isCRMIdMarketValid,
  errorAddingCRMId,
  onSearchCrmId,
  getCRMLead,
  crmConfig,
}: Props) => {
  const { t } = useTranslation();
  const isReadOnly: boolean = isProposalReadOnly(proposal);

  //#region refactor to a selector
  const [currentStep, setCurrentStep] = React.useState<CrmIdSteps>(
    crmId ? CrmIdSteps.Success : CrmIdSteps.Search
  );
  const [cleanedCrmId, setCleanedCrmId] = React.useState('');
  const [isError, setIsError] = React.useState(CrmIdErrorStates.Valid);
  const [selectedCrmId, setSelectedCrmId] = React.useState(crmId || '');
  const [displayAnimatedViewInMSXLink, setDisplayAnimatedViewInMSXLink] = React.useState(false);
  const dispatchVerificationAction = (input: string) => {
    setCurrentStep(CrmIdSteps.Processing);
    onSearchCrmId(input, proposal.id);
    setSelectedCrmId(input);
    setDisplayAnimatedViewInMSXLink(true);
  };

  // Verify success or fail of action
  React.useEffect(() => {
    if (crmId !== undefined) {
      currentStep !== CrmIdSteps.Search && setCurrentStep(CrmIdSteps.Success);
      setIsError(CrmIdErrorStates.Valid);
      const market = oc(proposal).header.pricingContext.market();
      if (market !== undefined && modernMarkets.includes(market)) {
        // only update localMarketKey if market is on modern list
        LocalStorage.set<Market>(LocalStorage.localMarketKey, market);
      }
    } else if (
      ((!isCRMIdMarketValid && !loadingCrmId) || errorAddingCRMId) &&
      currentStep === CrmIdSteps.Processing
    ) {
      if (isCRMIdMarketValid === false) {
        setIsError(CrmIdErrorStates.InvalidMarket);
      } else if (errorAddingCRMId) {
        isCRMIdMarketValid === undefined
          ? setIsError(CrmIdErrorStates.CrmIdNotFound)
          : setIsError(CrmIdErrorStates.AddingCrmIdError);
      }
      setCurrentStep(CrmIdSteps.Search);
    }
  }, [
    crmId,
    isCRMIdMarketValid,
    loadingCrmId,
    setIsError,
    currentStep,
    proposal,
    errorAddingCRMId,
  ]);
  //#endregion

  const getErrorMessage = (): string => {
    switch (isError) {
      case CrmIdErrorStates.CrmIdNotFound:
        return t('quote::CRM ID not found');
      case CrmIdErrorStates.InvalidMarket:
        return t(
          'quote::The customer account associated with the CRM ID is in an unsupported market. You must either select a different account for this customer or sell to them using a different sales motion.'
        );
      case CrmIdErrorStates.InvalidCrmId:
        return t('quote::Enter a valid CRM ID');
      case CrmIdErrorStates.AddingCrmIdError:
        return t('quote::An error occurred while adding the CRM ID');
      case CrmIdErrorStates.Valid:
      default:
        return '';
    }
  };

  const handleCrmEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter) {
      if (cleanedCrmId && isError !== CrmIdErrorStates.InvalidCrmId) {
        dispatchVerificationAction(cleanedCrmId);
      }
    }
  };

  const validCrmIdMinLength = 7;

  const sharedTexboxProps: Partial<TextboxAtomProps> = {
    addClass: classes.field,
    label: t('quote::CRM ID'),
    required: true,
    dataAutomationId: 'crmId',
  };

  const viewCrmIdSearch = flexSectionCreator(
    classes.flexXLarge,
    classes.flexXLarge,
    <TextboxStandard
      {...sharedTexboxProps}
      ariaLabel={t('quote::Enter or search for a CRM ID')}
      dataAutomationId="salesCRMId"
      disabled={isReadOnly}
      errorMessage={getErrorMessage()}
      placeholder={t('quote::Enter or search for a CRM ID')}
      value={cleanedCrmId}
      onBlur={() => {
        if (cleanedCrmId && isError !== CrmIdErrorStates.InvalidCrmId) {
          dispatchVerificationAction(cleanedCrmId);
        }
      }}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        value?: string
      ) => {
        const clean = value ? value.replace(/ /g, '') : '';
        setCleanedCrmId(clean);
        if (!!clean.length && clean.length < validCrmIdMinLength)
          return setIsError(CrmIdErrorStates.InvalidCrmId);
        if (clean !== crmId) setIsError(CrmIdErrorStates.Valid);
      }}
      onKeyDown={handleCrmEnter}
    />,
    classes.flexContainer
  );
  const viewCrmIdLoading = flexSectionCreator(
    classes.flexXLarge,
    classes.flexXLarge,
    <span>
      <TextboxWithProgress {...sharedTexboxProps} disabled value={selectedCrmId} />
    </span>,
    classes.flexContainer
  );
  let crmLead;
  if (crmId) {
    crmLead = getCRMLead(crmId);
  }

  const onRemove = () => {
    setCurrentStep(CrmIdSteps.Search);
    setCleanedCrmId('');
  };
  const msxBaseUrl = externalLinkMSX[crmConfig.environment];
  let msxUrl;
  if (crmLead) {
    msxUrl = msxBaseUrl.replace('{entityName}', crmLead.entityName);
    msxUrl = msxUrl.replace('{id}', crmLead.id);
  }
  const viewCrmId = flexSectionCreator(
    classes.flexXLarge,
    null,
    <span>
      <TextboxStandard {...sharedTexboxProps} readOnly value={crmId && crmId.toUpperCase()} />
      {crmLead && (
        <div
          className={displayAnimatedViewInMSXLink ? classes.crmIdLinkAnimate : classes.crmIdLink}
        >
          <LinkExternal
            dataAutomationId="viewInMSXLink"
            displayText={t('quote::view in MSX')}
            href={msxUrl}
            size="medium"
          />
        </div>
      )}
    </span>,
    classes.flexContainer,
    classes.flexXLarge,
    <div>
      <span className={classes.paddingRightMedium} />
      {!isReadOnly && <RemoveCrmIdButton addClass={classes.crmIdRemove} onRemoveClick={onRemove} />}
    </div>
  );
  const getCurrentStep = (step: CrmIdSteps) => {
    switch (step) {
      case CrmIdSteps.Search:
        return viewCrmIdSearch;
      case CrmIdSteps.Processing:
        return viewCrmIdLoading;
      case CrmIdSteps.Success:
        return viewCrmId;
    }
  };

  const crmIdSection: JSX.Element = getCurrentStep(currentStep);

  return crmIdSection;
};

const SalesHeaderStyled = withStyles(styles)(SalesHeaderUnstyled);
export const SalesHeader = connect(mapStateToProps, dispatchProps)(SalesHeaderStyled);
