import { ComboBox, TextBody } from 'components';
import { GqlLanguage, LanguageInfo } from 'generated/graphql';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { OrgWizardSharedProps, WizardDialog } from '../Shared';
import { invoiceLanguageStyles } from './InvoiceLanguageDialog.styles';

export interface InvoiceLanguageBodyProps extends OrgWizardSharedProps<GqlLanguage> {
  invoiceLanguages: LanguageInfo[];
}

type Props = InvoiceLanguageBodyProps & WithStyles<typeof invoiceLanguageStyles>;

const InvoiceLanguageDialogUnstyled: React.FC<Props> = props => {
  const { classes, initialData, invoiceLanguages } = props;
  const { t } = useTranslation();
  const defaultLanguage = GqlLanguage.EnUs;

  const [language, setLanguage] = React.useState<GqlLanguage>(initialData || defaultLanguage);

  const onClickOverview = () => {
    props.onOverviewClick(language);
  };

  const buildComboBoxLanguageOptions = (languages: LanguageInfo[]): IComboBoxOption[] => {
    return languages.map((language: LanguageInfo) => {
      return {
        key: language.gqlCode,
        text: language.display,
      };
    });
  };

  const languageComboBoxOptions = buildComboBoxLanguageOptions(invoiceLanguages);

  const onInvoiceLanguageChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option) {
      const language = option.key as GqlLanguage;
      setLanguage(language);
    }
  };

  const body = (
    <div>
      <TextBody addClass={classes.secondaryText}>
        {t(
          `quote::The invoice language determines the language your customer's invoice will be in. Unfortunately, there is currently an issue with the system that prevents this from being changed once the Billing Account has been created.`
        )}
      </TextBody>
      <div className={classes.textSpacer} />
      <TextBody addClass={classes.secondaryText}>
        {t(
          'quote::Please make sure you select the language your customer wants before creating the Billing Account.'
        )}
      </TextBody>
      <div className={classes.comboBoxStyles}>
        <ComboBox
          id="invoicing-language-input"
          label={t('quote::Invoice language')}
          options={languageComboBoxOptions}
          required={true}
          selectedKey={language || defaultLanguage}
          onChange={onInvoiceLanguageChange}
        />
      </div>
    </div>
  );
  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      headline={props.headline}
      isOverviewAvailable={props.isOverviewAvailable}
      overviewButtonOnly={true}
      title={t('quote::Invoice language')}
      onOverviewClick={onClickOverview}
    />
  );
};

export const InvoiceLanguageDialog = withStyles(invoiceLanguageStyles)(
  InvoiceLanguageDialogUnstyled
);
