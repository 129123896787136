import gql from 'graphql-tag';

export const GetModernAgreement = gql`
  query GetModernAgreement($agreementId: String!, $accountId: String!, $organizationId: String!) {
    getModernAgreement(
      agreementId: $agreementId
      accountId: $accountId
      organizationId: $organizationId
    ) {
      participants {
        companyId
        role
      }
    }
  }
`;
