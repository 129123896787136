import { CalendarInputProps } from './CalendarInput';

export const calendarInputStyles = () => {
  return {
    calendarContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: (props: CalendarInputProps) =>
        props.maxWidth != null && props.maxWidth >= 0 ? props.maxWidth : 'unset',
      width: (props: CalendarInputProps) => (props.stretch ? '100%' : 'auto'),
    },
    flexItem: {
      flexGrow: 2,
    },
    calendarButtonContainer: {
      paddingTop: 29,
    },
    flexSpacer: {
      flex: '1 0 8px',
      maxWidth: '8px',
    },
  };
};
