import { ThemeProps } from 'styles';

export const existingDiscountsListStyles = (theme: ThemeProps) => {
  const { semiBold } = theme.fonts.fontWeights;
  const colors = theme.palette;

  return {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    ceilingFutureGroup: {
      marginBottom: '15px',
    },
    group: {
      marginBottom: '12px',
    },
    existingDiscountGroup: {
      marginBottom: '8px',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      '& >span': {
        display: 'flex',
      },
      marginBottom: '4px',
    },
    rowLabel: {
      width: '60px',
      color: colors.textTertiary,
    },
    rowValue: {
      color: colors.text,
    },
    title: {
      paddingBottom: '3px',
    },
    existingDiscountRow: {
      display: 'flex',
      flexDirection: 'column',
      '& >span': {
        display: 'flex',
      },
    },
    lineHeight: {
      lineHeight: 0.9,
    },
    dateRowLabel: {
      width: '40px',
      color: colors.textTertiary,
    },
    oneTimeLabel: {
      width: '200px',
      color: colors.textTertiary,
    },
    dates: { color: colors.textTertiary },
    percentage: {
      marginLeft: 'auto',
      marginRight: '20px',
      color: colors.textTertiary,
    },
    semiBold: {
      fontWeight: semiBold,
    },
  };
};
