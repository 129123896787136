import { getExchangeRates } from 'features-apollo/quote/selectors/lineItem';
import { LineItem, MessageSource } from 'generated/graphql';
import i18next from 'i18n';

import { getErrorForSpecificListRowMessage } from '../utils';

/**
 * Gets message to display in product list row.
 *
 * NOTE: The message is different from what it is displayed on the details pane per requirements.
 */
export const getProductListRowMessage = (lineItem: LineItem) => {
  const message = getErrorForSpecificListRowMessage(lineItem);
  if (!message) {
    return;
  }

  switch (message.messageSource) {
    case MessageSource.LineitemBroken:
      return i18next.t(
        'quote::This product failed to load. This could have been caused by a change in market, national cloud, or product availability.'
      );
    case MessageSource.LineitemDuplicate:
      const { configurationSummary } = lineItem;

      return i18next.t(
        'quote::Only one {{configuration}} is needed. Please delete the duplicate line item.',
        { configuration: configurationSummary && configurationSummary.display }
      );
  }
};

/**
 * Gets the exchange rate use in line items if are all the same.
 */
export const getExchangeRate = (lineItems: LineItem[]) => {
  const exchangeRates = getExchangeRates(lineItems);

  return exchangeRates.length === 1 ? exchangeRates[0] : undefined;
};

/**
 * Whether or not the line item is in a warning state.
 */
export const isLineItemWarningState = (lineItem: LineItem) =>
  lineItem.messages.some(
    message => message.messageSource === MessageSource.LineitemMissingConfiguration
  );
