import { ChoiceGroup, SectionSeparator, TextBody } from 'components';
import { getProductFragmentsIndexed } from 'features/catalog/selectors';
import { getAssetsByAccountId } from 'features/customer/selectors';
import { loadAssetProducts } from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import { IChoiceGroupOption, Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import { AssetListItem } from 'services/edge/types';
import { RootState } from 'store/types';

import { choiceGroupStyles, convertAssetDialogStyles } from './ConvertAssetDialog.styles';
import { buildAssetConversionOptions, buildAssetDetailsContent } from './utils';

const mapStateToProps = (state: RootState) => {
  const proposal = selectors.getActiveProposal(state);
  const accountId = selectors.getAccountId(state, proposal);
  return {
    products: getProductFragmentsIndexed(state),
    assets: accountId ? getAssetsByAccountId(state, accountId) : undefined,
  };
};

const dispatchProps = {
  loadAssetProducts: (request: string[]) => loadAssetProducts(request),
};

export interface ConvertAssetConversionDialogProps {
  selectedAssetListItem?: AssetListItem;
  setSelectedAssetProduct: () => void;
}

type Props = ConvertAssetConversionDialogProps &
  WithStyles<typeof convertAssetDialogStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const ConvertAssetConversionDialogUnstyled: React.FC<Props> = props => {
  const {
    classes,
    selectedAssetListItem,
    assets,
    products,
    setSelectedAssetProduct,
    loadAssetProducts,
  } = props;
  const [assetProductChoice, setAssetProductChoice] = React.useState<
    IChoiceGroupOption | undefined
  >();
  const [assetProduct, setAssetProduct] = React.useState<Product | undefined>();

  React.useEffect(() => {
    // assetProductChoice.key looks like this -> ProductId/SkuId
    const assetProductId =
      assetProductChoice && assetProductChoice.key && assetProductChoice.key.split('/')[0];
    const assetLoadedProduct = assetProductId && products[assetProductId];
    if (!assetProduct) {
      if (assetLoadedProduct) {
        setAssetProduct(assetLoadedProduct);
      } else if (assetProductId) {
        loadAssetProducts([assetProductId]);
      }
    }
  }, [assetProduct, assetProductChoice, loadAssetProducts, products]);

  const { t } = useTranslation();
  const selectedProduct =
    selectedAssetListItem && products && products[selectedAssetListItem.productId];
  const selectedAsset =
    selectedAssetListItem && assets && assets.find(asset => asset.id === selectedAssetListItem.key);

  const handleOnChangeAssetChoice = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined
  ) => {
    if (option) {
      const assetProductId = option.key.split('/')[0];
      const newAssetProduct = assetProductId ? products[assetProductId] : undefined;

      setAssetProductChoice(option);
      setAssetProduct(newAssetProduct);
      setSelectedAssetProduct();
    }
  };

  const placeholder = (
    <div className={classes.convertDialogAssetDetails}>
      <TextBody>{t('quote::Converting to:')}</TextBody>
      <div className={classes.placeholder}>
        {t('quote::Select product above to view conversion')}
      </div>
    </div>
  );

  return (
    <div className={classes.convertActionableAssetDialogBody}>
      <TextBody>{t('quote::Which product your customer would like convert to:')}</TextBody>
      <div className={classes.flexAlignStart}>
        <ChoiceGroup
          options={buildAssetConversionOptions(selectedProduct, selectedAsset, products)}
          required
          selectedKey={assetProductChoice && assetProductChoice.key}
          styles={choiceGroupStyles}
          onChange={handleOnChangeAssetChoice}
        />
      </div>
      <SectionSeparator />
      <div className={classes.convertActionableAssetDetails}>
        {buildAssetDetailsContent(
          t('quote::Existing asset:'),
          classes,
          selectedProduct,
          selectedAsset,
          selectedAssetListItem
        )}
      </div>
      <div className={classes.convertActionableAssetDetailsIcon}>
        <Icon iconName="Forward"></Icon>
      </div>
      <div className={classes.convertActionableAssetDetails}>
        {assetProduct
          ? buildAssetDetailsContent(
              t('quote::Converting to:'),
              classes,
              assetProduct,
              selectedAsset,
              selectedAssetListItem
            )
          : placeholder}
      </div>
    </div>
  );
};

export const ConvertAssetConversionDialogUnconnected = withStyles(convertAssetDialogStyles)(
  ConvertAssetConversionDialogUnstyled
);

export const ConvertAssetConversionDialog = connect(
  mapStateToProps,
  dispatchProps
)(ConvertAssetConversionDialogUnconnected);
