import { AgreementType, CustomerType, Quote } from 'generated/graphql';
import { oc } from 'ts-optchain';

/**
 * Determines if the quote is a legacy quote based on the agreement type
 *
 * @param quote quote object
 */
export const isQuoteLegacy = (quote?: Quote) => oc(quote).agreementType() === AgreementType.Legacy;

/**
 * Gets the account id define in the soldTo property in the organization (partner account id for indirect quote context)
 *
 * @param quote quote object
 */
export const getSoldToAccountId = (quote?: Quote) => oc(quote).soldTo.organization.accountId();

/**
 * Gets the organization id define in the soldTo property in the organization (partner organization id for indirect quote context)
 *
 * @param quote quote object
 */
export const getSoldToOrganizationId = (quote?: Quote) => oc(quote).soldTo.organization.id();

/**
 * Gets the account id define in the endCustomer property in the organization (customer account id for indirect quote context)
 *
 * @param quote quote object
 */
export const getEndCustomerAccountId = (quote?: Quote) => oc(quote).endCustomer.accountId();

/**
 * Gets the organization id define in the endCustomer property in the organization (customer organization id for indirect quote context)
 *
 * @param quote quote object
 */
export const getEndCustomerOrganizationId = (quote?: Quote) => oc(quote).endCustomer.id();

/**
 * Determines if the email and phone number of an organization in the quote can be edited.
 *
 * @param quote quote object
 * @param customerType determines where the organization information is stored in the quote
 */
export const isContactInformationEditable = (quote?: Quote, customerType?: CustomerType) => {
  const isCustomerInformationEditable = !oc(quote).readOnly() && !isQuoteLegacy(quote);

  switch (customerType) {
    case CustomerType.SoldToCustomer:
      const soldToAccountId = getSoldToAccountId(quote);
      const soldToOrganizationId = getSoldToOrganizationId(quote);

      return isCustomerInformationEditable && !!soldToAccountId && !!soldToOrganizationId;
    case CustomerType.EndCustomer:
    default:
      const endCustomerAccountId = getEndCustomerAccountId(quote);
      const endCustomerOrganizationId = getEndCustomerOrganizationId(quote);

      return isCustomerInformationEditable && !!endCustomerAccountId && !!endCustomerOrganizationId;
  }
};
