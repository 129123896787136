import { IChoiceGroupStyles } from 'office-ui-fabric-react';

export const discountCardStyles = {
  discountInputContainer: {
    width: 105,
    height: 60,
  },
  itallicStyle: {
    '& .ms-TextField-field': {
      fontStyle: 'italic',
    },
  },
  calendarButtonContainer: {
    paddingTop: 30,
  },
  flexSpacer: {
    flex: '1 0 16px',
    maxWidth: '16px',
  },
  gapBetweenDiscountInputAndSeparator: {
    marginTop: 18,
  },
  gapBetweenSeparatorAndSections: {
    marginTop: 12,
  },
  removeDiscount: {
    marginTop: 20,
  },
  errorMessageStyle: {
    width: 216,
  },
  mixedValues: {
    marginTop: 10,
    marginBottom: 8,
    maxWidth: 200,
  },
};

export const choiceGroupStyles: IChoiceGroupStyles = {
  flexContainer: {},
  root: {
    marginTop: 14,
    selectors: {
      '.ms-ChoiceField': {
        display: 'inline-block',
        paddingRight: 12,
      },
    },
  },
};
