import { headerButtonStyles } from 'components/ions/Buttons/HeaderButton/HeaderButton.styles';
import { buttonSharedStyles } from 'components/ions/Buttons/shared.styles';
import { ThemeProps } from 'styles';

export const calloutHeaderStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    ...buttonSharedStyles(theme),
    ...headerButtonStyles(theme),
    buttonContainer: {
      display: 'inline-block',
    },
    calloutMain: {
      backgroundColor: colors.backgroundStandout,
      borderRadius: '0px 0px 2px 2px',
    },
    calloutContentPadding: {
      padding: '24px 16px 24px 16px',
    },
    root: {
      borderRadius: '0px 0px 2px 2px',
      borderStyle: 'none',
      boxShadow: `${colors.shadowColor} 0px 5px 7px -2px`,
    },
  };
};
