// TODO: This Market type may change and or move

import { DiscountLineItem, DiscountType, PurchaseLineItem, TermLineItem } from 'generated/graphql';
import {
  DirectionalHint,
  IChoiceGroupOption,
  IComboBox,
  IComboBoxOption,
} from 'office-ui-fabric-react';

export const discountConstants = {
  Entitlement: 'Entitlement',
  azureFamilyDiscount: 'AzureFamilyDiscount',
  azureSupport: 'AzureSupport',
  MeterType: 'MeterType',
  dateFormat: 'YYYY-MM-DD',
};

export interface DateRange {
  minDate?: Date;
  maxDate?: Date;
  selected: Date;
}

export interface DiscountCardDateRanges {
  startDate: DateRange;
  endDate: DateRange;
  ceilingDate: DateRange;
}

export interface DiscountCardDropdownOptions {
  options: IComboBoxOption[];
  selectedKey: string;
}

export enum ConfigType {
  discount = 'discount',
  financeTerm = 'financeterm',
}

export enum DiscountConfigField {
  endDate = 'enddate',
  startDate = 'startdate',
  ceilingDate = 'ceilingdate',
  meter = 'meter',
  percentage = 'percentage',
}

export interface DiscountCardProps {
  target: React.RefObject<HTMLSpanElement>;
  directionalHint?: DirectionalHint;
  hidden?: boolean;
  onDismiss: () => void;
  selectedItemIds: string[];
}

export interface DiscountCardState {
  discountAmount: string;
  discountType: string;
  futureOptions: FutureDateOptions;
  ceilingOptions: CeilingDateOptions;
  errorMessage?: string | null;
  isBulkDiscount: boolean;
  isBulkUnconfigured?: boolean;
  amountError?: string;
  enableRemoveDiscount: boolean;
  selectedIds?: string[];
  selectedDiscountableItems: (DiscountLineItem | PurchaseLineItem)[];
  showMixedValuesWaterMark: boolean;
  meters?: DiscountCardDropdownOptions;
  meterErrorMessage?: string;
  initialStateSet?: boolean;
}

export interface DiscountOptions {
  type: string;
  options: {
    startDate: boolean;
    endDate: boolean;
    removeDiscount: boolean;
    ceilingDate?: boolean;
    meters?: { key: string; value: string }[];
  };
}

export interface DiscountChoiceGroupProps {
  discountTypeChoices: IChoiceGroupOption[];
  discountChoice: string;
  onChangeDiscountType: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => void;
  isReadOnly: boolean;
}

export interface DiscountDateSelections {
  startOptions?: DiscountCardDropdownOptions;
  endOptions?: DiscountCardDropdownOptions;
  ceilingOptions?: DiscountCardDropdownOptions;
  dateRanges?: DiscountCardDateRanges;
}

export interface CeilingDateOptions {
  priceCeilingOptions: DiscountCardDropdownOptions;
  startOptions: DiscountCardDropdownOptions;
  useDayPicker: boolean;
  disabled?: boolean;
  startEnabled?: boolean;
  dateRanges: DiscountCardDateRanges;
  endOptions: DiscountCardDropdownOptions;
  endEnabled?: boolean;
  setDateState?: (dates: DiscountDateSelections) => void;
}

export interface FutureDateOptions {
  startOptions: DiscountCardDropdownOptions;
  endOptions: DiscountCardDropdownOptions;
  dateRanges: DiscountCardDateRanges;
  endEnabled?: boolean;
  useDayPicker: boolean;
  meterOptions?: DiscountCardDropdownOptions;
  showMeters?: boolean;
  meterErrorMessage?: string;
  setDateState?: (dates: DiscountDateSelections) => void;
  setMeter?: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  disabled?: boolean;
}

export interface ApplicableDiscounts {
  types: DiscountType[];
  minStartDate: Date;
  useDayPicker: boolean;
}

interface MockLineItemProps {
  applicableDiscounts?: ApplicableDiscounts;
}
export type MockPurchaseLineItem = PurchaseLineItem & MockLineItemProps;
export type MockDiscountLineItem = DiscountLineItem & MockLineItemProps;
export type MockTermLineItem = TermLineItem & MockLineItemProps;
