import { BlurrableAtomProps } from './BlurrableAtom';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

export const blurrableStyles = (theme: ThemeProps) => ({
  backgroundContent: {
    height: '100vh',
    width: '100%',
  },
  frontContainer: {
    alignItems: 'center',
    backdropFilter: 'blur(6px)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'fixed',
    top: (props: BlurrableAtomProps) => oc(props).blurPosition.top(0),
    right: (props: BlurrableAtomProps) => oc(props).blurPosition.right(0),
    width: '100%',
    zIndex: theme.zIndex.sideBar + 1,
  },
  frontContent: {
    // shift upwards to offset the parent 'top' property so content remains centered vertically
    marginTop: (props: BlurrableAtomProps) => -oc(props).blurPosition.top(0),
  },
});
