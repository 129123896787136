import { LinkButton, SecondaryButton, Spinner, TextBody, TextBodySmall } from 'components';
import { CatalogContext } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { getProductRows } from './CatalogSectionRow';
import { searchSectionStyles } from './shared.styles';
import { CatalogPageProduct, ProductFamily, ServiceFamily } from './types';
/**
 * Props for the Search Section
 * @prop {ProductFamily} productFamily - ProductFamily to query catalog to populate section
 * @prop {CatalogPageProduct[]} products - list of products searched for
 * @prop {boolean} searchingCatalogProducts - states if producta are still loading
 * @prop {ServiceFamily} serviceFamily - ServiceFamily to query catalog to populate section
 * @prop {string} queryString - value searched for
 * @prop {boolean} moreResultsAvailable - To populate loadMore button
 * @prop {function} onExpandSearch - search to perform if additional results available
 * @prop {function} onClearSearch - function to call when clearing search term and results
 */
export interface SearchSectionProps {
  productFamily: ProductFamily;
  products: CatalogPageProduct[];
  searchingCatalogProducts: boolean;
  serviceFamily: ServiceFamily;
  queryString: string;
  moreResultsAvailable: boolean;
  onExpandSearch: () => void;
  onClearSearch: () => void;
  catalogContext: CatalogContext;
}

type Props = SearchSectionProps & WithStyles<typeof searchSectionStyles>;

export const SearchSectionUnstyled: React.FC<Props> = props => {
  const [selectedProduct, setSelectedProduct] = React.useState<string | undefined>();

  const { t } = useTranslation();

  const handleTileOnClick = (id: string) => {
    if (selectedProduct !== id) {
      setSelectedProduct(id);
    } else {
      setSelectedProduct(undefined);
    }
  };

  const handleDetailsOnClose = () => {
    setSelectedProduct(undefined);
  };

  const noResultsFound = (
    <div>
      <div>
        <TextBody>
          {t(`home::We could not find any results for "{{searchValue}}"`, {
            searchValue: props.queryString,
          })}
        </TextBody>
      </div>
      <div>
        <TextBody>
          {t('home::Try using a simpler term or search using a different term altogether.')}
        </TextBody>
      </div>
    </div>
  );

  return props.searchingCatalogProducts && !props.products.length ? (
    <Spinner className={props.classes.spinner} />
  ) : (
    <div className={props.classes.searchSection}>
      <div className={props.classes.header}>
        <div>
          <TextBodySmall dataAutomationId="searchSectionResultFoundCount">
            {t('home::{{count}} result found', {
              count: props.products.length,
              defaultValue_plural: '{{count}} results found', // eslint-disable-line @typescript-eslint/camelcase
            })}
          </TextBodySmall>
        </div>
        <div className={props.classes.clearSearchLink}>
          <LinkButton
            dataAutomationId="searchSectionClearSearch"
            displayText={t('home::clear search')}
            onClick={props.onClearSearch}
          />
        </div>
      </div>
      {props.products.length
        ? getProductRows(
            props.products,
            props.productFamily,
            handleDetailsOnClose,
            handleTileOnClick,
            selectedProduct,
            props.catalogContext
          )
        : noResultsFound}
      {props.moreResultsAvailable && (
        <div className={props.classes.expandSearch}>
          <SecondaryButton
            dataAutomationId="expandSearchButton"
            text={t('home::Expand search')}
            onClick={props.onExpandSearch}
          />
        </div>
      )}
    </div>
  );
};

export const SearchSection = withStyles(searchSectionStyles)(SearchSectionUnstyled);
