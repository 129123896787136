import { getFlightIsEnabled } from 'features/app/selectors';
import { loadProducts, loadReco } from 'features/catalog/sagas/auxiliary';
import {
  hydrateLite,
  loadAccount,
  loadAgreements,
  loadEnrollment,
  loadLeadOrganization,
  loadOrganization,
  loadPreapprovedECIFs,
  loadProjectFromEnrollment,
  searchOrganizationsPropertySheet,
} from 'features/customer/sagas/auxiliary';
import { loadCustomPrices } from 'features/customer/sagas/auxiliary/custom-prices';
import { loadAssetsByOrganization } from 'features/customer/sagas/auxiliary/hydrate';
import { Address } from 'features/customer/types';
import * as actions from 'features/proposal/actions';
import { getAllProductIds, getSalesAccountAddressFromQuote } from 'features/proposal/selectors';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import {
  areSoldToAndEndCustomerDifferent,
  getCRMId,
  hasEndCustomer,
  hasLeadOrgId,
  hasSoldToCustomer,
  isAgreementTypeLegacy,
  isProposalReadOnly,
} from 'features/proposal/utils';
import * as userActions from 'features/user/actions';
import { loadUserRoles } from 'features/user/sagas/auxiliary';
import { all, call, put, select, SimpleEffect, spawn } from 'redux-saga/effects';
import { safelistedCID } from 'services/credit/config';
import { CreditCheckEventType } from 'services/credit/types';
import { Flight } from 'services/flights/flightList';
import { Proposal, ProposalStatus } from 'services/proposal/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import {
  loadApproval,
  loadCreditInfo,
  loadPurchaseRecord,
  loadTenantNamesFromAccount,
} from './serviceCalls';
import { ensureLineItemAvailabilities } from './utils';

export function commonHydration(quote: Proposal, isSafeListedCreditCheckEnabled?: boolean) {
  const simpleEffects: SimpleEffect<unknown, unknown>[] = [];

  if (quote.header.msContact) {
    simpleEffects.push(call(loadUserRoles, quote.header.msContact));
  }
  simpleEffects.push(put(userActions.loadMSContactAsync.request(quote.header.msContact)));

  const accountId = oc(quote).header.soldToCustomerLegalEntity.accountId('');
  const organizationId = oc(quote).header.soldToCustomerLegalEntity.organizationId('');
  if (hasSoldToCustomer(quote)) {
    simpleEffects.push(
      call(loadOrganization, {
        accountId,
        id: organizationId,
      })
    );

    simpleEffects.push(call(loadAssetsByOrganization, accountId));

    if (hasLeadOrgId(quote)) {
      const leadOrgId = oc(quote).header.extendedProperties.userPreferences.leadOrgId('');
      simpleEffects.push(
        call(loadLeadOrganization, {
          accountId,
          id: leadOrgId,
        })
      );
    }

    if (!isAgreementTypeLegacy(quote)) {
      simpleEffects.push(
        call(loadCreditInfo, {
          legalEntity: {
            accountId: isSafeListedCreditCheckEnabled ? safelistedCID.accountId : accountId,
            organizationId: isSafeListedCreditCheckEnabled
              ? safelistedCID.organizationId
              : organizationId,
          },
          currency: oc(quote).header.pricingContext.billingCurrency() || DEFAULT_CURRENCY,
          // eslint-disable-next-line @typescript-eslint/camelcase
          event_type: CreditCheckEventType.CreditLookUp,
        })
      );
    }
    if (hasEndCustomer(quote)) {
      const accountId = oc(quote).header.endCustomerLegalEntity.accountId('');
      const organizationId = oc(quote).header.endCustomerLegalEntity.organizationId('');
      simpleEffects.push(
        call(loadOrganization, {
          accountId,
          id: organizationId,
        })
      );
      simpleEffects.push(call(loadAssetsByOrganization, accountId));
    }
  }
  if (quote.header.approval.id) {
    simpleEffects.push(call(loadApproval, quote.header.approval.id));
  }

  return simpleEffects;
}

export function* hydrateQuoteLite(quote: Proposal) {
  const quoteId = quote.id;
  yield put(actions.hydrateQuoteLite.request(quoteId));
  const isSafeListedCreditCheckEnabled: boolean = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.safelistedCreditCheck)
  );
  try {
    const calls = yield call(commonHydration, quote, isSafeListedCreditCheckEnabled);
    const crmId = getCRMId(quote);
    if (crmId) {
      calls.push(call(hydrateLite, crmId));
    }
    yield all(calls);
    yield put(actions.hydrateQuoteLite.success(quoteId));
  } catch (err) {
    yield put(
      actions.hydrateQuoteLite.failure({
        message: t('error::Error hydrating quote (lite)'),
        exception: err,
      })
    );
    throw err;
  }
}

export function* hydrateQuoteAll(quote: Proposal) {
  const preapprovedECIFsEnabled: boolean = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.ECIF)
  );
  const isSafeListedCreditCheckEnabled: boolean = yield select((state: RootState) =>
    getFlightIsEnabled(state, Flight.safelistedCreditCheck)
  );
  const quoteId = quote.id;

  yield put(actions.hydrateQuoteAll.request(quoteId));
  try {
    const calls = yield call(commonHydration, quote, isSafeListedCreditCheckEnabled);
    const isQuoteLegacy = isAgreementTypeLegacy(quote);

    if (
      quote.header.soldToCustomerLegalEntity &&
      quote.header.soldToCustomerLegalEntity.accountId &&
      quote.header.soldToCustomerLegalEntity.organizationId
    ) {
      //get existing discounts for the quote's orgId
      calls.push(
        call(
          loadCustomPrices,
          quote.header.soldToCustomerLegalEntity.accountId,
          quote.header.soldToCustomerLegalEntity.organizationId
        )
      );
    }
    if (
      quote.header.soldToCustomerLegalEntity &&
      quote.header.soldToCustomerLegalEntity.accountId &&
      quote.header.extendedProperties &&
      quote.header.extendedProperties.userPreferences &&
      quote.header.extendedProperties.userPreferences.leadOrgId
    ) {
      //get existing discounts for the quote's org's lead orgId (shared discounts)
      calls.push(
        call(
          loadCustomPrices,
          quote.header.soldToCustomerLegalEntity.accountId,
          quote.header.extendedProperties.userPreferences.leadOrgId
        )
      );
    }
    calls.push(call(loadProducts, getAllProductIds(quote)));
    const crmId = getCRMId(quote);
    if (crmId) {
      calls.push(call(hydrateLite, crmId));
    }

    if (hasEndCustomer(quote)) {
      const endCustomer = oc(quote).header.endCustomerLegalEntity();
      calls.push(call(loadAccount, oc(endCustomer).accountId('')));
      calls.push(
        call(loadAgreements, oc(endCustomer).accountId(''), oc(endCustomer).organizationId(''))
      );
      if (!isQuoteLegacy)
        calls.push(call(loadTenantNamesFromAccount, oc(endCustomer).accountId('')));
    }
    if (hasSoldToCustomer(quote)) {
      if (areSoldToAndEndCustomerDifferent(quote)) {
        const soldTo = oc(quote).header.soldToCustomerLegalEntity();
        calls.push(call(loadAccount, oc(soldTo).accountId('')));
        calls.push(call(loadAgreements, oc(soldTo).accountId(''), oc(soldTo).organizationId('')));
        if (!isQuoteLegacy) calls.push(call(loadTenantNamesFromAccount, oc(soldTo).accountId('')));
      }
    }
    const enrollmentNumber = oc(quote).header.extendedProperties.vlAgreementNumber();

    if (isQuoteLegacy) {
      if (enrollmentNumber) {
        calls.push(call(loadProjectFromEnrollment, enrollmentNumber));
      }
      if (
        quote.header.status === ProposalStatus.Active &&
        quote.lineItems.length &&
        quote.lineItems[0].purchaseId
      ) {
        calls.push(call(loadPurchaseRecord, quote.lineItems[0].purchaseId));
      }
    } else {
      calls.push(spawn(loadReco));
      if (enrollmentNumber) {
        yield call(loadEnrollment, enrollmentNumber);
      }
    }
    if (preapprovedECIFsEnabled) {
      calls.push(call(loadPreapprovedECIFs, quoteId));
    }
    yield all(calls);
    const salesAccountAddress: Address = yield select(getSalesAccountAddressFromQuote);
    if (salesAccountAddress && salesAccountAddress.companyName) {
      yield spawn(searchOrganizationsPropertySheet, salesAccountAddress.companyName);
    }
    const isGraphqlPhase2Enabled = yield select((state: RootState) =>
      getFlightIsEnabled(state, Flight.graphqlPhase2)
    );
    if (!isProposalReadOnly(quote) && !isGraphqlPhase2Enabled) {
      yield call(ensureLineItemAvailabilities, quote);
    }
    yield put(actions.hydrateQuoteAll.success(quoteId));
  } catch (err) {
    yield put(
      actions.hydrateQuoteAll.failure({
        message: t('error::Error hydrating quote (all)'),
        exception: err,
      })
    );
  }
}
