import {
  ErrorMessage,
  PrimaryButton,
  SecondaryButton,
  TextBodySmall,
  VerificationField,
} from 'components';
import {
  contributorRoleId,
  createCompleteList,
  createPartialList,
  ExistingOwner,
  ExistingOwnersList,
  LoadingErrorType,
  ownerRoleId,
  ownerRoleIds,
  OwnerType,
} from 'features-apollo/quote/components/ExistingOwners';
import { MS_EMAIL } from 'features-apollo/quote/components/utils';
import { TenantScenario } from 'features-apollo/quote/components/Wizards';
import { WizardBodyProps } from 'features-apollo/quote/components/Wizards/shared';
import { emailIsValid } from 'features/proposal/utils';
import {
  CustomerType,
  QueryGetRoleAssignmentsArgs,
  QueryGetTenantArgs,
  QueryVerifyInvitedUserArgs,
  QuoteMutationInput,
  RoleAssignments,
  TenantProfile,
} from 'generated/graphql';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { oc } from 'ts-optchain';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';

import { GetRoleAssignments, GetTenantAdmins } from '../../queries';
import { VerifyInvitedUser } from '../queries';
import { GqlVerifyInvitedUserErrors } from '../Shared';
import { workAccountStyles } from './WorkAccount.styles';

/**
 * Provides different text depending of the change been made to who the changes will be applied.
 *
 * @param hasInitialData Whether or not we are adding a work account or changing current one.
 * @param customStringType Provides a custom string for partner or partner-customer scenarios
 *
 * @default 'Edit work account || Add work account'
 */
export const getDialogTitleForWorkAccount = (
  hasInitialData: boolean,
  customStringType?: 'partner' | 'partner-customer' | 'billing-profile'
) => {
  switch (customStringType) {
    case 'partner':
      return hasInitialData
        ? i18next.t('quote::Edit partner work account')
        : i18next.t('quote::Add partner work account');
    case 'partner-customer':
      return hasInitialData
        ? i18next.t('quote::Edit customer work account')
        : i18next.t('quote::Add customer work account');
    case 'billing-profile':
      return i18next.t('quote::But first...');
    default:
      return hasInitialData
        ? i18next.t('quote::Edit work account')
        : i18next.t('quote::Add work account');
  }
};

export interface WorkAccountBodyProps extends WizardBodyProps<string | undefined> {
  accountId: string;
  organizationId: string;
  tenantId: string;
  isRequired: boolean;
  /**
   * Defines the strings and sections available for partner or partner-customer
   */
  quoteMutationInput: QuoteMutationInput;
  /**
   * Defines the strings and sections available for partner or partner-customer
   */
  specificScenario?: TenantScenario;
  setIsWorkAccountUnauthorized?: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = WorkAccountBodyProps & WithStyles<typeof workAccountStyles>;

const WorkAccountBodyUnstyled: React.FC<Props> = props => {
  const {
    accountId,
    classes,
    initialData,
    isRequired,
    organizationId,
    tenantId,
    quoteMutationInput,
    onInvalid,
    onValid,
    setIsWorkAccountUnauthorized,
  } = props;
  const { t } = useTranslation();

  //#region State
  const [lastVerified, setLastVerified] = React.useState<string | undefined>();
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(initialData || '');
  const [errorMessage, setErrorMessage] = React.useState<string | JSX.Element | undefined>();
  // TODO: masivaia - uncomment when Jason's deliverable with new requirements is ready
  // const [showWarningMessage, setShowWarningMessage] = React.useState<boolean>(false);
  const [ownersExist, setOwnersExist] = React.useState<boolean>(false);
  const [loadingError, setLoadingError] = React.useState<LoadingErrorType>(LoadingErrorType.None);
  const [displayList, setDisplayList] = React.useState<ExistingOwner[]>([]);
  const [tenantAdmins, setTenantAdmins] = React.useState<string[]>([]);
  const [tenantOwners, setTenantOwners] = React.useState<string[]>([]);
  const [tenantContributors, setTenantContributors] = React.useState<string[]>([]);
  //#endregion

  //#region Messages
  const regexErrorMsg = t('quote::Enter a valid email address');
  const msEmailErrorMsg = t('quote::You cannot use a Microsoft email address for a customer');
  const loadingErrorMsg = t('error::We encountered a problem while verifying. Please try again.');

  let customerType: CustomerType | undefined;
  let description: string;
  let verifyErrorMessageText: string;

  switch (props.specificScenario) {
    case TenantScenario.partner:
      customerType = CustomerType.SoldToCustomer;
      description = t(
        'quote::A work account is an Active Directory, administrative account, within the previously specified tenant. The work account for the partner on this quote needs to be verified that it exists on the partner tenant before it can be added to the quote.'
      );
      verifyErrorMessageText = t(
        'quote::The work account you have entered does not exist on the tenant specified for this partner. Please enter a valid work account or work with one of the global admins or owners on the tenant to provide them with an identity that would allow them to take commerce actions such as signing, purchasing, and establishing the details of their billing account.'
      );
      break;
    case TenantScenario.partnerCustomer:
      customerType = CustomerType.EndCustomer;
      description = t(
        'quote::A work account is an Active Directory, administrative account, within the previously specified tenant. The work account for the customer on this quote needs to be verified that it exists on the customer tenant before it can be added to the quote.'
      );
      verifyErrorMessageText = t(
        'quote::The work account you have entered does not exist on the tenant specified for this customer. Please enter a valid work account or work with one of the global admins or owners on the tenant to provide them with an identity that would allow them to take commerce actions such as signing, purchasing, and establishing the details of their billing account.'
      );
      break;
    case TenantScenario.billingAccountProfile:
      description = t(
        'quote::As this is the first billing profile created for this billing account we need to define an owner for the billing account and the billing profile. This will be an administrative account for your customer, within the specified tenant on the billing account. The work account needs to be verified that it exists on the tenant before it can be added. You will not be able to edit this after the billing profile has been created.'
      );
      verifyErrorMessageText = t(
        'quote::The work account you have entered does not exist on the tenant specified for this customer. Please enter a valid work account or work with one of the global admins or owners on the tenant to provide them with an identity that would allow them to take commerce actions such as signing, purchasing, and establishing the details of their billing account.'
      );
      break;
    default:
      description = t(
        'quote::A work account is an Active Directory, administrative account, within the previously specified tenant. The work account for this quote needs to be verified that it exists on the tenant before it can be added to the quote.'
      );
      verifyErrorMessageText = t(
        'quote::The work account you have entered does not exist on the tenant specified for this customer. Please enter a valid work account or work with one of the global admins or owners identified below to provide them with an identity that would allow them to take commerce actions such as signing, purchasing, and establishing the details of their billing account.'
      );
      break;
  }

  const verifyErrorMessage: JSX.Element | string = (
    <ErrorMessage mainMessage={t('quote::Identity does not exist on this tenant')}>
      {verifyErrorMessageText}
    </ErrorMessage>
  );

  // TODO: masivaia - uncomment when Jason's deliverable with new requirements is ready
  // const warningMessage = (
  //   <WarningMessage
  //     mainMessage={t('quote::Identity exists but does not have proper authorization')}
  //   >
  //     {t(
  //       'quote::This identity does not have proper authorization to take commerce actions such as signing, purchasing, and establishing the details of their billing account. Please work with your contact to help them identify a global admin who can provide them with proper authorization.'
  //     )}
  //   </WarningMessage>
  // );

  //#endregion

  const [verifyEmail, { loading: verifyLoading, error: verifyError }] = useLazyQuery<
    { verifyInvitedUser: boolean },
    QueryVerifyInvitedUserArgs
  >(VerifyInvitedUser, {
    onCompleted: data => {
      if (data.verifyInvitedUser === true) {
        setErrorMessage(undefined);
        onValid(lastVerified);

        const upns: string[] = ([] as string[]).concat(
          tenantOwners,
          tenantContributors,
          tenantAdmins
        );
        if (!upns.length || (lastVerified && !upns.includes(lastVerified))) {
          // TODO: masivaia - uncomment when Jason's deliverable with new requirements is ready
          // setShowWarningMessage(true);
          loggerService.log({
            name: 'Existing Owners - work account is unauthorized',
          });
          setIsWorkAccountUnauthorized && setIsWorkAccountUnauthorized(true);
        }
      } else {
        onInvalid();
      }
    },
    onError: error => {
      const errorCode = oc(error).graphQLErrors[0].extensions.code('');
      if (errorCode === GqlVerifyInvitedUserErrors.NotInTenant) {
        setErrorMessage(verifyErrorMessage);
        onInvalid();
      } else if (errorCode === GqlVerifyInvitedUserErrors.MsEmail) {
        setErrorMessage(msEmailErrorMsg);
        onInvalid();
      } else if (errorCode === GqlVerifyInvitedUserErrors.InvalidEmail) {
        setErrorMessage(regexErrorMsg);
        onInvalid();
      } else {
        setErrorMessage(loadingErrorMsg);
        onInvalid();
      }
    },
  });

  const { loading: tenantRolesLoading, error: tenantRolesError, data } = useQuery<
    {
      getRoleAssignments: RoleAssignments[];
    },
    QueryGetRoleAssignmentsArgs
  >(GetRoleAssignments, {
    variables: {
      input: {
        roles: ownerRoleIds,
        organization: {
          accountId,
          organizationId,
        },
      },
    },
    skip: props.specificScenario && props.specificScenario === TenantScenario.partnerCustomer,
  });

  const { loading: adminsLoading, error: adminsError, data: adminsData } = useQuery<
    { getTenant: TenantProfile },
    QueryGetTenantArgs
  >(GetTenantAdmins, {
    variables: {
      tenant: tenantId,
      isGovernmentTenant: !!(
        props.specificScenario && props.specificScenario === TenantScenario.partner
      ),
    },
    skip: props.specificScenario && props.specificScenario === TenantScenario.partnerCustomer,
  });

  const differentOrEmpty = lastVerified !== currentValue || !currentValue;
  const isError = !differentOrEmpty && !verifyLoading && !!verifyError;

  React.useEffect(() => {
    if (!tenantRolesLoading) {
      if (data && data.getRoleAssignments.length) {
        const roleAssignments = data.getRoleAssignments;
        const owners: string[] = [];
        roleAssignments.forEach((roleAssignment: RoleAssignments) => {
          if (roleAssignment.roleId === ownerRoleId && roleAssignment.userPrincipalName) {
            owners.push(roleAssignment.userPrincipalName);
          }
        });
        const contributors: string[] = [];
        roleAssignments.forEach((roleAssignment: RoleAssignments) => {
          if (roleAssignment.roleId === contributorRoleId && roleAssignment.userPrincipalName) {
            contributors.push(roleAssignment.userPrincipalName);
          }
        });
        setTenantOwners(owners);
        setTenantContributors(contributors);
      }
    }
  }, [tenantRolesLoading, data, tenantRolesError]);

  React.useEffect(() => {
    if (!adminsLoading && adminsData) {
      setTenantAdmins(adminsData.getTenant.tenantAdmins);
    }
  }, [adminsLoading, adminsData, adminsError]);

  React.useEffect(() => {
    // TODO: masivaia - uncomment when Jason's deliverable with new requirements is ready
    // setShowWarningMessage(false);
    if (!currentValue) {
      setErrorMessage(undefined);

      if (isRequired) {
        onInvalid();
      } else {
        onValid(undefined);
      }
    } else if (currentValue.endsWith(MS_EMAIL)) {
      setErrorMessage(msEmailErrorMsg);
      onInvalid();
    } else if (verifyLoading || currentValue !== lastVerified) {
      setErrorMessage(undefined);
      onInvalid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tenantAdmins,
    tenantOwners,
    isRequired,
    verifyLoading,
    currentValue,
    tenantId,
    lastVerified,
    onValid,
    onInvalid,
    msEmailErrorMsg,
  ]);

  React.useEffect(() => {
    let list: ExistingOwner[] = [];
    let error: LoadingErrorType = LoadingErrorType.None;
    if (!!tenantRolesError && !!adminsError) {
      error = LoadingErrorType.ErrorLoadingEverything;
    } else if (!!tenantRolesError) {
      error = LoadingErrorType.ErrorLoadingOwners;
      list = createPartialList(tenantAdmins, OwnerType.GlobalAdmin);
    } else if (!!adminsError) {
      error = LoadingErrorType.ErrorLoadingAdmins;
      const ownerList = createPartialList(tenantOwners, OwnerType.Owner);
      const contributorList = createPartialList(tenantContributors, OwnerType.Contributor);
      list = ownerList.concat(contributorList);
    } else {
      list = createCompleteList(tenantOwners, tenantContributors, tenantAdmins);
    }
    if (list.length && !ownersExist) {
      setOwnersExist(true);
    } else if (!list.length && ownersExist) {
      setOwnersExist(false);
    }
    setDisplayList(list);
    setLoadingError(error);
  }, [
    tenantRolesError,
    adminsError,
    setDisplayList,
    setLoadingError,
    tenantAdmins,
    tenantOwners,
    tenantContributors,
    ownersExist,
  ]);

  // TODO: masivaia - add back showWarning when Jason's deliverable with new requirements is ready
  const showMessage = !!errorMessage || isError ? errorMessage : '';
  const label =
    props.specificScenario === TenantScenario.billingAccountProfile
      ? t('quote::Billing account and profile owner')
      : t('quote::Work account');
  return (
    <div>
      <TextBodySmall addClass={classes.secondaryText}>{description}</TextBodySmall>
      <VerificationField
        buttonText={t('quote::Verify')}
        containerClassName={classes.input}
        dataAutomationId="workAccountVerification"
        errorMessage={showMessage}
        id="work-account-input"
        invalidStatusText={t('quote::Invalid')}
        isError={!!errorMessage || isError} // TODO: masivaia - add back showWarning when Jason's deliverable with new requirements is ready
        isLoading={verifyLoading}
        isVerified={currentValue === lastVerified && !errorMessage}
        lastVerified={lastVerified}
        showButtonWhenInvalid={false}
        textboxLabel={label}
        textboxPlaceholder={t('quote::AD work account on an Azure or O365 tenant')}
        textboxRequired={true}
        textboxValue={currentValue}
        validationErrorMessage={regexErrorMsg}
        verifiedStatusText={t('quote::Valid')}
        onChangeDebounced={setCurrentValue}
        onValidate={(value: string) => emailIsValid.test(value)}
        onVerify={(value: string) => {
          onInvalid();
          verifyEmail({
            variables: {
              input: {
                email: value,
                // NOTE: verifying the work account should not update the quote, but it seems that GQL is reusing a mutation input type for this query.
                quote: quoteMutationInput,
                customerType,
                tenantId,
              },
            },
          });
          setLastVerified(value);
        }}
      />
      {!(props.specificScenario === TenantScenario.partnerCustomer) && ownersExist && (
        <ExistingOwnersList
          error={loadingError}
          list={displayList}
          loading={tenantRolesLoading}
          maxHeight={286}
          showTooltip={true}
          specificScenario={props.specificScenario}
        />
      )}
    </div>
  );
};

export const WorkAccountFooterButtons = (
  isFromTenant: boolean,
  buttonDisabled: boolean,
  onButtonClick: () => void,
  onBackButtonClick?: () => void,
  isWorkAccountUnauthorized?: boolean,
  isForBillingProfile?: boolean
) => {
  const saveWorkAccount = () => {
    isWorkAccountUnauthorized &&
      loggerService.log({
        name: 'Existing Owners - user proceeds with unauthorized work account',
      });
    onButtonClick();
  };
  if (isFromTenant) {
    return [
      <SecondaryButton
        dataAutomationId="backToTenantButton"
        key="back-to-tenant"
        text={i18next.t('quote::Back')}
        onClick={onBackButtonClick}
      />,
      <PrimaryButton
        dataAutomationId="saveEmailButton"
        disabled={buttonDisabled}
        key="save-email"
        text={i18next.t('quote::Add work account')}
        onClick={saveWorkAccount}
      />,
    ];
  } else {
    return [
      <PrimaryButton
        dataAutomationId="saveWorkAccountButton"
        disabled={buttonDisabled}
        key="save-work-account"
        text={
          isForBillingProfile ? i18next.t('quote::Next') : i18next.t('quote::Save work account')
        }
        onClick={saveWorkAccount}
      />,
    ];
  }
};

export const WorkAccountBody = withStyles(workAccountStyles)(WorkAccountBodyUnstyled);
