import { Spinner, XLargeIcon } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { sharedStyles, ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => {
  const { palette } = theme;

  return {
    container: {
      ...sharedStyles(theme).iconContainerForDialog,
      backgroundColor: theme.palette.warning,
      '& i': {
        color: 'black',
      },
    },
    icon: { ...sharedStyles(theme).iconForDialog },
    spinner: {
      position: 'absolute',
      '& .ms-Spinner-circle': {
        borderColor: `${palette.primary} ${palette.backgroundDivider} ${palette.backgroundDivider}`,
        borderWidth: 2,
        height: 58,
        width: 58,
      },
    },
  };
};

const WaitingIndicatorUnStyled: React.FC<WithStyles<typeof styles>> = props => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <XLargeIcon addClass={classes.icon} iconName="Processing"></XLargeIcon>
      <Spinner addClass={classes.spinner} />
    </div>
  );
};

export const WaitingIndicator = withStyles(styles)(WaitingIndicatorUnStyled);
