import { getCustomerOrParticipantsNames } from 'features-apollo/quote/selectors/organization';
import { isQuoteHRDD } from 'features-apollo/quote/selectors/quote';

import { Quote } from 'generated/graphql';
import React from 'react';

import { NavBarTitle } from './NavBarTitle';

import { getQuoteDisplayStatus } from 'features-apollo/utils';

/**
 * Renders the participants' names, and name and status of the quote.
 */
export const renderNavBarQuoteSummary = (
  quote: Quote,
  loading?: boolean
): React.ReactNode | undefined => {
  const quoteIsHRDD = isQuoteHRDD(quote);
  const customerOrParticipantsNames = getCustomerOrParticipantsNames(quote);

  const customerOrParticipants = (
    <NavBarTitle
      animated
      dataAutomationId="quoteTitle"
      key="quote-customer-or-participants"
      loading={loading}
      title={customerOrParticipantsNames}
    />
  );

  if (quoteIsHRDD) {
    return [customerOrParticipants];
  }

  const quoteName = (
    <NavBarTitle
      animated
      dataAutomationId="quoteName"
      key="quote-name"
      loading={loading}
      title={quote.name}
    />
  );

  const quoteStatus = (
    <NavBarTitle
      animated
      key="quote-status"
      loading={loading}
      regular
      title={getQuoteDisplayStatus(quote)}
    />
  );

  return [customerOrParticipants, quoteName, quoteStatus];
};
