import { ComboBoxAtom, ComboBoxAtomProps } from 'components/atoms';
import { IComboBoxStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { TeachingBubbleLabel, TeachingBubbleLabelProps } from '../TeachingBubbleLabel';
import { comboBoxStyles } from './ComboBox.styles';

type Props = ComboBoxAtomProps & TeachingBubbleLabelProps & WithStyles<typeof comboBoxStyles>;

const ComboBoxUnstyled: React.FC<Props> = ({ classes, ...props }: Props) => {
  const isReadOnly =
    (props.defaultSelectedKey || props.selectedKey || props.selectedKeys) &&
    (props.disabled || props.options.length === 1);

  const styles: Partial<IComboBoxStyles> = {};
  if (isReadOnly) {
    styles.inputDisabled = classes.inputReadOnly;
    styles.labelDisabled = classes.labelReadOnly;
  }

  const onRenderLabel = () => {
    return <TeachingBubbleLabel infoButtonProps={props.infoButtonProps} label={props.label} />;
  };

  return (
    <ComboBoxAtom
      {...props}
      disabled={!props.options.length || isReadOnly || props.disabled}
      styles={styles}
      onRenderLabel={props.infoButtonProps ? onRenderLabel : props.onRenderLabel}
    />
  );
};

export const ComboBox = withStyles(comboBoxStyles)(ComboBoxUnstyled);
