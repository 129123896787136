import { mergeClassNames } from 'components/utilities';
import {
  BaseButton,
  Button,
  DefaultButton,
  IButton,
  IButtonStyles,
  IIconProps,
  RefObject,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { buttonAtomStyles } from './ButtonAtom.styles';

type ActionType =
  | HTMLDivElement
  | HTMLSpanElement
  | HTMLButtonElement
  | HTMLAnchorElement
  | BaseButton
  | Button;

export interface ButtonAtomProps {
  addClass?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  componentRef?:
    | React.RefObject<IButton>
    | RefObject<IButton>
    | ((ref: IButton | null) => void)
    | undefined;
  disabled?: boolean;
  id?: string;
  text?: string;
  title?: string;
  primary?: boolean;
  onClick?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
  iconProps?: IIconProps;
  styles?: Partial<IButtonStyles>;
  toggle?: boolean;
  dataAutomationId?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
}

type Props = ButtonAtomProps & WithStyles<typeof buttonAtomStyles>;

const ButtonUnstyled: React.FC<Props> = (props: Props) => {
  // TODO jepagan : request Fabric to enable the user of classes to modify styles properties for icon
  const styles: Partial<IButtonStyles> = {
    icon: props.classes.icon,
    iconHovered: { color: '' },
    iconPressed: { color: '' },
    ...props.styles,
  };

  return (
    <DefaultButton
      ariaDescription={props.text}
      ariaLabel={props.ariaLabel}
      autoFocus={props.autoFocus}
      className={mergeClassNames([props.classes.main, props.addClass])}
      componentRef={props.componentRef}
      data-automation-id={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={props.iconProps}
      id={props.id}
      primary={props.primary}
      styles={styles}
      text={props.text}
      title={props.title}
      toggle={props.toggle}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    />
  );
};

ButtonUnstyled.defaultProps = {
  disabled: false,
  primary: false,
  toggle: false,
};

export const ButtonAtom = withStyles(buttonAtomStyles)(ButtonUnstyled) as React.FC<ButtonAtomProps>;
