import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApprovalActionRequest, ApprovalActionType } from 'services/approval/types';
import {
  eTagMissmatchCode,
  invalidApprovalTransitionCode,
  unathorizedActionCode,
} from 'services/approval/utils';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { RefreshDialog } from '../RefreshDialog';
import { Success } from '../Shared';
import { approveRejectDimensions } from '../shared.styles';
import { JustificationView } from './JustificationView';

const mapStateToProps = (state: RootState) => ({
  approval: selectors.getActiveApproval(state),
  approvalActionProcessing: selectors.approvalActionProcessing(state),
});

const dispatchProps = {
  performApprovalAction: (request: ApprovalActionRequest) =>
    actions.performApprovalActionAsync.request(request),
};

export type ApproveDialogProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const ApproveDialogFeature: React.FC<ApproveDialogProps> = props => {
  const { approval, approvalActionProcessing } = props;
  const [initiated, setInitiated] = React.useState<boolean>(false);
  const [justification, setJustification] = React.useState<string>('');
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const onApproveClick = (comment?: string) => {
    comment = comment || justification;
    setJustification(comment);
    if (!approval) {
      loggerService.error({
        error: new Error('Tried to approve an approval that does not exist'),
      });
      return;
    }
    const request: ApprovalActionRequest = {
      action: ApprovalActionType.Approve,
      approvalId: approval.id,
    };

    if (comment) {
      request.comments = comment;
    }
    props.performApprovalAction(request);
    setInitiated(true);
  };

  if (!initiated) {
    return <JustificationView {...approveRejectDimensions} dispatchAction={onApproveClick} />;
  } else if (approvalActionProcessing.loading) {
    return (
      <Processing
        {...approveRejectDimensions}
        message1={t('quote::The quote is being approved.')}
      />
    );
  } else if (approvalActionProcessing.error) {
    const errorCode = oc(approvalActionProcessing).error.exception.response.data.details[0].code();
    const errorStatus412 = errorCode === eTagMissmatchCode;
    const isSequentialMultipleApprover =
      approval &&
      approval.workFlow &&
      approval.workFlow.toLowerCase() === 'sequentialmultipleapproval';
    if (
      errorCode === invalidApprovalTransitionCode ||
      (errorCode === unathorizedActionCode && isSequentialMultipleApprover)
    ) {
      const actionText = t('quote::Approval');
      return (
        <RefreshDialog
          isSequentialMultipleApprover
          {...approveRejectDimensions}
          action={actionText}
        />
      );
    }
    if (errorStatus412) {
      const actionText = t('quote::Approve');
      return <RefreshDialog action={actionText} {...approveRejectDimensions} is412={true} />;
    } else {
      return (
        <Fail
          {...approveRejectDimensions}
          closeDialog={closeDialog}
          dataAutomationId="quoteApprovalFail"
          message={t('quote::Sorry, the "Approve" action failed.')}
          onTryAgainClick={onApproveClick}
        />
      );
    }
  } else {
    return (
      <Success
        {...approveRejectDimensions}
        closeDialog={closeDialog}
        dataAutomationId="quoteApprovalSuccess"
        message={t('quote::The quote was successfully approved.')}
      />
    );
  }
};

export const ApproveDialog = connect(mapStateToProps, dispatchProps)(ApproveDialogFeature);
