import gql from 'graphql-tag';

// export const DELETE_LINE_ITEMS = gql`
//   mutation deleteLineItems($quoteId: String!, $lineItemsIds: [String]!) {
//     deleteLineItems(quoteId: $quoteId, lineItemsIds: $lineItemsIds) {
//     }
//   }
// `;

export const DELETE_AGREEMENT = gql`
  mutation deleteModernAgreement($input: DeleteModernAgreementInput!) {
    deleteModernAgreement(input: $input) {
      id
    }
  }
`;
