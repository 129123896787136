import {
  ApproveMessage,
  ErrorMessage,
  SecondaryButton,
  TextBodySmall,
  VerificationField,
} from 'components';
import { getValueOrUndefined, isDemoTenant } from 'features-apollo/quote/components/utils';
import { OrgWizardAccount } from 'features-apollo/quote/components/Wizards';
import { domainTestEnvironmentIncludes, validGuid } from 'features/proposal/utils';
import { OrganizationSimple, OrganizationType, TenantProfile, TenantType } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { useLazyQuery } from '@apollo/react-hooks';

import { GetTenant } from '../queries';
import {
  invalidTenantFormatMessage,
  invalidTestTenantFormatMessage,
  onValidateTenant,
  ViralTenantMessage,
  WizardDialog,
} from '../Shared';

const styles = (theme: ThemeProps) => ({
  description: { color: theme.palette.textTertiary, display: 'block', marginBottom: 24 },
  noFoundMessage: { paddingTop: 12, maxWidth: 400 },
  viralTenantMessage: { paddingTop: 12 },
});

export interface SearchAccountDialogProps {
  usingTestHeaders: boolean;
  onClose: () => void;
  onNextButtonClick: (account?: OrgWizardAccount, tenantId?: string) => void;
  onAccountFound: (
    account: OrgWizardAccount,
    tenantId: string,
    isIWOnly: boolean,
    organizations: OrganizationSimple[]
  ) => void;
}

type Props = SearchAccountDialogProps & WithStyles<typeof styles>;

const SearchAccountDialogUnstyled: React.FC<Props> = props => {
  const { classes, usingTestHeaders } = props;
  const { t } = useTranslation();

  const [lastVerified, setLastVerified] = React.useState<string>('');
  const [currentValue, setCurrentValue] = React.useState<string | undefined>();
  const [tenantId, setTenantId] = React.useState<string | undefined>();
  const [accountId, setAccountId] = React.useState<string | undefined>();
  const [accountName, setAccountName] = React.useState<string>('');
  const [noAccountFound, setNoAccountFound] = React.useState<boolean | undefined>();
  const [accountNoOrgs, setAccountNoOrgs] = React.useState<boolean>(false);
  const [viralError, setViralError] = React.useState<boolean>(false);
  const [searchTenantError, setSearchTenantError] = React.useState<boolean>(false);

  const isDifferent = lastVerified !== currentValue;
  const containsTestTest =
    oc(currentValue)('')
      .toLowerCase()
      .includes(domainTestEnvironmentIncludes) && !validGuid.test(oc(currentValue)(''));
  const validDomain = usingTestHeaders ? containsTestTest : true;

  //#region GQL
  const [searchTenant, { loading: searchLoading }] = useLazyQuery<{
    getTenant: TenantProfile;
  }>(GetTenant, {
    onCompleted: data => {
      const tenantData = data.getTenant;
      const tenantAccount = getValueOrUndefined(tenantData.account);
      if (tenantData.tenantType === TenantType.Viral) {
        setViralError(true);
      } else if (tenantAccount) {
        setTenantId(tenantData.tenantId);
        setAccountId(tenantAccount.id);
        setAccountName(tenantAccount.name);
        const orgsOnAccount =
          tenantData.organizations && tenantData.organizations.edges
            ? tenantData.organizations.edges.map((edge: { node: OrganizationSimple }) => edge.node)
            : [];
        const billingAccounts = orgsOnAccount.filter(
          (organization: OrganizationSimple) => organization.type !== OrganizationType.Individual
        );
        const iwOrgs = orgsOnAccount.filter(
          (organization: OrganizationSimple) => organization.type === OrganizationType.Individual
        );
        if (billingAccounts.length) {
          props.onAccountFound(
            { accountId: tenantAccount.id, accountName: tenantAccount.description },
            tenantData.tenantId,
            false,
            billingAccounts
          );
        } else if (iwOrgs.length) {
          props.onAccountFound(
            { accountId: tenantAccount.id, accountName: tenantAccount.description },
            tenantData.tenantId,
            true,
            iwOrgs
          );
        } else {
          setAccountNoOrgs(true);
        }
      } else {
        setNoAccountFound(true);
        setTenantId(tenantData.tenantId);
      }
    },
    onError: error => {
      const errorCode = oc(error).graphQLErrors[0].extensions.code('');
      if (errorCode === 'TENANT_DOES_NOT_EXIST') {
        setNoAccountFound(true);
      } else {
        setSearchTenantError(true);
      }
    },
  });
  //#endregion

  //#region Messages
  const errorMessage = t('quote::We encountered a problem while searching. Please try again.');
  const invalidDomainMessage = validDomain
    ? invalidTenantFormatMessage()
    : invalidTestTenantFormatMessage();
  const description = (
    <TextBodySmall addClass={classes.description}>
      {t(
        "quote::Before creating a new modern account and billing account, let's make sure the customer does not already have an existing account."
      )}
    </TextBodySmall>
  );
  const noAccountFoundMessage = noAccountFound && (
    <div className={classes.noFoundMessage}>
      <ApproveMessage mainMessage={t('quote::No account found')}>
        {t(
          'quote::We did not find a modern account associated with the specified domain or tenant. Please proceed with creating a new account and billing account.'
        )}
      </ApproveMessage>
    </div>
  );
  const noOrganizationFoundMessage = accountNoOrgs && (
    <div className={classes.noFoundMessage}>
      <ApproveMessage mainMessage={t('quote::No billing account found')}>
        {t(
          'quote::We did not find a billing account on the account of the specified domain or tenant. Please proceed with creating a new billing account.'
        )}
      </ApproveMessage>
    </div>
  );
  const viralTenantErrorMessage = viralError ? (
    <div className={classes.viralTenantMessage}>
      <ViralTenantMessage />
    </div>
  ) : null;
  const demoTenantNoOrgMessage = accountNoOrgs && isDemoTenant(tenantId) && (
    <div className={classes.noFoundMessage}>
      <ErrorMessage mainMessage={t('quote::Demo tenant')}>
        {t(
          'quote::The tenant provided is a demo tenant and does not support adding billing accounts. Please provide a different tenant.'
        )}
      </ErrorMessage>
    </div>
  );
  //#endregion

  const isValid =
    !!lastVerified && !isDifferent && !viralError && !searchTenantError && !isDemoTenant(tenantId);

  const onClickNext = () => {
    accountId
      ? props.onNextButtonClick({ accountId, accountName }, tenantId)
      : tenantId
      ? props.onNextButtonClick(undefined, tenantId)
      : props.onNextButtonClick();
  };

  const validateInput = (value: string) => {
    return onValidateTenant(value, usingTestHeaders);
  };

  const onSearch = (tenant: string) => {
    setNoAccountFound(undefined);
    setSearchTenantError(false);
    setLastVerified(tenant);
    searchTenant({ variables: { tenant } });
  };

  const searchInput = (
    <VerificationField
      allowTryAgain={!!(searchTenantError && currentValue)} // Only enable try again when there is something in the field
      buttonText={t('Search')}
      dataAutomationId="domainNameOrTenantId"
      errorMessage={errorMessage}
      isError={searchTenantError}
      isLoading={searchLoading}
      isVerified={validDomain}
      lastVerified={lastVerified} // This feature does not required to verify input but this is required to be able to display error messages
      showButtonWhenVerified
      textboxLabel={t('quote::Domain name or tenant ID')}
      textboxPlaceholder={t('quote::e.g. contoso.com')}
      validationErrorMessage={invalidDomainMessage}
      onChangeDebounced={(value?: string) => setCurrentValue(value)}
      onValidate={validateInput}
      onVerify={onSearch}
    />
  );

  const body = (
    <>
      {description}
      {searchInput}
      {!isDifferent &&
        (viralTenantErrorMessage ||
          noAccountFoundMessage ||
          noOrganizationFoundMessage ||
          demoTenantNoOrgMessage)}
    </>
  );

  const footerButton = [
    <SecondaryButton
      dataAutomationId="SearchAccountNext"
      disabled={!isValid}
      key="SearchAccountNext"
      text={t('Next')}
      onClick={onClickNext}
    />,
  ];

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      footer={footerButton}
      headline={t('quote::Check for an existing account')}
      isOverviewAvailable={false}
      overviewButtonOnly={false}
      title={t('quote::Before we start...')}
    />
  );
};

export const SearchAccountDialog = withStyles(styles)(SearchAccountDialogUnstyled);
