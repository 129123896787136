import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

/**
 * Creates selector that determine the loading state of the given action
 */
export const createLoadingSelector = (actions: string[]) => (state: RootState) =>
  actions.some(action => state.app.loading[action]);

/**
 * Gets the current environment the application is running on
 */
export const getAppEnvironment = (state: RootState) => state.app.appConfig.app;

/**
 * Gets the business store configuration for the current environment
 */
export const getBusinessStoreConfig = (state: RootState) => state.app.appConfig.businessStore;

/**
 * Whether or not the given is turned on
 */
export const isFlightEnabled = (state: RootState, id: Flight) => state.app.flights[id];
