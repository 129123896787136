import {
  Shimmer,
  ShimmerElementType as ElemType,
} from 'office-ui-fabric-react/lib-commonjs/components/Shimmer';
import * as React from 'react';

export const CreateShimmers = (
  count: number,
  width: string,
  heightInPixels: number,
  marginTop: number
) => {
  const shimmers = [];
  for (let i = 0; i < count; i++) {
    const shimmerElements = [{ type: ElemType.line, height: heightInPixels }];
    shimmers.push(
      <Shimmer
        key={`shimmer${i}`}
        shimmerElements={shimmerElements}
        style={{ marginTop }}
        width={width}
      />
    );
  }
  return <div>{shimmers}</div>;
};
