import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { blurrableStyles } from './BlurrableAtom.styles';

/** Used as a page wrapper to blur out the page's contents. Useful for modals and dialogs.
 *  @prop {string} id id to tag outer container with
 *  @prop {boolean} isBlurred flag to trigger blur animation on and off
 *  @prop {ReactElement} onTopContent content that will be displayed ontop of the blurred background. IE your modal or dialog box
 *  @prop {ReactNode} children the page content
 */
export interface BlurrableAtomProps {
  id?: string;
  isBlurred: boolean;
  onTopContent: React.ReactNode;
  children: React.ReactNode;
  blurPosition?: { top?: number; right?: number };
}

type Props = BlurrableAtomProps & WithStyles<typeof blurrableStyles>;

const BlurrableAtomUnstyled: React.FC<Props> = (props: Props) => {
  const { id, isBlurred, classes, onTopContent } = props;

  return (
    <div id={id}>
      <div className={classes.backgroundContent}>{props.children}</div>
      {isBlurred && (
        <div className={classes.frontContainer}>
          <div className={classes.frontContent}>{onTopContent}</div>
        </div>
      )}
    </div>
  );
};

export const BlurrableAtom = withStyles(blurrableStyles)(BlurrableAtomUnstyled) as React.FC<
  BlurrableAtomProps
>;
