/* eslint-disable no-fallthrough */
import { AddressLarge, Dialog, Spinner, TextBody } from 'components';
import { ActiveQuoteContext, GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { Fail, Processing, Success } from 'features-apollo/components/dialogs';
import {
  AccountIndividualsDialog,
  AccountNameDialog,
  AccountOrganizationsDialog,
  AddressDialog,
  addressHasChanges,
  AffiliateDialog,
  BillingContactInformation,
  BillingContactWizardDialog,
  defaultAddressConfiguration,
  GetMarketInfoForOrgWizard,
  hasValidAddressFields,
  InvoiceLanguageDialog,
  OrganizationNameDialog,
  OverviewDialog,
  OverviewRowProps,
  ParentAccountDialog,
  SearchAccountDialog,
  SimilarOrgFoundDialog,
  ValidateAddress,
} from 'features-apollo/quote/components/Dialogs/OrganizationDialogs';
import {
  AddOrganization,
  GetSuggestedOrganizations,
} from 'features-apollo/quote/components/PropertySheets/Customer/queries';
import { useAssociateCRMID } from 'features-apollo/quote/components/PropertySheets/Customer/scenarios/CustomerForIndirect/OrganizationSearch/CustomerSearchDialog/useAssociateCRMID';
import { usingAccountExtensionsTestHeaders } from 'features/app/selectors';
import {
  Account,
  AddressConfiguration,
  AddressValidationResponse,
  AddressValidationStatus,
  CrmLead,
  CustomerType,
  DiscountLineItem,
  GqlLanguage,
  LanguageInfo,
  Market,
  MarketInfo,
  MonetaryLineItem,
  MutationAddOrganizationArgs,
  MutationCreateAccountAndOrganizationArgs,
  MutationCreateOrganizationArgs,
  MutationUpdateLeadOrganizationArgs,
  MutationUpdateOrganizationAddressArgs,
  MutationUpdateOrganizationVersionArgs,
  MutationUpdateTradeNameArgs,
  OrganizationInput,
  OrganizationSimple,
  OrganizationSimpleConnection,
  OrganizationSimpleEdge,
  PriceAdjustmentType,
  PurchaseLineItem,
  QueryGetMarketInfoArgs,
  QueryOrganizationsByAccountIdArgs,
  QuerySearchOrganizationsArgs,
  QueryValidateAddressArgs,
  Quote,
  QuoteMutationInput,
  TenantProfile,
  TermLineItem,
  TransactionModel,
} from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext, DialogContextType, DialogProps } from 'styles';
import { oc } from 'ts-optchain';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { SharedDiscountsDialog } from '../../Dialogs/SharedDiscountsDialog';
import { getValueOrUndefined, isDemoTenant } from '../../utils';
import {
  CreateAccountAndOrganization,
  CreateOrganization,
  GetEligibleLeadOrgs,
  UpdateLeadOrg,
  UpdateOrgAddress,
  UpdateOrgVersion,
  UpdateTradeName,
} from '../queries';
import { organizationWizardStyles } from './OrganizationWizard.styles';
import {
  defaultLanguageInfo,
  generateInitialState,
  OrganizationWizardActions,
  organizationWizardReducer,
  OrgWizardAccount,
  OrgWizardAddress,
  OrgWizardBillingContact,
  OrgWizardDataSource,
  OrgWizardLeadOrg,
  OrgWizardOrganization,
} from './types';

const dialogDimensions = {
  height: 627,
  width: 600,
};

enum OrganizationAction {
  Select,
  Create,
  Update,
}

export enum OrganizationWizardView {
  AccountName = 1,
  OrganizationName,
  Address,
  Affiliate,
  ParentAccount,
  Contact,
  InvoiceLanguage,
  Overview,
  SearchAccount,
  IW,
  OrganizationsOnAccount,
  SimilarOrganization,
  Processing,
}

export enum OrganizationWizardFlow {
  CreateAccountAndOrganization = 1,
  CreateOrganization,
  Edit,
}

const mapStateToProps = (state: RootState) => {
  return {
    usingTestHeaders: usingAccountExtensionsTestHeaders(state),
  };
};

export interface OrganizationWizardContainerProps {
  flow?: OrganizationWizardFlow;
  initialView?: OrganizationWizardView;
  /**
   * To create an organization from a given tenant or account
   */
  tenant?: TenantProfile | null;
}

interface CurrentStep {
  /**
   * Determines which dialog to display
   */
  view: OrganizationWizardView;
  /**
   * Whether or not the user arrived to the current view from the Overview dialog.
   * Use to display the button that allows the user to go back direct to the Overview dialog.
   */
  fromOverview: boolean;
}

export interface OrganizationWizardProps {
  flow: OrganizationWizardFlow;
  initialView?: OrganizationWizardView;
  quoteId: string;
  quoteEtag: string;
  crmLead: CrmLead;
  endCustomer?: OrganizationSimple | null;
  account?: Account;
  leadOrg?: OrganizationSimple | null;
  hasSharedDiscounts: boolean;
  tenantId?: string;
  /**
   * Determines if the organization is found in the soldTo or endCustomer properties on the quote.
   * This value undefined will imply that the organization exist in both soldTo and endCustomer properties on the quote.
   */
  customerType?: CustomerType;
}

type Props = OrganizationWizardProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof organizationWizardStyles>;

const OrganizationWizardUnstyled: React.FC<Props> = props => {
  const {
    customerType,
    quoteId,
    quoteEtag,
    crmLead,
    account,
    endCustomer,
    leadOrg,
    hasSharedDiscounts,
    classes,
    flow,
  } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };

  const isEditFlow = props.flow === OrganizationWizardFlow.Edit;
  const associateCRMID = useAssociateCRMID(crmLead.id);

  const [tenantIdForAccount, setTenantIdForAccount] = React.useState<string | undefined>();
  const [affiliateInfoOpen, setAffiliateInfoOpen] = React.useState<boolean>(false);
  const [{ view: currentView, fromOverview }, setCurrentStep] = React.useState<CurrentStep>({
    view: props.initialView || OrganizationWizardView.Overview,
    fromOverview: false,
  });
  const [invoiceLanguageList, setInvoiceLanguageList] = React.useState<LanguageInfo[]>([
    defaultLanguageInfo,
  ]);
  const [addressConfiguration, setAddressConfiguration] = React.useState<AddressConfiguration>(
    defaultAddressConfiguration
  );
  const [countryDisplayName, setCountryDisplayName] = React.useState<string>('');
  const [orgsOnAccount, setOrgsOnAccount] = React.useState<OrganizationSimple[]>([]);
  const [eligibleParentAccounts, setEligibleParentAccounts] = React.useState<OrganizationSimple[]>(
    []
  );
  const [initialAddressStatus, setInitialAddressStatus] = React.useState<
    AddressValidationStatus | undefined
  >(undefined);
  const [selectedOrgId, setSelectedOrgId] = React.useState<string | undefined>(undefined);
  const [organizationAction, setOrganizationAction] = React.useState(
    isEditFlow ? OrganizationAction.Update : OrganizationAction.Create
  );
  const [tradeNameUpdated, setTradeNameUpdated] = React.useState<boolean>(false);
  const [addressUpdated, setAddressUpdated] = React.useState<boolean>(false);
  const [leadOrgUpdated, setLeadOrgUpdated] = React.useState<boolean>(false);
  const [availableSharedDiscounts, setAvailableSharedDiscounts] = React.useState<boolean>(false);
  const [isDemoTenantId, setIsDemoTenantId] = React.useState<boolean>(false);

  const [wizardState, dispatch] = React.useReducer(
    organizationWizardReducer,
    {
      flow,
      initialAccount: account,
      initialLeadOrg: leadOrg,
      initialOrganization: endCustomer,
      salesAccountAddress: oc(crmLead).salesAccount.address(),
    },
    generateInitialState
  );

  const showOverviewButton =
    fromOverview ||
    (!!wizardState.account.accountName &&
      !!wizardState.organization.organizationName &&
      !!wizardState.address.addressLine1 &&
      !!wizardState.isAddressValidated &&
      !!(
        (wizardState.isAffiliate && wizardState.leadOrg.leadOrgId) ||
        (!wizardState.isAffiliate && !wizardState.leadOrg.leadOrgId)
      ));

  const setCurrentStepFactory = (view: OrganizationWizardView, fromOverview?: boolean) => {
    setCurrentStep({ view, fromOverview: !!fromOverview });
  };

  const setCurrentStepFromOverview = (view: OrganizationWizardView) => () =>
    setCurrentStepFactory(view, true);

  const openSharedDiscounts = () => {
    closeDialog();
    const dialogProps: DialogProps = {
      providedDialog: (
        <SharedDiscountsDialog
          affiliateAccountName={wizardState.organization.organizationName}
          parentAccountName={wizardState.leadOrg.leadOrgName || undefined}
          quoteInput={{ id: quoteId, etag: quoteEtag }}
        />
      ),
    };
    if (availableSharedDiscounts) {
      context.openDialog(dialogProps);
    }
  };

  //#region GQL
  // validateAddress query
  const [validateAddress] = useLazyQuery<
    { validateAddress: AddressValidationResponse },
    QueryValidateAddressArgs
  >(ValidateAddress, {
    onCompleted: data => {
      const validationResponse = data.validateAddress;
      setInitialAddressStatus(validationResponse.status);
    },
  });

  // organizationsByAccountId query (with onlyLead flag)
  const [getLeadOrganizations, { loading: leadsLoading }] = useLazyQuery<
    { organizationsByAccountId: OrganizationSimpleConnection },
    QueryOrganizationsByAccountIdArgs
  >(GetEligibleLeadOrgs, {
    onCompleted: data => {
      setEligibleParentAccounts(
        oc(data)
          .organizationsByAccountId.edges([])
          .filter((edge: OrganizationSimpleEdge) => !!edge)
          .map((edge: { node: OrganizationSimple }) => edge.node)
      );
    },
    onError: error => {
      loggerService.error({
        error: new Error(
          `Error fetching eligible parent accounts for MCAPI Account: ${wizardState.account.accountId}. Error: ${error}`
        ),
      });
    },
    errorPolicy: 'all',
  });
  React.useEffect(() => {
    if (wizardState.account.accountId) {
      getLeadOrganizations({
        variables: { input: { key: wizardState.account.accountId, onlyLead: true } },
      });
    }
  }, [wizardState.account.accountId, getLeadOrganizations]);

  // getMarketInfo query
  const [getMarketInfo] = useLazyQuery<{ getMarketInfo: MarketInfo }, QueryGetMarketInfoArgs>(
    GetMarketInfoForOrgWizard,
    {
      onCompleted: data => {
        const marketInfo = data.getMarketInfo;
        if (marketInfo.invoiceLanguages) {
          setInvoiceLanguageList(marketInfo.invoiceLanguages);
          dispatch(
            OrganizationWizardActions.setInvoiceLanguage(marketInfo.invoiceLanguages[0].gqlCode)
          );
        }
        marketInfo.addressConfiguration && setAddressConfiguration(marketInfo.addressConfiguration);
        marketInfo.display && setCountryDisplayName(marketInfo.display);
      },
    }
  );

  // searchOrganizations query
  const [
    searchOrgs,
    { loading: searchOrgsLoading, error: searchOrgsError, data: searchOrgsData },
  ] = useLazyQuery<{ searchOrganizations: OrganizationSimple[] }, QuerySearchOrganizationsArgs>(
    GetSuggestedOrganizations
  );

  // addOrganization mutation
  const [selectOrg, { loading: selectOrgLoading, error: selectOrgError }] = useMutation<
    { addOrganization: Quote },
    MutationAddOrganizationArgs
  >(AddOrganization, {
    update(cache, response) {
      const updatedQuote = oc(response).data.addOrganization();

      updatedQuote &&
        cache.writeQuery({
          query: GET_QUOTE,
          variables: { id: updatedQuote.id },
          data: { getQuote: updatedQuote },
        });
    },
  });

  // updateOrganizationVersion mutation (updates org version on quote after updating organization)
  const [updateOrgVersion, { loading: versionLoading, error: versionError }] = useMutation<
    { updateOrganizationVersion: Quote },
    MutationUpdateOrganizationVersionArgs
  >(UpdateOrgVersion, {
    update(cache, response) {
      const updatedQuote = oc(response).data.updateOrganizationVersion();

      updatedQuote &&
        cache.writeQuery({
          query: GET_QUOTE,
          variables: { id: updatedQuote.id },
          data: { getQuote: updatedQuote },
        });
    },
  });

  // updateLeadOrganization mutation
  const [updateLeadOrg, { loading: updateLeadOrgLoading, error: updateLeadOrgError }] = useMutation<
    { updateLeadOrganization: Quote },
    MutationUpdateLeadOrganizationArgs
  >(UpdateLeadOrg, {
    update(cache, response) {
      const updatedQuote = oc(response).data.updateLeadOrganization();
      updatedQuote && setAvailableSharedDiscounts(updatedQuote.canShareLeadDiscounts);
      updatedQuote &&
        cache.writeQuery({
          query: GET_QUOTE,
          variables: { id: updatedQuote.id },
          data: { getQuote: updatedQuote },
        });
    },
    onCompleted: data => {
      const updateResponse = data.updateLeadOrganization;
      if (addressUpdated || tradeNameUpdated) {
        updateOrgVersion({
          variables: {
            input: {
              id: quoteId,
              etag: updateResponse.etag,
            },
            customerType,
          },
        });
      }
    },
  });

  // updateTradeName mutation
  const [
    updateTradeName,
    { loading: updateTradeNameLoading, error: updateTradeNameError },
  ] = useMutation<{ updateTradeName: OrganizationSimple }, MutationUpdateTradeNameArgs>(
    UpdateTradeName,
    {
      onCompleted: () => {
        if (leadOrgUpdated && wizardState.account.accountId && wizardState.leadOrg.leadOrgId) {
          updateLeadOrg({
            variables: {
              input: {
                quote: {
                  id: quoteId,
                  etag: quoteEtag,
                },
                accountId: wizardState.account.accountId,
                organizationId: wizardState.leadOrg.leadOrgId,
                customerType,
              },
            },
          });
        } else {
          updateOrgVersion({
            variables: {
              input: {
                id: quoteId,
                etag: quoteEtag,
              },
              customerType,
            },
          });
        }
      },
    }
  );

  // updateOrganizationAddress mutation (also will update billing contact)
  const [
    updateOrgAddress,
    { loading: updateAddressLoading, error: updateAddressError },
  ] = useMutation<
    { updateOrganizationAddress: OrganizationSimple },
    MutationUpdateOrganizationAddressArgs
  >(UpdateOrgAddress, {
    onCompleted: data => {
      const updateResponse = data.updateOrganizationAddress;
      const organizationIdentifier = {
        organizationId: updateResponse.id,
        accountId: updateResponse.accountId,
      };
      if (tradeNameUpdated) {
        updateTradeName({
          variables: {
            input: { tradeName: wizardState.organization.tradeName, organizationIdentifier },
          },
        });
      } else if (leadOrgUpdated && wizardState.account.accountId && wizardState.leadOrg.leadOrgId) {
        updateLeadOrg({
          variables: {
            input: {
              quote: {
                id: quoteId,
                etag: quoteEtag,
              },
              accountId: wizardState.account.accountId,
              organizationId: wizardState.leadOrg.leadOrgId,
              customerType,
            },
          },
        });
      } else {
        updateOrgVersion({
          variables: {
            input: {
              id: quoteId,
              etag: quoteEtag,
            },
            customerType,
          },
        });
      }
    },
  });

  // createAccountAndOrganization mutation
  const [createAccountAndOrg, { loading: createLoading, error: createError }] = useMutation<
    { createAccountAndOrganization: Quote },
    MutationCreateAccountAndOrganizationArgs
  >(CreateAccountAndOrganization, {
    update(cache, response) {
      const updatedQuote = oc(response).data.createAccountAndOrganization();

      updatedQuote &&
        cache.writeQuery({
          query: GET_QUOTE,
          variables: { id: updatedQuote.id },
          data: { getQuote: updatedQuote },
        });
    },
    onCompleted: data => {
      const endCustomer = data.createAccountAndOrganization.endCustomer;
      endCustomer && associateCRMID(endCustomer.accountId, endCustomer.id);
      // TODO: jejungk - remove this when able to set leadOrg as part of create
      if (wizardState.leadOrg.leadOrgId && endCustomer) {
        updateLeadOrg({
          variables: {
            input: {
              quote: {
                id: quoteId,
                etag: quoteEtag,
              },
              accountId: endCustomer.accountId,
              organizationId: wizardState.leadOrg.leadOrgId,
              customerType,
            },
          },
        });
      }
    },
  });

  // createOrganization mutation
  const [createOrg, { loading: createOrgLoading, error: createOrgError }] = useMutation<
    { createOrganization: Quote },
    MutationCreateOrganizationArgs
  >(CreateOrganization, {
    update(cache, response) {
      const updatedQuote = oc(response).data.createOrganization();

      updatedQuote &&
        cache.writeQuery({
          query: GET_QUOTE,
          variables: { id: updatedQuote.id },
          data: { getQuote: updatedQuote },
        });
    },
    onCompleted: data => {
      const endCustomer = data.createOrganization.endCustomer;
      endCustomer && associateCRMID(endCustomer.accountId, endCustomer.id);
      // TODO: jejungk - remove this when able to set leadOrg as part of create
      if (wizardState.leadOrg.leadOrgId && wizardState.account.accountId) {
        updateLeadOrg({
          variables: {
            input: {
              quote: {
                id: quoteId,
                etag: quoteEtag,
              },
              accountId: wizardState.account.accountId,
              organizationId: wizardState.leadOrg.leadOrgId,
              customerType,
            },
          },
        });
      }
    },
  });
  //#endregion

  const isCreateUpdateLoading =
    createLoading ||
    createOrgLoading ||
    selectOrgLoading ||
    updateAddressLoading ||
    updateTradeNameLoading ||
    updateLeadOrgLoading ||
    versionLoading;
  const isCreateUpdateError =
    !!createError ||
    !!createOrgError ||
    !!selectOrgError ||
    !!updateAddressError ||
    !!updateTradeNameError ||
    !!updateLeadOrgError ||
    !!versionError;

  React.useEffect(() => {
    if (wizardState.address.country) {
      getMarketInfo({ variables: { market: wizardState.address.country as Market } });
    }
  }, [wizardState.address.country, getMarketInfo]);

  // When Address Validation Flight is on, we need to make a call to AVS with the starting address
  // so we can accurately determine if we can go to Overview with the prepopulated address
  // without this, it will only verify that the required fields have values, and that market resolves to an appropriate country
  React.useEffect(() => {
    validateAddress({
      variables: {
        input: wizardState.address,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region Create/Save/Select functions
  const onClickCreateOrganization = () =>
    !wizardState.account.accountName
      ? setCurrentStepFactory(OrganizationWizardView.AccountName)
      : !hasValidAddressFields(wizardState.address, addressConfiguration) ||
        initialAddressStatus === AddressValidationStatus.Invalid ||
        !initialAddressStatus
      ? setCurrentStepFactory(OrganizationWizardView.OrganizationName)
      : setCurrentStepFactory(OrganizationWizardView.Overview);

  const createOrganization = () => {
    const quoteInput: QuoteMutationInput = {
      id: quoteId,
      etag: quoteEtag,
    };
    const orgInput: OrganizationInput = {
      address: {
        name: wizardState.organization.organizationName,
        ...wizardState.address,
        country: wizardState.address.country as Market,
        ...wizardState.billingContact,
      },
      language: wizardState.invoiceLanguage,
      tradeName: wizardState.organization.tradeName,
    };
    setCurrentStepFactory(OrganizationWizardView.Processing);
    if (wizardState.account.accountId) {
      createOrg({
        variables: {
          input: {
            quote: quoteInput,
            organization: orgInput,
            accountId: wizardState.account.accountId,
          },
        },
      });
    } else if (wizardState.account.accountName) {
      createAccountAndOrg({
        variables: {
          input: {
            quote: quoteInput,
            accountDescription: wizardState.account.accountName,
            organization: orgInput,
            tenantId: props.tenantId || tenantIdForAccount,
          },
        },
      });
    }
  };

  const saveOrganization = () => {
    setCurrentStepFactory(OrganizationWizardView.Processing);
    if (wizardState.organization.organizationId && wizardState.account.accountId) {
      const organizationIdentifier = {
        accountId: wizardState.account.accountId,
        organizationId: wizardState.organization.organizationId,
      };

      if (addressUpdated) {
        updateOrgAddress({
          variables: {
            input: {
              address: {
                name: wizardState.organization.organizationName,
                ...wizardState.address,
                country: wizardState.address.country as Market,
                ...wizardState.billingContact,
              },
              organizationIdentifier,
            },
          },
        });
      } else if (tradeNameUpdated) {
        updateTradeName({
          variables: {
            input: {
              tradeName: wizardState.organization.tradeName,
              organizationIdentifier,
            },
          },
        });
      } else if (leadOrgUpdated) {
        updateLeadOrg({
          variables: {
            input: {
              quote: {
                id: quoteId,
                etag: quoteEtag,
              },
              accountId: organizationIdentifier.accountId,
              organizationId: oc(wizardState).leadOrg.leadOrgId(''), // if no leadOrg, pass empty string to remove
              customerType,
            },
          },
        });
      } else {
        closeDialog();
        return null;
      }
    }
  };

  const selectOrganization = (orgId: string) => {
    const quoteInput: QuoteMutationInput = {
      id: quoteId,
      etag: quoteEtag,
    };
    setCurrentStepFactory(OrganizationWizardView.Processing);
    wizardState.account.accountId &&
      selectOrg({
        variables: {
          input: {
            quote: quoteInput,
            accountId: wizardState.account.accountId,
            organizationId: orgId,
            customerType,
          },
        },
      });
  };

  const onSubmitClick = () => {
    searchOrgs({
      variables: {
        query: wizardState.organization.organizationName,
      },
    });
    setCurrentStepFactory(OrganizationWizardView.SimilarOrganization);
  };
  //#endregion

  //#region Set current dialog content
  const headline = isEditFlow ? t('quote::Edit Billing Account') : t('quote::New Billing Account');

  switch (currentView) {
    case OrganizationWizardView.AccountName: {
      return (
        <AccountNameDialog
          headline={headline}
          initialData={wizardState.account.accountName}
          isOverviewAvailable={showOverviewButton}
          onClose={closeDialog}
          onNextButtonClick={(accountName: string | null) => {
            dispatch(OrganizationWizardActions.setAccountName(accountName));
            setCurrentStepFactory(OrganizationWizardView.OrganizationName);
          }}
          onOverviewClick={(input?: string | null) => {
            input && dispatch(OrganizationWizardActions.setAccountName(input));
            setCurrentStepFactory(OrganizationWizardView.Overview);
          }}
        />
      );
    }
    case OrganizationWizardView.OrganizationName: {
      return (
        <OrganizationNameDialog
          headline={headline}
          initialData={wizardState.organization}
          isOverviewAvailable={showOverviewButton}
          onClose={closeDialog}
          onNextButtonClick={(organization: OrgWizardOrganization) => {
            dispatch(OrganizationWizardActions.setOrganization(organization));
            setCurrentStepFactory(OrganizationWizardView.Address);
          }}
          onOverviewClick={(input?: OrgWizardOrganization | null) => {
            input && dispatch(OrganizationWizardActions.setOrganization(input));
            if (isEditFlow && endCustomer && input) {
              if (oc(endCustomer).address.companyName() !== input.organizationName) {
                setAddressUpdated(true);
              }
              if (endCustomer.tradeName !== input.tradeName) {
                setTradeNameUpdated(true);
              }
            }
            setCurrentStepFactory(OrganizationWizardView.Overview);
          }}
        />
      );
    }
    case OrganizationWizardView.Address: {
      return (
        <AddressDialog
          headline={headline}
          initialAddressStatus={initialAddressStatus}
          initialData={wizardState.address}
          isEditFlow={isEditFlow}
          isOverviewAvailable={showOverviewButton}
          isSalesAccount={wizardState.initialDataSource === OrgWizardDataSource.SalesAccount}
          validationEnabled={true}
          onBackButtonClick={(address?: OrgWizardAddress) => {
            address && dispatch(OrganizationWizardActions.setAddress(address));
            address && dispatch(OrganizationWizardActions.setIsAddressValidated(true));
            setCurrentStepFactory(OrganizationWizardView.OrganizationName);
          }}
          onClose={closeDialog}
          onOverviewClick={(input?: OrgWizardAddress | null) => {
            input && dispatch(OrganizationWizardActions.setAddress(input));
            if (isEditFlow && endCustomer && input) {
              if (addressHasChanges(endCustomer.address, input)) {
                setAddressUpdated(true);
              }
            }
            setCurrentStepFactory(OrganizationWizardView.Overview);
          }}
          onPrimaryButtonClick={(address: OrgWizardAddress) => {
            dispatch(OrganizationWizardActions.setAddress(address));
            dispatch(OrganizationWizardActions.setIsAddressValidated(true));
            setCurrentStepFactory(OrganizationWizardView.Overview);
          }}
        />
      );
    }
    case OrganizationWizardView.Affiliate: {
      return (
        <AffiliateDialog
          hasLeadOrgId={!!wizardState.leadOrg.leadOrgId}
          hasSharedDiscounts={hasSharedDiscounts}
          headline={headline}
          initialData={wizardState.isAffiliate}
          isOverviewAvailable={showOverviewButton}
          onClose={closeDialog}
          onNextButtonClick={(isAffiliate: boolean) => {
            dispatch(OrganizationWizardActions.setIsAffiliate(isAffiliate));
            setCurrentStepFactory(OrganizationWizardView.ParentAccount);
          }}
          onOverviewClick={(input?: boolean | null) => {
            input
              ? dispatch(OrganizationWizardActions.setIsAffiliate(input))
              : dispatch(OrganizationWizardActions.setIsAffiliate(false));
            !input && dispatch(OrganizationWizardActions.setLeadOrg({}));
            if (isEditFlow && leadOrg && !input) {
              setLeadOrgUpdated(true);
            }
            setCurrentStepFactory(OrganizationWizardView.Overview);
          }}
        />
      );
    }
    case OrganizationWizardView.ParentAccount: {
      return (
        <ParentAccountDialog
          eligibleParentAccounts={eligibleParentAccounts}
          hasSharedDiscounts={hasSharedDiscounts}
          headline={headline}
          initialData={wizardState.leadOrg}
          isOverviewAvailable={showOverviewButton}
          onBackButtonClick={(leadOrg?: OrgWizardLeadOrg | null) => {
            dispatch(OrganizationWizardActions.setLeadOrg(leadOrg || {}));
            setCurrentStepFactory(OrganizationWizardView.Affiliate);
          }}
          onClose={closeDialog}
          onOverviewClick={(input?: OrgWizardLeadOrg | null) => {
            dispatch(OrganizationWizardActions.setLeadOrg(input || {}));
            if (isEditFlow && input && input.leadOrgId !== oc(leadOrg).id()) {
              setLeadOrgUpdated(true);
            }
            setCurrentStepFactory(OrganizationWizardView.Overview);
          }}
        />
      );
    }
    case OrganizationWizardView.Contact: {
      return (
        <BillingContactWizardDialog
          headline={headline}
          initialData={wizardState.billingContact}
          isOverviewAvailable={showOverviewButton}
          onClose={closeDialog}
          onOverviewClick={(input?: OrgWizardBillingContact | null) => {
            input && dispatch(OrganizationWizardActions.setBillingContact(input));
            if (
              isEditFlow &&
              endCustomer &&
              input &&
              (endCustomer.address.email !== input.email ||
                endCustomer.address.phoneNumber !== input.phoneNumber)
            ) {
              setAddressUpdated(true);
            }
            setCurrentStepFactory(OrganizationWizardView.Overview);
          }}
        />
      );
    }
    case OrganizationWizardView.InvoiceLanguage: {
      return (
        <InvoiceLanguageDialog
          headline={headline}
          initialData={wizardState.invoiceLanguage}
          invoiceLanguages={invoiceLanguageList}
          isOverviewAvailable={true}
          onClose={closeDialog}
          onOverviewClick={(input?: GqlLanguage | null) => {
            input && dispatch(OrganizationWizardActions.setInvoiceLanguage(input));
            setCurrentStepFactory(OrganizationWizardView.Overview);
          }}
        />
      );
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore no-switch-case-fallthrough
    case OrganizationWizardView.SimilarOrganization: {
      if (searchOrgsLoading) {
        return (
          <Processing
            key="Processing"
            {...dialogDimensions}
            message1={t('quote::Searching for similar billing accounts')}
          />
        );
      } else if (searchOrgsError) {
        createOrganization();
        // break is omitted intentionally as createOrganization sets OrganizationWizardView.Processing, which is next case in switch, so fallthrough is ok
        // falls through
      } else if (searchOrgsData) {
        const similarOrgs = searchOrgsData.searchOrganizations;
        const similarOrg = similarOrgs.find((org: OrganizationSimple) => {
          return (
            org.name === wizardState.organization.organizationName ||
            org.address.addressLine1 === wizardState.address.addressLine1
          );
        });
        if (!!similarOrg) {
          const useExistingOrganization = () => {
            setOrganizationAction(OrganizationAction.Select);
            setCurrentStepFactory(OrganizationWizardView.Processing);
            setSelectedOrgId(similarOrg.id);
            selectOrganization(similarOrg.id);
          };
          const createdOrg = {
            address: wizardState.address,
            accountName: wizardState.account.accountName || '',
            orgName: wizardState.organization.organizationName,
            tradeName: wizardState.organization.tradeName,
          };
          const foundOrg = {
            address: {
              addressLine1: similarOrg.address.addressLine1 || '',
              addressLine2: similarOrg.address.addressLine2 || '',
              addressLine3: similarOrg.address.addressLine3 || '',
              city: similarOrg.address.city || '',
              region: similarOrg.address.region || '',
              country: similarOrg.address.country,
              postalCode: similarOrg.address.postalCode || '',
            },
            accountName: similarOrg.account.description || '',
            orgName: similarOrg.name,
            tradeName: getValueOrUndefined(similarOrg.tradeName),
          };
          return (
            <SimilarOrgFoundDialog
              createdOrganization={createdOrg}
              foundOrganization={foundOrg}
              headline={headline}
              onClose={closeDialog}
              onCreateClick={createOrganization}
              onSelectClick={useExistingOrganization}
            />
          );
        } else {
          createOrganization();
          // break is omitted intentionally as createOrganization sets OrganizationWizardView.Processing, which is next case in switch, so fallthrough is ok
          // falls through
        }
      }
    }
    case OrganizationWizardView.Processing: {
      let tryAgain = () => {};
      let loadingMessage = '';
      let successMessage: string | React.ReactElement | undefined;

      if (organizationAction === OrganizationAction.Create) {
        loadingMessage = t(
          'quote::The billing account is being created. Please be patient, this may take up to 60 seconds.'
        );
        successMessage = (
          <TextBody>
            {t('quote::The billing account ')}
            <span className={props.classes.bold}>{wizardState.organization.organizationName}</span>
            {t('quote:: has been successfully created.')}
          </TextBody>
        );
        tryAgain = createOrganization;
      } else if (organizationAction === OrganizationAction.Update) {
        loadingMessage = t(
          'quote::The billing account is being updated. Please be patient, this may take up to 60 seconds.'
        );
        successMessage = t('quote::The billing account has been successfully updated.');
        tryAgain = saveOrganization;
      } else {
        loadingMessage = t(
          'quote::The billing account is being selected. Please be patient, this may take a few seconds.'
        );
        if (selectedOrgId) {
          tryAgain = () => selectOrganization(selectedOrgId);
        }
      }

      if (isCreateUpdateLoading) {
        return <Processing {...dialogDimensions} key="Processing" message1={loadingMessage} />;
      } else if (isCreateUpdateError) {
        return (
          <Fail
            {...dialogDimensions}
            dataAutomationId="billingAccountProcessingFail"
            onTryAgainClick={tryAgain}
          />
        );
      } else if (successMessage) {
        return (
          <Success
            message={successMessage}
            // preventing clicking onClose from actually closing the dialog so that OrganizationWizard can handle if we are ready to close the dialog, or if we need to open the sharedDiscountsDialog as part of openSharedDiscounts function
            overrideDialogClose
            onClose={openSharedDiscounts}
            {...dialogDimensions}
            dataAutomationId="billingAccountProcessingSuccess"
          />
        );
      } else {
        closeDialog();
        return null;
      }
    }
    case OrganizationWizardView.SearchAccount: {
      return (
        <SearchAccountDialog
          usingTestHeaders={props.usingTestHeaders}
          onAccountFound={(
            account: OrgWizardAccount,
            tenantId: string,
            iwOnly: boolean,
            organizations: OrganizationSimple[]
          ) => {
            dispatch(OrganizationWizardActions.setAccountId(account.accountId));
            dispatch(OrganizationWizardActions.setAccountName(account.accountName));
            setIsDemoTenantId(isDemoTenant(tenantId));
            setOrgsOnAccount(organizations);
            if (iwOnly) {
              setCurrentStepFactory(OrganizationWizardView.IW);
            } else {
              setCurrentStepFactory(OrganizationWizardView.OrganizationsOnAccount);
            }
          }}
          onClose={closeDialog}
          onNextButtonClick={(account?: OrgWizardAccount, tenantId?: string) => {
            if (account) {
              dispatch(OrganizationWizardActions.setAccountId(account.accountId));
              dispatch(OrganizationWizardActions.setAccountName(account.accountName));
            }
            tenantId && setIsDemoTenantId(isDemoTenant(tenantId));
            tenantId && setTenantIdForAccount(tenantId);
            onClickCreateOrganization();
          }}
        />
      );
    }
    case OrganizationWizardView.IW: {
      if (!wizardState.account.accountId) {
        loggerService.error({ error: new Error('Tried to go to IW dialog with no account found') });
        return <Fail {...dialogDimensions} dataAutomationId="noAccountFoundFail" />;
      }
      const individuals = orgsOnAccount.map(organization => organization.name);
      return (
        <AccountIndividualsDialog
          individuals={individuals}
          onClose={closeDialog}
          onNextButtonClick={onClickCreateOrganization}
        />
      );
    }
    case OrganizationWizardView.OrganizationsOnAccount: {
      if (!wizardState.account.accountId) {
        loggerService.error({
          error: new Error('Tried to go to AccountOrganizations dialog with no account found'),
        });
        return <Fail {...dialogDimensions} dataAutomationId="noAccountFoundFail" />;
      }
      const useExistingOrganization = (organizationId: string) => {
        setOrganizationAction(OrganizationAction.Select);
        setCurrentStepFactory(OrganizationWizardView.Processing);
        setSelectedOrgId(organizationId);
        selectOrganization(organizationId);
      };
      return (
        <AccountOrganizationsDialog
          accountName={wizardState.account.accountName}
          createOrganization={onClickCreateOrganization}
          disableCreateOrganizationButton={isDemoTenantId}
          organizations={orgsOnAccount}
          useExistingOrganization={useExistingOrganization}
          onClose={closeDialog}
        />
      );
    }
    case OrganizationWizardView.Overview: {
      const orgNameAndDba = (
        <>
          <div data-automation-id="billingAccountNameValue">
            <TextBody
              addClass={classes.textOverflow}
              title={wizardState.organization.organizationName}
            >
              {wizardState.organization.organizationName}
            </TextBody>
          </div>
          {wizardState.organization.tradeName && (
            <div>
              <TextBody
                addClass={classes.textOverflow}
                title={t('quote::Doing business as {{tradeName}}', {
                  tradeName: wizardState.organization.tradeName,
                })}
              >
                {`dba ${wizardState.organization.tradeName}`}
              </TextBody>
            </div>
          )}
        </>
      );

      const leftColumn: OverviewRowProps[] = [
        {
          ariaLabel: 'MCAPI Account',
          label: t('quote::"MCAPI Account"'), //TODO - update name when the name for "MCAPI Account" is determined
          onClick: setCurrentStepFromOverview(OrganizationWizardView.AccountName),
          body: wizardState.account.accountName,
          hideIcon: !!wizardState.account.accountId,
          dataAutomationId: 'account',
        },
        {
          ariaLabel: 'Billing Account Name',
          label: t('quote::Name'),
          onClick: setCurrentStepFromOverview(OrganizationWizardView.OrganizationName),
          onRenderBody: () => orgNameAndDba,
          dataAutomationId: 'billingAccountName',
        },
        {
          ariaLabel: 'Affiliate',
          label: t('quote::Affiliate'),
          onClick: !!eligibleParentAccounts.length
            ? setCurrentStepFromOverview(OrganizationWizardView.Affiliate)
            : () => setAffiliateInfoOpen(!affiliateInfoOpen),
          body: wizardState.isAffiliate
            ? t(`quote::Affiliate of {{parentAccountName}}`, {
                parentAccountName: wizardState.leadOrg.leadOrgName,
              })
            : t('quote::No'),
          iconName: !!eligibleParentAccounts.length ? undefined : 'Info',
          buttonId: !!eligibleParentAccounts.length
            ? 'affiliate-edit-button'
            : 'affiliate-info-button',
          hideIcon: leadsLoading,
          dataAutomationId: 'billingAccountAffiliate',
        },
        {
          ariaLabel: 'Address',
          label: t('quote::Address'),
          onClick: setCurrentStepFromOverview(OrganizationWizardView.Address),
          // eslint-disable-next-line react/display-name
          onRenderBody:
            wizardState.address &&
            (() => (
              // TODO: jejungk - GQL is not translating addresses. Need to go back and do work to do translated Addresses. Also need to make a new Address component that handles the 'AddressLastLine' coming from GQL
              <AddressLarge
                address={{ ...wizardState.address, country: countryDisplayName, companyName: '' }}
                dataAutomationId="organizationAddress"
              />
            )),
          dataAutomationId: 'billingAccountAddress',
        },
      ];

      const contactInformationBody = () => (
        <BillingContactInformation
          dataAutomationId="addBillingContactLink"
          {...wizardState.billingContact}
          overflowEmail
          onClick={setCurrentStepFromOverview(OrganizationWizardView.Contact)}
        />
      );
      const displayLanguageInfo = invoiceLanguageList.find(
        invoiceLanguage => invoiceLanguage.gqlCode === wizardState.invoiceLanguage
      );

      const rightColumn: OverviewRowProps[] = [
        {
          ariaLabel: t('quote::Edit contact information'),
          label: t('quote::Contact'),
          maxWidth: 200,
          onClick: setCurrentStepFromOverview(OrganizationWizardView.Contact),
          onRenderBody: contactInformationBody,
          dataAutomationId: 'contact',
        },
        {
          ariaLabel: t('quote::Edit invoice language'),
          label: t('quote::Invoice language'),
          maxWidth: 200,
          onClick: setCurrentStepFromOverview(OrganizationWizardView.InvoiceLanguage),
          body: displayLanguageInfo ? displayLanguageInfo.display : wizardState.invoiceLanguage,
          hideIcon: invoiceLanguageList.length < 2 || isEditFlow, //TODO: update when becomes able to edit after creation
          dataAutomationId: 'invoiceLanguage',
        },
        {
          ariaLabel: t('quote::Edit terms'),
          label: t('quote::Required terms'),
          onClick: () => {},
          body: wizardState.isAffiliate
            ? t('quote::Customer Affiliate Purchase Terms')
            : t('quote::Microsoft Customer Agreement'),
          hideIcon: true,
          dataAutomationId: 'requiredTerms',
        },
      ];

      return (
        <OverviewDialog
          enrollmentNumber={
            wizardState.initialDataSource === OrgWizardDataSource.Enrollment &&
            crmLead.vlAgreementNumber
              ? crmLead.vlAgreementNumber
              : undefined
          }
          headline={headline}
          isDemoTenant={isDemoTenantId}
          isEditFlow={isEditFlow}
          isInfoDefaulted={
            wizardState.initialDataSource === OrgWizardDataSource.Empty ? false : true
          }
          isInfoOpen={affiliateInfoOpen}
          organizationsFirstColumnRows={leftColumn}
          organizationsSecondColumnRows={rightColumn}
          onClose={closeDialog}
          onCreateClick={onSubmitClick}
          onInfoClose={() => setAffiliateInfoOpen(false)}
          onSaveClick={saveOrganization}
        />
      );
    }
  }
  //#endregion
};

export const OrganizationWizard = connect(mapStateToProps)(
  withStyles(organizationWizardStyles)(OrganizationWizardUnstyled)
);

export const OrganizationWizardContainer: React.FC<OrganizationWizardContainerProps> = props => {
  const { activeQuote, loading } = React.useContext(ActiveQuoteContext);
  const dialogContext = React.useContext(DialogContext);

  const closeDialog = () => {
    dialogContext.closeDialog();
  };

  if (loading) {
    return (
      <Dialog {...dialogDimensions} closeDialog={closeDialog}>
        <Spinner />
      </Dialog>
    );
  }

  if (!activeQuote) {
    loggerService.error({ error: new Error('Tried to open OrganizationWizard with no quote') });
    return <Fail {...dialogDimensions} dataAutomationId="orgWizardNoQuoteFail" />;
  }

  const crmLead = activeQuote.crmLead;

  if (!crmLead) {
    loggerService.error({
      error: new Error('Tried to open OrganizationWizard with no crmLead on quote'),
    });
    return <Fail {...dialogDimensions} dataAutomationId="orgWizardNoCrmFail" />;
  }

  const tenantId = oc(props).tenant.tenantId();
  const account = oc(props).tenant.account() || oc(activeQuote).endCustomer.account();
  const endCustomer = activeQuote.endCustomer;
  const leadOrg = activeQuote.leadOrganization;
  const lineItems: (DiscountLineItem | PurchaseLineItem | TermLineItem | MonetaryLineItem)[] =
    activeQuote.lineItems;
  const hasSharedDiscounts: boolean =
    lineItems &&
    lineItems.some(
      lineItem =>
        lineItem.__typename === 'DiscountLineItem' &&
        oc(lineItem).discount.priceAdjustmentType() === PriceAdjustmentType.Extend
    );

  const defaultFlow = endCustomer
    ? OrganizationWizardFlow.Edit
    : account
    ? OrganizationWizardFlow.CreateOrganization
    : OrganizationWizardFlow.CreateAccountAndOrganization;

  const defaultInitialView = endCustomer
    ? OrganizationWizardView.Overview
    : account
    ? OrganizationWizardView.OrganizationName
    : tenantId
    ? OrganizationWizardView.AccountName
    : OrganizationWizardView.SearchAccount;

  const customerType =
    oc(activeQuote).transactionModel() === TransactionModel.ToPartnerCustomerAsset
      ? CustomerType.EndCustomer
      : undefined;

  return (
    <OrganizationWizard
      account={account}
      crmLead={crmLead}
      customerType={customerType}
      endCustomer={endCustomer}
      flow={props.flow || defaultFlow}
      hasSharedDiscounts={hasSharedDiscounts}
      initialView={props.initialView || defaultInitialView}
      leadOrg={leadOrg}
      quoteEtag={activeQuote.etag}
      quoteId={activeQuote.id}
      tenantId={tenantId}
    />
  );
};

export const openOrganizationWizardDialog = (
  context: DialogContextType,
  props: OrganizationWizardContainerProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <OrganizationWizardContainer {...props} />,
  };
  context.openDialog(dialogProps);
};
