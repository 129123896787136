import { ConfigCardButton } from 'features-apollo/quote/components/ConfigCards';
import { DiscountCardLink } from 'features-apollo/quote/components/DiscountCard';
import { QuoteListColumn } from 'features-apollo/quote/components/Lists/types';
import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import { MonetaryLineItem, PurchaseLineItem } from 'generated/graphql';
import React from 'react';
import { oc } from 'ts-optchain';

import { QuoteListRow, SelectionProps } from '../QuoteListRow';
import { LineItemRowProps, ProductLineItemContentAlignment } from '../types';
import { percentageFormatter, renderPrice, titleFormatter } from '../utils';
import { Quantity } from './Quantity';

export interface PurchaseRowProps extends LineItemRowProps, SelectionProps {
  /**
   * Identifies the line item on the quote line items list.
   */
  lineItem: PurchaseLineItem | MonetaryLineItem;
  /**
   * Total amount of this product with this configuration
   */
  quantity: number;
  /**
   * Determines the boundaries of quantity
   */
  quantityBoundaries?: { min: number; max: number };
  /**
   * Determines if quantity can be edit
   */
  quantifiable: boolean;
  /**
   * Represents the price listed in the product information.
   * Product price currency can be different from quote billing currency.
   */
  listPrice: {
    price?: number | null;
    currency: string;
  };
  /**
   * Defines the price the customer is getting
   */
  customerPrice?: number | null;
  /**
   * Defines a one-time discount for this product in percentage
   */
  oneTimeDiscount?: number | null;
  /**
   * Total price
   */
  amount?: number | null;
  /**
   * Defines quote's billing currency
   */
  currency: string;
  /**
   * Defines the current discount configuration
   */
  discountConfiguration?: string | null;
  /**
   * Determines whether a discount can be added or change
   */
  discountable: boolean;
  /**
   * Defines a custom flex CSS rule per content.
   */
  contentAlignment?: ProductLineItemContentAlignment;
  /**
   * Indicates if a line item needs any futher configuration
   */
  isReadyForPricing?: boolean;
  /**
   * Function that updates the quantity to the new one
   */
  updateQuantity?: (newQuantity: number, lineItemId: string) => void;
}

export const PurchaseRow: React.FC<PurchaseRowProps> = props => {
  const topColumns: QuoteListColumn[] = [
    {
      id: 'listrow-name',
      content: titleFormatter(props.productTitle),
      ...oc(props).contentAlignment.name({}),
    },
    {
      id: 'listrow-quantity',
      content: (
        <Quantity
          failedToLoad={props.errored}
          lineItemId={props.lineItem.id}
          quantifiable={props.quantifiable}
          quantity={props.quantity}
          quantityBoundaries={props.quantityBoundaries}
          updateQuantity={props.updateQuantity}
        />
      ),
      ...oc(props).contentAlignment.quantity({}),
    },
    {
      id: 'listrow-list-price',
      content: renderPrice(props.listPrice.price, props.listPrice.currency),
      ...oc(props).contentAlignment.listPrice({}),
    },
    {
      id: 'listrow-customer-price',
      content: renderPrice(props.customerPrice, props.currency),
      ...oc(props).contentAlignment.customerPrice({}),
    },
    {
      id: 'listrow-discount',
      content: percentageFormatter(props.oneTimeDiscount),
      ...oc(props).contentAlignment.discount({}),
    },
    {
      id: 'listrow-amount',
      content: renderPrice(props.amount, props.currency),
      ...oc(props).contentAlignment.amount({}),
    },
  ];

  const bottomColumns: QuoteListColumn[] = props.productUnavailable
    ? []
    : [
        {
          id: 'purchase-config-card-button',
          content: (
            <ConfigCardButton
              alreadyHasDiscount={props.oneTimeDiscount != null}
              configuration={props.configuration}
              disabled={props.readOnly}
              isConfigurable={props.configurable}
              lineItem={props.lineItem}
              quoteId={props.quoteId}
            />
          ),
          ...oc(props).contentAlignment.configCardButton({}),
        },
      ];

  if (bottomColumns.length && props.discountable) {
    bottomColumns.push({
      id: 'purchase-discount-config-card-button',
      content: (
        <>
          <DiscountCardLink
            dataAutomationId="discount-configcard-link"
            disabled={!props.configuration || props.readOnly}
            linkText={getValueOrUndefined(props.discountConfiguration)}
            selectedIds={[props.lineItem.id]}
          />
        </>
      ),
      ...oc(props).contentAlignment.discountConfigCardButton({}),
    });
  }

  return (
    <QuoteListRow
      {...props}
      bottomColumns={bottomColumns}
      isInErrorState={props.errored}
      isInWarnState={props.isInWarnState}
      isSelected={props.selectedIds.includes(props.lineItem.id)}
      lineItem={props.lineItem}
      topColumns={topColumns}
    />
  );
};
