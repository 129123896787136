import { Callout, IconButton } from 'components/ions';
import { TextBody, TextTitleSecondary } from 'components/ions/Text';
import moment from 'moment';
import { DirectionalHint } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { formatExchangeRate } from './utils';

interface ConversionButtonProps {
  /**
   * Displays in callout
   */
  exchangeRate: number;
  /**
   * Displays in callout
   */
  exchangeDate?: Date;
}

const UNDEFINED_PLACEHOLDER = '--';
const DATE_FORMAT = 'YYYY-MM-DD';

const styles = (theme: ThemeProps) => ({ title: { fontSize: theme.fonts.fontSizes.xLarge } });

type Props = ConversionButtonProps & WithStyles<typeof styles>;

const ConversionButtonUnstyled: React.FC<Props> = props => {
  const [calloutVisible, setCalloutVisible] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const exchangeRate = formatExchangeRate(props.exchangeRate);
  const exchangeDate = props.exchangeDate
    ? moment(props.exchangeDate).format(DATE_FORMAT)
    : UNDEFINED_PLACEHOLDER;

  return (
    <>
      <div ref={ref} style={{ height: 'min-content' }}>
        <IconButton
          ariaLabel={t('quote::Price Conversion')}
          dataAutomationId="price-conversion"
          iconName="info"
          onClick={() => setCalloutVisible(!calloutVisible)}
        />
      </div>
      <Callout
        calloutMaxWidth={320}
        directionalHint={DirectionalHint.topCenter}
        hidden={!calloutVisible}
        isBeakVisible={false}
        preventDismissOnLostFocus={false}
        styles={{ calloutMain: { padding: 14 } }}
        target={ref.current}
        trapFocus={false}
        onDismiss={() => {
          setCalloutVisible(false);
        }}
      >
        <TextTitleSecondary>{t('quote::Price Conversion')}</TextTitleSecondary>
        <TextBody>
          {t(
            'quote::Settlement currency for Azure products is an estimate based on an exchange rate of {{exchangeRate}} per 1 USD as of {{exchangeDate}}.',
            { exchangeRate, exchangeDate }
          )}
        </TextBody>
      </Callout>
    </>
  );
};

export const ConversionButton = withStyles(styles)(ConversionButtonUnstyled) as React.FC<
  ConversionButtonProps
>;
