import { Dialog, PrimaryButton } from 'components';
import { Dimensions, Fail, Processing } from 'features/components/dialogs';
import {
  GET_QUOTERECOMMENDATIONS,
  RENAME_FAVORITEGROUP,
} from 'features-apollo/quote/components/queries';
import { CatalogContext, Query } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ProductGroup } from 'services/user-preferences/types';
import { ThemeProps } from 'styles';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';
import { useMutation } from '@apollo/react-hooks';

import { GroupNameField } from './GroupNameField';

const dimensions: Dimensions = {
  height: 220,
  width: 424,
};

const styles = (theme: ThemeProps) => ({
  texboxInstructions: {
    color: theme.palette.textTertiary,
    paddingLeft: 8,
  },
});

interface RenameProductGroupDialogProps {
  currentGroupName: string;
  elementIdToFocusOnDismiss?: string;
  quoteId?: string;
  favoritesProductGroups?: Record<string, ProductGroup>;
  catalogContext?: CatalogContext;
}

type Props = RenameProductGroupDialogProps & WithStyles<typeof styles>;

export const RenameProductGroupDialogUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [newGroupName, setNewGroupName] = React.useState<string | undefined>();
  const [renamingGroup, setRenamingGroup] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean | undefined>();
  const context = React.useContext(DialogContext);

  const onClose = () => {
    context.closeDialog();

    if (props.elementIdToFocusOnDismiss) {
      const element = document.getElementById(props.elementIdToFocusOnDismiss);
      element && element.focus();
    }
  };
  const [isProductGroupRenamed, setIsProductGroupRenamed] = React.useState<boolean | undefined>(
    undefined
  );
  const [renameProductGroup, { error, loading }] = useMutation(RENAME_FAVORITEGROUP, {
    onCompleted: data => {
      if (data && data.renameFavoriteGroup) {
        setIsProductGroupRenamed(
          !data.renameFavoriteGroup.some(
            (group: { name: string }) => group.name === props.currentGroupName
          )
        );
      }
    },
  });

  React.useEffect(() => {
    if (loading && !isProductGroupRenamed) {
      setIsLoading(true);
    } else if (!loading && isProductGroupRenamed) {
      setIsLoading(false);
    }
  }, [loading, isProductGroupRenamed]);

  const buildOptimisticResponse = (name: string) => {
    return {
      renameFavoriteGroup: [
        {
          items: [],
          name: name,
          type: 'Product',
          __typename: 'RecommendationGroup',
        },
      ],
      __typename: 'Mutation',
    };
  };

  const renameGroup = () => {
    if (newGroupName) {
      renameProductGroup({
        variables: {
          input: {
            groupName: props.currentGroupName,
            newGroupName: newGroupName,
            catalogContext: props.catalogContext,
          },
        },
        optimisticResponse: buildOptimisticResponse(newGroupName),
        update: (useApolloClient, { data: { renameFavoriteGroup } }) => {
          // Read from cached
          const cached = useApolloClient.readQuery<Query>({
            query: GET_QUOTERECOMMENDATIONS,
            variables: { id: props.quoteId, input: props.catalogContext },
          });

          // Create update object
          if (cached && cached.getQuote && cached.getQuote.recommendations && renameFavoriteGroup) {
            const cachedRec = cached.getQuote.recommendations.find(
              rec => rec.name === props.currentGroupName
            );
            if (cachedRec) {
              cachedRec.name = newGroupName;
            }
          }

          useApolloClient.writeQuery({
            query: GET_QUOTERECOMMENDATIONS,
            variables: { id: props.quoteId, input: props.catalogContext },
            data: cached,
          });

          setIsLoading(false);
          onClose();
        },
      });

      setRenamingGroup(true);
    }
  };

  // #region adding product states
  if (newGroupName && renamingGroup) {
    if (isLoading) {
      return (
        <Processing
          {...dimensions}
          message1={t('Renaming group to "{{newGroupName}}"', { newGroupName })}
        />
      );
    } else if (error) {
      return <Fail {...dimensions} closeDialog={onClose} message={t('Unable to rename group')} />;
    } else if (isProductGroupRenamed) {
      onClose();
      return null;
    }
  }
  // #endregion

  return (
    <Dialog
      {...dimensions}
      closeDialog={onClose}
      footerButtons={
        <PrimaryButton
          dataAutomationId="productGroupDialogRenameGroup"
          disabled={!newGroupName}
          text={t('Rename grouping')}
          onClick={renameGroup}
        />
      }
      title={t('Rename product grouping')}
    >
      <GroupNameField
        favoritesProductGroups={props.favoritesProductGroups}
        onChange={(userInput?: string) => {
          setNewGroupName(userInput);
        }}
        onEnterKeyDown={renameGroup}
      />
    </Dialog>
  );
};

const RenameProductGroupDialog = withStyles(styles)(RenameProductGroupDialogUnstyled);

export const openRenameProductGroupDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: RenameProductGroupDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <RenameProductGroupDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
