import { DraggableId, DraggableLocation } from 'react-beautiful-dnd';
import { CreateLineItemsRequest, ProductIdentifier, Proposal } from 'services/proposal/types';

/**
 *  Props for the drag and drop items
 *  @prop {string []} selectedItemIds - Array of ids to keep track of the user's selection
 *  @prop {string} draggingItemId - Id of the item currently being dragged. Even if there are mulitple dragged items, this item is visually at the forefront.
 *  @prop {(itemId: string) => void} toggleSelection - function that updates which item is selected
 *  @prop {(itemId: string) => void} toggleSelectionInGroup - function that removes items that are no longer selected
 *  @prop {(itemId: string) => void} multiSelectTo - function that updates selected ids
 *  @prop {string} droppableId - Id of the drop zone the item is being dragged to
 */
export interface DragDropProps {
  selectedItemIds?: string[];
  draggingItemId?: string;
  toggleSelection?: (itemId: string) => void;
  toggleSelectionInGroup?: (itemId: string) => void;
  multiSelectTo?: (itemId: string) => void;
  droppableId: string;
}

export interface ExtendedDragDropProps extends DragDropProps {
  column: Column;
  entities: Entities;
}

export interface Dragging {
  id: DraggableId;
  location: DraggableLocation;
}

export interface FinderItemError {
  errorTitle: string;
  errorPrimary: string;
  errorSecondary: string;
}

/**
 * Props for an item in the list
 * @prop {string} itemText - text to be displayed
 * @prop {string} id - Id for the item in the list
 */
export interface FinderItem {
  productIdentifier?: ProductIdentifier;
  id: string;
  itemText: string;
  disabled?: boolean;
  addClass?: string;
  favoriteButton?: React.ReactNode;
  maxQuantityOnProposal?: number;
  error?: FinderItemError;
  type?: FinderItemType;
  oneAskId?: string;
}

export enum FinderItemType {
  ProductItem = 'ProductItem',
  UploadItem = 'UploadItem',
}

export interface Column {
  id: string;
  itemIds: string[];
}

export interface ColumnMap {
  [columnId: string]: Column;
}

export interface ItemMap {
  [itemId: string]: FinderItem;
}

export interface Entities {
  columnOrder: string[];
  columns: ColumnMap;
  items: ItemMap;
}

export interface CreateLineItemArgs {
  productIdentifier: ProductIdentifier;
  selectedProject?: string;
}

export interface Args {
  entities: Entities;
  selectedItemIds: string[];
  source: DraggableLocation;
  destination: DraggableLocation;
}

export interface AddLineItemArgs {
  isProposalReadonly: boolean;
  proposal: Proposal;
  proposalServiceEndpoint: string;
  addLineItem: (request: CreateLineItemsRequest) => void;
  selectedProject?: string;
}

export interface Result {
  entities: Entities;
  selectedItemIds: string[];
}
