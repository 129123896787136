import { Action, Middleware } from 'redux';
import * as LocalStorage from 'services/local-storage-service';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

const localStorageKey = 'redux-storage-middleware';

const mapStateToSave = (state: RootState) => {
  return {
    app: {
      isCachedFlightsEnabled: state.app.isCachedFlightsEnabled,
      flights: state.app.isCachedFlightsEnabled ? state.app.flights : undefined,
    },
  };
};

const createLocalStorageReduxMiddleware = () => {
  const localStorageReduxMiddleware: Middleware = store => next => (action: Action<string>) => {
    const result = next(action);
    const state = mapStateToSave(store.getState());
    LocalStorage.set(localStorageKey, state);
    return result;
  };

  return localStorageReduxMiddleware;
};

export const getLocalStorageState = (): Partial<RootState> => {
  let state = LocalStorage.get<RootState>(localStorageKey);

  if (state) {
    if (state.app && !state.app.isCachedFlightsEnabled) {
      delete state.app.flights;
    }

    return state;
  }

  return {};
};

export const clearFlightsFromStorage = () => {
  let state = LocalStorage.get<RootState>(localStorageKey);

  if (state && oc(state).app.flights()) {
    delete state.app.flights;
    LocalStorage.set<RootState>(localStorageKey, state);
  }
};

export const localStorageMiddleware = createLocalStorageReduxMiddleware();
