import { AnimateIn, ErrorMessage, Spinner, SpinnerSize } from 'components/ions';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import {
  AgreementLineItemDetails,
  getQuoteDetailsProps,
  getSelectedLineItems,
  LineItemDetails,
  QuoteDetailsPane,
} from 'features-apollo/quote/components/DetailsPane';
import { getMinCreditLineDivisor, getSelectedItemIds } from 'features-apollo/quote/selectors';
import { getFlightIsEnabled } from 'features/app/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

import { getCommentsDetails, getRequiredApprovalsPerPolicy } from '../Approval';
import { DetailsStyles } from './DetailsPane.styles';

export interface DetailsFlights {
  modernOfficeEnabled: boolean;
  showAddons: boolean;
  enableECIFUpdates: boolean;
}

const mapStateToProps = (state: RootState) => ({
  selectedItemIds: getSelectedItemIds(state),
  minCreditDivisor: getMinCreditLineDivisor(state),
  flights: {
    modernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
    showAddons: getFlightIsEnabled(state, Flight.showAddons),
    enableECIFUpdates: getFlightIsEnabled(state, Flight.ECIF),
  },
});

type Props = ReturnType<typeof mapStateToProps> & WithStyles<typeof DetailsStyles>;

export enum DetailsPaneFormat {
  quote = 'QuoteDetails',
  lineItem = 'LineItemDetails',
  multiSelect = 'MultiSelectDetails',
}

const DetailsPaneUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, minCreditDivisor, selectedItemIds, flights } = props;

  const { activeQuote, loading } = React.useContext(ActiveQuoteContext);
  const { t } = useTranslation();

  let bodyContent = null;
  if (loading) {
    bodyContent = <Spinner size={SpinnerSize.large} />;
  } else if (!loading && !activeQuote) {
    bodyContent = <ErrorMessage mainMessage={t('quote::Unable to load data')} />;
  } else if (activeQuote) {
    const selectedLineItems =
      selectedItemIds &&
      selectedItemIds.length &&
      getSelectedLineItems(activeQuote, selectedItemIds);

    const isAgreementSelected =
      selectedItemIds && selectedItemIds.length === 1 && selectedItemIds[0].includes('agreement');

    if (selectedLineItems.length) {
      bodyContent = (
        <LineItemDetails
          flights={flights}
          quote={activeQuote}
          selectedLineItems={selectedLineItems}
        />
      );
    } else if (isAgreementSelected) {
      bodyContent = <AgreementLineItemDetails quote={activeQuote} />;
    } else {
      const requiredApprovals = getRequiredApprovalsPerPolicy(activeQuote);
      const commentsDetails = getCommentsDetails(activeQuote);

      bodyContent = (
        <QuoteDetailsPane
          {...getQuoteDetailsProps(activeQuote, minCreditDivisor)}
          commentsDetails={commentsDetails}
          quote={activeQuote}
          requiredApprovals={requiredApprovals}
        />
      );
    }
  }

  return (
    <AnimateIn classOverride={classes.animateIn} hasContent={!loading}>
      <div
        className={classes.detailsContainer}
        data-automation-id="Quote-DetailsPane"
        id="QuoteDetails"
      >
        <>{bodyContent}</>
      </div>
    </AnimateIn>
  );
};

export const DetailsPane = connect(mapStateToProps)(withStyles(DetailsStyles)(DetailsPaneUnstyled));
