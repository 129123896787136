import { BarButtonProps, ShimmerForBackgroundStandout } from 'components/ions';
import { GetModernAgreementPreview } from 'features-apollo/quote/components/queries';
import { getAgreementConfig, getFlightIsEnabled } from 'features/app/selectors';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import * as catalogSelectors from 'features/catalog/selectors';
import {
  addSkuTitle,
  clearItemSelection,
  deleteProposalLineItemsAsync,
  removeLineItemGroupingAsync,
  toggleItemSelection,
} from 'features/proposal/actions';
import {
  ProductAndQualifyingSkusTitles,
  QualifyingSkuAvailability,
} from 'features/proposal/components/ConfigCard';
import { openAgreementPreview } from 'features/proposal/components/Dialogs';
import { getGroupingDeleteRequest } from 'features/proposal/components/FinanceTermsCard/FinanceCardBusinessLogic';
import { ProductListLineItem } from 'features/proposal/components/List/ProductList/types';
import {
  ProposalList,
  ProposalListColumnRenderProps,
  ProposalListWatermarkType,
  textFormatter,
  titleFormatter,
} from 'features/proposal/components/ProposalList';
import {
  getActiveProposal,
  getAllGroupedLineItems,
  getBillingCurrency,
  getGroupedLineItems,
  getLastSelectedId,
  getLineItemsSwitchingDFD,
  getProductListLineItems,
  getSelectedIds,
  getSkuTitles,
  getTermListLineItems,
  isPartnerProposal,
  isProposalReadOnly,
  lineItemLoading,
} from 'features/proposal/selectors';
import { getAddedLineItemCount } from 'features/proposal/selectors/lineItem';
import { getOpenCard } from 'features/proposal/selectors/views';
import { Currency } from 'features/proposal/supported-currencies';
import { OpenCard, SkuTitleMap } from 'features/proposal/types';
import {
  getAgreementsErrorTerms,
  getGqlTermIdError,
  getInvalidTermsErrorMessage,
} from 'features/proposal/utils';
import { useClearSelectionOnUnmount } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { LineItem, Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles/DialogueProvider';
import { oc } from 'ts-optchain';

import { useLazyQuery } from '@apollo/react-hooks';

import { listStyles } from '../List.styles';
import { TermListLineItem, TermListRow } from './';
import { getAllLineItemIdsFromSelectedList } from './utils';

export interface TermListProps {
  addSkuTitle: (
    qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
    productId: string
  ) => ReturnType<typeof addSkuTitle>;
  currency: Currency;
  terms: Record<string, Product>;
  termListLineItems?: TermListLineItem[];
  lastSelectedId: string;
  lineItems?: LineItem[];
  proposal?: Proposal;
  isProposalReadOnly?: boolean;
  selectedIds: string[];
  skuTitleMap: SkuTitleMap;
  openCard?: OpenCard;
  ecifEnabled: boolean;
  isDragEvent?: boolean;
  groupedLineItems?: LineItem[];
  productListLineItems?: ProductListLineItem[];
  isPartnerProposal?: boolean;
  isLineItemsLoading?: boolean;
  addedLineItemCount?: number;
  getProductName: (productId: string) => string;
}
const dispatchProps = {
  onSelection: toggleItemSelection,
  onClearSelection: clearItemSelection,
  onDelete: deleteProposalLineItemsAsync.request,
  onDeleteWithGrouping: removeLineItemGroupingAsync.request,
};
type Props = TermListProps & WithStyles<typeof listStyles> & typeof dispatchProps;

export const TermListUnstyled: React.FC<Props> = props => {
  const {
    termListLineItems,
    proposal,
    onClearSelection,
    groupedLineItems,
    productListLineItems,
    ecifEnabled,
    selectedIds,
    isProposalReadOnly,
    isLineItemsLoading,
    addedLineItemCount,
    classes,
    getProductName,
  } = props;
  useHelpContent(HelpContent.QuoteEditorTerms);

  useClearSelectionOnUnmount(onClearSelection);
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);
  const proposalId = oc(proposal).id('');
  const [getAgreementPreview, { loading }] = useLazyQuery(GetModernAgreementPreview, {
    onCompleted: data => {
      loggerService.log({ name: `Agreement preview generated for quoteId ${proposalId}` });
      openAgreementPreview({ id: 'agreement-preview-dialog', isError: false }, dialogContext, data);
    },
    onError: error => {
      let customError;
      const termsErrorResult = getGqlTermIdError(error);
      if (proposal && termsErrorResult.code && termsErrorResult.message) {
        const invalidTermIds = getAgreementsErrorTerms(
          termsErrorResult.code,
          termsErrorResult.message,
          proposal.lineItems
        );
        const invalidTermNames = invalidTermIds.map(invalidTermId => getProductName(invalidTermId));
        customError = getInvalidTermsErrorMessage(
          t('quote::Sorry, the "View Agreement" action failed.'),
          invalidTermNames,
          t
        );
        loggerService.error({
          error: new Error(
            `Failed to generate agreement for quoteId ${proposalId}. Invalid termId for 1 or more of the following: ${invalidTermNames}`
          ),
        });
      } else {
        loggerService.error({
          error: new Error(`Unable to generate the agreement for quote ${proposalId}`),
        });
      }

      openAgreementPreview(
        { id: 'agreement-preview-error-dialog', isError: true, customMessage: customError },
        dialogContext
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  const onDeleteLineItems = () => {
    if (proposal && selectedIds.length && !isProposalReadOnly) {
      const groupingDeleteRequest = getGroupingDeleteRequest(proposal, selectedIds[0]);
      if (groupingDeleteRequest) {
        props.onDeleteWithGrouping(groupingDeleteRequest);
      } else if (selectedIds.length) {
        let idsToDelete = selectedIds;
        if (termListLineItems && ecifEnabled) {
          idsToDelete = getAllLineItemIdsFromSelectedList(termListLineItems, selectedIds);
        }
        props.onDelete({
          etag: proposal.etag,
          lineItemIds: idsToDelete,
          proposalId: proposal.id,
        });
      }
      props.onClearSelection();
    }
  };

  const actionBarButtons: BarButtonProps[] = [
    {
      text: t('quote::Delete'),
      ariaLabel: t('quote::Delete'),
      iconName: 'DeleteRows',
      id: 'deleteButton',
      disabled: !selectedIds.length || isProposalReadOnly,
      dataAutomationId: 'termsListDeleteButton',
      onClick: onDeleteLineItems,
    },
    {
      text: t('quote::View Agreement'),
      spinnerLabelSize: 'medium',
      spinnerLabelPadding: 6,
      ariaLabel: t('quote::View Agreement'),
      iconName: 'OpenInNewWindow',
      id: 'viewButton',
      dataAutomationId: 'termsListViewAgreementButton',
      isLoading: loading,
      onClick: () => {
        if (proposal) {
          getAgreementPreview({
            variables: {
              quoteId: proposal.id,
            },
          });
        }
      },
    },
  ];

  const columns: ProposalListColumnRenderProps<TermListLineItem>[] = [
    {
      id: 'name',
      columnLabel: t('quote::Name'),
      width: 318,
      onRender: (item, columnProps) => {
        return titleFormatter(columnProps.width, item.name);
      },
    },
    {
      id: 'start',
      columnLabel: t('quote::Start'),
      width: 135,
      onRender: item => {
        return textFormatter(item.start);
      },
    },
    {
      id: 'end',
      columnLabel: t('quote::End'),
      width: 135,
      onRender: item => {
        return textFormatter(item.end);
      },
    },
    {
      id: 'type',
      columnLabel: t('quote::Type'),
      width: 90,
      onRender: item => {
        return textFormatter(item.type);
      },
    },
  ];

  const rows =
    termListLineItems && termListLineItems.length
      ? termListLineItems.map(term => {
          const groupGuid = oc(term).groups[0]();
          let groupedItem;
          if (groupedLineItems && groupGuid) {
            const groupedwith = getGroupedLineItems(groupedLineItems, groupGuid, term.id);
            groupedItem = oc(groupedwith)[0]();
          }
          return (
            <TermListRow
              addSkuTitle={props.addSkuTitle}
              columns={columns}
              currency={props.currency}
              ecifEnabled={props.ecifEnabled}
              errorText={term.loadFailure ? t('quote::Term failed to load') : term.errors[0]}
              groupedLineItem={groupedItem}
              isInErrorState={term.loadFailure || !!term.errors.length}
              isInWarnState={term.needsConfiguration}
              isSelected={selectedIds.includes(term.id)}
              isTermsList={true}
              key={term.id}
              lastSelectedId={props.lastSelectedId}
              lineItem={
                props.lineItems && props.lineItems.find(lineItem => lineItem.id === term.id)
              }
              openCard={props.openCard}
              productListLineItems={productListLineItems}
              proposal={props.proposal}
              selectedIds={selectedIds}
              termListLineItem={term}
              terms={props.terms}
              onCleared={props.onClearSelection}
              onDeleted={onDeleteLineItems}
              onSelected={props.onSelection}
            />
          );
        })
      : null;

  const displayShimmeredRows = () => {
    const shimmeredRow = (
      <div className={`${classes.lineItem} ${classes.lineItemContainer} ${classes.loading}`}>
        <ShimmerForBackgroundStandout width="30%" />
        <ShimmerForBackgroundStandout width="20%" />
      </div>
    );
    if (addedLineItemCount) {
      const shimmeredRows = [];
      for (let i = 0; i < addedLineItemCount; i++) {
        shimmeredRows.push(shimmeredRow);
      }
      return shimmeredRows;
    }
    return shimmeredRow;
  };

  return (
    <ProposalList
      actionBarButtons={actionBarButtons}
      columns={columns}
      isDragEvent={props.isDragEvent}
      isEmptyList={!(termListLineItems && !!termListLineItems.length)}
      isLineItemsLoading={isLineItemsLoading}
      isLoading={false}
      isPartnerProposal={props.isPartnerProposal}
      isProposalReadOnly={props.isProposalReadOnly}
      shimmeredRows={displayShimmeredRows()}
      watermarkType={ProposalListWatermarkType.TermWatermark}
      onClearSelection={props.onClearSelection}
    >
      {rows}
    </ProposalList>
  );
};

export const mapStateToProps = (state: RootState) => {
  return {
    agreementConfig: getAgreementConfig(state),
    terms: catalogSelectors.getProductFragmentsIndexed(state),
    termListLineItems: getTermListLineItems(state),
    lineItems: getLineItemsSwitchingDFD(state),
    openCard: getOpenCard(state),
    proposal: getActiveProposal(state),
    isProposalReadOnly: isProposalReadOnly(state),
    selectedIds: getSelectedIds(state),
    lastSelectedId: getLastSelectedId(state),
    skuTitleMap: getSkuTitles(state),
    currency: getBillingCurrency(state),
    groupedLineItems: getAllGroupedLineItems(state),
    productListLineItems: getProductListLineItems(state),
    ecifEnabled: getFlightIsEnabled(state, Flight.ECIF),
    isPartnerProposal: !!isPartnerProposal(state),
    isLineItemsLoading: lineItemLoading(state),
    addedLineItemCount: getAddedLineItemCount(state),
    getProductName: (productId: string) =>
      catalogSelectors.getProduct(state, productId).LocalizedProperties[0].ProductTitle,
  };
};

const TermListStyled = withStyles(listStyles)(TermListUnstyled) as React.FC<TermListProps>;

export const TermList = connect(mapStateToProps, {
  addSkuTitle: (qualifyingSkuAvailabilities: QualifyingSkuAvailability[], productId: string) => {
    const newSkuAvailProduct: ProductAndQualifyingSkusTitles = {
      QualifyingSkuAvailabilities: qualifyingSkuAvailabilities.slice(),
      ProductId: productId,
    };
    return addSkuTitle(newSkuAvailProduct);
  },
  ...dispatchProps,
})(TermListStyled);
