import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import { defaultMarket } from 'features/proposal/supported-markets';
import {
  AgreementType,
  Audience,
  Motion,
  MutationUpdateCloudTypeArgs,
  NationalCloud,
  QueryGetQuoteArgs,
  Quote,
  QuoteStatus,
  TransactionModel,
  UserGroup,
} from 'generated/graphql';
import React from 'react';
import { oc } from 'ts-optchain';
import { DialogContext, DialogProps } from 'styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ChangeFulfillmentCloudDialog } from 'features-apollo/quote/components/Dialogs/ChangeFulfillmentCloudDialog';
import { General, GeneralProps } from './General';
import { GET_QUOTE_GENERAL_INFO, UPDATE_CLOUD_FOR_GENERAL } from './queries';

interface GeneralContainerProps {
  quoteId: string;
  isUSNatEnabled?: boolean;
}

/**
 * Gets data needed for General Property Sheet
 *
 * @param props required information to get data
 * @returns  General component
 */
export const GeneralContainer: React.FC<GeneralContainerProps> = props => {
  const context = React.useContext(DialogContext);

  const { loading, data } = useQuery<QueryGetQuoteData, QueryGetQuoteArgs>(GET_QUOTE_GENERAL_INFO, {
    variables: { id: props.quoteId },
    skip: !props.quoteId.trim(),
  });

  const [updateCloudGQL] = useMutation<{ updateCloudType: Quote }, MutationUpdateCloudTypeArgs>(
    UPDATE_CLOUD_FOR_GENERAL
  );

  const generalProps: GeneralProps = {
    quoteId: props.quoteId,
    etag: '',
    name: '',
    market: defaultMarket,
    billingCurrency: DEFAULT_CURRENCY,
    language: undefined,
    expirationDate: new Date(),
    isQuoteLegacy: false,
    isQuoteReadOnly: false,
    msContact: undefined,
    crmId: undefined,
    hasLineItems: false,
    loading,
    transactionModel: TransactionModel.ToCustomer,
    quoteClouds: [],
    quoteChannel: Motion.Direct,
    quoteSegment: Audience.Commercial,
    status: QuoteStatus.Unknown,
    assignedTo: UserGroup.Field,
    isUSNatEnabled: props.isUSNatEnabled,
    onUpdateCloud: () => {},
  };

  if (data && data.getQuote) {
    const quote: Quote = data.getQuote;
    const quoteMutationInput = { id: quote.id, etag: quote.etag };

    generalProps.etag = quote.etag;
    generalProps.name = quote.name;
    generalProps.market = quote.market;
    generalProps.billingCurrency = quote.billingCurrency;
    generalProps.language = quote.languages;
    generalProps.expirationDate = new Date(quote.expirationDate);
    generalProps.msContact = quote.msContact;
    generalProps.isQuoteReadOnly = quote.readOnly;
    generalProps.crmId = oc(quote).crmLead.id();
    generalProps.transactionModel = quote.transactionModel;
    generalProps.quoteClouds = quote.clouds;
    generalProps.isQuoteLegacy = quote.agreementType === AgreementType.Legacy;
    generalProps.quoteSegment = quote.audience;
    generalProps.quoteChannel =
      quote.transactionModel === TransactionModel.ToPartnerCustomerAsset
        ? Motion.Partner
        : Motion.Direct;
    generalProps.hasLineItems = !!quote.lineItems.length;
    generalProps.status = quote.status;
    generalProps.assignedTo = quote.assignedTo;

    generalProps.onUpdateCloud = (cloudType: NationalCloud) => {
      const deleteLineItems = quote.lineItems.some(
        lineItem => lineItem.catalogContext.nationalCloud !== cloudType
      );
      const closeDialog = () => {
        context.closeDialog();
      };
      const updateCloud = () => {
        const optimisticResponse = {
          __typename: 'Mutation',
          updateCloudType: { ...quote, clouds: [cloudType] },
        };
        updateCloudGQL({
          variables: { input: quoteMutationInput, cloudType },
          optimisticResponse,
        });
        closeDialog();
      };
      if (deleteLineItems) {
        const dialogProps: DialogProps = {
          providedDialog: (
            <ChangeFulfillmentCloudDialog closeDialog={closeDialog} updateCloud={updateCloud} />
          ),
        };
        context.openDialog(dialogProps);
      } else {
        updateCloud();
      }
    };
  }

  return <General {...generalProps} />;
};
