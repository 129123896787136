import gql from 'graphql-tag';

export const UserObjectIdFragment = gql`
  fragment UserObjectId on User {
    __typename
    mail
  }
`;

export const UserPreferencesObjectIdFragment = gql`
  fragment UserPreferencesObjectId on UserPreferences {
    __typename
    mail
  }
`;
