import { GetModernAgreement } from 'features/customer/queries';
import { call } from 'redux-saga/effects';
import { apolloClient } from 'services/apollo/apolloClient';
import loggerService from 'services/logger-service';
import store from 'store';

async function getAgreementParticipantsGQL(
  agreementId: string,
  accountId: string,
  organizationId: string
) {
  // only need participants from CAPT agreement to determine the parent billing account
  const { data, errors } = await apolloClient(store).query({
    variables: {
      agreementId,
      accountId,
      organizationId,
    },
    query: GetModernAgreement,
  });
  if (errors) {
    return errors;
  }
  return data.getModernAgreement.participants;
}

export function* getLeadOrgIdFromAgreement(
  agreementId: string,
  accountId: string,
  organizationId: string
) {
  try {
    const participants = yield call(
      getAgreementParticipantsGQL,
      agreementId,
      accountId,
      organizationId
    );

    // parent billing account for an affiliate is listed under participants with role === LEAD
    const leadOrg = participants.filter(
      (participant: { companyId: string; role: string }) =>
        participant.role.toUpperCase() === 'LEAD'
    );

    return leadOrg[0].companyId;
  } catch (err) {
    loggerService.error(err);
    return;
  }
}
