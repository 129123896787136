import {
  LinkButton,
  LinkEmail,
  LinkExternal,
  TextBody,
  TextBodyLarge,
  TextTitle,
} from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { ThemeProps } from 'styles';

import { panelStyles } from './HelpPanel.styles';

export enum SupportLinkType {
  Email = 'email',
  Internal = 'internal',
  External = 'external',
  Teams = 'teams',
}

const styles = (theme: ThemeProps) => ({
  ...panelStyles(theme),
  bodyContent: {
    marginTop: 20,
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  footerContent: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  resourceGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    fontSize: theme.fonts.fontSizes.medium,
  },
  emailSupport: {
    fontSize: theme.fonts.fontSizes.medium,
    marginTop: 12,
  },
  support: {
    fontSize: theme.fonts.fontSizes.medium,
    marginTop: 12,
    '&:hover': { textDecoration: 'none' },
  },
  link: {
    fontSize: theme.fonts.fontSizes.medium,
  },
});

type Props = {
  onSupportClick: () => void;
} & WithStyles<typeof styles>;

const SupportResourcesUnstyled: React.FC<Props> = props => {
  const { classes, onSupportClick } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.panelContentInner}>
      <TextTitle>{t('Support Resources')}</TextTitle>
      <div className={classes.bodyContent}>
        <TextBodyLarge addClass={classes.title}>{t('Ask a question')}</TextBodyLarge>

        <div className={classes.resourceGroup}>
          <LinkEmail
            addClass={classes.link}
            displayText={t('Simple Deal Construction (US)')}
            email="MCAassist@microsoft.com"
            iconName="Mail"
            onClick={() =>
              loggerService.log(
                { name: 'Support link clicked' },
                {
                  displayText: t('Simple Deal Construction (US)'),
                  link: 'MCAassist@microsoft.com',
                  linkType: SupportLinkType.Email,
                }
              )
            }
          />
          <TextBody>
            {t(
              `Reach out to this alias for help getting started with creating deals in Quote Center.`
            )}
          </TextBody>
        </div>
        <div className={classes.resourceGroup}>
          <LinkButton
            addClass={classes.link}
            displayText={t('Commerce Experience - Field Led Team')}
            iconName="TeamsLogo"
            onClick={() => {
              loggerService.log(
                { name: 'Support link clicked' },
                {
                  displayText: t('Commerce Experience - Field Led Team'),
                  linkType: SupportLinkType.Teams,
                }
              );
              window.open(
                `https://teams.microsoft.com/l/team/19%3a21a82fef09924bdfb47635699c461178%40thread.skype/conversations?groupId=8fe59b54-99a4-4310-ac8a-7a12b2059769&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47`
              );
            }}
          />
          <TextBody>
            {t(
              `Join this teams channel to stay up to date on announcements, or ask a question to the field sales community.`
            )}
          </TextBody>
        </div>
      </div>
      <div className={classes.footerContent}>
        <TextBodyLarge addClass={classes.title}>{t(`It's broken`)}</TextBodyLarge>
        <LinkButton
          addClass={classes.support}
          displayText={t('Open a support ticket with CSS')}
          onClick={() => {
            loggerService.log(
              { name: 'Support link clicked' },
              {
                displayText: t('Open a support ticket with CSS'),
                linkType: SupportLinkType.Internal,
              }
            );
            onSupportClick();
          }}
        />
        <div className={classes.resourceGroup}>
          <LinkExternal
            addClass={classes.link}
            displayText={t('GetHelp Portal - Customer/Partner Escalations')}
            href="https://gethelpprod.powerappsportals.com"
            onClick={() =>
              loggerService.log(
                { name: 'Support link clicked' },
                {
                  displayText: t('GetHelp Portal - Customer/Partner Escalations'),
                  link: 'https://gethelpprod.powerappsportals.com',
                  linkType: SupportLinkType.External,
                }
              )
            }
          />
          <TextBody>
            {t(
              `The place for you to advocate on behalf of a customer/partner who is experiencing a service, product, or unresolved support issue with an existing service request case number.`
            )}
          </TextBody>
        </div>
      </div>
    </div>
  );
};

export const SupportResources = withStyles(styles)(SupportResourcesUnstyled);
