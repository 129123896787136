import { ErrorMessage, LinkExternal, TextBodySmall } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export const viralTenantMessageStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    scrollable: {
      maxHeight: (props: ViralTenantMessageProps) => props.maxHeight || 300,
      overflow: 'auto',
    },
    spacing: {
      marginTop: 12,
    },
    header: {
      color: palette.dangerText,
    },
    subHeader: {
      color: palette.textTertiary,
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    text: {
      color: palette.textTertiary,
    },
  };
};

export interface ViralTenantMessageProps {
  maxHeight?: string;
}

type Props = ViralTenantMessageProps & WithStyles<typeof viralTenantMessageStyles>;

const ViralTenantMessageUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = props;
  return (
    <ErrorMessage mainMessage={t('quote::Unmanaged tenant')}>
      <div className={classes.scrollable}>
        <TextBodySmall addClass={classes.text}>
          {t(
            'quote::Unmanaged tenants occur when a person uses their company email address to sign up for a product like PowerBI. This results in a tenant that does not have an administrator'
          )}
        </TextBodySmall>
        <div className={classes.spacing}>
          <TextBodySmall addClass={classes.subHeader}>
            {t('quote::Domain take-over needed')}
          </TextBodySmall>
        </div>
        <TextBodySmall addClass={classes.text}>
          {t(
            'quote::Instruct the customer to do a domain take over following the directions in the below article. When they are done, come back and create the billing account using the same domain and tenant.'
          )}
        </TextBodySmall>
        <div>
          <LinkExternal
            displayText={t(
              'quote::Take over an unmanaged directory as administrator in Azure Active Directory'
            )}
            href="https://docs.microsoft.com/en-us/azure/active-directory/users-groups-roles/domains-admin-takeover"
          />
        </div>
      </div>
    </ErrorMessage>
  );
};

export const ViralTenantMessage = withStyles(viralTenantMessageStyles)(ViralTenantMessageUnstyled);
