import { SectionSeparator, SelectionSection, TextBody } from 'components';
import { Processing } from 'features/app/selectors';
import { SelectableTile } from 'features/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { OrganizationSummary } from 'services/customer/types';
import { sharedStyles, ThemeProps } from 'styles';

import { getSelectableOrganizations } from '../shared';

interface AccountOrganizationsProps {
  accountName?: string;
  disableCreateOrganizationButton?: boolean;
  processingAddresses: Processing;
  organizations: OrganizationSummary[];
  createOrganization: () => void;
  useExistingOrganization: (organizationId: string) => void;
}

const styles = (theme: ThemeProps) => ({
  button: {
    width: 196,
  },
  createButton: {
    marginTop: 32,
    marginBottom: 32,
  },
  description: {
    color: theme.palette.textTertiary,
  },
  noAccount: {
    display: 'flex',
    marginLeft: 'auto',
  },
  textOverflow: sharedStyles(theme).textOverflow,
  selectButton: {
    marginTop: 23,
  },
  selectOrganizationSection: {
    alignItems: 'start',
    marginTop: 24,
  },
});

type Props = AccountOrganizationsProps & WithStyles<typeof styles>;

const AccountOrganizationsBodyUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { classes } = props;
  const [selectedOrganizationId, setSelectedOrganizationId] = React.useState<string | undefined>();

  const description = (
    <>
      <TextBody addClass={classes.description}>
        {t(
          `quote::The tenant associated with this domain is in use by the following modern account. If one of the account's billing accounts is your customer, select it and use it. If not, continue creating the new billing account which will be added to this account.`
        )}
      </TextBody>
    </>
  );

  const createOrganization = (
    <SelectionSection
      addButtonClass={`${classes.button} ${classes.createButton} ${!props.accountName &&
        classes.noAccount}`}
      buttonText={t('quote::Create new billing account')}
      dataAutomationId="createNewBillingAccount"
      disabled={props.disableCreateOrganizationButton}
      title={props.accountName && t('quote::Account')}
      onClick={props.createOrganization}
    >
      {props.accountName && (
        <TextBody
          addClass={classes.textOverflow}
          style={{ maxWidth: 340 }}
          title={props.accountName}
        >
          {props.accountName}
        </TextBody>
      )}
    </SelectionSection>
  );

  const selectableOrganizations = props.organizations
    ? getSelectableOrganizations(props.organizations, props.processingAddresses)
    : false;

  const onSelectOrganization = (organizationId: string) =>
    setSelectedOrganizationId(organizationId);

  const onUseExistingOrganization = () =>
    selectedOrganizationId && props.useExistingOrganization(selectedOrganizationId);

  const selectOrganization = (
    <SelectionSection
      addButtonClass={`${classes.button} ${classes.selectButton}`}
      addClass={classes.selectOrganizationSection}
      buttonText={t('quote::Use existing billing account')}
      dataAutomationId="useExistingBillingAccount"
      disabled={!selectedOrganizationId}
      title={t('quote::Existing billing account')}
      onClick={onUseExistingOrganization}
    >
      {selectableOrganizations && (
        <SelectableTile
          dataAutomationId="selectOrganizationTile"
          loading={props.processingAddresses.loading}
          organizationsInfo={selectableOrganizations}
          onSelect={onSelectOrganization}
        />
      )}
    </SelectionSection>
  );

  return (
    <>
      {description}
      {createOrganization}
      <SectionSeparator />
      {selectOrganization}
    </>
  );
};

export const AccountOrganizationsBody = withStyles(styles)(
  AccountOrganizationsBodyUnstyled
) as React.FC<AccountOrganizationsProps>;
