import { Dialog, PrimaryButton } from 'components';
import { SearchResultProduct } from 'features/catalog';
import { Dimensions, Fail, Processing } from 'features/components/dialogs';
import { addProductToFavorites } from 'features/user/actions';
import { processingUserPreferencesUpdate } from 'features/user/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';

import { GroupNameField } from './GroupNameField';

const dimensions: Dimensions = {
  height: 220,
  width: 424,
};

const dispatchProps = {
  addProductToFavorites: addProductToFavorites.request,
};

const mapStateToProps = (state: RootState) => {
  const { error, loading } = processingUserPreferencesUpdate(state);
  const userProductGroups = state.user.preferences.productGroups;
  const isProductInProductGroup = (groupName: string, productId: string) =>
    !!(
      userProductGroups &&
      userProductGroups[groupName] &&
      userProductGroups[groupName].products.some(item => item.productId === productId)
    );

  return {
    error,
    isProductInProductGroup,
    loading,
  };
};

export interface CreateProductGroupDialogProps {
  elementIdToFocusOnDismiss?: string;
  moveFrom?: string;
  product: SearchResultProduct;
}

type Props = CreateProductGroupDialogProps &
  typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

export const CreateProductGroupDialogUnconnected: React.FC<Props> = props => {
  const { moveFrom, product } = props;
  const { t } = useTranslation();
  const [creatingGroup, setCreatingGroup] = React.useState<boolean>(false);
  const [groupName, setGroupName] = React.useState<string | undefined>();
  const context = React.useContext(DialogContext);

  const createGroup = () => {
    if (groupName) {
      props.addProductToFavorites({ product, groupName, moveFrom });
      setCreatingGroup(true);
    }
  };

  const onClose = () => {
    context.closeDialog();
    if (props.elementIdToFocusOnDismiss) {
      const element = document.getElementById(props.elementIdToFocusOnDismiss);
      element && element.focus();
    }
  };

  // #region adding product states
  if (groupName && creatingGroup) {
    if (props.loading) {
      return <Processing {...dimensions} message1={t('Creating group')} />;
    } else if (props.error) {
      return <Fail {...dimensions} closeDialog={onClose} message={t('Unable to create group')} />;
    } else if (props.isProductInProductGroup(groupName, product.productId)) {
      onClose();
      return null;
    }
  }
  // #endregion

  return (
    <Dialog
      {...dimensions}
      closeDialog={onClose}
      footerButtons={
        <PrimaryButton
          dataAutomationId="createGroupingButton"
          disabled={!groupName}
          text={t('Create grouping')}
          onClick={createGroup}
        />
      }
      title={t('Name product grouping')}
    >
      <GroupNameField
        onChange={(userInput?: string) => {
          setGroupName(userInput);
        }}
        onEnterKeyDown={createGroup}
      />
    </Dialog>
  );
};

export const CreateProductGroupDialog = connect(
  mapStateToProps,
  dispatchProps
)(CreateProductGroupDialogUnconnected);

export const openCreateProductGroupDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: CreateProductGroupDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <CreateProductGroupDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
