import { TileAtom } from 'components/atoms';
import { TextBodySmall, TextTitleSecondary } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { smallTileStyles } from './Tiles.styles';

export interface SmallTileProps {
  button?: React.ReactNode;
  id: string;
  imageDescription: string;
  imageUrl: string;
  selected: boolean;
  tags?: string;
  title: string;
  onClick?: (ev?: React.SyntheticEvent<HTMLElement>) => void;
}

type Props = SmallTileProps & WithStyles<typeof smallTileStyles>;

export const SmallTileUnstyled: React.FC<Props> = props => {
  const image = (
    <img
      alt={props.imageDescription}
      className={props.classes.image}
      height={50}
      src={props.imageUrl}
      width={50}
    />
  );

  return (
    <TileAtom
      addClass={props.selected ? props.classes.documentCardSelected : props.classes.documentCard}
      dataAutomationId={`smallTile${props.id}`}
      id={props.id}
      onClick={props.onClick}
    >
      {props.button ? (
        <div className={props.classes.header}>
          {image}
          {props.button}
        </div>
      ) : (
        image
      )}
      <div title={props.title}>
        <TextTitleSecondary addClass={props.classes.title}>{props.title}</TextTitleSecondary>
      </div>
      {props.tags && (
        <div title={props.tags}>
          <TextBodySmall addClass={props.classes.tags}>{props.tags}</TextBodySmall>
        </div>
      )}
    </TileAtom>
  );
};

export const SmallTile = withStyles(smallTileStyles)(SmallTileUnstyled);
