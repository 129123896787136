import { ThemeProps } from 'styles';

export const overviewStyles = (theme: ThemeProps) => {
  return {
    error: {
      marginBottom: 24,
      marginTop: 'auto',
    },
    organizationDetailsHeader: {
      paddingTop: 24,
    },
    organizationsColumn1: {
      width: 236,
    },
    organizationLabelBottomPadding: {
      paddingBottom: 24,
    },
    separator: {
      width: 1,
      backgroundColor: theme.palette.textDisabled,
      margin: '4px 40px 0',
    },
    topDialogContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    organizationDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 16,
      '& > :not(:first-child)': {
        paddingTop: 16,
      },
    },
    organizationDetailsColumnsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    enrollmentTextColor: {
      color: theme.palette.textTertiary,
    },
    enrollmentTextFontWeight: {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
  };
};
