import { ComboBox, SearchHeader, TextBody } from 'components';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { CatalogPageRequest } from 'features/catalog';
import {
  clearSearchCatalogPageProducts,
  getNegotiatedTerms,
  searchCatalogPageProducts,
} from 'features/catalog/actions';
import { getComboBoxLanguages, Language } from 'features/proposal/supported-languages';
import {
  defaultMarket,
  getLanguages,
  getTranslatedMarketsAlphabetically,
  Market,
} from 'features/proposal/supported-markets';
import { IComboBox, IComboBoxOption, ISearchBox } from 'office-ui-fabric-react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { ProductFamily, ServiceFamily } from 'services/catalog/types';
import * as LocalStorage from 'services/local-storage-service';
import { RootState } from 'store/types';

import { CatalogSection } from './CatalogSection';
import { SearchSection } from './SearchSection';
import { catalogStyles } from './shared.styles';

const stringifyCatalogKey = (market: Market, languages: Language) => {
  return `market:${market}|languages:${languages}`;
};

const mapStateToProps = (state: RootState) => {
  return {
    market: LocalStorage.get<Market>(LocalStorage.localMarketKey) || defaultMarket,
  };
};

const dispatchProps = {
  searchCatalogProducts: searchCatalogPageProducts.request,
  getNegotiatedTerms: getNegotiatedTerms.request,
  onClearSearch: clearSearchCatalogPageProducts,
};

type Props = WithStyles<typeof catalogStyles> &
  typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

const CatalogUnstyled: React.FC<Props> = props => {
  const { searchCatalogProducts, getNegotiatedTerms } = props;
  const { t } = useTranslation();
  useHelpContent(HelpContent.Catalog);

  const [lastSearched, setLastSearched] = React.useState<string>('');
  const [currentValue, setCurrentValue] = React.useState<string>('');
  const [market, setMarket] = React.useState<Market>(props.market);
  const [language, setLanguage] = React.useState<Language>(getLanguages(market)[0]);
  const [searchFocus, setSearchFocus] = React.useState<boolean>(false);

  const maxMarketMenuHeight = 250;
  const languageComboBoxOptions = getComboBoxLanguages(getLanguages(market));

  React.useEffect(() => {
    searchCatalogProducts({
      productFamilyName: ProductFamily.Azure,
      serviceFamily: ServiceFamily.SaaS,
      market,
      language,
    });
    getNegotiatedTerms({
      top: 25,
      market,
      language,
    });
  }, [getNegotiatedTerms, searchCatalogProducts, market, language]);

  React.useEffect(() => {
    if (lastSearched) {
      searchCatalogProducts({
        productFamilyName: ProductFamily.Azure,
        query: lastSearched,
        serviceFamily: ServiceFamily.SaaS,
        market,
        language,
      });
    }
  }, [lastSearched, searchCatalogProducts, market, language]);

  const searchFocusRef = React.useRef<ISearchBox>(null);
  useHotkeys('ctrl+/', () => {
    searchFocusRef.current && searchFocusRef.current.focus();
  });

  const handleOnLoadMore = (request: CatalogPageRequest) => {
    searchCatalogProducts({ ...request, market, language });
  };

  const handleOnClearSearch = () => {
    setLastSearched('');
    setCurrentValue('');
    props.onClearSearch();
  };

  const handleOnBlur = () => {
    setSearchFocus(false);
  };

  return (
    <div className={props.classes.catalog}>
      <SearchHeader
        buttonText={t('home::Search')}
        searchAutoFocus={!window.location.href.includes('#sc')} // prevent autofocus with shortcut nav to avoid shortcut key being typed in
        searchComponentRef={searchFocusRef}
        searchDebounce={false}
        searchPlaceholder={t('home::Search by SaaS product name')}
        searchValue={currentValue}
        title={t('home::SaaS product search')}
        onBlur={handleOnBlur}
        onChange={(input: string) => {
          setCurrentValue(input);
          setSearchFocus(true);
        }}
        onClearSearch={handleOnClearSearch}
        onSearch={() => {
          setLastSearched(currentValue);
          setSearchFocus(false);
        }}
      />
      <div className={props.classes.filters}>
        <div className={props.classes.filterText}>
          <TextBody>{t('home::Show products available in the following:')}</TextBody>
        </div>
        <div className={props.classes.combobox}>
          <ComboBox
            allowFreeform={true}
            autoComplete="on"
            id="Market"
            label={t('home::Market')}
            maxHeight={maxMarketMenuHeight}
            options={getTranslatedMarketsAlphabetically(t)}
            selectedKey={market}
            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
              if (option) {
                setMarket(option.key as Market);
                setCurrentValue(lastSearched);
              }
            }}
          />
        </div>
        <div className={props.classes.combobox}>
          <ComboBox
            id="Language"
            label={t('home::Language')}
            options={languageComboBoxOptions}
            selectedKey={
              languageComboBoxOptions.find(option => option.key === language)
                ? language
                : (languageComboBoxOptions[0].key as Language)
            }
            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
              if (option) {
                setLanguage(option.key as Language);
                setCurrentValue(lastSearched);
              }
            }}
          />
        </div>
      </div>
      <div
        className={searchFocus ? props.classes.productSectionDimmed : props.classes.productSection}
      >
        {lastSearched ? (
          <SearchSection
            productFamily={ProductFamily.Azure}
            queryString={lastSearched}
            serviceFamily={ServiceFamily.SaaS}
            onClearSearch={handleOnClearSearch}
            onExpandSearch={handleOnLoadMore}
          />
        ) : (
          <div>
            <CatalogSection
              catalogKey={stringifyCatalogKey(market, language)}
              productFamily={ProductFamily.NegotiatedTerms}
              serviceFamily={ServiceFamily.SaaS}
              title={t('home::Negotiated Terms')}
              onLoadCatalogProducts={handleOnLoadMore}
            />
            <CatalogSection
              catalogKey={stringifyCatalogKey(market, language)}
              linkText={t('home::Take me to AppSource Solution Finder')}
              linkUrl="https://appsource.microsoft.com/en-us/marketplace/co-sell?solutionType=ip&page=1"
              productFamily={ProductFamily.Azure}
              serviceFamily={ServiceFamily.SaaS}
              title={t('home::Featured marketplace SaaS')}
              onLoadCatalogProducts={handleOnLoadMore}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const Catalog = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(catalogStyles)(CatalogUnstyled));
