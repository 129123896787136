import { ThemeProps } from 'styles';

export const approvalsListStyles = (theme: ThemeProps) => {
  const { approveText, dangerText } = theme.palette;

  return {
    statusColumn: {
      maxWidth: 25,
      paddingTop: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    statusChevronColumn: {
      maxWidth: 25,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    approvalListContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 250,
    },
    listContainerHwa: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingBottom: 10,
      paddingTop: 2,
      maxWidth: 300,
    },
    waitingTimeText: { paddingLeft: 12, color: theme.palette.dangerText },
    waitingTimeTextExtra: { paddingLeft: 22, color: theme.palette.dangerText },
    column: { width: '100%', display: 'flex', flexDirection: 'column' },
    row: {
      display: 'flex',
      paddingBottom: 2,
      paddingTop: 2,
    },
    accepted: { color: approveText },
    rejected: { color: dangerText },
    darkText: { color: theme.palette.text },
    lightText: { color: theme.palette.textTertiary },
    history: { color: theme.palette.warningText },
    actor: { fontWeight: theme.fonts.fontWeights.semiBold },
    chevronColumn: { display: 'flex', flexDirection: 'column', minWidth: 20 },
    chevron: {
      minWidth: 11,
      width: 11,
      height: 11,
      padding: 9,
      '& i': {
        fontSize: theme.fonts.fontSizes.small,
      },
    },
    name: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 100,
      display: 'inline-block',
    },
  };
};
