import cloneDeep from 'clone-deep';
import {
  getFlightIsEnabled,
  getMinCreditLineDivisor,
  usingAccountTestHeaders,
} from 'features/app/selectors';
import { loadProducts } from 'features/catalog/sagas/auxiliary';
import { getProductEntitiesIndexed } from 'features/catalog/selectors';
import { addTenantToAccountAsync } from 'features/customer/actions';
import {
  addTenantToAccount,
  loadAccount,
  loadEnrollment,
  loadModernFootprint,
  mapCRMDetailsOnProposal,
  mapModernFootprintToProposal,
} from 'features/customer/sagas/auxiliary';
import * as actions from 'features/proposal/actions';
import { getActiveProposal, getAllProductIds, getProposal } from 'features/proposal/selectors';
import {
  cloneProposal,
  createProposalUpdateEnrollmentNumberRequest,
} from 'features/proposal/utils';
import { loadUserRoles } from 'features/user/sagas/auxiliary';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { Account } from 'services/account/types';
import { Product } from 'services/catalog/types';
import { getParticipants } from 'services/ccf/utils';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { ProposalConfig } from 'services/proposal/config';
import { PriceAdjustmentType, Proposal } from 'services/proposal/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';
import { getType } from 'typesafe-actions';

import { isSupportOffer } from '../components/ConfigCard/ConfigCardBusinessLogic';
import { ModernFootprint } from '../types';
import { hasSoldToCustomer } from '../utils';
import {
  changeInvitedUserPatchCommand,
  getWithdrawActions,
  hydrateQuoteAll,
  multipleQuoteActions,
  patchQuoteHeader,
  updateQuote,
} from './auxiliary';

export function* createProposal() {
  const relevantAction = actions.createProposalAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const quoteConfig: ProposalConfig = yield select(
        (state: RootState) => state.app.appConfig.proposal
      );
      const quote: Proposal = yield call(api.proposal.createProposal, action.payload, quoteConfig);
      yield put(actions.createProposalAsync.success(quote));
    } catch (err) {
      yield put(
        actions.createProposalAsync.failure({
          message: t('error::Error creating quote.'),
          exception: err,
        })
      );
    }
  });
}

export function* deleteProposal() {
  const relevantAction = actions.deleteProposalAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const quoteConfig: ProposalConfig = yield select(
        (state: RootState) => state.app.appConfig.proposal
      );
      const quoteId: string = yield call(api.proposal.deleteProposal, action.payload, quoteConfig);
      yield put(actions.deleteProposalAsync.success({ id: quoteId }));
      const orderedFragments: string[] = yield select(
        (state: RootState) => state.proposal.proposals.fragments.ordered
      );
      const fragmentToSelect = orderedFragments.find(fragment => fragment !== quoteId);
      if (fragmentToSelect) {
        yield put(actions.loadAndHydrateLiteQuote(fragmentToSelect));
      }
    } catch (err) {
      yield put(
        actions.deleteProposalAsync.failure({
          message: t('error::Error deleting quote.'),
          exception: err,
        })
      );
    }
  });
}
//TODO: Remove when all patches are converted to saga (and nobody calls this request)
export function* patchProposalHeader() {
  const relevantAction = actions.patchProposalHeaderAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const originalQuote: Proposal = yield select((state: RootState) =>
        getProposal(state, action.payload.proposalId)
      );
      const quote: Proposal = yield call(patchQuoteHeader, action.payload.commands, originalQuote);
      yield put(actions.patchProposalHeaderAsync.success(quote));
      yield call(hydrateQuoteAll, quote);
    } catch (err) {
      yield put(
        actions.patchProposalHeaderAsync.failure({
          message: t('error::Error deleting quote.'),
          exception: err,
        })
      );
    }
  });
}

export function* updateProposal() {
  const relevantAction = actions.updateProposalAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const quote: Proposal = yield call(updateQuote, action.payload);
      yield put(actions.updateProposalAsync.success(quote));
      yield call(hydrateQuoteAll, quote);
    } catch (err) {
      yield put(
        actions.updateProposalAsync.failure({
          message: t('error::Error updating quote.'),
          exception: err,
        })
      );
    }
  });
}

export function* loadEnrollmentAndUpdateProposal() {
  const relevantAction = actions.loadEnrollmentAndUpdateProposal;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      let enrollment = undefined;
      if (action.payload) {
        enrollment = yield call(loadEnrollment, action.payload);
      }
      const proposal = yield select(getActiveProposal);
      const proposalToUpdate = cloneProposal(proposal);
      const modernOfficeEnabled: boolean = yield select((state: RootState) =>
        getFlightIsEnabled(state, Flight.modernOffice)
      );
      yield call(
        updateQuote,
        createProposalUpdateEnrollmentNumberRequest(
          action.payload,
          proposalToUpdate,
          enrollment,
          modernOfficeEnabled
        )
      );
    } catch (err) {
      loggerService.error(err);
    }
  });
}

export function* updateInvitedUser() {
  const relevantAction = actions.updateInvitedUser;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    let hasTestHeaderInProd;
    try {
      const { user, quoteId } = action.payload;
      const testHeadersAllowed: boolean = yield select((state: RootState) =>
        usingAccountTestHeaders(state)
      );

      const quote: Proposal = yield select((state: RootState) => getProposal(state, quoteId));
      if (user.TenantId) {
        const accountId = oc(quote).header.soldToCustomerLegalEntity.accountId();
        if (!accountId) {
          throw new Error("There's no account on the quote, but there's one on the user");
        }
        const account: Account = yield call(loadAccount, accountId);
        if (!account) {
          throw new Error('The quote account can not be loaded.');
        }
        hasTestHeaderInProd = !testHeadersAllowed && account && account.testHeader;
        if (hasTestHeaderInProd) {
          loggerService.log({
            name: 'TestHeaderInProd',
            properties: {
              accountId,
              tennant: user.TenantId,
              quote: oc(quote).id,
              action: relevantAction.name,
            },
          });
          throw new Error('There was a problem with the account footprint.');
        }

        if (!hasTestHeaderInProd && !account.externalIds.includes(user.TenantId)) {
          yield call(addTenantToAccount, accountId, user.TenantId);
        }
      }
      const invitedUserPatch = changeInvitedUserPatchCommand(user);
      yield call(patchQuoteHeader, [invitedUserPatch], quote);
    } catch (err) {
      if (hasTestHeaderInProd) {
        yield put(
          addTenantToAccountAsync.failure({
            message: t(
              'This account is associated to a test account please contact support for assistance or try another account.'
            ),
            exception: err,
          })
        );
      }
      loggerService.error(err);
    }
  });
}

export function* updateMsContact() {
  const relevantAction = actions.updateMsContact;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const { msContact, quoteId } = action.payload;
      const quote: Proposal = yield select((state: RootState) => getProposal(state, quoteId));
      const newQuote = {
        ...quote,
        header: { ...quote.header, msContact },
      };
      const roles: string[] = yield call(loadUserRoles, msContact);
      const participants = getParticipants(roles);
      newQuote.lineItems = newQuote.lineItems.map(lineItem => ({
        ...lineItem,
        participants: participants.length ? participants : undefined,
      }));
      yield call(updateQuote, { proposal: newQuote, proposalId: newQuote.id, etag: newQuote.etag });
    } catch (err) {
      loggerService.error(err);
    }
  });
}

export function* upgradeQuote() {
  const relevantAction = actions.upgradeQuoteAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      let quote: Proposal = yield select((state: RootState) => getProposal(state, action.payload));
      const withdrawActions = getWithdrawActions(quote);
      const withdrawCall = call(multipleQuoteActions, withdrawActions, quote);
      const productsCall = call(loadProducts, getAllProductIds(quote));
      const results: { quote: Proposal; products: string[] } = yield all({
        quote: withdrawCall,
        products: productsCall,
      });
      quote = results.quote;
      const clonedQuote = cloneDeep(quote);
      delete clonedQuote.header.billTo;
      delete clonedQuote.header.invitedUser;
      const crmId = clonedQuote.header.opportunityId;
      if (crmId) {
        delete clonedQuote.header.opportunityId;
        yield call(mapCRMDetailsOnProposal, clonedQuote, crmId);
      }

      //if quote has billing account / organization on it then update modern footprint
      if (hasSoldToCustomer(clonedQuote)) {
        /* eslint-disable @typescript-eslint/no-non-null-assertion*/
        // verified for existence in hasSoldToCustomer
        const accountId = clonedQuote.header.soldToCustomerLegalEntity!.accountId!;
        const organizationId = clonedQuote.header.soldToCustomerLegalEntity!.organizationId!;

        delete clonedQuote.header.soldToCustomerLegalEntity;
        delete clonedQuote.header.endCustomerLegalEntity;

        const footprint: ModernFootprint | undefined = yield call(
          loadModernFootprint,
          accountId,
          organizationId
        );
        if (!footprint) {
          throw new Error(
            `updateProposalWithCustomer: cannot proceed when footprint is not available. accountId: ${accountId} organizationId:${organizationId}`
          );
        }
        /* eslint-enable @typescript-eslint/no-non-null-assertion*/
        mapModernFootprintToProposal(clonedQuote, footprint);
      }

      //update monthly credit
      if (clonedQuote.header.estimatedDealValue) {
        const minCreditLineDivisor: number = yield select(getMinCreditLineDivisor);
        const annualDealEstimate = Number(clonedQuote.header.estimatedDealValue);
        const minimumCreditLine = Math.ceil(annualDealEstimate / minCreditLineDivisor);
        clonedQuote.header.soldToCustomerLegalEntity = {
          ...clonedQuote.header.soldToCustomerLegalEntity,
          monthlyCreditLimit: minimumCreditLine,
        };
      }

      const products: Record<string, Product> = yield select(getProductEntitiesIndexed);
      clonedQuote.lineItems.forEach(lineItem => {
        let product = products[lineItem.productIdentifier.productId];
        lineItem.productIdentifier.productFamily = product.ProductFamily;
        lineItem.productIdentifier.productType = product.ProductType;
        const pricingInstruction = lineItem.pricingInstruction;
        if (
          pricingInstruction &&
          pricingInstruction.productIdentifier &&
          pricingInstruction.productIdentifier.productId
        ) {
          product = products[pricingInstruction.productIdentifier.productId];
          pricingInstruction.productIdentifier.productFamily = product.ProductFamily;
          pricingInstruction.priceAdjustmentType = PriceAdjustmentType.new;

          //conditions cannot be an empty array
          if (
            pricingInstruction.conditions &&
            (pricingInstruction.conditions.length === 0 || isSupportOffer(product))
          ) {
            delete pricingInstruction.conditions;
          }
        }
        const extended = lineItem.extendedProperties;
        if (extended) {
          delete extended.userPreferences;
        }
      });

      quote = yield call(updateQuote, {
        proposal: clonedQuote,
        proposalId: clonedQuote.id,
        etag: clonedQuote.etag,
      });
      if (quote) {
        yield put(actions.upgradeQuoteAsync.success(quote));
      } else {
        yield put(
          actions.upgradeQuoteAsync.failure({
            message: t('error::Error upgrading quote to latest version.'),
            exception: null,
          })
        );
      }
    } catch (err) {
      yield put(
        actions.upgradeQuoteAsync.failure({
          message: t('error::Error upgrading quote to latest version.'),
          exception: err,
        })
      );
    }
  });
}
