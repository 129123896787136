import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  TextBody,
  TextboxStandard,
  TextTitle,
} from 'components';
import { useDebounce } from 'components/utilities/debounce';
import * as appSelectors from 'features/app/selectors';
import { formatCurrency } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, ThemeProps } from 'styles';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';

import { sharedStyles } from '../shared.styles';
import { AnnualDealEstimatePayload, CreditConstant, RequestCreditLineState } from './';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  inputContainer: {
    paddingTop: 16,
    width: 350,
  },
  information: {
    color: theme.palette.textTertiary,
  },
});

const localActions = {
  onAnnualDealEstimateChange: createStandardAction('DEALESTIMATE_CHANGE')<
    AnnualDealEstimatePayload
  >(),
  onMinimumCreditLineChange: createStandardAction('MINIMUMCREDITLINE_CHANGE')<string>(),
  updateAnnualDealEstimateError: createStandardAction('ANNUALDEALESTIMATE_ERROR_UPDATE')<string>(),
  updateMinimumCreditLineError: createStandardAction('MINIMUMCREDITLINE_ERROR_UPDATE')<string>(),
};

const getInitialState = (params: { annualDealEstimate: string; minimumCreditLine: string }) => {
  return {
    annualDealEstimate: params.annualDealEstimate,
    minimumCreditLine: params.minimumCreditLine,
    annualDealEstimateError: '',
    minimumCreditLineError: '',
  };
};

const reducer = (
  state: RequestCreditLineState,
  action: ActionType<typeof localActions>
): RequestCreditLineState => {
  switch (action.type) {
    case getType(localActions.onAnnualDealEstimateChange):
      let minimumCreditLine = !isNaN(+action.payload.annualDealEstimate)
        ? (Number(action.payload.annualDealEstimate) / action.payload.minCreditLineDivisor).toFixed(
            0
          )
        : state.minimumCreditLine;
      if (action.payload.annualDealEstimate === '') {
        minimumCreditLine = '';
      }
      return {
        ...state,
        annualDealEstimate: action.payload.annualDealEstimate,
        minimumCreditLine: minimumCreditLine.toString(),
      };

    case getType(localActions.onMinimumCreditLineChange):
      return { ...state, minimumCreditLine: action.payload };

    case getType(localActions.updateAnnualDealEstimateError):
      return { ...state, annualDealEstimateError: action.payload };

    case getType(localActions.updateMinimumCreditLineError):
      return { ...state, minimumCreditLineError: action.payload };

    default:
      return state;
  }
};

interface RequestCreditLineViewProps {
  onRequestCreditLine: (annualDealEstimate: string, minimumCreditLine: string) => void;
  height: number;
  width: number;
  annualDealEstimate: string;
  minimumCreditLine: string;
  billingCurrency: string;
}

const mapStateToProps = (state: RootState) => ({
  minCreditLineDivisor: appSelectors.getMinCreditLineDivisor(state),
});

type Props = WithStyles<typeof styles> &
  RequestCreditLineViewProps &
  ReturnType<typeof mapStateToProps>;

export const RequestCreditLineViewUnstyled: React.FC<Props> = props => {
  const amount$ = useDebounce(400);
  const [state, dispatch] = React.useReducer(
    reducer,
    { annualDealEstimate: props.annualDealEstimate, minimumCreditLine: props.minimumCreditLine },
    getInitialState
  );
  const [annualDealEstimateFocused, setAnnualDealEstimateFocused] = React.useState(false);
  const [minimumCreditLimitFocused, setMinimumCreditLimitFocused] = React.useState(false);

  const { classes, billingCurrency } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const disableRequest =
    !state.annualDealEstimate || !!state.annualDealEstimateError || !!state.minimumCreditLineError;

  const onDealEstimateChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value && /\s/.test(value)) return;
    dispatch(
      localActions.onAnnualDealEstimateChange({
        annualDealEstimate: value || '',
        minCreditLineDivisor: props.minCreditLineDivisor,
      })
    );
    amount$.next(() => {
      if (value) {
        if (isNaN(+value)) {
          // If not a number dispatch error message.
          dispatch(localActions.updateAnnualDealEstimateError('error::Only numbers are valid.'));
        } else if (+value < 0) {
          // If negative number dispatch error message.
          dispatch(
            localActions.updateAnnualDealEstimateError(
              t('error::Must be greater than or equal to 0.')
            )
          );
        } else {
          // If amount is valid remove error if any.
          dispatch(localActions.updateAnnualDealEstimateError(''));
        }
      } else {
        // If value removed then its valid input and remove error if any.
        dispatch(localActions.updateAnnualDealEstimateError(''));
      }
    });
  };

  const onMinimumCreditLineChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value && /\s/.test(value)) return;
    dispatch(localActions.onMinimumCreditLineChange(value || ''));

    amount$.next(() => {
      if (value) {
        if (isNaN(+value)) {
          // If not a number dispatch error message.
          dispatch(localActions.updateMinimumCreditLineError('error::Only numbers are valid.'));
        } else if (+value < 0) {
          // If negative number dispatch error message.
          dispatch(
            localActions.updateMinimumCreditLineError(
              t('error::Must be greater than or equal to 0.')
            )
          );
        } else {
          // If amount is valid remove error if any.
          dispatch(localActions.updateMinimumCreditLineError(''));
        }
      } else {
        // If value removed then its valid input and remove error if any.
        dispatch(localActions.updateAnnualDealEstimateError(''));
      }
    });
  };

  const onRequestCreditLine = () => {
    props.onRequestCreditLine(state.annualDealEstimate, state.minimumCreditLine);
  };

  const annualDealEstimateValue = annualDealEstimateFocused
    ? state.annualDealEstimate
    : !state.annualDealEstimateError && state.annualDealEstimate
    ? formatCurrency(state.annualDealEstimate, 0) || ''
    : state.annualDealEstimate;

  const annualDealEstimateFocus = () => {
    setAnnualDealEstimateFocused(true);
  };

  const annualDealEstimateBlur = () => {
    setAnnualDealEstimateFocused(false);
  };

  const minimumCreditLineValue = minimumCreditLimitFocused
    ? state.minimumCreditLine
    : !state.minimumCreditLineError && state.minimumCreditLine
    ? formatCurrency(state.minimumCreditLine, 0) || ''
    : state.minimumCreditLine;

  const minimumCreditLineFocus = () => {
    setMinimumCreditLimitFocused(true);
  };

  const minimumCreditLineBlur = () => {
    setMinimumCreditLimitFocused(false);
  };

  const Header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="requestCreditLine"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Request credit line')}</TextTitle>
    </DialogHeader>
  );

  const Body = (
    <div className={classes.body}>
      <TextBody addClass={classes.information}>
        {t(
          'quote::Credit services will be engaged to establish a credit line for your customer. They recommend a minimum credit line of 1/4 of the annual deal estimate; this will support their monthly invoices with room to grow.'
        )}
      </TextBody>
      <div className={classes.inputContainer}>
        <TextboxStandard
          autoFocus={true}
          dataAutomationId="annualDealEstimateTextbox"
          errorMessage={state.annualDealEstimateError}
          id="annualDealEstimateTextbox"
          label={t('quote::Annual deal estimate ({{currency}})', {
            currency: billingCurrency,
          })}
          maxLength={CreditConstant.maxDealEstimateLength}
          placeholder={t('quote::Estimated value of deal')}
          required
          value={annualDealEstimateValue}
          onBlur={annualDealEstimateBlur}
          onChange={onDealEstimateChange}
          onFocus={annualDealEstimateFocus}
        />
      </div>
      <div className={classes.inputContainer}>
        <TextboxStandard
          dataAutomationId="minimumCreditLineTextbox"
          disabled={!state.annualDealEstimate}
          errorMessage={state.minimumCreditLineError}
          id="minimumCreditLineTextbox"
          label={t('quote::Credit line request ({{currency}})', {
            currency: billingCurrency,
          })}
          maxLength={CreditConstant.maxDealEstimateLength}
          placeholder={t('quote::Calculated based on annual deal estimate')}
          required
          value={minimumCreditLineValue}
          onBlur={minimumCreditLineBlur}
          onChange={onMinimumCreditLineChange}
          onFocus={minimumCreditLineFocus}
        />
      </div>
    </div>
  );

  const Footer = (
    <div className={`${classes.footer} ${classes.flexAlignRight}`}>
      <PrimaryButton
        dataAutomationId="requestCreditLineSubmitButton"
        disabled={disableRequest}
        text={t('quote::Submit')}
        onClick={onRequestCreditLine}
      />
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={props.height}
      width={props.width}
    />
  );
};

export const RequestCreditLineViewStyled = withStyles(styles)(RequestCreditLineViewUnstyled);
export const RequestCreditLineView = connect(mapStateToProps)(RequestCreditLineViewStyled);
