import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextTitle,
} from 'components/ions';
import { ApproverSelector } from 'features/proposal/components/Dialogs/SubmitProposalDialog/ApproverSelector';
import * as LocalActions from 'features/proposal/components/Dialogs/SubmitProposalDialog/SubmitProposal.actions';
import {
  ApproverErrorType,
  ApproverSet,
  SelectedApprovers,
} from 'features/proposal/components/Dialogs/SubmitProposalDialog/types';
import * as selectors from 'features/proposal/selectors';
import { generateEmpowermentsKey } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { sharedStyles, ThemeProps } from 'styles';
import { ActionType, getType } from 'typesafe-actions';

const styles = (theme: ThemeProps) => {
  return {
    ...sharedStyles(theme),
    selectApproverBody: {
      paddingLeft: 23,
      paddingRight: 23,
      maxHeight: 255,
      overflow: 'auto',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
    selectApproverBodyHeader: {
      paddingLeft: 23,
      paddingRight: 23,
      paddingBottom: 23,
      fontSize: 14,
    },
  };
};

// Local states and actions -----------------------------------------------

interface LocalState {
  selectedApprovers: SelectedApprovers;
}

export const reducer = (state: LocalState, action: ActionType<typeof LocalActions>): LocalState => {
  switch (action.type) {
    case getType(LocalActions.setApprover): {
      return {
        ...state,
        selectedApprovers: {
          ...state.selectedApprovers,
          [action.payload.key]: {
            emails: action.payload.emails,
            errorTypes: action.payload.errorTypes,
            isSelectedOption: action.payload.isSelectedOption,
            order: action.payload.order,
            autoPopulate: action.payload.autoPopulate,
          },
        },
      };
    }
    case getType(LocalActions.removeKey): {
      let newState = { ...state };
      if (newState.selectedApprovers) {
        delete newState.selectedApprovers[action.payload];
      }
      return newState;
    }
    default:
      return state;
  }
};

// Props ------------------------------------------------------------------

interface SelectApproversViewProps {
  height: number;
  width: number;
  selectedApprovers: SelectedApprovers;
  closeDialog: () => void;
  onBackClick?: (selectedApprovers: SelectedApprovers) => void;
  onSubmitClick?: (selectedApprovers: SelectedApprovers) => void;
}

const mapStateToProps = (state: RootState, props: SelectApproversViewProps) => ({
  closeDialog: props.closeDialog,
  checkEmailEmpowerment: (empowermentsStoreKey: string, email: string) =>
    selectors.checkEmailEmpowerment(state, empowermentsStoreKey, email),
  checkEmpowermentValidForNextStep: (approverCount: number) =>
    selectors.checkEmpowermentValidForNextStep(state, approverCount),
  marketCode: selectors.getMarket(state),
  proposal: selectors.getActiveProposal(state),
  requiredApprovalLevels: selectors.getRequiredApprovalLevels(state),
  height: props.height,
  width: props.width,
  selectedApprovers: props.selectedApprovers,
});

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = WithStyles<typeof styles> & StateProps & SelectApproversViewProps;

// Component ---------------------------------------------------------------------

const SelectApproversViewUnstyled: React.FC<Props> = props => {
  const { classes, proposal } = props;
  const { t } = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, {
    selectedApprovers: props.selectedApprovers,
  });

  const handleBackClick = () => props.onBackClick && props.onBackClick(state.selectedApprovers);
  const handleSubmitClick = () =>
    props.onSubmitClick && props.onSubmitClick(state.selectedApprovers);

  const validApproverCount = Object.entries(state.selectedApprovers).filter(
    ([key, value]) =>
      !value.errorTypes.length ||
      (value.errorTypes.length === 1 &&
        !value.autoPopulate &&
        value.errorTypes[0] === ApproverErrorType.EmpowermentUnknown &&
        props.checkEmailEmpowerment(key, value.emails[0]))
  ).length;

  const removeApprover = (key: string): void => {
    dispatch(LocalActions.removeKey(key));
  };

  // Selecting approver and validation logic
  const setApprover = (approverSet: ApproverSet): void => {
    if (approverSet.autoPopulate) {
      dispatch(LocalActions.setApprover(approverSet));
    } else {
      // Check for duplicate here
      const isAlreadySelected = Object.values(state.selectedApprovers).some(item =>
        item.emails.includes(approverSet.emails[0])
      );
      if (isAlreadySelected) {
        Object.values(state.selectedApprovers).forEach(approver => {
          if (
            approver.emails.includes(approverSet.emails[0]) &&
            !approver.errorTypes.includes(ApproverErrorType.DuplicateApprover)
          )
            approver.errorTypes.push(ApproverErrorType.DuplicateApprover);
        });
        approverSet.errorTypes.push(ApproverErrorType.DuplicateApprover);
      } else {
        // Reset if any duplicate error was set before.
        const approversWithDuplicateError = Object.entries(
          state.selectedApprovers
        ).filter(selectedApprover =>
          selectedApprover[1].errorTypes.includes(ApproverErrorType.DuplicateApprover)
        );
        if (approversWithDuplicateError.length === 1) {
          approversWithDuplicateError.forEach(approverWithDuplicateError => {
            // Find method returning key at 0th index and email and other properties at 1st index.
            const remainingErrors = approverWithDuplicateError[1].errorTypes.filter(
              error => error !== ApproverErrorType.DuplicateApprover
            );
            const errors = remainingErrors.length ? remainingErrors : [];
            const resetApprover = {
              key: approverWithDuplicateError[0],
              emails: [approverWithDuplicateError[1].emails[0]],
              isSelectedOption: approverWithDuplicateError[1].isSelectedOption,
              errorTypes: errors,
              order: approverWithDuplicateError[1].order,
              autoPopulate: false,
            };
            dispatch(LocalActions.setApprover(resetApprover));
          });
        }
      }
      dispatch(LocalActions.setApprover(approverSet));
    }
  };

  const approverSelectors =
    props.requiredApprovalLevels &&
    props.requiredApprovalLevels.map((item, index) => {
      const storageKey = generateEmpowermentsKey({
        empowermentLevel: item.level,
        policy: item.policy,
        market: props.marketCode,
      });
      const selectedEmail =
        state.selectedApprovers[storageKey] && state.selectedApprovers[storageKey].isSelectedOption
          ? state.selectedApprovers[storageKey].emails[0]
          : '';
      const enteredText =
        state.selectedApprovers[storageKey] && !state.selectedApprovers[storageKey].isSelectedOption
          ? state.selectedApprovers[storageKey].emails[0]
          : '';
      let errorMessage = '';
      const hasMoreThanOneError =
        state.selectedApprovers[storageKey] &&
        state.selectedApprovers[storageKey].errorTypes.length > 1;
      if (
        state.selectedApprovers[storageKey] &&
        state.selectedApprovers[storageKey].errorTypes.length
      ) {
        const notEmpowered =
          state.selectedApprovers[storageKey].errorTypes.includes(
            ApproverErrorType.EmpowermentUnknown
          ) && props.checkEmailEmpowerment(storageKey, enteredText) === false;
        if (
          state.selectedApprovers[storageKey].errorTypes.includes(
            ApproverErrorType.EmailNotEmpowered
          ) ||
          notEmpowered
        ) {
          errorMessage = t('quote::This person is not empowered for this approval.');
        }
        if (
          state.selectedApprovers[storageKey].errorTypes.includes(ApproverErrorType.InvalidEmail)
        ) {
          errorMessage = t('quote::This is not a valid email.');
        }
        if (
          state.selectedApprovers[storageKey].errorTypes.includes(
            ApproverErrorType.DuplicateApprover
          ) &&
          (!hasMoreThanOneError || errorMessage === '')
        ) {
          errorMessage = t('quote::Duplicate approvers are not allowed.');
          const approversWithDuplicateEmail = Object.entries(state.selectedApprovers).some(
            approver =>
              approver[0] !== storageKey &&
              approver[1].emails[0] === state.selectedApprovers[storageKey].emails[0]
          );
          if (!approversWithDuplicateEmail) {
            errorMessage = '';
          }
        }
      }

      return (
        <ApproverSelector
          autoPopulate={item.autoPopulate}
          empowermentLevel={item.level}
          enteredText={enteredText}
          errorMessage={errorMessage}
          key={index}
          marketCode={proposal.header.pricingContext.market}
          order={item.order}
          policy={item.policy}
          removeApprover={removeApprover}
          selectedApprovers={state.selectedApprovers}
          selectedEmail={selectedEmail}
          setApprover={setApprover}
          storageKey={storageKey}
        />
      );
    });

  const Header = (
    <DialogHeader
      closeButtonClass={classes.customDialogCloseButton}
      dataAutomationId="selectApprovers"
      dialogClose={props.closeDialog}
      headerClass={classes.customDialogHeader}
    >
      <TextTitle>{t('quote::Submit for approval')}</TextTitle>
    </DialogHeader>
  );

  const Body = (
    <div>
      <div className={classes.selectApproverBodyHeader}>
        {t('quote::Select your approver below.', {
          count: props.requiredApprovalLevels ? props.requiredApprovalLevels.length : 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          defaultValue_plural: 'Select your approvers below.',
        })}{' '}
        {t(
          'quote::A reminder, transactions below an annual revenue of USD $50k do not meet the Field Ticket To Entry (TTE). In addition, transactions below an annual revenue of USD $500k do not meet the Business Desk TTE.'
        )}
      </div>
      <div className={classes.selectApproverBody}>{approverSelectors}</div>
    </div>
  );

  const Footer = (
    <div className={`${classes.customDialogFooter} ${classes.flexAlignRight}`}>
      <SecondaryButton
        dataAutomationId="submitForApprovalBack"
        id="submitForApprovalBack"
        text={t('quote::Back')}
        onClick={handleBackClick}
      />
      <div className={classes.padLeft20}>
        <PrimaryButton
          dataAutomationId="submitForApproval"
          disabled={!props.checkEmpowermentValidForNextStep(validApproverCount)}
          id="submitForApproval"
          text={t('quote::Submit for approval')}
          onClick={handleSubmitClick}
        />
      </div>
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={props.height}
      width={props.width}
    />
  );
};

export const SelectApproversViewStyled = withStyles(styles)(
  SelectApproversViewUnstyled
) as React.FC<StateProps>;
export const SelectApproversView = connect(mapStateToProps)(SelectApproversViewStyled);
