import * as accountExtensions from './account-extensions/api';
import * as account from './account/api';
import * as addressValidation from './address-validation/api';
import * as agreement from './agreement/api';
import * as approval from './approval/api';
import * as billingGroup from './billinggroup/api';
import * as catalog from './catalog/api';
import * as ccf from './ccf/api';
import * as constraints from './constraints/api';
import * as credit from './credit/api';
import * as crm from './crm/api';
import * as customer from './customer/api';
import * as ecifStatic from './ecif-static/api';
import * as edgeSearch from './edge-search/api';
import * as edge from './edge/api';
import * as empowerment from './empowerment/api';
import * as externalUser from './externaluser/api';
import * as flights from './flights/api';
import * as graph from './graph/api';
import * as ldss from './ldss/api';
import * as localStorage from './local-storage-service';
import * as logger from './logger-service';
import * as notification from './notification/api';
import * as pricingScope from './pricingscope/api';
import * as project from './project/api';
import * as proposal from './proposal/api';
import * as purchase from './purchase/api';
import * as recoCatalog from './reco-catalog/api';
import * as recoStatic from './reco-static/api';
import * as reco from './reco/api';
import * as signUp from './sign-up/api';
import * as user from './user-api-client';
import * as userPreferences from './user-preferences/api';

export const api = {
  account,
  accountExtensions,
  addressValidation,
  agreement,
  approval,
  billingGroup,
  catalog,
  ccf,
  constraints,
  credit,
  crm,
  customer,
  ecifStatic,
  edge,
  edgeSearch,
  empowerment,
  externalUser,
  flights,
  graph,
  ldss,
  notification,
  pricingScope,
  project,
  proposal,
  purchase,
  reco,
  recoCatalog,
  recoStatic,
  signUp,
  user,
  userPreferences,
};

export default {
  logger,
  localStorage,
  //   toast,
  api,
};
