import { CreditLineReason } from 'features-apollo/quote/types';
import { Credit, Quote } from 'generated/graphql';
import i18next from 'i18n';
import { oc } from 'ts-optchain';

import { hasMultipleParticipants } from './quote';

// -- Extract organization data from the quote -------------------------------------------

/**
 * Gets the address of the organization found in the quote's endCustomer property
 */
export const getEndCustomerOrganizationAddress = (quote: Quote) => oc(quote).endCustomer.address();

/**
 * Gets the identifier of the organization found in the quote's endCustomer property
 */
export const getEndCustomerOrganizationId = (quote: Quote) => oc(quote).endCustomer.id();

/**
 * Gets the name of the organization found in the quote's endCustomer property
 */
export const getEndCustomerOrganizationName = (quote: Quote) => {
  const address = getEndCustomerOrganizationAddress(quote);
  return oc(address).companyName();
};

/**
 * Gets the trade-name of the organization found in the quote's endCustomer property
 */
export const getEndCustomerOrganizationTradeName = (quote: Quote) =>
  oc(quote).endCustomer.tradeName();

/**
 * Gets the lead organization in relation to the quote
 */
export const getLeadOrganization = (quote: Quote) => quote.leadOrganization;

/**
 * Gets the address of the organization found in the quote's soldTo property
 */
export const getSoldToOrganizationAddress = (quote: Quote) =>
  oc(quote).soldTo.organization.address();

/**
 * Gets the identifier of the organization found in the quote's soldTo property
 */
export const getSoldToOrganizationId = (quote: Quote) => oc(quote).soldTo.organization.id();

/**
 * Gets the name of the organization found in the quote's soldTo property
 */
export const getSoldToOrganizationName = (quote: Quote) => {
  const address = getSoldToOrganizationAddress(quote);
  return oc(address).companyName();
};

/**
 * Gets the trade-name of the organization found in the quote's soldTo property
 */
export const getSoldToOrganizationTradeName = (quote: Quote) =>
  oc(quote).soldTo.organization.tradeName();

/**
 * Gets all credit information of the organization found in the quote's soldTo property
 */
export const getSoldToOrganizationCredit = (quote: Quote) => ({
  credit: oc(quote).soldTo.organization.credit(),
  monthlyCreditLimit: oc(quote).soldTo.monthlyCreditLimit(),
});

// -- Evaluates organization data in the quote -------------------------------------------

/**
 * Gets the UI representation of the participants in the quote
 */
export const getParticipantsNames = (quote: Quote) => {
  const partnerName = getSoldToOrganizationName(quote);
  const customerName = getEndCustomerOrganizationName(quote);

  return !partnerName && !customerName
    ? i18next.t('Unknown participants')
    : i18next.t('{{partnerName}} for {{customerName}}', {
        partnerName: partnerName || i18next.t('Unknown partner'),
        customerName: customerName || i18next.t('unknown customer'),
      });
};

/**
 * Gets the UI representation of the customer in the quote
 */
export const getCustomerName = (quote: Quote): string => {
  const customerName = getEndCustomerOrganizationName(quote);
  return customerName || i18next.t('Unknown billing account');
};

/**
 * Gets the UI representation of the customer in the quote whether is a single customer or multiple participants.
 */
export const getCustomerOrParticipantsNames = (quote: Quote) => {
  const isCustomerMultipleParticipants = hasMultipleParticipants(quote);

  if (isCustomerMultipleParticipants) {
    return getParticipantsNames(quote);
  } else {
    return getCustomerName(quote);
  }
};

/**
 * Gets credit line status
 */
export const getCreditLineStatus = (
  credit: Credit,
  monthlyCreditLimit: number | null | undefined
) => {
  const { creditLine, reason, decision } = credit;

  if (decision === 'approved' && reason && reason.indexOf(CreditLineReason.SafeList) >= 0) {
    return CreditLineReason.SafeList;
  } else if (decision === 'approved' && !reason) {
    if (Number(creditLine) < Number(monthlyCreditLimit)) {
      return CreditLineReason.CreditIncrease;
    } else {
      return CreditLineReason.CreditLine;
    }
  } else if (reason === CreditLineReason.PendingReview || reason === CreditLineReason.NoPiOnFile) {
    return CreditLineReason.PendingReview;
  } else if (reason === CreditLineReason.UnderReview) {
    return CreditLineReason.UnderReview;
  } else if (reason === CreditLineReason.ReviewCancelled) {
    return CreditLineReason.ReviewCancelled;
  } else if (reason === CreditLineReason.Rejected || reason === CreditLineReason.ManualRejected) {
    return CreditLineReason.Rejected;
  } else {
    return CreditLineReason.None;
  }
};
