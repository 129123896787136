import { ThemeProps } from 'styles';

export const toolBarAtomStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    commandsContainer: {
      alignItems: 'flex-end',
    },
    divider: {
      alignSelf: 'flex-end',
      borderRight: `solid 1px ${palette.backgroundDivider}`,
      height: '90%',
      transform: 'translateY(.5px)',
    },
    rightSideCommands: {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: '16px',
    },
    toolBar: {
      backgroundColor: palette.backgroundCommon,
      display: 'flex',
      '& > div': {
        display: 'flex',
      },
    },
    grow: {
      flexGrow: 1,
    },
  };
};
