import axios from 'axios';
import { TokenAuthorities } from 'services/utils';
import { createGuid, getAuthHeader, getCV } from 'services/utils';

import { CreditConfig, endpoints } from './config';
import { CreditEndpoints, CreditInfo, CreditLookupRequest, CreditRequest } from './types';

async function getHeaders(): Promise<Record<string, string>> {
  const token = await getAuthHeader(TokenAuthorities.L2O);
  return {
    authorization: token,
    'x-ms-correlation-id': createGuid(),
    'MS-CV': getCV(),
    'x-ms-tracking-id': createGuid(),
    'Content-Type': 'application/json; charset=utf-8',
    'api-version': '2019-08-29',
  };
}

async function performCreditRequest(
  request: CreditRequest | CreditLookupRequest,
  endpoint: CreditEndpoints,
  config: CreditConfig
): Promise<CreditInfo> {
  const headers = await getHeaders();
  const url = `${endpoints[config.environment]}/${endpoint}`;
  const response = await axios.post<CreditInfo>(url, request, { headers });
  const reason = response.data as CreditInfo;
  return reason;
}

export async function loadCreditInfo(
  request: CreditLookupRequest,
  config: CreditConfig
): Promise<CreditInfo> {
  return await performCreditRequest(request, CreditEndpoints.CreditLookup, config);
}

export async function performCreditCheck(
  request: CreditRequest,
  config: CreditConfig
): Promise<CreditInfo> {
  // product_id - Hard coded value and is not being used by CREDIT Service as of now.
  /* eslint-disable @typescript-eslint/camelcase */
  request.quote_detail.product_id = 12345;
  // product_type - As per business direction we pass Software
  request.quote_detail.product_type = 'software';
  /* eslint-enable */
  // TODO: Implement feature switch. Pass purchase_check_no_mr if featureSwitch.isCreditnomrSwitchOn is true.
  return await performCreditRequest(request, CreditEndpoints.MSProductService, config);
}
