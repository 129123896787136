import { ThemeProps } from 'styles';

export const summaryDetailsPaneStyles = (theme: ThemeProps) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 0,
      minHeight: 0,
      fontSize: 12,
      flexGrow: 1,
      backgroundColor: theme.palette.backgroundCommon,
    },
    contentContainer: {
      background: theme.palette.backgroundCommon,
      height: '100%',
      padding: '24px 30px',
      overflowY: 'auto',
    },
    headerContainer: {
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    },
    sectionSeparator: { margin: '32px 0 24px' },
    spinner: { flexGrow: 0.5 },
  };
};
