import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import gql from 'graphql-tag';

import { CustomerOrgFragment, CustomerSheetQuoteFragment } from './fragments';

/** Query to get organization suggestions */
export const GetSuggestedOrganizations = gql`
  query getSuggestedOrganizations($query: String!) {
    searchOrganizations(query: $query) {
      ...customerOrgFragment
    }
  }
  ${CustomerOrgFragment}
`;

/** Mutation to add existing billing account to quote */
export const AddOrganization = gql`
  mutation addOrganization($input: SelectOrganizationInput!) {
    addOrganization(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

/**
 * Use on indirect quotes to remove partner and/or customer organizations
 */
export const REMOVE_ORGANIZATION = gql`
  mutation REMOVE_ORGANIZATION($input: RemoveOrganizationInput!) {
    removeOrganization(input: $input) {
      ...customerSheetQuoteFragment
    }
  }
  ${CustomerSheetQuoteFragment}
`;
