import { CalloutCard, InfoButton, LinkExternal, TextBody, TextboxStandard } from 'components';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import { getSimpleTermId } from 'features-apollo/quote/components/ConfigCards/SimpleTermsCard/utils';
import { QuoteLineItem } from 'features-apollo/quote/components/types';
import { getSelectedLineItem, validGuid } from 'features-apollo/quote/components/utils';
import { CatalogAction } from 'generated/graphql';
import gql from 'graphql-tag';
import { DirectionalHint, Label } from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { simpleTermsCardStyles } from './SimpleTermsCard.styles';

const HELP_VIDEO_URL =
  'https://msit.microsoftstream.com/embed/video/6daa0770-1e29-48e7-b918-03f3ab772702?autoplay=false&amp;showinfo=true';

export interface SimpleTermsCardProps {
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  name: string;
  hidden?: boolean;
  onDismiss: () => void;
}

export const ApplySimpleTermConfiguration = gql`
  mutation ApplyConfigurationSingleSku(
    $quote: QuoteMutationInput!
    $configuration: ApplyConfigurationSingleSkuInput!
  ) {
    applyConfigurationSingleSku(quote: $quote, configuration: $configuration) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

type Props = SimpleTermsCardProps & WithStyles<typeof simpleTermsCardStyles>;

export const SimpleTermsCardUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, lineItemId, target, name, hidden, onDismiss } = props;
  const { t } = useTranslation();
  const { activeQuote } = React.useContext(ActiveQuoteContext);
  const [state, setState] = React.useState<{
    supplementalTermId: string;
    initialStateSet: boolean;
  }>({
    supplementalTermId: '',
    initialStateSet: false,
  });

  const [applySimpleTerm] = useMutation(ApplySimpleTermConfiguration);
  let lineItem: QuoteLineItem | undefined;
  let readOnly = true;
  let isECIF = false;
  if (activeQuote) {
    lineItem = getSelectedLineItem(activeQuote, lineItemId);
    readOnly = activeQuote.readOnly;
    if (!state.initialStateSet) {
      setState({
        supplementalTermId: getSimpleTermId(lineItem),
        initialStateSet: true,
      });
    }
    isECIF = oc(lineItem).product.productType() === 'ECIF';
  }

  const onCloseCard = () => {
    setState({
      supplementalTermId: '',
      initialStateSet: false,
    });
    onDismiss();
  };

  const onChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newTermId?: string
  ) => {
    if (newTermId !== undefined) {
      setState({ ...state, supplementalTermId: newTermId });
    }
  };

  const termIdError = state.supplementalTermId && !validGuid.test(state.supplementalTermId);
  const termIdErrorMessage = termIdError ? t('quote::Term ID is in an invalid format') : undefined;

  const applyButtonDisabled = !!termIdError || !state.supplementalTermId.trim() || !lineItem;
  const onApply = () => {
    if (applyButtonDisabled || !activeQuote) {
      return;
    }

    applySimpleTerm({
      variables: {
        quote: {
          id: activeQuote.id,
          etag: activeQuote.etag,
        },
        configuration: {
          lineItemId: oc(lineItem).id(),
          skuId: lineItem!.sku !== null ? oc(lineItem).sku.skuId() : undefined,
          availabilityId: lineItem!.availability && lineItem!.availability.availabilityId,
          action: CatalogAction.Purchase,
          supplementalTermReferenceData: {
            key: 'TermId',
            value: state.supplementalTermId,
          },
        },
        onError: () => {
          //Todo: Notify user there was an error
          console.log('Reactor core overloaded...');
        },
      },
    });
    onCloseCard();
  };

  const termIdInputLabel = () => (
    <div className={classes.termIdLabel}>
      <Label className={classes.label} htmlFor="financeTermsTermId" required>
        {t('quote::Term ID')}
      </Label>
      {isECIF && (
        <InfoButton
          ariaLabel={t('quote::Open term information')}
          calloutProps={{
            closeButtonAriaLabel: t('Close'),
            headline: t('quote::Term ID'),
            maxWidth: 343,
          }}
          id="term-id-info-button"
        >
          <TextBody>
            {t('quote::A Term ID is used to associate a specific amendment to this quote.')}
          </TextBody>
          <TextBody>
            <Trans ns="quote">
              To request ECIF for your customer, please visit{' '}
              <LinkExternal
                addClass={classes.infoLink}
                displayText="aka.ms/OneAsk"
                href="https://aka.ms/OneAsk"
                size="medium"
                onClick={() =>
                  loggerService.log({
                    name: 'SimpleTermsCard - OneAsk link is clicked',
                  })
                }
              />
              .
            </Trans>
          </TextBody>
          <TextBody>
            {t(
              `quote::Upon approval you will receive a Term ID. To check that ECIF has been correctly configured, select 'View agreement' from within Quote Center.`
            )}
          </TextBody>
        </InfoButton>
      )}
    </div>
  );

  return hidden ? null : (
    <CalloutCard
      applyButtonDisabled={applyButtonDisabled}
      applyButtonStrings={{ ariaLabel: t('quote::Apply'), text: t('quote::Apply') }}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      directionalHint={DirectionalHint.rightCenter}
      headerText={name}
      id={lineItemId}
      isBeakVisible={true}
      isReadOnly={readOnly}
      maxHeight={750}
      maxWidth={400}
      minWidth={325}
      target={target}
      onApply={onApply}
      onDismiss={onCloseCard}
    >
      <TextboxStandard
        ariaLabel={t('quote::Term ID')}
        dataAutomationId="simpleTermsCardTermId"
        errorMessage={termIdErrorMessage}
        readOnly={readOnly}
        value={state.supplementalTermId}
        onChange={onChange}
        onRenderLabel={termIdInputLabel}
      />
      {isECIF && (
        <LinkExternal
          addClass={classes.link}
          dataAutomationId="learnAboutECIFLink"
          displayText={t('quote::Learn more about ECIF')}
          href={HELP_VIDEO_URL}
        />
      )}
    </CalloutCard>
  );
};

export const SimpleTermsCard = withStyles(simpleTermsCardStyles)(SimpleTermsCardUnstyled);
