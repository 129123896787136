import { QuoteLineItem } from 'features-apollo/quote/components/types';
import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import { isPreApprovedEcif } from 'features-apollo/selectors';

export const getSimpleTermId = (lineItem?: QuoteLineItem): string => {
  if (
    lineItem &&
    (lineItem.__typename === 'SimpleTermLineItem' ||
      (lineItem.__typename === 'EcifLineItem' && !isPreApprovedEcif(lineItem)) ||
      lineItem.__typename === 'FinancingTermLineItem')
  ) {
    const supplementalData = getValueOrUndefined(lineItem.supplementalTermReferenceData);
    return supplementalData &&
      supplementalData.key.toLowerCase() === 'termid' &&
      supplementalData.value !== null
      ? supplementalData.value
      : '';
  }
  return '';
};
