import {
  quoteIsAssignedToCustomer,
  quoteIsAssignedToField,
  quoteIsAssignedToPartner,
  quoteRequiresApproval,
} from 'features-apollo/selectors';
import { Maybe, Quote, QuoteStatus } from 'generated/graphql';
import i18next from 'i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeTypeName<T>(obj: T): T {
  return { ...obj, __typename: undefined };
}

export function filterAndCastMaybeArray<T>(arr: Maybe<T>[]) {
  return arr.filter(el => el !== null && el !== undefined) as T[];
}

export const getQuoteStatusLabels = () => {
  return {
    Approved: i18next.t('quote::Approved'),
    Active: i18next.t('quote::Active'),
    ActiveApproved: i18next.t('quote::Active - Approved'),
    ActivePublished: i18next.t('quote::Active - Published'),
    Completed: i18next.t('quote::Completed'),
    Draft: i18next.t('quote::Draft'),
    DraftPublished: i18next.t('quote::Draft - Published'),
    DraftApprovalRequired: i18next.t('quote::Draft - Approval required'),
    Expired: i18next.t('quote::Expired'),
    PendingApproval: i18next.t('quote::Pending approval'),
    Purchased: i18next.t('quote::Purchased'),
    PurchaseFailed: i18next.t('quote::Purchase failed'),
    PurchaseInProgress: i18next.t('quote::Purchase in Progress - Published'),
    Rejected: i18next.t('quote::Rejected'),
    SubmittedPendingApproval: i18next.t('quote::Submitted - Pending approval'),
    Unknown: i18next.t('quote::Unknown'),
  };
};

export const getQuoteDisplayStatus = (quote: Quote) => {
  const statusLabels = getQuoteStatusLabels();
  const quoteStatus = quote.status;
  const approvalRequired = quoteRequiresApproval(quote);

  let activeLabel = statusLabels.Active;
  if (!approvalRequired && quoteIsAssignedToField(quote)) {
    activeLabel = statusLabels.ActiveApproved;
  } else if (
    !approvalRequired &&
    (quoteIsAssignedToCustomer(quote) || quoteIsAssignedToPartner(quote))
  ) {
    activeLabel = statusLabels.ActivePublished;
  }

  switch (quoteStatus) {
    case QuoteStatus.Draft:
      if (approvalRequired) {
        return statusLabels.DraftApprovalRequired;
      }
      return quoteIsAssignedToCustomer(quote) ? statusLabels.DraftPublished : statusLabels.Draft;
    case QuoteStatus.Submitted:
      return statusLabels.SubmittedPendingApproval;
    case QuoteStatus.Active:
      return activeLabel;
    case QuoteStatus.Rejected:
      return statusLabels.Rejected;
    case QuoteStatus.PurchaseInProgress:
      return statusLabels.PurchaseInProgress;
    case QuoteStatus.Purchased:
      return statusLabels.Purchased;
    case QuoteStatus.PurchaseFailure:
      return statusLabels.PurchaseFailed;
    case QuoteStatus.Completed:
      return statusLabels.Completed;
    case QuoteStatus.Expired:
      return statusLabels.Expired;
    case QuoteStatus.Unknown:
      return statusLabels.Unknown;
    default:
      return quote.status;
  }
};
