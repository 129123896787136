import { Dialog } from 'components';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { Fail, Processing, Success } from 'features-apollo/components/dialogs';
import {
  getDialogTitleForWorkAccount,
  TenantBody,
  TenantData,
  TenantFooterButtons,
  TenantHeaderString,
  WorkAccountBody,
  WorkAccountFooterButtons,
} from 'features-apollo/quote/components/Dialogs';
import { getFlightIsEnabled } from 'features/app/selectors';
import {
  AccountTenant,
  CustomerType,
  InvitedUserInput,
  InvitedUserSimple,
  MutationUpdateInvitedUserArgs,
  Quote,
  QuoteMutationInput,
} from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { AddTenantToAccount, UpdateInvitedUser } from '../queries';
import { tenantWizardStyles } from './TenantWizard.styles';

const dialogDimensions = {
  height: 612,
  width: 600,
};

export enum TenantWizardView {
  Tenant,
  Email,
  Processing,
  Success,
}

export enum TenantScenario {
  partner = 'partner',
  partnerCustomer = 'partner-customer',
  billingAccountProfile = 'billing-profile',
}

export interface TenantWizardProps {
  /**
   * Required to update the quote object.
   */
  quoteMutationInput: QuoteMutationInput;
  /**
   * Defines the first step presented to the user.
   * @default TenantWizardView.Tenant
   */
  initialView?: TenantWizardView;
  /**
   * Defines a tenant flow scenario specific for partner or partner-customer
   */
  specificScenario?: TenantScenario;
  /**
   * Identifies the account of the tenant
   */
  organization: { id: string; accountId: string };
  /**
   * Provides user the option to exchange the tenant from the owns available in the account
   */
  accountTenants: AccountTenant[];
  /**
   * Tenant currently found in the quote
   */
  invitedUser?: InvitedUserSimple | null;
}

const mapStateToProps = (state: RootState) => {
  return {
    preventAdditionalTenant: getFlightIsEnabled(state, Flight.noAdditionalTenant),
  };
};

type Props = TenantWizardProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof tenantWizardStyles>;

const TenantWizardUnstyled: React.FC<Props> = props => {
  const { invitedUser } = props;
  const { t } = useTranslation();
  const quoteId = props.quoteMutationInput.id;

  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };

  //#region State Management
  const [isTenant, setIsTenant] = React.useState<boolean>(false);
  const [isWorkAccountUnauthorized, setIsWorkAccountUnauthorized] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState<TenantWizardView>(
    props.initialView || TenantWizardView.Tenant
  );
  const [tenant, setTenant] = React.useState<TenantData>();
  const [email, setEmail] = React.useState<string | undefined>();
  const [isValid, setIsValid] = React.useState<boolean>();
  const [addTenantErrorCode, setAddTenantErrorCode] = React.useState<string | undefined>(undefined);
  const [updateUserRequest, setUpdateUserRequest] = React.useState<InvitedUserInput>();
  const setCurrentStepFactory = (view: TenantWizardView) => () => setCurrentStep(view);
  const notReady = React.useCallback(() => setIsValid(false), [setIsValid]);
  //#endregion

  //#region Messages
  const loadingMessage = t('quote::Update underway, this may take a couple of seconds');
  const successMessage = t('quote::The requested changes were successfully completed.');
  const failMessage = t(
    `error::The tenant {{tenantId}} is already linked to an account and therefore can't be used to create another account with this quote. Please reuse the existing account or chose another tenant.`,
    {
      tenantId: tenant ? tenant.tenantId : '',
    }
  );
  const successDialog = (
    <Success
      message={successMessage}
      {...dialogDimensions}
      dataAutomationId="addWorkAccountSuccess"
    />
  );
  //#endregion

  const [updateInvitedUser, { loading: updateUserLoading, error: updateUserError }] = useMutation<
    { updateInvitedUser: Quote },
    MutationUpdateInvitedUserArgs
  >(UpdateInvitedUser, {
    update(cache, { data: updateInvitedUser }) {
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: quoteId },
        data: { getQuote: updateInvitedUser },
      });
    },
    onCompleted: () => {
      return setCurrentStep(TenantWizardView.Success);
    },
  });

  const onUpdateWorkAccount = (input: InvitedUserInput) => {
    updateInvitedUser({
      variables: {
        input: {
          ...input,
          customerType:
            props.specificScenario === TenantScenario.partner
              ? CustomerType.SoldToCustomer
              : props.specificScenario === TenantScenario.partnerCustomer
              ? CustomerType.EndCustomer
              : undefined,
        },
      },
    });
  };

  const [addTenant, { loading: addTenantLoading, error: addTenantError }] = useMutation(
    AddTenantToAccount,
    {
      refetchQueries: () => [{ query: GET_QUOTE, variables: { id: quoteId } }],
      onCompleted: () => {
        if (updateUserRequest) {
          onUpdateWorkAccount(updateUserRequest);
        }
      },
      onError: error => {
        setAddTenantErrorCode(oc(error).graphQLErrors[0].extensions.errorData.code(''));
      },
    }
  );

  const tenantCount = props.accountTenants.length;

  const onUpdateInvitedUser = () => {
    if (
      tenant &&
      !props.accountTenants.find(
        (accountTenant: AccountTenant) => accountTenant.tenantId === tenant.tenantId
      )
    ) {
      addTenant({
        variables: {
          accountId: props.organization.accountId,
          tenant: {
            tenant: tenant.tenantId,
          },
        },
      });
      setCurrentStep(TenantWizardView.Processing);
    } else if (tenant && email && email !== oc(invitedUser).email()) {
      const request = {
        quote: props.quoteMutationInput,
        email: email,
        tenantId: tenant.tenantId,
      };
      setUpdateUserRequest(request);
      onUpdateWorkAccount(request);
      setCurrentStep(TenantWizardView.Processing);
    }
  };

  let header;
  let body;
  let footer;
  switch (currentStep) {
    case TenantWizardView.Tenant: {
      if (!isTenant) {
        setIsTenant(true);
      }

      const changingTenant = !!oc(invitedUser).tenant.id() || tenantCount === 1;
      const workAccount = oc(invitedUser).tenant.id() ? oc(invitedUser).email() : undefined;

      header = TenantHeaderString(!!tenantCount, changingTenant);
      body = (
        <TenantBody
          accountId={props.organization.accountId}
          accountTenants={props.accountTenants}
          changingTenant={changingTenant}
          initialData={tenant}
          preventAdditionalTenant={props.preventAdditionalTenant}
          workAccount={workAccount}
          onInvalid={notReady}
          onValid={(newTenant: TenantData) => {
            setTenant(newTenant);
            setIsValid(true);
          }}
        />
      );
      footer = TenantFooterButtons(setCurrentStepFactory(TenantWizardView.Email), !isValid);
      break;
    }
    case TenantWizardView.Email: {
      if (!email && isValid) {
        setIsValid(false);
      }

      const editEmail = props.initialView === TenantWizardView.Email;

      const tenantIdFromState: string | undefined =
        invitedUser && invitedUser.tenant
          ? invitedUser.tenant.id
          : editEmail && props.accountTenants.length
          ? props.accountTenants[0].tenantId
          : undefined;

      const tenantId: string | undefined = tenant ? tenant.tenantId : tenantIdFromState;
      if (!tenantId) {
        setCurrentStepFactory(TenantWizardView.Processing);
      } else if (!tenant) {
        setTenant({ tenantId: tenantId });
      }

      if (tenantId) {
        header = getDialogTitleForWorkAccount(
          !!tenantIdFromState && !!invitedUser,
          props.specificScenario
        );
        body = (
          <WorkAccountBody
            accountId={props.organization.accountId}
            isRequired
            organizationId={props.organization.id}
            quoteMutationInput={props.quoteMutationInput}
            setIsWorkAccountUnauthorized={setIsWorkAccountUnauthorized}
            specificScenario={props.specificScenario}
            tenantId={tenantId}
            onInvalid={notReady}
            onValid={(newEmail?: string) => {
              setEmail(newEmail);
              setIsValid(true);
            }}
          />
        );
        footer = WorkAccountFooterButtons(
          isTenant,
          !isValid,
          onUpdateInvitedUser,
          setCurrentStepFactory(TenantWizardView.Tenant),
          isWorkAccountUnauthorized
        );
      }
      break;
    }
    case TenantWizardView.Success:
      return successDialog;
    case TenantWizardView.Processing:
    default: {
      //TODO: Make this smarter.
      //Right now it'll act weird if it fails for one of the edit flows, you close the dialog without completing and open another edit dialog
      const loading = updateUserLoading || addTenantLoading;
      const errorDialog = (
        <Fail
          {...dialogDimensions}
          dataAutomationId="processingUpdateTenantFail"
          onTryAgainClick={onUpdateInvitedUser}
        />
      );

      if (loading) {
        return <Processing {...dialogDimensions} key="Processing" message1={loadingMessage} />;
      } else if (addTenantError) {
        if (addTenantErrorCode && addTenantErrorCode.toLowerCase() === 'externalidalreadybound') {
          return (
            <Fail
              {...dialogDimensions}
              dataAutomationId="tenantAlreadyLinkedFail"
              message={failMessage}
            />
          );
        } else {
          return errorDialog;
        }
      } else if (updateUserError) {
        return errorDialog;
      }
    }
  }

  return (
    <Dialog
      closeDialog={closeDialog}
      footerButtons={footer}
      title={header}
      {...dialogDimensions}
      bodyAddClass={props.classes.body}
      dataAutomationId="tenantWizard"
    >
      {body}
    </Dialog>
  );
};

export const TenantWizard = connect(mapStateToProps)(
  withStyles(tenantWizardStyles)(TenantWizardUnstyled)
);

export const openTenantWizardDialog = (
  context: { openDialog: (dialogProps: DialogProps) => void; closeDialog: () => void },
  props: TenantWizardProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <TenantWizard {...props} />,
  };
  context.openDialog(dialogProps);
};
