import { SmallIcon, TextBodySmall } from 'components';
import {
  getActorEmail,
  getCommentHistoryLogs,
  getDaysSinceLastSubmitApprove,
} from 'features-apollo/quote/selectors/approval';
import { getApproval } from 'features-apollo/quote/selectors/quote';
import { formatDate, getUsernameFromEmail } from 'features-apollo/quote/utils';
import { ApprovalLevelState, Quote, RequiredApproval } from 'generated/graphql';
import i18next from 'i18next';
import React from 'react';
import { oc } from 'ts-optchain';

import { ApprovalCommentsDetails, Comment } from './ApprovalComments';
import { AssignedUserState, RequiredApprovalsPerPolicy } from './RequiredApproval';

/**
 * Gets the assigned users state for the RequiredApproval component
 */
const getAssignedUsersStateFromRequiredApproval = (requiredApproval: RequiredApproval) => {
  const { state, assignedUsers, actor } = requiredApproval;

  if (!state || !assignedUsers) {
    return [];
  }

  if (actor) {
    const actorEmail = getActorEmail(actor);

    return assignedUsers.map<AssignedUserState>(assignedUser => {
      const assignedUserState = actorEmail === assignedUser ? state : undefined;
      const username = getUsernameFromEmail(assignedUser);
      return { assignedUser: username, state: assignedUserState };
    });
  }

  return assignedUsers.map<AssignedUserState>(assignedUser => {
    const username = getUsernameFromEmail(assignedUser);
    return { assignedUser: username, state };
  });
};

/**
 * Gets the required approvals per policy needed for the SummaryDetailsPaneApproval component
 */
export const getRequiredApprovalsPerPolicy = (quote: Quote) => {
  const requiredApprovals = oc(quote).approval.requiredApprovals();

  if (requiredApprovals && requiredApprovals.length) {
    const approval = getApproval(quote);
    const daysSinceLastSubmitApprove = approval && getDaysSinceLastSubmitApprove(approval);

    // requiredApprovals is expected to be sorted by GQL
    return requiredApprovals.map<RequiredApprovalsPerPolicy>(requiredApproval => {
      const { policy, level, state } = requiredApproval;

      const assignedUsers = getAssignedUsersStateFromRequiredApproval(requiredApproval);
      const days = state === ApprovalLevelState.Submitted ? daysSinceLastSubmitApprove : undefined;

      return { days, policy, level, state, assignedUsers };
    });
  }
};

/**
 * Gets comments details for the ApprovalComments component
 */
export const getCommentsDetails = (quote: Quote): ApprovalCommentsDetails | undefined => {
  const approval = getApproval(quote);
  if (!approval) {
    return;
  }

  const commentHistoryLogs = getCommentHistoryLogs(approval);
  const comments = commentHistoryLogs.map<Comment>(
    ({ action, comments: text, createdDate, createdBy }, index) => {
      const id = index.toString();
      const date = formatDate(createdDate);
      const createrByEmail = getActorEmail(createdBy);
      const author = createrByEmail && getUsernameFromEmail(createrByEmail);

      return { id, action, text, date, author };
    }
  );

  return comments.length ? { approvalId: approval.id, comments } : undefined;
};

/**
 * Gets the visual representation of the given approval level state
 */
export const renderApprovalStateIcon = (state: ApprovalLevelState) => {
  switch (state) {
    case ApprovalLevelState.Approved:
      return <SmallIcon iconName="CheckMark" predefinedStyle="approve" />;
    case ApprovalLevelState.Rejected:
      return <SmallIcon iconName="Cancel" predefinedStyle="danger" />;
    case ApprovalLevelState.Pending:
      return <SmallIcon iconName="History" predefinedStyle="warning" />;
    default:
      return <TextBodySmall>-</TextBodySmall>;
  }
};

/*
 * Gets the status text if rejected or approved else undefined
 */

export const getStatusText = (
  state: ApprovalLevelState,
  t: i18next.TFunction,
  classes: Record<string, string>
) => {
  let statusText;
  let textClass;
  switch (state) {
    case ApprovalLevelState.Rejected: {
      statusText = t('quote::Rejected');
      textClass = classes.dangerColor;
      break;
    }
    case ApprovalLevelState.Approved: {
      statusText = t('quote::Approved');
      textClass = classes.approveColor;
      break;
    }
  }
  return statusText ? <TextBodySmall addClass={textClass}>{statusText}</TextBodySmall> : undefined;
};
