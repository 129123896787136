import * as actions from 'features/customer/actions';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'services';
import { PricingScopeConfig } from 'services/pricingscope/config';
import { CustomPrice } from 'services/pricingscope/types';
import { PageResponse } from 'services/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';

export function* loadCustomPrices(accountId: string, orgId: string) {
  const request = { id: orgId, accountId };
  yield put(actions.loadCustomPricesAsync.request(request));
  try {
    const pricingScopeConfig: PricingScopeConfig = yield select(
      (state: RootState) => state.app.appConfig.pricingscope
    );
    const response: PageResponse<CustomPrice> = yield call(
      api.pricingScope.getCustomPrices,
      request,
      pricingScopeConfig
    );

    yield put(
      actions.loadCustomPricesAsync.success({
        id: orgId,
        value: response.value,
      })
    );
  } catch (err) {
    yield put(
      actions.loadCustomPricesAsync.failure({
        message: t('error::Error loading custom prices'),
        exception: err,
      })
    );
  }
}
