import React from 'react';

/**
 * Sets the min-width of the text container to hide the overflow
 */
export const useTextOverflow = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { current } = ref;

  React.useEffect(() => {
    if (current) {
      current.offsetWidth > 100
        ? (current.style.minWidth = '100px')
        : (current.style.minWidth = 'fit-content');
    }
  });

  return ref;
};
