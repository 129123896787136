import { getFlightIsEnabled } from 'features/app/selectors';
import * as actions from 'features/proposal/actions';
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { ApprovalActionType } from 'services/approval/types';
import { CreditCheckEventType } from 'services/credit/types';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { NegotiationReason, Proposal, ProposalAction } from 'services/proposal/types';
import { PatchCommand } from 'services/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';
import { getType } from 'typesafe-actions';

import { getAccountId, getOrganizationId, getProposal } from '../selectors';
import {
  changeExpirationDatePatchCommand,
  getWithdrawActions,
  hydrateQuoteAll,
  loadApproval,
  loadCreditInfo,
  loadQuote,
  multipleQuoteActions,
  patchQuoteHeader,
  singleQuoteAction,
} from './auxiliary';
import { safelistedCID } from 'services/credit/config';

export function* submitQuote() {
  const relevantAction = actions.submitProposalActionAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const { approvalLevels, negotiationReason } = action.payload;
      let quote: Proposal = action.payload.proposal;
      const commands: PatchCommand[] = [];
      if (!oc(quote).header.extendedProperties.userPreferences.setExpiration()) {
        const dateCommand: PatchCommand = yield call(changeExpirationDatePatchCommand);
        commands.push(dateCommand);
      }
      commands.push({
        op: 'replace',
        path: '/negotiationReason',
        value: negotiationReason === NegotiationReason.Unknown ? null : negotiationReason,
      });
      commands.push({
        op: 'replace',
        path: '/approval',
        value: { ...quote.header.approval, approvalLevels },
      });
      quote = yield call(patchQuoteHeader, commands, quote);

      const useTestHeader: boolean = yield select(
        (state: RootState) => state.app.appConfig.proposal.useTestHeader
      );

      const approvalOverrideEnabled = yield select((state: RootState) =>
        getFlightIsEnabled(state, Flight.approvalOverride)
      );

      const useApprovalTestHeaderScenarios = useTestHeader && approvalOverrideEnabled;

      quote = yield call(
        singleQuoteAction,
        {
          action: ProposalAction.Submit,
        },
        quote,
        useApprovalTestHeaderScenarios
      );

      yield put(actions.submitProposalActionAsync.success(quote));
      //TODO: Make this sync
      if (quote.header.approval.id) {
        // For the approval override scenarios, comments cannot be post after quote has been approved
        if (action.payload.comments && !useApprovalTestHeaderScenarios) {
          yield put(
            actions.performApprovalActionAsync.request({
              action: ApprovalActionType.Comment,
              approvalId: quote.header.approval.id,
              comments: action.payload.comments,
            })
          );
        } else {
          yield call(loadApproval, quote.header.approval.id);
        }
      }
    } catch (err) {
      loggerService.error(err);
      yield put(
        actions.submitProposalActionAsync.failure({
          message: t('error::Error submitting quote.'),
          exception: err,
        })
      );
    }
  });
}

export function* publishQuoteGqlSuccess() {
  const relevantAction = actions.publishQuoteGQLSuccess;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      yield put(actions.patchProposalHeaderAsync.success(action.payload));
      yield put(actions.performProposalActionAsync.success(action.payload));
      let quote: Proposal = yield select((state: RootState) =>
        getProposal(state, action.payload.id)
      );

      yield call(hydrateQuoteAll, quote);
    } catch (err) {
      loggerService.error(err);
    }
  });
}

export function* withdrawQuote() {
  const relevantAction = actions.withdrawQuote;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const quoteId = action.payload;
      let quote: Proposal = yield select((state: RootState) => getProposal(state, quoteId));
      const withdrawActions = getWithdrawActions(quote);
      quote = yield call(multipleQuoteActions, withdrawActions, quote);
      const dateCommand: PatchCommand = yield call(changeExpirationDatePatchCommand);
      quote = yield call(patchQuoteHeader, [dateCommand], quote);
    } catch (err) {
      loggerService.error(err);
    }
  });
}

export function* withdrawQuoteGqlSuccess() {
  const relevantAction = actions.withdrawQuoteGQLSuccess;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const quote = yield call(loadQuote, action.payload);
      yield call(hydrateQuoteAll, quote);
    } catch (err) {
      loggerService.error(err);
    }
  });
}

export function* updateProposalCurrency() {
  const relevantAction = actions.updateProposalCurrency;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const commands: PatchCommand[] = [
        {
          op: 'replace',
          path: '/pricingContext/billingCurrency',
          value: action.payload,
        },
      ];
      const quote: Proposal = yield select((state: RootState) =>
        getProposal(state, action.meta.id)
      );
      const organizationId = yield select((state: RootState) => getOrganizationId(state, quote));
      const accountId = yield select((state: RootState) => getAccountId(state, quote));
      const invokeCreditLookup = action.payload !== quote.header.pricingContext.billingCurrency;
      const isSafeListedCreditCheckEnabled: boolean = yield select((state: RootState) =>
        getFlightIsEnabled(state, Flight.safelistedCreditCheck)
      );
      yield call(patchQuoteHeader, commands, quote);
      // Invoke credit look up.
      if (invokeCreditLookup) {
        yield spawn(loadCreditInfo, {
          currency: action.payload,
          /* eslint-disable @typescript-eslint/camelcase */
          event_type: CreditCheckEventType.CreditLookUp,
          legalEntity: {
            accountId: isSafeListedCreditCheckEnabled ? safelistedCID.accountId : accountId,
            organizationId: isSafeListedCreditCheckEnabled
              ? safelistedCID.organizationId
              : organizationId,
          },
        });
      }
    } catch (err) {
      loggerService.error(err);
    }
  });
}
