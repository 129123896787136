import { OrgWizardAddress } from 'features-apollo/quote/components/Wizards/OrganizationWizard';
import {
  getMarketByCountryCode,
  getMarketByCountryName,
} from 'features/proposal/supported-markets';
import {
  Address,
  AddressConfiguration,
  AddressFieldType,
  RegionDisplayType,
  RegionInfo,
  RegionType,
} from 'generated/graphql';

import { shallowCompare } from '@uifabric/utilities';

export const defaultAddressConfiguration: AddressConfiguration = {
  fieldGroupInOrder: [AddressFieldType.City, AddressFieldType.Region, AddressFieldType.PostalCode],
  region: {
    display: RegionDisplayType.Required,
    type: RegionType.State,
  },
};

export const trimAddressFields = (address: OrgWizardAddress): OrgWizardAddress => ({
  addressLine1: address.addressLine1 ? address.addressLine1.trim() : address.addressLine1,
  addressLine2: address.addressLine2 ? address.addressLine2.trim() : address.addressLine2,
  addressLine3: address.addressLine3 ? address.addressLine3.trim() : address.addressLine3,
  city: address.city ? address.city.trim() : address.city,
  country: address.country ? address.country.trim() : address.country,
  region: address.region ? address.region.trim() : address.region,
  postalCode: address.postalCode ? address.postalCode.trim() : address.postalCode,
});

export const hasValidAddressFields = (
  address: OrgWizardAddress,
  addressConfig: AddressConfiguration
) => {
  const trimmedAddress = trimAddressFields(address);
  const market =
    getMarketByCountryName(trimmedAddress.country) ||
    getMarketByCountryCode(trimmedAddress.country);
  const marketRequiresRegion = addressConfig.region.display === RegionDisplayType.Required;

  return !!(
    trimmedAddress.addressLine1 &&
    trimmedAddress.city &&
    (!marketRequiresRegion || trimmedAddress.region) &&
    trimmedAddress.postalCode &&
    market
  );
};

export const capitalizeRegionType = (region: string) => {
  return region.charAt(0).toUpperCase() + region.slice(1);
};

export const addressHasChanges = (current: Address, next: Address): boolean => {
  return !shallowCompare(current, next);
};

export const alphabetizeRegions = (regionsList: RegionInfo[]) => {
  const sortedRegions = regionsList.sort((r1, r2) => {
    return r1.display.localeCompare(r2.display);
  });
  return sortedRegions;
};
