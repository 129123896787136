import { RejectButton } from 'components/ions';
import { RejectDialog } from 'features/proposal/components/Dialogs/RejectDialog/RejectDialog';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

const mapStateToProps = (state: RootState) => ({
  isProposalLoading: selectors.proposalLoading(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const RejectButtonFeature: React.FC<Props> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const dialogProps: DialogProps = {
    providedDialog: <RejectDialog />,
  };

  const openDialog = () => {
    context.openDialog(dialogProps);
  };

  return (
    <RejectButton
      dataAutomationId="rejectProposalButton"
      disabled={props.isProposalLoading}
      text={t('quote::Reject')}
      onClick={openDialog}
    />
  );
};

export const RejectProposalButton = connect(mapStateToProps)(RejectButtonFeature);
