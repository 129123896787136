import { AppEnvironment } from 'features-apollo/app/config/types';
import { PermissionEnvironment } from 'generated/graphql';

import { useQuery } from '@apollo/react-hooks';

import { GET_USERPERMISSIONS } from '../queries';
import { QueryGetUserPermissionsArgs, QueryGetUserPermissionsData } from '../types';

/**
 * Requests user permissions data from GetUser query
 * @param mail email of the current user
 * @param appEnvironment the current environment the user is visiting
 */
export const useGetUserPermissions = (mail: string, appEnvironment: AppEnvironment) => {
  let environment;

  switch (appEnvironment) {
    case AppEnvironment.Local: {
      environment = PermissionEnvironment.Dev;
      break;
    }
    case AppEnvironment.Test: {
      environment = PermissionEnvironment.Test;
      break;
    }
    case AppEnvironment.Prod: {
      environment = PermissionEnvironment.Prod;
      break;
    }
    case AppEnvironment.Latest:
    case AppEnvironment.Stable:
    default: {
      environment = PermissionEnvironment.Int;
      break;
    }
  }

  const { data, loading } = useQuery<QueryGetUserPermissionsData, QueryGetUserPermissionsArgs>(
    GET_USERPERMISSIONS,
    {
      variables: { mail, environment },
      skip: !mail,
    }
  );

  return { permissions: data && data.getUser.permissions, loading };
};
