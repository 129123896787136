import { LinkExternal, SecondaryButton, Spinner, TextTitle } from 'components';
import { CatalogContext } from 'generated/graphql';
import { getProductRows } from './CatalogSectionRow';
import { CatalogPageProduct, CatalogPageNegotiatedTerm, ProductFamily } from './types';
import { featuredSectionStyles } from './shared.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

/**
 * Props for the Catalog Section
 * @prop {string} title - Title for the Catalog Section
 * @prop {string} linkText - Text to display for the section link
 * @prop {string} linkUrl - Url for the section link
 * @prop {boolean} moreResultsAvailable - To populate loadMore button
 * @prop {CatalogPageProduct[] | CatalogPageNegotiatedTerm[]} products - Product array containing Catalog Page Products or Negotiated Terms
 * @prop {boolean} searchingCatalogProducts - To show spinner if produts are still loading
 * @prop {ProductFamily} productFamily - ProductFamily to query catalog to populate section
 * @prop {function} onLoadCatalogProducts - function to call to retrieve additional catalog products
 */
export interface CatalogSectionProps {
  title: string;
  linkText?: string;
  linkUrl?: string;
  moreResultsAvailable?: boolean;
  products: (CatalogPageProduct | CatalogPageNegotiatedTerm)[];
  searchingCatalogProducts: boolean;
  productFamily: ProductFamily;
  onLoadCatalogProducts: () => void;
  catalogContext: CatalogContext;
}

type Props = CatalogSectionProps & WithStyles<typeof featuredSectionStyles>;

export const CatalogSectionUnstyled: React.FC<Props> = props => {
  const { onLoadCatalogProducts, products, productFamily, moreResultsAvailable } = props;
  const [selectedProduct, setSelectedProduct] = React.useState<string | undefined>();

  const { t } = useTranslation();

  const handleTileOnClick = (id: string) => {
    if (selectedProduct !== id) {
      setSelectedProduct(id);
    } else {
      setSelectedProduct(undefined);
    }
  };

  const handleDetailsOnClose = () => {
    setSelectedProduct(undefined);
  };

  const link = (
    <div className={props.classes.headerLink}>
      <LinkExternal
        dataAutomationId="catalogSectionLink"
        displayText={props.linkText}
        href={props.linkUrl}
        size="medium"
      />
    </div>
  );

  const showLink = !!(props.linkText && props.linkUrl);
  const isNegotiatedTermsSection = productFamily === ProductFamily.NegotiatedTerms;
  return props.searchingCatalogProducts && !(props.products && props.products.length) ? (
    <Spinner className={props.classes.spinner} />
  ) : (
    <div className={props.classes.featuredSection}>
      <div className={props.classes.header}>
        <TextTitle>{props.title}</TextTitle>
        {showLink && link}
      </div>
      <div>
        {products &&
          getProductRows(
            products,
            productFamily,
            handleDetailsOnClose,
            handleTileOnClick,
            selectedProduct,
            props.catalogContext
          )}
      </div>
      {moreResultsAvailable && !isNegotiatedTermsSection && (
        <div className={props.classes.loadMore}>
          <SecondaryButton
            dataAutomationId="loadMoreProductsButton"
            text={t('home::Load more products')}
            onClick={onLoadCatalogProducts}
          />
        </div>
      )}
    </div>
  );
};

export const CatalogSection = withStyles(featuredSectionStyles)(CatalogSectionUnstyled);
