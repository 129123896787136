import { ThemeProps } from 'styles';

export const findOrganizationViewStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    message: {
      paddingBottom: 24,
    },
    changeOrganization: {
      paddingTop: 12,
      maxWidth: 148,
      fontSize: theme.fonts.fontSizes.medium,
    },
    searchTips: {
      display: 'flex',
      flexDirection: 'column',
      color: palette.textTertiary,
    },
    searchTipsContainer: {
      marginTop: 10,
    },
  };
};
