import { SupportedTheme } from 'styles/theme';

export const HomeCustomersStyles = (theme: SupportedTheme) => ({
  div: {
    overflowY: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    marginTop: '0px',
  },
  spacer: {
    paddingLeft: '8px',
  },
  container: {
    display: 'flex',
    maxWidth: 600,
    paddingRight: 32,
  },
  navigation: {
    paddingLeft: '26px',
    paddingTop: '26px',
  },
  scroll: {
    overflowY: 'auto',
  },
  navigationSeparator: {
    height: 2,
    maxWidth: 510,
  },
  customerList: {
    paddingLeft: '26px',
    paddingTop: '26px',
  },
  customerSearchBox: {
    width: '100%',
  },
  searchPanel: {
    backgroundColor: theme.palette.backgroundMain,
    borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    boxShadow: `18px -2px 18px ${theme.palette.shadowColor}`,
    paddingLeft: '26px',
    paddingTop: '36px',
    paddingBottom: '26px',
  },
});
