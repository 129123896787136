import gql from 'graphql-tag';

export const GetModernOfficeProduct = gql`
  query GetProduct($id: String!, $catalogContext: CatalogContextInput!, $quoteId: String!) {
    getProduct(id: $id, catalogContext: $catalogContext) {
      skus {
        skuId
        availabilities {
          actions
          availabilityId
          terms {
            termId
            description
            price(context: { quote: { quoteId: $quoteId } }) {
              currency
              amount
            }
            components {
              title
              ... on TermDuration {
                duration
              }
              ... on TermBillingPlan {
                billingPeriod
              }
              description
            }
          }
        }
        title
      }
    }
  }
`;
