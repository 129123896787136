import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withStyles, { WithStyles } from 'react-jss';

const styles = {
  span: {
    background: 'yellow',
  },
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithStyles<typeof styles> {}

interface Params {
  id: string;
}

const Product: React.FC<Props & RouteComponentProps<Params>> = (
  props: Props & RouteComponentProps<Params>
) => (
  <div>
    <h3>
      Catalog Product <span className={props.classes.span}>{props.match.params.id}</span>
    </h3>
  </div>
);

export default withStyles(styles)(Product);
