import { ButtonAtom } from 'components/atoms';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { toggleButtonStyles } from './ToggleButton.styles';

export interface IToggleButtonProps extends ButtonSharedProps {
  onToggleOn: () => void;
  onToggleOff: () => void;
  isToggled?: boolean;
  dataAutomationId?: string;
}

type Props = IToggleButtonProps & WithStyles<typeof toggleButtonStyles>;

const ToggleButtonUnstyled: React.FC<Props> = (props: Props) => {
  const [toggleOn, setToggleOn] = React.useState(oc(props).isToggled(false));

  const handleClick = () => {
    const newToggleState = !toggleOn;
    if (newToggleState) {
      setToggleOn(true);
      props.onToggleOn();
    } else {
      setToggleOn(false);
      props.onToggleOff();
    }
  };
  return (
    <ButtonAtom
      addClass={toggleOn ? props.classes.toggleOn : props.classes.toggle}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: props.iconName }}
      id={props.id}
      toggle={toggleOn}
      onClick={handleClick}
    />
  );
};

export const ToggleButton = withStyles(toggleButtonStyles)(ToggleButtonUnstyled) as React.FC<
  IToggleButtonProps
>;
