import { formatCurrency } from 'features/proposal/utils';
import * as React from 'react';
import { DialogProps } from 'styles';

import { ConfigCardConfirmationDialog } from './ConfigCardConfirmationDialog';

export const getDisplayAmount = (amount: string, isFocused: boolean) => {
  if (amount.trim() === '') {
    return '';
  }
  const formattedAmount = formatCurrency(amount, 0);
  return !isFocused && formattedAmount ? formattedAmount : amount;
};

export const applyConfigurationCardWithDialog = (
  onApply: () => void,
  onCancelDialog: () => void,
  dialogContext: {
    isDialogOpened: boolean;
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  }
) => {
  const dialog = <ConfigCardConfirmationDialog onApply={onApply} onCancel={onCancelDialog} />;
  const dialogProps: DialogProps = {
    providedDialog: dialog,
  };
  dialogContext.openDialog(dialogProps);
};
