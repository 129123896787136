import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import { Address as AddressType } from 'features/customer/types';
import {
  domainNameFormatRegExp,
  domainTestEnvironmentIncludes,
  microsoftDomainNameRegExp,
  validGuid,
} from 'features/proposal/utils';
import { OrganizationSimple } from 'generated/graphql';
import i18next from 'i18n';
import { oc } from 'ts-optchain';

export enum GqlVerifyInvitedUserErrors {
  ViralTenant = 'TENANT_VIRAL',
  MsEmail = 'INVALID_EMAIL_MICROSOFT',
  InvalidEmail = 'INVALID_EMAIL',
  NotInTenant = 'EMAIL_NOT_IN_TENANT',
  TenantKnown = 'TENANT_KNOWN',
}

export const invalidTenantFormatMessage = () =>
  i18next.t('quote::Enter a valid tenant domain or id.');

export const invalidTestTenantFormatMessage = () =>
  i18next.t('quote::Test environments require that the domain include "testtest" in the domain.');

export const onValidateTenant = (value: string, usingTestHeaders?: boolean) =>
  ((domainNameFormatRegExp.test(value) && !microsoftDomainNameRegExp.test(value)) ||
    validGuid.test(value)) &&
  (!usingTestHeaders ||
    (!validGuid.test(value) &&
      usingTestHeaders &&
      value.toLowerCase().includes(domainTestEnvironmentIncludes)));

export const getSelectableOrganizations = (eligibleParentAccounts: OrganizationSimple[]) => {
  const selectableOrganizations =
    !!eligibleParentAccounts.length &&
    eligibleParentAccounts.map(organization => {
      const { address } = organization;
      const country = oc(address).marketInfo.display() || address.country;

      const addressType: AddressType = {
        addressLine1: getValueOrUndefined(address.addressLine1),
        addressLine2: getValueOrUndefined(address.addressLine2),
        addressLine3: getValueOrUndefined(address.addressLine3),
        city: getValueOrUndefined(address.city),
        region: getValueOrUndefined(address.region),
        country,
        postalCode: getValueOrUndefined(address.postalCode),
        companyName: getValueOrUndefined(address.companyName),
      };

      return {
        address: addressType,
        id: organization.id,
        name: organization.name,
        doingBusinessAs: getValueOrUndefined(organization.tradeName),
      };
    });
  return selectableOrganizations;
};
