import { SectionSeparator, TextBody, TextBodySmall } from 'components/ions';
import { CrmLead } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import { EnrollmentNumberField } from './EnrollmentNumberField';
import { salesBodyStyles } from './SalesBody.styles';

export interface SalesBodyProps {
  isLegacy: boolean;
  modernOfficeEnabled?: boolean;
  crmLead?: CrmLead;
  isQuoteReadOnly: boolean;
  quoteId: string;
  etag: string;
  enrollmentNumber?: string;
  /**
   * EnrollmentAssembly feature not available for indirect quotes
   */
  isQuoteIndirect?: boolean;
}

type Props = SalesBodyProps & WithStyles<typeof salesBodyStyles>;

const SalesBodyUnstyled: React.FC<Props> = props => {
  const { quoteId, etag, crmLead, isLegacy, isQuoteReadOnly, enrollmentNumber, classes } = props;
  const salesAccount = oc(crmLead).salesAccount();
  const { t } = useTranslation();

  const salesBlock: JSX.Element = (
    <div>
      <TextBodySmall dataAutomationId="salesBodySalesAccountText">
        {t('quote::Sales account')}
      </TextBodySmall>
      {salesAccount ? (
        <TextBody
          addClass={`${classes.newline} ${classes.companyName}`}
          dataAutomationId="salesAccountCompany"
        >
          {oc(salesAccount).address.companyName('')}
        </TextBody>
      ) : (
        <TextBody addClass={classes.newline}>{t('quote::Set CRM ID to retrieve')}</TextBody>
      )}
    </div>
  );

  const enrollmentNumberSearch = (
    <EnrollmentNumberField
      crmLead={crmLead}
      enrollmentNumber={enrollmentNumber}
      etag={etag}
      isLegacy={isLegacy}
      isQuoteIndirect={props.isQuoteIndirect}
      isQuoteReadOnly={isQuoteReadOnly}
      modernOfficeVersion={props.modernOfficeEnabled}
      quoteId={quoteId}
    />
  );

  let customerBody: JSX.Element;
  if (isLegacy) {
    customerBody = enrollmentNumberSearch;
  } else {
    customerBody = salesAccount ? (
      <>
        {salesBlock}
        <SectionSeparator addClass={classes.enrollmentSeparator} />
        {enrollmentNumberSearch}
      </>
    ) : (
      <> {enrollmentNumberSearch} </>
    );
  }

  return <div>{customerBody}</div>;
};

export const SalesBody = withStyles(salesBodyStyles)(SalesBodyUnstyled);
