import { detailsListStyles } from '../styles';

export const styles = {
  rowHoverStyles: {
    '&:hover': {
      background: '#b3cde0',
    },
  },
  selectedStyles: {
    background: '#005b96',
    color: 'white',
  },
  detailsListStyles,
};
