import { CatalogPageNegotiatedTerm, CatalogPageProduct } from 'features/catalog/types';
import { getUserPreferences } from 'features/user/selectors';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { ProductFamily } from 'services/catalog/types';
import { RootState } from 'store/types';
import { ThemeKey } from 'styles';

import { CatalogSectionRowDetailsTile, CatalogSectionRowSmallTile } from './CatalogSectionRowTiles';
import { catalogSectionRowStyles } from './shared.styles';

export type CatalogSectionRowProducts = [
  CatalogPageProduct | CatalogPageNegotiatedTerm,
  (CatalogPageProduct | CatalogPageNegotiatedTerm)?,
  (CatalogPageProduct | CatalogPageNegotiatedTerm)?,
  (CatalogPageProduct | CatalogPageNegotiatedTerm)?
];

/**
 * Props for the section row
 * @prop {CatalogSectionRowProducts} products - array of 1-4 {CatalogPageProduct} to be displayed in the row
 * @prop {string} selected - Optional prop only passed if a product tile has been selected
 * @prop {function} tileOnClick - Function to call when clicking on a product tile
 * @prop {function} detailsOnClose - Function to call when closing the detailed product tile
 */
export interface CatalogSectionRowProps {
  products: CatalogSectionRowProducts;
  productFamily: ProductFamily;
  selected?: string;
  tileOnClick: (id: string) => void;
  detailsOnClose: () => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    theme: getUserPreferences(state).theme,
  };
};

type Props = CatalogSectionRowProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof catalogSectionRowStyles>;

export const getProductRows = (
  products: (CatalogPageProduct | CatalogPageNegotiatedTerm)[],
  productFamily: ProductFamily,
  detailsOnClose: () => void,
  tileOnClick: (id: string) => void,
  selectedProduct?: string
) => {
  let productRows: JSX.Element[] = [];
  for (let i = 0; i < products.length; i += 4) {
    let row = products.slice(i, i + 4);
    row.length = 4;
    const productRow: CatalogSectionRowProducts = [row[0], row[1], row[2], row[3]];

    productRows.push(
      <CatalogSectionRow
        detailsOnClose={detailsOnClose}
        key={i}
        productFamily={productFamily}
        products={productRow}
        selected={selectedProduct}
        tileOnClick={tileOnClick}
      />
    );
  }
  return productRows;
};

export const CatalogSectionRowUnstyled: React.FC<Props> = props => {
  const [selectedProduct, setSelectedProduct] = React.useState<
    CatalogPageProduct | CatalogPageNegotiatedTerm | undefined
  >(undefined);
  const rowScrollRef = React.createRef<HTMLDivElement>();
  const isNegotiatedTerms = props.productFamily === ProductFamily.NegotiatedTerms;
  const theme = props.theme as ThemeKey;

  React.useEffect(() => {
    if (props.selected) {
      const foundProduct = props.products.find(product => product && props.selected === product.id);
      setSelectedProduct(foundProduct);
    } else {
      setSelectedProduct(undefined);
    }
  }, [props.selected, props.products]);

  React.useEffect(() => {
    if (selectedProduct && rowScrollRef.current) {
      rowScrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]); // cannot include rowScrollRef in the dependency array or autoscroll is triggered when trying to search

  return (
    <div ref={rowScrollRef}>
      <div className={selectedProduct ? props.classes.tileGridDetails : props.classes.tileGrid}>
        {props.products.map(
          product =>
            product && (
              <CatalogSectionRowSmallTile
                isNegotiatedTerm={isNegotiatedTerms}
                product={product}
                selected={props.selected}
                theme={theme}
                tileOnClick={props.tileOnClick}
              />
            )
        )}
      </div>
      {selectedProduct && (
        <div className={props.classes.details}>
          <CatalogSectionRowDetailsTile
            detailsOnClose={props.detailsOnClose}
            isNegotiatedTerm={isNegotiatedTerms}
            selectedProduct={selectedProduct}
            theme={theme}
          />
        </div>
      )}
    </div>
  );
};

export const CatalogSectionRow = connect(mapStateToProps)(
  withStyles(catalogSectionRowStyles)(CatalogSectionRowUnstyled)
);
