import { External } from 'features/app/components';
import { HelpArticlePopout } from 'features/app/components/HelpPanel/HelpArticlePopout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

// TODO: Comment in for testing
// import { GQLTestComponent } from 'features-apollo/GQLTestComponent';

// Lazily loaded modules...
const Quote = React.lazy(() => import('features-apollo/quote/Quote'));
const Home = React.lazy(() => import('features/home/components/Home'));
const Catalog = React.lazy(() => import('features/catalog/components/Catalog'));
const Customer = React.lazy(() => import('features/ldsscustomer/components/Customer'));
const CrmId = React.lazy(() => import('features/proposal/components/CrmId'));

export const PageContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Switch>
      {/* 
        TODO: Remove when done testing
      <Route
        path="/test"
        render={() => (
            <GQLTestComponent/>
        )}
      /> */}
      <Route component={Quote} path={routes.quote.root} />
      <Route component={Home} path={routes.home.root} />
      <Route component={Catalog} path={routes.catalog.root} />
      <Route component={Customer} path={routes.customer.root} />
      <Route component={CrmId} path={routes.opportunity.root} />
      <Route component={CrmId} path={routes.engagement.root} />
      <Route component={CrmId} path={routes.crmId.root} />
      <Route component={External} path={routes.external.root} />
      <Route component={HelpArticlePopout} path={routes.content.articlePopout} />
      <Route
        path={routes.proposal.root}
        render={props => <Redirect to={routes.quote.forId(props.match.params.id)} />}
      />
      <Route exact path={routes.root} render={() => <Redirect to={routes.home.root} />} />
      <Route exact path={routes.notFound} render={() => <div>{t('Page not found...')}</div>} />
      <Route
        exact
        path={routes.catalog.redirect}
        render={() => <Redirect to={routes.home.catalog} />}
      />
      <Route
        exact
        path={routes.quote.redirect}
        render={() => <Redirect to={routes.home.quotes.root} />}
      />
      <Route
        exact
        path={routes.customer.redirect}
        render={() => <Redirect to={routes.home.customers} />}
      />
      <Route render={() => <Redirect to={routes.root} />} />
    </Switch>
  );
};
