import { ConfigCardButton } from 'features-apollo/quote/components/ConfigCards';
import { QuoteListColumn } from 'features-apollo/quote/components/Lists/types';
import { MonetaryLineItem } from 'generated/graphql';
import React from 'react';
import { oc } from 'ts-optchain';

import { QuoteListRow, SelectionProps } from '../QuoteListRow';
import { LineItemRowProps, ProductLineItemContentAlignment } from '../types';
import { renderPrice, titleFormatter } from '../utils';

export interface MonetaryRowProps extends LineItemRowProps, SelectionProps {
  /**
   * Identifies the line item on the quote line items list.
   */
  lineItem: MonetaryLineItem;
  /**
   * Defines the price listed in the product
   */
  listPrice?: number | null;
  /**
   * Defines the price the customer is getting
   */
  customerPrice?: number | null;
  /**
   * Defines a one-time discount for this product in percentage
   */
  oneTimeDiscount?: number | null;
  /**
   * Total price
   */
  amount?: number | null;
  /**
   * Defines pricing currency
   */
  currency: string;
  /**
   * Defines a custom flex CSS rule per content.
   */
  contentAlignment?: ProductLineItemContentAlignment;
}

const azureMonetaryCreditProductType = 'azuremonetarycredit';

export const MonetaryRow: React.FC<MonetaryRowProps> = props => {
  const topColumns: QuoteListColumn[] = [
    {
      id: 'listrow-name',
      content: titleFormatter(props.productTitle),
      ...oc(props).contentAlignment.name({}),
    },
    {
      id: 'listrow-quantity',
      content: '-',
      ...oc(props).contentAlignment.quantity({}),
    },
    {
      id: 'listrow-list-price',
      content: '-',
      ...oc(props).contentAlignment.listPrice({}),
    },
    {
      id: 'listrow-customer-price',
      content: '-',
      ...oc(props).contentAlignment.customerPrice({}),
    },
    {
      id: 'listrow-discount',
      content: '-',
      ...oc(props).contentAlignment.discount({}),
    },
    {
      id: 'listrow-amount',
      content:
        // We do not want to show the amount value for Azure credits
        props.lineItem.product?.productType.toLowerCase() !== azureMonetaryCreditProductType
          ? renderPrice(props.amount, props.currency)
          : '-',
      ...oc(props).contentAlignment.amount({}),
    },
  ];

  const bottomColumns: QuoteListColumn[] = props.productUnavailable
    ? []
    : [
        {
          id: 'monetary-config-card-button',
          content: (
            <ConfigCardButton
              configuration={props.configuration}
              disabled={props.readOnly}
              isConfigurable={props.configurable}
              lineItem={props.lineItem}
              quoteId={props.quoteId}
            />
          ),
          ...oc(props).contentAlignment.configCardButton({}),
        },
      ];

  return (
    <QuoteListRow
      {...props}
      bottomColumns={bottomColumns}
      isInErrorState={props.errored}
      isInWarnState={props.isInWarnState}
      isSelected={props.selectedIds.includes(props.lineItem.id)}
      lineItem={props.lineItem}
      topColumns={topColumns}
    />
  );
};
