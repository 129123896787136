import { CalloutCard, ComboBox, TextBody, TextboxStandard } from 'components/ions';
import { useDebounce } from 'components/utilities/debounce';
import { DurationUnit, getSingleDuration } from 'components/utilities/duration';
import { closeConfigCard, updateProposalAsync } from 'features/proposal/actions';
import { QualifyingSkuAvailability } from 'features/proposal/components/ConfigCard';
import { startConditionConstants } from 'features/proposal/components/DetailsPane/detailsUtils';
import { getActiveProposal, getBillingCurrency } from 'features/proposal/selectors';
import { Currency } from 'features/proposal/supported-currencies';
import {
  createProposalUpdateRequest,
  formatCurrency,
  isProposalReadOnly,
} from 'features/proposal/utils';
import { ProductFilterContext } from 'microsoft-commerce-product-filters';
import * as moment from 'moment';
import {
  DirectionalHint,
  IComboBox,
  IComboBoxOption,
  IDropdownOption,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product, ProductFamily, ProductReasonCodes } from 'services/catalog/types';
import { LineItem, ProposalUpdateRequest } from 'services/proposal/types';
import { NeedsTranslation, t } from 'services/utils';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';

import { isNegotiatedTerm } from '../ConfigCard/ConfigCardBusinessLogic';
import { monetaryCardStyles } from './MonetaryCard.styles';

export interface MonetaryCardProps {
  amountValue: string;
  goodForValue: string;
  currency: Currency;
  headerText: string;
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  defaultValue?: string;
  onDismiss: () => void;
  onApply: () => void;
  onAmountChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  onAmountErrorChange: (value?: string) => void;
  onGoodForChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  onGoodForErrorChange: (value?: string) => void;
  disableApplyButton: boolean;
  amountErrorMessage?: string;
  goodForErrorMessage?: string;
  reasons?: IDropdownOption[];
  onReasonSelection: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  selectedReasonKey?: string;
  isNegotiatedTerm: boolean;
  isQuoteReadOnly?: boolean;
  termDurations?: IDropdownOption[];
  onDurationSelection: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  selectedDurationKey?: string;
  startCondition?: string;
}

type Props = MonetaryCardProps & WithStyles<typeof monetaryCardStyles>;

export const defaultAmount = '';
export const defaultMinimumAmount = 1;
export const defaultMaximumAmount = 999999999999999;
export const defaultGoodFor = 'P12M';
export const defaultMinimumDuration = 12;
export const defaultMaximumDuration = 36;
const maxAmountDigitsAllowed = 15;

export interface MonetaryCardContainerProps {
  lineItemId: string;
  name: string;
  target: React.RefObject<HTMLSpanElement>;
  hydratedProduct: Product;
  reasons?: ProductReasonCodes[];
}

export const monetaryCardContainerActions = {
  onAmountChange: createStandardAction('AMOUNT_CHANGE')<string>(),
  onAmountError: createStandardAction('AMOUNT_ERROR')<{
    min: number;
    max: number;
    value: string;
  }>(),
  onGoodForChange: createStandardAction('GOOD_FOR')<string>(),
  onGoodForError: createStandardAction('GOOD_FOR_ERROR')<{
    min: number;
    max: number;
    value: string;
  }>(),
  onApply: createStandardAction('APPLY')<string>(),
  onReasonSelection: createStandardAction('REASON_SELECTED')<string>(),
  onDurationSelection: createStandardAction('TERM_DURATION_SELECTED')<string>(),
};

const mapStateToProps = (state: RootState) => {
  return {
    proposal: getActiveProposal(state),
    currency: getBillingCurrency(state),
  };
};

const dispatchProps = {
  onUpdateProposal: (proposalUpdateRequest: ProposalUpdateRequest) => {
    return updateProposalAsync.request(proposalUpdateRequest);
  },
  onDismiss: closeConfigCard,
};

export interface MonetaryCardContainerState {
  amount: string;
  amountError?: NeedsTranslation;
  goodFor: string;
  goodForError?: NeedsTranslation;
  selectedReasonKey?: string;
  selectedDurationKey?: string;
}

type ContainerProps = MonetaryCardContainerProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

export const removeDecimals = (value: string) => {
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    return '';
  }
  return parsed.toString();
};

const errors = {
  numberOnly: t('error::Only numbers are valid.'),
  numberGreaterThanOne: t('error::Value has to be greater than 1.'),
  numberGreaterThanMax: (maxAmount: number) =>
    t('error::Value cannot be more than {{maxAmount}}.', { maxAmount }),
  numberLessThanMin: (minAmount: number) =>
    t('error::Value cannot be less than {{minAmount}}.', { minAmount }),
  invalidMonthValue: t('error::Invalid month value.'),
  lessMonth: (min: number) => t('error::Value cannot be less than {{min}} months.', { min }),
  moreMonth: (max: number) => t('error::Value cannot be more than {{max}} months.', { max }),
};

export const getAmountError = (min: number, max: number, value?: string) => {
  if (!value) {
    return errors.numberOnly;
  }
  if (+value < min) {
    return errors.numberLessThanMin(min);
  }
  if (+value > max) {
    return errors.numberGreaterThanMax(max);
  }
};

export const getGoodForError = (min: number, max: number, value?: string) => {
  if (!value) {
    return errors.invalidMonthValue;
  }
  if (+value < min) {
    return errors.lessMonth(min);
  }
  if (+value > max) {
    return errors.moreMonth(max);
  }
};

export const monetaryCardReducer = (
  state: MonetaryCardContainerState,
  action: ActionType<typeof monetaryCardContainerActions>
): MonetaryCardContainerState => {
  switch (action.type) {
    case getType(monetaryCardContainerActions.onAmountChange): {
      return { ...state, amount: action.payload };
    }
    case getType(monetaryCardContainerActions.onGoodForChange): {
      return { ...state, goodFor: action.payload };
    }

    case getType(monetaryCardContainerActions.onAmountError): {
      const error = getAmountError(action.payload.min, action.payload.max, action.payload.value);
      return { ...state, amountError: error };
    }

    case getType(monetaryCardContainerActions.onGoodForError): {
      const error = getGoodForError(action.payload.min, action.payload.max, action.payload.value);
      return { ...state, goodForError: error };
    }

    case getType(monetaryCardContainerActions.onReasonSelection): {
      return { ...state, selectedReasonKey: action.payload };
    }

    case getType(monetaryCardContainerActions.onDurationSelection): {
      return { ...state, selectedDurationKey: action.payload };
    }

    default:
      return state;
  }
};

const generateInitialState = (initials: {
  initialAmount?: string;
  initialGoodFor?: string;
  selectedReasonKey?: string;
  selectedDurationKey?: string;
}): MonetaryCardContainerState => {
  const goodFor = initials.initialGoodFor || defaultGoodFor;
  const goodForMonths = moment.duration(goodFor).asMonths();
  return {
    amount: initials.initialAmount || defaultAmount,
    goodFor: goodForMonths.toString(),
    selectedReasonKey: initials.selectedReasonKey,
    selectedDurationKey: initials.selectedDurationKey,
  };
};

// TODO: Replace this with actual rules or even better replace the whole component with an existing input component

const MonetaryCardContainer: React.FunctionComponent<ContainerProps> = (props: ContainerProps) => {
  const { hydratedProduct, onUpdateProposal, proposal, name } = props;
  const lineItem = proposal.lineItems.find(
    (lineItem: LineItem) => lineItem.id === props.lineItemId
  );
  if (!lineItem) {
    throw new Error(`No LineItem found with id: ${props.lineItemId}`);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = React.useRef(new ProductFilterContext(hydratedProduct as any));
  const firstQualifyingSku: QualifyingSkuAvailability = ref.current.qualifyingSkuAvailabilities[0];
  if (!firstQualifyingSku) {
    throw new Error('No First qualifying Sku exists');
  }
  const minimumDuration = oc(firstQualifyingSku).Sku.Properties.MinimumDuration(
    defaultMinimumDuration
  );
  const maximumDuration = oc(firstQualifyingSku).Sku.Properties.MaximumDuration(
    defaultMaximumDuration
  );
  const minimumAmount = oc(hydratedProduct).Properties.Amount.Min(defaultMinimumAmount);
  //TODO: cameneks remove this once the downstream service accepts higher numbers and not overflows
  const hardUpperBoundMax = 2147483647;

  const maximumAmount = Math.min(
    oc(hydratedProduct).Properties.Amount.Max(defaultMaximumAmount),
    hardUpperBoundMax
  );

  const buildReasonsOptions = (reasons: ProductReasonCodes[]): IDropdownOption[] => {
    let reasonOptions: IDropdownOption[] = [];
    reasons.forEach(reason => {
      if (reason.Channels.includes('Field')) {
        reasonOptions.push({ text: reason.ReasonName, key: reason.ReasonCode });
      }
    });
    return reasonOptions;
  };

  const { t } = useTranslation();

  const buildDurationOptions = (hydratedProduct: Product) => {
    let durationOptions: IDropdownOption[] = [];
    const isTerm = isNegotiatedTerm(hydratedProduct);
    const durations = oc(hydratedProduct).Properties.Duration() as string[];
    isTerm &&
      !!durations &&
      durations.forEach(durationOption => {
        const years = moment.duration(durationOption).asYears();
        const suffix = years > 1 ? t('years') : t('year');
        durationOptions.push({
          text: `${years} ${suffix}`,
          key: durationOption,
        });
      });
    return durationOptions;
  };

  const initialPurchaseTargetTermUnits = oc(lineItem).purchaseInstruction.purchaseTermUnits();
  const reasonOptions = props.reasons && buildReasonsOptions(props.reasons);
  const durationOptions = buildDurationOptions(props.hydratedProduct);
  const selectedCodeKey =
    reasonOptions && reasonOptions.length === 1
      ? (reasonOptions[0].key as string)
      : lineItem.reasonCode;
  const selectedDurationKey =
    (durationOptions.length && (lineItem.duration || (durationOptions[0].key as string))) ||
    undefined;
  const [state, dispatch] = React.useReducer(
    monetaryCardReducer,
    {
      initialAmount: initialPurchaseTargetTermUnits,
      initialGoodFor: lineItem.duration,
      selectedReasonKey: selectedCodeKey,
      selectedDurationKey: selectedDurationKey,
    },
    generateInitialState
  );

  const onAmountChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(monetaryCardContainerActions.onAmountChange(value));
    }
  };
  const onGoodForChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(monetaryCardContainerActions.onGoodForChange(value));
    }
  };

  const onApply = () => {
    if (!state.goodFor || isNaN(+state.goodFor) || state.goodFor.trim() === '') {
      throw new Error(`goodFor is not a number, it is , ${state.goodFor}`);
    }
    const displaySkuAvailabilities = hydratedProduct.DisplaySkuAvailabilities;
    if (!displaySkuAvailabilities.length || !displaySkuAvailabilities[0].Availabilities.length) {
      throw new Error('display sku availabilities are empty');
    }
    props.onDismiss();
    const firstSkuAvailability = displaySkuAvailabilities[0];

    const singleDuration = getSingleDuration(parseInt(state.goodFor), DurationUnit.months);
    const firstAvailability = firstSkuAvailability.Availabilities[0];
    const productFamily = oc(lineItem).productIdentifier.productFamily();
    const startCondition = oc(hydratedProduct).Properties.StartCondition();
    const newLineItem: LineItem = {
      ...lineItem,
      purchaseInstruction: { ...lineItem.purchaseInstruction, purchaseTermUnits: state.amount },
      productIdentifier: {
        ...lineItem.productIdentifier,
        skuId: firstSkuAvailability.Sku.SkuId,
        availabilityId: firstAvailability.AvailabilityId,
        availabilityTermId: firstAvailability.Terms && firstAvailability.Terms[0].TermId,
        action: 'purchase',
      },
      reasonCode: state.selectedReasonKey,
      isReadyForPricing: true,
      duration: state.selectedDurationKey || singleDuration,
      isStartOnFirstOfMonth: !!(
        productFamily &&
        productFamily.toLowerCase() === ProductFamily.NegotiatedTerms.toLowerCase() &&
        startCondition &&
        startCondition.toLowerCase() === startConditionConstants.firstOfThisMonth
      ),
    };
    const proposalUpdateRequest = createProposalUpdateRequest(newLineItem, proposal);
    onUpdateProposal(proposalUpdateRequest);
  };

  const onAmountErrorChange = (value?: string) => {
    if (value !== undefined) {
      dispatch(
        monetaryCardContainerActions.onAmountError({
          min: minimumAmount,
          max: maximumAmount,
          value,
        })
      );
    }
  };

  const onGoodForErrorChange = (value?: string) => {
    if (value !== undefined) {
      dispatch(
        monetaryCardContainerActions.onGoodForError({
          min: minimumDuration,
          max: maximumDuration,
          value,
        })
      );
    }
  };

  const onReasonSelection = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    option && dispatch(monetaryCardContainerActions.onReasonSelection(option.key.toString()));
  };

  const onDurationSelection = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    option && dispatch(monetaryCardContainerActions.onDurationSelection(option.key.toString()));
  };

  //selected duration is only applicable for monetary term
  const goodForNotValid =
    !selectedDurationKey && !!getGoodForError(+minimumDuration, +maximumDuration, state.goodFor);
  const amountNotValid = !!getAmountError(
    +minimumAmount,
    maximumAmount && +maximumAmount,
    state.amount
  );
  const disableApplyButton = amountNotValid || goodForNotValid;

  const monetaryCardProps: MonetaryCardProps = {
    amountValue: state.amount,
    currency: props.currency,
    goodForValue: state.goodFor,
    lineItemId: props.lineItemId,
    disableApplyButton,
    onDismiss: props.onDismiss,
    target: props.target,
    headerText: name,
    onAmountChange,
    onAmountErrorChange: onAmountErrorChange,
    onGoodForChange,
    onGoodForErrorChange: onGoodForErrorChange,
    onApply,
    isQuoteReadOnly: isProposalReadOnly(props.proposal),
    amountErrorMessage: state.amountError && t(state.amountError.value, state.amountError.options),
    goodForErrorMessage:
      state.goodForError && t(state.goodForError.value, state.goodForError.options),
    reasons: reasonOptions || undefined,
    onReasonSelection: onReasonSelection,
    selectedReasonKey: state.selectedReasonKey,
    isNegotiatedTerm: isNegotiatedTerm(props.hydratedProduct),
    termDurations: buildDurationOptions(props.hydratedProduct),
    onDurationSelection: onDurationSelection,
    selectedDurationKey: state.selectedDurationKey,
    startCondition: props.hydratedProduct.Properties.StartCondition,
  };
  return <MonetaryCard {...monetaryCardProps} />;
};

const inputDebounceMilliseconds = 400;

export const MonetaryCardUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const applyButtonStrings = { text: t('quote::Apply'), key: t('quote::Apply') };
  const [goodForFocused, setGoodForFocused] = React.useState(false);
  const [amountFocused, setAmountFocused] = React.useState(false);

  const goodForAmount = goodForFocused ? props.goodForValue : `${props.goodForValue} months`;
  const formattedValue = !props.amountErrorMessage && formatCurrency(props.amountValue, 0);
  const amount = !amountFocused && formattedValue ? formattedValue : props.amountValue;
  const { onAmountErrorChange } = props;
  const amount$ = useDebounce(inputDebounceMilliseconds);
  const months$ = useDebounce(inputDebounceMilliseconds);

  const onMonetaryCardGoodForChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      const withoutDecimals = removeDecimals(value);
      months$.next(() => props.onGoodForErrorChange(withoutDecimals));
      props.onGoodForChange(event, withoutDecimals);
    }
  };

  const onGoodForBlur = () => {
    setGoodForFocused(false);
  };
  const onGoodForFocus = () => {
    setGoodForFocused(true);
  };

  const onAmountFocus = () => {
    setAmountFocused(true);
  };
  const onAmountBlur = () => {
    setAmountFocused(false);
  };

  const onMonetaryCardAmountChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      const limitedDigits = value.slice(0, maxAmountDigitsAllowed);
      const formatted = removeDecimals(limitedDigits);

      amount$.next(() => onAmountErrorChange(formatted));
      props.onAmountChange(event, formatted);
    }
  };

  const amountLabel = `${t('quote::Amount')} (${props.currency})`;

  return (
    <CalloutCard
      applyButtonDisabled={props.disableApplyButton}
      applyButtonStrings={applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      dataAutomationId="monetaryCard"
      directionalHint={DirectionalHint.rightCenter}
      headerText={props.headerText}
      id={props.lineItemId}
      isBeakVisible={true}
      isReadOnly={!!props.isQuoteReadOnly}
      maxHeight={750}
      maxWidth={400}
      minWidth={275}
      target={props.target}
      onApply={props.onApply}
      onDismiss={props.onDismiss}
    >
      <div className={props.classes.container}>
        <div className={props.classes.amountContainer}>
          <TextboxStandard
            ariaLabel={amountLabel}
            autoFocus={true}
            dataAutomationId="monetaryCardEnterAmount"
            disabled={props.isQuoteReadOnly}
            errorMessage={props.amountErrorMessage}
            errorMessageStyle={props.classes.errorMessageStyle}
            label={amountLabel}
            placeholder={t('quote::Enter amount')}
            required
            value={amount}
            onBlur={onAmountBlur}
            onChange={onMonetaryCardAmountChange}
            onFocus={onAmountFocus}
          />
        </div>
        {!props.reasons && (
          <div className={props.classes.startingInformation}>
            <div>
              <TextBody addClass={props.classes.emphasisText}>{t('quote::Starting')}</TextBody>
            </div>
            <div>
              <TextBody>
                {props.startCondition === startConditionConstants.firstOfThisMonth
                  ? t('quote::First of this month')
                  : t('quote::At order acceptance')}
              </TextBody>
            </div>
          </div>
        )}
        <div className={props.classes.goodForContainer}>
          {!props.isNegotiatedTerm ? (
            <TextboxStandard
              ariaLabel={t('quote::Good For')}
              dataAutomationId="monetaryCardGoodFor"
              errorMessage={props.goodForErrorMessage}
              errorMessageStyle={props.classes.errorMessageStyle}
              label={t('quote::Good For')}
              readOnly={props.isQuoteReadOnly}
              required
              value={goodForAmount}
              onBlur={onGoodForBlur}
              onChange={onMonetaryCardGoodForChange}
              onFocus={onGoodForFocus}
            />
          ) : (
            <ComboBox
              ariaLabel={t('quote::Good For')}
              disabled={props.isQuoteReadOnly}
              id="monetaryCardGoodFor"
              label={t('quote::Good For')}
              maxHeight={350}
              options={props.termDurations || []}
              required
              selectedKey={
                props.termDurations && props.termDurations.length === 1
                  ? (props.termDurations[0].key as string)
                  : props.selectedDurationKey
              }
              onChange={props.onDurationSelection}
            />
          )}
        </div>
        {props.reasons && (
          <div className={props.classes.reasonsContainer}>
            <div>
              <ComboBox
                disabled={props.isQuoteReadOnly}
                id="reasonForGivingCredit"
                label={t('quote::Reason for giving credit')}
                maxHeight={350}
                options={props.reasons}
                required={true}
                selectedKey={
                  props.reasons && props.reasons.length === 1
                    ? (props.reasons[0].key as string)
                    : props.selectedReasonKey
                }
                onChange={props.onReasonSelection}
              />
            </div>
          </div>
        )}
      </div>
    </CalloutCard>
  );
};

export const MonetaryCard = withStyles(monetaryCardStyles)(MonetaryCardUnstyled);
export const ConnectedMonetaryCard = connect(mapStateToProps, dispatchProps)(MonetaryCardContainer);
