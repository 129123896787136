import {
  CalloutCard,
  ComboBox,
  InfoButton,
  Label,
  LinkExternal,
  TextBody,
  TextboxStandard,
} from 'components';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import {
  financeTermCardStyles,
  getApplyOptions,
  getFinanceableLineItems,
  getFinanceableProducts,
} from 'features-apollo/quote/components/ConfigCards/Cards/FinanceTermConfigurationCard';
import { getSimpleTermId } from 'features-apollo/quote/components/ConfigCards/SimpleTermsCard/utils';
import { QuoteLineItem } from 'features-apollo/quote/components/types';
import {
  getSelectedLineItem,
  getValueOrUndefined,
  validGuid,
} from 'features-apollo/quote/components/utils';
import { FinanceAssociationIdType, FinancingTermLineItem, Product } from 'generated/graphql';
import gql from 'graphql-tag';
import { DirectionalHint, IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';

import { useMutation } from '@apollo/react-hooks';

export interface FinanceTermCardProps {
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  directionalHint?: DirectionalHint;
  hidden?: boolean;
  title?: string;
  onDismiss: () => void;
}

export interface FinanceTermCardState {
  termId: string;
  financeableLineItems: QuoteLineItem[];
  financeableProducts: Product[];
  selectedType?: FinanceAssociationIdType;
  selectedKey?: string;
  initialStateSet: boolean;
  lineItem?: FinancingTermLineItem;
}

export const ApplyFinanceTermConiguration = gql`
  mutation ApplyConfigurationFinance(
    $quote: QuoteMutationInput!
    $configuration: ApplyConfigurationFinanceInput!
  ) {
    applyConfigurationFinance(quote: $quote, configuration: $configuration) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

type Props = FinanceTermCardProps & WithStyles<typeof financeTermCardStyles>;

export const FinacneTermCardUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, lineItemId, target, directionalHint, hidden, onDismiss, title } = props;
  let readOnly = true;
  const { activeQuote } = React.useContext(ActiveQuoteContext);
  const [applyFinance] = useMutation(ApplyFinanceTermConiguration);

  const [state, setState] = React.useState<FinanceTermCardState>({
    termId: '',
    financeableLineItems: [],
    financeableProducts: [],
    initialStateSet: false,
  });

  const { t } = useTranslation();

  let billingCurrency = 'USD';

  if (activeQuote) {
    readOnly = activeQuote.readOnly;
    billingCurrency = activeQuote.billingCurrency;
    const lineItem = getSelectedLineItem(activeQuote, lineItemId);
    if (lineItem && lineItem.__typename === 'FinancingTermLineItem' && !state.initialStateSet) {
      const products = getFinanceableProducts(lineItem);
      const applicableItems = products && getFinanceableLineItems(products, activeQuote.lineItems);
      const termId = getSimpleTermId(lineItem);
      const associatedItem = getValueOrUndefined(lineItem.associatedLineItem);
      setState({
        termId,
        financeableProducts: products,
        financeableLineItems: applicableItems,
        selectedKey: associatedItem && associatedItem.id,
        selectedType: associatedItem
          ? FinanceAssociationIdType.LineItem
          : FinanceAssociationIdType.Product,
        lineItem: lineItem,
        initialStateSet: true,
      });
    }
  }
  const appliesToExisting = state.financeableLineItems.length > 0;
  const termIdError = state.termId && !validGuid.test(state.termId);
  const termIdErrorMessage = termIdError ? t('quote::Term ID is in an invalid format') : undefined;

  const onChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newTermId?: string
  ) => {
    if (newTermId !== undefined) {
      setState({ ...state, termId: newTermId });
    }
  };

  const onComboSelect = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    option && setState({ ...state, selectedKey: option.key.toString(), selectedType: option.data });
  };

  const applyButtonDisabled = !state.termId || termIdError || !state.lineItem || !state.selectedKey;
  const applyFinanceConfiguration = () => {
    if (!activeQuote || !state.lineItem || !state.selectedType || applyButtonDisabled) {
      return;
    }
    applyFinance({
      variables: {
        quote: {
          id: activeQuote.id,
          etag: activeQuote.etag,
        },
        configuration: {
          lineItemId: state.lineItem.id,
          termId: state.termId,
          associatedId: state.selectedKey,
          associatedType: state.selectedType,
        },
      },
    });
    onDismiss();
  };

  const onClose = () => {
    setState({
      ...state,
      initialStateSet: false,
    });
    onDismiss();
  };

  const getInputLabel = () => (
    <div className={classes.termIdLabel}>
      <Label className={classes.label} htmlFor="financeTermsTermId" required>
        {t('quote::Term ID')}
      </Label>
      <InfoButton
        ariaLabel={t('quote::Open information about the term id')}
        calloutProps={{
          closeButtonAriaLabel: t('Close'),
          headline: t('quote::Term ID'),
          maxWidth: 343,
        }}
        id="term-id-info-button"
      >
        <TextBody>
          {t('quote::A Term ID is used to associate a specific amendment to this quote.')}
        </TextBody>
        <TextBody>
          {t(
            'quote::You can contact your finance desk manager to request a Term ID specific for Financing Terms.'
          )}
        </TextBody>
        <TextBody>
          <Trans ns="quote">
            Visit{' '}
            <LinkExternal
              addClass={classes.infoLink}
              dataAutomationId="financingDeskContactsLink"
              displayText="aka.ms/FinancingDeskContacts"
              href="https://aka.ms/FinancingDeskContacts"
              size="medium"
              onClick={() =>
                loggerService.log({
                  name: 'FinanceTermsCard - FinancingDeskContacts link is clicked',
                })
              }
            />{' '}
            to learn more.
          </Trans>
        </TextBody>
      </InfoButton>
    </div>
  );

  const dropDownLabel = appliesToExisting ? t('quote::Applies to') : t('quote::Apply and add');

  const options = getApplyOptions(
    state.financeableLineItems,
    state.financeableProducts,
    billingCurrency
  );

  return hidden ? null : (
    <CalloutCard
      applyButtonDisabled={applyButtonDisabled}
      applyButtonStrings={{ ariaLabel: t('quote::Apply'), text: t('quote::Apply') }}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      directionalHint={directionalHint ? directionalHint : DirectionalHint.rightCenter}
      headerText={title || t('quote::Financing Terms')}
      id={lineItemId}
      isBeakVisible={true}
      isReadOnly={readOnly}
      maxHeight={750}
      maxWidth={400}
      minWidth={325}
      target={target}
      onApply={applyFinanceConfiguration}
      onDismiss={onClose}
    >
      <div className={classes.termIdContainer}>
        <TextboxStandard
          ariaLabel={t('quote::Term ID')}
          dataAutomationId="financeTermsTermId"
          errorMessage={termIdErrorMessage}
          id="financeTermsTermId"
          readOnly={readOnly}
          value={state.termId}
          onChange={onChange}
          onRenderLabel={getInputLabel}
        />
      </div>
      <div className={classes.inputContainer}>
        {' '}
        <ComboBox
          disabled={readOnly}
          id="applyTo"
          label={dropDownLabel}
          maxHeight={750}
          options={options}
          required={true}
          selectedKey={state.selectedKey}
          onChange={onComboSelect}
        />
      </div>
      <LinkExternal
        addClass={classes.link}
        dataAutomationId="learnAboutFinancingLink"
        displayText={t('quote::Learn more about Financing Terms')}
        href={
          'https://msit.microsoftstream.com/embed/video/d865413a-c8ca-47eb-bb83-15572b5bb312?autoplay=false&amp;showinfo=true'
        }
      />
    </CalloutCard>
  );
};

export const FinanceTermCard = withStyles(financeTermCardStyles)(FinacneTermCardUnstyled);
