import { TestHeaderConfig } from 'services/types';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface ApprovalConfig extends TestHeaderConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Prod]: 'https://approval.l2o.microsoft.com/token/v5/approvals',
  [Environment.Int]: 'https://approval.int.l2o.microsoft.com/token/v5/approvals',
};

export const commentLengthLimit = 1000;
