import { LinkExternal, TextBodySmall } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { InformationalTile } from './InformationalTile';

const AZURE_DIRECTORY_LINK =
  'https://docs.microsoft.com/en-us/azure/active-directory/users-groups-roles/domains-admin-takeover';

const styles = (theme: ThemeProps) => ({
  additionalContentContainer: { '& > *': { marginTop: 12 } },
  subtitle: { fontWeight: theme.fonts.fontWeights.semiBold },
  text: { display: 'block', marginTop: 4 },
});

type Props = { forPartnerLookup?: boolean } & WithStyles<typeof styles>;

export const TenantViralInfoTileUnstyled: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation();

  const additionalInfo = (
    <>
      <div className={classes.additionalContentContainer}>
        <div>
          <TextBodySmall addClass={classes.subtitle}>
            {t('quote::Domain take-over needed')}
          </TextBodySmall>
          <TextBodySmall addClass={classes.text}>
            {t(
              'quote::You may need to instruct your customer to do a domain take-over following the directions in the below article.'
            )}
          </TextBodySmall>
          <LinkExternal
            addClass={classes.text}
            displayText={t(
              'quote::Take over an unmanaged directory as administrator in Azure Active Directory'
            )}
            href={AZURE_DIRECTORY_LINK}
          />
        </div>
      </div>
    </>
  );

  return (
    <InformationalTile
      additionalContent={additionalInfo}
      description={t(
        'quote::The associated tenant entered does not have an administrator. This will require further action prior to creating a billing account'
      )}
      iconName="UserRemove"
      iconPredefinedStyle="error"
      title={t('quote::Unmanaged tenant')}
    />
  );
};

export const TenantViralInfoTile = withStyles(styles)(TenantViralInfoTileUnstyled);
TenantViralInfoTile.displayName = 'TenantViralInfoTile';
