import { ThemeProps } from 'styles';

import { TextProps } from './Content';

export const textBodyStyles = (theme: ThemeProps) => {
  const fontWeight = (props: TextProps) => ({
    fontWeight: props.emphasized
      ? theme.fonts.fontWeights.semiBold
      : theme.fonts.fontWeights.regular,
  });

  const bodyTextStyles = (props: TextProps) => {
    let color;
    switch (props.predefinedStyle) {
      case 'approve':
        color = theme.palette.approveText;
        break;
      case 'danger':
        color = theme.palette.dangerText;
        break;
      case 'warning':
        color = theme.palette.warningText;
        break;
      default:
        color = theme.palette.text;
        break;
    }
    return { ...fontWeight(props), color };
  };

  const navbarTextStyles = (props: TextProps) => ({
    ...fontWeight(props),
    fontSize: theme.fonts.fontSizes.larger,
    padding: '9px 0',
    whiteSpace: 'nowrap',
  });

  return {
    bodySmallStyles: {
      fontSize: theme.fonts.fontSizes.small,
      fontWeight: theme.fonts.fontWeights.regular,
    },
    container: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    currencyStyles: {
      color: theme.palette.textTertiary,
      fontSize: theme.fonts.fontSizes.tiny,
      fontWeight: theme.fonts.fontWeights.regular,
    },
    watermarkStyles: {
      fontSize: theme.fonts.fontSizes.medium,
      fontWeight: theme.fonts.fontWeights.regular,
      fontStyle: theme.fonts.fontStyles.italic,
      color: theme.palette.textTertiary,
    },
    watermarkSmallStyles: {
      fontSize: theme.fonts.fontSizes.small,
      fontWeight: theme.fonts.fontWeights.regular,
      fontStyle: theme.fonts.fontStyles.italic,
      color: theme.palette.textSecondary,
    },
    navigationPrimaryStyles: {
      fontSize: theme.fonts.fontSizes.large,
      fontWeight: theme.fonts.fontWeights.regular,
    },
    navigationSecondaryStyles: {
      fontSize: theme.fonts.fontSizes.medium,
      fontWeight: theme.fonts.fontWeights.regular,
    },
    navigationSecondarySelectedStyles: {
      fontSize: theme.fonts.fontSizes.medium,
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    bodyAsteriskStyles: {
      fontSize: theme.fonts.fontSizes.medium,
      fontWeight: theme.fonts.fontWeights.regular,
    },
    asteriskStyles: {
      color: theme.palette.dangerText,
      fontWeight: theme.fonts.fontWeights.regular,
    },
    priceStyles: {
      fontWeight: theme.fonts.fontWeights.semiBold,
      fontSize: theme.fonts.fontSizes.large,
    },
    bodyStyles: (props: TextProps) => ({
      ...bodyTextStyles(props),
      fontSize: theme.fonts.fontSizes.medium,
    }),
    bodyLargeStyles: (props: TextProps) => ({
      ...bodyTextStyles(props),
      fontSize: theme.fonts.fontSizes.large,
    }),
    bodyXLargeStyles: (props: TextProps) => ({
      ...bodyTextStyles(props),
      fontSize: theme.fonts.fontSizes.xLarge,
    }),
    navBarStyles: (props: TextProps) => navbarTextStyles(props),
    navBarPrimaryStyles: (props: TextProps) => ({
      ...navbarTextStyles(props),
      color: theme.palette.titleBarPrimaryText,
    }),
  };
};
