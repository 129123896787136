import { SmallTile } from 'components';
import { CatalogPageNegotiatedTerm, CatalogPageProduct } from 'features/catalog/types';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeKey } from 'styles';

import { catalogSectionRowTilesStyles } from './CatalogSectionRowTiles.styles';
import {
  buildTagsString,
  defaultImage,
  renderFavoriteButton,
} from './CatalogSectionRowTiles.utils';

export interface CatalogSectionRowSmallTileProps {
  theme: ThemeKey;
  product: CatalogPageProduct | CatalogPageNegotiatedTerm;
  tileOnClick: (id: string) => void;
  isNegotiatedTerm: boolean;
  selected?: string;
}
type SmallProps = CatalogSectionRowSmallTileProps & WithStyles<typeof catalogSectionRowTilesStyles>;

export const CatalogSectionRowSmallTileUnstyled: React.FC<SmallProps> = props => {
  const { theme, product, tileOnClick, isNegotiatedTerm, selected, classes } = props;

  let favoriteButton = undefined;
  let tags: string | undefined = '';
  if (isNegotiatedTerm) {
    tags = product.description;
  } else {
    const catalogProduct = product as CatalogPageProduct;
    tags = catalogProduct.categories && buildTagsString(catalogProduct.categories);
    favoriteButton = <div>{renderFavoriteButton(catalogProduct, classes)}</div>;
  }
  const smallTileProps = {
    button: favoriteButton,
    id: product.id,
    imageDescription: product.title,
    imageUrl:
      (product.images.medium && product.images.medium.Uri) ||
      (product.images.small && product.images.small.Uri) ||
      defaultImage(theme),
    key: product.id,
    selected: selected === product.id,
    tags,
    title: product.title,
    onClick: () => tileOnClick(product.id),
  };
  return <SmallTile {...smallTileProps} />;
};

export const CatalogSectionRowSmallTile = withStyles(catalogSectionRowTilesStyles)(
  CatalogSectionRowSmallTileUnstyled
);
