import { Availability, CatalogAction, FilteredSkus, Sku, Term } from 'generated/graphql';
import loggerService from 'services/logger-service';

export const getAllTermsFromTheSku = (sku: Sku) =>
  sku.availabilities.reduce<Term[]>(
    (terms, availability) => (availability.terms ? terms.concat(availability.terms) : terms),
    []
  );

export const flatSkuInfoList = (skuInformations: FilteredSkus[]) =>
  skuInformations.reduce<Sku[]>((flattened, skuList) => flattened.concat(skuList.skus), []);

export const getMaxTermCountAcrossSkus = (skus: Sku[]) => {
  let maxTermCount = 0;
  skus.forEach(sku => {
    maxTermCount = Math.max(maxTermCount, getAllTermsFromTheSku(sku).length);
  });
  return maxTermCount;
};

export const buildTermDescriptionDropdownOptions = (sku: Sku) => {
  const terms = getAllTermsFromTheSku(sku);
  return terms.map(term => {
    return { key: term.termId, text: term.description };
  });
};

export const getAllAvailabilities = (skus: Sku[]) => {
  let availabilities: Availability[] = [];
  skus.forEach(sku => {
    availabilities = availabilities.concat(sku.availabilities);
  });
  return availabilities;
};

export const getMatchedAvailability = (
  skuId: string,
  termId: string,
  skus: Sku[],
  cardNameForError: string
) => {
  const matchedSku = skus.find(sku => sku.skuId === skuId);
  if (!matchedSku) {
    throw new Error('no sku matched');
  }
  let matchedAvailabilities: Availability[] = [];
  matchedSku.availabilities.forEach(availability => {
    if (availability.terms && availability.terms.some(term => term.termId === termId)) {
      matchedAvailabilities.push(availability);
    }
  });
  if (matchedAvailabilities.length === 0) {
    loggerService.error({
      exception: {
        name: `${cardNameForError} - No Availability on Apply`,
        message: 'No Availability is found',
      },
    });
  } else if (matchedAvailabilities.length > 1) {
    loggerService.error({
      exception: {
        name: `${cardNameForError} - Multiple Availability on Apply`,
        message: `${matchedAvailabilities.length} is found`,
      },
    });
  } else {
    return matchedAvailabilities[0];
  }
};

export const isAutoRenewable = (skus: Sku[]) => {
  if (skus && skus.length && skus[0] && skus[0].availabilities && skus[0].availabilities.length) {
    return skus[0].availabilities[0].isAutoRenewable;
  }
  return false;
};

export const filterOutSkus = (skus: Sku[]) => {
  return skus.filter(sku => {
    const isNotPrivate = !sku.isPrivate;
    const hasActionOrConsume = sku.availabilities.some(
      availability =>
        availability.actions.includes(CatalogAction.Consume) ||
        availability.actions.includes(CatalogAction.Purchase)
    );
    return isNotPrivate && hasActionOrConsume;
  });
};
