import axios from 'axios';
import { getAuthHeader, getTestHeader, TokenAuthorities } from 'services/utils';

import { ConstraintsConfig, endpoints } from './config';
import { ValidateConstraintsResponse, ValidateQuantity } from './types';

export async function getHeaders(config: ConstraintsConfig): Promise<Record<string, string>> {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'api-version': '2019-11-07',
    'Content-Type': ' application/json',
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function validateQuantityConstraints(
  validateQuantity: ValidateQuantity,
  config: ConstraintsConfig
) {
  const headers = await getHeaders(config);
  const url = endpoints[config.environment] + '/validateConstraints';
  const response = await axios.post<ValidateConstraintsResponse>(url, validateQuantity, {
    headers,
  });
  return response.data;
}
