import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import {
  Audience,
  CustomerType,
  MutationAddOrganizationArgs,
  QuerySearchOrganizationsForTypeAArgs,
} from 'generated/graphql';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import {
  OrganizationSearchDialog,
  OrganizationSearchDialogProps,
  OrganizationSearchResultTileProps,
} from '../../CustomerForIndirect/OrganizationSearch/OrganizationSearchDialog';
import { getInformationalTile } from '../../CustomerForIndirect/OrganizationSearch/PartnerSearchDialog/utils';
import {
  ADD_ORGANIZATION,
  QuoteUpdatedData,
} from '../../CustomerForIndirect/OrganizationSearch/queries';
import { SEARCH_PARTNER_ORGS_TYPEA, SearchPartnerResultsDataForTypeA } from './queries';

export interface PartnerSearchDialogForJioCspProps {
  /**
   * Required to decide which description to display.
   */
  quoteAudience: Audience;
}

export const PartnerSearchDialogForJioCsp: React.FC<PartnerSearchDialogForJioCspProps> = props => {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);

  // TODO: get a design that allows to define the onComplete option on a mutation from ActiveQuoteContext
  // To get the quote id and etag for mutation.
  const { activeQuote: quote } = React.useContext(ActiveQuoteContext);

  //#region Apollo hooks and data
  const [searchPartnerOrganizations, { loading, error, data, called }] = useLazyQuery<
    SearchPartnerResultsDataForTypeA,
    QuerySearchOrganizationsForTypeAArgs
  >(SEARCH_PARTNER_ORGS_TYPEA);
  const messages = data && data.searchOrganizationsForTypeA.messages;

  const [addOrganization, { loading: addingPartner, error: erroredAddingPartner }] = useMutation<
    QuoteUpdatedData,
    MutationAddOrganizationArgs
  >(ADD_ORGANIZATION, {
    errorPolicy: 'all',
    onCompleted: () => dialogContext.closeDialog(),
    onError: () => {},
  });
  const [organizationIdSelected, setOrganizationIdSelected] = useState<string | undefined>();
  //#endregion

  //#region Event handlers
  const onSearch = (query: string) =>
    searchPartnerOrganizations({
      variables: {
        input: {
          query,
          audience: quote?.audience,
        },
      },
    });

  const onAdd = (accountId: string, organizationId: string) => {
    quote &&
      addOrganization({
        variables: {
          input: {
            quote: { id: quote.id, etag: quote.etag },
            accountId,
            organizationId,
            customerType: CustomerType.SoldToCustomer,
          },
        },
      });
    setOrganizationIdSelected(organizationId);
  };
  //#endregion

  //#region Data
  let results: OrganizationSearchResultTileProps[] | undefined;

  if (data) {
    const { organizations, tenant } = data.searchOrganizationsForTypeA;

    results = tenant
      ? organizations.map<OrganizationSearchResultTileProps>((organization, index) => ({
          id: `search-result-organization-${index}`,
          address: organization.address,
          tradeName: organization.tradeName,
          lastPurchaseDate: organization.lastInvoiceDate,
          tenantId: tenant.tenantId,
          tenantName: tenant.tenantDisplayName,
          loading: organizationIdSelected === organization.id && addingPartner,
          errored: organizationIdSelected === organization.id && !!erroredAddingPartner,
          buttonLabel: t('quote::Add partner'),
          loadingLabel: t('quote::adding partner'),
          errorMessage: t('quote::Unable to add the partner.'),
          onAdd: () => onAdd(organization.accountId, organization.id),
        }))
      : [];
  } else if (called && !loading && !error) {
    results = [];
  }

  const informationalTile = getInformationalTile(error, messages, results);
  //#endregion

  // #region Translated labels
  const title = t('quote::Partner search');
  const loadingLabel = t('quote::looking for partners');

  const description =
    props.quoteAudience === Audience.Commercial
      ? t('quote::Find a partner by searching using their tenant ID, or domain name.')
      : t(
          'quote::Use the field below to find a {{segment}}-qualified partner by searching using their tenant ID, or domain name.',
          { segment: props.quoteAudience.toLowerCase() }
        );

  const resultsLabel =
    results &&
    t('quote::{{count}} partner found', {
      count: results.length,
      // eslint-disable-next-line @typescript-eslint/camelcase
      defaultValue_plural: '{{count}} partners found',
    });
  //#endregion

  const dialogProps: OrganizationSearchDialogProps = {
    title,
    description,
    loading,
    loadingLabel,
    resultsLabel,
    results,
    informationalTile,
    onSearch,
  };

  return <OrganizationSearchDialog {...dialogProps} />;
};
