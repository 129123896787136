import { Spinner } from 'components';
import { SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';

export const LoadingScreen: React.FC = () => {
  const loadingContainerStyles: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  return (
    <div style={loadingContainerStyles}>
      <Spinner size={SpinnerSize.large} />
    </div>
  );
};
