import gql from 'graphql-tag';
import { ecifLineItemFragment } from '../../../../ActiveQuoteContext.queries';

export const ApplyEcifConfiguration = gql`
  mutation applyEcifConfiguration($quote: QuoteMutationInput!, $config: EcifConfigInput!) {
    applyEcifConfiguration(quote: $quote, config: $config) {
      id
      etag
      lineItems {
        id
        product {
          title
          productFamily
        }
        ...EcifLineItemFragment
      }
    }
  }
  ${ecifLineItemFragment}
`;
