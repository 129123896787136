import { Toggle } from 'components';
import { mergeClassNames } from 'components/utilities';
import { ResetFlightsButton } from 'features/app/components/Flights/ResetFlightsButton';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 16,
    height: 48,
  },
  button: {
    padding: 0,
    '&, & i': {
      color: theme.palette.text,
    },
    '&:hover': {
      background: 'transparent',

      '&, & i': {
        color: theme.palette.secondary,
      },
    },
  },
  cacheToggle: {
    marginBottom: 0,
    '& label': {
      marginLeft: 8,
    },
  },
});

type Props = {
  isCachedFlightsEnabled: boolean;
  className?: string;
  onCacheFlightsToggle: (enabled: boolean) => void;
  onResetFlightsClick: () => void;
  showResetButton: boolean;
} & WithStyles<typeof styles>;

const FlightsPanelControlsUnstyled: React.FC<Props> = props => {
  const {
    isCachedFlightsEnabled,
    classes,
    className,
    onCacheFlightsToggle,
    onResetFlightsClick,
    showResetButton,
  } = props;

  const [isCachingFlights, setIsCachingFlights] = React.useState(isCachedFlightsEnabled);

  return (
    <div className={mergeClassNames([classes.container, className])}>
      <Toggle
        addClass={classes.cacheToggle}
        checked={isCachingFlights}
        inlineLabel
        label="Cache Flights"
        onChange={() => {
          const nextValue = !isCachingFlights;
          setIsCachingFlights(nextValue);
          onCacheFlightsToggle(nextValue);
        }}
      />
      {showResetButton && (
        <ResetFlightsButton
          addClass={classes.button}
          key="resetFlights"
          text="Reset Flights"
          onClick={onResetFlightsClick}
        />
      )}
    </div>
  );
};

export const FlightsPanelControls = withStyles(styles)(FlightsPanelControlsUnstyled);
