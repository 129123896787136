import { sharedStyles, ThemeProps } from 'styles';

import { OrganizationProps } from './Organization';

export const styles = (theme: ThemeProps) => {
  const { fontSizes, fontWeights } = theme.fonts;

  return {
    addressContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: (props: OrganizationProps) => props.maxWidth,
    },
    headline: {
      ...sharedStyles(theme).textOverflow,
      color: theme.palette.textTertiary,
    },
    tradeName: {
      ...sharedStyles(theme).textOverflow,
      display: 'block',
      paddingTop: 2,
    },
    addressText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    pcnText: {
      paddingBottom: fontSizes.tiny,
    },
    contact: {
      paddingTop: fontSizes.tiny,
    },
    companyName: {
      display: 'block',
      marginBottom: 2,
      fontWeight: fontWeights.semiBold,
    },
    companyNameBold: {
      fontWeight: fontWeights.bold,
    },
    organizationName: {
      lineHeight: '16px',
      paddingBottom: 2,
    },
    shimmer: {
      marginBottom: 4,
    },
    textStyles: {
      fontSize: fontSizes.medium,
      fontWeight: fontWeights.regular,
      color: theme.palette.text,
    },
    contactEmail: {
      fontSize: theme.fonts.fontSizes.small,
    },
    watermark: {
      color: theme.palette.textTertiary,
    },
  };
};
