export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export const endpoints = {
  [Environment.Prod]: 'https://quotecenterservice.l2o.microsoft.com/v1/aaduser',
  [Environment.Int]: 'https://quotecenterservice.int.l2o.microsoft.com/v1/aaduser',
};

export interface ExternalUserConfig {
  environment: Environment;
}
