import {
  Callout,
  DirectionalHint,
  FocusTrapCallout,
  ICalloutContentStyles,
  ICalloutProps,
  IFocusTrapZoneProps,
} from 'office-ui-fabric-react';
import * as React from 'react';

import { calloutStyles } from './CalloutAtom.styles';

export interface CalloutAtomProps {
  alignTargetEdge?: boolean;
  doNotLayer?: boolean;
  focusTrapProps?: IFocusTrapZoneProps;
  id?: string;
  isBeakVisible: boolean;
  setInitialFocus?: boolean;
  shouldRestoreFocus?: boolean;
  trapFocus: boolean;
  hidden?: boolean;
  onDismiss: () => void;
  target?: HTMLDivElement | HTMLSpanElement | null;
  directionalHint: DirectionalHint;
  calloutMaxWidth?: number;
  calloutMaxHeight?: number;
  onMouseUp?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  preventDismissOnLostFocus?: boolean;
  dismissOnResize?: boolean;
  styles?: Partial<ICalloutContentStyles>;
  children?: React.ReactNode;
  preventDismissOnScroll?: boolean;
}

export const CalloutAtom: React.FC<CalloutAtomProps> = ({
  preventDismissOnScroll = true,
  ...props
}) => {
  const calloutProps: ICalloutProps = {
    alignTargetEdge: props.alignTargetEdge,
    setInitialFocus: props.setInitialFocus,
    isBeakVisible: props.isBeakVisible,
    doNotLayer: props.doNotLayer,
    hidden: props.hidden,
    id: props.id,
    directionalHint: props.directionalHint,
    preventDismissOnScroll,
    preventDismissOnResize: !props.dismissOnResize,
    preventDismissOnLostFocus: props.preventDismissOnLostFocus,
    calloutMaxWidth: props.calloutMaxWidth,
    calloutMaxHeight: props.calloutMaxHeight,
    shouldRestoreFocus: props.shouldRestoreFocus,
    styles: { ...calloutStyles, ...props.styles },
  };
  const overflowYAutoStyle: React.CSSProperties = { overflowY: 'auto' };
  const focusTrapCallout = (
    <FocusTrapCallout
      {...calloutProps}
      focusTrapProps={props.focusTrapProps}
      style={overflowYAutoStyle}
      target={props.target}
      onDismiss={props.onDismiss}
    >
      {props.children}
    </FocusTrapCallout>
  );
  const standardCallout = (
    <Callout
      {...calloutProps}
      style={overflowYAutoStyle}
      target={props.target}
      onDismiss={props.onDismiss}
      onMouseUp={props.onMouseUp}
    >
      {props.children}
    </Callout>
  );
  return props.trapFocus ? focusTrapCallout : standardCallout;
};
