import {
  AddressProps,
  ApprovalListItem,
  ApprovalsList,
  ConditionItem,
  ConditionsList,
  DetailsNotificationsList,
  NotificationItem,
  NotificationType,
  TextBody,
  TextBodySmall,
  TextTitleSecondary,
} from 'components';
import { LabelList, LabelListItem } from 'components/ions/CuratedText/LabelList/LabelList';
import { getApprovalDaysElapsedText } from 'features-apollo/quote/components/DetailsPane/utils';
import { Address } from 'features/components/Address';
import { AddOn, AddOns } from 'features/proposal/components/AddOns';
import {
  ExistingDiscount,
  ExistingDiscountLabels,
  ExistingDiscountsList,
} from 'features/proposal/components/ExistingDiscountsList';
import i18next from 'i18next';
import * as React from 'react';

export const renderConditions = (
  conditions: ConditionItem[],
  classes: any,
  largerText?: boolean
) => {
  if (conditions && conditions.length > 0) {
    return (
      <div className={classes.sectionRow}>
        <ConditionsList
          conditions={conditions}
          id="DetailsPane-Conditions"
          largerText={largerText}
        />
      </div>
    );
  }
  return null;
};

export const renderDetailsPaneLabelList = (items: LabelListItem[], rowClassName: string) => {
  if (items && items.length) {
    return (
      <div className={rowClassName}>
        <LabelList items={items} />
      </div>
    );
  }
};

/**
 * Displays the notifications sorted in the following order:
 * - errors
 * - standard
 * - warning
 * - info
 *
 * @param notifications notification list
 * @param classes styling
 * @returns DetailsNotifications component | undefined when list is empty
 */
export const renderNotifications = (notifications: NotificationItem[], classes: any) => {
  if (!notifications.length) {
    return;
  }

  const errorNotifications: NotificationItem[] = [];
  const standardNotifications: NotificationItem[] = [];
  const warningNotifications: NotificationItem[] = [];
  const infoNotifications: NotificationItem[] = [];

  notifications.forEach(notification => {
    switch (notification.type) {
      case NotificationType.error:
        errorNotifications.push(notification);
        break;
      case NotificationType.standard:
        standardNotifications.push(notification);
        break;
      case NotificationType.warning:
        warningNotifications.push(notification);
        break;
      case NotificationType.info:
        infoNotifications.push(notification);
        break;
    }
  });

  const sortedNotifications = errorNotifications.concat(
    standardNotifications,
    warningNotifications,
    infoNotifications
  );

  return (
    <div className={classes.sectionRow}>
      <DetailsNotificationsList
        dataAutomationId="detailsNotificationList"
        id="details-notification-list"
        notifications={sortedNotifications}
      />
    </div>
  );
};

export const renderApprovals = (
  approvals: ApprovalListItem[],
  classes: any,
  t: i18next.TFunction
) => {
  if (approvals && approvals.length > 0) {
    return (
      <div className={classes.sectionRow}>
        <TextBody addClass={classes.semiBoldText}>{t('quote::Approvals')}</TextBody>

        <div className={classes.innerRow}>
          {approvals.map((approval, key) => (
            <ApprovalsList
              approval={approval}
              dataAutomationId={`detailsApprovalsList-${key}`}
              getApprovalDaysElapsedText={(days: number) => getApprovalDaysElapsedText(days, t)}
              id={`details-approvals-list-${key}`}
              key={key}
              largerText={false}
            />
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export const renderPartners = (partners: string[], classes: any) => {
  if (partners && partners.length > 0) {
    return (
      <div className={classes.sectionRow}>
        <div>
          <TextBody>
            <span className={classes.semiBold}>Partners</span>
          </TextBody>
        </div>
        <div className={classes.innerRow}>
          {partners.map((name, index) => (
            <span className={classes.innerRow} key={name + index}>
              <TextBodySmall>{name}</TextBodySmall>
            </span>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export const renderDiscounts = (
  classes: any,
  labels: ExistingDiscountLabels,
  discounts?: ExistingDiscount[]
) => {
  if (discounts && discounts.length > 0) {
    return (
      <div className={classes.sectionRow}>
        <TextTitleSecondary>{labels.existingDiscount}</TextTitleSecondary>
        <div className={classes.innerRow}>
          <ExistingDiscountsList discounts={discounts} labels={labels} />
        </div>
      </div>
    );
  }
  return null;
};

export const renderAddOns = (classes: any, addOns?: AddOn[]) => {
  if (addOns && addOns.length) {
    return (
      <div className={classes.detailsPaneSection}>
        <AddOns addOns={addOns} />
      </div>
    );
  }
  return null;
};

export const renderAddress = (classes: any, address?: AddressProps) => {
  return address ? (
    <div className={classes.address}>
      <Address {...address} />
    </div>
  ) : null;
};

export const renderLabeledList = (label: string, values: string[], classes: any) => {
  if (values.length > 0) {
    return (
      <div className={classes.discountRow}>
        <div className={classes.label}>
          <TextBodySmall>
            <span>{label}</span>
          </TextBodySmall>
        </div>
        <div className={classes.shortText}>
          {values.map((name, index) => (
            <span className={classes.listRow} key={name + index}>
              <TextBodySmall dataAutomationId={name}>{name}</TextBodySmall>
            </span>
          ))}
        </div>
      </div>
    );
  }
  return null;
};
