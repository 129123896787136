import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import gql from 'graphql-tag';

//TODO: Don't get quotebody here
export const ApplySapConfiguration = gql`
  mutation ApplyConfiguratinoSap(
    $quote: QuoteMutationInput!
    $configuration: ApplyConfigurationSapInput!
  ) {
    applyConfigurationSap(quote: $quote, configuration: $configuration) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;
