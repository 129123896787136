import {
  GuidanceBox,
  LinkButton,
  LinkEmail,
  PrimaryButton,
  SectionSeparator,
  ShimmerForBackgroundCommon,
  TextBody,
  TextBodySmall,
  TextTitle,
  TextTitleSecondary,
} from 'components';
import { ErrorMessage } from 'components/ions/CuratedText/Messages/Messages';
import { EditLabel } from 'components/molecules/EditLabel/EditLabel';
import { mergeClassNames } from 'components/utilities';
import { Address as AddressIon } from 'features/components/Address';
import { Address } from 'features/customer/types';
import { openBillingContactDialog } from 'features/proposal/components';
import { BillingContactInformation } from 'features/proposal/components/Dialogs/BillingContact';
import { ExistingOwnersLinkButton } from 'features/proposal/components/Dialogs/ExistingOwnersDialog';
import { openVatIdDialog, VatIdLinkButton } from 'features/proposal/components/Dialogs/VatIdDialog';
import { GetMarketInfo } from 'features/proposal/components/VatId/Queries';
import {
  openEditOrganizationWizardDialog,
  openTenantWizardDialog,
  TenantWizardView,
} from 'features/proposal/components/Wizards';
import {
  defaultLanguage,
  Language,
  languageInfo,
  LanguageInfo,
} from 'features/proposal/supported-languages';
import { defaultMarket, Market } from 'features/proposal/supported-markets';
import { MarketInfo } from 'generated/graphql';
import { ShimmerElementType } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { OrganizationSummary, TaxId } from 'services/customer/types';
import { TenantName } from 'services/externaluser/types';
import { DialogContext, FontSizes } from 'styles';

import { useQuery } from '@apollo/react-hooks';

import { customerAddedStyles } from '../customer.styles';
import { CustomerCommandMenu } from './CustomerCommandMenu';
import { GuidanceBoxHeader } from './shared';

const TenantRequiredUnStyled: React.FC<{ title: string } & WithStyles<
  typeof customerAddedStyles
>> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  return (
    <GuidanceBox>
      <GuidanceBoxHeader addClass={classes.header} title={props.title}>
        <TextBody addClass={classes.paragraph}>
          {t(
            `quote::Before you can publish this quote, you need to add the tenant and AAD work account of the person who's authorized to transact the quote.`
          )}
        </TextBody>
      </GuidanceBoxHeader>
      <PrimaryButton
        dataAutomationId="addIdentityButton"
        text={t('quote::Add identity')}
        onClick={() => {
          openTenantWizardDialog(context, TenantWizardView.Tenant);
        }}
      />
    </GuidanceBox>
  );
};
const TenantRequired = withStyles(customerAddedStyles)(TenantRequiredUnStyled);

export interface CustomerAddedProps {
  accountId?: string;
  address?: Address | null;
  contactEmail?: string;
  contactPhoneNumber?: string;
  customerActions?: React.ReactNode;
  disableEditTenant?: boolean;
  organizationEditable?: boolean;
  organization?: OrganizationSummary;
  organizationId?: string;
  organizationIsMissingInformation?: boolean;
  invitedUser?: string;
  isQuoteLegacy?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  tradeName?: string;
  tenantSelected?: TenantName;
  language?: Language;
  leadOrgName?: string;
  isPartnerProposal?: boolean;
  isPartnerCustomer?: boolean;
  isQuotePublished?: boolean;
  vatId?: TaxId;
}

type CustomerAddedPropsType = CustomerAddedProps & WithStyles<typeof customerAddedStyles>;

const CustomerAddedUnStyled: React.FC<CustomerAddedPropsType> = props => {
  //isPartnerProposal means the quote is assigned to partner,
  //isPartnerCustomer tells how the customer added should be rendered, like a partner customer or regular customer
  const {
    accountId,
    address,
    classes,
    isQuoteLegacy,
    organization,
    organizationId,
    tenantSelected,
    language,
    organizationIsMissingInformation,
    isPartnerProposal,
    isPartnerCustomer,
    customerActions,
    isQuotePublished,
    vatId,
  } = props;
  const { t } = useTranslation();

  const context = React.useContext(DialogContext);

  const market: Market = (address && (address.country as Market)) || defaultMarket;

  const { data } = useQuery<{ getMarketInfo: MarketInfo }>(GetMarketInfo, {
    variables: { market },
  });

  const showVatIdSection =
    data && data.getMarketInfo.requiresVatId && organization && !organization.backingIdentity;

  const tenantInfo = (tenant: TenantName) => (
    <tr>
      <td>
        <TextBody>{tenant.tenantId}</TextBody>
      </td>
      <td>
        <TextBody>{tenant.tenantDisplayName}</TextBody>
      </td>
    </tr>
  );

  const partnerTenantIdText = isPartnerProposal
    ? t('quote::Partner tenant ID')
    : t('quote::Tenant ID');
  const tenantTable = (tenant: TenantName) => (
    <table className={`${classes.table} ${classes.tenantList}`}>
      <thead>
        <tr>
          <th>
            <EditLabel
              ariaLabel={t('quote::Edit tenant ID')}
              dataAutomationId="tenantIdEditLabel"
              hideIcon={props.disableEditTenant || props.readOnly}
              label={isPartnerCustomer ? t('quote::Customer tenant ID') : partnerTenantIdText}
              onClick={() => {
                openTenantWizardDialog(context, TenantWizardView.Tenant);
              }}
            />
          </th>
          <th>
            <EditLabel
              ariaLabel={t('quote::Edit tenant name')}
              dataAutomationId="tenantNameEditLabel"
              hideIcon
              label={t('quote::Tenant name')}
              onClick={() => {
                openTenantWizardDialog(context, TenantWizardView.Tenant);
              }}
            />
          </th>
        </tr>
      </thead>
      <tbody data-automation-id="tenantInfo">{tenantInfo(tenant)}</tbody>
    </table>
  );

  const reformatInvitedUser = (invitedUser: string) => {
    const userNameEndIndex = invitedUser.indexOf('_');
    const domainNameEndIndex = invitedUser.indexOf('#');
    const userName = invitedUser.substring(0, userNameEndIndex);
    const domainName = invitedUser.substring(userNameEndIndex + 1, domainNameEndIndex);
    return `${userName}@${domainName}`;
  };

  const selectedTenantInfo = (tenant: TenantName) => {
    // #EXT is shorthand for external and is present in guested user upns
    const guestedUserIdentifier = '#EXT';
    let invitedUser = props.invitedUser;
    const isGuestedUser = invitedUser && invitedUser.includes(guestedUserIdentifier);
    if (invitedUser && isGuestedUser) {
      invitedUser = reformatInvitedUser(invitedUser);
    }
    const workAccount = invitedUser ? (
      <TextBody>{invitedUser}</TextBody>
    ) : (
      <LinkButton
        addClass={classes.font}
        dataAutomationId="addWorkAccountLink"
        disabled={props.readOnly}
        displayText={t('quote::add work account')}
        onClick={() => {
          openTenantWizardDialog(context, TenantWizardView.Email);
        }}
      />
    );

    return (
      <>
        <EditLabel
          ariaLabel={t('quote::Add or edit work account')}
          dataAutomationId="addOrEditWorkAccount"
          hideIcon={props.readOnly}
          label={t('quote::Work account')}
          required
          onClick={() => {
            openTenantWizardDialog(context, TenantWizardView.Email);
          }}
        />
        {workAccount}
        {isQuotePublished && tenantSelected && (
          <ExistingOwnersLinkButton
            id="existing-owners-dialog-link"
            invitedUser={props.invitedUser}
            tenantId={tenantSelected.tenantId}
          />
        )}
        {tenantTable(tenant)}
      </>
    );
  };

  const setUpTenant = (
    <>
      <EditLabel
        ariaLabel={t('quote::Add or edit the sign up email address')}
        dataAutomationId="addOrEditSignUpEmailAddress"
        hideIcon={props.readOnly}
        label={t('quote::Sign up email address')}
        required
        onClick={() => {
          openTenantWizardDialog(context, TenantWizardView.Email);
        }}
      />
      <TextBody>{props.invitedUser}</TextBody>
      <div className={classes.tenantList}>
        <EditLabel
          ariaLabel={t('quote::Add tenant')}
          dataAutomationId="addTenantEditLabel"
          hideIcon={props.readOnly}
          label={t('quote::Tenant')}
          onClick={() => {
            openTenantWizardDialog(context, TenantWizardView.Tenant);
          }}
        />
        <TextBody>{t('quote::To be created on check out')}</TextBody>
      </div>
    </>
  );

  const tenantTitle = t('quote::Person accepting the quote');
  const getTenantSection = () => {
    if (tenantSelected) {
      return (
        <div>
          <TextTitleSecondary addClass={classes.tenantTitle}>{tenantTitle}</TextTitleSecondary>
          {selectedTenantInfo(tenantSelected)}
        </div>
      );
    } else if (props.invitedUser) {
      return (
        <div>
          <TextTitleSecondary addClass={classes.tenantTitle}>{tenantTitle}</TextTitleSecondary>
          {setUpTenant}
        </div>
      );
    } else {
      return <TenantRequired title={tenantTitle} />;
    }
  };

  const backingTenantSection = tenantSelected && <>{tenantTable(tenantSelected)}</>;

  const loadingState = (
    <div className={classes.shimmerContainer}>
      <div className={classes.header}>
        <ShimmerForBackgroundCommon
          className={classes.shimmer}
          shimmerElements={[{ type: ShimmerElementType.line, height: 24 }]}
          width={144}
        />
      </div>
      <ShimmerForBackgroundCommon
        className={classes.shimmer}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        width={140}
      />
      <ShimmerForBackgroundCommon
        className={classes.shimmer}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        width={100}
      />
      <ShimmerForBackgroundCommon
        className={classes.shimmer}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        width={120}
      />
      <ShimmerForBackgroundCommon
        className={classes.shimmer}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        width={80}
      />
    </div>
  );

  const affiliateMessage = !!props.leadOrgName && (
    <TextBodySmall
      addClass={classes.text}
      dataAutomationId="customerAffiliateMessage"
      title={props.leadOrgName}
    >
      {t('quote::Affiliate of {{parentAccountName}}', { parentAccountName: props.leadOrgName })}
    </TextBodySmall>
  );
  const draftMessage = props.organizationEditable && (
    <TextBodySmall addClass={classes.text} dataAutomationId="customerDraftMessage">
      {t('quote::This billing account is in draft status until validated by the customer.')}
    </TextBodySmall>
  );
  const info: LanguageInfo | undefined = languageInfo[language || defaultLanguage];
  const tradeName = props.tradeName && (
    <TextBodySmall
      addClass={classes.text}
      style={{ whiteSpace: 'nowrap' }}
      title={t('quote::Doing business as {{tradeName}}', { tradeName: props.tradeName })}
    >{`dba ${props.tradeName}`}</TextBodySmall>
  );
  const editBillingAccountLink = (
    <LinkButton
      dataAutomationId="incorrectBillingAccountEditButton"
      displayText={t('quote::edit billing account')}
      onClick={() => openEditOrganizationWizardDialog(context)}
    />
  );
  const qcsSupportLink = (
    <>
      <LinkEmail displayText="QCSupport@microsoft.com" email="QCSupport@microsoft.com" />
    </>
  );
  const incorrectBillingAccountSupportMessage = t(
    'quote::The billing account is missing information that must be completed before the quote can be published. Please contact Quote Center Support for assistance.'
  );
  const incorrectBillingAccountMessage = t(
    'quote::The billing account name or address is incorrect and must be fixed before the quote can be published.'
  );

  const openContactDialog =
    accountId && organizationId
      ? () =>
          openBillingContactDialog(context, {
            accountId,
            organizationId,
          })
      : undefined;

  const vatIdProps = accountId &&
    organizationId && {
      accountId,
      organizationId,
      size: 'medium' as keyof FontSizes,
      initialVatId: vatId && vatId.id,
    };

  const openIdDialog = vatIdProps ? () => openVatIdDialog(context, vatIdProps) : undefined;

  const displayVatId = () => {
    if (vatId && vatId.id) {
      return <TextBody dataAutomationId="vatIdentificationNumber">{vatId && vatId.id}</TextBody>;
    } else if (props.readOnly) {
      return <TextBody addClass={classes.readOnlyVatId}>{t('quote::none')}</TextBody>;
    } else if (vatIdProps) {
      return <VatIdLinkButton {...vatIdProps} />;
    }
  };

  const contactInformationSection =
    !isQuoteLegacy || (isQuoteLegacy && props.contactEmail && props.contactPhoneNumber) ? (
      <div className={classes.customerAddedSection}>
        <EditLabel
          ariaLabel={t('quote::Edit the billing contact information')}
          hideIcon={props.readOnly || !(accountId && organizationId)}
          label={t('quote::Contact')}
          required={!isQuoteLegacy}
          onClick={openContactDialog}
        >
          <BillingContactInformation
            dataAutomationId="addCustomerPropertyContactLink"
            email={props.contactEmail}
            phoneNumber={props.contactPhoneNumber}
            readOnly={props.readOnly || !(accountId && organizationId)}
            onClick={openContactDialog}
          />
        </EditLabel>
      </div>
    ) : null;

  const invoiceLanguageSection = (
    <div className={classes.customerAddedSection}>
      {/*TODO: Consider just making this a label*/}
      <EditLabel
        ariaLabel={t('quote::Invoice language')}
        hideIcon
        label={t('quote::Invoice language')}
      />
      <TextBody dataAutomationId="invoiceLanguage">{info && info.display}</TextBody>
    </div>
  );

  const vatIdSection = showVatIdSection && (
    <React.Fragment>
      <EditLabel
        ariaLabel={t('quote::VAT identification number')}
        hideIcon={props.readOnly || !(accountId && organizationId)}
        label={t('quote::VAT identification number')}
        onClick={openIdDialog}
      />
      {displayVatId()}
    </React.Fragment>
  );

  let addressContainerClass: string | undefined = props.organizationIsMissingInformation
    ? classes.incompleteOrganizationAddressPadding
    : classes.textSeparator;

  if (customerActions) {
    addressContainerClass = undefined;
  }

  const addedCustomer = address && (
    <>
      <div className={classes.header}>
        <div style={{ display: 'flex' }}>
          <TextTitle
            addClass={mergeClassNames([classes.title, classes.companyName])}
            dataAutomationId="companyName"
            title={address.companyName}
          >
            {address.companyName}
          </TextTitle>
          {!isQuoteLegacy && (
            <>
              <div className={classes.divider} />
              <CustomerCommandMenu
                addClass={classes.menuButton}
                disabled={props.readOnly}
                organizationEditable={props.organizationEditable}
              />
            </>
          )}
        </div>
        {tradeName}
        {affiliateMessage}
        {!isQuoteLegacy && draftMessage}
      </div>
      {organizationIsMissingInformation && (
        <ErrorMessage
          addClass={classes.errorTextFonts}
          addContainerClass={classes.errorTextContainer}
          mainMessage={
            props.organizationEditable
              ? incorrectBillingAccountMessage
              : incorrectBillingAccountSupportMessage
          }
        >
          {props.organizationEditable ? editBillingAccountLink : qcsSupportLink}
        </ErrorMessage>
      )}
      <AddressIon
        addClass={classes.font}
        addContainerClass={addressContainerClass}
        address={address}
        dataAutomationId="organizationAddress"
      />
      {customerActions}
      {contactInformationSection}
      {invoiceLanguageSection}
      {vatIdSection}
    </>
  );

  const tenantSection = isPartnerProposal ? backingTenantSection : getTenantSection();
  return (
    <div>
      {props.loading ? loadingState : addedCustomer}
      {!isPartnerProposal && !props.loading && <SectionSeparator addClass={classes.separator} />}
      {!isQuoteLegacy && !props.loading && tenantSection}
    </div>
  );
};

export const CustomerAdded = withStyles(customerAddedStyles)(CustomerAddedUnStyled);
