import {
  InfoButton,
  MediumIcon,
  Spinner,
  SpinnerSize,
  SystemError,
  TextBody,
  TextWatermark,
} from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { ExistingOwner, LoadingErrorType } from './ExistingOwners.types';
import { existingOwnersStyles } from './ExistingOwnersList.styles';

export const LIST_TEST_IDENTIFIER = 'existing-owners-list';
export const LOADING_LIST_TEST_IDENTIFIER = 'existing-owners-list-loading';

export interface ExistingOwnersListProps {
  list: ExistingOwner[];
  error: LoadingErrorType;
  loading?: boolean;
  showTooltip?: boolean;
  maxHeight?: number;
}

type Props = ExistingOwnersListProps & WithStyles<typeof existingOwnersStyles>;

const ExistingOwnersListUnstyled: React.FC<Props> = ({
  classes,
  list,
  error,
  showTooltip,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const reformatUpn = (upn: string) => {
    const userNameEndIndex = upn.indexOf('_');
    const domainNameEndIndex = upn.indexOf('#');
    const userName = upn.substring(0, userNameEndIndex);
    const domainName = upn.substring(userNameEndIndex + 1, domainNameEndIndex);
    return `${userName}@${domainName}`;
  };

  const guestedUserIcon = (
    <span title={t('quote::Guest User')}>
      <MediumIcon addClass={classes.guestedUserIcon} iconName="TemporaryUser" />
    </span>
  );

  const renderOwnersList = (list: ExistingOwner[]) => {
    return list.map((owner: ExistingOwner, index: number) => {
      // #EXT is shorthand for external and is present in guested user upns
      const guestedUserIdentifier = '#EXT';
      let upn = owner.upn;
      const isGuestedUser = upn.includes(guestedUserIdentifier);
      if (isGuestedUser) {
        upn = reformatUpn(upn);
      } else if (!upn) {
        upn = t('quote::Service Account');
      }
      return (
        <div className={classes.existingOwnersListRow} key={index}>
          <div className={classes.existingOwnersLeftColumn}>
            <TextBody addClass={classes.upn}>{upn}</TextBody>
            {isGuestedUser && guestedUserIcon}
          </div>
          <TextBody addClass={classes.existingOwnersRightColumn}>{owner.roles.join(', ')}</TextBody>
        </div>
      );
    });
  };

  const renderListScenario = () => {
    if (error === LoadingErrorType.ErrorLoadingEverything) {
      return (
        <SystemError
          mainMessage={t(
            'quote::We ran into an error and were unable to retrieve the existing owners on this tenant.'
          )}
        />
      );
    }
    return (
      <div className={classes.existingOwnersList} data-automation-id={LIST_TEST_IDENTIFIER}>
        {renderOwnersList(list)}
      </div>
    );
  };

  const tooltip = (
    <InfoButton
      ariaLabel={t('quote::Open information about customer tenant')}
      calloutProps={{
        closeButtonAriaLabel: t('Close'),
        headline: t('quote::Customer tenant owners'),
      }}
      id="customer-tenant-owners"
    >
      <TextBody>
        {t(
          "quote::The owners and global admins listed here have the appropriate authorization on their company's tenant to be able to execute on all the components of the quote being transacted. Guested users are denoted with an icon."
        )}
      </TextBody>
    </InfoButton>
  );

  const spinner = (
    <Spinner
      addClass={classes.spinner}
      dataAutomationId={LOADING_LIST_TEST_IDENTIFIER}
      size={SpinnerSize.large}
    />
  );

  const existingOwnersList = (
    <div className={classes.existingOwnersSection}>
      <TextBody addClass={classes.existingOwnersTitle}>{t('quote::Existing owners')}</TextBody>
      {showTooltip && tooltip}
      {renderListScenario()}
    </div>
  );

  const hasPartiallyLoaded =
    error === LoadingErrorType.ErrorLoadingOwners || error === LoadingErrorType.ErrorLoadingAdmins;

  const partialListLoadMessage = (
    <TextWatermark>{t(`quote::Not all owners are listed`)}</TextWatermark>
  );

  const renderList = () => {
    if (loading) {
      return spinner;
    }
    return (
      <div>
        {existingOwnersList}
        {hasPartiallyLoaded && (
          <div className={classes.partialListLoadMessage}>{partialListLoadMessage}</div>
        )}
      </div>
    );
  };

  return renderList();
};

export const ExistingOwnersList = withStyles(existingOwnersStyles)(ExistingOwnersListUnstyled);
