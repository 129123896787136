import { dateFormatLl } from 'components/utilities/dates';
import { DateOrDuration, EcifLineItem, LineItem, MessageSource } from 'generated/graphql';
import i18next from 'i18n';
import moment from 'moment-timezone';
import { oc } from 'ts-optchain';

import { getErrorForSpecificListRowMessage } from '../utils';

/**
 * Converts any duration in a ISO 8601 format into readable translated string
 *
 * @param duration
 */
export const convertISODurationToText = (duration: string) => {
  const durationInMonths = moment.duration(duration).asMonths();

  return durationInMonths % 12 === 0
    ? i18next.t('quote::In {{count}} year', {
        count: durationInMonths / 12,
        defaultValue_plural: 'In {{count}} years', // eslint-disable-line @typescript-eslint/camelcase
      })
    : i18next.t('quote::{{count}} month', {
        count: durationInMonths,
        defaultValue_plural: 'In {{count}} months', // eslint-disable-line @typescript-eslint/camelcase
      });
};

/**
 * Determines the end date that needs to be displayed on the term row
 *
 * @param lineItemDuration duration information from the line item
 * @param productDurations duration information from the line item's product information
 */
export const getTermRowEndDate = (
  lineItemDuration?: DateOrDuration | null,
  productDurations?: string[] | null
) => {
  if (lineItemDuration && lineItemDuration.__typename === 'Duration' && lineItemDuration.duration) {
    return convertISODurationToText(lineItemDuration.duration);
  } else if (productDurations) {
    return productDurations.length === 1 && productDurations[0] === 'Evergreen'
      ? i18next.t('quote::Evergreen')
      : undefined;
  }
};

/**
 * Determines if the product is an End Customer Invesment Funds (ECIF)
 *
 * @param productType type of product in the line item
 */
export const isProductECIF = (productType?: string | null) =>
  !!(productType && productType.toUpperCase() === 'ECIF');

/**
 * Gets message to display in the term list row.
 *
 * NOTE:  At the moment, we only display an error message in the row when product fails to load.
 *        The message is different from what it is displayed on the details pane per requirements.
 */
export const getTermListRowMessage = (lineItem: LineItem | EcifLineItem) => {
  const message = getErrorForSpecificListRowMessage(lineItem);
  if (!message) {
    return;
  }

  switch (message.messageSource) {
    case MessageSource.OneAskApprovalNotFound:
    case MessageSource.EcifLineitemAmountExceeded:
    case MessageSource.EcifLineitemPastEnddate:
      return message.messageDisplay.display;
    case MessageSource.LineitemBroken:
      return i18next.t(
        'quote::This term failed to load. This could have been caused by a change in market, national cloud, or term availability.'
      );
  }
};

export const calculateECIFEndDate = (ecifLineItem: EcifLineItem) => {
  const emptyLineItems = !oc(ecifLineItem).ecifConfig.lineItems([]).length;
  if (emptyLineItems) {
    return '-';
  }
  if (ecifLineItem.ecifConfig) {
    let endDate = new Date(ecifLineItem.ecifConfig.lineItems[0].endDate);
    ecifLineItem.ecifConfig.lineItems.forEach(lineItem => {
      let currentDate = new Date(lineItem.endDate);
      endDate = endDate > currentDate ? endDate : currentDate;
    });
    return moment(endDate).format(dateFormatLl);
  }
  return '-';
};
