import { ModernAgreementType } from 'generated/graphql';
import { Signatory } from 'features-apollo/quote/components';
import { validateEmailFormat } from 'features/proposal/utils';

export const getSignatoriesByType = (type: ModernAgreementType, allSignatories?: Signatory[]) => {
  return allSignatories
    ? allSignatories.filter((signatory: Signatory) => signatory.type === type)
    : [];
};

export const getSignatoryEmailsByType = (
  type: ModernAgreementType,
  allSignatories?: Signatory[]
) => {
  const emails = allSignatories
    ? allSignatories
        .filter((signatory: Signatory) => signatory.type === type)
        .map((signatory: Signatory) => signatory.email)
    : [];
  if (!emails.length) {
    return ['', ''];
  } else if (emails.length === 1) {
    return emails.concat(['']);
  }
  return emails;
};

const validateEmail = (email: string | undefined | null) =>
  !email || validateEmailFormat(email || '', true);

const getIsRequiredFieldsEmpty = (signatories?: Signatory[]) => {
  if (!(signatories && signatories.length)) {
    return true;
  }
  let isRequiredFieldsEmpty = false;
  signatories &&
    signatories.forEach((signatory: Signatory) => {
      Object.keys(signatory).forEach(key => {
        if (key !== 'id' && !signatory[key as keyof Signatory]) {
          isRequiredFieldsEmpty = true;
        }
        if (key === 'email' && !validateEmail(signatory[key])) {
          isRequiredFieldsEmpty = true;
        }
      });
    });
  return isRequiredFieldsEmpty;
};

export const hasAllEmptySignatoryFields = (signatory: Signatory) => {
  const signatoryFields = Object.keys(signatory).filter(key => key !== 'id' && key !== 'type');

  return signatoryFields.filter(key => !!signatory[key as keyof Signatory]).length === 0;
};

export const getIsSignatoryTypeRequired = (
  type: ModernAgreementType,
  allSignatories: Signatory[] | undefined
) =>
  !getSignatoriesByType(type, allSignatories).length ||
  getIsRequiredFieldsEmpty(getSignatoriesByType(type, allSignatories));

export const isApplyButtonDisabledForMicrosoft = (allSignatories: Signatory[] | undefined) => {
  const microsoftSignatories = getSignatoriesByType(ModernAgreementType.Microsoft, allSignatories);

  if (!microsoftSignatories.length) {
    return false;
  }

  if (
    microsoftSignatories.filter(hasAllEmptySignatoryFields).length === microsoftSignatories.length
  ) {
    return false;
  }

  return getIsRequiredFieldsEmpty(microsoftSignatories);
};

export const isSignatoryFieldRequired = (
  signatory: Signatory | undefined,
  signatoryType: ModernAgreementType
): boolean => {
  if (
    signatoryType === ModernAgreementType.Partner ||
    signatoryType === ModernAgreementType.Customer
  ) {
    return true;
  }

  if (signatoryType === ModernAgreementType.Microsoft) {
    return !!signatory && !hasAllEmptySignatoryFields(signatory);
  }

  return false;
};
