import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { sectionSeparatorStyles } from './SectionSeparator.styles';

interface SectionSeparatorProps {
  addClass?: string;
  style?: React.CSSProperties;
}

type Props = SectionSeparatorProps & WithStyles<typeof sectionSeparatorStyles>;

const SectionSeparatorAtomUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <div className={`${props.classes.sectionSeparator} ${props.addClass}`} style={props.style} />
  );
};

export const SectionSeparatorAtom = withStyles(sectionSeparatorStyles)(
  SectionSeparatorAtomUnstyled
) as React.FC<SectionSeparatorProps>;
