import { IconButton } from 'components/ions';
import { SmallIcon } from 'components/ions/Icons';
import { TextBody, TextBodySmall } from 'components/ions/Text';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { approvalsListStyles } from './ApprovalsList.styles';

const placeholder = '-';

export enum ApprovalStatus {
  accepted = 'CheckMark',
  history = 'History',
  rejected = 'Cancel',
  waiting = 'Waiting',
  unknown = 'Unknown',
}
export interface ApprovalItem {
  name: string;
  status?: ApprovalStatus;
  days: number | string;
  position: string;
}

export interface ApprovalListItem {
  policy: string;
  approvalItems: ApprovalItem[];
}

export interface ApprovalsListProps {
  id?: string;
  largerText?: boolean;
  approval: ApprovalListItem;
  dataAutomationId?: string;
  getApprovalDaysElapsedText: (days: number) => string;
}

type Props = ApprovalsListProps & WithStyles<typeof approvalsListStyles>;

const ApprovalsListUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, id, dataAutomationId, approval, largerText, getApprovalDaysElapsedText } = props;
  const [isApproversListExpanded, setIsApproversListExpanded] = React.useState(false);
  const { t } = useTranslation();

  const onToggle = () => {
    setIsApproversListExpanded(!isApproversListExpanded);
  };

  const renderStatus = (approver: ApprovalItem, isApprovedOrRejected: boolean) => {
    switch (approver.status) {
      case ApprovalStatus.accepted: {
        return (
          <SmallIcon
            addClass={classes.accepted}
            dataAutomationId="approvalAcceptedCheckmark"
            iconName={ApprovalStatus.accepted}
          />
        );
      }
      case ApprovalStatus.rejected: {
        return <SmallIcon addClass={classes.rejected} iconName={ApprovalStatus.rejected} />;
      }
      case ApprovalStatus.waiting: {
        return approver.days === placeholder && !isApprovedOrRejected ? (
          <SmallIcon addClass={classes.history} iconName={ApprovalStatus.history} />
        ) : (
          !isApprovedOrRejected && <span>{placeholder}</span>
        );
      }
      default: {
        return placeholder;
      }
    }
  };

  const renderStatusText = (approver: ApprovalItem, collapseList: boolean) => {
    const statusClass = collapseList ? classes.waitingTimeTextExtra : classes.waitingTimeText;
    switch (approver.status) {
      case ApprovalStatus.rejected: {
        return (
          <div className={classes.column}>
            <TextBodySmall addClass={mergeClassNames([classes.rejected, statusClass])}>
              {t('quote::Rejected')}
            </TextBodySmall>
          </div>
        );
      }
      case ApprovalStatus.waiting: {
        const waitingDays = approver && Number(approver.days);
        return (
          <div className={classes.column}>
            <TextBodySmall addClass={statusClass}>
              {getApprovalDaysElapsedText(waitingDays)}
            </TextBodySmall>
          </div>
        );
      }
      default: {
        return;
      }
    }
  };

  const renderApprovalItems = (
    approval: ApprovalListItem,
    collapseList: boolean,
    isApprovedOrRejected: boolean
  ) => {
    return approval.approvalItems.map((approver, key) => {
      return (
        <div className={classes.row} key={key}>
          {approver.status && (
            <div className={classes.statusColumn}>
              {!largerText ? (
                <TextBodySmall>{renderStatus(approver, isApprovedOrRejected)}</TextBodySmall>
              ) : (
                <TextBody>{renderStatus(approver, isApprovedOrRejected)}</TextBody>
              )}
            </div>
          )}
          {collapseList && <div className={classes.chevronColumn}></div>}
          {approver.name && (
            <div className={classes.column}>
              {largerText ? (
                <TextBody addClass={classes.name} title={approver.name}>
                  {approver.name}
                </TextBody>
              ) : (
                <TextBodySmall addClass={classes.name} title={approver.name}>
                  {approver.name}
                </TextBodySmall>
              )}
            </div>
          )}
          <div className={classes.column}>
            {largerText ? (
              <TextBody addClass={classes.lightText}>{approver.position || placeholder}</TextBody>
            ) : (
              <TextBodySmall addClass={classes.lightText}>
                {approver.position || placeholder}
              </TextBodySmall>
            )}
          </div>
        </div>
      );
    });
  };

  const chevron = isApproversListExpanded ? 'ChevronDown' : 'ChevronRight';
  const numberOfApprovers = approval.approvalItems && approval.approvalItems.length;
  const collapseApproversList = numberOfApprovers > 7;
  const position = collapseApproversList && approval.approvalItems[0].position;
  const approvedOrRejected = approval.approvalItems.find(
    item => item.status === ApprovalStatus.accepted || item.status === ApprovalStatus.rejected
  );
  const overallWaitTime = approval.approvalItems.find(
    item => !!item.days && item.days !== '-' && item.name
  );
  const overallStatus =
    (approvedOrRejected && renderStatus(approvedOrRejected, true)) || placeholder;
  return (
    <div
      className={largerText ? classes.listContainerHwa : classes.approvalListContainer}
      data-automation-id={dataAutomationId}
      id={id}
    >
      <div className={classes.row}>
        <div className={classes.column}>
          <TextBodySmall addClass={classes.actor}>{approval.policy}</TextBodySmall>
        </div>
        {overallWaitTime &&
          !approvedOrRejected &&
          renderStatusText(overallWaitTime, collapseApproversList)}
        {approvedOrRejected && renderStatusText(approvedOrRejected, collapseApproversList)}
      </div>
      {collapseApproversList && (
        <div className={classes.row}>
          <div className={!approvedOrRejected ? classes.statusChevronColumn : classes.statusColumn}>
            {!largerText ? (
              <TextBodySmall>{overallStatus}</TextBodySmall>
            ) : (
              <TextBody>{overallStatus}</TextBody>
            )}
          </div>
          <div className={classes.chevronColumn}>
            <IconButton addClass={classes.chevron} iconName={chevron} onClick={onToggle} />
          </div>
          <div className={classes.column}>
            <TextBodySmall>{`${numberOfApprovers} approvers`}</TextBodySmall>
          </div>
          <div className={classes.column}>
            {!largerText ? (
              <TextBodySmall addClass={classes.lightText}>{position}</TextBodySmall>
            ) : (
              <TextBody addClass={classes.lightText}>{position}</TextBody>
            )}
          </div>
        </div>
      )}
      {((collapseApproversList && isApproversListExpanded) || !collapseApproversList) &&
        renderApprovalItems(approval, collapseApproversList, !!approvedOrRejected)}
    </div>
  );
};

export const ApprovalsList = withStyles(approvalsListStyles)(ApprovalsListUnstyled) as React.FC<
  ApprovalsListProps
>;
