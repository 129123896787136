import {
  InfoButton,
  MediumIcon,
  Spinner,
  SpinnerSize,
  SystemError,
  TextBody,
  TextWatermark,
} from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { TenantScenario } from '../Wizards';
import { ExistingOwner, LoadingErrorType } from './ExistingOwners.types';
import { existingOwnersStyles } from './ExistingOwnersList.styles';

export const LIST_TEST_IDENTIFIER = 'existing-owners-list';
export const LOADING_LIST_TEST_IDENTIFIER = 'existing-owners-list-loading';

export interface ExistingOwnersListProps {
  list: ExistingOwner[];
  error: LoadingErrorType;
  loading?: boolean;
  showTooltip?: boolean;
  maxHeight?: number;
  /**
   * Customize strings in the Info callout
   */
  specificScenario?: TenantScenario;
}

type Props = ExistingOwnersListProps & WithStyles<typeof existingOwnersStyles>;

const ExistingOwnersListUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  const reformatUpn = (upn: string) => {
    const userNameEndIndex = upn.indexOf('_');
    const domainNameEndIndex = upn.indexOf('#');
    const userName = upn.substring(0, userNameEndIndex);
    const domainName = upn.substring(userNameEndIndex + 1, domainNameEndIndex);
    return `${userName}@${domainName}`;
  };

  const guestedUserIcon = (
    <span title={t('quote::Guest User')}>
      <MediumIcon addClass={classes.guestedUserIcon} iconName="TemporaryUser" />
    </span>
  );

  const renderOwnersList = (list: ExistingOwner[]) => {
    return list.map((owner: ExistingOwner, index: number) => {
      // #EXT is shorthand for external and is present in guested user upns
      const guestedUserIdentifier = '#EXT';
      let upn = owner.upn;
      const isGuestedUser = upn.includes(guestedUserIdentifier);
      if (isGuestedUser) {
        upn = reformatUpn(upn);
      }
      return (
        <div className={classes.existingOwnersListRow} key={index}>
          <div className={classes.existingOwnersLeftColumn}>
            <TextBody addClass={classes.upn}>{upn}</TextBody>
            {isGuestedUser && guestedUserIcon}
          </div>
          <TextBody addClass={classes.existingOwnersRightColumn}>{owner.roles.join(', ')}</TextBody>
        </div>
      );
    });
  };

  const renderListScenario = () => {
    if (props.error === LoadingErrorType.ErrorLoadingEverything) {
      return (
        <SystemError
          mainMessage={t(
            'quote::We ran into an error and were unable to retrieve the existing owners on this tenant.'
          )}
        />
      );
    }
    return (
      <div className={classes.existingOwnersList} data-automation-id={LIST_TEST_IDENTIFIER}>
        {renderOwnersList(props.list)}
      </div>
    );
  };

  let headline: string;
  let ariaLabel: string;
  switch (props.specificScenario) {
    case TenantScenario.partner:
      headline = t('quote::Partner tenant owners');
      ariaLabel = t('quote::Open information about partner tenant');
      break;
    default:
      headline = t('quote::Customer tenant owners');
      ariaLabel = t('quote::Open information about customer tenant');
      break;
  }

  const tooltip = (
    <InfoButton
      ariaLabel={ariaLabel}
      calloutProps={{
        closeButtonAriaLabel: t('Close'),
        headline,
      }}
      id="tenant-owners"
    >
      <TextBody>
        {t(
          "quote::The owners and global admins listed here have the appropriate authorization on their company's tenant to be able to execute on all the components of the quote being transacted. Guested users are denoted with an icon."
        )}
      </TextBody>
    </InfoButton>
  );

  const spinner = (
    <Spinner
      addClass={classes.spinner}
      dataAutomationId={LOADING_LIST_TEST_IDENTIFIER}
      size={SpinnerSize.large}
    />
  );

  const existingOwnersList = (
    <div className={classes.existingOwnersSection}>
      <TextBody addClass={classes.existingOwnersTitle}>{t('quote::Existing owners')}</TextBody>
      {props.showTooltip && tooltip}
      {renderListScenario()}
    </div>
  );

  const hasPartiallyLoaded =
    props.error === LoadingErrorType.ErrorLoadingOwners ||
    props.error === LoadingErrorType.ErrorLoadingAdmins;

  const partialListLoadMessage = (
    <TextWatermark>{t(`quote::Not all owners are listed`)}</TextWatermark>
  );

  const renderList = () => {
    if (props.loading) {
      return spinner;
    }
    return (
      <div>
        {existingOwnersList}
        {hasPartiallyLoaded && (
          <div className={classes.partialListLoadMessage}>{partialListLoadMessage}</div>
        )}
      </div>
    );
  };

  return renderList();
};

export const ExistingOwnersList = withStyles(existingOwnersStyles)(ExistingOwnersListUnstyled);
