import { ThemeProps } from 'styles';
import { buttonSharedStyles } from '../shared.styles';

export const splitButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    ...buttonSharedStyles(theme),
    split: {
      paddingRight: 8,
      color: colors.text,
      paddingTop: 12,
      paddingBottom: 15,
      '&:hover': {
        backgroundColor: 'transparent',
        color: colors.textSecondary,
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:focus': {
        backgroundColor: 'transparent',
        color: colors.textSecondary,
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:disabled': {
        color: colors.textDisabled,
        '& i': {
          color: colors.textDisabled,
        },
      },
      '& i': {
        color: colors.text,
      },
      '& .ms-Button-label': {
        marginRight: 0,
      },
    },
    splitMenuButton: {
      paddingLeft: 3,
      paddingRight: 4,
      minWidth: 0,
      '&:disabled i': {
        color: colors.textDisabled,
      },
    },
    divider: {
      backgroundColor: colors.backgroundDivider,
      display: 'inline-block',
      height: 16,
      width: 1,
      marginTop: 6,
    },
  };
};
