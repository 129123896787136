import {
  isAzureDuplicatedMessage,
  isEcifOneAskNotFoundMessage,
  isProductFailedMessage,
  isEcifVirtualLineItemMessage,
} from 'features-apollo/quote/selectors/general';
import { getLineItemMessages } from 'features-apollo/quote/selectors/lineItem';
import { LineItem, PurchaseLineItem } from 'generated/graphql';
import { oc } from 'ts-optchain';

export const getPurchaseProductQuantityBoundaries = (lineItem: PurchaseLineItem) => {
  const minQuantity = oc(lineItem).sku.quantity.minimum();
  const maxQuantity = oc(lineItem).sku.quantity.maximum();
  return maxQuantity != null && minQuantity != null
    ? {
        max: maxQuantity,
        min: minQuantity,
      }
    : undefined;
};

export const isPurchaseProductQuantifiable = (lineItem: PurchaseLineItem) => {
  const boundaries = getPurchaseProductQuantityBoundaries(lineItem);
  if (!boundaries) {
    return false;
  }
  return boundaries.max > boundaries.min;
};

/**
 * Gets error message source that requires a specific message for the list row
 */
export const getErrorForSpecificListRowMessage = (lineItem: LineItem) => {
  const messages = getLineItemMessages(lineItem);
  return messages.find(
    message =>
      isProductFailedMessage(message) ||
      isAzureDuplicatedMessage(message) ||
      isEcifVirtualLineItemMessage(message) ||
      isEcifOneAskNotFoundMessage(message)
  );
};
