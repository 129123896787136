import { ThemeProps } from 'styles';

export const listContainerDivStyles = (height: number | string) => {
  return { height, position: 'relative' };
};

export const stickyHeaderStyles = (theme: ThemeProps) => {
  return {
    header: {
      '& .ms-DetailsHeader-cellTitle': {
        marginTop: 10,
      },
      '& .ms-DetailsHeader-cell:hover': {
        background: theme.palette.backgroundPanel,
      },
      '& .ms-FocusZone.ms-DetailsHeader': {
        background: theme.palette.backgroundPanel,
        lineHeight: 1.5,
        paddingBottom: 7,
      },
      '& .ms-DetailsHeader-cellName': {
        whiteSpace: 'normal',
        lineHeight: 1.5,
      },
    },
    removeRowSeparators: {
      '& .ms-FocusZone.ms-DetailsRow': {
        borderBottom: 0,
      },
    },
  };
};
