import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';
import {
  endAdd,
  endSelect,
  priceCeilingAdd,
  priceCeilingSelect,
  startAdd,
  startSelect,
} from './reducer.utils';
import { DiscountCardContainerState } from './types';
import { getDiscountError, selectOption } from './utils';

export const reducer = (
  state: DiscountCardContainerState,
  action: ActionType<typeof actions>
): DiscountCardContainerState => {
  const { startAndEndDatesAreDayPickers } = state;
  switch (action.type) {
    case getType(actions.updateDiscountType): {
      const discountError = getDiscountError(state.discountAmount, action.payload);
      const error = discountError && state.discountFieldEdited ? discountError : undefined;
      return { ...state, discountType: action.payload, discountError: error };
    }
    case getType(actions.futureStartAdd): {
      const { newStartOptions, newEndOptions } = startAdd(
        action.payload,
        startAndEndDatesAreDayPickers,
        state.futureStartOptions,
        state.futureEndOptions
      );
      return { ...state, futureStartOptions: newStartOptions, futureEndOptions: newEndOptions };
    }

    case getType(actions.futureStartSelect): {
      const { newStartOptions, newEndOptions } = startSelect(
        action.payload,
        startAndEndDatesAreDayPickers,
        state.futureStartOptions,
        state.futureEndOptions
      );
      return { ...state, futureStartOptions: newStartOptions, futureEndOptions: newEndOptions };
    }

    case getType(actions.futureEndAdd): {
      const newEndOptions = endAdd(
        action.payload,
        startAndEndDatesAreDayPickers,
        state.futureEndOptions
      );
      return { ...state, futureEndOptions: newEndOptions };
    }

    case getType(actions.futureEndSelect): {
      const newEndOptions = endSelect(
        action.payload,
        startAndEndDatesAreDayPickers,
        state.futureStartOptions,
        state.futureEndOptions
      );
      return { ...state, futureEndOptions: newEndOptions };
    }

    case getType(actions.futureMetersSelect): {
      const futureMetersOptions = selectOption(action.payload, state.futureMetersOptions.options);
      return { ...state, futureMetersOptions };
    }
    case getType(actions.ceilingPriceCeilingAdd): {
      const {
        newPriceCeilingOptions,
        newStartCeilingOptions,
        newEndCeilingOptions,
      } = priceCeilingAdd(
        action.payload,
        state.ceilingPriceCeilingOptions,
        state.ceilingStartOptions,
        state.ceilingEndOptions
      );
      return {
        ...state,
        ceilingPriceCeilingOptions: newPriceCeilingOptions,
        ceilingStartOptions: newStartCeilingOptions,
        ceilingEndOptions: newEndCeilingOptions,
      };
    }
    case getType(actions.ceilingPriceCeilingSelect): {
      const {
        newPriceCeilingOptions,
        newCeilingStartOptions,
        newCeilingEndOptions,
      } = priceCeilingSelect(
        action.payload,
        state.ceilingPriceCeilingOptions,
        state.ceilingStartOptions,
        state.ceilingEndOptions
      );
      return {
        ...state,
        ceilingPriceCeilingOptions: newPriceCeilingOptions,
        ceilingStartOptions: newCeilingStartOptions,
        ceilingEndOptions: newCeilingEndOptions,
      };
    }

    case getType(actions.ceilingStartAdd): {
      const { newStartOptions, newEndOptions } = startAdd(
        action.payload,
        startAndEndDatesAreDayPickers,
        state.ceilingStartOptions,
        state.ceilingEndOptions
      );
      return { ...state, ceilingStartOptions: newStartOptions, ceilingEndOptions: newEndOptions };
    }
    case getType(actions.ceilingStartSelect): {
      const { newStartOptions, newEndOptions } = startSelect(
        action.payload,
        startAndEndDatesAreDayPickers,
        state.ceilingStartOptions,
        state.ceilingEndOptions
      );
      return { ...state, ceilingStartOptions: newStartOptions, ceilingEndOptions: newEndOptions };
    }

    case getType(actions.ceilingEndAdd): {
      const newCeilingEndOptions = endAdd(
        action.payload,
        startAndEndDatesAreDayPickers,
        state.ceilingEndOptions
      );
      return { ...state, ceilingEndOptions: newCeilingEndOptions };
    }
    case getType(actions.ceilingEndSelect): {
      const newCeilingEndOptions = endSelect(
        action.payload,
        startAndEndDatesAreDayPickers,
        state.ceilingStartOptions,
        state.ceilingEndOptions
      );
      return { ...state, ceilingEndOptions: newCeilingEndOptions };
    }

    case getType(actions.discountAmountChange): {
      return { ...state, discountAmount: action.payload, discountFieldEdited: true };
    }

    case getType(actions.discountAmountErrorChange): {
      const discountError = getDiscountError(action.payload, state.discountType);
      const error = discountError && state.discountFieldEdited ? discountError : undefined;
      return { ...state, discountError: error };
    }

    default: {
      return state;
    }
  }
};
