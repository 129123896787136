import axios, { AxiosResponse } from 'axios';
import { Language } from 'features/proposal/supported-languages';
import { Market } from 'features/proposal/supported-markets';
import { createGuid, getAuthHeader, getCV, getTestHeader, TokenAuthorities } from 'services/utils';

import { AgreementConfig, aptTemplateIds, endpoints, mcaTemplateIds } from './config';
import {
  Agreement,
  AgreementPreviewRequest,
  AgreementPreviewResponse,
  GetAgreementRequest,
  UploadDocumentsRequest,
  UploadDocumentsResponse,
} from './types';

async function getHeaders(
  config: AgreementConfig,
  language?: Language,
  contentType?: string,
  contentDisposition?: string
): Promise<Record<string, string>> {
  const token = await getAuthHeader(TokenAuthorities.Agreements);
  const headers: Record<string, string> = {
    authorization: token,
    'x-ms-correlation-id': createGuid(),
    'MS-CV': getCV(),
  };
  if (language) {
    headers['accept-language'] = language;
  }
  if (contentType) {
    headers['Content-Type'] = contentType;
  }
  if (contentDisposition) {
    headers['Content-Disposition'] = contentDisposition;
  }
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export const getAgreementPreviewUri = async (
  agreementConfig: AgreementConfig,
  proposalId: string,
  market: Market,
  language: Language,
  hasNegotiatedTerms: boolean,
  customerId?: string
): Promise<string | undefined> => {
  const agreementsUrl = `${endpoints[agreementConfig.environment]}/previewDocuments`;

  const body: AgreementPreviewRequest = {
    TemplateId: mcaTemplateIds[agreementConfig.environment],
    LanguageLocale: language,
    Constraints: {
      Tenant: 'Public',
      Segment: 'Commercial',
      Country: market,
    },
    AssemblyOptions: {
      Watermark: true, //todo: in future watermark may be defined by quote status
    },
  };

  if (hasNegotiatedTerms) {
    body.ExternalReference = {
      ExternalReferenceType: 'Quote',
      Id: proposalId,
    };
  }

  if (customerId) {
    body.CustomerId = customerId;
  }

  const headers = await getHeaders(agreementConfig, language);
  const response = await axios.post(agreementsUrl, body, { headers });
  return response.data.documentDisplayUri;
};

export const getAgreementPreview = async (
  request: AgreementPreviewRequest,
  agreementConfig: AgreementConfig
): Promise<AxiosResponse<AgreementPreviewResponse>> => {
  const agreementsUrl = `${endpoints[agreementConfig.environment]}/previewDocuments`;
  const headers = await getHeaders(agreementConfig, request.LanguageLocale);
  const response = await axios.post(agreementsUrl, request, { headers });
  return response;
};

export async function uploadAgreementDocuments(
  request: UploadDocumentsRequest,
  agreementConfig: AgreementConfig
): Promise<UploadDocumentsResponse> {
  const contentDisposition = `form-data; name="SignedDocument"; filename="${request.fileName}"`;
  const headers = await getHeaders(
    agreementConfig,
    undefined,
    request.contentType,
    contentDisposition
  );
  let url = `${endpoints[agreementConfig.environment]}/agreements/${
    request.agreementNumber
  }/documents/${request.documentType}?customerId=${request.customerId}`;
  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: request.formData,
  });
  return response.json();
}

export const getAgreements = async (
  request: GetAgreementRequest,
  agreementConfig: AgreementConfig
): Promise<Agreement[]> => {
  const agreementsUrl = `${endpoints[agreementConfig.environment]}/agreements`;
  const headers = await getHeaders(agreementConfig);
  const params = {
    customerId: `${request.accountId}_${request.organizationId}`,
    $filter: `Status eq 'Active' and Template/Id eq '${
      mcaTemplateIds[agreementConfig.environment]
    }' or Status eq 'Active' and Template/Id eq '${aptTemplateIds[agreementConfig.environment]}'`,
  };
  const response = await axios.get<Agreement[]>(agreementsUrl, { headers, params });
  return response.data;
};

export const getSignedAPT = async (
  request: GetAgreementRequest,
  agreementConfig: AgreementConfig
): Promise<Agreement[]> => {
  const agreementsUrl = `${endpoints[agreementConfig.environment]}/agreements`;
  const headers = await getHeaders(agreementConfig);
  const params = {
    customerId: `${request.accountId}_${request.organizationId}`,
    $filter: `Status eq 'Active' and Template/Id eq '${
      aptTemplateIds[agreementConfig.environment]
    }'`,
  };
  const response = await axios.get<Agreement[]>(agreementsUrl, { headers, params });
  return response.data;
};
