import {
  PrimaryButton,
  TextBodySmall,
  SecondaryButton,
  TextboxStandard,
  Spinner,
} from 'components';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { copyInputButtonStyles } from './CopyInputButton.styles';
import { ITextField, SpinnerSize } from 'office-ui-fabric-react';

const TIMEOUT = 2000;

export interface CopyInputButtonProps {
  inputValue: string;
  titleText: string;
  buttonText: string;
  labelText?: string;
  copiedText: string;
  useSecondaryButton?: boolean;
  errorMessage?: JSX.Element;
  processing?: boolean;
  dataAutomationId?: string;
}

type Props = CopyInputButtonProps & WithStyles<typeof copyInputButtonStyles>;

const CopyInputButtonUnstyled: React.FC<Props> = props => {
  const {
    classes,
    titleText,
    inputValue,
    buttonText,
    labelText,
    useSecondaryButton,
    copiedText,
    dataAutomationId,
  } = props;
  const inputRef: React.MutableRefObject<ITextField | null> = React.useRef(null);
  const [copyButtonText, setCopyButtonText] = React.useState<string>(buttonText);

  let timer: NodeJS.Timeout;

  const handleCopyClick = () => {
    clearTimeout(timer);

    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      setCopyButtonText(copiedText);
    }

    timer = setTimeout(() => {
      setCopyButtonText(buttonText);
    }, TIMEOUT);
  };

  React.useEffect(() => {
    if (inputValue) {
      setCopyButtonText(buttonText);
    }
  }, [inputValue, buttonText]);

  const inputClass = !!props.errorMessage ? classes.errorInput : classes.input;
  return (
    <div className={classes.container}>
      {labelText && <TextBodySmall addClass={classes.lightText}>{labelText}</TextBodySmall>}
      {props.processing ? (
        <div className={classes.innerRow}>
          <Spinner addClass={classes.spinner} size={SpinnerSize.medium} />
        </div>
      ) : (
        <div className={classes.innerRow}>
          <TextboxStandard
            addClass={inputClass}
            componentRef={inputRef}
            dataAutomationId={dataAutomationId}
            errorMessage={props.errorMessage}
            readOnly
            title={titleText}
            value={inputValue}
          />
          {useSecondaryButton ? (
            <SecondaryButton
              dataAutomationId="copyLinkButton"
              disabled={!!props.errorMessage}
              id="copyLinkButton"
              text={copyButtonText}
              onClick={handleCopyClick}
            />
          ) : (
            <PrimaryButton
              dataAutomationId="copyLinkButton"
              disabled={!!props.errorMessage}
              id="copyLinkButton"
              text={copyButtonText}
              onClick={handleCopyClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const CopyInputButton = withStyles(copyInputButtonStyles)(CopyInputButtonUnstyled);
