import { CollapsibleSection, TextBodySmall } from 'components';
import { ApprovalLevelState } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { getStatusText, renderApprovalStateIcon } from './utils';

export interface AssignedUserState {
  /**
   * Approver email's username
   */
  assignedUser: string;
  /**
   * Approver's approval action
   */
  state?: ApprovalLevelState;
}

export interface RequiredApprovalsPerPolicy {
  /**
   * Displays as header
   */
  policy: string;
  /**
   * Displays next to approvers information
   */
  level: string;
  /**
   * Displays next to policy
   */
  days?: number;
  /**
   * Displays next to total amount of approvers
   */
  state?: ApprovalLevelState | null;
  /**
   * List of approvers and approval actions
   */
  assignedUsers: AssignedUserState[];
}

const styles = (theme: ThemeProps) => {
  const leftContent = {
    width: 124,
  };
  return {
    assignedUser: { alignSelf: 'center' },
    assignedUserContainer: { ...leftContent, display: 'flex' },
    collapsibleSectionButtonRoot: { height: 18, padding: 0, left: -4 },
    collapsibleSectionIconRoot: { height: 'fit-content' },
    collapsibleSectionRoot: { height: 'fit-content', fontSize: theme.fonts.fontSizes.small },
    dangerColor: { color: theme.palette.dangerText },
    approveColor: { color: theme.palette.approveText },
    iconContainer: {
      height: 18,
      maxWidth: 21, // odd number to get alignment
      minWidth: 21, // odd number to get alignment
      display: 'flex',
      alignItems: 'center',
    },
    content: { display: 'flex' },
    levelContainer: {
      display: 'flex',
      alignItems: 'center',
      height: 18,
      '& *': { color: theme.palette.textTertiary },
    },
    policyContainer: { display: 'flex', marginBottom: 8 },
    policy: { ...leftContent, display: 'block', fontWeight: theme.fonts.fontWeights.semiBold },
  };
};

type Props = RequiredApprovalsPerPolicy & WithStyles<typeof styles>;

const RequiredApprovalUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  const days = props.days !== undefined && (
    <TextBodySmall addClass={classes.dangerColor}>
      {t('quote::{{count}} day elapsed', {
        count: props.days,
        defaultValue_plural: '{{count}} days elapsed', // eslint-disable-line @typescript-eslint/camelcase
      })}
    </TextBodySmall>
  );

  const level = (
    <div className={classes.levelContainer}>
      <TextBodySmall>{props.level}</TextBodySmall>
    </div>
  );

  const assignedUsers = props.assignedUsers.map(({ assignedUser, state }) => {
    return (
      <div className={classes.assignedUserContainer} key={assignedUser}>
        <div className={classes.iconContainer}>{state && renderApprovalStateIcon(state)}</div>
        <TextBodySmall addClass={classes.assignedUser}>{assignedUser}</TextBodySmall>
      </div>
    );
  });

  let content;
  if (!props.state || !assignedUsers.length) {
    content = level;
  } else {
    switch (assignedUsers.length) {
      case 1:
        content = (
          <div className={classes.content}>
            {assignedUsers}
            {level}
          </div>
        );
        break;
      default:
        const label = t('quote::{{total}} approvers', { total: assignedUsers.length });
        content = (
          <div className={classes.content}>
            <div className={classes.assignedUserContainer}>
              <div className={classes.iconContainer}>{renderApprovalStateIcon(props.state)}</div>
              <CollapsibleSection
                label={label}
                styles={{
                  buttonRoot: classes.collapsibleSectionButtonRoot,
                  iconRoot: classes.collapsibleSectionIconRoot,
                  root: classes.collapsibleSectionRoot,
                }}
              >
                {assignedUsers}
              </CollapsibleSection>
            </div>
            {level}
          </div>
        );
        break;
    }
  }

  const policyStatus = props.state && getStatusText(props.state, t, classes);
  return (
    <div>
      <div className={classes.policyContainer}>
        <TextBodySmall addClass={classes.policy}>{props.policy}</TextBodySmall>
        {policyStatus ? policyStatus : days}
      </div>
      {content}
    </div>
  );
};

export const RequiredApproval = withStyles(styles)(RequiredApprovalUnstyled);
