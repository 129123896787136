import { SectionSeparator, SelectionSection } from 'components';
import { OrganizationInfo } from 'features/proposal/components/OrganizationInfo';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { OrganizationAddress } from 'services/account-extensions/types';
import { OrganizationSearchResponseItem } from 'services/edge-search/types';

// #region OrganizationSelector
const organizationSelectorStyles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

interface OrganizationSelectorProps {
  accountId: string;
  address: OrganizationAddress;
  lastPurchaseDateTime?: string;
  name: string;
  organizationId: string;
  readOnly?: boolean;
  tradeName?: string;
  onSelectOrganization: (organizationId: string, accountId: string) => void;
}

const OrganizationSelectorUnStyled: React.FC<OrganizationSelectorProps &
  WithStyles<typeof organizationSelectorStyles>> = props => {
  const { t } = useTranslation();

  const onClick = () => {
    props.onSelectOrganization(props.organizationId, props.accountId);
  };

  return (
    <SelectionSection
      buttonText={t('quote::Select this billing account')}
      dataAutomationId="selectThisBillingAccount"
      disabled={props.readOnly}
      onClick={onClick}
    >
      <OrganizationInfo
        address={props.address}
        lastPurchaseDateTime={props.lastPurchaseDateTime}
        maxWidth={200}
        name={props.name}
        tradeName={props.tradeName}
      />
    </SelectionSection>
  );
};
const OrganizationSelector = withStyles(organizationSelectorStyles)(OrganizationSelectorUnStyled);
// #endregion

// #region Select Organization tool
export interface SelectOrganizationProps {
  organizationSuggestions: OrganizationSearchResponseItem[];
  onSelectOrganization: (organizationId: string, accountId: string) => void;
  limitTotalToDisplay?: number;
  readOnly?: boolean;
}

const selectOrganizationStyles = {
  separator: {
    marginBottom: 12,
    marginTop: 20,
  },
};

type Props2 = SelectOrganizationProps & WithStyles<typeof selectOrganizationStyles>;

const SelectOrganizationUnStyled: React.FC<Props2> = props => {
  const selectors = props.organizationSuggestions
    .slice(0, props.limitTotalToDisplay)
    .map((organizationItem, index) => {
      const name = organizationItem.organization.legalEntity.name;
      const selectorProps: OrganizationSelectorProps = {
        accountId: organizationItem.account.id,
        address: organizationItem.organization.legalEntity.businessLocation.address,
        lastPurchaseDateTime: organizationItem.purchase.lastPurchaseCreatedDateTime,
        name,
        organizationId: organizationItem.organization.id,
        readOnly: props.readOnly,
        tradeName: organizationItem.organization.legalEntity.tradeName,
        onSelectOrganization: props.onSelectOrganization,
      };

      return index === 0 ? (
        <OrganizationSelector {...selectorProps} key={`${name}-${index}`} />
      ) : (
        <React.Fragment key={`${name}-${index}`}>
          <SectionSeparator addClass={props.classes.separator} />
          <OrganizationSelector {...selectorProps} />
        </React.Fragment>
      );
    });

  return <>{selectors}</>;
};

export const SelectOrganization = withStyles(selectOrganizationStyles)(SelectOrganizationUnStyled);
// #endregion
