import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { routes } from 'routes';
import { RootState } from 'store/types';

import * as actions from '../actions';
import * as selectors from '../selectors';

const styles = {
  nav: {
    display: 'flex',
    justifyContent: 'space-around',
  },
};

const mapStateToProps = (state: RootState) => ({
  proposal: selectors.getActiveProposal(state),
  editor: selectors.getViews(state).editor,
});

const dispatchProps = {
  toggleSection: (section: string, newValue: boolean) =>
    newValue ? actions.addProposalSection(section) : actions.removeProposalSection(section),
};

type Props = {} & WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const SectionBar: React.FC<Props> = ({ proposal, classes, editor, toggleSection }: Props) => {
  const { t } = useTranslation();
  return (
    <nav className={classes.nav}>
      <NavLink hidden={!editor.sections['summary']} to={routes.quote.summaryForId(proposal.id)}>
        {t('quote::Summary')}
      </NavLink>
      <NavLink hidden={!editor.sections['products']} to={routes.quote.productsForId(proposal.id)}>
        {t('quote::Products')}
      </NavLink>
      <NavLink hidden={!editor.sections['terms']} to={routes.quote.termsForId(proposal.id)}>
        {t('quote::Terms')}
      </NavLink>
      <NavLink hidden={!editor.sections['solution']} to={routes.quote.solutionForId(proposal.id)}>
        {t('quote::Solution')}
      </NavLink>
      <NavLink
        hidden={!editor.sections['projection']}
        to={routes.quote.projectionForId(proposal.id)}
      >
        {t('quote::Cost projection')}
      </NavLink>
      <NavLink hidden={!editor.sections['attach']} to={routes.quote.attachForId(proposal.id)}>
        {t('quote::Attach files')}
      </NavLink>
      <span>
        <select
          value=""
          onChange={(e: React.FormEvent<HTMLSelectElement>) => {
            toggleSection(e.currentTarget.value, !editor.sections[e.currentTarget.value]);
            e.currentTarget.value = '';
          }}
        >
          <option value="">{t('quote::Toggle sections')}</option>
          <option value="summary">{t('quote::Summary')}</option>
          <option value="solution">{t('quote::Solution')}</option>
          <option value="projection">{t('quote::Cost projection')}</option>
          <option value="attach">{t('quote::Attach files')}</option>
        </select>
      </span>
    </nav>
  );
};

export default connect(mapStateToProps, dispatchProps)(withStyles(styles)(SectionBar));
