import { InfoDialogSlideProps } from 'components';
import i18next from 'i18next';

export const getFirstRunSlides = (t: i18next.TFunction): InfoDialogSlideProps[] => {
  const firstRunSlides: InfoDialogSlideProps[] = [
    {
      id: 'supportTicket',
      mediaUrl: '/images/FirstRun/supportTicket.png',
      mediaDescription: t('A person sitting at their desk working on a laptop.'),
      headline: t('A new way to get the support you need'),
      bodyText: t(
        'Now, to raise a support request, you must log a case from within Quote Center, where we now have built-in context-aware help and the ability to raise support requests right where you need it.'
      ),
      enabled: true,
    },
  ];
  return firstRunSlides;
};
