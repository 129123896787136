import axios from 'axios';
import { createGuid, getAuthHeader, TokenAuthorities } from 'services/utils';

import { CRMConfig, environmentConfiguration, externalIntegrationConfiguration } from './config';
import {
  AssociateSalesAccountMcApiIdRequest,
  Contact,
  CRMContactSearch,
  CRMIdSearch,
  CRMSearchResult,
  Engagement,
  Opportunity,
  SalesAccount,
} from './types';

async function getHeaders(subscriptionKey: string): Promise<Record<string, string>> {
  return {
    authorization: await getAuthHeader(TokenAuthorities.CRM),
    'Ocp-Apim-Subscription-Key': subscriptionKey,
    'x-ms-correlation-id': createGuid(),
  };
}

function createCRMSearchObject(id: string): CRMIdSearch {
  const search: CRMIdSearch = {
    SearchOnView: 4,
    SearchValues: {
      GlobalCrmId: id,
      Id: null,
    },
  };
  return search;
}

export async function getOpportunity(
  id: string,
  config: CRMConfig
): Promise<CRMSearchResult<Opportunity> | null> {
  const { environment } = config;
  const environmentConfig = environmentConfiguration[environment];
  const headers = await getHeaders(environmentConfig.opportunitySubscriptionKey);
  const host = environmentConfig.endpoint;

  const response = await axios.post<CRMSearchResult<Opportunity>>(
    `${host}/opportunity/v3/getopportunities`,
    createCRMSearchObject(id),
    {
      headers,
    }
  );
  return response.data.Results.length ? response.data : null;
}

export async function getEngagement(
  id: string,
  config: CRMConfig
): Promise<CRMSearchResult<Engagement> | null> {
  const { environment } = config;
  const environmentConfig = environmentConfiguration[environment];
  const headers = await getHeaders(environmentConfig.opportunitySubscriptionKey);
  const host = environmentConfig.endpoint;

  const response = await axios.post<CRMSearchResult<Engagement>>(
    `${host}/engagement/v3/getengagements`,
    createCRMSearchObject(id),
    {
      headers,
    }
  );
  return response.data.Results.length ? response.data : null;
}

export async function getSalesAccount(id: string, config: CRMConfig): Promise<SalesAccount> {
  const { environment } = config;
  const environmentConfig = environmentConfiguration[environment];
  const headers = await getHeaders(environmentConfig.salesaccountSubscriptionKey);
  const host = environmentConfig.endpoint;
  const response = await axios.get<SalesAccount>(`${host}/account/account/${id}`, {
    headers,
  });
  return response.data;
}

export async function getContact(id: string, config: CRMConfig): Promise<Contact | null> {
  const { environment } = config;
  const environmentConfig = environmentConfiguration[environment];
  const headers = await getHeaders(environmentConfig.opportunitySubscriptionKey);
  const host = environmentConfig.endpoint;

  const searchContact: CRMContactSearch = {
    IdList: [id],
    NumberOfRecords: 1,
  };

  const response = await axios.post<CRMSearchResult<Contact>>(
    `${host}/Contact/GetContactsByIds`,
    searchContact,
    {
      headers,
    }
  );
  return response.data.Results.length ? response.data.Results[0] : null;
}

/**
 * Patch Sales Account to modify the MCAPIID property. This request goes into a queue to be process.
 *
 * @param request Includes crmId from the sales account and string for the McApiId property
 * @param config Data required for service request
 *
 * @returns correlation id used on the request
 */
export async function addMcApiIdToSalesAccount(
  request: AssociateSalesAccountMcApiIdRequest,
  config: CRMConfig
): Promise<string | undefined> {
  const { environment } = config;
  const externalIntegrationConfig = externalIntegrationConfiguration[environment];
  const headers = await getHeaders(externalIntegrationConfig.subscriptionKey);
  const url = `${externalIntegrationConfig.endpoint}/${request.customerSalesAccountNumber}`;

  await axios.patch(url, request, { headers });
  return headers['x-ms-correlation-id'];
}

export async function getSalesAccountAssociationStatus(
  correlationId: string,
  config: CRMConfig
): Promise<string> {
  const { environment } = config;
  const externalIntegrationConfig = externalIntegrationConfiguration[environment];
  const headers = await getHeaders(externalIntegrationConfig.subscriptionKey);
  const url = `${externalIntegrationConfig.endpoint}/AssociationStatus/${correlationId}`;

  const response = await axios.get<string>(url, { headers });
  return response.data;
}
