import {
  CalloutCard,
  DetailsList,
  SectionSeparator,
  ShimmerForBackgroundCommon,
} from 'components/ions';
import { applyConfigurationCardWithDialog } from 'features-apollo/quote/components/ConfigCards/utils';
import { formatCurrency } from 'features-apollo/quote/components/utils';
import { removeTypeName } from 'features-apollo/utils';
import {
  ApplyConfigurationSingleSkuInput,
  CatalogAction,
  QuoteMutationInput,
  Sku,
} from 'generated/graphql';
import { useFabricSelectionSimple } from 'hooks/useFabricSelection/useFabricSelection';
import {
  CheckboxVisibility,
  ColumnActionsMode,
  DirectionalHint,
  IColumn,
  IObjectWithKey,
  Selection,
  SelectionMode,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { AddAsNewLineItemButton } from '../AddAsNewLineItemButton';
import { ApplyConfigurationSingleSku } from '../queries';
import { ConfigurationCardCommonProps, SkuConfigurationCardCommonProps, View } from '../types';
import { filterOutSkus } from '../utils';
import { styles } from './FallbackConfigurationCard.styles';
import { GetFallbackProduct } from './queries';

export interface FallbackConfigurationCardProps
  extends ConfigurationCardCommonProps,
    SkuConfigurationCardCommonProps {
  productId: string;
  lineItemId: string;
  quote: QuoteMutationInput;
  target: React.RefObject<HTMLSpanElement>;
  initialValues?: { skuId: string; availabilityId: string };
  skus: Sku[];
  readOnly?: boolean;
  onDismiss: () => void;
}

type Props = FallbackConfigurationCardProps & WithStyles<typeof styles>;

interface FallbackConfigurationResponse {
  getProduct: {
    skus: Sku[];
  };
}

interface ListItem extends Sku {
  data: { availabilityId: string; skuId: string };
  key: string;
  title: string;
  price?: string;
  selectable: boolean;
}

const getMatchedAvailability = (skuId: string, availabilityId: string, skus: Sku[]) => {
  const matchedSku = skus.find(sku => sku.skuId === skuId);
  if (!matchedSku) {
    throw new Error('no sku matched');
  }
  let matchedAvailabilities = matchedSku.availabilities.filter(
    availability => availability.availabilityId === availabilityId
  );
  if (matchedAvailabilities.length === 0) {
    loggerService.error({
      exception: {
        name: 'Fallback Card - No Availability on Apply',
        message: 'No Availability is found',
      },
    });
  } else {
    return matchedAvailabilities[0];
  }
};

// This card is the configuration card that opens when we don't know what to do with a product
// Ideally it should never be opened, but it can.
const FallbackConfigurationCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    productId,
    target,
    lineItemId,
    quote,
    initialValues,
    alreadyHasDiscount,
    productTitle,
    catalogContext,
    currency,
    classes,
    onDismiss,
  } = props;
  const { t } = useTranslation();
  const [applyConfiguration, { loading: applyLoading }] = useMutation(ApplyConfigurationSingleSku);
  const [view, setView] = React.useState<View>(View.CardContent);

  const dialogContext = React.useContext(DialogContext);

  const [availabilityId, setAvailabilityId] = React.useState<string | undefined>(undefined);
  const [initialSelectionIsSet, setInitialSelectionIsSet] = React.useState<boolean>(false);

  const [skuId, setSkuId] = React.useState<string | undefined>(
    initialValues && initialValues.skuId
  );
  const { data: skusData, loading: loadingPrices } = useQuery<FallbackConfigurationResponse>(
    GetFallbackProduct,
    {
      variables: {
        id: productId,
        catalogContext: removeTypeName(catalogContext),
        quoteId: quote.id,
      },
    }
  );
  const onSelectionChanged = (selection: Selection) => {
    const newSelection =
      selection &&
      (selection.getSelection()[0] as IObjectWithKey & {
        data: { skuId: string; availabilityId: string };
      });
    const data = newSelection && newSelection.data;
    if (data) {
      setSkuId(data.skuId);
      setAvailabilityId(data.availabilityId);
    } else {
      setSkuId(undefined);
      setAvailabilityId(undefined);
    }
  };

  const generateItems = (skus: Sku[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let items: ListItem[] = [];
    skus.forEach(sku => {
      sku.availabilities.forEach(availability => {
        const fullTitle = `${sku.title} ${availability.availabilityId}`;
        const item = {
          ...sku,
          data: { availabilityId: availability.availabilityId, skuId: sku.skuId },
          key: `${sku.skuId} ${availability.availabilityId}`,
          title: fullTitle,
          price:
            availability.price != null ? formatCurrency(availability.price.amount.toString()) : '-',
          selectable: true,
        };
        items.push(item);
      });
    });
    return items;
  };

  const selection = useFabricSelectionSimple(onSelectionChanged);

  React.useEffect(() => {
    loggerService.error({
      exception: {
        name: 'fallbackConfigurationCard',
        message: `Fallback Configuration card is opened with the ${productId}, this shouldnt happen`,
      },
    });
  }, [productId]);

  const skus = filterOutSkus(oc(skusData).getProduct.skus(props.skus));

  React.useEffect(() => {
    if (!initialSelectionIsSet && selection && skus && skus.length && initialValues && skuId) {
      const key = `${skuId} ${initialValues.availabilityId}`;
      selection.setItems(generateItems(skus));
      selection.setKeySelected(key, true, false);
      setInitialSelectionIsSet(true);
    }
  }, [skus, skuId, initialSelectionIsSet, initialValues, selection]);

  if (!skus) {
    return null;
  }

  const columns: IColumn[] = [
    // TODO: Ask this to Ben this might have unintended consequences to have blank name there
    {
      key: '',
      name: '',
      fieldName: 'title',
      minWidth: 0,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'Price',
      name: t('quote::Price ({{currency}})', { currency }),
      fieldName: 'price',
      minWidth: 0,
      onRender: item => {
        if (loadingPrices) {
          return <ShimmerForBackgroundCommon width={50} />;
        }
        return item.price;
      },
    },
  ];

  const skuList = (
    <DetailsList
      addClass={classes.detailsListStyles}
      checkboxVisibility={CheckboxVisibility.always}
      columns={columns}
      items={generateItems(skus)}
      selection={selection}
      selectionMode={props.readOnly ? SelectionMode.none : SelectionMode.single}
    />
  );

  const onApply = (skuId: string, availabilityId: string, configureAsNew: boolean) => {
    const availability = getMatchedAvailability(skuId, availabilityId, skus);
    if (!availability) {
      return;
    }
    const configuration: ApplyConfigurationSingleSkuInput = {
      action: CatalogAction.Purchase,
      skuId,
      availabilityId: availability.availabilityId,
      configureAsNew,
      lineItemId,
    };
    if (configureAsNew) {
      applyConfiguration({ variables: { quote, configuration } });
    } else if (alreadyHasDiscount) {
      setView(View.ConfirmationDialog);
      applyConfigurationCardWithDialog(
        () => {
          applyConfiguration({ variables: { quote, configuration } });
          onDismiss();
        },
        () => setView(View.CardContent),
        dialogContext
      );
    } else {
      applyConfiguration({ variables: { quote, configuration } });
      onDismiss();
    }
  };

  const isApplyButtonEnabled = skuId && availabilityId;

  return (
    <CalloutCard
      applyButtonDisabled={!isApplyButtonEnabled}
      applyButtonStrings={{ text: t('quote::Apply'), ariaLabel: t('quote::Apply') }}
      closeButtonAriaLabel={t('quote::Close')}
      directionalHint={DirectionalHint.rightCenter}
      headerText={productTitle}
      hidden={view === View.ConfirmationDialog}
      id="1234"
      isBeakVisible={true}
      isReadOnly={!!props.readOnly}
      minWidth={320}
      otherFooterButtons={
        <AddAsNewLineItemButton
          disabled={!isApplyButtonEnabled || props.readOnly}
          loading={applyLoading}
          onClick={() => {
            skuId && availabilityId && onApply(skuId, availabilityId, true);
          }}
        />
      }
      target={target}
      onApply={() => {
        skuId && availabilityId && onApply(skuId, availabilityId, false);
      }}
      onDismiss={onDismiss}
    >
      <div data-automation-id="skuList">{skuList}</div>
      <SectionSeparator />
    </CalloutCard>
  );
};

export const FallbackConfigurationCard = withStyles(styles)(FallbackConfigurationCardUnstyled);
