import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextNavigationSecondarySelected,
  TextTitle,
  VerificationField,
} from 'components';
import { meplaHistory } from 'createHistory';
import { Fail, Processing } from 'features/components';
import {
  getCrmError,
  ReferralDialogCrmLead,
  RefetchReferrals,
} from 'features/components/dialogs/Referral';
import { GetCrmForReferralDialog } from 'features/components/dialogs/Referral/Queries';
import { crmIDRegExp } from 'features/proposal/utils';
import { Referral, ReferralType } from 'generated/graphql';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { routes } from 'routes';
import { sleepCreator } from 'services/utils';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { CloseReferral } from '../../ReferralSummaryList/Queries';
import { deleteReferralDialogStyles } from '../DeleteReferralDialog/DeleteReferralDialog.styles';
import { DialogLayout, Success } from '../Shared';

enum DialogState {
  Request = 'Request',
  Processing = 'Processing',
  Success = 'Success',
  Fail = 'Fail',
  NoCrm = 'NoCrm',
}

const dimensions = {
  height: 255,
  width: 384,
};

export interface CloseReferralDialogProps {
  referral?: Referral;
  refetchReferrals?: RefetchReferrals;
}

type Props = CloseReferralDialogProps & WithStyles<typeof deleteReferralDialogStyles>;

const CloseReferralDialogUnstyled: React.FC<Props> = ({
  classes,
  referral,
  refetchReferrals,
}: Props) => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const isClaim = oc(referral).referralType() === ReferralType.Claim;
  const hasCrmId = !!oc(referral).crmLead();
  const [dialogState, setDialogState] = React.useState<DialogState>(
    hasCrmId ? DialogState.Request : DialogState.NoCrm
  );
  const [crmId, setCrmId] = React.useState<undefined | string>();
  const [lastCrmIdVerified, setLastCrmIdVerified] = React.useState<string | undefined>();

  const [
    getCrmId,
    { loading: crmLoading, error: crmCallFailed, data: crmLeadResponse },
  ] = useLazyQuery<{
    getCRMLead: ReferralDialogCrmLead;
  }>(GetCrmForReferralDialog);

  const crmLeadData = oc(crmLeadResponse).getCRMLead();

  const [closeReferralGQL] = useMutation(CloseReferral, {
    onCompleted: async () => {
      const sleep = sleepCreator(1000);
      await sleep();
      refetchReferrals && refetchReferrals.query({ variables: refetchReferrals.variables });
      meplaHistory.push(routes.home.referrals.closed);
      setDialogState(DialogState.Success);
    },
    onError: () => {
      setDialogState(DialogState.Fail);
    },
  });

  const closeDialog = () => {
    context.closeDialog();
  };

  const handleCloseReferral = () => {
    setDialogState(DialogState.Processing);
    if (lastCrmIdVerified) {
      closeReferralGQL({
        variables: {
          input: {
            id: oc(referral).id(''),
            crmId: lastCrmIdVerified.trim(),
            etag: oc(referral).etag(''),
          },
        },
      });
    } else {
      closeReferralGQL({
        variables: {
          input: {
            id: oc(referral).id(''),
            etag: oc(referral).etag(''),
          },
        },
      });
    }
  };

  const type = isClaim ? t('quote::claim') : t('quote::referral');
  const dialogHeader = (
    <DialogHeader
      closeButtonClass={classes.customDialogCloseButton}
      dataAutomationId="closeReferral"
      dialogClose={closeDialog}
      headerClass={classes.customDialogHeader}
    >
      <TextTitle>{t('quote::Close this {{type}}?', { type })}</TextTitle>
    </DialogHeader>
  );

  const referralName: string = oc(referral).name('');
  const requestLayout: DialogLayout = {
    header: dialogHeader,
    body: (
      <div className={classes.customDialogBody}>
        <Trans ns="common">
          <TextBody dataAutomationId={`close-${type}-body`}>
            Closing the {{ type }},{' '}
            <TextNavigationSecondarySelected>{{ referralName }}</TextNavigationSecondarySelected>,
            will set the baseline for measuring your influence. This action cannot be undone and
            will update the status on the {{ type }} to &quot;Closed&quot;.
          </TextBody>
        </Trans>
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <PrimaryButton
          autoFocus
          dataAutomationId="referralCloseButton"
          text={t('Close {{type}}', { type })}
          onClick={handleCloseReferral}
        />
        <SecondaryButton
          dataAutomationId="referralCloseCancelButton"
          text={t('Cancel')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  const requestDialog = (layout: DialogLayout) => (
    <CustomDialogBox
      bodySlot={layout.body}
      footerSlot={layout.footer}
      headerSlot={layout.header}
      {...dimensions}
      id={referral && referral.id}
    />
  );

  const processingDialog = (
    <Processing {...dimensions} message1={t('quote::Closing {{type}}...', { type })} />
  );

  const successDialog = (
    <Success
      {...dimensions}
      closeDialog={closeDialog}
      dataAutomationId="successCloseReferral"
      message={t('quote::The {{type}} has been closed.', { type })}
    />
  );

  const errorDialog = (
    <Fail
      {...dimensions}
      closeDialog={context.closeDialog}
      dataAutomationId="failedCloseReferral"
      hideTryAgainMessage={true}
      message={t('quote::Failed to close {{type}}. Please try again.', { type })}
      onTryAgainClick={() => handleCloseReferral()}
    />
  );

  const onVerifyCrmId = () => {
    if (crmId) {
      getCrmId({ variables: { id: crmId.trim() } });
      setLastCrmIdVerified(crmId);
    }
  };

  const lastVerifiedIsSameAsCurrent = crmId === lastCrmIdVerified;
  const shouldBeCheckedForErrors = lastVerifiedIsSameAsCurrent && !crmLoading;
  let crmError = shouldBeCheckedForErrors
    ? getCrmError(t, crmLeadData, !!crmCallFailed)
    : undefined;

  const isValidCrmId = !!(
    !crmLoading &&
    !crmCallFailed &&
    !crmError &&
    crmId &&
    crmLeadData &&
    lastVerifiedIsSameAsCurrent &&
    crmIDRegExp.test(crmId)
  );

  const noCrmAddedLayout: DialogLayout = {
    header: dialogHeader,
    body: (
      <div className={classes.customDialogBody}>
        <TextBody>{t('A valid CRM ID is required prior to closing the claim.')}</TextBody>
        <div className={classes.crmIdContainer}>
          <VerificationField
            allowTryAgain={!!crmCallFailed}
            buttonText={t('Try again')}
            dataAutomationId="crmIdVerificationField"
            debounceValue={0}
            errorMessage={crmError}
            errorMessageStyle={classes.crmIdErrorStyle}
            forceSyncTextBoxValueWithInternalValue={true}
            hideButton={!crmCallFailed}
            isError={!!crmError}
            isLoading={!!crmLoading}
            isVerified={isValidCrmId}
            lastVerified={lastCrmIdVerified}
            spinnerDataAutomationId={t('Loading')}
            textboxClassName={classes.crmIdWidth}
            textboxLabel={t('CRM ID')}
            textboxPlaceholder={t('Enter CRM ID')}
            textboxRequired={true}
            textboxValue={crmId}
            validationErrorMessage={t('Enter a valid CRM ID')}
            verifiedStatusText={t('Verified')}
            verifyOnBlur
            onChange={(value?: string) => {
              value !== undefined && setCrmId(value);
            }}
            onValidate={(value: string) => crmIDRegExp.test(value)}
            onVerify={onVerifyCrmId}
          />
        </div>
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <SecondaryButton
          dataAutomationId="referralNextButton"
          disabled={!isValidCrmId}
          text={t('quote::Next')}
          onClick={() => setDialogState(DialogState.Request)}
        />
      </div>
    ),
  };

  const getDialog = () => {
    switch (dialogState) {
      case DialogState.Processing:
        return processingDialog;
      case DialogState.Success:
        return successDialog;
      case DialogState.Fail:
        return errorDialog;
      case DialogState.NoCrm:
        return requestDialog(noCrmAddedLayout);
      default:
        return requestDialog(requestLayout);
    }
  };

  const currentDialog = getDialog();

  return <div>{currentDialog}</div>;
};

export const CloseReferralDialog = withStyles(deleteReferralDialogStyles)(
  CloseReferralDialogUnstyled
);
