import { LinkExternal, SecondaryButton, Spinner, TextTitle } from 'components';
import {
  catalogSearchLoading,
  getCatalogProducts,
  getCatalogNegotiatedTerms,
} from 'features/catalog/selectors';
import {
  CatalogPageProduct,
  CatalogPageRequest,
  CatalogPageNegotiatedTerm,
} from 'features/catalog/types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { ProductFamily, ServiceFamily } from 'services/catalog/types';
import { RootState } from 'store/types';

import { getProductRows } from './CatalogSectionRow';
import { featuredSectionStyles } from './shared.styles';

/**
 * Props for the Catalog Section
 * @prop {string} title - Title for the Catalog Section
 * @prop {string} linkText - Text to display for the section link
 * @prop {string} linkUrl - Url for the section link
 * @prop {ServiceFamily} serviceFamily - ServiceFamily to query catalog to populate section
 * @prop {ProductFamily} productFamily - ProductFamily to query catalog to populate section
 * @prop {string} catalogKey - market/language key for retrieving catalog products
 * @prop {function} onLoadCatalogProducts - function to call to retrieve additional catalog products
 */
export interface CatalogSectionProps {
  title: string;
  linkText?: string;
  linkUrl?: string;
  serviceFamily: ServiceFamily;
  productFamily: ProductFamily;
  catalogKey: string;
  onLoadCatalogProducts: (request: CatalogPageRequest) => void;
}

const mapStateToProps = (state: RootState, ownProps: CatalogSectionProps) => {
  const searchingCatalogProducts = catalogSearchLoading(state);
  const serviceFamilyResults = getCatalogProducts(
    state,
    ownProps.catalogKey,
    ownProps.serviceFamily
  );
  const products: CatalogPageProduct[] =
    (serviceFamilyResults &&
      Object.keys(serviceFamilyResults.products).map(key => {
        return serviceFamilyResults.products[key];
      })) ||
    [];
  const terms: CatalogPageNegotiatedTerm[] = getCatalogNegotiatedTerms(state);
  const isNegotiatedTerms = ownProps.productFamily === ProductFamily.NegotiatedTerms;
  return {
    searchingCatalogProducts,
    moreResultsAvailable: !!serviceFamilyResults && serviceFamilyResults.hasMorePages,
    products: isNegotiatedTerms ? terms : products,
    resultsToSkip: serviceFamilyResults && serviceFamilyResults.resultsToSkip,
  };
};

type Props = CatalogSectionProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof featuredSectionStyles>;

export const CatalogSectionUnstyled: React.FC<Props> = props => {
  const { onLoadCatalogProducts, productFamily, serviceFamily } = props;
  const [selectedProduct, setSelectedProduct] = React.useState<string | undefined>();

  const { t } = useTranslation();

  const handleLoadMoreOnClick = () => {
    onLoadCatalogProducts({
      productFamilyName: productFamily,
      serviceFamily: serviceFamily,
      resultsToSkip: props.resultsToSkip,
    });
  };

  const handleTileOnClick = (id: string) => {
    if (selectedProduct !== id) {
      setSelectedProduct(id);
    } else {
      setSelectedProduct(undefined);
    }
  };

  const handleDetailsOnClose = () => {
    setSelectedProduct(undefined);
  };

  const link = (
    <div className={props.classes.headerLink}>
      <LinkExternal
        dataAutomationId="catalogSectionLink"
        displayText={props.linkText}
        href={props.linkUrl}
        size="medium"
      />
    </div>
  );

  const showLink = !!(props.linkText && props.linkUrl);
  const isNegotiatedTermsSection = productFamily === ProductFamily.NegotiatedTerms;
  return props.searchingCatalogProducts && !(props.products && props.products.length) ? (
    <Spinner className={props.classes.spinner} />
  ) : (
    <div className={props.classes.featuredSection}>
      <div className={props.classes.header}>
        <TextTitle>{props.title}</TextTitle>
        {showLink && link}
      </div>
      <div>
        {props.products &&
          getProductRows(
            props.products,
            productFamily,
            handleDetailsOnClose,
            handleTileOnClick,
            selectedProduct
          )}
      </div>
      {props.moreResultsAvailable && !isNegotiatedTermsSection && (
        <div className={props.classes.loadMore}>
          <SecondaryButton
            dataAutomationId="loadMoreProductsButton"
            text={t('home::Load more products')}
            onClick={handleLoadMoreOnClick}
          />
        </div>
      )}
    </div>
  );
};

export const CatalogSection = connect(mapStateToProps)(
  withStyles(featuredSectionStyles)(CatalogSectionUnstyled)
);
