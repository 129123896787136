export const SearchResultStatusStyles = {
  noResultsMessage: {
    maxWidth: '60%',
    overflowWrap: 'break-word',
    paddingTop: 26,
  },
  noPermissionMessage: {
    maxWidth: 500,
  },
  clearSearchButton: {
    paddingLeft: 12,
  },
};
