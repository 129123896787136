export const discountCardFutureSectionStyles = {
  bottomMargin: {
    marginBottom: 0,
    marginTop: 16,
    width: '222px',
  },
  metersDropdown: {
    width: 174,
  },
  calendarDropdownMargin: {
    marginBottom: 12,
  },
  itallicComboboxInputStyle: {
    '& .ms-ComboBox-Input': {
      fontStyle: 'italic',
    },
  },
};
