import * as React from 'react';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
export const useDebounce = (debounceDuration: number) => {
  const ref = React.useRef(new Subject<() => void>());
  const function$ = ref.current;
  React.useEffect(() => {
    const sub = function$
      .pipe(
        debounceTime(debounceDuration),
        tap(obs => obs())
      )
      .subscribe();
    return function cleanup() {
      sub.unsubscribe();
    };
  }, [function$, debounceDuration]);
  return function$;
};
