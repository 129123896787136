import { SecondaryButton } from 'components';
import { GetModernAgreementPreview } from 'features-apollo/quote/components/queries';
import { getProduct } from 'features/catalog/selectors';
import { openAgreementPreview } from 'features/proposal/components/Dialogs';
import { RemoveEnrollmentDialog } from 'features/proposal/components/Dialogs/RemoveEnrollmentDialog/RemoveEnrollmentDialog';
import * as selectors from 'features/proposal/selectors';
import { getActiveProposal, proposalHasSAPTerm } from 'features/proposal/selectors/proposal';
import {
  getAgreementsErrorTerms,
  getGqlTermIdError,
  getInvalidTermsErrorMessage,
} from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { useLazyQuery } from '@apollo/react-hooks';

import { SubmitProposalDialog } from './SubmitProposalDialog';

const mapStateToProps = (state: RootState) => {
  const proposal = getActiveProposal(state);
  return {
    quote: proposal,
    isAnyBlockingNotification: selectors.isAnyBlockingNotification(state),
    disableSubmit: selectors.disableSubmit(state),
    enrollmentNumber: selectors.getEnrollmentNumber(state),
    proposalHasSAPTerm: proposalHasSAPTerm(state, proposal),
    getProductName: (productId: string) =>
      getProduct(state, productId).LocalizedProperties[0].ProductTitle,
  };
};

export type SubmitForApprovalButtonProps = ReturnType<typeof mapStateToProps>;

export const SubmitForApprovalButtonFeature: React.FC<SubmitForApprovalButtonProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const { disableSubmit, quote } = props;

  const disableButton = props.isAnyBlockingNotification || disableSubmit;

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <SubmitProposalDialog />,
  };

  const removeEnrollmentDialogProps: DialogProps = {
    providedDialog: <RemoveEnrollmentDialog submittingForApproval />,
  };

  // Verifies that we can successfully generate an agreement for the quote before allowing submit
  const [getAgreementPreview] = useLazyQuery(GetModernAgreementPreview, {
    onCompleted: () => {
      const showRemoveEnrollmentDialog = props.proposalHasSAPTerm && props.enrollmentNumber;
      context.openDialog(showRemoveEnrollmentDialog ? removeEnrollmentDialogProps : dialogProps);
    },
    onError: error => {
      let customError;
      const termsErrorResult = getGqlTermIdError(error);
      if (termsErrorResult.code && termsErrorResult.message) {
        const invalidTermIds = getAgreementsErrorTerms(
          termsErrorResult.code,
          termsErrorResult.message,
          quote.lineItems
        );
        const invalidTermNames = invalidTermIds.map(invalidTermId =>
          props.getProductName(invalidTermId)
        );
        customError = getInvalidTermsErrorMessage(
          t(`quote::Sorry, the "Submit for approval" action failed.`),
          invalidTermNames,
          t
        );
        loggerService.error({
          error: new Error(
            `Failed to submit quoteId ${quote.id} for approval. Invalid termId for 1 or more of the following: ${invalidTermNames}`
          ),
        });
      } else {
        loggerService.error({
          error: new Error(`Unable to generate the agreement for quote ${quote.id}`),
        });
      }
      openAgreementPreview(
        { id: 'agreement-preview-error-dialog', isError: true, customMessage: customError },
        context
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  // Loads empowerments and enable the dialog to be open
  const handleSubmitClick = () => {
    getAgreementPreview({
      variables: {
        quoteId: quote.id,
      },
    });
  };

  return (
    <SecondaryButton
      ariaLabel={t('quote::Submit the quote for approval')}
      dataAutomationId="submitForApprovalButton"
      disabled={disableButton}
      iconName="DocumentApproval"
      id="submit-for-approval-button"
      text={t('quote::Submit for approval')}
      onClick={handleSubmitClick}
    />
  );
};

export const SubmitForApprovalButton = connect(mapStateToProps)(SubmitForApprovalButtonFeature);
