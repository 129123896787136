import {
  AddButton,
  Callout,
  CalloutProps,
  CloseIconButton,
  CompoundButton,
  SectionSeparator,
  TextBody,
  TextBodyLarge,
} from 'components/ions';
import { SearchResultProduct } from 'features/catalog';
import { openCreateProductGroupDialog } from 'features/components/dialogs/FavoriteProductDialogs';
import { addProductToFavorites } from 'features/user/actions';
import { userFavoriteProductGroups } from 'features/user/selectors';
import {
  DirectionalHint,
  FocusZone,
  FocusZoneDirection,
  IButtonStyles,
  ICalloutContentStyles,
  IFocusTrapZoneProps,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { favoritesCalloutStyles } from './FavoritesCallout.styles';

const dispatchProps = {
  addProductToFavorites: addProductToFavorites.request,
};

/**
 * Props to FavoritesCallout component content and behavior
 * @prop {string} description - callout description
 * @prop {string} descriptionForGroups - description to display when groups are available (will overwrite description prop)
 * @prop {string} elementIdToFocusOnDismiss - Element id to refocus on when the callout dismiss
 * @prop {string} excludeGroupName - group to exclude from the list
 * @prop {boolean} excludeFavoriteGroup - do not display default group
 * @prop {boolean} hidden - Hides the callout
 * @prop {string} moveFrom - Group name from where the product should be move
 * @prop {string} product - Product from where the callout was open
 * @prop {HTMLDivElement | HTMLSpanElement | null} target - Element use as reference to determine position of the callout
 * @prop {string} title - Text for the header
 * @prop {function} onDismiss - Callback for when callout tries to close.
 */
export interface FavoritesCalloutProps {
  description: string;
  descriptionForGroups?: string;
  elementIdToFocusOnDismiss?: string;
  excludeGroupName?: string;
  hidden: boolean;
  moveFrom?: string;
  product: SearchResultProduct;
  target: HTMLDivElement | HTMLSpanElement | null;
  title: string;
  onDismiss: () => void;
}

/**
 * Retreives data from the Redux Store
 * @prop {ProductGroups} productGroupsAvailable - product groups available
 */
const mapStateToProps = (state: RootState, ownProps: FavoritesCalloutProps) => {
  const productGroups = userFavoriteProductGroups(state);

  const productGroupsAvailable = productGroups && { ...productGroups };

  if (productGroupsAvailable && ownProps.excludeGroupName) {
    delete productGroupsAvailable[ownProps.excludeGroupName];
  }

  return { productGroupsAvailable };
};

type Props = FavoritesCalloutProps &
  WithStyles<typeof favoritesCalloutStyles> &
  typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

const FavoritesCalloutUnStyled: React.FC<Props> = props => {
  const {
    classes,
    elementIdToFocusOnDismiss,
    hidden,
    moveFrom,
    onDismiss,
    product,
    productGroupsAvailable,
    target,
  } = props;
  const classNameForFocus = 'focusable';

  // #region Hooks
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);
  // #endregion

  // #region Props
  const calloutStyles: Partial<ICalloutContentStyles> = {
    calloutMain: props.classes.calloutMain,
    root: props.classes.root,
  };

  const focusTrapProps: IFocusTrapZoneProps = {
    firstFocusableSelector: classNameForFocus,
    forceFocusInsideTrap: false,
  };

  const calloutProps: CalloutProps = {
    directionalHint: DirectionalHint.rightCenter,
    focusTrapProps,
    hidden,
    isBeakVisible: true,
    onDismiss,
    preventDismissOnLostFocus: false,
    preventDismissOnScroll: false,
    target,
    trapFocus: true,
    setInitialFocus: false,
    styles: calloutStyles,
  };
  // #endregion

  // #region Elements
  const compoundButtonStyles: IButtonStyles = {
    description: classes.groupButtonSecondaryText,
    flexContainer: classes.groupButtonFlexContainer,
    label: classes.groupButtonLabel,
    textContainer: classes.groupButtonTextContainer,
  };

  const existingGroupsButtons: React.ReactNode[] | undefined =
    productGroupsAvailable &&
    Object.keys(productGroupsAvailable).map(groupName => (
      <React.Fragment key={`add-product-to-group-${groupName}`}>
        <CompoundButton
          addClass={`${classNameForFocus} ${classes.groupButton}`}
          dataAutomationId={`favoritesCallout-${groupName}`}
          secondaryText={t('{{count}} product', {
            count: productGroupsAvailable && productGroupsAvailable[groupName].products.length,
            defaultValue_plural: '{{count}} products', // eslint-disable-line @typescript-eslint/camelcase
          })}
          styles={compoundButtonStyles}
          onClick={() => {
            props.addProductToFavorites({ product, groupName, moveFrom });
          }}
        >
          {groupName}
        </CompoundButton>
      </React.Fragment>
    ));

  const existingGroups = existingGroupsButtons && !!existingGroupsButtons.length && (
    <FocusZone direction={FocusZoneDirection.vertical}>
      <div className={classes.groupButtons}>{existingGroupsButtons}</div>
      <SectionSeparator addClass={classes.separator} />
    </FocusZone>
  );

  const closeButton = onDismiss && (
    <CloseIconButton
      addClass={classes.closeButton}
      ariaLabel={t('Close')}
      dataAutomationId="closeButton"
      onClick={onDismiss}
    />
  );
  // #endregion

  return (
    <Callout {...calloutProps}>
      <div
        className={
          existingGroups ? `${classes.content} ${classes.reduceBottomPadding}` : classes.content
        }
      >
        <div className={classes.header}>
          <TextBodyLarge addClass={classes.title}>{props.title}</TextBodyLarge>
          {closeButton}
        </div>
        <TextBody>
          {existingGroups && props.descriptionForGroups
            ? props.descriptionForGroups
            : props.description}
        </TextBody>
        <div>
          {existingGroups}
          <AddButton
            addClass={`focusable ${classes.createGroupButton}`}
            dataAutomationId={`createGroup${product.productName}`}
            predefinedIconName="Add"
            text={t('Create new group')}
            onClick={() =>
              openCreateProductGroupDialog(dialogContext, {
                elementIdToFocusOnDismiss,
                moveFrom,
                product,
              })
            }
          />
        </div>
      </div>
    </Callout>
  );
};

export const FavoritesCalloutStyled = withStyles(favoritesCalloutStyles)(
  FavoritesCalloutUnStyled
) as React.FC<FavoritesCalloutProps>;

export const FavoritesCallout = connect(mapStateToProps, dispatchProps)(FavoritesCalloutStyled);
