import { Dialog, PrimaryButton } from 'components';
import { Dimensions, Fail, Processing } from 'features/components/dialogs';
import { renameProductGroup } from 'features/user/actions';
import { processingUserPreferencesUpdate } from 'features/user/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';

import { GroupNameField } from './GroupNameField';

const dimensions: Dimensions = {
  height: 220,
  width: 424,
};

const dispatchProps = {
  renameProductGroup: renameProductGroup.request,
};

const mapStateToProps = (state: RootState) => {
  const { error, loading } = processingUserPreferencesUpdate(state);

  const isProductGroupRenamed = (groupName: string) =>
    !!(state.user.preferences.productGroups && state.user.preferences.productGroups[groupName]);

  return {
    error,
    isProductGroupRenamed,
    loading,
  };
};

const styles = (theme: ThemeProps) => ({
  texboxInstructions: {
    color: theme.palette.textTertiary,
    paddingLeft: 8,
  },
});

interface RenameProductGroupDialogProps {
  currentGroupName: string;
  elementIdToFocusOnDismiss?: string;
}

type Props = RenameProductGroupDialogProps &
  WithStyles<typeof styles> &
  typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

export const RenameProductGroupDialogUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [newGroupName, setNewGroupName] = React.useState<string | undefined>();
  const [renamingGroup, setRenamingGroup] = React.useState<boolean>(false);
  const context = React.useContext(DialogContext);

  const onClose = () => {
    context.closeDialog();

    if (props.elementIdToFocusOnDismiss) {
      const element = document.getElementById(props.elementIdToFocusOnDismiss);
      element && element.focus();
    }
  };

  const renameGroup = () => {
    if (newGroupName) {
      props.renameProductGroup({
        groupName: props.currentGroupName,
        newGroupName,
      });
      setRenamingGroup(true);
    }
  };

  // #region adding product states
  if (newGroupName && renamingGroup) {
    if (props.loading) {
      return (
        <Processing
          {...dimensions}
          message1={t('Renaming group to "{{newGroupName}}"', { newGroupName })}
        />
      );
    } else if (props.error) {
      return <Fail {...dimensions} closeDialog={onClose} message={t('Unable to rename group')} />;
    } else if (props.isProductGroupRenamed(newGroupName)) {
      onClose();
      return null;
    }
  }
  // #endregion

  return (
    <Dialog
      {...dimensions}
      closeDialog={onClose}
      footerButtons={
        <PrimaryButton
          dataAutomationId="productGroupDialogRenameGroup"
          disabled={!newGroupName}
          text={t('Rename grouping')}
          onClick={renameGroup}
        />
      }
      title={t('Rename product grouping')}
    >
      <GroupNameField
        onChange={(userInput?: string) => {
          setNewGroupName(userInput);
        }}
        onEnterKeyDown={renameGroup}
      />
    </Dialog>
  );
};

const RenameProductGroupDialogStyled = withStyles(styles)(RenameProductGroupDialogUnstyled);
const RenameProductGroupDialog = connect(
  mapStateToProps,
  dispatchProps
)(RenameProductGroupDialogStyled);

export const openRenameProductGroupDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: RenameProductGroupDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <RenameProductGroupDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
