import { sharedStyles, ThemeProps } from 'styles';

export const skipToMainStyles = (theme: ThemeProps) => {
  const { palette } = theme;

  return {
    link: {
      ...sharedStyles(theme).link,
      position: 'absolute',
      fontSize: theme.fonts.fontSizes.large,
      '&:focus': {
        background: palette.backgroundMain,
        color: palette.activeLink,
        padding: '24px',       
        position: 'inherit',
        height: '100%',
        textAlign: 'center',
        border: `1px dashed ${palette.primary}`,
      },
    },

  };
};
