import * as actions from 'features/customer/actions';
import { getMarketByCountryCode } from 'features/proposal/supported-markets';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'services';
import {
  OrganizationSearchResponse,
  OrganizationSearchResponseItem,
} from 'services/edge-search/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';

export function* searchOrganizations(query: string, size?: number) {
  yield put(actions.searchOrganizationsAsync.request(query));
  try {
    const config = yield select((state: RootState) => state.app.appConfig.edgeSearch);
    const response: OrganizationSearchResponse = yield call(
      api.edgeSearch.searchOrganizations,
      query,
      config,
      size
    );
    const withoutNullsOrUndefineds = response.items.filter(
      (searchResult: OrganizationSearchResponseItem) => !!searchResult
    );
    const resultsFromSupportedMarkets = withoutNullsOrUndefineds.filter(
      (searchResult: OrganizationSearchResponseItem) => {
        const country = searchResult.organization.legalEntity.businessLocation.address.country;
        return !!getMarketByCountryCode(country, true); // Expect country in organization address to always be country code
      }
    );

    response.items = resultsFromSupportedMarkets;
    yield put(actions.searchOrganizationsAsync.success(response));
    return response;
  } catch (err) {
    yield put(
      actions.searchOrganizationsAsync.failure({
        message: t('error::Error searching billing accounts'),
        exception: err,
      })
    );
  }
}
