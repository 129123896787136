import axios from 'axios';
import { Channel } from './types';
import { createGuid } from 'services/utils';

const endpoint = 'https://reco-public.rec.mp.microsoft.com/channels/reco/v8.0/lists/collection';
function getParams(): Record<string, string> {
  return {
    count: '25',
    clientType: 'QuoteCenter',
    market: 'US',
    language: 'EN',
    isTest: 'true',
    itemTypes: 'Apps',
    refinements: 'DisableItemFiltering',
    deviceFamily: 'Windows.Desktop',
    environment: window.location.hostname, //Prevent CORS/Cache clash
  };
}

function getHeaders(): Record<string, string> {
  return {
    'MS-RequestId': createGuid(),
    'MS-CorrelationId': createGuid(),
  };
}

export async function loadChannel(name: string): Promise<Channel> {
  const headers = getHeaders();
  const url = `${endpoint}/${name}`;
  const params = getParams();
  const response = await axios.get<Channel>(url, { params, headers });
  response.data.name = name;
  return response.data;
}
