import { createLineItems } from 'features/proposal/utils';
import loggerService from 'services/logger-service';
import { Column, Entities, AddLineItemArgs, CreateLineItemArgs, Args } from './types';

export const getHomeColumn = (entities: Entities, taskId: string): Column => {
  const columnId: string | undefined = entities.columnOrder.find((id: string) => {
    const column: Column = entities.columns[id];
    return column.itemIds.includes(taskId);
  });

  return entities.columns[columnId || ''];
};

export const multiSelectTo = (
  entities: Entities,
  selectedItemIds: string[],
  newTaskId: string
): string[] | undefined => {
  // Nothing already selected
  if (!selectedItemIds.length) {
    return [newTaskId];
  }

  const columnOfNew: Column = getHomeColumn(entities, newTaskId);
  const indexOfNew: number = columnOfNew.itemIds.indexOf(newTaskId);

  const lastSelected: string = selectedItemIds[selectedItemIds.length - 1];
  const columnOfLast: Column = getHomeColumn(entities, lastSelected);
  const indexOfLast: number = columnOfLast.itemIds.indexOf(lastSelected);

  // multi selecting to another column
  // select everything up to the index of the current item
  if (columnOfNew !== columnOfLast) {
    return columnOfNew.itemIds.slice(0, indexOfNew + 1);
  }

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return;
  }

  const isSelectingForwards: boolean = indexOfNew > indexOfLast;
  const start: number = isSelectingForwards ? indexOfLast : indexOfNew;
  const end: number = isSelectingForwards ? indexOfNew : indexOfLast;

  const inBetween: string[] = columnOfNew.itemIds.slice(start, end + 1);

  // everything in between needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd: string[] = inBetween.filter((itemId: string): boolean => {
    // if already selected or disabled: then no need to select it again
    return !selectedItemIds.includes(itemId) && entities.items[itemId].disabled === false;
  });

  const sorted: string[] = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined: string[] = [...selectedItemIds, ...sorted];

  return combined;
};

// create line item
const createToAdd = (lineItemArgs: AddLineItemArgs, lineItems: CreateLineItemArgs[]) => {
  createLineItems(
    lineItemArgs.isProposalReadonly,
    lineItemArgs.proposal,
    lineItemArgs.proposalServiceEndpoint,
    lineItems,
    lineItemArgs.addLineItem
  );
};

export const addSingleLineItem = ({ entities, source }: Args, lineItemArgs: AddLineItemArgs) => {
  // moving to a new list
  const home: Column = entities.columns[source.droppableId];

  // create new task
  const itemId: string = home.itemIds[source.index];
  const item = entities.items[itemId];

  const lineItem = {
    productIdentifier: item.productIdentifier,
    selectedProject: lineItemArgs.selectedProject,
  };

  createToAdd(lineItemArgs, [lineItem]);
};

export const addMultiLineItems = (
  { entities, selectedItemIds }: Args,
  lineItemArgs: AddLineItemArgs
) => {
  // We track multiselection here rather than in the multiSelectTo function because
  // this function is utilized in both scenarios of multiselect (shift-select and
  // ctrl-select) while multiSelectTo is only used in the shift-select scenario
  loggerService.log({
    name: 'Finder Dnd - Finder items were multiselected and dragged to list.',
  });

  // add new line items
  const itemsToAdd = selectedItemIds
    .map((id: string) => {
      const item = entities.items[id];
      return {
        productIdentifier: item.productIdentifier,
        selectedProject: lineItemArgs.selectedProject,
      };
    })
    .filter(item => !!item.productIdentifier);

  createToAdd(lineItemArgs, itemsToAdd as CreateLineItemArgs[]);
};

export const addLineItemsWithDrag = (args: Args, lineItemArgs: AddLineItemArgs) => {
  // A successful drag is defined by a user dragging an item to the drop zone and letting go.
  // Unsuccessful drags involve the user initiating a drag and then proceeding to drop the
  // item outside of the drop zone. At this point, the user has entered the drop zone and
  // dropped the item. Even if the line item(s) was not successfully added, the drag itself
  // is still successful.
  loggerService.log({ name: 'Finder Dnd - User has successfully completed a drag.' });

  if (args.selectedItemIds.length > 1) {
    addMultiLineItems(args, lineItemArgs);
  } else {
    addSingleLineItem(args, lineItemArgs);
  }
};
