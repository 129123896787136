import { CalloutAtom } from 'components/atoms';
import { Button } from 'components/ions/Buttons';
import { Calendar } from 'components/ions/Calendar';
import { DirectionalHint, FocusTrapZone, ICalendarStrings } from 'office-ui-fabric-react';
import * as React from 'react';
import { ThemeProps } from 'styles';
import withStyles, { WithStyles } from 'react-jss';

export const calloutCalendarStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    disabledButton: {
      '&:disabled': {
        color: colors.text,
        backgroundColor: colors.backgroundCommon,
        borderColor: colors.backgroundDivider,
      },
    },
  };
};

/**
 * Props for the Callout Calendar
 * @prop {string} [ariaLabelButton]  - Aria Label for the Button
 * @prop {string} ariaLabelCalendar  - Aria Label for the Calendar
 * @prop {string} ariaLabelCallout - Aria Label for the Callout
 * @prop {string} [buttonId] - Callout Button Id
 * @prop {string} calendarStrings  - Localized strings to use in the Calendar
 * @prop {boolean} [defaultDate] - Default Date of the date picker
 * @prop {boolean} [disabled] - Whether the Callout Calendar button is disabled
 * @prop {string} [id]  - Id for the button container
 * @prop {boolean} isDayPickerVisible - Whether the day picker is shown beside the month picker or hidden.
 * @prop {Function} onSelectDate - Callback issued when a date is selected
 * @prop {Function} onDismiss - Function that would be called on dismiss
 * @prop {Date} minDate - Calendar will not allow selection before this date value
 * @prop {Date} maxDate - Calendar will not allow navigation to or selection of a date later than this value.
 * @prop {Date} [today] - Value of today. If undefined, current time in client machine will be used
 */
export interface CalloutCalendarProps {
  /**
   * Properties for the button that opens the calendar callout
   */
  buttonProps?: {
    id?: string;
    ariaLabel?: string;
    disabled?: boolean;
    text?: string;
  };
  calendarStrings: ICalendarStrings;
  dataAutomationId?: string;
  defaultDate: Date;
  id?: string;
  isDayPickerVisible: boolean;
  isMinDateUnset?: boolean;
  maxDate?: Date;
  minDate?: Date;
  showCalendar?: boolean;
  showEvergreen?: boolean;
  showTodayButton?: boolean;
  today?: Date;
  onDismiss?: () => void;
  onSelectDate: (date: Date, selectedDateRangeArray?: Date[]) => void;
  onSelectEvergreen?: () => void;
  resetShowCalender?: () => void;
}

type Props = CalloutCalendarProps & WithStyles<typeof calloutCalendarStyles>;

const CalloutCalendarUnstyled: React.FC<Props> = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [visible, setVisible] = React.useState(false);
  const onSelectDate = (date: Date, selectedDateRangeArray?: Date[]) => {
    props.onSelectDate(date, selectedDateRangeArray);
    setVisible(false);
  };
  const onSelectEvergreen = () => {
    props.onSelectEvergreen && props.onSelectEvergreen();
    setVisible(false);
  };
  const toggleButton = () => {
    setVisible(!visible);
  };
  const onDismiss = () => {
    setVisible(false);
    props.resetShowCalender && props.resetShowCalender();
  };

  React.useEffect(() => {
    setVisible(props.showCalendar || false);
  }, [setVisible, props.showCalendar]);

  const calendar = (
    <FocusTrapZone isClickableOutsideFocusTrap>
      <Calendar
        defaultDate={props.defaultDate}
        isDayPickerVisible={props.isDayPickerVisible}
        maxDate={props.maxDate}
        minDate={props.isMinDateUnset ? undefined : props.minDate || new Date()}
        showEvergreen={props.showEvergreen}
        showGoToToday={props.showTodayButton}
        strings={props.calendarStrings}
        today={props.today}
        onSelectDate={onSelectDate}
        onSelectEvergreen={onSelectEvergreen}
      />
    </FocusTrapZone>
  );
  const callout = (
    <CalloutAtom
      directionalHint={DirectionalHint.bottomLeftEdge}
      doNotLayer={false}
      hidden={!visible}
      id={props.id}
      isBeakVisible={false}
      target={ref.current}
      trapFocus={false}
      onDismiss={onDismiss}
    >
      {calendar}
    </CalloutAtom>
  );

  const calloutRender = visible && callout;
  return (
    <div>
      <div aria-owns={props.id} id={props.id} ref={ref}>
        <Button
          {...props.buttonProps}
          addClass={props.classes.disabledButton}
          dataAutomationId={`${props.dataAutomationId}-button`}
          iconName="Calendar"
          onClick={toggleButton}
        />
      </div>
      {calloutRender}
    </div>
  );
};

export const CalloutCalendar = withStyles(calloutCalendarStyles)(
  CalloutCalendarUnstyled
) as React.FC<CalloutCalendarProps>;
