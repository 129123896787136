import i18next from 'i18n';
import { IComboBoxOption } from 'office-ui-fabric-react';
import loggerService from 'services/logger-service';
import { NeedsTranslation, t } from 'services/utils';

import { Currency } from './supported-currencies';
import { Language } from './supported-languages';

export enum MarketType {
  legacy = 'legacy',
  modern = 'modern',
  referrals = 'referrals',
}

export type Market =
  | 'AE'
  | 'AF'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AR'
  | 'AT'
  | 'AU'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BG'
  | 'BH'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BR'
  | 'BS'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CV'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DK'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FO'
  | 'FR'
  | 'GB'
  | 'GE'
  | 'GH'
  | 'GR'
  | 'GT'
  | 'HK'
  | 'HN'
  | 'HR'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IN'
  | 'IQ'
  | 'IS'
  | 'IT'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KN'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LB'
  | 'LI'
  | 'LK'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MH'
  | 'MK'
  | 'MN'
  | 'MO'
  | 'MT'
  | 'MU'
  | 'MX'
  | 'MY'
  | 'NA'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PY'
  | 'QA'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SE'
  | 'SG'
  | 'SI'
  | 'SK'
  | 'SN'
  | 'SV'
  | 'TH'
  | 'TJ'
  | 'TM'
  | 'TN'
  | 'TR'
  | 'TT'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VE'
  | 'VI'
  | 'VN'
  | 'YE'
  | 'ZA'
  | 'ZM'
  | 'ZW';

export const defaultMarket: Market = 'US';

export interface MarketInfo {
  display: string;
  currencies: Currency[];
  languages: Language[];
  timeZones: { first: string; last: string };
  translation: NeedsTranslation;
}

export const marketInfo: Record<Market, MarketInfo> = {
  AE: {
    display: 'United Arab Emirates',
    currencies: ['USD'],
    languages: ['ar-SA', 'en-US'],
    timeZones: { first: 'Asia/Dubai', last: 'Asia/Dubai' },
    translation: t('market::United Arab Emirates'),
  },
  AF: {
    display: 'Afghanistan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Kabul', last: 'Asia/Kabul' },
    translation: t('market::Afghanistan'),
  },
  AL: {
    display: 'Albania',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Tirane', last: 'Europe/Tirane' },
    translation: t('market::Albania'),
  },
  AM: {
    display: 'Armenia',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Yerevan', last: 'Asia/Yerevan' },
    translation: t('market::Armenia'),
  },
  AO: {
    display: 'Angola',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Luanda', last: 'Africa/Luanda' },
    translation: t('market::Angola'),
  },
  AR: {
    display: 'Argentina',
    currencies: ['USD'],
    languages: ['es-ES', 'en-US'],
    timeZones: { first: 'America/Argentina/San_Luis', last: 'America/Argentina/Buenos_Aires' },
    translation: t('market::Argentina'),
  },
  AT: {
    display: 'Austria',
    currencies: ['EUR'],
    languages: ['de-DE', 'en-US'],
    timeZones: { first: 'Europe/Vienna', last: 'Europe/Vienna' },
    translation: t('market::Austria'),
  },
  AU: {
    display: 'Australia',
    currencies: ['AUD'],
    languages: ['en-US'],
    timeZones: { first: 'Australia/Lord_Howe', last: 'Australia/Perth' },
    translation: t('market::Australia'),
  },
  AZ: {
    display: 'Azerbaijan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Baku', last: 'Asia/Baku' },
    translation: t('market::Azerbaijan'),
  },
  BA: {
    display: 'Bosnia and Herzegovina',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Sofia', last: 'Europe/Sofia' },
    translation: t('market::Bosnia and Herzegovina'),
  },
  BB: {
    display: 'Barbados',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Barbados', last: 'America/Barbados' },
    translation: t('market::Barbados'),
  },
  BD: {
    display: 'Bangladesh',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Dhaka', last: 'Asia/Dhaka' },
    translation: t('market::Bangladesh'),
  },
  BE: {
    display: 'Belgium',
    currencies: ['EUR'],
    languages: ['fr-FR', 'nl-NL', 'de-DE', 'en-US'],
    timeZones: { first: 'Europe/Brussels', last: 'Europe/Brussels' },
    translation: t('market::Belgium'),
  },
  BG: {
    display: 'Bulgaria',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Sofia', last: 'Europe/Sofia' },
    translation: t('market::Bulgaria'),
  },
  BH: {
    display: 'Bahrain',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Bahrain', last: 'Asia/Bahrain' },
    translation: t('market::Bahrain'),
  },
  BM: {
    display: 'Bermuda',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Atlantic/Bermuda', last: 'Atlantic/Bermuda' },
    translation: t('market::Bermuda'),
  },
  BN: {
    display: 'Brunei',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Brunei', last: 'Asia/Brunei' },
    translation: t('market::Brunei'),
  },
  BO: {
    display: 'Bolivia',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/La_Paz', last: 'America/La_Paz' },
    translation: t('market::Bolivia'),
  },
  BR: {
    display: 'Brazil',
    currencies: ['BRL'],
    languages: ['pt-BR', 'en-US'],
    timeZones: { first: 'America/Noronha', last: 'America/Rio_Branco' },
    translation: t('market::Brazil'),
  },
  BS: {
    display: 'Bahamas',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Nassau', last: 'America/Nassau' },
    translation: t('market::Bahamas'),
  },
  BW: {
    display: 'Botswana',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Gaborone', last: 'Africa/Gaborone' },
    translation: t('market::Botswana'),
  },
  BY: {
    display: 'Belarus',
    currencies: ['USD'],
    languages: ['ru-RU', 'en-US'],
    timeZones: { first: 'Europe/Minsk', last: 'Europe/Minsk' },
    translation: t('market::Belarus'),
  },
  BZ: {
    display: 'Belize',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Belize', last: 'America/Belize' },
    translation: t('market::Belize'),
  },
  CA: {
    display: 'Canada',
    currencies: ['CAD'],
    languages: ['en-CA', 'fr-FR'],
    timeZones: { first: 'America/St_Johns', last: 'America/Dawson' },
    translation: t('market::Canada'),
  },
  CG: {
    display: 'Congo',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Lubumbashi', last: 'Africa/Kinshasa' },
    translation: t('market::Congo'),
  },
  CH: {
    display: 'Switzerland',
    currencies: ['CHF'],
    languages: ['de-DE', 'fr-FR', 'en-US'],
    timeZones: { first: 'Europe/Zurich', last: 'Europe/Zurich' },
    translation: t('market::Switzerland'),
  },
  CI: {
    display: `Côte d'Ivoire`,
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Abidjan', last: 'Africa/Abidjan' },
    translation: t(`market::Côte d'Ivoire`),
  },
  CL: {
    display: 'Chile',
    currencies: ['USD'],
    languages: ['es-ES', 'en-US'],
    timeZones: { first: 'America/Santiago', last: 'Pacific/Easter' },
    translation: t('market::Chile'),
  },
  CM: {
    display: 'Cameroon',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Douala', last: 'Africa/Douala' },
    translation: t('market::Cameroon'),
  },
  CN: {
    display: 'China',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Shanghai', last: 'Asia/Kashgar' },
    translation: t('market::China'),
  },
  CO: {
    display: 'Colombia',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Bogota', last: 'America/Bogota' },
    translation: t('market::Colombia'),
  },
  CR: {
    display: 'Costa Rica',
    currencies: ['USD'],
    languages: ['es-CR', 'en-US'],
    timeZones: { first: 'America/Costa_Rica', last: 'America/Costa_Rica' },
    translation: t('market::Costa Rica'),
  },
  CV: {
    display: 'Cape Verde',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Atlantic/Cape_Verde', last: 'Atlantic/Cape_Verde' },
    translation: t('market::Cape Verde'),
  },
  CW: {
    display: 'Curaçao',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Curacao', last: 'America/Curacao' },
    translation: t('market::Curaçao'),
  },
  CY: {
    display: 'Cyprus',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Famagusta', last: 'Asia/Nicosia' },
    translation: t('market::Cyprus'),
  },
  CZ: {
    display: 'Czech Republic',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Prague', last: 'Europe/Prague' },
    translation: t('market::Czech Republic'),
  },
  DE: {
    display: 'Germany',
    currencies: ['EUR'],
    languages: ['de-DE', 'en-US'],
    timeZones: { first: 'Europe/Berlin', last: 'Europe/Berlin' },
    translation: t('market::Germany'),
  },
  DK: {
    display: 'Denmark',
    currencies: ['DKK'],
    languages: ['da-DK', 'en-US'],
    timeZones: { first: 'Europe/Copenhagen', last: 'Europe/Copenhagen' },
    translation: t('market::Denmark'),
  },
  DO: {
    display: 'Dominican Republic',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Santo_Domingo', last: 'America/Santo_Domingo' },
    translation: t('market::Dominican Republic'),
  },
  DZ: {
    display: 'Algeria',
    currencies: ['USD'],
    languages: ['ar-SA', 'fr-FR', 'en-US'],
    translation: t('market::Algeria'),
    timeZones: { first: 'Africa/Algiers', last: 'Africa/Algiers' },
  },
  EC: {
    display: 'Ecuador',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Guayaquil', last: 'Pacific/Galapagos' },
    translation: t('market::Ecuador'),
  },
  EE: {
    display: 'Estonia',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Tallinn', last: 'Europe/Tallinn' },
    translation: t('market::Estonia'),
  },
  EG: {
    display: 'Egypt',
    currencies: ['USD'],
    languages: ['ar-EG', 'en-US'],
    timeZones: { first: 'Africa/Cairo', last: 'Africa/Cairo' },
    translation: t('market::Egypt'),
  },
  ES: {
    display: 'Spain',
    currencies: ['EUR'],
    languages: ['es-ES', 'en-US'],
    timeZones: { first: 'Atlantic/Canary', last: 'Europe/Madrid' },
    translation: t('market::Spain'),
  },
  ET: {
    display: 'Ethiopia',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Addis_Ababa', last: 'Africa/Addis_Ababa' },
    translation: t('market::Ethiopia'),
  },
  FI: {
    display: 'Finland',
    currencies: ['EUR'],
    languages: ['fi-FI', 'en-US'],
    timeZones: { first: 'Europe/Helsinki', last: 'Europe/Helsinki' },
    translation: t('market::Finland'),
  },
  FJ: {
    display: 'Fiji',
    currencies: ['USD', 'AUD'],
    languages: ['en-US'],
    timeZones: { first: 'Pacific/Fiji', last: 'Pacific/Fiji' },
    translation: t('market::Fiji'),
  },
  FO: {
    display: 'Faroe Islands',
    currencies: ['EUR', 'DKK'],
    languages: ['en-US'],
    timeZones: { first: 'Atlantic/Faroe', last: 'Atlantic/Faroe' },
    translation: t('market::Faroe Islands'),
  },
  FR: {
    display: 'France',
    currencies: ['EUR'],
    languages: ['fr-FR', 'en-US'],
    timeZones: { first: 'Europe/Paris', last: 'Europe/Paris' },
    translation: t('market::France'),
  },
  GB: {
    display: 'United Kingdom',
    currencies: ['GBP'],
    languages: ['en-GB', 'en-US'],
    timeZones: { first: 'Europe/London', last: 'Europe/London' },
    translation: t('market::United Kingdom'),
  },
  GE: {
    display: 'Georgia',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Tbilisi', last: 'Asia/Tbilisi' },
    translation: t('market::Georgia'),
  },
  GH: {
    display: 'Ghana',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Accra', last: 'Africa/Accra' },
    translation: t('market::Ghana'),
  },
  GR: {
    display: 'Greece',
    currencies: ['EUR'],
    languages: ['el-GR', 'en-US'],
    timeZones: { first: 'Europe/Athens', last: 'Europe/Athens' },
    translation: t('market::Greece'),
  },
  GT: {
    display: 'Guatemala',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Guatemala', last: 'America/Guatemala' },
    translation: t('market::Guatemala'),
  },
  HK: {
    display: 'Hong Kong SAR',
    currencies: ['USD', 'HKD'],
    languages: ['en-US', 'zh-HK'],
    timeZones: { first: 'Asia/Hong_Kong', last: 'Asia/Hong_Kong' },
    translation: t('market::Hong Kong SAR'),
  },
  HN: {
    display: 'Honduras',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Tegucigalpa', last: 'America/Tegucigalpa' },
    translation: t('market::Honduras'),
  },
  HR: {
    display: 'Croatia',
    currencies: ['USD', 'EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Belgrade', last: 'Europe/Belgrade' },
    translation: t('market::Croatia'),
  },
  HU: {
    display: 'Hungary',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Budapest', last: 'Europe/Budapest' },
    translation: t('market::Hungary'),
  },
  ID: {
    display: 'Indonesia',
    currencies: ['IDR', 'USD'],
    languages: ['id-ID', 'en-US'],
    translation: t('market::Indonesia'),
    timeZones: { first: 'Asia/Jayapura', last: 'Asia/Jakarta' },
  },
  IE: {
    display: 'Ireland',
    currencies: ['EUR'],
    languages: ['en-US'],
    translation: t('market::Ireland'),
    timeZones: { first: 'Europe/Dublin', last: 'Europe/Dublin' },
  },
  IL: {
    display: 'Israel',
    currencies: ['USD'],
    languages: ['he-IL', 'ar-IL', 'en-US'],
    timeZones: { first: 'Asia/Jerusalem', last: 'Asia/Jerusalem' },
    translation: t('market::Israel'),
  },
  IN: {
    display: 'India',
    currencies: ['INR'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Kolkata', last: 'Asia/Kolkata' },
    translation: t('market::India'),
  },
  IQ: {
    display: 'Iraq',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Baghdad', last: 'Asia/Baghdad' },
    translation: t('market::Iraq'),
  },
  IS: {
    display: 'Iceland',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Atlantic/Reykjavik', last: 'Atlantic/Reykjavik' },
    translation: t('market::Iceland'),
  },
  IT: {
    display: 'Italy',
    currencies: ['EUR'],
    languages: ['it-IT', 'en-US'],
    timeZones: { first: 'Europe/Rome', last: 'Europe/Rome' },
    translation: t('market::Italy'),
  },
  JM: {
    display: 'Jamaica',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Jamaica', last: 'America/Jamaica' },
    translation: t('market::Jamaica'),
  },
  JO: {
    display: 'Jordan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Amman', last: 'Asia/Amman' },
    translation: t('market::Jordan'),
  },
  JP: {
    display: 'Japan',
    currencies: ['JPY'],
    languages: ['ja-JP', 'en-US'],
    timeZones: { first: 'Asia/Tokyo', last: 'Asia/Tokyo' },
    translation: t('market::Japan'),
  },
  KE: {
    display: 'Kenya',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Nairobi', last: 'Africa/Nairobi' },
    translation: t('market::Kenya'),
  },
  KG: {
    display: 'Kyrgyzstan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Bishkek', last: 'Asia/Bishkek' },
    translation: t('market::Kyrgyzstan'),
  },
  KN: {
    display: 'Saint Kitts and Nevis',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/St_Kitts', last: 'America/St_Kitts' },
    translation: t('market::Saint Kitts and Nevis'),
  },
  KR: {
    display: 'Korea',
    currencies: ['KRW'],
    languages: ['ko-KR', 'en-US'],
    timeZones: { first: 'Asia/Seoul', last: 'Asia/Pyongyang' },
    translation: t('market::Korea'),
  },
  KW: {
    display: 'Kuwait',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Kuwait', last: 'Asia/Kuwait' },
    translation: t('market::Kuwait'),
  },
  KY: {
    display: 'Cayman Islands',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Cayman', last: 'America/Cayman' },
    translation: t('market::Cayman Islands'),
  },
  KZ: {
    display: 'Kazakhstan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Almaty', last: 'Asia/Oral' },
    translation: t('market::Kazakhstan'),
  },
  LB: {
    display: 'Lebanon',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Beirut', last: 'Asia/Beirut' },
    translation: t('market::Lebanon'),
  },
  LI: {
    display: 'Liechtenstein',
    currencies: ['EUR'],
    languages: ['en-US'],
    translation: t('market::Liechtenstein'),
    timeZones: { first: 'Europe/Vaduz', last: 'Europe/Vaduz' },
  },
  LK: {
    display: 'Sri Lanka',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Colombo', last: 'Asia/Colombo' },
    translation: t('market::Sri Lanka'),
  },
  LT: {
    display: 'Lithuania',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Vilnius', last: 'Europe/Vilnius' },
    translation: t('market::Lithuania'),
  },
  LU: {
    display: 'Luxembourg',
    currencies: ['EUR'],
    languages: ['fr-FR', 'de-DE', 'da-DK', 'en-US'],
    timeZones: { first: 'Europe/Luxembourg', last: 'Europe/Luxembourg' },
    translation: t('market::Luxembourg'),
  },
  LV: {
    display: 'Latvia',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Riga', last: 'Europe/Riga' },
    translation: t('market::Latvia'),
  },
  LY: {
    display: 'Libya',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Tripoli', last: 'Africa/Tripoli' },
    translation: t('market::Libya'),
  },
  MA: {
    display: 'Morocco',
    currencies: ['USD'],
    languages: ['ar-SA', 'fr-FR', 'en-US'],
    timeZones: { first: 'Africa/Casablanca', last: 'Africa/Casablanca' },
    translation: t('market::Morocco'),
  },
  MC: {
    display: 'Monaco',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Monaco', last: 'Europe/Monaco' },
    translation: t('market::Monaco'),
  },
  MD: {
    display: 'Moldova',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Chisinau', last: 'Europe/Chisinau' },
    translation: t('market::Moldova'),
  },
  ME: {
    display: 'Montenegro',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Belgrade', last: 'Europe/Belgrade' },
    translation: t('market::Montenegro'),
  },
  MH: {
    display: 'Marshall Islands',
    currencies: ['AUD'],
    languages: ['fr-FR', 'en-US'],
    timeZones: { first: 'Pacific/Majuro', last: 'Pacific/Majuro' },
    translation: t('market::Marshall Islands'),
  },
  MK: {
    display: 'Macedonia, FYRO',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Belgrade', last: 'Europe/Belgrade' },
    translation: t('market::Macedonia, FYRO'),
  },
  MN: {
    display: 'Mongolia',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Choibalsan', last: 'Asia/Hovd' },
    translation: t('market::Mongolia'),
  },
  MO: {
    display: 'Macao SAR',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Macau', last: 'Asia/Macau' },
    translation: t('market::Macao SAR'),
  },
  MT: {
    display: 'Malta',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Malta', last: 'Europe/Malta' },
    translation: t('market::Malta'),
  },
  MU: {
    display: 'Mauritius',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Indian/Mauritius', last: 'Indian/Mauritius' },
    translation: t('market::Mauritius'),
  },
  MX: {
    display: 'Mexico',
    currencies: ['USD', 'MXN'],
    languages: ['es-ES', 'en-US'],
    timeZones: { first: 'America/Cancun', last: 'America/Tijuana' },
    translation: t('market::Mexico'),
  },
  MY: {
    display: 'Malaysia',
    currencies: ['USD', 'MYR'],
    languages: ['en-US', 'ms-MY'],
    timeZones: { first: 'Asia/Kuching', last: 'Asia/Kuching' },
    translation: t('market::Malaysia'),
  },
  NA: {
    display: 'Namibia',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Windhoek', last: 'Africa/Windhoek' },
    translation: t('market::Namibia'),
  },
  NG: {
    display: 'Nigeria',
    currencies: ['USD'],
    languages: ['en-US'],
    translation: t('market::Nigeria'),
    timeZones: { first: 'Africa/Lagos', last: 'Africa/Lagos' },
  },
  NI: {
    display: 'Nicaragua',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Managua', last: 'America/Managua' },
    translation: t('market::Nicaragua'),
  },
  NL: {
    display: 'Netherlands',
    currencies: ['EUR'],
    languages: ['nl-NL', 'en-US'],
    timeZones: { first: 'Europe/Amsterdam', last: 'Europe/Amsterdam' },
    translation: t('market::Netherlands'),
  },
  NO: {
    display: 'Norway',
    currencies: ['NOK'],
    languages: ['nb-NO', 'en-US'],
    timeZones: { first: 'Europe/Oslo', last: 'Europe/Oslo' },
    translation: t('market::Norway'),
  },
  NP: {
    display: 'Nepal',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Katmandu', last: 'Asia/Katmandu' },
    translation: t('market::Nepal'),
  },
  NZ: {
    display: 'New Zealand',
    currencies: ['NZD'],
    languages: ['en-US'],
    translation: t('market::New Zealand'),
    timeZones: { first: 'Pacific/Chatham', last: 'Pacific/Auckland' },
  },
  OM: {
    display: 'Oman',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Muscat', last: 'Asia/Muscat' },
    translation: t('market::Oman'),
  },
  PA: {
    display: 'Panama',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Panama', last: 'America/Panama' },
    translation: t('market::Panama'),
  },
  PE: {
    display: 'Peru',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Lima', last: 'America/Lima' },
    translation: t('market::Peru'),
  },
  PH: {
    display: 'Philippines',
    currencies: ['USD'],
    languages: ['en-US', 'fil-PH'],
    translation: t('market::Philippines'),
    timeZones: { first: 'Asia/Manila', last: 'Asia/Manila' },
  },
  PK: {
    display: 'Pakistan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Karachi', last: 'Asia/Karachi' },
    translation: t('market::Pakistan'),
  },
  PL: {
    display: 'Poland',
    currencies: ['EUR'],
    languages: ['pl-PL', 'en-US'],
    timeZones: { first: 'Europe/Warsaw', last: 'Europe/Warsaw' },
    translation: t('market::Poland'),
  },
  PR: {
    display: 'Puerto Rico',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Puerto_Rico', last: 'America/Puerto_Rico' },
    translation: t('market::Puerto Rico'),
  },
  PS: {
    display: 'Palestinian Aut',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Gaza', last: 'Asia/Gaza' },
    translation: t('market::Palestinian Aut'),
  },
  PT: {
    display: 'Portugal',
    currencies: ['EUR'],
    languages: ['pt-PT', 'en-US'],
    timeZones: { first: 'Europe/Lisbon', last: 'Atlantic/Azores' },
    translation: t('market::Portugal'),
  },
  PY: {
    display: 'Paraguay',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Asuncion', last: 'America/Asuncion' },
    translation: t('Paraguay'),
  },
  QA: {
    display: 'Qatar',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Qatar', last: 'Asia/Qatar' },
    translation: t('market::Qatar'),
  },
  RO: {
    display: 'Romania',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Bucharest', last: 'Europe/Bucharest' },
    translation: t('market::Romania'),
  },
  RS: {
    display: 'Serbia',
    currencies: ['USD'],
    languages: ['sr-cyrl-RS', 'sr-latn-RS', 'en-US'],
    timeZones: { first: 'Europe/Belgrade', last: 'Europe/Belgrade' },
    translation: t('market::Serbia'),
  },
  RU: {
    display: 'Russia',
    currencies: ['RUB'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Anadyr', last: 'Europe/Kaliningrad' },
    translation: t('market::Russia'),
  },
  RW: {
    display: 'Rwanda',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Kigali', last: 'Africa/Kigali' },
    translation: t('market::Rwanda'),
  },
  SA: {
    display: 'Saudi Arabia',
    currencies: ['SAR'],
    languages: ['ar-SA', 'en-US'],
    timeZones: { first: 'Asia/Riyadh', last: 'Asia/Riyadh' },
    translation: t('market::Saudi Arabia'),
  },
  SE: {
    display: 'Sweden',
    currencies: ['SEK'],
    languages: ['sv-SE', 'en-US'],
    timeZones: { first: 'Europe/Stockholm', last: 'Europe/Stockholm' },
    translation: t('market::Sweden'),
  },
  SG: {
    display: 'Singapore',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Singapore', last: 'Asia/Singapore' },
    translation: t('market::Singapore'),
  },
  SI: {
    display: 'Slovenia',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Belgrade', last: 'Europe/Belgrade' },
    translation: t('market::Slovenia'),
  },
  SK: {
    display: 'Slovakia',
    currencies: ['EUR'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Prague', last: 'Europe/Prague' },
    translation: t('market::Slovakia'),
  },
  SN: {
    display: 'Senegal',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Dakar', last: 'Africa/Dakar' },
    translation: t('market::Senegal'),
  },
  SV: {
    display: 'El Salvador',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/El_Salvador', last: 'America/El_Salvador' },
    translation: t('market::El Salvador'),
  },
  TH: {
    display: 'Thailand',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Bangkok', last: 'Asia/Bangkok' },
    translation: t('market::Thailand'),
  },
  TJ: {
    display: 'Tajikistan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Dushanbe', last: 'Asia/Dushanbe' },
    translation: t('market::Tajikistan'),
  },
  TM: {
    display: 'Turkmenistan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Ashgabat', last: 'Asia/Ashgabat' },
    translation: t('market::Turkmenistan'),
  },
  TN: {
    display: 'Tunisia',
    currencies: ['USD'],
    languages: ['ar-SA', 'fr-FR', 'en-US'],
    timeZones: { first: 'Africa/Tunis', last: 'Africa/Tunis' },
    translation: t('market::Tunisia'),
  },
  TR: {
    display: 'Turkey',
    currencies: ['USD'],
    languages: ['tr-TR', 'en-US'],
    timeZones: { first: 'Asia/Istanbul', last: 'Asia/Istanbul' },
    translation: t('market::Turkey'),
  },
  TT: {
    display: 'Trinidad and Tobago',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Port_of_Spain', last: 'America/Port_of_Spain' },
    translation: t('market::Trinidad and Tobago'),
  },
  TW: {
    display: 'Taiwan',
    currencies: ['TWD'],
    languages: ['zh-TW', 'en-US'],
    timeZones: { first: 'Asia/Taipei', last: 'Asia/Taipei' },
    translation: t('market::Taiwan'),
  },
  TZ: {
    display: 'Tanzania',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Dar_es_Salaam', last: 'Africa/Dar_es_Salaam' },
    translation: t('market::Tanzania'),
  },
  UA: {
    display: 'Ukraine',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Europe/Simferopol', last: 'Europe/Kiev' },
    translation: t('market::Ukraine'),
  },
  UG: {
    display: 'Uganda',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Kampala', last: 'Africa/Kampala' },
    translation: t('market::Uganda'),
  },
  US: {
    display: 'United States',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/New_York', last: 'Pacific/Honolulu' },
    translation: t('market::United States'),
  },
  UY: {
    display: 'Uruguay',
    currencies: ['USD'],
    languages: ['es-ES', 'en-US'],
    timeZones: { first: 'America/Montevideo', last: 'America/Montevideo' },
    translation: t('market::Uruguay'),
  },
  UZ: {
    display: 'Uzbekistan',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Tashkent', last: 'Asia/Tashkent' },
    translation: t('market::Uzbekistan'),
  },
  VE: {
    display: 'Venezuela',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/Caracas', last: 'America/Caracas' },
    translation: t('market::Venezuela'),
  },
  VI: {
    display: 'U.S. Virgin Islands',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'America/St_Thomas', last: 'America/St_Thomas' },
    translation: t('market::U.S. Virgin Islands'),
  },
  VN: {
    display: 'Vietnam',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Ho_Chi_Minh', last: 'Asia/Ho_Chi_Minh' },
    translation: t('market::Vietnam'),
  },
  YE: {
    display: 'Yemen',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Asia/Aden', last: 'Asia/Aden' },
    translation: t('market::Yemen'),
  },
  ZA: {
    display: 'South Africa',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Johannesburg', last: 'Africa/Johannesburg' },
    translation: t('market::South Africa'),
  },
  ZM: {
    display: 'Zambia',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Lusaka', last: 'Africa/Lusaka' },
    translation: t('market::Zambia'),
  },
  ZW: {
    display: 'Zimbabwe',
    currencies: ['USD'],
    languages: ['en-US'],
    timeZones: { first: 'Africa/Harare', last: 'Africa/Harare' },
    translation: t('market::Zimbabwe'),
  },
};

const marketTuple = <T extends Market[]>(...args: T) => args;
const modernMarketList = marketTuple(
  'AR',
  'AT',
  'AU',
  'BE',
  'CA',
  'CH',
  'CL',
  'DE',
  'DK',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'IE',
  'IS',
  'IT',
  'LI',
  'LU',
  'NL',
  'NO',
  'PR',
  'PT',
  'SE',
  'TT',
  'US',
  'UY',
  'ZA'
);

export type ModernMarket = typeof modernMarketList[number];
export const modernMarkets: Market[] = modernMarketList;

const referralMarketList = marketTuple(
  'AF',
  'AL',
  'DZ',
  'AO',
  'AR',
  'AM',
  'AZ',
  'BH',
  'BD',
  'BB',
  'BY',
  'BZ',
  'BM',
  'BO',
  'BA',
  'BW',
  'BN',
  'CM',
  'CV',
  'KY',
  'CL',
  'CO',
  'CR',
  'CI',
  'CW',
  'DO',
  'EG',
  'ET',
  'GE',
  'GH',
  'GT',
  'HN',
  'HK',
  'ID',
  'IQ',
  'IL',
  'JM',
  'JO',
  'KZ',
  'KE',
  'KW',
  'KG',
  'LB',
  'LY',
  'MO',
  'MK',
  'MY',
  'MU',
  'MX',
  'MD',
  'MN',
  'ME',
  'MA',
  'NA',
  'NP',
  'NI',
  'NG',
  'OM',
  'PK',
  'PS',
  'PA',
  'PY',
  'PE',
  'PH',
  'PR',
  'QA',
  'RW',
  'KN',
  'SA',
  'SN',
  'RS',
  'SG',
  'ZA',
  'LK',
  'TJ',
  'TZ',
  'TH',
  'TT',
  'TN',
  'TM',
  'VI',
  'UG',
  'UA',
  'AE',
  'US',
  'UY',
  'UZ',
  'VE',
  'VN',
  'YE',
  'ZM',
  'ZW',
  'EC',
  'SV',
  'TR'
);

export const referralMarkets: Market[] = referralMarketList;

export const getMarkets = (
  marketType: MarketType = MarketType.modern,
  allMarketClaimsEnabled?: boolean
): IComboBoxOption[] => {
  let markets;
  switch (marketType) {
    case MarketType.modern:
      markets = modernMarkets;
      break;
    case MarketType.legacy:
      markets = Object.keys(marketInfo);
      break;
    case MarketType.referrals:
      if (allMarketClaimsEnabled) markets = Object.keys(marketInfo);
      else markets = referralMarkets;
      break;
  }
  return markets.map((key: string) => {
    return {
      key,
      text: marketInfo[key as Market].display,
      data: marketInfo[key as Market].translation,
    };
  });
};

export const getTranslatedMarketsAlphabetically = (
  t: i18next.TFunction,
  marketType: MarketType = MarketType.modern,
  allMarketClaimsEnabled?: boolean
): IComboBoxOption[] => {
  const marketsList = getMarkets(marketType, allMarketClaimsEnabled);
  const sortedMarkets = marketsList.sort((m1, m2) => {
    const translation1 = t(m1.data.value);
    const translation2 = t(m2.data.value);
    return translation1.localeCompare(translation2);
  });
  return sortedMarkets;
};

export function getCurrencies(market: Market) {
  return marketInfo[market].currencies;
}

export function getLanguages(market: Market) {
  return marketInfo[market].languages;
}

export function getMarketByCountryCode(
  countryCode: string,
  onlyModernMarkets?: boolean
): Market | undefined {
  const market = countryCode.toUpperCase() as Market;
  if (onlyModernMarkets) {
    return modernMarketList.includes(market as ModernMarket) ? market : undefined;
  }
  return marketInfo[market] && market;
}

export const getCountryNameFromCountryCode = (countryCode: string): string | undefined => {
  const market = getMarketByCountryCode(countryCode);
  return market && marketInfo[market].display;
};

export const getMarketByCountryName = (
  countryName: string,
  onlyModernMarkets?: boolean
): Market | undefined => {
  const marketList = onlyModernMarkets ? modernMarkets : marketInfo;
  const match = Object.keys(marketList).find(
    key => marketInfo[key as Market].display === countryName
  );
  return match as Market;
};

export const getTranslatedCountryName = (countryName: string, t: i18next.TFunction) => {
  const market = getMarketByCountryName(countryName);
  const foundMarket = market && marketInfo[market as Market];
  return foundMarket && t(foundMarket.translation.value);
};

export const getTranslatedCountryNameFromCountryCode = (
  countryCode: string,
  t: i18next.TFunction
) => {
  const countryName = getCountryNameFromCountryCode(countryCode);
  return countryName ? getTranslatedCountryName(countryName, t) : undefined;
};

export const getTranslatedCountry = (country: string, t: i18next.TFunction) => {
  const translatedCountry =
    getTranslatedCountryNameFromCountryCode(country, t) || getTranslatedCountryName(country, t);
  if (!translatedCountry) {
    loggerService.error({
      error: new Error(`Country not found: ${country}`),
    });
    return '';
  }
  return translatedCountry;
};

export const isReferralMarket = (market: Market) => referralMarkets.includes(market);

export const isModernMarket = (market: Market): market is ModernMarket => {
  return modernMarkets.includes(market);
};

export const getMarketForCountry = (countryNameOrCode: string) => {
  return getMarketByCountryName(countryNameOrCode) || getMarketByCountryCode(countryNameOrCode);
};

export const isModernMarketCountry = (country: string) => {
  const market = getMarketForCountry(country);

  return market && isModernMarket(market);
};
export const isReferralMarketCountry = (country: string) => {
  const market = getMarketForCountry(country);
  return !!market && isReferralMarket(market);
};
