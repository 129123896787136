import { PrimaryButton, SecondaryButton, TextBody, XLargeIcon } from 'components';
import { Dialog, headerHeight } from 'components/ions/PopUps/Dialogs/Dialog';
import { Dimensions } from 'features/components/dialogs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { sharedStyles, ThemeProps } from 'styles';

export interface SuccessProps extends Dimensions {
  closeDialog: () => void;
  message: string | React.ReactElement;
  footerAddClass?: string;
  useSecondaryButton?: boolean;
  containerPaddingTop?: number;
  dataAutomationId?: string;
}

const styles = (theme: ThemeProps) => {
  return {
    icon: { ...sharedStyles(theme).iconForDialog },
    iconContainer: {
      ...sharedStyles(theme).iconContainerForDialog,
      backgroundColor: theme.palette.approveText,
      '& i': {
        color: theme.palette.backgroundStandout,
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: (props: SuccessProps) =>
        props.containerPaddingTop === undefined
          ? props.height / 4 - headerHeight
          : props.containerPaddingTop,
    },
    message2: {
      color: theme.palette.textTertiary,
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& > :not(:first-child)': {
        paddingTop: 16,
      },
      maxWidth: 360,
      textAlign: 'center',
    },
  };
};

type Props = SuccessProps & WithStyles<typeof styles>;
const SuccessUnStyled: React.FC<Props> = props => {
  const { classes, useSecondaryButton } = props;
  const { t } = useTranslation();
  const closeButton = useSecondaryButton ? (
    <SecondaryButton
      dataAutomationId={`${props.dataAutomationId}CloseButton`}
      text={t('Close')}
      onClick={props.closeDialog}
    />
  ) : (
    <PrimaryButton
      dataAutomationId={`${props.dataAutomationId}CloseButton`}
      text={t('Close')}
      onClick={props.closeDialog}
    />
  );

  return (
    <Dialog
      closeDialog={props.closeDialog}
      dataAutomationId={props.dataAutomationId}
      footerAddClass={props.footerAddClass}
      footerButtons={closeButton}
      height={props.height}
      width={props.width}
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.iconContainer}>
            <XLargeIcon addClass={classes.icon} iconName="Accept" />
          </div>
          {typeof props.message === 'string' ? <TextBody>{props.message}</TextBody> : props.message}
        </div>
      </div>
    </Dialog>
  );
};

export const Success = withStyles(styles)(SuccessUnStyled);
