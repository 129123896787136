import { Pivot, SearchHeader, SectionSeparator } from 'components';
import { HomeCustomersStyles as styles } from 'features/home/components/styles/HomeCustomers.styles';
import * as actions from 'features/ldsscustomer/actions';
import SearchResults from 'features/ldsscustomer/components/SearchResults';
import {
  customerSearchProcessing,
  getFullSearchCustomerResults,
  getTopRecommendedSearchCustomerResults,
} from 'features/ldsscustomer/selectors';
import { checkLDSSReadPermissionProcessing, getUserPermissions } from 'features/user/selectors';
import { ISearchBox } from 'office-ui-fabric-react';
import React, { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { defaultCustomerSearchPageSize } from 'services/ldss/config';
import { CustomerSearchRequest } from 'services/ldss/types';
import { RootState } from 'store/types';

const mapStateToProps = (state: RootState) => {
  return {
    topCustomers: getTopRecommendedSearchCustomerResults(state),
    allCustomers: getFullSearchCustomerResults(state),
    searchProcessing: customerSearchProcessing(state),
    userPermissions: getUserPermissions(state),
    userPermissionsProcessing: checkLDSSReadPermissionProcessing(state),
  };
};

const dispatchProps = {
  onSearchCustomers: (request: CustomerSearchRequest) =>
    actions.searchCustomersAsync.request(request),
  selectedCustomer: (customerName: string) => actions.selectedCustomer(customerName),
};

// Because we are automatically including wildcard '*' char for search queries for the last character, we will sanitize this from users input
// if they explicitly include this since the results will reflect this regardless.
export const sanitizeSearchInput = (input: string): string => {
  let sanitizedSearchInput = (input && input.trim()) || '';
  if (sanitizedSearchInput.slice(-1) === '*') {
    sanitizedSearchInput = sanitizedSearchInput.slice(0, -1);
  }
  return sanitizedSearchInput;
};

enum SearchResultTypes {
  AllResults = 'allresults',
  TopResults = 'topresults',
}

type Props = WithStyles<typeof styles> & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const HomeCustomers: React.FC<Props> = ({
  classes,
  topCustomers,
  allCustomers,
  onSearchCustomers,
  searchProcessing,
  userPermissions,
  userPermissionsProcessing,
  selectedCustomer,
}: Props) => {
  const { t } = useTranslation();

  // If there is an error in the authz call we will give the user benefit of the doubt and allow search to be performed. The
  // actual authorization check will happen regardless within the service.
  const hasLDSSReadPermission =
    userPermissions.includes('LDSSRead') || !!userPermissionsProcessing.error;

  // #TODO (iabowers) - implement null coalescing once typescript is updated to 3.7
  // topCustomers?.customers[0]?.searchText ?? ''
  const [searchQuery, setSearchQuery] = React.useState(
    (topCustomers && topCustomers.customers.length && topCustomers.customers[0].searchText) || ''
  );
  const [searchQueryHighlight, setSearchQueryHighlight] = React.useState(
    (topCustomers && topCustomers.customers.length && topCustomers.customers[0].searchText) || ''
  );
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    (topCustomers &&
      topCustomers.loadedItemCount &&
      topCustomers.loadedItemCount / defaultCustomerSearchPageSize) ||
      0
  );
  const [activeTab, setTab] = React.useState(SearchResultTypes.TopResults);

  const searchFocusRef = useRef<ISearchBox>(null);
  useHotkeys('ctrl+/', () => {
    searchFocusRef.current && searchFocusRef.current.focus();
  });

  const onChange = (value?: string) => {
    setSearchQuery(value || '');
  };

  // Adding reference to the customer search results list to enable control of scroll position
  const scrollListRef = useRef<HTMLDivElement>(null);

  const performSearch = () => {
    setCurrentPageNumber(0);
    const sanitizedInput = sanitizeSearchInput(searchQuery);
    setSearchQueryHighlight(sanitizedInput);
    onSearchCustomers({ searchTerm: sanitizedInput, skipItems: 0, top: 50 });
    // When a new search is performed reset the scroll position
    if (scrollListRef.current) {
      scrollListRef.current.scrollTop = 0;
    }
  };

  const loadMore = () => {
    onSearchCustomers({
      searchTerm: searchQuery,
      skipItems: (currentPageNumber + 1) * defaultCustomerSearchPageSize,
    });
    setCurrentPageNumber(currentPageNumber + 1);
  };

  const clearSearch = () => {
    setCurrentPageNumber(0);
    setSearchQueryHighlight('');
    setSearchQuery('');
    allCustomers.customers = [];
    allCustomers.totalResults = 0;
    topCustomers.customers = [];
    topCustomers.totalResults = 0;
  };

  let currentLoadMoreHandler, currentSearchResults, displayEnrollmentId;
  if (activeTab === SearchResultTypes.TopResults) {
    currentSearchResults = topCustomers;
    displayEnrollmentId = false;
  } else {
    currentLoadMoreHandler = loadMore;
    currentSearchResults = allCustomers;
    displayEnrollmentId = true;
  }
  const searchResults = (
    <div className={classes.customerList} ref={scrollListRef}>
      <SearchResults
        clearSearch={clearSearch}
        currentPageNumber={currentPageNumber}
        displayEnrollmentId={displayEnrollmentId}
        enrollmentLabel={t('home:: Enrollment ID ')}
        errorRetry={performSearch}
        hasError={searchProcessing.error}
        hasLDSSPermission={hasLDSSReadPermission}
        isLoading={searchProcessing.loading}
        loadMoreHandler={currentLoadMoreHandler}
        searchQuery={searchQueryHighlight}
        searchResults={currentSearchResults}
        totalResults={currentSearchResults.totalResults}
        onSelect={selectedCustomer}
      />
    </div>
  );

  const setPivot = (id: SearchResultTypes) => {
    setTab(id);
    return '';
  };

  const pivProps = {
    items: [
      { id: SearchResultTypes.TopResults, text: t('home::Top Results') },
      { id: SearchResultTypes.AllResults, text: t('home::All Results') },
    ],
    onSelectItem: setPivot,
    defaultItemId: activeTab,
  };

  const navigationPivot = (
    <div className={classes.navigation}>
      <Pivot {...pivProps} />
      <SectionSeparator addClass={classes.navigationSeparator} />
    </div>
  );

  const displayPivot: boolean =
    !searchProcessing.error &&
    hasLDSSReadPermission &&
    !!(searchQueryHighlight && searchQueryHighlight.length) &&
    !searchProcessing.loading;

  return (
    <div className={classes.div}>
      <SearchHeader
        buttonDisabled={!hasLDSSReadPermission}
        buttonText={t('home::Search')}
        dataAutomationId="customerSearchGoButton"
        searchAutoFocus={!window.location.href.includes('#sc')} // prevent autofocus with shortcut nav to avoid shortcut key being typed in
        searchComponentRef={searchFocusRef}
        searchDisabled={!hasLDSSReadPermission}
        searchPlaceholder={t('home::Enter account, enrollment number, PCN, or tenant information')}
        searchValue={searchQuery}
        title={t('home::Customer search')}
        onChange={onChange}
        onClearSearch={clearSearch}
        onSearch={performSearch}
      />
      <div className={classes.scroll}>
        {displayPivot && navigationPivot}
        {searchResults}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(withStyles(styles)(HomeCustomers));
