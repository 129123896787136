import i18next from 'i18next';

export const overviewQuestions = (t: i18next.TFunction) => {
  return [
    {
      id: 'q1',
      list: true,
      question: t(
        'help::What are the key changes coming to Azure purchasing for commercial Corporate and Enterprise customers?'
      ),
      answer: {
        openingSentence: t(
          'help::With the launch of Azure purchases through the new commerce experience we are making the following changes:'
        ),
        answerList: [
          t(
            'help::New deal execution process: Automated quoting led by Microsoft sellers (Corporate sales and field) with management and consumption a joint activity between customers and partners'
          ),
          t(
            'help::Simplified Azure purchasing experience: The new Microsoft Customer Agreement with a fully digital process and the ability to customize'
          ),
          t(
            'help::Faster deal execution: New automated tools, such as Quote Center (Microsoft seller) and updated Azure.com (customer)'
          ),
          t(
            'help::Partner role update: Role focus is pre-sale and post-sale services and management (partner is no longer responsible for transacting agreements)'
          ),
        ],
      },
    },
    {
      id: 'q2',
      list: false,
      question: t('help::Why deliver a new commerce experience?'),
      answer: {
        openingSentence: t(
          'help::Every billing account today is grappling with how to digitally transform its business. Microsoft is a critical part of this transformation and must respond with a modernized and highly versatile commerce strategy. The vision of the new commerce experience is simple: provide customers with a consistent set of offers, available through three distinct channels and supported by an efficient and flexible commerce engine. To this end, we are making changes to provide unique offers that support customer digital transformation, improve the buying and selling experience, and in turn make it easier to do business with Microsoft.'
        ),
      },
    },
    {
      id: 'q3',
      list: false,
      question: t(
        'help::Which customer types does the introduction of Azure on the new commerce experience affect?'
      ),
      answer: {
        openingSentence: t(
          'help::At launch, this will affect commercial Corporate (March) and Enterprise (April) customers in direct markets with an Azure-only Server and Cloud Enrollment (through Enterprise Agreement or Enterprise Subscription Agreement) and direct Microsoft Product and Services Agreements. In FY20, we will expand to additional customers and regions.'
        ),
      },
    },
    {
      id: 'q4',
      list: false,
      question: t(
        'help::Which regions does the introduction of Azure in the new commerce experience through the Microsoft Customer Agreement affect?'
      ),
      answer: {
        openingSentence: t(
          'help::At launch, this will affect direct market countries/regions only. Those countries/regions are Argentina, Australia, Canada, Chile, France, Germany, Greece, Puerto Rico, South Africa, the United Kingdom, the United States, and all of Western Europe.'
        ),
      },
    },
    {
      id: 'q5',
      list: false,
      question: t(
        'help::Will public sector customers be asked to move their Azure-only purchases to the Microsoft Customer Agreement?'
      ),
      answer: {
        openingSentence: t(
          'help::Public sector customers will not be included in the transition during the first several phases (through FY20) of the launch of Azure on the Microsoft Customer Agreement.'
        ),
      },
    },
    {
      id: 'q6',
      list: false,
      question: t(
        'help::Whose role is it to transition customers to the new commerce experience for Azure purchases?'
      ),
      answer: {
        openingSentence: t(
          'help::Account Executives and Commercial Executives should work together to ensure the customer is transitioned. In most cases, the customer will want to continue working with a partner for multiple partners that provide managed services, and the AE should work with the customer and the appropriate partner. This lets the customer provide Azure role-based access and allows the partner to associate their MPN ID in the Partner Admin Link tool.\n\nCustomers who have monetary commitment credit left in their SCE enrollment should not be transitioned by their seller prior to their enrollment expiration, to avoid credit loss.'
        ),
      },
    },
    {
      id: 'q7',
      list: false,
      question: t('help::What is the Microsoft Azure Plan?'),
      answer: {
        openingSentence: t(
          'help::The Microsoft Azure Plan provides you access to Azure services at standard pay-as-you-go rates.'
        ),
      },
    },
    {
      id: 'q8',
      list: false,
      question: t('help::What is the Microsoft Azure Plan for DevTest?'),
      answer: {
        openingSentence: t(
          'help::The Microsoft Azure Plan for DevTest provides Visual Studio active subscribers discounted rates on development and testing workloads on Azure. This plan is on a pay-as-you-go basis with limited service-level agreements.'
        ),
      },
    },
    {
      id: 'q9',
      list: false,
      question: t('help::When are the Azure Plans needed?'),
      answer: {
        openingSentence: t(
          'help::Both the Microsoft Azure Plan and the Microsoft Azure Plan for DevTest are required on the quote for EA customers renewing into Modern. Only the Microsoft Azure Plan is required for new customers.'
        ),
      },
    },
    {
      id: 'q10',
      list: false,
      question: t('help::What happens if my quote expires?'),
      answer: {
        openingSentence: t(
          'help::If your quote expires before you had a chance to place the order, contact your Microsoft field representative.'
        ),
      },
    },
  ];
};
export const quoteCenterQuestions = (t: i18next.TFunction) => {
  return [
    {
      id: 'q1',
      list: false,
      question: t('help::What is Quote Center?'),
      answer: {
        openingSentence: t(
          'help::Quote Center is an internal Microsoft tool that allows you to create a quote and send it directly to the customer via email. It features drag-and-drop functionality to make creating a quote simple.\n\nAfter the quote is sent to the customer, the customer completes the transaction through the Microsoft Admin Center by reviewing and accepting the quote and e-signing the Microsoft Customer Agreement. During a renewal, the customer must go through a transition process of their subscriptions and admins.'
        ),
      },
    },
    {
      id: 'q2',
      list: true,
      question: t('help::What customer information can I get from Quote Center?'),
      answer: {
        openingSentence: t(
          'help::Sellers will be able to research customers extensively in Quote Center. They can:'
        ),
        answerList: [
          t(
            'help::Access customer research using the Top Parent ID (TPID) through the Quote Center Home Page'
          ),
          t(
            'help::Research the customer’s commerce footprint with Microsoft across all programs (EA, CSP, Web Direct, Open, SPLA, etc)'
          ),
          t(
            'help::Drill into each agreement to understand the demographic info, prior concessions, amendments, and review their order history'
          ),
          t(
            "help::Using the subscriptions tab, understand the customer's seat based online services footprint"
          ),
        ],
      },
    },
    {
      id: 'q3',
      list: false,
      question: t('help::Will I get a notification that a quote is accepted by the customer?'),
      answer: {
        openingSentence: t(
          'help::When customers accept a quote, the status of the quote will change in the Quote Center tool. At this time, the seller does not receive a push notification that the deal was accepted.'
        ),
      },
    },
    {
      id: 'q4',
      list: false,
      question: t(
        'help::For customers e.g. coming from an Azure-only SCE, is it required to technically migrate the Azure Service? Similar to the EA to CSP migration?'
      ),
      answer: {
        openingSentence: t(
          'help::You do have to do a transition in the portal, but you have better cost management and billing services. It’s just updating the billing relationship so the customer is billed via the new process; nothing changes for the tenant.'
        ),
      },
    },
    {
      id: 'q5',
      list: false,
      question: t(
        'help::If the account team creates quotes for Azure purchases through the new commerce experience, do they also have associated empowerment?'
      ),
      answer: {
        openingSentence: t(
          'help::Any seller has the ability to request discounts/credits in Quote Center, but only those with designated empowerment (as per empsup) or the Area BG Leads (for monetary credits) have the ability to approve those discounts/credits for Azure through the Microsoft Customer Agreement.'
        ),
      },
    },
    {
      id: 'q6',
      list: false,
      question: t('help::What concessions will be available to the MCA at launch?'),
      answer: {
        openingSentence: t(
          'help::We are working on finalizing the concessions, and expect to have a 1132 equivalent, which is the Azure general field empowerment, as well as an ACO Azure credit offer.'
        ),
      },
    },
    {
      id: 'q7',
      list: false,
      question: t('help::When will my discount start and end?'),
      answer: {
        openingSentence: t(
          'help::Your Azure consumption service discounts will start on the first day of the transacted month, or the first day of the month agreed to with your Microsoft Representative. Your entitlement services will start on the transacted day, or the day agreed to with your Microsoft Representative. All discount start and end times are based on the UTC time zone.'
        ),
      },
    },
    {
      id: 'q8',
      list: false,
      question: t('help::What if I have multiple discounts that overlap?'),
      answer: {
        openingSentence: t(
          'help::You will always receive the best discount applicable to the product; it is not accumulative.'
        ),
      },
    },
    {
      id: 'q9',
      list: false,
      question: t('help::Can I purchase all products?'),
      answer: {
        openingSentence: t(
          'help::No, only entitlement products can be purchased. Consumption and RO products can only be discounted.'
        ),
      },
    },
    {
      id: 'q10',
      list: false,
      question: t(
        'help::What should we tell customers who currently have custom terms on their agreements?'
      ),
      answer: {
        openingSentence: t(
          'help::At launch in March, the only concessions available are discounting and providing Azure credit. In the following months, many of our most commonly required amendments will be made available, including the financial services addendum and ECIF. The capability to support full customization via the Business Desk will follow. If you require a concession that is not available via the customer agreement at the point of renewal, the customer should continue with their EA.'
        ),
      },
    },
    {
      id: 'q11',
      list: false,
      question: t('help::What’s the deal value of a PAYGO deal?'),
      answer: {
        openingSentence: t('help::The deal value of a PAYGO deal is expected annual consumption.'),
      },
    },
    {
      id: 'q12',
      list: false,
      question: t('help::What is a Credit Check and why is it necessary?'),
      answer: {
        openingSentence: t(
          'help::A credit check is conducted to determine if a customer is in good standing and can afford additional products and series when paying by invoice. A credit check protects Microsoft from incurring unrecoverable costs when we provide customers services they can’t pay for.'
        ),
      },
    },
    {
      id: 'q13',
      list: false,
      question: t('help::When do I initiate a Credit Check?'),
      answer: {
        openingSentence: t(
          'help::While the goal is to automate as many credit checks as possible, manual reviews are still often required. Manual credit checks take longer (up to 48 hours) and are at a cost to Microsoft. Sellers should avoid requesting a credit check or increase unless they are confident some action will be taken on a quote shared with a customer to avoid unnecessary activities that may result from a manual review.'
        ),
      },
    },
    {
      id: 'q14',
      list: false,
      question: t('help::Why is it necessary to provide an Annual Deal Estimate?'),
      answer: {
        openingSentence: t(
          'help::Knowing the needs of the customer assists our credit team in setting expectations with customers as well as making sure that a customer has room to expand without having to be limited by unexpected credit issues as they grow.'
        ),
      },
    },
    {
      id: 'q15',
      list: false,
      question: t('help::How do I determine the value to use for Annual Deal Estimate?'),
      answer: {
        openingSentence: t(
          'help::There are many ways to arrive at an estimate for a deal. Also, there are many different levels of experience in understanding how a customer’s activities will result in actual billed usage. If a customer is migrating, a customer’s previous consumption pattern can also be used to estimate if available. Field sellers should use their experience and relationship with the customer to make the best attempt and provide an estimate similar to how they provide the estimate on the opportunity itself. While the deal estimate is a factor in establishing the credit line, it is only one factor. A reasonable credit line is always established. An Annual Deal Estimate provides the opportunity to improve the outcome for customers who may need additional considerations.'
        ),
      },
    },
    {
      id: 'q16',
      list: false,
      question: t(
        'help::What if my Annual Deal Estimate is lower than my customer’s established credit line?'
      ),
      answer: {
        openingSentence: t(
          'help::When initially establishing a credit line, this should seldom occur as the deal estimate you provided is a consideration in the establishment of the credit line, and the credit team will be working with you or the customer to provide options. However, when this does occur, an option is provided to re-engage credit by requesting a credit line increase. A seller may consider lowering the Annual Deal Estimate to avoid having to increase credit. Consumption services are challenging as the final outcome is unknown. While currently credit check primarily serves to protect against customers who are not paying or at high risk, it is the goal of credit to move to real time consideration of usage against a credit line. If you purposefully lower the estimated deal value, when credit begins to consider actual usage you will not have served you customer in establishing a meaningful credit line, and they will now have to deal with this in the form of notifications from credit.'
        ),
      },
    },
    {
      id: 'q17',
      list: false,
      question: t('help::Why does my customer require a Credit Check when they didn’t before?'),
      answer: {
        openingSentence: t(
          'help::Currently, we don’t support paying by credit card. This feature may be supported in the future. Paying by credit card reduces the risk for payment and is an option for other Azure programs. Whenever paying by invoice, a credit check is required.'
        ),
      },
    },
    {
      id: 'q18',
      list: false,
      question: t('help::Is credit used to determine eligibility for the Modern Azure solution?'),
      answer: {
        openingSentence: t(
          'help::The Modern Azure offer currently is a one-size-fits-all solution. It is intended for Enterprise customers who have a need for robust solutions with significant resources. Target customers have been identified in advance as being a good fit for this initial solution. As our modern solution expands to cover customers of all varieties, the Azure offer will learn how to right-size itself to better fit all customers and their needs. The criteria used to determine this eligibility is still under consideration.'
        ),
      },
    },
    {
      id: 'q19',
      list: false,
      question: t(
        'help::With a renewing customer, if the deal value is higher than what was preloaded in the tool, will the credit check need to be rerun?'
      ),
      answer: {
        openingSentence: t('help::The tool will prompt you to ask for a credit increase.'),
      },
    },
    {
      id: 'q20',
      list: false,
      question: t('help::Is a credit check required if a customer has no monetary commitment?'),
      answer: {
        openingSentence: t(
          'help::In Quote Center, every quote that is created will trigger a credit check. There is a mandatory field in Quote Center for “estimated deal value,” and that is what the credit check is based off.'
        ),
      },
    },
    {
      id: 'q21',
      list: false,
      question: t('help::When do I need to request a credit increase?'),
      answer: {
        openingSentence: t(
          'help::Your Microsoft Representative has estimated your billing account’s Azure costs. If this estimate is significantly higher than your current Azure costs, your billing account may need a credit increase.'
        ),
      },
    },
    {
      id: 'q22',
      list: false,
      question: t('help::How often is a Credit Check required?'),
      answer: {
        openingSentence: t(
          'help::Once a credit line is established, it doesn’t expire for 8 months. After 8 months, a new review is required. A manual review is less likely for an expired credit check.'
        ),
      },
    },
    {
      id: 'q23',
      list: false,
      question: t('help::How do I know if my quote will need additional approval?'),
      answer: {
        openingSentence: t(
          'help::When any action is taken on a quote that will require further approval, the Quote Name will change to show that approval is required. Details of what type of approval is needed will be shown in the detail pane.'
        ),
      },
    },
    {
      id: 'q24',
      list: false,
      question: t('help::I don’t see where to add an Approver to the quote, where can I do so?'),
      answer: {
        openingSentence: t(
          'help::When you submit the quote, the Approval dialog will be displayed where you can indicate the approver for this quote.'
        ),
      },
    },
    {
      id: 'q25',
      list: false,
      question: t('help::Do I need to send the Quote to the approver?'),
      answer: {
        openingSentence: t(
          'help::No, the system will automatically send an email notification to the approver indicating their action is needed (cc’ing the quote’s submitter).'
        ),
      },
    },
    {
      id: 'q26',
      list: false,
      question: t('help::My approver is on vacation, what do I do now?'),
      answer: {
        openingSentence: t(
          'help::Withdrawing the quote will cancel the request for approval and you can submit the quote again, selecting a different approver.'
        ),
      },
    },
    {
      id: 'q27',
      list: false,
      question: t(
        'help::I have documentation to support my discount request for this quote, where do I attach it?'
      ),
      answer: {
        openingSentence: t(
          'help::Attachments are not supported at this time. Instead, save the documentation in a place where the approver has access, e.g. SharePoint, and paste a link into the comments to the approver.'
        ),
      },
    },
    {
      id: 'q28',
      list: false,
      question: t(
        'help::I see a quote awaiting approval from one of my co-workers, can I approve them?'
      ),
      answer: {
        openingSentence: t(
          'help::You can only approve quotes on which you have been named as the approver, even if you have adequate empowerment.'
        ),
      },
    },
    {
      id: 'q29',
      list: false,
      question: t(
        'help::I want to suggest a different deal parameter to the seller, what should I do?'
      ),
      answer: {
        openingSentence: t(
          'help::Reject the quote, entering the message to the seller on the different parameters in the Rejection Comments.  The seller can then modify the quote and resubmit for your approval.'
        ),
      },
    },
  ];
};
export const customerQuestions = (t: i18next.TFunction) => {
  return [
    {
      id: 'q1',
      list: false,
      question: t('help::How is moving to the new commerce experience good for Azure customers?'),
      answer: {
        openingSentence: t(
          'help::For customers who want to buy their Azure directly from Microsoft and bring in various partners to help them manage and deploy services as needed, the new commerce experience and buying through the customer agreement is the easiest way to buy. Customers will have an easy signing and onboarding process, a unified deployment and management portal, and simplified invoicing.'
        ),
      },
    },
    {
      id: 'q2',
      list: false,
      question: t(
        'help::Why start with moving direct commercial customers to purchasing Azure on the new commerce experience through the Microsoft Customer Agreement?'
      ),
      answer: {
        openingSentence: t(
          'help::We want to start by reaching and converting breadth customers first, and we want to phase the launch by starting with a smaller audience with relatively simple requirements and lead up to more complex customers. Reaching direct commercial customers first helps us achieve transition in a small part of the breadth space, but also get enough customers onto the new commerce experience to show the true experience. We will expand both the products and services available, and the addressable market, over time.'
        ),
      },
    },
    {
      id: 'q3',
      list: false,
      question: t(
        'help::What happens to customers that meet the criteria to purchase Azure on the new commerce experience, but are non-managed by Microsoft or a partner?'
      ),
      answer: {
        openingSentence: t(
          'help::Eligible non-managed customers will be contacted by Microsoft sales (Inside Sales) starting in March 2019.'
        ),
      },
    },
    {
      id: 'q4',
      list: false,
      question: t('help::Is the Microsoft Customer Agreement digital signature only?'),
      answer: {
        openingSentence: t(
          'help::Yes, customers will receive the agreement via email and will click and accept to continue the purchasing process. There are plans for future functionality to download and print for other types of signatures.'
        ),
      },
    },
    {
      id: 'q5',
      list: false,
      question: t(
        'help::Can a customer move all their licenses, not just Azure, to the new commerce experience if they have another EA enrollment?'
      ),
      answer: {
        openingSentence: t(
          'help::At launch, Azure is being transacted via the Microsoft Customer Agreement for customers with a Server and Cloud Enrollment that only has Azure purchases. All other purchases that may be on other enrollments for a customer will continue to be transacted trough traditional volume licensing agreements.'
        ),
      },
    },
    {
      id: 'q6',
      list: false,
      question: t(
        'help::If a customer has other products on their SCE, in addition to Azure, can they move to the new motion with the Microsoft Customer Agreement?'
      ),
      answer: {
        openingSentence: t(
          'help::Customers that have more than just Azure on their SCE can move only their Azure to the new motion if they want to; other products will continue to be purchased through their existing EA or other motions (for example, CSP).'
        ),
      },
    },
    {
      id: 'q7',
      list: false,
      question: t('help::What offers are priced in USD?'),
      answer: {
        openingSentence: t(
          'help::Azure consumption and Azure entitlements (Azure support and Azure Reserved Instances). Third-Party Marketplace offers are excluded.'
        ),
      },
    },
    {
      id: 'q8',
      list: false,
      question: t(
        'help::What is the guidance on how to decide when to grant discounts? Especially since there are no commitment capabilities (MC< Commitment to consume) at launch to back that discount up.'
      ),
      answer: {
        openingSentence: t(
          'help::There will be concession guidance published soon, and it will be based off expected annual deal value/consumption. Standard field empowerment is available, but greater that 15% would go through Business Desk, the same as with an EA. Any deal that requires Business Desk touch outside of discounting won’t be supported at launch. As an example, TTE on the EA today is at $50K/year, so the new commerce experience guidance will be that the expected annual consumption should be $50K/year before granting any concessions.'
        ),
      },
    },
  ];
};
export const currencyQuestions = (t: i18next.TFunction) => {
  return [
    {
      id: 'q1',
      list: false,
      question: t(
        'help::How does currency for Azure pricing and billing work under the Microsoft Customer Agreement?'
      ),
      answer: {
        answerComponentKey: 'CurrencyMap',
        openingSentence: t(
          'help::With the new Microsoft Customer Agreement, Azure is priced in US dollars worldwide, enabling you to easily predict your monthly costs. If you’re billed in one of the 16 listed currencies, your monthly cost is first calculated in US dollars. For payment, the total is then converted to local currency using a monthly exchange rate.\n\n'
        ),
      },
    },
    {
      id: 'q2',
      list: false,
      question: t('help::What exchange rate will be used and how does it work with my bill?'),
      answer: {
        openingSentence: t(
          'help::To deliver cost predictably, we use Thompson Reuters benchmark rates* that are refreshed on the first day of each calendar month and applied to all transactions during that month. For consumption overage, monthly usage is aggregated at the end of each month in US dollars, and then converted to your billing currency using that month’s exchange rate.\n\nExample: The January exchange rate will be applied to all Azure purchases made in January and all Azure consumption in January. These will appear on the January invoice, which is available at the beginning of February.'
        ),
      },
    },
    {
      id: 'q3',
      list: true,
      question: t('help::What is priced in US dollars?'),
      answer: {
        openingSentence: t('help::Azure is priced in US dollars, including:'),
        answerList: [
          t('help::Azure products and services'),
          t('help::Azure support'),
          t('help::Azure reservations'),
        ],
      },
    },
    {
      id: 'q4',
      list: true,
      question: t('help::Which products and services are not affected by this change?'),
      answer: {
        openingSentence: t(
          'help::The following products and services are not affected by this change:'
        ),
        answerList: [
          t(
            'help::Software and software subscriptions including, but not limited to:\n\tSQL Server licenses\n\tWindows Server licenses'
          ),
          t(
            'help::Online services including, but not limited to:\n\tMicrosoft 365\n\tDynamics 365'
          ),
          t('help::Non-Microsoft products and services'),
        ],
      },
    },
    {
      id: 'q5',
      list: true,
      question: t('help::Where can I see the exchange rate?'),
      answer: {
        answerLinks: [
          {
            id: 'a1',
            text: t('help::The Azure pricing calculator'),
            url: 'https://azure.microsoft.com/en-us/pricing/calculator/',
          },
        ],
        openingSentence: t('help::You can find the exchange rate in:'),
        answerList: ['{0}', t('help::Your monthly invoice'), t('help::Your Microsoft quote')],
      },
    },
  ];
};
export const currencies = (t: i18next.TFunction) => {
  return [
    {
      id: 'c1',
      code: 'AUD',
      currency: t('help::Australian dollar'),
    },
    {
      id: 'c9',
      code: 'JPY',
      currency: t('help::Japanese yen'),
    },
    {
      id: 'c2',
      code: 'BRL',
      currency: t('help::Brazilian real'),
    },
    {
      id: 'c10',
      code: 'KRW',
      currency: t('help::Korean won'),
    },
    {
      id: 'c3',
      code: 'GBP',
      currency: t('help::British pound'),
    },
    {
      id: 'c11',
      code: 'NZD',
      currency: t('help::New Zealand dollar'),
    },
    {
      id: 'c4',
      code: 'CAD',
      currency: t('help::Canadian dollar'),
    },
    {
      id: 'c12',
      code: 'NOK',
      currency: t('help::Norwegian krone'),
    },
    {
      id: 'c5',
      code: 'CNY',
      currency: t('help::Chinese yuan'),
    },
    {
      id: 'c13',
      code: 'RUB',
      currency: t('help::Russian ruble'),
    },
    {
      id: 'c6',
      code: 'DKK',
      currency: t('help::Danish krone'),
    },
    {
      id: 'c14',
      code: 'SEK',
      currency: t('help::Swedish krona'),
    },
    {
      id: 'c7',
      code: 'EUR',
      currency: t('help::Euro'),
    },
    {
      id: 'c15',
      code: 'CHF',
      currency: t('help::Swiss franc'),
    },
    {
      id: 'c8',
      code: 'INR',
      currency: t('help::Indian rupee'),
    },
    {
      id: 'c16',
      code: 'TWD',
      currency: t('help::Taiwan dollar'),
    },
  ];
};
