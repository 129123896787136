import { MediumIcon } from 'components';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export enum HeaderMessageSeverity {
  Warning,
  Error,
}

export interface AddressHeaderMessage {
  severity: HeaderMessageSeverity;
  text: string;
}

const styles = (theme: ThemeProps) => ({
  errorContainer: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  errorIcon: {
    color: theme.palette.dangerText,
    marginRight: 6,
    marginTop: 2,
  },
  text: {
    color: (props: Props) =>
      props.message.severity === HeaderMessageSeverity.Warning
        ? theme.palette.warningText
        : theme.palette.dangerText,
    fontSize: 14,
  },
  warningIcon: {
    color: theme.palette.warningText,
    marginRight: 6,
    marginTop: 2,
  },
});

type Props = {
  message: AddressHeaderMessage;
} & WithStyles<typeof styles>;

const AddressDialogHeaderUnstyled: React.FC<Props> = props => {
  const { classes, message } = props;

  const icon =
    message.severity === HeaderMessageSeverity.Error ? (
      <MediumIcon addClass={classes.errorIcon} iconName="ErrorBadge" />
    ) : (
      <MediumIcon addClass={classes.warningIcon} iconName="Warning" />
    );

  return (
    <div className={classes.errorContainer}>
      {icon}
      <div className={classes.text}>{message.text}</div>
    </div>
  );
};

export const AddressDialogHeader = withStyles(styles)(AddressDialogHeaderUnstyled);
