import { CommandButtonAtom } from 'components/atoms';
import {
  IButtonStyles,
  IContextualMenuItemStyles,
  IContextualMenuStyles,
  IMenuItemStyles,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { buildButtonMenu, ButtonMenuProps, ButtonSharedProps } from '../ButtonSharedProps';
import { splitButtonStyles } from './SplitButton.styles';

export interface ISplitButtonProps extends ButtonSharedProps {
  menuProps: ButtonMenuProps[];
  menuId?: string;
  menuDisabled?: boolean;
}

type Props = ISplitButtonProps & WithStyles<typeof splitButtonStyles>;

const SplitButtonUnstyled: React.FC<Props> = (props: Props) => {
  const buttonStyle: Partial<IButtonStyles> = {
    rootExpanded: props.classes.commandExpanded,
  };
  const menuItemStyle: Partial<IContextualMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  const dropdownStyles: Partial<IContextualMenuStyles> = {
    root: props.classes.menuRoot,
    container: props.classes.menuList,
    subComponentStyles: {
      callout: {
        root: props.classes.calloutRoot,
        calloutMain: props.classes.calloutMain,
      },
      menuItem: menuItemStyle,
    },
  };
  const menuItemStyles: Partial<IMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  return (
    <div id={props.id}>
      <CommandButtonAtom
        addClass={props.classes.split}
        ariaLabel={props.ariaLabel}
        disabled={props.disabled}
        iconProps={props.iconName ? { iconName: props.iconName } : undefined}
        id={`${props.id}-Left`}
        text={props.text}
        onClick={props.onClick}
      />
      <div className={props.classes.divider} />
      <CommandButtonAtom
        addClass={`${props.classes.command} ${props.classes.splitMenuButton}`}
        ariaLabel="Open menu"
        disabled={props.menuDisabled}
        id={`${props.id}-Right`}
        menuProps={buildButtonMenu(props.menuProps, dropdownStyles, menuItemStyles, props.menuId)}
        styles={buttonStyle}
      />
    </div>
  );
};

SplitButtonUnstyled.defaultProps = {
  menuDisabled: false,
};

export const SplitButton = withStyles(splitButtonStyles)(SplitButtonUnstyled) as React.FC<
  ISplitButtonProps
>;
