import { LinkExternal, NotificationItem } from 'components';
import { LabelListItem } from 'components/ions/CuratedText/LabelList/LabelList';
import { RuleType } from 'features/app/config/type';
import { getFlightIsEnabled } from 'features/app/selectors';
import { getAddOnProductTitles, getProduct } from 'features/catalog/selectors';
import * as customerSelectors from 'features/customer/selectors';
import { loadPricesAsync } from 'features/proposal/actions';
import {
  getApplicableValueCondition,
  getDiscounts,
  getEndCondition,
  getMeterName,
  getStartCondition,
  isProductFinanceTerm,
} from 'features/proposal/components/DetailsPane/detailsUtils';
import { ExistingDiscountLabels } from 'features/proposal/components/ExistingDiscountsList';
import {
  getActiveProposal,
  getBillingCurrency,
  getLineItemProduct,
  getMarket,
  getOrganizationId,
  getPrices,
  getProductIdentifier,
  getSkuTitles,
  isProductsView,
  needsProductConfiguration,
} from 'features/proposal/selectors';
import { createSaasRenewalPriceInfoNotification } from 'features/proposal/selectors/utils';
import {
  buildLoadPriceRequest,
  computeFirstAndLastTimezone,
  displayInDayFormatAndUseMarketTimezone,
  getMonetaryCreditAmount,
} from 'features/proposal/utils';
import i18next from 'i18next';
import moment from 'moment-timezone';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isImageArray, isImageObj, Product } from 'services/catalog/types';
import { Flight } from 'services/flights/flightList';
import { LineItem, Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { isMonetary, isSAPTerm, isSimpleTerm } from '../ConfigCard/ConfigCardBusinessLogic';
import { buildSkuStrings } from '../DiscountCard/utils';
import { DiscountDetailsPane } from './DiscountDetailsPane';
import { LineItemDetails } from './LineItemDetails';

interface RowItemDetailsProps {
  notificationList: NotificationItem[];
  lineItem: LineItem;
  isTermItem?: boolean;
  groupedLineItem?: LineItem;
  isDiscountItem?: boolean;
}

const getTermsConditions = (
  lineItem: LineItem,
  t: i18next.TFunction,
  product?: Product,
  groupedProduct?: Product,
  currency?: string,
  proposal?: Proposal
) => {
  const results: LabelListItem[] = [];
  const isFinanceTerm = isProductFinanceTerm(product);
  const financeProductProps = oc(groupedProduct).LocalizedProperties[0]();
  const isSAPTermLineItem = product && isSAPTerm(product);
  const amount = currency && getMonetaryCreditAmount(lineItem, currency);
  isSAPTermLineItem &&
    amount &&
    results.push({
      name: t('quote::Amount'),
      value: amount,
      dataAutomationId: 'Amount',
    });
  if (!isFinanceTerm) {
    const start = {
      name: t('quote::Start'),
      value: getStartCondition(isFinanceTerm, t, lineItem, product, financeProductProps),
      dataAutomationId: 'Start',
    };
    if (
      product &&
      (isMonetary(product) ||
        isSimpleTerm(product) ||
        (product.DisplaySkuAvailabilities && product.DisplaySkuAvailabilities.length > 1))
    ) {
      results.push(getApplicableValueCondition(lineItem, t, currency));
    }
    results.push(start);
  }

  const end = getEndCondition(lineItem, isFinanceTerm, !!isSAPTermLineItem, t, product);
  results.push(end);

  if (isSAPTermLineItem) {
    const email = oc(proposal).header.sapReferenceData.contact.emailAddress();
    const firstName = oc(proposal).header.sapReferenceData.contact.firstName();
    const lastName = oc(proposal).header.sapReferenceData.contact.lastName();
    const name = firstName && lastName && `${firstName} ${lastName}`;
    const sapOpptyId = oc(proposal).header.sapReferenceData.opportunityId();

    name &&
      results.push({
        name: t('quote::Admin name'),
        value: name,
        dataAutomationId: 'adminName',
      });
    email &&
      results.push({
        name: t('quote::Admin email'),
        value: email,
        dataAutomationId: 'adminEmail',
      });
    sapOpptyId &&
      results.push({
        name: t('quote::SAP opportunity ID'),
        value: sapOpptyId,
      });
  }
  return results;
};

const dispatchProps = {
  loadPrice: loadPricesAsync.request,
};

const mapStateToProps = (state: RootState, ownProps: RowItemDetailsProps) => {
  const { lineItem } = ownProps;
  const productId = oc(getProductIdentifier(lineItem)).productId();
  const product = productId ? getProduct(state, productId) : undefined;
  const productType = product ? product.ProductType : undefined;

  return {
    isTermItem: !isProductsView(state),
    product,
    addOnProductTitles: productId ? getAddOnProductTitles(state, productId) : undefined,
    existingDiscounts: productId
      ? customerSelectors.getCustomPrices(state, getOrganizationId(state), productType, productId)
      : [],
    skuTitles: getSkuTitles(state),
    market: getMarket(state),
    groupedProduct: ownProps.groupedLineItem
      ? getLineItemProduct(state, ownProps.groupedLineItem)
      : undefined,
    lineItem,
    currency: getBillingCurrency(state),
    showAddons: getFlightIsEnabled(state, Flight.showAddons),
    modernOfficeFlightEnabled: getFlightIsEnabled(state, Flight.modernOffice),
    lineItemNeedsProductConfiguration: !!needsProductConfiguration(state, lineItem),
    enableECIFUpdates: getFlightIsEnabled(state, Flight.ECIF),
    proposal: getActiveProposal(state),
    priceMap: getPrices(state),
  };
};

export type Props = RowItemDetailsProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const RowItemDetailsUnconnected = (props: Props) => {
  const {
    product,
    existingDiscounts,
    skuTitles,
    isTermItem,
    lineItem,
    market,
    groupedProduct,
    currency,
    isDiscountItem,
    proposal,
    notificationList,
    loadPrice,
    priceMap,
  } = props;
  const { t } = useTranslation();

  const saasNotification =
    product && createSaasRenewalPriceInfoNotification(product, lineItem, market);
  if (saasNotification && !priceMap[saasNotification.key]) {
    const requestPayload = buildLoadPriceRequest(
      [saasNotification.productIdentifier],
      proposal.lineItems,
      proposal.header.pricingContext
    );
    // this means the SAAS cards product configuration is already done.
    if (oc(lineItem).productIdentifier.action()) {
      loadPrice(requestPayload);
    }
  }

  let imageUri = '';
  let publisherName = '';
  let publisherUri = '';
  let productDescription = '';
  let productTitle = '';
  let strippedDescription = '';
  const localizedProps = product && product.LocalizedProperties[0];
  if (localizedProps) {
    publisherName = localizedProps.PublisherName;
    publisherUri = localizedProps.PublisherWebsiteUri ? localizedProps.PublisherWebsiteUri : '';
    productDescription = localizedProps.ProductDescription;
    productTitle = localizedProps.ProductTitle;
    strippedDescription = localizedProps.StrippedDescription;
    if (isImageObj(localizedProps.Images)) {
      imageUri = localizedProps.Images.Logo.Uri;
    } else if (isImageArray(localizedProps.Images)) {
      const found = localizedProps.Images.find(img => img.ImagePurpose.toLowerCase() === 'logo');
      imageUri = found ? found.Uri : '';
    }
  }
  const shouldDisplayInDayFormatAndUseMarketTimezone = !!(
    product &&
    displayInDayFormatAndUseMarketTimezone(product.ProductType, lineItem.productIdentifier.action)
  );
  const displayDiscounts = getDiscounts(
    t,
    skuTitles,
    props.market,
    shouldDisplayInDayFormatAndUseMarketTimezone,
    product,
    existingDiscounts
  );
  const conditions = isTermItem
    ? getTermsConditions(lineItem, t, product, groupedProduct, currency, proposal)
    : [];

  const labels: ExistingDiscountLabels = {
    existingDiscount: t('quote::Existing discounts'),
    dates: t('quote::Dates'),
    lock: t('quote::Lock'),
    discount: t('quote::Discount'),
    duration: t('quote::Duration'),
    meter: t('quote::Meter:'),
    oneTimeDiscount: t('quote::One time discount'),
  };

  const publisherLink = publisherUri ? (
    <LinkExternal displayText={publisherName} href={publisherUri} id={`${publisherName}-website`} />
  ) : (
    undefined
  );

  const discountType = oc(lineItem).pricingInstruction.ruleType();

  // TODO: jepagan - create selector to generate this data from ECIF line items
  const mockEcifData = {
    currency: 'USD',
    oneAskId: '12345678',
    oneAskLink: '#',
    totalAmountApproved: '12000',
    summary: `Offering Hidden Spaces $25,000 to work with MCS to develop a POC for moving their HR tools
    to Azure. Lorem ipsum dolor sit amet consectetur adipiscing elit netus, dignissim dis 
    curabitur augue bibendum ornare justo facilisi proin, cras turpis facilisis congue
    vestibulum consequat auctor.`,
  };

  const mockEcifDataConfigured = {
    ...mockEcifData,
    endDate: new Date().toDateString().substr(4),
    finalAmount: '1200',
    productFamilies: ['Azure', 'Office 365'],
    types: ['Internal', 'External'],
    services: [
      {
        shortName: 'Proof of Concept',
        amount: '6000',
      },
      {
        shortName: 'ISV Tooling',
        amount: '3000',
      },
      {
        shortName: 'Migration and Deployment',
        amount: '400',
      },
      {
        shortName: 'Migration and Deployment',
        amount: '10000',
      },
      {
        shortName: 'TAP, EAP, RDP',
        amount: '2000',
      },
    ],
  };

  let startDate;
  let endDate;
  let priceGuaranteeDate;
  const { first: firstTimezone, last: lastTimezone } = computeFirstAndLastTimezone(
    market,
    shouldDisplayInDayFormatAndUseMarketTimezone
  );
  const dateWithDayFormat = 'MM-DD-YYYY';
  const dateWithMonthFormat = 'MMM. YYYY';
  let showDay = true;
  if (
    !!product &&
    !displayInDayFormatAndUseMarketTimezone(product.ProductType, lineItem.productIdentifier.action)
  ) {
    showDay = false;
  }
  if (lineItem.startDate && lineItem.endDate) {
    startDate = moment
      .tz(lineItem.startDate, firstTimezone)
      .format(showDay ? dateWithDayFormat : dateWithMonthFormat);
    endDate = moment
      .tz(lineItem.endDate, lastTimezone)
      .format(showDay ? dateWithDayFormat : dateWithMonthFormat);
  }

  let duration;
  if (lineItem.duration) {
    const monthsCount = moment.duration(lineItem.duration).asMonths();
    const yearsText = t('quote::{{count}} year', {
      count: Math.ceil(monthsCount / 12),
      // eslint-disable-next-line @typescript-eslint/camelcase
      defaultValue_plural: `{{count}} years`,
    });

    const monthsText = t('quote::{{count}} month', {
      count: monthsCount,
      // eslint-disable-next-line @typescript-eslint/camelcase
      defaultValue_plural: `{{count}} months`,
    });
    duration = monthsCount % 12 === 0 ? yearsText : monthsText;
  }

  if (lineItem.pricingInstruction && lineItem.pricingInstruction.priceGuaranteeDate) {
    priceGuaranteeDate = moment
      .tz(lineItem.pricingInstruction.priceGuaranteeDate, firstTimezone)
      .format(dateWithDayFormat);
  }

  return isDiscountItem &&
    discountType &&
    discountType.toLowerCase() !== RuleType.oneTime.toLowerCase() ? (
    <DiscountDetailsPane
      dataAutomationId="discountDetailsPane"
      description={productDescription}
      discountPercent={oc(lineItem).pricingInstruction.discountPercentage(0)}
      discounts={displayDiscounts}
      discountType={discountType}
      duration={duration}
      endDate={endDate}
      id="Discount-Details-Pane"
      imageUri={imageUri}
      labels={labels}
      lockDate={priceGuaranteeDate}
      meterName={getMeterName(lineItem)}
      notifications={notificationList}
      productName={productTitle}
      publisherLink={publisherLink}
      skus={buildSkuStrings(lineItem, skuTitles)}
      startDate={startDate}
    />
  ) : (
    <LineItemDetails
      addOns={props.addOnProductTitles}
      conditions={conditions}
      configurations={[]}
      description={productDescription}
      discounts={displayDiscounts}
      ecifData={
        // TODO jepagan - refactor when quote services work for ECIf is completed
        props.enableECIFUpdates &&
        props.lineItem.productIdentifier.productType &&
        props.lineItem.productIdentifier.productType.toLowerCase() === 'ecif'
          ? notificationList.length
            ? mockEcifData
            : mockEcifDataConfigured
          : undefined
      }
      id="RowItem-Details-Pane"
      imageUri={imageUri}
      labels={labels}
      notifications={notificationList}
      productName={productTitle}
      publisherLink={publisherLink}
      showAddons={props.showAddons}
      strippedDescription={strippedDescription}
    />
  );
};

export const RowItemDetails = connect(mapStateToProps, dispatchProps)(RowItemDetailsUnconnected);
