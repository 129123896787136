import { ActiveQuoteProvider } from 'features-apollo/ActiveQuoteProvider';
import * as React from 'react';
import { SetActiveQuoteContext } from 'testUtils';

import { completeMockQuote } from '../Cards/mocks';
import { SimpleTermsCard } from './SimpleTermsCard';

export const mockedSimpleTermQuote = {
  ...completeMockQuote,
  __typename: 'Quote',
  id: '12345',
  lineItems: [
    {
      __typename: 'SimpleTermLineItem',
      type: 'negotiated',
      description:
        'These terms cover the End Customer Investment Funding provided by Microsoft or its partners to the customer.',
      id: 'ECIFTEST1',
      subtitle: 'Subtitle here',
      title: 'Ecif line item test',
      product: {
        description:
          'These terms cover the End Customer Investment Funding provided by Microsoft or its partners to the customer.',
        plainTextDescription:
          'These terms cover the End Customer Investment Funding provided by Microsoft or its partners to the customer.',
        productFamily: 'NegotiatedTerms',
        productType: 'ECIF',
        publisherName: 'Microsoft',
        publisherUrl: 'http://www.microsoft.com/',
        productId: '0RDCKN5233F1',
        startCondition: null,
        title: 'End Customer Investment Funding Terms',
        __typename: 'Product',
      },
      sku: {
        __typename: 'Sku',
        skuId: '543321',
        filters: [],
      },
      availability: {
        __typename: 'Availability',
        availabilityId: '0987',
      },
      supplementalTermReferenceData: null,
      groups: [],
      isReadyForPricing: false,
      catalogContext: null,
      term: {
        __typename: 'Term',
        termId: 'term12',
      },
    },
  ],
};

const SimpleTermsCardGQL = (props: any) => {
  const ref = React.createRef<HTMLSpanElement>();

  return (
    <ActiveQuoteProvider>
      <SetActiveQuoteContext quoteId={props.quoteId ? props.quoteId : '12345'}>
        <SimpleTermsCard
          target={ref}
          hidden={false}
          name={'title test'}
          lineItemId={'ECIFTEST1'}
          onDismiss={props.onDismiss}
        />
      </SetActiveQuoteContext>
    </ActiveQuoteProvider>
  );
};

export default {
  component: SimpleTermsCardGQL,
  name: 'SimpleTermsCard-GQL',
  props: {
    onDismiss: () => {
      console.log('Close the card');
    },
  },
  apollo: {
    resolveWith: {
      data: {
        getQuote: {
          ...mockedSimpleTermQuote,
        },
      },
    },
  },
};
