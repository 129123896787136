import { ThemeProps } from 'styles';

export const AccessDeniedPageStyles = (theme: ThemeProps) => ({
  colContainer: {
    display: 'table',
    width: '100%',
    height: '100%',
  },
  backgroundContainer: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100%',
    '& div': {
      'background-repeat': 'no-repeat, no-repeat',
      'background-position': 'center center, center center',
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
  background: {
    background: 'url(/images/accessdenied.jpg) no-repeat center center fixed',
  },
  backgroundOverlay: { background: 'rgba(0, 0, 0, 0.55)' },
  messageContainer: {
    display: 'table-cell',
    verticalAlign: 'middle',
    width: '100%',
    height: '100vh',
  },
  innerMessage: {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    maxWidth: '350px',
    width: 'calc(100% - 40px)',
    padding: '44px',
    marginBottom: '28px',
    backgroundColor: theme.palette.backgroundStandout,
    '-webkit-box-shadow': '0 2px 6px rgba(0, 0, 0, 0.2)',
    '-moz-box-shadow': '0 2px 6px rgba(0, 0, 0, 0.2)',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
    minWidth: '250px',
    minHeight: '250px',
    overflow: 'hidden',
    display: 'table',
  },
  content: {
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  titleContainer: {
    display: 'flex',
    paddingBottom: '16px',
  },

  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    overflow: 'visible',
    zIndex: 99,
    clear: 'both',
    backgroundColor: 'rgba(0,0,0,0.6)',
    '& a, & span': {
      color: '#fff',
      fontSize: '12px',
      lineHeight: '28px',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      marginLeft: '8px',
      marginRight: '8px',
    },
  },
  footerLinks: {
    margin: 0,
    float: 'right',
  },
  choiceGroup: {
    paddingTop: '18px',
    paddingLeft: '12px',
  },
  button: {
    marginTop: '36px',
  },
});
