import { ThemeProps, sharedStyles } from 'styles';

export const accountNameDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  nameStyles: {
    paddingTop: '18px',
    width: '375px',
  },
  secondaryText: {
    color: theme.palette.textTertiary,
  },
});
