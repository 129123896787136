import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { Subscription } from 'apollo-client/util/Observable';
import { ApolloLink, Observable, Operation } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { buildNumber } from 'App';
import { getConfigByEnvironment } from 'features/app/config/configurations';
import { Store } from 'redux';
import { ApolloConfig, endpoints, environmentOverrides } from 'services/apollo/config';
import loggerService from 'services/logger-service';
import { getCV } from 'services/utils';

import introspectionQueryResultData from '../../generated/fragmentTypes.json';
import { getObjectId } from './utils';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === 'local.c3.mepla.com' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

async function getIdToken(): Promise<string> {
  const msal: any = window.msal;
  const token: any = await msal.getIdToken();
  return `${token.idToken.rawIdToken}`;
}

async function getHeadersWithIdToken(useTestHeader: boolean, token?: string) {
  return {
    idtoken: token ? token : await getIdToken(),
    usetestheader: useTestHeader,
    'qc-build-number': buildNumber,
    'ms-cv': getCV(),
    flights: '',
  };
}

export const createApolloClient = (apolloConfig: ApolloConfig, store: Store, token?: string) => {
  const request = async (operation: Operation) => {
    let headers = await getHeadersWithIdToken(apolloConfig.useTestHeader, token);

    if (apolloConfig.useEnvironmentOverrides) {
      headers = {
        ...headers,
        ...environmentOverrides[apolloConfig.environment],
      };
    }
    headers = {
      ...headers,
      flights: JSON.stringify({
        customterms: store.getState().app.flights.customterms,
        delaycreditcheck: store.getState().app.flights.delaycreditcheck,
        disableproductinclusionfilter: store.getState().app.flights.disableproductinclusionfilter,
        ecif: store.getState().app.flights.ecif,
        enableblendedautosuggest: store.getState().app.flights.enableBlendedAutosuggest,
        enrollmentassembly: store.getState().app.flights.enrollmentassembly,
        fedindirect: store.getState().app.flights.graphqlphase2,
        msxppe: store.getState().app.flights.MSXPPE,
        pidsearch: store.getState().app.flights.pidsearch,
        requiredcredit: store.getState().app.flights.requiredcredit,
        safelistedcreditcheck: store.getState().app.flights.safelistedcreditcheck,
      }),
    };
    if (store.getState().app.flights.ecif) {
      const oneAsk = { oneaskenvironment: 'int' };
      headers = {
        ...headers,
        ...oneAsk,
      };
    }
    operation.setContext({
      headers,
    });
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle: Subscription;
        Promise.resolve(operation)
          .then(oper => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError, response }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            if (isLocalhost) {
              console.group(
                '%c GraphQL Error',
                'color: #424949 ; background: #F7DC6F; font-size: large; display: block;'
              );
              console.info('Message: %s', message);
              console.info('Extensions: %O', extensions);
              console.info('Locations: %O', locations);
              console.info('Path: %O', path);
              console.info('Response: %O', response);
              console.groupEnd();
            } else {
              loggerService.log({
                name: 'GraphQL Error - Resolver Level',
                properties: {
                  message: message,
                  locations: locations,
                  path: path,
                  extensions: extensions,
                },
              });
            }
          });
        }
        if (networkError) {
          if (isLocalhost) {
            console.group(
              '%c GraphQL Network Error!',
              'color: #F4F6F7 ; background: #CB4335; font-size: large; display: block; width: 600px'
            );
            console.error(networkError);
            console.groupEnd();
          } else {
            loggerService.error({ error: networkError });
          }
        }
      }),
      requestLink,
      new HttpLink({
        uri: endpoints[apolloConfig.environment],
        credentials: 'same-origin',
        fetchOptions: {
          credentials: 'include',
        },
      }),
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: getObjectId,
      fragmentMatcher,
    }),
    defaultOptions: { mutate: { errorPolicy: 'all' }, query: { errorPolicy: 'all' } },
  });
};

export const apolloClient = (store: Store) =>
  createApolloClient(getConfigByEnvironment().apollo, store);
