import { CustomerList, SecondaryButton, Spinner, SpinnerSize } from 'components/ions';
import { SearchResultsStyles as styles } from 'features/ldsscustomer/components/SearchResults.styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { defaultCustomerSearchPageSize } from 'services/ldss/config';

import { CustomerListData } from '../types';
import { SearchResultStatus } from './SearchResultStatus';

export interface SearchResultsProps {
  addClass?: string;
  isLoading: boolean;
  hasError: boolean;
  searchResults?: CustomerListData;
  searchQuery?: string;
  errorRetry?: () => void;
  clearSearch?: () => void;
  loadMoreHandler?: () => void;
  totalResults?: number;
  currentPageNumber?: number;
  hasLDSSPermission: boolean;
  displayEnrollmentId?: boolean;
  enrollmentLabel?: string;
  onSelect?: (customer: string) => void;
}

type Props = SearchResultsProps & WithStyles<typeof styles>;

const SearchResults: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const loadedItemsCount = defaultCustomerSearchPageSize * ((props.currentPageNumber || 0) + 1);
  const enableLoadMore = (props.totalResults || 0) > loadedItemsCount && !props.isLoading;

  const customers = (
    <CustomerList
      customers={props.searchResults && props.searchResults.customers}
      displayEnrollmentId={props.displayEnrollmentId}
      enrollmentLabel={props.enrollmentLabel}
      loading={props.isLoading && props.currentPageNumber === 0}
      searchText={props.searchQuery || ''}
      onSelect={props.onSelect}
    />
  );

  const statusMessage = (
    <SearchResultStatus
      clearSearch={props.clearSearch}
      errorRetry={props.errorRetry}
      hasBeenSearched={!!(props.searchQuery && props.searchQuery.length)}
      hasError={props.hasError}
      hasLDSSPermission={props.hasLDSSPermission}
      isLoading={props.isLoading}
      numResults={props.totalResults || 0}
      searchQuery={props.searchQuery}
    />
  );

  const loadMoreButton = props.loadMoreHandler && (
    <SecondaryButton
      addClass={!enableLoadMore ? props.classes.transparent : ''}
      dataAutomationId="searchResultsLoadMoreButton"
      disabled={!enableLoadMore}
      text={t('home::Load more')}
      onClick={props.loadMoreHandler}
    />
  );

  const loadMoreSpinner = (
    <Spinner label="Loading..." labelPosition="right" size={SpinnerSize.large} />
  );

  return (
    <div className={`${props.addClass}`}>
      <div>{statusMessage}</div>
      <div>{customers}</div>
      {loadMoreButton && (
        <div className={props.classes.loadMoreButton}>
          {props.isLoading && !!props.currentPageNumber ? loadMoreSpinner : loadMoreButton}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(SearchResults);
