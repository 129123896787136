import { sharedStyles as dialogSharedStyles } from 'features-apollo/quote/components/Dialogs/shared.styles';
import { sharedStyles, ThemeProps } from 'styles';

export const tenantWizardStyles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  ...sharedStyles,
  body: {
    overflowY: 'hidden',
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25,
  },
});
