import {
  NavLinkInternal,
  TextBodySmall,
  TextPredefinedStyle,
  TextSectionHeading,
} from 'components';
import { Price } from 'features-apollo/components/Price';
import { CwaLink } from 'features-apollo/quote/components/CwaLink';
import { CreditLineReason } from 'features-apollo/quote/types';
import { Address, CrmType, Currency } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { routes } from 'routes';
import { ThemeProps } from 'styles';

import { OrganizationTile } from '../../OrganizationTile';
import { DetailDisplay } from './DetailDisplay';

export interface CRMInformation {
  /**
   * Display as detail
   */
  id?: string | null;
  /**
   * Display as part of label
   */
  type?: CrmType | null;
  /**
   * Use to redirect user to the Sales account information on CWA
   */
  tpid?: string | null;
}

export interface OrganizationDetails {
  /**
   * Use to search quotes based on SoldTo organizarion id
   */
  organizationId?: string | null;
  /**
   * Displays in Organization Tile
   */
  address?: Address | null;
  /**
   * Displays in Organization Tile
   */
  tradeName?: string | null;
}

interface SummaryDetailsPaneParticipantsProps {
  /**
   * Credit information of the single participant in the quote
   */
  creditInformation?: { creditLine?: string | null; currency: Currency; status: CreditLineReason };
  /**
   * Information from the CRM added in the quote
   */
  crmInformation?: CRMInformation;
  /**
   * Information from the customer participant in the quote
   */
  customerInformation: OrganizationDetails;
  /**
   * Reference of the customer commitment to an Enterprise Agreement (EA) with Microsoft
   */
  enrollmentNumber?: string | null;
  /**
   * Information from the partner participant in the quote
   */
  partnerInformation?: OrganizationDetails;
}

const styles = (theme: ThemeProps) => {
  const organizationInfoContainer = { width: 230 };
  return {
    crmId: (props: SummaryDetailsPaneParticipantsProps) =>
      props.crmInformation && props.crmInformation.id ? undefined : { fontStyle: 'italic' },
    label: { display: 'block', marginBottom: 4, color: theme.palette.textTertiary },
    link: { display: 'block', marginTop: 4 },
    organizationInfoContainer,
    participantsContainer: { display: 'flex', marginTop: 16 },
    partnerContainer: { ...organizationInfoContainer, marginRight: 32 },
    salesInformationContainer: { '& > *': { marginTop: 24 } },
    title: { fontSize: theme.fonts.fontSizes.large, fontWeight: theme.fonts.fontWeights.semiBold },
  };
};

type Props = SummaryDetailsPaneParticipantsProps & WithStyles<typeof styles>;

const SummaryDetailsPaneParticipantsUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  let title;
  let customerTitle;
  let unknownCustomerLabel;

  // Custom strings
  if (props.partnerInformation) {
    title = t('quote::Participant details');
    customerTitle = t('quote::End customer');
    unknownCustomerLabel = t('quote::Unknown customer');
  } else {
    title = t('quote::Customer details');
    customerTitle = t('quote::Customer');
  }

  // Sales information
  let crmInformation;
  let cwaLink;
  if (props.crmInformation) {
    const { tpid, type, id } = props.crmInformation;

    let crmTypeLabel;
    switch (type) {
      case CrmType.Opportunity:
        crmTypeLabel = t('quote::Opportunity');
        break;
      case CrmType.SuccessEngagement:
        crmTypeLabel = t('quote::Success Engagement');
        break;
    }

    crmInformation = (
      <DetailDisplay
        label={crmTypeLabel ? `CRM ID (${crmTypeLabel})` : 'CRM ID'}
        styles={{ textComponent: classes.crmId }}
        textDetail={id || t('quote::none')}
      />
    );

    cwaLink = tpid ? <CwaLink className={classes.link} fontSize="small" tpid={tpid} /> : null;
  }

  const enrollmentInformation = props.enrollmentNumber && (
    <DetailDisplay
      label={t('quote::Expiring enrollment number')}
      textDetail={props.enrollmentNumber}
    />
  );

  let creditInformation;
  if (props.creditInformation) {
    const { creditLine: amount, currency, status } = props.creditInformation;

    let price;
    let text;
    let predefinedStyle: TextPredefinedStyle | undefined;

    switch (status) {
      case CreditLineReason.CreditIncrease:
      case CreditLineReason.CreditLine:
        price = <Price amount={amount} currency={currency} />;
        break;
      case CreditLineReason.SafeList:
        text = t('quote::Safe list');
        break;
      case CreditLineReason.PendingReview:
        text = t('quote::Pending');
        break;
      case CreditLineReason.UnderReview:
        text = t('quote::Under review');
        break;
      case CreditLineReason.ReviewCancelled:
        text = t('quote::Review cancelled');
        break;
      case CreditLineReason.Rejected:
        text = t('quote::Rejected');
        break;
      default:
        text = t('quote::Unknown status');
        break;
    }

    switch (status) {
      case CreditLineReason.PendingReview:
      case CreditLineReason.UnderReview:
      case CreditLineReason.ReviewCancelled:
      case CreditLineReason.Rejected:
        predefinedStyle = 'danger';
        break;
      case CreditLineReason.SafeList:
        predefinedStyle = 'approve';
        break;
    }

    creditInformation = (
      <DetailDisplay label={t('quote::Credit line')} styles={{ predefinedStyle }} textDetail={text}>
        {price}
      </DetailDisplay>
    );
  }

  // Links
  const searchCustomerQuotesLink = props.customerInformation.organizationId ? (
    <NavLinkInternal
      className={classes.link}
      dataAutomationId="search-customer-quotes"
      fontSize="small"
      to={routes.home.quotes.mySearch(props.customerInformation.organizationId)}
    >
      {t('quote::view quotes for this billing account')}
    </NavLinkInternal>
  ) : null;

  const searchPartnerQuotesLink =
    props.partnerInformation && props.partnerInformation.organizationId ? (
      <NavLinkInternal
        className={classes.link}
        dataAutomationId="search-partner-quotes"
        fontSize="small"
        to={routes.home.quotes.mySearch(props.partnerInformation.organizationId)}
      >
        {t('quote::view quotes for this billing account')}
      </NavLinkInternal>
    ) : null;

  const customerLinks = (
    <>
      {cwaLink}
      {searchCustomerQuotesLink}
    </>
  );

  // Participants information
  const customer = (
    <div className={classes.organizationInfoContainer}>
      <TextBodySmall addClass={classes.label}>{customerTitle}</TextBodySmall>
      <OrganizationTile
        {...props.customerInformation}
        emphasized
        links={customerLinks}
        unknownLabel={unknownCustomerLabel}
      />
    </div>
  );

  const partner = props.partnerInformation ? (
    <div className={classes.partnerContainer}>
      <TextBodySmall addClass={classes.label}>{t('quote::Partner')}</TextBodySmall>
      <OrganizationTile
        {...props.partnerInformation}
        emphasized
        links={searchPartnerQuotesLink}
        unknownLabel={t('quote::Unknown partner')}
      />
    </div>
  ) : null;

  return (
    <section>
      <TextSectionHeading addClass={classes.title}>{title}</TextSectionHeading>
      <div className={classes.participantsContainer}>
        {partner}
        {customer}
      </div>
      <div className={classes.salesInformationContainer}>
        {crmInformation}
        {enrollmentInformation}
        {creditInformation}
      </div>
    </section>
  );
};

export const SummaryDetailsPaneParticipants = withStyles(styles)(
  SummaryDetailsPaneParticipantsUnstyled
);
