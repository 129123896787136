import { TextboxStandard } from 'components/ions';
import { useDebounce } from 'components/utilities/debounce';
import * as appSelectors from 'features/app/selectors';
import { CreditConstant, CreditLine } from 'features-apollo/components/dialogs/CreditDialog';
import { sharedStyles } from 'features/proposal/shared.styles';
import { flexSectionCreator, formatCurrency, pageSectionCreator } from 'features/proposal/utils';
import { KeyCodes } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { SoldToSimple, CrmLead, UserGroup } from 'generated/graphql';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import { oc } from 'ts-optchain';
import { salesStyles } from './Sales.styles';
import { UPDATE_ANNUAL_DEAL_ESTIMATE, GET_QUOTE_SALES_INFO } from './queries';
import { useMutation } from '@apollo/react-hooks';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles,
  ...salesStyles(theme),
});

export interface SalesFooterProps {
  soldTo?: SoldToSimple;
  crmLead?: CrmLead;
  quoteId: string;
  isQuoteReadOnly: boolean;
  billingCurrency: string;
  etag: string;
  assignedTo?: UserGroup;
}

interface SalesFooterState {
  annualDealEstimate?: string;
  minCreditLineDivisor?: string;
  annualDealEstimateErrorMessage?: string;
}

const localActions = {
  updateAnnualDealEstimate: createStandardAction('ANNUALDEALESTIMATE_UPDATE')<string>(),
  updateAnnualDealEstimateError: createStandardAction('ANNUALDEALESTIMATE_ERROR')<
    string | undefined
  >(),
};

const reducer = (
  state: SalesFooterState,
  action: ActionType<typeof localActions>
): SalesFooterState => {
  const newState = { ...state };

  switch (action.type) {
    case getType(localActions.updateAnnualDealEstimate):
      newState.annualDealEstimate = action.payload;
      return newState;

    case getType(localActions.updateAnnualDealEstimateError):
      newState.annualDealEstimateErrorMessage = action.payload;
      return newState;

    default:
      return state;
  }
};

const getInitialState = (params: {
  annualDealEstimate?: string;
  minCreditLineDivisor?: string;
}) => {
  return {
    annualDealEstimate: params.annualDealEstimate,
    annualDealEstimateErrorMessage: '',
    minCreditLineDivisor: params.minCreditLineDivisor,
  };
};

const mapStateToProps = (state: RootState) => ({
  minCreditLineDivisor: appSelectors.getMinCreditLineDivisor(state),
  wholeNumberRegex: appSelectors.getWholeNumberRegex(state),
});

type Props = SalesFooterProps & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

const SalesFooterUnstyled = (props: Props) => {
  const {
    classes,
    billingCurrency,
    isQuoteReadOnly,
    quoteId,
    etag,
    assignedTo,
    crmLead,
    soldTo,
  } = props;
  const annualDealEstimate$ = useDebounce(400);
  const soldToAnnualDealEstimate = oc(soldTo).dealEstimate();
  const annualDealEstimate = soldToAnnualDealEstimate ? soldToAnnualDealEstimate.toString() : '';
  const soldToMinCreditLineDivisor = oc(soldTo).monthlyCreditLimit();
  const minCreditLineDivisor = soldToMinCreditLineDivisor
    ? soldToMinCreditLineDivisor.toString()
    : '';
  const { t } = useTranslation();
  const [annualDealEstimateFocused, setAnnualDealEstimateFocused] = React.useState(false);
  const isQuotePublished = assignedTo === UserGroup.Customer;
  const [updateAnnualDealEstimateGQL] = useMutation(UPDATE_ANNUAL_DEAL_ESTIMATE, {
    refetchQueries: () => [
      { query: GET_QUOTE_SALES_INFO, variables: { id: quoteId } },
      { query: GET_QUOTE, variables: { id: quoteId } },
    ],
  });
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      annualDealEstimate: annualDealEstimate ? annualDealEstimate : undefined,
      minCreditLineDivisor: minCreditLineDivisor ? minCreditLineDivisor : undefined,
    },
    getInitialState
  );

  React.useEffect(() => {
    dispatch(localActions.updateAnnualDealEstimate(annualDealEstimate));
  }, [annualDealEstimate]);

  const annualDealEstimateValue = annualDealEstimateFocused
    ? state.annualDealEstimate
    : !state.annualDealEstimateErrorMessage && state.annualDealEstimate
    ? formatCurrency(state.annualDealEstimate, 0) || ''
    : state.annualDealEstimate;

  const onAnnualDealEstimateChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value && /\s/.test(value)) return;

    dispatch(localActions.updateAnnualDealEstimate(value || ''));
    annualDealEstimate$.next(() => {
      if (value) {
        if (isNaN(+value)) {
          // If not a number dispatch error message.
          dispatch(localActions.updateAnnualDealEstimateError(t('error::Only numbers are valid.')));
        } else if (+value < 0) {
          // If negative number dispatch error message.
          dispatch(
            localActions.updateAnnualDealEstimateError(
              t('error::Must be greater than or equal to 0.')
            )
          );
        } else {
          // If amount is valid remove error if any.
          dispatch(localActions.updateAnnualDealEstimateError(''));
        }
      } else {
        // If amount is valid remove error if any.
        dispatch(localActions.updateAnnualDealEstimateError(''));
      }
    });
  };

  const onAnnualDealEstimateBlur = () => {
    setAnnualDealEstimateFocused(false);
    if (!state.annualDealEstimateErrorMessage && annualDealEstimate !== state.annualDealEstimate) {
      const stateAnnualDealEstimate = state.annualDealEstimate;
      if (!stateAnnualDealEstimate) {
        updateAnnualDealEstimateGQL({
          variables: {
            input: {
              quoteIdentifier: {
                id: quoteId,
                etag: etag,
              },
              annualDealEstimate: 0,
              minimumCreditLine: undefined,
            },
          },
        });
      } else {
        const annualDealEstimate = Number(state.annualDealEstimate);
        const minimumCreditLine = Math.ceil(annualDealEstimate / props.minCreditLineDivisor);
        updateAnnualDealEstimateGQL({
          variables: {
            input: {
              quoteIdentifier: {
                id: quoteId,
                etag: etag,
              },
              annualDealEstimate: annualDealEstimate,
              minimumCreditLine: minimumCreditLine,
            },
          },
        });
      }
    }
  };

  const onAnnualDealEstimateFocus = () => {
    setAnnualDealEstimateFocused(true);
  };

  const setActualAnnualDealEstimate = (value: string) => {
    dispatch(localActions.updateAnnualDealEstimate(value));
  };

  const handleEstimateEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter) {
      setAnnualDealEstimateFocused(false);
      if (
        !state.annualDealEstimateErrorMessage &&
        annualDealEstimate !== state.annualDealEstimate
      ) {
        const stateAnnualDealEstimate = state.annualDealEstimate;
        if (!stateAnnualDealEstimate) {
          updateAnnualDealEstimateGQL({
            variables: {
              input: {
                quoteIdentifier: {
                  id: quoteId,
                  etag: etag,
                },
                annualDealEstimate: 0,
                minimumCreditLine: undefined,
              },
            },
          });
        } else {
          const annualDealEstimate = Number(state.annualDealEstimate);
          const minimumCreditLine = Math.ceil(annualDealEstimate / props.minCreditLineDivisor);
          updateAnnualDealEstimateGQL({
            variables: {
              input: {
                quoteIdentifier: {
                  id: quoteId,
                  etag: etag,
                },
                annualDealEstimate: annualDealEstimate,
                minimumCreditLine: minimumCreditLine,
              },
            },
          });
        }
      }
    }
  };

  const annualDealEstimateElement: JSX.Element = flexSectionCreator(
    classes.flexLarge,
    classes.flexXLarge,
    <TextboxStandard
      addClass={`${classes.field} ${classes.paddingBottom8px}`}
      dataAutomationId="salesAnnualDealEstimate"
      disabled={isQuoteReadOnly}
      errorMessage={state.annualDealEstimateErrorMessage}
      id="annualDealEstimateTextbox"
      label={t('quote::Annual deal estimate ({{currency}})', { currency: billingCurrency })}
      maxLength={CreditConstant.maxDealEstimateLength}
      placeholder={t('quote::Estimated value of deal')}
      required={!isQuoteReadOnly}
      value={annualDealEstimateValue}
      onBlur={onAnnualDealEstimateBlur}
      onChange={onAnnualDealEstimateChange}
      onFocus={!isQuoteReadOnly ? onAnnualDealEstimateFocus : undefined}
      onKeyDown={handleEstimateEnter}
    />,
    classes.flexContainer
  );

  const customerFooter = pageSectionCreator(
    [
      annualDealEstimateElement,
      <CreditLine
        annualDealEstimate={annualDealEstimate}
        billingCurrency={billingCurrency}
        crmLead={crmLead}
        disableLink={isQuotePublished}
        etag={etag}
        key="creditLine"
        quoteId={quoteId}
        setCustomerFooterAnnualDealEstimate={setActualAnnualDealEstimate}
        soldTo={soldTo}
      />,
    ],
    classes.paddingBottom24px
  );

  return <div>{customerFooter}</div>;
};

const SalesFooterStyled = withStyles(styles)(SalesFooterUnstyled);
export const SalesFooter = connect(mapStateToProps)(SalesFooterStyled);
