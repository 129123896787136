import { sharedStyles, ThemeProps } from 'styles';

export const addressDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  addressContainerStyles: {
    paddingBottom: '18px',
  },
  addressWidthStyles: {
    width: '375px',
  },
  addressPaddingStyles: {
    paddingTop: '10px',
  },
  addLinePadding: {
    paddingTop: '35px',
    paddingLeft: '15px',
  },
  addressContainerInline: {
    display: 'flex',
  },
});
