import { mergeClassNames, SectionSeparator, SelectionSection, TextBody } from 'components';
import { SelectableTile } from 'features/components';
import { OrganizationSimple } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { sharedStyles, ThemeProps } from 'styles';

import { getSelectableOrganizations, WizardDialog } from '../Shared';

interface AccountOrganizationsDialogProps {
  accountName?: string | null;
  disableCreateOrganizationButton?: boolean;
  organizations: OrganizationSimple[];
  createOrganization: () => void;
  onClose: () => void;
  useExistingOrganization: (organizationId: string) => void;
}

const styles = (theme: ThemeProps) => ({
  button: {
    width: 196,
  },
  createButton: {
    marginTop: 32,
    marginBottom: 32,
  },
  description: {
    color: theme.palette.textTertiary,
  },
  noAccount: {
    display: 'flex',
    marginLeft: 'auto',
  },
  textOverflow: sharedStyles(theme).textOverflow,
  selectButton: {
    marginTop: 23,
  },
  selectOrganizationSection: {
    alignItems: 'start',
    marginTop: 24,
  },
});

type Props = AccountOrganizationsDialogProps & WithStyles<typeof styles>;

const AccountOrganizationsDialogUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { classes } = props;
  const [selectedOrganizationId, setSelectedOrganizationId] = React.useState<string | undefined>();

  const description = (
    <>
      <TextBody addClass={classes.description}>
        {t(
          `quote::The tenant associated with this domain is in use by the following modern account. If one of the account's billing accounts is your customer, select it and use it. If not, continue creating the new billing account which will be added to this account.`
        )}
      </TextBody>
    </>
  );

  const demoTenantMessage = (
    <>
      <TextBody addClass={classes.description}>
        {t(
          'quote::The tenant associated with this domain is a demo tenant in use by the following modern account. Please select a billing account below. Demo tenants do not support adding new billing accounts.'
        )}
      </TextBody>
    </>
  );

  const createOrganization = (
    <SelectionSection
      addButtonClass={mergeClassNames([
        classes.button,
        classes.createButton,
        !props.accountName ? classes.noAccount : undefined,
      ])}
      buttonText={t('quote::Create new billing account')}
      dataAutomationId="createNewBillingAccount"
      disabled={props.disableCreateOrganizationButton}
      title={t('quote::Account')}
      onClick={props.createOrganization}
    >
      {props.accountName && (
        <TextBody
          addClass={classes.textOverflow}
          style={{ maxWidth: 340 }}
          title={props.accountName}
        >
          {props.accountName}
        </TextBody>
      )}
    </SelectionSection>
  );

  const selectableOrganizations =
    !!props.organizations.length && getSelectableOrganizations(props.organizations);

  const onSelectOrganization = (organizationId: string) =>
    setSelectedOrganizationId(organizationId);

  const onUseExistingOrganization = () =>
    selectedOrganizationId && props.useExistingOrganization(selectedOrganizationId);

  const selectOrganization = (
    <SelectionSection
      addButtonClass={`${classes.button} ${classes.selectButton}`}
      addClass={classes.selectOrganizationSection}
      buttonText={t('quote::Use existing billing account')}
      dataAutomationId="useExistingBillingAccount"
      disabled={!selectedOrganizationId}
      title={t('quote::Existing billing account')}
      onClick={onUseExistingOrganization}
    >
      {selectableOrganizations && (
        <SelectableTile
          dataAutomationId="selectOrganizationTile"
          organizationsInfo={selectableOrganizations}
          onSelect={onSelectOrganization}
        />
      )}
    </SelectionSection>
  );

  const body = (
    <>
      {props.disableCreateOrganizationButton ? demoTenantMessage : description}
      {createOrganization}
      <SectionSeparator />
      {selectOrganization}
    </>
  );

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      headline={t('quote::Use existing billing account')}
      isOverviewAvailable={false}
      overviewButtonOnly={false}
      title={t('quote::Modern account found')}
    />
  );
};

export const AccountOrganizationsDialog = withStyles(styles)(AccountOrganizationsDialogUnstyled);
