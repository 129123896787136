//@ts-nocheck
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { mockAvailability } from 'features-apollo/quote/components/ConfigCards/Cards/mocks';
import { QUOTE_ID, quoteApproval, quoteData, requiredApproval } from 'features-apollo/quote/mocks';
import {
  AgreementType,
  ApprovalActionType,
  ApprovalLevelState,
  ApprovalStatus,
  CatalogAction,
  CrmType,
  CrmValidity,
  Currency,
  DiscountType,
  GqlLanguage,
  Market,
  MessageType,
  NationalCloud,
  OrganizationSimple,
  OrganizationType,
  PriceAdjustmentType,
  Product,
  ProductAudience,
  QuoteStatus,
  StartCondition,
  TransactionModel,
  UserGroup,
} from 'generated/graphql';
import { mockStateGql, mockStore } from 'mocksgql';
import * as React from 'react';
import { getMockedActiveQuoteContextResponse, MockedProviders } from 'testUtils';

import { MockedResponse } from '@apollo/react-testing';

import { mockedCatalogContext } from '../mocks';
import { DetailsPane } from './DetailsContainer';

const mockedProduct: Product = {
  id: 'mockedId',
  description: 'This is a product description. Sometimes it can be long sometimes its short.',
  catalogContext: mockedCatalogContext,
  skus: [],
  filters: [],
  productType: 'mockedProductType',
  title: 'mockedTitle',
  images: {
    __typename: 'Images',
    medium: null,
    logo: null,
    small: null,
  },
  relatedProducts: [],
  recommendationGroups: [],
  filteredSkus: [],
  productFamily: 'Azure',
  __typename: 'Product',
  publisherName: 'Microsoft',
  publisherUrl: 'http://www.testtest.com',
  plainTextDescription:
    'This is a test description of a product. Plain text has all html tags cleaned out.',
  startCondition: StartCondition.OnOrderAcceptance,
};

export const mockedOrganizationSimple: OrganizationSimple = {
  account: { tenants: [], assets: [], externalIds: ['551'] },
  assets: [],
  version: 1,
  type: OrganizationType.Organization,
  name: 'TestingTesting 123',
  accountId: 'poiuytre',
  id: 'poiuytr',
  credit: {
    decision: 'approved',
    reason: null,
    currency: Currency.Usd,
    creditLine: '1000.00',
    remainingBalance: '1000.00',
    creditRiskScore: '100.00',
    __typename: 'Credit',
  },
  address: {
    companyName: 'TestName',
    addressLine1: '1 Sesame Street',
    addressLine2: null,
    addressLine3: null,
    phoneNumber: null,
    email: null,
    city: 'Sesame',
    region: 'PA',
    postalCode: '10010',
    country: 'US',
    __typename: 'Address',
  },
  billingGroups: {},
  tradeName: null,
  __typename: 'OrganizationSimple',
};

export const mockedDetailsResponse = {
  __typename: 'Quote',
  id: '123abcdefg',
  totalPrice: 1000.0,
  status: QuoteStatus.Active,
  expirationDate: '2020-08-22',
  billingCurrency: 'USD',
  assignedTo: UserGroup.Field,
  languages: GqlLanguage.EnUs,
  market: 'US',
  name: 'jyujfyum',
  etag: '"1111abc-0000-0800-0000-1aa1111111111"',
  readOnly: false,
  endCustomer: null,
  revision: 1,
  leadOrganization: null,
  transactionModel: TransactionModel.ToCustomer,
  messages: [
    {
      __typename: 'Message',
      messageType: MessageType.Standard,
      messageDisplay: {
        __typename: 'TranslatedString',
        translation: 'this is a test of messages',
        display: 'this is a test 2',
        language: GqlLanguage.EnUs,
        key: '182318173',
      },
    },
  ],
  catalogContext: {
    audience: ProductAudience.DirectCommercial,
    __typename: 'CatalogContext',
    agreementType: AgreementType.Modern,
    nationalCloud: NationalCloud.Global,
    market: Market.Us,
    action: CatalogAction.Purchase,
    languages: GqlLanguage.EnUs,
  },
  crmLead: {
    __typename: 'CrmLead',
    id: '123456789',
    entityId: 'mockedEntityId',
    entityName: 'mockedEntityName',
    validity: CrmValidity.Valid,
    salesAccount: {
      isStrategic: false,
      address: {
        companyName: 'TestingTesting 123',
        country: 'US',
        __typename: 'Address',
      },
      id: 'qwertyuio',
      tpid: null,
      __typename: 'SalesAccount',
    },
    type: CrmType.Opportunity,
  },
  msContact: {
    mail: 'test@testtest.com',
    __typename: 'User',
    companyName: null,
    givenName: null,
    surname: null,
    photo: null,
    userPrincipalName: null,
    displayName: 'Test Person',
  },
  soldTo: {
    quoteId: '123abcdefg',
    dealEstimate: 1000,
    organization: mockedOrganizationSimple,
    monthlyCreditLimit: 250,
    __typename: 'SoldToSimple',
  },
  approval: {
    id: '111',
    status: ApprovalStatus.Submitted,
    history: [
      {
        __typename: 'ApprovalHistory',
        action: ApprovalActionType.Submit,
        actor: {
          mail: 'test@testtest.com',
          __typename: 'User',
          companyName: null,
          givenName: null,
          surname: null,
          userPrincipalName: null,
          displayName: 'Test Person',
          photo: null,
        },
        comments: null,
        createdBy: {
          mail: 'QuoteProd',
          __typename: 'User',
          companyName: null,
          givenName: null,
          surname: null,
          userPrincipalName: null,
          displayName: 'Test Person',
          photo: null,
        },
        createdDate: '2020/06/22',
        status: ApprovalStatus.Submitted,
      },
      {
        action: ApprovalActionType.Comment,
        actor: {
          mail: 'test@testtest.com',
          __typename: 'User',
          companyName: null,
          givenName: null,
          surname: null,
          userPrincipalName: null,
          displayName: 'Test Person',
          photo: null,
        },
        comments: 'test',
        createdBy: {
          mail: 'test@testtest.com',
          __typename: 'User',
          companyName: null,
          givenName: null,
          surname: null,
          userPrincipalName: null,
          displayName: 'Test Person',
          photo: null,
        },
        createdDate: '2020/06/22',
        status: ApprovalStatus.Submitted,
        __typename: 'ApprovalHistory',
      },
      {
        action: ApprovalActionType.Approve,
        actor: {
          mail: 'approveguy@testtest.com',
          __typename: 'User',
          companyName: null,
          givenName: null,
          surname: null,
          userPrincipalName: null,
          displayName: 'Test Person',
          photo: null,
        },
        comments: 'The discount could be higher but I still approve',
        createdBy: {
          mail: 'approveguy@testtest.com',
          __typename: 'User',
          companyName: null,
          givenName: null,
          surname: null,
          userPrincipalName: null,
          displayName: 'Test Person',
          photo: null,
        },
        createdDate: '2020/06/22',
        status: ApprovalStatus.Approved,
        __typename: 'ApprovalHistory',
      },
    ],
    requiredApprovals: [
      {
        empoweredUsers: [],
        order: 1,
        level: 'BD-LE',
        policy: 'FieldSales',
        state: ApprovalLevelState.Approved,
        assignedUser: 'approveguy@testtest.com',
        assignedUsers: ['approveguy@testtest.com'],
        __typename: 'RequiredApproval',
      },
    ],
    __typename: 'Approval',
  },
  lineItems: [
    {
      __typename: 'SimpleTermLineItem',
      id: '1234',
      type: 'annoying',
      isReadyForPricing: false,
      isConfigurable: true,
      catalogContext: mockedCatalogContext,
      title: 'Abc123 Item',
      subtitle: 'subtitle',
      sku: null,
      availability: null,
      product: {
        __typename: 'Product',
        ...mockedProduct,
        id: 'term0101',
        title: 'mockedTerm',
      },
      duration: {
        __typename: 'Duration',
        duration: null,
      },
      purchaseTermUnits: 5,
      supplementalTermReferenceData: null,
      configurationSummary: {
        display: 'United Kingdom',
      },
      term: {
        __typename: 'Term',
        termId: 'term321',
        productId: 'atdc',
        skuId: 'what',
        units: 'usd',
        description: 'help',
        availabilityId: 'pppp',
        catalogContext: {
          audience: ProductAudience.DirectCommercial,
          __typename: 'CatalogContext',
          agreementType: AgreementType.Modern,
          nationalCloud: NationalCloud.Global,
          market: Market.Us,
          action: CatalogAction.Purchase,
          languages: GqlLanguage.EnUs,
        },
      },
    },
    {
      __typename: 'FinancingTermLineItem',
      id: '2211',
      title: 'Abc123 Item',
      subtitle: 'subtitle',
      type: 'super annoying',
      isReadyForPricing: false,
      sku: null,
      catalogContext: mockedCatalogContext,
      availability: mockAvailability.generic,
      product: {
        __typename: 'Product',
        ...mockedProduct,
        id: 'term0102',
        title: 'mockedTerm2',
        productType: 'Financing',
      },
      supplementalTermReferenceData: null,
      term: {
        __typename: 'Term',
        termId: 'term123',
      },
    },
    {
      __typename: 'DiscountLineItem',
      id: 'dis01',
      applicableDiscounts: {
        types: [],
        __typename: 'ApplicableDiscounts',
        datePrecision: 'day',
        minStartDate: new Date(),
      },
      catalogContext: mockedCatalogContext,
      title: 'Item Two',
      subtitle: 'subtitle',
      isReadyForPricing: false,
      sku: {
        title: 'test',
      },
      product: {
        ...mockedProduct,
        id: 'dis019',
        skus: [
          {
            productId: 'll1',
            skuId: 'pppp3',
            title: 'SkuTitle',
            description: 'Sku description',
            minQuantity: null,
            maxQuantity: null,
            __typename: 'Sku',
            availabilities: [],
            catalogContext: mockedCatalogContext,
            isPrivate: false,
          },
        ],
      },
      discount: {
        __typename: 'DiscountSimple',
        percentage: 12.0,
        type: DiscountType.Future,
        duration: {
          __typename: 'StartEndDates',
          startDate: new Date('02/01/2020'),
          endDate: new Date('07/31/2020'),
        },
        priceAdjustmentType: PriceAdjustmentType.New,
        priceGuaranteeDate: null,
      },
    },
    {
      id: '0987',
      quantity: 1,
      listPrice: 5.0,
      title: 'Item 3',
      subtitle: 'subtitle',
      sku: null,
      isReadyForPricing: false,
      applicableDiscounts: {
        types: [],
        __typename: 'ApplicableDiscounts',
        datePrecision: 'day',
        minStartDate: new Date(),
      },
      catalogContext: mockedCatalogContext,
      availability: mockAvailability.generic,
      amount: 1.0,
      oneTimeDiscount: null,
      pricingCurrency: 'USD',
      customerPrice: null,
      product: {
        __typename: 'Product',
        ...mockedProduct,
        id: 'poi',
        productType: 'testProduct',
        images: {
          __typename: 'Images',
          logo: null,
          medium: ['http://test.microsoft.com'],
          small: null,
        },
        productFamily: 'Azure',
        title: 'test product 3',
        publisherName: 'Microsoft Corporation',
        description:
          'Metallic Office 365 Backup &amp; Recovery gives you proven data protection with the simplicity of SaaS. Built on Commvault’s industry-leading technology, Metallic will make sure your data is recoverable from accidental deletion, corruption, or malicious attack.  With unlimited Azure storage and unlimited data retention included, Metallic SaaS offers comprehensive coverage to complement your existing Microsoft investment. Plus, your first month is free with full access to the product and customer support. For volume or commitment-based pricing, please complete a quote request form at',
      },
      __typename: 'PurchaseLineItem',
    },
  ],
};

const containerStyle = {
  width: 250,
  height: '100%',
  minWidth: 250,
  overflow: 'hidden',
};
export interface MockedDetailsProps {
  selectedIds?: string[];
  mockPartner?: boolean;
  mocks?: MockedResponse[];
}

export const mockedPartnerCustomerDetailsResponse = {
  ...mockedDetailsResponse,
  transactionModel: TransactionModel.ToPartnerCustomerAsset,
  soldTo: {
    ...mockedDetailsResponse.soldTo,
    organization: {
      ...mockedOrganizationSimple,
      id: '1',
      address: { ...mockedOrganizationSimple.address, companyName: 'mockedPartnerName' },
    },
  },
  endCustomer: {
    ...mockedOrganizationSimple,
    id: '2',
    address: { ...mockedOrganizationSimple.address, companyName: 'mockedCustomerName' },
  },
};

export const mockedDefaultResponse: MockedResponse = {
  request: {
    query: GET_QUOTE,
    variables: {
      id: '123abcdefg',
    },
  },
  result: {
    data: {
      getQuote: mockedDetailsResponse,
    },
  },
};

export const mockedPartnerMissingResponse = {
  ...mockedPartnerCustomerDetailsResponse,
  soldTo: { ...mockedPartnerCustomerDetailsResponse.soldTo, organization: null },
};

export const mockedCustomerMissingResponse = {
  ...mockedPartnerCustomerDetailsResponse,
  endCustomer: null,
};

export const mockedParticipantsMissingResponse = {
  ...mockedPartnerCustomerDetailsResponse,
  soldTo: { ...mockedPartnerCustomerDetailsResponse.soldTo, organization: null },
  endCustomer: null,
};

const mockedQuoteActiveContextResponse = getMockedActiveQuoteContextResponse(QUOTE_ID);

export const mockedGetQuoteQuoteApprovalResponse: MockedResponse = {
  ...mockedQuoteActiveContextResponse,
  result: {
    data: {
      getQuote: {
        ...quoteData,
        approval: {
          ...quoteApproval,
          requiredApprovals: [
            {
              ...requiredApproval,
              policy: 'Policy',
            },
          ],
        },
      },
    },
  },
};

export const MockedQuoteDetailsPane: React.FC<MockedDetailsProps> = (props: MockedDetailsProps) => {
  let mockState = mockStateGql;
  const quoteId = '123abcdefg';
  if (props.selectedIds && props.selectedIds.length) {
    mockState.proposal.views.editor.selectedItems = [...props.selectedIds];
  }

  const mockedResponse: MockedResponse = {
    request: {
      query: GET_QUOTE,
      variables: {
        id: quoteId,
      },
    },
    result: {
      data: {
        getQuote: {
          ...mockedDetailsResponse,
        },
      },
    },
  };

  const activeQuote: MockedResponse = {
    request: {
      query: GET_QUOTE,
      variables: {
        id: quoteId,
      },
    },
    result: {
      data: {
        getQuote: {
          ...mockedDetailsResponse,
        },
      },
    },
  };

  return (
    <MockedProviders
      addTypename={true}
      fragmentMatcher={true}
      mocks={props.mocks ? [activeQuote, ...props.mocks] : [mockedResponse, activeQuote]}
      quoteId={quoteId}
      store={mockStore(mockState)}
    >
      <div style={containerStyle}>
        <DetailsPane />
      </div>
    </MockedProviders>
  );
};
