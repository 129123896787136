import { ThemeProps } from 'styles';

export const finderStyles = (theme: ThemeProps) => {
  const commonPadding = {
    paddingLeft: 16,
    paddingRight: 24,
  };

  return {
    assets: {
      ...commonPadding,
      paddingTop: 16,
      paddingBottom: 16,
      fontSize: theme.fonts.fontSizes.medium,
      '& i': {
        fontSize: theme.fonts.fontSizes.large,
        paddingRight: 10,
      },
    },
    assetsButtonRoot: {
      ...commonPadding,
      height: 40,
      width: '100%',
    },
    assetsButtonLabel: {
      marginLeft: 13,
      textAlign: 'left',
    },
    assestsSeparator: {
      ...commonPadding,
      paddingBottom: 1,
    },
    list: {
      paddingBottom: 32,
    },
    linkButton: {
      border: 'none',
      fontSize: theme.fonts.fontSizes.medium,
      paddingTop: 8,
    },
    listContainer: {
      ...commonPadding,
      overflowY: 'auto',
      height: '100%',
      position: 'relative',
    },
    progressBarContainer: {
      paddingBottom: 16,
    },
    spinner: {
      display: 'flex',
      paddingTop: 8,
    },
    title: {
      ...commonPadding,
      paddingTop: 16,
      paddingBottom: 4,
    },
    noResults: {
      paddingBottom: 32,
      '& > *:not(div)': {
        display: 'block',
      },
      '& :first-child': {
        paddingBottom: 2,
      },
    },
    buttonReadonly: {
      cursor: 'default',
      '&:hover': {
        border: `1px solid ${theme.palette.backgroundStandout}`,
        boxShadow: 'none',
      },
    },
    progressBar: {
      padding: 0,
      '& .ms-ProgressIndicator-itemProgress': {
        position: 'absolute',
        padding: 0,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
    },
    productButton: {
      paddingRight: 32,
    },
    underline: {
      width: '100%',
      marginRight: 25,
    },
  };
};
