import { PivotAtomProps, PivotTab } from 'components/atoms';
import { Pivot as PivotIon } from 'components/ions';
import * as H from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { routes } from 'routes';

export interface Tab extends PivotTab {
  path: string;
  dataAutomationId?: string;
}

export interface PivotProps {
  addClass?: string;
  defaultTab: Tab;
  history: H.History;
  match: match;
  quoteId: string;
  tabs: Tab[];
  rightElement?: JSX.Element;
}

export const Pivot: React.FC<PivotProps> = props => {
  const { defaultTab, history, match, tabs } = props;

  const getSelectionFromPath = React.useCallback(() => {
    let tab = tabs.find(tab => tab.path === match.path);
    return tab ? tab.id : defaultTab.id;
  }, [defaultTab, tabs, match.path]);

  const [selected, setSelected] = React.useState<string>(getSelectionFromPath());

  React.useEffect(() => {
    setSelected(getSelectionFromPath());
  }, [getSelectionFromPath]);

  const tabSelected = (tab: string) => {
    setSelected(tab);
    history.push(`${routes.quote.forId(props.quoteId)}/${tab}`);
    return '';
  };

  const pivProps: PivotAtomProps = {
    items: props.tabs,
    onSelectItem: tabSelected,
    defaultItemId: selected,
    rightElement: props.rightElement,
  };

  return <PivotIon {...pivProps} addClass={props.addClass} />;
};
