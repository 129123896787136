import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
} from 'components/ions';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { getBusinessStoreLink } from 'services/proposal/config';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { styles } from './styles';

const mapStateToProps = (state: RootState) => {
  const proposal = selectors.getActiveProposal(state);

  return {
    businessStoreUrl: getBusinessStoreLink(state.app.appConfig.businessStore).replace(
      '{quoteId}',
      proposal.id
    ),
    proposalName: proposal.header.name,
  };
};

export type CopyLinkDialogProps = ReturnType<typeof mapStateToProps> & WithStyles<typeof styles>;

export const CopyLinkDialogUnstyled: React.FC<CopyLinkDialogProps> = props => {
  const { classes, proposalName } = props;
  const { t } = useTranslation();
  const [copyButtonText, setCopyButtonText] = React.useState<string>(t('quote::Copy'));
  const context = React.useContext(DialogContext);
  const inputRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);

  const closeDialog = () => context.closeDialog();

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      setCopyButtonText(t('quote::Copied'));
    }
  };

  const Header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="copyLinkDialog"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Copy quote link')}</TextTitle>
    </DialogHeader>
  );

  const copyText = (
    <Trans ns="quote">
      Copy a link to the quote, "
      <span className={classes.semiBoldFontWeight}>{{ proposalName }}</span>
      ".
    </Trans>
  );

  const Body = (
    <div className={classes.body}>
      <TextBody>{copyText}</TextBody>
      <div className={classes.inputContainer}>
        <input
          className={classes.input}
          readOnly
          ref={inputRef}
          title={t('quote::Link to {{proposalName}} in Business Store', {
            proposalName: props.proposalName,
          })}
          type="text"
          value={props.businessStoreUrl}
          onFocus={onFocus}
        />
        <PrimaryButton
          addClass={classes.button}
          dataAutomationId="copyLinkButton"
          text={copyButtonText}
          onClick={handleCopyClick}
        />
      </div>
    </div>
  );

  const Footer = (
    <div className={`${classes.footer} ${classes.buttonRightAlign}`}>
      <SecondaryButton
        addClass={classes.button}
        dataAutomationId="copyLinkCloseButton"
        text={t('quote::Close')}
        onClick={closeDialog}
      />
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={264}
      width={448}
    />
  );
};

export const CopyLinkDialogFeature = withStyles(styles)(CopyLinkDialogUnstyled);

export const CopyLinkDialog = connect(mapStateToProps)(CopyLinkDialogFeature);
