import { Panel } from 'components';
import { setCacheFlights, setFlightValue } from 'features/app/actions';
import { FlightsButton } from 'features/app/components/Flights/FlightsButton';
import { FlightsPanelControls } from 'features/app/components/Flights/FlightsPanelControls';
import { FlightsPanelList } from 'features/app/components/Flights/FlightsPanelList';
import { FlightsPanelUrlParams } from 'features/app/components/Flights/FlightsPanelUrlParams';
import { FlightState } from 'features/app/components/Flights/types';
import {
  buildFlightsQueryString,
  hasInitialFlights,
  reloadPageWithoutUrlFlights,
} from 'features/app/components/Flights/utils';
import { getAllFlights, getAppEnvironment } from 'features/app/selectors';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { connect, ConnectedProps } from 'react-redux';
import { Flight, flightList } from 'services/flights/flightList';
import { isFlightAvailableInEnvironment } from 'services/flights/utils';
import { clearFlightsFromStorage } from 'store/middleware/localStorageMiddleware';
import { RootState } from 'store/types';
import { isDevBuild } from 'store/utils';
import { ThemeProps } from 'styles';

//Flights here would not show up in the panel
const flightsToExclude: Flight[] = [];

export const styles = (theme: ThemeProps) => ({
  main: {
    backgroundColor: theme.palette.backgroundStandout,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  flightsPanelControls: {
    padding: '16px 0',
  },
  overlay: {
    background: theme.palette.backgroundStandoutOverlay,
  },
  panelContent: {
    height: '100%',
    paddingRight: 3,
  },
  scrollableContent: {
    height: '100%',
    overflowY: 'hidden',
  },
  urlParams: {
    flexGrow: 1,
  },
});

export const mapState = (state: RootState) => {
  return {
    appEnv: getAppEnvironment(state),
    isCachedFlightsEnabled: state.app.isCachedFlightsEnabled,
    flights: getAllFlights(state),
  };
};

export const mapDispatch = {
  setFlight: setFlightValue,
  toggleCacheFlights: setCacheFlights,
};

const connector = connect(mapState, mapDispatch);

type Props = {
  primaryColor?: boolean;
  isDemoMode?: boolean;
} & WithStyles<typeof styles> &
  ConnectedProps<typeof connector>;

export const FlightPanelUnstyled: React.FC<Props> = (props: Props) => {
  const {
    appEnv,
    isCachedFlightsEnabled,
    classes,
    flights,
    primaryColor,
    setFlight,
    toggleCacheFlights,
    isDemoMode,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const availableFlights = flightList.filter(
    flight => !flightsToExclude.includes(flight) && isFlightAvailableInEnvironment(flight, appEnv)
  );

  const queryString = buildFlightsQueryString(flights);

  const panelStyles = {
    main: classes.main,
    scrollableContent: classes.scrollableContent,
    content: classes.panelContent,
    overlay: classes.overlay,
  };

  return (
    <React.Fragment>
      <FlightsButton
        flights={flights}
        isDemoMode={isDemoMode}
        isOpen={isOpen}
        usePrimaryColor={primaryColor}
        onClick={() => setIsOpen(true)}
      />
      <Panel
        headerText="Flights"
        isLightDismiss
        isOpen={isOpen}
        styles={panelStyles}
        onDismiss={() => setIsOpen(false)}
      >
        <div className={classes.container}>
          <FlightsPanelList
            currentFlights={flights}
            flights={availableFlights}
            setFlight={(flight: FlightState) => setFlight(flight)}
          />
          <FlightsPanelUrlParams className={classes.urlParams} queryString={queryString} />
          {isDevBuild && (
            <FlightsPanelControls
              className={classes.flightsPanelControls}
              isCachedFlightsEnabled={isCachedFlightsEnabled}
              showResetButton={!hasInitialFlights(flights)}
              onCacheFlightsToggle={(enabled: boolean) => toggleCacheFlights(enabled)}
              onResetFlightsClick={() => {
                clearFlightsFromStorage();
                reloadPageWithoutUrlFlights();
              }}
            />
          )}
        </div>
      </Panel>
    </React.Fragment>
  );
};

const FlightsPanelWithStyles = withStyles(styles)(FlightPanelUnstyled);

export const FlightsPanel = connector(FlightsPanelWithStyles);
