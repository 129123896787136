import { ThemeProps } from 'styles';

export const pivotAtomStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    animation: {
      height: 3,
      position: 'relative',
      backgroundColor: palette.secondary,
    },
    container: {
      display: 'block',
    },
    itemsContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '6px',
    },
    rightSeparator: {
      marginBottom: '-3px',
      '& div:last-of-type': {
        borderRight: `1px solid ${palette.backgroundDivider}`,
      },
    },
  };
};
