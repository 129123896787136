import { ThemeProps, sharedStyles } from 'styles';

export const enrollmentSetStartDateDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  textPadding: {
    paddingTop: '8px',
    paddingBottom: '15px',
    display: 'block',
  },
  actionTextPadding: {
    paddingTop: '5px',
    paddingBottom: '5px',
    display: 'block',
  },
});
