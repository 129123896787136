import { organizationSimpleBody, quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import { SalesAccountFragment } from 'features-apollo/quote/components/fragments';
import gql from 'graphql-tag';

/** Query to get data needed to populate Crm Id Dialog */
export const GetQuoteForCrmDialog = gql`
  query getQuoteForCrmDialog($quoteId: String!) {
    getQuote(id: $quoteId) {
      id
      etag
      motion
      fgoe
      crmLead {
        id
      }
      vlAgreementNumber
      lineItems {
        id
        product {
          id
          productFamily
        }
      }
    }
  }
`;

/** Query to validate Crm Id */
export const ValidateCrmId = gql`
  query validateCrmId($id: String!, $motion: Motion!, $fgoe: Boolean) {
    getCRMLead(id: $id, fgoe: $fgoe) {
      id
      type
      salesAccount {
        ...salesAccountFragment
      }
      validity(motion: $motion)
    }
  }
  ${SalesAccountFragment}
`;

/** Query to get tenantAdmins for WorkAccount and ExistingOwners dialogs */
export const GetTenantAdmins = gql`
  query getTenantAdmins($tenant: String!, $isGovernmentTenant: Boolean) {
    getTenant(tenant: $tenant, isGovernmentTenant: $isGovernmentTenant) {
      tenantId
      tenantAdmins
    }
  }
`;

/** Query to get RoleAssignments for WorkAccount and ExistingOwners dialogs */
export const GetRoleAssignments = gql`
  query getRoleAssignments($input: RoleAssignmentsInput!) {
    getRoleAssignments(input: $input) {
      roleId
      principalId
      principalTenantId
      userPrincipalName
    }
  }
`;

/**
 * Mutation to associate Crm Id to the quote
 * Will also add billing account to the quote if one is associated with the Crm Id
 */
export const AssociateCrmId = gql`
  mutation associateCrmId($input: QuoteMutationInput!, $crmId: String!) {
    addCRMLead(input: $input, crmId: $crmId) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

/** Mutation to update Billing Contact for BillingContactDialog and OrganizationWizard */
export const UpdateBillingContact = gql`
  mutation updateBillingContact($input: OrganizationContactInput!) {
    updateOrganizationContact(input: $input) {
      ...OrganizationSimpleBody
    }
  }
  ${organizationSimpleBody}
`;

/** Mutation to remove enrollment number from Quote */
export const RemoveEnrollmentNumber = gql`
  mutation updateVLAgreementNumber($input: QuoteMutationInput!) {
    updateVLAgreementNumber(input: $input, vlAgreementNumberForMigration: "") {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;
