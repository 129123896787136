import { SecondaryButton } from 'components/ions';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { WithdrawDialog } from '../WithdrawDialog';

const mapStateToProps = (state: RootState) => ({
  isProposalLoading: selectors.proposalLoading(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const SellerWithdrawButtonFeature: React.FC<Props> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const dialogProps: DialogProps = {
    providedDialog: <WithdrawDialog />,
  };

  const openDialog = () => {
    context.openDialog(dialogProps);
  };

  return (
    <SecondaryButton
      dataAutomationId="sellerWithdrawButton"
      disabled={props.isProposalLoading}
      iconName="Undo"
      id="withdraw-button"
      text={t('quote::Withdraw')}
      onClick={openDialog}
    />
  );
};

export const SellerWithdrawButton = connect(mapStateToProps)(SellerWithdrawButtonFeature);
