import { CalendarInput, CalendarInputProps } from 'components/molecules/CalendarInput';
import { ICalendarStrings } from 'office-ui-fabric-react';
import * as React from 'react';

const calendarStrings: ICalendarStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  goToToday: 'Go to today',
};

const CalendarInputFixture: React.FunctionComponent<CalendarInputProps> = (
  props: CalendarInputProps
) => {
  const onSelectDate = () => {};

  return (
    <CalendarInput
      calendarId={props.calendarId}
      calendarStrings={calendarStrings}
      defaultDate={props.defaultDate}
      inputId={props.inputId}
      label={props.label}
      value={props.value}
      onSelectDate={onSelectDate}
    />
  );
};

const _CalendarFixture: any = CalendarInputFixture;
_CalendarFixture.namespace = 'Components/Molecules/CalendarInput';
_CalendarFixture.displayName = 'CalendarInput';

const newDate = new Date();
newDate.setDate(10);

const props: CalendarInputProps = {
  calendarStrings,
  disabled: false,
  onSelectDate: () => {},
  label: 'Test',
  defaultDate: new Date(),
  calendarId: 'cID',
  inputId: 'iID',
  value: newDate,
};

export default [
  {
    component: _CalendarFixture,
    name: 'CalendarInput-Default',
    props: { ...props, id: 'CalendarInput-Default' },
  },
];
