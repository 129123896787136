import { InfoDialog, InfoDialogSlideProps } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { firstRunStyles } from './FirstRun.styles';
import { getFirstRunSlides } from './utils';

export interface FirstRunProps {
  /**
   * Callback for close buttons onClick
   */
  onClose?: () => void;
}

type Props = FirstRunProps & WithStyles<typeof firstRunStyles>;

export const FirstRunUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const slides = getFirstRunSlides().filter(slide => slide.enabled);
  const context = React.useContext(DialogContext);

  const closeDialog = () => {
    props.onClose && props.onClose();
    context.closeDialog();
  };

  const generateCarouselDots = (currentSlide: number) => (
    <div className={props.classes.carousel}>
      {slides.length > 1 &&
        slides.map((slide: InfoDialogSlideProps, index: number) => {
          const image = index === currentSlide ? 'ActivePip' : 'InactivePip';
          return (
            <img
              alt={`slide ${index + 1} of ${slides.length}`}
              className={index < slides.length - 1 ? props.classes.carouselPip : ''}
              key={`pip-${index + 1}`}
              src={`/images/FirstRun/${image}.png`}
            />
          );
        })}
    </div>
  );

  return (
    <InfoDialog
      backButtonText={t('Back')}
      closeButtonText={t('Close')}
      generateCarousel={generateCarouselDots}
      isMandatory={true}
      nextButtonText={t('Next')}
      slides={slides}
      onCloseDialog={closeDialog}
    />
  );
};

export const FirstRun = withStyles(firstRunStyles)(FirstRunUnstyled);
