import { Error } from 'features/app/types';
import {
  CreateTypeCOrganizationFootprintRequest,
  CreateTypeCOrganizationFootprintResponse,
} from 'services/account-extensions/types';
import { Account, AddTenantRequest } from 'services/account/types';
import { Address, AddressValidationResponse } from 'services/address-validation/types';
import {
  AgreementPreviewRequest,
  AgreementPreviewResponse,
  GetAgreementRequest,
  UploadDocumentsRequest,
  UploadDocumentsResponse,
} from 'services/agreement/types';
import { Contact } from 'services/crm/types';
import {
  AddTaxId,
  GetOrganizationRequest,
  LeadOrganizationSummary,
  LegalEntity,
  OrganizationSummary,
  RemoveTaxId,
} from 'services/customer/types';
import {
  OrganizationSearchResponse,
  OrganizationSearchResponseItem,
} from 'services/edge-search/types';
import { Asset, AssetsByOrganizationRequest } from 'services/edge/types';
import {
  ExternalUser,
  GetTenantUpns,
  RoleAssignedUpn,
  TenantAdminResponse,
  TenantName,
  VerifyUserRequest,
} from 'services/externaluser/types';
import { Agreement as legacyAgreement } from 'services/ldss/types';
import { CustomPrice } from 'services/pricingscope/types';
import { Project } from 'services/project/types';
import { OneAskResult } from 'services/proposal/types';
import { PageRequest } from 'services/types';
import { createAsyncAction } from 'store/utils';
import { createStandardAction } from 'typesafe-actions';

import { LegacyFootprint, ModernFootprint } from '../proposal/types';
import {
  AssociateOrganizationToCRMIDRequest,
  CollectionWithId,
  CRMLead,
  MatchingOrganizationQueryRequest,
  NullableObjectWithId,
  ObjectWithId,
  OrganizationWithAccountName,
  SalesAccount,
  SalesAccountOrganization,
  TenantProfile,
} from './types';

export const hydrateCrmLite = {
  request: createStandardAction('@@CRM/HYDRATE_LITE/LOAD_REQUEST')<string>(),
  success: createStandardAction('@@CRM/HYDRATE_LITE/LOAD_SUCCESS')<string>(),
  failure: createStandardAction('@@CRM/HYDRATE_LITE/LOAD_FAILURE')<string>(),
};

export const loadCRMLeadAsync = {
  request: createStandardAction('@@CRMLead/LOAD_REQUEST')<string>(),
  success: createStandardAction('@@CRMLead/LOAD_SUCCESS')<CRMLead>(),
  failure: createStandardAction('@@CRMLead/LOAD_FAILURE')<
    Error,
    { crmId: string; error: boolean }
  >(),
};

export const salesAccountMarketNotSupported = createStandardAction(
  '@@CRMID/salesAccount/INVALID'
)();

export const loadModernFootprintByAccountAsync = {
  request: createStandardAction('@@mcapi/account/footprint/LOAD_REQUEST')<string>(),
  success: createStandardAction('@@mcapi/account/footprint/LOAD_SUCCESS')<ModernFootprint>(),
  failure: createStandardAction('@@mcapi/account/footprint/LOAD_FAILURE')<Error>(),
};

export const loadCRMContactAsync = createAsyncAction(
  '@@crmContact/LOAD_REQUEST',
  '@@crmContact/LOAD_SUCCESS',
  '@@crmContact/LOAD_FAILURE'
)<string, Contact | null, Error>();

export const loadOrganizationAsync = {
  request: createStandardAction('@@organization/LOAD_REQUEST')<GetOrganizationRequest>(),
  success: createStandardAction('@@organization/LOAD_SUCCESS')<ObjectWithId<OrganizationSummary>>(),
  failure: createStandardAction('@@organization/LOAD_FAILURE')<Error>(),
};

export const loadLeadOrganizationAsync = {
  request: createStandardAction('@@leadOrganization/LOAD_REQUEST')<GetOrganizationRequest>(),
  success: createStandardAction('@@leadOrganization/LOAD_SUCCESS')<
    ObjectWithId<LeadOrganizationSummary>
  >(),
  failure: createStandardAction('@@leadOrganization/LOAD_FAILURE')<Error>(),
};

export const loadOrganizationsAsync = createAsyncAction(
  '@@organization/accountId/LOAD_REQUEST',
  '@@organization/accountId/LOAD_SUCCESS',
  '@@organization/accountId/LOAD_FAILURE'
)<string, CollectionWithId<OrganizationSummary>, Error>();

export const loadOrganizationsWithAddressAsync = createAsyncAction(
  '@@organizations/address/LOAD_REQUEST',
  '@@organizations/address/LOAD_SUCCESS',
  '@@organizations/address/LOAD_FAILURE'
)<string, void, Error>();

export const loadCustomPricesAsync = createAsyncAction(
  '@@customPrices/LOAD_REQUEST',
  '@@customPrices/LOAD_SUCCESS',
  '@@customPrices/LOAD_FAILURE'
)<PageRequest, CollectionWithId<CustomPrice>, Error>();

export const loadCustomerContactAsync = createAsyncAction(
  '@@externalUser/LOAD_REQUEST',
  '@@externalUser/LOAD_SUCCESS',
  '@@externalUser/LOAD_FAILURE'
)<string, ExternalUser, Error>();

export const validateWorkAccountAsync = {
  request: createStandardAction('@@externaluser/LOAD_REQUEST')<VerifyUserRequest>(),
  success: createStandardAction('@@externaluser/LOAD_SUCCESS')<ExternalUser[]>(),
  failure: createStandardAction('@@externaluser/LOAD_FAILURE')<Error>(),
};

export const saveOrganizationOnEnrollmentLookup = createStandardAction(
  '@@enrollment/organization/SAVE'
)<ObjectWithId<OrganizationSummary>>();

export const loadLegacyFootprintAsync = {
  request: createStandardAction('@@mcapi/legacyFootprint/LOAD_REQUEST')<
    ObjectWithId<string>,
    string
  >(),
  success: createStandardAction('@@mcapi/legacyFootprint/LOAD_SUCCESS')<LegacyFootprint>(),
  failure: createStandardAction('@@mcapi/legacyFootprint/LOAD_FAILURE')<Error>(),
};

export const mapAccountToProjectAsync = {
  request: createStandardAction('@@project/account/MAP_REQUEST')<Project[], string>(), // meta: enrollmentNumber
  success: createStandardAction('@@project/account/MAP_SUCCESS')<ObjectWithId<string>, string>(), //payload:<accountId,projectId>, meta:enrollmentNumber
  failure: createStandardAction('@@project/account/MAP_FAILURE')<Error>(),
};

export const searchEnrollmentNumber = createStandardAction('@@enrollment/search')<string>();

export const loadEnrollmentAsync = createAsyncAction(
  '@@ldss/agreement/LOAD_REQUEST',
  '@@ldss/agreement/LOAD_SUCCESS',
  '@@ldss/agreement/LOAD_FAILURE'
)<string, ObjectWithId<legacyAgreement>, Error>();

export interface CreateAndAssociateRequest {
  createRequest: CreateTypeCOrganizationFootprintRequest;
  quoteId: string;
  leadOrgId?: string;
  user?: ExternalUser;
}

export const createOrganizationAndAssociateAsync = {
  request: createStandardAction('@@accountExtensions/typecorg/CREATE_REQUEST')<
    CreateAndAssociateRequest
  >(),
  success: createStandardAction('@@accountExtensions/typecorg/CREATE_SUCCESS')<
    CreateTypeCOrganizationFootprintResponse
  >(), //payload:<accountId,projectId>
  failure: createStandardAction('@@accountExtensions/typecorg/CREATE_FAILURE')<Error>(),
};

export const loadProjectFromEnrollmentAsync = createAsyncAction(
  '@@project/LOAD_REQUEST',
  '@@project/LOAD_SUCCESS',
  '@@project/LOAD_FAILURE'
)<string, NullableObjectWithId<Project>, Error>();

export const searchOrganizationsAsync = createAsyncAction(
  '@@edgeSearch/organization/SEARCH_REQUEST',
  '@@edgeSearch/organization/SEARCH_SUCCESS',
  '@@edgeSearch/organization/SEARCH_FAILURE'
)<string, OrganizationSearchResponse, Error>();

export const addTenantToAccountAsync = createAsyncAction(
  '@@account/tenant/ADD_REQUEST',
  '@@account/tenant/ADD_SUCCESS',
  '@@account/tenant/ADD_FAILURE'
)<AddTenantRequest, Account, Error>();

export const loadAccountAsync = createAsyncAction(
  '@@account/LOAD_REQUEST',
  '@@account/LOAD_SUCCESS',
  '@@account/LOAD_FAILURE'
)<string, Account, Error>();

export const updateAccountDescriptionAsync = {
  request: createStandardAction('@@account/description/UPDATE_REQUEST')<string, string>(), //accountId , description
  success: createStandardAction('@@account/description/UPDATE_SUCCESS')<Account>(),
  failure: createStandardAction('@@account/description/UPDATE_FAILURE')<Error>(),
};

export const loadTenantAsync = createAsyncAction(
  '@@signup/tenant/LOAD_REQUEST',
  '@@signup/tenant/LOAD_SUCCESS',
  '@@signup/tenant/LOAD_FAILURE'
)<string, ObjectWithId<TenantProfile>, Error>();

export const verifyTenantAsync = createAsyncAction(
  '@@signup/verifyTenant/LOAD_REQUEST',
  '@@signup/verifyTenant/LOAD_SUCCESS',
  '@@signup/verifyTenant/LOAD_FAILURE'
)<string, void, Error>();

export const loadTenantNamesAsync = createAsyncAction(
  '@@externalUser/tenantName/LOAD_REQUEST',
  '@@externalUser/tenantName/LOAD_SUCCESS',
  '@@externalUser/tenantName/LOAD_FAILURE'
)<string[], TenantName[], Error>();

export const loadTenantAdminsAsync = createAsyncAction(
  '@@externalUser/tenantAdmin/LOAD_REQUEST',
  '@@externalUser/tenantAdmin/LOAD_SUCCESS',
  '@@externalUser/tenantAdmin/LOAD_FAILURE'
)<string[], TenantAdminResponse[], Error>();

export const loadTenantUpnsAsync = createAsyncAction(
  '@@externalUser/upn/LOAD_REQUEST',
  '@@externalUser/upn/LOAD_SUCCESS',
  '@@externalUser/upn/LOAD_FAILURE'
)<GetTenantUpns[], RoleAssignedUpn[], Error>();

export const searchOrganizationsForDialogAsync = createAsyncAction(
  '@@edgeSearch/organization/DIALOG_SEARCH_REQUEST',
  '@@edgeSearch/organization/DIALOG_SEARCH_SUCCESS',
  '@@edgeSearch/organization/DIALOG_SEARCH_FAILURE'
)<string, OrganizationSearchResponse, Error>();

export const searchOrganizationsForPropertySheetAsync = createAsyncAction(
  '@@edgeSearch/organization/PROPERTY_SHEET_SEARCH_REQUEST',
  '@@edgeSearch/organization/PROPERTY_SHEET_SEARCH_SUCCESS',
  '@@edgeSearch/organization/PROPERTY_SHEET_SEARCH_FAILURE'
)<string, CollectionWithId<OrganizationSearchResponseItem>, Error>();

export const searchOrganizationForSimilarOrganizationsAsync = createAsyncAction(
  '@@edgeSearch/organization/SIMILAR_ORG_SEARCH_REQUEST',
  '@@edgeSearch/organization/SIMILAR_ORG_SEARCH_SUCCESS',
  '@@edgeSearch/organization/SIMILAR_ORG_SEARCH_FAILURE'
)<MatchingOrganizationQueryRequest, OrganizationWithAccountName | null, Error>();

export const loadAgreementPreviewAsync = createAsyncAction(
  '@@agreement/preview/LOAD_REQUEST',
  '@@agreement/preview/LOAD_SUCCESS',
  '@@agreement/preview/LOAD_FAILURE'
)<AgreementPreviewRequest, AgreementPreviewResponse, Error>();

export const uploadDocumentsAsync = createAsyncAction(
  '@@agreement/UPLOAD_REQUEST',
  '@@agreement/UPLOAD_SUCCESS',
  '@@agreement/UPLOAD_FAILURE'
)<UploadDocumentsRequest, UploadDocumentsResponse, Error>();

export const uploadDocumentsStateReset = createStandardAction('@@agreement/UPLOAD_FINISHED')();

export const loadAgreementsAsync = createAsyncAction(
  '@@agreement/agreements/LOAD_REQUEST',
  '@@agreement/agreements/LOAD_SUCCESS',
  '@@agreement/agreements/LOAD_FAILURE'
)<GetAgreementRequest, ObjectWithId<boolean>, Error>();

export const updateOrganizationAsync = {
  request: createStandardAction('@@organization/UPDATE_REQUEST')<
    LegalEntity,
    { accountId: string; organizationId: string; leadOrgId?: string }
  >(),
  success: createStandardAction('@@organization/UPDATE_SUCCESS')<
    ObjectWithId<OrganizationSummary>
  >(),
  failure: createStandardAction('@@organization/UPDATE_FAILURE')<Error>(),
};

export const updateOrganizationContactInformationAsync = {
  request: createStandardAction('@@organization/UPDATE_CONTACT_INFORMATION_REQUEST')<
    { email: string; phoneNumber: string },
    { accountId: string; organizationId: string }
  >(),
  success: createStandardAction('@@organization/UPDATE_CONTACT_INFORMATION_SUCCESS')(),
  failure: createStandardAction('@@organization/UPDATE_CONTACT_INFORMATION_FAILURE')<Error>(),
};

export const loadAccountsByTenantIdAsync = createAsyncAction(
  '@@account/tenantId/LOAD_REQUEST',
  '@@account/tenantId/LOAD_SUCCESS',
  '@@account/tenantId/LOAD_FAILURE'
)<string, CollectionWithId<Account>, Error>();

export const searchTenantFootprintAsync = createAsyncAction(
  '@@mcapi/tenantFootprint/SEARCH_REQUEST',
  '@@mcapi/tenantFootprint/SEARCH_SUCCESS',
  '@@mcapi/tenantFootprint/SEARCH_FAILURE'
)<string, void, Error>();

export const searchSignupEmailAsync = createAsyncAction(
  '@@mcapi/signupEmail/SEARCH_REQUEST',
  '@@mcapi/signupEmail/SEARCH_SUCCESS',
  '@@mcapi/signupEmail/SEARCH_FAILURE'
)<string, void, Error>();

export const getAssetsByOrganizationAsync = {
  request: createStandardAction('@@assets/LOAD_REQUEST')<AssetsByOrganizationRequest>(),
  success: createStandardAction('@@assets/LOAD_SUCCESS')<CollectionWithId<Asset>>(),
  failure: createStandardAction('@@assets/LOAD_FAILURE')<Error>(),
};
export const validateAddressAsync = createAsyncAction(
  '@@addressValidation/VALIDATE_REQUEST',
  '@@addressValidation/VALIDATE_SUCCESS',
  '@@addressValidation/VALIDATE_FAILURE'
)<Address, AddressValidationResponse, Error>();

export const resetAddressValidation = createStandardAction('@@addressValidation/reset')();

// #region Sales account actions

export const loadSalesAccountAsync = {
  request: createStandardAction('@@salesaccount/LOAD_REQUEST')<string>(),
  success: createStandardAction('@@salesaccount/LOAD_SUCCESS')<SalesAccount>(),
  failure: createStandardAction('@@salesaccount/LOAD_FAILURE')<Error>(),
};

export const setIsStrategicAccount = createStandardAction('@@proposal/SET_IS_STRATEGIC_ACCOUNT')<
  boolean
>();

export const associateOrganizationToSalesAccount = createAsyncAction(
  '@@salesAccount/ASSOCIATE_BILLING_ACCOUNT_REQUEST',
  '@@salesAccount/ASSOCIATE_BILLING_ACCOUNT_SUCCESS',
  '@@salesAccount/ASSOCIATE_BILLING_ACCOUNT_FAILURE'
)<AssociateOrganizationToCRMIDRequest, void, Error>();

export const getOrganizationFromCRMID = createAsyncAction(
  '@@salesAccount/GET_ORGANIZATION_FROM_CRMID_REQUEST',
  '@@salesAccount/GET_ORGANIZATION_FROM_CRMID_SUCCESS',
  '@@salesAccount/GET_ORGANIZATION_FROM_CRMID_FAILURE'
)<string, SalesAccountOrganization | undefined, Error>();

export const getSalesAccountAssociationStatus = createAsyncAction(
  '@@salesAccount/ASSOCIATE_STATUS_REQUEST',
  '@@salesAccount/ASSOCIATE_STATUS_SUCCESS',
  '@@salesAccount/ASSOCIATE_STATUS_FAILURE'
)<string, string, Error>();

export const loadPreapprovedECIFsAsync = {
  request: createStandardAction('@@ECIFs/LOAD_REQUEST')<string>(),
  success: createStandardAction('@@ECIFs/LOAD_SUCCESS')<OneAskResult[]>(),
  failure: createStandardAction('@@ECIFs/LOAD_FAILURE')<Error>(),
};

export const addVatIdAsync = createAsyncAction(
  '@@VatId/add/LOAD_REQUEST',
  '@@VatId/add/LOAD_SUCCESS',
  '@@VatId/add/LOAD_FAILURE'
)<AddTaxId, ObjectWithId<OrganizationSummary>, Error>();

export const removeVatIdAsync = createAsyncAction(
  '@@VatId/remove/LOAD_REQUEST',
  '@@VatId/remove/LOAD_SUCCESS',
  '@@VatId/remove/LOAD_FAILURE'
)<RemoveTaxId, ObjectWithId<OrganizationSummary>, Error>();

export const requireVatId = {
  success: createStandardAction('@@VatId/isRequired/LOAD_SUCCESS')<boolean>(),
};

// #endregion
