export const firstRunStyles = {
  carousel: {
    paddingTop: 0,
    paddingBottom: 40,
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  carouselPip: {
    paddingRight: 10,
  },
};
