import { ComboBoxAtom, ComboBoxAtomProps, ProgressIndicatorAtom } from 'components/atoms';
import { IProgressIndicatorStyleProps, IProgressIndicatorStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { IStyleFunctionOrObject } from '@uifabric/utilities';

const styles = (theme: ThemeProps) => {
  return {
    container: {
      width: 294,
    },
    itemProgress: {
      borderRadius: '50%',
      padding: 0,
    },
    progressBar: {
      background: theme.palette.progressBar,
    },
    progressTrack: {
      backgroundColor: 'transparent',
    },
  };
};

export interface ComboBoxWithProgressProps extends ComboBoxAtomProps {
  containerClass?: string;
  styleErrorMessage?: string;
  progressAriaValueText?: string;
  progressDescription?: React.ReactNode;
  progressLabel?: React.ReactNode;
  progressHidden?: boolean;
}

type Props = ComboBoxWithProgressProps & WithStyles<typeof styles>;

const ComboBoxWithProgressIon: React.FC<Props> = ({ classes, ...props }) => {
  const progressIndicatorStyles: Partial<
    IStyleFunctionOrObject<IProgressIndicatorStyleProps, IProgressIndicatorStyles>
  > = {
    itemProgress: classes.itemProgress,
    progressBar: classes.progressBar,
    progressTrack: classes.progressTrack,
  };

  return (
    <div className={`${classes.container} ${props.containerClass}`}>
      <ComboBoxAtom
        allowFreeform={props.allowFreeform}
        autoComplete={props.autoComplete}
        defaultSelectedKey={props.defaultSelectedKey}
        disabled={!props.progressHidden}
        errorMessage={props.errorMessage}
        id={props.id}
        isSearch={props.isSearch}
        label={props.label}
        maxHeight={props.maxHeight}
        options={props.options}
        placeholder={props.placeholder}
        required={props.required}
        selectedKey={props.selectedKey}
        styleContainer={`${classes.container} ${props.containerClass}`}
        styleErrorMessage={props.styleErrorMessage}
        text={props.text}
        onChange={props.onChange}
      />
      <ProgressIndicatorAtom
        ariaValueText={props.progressAriaValueText}
        description={props.progressDescription}
        label={props.progressLabel}
        progressHidden={props.progressHidden}
        styles={progressIndicatorStyles}
      />
    </div>
  );
};

export const ComboBoxWithProgress = withStyles(styles)(ComboBoxWithProgressIon) as React.FC<
  ComboBoxWithProgressProps
>;
