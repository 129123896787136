import { detailsListStyles } from '../styles';

export const styles = {
  dropdownsContainer: {
    width: '100%',
    marginBottom: 24,
  },
  locationDropdown: {
    width: 216,
    display: 'inline-block',
    paddingRight: 23,
  },
  durationDropdown: {
    width: 216,
    display: 'inline-block',
  },
  detailsListContainer: {
    overflowY: 'auto',
    maxHeight: 400,
  },
  message: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  detailsListStyles,
};
