import { PrimaryButton, SecondaryButton, TextBodySmall, VerificationField } from 'components';
import * as actions from 'features/customer/actions';
import {
  getTenant as getTenantSelector,
  searchSignupEmailProcessing,
} from 'features/customer/selectors';
import { TenantProfile } from 'features/customer/types';
import { BodyProps as WizardBodyProps } from 'features/proposal/components/Wizards/shared';
import { emailIsValid } from 'features/proposal/utils';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';

import { ViralTenantMessage } from '../Partials';
import { signUpEmailStyles } from './SignUpEmail.styles';
import { TenantExists } from './TenantExists';

export const SignUpEmailHeaderString = (hasInitialData: boolean) => {
  if (hasInitialData) {
    return i18next.t('quote::Edit sign up email address');
  } else {
    return i18next.t('quote::Add sign up email address');
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignUpEmailBodyProps extends WizardBodyProps<string | undefined> {}

const extractDomainFromEmail = (email?: string) => email && email.split('@')[1];

const mapStateToProps = (state: RootState) => {
  return {
    getSignupEmailSearchResult: (email: string) => {
      const domain = extractDomainFromEmail(email);
      return domain ? getTenantSelector(state, domain) : undefined;
    },
    searchSignupEmailProcessing: searchSignupEmailProcessing(state),
  };
};

const dispatchProps = {
  onSearch: actions.searchSignupEmailAsync.request,
};

export const computeStatus = (isIdleWithoutErrors: boolean, searchResult?: TenantProfile) => {
  const searchFinishedWithResults = isIdleWithoutErrors && searchResult && searchResult.tenantId;
  if (searchFinishedWithResults && searchResult) {
    return {
      isConsumerEmail: !!searchResult.isConsumer,
      isTenantViral: !!searchResult.isViral,
    };
  } else {
    return { isConsumerEmail: false, isTenantViral: false };
  }
};

type BodyProps = SignUpEmailBodyProps &
  WithStyles<typeof signUpEmailStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const initializeLastVerified = (initialData?: string) => {
  if (initialData) {
    const domain = extractDomainFromEmail(initialData);
    if (!domain) {
      loggerService.error({
        error: new Error('We have an email without @ coming as the initial data'),
      });
    }
    return initialData;
  }
};

const SignUpEmailBodyUnstyled: React.FC<BodyProps> = props => {
  const {
    classes,
    initialData,
    getSignupEmailSearchResult,
    onInvalid,
    onValid,
    searchSignupEmailProcessing,
  } = props;
  const { t } = useTranslation();
  const msEmail = '@microsoft.com';

  const regexError = t('quote::Enter a valid email address');
  const msEmailError = t('quote::You cannot use a Microsoft email address for a customer');
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(initialData);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [lastVerified, setLastVerified] = React.useState<string | undefined>(() => {
    return initializeLastVerified(props.initialData);
  });
  const isIdleWithoutErrors =
    !props.searchSignupEmailProcessing.loading && !props.searchSignupEmailProcessing.error;

  const searchResult = lastVerified ? props.getSignupEmailSearchResult(lastVerified) : undefined;
  const lastVerifiedSameAsCurrent = lastVerified === currentValue;
  React.useEffect(() => {
    if (isIdleWithoutErrors) {
      const status = computeStatus(true, searchResult);
      const allowedEmail =
        status.isConsumerEmail ||
        !searchResult ||
        (!!searchResult && !searchResult.tenantId && !status.isTenantViral);
      if (allowedEmail && lastVerifiedSameAsCurrent) {
        onValid(lastVerified);
      } else {
        onInvalid();
      }
    } else {
      onInvalid();
    }
  }, [
    lastVerified,
    searchSignupEmailProcessing,
    onValid,
    onInvalid,
    searchResult,
    getSignupEmailSearchResult,
    isIdleWithoutErrors,
    lastVerifiedSameAsCurrent,
  ]);

  const onValidateEmail = (value?: string) =>
    !!(value && emailIsValid.test(value) && !value.endsWith(msEmail));

  const onSignUpEmailChange = (value?: string) => {
    setCurrentValue(value);
    if (!value) {
      setErrorMessage(undefined);
    } else if (value.endsWith(msEmail)) {
      setErrorMessage(msEmailError);
    } else if (!emailIsValid.test(value)) {
      setErrorMessage(regexError);
    } else {
      setErrorMessage(undefined);
    }
  };

  const onSearch = (value: string) => {
    const domain = extractDomainFromEmail(value);
    if (!domain) {
      loggerService.error({
        error: new Error('We have an email without @ that we are trying to verify'),
      });
    }
    props.onSearch(domain || '');
    setCurrentValue(value);
    setLastVerified(value);
  };

  const foundMatchingAccount = isIdleWithoutErrors && searchResult && searchResult.tenantId;
  const status = computeStatus(isIdleWithoutErrors, searchResult);
  let message = null;
  const viralTenantMessage = status.isTenantViral && lastVerifiedSameAsCurrent && (
    <div className={classes.messages}>
      <ViralTenantMessage />
    </div>
  );
  const tenantExistsMessage = foundMatchingAccount &&
    !status.isConsumerEmail &&
    lastVerifiedSameAsCurrent && (
      <div className={classes.messages}>
        <TenantExists />
      </div>
    );

  const loadingError = t('error::We encountered a problem while verifying. Please try again.');
  const isError = props.searchSignupEmailProcessing.error || !!errorMessage;
  const errorMessageToDisplay = searchSignupEmailProcessing.error ? loadingError : errorMessage;

  if (isIdleWithoutErrors && lastVerified) {
    message = viralTenantMessage || tenantExistsMessage;
  }
  const isVerified = isIdleWithoutErrors && !!lastVerified && !message && lastVerifiedSameAsCurrent;
  return (
    <div>
      <TextBodySmall addClass={classes.secondaryText}>
        {t(
          'quote::You have indicated that there is no existing tenant for this customer. Please provide the email address for the customer representative that will receive the signup instructions to create their company’s Azure account. This representative will be defined as the Commerce Admin, and will be able to create additional accounts for the rest of the members on their team.'
        )}
      </TextBodySmall>
      <VerificationField
        allowTryAgain={props.searchSignupEmailProcessing.error && !!currentValue}
        buttonText={t('Verify')}
        dataAutomationId="emailVerification"
        errorMessage={errorMessageToDisplay}
        isError={isError}
        isLoading={props.searchSignupEmailProcessing.loading}
        isVerified={isVerified}
        lastVerified={lastVerified}
        textboxLabel={t('quote::Email address')}
        textboxPlaceholder={t('quote::Administrative email address')}
        textboxRequired={true}
        textboxValue={currentValue}
        validationErrorMessage={errorMessage}
        verifiedStatusText={t('quote::Verified')}
        onChangeDebounced={onSignUpEmailChange}
        onValidate={onValidateEmail}
        onVerify={onSearch}
      />
      {message}
    </div>
  );
};

export const SignUpEmailFooterButtons = (
  isFromTenant: boolean,
  buttonDisabled: boolean,
  onButtonClick: () => void,
  onBackButtonClick?: () => void
) => {
  if (isFromTenant) {
    return [
      <SecondaryButton
        dataAutomationId="signUpEmailBackButton"
        key="back-to-tenant"
        text={i18next.t('quote::Back')}
        onClick={onBackButtonClick}
      />,
      <PrimaryButton
        dataAutomationId="signUpEmailSaveButton"
        disabled={buttonDisabled}
        key="save-email"
        text={i18next.t('quote::Save')}
        onClick={onButtonClick}
      />,
    ];
  } else {
    return [
      <PrimaryButton
        dataAutomationId="saveEmailAddressButton"
        disabled={buttonDisabled}
        key="save-email"
        text={i18next.t('quote::Save email address')}
        onClick={onButtonClick}
      />,
    ];
  }
};

export const SignUpEmailBodyUnconnected = withStyles(signUpEmailStyles)(SignUpEmailBodyUnstyled);
export const SignupEmail = connect(mapStateToProps, dispatchProps)(SignUpEmailBodyUnconnected);
