import { TextBody, TextBodySmall, TextPredefinedStyle } from 'components';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

interface DetailDisplayStyles {
  /**
   * Select style that is predefined for the TextBody components
   */
  predefinedStyle?: TextPredefinedStyle;
  /**
   * Class name pass to the addClass prop of the TextBody component
   */
  textComponent?: string;
}

export interface DetailDisplayProps {
  /**
   * Displays at the top
   */
  label: string;
  /**
   * Display detail in a text format
   */
  textDetail?: string;
  /**
   * Styles for elements and reusable components
   */
  styles?: DetailDisplayStyles;
}

const styles = (theme: ThemeProps) => ({
  container: { '&, & > *': { display: 'flex', flexDirection: 'column' } },
  details: { '& > :not(:first-child)': { marginTop: 4 } },
  label: { display: 'block', marginBottom: 4, color: theme.palette.textTertiary },
});

type Props = DetailDisplayProps & WithStyles<typeof styles>;

const DetailDisplayUnstyled: React.FC<Props> = props => {
  const { classes, styles } = props;

  const text = props.textDetail && (
    <TextBody
      addClass={styles && styles.textComponent}
      predefinedStyle={styles && styles.predefinedStyle}
    >
      {props.textDetail}
    </TextBody>
  );

  return (
    <div className={classes.container}>
      <TextBodySmall addClass={classes.label}>{props.label}</TextBodySmall>
      <div className={classes.details}>
        {text}
        {props.children}
      </div>
    </div>
  );
};

export const DetailDisplay = withStyles(styles)(DetailDisplayUnstyled);
