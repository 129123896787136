import { sharedStyles, ThemeProps } from 'styles';

export const customerAddedStyles = (theme: ThemeProps) => ({
  customerAddedSection: {
    marginBottom: 24,
  },
  divider: {
    backgroundColor: theme.palette.backgroundDivider,
    display: 'inline-block',
    height: 24,
    marginLeft: 8,
    width: 1,
  },
  header: {
    marginBottom: 16,
  },
  font: {
    fontSize: theme.fonts.fontSizes.medium,
  },
  menuButton: {
    width: 48,
  },
  paragraph: {
    marginTop: 8,
  },
  tenantTitle: {
    marginBottom: 16,
  },
  textSeparator: {
    paddingBottom: 24,
  },
  incompleteOrganizationAddressPadding: {
    paddingBottom: 12,
  },
  readOnlyVatId: {
    fontStyle: 'italic',
    color: theme.palette.textTertiary,
  },
  separator: {
    marginTop: 32,
    marginBottom: 24,
    width: 450,
  },
  table: {
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: 0,
      textAlign: 'left',
    },
    '& tr > :first-child': {
      paddingRight: 24,
    },
  },
  tenantList: {
    marginTop: 24,
  },
  text: {
    ...sharedStyles(theme).textOverflow,
    color: theme.palette.textTertiary,
    display: 'block',
    maxWidth: 450,
  },
  shimmer: {
    marginBottom: 4,
  },
  shimmerContainer: {
    paddingTop: 8,
  },
  title: {
    display: 'inline-block',
  },
  companyName: {
    ...sharedStyles(theme).textOverflow,
    maxWidth: 450,
  },
  errorTextContainer: {
    maxWidth: 450,
    marginBottom: 24,
  },
  errorTextFonts: {
    fontSize: theme.fonts.fontSizes.small,
  },
});
