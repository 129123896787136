import { ThemeProps } from 'styles';

export const PanelMainWidth = 408;
export const PanelArticleWidth = 580;

export const panelStyles = (theme: ThemeProps) => ({
  panelContentInner: {
    marginTop: 8,
  },
  panelScrollableContent: {
    paddingTop: 12,
  },
  backButtonRoot: {
    '& i, &:hover i': {
      //override ActionButton icon selector specificity
      color: theme.palette.secondary,
    },
    height: 32,
  },
  backButtonIcon: {
    fontSize: theme.fonts.fontSizes.large,
  },
  backButtonLabel: {
    color: theme.palette.secondary,
  },
  panelMainForSelectedContent: {
    background: theme.palette.backgroundStandout,
    boxShadow: `0px 30px 30px ${theme.palette.shadowColor}`,
    width: PanelArticleWidth,
  },
  openNewWindowLink: {
    height: 32,
    fontSize: theme.fonts.fontSizes.larger,
    width: 32,
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
  },
  panelMain: {
    background: theme.palette.backgroundStandout,
    boxShadow: `0px 30px 30px ${theme.palette.shadowColor}`,
    width: PanelMainWidth,
  },
  navigationContent: {
    '& button:last-child': {
      height: 32,
      width: 32,
    },
    alignItems: 'center',
    display: 'flex',
    height: 32,
    justifyContent: 'space-between',
    marginLeft: 16,
    width: '100%',
  },
  navRightButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  panelCommands: {
    marginTop: 18,
  },
  panelNavigation: {
    height: 32,
  },
  footerInner: {
    padding: 0,
  },
});
