import { ThemeProps } from 'styles';

export const linkHeaderStyles = (theme: ThemeProps) => {
  const { palette, fonts } = theme;
  return {
    headerDefaultStyle: {
      color: palette.text,
      fontSize: fonts.fontSizes.larger,
      fontWight: fonts.fontWeights.regular,
      lineHeight: '18px',
      padding: '13.82px 14px',
      '&:active': {
        backgroundColor: palette.secondary,
        color: palette.titleBarPrimaryText,
      },
      '&:active:hover': {
        backgroundColor: palette.secondary,
        color: palette.titleBarPrimaryText,
      },
      '&:focus': {
        color: palette.textSecondary,
      },
      '&:hover': {
        color: palette.textSecondary,
      },
      '& i': {
        fontSize: fonts.fontSizes.xLarge,
      },
    },
    headerPrimaryStyle: {
      color: palette.titleBarPrimaryText,
      fontSize: fonts.fontSizes.larger,
      lineHeight: '18px',
      padding: '13.82px 14px',
      '&:active': {
        backgroundColor: palette.primaryActive,
        color: palette.titleBarPrimaryText,
      },
      '&:active:hover': {
        backgroundColor: palette.primaryActive,
        color: palette.titleBarPrimaryText,
      },
      '&:focus': {
        backgroundColor: palette.primaryHover,
        color: palette.titleBarPrimaryText,
      },
      '&:hover': {
        backgroundColor: palette.primaryHover,
        color: palette.titleBarPrimaryText,
      },
      '& i': {
        fontSize: fonts.fontSizes.xLarge,
      },
    },
    root: {
      borderBottom: 'none',
      borderRadius: '0',
    },
  };
};
