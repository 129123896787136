import { CloseIconButton } from 'components/ions';
import { useKey } from 'hooks';
import { FocusTrapZone, KeyCodes } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { customDialogStyles } from './Dialog.styles';

/** Must be used with an overlay to produce popup effect
 * @prop {string} id id tag for the outer most div
 * @prop {function} dialogClose callback function to execute when close button is clicked.
 * @prop {string} headingSlot content to display in the top row of the dialog. *Note it is recommended to use a DialgoHeader
 * @prop {string} bodySlot content to display in the body of the dialog
 * @prop {string} footerSlot content to display in the bottom row *not required.
 * @prop {number} height  optional property to manually set the height of the dialog box represented in pixels
 * @prop {number} width:  optional property to manually set the width of the dialog box represented in pixels
 */
export interface DialogHeaderProps {
  id?: string;
  dialogClose: () => void;
  headerClass?: string;
  hideCloseButton?: boolean;
  closeButtonClass?: string;
  closeButtonContainerClass?: string;
  dataAutomationId?: string;
}

export interface CustomDialogBoxProps {
  id?: string;
  headerClass?: string;
  headerSlot?: React.ReactNode;
  bodySlot: React.ReactNode;
  footerSlot?: React.ReactNode;
  height?: number;
  width?: number;
  dataAutomationId?: string;
  enterKeyCallback?: () => void;
}
/**
 * @prop {function} dialogClose callback to be called when close button is clicked.
 * @prop {string} headerClass jss class to be applied to the header row.
 * @prop {boolean} hideCloseButton flag used to prevent close button from rendering.
 * @prop {string} closeButton jss class to be applied to the close button.
 */
export const DialogHeader: React.FC<DialogHeaderProps> = (
  props: React.PropsWithChildren<DialogHeaderProps>
) => {
  const {
    id,
    headerClass,
    dialogClose,
    children,
    closeButtonClass,
    closeButtonContainerClass,
    hideCloseButton,
    dataAutomationId,
  } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  useKey('Escape', dialogClose || context.closeDialog);

  const closeButton = !hideCloseButton ? (
    <div className={closeButtonContainerClass}>
      <CloseIconButton
        addClass={closeButtonClass}
        ariaLabel={t('common::Close')}
        dataAutomationId={`${dataAutomationId}CloseButton`}
        onClick={dialogClose}
      />
    </div>
  ) : null;

  if (children) {
    return (
      <div className={headerClass} id={id}>
        {children}
        {closeButton}
      </div>
    );
  } else {
    return closeButton;
  }
};

type Props = CustomDialogBoxProps & WithStyles<typeof customDialogStyles>;

const CustomDialogUnstyled: React.FC<Props> = (props: Props) => {
  const { id, classes, headerSlot, bodySlot, footerSlot, enterKeyCallback } = props;

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === KeyCodes.enter) {
      if (enterKeyCallback) {
        enterKeyCallback();
      }
    }
  };

  return (
    <FocusTrapZone forceFocusInsideTrap={false} isClickableOutsideFocusTrap>
      <div className={`${classes.contentWrapper} ${classes.size}`} id={id} onKeyUp={handleKeyUp}>
        <div className={classes.headerRow}>{headerSlot}</div>
        <div className={classes.bodyRow}>{bodySlot}</div>
        {footerSlot && <div className={classes.footerRow}>{footerSlot}</div>}
      </div>
    </FocusTrapZone>
  );
};

export const CustomDialogBox = withStyles(customDialogStyles)(CustomDialogUnstyled);
