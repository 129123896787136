import gql from 'graphql-tag';

export const SaveNpsSurveyResponseMutation = gql`
  mutation saveNpsSurveyResponse($input: SurveyResponseInput!) {
    saveNpsSurveyResponse(input: $input)
  }
`;

export const SaveNpsSurveyDeclinedMutation = gql`
  mutation {
    saveNpsSurveyDeclined
  }
`;
