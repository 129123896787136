import { ThemeProps } from 'styles';

import { QuoteListRowProps } from './QuoteListRow';

const selectBackgroundColor = (theme: ThemeProps, selectionProps: QuoteListRowProps) => {
  const { palette } = theme;
  return selectionProps.isSelected && !selectionProps.isInErrorState
    ? palette.lineItemHighlight
    : selectionProps.isInErrorState
    ? palette.dangerHighlight
    : selectionProps.isInWarnState
    ? palette.warningHighlight
    : palette.transparent;
};

const hoverBackgroundColor = (theme: ThemeProps, selectionProps: QuoteListRowProps) => {
  const { palette } = theme;
  return selectionProps.isSelected && !selectionProps.isInErrorState
    ? palette.secondaryActive
    : selectionProps.isInErrorState
    ? palette.dangerHighlight
    : selectionProps.isInWarnState
    ? palette.warningHighlight
    : palette.secondaryHover;
};

const selectIndicatorColor = (theme: ThemeProps, selectionProps: QuoteListRowProps) => {
  const { palette } = theme;
  return selectionProps.isSelected && !selectionProps.isInErrorState
    ? theme.palette.primary
    : selectionProps.isInErrorState
    ? palette.danger
    : selectionProps.isInWarnState
    ? palette.warning
    : 'transparent';
};

const selectMessageColor = (theme: ThemeProps, selectionProps: QuoteListRowProps) => {
  const { palette } = theme;
  return selectionProps.isSelected && !selectionProps.isInErrorState
    ? theme.palette.primary
    : selectionProps.isInErrorState
    ? palette.dangerText
    : selectionProps.isInWarnState
    ? palette.warningText
    : undefined;
};

// #region Content
export const quoteListRowStyles = (theme: ThemeProps) => {
  return {
    row: (props: QuoteListRowProps) => ({
      cursor: 'pointer',
      backgroundColor: theme.palette.backgroundStandout,
      '&:hover': {
        backgroundColor: hoverBackgroundColor(theme, props),
      },
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
      minHeight: 71,
    }),
    rowContainer: (props: QuoteListRowProps) => ({
      borderLeft: `4px solid ${selectIndicatorColor(theme, props)}`,
      backgroundColor: selectBackgroundColor(theme, props),
      padding: '12px 0 16px',
    }),
    rowContent: {
      height: '100%',
      display: 'flex',
      '& > div:first-child': { padding: '0 16px 0 12px' },
      '& > div:not(:first-child)': { padding: '0 16px 0' },
      '& > div:last-child': { paddingRight: 36 },
    },
    lineItemMessage: {
      '& > span': {
        maxWidth: 380,
        textAlign: 'center',
      },
      paddingTop: 10,
      paddingBottom: 2,
      justifyContent: 'center',
      display: (props: QuoteListRowProps) => {
        return !!props.message ? 'flex' : 'none';
      },
    },
    loadingRow: {
      padding: '16px 10px 16px',
      height: 79,
      cursor: 'wait',
    },
    lineItemErrorEnabled: {
      display: 'flex',
    },
    messageText: {
      color: (props: QuoteListRowProps) => selectMessageColor(theme, props),
    },
  };
};
