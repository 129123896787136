import { CatalogContext } from 'generated/graphql';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeKey } from 'styles';
import { SmallTile } from 'components';
import {
  renderFavoriteButton,
  defaultImage,
  buildTagsString,
} from './CatalogSectionRowTiles.utils';
import { catalogSectionRowTilesStyles } from './CatalogSectionRowTiles.styles';
import { CatalogPageProduct, CatalogPageNegotiatedTerm } from '../types';

export interface CatalogSectionRowSmallTileProps {
  theme: ThemeKey;
  product: CatalogPageProduct | CatalogPageNegotiatedTerm;
  tileOnClick: (id: string) => void;
  isNegotiatedTerm: boolean;
  selected?: string;
  catalogContext?: CatalogContext;
}
type SmallProps = CatalogSectionRowSmallTileProps & WithStyles<typeof catalogSectionRowTilesStyles>;

export const CatalogSectionRowSmallTileUnstyled: React.FC<SmallProps> = props => {
  const { theme, product, tileOnClick, isNegotiatedTerm, selected, classes } = props;

  let favoriteButton = undefined;
  let tags: string | undefined = '';
  if (isNegotiatedTerm) {
    tags = product.description;
  } else {
    const catalogProduct = product as CatalogPageProduct;
    tags = catalogProduct.categories && buildTagsString(catalogProduct.categories);
    favoriteButton = (
      <div>{renderFavoriteButton(catalogProduct, classes, props.catalogContext)}</div>
    );
  }
  const smallTileProps = {
    button: favoriteButton,
    id: product.id,
    imageDescription: product.title,
    imageUrl:
      (product.images.medium && product.images.medium.Uri) ||
      (product.images.small && product.images.small.Uri) ||
      (product.images.logo && product.images.logo.Uri) ||
      defaultImage(theme),
    key: product.id,
    selected: selected === product.id,
    tags,
    title: product.title,
    onClick: () => tileOnClick(product.id),
  };
  return <SmallTile {...smallTileProps} />;
};

export const CatalogSectionRowSmallTile = withStyles(catalogSectionRowTilesStyles)(
  CatalogSectionRowSmallTileUnstyled
);
