import {
  ActionType,
  ButtonMenuProps,
  mergeClassNames,
  ProgressIndicator,
  ShimmerForBackgroundStandout,
  TextBodyLarge,
} from 'components';
import { IconButton, IconMenuButton, TextBodySmall } from 'components/ions';
import { FocusZone, ShimmerElementType } from 'office-ui-fabric-react';
import * as React from 'react';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ProductIdentifier } from 'services/proposal/types';
import { ThemeProps } from 'styles';
import { useTheme } from 'theming';

import { DragDropProps, DraggableButton, FinderItem, FinderItemType } from '../DragAndDrop';
import { finderListStyles, getListStyle } from './FinderList.styles';

/**
 * Props for the finder list
 * @prop {string} listHeader - Title for the list group
 * @prop {string} id - Id for the list
 * @prop {FinderItem[]} finderItems - Array of the products or terms in the list
 * @prop {boolean} expanded - whether the list starts expanded (default: true)
 * @prop {boolean} addLineItemBusy - indicates if addLineItemMutation is ready for input
 */
export interface FinderListProps extends DragDropProps {
  finderItems: FinderItem[];
  isTerms?: boolean;
  id?: string;
  itemsListClass?: string;
  listHeader: string;
  menuButtonId?: string;
  menuProps?: ButtonMenuProps[];
  expanded?: boolean;
  primaryMessage?: string;
  secondaryMessage?: React.ReactNode;
  preapprovedPrimary?: string;
  preapprovedSecondary?: string;
  onProductKeyDown?: (event: React.KeyboardEvent<ActionType>) => void;
  onToggleList?: (listName: string, listOpened: boolean) => void;
  onItemClick?: (
    productIdentifier?: ProductIdentifier,
    type?: FinderItemType,
    itemText?: string,
    oneAskCaseId?: string
  ) => void;
  isDragEvent: boolean;
  preventKeyClick: boolean;
  crmId?: string;
  isECIF?: boolean;
  dataAutomationId?: string;
  displayFinderList?: boolean;
  addLineItemBusy?: boolean;
}

type Props = FinderListProps & WithStyles<typeof finderListStyles>;

interface ItemIdMap {
  [itemId: string]: true;
}

const getSelectedMap = (selectedItemIds: string[]): ItemIdMap =>
  selectedItemIds.reduce((previous: ItemIdMap, current: string): ItemIdMap => {
    previous[current] = true;
    return previous;
  }, {});

const FinderListUnstyled: React.FC<Props> = (props: Props) => {
  const { addLineItemBusy } = props;
  const theme: ThemeProps = useTheme() as ThemeProps;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(props.expanded);

  const onToggle = () => {
    setIsOpen(!isOpen);
    props.onToggleList && props.onToggleList(props.listHeader, !isOpen);
  };

  const renderHeader = () => {
    const chevron = isOpen ? 'ChevronDown' : 'ChevronRight';

    return (
      <div className={props.classes.header}>
        <div className={props.classes.headerGroup}>
          <TextBodyLarge
            addClass={props.classes.headerTitle}
            data-testid={props.id}
            dataAutomationId={props.dataAutomationId}
            id={props.id}
          >
            {props.listHeader}
          </TextBodyLarge>

          <div>
            {props.menuProps && !!props.menuProps.length && (
              <IconMenuButton
                addClass={props.classes.groupMenu}
                dataAutomationId={`${props.listHeader}MenuButton`}
                iconName="More"
                id={props.menuButtonId}
                menuId={`${props.listHeader}-menu`}
                menuProps={props.menuProps}
              />
            )}
            {((props.finderItems && !!props.finderItems.length) || !!props.isECIF) && (
              <IconButton addClass={props.classes.chevron} iconName={chevron} onClick={onToggle} />
            )}
          </div>
        </div>
        <div className={props.classes.headerLoadingIndicator}>
          <ProgressIndicator ariaValueText="finderBusy" progressHidden={!addLineItemBusy} />
        </div>
      </div>
    );
  };

  const finderItem = (item: FinderItem, index: number, isEcif?: boolean) => {
    const isSelected = Boolean(getSelectedMap(props.selectedItemIds || [])[item.id]);
    const isGhosting: boolean =
      isSelected && Boolean(props.draggingItemId) && props.draggingItemId !== item.id;
    const handleOnClick = () => {
      props.onItemClick &&
        props.onItemClick(item.productIdentifier, item.type, item.itemText, item.oneAskId);
    };

    const ecifProps = isEcif && {
      link: 'QCSupport@microsoft.com',
      linkText: 'QCSupport',
      error: item.error,
      target: item.error && `ECIF${item.id}`,
    };
    return (
      <div
        className={mergeClassNames([
          'FinderListItem',
          props.classes.itemsContainer,
          props.itemsListClass,
        ])}
        key={isEcif ? item.oneAskId : item.id}
        title={item.disabled ? t('quote::Not available for this quote') : undefined}
      >
        <DraggableButton
          {...ecifProps}
          dataAutomationId={item.itemText}
          destinationId="lineItemsList"
          disabled={item.disabled || addLineItemBusy}
          dropCoordinates={{ x: 325, y: 50 }}
          id={item.id}
          index={index}
          isDragEvent={props.isDragEvent}
          isFavoritesButton={!!item.favoriteButton}
          isGhosting={isGhosting}
          isSelected={isSelected}
          item={item}
          key={item.id}
          multiSelectTo={props.multiSelectTo}
          preventKeyClick={props.preventKeyClick}
          selectionCount={props.selectedItemIds ? props.selectedItemIds.length : 0}
          sourceId={props.id}
          toggleSelection={props.toggleSelection}
          toggleSelectionInGroup={props.toggleSelectionInGroup}
          onClick={handleOnClick}
          onKeyDown={props.onProductKeyDown}
        />
        {!props.isDragEvent && item.favoriteButton && (
          <div className={isSelected ? props.classes.favoritesIconStyle : ''}>
            {item.favoriteButton}
          </div>
        )}
      </div>
    );
  };

  const preapprovedECIF =
    props.finderItems &&
    !!props.finderItems.length &&
    props.finderItems.map((item: FinderItem, index: number) => {
      return finderItem(item, index, true);
    });

  const noPreapprovedECIF = (
    <div className={props.itemsListClass}>
      <TextBodySmall addClass={props.classes.watermark}>{props.preapprovedPrimary}</TextBodySmall>
      <TextBodySmall addClass={props.classes.watermark}>{props.preapprovedSecondary}</TextBodySmall>
    </div>
  );

  const ecifList = isOpen && (
    <>
      {props.crmId && props.finderItems && !!props.finderItems.length
        ? preapprovedECIF
        : noPreapprovedECIF}
    </>
  );

  const finderList = isOpen && props.finderItems && (
    <>
      {props.primaryMessage && (
        <TextBodySmall addClass={props.classes.messages}>{props.primaryMessage}</TextBodySmall>
      )}
      {props.secondaryMessage && (
        <TextBodySmall addClass={`${props.classes.messages} ${props.classes.secondaryMessage}`}>
          {props.secondaryMessage}
        </TextBodySmall>
      )}
      {props.finderItems.map((item: FinderItem, index: number) => {
        return finderItem(item, index);
      })}
    </>
  );

  const toRender = props.isECIF ? ecifList : finderList;
  const shimmerColors = (color: string) => {
    return {
      shimmer: color,
      shimmerWave: color,
      background: color,
    };
  };

  const renderListShimmer = () => {
    const shimmerList = [];
    for (let opacity = 1; opacity > 0; opacity = opacity < 0.8 ? opacity - 0.1 : opacity - 0.2) {
      shimmerList.push(
        <div key={`finder-item-shimmer-${opacity}`}>
          <ShimmerForBackgroundStandout
            shimmerColors={shimmerColors(theme.palette.backgroundStandout)}
            shimmerElements={[{ type: ShimmerElementType.line, height: 25 }]}
            styles={{ root: { opacity } }}
            width={212}
          />
          <ShimmerForBackgroundStandout
            shimmerColors={shimmerColors(theme.palette.backgroundPanel)}
            shimmerElements={[{ type: ShimmerElementType.gap, height: 3 }]}
            width={212}
          />
        </div>
      );
    }
    return shimmerList;
  };

  const renderDraggableList = (
    <FocusZone checkForNoWrap onBeforeFocus={props.onProductKeyDown ? () => false : undefined}>
      <div
        data-automation-id={`finderlist-${props.id}`}
        data-testid={`finderlist-${props.id}`}
        id={`finderlist-${props.id}`}
      >
        <Droppable droppableId={props.droppableId}>
          {(droppableProvided: DroppableProvided) => (
            <div ref={droppableProvided.innerRef} style={getListStyle(theme)}>
              {toRender}
            </div>
          )}
        </Droppable>
      </div>
    </FocusZone>
  );

  const renderList = props.displayFinderList ? renderDraggableList : renderListShimmer();

  // onBeforeFocus evaluate if the element will focus after an arrow key event.
  // Returning always false will prevent any focus to happening from arrow key events inside FocusZone.
  // This is required to be able to implement out own arrow key events.
  return (
    <div>
      {renderHeader()}
      {renderList}
    </div>
  );
};

FinderListUnstyled.defaultProps = {
  expanded: true,
  selectedItemIds: [],
  toggleSelection: () => {},
  toggleSelectionInGroup: () => {},
  multiSelectTo: () => {},
};

export const FinderList = withStyles(finderListStyles)(FinderListUnstyled) as React.FC<
  FinderListProps
>;
