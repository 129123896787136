import { sharedStyles, ThemeProps } from 'styles';

export const organizationDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  nameStyles: {
    paddingTop: 18,
    width: 375,
  },
});
