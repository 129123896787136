export const contactSuggestionRowStyles = {
  container: {
    paddingTop: 10,
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
    paddingBottom: 10,
  },
  leftPart: {
    paddingLeft: 10,
  },
  rightPart: {
    textOverflow: 'ellipsis',
    paddingLeft: 15,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 5,
  },
  persona: {
    width: 20,
    height: '100%',
  },
  primaryText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  secondaryText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
