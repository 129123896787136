import { createLoadingSelector } from 'features-apollo/app/selectors';
import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { PermissionsConfig } from './types';

// --- Loading selectors
export const checkLDSSReadPermissionLoading = createLoadingSelector([
  '@@user/ldsspermissions/LOAD',
]);
export const userPermissionsLoading = createLoadingSelector(['@@user/permissions/LOAD']);

// --- Data selectors

/**
 * Gets information of current user from Redux State
 */
export const getUser = (state: RootState) => state.user.current;

/**
 * Gets current user email from Redux State
 */
export const getUserMail = createSelector(getUser, currentUser => currentUser.email);

export const getUserPermissions = createSelector(
  getUser,
  (state: RootState) => state.app.appConfig.app,
  (state: RootState) => state.user.current.permissions,
  (user, environment): string[] => {
    const permissions = (user.permissions && user.permissions.permissions) || [];
    return permissions
      .filter(permission => permission.constraints.includes(environment))
      .map(permission => permission.name);
  }
);

export const userHasPermission = (
  state: RootState,
  permissionsConfig: PermissionsConfig
): boolean => {
  const permissions = getUserPermissions(state);

  // Ensure the user has oneOf the permissions being checked.
  if (
    permissionsConfig.oneOf &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    !permissions.some(permission => permissionsConfig.oneOf!.includes(permission))
  ) {
    return false;
  }

  // Ensure the user has allOf the permissions being checked.
  if (
    permissionsConfig.allOf &&
    !permissionsConfig.allOf.every(permission => permissions.includes(permission))
  ) {
    return false;
  }

  return true;
};

export const userPermissionsLoaded = createSelector(
  getUser,
  (state: RootState) => state.app.appConfig.app,
  (state: RootState) => checkLDSSReadPermissionLoading(state),
  (state: RootState) => userPermissionsLoading(state),
  (user, config, ldssPermissionsLoading, userPermissionsLoading): boolean => {
    if (user.permissions && !ldssPermissionsLoading && !userPermissionsLoading) {
      return user.permissions.permissions !== undefined;
    } else {
      return false;
    }
  }
);
