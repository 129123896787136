import { Dialog, LinkButton, PrimaryButton, TextBody, TextWatermarkSmall } from 'components/ions';
import { Autosuggest, AutosuggestFooter, Suggestion } from 'components/molecules';
import { NotFound } from 'components/molecules/Autosuggest/NotFound';
import { searchOrganizationMaxDisplayLength } from 'features/app/constants';
import { searchOrganizationsForDialogAsync } from 'features/customer/actions';
import {
  getSearchOrganizationDialogResults,
  searchOrganizationDialogHasMore,
  searchOrganizationsForDialogLoading,
} from 'features/customer/selectors';
import { OrganizationInfo } from 'features/proposal/components/OrganizationInfo';
import { getActiveProposal } from 'features/proposal/selectors';
import { getCRMId } from 'features/proposal/utils';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { OrganizationSearchResponseItem } from 'services/edge-search/types';
import { RootState } from 'store/types';

import { AddressSuggestion, AddressSuggestionRow } from './AddressSuggestionRow';
import { dialogDimensions } from './FindOrganizationDialog';
import { findOrganizationViewStyles } from './FindOrganizationView.styles';

export interface FindOrganizationViewProps {
  onApply: (values: OrganizationInformation) => void;
}

const mapStateToProps = (state: RootState) => {
  const proposal = getActiveProposal(state);
  return {
    searchOrganizationsForDialogLoading: searchOrganizationsForDialogLoading(state),
    organizations: getSearchOrganizationDialogResults(state),
    crmId: getCRMId(proposal),
    searchOrganizationDialogHasMore: searchOrganizationDialogHasMore(state),
  };
};

export interface OrganizationInformation {
  accountId: string;
  organizationId: string;
}

export const dispatchProps = {
  onOrganizationSearch: searchOrganizationsForDialogAsync.request,
};

type Props = FindOrganizationViewProps &
  WithStyles<typeof findOrganizationViewStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const createSuggestion = (organizationItem: OrganizationSearchResponseItem) => {
  const { organization } = organizationItem;

  const suggestion: Suggestion<AddressSuggestion> = {
    ariaLabel: organization.legalEntity.name,
    key: organization.id,
    value: {
      accountId: organizationItem.account.id,
      address: organization.legalEntity.businessLocation.address,
      lastPurchaseDateTime: organizationItem.purchase.lastPurchaseCreatedDateTime,
      name: organization.legalEntity.name,
      organizationId: organization.id,
      tradeName: organization.legalEntity.tradeName,
    },
  };
  return suggestion;
};

export const findOrganizationDialogTitle = i18next.t('quote::Find Billing Account');

export const FindOrganizationViewUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const [selected, setSelected] = React.useState<AddressSuggestion | undefined>();
  const { t } = useTranslation();
  const onRenderRow = (suggestion: Suggestion<AddressSuggestion>) => {
    return <AddressSuggestionRow suggestion={suggestion} />;
  };
  const onSelect = (suggestion: Suggestion<AddressSuggestion>) => {
    const { value } = suggestion;
    setSelected(value);
  };
  const onSearch = (query: string) => {
    props.onOrganizationSearch(query);
  };
  const resetSelection = () => {
    setSelected(undefined);
  };

  const hasMoreFooter = props.searchOrganizationDialogHasMore ? (
    <AutosuggestFooter
      primaryText={t('Only {{searchOrganizationMaxLength}} results are shown.', {
        searchOrganizationMaxLength: searchOrganizationMaxDisplayLength,
      })}
      secondaryText={t('Refine your results by adding more characters to your search term.')}
    />
  ) : (
    undefined
  );

  const suggestions: Suggestion<AddressSuggestion>[] = props.organizations.map(organizationItem =>
    createSuggestion(organizationItem)
  );

  const cap = Math.min(suggestions.length, searchOrganizationMaxDisplayLength);
  const cappedSuggestions = suggestions.slice(0, cap);

  const searchTips = (
    <div className={props.classes.searchTipsContainer}>
      <TextWatermarkSmall addClass={props.classes.searchTips}>
        {t('quote::Search tips:')}
        <div />
        {t('quote::To return results beginning with your search term, add an "*" at the end.')}
        <div />
        {t('quote::To return results that contain your search term, add an "*" to both sides.')}
      </TextWatermarkSmall>
    </div>
  );

  let dialogContent;
  if (selected) {
    dialogContent = <OrganizationInfo {...selected} />;
  } else {
    dialogContent = (
      <>
        <Autosuggest
          autoFocus
          dataAutomationId="billingAccountLookup"
          icon={{ iconName: 'Search' }}
          isLoading={props.searchOrganizationsForDialogLoading}
          listFooter={hasMoreFooter}
          notFound={
            <NotFound primaryText={t('quote::No results found')} secondaryText={searchTips} />
          }
          placeholder={t('quote::Search for a billing account by name')}
          required={true}
          showButton
          strings={{
            listAriaLabel: t('quote::Find billing account'),
            loading: t('quote::Loading'),
            notFound: t('quote::No results found'),
          }}
          suggestions={cappedSuggestions}
          textboxLabel={t('quote::Billing account look up')}
          onRenderRow={onRenderRow}
          onSearch={onSearch}
          onSelect={onSelect}
        />
        {searchTips}
      </>
    );
  }
  const onApply = () => {
    selected &&
      props.crmId &&
      props.onApply({
        accountId: selected.accountId,
        organizationId: selected.organizationId,
      });
  };

  const findOrganizationDialogFooterButtons = [
    <PrimaryButton
      dataAutomationId="findOrganizationDialogApply"
      disabled={!selected}
      key="apply-button"
      text={t('quote::Apply')}
      onClick={onApply}
    />,
  ];

  return (
    <Dialog
      {...dialogDimensions}
      dataAutomationId="findBillingAccountDialog"
      footerButtons={findOrganizationDialogFooterButtons}
      title={t('quote::Find billing account')}
    >
      <div className={classes.container}>
        <div className={classes.message}>
          <TextBody>
            {t('quote::Look for a billing account by searching using their name.')}
          </TextBody>
        </div>
        {dialogContent}
        {selected && (
          <LinkButton
            addClass={classes.changeOrganization}
            displayText={t('quote::change billing account')}
            onClick={resetSelection}
          />
        )}
      </div>
    </Dialog>
  );
};

export const FindOrganizationViewWithStyles = withStyles(findOrganizationViewStyles)(
  FindOrganizationViewUnstyled
);

export const FindOrganizationView = connect(
  mapStateToProps,
  dispatchProps
)(FindOrganizationViewWithStyles);
