export enum ErrorType {
  invalidField,
  missingInformation,
}

export interface BodyProps<T> {
  initialData?: T;
  onValid: (newValue: T) => void;
  onInvalid: (errorType?: ErrorType) => void;
}
