import { SearchAtom } from 'components/atoms';
import { TextBodySmall } from 'components/ions';
import { ActionType } from 'components/ions/index';
import { ISearchBox, ISearchBoxStyles } from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { standardSearchStyles } from './StandardSearch.styles';

export interface StandardSearchProps {
  ariaLabel?: string;
  autoFocus?: boolean;
  componentRef?: React.RefObject<ISearchBox>;
  debounce?: boolean;
  disabled?: boolean;
  focusPlaceholder?: string;
  errorMessage?: string;
  id?: string;
  isError?: boolean;
  value?: string;
  placeholder: string;
  styles?: Partial<ISearchBoxStyles>;
  onChange?: (input?: string) => void;
  onChangeDebounced?: (input?: string) => void;
  onBlur?: () => void;
  onClear?: (event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>) => void;
  onFocus?: () => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearch?: (input: string) => void;
}

type Props = StandardSearchProps & WithStyles<typeof standardSearchStyles>;

const StandardSearchUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <SearchAtom
        ariaLabel={props.ariaLabel}
        autoFocus={props.autoFocus}
        componentRef={props.componentRef}
        debounce={props.debounce}
        disabled={props.disabled}
        focusPlaceholder={props.focusPlaceholder}
        id={props.id}
        mainClass={props.classes.border}
        placeholder={props.placeholder}
        styles={props.styles}
        value={props.value}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onChangeDebounced={props.onChangeDebounced}
        onClear={props.onClear}
        onFocus={props.onFocus}
        onKeyPress={props.onKeyPress}
        onSearch={props.onSearch}
      />
      {props.isError ? (
        <TextBodySmall addClass={props.classes.error}> {props.errorMessage} </TextBodySmall>
      ) : null}
    </div>
  );
};

export const StandardSearch = withStyles(standardSearchStyles)(StandardSearchUnstyled) as React.FC<
  StandardSearchProps
>;
