import { LinkButton } from 'components/ions';
import { TextBodySmall, TextTitleSecondary } from 'components/ions/Text';
import { mergeClassNames } from 'components/utilities';
import * as customerSelectors from 'features/customer/selectors';
import * as actions from 'features/proposal/actions';
import * as proposalSelectors from 'features/proposal/selectors';
import { createLineItem } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { endpoints as proposalServiceEndpoints } from 'services/proposal/config';
import { CreateLineItemsRequest, LineItem, ProductIdentifier } from 'services/proposal/types';
import { RootState } from 'store/types';

import { addOnsListStyles } from './AddOns.styles';

export interface AddOn {
  productId: string;
  productName: string;
}

export interface AddOnsProps {
  addOns: AddOn[];
  selectedProject?: string;
}

const mapStateToProps = (state: RootState) => {
  const activeProposal = proposalSelectors.getActiveProposal(state);
  const accountId = proposalSelectors.getAccountId(state, activeProposal);

  return {
    proposal: activeProposal,
    isProposalReadonly: proposalSelectors.isProposalReadOnly(state),
    proposalServiceEndpoint: proposalServiceEndpoints[state.app.appConfig.proposal.environment],
    assets: accountId
      ? customerSelectors.getFilteredAssetsByAccountId(state, accountId)
      : undefined,
  };
};

const dispatchProps = {
  addLineItem: (request: CreateLineItemsRequest) =>
    actions.createProposalLineItemsAsync.request(request),
};

type Props = AddOnsProps &
  WithStyles<typeof addOnsListStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const AddOnsUnstyled: React.FC<Props> = ({
  proposal,
  isProposalReadonly,
  selectedProject,
  proposalServiceEndpoint,
  assets,
  addLineItem,
  addOns,
  classes,
}: Props) => {
  const { t } = useTranslation();

  const onItemSelect = (productIdentifier: ProductIdentifier) => {
    createLineItem(
      isProposalReadonly,
      proposal,
      proposalServiceEndpoint,
      productIdentifier,
      addLineItem,
      selectedProject
    );
  };

  const renderAddOns = addOns.map((addOn, index) => {
    const emptyList: LineItem[] = [];
    const lineItems = proposal.lineItems || emptyList;
    const existingLineItem = lineItems.some(
      lineItem => addOn.productId === lineItem.productIdentifier.productId
    );
    const existingAsset =
      assets && assets.some(asset => asset.assetData.productInfo.productId === addOn.productId);

    const existingAssetDisplayText = t('quote::existing asset');
    const addToQuoteDisplayText = t('quote::add to quote');
    const displayText = () =>
      existingAsset || existingLineItem ? existingAssetDisplayText : addToQuoteDisplayText;
    const assetExists = () => existingAsset || existingLineItem || false;

    const addOnLink = (
      <div className={classes.group} key={index}>
        {addOn && (
          <div className={classes.group}>
            {addOn && addOn.productName && (
              <div className={classes.rowFlex}>
                <div className={classes.column}>
                  <TextBodySmall>
                    <span>{addOn.productName}</span>
                  </TextBodySmall>
                </div>
                <div className={mergeClassNames([classes.column, classes.rightAlign])}>
                  <TextBodySmall>
                    <LinkButton
                      addClass={classes.button}
                      disabled={assetExists()}
                      displayText={displayText()}
                      onClick={() => onItemSelect({ productId: addOn.productId })}
                    />
                  </TextBodySmall>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
    return addOnLink;
  });

  return (
    <div className={classes.listContainer}>
      <div className={classes.list}>
        <TextTitleSecondary addClass={classes.title}>
          {t('quote::Prerequisites')}
        </TextTitleSecondary>

        <TextBodySmall addClass={classes.subtitle}>
          {t('quote::This product is an add-on for the following prerequisite product:', {
            count: addOns ? addOns.length : 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            defaultValue_plural:
              'This product is an add-on for the following prerequisite products:',
          })}
        </TextBodySmall>
        {renderAddOns}
      </div>
    </div>
  );
};

export const AddOnsStyled = withStyles(addOnsListStyles)(AddOnsUnstyled) as React.FC<AddOnsProps>;

export const AddOns = connect(mapStateToProps, dispatchProps)(AddOnsStyled);
