import { CustomerListRowProps } from 'components/ions/CustomerListRow';
import { createProcessingSelectors } from 'features/app/selectors';
import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import {
  filterToDistinctCustomers,
  mapEntityTypeToCustomerDisplayRow,
  mergeExactSearchResultToTop,
} from './ldssCustomerBusinessLogic';

const getSearchTermState = (state: RootState) => state.ldsscustomer.customerSearch.searchTerm;

const getSearchCustomerWildcardResult = (state: RootState) =>
  state.ldsscustomer.customerSearch.wildcardResponse;

const getSearchCustomerTopResults = (state: RootState) =>
  state.ldsscustomer.customerSearch.topResults;

const getSearchCustomerExactResult = (state: RootState) =>
  state.ldsscustomer.customerSearch.exactResponse;

export const getLdssCustomerName = (state: RootState) => state.ldsscustomer.selectedCustomer;

const totalResults = (state: RootState) => state.ldsscustomer.customerSearch.totalResults;
const skipItems = (state: RootState) => state.ldsscustomer.customerSearch.skipItems;
export const getTopRecommendedSearchCustomerResults = createSelector(
  [getSearchTermState, getSearchCustomerTopResults, getSearchCustomerExactResult, skipItems],
  (search, topResults, exactResult, skipItems) => {
    const searchTerm = search;

    mergeExactSearchResultToTop(searchTerm, topResults, exactResult);

    topResults = filterToDistinctCustomers(topResults);

    return {
      customers: topResults.map(customer => {
        let mappedCustomer: CustomerListRowProps = mapEntityTypeToCustomerDisplayRow(
          customer,
          searchTerm
        );
        return mappedCustomer;
      }),
      totalResults: topResults.length,
      loadedItemCount: skipItems,
    };
  }
);

export const getFullSearchCustomerResults = createSelector(
  [getSearchTermState, getSearchCustomerWildcardResult, totalResults, skipItems],
  (search, wildCardResult, totalResults, skipItems) => {
    const searchTerm = search;

    return {
      customers: wildCardResult.map(customer => {
        let mappedCustomer: CustomerListRowProps = mapEntityTypeToCustomerDisplayRow(
          customer,
          searchTerm,
          true
        );
        return mappedCustomer;
      }),
      totalResults: totalResults,
      loadedItemCount: skipItems,
    };
  }
);

export const customerSearchProcessing = createProcessingSelectors(['@@ldsscustomer/SEARCH']);
