import { ButtonAtom } from 'components/atoms';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { mergeClassNames } from '../../../utilities';

export const iconBorderButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    button: (props: ButtonSharedProps) => ({
      minWidth: 32,
      height: 32,
      backgroundColor: colors.backgroundStandout,
      border: `1px solid ${colors.border}`,
      padding: '7px 8px',
      '& i': {
        color: colors.textTertiary,
        paddingRight: props.iconName && props.text ? 4 : undefined,
        margin: 0,
      },
      '&:hover': {
        backgroundColor: colors.backgroundStandout,
        border: `1px solid ${colors.text}`,
        '& i': {
          color: colors.textTertiary,
        },
      },
      '&:focus': {
        '& i': {
          color: colors.textTertiary,
        },
      },
      '&:active': {
        backgroundColor: colors.backgroundMain,
        '& i': {
          color: colors.textTertiary,
        },
      },
      '&:active:hover': {
        backgroundColor: colors.backgroundMain,
        border: `1px solid ${colors.text}`,
        '& i': {
          color: colors.textTertiary,
        },
      },
      '& i:disabled': {
        color: colors.textDisabled,
      },
    }),
  };
};

type Props = ButtonSharedProps & WithStyles<typeof iconBorderButtonStyles>;

const DefaultButtonUnstyled: React.FC<Props> = props => {
  return (
    <ButtonAtom
      {...props}
      addClass={mergeClassNames([props.addClass, props.classes.button])}
      iconProps={{ iconName: props.iconName }}
    />
  );
};

export const Button = withStyles(iconBorderButtonStyles)(DefaultButtonUnstyled) as React.FC<
  ButtonSharedProps
>;
