import { TextBody } from 'components';
import { EditLabel } from 'components/molecules/EditLabel/EditLabel';
import { LanguageInfo } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { organizationInformationStyles } from '../OrganizationInformation.styles';

export interface OrganizationInformationLanguageProps {
  organizationLanguage?: LanguageInfo | null;
}

type Props = OrganizationInformationLanguageProps &
  WithStyles<typeof organizationInformationStyles>;

const OrganizationInformationLanguageUnstyled: React.FC<Props> = props => {
  const { classes, organizationLanguage } = props;
  const { t } = useTranslation();

  // #region Invoice Language
  return (
    <div className={classes.customerAddedSection}>
      <EditLabel
        ariaLabel={t('quote::Invoice language')}
        hideIcon
        label={t('quote::Invoice language')}
      />
      <TextBody dataAutomationId="invoiceLanguage">
        {organizationLanguage && organizationLanguage.display}
      </TextBody>
    </div>
  );
  // #endregion
};

export const OrganizationInformationLanguage = withStyles(organizationInformationStyles)(
  OrganizationInformationLanguageUnstyled
);
