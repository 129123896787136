import { LinkButton, TextBodySmall } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext, DialogProps } from 'styles';

const ShowMoreStyles = {
  linkStyle: {
    paddingTop: 4,
  },
  summaryContainer: {
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    margin: 0,
  },
  summary: {
    display: 'block',
  },
};

export interface ShowMoreDialogueProps extends WithStyles<typeof ShowMoreStyles> {
  id?: string;
  linkId?: string;
  text: string;
  linkText: string;
  maxLines: number;
  dialogProps: DialogProps;
  alwaysShowLink?: boolean;
  dataAutomationId?: string;
}

const showLink = (isClamped: boolean, link: React.ReactNode, alwaysShow: boolean = false) => {
  if (alwaysShow || isClamped) {
    return <div>{link}</div>;
  }
  return null;
};

const ShowMoreDialogUnstyled: React.FC<ShowMoreDialogueProps> = ({ dialogProps, ...props }) => {
  const [isClamped, setClamped] = React.useState(false);
  const { id, linkId, text, linkText, classes, alwaysShowLink } = props;
  const context = React.useContext(DialogContext);
  const paragraphRef = React.useRef<HTMLParagraphElement | null>(null);

  React.useLayoutEffect(() => {
    const isEllipsisActive = !!paragraphRef.current && paragraphRef.current.scrollHeight > 80;
    setClamped(isEllipsisActive);
  }, [text, paragraphRef]);

  const openDialog = () => {
    context.openDialog(dialogProps);
  };

  const link = (
    <div className={classes.linkStyle}>
      <TextBodySmall>
        <LinkButton
          dataAutomationId={props.dataAutomationId}
          displayText={linkText}
          id={linkId}
          onClick={openDialog}
        />
      </TextBodySmall>
    </div>
  );

  return (
    <div id={id}>
      <p className={classes.summaryContainer} ref={paragraphRef}>
        <TextBodySmall
          addClass={classes.summary}
          dataAutomationId={
            props.dataAutomationId
              ? `${props.dataAutomationId}-Limited-Description`
              : 'Limited-Description'
          }
        >
          {text}
        </TextBodySmall>
      </p>
      {showLink(isClamped, link, alwaysShowLink)}
    </div>
  );
};

export const ShowMoreDialog = withStyles(ShowMoreStyles)(ShowMoreDialogUnstyled);
