import {
  agreementsBody,
  approvalUnionBody,
  crmLeadBody,
  discountLineItemFragment,
  ecifLineItemFragment,
  financingTermLineItemFragment,
  invitedUserSimpleBody,
  messageFragment,
  monetaryLineItemFragment,
  organizationSimpleBody,
  purchaseLineItemFragment,
  sapLineItemFragment,
  simpleTermLineItemFragment,
  userFragment,
} from 'features-apollo/ActiveQuoteContext.queries';
import {
  ApplicableDiscountsFragment,
  DateOrDurationFragment,
} from 'features-apollo/quote/components/DiscountCard/queries';
import gql from 'graphql-tag';

const lineItemBodyWithoutProductDetails = gql`
  fragment LineItemBodyWithoutProductDetails on LineItem {
    __typename
    id
    isConfigurable
    title
    groups
    product {
      __typename
      id
      isPotentiallyDiscountable
    }
    messages {
      ...MessageFragment
    }
    configurationSummary {
      display
    }
    isReadyForPricing
    sku {
      skuId
      title
      filters {
        key
        values
      }
    }
    availability {
      availabilityId
    }
    term {
      termId
    }
    catalogContext {
      audience
      agreementType
      nationalCloud
      market
      languages
      action
    }
    messages {
      ...MessageFragment
    }
    ... on SapLineItem {
      ...SapLineItemFragment
    }
    ... on PurchaseLineItem {
      ...PurchaseLineItemFragment
    }
    ... on DiscountLineItem {
      ...DiscountLineItemFragment
    }
    ... on FinancingTermLineItem {
      ...FinancingTermLineItemFragment
    }
    ... on SimpleTermLineItem {
      ...SimpleTermLineItemFragment
    }
    ... on MonetaryLineItem {
      ...MonetaryLineItemFragment
    }
    ... on EcifLineItem {
      ...EcifLineItemFragment
    }
  }
  ${messageFragment}
  ${ApplicableDiscountsFragment}
  ${DateOrDurationFragment}
  ${monetaryLineItemFragment}
  ${sapLineItemFragment}
  ${financingTermLineItemFragment}
  ${simpleTermLineItemFragment}
  ${discountLineItemFragment}
  ${purchaseLineItemFragment}
  ${messageFragment}
  ${ecifLineItemFragment}
`;

export const quoteBodyWithoutProductDetails = gql`
  fragment QuoteBodyWithoutProductDetails on Quote {
    id
    etag
    agreementType
    assignedTo
    audience
    billingCurrency
    canShareLeadDiscounts
    clouds
    exchangeRateDate
    expirationDate
    fgoe
    market
    motion
    name
    productAudience
    readOnly
    status
    totalPrice
    transactOnBehalf
    transactionModel
    version
    vlAgreementNumber
    agreements(input: { agreementType: SPECIAL }) {
      ...AgreementsBody
    }
    approval {
      ...ApprovalUnionBody
    }
    crmLead {
      ...CrmLeadBody
    }
    endCustomer {
      ...OrganizationSimpleBody
    }
    endCustomerInvitedUser: invitedUser(customerType: EndCustomer) {
      ...InvitedUserSimpleBody
    }
    leadOrganization {
      ...OrganizationSimpleBody
    }
    languageInfo {
      gqlCode
    }
    lineItems {
      ...LineItemBodyWithoutProductDetails
    }
    messages {
      ...MessageFragment
    }
    msContact {
      ...UserFragment
      displayName
    }
    soldTo {
      dealEstimate
      monthlyCreditLimit
      organization {
        ...OrganizationSimpleBody
      }
    }
    soldToInvitedUser: invitedUser(customerType: SoldToCustomer) {
      ...InvitedUserSimpleBody
    }
  }
  ${agreementsBody}
  ${approvalUnionBody}
  ${crmLeadBody}
  ${invitedUserSimpleBody}
  ${organizationSimpleBody}
  ${lineItemBodyWithoutProductDetails}
  ${userFragment}
  ${messageFragment}
`;

export const ApplyConfigurationSingleSku = gql`
  mutation applyConfigurationSingleSku(
    $quote: QuoteMutationInput!
    $configuration: ApplyConfigurationSingleSkuInput!
  ) {
    applyConfigurationSingleSku(quote: $quote, configuration: $configuration) {
      ...QuoteBodyWithoutProductDetails
    }
  }
  ${quoteBodyWithoutProductDetails}
`;
