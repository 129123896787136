import { mergeClassNames } from 'components';
import { ButtonMenuProps, ButtonSharedProps, IconMenuButton } from 'components/ions/Buttons';
import { SearchResultProduct } from 'features/catalog';
import {
  GET_QUOTERECOMMENDATIONS,
  UPDATE_FAVORITEGROUP,
} from 'features-apollo/quote/components/queries';
import { favoriteButtonKeyboardNavigation } from 'features/proposal/components/Finder/keyboardNavigation';
import {
  BaseRecommendationAction,
  CatalogContext,
  Query,
  ProductRecommendationAction,
  RecommendationGroup,
} from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ProductGroup, ProductGroups } from 'services/user-preferences/types';
import { useMutation } from '@apollo/react-hooks';

import { FavoritesCallout } from '../FavoritesCallout';
import { favoriteButtonsSharedStyles } from '../shared.styles';
import { buildOptimisticResponse } from '../utils';

/**
 * Props for Favorite Menu Button
 * @prop {string} groupName - To know where the product may be move from and be remove from that group
 * @prop {string} product - Product from where the callout was open
 */
export interface FavoriteMenuButtonProps extends ButtonSharedProps {
  addContainerClass?: string;
  groupName: string;
  product: SearchResultProduct;
  favoritesProductGroups: Record<string, ProductGroup>;
  quoteId?: string;
  catalogContext?: CatalogContext;
}

type Props = FavoriteMenuButtonProps & WithStyles<typeof favoriteButtonsSharedStyles>;

const FavoriteMenuButtonUnStyled: React.FC<Props> = (props: Props) => {
  const [copyToDifferentGroupCalloutDismissed, setCopyToCalloutDismissed] = React.useState(true);
  const [moveToDifferentGroupCalloutDismissed, setMoveToCalloutDismissed] = React.useState(true);

  const { t } = useTranslation();
  const { groupName, product } = props;
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);

  const elementIdToRefocus = `favorite-button-${groupName}-${product.productId}`;
  const [removeProductFromFavorites] = useMutation(UPDATE_FAVORITEGROUP, {
    onCompleted: data => {
      const productGroups: ProductGroups = {};

      data.updateFavoriteGroup &&
        data.updateFavoriteGroup.forEach((favGroup: RecommendationGroup) => {
          const products = favGroup.items.map(item => {
            const recommendationItem = item as ProductRecommendationAction;
            return {
              productId: recommendationItem.product.id,
              productIdentifier: {
                productId: recommendationItem.product.id,
                productType: recommendationItem.product.productType,
              },
              productName: recommendationItem.product.title,
            };
          });
          productGroups[favGroup.name] = { products: products };
        });
    },
  });

  const isEqual = (item: BaseRecommendationAction, product: SearchResultProduct) => {
    const recommendationItem = item as ProductRecommendationAction;
    return recommendationItem.product.id === product.productId;
  };

  const menuOptions: ButtonMenuProps[] = [
    {
      key: 'unfavorite',
      text: t('Unfavorite'),
      icon: 'Heart',
      dataAutomationId: 'unfavoriteMenuButton',
      onClick: () => {
        removeProductFromFavorites({
          variables: {
            input: {
              productId: product.productId,
              removeFromGroup: groupName,
              catalogContext: props.catalogContext,
            },
          },
          optimisticResponse: buildOptimisticResponse(product, groupName),
          update: (useApolloClient, { data: { updateFavoriteGroup } }) => {
            // Read from cached
            const cached = useApolloClient.readQuery<Query>({
              query: GET_QUOTERECOMMENDATIONS,
              variables: { id: props.quoteId, input: props.catalogContext },
            });

            // Create Update object
            if (
              updateFavoriteGroup.find((rec: RecommendationGroup) => rec.name === groupName).items
            ) {
              if (cached && cached.getQuote && cached.getQuote.recommendations) {
                let recGroups = cached.getQuote.recommendations;
                let favRecGroup = recGroups.find(rec => rec.name === groupName);
                if (favRecGroup) {
                  const index = favRecGroup.items.findIndex(item => isEqual(item, product));
                  favRecGroup.items.splice(index, 1);
                }
              }
            }

            // Write updated clone object to cache
            useApolloClient.writeQuery({
              query: GET_QUOTERECOMMENDATIONS,
              variables: { id: props.quoteId, input: props.catalogContext },
              data: cached,
            });
          },
        });
      },
    },
    {
      key: 'move',
      text: t('Move to different group'),
      dataAutomationId: 'moveToDifferentGroupMenuButton',
      icon: 'FabricMovetoFolder',
      onClick: () => setMoveToCalloutDismissed(false),
    },
    {
      key: 'copy',
      text: t('Copy to different group'),
      dataAutomationId: 'CopyToDifferentGroupMenuButton',
      icon: 'FabricNewFolder',
      onClick: () => setCopyToCalloutDismissed(false),
    },
  ];

  const copyToDifferentGroupCallout = (
    <FavoritesCallout
      catalogContext={props.catalogContext}
      description={t('Which group would you like to copy this product to?')}
      elementIdToFocusOnDismiss={elementIdToRefocus}
      excludeGroupName={groupName}
      hidden={copyToDifferentGroupCalloutDismissed}
      product={props.product}
      quoteId={props.quoteId}
      target={buttonContainerRef.current}
      title={t('Copy to...')}
      onDismiss={() => setCopyToCalloutDismissed(true)}
    />
  );

  const moveToDifferentGroupCallout = (
    <FavoritesCallout
      catalogContext={props.catalogContext}
      description={t('Which group would you like to move this product to?')}
      elementIdToFocusOnDismiss={elementIdToRefocus}
      excludeGroupName={groupName}
      hidden={moveToDifferentGroupCalloutDismissed}
      moveFrom={groupName}
      product={props.product}
      quoteId={props.quoteId}
      target={buttonContainerRef.current}
      title={t('Move to...')}
      onDismiss={() => {
        setMoveToCalloutDismissed(true);
      }}
    />
  );

  return (
    <>
      <div
        className={mergeClassNames([props.classes.buttonContainer, props.addContainerClass])}
        ref={buttonContainerRef}
        onKeyDown={favoriteButtonKeyboardNavigation}
      >
        <IconMenuButton
          addClass={props.classes.button}
          ariaLabel={t('Product menu')}
          dataAutomationId={elementIdToRefocus}
          iconName="More"
          id={elementIdToRefocus}
          menuId={`favorite-product-menu-${product.productId}`}
          menuProps={menuOptions}
          title={props.title}
        />
      </div>
      {copyToDifferentGroupCallout}
      {moveToDifferentGroupCallout}
    </>
  );
};

export const FavoriteMenuButton = withStyles(favoriteButtonsSharedStyles)(
  FavoriteMenuButtonUnStyled
) as React.FC<FavoriteMenuButtonProps>;
