import { CommandButtonAtom } from 'components/atoms';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { iconBorderButtonStyles } from './IconBorderButton.styles';

type Props = ButtonSharedProps & WithStyles<typeof iconBorderButtonStyles>;

const IconBorderButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <CommandButtonAtom
      addClass={`${props.classes.command} ${props.classes.icon}`}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: props.iconName }}
      id={props.id}
      onClick={props.onClick}
    />
  );
};

export const IconBorderButton = withStyles(iconBorderButtonStyles)(
  IconBorderButtonUnstyled
) as React.FC<ButtonSharedProps>;
