import { ThemeProps, sharedStyles } from 'styles';
import { deleteProposalDialogStyles } from 'features/proposal/components/Dialogs/DeleteDialog/DeleteDialog.styles';

export const deleteReferralDialogStyles = (theme: ThemeProps) => ({
  ...deleteProposalDialogStyles(theme),
  customDialogBody: {
    ...sharedStyles(theme).customDialogBody,
    wordBreak: 'break-word',
  },
  bold: {
    '&:not(:first-child):not(:last-child)': {
      fontWeight: theme.fonts.fontWeights.semiBold,
      color: theme.palette.text,
    },
  },
  crmIdContainer: {
    marginTop: 11,
  },
  crmIdWidth: {
    width: 140,
  },
  crmIdErrorStyle: {
    width: 345,
  },
});
