import { Dialog } from 'components';
import { Fail, Processing } from 'features/components/dialogs';
import {
  getTenant as getTenantSelector,
  processingAddingTenant,
  processingOrganizationEdit,
  searchingTenantFootprint,
} from 'features/customer/selectors';
import * as proposalActions from 'features/proposal/actions';
import {
  SignupEmail,
  SignUpEmailFooterButtons,
  SignUpEmailHeaderString,
  TenantBody,
  TenantData,
  TenantFooterButtons,
  TenantHeaderString,
  WorkAccountBody,
  WorkAccountFooterButtons,
  WorkAccountHeaderString,
} from 'features/proposal/components/Dialogs';
import { Success } from 'features/proposal/components/Dialogs/Shared';
import { getAccountId, getActiveProposal } from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { ExternalUser } from 'services/externaluser/types';
import { convertToExternalUser } from 'services/externaluser/utils';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';
import { oc } from 'ts-optchain';

import { tenantWizardStyles } from './TenantWizard.styles';

const dialogDimensions = {
  height: 612,
  width: 600,
};

export enum TenantWizardView {
  Tenant,
  Email,
  Processing,
}

export interface TenantWizardProps {
  initialView?: TenantWizardView;
}

const mapStateToProps = (state: RootState) => {
  const proposal = getActiveProposal(state);
  const invitedUser = convertToExternalUser(proposal.header.invitedUser);
  const accountId = getAccountId(state, proposal);
  const account = accountId && state.customer.account[accountId];
  const processingEdit = processingOrganizationEdit(state);
  const processingUpdateTenant = processingAddingTenant(state);
  const tenantSearchResult = (tenantId: string) => getTenantSelector(state, tenantId);
  const searchingTenant = searchingTenantFootprint(state);

  return {
    processingUpdateTenant,
    proposal,
    invitedUser,
    accountId,
    tenants: (account && account.externalIds) || undefined,
    processingEdit,
    tenantSearchResult,
    searchingTenant,
  };
};

const dispatchProps = {
  updateInvitedUser: proposalActions.updateInvitedUser,
};

export type TenantWizardStateProps = typeof dispatchProps &
  ReturnType<typeof mapStateToProps> &
  TenantWizardProps;

type Props = TenantWizardStateProps & WithStyles<typeof tenantWizardStyles>;

const TenantWizardUnstyled: React.FC<Props> = props => {
  const tenantCount = oc(props).tenants.length(0);
  //#region State Management
  const { t } = useTranslation();

  const [isTenant, setIsTenant] = React.useState<boolean>(false);
  const [isWorkAccountUnauthorized, setIsWorkAccountUnauthorized] = React.useState<boolean>(false);

  const [currentStep, setCurrentStep] = React.useState<TenantWizardView>(
    props.initialView || TenantWizardView.Tenant
  );

  const [tenant, setTenant] = React.useState<TenantData | null | undefined>();
  const [email, setEmail] = React.useState<ExternalUser | undefined>(props.invitedUser);
  const [isValid, setIsValid] = React.useState<boolean>();
  //#endregion

  const notReady = React.useCallback(() => setIsValid(false), [setIsValid]);
  const onValidEmail = React.useCallback(
    (newEmail?: string) => {
      if (newEmail) {
        setEmail({ UPN: newEmail });
        setIsValid(true);
      } else {
        setEmail(undefined);
        setIsValid(false);
      }
    },
    [setEmail, setIsValid]
  );
  const setCurrentStepFactory = (view: TenantWizardView) => () => setCurrentStep(view);

  const updateInvitedUser = () => {
    if (email) {
      props.updateInvitedUser({ user: email, quoteId: props.proposal.id });
      setCurrentStep(TenantWizardView.Processing);
    }
  };
  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };
  let header: string;
  let body;
  let footer;

  switch (currentStep) {
    case TenantWizardView.Tenant: {
      if (!isTenant) {
        setIsTenant(true);
      }

      const changingTenant =
        !!(props.invitedUser && props.invitedUser.TenantId) || tenantCount === 1;

      const workAccount =
        props.invitedUser && props.invitedUser.TenantId ? props.invitedUser.UPN : undefined;

      header = TenantHeaderString(!!tenantCount, changingTenant);
      body = (
        <TenantBody
          changingTenant={changingTenant}
          initialData={tenant}
          workAccount={workAccount}
          onInvalid={notReady}
          onValid={(newTenant?: TenantData | null) => {
            setTenant(newTenant);
            setIsValid(true);
          }}
        />
      );
      footer = TenantFooterButtons(setCurrentStepFactory(TenantWizardView.Email), !isValid);
      break;
    }
    case TenantWizardView.Email: {
      if (!email && isValid) {
        setIsValid(false);
      }

      const editEmail = props.initialView === TenantWizardView.Email;

      const tenantIdFromState =
        (props.invitedUser && props.invitedUser.TenantId) ||
        (editEmail && props.tenants && props.tenants[0]);

      const tenantId = (tenant && tenant.tenantId) || tenantIdFromState;
      if (tenantId) {
        header = WorkAccountHeaderString(!!tenantIdFromState && !!props.invitedUser);
        body = (
          <WorkAccountBody
            initialData={tenantIdFromState ? email : undefined}
            isRequired={true}
            tenantId={tenantId}
            onInvalid={notReady}
            onValid={(newEmail?: ExternalUser) => {
              setEmail(newEmail);
              setIsValid(true);
            }}
            setIsWorkAccountUnauthorized={setIsWorkAccountUnauthorized}
          />
        );
      } else {
        header = SignUpEmailHeaderString(!!props.invitedUser);

        body = (
          <SignupEmail
            initialData={email && email.UPN}
            onInvalid={notReady}
            onValid={onValidEmail}
          />
        );
      }

      footer = tenantId
        ? WorkAccountFooterButtons(
            isTenant,
            !isValid,
            updateInvitedUser,
            setCurrentStepFactory(TenantWizardView.Tenant),
            isWorkAccountUnauthorized
          )
        : SignUpEmailFooterButtons(
            isTenant,
            !isValid,
            updateInvitedUser,
            setCurrentStepFactory(TenantWizardView.Tenant)
          );
      break;
    }

    case TenantWizardView.Processing:
    default: {
      const loadingMessage = t('quote::Update underway, this may take a couple of seconds');
      const successMessage = t('quote::The requested changes were successfully completed.');
      const failMessage = t(
        `error::The tenant {{tenantId}} is already linked to an account and therefore can't be used to create another account with this quote. Please reuse the existing account or chose another tenant.`,
        {
          tenantId: tenant ? tenant.tenantId : '',
        }
      );
      //TODO: Make this smarter.
      //Right now it'll act weird if it fails for one of the edit flows, you close the dialog without completing and open another edit dialog
      const loading = props.processingEdit.loading || props.processingUpdateTenant.loading;
      const tryAgain = updateInvitedUser;
      const errorDialog = (
        <Fail
          {...dialogDimensions}
          closeDialog={closeDialog}
          dataAutomationId="processingUpdateTenantFail"
          onTryAgainClick={tryAgain}
        />
      );

      if (loading) {
        return <Processing {...dialogDimensions} key="Processing" message1={loadingMessage} />;
      }
      // Errors that only apply when the user has selected a tenant
      else if (tenant && props.processingUpdateTenant.error) {
        return oc(props.processingUpdateTenant).error.exception.response.data.code() ===
          'ExternalIdAlreadyBound' ? (
          <Fail
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="tenantAlreadyLinkedFail"
            message={failMessage}
          />
        ) : (
          errorDialog
        );
      } else if (props.processingEdit.error) {
        return errorDialog;
      } else {
        return (
          <Success
            message={successMessage}
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="addWorkAccountSuccess"
          />
        );
      }
    }
  }

  return (
    <Dialog
      closeDialog={closeDialog}
      footerButtons={footer}
      title={header}
      {...dialogDimensions}
      bodyAddClass={props.classes.body}
      dataAutomationId="tenantWizard"
    >
      {body}
    </Dialog>
  );
};

export const TenantWizardStyled = withStyles(tenantWizardStyles)(TenantWizardUnstyled);

export const TenantWizard = connect(mapStateToProps, dispatchProps)(TenantWizardStyled);

export const openTenantWizardDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  view?: TenantWizardView
) => {
  const dialogProps: DialogProps = {
    providedDialog: <TenantWizard initialView={view} />,
  };
  context.openDialog(dialogProps);
};
