import { CommandButton, mergeClassNames } from 'components';
import { headerButtonStyles } from 'components/ions/Buttons/HeaderButton/HeaderButton.styles';
import { buttonSharedStyles } from 'components/ions/Buttons/shared.styles';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { Flight, isFlight } from 'services/flights/flightList';
import { isDevBuild } from 'store/utils';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  ...buttonSharedStyles(theme),
  ...headerButtonStyles(theme),
  container: {
    position: 'relative',
  },
  flightCount: {
    background: theme.palette.lightColor,
    borderRadius: 16,
    color: theme.palette.darkColor,
    fontSize: theme.fonts.fontSizes.small,
    position: 'absolute',
    right: 5,
    textAlign: 'center',
    top: 5,
    width: 16,
  },
});

type Props = {
  flights: Partial<Record<Flight, boolean>>;
  isOpen: boolean;
  onClick: () => void;
  usePrimaryColor?: boolean;
  isDemoMode?: boolean;
} & WithStyles<typeof styles>;

const FlightsButtonUnstyled: React.FC<Props> = props => {
  const { classes, flights, isOpen, onClick, usePrimaryColor, isDemoMode } = props;

  const buttonStyle = isOpen ? classes.expanded : classes.command;
  const buttonPrimaryStyle = isOpen ? classes.primaryExpanded : classes.primary;
  const buttonDemoStyle = isOpen ? classes.primaryExpanded : classes.demo;

  const themeColor = isDemoMode
    ? buttonDemoStyle
    : usePrimaryColor
    ? buttonPrimaryStyle
    : buttonStyle;
  const buttonClasses = mergeClassNames([themeColor, classes.iconButton]);

  const enabledFlightCount = Object.keys(flights).reduce<number>(
    (total: number, flight: string) => total + (isFlight(flight) && flights[flight] ? 1 : 0),
    0
  );

  return (
    <div className={classes.container}>
      <CommandButton
        addClass={buttonClasses}
        iconProps={{ iconName: 'Airplane' }}
        title="Open Flights Panel"
        onClick={onClick}
      />
      {isDevBuild && <div className={classes.flightCount}>{enabledFlightCount}</div>}
    </div>
  );
};

export const FlightsButton = withStyles(styles)(FlightsButtonUnstyled);
