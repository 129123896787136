import { Panel } from 'components/ions';
import { SupportTicketPanelContent as SupportTicketPanelContentApollo } from 'features-apollo/components/helpPanel/SupportTicketPanelContent';
import { getAppEnvironment, getFlightIsEnabled } from 'features/app/selectors';
import { IPanelProps, IPanelStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect, ConnectedProps } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

import { setHelpPanelWidth } from '../../actions';
import { HelpArticle } from './HelpArticle';
import { PanelArticleWidth, PanelMainWidth, panelStyles } from './HelpPanel.styles';
import { Article } from './HelpPanel.types';
import { getNavigationContent } from './HelpPanel.utils';
import { HelpPanelArticleGroup } from './HelpPanelArticleGroup';
import { HelpPanelFooter } from './HelpPanelFooter';
import { SupportResources } from './SupportResources';
import { SupportTicketPanelContent } from './SupportTicketPanelContent';

const mapState = (state: RootState) => ({
  appEnvironment: getAppEnvironment(state),
  contentType: state.app.helpContentType,
  isGQLFlightEnabled: getFlightIsEnabled(state, Flight.graphqlPhase2),
});

const mapDispatch = {
  setStoreHelpPanelWidth: setHelpPanelWidth,
};

const connector = connect(mapState, mapDispatch);

export interface HelpPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
  styles?: Partial<IPanelStyles>;
}

type Props = HelpPanelProps & WithStyles<typeof panelStyles> & ConnectedProps<typeof connector>;

const HelpPanelUnStyled: React.FC<Props> = props => {
  const { appEnvironment, classes, contentType, isOpen, onDismiss, setStoreHelpPanelWidth } = props;
  const { t } = useTranslation();

  const [showSupportResources, setShowSupportResources] = React.useState(false);
  const [isArticleShowing, setIsArticleShowing] = React.useState(false);
  const [isSupportShowing, setIsSupportShowing] = React.useState(false);
  const [selectedArticle, setSelectedArticle] = React.useState<Article | null>(null);

  React.useEffect(() => {
    setStoreHelpPanelWidth(
      isArticleShowing || showSupportResources ? PanelArticleWidth : PanelMainWidth
    );
  }, [isArticleShowing, showSupportResources, setStoreHelpPanelWidth]);

  React.useEffect(() => {
    if (!isOpen) {
      setIsArticleShowing(false);
      setShowSupportResources(false);
    }
  }, [isOpen]);

  const getRenderNavigationContent = (onBack: () => void, showOpenNewWindowIcon?: boolean) => {
    const popoutProps = showOpenNewWindowIcon
      ? { article: selectedArticle, onPopout: () => setIsArticleShowing(false) }
      : undefined;
    return getNavigationContent(onBack, classes, t('Back'), popoutProps);
  };

  const getRenderFooterContent = () => (
    <HelpPanelFooter onSupportResourcesClick={() => setShowSupportResources(true)} />
  );

  const getPanelProps = (): IPanelProps => {
    const baseProps = {
      closeButtonAriaLabel: t('Close help panel'),
      focusTrapZoneProps: { disabled: true },
      isBlocking: false,
      isOpen,
      styles: {
        ...props.styles,
        main: classes.panelMain,
        contentInner: classes.panelContentInner,
        scrollableContent: classes.panelScrollableContent,
      },
      onDismiss: () => {
        setIsArticleShowing(false);
        setIsSupportShowing(false);
        onDismiss();
      },
    };

    if (isArticleShowing) {
      return {
        ...baseProps,
        closeButtonAriaLabel: t('Close article'),
        styles: {
          ...baseProps.styles,
          navigation: classes.panelNavigation,
          commands: classes.panelCommands,
          main: classes.panelMainForSelectedContent,
        },
        onRenderNavigationContent: getRenderNavigationContent(
          () => setIsArticleShowing(false),
          true
        ),
      };
    }

    if (showSupportResources) {
      return {
        ...baseProps,
        styles: {
          ...baseProps.styles,
          navigation: classes.panelNavigation,
          commands: classes.panelCommands,
          main: classes.panelMain,
        },
        onRenderNavigationContent: getRenderNavigationContent(() => setShowSupportResources(false)),
      };
    }

    if (isSupportShowing) {
      return {
        ...baseProps,
        closeButtonAriaLabel: t('Close'),
        styles: {
          ...baseProps.styles,
          navigation: classes.panelNavigation,
          commands: classes.panelCommands,
          main: classes.panelMainForSelectedContent,
        },
        onRenderNavigationContent: getRenderNavigationContent(() => {
          setShowSupportResources(true);
          setIsSupportShowing(false);
        }),
      };
    }

    return {
      ...baseProps,
      headerText: t('Help'),
      isFooterAtBottom: true,
      onRenderFooterContent: getRenderFooterContent,
      styles: {
        ...baseProps.styles,
        footerInner: classes.footerInner,
      },
    };
  };

  const getContentComponent = () => {
    if (isArticleShowing && selectedArticle) {
      return <HelpArticle appEnvironment={appEnvironment} article={selectedArticle} />;
    }

    if (showSupportResources) {
      return (
        <SupportResources
          onSupportClick={() => {
            setIsSupportShowing(true);
            setShowSupportResources(false);
          }}
        />
      );
    }

    if (isSupportShowing) {
      if (props.isGQLFlightEnabled) {
        return <SupportTicketPanelContentApollo />;
      }
      return <SupportTicketPanelContent />;
    }

    return (
      <HelpPanelArticleGroup
        appEnvironment={appEnvironment}
        contentType={contentType}
        onArticleClick={(article: Article) => {
          setSelectedArticle(article);
          setIsArticleShowing(true);
        }}
      />
    );
  };

  return <Panel {...getPanelProps()}>{getContentComponent()}</Panel>;
};

export const HelpPanelStyled = withStyles(panelStyles)(HelpPanelUnStyled);
export const HelpPanel = connector(HelpPanelStyled);
