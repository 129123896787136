import React from 'react';
import loggerService from 'services/logger-service';
import { DialogProps } from 'styles/DialogueProvider';
import { oc } from 'ts-optchain';

import { ViewAgreementErrorDialog } from './ViewAgreementErrorDialog';

/**
 * Handles the user experience when the call to get the agreement is successfull
 * - Opens new tab if URI is found
 * - Opens dialog if URI is not found
 */
export const openAgreementPreview = (
  dialogContext: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  // TODO: jepagan - verify type with gql
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
) => {
  const documentUrl = oc(data).getModernAgreementPreview.documentDisplayUri();
  if (documentUrl) {
    window.open(documentUrl);
  } else {
    dialogContext.openDialog({
      providedDialog: (
        <ViewAgreementErrorDialog dialogClose={dialogContext.closeDialog} isError={false} />
      ),
    });
  }
};

/**
 * Opens error dialog
 */
export const openAgreementPreviewError = (
  dialogContext: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  quoteId: string,
  customMessage?: string | JSX.Element
) => {
  dialogContext.openDialog({
    providedDialog: (
      <ViewAgreementErrorDialog
        customMessage={customMessage}
        dialogClose={dialogContext.closeDialog}
        isError={true}
      />
    ),
  });
  const error = customMessage
    ? new Error(`Unable to generate the agreement for quote ${quoteId} due to invalid termID`)
    : new Error(`Unable to generate the agreement for quote ${quoteId}`);
  loggerService.error({
    error,
  });
};
