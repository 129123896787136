import { ErrorMessage, Spinner, SpinnerSize, TextBodySmall, TextTitle } from 'components';
import {
  DetailsStyles,
  parseMessage,
  renderNotifications,
} from 'features-apollo/quote/components/DetailsPane';
import { AgreementTermLineItem } from 'features-apollo/quote/components/Lists/Rows/AgreementTermRow';
import { getDateContent } from 'features-apollo/quote/components/Lists/Rows/utils';
import { getAgreementSignatoryLabel } from 'features-apollo/quote/components/utils';
import { ModernAgreementParticipant, ModernAgreementType, Quote } from 'generated/graphql';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

export interface AgreementLineItemDetailsProps {
  quote: Quote;
}

type Props = AgreementLineItemDetailsProps & WithStyles<typeof DetailsStyles>;

const participantInfo = (
  participants: (ModernAgreementParticipant | null | undefined)[],
  isCustomer: boolean,
  classes: Record<string, string>,
  t: i18next.TFunction
) => {
  const dateDisplay = isCustomer ? t('quote::Viewed on') : t('quote::Signed on');
  const renderParticipants = participants.map(
    (participant: ModernAgreementParticipant | null | undefined, key: number) => {
      return (
        participant && (
          <div className={key ? classes.description : undefined} key={key}>
            <TextBodySmall
              addClass={classes.participantInfo}
              title={`${participant.firstName} ${participant.lastName}`}
            >
              {participant.firstName} {participant.lastName}
            </TextBodySmall>
            <TextBodySmall
              addClass={classes.participantInfo}
              title={participant.email || undefined}
            >
              {participant.email}
            </TextBodySmall>
            <TextBodySmall addClass={classes.participantInfo}>
              {dateDisplay} {getDateContent(new Date(participant.statusDate || ''))}
            </TextBodySmall>
          </div>
        )
      );
    }
  );
  return <div className={classes.participantField}>{renderParticipants}</div>;
};

const displayParticipant = (
  participants: (ModernAgreementParticipant | null | undefined)[],
  participantLabel: string,
  isCustomer: boolean,
  classes: Record<string, string>,
  t: i18next.TFunction
) => {
  if (participants && participants.length) {
    return (
      <div className={classes.participantSection}>
        <TextBodySmall addClass={classes.participantLabel}>{participantLabel}</TextBodySmall>
        {participantInfo(participants, isCustomer, classes, t)}
      </div>
    );
  }
};

const displayParticipants = (
  participants: (ModernAgreementParticipant | null | undefined)[] | null | undefined,
  classes: Record<string, string>,
  t: i18next.TFunction
) => {
  if (participants && participants.length) {
    const partnerLabel = `${getAgreementSignatoryLabel(ModernAgreementType.Partner)}:`;
    const customerLabel = `${getAgreementSignatoryLabel(ModernAgreementType.Customer)}:`;
    const microsoftLabel = `${getAgreementSignatoryLabel(ModernAgreementType.Microsoft)}:`;

    const partners = participants.filter(
      participant => participant && participant.type === ModernAgreementType.Partner
    );
    const customers = participants.filter(
      participant => participant && participant.type === ModernAgreementType.Customer
    );
    const microsofts = participants.filter(
      participant => participant && participant.type === ModernAgreementType.Microsoft
    );
    return (
      <div>
        {displayParticipant(partners, partnerLabel, false, classes, t)}
        {displayParticipant(customers, customerLabel, true, classes, t)}
        {displayParticipant(microsofts, microsoftLabel, false, classes, t)}
      </div>
    );
  }
};

export const AgreementLineItemDetailsUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { quote, classes } = props;
  const { t } = useTranslation();

  let AgreementLineItemDetails = <Spinner size={SpinnerSize.large} />;
  let agreementDetails: Partial<AgreementTermLineItem> = {};

  oc(quote)
    .agreements.edges([])
    .forEach(edge => {
      const agreement = edge.node;
      agreementDetails = {
        startDate: agreement.startEffectiveDate,
        endDate: agreement.endEffectiveDate,
        documentDisplayUri: agreement.documentDisplayUri,
        customerId: agreement.customerId,
        documentFileName: agreement.documentFileName,
        participants: agreement.participants,
      };
    });

  if (agreementDetails) {
    const { startDate, endDate, participants } = agreementDetails;
    const heading = (
      <div>
        <TextTitle addClass={classes.heading}>{t('quote::Upload agreement')}</TextTitle>
        <TextBodySmall addClass={classes.agreementDescription}>
          {t(
            'quote::For deals in which an agreement has been negotiated and signed offline, the document naming all of the participants needs to be uploaded. Currently, the line item needs to be fully configured with an uploaded agreement and start and end date otherwise the line item will be deleted whenever the quote is reloaded.'
          )}
        </TextBodySmall>
      </div>
    );
    const body = (
      <div className={classes.dateSection}>
        <div className={classes.dateSubsection}>
          <TextBodySmall addClass={classes.participantLabel}>{t('quote::Start:')}</TextBodySmall>
          <TextBodySmall addClass={classes.participantField}>
            {getDateContent(new Date(startDate || ''))}
          </TextBodySmall>
        </div>
        <div className={classes.dateSubsection}>
          <TextBodySmall addClass={classes.participantLabel}>{t('quote::End:')}</TextBodySmall>
          <TextBodySmall addClass={classes.participantField}>
            {getDateContent(new Date(endDate || ''), true)}
          </TextBodySmall>
        </div>
        {displayParticipants(participants, classes, t)}
      </div>
    );
    const messages = oc(quote)
      .agreements.edges([])
      .map(edge => edge.node.messages);
    const notifications =
      messages.length === 1 && messages[0].map(message => parseMessage(message));
    AgreementLineItemDetails = (
      <div>
        {heading}
        {body}
        {renderNotifications(notifications || [], classes, quote)}
      </div>
    );
  } else {
    AgreementLineItemDetails = (
      <>
        <ErrorMessage mainMessage={t('quote::Unable to load data')} />
      </>
    );
  }
  return AgreementLineItemDetails;
};

export const AgreementLineItemDetails = withStyles(DetailsStyles)(AgreementLineItemDetailsUnstyled);
