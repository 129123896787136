import { CloseIconButton, TextBodySmall, TextTitle } from 'components/ions';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import withStyles, { WithStyles } from 'react-jss';

import { sharedDialogStyles } from './Dialog.styles';

/** Must be used with an overlay to produce popup effect
 * YOU MUST SANITIZE bodyText upstream if it contains html - currently done at proposal load ***
 * @prop {string} id id tag for the outer most div
 * @prop {function} dialogClose function to execute when close button is clicked.
 * @prop {string} imageUrl
 * @prop {string} headingText text to display in title
 * @prop {string} bodyText text to display in body of dialog
 * @prop {number} hight optional property to mnaullay set the height of the dialog box represented in pixels
 * @prop {number} width: optiona property to manually width of the dialog box represented in pixels
 */
export interface ImageDialogBoxProps {
  id?: string;
  dialogClose: () => void;
  headingText: string;
  bodyText: string;
  height?: number;
  width?: number;
  imageUrl?: string;
  actionLink?: React.ReactElement;
  dataAutomationId?: string;
}

type Props = ImageDialogBoxProps & WithStyles<typeof sharedDialogStyles>;

const renderIfPresent = (actionLinkClass: string, action?: React.ReactElement) => {
  if (action) {
    return (
      <span className={actionLinkClass}>
        <TextBodySmall>{action}</TextBodySmall>
      </span>
    );
  }
  return null;
};
const ImageDialogUnstyled: React.FC<Props> = (props: Props) => {
  const {
    id,
    dataAutomationId,
    actionLink,
    classes,
    imageUrl,
    dialogClose,
    headingText,
    bodyText,
  } = props;

  return (
    <div
      className={`${classes.contentWrapper} ${classes.size}`}
      data-automation-id={dataAutomationId}
      id={id}
    >
      <div className={classes.heading}>
        <div className={classes.imageContainer}>
          <img alt={headingText} className={classes.image} src={imageUrl} />
        </div>
        <div className={classes.title}>
          <span className={classes.imageTitle}>
            <TextTitle>{headingText}</TextTitle>
          </span>
          {renderIfPresent(classes.actionLink, actionLink)}
        </div>
        <div className={classes.closeButton}>
          <CloseIconButton ariaLabel="close dialog" onClick={dialogClose} />
        </div>
      </div>
      <div className={classes.contentBody}>
        <TextBodySmall>{ReactHtmlParser(bodyText)}</TextBodySmall>
      </div>
    </div>
  );
};

export const ImageDialogBox = withStyles(sharedDialogStyles)(ImageDialogUnstyled) as React.FC<
  ImageDialogBoxProps
>;
