import { ButtonAtom } from 'components/atoms';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { withdrawButtonStyles } from './WithdrawButton.styles';

type Props = ButtonSharedProps & WithStyles<typeof withdrawButtonStyles>;

const WithdrawButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <ButtonAtom
      addClass={props.classes.withdraw}
      ariaLabel={props.ariaLabel || props.text}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: 'Undo' }}
      id={props.id}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const WithdrawButton = withStyles(withdrawButtonStyles)(WithdrawButtonUnstyled) as React.FC<
  ButtonSharedProps
>;
