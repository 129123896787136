import { ThemeProps } from 'styles/theme';

export const styles = (theme: ThemeProps) => {
  const { fonts, palette } = theme;
  return {
    dateIcon: {
      display: 'block',
      transform: 'translateY(3.5px)',
    },
    dateIconBox: {
      border: `solid 1px ${palette.text}`,
      display: 'inline-block',
      fontSize: fonts.fontSizes.tiny,
      fontWeight: fonts.fontWeights.regular,
      height: 32,
      textAlign: 'center',
      transform: 'translateY(2px)',
      width: 32,
    },
    divider: {
      border: 'none',
      borderBottom: `solid 2px ${palette.textDisabled}`,
      margin: '40px auto',
    },
    paragraphs: {
      letterSpacing: 0.1,
    },
    sections: {
      margin: 0,
      paddingBottom: 10,
      paddingLeft: 48,
      width: 700,
    },
    sectionHeader: {
      display: 'inline-block',
      fontSize: 24,
      fontWeight: 'lighter',
      margin: 0,
      paddingLeft: 15,
      paddingBottom: 20,
    },
    subsections: {
      marginBottom: 30,
      marginRight: 30,
    },
    listSubsections: {
      marginBottom: 30,
      marginRight: 30,
      marginTop: 15,
    },
    listSubsectionTitle: {
      fontWeight: 600,
    },
    subsectionHeader: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 14,
      marginTop: 0,
    },
    videoPlayer: {
      marginTop: 20,
      marginBottom: 20,
    },
    workItem: {
      display: 'none',
    },
    scroll: {
      overflowY: 'scroll',
      display: 'flex',
      padding: '32px',
      flexDirection: 'column',
    },
    loadMore: {
      display: 'block',
      margin: '0 auto',
      marginBottom: 20,
    },
    showMoreLinks: {
      marginTop: '0px',
      marginBottom: '20px',
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'block',
    },
    image: {
      width: 596,
    },
    videoTitle: {
      fontSize: theme.fonts.fontSizes.xLarge,
      fontWeight: fonts.fontWeights.regular,
      margin: 0,
      paddingBottom: 20,
    },
  };
};
