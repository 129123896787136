import {
  DELETE_PREFERENCES,
  GET_USERPREFERENCES,
  UPDATE_THEME,
} from 'features-apollo/user/queries';
import {
  MutationDeletePreferencesData,
  MutationUpdateThemeData,
  QueryGetUserPreferencesData,
} from 'features-apollo/user/types';
import { deleteUserPreferencesAsync, updateUserPreferencesAsync } from 'features/user/actions';
import { MutationUpdateThemeArgs, PreferredTheme } from 'generated/graphql';
import React from 'react';
import { connect } from 'react-redux';
import { api } from 'services';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { NavBar } from './NavBar';

const dispatchProps = {
  deleteUserPreferencesAsync: deleteUserPreferencesAsync.success,
  updateUserPreferencesAsync: updateUserPreferencesAsync.success,
};

type Props = typeof dispatchProps;

const NavBarContainerDisconnected: React.FC<Props> = props => {
  // --- Local State
  const [theme, setTheme] = React.useState<PreferredTheme>(PreferredTheme.Light);

  // --- Queries
  useQuery<QueryGetUserPreferencesData>(GET_USERPREFERENCES, {
    onCompleted: data => setTheme(data.getUserPreferences.theme),
  });

  const [updateTheme] = useMutation<MutationUpdateThemeData, MutationUpdateThemeArgs>(
    UPDATE_THEME,
    {
      onCompleted: data => {
        const { theme } = data.updateTheme;
        setTheme(theme);
        const localPreferences = api.userPreferences.getLocalPreferences();
        const preferences = { ...localPreferences, theme };

        api.userPreferences.setLocalPreferences(preferences);
        props.updateUserPreferencesAsync(preferences);
      },
    }
  );

  const [deletePreferences] = useMutation<MutationDeletePreferencesData>(DELETE_PREFERENCES, {
    onCompleted: data => {
      if (data && data.deletePreferences) {
        props.deleteUserPreferencesAsync();
        setTheme(PreferredTheme.Light);
      }
    },
  });

  // --- Event handlers
  const onUpdateThemePreference = (theme: PreferredTheme) => updateTheme({ variables: { theme } });

  return (
    <NavBar
      userThemePreference={theme}
      onDeleteUserPreferences={deletePreferences}
      onUpdateThemePreference={onUpdateThemePreference}
    />
  );
};

export const NavBarContainer = connect(null, dispatchProps)(NavBarContainerDisconnected);
