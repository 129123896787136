import { combineReducers } from 'redux';
import { Flight } from 'services/flights/flightList';
import { getInitialFlights } from 'services/flights/utils';
import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './actions';
import { getConfigByEnvironment } from './config/configurations';
import { AppConfig, Config } from './config/type';
import { HelpContent } from './types';

// One place to handle all loading flags and errors.  See: https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
const reducer = combineReducers({
  appConfig: createReducer<AppConfig, ActionType<typeof actions>>(
    getConfigByEnvironment()
  ).handleAction(actions.updateServiceConfig, (state, action) => action.payload),
  /**
   * Application level configuration will go here. All these configuration would be loaded to Redux store.
   * Please add only those values that are configurable in nature and shared among components.
   * NOTE - Before update please ensure update will not break any consumer.
   */
  config: createReducer<Config, ActionType<typeof actions>>({
    approvalHierarchyLevel: 'Country',
    proposalNextExpiryDays: 60,
    suppressedMessages: [
      '2200',
      '2201',
      '2206',
      '2208',
      '2407',
      '2438',
      '2439',
      '2468',
      '2474',
      '2601',
      '2605',
      '2629',
      '2630',
      '2632',
      '2634',
    ],
    minCreditLineDivisor: 4,
    // This is the regex for whole number to validate also blank is valid whole number. Example: 123456, 1452.253 are valid.
    wholeNumberRegex: /^(\s*|\d+)$/,
  }).handleAction(actions.updateConfig, (state, action) => ({ ...state, ...action.payload })),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: (state: any = {}, action: any) => {
    const { type, payload } = action;
    const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

    // not a *_REQUEST / *_FAILURE actions, so we ignore them
    if (!matches) return state;

    const [, requestName, requestState] = matches;
    return {
      ...state,
      // Store errorMessage
      // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
      //      else clear errorMessage when receiving GET_TODOS_REQUEST
      [requestName]: requestState === 'FAILURE' ? payload : '',
    };
  },

  loading: (state: Record<string, boolean> = {}, action) => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

    // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
    if (!matches) return state;

    const [, requestName, requestState] = matches;
    return {
      ...state,
      // Store whether a request is happening at the moment or not
      // e.g. will be true when receiving GET_TODOS_REQUEST
      //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
      [requestName]: requestState === 'REQUEST',
    };
  },
  flights: createReducer<Record<Flight, boolean>, ActionType<typeof actions>>(getInitialFlights())
    .handleAction(actions.setFlightValue, (state, action) => {
      return { ...state, [action.payload.key]: action.payload.value };
    })
    .handleAction(actions.setFlights, (state, action) => {
      return { ...state, ...action.payload };
    }),
  isCachedFlightsEnabled: createReducer<boolean, ActionType<typeof actions>>(true).handleAction(
    actions.setCacheFlights,
    (state, action) => action.payload
  ),
  helpContentType: createReducer<HelpContent, ActionType<typeof actions>>(
    HelpContent.Default
  ).handleAction(actions.setHelpContentType, (state, action) => action.payload),
  isHelpPanelOpen: createReducer<boolean, ActionType<typeof actions>>(false).handleAction(
    actions.setIsHelpPanelOpen,
    (state, action) => action.payload
  ),
  helpPanelWidth: createReducer<number, ActionType<typeof actions>>(0).handleAction(
    actions.setHelpPanelWidth,
    (state, action) => action.payload
  ),
});

export default reducer;
