import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { videoPlayerStyles } from './VideoPlayer.styles';

/**
 * Props for Video Player Component
 *
 * @prop {ClassNameMap} [classes] - styles use in the component
 * @prop {string} videoUrl
 * @prop {string} videoDescription
 * @prop {string} videoTitle
 */

export interface VideoPlayerProps {
  videoTitle?: string;
  videoUrl: string;
  videoDescription?: string;
}

type Props = VideoPlayerProps & WithStyles<typeof videoPlayerStyles>;

const VideoPlayerBase: React.FC<Props> = props => {
  const { classes, videoTitle, videoDescription, videoUrl } = props;
  const titleRender = videoTitle && <h1 className={classes.videoTitle}>{videoTitle}</h1>;
  const descriptionRender = videoDescription && (
    <p className={classes.videoDescription}>{props.videoDescription}</p>
  );
  return (
    <div>
      {titleRender}
      <iframe allowFullScreen={true} className={classes.video} src={videoUrl} title={videoTitle} />
      {descriptionRender}
    </div>
  );
};

export const VideoPlayer = withStyles(videoPlayerStyles)(VideoPlayerBase) as React.FC<
  VideoPlayerProps
>;
