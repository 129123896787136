import { TestHeaderConfig } from 'services/types';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
  Insider = 'insider',
}

export interface ApolloConfig extends TestHeaderConfig {
  environment: Environment;
  useEnvironmentOverrides: boolean;
}

export const endpoints = {
  [Environment.Prod]: 'https://quotecenterbackend.l2o.microsoft.com/graphql',
  [Environment.Int]: 'https://quotecenterbackend.int.l2o.microsoft.com/graphql',
  [Environment.Insider]: 'https://quotecenterinsiderbackend.l2o.microsoft.com/graphql',
};

export const environmentOverrides = {
  [Environment.Prod]: {},
  //todo: ignoring user pref, LDSS until GQL is ready. Agreements isn't working in int will be enabled once that is resolved
  [Environment.Int]: {
    quoteenvironment: 'int',
    approvalenvironment: 'int',
    empowermentenvironment: 'int',
    oneaskenvironment: 'int',
    bd360environment: 'int',
  },
  [Environment.Insider]: {},
};
