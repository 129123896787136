import { SectionSeparator, SmallIcon, TextBody, TextBodySmall } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => {
  return {
    separatorSpacing: {
      marginTop: 24,
      marginBottom: 32,
    },
    reasonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    reasonPadding: {
      paddingBottom: 12,
      width: 'max-content',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 12,
      '& >span': {
        display: 'flex',
      },
    },
    reasonText: {
      color: theme.palette.textTertiary,
      textAlign: 'start',
    },
    iconColumn: {
      paddingRight: 8,
      paddingTop: 1,
      '& >i': {
        color: theme.palette.dangerText,
      },
    },
  };
};

export interface FailReasonBodyProps {
  failedActionTitle: string;
  failReasons: string[];
}

type BodyProps = FailReasonBodyProps & WithStyles<typeof styles>;

const FailReasonBodyUnstyled: React.FC<BodyProps> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const [reasonWidth, setReasonWidth] = React.useState<number | undefined>(undefined);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      setReasonWidth(ref.current.offsetWidth);
    }
  }, []);

  return (
    <div>
      <TextBody>{props.failedActionTitle}</TextBody>
      <SectionSeparator addClass={classes.separatorSpacing} />
      <div className={classes.reasonContainer}>
        <div className={classes.reasonPadding} ref={ref}>
          <TextBody>
            {t('We found the following reason for the failure:', {
              count: props.failReasons.length,
              // eslint-disable-next-line @typescript-eslint/camelcase
              defaultValue_plural: 'We found the following reasons for the failure:',
            })}
          </TextBody>
        </div>
        <div className={classes.listContainer} style={{ width: reasonWidth }}>
          {props.failReasons.map((reason, index) => (
            <div className={classes.row} key={index}>
              <TextBodySmall addClass={classes.reasonText}>
                <span className={classes.iconColumn}>
                  <SmallIcon iconName="ErrorBadge" />
                </span>
                {reason}
              </TextBodySmall>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const FailReasonBody = withStyles(styles)(FailReasonBodyUnstyled);
