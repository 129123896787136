import { Calendar as FabricCalendar, ICalendarStrings } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { calloutCalendarStyles } from './Calendar.styles';
import { LinkButton } from 'components/ions';

export interface CalendarProps {
  strings: ICalendarStrings;
  defaultDate: Date;
  onSelectDate: (date: Date, selectedDateRangeArray?: Date[]) => void;
  onSelectEvergreen?: () => void;
  isDayPickerVisible: boolean;
  showGoToToday?: boolean;
  showEvergreen?: boolean;
  yearPickerVisible?: boolean;
  minDate?: Date;
  maxDate?: Date;
  today?: Date;
}

type Props = CalendarProps & WithStyles<typeof calloutCalendarStyles>;

const CalendarUnstyled: React.FC<Props> = (props: Props) => {
  const [selectedDate, setSelectedDate] = React.useState(props.defaultDate);
  const onSelectDate = (date: Date, selectedDateRangeArray?: Date[]) => {
    props.onSelectDate(date, selectedDateRangeArray);
    setSelectedDate(date);
  };
  const mergedClass = [
    props.classes.goToToday,
    props.classes.days,
    props.classes.yearPicker,
    props.classes.selectedDay,
    props.classes.selectedYear,
    props.classes.todayDate,
    props.classes.todayIsSelected,
    props.classes.monthPicker,
    props.classes.arrows,
  ].join(' ');
  const evergreenDisplay = (
    <LinkButton
      addClass={props.classes.evergreen}
      dataAutomationId="evergreen-button"
      displayText="Evergreen"
      onClick={props.onSelectEvergreen}
    />
  );
  return (
    <div className={props.classes.container} data-automation-id="calendar-container">
      <FabricCalendar
        className={mergedClass}
        highlightSelectedMonth
        isDayPickerVisible={props.isDayPickerVisible}
        maxDate={props.maxDate}
        minDate={props.minDate}
        showGoToToday={props.showGoToToday}
        strings={props.strings}
        today={props.today}
        value={selectedDate}
        yearPickerHidden={!props.yearPickerVisible}
        onSelectDate={onSelectDate}
      />
      {props.showEvergreen && evergreenDisplay}
    </div>
  );
};

export const Calendar = withStyles(calloutCalendarStyles)(CalendarUnstyled) as React.FC<
  CalendarProps
>;
