import { ThemeProps } from 'styles';

export const detailsListStyles = (theme: ThemeProps) => {
  const { palette, fonts } = theme;
  return {
    cellName: {
      fontWeight: fonts.fontWeights.regular,
    },
    listStyles: {
      '& .ms-DetailsHeader': {
        background: palette.backgroundStandout,
      },
      '& .ms-DetailsHeader-cellName': {
        overflow: 'visible',
        lineHeight: 1.5,
        whiteSpace: 'normal',
      },
      '& .ms-Check-circle': {
        color: palette.text,
        fontSize: fonts.fontSizes.larger,
        background: palette.backgroundStandout,
      },
      '& .ms-DetailsRow:hover': {
        '& .ms-Check-circle': {
          background: palette.transparent,
        },
      },
      '& .is-selected.ms-DetailsRow:hover': {
        '& .ms-Check-circle': {
          background: palette.secondary,
        },
      },
      '& .ms-DetailsRow-cell': {
        fontSize: fonts.fontSizes.medium,
        whiteSpace: 'normal',
      },
      '& .ms-DetailsRow-cell:focus': {
        color: palette.lineItemHighlight,
        '&:hover': {
          color: palette.lineItemHighlight,
        },
      },
      '& .is-selected': {
        background: palette.secondary,
        color: palette.backgroundStandout,
        '& .ms-Check-circle': {
          color: palette.backgroundStandout,
          background: palette.secondary,
        },
      },
      '& .is-selected .ms-DetailsRow-cell': {
        color: palette.backgroundStandout,
      },
      '& .is-selected .ms-Check-check': {
        color: palette.backgroundStandout,
        '&:hover': {
          color: palette.backgroundStandout,
        },
      },
      '& .ms-Check-check': {
        color: palette.lineItemHighlight,
      },
      '& .ms-Check::before': {
        background: palette.transparent,
      },
      '& .ms-Check-check:hover': {
        color: palette.text,
      },
    },
    groupedListStyles: {
      '& .ms-GroupHeader': {
        backgroundColor: palette.backgroundCommon,
        border: 'none',
      },
      '& .ms-GroupHeader-title': {
        '& span:first-child': {
          fontWeight: theme.fonts.fontWeights.bold,
        },
        '& span:last-child': {
          display: 'none',
        },
      },
    },
    listCellStyles: {
      '& .ms-FocusZone .ms-DetailsRow': {
        border: `1px solid ${theme.palette.backgroundDivider}`,
      },
    },
    detailsRowRoot: {
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    },
  };
};
