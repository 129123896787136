import React from 'react';
import store from 'store';

import { setHelpContentType } from './actions';
import { HelpContent } from './types';

export const useHelpContent = (type: HelpContent) => {
  React.useEffect(() => {
    store.dispatch(setHelpContentType(type));
    return () => {
      store.dispatch(setHelpContentType(HelpContent.Default));
    };
  }, [type]);
};
