import { ThemeProps } from 'styles/theme';

export const styles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    scroll: {
      overflowY: 'scroll',
      display: 'flex',
      padding: '32px',
      flexDirection: 'column',
      flexGrow: '1',
    },
    numberBox: {
      backgroundColor: palette.primary,
      color: 'white',
      display: 'inline-block',
      fontSize: '21px',
      height: '28px',
      textAlign: 'center',
      width: '28px',
    },
    number: {
      margin: 0,
      transform: 'translateY(-1px)',
    },
    header: {
      display: 'inline-block',
      fontSize: 24,
      fontWeight: 'lighter',
      margin: 0,
      paddingLeft: 15,
      paddingBottom: 20,
    },
    instructions: {
      paddingBottom: 40,
      paddingLeft: 44,
      width: 591,
      margin: 0,
    },
    instructionContent: {
      marginTop: 0,
      marginBottom: 4,
    },
    instructionContentTop: {
      marginTop: 0,
    },
    link: {
      color: palette.textLink,
      '&:hover': {
        color: palette.textLinkHover,
      },
    },
    icon: {
      paddingLeft: 3,
      paddingRight: 3,
    },
    image: {
      width: 596,
    },
    videoTitle: {
      fontSize: theme.fonts.fontSizes.xLarge,
      fontWeight: theme.fonts.fontWeights.regular,
      margin: 0,
      paddingBottom: 20,
    },
  };
};
