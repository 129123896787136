import * as actions from 'features/customer/actions';
import { call, takeEvery } from 'redux-saga/effects';
import loggerService from 'services/logger-service';
import { getType } from 'typesafe-actions';

import { loadProjectFromEnrollment } from './auxiliary';

export function* searchEnrollmentNumber() {
  const relevantAction = actions.searchEnrollmentNumber;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      yield call(loadProjectFromEnrollment, action.payload);
    } catch (err) {
      loggerService.error(err);
    }
  });
}
