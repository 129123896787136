import { ButtonMenuProps, IconMenuButton } from 'components';
import { openFindOrganizationDialog } from 'features/proposal/components/Dialogs';
import {
  openOrganizationWizardDialog,
  OrganizationWizardFlow,
  OrganizationWizardView,
} from 'features/proposal/components/Wizards';
import { ContextualMenuItemType } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';

interface Props {
  addClass?: string;
  disabled?: boolean;
  organizationEditable?: boolean;
}

export const CustomerCommandMenu: React.FC<Props> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const editOrganization = {
    key: 'edit-billing-account',
    icon: 'AccountManagement',
    onClick: () => {
      openOrganizationWizardDialog(context, { flow: OrganizationWizardFlow.Edit });
    },
    text: t('quote::Edit billing account'),
    dataAutomationId: 'editBillingAccount',
  };

  const addNewOrganization = {
    key: 'add-new-billing-account',
    icon: 'AddFriend',
    onClick: () => {
      openOrganizationWizardDialog(context, {
        flow: OrganizationWizardFlow.CreateOrganization,
        initialView: OrganizationWizardView.Organization,
      });
    },
    text: t('quote::Add new billing account'),
    dataAutomationId: 'addNewBillingAccount',
  };

  const selectOrganization = {
    key: 'select-billing-account',
    icon: 'ProfileSearch',
    onClick: () => {
      openFindOrganizationDialog(context);
    },
    text: t('quote::Select existing billing account'),
    dataAutomationId: 'selectExistingBillingAccount',
  };

  const createOrganizationAndAccount = {
    key: 'create-billing-account-and-account',
    icon: 'Org',
    onClick: () => {
      openOrganizationWizardDialog(context, {
        flow: OrganizationWizardFlow.CreateAccountAndOrganization,
        initialView: OrganizationWizardView.SearchAccount,
      });
    },
    text: t('quote::Create new billing account and account'),
    dataAutomationId: 'createNewBillingAccount',
  };

  const divider = {
    key: 'edit-billing-account-header',
    text: 'Edit billing account',
    itemType: ContextualMenuItemType.Divider,
  };

  const buttons: ButtonMenuProps[] = props.organizationEditable ? [editOrganization] : [];
  buttons.push(addNewOrganization, divider, selectOrganization, createOrganizationAndAccount);

  return (
    <IconMenuButton
      addClass={props.addClass}
      dataAutomationId="customerCommandMenu"
      disabled={props.disabled}
      iconName="More"
      menuId="customer-menu"
      menuProps={buttons}
    />
  );
};
