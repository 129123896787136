import {
  AddButton,
  CalloutCalendar,
  LinkButton,
  TextBodyAsterisk,
  TextBodySmall,
  TextboxStandard,
} from 'components/ions';
import { calendarStrings } from 'components/utilities/calendarStrings';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { Signatory, SignatoryField } from 'features-apollo/quote/components';
import { getAgreementSignatoryLabel } from 'features-apollo/quote/components/utils';
import { validateEmailFormat } from 'features-apollo/quote/utils';
import { ModernAgreementType } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import { styles } from './AgreementSignatory.styles';

export interface AgreementSignatoryProps {
  signatories: Signatory[];
  emailMap: Record<ModernAgreementType, (string | undefined | null)[]>;
  readOnly?: boolean;
  /**
   * Identifies the type of signatory in agreement services
   */
  signatoryType: ModernAgreementType;
  updateSignatories: React.Dispatch<React.SetStateAction<Signatory[]>>;
  setEmailMap: React.Dispatch<
    React.SetStateAction<Record<ModernAgreementType, (string | undefined | null)[]>>
  >;
  isFieldRequired: (
    signatory: Signatory | undefined,
    signatoryType: ModernAgreementType,
    fieldName: SignatoryField
  ) => boolean;
}

type Props = AgreementSignatoryProps & WithStyles<typeof styles>;

const AgreementSignatoryUnstyled: React.FC<Props> = (props: Props) => {
  const {
    signatoryType,
    signatories,
    emailMap,
    setEmailMap,
    readOnly,
    updateSignatories,
    classes,
    isFieldRequired,
  } = props;
  const { t } = useTranslation();

  // #region signatory list mutations
  const removeSignatory = () => {
    if (signatories.length > 1) {
      updateSignatories([signatories[0]]);
      const newEmails: Record<ModernAgreementType, (string | null | undefined)[]> = {
        ...emailMap,
        [signatoryType]: [emailMap[signatoryType][0], ''],
      };
      setEmailMap(newEmails);
    }
  };
  const addSignatory = () => {
    const newEmptySignatory: Signatory = {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      statusDate: null,
      type: signatoryType,
    };
    updateSignatories(
      signatories.concat(
        signatories.length ? [newEmptySignatory] : [newEmptySignatory, newEmptySignatory]
      )
    );
  };

  const updateSignatory = (newCurrentSignatories: Signatory[], index: number) => {
    if (signatories.length > 1 && index === 1) {
      updateSignatories([signatories[0]].concat(newCurrentSignatories));
    } else if (signatories.length > 1 && index === 0) {
      updateSignatories(newCurrentSignatories.concat([signatories[1]]));
    } else {
      updateSignatories(newCurrentSignatories);
    }
  };

  const updateSignatoryField = (
    value: string,
    key: SignatoryField,
    index: number,
    isNew: boolean
  ) => {
    const updatedSignatory: Signatory = isNew
      ? {
          id: null,
          firstName: null,
          lastName: null,
          email: null,
          statusDate: null,
          type: signatoryType,
        }
      : {
          id: signatories[index].id,
          firstName: signatories[index].firstName,
          lastName: signatories[index].lastName,
          email: signatories[index].email,
          statusDate: signatories[index].statusDate,
          type: signatoryType,
        };
    updatedSignatory[key] = value;
    updateSignatory([updatedSignatory], index);
  };
  // #endregion

  const removeSignatoryButton = (
    <LinkButton
      addClass={classes.removeSignatory}
      dataAutomationId="remove"
      disabled={readOnly}
      displayText={t('remove')}
      title={t('remove')}
      onClick={() => {
        removeSignatory();
      }}
    />
  );

  const addSignatoryButton = (
    <AddButton
      addClass={classes.addAdditionalSignatory}
      ariaLabel={t('quote::Add additional signatory')}
      dataAutomationId="Add additional signatory"
      disabled={readOnly}
      id="AddSignatory"
      predefinedIconName="AddTo"
      text={t('quote::Add additional signatory')}
      onClick={() => {
        addSignatory();
      }}
    />
  );

  const signatoryInfo = (index: number, currentSignatory?: Signatory) => {
    const email = emailMap[signatoryType][index];

    // #region email validation
    const onChangeEmail = (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      updateSignatoryField(value || '', 'email', index, !signatories.length);

      const newEmails = emailMap[signatoryType].map(
        (email: string | undefined | null, emailIndex: number) => {
          if (emailIndex === index) {
            return value || '';
          }
          return email;
        }
      );
      setEmailMap({ ...emailMap, [signatoryType]: newEmails });
    };
    // #endregion

    return (
      <div className={classes.signatorySection}>
        <TextBodySmall addClass={classes.signatoryHeading}>
          {getAgreementSignatoryLabel(signatoryType, index + 1)}
        </TextBodySmall>
        {index === 1 && removeSignatoryButton}
        <div className={classes.signatoryInfoSection}>
          <div className={classes.textInputContainer}>
            <TextboxStandard
              ariaLabel={t('quote::First name input')}
              autoFocus={!readOnly}
              dataAutomationId="AgreementSignatoryCardEnterFirstName"
              disabled={readOnly}
              label={t('quote::First name')}
              placeholder={t('quote::Enter first name')}
              required={isFieldRequired(currentSignatory, signatoryType, 'firstName')}
              value={oc(currentSignatory).firstName('')}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                value?: string
              ) => {
                updateSignatoryField(value || '', 'firstName', index, !signatories.length);
              }}
            />
          </div>
          <div className={classes.textInputContainer}>
            <TextboxStandard
              ariaLabel={t('quote::Last name input')}
              dataAutomationId="AgreementSignatoryCardEnterLastName"
              disabled={readOnly}
              label={t('quote::Last name')}
              placeholder={t('quote::Enter last name')}
              required={isFieldRequired(currentSignatory, signatoryType, 'lastName')}
              value={oc(currentSignatory).lastName('')}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                value?: string
              ) => {
                updateSignatoryField(value || '', 'lastName', index, !signatories.length);
              }}
            />
          </div>
        </div>
        <div className={classes.signatoryInfoSection}>
          <div className={classes.textInputContainerEmail}>
            <TextboxStandard
              dataAutomationId="AgreementSignatoryCardEnterEmail"
              disabled={readOnly}
              errorMessage={
                email && !validateEmailFormat(email, true)
                  ? t('error::Enter valid email address.')
                  : ''
              }
              label={t('quote::Email address')}
              placeholder={t('quote::Enter email address')}
              required={isFieldRequired(currentSignatory, signatoryType, 'email')}
              value={emailMap[signatoryType][index] || ''}
              onChange={onChangeEmail}
            />
          </div>
          <div className={classes.textInputContainer}>
            <div className={classes.dateSigned}>
              <TextBodyAsterisk
                addClass={classes.dateSignedText}
                required={isFieldRequired(currentSignatory, signatoryType, 'statusDate')}
              >
                {signatoryType === ModernAgreementType.Customer
                  ? t('quote::Agreement view date')
                  : t('quote::Date signed')}
              </TextBodyAsterisk>
              {signatoryType === ModernAgreementType.Microsoft && currentSignatory?.statusDate && (
                <LinkButton
                  dataAutomationId="ms-signatory-clear"
                  displayText="clear"
                  title="clear"
                  onClick={() => updateSignatoryField('', 'statusDate', index, !signatories.length)}
                />
              )}
            </div>
            <div
              className={
                currentSignatory && currentSignatory.statusDate
                  ? classes.calendar
                  : classes.calendarNoDate
              }
            >
              <CalloutCalendar
                buttonProps={{
                  text:
                    currentSignatory && currentSignatory.statusDate
                      ? convertDateToFormattedString(
                          new Date(currentSignatory.statusDate),
                          LocaleDateFormat.ll
                        )
                      : t('quote::select date...'),
                  disabled: readOnly,
                }}
                calendarStrings={calendarStrings}
                dataAutomationId="AgreementSignatoryCardDateSigned"
                defaultDate={new Date()}
                isDayPickerVisible
                isMinDateUnset={true}
                showTodayButton={true}
                today={new Date()}
                onSelectDate={(date: Date) => {
                  updateSignatoryField(
                    convertDateToFormattedString(date, LocaleDateFormat.ll),
                    'statusDate',
                    index,
                    !signatories.length
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const firstSignatory = signatoryInfo(0, (signatories.length > 0 && signatories[0]) || undefined);
  const secondSignatory = signatoryInfo(1, (signatories.length > 1 && signatories[1]) || undefined);

  return (
    <div>
      {firstSignatory}
      {signatories.length > 1 ? secondSignatory : addSignatoryButton}
    </div>
  );
};

export const AgreementSignatory = withStyles(styles)(AgreementSignatoryUnstyled);
