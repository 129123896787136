import { Fail, Processing } from 'features/components/dialogs';
import {
  getSearchOrganizationDialogResults,
  selectOrganizationError,
  selectOrganizationLoading,
} from 'features/customer/selectors';
import { selectOrganizationAsync } from 'features/proposal/actions';
import { getOrganizationId } from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { FindOrganizationView, OrganizationInformation } from './FindOrganizationView';

const mapStateToProps = (state: RootState) => {
  return {
    selectOrganizationIsLoading: selectOrganizationLoading(state),
    selectOrganizationHasError: selectOrganizationError(state),
    savedOrganizationId: getOrganizationId(state),
    items: getSearchOrganizationDialogResults(state),
  };
};

export const dialogDimensions = {
  width: 450,
  height: 360,
};

const dispatchProps = {
  onOrganizationSelect: (values: OrganizationInformation) => {
    return selectOrganizationAsync.request({
      accountId: values.accountId,
      organizationId: values.organizationId,
    });
  },
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const FindOrganizationDialogNotConnected: React.FC<Props> = props => {
  const [organizationInfo, setOrganizationInfo] = React.useState<
    OrganizationInformation | undefined
  >();
  const { t } = useTranslation();
  const [applyPressed, setApplyPressed] = React.useState(false);
  const context = React.useContext(DialogContext);

  const selectedOrganizationIsSame =
    organizationInfo && props.savedOrganizationId === organizationInfo.organizationId;
  if (applyPressed && selectedOrganizationIsSame) {
    context.closeDialog();
  }

  const onApply = (organizationValuesToSave: OrganizationInformation) => {
    const organizationWillChange =
      organizationValuesToSave.organizationId !== props.savedOrganizationId;
    if (organizationWillChange) {
      props.onOrganizationSelect(organizationValuesToSave);
    }
    setOrganizationInfo(organizationValuesToSave);
    setApplyPressed(true);
  };
  const getCurrentView = () => {
    if (applyPressed) {
      if (props.selectOrganizationHasError && !props.selectOrganizationIsLoading) {
        return (
          <Fail
            {...dialogDimensions}
            closeDialog={context.closeDialog}
            dataAutomationId="selectOrganizationFail"
            message={t('quote::Sorry, the "Select" action failed.')}
            onTryAgainClick={() => {
              organizationInfo && props.onOrganizationSelect(organizationInfo);
            }}
          />
        );
      } else {
        return (
          <Processing {...dialogDimensions} message1={t('quote::Selecting the billing account')} />
        );
      }
    }
    return <FindOrganizationView onApply={onApply} />;
  };
  return getCurrentView();
};

const FindOrganizationDialog = connect(
  mapStateToProps,
  dispatchProps
)(FindOrganizationDialogNotConnected);

export const openFindOrganizationDialog = (context: {
  openDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}) => {
  const dialogProps: DialogProps = {
    providedDialog: <FindOrganizationDialog />,
  };
  context.openDialog(dialogProps);
};
