import { all, call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';
import {
  loadFlights,
  loadLDSSReadPermission,
  loadUserPermissions,
  loadUserPhoto,
  loadUserPreferences,
  loadUserRoles,
} from './auxiliary';

export function* userChange() {
  const relevantAction = actions.setUser;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const email = action.payload.email;
    if (!email) {
      return;
    }
    yield all([
      call(loadUserPermissions, email),
      call(loadUserPhoto, email),
      call(loadFlights, email),
      call(loadUserRoles, email),
      call(loadUserPreferences, email),
      call(loadLDSSReadPermission),
    ]);
  });
}

export function* loginSuccess() {
  const relevantAction = actions.aadLoginSuccess;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    yield put(
      actions.setUser({
        ...api.user.loadUser(),
        name: action.payload.account.name,
        email: action.payload.account.userName,
        oid: action.payload.account.accountIdentifier,
        tid: action.payload.account.idToken.tid,
      })
    );
  });
}
