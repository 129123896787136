import { comboBoxAtomStyles } from 'components/atoms';
import { ThemeProps } from 'styles';

export const comboBoxStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    labelReadOnly: {
      color: palette.text,
    },
    inputReadOnly: {
      ...comboBoxAtomStyles(theme).inputDisabled,
      color: palette.text,
    },
  };
};
