import {
  ComboBox,
  InfoButton,
  Label,
  LinkButton,
  Spinner,
  StatusIndicatorApproved,
  TextBody,
  TextBodySmall,
} from 'components';
import { TextboxStandard } from 'components/ions';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { getFlightIsEnabled } from 'features/app/selectors';
import * as actions from 'features/proposal/actions';
import { EnterEnrollmentNumberDialog } from 'features/proposal/components/Dialogs/EnterEnrollmentNumberDialog/EnterEnrollmentNumberDialog';
import { getEnrollmentFieldView } from 'features/proposal/selectors/views';
import { sharedStyles } from 'features/proposal/shared.styles';
import { EnrollmentFieldView } from 'features/proposal/types';
import { flexSectionCreator } from 'features/proposal/utils';
import { CrmLead, EnrollmentSummary } from 'generated/graphql';
import {
  IComboBox,
  IComboBoxOption,
  IOnRenderComboBoxLabelProps,
  ISelectableOption,
  ITextFieldProps,
  KeyCodes,
  SelectableOptionMenuItemType,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { DialogContext, DialogProps, ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';

import {
  GET_QUOTE_SALES_INFO,
  GetValidEnrollments,
  UPDATE_ENROLLMENT_NUMBER,
  VALIDATE_ENROLLMENT_NUMBER_AND_REFETCH_QUOTE,
} from './queries';
import { salesStyles } from './Sales.styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles,
  ...salesStyles(theme),
});

export interface SalesFooterProps {
  isQuoteReadOnly: boolean;
  isLegacy: boolean;
  modernOfficeVersion?: boolean;
  crmLead?: CrmLead;
  quoteId: string;
  etag: string;
  enrollmentNumber?: string;
  /**
   * EnrollmentAssembly feature not available for indirect quotes
   */
  isQuoteIndirect?: boolean;
}

const mapStateToProps = (state: RootState, props: SalesFooterProps) => {
  return {
    enrollmentAssemblyEnabled:
      !props.isQuoteIndirect && getFlightIsEnabled(state, Flight.enrollmentAssembly),
    getEnrollmentFieldView: getEnrollmentFieldView(state),
  };
};

const dispatchProps = {
  setEnrollmentFieldView: actions.setEnrollmentFieldView,
};

type Props = SalesFooterProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const maxEnrollmentLength = 8;

interface QueryValidateEnrollmentNumberGetQuoteData extends QueryGetQuoteData {
  validateEnrollmentNumber: boolean;
}

const EnrollmentNumberFieldUnStyled: React.FC<Props> = props => {
  const { classes, quoteId, etag, isLegacy, isQuoteReadOnly, crmLead, enrollmentNumber } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const crmId = oc(crmLead).id() || '';
  const tpid = oc(crmLead).salesAccount.tpid() || '';
  const [cleanedEnrollmentNumber, setCleanedEnrollmentNumber] = React.useState(enrollmentNumber);
  const [displayErrorMessage, setDisplayErrorMessage] = React.useState(false);
  const [displayVerified, setDisplayVerified] = React.useState(false);
  // TODO: Condense to one useState when both can use GQL
  const [selectedEnrollmentNumber, setSelectedEnrollmentNumber] = React.useState<
    string | undefined
  >(enrollmentNumber);

  const [
    updateEnrollmentNumberGQL,
    { loading: updateLoading, data: updateEnrollmentNumberData },
  ] = useMutation(UPDATE_ENROLLMENT_NUMBER, {
    refetchQueries: () => [
      { query: GET_QUOTE_SALES_INFO, variables: { id: quoteId } },
      { query: GET_QUOTE, variables: { id: quoteId } },
    ],
    onCompleted: data => {
      setDisplayVerified(!!data);
    },
  });

  if (!crmId) {
    props.setEnrollmentFieldView(EnrollmentFieldView.ComboBox);
  }

  const [
    validateEnrollmentNumber,
    { loading: validateLoading, data: validationResponse },
  ] = useLazyQuery<QueryValidateEnrollmentNumberGetQuoteData>(
    VALIDATE_ENROLLMENT_NUMBER_AND_REFETCH_QUOTE,
    {
      onCompleted: data => {
        setDisplayErrorMessage(!data.validateEnrollmentNumber);
        if (data.validateEnrollmentNumber)
          updateEnrollmentNumberGQL({
            variables: {
              input: {
                id: quoteId,
                etag: etag,
              },
              vlAgreementNumberForMigration: cleanedEnrollmentNumber,
            },
          });
      },
    }
  );

  const isAzureBackedEnrollment = validationResponse && validationResponse.validateEnrollmentNumber;

  const generateEnrollmentOptions = (validAzureEnrollments: EnrollmentSummary[]) => {
    let enrollmentOptions: IComboBoxOption[] = [
      {
        key: '',
        text: t('quote::No enrollment'),
      },
      { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
    ];
    const validAzureEnrollmentsSorted = validAzureEnrollments.sort((enrollment1, enrollment2) => {
      return new Date(enrollment1.endDate).getTime() - new Date(enrollment2.endDate).getTime();
    });
    validAzureEnrollmentsSorted.forEach(enrollmentSummary => {
      if (enrollmentSummary.agreementNumber) {
        enrollmentOptions.push({
          key: enrollmentSummary.agreementNumber,
          text: enrollmentSummary.agreementNumber,
          data: {
            date: enrollmentSummary.endDate,
          },
        });
      }
    });
    enrollmentOptions.push({
      key: 'enter enrollment number',
      text: t('quote::enter enrollment number'),
    });
    return enrollmentOptions;
  };

  const { data } = useQuery<{ validAzureEnrollments: EnrollmentSummary[] }>(GetValidEnrollments, {
    variables: {
      tpid: tpid,
    },
    skip: !tpid,
  });

  const validAzureEnrollments = data ? data.validAzureEnrollments : [];
  const enrollmentOptions = generateEnrollmentOptions(validAzureEnrollments);

  const enrollmentNumberValidation = new RegExp('^[0-9]{7,8}$');
  const regExpValidateEnrollmentNumber = enrollmentNumberValidation.test(
    cleanedEnrollmentNumber || ''
  );
  React.useEffect(() => {
    setCleanedEnrollmentNumber(enrollmentNumber);
  }, [enrollmentNumber]);

  const updateEnrollmentNumber = (enrollmentNumber: string) => {
    if (
      props.enrollmentAssemblyEnabled &&
      props.getEnrollmentFieldView === EnrollmentFieldView.TextboxStandard &&
      enrollmentNumber.trim() !== ''
    ) {
      validateEnrollmentNumber({ variables: { id: enrollmentNumber, quoteId: quoteId } });
    } else {
      updateEnrollmentNumberGQL({
        variables: {
          input: {
            id: quoteId,
            etag: etag,
          },
          vlAgreementNumberForMigration: enrollmentNumber,
        },
      });
    }
  };

  const handleEnrollmentEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter && cleanedEnrollmentNumber !== enrollmentNumber) {
      updateEnrollmentNumber(cleanedEnrollmentNumber || '');
    }
  };

  const renderLabel = (disabled: boolean): JSX.Element => {
    return (
      <div className={classes.enrollmentNumberFieldLabelContainer}>
        <Label
          className={
            disabled
              ? classes.enrollmentNumberFieldLabelDisabled
              : classes.enrollmentNumberFieldLabel
          }
          htmlFor="enrollment-number-field"
          required={props.enrollmentAssemblyEnabled}
        >
          {t('quote::Expiring enrollment number')}
        </Label>
        <InfoButton
          ariaLabel={t('quote::Open enrollment information')}
          calloutProps={{
            closeButtonAriaLabel: t('Close'),
            headline: t('quote::Expiring enrollment number'),
            maxWidth: 386,
          }}
          className={classes.infoButton}
          id="enrollment-info-button"
        >
          <TextBody>
            {t(
              'quote::The expiring enrollment number connects expiring enrollments to the quote and can at times be attached to the CRM ID. For Office products, the enrollment number can be used to align new Office subscriptions to the end of the expiring enrollment to ensure your customer has consistent access to their services. Removing or adding an expiring enrollment number will modify any Office subscriptions current on the quote.'
            )}
          </TextBody>
        </InfoButton>
      </div>
    );
  };

  const renderComboBoxLabel = (comboBoxProps?: IOnRenderComboBoxLabelProps): JSX.Element => {
    const disabled = (comboBoxProps && comboBoxProps.props.disabled) || false;
    return renderLabel(disabled);
  };

  const renderTextBoxLabel = (textfieldProps?: ITextFieldProps): JSX.Element => {
    const disabled = (textfieldProps && textfieldProps.disabled) || false;
    return renderLabel(disabled);
  };

  const onRenderOption = (item?: ISelectableOption): JSX.Element => {
    if (item) {
      if (item.data) {
        return (
          <div className={classes.enrollmentComboBoxRowStyle}>
            <div>
              <TextBody>{item.text}</TextBody>
            </div>
            <div>
              <TextBodySmall addClass={classes.enrollmentExpirationDateStyle}>
                {t('quote::Expiration date:') +
                  ' ' +
                  convertDateToFormattedString(item.data.date, LocaleDateFormat.ll)}
              </TextBodySmall>
            </div>
          </div>
        );
      } else {
        if (item.key === 'enter enrollment number') {
          return <TextBody addClass={classes.enterEnrollmentNumberStyle}>{item.text}</TextBody>;
        } else {
          return <TextBody>{item.text}</TextBody>;
        }
      }
    }
    return <span></span>;
  };

  const spinner = <Spinner className={classes.spinnerStyle} id="spinner" />;

  const verified = (
    <span className={classes.verifiedText}>
      <StatusIndicatorApproved text={t('quote::Verified')} />
    </span>
  );

  let errorMessage = '';
  if (isAzureBackedEnrollment === false && displayErrorMessage) {
    errorMessage = t('quote::Please enter an Azure-backed enrollment number');
  } else if (
    !(regExpValidateEnrollmentNumber || !cleanedEnrollmentNumber) &&
    !props.enrollmentAssemblyEnabled
  ) {
    errorMessage = t('quote::Enter a valid enrollment number');
  }

  const enrollmentNumberFieldView =
    props.enrollmentAssemblyEnabled &&
    props.getEnrollmentFieldView === EnrollmentFieldView.ComboBox ? (
      <ComboBox
        className={classes.field}
        dataAutomationId="enrollmentNumberComboBox"
        disabled={isQuoteReadOnly || isLegacy || !crmId}
        id="enrollment-number-combobox"
        label={props.modernOfficeVersion ? '' : 'Expiring enrollment number'}
        maxHeight={365}
        options={enrollmentOptions}
        placeholder={t('quote::Select an option')}
        required
        selectedKey={selectedEnrollmentNumber}
        text={enrollmentNumber}
        onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
          if (option) {
            if (option.key === 'enter enrollment number') {
              const dialogProps: DialogProps = {
                providedDialog: <EnterEnrollmentNumberDialog />,
              };
              context.openDialog(dialogProps);
            } else {
              updateEnrollmentNumber(option.key.toString());
              setSelectedEnrollmentNumber(option.key.toString());
            }
          }
        }}
        onRenderLabel={props.modernOfficeVersion ? renderComboBoxLabel : undefined}
        onRenderOption={onRenderOption}
      />
    ) : (
      <div>
        <div style={{ display: 'flex' }}>
          <TextboxStandard
            addClass={classes.field}
            dataAutomationId="enrollmentNumberTextField"
            disabled={isQuoteReadOnly || isLegacy}
            errorMessage={errorMessage}
            id="enrollment-number-field"
            label={props.modernOfficeVersion ? '' : 'Expiring enrollment number'}
            maxLength={maxEnrollmentLength} // TODO: make this conditional - unideal behavior for pasted inputs
            placeholder="12345678"
            required={props.enrollmentAssemblyEnabled}
            value={cleanedEnrollmentNumber || ''}
            onBlur={() => {
              if (cleanedEnrollmentNumber !== enrollmentNumber) {
                updateEnrollmentNumber(cleanedEnrollmentNumber || '');
              }
            }}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              value?: string
            ) => {
              const clean = value ? value.replace(/ /g, '') : '';
              setCleanedEnrollmentNumber(clean);
              setDisplayErrorMessage(false);
              setDisplayVerified(false);
            }}
            onKeyDown={handleEnrollmentEnter}
            onRenderLabel={props.modernOfficeVersion ? renderTextBoxLabel : undefined}
          />
          {props.enrollmentAssemblyEnabled &&
            (validateLoading || updateLoading
              ? spinner
              : isAzureBackedEnrollment &&
                updateEnrollmentNumberData &&
                displayVerified &&
                cleanedEnrollmentNumber
              ? verified
              : null)}
        </div>
        {props.enrollmentAssemblyEnabled && (
          <LinkButton
            displayText={t('quote::view list of customer enrollments')}
            onClick={() => {
              props.setEnrollmentFieldView(EnrollmentFieldView.ComboBox);
            }}
          />
        )}
      </div>
    );

  const enrollmentNumberField: JSX.Element = flexSectionCreator(
    classes.flexLarge,
    classes.flexXLarge,
    enrollmentNumberFieldView,
    classes.flexContainer
  );

  return enrollmentNumberField;
};

export const EnrollmentNumberField = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(styles)(EnrollmentNumberFieldUnStyled));
