import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { Fail, Processing } from 'features/components/dialogs';
// import * as actions from 'features/proposal/actions';
// import { RefreshDialog } from 'features/proposal/components/Dialogs/RefreshDialog'; //Needs Migration
import { Success } from 'features/proposal/components/Dialogs/Shared'; //Needs Migration->
// import * as selectors from 'features/proposal/selectors';
import { ApprovalActionType as ApprovalActionTypeGQL } from 'generated/graphql';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { connect } from 'react-redux';
import { ApprovalActionRequest, ApprovalActionType } from 'services/approval/types';
// import {
//   eTagMissmatchCode,
//   invalidApprovalTransitionCode,
//   unathorizedActionCode,
// } from 'services/approval/utils';
import loggerService from 'services/logger-service';
// import { RootState } from 'store/types';
import { DialogContext } from 'styles';

// import { oc } from 'ts-optchain';
import { approveRejectDimensions } from '../shared.styles';
import { JustificationView } from './JustificationView';

// const mapStateToProps = (state: RootState) => ({
//   approval: selectors.getActiveApproval(state),
//   approvalActionProcessing: selectors.approvalActionProcessing(state),
// });

// const dispatchProps = {
//   performApprovalAction: (request: ApprovalActionRequest) =>
//     actions.performApprovalActionAsync.request(request),
// };

// export type ApproveDialogProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const ApproveDialog: FC = () => {
  // const { approval, approvalActionProcessing } = props;
  const [initiated, setInitiated] = useState<boolean>(false);
  const [justification, setJustification] = useState<string>('');
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();
  const activeQuoteContext = useContext(ActiveQuoteContext);

  const approval = activeQuoteContext.activeQuote && activeQuoteContext.activeQuote.approval;

  if (approval && approval.__typename === 'Approval') {
    const onApproveClick = (comment?: string) => {
      comment = comment || justification;
      setJustification(comment);
      if (!approval) {
        loggerService.error({
          error: new Error('Tried to approve an approval that does not exist'),
        });
        return;
      }
      const request: ApprovalActionRequest = {
        action: ApprovalActionType.Approve,
        approvalId: approval.id,
      };

      if (comment) {
        request.comments = comment;
      }
      activeQuoteContext.availableMutations &&
        activeQuoteContext.availableMutations.performApprovalAction(
          ApprovalActionTypeGQL.Approve,
          comment || justification
        );

      //props.performApprovalAction(request);

      setInitiated(true);
    };

    if (!initiated) {
      return <JustificationView {...approveRejectDimensions} dispatchAction={onApproveClick} />;
    } else if (activeQuoteContext.availableMutationsStatus.performApprovalAction.loading) {
      return (
        <Processing
          {...approveRejectDimensions}
          message1={t('quote::The quote is being approved.')}
        />
      );
    } else if (activeQuoteContext.availableMutationsStatus.performApprovalAction.error) {
      //TODO: michmel - this needs a better implementation
      const error = activeQuoteContext.availableMutationsStatus.performApprovalAction.error;
      const code = error.graphQLErrors.length && error.graphQLErrors[0].message;
      console.log(error + ' ' + code);
      // const errorCode = oc(approvalActionProcessing).error.exception.response.data.details[0].code();
      // const errorStatus412 = errorCode === eTagMissmatchCode;
      // const isSequentialMultipleApprover =
      //   approval &&
      //   approval.workFlow &&
      //   approval.workFlow.toLowerCase() === 'sequentialmultipleapproval';
      // if (
      //   errorCode === invalidApprovalTransitionCode ||
      //   (errorCode === unathorizedActionCode && isSequentialMultipleApprover)
      // ) {
      //   const actionText = t('quote::Approval');
      //   return (
      //     <RefreshDialog
      //       isSequentialMultipleApprover
      //       {...approveRejectDimensions}
      //       action={actionText}
      //     />
      //   );
      // }
      // if (errorStatus412) {
      //   const actionText = t('quote::Approve');
      //   return <RefreshDialog action={actionText} {...approveRejectDimensions} is412={true} />;
      // } else {
      return (
        <Fail
          {...approveRejectDimensions}
          closeDialog={closeDialog}
          dataAutomationId="quoteApprovalFail"
          message={t('quote::Sorry, the "Approve" action failed.')}
          onTryAgainClick={onApproveClick}
        />
      );
      //}
    } else {
      return (
        <Success
          {...approveRejectDimensions}
          closeDialog={closeDialog}
          dataAutomationId="quoteApprovalSuccess"
          message={t('quote::The quote was successfully approved.')}
        />
      );
    }
  } else {
    //TODO: Michmel - this needs to be updated to be non breaking
    throw new Error('No Approval');
  }
};

// export const ApproveDialog = connect(mapStateToProps, dispatchProps)(ApproveDialogFeature);
