import { SearchResultProduct } from 'features/catalog';
import { RecommendationSource } from 'generated/graphql';

export const buildOptimisticResponse = (product: SearchResultProduct, name: string) => {
  return {
    updateFavoriteGroup: [
      {
        source: RecommendationSource.UserPreferences,
        items: [
          {
            meta: null,
            product: {
              id: product.productId,
              title: product.productName,
              productType: product.productIdentifier.productType,
              maxQuantityOnQuote: null,
              __typename: 'Product',
            },
            __typename: 'ProductRecommendationAction',
          },
        ],
        name: name,
        type: 'Product',
        __typename: 'RecommendationGroup',
      },
    ],
    __typename: 'Mutation',
  };
};
