import axios from 'axios';
import { TokenAuthorities } from 'services/utils';
import { PageResponse } from 'services/types';

import { createGuid, getAuthHeader, getCV, getTestHeader } from '../utils';
import { BillingGroupConfig, endpoints } from './config';
import { BillingGroup } from './types';

async function getHeaders(config: BillingGroupConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
    'api-version': '2019-05-31',
    'ms-cv': getCV(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function getBillingGroupId(
  accountId: string,
  config: BillingGroupConfig
): Promise<string | null> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/${accountId}/billinggroups`;
  const params = { top: 1 };
  const response = await axios.get<PageResponse<BillingGroup>>(url, { params, headers });
  return response.data && response.data.value && response.data.value.length
    ? response.data.value[0].id
    : null;
}
