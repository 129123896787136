import * as actions from 'features/user/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { User } from 'services/graph/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

export function* loadMSContact() {
  const relevantAction = actions.loadMSContactAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const email = action.payload;
    if (!email) {
      return;
    }
    const fromState: User | undefined = yield select(
      (state: RootState) => state.user.msContact[email]
    );
    const fromSearch: User | undefined = yield select((state: RootState) =>
      state.user.msContactResults.find(user => user.userPrincipalName === email)
    );
    const stateUser = fromState || fromSearch;
    if (stateUser) {
      yield put(actions.loadMSContactAsync.success([stateUser]));
      return;
    }
    try {
      const result: User[] = yield call(api.graph.searchUser, email);
      yield put(actions.loadMSContactAsync.success(result));
    } catch (err) {
      yield put(
        actions.loadMSContactAsync.failure({
          message: t('error::Error getting ms contact'),
          exception: err,
        })
      );
    }
  });
}

export function* searchMSContact() {
  const relevantAction = actions.searchMSContactsAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const query = action.payload;
    if (!query) {
      return;
    }
    try {
      const result: User[] = yield call(api.graph.searchUser, query);
      yield put(actions.searchMSContactsAsync.success(result));
    } catch (err) {
      yield put(
        actions.searchMSContactsAsync.failure({
          message: t('error::Error getting ms contact'),
          exception: err,
        })
      );
    }
  });
}
