import { Suggestion } from 'components/molecules/Autosuggest';
import {
  OrganizationInfo,
  OrganizationInfoProps,
} from 'features-apollo/quote/components/OrganizationInfo';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

export interface AddressSuggestion extends OrganizationInfoProps {
  accountId: string;
  organizationId: string;
}

export interface AddressSuggestionRowProps {
  suggestion: Suggestion<AddressSuggestion>;
}

const addressSuggestionRowStyles = {
  addressContainer: {
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

type Props = AddressSuggestionRowProps & WithStyles<typeof addressSuggestionRowStyles>;

export const AddressSuggestionRowUnstyled: React.FC<Props> = props => {
  const addressComponent = <OrganizationInfo {...props.suggestion.value} />;
  return <div className={props.classes.addressContainer}>{addressComponent}</div>;
};

export const AddressSuggestionRow = withStyles(addressSuggestionRowStyles)(
  AddressSuggestionRowUnstyled
);
