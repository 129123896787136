import {
  PropertySheetContent,
  PropertySheetLayout,
} from 'features-apollo/quote/components/PropertySheets/Layout';
import { TenantScenario } from 'features-apollo/quote/components/Wizards';
import { InvitedUserSimple, OrganizationSimple } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerLoading, OrganizationInformation, OrganizationNotFound } from '../states';

interface CustomerForHRDD {
  loading: boolean;
  invitedUser?: InvitedUserSimple;
  customerOrganization?: OrganizationSimple;
  partner: { organization?: OrganizationSimple; invitedUser?: InvitedUserSimple | null };
  salesAccountTPID?: string;
}

export const CustomerForHRDD: React.FC<CustomerForHRDD> = props => {
  const { t } = useTranslation();

  const loadingState = <CustomerLoading />;
  const organizationNotFoundErrorMessage = <OrganizationNotFound />;

  let partnerContent;
  let customerContent;

  if (props.loading) {
    partnerContent = loadingState;
    customerContent = loadingState;
  } else {
    partnerContent = props.partner.organization ? (
      <OrganizationInformation
        invitedUser={props.partner.invitedUser}
        organization={props.partner.organization}
        readOnly
        specificScenario={TenantScenario.partner}
      />
    ) : (
      organizationNotFoundErrorMessage
    );

    customerContent = props.customerOrganization ? (
      <OrganizationInformation
        organization={props.customerOrganization}
        readOnly
        salesAccountTPID={props.salesAccountTPID}
        specificScenario={TenantScenario.partnerCustomer}
      />
    ) : (
      organizationNotFoundErrorMessage
    );
  }

  const partnerSection: PropertySheetContent | undefined = partnerContent && {
    leftPaneTitle: t('quote::Partner'),
    content: partnerContent,
  };
  const customerSection: PropertySheetContent = {
    leftPaneTitle: t('quote::Customer'),
    content: customerContent,
  };

  return <PropertySheetLayout contentList={[partnerSection, customerSection]} />;
};
