import { SummaryList as SummaryListApollo } from 'features-apollo/quote/components';
import { getFlightIsEnabled } from 'features/app/selectors';
import { HomeProposalStyles as styles } from 'features/home/components/styles/HomeProposal.styles';
import { SummaryList } from 'features/proposal/components';
import { ISearchBox } from 'office-ui-fabric-react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

const mapStateToProps = (state: RootState) => ({
  useApolloClient: getFlightIsEnabled(state, Flight.graphqlPhase2),
});

type Props = {} & WithTranslation & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

const HomeProposals: React.FC<Props> = ({ classes, useApolloClient }: Props) => {
  const { t } = useTranslation();

  const searchFocusRef = React.useRef<ISearchBox>(null);
  useHotkeys('ctrl+/', () => {
    searchFocusRef.current && searchFocusRef.current.focus();
  });

  return (
    <div className={classes.div}>
      <nav className={classes.nav}>
        <NavLink
          className={classes.navLink}
          data-automation-id="myQuotesTab"
          to={routes.home.quotes.my}
        >
          {t('home::My quotes')}
        </NavLink>
        <NavLink
          className={classes.navLink}
          data-automation-id="needsMyApprovalTab"
          to={routes.home.quotes.myApprovals}
        >
          {t('home::Needs my approval')}
        </NavLink>
      </nav>

      <Switch>
        <Route
          exact
          path={routes.home.quotes.root}
          render={() => <Redirect to={routes.home.quotes.my} />}
        />
        {useApolloClient ? (
          <Route
            exact
            path={routes.home.quotes.my}
            render={() => <SummaryListApollo filter="my" searchComponentRef={searchFocusRef} />}
          />
        ) : (
          <Route
            exact
            path={routes.home.quotes.my}
            render={() => <SummaryList filter="my" searchComponentRef={searchFocusRef} />}
          />
        )}

        {useApolloClient ? (
          <Route
            exact
            path={routes.home.quotes.myApprovals}
            render={() => <SummaryListApollo filter="my-approvals" />}
          />
        ) : (
          <Route
            exact
            path={routes.home.quotes.myApprovals}
            render={() => <SummaryList filter="my-approvals" />}
          />
        )}
        <Route render={() => <Redirect to={routes.home.quotes.root} />} />
      </Switch>
    </div>
  );
};

export default connect(mapStateToProps)(withTranslation('home')(withStyles(styles)(HomeProposals)));
