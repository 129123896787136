import {
  CopyInputButton,
  InfoButton,
  LinkButton,
  Persona,
  SectionSeparator,
  Spinner,
  SystemError,
  TextBody,
  TextBodySmall,
  TextTitle,
  TextTitleSecondary,
} from 'components';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import {
  Asset,
  OrganizationType,
  Referral,
  ReferralStatus,
  ReferralType,
  UserGroup,
} from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { oc } from 'ts-optchain';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { GetReferral, RepairReferral } from './Queries';
import { ReferralSubscriptions } from './ReferralSubscriptions';
import { referralSummaryDetailStyles } from './ReferralSummaryDetail.styles';

const mapStateToProps = () => ({});

interface ReferralSummaryDetailsProps {
  referralId: string;
  needsRepair: boolean;
  closed?: boolean;
}

type Props = ReferralSummaryDetailsProps &
  WithStyles<typeof referralSummaryDetailStyles> &
  ReturnType<typeof mapStateToProps>;

const ReferralSummaryDetailUnconnected: React.FC<Props> = ({
  classes,
  referralId,
  closed,
}: Props) => {
  const { t } = useTranslation();
  const { data, error, loading, refetch } = useQuery<{ getReferral: Referral }>(GetReferral, {
    notifyOnNetworkStatusChange: true,
    variables: { id: referralId },
  });

  const [repairReferral, { loading: isRepairProcessing }] = useMutation<{
    repairReferral: { id: string; etag: string };
  }>(RepairReferral);

  const referral = data && (data.getReferral as Referral);

  const status = oc(referral).status();
  const referralName = oc(referral).name();
  const azureType = oc(referral).azureType();
  const market = oc(referral).market();
  const isIndividualOrg = oc(referral).organization.type() === OrganizationType.Individual;
  const billingAccount = isIndividualOrg
    ? t('quote::Individual account')
    : oc(referral).organization.name();
  const crmId = oc(referral).crmLead.id();
  const crmType = oc(referral).crmLead.type();
  const goodUntil = oc(referral).expirationDate();
  const msContact = oc(referral).msContact.mail();
  const link = oc(referral).referralLink();
  const assets = oc(referral).organization.assets() as Asset[];
  const subscriptions: string[] = [];
  assets &&
    assets.forEach((asset: Asset) => {
      if (asset.subscriptionId) {
        subscriptions.push(asset.subscriptionId);
      }
    });
  const subscriptionsError =
    error &&
    error.graphQLErrors.find(error => error && error.path && error.path.includes('organization'));
  const isClaim = oc(referral).referralType() === ReferralType.Claim;
  const type = isClaim ? t('quote::claim') : t('quote::referral');
  const isReferralClosed = status === ReferralStatus.Closed;
  const isTransacted = status === ReferralStatus.Transacted;
  const crmTypeText =
    crmType && crmType.toString().toLowerCase() === 'successengagement'
      ? t('quote::Success Engagement')
      : crmType;
  const assignedToField = oc(referral).assignedTo() !== UserGroup.Customer;

  React.useEffect(() => {
    if (referral && !crmId && closed) {
      refetch();
    }
  }, [crmId, closed, refetch, referral]);

  const onRetry = () => {
    repairReferral({
      variables: {
        input: {
          id: oc(referral).id(''),
          etag: oc(referral).etag(''),
        },
      },
    });
  };

  const renderSubscriptionError = () => (
    <div className={classes.detailContainer}>
      <TextBodySmall addClass={classes.lightText}>{t('home::Subscription ID')}</TextBodySmall>
      <SystemError
        actionLabel={t('retry')}
        mainMessage={t(
          'We ran into an error and were unable to retrieve the subscription IDs for this {{type}}.',
          { type }
        )}
        onClick={() =>
          refetch({
            variables: {
              input: {
                id: oc(referral).id(''),
              },
            },
          })
        }
      />
    </div>
  );

  const errorMessage =
    (!!assignedToField && (
      <TextBody addClass={classes.errorMessage}>
        {t('The referral link is not usable to your customer.')}{' '}
        <LinkButton displayText={t('retry?')} onClick={onRetry} />
      </TextBody>
    )) ||
    undefined;

  const referralDetails = (
    <>
      {!isReferralClosed && !isTransacted && link && (
        <div className={classes.detailContainer}>
          <CopyInputButton
            buttonText={t('home::Copy link')}
            copiedText={t('home::Copied')}
            errorMessage={errorMessage}
            inputValue={link}
            labelText={t('home::Azure referral link')}
            processing={isRepairProcessing}
            titleText={t('home::Azure referral link')}
            useSecondaryButton={true}
          />
        </div>
      )}
      {!isClaim && (
        <div className={classes.detailContainer}>
          <TextBodySmall addClass={classes.lightText}>{t('home::Referral type')}</TextBodySmall>
          <TextBody>{azureType}</TextBody>
        </div>
      )}
      <div className={classes.detailContainer}>
        <TextBodySmall addClass={classes.lightText}>{t('home::Market')}</TextBodySmall>
        <TextBody>{market}</TextBody>
      </div>
    </>
  );
  const customerDetails = (
    <>
      <div className={classes.detailContainer}>
        <TextBodySmall addClass={classes.lightText}>{t('home::Billing account')}</TextBodySmall>
        <TextBody addClass={!billingAccount ? classes.noBilling : undefined}>
          {billingAccount || t('home::None')}
        </TextBody>
      </div>
      {crmId ? (
        <div className={classes.detailContainer}>
          <TextBodySmall addClass={classes.lightText}>
            {t('home::CRM ID ({{CRMLeadType}})', { CRMLeadType: crmTypeText })}
          </TextBodySmall>
          <TextBody>{crmId}</TextBody>
        </div>
      ) : (
        <div className={classes.detailContainer}>
          <TextBodySmall addClass={classes.lightText}>
            {t('home::CRM ID', { CRMLeadType: crmTypeText })}
          </TextBodySmall>
          <TextBody addClass={classes.noCRMId}>
            {t('No CRM ID has been added to this {{type}}. A CRM ID can be added on close.', {
              type,
            })}
          </TextBody>
        </div>
      )}
      {!subscriptionsError && !!(subscriptions && subscriptions.length) && (
        <div className={classes.detailContainer}>
          <TextBodySmall addClass={classes.lightText}>
            {t('home::Subscription ID')}
            <InfoButton
              ariaLabel={t('Open information about C+AI Customer Portal data')}
              calloutProps={{
                closeButtonAriaLabel: t('Close'),
                headline: t('C+AI Customer Portal data'),
                maxWidth: 265,
              }}
              id="CAI-portal-info-button"
            >
              <TextBody>
                {t(
                  'Consumption data, related to the subscriptions listed, takes roughly 24-96 hours to be reflected in the C+AI Customer portal. Prior to that point, the C+AI link will result in a dead end.'
                )}
              </TextBody>
            </InfoButton>
          </TextBodySmall>
          <ReferralSubscriptions
            showLessText={t('common::show less')}
            showMoreText={t('common::show more')}
            subscriptions={subscriptions}
            titleText={t('home::Subscription ID')}
          />
        </div>
      )}
      {subscriptionsError && renderSubscriptionError()}
    </>
  );
  const generalDetails = (
    <>
      {!isReferralClosed && goodUntil && (
        <div className={classes.detailContainer}>
          <TextBodySmall addClass={classes.lightText}>{t('home::Good until')}</TextBodySmall>
          <TextBody>{convertDateToFormattedString(goodUntil, LocaleDateFormat.lll)}</TextBody>
        </div>
      )}
      <div className={classes.detailContainer}>
        <TextBodySmall addClass={classes.lightText}>{t('home::Microsoft contact')}</TextBodySmall>
        <div className={classes.persona}>
          <Persona id="referralMsContact" name={msContact} />
          {!msContact && (
            <TextBodySmall addClass={classes.noContact}>{t('home::no contact')}</TextBodySmall>
          )}
        </div>
      </div>
    </>
  );

  return !loading && referral ? (
    <aside className={classes.detail} data-automation-id="referralSummaryDetail">
      <header className={classes.header}>
        <TextTitle>{referralName || 'test'}</TextTitle>
        <TextBodySmall>{isClaim ? t('Claim') : status}</TextBodySmall>
      </header>
      <section className={classes.main}>
        <article data-automation-id="referralDetails">
          <div className={classes.spacing} />
          <TextTitleSecondary>
            {isClaim ? t('home::Claim Details') : t('home::Referral Details')}
          </TextTitleSecondary>
          {referralDetails}
          <SectionSeparator addClass={classes.separator} />
        </article>
        <article data-automation-id="customerDetails">
          <div className={classes.spacing} />
          <TextTitleSecondary>{t('home::Customer Details')}</TextTitleSecondary>
          {customerDetails}
          <SectionSeparator addClass={classes.separator} />
        </article>
        <article data-automation-id="generalDetails">
          <div className={classes.spacing} />
          <TextTitleSecondary>{t('home::General Details')}</TextTitleSecondary>
          {generalDetails}
        </article>
      </section>
    </aside>
  ) : (
    <Spinner className={classes.spinner} />
  );
};

export const ReferralSummaryDetail = connect(mapStateToProps)(
  withStyles(referralSummaryDetailStyles)(ReferralSummaryDetailUnconnected)
);
