import { TestHeaderConfig } from 'services/types';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface EmpowermentConfig extends TestHeaderConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Prod]: 'https://empowerment.l2o.microsoft.com/token/v2/empowerments',
  [Environment.Int]: 'https://empowerment.int.l2o.microsoft.com/token/v2/empowerments',
};
