import { LinkExternal, SectionSeparator } from 'components';
import { getAppEnvironment, getFlightIsEnabled } from 'features/app/selectors';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { CwaLink } from 'features/components/CwaLink';
import {
  canEditOrganization,
  getCustomerAccount,
  getLeadOrganizationName,
  getOrganization,
  getSearchOrganizationPropertySheetResults,
  getTenantNames,
  getVatId,
} from 'features/customer/selectors';
import { Address } from 'features/customer/types';
import { selectOrganizationAsync } from 'features/proposal/actions';
import {
  getAccountId,
  getActiveProposal,
  getCustomerTpid,
  getEndCustomerAccountId,
  getEndCustomerOrganizationId,
  getLeadOrganizationId,
  getOrganizationId,
  getSalesAccountAddressFromQuote,
  getSoldToOrgId,
  hasIncompleteOrganization,
  isPartnerProposal,
  isQuotePublished,
  quoteHydrateLoading,
} from 'features/proposal/selectors';
import { Language } from 'features/proposal/supported-languages';
import { getCRMId, isAgreementTypeLegacy, isProposalReadOnly } from 'features/proposal/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { routes } from 'routes';
import { TenantName } from 'services/externaluser/types';
import { convertToExternalUser } from 'services/externaluser/utils';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';

import {
  ExistingOrganizations,
  NoOpportunity,
  NoOrganization,
  NoOrganizationHeaderProps,
  NoOrganizationWithSuggestions,
} from './States';
import { CustomerAdded } from './States/CustomerAdded';

export const styles = (theme: ThemeProps) => ({
  separator: {
    backgroundColor: theme.palette.textDisabled,
    width: 450,
    marginTop: 46,
  },
  blockLinkText: {
    display: 'block',
  },
  customerActionsPadding: {
    paddingTop: 16,
    paddingBottom: 24,
  },
});

const dispatchProps = {
  onSelectOrganization: selectOrganizationAsync.request,
};

export interface Props {
  isPartnerCustomer?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: Props) => {
  const quote = getActiveProposal(state);
  const customerTpid = getCustomerTpid(state);
  const soldToOrgId = getSoldToOrgId(state);

  const disableEditTenant = getFlightIsEnabled(state, Flight.noAdditionalTenant);
  const organizationId = ownProps.isPartnerCustomer
    ? getEndCustomerOrganizationId(state)
    : getOrganizationId(state);
  const organization = organizationId ? getOrganization(state, organizationId) : undefined;
  const organizationEditable = !!organizationId && canEditOrganization(state, organizationId);
  const externalUser = convertToExternalUser(quote.header.invitedUser);
  const accountId = ownProps.isPartnerCustomer
    ? getEndCustomerAccountId(state)
    : getAccountId(state);
  const account = accountId ? getCustomerAccount(state, accountId) : undefined;
  const salesAccountAddress = getSalesAccountAddressFromQuote(state, quote);
  const externalIds = account && account.externalIds;
  const accountTenants = externalIds && getTenantNames(state, externalIds);
  const leadOrganizationId = getLeadOrganizationId(state);
  const leadOrganizationName =
    leadOrganizationId && getLeadOrganizationName(state, leadOrganizationId);

  const organizationIsMissingInformation = hasIncompleteOrganization(state);

  let tenantSelected: TenantName | undefined;

  // Only show invitedUser tenant as selected if is associated with the current account
  if (accountTenants) {
    const invitedUserTenant =
      externalUser &&
      externalUser.TenantId &&
      accountTenants.find(tenant => tenant.tenantId === externalUser.TenantId);
    if (invitedUserTenant) {
      tenantSelected = invitedUserTenant;
    } else if (accountTenants.length === 1) {
      tenantSelected = accountTenants[0];
    }
  }

  return {
    accountId,
    crmID: getCRMId(quote),
    appEnv: getAppEnvironment(state),
    customerTpid,
    soldToOrgId,
    invitedUser: externalUser ? externalUser.UPN : undefined,
    isQuoteLegacy: isAgreementTypeLegacy(quote),
    disableEditTenant,
    readOnly: isProposalReadOnly(quote),
    quoteId: quote.id,
    quoteEtag: quote.etag,
    organization,
    organizationId,
    organizationEditable,
    organizations: [] as Address[], // TODO: Update once we have a way of getting orgs from sales account
    organizationIsMissingInformation,
    organizationAddress: organization && organization.legalEntity.address,
    vatId: getVatId(state),
    language: organization ? (organization.cultureInfo.culture as Language) : undefined,
    salesAccountAddress,
    suggestions: getSearchOrganizationPropertySheetResults(
      state,
      salesAccountAddress ? salesAccountAddress.companyName : ''
    ),
    quoteHydrateIsLoading: quoteHydrateLoading(state),
    tenantSelected,
    tradeName: organization && organization.legalEntity.tradeName,
    leadOrganizationName,
    isPartnerProposal: isPartnerProposal(state),
    isQuotePublished: isQuotePublished(state),
  };
};

export type CustomerProps = Props &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  WithStyles<typeof styles>;

export const CustomerDisconnected: React.FC<CustomerProps> = props => {
  const {
    salesAccountAddress,
    readOnly,
    organizationIsMissingInformation,
    isPartnerProposal,
    isPartnerCustomer,
    soldToOrgId,
    customerTpid,
    isQuotePublished,
    vatId,
  } = props;
  useHelpContent(HelpContent.CustomerPropertySheet);

  const onClickSelectOrganization = (organizationId: string, accountId: string) => {
    props.onSelectOrganization({ organizationId, accountId });
  };

  const { t } = useTranslation();

  const state = () => {
    const headerProps: NoOrganizationHeaderProps = {
      customerName: salesAccountAddress ? salesAccountAddress.companyName : undefined,
      readOnly,
    };

    if (props.quoteHydrateIsLoading) {
      return <CustomerAdded loading readOnly={readOnly} />;
    }

    //TODO:Move these stuff inside customer added
    const viewCustomerLink = customerTpid && (
      <CwaLink
        dataAutomationId="viewCustomer"
        fontSize="medium"
        linkText={t('quote::view customer')}
        tpid={customerTpid}
      />
    );
    let externalViewFromCustomerLink = undefined;
    if (soldToOrgId) {
      externalViewFromCustomerLink = routes.home.quotes.mySearch(soldToOrgId);
    }

    const viewQuotesForThisCustomerLink = soldToOrgId && (
      <LinkExternal
        addClass={props.classes.blockLinkText}
        dataAutomationId="viewQuotesForThisCustomer"
        displayText={t('quote::view quotes for this customer')}
        href={externalViewFromCustomerLink}
        size="medium"
      />
    );

    const showCustomerActions = customerTpid || soldToOrgId;
    const customerActions: React.ReactNode = showCustomerActions && (
      <div className={props.classes.customerActionsPadding}>
        {viewCustomerLink}
        {viewQuotesForThisCustomerLink}
      </div>
    );

    if (props.isQuoteLegacy) {
      return (
        <CustomerAdded
          address={props.organizationAddress}
          customerActions={customerActions}
          isQuoteLegacy
          language={props.language}
          readOnly={readOnly}
        />
      );
    } else if (props.organizationAddress) {
      return (
        <>
          <CustomerAdded
            accountId={props.accountId}
            address={props.organizationAddress}
            contactEmail={props.organizationAddress.email}
            contactPhoneNumber={props.organizationAddress.phoneNumber}
            customerActions={customerActions}
            disableEditTenant={props.disableEditTenant}
            invitedUser={props.invitedUser}
            isPartnerCustomer={isPartnerCustomer}
            isPartnerProposal={isPartnerProposal}
            isQuotePublished={isQuotePublished}
            language={props.language}
            leadOrgName={props.leadOrganizationName}
            organization={props.organization}
            organizationEditable={props.organizationEditable}
            organizationId={props.organizationId}
            organizationIsMissingInformation={organizationIsMissingInformation}
            readOnly={readOnly}
            tenantSelected={props.tenantSelected}
            tradeName={props.tradeName}
            vatId={vatId}
          />
          {isPartnerProposal && !isPartnerCustomer && (
            <SectionSeparator addClass={props.classes.separator} />
          )}
        </>
      );
    } else if (!props.crmID) {
      return <NoOpportunity quoteId={props.quoteId} readOnly={readOnly} />;
    } else if (props.organizations && props.organizations.length) {
      return <ExistingOrganizations {...headerProps} addresses={props.organizations} />;
    } else if (props.suggestions && props.suggestions.length) {
      return (
        <NoOrganizationWithSuggestions
          {...headerProps}
          organizationSuggestions={props.suggestions}
          onSelectOrganization={onClickSelectOrganization}
        />
      );
    } else {
      return <NoOrganization {...headerProps} readOnly={readOnly} />;
    }
  };

  return state();
};

export const Customer = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(styles)(CustomerDisconnected));
