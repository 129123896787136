import { LinkButton, LinkProps, Offset } from 'components/ions';
import { openConfigCard } from 'features/proposal/actions';
import { CardType, OpenCard } from 'features/proposal/types';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { FontSizes } from 'styles';

import { calloutCardLinkStyles, offsetStyles } from './CalloutCardLink.styles';

export interface CalloutCardLinkProps {
  reference: React.RefObject<HTMLSpanElement>;
  lineItemId: string;
  maxWidth?: number;
  type: CardType;
  linkProps: LinkProps;
  offset: Offset;
  isReadOnly?: boolean;
  relatedIds?: string[];
  title?: string;
  dataAutomationId?: string;
  size?: keyof FontSizes;
}

const dispatchProps = {
  onClickShowCard: (openCard: OpenCard) => {
    return openConfigCard(openCard);
  },
};

type Props = CalloutCardLinkProps & WithStyles<typeof calloutCardLinkStyles> & typeof dispatchProps;

const CalloutCardLinkUnstyled: React.FC<Props> = (props: Props) => {
  const onClick = () => {
    props.onClickShowCard({
      lineItemId: props.lineItemId,
      type: props.type,
      relatedIds: props.relatedIds,
    });
  };
  return (
    <div className={props.classes.relativePositionStyles}>
      <LinkButton
        addClass={props.classes.button}
        size={props.size}
        title={props.title}
        onClick={onClick}
        {...props.linkProps}
        disabled={props.isReadOnly}
      />
      <span ref={props.reference} style={offsetStyles(props.offset)} />
    </div>
  );
};

const CalloutCardLink = withStyles(calloutCardLinkStyles)(CalloutCardLinkUnstyled) as React.FC<
  CalloutCardLinkProps
>;

export const ConnectedCalloutCardLink = connect(null, dispatchProps)(CalloutCardLink);
