import { ThemeProps } from 'styles';

export const infoDialogStyles = (theme: ThemeProps) => {
  const { palette } = theme;

  return {
    media: {
      height: 338,
      width: 600,
      overflow: 'hidden',
    },
    image: {
      objectFit: 'cover',
    },
    bodyOverride: {
      paddingLeft: 0,
      paddingRight: 0,
      overflow: 'hidden',
      textAlign: 'center',
      position: 'relative',
    },
    bodyOverrideHeader: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: -78,
      overflow: 'hidden',
      textAlign: 'center',
      position: 'relative',
    },
    body: {
      paddingTop: 20,
      paddingLeft: 80,
      paddingRight: 80,
      paddingBottom: 40,
    },
    bodyLink: {
      paddingTop: 12,
    },
    title: {
      paddingBottom: 8,
    },
    carousel: {
      paddingTop: 0,
      paddingBottom: 40,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    header: {
      backgroundColor: 'transparent',
      zIndex: 1,
    },
    lightCloseButton: {
      '& i': {
        color: palette.lightColor,
      },
    },
    darkCloseButton: {
      '& i': {
        color: palette.darkColor,
      },
    },
  };
};
