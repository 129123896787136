import { ThemeProps } from 'styles';
import { ProposalListProps } from './ProposalList';

const columnHeaderPadding = 18 * 2;

export const proposalListStyles = (theme: ThemeProps) => {
  return {
    list: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.backgroundCommon,
      border: `1px solid ${theme.palette.backgroundDivider}`,
      width: (props: ProposalListProps) => {
        return props.columns.reduce((a, col) => {
          return a + col.width + 34 || 0;
        }, columnHeaderPadding);
      },
      boxSizing: 'border-box',
      boxShadow: '0px 6px 15px rgba(0,0,0,0.2)',
      zIndex: 1,
      position: 'relative',
    },
    listFill: {
      height: '100%',
    },
    listBody: {
      overflowY: 'auto',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      flexGrow: 1,
      paddingTop: 22,
      minHeight: 72,
    },
    listWrapper: {
      display: 'flex',
      height: '100%',
      backgroundColor: theme.palette.backgroundCommon,
    },
    detailsPane: {
      height: '100%',
      overflowY: 'auto',
      width: 325,
      backgroundColor: theme.palette.backgroundStandout,
      minWidth: 325,
      borderTop: `solid 1px ${theme.palette.backgroundDivider}`,
      borderRight: `solid 1px ${theme.palette.backgroundDivider}`,
    },
    title: {
      paddingTop: 48,
      paddingLeft: 24,
    },
    text: {
      paddingLeft: 24,
      paddingBottom: 12,
      width: '75%',
      display: 'flex',
    },
    sharedDiscounts: {
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    },
  };
};
