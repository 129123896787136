/**
 * @deprecated Const enum use is deprecated. See GitHub issue #7110: https://github.com/OfficeDev/office-ui-fabric-react/issues/7110
 */
export enum IconNames {
  ProductVariant = 'ProductVariant',
  ReplyMirrored = 'ReplyMirrored',
  ReplyAllMirrored = 'ReplyAllMirrored',
  Medal = 'Medal',
  AddGroup = 'AddGroup',
  QuestionnaireMirrored = 'QuestionnaireMirrored',
  CloudImportExport = 'CloudImportExport',
  TemporaryUser = 'TemporaryUser',
  ProductRelease = 'ProductRelease',
  CaretSolid16 = 'CaretSolid16',
  GroupedAscending = 'GroupedAscending',
  SortUp = 'SortUp',
  SortDown = 'SortDown',
  AwayStatus = 'AwayStatus',
  MyMoviesTV = 'MyMoviesTV',
  SyncToPC = 'SyncToPC',
  GenericScan = 'GenericScan',
  AustralianRules = 'AustralianRules',
  GroupedDescending = 'GroupedDescending',
  ProductionFloorManagement = 'ProductionFloorManagement',
  ManagerSelfService = 'ManagerSelfService',
  Questionnaire = 'Questionnaire',
  CaretDown8 = 'CaretDown8',
  CaretLeftSolid8 = 'CaretLeftSolid8',
  CaretRightSolid8 = 'CaretRightSolid8',
  CaretUpSolid8 = 'CaretUpSolid8',
  CaretDownSolid8 = 'CaretDownSolid8',
  ClearFormatting = 'ClearFormatting',
  Superscript = 'Superscript',
  Subscript = 'Subscript',
  Strikethrough = 'Strikethrough',
  Export = 'Export',
  ExportMirrored = 'ExportMirrored',
  SingleBookmark = 'SingleBookmark',
  SingleBookmarkSolid = 'SingleBookmarkSolid',
  DoubleChevronDown = 'DoubleChevronDown',
  FollowUser = 'FollowUser',
  ReplyAll = 'ReplyAll',
  WorkforceManagement = 'WorkforceManagement',
  RecruitmentManagement = 'RecruitmentManagement',
  WifiEthernet = 'WifiEthernet',
  CaretUp8 = 'CaretUp8',
  TrackersMirrored = 'TrackersMirrored',
  StopSolid = 'StopSolid',
  LargeGrid = 'LargeGrid',
  TriangleSolidUp12 = 'TriangleSolidUp12',
  TriangleSolidDown12 = 'TriangleSolidDown12',
  TriangleSolidLeft12 = 'TriangleSolidLeft12',
  TriangleSolidRight12 = 'TriangleSolidRight12',
  TriangleUp12 = 'TriangleUp12',
  TriangleDown12 = 'TriangleDown12',
  TriangleLeft12 = 'TriangleLeft12',
  OfflineOneDriveParachuteDisabled = 'OfflineOneDriveParachuteDisabled',
  TriangleRight12 = 'TriangleRight12',
  ArrowDownRight8 = 'ArrowDownRight8',
  DocumentSet = 'DocumentSet',
  GoToDashboard = 'GoToDashboard',
  DelveAnalytics = 'DelveAnalytics',
  ArrowUpRightMirrored8 = 'ArrowUpRightMirrored8',
  ArrowDownRightMirrored8 = 'ArrowDownRightMirrored8',
  CompanyDirectory = 'CompanyDirectory',
  OpenEnrollment = 'OpenEnrollment',
  ArrowUpRight8 = 'ArrowUpRight8',
  OfflineOneDriveParachute = 'OfflineOneDriveParachute',
  OfficeAddinsLogo = 'OfficeAddinsLogo',
  PictureLibrary = 'PictureLibrary',
  DoubleChevronUp12 = 'DoubleChevronUp12',
  DoubleChevronDown12 = 'DoubleChevronDown12',
  DoubleChevronLeft12 = 'DoubleChevronLeft12',
  DoubleChevronRight12 = 'DoubleChevronRight12',
  CalendarAgenda = 'CalendarAgenda',
  ConnectVirtualMachine = 'ConnectVirtualMachine',
  AddEvent = 'AddEvent',
  AssetLibrary = 'AssetLibrary',
  DataConnectionLibrary = 'DataConnectionLibrary',
  DocLibrary = 'DocLibrary',
  FormLibrary = 'FormLibrary',
  FormLibraryMirrored = 'FormLibraryMirrored',
  ReportLibrary = 'ReportLibrary',
  ReportLibraryMirrored = 'ReportLibraryMirrored',
  ContactCard = 'ContactCard',
  CustomList = 'CustomList',
  CustomListMirrored = 'CustomListMirrored',
  IssueTracking = 'IssueTracking',
  IssueTrackingMirrored = 'IssueTrackingMirrored',
  DateTimeMirrored = 'DateTimeMirrored',
  CaretRight8 = 'CaretRight8',
  CaretLeft8 = 'CaretLeft8',
  ProjectLogoInverse = 'ProjectLogoInverse',
  Balloons = 'Balloons',
  Cat = 'Cat',
  MailAlert = 'MailAlert',
  MailCheck = 'MailCheck',
  MailLowImportance = 'MailLowImportance',
  MailPause = 'MailPause',
  MailRepeat = 'MailRepeat',
  SecurityGroup = 'SecurityGroup',
  VisioLogoInverse = 'VisioLogoInverse',
  Table = 'Table',
  VoicemailReply = 'VoicemailReply',
  Waffle = 'Waffle',
  RemoveEvent = 'RemoveEvent',
  EventInfo = 'EventInfo',
  ForwardEvent = 'ForwardEvent',
  WipePhone = 'WipePhone',
  AddOnlineMeeting = 'AddOnlineMeeting',
  JoinOnlineMeeting = 'JoinOnlineMeeting',
  VoicemailForward = 'VoicemailForward',
  SocialListeningLogo = 'SocialListeningLogo',
  OfficeVideoLogoInverse = 'OfficeVideoLogoInverse',
  ReopenPages = 'ReopenPages',
  EmojiTabSymbols = 'EmojiTabSymbols',
  AADLogo = 'AADLogo',
  AccessLogo = 'AccessLogo',
  AdminALogoInverse32 = 'AdminALogoInverse32',
  AdminCLogoInverse32 = 'AdminCLogoInverse32',
  AdminDLogoInverse32 = 'AdminDLogoInverse32',
  AdminELogoInverse32 = 'AdminELogoInverse32',
  AdminLLogoInverse32 = 'AdminLLogoInverse32',
  AdminMLogoInverse32 = 'AdminMLogoInverse32',
  AdminOLogoInverse32 = 'AdminOLogoInverse32',
  AdminPLogoInverse32 = 'AdminPLogoInverse32',
  AdminSLogoInverse32 = 'AdminSLogoInverse32',
  AdminYLogoInverse32 = 'AdminYLogoInverse32',
  DelveLogoInverse = 'DelveLogoInverse',
  ExchangeLogoInverse = 'ExchangeLogoInverse',
  LyncLogo = 'LyncLogo',
  RemoveLink = 'RemoveLink',
  PeopleBlock = 'PeopleBlock',
  PeopleRepeat = 'PeopleRepeat',
  PeopleAlert = 'PeopleAlert',
  Ascending = 'Ascending',
  Descending = 'Descending',
  TextBox = 'TextBox',
  TextField = 'TextField',
  NumberField = 'NumberField',
  Dropdown = 'Dropdown',
  PenWorkspace = 'PenWorkspace',
  BookingsLogo = 'BookingsLogo',
  ClassNotebookLogoInverse = 'ClassNotebookLogoInverse',
  DelveAnalyticsLogo = 'DelveAnalyticsLogo',
  DocsLogoInverse = 'DocsLogoInverse',
  Dynamics365Logo = 'Dynamics365Logo',
  DynamicSMBLogo = 'DynamicSMBLogo',
  OfficeAssistantLogo = 'OfficeAssistantLogo',
  OfficeStoreLogo = 'OfficeStoreLogo',
  OneNoteEduLogoInverse = 'OneNoteEduLogoInverse',
  PlannerLogo = 'PlannerLogo',
  PowerApps = 'PowerApps',
  Suitcase = 'Suitcase',
  DoubleChevronRight = 'DoubleChevronRight',
  CompanyDirectoryMirrored = 'CompanyDirectoryMirrored',
  DoubleChevronLeft = 'DoubleChevronLeft',
  Split = 'Split',
  PeoplePause = 'PeoplePause',
  TransferCall = 'TransferCall',
  AddPhone = 'AddPhone',
  UnknownCall = 'UnknownCall',
  NoteReply = 'NoteReply',
  NoteForward = 'NoteForward',
  NotePinned = 'NotePinned',
  RemoveOccurrence = 'RemoveOccurrence',
  Timeline = 'Timeline',
  EditNote = 'EditNote',
  CircleHalfFull = 'CircleHalfFull',
  Room = 'Room',
  Unsubscribe = 'Unsubscribe',
  Subscribe = 'Subscribe',
  HardDrive = 'HardDrive',
  RecurringTask = 'RecurringTask',
  TaskManager = 'TaskManager',
  TaskManagerMirrored = 'TaskManagerMirrored',
  Combine = 'Combine',
  DoubleChevronUp = 'DoubleChevronUp',
  OneDriveAdd = 'OneDriveAdd',
  ProfileSearch = 'ProfileSearch',
  Header2 = 'Header2',
  SearchIssue = 'SearchIssue',
  SearchIssueMirrored = 'SearchIssueMirrored',
  FabricAssetLibrary = 'FabricAssetLibrary',
  FabricDataConnectionLibrary = 'FabricDataConnectionLibrary',
  FabricDocLibrary = 'FabricDocLibrary',
  FabricFormLibrary = 'FabricFormLibrary',
  FabricFormLibraryMirrored = 'FabricFormLibraryMirrored',
  FabricReportLibrary = 'FabricReportLibrary',
  PowerApps2Logo = 'PowerApps2Logo',
  FabricReportLibraryMirrored = 'FabricReportLibraryMirrored',
  FabricFolderSearch = 'FabricFolderSearch',
  FabricMovetoFolder = 'FabricMovetoFolder',
  FabricUnsyncFolder = 'FabricUnsyncFolder',
  FabricSyncFolder = 'FabricSyncFolder',
  FabricOpenFolderHorizontal = 'FabricOpenFolderHorizontal',
  FabricFolder = 'FabricFolder',
  FabricFolderFill = 'FabricFolderFill',
  FabricNewFolder = 'FabricNewFolder',
  FabricPublicFolder = 'FabricPublicFolder',
  PowerAppsLogo = 'PowerAppsLogo',
  CaretSolidUp = 'CaretSolidUp',
  CaretSolidRight = 'CaretSolidRight',
  ZipFolder = 'ZipFolder',
  SurveyQuestions = 'SurveyQuestions',
  TextDocument = 'TextDocument',
  TextDocumentShared = 'TextDocumentShared',
  PageCheckedOut = 'PageCheckedOut',
  PageShared = 'PageShared',
  SaveAndClose = 'SaveAndClose',
  Script = 'Script',
  Archive = 'Archive',
  MessageFriendRequest = 'MessageFriendRequest',
  ActivityFeed = 'ActivityFeed',
  Compare = 'Compare',
  EventDate = 'EventDate',
  ArrowUpRight = 'ArrowUpRight',
  CaretRight = 'CaretRight',
  SetAction = 'SetAction',
  ChatBot = 'ChatBot',
  CaretSolidLeft = 'CaretSolidLeft',
  CaretSolidDown = 'CaretSolidDown',
  FabricPictureLibrary = 'FabricPictureLibrary',
  PhotoVideoMedia = 'PhotoVideoMedia',
  AddFavorite = 'AddFavorite',
  AddFavoriteFill = 'AddFavoriteFill',
  StatusTriangleExclamation = 'StatusTriangleExclamation',
  StatusCircleExclamation = 'StatusCircleExclamation',
  StatusCircleErrorX = 'StatusCircleErrorX',
  StatusCircleCheckmark = 'StatusCircleCheckmark',
  StatusCircleInfo = 'StatusCircleInfo',
  StatusCircleBlock = 'StatusCircleBlock',
  StatusCircleBlock2 = 'StatusCircleBlock2',
  StatusCircleQuestionMark = 'StatusCircleQuestionMark',
  StatusCircleSync = 'StatusCircleSync',
  Toll = 'Toll',
  ExploreContentSingle = 'ExploreContentSingle',
  CollapseContent = 'CollapseContent',
  CollapseContentSingle = 'CollapseContentSingle',
  InfoSolid = 'InfoSolid',
  GroupList = 'GroupList',
  ProgressRingDots = 'ProgressRingDots',
  CheckboxIndeterminateCombo = 'CheckboxIndeterminateCombo',
  CaloriesAdd = 'CaloriesAdd',
  BranchFork = 'BranchFork',
  StatusTriangleInner = 'StatusTriangleInner',
  HomeVerify = 'HomeVerify',
  StatusTriangleOuter = 'StatusTriangleOuter',
  StatusCircleInner = 'StatusCircleInner',
  BufferTimeBefore = 'BufferTimeBefore',
  BufferTimeAfter = 'BufferTimeAfter',
  BufferTimeBoth = 'BufferTimeBoth',
  PublishContent = 'PublishContent',
  ClipboardList = 'ClipboardList',
  ClipboardListMirrored = 'ClipboardListMirrored',
  CannedChat = 'CannedChat',
  SkypeForBusinessLogo = 'SkypeForBusinessLogo',
  TabCenter = 'TabCenter',
  PageCheckedin = 'PageCheckedin',
  PageList = 'PageList',
  ReadOutLoud = 'ReadOutLoud',
  ScreenPreviewOn = 'ScreenPreviewOn',
  CaretBottomLeftSolid8 = 'CaretBottomLeftSolid8',
  CaretBottomRightSolid8 = 'CaretBottomRightSolid8',
  FolderHorizontal = 'FolderHorizontal',
  MicrosoftStaffhubLogo = 'MicrosoftStaffhubLogo',
  GiftboxOpen = 'GiftboxOpen',
  StatusCircleOuter = 'StatusCircleOuter',
  StatusCircleRing = 'StatusCircleRing',
  Trophy = 'Trophy',
  Relationship = 'Relationship',
  CRMReport = 'CRMReport',
  LifesaverLock = 'LifesaverLock',
  InboxCheck = 'InboxCheck',
  FolderSearch = 'FolderSearch',
  CollapseMenu = 'CollapseMenu',
  ExpandMenu = 'ExpandMenu',
  Boards = 'Boards',
  SunAdd = 'SunAdd',
  SunQuestionMark = 'SunQuestionMark',
  Lifesaver = 'Lifesaver',
  LandscapeOrientation = 'LandscapeOrientation',
  PublicCalendar = 'PublicCalendar',
  PublicContactCard = 'PublicContactCard',
  PublicEmail = 'PublicEmail',
  PublicFolder = 'PublicFolder',
  WordDocument = 'WordDocument',
  PowerPointDocument = 'PowerPointDocument',
  ExcelDocument = 'ExcelDocument',
  GroupedList = 'GroupedList',
  DocumentSearch = 'DocumentSearch',
  EditMail = 'EditMail',
  EditStyle = 'EditStyle',
  ReplyAllAlt = 'ReplyAllAlt',
  Header3 = 'Header3',
  Header4 = 'Header4',
  RingerSolid = 'RingerSolid',
  Eyedropper = 'Eyedropper',
  MarketDown = 'MarketDown',
  CalendarWorkWeek = 'CalendarWorkWeek',
  SidePanel = 'SidePanel',
  GlobeFavorite = 'GlobeFavorite',
  CaretTopLeftSolid8 = 'CaretTopLeftSolid8',
  CaretTopRightSolid8 = 'CaretTopRightSolid8',
  ViewAll2 = 'ViewAll2',
  DocumentReply = 'DocumentReply',
  PlayerSettings = 'PlayerSettings',
  ReceiptForward = 'ReceiptForward',
  ReceiptReply = 'ReceiptReply',
  ReceiptCheck = 'ReceiptCheck',
  Fax = 'Fax',
  RecurringEvent = 'RecurringEvent',
  ReplyAlt = 'ReplyAlt',
  ClassroomLogo = 'ClassroomLogo',
  Sections = 'Sections',
  EditPhoto = 'EditPhoto',
  Starburst = 'Starburst',
  FixedAssetManagement = 'FixedAssetManagement',
  TimeEntry = 'TimeEntry',
  CRMProcesses = 'CRMProcesses',
  PageEdit = 'PageEdit',
  PageArrowRight = 'PageArrowRight',
  PageRemove = 'PageRemove',
  Database = 'Database',
  DataManagementSettings = 'DataManagementSettings',
  CRMServices = 'CRMServices',
  EditContact = 'EditContact',
  ConnectContacts = 'ConnectContacts',
  AppIconDefaultAdd = 'AppIconDefaultAdd',
  AppIconDefaultList = 'AppIconDefaultList',
  ActivateOrders = 'ActivateOrders',
  DeactivateOrders = 'DeactivateOrders',
  ProductCatalog = 'ProductCatalog',
  ScatterChart = 'ScatterChart',
  AccountActivity = 'AccountActivity',
  DocumentManagement = 'DocumentManagement',
  Flow = 'Flow',
  KnowledgeArticle = 'KnowledgeArticle',
  ClearFilter = 'ClearFilter',
  Breadcrumb = 'Breadcrumb',
  ShareiOS = 'ShareiOS',
  AirTickets = 'AirTickets',
  PencilReply = 'PencilReply',
  Tiles2 = 'Tiles2',
  SkypeCircleCheck = 'SkypeCircleCheck',
  SkypeCircleClock = 'SkypeCircleClock',
  SkypeCircleMinus = 'SkypeCircleMinus',
  SkypeCheck = 'SkypeCheck',
  SkypeClock = 'SkypeClock',
  SkypeMinus = 'SkypeMinus',
  SkypeMessage = 'SkypeMessage',
  ClosedCaption = 'ClosedCaption',
  ATPLogo = 'ATPLogo',
  OfficeFormsLogoInverse = 'OfficeFormsLogoInverse',
  RecycleBin = 'RecycleBin',
  EmptyRecycleBin = 'EmptyRecycleBin',
  Hide2 = 'Hide2',
  BirthdayCake = 'BirthdayCake',
  SkipForward30 = 'SkipForward30',
  SkipBack10 = 'SkipBack10',
  OutOfOffice = 'OutOfOffice',
  DateTime2 = 'DateTime2',
  Shield = 'Shield',
  Header1 = 'Header1',
  PageAdd = 'PageAdd',
  NumberedList = 'NumberedList',
  PowerBILogo = 'PowerBILogo',
  Info2 = 'Info2',
  MusicInCollectionFill = 'MusicInCollectionFill',
  Glasses = 'Glasses',
  List = 'List',
  ErrorBadge = 'ErrorBadge',
  CircleRing = 'CircleRing',
  CircleFill = 'CircleFill',
  Record2 = 'Record2',
  AllAppsMirrored = 'AllAppsMirrored',
  BookmarksMirrored = 'BookmarksMirrored',
  BulletedListMirrored = 'BulletedListMirrored',
  CaretHollowMirrored = 'CaretHollowMirrored',
  Asterisk = 'Asterisk',
  PeopleAdd = 'PeopleAdd',
  HailNight = 'HailNight',
  Teamwork = 'Teamwork',
  BarChartVertical = 'BarChartVertical',
  Freezing = 'Freezing',
  FunnelChart = 'FunnelChart',
  Processing = 'Processing',
  Quantity = 'Quantity',
  ReportDocument = 'ReportDocument',
  StackColumnChart = 'StackColumnChart',
  SnowShowerDay = 'SnowShowerDay',
  HailDay = 'HailDay',
  WorkFlow = 'WorkFlow',
  LightSnow = 'LightSnow',
  HourGlass = 'HourGlass',
  StoreLogoMed20 = 'StoreLogoMed20',
  TimeSheet = 'TimeSheet',
  TriangleSolid = 'TriangleSolid',
  UpgradeAnalysis = 'UpgradeAnalysis',
  VideoSolid = 'VideoSolid',
  RainShowersNight = 'RainShowersNight',
  SnowShowerNight = 'SnowShowerNight',
  CaretSolidMirrored = 'CaretSolidMirrored',
  ChromeBackMirrored = 'ChromeBackMirrored',
  ClearSelectionMirrored = 'ClearSelectionMirrored',
  ClosePaneMirrored = 'ClosePaneMirrored',
  Lightbulb = 'Lightbulb',
  StatusTriangle = 'StatusTriangle',
  VolumeDisabled = 'VolumeDisabled',
  Puzzle = 'Puzzle',
  EmojiNeutral = 'EmojiNeutral',
  EmojiDisappointed = 'EmojiDisappointed',
  HomeSolid = 'HomeSolid',
  Ringer = 'Ringer',
  PDF = 'PDF',
  HeartBroken = 'HeartBroken',
  StoreLogo16 = 'StoreLogo16',
  MultiSelectMirrored = 'MultiSelectMirrored',
  Broom = 'Broom',
  AddToShoppingList = 'AddToShoppingList',
  Cocktails = 'Cocktails',
  Wines = 'Wines',
  Articles = 'Articles',
  Cycling = 'Cycling',
  DietPlanNotebook = 'DietPlanNotebook',
  VideoLightOff = 'VideoLightOff',
  BarChartHorizontal = 'BarChartHorizontal',
  Focus = 'Focus',
  Devices3 = 'Devices3',
  DockLeftMirrored = 'DockLeftMirrored',
  DoubleChevronLeftMedMirrored = 'DoubleChevronLeftMedMirrored',
  GoMirrored = 'GoMirrored',
  HelpMirrored = 'HelpMirrored',
  ImportMirrored = 'ImportMirrored',
  ImportAllMirrored = 'ImportAllMirrored',
  ListMirrored = 'ListMirrored',
  MailForwardMirrored = 'MailForwardMirrored',
  MailReplyMirrored = 'MailReplyMirrored',
  MailReplyAllMirrored = 'MailReplyAllMirrored',
  MiniContractMirrored = 'MiniContractMirrored',
  MiniExpandMirrored = 'MiniExpandMirrored',
  OpenPaneMirrored = 'OpenPaneMirrored',
  OpenWithMirrored = 'OpenWithMirrored',
  ParkingLocationMirrored = 'ParkingLocationMirrored',
  SendMirrored = 'SendMirrored',
  ShowResultsMirrored = 'ShowResultsMirrored',
  ThumbnailViewMirrored = 'ThumbnailViewMirrored',
  Media = 'Media',
  FlashOff = 'FlashOff',
  Pill = 'Pill',
  Equalizer = 'Equalizer',
  Total = 'Total',
  CalculatorEqualTo = 'CalculatorEqualTo',
  PrintfaxPrinterFile = 'PrintfaxPrinterFile',
  StorageOptical = 'StorageOptical',
  Communications = 'Communications',
  Headset = 'Headset',
  FlashAuto = 'FlashAuto',
  Health = 'Health',
  Webcam2 = 'Webcam2',
  CalculatorPercentage = 'CalculatorPercentage',
  FrontCamera = 'FrontCamera',
  ChevronDownSmall = 'ChevronDownSmall',
  ChevronLeftSmall = 'ChevronLeftSmall',
  ChevronRightSmall = 'ChevronRightSmall',
  ChevronUpMed = 'ChevronUpMed',
  ChevronDownMed = 'ChevronDownMed',
  ChevronLeftMed = 'ChevronLeftMed',
  ChevronRightMed = 'ChevronRightMed',
  Devices2 = 'Devices2',
  ChevronUpSmall = 'ChevronUpSmall',
  CalculatorSubtract = 'CalculatorSubtract',
  CalculatorAddition = 'CalculatorAddition',
  CalculatorMultiply = 'CalculatorMultiply',
  Annotation = 'Annotation',
  Fingerprint = 'Fingerprint',
  Handwriting = 'Handwriting',
  ChromeFullScreen = 'ChromeFullScreen',
  Completed = 'Completed',
  Label = 'Label',
  FlickDown = 'FlickDown',
  FlickUp = 'FlickUp',
  FlickLeft = 'FlickLeft',
  FlickRight = 'FlickRight',
  MiniExpand = 'MiniExpand',
  MiniContract = 'MiniContract',
  Streaming = 'Streaming',
  MusicInCollection = 'MusicInCollection',
  OneDriveLogo = 'OneDriveLogo',
  CompassNW = 'CompassNW',
  Code = 'Code',
  LightningBolt = 'LightningBolt',
  Info = 'Info',
  PC1 = 'PC1',
  PresenceChickletVideo = 'PresenceChickletVideo',
  Reply = 'Reply',
  HalfAlpha = 'HalfAlpha',
  Rain = 'Rain',
  Thunderstorms = 'Thunderstorms',
  RainSnow = 'RainSnow',
  Snow = 'Snow',
  BlowingSnow = 'BlowingSnow',
  Frigid = 'Frigid',
  Fog = 'Fog',
  Squalls = 'Squalls',
  Duststorm = 'Duststorm',
  Unknown = 'Unknown',
  Precipitation = 'Precipitation',
  SortLines = 'SortLines',
  Ribbon = 'Ribbon',
  AreaChart = 'AreaChart',
  Assign = 'Assign',
  FlowChart = 'FlowChart',
  CheckList = 'CheckList',
  Diagnostic = 'Diagnostic',
  Generate = 'Generate',
  RainShowersDay = 'RainShowersDay',
  LineChart = 'LineChart',
  ClearNight = 'ClearNight',
  PartlyCloudyDay = 'PartlyCloudyDay',
  ConstructionCone = 'ConstructionCone',
  DoubleChevronLeftMed = 'DoubleChevronLeftMed',
  Volume0 = 'Volume0',
  Volume1 = 'Volume1',
  Volume2 = 'Volume2',
  Volume3 = 'Volume3',
  Chart = 'Chart',
  Robot = 'Robot',
  Manufacturing = 'Manufacturing',
  LockSolid = 'LockSolid',
  FitPage = 'FitPage',
  FitWidth = 'FitWidth',
  BidiLtr = 'BidiLtr',
  BidiRtl = 'BidiRtl',
  RightDoubleQuote = 'RightDoubleQuote',
  Sunny = 'Sunny',
  CloudWeather = 'CloudWeather',
  Cloudy = 'Cloudy',
  PartlyCloudyNight = 'PartlyCloudyNight',
  MuteChat = 'MuteChat',
  ExerciseTracker = 'ExerciseTracker',
  Medical = 'Medical',
  EdgeOldLogo = 'EdgeOldLogo',
  CompletedSolid = 'CompletedSolid',
  AlbumRemove = 'AlbumRemove',
  MessageFill = 'MessageFill',
  TabletSelected = 'TabletSelected',
  MobileSelected = 'MobileSelected',
  LaptopSelected = 'LaptopSelected',
  TVMonitorSelected = 'TVMonitorSelected',
  PlaybackRate1x = 'PlaybackRate1x',
  DeveloperTools = 'DeveloperTools',
  InsertTextBox = 'InsertTextBox',
  LowerBrightness = 'LowerBrightness',
  WebComponents = 'WebComponents',
  OfflineStorage = 'OfflineStorage',
  DOM = 'DOM',
  CloudUpload = 'CloudUpload',
  ScrollUpDown = 'ScrollUpDown',
  DateTime = 'DateTime',
  Shapes = 'Shapes',
  MicOff = 'MicOff',
  MusicNote = 'MusicNote',
  ThisPC = 'ThisPC',
  Section = 'Section',
  OneNoteLogoInverse = 'OneNoteLogoInverse',
  ToggleFilled = 'ToggleFilled',
  ToggleBorder = 'ToggleBorder',
  SliderThumb = 'SliderThumb',
  ToggleThumb = 'ToggleThumb',
  Documentation = 'Documentation',
  Badge = 'Badge',
  Giftbox = 'Giftbox',
  VisualStudioLogo = 'VisualStudioLogo',
  HomeGroup = 'HomeGroup',
  MyNetwork = 'MyNetwork',
  ExcelLogoInverse = 'ExcelLogoInverse',
  WordLogoInverse = 'WordLogoInverse',
  PowerPointLogoInverse = 'PowerPointLogoInverse',
  Cafe = 'Cafe',
  SpeedHigh = 'SpeedHigh',
  Commitments = 'Commitments',
  Event = 'Event',
  Cake = 'Cake',
  Tiles = 'Tiles',
  Org = 'Org',
  PageHeader = 'PageHeader',
  Glimmer = 'Glimmer',
  ChatInviteFriend = 'ChatInviteFriend',
  Brush = 'Brush',
  Shirt = 'Shirt',
  Crown = 'Crown',
  Diamond = 'Diamond',
  ScaleUp = 'ScaleUp',
  ResetDevice = 'ResetDevice',
  QRCode = 'QRCode',
  Feedback = 'Feedback',
  SharepointLogoInverse = 'SharepointLogoInverse',
  YammerLogo = 'YammerLogo',
  Hide = 'Hide',
  Uneditable = 'Uneditable',
  ReturnToSession = 'ReturnToSession',
  OpenFolderHorizontal = 'OpenFolderHorizontal',
  CalendarMirrored = 'CalendarMirrored',
  SwayLogoInverse = 'SwayLogoInverse',
  Toolbox = 'Toolbox',
  CityNext2 = 'CityNext2',
  FangBody = 'FangBody',
  Hot = 'Hot',
  PartyLeader = 'PartyLeader',
  DRM = 'DRM',
  CloudAdd = 'CloudAdd',
  AppIconDefault = 'AppIconDefault',
  Photo2Add = 'Photo2Add',
  Photo2Remove = 'Photo2Remove',
  Calories = 'Calories',
  POI = 'POI',
  RemoveContent = 'RemoveContent',
  AddTo = 'AddTo',
  RadioBtnOff = 'RadioBtnOff',
  RadioBtnOn = 'RadioBtnOn',
  ExploreContent = 'ExploreContent',
  Embed = 'Embed',
  Product = 'Product',
  ProgressLoopInner = 'ProgressLoopInner',
  ProgressLoopOuter = 'ProgressLoopOuter',
  Blocked2 = 'Blocked2',
  HandsFree = 'HandsFree',
  CityNext = 'CityNext',
  Umbrella = 'Umbrella',
  Cricket = 'Cricket',
  Golf = 'Golf',
  Baseball = 'Baseball',
  Soccer = 'Soccer',
  MoreSports = 'MoreSports',
  AutoRacing = 'AutoRacing',
  CollegeHoops = 'CollegeHoops',
  CollegeFootball = 'CollegeFootball',
  MSNVideos = 'MSNVideos',
  ProFootball = 'ProFootball',
  Rugby = 'Rugby',
  SubstitutionsIn = 'SubstitutionsIn',
  Tennis = 'Tennis',
  Arrivals = 'Arrivals',
  Design = 'Design',
  Website = 'Website',
  Drop = 'Drop',
  HistoricalWeather = 'HistoricalWeather',
  ProHockey = 'ProHockey',
  Source = 'Source',
  Lamp = 'Lamp',
  StockUp = 'StockUp',
  Running = 'Running',
  Weights = 'Weights',
  Trackers = 'Trackers',
  Vaccination = 'Vaccination',
  AddNotes = 'AddNotes',
  AllCurrency = 'AllCurrency',
  BarChart4 = 'BarChart4',
  CirclePlus = 'CirclePlus',
  Coffee = 'Coffee',
  Cotton = 'Cotton',
  Currency = 'Currency',
  Market = 'Market',
  Money = 'Money',
  PieDouble = 'PieDouble',
  PieSingle = 'PieSingle',
  RemoveFilter = 'RemoveFilter',
  Savings = 'Savings',
  Sell = 'Sell',
  StockDown = 'StockDown',
  SkiResorts = 'SkiResorts',
  Snowflake = 'Snowflake',
  BusSolid = 'BusSolid',
  FerrySolid = 'FerrySolid',
  FastForward = 'FastForward',
  Rewind = 'Rewind',
  Photo2 = 'Photo2',
  OpenSource = 'OpenSource',
  Movers = 'Movers',
  CloudDownload = 'CloudDownload',
  Family = 'Family',
  WindDirection = 'WindDirection',
  Bug = 'Bug',
  SiteScan = 'SiteScan',
  BrowserScreenShot = 'BrowserScreenShot',
  F12DevTools = 'F12DevTools',
  CSS = 'CSS',
  JS = 'JS',
  DeliveryTruck = 'DeliveryTruck',
  ReminderPerson = 'ReminderPerson',
  ReminderGroup = 'ReminderGroup',
  ReminderTime = 'ReminderTime',
  TabletMode = 'TabletMode',
  Certificate = 'Certificate',
  NetworkTower = 'NetworkTower',
  StatusErrorFull = 'StatusErrorFull',
  GiftCard = 'GiftCard',
  EndPointSolid = 'EndPointSolid',
  AirplaneSolid = 'AirplaneSolid',
  TrainSolid = 'TrainSolid',
  Heart = 'Heart',
  HeartFill = 'HeartFill',
  Ticket = 'Ticket',
  WifiWarning4 = 'WifiWarning4',
  Devices4 = 'Devices4',
  NUIFace = 'NUIFace',
  AzureLogo = 'AzureLogo',
  BingLogo = 'BingLogo',
  MSNLogo = 'MSNLogo',
  OutlookLogoInverse = 'OutlookLogoInverse',
  OfficeLogo = 'OfficeLogo',
  SkypeLogo = 'SkypeLogo',
  Door = 'Door',
  EditMirrored = 'EditMirrored',
  DoubleBookmark = 'DoubleBookmark',
  AddHome = 'AddHome',
  AddWork = 'AddWork',
  ScreenTime = 'ScreenTime',
  AlignVerticalBottom = 'AlignVerticalBottom',
  HorizontalDistributeCenter = 'HorizontalDistributeCenter',
  VerticalDistributeCenter = 'VerticalDistributeCenter',
  Ellipse = 'Ellipse',
  Line = 'Line',
  Octagon = 'Octagon',
  Hexagon = 'Hexagon',
  Pentagon = 'Pentagon',
  AlignVerticalCenter = 'AlignVerticalCenter',
  RightTriangle = 'RightTriangle',
  QuarterCircle = 'QuarterCircle',
  ThreeQuarterCircle = 'ThreeQuarterCircle',
  SixPointStar = 'SixPointStar',
  TwelvePointStar = 'TwelvePointStar',
  ArrangeBringToFront = 'ArrangeBringToFront',
  ArrangeSendToBack = 'ArrangeSendToBack',
  ArrangeSendBackward = 'ArrangeSendBackward',
  ArrangeBringForward = 'ArrangeBringForward',
  HalfCircle = 'HalfCircle',
  AlignVerticalTop = 'AlignVerticalTop',
  AlignHorizontalRight = 'AlignHorizontalRight',
  AlignHorizontalCenter = 'AlignHorizontalCenter',
  ToDoLogoInverse = 'ToDoLogoInverse',
  Snooze = 'Snooze',
  CortanaLogoBeckonInner = 'CortanaLogoBeckonInner',
  CortanaLogoBeckonOuter = 'CortanaLogoBeckonOuter',
  CortanaLogoReadyInner = 'CortanaLogoReadyInner',
  CortanaLogoReadyOuter = 'CortanaLogoReadyOuter',
  WaffleOffice365 = 'WaffleOffice365',
  ImageSearch = 'ImageSearch',
  NewsSearch = 'NewsSearch',
  VideoSearch = 'VideoSearch',
  R = 'R',
  FontColorA = 'FontColorA',
  FontColorSwatch = 'FontColorSwatch',
  LightWeight = 'LightWeight',
  NormalWeight = 'NormalWeight',
  SemiboldWeight = 'SemiboldWeight',
  GroupObject = 'GroupObject',
  UngroupObject = 'UngroupObject',
  AlignHorizontalLeft = 'AlignHorizontalLeft',
  BorderDash = 'BorderDash',
  BorderDot = 'BorderDot',
  LineStyle = 'LineStyle',
  LineThickness = 'LineThickness',
  PictureStretch = 'PictureStretch',
  PictureTile = 'PictureTile',
  Slider = 'Slider',
  SliderHandleSize = 'SliderHandleSize',
  DefaultRatio = 'DefaultRatio',
  NumberSequence = 'NumberSequence',
  GUID = 'GUID',
  ReportAdd = 'ReportAdd',
  DashboardAdd = 'DashboardAdd',
  MapPinSolid = 'MapPinSolid',
  WebPublish = 'WebPublish',
  PieSingleSolid = 'PieSingleSolid',
  BlockedSolid = 'BlockedSolid',
  DrillDown = 'DrillDown',
  DrillDownSolid = 'DrillDownSolid',
  DrillExpand = 'DrillExpand',
  DrillShow = 'DrillShow',
  SpecialEvent = 'SpecialEvent',
  OneDriveFolder16 = 'OneDriveFolder16',
  PicturePosition = 'PicturePosition',
  AdminALogoFill32 = 'AdminALogoFill32',
  PictureFill = 'PictureFill',
  VisualsStore = 'VisualsStore',
  WindowEdit = 'WindowEdit',
  HintText = 'HintText',
  MediaAdd = 'MediaAdd',
  AnchorLock = 'AnchorLock',
  AutoHeight = 'AutoHeight',
  ChartSeries = 'ChartSeries',
  ChartXAngle = 'ChartXAngle',
  ChartYAngle = 'ChartYAngle',
  Combobox = 'Combobox',
  LineSpacing = 'LineSpacing',
  Padding = 'Padding',
  PaddingTop = 'PaddingTop',
  PaddingBottom = 'PaddingBottom',
  PaddingLeft = 'PaddingLeft',
  PaddingRight = 'PaddingRight',
  NavigationFlipper = 'NavigationFlipper',
  AlignJustify = 'AlignJustify',
  TextOverflow = 'TextOverflow',
  VisualsFolder = 'VisualsFolder',
  PictureCenter = 'PictureCenter',
  AdminALogo32 = 'AdminALogo32',
  UneditableSolidMirrored12 = 'UneditableSolidMirrored12',
  OfficeFormsLogo = 'OfficeFormsLogo',
  OfficeFormsLogo16 = 'OfficeFormsLogo16',
  OfficeFormsLogoInverse24 = 'OfficeFormsLogoInverse24',
  OfficeFormsLogo24 = 'OfficeFormsLogo24',
  PageLock = 'PageLock',
  NotExecuted = 'NotExecuted',
  NotImpactedSolid = 'NotImpactedSolid',
  FieldReadOnly = 'FieldReadOnly',
  OfficeFormsLogoInverse16 = 'OfficeFormsLogoInverse16',
  FieldRequired = 'FieldRequired',
  ExternalBuild = 'ExternalBuild',
  ExternalTFVC = 'ExternalTFVC',
  ExternalXAML = 'ExternalXAML',
  IssueSolid = 'IssueSolid',
  DefectSolid = 'DefectSolid',
  LadybugSolid = 'LadybugSolid',
  NugetLogo = 'NugetLogo',
  BacklogBoard = 'BacklogBoard',
  BackgroundColor = 'BackgroundColor',
  IDBadge = 'IDBadge',
  CustomerAssets = 'CustomerAssets',
  SkypeForBusinessLogoFill16 = 'SkypeForBusinessLogoFill16',
  FilterSolid = 'FilterSolid',
  MailUndelivered = 'MailUndelivered',
  MailTentative = 'MailTentative',
  MailTentativeMirrored = 'MailTentativeMirrored',
  MailReminder = 'MailReminder',
  ReceiptUndelivered = 'ReceiptUndelivered',
  ReceiptTentative = 'ReceiptTentative',
  ReceiptTentativeMirrored = 'ReceiptTentativeMirrored',
  Inbox = 'Inbox',
  IRMReply = 'IRMReply',
  IRMReplyMirrored = 'IRMReplyMirrored',
  IRMForward = 'IRMForward',
  IRMForwardMirrored = 'IRMForwardMirrored',
  VoicemailIRM = 'VoicemailIRM',
  EventAccepted = 'EventAccepted',
  EventTentative = 'EventTentative',
  EventTentativeMirrored = 'EventTentativeMirrored',
  EventDeclined = 'EventDeclined',
  TFVCLogo = 'TFVCLogo',
  ProjectLogo32 = 'ProjectLogo32',
  ProjectLogo16 = 'ProjectLogo16',
  SwayLogo32 = 'SwayLogo32',
  Brunch = 'Brunch',
  BeerMug = 'BeerMug',
  Vacation = 'Vacation',
  Teeth = 'Teeth',
  Taxi = 'Taxi',
  Chopsticks = 'Chopsticks',
  SyncOccurence = 'SyncOccurence',
  UnsyncOccurence = 'UnsyncOccurence',
  GIF = 'GIF',
  PrimaryCalendar = 'PrimaryCalendar',
  SearchCalendar = 'SearchCalendar',
  VideoOff = 'VideoOff',
  MicrosoftFlowLogo = 'MicrosoftFlowLogo',
  BusinessCenterLogo = 'BusinessCenterLogo',
  ToDoLogoBottom = 'ToDoLogoBottom',
  ToDoLogoTop = 'ToDoLogoTop',
  EditSolid12 = 'EditSolid12',
  EditSolidMirrored12 = 'EditSolidMirrored12',
  UneditableSolid12 = 'UneditableSolid12',
  Breakfast = 'Breakfast',
  UneditableMirrored = 'UneditableMirrored',
  Guitar = 'Guitar',
  GallatinLogo = 'GallatinLogo',
  SwayLogoFill32 = 'SwayLogoFill32',
  SwayLogo16 = 'SwayLogo16',
  SwayLogoFill16 = 'SwayLogoFill16',
  ClassNotebookLogo32 = 'ClassNotebookLogo32',
  ClassNotebookLogoFill32 = 'ClassNotebookLogoFill32',
  ClassNotebookLogo16 = 'ClassNotebookLogo16',
  ClassNotebookLogoFill16 = 'ClassNotebookLogoFill16',
  ClassNotebookLogoInverse32 = 'ClassNotebookLogoInverse32',
  ClassNotebookLogoInverse16 = 'ClassNotebookLogoInverse16',
  StaffNotebookLogo32 = 'StaffNotebookLogo32',
  StaffNotebookLogoFill32 = 'StaffNotebookLogoFill32',
  StaffNotebookLogo16 = 'StaffNotebookLogo16',
  StaffNotebookLogoFill16 = 'StaffNotebookLogoFill16',
  StaffNotebookLogoInverted32 = 'StaffNotebookLogoInverted32',
  StaffNotebookLogoInverted16 = 'StaffNotebookLogoInverted16',
  KaizalaLogo = 'KaizalaLogo',
  TaskLogo = 'TaskLogo',
  ProtectionCenterLogo32 = 'ProtectionCenterLogo32',
  Globe2 = 'Globe2',
  SkypeForBusinessLogo16 = 'SkypeForBusinessLogo16',
  FunctionalManagerDashboard = 'FunctionalManagerDashboard',
  CodeEdit = 'CodeEdit',
  ButtonControl = 'ButtonControl',
  TableGroup = 'TableGroup',
  MountainClimbing = 'MountainClimbing',
  TagUnknown = 'TagUnknown',
  TagUnknownMirror = 'TagUnknownMirror',
  TagUnknown12 = 'TagUnknown12',
  TagUnknown12Mirror = 'TagUnknown12Mirror',
  Link12 = 'Link12',
  BacklogList = 'BacklogList',
  Presentation = 'Presentation',
  Lock12 = 'Lock12',
  BuildDefinition = 'BuildDefinition',
  ReleaseDefinition = 'ReleaseDefinition',
  SaveTemplate = 'SaveTemplate',
  UserGauge = 'UserGauge',
  BlockedSiteSolid12 = 'BlockedSiteSolid12',
  TagSolid = 'TagSolid',
  OfficeChat = 'OfficeChat',
  Presentation12 = 'Presentation12',
  D365CoreHR = 'D365CoreHR',
  D365TalentInsight = 'D365TalentInsight',
  D365TalentLearn = 'D365TalentLearn',
  ArrangeByFrom = 'ArrangeByFrom',
  Phishing = 'Phishing',
  CreateMailRule = 'CreateMailRule',
  Number = 'Number',
  PublishCourse = 'PublishCourse',
  DictionaryRemove = 'DictionaryRemove',
  UserRemove = 'UserRemove',
  UserEvent = 'UserEvent',
  Encryption = 'Encryption',
  LiveSite = 'LiveSite',
  PasswordField = 'PasswordField',
  OpenInNewTab = 'OpenInNewTab',
  Hide3 = 'Hide3',
  VerifiedBrandSolid = 'VerifiedBrandSolid',
  MarkAsProtected = 'MarkAsProtected',
  AuthenticatorApp = 'AuthenticatorApp',
  WebTemplate = 'WebTemplate',
  DefenderTVM = 'DefenderTVM',
  MedalSolid = 'MedalSolid',
  OfficeChatSolid = 'OfficeChatSolid',
  MailSchedule = 'MailSchedule',
  PowerShell2 = 'PowerShell2',
  WarningSolid = 'WarningSolid',
  BarChartVerticalFilterSolid = 'BarChartVerticalFilterSolid',
  MicOff2 = 'MicOff2',
  MicrosoftTranslatorLogo = 'MicrosoftTranslatorLogo',
  ShowTimeAs = 'ShowTimeAs',
  FileRequest = 'FileRequest',
  AcceptMedium = 'AcceptMedium',
  WorkItemAlert = 'WorkItemAlert',
  PowerBILogo16 = 'PowerBILogo16',
  PowerBILogoBackplate16 = 'PowerBILogoBackplate16',
  BulletedListText = 'BulletedListText',
  BulletedListBullet = 'BulletedListBullet',
  BulletedListTextMirrored = 'BulletedListTextMirrored',
  BulletedListBulletMirrored = 'BulletedListBulletMirrored',
  NumberedListText = 'NumberedListText',
  NumberedListNumber = 'NumberedListNumber',
  NumberedListTextMirrored = 'NumberedListTextMirrored',
  NumberedListNumberMirrored = 'NumberedListNumberMirrored',
  RemoveLinkChain = 'RemoveLinkChain',
  RemoveLinkX = 'RemoveLinkX',
  BarChartVerticalFilter = 'BarChartVerticalFilter',
  InsertSignatureLine = 'InsertSignatureLine',
  PromotedDatabase = 'PromotedDatabase',
  LinkedDatabase = 'LinkedDatabase',
  Blocked2Solid = 'Blocked2Solid',
  SkypeCircleArrow = 'SkypeCircleArrow',
  SkypeArrow = 'SkypeArrow',
  SyncStatus = 'SyncStatus',
  SyncStatusSolid = 'SyncStatusSolid',
  ProjectDocument = 'ProjectDocument',
  ToDoLogoOutline = 'ToDoLogoOutline',
  VisioDiagramSync = 'VisioDiagramSync',
  Event12 = 'Event12',
  EventDateMissed12 = 'EventDateMissed12',
  UserOptional = 'UserOptional',
  ResponsesMenu = 'ResponsesMenu',
  DoubleDownArrow = 'DoubleDownArrow',
  DistributeDown = 'DistributeDown',
  BookmarkReport = 'BookmarkReport',
  FilterSettings = 'FilterSettings',
  GripperDotsVertical = 'GripperDotsVertical',
  MailAttached = 'MailAttached',
  AddIn = 'AddIn',
  TableLink = 'TableLink',
  BIDashboard = 'BIDashboard',
  PinnedSolid = 'PinnedSolid',
  WhiteBoardApp16 = 'WhiteBoardApp16',
  TransitionPush = 'TransitionPush',
  TransitionEffect = 'TransitionEffect',
  LookupEntities = 'LookupEntities',
  ExploreData = 'ExploreData',
  AddBookmark = 'AddBookmark',
  SearchBookmark = 'SearchBookmark',
  DrillThrough = 'DrillThrough',
  MasterDatabase = 'MasterDatabase',
  TransitionPop = 'TransitionPop',
  CertifiedDatabase = 'CertifiedDatabase',
  MinimumValue = 'MinimumValue',
  CriticalErrorSolid = 'CriticalErrorSolid',
  VisualStudioIDELogo32 = 'VisualStudioIDELogo32',
  PasteAsText = 'PasteAsText',
  MaximumValue = 'MaximumValue',
  PlaySolid = 'PlaySolid',
  ReportWarning = 'ReportWarning',
  AccountManagement = 'AccountManagement',
  RenewalCurrent = 'RenewalCurrent',
  RenewalFuture = 'RenewalFuture',
  SplitObject = 'SplitObject',
  BulkUpload = 'BulkUpload',
  DownloadDocument = 'DownloadDocument',
  GreetingCard = 'GreetingCard',
  Flower = 'Flower',
  WaitlistConfirm = 'WaitlistConfirm',
  WaitlistConfirmMirrored = 'WaitlistConfirmMirrored',
  LaptopSecure = 'LaptopSecure',
  DragObject = 'DragObject',
  EntryView = 'EntryView',
  EntryDecline = 'EntryDecline',
  ContactCardSettings = 'ContactCardSettings',
  ContactCardSettingsMirrored = 'ContactCardSettingsMirrored',
  CalendarSettings = 'CalendarSettings',
  CalendarSettingsMirrored = 'CalendarSettingsMirrored',
  HardDriveLock = 'HardDriveLock',
  HardDriveUnlock = 'HardDriveUnlock',
  PasteAsCode = 'PasteAsCode',
  BrowserTab = 'BrowserTab',
  BrowserTabScreenshot = 'BrowserTabScreenshot',
  DesktopScreenshot = 'DesktopScreenshot',
  InsertRowsAbove = 'InsertRowsAbove',
  InsertRowsBelow = 'InsertRowsBelow',
  DeleteColumns = 'DeleteColumns',
  DeleteRows = 'DeleteRows',
  DeleteRowsMirrored = 'DeleteRowsMirrored',
  DeleteTable = 'DeleteTable',
  AccountBrowser = 'AccountBrowser',
  VersionControlPush = 'VersionControlPush',
  StackedColumnChart2 = 'StackedColumnChart2',
  TripleColumnWide = 'TripleColumnWide',
  QuadColumn = 'QuadColumn',
  InsertColumnsRight = 'InsertColumnsRight',
  WhiteBoardApp32 = 'WhiteBoardApp32',
  InsertColumnsLeft = 'InsertColumnsLeft',
  ContentSettings = 'ContentSettings',
  FileYML = 'FileYML',
  ClipboardSolid = 'ClipboardSolid',
  FabricUserFolder = 'FabricUserFolder',
  FabricNetworkFolder = 'FabricNetworkFolder',
  BullseyeTarget = 'BullseyeTarget',
  AnalyticsView = 'AnalyticsView',
  Video360Generic = 'Video360Generic',
  Untag = 'Untag',
  Leave = 'Leave',
  Trending12 = 'Trending12',
  Blocked12 = 'Blocked12',
  Warning12 = 'Warning12',
  CheckedOutByOther12 = 'CheckedOutByOther12',
  CheckedOutByYou12 = 'CheckedOutByYou12',
  CircleShapeSolid = 'CircleShapeSolid',
  SquareShapeSolid = 'SquareShapeSolid',
  TriangleShapeSolid = 'TriangleShapeSolid',
  DropShapeSolid = 'DropShapeSolid',
  RectangleShapeSolid = 'RectangleShapeSolid',
  ZoomToFit = 'ZoomToFit',
  ChromeRestore = 'ChromeRestore',
  SkypeLogo16 = 'SkypeLogo16',
  TeamsLogo16 = 'TeamsLogo16',
  IconSetsFlag = 'IconSetsFlag',
  VisioLogo = 'VisioLogo',
  VisioDocument = 'VisioDocument',
  TimelineProgress = 'TimelineProgress',
  TimelineDelivery = 'TimelineDelivery',
  Backlog = 'Backlog',
  TeamFavorite = 'TeamFavorite',
  TaskGroup = 'TaskGroup',
  TextCallout = 'TextCallout',
  TaskGroupMirrored = 'TaskGroupMirrored',
  AssessmentGroupTemplate = 'AssessmentGroupTemplate',
  NewTeamProject = 'NewTeamProject',
  CommentAdd = 'CommentAdd',
  CommentNext = 'CommentNext',
  CommentPrevious = 'CommentPrevious',
  ShopServer = 'ShopServer',
  LocaleLanguage = 'LocaleLanguage',
  QueryList = 'QueryList',
  ScopeTemplate = 'ScopeTemplate',
  HighlightMappedShapes = 'HighlightMappedShapes',
  VisioDiagram = 'VisioDiagram',
  ServerEnviroment = 'ServerEnviroment',
  CloneToDesktop = 'CloneToDesktop',
  InstallToDrive = 'InstallToDrive',
  Blur = 'Blur',
  Build = 'Build',
  ProcessMetaTask = 'ProcessMetaTask',
  BranchFork2 = 'BranchFork2',
  BranchLocked = 'BranchLocked',
  BranchCommit = 'BranchCommit',
  BranchCompare = 'BranchCompare',
  BranchMerge = 'BranchMerge',
  BranchPullRequest = 'BranchPullRequest',
  BranchSearch = 'BranchSearch',
  BranchShelveset = 'BranchShelveset',
  RawSource = 'RawSource',
  MergeDuplicate = 'MergeDuplicate',
  RowsGroup = 'RowsGroup',
  RowsChild = 'RowsChild',
  Deploy = 'Deploy',
  Redeploy = 'Redeploy',
  UserSync = 'UserSync',
  UserPause = 'UserPause',
  StreamingOff = 'StreamingOff',
  MoreVertical = 'MoreVertical',
  BoxAdditionSolid = 'BoxAdditionSolid',
  BoxMultiplySolid = 'BoxMultiplySolid',
  BoxPlaySolid = 'BoxPlaySolid',
  BoxCheckmarkSolid = 'BoxCheckmarkSolid',
  CirclePauseSolid = 'CirclePauseSolid',
  CirclePause = 'CirclePause',
  MSNVideosSolid = 'MSNVideosSolid',
  CircleStopSolid = 'CircleStopSolid',
  CircleStop = 'CircleStop',
  NavigateBack = 'NavigateBack',
  NavigateBackMirrored = 'NavigateBackMirrored',
  NavigateForward = 'NavigateForward',
  NavigateForwardMirrored = 'NavigateForwardMirrored',
  UnknownSolid = 'UnknownSolid',
  UnknownMirroredSolid = 'UnknownMirroredSolid',
  CircleAddition = 'CircleAddition',
  CircleAdditionSolid = 'CircleAdditionSolid',
  FilePDB = 'FilePDB',
  FileTemplate = 'FileTemplate',
  BoxSubtractSolid = 'BoxSubtractSolid',
  DocumentApproval = 'DocumentApproval',
  CalculatorNotEqualTo = 'CalculatorNotEqualTo',
  LocationOutline = 'LocationOutline',
  ArrowTallUpLeft = 'ArrowTallUpLeft',
  ArrowTallUpRight = 'ArrowTallUpRight',
  ArrowTallDownLeft = 'ArrowTallDownLeft',
  ArrowTallDownRight = 'ArrowTallDownRight',
  FieldEmpty = 'FieldEmpty',
  FieldFilled = 'FieldFilled',
  FieldChanged = 'FieldChanged',
  FieldNotChanged = 'FieldNotChanged',
  RingerOff = 'RingerOff',
  PlayResume = 'PlayResume',
  BulletedList2 = 'BulletedList2',
  BulletedList2Mirrored = 'BulletedList2Mirrored',
  ImageCrosshair = 'ImageCrosshair',
  GitGraph = 'GitGraph',
  Repo = 'Repo',
  RepoSolid = 'RepoSolid',
  FolderQuery = 'FolderQuery',
  FolderList = 'FolderList',
  FolderListMirrored = 'FolderListMirrored',
  POISolid = 'POISolid',
  FileSQL = 'FileSQL',
  Signin = 'Signin',
  OfficeVideoLogoFill = 'OfficeVideoLogoFill',
  ColumnRightTwoThirds = 'ColumnRightTwoThirds',
  AnalyticsLogo = 'AnalyticsLogo',
  AnalyticsQuery = 'AnalyticsQuery',
  NewAnalyticsQuery = 'NewAnalyticsQuery',
  AnalyticsReport = 'AnalyticsReport',
  WordLogo = 'WordLogo',
  ExcelLogo = 'ExcelLogo',
  OneNoteLogo = 'OneNoteLogo',
  ColumnLeftTwoThirds = 'ColumnLeftTwoThirds',
  OutlookLogo = 'OutlookLogo',
  PublisherLogo = 'PublisherLogo',
  ScheduleEventAction = 'ScheduleEventAction',
  FlameSolid = 'FlameSolid',
  PowerShell = 'PowerShell',
  ServerProcesses = 'ServerProcesses',
  RegistryEditor = 'RegistryEditor',
  Server = 'Server',
  SaveAll = 'SaveAll',
  PowerPointLogo = 'PowerPointLogo',
  TripleColumn = 'TripleColumn',
  DoubleColumn = 'DoubleColumn',
  SingleColumn = 'SingleColumn',
  MobileReport = 'MobileReport',
  ScaleVolume = 'ScaleVolume',
  HardDriveGroup = 'HardDriveGroup',
  FastMode = 'FastMode',
  ToggleLeft = 'ToggleLeft',
  ToggleRight = 'ToggleRight',
  FullView = 'FullView',
  FocusView = 'FocusView',
  CircleShape = 'CircleShape',
  SquareShape = 'SquareShape',
  TriangleShape = 'TriangleShape',
  DropShape = 'DropShape',
  RectangleShape = 'RectangleShape',
  CubeShape = 'CubeShape',
  Trophy2 = 'Trophy2',
  BucketColor = 'BucketColor',
  BucketColorFill = 'BucketColorFill',
  Taskboard = 'Taskboard',
  LinkedInLogo = 'LinkedInLogo',
  Decimals = 'Decimals',
  FilterAscending = 'FilterAscending',
  FilterDescending = 'FilterDescending',
  BuildQueue = 'BuildQueue',
  BuildQueueNew = 'BuildQueueNew',
  UserFollowed = 'UserFollowed',
  ContactLink = 'ContactLink',
  Clicked = 'Clicked',
  Stack = 'Stack',
  Bullseye = 'Bullseye',
  VennDiagram = 'VennDiagram',
  FiveTileGrid = 'FiveTileGrid',
  FocalPoint = 'FocalPoint',
  Insert = 'Insert',
  RingerRemove = 'RingerRemove',
  TeamsLogoInverse = 'TeamsLogoInverse',
  TeamsLogo = 'TeamsLogo',
  SkypeForBusinessLogoFill = 'SkypeForBusinessLogoFill',
  SharepointLogo = 'SharepointLogo',
  DelveLogo = 'DelveLogo',
  DelveLogoFill = 'DelveLogoFill',
  OfficeVideoLogo = 'OfficeVideoLogo',
  StackedLineChart = 'StackedLineChart',
  ExchangeLogo = 'ExchangeLogo',
  StackedBarChart = 'StackedBarChart',
  TriggerUser = 'TriggerUser',
  SidePanelMirrored = 'SidePanelMirrored',
  ProtectRestrict = 'ProtectRestrict',
  Blog = 'Blog',
  IOT = 'IOT',
  UnknownMirrored = 'UnknownMirrored',
  PublicContactCardMirrored = 'PublicContactCardMirrored',
  GridViewSmall = 'GridViewSmall',
  GridViewMedium = 'GridViewMedium',
  GridViewLarge = 'GridViewLarge',
  Step = 'Step',
  StepInsert = 'StepInsert',
  StepShared = 'StepShared',
  StepSharedAdd = 'StepSharedAdd',
  StepSharedInsert = 'StepSharedInsert',
  ViewDashboard = 'ViewDashboard',
  ViewList = 'ViewList',
  ViewListGroup = 'ViewListGroup',
  ViewListTree = 'ViewListTree',
  TriggerAuto = 'TriggerAuto',
  PivotChart = 'PivotChart',
  Spacer = 'Spacer',
  FileJAVA = 'FileJAVA',
  FileCSS = 'FileCSS',
  DoubleChevronDown8 = 'DoubleChevronDown8',
  DoubleChevronUp8 = 'DoubleChevronUp8',
  DoubleChevronLeft8 = 'DoubleChevronLeft8',
  DoubleChevronRight8 = 'DoubleChevronRight8',
  ChevronDownEnd6 = 'ChevronDownEnd6',
  ChevronUpEnd6 = 'ChevronUpEnd6',
  ChevronLeftEnd6 = 'ChevronLeftEnd6',
  ChevronRightEnd6 = 'ChevronRightEnd6',
  ChevronFold10 = 'ChevronFold10',
  ContextMenu = 'ContextMenu',
  AzureAPIManagement = 'AzureAPIManagement',
  AzureServiceEndpoint = 'AzureServiceEndpoint',
  VSTSLogo = 'VSTSLogo',
  VSTSAltLogo1 = 'VSTSAltLogo1',
  VSTSAltLogo2 = 'VSTSAltLogo2',
  ChevronUnfold10 = 'ChevronUnfold10',
  DonutChart = 'DonutChart',
  CaretTopLeftCenter8 = 'CaretTopLeftCenter8',
  ColorSolid = 'ColorSolid',
  ReviewSolid = 'ReviewSolid',
  ReviewRequestSolid = 'ReviewRequestSolid',
  ReviewRequestMirroredSolid = 'ReviewRequestMirroredSolid',
  ReviewResponseSolid = 'ReviewResponseSolid',
  FeedbackRequestSolid = 'FeedbackRequestSolid',
  FeedbackRequestMirroredSolid = 'FeedbackRequestMirroredSolid',
  FeedbackResponseSolid = 'FeedbackResponseSolid',
  WorkItemBar = 'WorkItemBar',
  WorkItemBarSolid = 'WorkItemBarSolid',
  Separator = 'Separator',
  NavigateExternalInline = 'NavigateExternalInline',
  PlanView = 'PlanView',
  TimelineMatrixView = 'TimelineMatrixView',
  EngineeringGroup = 'EngineeringGroup',
  ProjectCollection = 'ProjectCollection',
  CaretBottomRightCenter8 = 'CaretBottomRightCenter8',
  CaretBottomLeftCenter8 = 'CaretBottomLeftCenter8',
  CaretTopRightCenter8 = 'CaretTopRightCenter8',
  FileTypeSolution = 'FileTypeSolution',
  DateTime12 = 'DateTime12',
  WordLogoInverse16 = 'WordLogoInverse16',
  WordLogo16 = 'WordLogo16',
  Sprint = 'Sprint',
  SignOut = 'SignOut',
  TriggerApproval = 'TriggerApproval',
  Rocket = 'Rocket',
  AzureKeyVault = 'AzureKeyVault',
  Onboarding = 'Onboarding',
  Transition = 'Transition',
  LikeSolid = 'LikeSolid',
  DislikeSolid = 'DislikeSolid',
  D365CustomerInsights = 'D365CustomerInsights',
  EditCreate = 'EditCreate',
  PlayReverseResume = 'PlayReverseResume',
  PlayReverse = 'PlayReverse',
  SearchData = 'SearchData',
  UnSetColor = 'UnSetColor',
  AutoDeploySettings = 'AutoDeploySettings',
  ContactLock = 'ContactLock',
  DeclineCall = 'DeclineCall',
  RectangularClipping = 'RectangularClipping',
  TestCase = 'TestCase',
  FiltersSolid = 'FiltersSolid',
  TestSuite = 'TestSuite',
  TestStep = 'TestStep',
  PowerPointLogoInverse16 = 'PowerPointLogoInverse16',
  PowerPointLogo16 = 'PowerPointLogo16',
  ExcelLogoInverse16 = 'ExcelLogoInverse16',
  ExcelLogo16 = 'ExcelLogo16',
  OneNoteLogoInverse16 = 'OneNoteLogoInverse16',
  OneNoteLogo16 = 'OneNoteLogo16',
  OutlookLogoInverse16 = 'OutlookLogoInverse16',
  OutlookLogo16 = 'OutlookLogo16',
  PublisherLogoInverse16 = 'PublisherLogoInverse16',
  PublisherLogo16 = 'PublisherLogo16',
  VisioLogoInverse16 = 'VisioLogoInverse16',
  VisioLogo16 = 'VisioLogo16',
  TestBeaker = 'TestBeaker',
  TestBeakerSolid = 'TestBeakerSolid',
  TestExploreSolid = 'TestExploreSolid',
  TestAutoSolid = 'TestAutoSolid',
  TestUserSolid = 'TestUserSolid',
  TestImpactSolid = 'TestImpactSolid',
  TestPlan = 'TestPlan',
  TestParameter = 'TestParameter',
  FileASPX = 'FileASPX',
  ParachuteSolid = 'ParachuteSolid',
  DecisionSolid = 'DecisionSolid',
  UnlockSolid = 'UnlockSolid',
  Variable = 'Variable',
  Parameter = 'Parameter',
  CommentUrgent = 'CommentUrgent',
  Storyboard = 'Storyboard',
  DiffInline = 'DiffInline',
  DiffSideBySide = 'DiffSideBySide',
  ImageDiff = 'ImageDiff',
  PlugDisconnected = 'PlugDisconnected',
  ImagePixel = 'ImagePixel',
  FileCode = 'FileCode',
  FileComment = 'FileComment',
  BusinessHoursSign = 'BusinessHoursSign',
  FileImage = 'FileImage',
  FileSymlink = 'FileSymlink',
  AutoFillTemplate = 'AutoFillTemplate',
  WorkItem = 'WorkItem',
  WorkItemBug = 'WorkItemBug',
  FileBug = 'FileBug',
  PlugConnected = 'PlugConnected',
  PlugSolid = 'PlugSolid',
  Plug = 'Plug',
  FileSass = 'FileSass',
  FileLess = 'FileLess',
  FileHTML = 'FileHTML',
  JavaScriptLanguage = 'JavaScriptLanguage',
  CSharpLanguage = 'CSharpLanguage',
  CSharp = 'CSharp',
  VisualBasicLanguage = 'VisualBasicLanguage',
  VB = 'VB',
  CPlusPlusLanguage = 'CPlusPlusLanguage',
  CPlusPlus = 'CPlusPlus',
  FSharpLanguage = 'FSharpLanguage',
  FSharp = 'FSharp',
  TypeScriptLanguage = 'TypeScriptLanguage',
  PythonLanguage = 'PythonLanguage',
  PY = 'PY',
  CoffeeScript = 'CoffeeScript',
  MarkDownLanguage = 'MarkDownLanguage',
  FullWidth = 'FullWidth',
  FullWidthEdit = 'FullWidthEdit',
  LogRemove = 'LogRemove',
  ColumnOptions = 'ColumnOptions',
  Packages = 'Packages',
  BuildIssue = 'BuildIssue',
  ReadingModeSolid = 'ReadingModeSolid',
  SadSolid = 'SadSolid',
  HealthSolid = 'HealthSolid',
  ShieldSolid = 'ShieldSolid',
  GiftBoxSolid = 'GiftBoxSolid',
  ShoppingCartSolid = 'ShoppingCartSolid',
  MailSolid = 'MailSolid',
  ChatSolid = 'ChatSolid',
  RibbonSolid = 'RibbonSolid',
  FinancialSolid = 'FinancialSolid',
  FinancialMirroredSolid = 'FinancialMirroredSolid',
  HeadsetSolid = 'HeadsetSolid',
  PermissionsSolid = 'PermissionsSolid',
  ParkingSolid = 'ParkingSolid',
  ParkingMirroredSolid = 'ParkingMirroredSolid',
  DiamondSolid = 'DiamondSolid',
  AsteriskSolid = 'AsteriskSolid',
  OfflineStorageSolid = 'OfflineStorageSolid',
  BankSolid = 'BankSolid',
  StarburstSolid = 'StarburstSolid',
  Parachute = 'Parachute',
  PageListMirroredSolid = 'PageListMirroredSolid',
  ConstructionConeSolid = 'ConstructionConeSolid',
  AssessmentGroup = 'AssessmentGroup',
  VariableGroup = 'VariableGroup',
  FullHistory = 'FullHistory',
  Wheelchair = 'Wheelchair',
  SingleColumnEdit = 'SingleColumnEdit',
  DoubleColumnEdit = 'DoubleColumnEdit',
  TripleColumnEdit = 'TripleColumnEdit',
  ColumnLeftTwoThirdsEdit = 'ColumnLeftTwoThirdsEdit',
  ColumnRightTwoThirdsEdit = 'ColumnRightTwoThirdsEdit',
  StreamLogo = 'StreamLogo',
  PassiveAuthentication = 'PassiveAuthentication',
  AlertSolid = 'AlertSolid',
  MegaphoneSolid = 'MegaphoneSolid',
  TaskSolid = 'TaskSolid',
  ConfigurationSolid = 'ConfigurationSolid',
  BugSolid = 'BugSolid',
  CrownSolid = 'CrownSolid',
  Trophy2Solid = 'Trophy2Solid',
  QuickNoteSolid = 'QuickNoteSolid',
  PageListSolid = 'PageListSolid',
  ChromeMinimize = 'ChromeMinimize',
  LocationFill = 'LocationFill',
  FullCircleMask = 'FullCircleMask',
  FabricTextHighlight = 'FabricTextHighlight',
  Refresh = 'Refresh',
  Share = 'Share',
  Lock = 'Lock',
  BlockedSite = 'BlockedSite',
  ReportHacked = 'ReportHacked',
  EMI = 'EMI',
  MiniLink = 'MiniLink',
  Blocked = 'Blocked',
  Back = 'Back',
  FavoriteStar = 'FavoriteStar',
  ReadingMode = 'ReadingMode',
  Favicon = 'Favicon',
  Remove = 'Remove',
  Checkbox = 'Checkbox',
  CheckboxComposite = 'CheckboxComposite',
  CheckboxFill = 'CheckboxFill',
  CheckboxIndeterminate = 'CheckboxIndeterminate',
  CheckboxCompositeReversed = 'CheckboxCompositeReversed',
  FavoriteStarFill = 'FavoriteStarFill',
  Forward = 'Forward',
  PageSolid = 'PageSolid',
  FavoriteList = 'FavoriteList',
  More = 'More',
  Settings = 'Settings',
  Video = 'Video',
  Mail = 'Mail',
  People = 'People',
  Phone = 'Phone',
  Pin = 'Pin',
  Shop = 'Shop',
  Stop = 'Stop',
  Link = 'Link',
  Filter = 'Filter',
  AllApps = 'AllApps',
  Zoom = 'Zoom',
  ZoomOut = 'ZoomOut',
  Microphone = 'Microphone',
  Search = 'Search',
  Camera = 'Camera',
  Attach = 'Attach',
  Send = 'Send',
  CheckMark = 'CheckMark',
  BackToWindow = 'BackToWindow',
  FullScreen = 'FullScreen',
  SwitchUser = 'SwitchUser',
  ChevronRight = 'ChevronRight',
  InkingTool = 'InkingTool',
  Emoji2 = 'Emoji2',
  GripperBarHorizontal = 'GripperBarHorizontal',
  System = 'System',
  Personalize = 'Personalize',
  SearchAndApps = 'SearchAndApps',
  Globe = 'Globe',
  EaseOfAccess = 'EaseOfAccess',
  UpdateRestore = 'UpdateRestore',
  ContactInfo = 'ContactInfo',
  Unpin = 'Unpin',
  Contact = 'Contact',
  Memo = 'Memo',
  IncomingCall = 'IncomingCall',
  Paste = 'Paste',
  WindowsLogo = 'WindowsLogo',
  Error = 'Error',
  GripperBarVertical = 'GripperBarVertical',
  ChevronLeft = 'ChevronLeft',
  Cancel = 'Cancel',
  Pause = 'Pause',
  KeyboardClassic = 'KeyboardClassic',
  Print = 'Print',
  Up = 'Up',
  Down = 'Down',
  OEM = 'OEM',
  Delete = 'Delete',
  Save = 'Save',
  ReturnKey = 'ReturnKey',
  Cloud = 'Cloud',
  Flashlight = 'Flashlight',
  CommandPrompt = 'CommandPrompt',
  Sad = 'Sad',
  RealEstate = 'RealEstate',
  SIPMove = 'SIPMove',
  EraseTool = 'EraseTool',
  GripperTool = 'GripperTool',
  Dialpad = 'Dialpad',
  PageLeft = 'PageLeft',
  PageRight = 'PageRight',
  MultiSelect = 'MultiSelect',
  Play = 'Play',
  Unlock = 'Unlock',
  Add = 'Add',
  ChevronUp = 'ChevronUp',
  RewindOneFiveX = 'RewindOneFiveX',
  RewindTwoX = 'RewindTwoX',
  RewindFourX = 'RewindFourX',
  RewindEightX = 'RewindEightX',
  LockShare = 'LockShare',
  FabricFolderLink = 'FabricFolderLink',
  LightningBoltSolid = 'LightningBoltSolid',
  Gather = 'Gather',
  RewindOneX = 'RewindOneX',
  AssignPolicy = 'AssignPolicy',
  MobileAngled = 'MobileAngled',
  CopyEdit = 'CopyEdit',
  EntityExtraction = 'EntityExtraction',
  MostySunnyDay = 'MostySunnyDay',
  PartlySunnyDay = 'PartlySunnyDay',
  IntermittentCloudsDay = 'IntermittentCloudsDay',
  HazyDay = 'HazyDay',
  Showers = 'Showers',
  CalculatorGroup = 'CalculatorGroup',
  RewindPointFiveX = 'RewindPointFiveX',
  FastForwardEightX = 'FastForwardEightX',
  FastForwardFourX = 'FastForwardFourX',
  DeviceBug = 'DeviceBug',
  HomeDropdown = 'HomeDropdown',
  ContentFeed = 'ContentFeed',
  CompareUneven = 'CompareUneven',
  D365ProjectOperations = 'D365ProjectOperations',
  FileSystem = 'FileSystem',
  SQLAnalyticsPool = 'SQLAnalyticsPool',
  StorageAcount = 'StorageAcount',
  BlobStorage = 'BlobStorage',
  DatabaseView = 'DatabaseView',
  Column = 'Column',
  AzureDataExplorer = 'AzureDataExplorer',
  SortLinesAscending = 'SortLinesAscending',
  TextDocumentEdit = 'TextDocumentEdit',
  VideoOff2 = 'VideoOff2',
  FastForwardPointFiveX = 'FastForwardPointFiveX',
  FastForwardOneX = 'FastForwardOneX',
  FastForwardOneFiveX = 'FastForwardOneFiveX',
  FastForwardTwoX = 'FastForwardTwoX',
  MostyCloudyShowersDay = 'MostyCloudyShowersDay',
  PartlySunnyShowersDay = 'PartlySunnyShowersDay',
  MostyCloudyTStormsDay = 'MostyCloudyTStormsDay',
  PartlySunnyTStormsDay = 'PartlySunnyTStormsDay',
  OfficeCatchUp = 'OfficeCatchUp',
  DynamicList = 'DynamicList',
  GroupRemove = 'GroupRemove',
  ReceiptProcessing = 'ReceiptProcessing',
  InboxActive = 'InboxActive',
  RingerActive = 'RingerActive',
  FlowTemplate = 'FlowTemplate',
  TextDocumentSettings = 'TextDocumentSettings',
  PowerAppsTemplate = 'PowerAppsTemplate',
  TemporaryAccessPass = 'TemporaryAccessPass',
  ChoiceColumn = 'ChoiceColumn',
  GlobalNavButton = 'GlobalNavButton',
  InternetSharing = 'InternetSharing',
  Brightness = 'Brightness',
  MapPin = 'MapPin',
  Airplane = 'Airplane',
  Tablet = 'Tablet',
  QuickNote = 'QuickNote',
  ChevronDown = 'ChevronDown',
  SecurityTest = 'SecurityTest',
  Edit = 'Edit',
  PhishingHook = 'PhishingHook',
  CategoryClassification = 'CategoryClassification',
  MostyCloudyFlurriesDay = 'MostyCloudyFlurriesDay',
  PartlySunnyFlurriesDay = 'PartlySunnyFlurriesDay',
  Ice = 'Ice',
  Sleet = 'Sleet',
  FreezingRain = 'FreezingRain',
  MostyClearNight = 'MostyClearNight',
  PartlyClearNight = 'PartlyClearNight',
  IntermittentCloudsNight = 'IntermittentCloudsNight',
  HazyNight = 'HazyNight',
  PartlySunnyShowersNight = 'PartlySunnyShowersNight',
  MostyCloudyTStormsNight = 'MostyCloudyTStormsNight',
  PartlySunnyTStormsNight = 'PartlySunnyTStormsNight',
  MostyCloudyFlurriesNight = 'MostyCloudyFlurriesNight',
  Sharepoint2013LogoInverse = 'Sharepoint2013LogoInverse',
  OutlookSpacesBucket = 'OutlookSpacesBucket',
  CaretSolidAlt = 'CaretSolidAlt',
  PowerVirtualAgentsLogo = 'PowerVirtualAgentsLogo',
  FluidLogo = 'FluidLogo',
  FormProcessing = 'FormProcessing',
  PhishingCampaign = 'PhishingCampaign',
  Reset = 'Reset',
  Slideshow = 'Slideshow',
  Megaphone = 'Megaphone',
  MailReplyAll = 'MailReplyAll',
  Read = 'Read',
  Cut = 'Cut',
  PaymentCard = 'PaymentCard',
  Copy = 'Copy',
  Important = 'Important',
  MailReply = 'MailReply',
  Sort = 'Sort',
  CalendarWeek = 'CalendarWeek',
  GotoToday = 'GotoToday',
  FontColor = 'FontColor',
  FolderFill = 'FolderFill',
  Permissions = 'Permissions',
  DisableUpdates = 'DisableUpdates',
  Unfavorite = 'Unfavorite',
  Italic = 'Italic',
  Underline = 'Underline',
  Bold = 'Bold',
  Font = 'Font',
  CalendarDay = 'CalendarDay',
  Message = 'Message',
  ShowResults = 'ShowResults',
  OpenPane = 'OpenPane',
  PreviewLink = 'PreviewLink',
  ZoomIn = 'ZoomIn',
  Bookmarks = 'Bookmarks',
  Document = 'Document',
  ProtectedDocument = 'ProtectedDocument',
  OpenInNewWindow = 'OpenInNewWindow',
  MailFill = 'MailFill',
  ViewAll = 'ViewAll',
  Switch = 'Switch',
  Rename = 'Rename',
  Go = 'Go',
  Remote = 'Remote',
  SelectAll = 'SelectAll',
  Orientation = 'Orientation',
  Import = 'Import',
  Folder = 'Folder',
  Picture = 'Picture',
  ChromeClose = 'ChromeClose',
  MoveToFolder = 'MoveToFolder',
  Dislike = 'Dislike',
  Like = 'Like',
  AlignRight = 'AlignRight',
  News = 'News',
  Chat = 'Chat',
  Group = 'Group',
  World = 'World',
  Comment = 'Comment',
  DockLeft = 'DockLeft',
  DockRight = 'DockRight',
  Repair = 'Repair',
  Accounts = 'Accounts',
  Street = 'Street',
  RadioBullet = 'RadioBullet',
  Stopwatch = 'Stopwatch',
  Clock = 'Clock',
  WorldClock = 'WorldClock',
  AlarmClock = 'AlarmClock',
  Photo = 'Photo',
  ActionCenter = 'ActionCenter',
  Hospital = 'Hospital',
  Timer = 'Timer',
  Preview = 'Preview',
  ClosePane = 'ClosePane',
  BulletedList = 'BulletedList',
  AddFriend = 'AddFriend',
  AlignCenter = 'AlignCenter',
  AlignLeft = 'AlignLeft',
  OpenFile = 'OpenFile',
  ClearSelection = 'ClearSelection',
  FontDecrease = 'FontDecrease',
  FontIncrease = 'FontIncrease',
  FontSize = 'FontSize',
  CellPhone = 'CellPhone',
  Tag = 'Tag',
  RepeatOne = 'RepeatOne',
  RepeatAll = 'RepeatAll',
  Calculator = 'Calculator',
  Library = 'Library',
  PostUpdate = 'PostUpdate',
  NewFolder = 'NewFolder',
  CalendarReply = 'CalendarReply',
  UnsyncFolder = 'UnsyncFolder',
  SyncFolder = 'SyncFolder',
  BlockContact = 'BlockContact',
  Accept = 'Accept',
  Calendar = 'Calendar',
  MailForward = 'MailForward',
  Upload = 'Upload',
  Warning = 'Warning',
  Financial = 'Financial',
  Education = 'Education',
  ShoppingCart = 'ShoppingCart',
  Train = 'Train',
  Flag = 'Flag',
  Move = 'Move',
  Page = 'Page',
  Telemarketer = 'Telemarketer',
  TouchPointer = 'TouchPointer',
  TurnRight = 'TurnRight',
  Ferry = 'Ferry',
  Highlight = 'Highlight',
  PowerButton = 'PowerButton',
  Tab = 'Tab',
  Admin = 'Admin',
  SDCard = 'SDCard',
  TVMonitor = 'TVMonitor',
  Merge = 'Merge',
  Package = 'Package',
  ThumbnailView = 'ThumbnailView',
  ViewOriginal = 'ViewOriginal',
  Trim = 'Trim',
  AutoEnhanceOn = 'AutoEnhanceOn',
  AutoEnhanceOff = 'AutoEnhanceOff',
  Color = 'Color',
  SaveAs = 'SaveAs',
  Light = 'Light',
  Filters = 'Filters',
  AspectRatio = 'AspectRatio',
  Contrast = 'Contrast',
  Redo = 'Redo',
  Undo = 'Undo',
  Crop = 'Crop',
  PhotoCollection = 'PhotoCollection',
  Album = 'Album',
  OpenWith = 'OpenWith',
  Rotate = 'Rotate',
  PanoIndicator = 'PanoIndicator',
  Translate = 'Translate',
  RedEye = 'RedEye',
  Speakers = 'Speakers',
  Game = 'Game',
  HorizontalTabKey = 'HorizontalTabKey',
  UnstackSelected = 'UnstackSelected',
  Bank = 'Bank',
  LocationDot = 'LocationDot',
  Dictionary = 'Dictionary',
  ChromeBack = 'ChromeBack',
  CortanaLogoOuter = 'CortanaLogoOuter',
  CortanaLogoInner = 'CortanaLogoInner',
  ProvisioningPackage = 'ProvisioningPackage',
  FolderOpen = 'FolderOpen',
  Pinned = 'Pinned',
  PinnedFill = 'PinnedFill',
  RevToggleKey = 'RevToggleKey',
  USB = 'USB',
  View = 'View',
  Previous = 'Previous',
  Next = 'Next',
  Clear = 'Clear',
  Sync = 'Sync',
  Download = 'Download',
  Help = 'Help',
  Hotel = 'Hotel',
  Emoji = 'Emoji',
  Recent = 'Recent',
  SearchNearby = 'SearchNearby',
  StackIndicator = 'StackIndicator',
  Nav2DMapView = 'Nav2DMapView',
  StreetsideSplitMinimize = 'StreetsideSplitMinimize',
  Car = 'Car',
  Bus = 'Bus',
  EatDrink = 'EatDrink',
  SeeDo = 'SeeDo',
  LocationCircle = 'LocationCircle',
  Home = 'Home',
  SwitcherStartEnd = 'SwitcherStartEnd',
  ParkingLocation = 'ParkingLocation',
  IncidentTriangle = 'IncidentTriangle',
  Touch = 'Touch',
  MapDirections = 'MapDirections',
  CaretHollow = 'CaretHollow',
  CaretSolid = 'CaretSolid',
  History = 'History',
  Location = 'Location',
  MapLayers = 'MapLayers',
  Work = 'Work',
  ShapeSolid = 'ShapeSolid',
  ImageInAR = 'ImageInAR',
  CubeShapeSolid = 'CubeShapeSolid',
  TextRecognition = 'TextRecognition',
  KeyPhraseExtraction = 'KeyPhraseExtraction',
  AltText = 'AltText',
  NumberedListMirrored = 'NumberedListMirrored',
  IncreaseIndent = 'IncreaseIndent',
  IncreaseIndentMirrored = 'IncreaseIndentMirrored',
  DecreaseIndent = 'DecreaseIndent',
  DecreaseIndentMirrored = 'DecreaseIndentMirrored',
  SentimentAnalysis = 'SentimentAnalysis',
  IncreaseIndentHanging = 'IncreaseIndentHanging',
  ArchiveUndo = 'ArchiveUndo',
  LeaveUser = 'LeaveUser',
  FabricFolderUpload = 'FabricFolderUpload',
  RedEye12 = 'RedEye12',
  MapPin12 = 'MapPin12',
  Insights = 'Insights',
  TaskList = 'TaskList',
  MachineLearning = 'MachineLearning',
  IncreaseIndentHangingMirrored = 'IncreaseIndentHangingMirrored',
  Forum = 'Forum',
  CommonDataServiceCDS = 'CommonDataServiceCDS',
  DependencyAdd = 'DependencyAdd',
  DependencyRemove = 'DependencyRemove',
  EntitlementPolicy = 'EntitlementPolicy',
  EntitlementRedemption = 'EntitlementRedemption',
  SchoolDataSyncLogo = 'SchoolDataSyncLogo',
  PinSolid12 = 'PinSolid12',
  PinSolidOff12 = 'PinSolidOff12',
  AddLink = 'AddLink',
  SharepointAppIcon16 = 'SharepointAppIcon16',
  DataflowsLink = 'DataflowsLink',
  TimePicker = 'TimePicker',
  UserWarning = 'UserWarning',
  ComplianceAudit = 'ComplianceAudit',
  StickyNotesOutlineAppIcon = 'StickyNotesOutlineAppIcon',
  StickyNotesSolidAppIcon = 'StickyNotesSolidAppIcon',
  Webcam2Off = 'Webcam2Off',
  eDiscovery = 'eDiscovery',
  CalendarYear = 'CalendarYear',
  IgnoreConversation = 'IgnoreConversation',
  HealthRefresh = 'HealthRefresh',
  FontSize2 = 'FontSize2',
  SharedDatabase = 'SharedDatabase',
  WebEnvironment = 'WebEnvironment',
  TagGroup = 'TagGroup',
  PhotoError = 'PhotoError',
  HexaditeInvestigation = 'HexaditeInvestigation',
  HexaditeInvestigationCancel = 'HexaditeInvestigationCancel',
  HexaditeInvestigationSemiAuto = 'HexaditeInvestigationSemiAuto',
  DriverOff = 'DriverOff',
  FileOff = 'FileOff',
  ServiceOff = 'ServiceOff',
  URLBlock = 'URLBlock',
  BugSync = 'BugSync',
  BugBlock = 'BugBlock',
  DeviceRun = 'DeviceRun',
  DeviceOff = 'DeviceOff',
  ProcessingCancel = 'ProcessingCancel',
  ProcessingRun = 'ProcessingRun',
  ProcessingPause = 'ProcessingPause',
  CalculatorDelta = 'CalculatorDelta',
  InputAddress = 'InputAddress',
  ViewInAR = 'ViewInAR',
  AutomateFlow = 'AutomateFlow',
  UnpublishContent = 'UnpublishContent',
  TableBrandedColumn = 'TableBrandedColumn',
  TableFirstColumn = 'TableFirstColumn',
  FormatPainter = 'FormatPainter',
  IndentFirstLine = 'IndentFirstLine',
  AddSpaceBefore = 'AddSpaceBefore',
  AddSpaceAfter = 'AddSpaceAfter',
  TextAlignTop = 'TextAlignTop',
  TextAlignMiddle = 'TextAlignMiddle',
  TextAlignBottom = 'TextAlignBottom',
  TextParagraphOption = 'TextParagraphOption',
  TextRotation = 'TextRotation',
  TextRotateHorizontal = 'TextRotateHorizontal',
  TextRotate90Degrees = 'TextRotate90Degrees',
  TextRotate270Degrees = 'TextRotate270Degrees',
  AutoFitContents = 'AutoFitContents',
  AutoFitWindow = 'AutoFitWindow',
  FixedColumnWidth = 'FixedColumnWidth',
  RepeatHeaderRows = 'RepeatHeaderRows',
  TableHeaderRow = 'TableHeaderRow',
  TableTotalRow = 'TableTotalRow',
  TableBrandedRow = 'TableBrandedRow',
  TableLastColumn = 'TableLastColumn',
  ProductList = 'ProductList',
  PageData = 'PageData',
  DecreaseIndentLegacy = 'DecreaseIndentLegacy',
  IncreaseIndentLegacy = 'IncreaseIndentLegacy',
  SizeLegacy = 'SizeLegacy',
  PageLink = 'PageLink',
  CommentSolid = 'CommentSolid',
  ChangeEntitlements = 'ChangeEntitlements',
  Installation = 'Installation',
  WebAppBuilderModule = 'WebAppBuilderModule',
  WebAppBuilderFragment = 'WebAppBuilderFragment',
  WebAppBuilderSlot = 'WebAppBuilderSlot',
  BullseyeTargetEdit = 'BullseyeTargetEdit',
  WebAppBuilderFragmentCreate = 'WebAppBuilderFragmentCreate',
  PageHeaderEdit = 'PageHeaderEdit',
  ClearFormattingA = 'ClearFormattingA',
  Photo2Fill = 'Photo2Fill',
  Uneditable2 = 'Uneditable2',
  Uneditable2Mirrored = 'Uneditable2Mirrored',
  Spelling = 'Spelling',
  BarChartVerticalEdit = 'BarChartVerticalEdit',
  GlobalNavButtonActive = 'GlobalNavButtonActive',
  PollResults = 'PollResults',
  Rerun = 'Rerun',
  QandA = 'QandA',
  QandAMirror = 'QandAMirror',
  BookAnswers = 'BookAnswers',
  AlertSettings = 'AlertSettings',
  TrimStart = 'TrimStart',
  TrimEnd = 'TrimEnd',
  TableComputed = 'TableComputed',
  ReportLock = 'ReportLock',
  DisconnectVirtualMachine = 'DisconnectVirtualMachine',
  Variable2 = 'Variable2',
  LearningTools = 'LearningTools',
  Dataflows = 'Dataflows',
  ShowGrid = 'ShowGrid',
  DiagnosticDataBarTooltip = 'DiagnosticDataBarTooltip',
  SaveToMobile = 'SaveToMobile',
  Orientation2 = 'Orientation2',
  ScreenCast = 'ScreenCast',
  GenericScanFilled = 'GenericScanFilled',
  FabricTextHighlightComposite = 'FabricTextHighlightComposite',
  SnapToGrid = 'SnapToGrid',
  NewMail = 'NewMail',
  EyeShadow = 'EyeShadow',
  ContactList = 'ContactList',
  StreamDiscover = 'StreamDiscover',
  ShieldAlert = 'ShieldAlert',
  RemoveFromShoppingList = 'RemoveFromShoppingList',
  IncreaseIndentText = 'IncreaseIndentText',
  IncreaseIndentArrow = 'IncreaseIndentArrow',
  DecreaseIndentText = 'DecreaseIndentText',
  DecreaseIndentArrow = 'DecreaseIndentArrow',
  IncreaseIndentTextMirrored = 'IncreaseIndentTextMirrored',
  IncreaseIndentArrowMirrored = 'IncreaseIndentArrowMirrored',
  DecreaseIndentTextMirrored = 'DecreaseIndentTextMirrored',
  DecreaseIndentArrowMirrored = 'DecreaseIndentArrowMirrored',
  CheckListText = 'CheckListText',
  CheckListCheck = 'CheckListCheck',
  CheckListTextMirrored = 'CheckListTextMirrored',
  CheckListCheckMirrored = 'CheckListCheckMirrored',
  NumberSymbol = 'NumberSymbol',
  Coupon = 'Coupon',
  VerifiedBrand = 'VerifiedBrand',
  ReleaseGate = 'ReleaseGate',
  ReleaseGateCheck = 'ReleaseGateCheck',
  ReleaseGateError = 'ReleaseGateError',
  M365InvoicingLogo = 'M365InvoicingLogo',
  FabricFolderConfirm = 'FabricFolderConfirm',
  InformationBarriers = 'InformationBarriers',
  CommentActive = 'CommentActive',
  AccessibiltyChecker = 'AccessibiltyChecker',
  DatabaseSync = 'DatabaseSync',
  ReservationOrders = 'ReservationOrders',
  TabOneColumn = 'TabOneColumn',
  TabTwoColumn = 'TabTwoColumn',
  TabThreeColumn = 'TabThreeColumn',
  BulletedTreeList = 'BulletedTreeList',
  MicrosoftTranslatorLogoGreen = 'MicrosoftTranslatorLogoGreen',
  MicrosoftTranslatorLogoBlue = 'MicrosoftTranslatorLogoBlue',
  InternalInvestigation = 'InternalInvestigation',
  AddReaction = 'AddReaction',
  ContactHeart = 'ContactHeart',
  VisuallyImpaired = 'VisuallyImpaired',
  EventToDoLogo = 'EventToDoLogo',
  PlainText = 'PlainText',
  ModelingView = 'ModelingView',
  StackedColumnChart2Fill = 'StackedColumnChart2Fill',
  Header = 'Header',
  ColumnVerticalSectionEdit = 'ColumnVerticalSectionEdit',
  WavingHand = 'WavingHand',
  ShakeDevice = 'ShakeDevice',
  SmartGlassRemote = 'SmartGlassRemote',
  Rotate90Clockwise = 'Rotate90Clockwise',
  Rotate90CounterClockwise = 'Rotate90CounterClockwise',
  CampaignTemplate = 'CampaignTemplate',
  ChartTemplate = 'ChartTemplate',
  PageListFilter = 'PageListFilter',
  SecondaryNav = 'SecondaryNav',
  ColumnVerticalSection = 'ColumnVerticalSection',
  SkypeCircleSlash = 'SkypeCircleSlash',
  SkypeSlash = 'SkypeSlash',
  CustomizeToolbar = 'CustomizeToolbar',
  DuplicateRow = 'DuplicateRow',
  RemoveFromTrash = 'RemoveFromTrash',
  MailOptions = 'MailOptions',
  Childof = 'Childof',
  Footer = 'Footer',
  BarChartVerticalFill = 'BarChartVerticalFill',
  ClearFormattingEraser = 'ClearFormattingEraser',
}

// tslint:disable-next-line:deprecation
export type IconNamesInput = keyof typeof IconNames;
