import { SecondaryButton } from 'components';
import {
  getGqlTermIdError,
  getInvalidTermsErrorMessage,
  RemoveEnrollmentDialog,
} from 'features-apollo/quote/components';
import { GetModernAgreementPreview } from 'features-apollo/quote/components/queries';
import * as actions from 'features/proposal/actions';
import { Quote, TransactionModel } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { useLazyQuery } from '@apollo/react-hooks';

import * as selectors2 from '../../../../selectors';
import { openAgreementPreviewError } from '../ViewAgreementErrorDialog';
import { SubmitQuoteDialog } from './SubmitQuoteDialog';

const mapStateToProps = (state: RootState, ownProps: { quote: Quote }) => {
  return {
    quote: ownProps.quote,
    // isAnyBlockingNotification: selectors.isAnyBlockingNotification(state), // done
    skipAgreementCall: ownProps.quote.transactionModel === TransactionModel.ToPartnerCustomerAsset,
  };
};
const dispatchProps = {
  getProposal: (proposalId: string) => actions.loadAndHydrateAllQuote(proposalId),
};

export type SubmitForApprovalButtonProps = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

export const SubmitForApprovalButtonFeature: React.FC<SubmitForApprovalButtonProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const { quote, getProposal } = props;

  // const disableButton =  selectors2.quoteHasBlockingNotifications(quote) || selectors2.disableSubmit(quote);
  const disableButton = selectors2.disableSubmit(quote);
  React.useEffect(() => {
    if (!disableButton) {
      getProposal(quote.id);
    }
  }, [getProposal, disableButton, quote.id]);
  const quoteHasSapTerm = selectors2.quoteHasSapTerm(quote);
  const enrollmentNumber = quote.vlAgreementNumber;
  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <SubmitQuoteDialog />,
  };

  const removeEnrollmentDialogProps: DialogProps = {
    providedDialog: (
      <RemoveEnrollmentDialog quoteEtag={quote.etag} quoteId={quote.id} submittingForApproval />
    ),
  };

  // Verifies that we can successfully generate an agreement for the quote before allowing submit
  const [getAgreementPreview] = useLazyQuery(GetModernAgreementPreview, {
    onCompleted: () => {
      const showRemoveEnrollmentDialog = quoteHasSapTerm && enrollmentNumber;
      context.openDialog(showRemoveEnrollmentDialog ? removeEnrollmentDialogProps : dialogProps);
    },
    // Implemented based on business logic pre-GQLMigration. This logic will move to GQL in the future
    // TODO - Michmel - re implement this error handling for the new contract
    onError: error => {
      let customError;
      const termsErrorResult = getGqlTermIdError(error);
      if (termsErrorResult.code && termsErrorResult.message) {
        customError = getInvalidTermsErrorMessage(
          t(`quote::Sorry, the "Submit for approval" action failed.`),
          termsErrorResult.code,
          termsErrorResult.message,
          quote.lineItems,
          t
        );
      }
      openAgreementPreviewError(context, quote.id, customError);
    },
    fetchPolicy: 'cache-and-network',
  });

  // Loads empowerments and enable the dialog to be open
  const handleSubmitClick = () => {
    if (props.skipAgreementCall) {
      context.openDialog(dialogProps);
    } else {
      // TODO: Verify if this will also break on HRDD quotes
      getAgreementPreview({
        variables: {
          quoteId: quote.id,
        },
      });
    }
  };

  return (
    <SecondaryButton
      ariaLabel={t('quote::Submit the quote for approval')}
      dataAutomationId="submitForApprovalButton"
      disabled={disableButton}
      iconName="DocumentApproval"
      id="submit-for-approval-button"
      text={t('quote::Submit for approval')}
      onClick={handleSubmitClick}
    />
  );
};

export const SubmitForApprovalButton = connect(
  mapStateToProps,
  dispatchProps
)(SubmitForApprovalButtonFeature);
