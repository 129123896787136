import { ComboBox, InfoButton, Label, LinkButton, TextBody, TextBodySmall } from 'components';
import { TextboxStandard } from 'components/ions';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { getFlightIsEnabled } from 'features/app/selectors';
import * as actions from 'features/proposal/actions';
import { EnterEnrollmentNumberDialog } from 'features/proposal/components/Dialogs/EnterEnrollmentNumberDialog/EnterEnrollmentNumberDialog';
import { getCustomerTpid, getEnrollmentNumber } from 'features/proposal/selectors';
import { getEnrollmentFieldView } from 'features/proposal/selectors/views';
import { sharedStyles } from 'features/proposal/shared.styles';
import { EnrollmentFieldView } from 'features/proposal/types';
import { flexSectionCreator, getCRMId, isProposalReadOnly } from 'features/proposal/utils';
import { EnrollmentSummary } from 'generated/graphql';
import {
  IComboBox,
  IComboBoxOption,
  IOnRenderComboBoxLabelProps,
  ISelectableOption,
  ITextFieldProps,
  KeyCodes,
  SelectableOptionMenuItemType,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext, DialogProps, ThemeProps } from 'styles';

import { useQuery } from '@apollo/react-hooks';

import { GetValidEnrollments } from './Queries';
import { salesStyles } from './Sales.styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles,
  ...salesStyles(theme),
});

export interface SalesFooterProps {
  quote: Proposal;
  isLegacy: boolean;
  modernOfficeVersion?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: SalesFooterProps) => {
  return {
    enrollmentNumber: getEnrollmentNumber(state, ownProps.quote),
    enrollmentAssemblyEnabled: getFlightIsEnabled(state, Flight.enrollmentAssembly),
    quote: ownProps.quote,
    getEnrollmentFieldView: getEnrollmentFieldView(state),
    isLegacy: ownProps.isLegacy,
    tpid: getCustomerTpid(state),
    crmId: getCRMId(ownProps.quote),
  };
};

const dispatchProps = {
  loadEnrollmentAndUpdateProposal: actions.loadEnrollmentAndUpdateProposal,
  setEnrollmentFieldView: actions.setEnrollmentFieldView,
};

type Props = SalesFooterProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const maxEnrollmentLength = 8;

const EnrollmentNumberFieldUnStyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const [cleanedEnrollmentNumber, setCleanedEnrollmentNumber] = React.useState(
    props.enrollmentNumber
  );
  // TODO: Condense to one useState when both can use GQL
  const [selectedEnrollmentNumber, setSelectedEnrollmentNumber] = React.useState<
    string | undefined
  >(props.enrollmentNumber);

  const generateEnrollmentOptions = (validAzureEnrollments: EnrollmentSummary[]) => {
    let enrollmentOptions: IComboBoxOption[] = [
      {
        key: '',
        text: t('quote::No enrollment'),
      },
      { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
    ];
    const validAzureEnrollmentsSorted = validAzureEnrollments.sort((enrollment1, enrollment2) => {
      return new Date(enrollment1.endDate).getTime() - new Date(enrollment2.endDate).getTime();
    });
    validAzureEnrollmentsSorted.forEach(enrollmentSummary => {
      if (enrollmentSummary.agreementNumber) {
        enrollmentOptions.push({
          key: enrollmentSummary.agreementNumber,
          text: enrollmentSummary.agreementNumber,
          data: {
            date: enrollmentSummary.endDate,
          },
        });
      }
    });
    enrollmentOptions.push({
      key: 'enter enrollment number',
      text: t('quote::enter enrollment number'),
    });
    return enrollmentOptions;
  };

  const { data } = useQuery<{ validAzureEnrollments: EnrollmentSummary[] }>(GetValidEnrollments, {
    variables: {
      tpid: props.tpid,
    },
    skip: !props.tpid,
  });

  const validAzureEnrollments = data ? data.validAzureEnrollments : [];
  const enrollmentOptions = generateEnrollmentOptions(validAzureEnrollments);

  const enrollmentNumberValidation = new RegExp('^[0-9]{7,8}$');
  const isReadOnly: boolean = isProposalReadOnly(props.quote);
  React.useEffect(() => {
    setCleanedEnrollmentNumber(props.enrollmentNumber);
  }, [props.enrollmentNumber]);

  const handleEnrollmentEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter && cleanedEnrollmentNumber !== props.enrollmentNumber) {
      props.loadEnrollmentAndUpdateProposal(cleanedEnrollmentNumber || '');
    }
  };

  const renderLabel = (disabled: boolean): JSX.Element => {
    return (
      <div className={classes.enrollmentNumberFieldLabelContainer}>
        <Label
          className={
            disabled
              ? classes.enrollmentNumberFieldLabelDisabled
              : classes.enrollmentNumberFieldLabel
          }
          htmlFor="enrollment-number-field"
          required={props.enrollmentAssemblyEnabled}
        >
          {t('quote::Expiring enrollment number')}
        </Label>
        <InfoButton
          ariaLabel={t('quote::Open enrollment information')}
          calloutProps={{
            closeButtonAriaLabel: t('Close'),
            headline: t('quote::Expiring enrollment number'),
            maxWidth: 386,
          }}
          className={classes.infoButton}
          id="enrollment-info-button"
        >
          <TextBody>
            {t(
              'quote::The expiring enrollment number connects expiring enrollments to the quote and can at times be attached to the CRM ID. For Office products, the enrollment number can be used to align new Office subscriptions to the end of the expiring enrollment to ensure your customer has consistent access to their services. Removing or adding an expiring enrollment number will modify any Office subscriptions current on the quote.'
            )}
          </TextBody>
        </InfoButton>
      </div>
    );
  };

  const renderComboBoxLabel = (comboBoxProps?: IOnRenderComboBoxLabelProps): JSX.Element => {
    const disabled = (comboBoxProps && comboBoxProps.props.disabled) || false;
    return renderLabel(disabled);
  };

  const renderTextBoxLabel = (textfieldProps?: ITextFieldProps): JSX.Element => {
    const disabled = (textfieldProps && textfieldProps.disabled) || false;
    return renderLabel(disabled);
  };

  const onRenderOption = (item?: ISelectableOption): JSX.Element => {
    if (item) {
      if (item.data) {
        return (
          <div className={classes.enrollmentComboBoxRowStyle}>
            <div>
              <TextBody>{item.text}</TextBody>
            </div>
            <div>
              <TextBodySmall addClass={classes.enrollmentExpirationDateStyle}>
                {t('quote::Expiration date:') +
                  ' ' +
                  convertDateToFormattedString(item.data.date, LocaleDateFormat.ll)}
              </TextBodySmall>
            </div>
          </div>
        );
      } else {
        if (item.key === 'enter enrollment number') {
          return <TextBody addClass={classes.enterEnrollmentNumberStyle}>{item.text}</TextBody>;
        } else {
          return <TextBody>{item.text}</TextBody>;
        }
      }
    }
    return <span></span>;
  };

  const enrollmentNumberFieldView =
    props.enrollmentAssemblyEnabled &&
    props.getEnrollmentFieldView === EnrollmentFieldView.ComboBox ? (
      <ComboBox
        className={classes.field}
        dataAutomationId="enrollmentNumberComboBox"
        disabled={isReadOnly || props.isLegacy || !props.crmId}
        id="enrollment-number-combobox"
        label={props.modernOfficeVersion ? '' : 'Expiring enrollment number'}
        maxHeight={365}
        options={enrollmentOptions}
        placeholder={t('quote::Select an option')}
        required
        selectedKey={selectedEnrollmentNumber}
        onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
          if (option) {
            if (option.key === 'enter enrollment number') {
              const dialogProps: DialogProps = {
                providedDialog: <EnterEnrollmentNumberDialog />,
              };
              context.openDialog(dialogProps);
            } else {
              props.loadEnrollmentAndUpdateProposal(option.key.toString());
              setSelectedEnrollmentNumber(option.key.toString());
            }
          }
        }}
        onRenderLabel={props.modernOfficeVersion ? renderComboBoxLabel : undefined}
        onRenderOption={onRenderOption}
      />
    ) : (
      <div>
        <div style={{ display: 'flex' }}>
          <TextboxStandard
            addClass={classes.field}
            dataAutomationId="enrollmentNumberTextField"
            disabled={isReadOnly || props.isLegacy}
            id="enrollment-number-field"
            label={props.modernOfficeVersion ? '' : 'Expiring enrollment number'}
            maxLength={maxEnrollmentLength} // TODO: make this conditional - unideal behavior for pasted inputs
            placeholder="12345678"
            value={cleanedEnrollmentNumber}
            onBlur={() => {
              if (cleanedEnrollmentNumber !== props.enrollmentNumber) {
                props.loadEnrollmentAndUpdateProposal(cleanedEnrollmentNumber || '');
              }
            }}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              value?: string
            ) => {
              const clean = value ? value.replace(/ /g, '') : '';
              setCleanedEnrollmentNumber(clean);
            }}
            onGetErrorMessage={() => {
              const validateEnrollmentNumber = enrollmentNumberValidation.test(
                cleanedEnrollmentNumber || ''
              );
              return validateEnrollmentNumber || !cleanedEnrollmentNumber
                ? ''
                : t('quote::Enter a valid enrollment number');
            }}
            onKeyDown={handleEnrollmentEnter}
            onRenderLabel={props.modernOfficeVersion ? renderTextBoxLabel : undefined}
          />
        </div>
        {props.enrollmentAssemblyEnabled && (
          <LinkButton
            displayText={t('quote::view list of customer enrollments')}
            onClick={() => {
              props.setEnrollmentFieldView(EnrollmentFieldView.ComboBox);
            }}
          />
        )}
      </div>
    );

  const enrollmentNumberField: JSX.Element = flexSectionCreator(
    classes.flexLarge,
    classes.flexXLarge,
    enrollmentNumberFieldView,
    classes.flexContainer
  );

  return enrollmentNumberField;
};

export const EnrollmentNumberField = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(styles)(EnrollmentNumberFieldUnStyled));
