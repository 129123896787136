import { Checkbox, ICheckboxStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { checkboxStyles } from './CheckboxAtom.styles';

export interface CheckboxAtomProps {
  ariaLabel: string;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  label: string;
  onChange: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
  dataAutomationId?: string;
}

type Props = CheckboxAtomProps & WithStyles<typeof checkboxStyles>;

export const UnstyledCheckboxAtom: React.FC<Props> = (props: Props) => {
  const styles: ICheckboxStyles = {
    checkmark: props.classes.checkmark,
  };
  return (
    <div className={props.classes.checkboxWrapper}>
      <Checkbox
        ariaLabel={props.ariaLabel}
        checked={props.checked}
        data-automation-id={props.dataAutomationId}
        disabled={props.disabled}
        id={props.id}
        label={props.label}
        styles={styles}
        onChange={props.onChange}
      />
    </div>
  );
};

export const CheckboxAtom = withStyles(checkboxStyles)(UnstyledCheckboxAtom) as React.FC<
  CheckboxAtomProps
>;
