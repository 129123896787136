import { TextBodySmall } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './Carousel.styles';
import { NavigationBackButton, NavigationForwardButton } from './NavigationButton';

export interface Slide {
  content: JSX.Element;
  dotAriaLabel: string;
  dotDataAutomationId?: string;
  id: string;
  hasError?: boolean;
}

export interface CarouselProps {
  slides: Slide[];
  strings: {
    nextButton: string;
    backButton: string;
    stepInformation: string;
    backButtonAriaLabel: string;
    nextButtonAriaLabel: string;
  };
  initialSlideId?: string;
  onSlideNavigate?: (index: number, slide: Slide) => void;
  sequencedNavigation?: boolean;
}

type Props = CarouselProps & WithStyles<typeof styles>;

export const CarouselUnstyled: React.FC<Props> = (props: Props) => {
  const { initialSlideId, slides, classes, sequencedNavigation, onSlideNavigate, strings } = props;
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number>(() => {
    return slides.findIndex(slide => slide.id === initialSlideId) || 0;
  });

  if (
    currentSlideIndex === undefined ||
    !slides.length ||
    currentSlideIndex >= slides.length ||
    currentSlideIndex < 0
  ) {
    return null;
  }

  const dots = slides.map((slide, index) => {
    if (index === currentSlideIndex) {
      return (
        <button
          aria-label={slide.dotAriaLabel}
          className={classes.selectedDot}
          data-automation-id={slide.dotDataAutomationId}
          disabled
          key={index}
        ></button>
      );
    } else if (index < currentSlideIndex) {
      return (
        <button
          aria-label={slide.dotAriaLabel}
          className={slide.hasError ? classes.errorDot : classes.dot}
          data-automation-id={slide.dotDataAutomationId}
          key={index}
          onClick={() => {
            onSlideNavigate && onSlideNavigate(index, slide);
            setCurrentSlideIndex(index);
          }}
        ></button>
      );
    } else {
      let className = sequencedNavigation ? classes.disabledDot : classes.dot;
      if (slide.hasError) {
        className = classes.errorDot;
      }
      return (
        <button
          aria-label={slide.dotAriaLabel}
          className={className}
          data-automation-id={slide.dotDataAutomationId}
          disabled={sequencedNavigation}
          key={index}
          onClick={() => {
            onSlideNavigate && onSlideNavigate(index, slide);
            setCurrentSlideIndex(index);
          }}
        ></button>
      );
    }
  });

  const isLastSlide = currentSlideIndex === slides.length - 1;
  const isFirstSlide = currentSlideIndex === 0;
  const backButton = (
    <NavigationBackButton
      addClass={isFirstSlide ? classes.hiddenBackButton : classes.backButton}
      ariaLabel={strings.backButtonAriaLabel}
      dataAutomationId="carouselBackButton"
      text={strings.backButton}
      onClick={() => {
        const slide = slides[currentSlideIndex - 1];
        const index = currentSlideIndex - 1;
        onSlideNavigate && onSlideNavigate(index, slide);
        setCurrentSlideIndex(index);
      }}
    />
  );
  const nextButton = (
    <NavigationForwardButton
      addClass={isLastSlide ? classes.hiddenNextButton : classes.nextButton}
      ariaLabel={strings.nextButtonAriaLabel}
      dataAutomationId="carouselNextButton"
      text={strings.nextButton}
      onClick={() => {
        const slide = slides[currentSlideIndex + 1];
        const index = currentSlideIndex + 1;
        onSlideNavigate && onSlideNavigate(index, slide);
        setCurrentSlideIndex(index);
      }}
    />
  );
  const navigation = (
    <div className={classes.navigationContainer}>
      {backButton}
      <TextBodySmall addClass={classes.stepCount}>{strings.stepInformation}</TextBodySmall>
      {nextButton}
    </div>
  );
  return (
    <div>
      <div aria-atomic="true" aria-live="polite" className={props.classes.offScreen}>
        {strings.stepInformation}
      </div>
      {props.slides[currentSlideIndex].content}
      <div className={classes.dotContainer}>{dots}</div>
      {navigation}
    </div>
  );
};
export const Carousel = withStyles(styles)(CarouselUnstyled);
