import { AddLineItemMutationStatus } from 'features-apollo/ActiveQuoteContext';
import {
  hasAnyLineItemErrorMessage,
  isLineItemProductFailedToLoad,
} from 'features-apollo/quote/selectors/lineItem';
import { CatalogItemType } from 'features-apollo/quote/types';
import { getStartConditionForDisplay } from 'features-apollo/quote/utils';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { clearItemSelection, toggleItemSelection } from 'features/proposal/actions';
import { getLastSelectedId, getSelectedIds } from 'features/proposal/selectors';
import { EcifLineItem, MonetaryLineItem, TermLineItem } from 'generated/graphql';
import { useClearSelectionOnUnmount } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { DeleteLineItemsButton, ViewAgreementButton } from '../ActionBarButtons';
import { QuoteList } from '../QuoteList';
import { TermRow } from '../Rows';
import { AgreementTermLineItem, AgreementTermRow } from '../Rows/AgreementTermRow/AgreementTermRow';
import { LoadingRow } from '../Rows/QuoteListRow';
import { QuoteListColumn } from '../types';
import {
  calculateECIFEndDate,
  getTermListRowMessage,
  getTermRowEndDate,
  isProductECIF,
} from './utils';

export interface TermListProps {
  /**
   * Terms found in quote's line items
   */
  termLineItems: (TermLineItem | MonetaryLineItem)[];
  /**
   * Currency of all price information in quote
   */
  billingCurrency: string;
  /**
   * Quote Id
   */
  quoteId: string;
  /**
   * Disables all features except view agreements.
   */
  isQuoteReadOnly: boolean;
  /**
   * Hides action bar
   */
  isQuoteHRDD: boolean;
  /**
   * Agreements found on quote
   */
  agreementTermLineItems: AgreementTermLineItem[];
  /**
   * Use to displays shimmers while waiting for line items to load
   */
  loading?: boolean;
  /**
   * Enables the drag-and-drop feature
   */
  dndEnabled?: boolean;
  /**
   * Detects is drag event is happening.
   * TODO: jepagan - check if this can be detect independently from list component.
   */
  isDragEvent?: boolean;
  /**
   * Enables direc/indirect gov display
   */
  isGovSegmentQuote?: boolean;
  /**
   * Function to execute when the delete button is pressed
   */
  onDelete?: (ids: string[]) => void;
  /**
   * indicates a line item is being added and loading
   */
  addLineItemStatus?: AddLineItemMutationStatus;
}

/**
 * Needed for selection feature
 * TODO - jepagan: explore if this feature can be independent from lists
 *
 * @param state redux store
 */
export const mapStateToProps = (state: RootState) => {
  return {
    selectedIds: getSelectedIds(state),
    lastSelectedId: getLastSelectedId(state),
  };
};

/**
 * Needed for selection feature
 * TODO - jepagan: explore if this feature can be independent from lists
 */
const dispatchProps = {
  toggleItemSelection,
  clearItemSelection,
};

type Props = TermListProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const TermList: React.FC<Props> = props => {
  const { t } = useTranslation();
  useHelpContent(HelpContent.QuoteEditorTerms);
  useClearSelectionOnUnmount(props.clearItemSelection);

  const actionBarButtons: JSX.Element[] = props.isGovSegmentQuote
    ? [
        <DeleteLineItemsButton
          isQuoteReadOnly={props.isQuoteReadOnly}
          key="delete-line-items-btn"
          quoteId={props.quoteId}
          onClick={props.onDelete}
        />,
      ]
    : [
        <DeleteLineItemsButton
          isQuoteReadOnly={props.isQuoteReadOnly}
          key="delete-line-items-btn"
          quoteId={props.quoteId}
          onClick={props.onDelete}
        />,
        <ViewAgreementButton key="view-agreement-btn" quoteId={props.quoteId} />,
      ];

  const flexContent = { name: 2 };

  const columnsHeaders: QuoteListColumn[] = [
    {
      id: 'name-column-header',
      content: t('quote::Name'),
      flex: flexContent.name,
    },
    {
      id: 'start-column-header',
      content: t('quote::Start'),
    },
    {
      id: 'end-column-header',
      content: t('quote::End'),
    },
    {
      id: 'type-column-header',
      content: t('quote::Type'),
    },
  ];

  const itemIds: string[] = [
    ...props.agreementTermLineItems.map(item => item.id),
    ...props.termLineItems.map(item => item.id),
  ];

  const virtualRows = props.agreementTermLineItems.map((agreementTermLineItem, index) => (
    <AgreementTermRow
      agreementTitle={t('quote::Offline Agreement')}
      errors={null}
      flexContent={{
        ...flexContent,
        configCardButtons: [1, 5],
      }}
      isQuoteReadOnly={props.isQuoteReadOnly}
      itemIds={itemIds}
      key={index}
      lastSelectedId={props.lastSelectedId}
      lineItem={agreementTermLineItem}
      lineItemId={agreementTermLineItem.id}
      quoteId={props.quoteId}
      selectedIds={props.selectedIds}
      onCleared={props.clearItemSelection}
      onSelected={props.toggleItemSelection}
    />
  ));

  const isTermECIFLineItem = (termLineItem: EcifLineItem) => {
    return !!oc(termLineItem).oneAskCaseNumber();
  };

  const rows = props.termLineItems.map((termLineItem, index) => {
    const isECIF = isProductECIF(oc(termLineItem).product.productType());
    const isECIFForGov = props.isGovSegmentQuote && isECIF;
    const isECIFLineItem = isTermECIFLineItem(termLineItem as EcifLineItem);
    let startDate = getStartConditionForDisplay({
      startCondition: oc(termLineItem).product.startCondition(),
      catalogItemType: isECIFLineItem ? CatalogItemType.Ecif : CatalogItemType.Term,
    });
    let endDate = getTermRowEndDate(termLineItem.duration, oc(termLineItem).product.durations());
    if (isECIF && isECIFLineItem) {
      endDate = calculateECIFEndDate(termLineItem as EcifLineItem);
    }
    //NehaSadhvi Add end date error calculation for ECIF
    const errored = hasAnyLineItemErrorMessage(termLineItem);
    const message = getTermListRowMessage(termLineItem);
    const productUnavailable = isLineItemProductFailedToLoad(termLineItem);

    return (
      <TermRow
        configurable={!!termLineItem.isConfigurable && !isECIFForGov}
        configuration={oc(termLineItem).configurationSummary.display()}
        dataAutomationId={termLineItem.title || undefined}
        endDate={endDate}
        errored={errored}
        flexContent={flexContent}
        itemIds={itemIds}
        key={index}
        lastSelectedId={props.lastSelectedId}
        lineItem={termLineItem}
        lineItemId={termLineItem.id}
        message={message}
        productTitle={termLineItem.title}
        productUnavailable={productUnavailable}
        quoteId={props.quoteId}
        readOnly={props.isQuoteReadOnly}
        selectedIds={props.selectedIds}
        startDate={startDate}
        onCleared={props.clearItemSelection}
        onSelected={props.toggleItemSelection}
      />
    );
  });

  if (props.addLineItemStatus && props.addLineItemStatus.loading) {
    for (let i = 0; i < oc(props).addLineItemStatus.loadingCount(1); i++) {
      rows.push(<LoadingRow id={`loadingLineItem-${i}`} />);
    }
  }

  const watermark = [
    t('quote::To add a term to the quote, drag or click on a term in the Term finder.'),
    t('quote::All changes are automatically saved.'),
  ];
  // TODO: kaderbez move logic to term list container
  const virtual = (virtualRows.length && virtualRows) || undefined;
  const nonVirtual = (rows.length && rows) || undefined;
  const renderChildren = () => {
    if (virtual || nonVirtual) {
      return (
        <>
          {virtual}
          {nonVirtual}
        </>
      );
    }
  };
  return (
    <QuoteList
      actionBarButtons={actionBarButtons}
      columnsHeaders={columnsHeaders}
      configurationCardIsOpen={false}
      dndEnabled={props.dndEnabled}
      isDragEvent={props.isDragEvent}
      isQuoteHRDD={props.isQuoteHRDD}
      isQuoteReadOnly={props.isQuoteReadOnly}
      loading={props.loading}
      watermark={watermark}
      onClearSelection={props.clearItemSelection}
    >
      {renderChildren()}
    </QuoteList>
  );
};

/**
 * UI representation of terms found in line items and available features for them
 */
export const TermListConnected = connect(mapStateToProps, dispatchProps)(TermList);
