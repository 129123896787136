import { AccountExtensionsConfig } from 'services/account-extensions/config';
import { AccountConfig } from 'services/account/config';
import { AddressValidationConfig } from 'services/address-validation/config';
import { AgreementConfig } from 'services/agreement/config';
import { ApolloConfig } from 'services/apollo/config';
import { ApprovalConfig } from 'services/approval/config';
import { BD360Config } from 'services/bd360/config';
import { BillingGroupConfig } from 'services/billinggroup/config';
import { CatalogConfig } from 'services/catalog/config';
import { ComplianceControlConfig } from 'services/ccf/config';
import { ConstraintsConfig } from 'services/constraints/config';
import { CreditConfig } from 'services/credit/config';
import { CRMConfig } from 'services/crm/config';
import { CustomerConfig } from 'services/customer/config';
import { EdgeSearchConfig } from 'services/edge-search/config';
import { EdgeConfig } from 'services/edge/config';
import { EmpowermentConfig } from 'services/empowerment/config';
import { ExternalUserConfig } from 'services/externaluser/config';
import { FlightsConfig } from 'services/flights/config';
import { LDSSConfig } from 'services/ldss/config';
import { NotificationConfig } from 'services/notification/config';
import { PricingScopeConfig } from 'services/pricingscope/config';
import { ProjectConfig } from 'services/project/config';
import { Environment as ProposalEnvironment, ProposalConfig } from 'services/proposal/config';
import { PurchaseConfig } from 'services/purchase/config';
import { SignUpConfig } from 'services/sign-up/config';
import { UserPreferencesConfig } from 'services/user-preferences/config';
import { AadEnvironment } from 'services/utils';

import { CwaConfig } from './CwaConfig';

export enum AppEnvironment {
  Local = 'DEV',
  Latest = 'INT',
  Stable = 'PPE',
  Test = 'TEST',
  Prod = 'PROD',
}
export interface AppConfig {
  aad: AadEnvironment;
  account: AccountConfig;
  accountExtensions: AccountExtensionsConfig;
  agreement: AgreementConfig;
  app: AppEnvironment;
  appInsightsKey: string;
  approval: ApprovalConfig;
  addressValidation: AddressValidationConfig;
  bd360: BD360Config;
  billinggroup: BillingGroupConfig;
  businessStore: ProposalEnvironment;
  catalog: CatalogConfig;
  ccf: ComplianceControlConfig;
  constraints: ConstraintsConfig;
  credit: CreditConfig;
  crm: CRMConfig;
  customer: CustomerConfig;
  cwa: CwaConfig;
  edge: EdgeConfig;
  edgeSearch: EdgeSearchConfig;
  empowerment: EmpowermentConfig;
  externaluser: ExternalUserConfig;
  flights: FlightsConfig;
  ldss: LDSSConfig;
  notifications: NotificationConfig;
  permissions: PermissionsConfig;
  pricingscope: PricingScopeConfig;
  project: ProjectConfig;
  proposal: ProposalConfig;
  purchase: PurchaseConfig;
  signUp: SignUpConfig;
  translation: { pseudoLoc: boolean };
  userPreferences: UserPreferencesConfig;
  apollo: ApolloConfig;
}

export interface PermissionsConfig {
  oneOf?: string[];
  allOf?: string[];
}

export enum RuleType {
  future = 'discount',
  priceGuarantee = 'priceGuarantee',
  oneTime = 'oneTime',
}

/**
 * Application level configuration will go here. All these configuration would be loaded to Redux store.
 * Please add only those values that are configurable in nature and shared among components.
 * NOTE - Before update please ensure update will not break any consumer.
 */
export interface Config {
  approvalHierarchyLevel: string;
  proposalNextExpiryDays: number;
  suppressedMessages: string[];
  minCreditLineDivisor: number;
  wholeNumberRegex: RegExp;
}
