import { CommandBar, CommandBarButton } from 'components/ions';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { QuoteData } from 'features-apollo/quote/types';
import { Pivot } from 'features/proposal/components/Pivot';
import { MessageSource, ProductAudience, TransactionModel, UserGroup } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { RouteComponentProps, withRouter } from 'react-router';
import { routes } from 'routes';
import { oc } from 'ts-optchain';

const styles = {
  backButton: {
    minWidth: 148,
  },
  pivot: {
    marginLeft: 18,
  },
};

const customerNotifications: MessageSource[] = [
  MessageSource.BillingaccountMissing, // Direct missing billing account
  MessageSource.BillingaccountContactMissing, // Direct or Indirect Customer missing billing account contact (to be deprecated)
  MessageSource.BillingaccountPartnerContactMissing,
  MessageSource.BillingaccountCustomerContactMissing,
  MessageSource.CustomerMissingWorkaccount, // Direct or Indirect Customer missing work account
  MessageSource.PartnerMissingWorkaccount, // Indirect Partner missing work account
  MessageSource.QuoteheaderMissingParticipants, // Indirect Partner &/or Customer billing account missing
  MessageSource.AccountInvalidTenant, // Tenant not yet added
  MessageSource.QuoteheaderMissingOpportunity, // Missing CRM ID
];
const salesNotifications: MessageSource[] = [
  MessageSource.CustomerCreditlineInvalid,
  MessageSource.CustomerCreditRejected,
  MessageSource.QuoteheaderMissingOpportunity,
  MessageSource.QuoteheaderMissingDealestimate,
  MessageSource.EnrollmentnumberInvalid,
  MessageSource.EnrollmentnumberMissing,
];

const anySalesNotifications = (activeQuote: QuoteData) => {
  const isIndirectGov =
    oc(activeQuote).transactionModel() === TransactionModel.ToPartnerCustomerAsset;
  return activeQuote.messages.some(message => {
    // If indirect-gov quote, then we don't need to require this notification
    if (
      !(isIndirectGov && message.messageSource === MessageSource.QuoteheaderMissingDealestimate)
    ) {
      return message.messageSource && salesNotifications.includes(message.messageSource);
    } else {
      return false;
    }
  });
};

type Props = RouteComponentProps & WithStyles<typeof styles>;

const BackToQuoteBarUnstyled: React.FC<Props> = props => {
  const { classes, history, match } = props;
  const { t } = useTranslation();

  const { activeQuote, loading } = React.useContext(ActiveQuoteContext);

  const quoteId = oc(activeQuote).id('');

  let pivotProps = {
    addClass: props.classes.pivot,
    defaultTab: { id: 'customer', path: routes.quote.customer, text: t('quote::Customer') },
    history,
    match,
    quoteId,
    tabs: [
      {
        id: 'customer',
        path: routes.quote.customer,
        text: t('quote::Customer'),
        required: false,
        dataAutomationId: 'customerTab',
      },
      {
        id: 'sales',
        path: routes.quote.sales,
        text: t('quote::Sales'),
        required: false,
        dataAutomationId: 'salesTab',
      },
      {
        id: 'general',
        path: routes.quote.general,
        text: t('quote::General'),
        required: false,
        dataAutomationId: 'generalTab',
      },
    ],
  };
  if (activeQuote && !loading) {
    const anyCustomerNotifications = activeQuote.messages.some(
      message => message.messageSource && customerNotifications.includes(message.messageSource)
    );

    const isPartnerQuote =
      activeQuote.assignedTo === UserGroup.Partner &&
      activeQuote.productAudience === ProductAudience.PartnerCommercial;

    pivotProps = {
      ...pivotProps,
      tabs: [
        {
          id: 'customer',
          path: routes.quote.customer,
          text: t('quote::Customer'),
          required: !isPartnerQuote && anyCustomerNotifications,
          dataAutomationId: 'customerTab',
        },
        {
          id: 'sales',
          path: routes.quote.sales,
          text: t('quote::Sales'),
          required: !isPartnerQuote && anySalesNotifications(activeQuote),
          dataAutomationId: 'salesTab',
        },
        {
          id: 'general',
          path: routes.quote.general,
          text: t('quote::General'),
          required: false,
          dataAutomationId: 'generalTab',
        },
      ],
    };
  }

  return (
    <CommandBar id="ProposalEditor-CommandBar" useBackgroundStandoutColor>
      <CommandBarButton
        addClass={classes.backButton}
        ariaLabel={t('quote::Back to the Quote Editor page')}
        dataAutomationId="backToQuoteButton"
        iconName="Back"
        text={t('quote::Back To Quote')}
        onClick={() => history.push(routes.quote.forId(quoteId))}
      />
      <Pivot {...pivotProps} />
    </CommandBar>
  );
};

export const BackToQuoteBar = withRouter(withStyles(styles)(BackToQuoteBarUnstyled));
