import { ThemeProps } from 'styles';
import { sharedStyles } from 'styles/shared.styles';

import { TextboxAtomProps } from './TextboxAtom';

export const textboxStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  const fieldGroup = {
    ...sharedStyles(theme).roundedCorners,
    '&, &:hover': {
      backgroundColor: (props: TextboxAtomProps) =>
        props.readOnly ? theme.palette.backgroundCommon : palette.backgroundStandout,
      borderColor: (props: TextboxAtomProps) => {
        if (props.readOnly) {
          return theme.palette.backgroundDivider;
        } else {
          const hasError =
            props.errorMessage ||
            (props.onGetErrorMessage && props.onGetErrorMessage(props.value || ''));

          return hasError ? palette.dangerText : palette.border;
        }
      },
    },
    '& .ms-TextField-field::-webkit-input-placeholder': {
      fontStyle: theme.fonts.fontStyles.italic,
    },
    '& .ms-TextField-field::placeholder': {
      fontStyle: theme.fonts.fontStyles.italic,
    },
  };

  return {
    fieldGroup,
    fieldGroupDisabled: {
      ...fieldGroup,
      '&, &:hover': {
        borderColor: theme.palette.backgroundDivider,
      },
    },
    fieldDisabled: {
      ...fieldGroup,
      background: palette.backgroundCommon,
    },
    suffix: {
      color: palette.text,
      backgroundColor: palette.backgroundCommon,
    },
    wrapper: (props: TextboxAtomProps) => ({
      color: props.disabled ? theme.palette.textDisabled : theme.palette.text,
    }),
  };
};
