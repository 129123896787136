import sanitizeHtml from 'sanitize-html';

import { CatalogCallConfig, ProductKey } from './config';
import {
  Image,
  ImageObj,
  ImagePurpose,
  isImageArray,
  Product,
  ProductFamily,
  ProductOwnershipSellingMotion,
  ProductType,
  Service,
  ServiceFamily,
} from './types';

export function StringifyProductKey(productKey: ProductKey) {
  let finalKey = '';
  const keys = Object.keys(productKey) as (keyof ProductKey)[];
  keys.sort();
  keys.forEach(key => {
    finalKey = `${finalKey}|${key}:${productKey[key]}`;
  });
  return finalKey;
}

export function getStringifiedKeyFromConfig(config: CatalogCallConfig) {
  const productKey: ProductKey = {
    agreementType: config.agreementType,
    audience: config.audience,
    nationalCloud: config.nationalCloud,
    market: config.market,
    languages: config.languages,
    template: config.template,
  };
  return StringifyProductKey(productKey);
}

export function InPlaceSanitizeProduct(product: Product) {
  product.LocalizedProperties.forEach(prop => {
    prop.ProductDescription = sanitizeHtml(prop.ProductDescription);
    prop.StrippedDescription = sanitizeHtml(prop.ProductDescription, {
      allowedTags: [],
      allowedAttributes: {},
    }).trim();
  });
}

export const getImageTileByPurpose = (
  imagePurpose: ImagePurpose,
  images: ImageObj
): Image | undefined => {
  if (isImageArray(images.Tile)) {
    return images.Tile.find(tile => tile.ImagePurpose === imagePurpose);
  } else {
    return images.Tile.ImagePurpose === ImagePurpose.smallTile ? images.Tile : undefined;
  }
};

const isAzure3PPReseller = (product: Product, productType: string): boolean =>
  productType === ProductType.Azure &&
  product.Properties.ProductOwnershipSellingMotion ===
    ProductOwnershipSellingMotion.ThirdPartyProductReseller &&
  !!product.Properties.Service &&
  product.Properties.Service.toLowerCase() === Service.VirtualMachinesLicenses &&
  !!product.Properties.ServiceFamily &&
  product.Properties.ServiceFamily.toLowerCase() === ServiceFamily.Compute;

export const filterModernProducts = (products: Product[]): Product[] => {
  return products.filter(product => {
    const productType =
      (product.Properties && product.Properties.ProductType) || product.ProductType;

    const isAzure1PP =
      productType === ProductType.Azure &&
      product.Properties.ProductOwnershipSellingMotion ===
        ProductOwnershipSellingMotion.FirstPartyProducts;

    const isEntitlement3PPAgency =
      productType === ProductType.Entitlement &&
      product.Properties.ProductOwnershipSellingMotion ===
        ProductOwnershipSellingMotion.ThirdPartyProductsAgency &&
      product.Properties.ServiceFamily &&
      product.Properties.ServiceFamily.toLowerCase() === ServiceFamily.SaaS &&
      product.DisplaySkuAvailabilities.some(sku =>
        sku.Availabilities.some(availability => availability.Actions.includes('Purchase'))
      );

    const isSupportedProductType =
      productType === ProductType.AzureAccessPass ||
      productType === ProductType.AzureSupport ||
      productType === ProductType.AzureFamilyDiscount ||
      productType === ProductType.AzureMonetaryCredit ||
      productType === ProductType.AzureMonetaryCommit;

    return (
      product.ProductFamily === ProductFamily.Azure &&
      productType &&
      (isAzure1PP ||
        isAzure3PPReseller(product, productType) ||
        isEntitlement3PPAgency ||
        isSupportedProductType)
    );
  });
};

export const filterLegacyProducts = (products: Product[]): Product[] => {
  return products.filter(product => {
    const productType =
      (product.Properties && product.Properties.ProductType) || product.ProductType;
    const isAzure1PP =
      productType === ProductType.Azure &&
      product.Properties.ProductOwnershipSellingMotion ===
        ProductOwnershipSellingMotion.FirstPartyProducts &&
      product.DisplaySkuAvailabilities.some(sku =>
        sku.Availabilities.some(availability => availability.Actions.includes('Purchase'))
      );

    return product.ProductFamily === ProductFamily.Azure && isAzure1PP;
  });
};
