import { SectionSeparator } from 'components/ions/SectionSeparator';
import { TextWatermarkSmall } from 'components/ions/Text';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export interface AutosuggestFooter {
  primaryText: string;
  secondaryText: string;
}

const styles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 17,
      marginBottom: 17,
      paddingLeft: 5,
      paddingRight: 5,
      textAlign: 'center',
    },
    primaryText: {
      color: palette.textTertiary,
    },
    secondaryText: {
      paddingTop: 3,
      color: palette.textTertiary,
    },
    sectionSeparator: {
      width: '100%',
      marginLeft: 3,
    },
    sectionSeparatorContainer: {
      display: 'flex',
    },
  };
};

type Props = AutosuggestFooter & WithStyles<typeof styles>;

export const AutosuggestFooterUnstyled: React.FC<Props> = props => {
  return (
    <div>
      <div className={props.classes.sectionSeparatorContainer}>
        <SectionSeparator addClass={props.classes.sectionSeparator} />
      </div>
      <div className={props.classes.textContainer}>
        <TextWatermarkSmall addClass={props.classes.primaryText}>
          {props.primaryText}
        </TextWatermarkSmall>
        <TextWatermarkSmall addClass={props.classes.secondaryText}>
          {props.secondaryText}
        </TextWatermarkSmall>
      </div>
    </div>
  );
};

export const AutosuggestFooter = withStyles(styles)(AutosuggestFooterUnstyled);
