import * as actions from 'features/customer/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { ExternalUserConfig } from 'services/externaluser/config';
import { ExternalUser } from 'services/externaluser/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

export function* validateWorkAccount() {
  const relevantAction = actions.validateWorkAccountAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const externalUserConfig: ExternalUserConfig = yield select(
      (state: RootState) => state.app.appConfig.externaluser
    );
    try {
      const result: ExternalUser[] = yield call(
        api.externalUser.validateUser,
        action.payload,
        externalUserConfig
      );
      yield put(actions.validateWorkAccountAsync.success(result));
    } catch (err) {
      yield put(
        actions.validateWorkAccountAsync.failure({
          message: t('error::Error validating work account'),
          exception: err,
        })
      );
    }
  });
}
