import axios from 'axios';
import { PageRequest, PageResponse } from 'services/types';
import { getCV, TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader, getTestHeader, validatePageRequest } from '../utils';
import { endpoints, PricingScopeConfig } from './config';
import { CustomPrice } from './types';

async function getHeaders(config: PricingScopeConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
    'ms-cv': getCV(),
    'api-version': '2019-06-30',
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function getCustomPrices(
  request: PageRequest,
  config: PricingScopeConfig
): Promise<PageResponse<CustomPrice>> {
  validatePageRequest(request);
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = request.nextLink ? `${host}${request.nextLink}` : `${host}/customPrices`;
  const params = {
    byBeneficiaryOrganization: {
      organization: {
        accountId: request.accountId,
        id: request.id,
      },
    },
  };
  const response = await axios.get<PageResponse<CustomPrice>>(url, { params, headers });
  return response.data;
}
