import { Dialog, DialogProps } from 'components';
import { IconButton } from 'components/ions/Buttons';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'store/types';

import { setIsHelpPanelOpen } from '../actions';
import { HelpContent } from '../types';
import { useHelpContent } from '../useHelpContent';

const mapState = (state: RootState) => ({
  isHelpPanelOpen: state.app.isHelpPanelOpen,
});

const mapDispatch = {
  setPanelOpen: setIsHelpPanelOpen,
};

const connector = connect(mapState, mapDispatch);

type Props = {
  children: React.ReactNode;
  helpContentType: HelpContent;
  showHelpButton?: boolean;
} & DialogProps &
  ConnectedProps<typeof connector>;

const DialogWithHelpDisconnected: React.FC<Props> = props => {
  const { helpContentType, isHelpPanelOpen, setPanelOpen, ...dialogProps } = props;

  useHelpContent(helpContentType);

  const toggleHelpPanelVisibility = () => {
    setPanelOpen(!isHelpPanelOpen);
  };

  return (
    <Dialog
      {...dialogProps}
      headerSecondaryButton={
        props.showHelpButton && <IconButton iconName="Help" onClick={toggleHelpPanelVisibility} />
      }
    />
  );
};

export const DialogWithHelp = connector(DialogWithHelpDisconnected);
