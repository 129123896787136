import { TermListLineItem } from './types';

export const getAllLineItemIdsFromSelectedList = (
  termLineItems: TermListLineItem[],
  selectedIds: string[]
) => {
  let idsToDelete: string[] = [];
  selectedIds.forEach(id => {
    const termLineItem = termLineItems.find(item => item.id === id);
    if (termLineItem) {
      if (termLineItem.ecifGroup) {
        const idsFromECIFGroup = termLineItem.ecifGroup.map(item => item.id);
        idsToDelete = idsToDelete.concat(idsFromECIFGroup);
      } else {
        idsToDelete.push(id);
      }
    }
  });
  return idsToDelete;
};
