import { ThemeProps } from 'styles';

import { IconProps } from './types';

const getPredefinedStyle = (props: IconProps, theme: ThemeProps) => {
  switch (props.predefinedStyle) {
    case 'primary':
      return {
        height: 'fit-content',
        borderRadius: '50%',
        backgroundColor: theme.palette.lineItemHighlight,
        padding: 12,
        color: theme.palette.secondary,
      };
    case 'error':
      return {
        height: 'fit-content',
        borderRadius: '50%',
        backgroundColor: theme.palette.dangerHighlight,
        padding: 12,
        color: theme.palette.dangerText,
      };
    case 'approve':
      return { color: theme.palette.approveText };
    case 'danger':
      return { color: theme.palette.dangerText };
    case 'warning':
      return { color: theme.palette.warningText };
    case 'default':
    default:
      return { color: theme.palette.text };
  }
};

export const iconStyles = (theme: ThemeProps) => {
  return {
    iconSmallStyles: (props: IconProps) => ({
      ...getPredefinedStyle(props, theme),
      fontSize: theme.fonts.fontSizes.small,
    }),
    iconMediumStyles: (props: IconProps) => ({
      ...getPredefinedStyle(props, theme),
      fontSize: theme.fonts.fontSizes.medium,
    }),
    iconLargeStyles: (props: IconProps) => ({
      ...getPredefinedStyle(props, theme),
      fontSize: theme.fonts.fontSizes.large,
    }),
    iconLargerStyles: (props: IconProps) => ({
      ...getPredefinedStyle(props, theme),
      fontSize: theme.fonts.fontSizes.larger,
    }),
    iconXLargeStyles: (props: IconProps) => ({
      ...getPredefinedStyle(props, theme),
      fontSize: theme.fonts.fontSizes.xLarge,
    }),
    iconXLargerStyles: (props: IconProps) => ({
      ...getPredefinedStyle(props, theme),
      fontSize: theme.fonts.fontSizes.xLarger,
    }),
    iconXXLargeStyles: (props: IconProps) => ({
      ...getPredefinedStyle(props, theme),
      fontSize: theme.fonts.fontSizes.xXLarge,
    }),
  };
};
