import { Language } from 'features/proposal/supported-languages';
import { Market } from 'features/proposal/supported-markets';

import { ProductFamily } from './types';

export const searchProductPageSize = 12; // maximum total of results the searchProducts api can return
export const searchProductsByFamilyPageSize = 25; // maximum total of results the searchProductsByFamily api can return

export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export enum AgreementType {
  Modern = 'modern',
  Legacy = 'legacy',
}

export enum Audience {
  DirectCommercial = 'Direct-Commercial',
  DirectGov = 'Direct-Gov',
  PartnerCommercial = 'Partner-Commercial',
  PartnerGov = 'Partner-Gov',
}

export enum NationalCloud {
  Global = 'Global', //t('quote::Global')
  USGov = 'US-GOV', //t('quote::US-GOV')
  USNat = 'US-NAT', //t('quote::US-NAT')
  USSec = 'US-SEC', //t('quote::US-SEC')
}

export enum Channel {
  Direct = 'Direct', //t('quote::Direct')
  Indirect = 'Indirect', //t('quote::Indirect')
}

export enum Segment {
  Commercial = 'Commercial', //t('quote::Commercial')
  Government = 'Government', //t('quote::Government')
}

export enum FieldsTemplate {
  Browse = 'browse',
  Details = 'details',
  Purchase = 'purchase',
}

export const DefaultProductFamilies: ProductFamily[] = [
  ProductFamily.Azure,
  ProductFamily.Devices,
  ProductFamily.Passes,
  ProductFamily.Software,
];

export interface CatalogConfig {
  actionFilter: FieldsTemplate;
  environment: Environment;
}

export interface ProductKey {
  agreementType: AgreementType;
  audience: Audience;
  nationalCloud: NationalCloud;
  market: Market;
  languages: Language;
  template: FieldsTemplate;
}

export interface CatalogCallConfig extends CatalogConfig, ProductKey {}

export const endpoints = {
  [Environment.Prod]: 'https://quotecenterservice.l2o.microsoft.com/v2/dcat/v8.0',
  [Environment.Int]: 'https://quotecenterservice.int.l2o.microsoft.com/v2/dcat/v8.0',
};

export const defaultCatalogConfig: CatalogCallConfig = {
  actionFilter: FieldsTemplate.Details,
  environment: Environment.Prod,
  agreementType: AgreementType.Modern,
  audience: Audience.DirectCommercial,
  nationalCloud: NationalCloud.Global,
  template: FieldsTemplate.Details,
  market: 'US',
  languages: 'en-US',
};
