import { OrganizationSummary } from 'services/customer/types';

import { SalesAccount, SalesAccountOrganization } from './types';

export const isInvalidCompanyName = (companyName: string) =>
  companyName.toLowerCase() === 'default directory';
export const isValidCompanyName = (companyName: string) => !isInvalidCompanyName(companyName);
export const organizationHasInvalidCompanyName = (organization: OrganizationSummary) =>
  isInvalidCompanyName(organization.legalEntity.address.companyName);

// #region Sales Account McApiId helpers

const salesAccountMcApiIdSeparator = '::';

export const getOrganizationFromSalesAccount = (
  salesAccount?: SalesAccount
): SalesAccountOrganization | undefined => {
  const ids =
    salesAccount &&
    salesAccount.McApiId &&
    salesAccount.McApiId.split(salesAccountMcApiIdSeparator);

  if (ids && ids.length === 2) {
    return { accountId: ids[0], organizationId: ids[1] };
  }
};

/**
 * Helper to get the correct format of the Sales Account MCAPI ID
 *
 * @param accountId Identifier for the account where the organization can be found
 * @param organizationId Identifier of the organization to be associated to the sales account
 */
export const formatSalesAccountMcApiId = (accountId: string, organizationId: string) =>
  `${accountId}${salesAccountMcApiIdSeparator}${organizationId}`;

// #endregion
