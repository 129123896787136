import {
  PrimaryButton,
  Spinner,
  StatusIndicatorApproved,
  StatusIndicatorInvalid,
  TextboxStandard,
} from 'components';
import { useDebounce } from 'components/utilities/debounce';
import { trimLeadingTrailingSpaces } from 'features/proposal/utils';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { KeyCodes } from '@uifabric/utilities';

import { verificationFieldStyles } from './VerificationField.styles';

export interface VerificationFieldProps {
  allowTryAgain?: boolean;
  hideButton?: boolean;
  buttonClassName?: string;
  buttonDisabled?: boolean;
  buttonErrorText?: string;
  buttonIconName?: string;
  buttonId?: string;
  buttonText?: string;
  containerClassName?: string;
  dataAutomationId?: string;
  debounceValue?: number;
  errorMessage?: string | JSX.Element;
  errorMessageStyle?: string;
  id?: string;
  forceSyncTextBoxValueWithInternalValue?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isVerified?: boolean;
  textboxLabel?: string;
  lastVerified?: string;
  spinnerClassName?: string;
  spinnerDataAutomationId?: string;
  spinnerId?: string;
  textboxAutoFocus?: boolean;
  textboxClassName?: string;
  textboxDisabled?: boolean;
  textboxId?: string;
  textboxLabelStyle?: string;
  textboxMaxLength?: number;
  textboxPlaceholder?: string;
  textboxRequired?: boolean;
  textboxTitle?: string;
  textboxValue?: string;
  validationErrorMessage?: string;
  showButtonWhenVerified?: boolean;
  verifiedStatusClassName?: string;
  verifiedStatusId?: string;
  verifiedStatusText?: string;
  showButtonWhenInvalid?: boolean;
  invalidStatusClassName?: string;
  invalidStatusId?: string;
  invalidStatusText?: string;
  verifyOnBlur?: boolean;
  onChange?: (value?: string) => void;
  onChangeDebounced?: (value?: string) => void;
  onValidate?: (value: string) => boolean;
  onVerify: (value: string) => void;
}

type Props = VerificationFieldProps & WithStyles<typeof verificationFieldStyles>;

const VerificationFieldUnstyled: React.FC<Props> = ({
  classes,
  isLoading,
  onChange,
  onChangeDebounced,
  forceSyncTextBoxValueWithInternalValue,
  textboxValue,
  ...props
}: Props) => {
  const [inputValue, setInputValue] = React.useState(textboxValue || '');
  const validateInput: boolean = props.onValidate ? props.onValidate(inputValue) : true;

  //TODO:cameneks, This is a work around other than this no way exists to reset the state in this component.
  React.useEffect(() => {
    if (textboxValue !== inputValue && forceSyncTextBoxValueWithInternalValue) {
      setInputValue(textboxValue || '');
    }
  }, [textboxValue, forceSyncTextBoxValueWithInternalValue, inputValue]);

  const input$ = useDebounce(props.debounceValue || 100);

  const handleOnVerify = () => {
    if (props.allowTryAgain || (inputValue !== props.lastVerified && validateInput)) {
      props.onVerify(inputValue);
    }
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter) {
      handleOnVerify();
    }
  };

  const handleOnChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    const cleanedValue = value ? trimLeadingTrailingSpaces(value) : value;
    setInputValue(cleanedValue || '');
    input$.next(() => {
      onChangeDebounced && onChangeDebounced(cleanedValue);
    });
    onChange && onChange(value);
  };

  const handleGetErrorMessage = () => {
    if (!validateInput && inputValue) {
      return props.validationErrorMessage || '';
    } else if (props.isError && inputValue && inputValue === props.lastVerified) {
      return props.errorMessage;
    }
    return '';
  };

  const verifyButtonClassName = props.textboxLabel ? classes.button : classes.buttonNoLabel;
  const verifyButton = (
    <span className={`${verifyButtonClassName} ${props.buttonClassName}`}>
      <PrimaryButton
        dataAutomationId={`${props.dataAutomationId}Button`}
        disabled={
          props.buttonDisabled ||
          (props.allowTryAgain
            ? false
            : !validateInput ||
              isLoading ||
              !inputValue ||
              inputValue === props.lastVerified ||
              props.textboxDisabled)
        }
        iconName={props.buttonIconName}
        id={props.buttonId}
        text={props.isError && props.buttonErrorText ? props.buttonErrorText : props.buttonText}
        onClick={handleOnVerify}
      />
    </span>
  );

  const spinnerClassName = props.textboxLabel ? classes.spinner : classes.spinnerNoLabel;
  const spinner = (
    <Spinner
      className={`${spinnerClassName} ${props.spinnerClassName}`}
      dataAutomationId={props.spinnerDataAutomationId}
      id={props.spinnerId}
    />
  );

  const statusClassName = props.textboxLabel ? classes.status : classes.statusNoLabel;
  const verified = (
    <span className={`${statusClassName} ${props.verifiedStatusClassName}`}>
      <StatusIndicatorApproved id={props.verifiedStatusId} text={props.verifiedStatusText || ''} />
    </span>
  );
  const invalid = (
    <span className={`${statusClassName} ${props.invalidStatusClassName}`}>
      <StatusIndicatorInvalid id={props.invalidStatusId} text={props.invalidStatusText || ''} />
    </span>
  );

  const displayStatus = () => {
    const isFinishedVerifying = inputValue === props.lastVerified && props.lastVerified;
    if (isLoading) {
      return spinner;
    }

    if (isFinishedVerifying) {
      if (!props.showButtonWhenVerified && props.isVerified) {
        return verified;
      } else if (!props.showButtonWhenInvalid && !props.isVerified) {
        return invalid;
      }
      return !props.hideButton && verifyButton;
    }

    return !props.hideButton && verifyButton;
  };

  return (
    <div className={`${classes.container} ${props.containerClassName}`} id={props.id}>
      <div className={`${classes.textbox} ${props.textboxClassName}`}>
        <TextboxStandard
          dataAutomationId={`${props.dataAutomationId}Textbox`}
          disabled={props.textboxDisabled || isLoading}
          errorMessage={handleGetErrorMessage()}
          errorMessageStyle={props.errorMessageStyle}
          label={props.textboxLabel}
          labelStyle={props.textboxLabelStyle}
          maxLength={props.textboxMaxLength}
          placeholder={props.textboxPlaceholder}
          required={props.textboxRequired}
          title={props.textboxTitle}
          value={inputValue}
          onBlur={props.verifyOnBlur ? handleOnVerify : undefined}
          onChange={handleOnChange}
          onKeyDown={handlePressEnter}
        />
      </div>
      {displayStatus()}
    </div>
  );
};

VerificationFieldUnstyled.defaultProps = {
  showButtonWhenInvalid: true,
  showButtonWhenVerified: false,
  textboxDisabled: false,
  textboxRequired: false,
  textboxAutoFocus: false,
};

export const VerificationField = withStyles(verificationFieldStyles)(VerificationFieldUnstyled);
