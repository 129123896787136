import { ThemeProps } from 'styles';
export const listStyles = (theme: ThemeProps) => {
  return {
    lineItem: {
      display: 'flex',
      width: '100%',
      cursor: 'pointer',
      minHeight: 70,
      backgroundColor: theme.palette.backgroundStandout,
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    },
    lineItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingLeft: 4,
      boxSizing: 'border-box',
    },
    loading: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 17,
      paddingTop: 7,
      paddingBottom: 7,
      flexGrow: 1,
      justifyContent: 'space-around',
    },
  };
};
