import { ThemeProps } from 'styles';

export const termListRowStyles = (theme: ThemeProps) => {
  return {
    lineItemTop: {
      paddingTop: 12,
      display: 'flex',
    },
    lineItemBottom: {
      paddingTop: 5,
      display: 'flex',
    },
    watermark: {
      fontSize: theme.fonts.fontSizes.small,
    },
  };
};
