import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => ({
  errorMessage: {
    color: theme.palette.dangerText,
    paddingLeft: 70,
  },
  navigation: {
    paddingTop: 16,
  },
  navigationSeparator: {
    height: 1,
    maxWidth: 506,
    color: theme.palette.backgroundDivider,
  },
});
