import { InfoDialogSlideProps } from 'components';
import { UserPreferences } from 'generated/graphql';
import i18next from 'i18next';
import React from 'react';
import { DialogContextType, DialogProps } from 'styles';

import { FirstRunContainer } from './FirstRun.container';
import { DialogVersion } from './types';

/**
 * Gets only the slide that are enabled to display
 */
export const getFirstRunSlides = (): InfoDialogSlideProps[] => {
  const firstRunSlides: InfoDialogSlideProps[] = [
    {
      id: 'quoteContext',
      mediaUrl: '/images/FirstRun/quoteContext.png',
      mediaDescription: i18next.t('People reviewing a blueprint.'),
      headline: i18next.t('New quote capabilities, now in Quote Center'),
      bodyText: i18next.t(
        "When creating a new quote you will now see a new design with options supporting indirect government deals. For direct commercial sellers your steps haven't changed, just name the quote and create!"
      ),
      enabled: true,
    },
  ];

  return firstRunSlides.filter(slide => slide.enabled);
};

/**
 * Whether or not the user has already seen the given first dialog version
 */
export const isFirstRunVersionViewed = (userPreferences: UserPreferences, version: string) => {
  const { dialogsViewed } = userPreferences;
  return dialogsViewed.some(dialogViewed => dialogViewed === version);
};

/**
 * Helper to open the FirstRunDialog component
 */
export const openFirstRunDialog = (context: DialogContextType) => {
  const dialogProps: DialogProps = {
    providedDialog: <FirstRunContainer />,
  };

  context.openDialog(dialogProps);
};

/*
 * Determines whether the first run dialog should display or not
 */
export const hasFirstRunToShow = (userPreferences: UserPreferences) => {
  const enabledSlides = getFirstRunSlides();
  const viewedFirstRun = isFirstRunVersionViewed(userPreferences, DialogVersion.dialog20200831);

  return !viewedFirstRun && !!enabledSlides.length;
};
