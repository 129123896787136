import { ThemeProps } from 'styles';
import { SelectionProps } from './ProposalListRow';

// TODO: michmel-Re-enable when jss is fixed
// const selectBackgroundColor = (theme: ThemeProps, selectionProps: SelectionProps) => {
//   const { palette } = theme;
//   return selectionProps.isSelected
//     ? palette.lineItemHighlight
//     : selectionProps.isInErrorState
//     ? palette.dangerHighlight
//     : selectionProps.isInWarnState
//     ? palette.warningHighlight
//     : palette.transparent;
// };

// const selectIndicatorColor = (theme: ThemeProps, selectionProps: SelectionProps) => {
//   const { palette } = theme;
//   return selectionProps.isSelected
//     ? theme.palette.primary
//     : selectionProps.isInErrorState
//     ? palette.danger
//     : selectionProps.isInWarnState
//     ? palette.warning
//     : palette.transparent;
// };

// #region Content
export const proposalListRowStyles = (theme: ThemeProps) => {
  return {
    lineItem: {
      display: 'flex',
      width: '100%',
      cursor: 'pointer',
      minHeight: 70,
      backgroundColor: theme.palette.backgroundStandout,
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
      '&:hover': {
        backgroundColor: theme.palette.secondaryHover,
      },
    },
    lineItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingLeft: 4,
      boxSizing: 'border-box',
      // TODO: michmel-Re-enable when jss is fixed
      // backgroundColor: (props: SelectionProps) => {
      //   return selectBackgroundColor(theme, props);
      // },
      // borderLeft: (props: SelectionProps) => {
      //   return `4px solid ${selectIndicatorColor(theme, props)}`;
      // },
    },
    lineItemNotSelected: {
      backgroundColor: theme.palette.transparent,
      borderLeft: `4px solid ${theme.palette.transparent}`,
    },
    lineItemSelected: {
      backgroundColor: theme.palette.lineItemHighlight,
      borderLeft: `4px solid ${theme.palette.primary}`,
    },
    lineItemWarning: {
      backgroundColor: theme.palette.warningHighlight,
      borderLeft: `4px solid ${theme.palette.warning}`,
    },
    lineItemError: {
      backgroundColor: theme.palette.dangerHighlight,
      borderLeft: `4px solid ${theme.palette.danger}`,
    },
    lineItemErrorMessage: {
      paddingTop: 5,
      paddingBottom: 10,
      justifyContent: 'center',
      display: (props: SelectionProps) => {
        return props.isInErrorState ? 'flex' : 'none';
      },
    },
    lineItemErrorEnabled: {
      display: 'flex',
    },
    errorText: {
      color: theme.palette.dangerText,
    },
    loading: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 17,
      paddingTop: 7,
      paddingBottom: 7,
      flexGrow: 1,
      justifyContent: 'space-around',
    },
  };
};
