import { TextBody, TextboxStandard } from 'components/ions';
import { trimLeadingTrailingSpaces } from 'features/proposal/utils';
import { useKey } from 'hooks';
import { IRenderFunction, ITextFieldProps, Stack } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ProductGroup } from 'services/user-preferences/types';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  texboxInstructions: {
    color: theme.palette.textTertiary,
    paddingLeft: 8,
  },
});

interface GroupNameFieldProps {
  onChange: (newGroupName?: string) => void;
  onEnterKeyDown: () => void;
  favoritesProductGroups?: Record<string, ProductGroup>;
}

type Props = WithStyles<typeof styles> & GroupNameFieldProps;

export const GroupNameFieldUnstyled: React.FC<Props> = props => {
  const { favoritesProductGroups, onChange } = props;

  const [existingProductGroupsNames, setExistingProductGroupsNames] = React.useState<
    string[] | undefined
  >(undefined);

  React.useEffect(() => {
    if (favoritesProductGroups) {
      const existingProductGroupsNames =
        favoritesProductGroups && Object.keys(favoritesProductGroups);

      setExistingProductGroupsNames(existingProductGroupsNames);
    }
  }, [favoritesProductGroups]);

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  const characterLimit = 20;
  const groupAlreadyExistsError = t('Grouping already exists. Try a different name.');
  const characterLimitExceededError = t('Name exceeds the 20 character limit');

  const handleChange = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newGroupName?: string
  ) => {
    const updateNewGroupName = newGroupName && trimLeadingTrailingSpaces(newGroupName);

    if (updateNewGroupName) {
      if (updateNewGroupName.length > characterLimit) {
        setErrorMessage(characterLimitExceededError);
        onChange(undefined);
        return;
      } else if (
        existingProductGroupsNames &&
        existingProductGroupsNames.includes(updateNewGroupName)
      ) {
        setErrorMessage(groupAlreadyExistsError);
        onChange(undefined);
        return;
      }
    }
    setErrorMessage(undefined);
    onChange(updateNewGroupName);
  };

  const onWrapDefaultLabelRenderer = (
    textfieldProps?: ITextFieldProps,
    defaultRender?: IRenderFunction<ITextFieldProps>
  ): JSX.Element => {
    return (
      <>
        <Stack horizontal verticalAlign="center">
          {defaultRender && defaultRender(textfieldProps)}
          <TextBody addClass={props.classes.texboxInstructions}>
            {t('(20 character limit)')}
          </TextBody>
        </Stack>
      </>
    );
  };

  useKey('Enter', props.onEnterKeyDown);

  return (
    <TextboxStandard
      autoFocus
      dataAutomationId="groupNameTextfield"
      errorMessage={errorMessage}
      label={t('Group name')}
      placeholder={t(`i.e. "Azure products, Miscellaneous, core products"`)}
      onChange={handleChange}
      onRenderLabel={onWrapDefaultLabelRenderer}
    />
  );
};

export const GroupNameFieldStyled = withStyles(styles)(GroupNameFieldUnstyled);
export const GroupNameField = GroupNameFieldStyled;
