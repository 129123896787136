import React from 'react';
import { useTranslation } from 'react-i18next';

import { InformationalTile } from './InformationalTile';

export const BillingAccountNotFoundInfoTile: React.FC<{
  scenario: 'partner' | 'customer';
  onClick?: () => void;
}> = props => {
  const { t } = useTranslation();

  let title;
  let description;
  let iconName;
  let buttonProps;

  switch (props.scenario) {
    case 'partner':
      title = t('quote::No partner billing account found');
      description = t(
        'quote::Please contact partner support as no organization has been created with the tenant or domain name used in your search.'
      );
      iconName = 'ProfileSearch';
      break;
    case 'customer':
    default:
      title = t('quote::No billing account found with that tenant...');
      description = t(
        'quote::But we can quickly create one using the provided tenant information.'
      );
      iconName = 'AccountManagement';
      buttonProps = { text: t('quote::Create billing account'), onClick: props.onClick };
      break;
  }

  return (
    <InformationalTile
      buttonProps={buttonProps}
      description={description}
      iconName={iconName}
      title={title}
    />
  );
};
