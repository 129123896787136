import { GuidanceBox, TextBody } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import { CustomerSearchDialog, CustomerSearchDialogProps } from '../OrganizationSearch';

export interface SelectCustomerForIndirectProps extends CustomerSearchDialogProps {
  disabled?: boolean;
}

export const SelectCustomerForIndirect: React.FC<SelectCustomerForIndirectProps> = props => {
  const { t } = useTranslation();

  // Dialog props
  const context = React.useContext(DialogContext);
  const customerSearchProps = props as CustomerSearchDialogProps;
  const dialogProps: DialogProps = {
    providedDialog: <CustomerSearchDialog {...customerSearchProps} />,
  };

  // Button props
  const buttonProps = {
    dataAutomationId: 'add-customer',
    disabled: props.disabled,
    text: t('quote::Add a customer'),
    onClick: () => context.openDialog(dialogProps),
  };

  return (
    <GuidanceBox buttonProps={buttonProps} title={t('quote::Add a customer...')}>
      <TextBody>
        <p style={{ margin: '8px 0 0' }}>
          {t(
            'quote::The quickest way to find and add a customer is by searching for them using their tenant ID.'
          )}
        </p>
        <p style={{ margin: '16px 0 0' }}>
          {t(
            'quote::In the case that the customer has not been set up with a billing account, you will be able to immediately create one.'
          )}
        </p>
      </TextBody>
    </GuidanceBox>
  );
};
