import { FilteredSkus, StartCondition } from 'generated/graphql';
import i18next from 'i18next';
import moment from 'moment';

import { emailIsValid, emailIsValidStrict } from './contants';
import { CatalogItemType, DateFormat } from './types';

/**
 * Returns date formatted to readable text
 * @default MM/DD/YYYY
 */
export const formatDate = (date: Date | string, format?: DateFormat) => {
  switch (format) {
    case 'MMMM DD, YYYY':
      return moment(date).format('LL');
    case 'YYYY-MM-DD':
      return moment(date).format('YYYY-MM-DD');
    case 'MM/DD/YYYY':
    default:
      return moment(date).format('L');
  }
};

/**
 * Returns text capitalized
 */
export const formatText = (text: string) => text.replace(/^\w/, c => c.toUpperCase());

/**
 * Returns the number of days between the two given dates
 */
export const getRemainingDays = (earlierDate: Date | string, laterDate: Date | string) => {
  const earlier = moment.utc(earlierDate);
  const later = moment.utc(laterDate);
  return later.diff(earlier, 'days');
};

/**
 * Gets the username or account name from a given email address
 */
export const getUsernameFromEmail = (email: string) => email.split('@')[0];

/**
 * Whether or not the given email has a valid format
 */
export const validateEmailFormat = (email: string, strict?: boolean) =>
  strict ? emailIsValidStrict.test(email || '') : emailIsValid.test(email || '');

export const filteredSkusRawToFilteredSkus = (filteredSkusRaw: string) =>
  JSON.parse(filteredSkusRaw) as FilteredSkus[];

export const getStartConditionForDisplay = (params: {
  catalogItemType: CatalogItemType;
  startCondition?: StartCondition | null;
}) => {
  const { catalogItemType, startCondition } = params;
  if (catalogItemType === CatalogItemType.Product) {
    return i18next.t('quote::At order placement');
  }
  if (catalogItemType === CatalogItemType.Ecif) {
    return i18next.t('quote::At order acceptance');
  }
  if (!startCondition) {
    return i18next.t('quote::At order acceptance');
  }
  if (startCondition.toLowerCase() === StartCondition.OnOrderAcceptance.toLowerCase()) {
    return i18next.t('quote::At order acceptance');
  }

  if (startCondition.toLowerCase() === StartCondition.FirstOfMonth.toLowerCase()) {
    return i18next.t('quote::First of this month');
  }
  return i18next.t('quote::At order acceptance');
};
