import * as app from 'features/app/sagas';
import * as catalog from 'features/catalog/sagas/search';
import * as validateConstraints from 'features/catalog/sagas/validation';
import * as customerCrmId from 'features/customer/sagas/crmId';
import * as legacy from 'features/customer/sagas/legacy';
import * as customerModernCommerce from 'features/customer/sagas/modernCommerce';
import * as customerOthers from 'features/customer/sagas/others';
import * as customerSearchOrganizations from 'features/customer/sagas/searchOrganizations';
import * as customerWorkAccount from 'features/customer/sagas/workAccount';
import * as ldssCustomerSearch from 'features/ldsscustomer/sagas/searchCustomers';
import * as quoteActions from 'features/proposal/sagas/actions';
import * as credit from 'features/proposal/sagas/credit';
import * as quoteLineItems from 'features/proposal/sagas/lineItems';
import * as quoteLoadAndHydrate from 'features/proposal/sagas/loadAndHydrate';
import * as quoteModify from 'features/proposal/sagas/modify';
import * as quoteOther from 'features/proposal/sagas/other';
import * as quotePurchaseRecord from 'features/proposal/sagas/purchaseRecord';
import * as userMsContact from 'features/user/sagas/msContact';
import * as userSignedIn from 'features/user/sagas/signedInUser';
import * as userPreferences from 'features/user/sagas/userPreferences';
import { all, fork } from 'redux-saga/effects';

export default function* rootSaga() {
  const sagaList = [
    ...Object.values(app),
    ...Object.values(catalog),
    ...Object.values(credit),
    ...Object.values(customerCrmId),
    ...Object.values(customerModernCommerce),
    ...Object.values(customerOthers),
    ...Object.values(customerSearchOrganizations),
    ...Object.values(customerWorkAccount),
    ...Object.values(ldssCustomerSearch),
    ...Object.values(legacy),
    ...Object.values(quoteLoadAndHydrate),
    ...Object.values(quoteLineItems),
    ...Object.values(quoteModify),
    ...Object.values(quotePurchaseRecord),
    ...Object.values(quoteOther),
    ...Object.values(quoteActions),
    ...Object.values(userMsContact),
    ...Object.values(userPreferences),
    ...Object.values(userSignedIn),
    ...Object.values(validateConstraints),
  ];
  yield all(sagaList.map(saga => fork(saga)));
}
