import { H1Atom, TextSharedProps } from 'components/atoms/Text';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { h1Styles } from './H1.styles';

type Props = TextSharedProps & WithStyles<typeof h1Styles>;

const H1: React.FC<Props> = props => {
  return (
    <H1Atom
      addClass={`${props.classes.titleStyles} ${props.addClass}`}
      dataAutomationId={props.dataAutomationId}
      id={props.id}
      title={props.title}
    >
      {props.children}
    </H1Atom>
  );
};

export const TextTitle = withStyles(h1Styles)(H1) as React.FC<TextSharedProps>;
