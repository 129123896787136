import { LinkButton, NavLinkInternal, NotificationItem } from 'components/ions';
import { RequestCreditLineButton } from 'features-apollo/components/dialogs/CreditDialog';
import {
  getValueOrUndefined,
  RemoveEnrollmentDialog,
  VatIdLinkButton,
  VatIdLinkButtonProps,
} from 'features-apollo/quote/components';
import { isPartnerCustomerQuote } from 'features-apollo/quote/components/DetailsPane/utils';
import { openCrmIdDialog } from 'features-apollo/quote/components/Dialogs/CrmIdDialog';
import {
  openTenantWizardDialog,
  TenantScenario,
  TenantWizardProps,
  TenantWizardView,
} from 'features-apollo/quote/components/Wizards/TenantWizard';
import { MessageSource, Quote, QuoteStatus, UserGroup } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { DialogContext, DialogProps, FontSizes } from 'styles';
import { oc } from 'ts-optchain';

interface NotificationProps {
  disabled?: boolean;
  notification: NotificationItem;
  quote: Quote;
}

interface LineItemNotificationProps extends NotificationProps {
  lineItemId: string;
}

export const SelectOrganizationAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const url = routes.quote.customerForId(props.quote.id);
  return (
    <NavLinkInternal dataAutomationId="selectBillingAccount" to={url}>
      {t('quote::select billing account')}
    </NavLinkInternal>
  );
};

export const AddTenantAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const organization = getValueOrUndefined(props.quote.soldTo.organization);
  const wizardProps: TenantWizardProps = {
    initialView: TenantWizardView.Tenant,
    accountTenants: organization ? organization.account.tenants : [],
    quoteMutationInput: {
      id: props.quote.id,
      etag: props.quote.etag,
    },
    organization: organization
      ? {
          id: organization.id,
          accountId: organization.accountId,
        }
      : {
          id: '',
          accountId: '',
        },
  };
  return (
    <LinkButton
      disabled={props.disabled}
      displayText={t('quote::add tenant')}
      onClick={() => {
        openTenantWizardDialog(context, wizardProps);
      }}
    />
  );
};

type AddWorkAccountProps = NotificationProps & { isPartner?: boolean };
export const AddWorkAccountAction: React.FC<AddWorkAccountProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const isIndirectQuote = isPartnerCustomerQuote(props.quote);

  let organization = getValueOrUndefined(props.quote.soldTo.organization);
  let linkText = t('quote::add work account');
  let specificScenario;

  if (isIndirectQuote) {
    if (!props.isPartner) {
      organization = oc(props).quote.endCustomer();
      linkText = t('quote::add customer work account');
      specificScenario = TenantScenario.partnerCustomer;
    } else {
      linkText = t('quote::add partner work account');
      specificScenario = TenantScenario.partner;
    }
  }

  const wizardProps: TenantWizardProps = {
    initialView: TenantWizardView.Email,
    accountTenants: organization ? organization.account.tenants : [],
    quoteMutationInput: {
      id: props.quote.id,
      etag: props.quote.etag,
    },
    specificScenario,
    organization: organization
      ? {
          id: organization.id,
          accountId: organization.accountId,
        }
      : {
          id: '',
          accountId: '',
        },
  };

  return (
    <LinkButton
      dataAutomationId={`add-work-account-${specificScenario}`}
      disabled={props.disabled}
      displayText={linkText}
      onClick={() => {
        openTenantWizardDialog(context, wizardProps);
      }}
    />
  );
};

export const AddCRMIdAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();

  const context = React.useContext(DialogContext);
  return (
    <LinkButton
      dataAutomationId="enterCRMIdButton"
      disabled={props.disabled}
      displayText={t('quote::enter CRM ID')}
      onClick={() => {
        openCrmIdDialog(context);
      }}
    />
  );
};

export const EditOrganizationAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  // const context = React.useContext(DialogContext);
  //todo: add function to open edit organization account
  return (
    <LinkButton
      dataAutomationId="editOrganizationButton"
      disabled={props.disabled}
      displayText={t('quote::edit billing account')}
      onClick={() => {}}
    />
  );
};

export const OpenConfigCardAction: React.FC<LineItemNotificationProps> = props => {
  const { t } = useTranslation();
  //TODO: Add config card buttons here
  // const linkProps: LinkProps = {
  //   displayText: t('quote::open configuration card'),
  // };
  // const target = React.useRef<HTMLSpanElement>(null);
  return (
    <LinkButton
      dataAutomationId="openConfigCard"
      disabled={props.disabled}
      displayText={t('quote::configure')}
      onClick={() => {}}
    />
  );
};

export const RemoveEnrollmentNumberAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const { quote } = props;
  const removeEnrollmentDialogProps: DialogProps = {
    providedDialog: <RemoveEnrollmentDialog quoteEtag={quote.etag} quoteId={quote.id} />,
  };
  return (
    <LinkButton
      dataAutomationId="removeEnrollmentNumberButton"
      disabled={props.disabled}
      displayText={t('quote::remove enrollment number')}
      onClick={() => context.openDialog(removeEnrollmentDialogProps)}
    />
  );
};

export const ViewInSalesAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const url = routes.quote.salesForId(props.quote.id);
  return (
    <NavLinkInternal dataAutomationId="viewInSalesLink" to={url}>
      {t('quote::view sales properties')}
    </NavLinkInternal>
  );
};

export const SelectExpiringEnrollmentIntentAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const url = routes.quote.salesForId(props.quote.id);
  return (
    <NavLinkInternal dataAutomationId="selectExpiringEnrollmentLink" to={url}>
      {t('quote::select expiring enrollment intent')}
    </NavLinkInternal>
  );
};

export const ViewCustomerSheetAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const url = routes.quote.customerForId(props.quote.id);
  const linkText =
    props.notification.source &&
    props.notification.source ===
      (MessageSource.BillingaccountContactMissing ||
        MessageSource.BillingaccountPartnerContactMissing ||
        MessageSource.BillingaccountCustomerContactMissing)
      ? t('quote::add contact information')
      : t('quote::view customer properties');
  return (
    <NavLinkInternal dataAutomationId="viewCustomerSheet" to={url}>
      {linkText}
    </NavLinkInternal>
  );
};

export const AddSpecialAgreementAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();

  const url = routes.quote.termsForId(props.quote.id);
  return (
    <NavLinkInternal dataAutomationId="uploadAgreement" to={url}>
      {t('quote::upload an agreement')}
    </NavLinkInternal>
  );
};

export const CreditRequestAction: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const { notification, quote } = props;
  const linkText =
    notification.source && notification.source === MessageSource.CustomerCreditRejected
      ? t('quote::resubmit credit line request')
      : t('quote::request credit line');

  return (
    <RequestCreditLineButton
      annualDealEstimate={quote.soldTo.dealEstimate ? quote.soldTo.dealEstimate.toString() : ''}
      billingCurrency={quote.billingCurrency}
      dataAutomationId="detailsCreditAction"
      disabled={quote.status === QuoteStatus.Expired || quote.assignedTo === UserGroup.Customer}
      displayText={linkText}
      etag={quote.etag}
      minimumCreditLine={
        quote.soldTo.monthlyCreditLimit ? quote.soldTo.monthlyCreditLimit.toString() : ''
      }
      quoteId={quote.id}
      size={'small' as keyof FontSizes}
      soldTo={quote.soldTo}
    />
  );
};

export const AddVATAction: React.FC<NotificationProps> = props => {
  const { quote } = props;
  const accountId = oc(quote).leadOrganization.accountId('');
  const organizationId = oc(quote).leadOrganization.id('');
  const vatId = oc(quote).leadOrganization.vatId('');
  const vatIdProps: VatIdLinkButtonProps = {
    organizationIdentifier: { accountId, organizationId },
    size: 'small' as keyof FontSizes,
    initialVatId: vatId,
  };

  return <VatIdLinkButton {...vatIdProps} />;
};

export const NotificationActionLink: React.FC<NotificationProps> = props => {
  const { notification } = props;
  //add query here if you need more data then active quote context provides.
  if (notification.source && notification.source !== null) {
    switch (notification.source) {
      case MessageSource.QuoteheaderMissingOpportunity:
        return <AddCRMIdAction {...props} />;
      case MessageSource.OrganizationsearchNobillingaccount:
      case MessageSource.QuoteheaderMissingParticipants:
      case MessageSource.OrganizationsearchInvalidsearchterm:
      case MessageSource.OrganizationsearchUnmanagedtenant:
      case MessageSource.BillingaccountContactMissing: //TODO(iabowers) - Deprecate BillingaccountContactMissing and replace with either specific partner/customer sources
      case MessageSource.BillingaccountPartnerContactMissing:
      case MessageSource.BillingaccountCustomerContactMissing:
        return <ViewCustomerSheetAction {...props} />;
      case MessageSource.PartnerMissingWorkaccount:
        return <AddWorkAccountAction isPartner={true} {...props} />;
      case MessageSource.CustomerMissingWorkaccount:
        return <AddWorkAccountAction isPartner={false} {...props} />;
      case MessageSource.PartnerCustomerMissingSpecialagreement:
        return <AddSpecialAgreementAction {...props} />;
      case MessageSource.QuoteheaderMissingDealestimate:
        return <ViewInSalesAction {...props} />;
      case MessageSource.CustomerCreditRejected:
      case MessageSource.CustomerCreditlineInvalid:
        return <CreditRequestAction {...props} />;
      case MessageSource.VatNumberNotAdded:
        return <AddVATAction {...props} />;
      case MessageSource.AccountInvalidTenant:
        return <AddTenantAction {...props} />;
      case MessageSource.EnrollmentnumberMissing:
        return <SelectExpiringEnrollmentIntentAction {...props} />;
      case MessageSource.SapEnrollmentnumberRemoval:
        return <RemoveEnrollmentNumberAction {...props} />;
    }
  }
  return null;
};
