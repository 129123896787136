import {
  ComboBox,
  LinkButton,
  mergeClassNames,
  PrimaryButton,
  SecondaryButton,
  TextboxStandard,
} from 'components';
import { FieldType, hasFieldValidationError } from 'components/utilities/address';
import { useDebounce } from 'components/utilities/debounce';
import {
  emptyOrgWizardAddress,
  OrgWizardAddress,
} from 'features-apollo/quote/components/Wizards/OrganizationWizard';
import { ErrorType } from 'features-apollo/quote/components/Wizards/shared';
import {
  getTranslatedMarketsAlphabetically,
  isModernMarketCountry,
} from 'features/proposal/supported-markets';
import {
  AddressConfiguration,
  AddressFieldType,
  AddressRegion,
  AddressValidationStatus,
  RegionDisplayType,
  RegionInfo,
} from 'generated/graphql';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';

import { useLazyQuery } from '@apollo/react-hooks';

import { GetMarketInfoForOrgWizard, ValidateAddress } from '../queries';
import { OrgWizardSharedProps, WizardDialog } from '../Shared';
import { addressDialogStyles } from './AddressDialog.styles';
import {
  AddressDialogHeader,
  AddressHeaderMessage,
  HeaderMessageSeverity,
} from './AddressDialogHeader';
import {
  alphabetizeRegions,
  capitalizeRegionType,
  defaultAddressConfiguration,
  hasValidAddressFields,
  trimAddressFields,
} from './utils';

export interface AddressDialogProps extends OrgWizardSharedProps<OrgWizardAddress> {
  initialAddressStatus?: AddressValidationStatus;
  isEditFlow: boolean;
  isSalesAccount: boolean;
  validationEnabled: boolean;
  onBackButtonClick: (address?: OrgWizardAddress) => void;
  onPrimaryButtonClick: (address: OrgWizardAddress) => void;
}

type ActionType = HTMLAnchorElement | HTMLElement | HTMLButtonElement;
const addressCharacterLimit = 128;

type Props = AddressDialogProps & WithStyles<typeof addressDialogStyles>;

const AddressDialogUnstyled: React.FC<Props> = props => {
  const { classes, initialData } = props;
  const { t } = useTranslation();

  const hasCountry = !!(initialData && initialData.country);
  const isModernMarket =
    !!initialData && !!initialData.country && isModernMarketCountry(initialData.country);
  const countryReadOnly = props.isEditFlow && hasCountry && isModernMarket;

  const addressStyles = mergeClassNames([classes.addressWidthStyles, classes.addressPaddingStyles]);
  const accountType = props.isSalesAccount ? t('quote::sales account') : t('quote::enrollment');

  const maxComboBoxHeight = 250;
  const addressDebounce$ = useDebounce(500);

  const [address, setAddress] = React.useState<OrgWizardAddress>(
    initialData || emptyOrgWizardAddress
  );
  const [addressToBeValidated, setAddressToBeValidated] = React.useState<OrgWizardAddress>();
  const [validationSuggestedAddress, setValidationSuggestedAddress] = React.useState<
    OrgWizardAddress
  >();
  const [validationStatus, setValidationStatus] = React.useState<
    AddressValidationStatus | undefined
  >(props.initialAddressStatus);
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const [isValid, setIsValid] = React.useState<boolean>();

  const [regionsList, setRegionsList] = React.useState<RegionInfo[]>();
  const [addressConfig, setAddressConfig] = React.useState<AddressConfiguration>(
    defaultAddressConfiguration
  );
  const [isAddLineClicked, setIsAddLineClicked] = React.useState(!!address.addressLine3);
  const [touched, setTouched] = React.useState<Partial<Record<FieldType, boolean>>>({});
  const [isPrepopulatedDataInvalid, setIsPrepopulatedDataInvalid] = React.useState(
    hasCountry && isModernMarket && !hasValidAddressFields(address, addressConfig)
  );
  const [errorType, setErrorType] = React.useState<ErrorType | undefined>(undefined);

  const onClickAddLine = (event: React.MouseEvent<ActionType, MouseEvent>) => {
    if (event) setIsAddLineClicked(true);
  };

  const autoFocusCountryCallback = React.useCallback(component => {
    component && component.focus(false, true);
  }, []);

  const [validateAddress, { error: validateError }] = useLazyQuery(ValidateAddress, {
    onCompleted: data => {
      const validationResponse = data.validateAddress;
      setValidationSuggestedAddress(validationResponse.suggestedAddress);
      setValidationStatus(validationResponse.status);
      setValidationErrors(validationResponse.errorFields);
    },
  });

  const [updateMarketInfo] = useLazyQuery(GetMarketInfoForOrgWizard, {
    onCompleted: data => {
      const marketInfo = data.getMarketInfo;
      marketInfo.regions && setRegionsList(alphabetizeRegions(marketInfo.regions));
      marketInfo.addressConfiguration && setAddressConfig(marketInfo.addressConfiguration);
    },
  });

  const allowSaveOnAddressInvalid =
    !!validateError || validationStatus === AddressValidationStatus.Warning;

  const onValid = () => {
    setErrorType(undefined);
    setIsValid(true);
  };

  const onInvalid = (invalidErrorType?: ErrorType) => {
    if (errorType !== invalidErrorType) {
      setErrorType(invalidErrorType);
    }
    if (isValid) {
      setIsValid(false);
    }
  };

  const onClickBack = () => {
    props.onBackButtonClick(address);
  };

  const onClickSave = () => {
    props.onPrimaryButtonClick(address);
  };

  const onClickOverview = () => {
    address && errorType === undefined ? props.onOverviewClick(address) : props.onOverviewClick();
  };

  //this effect updates the ui with the address suggested by AVS, based on user input
  React.useEffect(() => {
    if (
      //prevent race condition: make sure the AVS response is what the user last typed
      addressToBeValidated &&
      addressToBeValidated.postalCode === address.postalCode &&
      //prevent address update loop: only call setAddress if the postal code isn't the suggested value
      validationSuggestedAddress &&
      validationSuggestedAddress.postalCode &&
      validationSuggestedAddress.postalCode !== address.postalCode
    ) {
      setAddress({
        ...address,
        postalCode: validationSuggestedAddress.postalCode,
      });
    }
  }, [address, addressToBeValidated, validationSuggestedAddress]);

  React.useEffect(() => {
    if (initialData && initialData.country)
      updateMarketInfo({ variables: { market: initialData.country } });
    // Only want this to run initially to have regionList if country already set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (hasValidAddressFields(address, addressConfig)) {
      if (props.validationEnabled) {
        setValidationStatus(undefined);
        onInvalid(ErrorType.invalidField);

        //reset validation before calling AVS
        setAddressToBeValidated(address);
        setValidationSuggestedAddress(undefined);

        //make the call to AVS
        addressDebounce$.next(() => {
          validateAddress({ variables: { input: address } });
        });
      } else {
        onValid();
      }
    } else {
      setValidationStatus(undefined);
      onInvalid(ErrorType.missingInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  React.useEffect(() => {
    if (hasValidAddressFields(address, addressConfig) && !validationErrors.length) {
      setIsPrepopulatedDataInvalid(false);
      onValid();
    }
  }, [address, addressConfig, validationErrors]);

  const getFieldErrorMessage = (field: FieldType, required: boolean): string | undefined => {
    if (required) {
      const trimmedAddress = trimAddressFields(address);

      if (
        (field === FieldType.AddressLine1 && !trimmedAddress.addressLine1) ||
        (field === FieldType.City && !trimmedAddress.city) ||
        (field === FieldType.Region && !trimmedAddress.region) ||
        (field === FieldType.PostalCode && !trimmedAddress.postalCode)
      ) {
        return touched[field] ? t('quote::Field is required') : undefined;
      }
    }

    if (hasFieldValidationError(field, validationErrors)) {
      onInvalid(ErrorType.invalidField);
      return t('quote::Incorrect value');
    }
  };

  //#region Build address lines

  const getAddressLine1 = () => (
    <TextboxStandard
      addClass={addressStyles}
      dataAutomationId="addressDialogAddressLine1"
      errorMessage={getFieldErrorMessage(FieldType.AddressLine1, true)}
      label={t('quote::Address line 1')}
      maxLength={addressCharacterLimit}
      placeholder={t('quote::i.e. Street and number or P.O. Box #')}
      required
      value={address.addressLine1 || ''}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        const value: OrgWizardAddress = {
          ...address,
          addressLine1: newValue || '',
        };
        setAddress(value);
        setTouched({
          ...touched,
          [FieldType.AddressLine1]: true,
        });
      }}
    />
  );

  const getAddressLine2 = () => (
    <span className={classes.addressContainerInline}>
      <TextboxStandard
        addClass={addressStyles}
        dataAutomationId="addressDialogAddressLine2"
        label={t('quote::Address line 2')}
        maxLength={addressCharacterLimit}
        placeholder={t('quote::i.e. Unit #, Suite, Floor')}
        value={address.addressLine2 || ''}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value: OrgWizardAddress = {
            ...address,
            addressLine2: newValue || undefined,
          };
          setAddress(value);
          setTouched({
            ...touched,
            [FieldType.AddressLine2]: true,
          });
        }}
      />
      {!isAddLineClicked && !address.addressLine3 && (
        <LinkButton
          addClass={classes.addLinePadding}
          dataAutomationId="addressDialogAddLine"
          displayText={t('quote::add line')}
          iconLeft={true}
          iconName="AddTo"
          onClick={onClickAddLine}
        />
      )}
    </span>
  );

  const getAddressLine3 = () => (
    <TextboxStandard
      addClass={addressStyles}
      dataAutomationId="addressDialogAddressLine3"
      label={t('quote::Address line 3')}
      maxLength={addressCharacterLimit}
      value={address.addressLine3 || ''}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        const value: OrgWizardAddress = {
          ...address,
          addressLine3: newValue || undefined,
        };
        setAddress(value);
        setTouched({
          ...touched,
          [FieldType.AddressLine3]: true,
        });
      }}
    />
  );

  const getCity = () => (
    <TextboxStandard
      addClass={addressStyles}
      dataAutomationId="addressDialogCity"
      errorMessage={getFieldErrorMessage(FieldType.City, true)}
      key="city"
      label={t('quote::City')}
      maxLength={addressCharacterLimit}
      placeholder={t('quote::e.g. Redmond')}
      required
      value={address.city || ''}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        const value: OrgWizardAddress = {
          ...address,
          city: newValue || '',
        };
        setAddress(value);
        setTouched({
          ...touched,
          [FieldType.City]: true,
        });
      }}
    />
  );

  const getRegion = (props: { region: AddressRegion }) => {
    const { region } = props;

    if (regionsList && regionsList.length) {
      let regionOptions = regionsList.map((region: RegionInfo) => {
        return { key: region.code, text: region.display };
      });
      regionOptions.unshift({
        key: 'select-region',
        text: t('quote::Select a {{regionType}}', { regionType: addressConfig.region.type }),
      });
      const getDefaultRegion = () => {
        const regionKey = regionOptions.find(
          option =>
            option.key === (address.region && address.region.toUpperCase()) ||
            option.text.toUpperCase() === (address.region && address.region.toUpperCase())
        );
        return regionKey ? regionKey.key.toString() : 'select-region';
      };
      return (
        <div className={addressStyles} key="region">
          <ComboBox
            errorMessage={getFieldErrorMessage(FieldType.Region, true)}
            id="region"
            label={capitalizeRegionType(addressConfig.region.type)}
            maxHeight={maxComboBoxHeight}
            options={regionOptions}
            required
            selectedKey={getDefaultRegion()}
            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
              if (option) {
                const value: OrgWizardAddress = {
                  ...address,
                  region: option.key === 'select-region' ? '' : option.key.toString(),
                };
                setAddress(value);
                setTouched({
                  ...touched,
                  [FieldType.Region]: true,
                });
              }
            }}
          />
        </div>
      );
    } else {
      const required = region.display === RegionDisplayType.Required;
      return (
        <TextboxStandard
          addClass={addressStyles}
          dataAutomationId="addressDialogRegion"
          errorMessage={
            required || address.region
              ? getFieldErrorMessage(FieldType.Region, required)
              : undefined
          }
          key="region"
          label={capitalizeRegionType(addressConfig.region.type)}
          placeholder={t('quote::e.g. Washington')}
          required={required}
          value={address.region || ''}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) => {
            const value: OrgWizardAddress = {
              ...address,
              region: newValue || undefined,
            };
            setAddress(value);
            setTouched({
              ...touched,
              [FieldType.Region]: true,
            });
          }}
        />
      );
    }
  };

  const getPostalCode = () => {
    return (
      <TextboxStandard
        addClass={addressStyles}
        dataAutomationId="addressDialogPostalCode"
        errorMessage={getFieldErrorMessage(FieldType.PostalCode, true)}
        key="postalCode"
        label={t('quote::Postal Code')}
        maxLength={addressCharacterLimit}
        placeholder={t('quote::e.g. 98052')}
        required
        value={address.postalCode || ''}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value: OrgWizardAddress = {
            ...address,
            postalCode: newValue || undefined,
          };
          setAddress(value);
          setTouched({
            ...touched,
            [FieldType.PostalCode]: true,
          });
        }}
      />
    );
  };

  const getCountry = () => {
    return (
      <div className={addressStyles}>
        <ComboBox
          componentRef={autoFocusCountryCallback}
          disabled={countryReadOnly}
          id="country"
          label={t('quote::Country')}
          maxHeight={maxComboBoxHeight}
          options={getTranslatedMarketsAlphabetically(t)}
          required={!countryReadOnly}
          selectedKey={address.country}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            const country = option ? option.key.toString() : 'US';
            updateMarketInfo({ variables: { market: country } });
            const newAddress = { ...address, region: '', country: country };
            setAddress(newAddress);
            setTouched({
              ...touched,
              [FieldType.Country]: true,
            });
          }}
        />
      </div>
    );
  };
  const getHeaderMessage = (): AddressHeaderMessage | null => {
    let headerMessage = null;

    if (hasCountry && !isModernMarket) {
      headerMessage = {
        text: t(
          `quote::We tried to populate the address based on the {{accountType}}. Unfortunately, the customer resides in a country not currently eligible for the Microsoft Customer Agreement.`,
          { accountType }
        ),
        severity: HeaderMessageSeverity.Error,
      };
    }

    if (isPrepopulatedDataInvalid && !props.isEditFlow) {
      headerMessage = {
        text: t(
          `quote::We've populated the address based on the {{accountType}}. Unfortunately, there are a few errors in the data that need correcting. See below.`,
          { accountType }
        ),
        severity: HeaderMessageSeverity.Error,
      };
    }

    if (!!validateError) {
      headerMessage = {
        text: t(
          'quote::We were unable to validate the address provided due to a service error. If you are confident the address is correct, you may still proceed.'
        ),
        severity: HeaderMessageSeverity.Warning,
      };
    }

    if (validationStatus === AddressValidationStatus.Warning) {
      headerMessage = {
        text: t(
          'quote::We were unable to confirm the address you provided. Often this occurs because there’s a mismatch between two fields like the city and the postal code. Please double check the address. If you are confident it’s correct, you may still proceed.'
        ),
        severity: HeaderMessageSeverity.Warning,
      };
    }

    return headerMessage;
  };

  const headerErrorMessage = getHeaderMessage();

  //#endregion

  const body = (
    <div className={classes.addressContainerStyles}>
      {headerErrorMessage && <AddressDialogHeader message={headerErrorMessage} />}
      {getCountry()}
      {getAddressLine1()}
      {getAddressLine2()}
      {(isAddLineClicked || address.addressLine3) && getAddressLine3()}
      {addressConfig.fieldGroupInOrder.map((fieldType, index) => {
        if (fieldType === AddressFieldType.City) {
          return getCity();
        }

        if (fieldType === AddressFieldType.Region) {
          return getRegion({ region: addressConfig.region });
        }

        if (fieldType === AddressFieldType.PostalCode) {
          return getPostalCode();
        }

        return <React.Fragment key={index} />;
      })}
    </div>
  );

  const backButton = (
    <SecondaryButton
      dataAutomationId="addressDialogBack"
      key="Back"
      text={t('Back')}
      onClick={onClickBack}
    />
  );

  const saveButton = (
    <PrimaryButton
      dataAutomationId="addressDialogSave"
      disabled={!isValid}
      key="Save"
      text={t('Next')}
      onClick={onClickSave}
    />
  );

  const proceedButton = (
    <PrimaryButton
      dataAutomationId="addressDialogProceedAnyway"
      disabled={!isValid}
      key="ProceedAnyway"
      text={t('quote::Proceed Anyway')}
      onClick={() => {
        loggerService.log({
          name: 'Billing address updated with invalid address (Proceed anyway scenario)',
        });
        onClickSave();
      }}
    />
  );

  let footerButtons = [];

  if (!props.isOverviewAvailable) {
    footerButtons.push(backButton);
    if (!allowSaveOnAddressInvalid) {
      footerButtons.push(saveButton);
    }
  }
  if (allowSaveOnAddressInvalid) {
    footerButtons.push(proceedButton);
  }

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      errorType={errorType}
      footer={footerButtons}
      headline={props.headline}
      isOverviewAvailable={props.isOverviewAvailable}
      overviewButtonOnly={props.isOverviewAvailable}
      title={t('quote::Address')}
      onOverviewClick={onClickOverview}
    />
  );
};

export const AddressDialog = withStyles(addressDialogStyles)(AddressDialogUnstyled);
