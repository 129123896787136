import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import app from '../features/app/reducer';
import catalog from '../features/catalog/reducer';
import customer from '../features/customer/reducer';
import ldsscustomer from '../features/ldsscustomer/reducer';
import proposal from '../features/proposal/reducer';
import user from '../features/user/reducer';

const rootReducer = (history: History<any>) =>
  combineReducers({
    router: connectRouter(history),
    app,
    user,
    ldsscustomer,
    proposal,
    customer,
    catalog,
  });

export default rootReducer;
