import { CollapsibleSection } from 'components';
import {
  Checkbox,
  ChoiceGroup,
  Dialog,
  DialogImageProps,
  Dropdown,
  PrimaryButton,
  TextBody,
  TextboxStandard,
} from 'components/ions';
import { Fail, Processing } from 'features-apollo/components';
import { getFulfillmentCloudsBySegment } from 'features-apollo/quote/supported-clouds';
import { getCurrencies, getLanguages } from 'features/proposal/supported-markets';
import {
  Audience as Segment,
  CreateQuoteInput,
  Currency,
  GqlLanguage,
  Market,
  Motion as Channel,
  NationalCloud as Cloud,
  Permission,
} from 'generated/graphql';
import { IChoiceGroupOption, IDropdownOption } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { get as getLocalStorageData, localMarketKey } from 'services/local-storage-service';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => {
  return {
    quoteContextOptions: {
      '& > *': {
        marginTop: 19, // subtracting a padding of 5 for a 24px space
      },
    },
    collapsibleSectionButtonRoot: {
      height: 18,
      padding: 0,
      left: -4,
      marginBottom: 16,
      '& .ms-Button-label': {
        fontSize: theme.fonts.fontSizes.medium,
        color: theme.palette.primary,
        fontWeight: theme.fonts.fontWeights.semiBold,
      },
    },
  };
};

export interface FlightsOptions {
  cspEnabled?: boolean;
  directFGOEEnabled?: boolean;
  directGovEnabled?: boolean;
  indirectFGOEEnabled?: boolean;
  jioAsCspEnabled?: boolean;
  qcTransactEnabled?: boolean;
  usNatEnabled?: boolean;
}

export interface QuoteContextWizardProps {
  /**
   * Displays the loading dialog
   */
  loading: boolean;
  /**
   * Displays the fail dialog
   */
  errored: boolean;
  /**
   * Displays features that the user has access to
   */
  permissions: Permission[];
  /**
   * Displays a feature per flight
   */
  flights?: FlightsOptions;
  /**
   * Callback for the create button onClick event
   */
  onCreate: (input: CreateQuoteInput) => void;
}

type Props = QuoteContextWizardProps & { defaultQuoteName?: string } & WithStyles<typeof styles>;

const INVALID_CHARACTERS_REGEX = /[\!\@\#\%\^\[\]\.\/]|<|>|^$/; // eslint-disable-line no-useless-escape

const DIALOG_DIMENSIONS = { height: 520, width: 935 };
const DIALOG_IMAGE_PROPS: DialogImageProps = { url: '/images/quotecreation.png' };

const QUOTE_LENGTH_LIMIT = 100;
const DEFAULT_CHANNEL = Channel.Direct;
const DEFAULT_SEGMENT = Segment.Commercial;
const DEFAULT_CLOUDS = [Cloud.Global];

const USER_MARKET: Market = getLocalStorageData<Market>(localMarketKey) || Market.Us;
const MARKET_LANGUAGE: GqlLanguage = getLanguages(USER_MARKET)[0].replace('-', '_') as GqlLanguage;
const MARKET_CURRENCY: Currency = getCurrencies(USER_MARKET)[0] as Currency;

export const QuoteContextWizardUnstyled: React.FC<Props> = props => {
  const { flights = {} } = props;

  // Translated error messages
  const { t } = useTranslation();
  const nameTooLongErrorMessage = t('Name cannot be longer than 100 characters');
  const forbiddenCharactersErrorMessage = t(
    'The following characters are not allowed: ! @ # % ^ [ ] . / < >'
  );

  // Values
  const [name, setName] = React.useState<string | undefined>(props.defaultQuoteName);
  const [nameErrorMessage, setNameErrorMessage] = React.useState<string | undefined>();
  const [channel, setChannel] = React.useState<Channel>(DEFAULT_CHANNEL);
  const [segment, setSegment] = React.useState<Segment>(DEFAULT_SEGMENT);
  const [clouds, setClouds] = React.useState<Cloud[]>(DEFAULT_CLOUDS);
  const [isFGOESelected, setFGOE] = React.useState<boolean>(false);
  const [isQcTransact, setQcTransact] = React.useState<boolean>(false);
  const [isJIOCSPSelected, setJIOCSPSelected] = React.useState<boolean>(false);
  const createQuoteDisabled = !name || !!nameErrorMessage || !clouds.length;
  const hasInsiderPermissions = props.permissions.includes(Permission.Insider);
  const hasGovernmentPermissions = props.permissions.includes(Permission.Government);
  const hasCspPermisions = props.permissions.includes(Permission.Csp);
  const hasJioAsCspPermissions = props.permissions.includes(Permission.CspPartnerDeal);

  // Event handlers
  const onNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setName(value);

    // Set errors for quote name
    if (value) {
      if (value.length > QUOTE_LENGTH_LIMIT) {
        setNameErrorMessage(nameTooLongErrorMessage);
        return;
      } else if (INVALID_CHARACTERS_REGEX.test(value)) {
        setNameErrorMessage(forbiddenCharactersErrorMessage);
        return;
      }
    }

    if (nameErrorMessage) {
      setNameErrorMessage(undefined);
    }
  };

  const onChannelChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    if (option) {
      const { key: channelSelected } = option;
      setChannel(channelSelected as Channel);
      setFGOE(false);
      setQcTransact(false);
      if (channelSelected === Channel.Direct) {
        setSegment(Segment.Commercial);
        setClouds([Cloud.Global]);
      } else if (channelSelected === Channel.Partner) {
        setSegment(Segment.Government);
        setClouds([Cloud.UsGov]);
      } else if (channelSelected === Channel.PartnerReseller) {
        setSegment(Segment.Commercial);
        setClouds([Cloud.Global]);
      }
    } else {
      setChannel(DEFAULT_CHANNEL);
      setSegment(DEFAULT_SEGMENT);
      setClouds(DEFAULT_CLOUDS);
    }
  };

  const onSegmentChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    if (option) {
      const { key: segmentSelected } = option;
      setSegment(segmentSelected as Segment);
      setFGOE(false);
      setQcTransact(false);
      if (segmentSelected === Segment.Government) {
        setClouds([Cloud.UsGov]);
      }
    } else {
      setClouds(DEFAULT_CLOUDS);
    }
  };

  const onCloudChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      const selectedCloud = option.key.toString() as Cloud;
      setClouds(
        option.selected ? [...clouds, selectedCloud] : clouds.filter(key => key !== selectedCloud)
      );
    }
  };

  const handleFGOECheckboxToggle = () => {
    setFGOE(!isFGOESelected);
  };

  const handleQcTransactCheckboxToggle = () => {
    setQcTransact(!isQcTransact);
  };

  const handleJIOCSPCheckboxToggle = () => {
    setJIOCSPSelected(!isJIOCSPSelected);
  };

  const onCreateQuote = () => {
    if (name) {
      props.onCreate({
        name,
        clouds,
        motion: channel,
        audience: segment,
        market: USER_MARKET,
        language: MARKET_LANGUAGE,
        billingCurrency: MARKET_CURRENCY,
        fgoe: isFGOESelected,
        transactOnBehalf: isQcTransact,
        noCustomer: isJIOCSPSelected,
      });
    }
  };

  const onCreateQuoteOnEnter = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => (!createQuoteDisabled && event.key === 'Enter' ? onCreateQuote() : undefined);

  // Segment options
  const directChannelOption: IChoiceGroupOption = {
    key: Channel.Direct,
    text: t('Direct'),
  };
  const indirectChannelOption: IChoiceGroupOption = {
    key: Channel.Partner,
    text: t('Indirect'),
  };
  const indirectResellerChannelOption: IChoiceGroupOption = {
    key: Channel.PartnerReseller,
    text: t('Indirect reseller (CSP)'),
  };

  // Segment options
  const commercialSegmentOption: IChoiceGroupOption = {
    key: Segment.Commercial,
    text: t('Commercial'),
  };
  const governmentSegmentOption: IChoiceGroupOption = {
    key: Segment.Government,
    text: t('Government'),
  };

  if (!hasInsiderPermissions) {
    directChannelOption.disabled = true;
    commercialSegmentOption.disabled = true;
  } else if (
    (!flights.directGovEnabled && channel === Channel.Direct) ||
    !hasGovernmentPermissions ||
    channel === Channel.PartnerReseller
  ) {
    governmentSegmentOption.disabled = true;
  } else if (channel === Channel.Partner) {
    commercialSegmentOption.disabled = true;
  }

  const addCSP =
    (flights.cspEnabled && hasCspPermisions) || (flights.jioAsCspEnabled && hasJioAsCspPermissions);

  const channelOptions: IChoiceGroupOption[] = hasInsiderPermissions
    ? addCSP
      ? [directChannelOption, indirectChannelOption, indirectResellerChannelOption]
      : [directChannelOption, indirectChannelOption]
    : [directChannelOption];

  const segmentsOptions: IChoiceGroupOption[] = hasInsiderPermissions
    ? hasGovernmentPermissions
      ? [commercialSegmentOption, governmentSegmentOption]
      : [commercialSegmentOption]
    : [commercialSegmentOption];

  const cloudOptions: IDropdownOption[] = getFulfillmentCloudsBySegment(
    segment,
    flights.usNatEnabled
  );

  //FGOE checkbox
  const showFGOEOption =
    segment === Segment.Government &&
    ((channel === Channel.Direct && flights.directFGOEEnabled) ||
      (channel === Channel.Partner && flights.indirectFGOEEnabled));

  //Advanced options
  const showQcTransactCheckbox =
    channel === Channel.Direct && segment === Segment.Commercial && flights.qcTransactEnabled;

  const showAdvancedOptions =
    (channel === Channel.PartnerReseller && flights.jioAsCspEnabled && hasJioAsCspPermissions) ||
    showQcTransactCheckbox;

  const getAdvancedOption = () =>
    showQcTransactCheckbox ? (
      <Checkbox
        ariaLabel={t('This quote will be transacted in Quote Center')}
        checked={isQcTransact}
        dataAutomationId="QcTransactCheckbox"
        id="QcTransactCheckbox"
        label={t('This quote will be transacted in Quote Center')}
        onChange={handleQcTransactCheckboxToggle}
      />
    ) : (
      <Checkbox
        ariaLabel={t('This quote is for just a partner without a customer')}
        checked={isJIOCSPSelected}
        dataAutomationId="JIOCSPCheckbox"
        id="JIOCSPCheckbox"
        label={t('This quote is for just a partner without a customer')}
        onChange={handleJIOCSPCheckboxToggle}
      />
    );

  // Loading
  if (props.loading) {
    return (
      <Processing
        {...DIALOG_DIMENSIONS}
        imageProps={DIALOG_IMAGE_PROPS}
        message1={t('Creating...')}
      />
    );
  } else if (props.errored) {
    return (
      <Fail
        {...DIALOG_DIMENSIONS}
        imageProps={DIALOG_IMAGE_PROPS}
        message={t('Failed to create quote. Please try again.')}
        onTryAgainClick={onCreateQuote}
      />
    );
  }

  return (
    <Dialog
      {...DIALOG_DIMENSIONS}
      footerButtons={
        <PrimaryButton
          dataAutomationId="createQuoteButton"
          disabled={createQuoteDisabled}
          text={t('Create quote')}
          onClick={onCreateQuote}
        />
      }
      imageProps={DIALOG_IMAGE_PROPS}
      title={t(`Let's create a new quote...`)}
    >
      <TextBody>{t('Please provide some information about the quote you are creating.')}</TextBody>
      <TextboxStandard
        autoFocus
        dataAutomationId="newQuoteName"
        errorMessage={nameErrorMessage}
        label={t('Quote name')}
        required
        value={name}
        onChange={onNameChange}
        onKeyDown={onCreateQuoteOnEnter}
      />
      <div className={props.classes.quoteContextOptions}>
        <ChoiceGroup
          dataAutomationId="newQuoteChannel"
          label={t('Channel')}
          options={channelOptions}
          orientation="horizontal"
          readOnly={!hasInsiderPermissions}
          selectedKey={channel}
          onChange={onChannelChange}
        />
        <ChoiceGroup
          dataAutomationId="newQuoteSegment"
          label={t('Segment')}
          options={segmentsOptions}
          orientation="horizontal"
          readOnly={!hasInsiderPermissions}
          selectedKey={segment}
          onChange={onSegmentChange}
        />
        {cloudOptions.length > 1 && (
          <div style={{ maxWidth: 250, marginBottom: 24 }}>
            <Dropdown
              infoButtonProps={{
                ariaLabel: t('Open fulfillment clouds options'),
                closeButtonAriaLabel: t('Close'),
                content: t(
                  'Used for deals that need to establish products in different government clouds. Deals that allow this selection are able to add and remove clouds once the quote is created.'
                ),
                headline: t('Fulfillment clouds'),
                id: 'fulfillment-clouds-info',
              }}
              label={t('Fulfillment cloud')}
              multiSelect
              options={cloudOptions}
              required
              selectedKeys={clouds}
              stylesPredefined="style2"
              onChange={onCloudChange}
            />
          </div>
        )}
        {showFGOEOption && (
          <Checkbox
            ariaLabel={t('Use the Federal Government Operating Entity (FGOE)')}
            checked={isFGOESelected}
            dataAutomationId="FGOECheckbox"
            id="FGOECheckbox"
            label={t('Use the Federal Government Operating Entity (FGOE)')}
            onChange={handleFGOECheckboxToggle}
          />
        )}
        {showAdvancedOptions && (
          <CollapsibleSection
            label={t('Advanced options')}
            styles={{
              buttonRoot: props.classes.collapsibleSectionButtonRoot,
            }}
          >
            {getAdvancedOption()}
          </CollapsibleSection>
        )}
      </div>
    </Dialog>
  );
};

export const QuoteContextWizard = withStyles(styles)(QuoteContextWizardUnstyled);
