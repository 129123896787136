import { ConditionItem, mergeClassNames, NotificationItem, TextBody } from 'components';
import {
  DetailsStyles,
  renderConditions,
  renderNotifications,
} from 'features-apollo/quote/components/DetailsPane';
import { isQuoteHRDD } from 'features-apollo/selectors';
import { OrganizationSimple, Quote, TransactionModel } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { ApprovalCommentsProp, ApprovalDetails, RequiredApprovalsPerPolicy } from '../../Approval';
import { OrganizationDetails } from './OrganizationDetails';

export interface QuoteDetailsProps {
  id: string;
  conditions?: ConditionItem[];
  notifications?: NotificationItem[];
  isPartner?: boolean;
  quote?: Quote;
  /**
   * Details of partner or customer
   */
  soldToOrganization?: OrganizationSimple;
  /**
   * Details of customer
   */
  endCustomerOrganization?: OrganizationSimple;
  /**
   * Used to identify soldTo as partner information
   */
  transactionModel: TransactionModel;
  /**
   * List of props used for the RequiredApproval component
   */
  requiredApprovals?: RequiredApprovalsPerPolicy[];
  /**
   * Displays after required approval details
   */
  commentsDetails?: ApprovalCommentsProp;
}

type Props = QuoteDetailsProps & WithStyles<typeof DetailsStyles>;

const QuoteDetailsUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, quote } = props;

  const { t } = useTranslation();
  const labels = {
    partner: t('quote::Partner'),
    endCustomer: t('quote::End customer'),
    unknownPartner: t('quote::Unknown partner'),
    unknownParticipants: t('quote::Unknown participants'),
  };

  let unknownParties;
  let partnerDetails;
  let customerDetails;
  let headingClass = classes.heading;
  //TODO: kaderbez refactor HRDD to different components
  const isHRDDQuote = quote && isQuoteHRDD(quote);
  const shouldRenderBothPartnerAndCustomer =
    props.transactionModel === TransactionModel.ToPartnerCustomerAsset || isHRDDQuote; //if its indirect gov or hrdd
  if (shouldRenderBothPartnerAndCustomer) {
    if (props.soldToOrganization || props.endCustomerOrganization) {
      headingClass = mergeClassNames([headingClass, classes.doubleHeading]);
      partnerDetails = (
        <OrganizationDetails
          customUnknwonLabel={labels.unknownPartner}
          headline={labels.partner}
          organization={props.soldToOrganization}
        />
      );
      customerDetails = (
        <OrganizationDetails
          headline={labels.endCustomer}
          organization={props.endCustomerOrganization}
        />
      );
    } else {
      unknownParties = <OrganizationDetails customUnknwonLabel={labels.unknownParticipants} />;
    }
  } else {
    customerDetails = <OrganizationDetails organization={props.soldToOrganization} />;
  }

  const approvalDetails = props.requiredApprovals && (
    <div className={classes.approvalContainer}>
      <TextBody addClass={classes.approvalHeader}>{t('quote::Approvals')}</TextBody>
      <ApprovalDetails
        commentsDetails={props.commentsDetails}
        enableShowMore
        requiredApprovals={props.requiredApprovals}
      />
    </div>
  );

  return (
    <>
      <div className={headingClass}>
        {unknownParties}
        {partnerDetails}
        {customerDetails}
      </div>
      {!isHRDDQuote && props.conditions && renderConditions(props.conditions, classes)}
      {!isHRDDQuote &&
        props.notifications &&
        renderNotifications(props.notifications, classes, quote)}
      {approvalDetails}
    </>
  );
};

export const QuoteDetailsPane = withStyles(DetailsStyles)(QuoteDetailsUnstyled);
