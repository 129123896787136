import { LinkButton, mergeClassNames, TextBody } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

interface BillingContactInformationProps {
  email?: string;
  phoneNumber?: string;
  readOnly?: boolean;
  onClick?: () => void;
  overflowEmail?: boolean;
  dataAutomationId?: string;
}

const styles = {
  information: {
    display: 'block',
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

type Props = BillingContactInformationProps & WithStyles<typeof styles>;

export const BillingContactInformationUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();

  if (!props.email || !props.phoneNumber) {
    return (
      <LinkButton
        dataAutomationId={props.dataAutomationId}
        disabled={props.readOnly}
        displayText={t('quote::add contact')}
        size="medium"
        onClick={props.onClick}
      />
    );
  } else {
    return (
      <>
        <TextBody
          addClass={
            props.overflowEmail
              ? mergeClassNames([props.classes.information, props.classes.overflow])
              : props.classes.information
          }
          dataAutomationId="billingContactEmail"
          title={props.overflowEmail ? props.email : undefined}
        >
          {props.email}
        </TextBody>
        <TextBody addClass={props.classes.information} dataAutomationId="billingContactPhoneNumber">
          {props.phoneNumber}
        </TextBody>
      </>
    );
  }
};

export const BillingContactInformation = withStyles(styles)(BillingContactInformationUnstyled);
