export const SearchResultsStyles = {
  transparent: {
    opacity: 0,
  },
  loadMoreButton: {
    maxWidth: '510px',
    margin: '40px 0',
    paddingRight: '32px',
    textAlign: 'center',
  },
};
