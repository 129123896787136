import { ThemeProps } from 'styles';

export const approveRejectDimensions = {
  height: 432,
  width: 554,
};

export const sharedStyles = (theme: ThemeProps) => ({
  body: {
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
  buttonRightAlign: {
    justifyContent: 'flex-end',
  },
  closeButton: {
    background: 'transparent',
    '& i': {
      fontSize: theme.fonts.fontSizes.xLarge,
    },
  },
  closeButtonWithoutTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '23px 15px 17px 23px',
  },
  footer: {
    backgroundColor: theme.palette.backgroundCommon,
    display: 'flex',
    marginTop: 40,
    padding: '8px 24px 8px 24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 16px 24px 24px',
  },
  secondButton: {
    paddingLeft: 20,
  },
  responseStatusFooter: {
    marginTop: 24,
  },
  processing: {
    height: 'auto',
    marginTop: 70,
  },
  failed: {
    height: 'auto',
  },
  flexAlignRight: {
    justifyContent: 'flex-end',
  },
  padLeft20: {
    paddingLeft: 20,
  },
  marginTop24: {
    marginTop: 24,
  },
});
