/* eslint-disable @typescript-eslint/camelcase */
import { Dialog, PrimaryButton, SecondaryButton, TextBody } from 'components';
import {
  addressHasChanges,
  getAddressForValidation,
  hasInvalidAddressButNoFieldErrors,
  hasValidAddressFields,
  trimAddressFields,
} from 'components/utilities/address';
import { GetLeadOrganizations } from 'features-apollo/quote/components/queries';
import { Processing as ProcessingInterface } from 'features/app/selectors';
import { getProductFragmentsIndexed } from 'features/catalog/selectors';
import { AddressLarge, emptyAddress } from 'features/components/Address';
import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/customer/actions';
import {
  getAccountSuggestedOrganizations,
  getAddressFieldsWithErrors,
  getAddressValidationOriginalAddress,
  getAddressValidationSuggestedAddress,
  getCRMLead,
  getIWOrganizations,
  getLeadOrganizationName,
  getOrganization,
  getSalesAccount,
  getSearchOrganizationSimilarOrganizationResult,
  getSharedCustomPrices,
  getTenant as getTenantSelector,
  getTenantAccount as getTenantAccountSelector,
  hasAddressValidationResponse,
  hasSalesAccount,
  isAddressValid,
  processingOrganizationCreation,
  processingOrganizationEdit,
  processingOrganizationsAddresses as processingOrganizationsAddressesSelector,
  processingOrganizationSelection,
  processingSimilarOrgSearch,
  searchingTenantFootprint,
} from 'features/customer/selectors';
import { Address, TenantProfile } from 'features/customer/types';
import { isValidCompanyName } from 'features/customer/utils';
import * as proposalActions from 'features/proposal/actions';
import {
  accountFoundTitle,
  AccountIndividualsBody,
  AccountNameBody,
  AccountNameFooterButton,
  AccountNameHeaderString,
  AccountOrganizationsBody,
  AddressBody,
  AddressFooterButton,
  AddressHeaderString,
  AffiliateBody,
  AffiliateFooterButtons,
  AffiliateHeaderString,
  InvoiceLanguage,
  InvoiceLanguageBody,
  InvoiceLanguageFooterButton,
  InvoiceLanguageHeaderString,
  OrganizationBody,
  OrganizationFooterButton,
  OrganizationHeaderString,
  OverviewDialog,
  OverviewRowProps,
  ParentAccountBody,
  ParentAccountFooterButtons,
  ParentAccountHeaderString,
  PrimaryButtonType,
  SearchAccountBody,
  searchAccountTitle,
  SharedDiscountsDialog,
  SimilarOrgFoundBody,
  SimilarOrgFoundFooterButton,
  SimilarOrgFoundHeaderString,
} from 'features/proposal/components/Dialogs';
import { BillingContactInformation } from 'features/proposal/components/Dialogs/BillingContact';
import { Success } from 'features/proposal/components/Dialogs/Shared';
import { Organization } from 'features/proposal/components/organization-types';
import {
  getAccountId,
  getActiveProposal,
  getEnrollmentNumber,
  getLeadOrganizationId,
  getOrganizationId,
  getSelectedProject,
  getSharedDiscountLineItems,
} from 'features/proposal/selectors';
import { languageInfo } from 'features/proposal/supported-languages';
import {
  getMarketByCountryCode,
  getMarketByCountryName,
} from 'features/proposal/supported-markets';
import {
  getRegionCodeByRegionName,
  getRegionCodeIfExists,
  MarketWithRegions,
  regions,
} from 'features/proposal/supported-regions';
import {
  createSharedDiscountLineItems,
  getCRMId,
  getInvoiceLanguage,
  getInvoiceLanguages,
} from 'features/proposal/utils';
import { OrganizationEdge } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { CreateTypeCOrganizationFootprintRequest } from 'services/account-extensions/types';
import { Account } from 'services/account/types';
import { getOriginatingChannel } from 'services/ccf/utils';
import { EdgeOrganization, LegalEntity, OrganizationType } from 'services/customer/types';
import loggerService from 'services/logger-service';
import { endpoints as proposalServiceEndpoints, productIds } from 'services/proposal/config';
import { CreateLineItemsRequest } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';
import { oc } from 'ts-optchain';

import { useLazyQuery } from '@apollo/react-hooks';

import { BillingContactBodyContent } from '../../Dialogs/BillingContact/BillingContactBodyContent';
import { ErrorType } from '../shared';
import { organizationWizardStyles } from './OrganizationWizard.styles';

const dialogDimensions = {
  height: 627,
  width: 600,
};

export enum OrganizationWizardView {
  Contact,
  Overview,
  SearchAccount,
  IW,
  OrganizationsOnAccount,
  Account,
  Organization,
  Address,
  Affiliate,
  ParentAccount,
  InvoiceLanguage,
  SimilarOrganization,
  Processing,
}

export enum OrganizationWizardFlow {
  CreateOrganization,
  CreateAccountAndOrganization,
  Edit,
}

export interface OrganizationWizardProps {
  flow: OrganizationWizardFlow;
  initialView?: OrganizationWizardView;
}

const mapStateToProps = (state: RootState, props: OrganizationWizardProps) => {
  const isEditFlow = props.flow === OrganizationWizardFlow.Edit;
  const proposal = getActiveProposal(state);

  const accountId =
    props.flow !== OrganizationWizardFlow.CreateAccountAndOrganization
      ? getAccountId(state, proposal)
      : undefined;

  const account = accountId && state.customer.account[accountId];
  const crmId = getCRMId(proposal);
  const crmLead = crmId && getCRMLead(state, crmId);
  const organizationId = getOrganizationId(state, proposal);
  const salesAccountId = crmLead && crmLead.account.Id;
  const salesAccount = salesAccountId && getSalesAccount(state, salesAccountId);
  const enrollmentNumber = getEnrollmentNumber(state, proposal);
  const language = oc(proposal).header.pricingContext.languages('en-US');
  const agreement = enrollmentNumber && state.customer.enrollment[enrollmentNumber];
  const organization = organizationId && getOrganization(state, organizationId);
  const similarOrganizationItem = getSearchOrganizationSimilarOrganizationResult(state);
  const processingCreate = processingOrganizationCreation(state);
  const processingSelect = processingOrganizationSelection(state);
  const processingEdit = processingOrganizationEdit(state);
  const processingOrganizationsAddresses = processingOrganizationsAddressesSelector(state);
  const products = getProductFragmentsIndexed(state);
  const searchingTenantAccount = searchingTenantFootprint(state);
  const searchingSimilarOrg = processingSimilarOrgSearch(state);
  const getTenant = (tenantId: string) => getTenantSelector(state, tenantId);
  const getTenantAccount = (tenant: string) => getTenantAccountSelector(state, tenant);
  const getTenantAccountIndividuals = (accountId: string) => getIWOrganizations(state, accountId);
  const getTenantAccountOrganizations = (accountId: string) =>
    getAccountSuggestedOrganizations(state, accountId);
  const hasAvsResponse = hasAddressValidationResponse(state);
  const hasAvsApiFailure = state.customer.hasAvsApiFailure;
  const hasValidAddressFromService = isAddressValid(state);
  const addressValidationErrors = hasValidAddressFromService
    ? []
    : getAddressFieldsWithErrors(state);
  const allowSaveOnAddressInvalid =
    hasAvsApiFailure ||
    hasInvalidAddressButNoFieldErrors(
      hasAvsResponse,
      hasValidAddressFromService,
      !!addressValidationErrors.length
    );
  const validationOriginalAddress = getAddressValidationOriginalAddress(state);
  const validationSuggestedAddress = getAddressValidationSuggestedAddress(state);
  const isSalesAccount = hasSalesAccount(state);
  const sharedDiscountLineItems = getSharedDiscountLineItems(state);
  const sharedDiscounts = getSharedCustomPrices(state);
  const leadOrganizationId = getLeadOrganizationId(state);
  const selectedProject = getSelectedProject(state);
  const proposalServiceEndpoint =
    proposalServiceEndpoints[state.app.appConfig.proposal.environment];
  const leadOrganizationName =
    leadOrganizationId && getLeadOrganizationName(state, leadOrganizationId);

  let address: Address;

  // if editing org, use address from org on quote
  // if creating new org and there is an org on the quote, start with emptyAddress
  // if creating new org and there is no org on the quote, use agreement/sales account info to try to populate address
  // if none of the above, default to emptyAddress
  if (isEditFlow && organization) {
    address = organization.legalEntity.address;
  } else if (organization) {
    address = emptyAddress;
  } else if (agreement && agreement.customerAddress) {
    address = {
      addressLine1: agreement.customerAddress.address1 || '',
      addressLine2: agreement.customerAddress.address2 || undefined,
      addressLine3: agreement.customerAddress.address3 || undefined,
      city: agreement.customerAddress.city || '',
      region: agreement.customerAddress.stateProvince,
      country: agreement.customerAddress.country || '',
      postalCode: agreement.customerAddress.postalCode,
      companyName: agreement.customerName || '',
    };
  } else if (salesAccount && salesAccount.Address) {
    address = salesAccount.Address;
  } else {
    address = emptyAddress;
  }

  address = {
    ...address,
    country:
      getMarketByCountryCode(address.country) ||
      getMarketByCountryName(address.country) ||
      address.country,
  };

  const market = address.country as MarketWithRegions;
  if (address.region && regions[market]) {
    const region =
      getRegionCodeIfExists(market, address.region) ||
      getRegionCodeByRegionName(market, address.region);
    address = {
      ...address,
      region,
    };
  }

  let { initialView } = props;

  if (
    (!initialView && !hasValidAddressFields(address)) ||
    !isValidCompanyName(address.companyName)
  ) {
    initialView = OrganizationWizardView.Organization;
  }

  const msContact = proposal.header.msContact;
  let originatingChannel: string | undefined;
  if (msContact) {
    originatingChannel = getOriginatingChannel(state.user.roles[msContact]);
  }

  let email: string | undefined;
  let phoneNumber: string | undefined;

  if (isEditFlow && organization) {
    email = organization.legalEntity.address.email;
    phoneNumber = organization.legalEntity.address.phoneNumber;
  }

  return {
    accountId,
    accountName: account && (account.description || ''),
    address,
    addressValidationErrors,
    allowSaveOnAddressInvalid,
    companyName: address.companyName || '',
    email,
    enrollmentNumber: agreement ? enrollmentNumber : undefined,
    getTenant,
    getTenantAccount,
    getTenantAccountIndividuals,
    getTenantAccountOrganizations,
    hasAvsApiFailure,
    hasAvsResponse,
    isEditFlow,
    sharedDiscounts,
    hasSharedDiscounts: !!sharedDiscountLineItems.length,
    sharedDiscountLineItems,
    hasValidAddressFromService,
    initialView,
    isSalesAccount,
    language,
    leadOrganizationId,
    leadOrganizationName,
    organizationId,
    originatingChannel,
    phoneNumber,
    processingCreate,
    processingEdit,
    processingOrganizationsAddresses,
    processingSelect,
    proposal,
    products,
    searchingSimilarOrg,
    searchingTenantAccount,
    similarOrganizationItem,
    tradeName: organization && organization.legalEntity.tradeName,
    validationOriginalAddress,
    validationSuggestedAddress,
    selectedProject,
    proposalServiceEndpoint,
  };
};

const dispatchProps = {
  createAccountAndOrg: actions.createOrganizationAndAssociateAsync.request,
  resetAddressValidation: actions.resetAddressValidation,
  searchForAccount: actions.searchTenantFootprintAsync.request,
  searchForSimilarOrganizations: actions.searchOrganizationForSimilarOrganizationsAsync.request,
  selectOrganization: proposalActions.selectOrganizationAsync.request,
  updateOrganization: actions.updateOrganizationAsync.request,
  updateProposal: proposalActions.updateProposalAsync.request,
  validateAddress: actions.validateAddressAsync.request,
  loadDFDProduct: proposalActions.loadDFDProduct,
  addLineItem: (request: CreateLineItemsRequest) =>
    proposalActions.createProposalLineItemsAsync.request(request),
};

enum OrganizationAction {
  Select,
  Create,
  Update,
}

export type OrganizationWizardStateProps = typeof dispatchProps &
  ReturnType<typeof mapStateToProps> &
  OrganizationWizardProps;

type Props = OrganizationWizardStateProps & WithStyles<typeof organizationWizardStyles>;

interface LocalState<T> {
  current: T;
  new?: T;
}

const OrganizationWizardUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    accountId,
    hasValidAddressFromService,
    organizationId,
    originatingChannel,
    proposal,
    products,
    loadDFDProduct,
    resetAddressValidation,
    sharedDiscounts,
    sharedDiscountLineItems,
  } = props;

  const dfdProduct = products[productIds.discountFulfillmentDocument];
  //on mounted
  React.useEffect(() => {
    resetAddressValidation();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!dfdProduct) {
      loadDFDProduct();
    }
  }, [dfdProduct, loadDFDProduct]);

  //#region State Management
  const [accountName, setAccountName] = React.useState<LocalState<string>>({
    current: props.accountName || props.companyName,
  });
  const [organization, setOrganization] = React.useState<LocalState<Organization>>({
    current: {
      name: props.companyName,
      doingBusinessAs: props.isEditFlow ? props.tradeName : undefined,
    },
  });
  const [address, setAddress] = React.useState<LocalState<Address>>({ current: props.address });
  const [contactInformation, setContactInformation] = React.useState<
    LocalState<{ email?: string; phoneNumber?: string }>
  >({ current: { email: props.email, phoneNumber: props.phoneNumber } });

  const [errorType, setErrorType] = React.useState<ErrorType | undefined>();
  const [showFooterErrorMessage, shouldShowFooterErrorMessage] = React.useState<boolean>(false);

  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [isOverview, setIsOverview] = React.useState<boolean>(false);
  const [organizationAction, setOrganizationAction] = React.useState(OrganizationAction.Create);
  const [verifiedTenantId, setVerifiedTenantId] = React.useState<string | undefined>();
  const [tenantAccountId, setTenantAccountId] = React.useState<string | undefined>(accountId);
  const [currentStep, setCurrentStep] = React.useState<OrganizationWizardView>(
    props.initialView || OrganizationWizardView.Overview
  );
  const [tenantAccountName, setTenantAccountName] = React.useState<string | undefined>(
    props.accountName
  );
  const [invoiceLanguage, setInvoiceLanguage] = React.useState<InvoiceLanguage>(
    getInvoiceLanguage(address.current.country, props.language)
  );
  const [parentAccount, setParentAccount] = React.useState<string | undefined>(
    props.isEditFlow ? props.leadOrganizationId : undefined
  );
  const [isAffiliate, setIsAffiliate] = React.useState<boolean>(
    props.isEditFlow && !!parentAccount
  );
  const [affiliateInfoOpen, setAffiliateInfoOpen] = React.useState<boolean>(false);
  //#endregion

  //#region GQL for eligible Lead Billing Accounts
  let eligibleParentAccounts: EdgeOrganization[] = [];

  const [getLeadOrganizations, { loading, data }] = useLazyQuery(GetLeadOrganizations, {
    errorPolicy: 'all',
  });

  if (data) {
    eligibleParentAccounts = data.leadOrganizationsByAccountId.edges
      .filter((org: OrganizationEdge) => !!org)
      .map((edge: { node: EdgeOrganization }) => edge.node);
  }

  React.useEffect(() => {
    if (tenantAccountId) {
      getLeadOrganizations({
        variables: { accountId: tenantAccountId, cursor: null },
      });
    }
  }, [tenantAccountId, getLeadOrganizations]);
  //#endregion

  //address line 2 and 3 cannot be null or empty. Limitation by organization api
  const addressWithCompanyName: Address = {
    ...address.current,
    companyName: organization.current.name,
  };

  // When Address Validation Flight is on, we need to make a call to AVS with the starting address
  // so we can accurately determine if we can go to Overview with the prepopulated address
  // without this, it will only verify that the required fields have values, and that market resolves to an appropriate country
  React.useEffect(() => {
    const addressForValidation = getAddressForValidation(address.current);
    props.validateAddress(addressForValidation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParentAccountName = () => {
    if (loading && props.leadOrganizationName) {
      return props.leadOrganizationName;
    }
    const account = eligibleParentAccounts.find(
      (account: EdgeOrganization) => account.id === parentAccount
    );
    return account ? account.legalEntity.address.companyName : undefined;
  };

  const notReady = () => setIsValid(false);
  const setCurrentStepFactory = (view: OrganizationWizardView) => () => {
    setCurrentStep(view);
    props.resetAddressValidation();
  };

  const backToOverview = (onClick?: () => void) => [
    <SecondaryButton
      dataAutomationId="backToOverviewButton"
      key="back-to-overview"
      text={t('quote::Back to overview')}
      onBlur={() => shouldShowFooterErrorMessage(false)}
      onClick={() => {
        onClick && onClick();
        setCurrentStepFactory(OrganizationWizardView.Overview)();
      }}
      onFocus={() => shouldShowFooterErrorMessage(true)}
      onMouseEnter={() => shouldShowFooterErrorMessage(true)}
      onMouseLeave={event => {
        if (event.target !== document.activeElement) shouldShowFooterErrorMessage(false);
      }}
    />,
  ];

  const createOrganization = () => {
    const orgRequest: CreateTypeCOrganizationFootprintRequest = {
      accountDescription: tenantAccountName || accountName.current,
      accountId: tenantAccountId || props.accountId, // If tenant account found, create org in that account
      organizationDescription: organization.current.name,
      companyName: organization.current.name,
      organizationAddress: {
        ...trimAddressFields(addressWithCompanyName),
        ...contactInformation.current,
      },
      culture: invoiceLanguage.language,
      language: languageInfo[invoiceLanguage.language].twoLetterCode,
      customerAccountType: (props.enrollmentNumber && 'enrollment') || undefined,
      customerAccountNumber: props.enrollmentNumber || undefined,
      tenantId: verifiedTenantId,
      tradeName: organization.current.doingBusinessAs,
      originatingChannel,
    };

    props.createAccountAndOrg({
      createRequest: orgRequest,
      quoteId: proposal.id,
      leadOrgId: parentAccount,
    });
    setCurrentStep(OrganizationWizardView.Processing);
    setOrganizationAction(OrganizationAction.Create);

    if (parentAccount) {
      loggerService.log({ name: 'Affiliate Billing Account created' });
    } else {
      loggerService.log({ name: 'Lead Billing Account created' });
    }
  };

  const context = React.useContext(DialogContext);

  const addSharedDiscounts = () => {
    const dfdProduct = props.products[productIds.discountFulfillmentDocument];
    if (
      dfdProduct &&
      sharedDiscounts &&
      sharedDiscounts.length &&
      sharedDiscountLineItems &&
      props.proposal
    ) {
      createSharedDiscountLineItems(
        sharedDiscounts,
        sharedDiscountLineItems,
        dfdProduct,
        props.proposal,
        props.proposalServiceEndpoint,
        props.addLineItem,
        props.selectedProject
      );
    }
  };

  const closeDialog = () => {
    context.closeDialog();
  };

  const openSharedDiscounts = () => {
    closeDialog();
    const dialogProps: DialogProps = {
      providedDialog: (
        <SharedDiscountsDialog
          affiliateAccountName={organization.current.name}
          extendDiscounts={() => addSharedDiscounts()}
          parentAccountName={getParentAccountName()}
        />
      ),
    };
    const sharedDiscountsToAdd =
      sharedDiscounts &&
      sharedDiscounts.length &&
      sharedDiscountLineItems &&
      sharedDiscounts.length !== sharedDiscountLineItems.length;
    if (sharedDiscountsToAdd) {
      context.openDialog(dialogProps);
    }
  };

  const onSaveOrganization = () => {
    if (accountId && organizationId) {
      const trimmedAddress = trimAddressFields(addressWithCompanyName);
      const updateOrgRequest: LegalEntity = {
        tradeName: organization.current.doingBusinessAs,
        businessLocationId: '',
        address: {
          ...trimmedAddress,
          addressType: OrganizationType.organization,
          ...contactInformation.current,
        },
      };
      props.updateOrganization(updateOrgRequest, {
        accountId,
        organizationId,
        leadOrgId: parentAccount,
      });
      setCurrentStep(OrganizationWizardView.Processing);
      setOrganizationAction(OrganizationAction.Update);

      if (parentAccount && parentAccount !== props.leadOrganizationId) {
        if (!props.leadOrganizationId) {
          loggerService.log({ name: 'Billing Account updated from Lead to Affiliate' });
        } else {
          loggerService.log({ name: 'Affiliate Billing Account parent updated' });
        }
      } else if (props.leadOrganizationId && !parentAccount) {
        loggerService.log({ name: 'Billing Account updated from Affiliate to Lead' });
      }
    } else {
      loggerService.error({
        error: new Error(
          `Edit flow should not be triggered if there is no billing account. proposalId: ${proposal.id}`
        ),
      });
    }
  };

  const selectOrganization = () => {
    const organizationItem =
      props.similarOrganizationItem && props.similarOrganizationItem.organizationItem;
    if (!organizationItem) {
      return;
    }
    const similarOrganization = organizationItem.organization;
    const similarOrganizationAccountId = organizationItem.account.id;
    props.selectOrganization({
      accountId: similarOrganizationAccountId,
      organizationId: similarOrganization.id,
    });
    setCurrentStep(OrganizationWizardView.Processing);
    setOrganizationAction(OrganizationAction.Select);
  };

  const onClickCreateOrganization =
    !accountName.current && !tenantAccountName
      ? setCurrentStepFactory(OrganizationWizardView.Account)
      : !hasValidAddressFields(address.current) || !hasValidAddressFromService
      ? setCurrentStepFactory(OrganizationWizardView.Organization)
      : setCurrentStepFactory(OrganizationWizardView.Overview);

  const onSubmitInvoiceLanguageClick = () => {
    if (accountId && organizationId) {
      setCurrentStep(OrganizationWizardView.Processing);
    } else {
      loggerService.error({
        error: new Error(
          `Edit flow should not be triggered if there is no billing account. proposalId: ${proposal.id}`
        ),
      });
    }
  };
  const onSubmitClick = () => {
    props.searchForSimilarOrganizations({
      addressLine1: addressWithCompanyName.addressLine1,
      companyName: organization.current.name,
    });
    setCurrentStep(OrganizationWizardView.SimilarOrganization);

    if (addressHasChanges(props.address, address.current)) {
      loggerService.log({
        name: 'Billing address updated',
      });
    }
  };

  const headline = props.isEditFlow
    ? t('quote::Edit Billing Account')
    : t('quote::New Billing Account');

  let customizedHeadline: string | undefined = undefined;
  let title: string;
  let body;
  let footer: JSX.Element[] | undefined = backToOverview();

  switch (currentStep) {
    case OrganizationWizardView.Account: {
      if (!accountName.current && !accountName.new && isValid) {
        setIsValid(false);
      }
      title = AccountNameHeaderString();
      body = (
        <AccountNameBody
          initialData={accountName.current}
          onInvalid={errorType => {
            notReady();
            setErrorType(errorType);
          }}
          onValid={(newAccountName: string) => {
            setAccountName({ ...accountName, new: newAccountName });
            setIsValid(true);
            setErrorType(undefined);
          }}
        />
      );
      footer = isOverview
        ? backToOverview(
            () => accountName.new && isValid && setAccountName({ current: accountName.new })
          )
        : AccountNameFooterButton(() => {
            accountName.new && isValid && setAccountName({ current: accountName.new });
            setCurrentStepFactory(OrganizationWizardView.Organization)();
          }, isValid);
      break;
    }
    case OrganizationWizardView.Organization: {
      if (
        !organization.current.name &&
        (!organization.new || (organization.new && !organization.new.name)) &&
        isValid
      ) {
        setIsValid(false);
      }
      title = OrganizationHeaderString();
      body = (
        <OrganizationBody
          initialData={organization.current}
          onInvalid={errorType => {
            notReady();
            setErrorType(errorType);
          }}
          onValid={(newOrganization: Organization) => {
            setOrganization({ ...organization, new: newOrganization });
            setIsValid(true);
            setErrorType(undefined);
          }}
        />
      );
      const saveValidData = () =>
        organization.new && isValid && setOrganization({ current: organization.new });

      footer = isOverview
        ? backToOverview(saveValidData)
        : OrganizationFooterButton(() => {
            setCurrentStepFactory(OrganizationWizardView.Address)();
            saveValidData();
          }, isValid);
      break;
    }
    case OrganizationWizardView.Address: {
      if (isValid) {
        const addressToValidate = address.new || address.current;
        if (!hasValidAddressFields(addressToValidate) || !props.hasValidAddressFromService) {
          setIsValid(false);
        }
      }
      title = AddressHeaderString();
      body = (
        <AddressBody
          hasAddressValidationResponse={props.hasAvsResponse}
          hasAvsApiFailure={props.hasAvsApiFailure}
          hasValidAddressFromService={props.hasValidAddressFromService}
          initialData={address.current}
          isEditFlow={props.isEditFlow}
          isSalesAccount={props.isSalesAccount}
          resetAddressValidation={props.resetAddressValidation}
          showInitialErrorMessage={true}
          validateAddress={props.validateAddress}
          validationErrors={props.addressValidationErrors}
          validationOriginalAddress={props.validationOriginalAddress}
          validationSuggestedAddress={props.validationSuggestedAddress}
          onInvalid={errorType => {
            notReady();
            setErrorType(errorType);
          }}
          onValid={(newAddress: Address) => {
            setAddress({ ...address, new: newAddress });
            setInvoiceLanguage(getInvoiceLanguage(newAddress.country, props.language));
            setIsValid(true);
            setErrorType(undefined);
          }}
        />
      );

      const saveNewAddress = () => address.new && setAddress({ current: address.new });

      if (isOverview) {
        if (props.allowSaveOnAddressInvalid) {
          footer = [
            <SecondaryButton
              dataAutomationId="proceedAnywayButton"
              key="proceed-back-to-overview"
              text={t('quote::Proceed Anyway')}
              onClick={() => {
                saveNewAddress();
                setCurrentStepFactory(OrganizationWizardView.Overview)();
                loggerService.log({
                  name: 'Back to overview button clicked (Proceed anyway scenario)',
                });
              }}
            />,
          ];
        } else {
          footer = backToOverview(() => isValid && saveNewAddress());
        }
      } else {
        footer = AddressFooterButton(
          props.allowSaveOnAddressInvalid
            ? PrimaryButtonType.ProceedAnyway
            : PrimaryButtonType.Next,
          () => {
            saveNewAddress();
            setCurrentStepFactory(OrganizationWizardView.Overview)();
          },
          () => {
            saveNewAddress();
            setCurrentStepFactory(OrganizationWizardView.Organization)();
          },
          props.allowSaveOnAddressInvalid || isValid
        );
      }

      break;
    }
    case OrganizationWizardView.Contact: {
      title = t('quote::Contact Information');
      body = (
        <BillingContactBodyContent
          {...contactInformation.current}
          setEmail={({ address, invalid }) => {
            setContactInformation({
              ...contactInformation,
              new: contactInformation.new
                ? { ...contactInformation.new, email: address }
                : { ...contactInformation.current, email: address },
            });

            if (invalid) {
              notReady();
              setErrorType(ErrorType.invalidField);
            } else {
              setIsValid(true);
              setErrorType(undefined);
            }
          }}
          setPhoneNumber={phoneNumber =>
            setContactInformation({
              ...contactInformation,
              new: contactInformation.new
                ? { ...contactInformation.new, phoneNumber }
                : { ...contactInformation.current, phoneNumber },
            })
          }
        />
      );
      const setValidData = () =>
        contactInformation.new &&
        isValid &&
        setContactInformation({ current: contactInformation.new });

      footer = backToOverview(setValidData);
      break;
    }
    case OrganizationWizardView.Affiliate: {
      title = AffiliateHeaderString();
      body = (
        <AffiliateBody
          initialData={isAffiliate}
          showInfoMessage={!isAffiliate && props.hasSharedDiscounts}
          onInvalid={() => {
            notReady();
            setIsAffiliate(true);
          }}
          onValid={() => {
            setIsAffiliate(false);
            setParentAccount(undefined);
            setIsValid(true);
          }}
        />
      );
      footer = AffiliateFooterButtons(
        isValid,
        isAffiliate,
        setCurrentStepFactory(OrganizationWizardView.Overview),
        setCurrentStepFactory(OrganizationWizardView.ParentAccount)
      );
      break;
    }
    case OrganizationWizardView.ParentAccount: {
      title = ParentAccountHeaderString();
      body = (
        <ParentAccountBody
          eligibleParentAccounts={eligibleParentAccounts}
          hasSharedDiscounts={props.hasSharedDiscounts}
          initialData={parentAccount}
          leadOrgOnQuote={props.leadOrganizationId}
          processingAddresses={props.processingOrganizationsAddresses}
          onInvalid={notReady}
          onValid={(parentId: string | undefined) => {
            setParentAccount(parentId);
            setIsValid(true);
          }}
        />
      );
      footer = ParentAccountFooterButtons(
        isValid,
        setCurrentStepFactory(OrganizationWizardView.Overview),
        setCurrentStepFactory(OrganizationWizardView.Affiliate)
      );
      break;
    }
    case OrganizationWizardView.InvoiceLanguage: {
      if (!isValid) {
        setIsValid(true);
        setInvoiceLanguage(invoiceLanguage);
      }
      title = InvoiceLanguageHeaderString();
      body = (
        <InvoiceLanguageBody
          initialData={invoiceLanguage}
          onInvalid={notReady}
          onValid={(newInvoiceLanguage: InvoiceLanguage) => {
            setInvoiceLanguage(newInvoiceLanguage);
          }}
        />
      );
      footer = isOverview
        ? backToOverview()
        : InvoiceLanguageFooterButton(onSubmitInvoiceLanguageClick, isValid);
      break;
    }
    case OrganizationWizardView.SimilarOrganization: {
      if (props.searchingSimilarOrg.loading) {
        return (
          <Processing
            key="Processing"
            {...dialogDimensions}
            message1={t('quote::Searching for similar billing accounts')}
          />
        );
      } else if (props.searchingSimilarOrg.error || !props.similarOrganizationItem) {
        title = '';
        createOrganization();
      } else {
        const { organizationItem } = props.similarOrganizationItem;
        const similarOrganization = organizationItem.organization;
        const createdOrganization = {
          address: addressWithCompanyName,
          accountName: accountName.current,
          tradeName: organization.current.doingBusinessAs,
        };
        const foundOrganization = {
          address: {
            ...similarOrganization.legalEntity.businessLocation.address,
            companyName: similarOrganization.legalEntity.name,
          },
          accountName: props.similarOrganizationItem.accountName,
          tradeName: similarOrganization.legalEntity.tradeName,
        };
        title = SimilarOrgFoundHeaderString();
        body = (
          <SimilarOrgFoundBody
            createdOrganization={createdOrganization}
            foundOrganization={foundOrganization}
          />
        );
        footer = SimilarOrgFoundFooterButton(createOrganization, selectOrganization);
        break;
      }
      break;
    }
    case OrganizationWizardView.Processing: {
      let tryAgain = () => {};
      let loadingMessage: string;
      let successMessage: string | React.ReactElement | undefined;
      let processing: ProcessingInterface;
      if (organizationAction === OrganizationAction.Create) {
        loadingMessage = t(
          'quote::The billing account is being created. Please be patient, this may take 60 seconds.'
        );
        successMessage = (
          <TextBody>
            {t('quote::The billing account ')}
            <span className={props.classes.bold}>{organization.current.name}</span>
            {t('quote:: has been successfully created.')}
          </TextBody>
        );
        processing = props.processingCreate;
        tryAgain = createOrganization;
      } else if (organizationAction === OrganizationAction.Update) {
        loadingMessage = t(
          'quote::The billing account is being updated. Please be patient, this may take 60 seconds.'
        );
        successMessage = t('quote::The billing account has been successfully updated.');
        processing = props.processingEdit;
        tryAgain = onSaveOrganization;
      } else {
        loadingMessage = t(
          'quote::The billing account is being selected. Please be patient, this may take couple of seconds'
        );
        processing = props.processingSelect;
        tryAgain = selectOrganization;
      }

      if (processing.loading) {
        return <Processing {...dialogDimensions} key="Processing" message1={loadingMessage} />;
      } else if (processing.error) {
        return (
          <Fail
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="billingAccountProcessingFail"
            onTryAgainClick={tryAgain}
          />
        );
      } else if (successMessage) {
        return (
          <Success
            message={successMessage}
            {...dialogDimensions}
            closeDialog={openSharedDiscounts}
            dataAutomationId="billingAccountProcessingSuccess"
          />
        );
      } else {
        closeDialog();
        return null;
      }
    }
    case OrganizationWizardView.SearchAccount: {
      const onValid = (result?: TenantProfile | Account) => {
        if (!isValid) {
          setIsValid(true);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isAccount = (result: any): result is Account => !!result.id;

        if (result && isAccount(result)) {
          const accountIndividuals = props.getTenantAccountIndividuals(result.id);
          const accountOrganizations = props.getTenantAccountOrganizations(result.id);
          if (tenantAccountId !== result.id) {
            setTenantAccountId(result.id);
            setTenantAccountName(result.description);
          }
          if (accountOrganizations && accountOrganizations.length) {
            setCurrentStep(OrganizationWizardView.OrganizationsOnAccount);
          } else if (accountIndividuals && accountIndividuals.length) {
            setCurrentStep(OrganizationWizardView.IW);
          }
        } else if (result && result.tenantId !== verifiedTenantId) {
          setVerifiedTenantId(result.tenantId);
        }
      };
      customizedHeadline = t('quote::Check for an existing account');
      title = searchAccountTitle();
      body = (
        <SearchAccountBody
          onInvalid={() => {
            if (isValid) {
              setIsValid(false);
            }
          }}
          onValid={onValid}
        />
      );
      footer = [
        <SecondaryButton
          dataAutomationId="SearchAccountNext"
          disabled={!isValid}
          key={1}
          text={t('Next')}
          onClick={onClickCreateOrganization}
        />,
      ];

      break;
    }
    case OrganizationWizardView.IW: {
      if (!tenantAccountId) {
        loggerService.error({ error: new Error('Tried to go to IW dialog with no account found') });
        return (
          <Fail
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="noAccountFoundFail"
          />
        );
      }
      const accountIndividuals = props.getTenantAccountIndividuals(tenantAccountId) || [];
      customizedHeadline = t('FYI');
      title = accountFoundTitle();
      body = <AccountIndividualsBody individuals={accountIndividuals} />;
      footer = [
        <SecondaryButton
          dataAutomationId="accountIndividualsNextButton"
          key={1}
          text={t('Next')}
          onClick={onClickCreateOrganization}
        />,
      ];
      break;
    }
    case OrganizationWizardView.OrganizationsOnAccount: {
      if (!tenantAccountId) {
        loggerService.error({ error: new Error('Tried to go to IW dialog with no account found') });
        return (
          <Fail
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="noAccountFoundFail"
          />
        );
      }
      const accountOrganizations = props.getTenantAccountOrganizations(tenantAccountId) || [];
      const useExistingOrganization = (organizationId: string) => {
        setOrganizationAction(OrganizationAction.Select);
        setCurrentStep(OrganizationWizardView.Processing);
        props.selectOrganization({
          organizationId: organizationId,
          accountId: tenantAccountId,
        });
      };

      customizedHeadline = t('quote::Use existing billing account');
      title = accountFoundTitle();
      body = (
        <AccountOrganizationsBody
          accountName={tenantAccountName}
          createOrganization={onClickCreateOrganization}
          organizations={accountOrganizations}
          processingAddresses={props.processingOrganizationsAddresses}
          useExistingOrganization={useExistingOrganization}
        />
      );
      footer = undefined;
      break;
    }
    case OrganizationWizardView.Overview:
    default: {
      if (!isOverview) {
        setIsOverview(true);
      }
      if (!isValid) {
        setIsValid(true);
      }
      if (errorType) {
        setErrorType(undefined);
      }
      if (showFooterErrorMessage) {
        shouldShowFooterErrorMessage(false);
      }
      title = t('quote::Overview');
      const { name, doingBusinessAs } = organization.current;
      let orgNameAndDba = (
        <>
          <div data-automation-id="billingAccountNameValue">
            <TextBody addClass={props.classes.textOverflow} title={name}>
              {name}
            </TextBody>
          </div>
          {doingBusinessAs && (
            <div>
              <TextBody
                addClass={props.classes.textOverflow}
                title={t('quote::Doing business as {{doingBusinessAs}}', { doingBusinessAs })}
              >{`dba ${doingBusinessAs}`}</TextBody>
            </div>
          )}
        </>
      );
      let leftColumn: OverviewRowProps[] = [
        {
          ariaLabel: 'MCAPI Account',
          label: t('quote::"MCAPI Account"'), //TODO: jejungk - update name when the name for "MCAPI Account" is determined
          onClick: setCurrentStepFactory(OrganizationWizardView.Account),
          body: tenantAccountName || accountName.current,
          hideIcon: tenantAccountId ? !!tenantAccountName : !!props.accountName, // If tenant account found, the edit option should be based on that account
          dataAutomationId: 'account',
        },
        {
          ariaLabel: 'Billing Account Name',
          label: t('quote::Name'),
          onClick: setCurrentStepFactory(OrganizationWizardView.Organization),
          onRenderBody: () => orgNameAndDba,
          dataAutomationId: 'billingAccountName',
        },
        {
          ariaLabel: 'Affiliate',
          label: t('quote::Affiliate'),
          onClick: !!eligibleParentAccounts.length
            ? setCurrentStepFactory(OrganizationWizardView.Affiliate)
            : () => setAffiliateInfoOpen(!affiliateInfoOpen),
          body: parentAccount
            ? t(`quote::Affiliate of {{parentAccountName}}`, {
                parentAccountName: getParentAccountName(),
              })
            : t('quote::No'),
          iconName: !!eligibleParentAccounts.length ? undefined : 'Info',
          buttonId: !!eligibleParentAccounts.length
            ? 'affiliate-edit-button'
            : 'affiliate-info-button',
          hideIcon: loading,
          dataAutomationId: 'billingAccountAffiliate',
        },
        {
          ariaLabel: 'Address',
          label: t('quote::Address'),
          onClick: setCurrentStepFactory(OrganizationWizardView.Address),
          // eslint-disable-next-line react/display-name
          onRenderBody:
            address.current &&
            (() => (
              <AddressLarge address={address.current} dataAutomationId="organizationAddress" />
            )),
          dataAutomationId: 'billingAccountAddress',
        },
      ];

      const contactInformationBody = () => (
        <BillingContactInformation
          dataAutomationId="addBillingContactLink"
          {...contactInformation.current}
          overflowEmail
          onClick={setCurrentStepFactory(OrganizationWizardView.Contact)}
        />
      );

      const rightColumn: OverviewRowProps[] = [
        {
          ariaLabel: t('quote::Edit contact information'),
          label: t('quote::Contact'),
          maxWidth: 200,
          onClick: setCurrentStepFactory(OrganizationWizardView.Contact),
          onRenderBody: contactInformationBody,
          dataAutomationId: 'contact',
        },
        {
          ariaLabel: t('quote::Edit invoice language'),
          label: t('quote::Invoice language'),
          maxWidth: 200,
          onClick: setCurrentStepFactory(OrganizationWizardView.InvoiceLanguage),
          body: languageInfo[invoiceLanguage.language].display,
          hideIcon: getInvoiceLanguages(invoiceLanguage.market).length < 2 || props.isEditFlow, //TODO: update when becomes able to edit after creation
          dataAutomationId: 'invoiceLanguage',
        },
        {
          ariaLabel: t('quote::Edit terms'),
          label: t('quote::Required terms'),
          onClick: () => {},
          body: parentAccount
            ? t('quote::Customer Affiliate Purchase Terms')
            : t('quote::Microsoft Customer Agreement'),
          hideIcon: true,
          dataAutomationId: 'requiredTerms',
        },
      ];

      body = (
        <OverviewDialog
          enrollmentNumber={props.enrollmentNumber}
          isEditFlow={props.isEditFlow}
          isInfoDefaulted={!props.isEditFlow && !!organizationId}
          isInfoOpen={affiliateInfoOpen}
          organizationsFirstColumnRows={leftColumn}
          organizationsSecondColumnRows={rightColumn}
          onInfoClose={() => setAffiliateInfoOpen(false)}
        />
      );
      footer = props.isEditFlow
        ? [
            <PrimaryButton
              dataAutomationId="saveBillingAccountButton"
              key="saveOrg"
              text={t('quote::Update billing account')}
              onClick={onSaveOrganization}
            />,
          ]
        : [
            <PrimaryButton
              dataAutomationId="createBillingAccountButton"
              key="createOrg"
              text={t('quote::Create billing account')}
              onClick={onSubmitClick}
            />,
          ];
    }
  }
  const footerErrorMessage = showFooterErrorMessage && isOverview && errorType !== undefined && (
    <TextBody addClass={props.classes.footerErrorMessage}>
      {t('quote::{{errorType}}. Changes will not be kept.', {
        errorType:
          errorType === ErrorType.missingInformation
            ? t('quote::Missing information')
            : t('quote::Invalid field'),
      })}
    </TextBody>
  );

  return (
    <Dialog
      {...dialogDimensions}
      bodyAddClass={props.classes.dialogBody}
      closeDialog={closeDialog}
      dataAutomationId="OrganizationWizard"
      footerButtons={footer}
      headline={customizedHeadline || headline}
      hideFooter={!footer}
      title={title}
    >
      <div className={!!footer ? props.classes.mainContent : undefined}>{body}</div>
      {footerErrorMessage}
    </Dialog>
  );
};

export const OrganizationWizardStyled = withStyles(organizationWizardStyles)(
  OrganizationWizardUnstyled
) as React.FC<OrganizationWizardStateProps>;

export const OrganizationWizard = connect(mapStateToProps, dispatchProps)(OrganizationWizardStyled);

export const openOrganizationWizardDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: OrganizationWizardProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <OrganizationWizard {...props} />,
  };
  context.openDialog(dialogProps);
};
