import { IObjectWithKey, Selection } from 'office-ui-fabric-react';
import * as React from 'react';

export const useFabricSelection = (selectionFunction?: (selected: Selection) => void) => {
  const [, setSelectedItem] = React.useState<boolean>(false);
  const selection = React.useRef<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelectedItem(s => !s);
        selection.current.count && selectionFunction && selectionFunction(selection.current);
      },
      canSelectItem: (item: IObjectWithKey) => {
        return (item as { selectable: boolean }).selectable;
      },
    })
  );
  return selection.current;
};

const canSelectItemDefault = (item: IObjectWithKey) => {
  return (item as { selectable: boolean }).selectable;
};
/**
 * Create a custom hook to create the Selection class object needed for Fabrics DetailsList component. (Refactored)
 * TODO: Make changes to update implementation to use reusable version.
 * @param {(selected: Selection) => void} onSelected callback for when the selection changes
 * @param {(item: IObjectWithKey, index?: number | undefined) => boolea} canSelectItem callback for when the selection changes, default to canSelectItemDefault when undefined
 *
 * @returns the selection class instance
 */
export function useFabricSelectionSimple(
  onSelected: (selected: Selection) => void,
  canSelectItem: (
    item: IObjectWithKey,
    index?: number | undefined
  ) => boolean = canSelectItemDefault
) {
  const selection = React.useRef<Selection>(
    new Selection({
      onSelectionChanged: () => {
        onSelected(selection.current);
      },
      canSelectItem,
    })
  );
  return selection.current;
}
