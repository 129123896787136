import { PrimaryButton, SecondaryButton, TextBody, Tile, Tiles } from 'components';
import { Address as AddressComponent } from 'features/components/Address';
import { Address } from 'features/customer/types';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { similarOrgFoundDialogStyles } from './SimilarOrgFound.styles';

export const SimilarOrgFoundHeaderString = () => i18next.t('quote::Similar billing account found');

export interface SimilarOrgFoundBodyProps {
  createdOrganization: {
    address: Address;
    accountName: string;
    tradeName?: string;
  };
  foundOrganization: {
    address: Address;
    accountName: string;
    tradeName?: string;
  };
}

type BodyProps = SimilarOrgFoundBodyProps & WithStyles<typeof similarOrgFoundDialogStyles>;

const tileProps = {
  id: 'Tile-Found',
  maxWidth: '100%',
  tileWidth: '250px',
};

const SimilarOrgFoundBodyUnstyled: React.FC<BodyProps> = ({
  classes,
  createdOrganization,
  foundOrganization,
}) => {
  const { t } = useTranslation();
  const foundContent: React.ReactElement = (
    <div className={classes.tileContent}>
      <AddressComponent
        address={foundOrganization.address}
        addTradeNameClass={classes.tradeName}
        dataAutomationId="foundOrganizationAddress"
        headline={foundOrganization.accountName}
        showAccountName
        showCompanyName
        tradeName={foundOrganization.tradeName}
      />
    </div>
  );
  const createdContent: React.ReactElement = (
    <div className={classes.tileContent}>
      <AddressComponent
        address={createdOrganization.address}
        addTradeNameClass={classes.tradeName}
        dataAutomationId="createdOrganizationAddress"
        headline={createdOrganization.accountName}
        showAccountName
        showCompanyName
        tradeName={createdOrganization.tradeName}
      />
    </div>
  );
  const foundTile: Tile[] = [{ title: '', content: foundContent }];
  const createdTile: Tile[] = [{ title: '', content: createdContent }];
  return (
    <div>
      <TextBody addClass={classes.text}>
        {t(
          'quote::Based on the information entered, we found a billing account that looks similar to the new billing account you are creating. Please verify whether the billing account we found is correct before creating a new billing account.'
        )}
      </TextBody>
      <div className={classes.spacerLarge} />
      <div className={classes.containerFlexTiles}>
        <TextBody addClass={classes.emphasize}>{t('quote::Billing account we found:')}</TextBody>
        <TextBody addClass={classes.tileRightHeadingOffset}>
          {t('quote::Billing account you are creating:')}
        </TextBody>
      </div>
      <div className={classes.spacerMedium} />
      <div className={classes.containerFlexTiles}>
        <Tiles
          {...tileProps}
          addClass={`${classes.tileBackground} ${classes.itemsFlexTiles}`}
          hasBorder
          tile={foundTile}
        />
        <div className={classes.spaceFlex} />
        <div className={classes.separatorFlex} />
        <Tiles {...tileProps} addClass={classes.itemsFlexTiles} hasBorder tile={createdTile} />
      </div>
    </div>
  );
};

export const SimilarOrgFoundFooterButton = (
  onClickCreateNewOrg: () => void,
  onClickUseExistingOrg: () => void
) => {
  return [
    <SecondaryButton
      dataAutomationId="similarOrgFoundCreateNewBillingAccount"
      key="CreateNewOrg"
      text={i18next.t('quote::Create new billing account')}
      onClick={onClickCreateNewOrg}
    />,
    <PrimaryButton
      dataAutomationId="similarOrgFoundUseExistingBillingAccount"
      key="UseExistingOrg"
      text={i18next.t('quote::Use existing billing account')}
      onClick={onClickUseExistingOrg}
    />,
  ];
};

export const SimilarOrgFoundBody = withStyles(similarOrgFoundDialogStyles)(
  SimilarOrgFoundBodyUnstyled
);
