import { getAppEnvironment } from 'features/app/selectors';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';

import { HelpArticle } from './HelpArticle';
import { Article } from './HelpPanel.types';

const styles = (theme: ThemeProps) => ({
  helpArticlePopout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
  },
  article: {
    marginTop: 80,
    maxWidth: 700,
    padding: 24,
    marginBottom: 32,
    background: theme.palette.backgroundStandout,
    boxSizing: 'border-box',
  },
});

const mapState = (state: RootState) => ({
  appEnvironment: getAppEnvironment(state),
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & WithStyles<typeof styles>;

const HelpArticlePopoutUnstyled: React.FC<Props> = props => {
  const { appEnvironment, classes } = props;

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [article, setArticle] = React.useState<Article | undefined>();
  const search = useLocation().search;

  React.useEffect(() => {
    const getArticle = () => {
      const searchParams = new URLSearchParams(search);
      const json = searchParams.get('article');
      return json ? JSON.parse(json) : undefined;
    };

    setArticle(getArticle());
    setIsLoaded(true);
  }, [search]);

  if (!isLoaded) {
    return <></>;
  } else if (!article) {
    return <Redirect to={routes.home.root} />;
  }

  return (
    <div className={classes.helpArticlePopout}>
      <div className={classes.article}>
        <HelpArticle appEnvironment={appEnvironment} article={article} />
      </div>
    </div>
  );
};

export const HelpArticlePopout = connector(withStyles(styles)(HelpArticlePopoutUnstyled));
