import { LargeIcon, LinkButton, TextBody } from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { systemErrorStyles } from './SystemError.styles';

type ActionType = HTMLAnchorElement | HTMLElement | HTMLButtonElement;

/**
 * Props for SystemError
 * @prop {string} iconName - office-ui icon name
 * @prop {string} addClass
 * @prop {string} mainMessage - main error message displayed
 * @prop {string} actionLabel - label for click action
 * @prop {string} onClick - handler for click action
 */

export interface SystemErrorProps {
  iconName?: string;
  addClass?: string;
  mainMessage: string;
  actionLabel?: string;
  onClick?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
  children?: React.ReactNode;
  dataAutomationId?: string;
}

type Props = SystemErrorProps & WithStyles<typeof systemErrorStyles>;

const SystemErrorUnstyled: React.FC<Props> = (props: Props) => {
  const actionLink = !!props.actionLabel ? (
    <div>
      <LinkButton displayText={props.actionLabel} onClick={props.onClick} />
    </div>
  ) : (
    ''
  );

  return (
    <div
      className={mergeClassNames([props.classes.container, props.addClass])}
      data-automation-id={props.dataAutomationId}
    >
      <TextBody>
        <div className={props.classes.column}>
          <div className={props.classes.iconContainer}>
            <LargeIcon
              addClass={props.classes.icon}
              iconName={props.iconName || 'StatusErrorFull'}
            ></LargeIcon>
          </div>
          <div className={props.classes.mainMessage}>
            <div>{props.mainMessage}</div>
            {actionLink}
            <div>{props.children}</div>
          </div>
        </div>
      </TextBody>
    </div>
  );
};

export const SystemError = withStyles(systemErrorStyles)(SystemErrorUnstyled) as React.FC<
  SystemErrorProps
>;
