import { styles } from './AgreementSignatoryConfigurationCard.styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { CalloutCard, Pivot, SectionSeparator, TextBody } from 'components/ions';
import { DirectionalHint } from 'office-ui-fabric-react';
import { ModernAgreementType, AgreementTypes, ModernAgreementParticipant } from 'generated/graphql';
import { useMutation } from '@apollo/react-hooks';
import { CreateOrUpdateModernAgreement } from 'features-apollo/quote/components/Lists/queries';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { AgreementSignatory } from './AgreementSignatory';
import { Signatory } from 'features-apollo/quote/components';
import { AgreementTermLineItem } from 'features-apollo/quote/components/Lists/Rows/AgreementTermRow';
import { getAgreementId } from 'features-apollo/quote/components/utils';
import { oc } from 'ts-optchain';
import {
  getIsSignatoryTypeRequired,
  getSignatoriesByType,
  getSignatoryEmailsByType,
  hasAllEmptySignatoryFields,
  isApplyButtonDisabledForMicrosoft,
  isSignatoryFieldRequired,
} from './AgreementSignatoryConfigurationCard.utils';

export interface AgreementSignatoryConfigurationCardProps {
  agreement: AgreementTermLineItem;
  quoteId: string;
  target: React.RefObject<HTMLSpanElement>;
  readOnly?: boolean;
  onDismiss: () => void;
}

type Props = AgreementSignatoryConfigurationCardProps & WithStyles<typeof styles>;

const AgreementSignatoryConfigurationCardUnstyled: React.FC<Props> = (props: Props) => {
  const { agreement, quoteId, target, readOnly, onDismiss, classes } = props;
  const { t } = useTranslation();

  const filteredParticipants: ModernAgreementParticipant[] | undefined = agreement.participants
    ? (agreement.participants.filter(participant => !!participant) as ModernAgreementParticipant[])
    : undefined;

  const initialSignatories: Signatory[] | undefined = filteredParticipants
    ? filteredParticipants.map((participant: ModernAgreementParticipant) => {
        return {
          id: participant.id,
          type: participant.type,
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          statusDate: participant.statusDate,
        };
      })
    : undefined;

  // #region local states
  const [allSignatories, setAllSignatories] = React.useState<Signatory[] | undefined>(
    initialSignatories
  );
  const [currentSignatoryType, setCurrentSignatoryType] = React.useState<ModernAgreementType>(
    ModernAgreementType.Partner
  );
  const [currentSignatories, setCurrentSignatories] = React.useState<Signatory[]>(
    getSignatoriesByType(currentSignatoryType, initialSignatories)
  );
  const [applyClicked, setApplyClicked] = React.useState<boolean>(false);
  // #endregion

  const [emailMap, setEmailMap] = React.useState<
    Record<ModernAgreementType, (string | undefined | null)[]>
  >({
    [ModernAgreementType.Partner]: getSignatoryEmailsByType(
      ModernAgreementType.Partner,
      initialSignatories
    ),
    [ModernAgreementType.Customer]: getSignatoryEmailsByType(
      ModernAgreementType.Customer,
      initialSignatories
    ),
    [ModernAgreementType.Microsoft]: getSignatoryEmailsByType(
      ModernAgreementType.Microsoft,
      initialSignatories
    ),
  });

  // #region agreement participants mutations
  const [createOrUpdateModernAgreement, { loading, error }] = useMutation(
    CreateOrUpdateModernAgreement,
    {
      refetchQueries: () => [{ query: GET_QUOTE, variables: { id: quoteId } }],
    }
  );
  // #endregion

  React.useEffect(() => {
    if (applyClicked && !loading && !error) {
      onDismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error]);

  React.useEffect(() => {
    const otherSignatories = allSignatories
      ? allSignatories.filter((signatory: Signatory) => signatory.type !== currentSignatoryType)
      : [];
    setAllSignatories(otherSignatories.concat(currentSignatories));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSignatoryType, currentSignatories]);

  const pivotProps = {
    items: [
      {
        id: ModernAgreementType.Partner,
        text: ModernAgreementType.Partner,
        required: getIsSignatoryTypeRequired(ModernAgreementType.Partner, allSignatories),
      },
      {
        id: ModernAgreementType.Customer,
        text: ModernAgreementType.Customer,
        required: getIsSignatoryTypeRequired(ModernAgreementType.Customer, allSignatories),
      },
      {
        id: ModernAgreementType.Microsoft,
        text: ModernAgreementType.Microsoft,
      },
    ],
    onSelectItem: (itemId: string) => {
      const type = itemId as ModernAgreementType;
      setCurrentSignatoryType(type);
      setCurrentSignatories(getSignatoriesByType(type, allSignatories));
      return '';
    },
    defaultItemId: currentSignatoryType,
  };

  const isExpandedView =
    getSignatoriesByType(ModernAgreementType.Partner, initialSignatories).length > 1 ||
    getSignatoriesByType(ModernAgreementType.Customer, initialSignatories).length > 1 ||
    getSignatoriesByType(ModernAgreementType.Microsoft, initialSignatories).length > 1;

  const content = (
    <div className={classes.navigation} style={{ height: isExpandedView ? 376 : 'auto' }}>
      <Pivot {...pivotProps} />
      <SectionSeparator addClass={classes.navigationSeparator} />
      <AgreementSignatory
        emailMap={emailMap}
        isFieldRequired={isSignatoryFieldRequired}
        readOnly={readOnly}
        setEmailMap={setEmailMap}
        signatories={currentSignatories}
        signatoryType={currentSignatoryType}
        updateSignatories={setCurrentSignatories}
      />
    </div>
  );

  const onApply = () => {
    const participants =
      allSignatories &&
      allSignatories
        .map((signatory: Signatory) => {
          return {
            firstName: oc(signatory).firstName(''),
            lastName: oc(signatory).lastName(''),
            email: oc(signatory).email(''),
            statusDate: oc(signatory).statusDate(''),
            type: oc(signatory).type(ModernAgreementType.Partner),
          };
        })
        .filter(
          signatory =>
            signatory.type !== ModernAgreementType.Microsoft ||
            !hasAllEmptySignatoryFields(signatory)
        );
    createOrUpdateModernAgreement({
      variables: {
        agreementId: agreement.id && getAgreementId(agreement.id),
        input: {
          quoteId,
          agreementType: AgreementTypes.Special,
          startDate: agreement.startDate,
          endDate: agreement.endDate,
          participants,
        },
      },
    });
    setApplyClicked(true);
  };

  const applyButtonDisabled =
    readOnly ||
    getIsSignatoryTypeRequired(ModernAgreementType.Partner, allSignatories) ||
    getIsSignatoryTypeRequired(ModernAgreementType.Customer, allSignatories) ||
    isApplyButtonDisabledForMicrosoft(allSignatories);

  const errorMessage = (
    <TextBody addClass={classes.errorMessage}>
      {t('quote::We were unable to add signatory information, please retry or try again later.')}
    </TextBody>
  );

  return (
    <CalloutCard
      applyButtonDisabled={applyButtonDisabled}
      applyButtonStrings={{ text: t('quote::Apply'), ariaLabel: t('quote::Apply') }}
      applyError={!!error}
      applyLoading={loading}
      closeButtonAriaLabel={t('quote::Close')}
      directionalHint={DirectionalHint.rightTopEdge}
      errorMessage={errorMessage}
      headerSubText={t(
        'quote::Please provide signatory information for the Partner, Customer and Microsoft.'
      )}
      headerText={t('quote::Add signatory information')}
      id="agreementConfigurationCard"
      isBeakVisible={true}
      isReadOnly={false}
      maxWidth={548}
      target={target}
      onApply={onApply}
      onDismiss={onDismiss}
    >
      {content}
    </CalloutCard>
  );
};

export const AgreementSignatoryConfigurationCard = withStyles(styles)(
  AgreementSignatoryConfigurationCardUnstyled
);
