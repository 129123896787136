import { TextBodySmall } from 'components/ions';
import { SmallIcon } from 'components/ions/Icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { NeedsTranslation } from 'services/utils';

import { detailsNotificationListStyles } from './DetailsNotificationList.styles';

/**
 * @property {enum} standard Renders the standard notification with "danger color" asterisk icon
 * @property {enum} info Renders an info notification with a "secondary color" info icon
 * @property {enum} warning Renders a warning notification with a "warning color" triangle with exclamation icon
 * @property {enum} error Renders a error notification with a "danger color" red circled x icon
 */
export enum NotificationType {
  standard = 'AsteriskSolid',
  info = 'Info',
  warning = 'Warning',
  error = 'ErrorBadge',
  action = 'Action',
}

/**
 * Use to identify where the notification have originated from
 */
export enum notificationOrigin {
  customer,
  sales,
  info,
}

/**
 * @property {NeedsTranslation} Title header displayed in semi bold
 * @property {string} Id optional id to tag container div with
 * @property {NeedsTranslation} description text body to display under header
 * @property {enum} type to represent the type of notification to render.
 * @property {React.ReactElement}
 * @property {notificationOrigin} origin list of areas where the notification have originated
 */
export interface NotificationItem {
  title: NeedsTranslation | string;
  description: NeedsTranslation | string;
  type: NotificationType;
  action?: React.ReactNode;
  origins?: notificationOrigin[];
  dataAutomationId?: string;
  source?: string;
}

export interface DetailsNotificationsListProps {
  id?: string;
  notifications: NotificationItem[];
  dataAutomationId?: string;
}

type Props = DetailsNotificationsListProps & WithStyles<typeof detailsNotificationListStyles>;

const renderIcon = (
  notificationType: NotificationType,
  classes: Record<
    | 'column'
    | 'semiBold'
    | 'iconColumn'
    | 'listContainer'
    | 'row'
    | 'warning'
    | 'standard'
    | 'info'
    | 'error'
    | 'marginLeft'
    | 'marginBottom',
    string
  >
) => {
  let iconName: string;
  let classToUse: string;
  switch (notificationType) {
    case NotificationType.info: {
      iconName = NotificationType.info;
      classToUse = classes.info;
      break;
    }
    case NotificationType.warning: {
      iconName = NotificationType.warning;
      classToUse = classes.warning;
      break;
    }
    case NotificationType.error: {
      iconName = NotificationType.error;
      classToUse = classes.error;
      break;
    }
    default: {
      iconName = NotificationType.standard;
      classToUse = classes.standard;
      break;
    }
  }

  return (
    <span className={`${classes.semiBold} ${classes.iconColumn}`}>
      <SmallIcon addClass={`${classToUse}`} iconName={iconName} />
    </span>
  );
};

const DetailsNotificationsListUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, id, notifications, dataAutomationId } = props;
  const { t } = useTranslation();

  const renderIfPresent = (action?: React.ReactNode) => {
    if (action) {
      return <span className={classes.row}>{action}</span>;
    }

    return null;
  };

  return (
    <div className={classes.listContainer} data-automation-id={dataAutomationId} id={id}>
      {notifications.map((notification, key) => (
        <div
          className={`${classes.row} ${classes.marginBottom}`}
          data-automation-id={notification.dataAutomationId}
          key={key}
        >
          <TextBodySmall>
            {renderIcon(notification.type, classes)}
            <span className={classes.marginLeft}>
              <span className={classes.row}>
                <span className={`${classes.semiBold}`}>
                  {typeof notification.title === 'string'
                    ? notification.title
                    : t(notification.title.value, notification.title.options)}
                </span>
              </span>
              <span className={classes.row}>
                {typeof notification.description === 'string'
                  ? notification.description
                  : t(notification.description.value, notification.description.options)}
              </span>
              {renderIfPresent(notification.action)}
            </span>
          </TextBodySmall>
        </div>
      ))}
    </div>
  );
};

export const DetailsNotificationsList = withStyles(detailsNotificationListStyles)(
  DetailsNotificationsListUnstyled
) as React.FC<DetailsNotificationsListProps>;
