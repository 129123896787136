import { ThemeProps } from 'styles';

export const monetaryCardStyles = (theme: ThemeProps) => {
  const { fonts } = theme;
  return {
    container: {
      marginTop: 11,
      width: 216,
    },
    startingInformation: {
      marginTop: 24,
    },
    goodForContainer: {
      height: 67,
      marginTop: 16,
      width: 180,
    },
    emphasisText: {
      fontWeight: fonts.fontWeights.semiBold,
    },
    amountContainer: {
      width: 180,
      height: 67,
    },
    errorMessageStyle: {
      width: 216,
    },
    reasonsContainer: {
      height: 67,
      marginTop: 16,
      width: 250,
    },
  };
};
