import { ThemeProps } from 'styles';

export const dropZoneStyles = (theme: ThemeProps) => {
  return {
    iconAdd: {
      color: theme.palette.textTertiary,
      textAlign: 'center',
      opacity: 1,
    },
    iconDown: {
      color: theme.palette.primary,
      textAlign: 'center',
      opacity: 1,
    },
    dragText: {
      paddingTop: '16px',
      textAlign: 'center',
      opacity: 1,
    },
    watermarkText: {
      display: 'block',
      paddingBottom: '12px',
      fontStyle: theme.fonts.fontStyles.italic,
      color: theme.palette.textTertiary,
      lineHeight: '150%',
    },
    watermarkContainer: {
      textAlign: 'center',
      paddingTop: '72px',
      width: '350px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
};

const dropZoneStyle = (height: number, width: number): React.CSSProperties => {
  return {
    backdropFilter: 'blur(1px)',
    display: 'flex',
    flexDirection: 'column',
    opacity: 0.8,
    zIndex: 100,
    position: 'absolute',
    width,
    height,
  };
};

export const getSpacerStyles = (height: number, width: number) => {
  return {
    width,
    height,
    padding: 0,
  };
};

export const hoveringOverDropZoneStyle = (
  theme: ThemeProps,
  height: number,
  width: number
): React.CSSProperties => {
  return {
    ...dropZoneStyle(height, width),
    background: `${theme.palette.lineItemHighlight} 0% 0% no-repeat padding-box`,
  };
};

export const initialDropZoneStyle = (
  theme: ThemeProps,
  height: number,
  width: number
): React.CSSProperties => {
  return {
    ...dropZoneStyle(height, width),
    background: `${theme.palette.shimmerWave} 0% 0% no-repeat padding-box`,
  };
};
