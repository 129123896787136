import axios from 'axios';
import { PageResponse } from 'services/types';
import { createGuid, getAuthHeader, getCV, getTestHeader, TokenAuthorities } from 'services/utils';

import { EdgeConfig, endpoints } from './config';
import { Asset, AssetsByOrganizationRequest } from './types';

async function getHeaders(config: EdgeConfig): Promise<Record<string, string>> {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
    'MS-CorrelationId': createGuid(),
    'MS-CV': getCV(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function getAssetsByOrganization(
  request: AssetsByOrganizationRequest,
  config: EdgeConfig
): Promise<PageResponse<Asset>> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/${request.accountId}/assets?includePartnerAssets=true`;
  const response = await axios.get<PageResponse<Asset>>(url, {
    headers,
  });
  return response.data;
}
