import { ComingSoon, Pivot } from 'components';
import { PivotAtomProps } from 'components/atoms';
import { meplaHistory } from 'createHistory';
import { getFlightIsEnabled } from 'features/app/selectors';
import { ProductType, RecoChannelProducts } from 'features/catalog';
import * as catalogSelectors from 'features/catalog/selectors';
import { SearchResultProduct } from 'features/catalog/types';
import { FavoriteMenuButton } from 'features/components/Favorites';
import * as customerSelectors from 'features/customer/selectors';
import { clearItemSelection } from 'features/proposal/actions';
import * as proposalSelectors from 'features/proposal/selectors';
import { buildProductIdentifier, isAgreementTypeLegacy } from 'features/proposal/utils';
import { EditorLayout, PivotContainer } from 'layouts';
import { ISearchBox } from 'office-ui-fabric-react';
import React from 'react';
import { DragDropContext, DraggableLocation, DragStart, DropResult } from 'react-beautiful-dnd';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from 'routes';
import { Product } from 'services/catalog/types';
import { Flight } from 'services/flights/flightList';
import { endpoints as proposalServiceEndpoints } from 'services/proposal/config';
import { CreateLineItemsRequest, OneAskResult, Proposal } from 'services/proposal/types';
import { ProductGroups } from 'services/user-preferences/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { KeyCodes } from '@uifabric/utilities';

import * as actions from '../actions';
import {
  addLineItemsWithDrag,
  Column,
  ColumnMap,
  Entities,
  ExtendedDragDropProps,
  Finder,
  FinderItem,
  ItemMap,
  multiSelectTo as multiSelect,
} from './Finder';
import { ProductList } from './List/ProductList';
import { TermList } from './List/TermList';

interface EditorProps {
  proposal: Proposal;
  isLegacyLoading: boolean;
  getProductFailed: (id: string) => boolean;
  isFavoriteProductForLegacyDisabled: (product: SearchResultProduct) => boolean;
  getRecoChannels: (productType: ProductType) => RecoChannelProducts[];
  getEcifProduct: () => RecoChannelProducts[];
  isRecoLoading: boolean;
  displayFinderProducts: boolean;
  isProposalReadonly: boolean;
  selectedProject?: string;
  addLineItem: (request: CreateLineItemsRequest) => void;
  updateAddedLineItemCount: (count: number) => void;
  proposalServiceEndpoint: string;
  userFavoriteProductGroups?: ProductGroups;
  searchResults: SearchResultProduct[];
  showECIF: boolean;
  oneAskResults: OneAskResult[];
  isPartnerProposal?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    isLegacyLoading: customerSelectors.legacyLoading(state),
    isRecoLoading: catalogSelectors.recoLoading(state),
    isProposalReadonly: proposalSelectors.isProposalReadOnly(state),
    isPartnerProposal: !!proposalSelectors.isPartnerProposal(state),
    getProductFailed: (id: string) => catalogSelectors.getProductFailed(state, id),
    isFavoriteProductForLegacyDisabled: (product: SearchResultProduct) =>
      catalogSelectors.isFavoriteProductForLegacyDisabled(state, product),
    getRecoChannels: (productType: ProductType) =>
      catalogSelectors.getRecoChannels(state, productType),
    getEcifProduct: () => catalogSelectors.getRecoChannels(state, ProductType.ECIF),
    displayFinderProducts: catalogSelectors.loadAndHydrateAllQuoteWithFavoritesSuccess(state),
    proposalServiceEndpoint: proposalServiceEndpoints[state.app.appConfig.proposal.environment],
    selectedProject: proposalSelectors.getSelectedProject(state),
    userFavoriteProductGroups: state.user.preferences.productGroups,
    searchResults: state.catalog.search.results.products,
    oneAskResults: customerSelectors.getOneAskResponse(state),
    showECIF: getFlightIsEnabled(state, Flight.ECIF),
  };
};

const dispatchProps = {
  clearConfigurationCard: actions.closeConfigCard,
  clearItemSelection: clearItemSelection,
  addLineItem: (request: CreateLineItemsRequest) =>
    actions.createProposalLineItemsAsync.request(request),
  updateAddedLineItemCount: actions.updateAddedLineItemCount.request,
};

type Props = EditorProps & typeof dispatchProps;

const emptyEntities: Entities = {
  columnOrder: [],
  columns: {},
  items: {},
};

const getBodyContent = (id: string, isDragging: boolean) => {
  return (
    <Switch>
      <Route component={ComingSoon} exact path={routes.quote.summary} />
      <Route
        exact
        path={routes.quote.products.root}
        render={() => <ProductList isDragEvent={isDragging} />}
      />
      <Route exact path={routes.quote.terms} render={() => <TermList isDragEvent={isDragging} />} />
      <Route component={ComingSoon} exact path={routes.quote.solution} />
      <Route component={ComingSoon} exact path={routes.quote.projection} />
      <Route component={ComingSoon} exact path={routes.quote.attach} />
      <Route render={() => <Redirect to={routes.quote.forId(id)} />} />
    </Switch>
  );
};

const getEntities = (
  proposal: Proposal,
  isProposalReadonly: boolean,
  recoProducts: RecoChannelProducts[],
  showECIF: boolean,
  ecifProduct: RecoChannelProducts[],
  oneAskResults: OneAskResult[],
  isFavoriteProductDisabled: (product: SearchResultProduct) => boolean,
  userFavoriteProductGroups?: ProductGroups,
  searchResults?: SearchResultProduct[]
) => {
  const items: FinderItem[][] = [];
  const columns: Column[] = [];
  const groupNames: string[] = [];

  recoProducts.forEach((channel: RecoChannelProducts) => {
    const isValidChannel = !!oc(channel).products[0].LocalizedProperties[0].ProductTitle();

    if (isValidChannel) {
      let channelItems: FinderItem[];
      if (showECIF) {
        channelItems = channel.products
          .filter(product => product.ProductId !== '0RDCKN523H1P')
          .map((product: Product) => {
            const isProductAtMaxQuantity =
              !!product.Properties.MaxQuantityOnProposal &&
              proposal.lineItems.filter(
                item => item.productIdentifier.productId === product.ProductId
              ).length >= product.Properties.MaxQuantityOnProposal;
            return {
              id: product.ProductId,
              productIdentifier: buildProductIdentifier(product),
              itemText: product.LocalizedProperties[0].ProductTitle, // TODO: need to get ProductTitle from the LocalizedProperties element with Language === current language
              disabled: isProductAtMaxQuantity || isProposalReadonly,
              maxQuantityOnProposal: product.Properties.MaxQuantityOnProposal,
            };
          });
      } else {
        channelItems = channel.products.map((product: Product) => {
          const isProductAtMaxQuantity =
            !!product.Properties.MaxQuantityOnProposal &&
            proposal.lineItems.filter(
              item => item.productIdentifier.productId === product.ProductId
            ).length >= product.Properties.MaxQuantityOnProposal;
          return {
            id: product.ProductId,
            productIdentifier: buildProductIdentifier(product),
            itemText: product.LocalizedProperties[0].ProductTitle, // TODO: need to get ProductTitle from the LocalizedProperties element with Language === current language
            disabled: isProductAtMaxQuantity || isProposalReadonly,
            maxQuantityOnProposal: product.Properties.MaxQuantityOnProposal,
          };
        });
      }

      items.push(channelItems);
      columns.push({
        id: 'finderList',
        itemIds: channelItems.map((item: FinderItem): string => item.id),
      });
    }
  });

  ecifProduct.forEach((channel: RecoChannelProducts) => {
    const isValidChannel = !!oc(channel).products[0].LocalizedProperties[0].ProductTitle();

    if (isValidChannel) {
      const channelItems: FinderItem[] = channel.products
        .map((product: Product) => {
          return oneAskResults.map(result => {
            const isProductAtMaxQuantity =
              !!product.Properties.MaxQuantityOnProposal &&
              proposal.lineItems.filter(
                item => item.productIdentifier.productId === product.ProductId
              ).length >= product.Properties.MaxQuantityOnProposal;
            return {
              id: result.RequestNumber,
              productIdentifier: buildProductIdentifier(product),
              itemText: product.LocalizedProperties[0].ProductTitle, // TODO: need to get ProductTitle from the LocalizedProperties element with Language === current language
              disabled: isProductAtMaxQuantity,
              maxQuantityOnProposal: product.Properties.MaxQuantityOnProposal,
            };
          });
        })
        .reduce((acc, val) => acc.concat(val), []);

      items.push(channelItems);
      columns.push({
        id: 'ecifList',
        itemIds: channelItems.map((item: FinderItem): string => item.id),
      });
    }
  });
  if (userFavoriteProductGroups) {
    for (let groupName in userFavoriteProductGroups) {
      groupNames.push(groupName);
      items.push(
        userFavoriteProductGroups[groupName].products.map((product: SearchResultProduct) => {
          return {
            id: `${groupName}-${product.productId}`,
            productIdentifier: product.productIdentifier,
            itemText: product.productName,
            favoriteButton: <FavoriteMenuButton groupName={groupName} product={product} />,
            disabled: isFavoriteProductDisabled(product) || isProposalReadonly,
          };
        })
      );
      columns.push({
        id: `favoritesList-${groupName}`,
        itemIds: userFavoriteProductGroups[groupName].products.map(
          (product: SearchResultProduct) => `${groupName}-${product.productId}`
        ),
      });
    }
  }

  if (searchResults) {
    items.push(
      searchResults.map((product: SearchResultProduct) => ({
        id: `SearchResults-${product.productId}`,
        productIdentifier: product.productIdentifier,
        itemText: product.productName,
      }))
    );
    columns.push({
      id: 'searchResultsList',
      itemIds: searchResults.map(
        (product: SearchResultProduct) => `SearchResults-${product.productId}`
      ),
    });
  }

  const lineItemsList: Column = {
    id: 'lineItemsList',
    itemIds: [],
  };

  const flattenedItems: FinderItem[] = [];
  if (columns) {
    const itemMap: ItemMap = flattenedItems
      .concat(...items)
      .reduce((previous: ItemMap, current: FinderItem): ItemMap => {
        if (current) {
          previous[current.id] = current;
        }
        return previous;
      }, {});
    const finderColumnMap: ColumnMap =
      columns &&
      columns.reduce((previous: ColumnMap, currentValue: Column) => {
        previous[currentValue.id] = currentValue;
        return previous;
      }, {});
    const intitialEntities: Entities = {
      columnOrder: [
        'finderList',
        ...groupNames.map((groupName: string) => `favoritesList-${groupName}`),
        'searchResultsList',
        'lineItemsList',
        'ecifList',
      ],
      columns: {
        ...finderColumnMap,
        [lineItemsList.id]: lineItemsList,
      },
      items: itemMap,
    };
    return intitialEntities;
  }
  return emptyEntities;
};

const getTermEntities = (entities: Entities, showECIF: boolean) => {
  const items = entities.items;
  const ecifId = '0RDCKN523H1P';

  const keys = Object.keys(items).filter(key => !key.includes('-'));
  const termsKeys = keys.filter(key => key !== ecifId);
  const ecifKeys = keys.filter(key => key === ecifId);

  const sortedKeys = (showECIF ? termsKeys : keys).sort((prev, curr) =>
    items[prev].itemText.localeCompare(items[curr].itemText)
  );
  const sortedItems: ItemMap = {};
  sortedKeys.forEach(key => {
    sortedItems[key] = items[key];
  });
  const columnOrder = ['finderList', 'lineItemsList'];
  const columns = {
    finderList: {
      id: 'finderList',
      itemIds: sortedKeys,
    },
    lineItemsList: {
      id: 'lineItemsList',
      itemIds: [],
    },
  };
  const sortedEntities = {
    columnOrder: showECIF ? [...columnOrder, 'ecifList'] : columnOrder,
    columns: showECIF
      ? {
          ...columns,
          ecifList: {
            id: 'ecifList',
            itemIds: ecifKeys,
          },
        }
      : columns,
    items: sortedItems,
  };
  return sortedEntities;
};

const EditorUnconnected: React.FC<Props> = props => {
  const {
    isProposalReadonly,
    proposal,
    proposalServiceEndpoint,
    addLineItem,
    selectedProject,
    clearConfigurationCard,
    clearItemSelection,
    getRecoChannels,
    getEcifProduct,
    isRecoLoading,
    displayFinderProducts,
    userFavoriteProductGroups,
    searchResults,
    isPartnerProposal,
    showECIF,
    oneAskResults,
    updateAddedLineItemCount,
    isFavoriteProductForLegacyDisabled,
    getProductFailed,
    isLegacyLoading,
  } = props;

  const termsView = useRouteMatch(routes.quote.terms);
  const isTermsView = termsView ? termsView.isExact : false;

  const productType = isTermsView ? ProductType.Term : ProductType.Product;
  const recoProducts: RecoChannelProducts[] = getRecoChannels(productType);
  const ecifProduct: RecoChannelProducts[] = getEcifProduct();
  const lineItems = proposal.lineItems;

  // states for dnd and multiselect
  const [entities, setEntities] = React.useState<Entities>(emptyEntities);
  const [selectedItemIds, setSelectedItemIds] = React.useState<string[]>([]);
  const [draggingItemId, setDraggingItemId] = React.useState<string | undefined>(undefined);

  // other states
  const [selected, setSelected] = React.useState(isTermsView ? 'terms' : 'products');

  React.useEffect(() => {
    setSelected(isTermsView ? 'terms' : 'products');
  }, [isTermsView]);

  const searchFocusRef = React.useRef<ISearchBox>(null);
  useHotkeys('ctrl+/', () => {
    searchFocusRef.current && searchFocusRef.current.focus();
  });

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.keyCode === KeyCodes.escape) {
      setSelectedItemIds([]);
    }
  };

  const onWindowClick = (event: Event) => {
    if (event.defaultPrevented) {
      return;
    }
    setSelectedItemIds([]);
  };

  const onWindowTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    setSelectedItemIds([]);
  };

  const productsLength = oc(recoProducts)[0].products.length();

  const productLength = oc(ecifProduct)[0].products.length();

  React.useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown, true);
    window.addEventListener('touchend', onWindowTouchEnd);
    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, []);

  const isFavoriteProductFailed = (product: SearchResultProduct) => {
    return isAgreementTypeLegacy(proposal)
      ? isFavoriteProductForLegacyDisabled(product)
      : getProductFailed(product.productId);
  };

  React.useEffect(() => {
    if (displayFinderProducts) {
      const newEntities = getEntities(
        proposal,
        isProposalReadonly,
        recoProducts,
        showECIF,
        ecifProduct,
        oneAskResults,
        isFavoriteProductFailed,
        userFavoriteProductGroups,
        searchResults
      );
      if (isTermsView) {
        setEntities(getTermEntities(newEntities, showECIF));
      } else {
        setEntities(newEntities);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLegacyLoading,
    isRecoLoading,
    displayFinderProducts,
    productsLength,
    showECIF,
    productLength,
    oneAskResults,
    userFavoriteProductGroups,
    searchResults,
    lineItems,
    isProposalReadonly,
  ]);

  // finder list - drag brehavior
  const unselectAll = () => {
    setSelectedItemIds([]);
  };
  const onDragStart = (start: DragStart) => {
    const id: string = start.draggableId;
    const selected: string | undefined = selectedItemIds.find(
      (itemId: string): boolean => itemId === id
    );

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }
    setDraggingItemId(start.draggableId);
  };
  const onDragEnd = (result: DropResult) => {
    const destination: DraggableLocation | undefined = result.destination;
    const source: DraggableLocation = result.source;

    // user has 'let go' of button so no action is taken
    if (!destination || result.reason === 'CANCEL' || destination.droppableId !== 'lineItemsList') {
      setDraggingItemId(undefined);
      return;
    }
    addLineItemsWithDrag(
      {
        entities,
        selectedItemIds,
        source,
        destination,
      },
      {
        isProposalReadonly,
        proposal,
        proposalServiceEndpoint,
        addLineItem,
        selectedProject,
      }
    );
    updateAddedLineItemCount(selectedItemIds.length);
    setDraggingItemId(undefined);
    unselectAll();
  };

  const toggleSelection = (itemId: string) => {
    const copySelectedItemIds: string[] = selectedItemIds;
    const wasSelected: boolean = copySelectedItemIds.includes(itemId);

    const newItemIds: string[] = (() => {
      // Item was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [itemId];
      }

      // Item was part of a selected group
      // will now become the only selected item
      if (copySelectedItemIds.length > 1) {
        return [itemId];
      }

      // Item was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setSelectedItemIds(newItemIds);
  };

  const toggleSelectionInGroup = (itemId: string) => {
    const copySelectedItemIds: string[] = selectedItemIds;
    const index: number = copySelectedItemIds.indexOf(itemId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setSelectedItemIds([...copySelectedItemIds, itemId]);
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow: string[] = [...copySelectedItemIds];
    shallow.splice(index, 1);
    setSelectedItemIds(shallow);
  };

  // This behaviour matches the Windows finder selection
  const multiSelectTo = (newItemId: string) => {
    const updated: string[] | undefined = multiSelect(entities, selectedItemIds, newItemId);

    if (!updated) {
      return;
    }

    setSelectedItemIds(updated);
  };

  const clearTransientUIStates = () => {
    clearItemSelection();
    clearConfigurationCard();
  };
  const pivotSelected = (id: string) => {
    setSelected(id);
    meplaHistory.push(`${routes.quote.forId(proposal.id)}/${id}`);
    clearTransientUIStates();
    return id;
  };
  const { t } = useTranslation();
  const pivProps: PivotAtomProps = {
    items: [{ id: 'products', text: t('quote::Products'), dataAutomationId: 'productsTab' }],
    onSelectItem: pivotSelected,
    defaultItemId: selected,
  };
  if (!isAgreementTypeLegacy(proposal)) {
    pivProps.items.push({ id: 'terms', text: t('quote::Terms'), dataAutomationId: 'termsTab' });
  }

  const finderProducts: ExtendedDragDropProps = {
    column: entities.columns['finderList'],
    entities,
    selectedItemIds,
    draggingItemId,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
    droppableId: 'finderList',
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <EditorLayout
          leftPane={
            !isPartnerProposal ? (
              <React.Fragment>
                <Route
                  exact
                  path={routes.quote.products.root}
                  render={() => (
                    <>
                      <Finder
                        displayFinderList={displayFinderProducts}
                        finderListProps={finderProducts}
                        isDragEvent={!!draggingItemId}
                        preventKeyClick={selectedItemIds.length > 1}
                        productType={ProductType.Product}
                        searchComponentRef={searchFocusRef}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path={routes.quote.terms}
                  render={() => (
                    <>
                      <Finder
                        displayFinderList={displayFinderProducts}
                        finderListProps={finderProducts}
                        isDragEvent={!!draggingItemId}
                        preventKeyClick={selectedItemIds.length > 1}
                        productType={ProductType.Term}
                      />
                    </>
                  )}
                />
              </React.Fragment>
            ) : (
              undefined
            )
          }
          mainContent={
            <PivotContainer
              content={getBodyContent(proposal.id, !!draggingItemId)}
              pivotNav={pivProps.items.length > 1 ? <Pivot {...pivProps} /> : null}
            />
          }
          onContainerAreaClick={() => {
            clearItemSelection();
          }}
        />
      </DragDropContext>
    </React.Fragment>
  );
};

export { EditorUnconnected };

export const Editor = connect(mapStateToProps, dispatchProps)(EditorUnconnected);
