import { ThemeProps } from 'styles';

export const commandBarStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    commandBarStyle: {
      height: 40,
    },
    backgroundStandout: {
      backgroundColor: palette.backgroundStandout,
      height: 40,
    },
  };
};
