import { getFlightIsEnabled } from 'features/app/selectors';
import { loadEnrollment } from 'features/customer/sagas/auxiliary';
import { getSalesAccountFromCrmId } from 'features/customer/selectors';
import { CRMLead, CRMLeadType, SalesAccount } from 'features/customer/types';
import * as proposalActions from 'features/proposal/actions';
import { createCustomPriceScope } from 'features/proposal/components/DiscountCard/DiscountCardBusinessLogic';
import { deleteLineItems } from 'features/proposal/sagas/auxiliary';
import {
  getEnrollmentNumber,
  getProposal,
  isCRMIdMarketValid,
  isExtendedPriceAdjustment,
} from 'features/proposal/selectors';
import {
  getCurrencies,
  getLanguages,
  getMarketByCountryCode,
  getMarketByCountryName,
  Market,
} from 'features/proposal/supported-markets';
import { ModernFootprint } from 'features/proposal/types';
import {
  buildProposalRequest,
  cloneProposal,
  isProposalReadOnly,
  updateStartDate,
} from 'features/proposal/utils';
import { call, put, select } from 'redux-saga/effects';
import { ProductFamily } from 'services/catalog/types';
import { ExternalUser } from 'services/externaluser/types';
import { convertToString } from 'services/externaluser/utils';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { LineItem, Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { loadModernFootprint } from './hydrate';

export function mapMarketToProposal(proposal: Proposal, market: Market) {
  const marketIsChanged = proposal.header.pricingContext.market !== market;
  if (marketIsChanged) {
    proposal.header.pricingContext.market = market;
    proposal.header.pricingContext.billingCurrency = getCurrencies(market)[0];
    proposal.header.pricingContext.languages = getLanguages(market)[0];
  }
}

export function mapModernFootprintToProposal(proposal: Proposal, footprint: ModernFootprint) {
  //spread soldToCustomerLegalEntity to retain the monthlyCreditLimit if it exists
  proposal.header.soldToCustomerLegalEntity = {
    ...proposal.header.soldToCustomerLegalEntity,
    organizationId: footprint.organization.id,
    organizationVersion: footprint.organization.version,
    accountId: footprint.accountId,
  };

  proposal.header.endCustomerLegalEntity = {
    organizationId: footprint.organization.id,
    organizationVersion: footprint.organization.version,
    accountId: footprint.accountId,
  };

  delete proposal.header.invitedUser;
  // default the language to culture on the organization or fallback to first language in the market
  const market = getMarketByCountryCode(footprint.organization.legalEntity.address.country);
  market && mapMarketToProposal(proposal, market);

  proposal.lineItems &&
    proposal.lineItems.forEach(item => {
      if (footprint.projectId) {
        item.assetTo = {
          projectId: footprint.projectId,
          accountId: footprint.accountId,
          organizationId: footprint.organization.id,
        };
      } else {
        delete item.assetTo;
      }
      if (item.pricingInstruction) {
        item.pricingInstruction.customPriceScope = createCustomPriceScope(proposal);
        item.pricingInstruction.organizations = [
          { organizationId: footprint.organization.id, accountId: footprint.accountId },
        ];
      }
    });
}

export function* mapCRMDetailsOnProposal(proposal: Proposal, crmId: string) {
  const lead: CRMLead | null = yield select((state: RootState) => state.customer.CRMLead[crmId]);
  if (!lead || !lead.account) {
    return;
  }
  const salesAccount: SalesAccount | null = yield select(
    (state: RootState) => state.customer.salesAccount[lead.account.Id]
  );
  if (!salesAccount) {
    return;
  }
  if (lead.type === CRMLeadType.Opportunity) {
    proposal.header.opportunityId = crmId;
  } else {
    proposal.header.engagementId = crmId;
  }
  const enrollmentNumberOnQuote = yield select((state: RootState) =>
    getEnrollmentNumber(state, proposal)
  );
  if (!enrollmentNumberOnQuote && lead.enrollmentNumber) {
    proposal.header.extendedProperties = {
      ...proposal.header.extendedProperties,
      vlAgreementNumber: lead.enrollmentNumber,
    };
    const modernOfficeEnabled: boolean = yield select((state: RootState) =>
      getFlightIsEnabled(state, Flight.modernOffice)
    );
    if (modernOfficeEnabled && proposal.lineItems) {
      const enrollment = yield call(loadEnrollment, lead.enrollmentNumber);
      proposal.lineItems.forEach(lineItem => {
        if (lineItem.productIdentifier.productFamily === ProductFamily.Passes) {
          lineItem.purchaseInstruction = {
            ...lineItem.purchaseInstruction,
            termStartDate: updateStartDate(lineItem, enrollment),
          };
        }
      });
    }
  }
  const market = getMarketByCountryName(salesAccount.Address.country);
  market && mapMarketToProposal(proposal, market);
}

export function* updateProposalWithCustomer(
  proposalId: string,
  crmId?: string,
  accountId?: string,
  organizationId?: string,
  leadOrgId?: string,
  user?: ExternalUser
) {
  if (!(crmId || (accountId && organizationId))) {
    return;
  }
  const proposal: Proposal = yield select((state: RootState) => getProposal(state, proposalId));
  const isValidMarket = yield select((state: RootState) => isCRMIdMarketValid(state, crmId));

  if (!proposal || isProposalReadOnly(proposal)) {
    return;
  } else if (crmId && !isValidMarket) {
    const salesAccount = yield select((state: RootState) => getSalesAccountFromCrmId(state, crmId));
    const salesAccountCountry = salesAccount && salesAccount.Address.country;
    const error = new Error(
      `quoteId:${proposal.id}, crmId:${crmId} has "${salesAccountCountry}" which is an invalid market`
    );
    loggerService.error({ error });
    throw error;
  }
  let updatedQuote: Proposal | undefined = undefined;
  const userPreferences = oc(proposal).header.extendedProperties.userPreferences({});
  const leadOrgIdChanged = userPreferences.leadOrgId !== leadOrgId;

  if (leadOrgIdChanged) {
    // delete any shared discounts on quote when changing leadOrgId
    const sharedDiscounts = proposal.lineItems.filter((lineItem: LineItem) =>
      isExtendedPriceAdjustment(lineItem)
    );

    if (sharedDiscounts) {
      updatedQuote = yield call(deleteLineItems, {
        etag: proposal.etag,
        lineItemIds: sharedDiscounts.map((lineItem: LineItem) => lineItem.id),
        proposalId,
      });
    }
  }

  const proposalToUpdate = cloneProposal(updatedQuote ? updatedQuote : proposal);
  if (crmId) {
    yield call(mapCRMDetailsOnProposal, proposalToUpdate, crmId);
  }
  if (accountId && organizationId) {
    const footprint: ModernFootprint | undefined = yield call(
      loadModernFootprint,
      accountId,
      organizationId
    );
    if (!footprint) {
      throw new Error(
        `updateProposalWithCustomer: cannot proceed when footprint is not available. accountId: ${accountId} organizationId:${organizationId}`
      );
    }
    mapModernFootprintToProposal(proposalToUpdate, footprint);
  }

  // update parent billing account on quote if changed
  if (leadOrgIdChanged) {
    proposalToUpdate.header.extendedProperties = {
      ...proposalToUpdate.header.extendedProperties,
      userPreferences: {
        ...oc(proposalToUpdate).header.extendedProperties.userPreferences({}),
        leadOrgId,
      },
    };
  }

  if (user) {
    proposalToUpdate.header.invitedUser = convertToString(user);
  }
  yield put(proposalActions.updateProposalAsync.request(buildProposalRequest(proposalToUpdate)));
}
