import { ApolloError } from 'apollo-client';
import { AxiosError } from 'axios';
import cloneDeep from 'clone-deep';
import { FailReasonBody } from 'features/components/dialogs/FailReasonBody';
import { CRMLeadType } from 'features/customer/types';
import { addSkuTitle } from 'features/proposal/actions';
import {
  CreateLineItemProperties,
  HydratedProduct,
  QualifyingSkuAvailability,
} from 'features/proposal/components/ConfigCard';
import { RequestCreditLine } from 'features/proposal/components/Dialogs/CreditDialog/types';
import { cardConstants } from 'features/proposal/components/DiscountCard/cardConstants';
import { CreateLineItemArgs } from 'features/proposal/components/Finder';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import i18next from 'i18next';
import { ProductFilterContext } from 'microsoft-commerce-product-filters';
import moment from 'moment-timezone';
import React from 'react';
import { Account, PurchaseMotion } from 'services/account/types';
import { Channel, Segment } from 'services/catalog/config';
import { Product, ProductFamily, ProductType, Term } from 'services/catalog/types';
import { safelistedCID } from 'services/credit/config';
import { CreditCheckEventType } from 'services/credit/types';
import { Asset } from 'services/edge/types';
import { EmpowermentsKey, EmpowermentsSuccess } from 'services/empowerment/types';
import { Agreement as Enrollment } from 'services/ldss/types';
import loggerService from 'services/logger-service';
import { CustomPrice, PricingInstruction } from 'services/pricingscope/types';
import { ClaimType, productIds } from 'services/proposal/config';
import {
  CreateLineItemsRequest,
  LineItem,
  PriceAdjustmentType,
  PricingContext,
  PricingInstructionCondition,
  PricingLineItem,
  ProductIdentifier,
  Proposal,
  ProposalPricingRequest,
  ProposalStatus,
  ProposalSummary,
  ProposalUpdateRequest,
  RequestLineItem,
  RequestProposal,
  RequestProposalHeader,
  UpdateAndCreateLineItemsRequest,
} from 'services/proposal/types';
import { oc } from 'ts-optchain';

import {
  buildConditions,
  filterOnDurations,
  filterOnLocations,
  hasMultipleSkus,
  isMonetary,
  isNegotiatedTerm,
  isSAPTerm,
} from './components/ConfigCard/ConfigCardBusinessLogic';
import { InvoiceLanguage } from './components/Dialogs';
import { invoiceLanguageInfo } from './supported-invoice-languages';
import { Language } from './supported-languages';
import { defaultMarket, getMarketByCountryCode, Market, marketInfo } from './supported-markets';
import {
  CardType,
  DuplicateType,
  ExternalReference,
  OpenCard,
  PriceMap,
  Recipient,
  SkuTitleMap,
  Timezones,
} from './types';

//#region REGEX
//html input field email spec as is from: https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)  Copyright © 2018 WHATWG (Apple, Google, Mozilla, Microsoft). https://github.com/whatwg/html/blob/master/LICENSE
export const emailIsValid = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // eslint-disable-line no-useless-escape
export const emailIsValidStrict = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line no-useless-escape
export const quoteNameInvalidCharacters = /[\!\@\#\%\^\[\]\.\/]|<|>|^$/g; // eslint-disable-line no-useless-escape
const leadingTrailingSpaces = /^\s+|\s+$/g;
export const validGuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
export const enrollmentNumberRegExp = /^[0-9]{7,8}$/;
export const crmIDRegExp = /^.{7,}$/;
export const validTermIdRegexpCharacters = /^[\w-]*$/;
// Domain regex from: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch08s15.html, updated with the ^ and $ at the end to avoid partial matches
export const domainNameFormatRegExp = /^\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b$/i;
export const microsoftDomainNameRegExp = /^microsoft\.com$/i;
//#endregion

export const domainTestEnvironmentIncludes = 'testtest';
export const quoteVersionToUpgradeTo = '11';
export const minimumQuoteVersionSupported = '8';
export const statusWhereQuoteCanBeUpgraded = [
  ProposalStatus.Draft,
  ProposalStatus.Submitted,
  ProposalStatus.Active,
  ProposalStatus.Rejected,
];

export const isAgreementTypeLegacy = (proposal: Proposal) =>
  oc(proposal)
    .header.extendedProperties.userPreferences.agreementType('')
    .toLowerCase() === 'legacy';

export const isProposalDraft = (proposal: Proposal): boolean =>
  proposal.header.status === ProposalStatus.Draft;

export const pageSectionCreator = (sections: JSX.Element[], padding: string): JSX.Element[] => {
  const elements: JSX.Element[] = [];

  Object.keys(sections).forEach((value: string, key: number) => {
    elements.push(
      <div className={`.ms-page-flexSection-${key}`} key={value}>
        {sections[key]}
        <div className={padding} />
      </div>
    );
  });
  return elements;
};

export const flexSectionCreator = (
  flexLeft: string,
  flexRight: string | null,
  contentPrimary: JSX.Element,
  styleClass?: string,
  flexExtra?: string,
  contentSecondary?: JSX.Element
) => {
  return (
    <div className={styleClass}>
      <div className={flexLeft}>{contentPrimary}</div>
      {flexRight ? <div className={flexRight} /> : null}
      {flexExtra ? <div className={flexExtra}>{contentSecondary}</div> : null}
    </div>
  );
};

export const buildRequestLineItems = (
  productIdentifiers: ProductIdentifier[],
  lineItems: LineItem[]
) => {
  return productIdentifiers.map((productIdentifier: ProductIdentifier) => {
    const lineItem = lineItems.find(
      (lineItem: LineItem) => lineItem.productIdentifier.productId === productIdentifier.productId
    );
    if (!lineItem) {
      throw new Error(`No Line Items found with the productId ${productIdentifier.productId}`);
    }
    const requestLineItem: PricingLineItem = {
      customerIntent: lineItem.customerIntent,
      catalogClaims: lineItem.catalogClaims,
      quantity: 1,
      productIdentifier,
      pricingInstruction: undefined,
      isReadyForPricing: true,
    };
    return requestLineItem;
  });
};

export const buildLoadPriceRequest = (
  productIdentifiers: ProductIdentifier[],
  lineItems: LineItem[],
  pricingContext: PricingContext
) => {
  const proposalPricingRequest: ProposalPricingRequest = {
    lineItems: buildRequestLineItems(productIdentifiers, lineItems),
    pricingContext,
  };
  return proposalPricingRequest;
};

export const generateProductIdentifierKey = (productIdentifier: ProductIdentifier) => {
  const arr = [
    productIdentifier.productId,
    productIdentifier.skuId,
    productIdentifier.availabilityId,
    productIdentifier.availabilityTermId,
  ].filter((s?: string) => {
    return s !== undefined;
  });

  return arr.join('/');
};

export const createPriceMap = (lineItems: LineItem[]): PriceMap => {
  const priceMap: PriceMap = {};
  lineItems.forEach((lineItem: LineItem) => {
    const key = generateProductIdentifierKey(lineItem.productIdentifier);
    priceMap[key] = { price: lineItem.chargePrice.price, isLoading: false };
  });
  return priceMap;
};

export const generateEmpowermentsKey = (data: EmpowermentsSuccess | EmpowermentsKey): string =>
  [data.market, data.policy, data.empowermentLevel].join('::');

export const validateEmailFormat = (email: string, strict?: boolean) =>
  strict ? emailIsValidStrict.test(email || '') : emailIsValid.test(email || '');
export const isProposalNameInvalid = (name: string) => {
  quoteNameInvalidCharacters.lastIndex = 0;
  return quoteNameInvalidCharacters.test(name);
};

/**
 * Converts a Date to a UTC representation. The date passed in already has the correct UTC date,
 * but when displayed, the date is converted to the locale time zone. To correctly show the date
 * as UTC, this function extracts the UTC values from the date and creates a new date to
 * represent the UTC date, essentially "tricking" Javascript to having a UTC date.
 * @param {Date} date - The date needing UTC representation
 * @returns {Date} - a UTC representation of the date parameter
 */
export const convertDateToUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const lineItemStartDateInPast = (lineItemStartDate: Date) => {
  const startDate = moment.utc(lineItemStartDate);
  return startDate.isBefore(moment.utc(), 'day');
};

export const updateStartDate = (lineItem: LineItem, enrollment?: Enrollment): Date | undefined => {
  const lineItemStartDate = oc(lineItem).purchaseInstruction.termStartDate();
  if (enrollment) {
    return moment
      .utc(enrollment.endDate)
      .add('1', 'minutes')
      .toDate();
  } else if (lineItemStartDate) {
    const startDate = moment.utc(lineItemStartDate);
    if (!startDate.isBefore(moment.utc(), 'day')) {
      return lineItemStartDate;
    }
  }
};

export const createProposalUpdateEnrollmentNumberRequest = (
  enrollmentNumber: string,
  proposal: Proposal,
  enrollment?: Enrollment,
  modernOfficeEnabled?: boolean
): ProposalUpdateRequest => {
  const requestProposalHeader: RequestProposalHeader = {
    ...proposal.header,
    extendedProperties: {
      ...proposal.header.extendedProperties,
      vlAgreementNumber: enrollmentNumber,
    },
  };
  const requestProposalLineItems = proposal.lineItems;

  if (modernOfficeEnabled) {
    requestProposalLineItems.forEach(lineItem => {
      if (lineItem.productIdentifier.productFamily === ProductFamily.Passes) {
        lineItem.purchaseInstruction = {
          ...lineItem.purchaseInstruction,
          termStartDate: updateStartDate(lineItem, enrollment),
        };
      }
    });
  }

  const requestProposal: RequestProposal = {
    header: requestProposalHeader,
    lineItems: requestProposalLineItems,
  };
  const proposalUpdateRequest: ProposalUpdateRequest = {
    etag: proposal.etag,
    proposalId: proposal.id,
    proposal: requestProposal,
  };
  return proposalUpdateRequest;
};

export const createProposalUpdateRequest = (
  newLineItem: LineItem,
  activeProposal: Proposal,
  newHeader?: RequestProposalHeader
): ProposalUpdateRequest => {
  const requestProposalHeader: RequestProposalHeader = newHeader || activeProposal.header;
  const index = activeProposal.lineItems.findIndex(
    (lineItem: LineItem) => lineItem.id === newLineItem.id
  );
  if (index === -1) {
    throw new Error(`LineItemId does not exist, id:  ${newLineItem.id}`);
  }
  const newLineItems = [
    ...activeProposal.lineItems.slice(0, index),
    newLineItem,
    ...activeProposal.lineItems.slice(index + 1),
  ];

  const requestProposal: RequestProposal = {
    header: requestProposalHeader,
    lineItems: newLineItems,
  };
  const proposalUpdateRequest: ProposalUpdateRequest = {
    etag: activeProposal.etag,
    proposalId: activeProposal.id,
    proposal: requestProposal,
  };
  return proposalUpdateRequest;
};

export const createProposalGlobalCloudChannelOrSegmentRequest = (
  activeProposal: Proposal,
  nationalCloud?: string,
  segment?: string,
  channel?: string
): ProposalUpdateRequest => {
  const requestProposalHeader: RequestProposalHeader = {
    ...activeProposal.header,
    extendedProperties: {
      ...activeProposal.header.extendedProperties,
      userPreferences: {
        ...oc(activeProposal).header.extendedProperties.userPreferences(),
        nationalCloud:
          nationalCloud ||
          oc(activeProposal).header.extendedProperties.userPreferences.nationalCloud(),
        segment: segment || oc(activeProposal).header.extendedProperties.userPreferences.segment(),
        channel: channel || oc(activeProposal).header.extendedProperties.userPreferences.channel(),
      },
    },
  };
  const requestProposalLineItems = activeProposal.lineItems;
  const requestProposal: RequestProposal = {
    header: requestProposalHeader,
    lineItems: requestProposalLineItems,
  };
  const proposalUpdateRequest: ProposalUpdateRequest = {
    etag: activeProposal.etag,
    proposalId: activeProposal.id,
    proposal: requestProposal,
  };
  return proposalUpdateRequest;
};

export const getTenantId = (backingIdentity: string) => {
  if (!backingIdentity || backingIdentity.split(':').length < 2) {
    throw new Error('Invalid backingIdentity');
  }

  return backingIdentity.split(':')[1];
};

export function buildProposalRequest(proposal: Proposal) {
  const proposalRequest: ProposalUpdateRequest = {
    etag: proposal.etag,
    proposalId: proposal.id,
    proposal: proposal,
  };
  return proposalRequest;
}

export const getMinimumCredit = (annualDealEstimate: number, minCreditLineDivisor: number) => {
  return Math.ceil(Number(annualDealEstimate) / minCreditLineDivisor).toString();
};

export function buildCreditRequest(
  proposal: Proposal,
  organizationId: string,
  accountId: string,
  minCreditLineDivisor: number,
  annualDealEstimate: number,
  minimumCreditLine?: string,
  safeListedCreditCheck?: boolean
) {
  const defaultMinimumCredit = getMinimumCredit(annualDealEstimate, minCreditLineDivisor);
  const request: RequestCreditLine = {
    quote: proposal,
    creditRequest: {
      legalEntity: {
        organizationId: safeListedCreditCheck ? safelistedCID.organizationId : organizationId,
        accountId: safeListedCreditCheck ? safelistedCID.accountId : accountId,
      },
      currency: proposal.header.pricingContext.billingCurrency || DEFAULT_CURRENCY,
      /* eslint-disable @typescript-eslint/camelcase */
      event_type: CreditCheckEventType.PurchaseCheck,
      quote_id: proposal.id,
      quote_detail: {
        purchase_value: Number(minimumCreditLine) || Number(defaultMinimumCredit),
      },
      /* eslint-enable */
    },
    minimumCreditLine: minimumCreditLine || defaultMinimumCredit.toString(),
    annualDealEstimate: annualDealEstimate.toString(),
  };
  return request;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAxiosError(error: any): error is AxiosError {
  return 'config' in error;
}

export const isOldProposalVersion = (modifiedApiVersion: string) =>
  modifiedApiVersion !== undefined && +modifiedApiVersion < 8;

export const isProposalReadOnly = (proposal: Proposal) =>
  proposal.header.readOnly ||
  proposal.header.status === ProposalStatus.Active ||
  isOldProposalVersion(proposal.header.modifiedApiVersion) ||
  (+proposal.header.modifiedApiVersion < 11 && isAgreementTypeLegacy(proposal));

export const cloneProposal = (proposal: Proposal): Proposal => {
  if (proposal.lineItems && proposal.lineItems.length > 0) {
    return cloneDeep(proposal);
  } else {
    return { ...proposal, header: cloneDeep(proposal.header) };
  }
};

export const getSkuTitleFromLineItem = (
  skuId: string,
  product: Product,
  lineItem: LineItem
): string | undefined => {
  const skuTitleFromUserPreferences = oc(lineItem).extendedProperties.userPreferences.skuTitle(
    undefined
  );
  if (skuTitleFromUserPreferences) {
    return skuTitleFromUserPreferences;
  }
  const sku = product.DisplaySkuAvailabilities.find(
    displaySkuAvailability => displaySkuAvailability.Sku.SkuId === skuId
  );
  if (sku && sku.Sku.LocalizedProperties && sku.Sku.LocalizedProperties.length) {
    return sku.Sku.LocalizedProperties[0].SkuTitle;
  }
};

export const getDuplicateAzurePlan = (
  proposal: Proposal,
  product: Product,
  lineItem: LineItem,
  assets: Asset[],
  isConfigured: boolean,
  organizationId?: string
) => {
  if (
    lineItem.productIdentifier.productId !== productIds.azurePlan ||
    isAgreementTypeLegacy(proposal) ||
    !isConfigured
  ) {
    return;
  }
  const otherAzurePlans = proposal.lineItems.filter(
    item => item.productIdentifier.productId === productIds.azurePlan && item.id !== lineItem.id
  );
  const searchedSku = oc(lineItem).productIdentifier.skuId('');
  let duplicate;
  let duplicateType: DuplicateType = DuplicateType.fromQuote;
  if (searchedSku) {
    duplicate = otherAzurePlans.find(
      azurePlan => azurePlan.productIdentifier.skuId === searchedSku
    );
    duplicateType = DuplicateType.fromQuote;
  }
  if (!duplicate && assets && organizationId) {
    duplicate = assets.find(
      asset =>
        asset.organizationId === organizationId &&
        asset.assetData.productInfo.skuId === searchedSku &&
        asset.assetData.productInfo.productId === productIds.azurePlan
    );
    duplicateType = DuplicateType.fromAssets;
  }
  const skuTitle = getSkuTitleFromLineItem(searchedSku, product, lineItem) || '';
  if (!skuTitle) {
    loggerService.error({
      error: new Error('We have an empty skuTitle in the lineItem, getDuplicateAzurePlan'),
    });
  }
  // We only want to show this error in draft
  let showDuplicateAzurePlanError = !!(duplicate && isProposalDraft(proposal));
  return showDuplicateAzurePlanError ? { type: duplicateType, sku: skuTitle } : undefined;
};

export function getInvitedUserOrgProps(org: string) {
  const invitedUserOrgProps = org.split(':');
  if (invitedUserOrgProps.length < 2) throw new Error('invalid invited user org string');
  return invitedUserOrgProps;
}

export const getCustomerContactEmail = (proposal: Proposal) => {
  if (proposal.header.invitedUser) {
    const invitedUserProps = proposal.header.invitedUser.split(',');
    if (invitedUserProps.length >= 1) {
      const emailProps = invitedUserProps[0].split(':');
      if (emailProps.length < 2) throw new Error('invalid invited user email string');
      return emailProps[1];
    }
  }
};

export const getCustomerContactTenant = (proposal: Proposal) => {
  if (proposal.header.invitedUser) {
    const invitedUserProps = proposal.header.invitedUser.split(',');
    if (invitedUserProps.length > 1) {
      const organizationProps = getInvitedUserOrgProps(invitedUserProps[1]);
      return organizationProps[1];
    }
  }
};

export const buildProductIdentifier = (product: Product): ProductIdentifier => {
  const isSAPProduct = product.ProductType === ProductType.SAP;
  if (
    product.DisplaySkuAvailabilities.length === 1 &&
    product.DisplaySkuAvailabilities[0].Sku &&
    !isSAPProduct
  ) {
    return {
      productId: product.ProductId,
      skuId: product.DisplaySkuAvailabilities[0].Sku.SkuId,
      availabilityId: product.DisplaySkuAvailabilities[0].Availabilities[0].AvailabilityId,
      productType: product.ProductType,
      productFamily: product.ProductFamily,
      action: isNegotiatedTerm(product) ? 'purchase' : undefined,
    };
  } else {
    return {
      productId: product.ProductId,
      productType: product.ProductType,
      productFamily: product.ProductFamily,
    };
  }
};

export const isCardOpen = (id: string, type: CardType, openCard?: OpenCard) => {
  return openCard && openCard.lineItemId === id && openCard.type === type;
};

export const hasLeadOrgId = (proposal: Proposal) => {
  return !!oc(proposal).header.extendedProperties.userPreferences.leadOrgId();
};

export const hasSoldToCustomer = (proposal: Proposal) =>
  !!(
    proposal &&
    proposal.header.soldToCustomerLegalEntity &&
    proposal.header.soldToCustomerLegalEntity.accountId &&
    proposal.header.soldToCustomerLegalEntity.organizationId
  );

export const hasEndCustomer = (proposal: Proposal) =>
  !!(
    proposal &&
    proposal.header.endCustomerLegalEntity &&
    proposal.header.endCustomerLegalEntity.accountId &&
    proposal.header.endCustomerLegalEntity.organizationId
  );

export const areSoldToAndEndCustomerDifferent = (proposal: Proposal) => {
  const soldToCust = oc(proposal).header.soldToCustomerLegalEntity.organizationId('');
  const endCust = oc(proposal).header.endCustomerLegalEntity.organizationId('');
  return soldToCust !== endCust;
};

export const formatCurrency = (
  amount: string | number,
  minimumFractionDigits: number = 2,
  language?: Language
) => {
  if (isNaN(+amount)) {
    return;
  }
  if (typeof amount === 'string') {
    amount = +amount;
  }
  const formattingOptions = { minimumFractionDigits };
  return amount.toLocaleString(language || i18next.language, formattingOptions);
};

// Cleans value variable to only have numbers
export function removeNonNumericalCharacters(value: string): string {
  return value.replace(/[^0-9.]/g, '');
}

export const canRefreshCustomer = (proposal: Proposal) =>
  !hasSoldToCustomer(proposal) &&
  proposal.header.status !== ProposalStatus.Completed &&
  proposal.header.assignedToGroup === 'Field';

export const loadSkuTitlesIfNeeded = (
  productId: string,
  addSkuTitleToMap: (
    qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
    productId: string
  ) => ReturnType<typeof addSkuTitle>,
  hydratedProduct?: Product,
  skuTitleMap?: SkuTitleMap
) => {
  if (hydratedProduct && skuTitleMap && !skuTitleMap[productId]) {
    addSkuTitleToMap(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      new ProductFilterContext(hydratedProduct as any).qualifyingSkuAvailabilities,
      productId
    );
  }
};

export const getCRMLeadType = (quote: Proposal): CRMLeadType | undefined => {
  if (quote.header.opportunityId) {
    return CRMLeadType.Opportunity;
  } else if (quote.header.engagementId) {
    return CRMLeadType.SuccessEngagement;
  }
};

export const getCRMId = (quote: ProposalSummary | Proposal): string | undefined =>
  oc(quote).header.opportunityId() || oc(quote).header.engagementId();

export const hasCRMId = (quote: ProposalSummary | Proposal): boolean => !!getCRMId(quote);

export const isDefaultCustomerName = (name: string) => name === 'Unknown';

export const trimLeadingTrailingSpaces = (text: string) => text.replace(leadingTrailingSpaces, '');

export const computeFirstTimezone = (market: Market) => {
  const matchedMarket = market && marketInfo[market];
  if (!matchedMarket) {
    loggerService.error({
      error: new Error(`Cannot access timezones for unsupported market ${market}`),
    });
  }
  const firstTimezone = (matchedMarket && matchedMarket.timeZones.first) || 'Etc/GMT-14';
  return firstTimezone;
};
export const computeLastTimezone = (market: Market) => {
  const matchedMarket = market && marketInfo[market];
  if (!matchedMarket) {
    loggerService.error({
      error: new Error(`Cannot access timezones for unsupported market ${market}`),
    });
  }
  const lastTimezone = (matchedMarket && matchedMarket.timeZones.last) || 'Etc/GMT+12';
  return lastTimezone;
};
export const computeFirstAndLastTimezone = (
  market: Market,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean
): Timezones => {
  if (shouldDisplayInDayFormatAndUseMarketTimezone) {
    return { first: computeFirstTimezone(market), last: computeLastTimezone(market) };
  }
  return { first: 'Etc/GMT', last: 'Etc/GMT' };
};

export const getInvoiceLanguages = (market: Market) => invoiceLanguageInfo[market].languages;

export const getInvoiceLanguage = (country: string, language: Language): InvoiceLanguage => {
  const market: Market = getMarketByCountryCode(country) || defaultMarket;
  const availableLanguages: Language[] = getInvoiceLanguages(market);
  const selectedLanguage = availableLanguages.includes(language) ? language : availableLanguages[0];
  return { language: selectedLanguage, market: market };
};

//purchase motion if null, should be treated as customer_commerce
export const getCustomerCommerceAccount = (accounts: Account[]): Account | undefined =>
  accounts.find(
    account => !account.purchaseMotion || account.purchaseMotion === PurchaseMotion.CustomerCommerce
  );

export const isQuoteExpiringSoon = (expirationDate: Date) => {
  const now = moment();
  const isDateInTheFuture = moment(expirationDate).diff(now) > 0;
  const expirationDays = moment(expirationDate).diff(now, 'days');

  return isDateInTheFuture && expirationDays < 10;
};

export const getPricingAudienceClaim = (quote?: Proposal) => {
  const channel = oc(quote).header.extendedProperties.userPreferences.channel();
  const segment = oc(quote).header.extendedProperties.userPreferences.segment();
  const channelText = channel === Channel.Indirect ? 'Partner' : Channel.Direct;
  const segmentText = segment === Segment.Government ? 'Gov' : Segment.Commercial;
  return `${channelText}-${segmentText}`;
};

export const createLineItemRequest = (
  proposal: Proposal,
  proposalServiceEndpoint: string,
  productIdentifier: ProductIdentifier,
  selectedProject?: string,
  customerIntent?: string,
  quantity?: number,
  assetId?: string
) => {
  const claims: Partial<Record<ClaimType, string>> = {
    [ClaimType.PricingAudience]: getPricingAudienceClaim(proposal),
  };
  const userPreferences = oc(proposal).header.extendedProperties.userPreferences({});
  //TODO: Log somewhere if we hit into this not existing, means something went wrong
  if (userPreferences.agreementType) {
    claims[ClaimType.AgreementType] = userPreferences.agreementType;
  }
  if (userPreferences.nationalCloud) {
    claims[ClaimType.NationalCloud] = userPreferences.nationalCloud;
  }
  let externalReference = null;
  let recipient = null;
  if (
    isAgreementTypeLegacy(proposal) &&
    userPreferences.recipientId &&
    hasSoldToCustomer(proposal)
  ) {
    externalReference = {
      id: proposal.id,
      url: `${proposalServiceEndpoint}/${proposal.id}`,
    };
    recipient = {
      recipientId: userPreferences.recipientId,
      accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
      organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
    };
  }
  const request = {
    etag: proposal.etag,
    proposalId: proposal.id,
    lineItems: [
      {
        customerIntent: customerIntent || 'New',
        productIdentifier,
        quantity: quantity || 1,
        //todo:can,karina to fix this-set isReadyForPricing only if the item is not configurable and not discountable
        isReadyForPricing:
          productIdentifier.productFamily === ProductFamily.NegotiatedTerms || !!customerIntent,
        assetTo:
          hasSoldToCustomer(proposal) && selectedProject
            ? {
                projectId: selectedProject,
                accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
                organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
              }
            : null,
        catalogClaims: claims,
        externalReference,
        recipient,
        purchaseInstruction: assetId
          ? {
              assetId: assetId,
            }
          : undefined,
      },
    ],
  };
  return request;
};

export const createLineItemsRequest = (
  proposal: Proposal,
  proposalServiceEndpoint: string,
  lineItems: CreateLineItemArgs[]
) => {
  const claims: Partial<Record<ClaimType, string>> = {
    [ClaimType.PricingAudience]: getPricingAudienceClaim(proposal),
  };
  const userPreferences = oc(proposal).header.extendedProperties.userPreferences({});
  //TODO: Log somewhere if we hit into this not existing, means something went wrong
  if (userPreferences.agreementType) {
    claims[ClaimType.AgreementType] = userPreferences.agreementType;
  }
  if (userPreferences.nationalCloud) {
    claims[ClaimType.NationalCloud] = userPreferences.nationalCloud;
  }
  let externalReference: ExternalReference;
  let recipient: Recipient;
  if (
    isAgreementTypeLegacy(proposal) &&
    userPreferences.recipientId &&
    hasSoldToCustomer(proposal)
  ) {
    externalReference = {
      id: proposal.id,
      url: `${proposalServiceEndpoint}/${proposal.id}`,
    };
    recipient = {
      recipientId: userPreferences.recipientId,
      accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
      organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
    };
  }
  const createLineItems: Partial<RequestLineItem>[] = lineItems.map(
    (lineItem: CreateLineItemArgs) => {
      return {
        customerIntent: 'New',
        productIdentifier: lineItem.productIdentifier,
        quantity: 1,
        //todo:can,karina to fix this-set isReadyForPricing only if the item is not configurable and not discountable
        isReadyForPricing:
          lineItem.productIdentifier.productFamily === ProductFamily.NegotiatedTerms,
        assetTo:
          hasSoldToCustomer(proposal) && lineItem.selectedProject
            ? {
                projectId: lineItem.selectedProject,
                accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
                organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
              }
            : null,
        catalogClaims: claims,
        externalReference,
        recipient,
      };
    }
  );
  const request = {
    etag: proposal.etag,
    proposalId: proposal.id,
    lineItems: createLineItems,
  };
  return request;
};

export const createLineItemCopiesRequest = (
  createLineItems: CreateLineItemProperties[],
  updatedLineItem: LineItem,
  hydratedProduct: HydratedProduct
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const productFilterContext = new ProductFilterContext(hydratedProduct as any);
  const request: Partial<RequestLineItem>[] = createLineItems.map(
    (lineItem: CreateLineItemProperties) => {
      filterOnLocations(productFilterContext, lineItem.location);
      lineItem.duration &&
        filterOnDurations(productFilterContext, hydratedProduct, lineItem.duration);
      const conditions = buildConditions(
        productFilterContext.productFilters,
        hydratedProduct,
        productFilterContext.qualifyingSkuAvailabilities,
        lineItem.duration,
        lineItem.location
      );
      const skuCount = hasMultipleSkus(productFilterContext.qualifyingSkuAvailabilities)
        ? productFilterContext.qualifyingSkuAvailabilities.length
        : undefined;
      // cannot just update location
      const userPreferences = lineItem.location
        ? {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...updatedLineItem.extendedProperties!.userPreferences,
            duration: lineItem.duration,
            location: lineItem.location,
            skuCount,
            conditions: conditions.length ? conditions : undefined,
          }
        : {};
      return {
        ...updatedLineItem,
        isReadyForPricing: true,
        id: undefined,
        extendedProperties: {
          ...updatedLineItem.extendedProperties,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          userPreferences: JSON.stringify(userPreferences) as any,
        },
      };
    }
  );
  return request;
};

export const createLineItemCopiesRequestWithCurrentLineItem = (
  createLineItems: CreateLineItemProperties[],
  currentLineItem: LineItem,
  hydratedProduct: HydratedProduct
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const productFilterContext = new ProductFilterContext(hydratedProduct as any);
  const request: Partial<RequestLineItem>[] = createLineItems.map(
    (lineItem: CreateLineItemProperties) => {
      filterOnLocations(productFilterContext, lineItem.location);
      lineItem.duration &&
        filterOnDurations(productFilterContext, hydratedProduct, lineItem.duration);
      const conditions = buildConditions(
        productFilterContext.productFilters,
        hydratedProduct,
        productFilterContext.qualifyingSkuAvailabilities,
        lineItem.duration,
        lineItem.location
      );
      const skuCount = hasMultipleSkus(productFilterContext.qualifyingSkuAvailabilities)
        ? productFilterContext.qualifyingSkuAvailabilities.length
        : undefined;
      // cannot just update location
      const userPreferences = lineItem.location
        ? {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

            duration: lineItem.duration,
            location: lineItem.location,
            skuCount,
            conditions: conditions.length ? conditions : undefined,
          }
        : {};
      return {
        ...currentLineItem,
        isReadyForPricing: true,
        id: undefined,
        extendedProperties: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          userPreferences: JSON.stringify(userPreferences) as any,
        },
      };
    }
  );
  return request;
};

export const updateAndCreateLineItemsRequest = (
  proposal: Proposal,
  createLineItems: CreateLineItemProperties[],
  updatedLineItem: LineItem,
  hydratedProduct: HydratedProduct
) => {
  const lineItemCopies: Partial<RequestLineItem>[] = createLineItemCopiesRequest(
    createLineItems,
    updatedLineItem,
    hydratedProduct
  );

  const request: UpdateAndCreateLineItemsRequest = {
    etag: proposal.etag,
    updatedLineItem,
    lineItems: lineItemCopies,
    proposalId: proposal.id,
  };
  return request;
};

export const createLineItemsRequestOnly = (
  proposal: Proposal,
  createLineItems: CreateLineItemProperties[],
  lineItem: LineItem,
  hydratedProduct: HydratedProduct
) => {
  const lineItemCopies: Partial<RequestLineItem>[] = createLineItemCopiesRequestWithCurrentLineItem(
    createLineItems,
    lineItem,
    hydratedProduct
  );

  const request: CreateLineItemsRequest = {
    etag: proposal.etag,
    lineItems: lineItemCopies,
    proposalId: proposal.id,
  };
  return request;
};

export const createCustomPriceScope = (proposal: Proposal) => {
  const isLegacy = isAgreementTypeLegacy(proposal);
  const accountId = oc(proposal).header.soldToCustomerLegalEntity.accountId('');
  const enrollmentId = oc(proposal).header.extendedProperties.vlAgreementNumber('');
  return (
    !!accountId && {
      type: isLegacy ? 'eaEnrollment' : 'account',
      value: isLegacy ? enrollmentId : accountId,
    }
  );
};

export const buildProductIdentifierFromCustomPrice = (
  product: PricingInstruction
): ProductIdentifier => {
  if (!product.product && product.applicability === 'productFamily') {
    return {
      productId: productIds.azureFamilyDiscount,
      skuId: product.sku,
      productFamily: product.productFamily,
      action: product.action,
    };
  }
  return {
    productId: product.product || '',
    skuId: product.sku,
    productFamily: product.productFamily,
    action: product.action,
  };
};

export const createSharedDiscountLineItemRequest = (
  proposal: Proposal,
  dfd: Product,
  customPrices: CustomPrice[],
  proposalServiceEndpoint: string,
  selectedProject?: string
): CreateLineItemsRequest => {
  const claims: Partial<Record<ClaimType, string>> = {
    [ClaimType.PricingAudience]: getPricingAudienceClaim(proposal),
  };
  const hasSkus = dfd.DisplaySkuAvailabilities.length;
  const hasAvailabilities = hasSkus && dfd.DisplaySkuAvailabilities[0].Availabilities.length;
  const userPreferences = oc(proposal).header.extendedProperties.userPreferences({});
  //TODO: Log somewhere if we hit into this not existing, means something went wrong
  if (userPreferences.agreementType) {
    claims[ClaimType.AgreementType] = userPreferences.agreementType;
  }
  if (userPreferences.nationalCloud) {
    claims[ClaimType.NationalCloud] = userPreferences.nationalCloud;
  }
  let externalReference: { id: string; url: string } | null = null;
  let recipient: { recipientId: string; accountId: string; organizationId: string } | null = null;
  if (
    isAgreementTypeLegacy(proposal) &&
    userPreferences.recipientId &&
    hasSoldToCustomer(proposal)
  ) {
    externalReference = {
      id: proposal.id,
      url: `${proposalServiceEndpoint}/${proposal.id}`,
    };
    recipient = {
      recipientId: userPreferences.recipientId,
      accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
      organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
    };
  }

  const request = {
    etag: proposal.etag,
    proposalId: proposal.id,
    lineItems: customPrices.map(customPrice => {
      const productIdentifier = buildProductIdentifierFromCustomPrice(
        customPrice.pricingInstructions
      );
      return {
        customerIntent: 'New',
        productIdentifier: {
          productId: dfd.ProductId,
          skuId: hasSkus ? dfd.DisplaySkuAvailabilities[0].Sku.SkuId : undefined,
          availabilityId: hasAvailabilities
            ? dfd.DisplaySkuAvailabilities[0].Availabilities[0].AvailabilityId
            : undefined,
          action: cardConstants.purchase,
        },
        quantity: 1,
        //todo:can,karina to fix this-set isReadyForPricing only if the item is not configurable and not discountable
        isReadyForPricing: true,
        assetTo:
          hasSoldToCustomer(proposal) && selectedProject
            ? {
                projectId: selectedProject,
                accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
                organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
              }
            : null,
        catalogClaims: claims,
        externalReference,
        recipient,
        startDate: (customPrice.startDateTime && new Date(customPrice.startDateTime)) || undefined,
        endDate: (customPrice.endDateTime && new Date(customPrice.endDateTime)) || undefined,
        pricingInstruction: {
          ruleType: customPrice.pricingInstructions.ruleType || 'discount',
          applicability: customPrice.pricingInstructions.applicability,
          discountPercentage: customPrice.pricingInstructions.discountPercentage * 100,
          priceGuaranteeDate:
            (customPrice.pricingInstructions.priceGuaranteeDate &&
              new Date(customPrice.pricingInstructions.priceGuaranteeDate)) ||
            undefined,
          pricingPolicy: customPrice.pricingInstructions.pricingPolicy,
          conditions:
            (customPrice.pricingInstructions.conditions &&
              (customPrice.pricingInstructions.conditions as PricingInstructionCondition[])) ||
            undefined,
          productIdentifier: productIdentifier,
          organizations: [
            {
              accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
              organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
            },
          ],
          customPriceReferenceTokens: [customPrice.id],
          priceAdjustmentType: PriceAdjustmentType.extend,
        },
      };
    }),
  };
  return request;
};

export const createSharedDiscountLineItems = (
  sharedDiscounts: CustomPrice[],
  sharedDiscountLineItems: LineItem[],
  dfd: Product,
  proposal: Proposal,
  proposalServiceEndpoint: string,
  addLineItem: (request: CreateLineItemsRequest) => void,
  selectedProject?: string
) => {
  const lineItemsToCreate = sharedDiscounts.filter(
    discount =>
      !sharedDiscountLineItems.some(
        lineItem =>
          lineItem.pricingInstruction &&
          lineItem.pricingInstruction.customPriceReferenceTokens &&
          lineItem.pricingInstruction.customPriceReferenceTokens.includes(discount.id)
      )
  );
  if (lineItemsToCreate) {
    const request = createSharedDiscountLineItemRequest(
      proposal,
      dfd,
      lineItemsToCreate,
      proposalServiceEndpoint,
      selectedProject
    );
    addLineItem(request);
  }
};

export const createLineItem = (
  isProposalReadonly: boolean,
  proposal: Proposal,
  proposalServiceEndpoint: string,
  productIdentifier: ProductIdentifier,
  addLineItem: (request: CreateLineItemsRequest) => void,
  selectedProject?: string
) => {
  if (!isProposalReadonly) {
    const request = createLineItemRequest(
      proposal,
      proposalServiceEndpoint,
      productIdentifier,
      selectedProject
    );
    addLineItem(request);
  }
};

export const createLineItems = (
  isProposalReadonly: boolean,
  proposal: Proposal,
  proposalServiceEndpoint: string,
  lineItems: CreateLineItemArgs[],
  addLineItem: (request: CreateLineItemsRequest) => void
) => {
  if (!isProposalReadonly) {
    const request = createLineItemsRequest(proposal, proposalServiceEndpoint, lineItems);
    addLineItem(request);
  }
};

export const hasLineItemsThatAreNotDiscounted = (lineItems: LineItem[]) =>
  lineItems.some(
    lineItem => lineItem.productIdentifier.productId !== productIds.discountFulfillmentDocument
  );

export const isConfiguredV8ToV11Quote = (lineItem: LineItem, isConfigured: boolean) => {
  if (!isConfigured) {
    return false;
  }
  if (lineItem.extendedProperties && lineItem.extendedProperties.userPreferences) {
    const notEmpty = !!Object.keys(lineItem.extendedProperties.userPreferences).length;
    if (notEmpty) {
      return false;
    }
  }
  return true;
};

export const getTermWithId = (termId: string, product: Product) => {
  let terms: Term[] = [];
  const availabilitiesList = product.DisplaySkuAvailabilities.map(
    displaySkuAvailability => displaySkuAvailability.Availabilities
  );
  availabilitiesList.forEach(availabilities => {
    availabilities.forEach(availability => {
      if (availability.Terms) {
        availability.Terms.forEach(term => {
          terms.push(term);
        });
      }
    });
  });
  return terms.find((term: Term) => term.TermId === termId);
};

export const getConditionValueFromLineItem = (lineItem: LineItem, name: string) => {
  if (lineItem.pricingInstruction && lineItem.pricingInstruction.conditions) {
    const matchedCondition = lineItem.pricingInstruction.conditions.find(
      condition =>
        oc(condition)
          .name('')
          .toLowerCase() === name.toLowerCase()
    );
    return matchedCondition && matchedCondition.value.toString();
  }
};

export const getLocation = (lineItem: LineItem, isConfigured: boolean) => {
  if (!isConfigured) {
    return;
  }
  const location = oc(lineItem).extendedProperties.userPreferences.location('');
  if (location) {
    return location;
  }
  if (isConfiguredV8ToV11Quote(lineItem, isConfigured)) {
    return getConditionValueFromLineItem(lineItem, 'location') || 'All Locations';
  }
};

export const computeDuration = (duration: string, years: string, months: string) => {
  const durationValue = moment.duration(duration).asMonths();
  return durationValue % 12 === 0 ? `${durationValue / 12} ${years}` : `${durationValue} ${months}`;
};

export const getDuration = (lineItem: LineItem, isConfigured: boolean, action?: string) => {
  if (!isConfigured) {
    return;
  }
  const duration = oc(lineItem).extendedProperties.userPreferences.duration('');
  if (duration) {
    return duration;
  }
  const durationValue = getConditionValueFromLineItem(lineItem, 'termUnits');
  const durationValueToDisplay = durationValue && moment.duration(durationValue).humanize();

  if (durationValueToDisplay) {
    return durationValueToDisplay;
  }

  if (!action) {
    return;
  }
  return action.toLowerCase() === 'purchase' ? 'All Reservations' : 'Pay go';
};

export const getMonetaryCreditAmount = (lineItem: LineItem, currency: string) => {
  const amount = oc(lineItem).purchaseInstruction.purchaseTermUnits();
  if (amount && currency) {
    return `${formatCurrency(amount, 0)} ${currency}`;
  }
};

export const getMonetaryCreditDuration = (
  lineItem: LineItem,
  years: string = 'year(s)',
  months: string = 'month(s)'
) => {
  const duration = oc(lineItem).duration();
  return duration && computeDuration(duration, years, months);
};

export const negotiatedTermsNeedsConfiguration = (
  lineItem: LineItem,
  product?: Product
): boolean => {
  if (!product) {
    return false;
  }
  const missingAction = !lineItem.productIdentifier.action;
  const missingSupplementalReferenceData = !lineItem.supplementalTermReferenceData;
  const hasTermId = !!product.Properties.TermId;
  const productType = oc(lineItem).productIdentifier.productType('');
  const isFinancingTermThatNeedsConfiguration =
    productType === 'Financing' && !oc(lineItem).groups([]).length;
  const isMonetaryProductThatNeedsConfiguration =
    isMonetary(product) && !oc(lineItem).purchaseInstruction.purchaseTermUnits('');
  const isSapTermThatNeedsConfiguration =
    isSAPTerm(product) && !oc(lineItem).purchaseInstruction.purchaseTermUnits('');
  return (
    missingAction ||
    (hasTermId && missingSupplementalReferenceData) ||
    isFinancingTermThatNeedsConfiguration ||
    isMonetaryProductThatNeedsConfiguration ||
    isSapTermThatNeedsConfiguration
  );
};

/**
 * Decides if the dates for that product and lineItem combination should be displayed in days,
 * Related to downstream dependencies(rating service) where some combinations have to be displayed in months
 */
export const displayInDayFormatAndUseMarketTimezone = (
  productType: string,
  lineItemAction?: string
) => {
  const productTypeUpperCase = productType.toUpperCase();
  const showMonthFormatUseUTC =
    productTypeUpperCase === 'SAP' || productTypeUpperCase === 'AZUREFAMILYDISCOUNT';
  if (showMonthFormatUseUTC) {
    return false;
  }
  return !!(
    productTypeUpperCase === 'ENTITLEMENT' ||
    productTypeUpperCase === 'AZURESUPPORT' ||
    (lineItemAction && lineItemAction.toUpperCase() === cardConstants.purchase.toUpperCase())
  );
};

export const createAssetLineItem = (
  proposal: Proposal,
  proposalServiceEndpoint: string,
  selectedAsset: Asset,
  quantity: number,
  addLineItem: (request: CreateLineItemsRequest) => void,
  selectedProject?: string
) => {
  const customerIntent = 'addQuantity';
  const productIdentifier: ProductIdentifier = {
    productId: selectedAsset.assetData.productInfo.productId,
    skuId: selectedAsset.assetData.productInfo.skuId,
    availabilityId: selectedAsset.assetData.productInfo.availabilityId,
    productFamily: selectedAsset.assetData.productInfo.productFamily,
    action: 'purchase',
  };
  const request = createLineItemRequest(
    proposal,
    proposalServiceEndpoint,
    productIdentifier,
    selectedProject,
    customerIntent,
    quantity,
    selectedAsset.id
  );
  addLineItem(request);
};

export enum gqlTermIdErrorCodes {
  InvalidTermIdFormat = '4049', // termId provided is not a guid
  TermIdDoesNotExist = '4508', // termId provided is a guid, but does not exist in agreements
  InvalidTermId = '4516', // termId provided is a guid, and exists in agreements, but not for this agreement type (CAPT vs MCA)
}

export const getGqlTermIdError = (error: ApolloError) => {
  const errorCode = oc(error).graphQLErrors[0].extensions.code('');
  const termErrorCode = oc(error).graphQLErrors[0].extensions.exception.errorData.code(
    ''
  ) as gqlTermIdErrorCodes;
  if (errorCode.toLowerCase() === 'bad request' && termErrorCode) {
    const termErrorMessage = oc(error).graphQLErrors[0].extensions.exception.errorData.message('');
    return {
      code: termErrorCode,
      message: termErrorMessage,
    };
  }
  return {};
};

// Returns the line item(s) that have a termId that matches the termId from the error
export const getInvalidTerms = (customTerms: LineItem[], termId: string) => {
  const invalidTerms = customTerms.filter(
    lineItem => lineItem.supplementalTermReferenceData === `TermId:${termId}`
  );
  const invalidTermIds = invalidTerms.map(invalidTerm => invalidTerm.productIdentifier.productId);
  return invalidTermIds;
};

// Returns all custom term line items on the quote
export const getCustomTerms = (lineItems: LineItem[]) => {
  const customTerms = lineItems.filter(
    lineItem =>
      lineItem.productIdentifier.productType &&
      ['custom', 'financing', 'ecif'].includes(lineItem.productIdentifier.productType.toLowerCase())
  );
  return customTerms;
};

export const getAgreementsErrorTerms = (
  errorCode: gqlTermIdErrorCodes,
  message: string,
  lineItems: LineItem[]
) => {
  const customTerms = getCustomTerms(lineItems);
  let invalidTerms: string[] = [];

  // There is almost never more than 1 custom term on a quote, so we can just return the single custom term on the quote
  if (customTerms.length === 1) {
    invalidTerms.push(customTerms[0].productIdentifier.productId);
    return invalidTerms;
  }

  switch (errorCode) {
    case gqlTermIdErrorCodes.InvalidTermId: {
      // the validGuid regExpression from up top with the ^ and $ does not work here as that checks if the entire string is a guid, not if the string includes a guid
      const guidReg = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/;
      const regTermArray = guidReg.exec(message);
      if (regTermArray) {
        const termId = regTermArray[0];
        invalidTerms = getInvalidTerms(customTerms, termId);
      } else {
        invalidTerms = customTerms.map(customTerm => customTerm.productIdentifier.productId);
      }
      break;
    }
    case gqlTermIdErrorCodes.TermIdDoesNotExist: {
      // cannot use getInvalidTerms for this error as agreements does not return the incorrect termId for this error
      // so if there is more than 1 custom term, we cannot identify which one in this case
      invalidTerms = customTerms.map(customTerm => customTerm.productIdentifier.productId);
      break;
    }
    case gqlTermIdErrorCodes.InvalidTermIdFormat: {
      // invalid termId for this error is placed inside single quotes
      const firstQuote = message.indexOf(`'`);
      const secondQuote = message.indexOf(`'`, firstQuote + 1);
      const termId = message.slice(firstQuote + 1, secondQuote);
      if (termId) {
        invalidTerms = getInvalidTerms(customTerms, termId);
      } else {
        invalidTerms = customTerms.map(customTerm => customTerm.productIdentifier.productId);
      }
      break;
    }
  }

  // This will almost always be an array of 1, but there is a very small chance that it is longer if there are multiple custom terms on the quote
  return invalidTerms;
};

export const getInvalidTermsErrorMessage = (
  title: string,
  invalidTermNames: string[],
  t: i18next.TFunction
) => {
  let failReasons: string[] = [];
  if (invalidTermNames.length === 1) {
    failReasons.push(
      t(`error::The TermID added to the negotiated term "{{invalidTerm}}" is invalid.`, {
        invalidTerm: invalidTermNames[0],
      })
    );
  } else if (invalidTermNames.length === 2) {
    failReasons.push(
      t(
        `error::The TermID added for either the negotiated term "{{invalidTerm1}}" or the negotiated term "{{invalidTerm2}}" is invalid.`,
        {
          invalidTerm1: invalidTermNames[0],
          invalidTerm2: invalidTermNames[1],
        }
      )
    );
  } else {
    failReasons.push(t(`error::The TermID added for one of the negotiated terms is invalid.`));
  }
  return <FailReasonBody failedActionTitle={title} failReasons={failReasons} />;
};
