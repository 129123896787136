import { ThemeProps } from 'styles';

export const toggleButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    toggle: {
      minWidth: 32,
      width: 32,
      backgroundColor: 'transparent',
      borderRadius: 0,
      padding: '7px 8px',
      '& i': {
        color: colors.textSecondary,
        padding: 0,
        margin: 0,
      },
      '&:hover': {
        backgroundColor: colors.secondaryActive,
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:active': {
        backgroundColor: colors.textSecondary,
        '& i': {
          color: colors.backgroundMain,
        },
      },
      '&:active:hover': {
        backgroundColor: colors.textSecondary,
        '& i': {
          color: colors.backgroundMain,
        },
      },
      '&:disabled i': {
        color: colors.textDisabled,
      },
    },
    toggleOn: {
      minWidth: 32,
      width: 32,
      padding: '7px 8px',
      backgroundColor: colors.textSecondary,
      borderRadius: 0,
      '& i': {
        color: colors.backgroundMain,
        padding: 0,
        margin: 0,
      },
      '&:hover': {
        backgroundColor: colors.secondaryActive,
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:active': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:active:hover': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:disabled': {
        backgroundColor: colors.backgroundDivider,
        '& i': {
          color: colors.textDisabled,
        },
      },
    },
  };
};
