import { GuidanceBox, PrimaryButton, TextBody } from 'components';
import {
  openTenantWizardDialog,
  TenantScenario,
  TenantWizardView,
} from 'features-apollo/quote/components/Wizards';
import { AccountTenant, InvitedUserSimple, QuoteMutationInput } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { GuidanceBoxHeader } from '../../shared';
import { organizationInformationStyles } from '../OrganizationInformation.styles';

interface TenantRequiredProps {
  title: string;
  /**
   * Required to update the quote object.
   */
  quoteMutationInput: QuoteMutationInput;
  /**
   * Defines the tenant flow scenario specific for partner or partner-customer
   */
  specificScenario?: TenantScenario;
  /**
   * Identifies the account of the tenant
   */
  organization: { id: string; accountId: string };
  /**
   * Use for default tenant if there is none found in the quote.
   */
  accountTenants: AccountTenant[];
  invitedUser?: InvitedUserSimple | null;
}

type Props = TenantRequiredProps & WithStyles<typeof organizationInformationStyles>;

const TenantRequiredUnStyled: React.FC<Props> = props => {
  const {
    classes,
    quoteMutationInput,
    specificScenario,
    organization,
    accountTenants,
    invitedUser,
  } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  return (
    <GuidanceBox>
      <GuidanceBoxHeader addClass={classes.header} title={props.title}>
        <TextBody addClass={classes.paragraph}>
          {t(
            `quote::Before you can publish this quote, you need to add the tenant and AAD work account of the person who's authorized to transact the quote.`
          )}
        </TextBody>
      </GuidanceBoxHeader>
      <PrimaryButton
        dataAutomationId="addIdentityButton"
        text={t('quote::Add identity')}
        onClick={() => {
          openTenantWizardDialog(context, {
            initialView: TenantWizardView.Tenant,
            quoteMutationInput,
            specificScenario,
            organization,
            accountTenants,
            invitedUser,
          });
        }}
      />
    </GuidanceBox>
  );
};

export const TenantRequired = withStyles(organizationInformationStyles)(TenantRequiredUnStyled);
