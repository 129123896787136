import { Channel } from '../reco/types';

const termsChannel: Channel = {
  name: 'ECIF',
  Title: 'ECIF',
  Items: [
    {
      Id: '0RDCKN523H1P',
    },
  ],
};

export const loadChannel = () => {
  return termsChannel;
};
