import { Dialog, SecondaryButton, TextBody } from 'components';
import { ErrorType } from 'features-apollo/quote/components/Wizards/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const dialogDimensions = {
  height: 627,
  width: 600,
};

const styles = (theme: ThemeProps) => ({
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footerErrorMessage: {
    color: theme.palette.dangerText,
    height: 20,
    textAlign: 'right',
  },
  mainContent: {
    height: 465,
    overflowY: 'auto',
  },
});

export interface OrgWizardSharedProps<T> {
  headline: string;
  initialData?: T | null;
  isOverviewAvailable: boolean;
  onClose: () => void;
  onOverviewClick: (input?: T | null) => void;
}

export interface WizardDialogProps {
  errorType?: ErrorType;
  headline: string;
  isOverviewAvailable: boolean;
  title: string;
  body: React.ReactNode;
  footer?: React.ReactNode;
  overviewButtonOnly: boolean;
  showFooterErrorMessage?: boolean;
  closeDialog: () => void;
  onOverviewClick?: (input?: any) => void;
}

type Props = WizardDialogProps & WithStyles<typeof styles>;

const WizardDialogUnstyled: React.FC<Props> = props => {
  const {
    classes,
    errorType,
    headline,
    isOverviewAvailable,
    title,
    body,
    footer,
    closeDialog,
  } = props;
  const { t } = useTranslation();
  const [showFooterError, setShowFooterError] = React.useState<boolean>(false);

  const footerErrorMessage = showFooterError && isOverviewAvailable && errorType !== undefined && (
    <TextBody addClass={classes.footerErrorMessage}>
      {t('quote::{{errorType}}. Changes will not be kept.', {
        errorType:
          errorType === ErrorType.missingInformation
            ? t('quote::Missing information')
            : t('quote::Invalid field'),
      })}
    </TextBody>
  );

  const backToOverview = (onClick?: () => void) => [
    <SecondaryButton
      dataAutomationId="backToOverviewButton"
      key="back-to-overview"
      text={t('quote::Back to overview')}
      onBlur={() => setShowFooterError(false)}
      onClick={onClick}
      onFocus={() => setShowFooterError(true)}
      onMouseEnter={() => setShowFooterError(true)}
      onMouseLeave={event => {
        if (event.target !== document.activeElement) setShowFooterError(false);
      }}
    />,
  ];

  return (
    <Dialog
      {...dialogDimensions}
      bodyAddClass={classes.dialogBody}
      closeDialog={closeDialog}
      dataAutomationId="OrganizationWizard"
      footerButtons={props.overviewButtonOnly ? backToOverview(props.onOverviewClick) : footer}
      headline={headline}
      hideFooter={!footer && !props.overviewButtonOnly}
      title={title}
    >
      <div className={!!footer ? classes.mainContent : undefined}>{body}</div>
      {footerErrorMessage}
    </Dialog>
  );
};

export const WizardDialog = withStyles(styles)(WizardDialogUnstyled);
