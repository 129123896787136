import cloneDeep from 'clone-deep';
import {
  CalloutCard,
  LinkButton,
  SectionSeparator,
  ShimmerForBackgroundCommon,
  TextBody,
} from 'components/ions';
import { DetailsList } from 'components/ions/DetailsLists';
import { Dropdown } from 'components/ions/Dropdown';
import { applyConfigurationCardWithDialog } from 'features-apollo/quote/components/ConfigCards/utils';
import { filteredSkusRawToFilteredSkus } from 'features-apollo/quote/utils';
import { filterAndCastMaybeArray, removeTypeName } from 'features-apollo/utils';
import { formatCurrency } from 'features/proposal/utils';
import {
  ApplyConfigurationMultipleSkusInput,
  ApplyConfigurationSingleSkuInput,
  Availability,
  CatalogAction,
  Condition,
  ConditionInput,
  Currency,
  FilteredSkus,
  KeyNullableValues,
  KeyValueInput,
  MutationApplyConfigurationMultipleSkusArgs,
  MutationApplyConfigurationSingleSkuArgs,
  Price,
  Product,
  QuoteMutationInput,
  Sku,
  Term,
} from 'generated/graphql';
import { useFabricSelectionSimple } from 'hooks/useFabricSelection/useFabricSelection';
import i18next from 'i18next';
import {
  CheckboxVisibility,
  ColumnActionsMode,
  DirectionalHint,
  DropdownMenuItemType,
  IColumn,
  IComboBoxOption,
  IDropdownOption,
  IRenderFunction,
  Selection,
  SelectionMode,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { getUnique } from 'services/utils';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { AddAsNewLineItemButton } from '../AddAsNewLineItemButton';
import { calloutCardDimensions } from '../common';
import { ApplyConfigurationSingleSku } from '../queries';
import {
  ConfigurationCardCommonProps,
  DurationOptions,
  FilterNames,
  LocationOptions,
  SkuConfigurationCardCommonProps,
  View,
} from '../types';
import { flatSkuInfoList, getMaxTermCountAcrossSkus } from '../utils';
import { styles } from './MetersConfigurationCard.styles';
import { ApplyConfigurationMultipleSkus, GetMeterProduct } from './queries';

const getAllTermsFromTheSku = (sku: Sku) =>
  sku.availabilities.reduce<Term[]>(
    (terms, availability) => (availability.terms ? terms.concat(availability.terms) : terms),
    []
  );

const generatelocationDropdownOptions = (
  locationFilters: string[],
  t: i18next.TFunction,
  allowUnspecifiedOption?: boolean
) => {
  let dropdownOptions = locationFilters.map(locationFilter => {
    const dropdownOption: IDropdownOption = {
      key: locationFilter,
      text: locationFilter ? locationFilter : t('quote::Non-regional SKUs'),
      ariaLabel: locationFilter ? locationFilter : t('quote::Non-regional SKUs'),
    };
    return dropdownOption;
  });

  dropdownOptions = [
    {
      key: LocationOptions.AllLocations,
      text: t('quote::All Locations'),
      ariaLabel: t('quote::All Locations'),
    },
    ...dropdownOptions,
  ];

  const [allLocationsOption, ...rest] = dropdownOptions;
  dropdownOptions =
    dropdownOptions.length > 1
      ? [
          allLocationsOption,
          { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider },
          {
            key: 'select-all',
            text: t('quote::select all'),
            itemType: DropdownMenuItemType.Header,
            ariaLabel: t('quote::select all'),
          },
          ...rest,
        ]
      : //FIXME: have a test for the length 1 case,
        dropdownOptions;
  if (dropdownOptions.length > 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [allLocationsOption, divider, selectAll, ...rest] = dropdownOptions;
    const withNoRegionOptionRemoved = rest.filter(option => !!option.key);
    const sortedRest = withNoRegionOptionRemoved.sort((a, b) => a.text.localeCompare(b.text));
    if (allowUnspecifiedOption) {
      dropdownOptions = [
        allLocationsOption,
        divider,
        selectAll,
        { key: '', text: t('quote::Non-regional SKUs'), ariaLabel: t('quote::Non-regional SKUs') },
        ...sortedRest,
      ];
    } else {
      dropdownOptions = [allLocationsOption, divider, selectAll, ...sortedRest];
    }
  }
  return dropdownOptions;
};

/**
 * We want to ignore the durations that are associated with the Consume action
 */
export const getDurationFiltersThatAreNotAssociatedWithConsume = (product: Product) => {
  let durationFilters: string[] = [];
  let alreadyProcessed = new Set<string>();
  const filteredSkus = filteredSkusRawToFilteredSkus(product.filteredSkusRaw);
  filteredSkus.forEach(filteredSku => {
    const action = filteredSku.filters.find(filter => filter.key === FilterNames.Action);
    const notConsume =
      oc(action)
        .value('')
        .toLowerCase() !== CatalogAction.Consume.toLowerCase();
    if (notConsume) {
      const termDuration = filteredSku.filters.find(filter => filter.key === FilterNames.Duration);
      if (termDuration && termDuration.value) {
        !alreadyProcessed.has(termDuration.value) &&
          durationFilters.push(termDuration.value) &&
          alreadyProcessed.add(termDuration.value);
      }
    }
  });
  return durationFilters;
};

const readLocationsWithMultipleSkus = (conditions: Condition[]) => {
  let locations = conditions.find(
    condition => condition.name === FilterNames.Location && condition.type === 'equalAny'
  );
  if (locations && locations.values && locations.values.length) {
    return locations.values;
  }
  return [LocationOptions.AllLocations];
};

const readLocationsWithSingleSku = (filters: KeyNullableValues[]) => {
  if (!filters.length) {
    return;
  }
  const locationFilter = filters.find(filter => filter.key === FilterNames.Location);
  const locationValues = filterAndCastMaybeArray(oc(locationFilter).values([]));
  if (locationValues && locationValues.length) {
    return [locationValues[0]];
  }
  return;
};

export const readDurationsWithMultipleSkus = (conditions: Condition[]) => {
  let durations = conditions.find(
    condition => condition.name === FilterNames.Duration && condition.type === 'equalAny'
  );
  if (durations && durations.values && durations.values.length) {
    return durations.values[0];
  }
  return DurationOptions.AllReservations;
};

const readDurationsWithSingleSku = (filters: KeyNullableValues[]) => {
  if (!filters.length) {
    return;
  }
  const durationFilter = filters.find(filter => filter.key === FilterNames.Duration);
  const durationValues = filterAndCastMaybeArray(oc(durationFilter).values([]));
  if (durationValues) {
    return durationValues[0];
  }
  return;
};

const readAction = (filters: KeyNullableValues[], action?: CatalogAction) => {
  if (action) {
    return action;
  }
  const actionFilter = filters.find(filter => filter.key === FilterNames.Action);
  const actionValues = filterAndCastMaybeArray(oc(actionFilter).values([]));
  const hasConsume = actionValues.some(
    action => action.toUpperCase() === CatalogAction.Consume.toUpperCase()
  );
  const hasPurchase = actionValues.some(
    action => action.toUpperCase() === CatalogAction.Purchase.toUpperCase()
  );
  if (hasConsume && hasPurchase) {
    loggerService.error({ error: new Error('has both purchase and consume') });
  }
  if (hasConsume) {
    return CatalogAction.Consume;
  }
  return CatalogAction.Purchase;
};

export const readInitialValues = (
  singleSku: boolean,
  filters: KeyNullableValues[],
  conditions?: Condition[],
  catalogAction?: CatalogAction
) => {
  if (!singleSku && !conditions) {
    loggerService.error({
      exception: {
        message: 'readInitialValues is missing conditions',
        name: 'readInitialValues(MetersCard) conditions error',
      },
    });
  }

  const action = readAction(filters, catalogAction);
  let duration = singleSku
    ? readDurationsWithSingleSku(filters)
    : readDurationsWithMultipleSkus(conditions || []);

  if (action === CatalogAction.Consume) {
    duration = DurationOptions.Paygo;
  }
  return {
    duration,
    locations: singleSku
      ? readLocationsWithSingleSku(filters)
      : readLocationsWithMultipleSkus(conditions || []),
  };
};

const generateDurationDropdownOptions = (
  durationOptions: string[],
  t: i18next.TFunction,
  isLegacy?: boolean,
  hasConsume?: boolean
) => {
  let dropdownOptions: IComboBoxOption[] = durationOptions.map(durationOption => {
    return {
      key: durationOption,
      text: durationOption,
      ariaLabel: durationOption,
    };
  });
  dropdownOptions = [
    {
      key: DurationOptions.AllReservations,
      text: t('quote::All Reservations'),
      ariaLabel: t('quote::All Reservations'),
    },

    ...dropdownOptions.sort((a, b) => a.text.localeCompare(b.text)),
  ];
  if (!isLegacy && hasConsume) {
    dropdownOptions.splice(0, 0, {
      key: DurationOptions.Paygo,
      text: t('quote::Pay go'),
      ariaLabel: t('quote::Pay go'),
    });
  }
  return dropdownOptions;
};

const firstAvailableDuration = (
  durationOptions: string[],
  t: i18next.TFunction,
  isLegacy?: boolean
) => {
  const dropdownOptions = generateDurationDropdownOptions(durationOptions, t, isLegacy);
  return dropdownOptions.length ? dropdownOptions[0].key.toString() : '';
};

const getInitialSelectedLocations = (
  locationOptions: string[],
  selectedLocations?: string[]
): string[] => {
  if (selectedLocations && selectedLocations.length) {
    return selectedLocations;
  }
  return [LocationOptions.AllLocations];
};

const getInitialDurationSelection = (
  durationFilters: string[],
  t: i18next.TFunction,
  initialDuration?: string,
  isLegacy?: boolean,
  hasConsume?: boolean
) => {
  if (initialDuration) {
    return initialDuration;
  }
  if (isLegacy || !hasConsume) {
    return firstAvailableDuration(durationFilters, t, isLegacy);
  }
  return DurationOptions.Paygo;
};

const getAllAvailabilities = (skus: Sku[]) => {
  let availabilities: Availability[] = [];
  skus.forEach(sku => {
    availabilities = availabilities.concat(sku.availabilities);
  });
  return availabilities;
};

const getAllMeters = (skus: Sku[]) => {
  const availabilities = getAllAvailabilities(skus);
  let meters: string[] = [];
  availabilities.forEach(availability => {
    if (availability.meterType) {
      meters.push(availability.meterType);
    }
  });
  return meters;
};

const LOCATION_GLOBAL = 'GLOBAL';

//TODO: copied this for now, check for possible improvements
export const applyButtonEnabled = (
  skuId: string | undefined,
  locationFilters: string[],
  durationFilters: string[],
  duration: string,
  locations: string[],
  skuCount: number
): boolean => {
  //TODO: Look at this again
  if (!locations.length || !skuCount) {
    return false;
  }

  if (locations.some(location => location === '') && !skuId) {
    return false;
  }
  const firstlocation = locations[0];
  const isAllLocations = firstlocation === LocationOptions.AllLocations;
  const durationIsAllReservations = duration === DurationOptions.AllReservations;
  const isGlobalLocation =
    firstlocation.toUpperCase() === LOCATION_GLOBAL && durationIsAllReservations;
  const durationIsPayGo = duration === DurationOptions.Paygo;
  const isAllLocationsButNotPayGo = isAllLocations && !durationIsPayGo;
  const isLocationButNotDuration = locationFilters.length && !durationFilters.length;

  return !!(
    isGlobalLocation ||
    durationIsPayGo ||
    !!skuId ||
    isAllLocationsButNotPayGo ||
    isLocationButNotDuration
  );
};

const generateFiltersToGetPrices = (locations: string[], duration: string) => {
  const locationFilters: KeyValueInput[] = locations.map(location => {
    return { key: 'Location', value: location };
  });
  let durationFilters: KeyValueInput[] = [];
  let actionFilters: KeyValueInput[] = [];
  if (duration === DurationOptions.Paygo) {
    actionFilters = [
      {
        key: 'Action',
        value: 'Consume',
      },
    ];
  } else {
    durationFilters = [{ key: 'TermDescription', value: duration }];
  }
  return locationFilters.concat(durationFilters, actionFilters);
};

const shouldShowMeters = (action: CatalogAction, uniqueMeters: string[]) => {
  if (action === CatalogAction.Consume && uniqueMeters.length) {
    return true;
  }
  if (action === CatalogAction.Purchase && uniqueMeters.length > 1) {
    return true;
  }
  return false;
};

export const addPricesToTerms = (priceResponse: FilteredSkus[], skusWeDisplay: Sku[]) => {
  let pricesMap: Record<string, Price | null | undefined> = {};
  priceResponse.forEach(filteredSku => {
    let priceString = '';
    filteredSku.skus.forEach(sku => {
      sku.availabilities.forEach(availability => {
        if (availability.terms) {
          availability.terms.forEach(term => {
            priceString = [sku.skuId, availability.availabilityId, term.termId].join('#');
            pricesMap[priceString] = term.price;
          });
        }
      });
    });
  });
  const clonedSkusWeDisplay = cloneDeep(skusWeDisplay);
  clonedSkusWeDisplay.forEach(sku => {
    sku.availabilities.forEach(availability => {
      if (availability.terms) {
        availability.terms.forEach(term => {
          const priceString = [sku.skuId, availability.availabilityId, term.termId].join('#');
          if (pricesMap[priceString]) {
            term.price = pricesMap[priceString];
          }
        });
      }
    });
  });
  return clonedSkusWeDisplay;
};

const generateColumnHeaders = (
  maxTermCount: number,
  uniqueMeters: string[],
  loadingPrices: boolean,
  currency: Currency,
  action: CatalogAction
) => {
  const showMeters = shouldShowMeters(action, uniqueMeters);
  const titleColumn: IColumn = {
    name: '',
    key: '',
    fieldName: 'title',
    minWidth: 0,
    columnActionsMode: ColumnActionsMode.disabled,
  };
  if (maxTermCount <= 1 && !showMeters) {
    const priceColumn: IColumn = {
      name: i18next.t('quote::Price ({{currency}})', { currency }),
      key: 'Price',
      fieldName: 'price',
      minWidth: 0,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: item => {
        if (loadingPrices) {
          return <ShimmerForBackgroundCommon width={50} />;
        }
        return item.price;
      },
    };

    return [titleColumn, priceColumn];
  } else {
    const meterColumns = uniqueMeters.map(meter => {
      const column: IColumn = {
        name: meter,
        key: meter,
        fieldName: meter,
        minWidth: 0,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: item => {
          if (loadingPrices) {
            return <ShimmerForBackgroundCommon width={50} />;
          }
          return item[meter];
        },
      };
      return column;
    });
    return [titleColumn, ...meterColumns];
  }
};

const isFilterAvailable = (skuInformation: FilteredSkus, key: string, value?: string) => {
  if (value == null) {
    return false;
  }
  return skuInformation.filters.some(
    filter => filter.key === key && (filter.value || '').toUpperCase() === value.toUpperCase()
  );
};

const hasOneOflocations = (skuInformation: FilteredSkus, locations: string[]) => {
  if (locations[0] === LocationOptions.AllLocations) {
    return true;
  }
  return locations.some(location =>
    isFilterAvailable(skuInformation, FilterNames.Location, location)
  );
};

const filterlocation = (skuInformations: FilteredSkus[], locations: string[]) =>
  skuInformations.filter(skuInformation => hasOneOflocations(skuInformation, locations));

const filterDuration = (skuInformations: FilteredSkus[], duration?: string) =>
  skuInformations.filter(skuInformation =>
    isFilterAvailable(skuInformation, FilterNames.Duration, duration)
  );

const filterAction = (action: string, skuInformations: FilteredSkus[]) =>
  skuInformations.filter(skuInformation =>
    isFilterAvailable(skuInformation, FilterNames.Action, action)
  );

const filterPaygo = (skuInformations: FilteredSkus[], locations: string[]) => {
  if (locations[0] === LocationOptions.AllLocations) {
    return flatSkuInfoList(filterAction(CatalogAction.Consume, skuInformations));
  }
  const filtered = skuInformations.filter(
    skuInformation =>
      isFilterAvailable(skuInformation, FilterNames.Action, CatalogAction.Consume) &&
      hasOneOflocations(skuInformation, locations)
  );
  return flatSkuInfoList(filtered);
};

export const filterSkus = (
  skuInformations: FilteredSkus[],
  locations: string[] = [],
  action: CatalogAction,
  duration?: string
): Sku[] => {
  if ((!locations || !locations.length) && !duration) {
    return flatSkuInfoList(skuInformations);
  }
  if (action === CatalogAction.Consume) {
    return filterPaygo(skuInformations, locations);
  }
  let onlyPurchases = filterAction(CatalogAction.Purchase, skuInformations);

  // Non-Paygo cases
  if (
    duration === DurationOptions.AllReservations &&
    locations[0] === LocationOptions.AllLocations
  ) {
    return flatSkuInfoList(onlyPurchases);
  }

  if (duration === DurationOptions.AllReservations) {
    const filtered = filterlocation(onlyPurchases, locations);
    return flatSkuInfoList(filtered);
  }

  if (!locations || !locations.length || locations[0] === LocationOptions.AllLocations) {
    return flatSkuInfoList(filterDuration(onlyPurchases, duration));
  }

  const filtered = onlyPurchases.filter(
    skuInformation =>
      hasOneOflocations(skuInformation, locations) &&
      isFilterAvailable(skuInformation, FilterNames.Duration, duration)
  );
  return flatSkuInfoList(filtered);
};

export const generateLocationPreviewMessage = (locations: string[]) => {
  const withEmptyCaseHandled = locations.map(loc =>
    !loc ? i18next.t('quote::Non-regional SKUs') : loc
  );
  const maxCharacterDropdownCanTake = 27;
  const combined = withEmptyCaseHandled.join(', ');

  // we have to make it shorter
  if (combined.length > maxCharacterDropdownCanTake) {
    const countText = `...(${withEmptyCaseHandled.length})`;
    // First reserve space for the ....length part
    const characterCountWeCanUseForLocations = maxCharacterDropdownCanTake - countText.length;
    // Use the remaining for locations
    const shorter = `${combined.substr(0, characterCountWeCanUseForLocations).trim()}${countText}`;
    return shorter;
  } else {
    return combined;
  }
};

const generateRIMessage = (
  locations: string[],
  duration: string,
  isLegacy: boolean,
  isHidden: boolean,
  t: i18next.TFunction,
  classes: Record<string, string>
) => {
  if (isHidden) {
    return;
  }
  const isSpecificLocation =
    locations.length === 1 && locations[0] !== LocationOptions.AllLocations;

  const isSpecificDuration =
    duration !== DurationOptions.Paygo && duration !== DurationOptions.AllReservations;
  if (isSpecificLocation && isSpecificDuration) {
    return isLegacy ? (
      <TextBody addClass={classes.message} dataAutomationId="configCardBodyMessage">
        {t("quote::RI's may only be discounted for all reservations or a specific instance below")}
      </TextBody>
    ) : (
      <TextBody addClass={classes.message} dataAutomationId="configCardBodyMessage">
        {t("quote::RI's may only be discounted for all locations or a specific instance below")}
      </TextBody>
    );
  }
};

const generateSkuMessage = (
  skuCount: number,
  t: i18next.TFunction,
  locations: string[] = [],
  duration?: string
) => {
  if (!skuCount) {
    return t(
      'quote::No SKUs available for this configuration, please select a different configuration'
    );
  }

  if (
    locations[0] !== LocationOptions.AllLocations &&
    duration === DurationOptions.AllReservations
  ) {
    return t(
      'quote::Please select a new configuration.Currently, the configuration "All reservations" requires the selection "All locations."'
    );
  }

  //TODO: these are not super critical but create mocks to test these
  if (locations.length > 1 && locations.length <= 5) {
    return t(`quote::{{count}} SKUs in the locations {{locations}} will be part of the discount`, {
      count: skuCount,
      locations: locations.join(', '),
    });
  }

  if (locations.length > 5) {
    return t(`quote::{{count}} SKUs in {{locationsCount}} locations will be part of the discount`, {
      count: skuCount,
      locationsCount: locations.length,
    });
  }

  if (
    locations[0] === LocationOptions.AllLocations ||
    duration === DurationOptions.AllReservations
  ) {
    return t('quote::{{count}} SKU will be part of the discount', {
      count: skuCount,
      // eslint-disable-next-line @typescript-eslint/camelcase
      defaultValue_plural: '{{count}} SKUs will be part of the discount',
    });
  }
};

const generateItems = (
  uniqueMeters: string[],
  skus: Sku[],
  maxTermCount: number,
  action: CatalogAction
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rows: any = [];
  const showMeters = shouldShowMeters(action, uniqueMeters);

  if (maxTermCount <= 1 && !showMeters) {
    skus.forEach(sku => {
      const terms = getAllTermsFromTheSku(sku);
      if (terms[0].price !== null && terms[0].price !== undefined) {
        rows.push({
          ...sku,
          key: sku.skuId,
          price: formatCurrency(terms[0].price.amount),
          selectable: true,
        });
      } else {
        rows.push({ ...sku, key: sku.skuId, price: '-', selectable: true });
      }
    });
  } else {
    skus.forEach(sku => {
      let row = {};
      const common = { ...sku, key: sku.skuId, selectable: true };
      uniqueMeters.forEach(meterName => {
        const availability = sku.availabilities.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          availability => availability.meterType === meterName
        );
        let meterValue: string | undefined = '-';
        if (availability && availability.terms && availability.terms.length) {
          const firstTermHasPrice = availability.terms[0] && availability.terms[0].price != null;
          if (firstTermHasPrice) {
            meterValue =
              availability.terms[0].price !== null && availability.terms[0].price !== undefined
                ? formatCurrency(availability.terms[0].price.amount.toString())
                : '-';
          }
        }
        row = {
          ...common,
          ...row,
          [meterName]: meterValue,
        };
      });

      rows.push(row);
    });
  }
  return rows;
};

export interface MetersConfigurationCardProps
  extends ConfigurationCardCommonProps,
    SkuConfigurationCardCommonProps {
  allowUnspecifiedOptionInLocationFilters?: boolean;
  locationFilters: string[];
  durationFilters: string[];
  actionFilters: string[];
  productId: string;
  readOnly?: boolean;
  filteredSkus: FilteredSkus[];
  isLegacy?: boolean;
  quote: QuoteMutationInput;
  initialValues?: { locations: string[]; duration?: string; skuId?: string };
  lineItemId: string;
  onDismiss: () => void;
  target: React.RefObject<HTMLSpanElement>;
}

export interface MeterConfigurationCardResponse {
  filteredSkus: FilteredSkus[];
}

interface PriceCallSku {
  skuId: string;
  availabilities: {
    terms: {
      price: Price;
      termId: string;
    }[];
  }[];
}

export interface PriceResult {
  id: string;
  filteredSkus: {
    skus: PriceCallSku[];
  }[];
}

interface GetMeterProductResponse {
  getProduct: {
    filteredSkus: FilteredSkus[];
  };
}

type Props = MetersConfigurationCardProps & WithStyles<typeof styles>;

const getFirstAvailability = (skus: Sku[], skuId: string) => {
  const matchedSku = skus.find(sku => sku.skuId === skuId);
  if (!matchedSku) {
    throw new Error('mismatch in Sku');
  }
  const firstAvailability = matchedSku.availabilities[0];
  if (!firstAvailability) {
    throw new Error('no availability');
  }
  return firstAvailability;
};

const differentSelection = (current: string[], newSelections: string[]) => {
  if (current.length !== newSelections.length) {
    return true;
  }
  for (let i = 0; i < current.length; i++) {
    if (current[i] !== newSelections[i]) {
      return true;
    }
  }
  return false;
};

const MetersConfigurationCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    actionFilters,
    allowUnspecifiedOptionInLocationFilters,
    locationFilters,
    durationFilters,
    productId,
    target,
    classes,
    initialValues,
    quote,
    currency,
    catalogContext,
    lineItemId,
    alreadyHasDiscount,
    productTitle,
    isLegacy,
    readOnly,
    onDismiss,
  } = props;

  const { t } = useTranslation();
  const [view, setView] = React.useState<View>(View.CardContent);
  const hasConsume = actionFilters.some(
    action => action.toUpperCase() === CatalogAction.Consume.toUpperCase()
  );

  const [locations, setlocations] = React.useState<string[]>(
    getInitialSelectedLocations(locationFilters, oc(initialValues).locations())
  );
  const [locationDropdownSelections, setlocationDropdownSelections] = React.useState<string[]>(
    getInitialSelectedLocations(locationFilters, oc(initialValues).locations())
  );
  const [initialSelectionIsSet, setInitialSelectionIsSet] = React.useState<boolean>(false);
  const [duration, setDuration] = React.useState<string>(
    getInitialDurationSelection(
      durationFilters,
      t,
      oc(initialValues).duration(),
      isLegacy,
      hasConsume
    )
  );
  const [skuId, setSkuId] = React.useState<string | undefined>(
    initialValues && initialValues.skuId
  );

  const multipleLocationsSelected = locations.length > 1;
  const allLocationsSelected = !!locations.length && locations[0] === LocationOptions.AllLocations;
  const skipGettingPrices =
    multipleLocationsSelected ||
    duration === DurationOptions.AllReservations ||
    allLocationsSelected;

  const { loading: loadingPrices, data: priceData } = useQuery<GetMeterProductResponse>(
    GetMeterProduct,
    {
      variables: {
        filter: generateFiltersToGetPrices(locations, duration),
        id: productId,
        catalogContext: removeTypeName(catalogContext),
        quoteId: quote.id,
      },
      skip: skipGettingPrices,
    }
  );

  const prices = oc(priceData).getProduct.filteredSkus();

  const dialogContext = React.useContext(DialogContext);
  let skuInformations = props.filteredSkus;
  const [configureWithSingleSku, { loading: singleSkuLoading }] = useMutation<
    undefined,
    MutationApplyConfigurationSingleSkuArgs
  >(ApplyConfigurationSingleSku, { errorPolicy: 'ignore' });
  const [configureWithMultipleSku, { loading: multipleSkuLoading }] = useMutation<
    undefined,
    MutationApplyConfigurationMultipleSkusArgs
  >(ApplyConfigurationMultipleSkus);

  const mutationLoading = singleSkuLoading || multipleSkuLoading;

  const locationOptions = generatelocationDropdownOptions(
    locationFilters,
    t,
    allowUnspecifiedOptionInLocationFilters
  );
  const durationOptions = generateDurationDropdownOptions(durationFilters, t, isLegacy, hasConsume);
  const onlyConsume =
    actionFilters.length === 1 &&
    actionFilters[0].toUpperCase() === CatalogAction.Consume.toUpperCase();

  const onRenderOption = (
    option?: IDropdownOption,
    defaultRender?: IRenderFunction<IDropdownOption>
  ): JSX.Element | null => {
    if (!defaultRender || !option) {
      loggerService.error({
        error: new Error('onRenderOption defaultRender or option is undefined'),
      });
      return null;
    }
    if (option.key === 'select-all') {
      // TODO: I want something like this here https://github.com/microsoft/TypeScript/issues/31388
      //all locations,divider,select all,...rest
      const [, , , ...rest] = locationOptions.map(location => location.key.toString());
      const isUnselect =
        locationDropdownSelections.length === rest.length &&
        locationDropdownSelections[0] !== LocationOptions.AllLocations;
      const text = isUnselect ? t('quote::unselect all') : t('quote::select all');

      return (
        <LinkButton
          displayText={text}
          onClick={() => {
            if (isUnselect) {
              setlocationDropdownSelections([LocationOptions.AllLocations]);
            } else {
              setlocationDropdownSelections(rest);
            }
          }}
        />
      );
    }
    return defaultRender(option);
  };

  const locationDropdown = (
    <Dropdown
      data-automation-id="location"
      disabled={readOnly}
      label={t('quote::Location')}
      maxHeight={258}
      multiSelect={true}
      options={locationOptions}
      selectedKeys={locationDropdownSelections}
      style={{ marginBottom: 10 }}
      title={locationDropdownSelections.join(', ')}
      onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
          const deselect = locationDropdownSelections.find(location => location === option.key);
          if (deselect != null) {
            const newlocations = locationDropdownSelections.filter(
              location => location !== option.key
            );
            if (!newlocations.length) {
              setlocationDropdownSelections([LocationOptions.AllLocations]);
            } else {
              setlocationDropdownSelections(newlocations);
            }
          } else {
            if (option.key === LocationOptions.AllLocations) {
              setlocationDropdownSelections([LocationOptions.AllLocations]);
            } else {
              const allLocationsRemoved = locationDropdownSelections.filter(
                location => location !== LocationOptions.AllLocations
              );
              setlocationDropdownSelections([...allLocationsRemoved, option.key.toString()]);
            }
          }
        }
      }}
      onDismiss={() => {
        //Only reset the skuId when there is a difference between the selections, if it is the same we want to keep it
        if (differentSelection(locations, locationDropdownSelections)) {
          setSkuId(undefined);
        }
        const [, , , ...rest] = locationOptions;
        const isAllSpecificLocationsIndividuallySelected =
          rest.length === locationDropdownSelections.length;
        if (isAllSpecificLocationsIndividuallySelected && rest.length > 1) {
          setlocations([LocationOptions.AllLocations]);
          setlocationDropdownSelections([LocationOptions.AllLocations]);
        } else {
          setlocations(locationDropdownSelections);
          setlocationDropdownSelections(locationDropdownSelections);
        }
      }}
      onRenderOption={onRenderOption}
      onRenderTitle={(
        options?: IDropdownOption[],
        defaultRender?: IRenderFunction<IDropdownOption[]>
      ) => {
        if (!defaultRender) {
          loggerService.error({
            error: new Error("MetersCard onRenderTitle's defaultRender is falsy"),
          });
          return null;
        }
        const preview = generateLocationPreviewMessage(locationDropdownSelections);
        let option = {
          key: preview,
          text: preview,
        };
        const [, , , ...rest] = locationOptions;
        const isAllSpecificLocationsIndividuallySelected =
          rest.length === locationDropdownSelections.length;
        if (isAllSpecificLocationsIndividuallySelected && rest.length > 1) {
          option = {
            key: t('quote::All Locations'),
            text: t('quote::All Locations'),
          };
        }
        return defaultRender([option]);
      }}
    />
  );

  const action =
    duration === DurationOptions.Paygo ? CatalogAction.Consume : CatalogAction.Purchase;

  let skus: Sku[] = [];
  if (skuInformations) {
    skus = getUnique(filterSkus(skuInformations, locations, action, duration), sku => sku.skuId);
    if (!loadingPrices && prices && !skipGettingPrices) {
      skus = addPricesToTerms(prices, skus);
    }
  }

  const onApplySingleSku = (skuId: string, configureAsNew: boolean) => {
    const availability = getFirstAvailability(skus || [], skuId);
    if (availability) {
      const configuration: ApplyConfigurationSingleSkuInput = {
        action: duration === DurationOptions.Paygo ? CatalogAction.Consume : CatalogAction.Purchase,
        skuId,
        availabilityId: availability.availabilityId,
        lineItemId: lineItemId,
        configureAsNew,
        termId:
          availability.terms && availability.terms.length
            ? availability.terms[0].termId
            : undefined,
      };

      const variables = {
        quote,
        configuration,
      };
      configureWithSingleSku({ variables });
    }
  };
  const createMultipleSkusConditions = () => {
    let conditions: ConditionInput[] = [];
    if (locations.length && locations[0] !== LocationOptions.AllLocations) {
      conditions.push({ name: FilterNames.Location, type: 'equalAny', values: locations });
    }
    if (duration !== DurationOptions.Paygo && duration !== DurationOptions.AllReservations) {
      conditions.push({ name: FilterNames.Duration, type: 'equalAny', values: [duration] });
    }
    return conditions;
  };

  const onApplyMultipleSkus = (configureAsNew: boolean) => {
    const configuration: ApplyConfigurationMultipleSkusInput = {
      action: duration === DurationOptions.Paygo ? CatalogAction.Consume : CatalogAction.Purchase,
      lineItemId: lineItemId,
      conditions: createMultipleSkusConditions(),
      configureAsNew,
    };
    configureWithMultipleSku({ variables: { quote, configuration } });
  };

  const onApply = (skuId: string | undefined, configureAsNew: boolean) => {
    if (skuId) {
      if (configureAsNew) {
        onApplySingleSku(skuId, configureAsNew);
      } else if (alreadyHasDiscount) {
        setView(View.ConfirmationDialog);
        applyConfigurationCardWithDialog(
          () => {
            onApplySingleSku(skuId, configureAsNew);
            onDismiss();
          },
          () => setView(View.CardContent),
          dialogContext
        );
      } else {
        onApplySingleSku(skuId, configureAsNew);
        onDismiss();
      }
    }
    //We are applying multiple skus
    else {
      if (configureAsNew) {
        onApplyMultipleSkus(configureAsNew);
      } else if (alreadyHasDiscount) {
        setView(View.ConfirmationDialog);
        applyConfigurationCardWithDialog(
          () => {
            onApplyMultipleSkus(configureAsNew);
            onDismiss();
          },
          () => setView(View.CardContent),
          dialogContext
        );
      } else {
        onApplyMultipleSkus(configureAsNew);
        onDismiss();
      }
    }
  };

  const DurationDropdown = (
    <Dropdown
      data-automation-id="duration"
      disabled={readOnly}
      label={t('quote::Commitment')}
      maxHeight={258}
      options={durationOptions}
      selectedKey={duration}
      onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
          setSkuId(undefined);
          setDuration(option.key.toString());
        }
      }}
    />
  );

  const onSelectionChanged = (selection: Selection) => {
    const newSelection = selection && selection.getSelection()[0];
    const skuId = newSelection && newSelection.key;
    if (skuId) {
      setSkuId(skuId.toString());
    } else {
      setSkuId(undefined);
    }
  };

  const uniqueMeters = [...new Set(getAllMeters(skus))];
  const maxTermCount = getMaxTermCountAcrossSkus(skus);
  const selection = useFabricSelectionSimple(onSelectionChanged);

  React.useEffect(() => {
    if (!initialSelectionIsSet && selection && skus && skus.length && initialValues && skuId) {
      const items = generateItems(uniqueMeters, skus, maxTermCount, action);
      selection.setItems(items);
      selection.setKeySelected(skuId, true, false);
      setInitialSelectionIsSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skus, skuId, initialSelectionIsSet, initialValues]);

  const skuList = (
    <div className={classes.detailsListContainer}>
      <DetailsList
        addClass={classes.detailsListStyles}
        checkboxVisibility={CheckboxVisibility.always}
        columns={generateColumnHeaders(maxTermCount, uniqueMeters, loadingPrices, currency, action)}
        compact={true}
        items={generateItems(uniqueMeters, skus, maxTermCount, action)}
        selection={selection}
        selectionMode={readOnly ? SelectionMode.none : SelectionMode.single}
        shouldResizeAfterFirstRender={true}
      />
      <SectionSeparator />
    </div>
  );

  const isApplyEnabled = applyButtonEnabled(
    skuId,
    locationFilters,
    durationFilters,
    duration,
    locations,
    skus.length
  );
  const RIMessage = generateRIMessage(locations, duration, !!isLegacy, !skus.length, t, classes);
  const skuMessage = generateSkuMessage(skus.length, t, locations, duration);
  const content = skuMessage ? (
    <div className={classes.message} data-automation-id="configCardWaterMark">
      {skuMessage}
    </div>
  ) : (
    <div data-automation-id="skuList">{skuList}</div>
  );

  return (
    <div>
      <CalloutCard
        {...calloutCardDimensions}
        applyButtonDisabled={!isApplyEnabled}
        applyButtonStrings={{ text: t('quote::Apply'), ariaLabel: t('quote::Apply') }}
        closeButtonAriaLabel={t('quote::Close')}
        directionalHint={DirectionalHint.rightCenter}
        headerText={productTitle}
        hidden={view === View.ConfirmationDialog}
        id="1234"
        isBeakVisible={true}
        isReadOnly={!!readOnly}
        otherFooterButtons={
          <AddAsNewLineItemButton
            disabled={!isApplyEnabled || readOnly}
            loading={mutationLoading}
            onClick={() => {
              onApply(skuId, true);
            }}
          />
        }
        target={target}
        onApply={() => {
          onApply(skuId, false);
        }}
        onDismiss={onDismiss}
      >
        <div className={classes.dropdownsContainer}>
          <div className={classes.locationDropdown}>{locationDropdown}</div>
          <div className={classes.durationDropdown}>{!onlyConsume && DurationDropdown}</div>
        </div>
        <SectionSeparator />
        {RIMessage}
        {content}
      </CalloutCard>
    </div>
  );
};

export const MetersConfigurationCard = withStyles(styles)(MetersConfigurationCardUnstyled);
