import { TextBody, TextBodySmall } from 'components';
import { Dialog, DialogImageProps, headerHeight } from 'components/ions/PopUps/Dialogs/Dialog';
import { WaitingIndicator } from 'components/ions/Progress/WaitingIndicator';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { Dimensions } from './types';

export interface ProcessingProps extends Dimensions {
  message1?: string;
  message2?: string;
  imageProps?: DialogImageProps;
}

const styles = (theme: ThemeProps) => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: (props: ProcessingProps) => {
        const paddingTop = props.height / 4;
        return paddingTop < headerHeight ? headerHeight : paddingTop;
      },
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& > :not(:first-child)': {
        paddingTop: 16,
      },
      maxWidth: 360,
      textAlign: 'center',
    },
    message2: {
      color: theme.palette.textTertiary,
    },
  };
};

type Props = ProcessingProps & WithStyles<typeof styles>;
const ProcessingUnStyled: React.FC<Props> = props => {
  const { classes } = props;
  return (
    <Dialog height={props.height} hideHeader imageProps={props.imageProps} width={props.width}>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <WaitingIndicator />
          {props.message1 && <TextBody>{props.message1}</TextBody>}
          {props.message2 && (
            <TextBodySmall addClass={classes.message2}>{props.message2}</TextBodySmall>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export const Processing = withStyles(styles)(ProcessingUnStyled);
