import { Location } from 'history';

import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';

// Test URL: https://local.c3.mepla.microsoft.com/external?link=quote&id=8610306ea39f&referrer=abc

class LogEvent {
  public eventTelemetry: IEventTelemetry;
  public customProperties?: ICustomProperties;

  public constructor(logEvent: LogEvent) {
    this.eventTelemetry = logEvent.eventTelemetry;
    this.customProperties = logEvent.customProperties;
  }
}

class ErrorEvent {
  public exceptionTelemetry: IExceptionTelemetry;

  public constructor(errorEvent: ErrorEvent) {
    this.exceptionTelemetry = errorEvent.exceptionTelemetry;
  }
}

class PageLoadEvent {
  public pageName: string;
  public fullPathName: string;

  public constructor(pageLoadEvent: PageLoadEvent) {
    this.pageName = pageLoadEvent.pageName;
    this.fullPathName = pageLoadEvent.fullPathName;
  }
}

class LoggerService {
  private static _instance: LoggerService;

  private appInsights: ApplicationInsights | undefined;
  // eslint-disable-next-line @typescript-eslint/array-type
  private eventQueue: Array<LogEvent | ErrorEvent | PageLoadEvent> = [];
  private consoleLog = false;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public init(appInsights: ApplicationInsights) {
    this.appInsights = appInsights;

    this.eventQueue.forEach(event => {
      if (event instanceof LogEvent) {
        return this.log(event.eventTelemetry, event.customProperties);
      }

      if (event instanceof ErrorEvent) {
        return this.error(event.exceptionTelemetry);
      }

      if (event instanceof PageLoadEvent) {
        return this.pageLoad(event.pageName, event.fullPathName);
      }
    });
  }

  public log(eventTelemetry: IEventTelemetry, customProperties?: ICustomProperties | undefined) {
    if (this.consoleLog)
      console.log(`${!this.appInsights ? 'QUEUED ' : ''}LoggerService.log`, {
        eventTelemetry,
        customProperties,
      });

    if (!this.appInsights) {
      return this.eventQueue.push(new LogEvent({ eventTelemetry, customProperties }));
    }

    this.appInsights.trackEvent(eventTelemetry, customProperties);
  }

  public error(exceptionTelemetry: IExceptionTelemetry) {
    if (this.consoleLog)
      console.log(`${!this.appInsights ? 'QUEUED ' : ''}LoggerService.error`, {
        exceptionTelemetry,
      });

    if (!this.appInsights) {
      return this.eventQueue.push(new ErrorEvent({ exceptionTelemetry }));
    }

    this.appInsights.trackException(exceptionTelemetry);
  }

  public locationChange(location: Location) {
    // Rewrite Page Names --------------------------------------------------------------------
    // Removing page id's from the page view name so that AppInsights reporting has less noise
    // and groups all Quote/Customer "page views" together.

    let pageName = location.pathname;

    // Remove id '8610306ea39j' after '/quote/8610306ea39j'
    pageName = pageName.replace(/quote\/[^/]+/g, 'quote');

    // Remove id '99' after '/customer/99'
    pageName = pageName.replace(/customer\/[^/]+/g, 'customer');

    // Remove id 'ngvl:1234567890' after '/customer/agreements/active/ngvl:1234567890/info' or '/customer/agreements/expired/ngvl:1234567890/info'
    pageName = pageName.replace(
      /customer\/agreements\/(active|expired)\/[^/]+/g,
      'customer/agreements/$1'
    );
    // Remove id 'ab1234567890' after /customer/subscriptions/all/ab1234567890
    pageName = pageName.replace(/customer\/subscriptions\/all\/(.*)/, 'customer/subscriptions/all');

    // Skip Location Changes -----------------------------------------------------------------
    // These locations are known to be redirected in React components using <Redirect... />.
    // As such, we want to skip the telemetry logging of these extra steps in redirection to
    // lessen the telemetry noise when evaluating a user's navigation flow/path from page to
    // page during their session.  This somewhat "brittle" approach to ignoring certain location
    // changes was done given the likely changes that will come to React Router in the near
    // future that may affect our approach to this problem.
    switch (pageName) {
      case '/':
      case '/home':
      case '/home/help':
      case '/home/quotes':
      case '/home/referrals':
      case '/quote':
      case '/external':
      case '/customer':
      case '/customer/agreements':
      case '/customer/agreements/active':
      case '/customer/agreements/expired':
      case '/customer/subscriptions':
        return;
    }

    this.pageLoad(pageName, location.pathname);
  }

  public pageLoad(pageName: string, fullPathName: string) {
    if (this.consoleLog)
      console.log(`${!this.appInsights ? 'QUEUED ' : ''}LoggerService.pageLoad`, {
        pageName,
        fullPathName,
      });

    if (!this.appInsights) {
      return this.eventQueue.push(new PageLoadEvent({ pageName, fullPathName }));
    }

    this.appInsights.trackPageView({
      name: pageName,
      properties: { fullPathName },
    });
  }
}

export default LoggerService.Instance;
