import { buildProductIdentifier } from 'features/proposal/utils';
import { combineReducers } from 'redux';
import { NegotiatedTermResponse, Product, ServiceFamily } from 'services/catalog/types';
import { ValidateConstraintsResponse } from 'services/constraints/types';
import { Channel } from 'services/reco/types';
import { oc } from 'ts-optchain';
import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './actions';
import { CatalogPageProducts, ProductSearch, ProductType } from './types';

const reducer = combineReducers({
  products: combineReducers({
    fragments: combineReducers({
      indexed: createReducer<Record<string, Record<string, Product>>, ActionType<typeof actions>>(
        {}
      )
        // When new products are loaded successfully, add them to the state.
        .handleAction(actions.loadProductsAsync.success, (state, action) => {
          if (!action.payload.products.length) {
            return state;
          }
          return {
            ...state,
            [action.payload.productKey]: {
              ...(state[action.payload.productKey] || {}),
              ...action.payload.products.reduce(
                (products: Record<string, Product>, product: Product) => {
                  products[product.ProductId] = product;
                  return products;
                },
                {}
              ),
            },
          };
        }),

      // Optimistically add newly requested products so that we can prevent
      // requesting products to load more than once.
      // .handleAction(actions.loadProductsAsync.request, (state, action) => ({
      //   ...state,
      //   ...action.payload.reduce((products: { [id: string]: ProductFragment }, id: string) => {
      //     products[id] = { id, name: '' };
      //     return products;
      //   }, {}),
      // }))

      // TODO: Handle the case of failure to load product and remove the optimistically
      // added ID from the state.

      ordered: createReducer<Record<string, string[]>, ActionType<typeof actions>>({}).handleAction(
        actions.loadProductsAsync.success,
        (state, action) => {
          if (!action.payload.products.length) {
            return state;
          }
          return {
            ...state,
            [action.payload.productKey]: [
              ...(state[action.payload.productKey] || []),
              ...action.payload.products.map(product => product.ProductId),
            ],
          };
        }
      ),

      // Optimistically add newly requested products so that we can prevent
      // requesting products to load more than once.
      // .handleAction(actions.loadProductsAsync.request, (state, action) => [
      //   ...state,
      //   ...action.payload,
      // ]),
    }),
    entities: combineReducers({
      indexed: createReducer<Record<string, Record<string, Product>>, ActionType<typeof actions>>(
        {}
      )
        // When new products are loaded successfully, add them to the state.
        .handleAction(actions.loadProductsAsync.success, (state, action) => {
          if (!action.payload.products.length) {
            return state;
          }
          return {
            ...state,
            [action.payload.productKey]: {
              ...(state[action.payload.productKey] || {}),
              ...action.payload.products.reduce(
                (products: Record<string, Product>, product: Product) => {
                  products[product.ProductId] = product;
                  return products;
                },
                {}
              ),
            },
          };
        }),
      ordered: createReducer<Record<string, string[]>, ActionType<typeof actions>>({}).handleAction(
        actions.loadProductsAsync.success,
        (state, action) => {
          if (!action.payload.products.length) {
            return state;
          }
          return {
            ...state,
            [action.payload.productKey]: [
              ...(state[action.payload.productKey] || []),
              ...action.payload.products.map(product => product.ProductId),
            ],
          };
        }
      ),
    }),
    failed: createReducer<Record<string, Record<string, true>>, ActionType<typeof actions>>(
      {}
    ).handleAction(actions.loadProductsAsync.success, (state, action) => {
      if (!action.payload.failed.length) {
        return state;
      }
      const newRecord: Record<string, true> = {};
      action.payload.failed.forEach(id => (newRecord[id] = true));
      return {
        ...state,
        [action.payload.productKey]: {
          ...(state[action.payload.productKey] || {}),
          ...newRecord,
        },
      };
    }),
  }),

  recommendations: combineReducers({
    byChannel: createReducer<Record<string, Channel>, ActionType<typeof actions>>({}).handleAction(
      actions.loadRecoAsync.success,
      (state, action) => {
        if (!action.payload.length) {
          return state;
        }
        const newState = {
          ...state,
        };
        action.payload.forEach(channel => {
          newState[channel.name] = {
            name: channel.name,
            Title: channel.Title,
            Description: channel.Description,
            Items: channel.Items.map(item => ({ Id: item.Id })),
          };
        });
        return newState;
      }
    ),

    channelsByType: createReducer<Record<string, string[]>>({
      [`modern|${ProductType.Product.toString()}`]: ['AzureEssentials'],
      [`modern|${ProductType.Term.toString()}`]: ['StandardTerms'],
      [`modern|${ProductType.ECIF.toString()}`]: ['ECIF'],
    }),
  }),

  search: combineReducers({
    // nextSkip: Use when searching more products to get the next new results
    results: createReducer<ProductSearch, ActionType<typeof actions>>({
      nextSkip: 0,
      products: [],
      productFamiliesWithMoreResults: [],
    })
      .handleAction(
        [
          actions.searchProductsAsync.success,
          actions.expandProductSearchAsync.success,
          actions.searchTermsAsync.success,
        ],
        (state, action) => {
          let products;

          const newResults = action.payload.products.map(item => ({
            productId: item.ProductId,
            productName: item.LocalizedProperties[0].ProductTitle,
            productIdentifier: buildProductIdentifier(item),
            maxQuantityOnQuote: item.Properties.MaxQuantityOnProposal,
          }));

          if (!state.products.length) {
            if (!action.payload.products.length) {
              products = [
                {
                  productId: '0',
                  productName: 'noResultsFound',
                  productIdentifier: {
                    productId: '',
                    productType: '',
                    productFamily: '',
                  },
                },
              ];
            } else {
              products = newResults;
            }
          } else {
            if (!action.payload.products.length) {
              products = state.products;
            } else if (state.products[0].productName === 'noResultsFound') {
              products = newResults;
            } else {
              products = state.products.concat(newResults);
            }
          }

          return {
            nextSkip: action.payload.nextSkip,
            products,
            productFamiliesWithMoreResults: action.payload.productFamiliesWithMoreResults,
          };
        }
      )
      .handleAction(
        [
          actions.searchProductsAsync.request,
          actions.searchTermsAsync.request,
          actions.clearSearchProducts,
        ],
        () => ({
          nextSkip: 0,
          products: [],
          productFamiliesWithMoreResults: [],
        })
      ),
  }),

  filteredFavorites: combineReducers({
    products: createReducer<Product[], ActionType<typeof actions>>([])
      .handleAction(actions.filterFavoriteProductsForLegacyAsync.success, (state, action) => {
        return state.concat(action.payload);
      })
      .handleAction(actions.filterFavoriteProductsForLegacyAsync.request, (state, action) => []),
  }),

  catalogPage: combineReducers({
    featured: createReducer<
      Record<string, Partial<Record<ServiceFamily, CatalogPageProducts>>>,
      ActionType<typeof actions>
    >({}).handleAction(actions.searchCatalogPageProducts.success, (state, action) => {
      const {
        hasMorePages,
        products,
        resultsToSkip,
        serviceFamily,
        catalogKey,
        query,
      } = action.payload;
      if (query) {
        return state;
      }
      const oldProducts = oc(state)[catalogKey][serviceFamily].products({});

      return {
        ...state,
        [catalogKey]: {
          [serviceFamily]: {
            products: { ...oldProducts, ...products },
            resultsToSkip,
            serviceFamily,
            hasMorePages,
          },
        },
      };
    }),

    negotiatedTerms: createReducer<NegotiatedTermResponse, ActionType<typeof actions>>({
      Agregations: [],
      HasMorePages: false,
      ProductIds: [],
      Products: [],
      TotalResultCount: 0,
    }).handleAction(actions.getNegotiatedTerms.success, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    }),

    searchResults: createReducer<Partial<CatalogPageProducts>, ActionType<typeof actions>>({})
      .handleAction(actions.searchCatalogPageProducts.success, (state, action) => {
        if (!action.payload.query) {
          return state;
        }
        if (action.payload.resultsToSkip > 25) {
          return {
            ...state,
            products: { ...state.products, ...action.payload.products },
            resultsToSkip: action.payload.resultsToSkip,
            serviceFamily: action.payload.serviceFamily,
            hasMorePages: action.payload.hasMorePages,
          };
        }
        return {
          products: action.payload.products,
          resultsToSkip: action.payload.resultsToSkip,
          serviceFamily: action.payload.serviceFamily,
          hasMorePages: action.payload.hasMorePages,
        };
      })
      .handleAction(actions.clearSearchCatalogPageProducts, () => ({
        products: undefined,
        resultsToSkip: 0,
        serviceFamily: undefined,
        hasMorePages: false,
      })),
  }),
  validations: combineReducers({
    constraintsResults: createReducer<ValidateConstraintsResponse, ActionType<typeof actions>>({
      productConstraintResults: [],
    }).handleAction(actions.validateQuantityConstraintAsync.success, (state, action) => {
      if (action.payload && action.payload.productConstraintResults.length !== 0) {
        return {
          productConstraintResults: [
            {
              // Picking the first item to get the constraints on the Product
              product: action.payload.productConstraintResults[0].product,
              constraintResults: [
                {
                  maximumQuantity:
                    action.payload.productConstraintResults[0].constraintResults[0].maximumQuantity,
                  minimumQuantity:
                    action.payload.productConstraintResults[0].constraintResults[0].minimumQuantity,
                  purchasedQuantity:
                    action.payload.productConstraintResults[0].constraintResults[0]
                      .purchasedQuantity,
                  remainingQuantity:
                    action.payload.productConstraintResults[0].constraintResults[0]
                      .remainingQuantity,
                  constraintType:
                    action.payload.productConstraintResults[0].constraintResults[0].constraintType,
                  isValid: action.payload.productConstraintResults[0].constraintResults[0].isValid,
                  message: action.payload.productConstraintResults[0].constraintResults[0].message,
                },
              ],
            },
          ],
        };
      } else {
        return state;
      }
    }),
  }),
});

export default reducer;
