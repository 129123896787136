import { LinkButton, LinkEmail } from 'components/ions';
import { VideoPlayer } from 'components/molecules';
import i18next from 'i18next';
import React from 'react';
import { Trans, useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './New.styles';

type Props = WithTranslation & WithStyles<typeof styles>;

let newLookFeelVideo = (
  <VideoPlayer
    videoUrl={
      'https://msit.microsoftstream.com/embed/video/80a02488-0589-4768-93cf-d20aa616978e?autoplay=false&amp;showinfo=true'
    }
  />
);
let standardAmendmentVideo = (
  <VideoPlayer
    videoUrl={
      'https://msit.microsoftstream.com/embed/video/85eb3a59-db53-4e53-82c9-cba080d1960e?autoplay=false&amp;showinfo=true'
    }
  />
);
let endCustomerInvestmentFundingVideo = (
  <VideoPlayer
    videoUrl={
      'https://msit.microsoftstream.com/embed/video/6daa0770-1e29-48e7-b918-03f3ab772702?autoplay=false&amp;showinfo=true'
    }
  />
);
let financingTermsVideo = (
  <VideoPlayer
    videoUrl={
      'https://msit.microsoftstream.com/embed/video/d865413a-c8ca-47eb-bb83-15572b5bb312?autoplay=false&amp;showinfo=true'
    }
  />
);
let azureCommitmentToConsumeVideo = (
  <VideoPlayer
    videoUrl={
      'https://msit.microsoftstream.com/embed/video/2469db65-c3f7-4d27-89c7-068cb520be88?autoplay=false&amp;showinfo=true'
    }
  />
);
let improvedCustomerSetupVideo = (
  <VideoPlayer videoUrl="https://msit.microsoftstream.com/embed/video/cfa6f44d-fec5-460b-bb15-42d93e7c3015" />
);
let azurePrepaymentVideo = (
  <VideoPlayer
    videoUrl={
      'https://msit.microsoftstream.com/embed/video/79d40761-356b-4d0c-aa4d-f672e2f644a7?autoplay=false&amp;showinfo=true'
    }
  />
);
let captVideo = (
  <VideoPlayer
    videoUrl={
      'https://msit.microsoftstream.com/embed/video/22a7fe86-a534-4fc8-988d-f785b625c939?autoplay=false&amp;showinfo=true'
    }
  />
);
// TODO - jek - add work items for the newest release
const New: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = React.useState(false);
  const { classes } = props;
  var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <div className={classes.scroll}>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::APR')}</span>
              <span className={classes.dateIcon}>{(1).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2020, 3, 1, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>
              {t('help::Customer Affiliate Purchase Terms (CAPT)')}
            </h3>
            <p className={classes.paragraphs}>
              {t(
                'help::As of April 1st, customers can sign Customer Affiliate Purchase Terms. Review this video to view step-by-step instructions on how to create a billing account and quote for an Affiliate customer.'
              )}
              <br /> <span className={classes.workItem}>25216973</span>
              <div className={classes.videoPlayer}>{captVideo}</div>
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::FEB')}</span>
              <span className={classes.dateIcon}>{(17).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2020, 1, 17, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>
              {t('help::Finding a Billing Account by Sales Account')}
            </h3>
            <p className={classes.paragraphs}>
              <Trans ns="help">
                I want to use the same Billing Account as last time… we find it for you.
                <br />
                <span className={classes.listSubsectionTitle}>CRM Sales Account Association</span>
                <br />
                <br />
                We now remember which Billing Account was used the last time you created a quote and
                automatically add that billing account to the next Quote. You can still create
                another account or switch the account if you have multiple Billing Accounts.
              </Trans>
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::OCT')}</span>
              <span className={classes.dateIcon}>{(15).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2019, 9, 15, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::New look & feel')}</h3>
            <p className={classes.paragraphs}>
              {t(
                'help::Review this video to understand and navigate the new look and feel of Quote Center with an end to end workflow of creating a proposal for a customer.'
              )}
              <br /> <span className={classes.workItem}>21965386</span>
              <div className={classes.videoPlayer}>{newLookFeelVideo}</div>
            </p>
          </div>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Negotiated terms')}</h3>
            <p className={classes.paragraphs}>
              {t(
                'help::Review the following videos to understand how to add negotiated terms to your proposal.'
              )}
              <br />
              <div className={`${classes.listSubsections} listSubsection`}>
                <div>
                  <span className={classes.listSubsectionTitle}>
                    {t('help::1. Standard amendment: ')}
                  </span>
                  <span>
                    {t(
                      'help::view this video to understand how to add a standard term to your proposal.'
                    )}
                  </span>
                </div>
                <div className={classes.videoPlayer}>{standardAmendmentVideo}</div>
                <div>
                  <span className={classes.listSubsectionTitle}>
                    {t('help::2. End Customer Investment Funding: ')}
                  </span>
                  <span>
                    {t(
                      'help::view this video to understand how to add the end customer investment funding term to your proposal.'
                    )}
                  </span>
                </div>
                <div className={classes.videoPlayer}>{endCustomerInvestmentFundingVideo}</div>
                <div>
                  <span className={classes.listSubsectionTitle}>
                    {t('help::3. Financing terms: ')}
                  </span>
                  <span>
                    {t(
                      'help::view this video to understand how to add the financing term to your proposal.'
                    )}
                  </span>
                </div>
                <div className={classes.videoPlayer}>{financingTermsVideo}</div>
                <div>
                  <span className={classes.listSubsectionTitle}>
                    {t('help::4. Microsoft Azure Commitment to consume: ')}
                  </span>
                  <span>
                    {t(
                      'help::view this video to understand how to add the commit to consume term to your proposal.'
                    )}
                  </span>
                </div>
                <div className={classes.videoPlayer}>{azureCommitmentToConsumeVideo}</div>
              </div>
            </p>
          </div>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Improved Customer Setup')}</h3>
            <p className={classes.paragraphs}>
              {t(
                'help::Review the following videos to understand how to setup a billing account for your customer on the proposal.'
              )}
              <br />
              <div className={classes.videoPlayer}>{improvedCustomerSetupVideo}</div>
            </p>
          </div>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Azure prepayment')}</h3>
            <p className={classes.paragraphs}>
              {t(
                'help::Review the following videos to understand how to add Azure prepayment for your customer.'
              )}
              <br />
              <div className={classes.videoPlayer}>{azurePrepaymentVideo}</div>
            </p>
          </div>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Help & Support')}</h3>
            <p className={classes.paragraphs}>
              {t('help::For all your support needs, continue to email ')}
              <LinkEmail email="QCSupport@microsoft.com" />
              <br />
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::APR')}</span>
              <span className={classes.dateIcon}>{(18).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2019, 3, 18, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Deleting a Quote')}</h3>
            <p className={classes.paragraphs}>
              {t('help::Of course you want to be able to delete your quote… Now you can!')}
              <br />
              <span className={classes.workItem}>20860403</span>
            </p>
          </div>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Quote Approval')}</h3>
            <p className={classes.paragraphs}>
              {t(
                "help::Don't see an approver in the list, you can now manually enter an approval email address! If the entered approver meets the empowerment rules for the Quote, you are good to go."
              )}
              <br />
              <span className={classes.workItem}>20721171</span>
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::MAR')}</span>
              <span className={classes.dateIcon}>{(28).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2019, 2, 28, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>
              {t('help::Customer Work Account Guidance')}
            </h3>
            <p className={classes.paragraphs}>
              {t(
                'help::Quote Center now provides assistance by helping you determine if your customer has a tenant by providing you a place to enter your customer’s work account and determine if your customer exists on a tenant.'
              )}
              <br /> <span className={classes.workItem}>20468034</span>
            </p>
          </div>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>
              {t('help::Sales Account and Company Address')}
            </h3>
            <p className={classes.paragraphs}>
              {t(
                'help::Customer account addresses are established using information that already exists as part of the customer’s tenant. To avoid confusion between the sales account address and the actual address your customer will see, Quote Center now provides both addresses. Now you can confirm you are using the right sales account on the quote AND see the address your customer will see when they access the quote.'
              )}
              <br /> <span className={classes.workItem}>20809826</span>
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::MAR')}</span>
              <span className={classes.dateIcon}>{(26).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2019, 2, 26, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Fixes')}</h3>
            <p className={classes.paragraphs}>
              {t(
                "help::The cool thing about new browsers is that they update frequently. The challenging thing about new browsers is that they update frequently. The 'Discount card' was broken in the latest version of Chrome (73.0.3683.88, released late on March 25). We fixed it within the first few hours it was released. Humility is important, but we're a bit pleased with ourselves that we published the fix before any users were impacted. We do our best."
              )}
              <br /> <span className={classes.workItem}>20971913</span>
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::MAR')}</span>
              <span className={classes.dateIcon}>{(21).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2019, 2, 21, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Discounting')}</h3>
            <p className={classes.paragraphs}>
              {t(
                'help::Discount "Start from" date was unconstrained. We limited it to 3 months in the future. Forever is a long time.'
              )}
              <br /> <span className={classes.workItem}>20883114</span>
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::MAR')}</span>
              <span className={classes.dateIcon}>{(20).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2019, 2, 20, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Customer Research')}</h3>
            <p className={classes.paragraphs}>
              {t(
                'help::C &amp; AI has a handy Customer Portal with Azure data for customers. We added a link so you can go straight there from the Customer Research area.'
              )}
              <br /> <span className={classes.workItem}>20844730</span>
            </p>
          </div>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Fixes')}</h3>
            <p className={classes.paragraphs}>
              {t(
                'help::The "Seat subscription" list in the Customer Research had its own unique font. Unique is good for snowflakes, but not for this list.'
              )}
              <br /> <span className={classes.workItem}>20896712</span>
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      <div>
        <div className="date-icon">
          <div className={classes.dateIconBox}>
            <div>
              <span className={classes.dateIcon}>{t('help::MAR')}</span>
              <span className={classes.dateIcon}>{(19).toLocaleString(i18next.language)}</span>
            </div>
          </div>
          <h2 className={classes.sectionHeader}>
            {new Date(2019, 2, 19, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
          </h2>
        </div>
        <div className={classes.sections}>
          <div className={`${classes.subsections} subsection`}>
            <h3 className={classes.subsectionHeader}>{t('help::Fixes')}</h3>
            <p className={classes.paragraphs}>
              {t(
                'help::Clicking the "Edit Quote" button resulted in a warning that the quote would be withdrawn. While this was accurate, it was misleading. We changed the button label to "Withdraw", because we like consistency.'
              )}
              <br /> <span className={classes.workItem}>20620096</span>
            </p>
            <p className={classes.paragraphs}>
              {t(
                "help::Legacy quotes don't have a customer contact field. So it is confusing when we tell you the 'Customer contact is invalid' on legacy quotes. We don't do that anymore."
              )}
              <br /> <span className={classes.workItem}>20861957</span>
            </p>
          </div>
          <hr className={classes.divider} />
        </div>
      </div>
      {showMore ? (
        <div>
          <section>
            <div className="date-icon">
              <div className={classes.dateIconBox}>
                <div>
                  <span className={classes.dateIcon}>{t('help::MAR')}</span>
                  <span className={classes.dateIcon}>{(14).toLocaleString(i18next.language)}</span>
                </div>
              </div>
              <h2 className={classes.sectionHeader}>
                {new Date(2019, 2, 14, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
              </h2>
            </div>
            <div className={classes.sections}>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Fixes')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    'help::Our testers found some limited scenarios where the wrong expiration date was being displayed for a quote. We fixed it.'
                  )}
                  <br /> <span className={classes.workItem}>20762255</span>
                </p>
                <p className={classes.paragraphs}>
                  {t(
                    'help::Our testers were able to get a quote into a state where the error message indicated there were 2 rows with errors, but there was only 1 row on the quote. Counting is important, so we fixed the issue.'
                  )}
                  <br /> <span className={classes.workItem}>20620215</span>
                </p>
              </div>
              <hr className={classes.divider} />
            </div>
          </section>
          <section>
            <div className="date-icon">
              <div className={classes.dateIconBox}>
                <div>
                  <span className={classes.dateIcon}>{t('help::MAR')}</span>
                  <span className={classes.dateIcon}>{(13).toLocaleString(i18next.language)}</span>
                </div>
              </div>
              <h2 className={classes.sectionHeader}>
                {new Date(2019, 2, 13, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
              </h2>
            </div>
            <div className={classes.sections}>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Customer Experience')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    "help::Sellers really need to have as much visibility as possible into the customer's experience. We are now copying sellers when quotes are shared with customers via email."
                  )}
                  <br /> <span className={classes.workItem}>20723166</span>
                </p>
              </div>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Quote Details')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    'help::Sometimes a line item in a quote can become unavailable before the quote is published. When this happens, you should get a super clear message that explains what has happened. We added a super clear message.'
                  )}
                  <br /> <span className={classes.workItem}>20669202</span>
                </p>
                <p className={classes.paragraphs}>
                  {t(
                    'help::Earlier we added some descriptive details to the right-hand side bar. These details change with each row you select, or when you select no rows (the details are then specific to the whole quote, not just the row). We expanded the clickable region when deselecting a line so that it is easier to see the details for the whole quote.'
                  )}
                  <br /> <span className={classes.workItem}>20658764</span>
                </p>
                <p className={classes.paragraphs}>
                  {t(
                    'help::Nobody likes seeing a bunch of … (ellipses, such an interesting name for a bunch of dots…) - so we increased the width of the drop-down list for Meter selection.'
                  )}
                  <br /> <span className={classes.workItem}>20728233</span>
                </p>
                <p className={classes.paragraphs}>
                  {t(
                    'help::When setting "Good For" duration on discounts, users reported needing more flexibility. We have updated the choices to include 1-12 months (rather than multi-month increments).'
                  )}
                  <br /> <span className={classes.workItem}>20698099</span>
                </p>
              </div>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>Help</h3>
                <p className={classes.paragraphs}>
                  {t(
                    'help::It seems likely that when users access "Help" from a quote they are editing, they probably want some help in understanding something about that active quote. We are opening Help in a new tab now so that the quote you are editing stays open for you to come back to it easily after getting answers to your questions.'
                  )}
                  <br /> <span className={classes.workItem}>20620174</span>
                </p>
              </div>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Fixes')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    "help::It really doesn't make sense to add items to your quote from the 'Quick pane' (bar on the left) when the 'Info' tab is active. We've disabled the 'Quick pane' when the 'Details' tab is not active. Panes, tabs, active, disabled… Basically, it's better now!"
                  )}
                  <br /> <span className={classes.workItem}>20726032</span>
                </p>
              </div>
              <hr className={classes.divider} />
            </div>
          </section>
          <section>
            <div className="date-icon">
              <div className={classes.dateIconBox}>
                <div>
                  <span className={classes.dateIcon}>{t('help::MAR')}</span>
                  <span className={classes.dateIcon}>{(12).toLocaleString(i18next.language)}</span>
                </div>
              </div>
              <h2 className={classes.sectionHeader}>
                {new Date(2019, 2, 12, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
              </h2>
            </div>
            <div className={classes.sections}>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Helpful Warnings')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    "help::Our Azure friends have to do some work to make sure that customers are ready to move to modern. We have added a feature to warn users when they are creating a quote for a customer that is not in the addressable market. You'll know if the customer isn't ready for a modern quote and we will provide some additional guidance."
                  )}
                  <br />
                  <span className={classes.workItem}>20799587</span>
                </p>
              </div>
              <hr className={classes.divider} />
            </div>
          </section>
          <section>
            <div className="date-icon">
              <div className={classes.dateIconBox}>
                <div>
                  <span className={classes.dateIcon}>{t('help::MAR')}</span>
                  <span className={classes.dateIcon}>{(11).toLocaleString(i18next.language)}</span>
                </div>
              </div>
              <h2 className={classes.sectionHeader}>
                {new Date(2019, 2, 11, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
              </h2>
            </div>
            <div className={classes.sections}>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Quote Info')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    "help::It is important that sellers not make Microsoft employees the 'Customer contact' (this is meant to be the email of the customer that will be transacting the quote). We updated this input to prevent '@microsoft.com' email addresses and keep you on the happy path through the forest."
                  )}
                  <br /> <span className={classes.workItem}>20764874</span>
                </p>
                <p className={classes.paragraphs}>
                  {t(
                    'help::The validation message for "Enrollment number" was insufficiently precise. We added more words.'
                  )}
                  <br /> <span className={classes.workItem}>20619468</span>
                </p>
              </div>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Fixes')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    "help::When approval is not required for a quote, there shouldn't be any approvers - but there were - and now we remove them. Fixed!"
                  )}
                  <br /> <span className={classes.workItem}>20772958</span>
                </p>
              </div>
              <hr className={classes.divider} />
            </div>
          </section>
          <section>
            <div className="date-icon">
              <div className={classes.dateIconBox}>
                <div>
                  <span className={classes.dateIcon}>{t('help::MAR')}</span>
                  <span className={classes.dateIcon}>{(6).toLocaleString(i18next.language)}</span>
                </div>
              </div>
              <h2 className={classes.sectionHeader}>
                {new Date(2019, 2, 6, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
              </h2>
            </div>
            <div className={classes.sections}>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Fixes')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    "help::When filtering 'Purchase orders' by discount in Customer Research, some rows were inadvertently hidden. We don't have anything against these rows; we brought them back."
                  )}
                  <br />
                  <span className={classes.workItem}>20592947</span>
                </p>
              </div>
              <hr className={classes.divider} />
            </div>
          </section>
          <section>
            <div className="date-icon">
              <div className={classes.dateIconBox}>
                <div>
                  <span className={classes.dateIcon}>{t('help::MAR')}</span>
                  <span className={classes.dateIcon}>{(4).toLocaleString(i18next.language)}</span>
                </div>
              </div>
              <h2 className={classes.sectionHeader}>
                {new Date(2019, 2, 4, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
              </h2>
            </div>
            <div className={classes.sections}>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Discounting')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    'help::We received reports that changing the discount amount on a quote line item was clunky. We changed the behavior so the previous discount value will be selected automatically. This means you can just start typing away without any extra fiddly mouse clicks.'
                  )}
                  <br />
                  <span className={classes.workItem}>20620078</span>
                </p>
              </div>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Quote Info')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    'help::The label "Project description" was causing some confusion, so we changed it to just "Project".'
                  )}
                  <br />
                  <span className={classes.workItem}>20396373</span>
                </p>
              </div>
              <hr className={classes.divider} />
            </div>
          </section>
          <section>
            <div className="date-icon">
              <div className={classes.dateIconBox}>
                <div>
                  <span className={classes.dateIcon}>{t('help::MAR')}</span>
                  <span className={classes.dateIcon}>{(1).toLocaleString(i18next.language)}</span>
                </div>
              </div>
              <h2 className={classes.sectionHeader}>
                {new Date(2019, 2, 1, 0, 0).toLocaleDateString(i18next.language, dateOptions)}
              </h2>
            </div>
            <div className={classes.sections}>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Expired Quotes')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    'help::Users would like to see whether a quote is expired when viewing their list of quotes. Now you can!'
                  )}
                  <br />
                  <span className={classes.workItem}>20687505</span>
                </p>
              </div>
              <div className={`${classes.subsections} subsection`}>
                <h3 className={classes.subsectionHeader}>{t('help::Fixes')}</h3>
                <p className={classes.paragraphs}>
                  {t(
                    "help::My Quotes - Sometimes quotes with strange names don't sort as expected. We taught the list to sort properly."
                  )}
                  <br />
                  <span className={classes.workItem}>20619610</span>
                </p>
              </div>
              <hr className={classes.divider} />
            </div>
          </section>
        </div>
      ) : (
        <div className={classes.sections}>
          <LinkButton
            addClass={classes.showMoreLinks}
            displayText={t('help::load more')}
            size="medium"
            onClick={() => setShowMore(!showMore)}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation('help')(withStyles(styles)(New));
