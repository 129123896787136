import { CalloutAtom, CalloutAtomProps, CommandButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import { DirectionalHint, ICalloutContentStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import withStyles, { WithStyles } from 'react-jss';

import { calloutHeaderStyles } from './CalloutHeader.styles';

/**
 * Props for Callout use in the navigation bar
 * @prop {string} ariaLabel - Describes button's action
 * @prop {string} id - Identifies button's container
 * @prop {boolean} primaryColor - Use primary color defined in theme as background color
 * @prop {boolean} usesHotkeys - Whether the callout should open/close by using the hotkeys
 */
export interface CalloutHeaderProps {
  ariaLabel: string;
  calloutId?: string;
  buttonId?: string;
  iconName: string;
  id?: string;
  primaryColor?: boolean;
  children?: React.ReactNode;
  title?: string;
  onMouseUp?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  dataAutomationId?: string;
  isDemoMode?: boolean;
  text?: string;
  buttonCustomStyle?: string;
  calloutCustomStyle?: string;
  usesHotkeys?: boolean;
}

type Props = CalloutHeaderProps & WithStyles<typeof calloutHeaderStyles>;

const CalloutHeaderUnStyled: React.FC<Props> = (props: Props) => {
  const { usesHotkeys } = props;
  const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);

  useHotkeys(
    'shift+/',
    () => {
      if (usesHotkeys) {
        setIsCalloutVisible(!isCalloutVisible);
      }
    },
    [usesHotkeys, isCalloutVisible]
  );

  const headerButtonElement = React.useRef<HTMLDivElement>(null);

  const calloutStyles: Partial<ICalloutContentStyles> = {
    calloutMain: mergeClassNames([props.classes.calloutMain, props.calloutCustomStyle]),
    root: props.classes.root,
  };

  const handleButtonClick = () => {
    setIsCalloutVisible(!isCalloutVisible);
  };

  const handleDismissCallout = () => {
    setIsCalloutVisible(false);
  };

  const buttonStyle = isCalloutVisible ? props.classes.expanded : props.classes.command;
  const buttonPrimaryStyle = isCalloutVisible
    ? props.classes.primaryExpanded
    : props.classes.primary;
  const demoStyle = isCalloutVisible ? props.classes.primaryExpanded : props.classes.demo;

  const calloutProps: CalloutAtomProps = {
    alignTargetEdge: true,
    directionalHint: DirectionalHint.bottomRightEdge,
    id: props.calloutId,
    trapFocus: false,
    preventDismissOnLostFocus: false,
    setInitialFocus: true,
    isBeakVisible: false,
    hidden: !isCalloutVisible,
    onDismiss: handleDismissCallout,
    styles: calloutStyles,
    target: headerButtonElement.current,
    onMouseUp: props.onMouseUp,
  };
  const buttonTheme = props.isDemoMode
    ? mergeClassNames([demoStyle, props.classes.iconButton])
    : props.primaryColor
    ? mergeClassNames([buttonPrimaryStyle, props.classes.iconButton])
    : mergeClassNames([buttonStyle, props.classes.iconButton]);
  return (
    <>
      <div className={props.classes.buttonContainer} id={props.id} ref={headerButtonElement}>
        <CommandButtonAtom
          addClass={mergeClassNames([buttonTheme, props.buttonCustomStyle])}
          ariaLabel={props.ariaLabel}
          dataAutomationId={props.dataAutomationId}
          iconProps={{ iconName: props.iconName }}
          id={props.buttonId}
          text={props.text}
          title={props.title}
          onClick={handleButtonClick}
        />
      </div>
      <CalloutAtom {...calloutProps}>
        <div className={props.classes.calloutContentPadding}>{props.children}</div>
      </CalloutAtom>
    </>
  );
};

export const CalloutHeader = withStyles(calloutHeaderStyles)(CalloutHeaderUnStyled) as React.FC<
  CalloutHeaderProps
>;
