import React from 'react';
import { uploadFileStyles } from './UploadFile.styles';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { useTheme } from 'theming';
import { IProgressIndicatorStyles } from 'office-ui-fabric-react';

import {
  LargeIcon,
  TextBody,
  SecondaryButton,
  ButtonSharedProps,
  IconButton,
  ProgressIndicator,
} from 'components/ions';
import { Border } from 'components/molecules';
import { IconNames } from 'icons/src';
import { useTranslation } from 'react-i18next';
import { mergeClassNames } from 'components';

export enum UploadActionType {
  SET_DROP_DEPTH,
  SET_IN_DROP_ZONE,
  ADD_FILE_TO_LIST,
  DELETE_FILE_FROM_LIST,
}

export enum UploadError {
  None,
  UploadFailed,
  MaxSizeExceeded,
  IncorrectFileType,
}

export enum UploadState {
  Initial,
  Loading,
  Error,
  Complete,
}

export interface UploadAction {
  type?: UploadActionType;
  dropDepth?: number;
  inDropZone?: boolean;
  fileName?: string;
  fileURI?: string;
  fileType?: string;
  formData?: FormData;
}

export interface FileTypeInclusion {
  type:
    | 'application/pdf'
    | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  userInteraction: 'View' | 'Download';
}

export interface UploadFileProps {
  id: string;
  data: UploadAction;
  dispatch: (action: UploadAction) => void;
  enableFileSelection?: boolean;
  fileTypeInclusionList: FileTypeInclusion[];
  width: number;
  height: number;
  readOnly?: boolean;
  error?: UploadError;
  addClass?: string;
}

type Props = UploadFileProps & WithStyles<typeof uploadFileStyles>;

const UploadFileUnstyled: React.FC<Props> = ({
  addClass,
  classes,
  data,
  dispatch,
  enableFileSelection,
  error,
  fileTypeInclusionList,
  id,
  width,
  height,
  readOnly,
}: Props) => {
  const { t } = useTranslation();
  const canViewFileTypes = fileTypeInclusionList.filter(item => item.userInteraction === 'View');

  // #region states
  const initialUploadState = data.fileName
    ? error
      ? UploadState.Error
      : UploadState.Complete
    : UploadState.Initial;
  const [uploadState, setUploadState] = React.useState<UploadState>(initialUploadState);
  const [errorState, setErrorState] = React.useState<UploadError>(error || UploadError.None);
  const [canViewFile, setCanViewFile] = React.useState(
    canViewFileTypes.some(item => !!(data.fileType && data.fileType.includes(item.type)))
  );
  // #endregion

  const theme: ThemeProps = useTheme() as ThemeProps;
  const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress: classes.itemProgress,
    progressBar: classes.progressBar,
    progressTrack: classes.progressTrack,
  };

  let files: FileList;
  // #region dnd and file handlers
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    data.dropDepth &&
      dispatch({ type: UploadActionType.SET_DROP_DEPTH, dropDepth: data.dropDepth + 1 });
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    data.dropDepth &&
      dispatch({ type: UploadActionType.SET_DROP_DEPTH, dropDepth: data.dropDepth - 1 });
    dispatch({ type: UploadActionType.SET_IN_DROP_ZONE, inDropZone: false });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    e.dataTransfer.dropEffect = 'copy';
    dispatch({ type: UploadActionType.SET_IN_DROP_ZONE, inDropZone: true });
  };

  const handleUploadedFile = (files: FileList) => {
    if (files && files.length === 1) {
      let file = files[0];

      let reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          setUploadState(UploadState.Loading);
        },
        false
      );

      // #region error checking
      let isError = false;
      // upload error
      reader.addEventListener(
        'error',
        () => {
          setUploadState(UploadState.Error);
          setErrorState(UploadError.UploadFailed);
          isError = true;
        },
        false
      );

      // exceeded max file size of 5 MB error
      const MAX_FILE_SIZE = 5000000;
      if (file.size >= MAX_FILE_SIZE) {
        setUploadState(UploadState.Error);
        setErrorState(UploadError.MaxSizeExceeded);
        isError = true;
      }

      // incorrect file type error
      const isIncorrectType = !fileTypeInclusionList.some(fileType => file.type === fileType.type);

      if (isIncorrectType) {
        setUploadState(UploadState.Error);
        setErrorState(UploadError.IncorrectFileType);
        isError = true;
      }
      // #endregion
      if (!isError) {
        if (file) {
          reader.addEventListener(
            'loadend',
            () => {
              setUploadState(UploadState.Complete);
              setCanViewFile(canViewFileTypes.some(item => file.type === item.type));
            },
            false
          );
          reader.readAsDataURL(file);
        }
      }
      const formData = new FormData();
      formData.append('userfile', file, file.name);
      formData.append('name', 'SignedDocument');
      dispatch({
        type: UploadActionType.ADD_FILE_TO_LIST,
        fileName: file.name,
        fileURI: window.URL.createObjectURL(file),
        fileType: file.type,
        formData,
      });
      dispatch({ type: UploadActionType.SET_DROP_DEPTH, dropDepth: 0 });
      dispatch({ type: UploadActionType.SET_IN_DROP_ZONE, inDropZone: false });
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    files = event.dataTransfer.files;
    handleUploadedFile(files);
  };

  // #endregion

  const selectFile = () => {
    const iconTextProps: ButtonSharedProps = {
      text: t('quote::Select file'),
      ariaLabel: t('quote::Select file'),
      iconName: IconNames.OpenFile,
      onClick: () => {
        const input = document.getElementById('file');
        if (input) {
          input.onchange = e => {
            const target = e && (e.target as any);
            files = target.files;
            handleUploadedFile(files);
          };
          input.click();
        }
      },
    };
    return (
      <span>
        <TextBody addClass={classes.selectFile}>Or</TextBody>
        <SecondaryButton {...iconTextProps} />
        <form encType="multipart/form-data" id="UploadForm">
          <input id="file" name="SignedDocument" style={{ display: 'none' }} type="file" />
        </form>
      </span>
    );
  };

  // #region views

  const initialView = () => {
    const containerClassName = data.inDropZone ? classes.initialViewActive : classes.initialView;
    const contentClassName = data.inDropZone
      ? classes.initialContentActive
      : classes.initialContent;
    const textClassName = data.inDropZone ? classes.initialTextActive : classes.initialText;
    const text = data.inDropZone
      ? t('quote::Drop file here to upload')
      : t('quote::Drop a file here to attach');
    const icon = data.inDropZone ? (
      <LargeIcon addClass={classes.downIcon} iconName={IconNames.Down} />
    ) : (
      <LargeIcon addClass={classes.attachIcon} iconName={IconNames.Attach} />
    );
    return (
      <div
        className={containerClassName}
        id={id}
        onDragEnter={e => handleDragEnter(e)}
        onDragLeave={e => handleDragLeave(e)}
        onDragOver={e => handleDragOver(e)}
        onDrop={e => handleDrop(e)}
      >
        {!data.inDropZone && (
          <Border color={theme.palette.backgroundDivider} strokeWidth={1} x={width} y={height} />
        )}
        <span className={contentClassName}>
          {icon}
          <TextBody addClass={textClassName}>{text}</TextBody>
          {enableFileSelection && !data.inDropZone && selectFile()}
        </span>
      </div>
    );
  };

  const loadingView = (
    <div className={classes.loadingContainer}>
      <div className={classes.loadingView} id={id}>
        <LargeIcon addClass={classes.openFileIcon} iconName={IconNames.OpenFile} />
        <TextBody addClass={classes.loadingFileName} title={data.fileName}>
          {data.fileName}
        </TextBody>
      </div>
      <ProgressIndicator
        progressHidden={uploadState === UploadState.Loading}
        styles={progressIndicatorStyles}
      />
    </div>
  );

  const errorView = () => {
    let errorMessage = '';
    let errorIcon = '';
    if (errorState === UploadError.UploadFailed) {
      errorMessage = t('quote::Upload failed. Please retry');
      errorIcon = IconNames.Refresh;
    } else if (errorState === UploadError.MaxSizeExceeded) {
      errorMessage = t("quote::Please upload a file that doesn't exceed 5 MB");
      errorIcon = IconNames.Delete;
    } else if (errorState === UploadError.IncorrectFileType) {
      errorMessage = t('quote::Please upload a PDF or Word document');
      errorIcon = IconNames.Delete;
    }
    return (
      <div className={classes.errorView} id={id}>
        <LargeIcon addClass={classes.openFileIcon} iconName={IconNames.OpenFile} />
        <TextBody addClass={classes.errorFileName} title={data.fileName}>
          {data.fileName}
        </TextBody>
        <TextBody addClass={classes.errorMessage}>{errorMessage}</TextBody>
        <IconButton
          addClass={classes.rightActionIcon}
          ariaLabel={errorIcon}
          dataAutomationId={errorIcon}
          iconName={errorIcon}
          onClick={() => {
            if (errorIcon === IconNames.Refresh) {
              handleUploadedFile(files);
            } else {
              setUploadState(UploadState.Initial);
              dispatch({ type: UploadActionType.DELETE_FILE_FROM_LIST });
            }
          }}
        />
      </div>
    );
  };

  const onClick = () => {
    // we assign a name to the link's download to be able to preserve file name in the download
    const downloadLinkWithName = document.createElement('a');
    downloadLinkWithName.href = data.fileURI || '';
    downloadLinkWithName.download = data.fileName || '';
    canViewFile ? window.open(data.fileURI) : downloadLinkWithName.click();
  };

  const completeView = (
    <div className={mergeClassNames([addClass, classes.completeView])} id={id}>
      <LargeIcon addClass={classes.openFileIcon} iconName={IconNames.OpenFile} />
      <TextBody addClass={classes.completeFileName} title={data.fileName}>
        {data.fileName}
      </TextBody>
      <IconButton
        addClass={readOnly ? classes.rightActionIcon : classes.leftActionIcon}
        ariaLabel={canViewFile ? IconNames.View : IconNames.Download}
        dataAutomationId={canViewFile ? IconNames.View : IconNames.Download}
        iconName={canViewFile ? IconNames.View : IconNames.Download}
        id={canViewFile ? IconNames.View : IconNames.Download}
        onClick={onClick}
      />
      {!readOnly && (
        <IconButton
          addClass={classes.rightActionIcon}
          ariaLabel="Delete"
          dataAutomationId="Delete"
          iconName={IconNames.Delete}
          onClick={() => {
            setUploadState(UploadState.Initial);
            dispatch({ type: UploadActionType.DELETE_FILE_FROM_LIST });
          }}
        />
      )}
    </div>
  );
  // #endregion

  switch (uploadState) {
    case UploadState.Initial:
      return initialView();
    case UploadState.Complete:
      return completeView;
    case UploadState.Loading:
      return loadingView;
    case UploadState.Error:
      return errorView();
    default:
      return initialView();
  }
};

export const UploadFile = withStyles(uploadFileStyles)(UploadFileUnstyled);
