import {
  getDiscountDatesForRender,
  renderLabeledList,
} from 'features-apollo/quote/components/DetailsPane';
import { ExistingDiscountLabels } from 'features-apollo/quote/components/ExistingDiscountsList';
import * as React from 'react';

export interface CeilingDiscountDetailProps {
  startDate?: string;
  endDate?: string;
  priceGuaranteeDate?: string;
  labels: ExistingDiscountLabels;
  duration?: string;
  classes: Record<string, string>;
  discountPercent: string;
}

export const RenderCeilingDiscount: React.FunctionComponent<CeilingDiscountDetailProps> = props => {
  const {
    discountPercent,
    startDate,
    endDate,
    classes,
    priceGuaranteeDate,
    duration,
    labels,
  } = props;

  const datesToDisplay = getDiscountDatesForRender(startDate, endDate, duration);

  return (
    <div className={classes.flexCol}>
      {renderLabeledList(labels.discount, [discountPercent], classes)}
      {priceGuaranteeDate && renderLabeledList(labels.lock, [priceGuaranteeDate], classes)}
      {!!datesToDisplay.length && renderLabeledList(labels.duration, datesToDisplay, classes)}
    </div>
  );
};
