import { LinkButton } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';

import { openBillingContactDialog } from '../Dialogs';

/**
 * contactDialogProps is used over onClick if defined
 *
 * @interface BillingContactInformationProps
 */
interface AddBillingContactButtonProps {
  accountId: string;
  organizationId: string;
  readOnly?: boolean;
}

export const AddBillingContactButton: React.FC<AddBillingContactButtonProps> = props => {
  const { accountId, organizationId } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const openContactDialog = () =>
    openBillingContactDialog(context, {
      accountId,
      organizationId,
    });

  return (
    <LinkButton
      disabled={props.readOnly}
      displayText={t('quote::add contact information')}
      onClick={openContactDialog}
    />
  );
};
