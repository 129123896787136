import { TestHeaderConfig } from 'services/types';

export enum Environment {
  Prod = 'prod',
  PreProd = 'preProd',
  Int = 'int',
}

export interface AgreementConfig extends TestHeaderConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Prod]: 'https://agreement.l2o.microsoft.com/v1',
  [Environment.PreProd]: 'https://agreement.l2o.microsoft.com/v1',
  [Environment.Int]: 'https://agreement.int.l2o.microsoft.com/v1',
};

// Notes on Template IDs
// MCA & CAPT have 2 different TemplateId for production Agreements service
// 1 is for production agreements, the other is for test agreements in production
// int Agreements service uses different TemplateIds
// MCA Prod TemplateId = 9303e132-70b2-4a01-9b9b-605511b6283a
// MCA ProdTest TemplateId = e40cd0d3-89a1-4506-a382-51699fab25c9
// MCA INT TemplateId = 8d5ad5f3-5da0-401a-9f08-0a6810f9b11b
// CAPT Prod TemplateId = d78f889f-0927-4cee-af37-5af1cc2e289e
// CAPT ProdTest TemplateId = 4e6a16f9-a3b3-4bf2-aa66-6d91e45f14cc
// CAPT INT TemplateId = 02eb1b33-2c00-4bb1-b73d-2b9c18a92196

export const mcaTemplateIds = {
  [Environment.Prod]: '9303e132-70b2-4a01-9b9b-605511b6283a',
  [Environment.PreProd]: 'e40cd0d3-89a1-4506-a382-51699fab25c9',
  [Environment.Int]: '8d5ad5f3-5da0-401a-9f08-0a6810f9b11b',
};

export const aptTemplateIds = {
  [Environment.Prod]: 'd78f889f-0927-4cee-af37-5af1cc2e289e',
  [Environment.PreProd]: '4e6a16f9-a3b3-4bf2-aa66-6d91e45f14cc',
  [Environment.Int]: '02eb1b33-2c00-4bb1-b73d-2b9c18a92196',
};
