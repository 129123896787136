import { useEffect } from 'react';

export const useResizeAfterFirstRender = (shouldResizeAfterFirstRender?: boolean) => {
  useEffect(() => {
    if (shouldResizeAfterFirstRender) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 600);
      }, 10);
    }
  }, [shouldResizeAfterFirstRender]);
};
