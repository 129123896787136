import { sharedStyles as dialogStyles } from 'features/proposal/components/Dialogs/shared.styles';
import { sharedStyles, ThemeProps } from 'styles';

export const configCardConfirmationDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  ...dialogStyles(theme),

  closeButtonNoTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '23px 15px 3px 23px',
  },

  confirmationDialogBody: {
    alignItems: 'center',
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'left',
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
  },

  footer: {
    ...sharedStyles(theme).customDialogFooter,
    justifyContent: 'flex-end',
  },
});
