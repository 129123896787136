import { sharedStyles as dialogSharedStyles } from 'features/proposal/components/Dialogs/shared.styles';
import { sharedStyles as themeSharedStyles, ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  button: {
    alignSelf: 'center',
    width: 90,
  },
  input: {
    ...themeSharedStyles(theme).roundedCorners,
    backgroundColor: theme.palette.backgroundStandout,
    color: theme.palette.text,
    border: `1px ${theme.palette.backgroundDivider} solid`,
    flexGrow: 1,
    fontFamily: theme.fonts.fontFamily.fabricDefault,
    fontSize: theme.fonts.fontSizes.medium,
    marginRight: 8,
    minWidth: 180,
    padding: '5.8px 12px',
    '&:focus': {
      borderColor: theme.palette.backgroundDividerFocus,
    },
    '&:hover': {
      borderColor: theme.palette.backgroundDividerHover,
    },
  },
  inputContainer: {
    display: 'flex',
    marginTop: 24,
  },
  label: {
    fontFamily: theme.fonts.fontFamily.fabricDefault,
    fontSize: theme.fonts.fontSizes.medium,
  },
  semiBoldFontWeight: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
});
