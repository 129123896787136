import React from 'react';
import { useTranslation } from 'react-i18next';

import { InformationalTile } from './InformationalTile';

export const ErrorInfoTile: React.FC = () => {
  const { t } = useTranslation();

  return (
    <InformationalTile
      description={t(
        'quote::Sorry, we encountered an unknown error. If you need immediate support you can reach out to support using the "?" icon at the top right of your screen.'
      )}
      iconName="ErrorBadge"
      iconPredefinedStyle="error"
      title={t('quote::Error encountered')}
    />
  );
};
