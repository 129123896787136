import { TextBody, TextBodySmall, TextboxStandard } from 'components';
import { emailIsValid } from 'features/proposal/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

/**
 * This is the content that will show in Billing Content dialog and wizard.
 *
 * @interface BillingContactBodyContentProps
 */
interface BillingContactBodyContentProps {
  email?: string;
  phoneNumber?: string;
  setEmail: (data: { address: string | undefined; invalid?: boolean }) => void;
  setPhoneNumber: (phoneNumber: string | undefined) => void;
}

const styles = (theme: ThemeProps) => ({
  description: {
    margin: '0 0 24px',
  },
  fieldset: {
    width: 402,
  },
  phoneNumber: {
    marginTop: 20,
  },
  note: {
    color: theme.palette.textTertiary,
  },
});

type Props = BillingContactBodyContentProps & WithStyles<typeof styles>;

const BillingContactBodyContentUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string | undefined>(props.email);
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(props.phoneNumber);
  const [emailError, setEmailError] = React.useState<string | undefined>();

  const description = (
    <p className={props.classes.description}>
      <TextBody>
        {t(
          'quote::Please enter an administrative email and phone number for the billing account. This is required for Microsoft to contact the company in case of billing or support issues. Your customer will have the ability to update the information when they view the quote.'
        )}
      </TextBody>
    </p>
  );

  const validateEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newEmail?: string
  ) => {
    setEmail(newEmail);
    if (newEmail && !emailIsValid.test(newEmail)) {
      setEmailError(t('quote::Enter a valid email address'));
      props.setEmail({ address: newEmail, invalid: true });
    } else {
      setEmailError(undefined);
      props.setEmail({ address: newEmail, invalid: false });
    }
  };

  const emailField = (
    <TextboxStandard
      dataAutomationId="emailField"
      errorMessage={emailError}
      label={t('Email')}
      placeholder="alias@domain.com"
      required
      value={email}
      onChange={validateEmail}
    />
  );

  const onPhoneNumberChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newPhoneNumber?: string
  ) => {
    setPhoneNumber(newPhoneNumber);
    props.setPhoneNumber(newPhoneNumber);
  };

  const phoneNumberField = (
    <TextboxStandard
      addClass={props.classes.phoneNumber}
      dataAutomationId="phoneNumberField"
      label={t('Phone number')}
      placeholder={t('quote::country code + area code + phone number')}
      required
      value={phoneNumber}
      onChange={onPhoneNumberChange}
    />
  );

  const note = (
    <TextBodySmall addClass={props.classes.note}>
      {t(
        'quote::Please note, the system will not format the number, so please include spaces, (), and - as appropriate in order to make the number legible for others using it in the future. You may also include extension information if applicable; e.g. ext 1234.'
      )}
    </TextBodySmall>
  );

  return (
    <>
      {description}
      <div className={props.classes.fieldset}>
        {emailField}
        {phoneNumberField}
        {note}
      </div>
    </>
  );
};

export const BillingContactBodyContent = withStyles(styles)(BillingContactBodyContentUnstyled);
