import { CommandButtonAtom } from 'components/atoms';
import { ActionType } from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import { IButton } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { iconButtonStyles } from './IconButton.styles';

interface IconButtonProps {
  addClass?: string;
  ariaLabel?: string;
  dataAutomationId?: string;
  disabled?: boolean;
  iconName?: string;
  id?: string;
  text?: string;
  onClick?: (
    event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>,
    item?: IButton
  ) => boolean | void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

type Props = IconButtonProps & WithStyles<typeof iconButtonStyles>;
const IconButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <CommandButtonAtom
      addClass={mergeClassNames([props.classes.command, props.classes.icon, props.addClass])}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: props.iconName }}
      id={props.id}
      text={props.text}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    />
  );
};

const IconButtonErrorUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <CommandButtonAtom
      addClass={mergeClassNames([props.classes.command, props.classes.errorIcon, props.addClass])}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: props.iconName }}
      id={props.id}
      onClick={props.onClick}
    />
  );
};

export const IconButton = withStyles(iconButtonStyles)(IconButtonUnstyled) as React.FC<
  IconButtonProps
>;
export const IconButtonError = withStyles(iconButtonStyles)(IconButtonErrorUnstyled) as React.FC<
  IconButtonProps
>;
