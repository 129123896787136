import {
  PropertySheetContent,
  PropertySheetLayout,
} from 'features-apollo/quote/components/PropertySheets/Layout';
import { Audience, CustomerType, OrganizationSimple, QuoteMutationInput } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { CustomerLoading } from '../../states';
import { OrganizationInformationForJioCsp } from './OrganizationInformationForJioCsp/OrganizationInformationForJioCsp';
import { SelectPartnerForJioCsp } from './SelectPartnerForJioCsp';

export const customerForJioCspStyles = {
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25,
  },
};

export interface CustomerForJioCspProps {
  quoteMutationInput: QuoteMutationInput;
  audience: Audience;
  loading: boolean;
  organization?: OrganizationSimple;
  onRemove: (customerType: CustomerType) => void;
}

type Props = CustomerForJioCspProps & WithStyles<typeof customerForJioCspStyles>;

export const CustomerForJioCspUnstyled: React.FC<Props> = props => {
  const { quoteMutationInput, audience, organization, loading } = props;
  const { t } = useTranslation();
  let partnerContent;

  const loadingState = <CustomerLoading />;

  if (loading) {
    partnerContent = loadingState;
  } else {
    partnerContent = organization ? (
      <OrganizationInformationForJioCsp
        organization={organization}
        quoteMutationInput={quoteMutationInput}
        onRemove={() => props.onRemove(CustomerType.SoldToCustomer)}
      />
    ) : (
      <SelectPartnerForJioCsp disabled={false} quoteAudience={audience} />
    );
  }

  const partnerSection: PropertySheetContent | undefined = partnerContent && {
    leftPaneTitle: t('quote::Partner'),
    content: partnerContent,
  };

  return <PropertySheetLayout contentList={[partnerSection]} />;
};

export const CustomerForJioCsp = withStyles(customerForJioCspStyles)(CustomerForJioCspUnstyled);
