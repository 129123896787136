import { SecondaryButton, TextBodySmall, TextboxStandard } from 'components';
import { BodyProps, ErrorType } from 'features/proposal/components/Wizards/shared';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { accountNameDialogStyles } from './AccountNameDialog.styles';

export const AccountNameHeaderString = () => i18next.t('quote::Account name');

type AccountNameProps = BodyProps<string> & WithStyles<typeof accountNameDialogStyles>;

export const AccountNameBodyUnstyled: React.FC<AccountNameProps> = ({
  classes,
  initialData,
  onValid,
  onInvalid,
}) => {
  const { t } = useTranslation();
  const [accountName, setAccountName] = React.useState<string>(initialData || '');

  return (
    <div>
      <TextBodySmall addClass={classes.secondaryText}>
        {t(
          'quote::A customer Account may have multiple Billing Accounts. Since you are creating the first billing account, you need to give a name to the Account, all up. For example, if Microsoft Corporation was our customer, they might need different billing account for the different countries they operate in, e.g., Microsoft Japan, Microsoft India, etc. Collectively, you would call the Account, Microsoft.'
        )}
      </TextBodySmall>
      <TextboxStandard
        addClass={classes.nameStyles}
        dataAutomationId="accountName"
        label={t('quote::Account name')}
        required
        value={accountName}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value = newValue || '';
          setAccountName(value);
          if (value && value.trim()) {
            onValid(value);
          } else {
            onInvalid(ErrorType.missingInformation);
          }
        }}
      />
    </div>
  );
};

export const AccountNameBody = withStyles(accountNameDialogStyles)(AccountNameBodyUnstyled);

export const AccountNameFooterButton = (onClick: () => void, isValid: boolean) => {
  return [
    <SecondaryButton
      dataAutomationId="accountNameDialogNext"
      disabled={!isValid}
      key="next"
      text={i18next.t('quote::Next')}
      onClick={onClick}
    />,
  ];
};
