import cloneDeep from 'clone-deep';
import { loadProducts } from 'features/catalog/sagas/auxiliary';
import * as actions from 'features/proposal/actions';
import * as catalogActions from 'features/catalog/actions';
import { ProductFamily } from 'services/catalog/types';
import { getActiveProposal } from 'features/proposal/selectors';
import { getUserFavoriteProductNames } from 'features/user/selectors';
import {
  getCurrencies,
  getLanguages,
  getMarketByCountryCode,
} from 'features/proposal/supported-markets';
import { LegacyFootprint } from 'features/proposal/types';
import { buildProposalRequest } from 'features/proposal/utils';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { OrganizationSummary } from 'services/customer/types';
import { Proposal } from 'services/proposal/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

import { loadApproval, updateQuote } from './auxiliary';

export function* applyBulkDiscount() {
  const relevantAction = actions.bulkDiscountApply;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    yield put(actions.bulkUpdateDiscountOnProposalWithDFD(action.payload));
    yield call(loadProducts, action.payload.productIds);
  });
}

export function* applyDiscount() {
  const relevantAction = actions.discountApply;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const arr = [action.payload.id];
    yield put(actions.updateDiscountOnProposalWithDFD(action.payload));
    yield call(loadProducts, arr);
  });
}

export function* loadSharedDiscountProducts() {
  const relevantAction = actions.loadSharedDiscountProducts;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    yield call(loadProducts, action.payload);
  });
}

export function* loadAssetProducts() {
  const relevantAction = actions.loadAssetProducts;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    yield call(loadProducts, action.payload);
  });
}

export function* convertToLegacy() {
  const relevantAction = actions.convertToLegacyAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      yield put(actions.loadAndHydrateAllQuoteWithFavorites.request());
      const currentQuote: Proposal = yield select(getActiveProposal);
      if (currentQuote.lineItems && currentQuote.lineItems.length) {
        yield put(
          actions.convertToLegacyAsync.failure({
            message: t('error::Quote has line items, cannot convert quote to legacy.'),
            exception: new Error(),
          })
        );
        return;
      }

      const footprint: LegacyFootprint | null = yield select(
        (state: RootState) => state.customer.legacyFootprint[action.payload]
      );
      const organization: OrganizationSummary | null = yield select(
        (state: RootState) => footprint && state.customer.organization[footprint.organizationId]
      );
      if (!footprint || !organization) {
        yield put(
          actions.convertToLegacyAsync.failure({
            message: t('error::Invalid state. Cannot convert quote to legacy.'),
            exception: new Error(),
          })
        );
        return;
      }
      const quote: Proposal = cloneDeep(currentQuote);
      quote.header.extendedProperties = {
        ...quote.header.extendedProperties,
        vlAgreementNumber: footprint.enrollment,
        userPreferences: {
          agreementType: 'legacy',
          nationalCloud: 'Global',
          recipientId: footprint.recipientId,
        },
      };
      quote.header.billTo = {
        billingGroupId: footprint.billingGroupId,
        accountId: footprint.accountId,
        isOnline: false,
      };
      quote.header.soldToCustomerLegalEntity = quote.header.endCustomerLegalEntity = {
        accountId: organization.accountId,
        organizationId: organization.id,
        organizationVersion: organization.version,
      };
      const market = getMarketByCountryCode(organization.legalEntity.address.country);
      if (market && market !== quote.header.pricingContext.market) {
        quote.header.pricingContext.market = market;
        quote.header.pricingContext.billingCurrency = getCurrencies(market)[0];
        quote.header.pricingContext.languages = getLanguages(market)[0];
      }
      yield call(updateQuote, buildProposalRequest(quote));
      yield put(actions.convertToLegacyAsync.success());
      const productNames = yield select(getUserFavoriteProductNames);
      const filterFavoriteProductsRequest = {
        productNames,
        productFamilyNames: [ProductFamily.Azure],
      };
      yield put(
        catalogActions.filterFavoriteProductsForLegacyAsync.request(filterFavoriteProductsRequest)
      );
    } catch (err) {
      yield put(
        actions.convertToLegacyAsync.failure({
          message: t('error::Error converting quote to legacy.'),
          exception: err,
        })
      );
    }
  });
}

export function* getLatestApproval() {
  const relevantAction = actions.refreshApproval;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const approvalId = action.payload;
    yield call(loadApproval, approvalId);
  });
}
