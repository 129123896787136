import * as React from 'react';
import { Dialog, PrimaryButton, mergeClassNames, TextboxStandard, ActionButton } from 'components';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { SaveNpsSurveyResponseMutation, SaveNpsSurveyDeclinedMutation } from './queries';
import loggerService from 'services/logger-service';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { getUser } from 'features-apollo/user/selectors';
import { DialogContext } from 'styles';

const styles = (theme: ThemeProps) => ({
  scoreRow: {
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: 'repeat(11, 1fr)',
  },
  scoreCell: {
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.primary,
      color: theme.palette.lightColor,
    },
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: 2,
    boxSizing: 'border-box',
    color: theme.palette.primary,
    display: 'flex',
    flex: 1,
    fontSize: theme.fonts.fontSizes.xLarge,
    fontWeight: 600,
    height: 48,
    justifyContent: 'center',
    paddingBottom: 2,
  },
  scoreSelected: {
    background: theme.palette.primary,
    color: theme.palette.lightColor,
  },
  likelihoodRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 8,
    color: theme.palette.text,
    fontSize: 14,
  },
  comments: {
    '& textarea': {
      '&::placeholder': {
        color: theme.palette.textTertiary,
      },
      resize: 'none',
      height: 112,
    },
  },
  commentsHeader: {
    color: theme.palette.text,
    fontSize: 14,
    fontWeight: 600,
    marginTop: 32,
    marginBottom: 6,
  },
  errorMessage: {
    marginTop: 8,
    color: theme.palette.dangerActive,
    fontSize: 14,
  },
});

type Props = WithStyles<typeof styles>;

const commentMaxLength = 8000;

const NetPromoterScoreUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  const context = React.useContext(DialogContext);
  const userId = useSelector((state: RootState) => getUser(state).oid);

  const [selectedScore, setSelectedScore] = React.useState<number | null>(null);
  const [commentText, setCommentText] = React.useState<string>();

  const [saveNpsSurveyResponse] = useMutation(SaveNpsSurveyResponseMutation, {
    onCompleted: () => loggerService.log({ name: 'saveNpsSurveyResponse succeeded' }, { userId }),
    onError: () => loggerService.log({ name: 'saveNpsSurveyResponse failed' }, { userId }),
  });

  const [saveNpsSurveyDeclined] = useMutation(SaveNpsSurveyDeclinedMutation, {
    onCompleted: () => loggerService.log({ name: 'saveNpsSurveyDeclined succeeded' }, { userId }),
    onError: () => loggerService.log({ name: 'saveNpsSurveyDeclined failed' }, { userId }),
  });

  const saveResponse = () => {
    const scoreAndComment = {
      score: selectedScore,
      ...(commentText && { comments: commentText.trim() }),
    };

    saveNpsSurveyResponse({
      variables: {
        input: scoreAndComment,
      },
    });

    //log the survey response directly to app insights as a backup in case gql fails
    loggerService.log(
      { name: 'npsSurveyResponse backup' },
      {
        userId,
        ...scoreAndComment,
      }
    );

    context.closeDialog();
  };

  const scores = [...Array(11).keys()]; //0 through 10
  const isCommentLengthInvalid = !!(commentText && commentText.length > commentMaxLength);

  return (
    <Dialog
      closeDialog={() => {
        saveNpsSurveyDeclined();
        context.closeDialog();
      }}
      footerButtons={
        <PrimaryButton
          dataAutomationId="npsSurveySubmitButton"
          disabled={selectedScore === null || isCommentLengthInvalid}
          text={t(`common::Submit`)}
          onClick={saveResponse}
        />
      }
      height={412}
      title={t(`common::How likely are you to recommend Quote Center to a co-worker?`)}
      width={680}
    >
      <div className={classes.scoreRow}>
        {scores.map(score => (
          <ActionButton
            key={score}
            mainClass={
              score === selectedScore
                ? mergeClassNames([classes.scoreCell, classes.scoreSelected])
                : classes.scoreCell
            }
            onClick={() => setSelectedScore(score)}
          >
            {score}
          </ActionButton>
        ))}
      </div>
      <div className={classes.likelihoodRow}>
        <div>{t(`common::Not very likely`)}</div>
        <div>{t(`common::Extremely likely`)}</div>
      </div>
      <div className={classes.commentsHeader}>Comments</div>
      <TextboxStandard
        addClass={classes.comments}
        maxLength={commentMaxLength + 1}
        multiline
        placeholder={t(`common::It's optional but we'd love to hear more about your experience.`)}
        onChange={(e, value) => setCommentText(value)}
      />
      {isCommentLengthInvalid && (
        <div className={classes.errorMessage}>
          <Trans ns="common">Maximum limit of {{ commentMaxLength }} characters.</Trans>
        </div>
      )}
    </Dialog>
  );
};

export const NetPromoterScore = withStyles(styles)(NetPromoterScoreUnstyled);
