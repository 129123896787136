import { sharedStyles, ThemeProps } from 'styles';

import { sharedStyles as dialogSharedStyles } from '../shared.styles';

export const LegacyDialogStyles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  ...sharedStyles,
  enrollmentBody: {
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  enrollmentInput: {
    width: 244,
  },
  verifyButton: {
    paddingTop: 30,
  },
  viewBody: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputContainer: {
    paddingTop: 16,
    width: 350,
  },
  information: {
    color: theme.palette.textTertiary,
  },
  newline: {
    display: 'block',
  },
});
