import { AddButton, CalloutCard, LinkExternal, Spinner, TextBody } from 'components/ions';
import { dateFormat } from 'components/utilities/dates';
import { getFlightIsEnabled } from 'features/app/selectors';
import {
  addSkuTitle,
  closeConfigCard,
  createProposalLineItemsAsync,
  loadPricesAsync,
  updateAddedLineItemCount,
  updateAndCreateProposalLineItems,
  updateProposalAsync,
} from 'features/proposal/actions';
import { ConfigCardDropdownOptions } from 'features/proposal/components/ConfigCard';
import {
  getActiveProposal,
  getEnrollment,
  getEnrollmentNumber,
  getLineItemPrices,
  getLineItemsSwitchingDFD,
  getSelectedProject,
  getSkuTitles,
  lineItemCreationLoading,
} from 'features/proposal/selectors';
import { PriceMap, SkuTitleMap } from 'features/proposal/types';
import {
  convertDateToUTC,
  isAgreementTypeLegacy,
  isProposalReadOnly,
} from 'features/proposal/utils';
import i18next from 'i18next';
import { ProductFilter, ProductFilterContext } from 'microsoft-commerce-product-filters';
import moment from 'moment-timezone';
import {
  DirectionalHint,
  IColumn,
  IComboBox,
  IComboBoxOption,
  IDropdownOption,
  ISelectableOption,
  SpinnerSize,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { endpoints as proposalServiceEndpoints, supportOfferUrl } from 'services/proposal/config';
import {
  LineItem,
  LineItemUserPreferences,
  Proposal,
  ProposalPricingRequest,
  ProposalUpdateRequest,
} from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext, DialogProps, ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import * as ConfigCardActions from './ConfigCard.actions';
import {
  applyButtonEnabled,
  applyConfiguration,
  buildBillingPlanDropdownOptions,
  buildColumnTitles,
  buildDurationDropdownOptions,
  buildLocationDropdownOptions,
  buildSkuRows,
  buildTermDropdownOptions,
  buildWatermarkMessage,
  filterOnDurations,
  filterOnLocations,
  getPriceFromSelection,
  getTermsForAllQualifyingSkus,
  hasLocationFilter,
  hasTermDescriptionFilter,
  isConsumableProduct,
  isNegotiatedTerm,
  isPasses,
  isSupportOffer,
  isUnfilterableProduct,
  isValidSelectedTerm,
  maxSkuAvailabilitiesLength,
  maxSkuAvailabilitiesTermsLength,
  productContainsBillingPlans,
  removeInvalidSaaSAvailabilities,
  showAutoRenewOption,
  showDurationDropdown,
  showLocationDropdown,
  showMessage,
  showTermsDropdown,
  showWatermark,
} from './ConfigCardBusinessLogic';
import { ConfigCardConfirmationDialog } from './ConfigCardConfirmationDialog';
import { addConfiguration } from './ConfigCardMultipleAdd';
import {
  ConfigCardBody,
  ConfigCardBodyProps,
  ConfigCardMainBodyContent,
} from './ConfigCardSections/Body/ConfigCardBody';
import {
  ConfigCardHeader,
  ConfigCardHeaderProps,
} from './ConfigCardSections/Header/ConfigCardHeader';
import { ConfigCardBusinessLogicState, reducer } from './reducer';
import {
  ConfigCardProps,
  CreateLineItemProperties,
  HydratedProduct,
  ProductAndQualifyingSkusTitles,
  QualifyingSkuAvailability,
  SkuRow,
  Term,
} from './types';

const standardSupportLinkRightMargin = {
  marginRight: 10,
};

const styles = (theme: ThemeProps) => {
  return {
    createGroupButton: {
      color: theme.palette.textLink,
      '& i': {
        color: theme.palette.textLink,
      },
      '&:hover, &:hover i, &:focus, &:focus i': {
        color: theme.palette.textLinkHover,
      },
    },
  };
};

export interface ConfigCardBusinessLogicProps {
  hydratedProduct: Product;
  activeLineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  minWidth?: number;
  maxWidth?: number;
}

export const mapStateToProps = (state: RootState) => {
  const proposal = getActiveProposal(state);
  return {
    proposal,
    enrollment: getEnrollment(state),
    enrollmentNumber: getEnrollmentNumber(state),
    modernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
    prices: getLineItemPrices(state),
    isLegacyQuote: isAgreementTypeLegacy(proposal),
    lineItems: getLineItemsSwitchingDFD(state),
    isLineItemCreationLoading: lineItemCreationLoading(state),
    skuTitles: getSkuTitles(state),
    proposalServiceEndpoint: proposalServiceEndpoints[state.app.appConfig.proposal.environment],
    selectedProject: getSelectedProject(state),
    readOnly: isProposalReadOnly(proposal),
    allowUnspecifiedOptionInLocationFilters: getFlightIsEnabled(
      state,
      Flight.allowUnspecifiedOptionInLocationFilters
    ),
  };
};

const dispatchProps = {
  loadPrice: (proposalPricingRequest: ProposalPricingRequest) => {
    if (proposalPricingRequest) {
      return loadPricesAsync.request(proposalPricingRequest);
    }
  },
  onDismiss: closeConfigCard,

  updateProposal: (proposalRequest: ProposalUpdateRequest) => {
    return updateProposalAsync.request(proposalRequest);
  },

  addSkuTitles: (qualifyingSkuAvailabilities: QualifyingSkuAvailability[], productId: string) => {
    const newSkuAvailProduct: ProductAndQualifyingSkusTitles = {
      QualifyingSkuAvailabilities: qualifyingSkuAvailabilities.slice(),
      ProductId: productId,
    };
    return addSkuTitle(newSkuAvailProduct);
  },

  addLineItem: createProposalLineItemsAsync.request,

  createLineItemsFromMultiRegionalSelection: updateAndCreateProposalLineItems.request,

  updateAddedLineItemCount: updateAddedLineItemCount.request,
};

type Props = ConfigCardBusinessLogicProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

export const generateConfigCardHeaderProps = (
  hydratedProduct: Product,
  filters: ProductFilter[],
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  onLocationSelect: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => void,
  selectMultipleLocations: (selectedOptions: IDropdownOption[]) => void,
  onDurationSelect: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => void,
  selectedDurationKey: string,
  selectedLocationKey: string,
  selectedLocationKeys: string[],
  isLegacyQuote: boolean,
  allowUnspecifiedOptionInLocationFilters: boolean
): ConfigCardHeaderProps => {
  const durationChoices = buildDurationDropdownOptions(
    filters,
    hydratedProduct,
    qualifyingSkuAvailabilities,
    isLegacyQuote
  );
  const durationOptions = {
    options: durationChoices,
    initialSelectedKey: selectedDurationKey,
    readOnly: durationChoices.length === 1,
    disabled: durationChoices.length === 0,
  };
  const locationChoices: ISelectableOption[] = showLocationDropdown(filters)
    ? buildLocationDropdownOptions(filters)
    : [];

  const locationDropdownOptions: ConfigCardDropdownOptions = {
    options: locationChoices,
    initialSelectedKey: selectedLocationKey,
    selectedKeys: selectedLocationKeys,
    readOnly: locationChoices.length === 1,
    disabled: locationChoices.length === 0,
  };
  return {
    onDurationSelection: onDurationSelect,
    selectMultipleLocations,
    onLocationSelection: onLocationSelect,
    durationDropdownOptions: durationOptions,
    locationDropdownOptions,
    showDuration: showDurationDropdown(
      filters,
      hydratedProduct,
      qualifyingSkuAvailabilities,
      isLegacyQuote
    ),
    showLocation: showLocationDropdown(filters),
    allowUnspecifiedOptionInLocationFilters,
  };
};

/**
 * Creates the default options for the "Starting" field to set a start date.
 * Returns default options if the product is not a "Passes" product.
 * @param {boolean} isPasses - whether the productFamily === "Passes" for the lineItem.
 */
export const getDefaultStartDateOptions = (
  isPasses: boolean,
  lineItem: LineItem
): ConfigCardDropdownOptions => {
  let defaultOptions: ConfigCardDropdownOptions = {
    options: [{ text: i18next.t('quote::At order placement'), key: 'order placement' }],
    initialSelectedKey: 'order placement',
  };
  if (!isPasses) {
    return defaultOptions;
  }
  //TODO: cameneks,jek this part can be simplified we don't need the choice options variable and we prob can rename default options -> options and use that
  let choiceOptions = defaultOptions.options;
  let selectedDropdownKey = defaultOptions.initialSelectedKey;
  const lineItemStartDate = oc(lineItem).purchaseInstruction.termStartDate();
  if (lineItemStartDate) {
    selectedDropdownKey = moment.utc(lineItemStartDate).format(dateFormat);

    const enrollmentStartDate: IComboBoxOption = {
      text: selectedDropdownKey,
      key: selectedDropdownKey,
    };
    choiceOptions = choiceOptions.concat(enrollmentStartDate);
    defaultOptions.options = choiceOptions;
    defaultOptions.initialSelectedKey = selectedDropdownKey;
  }
  return defaultOptions;
};

export interface DispatchMethods {
  onClearSelect: () => void;
  onRecurringCheckboxToggle: () => void;
  onStartDateAdded: (date: Date) => void;
  onStartSelectionChanged: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  onBillingPlanSelection: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  onListSelect: (key: string, skuRow: SkuRow) => void;
  onTermSelection: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
}

export const generateConfigCardBodyProps = (
  props: Props,
  state: ConfigCardBusinessLogicState,
  dispatchMethods: DispatchMethods,
  filters: ProductFilter[],
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  terms: Term[],
  message: JSX.Element,
  activeLineItem: LineItem
): ConfigCardBodyProps => {
  const {
    enrollment,
    proposal,
    modernOfficeEnabled,
    enrollmentNumber,
    hydratedProduct,
    loadPrice,
    lineItems,
  } = props;
  const {
    selectedListKey,
    selectedTermsKey,
    selectedLocationKey,
    selectedDurationKey,
    startDateOptions,
    selectedStartDateKey,
    selectedTermsDurationKey,
    recurringCheckEnabled,
    createLineItems,
  } = state;
  const {
    onClearSelect,
    onRecurringCheckboxToggle,
    onStartDateAdded,
    onStartSelectionChanged,
    onBillingPlanSelection,
    onListSelect,
    onTermSelection,
  } = dispatchMethods;
  const multipleLocationSelected = !!createLineItems.length;
  const pricingContext = proposal.header.pricingContext;
  const prices: PriceMap = props.prices || {};
  if (!lineItems) {
    const e = 'We dont have line Items but we are rendering ConfigCard';
    const error = new Error(e);
    throw error;
  }
  const mainBodyContent = showWatermark(
    filters,
    selectedLocationKey,
    selectedDurationKey,
    qualifyingSkuAvailabilities,
    hydratedProduct,
    multipleLocationSelected
  )
    ? ConfigCardMainBodyContent.WATERMARK_MESSAGE
    : ConfigCardMainBodyContent.LIST;
  const showTerms = showTermsDropdown(
    isSupportOffer(hydratedProduct),
    isUnfilterableProduct(filters, qualifyingSkuAvailabilities, hydratedProduct),
    terms
  );
  const columns: IColumn[] = buildColumnTitles(
    hydratedProduct,
    hasTermDescriptionFilter(filters),
    isConsumableProduct(filters),
    maxSkuAvailabilitiesLength(qualifyingSkuAvailabilities, hydratedProduct),
    maxSkuAvailabilitiesTermsLength(qualifyingSkuAvailabilities, hydratedProduct),
    qualifyingSkuAvailabilities,
    oc(pricingContext).billingCurrency('USD'),
    selectedDurationKey
  );
  const listItems =
    mainBodyContent === ConfigCardMainBodyContent.LIST
      ? buildSkuRows(
          hydratedProduct,
          qualifyingSkuAvailabilities,
          columns,
          loadPrice,
          prices,
          lineItems,
          pricingContext,
          selectedTermsKey
        )
      : [];
  const termsDropdownChoiceOptions = showTerms
    ? buildTermDropdownOptions(qualifyingSkuAvailabilities, hydratedProduct, selectedListKey)
    : [];

  const billingPlanKey = selectedTermsKey;
  const billingPlanOptions = buildBillingPlanDropdownOptions(
    qualifyingSkuAvailabilities,
    hydratedProduct,
    selectedListKey,
    selectedTermsDurationKey
  );
  const startDateChoiceOptions = getDefaultStartDateOptions(
    isPasses(hydratedProduct),
    activeLineItem
  );

  let minStartDate = convertDateToUTC(new Date());
  if (isPasses(hydratedProduct) && enrollmentNumber) {
    const enrollmentContents = enrollment[enrollmentNumber];
    if (enrollmentContents) {
      const startDate = moment
        .utc(enrollmentContents.endDate)
        .add('1', 'minutes')
        .toDate();
      minStartDate = convertDateToUTC(startDate);
    }
  }

  const createdLineItemLocations: string[] = createLineItems.map(item => item.location);
  const watermarkMessageLocations: string[] = [
    state.selectedLocationKey,
    ...createdLineItemLocations,
  ];

  return {
    mainBodyContentChoice: mainBodyContent,
    message,
    price: getPriceFromSelection(
      qualifyingSkuAvailabilities,
      prices,
      selectedListKey,
      selectedTermsKey
    ),
    billingPlanDropdownOptions: {
      options: billingPlanOptions,
      initialSelectedKey: billingPlanKey,
      readOnly: billingPlanOptions.length === 1,
    },
    isNegotiatedTerm: isNegotiatedTerm(hydratedProduct),
    isPasses: isPasses(hydratedProduct),
    showMessage: showMessage(
      filters,
      qualifyingSkuAvailabilities,
      hydratedProduct,
      qualifyingSkuAvailabilities.length,
      selectedLocationKey,
      selectedDurationKey,
      selectedListKey
    ),
    currency: oc(pricingContext).billingCurrency('USD'),
    recurringBillingEnabled: recurringCheckEnabled,
    showRecurringBilling: showAutoRenewOption(qualifyingSkuAvailabilities),
    modernOfficeEnabled,
    onClearListSelection: onClearSelect,
    columns,
    listItems,
    createLineItems,
    initialSelectedListKey: selectedListKey,
    onRecurringCheckboxToggle,
    showTerms,
    onListSelection: onListSelect,
    onTermSelection: onTermSelection,
    onStartDateAdded,
    onStartSelectionChanged,
    onBillingPlanSelection,
    termsDropdownOptions: {
      options: termsDropdownChoiceOptions,
      disabled: termsDropdownChoiceOptions.length === 0 || !selectedListKey,
      readOnly: termsDropdownChoiceOptions.length === 1,
      initialSelectedKey: selectedTermsDurationKey,
    },
    startDateDropdownOptions: {
      options: startDateOptions || startDateChoiceOptions.options,
      initialSelectedKey: selectedStartDateKey || startDateChoiceOptions.initialSelectedKey,
    },
    productHasBillingPlans: productContainsBillingPlans(
      qualifyingSkuAvailabilities,
      hydratedProduct
    ),
    defaultMinStartDate: minStartDate,
    watermarkMessage: buildWatermarkMessage(
      qualifyingSkuAvailabilities,
      hydratedProduct,
      watermarkMessageLocations
    ),
    enrollmentNumber,
    disableSelectionZone: props.readOnly,
  };
};

export const generateConfigCardProps = (
  hydratedProduct: HydratedProduct,
  filters: ProductFilter[],
  lineItem: LineItem,
  headerProps: ConfigCardHeaderProps,
  bodyProps: ConfigCardBodyProps,
  isReadOnly: boolean,
  selectedLocation: string,
  selectedDuration: string,
  target: React.RefObject<HTMLSpanElement>,
  onApplyConfiguration: () => void,
  onAddConfiguration: () => void,
  onDismiss: () => void,
  selectedListKey?: string,
  hidden?: boolean,
  minWidth?: number,
  maxWidth?: number,
  allowUnspecifiedOptionInLocationFilters?: boolean,
  isLineItemCreationLoading?: boolean
): ConfigCardProps => {
  const firstLocalizedProperty = hydratedProduct.LocalizedProperties[0];
  const configCardProps: ConfigCardProps = {
    lineItemId: lineItem.id,
    applyButtonEnabled:
      !isReadOnly &&
      applyButtonEnabled(
        filters,
        selectedDuration,
        selectedLocation,
        selectedListKey,
        allowUnspecifiedOptionInLocationFilters
      ),
    target,
    headerProps,
    bodyProps,
    headerText: firstLocalizedProperty.ProductTitle,
    onApply: onApplyConfiguration,
    onAddConfiguration,
    onDismiss,
    hidden,
    minWidth,
    maxWidth,
    isANegotiatedTerm: isNegotiatedTerm(hydratedProduct),
    isLineItemCreationLoading,
  };
  return configCardProps;
};

export const generateInitialState = (initial: {
  lineItem: LineItem;
  filters: ProductFilter[];
  hydratedProduct: Product;
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[];
  addSkuTitles: (
    qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
    productId: string
  ) => ReturnType<typeof addSkuTitle>;
  createLineItems: CreateLineItemProperties[];
  isLegacyQuote: boolean;
  userPreferences?: LineItemUserPreferences;
  skuTitles?: SkuTitleMap;
}): ConfigCardBusinessLogicState => {
  const {
    hydratedProduct,
    qualifyingSkuAvailabilities,
    addSkuTitles,
    createLineItems,
    userPreferences,
    skuTitles,
    filters,
    lineItem,
    isLegacyQuote,
  } = initial;
  if (skuTitles && !skuTitles[hydratedProduct.ProductId]) {
    addSkuTitles(qualifyingSkuAvailabilities, hydratedProduct.ProductId);
  }

  const durationDropdownOptions = buildDurationDropdownOptions(
    filters,
    hydratedProduct,
    qualifyingSkuAvailabilities,
    isLegacyQuote
  );

  const hasPayGo = durationDropdownOptions.some(option => option.key === 'Pay go');
  const firstDurationKey =
    (durationDropdownOptions.length && (durationDropdownOptions[0].key as string)) || '';
  const defaultDurationKey = hasPayGo ? 'Pay go' : firstDurationKey;
  const validSaasProducts = removeInvalidSaaSAvailabilities(
    qualifyingSkuAvailabilities,
    hydratedProduct
  );
  const numberOfAvailabilities = maxSkuAvailabilitiesLength(validSaasProducts, hydratedProduct);
  const terms = buildTermDropdownOptions(validSaasProducts, hydratedProduct);
  const defaultTermKey = terms && terms.length ? terms[0].key.toString() : '';

  const locationDropdownOptions = hasLocationFilter(filters)
    ? buildLocationDropdownOptions(filters)
    : [];
  const defaultLocationKey =
    (locationDropdownOptions.length && (locationDropdownOptions[0].key as string)) || '';

  const productId = oc(lineItem).productIdentifier.productId();
  const skuId = oc(lineItem).productIdentifier.skuId();
  const availabilityId = oc(lineItem).productIdentifier.availabilityId();

  const selectedKey =
    (numberOfAvailabilities <= 1 && availabilityId) ||
    (oc(userPreferences).term() !== oc(userPreferences).availabilityTermId() &&
      oc(userPreferences).duration('').length)
      ? [productId, skuId, availabilityId].join('/')
      : [productId, skuId].join('/');

  const selectedTermsKey = oc(userPreferences).term(defaultTermKey);
  const selectedTermsDurationKey = oc(userPreferences).termDurationKey(selectedTermsKey);
  const defaultStartDateOptions = getDefaultStartDateOptions(isPasses(hydratedProduct), lineItem);
  return {
    recurringCheckEnabled: oc(lineItem).purchaseInstruction.isAutoRenew(true),
    selectedDurationKey: oc(userPreferences).duration(defaultDurationKey),
    selectedLocationKey: oc(userPreferences).location(defaultLocationKey),
    selectedTermsKey,
    selectedTermsDurationKey,
    startDateOptions: defaultStartDateOptions.options,
    selectedStartDateKey: defaultStartDateOptions.initialSelectedKey,
    selectedListKey: oc(lineItem).productIdentifier.action() && productId && skuId && selectedKey,
    productHasBillingPlans: productContainsBillingPlans(
      qualifyingSkuAvailabilities,
      hydratedProduct
    ),
    createLineItems,
  };
};

export const addAndSelectTheKey = (key: string, dropdownOptions: IComboBoxOption[]) => {
  // The key is assumed to be passed in as a UTC date.
  const newSelectedStartDateKey = moment(new Date(key), dateFormat)
    .format(dateFormat)
    .toString();

  const alreadyExists: boolean = dropdownOptions.some(
    (option: IComboBoxOption) => option.key === newSelectedStartDateKey
  );
  const itemToAdd: IComboBoxOption = {
    text: newSelectedStartDateKey,
    key: newSelectedStartDateKey,
  };
  const newStartDateOptionsNotSorted = alreadyExists
    ? dropdownOptions
    : dropdownOptions.concat(itemToAdd);
  const newStartDateOptions = newStartDateOptionsNotSorted.sort();
  return { newStartDateOptions, newSelectedStartDateKey };
};

export const generateMessage = (t: i18next.TFunction, proposal: Proposal) => {
  //TODO: cameneks, better translations that preserve the order is needed
  const supportOfferMessagePart1 = t(
    'quote::As part of a limited promotional offer, all customers are eligible for'
  );
  const supportOfferMessagePart2 = t(
    'quote::at no additional cost. Please only add Professional Direct to the quote as a paid offer.'
  );
  const supportOfferTitle = t('quote::Standard Support');
  const supportOfferComplete = (
    <TextBody dataAutomationId="supportOfferMessage">
      {supportOfferMessagePart1}
      <span style={standardSupportLinkRightMargin}>
        <LinkExternal
          dataAutomationId="standardSupportLink"
          displayText={supportOfferTitle}
          href={supportOfferUrl}
        />
      </span>
      <span>{supportOfferMessagePart2}</span>
    </TextBody>
  );
  const isLegacy =
    oc(proposal).header.extendedProperties.userPreferences.agreementType() === 'legacy';
  const RIstring = isLegacy
    ? t("quote::RI's may only be discounted for all reservations or a specific instance below")
    : t("quote::RI's may only be discounted for all locations or a specific instance below");
  const RI = <TextBody> {RIstring}</TextBody>;
  return { RI: RI, supportOffer: supportOfferComplete };
};

export const ConfigCardBusinessLogic: React.FC<Props> = (props: Props) => {
  const dialogContext = React.useContext(DialogContext);
  const {
    hydratedProduct,
    activeLineItemId,
    lineItems,
    isLegacyQuote,
    isLineItemCreationLoading,
    allowUnspecifiedOptionInLocationFilters,
  } = props;
  const { t } = useTranslation();
  const generatedMessage = generateMessage(t, props.proposal);
  const message = isSupportOffer(hydratedProduct)
    ? generatedMessage.supportOffer
    : generatedMessage.RI;
  if (!lineItems) {
    const e = 'We dont have line Items but we are rendering ConfigCard';
    const error = new Error(e);
    loggerService.error({ error });
    throw error;
  }
  const activeLineItem =
    lineItems && lineItems.find((lineItem: LineItem) => lineItem.id === activeLineItemId);
  if (!activeLineItem) {
    const e = 'Trying to render Config Card without a line Item, this should not happen';
    const error = new Error(e);
    loggerService.error({ error });
    throw error;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = React.useRef(new ProductFilterContext(hydratedProduct as any));
  const productFilterContext = ref.current;

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      lineItem: activeLineItem,
      createLineItems: [],
      filters: productFilterContext.productFilters,
      hydratedProduct,
      isLegacyQuote,
      qualifyingSkuAvailabilities: productFilterContext.qualifyingSkuAvailabilities,
      addSkuTitles: props.addSkuTitles,
      userPreferences: oc(activeLineItem).extendedProperties.userPreferences(),
      skuTitles: props.skuTitles,
    },
    generateInitialState
  );

  const _performance = performance;
  _performance.mark('config card opened');

  const hasDiscount =
    activeLineItem.pricingInstruction && activeLineItem.pricingInstruction.ruleType;
  filterOnLocations(
    productFilterContext,
    state.selectedLocationKey,
    allowUnspecifiedOptionInLocationFilters
  );
  filterOnDurations(productFilterContext, props.hydratedProduct, state.selectedDurationKey);

  const onLocationSelect = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    loggerService.log({ name: `Config Card - location: '${oc(option).key()}' was selected` });
    option && dispatch(ConfigCardActions.locationSelection(option.key.toString()));
  };

  const updateAndCreateLineItems = (selectedOptions: IDropdownOption[]) => {
    let isUpdatedLineItem = true;
    const createLineItems: CreateLineItemProperties[] = [];
    if (!props.readOnly) {
      selectedOptions.forEach((option: IDropdownOption) => {
        const newLocation = option.key.toString();
        if (isUpdatedLineItem) {
          loggerService.log({ name: `Config Card - location: '${oc(option).key()}' was selected` });
          option && dispatch(ConfigCardActions.locationSelection(newLocation));
          isUpdatedLineItem = false;
        } else {
          createLineItems.push({
            location: newLocation,
            duration: state.selectedDurationKey,
          });
        }
      });
      dispatch(ConfigCardActions.addLineItems(createLineItems));
      props.updateAddedLineItemCount(createLineItems.length);
    }
  };

  const onDurationSelect = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    loggerService.log({ name: `Config Card - duration: '${oc(option).key()}' was selected` });
    option && dispatch(ConfigCardActions.durationSelection(option.key.toString()));
  };

  const terms = getTermsForAllQualifyingSkus(
    productFilterContext.qualifyingSkuAvailabilities,
    hydratedProduct
  );

  const onClearSelect = () => {
    dispatch(ConfigCardActions.clearListSelection({}));
  };

  const onBillingPlanSelection = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option && option.key) {
      loggerService.log({ name: `Config Card - Billing Plan: '${oc(option).key()}' was selected` });
      dispatch(ConfigCardActions.termsSelection(option.key.toString()));
    }
  };

  const onStartDateAdded = (date: Date) => {
    loggerService.log({ name: `Config Card - future start date added: '${date.toISOString()}'` });
    dispatch(ConfigCardActions.startDateAdded(date.toISOString()));
  };

  const onStartSelectionChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option && typeof option.key === 'string') {
      loggerService.log({ name: `Config Card - future start date selected: '${option.key}'` });
      dispatch(ConfigCardActions.startDateSelected(option.key));
    }
  };

  const onRecurringCheckboxToggle = () => {
    dispatch(ConfigCardActions.recurringCheckboxToggled({}));
  };

  const onDismiss = () => {
    props.onDismiss();
  };

  const onAdd = () => {
    loggerService.log({
      name: 'Config Card - Selection as new line item(s) is clicked',
    });
    addConfiguration(
      productFilterContext.productFilters,
      productFilterContext.qualifyingSkuAvailabilities,
      props.hydratedProduct,
      activeLineItem,
      state.createLineItems,
      props.proposal,
      props.addLineItem,
      props.updateProposal,
      state.selectedDurationKey,
      state.selectedLocationKey,
      state.selectedTermsKey,
      state.selectedTermsDurationKey,
      state.selectedStartDateKey,
      state.selectedListKey,
      state.selectedSkuRow,
      state.recurringCheckEnabled
    );
  };

  const onApply = () => {
    applyConfiguration(
      productFilterContext.productFilters,
      productFilterContext.qualifyingSkuAvailabilities,
      props.hydratedProduct,
      activeLineItem,
      state.createLineItems,
      props.proposal,
      props.createLineItemsFromMultiRegionalSelection,
      props.updateProposal,
      state.selectedDurationKey,
      state.selectedLocationKey,
      state.selectedTermsKey,
      state.selectedTermsDurationKey,
      state.selectedStartDateKey,
      state.selectedListKey,
      state.selectedSkuRow,
      state.recurringCheckEnabled
    );
    onDismiss();
  };

  const selectedLocationKeys = state.createLineItems.map(item => item.location);

  const headerProps = generateConfigCardHeaderProps(
    hydratedProduct,
    productFilterContext.productFilters,
    productFilterContext.qualifyingSkuAvailabilities,
    onLocationSelect,
    updateAndCreateLineItems,
    onDurationSelect,
    state.selectedDurationKey,
    state.selectedLocationKey,
    selectedLocationKeys,
    isLegacyQuote,
    allowUnspecifiedOptionInLocationFilters
  );

  const onTermSelection = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    loggerService.log({ name: `Config Card - term:'${oc(option).key()}' was selected` });
    if (option && option.data && option.data.termWithoutBillingPlan) {
      dispatch(ConfigCardActions.termsSelection(option.key.toString()));
    } else if (state.selectedSkuRow && option) {
      const key = option.key.toString();
      const billingPlanInitial = buildBillingPlanDropdownOptions(
        productFilterContext.qualifyingSkuAvailabilities,
        hydratedProduct,
        state.selectedListKey,
        key
      );
      let selectedTermNotAvailable = false;
      if (billingPlanInitial) {
        selectedTermNotAvailable = !billingPlanInitial.find(
          option => option.key === state.selectedTermsKey
        );
      }

      if (selectedTermNotAvailable) {
        // Fallback to the first term if exists
        const billingPlans = buildBillingPlanDropdownOptions(
          productFilterContext.qualifyingSkuAvailabilities,
          hydratedProduct,
          state.selectedListKey,
          key
        );
        const firstBillingPlan = billingPlans && billingPlans[0].key.toString();
        if (firstBillingPlan) {
          dispatch(ConfigCardActions.termsSelection(firstBillingPlan));
        } else {
          dispatch(ConfigCardActions.clearTermSelection({}));
        }
      }
    }
    option && dispatch(ConfigCardActions.TermDurationSelection(option.key.toString()));
  };

  const onListSelect = (key: string, skuRow: SkuRow) => {
    //todo: kaderbez add event capture to telemetry
    loggerService.log({ name: `Config Card - sku list: '${key}' option selected` });
    const selectedTermNotAvailable = !isValidSelectedTerm(skuRow, state.selectedTermsKey);
    if (selectedTermNotAvailable) {
      // try to select the first term
      const newTermDropdownOptions = buildTermDropdownOptions(
        productFilterContext.qualifyingSkuAvailabilities,
        hydratedProduct,
        key
      );
      const firstTerm =
        newTermDropdownOptions && newTermDropdownOptions.length
          ? newTermDropdownOptions[0].key.toString()
          : undefined;
      if (firstTerm) {
        dispatch(ConfigCardActions.termsSelection(firstTerm));
        dispatch(ConfigCardActions.TermDurationSelection(firstTerm));
      } else {
        dispatch(ConfigCardActions.clearTermSelection({}));
        dispatch(ConfigCardActions.clearTermDurationSelection({}));
      }
    }
    dispatch(ConfigCardActions.listSelection({ key, skuRow }));
  };

  let bodyProps = generateConfigCardBodyProps(
    props,
    state,
    {
      onBillingPlanSelection,
      onClearSelect,
      onRecurringCheckboxToggle,
      onStartDateAdded,
      onStartSelectionChanged,
      onListSelect,
      onTermSelection,
    },
    productFilterContext.productFilters,
    productFilterContext.qualifyingSkuAvailabilities,
    terms,
    message,
    activeLineItem
  );

  const onCancelPopup = () => {
    dispatch(ConfigCardActions.cancelPopup());
  };

  const onApplyConfiguration = () => {
    if (hasDiscount) {
      loggerService.log({ name: 'Config Card - configuration was made after discount' });
      dispatch(ConfigCardActions.applyConfiguration());
      const dialog = <ConfigCardConfirmationDialog onApply={onApply} onCancel={onCancelPopup} />;
      const dialogProps: DialogProps = {
        providedDialog: dialog,
      };
      dialogContext.openDialog(dialogProps);
    } else {
      _performance.mark('config card dismissed');
      _performance.measure('config card in use', 'config card opened', 'config card dismissed');
      const measure = _performance.getEntriesByType('measure');
      const duration = measure[0].duration;
      loggerService.log({
        name: 'Config Card - configuration was applied',
        measurements: { duration },
      });
      performance.clearMarks();
      performance.clearMeasures();
      onApply();
    }
  };

  const configCardProps = generateConfigCardProps(
    props.hydratedProduct,
    productFilterContext.productFilters,
    activeLineItem,
    headerProps,
    bodyProps,
    isProposalReadOnly(props.proposal),
    state.selectedLocationKey,
    state.selectedDurationKey,
    props.target,
    onApplyConfiguration,
    onAdd,
    onDismiss,
    state.selectedListKey,
    state.hidden,
    props.minWidth,
    props.maxWidth,
    allowUnspecifiedOptionInLocationFilters,
    isLineItemCreationLoading
  );
  return <ConfigCard {...configCardProps} />;
};

type ConfigCardCombinedProps = ConfigCardProps & WithStyles<typeof styles>;

export const ConfigCardUnstyled: React.FunctionComponent<ConfigCardCombinedProps> = (
  props: ConfigCardCombinedProps
) => {
  const { t } = useTranslation();
  const {
    bodyProps,
    classes,
    headerProps,
    headerText,
    onApply,
    isANegotiatedTerm,
    isLineItemCreationLoading,
    onAddConfiguration,
    target,
    minWidth,
    maxWidth,
  } = props;
  // adding one for the active line item
  const createLineItemsLength = props.bodyProps.createLineItems.length + 1;
  const applyButtonStrings =
    createLineItemsLength > 1
      ? {
          text: t('quote::Add {{length}} line items', {
            length: createLineItemsLength,
          }),
          key: t('quote::Add {{length}} line items', {
            length: createLineItemsLength,
          }),
        }
      : { text: t('quote::Apply'), key: t('quote::Apply') };
  let content = (
    <div>
      <ConfigCardHeader {...headerProps} />
      <ConfigCardBody {...bodyProps} />
    </div>
  );

  const addButton = isLineItemCreationLoading ? (
    <div style={{ paddingRight: 120 }}>
      <Spinner size={SpinnerSize.large} />
    </div>
  ) : (
    <div style={{ paddingRight: 16 }}>
      <AddButton
        addClass={classes.createGroupButton}
        disabled={!props.applyButtonEnabled}
        predefinedIconName="Add"
        text={t('quote::Selection as new line item(s)')}
        onClick={onAddConfiguration}
      />
    </div>
  );

  return (
    <CalloutCard
      applyButtonDisabled={!props.applyButtonEnabled}
      applyButtonStrings={applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      dataAutomationId="configCard"
      directionalHint={DirectionalHint.rightCenter}
      headerText={headerText}
      hidden={props.hidden}
      id={props.lineItemId}
      isBeakVisible={true}
      isReadOnly={false}
      maxHeight={750}
      maxWidth={500 || maxWidth}
      minWidth={400 || minWidth}
      otherFooterButtons={!isANegotiatedTerm ? addButton : null}
      target={target}
      onApply={onApply}
      onDismiss={props.onDismiss}
    >
      {content}
    </CalloutCard>
  );
};

export const ConfigCard = withStyles(styles)(ConfigCardUnstyled);

export const ConnectedConfigCardBusinessLogic = connect(
  mapStateToProps,
  dispatchProps
)(ConfigCardBusinessLogic);
