import { SearchOrganizationsResponse } from 'generated/graphql';
import gql from 'graphql-tag';

export interface SearchCustomerResultsData {
  searchCustomerOrganizationsForTypeC: SearchOrganizationsResponse;
}

export const ASSOCIATE_CRMID = gql`
  mutation AssociateCrmIdToMcapiId($input: AssociateCrmIdToMcapiInput!) {
    associateCrmIdToMcapiId(input: $input)
  }
`;

export const SEARCH_CUSTOMER_ORGS = gql`
  query SearchCustomerOrganizationsForTypeC($searchInput: SearchOrganizationsInput!) {
    searchCustomerOrganizationsForTypeC(searchInput: $searchInput) {
      organizations {
        accountId
        id
        name
        address {
          companyName
          addressLine1
          addressLine2
          addressLine3
          addressLastLine
          city
          region
          country
          marketInfo {
            display
          }
          postalCode
        }
        lastInvoiceDate
      }
      tenant {
        tenantId
        tenantDisplayName
        account {
          id
          description
        }
      }
      messages {
        messageSource
      }
    }
  }
`;
