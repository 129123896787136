import {
  CustomDialogBox,
  DialogHeader,
  headerHeight,
  mergeClassNames,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
  XLargeIcon,
} from 'components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { DialogContext, sharedStyles, ThemeProps } from 'styles';

import { DialogLayout } from '../Shared';

export interface OwnProps {
  action: string;
  title?: string;
  height?: number;
  width?: number;
  is412?: boolean;
  isSequentialMultipleApprover?: boolean;
}

const styles = (theme: ThemeProps) => {
  return {
    ...sharedStyles(theme),
    icon: { ...sharedStyles(theme).iconForDialog },
    iconContainer: {
      ...sharedStyles(theme).iconContainerForDialog,
      backgroundColor: theme.palette.dangerText,
      '& i': {
        color: theme.palette.backgroundStandout,
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: (props: OwnProps) => (props.height || 225) / 4 - headerHeight,
      marginTop: (props: OwnProps) =>
        !props.title && ((props.height && props.height <= 300 && props.is412) || !props.height)
          ? '-40px'
          : 0,
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& > :not(:first-child)': {
        paddingTop: 16,
      },
      maxWidth: 360,
      textAlign: 'center',
    },
  };
};

export type RefreshDialogProps = WithStyles<typeof styles> & OwnProps;

export const RefreshDialogFeature: React.FC<RefreshDialogProps> = props => {
  const { t } = useTranslation();
  const { classes, action } = props;
  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };

  const refreshPage = () => {
    loggerService.log({
      name: `Refresh Dialog - ${props.is412 ? '412 -' : ''} window was reloaded`,
    });
    window.location.reload();
  };

  const displayText = props.isSequentialMultipleApprover
    ? t(
        'quote::Another approver has already taken action on this quote. Your {{action}} was not recorded. You can refresh the quote to see its current state.',
        { action: action && action.toLowerCase() }
      )
    : t(
        'quote::Sorry the "{{action}}" action failed. Due to changes in the quote, this page may be out of date. Please refresh the page.',
        { action }
      );

  const layout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        dataAutomationId="refreshQuote"
        dialogClose={closeDialog}
        headerClass={classes.customDialogHeader}
      >
        <TextTitle>{props.title}</TextTitle>
      </DialogHeader>
    ),
    body: (
      <div className={classes.customDialogBody}>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes.iconContainer}>
              <XLargeIcon addClass={classes.icon} iconName="Cancel" />
            </div>
            {props.is412 ? (
              <TextBody>
                <Trans ns="quote">
                  Sorry the &quot;{{ action }}&quot; action failed.
                  <br />
                  <br />
                  Please refresh the page. Due to changes in the quote, this page may be out of
                  date. After refreshing the page look over the quote before taking the &quot;
                  {{ action }}&quot; action.
                </Trans>
              </TextBody>
            ) : (
              <TextBody>{displayText}</TextBody>
            )}
          </div>
        </div>
      </div>
    ),
    footer: (
      <div className={mergeClassNames([classes.customDialogFooter, classes.flexAlignRight])}>
        <SecondaryButton
          dataAutomationId="refreshButton"
          text={t('quote::Refresh page')}
          onClick={refreshPage}
        />
        <PrimaryButton
          addClass={classes.marginLeft20}
          dataAutomationId="closeRefreshButton"
          text={t('quote::Close')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  return (
    <CustomDialogBox
      bodySlot={layout.body}
      footerSlot={layout.footer}
      headerSlot={layout.header}
      height={props.height || 225}
      width={props.width || 456}
    />
  );
};

export const RefreshDialog = withStyles(styles)(RefreshDialogFeature);
