import { ApolloError } from 'apollo-client';
import { AvailableMutations } from 'features-apollo/ActiveQuoteContext';
import { isQuoteHRDD, isQuoteIndirect, isQuoteJioCsp } from 'features-apollo/quote/selectors/quote';
import { QuoteData } from 'features-apollo/quote/types';
import { AgreementType, CustomerType } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';

import { PropertySheetLayout } from '../Layout';
import {
  CustomerForDirect,
  CustomerForHRDD,
  CustomerForIndirect,
  CustomerForJioCsp,
} from './scenarios';
import { CustomerLoading, OrganizationNotFound } from './states';

enum CustomerContext {
  Customer,
  PartnerCustomer,
  HRDD,
  JIOCSP,
}

export interface CustomerProps {
  /**
   * Loading active quote
   */
  loading: boolean;
  /**
   * Errored getting active quote
   */
  error: ApolloError | undefined;
  /**
   * Current active quote
   */
  quote?: QuoteData;
  /**
   * Available mutation for the active quote
   */
  availableMutations?: AvailableMutations;
  /**
   * Whether or not customer's phone number and email can be updated.
   */
  customerContactEditable: boolean;
}

export const Customer: React.FC<CustomerProps> = props => {
  const { quote, availableMutations } = props;
  const { t } = useTranslation();

  let content;

  if (quote && availableMutations) {
    const quoteMutationInput = { id: quote.id, etag: quote.etag };
    const readOnly = quote.readOnly;
    const endCustomerInvitedUser = quote.endCustomerInvitedUser || undefined;
    const isQuoteLegacy = quote.agreementType === AgreementType.Legacy;
    const leadOrganizationName = oc(quote).leadOrganization.address.companyName() || undefined;
    const soldToOrganization = quote.soldTo.organization || undefined;
    const endCustomerOrganization = quote.endCustomer || undefined;
    const crmID = oc(quote).crmLead.id();
    const salesAccountTPID = oc(quote).crmLead.salesAccount.tpid() || undefined;
    const quoteIsHRDD = isQuoteHRDD(quote);
    const quoteIsIndirect = isQuoteIndirect(quote);
    const quoteIsJIOCSP = isQuoteJioCsp(quote);

    const customerContext = quoteIsHRDD
      ? CustomerContext.HRDD
      : quoteIsIndirect
      ? CustomerContext.PartnerCustomer
      : quoteIsJIOCSP
      ? CustomerContext.JIOCSP
      : CustomerContext.Customer;

    const onRemove = (customerType: CustomerType) =>
      availableMutations.removeOrganization(customerType, true);

    switch (customerContext) {
      case CustomerContext.HRDD:
        return (
          <CustomerForHRDD
            customerOrganization={endCustomerOrganization}
            invitedUser={endCustomerInvitedUser}
            loading={props.loading}
            partner={{ organization: soldToOrganization, invitedUser: quote.soldToInvitedUser }}
            salesAccountTPID={salesAccountTPID}
          />
        );
      case CustomerContext.PartnerCustomer:
        return (
          <CustomerForIndirect
            audience={quote.audience}
            crmID={crmID}
            customer={{
              organization: endCustomerOrganization,
              invitedUser: endCustomerInvitedUser,
            }}
            customerContactEditable={props.customerContactEditable}
            loading={props.loading}
            partner={{ organization: soldToOrganization, invitedUser: quote.soldToInvitedUser }}
            quoteMutationInput={quoteMutationInput}
            readOnly={readOnly}
            onRemove={onRemove}
          />
        );
      case CustomerContext.JIOCSP:
        return (
          <CustomerForJioCsp
            audience={quote.audience}
            loading={props.loading}
            organization={soldToOrganization}
            quoteMutationInput={quoteMutationInput}
            onRemove={onRemove}
          />
        );
      case CustomerContext.Customer:
      default:
        return (
          <CustomerForDirect
            contactInformationEditable={props.customerContactEditable}
            crmID={crmID}
            invitedUser={quote.soldToInvitedUser}
            isQuoteLegacy={isQuoteLegacy}
            leadOrganizationName={leadOrganizationName}
            loading={props.loading}
            organization={soldToOrganization}
            quoteMutationInput={quoteMutationInput}
            readOnly={readOnly}
            salesAccountName={oc(quote).crmLead.salesAccount.address.companyName() || undefined}
            salesAccountTPID={salesAccountTPID}
          />
        );
    }
  }

  // Generic loading/error state when customer context can't be determined
  if (props.loading) {
    content = <CustomerLoading />;
  } else if (props.error) {
    content = (
      <OrganizationNotFound
        customMessage={t(
          'quote::Currently we are unable to get the customer information from the quote.'
        )}
      />
    );
  }

  return (
    <PropertySheetLayout
      contentList={[
        {
          leftPaneTitle: t('quote::Customer'),
          content,
        },
      ]}
    />
  );
};
