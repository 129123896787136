import { CommandButton } from 'components';
import { headerButtonStyles } from 'components/ions/Buttons/HeaderButton/HeaderButton.styles';
import { buttonSharedStyles } from 'components/ions/Buttons/shared.styles';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  ...buttonSharedStyles(theme),
  ...headerButtonStyles(theme),
  icon: {
    height: 48,
    padding: '14px 0 14px 0',
    whiteSpace: 'nowrap',
  },
});

type Props = {
  addClass?: string;
  primaryColor?: boolean;
  text?: string;
  isDemoMode?: boolean;
  onClick: () => void;
} & WithStyles<typeof styles>;

const ResetFlightsButtonUnstyled: React.FC<Props> = props => {
  const { addClass, classes, primaryColor, onClick, text, isDemoMode } = props;

  const buttonClass = isDemoMode ? classes.demo : primaryColor ? classes.primary : classes.command;

  return (
    <CommandButton
      addClass={mergeClassNames([addClass, classes.icon, buttonClass])}
      iconProps={{ iconName: 'RevToggleKey' }}
      text={text}
      title="Reset Flights to Defaults"
      onClick={onClick}
    />
  );
};

export const ResetFlightsButton = withStyles(styles)(ResetFlightsButtonUnstyled);
