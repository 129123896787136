import { ApprovalHistory, InvitedUserSimple, Maybe, Quote } from 'generated/graphql';

export enum CreditLineReason {
  SafeList = 'account is safe listed',
  PendingReview = 'pendingreview',
  UnderReview = 'underreview',
  ReviewCancelled = 'reviewcancelled',
  Rejected = 'rejected',
  CreditLine = 'CreditLine',
  CreditIncrease = 'CreditIncrease',
  None = 'None',
  Approved = 'approved',
  NoPiOnFile = 'no_pi_on_file',
  ManualRejected = 'manual_review_rejected',
}

export type DateFormat = 'MM/DD/YYYY' | 'MMMM DD, YYYY' | 'YYYY-MM-DD';

export interface ApprovalHistoryComment extends ApprovalHistory {
  comments: string;
}

export interface MutationCreateQuoteData {
  createQuote?: Quote;
}

export interface QueryGetQuoteData {
  getQuote?: Quote;
}

export interface QueryGetQuotesData {
  getQuotes: Quote[];
}

// TODO: this needs to be generated from codegen
export interface QuoteData extends Quote {
  soldToInvitedUser?: Maybe<InvitedUserSimple>;
  endCustomerInvitedUser?: Maybe<InvitedUserSimple>;
}

export enum CatalogItemType {
  Product = 'Product',
  Term = 'Term',
  Ecif = 'Ecif',
}
