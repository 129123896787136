import i18next from 'i18next';

const validateAgainstMaxSeats = (
  quantity: number,
  maxSeats: number,
  purchaseAtStart: number,
  sumOfQuantityExceptCurrentItem: number,
  t: i18next.TFunction
) => {
  const totalSeats = quantity + purchaseAtStart + sumOfQuantityExceptCurrentItem;
  if (quantity > maxSeats) {
    return {
      errorMessage: t('error::Set quantity exceeds maximum quantity'),
      isApplyButtonDisabled: true,
      isWarning: false,
    };
  } else if (totalSeats > maxSeats && quantity <= maxSeats) {
    return {
      errorMessage: t('error::Set quantity exceeds purchasable seats'),
      isApplyButtonDisabled: false,
      isWarning: true,
    };
  } else {
    return { errorMessage: '', isApplyButtonDisabled: false, isWarning: false };
  }
};
const validateAgainstMinSeats = (
  quantity: number,
  minSeats: number,
  purchaseAtStart: number,
  t: i18next.TFunction
) => {
  if (quantity < minSeats - purchaseAtStart) {
    return {
      errorMessage: t('error::Set quantity is less than minimum purchasable seats'),
      isApplyButtonDisabled: quantity === 0,
      isWarning: false,
    };
  } else if (quantity < minSeats) {
    return {
      errorMessage: t('error::Set quantity is less than minimum seats'),
      isApplyButtonDisabled: true,
      isWarning: false,
    };
  } else {
    return { errorMessage: '', isApplyButtonDisabled: false, isWarning: false };
  }
};

export const validateQuantity = (
  quantiy: number,
  maxSeats: number,
  minSeats: number,
  purchaseAtStart: number,
  sumOfQuantityExceptCurrentItem: number,
  t: i18next.TFunction
) => {
  const validationResponse = validateAgainstMaxSeats(
    quantiy,
    maxSeats,
    purchaseAtStart,
    sumOfQuantityExceptCurrentItem,
    t
  );
  if (validationResponse.errorMessage === '') {
    return validateAgainstMinSeats(quantiy, minSeats, purchaseAtStart, t);
  } else {
    return validationResponse;
  }
};
