import { AddButton, Spinner } from 'components/ions';
import { SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export interface AddAsNewLineItemButtonProps {
  loading: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const styles = (theme: ThemeProps) => {
  return {
    newLineButton: {
      color: theme.palette.textLink,
      '& i': {
        color: theme.palette.textLink,
      },
      '&:hover, &:hover i, &:focus, &:focus i': {
        color: theme.palette.textLinkHover,
      },
    },
    spinnerPadding: {
      paddingRight: 120,
    },
    buttonPadding: {
      paddingRight: 16,
    },
  };
};

type Props = AddAsNewLineItemButtonProps & WithStyles<typeof styles>;

const AddAsNewLineItemButtonUnstyled: React.FC<Props> = props => {
  const { loading, classes } = props;
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className={classes.spinnerPadding}>
        <Spinner size={SpinnerSize.large} />
      </div>
    );
  }
  return (
    <div className={classes.buttonPadding}>
      <AddButton
        addClass={classes.newLineButton}
        dataAutomationId="addAsNewLineItemButton"
        disabled={props.disabled}
        predefinedIconName="Add"
        text={t('quote::Selection as new line item(s)')}
        onClick={props.onClick}
      />
    </div>
  );
};

export const AddAsNewLineItemButton = withStyles(styles)(AddAsNewLineItemButtonUnstyled);
