import { ApolloError } from 'apollo-client';
import { GET_APPROVALS } from 'features-apollo/quote/components/queries';
import {
  ApprovalActionType,
  ApprovalLevelInput,
  ApprovalStatus,
  CatalogContext,
  CustomerType,
  LineItemInput,
  MutationAddLineItemsArgs,
  MutationAddSharedDiscountsArgs,
  MutationPerformApprovalActionArgs,
  MutationRemoveLineItemsArgs,
  MutationRemoveOrganizationArgs,
  MutationSubmitQuoteArgs,
  MutationUpdateQuantityArgs,
  MutationWithdrawQuoteArgs,
  OrderDirection,
  Query,
  QueryGetQuoteArgs,
  QuoteMutationInput,
} from 'generated/graphql';
import gql from 'graphql-tag';
import React from 'react';
import { SearchProposalsSortField, SearchSortOrder } from 'services/proposal/types';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { quoteBody } from './ActiveQuoteContext.queries';
import { getRemoveOrganizationOptimisticResponse } from './optimisticResponses';
import { QuoteData } from './quote/types';

type RemoveOrganizationCallback = (
  customerType: CustomerType,
  useOptimisticResponse?: boolean
) => void;

export interface AddLineItemMutationStatus {
  loading?: boolean;
  error?: ApolloError;
  loadingCount?: number;
}

export interface AvailableMutations {
  addLineItems: (
    productId: string[],
    catalogContext: CatalogContext,
    text?: string,
    oneAskCaseId?: string
  ) => string;
  removeLineItems: (lineItemIds: string[]) => void;
  submitQuote: (comment?: string, approvalLevels?: ApprovalLevelInput[]) => string;
  updateQuantity: (newQuantity: number, lineItemId: string) => void;
  withdrawQuote: () => string;
  performApprovalAction: (approvalActionType: ApprovalActionType, comments?: string) => void;
  removeOrganization: RemoveOrganizationCallback;
  addSharedDiscounts: () => string;
}

//TODO: this will be refactored
interface ActiveQuoteContext {
  quoteId?: string;
  updateQuoteId: (id?: string) => void;
  activeQuote?: QuoteData;
  availableMutations?: AvailableMutations;
  availableMutationsStatus: {
    submitQuote: { error?: ApolloError; loading: boolean; called: boolean };
    withdrawQuote: { error?: ApolloError; loading: boolean; called: boolean };
    performApprovalAction: { error?: ApolloError; loading: boolean; called: boolean };
    addLineItems: AddLineItemMutationStatus;
  };
  loading: boolean;
  quoteError: ApolloError | undefined;
  refetch?: (variables?: { id: string }) => void;
}

export const ActiveQuoteContext = React.createContext<ActiveQuoteContext>({
  updateQuoteId: () => {},
  loading: false,
  quoteError: undefined,
  availableMutationsStatus: {
    submitQuote: { loading: false, called: false },
    withdrawQuote: { loading: false, called: false },
    performApprovalAction: { loading: false, called: false },
    addLineItems: { loading: false, loadingCount: 0 },
  },
});
ActiveQuoteContext.displayName = 'ActiveQuoteContext';

export const GET_QUOTE = gql`
  query GetTheQuote($id: String!) {
    getQuote(id: $id) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

export const ADD_LINE_ITEM = gql`
  mutation AddALineItem($input: QuoteMutationInput!, $lineItems: [LineItemInput!]!) {
    addLineItems(input: $input, lineItems: $lineItems) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

export const REMOVE_LINE_ITEMS = gql`
  mutation RemoveALineItem($input: QuoteMutationInput!, $lineItemIds: [String!]!) {
    removeLineItems(input: $input, lineItemIds: $lineItemIds) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

export const SUBMIT_QUOTE = gql`
  mutation submitQuote($input: QuoteMutationInput!, $submitQuoteInput: SubmitQuoteInput!) {
    submitQuote(input: $input, submitQuoteInput: $submitQuoteInput) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

export const UPDATE_QUANTITY = gql`
  mutation updateQuantity($input: QuoteMutationInput!, $lineItemId: String!, $newQuantity: Int!) {
    updateQuantity(input: $input, lineItemId: $lineItemId, newQuantity: $newQuantity) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

export const WITHDRAW_QUOTE = gql`
  mutation withdrawQuote($input: QuoteMutationInput!) {
    withdrawQuote(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

export const PERFORM_APPROVAL_ACTION = gql`
  mutation performApprovalAction($id: String!, $action: ApprovalActionType!, $comments: String) {
    performApprovalAction(id: $id, action: $action, comments: $comments) {
      status
    }
  }
`;

/**
 * Use on indirect quotes to remove partner and/or customer organizations
 */
export const REMOVE_ORGANIZATION = gql`
  mutation REMOVE_ORGANIZATION($input: RemoveOrganizationInput!) {
    removeOrganization(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

/** Mutation to add available shared discounts to a Quote for a CAPT billing account */
export const ADD_SHARED_DISCOUNTS = gql`
  mutation addSharedDiscounts($input: QuoteMutationInput!) {
    addSharedDiscounts(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

export const useActiveQuote = (): ActiveQuoteContext => {
  const [quoteId, setQuoteId] = React.useState<string | undefined>();
  // this is intended for now.
  const trimmedQuoteID = quoteId && quoteId.trim();
  const { data, loading, error: quoteError, refetch } = useQuery<
    { getQuote?: QuoteData },
    QueryGetQuoteArgs
  >(GET_QUOTE, {
    skip: !trimmedQuoteID,
    variables: { id: quoteId || '' },
    errorPolicy: 'all',
  });

  const quote = data && data.getQuote;

  let addLineItems,
    removeLineItems,
    submitQuote,
    performApprovalAction,
    withdrawQuote,
    updateQuantity,
    addSharedDiscounts;
  let removeOrganization: RemoveOrganizationCallback | undefined;

  const [lastAddLineItemsCount, setLastAddLineItemCount] = React.useState(0);
  const [addLineItemMutation, addLineItemsMutationProps] = useMutation<
    any,
    MutationAddLineItemsArgs
  >(ADD_LINE_ITEM, {
    errorPolicy: 'all',
    update(cache, { data: { addLineItems } }) {
      const quote = cache.readQuery<{ getQuote: QuoteData }, QueryGetQuoteArgs>({
        query: GET_QUOTE,
        variables: { id: quoteId || '' },
      });
      cache.writeQuery({ query: GET_QUOTE, data: { getQuote: addLineItems || quote } });
      setLastAddLineItemCount(0);
    },
  });

  const [removeLineItemsMutation] = useMutation<any, MutationRemoveLineItemsArgs>(
    REMOVE_LINE_ITEMS,
    {
      errorPolicy: 'all',
      update(cache, { data: { removeLineItems } }) {
        const quote = cache.readQuery<{ getQuote: QuoteData }, QueryGetQuoteArgs>({
          query: GET_QUOTE,
          variables: { id: quoteId || '' },
        });

        cache.writeQuery({ query: GET_QUOTE, data: { getQuote: removeLineItems || quote } });
      },
    }
  );

  const [submitQuoteMutation, submitQuoteMutationProps] = useMutation<any, MutationSubmitQuoteArgs>(
    SUBMIT_QUOTE,
    {
      errorPolicy: 'all',
      update(cache, { data: { submitQuote } }) {
        cache.writeQuery({ query: GET_QUOTE, data: { getQuote: submitQuote } });
      },
    }
  );

  const [updateQuantityMutation] = useMutation<any, MutationUpdateQuantityArgs>(UPDATE_QUANTITY, {
    errorPolicy: 'all',
    update(cache, { data: { updateQuantity } }) {
      const quote = cache.readQuery<{ getQuote: QuoteData }, QueryGetQuoteArgs>({
        query: GET_QUOTE,
        variables: { id: quoteId || '' },
      });
      cache.writeQuery({
        query: GET_QUOTE,
        data: { getQuote: updateQuantity || quote },
      });
    },
  });

  const [withdrawQuoteMutation, withdrawQuoteMutationProps] = useMutation<
    any,
    MutationWithdrawQuoteArgs
  >(WITHDRAW_QUOTE, {
    errorPolicy: 'all',
    update(cache, { data: { withdrawQuote } }) {
      cache.writeQuery({ query: GET_QUOTE, data: { getQuote: withdrawQuote } });
    },
  });

  //This Returns Approval
  const [performApprovalActionMutation, performApprovalActionMutationProps] = useMutation<
    any,
    MutationPerformApprovalActionArgs
  >(PERFORM_APPROVAL_ACTION, {
    update(cache, { data: { performApprovalAction } }) {
      const quote = cache.readQuery<{ getQuote: QuoteData }, QueryGetQuoteArgs>({
        query: GET_QUOTE,
        variables: { id: quoteId || '' },
      });

      try {
        // updates cached approvals, when quote is approved
        if (performApprovalAction && performApprovalAction.status === ApprovalStatus.Approved) {
          const approvals = cache.readQuery<Query>({
            query: GET_APPROVALS,
            variables: {
              options: {
                pendingOnly: true,
                sort: {
                  orderField: SearchProposalsSortField.ModifiedDate,
                  orderDirection: SearchSortOrder.Descending.toUpperCase() as OrderDirection,
                },
              },
            },
          });

          if (approvals && approvals.getApprovals) {
            approvals.getApprovals = approvals.getApprovals.filter(
              approval => approval.id !== quoteId
            );
            cache.writeQuery({
              query: GET_APPROVALS,
              variables: {
                options: {
                  pendingOnly: true,
                  sort: {
                    orderField: SearchProposalsSortField.ModifiedDate,
                    orderDirection: SearchSortOrder.Descending.toUpperCase() as OrderDirection,
                  },
                },
              },
              data: { getApprovals: approvals },
            });
          }
        }
      } catch (err) {
        throw new Error('Failed to retrieve getApprovals from graphQL cache');
      }

      cache.writeQuery({ query: GET_QUOTE, data: { getQuote: performApprovalAction || quote } });
    },
  });

  const [removeOrganizationMutation] = useMutation<
    { removeOrganization: QuoteData },
    MutationRemoveOrganizationArgs
  >(REMOVE_ORGANIZATION);

  const [addSharedDiscountsMutation] = useMutation<
    { addSharedDiscounts: QuoteData },
    MutationAddSharedDiscountsArgs
  >(ADD_SHARED_DISCOUNTS, {
    errorPolicy: 'all',
  });

  //TODO: michmel -move to anoteher file when refactoring
  if (quote) {
    const quoteMutationInput: QuoteMutationInput = {
      id: quote.id,
      etag: quote.etag,
    };

    addLineItems = (productId: string[], catalogContext: CatalogContext, oneAskCaseId?: string) => {
      //TODO: what if it's a combination of ECIF, and a non-ecif product sending one ask for all of them currently, for now we just ignore this cannot happen
      const lineItems: LineItemInput[] = productId.map(item => {
        return {
          quantity: 1,
          productId: item,
          catalogContext,
          oneAskCaseId,
        };
      });
      setLastAddLineItemCount(productId.length);
      addLineItemMutation({ variables: { input: quoteMutationInput, lineItems } });
      return '';
    };
    removeLineItems = (lineItemIds: string[]) => {
      const lineItems = lineItemIds.map(id => {
        return { id, __typename: 'LineItem' };
      });
      removeLineItemsMutation({
        variables: { input: quoteMutationInput, lineItemIds: [...lineItemIds] },
        optimisticResponse: {
          removeLineItems: {
            __typename: 'Quote',
            id: quote,
            lineItems,
          },
          __typename: 'Mutation',
        },
      });
    };
    submitQuote = (comment?: string, approvalLevels?: ApprovalLevelInput[]) => {
      submitQuoteMutation({
        variables: { input: quoteMutationInput, submitQuoteInput: { approvalLevels, comment } },
      });
      return '';
    };

    updateQuantity = (newQuantity: number, lineItemId: string) => {
      updateQuantityMutation({
        variables: { input: quoteMutationInput, lineItemId: lineItemId, newQuantity: newQuantity },
      });
    };

    withdrawQuote = () => {
      withdrawQuoteMutation({
        variables: { input: quoteMutationInput },
      });
      return '';
    };
    performApprovalAction = (action: ApprovalActionType, comments?: string) => {
      if (quote.approval && quote.approval.__typename === 'Approval') {
        performApprovalActionMutation({
          variables: { id: quote.approval.id, action, comments },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_QUOTE, variables: { id: quoteId || '' } }],
        });
      }
    };

    removeOrganization = (customerType, useOptimisticResponse) => {
      const optimisticResponse = useOptimisticResponse
        ? getRemoveOrganizationOptimisticResponse(quote, customerType)
        : undefined;

      removeOrganizationMutation({
        variables: { input: { quoteIdentifier: quoteMutationInput, customerType } },
        optimisticResponse,
      });
    };

    addSharedDiscounts = () => {
      addSharedDiscountsMutation({ variables: { input: quoteMutationInput } });
      return '';
    };
  }

  const updateQuoteId = React.useCallback((quoteId?: string) => {
    setQuoteId(quoteId);
  }, []);

  const availableMutations =
    quote &&
    addLineItems &&
    removeLineItems &&
    submitQuote &&
    updateQuantity &&
    performApprovalAction &&
    withdrawQuote &&
    removeOrganization &&
    addSharedDiscounts
      ? {
          addLineItems,
          removeLineItems,
          submitQuote,
          updateQuantity,
          performApprovalAction,
          withdrawQuote,
          removeOrganization,
          addSharedDiscounts,
        }
      : undefined;

  const availableMutationsStatus = {
    submitQuote: {
      loading: submitQuoteMutationProps.loading,
      error: submitQuoteMutationProps.error,
      called: submitQuoteMutationProps.called,
    },
    withdrawQuote: {
      loading: withdrawQuoteMutationProps.loading,
      error: withdrawQuoteMutationProps.error,
      called: withdrawQuoteMutationProps.called,
    },
    performApprovalAction: {
      loading: performApprovalActionMutationProps.loading,
      error: performApprovalActionMutationProps.error,
      called: performApprovalActionMutationProps.called,
    },
    addLineItems: {
      loading: addLineItemsMutationProps.loading,
      error: addLineItemsMutationProps.error,
      loadingCount: lastAddLineItemsCount,
    },
  };

  return {
    quoteId,
    updateQuoteId,
    activeQuote: data && data.getQuote,
    availableMutations,
    availableMutationsStatus,
    loading,
    quoteError,
    refetch,
  };
};
