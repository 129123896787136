import { Dialog, PrimaryButton, SecondaryButton, TextBody } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

interface ChangeCustomerDialogProps {
  /**
   * Callback to 'Add new billing account' button
   */
  onChange: () => void;
}

type Props = ChangeCustomerDialogProps;

export const ChangeCustomerDialog: React.FC<Props> = props => {
  const { closeDialog } = React.useContext(DialogContext);
  const { t } = useTranslation();
  const title = t('quote::Add new billing account');

  const footerButtons = [
    <PrimaryButton
      dataAutomationId="changeCustomerConfirmButton"
      key="changeCustomerConfirmButton"
      text={title}
      onClick={props.onChange}
    />,
    <SecondaryButton
      dataAutomationId="changeCustomerCancelButton"
      key="changeCustomerCancelButton"
      text={t('Cancel')}
      onClick={() => closeDialog()}
    />,
  ];

  return (
    <Dialog footerButtons={footerButtons} height={240} title={title} width={400}>
      <TextBody>
        {t(
          'quote::Changing the customer on the quote will delete the uploaded agreement. Upon adding a customer you will need to re-upload the agreement and configure. Would you like to add a new billing account to the quote?'
        )}
      </TextBody>
    </Dialog>
  );
};

export const openChangeCustomerDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: ChangeCustomerDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <ChangeCustomerDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
