import { ThemeProps } from 'styles';

export const servicesConfigCardFieldsStyles = (theme: ThemeProps) => {
  return {
    configurationRow: {
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'row',
      '& > *:not(:last-child)': {
        marginRight: 10,
      },
    },
    addButton: {
      marginTop: 30,
      minWidth: 60,
      paddingLeft: 8,
      paddingRight: 8,
      '&:disabled': {
        backgroundColor: 'transparent',
      },
    },
    contentBody: {
      marginTop: 30,
    },
    servicesConfigRowTitle: {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    typeComboBox: {
      width: 110,
      '& label': {
        color: theme.palette.text,
        fontWeight: theme.fonts.fontWeights.regular,
      },
    },
    productFamilyComboBox: {
      width: 170,
      '& label': {
        color: theme.palette.text,
        fontWeight: theme.fonts.fontWeights.regular,
      },
    },
    descriptionComboBox: {
      width: 240,
      '& label': {
        color: theme.palette.text,
        fontWeight: theme.fonts.fontWeights.regular,
      },
    },
    endsDatePicker: {
      width: 130,
      '& i': {
        borderLeft: `1px solid ${theme.palette.border}`,
      },
      '& label': {
        color: theme.palette.text,
        fontWeight: theme.fonts.fontWeights.regular,
      },
      '& .is-disabled': {
        '& input': {
          backgroundColor: theme.palette.backgroundCommon,
          borderColor: theme.palette.border,
        },
        '& i': {
          borderLeft: `1px solid ${theme.palette.meControlPersonaBackground}`,
        },
      },
    },
    amountTextBox: {
      width: 90,
      height: 60,
      whiteSpace: 'nowrap',
      '& label': {
        color: theme.palette.text,
        fontWeight: theme.fonts.fontWeights.regular,
      },
      '& input, & input:focus': {
        textAlign: 'right',
      },
    },
    disabledTextBoxStyle: {
      '& input, & .is-disabled': {
        backgroundColor: theme.palette.backgroundCommon,
      },
    },
    errorMessageStyle: {
      fontSize: 10,
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: 90,
    },
  };
};

export const servicesConfigCardListStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    listSectionWrapper: {
      marginTop: 20,
      height: 225,
    },
    scrollablePaneWrapper: {
      marginTop: 15,
      position: 'relative',
      overflowY: 'auto',
      maxWidth: 850,
      maxHeight: 200,
    },
    lineItemsList: {
      overflowX: 'hidden',
      '& .ms-DetailsRow': {
        borderBottom: 'none',
        height: '35px',
      },
      '& .ms-DetailsRow-cell': {
        display: 'inline-flex',
        padding: 5,
      },
      '& .ms-DetailsRow-cell span': {
        padding: 5,
        paddingLeft: 8,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    amountText: {
      flex: 1,
      textAlign: 'right',
      marginRight: 13,
    },
    deleteButton: {
      '& i': {
        fontSize: theme.fonts.fontSizes.small,
        color: theme.palette.text,
        marginBottom: '3px',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: colors.primaryHover,
        '& i': {
          color: theme.palette.text,
        },
      },
      '&:focus': {
        backgroundColor: 'transparent',
        color: colors.primaryHover,
        '& i': {
          color: colors.primaryHover,
        },
      },
      '&:active:hover': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.primaryHover,
        },
      },
      backgroundColor: 'transparent',
    },
    watermarkContainer: {
      marginTop: 15,
      paddingTop: 13,
      textAlign: 'center',
      margin: '0 auto',
      width: 330,
      color: theme.palette.textTertiary,
    },
    sectionSeparator: {
      marginBottom: 10,
      marginTop: 12,
      color: theme.palette.border,
    },
    totalAmount: {
      float: 'right',
      marginRight: 83,
    },
    lineItemMessage: {
      height: '18px',
      paddingBottom: 4,
      justifyContent: 'center',
      display: 'flex',
      color: theme.palette.dangerText,
      marginBottom: '4px',
      fontStyle: 'normal',
    },
    errorStyle: {
      backgroundColor: theme.palette.dangerHighlight,
      width: '850px',
      '&:hover': {
        backgroundColor: theme.palette.dangerHighlight,
      },
    },
    detailsRowStyle: {
      width: '850px',
    },
  };
};
