import { LinkButton } from 'components/ions';
import { DiscountCard } from 'features-apollo/quote/components/DiscountCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { FontSizes } from 'styles';

import { linkStyles } from './DiscountCard.styles';

export interface DiscountCardLinkProps {
  ariaLabel?: string;
  dataAutomationId?: string;
  linkText?: string;
  disabled?: boolean;
  size?: keyof FontSizes;
  selectedIds: string[];
}

type Props = DiscountCardLinkProps & WithStyles<typeof linkStyles>;

const DiscountCardLinkUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { ariaLabel, classes, dataAutomationId, linkText, size, selectedIds } = props;
  const refTarget = React.useRef<HTMLSpanElement>(null);
  const [hidden, setHidden] = React.useState(true);
  const { t } = useTranslation();
  const text = linkText ? linkText : t('quote::configure');

  const onClick = () => {
    setHidden(false);
  };

  const onClose = () => {
    setHidden(true);
  };

  return (
    <>
      <span ref={refTarget}>
        <LinkButton
          addClass={classes.button}
          ariaLabel={ariaLabel}
          dataAutomationId={dataAutomationId}
          disabled={props.disabled}
          displayText={text}
          size={size}
          onClick={onClick}
        />
      </span>
      {hidden ? null : (
        <DiscountCard
          hidden={hidden}
          selectedItemIds={selectedIds}
          target={refTarget}
          onDismiss={onClose}
        />
      )}
    </>
  );
};

export const DiscountCardLink = withStyles(linkStyles)(DiscountCardLinkUnstyled);
