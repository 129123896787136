import axios from 'axios';
import { TokenAuthorities } from 'services/utils';

import { getAuthHeader } from '../utils';
import { SearchResponse, User } from './types';

const endpoint = 'https://graph.windows.net/microsoft.onmicrosoft.com/users';
async function getHeaders() {
  return {
    authorization: await getAuthHeader(TokenAuthorities.Graph),
  };
}

export async function getPhoto(alias: string): Promise<string> {
  const headers = await getHeaders();
  const url = `${endpoint}/${alias}/thumbnailPhoto`;
  const params = {
    'api-version': '1.6',
  };
  const response = await axios.get<Blob>(url, { params, headers, responseType: 'blob' });
  return URL.createObjectURL(response.data);
}

export async function searchUser(value: string): Promise<User[]> {
  const headers = await getHeaders();
  const params = {
    'api-version': '1.6',
    $top: '30',
    $filter: `startswith(displayName, '${value}') or startswith(userPrincipalName, '${value}') or startswith(mail , '${value}') `,
  };
  const response = await axios.get<SearchResponse>(endpoint, { params, headers });
  return response.data.value.filter(item => !item.userPrincipalName.includes('#EXT#'));
}
