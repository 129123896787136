export const routes = {
  root: '/',
  notFound: '/404',

  home: {
    root: '/home',
    catalog: '/home/catalog',
    quotes: {
      root: '/home/quotes',
      my: '/home/quotes/my',
      myApprovals: '/home/quotes/my-approvals',
      mySearch: (searchQuery: string) => `${routes.home.quotes.my}?search=${searchQuery}`,
    },
    referrals: {
      root: '/home/referrals',
      active: '/home/referrals/active',
      closed: '/home/referrals/closed',
      expired: '/home/referrals/expired',
      activeForQuery: (query: string) => `/home/referrals/active${query}`,
      closedForQuery: (query: string) => `/home/referrals/closed${query}`,
      expiredForQuery: (query: string) => `/home/referrals/expired${query}`,
    },
    customers: '/home/customers',
    help: {
      root: '/home/help',
      new: '/home/help/whats-new',
      start: '/home/help/getting-started',
      videos: '/home/help/videos',
      faq: '/home/help/faq',
      resources: '/home/help/other-resources',
    },
  },

  catalog: {
    redirect: '/catalog',
    root: '/catalog/:id',

    forId: (catalogId: string) => routes.catalog.root.replace(':id', catalogId),
  },

  quote: {
    redirect: '/quote',
    root: '/quote/:id',
    customer: '/quote/:id/customer',
    info: '/quote/:id/info',
    general: '/quote/:id/general',
    terms: '/quote/:id/terms',
    summary: '/quote/:id/summary',
    solution: '/quote/:id/solution',
    projection: '/quote/:id/projection',
    attach: '/quote/:id/attach',
    sales: '/quote/:id/sales',
    products: {
      root: '/quote/:id/products',
      cloud: '/quote/:id/products/:cloud',
    },
    forId: (quoteId: string) => routes.quote.root.replace(':id', quoteId),
    customerForId: (quoteId: string) => routes.quote.customer.replace(':id', quoteId),
    salesForId: (quoteId: string) => routes.quote.sales.replace(':id', quoteId),
    infoForId: (quoteId: string) => routes.quote.info.replace(':id', quoteId),
    productsForId: (quoteId: string) => routes.quote.products.root.replace(':id', quoteId),
    generalForId: (quoteId: string) => routes.quote.general.replace(':id', quoteId),
    termsForId: (quoteId: string) => routes.quote.terms.replace(':id', quoteId),
    summaryForId: (quoteId: string) => routes.quote.summary.replace(':id', quoteId),
    solutionForId: (quoteId: string) => routes.quote.solution.replace(':id', quoteId),
    projectionForId: (quoteId: string) => routes.quote.projection.replace(':id', quoteId),
    attachForId: (quoteId: string) => routes.quote.attach.replace(':id', quoteId),
    productsForIdAndCloud: (quoteId: string, cloud: string) =>
      routes.quote.products.cloud.replace(':id', quoteId).replace(':cloud', cloud),
  },

  customer: {
    redirect: '/customer',
    root: '/customer/:id',
    agreementsRoot: '/customer/:id/agreements',
    agreements: '/customer/:id/agreements/:filter?/:agreementId?/:detailsTab?',
    subscriptionsRoot: '/customer/:id/subscriptions',
    subscriptions: '/customer/:id/subscriptions/:filter?/:tenantId?/:productId?',
    about: '/customer/:id/about',
    azure: 'http://bing.com',
    forId: (customerId: string) => routes.customer.root.replace(':id', customerId),
    agreementsForId: (customerId: string) =>
      `${routes.customer.agreementsRoot.replace(':id', customerId)}/active`,
    agreementById: (customerId: string, agreementId: string) =>
      `${routes.customer.agreementsRoot.replace(':id', customerId)}/active/${agreementId}/info`,
    subscriptionsForId: (customerId: string) =>
      routes.customer.subscriptionsRoot.replace(':id', customerId),
    aboutForId: (customerId: string) => routes.customer.about.replace(':id', customerId),
  },

  proposal: {
    root: '/proposal/:id',
  },

  crmId: {
    root: '/crmid/:id',
    forId: (crmId: string) => routes.crmId.root.replace(':id', crmId),
  },

  opportunity: {
    root: '/opportunity/:id',
  },

  engagement: {
    root: '/engagement/:id',
  },

  external: {
    root: '/external',
  },

  content: {
    articlePopout: '/content/article-popout',
  },
};
