import { NotificationItem, NotificationType } from 'components';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { RuleType } from 'features/app/config/type';
import {
  createProcessingSelectors,
  getFlightIsEnabled,
  getSuppressedMessages,
} from 'features/app/selectors';
import {
  getProduct,
  getProductEntitiesIndexed,
  getProductsFailed,
} from 'features/catalog/selectors';
import { getOneAskResponse, oneAskResultsProcessing } from 'features/customer/selectors';
import {
  isAccessPass,
  isMonetary,
  isPasses,
} from 'features/proposal/components/ConfigCard/ConfigCardBusinessLogic';
import { startConditionConstants } from 'features/proposal/components/DetailsPane/detailsUtils';
import { computeDuration, negotiatedTermsNeedsConfiguration } from 'features/proposal/utils';
import i18next from 'i18next';
import { createSelector } from 'reselect';
import {
  DisplaySkuAvailability,
  Product,
  ProductFamily,
  ProductType,
  Sku,
} from 'services/catalog/types';
import { Flight } from 'services/flights/flightList';
import { productIds } from 'services/proposal/config';
import { LineItem, Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { ProductListLineItem } from '../components/List/ProductList';
import { TermListLineItem } from '../components/List/TermList';
import { InvitedUserStatus, ProposalListLineItem } from '../types';
import {
  getECIFErrors,
  getLatestEndDateOfECIFItems,
  getMockTermLineItems,
  getProposalListLineItems,
} from './ecifUtils';
import {
  getActiveProposal,
  getBillingCurrency,
  getCustomerAddress,
  isProposalReadOnly,
} from './proposal';
import {
  getLineItemNotifications,
  getNotifications,
  hasAnyBlockingNotifications,
} from './proposalValidations';

export const switchDFD = (lineItem: LineItem) => {
  if (
    !lineItem.pricingInstruction ||
    !lineItem.pricingInstruction.productIdentifier ||
    !lineItem.pricingInstruction.productIdentifier.productId
  ) {
    throw new Error(
      `DFD line item does not have a correct pricing instruction, it was :  ${lineItem}`
    );
  }
  const switched: LineItem = {
    ...lineItem,
    productIdentifier: {
      ...lineItem.pricingInstruction.productIdentifier,
      productId: lineItem.pricingInstruction.productIdentifier.productId,
      availabilityId: oc(lineItem.extendedProperties).userPreferences.availabilityId(),
      productType: oc(lineItem.extendedProperties).userPreferences.productType(),
      availabilityTermId: oc(lineItem.extendedProperties).userPreferences.availabilityTermId(),
    },
  };
  return switched;
};

export const getLineItemsSwitchingDFD = (state: RootState) => {
  const activeProposal = getActiveProposal(state);
  if (!activeProposal.lineItems) {
    return undefined;
  }
  const lineItems = activeProposal.lineItems.map((lineItem: LineItem) => {
    if (lineItem.productIdentifier.productId === productIds.discountFulfillmentDocument) {
      return switchDFD(lineItem);
    }
    return lineItem;
  });
  return lineItems;
};

export const getAddedLineItemCount = (state: RootState) =>
  state.proposal.views.editor.addedLineItemCount || undefined;

export const getLineItems = (state: RootState) => {
  const activeProposal = getActiveProposal(state);
  const ecifEnabled = getFlightIsEnabled(state, Flight.ECIF);
  if (!activeProposal.lineItems) {
    return undefined;
  }
  if (ecifEnabled) {
    const mock = getMockTermLineItems(activeProposal.lineItems);
    return getProposalListLineItems(mock);
  }
  return activeProposal.lineItems;
};

export const isExtendedPriceAdjustment = (item: LineItem) => {
  return item.pricingInstruction && item.pricingInstruction.priceAdjustmentType === 'extend';
};

export const getProductLineItemsWithSwitchedDFD = createSelector(
  getLineItemsSwitchingDFD,
  lineItems =>
    lineItems
      ? lineItems.filter(item => {
          return (
            item.productIdentifier.productFamily !== 'NegotiatedTerms' &&
            !isExtendedPriceAdjustment(item)
          );
        })
      : []
);

export const getProductLineItems = createSelector(getLineItems, lineItems =>
  lineItems
    ? lineItems.filter(item => {
        return (
          item.productIdentifier.productFamily !== 'NegotiatedTerms' &&
          !isExtendedPriceAdjustment(item)
        );
      })
    : []
);

export const getSharedDiscountLineItems = createSelector(getLineItemsSwitchingDFD, lineItems =>
  lineItems
    ? lineItems.filter(item => {
        return isExtendedPriceAdjustment(item);
      })
    : []
);

export const getSharedDiscountLineItemsNoSwitchedDfd = createSelector(getLineItems, lineItems =>
  lineItems
    ? lineItems.filter(item => {
        return isExtendedPriceAdjustment(item);
      })
    : []
);

export const isDiscountFulfillmentDocument = (lineItem: LineItem) =>
  productIds.discountFulfillmentDocument === lineItem.productIdentifier.productId;

export const getLineItemWithSwitchedDFD = (state: RootState, id: string) => {
  const lineItem = getActiveProposal(state).lineItems.find(
    (lineItem: LineItem) => lineItem.id === id
  );
  if (lineItem && isDiscountFulfillmentDocument(lineItem)) {
    return switchDFD(lineItem);
  }
  return lineItem;
};

export const getSelectedLineItemsWithSwitchedDFD = (state: RootState, selectedIds?: string[]) => {
  const results: LineItem[] = [];
  if (selectedIds && selectedIds.length) {
    const lineItems = getActiveProposal(state).lineItems;
    selectedIds.forEach(id => {
      const lineItem = lineItems.find(item => item.id === id);
      if (lineItem) {
        results.push(isDiscountFulfillmentDocument(lineItem) ? switchDFD(lineItem) : lineItem);
      }
    });
  }
  return results;
};

export const getLineItem = (state: RootState, id: string) =>
  getActiveProposal(state).lineItems.find((lineItem: LineItem) => lineItem.id === id);

export const getProductIdentifier = (lineItem: LineItem) =>
  isDiscountFulfillmentDocument(lineItem)
    ? oc(lineItem).pricingInstruction.productIdentifier()
    : lineItem.productIdentifier;

export const getLineItemProduct = (state: RootState, lineItem: LineItem) => {
  const productIdentifier = getProductIdentifier(lineItem);
  if (!productIdentifier || !productIdentifier.productId) {
    return;
  }
  return getProduct(state, productIdentifier.productId);
};

export const getLineItemProductOrSku = (state: RootState, lineItem: LineItem) => {
  const product = getLineItemProduct(state, lineItem);
  if (!product) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const productIdentifier = getProductIdentifier(lineItem)!;
  if (!productIdentifier.skuId) {
    return product;
  }
  const dsa = product.DisplaySkuAvailabilities.find(
    dsa => dsa.Sku.SkuId === productIdentifier.skuId
  );
  return dsa && dsa.Sku;
};

export const getCurrentSku = (isDFD: boolean, product: Product, lineItem: LineItem) => {
  const skuId = isDFD
    ? oc(lineItem).pricingInstruction.productIdentifier.skuId('')
    : oc(lineItem).productIdentifier.skuId('');
  const sku = oc(product)
    .DisplaySkuAvailabilities([])
    .filter(skuItem => {
      return skuItem.Sku.SkuId === skuId;
    });
  if (sku[0]) {
    sku[0].Sku.MaxOrderQuantity =
      oc(sku[0]).Sku.Properties.MaximumPurchaseQuantity() ||
      oc(sku[0]).Sku.Properties.MaxOrderQuantity();
    sku[0].Sku.MinOrderQuantity =
      oc(sku[0]).Sku.Properties.MinimumPurchaseQuantity() ||
      oc(sku[0]).Sku.Properties.MinOrderQuantity() ||
      0;
  }
  return sku && oc(sku[0]).Sku();
};

export const getLineItemPrices = (state: RootState) => state.proposal.views.editor.lineItemPrices;

export const getTermLineItems = createSelector(getLineItems, lineItems => {
  const termItems = lineItems
    ? lineItems.filter(item => item.productIdentifier.productFamily === 'NegotiatedTerms')
    : [];
  return termItems;
});

export const getLineItemsByOneAskId = (termLineItems: ProposalListLineItem[], oneAskId: string) => {
  const ecifLineItem = termLineItems.find(
    lineItem => lineItem.supplementalTermReferenceData === oneAskId
  );
  return (ecifLineItem && ecifLineItem.ecifGroup) || [];
};

// TODO: michmel - make this all correct
export const getTermListLineItems = createSelector(
  getTermLineItems,
  getProductEntitiesIndexed,
  getSuppressedMessages,
  getProductsFailed,
  getOneAskResponse,
  oneAskResultsProcessing,
  getBillingCurrency,
  (state: RootState) => getFlightIsEnabled(state, Flight.ECIF),
  (
    lineItems: ProposalListLineItem[],
    productEntities,
    suppressedErrors,
    failedProducts,
    oneAskResponse,
    oneAskResultsProcessing,
    currency,
    ecifEnabled
  ) => {
    return lineItems
      ? lineItems.map(lineItem => {
          const productId = lineItem.productIdentifier.productId;
          const product = productEntities[productId];
          const name =
            product && product.LocalizedProperties && product.LocalizedProperties.length
              ? product.LocalizedProperties[0].ProductTitle
              : '-';
          if (failedProducts[productId]) {
            const productListLineItem: TermListLineItem = {
              id: lineItem.id,
              name,
              productId,
              errors: [],
              isLoading: false,
              loadFailure: true,
              needsConfiguration: false,
            };
            return productListLineItem;
          }

          let errors = lineItem.messages
            ? lineItem.messages
                .filter(msg => !suppressedErrors.includes(msg.code))
                .map(msg => msg.content)
            : [];
          if (ecifEnabled) {
            //TODO: use the actual oneAskId
            const matchedResponse = oneAskResponse.find(
              response => response.RequestNumber === lineItem.supplementalTermReferenceData
            );
            const clientErrors: string[] = oneAskResultsProcessing.loading
              ? []
              : getECIFErrors(lineItem, currency, matchedResponse);
            errors = [...errors, ...clientErrors];
          }
          const termDurationValue = computeDuration(
            oc(lineItem).duration(''),
            i18next.t('quote::year(s)'),
            i18next.t('quote::month(s)')
          );
          const termDuration =
            lineItem.duration && !lineItem.endDate
              ? `${i18next.t('quote::In')} ${termDurationValue}`
              : (lineItem.endDate &&
                  convertDateToFormattedString(lineItem.endDate, LocaleDateFormat.ll)) ||
                '';
          let end =
            oc(product).ProductType() === 'CommitmentToConsume' ||
            oc(product).ProductType() === 'SCPCommitmentToConsume'
              ? termDuration
              : (oc(product).Properties.Duration('') as string) ||
                (oc(product).Properties.Duration('') as string[]).toString();

          if (lineItem.ecifGroup && ecifEnabled) {
            const latestEndDate = getLatestEndDateOfECIFItems(lineItem.ecifGroup);
            if (latestEndDate) {
              end = latestEndDate.format('ll');
            }
          }

          const startCondition = oc(product).Properties.StartCondition();
          const startDate =
            lineItem.startDate &&
            convertDateToFormattedString(lineItem.startDate, LocaleDateFormat.ll);
          const start = lineItem.startDate
            ? startDate
            : startCondition &&
              startCondition.toLowerCase() === startConditionConstants.firstOfThisMonth
            ? 'First of this month'
            : 'At order placement';
          const productListLineItem: TermListLineItem = {
            id: lineItem.id,
            productId: lineItem.productIdentifier.productId,
            isLoading: !product,
            ecifGroup: lineItem.ecifGroup,
            errors,
            name,
            start,
            end,
            type: oc(product).ProductFamily() === 'NegotiatedTerms' ? 'Negotiated' : undefined,
            needsConfiguration: negotiatedTermsNeedsConfiguration(lineItem, product),
            loadFailure: false,

            isStartOnFirstOfMonth:
              oc(product).ProductFamily() === ProductFamily.NegotiatedTerms &&
              oc(product).Properties.StartCondition() === startConditionConstants.firstOfThisMonth,
            groups: oc(lineItem).groups() || undefined,
            productType: lineItem.productIdentifier.productType,
          };
          return productListLineItem;
        })
      : [];
  }
);

export interface DetailsCustomerInfo {
  invitedUserStatus?: InvitedUserStatus;
  validOrganizationMarket?: boolean;
  refreshCustomer?: boolean;
}

export const currentCustomerAddress = (state: RootState) => {
  const activeProposal = getActiveProposal(state);
  return getCustomerAddress(state, activeProposal);
};

export const getDetailsNotifications = (state: RootState) =>
  getNotifications(state, getActiveProposal(state));

const emptyNotificationItemArray: NotificationItem[] = [];
export const getDetailsLineItemNotifications = (state: RootState, lineItemId: string) => {
  const proposal = getActiveProposal(state);
  if (!proposal.lineItems) {
    return [];
  }
  const lineItem = proposal.lineItems.find(lineItem => lineItem.id === lineItemId);

  return lineItem
    ? getLineItemNotifications(state, proposal, lineItem)
    : emptyNotificationItemArray;
};

export const lineItemHasNotificationError = (state: RootState, lineItemId: string) => {
  const notifications = getDetailsLineItemNotifications(state, lineItemId);
  for (let notification of notifications) {
    if (notification.type === NotificationType.error) {
      return true;
    }
  }
  return false;
};

export const isAnyBlockingNotification = (state: RootState) => {
  const activeProposal = getActiveProposal(state);
  return hasAnyBlockingNotifications(state, activeProposal);
};

export const needsConfiguration = (state: RootState, lineItem: LineItem): boolean | undefined => {
  const product = getProduct(state, lineItem.productIdentifier.productId);
  if (!product) {
    return; //If the product doesn't exist, we don't know if we need to configure
  }
  if (lineItem.productIdentifier.productFamily === ProductFamily.NegotiatedTerms) {
    return negotiatedTermsNeedsConfiguration(lineItem, product);
  }
  if (lineItem.isReadyForPricing || isDiscountFulfillmentDocument(lineItem)) {
    return false;
  }
  const allowUnspecifiedOptionInLocationFilters = getFlightIsEnabled(
    state,
    Flight.allowUnspecifiedOptionInLocationFilters
  );
  const location = oc(lineItem).extendedProperties.userPreferences.location();
  const duration = oc(lineItem).extendedProperties.userPreferences.duration();
  const locationExists = allowUnspecifiedOptionInLocationFilters
    ? location !== undefined
    : !!location;
  if (locationExists || duration) {
    return false;
  }
  return (
    isMonetary(product) ||
    product.DisplaySkuAvailabilities.length > 1 ||
    !oc(lineItem).productIdentifier.action()
  );
};

export const needsProductConfiguration = (
  state: RootState,
  lineItem: LineItem
): boolean | undefined => {
  const product = getProduct(state, lineItem.productIdentifier.productId);

  if (!product) {
    return;
  }

  const passesProductNeedsProductConfiguration =
    isPasses(product) &&
    (!lineItem.extendedProperties ||
      !lineItem.extendedProperties.userPreferences ||
      !Object.keys(lineItem.extendedProperties.userPreferences).length);

  return (
    ((isMonetary(product) || product.DisplaySkuAvailabilities.length > 1) &&
      !oc(lineItem).productIdentifier.action()) ||
    passesProductNeedsProductConfiguration
  );
};

export const isDiscounted = (lineItem: LineItem): boolean => {
  const ruleType =
    lineItem.pricingInstruction &&
    lineItem.pricingInstruction.ruleType &&
    lineItem.pricingInstruction.ruleType.toLowerCase();
  return (
    isDiscountFulfillmentDocument(lineItem) &&
    !!lineItem.pricingInstruction &&
    (ruleType === RuleType.priceGuarantee.toLowerCase() ||
      (ruleType === RuleType.future.toLowerCase() &&
        !!lineItem.pricingInstruction.discountPercentage &&
        lineItem.pricingInstruction.discountPercentage > 0))
  );
};

export const needsDiscount = (state: RootState, lineItem: LineItem): boolean => {
  const product = getLineItemProduct(state, lineItem);
  if (!product) return false;
  return (
    product.ProductType === ProductType.AzureFamilyDiscount ||
    product.ProductType === ProductType.SAP ||
    (product.ProductType === ProductType.Azure &&
      product.Properties.ProductOwnershipSellingMotion === '1PP')
  );
};

export const requiresDiscount = (lineItem: LineItem, product: Product): boolean => {
  return (
    product &&
    !isDiscounted(lineItem) &&
    (product.ProductType === ProductType.AzureFamilyDiscount ||
      product.ProductType === ProductType.SAP ||
      (product.ProductType === ProductType.Azure &&
        product.Properties.ProductOwnershipSellingMotion === '1PP'))
  );
};

export const isQuantifiableItem = (lineItem: LineItem, product?: Product) => {
  if (!product || isDiscountFulfillmentDocument(lineItem)) {
    return false;
  }
  let productOrSku: Product | Sku;
  let dsa: DisplaySkuAvailability | undefined;

  const productIdentifier = getProductIdentifier(lineItem) || {};

  if (productIdentifier.skuId) {
    dsa = product.DisplaySkuAvailabilities.find(dsa => dsa.Sku.SkuId === productIdentifier.skuId);
  }

  productOrSku = (dsa && dsa.Sku) || product;
  if (!productOrSku || !(productOrSku as Sku).SkuId) {
    return false;
  }
  const currentSku = productOrSku as Sku;
  const max =
    currentSku.Properties.MaximumPurchaseQuantity ||
    currentSku.Properties.MaxOrderQuantity ||
    currentSku.MaximumPurchaseQuantity ||
    currentSku.MaxOrderQuantity;
  const min =
    currentSku.Properties.MinimumPurchaseQuantity ||
    currentSku.Properties.MinOrderQuantity ||
    currentSku.MinimumPurchaseQuantity ||
    currentSku.MinOrderQuantity;

  const hasAction = !!oc(lineItem).productIdentifier.action();

  return max === undefined && min === 0
    ? false
    : min !== undefined && max !== undefined && hasAction;
};

export const isQuantityEditDisabled = (lineItem: LineItem, product: Product) => {
  if (!product) {
    return false;
  }
  const isMonetaryProduct =
    product &&
    isMonetary({
      ProductType: product.ProductType,
      ProductFamily: product.ProductFamily,
      LocalizedProperties: product.LocalizedProperties,
      ProductId: product.ProductId,
    });

  return isMonetaryProduct || !isQuantifiableItem(lineItem, product);
};
export const showProductQuantity = (
  product: Product,
  isDfd: boolean,
  isMonetary: boolean,
  isConsume: boolean
) => {
  if (!product || isDfd || isMonetary || isConsume) {
    return false;
  }
  return true;
};

export const isPurchase = (lineItem: LineItem, isDfd: boolean) => {
  if (isDfd) {
    return !!(oc(lineItem).pricingInstruction.productIdentifier.action() === 'purchase');
  }
  return !!(oc(lineItem).productIdentifier.action() === 'purchase');
};

export const isConsume = (lineItem: LineItem, isDfd: boolean) => {
  if (isDfd) {
    return !!(oc(lineItem).pricingInstruction.productIdentifier.action() === 'consume');
  }
  return !!(oc(lineItem).productIdentifier.action() === 'consume');
};

export const hasCommitment = (lineItem: LineItem) => {
  return oc(lineItem)
    .pricingInstruction.conditions([])
    .some(condition => condition.name === 'TermUnits');
};

export const hidePrices = (lineItem: LineItem, isDfd: boolean) => {
  const { pricingInstruction } = lineItem;
  return (
    oc(pricingInstruction).ruleType() !== undefined &&
    ((isPurchase(lineItem, isDfd) && hasCommitment(lineItem)) ||
      (isConsume(lineItem, isDfd) && !hasCommitment(lineItem)))
  );
};

// TODO: michmel - make this all correct, cameneks: It is not trivial :)
export const getProductListLineItems = createSelector(
  getProductLineItems,
  getProductEntitiesIndexed,
  getSuppressedMessages,
  isProposalReadOnly,
  getProductsFailed,
  (lineItems, productEntities, suppressedErrors, readOnly, failedProducts) => {
    return lineItems.map(lineItem => {
      const productIdentifier = getProductIdentifier(lineItem);
      const productId = (productIdentifier && productIdentifier.productId) || '';
      if (failedProducts[productId]) {
        const productListLineItem: ProductListLineItem = {
          id: lineItem.id,
          productId,
          isLoading: false,
          pricingCurrency: lineItem.pricingCurrency,
          loadFailure: true,
          minQuantity: 0,
        };
        return productListLineItem;
      } else {
        const product = productEntities[productId];
        const messages = lineItem.messages
          ? lineItem.messages
              .filter(msg => !suppressedErrors.includes(msg.code))
              .map(msg => msg.content)
          : undefined;

        const hasErrorMessages = !!(messages && messages.length);
        const isLoading = !product;
        const hasServiceValidationErrors = hasErrorMessages && !isLoading;
        const name = oc(product).LocalizedProperties[0].ProductTitle();
        const productIsDiscountFulfillmentDocument = isDiscountFulfillmentDocument(lineItem);
        const sku = getCurrentSku(productIsDiscountFulfillmentDocument, product, lineItem);
        const disableEdit = readOnly || isQuantityEditDisabled(lineItem, product);
        const isQuantifiable = isQuantifiableItem(lineItem, product);
        const isMonetaryProduct =
          product &&
          isMonetary({
            ProductType: product.ProductType,
            ProductFamily: product.ProductFamily,
            LocalizedProperties: product.LocalizedProperties,
            ProductId: product.ProductId,
          });
        const isAccessPassProduct =
          product &&
          isAccessPass({
            ProductType: product.ProductType,
            ProductFamily: product.ProductFamily,
            LocalizedProperties: product.LocalizedProperties,
            ProductId: product.ProductId,
          });

        const shouldHidePrice =
          productIsDiscountFulfillmentDocument ||
          hidePrices(lineItem, productIsDiscountFulfillmentDocument);

        const isConsumeable = isConsume(lineItem, productIsDiscountFulfillmentDocument);

        const showQuantity = showProductQuantity(
          product,
          productIsDiscountFulfillmentDocument,
          isMonetaryProduct,
          isConsumeable
        );

        const needsConfiguration =
          (productIdentifier && !productIdentifier.action) || requiresDiscount(lineItem, product);

        const productListLineItem: ProductListLineItem = {
          id: lineItem.id,
          productId,
          isLoading,
          name,
          disableQuantityEdit: disableEdit,
          quantity: showQuantity
            ? lineItem.quantity
            : isQuantifiable && !isMonetary
            ? 1
            : undefined,

          list:
            !isMonetaryProduct && !isAccessPassProduct && !shouldHidePrice
              ? lineItem.listPrice
              : undefined,
          customer:
            !isMonetaryProduct && !isAccessPassProduct && !shouldHidePrice
              ? lineItem.chargePrice.price
              : undefined,
          discount: !isMonetaryProduct
            ? lineItem.pricingInstruction &&
              lineItem.pricingInstruction.discountPercentage &&
              lineItem.pricingInstruction.discountPercentage / 100
            : undefined,
          amount:
            !shouldHidePrice || isAccessPassProduct || isMonetaryProduct
              ? lineItem.extendedPrice
              : undefined,
          messages,
          hasServiceValidationErrors,
          needsConfiguration,
          minQuantity: oc(sku).MinOrderQuantity(1),
          maxQuantity: sku && sku.MaxOrderQuantity,
          pricingCurrency: lineItem.pricingCurrency,
          loadFailure: false,
        };
        return productListLineItem;
      }
    });
  }
);

export const getSharedDiscountListLineItems = createSelector(
  getSharedDiscountLineItems,
  getProductEntitiesIndexed,
  getSuppressedMessages,
  getProductsFailed,
  (lineItems, productEntities, suppressedErrors, failedProducts) => {
    return lineItems.map(lineItem => {
      const productIdentifier = getProductIdentifier(lineItem);
      const productId = (productIdentifier && productIdentifier.productId) || '';
      if (failedProducts[productId]) {
        const productListLineItem: ProductListLineItem = {
          id: lineItem.id,
          productId,
          isLoading: false,
          pricingCurrency: lineItem.pricingCurrency,
          loadFailure: true,
          minQuantity: 0,
        };
        return productListLineItem;
      } else {
        const product = productEntities[productId];
        const messages = lineItem.messages
          ? lineItem.messages
              .filter(msg => !suppressedErrors.includes(msg.code))
              .map(msg => msg.content)
          : undefined;

        const hasErrorMessages = !!(messages && messages.length);
        const isLoading = !product;
        const hasServiceValidationErrors = hasErrorMessages && !isLoading;
        const name = oc(product).LocalizedProperties[0].ProductTitle();
        const productIsDiscountFulfillmentDocument = isDiscountFulfillmentDocument(lineItem);
        const sku = getCurrentSku(productIsDiscountFulfillmentDocument, product, lineItem);

        const productListLineItem: ProductListLineItem = {
          id: lineItem.id,
          productId,
          isLoading,
          name,
          disableQuantityEdit: true,
          quantity: undefined,
          list: undefined,
          customer: undefined,
          discount:
            (lineItem.pricingInstruction &&
              lineItem.pricingInstruction.discountPercentage &&
              lineItem.pricingInstruction.discountPercentage / 100) ||
            undefined,
          amount: undefined,
          messages,
          hasServiceValidationErrors,
          needsConfiguration: false,
          minQuantity: oc(sku).MinOrderQuantity(1),
          maxQuantity: sku && sku.MaxOrderQuantity,
          pricingCurrency: lineItem.pricingCurrency,
          loadFailure: false,
        };
        return productListLineItem;
      }
    });
  }
);

export const getAllGroupedLineItems = createSelector(getLineItems, lineItems => {
  if (!lineItems) {
    return [];
  }
  return lineItems.filter(lineitem => {
    return oc(lineitem).groups.length(0) > 0;
  });
});

export const getGroupedLineItems = (lineItems: LineItem[], groupId: string, parentId: string) => {
  return lineItems
    ? lineItems.filter(lineitem => {
        return (
          oc(lineitem)
            .groups([])
            .includes(groupId) && oc(lineitem).id('') !== parentId
        );
      })
    : [];
};

export const getFinanceApplicableProducts = (state: RootState, lineItemId: string) => {
  const lineItem = getLineItem(state, lineItemId);
  const products: Product[] = [];
  if (lineItem) {
    const product = getLineItemProduct(state, lineItem);
    const marketProperties = oc(product).MarketProperties([]);
    marketProperties.forEach(marketProp => {
      marketProp.RelatedProducts &&
        marketProp.RelatedProducts.forEach(relatedProduct => {
          if (relatedProduct.RelationshipType.toLowerCase() === 'sellableby') {
            const product = getProduct(state, relatedProduct.RelatedProductId);
            product && products.push(product);
          }
        });
    });
  }
  return products;
};

export const getFinanceableLineItems = (lineItems: LineItem[], products: Product[]) => {
  const applicableLineItems: LineItem[] = [];
  if (!lineItems) {
    return [];
  }
  lineItems.forEach(lineitem => {
    const productId = oc(lineitem).productIdentifier.productId('');
    const match = products.some(product => product.ProductId === productId);
    if (match) {
      applicableLineItems.push(lineitem);
    }
  });

  return applicableLineItems;
};

export const getLineItemsByProductId = (state: RootState, productId?: string): LineItem[] => {
  const proposal: Proposal = getActiveProposal(state);
  return proposal.lineItems.filter(l => l.productIdentifier.productId === productId);
};
export const processingAddSharedDiscounts = createProcessingSelectors([
  '@@proposal/line_items/CREATE',
]);
