import { getFlightIsEnabled } from 'features/app/selectors';
import * as proposalActions from 'features/proposal/actions';
import { deleteLineItems } from 'features/proposal/sagas/auxiliary';
import { getCustomerName, isExtendedPriceAdjustment } from 'features/proposal/selectors';
import {
  buildProposalRequest,
  cloneProposal,
  isDefaultCustomerName,
} from 'features/proposal/utils';
import moment from 'moment-timezone';
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { AgreementConfig } from 'services/agreement';
import { Agreement, GetAgreementRequest } from 'services/agreement/types';
import { ProductFamily } from 'services/catalog/types';
import { Flight } from 'services/flights/flightList';
import { LineItem, Proposal } from 'services/proposal/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

import {
  getLeadOrgIdFromAgreement,
  hydrateLite,
  searchOrganizationsPropertySheet,
  updateProposalWithCustomer,
} from './auxiliary';
import { getOrganizationFromCRMID } from './auxiliary/associate-sales-account';

export function* verifyCrmId() {
  const relevantAction = proposalActions.verifyCrmId.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      yield call(hydrateLite, action.payload);
      yield put(proposalActions.verifyCrmId.success(action.payload));
    } catch (err) {
      yield put(
        proposalActions.verifyCrmId.failure({
          message: t('error::Error verifying CRM Id'),
          exception: err,
        })
      );
    }
  });
}

export function* addNewCrmId() {
  const relevantAction = proposalActions.addNewCrmId.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const { proposalId, crmId } = action.payload;
    try {
      const organizationFromCRMID = yield call(getOrganizationFromCRMID, crmId);

      if (!organizationFromCRMID) {
        yield call(updateProposalWithCustomer, proposalId, crmId);
      } else {
        // check if billing account has a signed CAPT to see if we need leadOrgId
        let leadOrgId: string | undefined;
        const { accountId, organizationId } = organizationFromCRMID;

        const agreementsRequest: GetAgreementRequest = {
          accountId,
          organizationId,
        };
        const agreementConfig: AgreementConfig = yield select(
          (state: RootState) => state.app.appConfig.agreement
        );
        const agreementsResult: Agreement[] = yield call(
          api.agreement.getSignedAPT,
          agreementsRequest,
          agreementConfig
        );

        if (agreementsResult && agreementsResult.length) {
          // make call to GQL
          // need agreementId from the most recent agreement
          const agreementId = agreementsResult[agreementsResult.length - 1].id;
          try {
            leadOrgId = yield call(
              getLeadOrgIdFromAgreement,
              agreementId,
              accountId,
              organizationId
            );
          } catch (err) {
            yield put(
              proposalActions.selectOrganizationAsync.failure({
                message: t('error::Error fetching leadOrgId'),
                exception: err,
              })
            );
            return;
          }
        }

        yield call(
          updateProposalWithCustomer,
          proposalId,
          crmId,
          accountId,
          organizationId,
          leadOrgId
        );
      }

      yield put(proposalActions.addNewCrmId.success(action.payload));
      const customerName: string = yield select(getCustomerName);
      if (customerName && !isDefaultCustomerName(customerName)) {
        yield spawn(searchOrganizationsPropertySheet, customerName);
      }
    } catch (err) {
      yield put(
        proposalActions.addNewCrmId.failure({
          message: t('error::Error adding CRM Id'),
          exception: err,
        })
      );
    }
  });
}

export function* removeCrmId() {
  const relevantAction = proposalActions.removeCRMId;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const quote = cloneProposal(action.payload);
    delete quote.header.opportunityId;
    delete quote.header.engagementId;
    delete quote.header.invitedUser;
    if (quote.header.extendedProperties) {
      delete quote.header.extendedProperties.soldToLegalEntityId;
      delete quote.header.extendedProperties.vlAgreementNumber;
    }
    delete quote.header.endCustomerLegalEntity;
    if (quote.header.soldToCustomerLegalEntity) {
      delete quote.header.soldToCustomerLegalEntity.accountId;
      delete quote.header.soldToCustomerLegalEntity.organizationId;
      delete quote.header.soldToCustomerLegalEntity.organizationVersion;
    }
    const modernOfficeEnabled: boolean = yield select((state: RootState) =>
      getFlightIsEnabled(state, Flight.modernOffice)
    );

    let updatedQuote: Proposal | undefined = undefined;
    // update parent billing account on quote if changed
    if (
      quote.header.extendedProperties &&
      quote.header.extendedProperties.userPreferences &&
      quote.header.extendedProperties.userPreferences.leadOrgId
    ) {
      delete quote.header.extendedProperties.userPreferences.leadOrgId;

      // delete any shared discounts on quote when changing leadOrgId
      const sharedDiscounts = quote.lineItems.filter(lineItem =>
        isExtendedPriceAdjustment(lineItem)
      );
      if (sharedDiscounts) {
        updatedQuote = yield call(deleteLineItems, {
          etag: quote.etag,
          lineItemIds: sharedDiscounts.map((lineItem: LineItem) => lineItem.id),
          proposalId: quote.id,
        });
      }
      // remove deleted line items and update etag
      if (updatedQuote) {
        quote.lineItems = updatedQuote.lineItems;
        quote.etag = updatedQuote.etag;
      }
    }

    quote.lineItems.forEach(item => {
      delete item.assetTo;
      if (
        modernOfficeEnabled &&
        item.productIdentifier.productFamily === ProductFamily.Passes &&
        item.purchaseInstruction &&
        item.purchaseInstruction.termStartDate
      ) {
        const startDate = moment.utc(item.purchaseInstruction.termStartDate);
        if (startDate && startDate.isBefore(moment.utc(), 'day')) {
          delete item.purchaseInstruction.termStartDate;
        }
      }
    });

    yield put(proposalActions.updateProposalAsync.request(buildProposalRequest(quote)));
  });
}
