import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import { OrganizationFragment } from 'features/customer/fragments';
import gql from 'graphql-tag';

import { QuoteObjectIdFragment } from '../fragments';

const PageInfoFragment = gql`
  fragment pageInfoFields on PageInfo {
    id
    hasNextPage
    cursor
  }
`;

export const GetLeadOrganizations = gql`
  query LeadOrganizationsByAccountId($accountId: String, $first: Int, $cursor: String) {
    leadOrganizationsByAccountId(accountId: $accountId, first: $first, cursor: $cursor) {
      edges {
        cursor
        node {
          ...organizationFragment
        }
      }
      pageInfo {
        ...pageInfoFields
      }
    }
  }
  ${OrganizationFragment}
  ${PageInfoFragment}
`;

export const GetModernAgreementPreview = gql`
  query getModernAgreementPreview($quoteId: String!) {
    getModernAgreementPreview(quoteId: $quoteId) {
      documentId
      documentDisplayUri
      documentDownloadUri
    }
  }
`;

export const GetPreapprovedECIFs = gql`
  query getOldQuote($quoteId: String!) {
    getOldQuote(id: $quoteId) {
      header {
        ecifTerms {
          TotalApprovedAmount
          Currency
          RequestNumber
        }
      }
    }
  }
`;

export const GET_APPROVALS = gql`
  query getApprovals($options: GetApprovalsOptions!) {
    getApprovals(options: $options) {
      id
      name
      expirationDate
      status
      assignedTo
      crmLead {
        id
      }
    }
  }
`;

export const GET_QUOTES = gql`
  query getQuotes($query: String, $sort: [OrderInput!]) {
    getQuotes(query: $query, sort: $sort) {
      ...QuoteObjectId
      name
      expirationDate
      status
      assignedTo
      etag
      crmLead {
        __typename
        id
      }
    }
  }
  ${QuoteObjectIdFragment}
`;

export const GET_QUOTE = gql`
  query getQuote($id: String!) {
    getQuote(id: $id) {
      id
      status
      expirationDate
      billingCurrency
      assignedTo
      languages
      market
      name
      etag
      revision
      transactionModel
      crmLead {
        id
        salesAccount {
          address {
            companyName
          }
          id
          tpid
        }
        type
      }
      msContact {
        mail
      }
      soldTo {
        dealEstimate
        organization {
          name
          accountId
          id
          credit {
            decision
            reason
            currency
            creditLine
            remainingBalance
            creditRiskScore
          }
          address {
            companyName
            addressLine1
            addressLine2
            city
            region
            postalCode
            country
          }
          tradeName
        }
        monthlyCreditLimit
      }
      approval {
        ... on Approval {
          history {
            action
            actor {
              ... on User {
                mail
              }
              ... on NonUserActor {
                name
              }
            }
            comments
            createdBy {
              ... on User {
                mail
              }
              ... on NonUserActor {
                name
              }
            }
            createdDate
            status
          }
          requiredApprovals {
            order
            level
            policy
            state
            assignedUser
          }
        }
        ... on QuoteApproval {
          requiredApprovals {
            order
            level
            policy
            state
            assignedUser
          }
        }
      }
      lineItems {
        id
        product {
          id
          productFamily
        }
      }
    }
  }
`;

export const GET_QUOTERECOMMENDATIONS = gql`
  query getQuoteRecommendations($id: String!, $input: CatalogContextInput!) {
    getQuote(id: $id) {
      __typename
      id
      clouds
      transactionModel
      recommendations(catalogContext: $input) {
        __typename
        name
        type
        source
        items {
          ... on ProductRecommendationAction {
            __typename
            product {
              __typename
              id
              title
              maxQuantityOnQuote
              productType
            }
            meta {
              key
              value
            }
          }
          ... on UploadRecommendationAction {
            __typename
            title {
              __typename
              display
            }
            enabled
          }
        }
      }
    }
  }
`;

export const GET_RECOMMENDATIONS = gql`
  query getRecommendations($input: CatalogContextInput!) {
    getRecommendations(input: $input) {
      __typename
      name
      type
      items {
        ... on ProductRecommendationAction {
          __typename
          product {
            __typename
            id
            title
            productType
            maxQuantityOnQuote
          }
          meta {
            key
            value
          }
        }
      }
    }
  }
`;

export const SEARCH_PRODUCTS_FINDER = gql`
  query searchProductsFinder($input: CatalogSearchInput!) {
    searchProducts(input: $input) {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          title
          maxQuantityOnQuote
          productType
        }
      }
      pageInfo {
        __typename
        cursor
      }
    }
  }
`;

export const UPDATE_FAVORITEGROUP = gql`
  mutation updateFavoriteGroup($input: FavoriteProductInput!) {
    updateFavoriteGroup(input: $input) {
      __typename
      name
      type
      source
      items {
        ... on ProductRecommendationAction {
          __typename
          product {
            __typename
            id
            title
            productType
            maxQuantityOnQuote
          }
          meta {
            key
            value
          }
        }
      }
    }
  }
`;

export const RENAME_FAVORITEGROUP = gql`
  mutation renameFavoriteGroup($input: RenameFavoriteGroupInput!) {
    renameFavoriteGroup(input: $input) {
      __typename
      name
      items {
        ... on ProductRecommendationAction {
          __typename
          product {
            __typename
            id
            title
            productType
            maxQuantityOnQuote
          }
          meta {
            key
            value
          }
        }
      }
    }
  }
`;
export const DELETE_FAVORITEGROUP = gql`
  mutation deleteFavoriteGroup($input: DeleteFavoriteGroupInput!) {
    deleteFavoriteGroup(input: $input) {
      __typename
      name
      items {
        ... on ProductRecommendationAction {
          __typename
          product {
            __typename
            id
            title
            productType
            maxQuantityOnQuote
          }
          meta {
            key
            value
          }
        }
      }
    }
  }
`;

export const RoleAssignmentsQuery = gql`
  query RoleAssignments($input: RoleAssignmentsInput!) {
    getRoleAssignments(input: $input) {
      roleId
      principalId
      principalTenantId
    }
  }
`;

export const DELETE_QUOTE = gql`
  mutation deleteQuote($input: QuoteMutationInput!) {
    deleteQuote(input: $input) {
      __typename
    }
  }
`;

/** Mutation to add available shared discounts to a Quote for a CAPT billing account */
export const AddSharedDiscounts = gql`
  mutation addSharedDiscounts($input: QuoteMutationInput!) {
    addSharedDiscounts(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;
