import { defaultDataIdFromObject, IdGetter } from 'apollo-cache-inmemory';
import { Market, User, UserPreferences } from 'generated/graphql';
import { get as getLocalStorageData, localMarketKey } from 'services/local-storage-service';

/**
 * Generates an user object id with given mail
 * @param mail current user email
 */
export const generateUserObjectId = (mail: string) => {
  return `User:${mail}`;
};

/**
 * Gets the default id that identifies any object in cache
 * @returns __typename:id, __typename or id
 */
export const getDefaultObjectId: IdGetter = object => {
  const { __typename, id } = object;
  return __typename && id ? `${__typename}:${id}` : __typename || id;
};

/**
 * Gets the id that identifies the user object in cache
 * @returns __typename:mail
 */
export const getUserObjectId = (user: User) => {
  const { mail } = user;
  return generateUserObjectId(mail);
};

/**
 * Gets the id that identifies the user preferences object in cache
 * @returns __typename:mail
 */
export const getUserPreferencesObjectId = (userPreferences: UserPreferences) => {
  const { __typename, mail } = userPreferences;
  return `${__typename}:${mail}`;
};

/**
 * Gets the id that identifies the object in cache
 */
export const getObjectId: IdGetter = object => {
  const { __typename } = object;
  switch (__typename) {
    case 'User':
      return getUserObjectId(object as User);
    case 'UserPreferences':
      return getUserPreferencesObjectId(object as UserPreferences);
    default:
      return defaultDataIdFromObject(object);
  }
};

/**
 * Returns the market stored in local storage if valid
 */
export const getMarketFromLocalStorage = () => {
  const localStorageMarket = getLocalStorageData<Market>(localMarketKey);
  const validMarketStored =
    !!localStorageMarket && Object.values(Market).includes(localStorageMarket);

  return validMarketStored ? localStorageMarket : Market.Us;
};
