import { Panel } from 'components';
import { PageRoute } from 'features/app/components/NavBar';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import {
  CustomerShortcutsSection,
  GlobalShortcutsSection,
  HomeShortcutsSection,
  QuoteShortcutsSection,
} from './ShortcutsSection';

const keyboardShortcutsPanelStyles = (theme: ThemeProps) => ({
  main: {
    backgroundColor: theme.palette.backgroundStandout,
    borderRadius: 2,
    marginRight: 40,
    marginTop: 71,
    marginBottom: 29,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  overlay: {
    background: theme.palette.backgroundOverlay,
  },
});

export interface KeyboardShortcutsPanelProps {
  location: string;
  isOpen: boolean;
  onOpen: () => void;
  onDismiss: () => void;
}

type Props = KeyboardShortcutsPanelProps & WithStyles<typeof keyboardShortcutsPanelStyles>;

export const KeyboardShortcutsPanelUnstyled: React.FC<Props> = props => {
  const { classes, isOpen, onOpen, onDismiss } = props;
  const { t } = useTranslation();

  useHotkeys(
    'ctrl+.',
    () => {
      isOpen ? onDismiss() : onOpen();
    },
    [isOpen, onOpen, onDismiss]
  );

  const panelStyles = {
    main: classes.main,
    overlay: classes.overlay,
  };

  const getOrderedShortcutsSections = (location: string) => {
    switch (true) {
      case location.includes(PageRoute.quote): {
        return (
          <>
            <QuoteShortcutsSection />
            <HomeShortcutsSection />
            <CustomerShortcutsSection />
          </>
        );
      }
      case location.includes(PageRoute.customer): {
        return (
          <>
            <CustomerShortcutsSection />
            <HomeShortcutsSection />
            <QuoteShortcutsSection />
          </>
        );
      }
      case location.includes(PageRoute.home):
      default: {
        return (
          <>
            <HomeShortcutsSection />
            <QuoteShortcutsSection />
            <CustomerShortcutsSection />
          </>
        );
      }
    }
  };

  return (
    <Panel
      headerText={t('common::Keyboard shortcuts')}
      isLightDismiss
      isOpen={isOpen}
      styles={panelStyles}
      onDismiss={onDismiss}
    >
      <GlobalShortcutsSection />
      {getOrderedShortcutsSections(props.location)}
    </Panel>
  );
};

export const KeyboardShortcutsPanel = withStyles(keyboardShortcutsPanelStyles)(
  KeyboardShortcutsPanelUnstyled
);
