import { ThemeProps } from 'styles';

import { detailsListStyles } from '../styles';

export const styles = (theme: ThemeProps) => {
  const { warningText } = theme.palette;
  return {
    dropdown: {
      width: 232,
    },
    priceSeparator: {
      marginTop: 24,
    },
    priceContainer: {
      marginTop: 12,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    priceLabel: {
      paddingRight: 7,
    },
    dropdowns: {
      marginTop: 24,
      width: 232,
    },
    price: {
      paddingRight: 4,
    },
    dropdownSeparator: {
      paddingTop: 10,
    },
    currency: {
      paddingTop: 7,
    },
    detailsListContainer: {
      overflowY: 'auto',
      maxHeight: 400,
    },

    startDateField: {
      marginTop: 24,
      marginBottom: 12,
    },

    labelContainer: {
      display: 'flex',
    },

    futureStartDateLabel: {
      display: 'block',
      paddingBottom: 5,
      paddingTop: 5,
    },
    startDateNotification: {},
    warningIcon: { color: warningText, marginTop: 2 },
    warningText: {
      marginLeft: 6,
      color: theme.palette.textTertiary,
    },
    detailsListStyles,
  };
};
