import { ButtonAtom } from 'components/atoms';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { approveButtonStyles } from './ApproveButton.styles';

type Props = ButtonSharedProps & WithStyles<typeof approveButtonStyles>;

const ApproveButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <ButtonAtom
      addClass={props.classes.approve}
      ariaLabel={props.ariaLabel || props.text}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: 'CheckMark' }}
      id={props.id}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const ApproveButton = withStyles(approveButtonStyles)(ApproveButtonUnstyled) as React.FC<
  ButtonSharedProps
>;
