import axios from 'axios';
import { TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader } from '../utils';
import { endpoints, FlightsConfig } from './config';
import { Flight } from './flightList';
import { Flights, GetFlights } from './types';

async function getHeaders() {
  return {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
  };
}

export async function getFlights(request: GetFlights, config: FlightsConfig): Promise<Flight[]> {
  const headers = await getHeaders();
  const url = endpoints[config.environment];
  const response = await axios.post<Flights>(url, request, { headers });
  return response.data && response.data.flightList;
}
