import { CalloutCard, ComboBox, TextBody, TextboxStandard } from 'components/ions';
import { useDebounce } from 'components/utilities/debounce';
import { CatalogItemType } from 'features-apollo/quote/types';
import { getStartConditionForDisplay } from 'features-apollo/quote/utils';
import {
  ApplyConfigurationMonetaryInput,
  LineItem,
  MutationApplyConfigurationMonetaryArgs,
  QuoteMutationInput,
  Reason,
  StartCondition,
} from 'generated/graphql';
import i18next from 'i18next';
import moment from 'moment';
import {
  DirectionalHint,
  IComboBox,
  IComboBoxOption,
  IDropdownOption,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { ConfigurationCardCommonProps } from '../Cards/types';
import { getDisplayAmount } from '../utils';
import { styles } from './MonetaryConfigurationCard.styles';
import { ApplyMonetaryConfiguration } from './queries';

export interface MonetaryConfigurationCardProps extends ConfigurationCardCommonProps {
  target: React.RefObject<HTMLSpanElement>;
  isReadOnly?: boolean;
  initialValues?: { amount?: string; goodFor?: string; reason?: string };
  durations: string[];
  boundaries?: {
    amount?: { min?: number | null; max?: number | null };
    goodFor?: { min?: number | null; max?: number | null };
  };
  onDismiss: () => void;
  productTitle: string;
  reasons?: Reason[];
  startCondition?: StartCondition | null;
  isTerm: boolean;
  lineItemId: string;
  quote: QuoteMutationInput;
}
// TODO: Read these from GQL
const defaults = {
  amount: '',
  minAmount: 1,
  maxAmount: 999999999999999,
  goodFor: '12',
  minDuration: 12,
  maxDuration: 36,
};

//TODO: cameneks remove this once the downstream service accepts higher numbers and not overflows
const hardUpperBoundMax = 2147483647;
const maxAmountDigitsAllowed = 15;

export const readBoundaries = (lineItem: LineItem) => {
  if (!lineItem.product) {
    return undefined;
  }

  if (lineItem.sku) {
    const boundaries = {
      amount: {
        min: oc(lineItem).product.amount.minimum(),
        max: oc(lineItem).product.amount.maximum(),
      },
      goodFor: {
        min: oc(lineItem).sku.duration.minimum(),
        max: oc(lineItem).sku.duration.maximum(),
      },
    };
    return boundaries;
  }
};

export const getGoodForError = (value: string, min: number, max: number, focused: boolean) => {
  const goodForErrors = {
    empty: i18next.t('error::Please enter a duration'),
    invalid: i18next.t('error::Invalid month value'),
    lessThanMin: i18next.t('error::Value cannot be less than {{min}}', { min }),
    moreThanMax: i18next.t('error::Value cannot be more than {{max}}', { max }),
    noDecimals: i18next.t('error::Duration cannot have decimals'),
  };

  if (!focused && !value.trim()) {
    return goodForErrors.empty;
  }

  if (!value.trim()) {
    return;
  }

  if (isNaN(+value)) {
    return goodForErrors.invalid;
  } else if (value.includes('.')) {
    return goodForErrors.noDecimals;
  } else if (+value < min) {
    return goodForErrors.lessThanMin;
  } else if (+value > max) {
    return goodForErrors.moreThanMax;
  }
};

const getAmountError = (
  amount: string,
  min: number,
  max: number,
  amountProperties: { fieldTouched: boolean; focused: boolean }
) => {
  const amountErrors = {
    numberOnly: i18next.t('error::Only numbers are valid'),
    empty: i18next.t('error::Please enter an amount'),
    lessThanMin: i18next.t('error::Value cannot be less than {{min}}', { min }),
    moreThanMax: i18next.t('error::Value cannot be more than {{max}}', { max }),
    noDecimals: i18next.t('error::Please enter a number with no decimals'),
  };

  if (!amount.trim() && amountProperties.fieldTouched && !amountProperties.focused) {
    return amountErrors.empty;
  }

  if (!amount.trim()) {
    return;
  }

  if (isNaN(+amount)) {
    return amountErrors.numberOnly;
  } else if (amount.includes('.')) {
    return amountErrors.noDecimals;
  } else if (+amount < min) {
    return amountErrors.lessThanMin;
  } else if (+amount > max) {
    return amountErrors.moreThanMax;
  }
};

const buildGoodForDurationOptionsForTerms = (durations?: string[]) => {
  if (!durations) {
    return [];
  }
  let durationOptions: IDropdownOption[] = [];
  durations.forEach(durationOption => {
    const years = moment.duration(durationOption).asYears();
    const suffix = years > 1 ? i18next.t('years') : i18next.t('year');
    durationOptions.push({
      text: `${years} ${suffix}`,
      key: durationOption,
    });
  });
  return durationOptions;
};

const getInitialGoodForValue = (
  isTerms: boolean,
  initialGoodForValue?: string,
  durations?: string[]
) => {
  if (initialGoodForValue && isTerms) {
    return initialGoodForValue;
  }
  if (initialGoodForValue) {
    return moment
      .duration(initialGoodForValue)
      .asMonths()
      .toString();
  }
  if (isTerms && durations) {
    const durationOptions = buildGoodForDurationOptionsForTerms(durations);
    if (durationOptions.length) {
      return durationOptions[0].key.toString();
    }
  }
  return defaults.goodFor;
};

const buildFieldReasons = (reasons?: Reason[]): IDropdownOption[] => {
  if (!reasons) {
    return [];
  }
  return reasons.map(reason => ({
    text: reason.name,
    key: reason.code,
  }));
};

const getInitialReasonValue = (initialReasonValue?: string, reasons?: Reason[]) => {
  if (!reasons) {
    return;
  }
  if (initialReasonValue) {
    return initialReasonValue;
  }
  const reasonOptions = buildFieldReasons(reasons);
  if (reasonOptions.length) {
    return reasonOptions[0].key.toString();
  }
};

const getGoodForAmount = (goodFor: string, hasErrors: boolean, isFocused: boolean) => {
  return isFocused ? goodFor : `${goodFor} months`;
};

const inputDebounceMilliseconds = 400;

type Props = MonetaryConfigurationCardProps & WithStyles<typeof styles>;

const MonetaryConfigurationCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    initialValues,
    boundaries,
    isTerm,
    durations,
    reasons,
    isReadOnly,
    startCondition,
    classes,
    quote,
    target,
    currency,
    lineItemId,
    productTitle,
  } = props;

  const amount$ = useDebounce(inputDebounceMilliseconds);
  const duration$ = useDebounce(inputDebounceMilliseconds);
  const { t } = useTranslation();
  const [amount, setAmount] = React.useState(
    (initialValues && initialValues.amount) || defaults.amount
  );

  const minAmount = oc(boundaries).amount.min(defaults.minAmount);
  const maxAmount = Math.min(oc(boundaries).amount.max(defaults.maxAmount), hardUpperBoundMax);
  const minDuration = oc(boundaries).goodFor.min(defaults.minDuration);
  const maxDuration = oc(boundaries).goodFor.max(defaults.maxDuration);
  const [amountFieldTouched, setAmountFieldTouched] = React.useState(false);
  const [amountFocused, setAmountFocused] = React.useState(true);
  const [applyConfiguration] = useMutation<{ id: string }, MutationApplyConfigurationMonetaryArgs>(
    ApplyMonetaryConfiguration
  );

  const [amountError, setAmountError] = React.useState(
    initialValues && initialValues.amount
      ? getAmountError(initialValues.amount, minAmount, maxAmount, {
          fieldTouched: amountFieldTouched,
          focused: amountFocused,
        })
      : undefined
  );
  const [goodForDuration, setGoodForDuration] = React.useState(() =>
    getInitialGoodForValue(isTerm, oc(initialValues).goodFor(), durations)
  );
  const [goodForError, setGoodForError] = React.useState(
    initialValues && initialValues.goodFor
      ? getGoodForError(
          getInitialGoodForValue(isTerm, initialValues.goodFor, durations),
          minDuration,
          maxDuration,
          false
        )
      : undefined
  );
  const [goodForFocused, setGoodForFocused] = React.useState(false);
  const [reason, setReason] = React.useState(
    getInitialReasonValue(oc(initialValues).reason(), reasons)
  );

  const applyButtonStrings = { text: t('quote::Apply'), key: t('quote::Apply') };

  const showErrorInAmountField = amount || amountFieldTouched;

  const onChangeAmount = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setAmountFieldTouched(true);
    if (value !== undefined) {
      const shortened = value.slice(0, maxAmountDigitsAllowed);
      setAmount(shortened);
      amount$.next(() =>
        setAmountError(
          getAmountError(shortened, minAmount, maxAmount, {
            fieldTouched: amountFieldTouched,
            focused: amountFocused,
          })
        )
      );
    }
  };

  const onChangeGoodFor = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      setGoodForDuration(value);
      duration$.next(() =>
        setGoodForError(getGoodForError(value, minDuration, maxDuration, goodForFocused))
      );
    }
  };

  const onDurationSelection = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option) {
      setGoodForDuration(option.key.toString());
    }
  };

  const onReasonSelection = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option) {
      setReason(option.key.toString());
    }
  };

  const AmountTextBox = (
    <div className={classes.amountContainer}>
      <TextboxStandard
        autoFocus={true}
        dataAutomationId="monetary-amount-input"
        errorMessage={showErrorInAmountField ? amountError : undefined}
        errorMessageStyle={classes.errorMessageStyle}
        label={t('quote::Amount ({{currency}})', { currency })}
        placeholder={t('quote::Enter amount')}
        readOnly={isReadOnly}
        required
        value={getDisplayAmount(amount, amountFocused)}
        onBlur={() => {
          setAmountFocused(false);
          setAmountError(
            getAmountError(amount, minAmount, maxAmount, {
              fieldTouched: true,
              focused: false,
            })
          );
        }}
        onChange={onChangeAmount}
        onFocus={() => {
          setAmountFocused(true);
        }}
      />
    </div>
  );

  const ReasonsCombobox = (
    <div className={classes.reasonsContainer}>
      <ComboBox
        dataAutomationId="reasonCombobox"
        disabled={isReadOnly}
        label={t('quote::Reason for giving credit')}
        options={buildFieldReasons(reasons)}
        required
        selectedKey={reason}
        onChange={onReasonSelection}
      />
    </div>
  );

  const GoodForTextBox = (
    <div className={classes.goodForContainer}>
      <TextboxStandard
        dataAutomationId="goodFor"
        errorMessage={goodForError}
        label={t('quote::Good for')}
        readOnly={isReadOnly}
        required
        value={getGoodForAmount(goodForDuration, !!goodForError, goodForFocused)}
        onBlur={() => {
          setGoodForFocused(false);
          setGoodForError(getGoodForError(goodForDuration, minDuration, maxDuration, false));
        }}
        onChange={onChangeGoodFor}
        onFocus={() => setGoodForFocused(true)}
      />
    </div>
  );
  const GoodForCombobox = (
    <div className={classes.goodForContainer}>
      <ComboBox
        dataAutomationId="goodFor"
        disabled={isReadOnly}
        label={t('quote::Good for')}
        maxHeight={350}
        options={buildGoodForDurationOptionsForTerms(durations)}
        required
        selectedKey={goodForDuration}
        onChange={onDurationSelection}
      />
    </div>
  );

  const onApply = () => {
    if (amount && goodForDuration) {
      const configuration: ApplyConfigurationMonetaryInput = {
        amount: parseInt(amount),
        duration: goodForDuration[0] === 'P' ? goodForDuration : `P${goodForDuration}M`,
        lineItemId,
        reasonCode: reason,
      };
      applyConfiguration({ variables: { quote, configuration } });
    }
  };

  const startingInformationText = (
    <div className={classes.startingInformation}>
      <div>
        <TextBody addClass={classes.emphasisText}>{t('quote::Starting')}</TextBody>
      </div>
      <div>
        <TextBody>
          {getStartConditionForDisplay({
            catalogItemType: isTerm ? CatalogItemType.Term : CatalogItemType.Product,
            startCondition,
          })}
        </TextBody>
      </div>
    </div>
  );

  let applyDisabled = !!getAmountError(amount, minAmount, maxAmount, {
    fieldTouched: true,
    focused: false,
  });
  if (!isTerm) {
    applyDisabled =
      applyDisabled || !!getGoodForError(goodForDuration, minDuration, maxDuration, false);
  }

  return (
    <CalloutCard
      applyButtonDisabled={applyDisabled}
      applyButtonStrings={applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      directionalHint={DirectionalHint.rightCenter}
      headerText={productTitle}
      id="monetary card"
      isBeakVisible={true}
      isReadOnly={!!isReadOnly}
      maxHeight={750}
      maxWidth={400}
      minWidth={275}
      target={target}
      onApply={() => {
        onApply();
        props.onDismiss();
      }}
      onDismiss={props.onDismiss}
    >
      <div className={classes.container}>
        {AmountTextBox}
        {!reasons && startingInformationText}
        {isTerm ? GoodForCombobox : GoodForTextBox}
        {reasons && !!reasons.length && ReasonsCombobox}
      </div>
    </CalloutCard>
  );
};

export const MonetaryConfigurationCard = withStyles(styles)(MonetaryConfigurationCardUnstyled);
