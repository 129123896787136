import { AgreementCardType } from 'features-apollo/quote/components/ConfigCards';
import { AgreementCardButton } from 'features-apollo/quote/components/ConfigCards/Buttons/AgreementTermButton';
import { QuoteListColumn } from 'features-apollo/quote/components/Lists/types';
import { ModernAgreementParticipant } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';

import { QuoteListRow, SelectionProps } from '../QuoteListRow';
import { getDateContent, PLACEHOLDER_UNDEFINED, textFormatter, titleFormatter } from '../utils';

export interface AgreementTermLineItem {
  id: string;
  startDate?: Date;
  endDate?: Date | null;
  documentDisplayUri?: string | null;
  customerId?: string | null;
  documentFileName?: string | null;
  participants?: (ModernAgreementParticipant | null | undefined)[] | null;
  type: string;
}

export interface AgreementTermRowProps extends SelectionProps {
  /**
   * Quote Id
   */
  quoteId: string;
  /**
    /* Agreement Term line Item
    */
  lineItem: AgreementTermLineItem;
  /**
   * Name of the term.
   */
  agreementTitle: string;
  /**
   * Contains codes representing the current errors on the agreement config
   */
  errors: string[] | null;
  /**
   * Disables configuration card button.
   */
  isQuoteReadOnly?: boolean;
  /**
   * Defines a custom flex CSS rule per content.
   */
  flexContent?: {
    name?: number;
    startDate?: number;
    endDate?: number;
    type?: number;
    configCardButtons?: number[];
  };
}

export const AgreementTermRow: React.FC<AgreementTermRowProps> = props => {
  const { t } = useTranslation();
  const agreementErrorMessage = t('quote::Agreement failed to load');
  const agreementFailedToLoad = !!props.errors;

  const topColumns: QuoteListColumn[] = [
    {
      id: 'listrow-name',
      content: titleFormatter(props.agreementTitle),
      flex: oc(props).flexContent.name(),
    },
    {
      id: 'listrow-start-date',
      content: textFormatter(getDateContent(props.lineItem.startDate)),
      flex: oc(props).flexContent.startDate(),
    },
    {
      id: 'listrow-end-date',
      content: textFormatter(getDateContent(props.lineItem.endDate, true)),
      flex: oc(props).flexContent.endDate(),
    },
    {
      id: 'listrow-type',
      content: textFormatter(props.lineItem.type || PLACEHOLDER_UNDEFINED),
      flex: oc(props).flexContent.type(),
    },
  ];

  const bottomColumns: QuoteListColumn[] = [];

  if (!agreementFailedToLoad) {
    bottomColumns.push({
      id: 'agreement-term-config-card-upload-button',
      content: (
        <AgreementCardButton
          cardType={AgreementCardType.UploadAgreement}
          configuration={props.lineItem.documentFileName || t('quote::upload agreement')}
          disabled={!props.lineItem.id}
          isConfigurable={true}
          isQuoteReadOnly={props.isQuoteReadOnly}
          lineItem={props.lineItem}
          quoteId={props.quoteId}
        />
      ),
      flex: oc(props).flexContent.configCardButtons[0](),
    });
    bottomColumns.push({
      id: 'agreement-term-config-card-add-signatory-button',
      content: (
        <AgreementCardButton
          cardType={AgreementCardType.AddSignatories}
          configuration={
            oc(props).lineItem.participants([]).length
              ? t('quote::signatory information')
              : t('quote::add signatory information')
          }
          disabled={!props.lineItem.documentDisplayUri}
          isConfigurable={true}
          isQuoteReadOnly={props.isQuoteReadOnly}
          lineItem={props.lineItem}
          quoteId={props.quoteId}
        />
      ),
      flex: oc(props).flexContent.configCardButtons[1](),
    });
  }

  const isInWarnState =
    !props.isQuoteReadOnly && !(props.lineItem.participants && props.lineItem.participants.length);

  return (
    <QuoteListRow
      {...props}
      bottomColumns={bottomColumns}
      dataAutomationId={props.agreementTitle || undefined}
      isInErrorState={!!(props.errors && props.errors.length) || agreementFailedToLoad}
      isInWarnState={isInWarnState}
      isSelected={props.selectedIds.includes(props.lineItem.id)}
      lineItem={props.lineItem}
      lineItemId={props.lineItemId}
      message={agreementFailedToLoad ? agreementErrorMessage : undefined}
      topColumns={topColumns}
    />
  );
};
