import axios from 'axios';
import { getAuthHeader, TokenAuthorities } from 'services/utils';

import { AddressValidationConfig, endpoints } from './config';
import { Address, AddressValidationResponse, ValidationMode } from './types';

async function getHeaders() {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
  };
  return headers;
}

export async function validate(
  address: Address,
  config: AddressValidationConfig
): Promise<AddressValidationResponse> {
  const headers = await getHeaders();
  const url = endpoints[config.environment];
  const params = { validationMode: ValidationMode.LightWeight };
  const response = await axios.post<AddressValidationResponse>(url, address, { params, headers });
  return response.data;
}
