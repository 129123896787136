import { ThemeProps } from 'styles';

export const configCardHeaderStyles = (theme: ThemeProps) => {
  return {
    dropdownsContainer: {
      width: '100%',
      marginBottom: 24,
    },
    locationDropdown: {
      width: 216,
      display: 'inline-block',
      paddingRight: 23,
    },
    durationDropdown: {
      width: 216,
      display: 'inline-block',
    },
    divider: {
      marginBottom: '-32px',
    },
  };
};
