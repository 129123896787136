import { LinkButton } from 'components/ions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';
import { REMOVE_CRMID } from './queries';
import { useMutation } from '@apollo/react-hooks';
import { GET_QUOTE_SALES_INFO } from 'features-apollo/quote/components/PropertySheets/Sales/queries';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { openRemoveCrmDialog } from './RemoveCrmIdDialog';

export interface RemoveCrmIdButtonProps {
  onRemoveClick: () => void;
  organizationId?: string;
  addClass?: string;
  etag: string;
  quoteId: string;
}

export const RemoveCrmIdButton = (props: RemoveCrmIdButtonProps) => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const [removeCRMIdGQL, { data }] = useMutation(REMOVE_CRMID, {
    refetchQueries: () => [
      { query: GET_QUOTE_SALES_INFO, variables: { id: props.quoteId } },
      { query: GET_QUOTE, variables: { id: props.quoteId } },
    ],
  });
  const onClick = () => {
    const onRemoveCrmId = () => {
      removeCRMIdGQL({
        variables: {
          input: {
            id: props.quoteId,
            etag: props.etag,
          },
        },
      });
      if (data) props.onRemoveClick();
    };
    if (props.organizationId) {
      openRemoveCrmDialog(context, onRemoveCrmId);
    } else {
      onRemoveCrmId();
    }
  };
  return (
    <LinkButton
      addClass={props.addClass}
      dataAutomationId="removeCrmIdButton"
      displayText={t('quote::remove')}
      size="medium"
      onClick={onClick}
    />
  );
};
