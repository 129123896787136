import gql from 'graphql-tag';

export const GetCrmForReferralDialog = gql`
  query GetCrmForReferralDialog($id: String!) {
    getCRMLead(id: $id) {
      title
      salesAccount {
        id
        address {
          country
        }
      }
      validity(motion: WebDirect)
    }
  }
`;

export const SearchOrganizations = gql`
  query searchOrganizations($query: String!) {
    searchOrganizations(query: $query) {
      id
      accountId
      name
      tradeName
      lastInvoiceDate
      address {
        companyName
        addressLine1
        addressLine2
        addressLine3
        city
        region
        country
      }
    }
  }
`;

export const CreateReferral = gql`
  mutation createReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      id
      etag
      referralLink
      assignedTo
    }
  }
`;

export const CreateClaim = gql`
  mutation createClaim($input: CreateClaimInput!) {
    createClaim(input: $input) {
      id
    }
  }
`;
