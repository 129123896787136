import { RejectButton } from 'components/ions';
import { RejectDialog } from 'features-apollo/quote/components/Dialogs/RejectDialog/RejectDialog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

export const RejectQuoteButton: React.FC<{ quoteLoading: boolean }> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const dialogProps: DialogProps = {
    providedDialog: <RejectDialog />,
  };

  const openDialog = () => {
    context.openDialog(dialogProps);
  };

  return (
    <RejectButton
      dataAutomationId="rejectProposalButton"
      disabled={props.quoteLoading}
      text={t('quote::Reject')}
      onClick={openDialog}
    />
  );
};
