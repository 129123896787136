import { LegacyFootprint, ModernFootprint } from 'features/proposal/types';
import { combineReducers } from 'redux';
import { Account } from 'services/account/types';
import { AddressValidationResponse } from 'services/address-validation/types';
import { Contact } from 'services/crm/types';
import { LeadOrganizationSummary, OrganizationSummary } from 'services/customer/types';
import {
  OrganizationSearchResponse,
  OrganizationSearchResponseItem,
} from 'services/edge-search/types';
import { TenantAdminResponse, RoleAssignedUpn } from 'services/externaluser/types';
import { Asset } from 'services/edge/types';
import { Agreement } from 'services/ldss/types';
import { CustomPrice } from 'services/pricingscope/types';
import { Project } from 'services/project/types';
import { OneAskResult } from 'services/proposal/types';
import { ActionType, createReducer } from 'typesafe-actions';
import { getType } from 'typesafe-actions';

import * as actions from './actions';
import {
  CRMLead,
  Customer,
  CustomerFragment,
  OrganizationWithAccountName,
  SalesAccount,
  TenantProfile,
} from './types';

const reducer = combineReducers({
  customers: combineReducers({
    fragments: combineReducers({
      indexed: createReducer<Record<string, CustomerFragment>, ActionType<typeof actions>>({}),
      ordered: createReducer<string[], ActionType<typeof actions>>([]),
    }),
    entities: combineReducers({
      indexed: createReducer<Record<string, Customer>, ActionType<typeof actions>>({}),
      ordered: createReducer<string[], ActionType<typeof actions>>([]),
    }),
  }),
  customPrices: createReducer<Record<string, CustomPrice[]>, ActionType<typeof actions>>(
    {}
  ).handleAction(actions.loadCustomPricesAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.value,
  })),
  accountOrganizations: createReducer<
    Record<string, OrganizationSummary[]>,
    ActionType<typeof actions>
  >({}).handleAction(actions.loadOrganizationsAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.value,
  })),
  leadOrganization: createReducer<
    Record<string, LeadOrganizationSummary>,
    ActionType<typeof actions>
  >({}).handleAction([actions.loadLeadOrganizationAsync.success], (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.value,
  })),
  organization: createReducer<Record<string, OrganizationSummary>, ActionType<typeof actions>>(
    {}
  ).handleAction(
    [
      actions.loadOrganizationAsync.success,
      actions.saveOrganizationOnEnrollmentLookup,
      actions.updateOrganizationAsync.success,
      actions.addVatIdAsync.success,
      actions.removeVatIdAsync.success,
    ],
    (state, action) => {
      const org = action.payload.value;
      const stateOrg = state[action.payload.id];
      if (action.type === getType(actions.addVatIdAsync.success)) {
        return {
          ...state,
          [action.payload.id]: {
            ...stateOrg,
            version: org.version,
            legalEntity: {
              ...stateOrg.legalEntity,
              taxIds: org.legalEntity.taxIds,
            },
          },
        };
      } else if (action.type === getType(actions.removeVatIdAsync.success)) {
        const updatedTaxIds = org.legalEntity.taxIds;
        if (updatedTaxIds) {
          const removedVatIdIndex = updatedTaxIds.findIndex(taxId => taxId.type === 'vat_id');
          if (removedVatIdIndex >= 0) {
            updatedTaxIds[removedVatIdIndex] = {
              ...updatedTaxIds[removedVatIdIndex],
              endDate: updatedTaxIds[removedVatIdIndex].endDate,
            };
          }
          return {
            ...state,
            [action.payload.id]: {
              ...stateOrg,
              version: org.version,
              legalEntity: {
                ...stateOrg.legalEntity,
                taxIds: updatedTaxIds,
              },
            },
          };
        }
        return state;
      }
      return {
        ...state,
        [action.payload.id]: org,
      };
    }
  ),
  requireVatId: createReducer<boolean, ActionType<typeof actions>>(false).handleAction(
    actions.requireVatId.success,
    (state, action) => action.payload
  ),
  crmContacts: createReducer<Record<string, Contact>, ActionType<typeof actions>>({}).handleAction(
    actions.loadCRMContactAsync.success,
    (state, action) =>
      action.payload
        ? {
            ...state,
            [action.payload.Id]: action.payload,
          }
        : state
  ),
  // [crmId]: crmLead
  CRMLead: createReducer<Record<string, CRMLead | null>, ActionType<typeof actions>>({})
    .handleAction(actions.loadCRMLeadAsync.success, (state, action) => ({
      ...state,
      [action.payload.crmId]: action.payload,
    }))
    .handleAction(actions.loadCRMLeadAsync.failure, (state, action) =>
      action.meta.error ? { ...state, [action.meta.crmId]: null } : state
    ),
  // [accountId]: modernFootprint
  modernFootprint: createReducer<Record<string, ModernFootprint>, ActionType<typeof actions>>(
    {}
  ).handleAction([actions.loadModernFootprintByAccountAsync.success], (state, action) => ({
    ...state,
    [action.payload.accountId]: action.payload,
  })),
  // [enrollment]: legacyFootprint
  legacyFootprint: createReducer<Record<string, LegacyFootprint>, ActionType<typeof actions>>(
    {}
  ).handleAction([actions.loadLegacyFootprintAsync.success], (state, action) => ({
    ...state,
    [action.payload.enrollment]: action.payload,
  })),
  // [salesAccountId]: salesAccount
  salesAccount: createReducer<Record<string, SalesAccount>, ActionType<typeof actions>>(
    {}
  ).handleAction(actions.loadSalesAccountAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload,
  })),
  isStrategicAccount: createReducer<boolean, ActionType<typeof actions>>(false).handleAction(
    actions.setIsStrategicAccount,
    (state, action) => action.payload
  ),
  //[accountId]: account
  account: createReducer<Record<string, Account>, ActionType<typeof actions>>({}).handleAction(
    [
      actions.loadAccountAsync.success,
      actions.addTenantToAccountAsync.success,
      actions.updateAccountDescriptionAsync.success,
    ],
    (state, action) => ({
      ...state,
      [action.payload.id]: action.payload,
    })
  ),
  //[enrollment number]: enrollment
  enrollment: createReducer<Record<string, Agreement | null>, ActionType<typeof actions>>(
    {}
  ).handleAction(actions.loadEnrollmentAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.value,
  })),
  //[enrollment]:project
  project: createReducer<Record<string, Project | null>, ActionType<typeof actions>>(
    {}
  ).handleAction(actions.loadProjectFromEnrollmentAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.value,
  })),
  //[accountId]:tenant
  tenant: createReducer<Record<string, TenantProfile>, ActionType<typeof actions>>({}).handleAction(
    actions.loadTenantAsync.success,
    (state, action) => ({
      ...state,
      [action.payload.id]: action.payload.value,
    })
  ),
  //[tenantId]:tenantName
  tenantNames: createReducer<Record<string, string>, ActionType<typeof actions>>({}).handleAction(
    actions.loadTenantNamesAsync.success,
    (state, action) => {
      if (!action.payload.length) {
        return state;
      }

      const recordsToAdd: Record<string, string> = { ...state };
      action.payload.forEach(item => (recordsToAdd[item.tenantId] = item.tenantDisplayName));
      return recordsToAdd;
    }
  ),
  //[tenantId]:tenantAdmins
  tenantAdmins: createReducer<Record<string, string[]>, ActionType<typeof actions>>(
    {}
  ).handleAction(actions.loadTenantAdminsAsync.success, (state, action) => {
    if (!action.payload.length) {
      return state;
    }
    const recordsToAdd: Record<string, string[]> = { ...state };
    action.payload.forEach((item: TenantAdminResponse) => (recordsToAdd[item.tenantId] = item.upn));
    return recordsToAdd;
  }),
  //ownerAssignment[]
  tenantRoleAssignedUpns: createReducer<RoleAssignedUpn[], ActionType<typeof actions>>(
    []
  ).handleAction(actions.loadTenantUpnsAsync.success, (state, action) => {
    const ownerAssignments = action.payload;
    if (!ownerAssignments.length) {
      return [];
    }
    let newState = [] as RoleAssignedUpn[];
    ownerAssignments.forEach((ownerAssignment: RoleAssignedUpn) => {
      const findAllUpnsInState = newState.filter(
        (stateRoleAssignedUpn: RoleAssignedUpn) => stateRoleAssignedUpn.upn === ownerAssignment.upn
      );
      if (!findAllUpnsInState.length) {
        newState = newState.concat(ownerAssignment);
      } else {
        const findAllRoleIds = findAllUpnsInState.filter(
          (filteredRoleAssignedUpn: RoleAssignedUpn) =>
            filteredRoleAssignedUpn.roleId === ownerAssignment.roleId
        );
        if (!findAllRoleIds.length) {
          newState = newState.concat(ownerAssignment);
        }
      }
    });
    return newState;
  }),
  //[tenantId]:accounts[]
  tenantAccounts: createReducer<Record<string, Account[]>, ActionType<typeof actions>>(
    {}
  ).handleAction(actions.loadAccountsByTenantIdAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload.value,
  })),
  organizationSearch: combineReducers({
    dialogResults: createReducer<OrganizationSearchResponse, ActionType<typeof actions>>({
      items: [],
      totalItems: 0,
    })
      .handleAction(actions.searchOrganizationsForDialogAsync.request, () => ({
        items: [],
        totalItems: 0,
      }))
      .handleAction(
        actions.searchOrganizationsForDialogAsync.success,
        (state, action) => action.payload
      ),
    propertySheetSearchResults: createReducer<
      Record<string, OrganizationSearchResponseItem[] | null>,
      ActionType<typeof actions>
    >({}).handleAction(
      actions.searchOrganizationsForPropertySheetAsync.success,
      (state, action) => ({
        ...state,
        [action.payload.id]: action.payload.value,
      })
    ),
    similarOrganizationResult: createReducer<
      OrganizationWithAccountName | null,
      ActionType<typeof actions>
    >(null)
      .handleAction(actions.searchOrganizationForSimilarOrganizationsAsync.request, () => null)
      .handleAction(
        actions.searchOrganizationForSimilarOrganizationsAsync.success,
        (state, action) => action.payload
      ),
  }),
  //[organizationId]:hasSigned
  signedMCA: createReducer<Record<string, boolean>, ActionType<typeof actions>>({}).handleAction(
    actions.loadAgreementsAsync.success,
    (state, action) => ({
      ...state,
      [action.payload.id]: action.payload.value,
    })
  ),
  //[accountId]:Asset[]
  assets: createReducer<Record<string, Asset[]>, ActionType<typeof actions>>({}).handleAction(
    actions.getAssetsByOrganizationAsync.success,
    (state, action) => ({
      ...state,
      [action.payload.id]: action.payload.value,
    })
  ),
  addressValidated: createReducer<AddressValidationResponse | null, ActionType<typeof actions>>(
    null
  )
    .handleAction(actions.validateAddressAsync.request, () => null)
    .handleAction(actions.resetAddressValidation, () => null)
    .handleAction(actions.validateAddressAsync.success, (state, action) => action.payload),
  hasAvsApiFailure: createReducer<boolean, ActionType<typeof actions>>(false)
    .handleAction(actions.validateAddressAsync.failure, () => true)
    .handleAction(actions.resetAddressValidation, () => false),
  oneAskResults: createReducer<OneAskResult[], ActionType<typeof actions>>([]).handleAction(
    actions.loadPreapprovedECIFsAsync.success,
    (state, action) => action.payload
  ),
  finishedUpload: createReducer<boolean, ActionType<typeof actions>>(false)
    .handleAction(actions.uploadDocumentsAsync.success, () => true)
    .handleAction(actions.uploadDocumentsStateReset, () => false),
});

export default reducer;
