import {
  CalloutCalendarProps,
  CalloutCard,
  DetailsList,
  InfoButton,
  Label,
  SectionSeparator,
  SmallIcon,
  TextBody,
  TextBodySmall,
  TextCurrency,
  TextPrice,
} from 'components/ions';
import { CalendarDropdown } from 'components/molecules';
import { calendarStrings } from 'components/utilities/calendarStrings';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { applyConfigurationCardWithDialog } from 'features-apollo/quote/components/ConfigCards/utils';
import { removeTypeName } from 'features-apollo/utils';
import { convertDateToUTC, formatCurrency, lineItemStartDateInPast } from 'features/proposal/utils';
import {
  ApplyConfigurationSingleSkuInput,
  CatalogAction,
  QuoteMutationInput,
  Sku,
  Term,
  TermComponent,
  TermDuration,
} from 'generated/graphql';
import { useFabricSelectionSimple } from 'hooks/useFabricSelection';
import {
  CheckboxVisibility,
  ColumnActionsMode,
  DirectionalHint,
  IColumn,
  IComboBox,
  IComboBoxOption,
  IDropdownOption,
  Selection,
  SelectionMode,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { getUnique } from 'services/utils';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { AddAsNewLineItemButton } from '../AddAsNewLineItemButton';
import { ApplyConfigurationSingleSku } from '../queries';
import { ConfigurationCardCommonProps, SkuConfigurationCardCommonProps, View } from '../types';
import { filterOutSkus, getAllTermsFromTheSku, getMatchedAvailability } from '../utils';
import { styles } from './ModernOfficeConfigurationCard.styles';
import { ModernOfficeDropdowns } from './ModernOfficeDropdown';
import { ModernOfficeFallbackDropdown } from './ModernOfficeFallbackDropdown';
import { GetModernOfficeProduct } from './queries';
import { getSelectedSku, isBillingPlan, isTermDuration } from './utils';

export interface ModernOfficeConfigurationCardProps
  extends ConfigurationCardCommonProps,
    SkuConfigurationCardCommonProps {
  productId: string;
  lineItemId: string;
  quote: QuoteMutationInput;
  skus: Sku[];
  readOnly?: boolean;
  initialValues?: { skuId: string; termId: string; startDate?: Date };
  enrollmentNumber?: string;
  target: React.RefObject<HTMLSpanElement>;
  onDismiss: () => void;
}

// #region Fallback logic
// Typically most of the modern office products we want to support has term duration and billing plans, in the case of the sku we selected does not
// we mark it as a fallbackSKU
const isFallbackSku = (sku?: Sku) => {
  if (!sku) {
    return false;
  }
  const terms = getAllTermsFromTheSku(sku);
  const isTermComponentsMissing = terms.some(term => !term.components || !term.components.length);
  if (isTermComponentsMissing) {
    return true;
  }
  const missingTermDuration = terms.some(
    term => !term.components || !term.components.some(component => isTermDuration(component))
  );

  const missingBillingPlan = terms.some(
    term => !term.components || !term.components.some(component => isBillingPlan(component))
  );
  return missingTermDuration || missingBillingPlan;
};

const fallbackSelection = (sku: Sku, termId?: string) => {
  const terms = getAllTermsFromTheSku(sku);
  const shouldUpdateTermId = !terms.some(term => term.termId === termId);
  if (shouldUpdateTermId) {
    const firstTermId = terms[0].termId;
    return firstTermId;
  }
};

const buildTermDescriptionDropdownOptions = (sku: Sku) => {
  const terms = getAllTermsFromTheSku(sku);
  return terms.map(term => {
    return { key: term.termId, text: term.description };
  });
};
//#endregion

const findTermWithBillingPeriodAndTermDuration = (
  billingPeriod: string,
  termDuration: string,
  terms: Term[]
) => {
  return terms.find(term => {
    if (term.components) {
      const hasTheSameTermDuration = term.components.some(
        component => isTermDuration(component) && component.duration === termDuration
      );
      const hasTheSameBillingPeriod = term.components.some(
        component => isBillingPlan(component) && component.billingPeriod === billingPeriod
      );
      return hasTheSameTermDuration && hasTheSameBillingPeriod;
    }
    return false;
  });
};

const getBillingPeriodFromTerm = (term: Term) => {
  if (term.components) {
    const existingBillingPlan: TermComponent | undefined = term.components.find(component =>
      isBillingPlan(component)
    );
    if (existingBillingPlan && isBillingPlan(existingBillingPlan)) {
      return existingBillingPlan.billingPeriod;
    }
  }
};

const extractAllTermDurationsFromSku = (sku: Sku) => {
  const terms = getAllTermsFromTheSku(sku);
  const extracted: TermDuration[] = [];
  terms.forEach(term => {
    if (term.components) {
      term.components.forEach(termComponent => {
        if (isTermDuration(termComponent)) {
          extracted.push(termComponent);
        }
      });
    }
  });
  return extracted;
};

const buildTermDurationDropdownOptions = (sku: Sku) => {
  const termDurations = extractAllTermDurationsFromSku(sku);
  const uniqueTermDurations = getUnique(termDurations, termDuration => termDuration.duration);
  const options = uniqueTermDurations.map(termDuration => {
    return { key: termDuration.duration, text: termDuration.title };
  });
  return options;
};

const buildBillingPlanDropdownOptions = (selectedTermDuration: string, sku: Sku) => {
  const terms = getAllTermsFromTheSku(sku);
  const billingPlans: { key: string; text: string }[] = [];
  terms.forEach(term => {
    if (term.components) {
      const termDuration = term.components.find(
        termComponent =>
          isTermDuration(termComponent) && termComponent.duration === selectedTermDuration
      );
      if (termDuration) {
        const billingPlan = term.components.find(termComponent => isBillingPlan(termComponent));
        if (billingPlan) {
          billingPlans.push({ key: term.termId, text: billingPlan.title });
        }
      }
    }
  });
  return billingPlans;
};

const selection = (sku: Sku, termId?: string) => {
  const terms = getAllTermsFromTheSku(sku);
  const shouldUpdateTermId = !terms.some(term => term.termId === termId);
  if (shouldUpdateTermId) {
    const termDurationOptions = buildTermDurationDropdownOptions(sku);
    const newTermDuration = termDurationOptions[0].key;
    const billingPlanOptions = buildBillingPlanDropdownOptions(newTermDuration, sku);
    const newTermId = billingPlanOptions[0].key;
    return { newTermDuration, newTermId };
  }
};

const onSkuSelection = (
  sku: Sku | undefined,
  setTermId: (termId: string | undefined) => void,
  setTermDuration: (termDuration: string | undefined) => void,
  termId?: string,
  termDuration?: string
) => {
  if (sku) {
    const isFallback = isFallbackSku(sku);
    if (isFallback) {
      const newTermId = fallbackSelection(sku, termId);
      if (newTermId) {
        setTermId(newTermId);
      }
    } else {
      const newKeys = selection(sku, termId);
      if (newKeys) {
        setTermId(newKeys.newTermId);
        setTermDuration(newKeys.newTermDuration);
      }
    }
  } else {
    // reset term information
    if (termId) {
      setTermId(undefined);
    }
    if (termDuration) {
      setTermDuration(undefined);
    }
  }
};

export interface ModernOfficeConfigurationCardResponse {
  //FIXME: not the correct typing!, going to fix it
  skus: Sku[];
}

const onTermDurationDropdownSelect = (sku: Sku, newTermDuration: string, termId?: string) => {
  const terms = getAllTermsFromTheSku(sku);
  const currentlySelectedTerm = terms.find(t => t.termId === termId);
  if (currentlySelectedTerm && currentlySelectedTerm.components) {
    const billingPeriod = getBillingPeriodFromTerm(currentlySelectedTerm);
    if (billingPeriod) {
      const matchedTerm = findTermWithBillingPeriodAndTermDuration(
        billingPeriod,
        newTermDuration,
        terms
      );
      // we want to keep the same billing plan option if we find it again
      if (matchedTerm) {
        return { newTermId: matchedTerm.termId, newTermDuration };
      }
    }
  }
  //otherwise just default to the first one
  const billingPlanOptions = buildBillingPlanDropdownOptions(newTermDuration, sku);
  const firstOption = billingPlanOptions.length && billingPlanOptions[0].key.toString();
  if (firstOption) {
    return { newTermId: firstOption, newTermDuration: newTermDuration };
  }
  return { newTermDuration };
};

const getPrice = (sku?: Sku, selectedTerm?: string) => {
  if (!sku || !selectedTerm) {
    return null;
  }
  const allTerms = getAllTermsFromTheSku(sku);
  const matchedTerm = allTerms.find(term => term.termId === selectedTerm);
  return matchedTerm && matchedTerm.price != null ? matchedTerm.price : null;
};

const toUtcFormattedDate = (date: Date) => {
  const startDate = convertDateToFormattedString(convertDateToUTC(date), LocaleDateFormat.ll);
  return startDate;
};

type Props = ModernOfficeConfigurationCardProps & WithStyles<typeof styles>;

const ModernOfficeConfigurationCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    productId,
    classes,
    catalogContext,
    currency,
    target,
    lineItemId,
    enrollmentNumber,
    quote,
    initialValues,
    onDismiss,
    readOnly,
    alreadyHasDiscount,
    productTitle,
  } = props;
  const { data: skusData } = useQuery<{
    getProduct: ModernOfficeConfigurationCardResponse;
  }>(GetModernOfficeProduct, {
    variables: {
      id: productId,
      catalogContext: removeTypeName(catalogContext),
      quoteId: quote.id,
    },
  });

  const AT_ORDER_PLACEMENT = 'At order placement';

  const [applyConfiguration, { loading }] = useMutation(ApplyConfigurationSingleSku);
  const dialogContext = React.useContext(DialogContext);

  const emptyTitleColumn: IColumn = {
    name: '',
    fieldName: 'title',
    key: 'title',
    isRowHeader: true,
    isResizable: false,
    isSorted: false,
    minWidth: 0,
    columnActionsMode: ColumnActionsMode.disabled,
  };

  const [skuId, setSkuId] = React.useState<string | undefined>(
    initialValues && initialValues.skuId
  );

  const { t } = useTranslation();
  const skus = filterOutSkus(oc(skusData).getProduct.skus(props.skus));
  const [termId, setTermId] = React.useState<string | undefined>(undefined);
  const [termDuration, setTermDuration] = React.useState<string | undefined>(undefined);
  const [startDate, setStartDate] = React.useState<string>(
    initialValues && initialValues.startDate
      ? toUtcFormattedDate(initialValues.startDate)
      : AT_ORDER_PLACEMENT
  );
  const [startDateOptions, setStartDateOptions] = React.useState<IComboBoxOption[]>(
    initialValues && initialValues.startDate
      ? [
          {
            key: toUtcFormattedDate(initialValues.startDate),
            text: toUtcFormattedDate(initialValues.startDate),
          },
        ]
      : [{ key: AT_ORDER_PLACEMENT, text: t('quote::At order placement') }]
  );
  const [initialSelectionIsSet, setInitialSelectionIsSet] = React.useState<boolean>(false);
  const [view, setView] = React.useState<View>(View.CardContent);

  const onSelectionChanged = (selection: Selection) => {
    const newSelection = selection && selection.getSelection()[0];
    const skuId = newSelection && newSelection.key;
    if (skuId) {
      setSkuId(skuId.toString());
    } else {
      setSkuId(undefined);
    }
  };
  const selection = useFabricSelectionSimple(onSelectionChanged);
  const onApply = (skuId: string, termId: string, configureAsNew: boolean) => {
    const availability = getMatchedAvailability(skuId, termId, skus || [], 'Modern Office');
    if (!availability) {
      return;
    }
    const configuration: ApplyConfigurationSingleSkuInput = {
      action: CatalogAction.Purchase,
      skuId,
      availabilityId: availability.availabilityId,
      lineItemId,
      termId,
      configureAsNew,
    };

    if (configureAsNew) {
      applyConfiguration({ variables: { quote, configuration } });
    } else if (alreadyHasDiscount) {
      setView(View.ConfirmationDialog);
      applyConfigurationCardWithDialog(
        () => {
          applyConfiguration({ variables: { quote, configuration } });
          onDismiss();
        },
        () => setView(View.CardContent),
        dialogContext
      );
    } else {
      applyConfiguration({ variables: { quote, configuration } });
      onDismiss();
    }
  };

  React.useEffect(() => {
    if (!initialSelectionIsSet && selection && skus && skus.length && initialValues && skuId) {
      const items = (skus || []).map((sku: Sku) => {
        return { ...sku, key: sku.skuId, selectable: true };
      });
      selection.setItems(items);
      selection.setKeySelected(skuId, true, false);
      setTermId(initialValues && initialValues.termId);
      const sku = getSelectedSku(skus, skuId);
      if (sku && !isFallbackSku(sku) && initialValues.termId) {
        const term = getAllTermsFromTheSku(sku).find(term => term.termId === initialValues.termId);
        if (term && term.components) {
          const termDuration = term.components.find(t => isTermDuration(t));
          if (termDuration && isTermDuration(termDuration)) {
            setTermDuration(termDuration.duration);
          }
        }
      }
      setInitialSelectionIsSet(true);
    }
  }, [skus, skuId, initialSelectionIsSet, initialValues, selection]);

  const sku = getSelectedSku(skus, skuId);
  // This one is a bit weird implementation, I almost hear you saying
  // Why do a selection in the render?
  // This will not loop because we are only setting things if they change
  // The main reason is the way selection works in Fabric components
  // If you indeed have improvement suggestions about these please let me know
  onSkuSelection(sku, setTermId, setTermDuration, termId);

  const items = (skus || []).map((sku: Sku) => {
    return { ...sku, key: sku.skuId, selectable: true };
  });

  const fallback = isFallbackSku(sku);

  let termDurationOptions: IDropdownOption[] = [];
  let billingPlanOptions: IDropdownOption[] = [];
  let termDescriptionOptions: IDropdownOption[] = [];

  if (sku) {
    termDurationOptions = buildTermDurationDropdownOptions(sku);
    termDescriptionOptions = buildTermDescriptionDropdownOptions(sku);
    if (termDuration) {
      billingPlanOptions = buildBillingPlanDropdownOptions(termDuration, sku);
    }
  }

  const skuList = (
    <div className={classes.detailsListContainer}>
      <DetailsList
        addClass={classes.detailsListStyles}
        checkboxVisibility={CheckboxVisibility.always}
        columns={[emptyTitleColumn]}
        items={items}
        selection={selection}
        selectionMode={readOnly ? SelectionMode.none : SelectionMode.single}
      />
      <SectionSeparator />
    </div>
  );

  const todayForCalendar = convertDateToUTC(new Date(Date.now()));
  let startDatePickedOnCalendar = todayForCalendar;
  if (startDate && startDate !== AT_ORDER_PLACEMENT) {
    startDatePickedOnCalendar = new Date(startDate);
  }
  const calloutCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      ariaLabel: t('quote::Open Calendar'),
    },
    calendarStrings,
    today: todayForCalendar,
    defaultDate: startDatePickedOnCalendar,
    isDayPickerVisible: true,
    onSelectDate: date => {
      const dateString = convertDateToFormattedString(date, LocaleDateFormat.ll);
      setStartDate(dateString);
      setStartDateOptions(startDateOptions.concat({ key: dateString, text: dateString }));
    },
    dataAutomationId: 'start-date-calendar',
    minDate: todayForCalendar,
  };

  const onCalendarDropdownSelect = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (option) {
      const dateString = option.key.toString();
      setStartDate(dateString);
    }
  };

  const renderStartDateCustomLabel = () => (
    <div className={classes.labelContainer}>
      <Label
        className={enrollmentNumber ? undefined : classes.futureStartDateLabel}
        htmlFor="start-date-calendar-dropdown"
      >
        {t('quote::Starting')}
      </Label>
      {enrollmentNumber && (
        <InfoButton
          ariaLabel={t('quote::Open information about enrollment dates')}
          calloutProps={{
            closeButtonAriaLabel: t('Close'),
            headline: t('quote::Enrollment-set start dates'),
            maxWidth: 288,
          }}
          id="start-date-info-button"
        >
          <TextBody>
            {t(
              'quote::The date in the "Starting" field has been initially set based on the end date of the enrollment number on the quote plus one day to ensure continuing service for the customer. You can set a future date, but please confirm that your customer will not be out of service.'
            )}
          </TextBody>
        </InfoButton>
      )}
    </div>
  );

  const calendar = (
    <div className={classes.startDateField}>
      <div className={classes.dropdown}>
        <CalendarDropdown
          calloutCalendarProps={calloutCalendarProps}
          dataAutomationId="startDateCalendar"
          dateErrorMessage={
            !enrollmentNumber && startDate && lineItemStartDateInPast(new Date(startDate))
              ? t('quote::Start date cannot occur in the past')
              : undefined
          }
          dropdownDisabled={readOnly}
          dropdownOptions={startDateOptions}
          id="start-date-calendar-dropdown"
          label={t('quote::Starting')}
          selected={startDate}
          onDropdownSelect={onCalendarDropdownSelect}
          onRenderLabel={renderStartDateCustomLabel}
        />
      </div>
      {enrollmentNumber &&
        initialValues &&
        initialValues.startDate &&
        toUtcFormattedDate(initialValues.startDate) !== startDate && (
          <div className={classes.startDateNotification}>
            <SmallIcon addClass={classes.warningIcon} iconName="Warning" />
            <TextBodySmall addClass={classes.warningText}>
              {t("quote::The initial date was set based off of the customer's enrollment.")}
              <div />
              {t('quote::Please ensure that the customer will not be out of service.')}
            </TextBodySmall>
          </div>
        )}
    </div>
  );

  const dropdowns = fallback ? (
    <ModernOfficeFallbackDropdown
      readOnly={readOnly}
      termDescriptionOptions={termDescriptionOptions}
      termId={termId}
      onTermDescriptionSelection={(termId: string) => setTermId(termId)}
    />
  ) : (
    <div>
      <ModernOfficeDropdowns
        dropdownOptions={{ termDuration: termDurationOptions, billingPlans: billingPlanOptions }}
        readOnly={readOnly}
        select={{
          billingPlan: (termId: string) => setTermId(termId),
          termDuration: (termDuration: string) => {
            if (!sku) {
              throw new Error('Modern Office Term duration selection executed without a Sku');
            }
            const newKeys = onTermDurationDropdownSelect(sku, termDuration, termId);
            if (newKeys.newTermDuration) {
              setTermDuration(newKeys.newTermDuration);
            }
            if (newKeys.newTermId) {
              setTermId(newKeys.newTermId);
            }
          },
        }}
        selected={{ termDuration, termId }}
        sku={sku}
      />
    </div>
  );

  const price = getPrice(sku, termId);
  const priceAmount = price !== null ? formatCurrency(price.amount) : '-';

  const priceDisplay = (
    <div>
      <SectionSeparator addClass={classes.priceSeparator} />
      <div className={classes.priceContainer}>
        <TextBody addClass={classes.priceLabel}>
          <Trans ns="quote">
            Subscription price (per seat):{' '}
            <TextPrice addClass={classes.price}>{{ priceAmount }}</TextPrice>
          </Trans>
        </TextBody>
        <TextCurrency addClass={classes.currency}>{currency}</TextCurrency>
      </div>
    </div>
  );

  const isApplyEnabled = sku && termId;

  return (
    <CalloutCard
      applyButtonDisabled={!isApplyEnabled}
      applyButtonStrings={{ text: t('quote::Apply'), ariaLabel: t('quote::Apply') }}
      closeButtonAriaLabel={t('quote::Close')}
      directionalHint={DirectionalHint.rightCenter}
      headerText={productTitle}
      hidden={view === View.ConfirmationDialog}
      id="modernOfficeCard"
      isBeakVisible={true}
      isReadOnly={!!readOnly}
      minWidth={400}
      otherFooterButtons={
        <AddAsNewLineItemButton
          disabled={!isApplyEnabled || readOnly}
          loading={loading}
          onClick={() => {
            sku && termId && onApply(sku.skuId, termId, true);
          }}
        />
      }
      target={target}
      onApply={() => {
        sku && termId && onApply(sku.skuId, termId, false);
      }}
      onDismiss={onDismiss}
    >
      <div>
        {skuList}
        {calendar}
        <SectionSeparator />
        <div className={classes.dropdowns}>{dropdowns}</div>
        <div>{priceDisplay}</div>
      </div>
    </CalloutCard>
  );
};
export const ModernOfficeConfigurationCard = withStyles(styles)(
  ModernOfficeConfigurationCardUnstyled
);
