import { H4Atom, TextSharedProps } from 'components/atoms/Text';
import * as React from 'react';

export const TextSubsectionHeading: React.FC<TextSharedProps> = props => {
  return (
    <H4Atom addClass={props.addClass} id={props.id}>
      {props.children}
    </H4Atom>
  );
};
