import * as actions from 'features/catalog/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { ConstraintsConfig } from 'services/constraints/config';
import { ValidateConstraintsResponse } from 'services/constraints/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

export function* validateQuantityConstraint() {
  const relevantAction = actions.validateQuantityConstraintAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const constraintConfig: ConstraintsConfig = yield select(
      (state: RootState) => state.app.appConfig.constraints
    );

    try {
      const validatedContraints: ValidateConstraintsResponse = yield call(
        api.constraints.validateQuantityConstraints,
        action.payload,
        constraintConfig
      );
      yield put(actions.validateQuantityConstraintAsync.success(validatedContraints));
    } catch (err) {
      yield put(
        actions.validateQuantityConstraintAsync.failure({
          message: t('error::Error validating product quantity.'),
          exception: err,
        })
      );
    }
  });
}
