import { Dialog, PrimaryButton } from 'components';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { Fail, Processing } from 'features-apollo/components/dialogs';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import { useMutation } from '@apollo/react-hooks';

import { UpdateBillingContact } from '../queries';
import { BillingContactBody } from './BillingContactBody';

export interface BillingContactDialogProps {
  accountId: string;
  organizationId: string;
  quoteId: string;
  email?: string;
  phoneNumber?: string;
}

enum DialogViews {
  BillingContact,
  Processing,
}

const dimensions = {
  height: 612,
  width: 600,
};

export const BillingContactDialog: React.FC<BillingContactDialogProps> = props => {
  const dialog = useContext(DialogContext);
  const { accountId, organizationId, quoteId } = props;
  const [dialogState, setDialogState] = React.useState<DialogViews>(DialogViews.BillingContact);
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string | undefined>(props.email);
  const [isEmailValid, setIsEmailValid] = React.useState<boolean | undefined>(
    !!props.email || undefined
  );
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(props.phoneNumber);

  const [updateBillingContact, { loading, error }] = useMutation(UpdateBillingContact, {
    refetchQueries: () => [{ query: GET_QUOTE, variables: { id: quoteId } }],
  });

  const title = t('quote::Billing account contact information');

  const onEmailUpdate = (email?: string, isValid?: boolean) => {
    setEmail(email);
    setIsEmailValid(isValid);
  };

  const updateContact = () => {
    if (email && isEmailValid && phoneNumber) {
      setDialogState(DialogViews.Processing);
      updateBillingContact({
        variables: {
          input: { email, phoneNumber, organizationIdentifier: { organizationId, accountId } },
        },
      });
    }
  };

  const saveButton = (
    <PrimaryButton
      disabled={!email || !phoneNumber || !isEmailValid}
      text={t('quote::Save')}
      onClick={updateContact}
    />
  );

  const billingContactDialogView = (
    <Dialog footerButtons={saveButton} height={612} title={title} width={600}>
      <BillingContactBody
        email={email}
        phoneNumber={phoneNumber}
        setEmail={onEmailUpdate}
        setPhoneNumber={setPhoneNumber}
      />
    </Dialog>
  );

  switch (dialogState) {
    case DialogViews.Processing:
      if (error) {
        return (
          <Fail
            {...dimensions}
            dataAutomationId="saveBillingContactFail"
            message={t('quote::Error saving billing contact information')}
            onTryAgainClick={updateContact}
          />
        );
      } else if (loading) {
        return (
          <Processing {...dimensions} message1={t('quote::Updating billing contact information')} />
        );
      } else {
        dialog.closeDialog();
        return null;
      }
    case DialogViews.BillingContact:
    default:
      return billingContactDialogView;
  }
};

export const openBillingContactDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: BillingContactDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <BillingContactDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
