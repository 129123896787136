import { IComboBoxOption } from 'office-ui-fabric-react';

export type Language =
  | 'af-ZA'
  | 'ar-AE'
  | 'ar-EG'
  | 'ar-IL'
  | 'ar-SA'
  | 'bg-BG'
  | 'ca-ES'
  | 'cs-CZ'
  | 'da-DK'
  | 'de-CH'
  | 'de-DE'
  | 'de-LU'
  | 'el-GR'
  | 'en-AU'
  | 'en-CA'
  | 'en-GB'
  | 'en-IE'
  | 'en-US'
  | 'en-ZA'
  | 'es-CR'
  | 'es-ES'
  | 'et-EE'
  | 'eu-ES'
  | 'fi-FI'
  | 'fil-PH'
  | 'fr-BE'
  | 'fr-CA'
  | 'fr-CH'
  | 'fr-FR'
  | 'gl-ES'
  | 'he-IL'
  | 'hr-HR'
  | 'hu-HU'
  | 'id-ID'
  | 'it-CH'
  | 'it-IT'
  | 'ja-JP'
  | 'ko-KR'
  | 'lv-LV'
  | 'ms-MY'
  | 'nb-NO'
  | 'nl-BE'
  | 'nl-NL'
  | 'nn-NO'
  | 'pl-PL'
  | 'pt-BR'
  | 'pt-PT'
  | 'ro-RO'
  | 'ru-RU'
  | 'sk-SK'
  | 'sl-SI'
  | 'sr-cyrl-RS'
  | 'sr-latn-RS'
  | 'sv-SE'
  | 'th-TH'
  | 'tr-TR'
  | 'uk-UA'
  | 'vi-VN'
  | 'zh-CN'
  | 'zh-Hant'
  | 'zh-HK'
  | 'zh-TW';

export const defaultLanguage: Language = 'en-US';

export interface LanguageInfo {
  display: string;
  twoLetterCode: string;
}

export const languageInfo: Record<Language, LanguageInfo> = {
  'af-ZA': { display: 'Afrikaans', twoLetterCode: 'af' },
  'ar-AE': { display: 'Arabic', twoLetterCode: 'ar' },
  'ar-EG': { display: 'Arabic', twoLetterCode: 'ar' },
  'ar-IL': { display: 'Arabic', twoLetterCode: 'ar' },
  'ar-SA': { display: 'Arabic', twoLetterCode: 'ar' },
  'bg-BG': { display: 'Bulgarian', twoLetterCode: 'bg' },
  'ca-ES': { display: 'Catalan', twoLetterCode: 'ca' },
  'cs-CZ': { display: 'Czech', twoLetterCode: 'cs' },
  'da-DK': { display: 'Danish', twoLetterCode: 'da' },
  'de-CH': { display: 'German', twoLetterCode: 'de' },
  'de-DE': { display: 'German', twoLetterCode: 'de' },
  'de-LU': { display: 'German (LU)', twoLetterCode: 'de' },
  'el-GR': { display: 'Greek', twoLetterCode: 'el' },
  'en-AU': { display: 'English (AU)', twoLetterCode: 'en' },
  'en-CA': { display: 'English (CA)', twoLetterCode: 'en' },
  'en-GB': { display: 'English (UK)', twoLetterCode: 'en' },
  'en-IE': { display: 'English (IE)', twoLetterCode: 'en' },
  'en-US': { display: 'English (US)', twoLetterCode: 'en' },
  'en-ZA': { display: 'English (ZA)', twoLetterCode: 'en' },
  'es-CR': { display: 'Spanish (CR)', twoLetterCode: 'es' },
  'es-ES': { display: 'Spanish (ES)', twoLetterCode: 'es' },
  'et-EE': { display: 'Estonian', twoLetterCode: 'et' },
  'eu-ES': { display: 'Basque', twoLetterCode: 'eu' },
  'fi-FI': { display: 'Finnish', twoLetterCode: 'fi' },
  'fil-PH': { display: 'Filipino', twoLetterCode: 'fil' }, //TODO: this is more than two letters
  'fr-BE': { display: 'French', twoLetterCode: 'fr' },
  'fr-CA': { display: 'French', twoLetterCode: 'fr' },
  'fr-CH': { display: 'French', twoLetterCode: 'fr' },
  'fr-FR': { display: 'French', twoLetterCode: 'fr' },
  'gl-ES': { display: 'Galician', twoLetterCode: 'gl' },
  'he-IL': { display: 'Hebrew', twoLetterCode: 'he' },
  'hr-HR': { display: 'Croatian', twoLetterCode: 'hr' },
  'hu-HU': { display: 'Hungarian', twoLetterCode: 'hu' },
  'id-ID': { display: 'Indonesian', twoLetterCode: 'id' },
  'it-CH': { display: 'Italian', twoLetterCode: 'it' },
  'it-IT': { display: 'Italian', twoLetterCode: 'it' },
  'ja-JP': { display: 'Japanese', twoLetterCode: 'ja' },
  'ko-KR': { display: 'Korean', twoLetterCode: 'ko' },
  'lv-LV': { display: 'Latvian', twoLetterCode: 'lv' },
  'ms-MY': { display: 'Malay', twoLetterCode: 'ms' },
  'nb-NO': { display: 'Norwegian (Bokmal)', twoLetterCode: 'nb' },
  'nl-BE': { display: 'Dutch', twoLetterCode: 'nl' },
  'nl-NL': { display: 'Dutch', twoLetterCode: 'nl' },
  'nn-NO': { display: 'Norwegian Nynorsk', twoLetterCode: 'nn' },
  'pl-PL': { display: 'Polish', twoLetterCode: 'pl' },
  'pt-BR': { display: 'Portuguese', twoLetterCode: 'pt' },
  'pt-PT': { display: 'Portuguese (Portugal)', twoLetterCode: 'pt' },
  'ro-RO': { display: 'Romanian', twoLetterCode: 'ro' },
  'ru-RU': { display: 'Russian', twoLetterCode: 'ru' },
  'sk-SK': { display: 'Slovak', twoLetterCode: 'sk' },
  'sl-SI': { display: 'Slovenian', twoLetterCode: 'sl' },
  'sr-cyrl-RS': { display: 'Serbian (Cyrillic)', twoLetterCode: 'sr' },
  'sr-latn-RS': { display: 'Serbian (Latin)', twoLetterCode: 'sr' },
  'sv-SE': { display: 'Swedish', twoLetterCode: 'sv' },
  'th-TH': { display: 'Thai', twoLetterCode: 'th' },
  'tr-TR': { display: 'Turkish', twoLetterCode: 'tr' },
  'uk-UA': { display: 'Ukrainian', twoLetterCode: 'uk' },
  'vi-VN': { display: 'Vietnamese', twoLetterCode: 'vi' },
  'zh-CN': { display: 'Chinese (Simplified)', twoLetterCode: 'zh' },
  'zh-Hant': { display: 'Chinese (Traditional)', twoLetterCode: 'zh' },
  'zh-HK': { display: 'Chinese (Hong Kong)', twoLetterCode: 'zh' },
  'zh-TW': { display: 'Chinese (Traditional)', twoLetterCode: 'zh' },
};

export const getComboBoxLanguages = (acceptedLanguages: Language[]): IComboBoxOption[] => {
  return Object.keys(acceptedLanguages).map((value: string, index: number) => {
    return {
      key: acceptedLanguages[index],
      text: languageInfo[acceptedLanguages[index] as Language].display,
    };
  });
};
