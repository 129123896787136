import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { animated, useSpring } from 'react-spring';

import { pivotAtomStyles } from './PivotAtom.styles';
import { PivotItemAtom } from './PivotItemAtom';
import { mergeClassNames } from 'components/utilities';

/**
 * Props for Pivot Atom Component
 *
 * @prop {ClassNameMap} [classes] - styles use in the component
 * @prop {string} [defaultItemId] - pre-selects any of the given items
 * @prop {string} [id] - identifies the pivot container
 * @prop {array} items - list of items to use as tabs
 * @prop {string} item.id - identifies the button's item
 * @prop {string}  item.text - string for the button
 * @prop {boolean} [item.required] - postfix a red asterisk to the button's text
 * @prop {function} onSelectItem - callback passing the id of selected item
 * @prop {string}  item.subtitle - string for the button's subtitle
 * @prop {string}  item.subtitleIconName - string for the button's subtitle icon name
 */

export interface PivotTab {
  id: string;
  text: string;
  required?: boolean;
  dataAutomationId?: string;
  subtitle?: string;
  subtitleIconName?: string;
}
export interface PivotAtomProps {
  addClass?: string;
  defaultItemId?: string;
  id?: string;
  items: PivotTab[];
  onSelectItem(itemId: string): string;
  rightElement?: JSX.Element;
}

type Props = PivotAtomProps & WithStyles<typeof pivotAtomStyles>;

const Pivot: React.FC<Props> = (props: Props) => {
  const defaultItemId = props.defaultItemId || props.items[0].id;

  const [barPosition, setBarPosition] = React.useState({ left: 0, width: 0 });
  const [items, setItems] = React.useState(
    props.items.map(item => {
      return { ...item, selected: false };
    })
  );

  const barSpringStyle = useSpring({ left: barPosition.left, width: barPosition.width });
  const containerRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);

  // Gets initial horizontal position of the bar
  React.useEffect(() => {
    // Because we are using react suspense the useeffect/uselayout hooks used here will fire before the display none tag from suspense is removed.
    // so the pivot items and container refs are empty causing the blue line to not be shown when coming in from a deep link.
    // settimeout here bumps the code execution to the end of the queue so it will fire after suspense is finished.
    const timeoutId = setTimeout(() => {
      setItems(
        props.items.map(item =>
          item.id === defaultItemId ? { ...item, selected: true } : { ...item, selected: false }
        )
      );
    });

    return () => clearTimeout(timeoutId);
  }, [defaultItemId, props.items]);

  /**
   * Updates bar position and width
   * @prop {Object} position - data of selected item
   * @prop {number} position.left - number of pixel item current x coordinate
   * @prop {number} position.width - number of pixels if item current width
   */
  const setBarPositionCallback = React.useCallback((itemPosition: { x: number; width: number }) => {
    if (containerRef.current) {
      const { x, left } = containerRef.current.getBoundingClientRect() as DOMRect;
      const edgeSafeX = x !== undefined ? x : left;
      setBarPosition({ left: itemPosition.x - edgeSafeX, width: itemPosition.width });
    }
  }, []);

  /**
   * Updates selected item props
   * @prop {string} itemId - id of the selected item
   */
  const handleSelect = (itemId: string) => {
    const updatedSelected = items.map(item => ({ ...item, selected: item.id === itemId }));
    setItems(updatedSelected);
    props.onSelectItem(itemId);
  };

  // Array containing the required flags for all items
  const itemRequiredFlags = items.map(item => !!item.required);

  // Children components
  const PivotItems: JSX.Element[] = items.map(item => {
    const pivotItemProps = {
      id: item.id,
      label: item.text,
      setBarPosition: setBarPositionCallback,
      onSelect: handleSelect,
      currentlySelected: item.selected,
      required: item.required,
      dataAutomationId: item.dataAutomationId,
      itemRequiredFlags,
      subtitle: item.subtitle,
      subtitleIconName: item.subtitleIconName,
    };
    return <PivotItemAtom {...pivotItemProps} key={item.id} />;
  });
  const containerClases = props.rightElement
    ? mergeClassNames([props.classes.itemsContainer, props.classes.rightSeparator])
    : props.classes.itemsContainer;
  return (
    //@ts-ignore
    <div
      className={`${props.classes.container} ${props.addClass}`}
      id={props.id}
      ref={containerRef}
    >
      <div className={containerClases}>
        {PivotItems} {props.rightElement}
      </div>
      <animated.div className={props.classes.animation} style={{ ...barSpringStyle }} />
    </div>
  );
};

export const PivotAtom = withStyles(pivotAtomStyles)(Pivot) as React.FC<PivotAtomProps>;
