import {
  ComboBox,
  DatePicker,
  SecondaryButton,
  TextBodyAsterisk,
  TextboxStandard,
} from 'components/ions';
import { dateFormatLl } from 'components/utilities/dates';
import { removeNonNumericalCharacters } from 'features/proposal/utils';
import moment from 'moment';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { servicesConfigCardFieldsStyles } from './ServicesConfigCard.styles';

// TODO (jek): remove when we connect the API calls. Defining this for now for
// demonstration purposes.
const maxAmount = 25000;

export interface ServicesConfigCardFieldsProps {
  onAddClicked: (lineItem: any) => void;
}

type Props = ServicesConfigCardFieldsProps & WithStyles<typeof servicesConfigCardFieldsStyles>;

const ServicesConfigCardFieldsUnstyled: React.FC<Props> = (props: Props) => {
  const { classes } = props;
  const { t } = useTranslation();
  // TODO(jek) - currently using these options for all ComboBoxes. Eventually will update so
  // the ComboBoxes have the correct options.
  const typeOptions = [
    { text: t('quote::Internal'), key: 'Internal' },
    { text: t('quote::External'), key: 'External' },
  ];

  const [type, setType] = React.useState<string>('');
  const [productFamily, setProductFamily] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<Date | undefined>(undefined);
  const [amount, setAmount] = React.useState<number>(0);
  const [remainingAmount, setRemainingAmount] = React.useState<number>(maxAmount);
  /* Forces amount field to rerender and display the cleaned number 
     after user inputs invalid characters.*/
  const [, setRerender] = React.useState<boolean>(false);
  const allFieldsFilled =
    type && description && productFamily && endDate && amount && remainingAmount - amount >= 0;

  const addClicked = () => {
    setDescription('');
    setEndDate(undefined);
    setAmount(0);
    setRemainingAmount(remainingAmount - amount);
    props.onAddClicked({
      type,
      productFamily,
      description,
      endDate,
      amount,
    });
  };

  const onAmountChange = (value: string | undefined) => {
    if (value && isNaN(Number(value))) {
      setAmount(Number(removeNonNumericalCharacters(value)));
    } else {
      setAmount(Number(value));
    }
    setRerender(s => !s);
  };

  return (
    <div className={classes.contentBody}>
      <div>
        <TextBodyAsterisk addClass={classes.servicesConfigRowTitle} required>
          {t('quote::Consulting and product support services')}
        </TextBodyAsterisk>
      </div>
      <div className={classes.configurationRow}>
        <ComboBox
          label={t('quote::Type')}
          options={typeOptions}
          selectedKey={type}
          styleContainer={classes.typeComboBox}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            if (option) setType(option.key.toString());
          }}
        ></ComboBox>
        <ComboBox
          label={t('quote::Product Family')}
          options={typeOptions}
          selectedKey={productFamily}
          styleContainer={classes.productFamilyComboBox}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            if (option) setProductFamily(option.key.toString());
          }}
        ></ComboBox>
        <ComboBox
          label={t('quote::Description')}
          options={typeOptions}
          selectedKey={description}
          styleContainer={classes.descriptionComboBox}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            if (option) setDescription(option.key.toString());
          }}
        ></ComboBox>
        <DatePicker
          className={classes.endsDatePicker}
          formatDate={(date?: Date) => {
            return moment(date).format(dateFormatLl);
          }}
          label={t('quote::Ends')}
          minDate={new Date()}
          value={endDate}
          onSelectDate={(date?: Date | null) => {
            if (date) setEndDate(date);
          }}
        ></DatePicker>
        <TextboxStandard
          addClass={classes.amountTextBox}
          errorMessageStyle={classes.errorMessageStyle}
          label={t('quote::Amount (USD)')}
          placeholder={remainingAmount.toLocaleString()}
          value={amount ? amount.toLocaleString() : ''}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            value?: string
          ) => {
            onAmountChange(value);
          }}
          onGetErrorMessage={() => {
            return remainingAmount - amount < 0 ? t('quote::Available amount exceeded.') : '';
          }}
        ></TextboxStandard>
        <SecondaryButton
          addClass={classes.addButton}
          disabled={!allFieldsFilled}
          text={t('quote::Add')}
          onClick={addClicked}
        ></SecondaryButton>
      </div>
    </div>
  );
};

export const ServicesConfigCardFields = withStyles(servicesConfigCardFieldsStyles)(
  ServicesConfigCardFieldsUnstyled
);
