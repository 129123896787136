import i18next from 'i18next';

export const Resources = (t: i18next.TFunction) => {
  return [
    {
      id: 'Link1',
      header: t('help::Readiness assets'),
      link: 'https://aka.ms/Azure_Experience_Readiness',
      description: t(
        'help::This is where you can find role-specific job aids, scripts, and training for the Microsoft commerce experience for Azure.'
      ),
    },
    {
      id: 'Link2',
      header: t('help::Azure experience resources'),
      link: 'https://aka.ms/Azure_Experience',
      description: t(
        'help::Here you’ll find an overview deck, extensive internal FAQ, internal announcements, and partner resources about the broader Microsoft commerce experience and the new Azure purchasing experience specifically.'
      ),
    },
    {
      id: 'Link3',
      header: t('help::Licensing bot'),
      link: ' https://microsoft.sharepoint.com/sites/infopedia_g01/pages/azure-bot.aspx',
      description: t(
        'help::Ask Theresa, the Azure licensing bot, your licensing questions if you can’t find them in the FAQ.'
      ),
    },
    {
      id: 'Link4',
      header: t('help::Microsoft Customer Agreement'),
      link: 'http://aka.ms/customeragreement',
      description: t('help::View the standard Microsoft Customer Agreement'),
    },
  ];
};
