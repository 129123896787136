import { ApolloError } from 'apollo-client';
import { Message, MessageSource } from 'generated/graphql';
import React from 'react';

import {
  BillingAccountNotFoundInfoTile,
  ErrorInfoTile,
  MCAPIAccountNotFoundInfoTile,
  TenantInvalidInfoTile,
  TenantNotFoundInfoTile,
  TenantViralInfoTile,
} from '../InformationalTiles';
import { OrganizationSearchResultTileProps } from '../OrganizationSearchDialog';

/**
 * Provides the information tile to display based on error and result states
 *
 * @param error Error object returned by the searchCustomerOrganization query
 * @param results Organizations returned by the searchCustomerOrganization query
 */
export const getInformationalTile = (
  error: ApolloError | undefined,
  messages: Message[] | undefined,
  results: OrganizationSearchResultTileProps[] | undefined,
  onCreate: () => void
) => {
  const message = messages && messages.find(message => !!message.messageSource);

  if (error) {
    return <ErrorInfoTile />;
  } else if (message) {
    switch (message.messageSource) {
      case MessageSource.OrganizationsearchInvalidsearchterm:
        return <TenantInvalidInfoTile scenario="customer" />;
      case MessageSource.OrganizationsearchUnmanagedtenant:
        return <TenantViralInfoTile />;
      case MessageSource.OrganizationsearchTenantNotfound:
        return <TenantNotFoundInfoTile scenario="customer" />;
      case MessageSource.OrganizationsearchAccountNotfound:
        return <MCAPIAccountNotFoundInfoTile onClick={onCreate} />;
      case MessageSource.OrganizationsearchNobillingaccount:
        return <BillingAccountNotFoundInfoTile scenario="customer" onClick={onCreate} />;
      default:
        return <ErrorInfoTile />;
    }
  } else if (results && !results.length) {
    return <BillingAccountNotFoundInfoTile scenario="customer" onClick={onCreate} />;
  }
};
