import axios from 'axios';
import { TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader } from '../utils';
import { endpoints, SignUpConfig } from './config';
import { GetTenantRequest, Tenant } from './types';

async function getHeaders() {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'MS-CorrelationId': createGuid(),
  };
  return headers;
}

export async function getTenant(tenant: string, config: SignUpConfig): Promise<Tenant> {
  const headers = await getHeaders();
  const url = endpoints[config.environment];
  const params = {
    'api-version': 1,
  };
  const request: GetTenantRequest = {
    emailAddress: `quotecenter@${tenant}`,
    skipVerificationEmail: true,
    skuId: 'StoreForBusinessIW',
  };
  const response = await axios.post<Tenant>(url, request, { params, headers });
  return response.data;
}
