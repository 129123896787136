import loggerService from 'services/logger-service';
import { LineItem } from 'services/proposal/types';

export const getTermIdContent = (lineItem: LineItem) => {
  const splitted =
    lineItem.supplementalTermReferenceData && lineItem.supplementalTermReferenceData.split(':');
  if (splitted && splitted.length !== 2) {
    loggerService.error({
      error: new Error(`Term Id Error ${lineItem.supplementalTermReferenceData}`),
    });
    return;
  }
  return splitted && splitted[1];
};
