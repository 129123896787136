import { ThemeProps } from 'styles/theme';

export const styles = (theme: ThemeProps) => {
  return {
    videos: {
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      padding: '32px',
      flexGrow: '1',
    },
    image: {
      width: 596,
    },
    videoTitle: {
      fontSize: theme.fonts.fontSizes.xLarge,
      fontWeight: theme.fonts.fontWeights.regular,
      margin: 0,
      paddingBottom: 20,
    },
    videoDescription: {
      margin: 0,
      paddingBottom: 40,
      width: 598,
    },
  };
};
