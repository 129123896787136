import { ButtonMenuProps, IconMenuButton } from 'components';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import {
  openFindOrganizationDialog,
  openRemoveParticipantDialog,
} from 'features-apollo/quote/components/Dialogs';
import {
  openChangeCustomerWizard,
  openOrganizationWizardDialog,
  OrganizationWizardFlow,
  OrganizationWizardView,
  TenantScenario,
} from 'features-apollo/quote/components/Wizards';
import { ContextualMenuItemType } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

type MenuOption = 'all' | 'edit' | 'add' | 'select' | 'create' | 'remove' | 'divider';

export type MenuOptions = MenuOption[];

export interface CustomLabels {
  /**
   * @default 'Edit billing account'
   */
  edit?: string;
  /**
   * @default 'Add new billing account'
   */
  add?: string;
  /**
   * @default 'Select existing billing account'
   */
  select?: string;
  /**
   * @default 'Create new billing account and account'
   */
  create?: string;
  /**
   * @default 'Remove'
   */
  remove?: string;
}

interface Props {
  addClass?: string;
  /**
   * Includes the edit organization option in the menu
   */
  organizationEditable?: boolean;
  /**
   * Disables the menu button
   */
  disabled?: boolean;
  /**
   * Label for the menu button
   * @default 'Opens menu options for the billing account'
   */
  customAriaLabel?: string;
  /**
   * Allows to customize the content available in the menu
   */
  menuOptions?: MenuOptions;
  /**
   * Customize the button label per option available
   */
  customLabels?: CustomLabels;
  /**
   * Defines a custom string per scenario.
   */
  specificScenario?: TenantScenario;
  /**
   * Removes edit and add billing account options if true
   */
  isDemoTenant?: boolean;
  /**
   * Callback for remove button on menu
   */
  onRemove?: () => void;
}

export const CustomerCommandMenu: React.FC<Props> = props => {
  const { customLabels, onRemove, specificScenario, isDemoTenant } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const { activeQuote } = React.useContext(ActiveQuoteContext);
  const hasSpecialAgreement = oc(activeQuote).agreements.edges([]).length;

  const onClickRemove =
    hasSpecialAgreement && onRemove
      ? () => openRemoveParticipantDialog(context, { onRemove, specificScenario })
      : props.onRemove;

  const editOrganization: ButtonMenuProps = {
    key: 'edit-billing-account',
    icon: 'AccountManagement',
    onClick: () => {
      openOrganizationWizardDialog(context, { flow: OrganizationWizardFlow.Edit });
    },
    text: oc(customLabels).edit() || t('quote::Edit billing account'),
    dataAutomationId: 'editBillingAccount',
  };

  const addNewOrganization: ButtonMenuProps = {
    key: 'add-new-billing-account',
    icon: 'AddFriend',
    onClick: () => {
      if (hasSpecialAgreement && specificScenario === TenantScenario.partnerCustomer) {
        openChangeCustomerWizard(context);
      } else {
        openOrganizationWizardDialog(context, {
          flow: OrganizationWizardFlow.CreateOrganization,
          initialView: OrganizationWizardView.OrganizationName,
        });
      }
    },
    text: oc(customLabels).add() || t('quote::Add new billing account'),
    dataAutomationId: 'addNewBillingAccount',
  };

  const selectOrganization: ButtonMenuProps = {
    key: 'select-billing-account',
    icon: 'ProfileSearch',
    onClick: () => {
      openFindOrganizationDialog(context);
    },
    text: oc(customLabels).select() || t('quote::Select existing billing account'),
    dataAutomationId: 'selectExistingBillingAccount',
  };

  const createOrganizationAndAccount: ButtonMenuProps = {
    key: 'create-billing-account-and-account',
    icon: 'Org',
    onClick: () => {
      openOrganizationWizardDialog(context, {
        flow: OrganizationWizardFlow.CreateAccountAndOrganization,
        initialView: OrganizationWizardView.SearchAccount,
      });
    },
    text: oc(customLabels).create() || t('quote::Create new billing account and account'),
    dataAutomationId: 'createNewBillingAccount',
  };

  const removeOrganization: ButtonMenuProps = {
    key: 'remove-organization',
    icon: 'UserRemove',
    text: oc(customLabels).remove() || t('quote::Remove'),
    dataAutomationId: 'remove-organization',
    onClick: onClickRemove,
  };

  const divider = {
    key: 'edit-billing-account-header',
    text: 'Edit billing account',
    itemType: ContextualMenuItemType.Divider,
  };

  let buttons: ButtonMenuProps[];

  // Custom content in the menu
  if (props.menuOptions) {
    buttons = [];

    props.menuOptions.forEach(option => {
      switch (option) {
        case 'edit':
          props.organizationEditable && !isDemoTenant && buttons.push(editOrganization);
          break;
        case 'add':
          !isDemoTenant && buttons.push(addNewOrganization);
          break;
        case 'divider':
          buttons.push(divider);
          break;
        case 'select':
          buttons.push(selectOrganization);
          break;
        case 'create':
          buttons.push(createOrganizationAndAccount);
          break;
        case 'remove':
          buttons.push(removeOrganization);
          break;
      }
    });
  } else {
    if (isDemoTenant) {
      buttons = [selectOrganization, createOrganizationAndAccount];
    } else if (props.organizationEditable) {
      buttons = [
        editOrganization,
        addNewOrganization,
        divider,
        selectOrganization,
        createOrganizationAndAccount,
      ];
    } else {
      buttons = [addNewOrganization, divider, selectOrganization, createOrganizationAndAccount];
    }
  }

  return (
    <IconMenuButton
      addClass={props.addClass}
      ariaLabel={props.customAriaLabel || t('quote::Opens menu options for the billing account')}
      dataAutomationId="customerCommandMenu"
      disabled={props.disabled}
      iconName="More"
      menuId="customer-menu"
      menuProps={buttons}
    />
  );
};
