import { TestHeaderConfig } from 'services/types';
import { TokenAuthorities } from 'services/utils';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
  Test = 'test',
}

export const endpoints = {
  [Environment.Prod]: 'https://ldss.l2o.microsoft.com',
  [Environment.Int]: 'https://ldss.int.l2o.microsoft.com',
  [Environment.Test]: 'https://ldss.test.l2o.microsoft.com',
};

export interface LDSSConfig extends TestHeaderConfig {
  environment: Environment;
}

export function getTokenAuthority(environment: Environment) {
  return environment === Environment.Prod ? TokenAuthorities.LDSSProd : TokenAuthorities.LDSSInt;
}

export const defaultCustomerSearchPageSize = 25;
