import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { CustomerType } from 'generated/graphql';
import React from 'react';

import { Customer } from './Customer';
import { isContactInformationEditable } from './selectors';

export const CustomerContainer: React.FC = () => {
  useHelpContent(HelpContent.CustomerPropertySheet);

  const { activeQuote, loading, quoteError, availableMutations } = React.useContext(
    ActiveQuoteContext
  );

  const customerContactEditable = isContactInformationEditable(
    activeQuote,
    CustomerType.EndCustomer
  );

  return (
    <Customer
      availableMutations={availableMutations}
      customerContactEditable={customerContactEditable}
      error={quoteError}
      loading={loading}
      quote={activeQuote}
    />
  );
};
