import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
  XLargeIcon,
} from 'components';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { ProposalDeleteRequest } from 'services/proposal/types';
import { AsyncState, RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { DialogLayout, DialogStatusBody, DialogStatusType } from '../Shared';
import { deleteProposalDialogStyles } from './DeleteDialog.styles';

const mapStateToProps = (state: RootState, ownProps: DeleteProposalDialogProps) => ({
  dialogState: selectors.getViews(state).deleteProposal,
  proposal: selectors.getProposalEntitiesIndexed(state)[ownProps.proposalId],
});

const dispatchProps = {
  deleteProposal: (request: ProposalDeleteRequest) => actions.deleteProposalAsync.request(request),
  resetDialog: () => actions.resetDeleteProposalDialog(),
};

export interface DeleteProposalDialogProps {
  id?: string;
  proposalId: string;
}

type Props = DeleteProposalDialogProps &
  WithStyles<typeof deleteProposalDialogStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const DeleteProposalDialogUnstyled: React.FC<Props> = ({
  classes,
  deleteProposal,
  dialogState,
  id,
  resetDialog,
  proposal,
}: Props) => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  const closeDialog = () => {
    resetDialog();
    context.closeDialog();
  };

  const handleDeleteProposal = () => {
    deleteProposal({
      id: oc(proposal).id(''),
      etag: oc(proposal).etag(''),
    });
  };

  const warningLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        dataAutomationId="deleteQuote"
        dialogClose={closeDialog}
        headerClass={classes.customDialogHeader}
      >
        <TextTitle>{t('quote::Delete quote?')}</TextTitle>
      </DialogHeader>
    ),
    body: (
      <div className={classes.customDialogBody}>
        <TextBody>
          {t(
            'quote::Deleting a quote will make it unretrievable and permanently removed from Quote Center.'
          )}
        </TextBody>
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <PrimaryButton
          autoFocus={true}
          dataAutomationId="quoteDeleteButton"
          text={t('quote::Delete')}
          onClick={handleDeleteProposal}
        />
        <SecondaryButton
          dataAutomationId="quoteDeleteCancelButton"
          text={t('quote::Cancel')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  const processingLayout: DialogLayout = {
    body: (
      <div className={classes.customDialogContainer}>
        <DialogStatusBody
          status={DialogStatusType.Processing}
          text={t('quote::Deleting quote...')}
        />
      </div>
    ),
  };

  const successLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        closeButtonContainerClass={classes.closeButtonNoTitle}
        dataAutomationId="successDeleteQuote"
        dialogClose={closeDialog}
      />
    ),
    body: (
      <div className={classes.customDialogContainer}>
        <DialogStatusBody
          status={DialogStatusType.Success}
          text={t('quote::The quote has been deleted.')}
        />
      </div>
    ),
    footer: (
      <div className={classes.errorFooter}>
        <PrimaryButton
          dataAutomationId="successDeleteQuoteCloseButton"
          text={t('quote::Close')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  const errorLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        closeButtonContainerClass={classes.closeButtonNoTitle}
        dataAutomationId="failedDeleteQuote"
        dialogClose={closeDialog}
      />
    ),
    body: (
      <div className={classes.customDialogContainer}>
        <div className={`${classes.iconContainer} ${classes.customDialogFailIcon}`}>
          <XLargeIcon addClass={classes.customDialogIcon} iconName="Cancel" />
        </div>
        <div>
          <TextBody addClass={classes.text}>Failed to delete quote. Please try again.</TextBody>
        </div>
      </div>
    ),
    footer: (
      <div className={classes.errorFooter}>
        <PrimaryButton
          dataAutomationId="quoteDeleteTryAgainButton"
          disabled={false}
          text={t('quote::Try again')}
          onClick={handleDeleteProposal}
        />
      </div>
    ),
  };

  const getLayout = () => {
    switch (dialogState) {
      case AsyncState.Request:
        return processingLayout;
      case AsyncState.Success:
        return successLayout;
      case AsyncState.Failure:
        return errorLayout;
      default:
        return warningLayout;
    }
  };

  const currentLayout = getLayout();

  return (
    <div>
      <CustomDialogBox
        bodySlot={currentLayout.body}
        footerSlot={currentLayout.footer}
        headerSlot={currentLayout.header}
        height={224}
        id={id}
        width={338}
      />
    </div>
  );
};

export const DeleteProposalDialogUnconnected = withStyles(deleteProposalDialogStyles)(
  DeleteProposalDialogUnstyled
);

export const DeleteProposalDialog = connect(
  mapStateToProps,
  dispatchProps
)(DeleteProposalDialogUnconnected);
