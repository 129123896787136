import { Icon, IIconStyles } from 'office-ui-fabric-react';
import * as React from 'react';

export interface IconAtomProps {
  id?: string;
  ariaLabel?: string;
  iconName: string;
  mainClass?: string;
  styles?: Partial<IIconStyles>;
  dataAutomationId?: string;
  title?: string;
}

export const IconAtom: React.FC<IconAtomProps> = (props: IconAtomProps) => {
  return (
    <Icon
      ariaLabel={props.ariaLabel}
      className={props.mainClass}
      data-automation-id={props.dataAutomationId}
      iconName={props.iconName}
      id={props.id}
      styles={props.styles}
      title={props.title}
    />
  );
};
