import { Fail, Processing } from 'features/components/dialogs';
import { loadCRMContactAsync } from 'features/customer/actions';
import { getContactID } from 'features/customer/selectors';
import * as actions from 'features/proposal/actions';
import { Success } from 'features/proposal/components/Dialogs/Shared';
import * as selectors from 'features/proposal/selectors';
import { getCRMId } from 'features/proposal/utils';
import * as userSelectors from 'features/user/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Notification } from 'services/notification/types';
import { shareLinkBusinessStore } from 'services/proposal/config';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { SelectEmailView } from './SelectEmailView';

enum DialogSteps {
  SelectEmail,
  Processing,
  Success,
  Fail,
}

const dialogDimenstions = {
  height: 264,
  width: 448,
};

// Props ----------------------------------------------------------------
const mapStateToProps = (state: RootState) => {
  const proposal = selectors.getActiveProposal(state);

  return {
    crmId: getCRMId(proposal),
    businessStoreConfig: state.app.appConfig.businessStore,
    proposal,
    user: userSelectors.getUser(state),
    msContact: userSelectors.getMSContact(state),
    sendingEmail: state.proposal.views.editor.sendingEmailNotification,
    getCRMContactProposal: (crmId: string) => selectors.getCRMContactProposal(state, crmId),
    getCustomerName: (proposal: Proposal) => selectors.getCustomerName(state, proposal),
    getContactID: (crmId: string) => getContactID(state, crmId),
  };
};

const dispatchProps = {
  clearSendingEmail: () => actions.clearSendingEmail(),
  sendEmailNotification: (request: Notification) =>
    actions.createNotificationAsync.request(request),
  loadCRMContactAsync: (crmId: string) => loadCRMContactAsync.request(crmId),
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const SendEmailDialogFeature: React.FC<Props> = props => {
  const { crmId, getCRMContactProposal, getContactID, loadCRMContactAsync, proposal } = props;
  const [currentStep, setCurrentStep] = React.useState<DialogSteps>(DialogSteps.SelectEmail);
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const contactId = crmId ? getContactID(crmId) : undefined;
  const crmContact = crmId ? getCRMContactProposal(crmId) : undefined;

  const closeDialog = () => {
    props.clearSendingEmail();
    context.closeDialog();
  };

  const sendEmail = (emailNotification: Notification) => {
    setCurrentStep(DialogSteps.Processing);
    props.sendEmailNotification(emailNotification);
  };

  // Load missing CRM Contact
  React.useEffect(() => {
    if (contactId && !crmContact) {
      loadCRMContactAsync(contactId);
    }
  }, []); // eslint-disable-line

  // Verify send proposal email notification ---------------------------------
  React.useEffect(() => {
    if (props.sendingEmail === 'failure') {
      setCurrentStep(DialogSteps.Fail);
    } else if (props.sendingEmail === 'success') {
      setCurrentStep(DialogSteps.Success);
    }
  }, [setCurrentStep, props.sendingEmail]);

  // Switch views -----------------------------------------------------------
  const getCurrentView = (step: DialogSteps) => {
    switch (step) {
      case DialogSteps.Processing:
        return (
          <Processing {...dialogDimenstions} message1={t('quote::The email is being sent.')} />
        );
      case DialogSteps.Fail:
        return (
          <Fail
            {...dialogDimenstions}
            closeDialog={closeDialog}
            dataAutomationId="sendEmailFail"
            message={t(
              'quote::We are unable to send an email at this time. Please try again later.'
            )}
          />
        );
      case DialogSteps.Success:
        return (
          <Success
            {...dialogDimenstions}
            closeDialog={closeDialog}
            dataAutomationId="sendEmailSuccess"
            message={t(
              'quote::Your email has been sent. Your quote is now available to the customer for viewing.'
            )}
          />
        );
      case DialogSteps.SelectEmail:
      default:
        return (
          <SelectEmailView
            assignedTo={
              props.user.email && props.msContact[props.user.email]
                ? props.msContact[props.user.email].mail
                : ''
            }
            crmContact={crmContact}
            customerName={props.getCustomerName(proposal)}
            displayName={
              props.user.email && props.msContact[props.user.email]
                ? props.msContact[props.user.email].displayName
                : ''
            }
            hasCRMContact={crmId ? !!contactId : false}
            proposal={proposal}
            sendEmail={sendEmail}
            shareLinkBusinessStore={shareLinkBusinessStore[props.businessStoreConfig]}
            user={props.user}
          />
        );
    }
  };

  return getCurrentView(currentStep);
};

export const SendEmailDialog = connect(mapStateToProps, dispatchProps)(SendEmailDialogFeature);
