import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => {
  const { palette } = theme;

  const sharedDotStyles = {
    fontSize: theme.fonts.fontSizes.smaller,
    color: palette.backgroundDivider,
    background: palette.backgroundDivider,
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    borderRadius: '50%',
    width: 6,
    height: 6,
  };
  return {
    navigationContainer: {
      marginTop: 8,
      display: 'flex',
      justifyContent: 'center',
    },
    stepCount: {
      paddingTop: 2,
      display: 'inline-block,',
    },
    offScreen: {
      height: 1,
      overflow: 'hidden',
      padding: 0,
      width: 1,
      position: 'absolute',
      left: -999999,
    },
    backButton: {
      marginTop: -6,
      marginRight: 12,
    },
    nextButton: {
      marginTop: -6,
      marginLeft: 12,
    },
    hiddenBackButton: {
      marginTop: -6,
      marginRight: 12,
      visibility: 'hidden',
    },

    hiddenNextButton: {
      marginTop: -6,
      marginLeft: 12,
      visibility: 'hidden',
    },
    dotContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 20,
      '&> button': {
        marginRight: 8,
      },
    },
    errorDot: {
      ...sharedDotStyles,
      background: palette.danger,
    },
    dot: {
      ...sharedDotStyles,
      '&:hover,&:focus': {
        background: palette.textSecondary,
        border: 'none',
        outline: 'none',
      },
    },
    selectedDot: {
      ...sharedDotStyles,
      background: palette.textSecondary,

      '&:hover,&:focus': {
        background: palette.textSecondary,
        border: 'none',
        outline: 'none',
      },
    },
    disabledDot: {
      background: palette.backgroundDivider,
      border: 'none',
      padding: 0,
      borderRadius: '50%',
      width: 6,
      height: 6,
    },
  };
};
