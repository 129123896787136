import { PersonaAtom, PersonaAtomProps, PersonaPresence } from 'components/atoms';
import { PersonaInitialsColor, PersonaSize } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { LargeIcon } from '../Icons';

interface PersonaProps extends PersonaAtomProps {
  /**
   * Prevents initials to be rendered based on the provided name (if defined)
   */
  unknown?: boolean;
}

const styles = (theme: ThemeProps) => ({
  icon: { color: theme.palette.lightColor, fontWeight: theme.fonts.fontWeights.semiBold },
});

type Props = PersonaProps & WithStyles<typeof styles>;

const PersonaUnstyled: React.FC<Props> = props => {
  const { id, name, presence, addClass, classes } = props;
  const onRenderInitials = () => <LargeIcon addClass={classes.icon} iconName="Contact" />;

  return (
    <PersonaAtom
      {...props}
      addClass={addClass}
      id={id}
      initialsColor={PersonaInitialsColor.violet}
      name={name}
      presence={presence}
      size={props.size || PersonaSize.size40}
      onRenderInitials={props.unknown ? onRenderInitials : undefined}
    />
  );
};

export const Persona = withStyles(styles)(PersonaUnstyled);
export { PersonaPresence };
