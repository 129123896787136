import { ThemeProps } from 'styles';

import { ProposalListColumnProps } from './';

export const proposalListColumnStyles = (theme: ThemeProps) => {
  return {
    column: {
      backgroundColor: theme.palette.backgroundCommon,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 17,
      paddingRight: 17,
      display: 'flex',
      justifyContent: (props: ProposalListColumnProps) => {
        return props.alignText || 'flex-start';
      },
    },
  };
};
