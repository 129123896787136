import { ThemeProps } from 'styles';

export const productListRowStyles = (theme: ThemeProps) => {
  return {
    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    lineItemTop: {
      paddingTop: 12,
      display: 'flex',
      textAlign: 'right',
    },
    lineItemBottom: {
      paddingTop: 5,
      paddingBottom: 9,
      display: 'flex',
    },
    configuration: {
      width: 400,
    },
    watermark: {
      fontSize: theme.fonts.fontSizes.small,
    },
  };
};
