import { ThemeProps } from 'styles';

export const textAtomStyles = (theme: ThemeProps) => {
  return {
    textStyles: {
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      color: theme.palette.text,
      margin: 0,
    },
  };
};
