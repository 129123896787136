import { Channel } from '../reco/types';

//TODO:Remove the export once ecif mocks are not needed anymore.
export const termsChannel: Channel = {
  name: 'StandardTerms',
  Title: 'Standard',
  Items: [
    {
      Id: '0RDCKN522WX4',
    },
    {
      Id: '0RDCKN523H19',
    },
    {
      Id: '0RDCKN523H16',
    },
    {
      Id: '0RDCKN523H1M',
    },
    {
      Id: '0RDCKN523H1L',
    },
    {
      Id: '0RDCKN523H1K',
    },
    {
      Id: '0RDCKN523H0V',
    },
    {
      Id: '0RDCKN523H1P',
    },
    {
      Id: '0RDCKN523H1N',
    },
    {
      Id: '0RDCKN5233F0',
    },
  ],
};

const customTerms = [
  {
    Id: '0RDCKN523H17',
  },
];

const prodTerms = [
  {
    Id: '0RDCKN523H18', // Prod ECIF Terms
  },
];

const prodSAPTerm = [
  {
    Id: '0RDCKN5233DV', //Prod SCP Commitment
  },
];

export const loadChannel = (loadCustomTerms: boolean) => {
  const terms = {
    ...termsChannel,
    Items: [...termsChannel.Items],
  };
  if (loadCustomTerms) {
    terms.Items.push(...customTerms);
  }

  terms.Items.push(...prodTerms);
  terms.Items.push(...prodSAPTerm);

  return terms;
};
