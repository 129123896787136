import { ThemeProps } from 'styles';
import { CalendarProps } from './Calendar';

const selectedDay = '& .ms-DatePicker-day--highlighted .ms-DatePicker-day-button';
const selectedYear = '& button.ms-DatePicker-yearOption.ms-DatePicker-day--highlighted';
const dayPicker = '& .ms-DatePicker-day-button';
const yearPicker = '& button.ms-DatePicker-yearOption';
const outerDayTd = '& td.ms-DatePicker-day';
const todayDate = '& .ms-DatePicker-dayBackground .ms-DatePicker-day--today';
const month = '& .ms-DatePicker-monthOption';
const prevMonthArrow = '& .ms-DatePicker-prevMonth';
const nextMonthArrow = '& .ms-DatePicker-nextMonth';
const prevYearArrow = '& .ms-DatePicker-prevYear';
const nextYearArrow = '& .ms-DatePicker-nextYear';
const highlightedMonth = '& .ms-DatePicker-monthOption.ms-DatePicker-day--highlighted';
const goToToday = '& .ms-DatePicker-goToday.js-goToday';
const todayIsSelected = '& .ms-DatePicker-day--highlighted .ms-DatePicker-day--today';

export const calloutCalendarStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    container: {
      overflowY: 'hidden',
      height: 256,
    },

    evergreen: (props: CalendarProps) => {
      return {
        position: 'relative',
        right: props.isDayPickerVisible ? -220 : -20,
        top: -40,
        color: theme.palette.text,
        '&:hover': {
          color: theme.palette.text,
          background: theme.palette.backgroundCommon,
          textDecoration: 'none',
          height: 24,
          width: 71,
          right: props.isDayPickerVisible ? -211 : -11,
          top: -42,
          textAlign: 'center',
        },
        '&:focus': {
          color: theme.palette.text,
        },
        '&:active:focus': {
          color: theme.palette.lightColor,
          background: theme.palette.primary,
          textDecoration: 'none',
          height: 24,
          width: 71,
          right: props.isDayPickerVisible ? -211 : -11,
          top: -42,
          textAlign: 'center',
        },
      };
    },

    selectedDay: {
      [selectedDay]: {
        background: palette.primary,
        color: palette.titleBarPrimaryText,
        '&:hover': {
          background: palette.primary,
          color: palette.titleBarPrimaryText,
        },
        '&:active': {
          background: palette.primary,
          color: palette.titleBarPrimaryText,
        },
      },
    },
    selectedYear: {
      [selectedYear]: {
        color: palette.text,
        background: palette.lineItemHighlight,
        '&:active': {
          background: palette.lineItemHighlight,
          color: palette.text,
        },
      },
    },

    goToToday: {
      [goToToday]: {
        right: 8,
        '&:disabled': {
          color: palette.textDisabled,
        },
      },
    },

    days: {
      [outerDayTd]: {
        background: 'none !important', // "important" is the only current solution to overwrite Fabric style (css specificity)
      },
      [dayPicker]: {
        cursor: 'pointer',
        '&:hover': {
          background: palette.backgroundCommon,
        },
        '&:active': {
          background: palette.primary,
          color: palette.titleBarPrimaryText,
        },
        '&:disabled': {
          color: palette.textDisabled,
        },
      },
    },
    todayDate: {
      [todayDate]: {
        '&, &:hover, &:active': {
          background: palette.lineItemHighlight,
          borderRadius: 2,
          color: palette.text,
        },
      },
    },
    yearPicker: {
      [yearPicker]: {
        '&:hover': {
          background: palette.backgroundCommon,
        },
        '&:active': {
          background: palette.lineItemHighlight,
          color: palette.text,
        },
        '&:disabled': {
          color: palette.textDisabled,
        },
      },
    },
    todayIsSelected: {
      [todayIsSelected]: {
        '&, &:hover, &:active': {
          background: palette.lineItemHighlight,
          border: `1px solid ${palette.primary}`,
          borderRadius: 2,
          color: palette.text,
        },
      },
    },
    monthPicker: {
      [highlightedMonth]: {
        background: palette.primary,
        color: palette.titleBarPrimaryText,
        '&:hover': {
          background: palette.primary,
          color: palette.titleBarPrimaryText,
          border: 'none',
        },
      },
      [month]: {
        '&:hover': {
          background: palette.backgroundCommon,
        },
        '&:active': {
          background: palette.lineItemHighlight,
        },
        '&:disabled': {
          color: palette.textDisabled,
        },
      },
    },
    arrows: {
      [prevMonthArrow]: {
        '&:hover': {
          background: palette.backgroundCommon,
        },
        '&:active': {
          background: palette.primary,
          color: palette.titleBarPrimaryText,
        },
        '&:disabled': {
          color: palette.textDisabled,
        },
      },
      [nextMonthArrow]: {
        '&:hover': {
          background: palette.backgroundCommon,
        },
        '&:active': {
          background: palette.primary,
          color: palette.titleBarPrimaryText,
        },
        '&:disabled': {
          color: palette.textDisabled,
        },
      },
      [prevYearArrow]: {
        '&:hover': {
          background: palette.backgroundCommon,
        },
        '&:active': {
          background: palette.primary,
          color: palette.titleBarPrimaryText,
        },
        '&:disabled': {
          color: palette.textDisabled,
        },
      },
      [nextYearArrow]: {
        '&:hover': {
          background: palette.backgroundCommon,
        },
        '&:active': {
          background: palette.primary,
          color: palette.titleBarPrimaryText,
        },
        '&:disabled': {
          color: palette.textDisabled,
        },
      },
    },
  };
};
