import { Offset } from 'components/ions';
import { ThemeProps } from 'styles';

import { CalloutCardLinkProps } from './CalloutCardLink';

export const offsetStyles = (offset?: Offset): React.CSSProperties => {
  return {
    position: 'absolute',
    left: 30,
    top: 10,
    ...offset,
  };
};

export const calloutCardLinkStyles = (theme: ThemeProps) => {
  return {
    button: {
      maxWidth: (props: CalloutCardLinkProps) => props.maxWidth,
      '& > *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '&:disabled': {
        color: theme.palette.textTertiary,
      },
      '&:hover:disabled': {
        color: theme.palette.textTertiary,
      },
    },
    relativePositionStyles: {
      position: 'relative',
    },
  };
};
