import { GuidanceBox, LinkExternal, TextBody } from 'components/ions';
import { Address } from 'features/customer/types';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './GuidanceBoxStates.styles';
import { GuidanceBoxHeader } from './shared';

export interface ExistingOrganizationsProps {
  addresses: Address[];
  customerName?: string;
  cwaLink?: string;
  readOnly?: boolean;
}

type Props = ExistingOrganizationsProps & WithStyles<typeof styles>;

const ExistingOrganizationsUnStyled: React.FC<Props> = props => {
  const { classes, customerName } = props;
  const { t } = useTranslation();

  let text: JSX.Element;

  if (!customerName) {
    text = <Trans ns="quote">The Sales Account is linked to the following billing accounts.</Trans>;
  } else if (!props.cwaLink) {
    text = (
      <Trans ns="quote">
        The Sales Account, {{ customerName }} , is linked to the following billing accounts.
      </Trans>
    );
  } else {
    text = (
      <Trans ns="quote">
        The Sales Account, <LinkExternal displayText={customerName} href={props.cwaLink} /> , is
        linked to the following billing accounts.
      </Trans>
    );
  }

  return (
    <GuidanceBox>
      <GuidanceBoxHeader addClass={classes.header} title={t('quote::What we found...')}>
        <TextBody addClass={classes.paragraph}>
          {text}{' '}
          <Trans ns="quote">
            Please look over the following billing accounts and select the appropriate billing
            account for this quote.
          </Trans>
        </TextBody>
      </GuidanceBoxHeader>
    </GuidanceBox>
  );
};

export const ExistingOrganizations = withStyles(styles)(ExistingOrganizationsUnStyled);
