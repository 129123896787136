export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface BD360Config {
  environment: Environment;
}

export const endpoints = {
  [Environment.Prod]:
    'https://quotecenterservice.l2o.microsoft.com/v1/bd360/integration/api/EntityService/entity',
  [Environment.Int]:
    'https://quotecenterservice.int.l2o.microsoft.com/v1/bd360/integration/api/EntityService/entity',
};
