import { ThemeProps } from 'styles';

export const instantFeedbackButtonsStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    button: {
      backgroundColor: colors.transparent,
      padding: 0,
    },
    icon: {
      minWidth: 22,
      width: 22,
      height: 23,
      backgroundColor: colors.transparent,
      borderRadius: 0,
      padding: '0px 4px',
      '& i': {
        color: colors.text,
        padding: 0,
        margin: 0,
      },
      '&:hover': {
        backgroundColor: colors.transparent,
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:active': {
        backgroundColor: colors.transparent,
      },
      '& i:disabled': {
        color: colors.textDisabled,
      },
    },
    iconSolid: {
      '& i': {
        color: colors.textSecondary,
        padding: 0,
        margin: 0,
      },
    },
  };
};
