import { LinkButton } from 'components/ions';
import * as selectors from 'features/proposal/selectors';
import { sharedStyles } from 'features/proposal/shared.styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { isNeedsTranslation, NeedsTranslation } from 'services/utils';
import { RootState } from 'store/types';
import { DialogContext, DialogProps, FontSizes } from 'styles';
import { SoldToSimple } from 'generated/graphql';
import { RequestCreditLineDialog } from './';

export interface RequestCreditLineButtonProps {
  displayText: NeedsTranslation | string;
  disabled?: boolean;
  annualDealEstimate: string;
  minimumCreditLine: string;
  size?: keyof FontSizes;
  setCustomerFooterAnnualDealEstimate?: (value: string) => void;
  dataAutomationId?: string;
  soldTo?: SoldToSimple;
  quoteId: string;
  billingCurrency: string;
  etag: string;
}

const styles = {
  requestCreditButton: {
    width: 'fit-content',
  },
};

const mapStateToProps = (state: RootState) => ({
  creditInfo: selectors.getCreditInfo(state),
});

type Props = RequestCreditLineButtonProps &
  WithStyles<typeof sharedStyles> &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps>;

export const RequestCreditLineButtonUnstyled: React.FC<Props> = props => {
  const { soldTo, quoteId, billingCurrency, annualDealEstimate, minimumCreditLine, etag } = props;
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const dialogProps: DialogProps = {
    providedDialog: (
      <RequestCreditLineDialog
        annualDealEstimate={annualDealEstimate}
        billingCurrency={billingCurrency}
        etag={etag}
        minimumCreditLine={minimumCreditLine}
        quoteId={quoteId}
        setCustomerFooterAnnualDealEstimate={props.setCustomerFooterAnnualDealEstimate}
        soldTo={soldTo}
      />
    ),
  };

  const handleOnClick = () => {
    context.openDialog(dialogProps);
  };
  const text = isNeedsTranslation(props.displayText)
    ? t(props.displayText.value)
    : props.displayText;
  return (
    <LinkButton
      addClass={props.classes.requestCreditButton}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      displayText={text}
      id="requestCreditLine"
      size={props.size ? props.size : 'medium'}
      onClick={handleOnClick}
    />
  );
};

export const RequestCreditLineButtonStyled = withStyles({ ...sharedStyles, ...styles })(
  RequestCreditLineButtonUnstyled
);
export const RequestCreditLineButton = connect(mapStateToProps)(RequestCreditLineButtonStyled);
