import { LinkButton } from 'components/ions';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { openRemoveCrmDialog } from './RemoveCrmIdDialog';

const mapStateToProps = (state: RootState) => {
  return {
    proposal: selectors.getActiveProposal(state),
    organizationId: selectors.getOrganizationId(state),
  };
};

const dispatchProps = {
  removeCrmId: actions.removeCRMId,
};
export interface RemoveCrmIdButtonProps {
  proposal: Proposal;
  onRemoveClick: () => void;
  organizationId?: string;
  addClass?: string;
}
type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & RemoveCrmIdButtonProps;
export const RemoveCrmIdButtonUnconnected = (props: Props) => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const onClick = () => {
    const onRemoveCrmId = () => {
      props.removeCrmId(props.proposal);
      props.onRemoveClick();
    };
    if (props.organizationId) {
      openRemoveCrmDialog(context, onRemoveCrmId);
    } else {
      onRemoveCrmId();
    }
  };
  return (
    <LinkButton
      displayText={t('remove')}
      dataAutomationId="removeCrmIdButton"
      addClass={props.addClass}
      size="medium"
      onClick={onClick}
    />
  );
};

export const RemoveCrmIdButton = connect(
  mapStateToProps,
  dispatchProps
)(RemoveCrmIdButtonUnconnected);
