import { getAppEnvironment } from 'features-apollo/app/selectors';
import { useGetUserPermissions } from 'features-apollo/user/hooks';
import { getUserMail } from 'features-apollo/user/selectors';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { HomeProposalStyles as styles } from 'features/home/components/styles/HomeProposal.styles';
import { ReferralSummaryList } from 'features/proposal/components';
import { Permission } from 'generated/graphql';
import { ISearchBox } from 'office-ui-fabric-react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { RootState } from 'store/types';

const mapStateToProps = (state: RootState) => ({
  environment: getAppEnvironment(state),
  userMail: getUserMail(state) || '',
});

type Props = {} & WithTranslation & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

const HomeReferrals: React.FC<Props> = props => {
  const { classes } = props;

  useHelpContent(HelpContent.Claims);
  const { permissions, loading } = useGetUserPermissions(props.userMail, props.environment);

  const hasReferralPermissions =
    permissions &&
    (permissions.includes(Permission.ReferralView) ||
      permissions.includes(Permission.ReferralUpdate));

  const { t } = useTranslation();

  const searchFocusRef = React.useRef<ISearchBox>(null);
  useHotkeys('ctrl+/', () => {
    searchFocusRef.current && searchFocusRef.current.focus();
  });

  return (
    <div className={classes.div}>
      {permissions && !loading && (
        <nav className={classes.nav}>
          <NavLink
            className={classes.navLink}
            data-automation-id="referralsActiveTab"
            to={routes.home.referrals.active}
          >
            {t('home::Active')}
          </NavLink>
          <NavLink
            className={classes.navLink}
            data-automation-id="referralsClosedTab"
            to={routes.home.referrals.closed}
          >
            {t('home::Closed')}
          </NavLink>
          <NavLink
            className={classes.navLink}
            data-automation-id="referralsExpiredTab"
            to={routes.home.referrals.expired}
          >
            {t('home::Expired')}
          </NavLink>
        </nav>
      )}
      <Switch>
        <Route
          exact
          path={routes.home.referrals.root}
          render={() => {
            if (permissions && !loading && !hasReferralPermissions)
              return <Redirect to={routes.home.root} />;
            else return <Redirect to={routes.home.referrals.active} />;
          }}
        />
        <Route
          exact
          path={routes.home.referrals.active}
          render={() => {
            if (permissions && !loading && !hasReferralPermissions)
              return <Redirect to={routes.home.root} />;
            else return <ReferralSummaryList filter="Active" searchComponentRef={searchFocusRef} />;
          }}
        />
        <Route
          exact
          path={routes.home.referrals.closed}
          render={() => {
            if (permissions && !loading && !hasReferralPermissions)
              return <Redirect to={routes.home.root} />;
            else return <ReferralSummaryList filter="Closed" searchComponentRef={searchFocusRef} />;
          }}
        />
        <Route
          exact
          path={routes.home.referrals.expired}
          render={() => {
            if (permissions && !loading && !hasReferralPermissions)
              return <Redirect to={routes.home.root} />;
            else
              return <ReferralSummaryList filter="Expired" searchComponentRef={searchFocusRef} />;
          }}
        />
        <Route render={() => <Redirect to={routes.home.referrals.root} />} />
      </Switch>
    </div>
  );
};
export default connect(mapStateToProps)(withTranslation('home')(withStyles(styles)(HomeReferrals)));
