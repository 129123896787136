import { SectionSeparator, TextBody } from 'components';
import { CompoundButton } from 'components/ions';
import { getProductFragmentsIndexed } from 'features/catalog/selectors';
import { getAssetsByAccountId } from 'features/customer/selectors';
import * as selectors from 'features/proposal/selectors';
import { Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { AssetListItem } from 'services/edge/types';
import { RootState } from 'store/types';

import { convertAssetDialogStyles } from './ConvertAssetDialog.styles';
import { buildAssetConversionOptions, buildAssetDetailsContent } from './utils';

const mapStateToProps = (state: RootState) => {
  const proposal = selectors.getActiveProposal(state);
  const accountId = selectors.getAccountId(state, proposal);
  return {
    products: getProductFragmentsIndexed(state),
    assets: accountId ? getAssetsByAccountId(state, accountId) : undefined,
  };
};

export interface ConvertActionableAssetDialogProps {
  selectedAssetListItem?: AssetListItem;
  convertAsset: () => void;
  goToAddSeats: () => void;
}

type Props = ConvertActionableAssetDialogProps &
  WithStyles<typeof convertAssetDialogStyles> &
  ReturnType<typeof mapStateToProps>;

const ConvertActionableAssetDialogUnstyled: React.FC<Props> = props => {
  const { classes, selectedAssetListItem, assets, products, convertAsset, goToAddSeats } = props;
  const { t } = useTranslation();

  const selectedProduct =
    selectedAssetListItem && products && products[selectedAssetListItem.productId];
  const selectedAsset =
    selectedAssetListItem && assets && assets.find(asset => asset.id === selectedAssetListItem.key);

  const hasConversion = buildAssetConversionOptions(selectedProduct, selectedAsset, products);
  return (
    <div className={classes.convertActionableAssetDialogBody}>
      <TextBody>
        {t('quote::What action would you like to take on {{productName}}:', {
          productName: (selectedAssetListItem && selectedAssetListItem.product) || '',
        })}
      </TextBody>
      <div className={classes.flexAlignStart}>
        {hasConversion && !!hasConversion.length && (
          <CompoundButton
            addClass={classes.convertDialogIconButtonContainer}
            onClick={convertAsset}
          >
            <div className={classes.convertDialogIconContainerForDialog}>
              <Icon className={classes.iconForDialog} iconName="DependencyAdd"></Icon>
            </div>
            <div className={classes.buttonLink}>{t('quote::Convert')}</div>
          </CompoundButton>
        )}
        <CompoundButton addClass={classes.convertDialogIconButtonContainer} onClick={goToAddSeats}>
          <div className={classes.convertDialogIconContainerForDialog}>
            <Icon className={classes.iconForDialog} iconName="PeopleAdd"></Icon>
          </div>
          <div className={classes.buttonLink}>{t('quote::Add seats')}</div>
        </CompoundButton>
      </div>
      <SectionSeparator />
      {buildAssetDetailsContent(
        t('quote::Asset details'),
        classes,
        selectedProduct,
        selectedAsset,
        selectedAssetListItem
      )}
    </div>
  );
};

export const ConvertActionableAssetDialogUnconnected = withStyles(convertAssetDialogStyles)(
  ConvertActionableAssetDialogUnstyled
);

export const ConvertActionableAssetDialog = connect(mapStateToProps)(
  ConvertActionableAssetDialogUnconnected
);
