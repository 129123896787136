import { ThemeProps } from 'styles';

export const SeatsCardStyles = (theme: ThemeProps) => {
  return {
    closeButton: {
      '& i': {
        fontSize: theme.fonts.fontSizes.small,
      },
    },
    infoButtonContainer: { width: 16, height: 16, display: 'inline-block', whiteSpace: 'nowrap' },
    infoButton: {
      marginBottom: 2,
      minWidth: 12,
      width: 12,
      height: 12,
      verticalAlign: 'middle',
      '& i': {
        fontSize: theme.fonts.fontSizes.small,
      },
    },
    seatsInputContainerClass: {
      width: 150,
    },
    textBoxDivMargin: {
      marginBottom: 20,
      marginTop: 20,
    },
    content: {
      '& button:hover': {
        color: theme.palette.titleBarPrimaryText,
        '& i': {
          color: theme.palette.titleBarPrimaryText,
        },
      },
    },
    maxWidth: {
      maxWidth: 250,
    },
    textColor: {
      color: theme.palette.titleBarPrimaryText,
    },
    seatsLabel: {
      bottom: 25,
      left: 105,
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 220,
      width: 250,
      height: 200,
    },
    errorMessageStyle: {
      width: 216,
    },
    warningMessageStyle: {
      color: theme.palette.warningActive,
      width: 216,
    },
    seatConstraints: {
      paddingTop: 24,
      paddingBottom: 8,
      fontWeight: 600,
    },
    label: {
      color: theme.palette.textTertiary,
      paddingBottom: 5,
    },
    value: {
      paddingBottom: 4,
    },
    totalSeatsLabel: {
      paddingBottom: 4,
      fontWeight: 600,
    },
    totalSeatsValue: {
      paddingBottom: 4,
      fontWeight: 600,
    },
    minimumLabel: {
      color: theme.palette.textTertiary,
    },
    minimumValue: {
      paddingBottom: 4,
    },
    maximumLabel: {
      color: theme.palette.textTertiary,
    },
    maximumValue: {
      paddingBottom: 4,
    },
    purchasedAtStartLabel: {
      color: theme.palette.textTertiary,
    },
    purchasedAtStartValue: {
      paddingBottom: 4,
    },
    sumOnQuoteLabel: {
      color: theme.palette.textTertiary,
    },
    sumOnQuoteValue: {
      paddingBottom: 4,
    },
    separatorClass: {
      width: '57%',
      marginBottom: 4,
      marginTop: 4,
    },
    flexRow: {
      '& span, h4': {
        width: '45%',
      },
      display: 'flex',
      flexDirection: 'row',
    },
  };
};
