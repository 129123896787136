import {
  IIconProps,
  ILabelStyleProps,
  IRenderFunction,
  ITextField,
  ITextFieldProps,
  ITextFieldStyleProps,
  ITextFieldStyles,
  RefObject,
  TextField,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { textboxStyles } from './Textbox.styles';

export interface TextboxAtomProps {
  addClass?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  componentRef?:
    | React.RefObject<ITextField>
    | RefObject<ITextField>
    | ((ref: ITextField | null) => void);
  dataAutomationId?: string;
  disabled?: boolean;
  errorMessage?: string | JSX.Element;
  errorMessageStyle?: string;
  iconProps?: IIconProps;
  id?: string;
  label?: string;
  labelStyle?: string;
  maxLength?: number;
  multiline?: boolean;
  placeholder?: string;
  required?: boolean;
  resizable?: boolean;
  readOnly?: boolean;
  readOnlyOverride?: string;
  removeReadonlyStyling?: boolean;
  rows?: number;
  selectOnFocus?: boolean;
  suffix?: string;
  title?: string;
  validateOnFocusOut?: boolean;
  value?: string;
  warningMessage?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClick?: () => void;
  onPaste?: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onGetErrorMessage?: (value: string) => string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onNotifyValidationResult?: (errorMessage: string | JSX.Element, value?: string) => void;
  onChange?: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  onRenderLabel?: IRenderFunction<ITextFieldProps>;
}

type Props = TextboxAtomProps & WithStyles<typeof textboxStyles>;

export const TextboxAtomUnstyled: React.FC<Props> = (props: Props) => {
  const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onFocus && props.onFocus(event);
    props.selectOnFocus && event.target.select();
  };

  const getLabelStyles = (labelProps: ILabelStyleProps) => ({
    root: labelProps.required && props.labelStyle,
  });

  const getStyles = (stylesProps: ITextFieldStyleProps): Partial<ITextFieldStyles> => ({
    errorMessage: props.errorMessageStyle,
    field: stylesProps.disabled ? props.classes.fieldDisabled : undefined,
    fieldGroup: stylesProps.disabled ? props.classes.fieldGroupDisabled : props.classes.fieldGroup,
    subComponentStyles: {
      label: getLabelStyles,
    },
    suffix: props.classes.suffix,
    wrapper: `${props.classes.wrapper} ${props.addClass}`,
  });

  let errorString = props.errorMessage || props.warningMessage || undefined;
  return (
    <TextField
      ariaLabel={props.ariaLabel || props.label}
      autoFocus={props.autoFocus}
      componentRef={props.componentRef}
      data-automation-id={props.dataAutomationId}
      disabled={props.disabled}
      errorMessage={errorString}
      iconProps={props.iconProps}
      id={props.id}
      inputClassName={props.disabled ? 'is-disabled' : undefined}
      label={props.label}
      maxLength={props.maxLength}
      multiline={props.multiline}
      placeholder={props.placeholder}
      readOnly={props.readOnly}
      required={props.required}
      resizable={props.resizable}
      rows={props.rows}
      styles={getStyles}
      suffix={props.suffix}
      title={props.title}
      validateOnFocusOut={props.validateOnFocusOut}
      value={props.value}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onClick={props.onClick}
      onFocus={onFocus}
      onGetErrorMessage={props.onGetErrorMessage}
      onKeyDown={props.onKeyDown}
      onNotifyValidationResult={props.onNotifyValidationResult}
      onPaste={props.onPaste}
      onRenderLabel={props.onRenderLabel}
    />
  );
};

export const TextboxAtom = withStyles(textboxStyles)(TextboxAtomUnstyled) as React.FC<
  TextboxAtomProps
>;
