import { LinkExternal, NavLinkInternal, Persona, TextSectionHeading } from 'components';
import { AgreementTypes } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { routes } from 'routes';
import { ThemeProps } from 'styles';

import { DetailDisplay } from './DetailDisplay';

export interface ExpirationDetails {
  /**
   * Quote's expiration date formatted
   */
  date: string;
  /**
   * Whether or not provide option to update the expiration date
   */
  expiringSoon: boolean;
  /**
   * Used for redirect link to the General page
   */
  quoteId: string;
}

export interface AgreementDetails {
  /**
   * Type of agreement that is been linked to the quote
   */
  type: AgreementTypes;
  /**
   * Allows to open or download the agreement document
   */
  documentLink?: string | null;
}

interface SummaryDetailsPaneGeneralProps {
  /**
   * Details of the quote context
   */
  channelAndSegment: string;
  /**
   * Provides information of the agreement associated to the quote
   */
  agreementDetails?: AgreementDetails;
  /**
   * Provides information of the expiration date of the quote and it can be extended
   */
  expirationDetails?: ExpirationDetails;
  /**
   * Details of the first and surname for the Microsoft Contact
   */
  msContactFullName?: string | null;
  /**
   * UI representation of the quote current status
   */
  quoteStatus: string;
}

const styles = (theme: ThemeProps) => ({
  details: { '& > :first-child': { marginTop: 16 }, '& > :not(:first-child)': { marginTop: 24 } },
  personaDetails: { paddingLeft: 16 },
  title: { fontSize: theme.fonts.fontSizes.large, fontWeight: theme.fonts.fontWeights.semiBold },
});

type Props = SummaryDetailsPaneGeneralProps & WithStyles<typeof styles>;

const SummaryDetailsPaneGeneralUnstyled: React.FC<Props> = props => {
  const { classes, expirationDetails, agreementDetails } = props;
  const { t } = useTranslation();

  const channelAndSegment = (
    <DetailDisplay label={t('quote::Channel and segment')} textDetail={props.channelAndSegment} />
  );

  const microsoftContact = (
    <DetailDisplay label={t('quote::Microsoft contact')}>
      <Persona
        name={props.msContactFullName || t('quote::no contact')}
        styles={{ details: classes.personaDetails }}
        unknown={!props.msContactFullName}
      />
    </DetailDisplay>
  );

  let expirationDate;
  if (expirationDetails) {
    const { quoteId, expiringSoon, date } = expirationDetails;
    const link = expiringSoon ? (
      <NavLinkInternal to={routes.quote.generalForId(quoteId)}>
        {t('quote::extend date')}
      </NavLinkInternal>
    ) : (
      undefined
    );

    expirationDate = (
      <DetailDisplay
        label={t('quote::Good until')}
        styles={{ predefinedStyle: expiringSoon ? 'warning' : undefined }}
        textDetail={date}
      >
        {link}
      </DetailDisplay>
    );
  }

  const quoteStatus = (
    <DetailDisplay label={t('quote::Quote status')} textDetail={props.quoteStatus} />
  );

  let agreement;
  if (agreementDetails) {
    const { type, documentLink } = agreementDetails;
    let text;
    switch (type) {
      case AgreementTypes.Mca:
        text = t('quote::Microsoft Customer Agreement');
        break;
      case AgreementTypes.Apt:
        text = t('quote::Customer Affiliate Purchase Terms');
        break;
      case AgreementTypes.Special:
        text = t('quote::Uploaded agreement');
        break;
    }

    agreement = (
      <DetailDisplay label={t('quote::Agreement type')} textDetail={text}>
        <LinkExternal
          disabled={!documentLink}
          displayText={t('quote::view agreement')}
          href={documentLink || undefined}
        />
      </DetailDisplay>
    );
  }

  return (
    <section>
      <TextSectionHeading addClass={classes.title}>
        {t('quote::General details')}
      </TextSectionHeading>
      <div className={classes.details}>
        {expirationDate}
        {quoteStatus}
        {channelAndSegment}
        {agreement}
        {microsoftContact}
      </div>
    </section>
  );
};

export const SummaryDetailsPaneGeneral = withStyles(styles)(SummaryDetailsPaneGeneralUnstyled);
