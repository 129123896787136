import { searchOrganizationMaxDisplayLength } from 'features/app/constants';
import {
  createErrorMessageSelector,
  createLoadingSelector,
  createProcessingSelectors,
} from 'features/app/selectors';
import { contributorRoleId, ownerRoleId } from 'features/proposal/components/ExistingOwners';
import { getCustomerCommerceAccount } from 'features/proposal/utils';
import { createSelector } from 'reselect';
import { Address, SuggestedAddress, VerificationStatus } from 'services/address-validation/types';
import {
  OrganizationState,
  OrganizationSummary,
  OrganizationType,
  TaxId,
} from 'services/customer/types';
import { Asset } from 'services/edge/types';
import { TenantName } from 'services/externaluser/types';
import { CustomPrice } from 'services/pricingscope/types';
import { OneAskResult } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { getLeadOrganizationId, getOrganizationId } from '../proposal/selectors/proposal';

// #region CRM selectors
export const getCRMLead = (state: RootState, id: string) => state.customer.CRMLead[id];
export const getCRMLeads = (state: RootState) => state.customer.CRMLead;
// #endregion

// #region Sales Account selectors
export const getSalesAccount = (state: RootState, accountId: string) =>
  state.customer.salesAccount[accountId] || null;

export const getIsStrategicAccount = (state: RootState) => state.customer.isStrategicAccount;

export const getSalesAccountFromCrmId = (state: RootState, crmID?: string | null) => {
  const lead = !!crmID && state.customer.CRMLead[crmID];
  return lead && lead.account ? getSalesAccount(state, lead.account.Id) : null;
};

export const hasSalesAccount = (state: RootState) => {
  const salesAccount = oc(state).customer.salesAccount();

  if (!salesAccount) {
    return false;
  }

  const keys = Object.keys(salesAccount);

  if (keys.length) {
    const firstId = keys[0];
    const firstAccount = salesAccount[firstId];
    return oc(firstAccount).id() === firstId;
  }

  return false;
};

export const getSalesAccountAddressFromCrmId = createSelector(
  getSalesAccountFromCrmId,
  salesAccount => salesAccount && salesAccount.Address
);

export const getBillingAccountFromCrmId = createSelector(
  getSalesAccountFromCrmId,
  salesAccount => salesAccount && salesAccount.McApiId
);
// #endregion

export const getCustomers = (state: RootState) =>
  state.customer.customers.fragments.ordered.map(() => state.customer.customers.fragments.indexed);
export const getCustomer = (state: RootState, id: string) =>
  state.customer.customers.entities.indexed[id];
const searchEnrollment = [
  '@@project/LOAD',
  '@@project/account/MAP',
  '@@mcapi/legacyFootprint/LOAD',
];
export const processingEnrollment = createProcessingSelectors(searchEnrollment);
export const processingMapCrmId = createProcessingSelectors(['@@CRM/HYDRATE_LITE/LOAD']);
export const legacyLoading = createLoadingSelector(['@@mcapi/legacyFootprint/LOAD']);
export const selectOrganizationLoading = createLoadingSelector(['@@proposal/SELECT_ORGANIZATION']);

//TODO: Investigate not sending selectOrganization.success() for downstream error condition updating proposal
export const selectOrganizationError = createErrorMessageSelector([
  '@@proposal/SELECT_ORGANIZATION',
  '@@quote/UPDATE',
  '@@proposal/UPDATE',
]);

export const processingOrganizationCreation = createProcessingSelectors([
  '@@accountExtensions/typecorg/CREATE',
]);
export const processingOrganizationSelection = createProcessingSelectors([
  '@@proposal/SELECT_ORGANIZATION',
]);

export const processingOrganizationEdit = createProcessingSelectors([
  '@@organization/UPDATE',
  '@@quote/header/PATCH',
]);
export const processingOrganizationsAddresses = createProcessingSelectors([
  '@@organizations/address/LOAD',
]);

export const processingAddingTenant = createProcessingSelectors(['@@account/tenant/ADD']);

export const processingSimilarOrgSearch = createProcessingSelectors([
  '@@edgeSearch/organization/SIMILAR_ORG_SEARCH',
]);

export const searchOrganizationsForDialogLoading = createLoadingSelector([
  '@@edgeSearch/organization/DIALOG_SEARCH',
]);
export const searchOrganizationsForPropertySheetLoading = createLoadingSelector([
  '@@edgeSearch/organization/PROPERTY_SHEET_SEARCH',
]);
export const searchingTenantFootprint = createProcessingSelectors([
  '@@mcapi/tenantFootprint/SEARCH',
]);

export const searchSignupEmailProcessing = createProcessingSelectors([
  '@@mcapi/signupEmail/SEARCH',
]);

export const getCRMContacts = (state: RootState, contactId: string) =>
  state.customer.crmContacts[contactId];

const emptyArray: CustomPrice[] = [];
export const getCustomPrices = (
  state: RootState,
  organizationId: string = '',
  productType?: string,
  productId?: string
) => {
  const scopeArr = state.customer.customPrices[organizationId];
  if (!scopeArr) {
    return;
  }

  let fromProductId = emptyArray;

  if (productId) {
    fromProductId = scopeArr.filter(discount => discount.pricingInstructions.product === productId);
  }

  if (productId && fromProductId.length) {
    return fromProductId;
  }

  // For certain products(w/ProductFamily Azure discount) we want to fall back on applicability
  if (productType && productType.toLowerCase() === 'AzureFamilyDiscount'.toLowerCase()) {
    return scopeArr.filter(
      discount => discount.pricingInstructions.applicability === 'productFamily'
    );
  }
  return emptyArray;
};

export const getSharedCustomPrices = (state: RootState) => {
  const orgId = getOrganizationId(state);
  const leadOrgId = getLeadOrganizationId(state);
  const orgDiscounts = orgId ? state.customer.customPrices[orgId] : undefined;
  const leadOrgDiscounts = leadOrgId ? state.customer.customPrices[leadOrgId] : undefined;

  if (orgDiscounts && orgDiscounts.length && leadOrgDiscounts && leadOrgDiscounts.length) {
    const orgDiscountIds = orgDiscounts.map(customPrice => customPrice.id);
    return (
      leadOrgDiscounts.filter(customPrice => !orgDiscountIds.includes(customPrice.id)) || undefined
    );
  }
  return leadOrgDiscounts;
};

export const getContactID = (state: RootState, crmId: string) => {
  const lead = getCRMLead(state, crmId);
  return oc(lead).contactId.Id();
};

export const getCustomerAccount = (state: RootState, accountId: string) =>
  state.customer.account[accountId];
export const getLeadOrganization = (state: RootState, organizationId: string) =>
  state.customer.leadOrganization[organizationId];
export const getLeadOrganizationName = (state: RootState, leadOrgId: string) => {
  const leadOrg = getLeadOrganization(state, leadOrgId);
  return oc(leadOrg).legalEntity.address.companyName();
};
export const getLeadOrganizationAddress = (state: RootState, leadOrgId: string) => {
  const leadOrg = getLeadOrganization(state, leadOrgId);
  return oc(leadOrg).legalEntity.address();
};
export const getOrganization = (state: RootState, organizationId: string) =>
  state.customer.organization[organizationId];
export const getOrganizations = (state: RootState, accountId: string) =>
  state.customer.accountOrganizations[accountId];

export const processingSearchTenant = createProcessingSelectors(['@@signup/tenant/LOAD']);

export const getTenants = (state: RootState) => state.customer.tenant;
export const getSearchOrganizationDialogResults = (state: RootState) =>
  state.customer.organizationSearch.dialogResults.items;
export const searchOrganizationDialogHasMore = (state: RootState) =>
  state.customer.organizationSearch.dialogResults.totalItems > searchOrganizationMaxDisplayLength;
export const getSearchOrganizationPropertySheetResults = (state: RootState, name: string) =>
  state.customer.organizationSearch.propertySheetSearchResults[name];
export const getSearchOrganizationSimilarOrganizationResult = (state: RootState) =>
  state.customer.organizationSearch.similarOrganizationResult;
export const canEditOrganization = (state: RootState, organizationId: string) =>
  !state.customer.signedMCA[organizationId];
export const canEditAddressOnly = (state: RootState, organizationId: string) =>
  state.customer.signedMCA[organizationId];

export const getTenant = (state: RootState, tenant: string) => state.customer.tenant[tenant];

export const getTenantAdmins = (state: RootState, tenantId: string) =>
  state.customer.tenantAdmins[tenantId];

export const getTenantOwners = (state: RootState) =>
  state.customer.tenantRoleAssignedUpns
    .filter(owner => owner.roleId === ownerRoleId)
    .map(owner => owner.upn);

export const getTenantContributors = (state: RootState) =>
  state.customer.tenantRoleAssignedUpns
    .filter(owner => owner.roleId === contributorRoleId)
    .map(owner => owner.upn);

export const tenantAdminsProcessing = createProcessingSelectors([
  '@@externalUser/tenantAdmin/LOAD',
]);
export const tenantUpnsProcessing = createProcessingSelectors(['@@externalUser/upn/LOAD']);

export const getExternalIds = (state: RootState, accountId: string) =>
  getCustomerAccount(state, accountId).externalIds.filter(
    item => !item.toLowerCase().startsWith('pcn:')
  );

export const getTenantAccount = (state: RootState, tenant: string) => {
  const tenantInfo = getTenant(state, tenant);
  if (tenantInfo && tenantInfo.tenantId) {
    const accounts = state.customer.tenantAccounts[tenantInfo.tenantId];
    return accounts && getCustomerCommerceAccount(accounts);
  }
};

export const getOrganizationsByType = (
  state: RootState,
  accountId: string,
  type: OrganizationType
) => {
  const organizations = getOrganizations(state, accountId);
  return (
    organizations &&
    organizations.filter(
      org => org.organizationType === type && org.state !== OrganizationState.deleted
    )
  );
};

//todd: we should have an ability to detect errors
export const getAccountSuggestedOrganizations = (
  state: RootState,
  accountId: string
): OrganizationSummary[] | undefined => {
  const organizationsWithoutAddress = getOrganizationsByType(
    state,
    accountId,
    OrganizationType.organization
  );
  return (
    organizationsWithoutAddress &&
    organizationsWithoutAddress.map(org => getOrganization(state, org.id) || org)
  );
};

export const getIWOrganizations = (state: RootState, accountId: string): string[] | undefined => {
  const IWOrganizations = getOrganizationsByType(state, accountId, OrganizationType.individual);
  return IWOrganizations && IWOrganizations.map(item => item.description);
};

export const hasSuggestedOrganizations = (state: RootState, accountId: string) => {
  const organizations = getOrganizations(state, accountId);
  return (
    organizations &&
    organizations.some(org => org.organizationType === OrganizationType.organization)
  );
};

export const processingVerifyTenant = createProcessingSelectors(['@@signup/verifyTenant/LOAD']);

export const getTenantNames = createSelector(
  (state: RootState, tenantIds: string[]) => tenantIds,
  (state: RootState) => state.customer.tenantNames,
  (tenantIds, tenantNames) => {
    return tenantIds.map(id => {
      const match: TenantName = {
        tenantId: id,
        tenantDisplayName: tenantNames[id],
      };
      return match;
    });
  }
);
export const getAssetsByAccountId = (state: RootState, accountId: string): Asset[] =>
  state.customer.assets[accountId];

export const getFilteredAssetsByAccountId = (state: RootState, accountId: string): Asset[] => {
  const assets = state.customer.assets[accountId];
  const organizationId = getOrganizationId(state);
  return (
    assets &&
    assets.filter((asset: Asset) => {
      return asset.organizationId === organizationId;
    })
  );
};

export const hasAddressValidationResponse = (state: RootState): boolean => {
  const { addressValidated } = state.customer;

  return !!(addressValidated && addressValidated.status);
};

export const isAddressValid = (state: RootState): boolean => {
  const validated =
    state.customer.addressValidated &&
    (state.customer.addressValidated.status === VerificationStatus.Verified ||
      state.customer.addressValidated.status === VerificationStatus.VerifiedShippable);

  return !!validated;
};

export const getAddressFieldsWithErrors = (state: RootState) => {
  const addressValidated = state.customer.addressValidated;
  //The validation message will always be in the format as follows: "Address field invalid for property: 'PostalCode', 'City'"
  if (addressValidated && addressValidated.validation_message) {
    const index = addressValidated.validation_message.indexOf(':');
    if (index > 0) {
      let invalidFields = addressValidated.validation_message.substring(index + 1);
      return invalidFields.match(/\w+/g) || [];
    }
  }
  return [];
};

export const getAddressValidationSuggestedAddress = (
  state: RootState
): SuggestedAddress | undefined => {
  return oc(state).customer.addressValidated.suggested_address();
};

export const getAddressValidationOriginalAddress = (state: RootState): Address | undefined => {
  return oc(state).customer.addressValidated.original_address();
};

export const oneAskResultsProcessing = createProcessingSelectors(['@@ECIFs/LOAD']);
export const getOneAskResponse = (state: RootState): OneAskResult[] => state.customer.oneAskResults;

export const getVatId = (state: RootState) => {
  const orgId = getOrganizationId(state);
  const organization = orgId && getOrganization(state, orgId);
  const taxIds = organization && organization.legalEntity.taxIds;
  const vatId = taxIds && taxIds.find((taxId: TaxId) => taxId.type === 'vat_id');
  const isExpired = vatId && vatId.endDate;
  return isExpired || !vatId ? undefined : vatId;
};

export const processingAddVatId = createProcessingSelectors(['@@VatId/add/LOAD']);

export const processingUploadAgreement = createProcessingSelectors(['@@agreement/UPLOAD']);
