import { sharedStyles, ThemeProps } from 'styles';

export const addressDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  addressContainerStyles: {
    paddingBottom: 18,
  },
  addressWidthStyles: {
    width: 375,
  },
  addressPaddingStyles: {
    paddingTop: 10,
  },
  addLinePadding: {
    paddingTop: 35,
    paddingLeft: 15,
  },
  addressContainerInline: {
    display: 'flex',
  },
});
