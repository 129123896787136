import {
  getModernAgreementPreviewLink,
  getModernAgreementType,
  getSpecialAgreementPreviewLink,
} from 'features-apollo/quote/selectors/agreement';
import {
  getCreditLineStatus,
  getEndCustomerOrganizationAddress,
  getEndCustomerOrganizationId,
  getEndCustomerOrganizationTradeName,
  getSoldToOrganizationAddress,
  getSoldToOrganizationCredit,
  getSoldToOrganizationId,
  getSoldToOrganizationTradeName,
} from 'features-apollo/quote/selectors/organization';
import {
  getChannel,
  getCRMLead,
  getEnrollmentNumber,
  getExpirationDate,
  getSegment,
  getSpecialAgreements,
  hasMultipleParticipants,
  isQuoteExpiringSoon,
  isQuoteLegacy,
} from 'features-apollo/quote/selectors/quote';
import { formatDate } from 'features-apollo/quote/utils';
import {
  AgreementTypes,
  Audience,
  ModernAgreementDocument,
  Motion,
  Quote,
} from 'generated/graphql';
import i18next from 'i18next';

import { AgreementDetails, ExpirationDetails } from './SummaryDetailsPaneGeneral';
import { CRMInformation, OrganizationDetails } from './SummaryDetailsPaneParticipants';

/**
 * Gets the translated text that represents the given channel
 */
const translateChannel = (channel: Motion) => {
  switch (channel) {
    case Motion.Direct:
      return i18next.t('quote::Direct');
    case Motion.Partner:
      return i18next.t('quote::Indirect');
    case Motion.PartnerReseller:
      return i18next.t('quote::Indirect Reseller');
    case Motion.WebDirect:
      return i18next.t('quote::Web Direct');
    case Motion.Other:
      return i18next.t('quote::Other');
  }
};

/**
 * Gets the translated text that represents the given segment
 */
const translateSegment = (segment: Audience) => {
  switch (segment) {
    case Audience.Commercial:
      return i18next.t('quote::Commercial');
    case Audience.Government:
      return i18next.t('quote::Government');
    case Audience.Education:
      return i18next.t('quote::Education');
    case Audience.Charity:
      return i18next.t('quote::Charity');
  }
};

/**
 * Gets the deal estimate for modern quotes that only has a single customer.
 */
export const getAnnualDealEstimate = (quote: Quote) => {
  const quoteHasMultipleParticipants = hasMultipleParticipants(quote);
  const quoteIsLegacy = isQuoteLegacy(quote);

  return quoteHasMultipleParticipants || quoteIsLegacy ? undefined : quote.soldTo.dealEstimate || 0;
};

/**
 * Gets the customer organization id (for Direct quotes only) and address on the quote
 */
export const getCustomerInformation = (quote: Quote): OrganizationDetails => {
  const quoteHasMultipleParticipants = hasMultipleParticipants(quote);
  const organizationId = quoteHasMultipleParticipants
    ? undefined
    : getEndCustomerOrganizationId(quote);
  const address = getEndCustomerOrganizationAddress(quote);
  const tradeName = getEndCustomerOrganizationTradeName(quote);

  return { organizationId, address, tradeName };
};

/**
 * Gets the partner organization id and address on the quote
 */
export const getPartnerInformation = (quote: Quote): OrganizationDetails | undefined => {
  const quoteHasMultipleParticipants = hasMultipleParticipants(quote);
  return quoteHasMultipleParticipants
    ? {
        organizationId: getSoldToOrganizationId(quote),
        address: getSoldToOrganizationAddress(quote),
        tradeName: getSoldToOrganizationTradeName(quote),
      }
    : undefined;
};

/**
 * Gets CRM information for modern quotes only.
 */
export const getCRMInformation = (quote: Quote): CRMInformation | undefined => {
  const quoteIsLegacy = isQuoteLegacy(quote);
  if (quoteIsLegacy) {
    return;
  }

  const crmLead = getCRMLead(quote);
  if (!crmLead) {
    return {};
  }

  const { id, type, salesAccount } = crmLead;
  return { id, type, tpid: salesAccount && salesAccount.tpid };
};

/**
 * Gets the enrollment number for legacy quotes only.
 */
export const getEnrollmentNumberDetail = (quote: Quote) => {
  const quoteIsLegacy = isQuoteLegacy(quote);
  const enrollmentNumber = getEnrollmentNumber(quote);

  return quoteIsLegacy ? enrollmentNumber : undefined;
};

/**
 * Gets credit information only for modern quotes that haave one participant (example: direct quote)
 */
export const getCreditInformation = (quote: Quote) => {
  const quoteIsLegacy = isQuoteLegacy(quote);
  const quoteHasMultipleParticipants = hasMultipleParticipants(quote);
  const { credit, monthlyCreditLimit } = getSoldToOrganizationCredit(quote);

  if (quoteIsLegacy || quoteHasMultipleParticipants || !credit) {
    return;
  }

  const { creditLine, currency } = credit;
  const status = getCreditLineStatus(credit, monthlyCreditLimit);
  return { creditLine, currency, status };
};

/**
 * Get expiraction date details for SummaryDetailsPaneGeneral component
 */
export const getExpirationDetails = (quote: Quote): ExpirationDetails | undefined => {
  const expirationDate = getExpirationDate(quote);
  if (!expirationDate) {
    return;
  }

  const quoteId = quote.id;
  const date = formatDate(expirationDate, 'MMMM DD, YYYY');
  const expiringSoon = isQuoteExpiringSoon(quote);

  return { date, quoteId, expiringSoon };
};

/**
 * Gets UI text that represents the channel and segment selected for the given quote
 */
export const getChannelAndSegmentText = (quote: Quote) => {
  const channel = getChannel(quote);
  const segment = getSegment(quote);

  const channelTranslated = translateChannel(channel);
  const segmentTranslated = translateSegment(segment);

  return `${channelTranslated} - ${segmentTranslated}`;
};

/**
 * Gets agreement details for modern quotes only
 */
export const getAgreementDetails = (
  quote: Quote,
  modernAgreement?: ModernAgreementDocument | null
): AgreementDetails | undefined => {
  const quoteIsLegacy = isQuoteLegacy(quote);
  if (quoteIsLegacy) {
    return;
  }

  const type = getModernAgreementType(quote);

  let documentLink;
  switch (type) {
    case AgreementTypes.Special:
      const specialAgreements = getSpecialAgreements(quote);
      documentLink = getSpecialAgreementPreviewLink(specialAgreements);
      break;
    default:
      documentLink = modernAgreement && getModernAgreementPreviewLink(modernAgreement);
      break;
  }

  return { type, documentLink };
};

/**
 * Gets a label to display in the top-right corner of the details pane
 */
export const getQuoteLabel = (quote: Quote) => {
  const quoteIsLegacy = isQuoteLegacy(quote);
  return quoteIsLegacy ? i18next.t('quote::Legacy') : undefined;
};
