import cloneDeep from 'clone-deep';
import {
  AgreementType,
  CatalogAction,
  CatalogContext,
  FilteredSkus,
  GqlLanguage,
  Market,
  NationalCloud,
  ProductAudience,
  Sku,
} from 'generated/graphql';

export const automationIds = {
  duration: 'duration',
  location: 'location',
  skuList: 'skuList',
  terms: 'terms',
  termDuration: 'termDuration',
  termDescription: 'termDescription',
  billingPlan: 'billingPlan',
};

export const catalogContext: CatalogContext = {
  agreementType: AgreementType.Modern,
  audience: ProductAudience.DirectCommercial,
  nationalCloud: NationalCloud.Global,
  market: Market.Us,
  languages: GqlLanguage.EnUs,
  action: CatalogAction.Details,
};

export const flatten = (skuInformations: FilteredSkus[]): Sku[] =>
  skuInformations.reduce<Sku[]>(
    (flattened, skuInformation) => flattened.concat(skuInformation.skus),
    []
  );

export const removePricesFromTerms = (filteredSkus: FilteredSkus[]) => {
  const cloned = cloneDeep(filteredSkus);
  cloned.forEach(filteredSku => {
    filteredSku.skus.forEach(sku => {
      sku.availabilities.forEach(availability => {
        if (availability.terms && availability.terms.length) {
          availability.terms.forEach(term => {
            term.price = null;
          });
        }
      });
    });
  });
  return cloned;
};
