import { SectionSeparator, TextBody, TextBodySmall, TextSubsectionHeading } from 'components';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

const styles = {
  container: {},
  separatorStyles: {
    marginBottom: 16,
    marginTop: 16,
  },
  queryString: {
    display: 'block',
    overflowY: 'auto',
    paddingRight: 16,
    paddingTop: 16,
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
};

type Props = {
  className?: string;
  queryString: string;
} & WithStyles<typeof styles>;

const FlightsPanelUrlParamsUnstyled: React.FC<Props> = props => {
  const { classes, className, queryString } = props;

  return (
    <div className={mergeClassNames([classes.container, className])}>
      {queryString && (
        <React.Fragment>
          <SectionSeparator addClass={classes.separatorStyles} />
          <TextSubsectionHeading>Add this query to the current url</TextSubsectionHeading>
          <TextBodySmall>if a flight does not work try refreshing with query</TextBodySmall>
          <TextBody addClass={classes.queryString}>?setSwitch={queryString}</TextBody>
        </React.Fragment>
      )}
    </div>
  );
};

export const FlightsPanelUrlParams = withStyles(styles)(FlightsPanelUrlParamsUnstyled);
