import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import gql from 'graphql-tag';

export const ApplyMonetaryConfiguration = gql`
  mutation ApplyConfigurationMonetary(
    $quote: QuoteMutationInput!
    $configuration: ApplyConfigurationMonetaryInput!
  ) {
    applyConfigurationMonetary(quote: $quote, configuration: $configuration) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;
