import { Sku, TermBillingPlan, TermComponent, TermDuration } from 'generated/graphql';
import i18next from 'i18next';

export const placeholderKey = 'Select SKU to view options';
export const placeholderOptions = (t: i18next.TFunction) => {
  return [{ key: placeholderKey, text: t('quote::Select SKU to view options') }];
};

export const getSelectedSku = (skus: Sku[], skuId?: string) => {
  return skus.find(sku => sku.skuId === skuId);
};

export function isBillingPlan(value: TermComponent): value is TermBillingPlan {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(value as any).billingPeriod;
}

export function isTermDuration(value: TermComponent): value is TermDuration {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(value as any).duration;
}
