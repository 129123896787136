import {
  ActionButton,
  BaseButton,
  Button,
  IButtonStyles,
  IContextualMenuProps,
  IIconProps,
} from 'office-ui-fabric-react';
import * as React from 'react';

export interface ActionButtonAtomProps {
  ariaLabel?: string;
  disabled?: boolean;
  iconName?: string;
  id?: string;
  mainClass?: string;
  menuProps?: IContextualMenuProps;
  styles?: IButtonStyles;
  onClick?: (
    event: React.MouseEvent<
      | HTMLDivElement
      | HTMLSpanElement
      | HTMLButtonElement
      | HTMLAnchorElement
      | BaseButton
      | Button,
      MouseEvent
    >
  ) => void;
  children?: React.ReactNode;
  title?: string;
  dataAutomationId?: string;
  iconProps?: IIconProps;
}

export const ActionButtonAtom: React.FC<ActionButtonAtomProps> = (props: ActionButtonAtomProps) => {
  // TODO jepagan : request Fabric to enable the user of classes to modify styles properties for icon
  const styles: Partial<IButtonStyles> = {
    iconHovered: { color: '' },
    iconPressed: { color: '' },
    ...props.styles,
  };

  return (
    <ActionButton
      ariaLabel={props.ariaLabel}
      className={props.mainClass}
      data-automation-id={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={
        props.iconProps || {
          iconName: props.iconName,
        }
      }
      id={props.id}
      menuProps={props.menuProps}
      styles={styles}
      title={props.title}
      onClick={props.onClick}
    >
      {props.children}
    </ActionButton>
  );
};
