import { InfoButton, SectionSeparator, TextBody, TextboxStandard } from 'components';
import { Slide } from 'components/molecules/Carousel';
import { useDebounce } from 'components/utilities/debounce';
import { Carousel } from 'features/components/Carousel';
import { Currency } from 'features/proposal/supported-currencies';
import { formatCurrency } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { startConditionConstants } from '../DetailsPane/detailsUtils';
import { SAPCardStyles } from './SAPCard.styles';

export interface SAPCardProductFieldsProps {
  readOnly: boolean;
  currency: Currency;
  onDismiss: () => void;
  onApply: () => void;
  values: {
    amountValue: string;
    goodForValue: string;
    firstNameValue?: string;
    lastNameValue?: string;
    emailAddressValue?: string;
    SAPOpptyIdValue?: string;
    startConditionValue?: string;
  };
  inputActions: {
    onAmountChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onGoodForChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onEmailAddressChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onSAPOpptyIdChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onFirstNameChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onLastNameChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
  };
  inputErrorActions: {
    onAmountErrorChange: (value?: string) => void;
    onGoodForErrorChange: (value?: string) => void;
    onEmailAddressErrorChange: (value?: string) => void;
    onSAPOpptyIdErrorChange: (value?: string) => void;
  };
  errorMessages: {
    amountErrorMessage?: string;
    goodForErrorMessage?: string;
    sapOpptyIdErrorMessage?: string;
    emailAddressErrorMessage?: string;
  };
}

const dispatchProps = {};

type Props = SAPCardProductFieldsProps & typeof dispatchProps & WithStyles<typeof SAPCardStyles>;

const removeDecimals = (value: string) => {
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    return '';
  }
  return parsed.toString();
};

const inputDebounceMilliseconds = 400;

const SAPConfigCardProductFieldsUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, values, errorMessages, inputActions, inputErrorActions } = props;
  const { t } = useTranslation();
  const [amountFocused, setAmountFocused] = React.useState(false);

  const goodForAmount = values.goodForValue;
  const formattedValue = !errorMessages.amountErrorMessage && formatCurrency(values.amountValue, 0);
  const amount = !amountFocused && formattedValue ? formattedValue : values.amountValue;

  const amount$ = useDebounce(inputDebounceMilliseconds);
  const months$ = useDebounce(inputDebounceMilliseconds);
  const email$ = useDebounce(inputDebounceMilliseconds);
  const oppty$ = useDebounce(inputDebounceMilliseconds);
  const maxAmountDigitsAllowed = 15;

  const onSAPCardGoodForChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      const withoutDecimals = removeDecimals(value);
      months$.next(() => inputErrorActions.onGoodForErrorChange(withoutDecimals));
      inputActions.onGoodForChange(event, withoutDecimals);
    }
  };

  const onAmountFocus = () => {
    setAmountFocused(true);
  };
  const onAmountBlur = () => {
    setAmountFocused(false);
  };

  const onSAPCardAmountChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      const limitedDigits = value.slice(0, maxAmountDigitsAllowed);
      const formatted = removeDecimals(limitedDigits);

      amount$.next(() => inputErrorActions.onAmountErrorChange(formatted));
      inputActions.onAmountChange(event, formatted);
    }
  };

  const onSAPCardEmailChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      email$.next(() => inputErrorActions.onEmailAddressErrorChange(value));
      inputActions.onEmailAddressChange(event, value);
    }
  };

  const onSAPCardOpptyIdChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      oppty$.next(() => inputErrorActions.onSAPOpptyIdErrorChange(value));
      inputActions.onSAPOpptyIdChange(event, value);
    }
  };

  const amountLabel = `${t('quote::Amount')} (${props.currency})`;

  let stepContainerClass = classes.noError;
  if (errorMessages.emailAddressErrorMessage && errorMessages.sapOpptyIdErrorMessage) {
    stepContainerClass = classes.twoErrors;
  } else if (errorMessages.emailAddressErrorMessage || errorMessages.sapOpptyIdErrorMessage) {
    stepContainerClass = classes.oneError;
  }

  const firstStep = (
    <div className={stepContainerClass}>
      <div className={classes.amountContainer}>
        <TextboxStandard
          ariaLabel={amountLabel}
          autoFocus={true}
          dataAutomationId="SAPCardEnterAmount"
          disabled={props.readOnly}
          errorMessage={errorMessages.amountErrorMessage}
          errorMessageStyle={classes.errorMessageStyle}
          label={amountLabel}
          placeholder={t('quote::Enter amount')}
          required
          value={amount}
          onBlur={onAmountBlur}
          onChange={onSAPCardAmountChange}
          onFocus={onAmountFocus}
        />
      </div>
      <div className={classes.startingInformation}>
        <div>
          <TextBody addClass={classes.emphasisText}>{t('quote::Starting')}</TextBody>
        </div>
        <div>
          <TextBody>
            {values.startConditionValue === startConditionConstants.firstOfThisMonth
              ? t('quote::First of this month')
              : t('quote::At order acceptance')}
          </TextBody>
        </div>
      </div>
      <div className={classes.goodForContainer}>
        <TextboxStandard
          ariaLabel={t('quote::Good for')}
          dataAutomationId="SAPCardGoodFor"
          errorMessage={errorMessages.goodForErrorMessage}
          errorMessageStyle={classes.errorMessageStyle}
          label={t('quote::Good for')}
          readOnly={props.readOnly}
          required
          suffix={t('quote::month(s)')}
          value={goodForAmount}
          onChange={onSAPCardGoodForChange}
        />
      </div>
    </div>
  );

  const renderEmailFieldLabel = () => (
    <div className={classes.customLabel}>
      <TextBody addClass={classes.emphasisText}>{t('quote::Email address ')}</TextBody>
      <TextBody addClass={classes.required}>*</TextBody>
      <InfoButton
        ariaLabel={t('Open information about SAP admin email')}
        calloutProps={{
          closeButtonAriaLabel: t('Close'),
          headline: t('quote::SAP global administrator email'),
          maxWidth: 285,
        }}
        id="sap-email-button"
      >
        <TextBody>
          {t(
            'quote::The email address provided will be used to send a sign up email to the global adminstrator that will have access to the SAP Cloud Platform Cockpit.'
          )}
        </TextBody>
      </InfoButton>
    </div>
  );
  const renderOpportunityFieldLabel = () => (
    <div className={classes.customLabel}>
      <TextBody addClass={classes.emphasisText}>
        {t('quote::SAP opportunity ID (optional) ')}
      </TextBody>
      <InfoButton
        ariaLabel={t('Open information about SAP opportunity ID')}
        calloutProps={{
          closeButtonAriaLabel: t('Close'),
          headline: t('quote::SAP opportunity ID'),
          maxWidth: 285,
        }}
        id="sap-oppty-info-button"
      >
        <TextBody>
          {t(
            'quote::The SAP opportunity ID is approximately a nine-digit number used in cosell scenarios to reference to the SAP seller.'
          )}
        </TextBody>
      </InfoButton>
    </div>
  );

  const secondStep = (
    <div className={stepContainerClass}>
      <div>
        <TextBody addClass={classes.textTertiary}>
          {t('quote::SAP global administrator information')}
        </TextBody>
      </div>
      <div className={classes.textInputContainer}>
        <TextboxStandard
          ariaLabel={t('quote::First name input')}
          autoFocus={true}
          dataAutomationId="SAPCardEnterFirstName"
          disabled={props.readOnly}
          label={t('quote::First name')}
          required
          value={values.firstNameValue}
          onChange={inputActions.onFirstNameChange}
        />
      </div>
      <div className={classes.textInputContainer}>
        <TextboxStandard
          ariaLabel={t('quote::Last name input')}
          dataAutomationId="SAPCardEnterLastName"
          disabled={props.readOnly}
          errorMessageStyle={classes.errorMessageStyle}
          label={t('quote::Last name')}
          required
          value={values.lastNameValue}
          onChange={inputActions.onLastNameChange}
        />
      </div>
      <div>
        <TextboxStandard
          ariaLabel={t('quote::Email input')}
          dataAutomationId="SAPCardEnterEmail"
          disabled={props.readOnly}
          errorMessage={errorMessages.emailAddressErrorMessage}
          errorMessageStyle={classes.errorMessageStyle}
          placeholder={t('quote::someone@example.com')}
          value={values.emailAddressValue}
          onChange={onSAPCardEmailChange}
          onRenderLabel={renderEmailFieldLabel}
        />
      </div>
      <SectionSeparator addClass={classes.sectionSeparator} />
      <div className={props.classes.opportunityIdContainer}>
        <TextboxStandard
          ariaLabel={t('quote::SAP opportunity ID input')}
          dataAutomationId="SAPCardOpptyId"
          disabled={props.readOnly}
          errorMessage={errorMessages.sapOpptyIdErrorMessage}
          errorMessageStyle={classes.errorMessageStyle}
          value={values.SAPOpptyIdValue}
          onChange={onSAPCardOpptyIdChange}
          onRenderLabel={renderOpportunityFieldLabel}
        />
      </div>
    </div>
  );

  const isValidFirstName = !!(values.firstNameValue && values.firstNameValue.trim());
  const isValidLastName = !!(values.lastNameValue && values.lastNameValue.trim());
  const secondStepIsEdited =
    values.firstNameValue !== undefined ||
    values.lastNameValue !== undefined ||
    values.SAPOpptyIdValue !== undefined ||
    values.emailAddressValue !== undefined;

  const slideErrors = {
    first: !!(errorMessages.amountErrorMessage || errorMessages.goodForErrorMessage),
    second: !!(
      errorMessages.emailAddressErrorMessage ||
      errorMessages.sapOpptyIdErrorMessage ||
      (secondStepIsEdited && (!isValidFirstName || !isValidLastName))
    ),
  };

  const slides: Slide[] = [
    {
      content: firstStep,
      id: 'first-step',
      hasError: slideErrors.first,
      dotAriaLabel: slideErrors.first
        ? t('quote::Step {{step}} error present', { step: '1' })
        : t('quote::Step {{step}}', { step: '1' }),
    },
    {
      content: secondStep,
      id: 'second-step',
      hasError: slideErrors.second,
      dotAriaLabel: slideErrors.second
        ? t('quote::Step {{step}} error present', { step: '2' })
        : t('quote::Step {{step}}', { step: '2' }),
    },
  ];
  return (
    <div className={props.classes.container}>
      <Carousel initialSlideId="first-step" slides={slides} />
    </div>
  );
};

export const SAPCardProductFieldsStyled = withStyles(SAPCardStyles)(
  SAPConfigCardProductFieldsUnstyled
);

export const SAPCardProductFields = SAPCardProductFieldsStyled;
