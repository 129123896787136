import {
  Address as AddressIon,
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  TextBody,
  TextTitle,
  VerificationField,
} from 'components';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { GET_QUOTE_GENERAL_INFO } from 'features-apollo/quote/components/PropertySheets/General/queries';
import { Fail, Processing } from 'features/components/dialogs';
import { Success } from 'features/proposal/components/Dialogs/Shared';
import { enrollmentNumberRegExp } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { Address } from 'services/customer/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { LegacyDialogStyles } from './LegacyDialog.styles';
import { CONVERT_QUOTE_TO_LEGACY, SEARCH_LEGACY_ORGANIZATION } from './queries';

export enum DialogSteps {
  EnterEnrollment = 1,
  Convert = 2,
  Processing = 3,
}

const dialogDimension = {
  height: 340,
  width: 382,
};

export interface LegacyDialogProps {
  quoteId: string;
  etag: string;
  hasLineItems: boolean;
}

export type Props = LegacyDialogProps & WithStyles<typeof LegacyDialogStyles>;

const LegacyDialogUnstyled: React.FC<Props> = props => {
  const { classes, quoteId, etag, hasLineItems } = props;
  const { t } = useTranslation();

  const [searchLegacyEnrollment, { data, loading, error }] = useLazyQuery(
    SEARCH_LEGACY_ORGANIZATION,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [convertQuoteToLegacyGQL, { data: convertData, error: convertError }] = useMutation(
    CONVERT_QUOTE_TO_LEGACY,
    {
      refetchQueries: () => [
        { query: GET_QUOTE_GENERAL_INFO, variables: { id: quoteId } },
        { query: GET_QUOTE, variables: { id: quoteId } },
      ],
    }
  );

  const legacyOrganizationData = oc(data).searchLegacyOrganization();

  const [currentStep, setCurrentStep] = React.useState<DialogSteps>(DialogSteps.EnterEnrollment);
  const [lastVerified, setLastVerified] = React.useState<string | undefined>();
  const differentOrEmpty = !lastVerified || lastVerified === '';
  const isError = !!error || (!loading && !legacyOrganizationData);
  const address = legacyOrganizationData && legacyOrganizationData.address;
  const nextDisabled = loading || isError || differentOrEmpty || !legacyOrganizationData;

  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };

  const createAddressContent = (address?: Address) => {
    return (
      <div className={classes.addressPadding}>
        {address ? (
          <AddressIon
            address={address}
            dataAutomationId="organizationAddress"
            showCompanyName={true}
          />
        ) : (
          <TextBody>{t('quote::Customer address not found')}</TextBody>
        )}
      </div>
    );
  };

  const getHeader = (titleText: string) => (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="legacyDialog"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{titleText}</TextTitle>
    </DialogHeader>
  );

  const getFooter = (
    footerText: string,
    onButtonClick: () => void,
    disabled: boolean,
    dataAutomationId?: string
  ) => (
    <div className={`${classes.footer} ${classes.flexAlignRight}`}>
      <PrimaryButton
        dataAutomationId={dataAutomationId}
        disabled={disabled}
        text={footerText}
        onClick={onButtonClick}
      />
    </div>
  );

  const EnrollmentBody = (
    <div className={classes.body}>
      <VerificationField
        buttonText={t('quote::Verify')}
        dataAutomationId="enrollmentNumberVerification"
        debounceValue={500}
        errorMessage={t('quote::Enrollment number not found')}
        isError={isError}
        isLoading={loading}
        isVerified={!error && legacyOrganizationData}
        lastVerified={lastVerified}
        textboxAutoFocus={true}
        textboxClassName={classes.enrollmentInput}
        textboxLabel={t('quote::Customer lookup')}
        textboxMaxLength={8}
        textboxPlaceholder={t('quote::Enter enrollment number')}
        textboxRequired={true}
        textboxTitle={t('quote::Enter a 7-8 digit enrollment number')}
        validationErrorMessage={t('quote::Enter a valid enrollment number')}
        verifiedStatusText={t('quote::Verified')}
        onValidate={(value: string) => enrollmentNumberRegExp.test(value)}
        onVerify={(value: string) => {
          searchLegacyEnrollment({
            variables: {
              enrollmentNumber: value,
            },
          });

          setLastVerified(value);
        }}
      />
      {address && createAddressContent(address)}
    </div>
  );

  const Enrollment = (
    <CustomDialogBox
      bodySlot={EnrollmentBody}
      dataAutomationId="convertToLegacyEnrollment"
      footerSlot={getFooter(
        t('quote::Next'),
        () => {
          setCurrentStep(DialogSteps.Convert);
        },
        nextDisabled,
        'convertToLegacyEnrollmentNext'
      )}
      headerSlot={getHeader(t('quote::Convert to legacy'))}
      {...dialogDimension}
    />
  );

  const ConvertBody: JSX.Element = (
    <div className={classes.body}>
      <TextBody>
        {t(
          'quote::Products will be restricted to reserved instances and future discounts only. Products must be added after converting the Quote to Legacy. Remove all products to proceed.'
        )}
      </TextBody>
    </div>
  );

  const convert = () => {
    lastVerified &&
      convertQuoteToLegacyGQL({
        variables: {
          input: {
            quoteIdentifier: {
              id: quoteId,
              etag: etag,
            },
            enrollmentNumber: lastVerified,
          },
        },
      });
    setCurrentStep(DialogSteps.Processing);
  };

  const ConvertView = (
    <CustomDialogBox
      bodySlot={ConvertBody}
      dataAutomationId="convertToLegacy"
      footerSlot={getFooter(
        t('quote::Convert'),
        convert,
        hasLineItems,
        'convertToLegacyEnrollmentConvert'
      )}
      headerSlot={getHeader(t('quote::Convert to legacy quote?'))}
      {...dialogDimension}
    />
  );

  switch (currentStep) {
    case DialogSteps.Processing:
      if (convertError) {
        return (
          <Fail
            {...dialogDimension}
            closeDialog={closeDialog}
            dataAutomationId="convertLegacyFail"
            message={t('quote::Sorry, the "Convert" action failed.')}
            onTryAgainClick={convert}
          />
        );
      } else if (convertData && convertData.convertQuoteToLegacy) {
        return (
          <Success
            message={t('quote::Successfully converted to legacy quote')}
            {...dialogDimension}
            closeDialog={closeDialog}
            dataAutomationId="convertLegacySuccess"
          />
        );
      } else {
        return (
          <Processing {...dialogDimension} message2={t('quote::Converting quote to legacy...')} />
        );
      }
    case DialogSteps.Convert:
      return ConvertView;
    case DialogSteps.EnterEnrollment:
    default:
      return Enrollment;
  }
};

export const LegacyDialog = withStyles(LegacyDialogStyles)(LegacyDialogUnstyled);
