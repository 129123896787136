import { mergeClassNames } from 'components/utilities';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { ShimmerForBackgroundStandout } from '../Progress';

export const LOADING_TEST_IDENTIFIER = 'row-loading';

const styles = (theme: ThemeProps) => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.backgroundStandout,
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    },
    loading: {
      paddingLeft: 17,
      paddingTop: 7,
      paddingBottom: 7,
      flexGrow: 1,
      justifyContent: 'space-around',
      '& > div:not(:last-child)': {
        marginBottom: 8,
      },
    },
  };
};

interface RowProps {
  /**
   * Adds CSS class to div container
   */
  className?: string;
  /**
   * Decides whether shimmers get display.
   */
  loading?: boolean;
  /**
   * Add inline style to container element
   */
  containerCustomStyle?: React.CSSProperties;
  /**
   * Total of shimmers displayed when loading
   */
  shimmersCount?: number;
}

type Props = RowProps & WithStyles<typeof styles>;

const RowUnstyled: React.FC<Props> = props => {
  if (props.loading) {
    const { shimmersCount = 2 } = props;
    const shimmers = [<ShimmerForBackgroundStandout key="shimmer_1" width="30%" />];

    for (let i = 2; i <= shimmersCount; i++) {
      shimmers.push(<ShimmerForBackgroundStandout key={`shimmer_${i}`} width="20%" />);
    }

    return (
      <div
        className={mergeClassNames([
          props.classes.container,
          props.classes.loading,
          props.className,
        ])}
        data-automation-id={LOADING_TEST_IDENTIFIER}
        style={props.containerCustomStyle}
      >
        {shimmers}
      </div>
    );
  }

  return (
    <div
      className={mergeClassNames([props.classes.container, props.className])}
      style={props.containerCustomStyle}
    >
      {props.children}
    </div>
  );
};

/**
 * Base structure and style of rows in quote list.
 */
export const Row = withStyles(styles)(RowUnstyled) as React.FC<RowProps>;
