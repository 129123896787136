import { CreditRequest } from 'services/credit/types';
import { Proposal } from 'services/proposal/types';

export interface RequestCreditLine {
  quote: Proposal;
  creditRequest: CreditRequest;
  annualDealEstimate: string;
  minimumCreditLine: string;
}

export interface RequestCreditLineState {
  annualDealEstimate: string;
  minimumCreditLine: string;
  annualDealEstimateError: string;
  minimumCreditLineError: string;
}

export interface AnnualDealEstimate {
  annualDealEstimate?: number;
  minimumCreditLine?: number;
}

// It is union of CreditLineReason and some addition status.
export enum CreditLineReason {
  SafeList = 'account is safe listed',
  PendingReview = 'pendingreview',
  UnderReview = 'underreview',
  ReviewCancelled = 'reviewcancelled',
  Rejected = 'rejected',
  CreditLine = 'CreditLine',
  CreditIncrease = 'CreditIncrease',
  None = 'None',
  Approved = 'approved',
}

export const CreditConstant = {
  maxDealEstimateLength: 15,
};

export interface AnnualDealEstimatePayload {
  annualDealEstimate: string;
  minCreditLineDivisor: number;
}
