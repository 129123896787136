import { MediumIcon } from 'components/ions/Icons';
import { TextBody } from 'components/ions/Text';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { indicatorStyles } from './StatusIndicators.styles';

export interface StatusIndicatorProps {
  id?: string;
  text?: string;
}

type Props = StatusIndicatorProps & WithStyles<typeof indicatorStyles>;

interface DraftProps {
  isWarning?: boolean;
}
const StatusIndicatorDraftUnstyled: React.FC<Props & DraftProps> = props => {
  return (
    <TextBody addClass={props.isWarning ? props.classes.warning : ''} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="Edit" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorOpenUnstyled: React.FC<Props> = props => {
  return (
    <TextBody id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="OpenFolderHorizontal" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorReadyUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.warning} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="Share" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorApprovedUnstyled: React.FC<Props> = props => {
  return (
    <TextBody
      addClass={props.classes.approved}
      dataAutomationId="verifiedStatusIndicator"
      id={props.id}
    >
      <MediumIcon addClass={props.classes.icon} iconName="CheckMark" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorTransactedUnstyled: React.FC<Props> = props => {
  return (
    <TextBody id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="ShoppingCart" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorUnknownUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.unknown} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="Info" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorInvalidUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.danger} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="ErrorBadge" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorAwaitingUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.warning} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="Clock" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorDeletedUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.danger} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="Delete" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorRejectedUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.danger} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="Cancel" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorExpiredUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.text} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="HourGlass" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorClosedUnstyled: React.FC<Props> = props => {
  return (
    <TextBody id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="BarChartVertical" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorPublishedUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.approved} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="Send" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorPurchaseFailedUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.danger} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="AllCurrency" />
      {props.text || props.children}
    </TextBody>
  );
};
const StatusIndicatorPurchasingUnstyled: React.FC<Props> = props => {
  return (
    <TextBody addClass={props.classes.approved} id={props.id}>
      <MediumIcon addClass={props.classes.icon} iconName="AllCurrency" />
      {props.text || props.children}
    </TextBody>
  );
};

const StatusIndicatorDraft = withStyles(indicatorStyles)(StatusIndicatorDraftUnstyled) as React.FC<
  StatusIndicatorProps & DraftProps
>;
const StatusIndicatorReady = withStyles(indicatorStyles)(StatusIndicatorReadyUnstyled) as React.FC<
  StatusIndicatorProps
>;
const StatusIndicatorOpen = withStyles(indicatorStyles)(StatusIndicatorOpenUnstyled) as React.FC<
  StatusIndicatorProps
>;
const StatusIndicatorApproved = withStyles(indicatorStyles)(
  StatusIndicatorApprovedUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorTransacted = withStyles(indicatorStyles)(
  StatusIndicatorTransactedUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorUnknown = withStyles(indicatorStyles)(
  StatusIndicatorUnknownUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorInvalid = withStyles(indicatorStyles)(
  StatusIndicatorInvalidUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorAwaiting = withStyles(indicatorStyles)(
  StatusIndicatorAwaitingUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorDeleted = withStyles(indicatorStyles)(
  StatusIndicatorDeletedUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorRejected = withStyles(indicatorStyles)(
  StatusIndicatorRejectedUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorExpired = withStyles(indicatorStyles)(
  StatusIndicatorExpiredUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorClosed = withStyles(indicatorStyles)(
  StatusIndicatorClosedUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorPublished = withStyles(indicatorStyles)(
  StatusIndicatorPublishedUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorPurchaseFailed = withStyles(indicatorStyles)(
  StatusIndicatorPurchaseFailedUnstyled
) as React.FC<StatusIndicatorProps>;
const StatusIndicatorPurchasing = withStyles(indicatorStyles)(
  StatusIndicatorPurchasingUnstyled
) as React.FC<StatusIndicatorProps>;
export {
  StatusIndicatorReady,
  StatusIndicatorOpen,
  StatusIndicatorDraft,
  StatusIndicatorAwaiting,
  StatusIndicatorApproved,
  StatusIndicatorTransacted,
  StatusIndicatorInvalid,
  StatusIndicatorUnknown,
  StatusIndicatorDeleted,
  StatusIndicatorRejected,
  StatusIndicatorExpired,
  StatusIndicatorClosed,
  StatusIndicatorPublished,
  StatusIndicatorPurchaseFailed,
  StatusIndicatorPurchasing,
};
