import {
  ApplicableDiscountsFragment,
  DateOrDurationFragment,
} from 'features-apollo/quote/components/DiscountCard/queries';
import gql from 'graphql-tag';

export const messageFragment = gql`
  fragment MessageFragment on Message {
    messageType
    messageDisplay {
      display
    }
    messageTitle {
      display
    }
    messageSource
  }
`;

export const userFragment = gql`
  fragment UserFragment on User {
    mail
  }
`;

export const empoweredUsersFragment = gql`
  fragment EmpoweredUsersFragment on EmpoweredUsers {
    market
    hierarchyLevel
    level
    policy
    users {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const requiredApprovalFragment = gql`
  fragment RequiredApprovalFragment on RequiredApproval {
    order
    level
    policy
    state
    assignedUser
    assignedUsers
    actor {
      __typename
      ... on User {
        mail
      }
    }
    empoweredUsers {
      ...EmpoweredUsersFragment
    }
  }
  ${empoweredUsersFragment}
`;

export const approvalUnionBody = gql`
  fragment ApprovalUnionBody on ApprovalUnion {
    __typename
    ... on Approval {
      id
      status

      history {
        status
        createdDate
        comments
        action
        createdBy {
          ... on User {
            ...UserFragment
          }
          ... on NonUserActor {
            name
          }
        }
        actor {
          ... on User {
            ...UserFragment
          }
          ... on NonUserActor {
            name
          }
        }
      }
      requiredApprovals {
        ...RequiredApprovalFragment
      }
    }
    ... on QuoteApproval {
      workflow
      requiredApprovals {
        ...RequiredApprovalFragment
      }
    }
  }
  ${userFragment}
  ${empoweredUsersFragment}
  ${requiredApprovalFragment}
`;

export const creditFragment = gql`
  fragment CreditFragment on Credit {
    currency
    decision
    reason
    creditLine
    remainingBalance
    creditRiskScore
  }
`;

export const addressBody = gql`
  fragment AddressBody on Address {
    companyName
    addressLine1
    addressLine2
    addressLine3
    city
    region
    country
    marketInfo {
      display
    }
    postalCode
    phoneNumber
    email
  }
`;

export const crmLeadBody = gql`
  fragment CrmLeadBody on CrmLead {
    id
    salesAccount {
      id
      tpid
      address {
        ...AddressBody
      }
    }
    ... on OpportunitySimple {
      contact {
        id
        mail
      }
    }
  }
  ${addressBody}
`;

export const organizationSimpleBody = gql`
  fragment OrganizationSimpleBody on OrganizationSimple {
    id
    account {
      id
      description
      primaryTenantId
      tenants {
        tenantId
        tenantName
      }
      assets {
        subscriptionId
      }
    }
    accountId
    address {
      ...AddressBody
    }
    agreementSigned
    assets {
      subscriptionId
    }
    language
    languageInfo {
      gqlCode
      code
      display
      twoLetterCode
    }
    lastInvoiceDate
    name
    tradeName
    type
    vatId
    version
    credit {
      __typename
      ...CreditFragment
    }
    customerTag
  }
  ${addressBody}
  ${creditFragment}
`;

export const invitedUserSimpleBody = gql`
  fragment InvitedUserSimpleBody on InvitedUserSimple {
    email
    oid
    tenant {
      id
    }
  }
`;

export const monetaryLineItemFragment = gql`
  fragment MonetaryLineItemFragment on MonetaryLineItem {
    reason
    isTerm
    purchaseTermUnits
    term {
      termId
    }
    duration {
      ...dateOrDurationFragment
    }
    sku {
      duration {
        minimum
        maximum
      }
    }
    product {
      amount {
        maximum
        minimum
      }
      reasons(channelFilter: "Field") {
        code
        name
      }
      id
      title
      startCondition
      productType
    }
  }
  ${DateOrDurationFragment}
`;

export const ecifLineItemFragment = gql`
  fragment EcifLineItemFragment on EcifLineItem {
    id
    title
    purchaseTermUnits
    agreement {
      referenceData
    }
    oneAskCaseNumber
    term {
      termId
    }
    supplementalTermReferenceData {
      key
      value
    }
    duration {
      ...dateOrDurationFragment
    }
    sku {
      duration {
        minimum
        maximum
      }
    }
    product {
      id
      title
      description
      durations
    }
    messages {
      messageTitle {
        key
        translation
        language
        display
      }
      messageDisplay {
        key
        translation
        language
        display
      }
      messageType
      messageSource
    }
    ecifConfig {
      caseId
      caseNumber
      currency
      totalApprovedAmount
      lineItems {
        lineItemId
        type
        productFamily
        sku {
          skuId
          shortDescription
          title
        }
        endDate
        amount
      }
    }
  }
  ${DateOrDurationFragment}
`;

export const sapLineItemFragment = gql`
  fragment SapLineItemFragment on SapLineItem {
    isTerm
    email
    firstName
    lastName
    opportunityId
    purchaseTermUnits
    term {
      termId
    }
    duration {
      ...dateOrDurationFragment
    }
    sku {
      duration {
        minimum
        maximum
      }
    }
    product {
      amount {
        maximum
        minimum
      }
      id
      title
      startCondition
      productType
    }
  }
  ${DateOrDurationFragment}
`;

export const financingTermLineItemFragment = gql`
  fragment FinancingTermLineItemFragment on FinancingTermLineItem {
    associatedLineItem {
      id
      title
      configurationSummary {
        display
      }
      product {
        id
        title
      }
    }
    product {
      relatedProducts {
        relationshipType
        product {
          id
          productType
          title
        }
      }
    }
    term {
      termId
    }
    supplementalTermReferenceData {
      key
      value
    }
    duration {
      ...dateOrDurationFragment
    }
  }
  ${DateOrDurationFragment}
`;

export const simpleTermLineItemFragment = gql`
  fragment SimpleTermLineItemFragment on SimpleTermLineItem {
    term {
      termId
    }
    duration {
      ...dateOrDurationFragment
    }

    supplementalTermReferenceData {
      key
      value
    }
  }
  ${DateOrDurationFragment}
`;

export const discountLineItemFragment = gql`
  fragment DiscountLineItemFragment on DiscountLineItem {
    exchangeRate
    product {
      skuCount
      filters {
        key
        values
      }
      title
      productType
      productFamily
      id
    }
    discount {
      percentage
      type
      priceAdjustmentType
      priceGuaranteeDate
      scope {
        name
        type
        values
      }
      action
      duration {
        ...dateOrDurationFragment
      }
    }
    applicableDiscounts {
      ...applicableDiscountsFragment
    }
  }
  ${DateOrDurationFragment}
  ${ApplicableDiscountsFragment}
`;

export const purchaseLineItemFragment = gql`
  fragment PurchaseLineItemFragment on PurchaseLineItem {
    exchangeRate
    quantity
    isAutoRenew
    listPrice
    customerPrice
    oneTimeDiscount
    amount
    sku {
      quantity {
        minimum
        maximum
      }
    }
    product {
      skuCount
      filters {
        key
        values
      }
      title
      productType
      productFamily
      id
    }
    pricingCurrency
    applicableDiscounts {
      ...applicableDiscountsFragment
    }
  }
  ${ApplicableDiscountsFragment}
`;

export const lineItemBody = gql`
  fragment LineItemBody on LineItem {
    __typename
    id
    isConfigurable
    title
    groups
    configurationSummary {
      display
    }
    isReadyForPricing
    sku {
      skuId
      title
      filters {
        key
        values
      }
    }
    availability {
      availabilityId
    }
    term {
      termId
    }
    catalogContext {
      audience
      agreementType
      nationalCloud
      market
      languages
      action
    }
    messages {
      ...MessageFragment
    }
    product {
      __typename
      durations
      productType
      productFamily
      title
      id
      filteredSkusRaw(groupBy: ["Location", "TermDescription", "Action"])
      isPotentiallyDiscountable
      description
      plainTextDescription
      startCondition
      inputs {
        type {
          id
          value
        }
        productFamily {
          id
          value
        }
      }
      skus {
        skuId
        title
      }
    }
    ... on SapLineItem {
      ...SapLineItemFragment
    }
    ... on PurchaseLineItem {
      ...PurchaseLineItemFragment
    }
    ... on DiscountLineItem {
      ...DiscountLineItemFragment
    }
    ... on FinancingTermLineItem {
      ...FinancingTermLineItemFragment
    }
    ... on SimpleTermLineItem {
      ...SimpleTermLineItemFragment
    }
    ... on MonetaryLineItem {
      ...MonetaryLineItemFragment
    }
    ... on EcifLineItem {
      ...EcifLineItemFragment
    }
  }
  ${messageFragment}
  ${ApplicableDiscountsFragment}
  ${DateOrDurationFragment}
  ${monetaryLineItemFragment}
  ${sapLineItemFragment}
  ${financingTermLineItemFragment}
  ${simpleTermLineItemFragment}
  ${discountLineItemFragment}
  ${purchaseLineItemFragment}
  ${messageFragment}
  ${ecifLineItemFragment}
`;

export const agreementsBody = gql`
  fragment AgreementsBody on ModernAgreementActualConnection {
    edges {
      node {
        number
        startEffectiveDate
        endEffectiveDate
        documentDisplayUri
        customerId
        documentFileName
        participants {
          type
          firstName
          lastName
          email
          statusDate
        }
        messages {
          ...MessageFragment
        }
      }
    }
  }
`;

export const quoteBody = gql`
  fragment QuoteBody on Quote {
    id
    etag
    agreementType
    assignedTo
    audience
    billingCurrency
    canShareLeadDiscounts
    clouds
    exchangeRateDate
    expirationDate
    fgoe
    market
    motion
    name
    productAudience
    readOnly
    status
    totalPrice
    transactOnBehalf
    transactionModel
    version
    vlAgreementNumber
    agreements(input: { agreementType: SPECIAL }) {
      ...AgreementsBody
    }
    approval {
      ...ApprovalUnionBody
    }
    crmLead {
      ...CrmLeadBody
    }
    endCustomer {
      ...OrganizationSimpleBody
    }
    endCustomerInvitedUser: invitedUser(customerType: EndCustomer) {
      ...InvitedUserSimpleBody
    }
    leadOrganization {
      ...OrganizationSimpleBody
    }
    languageInfo {
      gqlCode
    }
    lineItems {
      ...LineItemBody
    }
    messages {
      ...MessageFragment
    }
    msContact {
      ...UserFragment
      displayName
    }
    soldTo {
      dealEstimate
      monthlyCreditLimit
      organization {
        ...OrganizationSimpleBody
      }
    }
    soldToInvitedUser: invitedUser(customerType: SoldToCustomer) {
      ...InvitedUserSimpleBody
    }
  }
  ${agreementsBody}
  ${approvalUnionBody}
  ${crmLeadBody}
  ${invitedUserSimpleBody}
  ${organizationSimpleBody}
  ${lineItemBody}
  ${userFragment}
  ${messageFragment}
`;
