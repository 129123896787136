import { ThemeProps } from 'styles';

export const editorListStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    quoteWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    pivotRow: {
      marginTop: 30,
      backgroundColor: 'transparent',
    },
    quoteContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      backgroundColor: 'transparent',
      minHeight: 0,
    },
    quoteContent: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 750,
      height: '100%',
      width: 750,
      backgroundColor: `${palette.backgroundCommon}`,
      boxShadow: `0px 2px 10px ${palette.shadowColor}`,
      border: `1px solid ${palette.backgroundDivider}`,
      zIndex: '1',
    },
    actionBarRow: {
      height: 30,
      backgroundColor: `${palette.backgroundCommon}`,
    },
    columnHeadings: {
      height: 30,
      shadowColor: 'rgba(0,0,0,0.2)',
      backgroundColor: `${palette.backgroundCommon}`,
    },
    listContainer: {
      maxHeight: '100%',
      overflowY: 'auto',
      borderTop: `2px solid ${palette.backgroundDivider}`,
      borderBottom: `2px solid ${palette.backgroundDivider}`,
    },
    quoteFooter: {
      maxHeight: '100%',
      minHeight: 100,
    },
    quoteDetailsPane: {
      width: 325,
      backgroundColor: palette.backgroundStandout,
      minWidth: 325,
    },
  };
};

export const editorLayoutStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    leftPane: {
      boxSizing: 'border-box',
      backgroundColor: palette.backgroundPanel,
      height: '100%',
      minWidth: 260,
      maxWidth: 260,
      borderRight: `1px solid ${palette.backgroundDivider}`,
      boxShadow: `-2px 18px 18px ${palette.shadowColor}`,
      overflow: 'hidden',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    mainContent: {
      height: '100%',
      minWidth: '60%',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '2%',
      flexGrow: 1,
    },
    mainContentNoLeftPane: {
      paddingLeft: 48,
      height: '100%',
      minWidth: '60%',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    isVisible: {
      display: 'block',
    },
    notVisible: {
      display: 'none',
    },
  };
};
