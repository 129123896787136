import { Epic } from 'redux-observable';
import { Services } from 'services/types';
import { Action, StateType } from 'typesafe-actions';

export type Store = StateType<typeof import('./index').default>;
export type RootState = StateType<ReturnType<typeof import('./root-reducer').default>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RootEpic<T extends Action<any>> = Epic<T, T, RootState, Services>;

export enum AsyncState {
  Unset,
  Request,
  Success,
  Failure,
}
