import { DetailsListAtom } from 'components/atoms/DetailsList';
import {
  listContainerDivStyles,
  stickyHeaderGroupedStyles,
} from 'components/ions/DetailsLists/StickyHeaderDetailsListGrouped/SitckyHeaderDetailsListGrouped.styles';
import { useResizeAfterFirstRender } from 'hooks';
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  IColumn,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IGroupRenderProps,
  IRenderFunction,
  ITooltipHostProps,
  ScrollablePane,
  ScrollbarVisibility,
  Selection as FabricSelection,
  SelectionMode,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';

/**
 * Props for StickyHeaderDetailsListGrouped Component
 *
 * @prop {string} [ariaLabelForSelectionColumn] - An ARIA label for the name of the selection column, for localization.
 * @prop {CheckboxVisibility}  checkboxVisibility - Controls the visibility of selection check box. *
 * @prop {IColumn[]} columns - Given column definitions. If none are provided, default columns will be created based on the item's properties.
 * @prop {number} height - height of the List.
 * @prop {string} [id] -id.
 * @prop {any[]} items -  The items to render.
 * @prop {boolean} [removeRowSeparators] - This would remove the row separators between rows
 * @prop {boolean} [shouldResizeAfterFirstRender] - Used to remove unnecessary horizontal bars that appear on Edge & Firefox because of the resize observer
 * @prop {Selection} [selection] - Optional selection model to track selection state.
 * @prop {SelectionMode}  selectionMode - Controls how/if the details list manages selection. Options include none, single, multiple.
 * @prop {function} [onRenderDetailsFooter] - An Override For DetailsFooter, useful for showing Errors or Shimmer.
 *
 */
export interface StickyHeaderDetailsListGroupedProps {
  ariaLabelForSelectionColumn?: string;
  checkboxVisibility: CheckboxVisibility;
  groups: any[];
  columns?: IColumn[];
  height: string;
  width?: string;
  id?: string;
  items: any[];
  wayPoint?: () => React.ReactNode;
  removeRowSeparators?: boolean;
  shouldResizeAfterFirstRender?: boolean;
  selection?: FabricSelection;
  selectionMode: SelectionMode;
  onRenderDetailsFooter?: () => JSX.Element | null;
  stickyOnRenderHeaderCreator?: () => JSX.Element | null;
  groupProps?: IGroupRenderProps;
  isLoadingMore?: boolean;
  stylesOverride?: string;
  onRenderRow?: IRenderFunction<IDetailsRowProps>;
}

type Props = StickyHeaderDetailsListGroupedProps & WithStyles<typeof stickyHeaderGroupedStyles>;

const defaultStickyHeaderCreator = (headerClass: string) => {
  const onRenderDetailsHeader = (
    headerProps?: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ) => {
    if (!defaultRender || !headerProps) {
      loggerService.error({
        error: new Error('OnRenderDetailsHeader defaultRender or headerProps is undefined or null'),
      });
    }
    const onRenderColumnHeaderToolTip = (tooltipHostProps?: ITooltipHostProps) => (
      <TooltipHost {...tooltipHostProps} />
    );
    return (
      <Sticky
        isScrollSynced
        stickyClassName={headerClass}
        stickyPosition={StickyPositionType.Header}
      >
        {defaultRender &&
          defaultRender({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...headerProps!,
            onRenderColumnHeaderTooltip: onRenderColumnHeaderToolTip,
          })}
      </Sticky>
    );
  };
  return onRenderDetailsHeader;
};

export const StickyHeaderDetailsListGroupedUnstyled: React.FC<Props> = (props: Props) => {
  const {
    ariaLabelForSelectionColumn,
    checkboxVisibility,
    columns,
    groups,
    height,
    width,
    id,
    items,
    removeRowSeparators,
    shouldResizeAfterFirstRender,
    selection,
    selectionMode,
    onRenderDetailsFooter,
    groupProps,
    classes,
    wayPoint,
    onRenderRow,
  } = props;
  const headerClass = props.stylesOverride || props.classes.header;
  const onHeaderRender =
    props.stickyOnRenderHeaderCreator || defaultStickyHeaderCreator(headerClass);

  useResizeAfterFirstRender(shouldResizeAfterFirstRender);

  const style = listContainerDivStyles(height, width ? width : '100%') as React.CSSProperties;

  const mergedClasses = `${
    removeRowSeparators ? [classes.header, classes.removeRowSeparators].join(' ') : classes.header
  } ${classes.row}`;

  const mainclass = props.stylesOverride || mergedClasses;

  return (
    <div id={id} style={style}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsListAtom
          ariaLabelForSelectionColumn={ariaLabelForSelectionColumn}
          checkboxVisibility={checkboxVisibility}
          columns={columns}
          constrainMode={ConstrainMode.unconstrained}
          groupProps={groupProps}
          groups={groups}
          items={items}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          mainClass={mainclass}
          selection={selection}
          selectionMode={selectionMode}
          onRenderDetailsFooter={onRenderDetailsFooter}
          onRenderDetailsHeader={onHeaderRender}
          onRenderRow={onRenderRow}
        />
        {wayPoint && wayPoint()}
      </ScrollablePane>
    </div>
  );
};

export const StickyHeaderDetailsListGrouped = withStyles(stickyHeaderGroupedStyles)(
  StickyHeaderDetailsListGroupedUnstyled
) as React.FC<StickyHeaderDetailsListGroupedProps>;
