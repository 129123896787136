import { h1AtomStyles } from 'components/atoms/Text/H1Atom/H1Atom.styles';
import { h1Styles } from 'components/ions/Text/H1/H1.styles';
import { sharedStyles, ThemeProps } from 'styles';

export const summaryDetailStyles = (theme: ThemeProps) => {
  const { fontWeights } = theme.fonts;
  const { backgroundStandout, backgroundCommon } = theme.palette;
  return {
    // #region Approval comments styles
    commentAuthor: { padding: 5, marginLeft: 10 },
    commentContainer: {
      overflow: 'hidden',
    },
    commentSection: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      background: backgroundStandout,
      padding: 8,
    },
    commentText: {
      fontWeight: fontWeights.semiBold,
    },
    commentTextContainer: { padding: 2, marginLeft: 10 },
    // #endregion
    spinner: {
      flexGrow: 0.5,
    },
    textOverflow: {
      ...sharedStyles(theme).textOverflow,
      display: 'block',
    },
    detail: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 0,
      minHeight: 0,
      fontSize: 12,
      flexGrow: 1,
    },
    flex: {
      display: 'flex',
    },
    noContact: {
      paddingTop: 12,
      color: theme.palette.textDisabled,
      fontStyle: theme.fonts.fontStyles.italic,
      marginLeft: '-15px',
      fontSize: theme.fonts.fontSizes.medium,
    },
    header: {
      padding: '20px 24px',
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
      background: backgroundStandout,
      display: 'flex',
      justifyContent: 'space-between',

      '& h1': {
        flexGrow: 1,

        '& small': {
          display: 'block',
          fontSize: 12,
          fontWeight: 'normal',
          marginTop: 4,
        },
      },

      '& aside': {
        textAlign: 'right',

        '& label': {
          marginTop: 6,
        },
      },
    },
    namesContainer: {
      maxWidth: 352,
    },
    annualDeal: {
      minWidth: '115px',
    },
    block: {
      display: 'block',
    },

    main: {
      background: backgroundCommon,
      height: '100%',
      padding: '0 30px 30px 30px',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',

      '& .contacts': {
        display: 'flex',

        '& label': {
          display: 'block',
          fontWeight: 'bold',
        },
      },

      '& .required': {
        '& p': {
          paddingTop: 8,

          '& button': {
            display: 'block',
            paddingTop: 8,
          },
        },
      },
    },

    customerDetails: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 8,
    },

    quoteDetails: {
      paddingBottom: 20,
      width: '100%',
    },

    right: {
      float: 'right',
    },

    estimate: {
      color: theme.palette.approveText,
    },

    h1: {
      ...h1AtomStyles(theme).headingStyles,
      ...h1Styles(theme).titleStyles,
    },
    marginTop21: { marginTop: 21 },
    marginTop10: { marginTop: 12 },
    semiBold: {
      fontWeight: fontWeights.semiBold,
    },
    sectionRow: { display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden' },
    innerRow: { display: 'flex', flexDirection: 'column' },
    paddingTopXSmall: {
      paddingTop: '3px',
    },
    italic: { fontStyle: 'italic' },
    customerCol: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '50%',
    },
    partnerCol: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '50%',
    },
    creditMargin: { marginLeft: 60 },
    approvalList: { marginTop: 4, '& div': { marginTop: 0 } },
    approvalContainer: { marginTop: 4, '& div:not(:first-child)': { marginTop: 0 } },
    marginTop12: { marginTop: 12 },
    maxWidth: { maxWidth: 150 },
    address: { display: 'flex', flexDirection: 'column' },
    darkText: { color: theme.palette.text },
    lightText: { color: theme.palette.textTertiary },
    spacing: { marginTop: 24, width: '100%' },
    customerLoading: { fontWeight: fontWeights.regular, color: theme.palette.textDisabled },
    shimmer: {
      '& .ms-ShimmerLine-root': {
        height: 26.64,
      },
    },
    safeList: {
      color: theme.palette.approveText,
    },
    pending: {
      color: theme.palette.dangerText,
    },
    rejected: {
      color: theme.palette.dangerText,
    },
    expirationDateContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 12,
    },
    goodUntilText: {
      color: theme.palette.textTertiary,
      fontSize: 12,
    },
    expirationDateRow: {
      display: 'flex',
      fontSize: 14,
      marginTop: 2,
    },
    expirationDateColor: {
      color: theme.palette.warningText,
    },
    extendDateText: {
      marginLeft: 16,
    },
  };
};
