import { SupportedTheme } from 'styles/theme';

export const searchHeaderStyles = (theme: SupportedTheme) => ({
  searchHeader: {
    backgroundColor: theme.palette.backgroundMain,
    borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    boxShadow: `18px -2px 18px ${theme.palette.shadowColor}`,
    paddingLeft: 40,
    paddingTop: 36,
    paddingBottom: 33,
  },
  title: {
    fontSize: theme.fonts.fontSizes.larger,
    paddingBottom: 7,
  },
  searchContainer: {
    display: 'flex',
    maxWidth: 600,
    paddingRight: 32,
  },
  searchBox: {
    width: '100%',
  },
  spacer: {
    paddingLeft: 16,
  },
  searchButton: {
    paddingTop: 1,
  },
});
