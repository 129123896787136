import { ErrorMessage, LinkButton } from 'components';
import {
  contributorRoleId,
  createCompleteList,
  createPartialList,
  ExistingOwner,
  LoadingErrorType,
  ownerRoleId,
  ownerRoleIds,
  OwnerType,
} from 'features-apollo/quote/components/ExistingOwners';
import {
  OrganizationIdentifierInput,
  QueryGetRoleAssignmentsArgs,
  QueryGetTenantArgs,
  RoleAssignments,
  TenantProfile,
} from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { DialogContext, DialogProps } from 'styles';

import { useQuery } from '@apollo/react-hooks';

import { GetRoleAssignments, GetTenantAdmins } from '../queries';
import { ExistingOwnersDialog } from './ExistingOwnersDialog';
import { existingOwnersDialogStyles } from './ExistingOwnersDialog.styles';

export interface ExistingOwnersLinkButtonProps {
  tenantId: string;
  organizationIdentifier: OrganizationIdentifierInput;
  invitedUser?: string;
  isGovernmentTenant?: boolean;
}

type Props = ExistingOwnersLinkButtonProps & WithStyles<typeof existingOwnersDialogStyles>;

export const ExistingOwnersLinkButtonUnstyled: React.FC<Props> = props => {
  const { classes, invitedUser, tenantId, organizationIdentifier } = props;
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);

  const [ownersExist, setOwnersExist] = React.useState<boolean>(false);
  const [loadingError, setLoadingError] = React.useState<LoadingErrorType>(LoadingErrorType.None);
  const [displayList, setDisplayList] = React.useState<ExistingOwner[]>([]);
  const [isInvitedUserOnlyOwner, setIsInvitedUserOnlyOwner] = React.useState<boolean>(false);
  const [tenantAdmins, setTenantAdmins] = React.useState<string[]>([]);
  const [tenantOwners, setTenantOwners] = React.useState<string[]>([]);
  const [tenantContributors, setTenantContributors] = React.useState<string[]>([]);

  const { loading: tenantRolesLoading, error: tenantRolesError, data } = useQuery<
    {
      getRoleAssignments: RoleAssignments[];
    },
    QueryGetRoleAssignmentsArgs
  >(GetRoleAssignments, {
    variables: {
      input: {
        roles: ownerRoleIds,
        organization: organizationIdentifier,
      },
    },
  });

  const { loading: adminsLoading, error: adminsError, data: adminsData } = useQuery<
    { getTenant: TenantProfile },
    QueryGetTenantArgs
  >(GetTenantAdmins, {
    variables: {
      tenant: tenantId,
      isGovernmentTenant: props.isGovernmentTenant,
    },
  });

  React.useEffect(() => {
    if (!tenantRolesLoading) {
      if (data && data.getRoleAssignments.length) {
        const roleAssignments = data.getRoleAssignments;
        let owners: string[] = [];
        roleAssignments.forEach((roleAssignment: RoleAssignments) => {
          if (roleAssignment.roleId === ownerRoleId && roleAssignment.userPrincipalName) {
            owners.push(roleAssignment.userPrincipalName);
          }
        });
        let contributors: string[] = [];
        roleAssignments.forEach((roleAssignment: RoleAssignments) => {
          if (roleAssignment.roleId === contributorRoleId && roleAssignment.userPrincipalName) {
            contributors.push(roleAssignment.userPrincipalName);
          }
        });
        setTenantOwners(owners);
        setTenantContributors(contributors);
      }
    }
  }, [tenantRolesLoading, data, tenantRolesError]);

  React.useEffect(() => {
    if (!adminsLoading && adminsData) {
      setTenantAdmins(adminsData.getTenant.tenantAdmins);
    }
  }, [adminsLoading, adminsData, adminsError]);

  React.useEffect(() => {
    let list: ExistingOwner[] = [];
    let error: LoadingErrorType = LoadingErrorType.None;

    if (!!tenantRolesError && !!adminsError) {
      error = LoadingErrorType.ErrorLoadingEverything;
    } else if (!!tenantRolesError) {
      error = LoadingErrorType.ErrorLoadingOwners;
      list = createPartialList(tenantAdmins, OwnerType.GlobalAdmin);
    } else if (!!adminsError) {
      error = LoadingErrorType.ErrorLoadingAdmins;
      const ownerList = createPartialList(tenantOwners, OwnerType.Owner);
      const contributorList = createPartialList(tenantContributors, OwnerType.Contributor);
      list = ownerList.concat(contributorList);
    } else {
      list = createCompleteList(tenantOwners, tenantContributors, tenantAdmins);
    }
    if (list.length && !ownersExist) {
      setOwnersExist(true);
    } else if (!list.length && ownersExist) {
      setOwnersExist(false);
    }

    const upnList = list.map(item => item.upn);
    if (invitedUser && upnList.includes(invitedUser)) {
      upnList.length === 1 && setIsInvitedUserOnlyOwner(true);
      list = list.filter(item => item.upn !== invitedUser);
    }
    setDisplayList(list);
    setLoadingError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tenantRolesError,
    adminsError,
    setDisplayList,
    setLoadingError,
    tenantAdmins,
    tenantOwners,
    tenantContributors,
    ownersExist,
  ]);

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <ExistingOwnersDialog error={loadingError} list={displayList} />,
  };

  const handleOnClick = () => {
    dialogContext.openDialog(dialogProps);
    loggerService.log({
      name: 'Existing Owners - user has viewed existing owners after quote is transacted',
    });
  };

  const linkButton = (
    <LinkButton
      addClass={classes.link}
      displayText={t('quote::view other owners in this tenant')}
      id="existing-owners-dialog-link"
      size="medium"
      onClick={handleOnClick}
    />
  );

  const showLink =
    !isInvitedUserOnlyOwner &&
    (ownersExist || (!ownersExist && loadingError === LoadingErrorType.ErrorLoadingEverything));

  if (loadingError === LoadingErrorType.ErrorLoadingEverything) {
    return (
      <ErrorMessage
        mainMessage={t('quote::Sorry, we failed to return other owners on the tenant')}
      />
    );
  } else {
    return showLink ? linkButton : null;
  }
};

export const ExistingOwnersLinkButton = withStyles(existingOwnersDialogStyles)(
  ExistingOwnersLinkButtonUnstyled
);
