import { ThemeProps } from 'styles';

export const borderlessSearchStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    borderless: {
      color: colors.textSecondary,
      backgroundColor: colors.backgroundPanel,
      borderStyle: 'none',
      height: 44,
      '& .ms-SearchBox-field::-webkit-input-placeholder': {
        color: colors.textSecondary,
      },
      '& .ms-SearchBox-field::placeholder': {
        color: colors.textSecondary,
      },
      '& .ms-SearchBox-icon': {
        paddingLeft: 10,
      },
      '& input': {
        paddingLeft: 12,
      },
      '& .ms-SearchBox-clearButton': {
        paddingRight: 13,
      },
      '& active': {
        backgroundColor: colors.backgroundStandout,
      },
      '& focus': {
        backgroundColor: colors.backgroundStandout,
      },
    },
    borderlessActive: {
      color: colors.text,
      backgroundColor: colors.backgroundStandout,
      borderStyle: 'none',
      height: 44,
      '& .ms-SearchBox-field::-webkit-input-placeholder': {
        fontStyle: theme.fonts.fontStyles.italic,
      },
      '& .ms-SearchBox-field::placeholder': {
        fontStyle: theme.fonts.fontStyles.italic,
      },
      '& .ms-SearchBox-icon': {
        paddingLeft: 10,
      },
      '& input': {
        paddingLeft: 12,
      },
      '& .ms-SearchBox-clearButton': {
        paddingRight: 13,
      },
    },
    root: {
      margin: 1,
    },
  };
};
