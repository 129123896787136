import {
  ErrorMessage,
  PrimaryButton,
  TeachingBubble,
  TextBody,
  TextTitleSecondary,
} from 'components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { WizardDialog } from '../Shared';
import { overviewStyles } from './OverviewDialog.styles';
import { OverviewList } from './OverviewList';
import { OverviewRowProps } from './OverviewRow';

export interface OverviewDialogProps {
  headline: string;
  enrollmentNumber?: string;
  errorText?: string;
  organizationsFirstColumnRows: OverviewRowProps[];
  organizationsSecondColumnRows: OverviewRowProps[];
  isEditFlow: boolean;
  isDemoTenant: boolean;
  isInfoDefaulted: boolean;
  isInfoOpen?: boolean;
  onInfoClose?: () => void;
  onClose: () => void;
  onSaveClick: () => void;
  onCreateClick: () => void;
}

type Props = OverviewDialogProps & WithStyles<typeof overviewStyles>;

const generateMessage = (
  classes: { color: string; fontWeight: string },
  partialDescription: string,
  isEditFlow: boolean,
  isInfoDefaulted: boolean,
  isDemoTenant: boolean,
  enrollmentNumber?: string
) => {
  const enrollmentText = (
    <Trans ns="quote">
      We've defaulted the billing account information from
      <span className={classes.fontWeight}> Enrollment {{ enrollmentNumber }}</span>.
    </Trans>
  );

  const salesAccountText = (
    <Trans ns="quote">
      We've defaulted the billing account information from the sales account.
    </Trans>
  );

  const demoTenantText = (
    <Trans ns="quote">
      The tenant provided is a demo tenant and does not support adding or editing billing accounts.
      Please close the Wizard and select "Create new billing account and account" to create a new
      billing account in a different tenant.
    </Trans>
  );

  let overviewMessage;
  if (isEditFlow || !isInfoDefaulted) {
    overviewMessage = null;
  } else if (isDemoTenant) {
    overviewMessage = demoTenantText;
  } else {
    overviewMessage = enrollmentNumber ? enrollmentText : salesAccountText;
  }

  return (
    <div>
      <TextBody addClass={classes.color} dataAutomationId="overviewMessage">
        {overviewMessage}
        {overviewMessage ? ' ' : ''}
        {partialDescription}
      </TextBody>
    </div>
  );
};

export const OverviewDialogUnstyled: React.FC<Props> = (props: Props) => {
  const { classes } = props;
  const { t } = useTranslation();

  const handleOnInfoClose = () => {
    props.onInfoClose && props.onInfoClose();
  };

  const message = generateMessage(
    {
      color: classes.enrollmentTextColor,
      fontWeight: classes.enrollmentTextFontWeight,
    },
    t(
      'quote::Please verify that the information is correct and update as appropriate. The customer will be able to update and approve this information on checkout.'
    ),
    props.isEditFlow,
    props.isInfoDefaulted,
    props.isDemoTenant,
    props.enrollmentNumber
  );
  const organizationDetailsHeader = (
    <span className={classes.organizationDetailsHeader}>
      <TextTitleSecondary>{t('quote::New billing account details')}</TextTitleSecondary>
    </span>
  );

  const body = (
    <div className={classes.topDialogContainer}>
      {message}
      <div className={classes.organizationDetailsContainer}>
        {organizationDetailsHeader}
        <div className={classes.organizationDetailsColumnsContainer}>
          <div className={classes.organizationsColumn1}>
            <OverviewList rowProps={props.organizationsFirstColumnRows} />
          </div>
          <div className={classes.separator} />
          <div>
            <OverviewList rowProps={props.organizationsSecondColumnRows} />
          </div>
        </div>
      </div>
      <div className={classes.error}>
        {props.errorText && <ErrorMessage mainMessage={props.errorText} />}
      </div>
      {props.isInfoOpen && (
        <TeachingBubble
          closeButtonAriaLabel={t('quote::Close')}
          headline={t('quote::Affiliate accounts')}
          maxWidth={324}
          target="#affiliate-info-button"
          onDismiss={handleOnInfoClose}
        >
          {/* TODO: update "MCAPI Account" when name decided on */}
          <TextBody dataAutomationId="affiliateAccountsText1">
            {t(
              'quote::This billing account cannot be an affiliate because there are no eligible parent accounts.'
            )}
          </TextBody>
          <TextBody dataAutomationId="affiliateAccountsText2">
            {t(
              'quote::An eligible parent account is a billing account which has been created within the same "MCAPI Account" as the affiliate, and the customer has already signed the Microsoft Customer Agreement (MCA).'
            )}
          </TextBody>
        </TeachingBubble>
      )}
    </div>
  );

  const footerButton = props.isEditFlow
    ? [
        <PrimaryButton
          dataAutomationId="saveBillingAccountButton"
          key="saveOrg"
          text={t('quote::Update billing account')}
          onClick={props.onSaveClick}
        />,
      ]
    : [
        <PrimaryButton
          dataAutomationId="createBillingAccountButton"
          disabled={props.isDemoTenant}
          key="createOrg"
          text={t('quote::Create billing account')}
          onClick={props.onCreateClick}
        />,
      ];

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      footer={footerButton}
      headline={props.headline}
      isOverviewAvailable={true}
      overviewButtonOnly={false}
      title={t('quote::New Billing Account')}
    />
  );
};

export const OverviewDialog = withStyles(overviewStyles)(OverviewDialogUnstyled);
