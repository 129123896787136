import { TileAtom } from 'components/atoms';
import {
  CloseIconButton,
  TextBody,
  TextBodySmall,
  TextNavigationSecondarySelected,
  TextTitleSecondary,
} from 'components/ions';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import withStyles, { WithStyles } from 'react-jss';

import { detailsTileStyles } from './Tiles.styles';

export interface DetailsTileTableRowProps {
  name: string;
  description: string;
}

export interface DetailsTileTableProps {
  firstColumnName: string;
  secondColumnName: string;
  tableRows: DetailsTileTableRowProps[];
}

export interface DetailsTileProps {
  addLeftSideContent?: React.ReactNode;
  imageUrl: string;
  imageDescription: string;
  title: string;
  description: string;
  publisher?: string;
  tags?: Record<string, string>;
  detailsTable?: DetailsTileTableProps;
  onClickClose?: () => void;
}

type Props = DetailsTileProps & WithStyles<typeof detailsTileStyles>;

export const DetailsTileUnstyled: React.FC<Props> = props => {
  const getTable = () => {
    if (props.detailsTable) {
      return (
        <table className={props.classes.table}>
          <thead>
            <tr className={props.classes.tableHeader}>
              <th className={props.classes.tableLeftColumn}>
                <TextBodySmall>{props.detailsTable.firstColumnName}</TextBodySmall>
              </th>
              <th className={props.classes.tableRightColumn}>
                <TextBodySmall>{props.detailsTable.secondColumnName}</TextBodySmall>
              </th>
            </tr>
          </thead>
          <tbody>
            {props.detailsTable.tableRows.map(row => {
              return (
                <tr className={props.classes.tableRow} key={row.name}>
                  <td className={props.classes.tableLeftColumn}>
                    <TextBody>{row.name}</TextBody>
                  </td>
                  <td className={props.classes.tableRightColumn}>
                    <div className={props.classes.tableDescription}>
                      {ReactHtmlParser(row.description)}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else return null;
  };

  const buildTags = (tags: Record<string, string>) => {
    let tagsArray: JSX.Element[] = Object.entries(tags).map(([key, value]) => {
      return (
        <div className={props.classes.tagsContainer} key={key}>
          <TextBodySmall addClass={props.classes.tags} dataAutomationId={`detailsTile${key}`}>
            {key}
          </TextBodySmall>
          <TextBodySmall addClass={props.classes.tags}>{value}</TextBodySmall>
        </div>
      );
    });
    return tagsArray;
  };

  return (
    <TileAtom addClass={props.classes.documentCard}>
      <div className={props.classes.leftSide}>
        <div className={props.classes.heading}>
          <img
            alt={props.imageDescription}
            className={props.classes.image}
            height={65}
            src={props.imageUrl}
            width={65}
          />
          <div title={props.title}>
            <TextTitleSecondary addClass={props.classes.title}>{props.title}</TextTitleSecondary>
          </div>
        </div>
        {props.publisher && (
          <TextNavigationSecondarySelected addClass={props.classes.publisher}>
            {props.publisher}
          </TextNavigationSecondarySelected>
        )}
        {props.tags && buildTags(props.tags)}
        {props.addLeftSideContent}
      </div>
      <div className={props.classes.spacer} />
      <div className={props.classes.rightSide}>
        <div className={props.classes.description}>{ReactHtmlParser(props.description)}</div>
        {getTable()}
      </div>
      <CloseIconButton addClass={props.classes.closeButton} onClick={props.onClickClose} />
    </TileAtom>
  );
};

export const DetailsTile = withStyles(detailsTileStyles)(DetailsTileUnstyled);
