import { ThemeProps } from 'styles';

export const signupEmailStyles = (theme: ThemeProps) => ({
  secondaryText: {
    color: theme.palette.textTertiary,
  },
  input: {
    paddingTop: 18,
  },
  messages: {
    marginTop: 12,
  },
});
