import { sharedStyles, ThemeProps } from 'styles';

export const similarOrgFoundDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  newline: {
    display: 'block',
  },
  emphasize: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  text: {
    color: theme.palette.textTertiary,
  },
  tileBackground: {
    background: theme.palette.secondaryHover,
  },
  tileContent: {
    marginRight: 24,
  },
  containerFlexTiles: {
    display: 'flex',
    paddingBottom: 16,
  },
  spaceFlex: {
    flex: 1,
  },
  separatorFlex: {
    flex: 1,
    borderLeft: '1px solid',
    borderColor: theme.palette.backgroundDivider,
  },
  tileRightHeadingOffset: {
    paddingLeft: 155,
  },
  itemsFlexTiles: {
    flex: 5,
    marginRight: 0,
    marginBottom: 0,
    minHeight: 120,
    width: 247,
  },
  spacerSmall: {
    height: 4,
  },
  spacerMedium: {
    height: 12,
  },
  spacerLarge: {
    height: 20,
  },
  tradeName: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 2,
  },
});
