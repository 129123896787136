import { VideoPlayer } from 'components/molecules';
import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { NavLink } from 'react-router-dom';
import { routes } from 'routes';

import { styles } from './Start.styles';

type Props = {} & WithTranslation & WithStyles<typeof styles>;

const Start: React.FC<Props> = ({ classes }: Props) => {
  const { t } = useTranslation();
  const renderNumberedHeader = (step: string, title: string) => {
    return (
      <div className="NumberedHeader">
        <div className={classes.numberBox}>
          <p className={classes.number}>{step}</p>
        </div>
        <h3 className={classes.header}>{title}</h3>
      </div>
    );
  };

  let gettingStartedVideo = (
    <VideoPlayer
      videoTitle={t('help::Getting Started')}
      videoUrl={
        'https://msit.microsoftstream.com/embed/video/80a02488-0589-4768-93cf-d20aa616978e?autoplay=false&amp;showinfo=true'
      }
    />
  );

  return (
    <div className={classes.scroll}>
      <div>
        {renderNumberedHeader('1', t('help::Before doing anything else, watch this.'))}
        <div className={classes.instructions}>{gettingStartedVideo}</div>
      </div>
      <div>
        {renderNumberedHeader('2', t('help::Read through the FAQ'))}
        <div className={classes.instructions}>
          <p className={classes.instructionContentTop}>
            {t(
              'help::After watching the getting started video, you should know what you need to start using the tools and creating quotes. At the same time, new, more detailed questions may have arisen.'
            )}
          </p>
          <p className={classes.instructionContent}>
            {t(
              'help::Skim through the FAQ and read the questions that directly apply to your situation.'
            )}
          </p>
          <NavLink className={classes.link} to={routes.home.help.faq}>
            {t('help::go to the FAQ')}
          </NavLink>
        </div>
      </div>
      <div>
        {renderNumberedHeader('3', t('help::Familiarize yourself with the video tutorials'))}
        <div className={classes.instructions}>
          <p className={classes.instructionContent}>
            {t(
              "help::Finally, before you start using the tools, see what video tutorials we have so you'll know what to come back and get more details on when questions arise."
            )}
          </p>
          <NavLink className={classes.link} to={routes.home.help.videos}>
            {t('help::go to videos')}
          </NavLink>
        </div>
      </div>
      <div>
        {renderNumberedHeader('4', t('help::Give us feedback'))}
        <div className={classes.instructions}>
          <p className={classes.instructionContent}>
            {t('help::On the right side of the title bar, the ')}
            <Icon className={classes.icon} iconName="Help" />
            {t(
              'help:: button has links to both support and user feedback. Obviously, if something breaks, please go straight to support. Otherwise, please let us know how the tool is working for you and give us suggestions for new features.'
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('help')(withStyles(styles)(Start));
