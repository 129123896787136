import * as React from 'react';

import { IPanelProps, IRenderFunction } from 'office-ui-fabric-react';

import { HelpContent } from '../../types';
import { panelStyles } from './HelpPanel.styles';
import { ActionButton, LinkExternal } from 'components/ions';
import { routes } from 'routes';
import { Article } from './HelpPanel.types';
import loggerService from 'services/logger-service';

export const configFiles: Partial<Record<HelpContent, string>> = {
  [HelpContent.Catalog]: 'Catalog.json',
  [HelpContent.Claims]: 'Claims Articles.json',
  [HelpContent.CustomerPropertySheet]: 'Customer Property Sheet.json',
  [HelpContent.CwaAgreements]: 'CWA - Agreements.json',
  [HelpContent.CwaSubscriptions]: 'CWA - Subscriptions.json',
  [HelpContent.Default]: 'My Quotes.json',
  [HelpContent.Help]: 'Help.json',
  [HelpContent.InfoPropertySheet]: 'Info Property Sheet.json',
  [HelpContent.MyQuotes]: 'My Quotes.json',
  [HelpContent.NeedsMyApproval]: 'Needs my approval.json',
  [HelpContent.QuoteEditorProducts]: 'Quote Editor Products.json',
  [HelpContent.QuoteEditorTerms]: 'Quote Editor Terms.json',
  [HelpContent.SalesPropertySheet]: 'Sales Property Sheet.json',
};

export interface PopoutProps {
  article?: Article | null;
  onPopout: () => void;
}

export const getNavigationContent = (
  onBack: () => void,
  classes: Record<keyof ReturnType<typeof panelStyles>, string>,
  backButtonText: string,
  popoutProps?: PopoutProps
) => {
  const onRenderNavigationContent: IRenderFunction<IPanelProps> = (props, defaultRender) => (
    <div className={classes.navigationContent}>
      <ActionButton
        iconName="Back"
        styles={{
          root: classes.backButtonRoot,
          label: classes.backButtonLabel,
          icon: classes.backButtonIcon,
        }}
        onClick={onBack}
      >
        {backButtonText}
      </ActionButton>
      <div className={classes.navRightButtons}>
        {popoutProps && (
          <LinkExternal
            addClass={classes.openNewWindowLink}
            displayText=" "
            href={`${routes.content.articlePopout}?article=${JSON.stringify(popoutProps.article)}`}
            onClick={popoutProps.onPopout}
          />
        )}
        {defaultRender && defaultRender(props)}
      </div>
    </div>
  );

  return onRenderNavigationContent;
};

export const getArticleGroupConfig = async (baseUrl: string, contentType: HelpContent) => {
  try {
    const response = await fetch(`${baseUrl}/articles/help/config/${configFiles[contentType]}`);

    try {
      return JSON.parse(await response.text());
    } catch {
      loggerService.log({
        name: `Unable to parse help panel config json for context ${contentType}`,
      });
    }
  } catch {
    loggerService.log({
      name: `Error downloading help panel config for context ${contentType}`,
    });
  }
};
