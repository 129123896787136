export interface ExternalUser {
  UPN: string;
  ObjectId?: string | null;
  TenantId?: string | null;
  TenantName?: string | null;
}

export interface VerifyUserRequest {
  email: string;
  tenantIds: string[];
}

export interface Tenant {
  tenantId: string;
  tenantType: TenantType;
}

export enum TenantType {
  managed = 'Managed',
  viral = 'Viral',
}

export interface TenantName {
  tenantId: string;
  tenantDisplayName: string;
}

export interface TenantAdminResponse {
  tenantId: string;
  upn: string[];
}

export interface GetTenantNames {
  tenantIds: string[];
}

export interface TenantUpnResponse {
  tenantId: string;
  upn: string[];
}

export interface GetTenantUpns {
  roleId: string;
  tenantId: string;
  objectId: string;
}

export interface RoleAssignedUpn {
  roleId: string;
  upn: string;
}
