import { ThemeProps } from 'styles';

export const highlightedTextStyles = (theme: ThemeProps) => {
  return {
    bold: {
      fontWeight: theme.fonts.fontWeights.semiBold,
      color: theme.palette.textLinkHover,
    },
  };
};
