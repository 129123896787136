import { AddOn } from 'features/proposal/components/AddOns';
import { getActiveProposal, getActiveProposalWithOc } from 'features/proposal/selectors/proposal';
import { getPricingAudienceClaim } from 'features/proposal/utils';
import { createSelector } from 'reselect';
import { AgreementType, NationalCloud } from 'services/catalog/api';
import {
  Audience,
  CatalogCallConfig,
  defaultCatalogConfig,
  FieldsTemplate,
  ProductKey,
} from 'services/catalog/config';
import { Availability, Product, ServiceFamily } from 'services/catalog/types';
import { StringifyProductKey } from 'services/catalog/utils';
import { ProductConstraintsResult } from 'services/constraints/types';
import { Proposal } from 'services/proposal/types';
import { Channel } from 'services/reco/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { createLoadingSelector, createProcessingSelectors } from '../app/selectors';
import { ProductType, RecoChannelProducts, SearchResultProduct } from './types';

const productKey = {
  getAgreementType: (proposal: Proposal) =>
    oc(proposal).header.extendedProperties.userPreferences.agreementType(
      defaultCatalogConfig.agreementType
    ) as AgreementType,
  getAudience: (proposal: Proposal) => getPricingAudienceClaim(proposal) as Audience,
  getNationalCloud: (proposal: Proposal) =>
    oc(proposal).header.extendedProperties.userPreferences.nationalCloud(
      defaultCatalogConfig.nationalCloud
    ) as NationalCloud,
  getMarket: (proposal: Proposal) => oc(proposal).header.pricingContext.market('US'),
  getLanguage: (proposal: Proposal) => oc(proposal).header.pricingContext.languages('en-US'),
};

export const getProductKey = createSelector(
  productKey.getAgreementType,
  productKey.getAudience,
  productKey.getNationalCloud,
  productKey.getMarket,
  productKey.getLanguage,
  (agreementType, audience, nationalCloud, market, languages) => {
    const newProductKey: ProductKey = {
      agreementType,
      audience,
      nationalCloud,
      market,
      languages,
      template: FieldsTemplate.Details,
    };
    return newProductKey;
  }
);

export const getCatalogConfig = createSelector(
  (state: RootState) => state.app.appConfig.catalog,
  (state: RootState) => getProductKey(getActiveProposal(state)),
  (config, key): CatalogCallConfig => ({ ...config, ...key })
);

export const getStringifiedProductKey = createSelector(getProductKey, StringifyProductKey);

export const getProductFragmentsOrdered = (state: RootState) =>
  oc(state)
    .catalog.products.fragments.ordered[getStringifiedProductKey(getActiveProposal(state))]([])
    .map(() => state.catalog.products.fragments.indexed);
export const getProductFragmentsIndexed = (state: RootState) =>
  oc(state).catalog.products.fragments.indexed[getStringifiedProductKey(getActiveProposal(state))](
    {}
  );
export const getProductEntitiesIndexed = (state: RootState) => {
  const x = oc(state).catalog.products.entities.indexed[
    getStringifiedProductKey(getActiveProposal(state))
  ]({});
  return x;
};

export const getProductFragment = (state: RootState, id: string) =>
  getProductFragmentsIndexed(state)[id];
export const getProduct = (state: RootState, id: string) => getProductEntitiesIndexed(state)[id];
export const getAddOnProductTitles = (state: RootState, productId: string) => {
  const product = getProduct(state, productId);
  let addOns: AddOn[] = [];
  addOns = oc(product)
    .DisplaySkuAvailabilities[0].Sku.Properties.ConstraintsData.PrerequisiteSkus.MustHaveAny([])
    .map(mha => {
      const addOnProduct = getProduct(state, mha.ProductId);
      const addOn: AddOn = {
        productId: mha.ProductId,
        productName: addOnProduct && addOnProduct.LocalizedProperties[0].ProductTitle,
      };
      return addOn;
    });
  return addOns;
};
export const isQuantityOwnershipConstraintEnabledOnProduct = (
  state: RootState,
  productId: string
) => {
  const product = getProduct(state, productId);
  const seatConstraints = oc(
    product
  ).DisplaySkuAvailabilities[0].Sku.Properties.ConstraintsData.SeatConstraints();
  if (seatConstraints) {
    return seatConstraints.some(s => s.Type && s.Type.toUpperCase() === 'QUANTITY');
  }
  return false;
};

export const getOwnershipConstraintsForProduct = (state: RootState, productId: string) =>
  state.catalog.validations.constraintsResults.productConstraintResults.find(
    (product: ProductConstraintsResult) => product.product.productId === productId
  );

export const getProductsFailed = (state: RootState) =>
  oc(state).catalog.products.failed[getStringifiedProductKey(getActiveProposal(state))]({});
export const getProductFailed = (state: RootState, id: string) =>
  oc(state).catalog.products.failed[getStringifiedProductKey(getActiveProposal(state))]({})[id];

export const getRecoKey = (state: RootState, productType: ProductType) => {
  const agreementType = getActiveProposalWithOc(
    state
  ).header.extendedProperties.userPreferences.agreementType(defaultCatalogConfig.agreementType);
  return `${agreementType.toString()}|${productType.toString()}`;
};

export const getRecoChannels = (
  state: RootState,
  productType: ProductType,
  recoKey: string = getRecoKey(state, productType)
): RecoChannelProducts[] => {
  const byType = state.catalog.recommendations.channelsByType[recoKey] || [];

  const channelByName = (name: string) => state.catalog.recommendations.byChannel[name];

  const recoChannelProductsForRecoChannel = (recoChannel: Channel): RecoChannelProducts => {
    const recoProducts: Product[] = recoChannel.Items.reduce((recoProducts: Product[], item) => {
      if (getProductFragment(state, item.Id)) {
        recoProducts.push(getProductFragment(state, item.Id));
      }
      return recoProducts;
    }, []);
    return {
      name: recoChannel.name,
      title: recoChannel.Title,
      products: recoProducts,
    };
  };

  const channels = byType.map(item => channelByName(item));

  return (
    channels &&
    channels.map((channel: Channel) => channel && recoChannelProductsForRecoChannel(channel))
  );
};

export const recoLoading = createLoadingSelector(['@@reco/LOAD']);
export const loadAndHydrateAllQuoteWithFavoritesSuccess = (state: RootState) =>
  state.app.loading['@@quote/loadAndHydrateAllQuoteWithFavorites/LOAD'] === false;

export const isFavoriteProductForLegacyDisabled = (
  state: RootState,
  product: SearchResultProduct
): boolean =>
  !state.catalog.filteredFavorites.products.some(
    (filteredFavorite: Product) => filteredFavorite.ProductId === product.productId
  );

export const getSearchResults = (state: RootState): SearchResultProduct[] =>
  state.catalog.search.results.products;

export const searchLoading = createLoadingSelector(['@@products/SEARCH']);
export const expandSearchProcessing = createProcessingSelectors(['@@products/EXPAND_SEARCH']);
export const termSearchLoading = createLoadingSelector(['@@terms/SEARCH']);

export const getNewAvailability = (
  state: RootState,
  identifier: { productId?: string; skuId?: string; availabilityId?: string }
) => {
  if (!identifier.productId || !identifier.skuId) {
    return;
  }
  const product = getProduct(state, identifier.productId);
  if (!product) {
    return;
  }
  const sku = product.DisplaySkuAvailabilities.find(dsa => dsa.Sku.SkuId === identifier.skuId);
  if (!sku) {
    return;
  }
  const availability = sku.Availabilities.find(
    availability => availability.AvailabilityId === identifier.availabilityId
  );

  return availability || sku.Availabilities[0];
};

export const getNewTermId = (availability: Availability, oldTermId?: string) => {
  if (!oldTermId || !availability.Terms || !availability.Terms.length) {
    return;
  }
  if (availability.Terms.some(term => term.TermId === oldTermId)) {
    return oldTermId;
  }
  return availability.Terms[0].TermId;
};

export const getCatalogProducts = (
  state: RootState,
  catalogKey: string,
  serviceFamily: ServiceFamily
) => {
  const products = oc(state).catalog.catalogPage.featured[catalogKey][serviceFamily]({
    products: {},
    resultsToSkip: 0,
    serviceFamily,
    hasMorePages: false,
  });
  return products;
};

export const getCatalogNegotiatedTerms = (state: RootState) => {
  return state.catalog.catalogPage.negotiatedTerms.Products;
};

export const getCatalogSearchProducts = (state: RootState) => {
  return state.catalog.catalogPage.searchResults;
};

export const catalogSearchLoading = createLoadingSelector(['@@catalog/SEARCH']);
