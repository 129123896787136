import { loadProducts } from 'features/catalog/sagas/auxiliary';
import * as actions from 'features/proposal/actions';
import * as catalogActions from 'features/catalog/actions';
import { loadUserPreferences } from 'features/user/sagas/auxiliary';
import {
  getUser,
  getUserFavoriteProductNames,
  getUserFavoriteProductsIds,
} from 'features/user/selectors';
import { User } from 'features/user/types';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import loggerService from 'services/logger-service';
import { Proposal } from 'services/proposal/types';
import { getType } from 'typesafe-actions';
import { isAgreementTypeLegacy } from 'features/proposal/utils';
import { ProductFamily } from 'services/catalog/types';

import { hydrateQuoteAll, hydrateQuoteLite, loadQuote } from './auxiliary';

export function* loadAndHydrateAllQuote() {
  const relevantAction = actions.loadAndHydrateAllQuote;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const quote: Proposal = yield call(loadQuote, action.payload);
      const user: User = yield select(getUser);
      const email = user.email;
      const productNames = yield select(getUserFavoriteProductNames);
      const productIds = yield select(getUserFavoriteProductsIds);

      if (email) {
        yield put(actions.loadAndHydrateAllQuoteWithFavorites.request());
        yield call(loadUserPreferences, email);
        yield call(hydrateQuoteAll, quote);
        if (isAgreementTypeLegacy(quote)) {
          const filterFavoriteProductsRequest = {
            productNames,
            productFamilyNames: [ProductFamily.Azure],
          };
          yield put(
            catalogActions.filterFavoriteProductsForLegacyAsync.request(
              filterFavoriteProductsRequest
            )
          );
        } else {
          yield call(loadProducts, productIds);
        }
      } else {
        yield call(hydrateQuoteAll, quote);
      }
      if (!isAgreementTypeLegacy(quote)) {
        yield put(actions.loadAndHydrateAllQuoteWithFavorites.success());
      }
    } catch (err) {
      loggerService.error(err);
    }
  });
}

export function* loadAndHydrateLiteQuote() {
  const relevantAction = actions.loadAndHydrateLiteQuote;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const quote: Proposal = yield call(loadQuote, action.payload);
      yield call(hydrateQuoteLite, quote);
    } catch (err) {
      loggerService.error(err);
    }
  });
}
