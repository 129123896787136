import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
} from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext, DialogProps } from 'styles';

import { DialogLayout } from '../Shared';
import { removeCrmIdDialogStyles } from './RemoveCrmIdDialog.styles';

export interface RemoveCrmIdDialogProps {
  onRemove: () => void;
}

type Props = WithStyles<typeof removeCrmIdDialogStyles> & RemoveCrmIdDialogProps;

export const RemoveCrmIdDialogUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { classes, onRemove } = props;
  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };
  const handleRemoveCrmId = () => {
    onRemove();
    closeDialog();
  };
  const warningLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        dataAutomationId="deleteQuote"
        dialogClose={closeDialog}
        headerClass={classes.customDialogHeader}
      >
        <TextTitle>{t('quote::Remove the CRM ID?')}</TextTitle>
      </DialogHeader>
    ),
    body: (
      <div className={classes.customDialogBody}>
        <TextBody>
          {t(
            'quote::The billing account will be removed from the quote which may modify or invalidate some line items.'
          )}
        </TextBody>
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <PrimaryButton
          dataAutomationId="removeCrmIdConfirmButton"
          text={t('quote::Remove CRM ID')}
          onClick={handleRemoveCrmId}
        />
        <SecondaryButton
          dataAutomationId="removeCrmIdCancelButton"
          text={t('quote::Cancel')}
          onClick={closeDialog}
        />
      </div>
    ),
  };
  return (
    <div>
      <CustomDialogBox
        bodySlot={warningLayout.body}
        footerSlot={warningLayout.footer}
        headerSlot={warningLayout.header}
        height={203}
        width={366}
      />
    </div>
  );
};
export const RemoveCrmIdDialog = withStyles(removeCrmIdDialogStyles)(RemoveCrmIdDialogUnstyled);

export const openRemoveCrmDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  onRemove: () => void
) => {
  const dialogProps: DialogProps = {
    providedDialog: <RemoveCrmIdDialog onRemove={onRemove} />,
  };
  context.openDialog(dialogProps);
};
