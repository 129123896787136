import { ButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { borderlessButtonStyles } from './BorderlessButton.styles';

type Props = ButtonSharedProps & WithStyles<typeof borderlessButtonStyles>;
const BorderlessButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <ButtonAtom
      addClass={mergeClassNames([props.classes.borderless, props.addClass])}
      ariaLabel={props.ariaLabel}
      disabled={props.disabled}
      iconProps={props.iconName ? { iconName: props.iconName } : undefined}
      id={props.id}
      styles={props.styles}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const BorderlessButton = withStyles(borderlessButtonStyles)(
  BorderlessButtonUnstyled
) as React.FC<ButtonSharedProps>;
