import { ThemeProps } from 'styles';

export const conditionsListStyles = (theme: ThemeProps) => {
  return {
    safe: {
      color: theme.palette.approveText,
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    danger: {
      color: theme.palette.dangerText,
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    columnSpacing: {
      borderSpacing: '12px 1px',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    column: {
      flex: 1,
    },
    rightAlign: {
      textAlign: 'right',
      marginRight: 10,
      '& span': {
        whiteSpace: 'nowrap',
      },
    },
    row: {
      display: 'flex',
    },
  };
};
