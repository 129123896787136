import { SkipToMain } from 'components/ions';
import { meplaHistory } from 'createHistory';
import { MainLayout } from 'layouts';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

import { NavBar } from './NavBar';
import { PageContent } from './PageContent';
import { useStartDialogs } from './useStartDialogs';

const getHeaderSlot = () => {
  return (
    <Switch>
      <Route component={NavBar} path={routes.home.root} />
      <Route component={NavBar} path={routes.catalog.root} />
      <Route component={NavBar} path={routes.quote.root} />
      <Route component={NavBar} path={routes.customer.root} />
      <Route component={NavBar} path={routes.opportunity.root} />
      <Route component={NavBar} path={routes.engagement.root} />
      <Route component={NavBar} path={routes.crmId.root} />
      <Route component={NavBar} exact path={routes.notFound} />
    </Switch>
  );
};

export const AppContent: React.FC = () => {
  const { t } = useTranslation();

  useStartDialogs();

  useHotkeys('g+h', () => {
    meplaHistory.push(routes.home.quotes.root);
  });

  const currentPageNoArticlePopout =
    meplaHistory.location.pathname !== routes.content.articlePopout;

  const skipToMain = currentPageNoArticlePopout ? (
    <SkipToMain displayText={t('Skip to main content')} href="#PrimaryArea" id="SkipToMain" />
  ) : null;

  return (
    <MainLayout
      bodySlot={<PageContent />}
      headerSlot={getHeaderSlot()}
      skipToMainSlot={skipToMain}
    />
  );
};
