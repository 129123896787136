import {
  PropertySheetContent,
  PropertySheetLayout,
} from 'features-apollo/quote/components/PropertySheets/Layout';
import { TenantScenario } from 'features-apollo/quote/components/Wizards';
import {
  Audience,
  CustomerType,
  InvitedUserSimple,
  OrganizationSimple,
  QuoteMutationInput,
} from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { CustomerLoading, NoCrmLead, OrganizationInformation } from '../../states';
import { SelectCustomerForIndirect } from './SelectCustomerForIndirect';
import { SelectPartnerForIndirect } from './SelectPartnerForIndirect';

export const customerForIndirectStyles = {
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25,
  },
};

export interface CustomerForIndirectProps {
  quoteMutationInput: QuoteMutationInput;
  audience: Audience;
  loading: boolean;
  readOnly: boolean;
  crmID?: string;
  partner: { organization?: OrganizationSimple; invitedUser?: InvitedUserSimple | null };
  customer: { organization?: OrganizationSimple; invitedUser?: InvitedUserSimple | null };
  salesAccountTPID?: string;
  /**
   * Whether or not customer's phone number and email can be updated.
   */
  customerContactEditable: boolean;
  onRemove: (customerType: CustomerType) => void;
}

type Props = CustomerForIndirectProps & WithStyles<typeof customerForIndirectStyles>;

export const CustomerForIndirectUnstyled: React.FC<Props> = props => {
  const { quoteMutationInput } = props;
  const { t } = useTranslation();
  let partnerContent;
  let customerContent;

  const loadingState = <CustomerLoading />;

  if (props.loading) {
    partnerContent = loadingState;
    customerContent = loadingState;
  } else {
    partnerContent = props.partner.organization ? (
      <OrganizationInformation
        contactInformationEditable={false}
        customLabels={{ remove: t('quote::Remove partner') }}
        disableEdit
        invitedUser={props.partner.invitedUser}
        menuButtonAriaLabel={t(`quote::Opens menu options for partner's billing account`)}
        menuOptions={['remove']}
        organization={props.partner.organization}
        quoteMutationInput={quoteMutationInput}
        readOnly={props.readOnly}
        specificScenario={TenantScenario.partner}
        onRemove={() => props.onRemove(CustomerType.SoldToCustomer)}
      />
    ) : (
      <SelectPartnerForIndirect disabled={props.readOnly} quoteAudience={props.audience} />
    );

    customerContent = props.customer.organization ? (
      <OrganizationInformation
        contactInformationEditable={props.customerContactEditable}
        customLabels={{ remove: t('quote::Remove customer') }}
        invitedUser={props.customer.invitedUser}
        menuButtonAriaLabel={t(`quote::Opens menu options for customer's billing account`)}
        menuOptions={['remove', 'edit', 'add']}
        organization={props.customer.organization}
        quoteMutationInput={quoteMutationInput}
        readOnly={props.readOnly}
        salesAccountTPID={props.salesAccountTPID}
        specificScenario={TenantScenario.partnerCustomer}
        onRemove={() => props.onRemove(CustomerType.EndCustomer)}
      />
    ) : !props.crmID ? (
      <NoCrmLead quoteId={quoteMutationInput.id} readOnly={props.readOnly} />
    ) : (
      <SelectCustomerForIndirect crmId={props.crmID} disabled={props.readOnly} />
    );
  }

  const partnerSection: PropertySheetContent | undefined = partnerContent && {
    leftPaneTitle: t('quote::Partner'),
    content: partnerContent,
  };
  const customerSection: PropertySheetContent = {
    leftPaneTitle: t('quote::Customer'),
    content: customerContent,
  };

  return <PropertySheetLayout contentList={[partnerSection, customerSection]} />;
};

export const CustomerForIndirect = withStyles(customerForIndirectStyles)(
  CustomerForIndirectUnstyled
);
