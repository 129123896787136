import { VideoPlayer, VideoPlayerProps } from 'components/molecules';
import React from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './Videos.styles';
import * as VideosContent from './VideosContent';

interface IVideoPlayerContent extends VideoPlayerProps {
  id: string;
  image?: string;
}

type Props = {} & WithTranslation & WithStyles<typeof styles>;

const Videos: React.FC<Props> = props => {
  const { t } = useTranslation();
  const videoPlayerItems = VideosContent.Videos(t).map((video: IVideoPlayerContent) => {
    let videoPlayer = (
      <VideoPlayer
        key={video.id}
        videoDescription={video.videoDescription}
        videoTitle={video.videoTitle}
        videoUrl={video.videoUrl}
      />
    );

    return videoPlayer;
  });
  return <div className={props.classes.videos}>{videoPlayerItems}</div>;
};

export default withTranslation('help')(withStyles(styles)(Videos));
