import { getMarketByCountryCode } from 'features/proposal/supported-markets';
import { LegacyFootprint } from 'features/proposal/types';
import { forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import services from 'services';
import { OrganizationSummary, RecipientSummary } from 'services/customer/types';
import { PageResponse } from 'services/types';
import { NeedsTranslation, t } from 'services/utils';
import { RootEpic, RootState } from 'store/types';
import { ActionType, isActionOf } from 'typesafe-actions';

import * as actions from '../actions';

export const mapAccountToProject: RootEpic<ActionType<typeof actions>> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.mapAccountToProjectAsync.request)),
    map(action => {
      if (action.payload[0]) {
        return actions.mapAccountToProjectAsync.success(
          { id: action.payload[0].accountId, value: action.payload[0].id },
          action.meta
        );
      }
      return actions.mapAccountToProjectAsync.failure({
        message: t('error::Enrollment is not associated with any projects'),
        exception: new Error(),
      });
    })
  );

const mapLegacyFootprint = (
  legacyApiResponse: {
    organizations: PageResponse<OrganizationSummary>;
    recipients: PageResponse<RecipientSummary>;
    billingGroup: string | null;
  },

  enrollmentNumber: string,
  accountId: string,
  projectId: string
): LegacyFootprint | undefined => {
  const organizations = legacyApiResponse.organizations.value;
  const recipients = legacyApiResponse.recipients.value;
  const billingGroupId = legacyApiResponse.billingGroup;

  if (billingGroupId && organizations.length) {
    const organization = organizations[0];
    const recipient = recipients.find(recipient => recipient.description === enrollmentNumber);

    if (recipient) {
      const footprint: LegacyFootprint = {
        accountId: accountId,
        billingGroupId: billingGroupId,
        organizationId: organization.id,
        projectId: projectId,
        recipientId: recipient.id,
        enrollment: enrollmentNumber,
      };
      return footprint;
    }
  }
};

const getLegacyApis = (accountId: string, state: RootState, api: typeof services.api) => {
  return {
    organizations: api.customer.getOrganizations(
      { id: accountId },
      state.app.appConfig.customer
    ),
    recipients: api.customer.getRecipients({ id: accountId }, state.app.appConfig.customer),
    billingGroup: api.billingGroup.getBillingGroupId(
      accountId,
      state.app.appConfig.billinggroup
    ),
  };
};

const loadLegacyFootprintDispatchFailure = (message: NeedsTranslation, err = new Error()) =>
  of(
    actions.loadLegacyFootprintAsync.failure({
      message: message,
      exception: err,
    })
  );

const getOrganizationAndDispatch = (
  legacyFootprint: LegacyFootprint,
  state: RootState,
  api: typeof services.api
) => {
  return from(
    api.customer.getOrganization(
      { accountId: legacyFootprint.accountId, id: legacyFootprint.organizationId },
      state.app.appConfig.customer
    )
  ).pipe(
    switchMap(organizationWithAddress => {
      const market = getMarketByCountryCode(organizationWithAddress.legalEntity.address.country);
      if (market) {
        return [
          actions.saveOrganizationOnEnrollmentLookup({
            id: organizationWithAddress.id,
            value: organizationWithAddress,
          }),
          actions.loadLegacyFootprintAsync.success(legacyFootprint),
        ];
      } else {
        return [
          actions.loadLegacyFootprintAsync.failure({
            message: t('error::Market not supported'),
            exception: new Error(),
          }),
        ];
      }
    }),
    catchError(err =>
      of(
        actions.loadLegacyFootprintAsync.failure({
          message: t('error::Error loading customer address for legacy'),
          exception: err,
        })
      )
    )
  );
};

export const projectToLegacyFootprint: RootEpic<ActionType<typeof actions>> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.mapAccountToProjectAsync.success)),
    map(action => actions.loadLegacyFootprintAsync.request(action.payload, action.meta))
  );

export const loadLegacyFootprint: RootEpic<ActionType<typeof actions>> = (
  action$,
  state$,
  { api }
) =>
  action$.pipe(
    filter(isActionOf(actions.loadLegacyFootprintAsync.request)),
    switchMap(action =>
      forkJoin(getLegacyApis(action.payload.id, state$.value, api)).pipe(
        map(legacyApiResponse =>
          mapLegacyFootprint(
            legacyApiResponse,
            action.meta,
            action.payload.id,
            action.payload.value
          )
        ),
        switchMap(legacyFootprint => {
          if (legacyFootprint) {
            return getOrganizationAndDispatch(legacyFootprint, state$.value, api);
          } else {
            return loadLegacyFootprintDispatchFailure(
              t(
                'error::Cannot find legacy footprint. Enrollment number is associated with invalid customer data.'
              )
            );
          }
        }),
        catchError(err =>
          loadLegacyFootprintDispatchFailure(
            t('error::Error calling one or more apis to get legacy footprint.'),
            err
          )
        )
      )
    )
  );
