import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { getTermString } from 'features-apollo/quote/components/ConfigCards/Cards/MetersConfigurationCard/utils';
import { LocationOptions } from 'features-apollo/quote/components/ConfigCards/Cards/types';
import { RuleType } from 'features/app/config/type';
import { isFutureOption } from 'features/proposal/components/DiscountCard/DiscountCardBusinessLogic';
import {
  isDiscountable,
  showDiscountLink,
} from 'features/proposal/components/List/ProductList/ProductListRow';
import { Market } from 'features/proposal/supported-markets';
import { SelectedRowItem, SkuTitleMap } from 'features/proposal/types';
import {
  computeFirstAndLastTimezone,
  displayInDayFormatAndUseMarketTimezone,
  formatCurrency,
} from 'features/proposal/utils';
import { CatalogAction, Condition } from 'generated/graphql';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { LocalizedProperty, Product } from 'services/catalog/types';
import loggerService from 'services/logger-service';
import { CustomPrice } from 'services/pricingscope/types';
import { LineItem, PricingInstructionCondition } from 'services/proposal/types';
import { oc } from 'ts-optchain';

import { buildDurationString } from '../MonetaryCard/utils';

export const formatDate = (date?: Date) => {
  if (date) {
    return moment(date).format('MM-DD-YYYY');
  }
};

export const getMeterName = (lineItem: LineItem) => {
  const conditions = oc(lineItem).pricingInstruction.conditions([]);
  const meter = conditions.find(
    (condition: PricingInstructionCondition) => condition.name === 'MeterType'
  );
  if (meter) {
    return meter.value;
  }
};

export const isProductFinanceTerm = (product?: Product) => {
  return (
    oc(product)
      .ProductType('')
      .toLocaleLowerCase() === 'financing'
  );
};

export const getVersionString = (lineItem: LineItem) => {
  const version =
    lineItem.supplementalTermReferenceData && lineItem.supplementalTermReferenceData.split(':');

  return version && version.length >= 2 ? version[1] : '-';
};

export const getTermDuration = (lineItem: LineItem, t: i18next.TFunction) => {
  if (lineItem.duration) {
    const duration = moment.duration(lineItem.duration).humanize();
    return duration.toLowerCase() === t('quote::a year')
      ? t('quote::In 1 year')
      : t(`quote::In {{duration}}`, { duration });
  }

  return '-';
};

const getDurationString = (lineItem: LineItem, t: i18next.TFunction, product?: Product) => {
  return oc(product).ProductType() === 'CommitmentToConsume'
    ? getTermDuration(lineItem, t)
    : oc(product)
        .Properties.Duration('')
        .toString();
};

// Reflects startCondition keys used by PCD
export const startConditionConstants = {
  onOrderAcceptance: 'on order acceptance',
  firstOfThisMonth: 'first_of_this_month',
};

export const getStartConditionString = (t: i18next.TFunction, startCondition?: string) => {
  if (startCondition && startCondition.toLowerCase() === startConditionConstants.firstOfThisMonth) {
    return t('quote::First of this month');
  } else {
    return t('quote::At order placement');
  }
};

export const getStartCondition = (
  isFinanceTerm: boolean,
  t: i18next.TFunction,
  lineItem: LineItem,
  product?: Product,
  financeProductProps?: LocalizedProperty
) => {
  if (isFinanceTerm) {
    return oc(financeProductProps).ProductDescription('-');
  } else {
    return lineItem.startDate
      ? convertDateToFormattedString(lineItem.startDate, LocaleDateFormat.ll)
      : getStartConditionString(t, oc(product).Properties.StartCondition());
  }
};

export const getEndCondition = (
  lineItem: LineItem,
  isFinanceTerm: boolean,
  isSAPTerm: boolean,
  t: i18next.TFunction,
  product?: Product
) => {
  const sapValue =
    lineItem.duration && !lineItem.endDate
      ? buildDurationString(lineItem.duration, t('quote::year(s)'), t('quote::month(s)'))
      : (lineItem.endDate && convertDateToFormattedString(lineItem.endDate, LocaleDateFormat.ll)) ||
        '';
  const value = isSAPTerm ? sapValue : getDurationString(lineItem, t, product);
  return {
    name: isFinanceTerm ? t('quote::TermId') : t('quote::End'),
    value: isFinanceTerm ? getVersionString(lineItem) : value,
    status: '',
    dataAutomationId: 'End',
  };
};

const computeDates = (
  market: Market,
  startDate: string,
  endDate: string,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  priceGuaranteeDate?: string
) => {
  const timezones = computeFirstAndLastTimezone(
    market,
    shouldDisplayInDayFormatAndUseMarketTimezone
  );
  const newStartDate = moment.tz(startDate, timezones.first);
  const newEndDate = moment.tz(endDate, timezones.last);
  const newPricingGuaranteeDate =
    priceGuaranteeDate && moment.tz(priceGuaranteeDate, timezones.first);
  return { start: newStartDate, end: newEndDate, priceGuarantee: newPricingGuaranteeDate };
};

export const getDiscounts = (
  t: i18next.TFunction,
  skuTitles: SkuTitleMap,
  market: Market,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  product?: Product,
  existingDiscounts?: CustomPrice[]
) => {
  const result =
    existingDiscounts && product
      ? existingDiscounts.map((discount: CustomPrice) => {
          const action = discount.pricingInstructions.action;

          const { start, end, priceGuarantee } = computeDates(
            market,
            discount.startDateTime,
            discount.endDateTime,
            shouldDisplayInDayFormatAndUseMarketTimezone,
            discount.pricingInstructions.priceGuaranteeDate
          );
          let discountTitle = product.LocalizedProperties[0].ProductTitle;
          if (discount.pricingInstructions.sku) {
            const matchedSku =
              skuTitles[product.ProductId] &&
              skuTitles[product.ProductId].find(
                item => item.sku === discount.pricingInstructions.sku
              );
            if (matchedSku) {
              discountTitle = matchedSku.title;
            }
          }
          const showDays = displayInDayFormatAndUseMarketTimezone(product.ProductType, action);
          const startDate = showDays ? start.format('YYYY-MM-DD') : start.format('MMM. YYYY');
          const endDate = showDays ? end.format('YYYY-MM-DD') : end.format('MMM. YYYY');

          let discountType = '';
          if (
            discount.pricingInstructions.ruleType &&
            discount.pricingInstructions.ruleType.toLowerCase() ===
              RuleType.priceGuarantee.toLowerCase()
          ) {
            discountType = RuleType.priceGuarantee;
          } else if (discount.endDateTime) {
            discountType = RuleType.future;
          } else {
            loggerService.error({
              error: new Error(
                `discountType is neither price guarantee nor future for product:${product.ProductId}`
              ),
            });
          }

          return {
            productSku: discountTitle,
            dates: t('quote::{{date1}} - {{date2}}', {
              date1: startDate,
              date2: endDate,
            }),
            discountPercent: (discount.pricingInstructions.discountPercentage * 100).toFixed(2),
            discountType,
            priceGuaranteeDate: priceGuarantee ? priceGuarantee.format('YYYY-MM-DD') : '',
            meter:
              discount.pricingInstructions.conditions &&
              discount.pricingInstructions.conditions[0].name === 'MeterType'
                ? discount.pricingInstructions.conditions[0].value[0]
                : undefined,
          };
        })
      : undefined;
  return result;
};

export const getApplicableValueCondition = (
  lineItem: LineItem,
  t: i18next.TFunction,
  currency?: string
) => {
  const termName = oc(lineItem)
    .productIdentifier.productType('')
    .toLowerCase();
  const result = {
    name: '',
    value: '-',
    dataAutomationId: '',
  };
  switch (termName) {
    case 'ecif' || 'custom': {
      result.name = t('quote::TermId');
      result.value = getVersionString(lineItem);
      result.dataAutomationId = 'TermId';
      break;
    }
    case 'commitmenttoconsume': {
      const value = formatCurrency(oc(lineItem).purchaseInstruction.purchaseTermUnits('-'), 0);
      result.name = t('quote::Amount');
      result.value = (value && currency && `${value} ${currency}`) || '-';
      result.dataAutomationId = 'Amount';
      break;
    }
    default: {
      result.name = t('quote::Version');
      result.value = getVersionString(lineItem);
      result.dataAutomationId = 'Version';
      break;
    }
  }
  return result;
};

export const getProductNamesWithCount = (productTitles: string[]) => {
  const uniqueNames = [...new Set(productTitles)];
  return uniqueNames.map(name => {
    const count = productTitles.filter(title => title === name).length;

    return (name = count > 1 ? `${name} (${count})` : name);
  });
};

export interface MultiSelectDiscountInfo {
  discountableProducts: SelectedRowItem[];
  otherProducts: SelectedRowItem[];
  discountableProductsText: string[];
  otherProductsDisplay: string[];
}

export const isFutureDiscountApplicable = (product: Product, lineItem: LineItem) => {
  return showDiscountLink(product) && isFutureOption(product, lineItem);
};

export const getDiscountableProductInfo = (
  selectedItems: SelectedRowItem[]
): MultiSelectDiscountInfo => {
  const discountableProducts: SelectedRowItem[] = [];
  const otherProducts: SelectedRowItem[] = [];

  selectedItems.forEach(item => {
    if (
      item.product &&
      item.lineItem &&
      isDiscountable(
        item.product.ProductFamily,
        item.product.ProductType,
        item.product,
        item.lineItem
      ) &&
      isFutureDiscountApplicable(item.product, item.lineItem)
    ) {
      discountableProducts.push(item);
    } else {
      otherProducts.push(item);
    }
  });
  return {
    discountableProducts,
    otherProducts,
    discountableProductsText: getProductNamesWithCount(
      discountableProducts.map(product => product.productTitle)
    ),
    otherProductsDisplay: getProductNamesWithCount(
      otherProducts.map(product => product.productTitle)
    ),
  };
};

export const getTermStringForDetailsPane = (action: CatalogAction, conditions: Condition[]) => {
  const termDescriptionCondition = conditions.find(
    condition => condition.name === 'TermDescription' && condition.type === 'equalAny'
  );
  return getTermString(action, termDescriptionCondition);
};

export const getLocationInfoForDetailsPane = (locations: string[], singleSku: boolean) => {
  if (singleSku) {
    return [];
  }
  if (locations.length) {
    return locations;
  }
  return [LocationOptions.AllLocations];
};
