import { Processing } from 'features/app/selectors';
import { getMarketByCountryCode } from 'features/proposal/supported-markets';
import {
  domainNameFormatRegExp,
  domainTestEnvironmentIncludes,
  microsoftDomainNameRegExp,
  validGuid,
} from 'features/proposal/utils';
import i18next from 'i18n';
import { OrganizationState, OrganizationSummary } from 'services/customer/types';

export const invalidTenantFormatMessage = () =>
  i18next.t('quote::Enter a valid tenant domain or id.');

export const invalidTestTenantFormatMessage = () =>
  i18next.t('quote::Test environments require that the domain include "testtest" in the domain.');

export const onValidateTenant = (value: string, usingTestHeaders?: boolean) =>
  ((domainNameFormatRegExp.test(value) && !microsoftDomainNameRegExp.test(value)) ||
    validGuid.test(value)) &&
  (!usingTestHeaders ||
    (!validGuid.test(value) &&
      usingTestHeaders &&
      value.toLowerCase().includes(domainTestEnvironmentIncludes)));

export const getSelectableOrganizations = (
  eligibleParentAccounts: OrganizationSummary[],
  processingAddresses: Processing
) => {
  // Error messages
  const unsupportedCountryMessage = i18next.t('quote::Unsupported country');
  const organizationAddressFailedMessage = i18next.t(
    'quote::Unable to get billing account details'
  );
  const organizationLockedMessage = i18next.t('quote::Locked billing account');

  const selectableOrganizations =
    !!eligibleParentAccounts.length &&
    eligibleParentAccounts.map(organization => {
      let errorMessage: string | undefined;

      /**
       * Address
       * defined : Organization address was found in state
       * undefined : Organization address is null in state but loading address still in process
       * null : The process of loading addresses ended and unable to find organization address (address still null in state)
       */
      const addressFromState = organization.legalEntity.address;
      const address = addressFromState || (processingAddresses.loading ? undefined : null);

      if (address) {
        const market = getMarketByCountryCode(address.country); // Organization countries are country code only

        if (!market) {
          errorMessage = unsupportedCountryMessage;
        } else if (organization.state === OrganizationState.locked) {
          errorMessage = organizationLockedMessage;
        }
      } else if (address === null) {
        errorMessage = organizationAddressFailedMessage;
      }

      return {
        address,
        errorMessage,
        id: organization.id,
        name: (address && address.companyName) || undefined,
        doingBusinessAs: organization.legalEntity.tradeName,
      };
    });
  return selectableOrganizations;
};
