/* eslint-disable @typescript-eslint/camelcase */
import { Address } from 'features/customer/types';
import {
  getMarketByCountryCode,
  getMarketByCountryName,
  getMarketForCountry,
  isModernMarket,
  Market,
  ModernMarket,
} from 'features/proposal/supported-markets';
import {
  MarketWithRegions,
  noRegion,
  optionalRegion,
  RegionDisplayType,
  RegionField,
  regions,
  requiredProvince,
  requiredRegion,
  requiredState,
} from 'features/proposal/supported-regions';
import { Address as ApolloAddress } from 'generated/graphql';
import { OrganizationAddress as CreateOrganizationAddress } from 'services/account-extensions/types';
import { Address as ValidationAddress } from 'services/address-validation/types';
import { Address as UpdateOrganizationAddress } from 'services/customer/types';
import { oc } from 'ts-optchain';

import { shallowCompare } from '@uifabric/utilities';

export enum FieldType {
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  AddressLine3 = 'addressLine3',
  City = 'city',
  Region = 'region',
  PostalCode = 'postalCode',
  Country = 'country',
}

export interface AddressConfiguration {
  fieldGroup: FieldType[];
  region: RegionField;
}

const fieldGroupCityPostal = [FieldType.City, FieldType.PostalCode];
const fieldGroupCityPostalRegion = [FieldType.City, FieldType.PostalCode, FieldType.Region];
const fieldGroupCityRegionPostal = [FieldType.City, FieldType.Region, FieldType.PostalCode];
const fieldGroupPostalCity = [FieldType.PostalCode, FieldType.City];
const fieldGroupPostalCityRegion = [FieldType.PostalCode, FieldType.City, FieldType.Region];
const fieldGroupPostalRegionCity = [FieldType.PostalCode, FieldType.Region, FieldType.City];

const addressConfigs: Record<ModernMarket, AddressConfiguration> = {
  AR: { region: noRegion, fieldGroup: fieldGroupPostalCity }, //Argentina
  AT: { region: optionalRegion, fieldGroup: fieldGroupPostalRegionCity }, //Austria
  AU: { region: requiredProvince, fieldGroup: fieldGroupCityRegionPostal }, //Australia
  BE: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Belgium
  CA: { region: requiredProvince, fieldGroup: fieldGroupCityRegionPostal }, //Canada
  CH: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Switzerland
  CL: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Chile
  DE: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Germany
  DK: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Denmark
  ES: { region: requiredProvince, fieldGroup: fieldGroupCityRegionPostal }, //Spain
  FI: { region: noRegion, fieldGroup: fieldGroupPostalCity }, //Finland
  FR: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //France
  GB: { region: optionalRegion, fieldGroup: fieldGroupCityRegionPostal }, //United Kingdom
  GR: { region: noRegion, fieldGroup: fieldGroupPostalCity }, //Greece
  IE: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Ireland
  IS: { region: noRegion, fieldGroup: fieldGroupPostalCity }, //Iceland
  IT: { region: requiredProvince, fieldGroup: fieldGroupCityRegionPostal }, //Italy
  LI: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Liechtenstein
  LU: { region: noRegion, fieldGroup: fieldGroupPostalCity }, //Luxembourg
  NL: { region: requiredProvince, fieldGroup: fieldGroupCityRegionPostal }, //Netherlands
  NO: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Norway
  PR: { region: noRegion, fieldGroup: fieldGroupCityPostal }, //Puerto Rico
  PT: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Portugal
  SE: { region: noRegion, fieldGroup: fieldGroupPostalCity }, //Sweden
  TT: { region: requiredRegion, fieldGroup: fieldGroupCityPostalRegion }, //Trinidad and Tobago
  US: { region: requiredState, fieldGroup: fieldGroupCityRegionPostal }, //United States
  UY: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //Uruguay
  ZA: { region: optionalRegion, fieldGroup: fieldGroupPostalCityRegion }, //South Africa
};

const defaultConfig: AddressConfiguration = {
  region: requiredState,
  fieldGroup: fieldGroupCityRegionPostal,
};

export const getAddressConfigByMarket = (market: Market | undefined): AddressConfiguration => {
  let config = defaultConfig;

  if (market && isModernMarket(market)) {
    config = addressConfigs[market];
  }

  return config;
};

export const getAddressConfiguration = (country: string): AddressConfiguration => {
  const market = getMarketForCountry(country);
  return getAddressConfigByMarket(market);
};

export const isPuertoRico = (country: string) => {
  const market = getMarketForCountry(country);

  return !!market && market === 'PR';
};

export const getNewAddressForCountry = (
  currentAddress: Address,
  country: ModernMarket
): Address => {
  let nextAddress = {
    ...currentAddress,
    country,
  };

  if (isPuertoRico(country)) {
    nextAddress.region = 'PR';
  } else {
    nextAddress.region = '';
  }

  return nextAddress;
};

export const addressHasChanges = (current: Address, next: Address): boolean => {
  return !shallowCompare(current, next);
};

export const formatRegion = (address: Address): string | undefined => {
  if (!address.region) {
    return address.region;
  }

  const market = getMarketForCountry(address.country);
  if (market && regions.hasOwnProperty(market)) {
    const marketWithRegion = regions[market as MarketWithRegions];
    const region = address.region.toUpperCase();

    if (marketWithRegion[region]) {
      return region;
    }
  }

  return address.region;
};

export const formatRegionApollo = (address: ApolloAddress): string | undefined | null => {
  if (!address.region) {
    return address.region;
  }

  const market = getMarketForCountry(address.country);
  if (market && regions.hasOwnProperty(market)) {
    const marketWithRegion = regions[market as MarketWithRegions];
    const region = address.region.toUpperCase();

    if (marketWithRegion[region]) {
      return region;
    }
  }

  return address.region;
};

export const trimAddressFields = (address: Address): Address => ({
  ...address,
  addressLine1: address.addressLine1 ? address.addressLine1.trim() : address.addressLine1,
  addressLine2: address.addressLine2 ? address.addressLine2.trim() : address.addressLine2,
  addressLine3: address.addressLine3 ? address.addressLine3.trim() : address.addressLine3,
  city: address.city ? address.city.trim() : address.city,
  country: address.country ? address.country.trim() : address.country,
  region: address.region ? address.region.trim() : address.region,
  postalCode: address.postalCode ? address.postalCode.trim() : address.postalCode,
  companyName: address.companyName ? address.companyName.trim() : address.companyName,
});

export const hasValidAddressFields = (address: Address) => {
  const trimmedAddress = trimAddressFields(address);
  const market =
    getMarketByCountryName(trimmedAddress.country) ||
    getMarketByCountryCode(trimmedAddress.country);
  const addressConfig = getAddressConfigByMarket(market);
  const marketRequiresRegion = oc(addressConfig).region.display() === RegionDisplayType.Required;

  return !!(
    trimmedAddress.addressLine1 &&
    trimmedAddress.city &&
    (!marketRequiresRegion || trimmedAddress.region) &&
    trimmedAddress.postalCode &&
    market
  );
};

const validationServiceFieldMap: Record<string, FieldType> = {
  Street: FieldType.AddressLine1,
  City: FieldType.City,
  Province: FieldType.Region,
  PostalCode: FieldType.PostalCode,
};

export const hasFieldValidationError = (fieldType: FieldType, validationErrors: string[]) => {
  return validationErrors.some(errorField => validationServiceFieldMap[errorField] === fieldType);
};

export const convertToUndefinedIfFalsy = (value?: string | null) => (!value ? undefined : value);

export const serviceifyAddressOrgCreation = (
  organizationAddress: CreateOrganizationAddress
): CreateOrganizationAddress => {
  return {
    ...organizationAddress,
    addressLine2: convertToUndefinedIfFalsy(organizationAddress.addressLine2),
    addressLine3: convertToUndefinedIfFalsy(organizationAddress.addressLine3),
    postalCode: convertToUndefinedIfFalsy(organizationAddress.postalCode),
    region: convertToUndefinedIfFalsy(organizationAddress.region),
  };
};

export const serviceifyAddressOrganizationUpdate = (
  organizationAddress: UpdateOrganizationAddress
) => {
  return {
    ...organizationAddress,
    addressLine2: convertToUndefinedIfFalsy(organizationAddress.addressLine2),
    addressLine3: convertToUndefinedIfFalsy(organizationAddress.addressLine3),
    postalCode: convertToUndefinedIfFalsy(organizationAddress.postalCode),
    region: convertToUndefinedIfFalsy(organizationAddress.region),
  };
};

export const getAddressForValidation = (address: Address): ValidationAddress => {
  const addressConfig = getAddressConfiguration(address.country);

  let addressForValidation: ValidationAddress = {
    //we currently don't validate Address Line 1, 2, or 3 with AVS
    address_line1: '', //line 1 is required for AVS but may be empty
    city: address.city,
    country: address.country,
    postal_code: address.postalCode,
    region: address.region,
  };

  if (isPuertoRico(address.country)) {
    addressForValidation.region = 'PR';
  } else if (addressConfig.region.display === RegionDisplayType.Required) {
    addressForValidation.region = address.region;
  }

  return addressForValidation;
};

//this function handles the scenario where AVS responds
//  with a status of "None" but doesn't provided any field errors
export const hasInvalidAddressButNoFieldErrors = (
  hasAvsResponse: boolean,
  hasValidAddressFromService: boolean,
  hasValidationErrors: boolean
) => {
  return hasAvsResponse && !hasValidAddressFromService && !hasValidationErrors;
};
