import { Filter } from 'services/types';

export enum ApprovalActionType {
  Comment = 'COMMENT',
  Approve = 'APPROVE',
  Withdraw = 'WITHDRAW',
  Expire = 'EXPIRE',
  Reject = 'REJECT',
  TestApprovalOverride = 'TestApprovalOverride',
  Submitted = 'submitted',
}

export enum ApprovalLevelState {
  pending = 'pending',
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
  expired = 'expired',
  partiallyApproved = 'partiallyApproved',
}

export interface ApprovalLevel {
  order: number;
  level: string;
  state: ApprovalLevelState;
  policy: string;
  approvers: {
    emailAddress: string;
    firstName: string;
    lastName: string;
  }[];
  autoPopulate: boolean;
}

export interface Approval {
  id: string;
  context: {
    contextType: string;
    contextId: string;
    rulesVersion: string;
    market: {
      hierarchyLevel: string;
      marketId: string;
      marketCode: string;
    };
    segment: string;
    header: {
      name: string;
      quoteId: string;
    };
  };
  submitter: string;
  requiredApprovalLevels: {
    order: number;
    level: string;
    policy: string;
    autoPopulate: boolean;
  }[];
  approvalLevels: ApprovalLevel[];
  submittedDate: Date;
  status: ApprovalStatus;
  workFlow: string;
  possibleActions: string[];
  expirationDateTimeUtc: Date;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
  history: {
    action: ApprovalActionType;
    actor: string;
    comments: string;
    status?: string;
    createdBy: string;
    createdDate: Date;
  }[];
}

export interface ApprovalSummary {
  approvalLevels: ApprovalLevel[];
  context: {
    contextId: string;
    contextType: string;
    header: {};
  };
  createdBy: string;
  createdDate: string;
  history: {
    action: string;
    actor: string;
    comments: string;
    createdBy: string;
    createdDate: string;
    status: string;
  }[];
  id: string;
  status: ApprovalStatus;
  submittedDate: string;
  submitter: string;
  updatedBy: string;
  updatedDate: string;
  workFlow: string;
}

export enum ApprovalStatus {
  Submitted = 'submitted',
  Pending = 'pending',
  PartiallyApproved = 'partiallyApproved',
  Approved = 'approved',
  Rejected = 'rejected',
  Withdrawn = 'withdrawn',
  Expired = 'expired',
}

export interface Approvals {
  nextPageUri: string;
  page: number;
  pageSize: number;
  results: ApprovalSummary[];
  totalSize: number;
}

export interface ApprovalActionRequest {
  action: ApprovalActionType;
  approvalId: string;
  comments?: string;
}

export interface ApprovalAction {
  approvalId: string;
  comments: string;
  status: string;
  actor: string;
  possibleActions: string[];
  createdBy: string;
  createdDate: string;
}

export enum SearchApprovalsFilterField {
  EmailAddresss = 'approvalLevels.approvers.emailAddress',
  Submitter = 'submitter',
  ContextId = 'context.contextId',
  ContextType = 'context.contextType',
}

export interface SearchApprovalsParams {
  filter?: Filter<SearchApprovalsFilterField>;
}
