import { meplaHistory } from 'createHistory';
import { NetPromoterScore } from 'features-apollo/app/components/AppContent/NetPromoterScore/NetPromoterScore';
import { openFirstRunDialog } from 'features/app/components';
import { getFirstRunSlides } from 'features/app/components/InfoDialogs/FirstRun.utils';
import { getFlightIsEnabled } from 'features/app/selectors';
import * as userSelectors from 'features/user/selectors';
import { dialog20200803 } from 'features/user/utils';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { routes } from 'routes';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { DialogContext, ThemeProps } from 'styles';

const MainLayoutStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    width100: {
      width: '100%',
    },
    appContainer: {
      height: '100%',
      width: '100%',
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      color: palette.text,
    },
    skipToMainRow: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      boxShadow: `0 -2px 18px ${theme.palette.shadowColor}`,
      zIndex: theme.zIndex.navBar,

      '& > nav': {
        width: '100%',
      },
    },
    bodyRow: {
      backgroundImage: palette.backgroundBaseGradient,
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '& > div': {
        width: '100%',
      },
      overflow: 'hidden',
    },
  };
};

export enum bodyDisplay {
  default = 'bodySlot',
  info = 'infoDisplay',
  customer = 'customerDisplay',
}

interface MainLayoutProps extends WithStyles<typeof MainLayoutStyles> {
  skipToMainSlot: React.ReactNode;
  headerSlot: React.ReactElement;
  bodySlot: React.ReactElement;
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingPreferences: userSelectors.userPreferencesLoading(state),
    viewedFirstRun: userSelectors.isFirstRunViewed(state),
    showNpsSurvey: userSelectors.showNpsSurvey(state),
    useApolloClient: getFlightIsEnabled(state, Flight.graphqlPhase2),
  };
};

type Props = MainLayoutProps & ReturnType<typeof mapStateToProps>;

// #region Remove this after GQL flight is removed. Layout component should not have business logic.
const useMainBusinessLogic = (
  isLoadingPreferences: boolean,
  showNpsSurvey: boolean,
  viewedFirstRun: boolean,
  useApolloClient: boolean
) => {
  const { t } = useTranslation();
  useHotkeys('g+h', () => {
    meplaHistory.push(routes.home.quotes.root);
  });

  const [hasSeenDialog, setHasSeenDialog] = React.useState(false); //only 1 dialog per QC visit

  const enabledSlides = getFirstRunSlides(t).filter(slide => slide.enabled);
  const context = React.useContext(DialogContext);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (useApolloClient || isLoadingPreferences || hasSeenDialog) {
      return;
    }

    // Using '#info-dialog' at the end of QC urls to prevent reopening the dialog in the new tab/window
    if (window.location.href.includes('#info-dialog')) {
      return;
    }

    if (!viewedFirstRun && !!enabledSlides.length) {
      openFirstRunDialog(context, dialog20200803, enabledSlides);
      setHasSeenDialog(true);
    } else if (showNpsSurvey) {
      context.openDialog({
        providedDialog: <NetPromoterScore />,
      });
      setHasSeenDialog(true);
    }
  }, [isLoadingPreferences]);
  /* eslint-enable react-hooks/exhaustive-deps */
};

// #endregion

const MainLayoutUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    viewedFirstRun,
    isLoadingPreferences,

    showNpsSurvey,
    useApolloClient,
  } = props;

  useMainBusinessLogic(isLoadingPreferences, showNpsSurvey, viewedFirstRun, useApolloClient);

  const skipToMainSlot = props.skipToMainSlot && (
    <div className={classes.skipToMainRow}>{props.skipToMainSlot}</div>
  );

  return (
    <div className={classes.appContainer}>
      <div className={classes.flexContainer}>
        {skipToMainSlot}
        <div className={classes.headerRow}>{props.headerSlot}</div>
        <div className={classes.bodyRow}>{props.bodySlot}</div>
      </div>
    </div>
  );
};

export const MainLayoutStyled = withStyles(MainLayoutStyles)(MainLayoutUnstyled);
export const MainLayout = connect(mapStateToProps)(MainLayoutStyled);
