import { Error } from 'features/app/types';
import { CustomerSearchRequest } from 'services/ldss/types';
import { createAsyncAction } from 'store/utils';
import { createStandardAction } from 'typesafe-actions';

import { CustomerSearchWithWildcard } from './types';

export const searchCustomersAsync = createAsyncAction(
  '@@ldsscustomer/SEARCH_REQUEST',
  '@@ldsscustomer/SEARCH_SUCCESS',
  '@@ldsscustomer/SEARCH_FAILURE'
)<CustomerSearchRequest, CustomerSearchWithWildcard, Error>();

export const selectedCustomer = createStandardAction('@@ldsscustomer/SELECT_CUSTOMER')<string>();
