import { AccessDeniedPage } from 'features/app/components/AccessDeniedPage';
import * as selectors from 'features/user/selectors';
import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';

import { LoadingScreen } from './LoadingScreen';

const mapStateToProps = (state: RootState) => ({
  permitted: selectors.userHasPermission(state, state.app.appConfig.permissions),
  userPermissionsLoaded: selectors.userPermissionsLoaded(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const AuthProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  permitted,
  userPermissionsLoaded,
}: PropsWithChildren<Props>) => {
  if (permitted) {
    return children as React.ReactElement;
  } else if (!userPermissionsLoaded) {
    return <LoadingScreen />;
  } else {
    return <AccessDeniedPage />;
  }
};

export default connect(mapStateToProps)(AuthProvider);
