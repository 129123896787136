import {
  CalloutCard,
  Checkbox,
  DetailsList,
  Dropdown,
  LinkExternal,
  SectionSeparator,
  ShimmerForBackgroundCommon,
  TextBody,
} from 'components/ions';
import { applyConfigurationCardWithDialog } from 'features-apollo/quote/components/ConfigCards/utils';
import { formatCurrency } from 'features-apollo/quote/components/utils';
import { removeTypeName } from 'features-apollo/utils';
import {
  ApplyConfigurationSingleSkuInput,
  CatalogAction,
  Currency,
  QuoteMutationInput,
  Sku,
  Term,
} from 'generated/graphql';
import { useFabricSelectionSimple } from 'hooks/useFabricSelection';
import i18next from 'i18next';
import {
  CheckboxVisibility,
  ColumnActionsMode,
  DirectionalHint,
  IColumn,
  IDropdownOption,
  Selection,
  SelectionMode,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { getUnique } from 'services/utils';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { AddAsNewLineItemButton } from '../AddAsNewLineItemButton';
import { calloutCardDimensions } from '../common';
import {
  getSelectedSku,
  placeholderKey,
  placeholderOptions,
} from '../ModernOfficeConfigurationCard/utils';
import { ApplyConfigurationSingleSku } from '../queries';
import { ConfigurationCardCommonProps, SkuConfigurationCardCommonProps, View } from '../types';
import {
  buildTermDescriptionDropdownOptions,
  filterOutSkus,
  getAllAvailabilities,
  getAllTermsFromTheSku,
  getMatchedAvailability,
  getMaxTermCountAcrossSkus,
  isAutoRenewable,
} from '../utils';
import { GetProductWithTerms } from './queries';
import { styles } from './TermsConfigurationCard.styles';

export interface TermsConfigurationCardProps
  extends ConfigurationCardCommonProps,
    SkuConfigurationCardCommonProps {
  productId: string;
  lineItemId: string;
  quote: QuoteMutationInput;
  skus: Sku[];
  readOnly?: boolean;
  productType: string;
  target: React.RefObject<HTMLSpanElement>;
  onDismiss: () => void;
  initialValues?: { skuId: string; termId: string; autoRenew: boolean };
}

type Props = TermsConfigurationCardProps & WithStyles<typeof styles>;

interface TermsConfigurationCardResponse {
  skus: Sku[];
}

const getAllTerms = (skus: Sku[]) => {
  const availabilities = getAllAvailabilities(skus);
  let terms: Term[] = [];
  availabilities.forEach(availability => {
    if (availability.terms) {
      availability.terms.forEach(term => {
        terms.push(term);
      });
    }
  });
  return terms;
};

const generateColumnHeaders = (
  maxTermCount: number,
  uniqueTerms: Term[],
  currency: Currency,
  loadingPrices?: boolean
) => {
  const titleColumn: IColumn = {
    name: '',
    key: '',
    fieldName: 'title',
    minWidth: 0,
    columnActionsMode: ColumnActionsMode.disabled,
  };
  if (maxTermCount <= 1) {
    const priceColumn: IColumn = {
      name: i18next.t('quote::Price ({{currency}})', { currency }),
      key: 'Price',
      fieldName: 'price',
      minWidth: 0,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: item => {
        if (loadingPrices) {
          return <ShimmerForBackgroundCommon width={50} />;
        }
        return item.price;
      },
    };

    return [titleColumn, priceColumn];
  } else {
    const termColumns = uniqueTerms.map(term => {
      const column: IColumn = {
        name: term.description,
        key: term.description,
        fieldName: term.description,
        minWidth: 0,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: item => {
          if (loadingPrices) {
            return <ShimmerForBackgroundCommon width={50} />;
          }
          return item[term.description];
        },
      };
      return column;
    });
    return [titleColumn, ...termColumns];
  }
};

const getPriceFromTermDescriptionAndSku = (termDescription: string, sku: Sku) => {
  const matchedAvailability = sku.availabilities.find(
    av => av.terms && av.terms.some(term => term.description === termDescription)
  );
  if (matchedAvailability && matchedAvailability.terms) {
    const matchedTerm = matchedAvailability.terms.find(
      term => term.description === termDescription
    );
    if (
      matchedAvailability.isTrial &&
      matchedTerm &&
      matchedTerm.price != null &&
      matchedTerm.price.amount === 0
    ) {
      return i18next.t('quote::Trial');
    } else if (matchedTerm && matchedTerm.price != null) {
      return formatCurrency(matchedTerm.price.amount.toString()) || '-';
    }
  }
  return '-';
};
//This is a oneoff exception that we handle in the UX
export const standardSupportSku = {
  skuId: '0002',
  productId: 'DZH318Z0BWV3',
  productType: 'azuresupport',
};

export const isAzureSupportProduct = (productType: string, productId: string) => {
  return (
    productType.toLowerCase() === standardSupportSku.productType &&
    productId === standardSupportSku.productId
  );
};

const isSelectableSku = (productType: string, productId: string, skuId: string) =>
  !(isAzureSupportProduct(productType, productId) && skuId === standardSupportSku.skuId);

const generateItems = (
  uniqueTerms: Term[],
  skus: Sku[],
  maxTermCount: number,
  product: { id: string; type: string }
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rows: any = [];
  if (maxTermCount <= 1) {
    skus.forEach(sku => {
      const terms = getAllTermsFromTheSku(sku);
      if (terms[0].price) {
        const row = {
          ...sku,
          price: formatCurrency(terms[0].price.amount),
          selectable: isSelectableSku(product.type, product.id, sku.skuId),
          key: sku.skuId,
        };
        rows.push(row);
      } else {
        rows.push({
          ...sku,
          price: '-',
          selectable: isSelectableSku(product.type, product.id, sku.skuId),
          key: sku.skuId,
        });
      }
    });
  } else {
    skus.forEach(sku => {
      let row = {};
      const allTerms = getAllTermsFromTheSku(sku);
      uniqueTerms.forEach(term => {
        const matchedTerm = allTerms.find(t => t.termId === term.termId);
        let price = '-';
        if (matchedTerm) {
          price = getPriceFromTermDescriptionAndSku(matchedTerm.description, sku);
        }
        row = {
          ...sku,
          ...row,
          [term.description]: price,
          key: sku.skuId,
          selectable: isSelectableSku(product.type, product.id, sku.skuId),
        };
      });
      rows.push(row);
    });
  }
  return rows;
};

const onSkuSelection = (
  skus: Sku[],
  setTermId: (termId: string) => void,
  sku?: Sku,
  termId?: string
) => {
  if (!sku) {
    return;
  }
  const termDescriptions = buildTermDescriptionDropdownOptions(sku);
  const isSameTerm = termDescriptions.some(term => term.key === termId);
  if (termDescriptions.length && !isSameTerm && termId !== termDescriptions[0].key) {
    setTermId(termDescriptions[0].key);
  }
};

const generateAzureSupportMessage = () => {
  const supportOfferTitle = i18next.t('quote::Standard Support');
  const supportOfferComplete = (
    <Trans ns="quote">
      <TextBody dataAutomationId="supportOfferMessage">
        As part of a limited promotional offer, all customers are eligible for
        <span style={{ marginRight: 10 }}>
          <LinkExternal
            dataAutomationId="standardSupportLink"
            displayText={supportOfferTitle}
            href="https://azure.microsoft.com/en-us/offers/enterprise-agreement-support/"
          />
        </span>
        at no additional cost. Please only add Professional Direct to the quote as a paid offer.
      </TextBody>
    </Trans>
  );
  return supportOfferComplete;
};

const TermsConfigurationCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    productId,
    target,
    classes,
    currency,
    lineItemId,
    quote,
    initialValues,
    catalogContext,
    productTitle,
    productType,
    readOnly,
    onDismiss,
    alreadyHasDiscount,
  } = props;
  const [view, setView] = React.useState<View>(View.CardContent);
  const { data: skusData, loading } = useQuery<{
    getProduct: TermsConfigurationCardResponse;
  }>(GetProductWithTerms, {
    variables: {
      id: productId,
      catalogContext: removeTypeName(catalogContext),
      quoteId: quote.id,
    },
    errorPolicy: 'ignore',
  });
  const skus = filterOutSkus(oc(skusData).getProduct.skus(props.skus));
  const [autoRenew, setAutoRenew] = React.useState<boolean>(
    initialValues ? initialValues.autoRenew : isAutoRenewable(skus)
  );

  const dialogContext = React.useContext(DialogContext);

  const [termId, setTermId] = React.useState<string | undefined>(
    initialValues && initialValues.termId
  );
  const [skuId, setSkuId] = React.useState<string | undefined>(
    initialValues && initialValues.skuId
  );

  const [applyConfiguration, { loading: applyLoading }] = useMutation(ApplyConfigurationSingleSku);

  const { t } = useTranslation();

  const onSelectionChanged = (selection: Selection) => {
    const newSelection = selection && selection.getSelection()[0];
    const skuId = newSelection && newSelection.key;
    if (skuId) {
      setSkuId(skuId.toString());
    } else {
      setSkuId(undefined);
      setTermId(undefined);
    }
  };

  const autoRenewableProduct = isAutoRenewable(skus);

  const selection = useFabricSelectionSimple(onSelectionChanged);

  React.useEffect(() => {
    if (selection && skuId) {
      const items = (skus || []).map((sku: Sku) => {
        return { ...sku, key: sku.skuId, selectable: true };
      });
      selection.setItems(items);
      selection.setKeySelected(skuId, true, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!skus) {
    return null;
  }

  const onApply = (skuId: string, termId: string, configureAsNew: boolean) => {
    const availability = getMatchedAvailability(
      skuId,
      termId,
      skus || [],
      'Terms Configuration Card'
    );
    if (!availability) {
      return;
    }
    const configuration: ApplyConfigurationSingleSkuInput = {
      action: CatalogAction.Purchase,
      skuId,
      availabilityId: availability.availabilityId,
      lineItemId,
      autoRenew,
      termId,
      configureAsNew,
    };
    if (configureAsNew) {
      applyConfiguration({ variables: { quote, configuration } });
    } else if (alreadyHasDiscount) {
      setView(View.ConfirmationDialog);
      applyConfigurationCardWithDialog(
        () => {
          applyConfiguration({ variables: { quote, configuration } });
          onDismiss();
        },
        () => setView(View.CardContent),
        dialogContext
      );
    } else {
      applyConfiguration({ variables: { quote, configuration } });
      onDismiss();
    }
  };

  const sku = getSelectedSku(skus || [], skuId);
  let termDescriptionOptions: IDropdownOption[] = [];
  if (sku) {
    termDescriptionOptions = buildTermDescriptionDropdownOptions(sku);
  }

  const TermDropdown = (
    <Dropdown
      data-automation-id="terms"
      disabled={readOnly}
      label={t('quote::Commitment')}
      options={sku ? termDescriptionOptions : placeholderOptions(t)}
      selectedKey={sku ? termId : placeholderKey}
      onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
          setTermId(option.key.toString());
        }
      }}
    />
  );

  const uniqueTerms = getUnique(getAllTerms(skus), term => term.termId);
  const maxTermCount = getMaxTermCountAcrossSkus(skus);
  onSkuSelection(skus, setTermId, sku, termId);
  const detailsList = (
    <div className={classes.detailsListContainer}>
      <DetailsList
        addClass={classes.detailsListStyles}
        checkboxVisibility={CheckboxVisibility.always}
        columns={generateColumnHeaders(maxTermCount, uniqueTerms, currency, loading)}
        compact={true}
        items={generateItems(uniqueTerms, skus, maxTermCount, { id: productId, type: productType })}
        selection={selection}
        selectionMode={readOnly ? SelectionMode.none : SelectionMode.single}
        shouldResizeAfterFirstRender={true}
      />
    </div>
  );

  const isApplyEnabled = sku && termId;

  const recurringBillingCheckbox = (
    <div className={classes.reccuringBillingCheckboxContainer}>
      <Checkbox
        ariaLabel={i18next.t('quote::Recurring Billing')}
        checked={autoRenew}
        disabled={readOnly}
        id="recurringBillingCheckbox"
        label={i18next.t('quote::Recurring Billing')}
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
          checked?: boolean | undefined
        ) => {
          setAutoRenew(!!checked);
        }}
      />
    </div>
  );

  let content = (
    <>
      <div data-automation-id="skuList">
        {isAzureSupportProduct(productType, productId) && generateAzureSupportMessage()}
        {detailsList}
        <SectionSeparator />
        <div className={classes.dropdown}>{TermDropdown}</div>
      </div>
      {autoRenewableProduct && recurringBillingCheckbox}
    </>
  );
  //TODO: move this logic to gql probably and not return sku's if all of their availabilities are missing actions

  const noSkusOrTermsAfterLoadingIsFinished = (!uniqueTerms.length || !skus.length) && !loading;
  if (noSkusOrTermsAfterLoadingIsFinished) {
    content = (
      <TextBody>
        {t('quote::No SKUs are available, please remove the product from the quote')}
      </TextBody>
    );
  }

  return (
    <CalloutCard
      {...calloutCardDimensions}
      applyButtonDisabled={!isApplyEnabled}
      applyButtonStrings={{ text: t('quote::Apply'), ariaLabel: t('quote::Apply') }}
      closeButtonAriaLabel={t('quote::Close')}
      directionalHint={DirectionalHint.rightCenter}
      headerText={productTitle}
      hidden={view === View.ConfirmationDialog}
      id="termsConfigurationCard"
      isBeakVisible={true}
      isReadOnly={!!readOnly}
      otherFooterButtons={
        <AddAsNewLineItemButton
          disabled={!isApplyEnabled || readOnly}
          loading={applyLoading}
          onClick={() => {
            sku && termId && onApply(sku.skuId, termId, true);
          }}
        />
      }
      target={target}
      onApply={() => {
        sku && termId && onApply(sku.skuId, termId, false);
      }}
      onDismiss={onDismiss}
    >
      {content}
    </CalloutCard>
  );
};

export const TermsConfigurationCard = withStyles(styles)(TermsConfigurationCardUnstyled);
