import { CloseIconButton, TextBodySmall, TextTitle } from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import { useKey } from 'hooks';
import { FocusTrapZone } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { dialogStyles } from '../Dialog.styles';

export const headerHeight = 78;

//#region DialogHeader
interface DialogHeaderProps {
  addClass?: string;
  closeButtonAriaLabel?: string;
  closeButtonAddClass?: string;
  dataAutomationId?: string;
  headline?: string;
  hideCloseButton?: boolean;
  secondaryButton?: React.ReactNode;
  title?: string;
  closeDialog?: () => void;
}

type DialogHeaderPropsType = DialogHeaderProps & WithStyles<typeof dialogStyles>;

const DialogHeaderUnStyled: React.FC<DialogHeaderPropsType> = props => {
  const { classes, title, headline } = props;
  const context = React.useContext(DialogContext);
  useKey('Escape', props.closeDialog || context.closeDialog);

  return (
    <div
      className={`${classes.header} ${title ? classes.title : classes.noTitle} ${props.addClass}`}
    >
      {title && (
        <div>
          {headline && <TextBodySmall>{headline}</TextBodySmall>}
          <TextTitle>{title}</TextTitle>
        </div>
      )}
      <div>
        {props.secondaryButton}
        {!props.hideCloseButton && (
          <CloseIconButton
            addClass={mergeClassNames([classes.closeButton, props.closeButtonAddClass])}
            ariaLabel={props.closeButtonAriaLabel}
            dataAutomationId={`${props.dataAutomationId}Close`}
            onClick={props.closeDialog || context.closeDialog}
          />
        )}
      </div>
    </div>
  );
};

const DialogHeader = withStyles(dialogStyles)(DialogHeaderUnStyled);
//#endregion

export interface DialogImageProps {
  url: string;
  width?: string | number;
}

//#region Dialog
export interface DialogProps {
  bodyAddClass?: string;
  closeButtonAddClass?: string;
  closeButtonAriaLabel?: string;
  closeDialog?: () => void;
  dataAutomationId?: string;
  disableFocusTrapZone?: boolean;
  footerAddClass?: string;
  footerButtons?: React.ReactNode;
  headerAddClass?: string;
  headerSecondaryButton?: React.ReactNode;
  headline?: string;
  height?: number;
  hideCloseButton?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  title?: string;
  width: number;
  minHeight?: number;
  /**
   * Properties for the side image
   */
  imageProps?: DialogImageProps;
}

type DialogPropsType = DialogProps & WithStyles<typeof dialogStyles>;

const DialogUnstyled: React.FC<DialogPropsType> = props => {
  const { classes } = props;

  return (
    <FocusTrapZone
      disabled={props.disableFocusTrapZone}
      forceFocusInsideTrap={false}
      isClickableOutsideFocusTrap
    >
      <div className={classes.dialog}>
        {props.imageProps && <div className={classes.sideImage} />}
        <div className={classes.dialogContent}>
          {!props.hideHeader && (
            <DialogHeader
              addClass={props.headerAddClass}
              closeButtonAddClass={props.closeButtonAddClass}
              closeButtonAriaLabel={props.closeButtonAriaLabel}
              closeDialog={props.closeDialog}
              dataAutomationId={props.dataAutomationId}
              headline={props.headline}
              hideCloseButton={props.hideCloseButton}
              secondaryButton={props.headerSecondaryButton}
              title={props.title}
            />
          )}
          <div className={`${classes.body} ${props.bodyAddClass}`}>{props.children}</div>
          {!props.hideFooter && (
            <div className={mergeClassNames([classes.footer, props.footerAddClass])}>
              {props.footerButtons}
            </div>
          )}
        </div>
      </div>
    </FocusTrapZone>
  );
};

export const Dialog = withStyles(dialogStyles)(DialogUnstyled);
//#endregion
