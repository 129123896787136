import {
  AgreementType,
  Approval,
  ApprovalStatus,
  DiscountType,
  EcifLineItem,
  Motion,
  ProductAudience,
  Quote,
  QuoteStatus,
  TransactionModel,
  UserGroup,
} from 'generated/graphql';
import { oc } from 'ts-optchain';

import { QuoteLineItem } from './quote/components';
import { hasBlockingMessage } from './quote/selectors/general';

export const quoteIsLegacy = (quote: Quote) => quote.agreementType === AgreementType.Legacy;

export const quoteIsPartner = (quote: Quote) => quote.motion === Motion.Partner;

export const quoteIsActive = (quote: Quote) => quote.status === QuoteStatus.Active;

export const quoteIsDraft = (quote: Quote) => quote.status === QuoteStatus.Draft;

export const quoteIsSubmitted = (quote: Quote) => quote.status === QuoteStatus.Submitted;

export const quoteIsRejected = (quote: Quote) => quote.status === QuoteStatus.Rejected;

export const quoteIsDraftOrActive = (quote: Quote) => quoteIsDraft(quote) || quoteIsActive(quote);

export const quoteIsAssignedToCustomer = (quote: Quote) => quote.assignedTo === UserGroup.Customer;

export const quoteIsAssignedToField = (quote: Quote) => quote.assignedTo === UserGroup.Field;

export const quoteIsAssignedToPartner = (quote: Quote) => quote.assignedTo === UserGroup.Partner;

export const quoteIsRejectedOrSubmitted = (quote: Quote) =>
  quoteIsRejected(quote) || quoteIsSubmitted(quote);

export const quoteIsEditableByCustomer = (quote: Quote) =>
  quoteIsAssignedToCustomer(quote) && quoteIsDraftOrActive(quote);

export const quoteIsEditableByField = (quote: Quote) =>
  quoteIsAssignedToCustomer(quote) && quoteIsActive(quote);

export const quoteIsEditable = (quote: Quote) =>
  quoteIsEditableByCustomer(quote) || quoteIsEditableByField(quote);

export const quoteIsReadOnly = (quote: Quote) => quote.readOnly;

export const canWithdrawQuote = (quote: Quote) =>
  (quoteIsRejectedOrSubmitted(quote) || quoteIsEditable(quote)) &&
  quoteIsReadOnly(quote) &&
  !quoteIsPartner(quote);

export const isQuoteActivatable = (quote: Quote) => {
  if (!quote.approval || quote.approval.__typename !== 'QuoteApproval') {
    throw new Error('need to include quote approval in your query');
  }
  return quoteIsDraft(quote) && quote.approval.workflow === 'AutoApproval';
};

export const isQuoteActiveOrActivatable = (quote: Quote) => {
  (quote.status === QuoteStatus.Draft && quote.approval) || quoteIsActive(quote);
};

export const isQuoteExtendable = (quote: Quote) => {
  return (
    quote &&
    quote.status === QuoteStatus.Expired &&
    quote.modifiedApiVersion &&
    +quote.modifiedApiVersion >= 11 &&
    quote.lineItems &&
    quote.lineItems.length
  );
};

const isApprovalApproved = (approval: Approval) => approval.status === ApprovalStatus.Approved;
const isApprovalRejected = (approval: Approval) => approval.status === ApprovalStatus.Rejected;

const isApprovalApprovedOrRejected = (approval: Approval) =>
  isApprovalApproved(approval) || isApprovalRejected;

export const isUsersTurn = (quote: Quote) => {
  const { approval } = quote;
  if (approval && approval.__typename === 'Approval') {
    return isApprovalApprovedOrRejected(approval);
  }
  if (approval && approval.__typename === 'QuoteApproval') {
    //TODO - michmel: not sure if this is correct
    throw new Error('bad state, approval is quote approval');
  }
};

export const shouldShowApproverCommands = (quote: Quote) =>
  isUsersTurn(quote) && quoteIsSubmitted(quote);

// export function isUsersTurnToApprove(approval: ApprovalSummary | Approval, email: string) {
//   const sortedApprovalLevels = approval.approvalLevels.sort((a, b) => a.order - b.order);
//   const firstSubmittedApprovalLevel = sortedApprovalLevels.find(
//     level => level.state === ApprovalLevelState.submitted
//   );
//   const isUsersTurn = firstSubmittedApprovalLevel
//     ? firstSubmittedApprovalLevel.approvers.some(
//         approver => approver.emailAddress.toLowerCase() === email.toLowerCase()
//       )
//     : false;
//   const isApprovalDone =
//     approval.status === ApprovalStatus.Approved || approval.status === ApprovalStatus.Rejected;
//   return isUsersTurn && !isApprovalDone;
// }

export const quoteHasBlockingNotifications = (quote: Quote) => {
  return (
    hasBlockingMessage(quote.messages) ||
    quote.lineItems.some(lineItem => hasBlockingMessage(lineItem.messages)) ||
    quote.agreements.edges.some(agreement => hasBlockingMessage(agreement.node.messages))
  );
};

export const lineItemsHaveBlockingNotifications = (quote: Quote) => {
  return (
    quote.lineItems.some(lineItem => hasBlockingMessage(lineItem.messages)) ||
    quote.agreements.edges.some(agreement => hasBlockingMessage(agreement.node.messages))
  );
};

export const hasNonDiscountLineItems = (quote: Quote) => {
  return quote.lineItems.some(
    (lineItem: QuoteLineItem) =>
      lineItem.__typename !== 'DiscountLineItem' ||
      (lineItem.__typename === 'DiscountLineItem' &&
        oc(lineItem).discount.type() === DiscountType.Unconfigured)
  );
};

export const isQuoteHRDD = (quote: Quote) =>
  quote.transactionModel !== TransactionModel.ToPartnerCustomerAsset &&
  quote.productAudience === ProductAudience.PartnerCommercial &&
  quote.assignedTo === UserGroup.Partner;

export const isQuoteSubmitable = (quote: Quote) => {
  quote.lineItems.length && !quoteIsDraft(quote) && quoteHasBlockingNotifications(quote);
};

export const quoteHasSapTerm = (quote: Quote) =>
  quote.lineItems.some(
    lineItem => lineItem.product && lineItem.product.productType === 'SCPCommitmentToConsume'
  );

//TODO: michmel - add additional validation

export const quoteRequiresApproval = (quote: Quote) => {
  const { approval } = quote;
  if (approval) {
    if (approval.__typename === 'Approval') {
      return oc(approval).requiredApprovals([]).length && !isApprovalApproved(approval);
    }
    if (approval.__typename === 'QuoteApproval') {
      return (
        oc(approval)
          .workflow('')
          .toLocaleLowerCase() !== 'autoapproval' && !!oc(approval).requiredApprovals([]).length
      );
    }
  }
  //TODO: michmel - there is no potection here if the approval is not queried for it will just assume that the quote does not require approval

  return false;
};

export const disableSubmit = (quote: Quote) => {
  return (
    !quote.lineItems.length ||
    !quoteIsDraft(quote) ||
    quoteIsReadOnly(quote) ||
    !quoteRequiresApproval(quote) ||
    quoteHasBlockingNotifications(quote)
  );
};

export const disablePublish = (quote: Quote) => {
  return (
    quoteHasBlockingNotifications(quote) ||
    quoteRequiresApproval(quote) ||
    !quoteIsAssignedToField(quote) ||
    quote.lineItems.length === 0
  );
};

export const disableTransact = (quote: Quote) => {
  return (
    disablePublish(quote) ||
    hasNonDiscountLineItems(quote) ||
    quote.status === QuoteStatus.PurchaseInProgress ||
    quote.status === QuoteStatus.Completed
  );
};

//TODO: Probably should drive off of the approval object
export const quoteCanBeWithdrawn = (quote: Quote) => {
  return (
    ((quoteIsRejectedOrSubmitted(quote) || quoteIsEditable(quote)) && quoteIsReadOnly(quote)) ||
    !isQuoteHRDD(quote)
  );
};

export const isPreApprovedEcif = (lineItem: EcifLineItem) => {
  return lineItem?.ecifConfig?.caseNumber;
};
