import { call, select, takeEvery } from 'redux-saga/effects';
import loggerService from 'services/logger-service';
import { ProductGroup, UserPreferences } from 'services/user-preferences/types';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';
import { getUser, getUserPreferences } from '../selectors';
import { User } from '../types';
import { deleteUserPreferences, updateUserPreferences } from './auxiliary';

function* getUserEmail() {
  const user: User = yield select(getUser);
  if (!user.email) {
    loggerService.error({
      error: new Error('Email id of logged in user is missing.'),
    });
    return '';
  }
  return user.email;
}

export function* updateTheme() {
  const relevantAction = actions.setUserTheme;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const fromState: UserPreferences = yield select(getUserPreferences);
    const newPreferences: UserPreferences = { ...fromState, theme: action.payload };
    const email = yield call(getUserEmail);
    yield call(updateUserPreferences, newPreferences, email);
  });
}

export function* infoDialogViewed() {
  const relevantAction = actions.infoDialogViewed;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const fromState: UserPreferences = yield select(getUserPreferences);
    const newPreferences: UserPreferences = {
      ...fromState,
      viewedInfoDialogs: fromState.viewedInfoDialogs
        ? [...fromState.viewedInfoDialogs, action.payload]
        : [action.payload],
    };
    const email = yield call(getUserEmail);
    yield call(updateUserPreferences, newPreferences, email);
  });
}

export function* addProductToFavorites() {
  const relevantAction = actions.addProductToFavorites.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const fromState: UserPreferences = yield select(getUserPreferences);
    const productGroups = fromState.productGroups || { Favorites: { products: [] } }; // Add default group is no group exist
    const { groupName, product, moveFrom } = action.payload;

    if (!groupName) {
      return fromState;
    }

    const newProductGroup: ProductGroup = productGroups[groupName] || { products: [] };

    // Prevent duplicates
    if (newProductGroup.products.some(item => item.productId === product.productId)) {
      return fromState;
    } else {
      newProductGroup.products.push(product);
    }

    const newPreferences: UserPreferences = {
      ...fromState,
      productGroups:
        moveFrom && productGroups[moveFrom]
          ? {
              ...productGroups,
              [groupName]: newProductGroup,
              [moveFrom]: {
                ...productGroups[moveFrom],
                products: productGroups[moveFrom].products.filter(
                  item => item.productId !== product.productId
                ),
              },
            }
          : {
              ...productGroups,
              [groupName]: newProductGroup,
            },
    };

    const email = yield call(getUserEmail);
    yield call(updateUserPreferences, newPreferences, email);
  });
}

export function* removeProductFromFavorites() {
  const relevantAction = actions.removeProductFromFavorites.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const fromState: UserPreferences = yield select(getUserPreferences);
    const { productGroups } = fromState;
    const { groupName, product } = action.payload;
    const newPreferences: UserPreferences = {
      ...fromState,
    };

    if (!productGroups) {
      return fromState;
    }

    // Remove product from specific group
    if (groupName) {
      if (!productGroups[groupName]) {
        return fromState;
      }

      newPreferences.productGroups = {
        ...productGroups,
        [groupName]: {
          ...productGroups[groupName],
          products: productGroups[groupName].products.filter(
            item => item.productId !== product.productId
          ),
        },
      };
    }
    // Remove product from all groups
    else {
      const newProductGroups = { ...productGroups };

      for (let groupName in newProductGroups) {
        newProductGroups[groupName].products = newProductGroups[groupName].products.filter(
          item => item.productId !== product.productId
        );
      }

      newPreferences.productGroups = newProductGroups;
    }

    const email = yield call(getUserEmail);
    yield call(updateUserPreferences, newPreferences, email);
  });
}

export function* renameProductGroup() {
  const relevantAction = actions.renameProductGroup.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const fromState: UserPreferences = yield select(getUserPreferences);
    const { productGroups } = fromState;
    const { groupName, newGroupName } = action.payload;

    if (!productGroups || !productGroups[groupName] || !newGroupName) {
      return fromState;
    }

    const newProductGroups = { ...productGroups };
    newProductGroups[newGroupName] = productGroups[groupName];
    delete newProductGroups[groupName];

    const newPreferences: UserPreferences = {
      ...fromState,
      productGroups: newProductGroups,
    };

    const email = yield call(getUserEmail);
    yield call(updateUserPreferences, newPreferences, email);
  });
}

export function* deleteProductGroup() {
  const relevantAction = actions.deleteProductGroup.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const fromState: UserPreferences = yield select(getUserPreferences);
    const { productGroups } = fromState;
    const { groupName } = action.payload;

    if (!productGroups || !productGroups[groupName]) {
      return fromState;
    }

    const newProductGroups = { ...productGroups };
    delete newProductGroups[groupName];

    const newPreferences: UserPreferences = {
      ...fromState,
      productGroups: newProductGroups,
    };

    const email = yield call(getUserEmail);
    yield call(updateUserPreferences, newPreferences, email);
  });
}

export function* removeUserPreferences() {
  const relevantAction = actions.deleteUserPreferencesAsync.request;
  yield takeEvery(getType(relevantAction), function*() {
    const email = yield call(getUserEmail);
    yield call(deleteUserPreferences, email);
  });
}
