import { userHasPermission, userPermissionsLoaded } from 'features-apollo/user/selectors';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';

import { LoadingScreen } from '../LoadingScreen';
import { AccessDeniedPage } from './AccessDeniedPage';

const mapStateToProps = (state: RootState) => ({
  permitted: userHasPermission(state, state.app.appConfig.permissions),
  userPermissionsLoaded: userPermissionsLoaded(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const AuthzProvider: React.FC<Props> = props => {
  const { children, permitted, userPermissionsLoaded } = props;
  if (permitted) {
    return children as React.ReactElement;
  } else if (!userPermissionsLoaded) {
    return <LoadingScreen />;
  } else {
    return <AccessDeniedPage />;
  }
};

export default connect(mapStateToProps)(AuthzProvider);
