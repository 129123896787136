import { ShimmerForBackgroundCommon } from 'components';
import { Currency } from 'features/proposal/supported-currencies';
/* eslint-disable @typescript-eslint/no-use-before-define */
import { PriceMap, SkuTitleMap } from 'features/proposal/types';
import {
  buildLoadPriceRequest,
  createProposalUpdateRequest,
  formatCurrency,
  generateProductIdentifierKey,
  updateAndCreateLineItemsRequest,
} from 'features/proposal/utils';
import i18next from 'i18n';
import {
  FilterValue,
  ProductFilter,
  ProductFilterContext,
} from 'microsoft-commerce-product-filters';
import moment from 'moment';
import {
  ColumnActionsMode,
  IColumn,
  IDropdownOption,
  ISelectableOption,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { DisplaySkuAvailability, Product } from 'services/catalog/types';
import loggerService from 'services/logger-service';
import {
  LineItem,
  PricingContext,
  PricingInstructionCondition,
  ProductIdentifier,
  Proposal,
  ProposalPricingRequest,
  ProposalUpdateRequest,
  UpdateAndCreateLineItemsRequest,
} from 'services/proposal/types';
import { getUnique } from 'services/utils';
import { oc } from 'ts-optchain';

import {
  Attribute,
  Availability,
  CreateLineItemProperties,
  HydratedProduct,
  MatchingTermAndBillingPlan,
  QualifyingSkuAvailability,
  SkuRow,
  Term,
  TermComponent,
} from './';

//TODO kaderbez translation strategy and constants
//TODO kaderbez add/change all applicable to oc

export const defaultSelectedDuration = 'Pay go';
export const defaultSelectedLocation = 'All Locations';
export const termDurationType = 'termduration';
export const billingPlanType = 'billingplan';

//#region Builder functions
export const buildColumn = (
  name: string,
  key: string,
  fieldName: string,
  isRowHeader = false
): IColumn => {
  return {
    name,
    fieldName,
    key,
    isRowHeader,
    isResizable: false,
    isSorted: false,
    minWidth: 0,
    columnActionsMode: ColumnActionsMode.disabled,
  };
};

export const buildMeterColumns = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product
) => {
  let allMeterTypes: string[] = [];
  const skurows = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    product
  );
  skurows.forEach((qualifyingSkuAvailability: QualifyingSkuAvailability) => {
    qualifyingSkuAvailability.Availabilities.forEach((availability: Availability) => {
      const properties = availability.Properties;
      if (properties && properties.MeterType) {
        allMeterTypes.push(properties.MeterType);
      }
    });
  });
  const uniqueMeterTypes = [...new Set(allMeterTypes)];
  return uniqueMeterTypes.map((meterType: string) => buildColumn(meterType, meterType, meterType));
};

export const buildTermColumns = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product
): IColumn[] => {
  const skurows = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    product
  );
  const allTerms = getTermsForAllQualifyingSkus(skurows, product);
  return allTerms.map((term: Term) =>
    buildColumn(term.TermDescription, term.TermId, term.TermDescription)
  );
};

export const buildColumnTitles = (
  hydratedProduct: Product,
  hasTermDescriptionFilter: boolean,
  isConsumableProduct: boolean,
  maxSkuAvailabilitiesLength: number,
  maxSkuAvailabilitiesTermsLength: number,
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  currency: Currency,
  selectedDuration?: string
): IColumn[] => {
  const isNegotiatedTerms = isNegotiatedTerm(hydratedProduct);
  const emptyTitleColumn = buildColumn('', 'Title', 'Title', true);
  const maxSkuAvailabilityTermsGreaterThanOne = maxSkuAvailabilitiesTermsLength > 1;
  const selectedDurationIsPayGo = !selectedDuration || selectedDuration.toUpperCase() === 'PAY GO';
  if (isNegotiatedTerms) {
    return [buildColumn('Location', 'Title', 'Title', true)];
  } else {
    if (
      hasTermDescriptionFilter &&
      isConsumableProduct &&
      selectedDurationIsPayGo &&
      maxSkuAvailabilitiesLength
    ) {
      const meterColumns = buildMeterColumns(qualifyingSkuAvailabilities, hydratedProduct);
      return [emptyTitleColumn].concat(meterColumns);
    } else if (
      !hasTermDescriptionFilter &&
      maxSkuAvailabilitiesLength &&
      maxSkuAvailabilityTermsGreaterThanOne &&
      !isPasses(hydratedProduct)
    ) {
      const termColumns = buildTermColumns(qualifyingSkuAvailabilities, hydratedProduct);
      return [emptyTitleColumn].concat(termColumns);
    } else {
      if (!isPasses(hydratedProduct)) {
        const priceColumn = buildColumn(
          `Price (${currency})`,
          `Price (${currency})`,
          `Price (${currency})`
        );
        return [emptyTitleColumn, priceColumn];
      }
      return [emptyTitleColumn];
    }
  }
};

export const buildLocationDropdownOptions = (filters: ProductFilter[]): ISelectableOption[] => {
  if (hasLocationFilter(filters)) {
    const locationFilter = getLocationFilter(filters);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sortedValues = locationFilter!.values.sort((a: FilterValue, b: FilterValue) =>
      a.text.localeCompare(b.text)
    );
    if (sortedValues.length > 1) {
      return [{ text: 'All Locations', key: 'All Locations' }].concat(sortedValues);
    }
    return sortedValues;
  }
  loggerService.error({ error: new Error('No Location Values') });
  return [];
};

export const matchTermsAndBillingPlans = (terms: Term[]) => {
  let termBillingPlans: MatchingTermAndBillingPlan[] = [];
  terms.forEach((term: Term) => {
    if (term.TermComponents) {
      const termDuration = term.TermComponents.find(
        (termComponent: TermComponent) => termComponent.Type.toLowerCase() === termDurationType
      );
      const billingPlan = term.TermComponents.find(
        (termComponent: TermComponent) => termComponent.Type.toLowerCase() === billingPlanType
      );
      if (termDuration) {
        if (termDuration.Properties.Duration) {
          termBillingPlans.push({
            // e.g. Yearly
            billingPlan: billingPlan && billingPlan.Properties.Title,
            duration: {
              // e.g. 1 year subscription
              text: termDuration.Properties.Title,
              // e.g. 1Y
              key: termDuration.Properties.Duration,
            },
            termId: term.TermId,
          });
        } else {
          loggerService.error({
            error: new Error(`termDuration ${termDuration} has no Properties.Duration`),
          });
        }
      }
    }
  });
  return termBillingPlans;
};

export const getTermsForSelection = (
  selectedSku: string,
  skuRows: QualifyingSkuAvailability[],
  product: Product
): Term[] => {
  const productSkuId = selectedSku.split('/');
  const skuId = productSkuId.length >= 2 ? productSkuId[1] : undefined;
  const skus = skuRows.find(
    qualifyingSkuAvailability => qualifyingSkuAvailability.Sku.SkuId === skuId
  );
  return (skus && getTermsForAllQualifyingSkus([skus], product)) || [];
};

export const getTermDurationFromTermId = (terms: Term[], termId: string) => {
  const matched = terms.find(term => term.TermId === termId);
  if (matched && matched.TermComponents) {
    const durationComponent = matched.TermComponents.find(
      (termComponent: TermComponent) =>
        termComponent.Type.toLowerCase() === termDurationType && !!termComponent.Properties.Duration
    );
    return durationComponent && durationComponent.Properties.Duration;
  }
};

export const buildBillingPlanDropdownOptions = (
  qualifyingSkuAvailabilites: QualifyingSkuAvailability[],
  product: Product,
  selectedListKey?: string,
  selectedTermId?: string
): IDropdownOption[] => {
  const skuRows = removePrivateAndNonPurchasableConsumableSkus(qualifyingSkuAvailabilites, product);
  if (selectedListKey && selectedTermId) {
    const terms = getTermsForSelection(selectedListKey, skuRows, product);
    if (terms && terms.length && hasTermComponents(terms)) {
      const termsAndBillingPlans = matchTermsAndBillingPlans(terms);
      const termDuration = getTermDurationFromTermId(terms, selectedTermId);
      const selectedOptions = termsAndBillingPlans.filter((term: MatchingTermAndBillingPlan) => {
        return term.duration.key === termDuration;
      });
      let plans: IDropdownOption[] = [];
      selectedOptions.forEach(termBillingPlan => {
        if (termBillingPlan.billingPlan) {
          plans.push({ key: termBillingPlan.termId, text: termBillingPlan.billingPlan });
        }
      });

      if (!plans.length) {
        loggerService.error({
          error: new Error(
            `product does not have billing plan options for the selected SKU, even though it has termComponents, terms: ${terms}`
          ),
        });
      }
      return plans;
    }
  }
  return [];
};

export const buildTermDropdownOptions = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product,
  selectedListKey?: string
): IDropdownOption[] => {
  let allTerms: Term[];
  const skurows = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    product
  );
  if (selectedListKey) {
    const productSkuId = selectedListKey.split('/');
    const skuId = productSkuId.length >= 2 ? productSkuId[1] : undefined;
    const row = skurows.find(
      qualifyingSkuAvailability => qualifyingSkuAvailability.Sku.SkuId === skuId
    );
    allTerms = (row && getTermsForAllQualifyingSkus([row], product)) || [];
  } else {
    allTerms = getTermsForAllQualifyingSkus(skurows, product);
  }

  const withTermComponents = allTerms.filter(term => !!term.TermComponents);
  const withoutTermComponents = allTerms.filter(term => !term.TermComponents);
  const TermsFromTermComponents = matchTermsAndBillingPlans(withTermComponents);
  const withoutTermComponentsTerms = withoutTermComponents.map(term => {
    const billingPlanAndTerms: MatchingTermAndBillingPlan = {
      termId: term.TermId,
      duration: {
        //e.g 1 Month Subscription
        key: term.TermDescription,
        text: term.TermDescription,
      },
    };
    return billingPlanAndTerms;
  });

  const combinedTerms = [...TermsFromTermComponents, ...withoutTermComponentsTerms];
  const dropdownOptions = combinedTerms.map((term: MatchingTermAndBillingPlan) => {
    return {
      text: term.duration.text,
      key: term.termId,
      data: { termWithoutBillingPlan: !term.billingPlan, duration: term.duration.key },
    };
  });
  const deduplicated = getUnique<IDropdownOption>(dropdownOptions, option =>
    option.text.toString()
  );
  return deduplicated;
};

export const buildDurationDropdownOptions = (
  filters: ProductFilter[],
  hydratedProduct: Product,
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  isLegacyQuote: boolean
) => {
  let result: IDropdownOption[] = [];
  if (!isSoftware(hydratedProduct)) {
    const durationFilters = durationFilterOptions(filters);
    if (durationFilters.length) {
      result = durationFilters;
    } else if (isNonLocationMultipleSkusProduct(filters, qualifyingSkuAvailabilities)) {
      result = [];
    } else if (
      hasTermsAsDuration(filters, hydratedProduct) &&
      hasQualifyingSkus(qualifyingSkuAvailabilities)
    ) {
      result = buildTermDropdownOptions(qualifyingSkuAvailabilities, hydratedProduct);
    }

    const hasPayGo =
      (isConsumableProduct(filters) && hasDurationFilter(filters)) ||
      isNonLocationMultipleSkusProduct(filters, qualifyingSkuAvailabilities);
    if (hasPayGo && !isLegacyQuote) {
      const allReservationsOption: IDropdownOption = {
        text: 'Pay go',
        key: 'Pay go',
      };
      result = [allReservationsOption].concat(result);
    }
  }
  return result;
};

export const buildSkuRows = (
  hydratedProduct: Product,
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  columns: IColumn[],
  loadPrice: (proposalPricingRequest: ProposalPricingRequest) => void,
  prices: PriceMap,
  lineItems: LineItem[],
  pricingContext: PricingContext,
  selectedTermsKey?: string
) => {
  let displaySkuRows: any = [];
  const filteredSkus = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    hydratedProduct
  );
  if (filteredSkus) {
    filteredSkus.forEach((sku: QualifyingSkuAvailability) => {
      const hasOneAvailability = maxSkuAvailabilitiesLength(filteredSkus, hydratedProduct) <= 1;
      const key = hasOneAvailability
        ? `${hydratedProduct.ProductId}/${sku.Sku.SkuId}/${sku.Availabilities[0].AvailabilityId}`
        : `${hydratedProduct.ProductId}/${sku.Sku.SkuId}`;
      const row: SkuRow = {
        Availabilities: sku.Availabilities,
        Sku: sku.Sku,
        key,
        Title: sku.Title,
        selectable: isSupportOffer(hydratedProduct) && sku.Sku.SkuId === '0002' ? false : true,
      };
      displaySkuRows.push(row);
    });
  }
  requestPriceForRows(
    displaySkuRows,
    loadPrice,
    hydratedProduct.ProductId,
    prices,
    lineItems,
    pricingContext,
    hydratedProduct
  );

  return setPriceForRows(displaySkuRows, columns, prices, hydratedProduct, selectedTermsKey);
};

const locationsListEndingStringBuilder = (
  listLength: number,
  maxLocationsShown: number,
  minListLength: number
) => {
  if (listLength > maxLocationsShown) {
    return i18next.t('quote::, etc.');
  }
  if (listLength > minListLength) {
    return i18next.t('quote::, and ');
  }
  return i18next.t('quote:: and ');
};

const locationsListStringBuilder = (
  locations: string[],
  maxLocationsShown: number,
  locationsListEnd: string
) => {
  if (locations.length > maxLocationsShown) {
    return locations
      .slice(0, maxLocationsShown)
      .join(', ')
      .concat(locationsListEnd);
  }

  return [
    locations.slice(0, locations.length - 1).join(', '),
    locations[locations.length - 1],
  ].join(locationsListEnd);
};

const buildMultiRegionalWatermarkMessage = (locations: string[]) => {
  const maxLocationsShown = 10;
  const minListLength = 2;
  const locationsListEnd = locationsListEndingStringBuilder(
    locations.length,
    maxLocationsShown,
    minListLength
  );
  const locationsList = locationsListStringBuilder(locations, maxLocationsShown, locationsListEnd);
  return i18next.t(
    'quote::{{length}} line items for the locations {{locationsList}} will be added to the quote',
    { length: locations.length, locationsList }
  );
};

export const buildWatermarkMessage = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product,
  locations: string[]
) => {
  if (locations.length > 1) {
    return buildMultiRegionalWatermarkMessage(locations);
  }
  const skuCount = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    product
  );
  return skuCount.length
    ? i18next.t('quote::{{length}} SKUs will be part of the discount', {
        length: skuCount.length,
      })
    : i18next.t('quote::No SKUs are available, please remove the product from the quote');
};

export const applyButtonEnabled = (
  filters: ProductFilter[],
  selectedDuration: string,
  selectedLocation: string,
  selectedSku?: string,
  allowUnspecifiedOptionInLocationFilters?: boolean
): boolean => {
  if (selectedLocation === '' && allowUnspecifiedOptionInLocationFilters && !selectedSku) {
    return false;
  }
  const isAllLocations =
    hasLocationFilter(filters) && selectedLocation.toUpperCase() === 'ALL LOCATIONS';

  const durationIsAllReservations =
    hasDurationFilter(filters) && selectedDuration.toUpperCase() === 'ALL RESERVATIONS';
  const isGlobalLocation =
    hasLocationFilter(filters) &&
    selectedLocation.toUpperCase() === 'GLOBAL' &&
    durationIsAllReservations;
  const durationIsPayGo = hasDurationFilter(filters) && selectedDuration.toUpperCase() === 'PAY GO';
  const isAllLocationsButNotPayGo = isAllLocations && !durationIsPayGo;
  const isLocationButNotDuration = hasLocationFilter(filters) && !hasDurationFilter(filters);
  return !!(
    isGlobalLocation ||
    durationIsPayGo ||
    selectedSku ||
    isAllLocationsButNotPayGo ||
    isLocationButNotDuration
  );
};

export const createProductIdentifiersForRowAvailability = (
  qualifyingSkuAvailability: QualifyingSkuAvailability,
  productId: string,
  prices: PriceMap
) => {
  let productIdentifiers: ProductIdentifier[] = [];
  const skuId = qualifyingSkuAvailability.Sku.SkuId;
  qualifyingSkuAvailability.Availabilities.forEach((availability: Availability) => {
    const isConsumable = availability.Actions.some(
      (action: string) => action.toUpperCase() === 'CONSUME'
    );
    const action = isConsumable ? 'Consume' : 'Purchase';
    if (availability.Terms) {
      const terms = getTermsForAvailability(availability);
      terms.forEach((term: Term) => {
        const productIdentifier: ProductIdentifier = {
          productId,
          skuId,
          availabilityId: availability.AvailabilityId,
          availabilityTermId: term.TermId || undefined,
          action,
        };
        const key = generateProductIdentifierKey(productIdentifier);
        if (prices[key] === undefined) {
          productIdentifiers.push(productIdentifier);
        }
      });
    } else {
      const productIdentifier: ProductIdentifier = {
        productId,
        skuId,
        availabilityId: availability.AvailabilityId,
        action,
      };
      const key = generateProductIdentifierKey(productIdentifier);
      if (prices[key] === undefined) {
        productIdentifiers.push(productIdentifier);
      }
    }
  });
  return productIdentifiers;
};

export const createProductIdentifiersForAllRowsAvailability = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  productId: string,
  prices: PriceMap,
  product: Product
): ProductIdentifier[] => {
  let productIdentifiers: ProductIdentifier[] = [];
  const filteredSkus = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    product
  );
  filteredSkus.forEach((qualifyingSkuAvailability: QualifyingSkuAvailability) => {
    const productIdentifierForRow = createProductIdentifiersForRowAvailability(
      qualifyingSkuAvailability,
      productId,
      prices
    );
    productIdentifiers = productIdentifiers.concat(productIdentifierForRow);
  });
  return productIdentifiers;
};

export const durationFilterOptions = (filters: ProductFilter[]) => {
  if (hasDurationFilter(filters)) {
    const durationFilter = getDurationFilter(filters);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = durationFilter!.values.sort((a: FilterValue, b: FilterValue) =>
      a.text.localeCompare(b.text)
    );
    if (result.length > 1) {
      const allReservationsOption: IDropdownOption = {
        text: 'All Reservations',
        key: 'All Reservations',
      };
      return [allReservationsOption].concat(result);
    }
    return result;
  }
  return [];
};

export const removePrivateAndNonPurchasableConsumableSkus = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product
) => {
  const validSaaSProducts = removeInvalidSaaSAvailabilities(qualifyingSkuAvailabilities, product);
  return validSaaSProducts.filter((qualifyingSkuAvailability: QualifyingSkuAvailability) => {
    const properties = oc(qualifyingSkuAvailability).Sku.Properties();
    const isPrivate =
      properties &&
      properties.Attributes &&
      properties.Attributes.some(
        (attribute: Attribute) =>
          attribute.Key.toUpperCase() === 'ISPRIVATEMARKETPLACE' && !!attribute.Value
      );
    const hasSpot =
      properties &&
      properties.Attributes &&
      properties.Attributes.some(
        (attribute: Attribute) =>
          attribute.Key.toUpperCase() === 'FEATURE' &&
          attribute.Value &&
          attribute.Value.toString().toUpperCase() === 'SPOT'
      );
    const firstAvailability = qualifyingSkuAvailability.Availabilities[0];
    const hasNoActions = firstAvailability.Actions && firstAvailability.Actions.length === 0;
    const hasPurchaseOrConsume =
      firstAvailability.Actions &&
      firstAvailability.Actions.some(
        (action: string) =>
          action.toUpperCase() === 'PURCHASE' || action.toUpperCase() === 'CONSUME'
      );
    return !isPrivate && !hasNoActions && !hasSpot && hasPurchaseOrConsume;
  });
};

// 0 has a higher rank than 1 and 1 has a higher rank than 2 ,
// it's like the olympics ranking where the 1'st one gets gold 2'nd one get's silver 3'rd one gets bronze, in that case 1 has the highest ranking.
export function InPlaceHighestRankedAvailability(
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[]
) {
  const displaySkuAvailabilitiesWithOnlyHighestRankAvailability = qualifyingSkuAvailabilities.map(
    displaySkuAvailability => {
      const sorted = displaySkuAvailability.Availabilities.sort(
        (availability1, availability2) => availability1.DisplayRank - availability2.DisplayRank
      );
      const highestRankedAvailability = sorted[0];
      return {
        ...displaySkuAvailability,
        Availabilities: highestRankedAvailability ? [highestRankedAvailability] : [],
      };
    }
  );
  return displaySkuAvailabilitiesWithOnlyHighestRankAvailability;
}

export const removeInvalidSaaSAvailabilities = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product
) => {
  if (isSaaSProduct(product)) {
    return InPlaceHighestRankedAvailability(qualifyingSkuAvailabilities);
  }
  return qualifyingSkuAvailabilities;
};

export const isAvailabilityTrial = (availabilities: Availability[], availabilityId: string) => {
  const selectedAvailability = availabilities.find(
    availability => availability.AvailabilityId === availabilityId
  );
  return oc(selectedAvailability).OrderManagementData.GrantedEntitlements[0].IsTrial(false);
};

export const setPriceForRows = (
  rows: SkuRow[],
  columns: IColumn[],
  prices: PriceMap,
  product: Product,
  selectedTermsKey?: string
) => {
  const rowsWithPrices = rows.map(row => {
    let newRow = { ...row };
    const hasMoreThanTwoColumns = columns.length > 2;
    columns.forEach((column: IColumn) => {
      const isFirstColumn = column.key.toLowerCase() === 'title';
      const availabilityForTerm =
        selectedTermsKey && getAvailabilityForTermId(row.Availabilities, selectedTermsKey);
      let availabilityId =
        row.Availabilities.length === 1
          ? oc(row.Availabilities)[0].AvailabilityId()
          : availabilityForTerm && availabilityForTerm.AvailabilityId;
      let termId =
        row.Availabilities.length === 1
          ? getFirstTermIdFromFirstAvailability(row.Availabilities)
          : selectedTermsKey;
      if (hasMoreThanTwoColumns && !isFirstColumn) {
        const availabilityTerm = getAvailabilityAndTermForColumnWhenMultipleAvailabilities(
          rows,
          row,
          column,
          product
        );
        availabilityId = availabilityTerm.availabilityId || availabilityId;
        termId = availabilityTerm.termId || termId;
      }
      const isTrial = !!availabilityId && isAvailabilityTrial(row.Availabilities, availabilityId);
      const { value, placeholder } = getPriceValue(newRow.key, prices, availabilityId, termId);
      const formattedValue = formatCurrency(value.toString());

      if (!isFirstColumn) {
        newRow = {
          ...newRow,
          [column.name]: isTrial ? 'Trial' : formattedValue || value || placeholder,
        };
      }
    });
    return newRow;
  });
  return rowsWithPrices;
};

export const buildConfigureCombinedSummary = (
  lineItem?: LineItem,
  skuTitles?: SkuTitleMap,
  displaySkuAvailabilities?: DisplaySkuAvailability[]
) => {
  const location = oc(lineItem).extendedProperties.userPreferences.location();
  const duration = oc(lineItem).extendedProperties.userPreferences.duration();
  const title = oc(lineItem).extendedProperties.userPreferences.skuTitle();
  const productId = oc(lineItem).productIdentifier.productId();
  const skuId = oc(lineItem).productIdentifier.skuId();
  const action = oc(lineItem).productIdentifier.action();
  if (!location && !duration && !(productId && skuId)) {
    return action
      ? buildLegacyConfigureCombinedSummary(lineItem, displaySkuAvailabilities)
      : 'configure';
  } else if (productId && skuId) {
    const selected =
      action &&
      skuTitles &&
      skuTitles[productId] &&
      skuTitles[productId].find(skutitle => skutitle.sku === skuId);
    return selected
      ? selected.title
      : title || buildLegacyConfigureCombinedSummary(lineItem, displaySkuAvailabilities);
  } else if (location && duration) {
    return `${location} / ${duration}`;
  } else {
    return location || duration;
  }
};

export const buildLegacyConfigureCombinedSummary = (
  lineItem?: LineItem,
  displaySkuAvailabilities?: DisplaySkuAvailability[]
) => {
  const skuId = oc(lineItem).productIdentifier.skuId();
  const action = oc(lineItem).productIdentifier.action();
  const conditions = oc(lineItem).pricingInstruction.conditions();
  const locationCondition =
    conditions &&
    conditions.find(
      condition =>
        oc(condition)
          .name('')
          .toLocaleLowerCase() === 'location'
    );
  const durationCondition =
    conditions &&
    conditions.find(
      condition =>
        oc(condition)
          .name('')
          .toLocaleLowerCase() === 'termunits'
    );
  const location = (locationCondition && locationCondition.value.toString()) || 'All Locations';
  const duration =
    (durationCondition && moment.duration(durationCondition.value.toString()).humanize()) ||
    'All Reservations';

  if (!action) {
    return 'configure';
  }
  if (displaySkuAvailabilities && skuId) {
    const sku = displaySkuAvailabilities.find(dsa => dsa.Sku.SkuId === skuId);
    return sku && sku.Sku.LocalizedProperties[0].SkuTitle;
  }
  if (conditions || (lineItem && lineItem.pricingInstruction)) {
    switch (action.toLocaleLowerCase()) {
      case 'consume': {
        return `${location} / Pay go`;
      }
      case 'purchase': {
        if (location && duration) {
          return `${location} / ${duration}`;
        } else {
          return location || duration;
        }
      }
    }
  }

  return 'configured';
};
//#endregion

//#region boolean returning functions
export const hasFilter = (filters: ProductFilter[], name: string) =>
  filters.some((filter: ProductFilter) => filter.name.toUpperCase() === name.toUpperCase());
export const isProductType = (hydratedProduct: HydratedProduct, type: string) =>
  hydratedProduct.ProductType.toUpperCase() === type.toUpperCase();

//todo: move all of these to a separate file
export const isAccessPass = (hydratedProduct: HydratedProduct) =>
  isProductType(hydratedProduct, 'AzureAccessPass');
export const isSupportOffer = (hydratedProduct: HydratedProduct) =>
  isProductType(hydratedProduct, 'AzureSupport');
export const isEntitlement = (hydratedProduct: HydratedProduct) =>
  isProductType(hydratedProduct, 'Entitlement');
export const isFamilyDiscount = (hydratedProduct: HydratedProduct) =>
  isProductType(hydratedProduct, 'AzureFamilyDiscount');
export const isMonetary = (hydratedProduct: HydratedProduct) =>
  isProductType(hydratedProduct, 'AzureMonetaryCommit') ||
  isProductType(hydratedProduct, 'AzureMonetaryCredit') ||
  isProductType(hydratedProduct, 'CommitmentToConsume');
export const isSAPTerm = (hydratedProduct: HydratedProduct) =>
  isProductType(hydratedProduct, 'SCPCommitmentToConsume');
export const isSAPProduct = (hydratedProduct: HydratedProduct) =>
  isProductType(hydratedProduct, 'SAP');
export const isNegotiatedTerm = (hydratedProduct: HydratedProduct) =>
  hydratedProduct.ProductFamily === 'NegotiatedTerms';
export const isPasses = (hydratedProduct: HydratedProduct) =>
  hydratedProduct.ProductFamily === 'Passes';
export const isSimpleTerm = (hydratedProduct: Product) => !!hydratedProduct.Properties.TermId;
export const isSaaSProduct = (hydratedProduct: Product) =>
  oc(hydratedProduct)
    .Properties.Service('')
    .toUpperCase() === 'SAAS';
export const isSoftware = (hydratedProduct: HydratedProduct) =>
  hydratedProduct.ProductFamily === 'Software';
export const hasTermDescriptionFilter = (filters: ProductFilter[]) =>
  hasFilter(filters, 'TermDescription'); // Formally known as isVariableLoad
export const hasActionFilter = (filters: ProductFilter[]) => hasFilter(filters, 'Action');
export const hasDurationFilter = (filters: ProductFilter[]) => hasFilter(filters, 'Duration');
export const hasLocationFilter = (filters: ProductFilter[]) => hasFilter(filters, 'Location');

export const hasTermsAsDuration = (filters: ProductFilter[], hydratedProduct: HydratedProduct) =>
  hasTermDescriptionFilter(filters) &&
  (isEntitlement(hydratedProduct) || isSupportOffer(hydratedProduct));

export const isConsumableProduct = (filters: ProductFilter[]) => {
  const filter = getActionFilter(filters);
  return !!(filter && filterContainsKey(filter, 'consume'));
};

export const isPurchasableProduct = (filters: ProductFilter[]) => {
  const filter = getActionFilter(filters);
  return !!(filter && filterContainsKey(filter, 'purchase'));
};

export const isUnfilterableProduct = (
  filters: ProductFilter[],
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  hydratedProduct: Product
): boolean => {
  const filteredSkus = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    hydratedProduct
  );
  const atMostOneQualifyingSku = !hasMultipleSkus(filteredSkus);
  const noFilters = filters.length === 0;
  const singleFilterWithASingleValue = filters.length === 1 && filters[0].values.length <= 1;
  return (
    (atMostOneQualifyingSku ||
      noFilters ||
      singleFilterWithASingleValue ||
      isSoftware(hydratedProduct) ||
      isAccessPass(hydratedProduct)) &&
    !isConsumableProduct(filters)
  );
};

export const isNonLocationMultipleSkusProduct = (
  filters: ProductFilter[],
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[]
) =>
  hasTermDescriptionFilter(filters) &&
  hasMultipleSkus(qualifyingSkuAvailabilities) &&
  isConsumableProduct(filters) &&
  !hasLocationFilter(filters);

export const maxSkuAvailabilitiesLength = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  hydratedProduct: Product
) => {
  const filteredSkus = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    hydratedProduct
  );
  const availabilities = filteredSkus.map(
    (qualifyingSkuAvailability: QualifyingSkuAvailability) => {
      return qualifyingSkuAvailability.Availabilities.filter(
        availability => availability.Terms || oc(availability).Properties.MeterType()
      ).length;
    }
  );
  return Math.max(Math.max.apply(null, availabilities), 0);
};

export const filterContainsKey = (filter: ProductFilter, key: string) =>
  filter.values.some((value: { key: string }) => value.key.toUpperCase() === key.toUpperCase());

export const hasMultipleSkus = (qualifyingSkuAvailabilities: QualifyingSkuAvailability[]) =>
  qualifyingSkuAvailabilities.length > 1;

export const filterHasValues = (filters: ProductFilter[], name: string) => {
  if (!hasFilter(filters, name)) {
    return false;
  }
  const filter = getFilter(filters, name);
  return !!(filter && filter.values && filter.values.length);
};

export const showLocationDropdown = (filters: ProductFilter[]): boolean => {
  return filterHasValues(filters, 'Location');
};

export const showDurationDropdown = (
  filters: ProductFilter[],
  hydratedProduct: Product,
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  isLegacy: boolean
) => {
  const durationDropdownOptions = buildDurationDropdownOptions(
    filters,
    hydratedProduct,
    qualifyingSkuAvailabilities,
    isLegacy
  );
  return !!(durationDropdownOptions && durationDropdownOptions.length);
};

export const showTermsDropdown = (
  isSupportOffer: boolean,
  isUnfilterableProduct: boolean,
  terms: Term[]
) => !!(!isSupportOffer && isUnfilterableProduct && terms.length);

export const showWatermark = (
  filters: ProductFilter[],
  selectedLocation: string,
  selectedDuration: string,
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product,
  multipleLocationSelected?: boolean
) => {
  const locationIsAllLocations = selectedLocation.toUpperCase() === 'ALL LOCATIONS';
  const durationIsAllReservations = selectedDuration.toUpperCase() === 'ALL RESERVATIONS';
  const skurows = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    product
  );
  return (
    multipleLocationSelected ||
    (!isSoftware(product) && locationIsAllLocations && hasLocationFilter(filters)) ||
    (!isSoftware(product) && durationIsAllReservations && hasDurationFilter(filters)) ||
    !skurows.length
  );
};

export const showMessage = (
  filters: ProductFilter[],
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  hydratedProduct: Product,
  skuCount: number,
  selectedLocation: string,
  selectedDuration: string,
  selectedListKey?: string
) => {
  const isUnfilterable = isUnfilterableProduct(
    filters,
    qualifyingSkuAvailabilities,
    hydratedProduct
  );
  const isNegotiatedTerms = isNegotiatedTerm(hydratedProduct);
  const locationIsAllLocations = selectedLocation.toUpperCase() === 'ALL LOCATIONS';
  const durationNoPayGoNoReservations =
    selectedDuration.toUpperCase() !== 'PAY GO' &&
    selectedDuration.toUpperCase() !== 'ALL RESERVATIONS';
  return (
    isSupportOffer(hydratedProduct) ||
    (!isUnfilterable &&
      hasDurationFilter(filters) &&
      !locationIsAllLocations &&
      durationNoPayGoNoReservations &&
      !selectedListKey &&
      !!skuCount &&
      !isNegotiatedTerms)
  );
};

export const showAutoRenewOption = (qualifyingSkus: QualifyingSkuAvailability[]) => {
  const firstAvailabilities = !!qualifyingSkus.length && qualifyingSkus[0].Availabilities;
  const firstAvailability =
    firstAvailabilities && !!firstAvailabilities.length && firstAvailabilities[0];
  const firstTransferInstruction =
    firstAvailability &&
    firstAvailability.AssetsData &&
    firstAvailability.AssetsData.TransferInstructions &&
    !!firstAvailability.AssetsData.TransferInstructions.length &&
    firstAvailability.AssetsData.TransferInstructions[0];
  const firstTransferOption =
    firstTransferInstruction &&
    firstTransferInstruction.TransferOptions &&
    !!firstTransferInstruction.TransferOptions.length &&
    firstTransferInstruction.TransferOptions[0];
  return !!(
    firstTransferOption &&
    firstTransferOption.RenewalData &&
    firstTransferOption.RenewalData.IsAutorenewable
  );
};

export const hasQualifyingSkus = (
  qualifyingSkuAvailabilities?: QualifyingSkuAvailability[]
): boolean => {
  return !!(qualifyingSkuAvailabilities && qualifyingSkuAvailabilities.length);
};

export const hasTermComponents = (terms: Term[]) =>
  terms.some((term: Term) => !!term.TermComponents);

//#endregion

//#region getters

export const getFilter = (filters: ProductFilter[], name: string) =>
  filters.find((filter: ProductFilter) => filter.name.toUpperCase() === name.toUpperCase());

export const getActionFilter = (filters: ProductFilter[]) => getFilter(filters, 'Action');
export const getLocationFilter = (filters: ProductFilter[]) => getFilter(filters, 'Location');
export const getDurationFilter = (filters: ProductFilter[]) => getFilter(filters, 'Duration');

export const getTermDescription = (term: Term) => {
  const description = term.TermDescription;
  const descriptionParameters = term.TermDescriptionParameters;
  const parameter = (descriptionParameters && descriptionParameters[0].Parameter) || '';
  const value = (descriptionParameters && descriptionParameters[0].Value) || '';
  return descriptionParameters ? description.replace(parameter, value) : description;
};

export const getTermsForAvailability = (availability: Availability) => {
  const termsWithDescription: Term[] = [];
  const terms = availability.Terms;
  if (terms) {
    terms.forEach((term: Term) => {
      const description = getTermDescription(term);
      termsWithDescription.push({
        TermDescription: description,
        TermId: term.TermId,
        TermUnits: term.TermUnits,
        TermComponents: term.TermComponents,
      });
    });
  }
  return termsWithDescription;
};

export const maxSkuAvailabilitiesTermsLength = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product
) => {
  let availabilities: Availability[] = [];
  const filteredSkus = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    product
  );
  filteredSkus.forEach((qualifyingSkuAvailability: QualifyingSkuAvailability) => {
    availabilities = availabilities.concat(qualifyingSkuAvailability.Availabilities);
  });

  const terms = availabilities.map((availability: Availability) => {
    return availability && availability.Terms ? availability.Terms.length : 0;
  });
  return Math.max(Math.max.apply(null, terms), 0);
};

export const getTermsForAllAvailabilities = (
  qualifyingSkuAvailability: QualifyingSkuAvailability
) => {
  let termsForAllAvailabilities: Term[] = [];
  qualifyingSkuAvailability.Availabilities.forEach((qualifyingSkuAvailability: Availability) => {
    const termsForAvailability = getTermsForAvailability(qualifyingSkuAvailability);
    termsForAllAvailabilities = termsForAllAvailabilities.concat(termsForAvailability);
  });
  return termsForAllAvailabilities;
};

export const getTermsForAllQualifyingSkus = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product
) => {
  let termsForAllQualifyingSkus: Term[] = [];
  const filteredSkus = removePrivateAndNonPurchasableConsumableSkus(
    qualifyingSkuAvailabilities,
    product
  );
  filteredSkus.forEach((qualifyingSkuAvailability: QualifyingSkuAvailability) => {
    const terms = getTermsForAllAvailabilities(qualifyingSkuAvailability);
    termsForAllQualifyingSkus = termsForAllQualifyingSkus.concat(terms);
  });
  const uniqueTerms: Term[] = [];
  const ids = new Set();
  termsForAllQualifyingSkus.forEach((term: Term) => {
    if (!ids.has(term.TermId) && term.TermDescription.trim() !== '') {
      uniqueTerms.push(term);
      ids.add(term.TermId);
    }
  });
  return uniqueTerms;
};

export const productContainsBillingPlans = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  product: Product
) => {
  const terms = getTermsForAllQualifyingSkus(qualifyingSkuAvailabilities, product);
  return terms.some((term: Term) => {
    return (
      term.TermComponents &&
      term.TermComponents.some(
        termComponent => termComponent.Type.toLowerCase() === billingPlanType
      )
    );
  });
};

export const getAvailabilityForTermMeter = (availabilities: Availability[], meterType: string) => {
  const availability = availabilities.find(
    availability => oc(availability.Properties).MeterType('') === meterType
  );
  return availability;
};
export const getAvailabilityForTermId = (availabilities: Availability[], termId: string) => {
  const availability = availabilities.find(
    availability => availability.Terms && availability.Terms.some(term => term.TermId === termId)
  );
  return availability;
};

export const getFirstTermId = (availability: Availability) => {
  return availability.Terms && availability.Terms.length === 1 && availability.Terms[0].TermId;
};

export const getFirstTermIdFromFirstAvailability = (availabilities: Availability[]) => {
  return (
    !!availabilities.length &&
    availabilities[0].Terms &&
    !!availabilities[0].Terms.length &&
    availabilities[0].Terms[0].TermId
  );
};

export const getAvailabilityAndTermForColumnWhenMultipleAvailabilities = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  qualifyingSkuAvailability: QualifyingSkuAvailability,
  column: IColumn,
  product: Product
): { availabilityId?: string; termId?: string | false } => {
  if (maxSkuAvailabilitiesLength(qualifyingSkuAvailabilities, product) > 1) {
    let availability = getAvailabilityForTermMeter(
      qualifyingSkuAvailability.Availabilities,
      column.name
    );
    availability =
      availability ||
      getAvailabilityForTermId(qualifyingSkuAvailability.Availabilities, column.key);
    return {
      availabilityId: availability && availability.AvailabilityId,
      termId:
        availability && oc(availability).Terms.length(0) === 1
          ? getFirstTermId(availability)
          : column.key,
    };
  } else if (maxSkuAvailabilitiesTermsLength(qualifyingSkuAvailabilities, product) > 1) {
    return {
      termId: column.key,
    };
  }
  return {};
};

export const getPriceValue = (
  rowKey: string,
  prices: PriceMap,
  availabilityId?: string,
  termId?: string | false
) => {
  const alreadyHasAvailability = rowKey.split('/').length === 3;
  const keyWithAvailability =
    availabilityId && !alreadyHasAvailability ? `${rowKey}/${availabilityId}` : rowKey;
  const key = termId ? `${keyWithAvailability}/${termId}` : keyWithAvailability;
  const hasRowKey = !!prices[key];
  const hasPrice = hasRowKey && prices[key].price !== undefined;
  const isLoading = hasRowKey && prices[key].isLoading;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const value = hasPrice && prices[key].price!.toString();
  const placeholder = isLoading ? <ShimmerForBackgroundCommon width={50} /> : '-';
  return { value, placeholder };
};

export const getPriceFromSelection = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  prices: PriceMap,
  selectedListKey?: string,
  selectedTermsKey?: string
): string | undefined => {
  let matchedPrice;
  if (selectedListKey) {
    const sku = selectedListKey.split('/')[1];
    if (sku) {
      let allAvailabilities: Availability[] = [];
      qualifyingSkuAvailabilities.forEach(qualifyingSkuAvailability => {
        allAvailabilities = allAvailabilities.concat(qualifyingSkuAvailability.Availabilities);
      });
      let selectableAvailabilities: Availability[] = [];

      const matchingSkuAvailabilities = qualifyingSkuAvailabilities.filter(
        availability => availability.Sku.SkuId === sku
      );

      matchingSkuAvailabilities.forEach(qualifyingSkuAvailability => {
        const availabilityCandidates = qualifyingSkuAvailability.Availabilities;
        selectableAvailabilities = selectableAvailabilities.concat(availabilityCandidates);
      });
      let matchedAvailability;
      if (selectedTermsKey) {
        matchedAvailability = getAvailabilityForTermId(selectableAvailabilities, selectedTermsKey);
      }

      const availabilityId = matchedAvailability && matchedAvailability.AvailabilityId;
      const priceValue = getPriceValue(selectedListKey, prices, availabilityId, selectedTermsKey)
        .value;
      matchedPrice = priceValue || undefined;
    }
  }
  return matchedPrice;
};

export const getFirstPurchaseAvailabilities = (availabilities: Availability[]) => {
  const purchaseAvailabilities = availabilities.filter(
    avail => avail.Actions && avail.Actions.includes('Purchase')
  );
  return (purchaseAvailabilities.length && purchaseAvailabilities[0]) || undefined;
};

export const getTermUnits = (
  availabilityId: string,
  availabilities: Availability[],
  selectedTermsKey?: string
) => {
  const selectedAvailability = availabilities.find(
    avail => avail.AvailabilityId === availabilityId
  );
  if (selectedAvailability) {
    if (selectedTermsKey) {
      const selectedTerm =
        selectedAvailability.Terms &&
        selectedAvailability.Terms.find(term => term.TermId === selectedTermsKey);
      return selectedTerm && selectedTerm.TermUnits;
    }
    return (
      selectedAvailability.Terms &&
      selectedAvailability.Terms.length &&
      selectedAvailability.Terms[0].TermUnits
    );
  }
};

export const getActionValue = (productFilters: ProductFilter[], selectedDurationKey?: string) => {
  const actionFilter = hasActionFilter(productFilters);
  const durationFilter = hasDurationFilter(productFilters);
  if (actionFilter && durationFilter) {
    return selectedDurationKey && selectedDurationKey.toUpperCase() === 'PAY GO'
      ? 'consume'
      : 'purchase';
  } else {
    const isConsumable = isConsumableProduct(productFilters);
    const isPurchasable = isPurchasableProduct(productFilters);
    return isConsumable && !isPurchasable ? 'consume' : 'purchase';
  }
};

export const getLocationId = (qualifyingSkuAvailabilities: QualifyingSkuAvailability[]) => {
  return (
    (qualifyingSkuAvailabilities.length &&
      qualifyingSkuAvailabilities[0].Sku.Properties &&
      qualifyingSkuAvailabilities[0].Sku.Properties.LocationId) ||
    ''
  );
};

export const getLocationValue = (
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  locationId: string
) => {
  const qualifyingSkuAvailability = qualifyingSkuAvailabilities.find(
    qualifyingSkuAvailability =>
      !!qualifyingSkuAvailability.Sku.Properties &&
      qualifyingSkuAvailability.Sku.Properties.LocationId === locationId
  );
  return oc(qualifyingSkuAvailability).Sku.Properties.Location('');
};
//#endregion

//#region others
export const requestPriceForRows = (
  rows: SkuRow[],
  loadPrice: (proposalPricingRequest: ProposalPricingRequest) => void,
  productId: string,
  prices: PriceMap,
  lineItems: LineItem[],
  pricingContext: PricingContext,
  product: Product
) => {
  if (rows) {
    const productIdentifiers = createProductIdentifiersForAllRowsAvailability(
      rows,
      productId,
      prices,
      product
    );
    if (productIdentifiers.length) {
      const requestPayload = buildLoadPriceRequest(productIdentifiers, lineItems, pricingContext);
      loadPrice(requestPayload);
    }
  }
};

export const buildConditions = (
  productFilters: ProductFilter[],
  hydratedProduct: HydratedProduct,
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  selectedDurationKey?: string,
  selectedLocationKey?: string
) => {
  let conditions: PricingInstructionCondition[] = [];
  const hasDuration =
    hasDurationFilter(productFilters) &&
    selectedDurationKey &&
    selectedDurationKey.toUpperCase() !== 'PAY GO' &&
    selectedDurationKey.toUpperCase() !== 'ALL RESERVATIONS';
  const hasLocation =
    hasLocationFilter(productFilters) &&
    selectedLocationKey &&
    selectedLocationKey.toUpperCase() !== 'ALL LOCATIONS';
  const hasTerm = hasTermsAsDuration(productFilters, hydratedProduct) && selectedDurationKey;
  const isTerm = isNegotiatedTerm(hydratedProduct);
  if (!isTerm) {
    if (hasDuration || hasTerm) {
      conditions = conditions.concat({
        type: 'equalAny',
        name: 'TermUnits',
        value: [
          hasDuration
            ? oc(qualifyingSkuAvailabilities[0]).Availabilities[0].Terms[0].TermUnits('')
            : oc(selectedDurationKey)(''),
        ],
      });
    }
    if (hasLocation) {
      const locationId = getLocationId(qualifyingSkuAvailabilities);
      conditions = conditions.concat({
        type: 'equalAny',
        name: 'LocationId',
        value: [locationId],
      });
    }
  }
  return conditions;
  //TODO: add conditions to existing line item conditions in state
};
//#endregion

//#region ProductFilter specific stuff
export const filterOnLocations = (
  productFilterContext: ProductFilterContext,
  selectedLocation?: string,
  allowUnspecifiedOptionInLocationFilters?: boolean
) => {
  const filters = productFilterContext.productFilters;
  if (hasLocationFilter(filters)) {
    if (allowUnspecifiedOptionInLocationFilters && selectedLocation === '') {
      productFilterContext.setFilterSelectedValues('Location', ['']);
    } else if (!selectedLocation || selectedLocation.toUpperCase() === 'ALL LOCATIONS') {
      productFilterContext.setFilterSelectedValues('Location', []);
    } else {
      productFilterContext.setFilterSelectedValues('Location', [selectedLocation]);
    }
  }
};

export const filterOnDurations = (
  productFilterContext: ProductFilterContext,
  hydratedProduct: HydratedProduct,
  selectedDuration: string
) => {
  const filters = productFilterContext.productFilters;
  if (
    hasDurationFilter(filters) &&
    productFilterContext.productFilters.some(filter => filter.name.toUpperCase() === 'ACTION')
  ) {
    if (!hasTermsAsDuration(filters, hydratedProduct)) {
      if (selectedDuration.toUpperCase() === 'PAY GO') {
        productFilterContext.setFilterSelectedValues('Duration', []);
        productFilterContext.setFilterSelectedValues('Action', ['Consume']);
      } else if (selectedDuration.toUpperCase() === 'ALL RESERVATIONS') {
        productFilterContext.setFilterSelectedValues('Duration', []);
        productFilterContext.setFilterSelectedValues('Action', ['Purchase']);
      } else {
        productFilterContext.setFilterSelectedValues('Duration', [selectedDuration]);
        productFilterContext.setFilterSelectedValues('Action', ['Purchase']);
      }
    } else {
      productFilterContext.setFilterSelectedValues('TermDescription', [selectedDuration]);
    }
  }
};
//#endregion

//#region Apply logic
// need to reset sku properly
export const applyAccessPassConfiguration = (
  lineItem: LineItem,
  proposal: Proposal,
  updateProposal: (request: ProposalUpdateRequest) => void,
  currency: Currency,
  selectedListKey: string,
  recurringCheckEnabled?: boolean,
  selectedSkuRow?: SkuRow
) => {
  const productId: string[] = selectedListKey.split('/');
  const isValid = productId.length === 3;
  if (isValid) {
    const updatedLineItem: LineItem = {
      ...lineItem,
      productIdentifier: {
        ...lineItem.productIdentifier,
        productId: productId[0],
        skuId: productId[1],
        availabilityId: productId[2],
        action: 'purchase',
      },
      pricingCurrency: currency,
      isReadyForPricing: true,
      purchaseInstruction: { ...lineItem.purchaseInstruction, isAutoRenew: recurringCheckEnabled },
      extendedProperties: {
        userPreferences: {
          ...(lineItem.extendedProperties && lineItem.extendedProperties.userPreferences),
          skuTitle: (selectedSkuRow && selectedSkuRow.Title) || undefined,
        },
      },
    };
    const request = createProposalUpdateRequest(updatedLineItem, proposal);
    updateProposal(request);
  } else {
    loggerService.error({ error: new Error('invalid product identifier') });
  }
};

export const hasAvailabilityConsumeAction = (availability: Availability) => {
  return availability.Actions.includes('Consume') && !availability.Actions.includes('Purchase');
};

export const applyUnfilterableConfiguration = (
  hydratedProduct: HydratedProduct,
  lineItem: LineItem,
  proposal: Proposal,
  selectedSkuRow: SkuRow,
  updateProposal: (request: ProposalUpdateRequest) => void,
  currency: Currency,
  selectedListKey: string,
  selectedTermsKey?: string,
  selectedTermsDuration?: string,
  recurringCheckEnabled?: boolean,
  isANegotiatedTerm?: boolean
) => {
  let action = 'purchase';
  const productId: string[] = selectedListKey.split('/');
  const isValid = productId.length >= 2;
  const availabilityForTerm =
    selectedTermsKey && getAvailabilityForTermId(selectedSkuRow.Availabilities, selectedTermsKey);
  let availabilityId =
    selectedSkuRow.Availabilities.length === 1 && productId.length >= 3
      ? productId[2]
      : availabilityForTerm && availabilityForTerm.AvailabilityId;
  let conditions: PricingInstructionCondition[] = [];
  if (selectedSkuRow.Availabilities && selectedSkuRow.Availabilities.length && !availabilityId) {
    const purchaseAvailability = getFirstPurchaseAvailabilities(selectedSkuRow.Availabilities);
    availabilityId = purchaseAvailability && purchaseAvailability.AvailabilityId;
  }
  if (availabilityId) {
    const units = getTermUnits(availabilityId, selectedSkuRow.Availabilities, selectedTermsKey);
    if (units && !isSupportOffer(hydratedProduct)) {
      const condition: PricingInstructionCondition = {
        type: 'equalAny',
        name: 'TermUnits',
        value: [units],
      };
      conditions = [condition];
    }
    const availability =
      selectedSkuRow.Availabilities &&
      selectedSkuRow.Availabilities.find(avail => avail.AvailabilityId === availabilityId);
    action = availability && hasAvailabilityConsumeAction(availability) ? 'consume' : 'purchase';
  }

  if (isValid) {
    const updatedLineItem: LineItem = {
      ...lineItem,
      supplementalTermReferenceData: isANegotiatedTerm
        ? `${lineItem.productIdentifier.productType}:${selectedSkuRow.Title}`
        : undefined,
      productIdentifier: {
        ...lineItem.productIdentifier,
        productId: productId[0],
        skuId: productId[1],
        availabilityId,
        availabilityTermId: selectedTermsKey || undefined,
        action,
      },
      pricingCurrency: currency,
      isReadyForPricing: true,
      purchaseInstruction: { ...lineItem.purchaseInstruction, isAutoRenew: recurringCheckEnabled },
      extendedProperties: {
        ...lineItem.extendedProperties,
        userPreferences: {
          ...(lineItem.extendedProperties && lineItem.extendedProperties.userPreferences),
          duration: undefined,
          location: undefined,
          term: selectedTermsKey,
          termDurationKey: selectedTermsDuration,
          skuTitle: selectedSkuRow.Title || undefined,
          conditions: conditions.length ? conditions : undefined,
          availabilityTermId: selectedTermsKey,
        },
      },
    };
    const request = createProposalUpdateRequest(updatedLineItem, proposal);
    updateProposal(request);
  } else {
    loggerService.error({ error: new Error('invalid product identifier') });
  }
};

export const applySkuLevelConfiguration = (
  lineItem: LineItem,
  proposal: Proposal,
  action: string,
  selectedListKey: string,
  selectedSkuRow: SkuRow,
  updateProposal: (request: ProposalUpdateRequest) => void,
  currency: Currency,
  recurringCheckEnabled?: boolean,
  selectedDuration?: string,
  selectedLocation?: string,
  selectedTerm?: string,
  selectedTermDuration?: string
) => {
  //sku level
  const productId: string[] = selectedListKey.split('/');
  const isValid = productId.length >= 2;
  let availabilityId = productId.length >= 3 ? productId[2] : undefined;
  let termId;
  if (selectedSkuRow.Availabilities.length === 1 && availabilityId === undefined) {
    availabilityId = selectedSkuRow.Availabilities[0].AvailabilityId;
  }
  if (
    selectedSkuRow.Availabilities.length &&
    selectedSkuRow.Availabilities[0].Terms &&
    selectedSkuRow.Availabilities[0].Terms.length === 1
  ) {
    termId = selectedSkuRow.Availabilities[0].Terms[0].TermId;
  }
  if (isValid) {
    const updatedLineItem: LineItem = {
      ...lineItem,
      productIdentifier: {
        ...lineItem.productIdentifier,
        productId: productId[0],
        skuId: productId[1],
        availabilityId,
        availabilityTermId: termId,
        action,
      },
      pricingCurrency: currency,
      isReadyForPricing: !!availabilityId && action !== 'consume',
      purchaseInstruction: {
        ...lineItem.purchaseInstruction,
        isAutoRenew: recurringCheckEnabled,
      },
      extendedProperties: {
        ...lineItem.extendedProperties,
        userPreferences: {
          ...oc(lineItem).extendedProperties.userPreferences(),
          duration: selectedDuration,
          location: selectedLocation,
          term: selectedTerm,
          termDurationKey: selectedTermDuration,
          skuTitle: selectedSkuRow.Title || undefined,
          conditions: undefined,
        },
      },
    };

    const request = createProposalUpdateRequest(updatedLineItem, proposal);
    updateProposal(request);
  } else {
    loggerService.error({ error: new Error('invalid product identifier') });
  }
};

export const applyFilterableConfiguration = (
  hydratedProduct: HydratedProduct,
  productFilters: ProductFilter[],
  lineItem: LineItem,
  lineItems: CreateLineItemProperties[],
  proposal: Proposal,
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  updateProposal: (request: ProposalUpdateRequest) => void,
  createLineItemsFromMultiRegionalSelection: (request: UpdateAndCreateLineItemsRequest) => void,
  currency: Currency,
  selectedDurationKey?: string,
  selectedLocationKey?: string,
  selectedListKey?: string,
  selectedSkuRow?: SkuRow,
  recurringCheckEnabled?: boolean,
  selectedTermKey?: string,
  selectedTermsDuration?: string
) => {
  const action = getActionValue(productFilters, selectedDurationKey);
  if (selectedListKey && selectedSkuRow) {
    //sku level
    return applySkuLevelConfiguration(
      lineItem,
      proposal,
      action,
      selectedListKey,
      selectedSkuRow,
      updateProposal,
      currency,
      recurringCheckEnabled,
      selectedDurationKey,
      selectedLocationKey,
      selectedTermKey,
      selectedTermsDuration
    );
  } else {
    const conditions = buildConditions(
      productFilters,
      hydratedProduct,
      qualifyingSkuAvailabilities,
      selectedDurationKey,
      selectedLocationKey
    );
    const productId = hydratedProduct.ProductId;
    const qualifyingSkuCount = removePrivateAndNonPurchasableConsumableSkus(
      qualifyingSkuAvailabilities,
      hydratedProduct as Product
    );
    const skuCount =
      qualifyingSkuCount && qualifyingSkuCount.length > 1 ? qualifyingSkuCount.length : undefined;
    const updatedLineItem: LineItem = {
      ...lineItem,
      productIdentifier: {
        ...lineItem.productIdentifier,
        productId,
        skuId: undefined,
        availabilityId: undefined,
        action,
      },
      pricingCurrency: currency,
      purchaseInstruction: {
        ...lineItem.purchaseInstruction,
        isAutoRenew: recurringCheckEnabled,
      },
      extendedProperties: {
        ...lineItem.extendedProperties,
        userPreferences: {
          ...oc(lineItem).extendedProperties.userPreferences(),
          duration: selectedDurationKey,
          location: selectedLocationKey,
          skuCount,
          term: undefined,
          termDurationKey: undefined,
          skuTitle: undefined,
          conditions: conditions.length ? conditions : undefined,
        },
      },
    };
    const request = updateAndCreateLineItemsRequest(
      proposal,
      lineItems,
      updatedLineItem,
      hydratedProduct
    );
    createLineItemsFromMultiRegionalSelection(request);
  }
};

export const hasDiscount = (lineItem: LineItem) => {
  return oc(lineItem).pricingInstruction.ruleType();
};

export const isValidSelectedTerm = (skuRow: SkuRow, selectedTerm?: string) => {
  return !!(selectedTerm && getAvailabilityForTermId(skuRow.Availabilities, selectedTerm));
};

export const findMatchedBillingPlanNameFromTermid = (terms: Term[], termId: string) => {
  const term = terms.find(term => term.TermId === termId);
  const termComponents = term && term.TermComponents;
  if (termComponents) {
    const billingPlan = termComponents.find(
      (termComponent: TermComponent) => termComponent.Type.toLowerCase() === billingPlanType
    );
    return billingPlan && billingPlan.Properties.Title;
  }
};

export const applyConfiguration = (
  filters: ProductFilter[],
  qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
  hydratedProduct: Product,
  lineItem: LineItem,
  createLineItems: CreateLineItemProperties[],
  proposal: Proposal,
  createLineItemsFromMultiRegionalSelection: (request: UpdateAndCreateLineItemsRequest) => void,
  updateProposal: (proposalRequest: ProposalUpdateRequest) => void,
  selectedDurationKey?: string,
  selectedLocationKey?: string,
  selectedTermsKey?: string,
  selectedTermsDuration?: string,
  selectedStartDateKey?: string,
  selectedListKey?: string,
  selectedSkuRow?: SkuRow,
  recurringCheckEnabled?: boolean
) => {
  const isUnfilterable = isUnfilterableProduct(
    filters,
    qualifyingSkuAvailabilities,
    hydratedProduct
  );
  const currency = oc(proposal).header.pricingContext.billingCurrency('USD');
  const previouslyDiscounted = hasDiscount(lineItem);
  let startDate = undefined;
  if (selectedStartDateKey && isPasses(hydratedProduct)) {
    if (selectedStartDateKey !== 'order placement') {
      startDate = moment.utc(selectedStartDateKey).toDate();
    }
    lineItem.purchaseInstruction = { ...lineItem.purchaseInstruction, termStartDate: startDate };
  }
  const newLineItem: LineItem = {
    ...lineItem,
    startDate: !previouslyDiscounted ? lineItem.startDate : undefined,
    endDate: !previouslyDiscounted ? lineItem.endDate : undefined,
    duration: !previouslyDiscounted ? lineItem.duration : undefined,
    pricingInstruction: !previouslyDiscounted ? lineItem.pricingInstruction : undefined,
  };

  const createLineItemsWithUpdatedDuration = createLineItems.map(createLineItem => ({
    location: createLineItem.location,
    duration: selectedDurationKey,
  }));
  if (isAccessPass(hydratedProduct)) {
    selectedListKey &&
      applyAccessPassConfiguration(
        newLineItem,
        proposal,
        updateProposal,
        currency,
        selectedListKey,
        undefined,
        selectedSkuRow
      );
  } else if (isUnfilterable) {
    const isANegotiatedTerm = isNegotiatedTerm(hydratedProduct);
    selectedSkuRow &&
      selectedListKey &&
      applyUnfilterableConfiguration(
        hydratedProduct,
        newLineItem,
        proposal,
        selectedSkuRow,
        updateProposal,
        currency,
        selectedListKey,
        selectedTermsKey || selectedDurationKey,
        selectedTermsDuration,
        recurringCheckEnabled,
        isANegotiatedTerm
      );
  } else {
    applyFilterableConfiguration(
      hydratedProduct,
      filters,
      newLineItem,
      createLineItemsWithUpdatedDuration,
      proposal,
      qualifyingSkuAvailabilities,
      updateProposal,
      createLineItemsFromMultiRegionalSelection,
      currency,
      selectedDurationKey,
      selectedLocationKey,
      selectedListKey,
      selectedSkuRow,
      recurringCheckEnabled,
      selectedTermsKey,
      selectedTermsDuration
    );
  }
};
//#endregion
