import { ThemeProps } from 'styles';

export const invoicingLanguagStyles = (theme: ThemeProps) => ({
  secondaryText: {
    color: theme.palette.textTertiary,
  },
  textSpacer: {
    padding: 8,
  },
  comboBoxStyles: {
    width: 402,
    paddingTop: 24,
  },
});
