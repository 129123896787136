import { SecondaryButton } from 'components/ions';
import * as actions from 'features/proposal/actions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

interface ExtendButtonProps {
  quoteId: string;
}

const dispatchProps = {
  extendQuote: (quoteId: string) => actions.withdrawQuote(quoteId),
};

export type Props = ExtendButtonProps & typeof dispatchProps;

const PureExtendButton: React.FC<Props> = props => {
  const { t } = useTranslation();

  const onClick = () => {
    props.extendQuote(props.quoteId);
  };

  return (
    <SecondaryButton
      ariaLabel={t('quote::Extend expiration')}
      dataAutomationId="extendExpirationButton"
      iconName="DateTime"
      id="extend-button"
      text={t('quote::Extend expiration')}
      title={t('quote::Extends the "Good until" date on the quote')}
      onClick={onClick}
    />
  );
};

export const ExtendButton = connect(undefined, dispatchProps)(PureExtendButton);
