import { ActionBar, Row, TextBodySmall, TextWatermark } from 'components/ions';
import { EventTrap } from 'components/utilities';
import { DetailsPane } from 'features-apollo/quote/components';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { useClearSelectionOnUnmount } from 'hooks';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { QuoteListColumn } from '../types';
import { quoteListColumnHeadersStyle, quoteListStyles as styles } from './QuoteList.styles';
import { QuoteListDnD } from './QuoteListDnD/QuoteListDnD';

//#region Column Header
interface ColumnHeaderProps {
  columnsHeaders: QuoteListColumn[];
}

type ColumnHeadersType = ColumnHeaderProps & WithStyles<typeof quoteListColumnHeadersStyle>;

const ColumnHeadersUnStyled: React.FC<ColumnHeadersType> = props => {
  return (
    <div className={props.classes.container}>
      {props.columnsHeaders.map(header => (
        <div
          className={props.classes.column}
          key={header.id}
          style={{ display: 'flex', flex: header.flex || 1, justifyContent: header.alignText }}
        >
          <TextBodySmall>{header.content}</TextBodySmall>
        </div>
      ))}
    </div>
  );
};

const ColumnHeaders = withStyles(quoteListColumnHeadersStyle)(ColumnHeadersUnStyled) as React.FC<
  ColumnHeaderProps
>;
//#endregion

export interface QuoteListProps {
  /**
  Whether any configuration card is open, using to avoid scrolling the list when it's open
   */
  configurationCardIsOpen: boolean;
  /**
   * List of labels for the column headers.
   */
  columnsHeaders: QuoteListColumn[];
  /**
   * List of buttons props to add on action bar.
   */
  actionBarButtons?: JSX.Element[];
  /**
   * Displays a fixed amount of rows in loading state.
   */
  loading?: boolean;
  /**
   * Hides the action bar for quote assigned to partners.
   */
  isQuoteHRDD: boolean;
  /**
   * Disables drag-and-drop feature to prevent adding product to a read-only quote.
   */
  isQuoteReadOnly: boolean;
  /**
   * Watermark to show when list is empty.
   */
  watermark?: string | string[];
  /**
   * Overrides the condition to show the watermark
   */
  showWatermark?: boolean;
  /**
   * Content displayed at the bottom of the list
   */
  footer?: React.ReactNode;
  /**
   * Enables drag-and-drop feature on list.
   */
  dndEnabled?: boolean;
  /**
   * Detects if drag event is happening.
   * TODO: jepagan - check if this can be detect independently from list component.
   */
  isDragEvent?: boolean;
  /**
   * Resets selection when component unmounts.
   * TODO: jepagan - check if this can be move to a custom hook.
   */
  onClearSelection: () => void;
}

type Props = QuoteListProps & WithStyles<typeof styles>;

export const QuoteListUnstyled: React.FC<Props> = props => {
  const { classes, onClearSelection, isDragEvent } = props;
  useClearSelectionOnUnmount(onClearSelection);
  useHelpContent(HelpContent.QuoteEditorProducts);
  const rowsContainerRef = React.useRef<HTMLDivElement>(null);

  const actionBar =
    !props.isQuoteHRDD && props.actionBarButtons && props.actionBarButtons.length ? (
      <ActionBar id="quote-list-action-bar">{props.actionBarButtons}</ActionBar>
    ) : null;

  const columnHeaders = props.columnsHeaders.length ? (
    <ColumnHeaders columnsHeaders={props.columnsHeaders} />
  ) : null;

  const watermark =
    props.watermark && (!props.children || props.showWatermark) ? (
      <div className={classes.watermarkContainer}>
        {typeof props.watermark === 'string' ? (
          <TextWatermark addClass={classes.watermarkText}>{props.watermark}</TextWatermark>
        ) : (
          props.watermark.map((message, index) => (
            <TextWatermark addClass={classes.watermarkText} key={index}>
              {message}
            </TextWatermark>
          ))
        )}
      </div>
    ) : null;

  const list = (
    <EventTrap fullWidth={true}>
      <div className={classes.listWrapper} role="grid">
        <div className={classes.list}>
          {actionBar}
          {columnHeaders}
          <div className={classes.listBody} ref={rowsContainerRef}>
            {props.loading ? (
              <>
                <Row loading />
                <Row loading />
                <Row loading />
                <Row loading />
                <Row loading />
                <Row loading />
              </>
            ) : (
              <>
                {watermark}
                {props.children}
              </>
            )}
          </div>
          {props.footer}
        </div>
        <div className={classes.detailsPane}>
          <DetailsPane />
        </div>
      </div>
    </EventTrap>
  );

  if (props.dndEnabled && !(props.isQuoteHRDD || props.isQuoteReadOnly)) {
    return (
      <QuoteListDnD
        configurationCardIsOpen={props.configurationCardIsOpen}
        disabled={props.loading}
        isDragEvent={isDragEvent}
        isEmptyList={!props.children}
        rowsContainerRef={rowsContainerRef}
      >
        {list}
      </QuoteListDnD>
    );
  } else {
    return list;
  }
};

/**
 * UI representation of line items in the quote.
 */
export const QuoteList = withStyles(styles)(QuoteListUnstyled) as React.FC<QuoteListProps>;
