import { Dropdown } from 'components/ions/Dropdown';
import { IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

export interface ModernOfficeFallbackDropdownProps {
  termDescriptionOptions: IDropdownOption[];
  onTermDescriptionSelection: (termId: string) => void;
  readOnly?: boolean;
  termId?: string;
}

const styles = {
  termDescriptionDropdown: {
    marginBottom: 10,
  },
};

type Props = ModernOfficeFallbackDropdownProps & WithStyles<typeof styles>;

const ModernOfficeFallbackDropdownUnstyled: React.FC<Props> = (props: Props) => {
  const { termId, termDescriptionOptions, classes, onTermDescriptionSelection, readOnly } = props;

  const TermDescriptionDropdown = (
    <div className={classes.termDescriptionDropdown}>
      <Dropdown
        data-automation-id="termDescription"
        disabled={readOnly}
        options={termDescriptionOptions}
        selectedKey={termId}
        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
          if (option) {
            onTermDescriptionSelection(option.key.toString());
          }
        }}
      />
    </div>
  );

  return TermDescriptionDropdown;
};

export const ModernOfficeFallbackDropdown = withStyles(styles)(
  ModernOfficeFallbackDropdownUnstyled
);
