import { ButtonAtom } from 'components/atoms';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { rejectButtonStyles } from './RejectButton.styles';

type Props = ButtonSharedProps & WithStyles<typeof rejectButtonStyles>;

const RejectButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <ButtonAtom
      addClass={props.classes.reject}
      ariaLabel={props.ariaLabel || props.text}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: 'Cancel' }}
      id={props.id}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const RejectButton = withStyles(rejectButtonStyles)(RejectButtonUnstyled) as React.FC<
  ButtonSharedProps
>;
