import { CatalogAction, Condition } from 'generated/graphql';
import i18next from 'i18next';

const createLocationString = (locations: string[]) => {
  if (locations.length < 5) {
    return locations.join(', ');
  }
  return i18next.t('quote::{{length}} locations', { length: locations.length });
};

export const getTermString = (action: CatalogAction, termDescriptionCondition?: Condition) => {
  if (termDescriptionCondition && termDescriptionCondition.values.length) {
    return termDescriptionCondition.values[0];
  }
  return action === CatalogAction.Consume
    ? i18next.t('quote::Pay go')
    : i18next.t('quote::All reservations');
};

const getLocationString = (locationCondition?: Condition) => {
  if (locationCondition && locationCondition.values && locationCondition.values.length) {
    return createLocationString(locationCondition.values);
  }
  return i18next.t('quote::All locations');
};

export const getSummaryTextForMultipleSkus = (action: CatalogAction, conditions: Condition[]) => {
  const termDescriptionCondition = conditions.find(
    condition => condition.name === 'TermDescription' && condition.type === 'equalAny'
  );
  const locationCondition = conditions.find(
    condition => condition.name === 'Location' && condition.type === 'equalAny'
  );
  return `${getLocationString(locationCondition)} / ${getTermString(
    action,
    termDescriptionCondition
  )}`;
};
