import { ApolloQueryResult } from 'apollo-client';
import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextNavigationSecondarySelected,
  TextTitle,
  XLargeIcon,
} from 'components';
import { DeleteReferral } from 'features/proposal/components/ReferralSummaryList/Queries';
import { Referral, ReferralType } from 'generated/graphql';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { sleepCreator } from 'services/utils';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { SortQuery } from '../../ReferralSummaryList/ReferralSummaryList';
import { DialogLayout, DialogStatusBody, DialogStatusType } from '../Shared';
import { deleteReferralDialogStyles } from './DeleteReferralDialog.styles';

enum DialogState {
  Request = 'Request',
  Processing = 'Processing',
  Success = 'Success',
  Fail = 'Fail',
}

export interface DeleteReferralDialogProps {
  id?: string;
  referral?: Referral;
  getReferralsVariables: {
    query: string;
    options: { referralFilter: string; sort: SortQuery };
  };
  refetchReferrals: (
    variables?: Record<string, any> | undefined
  ) => Promise<
    ApolloQueryResult<{
      getReferrals: Referral[];
    }>
  >;
}

type Props = DeleteReferralDialogProps & WithStyles<typeof deleteReferralDialogStyles>;

const DeleteReferralDialogUnstyled: React.FC<Props> = ({
  classes,
  id,
  referral,
  refetchReferrals,
  getReferralsVariables,
}: Props) => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  const [dialogState, setDialogState] = React.useState(DialogState.Request);

  const [deleteReferralGQL] = useMutation(DeleteReferral, {
    onCompleted: async () => {
      const sleep = sleepCreator(1000);
      await sleep();
      refetchReferrals({ variables: getReferralsVariables });
      setDialogState(DialogState.Success);
    },
    onError: () => {
      setDialogState(DialogState.Fail);
    },
  });

  const closeDialog = () => {
    context.closeDialog();
  };

  const handleDeleteReferral = () => {
    setDialogState(DialogState.Processing);
    deleteReferralGQL({
      variables: {
        input: {
          id: oc(referral).id(''),
          etag: oc(referral).etag(''),
        },
      },
    });
  };

  const referralName: string = oc(referral).name('');
  const isClaim = oc(referral).referralType() === ReferralType.Claim;
  const type = isClaim ? t('quote::claim') : t('quote::referral');
  const requestLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        dataAutomationId="deleteReferral"
        dialogClose={closeDialog}
        headerClass={classes.customDialogHeader}
      >
        <TextTitle>{t('quote::Delete {{type}}?', { type })}</TextTitle>
      </DialogHeader>
    ),
    body: (
      <div className={classes.customDialogBody}>
        <Trans ns="common">
          <TextBody dataAutomationId={`delete-${type}-body`}>
            Deleting the {{ type }},{' '}
            <TextNavigationSecondarySelected>{{ referralName }}</TextNavigationSecondarySelected>,
            will permanently remove it from Quote Center and it won't be able to be retrieved.
          </TextBody>
        </Trans>
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <PrimaryButton
          autoFocus
          dataAutomationId="referralDeleteButton"
          text={t('quote::Delete')}
          onClick={handleDeleteReferral}
        />
        <SecondaryButton
          dataAutomationId="referralDeleteCancelButton"
          text={t('quote::Cancel')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  const processingLayout: DialogLayout = {
    body: (
      <div className={classes.customDialogContainer}>
        <DialogStatusBody
          dataAutomationId="processingDeleteReferral"
          status={DialogStatusType.Processing}
          text={t('quote::Deleting {{type}}...', { type })}
        />
      </div>
    ),
  };

  const successLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        closeButtonContainerClass={classes.closeButtonNoTitle}
        dataAutomationId="successDeleteReferral"
        dialogClose={closeDialog}
      />
    ),
    body: (
      <div className={classes.customDialogContainer}>
        <DialogStatusBody
          dataAutomationId="successDeleteReferralBody"
          status={DialogStatusType.Success}
          text={t('quote::The {{type}} has been deleted.', { type })}
        />
      </div>
    ),
    footer: (
      <div className={classes.errorFooter}>
        <PrimaryButton
          dataAutomationId="successDeleteReferralCloseButton"
          text={t('quote::Close')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  const errorLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        closeButtonContainerClass={classes.closeButtonNoTitle}
        dataAutomationId="failedDeleteReferral"
        dialogClose={closeDialog}
      />
    ),
    body: (
      <div className={classes.customDialogContainer}>
        <div className={`${classes.iconContainer} ${classes.customDialogFailIcon}`}>
          <XLargeIcon addClass={classes.customDialogIcon} iconName="Cancel" />
        </div>
        <div>
          <TextBody addClass={classes.text}>
            {t('quote::Failed to delete {{type}}. Please try again.', { type })}
          </TextBody>
        </div>
      </div>
    ),
    footer: (
      <div className={classes.errorFooter}>
        <PrimaryButton
          dataAutomationId="referralDeleteTryAgainButton"
          text={t('quote::Try again')}
          onClick={handleDeleteReferral}
        />
      </div>
    ),
  };

  const getLayout = () => {
    switch (dialogState) {
      case DialogState.Processing:
        return processingLayout;
      case DialogState.Success:
        return successLayout;
      case DialogState.Fail:
        return errorLayout;
      default:
        return requestLayout;
    }
  };

  const currentLayout = getLayout();

  return (
    <div>
      <CustomDialogBox
        bodySlot={currentLayout.body}
        footerSlot={currentLayout.footer}
        headerSlot={currentLayout.header}
        height={264}
        id={id}
        width={428}
      />
    </div>
  );
};

export const DeleteReferralDialog = withStyles(deleteReferralDialogStyles)(
  DeleteReferralDialogUnstyled
);
