import { styles } from './AgreementUploadConfigurationCard.styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DirectionalHint } from 'office-ui-fabric-react';
import { TextBodyAsterisk, CalloutCalendar, CalloutCard, TextBody } from 'components';
import { calendarStrings } from 'components/utilities/calendarStrings';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { connect } from 'react-redux';
import * as actions from 'features/customer/actions';
import * as selectors from 'features/customer/selectors';
import { CreateOrUpdateModernAgreement } from 'features-apollo/quote/components/Lists/queries';
import { useMutation } from '@apollo/react-hooks';
import { AgreementTermLineItem } from 'features-apollo/quote/components/Lists/Rows/AgreementTermRow';
import { UploadFile, UploadActionType, UploadAction } from 'features-apollo/components';
import { AgreementTypes } from 'generated/graphql';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { RootState } from 'store/types';

export interface AgreementUploadConfigurationCardProps {
  agreement: AgreementTermLineItem;
  quoteId: string;
  target: React.RefObject<HTMLSpanElement>;
  onDismiss: () => void;
  readOnly?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    loadingUploadAgreement: selectors.processingUploadAgreement(state).loading,
    errorUploadAgreement: selectors.processingUploadAgreement(state).error,
    finishedUpload: state.customer.finishedUpload,
  };
};

const dispatchProps = {
  uploadFile: actions.uploadDocumentsAsync.request,
  uploadDocumentsStateReset: actions.uploadDocumentsStateReset,
};

type Props = AgreementUploadConfigurationCardProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  WithStyles<typeof styles>;

const AgreementUploadConfigurationCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    agreement,
    quoteId,
    readOnly,
    target,
    onDismiss,
    finishedUpload,
    loadingUploadAgreement,
    errorUploadAgreement,
    uploadDocumentsStateReset,
    uploadFile,
    classes,
  } = props;
  const { t } = useTranslation();

  const agreementId = agreement.id ? agreement.id.substring(agreement.id.indexOf('-') + 1) : '';

  const { refetch } = React.useContext(ActiveQuoteContext);
  const [createOrUpdateModernAgreement, { data, loading, error }] = useMutation(
    CreateOrUpdateModernAgreement
  );

  // #region - local states
  const reducer = (state: UploadAction, action: UploadAction) => {
    switch (action.type) {
      case UploadActionType.SET_DROP_DEPTH:
        return { ...state, dropDepth: action.dropDepth };
      case UploadActionType.SET_IN_DROP_ZONE:
        return { ...state, inDropZone: action.inDropZone };
      case UploadActionType.ADD_FILE_TO_LIST:
        return {
          ...state,
          fileName: action.fileName,
          fileURI: action.fileURI,
          fileType: action.fileType,
          formData: action.formData,
        };
      case UploadActionType.DELETE_FILE_FROM_LIST: {
        return {
          ...state,
          fileName: undefined,
          fileURI: undefined,
          fileType: undefined,
          formData: undefined,
        };
      }
      default:
        return state;
    }
  };

  const initialFileName = (agreement && agreement.documentFileName) || undefined;
  const fileType = initialFileName
    ? initialFileName.includes('pdf')
      ? 'application/pdf'
      : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    : undefined;

  const [uploadData, dispatch] = React.useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileName: initialFileName,
    fileURI: agreement.documentDisplayUri || undefined,
    fileType,
    formData: new FormData(),
  });

  const [startDate, setStartDate] = React.useState<Date | undefined | null>(
    agreement && agreement.startDate
  );
  const [goodUntil, setGoodUntil] = React.useState<Date | undefined | null>(
    agreement && agreement.endDate
  );

  React.useEffect(() => {
    if (data && data.createOrUpdateModernAgreement) {
      uploadFile({
        agreementNumber: data.createOrUpdateModernAgreement.number,
        documentType: 'signed',
        customerId: data.createOrUpdateModernAgreement.customerId,
        fileName: uploadData.fileName || '',
        formData: uploadData.formData || new FormData(),
        contentType: uploadData.fileType || 'application/pdf',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  React.useEffect(() => {
    if (finishedUpload) {
      refetch && refetch({ id: quoteId });
      uploadDocumentsStateReset();
      if (!loading && !loadingUploadAgreement && !errorUploadAgreement && !error) {
        onDismiss();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedUpload, loading, loadingUploadAgreement, errorUploadAgreement, error]);
  //#endregion

  const headerSubText =
    agreement.documentFileName || uploadData.fileName
      ? t('quote::Uploaded document:')
      : t(
          'quote::Upload the signed agreement document naming both the partner and customer. The agreement can be a Word document or a PDF and must be less than 5 MB.'
        );

  const getGoodUntilText = () => {
    if (goodUntil) {
      const date = convertDateToFormattedString(goodUntil, LocaleDateFormat.ll);
      const isEvergreen = date === 'Dec 31, 9999';
      return isEvergreen ? 'Evergreen' : date;
    } else {
      return t('quote::select date...');
    }
  };

  const getDefaultDate = (currentDate: Date | undefined | null) => {
    if (currentDate) {
      const date = convertDateToFormattedString(currentDate, LocaleDateFormat.ll);
      const isEvergreen = date === 'Dec 31, 9999';
      return isEvergreen ? new Date() : new Date(currentDate);
    } else {
      return new Date();
    }
  };

  const content = (
    <div className={classes.content}>
      <UploadFile
        data={uploadData}
        dispatch={dispatch}
        enableFileSelection={true}
        fileTypeInclusionList={[
          {
            type: 'application/pdf',
            userInteraction: 'Download',
          },
          {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            userInteraction: agreement.documentDisplayUri ? 'View' : 'Download',
          },
        ]}
        height={62}
        id="AgreementUploadFile"
        readOnly={readOnly || !!agreement.documentDisplayUri}
        width={506}
      />
      <div className={classes.dates}>
        <div className={classes.dateContainer}>
          <TextBodyAsterisk addClass={classes.dateLabel}>{t('quote::Start date')}</TextBodyAsterisk>
          <div className={startDate ? classes.calendar : classes.calendarNoDate}>
            <CalloutCalendar
              buttonProps={{
                text: startDate
                  ? convertDateToFormattedString(startDate, LocaleDateFormat.ll)
                  : t('quote::select date...'),
                disabled: readOnly,
              }}
              calendarStrings={calendarStrings}
              dataAutomationId="AgreementUploadCardStartDate"
              defaultDate={getDefaultDate(startDate)}
              isDayPickerVisible
              isMinDateUnset={true}
              showTodayButton={true}
              today={new Date()}
              onSelectDate={(date: Date) => {
                setStartDate(date);
              }}
            />
          </div>
        </div>
        <div className={classes.dateContainer}>
          <TextBodyAsterisk addClass={classes.dateLabel} required>
            {t('quote::Good until')}
          </TextBodyAsterisk>
          <div className={goodUntil ? classes.calendar : classes.calendarNoDate}>
            <CalloutCalendar
              buttonProps={{
                text: getGoodUntilText(),
                disabled: readOnly,
              }}
              calendarStrings={calendarStrings}
              dataAutomationId="AgreementUploadCardStartDate"
              defaultDate={getDefaultDate(goodUntil)}
              isDayPickerVisible
              showEvergreen={true}
              showTodayButton={true}
              today={new Date()}
              onSelectDate={(date: Date) => {
                setGoodUntil(date);
              }}
              onSelectEvergreen={() => {
                setGoodUntil(new Date('9999-12-31T00:00:00'));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const errorMessage = (
    <TextBody addClass={classes.errorMessage}>
      {t('quote::We were unable to add the agreement, please retry or try again later.')}
    </TextBody>
  );

  return (
    <CalloutCard
      applyButtonDisabled={!(startDate && goodUntil && uploadData.fileName) || readOnly}
      applyButtonStrings={{ text: t('quote::Apply'), ariaLabel: t('quote::Apply') }}
      applyError={errorUploadAgreement || error}
      applyLoading={loading || loadingUploadAgreement}
      closeButtonAriaLabel={t('quote::Close')}
      directionalHint={DirectionalHint.rightCenter}
      errorMessage={errorMessage}
      headerSubText={headerSubText}
      headerText={t('quote::Upload agreement')}
      id="agreementUploadConfigurationCard"
      isBeakVisible={true}
      isReadOnly={false}
      maxWidth={548}
      target={target}
      onApply={() => {
        createOrUpdateModernAgreement({
          variables: {
            agreementId,
            input: {
              quoteId,
              agreementType: AgreementTypes.Special,
              startDate,
              endDate: goodUntil,
              participants: agreement.participants,
            },
          },
        });
      }}
      onDismiss={onDismiss}
    >
      {content}
    </CalloutCard>
  );
};

export const AgreementUploadConfigurationCardUnconnected = withStyles(styles)(
  AgreementUploadConfigurationCardUnstyled
);

export const AgreementUploadConfigurationCard = connect(
  mapStateToProps,
  dispatchProps
)(AgreementUploadConfigurationCardUnconnected);
