import { QuotePublishWithdrawFragment } from 'features/proposal/fragments';
import gql from 'graphql-tag';

export const PublishQuoteMutation = gql`
  mutation PublishQuoteMutation($input: QuoteMutationInput!) {
    oldPublishQuote(input: $input) {
      ...quotePublishWithdrawFragment
    }
  }
  ${QuotePublishWithdrawFragment}
`;

export const WithdrawQuoteMutation = gql`
  mutation WithdrawQuoteMutation($input: QuoteMutationInput!) {
    oldWithdrawQuote(input: $input) {
      ...quotePublishWithdrawFragment
    }
  }
  ${QuotePublishWithdrawFragment}
`;
