import { ThemeProps } from 'styles/theme';

export const customerListRowStyles = (theme: ThemeProps) => {
  const { fonts, palette } = theme;
  return {
    inlineProperty: {
      display: 'inline',
    },
    rowItem: {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    accountName: {
      color: palette.textLink,
      fontSize: fonts.fontSizes.medium,
      fontWeight: fonts.fontWeights.regular,
    },
    externalLink: {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  };
};
