import { VerificationField } from 'components';
import { Dialog, PrimaryButton, TextBody, TextBodySmall } from 'components/ions';
import { Dimensions } from 'features/components/dialogs';
import { getSalesAccountFromCrmId } from 'features/customer/selectors';
import { verifyCrmId } from 'features/proposal/actions';
import {
  getActiveProposal,
  getCrmLeadType,
  isCRMIdMarketValid,
  processingVerifyCrmIdFromDialog,
} from 'features/proposal/selectors';
import { crmIDRegExp, hasCRMId } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  salesAccount: {
    color: theme.palette.textPrimaryDisabled,
  },
  spacing: {
    paddingTop: 16,
  },
  sectionRow: { display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden' },
});

interface OwnProps extends Dimensions {
  onAssociateClick: (crmId: string, quoteId: string) => void;
}

const mapStateToProps = (state: RootState) => {
  const quote = getActiveProposal(state);

  return {
    crmIdAssociated: hasCRMId(quote),
    processingVerifyCrmId: processingVerifyCrmIdFromDialog(state),
    quoteId: quote.id,
    isCRMIdMarketValid: isCRMIdMarketValid(state),
    salesAccount: (crmId: string) => getSalesAccountFromCrmId(state, crmId),
    getCrmLeadType: (crmId: string) => getCrmLeadType(state, crmId),
  };
};

const dispatchProps = {
  verifyCrmId: verifyCrmId.request,
};

export type SelectOpportunityViewProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

type Props = SelectOpportunityViewProps & WithStyles<typeof styles>;
const SelectOpportunityViewUnStyled: React.FC<Props> = props => {
  const [lastCrmIdVerified, setLastCrmIdVerified] = React.useState<string | undefined>();
  const [userInput, setUserInput] = React.useState<string | undefined>();
  const { t } = useTranslation();
  const { classes } = props;

  const context = React.useContext(DialogContext);
  const crmLeadType = lastCrmIdVerified ? props.getCrmLeadType(lastCrmIdVerified) : undefined;
  const salesAccount = lastCrmIdVerified ? props.salesAccount(lastCrmIdVerified) : undefined;

  let errorCrmMessage: string | undefined;

  if (!props.processingVerifyCrmId.loading) {
    if (props.processingVerifyCrmId.error) {
      errorCrmMessage = t('quote::We encountered a problem while verifying.');
    }
    // Sales account undefined
    else if (props.isCRMIdMarketValid === undefined) {
      errorCrmMessage = t('quote::CRM ID not found');
    } else if (props.isCRMIdMarketValid === false) {
      errorCrmMessage = t(
        'quote::The customer account associated with the CRM ID is in an unsupported market. You must either select a different account for this customer or sell to them using a different sales motion.'
      );
    }
  }

  const displayInformation = userInput === lastCrmIdVerified && !errorCrmMessage;

  // Verifies if address is defined to show both information simultaneously and avoid visual delay
  const label =
    displayInformation && salesAccount && crmLeadType
      ? t('quote::CRM ID ({{crmLeadType}})', { crmLeadType })
      : t('quote::CRM ID');

  const salesAccountName = displayInformation && salesAccount && (
    <div className={classes.spacing}>
      <TextBodySmall addClass={`${classes.salesAccount} ${classes.sectionRow}`}>
        {t('quote::Sales account')}
      </TextBodySmall>
      <TextBody addClass={classes.spacing}>{salesAccount.Address.companyName}</TextBody>
    </div>
  );

  const onChange = (value?: string) => {
    setUserInput(value);
  };

  const onVerifyCrmId = (crmId: string) => {
    setLastCrmIdVerified(crmId);
    props.verifyCrmId(crmId);
  };

  const associateCrmId = () => {
    lastCrmIdVerified && props.onAssociateClick(lastCrmIdVerified, props.quoteId);
  };

  const button = (
    <PrimaryButton
      dataAutomationId="crmIdAssociateButton"
      disabled={!salesAccount || !props.isCRMIdMarketValid}
      text={t('quote::Associate')}
      onClick={associateCrmId}
    />
  );

  if (props.crmIdAssociated) context.closeDialog();

  return (
    <Dialog
      dataAutomationId="selectOpportunityDialog"
      footerButtons={button}
      height={props.height}
      title={t('quote::Enter opportunity or success engagement')}
      width={props.width}
    >
      <TextBody>
        {t('quote::All quotes must be associated with an opportunity or a success engagement.')}
      </TextBody>
      <VerificationField
        buttonText={t('quote::Verify')}
        containerClassName={classes.spacing}
        dataAutomationId="crmIdVerification"
        errorMessage={errorCrmMessage}
        isError={props.processingVerifyCrmId.error || !props.isCRMIdMarketValid}
        isLoading={
          props.processingVerifyCrmId.loading && !salesAccount && !props.processingVerifyCrmId.error
        }
        lastVerified={lastCrmIdVerified}
        textboxLabel={label}
        textboxPlaceholder={t('quote::Enter an opportunity or a success engagement ID')}
        validationErrorMessage={t('quote::Enter a valid CRM ID')}
        onChangeDebounced={onChange}
        onValidate={(value: string) => crmIDRegExp.test(value)}
        onVerify={onVerifyCrmId}
      />
      {salesAccountName}
    </Dialog>
  );
};

export const SelectOpportunityViewStyled = withStyles(styles)(
  SelectOpportunityViewUnStyled
) as React.FC<SelectOpportunityViewProps>;
export const SelectOpportunityView = connect(
  mapStateToProps,
  dispatchProps
)(SelectOpportunityViewStyled);
