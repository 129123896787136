import { Row, TextBodySmall } from 'components/ions';
import { useDeleteLineItems } from 'features-apollo/quote/components/Lists/hooks';
import { QuoteListColumn, ShimmerRow } from 'features-apollo/quote/components/Lists/types';
import { LineItem } from 'generated/graphql';
import { KeyCodes } from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { AgreementTermLineItem } from '../AgreementTermRow';
import { quoteListRowStyles } from './QuoteListRow.styles';

export interface SelectionProps {
  /**
   * Identifies the quote of the line items
   *
   * @type {string}
   * @memberof SelectionProps
   */

  quoteId: string;
  /**
   * Line item id
   */
  lineItemId: string;
  /**
   * List of all item ids in the list
   */
  itemIds: string[];
  /**
   * Represents the last item selected in the list
   */
  lastSelectedId?: string;
  /**
   * List of all items currently selected on the list
   */
  selectedIds: string[];
  /**
   * Removes all selections on the list
   */
  onCleared: () => void;
  /**
   * Sets the list of items currently selected and the last item selected on the list
   */
  onSelected: (obj: { multi: boolean; ids: string[]; lastSelectedId?: string }) => void;
}

export type QuoteListRowProps = {
  lineItem: LineItem | AgreementTermLineItem | ShimmerRow;
  /**
   * Displays columns on the top part of the row
   */
  topColumns: QuoteListColumn[];
  /**
   * Optional columns that display on the bottom part of the row
   */
  bottomColumns: QuoteListColumn[];
  /**
   * For testing purposes
   */
  dataAutomationId?: string;
  /**
   * Text that gets displays at the bottom of the row. The style will depend on the row state.
   */
  message?: string;
  /**
   * Sets the row style as error
   */
  isInErrorState?: boolean;
  /**
   * Sets the row style as warning
   */
  isInWarnState?: boolean;
  /**
   * Sets the row style as selected
   */
  isSelected?: boolean;
  /**
   * Displays loading shimmer
   */
  isLoading?: boolean;
} & SelectionProps;

type Props = QuoteListRowProps & WithStyles<typeof quoteListRowStyles>;

/**
 * Accepts a mouse click of keyboard space/enter key event with/without modifiers
 * and changes selected lineItems
 */
function selectionEventHandler(
  event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
  currentSelectedId: string,
  onSelected: (obj: { multi: boolean; ids: string[]; lastSelectedId?: string }) => void,
  onCleared: () => void,
  allLineItemIds: string[],
  lastSelectedId?: string
) {
  if (event.ctrlKey) {
    onSelected({ multi: true, ids: [currentSelectedId] });
    return;
  } else if (event.shiftKey && lastSelectedId) {
    let from = allLineItemIds.indexOf(lastSelectedId);
    let to = allLineItemIds.indexOf(currentSelectedId);
    let selectedRangeOfIds = [];
    if (from !== undefined && to !== undefined) {
      if (from < to) {
        while (from !== to + 1) {
          selectedRangeOfIds.push(allLineItemIds[from]);
          from++;
        }
      } else if (from > to) {
        while (from !== to - 1) {
          selectedRangeOfIds.push(allLineItemIds[from]);
          from--;
        }
      } else {
        onCleared();
        selectedRangeOfIds.push(currentSelectedId);
      }
    }
    selectedRangeOfIds.length && onSelected({ multi: true, ids: selectedRangeOfIds });
    return;
  }

  onSelected({ multi: false, ids: [currentSelectedId] });
  return;
}

const renderColumn = (column: QuoteListColumn, isBottom?: boolean) => (
  <div
    data-automation-id={column.id}
    key={column.id}
    style={{
      display: 'flex',
      flex: column.flex || 1,
      justifyContent: column.alignText,
      minWidth: isBottom ? 'unset' : 0,
    }}
  >
    {column.content}
  </div>
);

export const QuoteListRowUnStyled: React.FC<Props> = props => {
  const {
    classes,
    onCleared,
    lineItemId,
    selectedIds,
    itemIds,
    lastSelectedId,
    onSelected,
  } = props;
  const selected = selectedIds || [];
  const rowRef = React.useRef<HTMLDivElement>(null);

  const deleteLineItems = useDeleteLineItems(props.quoteId, props.selectedIds);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    let targetElement = event.target as HTMLElement;
    let selectFrom = selected.includes(targetElement.id) ? lastSelectedId : targetElement.id;
    const keyCode = event.keyCode;

    /**
     * Sets focus on row and selects item if shift key was also pressed
     */
    const selectRow = () => {
      targetElement.focus();
      if (event.shiftKey) {
        selectionEventHandler(event, targetElement.id, onSelected, onCleared, itemIds, selectFrom);
      }
    };

    switch (keyCode) {
      /**
       * Navigates to previous row
       */
      case KeyCodes.up:
        event.preventDefault();
        targetElement = rowRef.current as HTMLElement;
        if (targetElement.previousElementSibling) {
          targetElement = targetElement.previousElementSibling as HTMLElement;
        }
        selectRow();
        break;
      /**
       * Navigates to next row
       */
      case KeyCodes.down: {
        event.preventDefault();
        targetElement = rowRef.current as HTMLElement;
        if (targetElement.nextElementSibling) {
          targetElement = targetElement.nextElementSibling as HTMLElement;
        }
        selectRow();
        break;
      }
      /**
       * Selects all rows on the list
       */
      case KeyCodes.a: {
        if (event.ctrlKey) {
          event.preventDefault();
          onSelected({ multi: true, ids: itemIds });
        }
        break;
      }
      /**
       * Clears all selected rows
       */
      case KeyCodes.escape: {
        onCleared();
        break;
      }
      /**
       * Selects current row.
       */
      case KeyCodes.space:
      case KeyCodes.enter: {
        // When key event comes from row content, all other selections are clear.
        if (targetElement.id !== lineItemId) {
          onCleared();
        }
        selectionEventHandler(event, lineItemId, onSelected, onCleared, itemIds, lastSelectedId);
        break;
      }
      /**
       * Deletes selected rows
       */
      case KeyCodes.del: {
        if (itemIds.length && selected.length) {
          deleteLineItems();
        }
        break;
      }
    }
  };

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = event.currentTarget as HTMLElement;
    if (event.shiftKey) event.preventDefault();
    selectionEventHandler(event, lineItemId, onSelected, onCleared, itemIds, lastSelectedId);
    targetElement && targetElement.focus();
    return;
  };

  const topContent = props.topColumns.length ? (
    <div className={classes.rowContent}>
      {props.topColumns.map(topColumn => renderColumn(topColumn))}
    </div>
  ) : null;

  const bottomContent = props.bottomColumns.length ? (
    <div className={classes.rowContent} style={{ paddingTop: 8 }}>
      {props.bottomColumns.map(bottomColumn => renderColumn(bottomColumn, true))}
    </div>
  ) : null;

  if (props.isLoading) {
    return (
      <div
        className={classes.loadingRow}
        data-automation-id={props.dataAutomationId}
        id={lineItemId}
        key={lineItemId}
        ref={rowRef}
        role="button"
        tabIndex={0}
      >
        <Row loading />
      </div>
    );
  } else {
    return (
      <div
        className={classes.row}
        data-automation-id={props.dataAutomationId}
        id={lineItemId}
        key={lineItemId}
        ref={rowRef}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onMouseDown={handleOnClick}
      >
        <Row className={classes.rowContainer} loading={props.isLoading}>
          {props.isLoading ? null : (
            <>
              {topContent}
              {bottomContent}
              <div className={classes.lineItemMessage}>
                <TextBodySmall addClass={classes.messageText}>{props.message}</TextBodySmall>
              </div>
            </>
          )}
        </Row>
      </div>
    );
  }
};

/**
 * Reusable component that constains the determines overall row states, content layout and selection feature.
 */
export const QuoteListRow = withStyles(quoteListRowStyles)(QuoteListRowUnStyled) as React.FC<
  QuoteListRowProps
>;
