import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import { sharedStyles } from 'features/proposal/shared.styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { eTagMissmatchCode, quoteIsNotSubmitableCode } from 'services/approval/utils';
import { ApprovalLevel, NegotiationReason, SubmittingProposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { RefreshDialog } from '../RefreshDialog';
import { Success } from '../Shared';
import { JustificationView } from './JustificationView';
import { SelectApproversView } from './SelectApproversView';
import { ApprovalStep, SelectedApprovers } from './types';
import { UnsavedCommentView } from './UnsavedCommentView';

// Props
export interface OwnProps {
  id?: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  quoteSubmitProcessing: selectors.quoteSubmitProcessing(state),
  commentSaveProcessing: selectors.approvalActionProcessing(state),
  id: props.id,
  proposal: selectors.getActiveProposal(state),
  hasAnyDiscount: selectors.hasAnyDiscount(state),
});

const dispatchProps = {
  loadMissingEmpowerments: (quoteId: string) => actions.loadMissingEmpowerments(quoteId),
  submitProposal: (request: SubmittingProposal) =>
    actions.submitProposalActionAsync.request(request),
};

type StateProps = OwnProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;
type Props = StateProps & WithStyles<typeof sharedStyles>;

const dimensions = {
  height: 475,
  width: 554,
};

const SubmitProposalDialogUnstyled: React.FC<Props> = ({
  loadMissingEmpowerments,
  quoteSubmitProcessing,
  commentSaveProcessing,
  proposal,
  ...props
}: Props) => {
  // Load any missing empowerments
  React.useEffect(() => {
    loadMissingEmpowerments(proposal.id);
  }, [proposal.id, loadMissingEmpowerments]);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState<ApprovalStep>(ApprovalStep.Justification);
  const [explanation, setExplanation] = React.useState<string>('');
  const [justification, setJustification] = React.useState<NegotiationReason>(
    NegotiationReason.Unknown
  );
  const [selectedApprovers, setSelectedApprovers] = React.useState<SelectedApprovers>({});
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const submitProposal = (selectedApprovers: SelectedApprovers) => {
    // Retrieve all selected approvers
    let approvalLevels: ApprovalLevel[] = [];
    for (let key in selectedApprovers) {
      const [, policy, empowermentLevel] = key.split('::');
      const approvers = selectedApprovers[key].emails.map(email => {
        return {
          emailAddress: email,
        };
      });
      const entry: ApprovalLevel = {
        approvers: approvers,
        level: empowermentLevel,
        order: selectedApprovers[key].order,
        policy,
      };
      approvalLevels.push(entry);
    }

    // Set request
    const request: SubmittingProposal = {
      approvalLevels,
      proposal,
      negotiationReason: justification,
    };

    // Add comment to request if available
    if (explanation) {
      request.comments = explanation;
    }

    props.submitProposal(request);
    setCurrentStep(ApprovalStep.Processing);
  };
  switch (currentStep) {
    case ApprovalStep.Justification: {
      const onJustificationNext = (justification: NegotiationReason, explanation?: string) => {
        if (explanation) setExplanation(explanation);
        if (justification) setJustification(justification);
        setCurrentStep(ApprovalStep.SelectApprover);
      };

      return (
        <JustificationView
          {...dimensions}
          closeDialog={closeDialog}
          defaultExplanation={explanation}
          defaultJustification={justification}
          hasAnyDiscount={props.hasAnyDiscount}
          title={t('quote::Submit for approval')}
          onNextClick={onJustificationNext}
        />
      );
    }
    case ApprovalStep.ReadOnlyJustification: {
      return (
        <JustificationView
          {...dimensions}
          closeDialog={closeDialog}
          defaultExplanation={explanation}
          defaultJustification={justification}
          hideButtons={true}
          title={t('quote::Copy justification')}
        />
      );
    }
    case ApprovalStep.Processing: {
      if (quoteSubmitProcessing.error) {
        const errorCode = oc(quoteSubmitProcessing).error.exception.response.data.details[0].code();
        const errorStatus412 = errorCode === eTagMissmatchCode;
        if (errorCode === quoteIsNotSubmitableCode) {
          const actionText = t('quote::Submit for approval');
          return <RefreshDialog action={actionText} {...dimensions} is412={false} />;
        }
        if (errorStatus412) {
          const actionText = t('quote::Submit for approval');
          return <RefreshDialog action={actionText} {...dimensions} is412={true} />;
        }
        return (
          <Fail
            {...dimensions}
            closeDialog={closeDialog}
            dataAutomationId="submitForApprovalFail"
            message={t('quote::Sorry, the "Submit for approval" action failed.')}
            onTryAgainClick={() => submitProposal(selectedApprovers)}
          />
        );
      } else if (quoteSubmitProcessing.loading || commentSaveProcessing.loading) {
        return <Processing {...dimensions} message1={t('quote::The quote is being submitted.')} />;
      } else if (commentSaveProcessing.error) {
        const onShowExplanation = () => {
          setCurrentStep(ApprovalStep.ReadOnlyJustification);
        };
        return (
          <UnsavedCommentView
            {...dimensions}
            closeDialog={closeDialog}
            onShowExplanation={onShowExplanation}
          />
        );
      } else {
        return (
          <Success
            {...dimensions}
            closeDialog={closeDialog}
            dataAutomationId="submitForApprovalSuccess"
            message={t('quote::The quote has successfully been submitted for approval.')}
          />
        );
      }
    }
    case ApprovalStep.SelectApprover:
    default: {
      const onSelectedApproversBack = (selectedApprovers: SelectedApprovers) => {
        setSelectedApprovers(selectedApprovers);
        setCurrentStep(ApprovalStep.Justification);
      };

      const onSubmitForApproval = (selectedApprovers: SelectedApprovers) => {
        setSelectedApprovers(selectedApprovers);
        submitProposal(selectedApprovers);
      };
      return (
        <SelectApproversView
          {...dimensions}
          closeDialog={closeDialog}
          selectedApprovers={selectedApprovers}
          onBackClick={onSelectedApproversBack}
          onSubmitClick={onSubmitForApproval}
        />
      );
    }
  }
};

export const SubmitProposalDialogStyled = withStyles(sharedStyles)(
  SubmitProposalDialogUnstyled
) as React.FC<StateProps>;
export const SubmitProposalDialog = connect(
  mapStateToProps,
  dispatchProps
)(SubmitProposalDialogStyled);
