import gql from 'graphql-tag';

/**
 * Query to get term line items from quote
 */
export const ApplicableDiscountsFragment = gql`
  fragment applicableDiscountsFragment on ApplicableDiscounts {
    __typename
    types
    minStartDate
    datePrecision
  }
`;

export const DateOrDurationFragment = gql`
  fragment dateOrDurationFragment on DateOrDuration {
    __typename
    ... on StartEndDates {
      startDate
      endDate
    }
    ... on Duration {
      duration
    }
  }
`;

export const DiscountFragment = gql`
  fragment discountFragment on DiscountSimple {
    __typename
    percentage
    type
    priceGuaranteeDate
    priceAdjustmentType
    duration {
      ...dateOrDurationFragment
    }
    scope {
      name
      type
      values
    }
  }
  ${DateOrDurationFragment}
`;
