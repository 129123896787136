export interface Address {
  address_line1: string;
  address_line2?: string;
  address_line3?: string;
  city?: string;
  country: string;
  postal_code?: string;
  region?: string;
}

export interface SuggestedAddress {
  address_line1?: string;
  address_line2?: string;
  address_line3?: string;
  city?: string;
  country: string;
  postal_code?: string;
  region?: string;
  latitude: string;
  longitude: string;
}

export interface AddressValidationResponse {
  suggested_address?: SuggestedAddress;
  original_address: Address;
  status: VerificationStatus;
  validation_message?: string;
}

export enum VerificationStatus {
  VerifiedShippable = 'VerifiedShippable',
  Verified = 'Verified',
  InteractionRequired = 'InteractionRequired',
  StreetPartial = 'StreetPartial',
  PremisesPartial = 'PremisesPartial',
  Multiple = 'Multiple',
  None = 'None',
  NotValidated = 'NotValidated',
}

export enum ValidationMode {
  LightWeight = 'LightWeight',
  Full = 'Full',
}
