import { CatalogPageNegotiatedTerm } from 'features/catalog';
import { Market } from 'features/proposal/supported-markets';
import { Language } from 'features/proposal/supported-languages';

import { FieldsTemplate } from './config';

export interface ProductResult {
  products: Product[];
  productKey: string;
  failed: string[];
  checkForSellableBy?: boolean;
}

export interface NegotiatedTerm {
  LastModifiedDate: string;
  LocalizedProperties: NegotiatedTermLocalizedProperty[];
  MarketProperties: NegotiatedTermMarketProperty[];
  ProductASchema: string;
  ProductBSchema: string;
  ProductId: string;
  Properties: {
    IsChannelEligible: boolean;
    IsMicrosoftProduct: boolean;
    IsTestProduct: boolean;
    ProductFamily: ProductFamily;
    StartCondition: string;
    MaxQuantityOnProposal: number;
    Duration: string;
    FulfillBeforeChargeEligible: boolean;
    RevisionId: string;
  };
  AlternateIds: string[];
  DomainDataVersion?: string;
  IngestionSource: string;
  isMicrosoftProduct: boolean;
  ProductType: string;
  ProductFamily: ProductFamily;
  SchemaVersion: string;
  ProductKind: string;
  DisplaySkuAvailabilities: DisplaySkuAvailability[];
}

export interface NegotiatedTermResponse {
  ProductIds: string[];
  Agregations: any[];
  HasMorePages: boolean;
  Products: CatalogPageNegotiatedTerm[];
  TotalResultCount: number;
}

export interface OrderManagementData {
  GrantedEntitlementKeys: string[];
  PIFilter: {
    ExclusionProperties: string[];
    InclusionProperties: string[];
  };
  Price: {
    CurrencyCode: string;
    IsPIRequired: boolean;
    ListPrice: number;
    MSRP: number;
    TaxType: string;
    WholesaleCurrencyCode: string;
    CurrencyConversionRequiredBy: string;
  };
  RevenueSkuSubstitute: string;
  RefundPolicyId?: string;
  FinancialInstructionsCardId?: string;
}

export interface Availability {
  Actions: string[];
  AssetsData?: AssetsData;
  AvailabilityASchema: string;
  AvailabilityBSchema: string;
  AvailabilityId: string;
  Conditions: Condition;
  LastModifiedDate: string;
  Markets: string[];
  OrderManagementData?: OrderManagementData;
  Properties: Property;
  SkuId: string;
  AlternateIds: string[];
  PricingRuleIds: string[];
  RemediationRequired?: boolean;
  DisplayRank: number;
  Terms?: Term[];
  ConsumptionUnitType?: string;
}

export interface AssetsData {
  TransferInstructions?: ClientTransferInstruction[];
}

export interface CatalogError {
  code: string;
  data?: string[];
  details?: string[];
  message: string;
  source?: string;
  innerError?: {
    code: string;
    data: string[];
    details: string[];
    message: string;
  };
}
export interface Condition {
  EndDate: string;
  StartDate: string;
  IsPermanent: boolean;
  ResourceSetIds: string[];
}
export interface DisplaySkuAvailability {
  Sku: Sku;
  Availabilities: Availability[];
}
export interface Duration {
  UnitType: string;
  Units: number;
}
export interface Filters {
  fieldsTemplate: FieldsTemplate;
}

export enum ImagePurpose {
  smallTile = 'smallTile',
  mediumTile = 'mediumTile',
}

export interface Image {
  Uri: string;
  ImagePurpose: ImagePurpose;
  Height: number;
  Width: number;
  BackgroundColor: string;
}
export interface ImageObj {
  Hero: Image;
  Logo: Image;
  Screenshots: Image;
  Tile: Image | Image[];
}
// added ImageObjc and typeguard since catalog is inconsistent in its return type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isImageObj(arg: any): arg is ImageObj {
  return arg && arg.Logo;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isImageArray(arg: any): arg is Image[] {
  return Array.isArray(arg);
}
export interface RelatedProduct {
  RelatedProductId: string;
  RelationshipType: string;
}
export interface MarketProperty {
  SupportedLanguages?: string[];
  Markets: Market[];
  RatingContextId?: string;
  RelatedProducts?: RelatedProduct[];
}

export interface UsageData {
  AverageRating: number;
  AggregateTimeSpan: string;
  RatingCount: number;
  PurchaseCount: string;
  TrialCount: string;
  RentalCount: string;
  PlayCount: number;
}
export interface NegotiatedTermMarketProperty {
  SupportedLanguages?: string[];
  Markets: Market[];
  UsageData: UsageData[];
}

export interface LocalizedProperty {
  Images: Image[] | ImageObj;
  PublisherName: string;
  PublisherWebsiteUri?: string;
  BaseProduct?: string;
  Language: string;
  ProductDescription: string;
  StrippedDescription: string;
  ProductTitle: string;
  Markets: Market[];
  MeterTypeDescriptions?: {
    Key: string;
    Value: {
      MeterType: string;
      UnitOfMeasure: string;
    };
  }[];
}

export interface NegotiatedTermLocalizedProperty {
  Images: Image[] | ImageObj;
  PublisherName: string;
  PublisherWebsiteUri?: string;
  BaseProduct?: string;
  Language: string;
  ProductDescription: string;
  StrippedDescription: string;
  ProductTitle: string;
  ShortDescription: string;
  ShortTitle: string;
  SupportUri: string;
  VoiceTitle: string;
  Markets: Market[];
}

export interface SkuLocalizedProperty {
  SkuTitle: string;
  SkuDescription: string;
  Markets: string[];
  Language: string;
  MeterTypeDescriptions?: {
    Key: string;
    Value: {
      MeterDescription: string;
      MeterName: string;
    };
  }[];
}

export enum ProductAttributeKey {
  AdditionalCategories = 'AdditionalCategories',
  AppVersion = 'AppVersion',
  Categories = 'Categories',
  Documents = 'Documents',
  IndustryCategories = 'IndustryCategories',
  MPNId = 'MPNId',
  ProductTags = 'ProductTags',
  SearchKeywords = 'SearchKeywords',
  SubmissionVersion = 'SubmissionVersion',
}

export interface ProductAttributeValue {
  ExternalId: string;
  Name: string;
}

export interface ProductAttribute {
  Key: ProductAttributeKey;
  Value: ProductAttributeValue[];
}

export interface ProductFragment {
  DisplaySkuAvailabilities: DisplaySkuAvailability[];
  LocalizedProperties: LocalizedProperty[];
  MarketProperties: MarketProperty[];
  ProductBSchema: string;
  ProductFamily: string;
  ProductId: string;
  ProductKind: string;
  ProductType?: string;
}

export interface ProductReasonCodes {
  Channels: string[];
  ReasonCode: string;
  ReasonName: string;
}

export interface ProductAmount {
  Min?: number;
  Max?: number;
}

export interface Product extends ProductFragment {
  LastModifiedDate: string;
  ProductASchema: string;
  AlternateIds: string[];
  IngestionSource: string;
  IsMicrosoftProduct: boolean;
  IsTestProduct?: boolean;
  ProductType: string;
  SchemaVersion: string;
  Properties: {
    Amount?: ProductAmount;
    Attributes?: ProductAttribute[];
    Duration?: string | string[] | undefined;
    IsChannelEligible?: boolean;
    IsMicrosoftProduct?: boolean;
    IsTestProduct?: boolean;
    MaxQuantityOnProposal?: number;
    TermId?: string;
    SupplementalTermsNeeded?: string[];
    ProductFieldsDescription?: {
      FieldName: string;
      IsBrowseDimension: boolean;
      IsDiscountDimension: boolean;
      Ordinal: number;
      ValueQueryMetadata: {
        Type: string;
        JPath?: string;
        AllowedValues?: string[];
      };
    }[];
    ReasonCodes?: ProductReasonCodes[];
    RevisionId?: string;
    ProductOwnershipSellingMotion?: string;
    IsDiscountable?: boolean;
    Series?: string;
    ProductShortName?: string;
    IsOpenSource?: string;
    IsPremium?: string;
    HasPriceGuarantee?: string;
    Service?: string;
    ServiceFamily?: string;
    ServiceId?: string;
    StartCondition?: string;
    ProductType?: string;
  };
}
export interface Products {
  Products: Product[];
}

export interface SeatConstraint {
  MaxSeats: number;
  MinSeats: number;
  Type: string;
}

export interface ConstraintsData {
  SeatConstraints?: SeatConstraint[];
  PrerequisiteSkus: {
    MustHaveAny: {
      ProductId: string;
    }[];
  };
}
export interface Property {
  LastUpdateDate?: string;
  IsTrial?: boolean;
  IsPreOrder?: boolean;
  IsBundle?: boolean;
  BillOnPurchase?: boolean;
  SkuDisplayRank?: number;
  MeterType?: string;
  Location?: string;
  LocationId?: string;
  LocationType?: string;
  MinOrderQuantity?: number;
  MaxOrderQuantity?: number;
  MinimumPurchaseQuantity?: number;
  MaximumPurchaseQuantity?: number;
  VisibleToB2BServiceIds?: string[];
  AdditionalIdentifiers?: string[];
  DurableId?: string;
  OfferType?: string;
  ConstraintsData?: ConstraintsData;
  ClientTransferInstructions?: ClientTransferInstruction[];
  TransferInstructions?: TransferInstruction[];
}
export interface RecurrencePolicy {
  IsRecurring: boolean;
  HasTrial: boolean;
  InitialDuration: Duration;
  Duration: Duration;
}
export interface Sku {
  LastModifiedDate: string;
  LocalizedProperties: SkuLocalizedProperty[];
  MarketProperties: MarketProperty[];
  ProductId: string;
  Properties: Property;
  SkuASchema: string;
  SkuBSchema: string;
  SkuId: string;
  SkuType: string;
  RecurrencePolicy: RecurrencePolicy | null;
  SubscriptionPolicyId?: null;
  MaxOrderQuantity?: number;
  MinOrderQuantity?: number;
  MinimumPurchaseQuantity?: number;
  MaximumPurchaseQuantity?: number;
  Duration?: Duration;
}

//TODO: consolidate all these types in the file with the ones in ConfigCard, don't think we need both
export interface TermComponent {
  Type: string;
  Properties: {
    BillingPeriod?: string;
    Description: string;
    Title: string;
    Duration?: string;
  };
}

export interface Term {
  TermId: string;
  TermUnits: string;
  TermDescription: string;
  TermComponents?: TermComponent[];
  RenewalQuoteTimer: string;
  ProrationPolicy: { MinimumProratedUnits: string };
}

export interface ProductSearchResult {
  ProductFamilyName: ProductFamily;
  ProductIds: string[];
  Products: Product[];
  TotalProductCount: number;
}

export interface ProductSearchResults {
  HasMorePages: boolean;
  Results: ProductSearchResult[];
  TotalFamilyCount: number;
}

export enum ProductFamily {
  All = 'All',
  Azure = 'Azure',
  Apps = 'Apps',
  Passes = 'Passes',
  Devices = 'Devices',
  Software = 'Software',
  NegotiatedTerms = 'NegotiatedTerms',
}

export enum ProductType {
  Azure = 'Azure',
  AzureAccessPass = 'AzureAccessPass',
  AzureFamilyDiscount = 'AzureFamilyDiscount',
  AzureMonetaryCredit = 'AzureMonetaryCredit',
  AzureMonetaryCommit = 'AzureMonetaryCommit',
  AzureSupport = 'AzureSupport',
  Entitlement = 'Entitlement',
  SAP = 'SAP',
}

export enum ServiceFamily {
  SaaS = 'saas',
  Compute = 'compute',
}

export enum Service {
  VirtualMachines = 'virtual machines',
  VirtualMachinesLicenses = 'virtual machines licenses',
}

export enum ProductOwnershipSellingMotion {
  FirstPartyProducts = '1PP',
  ThirdPartyProductsAgency = '3PPAgency',
  ThirdPartyProductReseller = '3PPReseller',
}

export interface ProductSearchRequest {
  productFamilyNames?: ProductFamily[];
  query: string;
}

export interface FilterFavoriteProductsForLegacyRequest {
  productFamilyNames?: ProductFamily[];
  productNames: string[];
}

export interface ProductSearchResponse {
  products: Product[];
  productFamiliesWithMoreResults: ProductFamily[];
}

export interface NegotiatedTermsRequest {
  top: number;
  market?: Market;
  language?: Language;
}

export interface ProductSearchByFamilyRequest {
  productFamilyName: ProductFamily;
  query: string;
  skip: number;
  top: number;
  serviceFamily?: string;
}

export interface ProductSearchByFamilyResults {
  Aggregations: [];
  HasMorePages: boolean;
  ProductIds: string[];
  Products: Product[];
  TotalResultCount: number;
}

export interface ProductSearchByFamilyResponse {
  hasMorePages: boolean;
  products: Product[];
  productFamilyName: ProductFamily;
}

export interface ProductSearchSuccess {
  nextSkip: number;
  products: Product[];
  productFamiliesWithMoreResults: ProductFamily[];
}

export interface TermSearchRequest {
  query: string;
  top: number;
}

export interface TransferInstruction {
  CurrentTermIds: string[];
  TransferType: string;
  TransferOptions: TransferOption[];
}

export interface ClientTransferInstruction {
  CurrentTermIds: string;
  TransferType: string;
  TransferOptions: TransferOption[];
}

export interface TransferOption {
  TransferToBigId: string;
  Rank: number;
  TermTransfer: TermTransfer[];
  RenewalData?: { IsAutorenewable: boolean; RenewToTermId: string };
}

export interface TermTransfer {
  TransferToTermUnit: string[];
  TransferToBillingPeriod: string[];
  TransferTiming: string;
}
