import i18next from 'i18next';
import moment from 'moment-timezone';
import loggerService from 'services/logger-service';
import { LineItem } from 'services/proposal/types';
import { termsChannel } from 'services/reco-static/api';

import { Currency } from '../supported-currencies';
import { ProposalListLineItem } from '../types';
import { formatCurrency } from '../utils';

export const createECIFGroups = (lineItems: ProposalListLineItem[]) => {
  let groups: Record<string, ProposalListLineItem[]> = {};
  lineItems.forEach(termLineItem => {
    // TODO:cameneks replace it with the actual field
    const oneAskId = termLineItem.supplementalTermReferenceData;
    if (oneAskId) {
      const existingGroup = groups[oneAskId];
      if (existingGroup) {
        groups[oneAskId] = [...existingGroup, termLineItem];
      } else {
        groups[oneAskId] = [termLineItem];
      }
    }
  });
  return groups;
};

export const getProposalListLineItems = (lineItems: ProposalListLineItem[]) => {
  let result: ProposalListLineItem[] = [];
  const ECIFGroups = createECIFGroups(lineItems);
  const usedGroups = new Set();
  lineItems.forEach(lineItem => {
    // TODO:cameneks replace it with the actual field
    const oneAskId = lineItem.supplementalTermReferenceData;
    if (oneAskId) {
      const matched = ECIFGroups[oneAskId];
      if (!matched) {
        loggerService.error({
          error: {
            name: 'ECIF_GROUP_ERROR',
            message: 'There exists a oneAskId without matching ECIF group',
          },
        });
      }
      //first time we saw this group push the item with the group
      if (!usedGroups.has(oneAskId) && matched) {
        result.push({ ...lineItem, ecifGroup: matched });
      }
      usedGroups.add(oneAskId);
    }
    //push the Non-ECIF line item as usual
    else {
      result.push(lineItem);
    }
  });
  return result;
};

//TODO: Remove this when we have the actual quote service work completed for ECIF
export const getMockTermLineItems = (lineItems: ProposalListLineItem[]): ProposalListLineItem[] => {
  if (termsChannel.Items.length < 4) {
    return [];
  }
  const productIdMapping: Record<string, { oneAskId: string; endDate: Date }> = {
    [termsChannel.Items[0].Id]: { oneAskId: 'E1', endDate: new Date(2018, 11, 12) },
    [termsChannel.Items[1].Id]: { oneAskId: 'E1', endDate: new Date(2017, 7, 10) },
    [termsChannel.Items[2].Id]: { oneAskId: 'E2', endDate: new Date(2018, 1, 12) },
    [termsChannel.Items[3].Id]: { oneAskId: 'E2', endDate: new Date(2017, 1, 11) },
    //ECIF terms product Id
    '0RDCKN523H1P': { oneAskId: 'E2', endDate: new Date(2017, 1, 11) },
  };

  return lineItems.map((lineItem: ProposalListLineItem) => {
    const match = productIdMapping[lineItem.productIdentifier.productId];

    if (match && match.oneAskId) {
      return { ...lineItem, supplementalTermReferenceData: match.oneAskId, endDate: match.endDate };
    }
    return lineItem;
  });
};

export const totalAmountExceededError = (
  lineItem: ProposalListLineItem,
  oneAskAmount: number,
  oneAskCurrency: string
) => {
  if (!lineItem.ecifGroup) {
    return;
  }
  let total = lineItem.ecifGroup.reduce((sum, lineItem) => sum + lineItem.chargePrice.price, 0);
  if (total > oneAskAmount) {
    return i18next.t(
      'quote::The total value of the configured services, exceeds the amount approved in OneAsk. Please edit the services list, so they do not exceed {{amount}}',
      { amount: `${formatCurrency(total)} ${oneAskCurrency}` }
    );
  }
};

export const getECIFErrors = (
  lineItem: ProposalListLineItem,
  quoteCurrency: Currency,
  oneAsk?: { Currency: string; TotalApprovedAmount: number }
) => {
  if (!lineItem.ecifGroup) {
    return [];
  }
  if (!oneAsk) {
    return [
      i18next.t(
        'quote::The OneAsk approval can no longer be found for this item. If approval has been withdrawn then the line item must be deleted.'
      ),
    ];
  }
  let errors: string[] = [];
  const currencyisDifferent =
    quoteCurrency !== oneAsk.Currency &&
    i18next.t(
      'quote:: The currency in the OneAsk request {{oneAskCurrency}} does not match the currency on the quote {{quoteCurrency}}',
      { oneAskCurrency: oneAsk.Currency, quoteCurrency }
    );
  const totalAmountExceeded = totalAmountExceededError(
    lineItem,
    oneAsk.TotalApprovedAmount,
    oneAsk.Currency
  );

  if (currencyisDifferent) {
    errors.push(currencyisDifferent);
  }

  if (totalAmountExceeded) {
    errors.push(totalAmountExceeded);
  }
  return errors;
};

export const getLatestEndDateOfECIFItems = (lineItems: LineItem[]) => {
  let latestEndDate: moment.Moment | undefined;
  lineItems.forEach(lineItem => {
    if (lineItem.endDate) {
      latestEndDate = latestEndDate
        ? moment.max([moment(lineItem.endDate), moment(latestEndDate)])
        : moment(lineItem.endDate);
    }
  });
  return latestEndDate;
};
