import { ThemeProps } from 'styles';
import { sharedStyles } from 'styles/shared.styles';

export const checkboxStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    checkmark: {
      color: palette.backgroundStandout,
    },
    checkboxWrapper: {
      '& .ms-Checkbox-checkbox': {
        ...sharedStyles(theme).roundedCorners,
        borderColor: palette.textTertiary,
      },
      '& .is-checked .ms-Checkbox-checkbox': {
        background: palette.secondary,
        borderColor: palette.secondary,
      },
      '& .ms-Checkbox-label:hover .ms-Checkbox-checkbox i': {
        color: palette.backgroundDivider,
      },
      '& .ms-Checkbox-label:hover .ms-Checkbox-checkbox': {
        color: palette.text,
        background: palette.backgroundStandout,
        borderColor: palette.text,
      },
      '& .is-checked .ms-Checkbox-label:hover .ms-Checkbox-checkbox i': {
        color: palette.backgroundStandout,
      },
      '& .is-checked .ms-Checkbox-label:hover .ms-Checkbox-checkbox': {
        background: palette.secondary,
        borderColor: palette.secondary,
      },
    },
  };
};
