import { getFlightIsEnabled } from 'features/app/selectors';
import { Dimensions, Fail, Processing } from 'features/components/dialogs';
import { addNewCrmId } from 'features/proposal/actions';
import {
  getActiveProposal,
  getEnrollmentNumber,
  processingAddCRMID,
  proposalHasPassesProduct,
} from 'features/proposal/selectors';
import { getCRMId } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { EnrollmentSetStartDateDialog } from '../EnrollmentSetStartDateDialog/EnrollmentSetStartDateDialog';
import { SelectOpportunityView } from './SelectOpportunityView';

const dimensions: Dimensions = {
  height: 312,
  width: 548,
};

const styles = {
  spacing: {
    paddingTop: 16,
  },
};

const mapStateToProps = (state: RootState) => {
  const proposal = getActiveProposal(state);

  return {
    crmId: getCRMId(proposal),
    enrollmentNumber: getEnrollmentNumber(state, proposal),
    processing: processingAddCRMID(state),
    modernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
    hasPassesProduct: proposalHasPassesProduct(state, proposal),
  };
};

const dispatchProps = {
  associateCrmId: addNewCrmId.request,
};

export type SelectOpportunityDialogProps = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

type Props = SelectOpportunityDialogProps & WithStyles<typeof styles>;
const SelectOpportunityDialogUnStyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [request, setRequest] = React.useState<{ crmId: string; proposalId: string } | undefined>();
  const [showEnrollmentSetStartDateDialog, setShowEnrollmentSetStartDateDialog] = React.useState<
    boolean
  >(false);
  const context = React.useContext(DialogContext);

  const associateCrmId = (request: { crmId: string; proposalId: string }) => {
    setIsProcessing(true);
    props.associateCrmId({ ...request });
  };

  const onAssociateClick = (crmId: string, quoteId: string) => {
    const newRequest = { crmId, proposalId: quoteId };
    setRequest(newRequest);
    associateCrmId(newRequest);
  };

  React.useEffect(() => {
    if (props.crmId) {
      if (props.modernOfficeEnabled && props.enrollmentNumber && props.hasPassesProduct) {
        setIsProcessing(false);
        // TODO - jek - Don't show dialog if nothing was actually updated.
        setShowEnrollmentSetStartDateDialog(true);
      } else {
        context.closeDialog();
      }
    }
  }, [props, context]);

  const getCurrentView = () => {
    if (!isProcessing) {
      if (showEnrollmentSetStartDateDialog) {
        return <EnrollmentSetStartDateDialog closeDialog={() => context.closeDialog()} />;
      } else return <SelectOpportunityView {...dimensions} onAssociateClick={onAssociateClick} />;
    } else if (props.processing.error) {
      return (
        <Fail
          {...dimensions}
          closeDialog={context.closeDialog}
          dataAutomationId="crmIdAssociationFail"
          message={t(
            'quote::We are sorry but the association to quote ran into an error and CRM ID could not be associated.'
          )}
          onTryAgainClick={() => request && associateCrmId(request)}
        />
      );
    } else {
      return (
        <Processing
          {...dimensions}
          message1={t('quote::The CRM ID is being associated to quote.')}
        />
      );
    }
  };

  return getCurrentView();
};

export const SelectOpportunityDialogStyled = withStyles(styles)(
  SelectOpportunityDialogUnStyled
) as React.FC<SelectOpportunityDialogProps>;
export const SelectOpportunityDialog = connect(
  mapStateToProps,
  dispatchProps
)(SelectOpportunityDialogStyled);

export const openSelectOpportunityDialog = (context: {
  openDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}) => {
  const dialogProps: DialogProps = {
    providedDialog: <SelectOpportunityDialog />,
  };
  context.openDialog(dialogProps);
};
