import { SectionSeparator, SelectionSection } from 'components';
import { OrganizationInfo } from 'features-apollo/quote/components/OrganizationInfo';
import { OrganizationSimple } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

// #region OrganizationSelector
const organizationSelectorStyles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

interface OrganizationSelectorProps {
  organization: OrganizationSimple;
  readOnly?: boolean;
  onSelectOrganization: (organizationId: string, accountId: string) => void;
}

const OrganizationSelectorUnStyled: React.FC<OrganizationSelectorProps &
  WithStyles<typeof organizationSelectorStyles>> = props => {
  const { t } = useTranslation();

  const onClick = () => {
    props.onSelectOrganization(props.organization.id, props.organization.accountId);
  };

  const address = props.organization.address
    ? props.organization.address
    : {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        companyName: '',
        country: '',
        postalCode: '',
        region: '',
      };

  return (
    <SelectionSection
      buttonText={t('quote::Select this billing account')}
      dataAutomationId="selectThisBillingAccount"
      disabled={props.readOnly}
      onClick={onClick}
    >
      <OrganizationInfo
        address={address}
        lastPurchaseDateTime={props.organization.lastInvoiceDate}
        maxWidth={200}
        name={props.organization.name}
        tradeName={props.organization.tradeName || ''}
      />
    </SelectionSection>
  );
};
const OrganizationSelector = withStyles(organizationSelectorStyles)(OrganizationSelectorUnStyled);
// #endregion

// #region Select Organization tool
export interface SelectOrganizationProps {
  organizationSuggestions: OrganizationSimple[];
  onSelectOrganization: (organizationId: string, accountId: string) => void;
  limitTotalToDisplay?: number;
  readOnly?: boolean;
}

const selectOrganizationStyles = {
  separator: {
    marginBottom: 12,
    marginTop: 20,
  },
};

type Props2 = SelectOrganizationProps & WithStyles<typeof selectOrganizationStyles>;

const SelectOrganizationUnStyled: React.FC<Props2> = props => {
  const selectors = props.organizationSuggestions
    .slice(0, props.limitTotalToDisplay)
    .map((organizationItem, index) => {
      const name = organizationItem.name;
      const selectorProps: OrganizationSelectorProps = {
        organization: organizationItem,
        readOnly: props.readOnly,
        onSelectOrganization: props.onSelectOrganization,
      };

      return index === 0 ? (
        <OrganizationSelector {...selectorProps} key={`${name}-${index}`} />
      ) : (
        <React.Fragment key={`${name}-${index}`}>
          <SectionSeparator addClass={props.classes.separator} />
          <OrganizationSelector {...selectorProps} />
        </React.Fragment>
      );
    });

  return <>{selectors}</>;
};

export const SelectOrganization = withStyles(selectOrganizationStyles)(SelectOrganizationUnStyled);
// #endregion
