import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { LinkButton } from '../Links';
import { TextBodySmall } from '../Text';

export interface ShowMoreTextProps {
  maxLine?: number;
  showLessButtonText: string;
  showMoreButtonText: string;
  text: string;
}

const DEFAULT_MAX_LINE = 5;
const DEFAULT_LINE_HEIGHT = 16;

const styles = (theme: ThemeProps) => ({
  showMoreButton: { fontSize: theme.fonts.fontSizes.small },
  textContainer: {
    display: '-webkit-box',
    '-webkit-line-clamp': (props: ShowMoreTextProps) => props.maxLine || DEFAULT_MAX_LINE,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fallbacks: {
      display: 'inline-block',
    },
  },
});

type Props = ShowMoreTextProps & WithStyles<typeof styles>;

const ShowMoreTextUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const [showMoreState, setShowMoreState] = React.useState<{
    displayButton: boolean;
    showComment: boolean;
    textHeight?: number;
  }>({
    displayButton: false,
    showComment: false,
  });

  const maxLine = props.maxLine || DEFAULT_MAX_LINE;
  const divRef = React.useRef<HTMLDivElement | null>(null);

  React.useLayoutEffect(() => {
    if (divRef.current && divRef.current.scrollHeight !== showMoreState.textHeight) {
      const textHeight = divRef.current.scrollHeight;
      const lines = textHeight / DEFAULT_LINE_HEIGHT;
      if (Math.round(lines) > maxLine) {
        setShowMoreState({
          ...showMoreState,
          displayButton: true,
          textHeight,
        });
      } else {
        setShowMoreState({
          ...showMoreState,
          displayButton: false,
          textHeight,
        });
      }
    }
  }, [maxLine, showMoreState]);

  const showMoreButton = (
    <LinkButton
      addClass={classes.showMoreButton}
      displayText={showMoreState.showComment ? props.showLessButtonText : props.showMoreButtonText}
      onClick={() => {
        const showComment = !showMoreState.showComment;
        setShowMoreState({ ...showMoreState, showComment });

        if (divRef.current) {
          showComment
            ? (divRef.current.style.webkitLineClamp = 'unset')
            : (divRef.current.style.webkitLineClamp = maxLine.toString());
        }
      }}
    />
  );

  return (
    <>
      <div className={classes.textContainer} ref={divRef}>
        <TextBodySmall block>{props.text}</TextBodySmall>
      </div>
      {showMoreState.displayButton && showMoreButton}
    </>
  );
};

export const ShowMoreText = withStyles(styles)(ShowMoreTextUnstyled);
