import { ColumnActionsMode, IColumn, IChoiceGroupOption } from 'office-ui-fabric-react';
import { Asset, AssetListItem } from 'services/edge/types';
import { Product } from 'services/catalog/types';
import moment from 'moment';
import { dateFormatLl } from 'components/utilities/dates';
import { billingPlanType, termDurationType } from '../../ConfigCard/ConfigCardBusinessLogic';
import * as React from 'react';
import { LabelList } from 'components/ions/CuratedText/LabelList/LabelList';
import { TextBody } from 'components';

export const buildColumns = (
  isStatic: boolean,
  classes: { assetInfo: string },
  openInfoBuble: (element: HTMLElement) => void
): IColumn[] => {
  //TODO: kaderbez, translation of all field names where we are rendering IColumn[]
  const columns: IColumn[] = [
    {
      name: 'Product',
      fieldName: 'product',
      key: 'product',
      isRowHeader: false,
      isResizable: false,
      isSorted: false,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      name: 'SKU',
      fieldName: 'skuTitle',
      key: 'skuTitle',
      isRowHeader: false,
      isResizable: false,
      isSorted: false,
      minWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
  if (isStatic) {
    columns.push({
      name: 'Static reason',
      fieldName: 'reason',
      key: 'reason',
      isRowHeader: false,
      isResizable: false,
      isSorted: false,
      minWidth: 0,
      columnActionsMode: ColumnActionsMode.clickable,
      iconName: 'Info',
      iconClassName: classes.assetInfo,
      onColumnClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        openInfoBuble(event.target as HTMLElement),
    });
  } else {
    columns.push({
      name: 'Seats',
      fieldName: 'seats',
      key: 'seats',
      isRowHeader: false,
      isResizable: false,
      isSorted: false,
      minWidth: 0,
      columnActionsMode: ColumnActionsMode.disabled,
    });
    columns.push({
      name: 'Start date',
      fieldName: 'startDate',
      key: 'startDate',
      isRowHeader: false,
      isResizable: false,
      isSorted: false,
      minWidth: 0,
      columnActionsMode: ColumnActionsMode.disabled,
    });
  }
  return columns;
};

export const buildAssetsList = (
  isStatic: boolean,
  assets?: Asset[],
  products?: Record<string, Product>
) => {
  let displayRows: AssetListItem[] = [];
  assets &&
    assets.forEach(asset => {
      const product = products && products[asset.assetData.productInfo.productId];
      const skuId = asset.assetData.productInfo.skuId;
      const sku =
        skuId &&
        product &&
        product.DisplaySkuAvailabilities.find(skuAvail => skuAvail.Sku.SkuId === skuId);
      const skuTitle = sku && sku.Sku.LocalizedProperties[0].SkuTitle;
      const quantity = asset.assetData.quantity;
      const startDate = asset.assetData.termInfo && asset.assetData.termInfo.startDate;
      const inGrace = asset.assetData.stateInfo.state === 'warning';
      const isFulfillmentPending = moment.utc(startDate).isAfter(moment.utc());
      const isRatable = asset.attributes && asset.attributes.some(attr => attr === 'ratable');
      const createdByPartner =
        asset.assetData.productInfo.audience === 'Partner-Commercial' &&
        asset.beneficiaryOrganization.accountId !== asset.accountId;
      const isUsage = asset.attributes && asset.attributes.some(attr => attr === 'usage');
      const isStaticProduct = inGrace || isFulfillmentPending || isRatable || createdByPartner;
      const isQuantifiable =
        asset.attributes && asset.attributes.some(attr => attr === 'quantifiable');
      const staticContent = `${inGrace ? 'In grace' : ''} ${
        isFulfillmentPending ? 'Future start date' : ''
      } ${isRatable ? 'Rating asset' : ''} ${createdByPartner ? 'Partner asset' : ''}`;
      if (!isUsage) {
        if (isStatic) {
          isStaticProduct &&
            displayRows.push({
              product: product && product.LocalizedProperties[0].ProductTitle,
              productId: (product && product.ProductId) || '',
              skuTitle,
              reason: staticContent,
              key: asset.id,
            });
        } else {
          !isStaticProduct &&
            isQuantifiable &&
            displayRows.push({
              product: product && product.LocalizedProperties[0].ProductTitle,
              productId: (product && product.ProductId) || '',
              skuTitle,
              seats: quantity,
              startDate: startDate && moment.utc(startDate).format(dateFormatLl),
              selectable: true,
              key: asset.id,
            });
        }
      }
    });
  return displayRows;
};

export const getCommitmentAndBilling = (
  product?: Product,
  asset?: Asset
): { commitment?: string; billingPlan?: string } => {
  const skuId = asset && asset.assetData.productInfo.skuId;
  const availabilityId = asset && asset.assetData.productInfo.availabilityId;
  const termId = asset && asset.assetData.termInfo && asset.assetData.termInfo.termId;
  const selectedSkuAvailability =
    skuId &&
    product &&
    product.DisplaySkuAvailabilities.find(skuAvail => skuAvail.Sku.SkuId === skuId);
  const selectedAvailability =
    selectedSkuAvailability &&
    selectedSkuAvailability.Availabilities &&
    selectedSkuAvailability.Availabilities.find(avail => avail.AvailabilityId === availabilityId);
  const selectedTerm =
    selectedAvailability &&
    selectedAvailability.Terms &&
    selectedAvailability.Terms.find(term => term.TermId === termId);
  const components = selectedTerm && selectedTerm.TermComponents;
  const commitmentComponent =
    components && components.find(component => component.Type.toLowerCase() === termDurationType);
  const billingComponent =
    components && components.find(component => component.Type.toLowerCase() === billingPlanType);
  const commitmentBilling = {
    commitment: commitmentComponent && commitmentComponent.Properties.Title,
    billingPlan: billingComponent && billingComponent.Properties.Title,
  };
  return commitmentBilling;
};

export const buildAssetDetailsContent = (
  title: string,
  classes: { convertDialogAssetDetailsTitle: string; convertDialogAssetDetails: string },
  selectedProduct?: Product,
  selectedAsset?: Asset,
  selectedAssetListItem?: AssetListItem
) => {
  const endDate =
    selectedAsset && selectedAsset.assetData.termInfo && selectedAsset.assetData.termInfo.endDate;
  const { commitment, billingPlan } = getCommitmentAndBilling(selectedProduct, selectedAsset);
  const productTitle = selectedProduct && selectedProduct.LocalizedProperties[0].ProductTitle;
  const productDetails = selectedAssetListItem && selectedAsset && (
    <div className={classes.convertDialogAssetDetails}>
      <TextBody>{title}</TextBody>
      <TextBody addClass={classes.convertDialogAssetDetailsTitle}>{productTitle}</TextBody>
      {selectedAssetListItem.seats && (
        <LabelList items={[{ name: 'Seats', value: selectedAssetListItem.seats.toString() }]} />
      )}
      {commitment && <LabelList items={[{ name: 'Commitment', value: commitment }]} />}
      {billingPlan && <LabelList items={[{ name: 'Billing plan', value: billingPlan }]} />}
      {endDate && (
        <LabelList
          items={[
            {
              name: 'End Date',
              value: moment.utc(endDate).format(dateFormatLl),
            },
          ]}
        />
      )}
    </div>
  );
  return productDetails;
};

export const buildAssetConversionOptions = (
  selectedProduct?: Product,
  selectedAsset?: Asset,
  products?: Record<string, Product>
): IChoiceGroupOption[] | undefined => {
  const skuId = selectedAsset && selectedAsset.assetData.productInfo.skuId;
  const termId =
    selectedAsset && selectedAsset.assetData.termInfo && selectedAsset.assetData.termInfo.termId;

  const selectedSkuAvailability =
    skuId &&
    selectedProduct &&
    selectedProduct.DisplaySkuAvailabilities.find(skuAvail => skuAvail.Sku.SkuId === skuId);
  const clientTransferInstructions =
    selectedSkuAvailability && selectedSkuAvailability.Sku.Properties.ClientTransferInstructions;
  const clientTransferInstruction =
    clientTransferInstructions &&
    clientTransferInstructions.find(instruction => instruction.CurrentTermIds === termId);
  if (clientTransferInstruction) {
    const options: IChoiceGroupOption[] =
      clientTransferInstruction &&
      clientTransferInstruction.TransferOptions.map(option => {
        const productId = option.TransferToBigId.split('/')[0];
        const product = products && products[productId];
        return {
          key: `${option.TransferToBigId}/${termId}`,
          text: (product && product.LocalizedProperties[0].ProductTitle) || '',
          billing: option.TermTransfer && option.TermTransfer[0].TransferToBillingPeriod,
          commitment: option.TermTransfer && option.TermTransfer[0].TransferToTermUnit,
        };
      });
    return options;
  }
};
