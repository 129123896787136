import { TextDialogBox } from 'components/ions/PopUps/Dialogs/TextDialog';
import { Fail } from 'features-apollo/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ViewAgreementErrorDialogProps {
  /**
   * Toggles between error and not-found message to display.
   */
  isError?: boolean;
  /**
   * Allows passing in a custom error message or element to display instead of the default for TermID validation errors
   */
  customMessage?: string | JSX.Element;
  /**
   * Required to close the dialog.
   */
  dialogClose: () => void;
}

// TODO: jepagan - consider refactoring after designing migration of new agreement error handling
export const ViewAgreementErrorDialog: React.FC<ViewAgreementErrorDialogProps> = props => {
  const { t } = useTranslation();

  if (props.customMessage) {
    const dialogDimensions = {
      height: 612,
      width: 600,
    };
    return (
      <Fail
        dataAutomationId="AgreementPreviewTermIDErrorDialog"
        {...dialogDimensions}
        message={props.customMessage}
        onClose={props.dialogClose}
      />
    );
  }

  let heading: string;
  let message: string;

  if (props.isError) {
    heading = t('quote::Our Apologies');
    message = t(
      'quote::We had trouble generating the agreement for this quote. Please try viewing the agreement sometime later or reach out to Quote support for assistance.'
    );
  } else {
    heading = t('quote::No New Term');
    message = t('quote::There are no new terms for the customer to sign as part of this quote.');
  }

  return <TextDialogBox bodyText={message} dialogClose={props.dialogClose} headingText={heading} />;
};
