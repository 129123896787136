import { UPDATE_DIALOGVIEWED } from 'features-apollo/user/queries';
import { MutationUpdateDialogViewedData } from 'features-apollo/user/types';
import { MutationUpdateDialogViewedArgs } from 'generated/graphql';
import React from 'react';
import loggerService from 'services/logger-service';

import { useMutation } from '@apollo/react-hooks';

import { FirstRun } from './FirstRun';
import { DialogVersion } from './types';

const _performance = performance;

export const FirstRunContainer: React.FC = () => {
  _performance.mark('First Run Experience dialog opened');
  const logFRETraversalTime = () => {
    _performance.mark('First Run Experience dialog closed');
    _performance.measure(
      'First Run Experience dialog opened',
      'First Run Experience dialog closed'
    );
    const measure = _performance.getEntriesByType('measure');
    const duration = measure[0].duration;
    loggerService.log({
      name: 'First Run Experience - traversal time',
      measurements: { duration },
    });
    performance.clearMarks();
    performance.clearMeasures();
  };

  const [updateDialogViewed] = useMutation<
    MutationUpdateDialogViewedData,
    MutationUpdateDialogViewedArgs
  >(UPDATE_DIALOGVIEWED);

  const onClose = () => {
    logFRETraversalTime();
    updateDialogViewed({
      variables: {
        key: DialogVersion.dialog20200831,
      },
    });
  };

  return <FirstRun onClose={onClose} />;
};
