import { SearchAtom } from 'components/atoms';
import { ActionType } from 'components/ions/index';
import { ISearchBox, ISearchBoxStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { borderlessSearchStyles } from './BorderlessSearch.styles';

/**
 * Props for the borderless search
 * @prop {string} ariaLabel - ariaLabel for the Search
 * @prop {string} id - Id for the Search
 * @prop {string} placeholder - Placeholder text to be displayed in the Searchbox
 * @prop {string} focusPlaceholder - Optional different placeholder text to be displayed when the Searchbox is focussed/active
 */
export interface BorderlessSearchProps {
  ariaLabel?: string;
  componentRef?: React.RefObject<ISearchBox>;
  dataAutomationId?: string;
  disabled?: boolean;
  focusPlaceholder?: string;
  id?: string;
  placeholder: string;
  styles?: Partial<ISearchBoxStyles>;
  onClear?: (event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>) => void;
  onChange?: (input?: string) => void;
  onChangeDebounced?: (input?: string) => void;
  onSearch?: (input: string) => void;
}

type Props = BorderlessSearchProps & WithStyles<typeof borderlessSearchStyles>;

const BorderlessSearchUnstyled: React.FC<Props> = (props: Props) => {
  const styles: ISearchBoxStyles = {
    root: props.classes.root,
    ...props.styles,
  };

  return (
    <div>
      <SearchAtom
        ariaLabel={props.ariaLabel}
        componentRef={props.componentRef}
        dataAutomationId={props.dataAutomationId}
        disabled={props.disabled}
        focusClass={props.classes.borderlessActive}
        focusPlaceholder={props.focusPlaceholder}
        id={props.id}
        mainClass={props.classes.borderless}
        placeholder={props.placeholder}
        styles={styles}
        onChange={props.onChange}
        onChangeDebounced={props.onChangeDebounced}
        onClear={props.onClear}
        onSearch={props.onSearch}
      />
    </div>
  );
};

export const BorderlessSearch = withStyles(borderlessSearchStyles)(
  BorderlessSearchUnstyled
) as React.FC<BorderlessSearchProps>;
