import { ThemeProps } from 'styles';

import { buttonSharedStyles } from '../shared.styles';

export const closeIconButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    ...buttonSharedStyles(theme),
    icon: {
      minWidth: 32,
      width: 32,
      backgroundColor: 'transparent',
      borderRadius: 0,
      padding: '7px 8px',
      '& i': {
        color: colors.textTertiary,
        padding: 0,
        margin: 0,
        marginBottom: 'auto',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.text,
        },
      },
      '&:active': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.textTertiary,
        },
      },
      '&:active:hover': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.textTertiary,
        },
      },
      '&:focus': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.textTertiary,
        },
      },
      '&:disabled': {
        '& i': {
          color: colors.textDisabled,
        },
      },
    },
  };
};
