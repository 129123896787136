import { Dialog, PrimaryButton, SecondaryButton, TextBody } from 'components';
import { Dimensions, Fail, Processing } from 'features/components/dialogs';
import { deleteProductGroup } from 'features/user/actions';
import { processingUserPreferencesUpdate } from 'features/user/selectors';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';

const dimensions: Dimensions = {
  height: 220,
  width: 424,
};

const dispatchProps = {
  deleteProductGroup: deleteProductGroup.request,
};

const mapStateToProps = (state: RootState) => {
  const { error, loading } = processingUserPreferencesUpdate(state);

  const isProductGroupDeleted = (groupName: string) =>
    !(state.user.preferences.productGroups && state.user.preferences.productGroups[groupName]);

  return {
    error,
    isProductGroupDeleted,
    loading,
  };
};

const styles = (theme: ThemeProps) => ({
  texboxInstructions: {
    color: theme.palette.textTertiary,
    paddingLeft: 8,
  },
});

interface DeleteProductGroupDialogProps {
  elementIdToFocusOnDismiss?: string;
  groupName: string;
}

type Props = DeleteProductGroupDialogProps &
  WithStyles<typeof styles> &
  typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

export const DeleteProductGroupDialogUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const { groupName } = props;

  const handleClose = () => {
    context.closeDialog();

    // Refocus
    if (props.elementIdToFocusOnDismiss) {
      const element = document.getElementById(props.elementIdToFocusOnDismiss);
      element && element.focus();
    }
  };

  // #region adding product states
  if (groupName) {
    if (props.loading) {
      return <Processing {...dimensions} message1={t('Deleting product group')} />;
    } else if (props.error) {
      return (
        <Fail
          {...dimensions}
          closeDialog={handleClose}
          message={t('Unable to delete product group')}
        />
      );
    } else if (props.isProductGroupDeleted(groupName)) {
      handleClose();
      return null;
    }
  }
  // #endregion

  return (
    <Dialog
      {...dimensions}
      closeDialog={handleClose}
      footerButtons={[
        <PrimaryButton
          dataAutomationId="productGroupDialogDeleteGroup"
          disabled={!groupName}
          key="delete-group"
          text={t('Delete group')}
          onClick={() => {
            groupName &&
              props.deleteProductGroup({
                groupName,
              });
          }}
        />,
        <SecondaryButton
          autoFocus
          dataAutomationId="productGroupDialogCancelDeleteGroup"
          key="cancel-delete-group"
          text={t('Cancel')}
          onClick={handleClose}
        />,
      ]}
      title={t('Delete product grouping')}
    >
      <TextBody>
        <Trans ns="quote">
          By deleting the group, named &quot;{{ groupName }}&quot;, will delete all of the products
          in this group as well. Are you sure you want to delete this group?
        </Trans>
      </TextBody>
    </Dialog>
  );
};

const DeleteProductGroupDialogStyled = withStyles(styles)(DeleteProductGroupDialogUnstyled);
const DeleteProductGroupDialog = connect(
  mapStateToProps,
  dispatchProps
)(DeleteProductGroupDialogStyled);

export const openDeleteProductGroupDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: DeleteProductGroupDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <DeleteProductGroupDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
