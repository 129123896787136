import { QuoteLineItem } from 'features-apollo/quote/components/types';
import { FinanceAssociationIdType, FinancingTermLineItem, Product } from 'generated/graphql';
import i18next from 'i18next';
import { oc } from 'ts-optchain';

export const getFinanceableProducts = (financeTerm: FinancingTermLineItem) => {
  const relatedTermProducts = financeTerm.product && financeTerm.product.relatedProducts;
  const applicableProducts: Product[] = [];

  if (relatedTermProducts && relatedTermProducts !== null && relatedTermProducts.length) {
    relatedTermProducts.forEach(relatedProduct => {
      if (relatedProduct.relationshipType.toLowerCase() === 'sellableby') {
        applicableProducts.push(relatedProduct.product);
      }
    });
  }
  return applicableProducts;
};

export const getFinanceableLineItems = (products: Product[], lineItems: QuoteLineItem[]) => {
  const financeableLineItems: QuoteLineItem[] = [];

  lineItems.forEach(lineItem => {
    const lineItemProductId = lineItem.product && lineItem.product !== null && lineItem.product.id;
    if (lineItemProductId && lineItemProductId !== null) {
      const isRelated = products.some(product => product.id === lineItemProductId);
      if (isRelated) {
        financeableLineItems.push(lineItem);
      }
    }
  });
  return financeableLineItems;
};

export const getAssociatedLineItem = (
  lineItems: QuoteLineItem[],
  termId: string,
  parentId: string
) =>
  !!termId.trim()
    ? lineItems.find(
        lineItem =>
          lineItem.groups &&
          lineItem.groups !== null &&
          lineItem.groups.includes(termId) &&
          lineItem.id !== parentId
      )
    : undefined;

const getLineItemConfigText = (lineItem: QuoteLineItem, currency: string) =>
  `(${oc(lineItem).configurationSummary.display('configure')})`;

export const getApplyOptions = (
  financeableLineItems: QuoteLineItem[],
  financeableProducts: Product[],
  currency: string
) => {
  return financeableLineItems.length
    ? financeableLineItems.map(item => {
        return {
          key: item.id,
          text: `${item.title} ${getLineItemConfigText(item, currency)}`,
          data: FinanceAssociationIdType.LineItem,
        };
      })
    : financeableProducts.map(product => {
        return {
          key: product.id,
          text: product.title,
          data: FinanceAssociationIdType.Product,
        };
      });
};

export const getAssociatedLineItemConfiguration = (currency: string, lineItem?: QuoteLineItem) =>
  lineItem ? `${i18next.t('quote::Applies to')} ${lineItem.title}` : i18next.t('quote::configure');
