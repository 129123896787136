import axios, { AxiosError, AxiosResponse } from 'axios';
import { serviceifyAddressOrgCreation } from 'components/utilities/address';
import loggerService from 'services/logger-service';
import {
  createGuid,
  getAuthHeader,
  getCV,
  getTestHeader,
  sleepCreator,
  TokenAuthorities,
} from 'services/utils';

import { AccountExtensionsConfig, endpoints } from './config';
import {
  CreateTypeCOrganizationFootprintRequest,
  CreateTypeCOrganizationFootprintResponse,
} from './types';

async function getHeaders(config: AccountExtensionsConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-tracking-id': createGuid(), //todo: should be changed to something traceable so that it can be used as a lookup before create
    'api-version': '2019-05-31',
    'ms-cv': getCV(),
    'x-ms-correlation-id': createGuid(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

async function postRequest(
  url: string,
  headers: Record<string, string>,
  request: CreateTypeCOrganizationFootprintRequest
): Promise<AxiosResponse<CreateTypeCOrganizationFootprintResponse>> {
  const response = await axios.post<CreateTypeCOrganizationFootprintResponse>(url, request, {
    headers,
  });
  return response;
}

export async function createTypeCOrganization(
  request: CreateTypeCOrganizationFootprintRequest,
  config: AccountExtensionsConfig
): Promise<CreateTypeCOrganizationFootprintResponse> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/footprint/typecorg`;
  let retryCount = 1;
  const serviciefiedRequest: CreateTypeCOrganizationFootprintRequest = {
    ...request,
    organizationAddress: serviceifyAddressOrgCreation(request.organizationAddress),
  };

  let response = await postRequest(url, headers, serviciefiedRequest);

  let retry: boolean = response.status === 202;
  // retry every 5 seconds
  let sleep = sleepCreator(5000);
  while (retry && retryCount <= 8) {
    await sleep();
    try {
      response = await postRequest(url, headers, serviciefiedRequest);
      retry = false;
    } catch (exception) {
      const error = exception as AxiosError;
      retry = !!error.response && error.response.status === 409;
    }

    retryCount++;
  }

  if (retry) {
    loggerService.error({
      error: new Error(
        `TypeC billing account creation failed. Exceeded retry attempts. TrackingId:${headers['x-ms-tracking-id']}`
      ),
    });
  }

  return response.data;
}
