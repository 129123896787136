import { PatchCommand } from 'services/types';

import { ClaimType } from '../config';
import { BaseLineItem, BaseProposalHeader } from './baseTypes';
import {
  PricingContext,
  PricingInstruction,
  ProductIdentifier,
  UserGroup,
  LineItem,
  SapReferenceData,
} from './types';

export interface RequestProposal {
  header: RequestProposalHeader;
  lineItems: RequestLineItem[];
  sapReferenceData?: SapReferenceData;
}

export interface RequestProposalHeader extends BaseProposalHeader {
  isOneClickActivation?: boolean;
  isApprovalPreCheckRequired?: boolean;
  approval?: {
    approvalLevels: {};
  };
}

export interface RequestLineItem extends BaseLineItem {
  externalizedPrice?: {
    pricingCurrency: string;
    listPrice: number;
    discountedPrice: number;
    proratedPrice: number;
    pricingRules?: [
      {
        id: string;
        affirmation: string;
        priceGuaranteed: boolean;
        affirmationParameters: {
          key: string;
          value: string;
          type: string;
        }[];
      }
    ];
    customPrice?: {
      id: string;
      version: string;
      pricingScopeId: string;
    };
  };
  externalizedBillingPlanContext?: {
    cycleCount: number;
    cycleDuration: string;
    markupMultiplier: number;
  };
  groups?: string[];
}

export interface CreateLineItemsRequest {
  etag: string;
  proposalId: string;
  lineItems: Partial<RequestLineItem>[];
}

export interface UpdateAndCreateLineItemsRequest {
  etag: string;
  proposalId: string;
  lineItems: Partial<RequestLineItem>[];
  updatedLineItem: LineItem;
}

export interface DeleteLineItemRequest {
  etag: string;
  proposalId: string;
  lineItemId: string;
}

export interface DeleteLineItemsRequest {
  etag: string;
  proposalId: string;
  lineItemIds: string[];
}

export interface ProposalHeaderPatchRequest {
  etag: string;
  proposalId: string;
  commands: PatchCommand[];
}

export interface ProposalActionBody {
  purchaseId?: string;
  purchaseProviderName?: string;
  assignedToGroup?: UserGroup;
  action: ProposalAction;
}

export enum ProposalAction {
  AssignToUserGroup = 'AssignToUserGroup',
  Submit = 'Submit',
  Withdraw = 'Withdraw',
  InitiatePurchase = 'InitiatePurchase',
  CompletePurchase = 'CompletePurchase',
  FailPurchase = 'FailPurchase',
}

export interface MultipleProposalActionRequest {
  etag: string;
  proposalId: string;
  bodies: ProposalActionBody[];
}

export interface ProposalActionRequest {
  etag: string;
  proposalId: string;
  body: ProposalActionBody;
}

export interface ProposalUpdateRequest {
  proposal: RequestProposal;
  proposalId: string;
  etag: string;
}

export interface ProposalDeleteRequest {
  id: string;
  etag: string;
}

export interface ProposalDeleteSuccess {
  id: string;
}

export interface PricingLineItem {
  catalogClaims?: Partial<Record<ClaimType, string>>;
  customerIntent: string;
  isReadyForPricing: true;
  pricingInstruction?: PricingInstruction;
  productIdentifier: ProductIdentifier;
  quantity: 1;
}

export interface ProposalPricingRequest {
  pricingContext: PricingContext;
  lineItems: PricingLineItem[];
}

export interface SearchProposalsRequest {
  orderBy: string;
  filters: string;
}

export interface CreateLineItemGroupRequest {
  etag: string;
  proposalId: string;
  updateLineItemId: string;
  createLineItems: CreateLineItemsRequest;
  originalLineItemIds: string[];
  guid: string;
  termId?: string;
}

export interface RemoveLineItemGroupRequest {
  etag: string;
  proposal: RequestProposal;
  deleteRequest: DeleteLineItemRequest;
  guids: string[];
}
