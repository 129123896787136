import { ApproveButton } from 'components/ions';
import { ApproveDialog } from 'features-apollo/quote/components/Dialogs/ApproveDialog/ApproveDialog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

export const ApproveQuoteButton: React.FC<{ quoteLoading: boolean }> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const dialogProps: DialogProps = {
    providedDialog: <ApproveDialog />,
  };

  const openDialog = () => {
    context.openDialog(dialogProps);
  };

  return (
    <ApproveButton
      dataAutomationId="approveProposalButton"
      disabled={props.quoteLoading}
      text={t('quote::Approve')}
      onClick={openDialog}
    />
  );
};
