import { completeMockQuote } from 'features-apollo/quote/components/ConfigCards/Cards/mocks';
import { DiscountCardLink } from 'features-apollo/quote/components/DiscountCard';
import { mockedCatalogContext } from 'features-apollo/quote/components/mocks';
import { GET_QUOTE } from 'features-apollo/quote/components/queries';
import * as React from 'react';
import { MockedProviders } from 'testUtils';

import { MockedResponse } from '@apollo/react-testing';

const mockQuoteInfo = {
  ...completeMockQuote,
  id: 'abc123',
  etag: 'abcdefg',
  name: 'GQL Discount Test',
};

const quoteResponseMockUnconfigured: MockedResponse = {
  request: {
    query: GET_QUOTE,
    variables: { id: 'abc123' },
  },
  result: {
    data: {
      getQuote: {
        ...mockQuoteInfo,
        lineItems: [
          {
            __typename: 'PurchaseLineItem',
            title: 'Azure Commitment Discount',
            subtitle: 'Azure CommitmentDiscount',
            isReadyForPricing: false,
            oneTimeDiscount: null,
            groups: null,
            applicableDiscounts: {
              __typename: 'ApplicableDiscounts',
              types: ['Future', 'PriceGuarantee'],
              minStartDate: null,
              datePrecision: 'month',
            },
            id: '123',
          },
          {
            __typename: 'PurchaseLineItem',
            title: 'Azure Commitment Discount',
            subtitle: 'Azure Commitment Discount',
            groups: null,
            oneTimeDiscount: null,
            isReadyForPricing: false,
            applicableDiscounts: {
              __typename: 'ApplicableDiscounts',
              types: ['Future', 'PriceGuarantee'],
              minStartDate: null,
              datePrecision: 'month',
            },
            id: '321',
          },
        ],
      },
    },
  },
};

const quoteResponseMockConfigured: MockedResponse = {
  request: {
    ...quoteResponseMockUnconfigured.request,
  },
  result: {
    data: {
      getQuote: {
        ...mockQuoteInfo,
        lineItems: [
          {
            __typename: 'DiscountLineItem',
            title: 'Azure Commitment Discount',
            subtitle: 'Azure Commitment Discount',
            isReadyForPricing: false,
            groups: null,
            applicableDiscounts: {
              __typename: 'ApplicableDiscounts',
              types: ['Future', 'PriceGuarantee'],
              minStartDate: null,
              datePrecision: 'month',
            },
            id: '123',
            discount: {
              __typename: 'DiscountSimple',
              percentage: 5,
              type: 'Future',
              duration: {
                __typename: 'Duration',
                duration: 'P18M',
              },
              priceAdjustmentType: 'new',
              scope: null,
              priceGuaranteeDate: null,
            },
          },
          {
            __typename: 'DiscountLineItem',
            title: 'Azure Commitment Discount',
            subtitle: 'Azure Commitment Discount',
            groups: null,
            isReadyForPricing: false,
            applicableDiscounts: {
              __typename: 'ApplicableDiscounts',
              types: ['Future', 'PriceGuarantee'],
              minStartDate: null,
              datePrecision: 'month',
            },
            id: '321',
            discount: {
              __typename: 'DiscountSimple',
              percentage: 10,
              type: 'Future',
              duration: {
                __typename: 'Duration',
                duration: 'P18M',
              },
              priceAdjustmentType: 'new',
              scope: null,
              priceGuaranteeDate: null,
            },
          },
        ],
      },
    },
  },
};

export const quoteResponseMockUtils = {
  request: {
    ...quoteResponseMockUnconfigured.request,
  },
  result: {
    data: {
      getQuote: {
        ...mockQuoteInfo,
        lineItems: [
          {
            __typename: 'DiscountLineItem',
            title: 'Azure Commitment Discount',
            subtitle: 'Azure Commitment Discount',
            priceGuaranteeDate: null,
            groups: null,
            isReadyForPricing: false,
            messages: [],
            applicableDiscounts: {
              __typename: 'ApplicableDiscounts',
              types: ['Future', 'PriceGuarantee'],
              minStartDate: null,
              datePrecision: 'month',
            },
            id: '123',
            discount: {
              __typename: 'DiscountSimple',
              percentage: 5,
              type: 'Future',
              duration: {
                __typename: 'Duration',
                duration: 'P18M',
              },
              scope: null,
              priceAdjustmentType: 'new',
              priceGuaranteeDate: null,
            },
          },
          {
            __typename: 'PurchaseLineItem',
            title: 'General Block Blob',
            subtitle: 'Abc 123',
            quantity: 1,
            oneTimeDiscount: null,
            isReadyForPricing: false,
            messages: [],
            product: {
              __typename: 'Product',
              id: 'abc123',
              title: 'General Thing',
              productType: 'Azure',
              productFamily: 'Azure',
              catalogContext: mockedCatalogContext,
            },
            groups: null,
            applicableDiscounts: {
              __typename: 'ApplicableDiscounts',
              types: ['Future'],
              minStartDate: null,
              datePrecision: 'month',
            },
            id: '5678',
            listPrice: 0.00036,
            amount: 0.00036,
          } as any,
        ],
      },
    },
  },
};
export interface DiscountMockProps {
  mockMultiSelect?: boolean;
  mockUnconfigured?: boolean;
  linkText?: string;
}

export const MockedDiscountCard: React.FC<DiscountMockProps> = (props: DiscountMockProps) => {
  let gqlResponse = quoteResponseMockConfigured;

  const selected: string[] = ['123'];
  if (props.mockUnconfigured) {
    gqlResponse = quoteResponseMockUnconfigured;
  }
  if (props.mockMultiSelect) {
    selected.push('321');
  }

  return (
    <MockedProviders
      addTypename={true}
      mocks={[gqlResponse]}
      fragmentMatcher={true}
      quoteId="abc123"
    >
      <DiscountCardLink
        dataAutomationId="DiscountConfigLink"
        linkText={props.linkText}
        selectedIds={selected}
      />
    </MockedProviders>
  );
};
