import { IconButton, InfoButton, mergeClassNames } from 'components';
import { ComboBoxWithProgress, TextBody, TextboxStandard } from 'components/ions';
import * as appSelectors from 'features/app/selectors';
import { validateEmpowermentAsync } from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import { validateEmailFormat } from 'features/proposal/utils';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { EmpowermentValidationRequest } from 'services/empowerment/types';
import { RootState } from 'store/types';

import { ApproverSelectorStyles } from './ApproverSelector.styles';
import { ApproverErrorType, ApproverSet, SelectedApprovers } from './types';

/**
 * Approver Selector props
 * @prop {string} empowermentLevel - Use to generate key to get the approvers and display next to ComboBox
 * @prop {string} policy - Use to generate key to get the approvers and as part of the label
 */
interface OwnProps {
  autoPopulate: boolean;
  empowermentLevel: string;
  policy: string;
  marketCode?: string;
  selectedEmail?: string;
  enteredText?: string;
  errorMessage?: string;
  storageKey: string;
  selectedApprovers: SelectedApprovers;
  order: number;
  setApprover: (set: ApproverSet) => void;
  removeApprover: (key: string) => void;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  loadingEmpowerments: selectors.loadingEmpowerments(state),
  order: props.order,
  empowermentLevel: props.empowermentLevel,
  marketCode: selectors.getMarket(state),
  policy: props.policy,
  getApprovers: (key: string) => selectors.getApprovers(key)(state),
  checkEmailEmpowerment: (empowermentsStoreKey: string, email: string) =>
    selectors.checkEmailEmpowerment(state, empowermentsStoreKey, email),
  getApprovalHierarchyLevel: () => appSelectors.getApprovalHierarchyLevel(state),
});

const dispatchProps = {
  validateEmpowerment: (request: EmpowermentValidationRequest) =>
    validateEmpowermentAsync.request(request),
};

type Props = OwnProps &
  WithStyles<typeof ApproverSelectorStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const ApproverSelectorUnstyled: React.FC<Props> = props => {
  const {
    classes,
    getApprovers,
    empowermentLevel,
    marketCode,
    validateEmpowerment,
    checkEmailEmpowerment,
    policy,
    selectedEmail,
    enteredText,
    errorMessage,
    storageKey,
    selectedApprovers,
    removeApprover,
    setApprover,
    getApprovalHierarchyLevel,
    order,
    autoPopulate,
  } = props;
  const { t } = useTranslation();
  const approvers = getApprovers(storageKey);
  let options: IComboBoxOption[] = [];
  const [isAutoApproversViewOpen, setIsAutoApproversViewOpen] = React.useState(false);

  const onToggle = () => {
    setIsAutoApproversViewOpen(!isAutoApproversViewOpen);
  };

  if (approvers && approvers.length > 0) {
    options =
      approvers &&
      approvers.map<IComboBoxOption>(approver => ({
        key: approver.email,
        text: approver.email,
        disabled: false,
      }));
  }

  // Disable selected approver in other combobox.
  Object.entries(selectedApprovers).forEach(([key, value]) => {
    if (key !== storageKey) {
      options = options.map(item => {
        return { text: item.text, key: item.key, disabled: value.emails.includes(item.text) };
      });
    }
  });

  const onChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    removeApprover(storageKey);

    if (!option) {
      // If entered value is removed then simply return.
      if (!value) {
        return;
      }

      const enteredValue = value || '';
      // Check is enter value is in email format.
      if (!validateEmailFormat(enteredValue)) {
        setApprover({
          key: storageKey,
          emails: [enteredValue],
          isSelectedOption: false,
          errorTypes: [ApproverErrorType.InvalidEmail],
          order,
          autoPopulate: false,
        });
        return;
      }

      // Check if this email is already validated and stored in Store.
      const isEmailValidated = checkEmailEmpowerment(storageKey, enteredValue);

      if (isEmailValidated === undefined) {
        // Check for empowerment.
        const request: EmpowermentValidationRequest = {
          email: enteredValue,
          empowermentLevel: empowermentLevel,
          id: enteredValue,
          market: {
            hierarchyLevel: getApprovalHierarchyLevel(),
            marketCode: marketCode || '',
          },
          policy: policy,
        };
        setApprover({
          key: storageKey,
          emails: [enteredValue],
          isSelectedOption: false,
          errorTypes: [ApproverErrorType.EmpowermentUnknown],
          order,
          autoPopulate: false,
        });
        validateEmpowerment(request);
      } else if (isEmailValidated) {
        // add email to selectedApprovers because its validated and empowered.
        setApprover({
          key: storageKey,
          emails: [enteredValue],
          isSelectedOption: false,
          errorTypes: [],
          order,
          autoPopulate: false,
        });
      } else {
        // add email to selectedApprovers because its validated but not empowered.
        setApprover({
          key: storageKey,
          emails: [enteredValue],
          isSelectedOption: false,
          errorTypes: [ApproverErrorType.EmailNotEmpowered],
          order,
          autoPopulate: false,
        });
      }
    } else {
      setApprover({
        key: storageKey,
        emails: [option.text],
        isSelectedOption: true,
        errorTypes: [],
        order,
        autoPopulate: false,
      });
    }
  };

  const renderAutoPopulatedApprovers = () =>
    options.map((item, index) => {
      return (
        <div className={classes.autopopulateContainer} key={index}>
          <TextboxStandard disabled={true} value={item.text} />
          <div className={classes.autopopulatesSelectorLevel}>
            <TextBody>{empowermentLevel}</TextBody>
          </div>
        </div>
      );
    });

  const chevron = isAutoApproversViewOpen ? 'ChevronDown' : 'ChevronRight';
  const numberOfApprovers = options && options.length;
  const showChevronForApprovers = numberOfApprovers > 7;

  const renderAutoPopulatedApproversBlock = () => (
    <div className={classes.approversContainer}>
      <TextBody addClass={classes.label}>
        {t('quote::{{policy}} approver', { policy: policy })}
      </TextBody>
      <TextBody addClass={mergeClassNames([classes.label, classes.textTertiary])}>
        {t('quote:: (Auto-populated)')}
      </TextBody>
      <InfoButton
        ariaLabel={t('Open information about auto populated approvers')}
        calloutProps={{
          closeButtonAriaLabel: t('Close'),
          headline: t('quote::Auto-populated approvers'),
          maxWidth: 285,
        }}
        id="auto-populated-approvers-info-button"
      >
        <TextBody>
          {t(
            'quote::Specific policy owners have indicated that approval requests be routed to an entire team of approvers to ensure proper coverage and to support the workloads of the approvers.'
          )}
        </TextBody>
      </InfoButton>
      {showChevronForApprovers && (
        <div className={classes.autopopulateLabelContainer}>
          <IconButton addClass={classes.chevron} iconName={chevron} onClick={onToggle} />
          <TextBody addClass={classes.showmoreLabel}>
            {t('quote::{{numberOfApprovers}} approvers available to approve', {
              numberOfApprovers: numberOfApprovers,
            })}
          </TextBody>
          <div className={classes.showmoreLabelLevel}>
            <TextBody>{empowermentLevel}</TextBody>
          </div>
        </div>
      )}
      {((showChevronForApprovers && isAutoApproversViewOpen) || !showChevronForApprovers) &&
        renderAutoPopulatedApprovers()}
    </div>
  );

  return autoPopulate && options && options.length ? (
    renderAutoPopulatedApproversBlock()
  ) : (
    <div className={classes.selectorContainer}>
      <ComboBoxWithProgress
        allowFreeform
        autoComplete="on"
        errorMessage={errorMessage}
        id="selectApprovers"
        label={t('quote::{{policy}} approver', { policy: policy })}
        maxHeight={250}
        options={options}
        placeholder={t('quote::Select or enter email')}
        progressHidden={options.length !== 0 || !props.loadingEmpowerments}
        required
        selectedKey={selectedEmail}
        styleErrorMessage={classes.errorMessage}
        text={enteredText}
        onChange={onChange}
      />
      <div className={classes.selectorLevel}>
        <TextBody>{empowermentLevel}</TextBody>
      </div>
    </div>
  );
};

export const ApproverSelector = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(ApproverSelectorStyles)(ApproverSelectorUnstyled));
