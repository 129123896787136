import { ThemeProps } from 'styles';

export const supportTicketPanelStyles = (theme: ThemeProps) => {
  return {
    header: { display: 'flex' },
    container: { maxWidth: 580, display: 'flex', flexFlow: 'column' },
    titleBlock: { display: 'flex', flexFlow: 'column', width: 471, marginBottom: 24 },
    title: { paddingBottom: 4 },
    subtitle: {
      fontSize: theme.fonts.fontSizes.large,
      marginBottom: 8,
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    body: {
      marginBottom: 16,
    },
    list: { marginTop: 0, marginBottom: 16, paddingLeft: 16, '& li': { marginBottom: 8 } },
    '& .list li::before': { paddingRight: 12 },

    bodyWarning: {
      marginBottom: 21,
      color: theme.palette.dangerText,
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    button: {
      marginBottom: 32,
      width: 129,
    },
    blockquote: {
      fontSize: theme.fonts.fontSizes.medium,
      fontWeight: theme.fonts.fontWeights.regular,
      color: theme.palette.text,
      background: theme.palette.lineItemHighlight,
      borderRadius: 2,
      padding: 16,
      margin: 0,
      marginBottom: 24,
      maxWidth: 410,
      '& table': { borderSpacing: 0, marginTop: 4 },
    },
    tableTitle: { fontWeight: theme.fonts.fontWeights.semiBold },
    animation: {
      color: theme.palette.approveText,
      height: 30,
      position: 'relative',
      alignItems: 'center',
      display: 'flex',
      marginLeft: 12,
      width: 155,
      '& i': {
        color: theme.palette.approveText,
        paddingTop: 1,
      },
      '& span': {
        fontSize: theme.fonts.fontSizes.medium,
        margin: 6,
      },
    },
    copyContainer: { display: 'flex' },
  };
};
