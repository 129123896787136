import { CloseIconButton, TextBodySmall, TextTitle } from 'components/ions';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import withStyles, { WithStyles } from 'react-jss';

import { sharedDialogStyles } from './Dialog.styles';

/** Must be used with an overlay to produce popup effect
 * * YOU MUST SANITIZE bodyText upstream if it contains html ***
 * @prop {string} id id tag for the outer most div
 * @prop {function} dialogClose function to execute when close button is clicked.
 * @prop {string} headingText text to display in title
 * @prop {string} bodyText text to display in body of dialog
 * @prop {number} height optional property to mnaullay set the height of the dialog box represented in pixels
 * @prop {number} width: optional property to mnaullay set the width of the dialog box represented in pixels
 */
export interface TextDialogBoxProps {
  id?: string;
  dialogClose: () => void;
  headingText: string;
  bodyText: string;
  height?: number;
  width?: number;
  dataAutomationId?: string;
}

type Props = TextDialogBoxProps & WithStyles<typeof sharedDialogStyles>;

const TextDialogUnstyled: React.FC<Props> = (props: Props) => {
  const { id, dataAutomationId, classes, dialogClose, headingText, bodyText } = props;
  return (
    <div
      className={`${classes.contentWrapper} ${classes.size}`}
      data-automation-id={dataAutomationId}
      id={id}
    >
      <div className={classes.heading}>
        <div className={classes.title}>
          <TextTitle>{headingText}</TextTitle>
        </div>
        <div className={classes.closeButton}>
          <CloseIconButton
            ariaLabel="close dialog"
            dataAutomationId={`closeButton-${dataAutomationId}`}
            onClick={dialogClose}
          />
        </div>
      </div>
      <div className={classes.contentBody}>
        <TextBodySmall>{ReactHtmlParser(bodyText)}</TextBodySmall>
      </div>
    </div>
  );
};

export const TextDialogBox = withStyles(sharedDialogStyles)(TextDialogUnstyled) as React.FC<
  TextDialogBoxProps
>;
