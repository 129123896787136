import {
  getDiscountDatesForRender,
  renderLabeledList,
} from 'features-apollo/quote/components/DetailsPane';
import { ExistingDiscountLabels } from 'features-apollo/quote/components/ExistingDiscountsList';
import * as React from 'react';

export interface FutureDiscountDetailProps {
  startDate?: string;
  endDate?: string;
  discountPercent: string;
  labels: ExistingDiscountLabels;
  duration?: string;
  classes: Record<string, string>;
  meters?: string[];
}

export const RenderFutureDiscount: React.FC<FutureDiscountDetailProps> = props => {
  const { discountPercent, startDate, endDate, classes, meters, duration, labels } = props;

  const datesToDisplay = getDiscountDatesForRender(startDate, endDate, duration);

  return (
    <div className={classes.flexCol}>
      {(meters && renderLabeledList(labels.meter, meters, classes)) || null}
      {renderLabeledList(labels.discount, [`${discountPercent}`], classes)}
      {!!datesToDisplay.length && renderLabeledList(labels.duration, datesToDisplay, classes)}{' '}
    </div>
  );
};
