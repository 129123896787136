import { TextWatermark } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 17,
      marginBottom: 17,
      paddingLeft: 17,
      paddingRight: 17,
      textAlign: 'center',
    },
    primaryText: {
      color: palette.textTertiary,
    },
    secondaryText: {
      marginTop: 16,
      color: palette.textTertiary,
    },
  };
};

export interface NotFoundProps {
  primaryText: string;
  secondaryText?: React.ReactNode;
}

type Props = NotFoundProps & WithStyles<typeof styles>;

const NotFoundUnstyled: React.FC<Props> = props => {
  return (
    <div className={props.classes.textContainer}>
      <TextWatermark addClass={props.classes.primaryText}>{props.primaryText}</TextWatermark>
      {props.secondaryText}
    </div>
  );
};

export const NotFound = withStyles(styles)(NotFoundUnstyled);
