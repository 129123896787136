import { ThemeProps } from 'styles';

import { favoriteButtonsSharedStyles } from '../shared.styles';
import { FavoriteButtonProps } from './FavoriteButton';

export const favoriteButtonStyles = (theme: ThemeProps) => {
  return {
    ...favoriteButtonsSharedStyles(theme),
    heartFillIcon: {
      '& i, &:hover i, &:focus i': {
        color: theme.palette.dangerActive,
      },
    },
    heartIcon: (props: FavoriteButtonProps) =>
      props.displayLabel ? {} : { ...favoriteButtonsSharedStyles(theme).hideIcon },
  };
};
