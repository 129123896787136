import { ActionButtonAtom, PersonaAtom } from 'components/atoms';
import {
  IButtonStyles,
  IContextualMenuItemStyles,
  IContextualMenuStyles,
  IMenuItemStyles,
  PersonaSize,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { useTheme } from 'theming';

import { buildButtonMenu, ButtonMenuProps } from '../ButtonSharedProps';
import { meControlButtonStyles } from './MeControlButton.styles';

export interface MeControlButtonProps {
  ariaLabel: string;
  disabled?: boolean;
  id?: string;
  menuItems?: ButtonMenuProps[];
  personaImageAlt?: string;
  personaImageUrl?: string;
  personaName?: string;
  primary?: boolean;
  title?: string;
  onClick?: () => void;
  dataAutomationId?: string;
  isDemoMode?: boolean;
}

type Props = MeControlButtonProps & WithStyles<typeof meControlButtonStyles>;

const MeControlButtonUnStyled: React.FC<Props> = (props: Props) => {
  const theme: ThemeProps = useTheme() as ThemeProps;

  const buttonStyle: Partial<IButtonStyles> = {
    menuIcon: props.classes.menuIcon,
    root: props.classes.root,
    rootExpanded: props.classes.rootExpanded,
  };
  const primaryStyle: Partial<IButtonStyles> = {
    menuIcon: props.classes.menuIcon,
    root: props.classes.rootPrimary,
    rootExpanded: props.classes.rootExpandedPrimary,
  };
  const demoStyle: Partial<IButtonStyles> = {
    menuIcon: props.classes.menuIcon,
    root: props.classes.rootDemo,
    rootExpanded: props.classes.rootExpandedPrimary,
  };
  const menuItemStyle: Partial<IContextualMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  const dropdownStyles: Partial<IContextualMenuStyles> = {
    root: props.classes.menuRoot,
    container: props.classes.menuList,
    subComponentStyles: {
      callout: {
        root: props.classes.contextualCalloutRoot,
        calloutMain: props.classes.calloutMenu,
      },
      menuItem: menuItemStyle,
    },
  };
  const menuItemStyles: Partial<IMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  const buttonThemeStyle = props.isDemoMode
    ? demoStyle
    : props.primary
    ? primaryStyle
    : buttonStyle;

  return (
    <ActionButtonAtom
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      id={props.id}
      menuProps={
        props.menuItems &&
        buildButtonMenu(props.menuItems, dropdownStyles, menuItemStyles, 'MeControlMenu')
      }
      styles={buttonThemeStyle}
      title={props.title}
      onClick={props.onClick}
    >
      <PersonaAtom
        hidePersonaDetails
        imageAlt={props.personaImageAlt}
        imageUrl={props.personaImageUrl}
        initialsColor={theme.palette.meControlPersonaBackground}
        name={props.personaName}
        size={PersonaSize.size28}
      />
    </ActionButtonAtom>
  );
};

export const MeControlButton = withStyles(meControlButtonStyles)(
  MeControlButtonUnStyled
) as React.FC<MeControlButtonProps>;
