import { ThemeProps } from 'styles';

export const propertySheetLayoutStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    flexPageContent: {
      backgroundColor: palette.backgroundCommon,
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 20,
      paddingTop: 32,
      width: '100%',
    },
    flexPageMultipleContent: {
      backgroundColor: palette.backgroundCommon,
      display: 'flex',
      flexDirection: 'row',
      overflowY: 'auto',
      paddingLeft: 0,
      paddingTop: 0,
      width: '100%',
    },
    leftPane: {
      backgroundColor: palette.backgroundCommon,
      flexShrink: 0,
      flexBasis: 148,
      height: '100%',
      overflow: 'hidden',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    columnFlexMainContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: palette.backgroundCommon,
      overflowY: 'auto',
      paddingBottom: 40,
    },
    columnFlex: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: palette.backgroundCommon,
    },
    rowFlex: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
    propertySheetTitleHeader: {
      paddingRight: 8,
      paddingTop: 32,
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      flexBasis: 310,
    },
    mainContent: {
      width: '100%',
      flex: 5,
      display: 'flex',
      flexDirection: 'row',
    },
    flexMainContent: {
      flexShrink: 0,
      maxWidth: 743,
    },
    flexRightEmptySpace: {
      flexShrink: 1,
    },
  };
};
