import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
} from 'components/ions';
import { sharedStyles } from 'features-apollo/quote/components/Dialogs/shared.styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext, ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  semiBoldFont: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
});

export interface TransactWarningProps {
  transactQuote: () => void;
}

export type Props = WithStyles<typeof styles> & TransactWarningProps;

const TransactWarning: React.FC<Props> = props => {
  const { classes, transactQuote } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const Header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="transactQuote"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Transact quote?')}</TextTitle>
    </DialogHeader>
  );

  const Body = (
    <div className={classes.body}>
      <TextBody>
        {t(
          `quote::You are about to transact this quote for the enrollment specified. Any discounts captured in the quote will be committed.`
        )}
      </TextBody>
    </div>
  );

  const Footer = (
    <div className={`${classes.footer} ${classes.buttonRightAlign}`}>
      <PrimaryButton
        dataAutomationId="transactConfirmButton"
        text={t('quote::Transact')}
        onClick={transactQuote}
      />
      <div className={classes.secondButton}>
        <SecondaryButton
          dataAutomationId="transactCancelButton"
          text={t('quote::Cancel')}
          onClick={closeDialog}
        />
      </div>
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={264}
      width={448}
    />
  );
};

export const TransactWarningView = withStyles(styles)(TransactWarning);
