import { LinkButton, TeachingBubble, TextBody } from 'components/ions';
import { getAppEnvironment } from 'features-apollo/app/selectors';
import { AgreementTermLineItem } from 'features-apollo/quote/components/Lists/Rows/AgreementTermRow';
import { useGetUserPermissions } from 'features-apollo/user/hooks';
import { getUserMail } from 'features-apollo/user/selectors';
import { Permission } from 'generated/graphql';
import { DirectionalHint, ITeachingBubbleStyles } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';

import { AgreementSignatoryConfigurationCard } from '../AgreementConfigurationCard/AgreementSignatoryConfigurationCard';
import { AgreementUploadConfigurationCard } from '../AgreementConfigurationCard/AgreementUploadConfigurationCard';
import { AgreementTermButtonStyles } from './AgreementTermButton.styles';
import { ConfigureCardCommonButtonProps } from './types';

export enum AgreementCardType {
  UploadAgreement,
  AddSignatories,
}

export interface AgreementButtonProps extends ConfigureCardCommonButtonProps {
  /**
   * Identifies line item in the quote's line items' list.
   */
  lineItem: AgreementTermLineItem;
  /**
   * Informs which config card to open
   */
  cardType: AgreementCardType;
  /**
   * Disables button
   */
  disable?: boolean;
  /**
   * Cards are read only
   */
  isQuoteReadOnly?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  environment: getAppEnvironment(state),
  userMail: getUserMail(state) || '',
});

type Props = AgreementButtonProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof AgreementTermButtonStyles>;

// TODO: jepagan - complete button implementation
const AgreementTermButtonUnstyled: React.FC<Props> = props => {
  const { cardType } = props;
  const { t } = useTranslation();
  const { permissions } = useGetUserPermissions(props.userMail, props.environment);

  const [isOpen, setIsOpen] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const configuration = props.configuration && props.configuration.trim();

  const hasUploadPermissions =
    permissions && permissions.includes(Permission.SpecialAgreementUpload);
  let displayText: string;
  let title: string | undefined;

  const teachingBubbleStyles: Partial<ITeachingBubbleStyles> = {
    headline: props.classes.teachingBubbleHeadline,
    subComponentStyles: {
      callout: {
        calloutMain: props.classes.teachingBubbleCalloutMain,
        beak: props.classes.teachingBubbleBeak,
        beakCurtain: props.classes.teachingBubbleBeakCurtain,
      },
    },
  };

  const incorrectRoleCallout = (
    <TeachingBubble
      closeButtonAriaLabel={t('quote::close')}
      dataAutomationId="incorrectRoleCallout"
      directionalHint={DirectionalHint.rightCenter}
      headline={t('quote::Upload agreement')}
      styles={teachingBubbleStyles}
      target={containerRef}
      onDismiss={() => setIsOpen(false)}
    >
      <TextBody addClass={props.classes.teachingBubbleText}>
        {t(
          'quote::Only Licensing Executives (LEs) are able to upload agreements that were signed offline. Please reach out to a LE to facilitate the upload of your agreement.'
        )}
      </TextBody>
    </TeachingBubble>
  );

  const renderCard = () => {
    switch (cardType) {
      case AgreementCardType.UploadAgreement: {
        return hasUploadPermissions ? (
          <AgreementUploadConfigurationCard
            agreement={props.lineItem}
            quoteId={props.quoteId}
            readOnly={props.isQuoteReadOnly}
            target={containerRef}
            onDismiss={() => setIsOpen(false)}
          />
        ) : (
          incorrectRoleCallout
        );
      }
      case AgreementCardType.AddSignatories: {
        return (
          <AgreementSignatoryConfigurationCard
            agreement={props.lineItem}
            quoteId={props.quoteId}
            readOnly={props.isQuoteReadOnly}
            target={containerRef}
            onDismiss={() => setIsOpen(false)}
          />
        );
      }
    }
  };

  if (configuration) {
    displayText = configuration;
    title = configuration;
  } else if (props.isConfigurable) {
    displayText = t('quote::configure');
  } else {
    displayText = t('quote::No options');
  }
  return (
    <div className={props.classes.container}>
      <LinkButton
        addClass={props.classes.button}
        dataAutomationId="config-card-button"
        disabled={props.disabled}
        displayText={displayText}
        title={title}
        onClick={() => setIsOpen(!isOpen)}
      />
      <span ref={containerRef} />
      {isOpen && renderCard()}
    </div>
  );
};

export const AgreementCardButtonUnconnected = withStyles(AgreementTermButtonStyles)(
  AgreementTermButtonUnstyled
) as React.FC<AgreementButtonProps>;

export const AgreementCardButton = connect(mapStateToProps)(AgreementCardButtonUnconnected);
