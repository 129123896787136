import { addAndSelectTheKey } from 'features/proposal/components/ConfigCard';
import { IComboBoxOption } from 'office-ui-fabric-react';
import { ActionType, getType } from 'typesafe-actions';

import * as ConfigCardActions from './ConfigCard.actions';
import { CreateLineItemProperties, SkuRow } from './types';

export interface ConfigCardBusinessLogicState {
  hidden?: boolean;
  productHasBillingPlans: boolean;
  recurringCheckEnabled: boolean;
  selectedDurationKey: string;
  selectedListKey?: string;
  selectedLocationKey: string;
  selectedTermsKey?: string;
  selectedTermsDurationKey?: string;
  startDateOptions: IComboBoxOption[];
  selectedStartDateKey?: string;
  selectedSkuRow?: SkuRow;
  combinedSummary?: string;
  createLineItems: CreateLineItemProperties[];
}

//TODO: Test this local reducer
export const reducer = (
  state: ConfigCardBusinessLogicState,
  action: ActionType<typeof ConfigCardActions>
): ConfigCardBusinessLogicState => {
  switch (action.type) {
    case getType(ConfigCardActions.locationSelection): {
      return {
        ...state,
        selectedLocationKey: action.payload,
        selectedListKey: undefined,
        selectedSkuRow: undefined,
      };
    }
    case getType(ConfigCardActions.durationSelection): {
      return {
        ...state,
        selectedDurationKey: action.payload,
        selectedListKey: undefined,
        selectedSkuRow: undefined,
      };
    }
    case getType(ConfigCardActions.listSelection): {
      return {
        ...state,
        selectedListKey: action.payload.key,
        selectedSkuRow: action.payload.skuRow,
      };
    }
    case getType(ConfigCardActions.addLineItems): {
      return {
        ...state,
        createLineItems: action.payload,
        selectedSkuRow: undefined,
      };
    }
    case getType(ConfigCardActions.TermDurationSelection): {
      return {
        ...state,
        selectedTermsDurationKey: action.payload,
      };
    }
    case getType(ConfigCardActions.clearListSelection): {
      return {
        ...state,
        selectedListKey: undefined,
        selectedSkuRow: undefined,
      };
    }
    case getType(ConfigCardActions.termsSelection): {
      return { ...state, selectedTermsKey: action.payload };
    }
    case getType(ConfigCardActions.clearTermDurationSelection): {
      return { ...state, selectedTermsDurationKey: undefined };
    }
    case getType(ConfigCardActions.clearTermSelection): {
      return { ...state, selectedTermsKey: undefined };
    }
    case getType(ConfigCardActions.recurringCheckboxToggled): {
      return { ...state, recurringCheckEnabled: !state.recurringCheckEnabled };
    }
    case getType(ConfigCardActions.startDateAdded): {
      const { newStartDateOptions, newSelectedStartDateKey } = addAndSelectTheKey(
        action.payload,
        state.startDateOptions
      );
      return {
        ...state,
        startDateOptions: newStartDateOptions,
        selectedStartDateKey: newSelectedStartDateKey,
      };
    }
    case getType(ConfigCardActions.startDateSelected): {
      return { ...state, selectedStartDateKey: action.payload };
    }
    case getType(ConfigCardActions.reset): {
      return action.payload;
    }
    case getType(ConfigCardActions.applyConfiguration): {
      return { ...state, hidden: true };
    }
    case getType(ConfigCardActions.cancelPopup): {
      return { ...state, hidden: false };
    }
    default:
      return state;
  }
};
