import { ToolBarAtom } from 'components/atoms';
import { SectionSeparator } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { commandBarStyles } from './CommandBar.styles';

export interface CommandBarProps {
  id?: string;
  useBackgroundStandoutColor?: boolean;
  rightSideCommands?: React.ReactElement | React.ReactElement[];
}

type Props = CommandBarProps & WithStyles<typeof commandBarStyles>;

const CommandBarUnStyled: React.FC<Props> = props => {
  return (
    <div>
      <ToolBarAtom
        id={props.id}
        mainClass={
          props.useBackgroundStandoutColor
            ? props.classes.backgroundStandout
            : props.classes.commandBarStyle
        }
        rightSideCommands={props.rightSideCommands}
      >
        {props.children}
      </ToolBarAtom>
      <SectionSeparator />
    </div>
  );
};

export const CommandBar = withStyles(commandBarStyles)(CommandBarUnStyled) as React.FC<
  CommandBarProps
>;
