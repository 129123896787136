import { OwnerType, ExistingOwner } from './ExistingOwners.types';

export const createPartialList = (list: string[], roleType: OwnerType) => {
  return list.map((item: string) => {
    return {
      upn: item,
      roles: [roleType],
    };
  });
};

const aggregateRoles = (existingOwners: ExistingOwner[], upns: string[], roleType: OwnerType) => {
  upns.forEach((upn: string) => {
    const existingOwnerUpns: string[] = existingOwners.map(
      (existingOwner: ExistingOwner) => existingOwner.upn
    );
    // upn already exists so we add a role
    if (existingOwnerUpns.includes(upn)) {
      const index = existingOwners.findIndex(
        (existingOwner: ExistingOwner) => existingOwner.upn === upn
      );
      existingOwners[index] = {
        upn: existingOwners[index].upn,
        roles: existingOwners[index].roles.concat([roleType]),
      };
    } else {
      existingOwners.push({
        upn,
        roles: [roleType],
      });
    }
  });
  return existingOwners;
};

export const createCompleteList = (
  tenantOwners: string[],
  tenantContributors: string[],
  tenantAdmins: string[]
) => {
  let existingOwners: ExistingOwner[] = [];
  existingOwners = aggregateRoles(existingOwners, tenantOwners, OwnerType.Owner);
  existingOwners = aggregateRoles(existingOwners, tenantContributors, OwnerType.Contributor);
  existingOwners = aggregateRoles(existingOwners, tenantAdmins, OwnerType.GlobalAdmin);
  return existingOwners;
};
