import gql from 'graphql-tag';

export const CustomerAddressFragment = gql`
  fragment customerAddressFragment on Address {
    companyName
    addressLine1
    addressLine2
    addressLine3
    city
    region
    country
    postalCode
    phoneNumber
    email
  }
`;

export const CustomerOrgFragment = gql`
  fragment customerOrgFragment on OrganizationSimple {
    id
    account {
      id
      description
      primaryTenantId
      tenants {
        tenantId
        tenantName
      }
      assets {
        subscriptionId
      }
    }
    accountId
    address {
      ...customerAddressFragment
    }
    agreementSigned
    assets {
      subscriptionId
    }
    language
    lastInvoiceDate
    name
    tradeName
    type
    vatId
    version
  }
  ${CustomerAddressFragment}
`;

export const CustomerSheetQuoteFragment = gql`
  fragment customerSheetQuoteFragment on Quote {
    id
    etag
    audience
    transactionModel
    agreementType
    motion
    productAudience
    crmLead {
      id
      salesAccount {
        id
        tpid
        address {
          ...customerAddressFragment
        }
      }
    }
    status
    readOnly
    assignedTo
    endCustomer {
      ...customerOrgFragment
    }
    soldTo {
      organization {
        ...customerOrgFragment
      }
    }
    leadOrganization {
      ...customerOrgFragment
    }
    invitedUser {
      email
      oid
      tenant {
        id
      }
    }
    soldToInvitedUser: invitedUser(customerType: SoldToCustomer) {
      email
      tenant {
        id
      }
    }
    endCustomerInvitedUser: invitedUser(customerType: EndCustomer) {
      email
      tenant {
        id
      }
    }
  }
  ${CustomerAddressFragment}
  ${CustomerOrgFragment}
`;
