import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
} from 'components/ions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { sharedStyles } from '../shared.styles';

interface OwnProps {
  closeDialog: () => void;
  dispatchWithdrawAction: () => void;
  height: number;
  width: number;
}

type Props = WithStyles<typeof sharedStyles> & OwnProps;

const WarningViewFeature: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  const Header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="withdrawQuote"
      dialogClose={props.closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Withdraw quote?')}</TextTitle>
    </DialogHeader>
  );

  const Body = (
    <div className={classes.body}>
      <TextBody>
        {t(
          'quote::Withdrawing a quote will return it back to an editable, draft state. The current version will not be available to others until it is re-submitted or re-published.'
        )}
      </TextBody>
    </div>
  );

  const Footer = (
    <div className={`${classes.footer} ${classes.flexAlignRight}`}>
      <PrimaryButton
        dataAutomationId="withdrawQuoteButton"
        text={t('quote::Withdraw')}
        onClick={props.dispatchWithdrawAction}
      />
      <div className={classes.padLeft20}>
        <SecondaryButton
          dataAutomationId="cancelWithdrawQuoteButton"
          text={t('quote::Cancel')}
          onClick={props.closeDialog}
        />
      </div>
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={props.height}
      width={props.width}
    />
  );
};

export const WarningView = withStyles(sharedStyles)(WarningViewFeature);
