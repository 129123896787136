import { ThemeProps } from 'styles';

export const sectionSeparatorStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    sectionSeparator: {
      backgroundColor: palette.backgroundDivider,
      height: 1,
    },
  };
};
