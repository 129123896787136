import { AppEnvironment } from 'features/app/config/type';
import { routes } from 'routes';
import { Environment as CRMEnvironment } from 'services/crm/config';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

import { AzureFootprint } from './config/CwaConfig';

export const createLoadingSelector = (actions: string[]) => (state: RootState) =>
  actions.some(action => state.app.loading[action]);

export const createErrorMessageSelector = (actions: string[]) => (state: RootState) => {
  const error = actions.map(action => state.app.errors[action]).find(err => err);
  return error || '';
};

export interface Processing {
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

export const createProcessingSelectors = (actions: string[]) => (state: RootState): Processing => ({
  loading: createLoadingSelector(actions)(state),
  error: createErrorMessageSelector(actions)(state),
});

export const getFlightIsEnabled = (state: RootState, id: Flight) => state.app.flights[id];
export const getAllFlights = (state: RootState) => state.app.flights;
export const getAzureFootPrint = (state: RootState) =>
  AzureFootprint[state.app.appConfig.cwa.environment];

// #region 'App configuration'

export const getAppEnvironment = (state: RootState) => state.app.appConfig.app;

export const getApprovalHierarchyLevel = (state: RootState) =>
  state.app.config.approvalHierarchyLevel;

export const getSuppressedMessages = (state: RootState) => state.app.config.suppressedMessages;

export const getMinCreditLineDivisor = (state: RootState) => state.app.config.minCreditLineDivisor;

export const getWholeNumberRegex = (state: RootState) => state.app.config.wholeNumberRegex;

export const getAgreementConfig = (state: RootState) => state.app.appConfig.agreement;

export const getApolloEnvironment = (state: RootState) => state.app.appConfig.apollo.environment;

export const getCRMConfig = (state: RootState) => {
  // ************* Deliverable#23993598 CRM preprod flighting *************
  var msxppeFlightIsEnabled: boolean = getFlightIsEnabled(state, Flight.MSXPPE);
  if (msxppeFlightIsEnabled && state.app.appConfig.app !== AppEnvironment.Prod) {
    return { environment: CRMEnvironment.Int };
  }
  // **********************************************************************
  return state.app.appConfig.crm;
};

//#endregion

export const usingAccountExtensionsTestHeaders = (state: RootState) =>
  state.app.appConfig.accountExtensions.useTestHeader;

export const usingAccountTestHeaders = (state: RootState) =>
  state.app.appConfig.account.useTestHeader;

export const isSkipToMainContentRowEnabled = (state: RootState) =>
  state.router.location.pathname !== routes.content.articlePopout;
