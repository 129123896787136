import { InfoDialog, InfoDialogSlideProps } from 'components';
import * as userActions from 'features/user/actions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import loggerService from 'services/logger-service';
import { DialogContext, DialogProps } from 'styles';
import { firstRunStyles } from './FirstRun.styles';

export interface FirstRunProps {
  id: string;
  slides: InfoDialogSlideProps[];
}

const dispatchProps = {
  updateViewedDialogs: (id: string) => userActions.infoDialogViewed(id),
};

type Props = FirstRunProps & typeof dispatchProps & WithStyles<typeof firstRunStyles>;

const _performance = performance;

export const FirstRunUnstyled: React.FC<Props> = props => {
  const { slides } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const logFRETraversalTime = () => {
    _performance.mark('First Run Experience dialog closed');
    _performance.measure(
      'First Run Experience dialog opened',
      'First Run Experience dialog closed'
    );
    const measure = _performance.getEntriesByType('measure');
    const duration = measure[0].duration;
    loggerService.log({
      name: 'First Run Experience - traversal time',
      measurements: { duration },
    });
    performance.clearMarks();
    performance.clearMeasures();
  };
  const closeDialog = () => {
    logFRETraversalTime();
    props.updateViewedDialogs(props.id);
    context.closeDialog();
  };

  const generateCarouselDots = (currentSlide: number) => (
    <div className={props.classes.carousel}>
      {slides.length > 1 &&
        slides.map((slide: InfoDialogSlideProps, index: number) => {
          const image = index === currentSlide ? 'ActivePip' : 'InactivePip';
          return (
            <img
              alt={`slide ${index + 1} of ${slides.length}`}
              className={index < slides.length - 1 ? props.classes.carouselPip : ''}
              key={`pip-${index + 1}`}
              src={`/images/FirstRun/${image}.png`}
            />
          );
        })}
    </div>
  );

  return (
    <InfoDialog
      backButtonText={t('Back')}
      closeButtonText={t('Close')}
      generateCarousel={generateCarouselDots}
      height={600}
      isMandatory={true}
      nextButtonText={t('Next')}
      slides={slides}
      onCloseDialog={closeDialog}
    />
  );
};

const FirstRunStyled = withStyles(firstRunStyles)(FirstRunUnstyled);

export const FirstRun = connect(null, dispatchProps)(FirstRunStyled);

export const openFirstRunDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  id: string,
  slides: InfoDialogSlideProps[]
) => {
  _performance.mark('First Run Experience dialog opened');
  const dialogProps: DialogProps = {
    providedDialog: <FirstRun id={id} slides={slides} />,
  };
  context.openDialog(dialogProps);
};
