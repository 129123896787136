import gql from 'graphql-tag';

import { UserObjectIdFragment, UserPreferencesObjectIdFragment } from './fragments';

export const GET_USERPERMISSIONS = gql`
  query getUser($mail: String!, $environment: PermissionEnvironment!) {
    getUser(mail: $mail) {
      ...UserObjectId
      permissions(environment: $environment)
    }
  }
  ${UserObjectIdFragment}
`;

export const GET_USERPREFERENCES = gql`
  query GetUserPreferences {
    getUserPreferences {
      ...UserPreferencesObjectId
      dialogsViewed
      theme
    }
  }
  ${UserPreferencesObjectIdFragment}
`;

export const UPDATE_DIALOGVIEWED = gql`
  mutation updateDialogViewed($key: String!) {
    updateDialogViewed(key: $key) {
      ...UserPreferencesObjectId
      dialogsViewed
    }
  }
  ${UserPreferencesObjectIdFragment}
`;

export const UPDATE_THEME = gql`
  mutation updateTheme($theme: PreferredTheme!) {
    updateTheme(theme: $theme) {
      ...UserPreferencesObjectId
      dialogsViewed
      theme
    }
  }
  ${UserPreferencesObjectIdFragment}
`;

export const DELETE_PREFERENCES = gql`
  mutation deletePreferences {
    deletePreferences
  }
`;
