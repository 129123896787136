import { SectionSeparator } from 'components';
import { convertAddressToAddressType } from 'features-apollo/quote/components/PropertySheets/Customer/utils';
import { TenantScenario } from 'features-apollo/quote/components/Wizards';
import { Address as AddressIon } from 'features/components/Address';
import { Market } from 'features/proposal/supported-markets';
import { InvitedUserSimple, OrganizationSimple, QuoteMutationInput } from 'generated/graphql';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import {
  CustomLabels,
  MenuOptions,
  OrganizationInformationContact,
  OrganizationInformationErrorMessage,
  OrganizationInformationHeader,
  OrganizationInformationLanguage,
  OrganizationInformationLinks,
  OrganizationInformationTenant,
  OrganizationInformationVatID,
} from './content';
import { organizationInformationStyles } from './OrganizationInformation.styles';

/**
 * Props for CustomerAdded
 * @prop {boolean} isPartner - Whether or not the quote is assigned to Partner
 * @prop {boolean} isPartnerCustomer - Whether the customer added should be rendered as a regular customer or a partner customer
 */
export interface OrganizationInformationProps {
  /**
   * Required to update the quote object.
   */
  quoteMutationInput?: QuoteMutationInput;
  /**
   * Defines the different scenarios specific for partner or partner-customer
   */
  specificScenario?: TenantScenario;
  /**
   * Disabled all features that allow changes in the organization
   */
  disableEdit?: boolean;
  invitedUser?: InvitedUserSimple | null;
  isQuoteLegacy?: boolean;
  leadOrganizationName?: string;
  organization: OrganizationSimple;
  readOnly?: boolean;
  salesAccountTPID?: string;
  menuOptions?: MenuOptions;
  /**
   * Customize the button label per menu option
   */
  customLabels?: CustomLabels;
  menuButtonAriaLabel?: string;
  /**
   * Whether or not customer's phone number and email can be updated.
   */
  contactInformationEditable?: boolean;
  /**
   * Callback for the remove option in menu
   */
  onRemove?: () => void;
}

type Props = OrganizationInformationProps & WithStyles<typeof organizationInformationStyles>;

const OrganizationInformationUnstyled: React.FC<Props> = props => {
  const { classes, salesAccountTPID, invitedUser, isQuoteLegacy, organization } = props;

  const { accountId, id: organizationId, address } = organization;
  const organizationEditable =
    !props.disableEdit && !organization.agreementSigned && !isQuoteLegacy;

  const header = (
    <OrganizationInformationHeader
      companyName={oc(address).companyName()}
      customLabels={props.customLabels}
      isQuoteLegacy={props.isQuoteLegacy}
      leadOrganizationName={props.leadOrganizationName}
      menuButtonAriaLabel={props.menuButtonAriaLabel}
      menuOptions={props.menuOptions}
      organization={props.organization}
      organizationEditable={organizationEditable}
      readOnly={props.readOnly}
      specificScenario={props.specificScenario}
      onRemove={props.onRemove}
    />
  );

  const errorMessage = (
    <OrganizationInformationErrorMessage
      organizationAddress={address}
      organizationEditable={organizationEditable}
    />
  );

  const links = (
    <OrganizationInformationLinks
      organizationId={organizationId}
      salesAccountTPID={salesAccountTPID}
      specificScenario={props.specificScenario}
    />
  );

  const contactInformation = (
    <div className={classes.sectionSpacing}>
      <OrganizationInformationContact
        accountId={accountId}
        editable={!props.readOnly && !!props.contactInformationEditable}
        organizationEditable={organizationEditable}
        organizationEmail={address.email || undefined}
        organizationId={organizationId}
        organizationPhoneNumber={address.phoneNumber || undefined}
        quoteId={oc(props).quoteMutationInput.id(undefined)}
        specificScenario={props.specificScenario}
      />
    </div>
  );

  const invoiceLanguage = (
    <div className={classes.sectionSpacing}>
      <OrganizationInformationLanguage organizationLanguage={organization.languageInfo} />
    </div>
  );

  const vatID = (
    <div className={classes.sectionSpacing}>
      <OrganizationInformationVatID
        market={oc(address).country('') as Market}
        organizationIdentifier={{ organizationId, accountId }}
        quoteId={oc(props).quoteMutationInput.id(undefined)}
        readOnly={props.readOnly}
        vatId={organization.vatId || undefined}
      />
    </div>
  );

  const tenantInformation = !isQuoteLegacy && (
    <div className={classes.sectionSpacing}>
      <OrganizationInformationTenant
        accountTenants={organization.account.tenants}
        invitedUser={invitedUser}
        organization={{ id: organization.id, accountId: organization.accountId }}
        quoteMutationInput={props.quoteMutationInput}
        readOnly={props.readOnly}
        specificScenario={props.specificScenario}
      />
    </div>
  );

  return (
    <>
      {header}
      {errorMessage}
      <AddressIon
        addClass={classes.font}
        address={convertAddressToAddressType(address)}
        dataAutomationId="organizationAddress"
      />
      {links}
      {contactInformation}
      {invoiceLanguage}
      {vatID}
      {!props.specificScenario && <SectionSeparator addClass={classes.separator} />}
      {tenantInformation}
    </>
  );
};

export const OrganizationInformation = withStyles(organizationInformationStyles)(
  OrganizationInformationUnstyled
);
