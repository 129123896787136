import { MediumIcon, Toggle } from 'components';
import {
  FlightDisplay,
  FlightListValues,
  FlightState,
} from 'features/app/components/Flights/types';
import { sortFlightsByDisplayName } from 'features/app/components/Flights/utils';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { Flight } from 'services/flights/flightList';

const styles = {
  list: {
    height: 'calc(100% - 200px)',
    listStyleType: 'none',
    marginBottom: 0,
    overflowY: 'auto',
    padding: 0,
  },
  expiredContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    marginRight: '6px',
  },
};

type Props = {
  currentFlights: FlightListValues;
  flights: Flight[];
  setFlight: (flight: FlightState) => void;
} & WithStyles<typeof styles>;

const FlightsPanelListUnstyled: React.FC<Props> = props => {
  const { classes, currentFlights, flights, setFlight } = props;

  const sortedFlights: FlightDisplay[] = sortFlightsByDisplayName(flights);

  return (
    <ul className={classes.list}>
      {sortedFlights.map(({ description, display, key, expirationDate }) => {
        const expired = expirationDate && new Date() > expirationDate;
        const expirationDescription =
          expired && expirationDate
            ? `Expiration date: ${expirationDate.toDateString()}`
            : undefined;
        const label = expired ? (
          <div className={classes.expiredContainer}>
            <MediumIcon
              addClass={classes.warningIcon}
              iconName="Warning"
              predefinedStyle="warning"
              title={expirationDescription}
            />
            {display}
          </div>
        ) : (
          display
        );

        return (
          <li key={key}>
            <Toggle
              checked={currentFlights[key]}
              inlineLabel
              label={label}
              title={`${description} - ${key.toString()}`}
              onChange={(ev, checked?: boolean) => setFlight({ key, value: !!checked })}
            />
          </li>
        );
      })}
    </ul>
  );
};

export const FlightsPanelList = withStyles(styles)(FlightsPanelListUnstyled);
