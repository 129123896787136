import { ErrorMessage, TextBody } from 'components';
import { EditLabel } from 'components/molecules/EditLabel/EditLabel';
import { AccountTenant, QuoteMutationInput } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { organizationInformationForJioCspStyles } from '../OrganizationInformationForJioCsp/OrganizationInformationForJioCsp.styles';

export interface OrganizationInformationTenantForJioCspProps {
  quoteMutationInput?: QuoteMutationInput;
  accountTenants: AccountTenant[];
  customerTag?: string | null;
}

type Props = OrganizationInformationTenantForJioCspProps &
  WithStyles<typeof organizationInformationForJioCspStyles>;

const OrganizationInformationTenantForJioCspUnstyled: React.FC<Props> = props => {
  const { classes, accountTenants, quoteMutationInput, customerTag } = props;
  const { t } = useTranslation();

  const selectedTenantInfo = accountTenants[0] ? (
    <>
      <table className={`${classes.table} ${classes.tenantList}`}>
        <thead>
          <tr>
            <th>
              <EditLabel
                ariaLabel={t('quote::Partner tenant ID')}
                dataAutomationId="tenantIdEditLabel"
                hideIcon={true}
                label={t('quote::Tenant ID')}
              />
            </th>
            {accountTenants[0].tenantName && (
              <th>
                <EditLabel
                  ariaLabel={t('quote::Tenant name')}
                  dataAutomationId="tenantNameEditLabel"
                  hideIcon={true}
                  label={t('quote::Tenant name')}
                />
              </th>
            )}
          </tr>
        </thead>
        <tbody data-automation-id="tenantInfo">
          <tr>
            <td>
              <TextBody>{accountTenants[0].tenantId}</TextBody>
            </td>
            {accountTenants[0].tenantName && (
              <td>
                <TextBody>{accountTenants[0].tenantName}</TextBody>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </>
  ) : null;

  const customerTags = customerTag && (
    <>
      <div>
        <EditLabel
          ariaLabel={t('quote::Customer tags')}
          dataAutomationId="customerTagLabel"
          hideIcon={true}
          label={t('quote::Customer tags')}
        />
      </div>
      <div>
        <TextBody>{customerTag}</TextBody>
      </div>
    </>
  );

  if (!quoteMutationInput) {
    return <ErrorMessage mainMessage={t('quote::Unable to load data')} />;
  }
  return (
    <>
      {customerTags}
      {selectedTenantInfo}
    </>
  );
};
export const OrganizationInformationTenantForJioCsp = withStyles(
  organizationInformationForJioCspStyles
)(OrganizationInformationTenantForJioCspUnstyled);
