import { isDate } from 'components/utilities/dates';
import moment from 'moment-timezone';

import { DiscountCardContainerState, DiscountCardDates } from './types';
import { computeEndDatesForCalendar, computeEndOptions, sortDropdownOptions } from './utils';

export const startOptions = (state: DiscountCardContainerState) =>
  sortDropdownOptions(state.futureStartOptions);
export const startDates = (state: DiscountCardContainerState) => {
  const momentMinDate = moment();
  const startDate = state.futureStartOptions.selectedKey;
  const momentMaxDate = moment(momentMinDate)
    .clone()
    .add(3, 'months');
  if (!isDate(startDate)) {
    const newDates = {
      minDate: momentMinDate.toDate(),
      selected: momentMinDate.toDate(),
      maxDate: momentMaxDate.toDate(),
    };
    return newDates;
  }
  const selected = moment(startDate);
  return {
    minDate: momentMinDate.toDate(),
    selected: moment(selected).toDate(),
    maxDate: momentMaxDate.toDate(),
  };
};

export const endCalendarEnabled = (state: DiscountCardContainerState): boolean => {
  return isDate(state.futureStartOptions.selectedKey);
};
export const endDates = (state: DiscountCardContainerState): DiscountCardDates =>
  computeEndDatesForCalendar(state.futureStartOptions, state.futureEndOptions);
export const endOptions = (state: DiscountCardContainerState) =>
  sortDropdownOptions(
    computeEndOptions(state.futureStartOptions.selectedKey, state.futureEndOptions)
  );
export const meterOptions = (state: DiscountCardContainerState) => state.futureMetersOptions;

export const showMeters = (state: DiscountCardContainerState) =>
  !!state.futureMetersOptions.options.length;
