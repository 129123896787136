import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { TextBodySmall } from 'components/ions/Text';
import { proposalListColumnStyles } from './ProposalListColumn.styles';

/**
 * Props for the column header
 *
 * @export
 * @interface ProposalListColumnProps
 */
export interface ProposalListColumnProps {
  /**
   * Unique id for the column
   *
   * @type {string}
   * @memberof ProposalListColumnProps
   */
  id: string;
  /**
   * Text that will be displayed as a label for this column
   *
   * @type {string}
   * @memberof ProposalListColumnProps
   */
  columnLabel: string;
  /**
   * The width of the column
   *
   * @type {number}
   * @memberof ProposalListColumnProps
   */
  width: number;
  /**
   * Aligns the column text
   *
   * @type {('flex-start' | 'center' | 'flex-end')}
   * @memberof ProposalListColumnProps
   */
  alignText?: 'flex-start' | 'center' | 'flex-end';
}
export interface ProposalListColumnRenderProps<T> extends ProposalListColumnProps {
  /**
   * Render function to run when the column data renders
   *
   * @memberof ProposalListColumnRenderProps
   */
  onRender: (item: T, columnProps: ProposalListColumnProps) => JSX.Element;
}

type Props = ProposalListColumnProps & WithStyles<typeof proposalListColumnStyles>;

const ColumnUnStyled: React.FC<Props> = props => {
  const { classes } = props;
  return (
    <div className={classes.column} style={{ minWidth: props.width }}>
      <TextBodySmall>{props.columnLabel}</TextBodySmall>
    </div>
  );
};

export const ProposalListColumn = withStyles(proposalListColumnStyles)(ColumnUnStyled) as React.FC<
  ProposalListColumnProps
>;
