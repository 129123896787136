import { getFlightIsEnabled } from 'features/app/selectors';
import * as actions from 'features/customer/actions';
import * as proposalActions from 'features/proposal/actions';
import {
  changeMarketPatchCommands,
  hydrateQuoteAll,
  loadCreditInfo,
  patchQuoteHeader,
} from 'features/proposal/sagas/auxiliary';
import {
  getActiveProposal,
  getActiveProposalId,
  getCreditInfo,
  getLeadOrganizationId,
  isExtendedPriceAdjustment,
} from 'features/proposal/selectors';
import { getMarketByCountryCode } from 'features/proposal/supported-markets';
import { getCustomerCommerceAccount } from 'features/proposal/utils';
import { call, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { AccountExtensionsConfig } from 'services/account-extensions/config';
import { CreateTypeCOrganizationFootprintResponse } from 'services/account-extensions/types';
import { AccountConfig } from 'services/account/config';
import { Account } from 'services/account/types';
import { AgreementConfig } from 'services/agreement';
import { Agreement, GetAgreementRequest } from 'services/agreement/types';
import { safelistedCID } from 'services/credit/config';
import { CreditCheckEventType, CreditInfo } from 'services/credit/types';
import { CustomerConfig } from 'services/customer/config';
import { LegalEntity, OrganizationSummary } from 'services/customer/types';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { LineItem } from 'services/proposal/types';
import { Tenant } from 'services/sign-up/types';
import { PatchCommand } from 'services/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';
import { getType } from 'typesafe-actions';

import { getCustomerAccount, getOrganization, hasSuggestedOrganizations } from '../selectors';
import { TenantProfile } from '../types';
import {
  getLeadOrgIdFromAgreement,
  loadAccountsByTenantId,
  loadModernFootprint,
  loadOrganizations,
  loadOrganizationsWithAddress,
  loadTenant,
  updateProposalWithCustomer,
} from './auxiliary';
import { associateOrganizationToSalesAccount } from './auxiliary/associate-sales-account';

export function* updateAccountDescription() {
  const relevantAction = actions.updateAccountDescriptionAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const config: AccountConfig = yield select((state: RootState) => state.app.appConfig.account);
    try {
      const account: Account = yield call(
        api.account.updateAccountDescription,
        action.payload,
        action.meta,
        config
      );

      yield put(actions.updateAccountDescriptionAsync.success(account));
    } catch (err) {
      yield put(
        actions.updateAccountDescriptionAsync.failure({
          message: t('error::Error updating account description'),
          exception: err,
        })
      );
    }
  });
}

export function* selectOrganization() {
  const relevantAction = proposalActions.selectOrganizationAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const { accountId, organizationId } = action.payload;
      let leadOrgId: string | undefined = undefined;

      const agreementsRequest: GetAgreementRequest = { accountId, organizationId };
      const agreementConfig: AgreementConfig = yield select(
        (state: RootState) => state.app.appConfig.agreement
      );
      const agreementsResult: Agreement[] = yield call(
        api.agreement.getSignedAPT,
        agreementsRequest,
        agreementConfig
      );

      if (agreementsResult && agreementsResult.length) {
        // make call to GQL
        // need agreementId from the most recent agreement
        const agreementId = agreementsResult[agreementsResult.length - 1].id;

        try {
          leadOrgId = yield call(getLeadOrgIdFromAgreement, agreementId, accountId, organizationId);
        } catch (err) {
          yield put(
            proposalActions.selectOrganizationAsync.failure({
              message: t('error::Error fetching leadOrgId'),
              exception: err,
            })
          );
          return;
        }
      }

      const proposalId = yield select(getActiveProposalId);
      yield call(
        updateProposalWithCustomer,
        proposalId,
        undefined,
        accountId,
        organizationId,
        leadOrgId
      );

      // associate selected organization to quote's sales account
      yield spawn(associateOrganizationToSalesAccount, {
        accountId,
        organizationId,
      });

      yield put(proposalActions.selectOrganizationAsync.success());
    } catch (err) {
      yield put(
        proposalActions.selectOrganizationAsync.failure({
          message: t('error::Error saving billing account'),
          exception: err,
        })
      );
    }
  });
}

export function* createOrganizationAndAssociate() {
  const relevantAction = actions.createOrganizationAndAssociateAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const accountExtensionsConfig: AccountExtensionsConfig = yield select(
      (state: RootState) => state.app.appConfig.accountExtensions
    );
    try {
      const createOrgRequest = action.payload.createRequest;
      const result: CreateTypeCOrganizationFootprintResponse = yield call(
        api.accountExtensions.createTypeCOrganization,
        createOrgRequest,
        accountExtensionsConfig
      );
      yield call(
        updateProposalWithCustomer,
        action.payload.quoteId,
        undefined,
        result.account.id,
        result.organization.id,
        action.payload.leadOrgId,
        action.payload.user
      );
      // if account exists and description is not available then update the description on the account
      const accountId = createOrgRequest.accountId;
      if (accountId) {
        const account: Account = yield select((state: RootState) =>
          getCustomerAccount(state, accountId)
        );
        if (!account.description) {
          yield put(
            actions.updateAccountDescriptionAsync.request(
              accountId,
              createOrgRequest.accountDescription
            )
          );
        }
      }

      loggerService.log({ name: 'Organization.Create' });

      // associate new billing account to quote's sales account
      yield spawn(associateOrganizationToSalesAccount, {
        accountId: result.account.id,
        organizationId: result.organization.id,
      });

      yield put(actions.createOrganizationAndAssociateAsync.success(result));
    } catch (err) {
      yield put(
        actions.createOrganizationAndAssociateAsync.failure({
          message: t('error::Error creating account and billing account'),
          exception: err,
        })
      );
    }
  });
}

export function* updateOrganization() {
  const relevantAction = actions.updateOrganizationAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const organization = action.payload;
      const soldTo = action.meta;
      const leadOrgId = action.meta.leadOrgId;
      const customerConfig: CustomerConfig = yield select(
        (state: RootState) => state.app.appConfig.customer
      );
      const isSafeListedCreditCheckEnabled: boolean = yield select((state: RootState) =>
        getFlightIsEnabled(state, Flight.safelistedCreditCheck)
      );
      const org: OrganizationSummary = yield select((state: RootState) =>
        getOrganization(state, soldTo.organizationId)
      );

      if (org.legalEntity.tradeName !== organization.tradeName) {
        yield call(
          api.customer.updateTradeName,
          {
            accountId: soldTo.accountId,
            id: soldTo.organizationId,
            request: { tradeName: organization.tradeName, effectiveDate: new Date() },
          },
          customerConfig
        );
      }

      const response: OrganizationSummary = yield call(
        api.customer.updateOrganizationAddress,
        {
          accountId: soldTo.accountId,
          id: soldTo.organizationId,
          request: { address: organization.address, effectiveDate: new Date() },
        },
        customerConfig
      );
      yield spawn(loadModernFootprint, response.accountId, response.id, true);
      const proposal = yield select(getActiveProposal);
      const patchCommands: PatchCommand[] = [
        {
          op: 'replace',
          path: '/soldToCustomerLegalEntity/organizationVersion',
          value: response.version,
        },
        {
          op: 'replace',
          path: '/endCustomerLegalEntity/organizationVersion',
          value: response.version,
        },
      ];
      const market = getMarketByCountryCode(action.payload.address.country);
      if (market && proposal.header.pricingContext.market !== market) {
        patchCommands.push(...changeMarketPatchCommands(market));
      }

      // update parent billing account on quote if changed
      const userPreferences = oc(proposal).header.extendedProperties.userPreferences({});
      const leadOrgIdChanged = userPreferences.leadOrgId !== leadOrgId;
      if (leadOrgIdChanged) {
        if (!proposal.header.extendedProperties) {
          patchCommands.push({ op: 'add', path: '/extendedProperties', value: {} });
        }
        userPreferences.leadOrgId = leadOrgId;
        patchCommands.push({
          op: 'add',
          path: '/extendedProperties/userPreferences',
          value: JSON.stringify(userPreferences),
        });
      }

      const updatedQuote = yield call(patchQuoteHeader, patchCommands, proposal);

      // delete any shared discounts on quote when changing leadOrgId
      if (leadOrgIdChanged) {
        const sharedDiscounts = updatedQuote.lineItems.filter((lineItem: LineItem) =>
          isExtendedPriceAdjustment(lineItem)
        );
        if (sharedDiscounts.length) {
          yield put(
            proposalActions.deleteProposalLineItemsAsync.request({
              etag: updatedQuote.etag,
              lineItemIds: sharedDiscounts.map((lineItem: LineItem) => lineItem.id),
              proposalId: updatedQuote.id,
            })
          );
        }
      }

      // Make a call if credit is not available or currency is changed.
      const creditInfo: CreditInfo = yield select(getCreditInfo);
      if (
        !creditInfo ||
        !creditInfo.reasons.currency ||
        creditInfo.reasons.currency.toUpperCase() !==
          updatedQuote.header.pricingContext.billingCurrency.toUpperCase()
      ) {
        // Invoke Credit look up.
        yield spawn(loadCreditInfo, {
          currency: updatedQuote.header.pricingContext.billingCurrency,
          /* eslint-disable @typescript-eslint/camelcase */
          event_type: CreditCheckEventType.CreditLookUp,
          legalEntity: {
            accountId: isSafeListedCreditCheckEnabled ? safelistedCID.accountId : soldTo.accountId,
            organizationId: isSafeListedCreditCheckEnabled
              ? safelistedCID.organizationId
              : soldTo.organizationId,
          },
        });
      }

      loggerService.log({ name: 'Organization.Update' });

      if (leadOrgIdChanged && leadOrgId) {
        yield call(hydrateQuoteAll, updatedQuote);
      }

      yield put(
        actions.updateOrganizationAsync.success({ id: soldTo.organizationId, value: response })
      );
    } catch (err) {
      yield put(
        actions.updateOrganizationAsync.failure({
          message: t('error::Error updating billing account'),
          exception: err,
        })
      );
    }
  });
}

export function* updateOrganizationContactInformation() {
  const relevantAction = actions.updateOrganizationContactInformationAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const organization: OrganizationSummary = yield select((state: RootState) =>
        getOrganization(state, action.meta.organizationId)
      );
      const updateOrgRequest: LegalEntity = {
        ...organization.legalEntity,
        address: {
          ...organization.legalEntity.address,
          ...action.payload,
        },
      };

      const leadOrgId: string = yield select((state: RootState) => getLeadOrganizationId(state));

      yield put(
        actions.updateOrganizationAsync.request(updateOrgRequest, { ...action.meta, leadOrgId })
      );

      yield put(actions.updateOrganizationContactInformationAsync.success());
    } catch (err) {
      yield put(
        actions.updateOrganizationContactInformationAsync.failure({
          message: t('error::Error updating billing account contact information'),
          exception: err,
        })
      );
    }
  });
}

export function* searchSignupEmail() {
  const relevantAction = actions.searchSignupEmailAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const domain = action.payload;
      const tenantProfile: TenantProfile | undefined = yield call(loadTenant, domain);
      if (!tenantProfile) {
        throw new Error(
          `Cannot proceed, loadTenant resulted in an invalid response. tenant: ${tenantProfile}`
        );
      }
      yield put(actions.searchSignupEmailAsync.success());
    } catch (err) {
      yield put(
        actions.searchSignupEmailAsync.failure({
          message: t('error::Error searching signup email'),
          exception: err,
        })
      );
    }
  });
}

export function* searchTenantFootprint() {
  const relevantAction = actions.searchTenantFootprintAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const tenant = action.payload;
      const tenantInfo: Tenant | undefined = yield call(loadTenant, tenant);
      if (!tenantInfo) {
        throw new Error(
          `Cannot proceed, loadTenant resulted in a invalid response. tenant: ${tenant}`
        );
      }
      const tenantId = tenantInfo.tenantId;
      if (tenantId) {
        const accounts: Account[] | undefined = yield call(loadAccountsByTenantId, tenantId);
        if (accounts) {
          const account = getCustomerCommerceAccount(accounts);
          const accountId = account && account.id;
          if (accountId) {
            yield call(loadOrganizations, accountId);
            const shouldGetOrganizationAddress = yield select((state: RootState) =>
              hasSuggestedOrganizations(state, accountId)
            );
            // Get address for each of the suggested organization
            if (shouldGetOrganizationAddress) {
              yield spawn(loadOrganizationsWithAddress, accountId);
            }
          }
        }
      }
      yield put(actions.searchTenantFootprintAsync.success());
    } catch (err) {
      yield put(
        actions.searchTenantFootprintAsync.failure({
          message: t('error::Error searching tenant footprint'),
          exception: err,
        })
      );
    }
  });
}
