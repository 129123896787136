import { TextDialogBox } from 'components/ions/PopUps/Dialogs/TextDialog';
import { Fail } from 'features/components/dialogs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';
import { oc } from 'ts-optchain';

export interface ViewAgreementErrorDialogBoxProps {
  id?: string;
  isError: boolean;
  customMessage?: string | JSX.Element;
}

type Props = ViewAgreementErrorDialogBoxProps;

export const ViewAgreementErrorDialog: React.FC<Props> = (props: Props) => {
  const dialog = React.useContext(DialogContext);
  const { t } = useTranslation();
  if (props.customMessage) {
    const dialogDimensions = {
      height: 612,
      width: 600,
    };
    return (
      <Fail
        dataAutomationId="AgreementPreviewAgreementErrorDialog"
        {...dialogDimensions}
        closeDialog={() => dialog.closeDialog()}
        message={props.customMessage}
      />
    );
  }
  const message = props.isError
    ? t(
        'quote::We had trouble generating the agreement for this quote. Please try viewing the agreement sometime later or reach out to Quote support for assistance.'
      )
    : t('quote::There are no new terms for the customer to sign as part of this quote.');
  const heading = props.isError ? t('quote::Our Apologies') : t('quote::No New Term');
  return (
    <TextDialogBox
      bodyText={message}
      dataAutomationId="AgreementPreviewDialog"
      dialogClose={() => dialog.closeDialog()}
      headingText={heading}
    />
  );
};

export const openAgreementPreview = (
  dialogBoxProps: ViewAgreementErrorDialogBoxProps,
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
) => {
  const documentUrl = oc(data).getModernAgreementPreview.documentDisplayUri();
  if (documentUrl) {
    window.open(documentUrl);
  } else {
    const dialogProps: DialogProps = {
      providedDialog: <ViewAgreementErrorDialog {...dialogBoxProps} />,
    };
    context.openDialog(dialogProps);
  }
};
