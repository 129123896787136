import { SecondaryButton, Spinner, TextBodySmall } from 'components';
import {
  OrganizationTile,
  OrganizationTileProps,
} from 'features-apollo/quote/components/OrganizationTile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { OrganizationSearchResultTileProps } from '../types';

const styles = (theme: ThemeProps) => {
  return {
    buttonContainer: {
      display: 'flex',
      height: 'fit-content',
      minWidth: 164,
      marginLeft: 12,
      borderLeft: `1px solid ${theme.palette.backgroundDivider}`,
      padding: `20px 4px`,
      flexDirection: 'column',
      alignItems: 'center',
    },
    errorContainer: {
      marginTop: 8,
    },
    errorText: {
      display: 'block',
      color: theme.palette.dangerText,
      fontStyle: 'italic',
      textAlign: 'center',
    },
  };
};

type Props = OrganizationSearchResultTileProps & WithStyles<typeof styles>;

const OrganizationSearchResultTileUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  const errorMessage = props.errored && (
    <div className={classes.errorContainer}>
      <TextBodySmall addClass={classes.errorText}>
        {props.errorMessage || t('quote::Unable to add the billing account.')}
      </TextBodySmall>
      <TextBodySmall addClass={classes.errorText}>{t('quote::Please retry later.')}</TextBodySmall>
    </div>
  );

  const organizationTileProps = props as OrganizationTileProps;

  return (
    <OrganizationTile {...organizationTileProps}>
      <div className={classes.buttonContainer}>
        {props.loading ? (
          <Spinner label={props.loadingLabel || t('quote::adding billing account')} />
        ) : (
          <SecondaryButton
            dataAutomationId={props.id && `${props.id}-button`}
            text={props.buttonLabel || t('quote::Add')}
            onClick={props.onAdd}
          />
        )}
        {errorMessage}
      </div>
    </OrganizationTile>
  );
};

export const OrganizationSearchResultTile = withStyles(styles)(
  OrganizationSearchResultTileUnstyled
);
