import { ErrorMessage, TextBodySmall } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    container: {
      maxWidth: 374,
    },
    icon: {
      color: palette.dangerText,
      paddingTop: 5,
    },
    rightPart: {
      paddingLeft: 12,
    },
    spacing: {
      marginTop: 12,
    },
    header: {
      color: palette.dangerText,
    },
    text: {
      color: palette.textTertiary,
    },
  };
};

type Props = WithStyles<typeof styles>;

export const TenantExistsUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = props;
  return (
    <div className={classes.container}>
      <ErrorMessage addClass={classes.container} mainMessage={t('quote::Tenant exists')}>
        <div>
          <TextBodySmall addClass={classes.text}>
            {t(
              'quote::There is a tenant associated with the domain of the email address above. If the consumer uses this email to login in and you have not identified the tenant, their check out experience will fail.'
            )}
          </TextBodySmall>
          <div className={classes.spacing}>
            <TextBodySmall addClass={classes.text}>
              {t('quote::Please go back and use the above domain of the email to pick the tenant.')}
            </TextBodySmall>
          </div>
        </div>
      </ErrorMessage>
    </div>
  );
};

export const TenantExists = withStyles(styles)(TenantExistsUnstyled);
