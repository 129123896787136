import {
  CalloutCard,
  ChoiceGroup,
  LinkButton,
  SectionSeparator,
  Spinner,
  SpinnerSize,
  TextboxStandard,
  TextWatermark,
} from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import { useDebounce } from 'components/utilities/debounce';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import {
  DiscountCardCeilingSection,
  DiscountCardFutureSection,
  DiscountCardProps,
  DiscountCardState,
  DiscountChoiceGroupProps,
  DiscountDateSelections,
} from 'features-apollo/quote/components/DiscountCard';
import {
  getBulkInput,
  getDefaultDateOptions,
  getDiscountInput,
  getDiscountOptions,
  getInitialDiscountState,
  getRemoveDiscountInput,
  getSelectedDiscountableLineItems,
  isApplyButtonDisabled,
  isValidDiscountType,
  limitDecimalPoint,
  validateDiscountAmount,
} from 'features-apollo/quote/components/DiscountCard/utils';
import { GET_QUOTE } from 'features-apollo/quote/components/queries';
import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { QueryGetQuoteArgs } from 'generated/graphql';
import gql from 'graphql-tag';
import {
  DirectionalHint,
  IChoiceGroupOption,
  IComboBox,
  IComboBoxOption,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { useMutation } from '@apollo/react-hooks';

import { choiceGroupStyles, discountCardStyles } from './DiscountCard.styles';

const ApplyDiscountMutation = gql`
  mutation ApplyDiscountMutation($quote: QuoteMutationInput!, $discount: ApplyDiscountInput!) {
    applyDiscount(quote: $quote, discount: $discount) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

const DiscountChoiceGroup: React.FC<DiscountChoiceGroupProps> = (
  props: DiscountChoiceGroupProps
) => {
  return (
    <ChoiceGroup
      dataAutomationId="discountChoiceGroup"
      disabled={props.isReadOnly}
      options={props.discountTypeChoices}
      selectedKey={props.discountChoice}
      styles={choiceGroupStyles}
      onChange={props.onChangeDiscountType}
    />
  );
};

interface DiscountInputProps {
  value: string;
  onDiscountAmountChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  ariaLabel: string;
  errorMessage?: string;
  errorMessageClass: string;
  discountInputContainerClass: string;
  isReadOnly: boolean;
}

const DiscountInput: React.FC<DiscountInputProps> = (props: DiscountInputProps) => {
  return (
    <div className={props.discountInputContainerClass}>
      <TextboxStandard
        ariaLabel={props.ariaLabel}
        autoFocus
        dataAutomationId="discountTextbox"
        disabled={props.isReadOnly}
        errorMessage={props.errorMessage}
        errorMessageStyle={props.errorMessageClass}
        selectOnFocus
        suffix="%"
        value={props.value}
        onChange={props.onDiscountAmountChange}
      />
    </div>
  );
};

const getDateSection = (
  currentState: DiscountCardState,
  setFutureDates: (dates: DiscountDateSelections) => void,
  setCeilingDates: (dates: DiscountDateSelections) => void,
  setMeterType: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined) => void,
  readOnly: boolean
) => {
  switch (currentState.discountType) {
    case 'Future': {
      return (
        <DiscountCardFutureSection
          {...currentState.futureOptions}
          disabled={currentState.futureOptions.disabled || readOnly}
          meterErrorMessage={currentState.meterErrorMessage}
          meterOptions={currentState.meters}
          setDateState={setFutureDates}
          setMeter={setMeterType}
        />
      );
    }
    case 'PriceGuarantee': {
      return (
        <DiscountCardCeilingSection
          {...currentState.ceilingOptions}
          disabled={currentState.futureOptions.disabled || readOnly}
          setDateState={setCeilingDates}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const inputDebounceMilliseconds = 200;
type Props = DiscountCardProps & WithStyles<typeof discountCardStyles>;

const DiscountCardUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { loading, activeQuote, quoteId } = React.useContext(ActiveQuoteContext);
  const { directionalHint, hidden, onDismiss, selectedItemIds } = props;
  const defaultDateOptions = getDefaultDateOptions(true, new Date());

  let readOnly = false;

  const [state, setDiscountState] = React.useState<DiscountCardState>({
    discountAmount: '0',
    discountType: 'Future',
    futureOptions: defaultDateOptions.futureOptions,
    ceilingOptions: defaultDateOptions.ceilingOptions,
    isBulkDiscount: false,
    enableRemoveDiscount: false,
    selectedDiscountableItems: [],
    selectedIds: selectedItemIds,
    showMixedValuesWaterMark: false,
    errorMessage: undefined,
    meterErrorMessage: undefined,
  });

  const [applyDiscount] = useMutation(ApplyDiscountMutation, {
    update(cache, { data: { applyDiscount } }) {
      const quote = cache.readQuery<QueryGetQuoteData, QueryGetQuoteArgs>({
        query: GET_QUOTE,
        variables: { id: quoteId || '' },
      });
      cache.writeQuery({ query: GET_QUOTE, data: { getQuote: applyDiscount || quote } });
    },
  });

  const applyButtonStrings = {
    ariaLabel: t('quote::Apply'),
    text: t('quote::Apply'),
  };
  const discount$ = useDebounce(inputDebounceMilliseconds);

  const dissmissCard = () => {
    setDiscountState({
      ...state,
      ...getInitialDiscountState(state.selectedDiscountableItems),
      initialStateSet: false,
    });

    onDismiss();
  };

  const setMeterType = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined
  ) => {
    if (option && option.key && !!option.key.toString().trim()) {
      const updatedMeterOptions = state.meters;
      if (updatedMeterOptions) {
        updatedMeterOptions.selectedKey = option.key.toString();
      }
      setDiscountState({
        ...state,
        meterErrorMessage: undefined,
        meters: updatedMeterOptions,
      });
    } else {
      setDiscountState({
        ...state,
        meterErrorMessage: t('quote::Invalid meter selection'),
      });
    }
  };

  const setAmount = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      const cleanedValue = limitDecimalPoint(value);

      setDiscountState({
        ...state,
        discountAmount: cleanedValue,
      });

      discount$.next(() => {
        setDiscountState({
          ...state,
          discountAmount: cleanedValue,
          amountError: validateDiscountAmount(
            cleanedValue,
            state.discountType,
            state.isBulkDiscount,
            state.isBulkUnconfigured
          ),
        });
      });
    }
  };

  const setType = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    if (option && option.key && isValidDiscountType(option.key)) {
      setDiscountState({
        ...state,
        discountType: option.key,
        amountError: validateDiscountAmount(state.discountAmount, option.key, state.isBulkDiscount),
      });
    } else {
      throw new Error(
        `invalid discount type was selected for discount configuration on quote ${quoteId || ''} `
      );
    }
  };

  const setFutureDates = (dates: DiscountDateSelections) => {
    const startOptions = dates.startOptions ? dates.startOptions : state.futureOptions.startOptions;
    const endOptions = dates.endOptions ? dates.endOptions : state.futureOptions.endOptions;
    const ranges = dates.dateRanges ? dates.dateRanges : state.futureOptions.dateRanges;

    setDiscountState({
      ...state,
      futureOptions: {
        ...state.futureOptions,
        startOptions: { ...startOptions },
        endOptions: { ...endOptions },
        dateRanges: { ...state.futureOptions.dateRanges, ...ranges },
      },
    });
  };

  const setCeilingDates = (dates: DiscountDateSelections) => {
    const startOptions = dates.startOptions ? dates.startOptions : state.futureOptions.startOptions;
    const endOptions = dates.endOptions ? dates.endOptions : state.futureOptions.endOptions;
    const ranges = dates.dateRanges ? dates.dateRanges : state.futureOptions.dateRanges;
    const priceCeilingOptions = dates.ceilingOptions
      ? dates.ceilingOptions
      : state.ceilingOptions.priceCeilingOptions;

    setDiscountState({
      ...state,
      ceilingOptions: {
        ...state.ceilingOptions,
        startOptions: { ...startOptions },
        endOptions: { ...endOptions },
        dateRanges: { ...ranges },
        priceCeilingOptions: { ...priceCeilingOptions },
      },
    });
  };

  const applyDisabled = isApplyButtonDisabled(
    state.discountAmount,
    state.futureOptions.endOptions.selectedKey,
    state.ceilingOptions.endOptions.selectedKey,
    t('quote::Select a duration'),
    readOnly,
    state.discountType,
    state.isBulkDiscount
  );

  const onApply = (removeDiscount?: boolean) => {
    if ((!removeDiscount && applyDisabled) || (removeDiscount && !state.enableRemoveDiscount)) {
      return;
    }

    const discountConfiguration = removeDiscount
      ? getRemoveDiscountInput(state)
      : state.isBulkDiscount
      ? getBulkInput(state)
      : getDiscountInput(state);

    applyDiscount({
      variables: {
        quote: {
          id: quoteId,
          etag: activeQuote && activeQuote.etag,
        },
        discount: {
          lineItemIds: state.selectedDiscountableItems.map(item => item.id),
          discount: discountConfiguration,
        },
        onError: () => {
          //TODO: handle the error let the user know there was an issue
          console.log('Huston we have a problem');
        },
      },
    });
    dissmissCard();
  };

  let cardBody = null;
  if (loading) {
    cardBody = <Spinner size={SpinnerSize.large} />;
  } else {
    //todo: handle error case here and show error message if unable to continue
    if (!loading && !activeQuote) {
      dissmissCard();
    }

    if (activeQuote && activeQuote.lineItems) {
      const currentDiscountableItems = getSelectedDiscountableLineItems(
        activeQuote.lineItems,
        selectedItemIds
      );

      readOnly = activeQuote.readOnly;

      if (!state.initialStateSet) {
        const initial = getInitialDiscountState(currentDiscountableItems);

        setDiscountState({
          ...state,
          ...initial,
          selectedDiscountableItems: currentDiscountableItems,
          initialStateSet: true,
        });
      }
    }

    const discountOptions = getDiscountOptions(state.selectedDiscountableItems);

    const bulkUpdateMessage = state.isBulkUnconfigured
      ? t('quote::All line items will receive the configured values.')
      : t('quote::Updates will only be made to the modified fields');
    cardBody = (
      <>
        {state.showMixedValuesWaterMark && (
          <div className={props.classes.mixedValues}>
            <TextWatermark>{bulkUpdateMessage}</TextWatermark>
          </div>
        )}
        <DiscountInput
          ariaLabel={t('quote::Modify discount')}
          discountInputContainerClass={
            state.discountAmount === '(mixed)'
              ? mergeClassNames([props.classes.discountInputContainer, props.classes.itallicStyle])
              : props.classes.discountInputContainer
          }
          errorMessage={state.amountError}
          errorMessageClass={props.classes.errorMessageStyle}
          isReadOnly={readOnly}
          value={state.discountAmount}
          onDiscountAmountChange={setAmount}
        />
        <SectionSeparator />

        <DiscountChoiceGroup
          discountChoice={state.discountType}
          discountTypeChoices={discountOptions}
          isReadOnly={readOnly}
          onChangeDiscountType={setType}
        />
        {getDateSection(state, setFutureDates, setCeilingDates, setMeterType, readOnly)}

        {state.enableRemoveDiscount && !readOnly && !state.isBulkDiscount && (
          <div className={props.classes.removeDiscount}>
            <LinkButton
              dataAutomationId="dicountCardRemoveDiscount"
              displayText={t('quote::remove discount')}
              onClick={() => {
                onApply(true);
              }}
            />
          </div>
        )}
      </>
    );
  }

  return hidden ? null : (
    <CalloutCard
      applyButtonDisabled={applyDisabled}
      applyButtonStrings={applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Discount Card')}
      directionalHint={directionalHint || DirectionalHint.rightCenter}
      headerText={t('quote::Discount')}
      id="DiscountConfig"
      isBeakVisible
      isReadOnly={readOnly}
      maxHeight={550}
      minWidth={200}
      target={props.target}
      onApply={onApply}
      onDismiss={dissmissCard}
    >
      {cardBody}
    </CalloutCard>
  );
};

export const DiscountCard = withStyles(discountCardStyles)(DiscountCardUnstyled);
