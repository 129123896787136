/* eslint-disable @typescript-eslint/camelcase */
import { Dialog } from 'components';
import {
  hasInvalidAddressButNoFieldErrors,
  hasValidAddressFields,
  trimAddressFields,
} from 'components/utilities/address';
import { emptyAddress } from 'features/components';
import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/customer/actions';
import {
  canEditAddressOnly,
  getAddressFieldsWithErrors,
  getAddressValidationOriginalAddress,
  getAddressValidationSuggestedAddress,
  getOrganization,
  hasAddressValidationResponse,
  hasSalesAccount,
  isAddressValid,
  processingOrganizationEdit,
} from 'features/customer/selectors';
import { Address } from 'features/customer/types';
import {
  AddressBody,
  AddressFooterButton,
  OrganizationBody,
  OrganizationFooterButton,
  PrimaryButtonType,
} from 'features/proposal/components/Dialogs';
import { Success } from 'features/proposal/components/Dialogs/Shared';
import { Organization } from 'features/proposal/components/organization-types';
import { getAccountId, getActiveProposal, getOrganizationId } from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { LegalEntity, OrganizationType } from 'services/customer/types';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';
import { oc } from 'ts-optchain';

import { editOrganizationWizardStyles } from './EditOrganizationWizard.styles';

const dialogDimensions = {
  height: 612,
  width: 600,
};

export enum EditOrganizationWizardView {
  Organization,
  Address,
  Processing,
}

const mapStateToProps = (state: RootState) => {
  const proposal = getActiveProposal(state);

  let accountId = getAccountId(state, proposal);
  const account = accountId && state.customer.account[accountId];
  let organizationId = getOrganizationId(state, proposal);
  const language = oc(proposal).header.pricingContext.languages('en-US');
  const organization = organizationId && getOrganization(state, organizationId);
  const editAddressOnly = !!(organizationId && canEditAddressOnly(state, organizationId));
  const processingEdit = processingOrganizationEdit(state);
  const hasValidAddressFromService = isAddressValid(state);
  const addressValidationErrors = hasValidAddressFromService
    ? []
    : getAddressFieldsWithErrors(state);
  const validationOriginalAddress = getAddressValidationOriginalAddress(state);
  const validationSuggestedAddress = getAddressValidationSuggestedAddress(state);
  const hasAvsResponse = hasAddressValidationResponse(state);
  const hasAvsApiFailure = state.customer.hasAvsApiFailure;
  const allowSaveOnAddressInvalid =
    hasAvsApiFailure ||
    hasInvalidAddressButNoFieldErrors(
      hasAvsResponse,
      hasValidAddressFromService,
      !!addressValidationErrors.length
    );
  const isSalesAccount = hasSalesAccount(state);

  let address: Address;

  if (!organization || !organizationId || !accountId) {
    accountId = '';
    organizationId = '';
    address = emptyAddress;
    loggerService.error({
      error: new Error(
        `Edit flow should not be triggered if there is no billing account. proposalId: ${proposal.id}`
      ),
    });
  } else {
    address = organization.legalEntity.address;
  }

  return {
    accountId,
    accountName: account && (account.description || ''),
    address,
    addressValidationErrors,
    allowSaveOnAddressInvalid,
    companyName: address.companyName || '',
    editAddressOnly,
    hasAvsApiFailure,
    hasAvsResponse,
    hasValidAddressFromService,
    isSalesAccount,
    language,
    organizationId,
    processingEdit,
    proposal,
    tradeName: organization && organization.legalEntity.tradeName,
    validationOriginalAddress,
    validationSuggestedAddress,
  };
};

const dispatchProps = {
  resetAddressValidation: actions.resetAddressValidation,
  updateOrganization: actions.updateOrganizationAsync.request,
  validateAddress: actions.validateAddressAsync.request,
};

export type EditOrganizationWizardStateProps = typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

export type Props = EditOrganizationWizardStateProps &
  WithStyles<typeof editOrganizationWizardStyles>;

const EditOrganizationWizardUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    accountId,
    addressValidationErrors,
    allowSaveOnAddressInvalid,
    editAddressOnly,
    hasAvsApiFailure,
    hasAvsResponse,
    hasValidAddressFromService,
    isSalesAccount,
    organizationId,
    resetAddressValidation,
    validateAddress,
    validationOriginalAddress,
    validationSuggestedAddress,
  } = props;

  //on mounted
  React.useEffect(() => {
    resetAddressValidation();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region State Management
  const [address, setAddress] = React.useState<Address>(props.address);
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState<EditOrganizationWizardView>(
    EditOrganizationWizardView.Organization
  );

  const [organization, setOrganization] = React.useState<Organization>({
    locked: editAddressOnly,
    name: props.companyName,
    doingBusinessAs: props.tradeName,
  });

  //#endregion

  //address line 2 and 3 cannot be null or empty. Limitation by organization api
  const addressWithCompanyName: Address = {
    ...address,
    addressLine2: address.addressLine2 || undefined,
    addressLine3: address.addressLine3 || undefined,
    companyName: organization.name,
  };
  const notReady = () => setIsValid(false);
  const setCurrentStepFactory = (view: EditOrganizationWizardView) => () => {
    setCurrentStep(view);
    resetAddressValidation();
  };

  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };

  const trimmedAddress = trimAddressFields(addressWithCompanyName);

  const onSubmitClick = () => {
    const updateOrgRequest: LegalEntity = {
      tradeName: organization.doingBusinessAs,
      businessLocationId: '',
      address: { ...trimmedAddress, addressType: OrganizationType.organization },
    };
    setCurrentStep(EditOrganizationWizardView.Processing);
    props.updateOrganization(updateOrgRequest, { accountId, organizationId, leadOrgId: undefined });
  };

  let body;
  let footer: JSX.Element[] | undefined;
  const title = t('quote::Edit billing account');
  switch (currentStep) {
    case EditOrganizationWizardView.Organization: {
      if (!organization && isValid) {
        setIsValid(false);
      }
      body = (
        <OrganizationBody
          initialData={organization}
          onInvalid={notReady}
          onValid={(newOrganization: Organization) => {
            setOrganization(newOrganization);
            setIsValid(true);
          }}
        />
      );
      footer = OrganizationFooterButton(
        setCurrentStepFactory(EditOrganizationWizardView.Address),
        isValid
      );
      break;
    }
    case EditOrganizationWizardView.Address: {
      if (isValid && (!hasValidAddressFields(address) || !hasValidAddressFromService)) {
        setIsValid(false);
      }

      body = (
        <AddressBody
          hasAddressValidationResponse={hasAvsResponse}
          hasAvsApiFailure={hasAvsApiFailure}
          hasValidAddressFromService={hasValidAddressFromService}
          initialData={address}
          isEditFlow
          isSalesAccount={isSalesAccount}
          resetAddressValidation={resetAddressValidation}
          showInitialErrorMessage={false}
          validateAddress={validateAddress}
          validationErrors={addressValidationErrors}
          validationOriginalAddress={validationOriginalAddress}
          validationSuggestedAddress={validationSuggestedAddress}
          onInvalid={() => {
            notReady();
          }}
          onValid={(newAddress: Address) => {
            setAddress(newAddress);
            setIsValid(true);
          }}
        />
      );

      footer = AddressFooterButton(
        allowSaveOnAddressInvalid ? PrimaryButtonType.ProceedAnyway : PrimaryButtonType.Save,
        onSubmitClick,
        setCurrentStepFactory(EditOrganizationWizardView.Organization),
        allowSaveOnAddressInvalid || isValid
      );
      break;
    }
    case EditOrganizationWizardView.Processing: {
      const loadingMessage = t('quote::Update underway, this may take a couple of seconds');
      const successMessage = t('quote::The requested changes were successfully completed.');
      //TODO: Make this smarter.
      //Right now it'll act weird if it fails for one of the edit flows, you close the dialog without completing and open another edit dialog
      const processing = props.processingEdit;
      const tryAgain = onSubmitClick;

      if (processing.loading) {
        return <Processing {...dialogDimensions} key="Processing" message1={loadingMessage} />;
      } else if (processing.error) {
        return (
          <Fail
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="editOrgFail"
            onTryAgainClick={tryAgain}
          />
        );
      } else if (successMessage) {
        return (
          <Success
            message={successMessage}
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="editOrgSuccess"
          />
        );
      } else {
        closeDialog();
        return null;
      }
    }
  }

  return (
    <Dialog
      dataAutomationId="editOrgWizard"
      {...dialogDimensions}
      closeDialog={closeDialog}
      footerButtons={footer}
      hideFooter={!footer}
      title={title}
    >
      {body}
    </Dialog>
  );
};

export const EditOrganizationWizardStyled = withStyles(editOrganizationWizardStyles)(
  EditOrganizationWizardUnstyled
) as React.FC<EditOrganizationWizardStateProps>;

export const EditOrganizationWizard = connect(
  mapStateToProps,
  dispatchProps
)(EditOrganizationWizardStyled);

export const openEditOrganizationWizardDialog = (context: {
  openDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}) => {
  const dialogProps: DialogProps = {
    providedDialog: <EditOrganizationWizard />,
  };
  context.openDialog(dialogProps);
};
