export enum Environment {
  Prod = 'Prod',
  Int = 'Int',
}

export interface CwaConfig {
  environment: Environment;
}

export const AzureFootprint = {
  [Environment.Prod]: 'https://caicustomers.microsoftonline.com/#PreAllocationTPID',
  [Environment.Int]: 'https://int-caicustomers.cloudapp.net/#PreAllocationTPID',
};
