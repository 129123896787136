import { SecondaryButton, TextboxStandard } from 'components';
import { OrgWizardOrganization } from 'features-apollo/quote/components/Wizards/OrganizationWizard';
import { ErrorType } from 'features-apollo/quote/components/Wizards/shared';
import { isInvalidCompanyName } from 'features/customer/utils';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { OrgWizardSharedProps, WizardDialog } from '../Shared';
import { organizationDialogStyles } from './OrganizationNameDialog.styles';

const ORGANIZATION_NAME_MAX_CHARACTERS = 64;
const DBA_NAME_MAX_CHARACTERS = 128;

export interface OrganizationNameDialogProps extends OrgWizardSharedProps<OrgWizardOrganization> {
  onNextButtonClick: (organization: OrgWizardOrganization) => void;
}

type Props = OrganizationNameDialogProps & WithStyles<typeof organizationDialogStyles>;

const OrganizationNameDialogUnstyled: React.FC<Props> = props => {
  const { classes, initialData } = props;
  const { t } = useTranslation();
  const emptyOrg = { organizationName: '' };

  const [organization, setOrganization] = React.useState<OrgWizardOrganization>(
    initialData || emptyOrg
  );
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [dbaErrorMessage, setDbaErrorMessage] = React.useState<string | undefined>();
  const [isValid, setIsValid] = React.useState<boolean>(!!initialData);
  const [errorType, setErrorType] = React.useState<ErrorType | undefined>(undefined);

  React.useEffect(() => {
    const name = organization.organizationName.trim();
    const dba = organization.tradeName ? organization.tradeName.trim() : undefined;
    let errorText: string | undefined;
    let dbaErrorText: string | undefined;

    if (dba && dba.length > DBA_NAME_MAX_CHARACTERS) {
      dbaErrorText = t('quote::Please enter a name that is less than 128 characters');
    }

    if (!name) {
      setIsValid(false);
      setErrorType(ErrorType.missingInformation);
    } else if (isInvalidCompanyName(name)) {
      setIsValid(false);
      setErrorType(ErrorType.invalidField);
      errorText = t('quote::"{{name}}" is not a valid legal name', { name });
    } else if (name.length > ORGANIZATION_NAME_MAX_CHARACTERS) {
      setIsValid(false);
      setErrorType(ErrorType.invalidField);
      errorText = t('quote::Please enter a name that is less than 64 characters');
    } else if (dbaErrorText) {
      setIsValid(false);
      setErrorType(ErrorType.invalidField);
    } else {
      setIsValid(true);
      setErrorType(undefined);
    }

    setErrorMessage(errorText);
    setDbaErrorMessage(dbaErrorText);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const onClickNext = () => {
    props.onNextButtonClick(organization);
  };

  const onClickOverview = () => {
    organization && !errorType ? props.onOverviewClick(organization) : props.onOverviewClick();
  };

  const body = (
    <div>
      <TextboxStandard
        addClass={classes.nameStyles}
        autoFocus
        dataAutomationId="legalName"
        // disabled={organization.locked} TODO: need to verify what this is b/c shouldn't get to this dialog if not editable
        errorMessage={errorMessage}
        label={t('quote::Legal name')}
        maxLength={64}
        placeholder={t('quote::The name they put on legal documents')}
        required
        value={organization.organizationName}
        onBlur={() => {
          const value: OrgWizardOrganization = {
            ...organization,
            organizationName: organization.organizationName.trim(),
          };
          setOrganization(value);
        }}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value: OrgWizardOrganization = {
            ...organization,
            organizationName: newValue || '',
          };
          setOrganization(value);
        }}
      />
      <TextboxStandard
        addClass={classes.nameStyles}
        dataAutomationId="doingBusinessAs"
        // disabled={organization.locked} TODO: need to verify what this is b/c shouldn't get to this dialog if not editable
        errorMessage={dbaErrorMessage}
        label={t('quote::Doing business as')}
        maxLength={128}
        value={organization.tradeName || ''}
        onBlur={() => {
          if (organization.tradeName) {
            const value: OrgWizardOrganization = {
              ...organization,
              tradeName: organization.tradeName.trim(),
            };
            setOrganization(value);
          }
        }}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value: OrgWizardOrganization = {
            ...organization,
            tradeName: newValue || undefined,
          };
          setOrganization(value);
        }}
      />
    </div>
  );

  const footerButtons = [
    <SecondaryButton
      dataAutomationId="organizationDialogNext"
      disabled={!isValid}
      key="next"
      text={i18next.t('quote::Next')}
      onClick={onClickNext}
    />,
  ];

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      errorType={errorType}
      footer={footerButtons}
      headline={props.headline}
      isOverviewAvailable={props.isOverviewAvailable}
      overviewButtonOnly={props.isOverviewAvailable}
      title={t('quote::Organization name')}
      onOverviewClick={onClickOverview}
    />
  );
};

export const OrganizationNameDialog = withStyles(organizationDialogStyles)(
  OrganizationNameDialogUnstyled
);
