import { getCRMConfig } from 'features/app/selectors';
import { CustomerConfig } from 'services/customer/config';
import { OrganizationSummary, TaxId, RemoveTaxIdRequest } from 'services/customer/types';
import * as actions from 'features/customer/actions';
import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import { api } from 'services';
import { AddressValidationConfig } from 'services/address-validation/config';
import { AddressValidationResponse } from 'services/address-validation/types';
import { CRMConfig } from 'services/crm/config';
import { Contact } from 'services/crm/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';
import { GetTenantUpns, TenantUpnResponse, RoleAssignedUpn } from 'services/externaluser/types';
import { ExternalUserConfig } from 'services/externaluser/config';
import { TenantProfile } from '../types';
import { loadAccountsByTenantId, loadTenant, loadTenantNames, loadTenantAdmins } from './auxiliary';
import { AgreementConfig } from 'services/agreement';

export function* loadCRMContact() {
  const relevantAction = actions.loadCRMContactAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const crmConfig: CRMConfig = yield select(getCRMConfig);
      const result: Contact | null = yield call(api.crm.getContact, action.payload, crmConfig);
      yield put(actions.loadCRMContactAsync.success(result));
    } catch (err) {
      yield put(
        actions.loadCRMContactAsync.failure({
          message: t('error::Error getting contact'),
          exception: err,
        })
      );
    }
  });
}

export function* verifyTenant() {
  const relevantAction = actions.verifyTenantAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const tenant: TenantProfile = yield call(loadTenant, action.payload);
      if (tenant && tenant.tenantId && !tenant.isConsumer && !tenant.isViral) {
        yield call(loadAccountsByTenantId, tenant.tenantId);
        yield call(loadTenantNames, [tenant.tenantId]);
        yield call(loadTenantAdmins, [tenant.tenantId]);
      }
      yield put(actions.verifyTenantAsync.success());
    } catch (err) {
      yield put(
        actions.verifyTenantAsync.failure({
          message: t('error::Error verifying tenant'),
          exception: err,
        })
      );
    }
  });
}

export function* validateAddress() {
  const relevantAction = actions.validateAddressAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const config: AddressValidationConfig = yield select(
      (state: RootState) => state.app.appConfig.addressValidation
    );
    try {
      const result: AddressValidationResponse = yield call(
        api.addressValidation.validate,
        action.payload,
        config
      );
      yield put(actions.validateAddressAsync.success(result));
    } catch (err) {
      yield put(
        actions.validateAddressAsync.failure({
          message: t('error::Error validating address'),
          exception: err,
        })
      );
    }
  });
}

export function* addVatIdToOrganization() {
  const relevantAction = actions.addVatIdAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const { accountId, organizationId, organization } = action.payload;
    const customerConfig: CustomerConfig = yield select(
      (state: RootState) => state.app.appConfig.customer
    );
    try {
      const taxIds = organization && organization.legalEntity.taxIds;
      const vatId = taxIds && taxIds.find((taxId: TaxId) => taxId.type === 'vat_id');
      const isExpired = vatId && vatId.endDate;
      if (vatId && !isExpired) {
        const removeRequest: RemoveTaxIdRequest = {
          taxId: vatId.id,
          taxIdReference: vatId.taxIdReference,
          endDate: new Date(),
        };
        const result: OrganizationSummary = yield call(
          api.customer.removeTaxId,
          {
            accountId,
            organizationId,
            request: removeRequest,
          },
          customerConfig
        );
        yield put(
          actions.removeVatIdAsync.success({
            id: result.id,
            value: result,
          })
        );
      }
      const result: OrganizationSummary = yield call(
        api.customer.addTaxId,
        action.payload,
        customerConfig
      );
      yield put(
        actions.addVatIdAsync.success({
          id: organizationId,
          value: result,
        })
      );
    } catch (err) {
      yield put(
        actions.addVatIdAsync.failure({
          message: t('error::Error adding VAT id'),
          exception: err,
        })
      );
    }
  });
}

export function* removeVatIdFromOrganization() {
  const relevantAction = actions.removeVatIdAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const customerConfig: CustomerConfig = yield select(
      (state: RootState) => state.app.appConfig.customer
    );
    try {
      const result: OrganizationSummary = yield call(
        api.customer.removeTaxId,
        action.payload,
        customerConfig
      );
      yield put(
        actions.removeVatIdAsync.success({
          id: result.id,
          value: result,
        })
      );
    } catch (err) {
      yield put(
        actions.removeVatIdAsync.failure({
          message: t('error::Error removing vat id'),
          exception: err,
        })
      );
    }
  });
}

export function* loadTenantUpns() {
  const relevantAction = actions.loadTenantUpnsAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const config: ExternalUserConfig = yield select(
        (state: RootState) => state.app.appConfig.externaluser
      );
      const allTenantUpns = yield all(
        action.payload.map((getTenantUpns: GetTenantUpns) => {
          return call(api.externalUser.getTenantUpn, getTenantUpns, config);
        })
      );
      let roleAssignedUpns: RoleAssignedUpn[] = [];
      allTenantUpns.forEach((allTenantUpn: TenantUpnResponse, index: number) => {
        const roleId = action.payload[index].roleId;
        allTenantUpn.upn.forEach((upn: string) => {
          roleAssignedUpns.push({
            upn,
            roleId,
          });
        });
      });
      yield put(actions.loadTenantUpnsAsync.success(roleAssignedUpns));
      return allTenantUpns;
    } catch (err) {
      yield put(
        actions.loadTenantUpnsAsync.failure({
          message: t('error::Error loading tenant owners'),
          exception: err,
        })
      );
    }
  });
}

export function* uploadDocuments() {
  const relevantAction = actions.uploadDocumentsAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const agreementConfig: AgreementConfig = yield select(
      (state: RootState) => state.app.appConfig.agreement
    );
    try {
      const result = yield call(
        api.agreement.uploadAgreementDocuments,
        action.payload,
        agreementConfig
      );
      yield put(actions.uploadDocumentsAsync.success(result));
    } catch (err) {
      yield put(
        actions.uploadDocumentsAsync.failure({
          message: t('error::Error uploading documents to agreement'),
          exception: err,
        })
      );
    }
  });
}
