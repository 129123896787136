import { Border } from 'components';
import { TextBody, XXLargeIcon } from 'components/ions';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { useTheme } from 'theming';

import {
  dropZoneStyles,
  getSpacerStyles,
  hoveringOverDropZoneStyle,
  initialDropZoneStyle,
} from './ProposalListDropZone.styles';

export enum ProposalListWatermarkType {
  ProductWatermark = 'product',
  TermWatermark = 'term',
}

export interface ProposalListDropZoneProps {
  watermarkType: ProposalListWatermarkType;
  height: number;
  width: number;
  isDraggingOver: boolean;
  isEmptyList?: boolean;
  isDragEvent?: boolean;
}

type Props = ProposalListDropZoneProps & WithStyles<typeof dropZoneStyles>;

const ProposalListDropZoneUnstyled: React.FC<Props> = (props: Props) => {
  const { watermarkType, height, width, isDraggingOver, isEmptyList, isDragEvent, classes } = props;
  const theme: ThemeProps = useTheme() as ThemeProps;
  const { t } = useTranslation();

  /**
   * To position the icon and text for the drag zone correctly height-wise,
   * in the center, we need to offset by half the combined size of the
   * icon, padding, and text (32, 16, and 14, respectively)
   */
  const spacerOffset = 31;

  const initialDropZone = (height: number, width: number) => {
    return (
      <div style={initialDropZoneStyle(theme, height, width)}>
        <Border color={theme.palette.textTertiary} strokeWidth={3} x={width} y={height} />
        <div style={getSpacerStyles(height / 2 - spacerOffset, width / 2)} />
        <XXLargeIcon addClass={classes.iconAdd} iconName="CircleAdditionSolid" />
        <TextBody addClass={classes.dragText}>{t('quote::Add item(s) to quote')}</TextBody>
      </div>
    );
  };

  const hoveredOverDropZone = (height: number, width: number) => {
    return (
      <div style={hoveringOverDropZoneStyle(theme, height, width)}>
        <Border color={theme.palette.primary} strokeWidth={3} x={width} y={height} />
        <div style={getSpacerStyles(height / 2 - spacerOffset, width / 2)} />
        <XXLargeIcon addClass={classes.iconDown} iconName="DrillDownSolid" />
        <TextBody addClass={classes.dragText}>{t('quote::Drop to add item(s)')}</TextBody>
      </div>
    );
  };

  const dropZoneState = () => {
    if (isDraggingOver) {
      return hoveredOverDropZone(height, width);
    } else if (isDragEvent) {
      return initialDropZone(height, width);
    }
  };

  const watermark = () => {
    const finderType =
      watermarkType === ProposalListWatermarkType.ProductWatermark ? 'Product' : 'Term';
    return (
      <div className={classes.watermarkContainer}>
        <TextBody addClass={classes.watermarkText}>
          {t(
            `quote::To add a {{watermarkType}} to the quote, drag or click on a {{watermarkType}} in the {{finderType}} finder.`,
            { watermarkType, finderType }
          )}
        </TextBody>
        <TextBody addClass={classes.watermarkText}>
          {t('quote::All changes are automatically saved.')}
        </TextBody>
      </div>
    );
  };

  const showWatermark = isEmptyList && !isDragEvent;

  return <div>{showWatermark ? watermark() : dropZoneState()}</div>;
};

export const ProposalListDropZone = withStyles(dropZoneStyles)(
  ProposalListDropZoneUnstyled
) as React.FC<ProposalListDropZoneProps>;
