import { Error } from 'features/app/types';
import {
  FilterFavoriteProductsForLegacyRequest,
  NegotiatedTermResponse,
  NegotiatedTermsRequest,
  Product,
  ProductResult,
  ProductSearchRequest,
  ProductSearchSuccess,
  TermSearchRequest,
} from 'services/catalog/types';
import { ValidateConstraintsResponse, ValidateQuantity } from 'services/constraints/types';
import { Channel } from 'services/reco/types';
import { createAsyncAction } from 'store/utils';
import { createStandardAction } from 'typesafe-actions';

import { CatalogPageRequest, CatalogPageResponse, ProductFamilySearchRequest } from './types';

export const loadProductsAsync = createAsyncAction(
  '@@products/LOAD_REQUEST',
  '@@products/LOAD_SUCCESS',
  '@@products/LOAD_FAILURE'
)<string[], ProductResult, Error>();

export const getNegotiatedTerms = createAsyncAction(
  '@@terms/GET_REQUEST',
  '@@terms/GET_SUCCESS',
  '@@terms/GET_FAILURE'
)<NegotiatedTermsRequest, NegotiatedTermResponse, Error>();

export const searchProductsAsync = createAsyncAction(
  '@@products/SEARCH_REQUEST',
  '@@products/SEARCH_SUCCESS',
  '@@products/SEARCH_FAILURE'
)<ProductSearchRequest, ProductSearchSuccess, Error>();

export const searchTermsAsync = createAsyncAction(
  '@@terms/SEARCH_REQUEST',
  '@@terms/SEARCH_SUCCESS',
  '@@terms/SEARCH_FAILURE'
)<TermSearchRequest, ProductSearchSuccess, Error>();

export const filterFavoriteProductsForLegacyAsync = createAsyncAction(
  '@@products/FILTER_FAVORITES_REQUEST',
  '@@products/FILTER_FAVORITES_SUCCESS',
  '@@products/FILTER_FAVORITES_FAILURE'
)<FilterFavoriteProductsForLegacyRequest, Product[], Error>();

export const expandProductSearchAsync = createAsyncAction(
  '@@products/EXPAND_SEARCH_REQUEST',
  '@@products/EXPAND_SEARCH_SUCCESS',
  '@@products/EXPAND_SEARCH_FAILURE'
)<string, ProductSearchSuccess, Error>();

export const searchProductsByFamilyAsync = createAsyncAction(
  '@@productFamily/SEARCH_REQUEST',
  '@@productFamily/SEARCH_SUCCESS',
  '@@productFamily/SEARCH_FAILURE'
)<ProductFamilySearchRequest, ProductSearchSuccess, Error>();

export const searchCatalogPageProducts = createAsyncAction(
  '@@catalog/SEARCH_REQUEST',
  '@@catalog/SEARCH_SUCCESS',
  '@@catalog/SEARCH_FAILURE'
)<CatalogPageRequest, CatalogPageResponse, Error>();

export const clearSearchProducts = createStandardAction('@@products/SEARCH_CLEAR')<void>();
export const clearSearchProductFamily = createStandardAction('@@productFamily/SEARCH_CLEAR')<
  void
>();
export const clearSearchCatalogPageProducts = createStandardAction('@@catalog/SEARCH_CLEAR')<
  void
>();

export const loadRecoAsync = createAsyncAction(
  '@@reco/LOAD_REQUEST',
  '@@reco/LOAD_SUCCESS',
  '@@reco/LOAD_FAILURE'
)<void, Channel[], Error>();

export const validateQuantityConstraintAsync = createAsyncAction(
  '@@catalog/VALIDATE_QUANTITY_CONSTRAINT_REQUEST',
  '@@catalog/VALIDATE_QUANTITY_CONSTRAINT_SUCCESS',
  '@@catalog/VALIDATE_QUANTITY_CONSTRAINT_FAILURE'
)<ValidateQuantity, ValidateConstraintsResponse, Error>();
