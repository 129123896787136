import { SecondaryButton } from 'components/ions';
import * as selectors from 'features/proposal/selectors';
import { hasLineItemsThatAreNotDiscounted } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { TransactDialog } from './TransactDialog';

const mapStateToProps = (state: RootState) => ({
  isAnyBlockingNotification: selectors.isAnyBlockingNotification(state),
  isProposalActiveOrActivable: (proposal: Proposal) =>
    selectors.isProposalActiveOrActivable(proposal),
  proposal: selectors.getActiveProposal(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const TransactButtonFeature: React.FC<Props> = props => {
  const { proposal } = props;

  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const dialogProps: DialogProps = {
    providedDialog: <TransactDialog />,
  };

  const openDialog = () => {
    context.openDialog(dialogProps);
  };
  const disableTransactButton =
    props.isAnyBlockingNotification ||
    !props.isProposalActiveOrActivable(proposal) ||
    !proposal.lineItems.length ||
    hasLineItemsThatAreNotDiscounted(proposal.lineItems);

  return (
    <SecondaryButton
      dataAutomationId="transactButton"
      disabled={disableTransactButton}
      iconName="PublishContent"
      id="transact-button"
      text={t('quote::Transact')}
      onClick={openDialog}
    />
  );
};

export const TransactButton = connect(mapStateToProps)(TransactButtonFeature);
