import { TextSharedProps } from 'components/atoms';
import { TextAtom } from 'components/atoms/Text/TextAtom/TextAtom';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { textBodyStyles } from './Content.styles';
import { useTextOverflow } from './useTextOverflow';

export type TextPredefinedStyle = 'approve' | 'danger' | 'warning';

export interface TextProps extends TextSharedProps {
  emphasized?: boolean;
  required?: boolean;
  dataAutomationId?: string;
  predefinedStyle?: TextPredefinedStyle;
}

type Props = TextProps & WithStyles<typeof textBodyStyles>;

const TextBodyUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom
      addClass={`${props.classes.bodyStyles} ${props.addClass}`}
      dataAutomationId={props.dataAutomationId}
      id={props.id}
      style={props.style}
      title={props.title}
    >
      {props.children}
    </TextAtom>
  );
};

const TextCurrencyUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom
      addClass={`${props.classes.currencyStyles} ${props.addClass}`}
      id={props.id}
      style={props.style}
    >
      {props.children}
    </TextAtom>
  );
};

const TextBodySmallUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom
      addClass={`${props.classes.bodySmallStyles} ${props.addClass}`}
      block={props.block}
      dataAutomationId={props.dataAutomationId}
      id={props.id}
      style={props.style}
      title={props.title}
    >
      {props.children}
      {props.required && <span className={props.classes.asteriskStyles}> *</span>}
    </TextAtom>
  );
};

const TextBodyLargeUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom
      addClass={`${props.classes.bodyLargeStyles} ${props.addClass}`}
      dataAutomationId={props.dataAutomationId}
      id={props.id}
      title={props.title}
    >
      {props.children}
    </TextAtom>
  );
};

const TextBodyXLargeUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom
      addClass={`${props.classes.bodyXLargeStyles} ${props.addClass}`}
      id={props.id}
      title={props.title}
    >
      {props.children}
    </TextAtom>
  );
};

const TextNavBarUnstyled: React.FC<Props> = props => {
  const ref = useTextOverflow();

  return (
    <div className={props.classes.container} ref={ref}>
      <TextAtom addClass={`${props.classes.navBarStyles} ${props.addClass}`} id={props.id}>
        {props.children}
      </TextAtom>
    </div>
  );
};

const TextNavBarPrimaryUnstyled: React.FC<Props> = props => {
  const ref = useTextOverflow();

  return (
    <div className={props.classes.container} ref={ref}>
      <TextAtom
        addClass={`${props.classes.navBarPrimaryStyles} ${props.addClass}`}
        dataAutomationId={props.dataAutomationId}
        id={props.id}
        title={props.title}
      >
        {props.children}
      </TextAtom>
    </div>
  );
};

const TextWatermarkUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom
      addClass={`${props.classes.watermarkStyles} ${props.addClass}`}
      dataAutomationId={props.dataAutomationId}
      id={props.id}
    >
      {props.children}
    </TextAtom>
  );
};

const TextWatermarkSmallUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom addClass={`${props.classes.watermarkSmallStyles} ${props.addClass}`} id={props.id}>
      {props.children}
    </TextAtom>
  );
};

const TextNavigationPrimaryUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom addClass={`${props.classes.navigationPrimaryStyles} ${props.addClass}`} id={props.id}>
      {props.children}
    </TextAtom>
  );
};

const TextNavigationSecondaryUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom
      addClass={`${props.classes.navigationSecondaryStyles} ${props.addClass}`}
      id={props.id}
    >
      {props.children}
    </TextAtom>
  );
};

const TextNavigationSecondarySelectedUnstyled: React.FC<Props> = props => {
  return (
    <TextAtom
      addClass={`${props.classes.navigationSecondarySelectedStyles} ${props.addClass}`}
      id={props.id}
    >
      {props.children}
    </TextAtom>
  );
};

const TextAsteriskUnstyled: React.FC<Props> = props => {
  return (
    <span>
      <TextAtom addClass={`${props.classes.bodyAsteriskStyles} ${props.addClass}`} id={props.id}>
        {props.children}
      </TextAtom>
      {props.required && <span className={props.classes.asteriskStyles}> *</span>}
    </span>
  );
};

export const TextPriceUnstyled: React.FC<Props> = props => {
  return (
    <span>
      <TextAtom addClass={`${props.classes.priceStyles} ${props.addClass}`} id={props.id}>
        {props.children}
      </TextAtom>
    </span>
  );
};

export const TextBody = withStyles(textBodyStyles)(TextBodyUnstyled) as React.FC<TextProps>;
export const TextBodyLarge = withStyles(textBodyStyles)(TextBodyLargeUnstyled) as React.FC<
  TextProps
>;
export const TextBodyXLarge = withStyles(textBodyStyles)(TextBodyXLargeUnstyled) as React.FC<
  TextProps
>;
export const TextCurrency = withStyles(textBodyStyles)(TextCurrencyUnstyled) as React.FC<TextProps>;
export const TextPrice = withStyles(textBodyStyles)(TextPriceUnstyled) as React.FC<TextProps>;
export const TextBodySmall = withStyles(textBodyStyles)(TextBodySmallUnstyled) as React.FC<
  TextProps
>;
export const TextBodyAsterisk = withStyles(textBodyStyles)(TextAsteriskUnstyled) as React.FC<
  TextProps
>;
export const TextNavBarTitle = withStyles(textBodyStyles)(TextNavBarUnstyled) as React.FC<
  TextProps
>;
export const TextNavBarTitlePrimary = withStyles(textBodyStyles)(
  TextNavBarPrimaryUnstyled
) as React.FC<TextProps>;
export const TextWatermark = withStyles(textBodyStyles)(TextWatermarkUnstyled) as React.FC<
  TextProps
>;
export const TextWatermarkSmall = withStyles(textBodyStyles)(
  TextWatermarkSmallUnstyled
) as React.FC<TextProps>;
export const TextNavigationPrimary = withStyles(textBodyStyles)(
  TextNavigationPrimaryUnstyled
) as React.FC<TextProps>;
export const TextNavigationSecondary = withStyles(textBodyStyles)(
  TextNavigationSecondaryUnstyled
) as React.FC<TextProps>;
export const TextNavigationSecondarySelected = withStyles(textBodyStyles)(
  TextNavigationSecondarySelectedUnstyled
) as React.FC<TextProps>;
