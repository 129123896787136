import { PreferredTheme, User, UserPreferences } from 'generated/graphql';

export const USER_MAIL = 'testtest@testtest.testtest';

export const user: User = {
  __typename: 'User',
  mail: USER_MAIL,
  companyName: null,
  displayName: null,
  givenName: null,
  permissions: null,
  photo: null,
};

export const userPreferences: UserPreferences = {
  __typename: 'UserPreferences',
  dialogsViewed: [],
  mail: '',
  theme: PreferredTheme.Light,
  npsSurvey: null,
};
