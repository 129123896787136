import { TextBody } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { tileStyles } from './Tiles.styles';

export interface Tile {
  title?: string;
  content: React.ReactElement;
}

export interface CustomTile {
  content: React.ReactElement;
}
export interface TilesProps {
  addClass?: string;
  id: string;
  tile: Tile[];
  maxWidth: string;
  tileWidth: string;
  hasBorder?: boolean;
  heightFitToContent?: boolean;
}

type Props = TilesProps & WithStyles<typeof tileStyles>;

const renderTitle = (props: Props, index: number, tile: Tile) => {
  if (tile.title) {
    return (
      <TextBody addClass={props.classes.emphasisText} id={`${props.id}-tileTitle-${index}`}>
        {tile.title}
      </TextBody>
    );
  }
  return null;
};

const TilesUnstyled: React.FC<Props> = (props: Props) => {
  const createTile = props.tile.map((tile: Tile, index: number) => {
    return (
      <div
        className={`${props.classes.tile} ${props.addClass} ${props.hasBorder &&
          props.classes.border}`}
        key={index}
        style={{
          flexBasis: props.tileWidth,
          maxWidth: props.tileWidth,
        }}
      >
        <div className={props.classes.tileContent}>
          {renderTitle(props, index, tile)}
          {tile.title ? <div className={props.classes.innerPadding} /> : null}
          <div id={`${props.id}-tileContent-${index}`}>{tile.content}</div>
        </div>
      </div>
    );
  });

  return (
    <div className={props.classes.tileSection} id={props.id} style={{ maxWidth: props.maxWidth }}>
      {createTile}
    </div>
  );
};

export const Tiles = withStyles(tileStyles)(TilesUnstyled) as React.FC<TilesProps>;
