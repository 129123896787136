import { sharedStyles, ThemeProps } from 'styles';

import { LinkAtomProps } from './LinkAtom';

export const linkStyles = (theme: ThemeProps) => {
  return {
    headerIcon: {
      position: 'relative',
      top: '3px',
    },
    link: {
      ...sharedStyles(theme).link,
      display: 'inline-block',
      '&:hover:disabled': {
        color: theme.palette.textDisabled,
      },
    },
    iconStyle: {
      position: 'relative',
      top: '1px',
      left: '5px',
    },
    linkAlignToIcon: {
      padding: '5px',
    },

    root: {
      fontSize: (props: LinkAtomProps) =>
        (props.size && theme.fonts.fontSizes[props.size]) || theme.fonts.fontSizes.small,
    },
  };
};
