import { IComboBoxOption } from 'office-ui-fabric-react';

import { Language, languageInfo } from './supported-languages';
import { Market } from './supported-markets';

export interface InvoiceLanguageInfo {
  country: string;
  locale: string;
  languages: Language[];
}

export const defaultInvoiceLanguage: Language = 'en-US';

// Note: there are a surplus of markets that we currently don't support but we need to support eventually to be able to support the corresponding invoice languages.
// TODO: jaschne rereview this list an edit post-research

export const invoiceLanguageInfo: Record<Market, InvoiceLanguageInfo> = {
  AF: {
    country: 'Afghanistan',
    locale: 'AF',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Åland Islands',
      locale: 'AX',
      languages: ['sv-SE', 'en-US'],
    },
  */
  AL: {
    country: 'Albania',
    locale: 'AL',
    languages: ['en-US'],
  },
  DZ: {
    country: 'Algeria',
    locale: 'DZ',
    languages: ['ar-SA', 'fr-FR', 'en-US'],
  },
  /*
    {
      country: 'American Samoa',
      locale: 'AS',
      languages: ['en-US'],
    },
    {
      country: 'Andorra',
      locale: 'AD',
      languages: ['ca-ES', 'en-US'],
    },
  */
  AO: {
    country: 'Angola',
    locale: 'AO',
    languages: ['en-US', 'pt-PT'],
  },
  /*
    {
      country: 'Anguilla',
      locale: 'AI',
      languages: ['en-US'],
    },
    {
      country: 'Antarctica ',
      locale: 'AQ',
      languages: ['en-US'],
    },
    {
      country: 'Antigua and Barbuda',
      locale: 'AG',
      languages: ['en-US'],
    },
  */
  AR: {
    country: 'Argentina',
    locale: 'AR',
    languages: ['es-ES', 'en-US'],
  },
  AM: {
    country: 'Armenia',
    locale: 'AM',
    languages: ['es-ES', 'en-US'],
  },
  /*
    {
      country: 'Aruba',
      locale: 'AW',
      languages: ['nl-NL', 'en-US'],
    },
  */
  AU: {
    country: 'Australia',
    locale: 'AU',
    languages: ['en-US'],
  },
  AT: {
    country: 'Austria',
    locale: 'AT',
    languages: ['de-DE', 'en-US'],
  },
  AZ: {
    country: 'Azerbaijan',
    locale: 'AZ',
    languages: ['en-US'],
  },
  BS: {
    country: 'Bahamas',
    locale: 'BS',
    languages: ['en-US'],
  },
  BH: {
    country: 'Bahrain',
    locale: 'BH',
    languages: ['en-US', 'ar-SA'],
  },
  BD: {
    country: 'Bangladesh',
    locale: 'BD',
    languages: ['en-US'],
  },
  BB: {
    country: 'Barbados',
    locale: 'BB',
    languages: ['en-US'],
  },
  BY: {
    country: 'Belarus',
    locale: 'BY',
    languages: ['ru-RU', 'en-US'],
  },
  BE: {
    country: 'Belgium',
    locale: 'BE',
    languages: ['fr-FR', 'nl-NL', 'en-US'],
  },
  BZ: {
    country: 'Belize',
    locale: 'BZ',
    languages: ['en-US', 'es-ES'],
  },
  /*
    {
      country: 'Benin',
      locale: 'BJ',
      languages: ['fr-FR', 'en-US'],
    },
  */
  BM: {
    country: 'Bermuda',
    locale: 'BM',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Bhutan',
      locale: 'BT',
      languages: ['en-US'],
    },
  */
  BO: {
    country: 'Bolivia',
    locale: 'BO',
    languages: ['en-US', 'es-ES'],
  },
  /*
    {
      country: 'Bonaire',
      locale: 'BQ',
      languages: ['nl-NL', 'en-US'],
    },
  */
  BA: {
    country: 'Bosnia and Herzegovina',
    locale: 'BA',
    languages: ['en-US'],
  },
  BW: {
    country: 'Botswana',
    locale: 'BW',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Bouvet Island',
      locale: 'BV',
      languages: ['nb-NO', 'en-US'],
    },
  */
  BR: {
    country: 'Brazil',
    locale: 'BR',
    languages: ['en-US', 'pt-BR'],
  },
  /*{
      country: 'British Indian Ocean Territory  ',
      locale: 'IO',
      languages: ['en-GB'],
    },
    {
      country: 'British Virgin Islands',
      locale: 'VG',
      languages: ['en-GB'],
    },
  */
  BN: {
    country: 'Brunei Darussalam',
    locale: 'BN',
    languages: ['en-US', 'ms-MY'],
  },
  BG: {
    country: 'Bulgaria',
    locale: 'BG',
    languages: ['en-US', 'bg-BG'],
  },
  /*
    {
      country: 'Burkina Faso',
      locale: 'BF',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Burundi',
      locale: 'BI',
      languages: ['fr-FR', 'en-US'],
    },
  */
  CV: {
    country: 'Cape Verde',
    locale: 'CV',
    languages: ['en-US', 'pt-PT'],
  },
  /*
    {
      country: 'Cambodia',
      locale: 'KH',
      languages: ['en-US'],
    },
  */
  CM: {
    country: 'Cameroon',
    locale: 'CM',
    languages: ['en-US', 'fr-FR'],
  },
  CA: {
    country: 'Canada',
    locale: 'CA',
    languages: ['en-CA', 'fr-FR'],
  },
  KY: {
    country: 'Cayman Islands',
    locale: 'KY',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Central African Republic',
      locale: 'CF',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Chad',
      locale: 'TD',
      languages: ['fr-FR', 'en-US'],
    },
  */
  CL: {
    country: 'Chile',
    locale: 'CL',
    languages: ['es-ES', 'en-US'],
  },
  CN: {
    country: 'China',
    locale: 'CN',
    languages: ['zh-CN', 'en-US'],
  },
  /*
    {
      country: 'Christmas Island',
      locale: 'CX',
      languages: ['en-US'],
    },
    {
      country: 'Cocos (Keeling) Islands',
      locale: 'CC',
      languages: ['en-US'],
    },
  */
  CO: {
    country: 'Colombia',
    locale: 'CO',
    languages: ['es-ES', 'en-US'],
  },
  /*
    {
      country: 'Comoros',
      locale: 'KM',
      languages: ['fr-FR', 'en-US'],
    },
  */
  CG: {
    country: 'Congo',
    locale: 'CG',
    languages: ['fr-FR', 'en-US'],
  },
  /*
    {
      country: 'Congo (DRC)',
      locale: 'CD',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Cook Islands',
      locale: 'CK',
      languages: ['en-US'],
    },
  */
  CR: {
    country: 'Costa Rica',
    locale: 'CR',
    languages: ['es-ES', 'en-US'],
  },
  CI: {
    country: "Côte d'Ivoire",
    locale: 'CI',
    languages: ['fr-FR', 'en-US'],
  },
  HR: {
    country: 'Croatia',
    locale: 'HR',
    languages: ['hr-HR', 'en-US'],
  },
  CW: {
    country: 'Curaçao',
    locale: 'CW',
    languages: ['nl-NL', 'en-US'],
  },
  CY: {
    country: 'Cyprus',
    locale: 'CY',
    languages: ['el-GR', 'en-US'],
  },
  CZ: {
    country: 'Czech Republic',
    locale: 'CZ',
    languages: ['cs-CZ', 'en-US'],
  },
  DK: {
    country: 'Denmark',
    locale: 'DK',
    languages: ['da-DK', 'en-US'],
  },
  /*{
      country: 'Djibouti',
      locale: 'DJ',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Dominica',
      locale: 'DM',
      languages: ['en-US'],
    },
  */
  DO: {
    country: 'Dominican Republic',
    locale: 'DO',
    languages: ['es-ES', 'en-US'],
  },
  EC: {
    country: 'Ecuador',
    locale: 'EC',
    languages: ['es-ES', 'en-US'],
  },
  EG: {
    country: 'Egypt',
    locale: 'EG',
    languages: ['ar-EG', 'en-US'],
  },
  SV: {
    country: 'El Salvador',
    locale: 'SV',
    languages: ['es-ES', 'en-US'],
  },
  /*
    {
      country: 'Equatorial Guinea',
      locale: 'GQ',
      languages: ['es-ES', 'en-US'],
    },
    {
      country: 'Eritrea',
      locale: 'ER',
      languages: ['ar-AE', 'en-US'],
    },
  */
  EE: {
    country: 'Estonia',
    locale: 'EE',
    languages: ['et-EE', 'en-US'],
  },
  ET: {
    country: 'Ethiopia',
    locale: 'ET',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Falkland Islands',
      locale: 'FK',
      languages: ['en-Us'],
    },
  */
  FO: {
    country: 'Faroe Islands',
    locale: 'FO',
    languages: ['da-DK', 'en-US'],
  },
  FJ: {
    country: 'Fiji',
    locale: 'FJ',
    languages: ['en-US'],
  },
  FI: {
    country: 'Finland',
    locale: 'FI',
    languages: ['fi-FI', 'en-US', 'sv-SE'],
  },
  FR: {
    country: 'France',
    locale: 'FR',
    languages: ['fr-FR', 'en-US'],
  },
  /*
    {
      country: 'French Guiana',
      locale: 'GF',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'French Polynesia',
      locale: 'PF',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'French Southern Territories',
      locale: 'TF',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Gabon',
      locale: 'GA',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Gambia',
      locale: 'GM',
      languages: ['en-US'],
    },
  */
  GE: {
    country: 'Georgia',
    locale: 'GE',
    languages: ['en-US'],
  },
  DE: {
    country: 'Germany',
    locale: 'DE',
    languages: ['de-DE', 'en-US'],
  },
  GH: {
    country: 'Ghana',
    locale: 'GH',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Gibraltar',
      locale: 'GI',
      languages: ['en-US'],
    },
  */
  GR: {
    country: 'Greece',
    locale: 'GR',
    languages: ['el-GR', 'en-US'],
  },
  /*
    {
      country: 'Greenland',
      locale: 'GL',
      languages: ['da-DK', 'en-US'],
    },
    {
      country: 'Grenada',
      locale: 'GD',
      languages: ['en-US'],
    },
    {
      country: 'Guadeloupe',
      locale: 'GP',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Guam',
      locale: 'GU',
      languages: ['en-US'],
    },
  */
  GT: {
    country: 'Guatemala',
    locale: 'GT',
    languages: ['es-ES', 'en-US'],
  },
  /*
    {
      country: 'Guernsey',
      locale: 'GG',
      languages: ['en-US'],
    },
    {
      country: 'Guinea',
      locale: 'GN',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Guinea-Bissau',
      locale: 'GW',
      languages: ['pt-PT', 'en-US'],
    },
    {
      country: 'Guyana',
      locale: 'GY',
      languages: ['en-US'],
    },
    {
      country: 'Haiti',
      locale: 'HT',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Heard Island and McDonald Islands',
      locale: 'HM',
      languages: ['en-US'],
    },
  */
  HN: {
    country: 'Honduras',
    locale: 'HN',
    languages: ['es-ES', 'en-US'],
  },
  HK: {
    country: 'Hong Kong',
    locale: 'HK',
    languages: ['zh-Hant', 'en-US'],
  },
  HU: {
    country: 'Hungary',
    locale: 'HU',
    languages: ['hu-HU', 'en-US'],
  },
  IS: {
    country: 'Iceland',
    locale: 'IS',
    languages: ['en-US'],
  },
  IN: {
    country: 'India',
    locale: 'IN',
    languages: ['en-US'], // TODO: we need to support Hindi as a language
  },
  ID: {
    country: 'Indonesia',
    locale: 'ID',
    languages: ['id-ID', 'en-US'],
  },
  IQ: {
    country: 'Iraq',
    locale: 'IQ',
    languages: ['en-US', 'ar-SA'],
  },
  IE: {
    country: 'Ireland',
    locale: 'IE',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Isle of Man',
      locale: 'IM',
      languages: ['en-US'],
    },
  */
  IL: {
    country: 'Israel',
    locale: 'IL',
    languages: ['he-IL', 'en-US'],
  },
  IT: {
    country: 'Italy',
    locale: 'IT',
    languages: ['it-IT', 'en-US'],
  },
  JM: {
    country: 'Jamaica',
    locale: 'JM',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Jan Mayen',
      locale: 'SJ',
      languages: ['nb-NO', 'en-US'],
    },
  */
  JP: {
    country: 'Japan',
    locale: 'JP',
    languages: ['ja-JP', 'en-US'],
  },
  /*
    {
      country: 'Jersey',
      locale: 'JE',
      languages: ['en-US'],
    },
  */
  JO: {
    country: 'Jordan',
    locale: 'JO',
    languages: ['ar-SA', 'en-US'],
  },
  KZ: {
    country: 'Kazakhstan',
    locale: 'KZ',
    languages: ['en-US', 'ru-RU'], //TODO: need to support language KK
  },
  KE: {
    country: 'Kenya',
    locale: 'KE',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Kiribati',
      locale: 'KI',
      languages: ['en-US'],
    },
  */
  KR: {
    country: 'Korea',
    locale: 'KR',
    languages: ['ko-KR', 'en-US'],
  },
  /*
    {
      country: 'Kosovo',
      locale: 'XK',
      languages: ['Een-USN'],
    },
  */
  KW: {
    country: 'Kuwait',
    locale: 'KW',
    languages: ['ar-SA', 'en-US'],
  },
  KG: {
    country: 'Kyrgyzstan',
    locale: 'KG',
    languages: ['ru-RU', 'en-US'],
  },
  /*
    {
      country: 'Laos',
      locale: 'LA',
      languages: ['en-US'],
    },
  */
  LV: {
    country: 'Latvia',
    locale: 'LV',
    languages: ['lv-LV', 'en-US'],
  },
  LB: {
    country: 'Lebanon',
    locale: 'LB',
    languages: ['ar-SA', 'fr-FR', 'en-US'],
  },
  /*
    {
      country: 'Lesotho',
      locale: 'LS',
      languages: ['en-US'],
    },
    {
      country: 'Liberia',
      locale: 'LR',
      
      languages: ['en-US'],
    },
  */
  LY: {
    country: 'Libya',
    locale: 'LY',
    languages: ['ar-SA', 'en-US'],
  },
  LI: {
    country: 'Liechtenstein',
    locale: 'LI',
    languages: ['de-DE', 'en-US'],
  },
  LT: {
    country: 'Lithuania',
    locale: 'LT',
    languages: ['en-US'], //TODO: need to support language LT
  },
  LU: {
    country: 'Luxemburg',
    locale: 'LU',
    languages: ['de-LU', 'fr-FR', 'en-US'],
  },
  MO: {
    country: 'Macao',
    locale: 'MO',
    languages: ['zh-Hant', 'en-US'],
  },
  MK: {
    country: 'Macedonia (FYRO)',
    locale: 'MK',
    languages: [],
  },
  /*
    {
      country: 'Madagascar',
      locale: 'MG',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Malawi',
      locale: 'MW',
      languages: ['en-US'],
    },
  */
  MY: {
    country: 'Malaysia',
    locale: 'MY',
    languages: ['ms-MY', 'en-US'],
  },
  /*
    {
      country: 'Maldives',
      locale: 'MV',
      languages: ['en-US'],
    },
    {
      country: 'Mali',
      locale: 'ML',
      languages: ['fr-FR', 'en-US'],
    },
  */
  MT: {
    country: 'Malta',
    locale: 'MT',
    languages: ['en-US'],
  },
  MH: {
    country: 'Marshall Islands',
    locale: 'MH',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Martinique',
      locale: 'MQ',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Mauritania',
      locale: 'MR',
      languages: ['ar-SA', 'en-US'],
    },
  */
  MU: {
    country: 'Mauritius',
    locale: 'MU',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Mayotte',
      locale: 'YT',
      languages: ['fr-FR', 'en-US'],
    },
  */
  MX: {
    country: 'Mexico',
    locale: 'MX',
    languages: ['es-ES', 'en-US'],
  },
  /*
    {
      country: 'Micronesia',
      locale: 'FM',
      languages: ['en-US'],
    },
  */
  MD: {
    country: 'Moldova',
    locale: 'MD',
    languages: ['en-US', 'ro-RO'],
  },
  MC: {
    country: 'Monaco',
    locale: 'MC',
    languages: ['en-US', 'fr-FR'],
  },
  MN: {
    country: 'Mongolia',
    locale: 'MN',
    languages: ['en-US'],
  },
  ME: {
    country: 'Montenegro',
    locale: 'ME',
    languages: ['sr-latn-RS', 'en-US', 'sr-cyrl-RS'],
  },
  /*
    {
      country: 'Montserrat',
      locale: 'MS',
      languages: ['en-US'],
    },
  */
  MA: {
    country: 'Morocco',
    locale: 'MA',
    languages: ['fr-FR', 'en-US', 'ar-SA'],
  },
  /*
    {
      country: 'Mozambique',
      locale: 'MZ',
      languages: ['pt-PT', 'en-US'],
    },
    {
      country: 'Myanmar',
      locale: 'MM',
      languages: ['en-US'],
    },
  */
  NA: {
    country: 'Namibia',
    locale: 'NA',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Nauru',
      locale: 'NR',
      languages: ['en-US'],
    },
  */
  NP: {
    country: 'Nepal',
    locale: 'NP',
    languages: ['en-US'],
  },
  NL: {
    country: 'Netherlands',
    locale: 'NL',
    languages: ['en-US', 'nl-NL'],
  },
  /*
    {
      country: 'New Caledonia',
      locale: 'NC',
      languages: ['fr-FR', 'en-US'],
    },
  */
  NZ: {
    country: 'New Zealand',
    locale: 'NZ',
    languages: ['en-US'],
  },
  NI: {
    country: 'Nicaragua',
    locale: 'NI',
    languages: ['en-US', 'es-ES'],
  },
  /*
    {
      country: 'Niger',
      locale: 'NE',
      languages: ['fr-FR', 'en-US'],
    },
  */
  NG: {
    country: 'Nigeria',
    locale: 'NG',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Niue',
      locale: 'NU',
      languages: ['en-US'],
    },
    {
      country: 'Norfolk Island',
      locale: 'NF',
      languages: ['en-US'],
    },
    {
      country: 'Northern Mariana Islands',
      locale: 'MP',
      languages: ['en-US'],
    },
  */
  NO: {
    country: 'Norway',
    locale: 'NO',
    languages: ['en-US', 'nb-NO'],
  },
  OM: {
    country: 'Oman',
    locale: 'OM',
    languages: ['en-US', 'ar-SA'],
  },
  PK: {
    country: 'Pakistan',
    locale: 'PK',
    languages: ['en-US', 'ar-SA'],
  },
  /*
    {
      country: 'Palau',
      locale: 'PW',
      languages: ['en-US'],
    },
  */
  PS: {
    country: 'Palestinian Authority',
    locale: 'PS',
    languages: ['en-US', 'ar-SA'],
  },
  PA: {
    country: 'Panama',
    locale: 'PA',
    languages: ['en-US', 'es-ES'],
  },
  /*
    {
      country: 'Papua New Guinea',
      locale: 'PG',
      languages: ['en-US'],
    },
  */
  PY: {
    country: 'Paraguay',
    locale: 'PY',
    languages: ['en-US', 'es-ES'],
  },
  PE: {
    country: 'Peru',
    locale: 'PE',
    languages: ['en-US', 'es-ES'],
  },
  PH: {
    country: 'Philippines',
    locale: 'PH',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Pitcairn Islands',
      locale: 'PN',
      languages: ['en-US'],
    },
  */
  PL: {
    country: 'Poland',
    locale: 'PL',
    languages: ['en-US', 'pl-PL'],
  },
  PT: {
    country: 'Portugal',
    locale: 'PT',
    languages: ['en-US', 'pt-PT'],
  },
  PR: {
    country: 'Puerto Rico',
    locale: 'PR',
    languages: ['en-US', 'es-ES'],
  },
  QA: {
    country: 'Qatar',
    locale: 'QA',
    languages: ['en-US', 'ar-SA'],
  },
  /*
    {
      country: 'Reunion',
      locale: 'RE',
      languages: ['fr-FR', 'en-US'],
    },
  */
  RO: {
    country: 'Romania',
    locale: 'RO',
    languages: ['en-US', 'ro-RO'],
  },
  RU: {
    country: 'Russia',
    locale: 'RU',
    languages: ['en-US', 'ru-RU'],
  },
  RW: {
    country: 'Rwanda',
    locale: 'RW',
    languages: ['en-US', 'fr-FR'],
  },
  /*
    {
      country: 'Saba',
      locale: 'BQ',
      languages: ['nl-NL', 'en-US'],
    },
    {
      country: 'Saint Barthélemy',
      locale: 'BL',
      languages: ['fr-FR', 'en-US'],
    },
  */
  KN: {
    country: 'Saint Kitts and Nevis',
    locale: 'KN',
    languages: ['en-US'],
  },
  /*
    {
      country: 'Saint Lucia',
      locale: 'LC',
      languages: ['en-US'],
    },
    {
      country: 'Saint Martin',
      locale: 'MF',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Saint Pierre and Miquelon',
      locale: 'PM',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Saint Vincent and the Grenadines',
      locale: 'VC',
      languages: ['en-US'],
    },
    {
      country: 'Samoa',
      locale: 'WS',
      languages: ['en-US'],
    },
    {
      country: 'San Marino',
      locale: 'SM',
      languages: ['it-IT', 'en-US'],
    },
    {
      country: 'São Tomé and Príncipe',
      locale: 'ST',
      languages: ['pt-PT', 'en-US'],
    },
  */
  SA: {
    country: 'Saudi Arabia',
    locale: 'SA',
    languages: ['en-US', 'ar-SA'],
  },
  SN: {
    country: 'Senegal',
    locale: 'SN',
    languages: ['en-US', 'fr-FR'],
  },
  RS: {
    country: 'Serbia',
    locale: 'RS',
    languages: ['sr-latn-RS', 'en-US', 'sr-cyrl-RS'],
  },
  /*
    {
      country: 'Seychelles',
      locale: 'SC',
      languages: ['en-US'],
    },
    {
      country: 'Sierra Leone',
      locale: 'SL',
      languages: ['en-US'],
    },
  */
  SG: {
    country: 'Singapore',
    locale: 'SG',
    languages: ['en-US', 'zh-Hant'],
  },
  /*
    {
      country: 'Sint Eustatius',
      locale: 'BQ',
      languages: ['nl-NL', 'en-US'],
    },
    {
      country: 'Sint Maarten',
      locale: 'SX',
      languages: ['en-US'],
    },
  */
  SK: {
    country: 'Slovakia',
    locale: 'SK',
    languages: ['en-US', 'sk-SK'],
  },
  SI: {
    country: 'Slovenia',
    locale: 'SI',
    languages: ['en-US', 'sl-SI'],
  },
  /*
    {
      country: 'Solomon Islands',
      locale: 'SB',
      languages: ['en-US'],
    },
    {
      country: 'Somalia',
      locale: 'SO',
      languages: ['ar-SA', 'en-US'],
    },
  */
  ZA: {
    country: 'South Africa',
    locale: 'ZA',
    languages: ['en-US'],
  },
  /*
    {
      country: 'South Georgia and South Sandwich Islands',
      locale: 'GS',
      languages: ['en-US'],
    },
    {
      country: 'South Sudan',
      locale: 'SS',
      languages: ['EN'],
    },
  */
  ES: {
    country: 'Spain',
    locale: 'ES',
    languages: ['en-US', 'es-ES', 'ca-ES', 'eu-ES', 'gl-ES'],
  },
  LK: {
    country: 'Sri Lanka',
    locale: 'LK',
    languages: ['en-US'],
  },
  /*
    {
      country: 'St Helena, Ascension, Tristan da Cunha',
      locale: 'SH',
      languages: ['en-US'],
    },
    {
      country: 'Suriname',
      locale: 'SR',
      languages: ['nl-NL', 'en-US'],
    },
    {
      country: 'Svalbard',
      locale: 'SJ',
      languages: ['nb-NO', 'en-US'],
    },
    {
      country: 'Swaziland',
      locale: 'SZ',
      languages: ['en-US'],
    },
  */
  SE: {
    country: 'Sweden',
    locale: 'SE',
    languages: ['en-US', 'sv-SE'],
  },
  CH: {
    country: 'Switzerland',
    locale: 'CH',
    languages: ['en-US', 'de-DE', 'fr-FR', 'it-IT'],
  },
  TW: {
    country: 'Taiwan',
    locale: 'TW',
    languages: ['en-US', 'zh-Hant'],
  },
  TJ: {
    country: 'Tajikistan',
    locale: 'TJ',
    languages: ['en-US', 'ru-RU'],
  },
  TZ: {
    country: 'Tanzania',
    locale: 'TZ',
    languages: ['en-US'],
  },
  TH: {
    country: 'Thailand',
    locale: 'TH',
    languages: ['en-US', 'th-TH'],
  },
  /*
    {
      country: 'Timor-Leste',
      locale: 'TL',
      languages: ['pt-PT', 'en-US'],
    },
    {
      country: 'Togo',
      locale: 'TG',
      languages: ['fr-FR', 'en-US'],
    },
    {
      country: 'Tokelau',
      locale: 'TK',
      languages: ['en-US'],
    },
    {
      country: 'Tonga',
      locale: 'TO',
      languages: ['en-US'],
    },
  */
  TT: {
    country: 'Trinidad and Tobago',
    locale: 'TT',
    languages: ['en-US'],
  },
  TN: {
    country: 'Tunisia',
    locale: 'TN',
    languages: ['en-US', 'fr-FR', 'ar-SA'],
  },
  TR: {
    country: 'Turkey',
    locale: 'TR',
    languages: ['en-US', 'tr-TR'],
  },
  TM: {
    country: 'Turkmenistan',
    locale: 'TM',
    languages: ['en-US', 'ru-RU'],
  },
  /*
    {
      country: 'Turks and Caicos Islands',
      locale: 'TC',
      languages: ['en-US'],
    },
    {
      country: 'Tuvalu',
      locale: 'TV',
      languages: ['en-US'],
    },
    {
      country: 'U.S. Outlying Islands  ',
      locale: 'UM',
      languages: ['en-US'],
    },
  */
  UG: {
    country: 'Uganda',
    locale: 'UG',
    languages: ['en-US'],
  },
  UA: {
    country: 'Ukraine',
    locale: 'UA',
    languages: ['en-US', 'uk-UA'],
  },
  AE: {
    country: 'United Arab Emirates',
    locale: 'AE',
    languages: ['en-US', 'ar-AE'],
  },
  GB: {
    country: 'United Kingdom',
    locale: 'GB',
    languages: ['en-GB'],
  },
  US: {
    country: 'United States',
    locale: 'US',
    languages: ['en-US', 'es-ES'],
  },
  UY: {
    country: 'Uruguay',
    locale: 'UY',
    languages: ['en-US', 'es-ES'],
  },
  UZ: {
    country: 'Uzbekistan',
    locale: 'UZ',
    languages: ['en-US', 'ru-RU'],
  },
  /*
                    {
                      country: 'Vanuatu',
                      locale: 'VU',
                      languages: ['en-US'],
                    },
                    {
                      country: 'Vatican City',
                      locale: 'VA',
                      languages: ['it-IT', 'en-US'],
                    },
                    */
  VE: {
    country: 'Venezuela',
    locale: 'VE',
    languages: ['en-US', 'es-ES'],
  },
  VN: {
    country: 'Vietnam',
    locale: 'VN',
    languages: ['en-US', 'vi-VN'],
  },
  VI: {
    country: 'Virgin Islands, US',
    locale: 'VI',
    languages: ['en-US'],
  },
  /*
                    {
                      country: 'Wallis and Futuna',
                      locale: 'WF',
                      languages: ['fr-FR', 'en-US'],
                    },
                    */
  YE: {
    country: 'Yemen',
    locale: 'YE',
    languages: ['en-US', 'ar-SA'],
  },
  ZM: {
    country: 'Zambia',
    locale: 'ZM',
    languages: ['en-US'],
  },
  ZW: {
    country: 'Zimbabwe',
    locale: 'ZW',
    languages: ['en-US'],
  },
};

export const getComboBoxInvoiceLanguages = (market: Market): IComboBoxOption[] => {
  const languages = invoiceLanguageInfo[market].languages;
  return Object.keys(languages).map((value: string, index: number) => {
    return {
      key: languages[index],
      text: languageInfo[languages[index]].display,
    };
  });
};
