import { InfoCalloutButton, TeachingBubble } from 'components/ions';
import { DirectionalHint, IButtonStyles, ITeachingBubbleStyles } from 'office-ui-fabric-react';
import React from 'react';

export interface InfoButtonProps {
  /**
   * Required for the correct location where the callout will open. Needs to be unique.
   */
  id: string;
  /**
   * This is required because the button does not have a label.
   * This cannot be generic and should be defined based on the specific use.
   */
  ariaLabel: string;
  className?: string;
  styles?: Partial<IButtonStyles>;
  calloutProps: {
    closeButtonAriaLabel: string;
    directionalHint?: DirectionalHint;
    headline: string;
    maxWidth?: number;
    styles?: Partial<ITeachingBubbleStyles>;
  };
}

type Props = InfoButtonProps;

export const InfoButton: React.FC<Props> = props => {
  const [isCalloutOpened, setCalloutOpened] = React.useState<boolean>(false);

  const button = (
    <InfoCalloutButton
      addClass={props.className}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.id}
      iconId={props.id}
      iconName="Info"
      styles={props.styles}
      onClick={() => setCalloutOpened(!isCalloutOpened)}
    />
  );

  const callout = (
    <TeachingBubble
      {...props.calloutProps}
      dataAutomationId={`${props.id}-callout`}
      target={`#${props.id}`}
      onDismiss={() => setCalloutOpened(false)}
    >
      {props.children}
    </TeachingBubble>
  );

  /**
   * It is required to use the span element because, as a text container, it only takes the space of the existing text.
   * This allows the callout to open next to the text in the button without making the button smaller.
   * */
  return (
    <>
      {button}
      {isCalloutOpened && callout}
    </>
  );
};
