import { ActiveQuoteContext, GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { Fail, Processing } from 'features-apollo/components/dialogs';
import { AddOrganization } from 'features-apollo/quote/components/PropertySheets/Customer/queries';
import { OrganizationIdentifierInput } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogContextType, DialogProps } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { FindOrganizationView } from './FindOrganizationView';

export const dialogDimensions = {
  width: 450,
  height: 360,
};

export const FindOrganizationDialog: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { activeQuote } = React.useContext(ActiveQuoteContext);
  const dialogContext = React.useContext(DialogContext);
  const [applyPressed, setApplyPressed] = React.useState(false);
  const [organizationInfo, setOrganizationInfo] = React.useState<
    OrganizationIdentifierInput | undefined
  >();

  const [selectOrg, { error: selectOrgError }] = useMutation(AddOrganization, {
    update(cache, { data: { addOrganization } }) {
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: addOrganization.id },
        data: { getQuote: addOrganization },
      });
    },
    onCompleted: () => {
      dialogContext.closeDialog();
    },
  });

  if (!activeQuote) {
    return (
      <Fail
        {...dialogDimensions}
        dataAutomationId="findOrganizationDialogLoadFail"
        message={t('quote::Sorry, we ran into an error. Please close and try again.')}
      />
    );
  }

  const quoteMutationInput = {
    id: activeQuote.id,
    etag: activeQuote.etag,
  };
  const currentOrgId = oc(activeQuote).endCustomer.id(undefined);

  const selectedOrganizationIsSame =
    organizationInfo && currentOrgId === organizationInfo.organizationId;

  if (applyPressed && selectedOrganizationIsSame) {
    dialogContext.closeDialog();
  }

  const onApply = (selectedOrg: OrganizationIdentifierInput) => {
    const organizationWillChange = selectedOrg.organizationId !== currentOrgId;
    if (organizationWillChange) {
      selectOrg({
        variables: {
          input: {
            quote: quoteMutationInput,
            accountId: selectedOrg.accountId,
            organizationId: selectedOrg.organizationId,
          },
        },
      });
    }
    setOrganizationInfo(selectedOrg);
    setApplyPressed(true);
  };

  const getCurrentView = () => {
    if (applyPressed) {
      if (selectOrgError) {
        return (
          <Fail
            {...dialogDimensions}
            dataAutomationId="selectOrganizationFail"
            message={t('quote::Sorry, the "Select" action failed.')}
            onTryAgainClick={() => {
              organizationInfo &&
                selectOrg({
                  variables: {
                    input: {
                      quote: quoteMutationInput,
                      accountId: organizationInfo.accountId,
                      organizationId: organizationInfo.organizationId,
                    },
                  },
                });
            }}
          />
        );
      } else {
        return (
          <Processing {...dialogDimensions} message1={t('quote::Selecting the billing account')} />
        );
      }
    }
    return <FindOrganizationView onApply={onApply} />;
  };
  return getCurrentView();
};

export const openFindOrganizationDialog = (context: DialogContextType) => {
  const dialogProps: DialogProps = {
    providedDialog: <FindOrganizationDialog />,
  };
  context.openDialog(dialogProps);
};
