import { GuidanceBox } from 'components';
import { OrganizationSimple } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/react-hooks';

import { GetSuggestedOrganizations } from '../queries';
import { SelectOrganization } from './SelectOrganization';
import {
  ExistingCustomerOption,
  NewOrganizationOption,
  OptionProps,
  OptionTemplate,
} from './shared';

export interface NoOrganizationProps {
  customerName?: string;
  readOnly?: boolean;
  salesAccountName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectOrganization: (organizationId: string, accountId: string) => any;
}

interface SelectOrganizationOptionProps extends OptionProps {
  organizationSuggestions: OrganizationSimple[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectOrganization: (organizationId: string, accountId: string) => any;
}

const SelectOrganizationOption: React.FC<SelectOrganizationOptionProps> = props => {
  const { t } = useTranslation();
  const title = t('quote::Option {{number}}', { number: props.optionNumber });
  return (
    <OptionTemplate
      description={t(
        'quote::Reuse one of the billing accounts below matching the name of the sales account on the opportunity or success engagement you started with. Choose this option when the customer may have previously accepted an MCA.'
      )}
      title={title}
    >
      <SelectOrganization
        limitTotalToDisplay={5}
        organizationSuggestions={props.organizationSuggestions}
        readOnly={props.readOnly}
        onSelectOrganization={props.onSelectOrganization}
      />
    </OptionTemplate>
  );
};

export const NoOrganization: React.FC<NoOrganizationProps> = props => {
  const { salesAccountName } = props;
  const [suggestions, setSuggestions] = React.useState<OrganizationSimple[]>([]);

  const [getSuggestions] = useLazyQuery(GetSuggestedOrganizations, {
    onCompleted: data => {
      setSuggestions(data.searchOrganizations);
    },
    onError: () => {
      setSuggestions([]);
    },
  });

  React.useEffect(() => {
    if (salesAccountName) {
      getSuggestions({ variables: { query: salesAccountName } });
    }
    // disabling so this only runs first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = () => {
    if (suggestions.length) {
      return (
        <>
          <SelectOrganizationOption
            optionNumber={1}
            organizationSuggestions={suggestions}
            readOnly={props.readOnly}
            onSelectOrganization={props.onSelectOrganization}
          />
          <NewOrganizationOption optionNumber={2} readOnly={props.readOnly} />
          <ExistingCustomerOption optionNumber={3} readOnly={props.readOnly} />
        </>
      );
    } else {
      return (
        <>
          <NewOrganizationOption optionNumber={1} readOnly={props.readOnly} />
          <ExistingCustomerOption optionNumber={2} readOnly={props.readOnly} />
        </>
      );
    }
  };

  return <GuidanceBox>{options()}</GuidanceBox>;
};
