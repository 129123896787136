import { CustomerType } from 'generated/graphql';

import { QuoteData } from './quote/types';

/**
 * Removes the organization information from the quote in cache.
 *
 * @param quote current state of the quote.
 * @param customerType Required to identify where the organization is been stored in the quote.
 */
export const getRemoveOrganizationOptimisticResponse = (
  quote: QuoteData,
  customerType: CustomerType
) => {
  const quoteUpdated: QuoteData =
    customerType === CustomerType.EndCustomer
      ? { ...quote, endCustomer: null }
      : {
          ...quote,
          soldTo: { ...quote.soldTo, organization: null },
        };

  return {
    __typename: 'Mutation',
    removeOrganization: quoteUpdated,
  };
};
