import { FilteredSkus, Sku } from 'generated/graphql';
import i18next from 'i18next';

export const getLinkButtonDisplayText = (
  configuration: string | null | undefined,
  configurable: boolean,
  productTypeAzureAccessPass?: boolean,
  isExtendDiscount?: boolean
) => {
  // This is a special case for Azure Access Pass Products
  if (configuration && productTypeAzureAccessPass) {
    return { displayText: configuration, title: configuration };
  }
  // We configured it and we have a configuration we should just display it
  if (configuration && (configurable || isExtendDiscount)) {
    return { displayText: configuration, title: configuration };
  }
  // It is configurable but we haven't configured it, we should, show configure
  else if (!configuration && configurable) {
    return { displayText: i18next.t('quote::configure'), title: '' };
  }
  //It has a configuraton but it is not a configurable so we show No Options
  else {
    return { displayText: i18next.t('quote::No options'), title: '' };
  }
};

export const filteredSkuToSkus = (filteredSkus: FilteredSkus[]) => {
  let skus: Sku[] = [];
  filteredSkus.forEach(filteredSku => (skus = skus.concat(filteredSku.skus)));
  return skus;
};
