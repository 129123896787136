import { ApolloError } from 'apollo-client';
import { Message, MessageSource } from 'generated/graphql';
import React from 'react';

import {
  BillingAccountNotFoundInfoTile,
  ErrorInfoTile,
  TenantInvalidInfoTile,
  TenantNotFoundInfoTile,
} from '../InformationalTiles';
import { OrganizationSearchResultTileProps } from '../OrganizationSearchDialog';

/**
 * Provides the information tile to display based on error and result states
 *
 * @param error Error object returned by the searchPartnerOrganization query
 * @param results Organizations returned by the searchPartnerOrganization query
 */
export const getInformationalTile = (
  error: ApolloError | undefined,
  messages: Message[] | undefined,
  results: OrganizationSearchResultTileProps[] | undefined
) => {
  const message = messages && messages.find(message => !!message.messageSource);

  if (error) {
    return <ErrorInfoTile />;
  } else if (message) {
    switch (message.messageSource) {
      case MessageSource.OrganizationsearchInvalidsearchterm:
        return <TenantInvalidInfoTile scenario="partner" />;
      case MessageSource.OrganizationsearchTenantNotfound:
        return <TenantNotFoundInfoTile scenario="partner" />;
      case MessageSource.OrganizationsearchNobillingaccount:
        return <BillingAccountNotFoundInfoTile scenario="partner" />;
      default:
        return <ErrorInfoTile />;
    }
  } else if (results && !results.length) {
    return <BillingAccountNotFoundInfoTile scenario="partner" />;
  }
};
