import { ThemeProps } from 'styles';

export const searchAtomStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    iconContainer: {
      width: 32,
    },
    search: {
      '& .ms-SearchBox-iconContainer': {
        '& i': {
          color: colors.textSecondary,
          visibility: 'visible',
          opacity: 1,
        },
      },
    },
    placeholder: {
      fontStyle: 'italic',
    },
  };
};
