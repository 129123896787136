import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { TextSharedProps } from '../TextSharedProps';
import { h2AtomStyles } from './H2Atom.styles';

type Props = TextSharedProps & WithStyles<typeof h2AtomStyles>;

export const H2: React.FC<Props> = props => {
  const className = `${props.classes.headingStyles} ${props.addClass}`;
  return (
    <h2 className={className} data-automation-id={props.dataAutomationId} id={props.id}>
      {props.children}
    </h2>
  );
};

export const H2Atom = withStyles(h2AtomStyles)(H2) as React.FC<TextSharedProps>;
