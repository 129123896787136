import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { toolBarAtomStyles } from './ToolBarAtom.styles';

export interface ToolBarAtomProps {
  id?: string;
  mainClass?: string;
  rightSideCommands?: React.ReactElement | React.ReactElement[];
  grow?: boolean;
}

type Props = ToolBarAtomProps & WithStyles<typeof toolBarAtomStyles>;

const commands = (dividerClass: string, children?: React.ReactNode) => {
  return (
    children &&
    React.Children.map(children, (child, index) => {
      if (index === 0) {
        return child;
      }
      return (
        <>
          {/* TODO: This should not be an explicitly included divider - must be configurable. */}
          <div className={dividerClass} /> {child}
        </>
      );
    })
  );
};

const ToolBarAtomUnStyled: React.FC<Props> = props => {
  const { classes } = props;
  return (
    <section
      className={`${classes.toolBar} ${props.mainClass}`}
      data-automation-id={props.id}
      id={props.id}
    >
      <div className={`${classes.commandsContainer} ${props.grow ? classes.grow : ''}`}>
        {commands(props.classes.divider, props.children)}
      </div>
      {props.rightSideCommands && (
        <>
          <div>
            <div className={classes.divider} />
            <div className={classes.rightSideCommands}>{props.rightSideCommands}</div>
          </div>
        </>
      )}
    </section>
  );
};

export const ToolBarAtom = withStyles(toolBarAtomStyles)(ToolBarAtomUnStyled) as React.FC<
  ToolBarAtomProps
>;
