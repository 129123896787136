import { getCRMConfig, getFlightIsEnabled } from 'features/app/selectors';
import * as actions from 'features/customer/actions';
import {
  AssociateOrganizationToCRMIDRequest,
  SalesAccount,
  SalesAccountOrganization,
} from 'features/customer/types';
import {
  formatSalesAccountMcApiId,
  getOrganizationFromSalesAccount,
} from 'features/customer/utils';
import { getSalesAccountFromActiveQuote } from 'features/proposal/selectors';
import { call, delay, put, select, spawn } from 'redux-saga/effects';
import { addMcApiIdToSalesAccount, getSalesAccountAssociationStatus } from 'services/crm/api';
import { CRMConfig } from 'services/crm/config';
import { OrganizationState, OrganizationSummary } from 'services/customer/types';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { t } from 'services/utils';
import { RootState } from 'store/types';

import { hydrateLite } from './crm-hydrate';
import { loadOrganization } from './hydrate';

/**
 * Use to log the status of a sales account association. This can help to understand
 * frequency which the association status shows as completed, inProgress or notFound
 * after the association request.
 *
 * This does not have any impact to the UX and therefore throwing the error is not necessary.
 *
 * @param correlationId
 */
function* logSalesAccountAssociationStatus(correlationId: string) {
  try {
    const config: CRMConfig = yield select(getCRMConfig);
    const status = yield call(getSalesAccountAssociationStatus, correlationId, config);
    loggerService.log({
      name: `SalesAccountAssociation - correlationId: ${correlationId} - status: ${status}`,
    });
  } catch (err) {
    loggerService.error(err);
  }
}

export function* associateOrganizationToSalesAccount(request: AssociateOrganizationToCRMIDRequest) {
  yield put(actions.associateOrganizationToSalesAccount.request(request));

  try {
    const { accountId, organizationId } = request;
    const config: CRMConfig = yield select(getCRMConfig);
    const mcapiId = formatSalesAccountMcApiId(accountId, organizationId);
    const salesAccount = yield select(getSalesAccountFromActiveQuote);
    const customerSalesAccountNumber = salesAccount && salesAccount.GlobalCrmId;
    const previouslyAssociated = !!(salesAccount && getOrganizationFromSalesAccount(salesAccount));

    if (customerSalesAccountNumber) {
      const correlationId = yield call(
        addMcApiIdToSalesAccount,
        { customerSalesAccountNumber, mcapiId },
        config
      );

      if (correlationId) {
        yield delay(10000); // Required since doing a GET right after PATCH may return an inaccurate response (mostly NotFound)
        yield spawn(logSalesAccountAssociationStatus, correlationId);
      }
    }

    yield put(actions.associateOrganizationToSalesAccount.success());

    // Feature telemetry
    if (previouslyAssociated) {
      loggerService.log({ name: 'CRMToOrgAssociation: Updated' });
    } else {
      loggerService.log({ name: 'CRMToOrgAssociation: New' });
    }
  } catch (err) {
    yield put(
      actions.associateOrganizationToSalesAccount.failure({
        message: t('error::Error associating billing account to sales account'),
        exception: err,
      })
    );
  }
}

export function* getOrganizationFromCRMID(crmId: string) {
  yield put(actions.getOrganizationFromCRMID.request(crmId));
  let salesAccountOrganization: SalesAccountOrganization | undefined;

  try {
    const disableBillingAccountAssociation = yield select((state: RootState) =>
      getFlightIsEnabled(state, Flight.disableBillingAccountAssociation)
    );

    if (!disableBillingAccountAssociation) {
      const salesAccount: SalesAccount = yield call(hydrateLite, crmId);
      const organizationFromSalesAccount = getOrganizationFromSalesAccount(salesAccount);

      if (!organizationFromSalesAccount) {
        loggerService.log({ name: 'CRMToOrgAssociation: NotFound' });
      } else {
        loggerService.log({ name: 'CRMToOrgAssociation: Found' });
        const { accountId, organizationId } = organizationFromSalesAccount;
        const organization: OrganizationSummary = yield call(
          loadOrganization,
          { accountId, id: organizationId },
          false
        );

        if (organization) {
          loggerService.log({ name: `CRMToOrgAssociation: ${organization.state}` });
          if (organization.state.toLowerCase() === OrganizationState.active) {
            salesAccountOrganization = organizationFromSalesAccount;
          }
        }
      }
    }

    yield put(actions.getOrganizationFromCRMID.success(salesAccountOrganization));
    return salesAccountOrganization;
  } catch (err) {
    yield put(
      actions.getOrganizationFromCRMID.failure({
        message: t('error::Error fetching billing account from CRM ID'),
        exception: err,
      })
    );
    return;
  }
}
