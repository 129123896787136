import { ThemeProps } from 'styles';

import { buttonSharedStyles } from '../shared.styles';

export const iconBorderButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    ...buttonSharedStyles(theme),
    command: {
      ...buttonSharedStyles(theme).command,
      borderRadius: 2,
    },
    icon: {
      minWidth: 32,
      width: 32,
      height: 32,
      backgroundColor: colors.backgroundStandout,
      border: `1px solid ${colors.border}`,
      padding: '7px 8px',
      '& i': {
        color: colors.text,
        padding: 0,
        margin: 0,
      },
      '&:hover': {
        backgroundColor: colors.backgroundStandout,
        border: `1px solid ${colors.text}`,
        '& i': {
          color: colors.text,
        },
      },
      '&:focus': {
        '& i': {
          color: colors.text,
        },
      },
      '&:active': {
        backgroundColor: colors.backgroundMain,
        '& i': {
          color: colors.text,
        },
      },
      '&:active:hover': {
        backgroundColor: colors.backgroundMain,
        border: `1px solid ${colors.text}`,
        '& i': {
          color: colors.text,
        },
      },
      '& i:disabled': {
        color: colors.textDisabled,
      },
    },
  };
};
