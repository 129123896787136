import { sharedStyles, ThemeProps } from 'styles';

export const DetailsPaneStyles = (theme: ThemeProps) => ({
  address: { marginTop: 5 },
  description: {
    paddingTop: 8,
  },
  detailsContainer: {
    margin: '32px 24px',
  },
  semiBoldText: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  partnerContainer: { marginBottom: 25 },
  ecifLabels: { color: theme.palette.textTertiary },
  heading: { display: 'flex', flexDirection: 'column', width: '100%' },
  tradeName: {
    ...sharedStyles(theme).textOverflow,
    display: 'block',
    width: '100%',
    color: theme.palette.textTertiary,
  },
  sectionRow: { display: 'flex', flexDirection: 'column', width: '100%', marginTop: 25 },
  detailsPaneSection: { display: 'flex', flexDirection: 'column', width: '100%', marginTop: 16 },
  innerRow: { display: 'flex', flexDirection: 'column', marginTop: 4 },
  discountRow: { display: 'flex', width: '100%', marginTop: 12 },
  flexCol: { flexDirection: 'column' },
  label: { minWidth: 50, marginRight: 20, lineHeight: 1.1 },
  listRow: { display: 'flex', flexDirection: 'column', lineHeight: 1.6 },
  shortText: { flex: 1 },
  link: { '& *': { fontSize: theme.fonts.fontSizes.small } },
  table: {
    borderSpacing: 0,
    '& tr > td:first-of-type': {
      paddingRight: 12,
    },
    '& tr > td:last-of-type': {
      paddingRight: 0,
    },
    '& td': {
      paddingTop: 0,
      paddingBottom: 4,
      paddingLeft: 0,
      verticalAlign: 'text-top',
    },
  },
  companyName: sharedStyles(theme).textOverflow,
  servicesNone: { fontStyle: 'italic' },
  sectionDetails: { paddingTop: 16 },
  sections: { paddingTop: 12 },
  servicesListTable: {
    width: 248,
    borderSpacing: 0,
    '& *': {
      borderSpacing: 0,
    },
    // styling table rows and cells
    '& tr:not(:last-child) td:last-child': { textAlign: 'right', width: 64 },
    '& tr:not(:nth-last-child(2)):not(:last-child)': {
      '& td:first-child': { padding: 0 },
      '& td:last-child': { padding: '0 22px 0 0' },
    },
    '& tr:nth-last-child(2)': {
      '& td:first-child': { padding: '0 0 12px 0' },
      '& td:last-child': { padding: '0 22px 12px 0' },
    },
    '& tr:last-child td:last-child': {
      borderTop: `1px solid ${theme.palette.border}`,
      padding: '2px 0 0 0',
      textAlign: 'right',
    },
  },
});
