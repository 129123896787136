import * as React from 'react';
import { ThemeProps } from 'styles';
import withStyles, { WithStyles } from 'react-jss';
import { LinkButton, LinkExternal } from 'components/ions';

export interface HelpSectionLink {
  href?: string;
  key: string;
  onClick?: () => void;
  text: string;
}

const sectionStyles = (theme: ThemeProps) => ({
  h3: {
    color: theme.palette.text,
    fontFamily: theme.fonts.fontFamily.fabricDefault,
    fontSize: theme.fonts.fontSizes.medium,
    fontWeight: theme.fonts.fontWeights.semiBold,
    margin: 0,
    paddingBottom: 6,
  },
  link: {
    display: 'block',
    fontSize: theme.fonts.fontSizes.medium,
  },
  content: {
    '& > *:not(:last-child)': {
      paddingBottom: 8,
    },
  },
  helpSection: {
    marginBottom: 20,
  },
});

type Props = {
  links?: HelpSectionLink[];
  title: string;
} & WithStyles<typeof sectionStyles>;

const HelpSectionUnstyled: React.FC<Props> = props => {
  const { classes, title, links, children } = props;

  const getLinks = (sectionLinks: HelpSectionLink[] | undefined) => {
    if (!sectionLinks || !sectionLinks.length) {
      return null;
    }

    const links = sectionLinks.map(link => {
      // Trim required since strings with just whitespace evaluates to true
      if (link.href && link.href.trim()) {
        return (
          <LinkExternal
            addClass={classes.link}
            displayText={link.text}
            href={link.href}
            key={link.key}
          />
        );
      } else {
        return (
          <LinkButton
            addClass={classes.link}
            displayText={link.text}
            key={link.key}
            onClick={link.onClick}
          />
        );
      }
    });

    return links;
  };

  const linkContent = getLinks(links);

  return (
    <div className={classes.helpSection}>
      <h3 className={classes.h3}>{title}</h3>
      {linkContent && <div className={classes.content}>{linkContent}</div>}
      {children && <div className={classes.content}>{children}</div>}
    </div>
  );
};

export const HelpSection = withStyles(sectionStyles)(HelpSectionUnstyled);
