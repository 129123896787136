import { Dialog, LinkButton, PrimaryButton } from 'components';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { VatId } from 'features-apollo/quote/components/Dialogs/VatId/VatId';
import { MutationUpdateVatIdArgs, OrganizationIdentifierInput } from 'generated/graphql';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps as DialogStyleProps, FontSizes } from 'styles';

import { useMutation } from '@apollo/react-hooks';

import { UpdateVatId } from './Queries';

export const VatIdHeaderString = () => i18next.t('quote::Value added tax identification number');

export interface VatIdDialogProps {
  organizationIdentifier: OrganizationIdentifierInput;
  initialVatId?: string;
  quoteId?: string;
}

export interface VatIdBodyProps extends VatIdDialogProps {
  isSaveVatIdEnabled: boolean;
  enableSaveVatId: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VatIdBody: React.FC<VatIdBodyProps> = props => {
  const {
    initialVatId,
    isSaveVatIdEnabled,
    enableSaveVatId,
    organizationIdentifier,
    quoteId,
  } = props;

  const [lastVerified, setLastVerified] = React.useState<string | undefined>();
  const [isClear, setIsClear] = React.useState<boolean>(!!(isSaveVatIdEnabled && lastVerified));
  const [isAddingVatIdLoading, setAddingVatIdLoading] = React.useState<boolean>(false);
  const [isAddingVatIdError, setAddingVatIdError] = React.useState<boolean>(false);

  const [removeVatId] = useMutation<any, MutationUpdateVatIdArgs>(UpdateVatId, {
    refetchQueries: () => [{ query: GET_QUOTE, variables: { id: quoteId } }],
  });

  const [addVatId, { loading: loadingAddingVatId, error: errorAddingVatId }] = useMutation<
    any,
    MutationUpdateVatIdArgs
  >(UpdateVatId, {
    refetchQueries: () => [{ query: GET_QUOTE, variables: { id: quoteId } }],
  });

  React.useEffect(() => {
    if (errorAddingVatId) {
      enableSaveVatId(false);
      setAddingVatIdError(true);
    } else {
      enableSaveVatId(true);
      setAddingVatIdError(false);
    }
  }, [errorAddingVatId, enableSaveVatId]);

  React.useEffect(() => {
    setAddingVatIdLoading(loadingAddingVatId);
  }, [loadingAddingVatId]);

  const onVerify = (value: string) => {
    addVatId({
      variables: {
        input: {
          vatId: value,
          organizationIdentifier,
        },
      },
    });
    setLastVerified(value);
  };

  const onChange = (newValue?: string) => {
    if (!newValue) {
      enableSaveVatId(true);
      setIsClear(true);
      removeVatId({
        variables: {
          input: {
            organizationIdentifier,
          },
        },
      });
    }
    setIsClear(false);
  };

  return (
    <VatId
      isClear={isClear}
      isError={isAddingVatIdError}
      isLoading={isAddingVatIdLoading}
      lastVerified={lastVerified}
      vatNumber={initialVatId}
      onChange={onChange}
      onVerify={onVerify}
    />
  );
};

const VatIdDialog: React.FC<VatIdDialogProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const [isSaveVatIdEnabled, setIsSaveVatIdEnabled] = React.useState<boolean>(false);

  const closeDialog = () => {
    context.closeDialog();
  };

  const saveButton = [
    <PrimaryButton
      dataAutomationId="vatIdSaveButton"
      disabled={!isSaveVatIdEnabled}
      key="vatIdSaveButton"
      text={t('quote::Save')}
      onClick={closeDialog}
    />,
  ];

  return (
    <Dialog
      dataAutomationId="vatIdDialog"
      footerButtons={saveButton}
      height={isSaveVatIdEnabled ? 363 : 403}
      title={t('quote::Value added tax identification number')}
      width={600}
    >
      <VatIdBody {...props} enableSaveVatId={setIsSaveVatIdEnabled} isSaveVatIdEnabled />
    </Dialog>
  );
};

export const openVatIdDialog = (
  context: {
    openDialog: (dialogProps: DialogStyleProps) => void;
    closeDialog: () => void;
  },
  props: VatIdDialogProps
) => {
  const dialogProps: DialogStyleProps = {
    providedDialog: <VatIdDialog {...props} />,
  };
  context.openDialog(dialogProps);
};

export interface VatIdLinkButtonProps extends VatIdDialogProps {
  size: keyof FontSizes;
}

export const VatIdLinkButton: React.FC<VatIdLinkButtonProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const { size, ...dialogProps } = props;
  return (
    <LinkButton
      displayText={t('quote::add VAT number')}
      size={size}
      onClick={() => openVatIdDialog(context, dialogProps)}
    />
  );
};
