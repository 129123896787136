export interface CreditInfo {
  organizationId: string;
  created_date?: string;
  decision: string;
  event_type: string;
  last_modified_date?: string;
  reasons: CreditInfoReasons;
}

export interface CreditLookupRequest {
  legalEntity: {
    accountId: string;
    organizationId: string;
  };
  currency: string;
  event_type: CreditCheckEventType;
}

export interface CreditRequest extends CreditLookupRequest {
  quote_id: string;
  quote_detail: {
    product_id?: number;
    purchase_value: number;
    product_type?: string;
  };
}

export enum CreditCheckEventType {
  PurchaseCheckNoMr = 'purchase_check_no_mr',
  PurchaseCheck = 'purchase_check',
  CreditLookUp = 'credit_look_up',
  CreditIncrease = 'credit_increase',
}

interface CreditInfoReasons {
  reason?: string;
  currency?: string;
  credit_line?: string;
  remaining_balance?: string;
  credit_risk_score?: string;
}

export enum CreditEndpoints {
  CreditLookup = 'creditlookup',
  MSProductService = 'mcproductservice',
}
