import { ConfigCardButton } from 'features-apollo/quote/components/ConfigCards';
import { DiscountCardLink } from 'features-apollo/quote/components/DiscountCard';
import { QuoteListColumn } from 'features-apollo/quote/components/Lists/types';
import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import { DiscountLineItem } from 'generated/graphql';
import React from 'react';
import { oc } from 'ts-optchain';

import { QuoteListRow, SelectionProps } from '../QuoteListRow';
import { LineItemRowProps, ProductLineItemContentAlignment } from '../types';
import { percentageFormatter, textFormatter, titleFormatter } from '../utils';

export interface DiscountRowProps extends LineItemRowProps, SelectionProps {
  /*
   * the line item on the quote line items list.
   */
  lineItem: DiscountLineItem;
  /**
   * Defines the current discount found in the line item
   */
  discount?: number | null;
  /**
   * Defines the current discount configuration
   */
  discountConfiguration?: string | null;
  /**
   * Defines a custom flex CSS rule per content.
   */
  contentAlignment?: ProductLineItemContentAlignment;
}

export const DiscountRow: React.FC<DiscountRowProps> = props => {
  const topColumns: QuoteListColumn[] = [
    {
      id: 'listrow-name',
      content: titleFormatter(props.productTitle),
      ...oc(props).contentAlignment.name({}),
    },
    {
      id: 'listrow-quantity',
      content: textFormatter('-'),
      ...oc(props).contentAlignment.quantity({}),
    },
    {
      id: 'listrow-list-price',
      content: textFormatter('-'),
      ...oc(props).contentAlignment.listPrice({}),
    },
    {
      id: 'listrow-customer-price',
      content: textFormatter('-'),
      ...oc(props).contentAlignment.customerPrice({}),
    },
    {
      id: 'listrow-discount',
      content: percentageFormatter(props.discount),
      ...oc(props).contentAlignment.discount({}),
    },
    {
      id: 'listrow-amount',
      content: textFormatter('-'),
      ...oc(props).contentAlignment.amount({}),
    },
  ];

  const bottomColumns: QuoteListColumn[] = props.productUnavailable
    ? []
    : [
        {
          id: 'discount-config-card-button',
          content: (
            <ConfigCardButton
              alreadyHasDiscount={props.lineItem.isReadyForPricing}
              configuration={props.configuration}
              disabled={props.readOnly}
              isConfigurable={props.configurable}
              lineItem={props.lineItem}
              quoteId={props.quoteId}
            />
          ),
          ...oc(props).contentAlignment.configCardButton({}),
        },
        {
          id: 'discount-discount-config-card-button',
          content: (
            <DiscountCardLink
              dataAutomationId="discount-configcard-link"
              disabled={props.readOnly || (props.configurable && !props.configuration)}
              linkText={getValueOrUndefined(props.discountConfiguration)}
              selectedIds={[props.lineItem.id]}
            />
          ),
          ...oc(props).contentAlignment.discountConfigCardButton({}),
        },
      ];

  return (
    <QuoteListRow
      {...props}
      bottomColumns={bottomColumns}
      isInErrorState={props.errored}
      isInWarnState={props.isInWarnState}
      isSelected={props.selectedIds.includes(props.lineItem.id)}
      lineItem={props.lineItem}
      topColumns={topColumns}
    />
  );
};
