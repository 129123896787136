import { OrgWizardBillingContact } from 'features-apollo/quote/components/Wizards/OrganizationWizard';
import { ErrorType } from 'features-apollo/quote/components/Wizards/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';

import { BillingContactBody } from '../../BillingContact';
import { OrgWizardSharedProps, WizardDialog } from '../Shared';

export const BillingContactWizardDialog: React.FC<OrgWizardSharedProps<
  OrgWizardBillingContact
>> = props => {
  const { initialData } = props;
  const { t } = useTranslation();

  const [email, setEmail] = React.useState<string | undefined>(oc(initialData).email(undefined));
  const [isEmailValid, setIsEmailValid] = React.useState<boolean | undefined>(
    !!oc(initialData).email(undefined)
  );
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(
    oc(initialData).phoneNumber(undefined)
  );
  const [errorType, setErrorType] = React.useState<ErrorType | undefined>(undefined);

  React.useEffect(() => {
    if (!isEmailValid) {
      setErrorType(ErrorType.invalidField);
    } else {
      setErrorType(undefined);
    }
  }, [email, phoneNumber, isEmailValid]);

  const onEmailUpdate = (email?: string, isValid?: boolean) => {
    setEmail(email);
    setIsEmailValid(isValid);
  };

  const onClickOverview = () => {
    !errorType && isEmailValid
      ? props.onOverviewClick({ email, phoneNumber })
      : props.onOverviewClick();
  };

  const body = (
    <BillingContactBody
      email={email}
      phoneNumber={phoneNumber}
      setEmail={onEmailUpdate}
      setPhoneNumber={setPhoneNumber}
    />
  );

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      errorType={errorType}
      headline={props.headline}
      isOverviewAvailable={props.isOverviewAvailable}
      overviewButtonOnly={true}
      title={t('quote::Contact Information')}
      onOverviewClick={onClickOverview}
    />
  );
};
