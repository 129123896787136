import { BarButton } from 'components';
import { RefetchReferrals } from 'features/components/dialogs/Referral';
import { Referral } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import { CloseReferralDialog } from './CloseReferralDialog';

export interface CloseReferralButtonProps {
  referral?: Referral;
  disabled?: boolean;
  dataAutomationId?: string;
  refetchReferrals?: RefetchReferrals;
}

export const CloseReferralBarButton: React.FC<CloseReferralButtonProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  const dialogProps: DialogProps = {
    providedDialog: (
      <CloseReferralDialog referral={props.referral} refetchReferrals={props.refetchReferrals} />
    ),
  };

  const handleOnClick = () => {
    if (!props.disabled) {
      context.openDialog(dialogProps);
    }
  };

  return (
    <BarButton
      ariaLabel={t('quote::Close referral')}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconName="BarChartVertical"
      id="closeReferralButton"
      text={t('quote::Close')}
      onClick={handleOnClick}
    />
  );
};
