import { ThemeProps } from 'styles';

export const detailsNotificationListStyles = (theme: ThemeProps) => {
  const { warningText, dangerText, secondary, dangerActive } = theme.palette;
  const { semiBold, bold } = theme.fonts.fontWeights;
  return {
    iconColumn: {
      width: '12px',
      paddingTop: '2px',
      '& >i': {
        fontWeight: bold,
      },
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    column: {
      flex: 1,
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      '& >span': {
        display: 'flex',
      },
    },
    warning: { color: warningText },
    standard: { color: dangerText },
    semiBold: { fontWeight: semiBold },
    info: { color: secondary },
    error: { color: dangerActive },
    marginLeft: { marginLeft: '10px' },
    marginBottom: { marginBottom: '20px' },
  };
};
