import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => {
  const { fonts, palette } = theme;
  return {
    list: {
      padding: 'unset',
      marginTop: 0,
      marginBottom: 0,
    },
    sectionHeader: {
      color: palette.text,
      fontWeight: fonts.fontWeights.regular,
      fontSize: fonts.fontSizes.xLarge,
      marginTop: 0,
      marginBottom: 20,
    },
    sectionRowQuestion: {
      maxWidth: 700,
      color: palette.textLink,
    },
  };
};
