import { ThemeProps } from 'styles';

export const peoplePickerStyles = (theme: ThemeProps) => {
  return {
    border: {
      borderColor: theme.palette.border,
      '&:hover': {
        borderColor: theme.palette.border,
      },
      '&:focus': {
        outline: 'none',
      },
    },
  };
};
