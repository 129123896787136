import { Dropdown, LinkButton, SectionSeparator } from 'components/ions';
import { ConfigCardDropdownOptions } from 'features/proposal/components/ConfigCard';
import { DropdownMenuItemType, IDropdownOption, IRenderFunction } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';

import { configCardHeaderStyles } from './ConfigCardHeader.styles';

export interface ConfigCardHeaderProps {
  selectMultipleLocations: (selectedLocationOptions: IDropdownOption[]) => void;
  onLocationSelection: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => void;
  onDurationSelection: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => void;
  showLocation?: boolean;
  locationDropdownOptions: ConfigCardDropdownOptions;
  showDuration?: boolean;
  durationDropdownOptions: ConfigCardDropdownOptions;
  allowUnspecifiedOptionInLocationFilters?: boolean;
}

type Props = ConfigCardHeaderProps & WithStyles<typeof configCardHeaderStyles>;

export const ConfigCardHeaderUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const {
    showLocation,
    locationDropdownOptions,
    showDuration,
    durationDropdownOptions,
    selectMultipleLocations,
    onDurationSelection,
    allowUnspecifiedOptionInLocationFilters,
  } = props;

  const defaultLocationKey = 'All Locations';
  const locationOptions = allowUnspecifiedOptionInLocationFilters
    ? locationDropdownOptions.options.map(option => {
        if (option.key === '') {
          return { key: option.key, text: t('quote::Unspecified') };
        }
        return option;
      })
    : locationDropdownOptions.options.filter(option => option.key !== '');

  const initialSelectedLocationKeys =
    // The unspecified option has the key '', yes literally blank, so in that case we do actually want to put it as selection
    locationDropdownOptions.initialSelectedKey !== undefined
      ? [locationDropdownOptions.initialSelectedKey]
      : [defaultLocationKey];

  const initialSelectedLocations: IDropdownOption[] = initialSelectedLocationKeys.map(key => {
    return {
      key,
      text: key,
      selected: true,
    };
  });

  const [selectedLocationOptions, setSelectedLocationOptions] = React.useState(
    initialSelectedLocations
  );
  const [selectedLocations, setSelectedLocations] = React.useState(initialSelectedLocationKeys);

  const updateLocationOptions = (selectedLocations: string[]) => {
    const newSelectedOptions = selectedLocations.map((location: string) => {
      return {
        key: location,
        text: location,
        selected: true,
      };
    });

    setSelectedLocationOptions(newSelectedOptions);
  };

  // sort locations alphabetically while maintaining the original selected key as first in the list if it exists
  const reorderLocations = (selectedLocations: string[]) => {
    if (locationDropdownOptions.initialSelectedKey && selectedLocations.length > 1) {
      const originalSelectedKeyIndex = selectedLocations.indexOf(
        locationDropdownOptions.initialSelectedKey
      );
      let originalSelectedKey;
      if (originalSelectedKeyIndex > -1) {
        originalSelectedKey = locationDropdownOptions.initialSelectedKey;
        selectedLocations.splice(originalSelectedKeyIndex, 1);
      }
      const sortedRemainingList = selectedLocations.sort();
      return originalSelectedKey
        ? [originalSelectedKey, ...sortedRemainingList]
        : sortedRemainingList;
    } else {
      return selectedLocations;
    }
  };

  const setDefaultLocation = () => {
    setSelectedLocations([defaultLocationKey]);
    return [defaultLocationKey];
  };

  const updateLocations = (option: IDropdownOption) => {
    // reset location list if 'All Locations' option is selected
    if (option.key === defaultLocationKey) {
      return setDefaultLocation();
    }

    let updatedLocations: string[] = [...selectedLocations];

    // deselect 'All Locations' if any other option is selected
    updatedLocations.includes(defaultLocationKey) && updatedLocations.splice(0, 1);

    const optionKey = option.key.toString();
    if (option.selected) {
      updatedLocations.push(optionKey);
    } else {
      const currIndex = updatedLocations.indexOf(optionKey);
      if (currIndex > -1) {
        updatedLocations.splice(currIndex, 1);
        if (!updatedLocations.length) {
          return setDefaultLocation();
        }
      }
    }
    const sortedLocations = reorderLocations(updatedLocations);
    setSelectedLocations(sortedLocations);
    return sortedLocations;
  };

  const onChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      const updatedLocations: string[] = updateLocations(option);
      updateLocationOptions(updatedLocations);
    }
  };

  const buildLocationsPreview = (locations: string[]) => {
    const maxLocationsShown = 2;
    const maxTitleLength = 20;
    const locationsDisplayed = locations && locations.slice(0, maxLocationsShown).join(', ');
    let locationsPreview = '';
    if (locationsDisplayed) {
      // if a space exists at the end of the substring, we further shorten the substring
      // for example, if the substring would have been 'Location A, Location B ', we
      // shorten this to 'Location A, Location B' to avoid the buildLocationSummary
      // being 'Location A, Location B ...'
      const isSpaceAtIndex = locationsDisplayed[maxTitleLength - 1] === ' ';
      const endIndex = isSpaceAtIndex ? maxTitleLength - 1 : maxTitleLength;
      locationsPreview = locationsDisplayed.substring(0, endIndex);
    }
    return locationsPreview;
  };

  const buildCustomizedDisplayOption = (locations: string[]) => {
    const locationsPreview = buildLocationsPreview(locations);
    const buildLocationSummary = locationsPreview.concat(`... (${selectedLocationOptions.length})`);
    const customOption = {
      key: buildLocationSummary,
      text: buildLocationSummary,
    };
    return customOption;
  };

  const onRenderTitle = (
    options?: IDropdownOption[],
    defaultRender?: IRenderFunction<IDropdownOption[]>
  ): JSX.Element | null => {
    if (!defaultRender || !options) {
      loggerService.error({
        error: new Error('OnRenderTitle defaultRender or options is undefined'),
      });
    }
    const maxLocationsShown = 2;
    const locations = options && options.map(option => option.key.toString());
    if (locations && selectedLocationOptions.length > maxLocationsShown) {
      const customOption = buildCustomizedDisplayOption(locations);
      return defaultRender ? defaultRender([customOption]) : null;
    }
    return defaultRender ? defaultRender(options) : null;
  };

  const customizedOptions =
    locationOptions.length > 1
      ? [
          locationOptions[0],
          { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider },
          { key: 'header', text: 'select all', itemType: DropdownMenuItemType.Header },
          ...locationOptions.slice(1, locationOptions.length),
        ]
      : locationOptions;

  const onSelectAll = () => {
    const selectedLocationOptions = locationOptions.slice(1, locationOptions.length);
    const selectedLocations = selectedLocationOptions.map(option => option.key.toString());
    setSelectedLocations(selectedLocations);
    setSelectedLocationOptions(selectedLocationOptions);
  };

  const onRenderOption = (
    option?: IDropdownOption,
    defaultRender?: IRenderFunction<IDropdownOption>
  ): JSX.Element | null => {
    if (!defaultRender || !option) {
      loggerService.error({
        error: new Error('onRenderOption defaultRender or option is undefined'),
      });
    }
    if (option && option.key === 'header') {
      return <LinkButton displayText={option.text} onClick={onSelectAll} />;
    }
    return defaultRender ? defaultRender(option) : null;
  };

  const hrefTitle = selectedLocations.join(', ');

  const defaultLocationDropdownProps = {
    disabled: locationDropdownOptions.disabled || locationDropdownOptions.readOnly,
    id: 'location',
    label: t('quote::Locations'),
    maxHeight: 258,
  };

  const locationDropdownProps = {
    ...defaultLocationDropdownProps,
    multiSelect: true,
    options: customizedOptions,
    selectedKeys: selectedLocations,
    title: hrefTitle,
    onChange,
    onDismiss: () => {
      // this is a hack to make it feel like the UI does not hang,  without this the dropdown hangs for a second or two while loading the sku's.
      // this makes it a tad better by closing the dropdown immediately
      setTimeout(() => {
        selectMultipleLocations(selectedLocationOptions);
      }, 0);
    },
    onRenderOption,
    onRenderTitle,
  };

  const locationDropdown = <Dropdown {...locationDropdownProps} />;

  const locationSelection = (
    <div className={props.classes.locationDropdown}>{locationDropdown}</div>
  );

  const durationSelectionProps = {
    disabled: durationDropdownOptions.disabled,
    id: 'commitment',
    label: t('quote::Commitment'),
    maxHeight: 258,
    options: durationDropdownOptions.options,
    selectedKey: durationDropdownOptions.initialSelectedKey,
    onChange: onDurationSelection,
  };

  const durationDropdown = <Dropdown {...durationSelectionProps} />;

  const durationSelection = (
    <div className={props.classes.durationDropdown}>{durationDropdown}</div>
  );
  const dropdowns = (
    <div>
      <div className={props.classes.dropdownsContainer}>
        {showLocation && locationSelection}
        {showDuration && durationSelection}
      </div>
      <SectionSeparator />
    </div>
  );
  const showContainer = showLocation || showDuration;
  return showContainer ? dropdowns : null;
};

export const ConfigCardHeader = withStyles(configCardHeaderStyles)(ConfigCardHeaderUnstyled);
