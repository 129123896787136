import { ThemeProps, sharedStyles } from 'styles';

export const copyInputButtonStyles = (theme: ThemeProps) => ({
  container: { display: 'flex', flexDirection: 'column' },
  spinner: { alignItems: 'center' },
  innerRow: {
    display: 'flex',
    flexDirection: 'row',
    '& .ms-TextField': { width: '77%' },
    '& .is-active': {
      '& div::after': {
        borderColor: theme.palette.backgroundDividerFocus,
        border: 1,
      },
    },
  },
  marginTop10: { marginTop: 10 },
  marginTop5: { marginTop: 5 },
  spacing: { marginTop: 30, width: '100%' },
  input: {
    '& .ms-TextField-fieldGroup': {
      ...sharedStyles(theme).roundedCorners,
      backgroundColor: theme.palette.backgroundStandout,
      color: theme.palette.text,
      border: `1px ${theme.palette.backgroundDivider} solid`,
      flexGrow: 1,
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      fontSize: theme.fonts.fontSizes.medium,
      marginRight: 8,
      minWidth: 180,
      height: 30,
      padding: '5.8px 4px',
      '&:focus:hover:active::after': {
        borderColor: theme.palette.backgroundDividerFocus,
        border: 1,
      },

      '& .ms-TextField-field': { color: theme.palette.text },
    },
  },
  errorInput: {
    '& .ms-TextField-fieldGroup': {
      ...sharedStyles(theme).roundedCorners,
      backgroundColor: theme.palette.backgroundStandout,
      color: theme.palette.textDisabled,
      border: `1px ${theme.palette.dangerActive} solid`,
      flexGrow: 1,
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      fontSize: theme.fonts.fontSizes.medium,
      marginRight: 8,
      minWidth: 180,
      padding: '5.8px 4px',
      height: 30,
      '&:focus:hover:active::after': {
        borderColor: theme.palette.dangerActive,
        border: 1,
      },
      '& .ms-TextField-field': { color: theme.palette.textDisabled },
    },
  },
  lightText: { color: theme.palette.textTertiary, marginBottom: 4 },
});
