import { GuidanceBox, TextBody } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import {
  PartnerSearchDialogForJioCsp,
  PartnerSearchDialogForJioCspProps,
} from '../PartnerSearchDialogForJioCsp';

export interface SelectPartnerForJioCspProps extends PartnerSearchDialogForJioCspProps {
  disabled?: boolean;
}

export const SelectPartnerForJioCsp: React.FC<SelectPartnerForJioCspProps> = props => {
  const { t } = useTranslation();

  // Dialog props
  const context = React.useContext(DialogContext);
  const partnerSearchProps = props as PartnerSearchDialogForJioCspProps;

  const dialogProps: DialogProps = {
    providedDialog: <PartnerSearchDialogForJioCsp {...partnerSearchProps} />,
  };

  // Button props
  const buttonProps = {
    dataAutomationId: 'add-partner',
    disabled: props.disabled,
    text: t('quote::Add a partner'),
    onClick: () => context.openDialog(dialogProps),
  };

  return (
    <GuidanceBox buttonProps={buttonProps} title={t('quote::Add a partner...')}>
      <p style={{ margin: '8px 0 0' }}>
        <TextBody>
          {t(
            'quote::A partner can be added as a party helping to facilitate, provide service to, or as the primary seller of this quote. Add a partner using their tenant ID or domain name.'
          )}
        </TextBody>
      </p>
    </GuidanceBox>
  );
};
