import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/proposal/actions';
import { WithdrawQuoteMutation } from 'features/proposal/components';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { approvalNotWithdrawable, eTagMissmatchCode } from 'services/approval/utils';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { RefreshDialog } from '../RefreshDialog';
import { Success } from '../Shared';
import { WarningView } from './WarningView';

const dialogDimensions = {
  height: 264,
  width: 448,
};

const styles = () => {
  return {
    processing: {
      height: 'auto',
      marginTop: 70,
    },
  };
};

// Connected props
const mapStateToProps = (state: RootState) => ({
  canWithdrawProposal: selectors.canWithdrawProposal(state),
  proposal: selectors.getActiveProposal(state),
  approval: selectors.getActiveApproval(state),
});

const dispatchProps = {
  withdrawQuoteSuccess: actions.withdrawQuoteGQLSuccess,
};

export type WithdrawDialogProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
type Props = WithdrawDialogProps & WithStyles<typeof styles>;

const WithdrawDialogUnstyled: React.FC<Props> = props => {
  const { proposal, approval } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const [withdrawQuoteGQL, { loading, error, data }] = useMutation(WithdrawQuoteMutation);
  const closeDialog = () => context.closeDialog();

  const dispatchWithdrawAction = () => {
    if (!props.canWithdrawProposal) {
      return;
    }
    withdrawQuoteGQL({
      variables: {
        input: {
          id: proposal.id,
          etag: proposal.etag,
        },
      },
    });
  };

  if (loading) {
    return (
      <Processing {...dialogDimensions} message1={t('quote::The quote is being withdrawn.')} />
    );
  } else if (error) {
    const errorCode = oc(error).graphQLErrors[0].extensions.code();
    const errorStatus412 =
      oc(error).graphQLErrors[0].extensions.errorData.code() === eTagMissmatchCode;
    const isSequentialMultipleApprover =
      approval &&
      approval.workFlow &&
      approval.workFlow.toLowerCase() === 'sequentialmultipleapproval';
    if (errorCode === approvalNotWithdrawable) {
      const actionText = t('quote::Withdraw');
      return (
        <RefreshDialog
          {...dialogDimensions}
          action={actionText}
          isSequentialMultipleApprover={!!isSequentialMultipleApprover}
        />
      );
    }
    if (errorStatus412) {
      const actionText = t('quote::Withdraw');
      return <RefreshDialog action={actionText} {...dialogDimensions} is412={true} />;
    }
    return (
      <Fail
        {...dialogDimensions}
        closeDialog={closeDialog}
        dataAutomationId="withdrawQuoteFail"
        message={t('quote::Sorry, the "Withdraw" action failed.')}
        onTryAgainClick={dispatchWithdrawAction}
      />
    );
  } else if (data) {
    const result = oc(data).oldWithdrawQuote();

    if (proposal.etag !== result.etag) {
      props.withdrawQuoteSuccess(proposal.id);
    }

    return (
      <Success
        {...dialogDimensions}
        closeDialog={closeDialog}
        dataAutomationId="withdrawQuoteSuccess"
        message={t('quote::The quote has successfully been withdrawn.')}
      />
    );
  } else {
    return (
      <WarningView
        {...dialogDimensions}
        closeDialog={closeDialog}
        dispatchWithdrawAction={dispatchWithdrawAction}
      />
    );
  }
};

export const WithdrawDialogStyled = withStyles(styles)(WithdrawDialogUnstyled) as React.FC<
  WithdrawDialogProps
>;

export const WithdrawDialog = connect(mapStateToProps, dispatchProps)(WithdrawDialogStyled);
