import { ThemeProps } from 'styles';

export const barSearchStyles = (theme: ThemeProps) => {
  const colors = theme.palette;

  const bar = {
    color: colors.textSecondary,
    backgroundColor: 'transparent',
    height: 30,

    '&, &:before, &:after': {
      borderStyle: 'none',
    },
    '& .ms-SearchBox-field::placeholder': {
      color: colors.text,
    },

    // Edge fix: the separation of this selector is intentional; declaring inline (i.e., in a commma-separated list of selectors in the rule above) results in Edge not applying the rule
    '& .ms-SearchBox-field::-webkit-input-placeholder': {
      color: colors.text,
    },

    '& .ms-SearchBox-iconContainer i': {
      color: colors.text,
      paddingLeft: 10,
    },

    '&:hover .ms-SearchBox-field::placeholder': {
      color: colors.textSecondary,
    },

    // Edge fix: the separation of this selector is intentional; declaring inline (i.e., in a commma-separated list of selectors in the rule above) results in Edge not applying the rule
    '&:hover .ms-SearchBox-field::-webkit-input-placeholder': {
      color: colors.textSecondary,
    },

    '& input': {
      paddingLeft: 5,
    },

    '& active, & focus': {
      backgroundColor: colors.backgroundStandout,
    },
  };

  return {
    div: {
      flexGrow: 1,

      '&:hover .ms-SearchBox-iconContainer i': {
        color: colors.textSecondary,
      },
    },

    bar,

    barActive: {
      ...bar,

      color: colors.text,
      backgroundColor: colors.backgroundStandout,

      '& .ms-SearchBox-field::placeholder': {
        fontStyle: theme.fonts.fontStyles.italic,
      },

      '&:hover .ms-SearchBox-field::placeholder': {},

      // Edge fix: the separation of this selector is intentional; declaring inline (i.e., in a commma-separated list of selectors in the rule above) results in Edge not applying the rule
      '& .ms-SearchBox-field::-webkit-input-placeholder': {
        fontStyle: theme.fonts.fontStyles.italic,
      },

      '&:hover .ms-SearchBox-field::-webkit-input-placeholder': {},

      '& .ms-SearchBox-iconContainer i': {
        ...bar['& .ms-SearchBox-iconContainer i'],
        color: colors.textSecondary,
      },
    },
  };
};
