import { CommandButtonAtom } from 'components/atoms';
import { Spinner } from 'components/ions/Spinner/Spinner';
import { mergeClassNames } from 'components/utilities';
import { SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { FontSizes, ThemeProps } from 'styles';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { barButtonStyles } from './BarButton.styles';

export interface BarButtonProps extends ButtonSharedProps {
  spinnerLabelSize?: keyof FontSizes;
  spinnerLabelPadding?: number;
  /**
   * Temporary prop to only use new icon color under Flight.shadow
   */
  showNewIconColor?: boolean;
}

const styles = (theme: ThemeProps) => {
  return {
    ...barButtonStyles(theme),
    command: {
      ...barButtonStyles(theme).command,
      '& i': undefined,
    },
    icon: (props: BarButtonProps) => ({
      color: props.showNewIconColor ? theme.palette.secondary : theme.palette.text,
    }),
  };
};

type Props = BarButtonProps & WithStyles<typeof styles>;

const BarButtonUnstyled: React.FC<Props> = (props: Props) => {
  const isProductListDeleteButton = props.id === 'deleteButton';
  const groupedMergeClassName = mergeClassNames([
    props.classes.command,
    props.classes.bar,
    isProductListDeleteButton ? props.classes.productListBar : undefined,
  ]);
  return props.isLoading ? (
    <div className={`${props.classes.loading}`}>
      <Spinner
        label={props.text}
        labelPadding={props.spinnerLabelPadding}
        labelPosition="right"
        labelSize={props.spinnerLabelSize}
        size={SpinnerSize.small}
      />
    </div>
  ) : (
    <CommandButtonAtom
      addClass={groupedMergeClassName}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={props.iconName ? { iconName: props.iconName } : undefined}
      id={props.id}
      styles={{
        icon: props.classes.icon,
      }}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const BarButton = withStyles(styles)(BarButtonUnstyled) as React.FC<BarButtonProps>;
