export enum Environment {
  Int = 'int',
  Prod = 'prod',
}

export interface ComplianceControlConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Int]:
    'https://quotecenterservice.int.l2o.microsoft.com/v1/compliancecontrol/ManagementAPI/v1/search/roles',
  [Environment.Prod]:
    'https://quotecenterservice.l2o.microsoft.com/v1/compliancecontrol/ManagementAPI/v1/search/roles',
};

export const applicationId = {
  [Environment.Int]: '12092',
  [Environment.Prod]: '1040',
};
