import { MutationAssociateCrmIdToMcapiIdArgs } from 'generated/graphql';

import { useMutation } from '@apollo/react-hooks';

import { ASSOCIATE_CRMID } from './queries';

export const useAssociateCRMID = (
  crmId: string
): ((accountId: string, organizationId: string) => void) => {
  const [associate] = useMutation<
    { associateCrmIdToMcapiId: string },
    MutationAssociateCrmIdToMcapiIdArgs
  >(ASSOCIATE_CRMID);

  const onAssociate = (accountId: string, organizationId: string) => {
    associate({ variables: { input: { accountId, organizationId, crmId } } });
  };

  return onAssociate;
};
