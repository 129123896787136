import { ThemeProps } from 'styles';

export const buttonSharedStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    secondary: {
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderColor: colors.textSecondary,
      color: colors.textSecondary,
      '&:hover': {
        color: colors.textSecondary,
      },
      '&:active': {
        color: colors.textSecondary,
      },
      '&:disabled': {
        backgroundColor: colors.backgroundCommon,
        borderColor: colors.backgroundDivider,
        color: colors.textDisabled,
      },
      '& i': {
        color: colors.textSecondary,
      },
    },
    command: {
      color: colors.text,
      paddingTop: 12,
      paddingBottom: 15,
      '&:hover': {
        backgroundColor: 'transparent',
        color: colors.textSecondary,
        '& i, & .ms-Button-menuIcon': {
          color: colors.textSecondary,
        },
      },
      '&:focus': {
        backgroundColor: 'transparent',
        color: colors.textSecondary,
        '& i': {
          color: colors.textSecondary,
        },
        '&::after': {
          outlineColor: `${theme.palette.primary} !important`,
        },
      },
      '&:disabled': {
        color: colors.textDisabled,
      },
      '& i': {
        color: colors.text,
      },
      '& .ms-Button-menuIcon': {
        color: colors.text,
        fontSize: theme.fonts.fontSizes.small,
      },
    },
    commandExpanded: {
      backgroundColor: colors.backgroundStandout,
      borderRadius: '2px 2px 0px 0px',
      boxShadow: `${colors.shadowColor} 0px 0px 7px -2px`,
      color: colors.textSecondary,
      '& i': {
        color: `${colors.textSecondary}`,
      },
      '& .ms-Button-menuIcon': {
        color: colors.textSecondary,
      },
      '& .ms-Button-icon': {
        color: colors.text,
      },
      '&:hover': {
        backgroundColor: colors.backgroundStandout,
        '& i, & .ms-Button-menuIcon': {
          backgroundColor: colors.backgroundStandout,
        },
      },
    },
    icon: {
      backgroundColor: 'transparent',
      color: colors.textSecondary,
      '&:hover': {
        backgroundColor: 'transparent',
        color: colors.primaryHover,
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: colors.primaryActive,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        color: colors.textDisabled,
        '& i': {
          color: colors.textDisabled,
        },
      },
    },
    calloutMain: {
      backgroundColor: colors.backgroundStandout,
      borderRadius: '0px 2px 2px 2px',
    },
    calloutRoot: {
      borderRadius: '0px 2px 2px 2px',
      borderStyle: 'none',
      boxShadow: `${colors.shadowColor} 0px 5px 7px -2px`,
    },
    label: {
      whiteSpace: 'nowrap',
    },
    menuRoot: {
      minWidth: 150,
      '& .ms-ContextualMenu-divider': {
        backgroundColor: colors.backgroundDivider,
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    menuList: {
      borderRadius: '0px 2px 2px 2px',
    },
    menuItem: {
      paddingLeft: 12,
      '& i': {
        color: colors.text,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: colors.textSecondary,
        '& i, & .ms-ContextualMenu-icon': {
          color: colors.textSecondary,
        },
      },
      '&:active, &:active:focus': {
        backgroundColor: colors.primary,
        color: 'white',
        '& i': {
          color: 'white',
        },
      },
      '&:focus': {
        backgroundColor: colors.backgroundCommon,
      },
    },
    chevron: {
      '& .ms-Button-menuIcon, & .ms-Button-icon': {
        display: 'none',
      },
    },
  };
};

export const actionButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    text: {
      color: colors.primary,
      paddingTop: 15,
      paddingBottom: 15,
      '&:hover': {
        backgroundColor: 'transparent',
        color: colors.primaryHover,
        '& i': {
          color: colors.primaryHover,
        },
      },
      '&:focus': {
        backgroundColor: 'transparent',
        color: colors.primaryHover,
        '& i': {
          color: colors.primaryHover,
        },
      },
      '&:disabled': {
        color: colors.textDisabled,
      },
      '& i': {
        color: colors.primary,
      },
    },
  };
};
