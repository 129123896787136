import { getFlightIsEnabled } from 'features/app/selectors';
import * as actions from 'features/catalog/actions';
import { getCatalogConfig } from 'features/catalog/selectors';
import * as proposalActions from 'features/proposal/actions';
import { buildProductIdentifier } from 'features/proposal/utils';
import { getUser } from 'features/user/selectors';
import { User } from 'features/user/types';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import {
  CatalogCallConfig,
  FieldsTemplate,
  getNegotiatedTerms,
  searchProductPageSize,
  searchProductsByFamilyPageSize,
} from 'services/catalog/api';
import {
  Image,
  ImageObj,
  ImagePurpose,
  isImageObj,
  NegotiatedTerm,
  NegotiatedTermsRequest,
  ProductAttributeKey,
  ProductSearchResponse,
  ProductSearchSuccess,
  SkuLocalizedProperty,
} from 'services/catalog/types';
import { getImageTileByPurpose } from 'services/catalog/utils';
import { Flight } from 'services/flights/flightList';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

import { CatalogPageNegotiatedTerm, CatalogPageProduct, ProductSearch } from '../types';
import { filterFavoriteProductForLegacy, searchProductsByFamily } from './auxiliary';

export function* searchProducts() {
  const relevantAction = actions.searchProductsAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const catalogConfig: CatalogCallConfig = yield select(getCatalogConfig);
    const disableProductInclusionFilter: boolean = yield select((state: RootState) =>
      getFlightIsEnabled(state, Flight.disableProductInclusionFilter)
    );
    const enableBlendedAutosuggest: boolean = yield select((state: RootState) =>
      getFlightIsEnabled(state, Flight.enableBlendedAutosuggest)
    );
    const user: User = yield select((state: RootState) => state.user.current);
    const pageSize = enableBlendedAutosuggest
      ? searchProductsByFamilyPageSize
      : searchProductPageSize;
    try {
      const {
        products,
        productFamiliesWithMoreResults,
      }: ProductSearchResponse = enableBlendedAutosuggest
        ? yield call(
            api.catalog.searchBlendedProducts,
            action.payload,
            user,
            catalogConfig,
            disableProductInclusionFilter
          )
        : yield call(
            api.catalog.searchProducts,
            action.payload,
            user,
            catalogConfig,
            disableProductInclusionFilter
          );

      if (!products.length && productFamiliesWithMoreResults.length) {
        const searchProductByFamilyNamesResponse: ProductSearchSuccess = yield call(
          searchProductsByFamily,
          { productFamilyNames: productFamiliesWithMoreResults, query: action.payload.query },
          pageSize
        );

        yield put(actions.searchProductsAsync.success(searchProductByFamilyNamesResponse));
      } else {
        yield put(
          actions.searchProductsAsync.success({
            nextSkip: pageSize, // skip first 12 results that where already found with product search to avoid duplicates
            products,
            productFamiliesWithMoreResults,
          })
        );
      }
    } catch (err) {
      yield put(
        actions.searchProductsAsync.failure({
          message: t('error::Error searching catalog for products.'),
          exception: err,
        })
      );
    }
  });
}

export function* filterFavoriteProductsForLegacy() {
  const relevantAction = actions.filterFavoriteProductsForLegacyAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      yield all(
        action.payload.productNames.map((productName: string) => {
          const request = {
            query: productName,
            productFamilyNames: action.payload.productFamilyNames,
          };
          return call(filterFavoriteProductForLegacy, request);
        })
      );
      yield put(proposalActions.loadAndHydrateAllQuoteWithFavorites.success());
    } catch (err) {
      yield put(
        actions.filterFavoriteProductsForLegacyAsync.failure({
          message: t('error::Error filtering favorites products.'),
          exception: err,
        })
      );
    }
  });
}

export function* loadNegotiatedTerms() {
  const relevantAction = actions.getNegotiatedTerms.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const user: User = yield select(getUser);
      const config: CatalogCallConfig = yield select(getCatalogConfig);
      const market = action.payload.market || config.market;
      const languages = action.payload.language || config.languages;
      const request: NegotiatedTermsRequest = {
        top: action.payload.top,
      };
      const response = yield call(getNegotiatedTerms, request, user, {
        ...config,
        market,
        languages,
      });
      const newTerms: CatalogPageNegotiatedTerm[] = [];
      const terms: NegotiatedTerm[] = response.Products;
      terms.forEach((term: NegotiatedTerm) => {
        const imageObjDefined = isImageObj(term.LocalizedProperties[0].Images)
          ? term.LocalizedProperties[0].Images
          : undefined;
        const smallImage = imageObjDefined
          ? getImageTileByPurpose(ImagePurpose.smallTile, imageObjDefined as ImageObj)
          : (term.LocalizedProperties[0].Images as Image[])[0];
        const mediumImage = imageObjDefined
          ? getImageTileByPurpose(ImagePurpose.mediumTile, imageObjDefined as ImageObj)
          : (term.LocalizedProperties[0].Images as Image[])[0];
        const skusLocalizedProperties = term.DisplaySkuAvailabilities.map(
          sku => sku.Sku.LocalizedProperties
        );
        const skus = ([] as SkuLocalizedProperty[]).concat(...skusLocalizedProperties);
        newTerms.push({
          description: term.LocalizedProperties[0].ProductDescription,
          id: term.ProductId,
          images: {
            small: smallImage,
            medium: mediumImage,
            logo: imageObjDefined && (imageObjDefined as ImageObj).Logo,
          },
          skus,
          title: term.LocalizedProperties[0].ProductTitle,
          publisherName: term.LocalizedProperties[0].PublisherName,
        });
      });
      yield put(actions.getNegotiatedTerms.success({ ...response, Products: newTerms }));
    } catch (err) {
      yield put(
        actions.getNegotiatedTerms.failure({
          message: t('error::Error getting negotiated terms'),
          exception: err,
        })
      );
    }
  });
}

export function* searchNegotiatedTerms() {
  const relevantAction = actions.searchTermsAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const catalogConfig: CatalogCallConfig = yield select(getCatalogConfig);
    const user: User = yield select((state: RootState) => state.user.current);
    try {
      const searchTermsResponse: ProductSearchResponse = yield call(
        api.catalog.searchTerms,
        action.payload,
        user,
        catalogConfig
      );
      yield put(
        actions.searchTermsAsync.success({
          nextSkip: action.payload.top,
          products: searchTermsResponse.products,
          productFamiliesWithMoreResults: searchTermsResponse.productFamiliesWithMoreResults,
        })
      );
    } catch (err) {
      yield put(
        actions.searchTermsAsync.failure({
          message: t('error::Error searching catalog for terms.'),
          exception: err,
        })
      );
    }
  });
}

export function* expandSearchProduct() {
  const relevantAction = actions.expandProductSearchAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const fromState: ProductSearch = yield select(
      (state: RootState) => state.catalog.search.results
    );

    try {
      const searchProductByFamilyNamesResults: ProductSearchSuccess = yield call(
        searchProductsByFamily,
        {
          productFamilyNames: fromState.productFamiliesWithMoreResults,
          query: action.payload,
        },
        fromState.nextSkip,
        3
      );

      yield put(actions.expandProductSearchAsync.success(searchProductByFamilyNamesResults));
    } catch (err) {
      yield put(
        actions.expandProductSearchAsync.failure({
          message: t('error::Error searching catalog for products by family names.'),
          exception: err,
        })
      );
    }
  });
}

export function* searchCatalogPageProducts() {
  const relevantAction = actions.searchCatalogPageProducts.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const catalogConfig: CatalogCallConfig = yield select(getCatalogConfig);
    const market = action.payload.market || catalogConfig.market;
    const languages = action.payload.language || catalogConfig.languages;
    const retries = 3;
    try {
      const {
        nextSkip,
        products,
        productFamiliesWithMoreResults,
      }: ProductSearchSuccess = yield call(
        searchProductsByFamily,
        {
          productFamilyNames: [action.payload.productFamilyName],
          query: action.payload.query || '*',
          serviceFamily: action.payload.serviceFamily,
        },
        action.payload.resultsToSkip,
        retries,
        // when getting products for the catalog page, we need to have the actionFilter set to Purchase to get appropriate results back
        { ...catalogConfig, actionFilter: FieldsTemplate.Purchase, market, languages }
      );

      const newProducts: Record<string, CatalogPageProduct> = {};

      products.forEach(product => {
        const attributes = product.Properties.Attributes;
        const categories =
          attributes &&
          attributes.find(attribute => attribute.Key === ProductAttributeKey.Categories);
        const industries =
          attributes &&
          attributes.find(attribute => attribute.Key === ProductAttributeKey.IndustryCategories);
        const imageObjDefined = isImageObj(product.LocalizedProperties[0].Images)
          ? product.LocalizedProperties[0].Images
          : undefined;

        const newProduct = {
          categories: categories && categories.Value,
          description: product.LocalizedProperties[0].ProductDescription,
          id: product.ProductId,
          images: {
            small:
              imageObjDefined && getImageTileByPurpose(ImagePurpose.smallTile, imageObjDefined),
            medium:
              imageObjDefined && getImageTileByPurpose(ImagePurpose.mediumTile, imageObjDefined),
            logo: imageObjDefined && imageObjDefined.Logo,
          },
          identifier: buildProductIdentifier(product),
          industries: industries && industries.Value,
          publisherName: product.LocalizedProperties[0].PublisherName,
          title: product.LocalizedProperties[0].ProductTitle,
          skus: product.DisplaySkuAvailabilities.map(sku => sku.Sku.LocalizedProperties[0]),
        };

        newProducts[newProduct.id] = newProduct;
      });

      yield put(
        actions.searchCatalogPageProducts.success({
          hasMorePages: !!productFamiliesWithMoreResults.length,
          products: newProducts,
          resultsToSkip: nextSkip,
          serviceFamily: action.payload.serviceFamily,
          catalogKey: `market:${market}|languages:${languages}`,
          query: action.payload.query,
        })
      );
    } catch (err) {
      yield put(
        actions.searchCatalogPageProducts.failure({
          message: t('error::Error searching catalog for products.'),
          exception: err,
        })
      );
    }
  });
}
