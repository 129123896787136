import { Dialog, PrimaryButton, TextBody } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { enrollmentSetStartDateDialogStyles } from './EnrollmentSetStartDateDialog.styles';

export interface EnrollmentSetStartDateDialogProps {
  closeDialog: () => void;
}

type Props = EnrollmentSetStartDateDialogProps &
  WithStyles<typeof enrollmentSetStartDateDialogStyles>;

const EnrollmentSetStartDateDialogUnstyled: React.FC<Props> = ({ classes, closeDialog }: Props) => {
  const { t } = useTranslation();

  const confirmButton = (
    <PrimaryButton
      dataAutomationId="enrollmentSetStartDateDialogConfirmButton"
      text={t('quote::Confirm')}
      onClick={closeDialog}
    />
  );

  return (
    <Dialog
      closeDialog={closeDialog}
      dataAutomationId="EnrollmentSetStartDateDialog"
      footerButtons={confirmButton}
      height={220}
      title={t('quote::Enrollment-set start dates')}
      width={375}
    >
      <div>
        <TextBody addClass={classes.actionTextPadding}>
          {t(
            'quote::The enrollment number added to the quote has automatically set the appropriate Office subscription start date to align with the end of this enrollment.'
          )}
        </TextBody>
      </div>
    </Dialog>
  );
};

export const EnrollmentSetStartDateDialog = withStyles(enrollmentSetStartDateDialogStyles)(
  EnrollmentSetStartDateDialogUnstyled
);
