// Included in main bundle...
import { SkipToMain } from 'components/ions';
import { ConnectedRouter } from 'connected-react-router';
import { meplaHistory } from 'createHistory';
import { ActiveQuoteProvider } from 'features-apollo/ActiveQuoteProvider';
import { AppContent } from 'features-apollo/app/components/AppContent';
import { AuthzProvider } from 'features-apollo/app/components/AuthzProvider';
import { FlightsProvider } from 'features-apollo/app/components/FlightsProvider';
import { LoadingScreen } from 'features-apollo/app/components/LoadingScreen';
import { addDnDEventListener } from 'features-apollo/app/utils';
import NavBar from 'features/app/components/NavBar';
import CrmId from 'features/proposal/components/CrmId';
import { initializeIcons } from 'icons/src';
import React, { Suspense } from 'react';
import { AzureAD } from 'react-aad-msal';
import { connect, Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { apolloClient } from 'services/apollo/apolloClient';
import { AppId, initAuthProvider } from 'services/utils';
import { RootState } from 'store/types';

import { ApolloProvider } from '@apollo/react-hooks';

import { External } from './features/app/components';
import { HelpArticlePopout } from './features/app/components/HelpPanel/HelpArticlePopout';
import Home from './features/home/components/Home';
import { MainLayout } from './layouts';
import { routes } from './routes';
import store from './store';
import { DialogProvider } from './styles';
import ThemeProvider from './styles/theme/ThemeProvider';

initializeIcons('https://quotecenterfabric.azureedge.net/fonts/');
addDnDEventListener();

export const buildNumber = '2020.9.30.4';

// Lazily loaded modules...
const Catalog = React.lazy(() => import('features/catalog/components/Catalog'));
const Proposal = React.lazy(() => import('features/proposal/components/Proposal'));
const Customer = React.lazy(() => import('features/ldsscustomer/components/Customer'));

const getBodySlot = () => {
  return (
    <Switch>
      <Route component={External} path={routes.external.root} />
      <Route component={HelpArticlePopout} path={routes.content.articlePopout} />
      <Route exact path={routes.root} render={() => <Redirect to={routes.home.root} />} />
      <Route component={Home} path={routes.home.root} />
      <Route component={Catalog} path={routes.catalog.root} />
      <Route component={Proposal} path={routes.quote.root} />
      <Route component={Customer} path={routes.customer.root} />
      <Route component={CrmId} path={routes.opportunity.root} />
      <Route component={CrmId} path={routes.engagement.root} />
      <Route component={CrmId} path={routes.crmId.root} />
      <Route
        path={routes.proposal.root}
        render={props => <Redirect to={routes.quote.forId(props.match.params.id)} />}
      />
      <Route exact path={routes.notFound} render={() => <div>Page not found...</div>} />
      <Route
        exact
        path={routes.catalog.redirect}
        render={() => <Redirect to={routes.home.catalog} />}
      />
      <Route
        exact
        path={routes.quote.redirect}
        render={() => <Redirect to={routes.home.quotes.root} />}
      />
      <Route
        exact
        path={routes.customer.redirect}
        render={() => <Redirect to={routes.home.customers} />}
      />
      <Route render={() => <Redirect to={routes.root} />} />
    </Switch>
  );
};

const getSkipToMainSlot = () => {
  return <SkipToMain displayText="Skip to main content" href="#PrimaryArea" id="SkipToMain" />;
};

const getHeaderSlot = () => {
  return (
    <Switch>
      <Route component={NavBar} path={routes.home.root} />
      <Route component={NavBar} path={routes.catalog.root} />
      <Route component={NavBar} path={routes.quote.root} />
      <Route component={NavBar} path={routes.customer.root} />
      <Route component={NavBar} path={routes.opportunity.root} />
      <Route component={NavBar} path={routes.engagement.root} />
      <Route component={NavBar} path={routes.crmId.root} />
      <Route component={NavBar} exact path={routes.notFound} />
    </Switch>
  );
};

interface Chooser {
  apolloClient: boolean;
}

const mapStateToProps = (state: RootState) => {
  return { apolloClient: state.app.flights.graphqlphase2 };
};

const MainLayoutSelectorUnconnected: React.FC<Chooser> = props => {
  if (!props.apolloClient) {
    const currentPageNoArticlePopout =
      meplaHistory.location.pathname !== routes.content.articlePopout;

    return (
      <MainLayout
        bodySlot={getBodySlot()}
        headerSlot={getHeaderSlot()}
        skipToMainSlot={currentPageNoArticlePopout ? getSkipToMainSlot() : null}
      />
    );
  }

  return <AppContent />;
};

const MainLayoutSelector = connect(mapStateToProps)(MainLayoutSelectorUnconnected);

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <AzureAD
          forceLogin={true}
          provider={initAuthProvider(AppId[store.getState().app.appConfig.aad])}
          reduxStore={store}
        >
          <Suspense fallback={<LoadingScreen />}>
            <AuthzProvider>
              <ApolloProvider client={apolloClient(store)}>
                <ActiveQuoteProvider>
                  <DialogProvider>
                    <ConnectedRouter history={meplaHistory}>
                      <FlightsProvider>
                        <MainLayoutSelector />
                      </FlightsProvider>
                    </ConnectedRouter>
                  </DialogProvider>
                </ActiveQuoteProvider>
              </ApolloProvider>
            </AuthzProvider>
          </Suspense>
        </AzureAD>
      </ThemeProvider>
    </Provider>
  );
};

