import { CommandButtonAtom } from 'components/atoms';
import {
  IButtonStyles,
  IContextualMenuItemStyles,
  IContextualMenuStyles,
  IMenuItemStyles,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { buildButtonMenu, ButtonMenuProps, ButtonSharedProps } from '../ButtonSharedProps';
import { buttonSharedStyles } from '../shared.styles';

export interface MenuButtonProps extends ButtonSharedProps {
  menuProps?: ButtonMenuProps[];
  menuId: string;
}

type Props = MenuButtonProps & WithStyles<typeof buttonSharedStyles>;

const MenuButtonUnstyled: React.FC<Props> = (props: Props) => {
  const buttonStyle: Partial<IButtonStyles> = {
    rootExpanded: props.classes.commandExpanded,
  };
  const menuItemStyle: Partial<IContextualMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  const dropdownStyles: Partial<IContextualMenuStyles> = {
    root: props.classes.menuRoot,
    container: props.classes.menuList,
    subComponentStyles: {
      callout: {
        root: props.classes.calloutRoot,
        calloutMain: props.classes.calloutMain,
      },
      menuItem: menuItemStyle,
    },
  };
  const menuItemStyles: Partial<IMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  return (
    <CommandButtonAtom
      addClass={props.classes.command}
      ariaLabel={props.ariaLabel}
      disabled={props.disabled}
      iconProps={props.iconName ? { iconName: props.iconName } : undefined}
      id={props.id}
      menuProps={
        props.menuProps && props.menuId
          ? buildButtonMenu(props.menuProps, dropdownStyles, menuItemStyles, props.menuId)
          : undefined
      }
      styles={buttonStyle}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const MenuButton = withStyles(buttonSharedStyles)(MenuButtonUnstyled) as React.FC<
  MenuButtonProps
>;
