import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => {
  const { fonts } = theme;
  return {
    container: {
      paddingTop: 29,
    },
    noError: {
      height: 354,
    },
    oneError: {
      height: 374.45,
    },
    twoErrors: {
      height: 394,
    },
    startingInformation: {
      marginTop: 16,
    },
    goodForContainer: {
      height: 67,
      marginTop: 16,
      width: 124,
    },
    emphasisText: {
      fontWeight: fonts.fontWeights.semiBold,
    },

    opportunityIdLabel: {
      display: 'inline',
      fontWeight: fonts.fontWeights.semiBold,
    },

    textTertiary: {
      fontWeight: fonts.fontWeights.regular,
      color: theme.palette.textTertiary,
    },
    amountContainer: {
      width: 180,
    },
    errorMessageStyle: {
      width: 216,
    },
    textInputContainer: {
      marginTop: 11,
      height: 67,
      width: 250,
      '& label': {
        fontWeight: fonts.fontWeights.semiBold,
      },
    },
    opportunityIdContainer: {
      marginTop: 11,
      width: 250,
      '& .ms-TextField-fieldGroup': {
        width: 150,
      },
    },
    sectionSeparator: {
      marginTop: 18,
    },
    customLabel: {
      marginTop: 16,
      marginBottom: 0,
      fontWeight: fonts.fontWeights.semiBold,
    },
    required: {
      color: theme.palette.dangerText,
    },
  };
};
