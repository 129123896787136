import { CommandBar, CommandBarButton, notificationOrigin } from 'components/ions';
import { getFlightIsEnabled } from 'features/app/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { routes } from 'routes';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

import {
  getActiveProposal,
  getActiveProposalId,
  isAnyNotificationOriginatedFrom,
  isPartnerProposal,
} from '../selectors';
import { Pivot, PivotProps } from './Pivot';

const styles = {
  backButton: {
    minWidth: 148,
  },
  pivot: {
    marginLeft: 18,
  },
};

const mapStateToProps = (state: RootState) => ({
  quoteId: getActiveProposalId(state),
  isAnyNotificationOriginatedFrom: (expectedOrigin: notificationOrigin) =>
    isAnyNotificationOriginatedFrom(state, getActiveProposal(state), expectedOrigin),
  isPartnerProposal: isPartnerProposal(state),
  useApolloClient: getFlightIsEnabled(state, Flight.graphqlPhase2),
});

type Props = RouteComponentProps & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

const BackToProposalBar: React.FC<Props> = props => {
  const {
    classes,
    history,
    match,
    quoteId,
    isAnyNotificationOriginatedFrom,
    isPartnerProposal,
    useApolloClient,
  } = props;
  const { t } = useTranslation();

  const anyCustomerNotifications = isAnyNotificationOriginatedFrom(notificationOrigin.customer);
  const anySalesNotifications = isAnyNotificationOriginatedFrom(notificationOrigin.sales);
  const anyInfoNotifications = isAnyNotificationOriginatedFrom(notificationOrigin.info);

  const getInfoTab = () => {
    if (useApolloClient) {
      return {
        id: 'general',
        path: routes.quote.general,
        text: t('quote::General'),
        required: !isPartnerProposal && anyInfoNotifications,
        dataAutomationId: 'generalTab',
      };
    } else {
      return {
        id: 'info',
        path: routes.quote.info,
        text: t('quote::Info'),
        required: !isPartnerProposal && anyInfoNotifications,
        dataAutomationId: 'infoTab',
      };
    }
  };

  const pivotProps: PivotProps = {
    addClass: props.classes.pivot,
    defaultTab: { id: 'customer', path: routes.quote.customer, text: t('quote::Customer') },
    history,
    match,
    quoteId,
    tabs: [
      {
        id: 'customer',
        path: routes.quote.customer,
        text: t('quote::Customer'),
        required: !isPartnerProposal && anyCustomerNotifications,
        dataAutomationId: 'customerTab',
      },
      {
        id: 'sales',
        path: routes.quote.sales,
        text: t('quote::Sales'),
        required: !isPartnerProposal && anySalesNotifications,
        dataAutomationId: 'salesTab',
      },
      getInfoTab(),
    ],
  };

  return (
    <CommandBar id="ProposalEditor-CommandBar" useBackgroundStandoutColor>
      <CommandBarButton
        addClass={classes.backButton}
        ariaLabel={t('quote::Back to the Quote Editor page')}
        dataAutomationId="backToQuoteButton"
        iconName="Back"
        text={t('quote::Back To Quote')}
        onClick={() => history.push(routes.quote.forId(quoteId))}
      />
      <Pivot {...pivotProps} />
    </CommandBar>
  );
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(BackToProposalBar)));
