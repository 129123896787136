import { LinkButton, mergeClassNames, Organization, TextBody, TextBodySmall } from 'components';
import { Address } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { sharedStyles, ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

export interface OrganizationTileCustomStyles {
  /**
   * Class name for the root element
   */
  root?: string;
}

export interface OrganizationTileProps {
  /**
   * Use to identify organization in the DOM
   */
  id?: string;
  /**
   * Organization Name and Address
   */
  address?: Address | null;
  /**
   * Modern account tenant id
   */
  tenantId?: string;
  /**
   * Organization trade name
   */
  tradeName?: string | null;
  /**
   * Modern account tenant name
   */
  tenantName?: string | null;
  /**
   * Whether tenant id or tenant name will be display. The other will be able by expanding the section.
   * @default 'tid'
   */
  tenantInfoToDisplay?: 'tid' | 'tname';
  /**
   * Customer's organization last purchase date. This is available after MCA is signed.
   */
  lastPurchaseDate?: string | null;
  /**
   * Displays at the bottom of the organization information
   */
  links?: React.ReactNode;
  /**
   * Darkers the tile borders
   */
  emphasized?: boolean;
  /**
   * Text to show when organization's address is missing
   * @default 'Unknown billing account'
   */
  unknownLabel?: string;
  /**
   * Custom styling for individual elements within the organization tile DOM.
   */
  styles?: OrganizationTileCustomStyles;
}

const styles = (theme: ThemeProps) => ({
  expandButton: { marginTop: 4, fontSize: theme.fonts.fontSizes.small },
  fadeIn: {
    transition: 'margin 0s, height 0s ease-out, opacity 0.3s ',
    height: 32,
    opacity: 1,
  },
  fadeOut: {
    transition: 'margin .1s, height 0s ease-in, opacity 0.1s ',
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    margin: 0,
  },
  lastPurchaseDate: {
    display: 'block',
    marginTop: 8,
    color: theme.palette.textTertiary,
  },
  organizationInformationContainer: { width: 'inherit' },
  tenantId: { display: 'flex', flexDirection: 'column', marginTop: 8 },
  tenantName: { display: 'flex', flexDirection: 'column', marginTop: 8 },
  unknownLabel: {
    ...sharedStyles(theme).textOverflow,
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  root: (props: OrganizationTileProps) => ({
    width: 'inherit',
    maxWidth: 440,
    // --
    display: 'flex',
    padding: '12px 0 12px 12px',
    justifyContent: 'space-between',
    // --
    border: `1px solid ${
      props.emphasized ? theme.palette.backgroundDivider : theme.palette.backgroundCommon
    }`,
    borderRadius: 2,
  }),
});

type Props = OrganizationTileProps & WithStyles<typeof styles>;

const OrganizationTileUnstyled: React.FC<Props> = props => {
  const { classes, styles } = props;
  const { t } = useTranslation();
  const [sectionExpanded, setSectionExpanded] = React.useState<boolean>(false);

  const fadeStyle = sectionExpanded ? classes.fadeIn : classes.fadeOut;

  const tenantIdClassName = mergeClassNames([
    classes.tenantId,
    props.tenantName && props.tenantInfoToDisplay === 'tname' ? fadeStyle : undefined,
  ]);
  const tenantNameClassName = mergeClassNames([
    classes.tenantName,
    props.tenantName && props.tenantInfoToDisplay !== 'tname' ? fadeStyle : undefined,
  ]);

  const organization = props.address ? (
    <Organization address={props.address} showCompanyName tradeName={props.tradeName} />
  ) : (
    <TextBody addClass={classes.unknownLabel}>
      {props.unknownLabel || t('quote::Unknown billing account')}
    </TextBody>
  );

  const tenantId = props.tenantId ? (
    <div className={tenantIdClassName}>
      <TextBodySmall>{t('quote::Tenant ID:')}</TextBodySmall>
      <TextBodySmall>{props.tenantId}</TextBodySmall>
    </div>
  ) : null;

  const tenantName = props.tenantName && (
    <div className={tenantNameClassName}>
      <TextBodySmall>{t('quote::Tenant name:')}</TextBodySmall>
      <TextBodySmall>{props.tenantName}</TextBodySmall>
    </div>
  );

  const lastPurchaseDate = props.lastPurchaseDate && (
    <TextBodySmall addClass={classes.lastPurchaseDate}>
      {t('quote::last purchase: {{date}}', { date: props.lastPurchaseDate })}
    </TextBodySmall>
  );

  const expandResultButton = props.tenantName && props.tenantId && (
    <LinkButton
      displayText={sectionExpanded ? t('less') : t('more')}
      styles={{ root: classes.expandButton }}
      onClick={() => setSectionExpanded(!sectionExpanded)}
    />
  );

  return (
    <div className={mergeClassNames([classes.root, oc(styles).root()])} id={props.id}>
      <div className={classes.organizationInformationContainer}>
        {organization}
        {tenantId}
        {tenantName}
        {lastPurchaseDate}
        {expandResultButton}
        {props.links}
      </div>
      {props.children}
    </div>
  );
};

export const OrganizationTile = withStyles(styles)(OrganizationTileUnstyled);
