import React from 'react';
import { useTranslation } from 'react-i18next';

import { InformationalTile } from './InformationalTile';

export const MCAPIAccountNotFoundInfoTile: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <InformationalTile
      buttonProps={{ text: t('quote::Create "MCAPI account"'), onClick }}
      description={t(
        'quote::But you can create your customer footprint using the provided tenant information.'
      )}
      iconName="AccountManagement"
      title={t('quote::No "MCAPI account" found with that tenant...')}
    />
  );
};
