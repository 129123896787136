import { StandardSearch } from 'components/ions/Search';
import { TextWatermark } from 'components/ions/Text/Content';
import { ExpandableList } from 'components/molecules/ExpandableList';
import { ISearchBox } from 'office-ui-fabric-react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './FAQ.styles';
import * as FAQContent from './FAQContent';

type Props = {} & WithTranslation & WithStyles<typeof styles>;

export interface IAnswerLink {
  url: string;
  text: string;
  id: string;
}
export interface IQuestionLayout {
  id: string;
  list: boolean;
  question: string;
  answer: {
    openingSentence: string;
    answerList?: string[];
    answerLinks?: IAnswerLink[];
    answerComponentKey?: string;
  };
}

const FAQ: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState('');

  const searchFocusRef = React.useRef<ISearchBox>(null);
  useHotkeys('ctrl+/', () => {
    searchFocusRef.current && searchFocusRef.current.focus();
  });

  const onSearch = (value?: string) => {
    setSearchValue(value || '');
  };

  let overviewQuestions: IQuestionLayout[] = FAQContent.overviewQuestions(t);
  let customerQuestions: IQuestionLayout[] = FAQContent.customerQuestions(t);
  let currencyQuestions: IQuestionLayout[] = FAQContent.currencyQuestions(t);
  let quoteCenterQuestions: IQuestionLayout[] = FAQContent.quoteCenterQuestions(t);

  const filterQuestions = (questions: IQuestionLayout[]): IQuestionLayout[] => {
    return questions.filter(
      t => t.question.toLowerCase().indexOf(searchValue.toString().toLowerCase()) >= 0
    );
  };

  if (searchValue && searchValue.length !== 0) {
    overviewQuestions = filterQuestions(overviewQuestions);
    customerQuestions = filterQuestions(customerQuestions);
    currencyQuestions = filterQuestions(currencyQuestions);
    quoteCenterQuestions = filterQuestions(quoteCenterQuestions);
  }

  return (
    <div className={props.classes.scroll}>
      <TextWatermark addClass={props.classes.label}>
        {t('help::Search our frequently asked questions')}
      </TextWatermark>
      <div className={props.classes.search}>
        <StandardSearch
          autoFocus={true}
          componentRef={searchFocusRef}
          placeholder="Search for an existing question using keywords"
          value={searchValue}
          onChangeDebounced={onSearch}
        />
      </div>
      <ExpandableList
        displayHeading={overviewQuestions.length !== 0}
        id="overviewQuestions"
        questions={overviewQuestions}
        title={t('help::Overview')}
      ></ExpandableList>
      <ExpandableList
        displayHeading={quoteCenterQuestions.length !== 0}
        id="quoteCenterQuestions"
        questions={quoteCenterQuestions}
        title={t('help::Quote Center')}
      ></ExpandableList>
      <ExpandableList
        displayHeading={customerQuestions.length !== 0}
        id="customerQuestions"
        questions={customerQuestions}
        title={t('help::Customer')}
      ></ExpandableList>
      <ExpandableList
        currencies={FAQContent.currencies(t)}
        displayHeading={currencyQuestions.length !== 0}
        id="currencyQuestions"
        questions={currencyQuestions}
        title={t('help::Currency')}
      ></ExpandableList>
      {overviewQuestions.length === 0 &&
      quoteCenterQuestions.length === 0 &&
      customerQuestions.length === 0 &&
      currencyQuestions.length === 0 ? (
        <TextWatermark addClass="NoResultsWatermark">
          {t(
            'help::No frequently asked questions were found based on your search term. Try a different term or contact support.'
          )}
        </TextWatermark>
      ) : null}
    </div>
  );
};

export default withTranslation('help')(withStyles(styles)(FAQ));
