import {
  CustomDialogBox,
  DialogHeader,
  mergeClassNames,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
} from 'components';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { Fail, Processing, Success } from 'features-apollo/components/dialogs';
import { QuoteMutationInput } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext, ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { AddSharedDiscounts } from '../../queries';
import { sharedStyles } from '../shared.styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  semiBoldFont: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  marginTop14: {
    marginTop: 14,
  },
});

const dialogDimensions = {
  width: 550,
  height: 305,
};

enum SharedDiscountsSteps {
  Overview,
  Processing,
}

export interface SharedDiscountsDialogProps {
  quoteInput: QuoteMutationInput;
  affiliateAccountName: string;
  parentAccountName?: string;
}

type Props = SharedDiscountsDialogProps & WithStyles<typeof styles>;

const SharedDiscountsDialogUnstyled: React.FC<Props> = props => {
  const { classes, quoteInput, parentAccountName, affiliateAccountName } = props;
  const { t } = useTranslation();

  const dialogContext = React.useContext(DialogContext);
  const closeDialog = () => dialogContext.closeDialog();

  const [currentStep, setCurrentStep] = React.useState<SharedDiscountsSteps>(
    SharedDiscountsSteps.Overview
  );

  // gql mutation to add shared discounts
  const [addSharedDiscounts, { loading, error }] = useMutation(AddSharedDiscounts, {
    update(cache, response) {
      const updatedQuote = oc(response).data.addSharedDiscounts();
      updatedQuote &&
        cache.writeQuery({
          query: GET_QUOTE,
          variables: { id: updatedQuote.id },
          data: { getQuote: updatedQuote },
        });
    },
  });

  const handleExtendDiscounts = () => {
    // call mutation
    addSharedDiscounts({
      variables: {
        input: quoteInput,
      },
    });
    setCurrentStep(SharedDiscountsSteps.Processing);
  };

  const header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="SharedDiscountsDialog"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Shared discounts')}</TextTitle>
    </DialogHeader>
  );

  const body = (
    <div className={classes.body}>
      <div>
        <TextBody>
          {parentAccountName
            ? t(
                'quote::The parent account, {{parentAccountName}}, has previously negotiated discounts. Consult with their Microsoft account team to understand if these discounts should be given to the affiliate account, {{affiliateAccountName}}.',
                { parentAccountName, affiliateAccountName }
              )
            : t(
                'quote::The parent account has previously negotiated discounts. Consult with their Microsoft account team to understand if these discounts should be given to the affiliate account, {{affiliateAccountName}}.',
                { affiliateAccountName }
              )}
        </TextBody>
      </div>
      <div className={classes.marginTop14}>
        <TextBody>
          {t(
            'quote::To extend the discounts to {{affiliateAccountName}}, you must add them to the quote.',
            { affiliateAccountName }
          )}
        </TextBody>
      </div>
    </div>
  );

  const footer = (
    <div className={mergeClassNames([classes.footer, classes.buttonRightAlign])}>
      <PrimaryButton
        dataAutomationId="shareDiscountsDialogButton"
        text={t('quote::Add discounts')}
        onClick={handleExtendDiscounts}
      />
      <div className={classes.secondButton}>
        <SecondaryButton
          dataAutomationId="shareDiscountsDialogDismissButton"
          text={t('quote::Not now')}
          onClick={closeDialog}
        />
      </div>
    </div>
  );

  switch (currentStep) {
    case SharedDiscountsSteps.Processing: {
      if (loading) {
        return <Processing {...dialogDimensions} key="Processing" />;
      } else if (error) {
        return (
          <Fail
            {...dialogDimensions}
            dataAutomationId="addSharedDiscountsFail"
            message={t(
              `quote::Sorry, we couldn't add the discounts to the quote. Try again later using the Add Shared Discounts button on the quote page.`
            )}
          />
        );
      } else {
        return (
          <Success
            message={t('quote::Discounts have been successfully added to the quote.')}
            {...dialogDimensions}
            dataAutomationId="addSharedDiscountsSuccess"
            onClose={closeDialog}
          />
        );
      }
    }
    case SharedDiscountsSteps.Overview:
    default: {
      return (
        <CustomDialogBox
          {...dialogDimensions}
          bodySlot={body}
          footerSlot={footer}
          headerSlot={header}
        />
      );
    }
  }
};

export const SharedDiscountsDialog = withStyles(styles)(SharedDiscountsDialogUnstyled);
