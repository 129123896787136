import {
  Collapsible,
  CurrencyMap,
  ICurrencyLayout,
  LinkExternal,
  TextSectionHeading,
} from 'components';
import { IAnswerLink, IQuestionLayout } from 'features/home/components/help/FAQ';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import { styles } from './ExpandableList.styles';

export interface ExpandableListProps {
  title?: string;
  questions: IQuestionLayout[];
  currencies?: ICurrencyLayout[];
  displayHeading?: boolean;
  id?: string;
}

type Props = ExpandableListProps & WithStyles<typeof styles>;

const formatAnswerString = (
  str: string,
  formatingFunction?: (text: number | string, url: string, key: string) => React.ReactNode,
  values?: IAnswerLink[]
) => {
  const templateSplit = new RegExp(/{(\d)}/g);
  const isNumber = new RegExp(/^\d+$/);
  const splitedText = str.split(templateSplit);
  return splitedText.map(sentence => {
    if (isNumber.test(sentence) && values && formatingFunction) {
      const value = values[Number(sentence)];
      return Boolean(formatingFunction)
        ? formatingFunction(value.text, value.url, value.id)
        : value.text;
    }
    return sentence;
  });
};

const ExpandableListUnstyled: React.FC<Props> = props => {
  const mapQuestions = (questions: IQuestionLayout[]) => {
    const questionsToDisplay = questions.map(questionElement => {
      let answerToDisplay, componentToDisplay;

      if (questionElement.list) {
        const answerListItems = oc(questionElement)
          .answer.answerList([])
          .map(listItem => {
            if (
              questionElement.answer.answerLinks &&
              questionElement.answer.answerLinks.length > 0
            ) {
              // Formatting answer list items with answer links if specified
              let formattedListItem = formatAnswerString(
                listItem,
                (text, url, key) => (
                  <LinkExternal displayText={text.toString()} href={url} key={key} />
                ),
                questionElement.answer.answerLinks
              );
              return <li key={listItem}>{formattedListItem}</li>;
            }
            return <li key={listItem}>{listItem}</li>;
          });
        answerToDisplay = (
          <ul className={props.classes.list}>
            {questionElement.answer.openingSentence}
            <ol>{answerListItems}</ol>
          </ul>
        );
      } else {
        answerToDisplay = questionElement.answer.openingSentence;
        // Inject custom answer components if specified
        if (questionElement.answer.answerComponentKey === 'CurrencyMap') {
          componentToDisplay = <CurrencyMap currencies={props.currencies} />;
        }
        // Formatting with answer links if specified
        if (questionElement.answer.answerLinks && questionElement.answer.answerLinks.length > 0) {
          answerToDisplay = formatAnswerString(
            answerToDisplay,
            (text, url, key) => <LinkExternal displayText={text.toString()} href={url} key={key} />,
            questionElement.answer.answerLinks
          );
        }
      }
      return (
        <div className={props.classes.sectionRowQuestion} key={questionElement.id}>
          <Collapsible anchor={`${props.id}-${questionElement.id}`} text={questionElement.question}>
            <div>
              {answerToDisplay}
              {componentToDisplay}
            </div>
          </Collapsible>
        </div>
      );
    });
    return questionsToDisplay;
  };
  const questions = mapQuestions(props.questions);
  return (
    <section key="overview">
      {props.displayHeading !== false ? (
        <TextSectionHeading addClass={props.classes.sectionHeader}>
          {props.title}
        </TextSectionHeading>
      ) : null}
      {questions}
    </section>
  );
};

export const ExpandableList = withStyles(styles)(ExpandableListUnstyled) as React.FC<
  ExpandableListProps
>;
