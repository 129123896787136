import { CompoundButton, IButtonStyles } from 'office-ui-fabric-react';
import React from 'react';

/**
 * Fabric button that can take content throught children prop
 * {@link https://developer.microsoft.com/en-us/fabric#/controls/web/button Fabric Buttons documentation}
 */
export interface CompoundButtonAtomProps {
  className?: string;
  secondaryText?: string;
  styles?: IButtonStyles;
  dataAutomationId?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const CompoundButtonAtom: React.FC<CompoundButtonAtomProps> = props => {
  const { dataAutomationId } = props;
  // TODO jepagan : request Fabric to enable the user of classes to modify styles properties for icon
  const styles: Partial<IButtonStyles> = {
    iconHovered: { color: '' },
    iconPressed: { color: '' },
    ...props.styles,
  };
  return <CompoundButton data-automation-id={dataAutomationId} {...props} styles={styles} />;
};
