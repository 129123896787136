import { ActionType } from 'components/ions';
import { KeyCodes } from '@uifabric/utilities';

export const favoriteButtonKeyboardNavigation = (event: React.KeyboardEvent<ActionType>) => {
  const target = event.target as HTMLElement;
  const iconButtonContainer = target.parentElement as HTMLElement;
  const buttonsContainer = iconButtonContainer.parentElement as HTMLElement;
  const listContainer = buttonsContainer.parentElement as HTMLElement;

  switch (event.keyCode) {
    case KeyCodes.up:
      event.preventDefault();
      const previousSibling = buttonsContainer.previousElementSibling as HTMLElement;

      if (previousSibling) {
        const buttons = previousSibling.getElementsByTagName('button');
        buttons && buttons[0].focus();
      } else {
        const lastRootContainer = listContainer.lastElementChild as HTMLElement;
        const buttons = lastRootContainer.getElementsByTagName('button');
        buttons && buttons[0].focus();
      }
      break;
    case KeyCodes.down:
      const nextSibling = buttonsContainer.nextElementSibling as HTMLElement;
      if (nextSibling) {
        const buttons = nextSibling.getElementsByTagName('button');
        buttons && buttons[0].focus();
      } else {
        const firstRootContainer = listContainer.firstElementChild as HTMLElement;
        const buttons = firstRootContainer.getElementsByTagName('button');
        buttons && buttons[0].focus();
      }
      break;

    case KeyCodes.left:
    case KeyCodes.right:
      const buttons = listContainer.getElementsByTagName('button');
      buttons && buttons[0].focus();
      break;
  }
};

export const productButtonKeyboardNavigation = (event: React.KeyboardEvent<ActionType>) => {
  const target = event.target as HTMLElement;
  const parentElement = target.parentElement as HTMLElement;
  const rootContainer = parentElement.parentElement as HTMLElement;
  switch (event.keyCode) {
    case KeyCodes.up:
      event.preventDefault();
      let focusPreviousButton;
      if (rootContainer.className.includes('FinderListItems')) {
        // wrap around
        const lastFinderItem = rootContainer.lastElementChild as HTMLElement;
        const lastFinderItemButtons = lastFinderItem && lastFinderItem.getElementsByTagName('button');
        focusPreviousButton = lastFinderItemButtons && lastFinderItemButtons[0];
      } else {
        const parent = rootContainer.parentElement as HTMLElement;
        const previousFinderItem = parent && (parent.previousElementSibling as HTMLElement);
        if (previousFinderItem) {
          const previousFinderItemButtons = previousFinderItem && previousFinderItem.getElementsByTagName('button');
          focusPreviousButton = previousFinderItemButtons && previousFinderItemButtons[0];
        } else {
          // wrap around
          const finderList = parent && (parent.parentElement as HTMLElement);
          const lastFinderItem = finderList.lastElementChild as HTMLElement;
          const lastFinderItemButtons = lastFinderItem && lastFinderItem.getElementsByTagName('button');
          focusPreviousButton = lastFinderItemButtons && lastFinderItemButtons[0];
        }
      }
      focusPreviousButton && focusPreviousButton.focus();
      break;
    case KeyCodes.down:
      event.preventDefault();
      let focusNextButton;
      if (parentElement.className.includes('FinderListItem')) {
        const nextFinderListItem = parentElement.nextElementSibling as HTMLElement;
        const nextFinderListItemButtons = nextFinderListItem.getElementsByTagName('button');
        focusNextButton = nextFinderListItemButtons && nextFinderListItemButtons[0];
        // skip favorites menu button
        if (focusNextButton && focusNextButton.id.includes('favorite-button')) {
          const favoritesMenu = rootContainer.parentElement as HTMLElement;
          const finderItem = favoritesMenu && (favoritesMenu.nextElementSibling as HTMLElement);
          const finderButtons = finderItem && finderItem.getElementsByTagName('button');
          focusNextButton = finderButtons && finderButtons[0];
        }
      } else {
        const finderListItem = rootContainer && rootContainer.parentElement;
        const nextFinderListItem =
          finderListItem && (finderListItem.nextElementSibling as HTMLElement);
        if (nextFinderListItem) {
          const nextFinderListItemButtons = nextFinderListItem && nextFinderListItem.getElementsByTagName('button');
          focusNextButton = nextFinderListItemButtons && nextFinderListItemButtons[0];
        } else {
          // wrap around
          const finderList = finderListItem && (finderListItem.parentElement as HTMLElement);
          const firstFinderListItem = finderList && (finderList.firstElementChild as HTMLElement);
          const firstFinderListItemButtons = firstFinderListItem && firstFinderListItem.getElementsByTagName('button');
          focusNextButton = firstFinderListItemButtons && firstFinderListItemButtons[0];
        }
      }
      focusNextButton && focusNextButton.focus();
      break;

    case KeyCodes.left:
    case KeyCodes.right:
      event.preventDefault();
      const sibling = target.nextElementSibling as HTMLElement;
      if (sibling) {
        const buttons = sibling.getElementsByTagName('button');
        buttons && buttons[0] && buttons[0].focus();
      } else {
        const rootSibling = rootContainer && (rootContainer.nextElementSibling as HTMLElement);
        const buttons = rootSibling && rootSibling.getElementsByTagName('button');
        buttons && buttons[0] && buttons[0].focus();
      }
      break;
  }
};
