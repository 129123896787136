import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { TextBody, IconButton } from 'components';
import { IconNames } from 'icons/src';
import { finderErrorStyles } from './FinderError.styles';

export interface FinderErrorProps {
  setShowAgreementError: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = FinderErrorProps & WithStyles<typeof finderErrorStyles>;

const FinderErrorUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const titleText = t('quote::Error creating agreement');
  const bodyText = t(
    'quote::We were unable to create the agreement. Please retry or try to upload the ageement later.'
  );
  return (
    <div>
      <div className={props.classes.header}>
        <TextBody addClass={props.classes.title}>{titleText}</TextBody>
        <IconButton
          addClass={props.classes.cancel}
          ariaLabel="Close error message"
          iconName={IconNames.Cancel}
          onClick={() => props.setShowAgreementError(false)}
        />
      </div>
      <TextBody addClass={props.classes.body}>{bodyText}</TextBody>
    </div>
  );
};

export const FinderError = withStyles(finderErrorStyles)(FinderErrorUnstyled);
