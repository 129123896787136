import { User } from 'features/user/types';
import { combineReducers } from 'redux';
import { api } from 'services';
import { UserPermissions } from 'services/ccf/types';
import { User as GraphUser } from 'services/graph/types';
import { UserPreferences } from 'services/user-preferences/types';
import { ThemeKey } from 'styles';
import { oc } from 'ts-optchain';
import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './actions';

export const reducer = combineReducers({
  current: combineReducers<User>({
    name: createReducer<string, ActionType<typeof actions>>('').handleAction(
      actions.setUser,
      (state, action) => oc(action).payload.name() || state
    ),

    email: createReducer<string, ActionType<typeof actions>>('').handleAction(
      actions.setUser,
      (state, action) => oc(action).payload.email() || state
    ),

    tid: createReducer<string, ActionType<typeof actions>>('').handleAction(
      actions.setUser,
      (state, action) => action.payload.tid || state
    ),

    oid: createReducer<string, ActionType<typeof actions>>('').handleAction(
      actions.setUser,
      (state, action) => action.payload.oid || state
    ),

    photo: createReducer<string, ActionType<typeof actions>>('')
      .handleAction(actions.setUser, (state, action) => oc(action).payload.photo() || state)
      .handleAction(actions.loadPhotoAsync.success, (state, action) => action.payload || ''),

    permissions: createReducer<UserPermissions, ActionType<typeof actions>>({
      permissions: undefined,
      roles: [],
    })
      .handleAction(actions.loadUserPermissionsAsync.success, (state, action) => {
        let payload: UserPermissions = { ...action.payload };
        if (payload.permissions && state.permissions) {
          payload.permissions = payload.permissions.concat(state.permissions);
          return payload;
        } else if (state.permissions) {
          return state;
        }
        return action.payload;
      })
      .handleAction(actions.checkLDSSReadPermissionAsync.success, (state, action) => {
        const payload: UserPermissions = { ...state };
        const environmentConstraint = action.payload.environment;
        if (payload && payload.permissions && action.payload.hasPermission) {
          payload.permissions.push({ name: 'LDSSRead', constraints: [environmentConstraint] });
        } else if (action.payload.hasPermission) {
          payload.permissions = [{ name: 'LDSSRead', constraints: [environmentConstraint] }];
        }
        return payload;
      }),
  }),
  msContact: createReducer<Record<string, GraphUser>, ActionType<typeof actions>>({}).handleAction(
    actions.loadMSContactAsync.success,
    (state, action) => {
      if (action.payload && action.payload.length) {
        return {
          ...state,
          [action.payload && action.payload[0].userPrincipalName]: action.payload[0],
        };
      }
      return state;
    }
  ),
  msContactResults: createReducer<GraphUser[], ActionType<typeof actions>>([])
    .handleAction(actions.searchMSContactsAsync.success, (state, action) => action.payload)
    .handleAction(actions.clearSearchMsContacts, () => []),
  roles: createReducer<Record<string, string[]>, ActionType<typeof actions>>({}).handleAction(
    actions.loadUserRoles.success,
    (state, action) => {
      return {
        ...state,
        [action.payload.email]: action.payload.roles,
      };
    }
  ),
  preferences: createReducer<UserPreferences, ActionType<typeof actions>>(
    api.userPreferences.getLocalPreferences()
  )
    .handleAction(
      [
        actions.updateUserPreferencesAsync.request,
        actions.updateUserPreferencesAsync.success,
        actions.loadUserPreferencesAsync.success,
      ],
      (state, action) => (state ? { ...state, ...action.payload } : action.payload)
    )
    .handleAction(actions.deleteUserPreferencesAsync.success, () => {
      return {
        eTag: '',
        theme: ThemeKey.light,
        viewedInfoDialogs: [],
      };
    }),
});

export default reducer;
