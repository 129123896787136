import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { TextAtom } from '../Text';
import { pivotItemAtomStyles } from './PivotItemAtom.styles';
import { IconAtom } from '../Icons';

/**
 * Props for the Pivot Item component
 *
 * @prop {string} id - identifies the button
 * @prop {string} label - text of button
 * @prop {dispatch} setBarPosition - updates position of the bar
 * @prop {function} onSelect - update bar and item states based on selected button
 * @prop {string} [ariaLabel] - description of button's action
 * @prop {ClassNameMap} [classes] - styles use in the component
 * @prop {boolean} [currentlySelected] - identifies if the item is selected
 * @prop {boolean} [required] - postfix a red asterisk to the button's text
 * @prop {string} [dataAutomationId] - tag for automation tetsing
 * @prop {boolen} [itemRequiredFlags] - array containing the required flags for all items
 */

export interface PivotItemAtomProps {
  id: string;
  label: string;
  setBarPosition(position: { x: number; width: number }): void;
  onSelect(itemId: string): void;
  ariaLabel?: string;
  currentlySelected?: boolean;
  required?: boolean;
  dataAutomationId?: string;
  itemRequiredFlags?: boolean[];
  subtitle?: string;
  subtitleIconName?: string;
}

type Props = PivotItemAtomProps & WithStyles<typeof pivotItemAtomStyles>;

const PivotItem: React.FC<Props> = ({ setBarPosition, onSelect, ...props }: Props) => {
  // Hooks
  const buttonRef: React.RefObject<HTMLButtonElement> = React.useRef(null);

  React.useEffect(() => {
    if (buttonRef.current) {
      const { x, width, left } = buttonRef.current.getBoundingClientRect() as DOMRect;
      const edgeSafeX = x !== undefined ? x : left;
      if (props.currentlySelected) {
        setBarPosition({ x: edgeSafeX, width });
      }
    }
  }, [props.currentlySelected, props.itemRequiredFlags, setBarPosition]);

  // Handlers
  const handleSelect = () => {
    onSelect(props.id);
  };

  return (
    <div className={props.classes.buttonContainer}>
      <button
        aria-label={props.ariaLabel}
        className={props.classes.button}
        data-automation-id={props.dataAutomationId}
        id={props.id}
        ref={buttonRef}
        type="button"
        onClick={handleSelect}
      >
        <TextAtom
          addClass={`
        ${props.classes.text} 
        ${props.currentlySelected && props.classes.selected}`}
        >
          {props.label}
          {props.subtitle && (
            <span className={props.classes.subtitle}>
              -{props.subtitleIconName && <IconAtom iconName={props.subtitleIconName} />}
              <span className={props.classes.subtitleText}>{props.subtitle}</span>
            </span>
          )}
          {props.required && <span className={props.classes.required}>*</span>}
        </TextAtom>
      </button>
    </div>
  );
};

export const PivotItemAtom = withStyles(pivotItemAtomStyles)(PivotItem) as React.FC<
  PivotItemAtomProps
>;
