import { TextBody, TextBodySmall } from 'components/ions';
import { Persona } from 'components/ions/Persona';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { Suggestion } from '../Autosuggest';
import { contactSuggestionRowStyles } from './ContactSuggestionRow.styles';

export interface ContactSuggestion {
  text: string;
  secondaryText?: string;
}

export interface ContactSuggestionRowProps {
  suggestion: Suggestion<ContactSuggestion>;
}

type Props = ContactSuggestionRowProps & WithStyles<typeof contactSuggestionRowStyles>;

export const ContactSuggestionRowUnstyled: React.FC<Props> = props => {
  return (
    <div className={props.classes.container}>
      <div className={props.classes.leftPart}>
        <Persona addClass={props.classes.persona} />
      </div>
      <div className={props.classes.rightPart}>
        <TextBody addClass={props.classes.primaryText}>{props.suggestion.value.text}</TextBody>
        <TextBodySmall
          addClass={props.classes.secondaryText}
          title={props.suggestion.value.secondaryText}
        >
          {props.suggestion.value.secondaryText}
        </TextBodySmall>
      </div>
    </div>
  );
};

export const ContactSuggestionRow = withStyles(contactSuggestionRowStyles)(
  ContactSuggestionRowUnstyled
) as React.FC<ContactSuggestionRowProps>;
