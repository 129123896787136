import { GqlLanguage } from 'generated/graphql';
import { Language } from 'features/proposal/supported-languages';

export const getGqlLanguagefromLanguage = (language: string | Language): GqlLanguage => {
  return language.toString().replace('-', '_') as GqlLanguage;
};

export const getLanguagefromGqlLanguage = (language: string | GqlLanguage): Language => {
  return language.toString().replace('_', '-') as Language;
};
