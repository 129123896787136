import { TextTitleSecondary } from 'components';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const keyBoxStyles = (theme: ThemeProps) => ({
  text: {
    fontWeight: theme.fonts.fontWeights.bold,
    padding: '3px 10px 6px 10px',
    textAlign: 'center',
  },
  keyBox: {
    backgroundColor: theme.palette.backgroundCommon,
    border: `1px solid ${theme.palette.backgroundDivider}`,
    borderRadius: 4,
    boxShadow: `0px 1px 2px ${theme.palette.shadowColor}`,
    height: 30,
    marginLeft: 4,
    width: 30,
  },
  keyBoxLong: {
    width: 48,
  },
});

export interface KeyBoxProps {
  displayText: string;
}

type Props = KeyBoxProps & WithStyles<typeof keyBoxStyles>;

export const KeyBoxUnstyled: React.FC<Props> = props => {
  const keyBoxStyle =
    props.displayText.length > 1
      ? mergeClassNames([props.classes.keyBox, props.classes.keyBoxLong])
      : props.classes.keyBox;
  return (
    <div className={keyBoxStyle}>
      <TextTitleSecondary
        addClass={props.classes.text}
        dataAutomationId={`keybox-${props.displayText}`}
      >
        {props.displayText}
      </TextTitleSecondary>
    </div>
  );
};

export const KeyBox = withStyles(keyBoxStyles)(KeyBoxUnstyled);
