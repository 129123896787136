import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

const styles = {
  div: {
    maxWidth: 0,
    opacity: 0,
    overflow: 'hidden',
    transition: 'all 600ms',
    whiteSpace: 'nowrap',
    '&.has-content': {
      maxWidth: 2000,
      opacity: 100,
    },
  },
};

export interface AnimateInProps {
  id?: string;
  classOverride?: string;
  hasContent: boolean;
}

type Props = AnimateInProps & WithStyles<typeof styles>;

export const AnimateInUnstyled: React.FC<Props> = ({
  id,
  hasContent,
  classes,
  children,
  classOverride,
}) => {
  const mainClass = classOverride ? classOverride : classes.div;
  return (
    <span className={`${mainClass} ${hasContent ? 'has-content' : ''}`} id={id}>
      {children}
    </span>
  );
};

export const AnimateIn = withStyles(styles)(AnimateInUnstyled);
