import { Error } from 'features/app/types';
import { ApprovalListSearchParams, EnrollmentFieldView, OpenCard } from 'features/proposal/types';
import {
  Approval,
  ApprovalAction,
  ApprovalActionRequest,
  ApprovalSummary,
} from 'services/approval/types';
import { CreditInfo, CreditLookupRequest, CreditRequest } from 'services/credit/types';
import {
  EmpowermentFilter,
  EmpowermentsSuccess,
  EmpowermentValidationRequest,
  EmpowermentValidationResult,
} from 'services/empowerment/types';
import { ExternalUser } from 'services/externaluser/types';
import { Notification } from 'services/notification/types';
import {
  CreateLineItemGroupRequest,
  CreateLineItemsRequest,
  DeleteLineItemRequest,
  DeleteLineItemsRequest,
  MultipleProposalActionRequest,
  Proposal,
  ProposalActionRequest,
  ProposalDeleteRequest,
  ProposalDeleteSuccess,
  ProposalHeaderPatchRequest,
  ProposalPricingRequest,
  ProposalSummary,
  ProposalUpdateRequest,
  RemoveLineItemGroupRequest,
  RequestProposal,
  SubmittingProposal,
  UpdateAndCreateLineItemsRequest,
} from 'services/proposal/types';
import { CreatePurchaseRecordRequest, PurchaseRecordSummary } from 'services/purchase/types';
import { createAsyncAction } from 'store/utils';
import { createStandardAction } from 'typesafe-actions';

import { ProductAndQualifyingSkusTitles } from './components';
import { AnnualDealEstimate, RequestCreditLine } from './components/Dialogs/CreditDialog';
import { Currency } from './supported-currencies';
import { Language } from './supported-languages';
import { Market } from './supported-markets';
import {
  BulkDiscountApplyRequest,
  DiscountApplyRequest,
  ModernFootprint,
  PriceMap,
  SummaryListSearchParams,
} from './types';

export const addNewCrmId = {
  request: createStandardAction('@@CRM/ADD_NEW/LOAD_REQUEST')<{
    proposalId: string;
    crmId: string;
  }>(),
  success: createStandardAction('@@CRM/ADD_NEW/LOAD_SUCCESS')<{
    proposalId: string;
    crmId: string;
  }>(),
  failure: createStandardAction('@@CRM/ADD_NEW/LOAD_FAILURE')<Error>(),
};

export const verifyCrmId = {
  request: createStandardAction('@@CRM/CRMID/VERIFY_REQUEST')<string>(),
  success: createStandardAction('@@CRM/CRMID/VERIFY_SUCCESS')<string>(),
  failure: createStandardAction('@@CRM/CRMID/VERIFY_FAILURE')<Error>(),
};

export const upgradeQuoteAsync = createAsyncAction(
  '@@quote/UPGRADE_REQUEST',
  '@@quote/UPGRADE_SUCCESS',
  '@@quote/UPGRADE_FAILURE'
)<string, Proposal, Error>();

export const clearActiveQuote = createStandardAction('@@quote/active/clear')<void>();

export const setQuoteSearchTerm = createStandardAction('@@proposal/SET_QUOTE_SEARCH_TERM')<
  string
>();

export const searchProposalFragmentsAsync = createAsyncAction(
  '@@proposalFragments/SEARCH_REQUEST',
  '@@proposalFragments/SEARCH_SUCCESS',
  '@@proposalFragments/SEARCH_FAILURE'
)<SummaryListSearchParams, ProposalSummary[], Error>();

export const loadProposalFragmentAsync = createAsyncAction(
  '@@proposalFragment/LOAD_REQUEST',
  '@@proposalFragment/LOAD_SUCCESS',
  '@@proposalFragment/LOAD_FAILURE'
)<string, Proposal, Error>();

export const loadProposalAsync = {
  request: createStandardAction('@@proposal/LOAD_REQUEST')<string>(),
  success: createStandardAction('@@proposal/LOAD_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@proposal/LOAD_FAILURE')<Error>(),
};
export const loadAndHydrateAllQuoteWithFavorites = {
  request: createStandardAction('@@quote/loadAndHydrateAllQuoteWithFavorites/LOAD_REQUEST')(),
  success: createStandardAction('@@quote/loadAndHydrateAllQuoteWithFavorites/LOAD_SUCCESS')(),
};
export const loadAndHydrateAllQuote = createStandardAction('@@quote/loadAndHydrate/all')<string>();
export const loadAndHydrateLiteQuote = createStandardAction('@@quote/loadAndHydrate/lite')<
  string
>();

export const hydrateQuoteAll = {
  request: createStandardAction('@@quote/hydrateAll/LOAD_REQUEST')<string>(),
  success: createStandardAction('@@quote/hydrateAll/LOAD_SUCCESS')<string>(),
  failure: createStandardAction('@@quote/hydrateAll/LOAD_FAILURE')<Error>(),
};

export const hydrateQuoteLite = {
  request: createStandardAction('@@quote/hydrateLite/LOAD_REQUEST')<string>(),
  success: createStandardAction('@@quote/hydrateLite/LOAD_SUCCESS')<string>(),
  failure: createStandardAction('@@quote/hydrateLite/LOAD_FAILURE')<Error>(),
};

export const createProposalAsync = createAsyncAction(
  '@@proposal/CREATE_REQUEST',
  '@@proposal/CREATE_SUCCESS',
  '@@proposal/CREATE_FAILURE'
)<RequestProposal, Proposal, Error>();

export const updateQuoteAsync = {
  request: createStandardAction('@@quote/UPDATE_REQUEST')<ProposalUpdateRequest>(),
  success: createStandardAction('@@quote/UPDATE_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@quote/UPDATE_FAILURE')<Error>(),
};

export const updateProposalAsync = {
  request: createStandardAction('@@proposal/UPDATE_REQUEST')<ProposalUpdateRequest>(),
  success: createStandardAction('@@proposal/UPDATE_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@proposal/UPDATE_FAILURE')<Error>(),
};

export const loadEnrollmentAndUpdateProposal = createStandardAction(
  '@@proposal/LOAD_ENROLLMENT_AND_UPDATE_PROPOSAL'
)<string>();

export const patchQuoteHeaderAsync = createAsyncAction(
  '@@quote/header/PATCH_REQUEST',
  '@@quote/header/PATCH_SUCCESS',
  '@@quote/header/PATCH_FAILURE'
)<ProposalHeaderPatchRequest, Proposal, Error>();

export const patchProposalHeaderAsync = createAsyncAction(
  '@@proposal/header/PATCH_REQUEST',
  '@@proposal/header/PATCH_SUCCESS',
  '@@proposal/header/PATCH_FAILURE'
)<ProposalHeaderPatchRequest, Proposal, Error>();

export const deleteProposalAsync = createAsyncAction(
  '@@proposal/DELETE_REQUEST',
  '@@proposal/DELETE_SUCCESS',
  '@@proposal/DELETE_FAILURE'
)<ProposalDeleteRequest, ProposalDeleteSuccess, Error>();

export const loadPricesAsync = createAsyncAction(
  '@@proposal/prices/LOAD_REQUEST',
  '@@proposal/prices/LOAD_SUCCESS',
  '@@proposal/prices/LOAD_FAILURE'
)<ProposalPricingRequest, PriceMap, Error>();

export const createLineItemsAsync = {
  //for the service call
  request: createStandardAction('@@quote/line_items/CREATE_REQUEST')<CreateLineItemsRequest>(),
  success: createStandardAction('@@quote/line_items/CREATE_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@quote/line_items/CREATE_FAILURE')<Error>(),
};

export const deleteLineItemAsync = {
  //for the service call
  request: createStandardAction('@@quote/line_item/DELETE_REQUEST')<DeleteLineItemRequest>(),
  success: createStandardAction('@@quote/line_item/DELETE_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@quote/line_item/DELETE_FAILURE')<Error>(),
};

export const deleteLineItemsAsync = {
  request: createStandardAction('@@quote/line_items/DELETE_REQUEST')<DeleteLineItemsRequest>(),
  success: createStandardAction('@@quote/line_items/DELETE_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@quote/line_items/DELETE_FAILURE')<Error>(),
};

export const createProposalLineItemsAsync = {
  //for the generic create line item path
  request: createStandardAction('@@proposal/line_items/CREATE_REQUEST')<CreateLineItemsRequest>(),
  success: createStandardAction('@@proposal/line_items/CREATE_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@proposal/line_items/CREATE_FAILURE')<Error>(),
};

export const updateAndCreateProposalLineItems = {
  request: createStandardAction('@@proposal/line_items/UPDATE_AND_CREATE_REQUEST')<
    UpdateAndCreateLineItemsRequest
  >(),
  success: createStandardAction('@@proposal/line_items/UPDATE_AND_CREATE_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@proposal/line_items/UPDATE_AND_CREATE_FAILURE')<Error>(),
};

export const updateAddedLineItemCount = createAsyncAction(
  '@@line_items/UPDATE_COUNT_REQUEST',
  '@@line_items/UPDATE_COUNT_SUCCESS',
  '@@line_items/UPDATE_COUNT_FAILURE'
)<number, number, Error>();

export const deleteProposalLineItemsAsync = {
  request: createStandardAction('@@proposal/line_items/DELETE_REQUEST')<DeleteLineItemsRequest>(),
  success: createStandardAction('@@proposal/line_items/DELETE_SUCCESS')<Proposal>(),
  failure: createStandardAction('@@proposal/line_items/DELETE_FAILURE')<Error>(),
};

export const performMultipleProposalActionAsync = createAsyncAction(
  '@@proposal/MULTIPLE_ACTION_REQUEST',
  '@@proposal/MULTIPLE_ACTION_SUCCESS',
  '@@proposal/MULTIPLE_ACTION_FAILURE'
)<MultipleProposalActionRequest, Proposal, Error>();

export const performProposalActionAsync = createAsyncAction(
  '@@proposal/ACTION_REQUEST',
  '@@proposal/ACTION_SUCCESS',
  '@@proposal/ACTION_FAILURE'
)<ProposalActionRequest, Proposal, Error>();

export const loadApprovalAsync = createAsyncAction(
  '@@approval/LOAD_REQUEST',
  '@@approval/LOAD_SUCCESS',
  '@@approval/LOAD_FAILURE'
)<string, Approval, Error>();

export const searchApprovalsAsync = createAsyncAction(
  '@@approvals/SEARCH_REQUEST',
  '@@approvals/SEARCH_SUCCESS',
  '@@approvals/SEARCH_FAILURE'
)<ApprovalListSearchParams, ApprovalSummary[], Error>();

export const performApprovalActionAsync = createAsyncAction(
  '@@approval/ACTION_REQUEST',
  '@@approval/ACTION_SUCCESS',
  '@@approval/ACTION_FAILURE'
)<ApprovalActionRequest, ApprovalAction, Error>();

export const loadEmpowermentsAsync = createAsyncAction(
  '@@empowerments/LOAD_REQUEST',
  '@@empowerments/LOAD_SUCCESS',
  '@@empowerments/LOAD_FAILURE'
)<EmpowermentFilter, EmpowermentsSuccess, Error>();

export const validateEmpowermentAsync = createAsyncAction(
  '@@empowerment/VALIDATE_REQUEST',
  '@@empowerment/VALIDATE_SUCCESS',
  '@@empowerment/VALIDATE_FAILURE'
)<EmpowermentValidationRequest, EmpowermentValidationResult, Error>();

export const createPurchaseRecordAsync = createAsyncAction(
  '@@purchase/CREATE_REQUEST',
  '@@purchase/CREATE_SUCCESS',
  '@@purchase/CREATE_FAILURE'
)<CreatePurchaseRecordRequest, boolean, Error>();

export const loadPurchaseRecordAsync = createAsyncAction(
  '@@purchase/LOAD_REQUEST',
  '@@purchase/LOAD_SUCCESS',
  '@@purchase/LOAD_FAILURE'
)<string, PurchaseRecordSummary, Error>();

export const createNotificationAsync = createAsyncAction(
  '@@notification/CREATE_REQUEST',
  '@@notification/CREATE_SUCCESS',
  '@@notification/CREATE_FAILURE'
)<Notification, boolean, Error>();

export const withdrawQuote = createStandardAction('@@quote/withdraw')<string>();
export const submitProposalActionAsync = createAsyncAction(
  '@@proposal/SUBMIT_REQUEST',
  '@@proposal/SUBMIT_SUCCESS',
  '@@proposal/SUBMIT_FAILURE'
)<SubmittingProposal, Proposal, Error>();

export const publishQuoteGQLSuccess = createStandardAction('@@proposal/PUBLISH_GQL_SUCCESS')<
  Proposal
>();

export const withdrawQuoteGQLSuccess = createStandardAction('@@proposal/WITHDRAW_GQL_SUCCESS')<
  string
>();

export const refreshApproval = createStandardAction('@@approval/REFRESH')<string>();

export const convertToLegacyAsync = createAsyncAction(
  '@@proposal/legacy/MAP_REQUEST',
  '@@proposal/legacy/MAP_SUCCESS',
  '@@proposal/legacy/MAP_FAILURE'
)<string, void, Error>();

export const updateProposalMarket = createStandardAction('@@proposal/market/UPDATE')<
  Market,
  Proposal
>();
export const updateProposalCurrency = createStandardAction('@@proposal/currency/UPDATE')<
  Currency,
  Proposal
>();
export const updateProposalLanguage = createStandardAction('@@proposal/language/UPDATE')<
  Language,
  Proposal
>();

export const updateInvitedUser = createStandardAction('@@quote/updateInvitedUser')<{
  user: ExternalUser;
  quoteId: string;
}>();

export const updateMsContact = createStandardAction('@@quote/updateMsContact')<{
  msContact: string;
  quoteId: string;
}>();

export const bulkDiscountApply = createStandardAction('@@proposal/BULK_DISCOUNT_APPLY')<
  BulkDiscountApplyRequest
>();

export const discountApply = createStandardAction('@@proposal/DISCOUNT_APPLY')<
  DiscountApplyRequest
>();

export const bulkUpdateDiscountOnProposalWithDFD = createStandardAction(
  '@@proposal/BULK_UPDATE_DISCOUNT_ON_PROPOSAL_WITH_DFD'
)<BulkDiscountApplyRequest>();

export const updateDiscountOnProposalWithDFD = createStandardAction(
  '@@proposal/UPDATE_DISCOUNT_ON_PROPOSAL_WITH_DFD'
)<DiscountApplyRequest>();

export const mapProposalWithModernFootprint = {
  request: createStandardAction('@@proposal/modernFootprint/MAP_REQUEST')<
    ModernFootprint,
    { proposalId: string; crmId: string }
  >(),
  success: createStandardAction('@@proposal/modernFootprint/MAP_SUCCESS')(),
  failure: createStandardAction('@@proposal/modernFootprint/MAP_FAILURE')<Error>(),
};

export const removeCRMId = createStandardAction('@@proposal/CRMId/DELETE')<Proposal>();

export const addProposalSection = createStandardAction('@@proposal/ADD_SECTION')<string>();
export const removeProposalSection = createStandardAction('@@proposal/REMOVE_SECTION')<string>();
export const clearProposal = createStandardAction('@@proposal/CLEAR')();

export const resetNewProposalDialog = createStandardAction('@@proposal/NEW_DIALOG_RESET')();
export const resetDeleteProposalDialog = createStandardAction('@@proposal/DELETE_DIALOG_RESET')();

export const addSelected = createStandardAction('@@proposal/ADD_SELECTED')<string[]>();
export const FAKE_ACTION = createStandardAction('@@proposal/FAKE_ACTION')<string>();

export const toggleItemSelection = createStandardAction('@@proposal/TOGGLE_ITEM_SELECTION')<{
  multi: boolean;
  ids: string[];
  lastSelectedId?: string;
}>();

export const clearItemSelection = createStandardAction('@@proposal/CLEAR_ITEM_SELECTION')();
export const openConfigCard = createStandardAction('@@proposal/OPEN_CONFIG_CARD')<OpenCard>();
export const setConfigCardOpenGQL = createStandardAction('@@proposal/SET_CONFIG_CARD_OPEN_GQL')<
  boolean
>();

export const closeConfigCard = createStandardAction('@@proposal/CLOSE_CONFIG_CARD')();
export const setEnrollmentFieldView = createStandardAction('@@proposal/SET_ENROLLMENT_FIELD_VIEW')<
  EnrollmentFieldView
>();

export const clearSendingEmail = createStandardAction('@@notification/CLEAR')();
export const clearTransactingProposal = createStandardAction('@@transact/CLEAR')();
export const loadMissingEmpowerments = createStandardAction('@@empowerments/LOAD_MISSING')<
  string //quoteId
>();

// #region 'Credit Action'
export const requestCreditLineAsync = createAsyncAction(
  '@@credit/REQUEST_CREDIT_LINE_REQUEST',
  '@@credit/REQUEST_CREDIT_LINE_SUCCESS',
  '@@credit/REQUEST_CREDIT_LINE_FAILURE'
)<RequestCreditLine, { creditInfo: CreditInfo; currency: string }, Error>();

export const loadCreditInfoAsync = createAsyncAction(
  '@@credit/LOAD_REQUEST',
  '@@credit/LOAD_SUCCESS',
  '@@credit/LOAD_FAILURE'
)<CreditLookupRequest, { creditInfo: CreditInfo; currency: string }, Error>();

export const requestCreditIncreaseAsync = createAsyncAction(
  '@@credit/UPDATE_REQUEST',
  '@@credit/UPDATE_SUCCESS',
  '@@credit/UPDATE_FAILURE'
)<CreditRequest, { creditInfo: CreditInfo; currency: string }, Error>();
// #endregion

export const updateAnnualDealEstimate = createStandardAction(
  '@@proposal/AnnualDealEstimate/UPDATE'
)<AnnualDealEstimate, Proposal>();

export const addSkuTitle = createStandardAction('@@proposal/ADD_SKU_TITLES')<
  ProductAndQualifyingSkusTitles
>();

export const hydrateProposalProducts = createStandardAction('@@proposal/LOAD_PRODUCTS')<Proposal>();

export const createLineItemGroupingAsync = createAsyncAction(
  '@@proposal/line_items/CREATEANDUPDATE_REQUEST',
  '@@proposal/line_items/CREATEANDUPDATE_SUCCESS',
  '@@proposal/line_items/CREATEANDUPDATE_FAILURE'
)<CreateLineItemGroupRequest, Proposal, Error>();

export const removeLineItemGroupingAsync = createAsyncAction(
  '@@proposal/line_items/REMOVEANDUPDATE_REQUEST',
  '@@proposal/line_items/REMOVEANDUPDATE_SUCCESS',
  '@@proposal/line_items/REMOVEANDUPDATE_FAILURE'
)<RemoveLineItemGroupRequest, Proposal, Error>();

export const selectOrganizationAsync = createAsyncAction(
  '@@proposal/SELECT_ORGANIZATION_REQUEST',
  '@@proposal/SELECT_ORGANIZATION_SUCCESS',
  '@@proposal/SELECT_ORGANIZATION_FAILURE'
)<{ organizationId: string; accountId: string }, undefined, Error>();

export const loadSharedDiscountProducts = createStandardAction(
  '@@proposal/LOAD_SHARED_DISCOUNT_PRODUCTS'
)<string[]>();

export const loadDFDProduct = createStandardAction('@@proposal/LOAD_DFD_PRODUCT')();
export const loadAssetProducts = createStandardAction('@@proposal/LOAD_ASSET_PRODUCTS')<string[]>();
