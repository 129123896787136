import { SecondaryButton } from 'components/ions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import { TransactDialog } from './TransactDialog';

interface TransactButtonProps {
  disabled: boolean;
}

export const TransactButton: React.FC<TransactButtonProps> = props => {
  const { disabled } = props;

  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const dialogProps: DialogProps = {
    providedDialog: <TransactDialog />,
  };

  const openDialog = () => {
    context.openDialog(dialogProps);
  };

  return (
    <SecondaryButton
      dataAutomationId="transactButton"
      disabled={disabled}
      iconName="PublishContent"
      id="transact-button"
      text={t('quote::Transact')}
      onClick={openDialog}
    />
  );
};
