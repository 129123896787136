export const currencyMapStyles = {
  currencies: {
    display: 'grid',
    gridColumnGap: '60px',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'start',
  },
  innerCurrencies: {
    display: 'grid',
    gridTemplateColumns: '30px 30px auto',
    justifyContent: 'start',
  },
};
