import {
  CustomDialogBox,
  DetailsList,
  DialogHeader,
  Pivot,
  PrimaryButton,
  SecondaryButton,
  SectionSeparator,
  TeachingBubble,
  TextBody,
  TextBodySmall,
  TextTitle,
} from 'components';
import { PivotAtomProps } from 'components/atoms';
import { getProductFragmentsIndexed } from 'features/catalog/selectors';
import { getAssetsByAccountId } from 'features/customer/selectors';
import { createProposalLineItemsAsync, loadAssetProducts } from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import { createAssetLineItem } from 'features/proposal/utils';
import { useFabricSelection } from 'hooks/useFabricSelection';
import { PivotContainer } from 'layouts';
import { CheckboxVisibility, DirectionalHint, SelectionMode } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Asset, AssetListItem } from 'services/edge/types';
import { endpoints as proposalServiceEndpoints } from 'services/proposal/config';
import { CreateLineItemsRequest } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { DialogLayout } from '../Shared';
import { ConvertActionableAssetDialog } from './ConvertActionableAssetDialog';
import { ConvertAssetConversionDialog } from './ConvertAssetConversionDialog';
import { convertAssetDialogStyles } from './ConvertAssetDialog.styles';
import { ConvertAssetSeatsDialog } from './ConvertAssetSeatsDialog';
import { buildAssetsList, buildColumns } from './utils';

export enum DialogState {
  Initial,
  Actionable,
  Convert,
  AddSeats,
}

export enum PivotState {
  modifiable = 'modifiable',
  static = 'static',
}

const mapStateToProps = (state: RootState) => {
  const proposal = selectors.getActiveProposal(state);
  const accountId = selectors.getAccountId(state, proposal);

  return {
    proposal,
    products: getProductFragmentsIndexed(state),
    assets: accountId ? getAssetsByAccountId(state, accountId) : undefined,
    proposalServiceEndpoint: proposalServiceEndpoints[state.app.appConfig.proposal.environment],
    selectedProject: selectors.getSelectedProject(state),
  };
};

const dispatchProps = {
  loadAssetProducts: (request: string[]) => loadAssetProducts(request),
  addLineItem: (request: CreateLineItemsRequest) => createProposalLineItemsAsync.request(request),
};

type Props = WithStyles<typeof convertAssetDialogStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const ConvertAssetDialogUnstyled: React.FC<Props> = props => {
  const {
    classes,
    assets,
    loadAssetProducts,
    products,
    proposal,
    addLineItem,
    proposalServiceEndpoint,
    selectedProject,
  } = props;
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const [selectedPivot, setSelectedPivot] = React.useState(PivotState.modifiable);
  const [dialogState, setDialogState] = React.useState(DialogState.Initial);
  const [isSelectedAssetProduct, setSelectedAssetProduct] = React.useState(false);
  const [isInfoBubbleOpen, setIsInfoBubbleOpen] = React.useState(false);
  const [isAddSeatsEnabled, setIsAddSeatsEnabled] = React.useState(false);
  const [seatsQuantity, setSeatsQuantity] = React.useState<number>(0);
  const isStatic = selectedPivot === PivotState.static;

  const openInfoBuble = (element: HTMLElement) => {
    if (element.classList.contains(classes.assetInfo)) {
      setIsInfoBubbleOpen(true);
    }
  };

  const closeInfoBuble = () => {
    setIsInfoBubbleOpen(false);
  };

  const infoBubble = (
    <TeachingBubble
      addClassName={classes.marginRight15}
      closeButtonAriaLabel={t('quote::Close')}
      directionalHint={DirectionalHint.bottomLeftEdge}
      headline={t('quote::Static assets')}
      maxWidth={285}
      target={`.${classes.assetInfo}`}
      onDismiss={closeInfoBuble}
    >
      <TextBody>
        {t(
          'quote::The list of assets presented here are not modifiable. The reasons for their static nature are presented on the right-hand side to provide context for you.'
        )}
      </TextBody>
    </TeachingBubble>
  );

  const onListSelection = () => {
    setDialogState(DialogState.Actionable);
  };

  const assetList = buildAssetsList(isStatic, assets, products);
  const columns = buildColumns(isStatic, classes, openInfoBuble);
  const selection = useFabricSelection(onListSelection);
  const selectedListItem = selection.getSelection() as AssetListItem[];

  React.useEffect(() => {
    if (assets) {
      const list = assets.map(asset => asset.assetData.productInfo.productId);
      loadAssetProducts(list);
    }
  }, [assets, loadAssetProducts]);

  const closeDialog = () => {
    context.closeDialog();
  };

  const handleBackClick = () => {
    selection.setItems(assetList, true);
    setDialogState(DialogState.Initial);
  };

  const handleBackConvertClick = () => {
    setSelectedAssetProduct(false);
    setDialogState(DialogState.Actionable);
  };

  const pivotSelected = (id: PivotState) => {
    setSelectedPivot(id);
    return id;
  };

  const convertAsset = () => {
    setDialogState(DialogState.Convert);
  };

  const goToAddSeats = () => {
    setDialogState(DialogState.AddSeats);
  };

  const addAssetToQuote = () => {
    //TODO kaderbez: add create logic
    if (dialogState === DialogState.Convert) {
      // convert line item
    } else if (dialogState === DialogState.AddSeats) {
      //add seats
      const selectedAsset =
        assets && assets.find((asset: Asset) => asset.id === selectedListItem[0].key);
      selectedAsset &&
        createAssetLineItem(
          proposal,
          proposalServiceEndpoint,
          selectedAsset,
          seatsQuantity,
          addLineItem,
          selectedProject
        );
    }
    closeDialog();
  };

  const getBodyContent = () => {
    return (
      <div className={classes.convertDialogBody}>
        <div className={!isStatic ? classes.detailsModifiableList : classes.detailsStaticList}>
          <DetailsList
            checkboxVisibility={CheckboxVisibility.hidden}
            columns={columns}
            compact={true}
            isHeaderVisible={true}
            items={assetList}
            selection={selection}
            selectionMode={SelectionMode.single}
            shouldResizeAfterFirstRender={false}
          />
        </div>
        <SectionSeparator />
      </div>
    );
  };

  const pivProps: PivotAtomProps = {
    items: [
      {
        id: PivotState.modifiable,
        text: t('quote::Modifiable assets'),
        dataAutomationId: 'modifiableTab',
      },
      { id: PivotState.static, text: t('quote::Static assets'), dataAutomationId: 'staticTab' },
    ],
    onSelectItem: pivotSelected,
    defaultItemId: selectedPivot,
  };

  const headerLayout = (
    <DialogHeader
      closeButtonClass={classes.customDialogCloseButton}
      dataAutomationId="convertAssets"
      dialogClose={closeDialog}
      headerClass={classes.convertDialogHeader}
    >
      <TextBodySmall>{t('quote::Asset management')}</TextBodySmall>
    </DialogHeader>
  );

  const actionableLayout: DialogLayout = {
    header: (
      <div>
        {headerLayout}
        <TextTitle addClass={classes.convertDialogTitleHeader}>
          {t('quote::Available actions')}
        </TextTitle>
      </div>
    ),
    body: (
      <div>
        <ConvertActionableAssetDialog
          convertAsset={convertAsset}
          goToAddSeats={goToAddSeats}
          selectedAssetListItem={selectedListItem && selectedListItem[0]}
        />
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <SecondaryButton
          dataAutomationId="convertActionsBack"
          id="convertActionsBack"
          text={t('quote::Back')}
          onClick={handleBackClick}
        />
      </div>
    ),
  };

  const convertLayout: DialogLayout = {
    header: (
      <div>
        {headerLayout}
        <TextTitle addClass={classes.convertDialogTitleHeader}>
          {t('quote::Select product conversion')}
        </TextTitle>
      </div>
    ),
    body: (
      <div>
        <ConvertAssetConversionDialog
          selectedAssetListItem={selectedListItem && selectedListItem[0]}
          setSelectedAssetProduct={() => setSelectedAssetProduct(true)}
        />
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <SecondaryButton
          dataAutomationId="convertAssetsBack"
          id="convertAssetsBack"
          text={t('quote::Back')}
          onClick={handleBackConvertClick}
        />
        <PrimaryButton
          dataAutomationId="convertAssetsAddSeats"
          disabled={!isSelectedAssetProduct}
          id="convertAssetsAddSeats"
          text={t('quote::Add to quote')}
          onClick={addAssetToQuote}
        />
      </div>
    ),
  };

  const addSeatsLayout: DialogLayout = {
    header: (
      <div>
        {headerLayout}
        <TextTitle addClass={classes.convertDialogTitleHeader}>{t('quote::Add seats')}</TextTitle>
      </div>
    ),
    body: (
      <div>
        <ConvertAssetSeatsDialog
          selectedAssetListItem={selectedListItem && selectedListItem[0]}
          setEnableAddSeats={(enabled: boolean) => setIsAddSeatsEnabled(enabled)}
          setSeatsQuantity={(quantity: number) => setSeatsQuantity(quantity)}
        />
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <SecondaryButton
          dataAutomationId="convertAssetsBack"
          id="convertAssetsBack"
          text={t('quote::Back')}
          onClick={handleBackConvertClick}
        />
        <PrimaryButton
          dataAutomationId="convertAssetsBack"
          disabled={!isAddSeatsEnabled}
          id="convertAssetsAdd"
          text={t('quote::Add to quote')}
          onClick={addAssetToQuote}
        />
      </div>
    ),
  };

  const initialListLayout: DialogLayout = {
    header: (
      <div>
        {headerLayout}
        <TextTitle addClass={classes.convertDialogTitleHeader}>
          {t('quote::Select customer asset')}
        </TextTitle>
      </div>
    ),
    body: (
      <div className={classes.customDialogBody}>
        <TextBody>
          {t(
            'quote::After selecting an asset below and indicating what modification you want to take on it, it will be added to a quote as a line item. Assets that currently do no have any available actions are presented in the "Static assets" tab.'
          )}
        </TextBody>
        <PivotContainer
          content={getBodyContent()}
          pivotNav={pivProps.items.length > 1 ? <Pivot {...pivProps} /> : null}
        />
      </div>
    ),
  };
  const getCurrentLayout = () => {
    switch (dialogState) {
      case DialogState.Initial:
        return initialListLayout;
      case DialogState.Actionable:
        return actionableLayout;
      case DialogState.Convert:
        return convertLayout;
      case DialogState.AddSeats:
        return addSeatsLayout;
      default:
        return initialListLayout;
    }
  };

  const currentLayout = getCurrentLayout();

  return (
    <div className={classes.convertDialog}>
      <CustomDialogBox
        bodySlot={currentLayout.body}
        footerSlot={currentLayout.footer}
        headerSlot={currentLayout.header}
        height={552}
        width={749}
      />
      {isInfoBubbleOpen && infoBubble}
    </div>
  );
};

export const ConvertAssetDialogUnconnected = withStyles(convertAssetDialogStyles)(
  ConvertAssetDialogUnstyled
);

export const ConvertAssetDialog = connect(
  mapStateToProps,
  dispatchProps
)(ConvertAssetDialogUnconnected);
