import {
  DetailsList,
  IconButton,
  SectionSeparator,
  TextBody,
  TextWatermark,
} from 'components/ions';
import { dateFormatLl } from 'components/utilities/dates';
import i18next from 'i18n';
import moment from 'moment';
import { CheckboxVisibility, IColumn, SelectionMode } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { servicesConfigCardListStyles } from './ServicesConfigCard.styles';

const lineItemColumns = [
  {
    key: 'type',
    minWidth: 100,
    name: i18next.t('quote::Type'),
    fieldName: 'type',
    maxWidth: 100,
  },
  {
    key: 'productFamily',
    minWidth: 160,
    name: i18next.t('quote::Product Family'),
    fieldName: 'productFamily',
    maxWidth: 160,
  },
  {
    key: 'description',
    minWidth: 230,
    name: i18next.t('quote::Description'),
    fieldName: 'description',
    maxWidth: 230,
  },
  {
    key: 'endDate',
    minWidth: 120,
    name: i18next.t('quote::End Date'),
    fieldName: 'endDate',
    maxWidth: 120,
  },
  {
    key: 'amount',
    minWidth: 80,
    name: i18next.t('quote::Amount'),
    fieldName: 'amount',
    maxWidth: 80,
  },
  {
    key: 'delete',
    minWidth: 30,
    name: i18next.t('quote::Delete'),
    fieldName: 'delete',
    maxWidth: 30,
  },
];

export interface ServicesConfigCardListProps {
  lineItems: any[];
  onDeleteClicked: (lineItem: any) => void;
}

type Props = ServicesConfigCardListProps & WithStyles<typeof servicesConfigCardListStyles>;

const ServicesConfigCardListUnstyled: React.FC<Props> = (props: Props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const scrollPaneRef = React.useRef<HTMLDivElement>(null);
  const [numLineItems, setNumLineItems] = React.useState<number>(props.lineItems.length);

  React.useLayoutEffect(() => {
    const lineItemsAdded = props.lineItems.length > numLineItems;
    if (scrollPaneRef.current && lineItemsAdded) {
      scrollPaneRef.current.scrollTop = scrollPaneRef.current.scrollHeight;
    }
    setNumLineItems(props.lineItems.length);
  }, [props.lineItems, numLineItems]);

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    props.lineItems.forEach(item => {
      totalAmount += Number(item.amount);
    });
    return totalAmount;
  };

  const renderItemColumn = (item?: any, index?: number, column?: IColumn) => {
    if (column) {
      const fieldContent = item[column.fieldName as keyof any] as string;
      switch (column.key) {
        case 'description':
          return <TextBody title={fieldContent}>{fieldContent}</TextBody>;
        case 'endDate':
          return <TextBody>{moment(fieldContent).format(dateFormatLl)}</TextBody>;
        case 'amount':
          const formattedAmount = Number(fieldContent).toLocaleString();
          return (
            <TextBody addClass={classes.amountText} title={formattedAmount}>
              {formattedAmount}
            </TextBody>
          );
        case 'delete':
          return (
            <IconButton
              addClass={classes.deleteButton}
              iconName="Clear"
              onClick={() => {
                if (index !== undefined) props.onDeleteClicked(index);
              }}
            />
          );
        default:
          return <TextBody>{fieldContent}</TextBody>;
      }
    }
    return;
  };

  const lineItemsAddedView = (
    <div>
      <div className={classes.scrollablePaneWrapper} ref={scrollPaneRef}>
        <DetailsList
          addClass={classes.lineItemsList}
          checkboxVisibility={CheckboxVisibility.hidden}
          columns={lineItemColumns}
          compact
          isHeaderVisible={false}
          items={props.lineItems}
          selectionMode={SelectionMode.none}
          onRenderItemColumn={renderItemColumn}
        />
      </div>
      <SectionSeparator addClass={classes.sectionSeparator} />
      <div className={classes.totalAmount}>
        <TextBody>{calculateTotalAmount().toLocaleString()}</TextBody>
      </div>
    </div>
  );

  return (
    <div className={classes.listSectionWrapper}>
      {props.lineItems.length ? (
        lineItemsAddedView
      ) : (
        <div className={classes.watermarkContainer}>
          <TextWatermark>
            {t('quote::To define services, fill in all the fields then click the Add button.')}
          </TextWatermark>
        </div>
      )}
    </div>
  );
};

export const ServicesConfigCardList = withStyles(servicesConfigCardListStyles)(
  ServicesConfigCardListUnstyled
);
