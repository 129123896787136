import { LinkEmail, TextBody, TextBodySmall, VerificationField } from 'components';
import { Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { vatIdStyles } from './VatId.styles';

export interface VatIdProps {
  vatNumber?: string;
  onChange: (value?: string) => void;
  onVerify: (value: string) => void;
  isError: boolean;
  isClear?: boolean;
  lastVerified?: string;
  isLoading?: boolean;
}

type Props = VatIdProps & WithStyles<typeof vatIdStyles>;

export const VatIdUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const classes = props.classes;

  const [currentValue, setCurrentValue] = React.useState<string | undefined>(props.vatNumber);

  const tasks: string[] = [
    t(
      'quote::Please work with your customer to obtain their VAT number. The VAT number will ensure correct tax treatment on the invoice issued by Microsoft (where VAT number is applicable).'
    ),
    t(
      'quote::It is important to ensure the VAT number is valid. Please add the VAT number and click on “Validate” to proceed.'
    ),
  ];

  const verificationErrorMessage = (
    <TextBodySmall dataAutomationId="verification-error-message">
      <Trans ns="quote">
        In the case that the VAT number is provided to you by your customer, please contact{' '}
        <LinkEmail email="dubtax@microsoft.com" size="small" /> to work through solutions.
      </Trans>
    </TextBodySmall>
  );

  const validationErrorMessage = t("quote::Enter a VAT number that doesn't contain a space");

  const createTaskIndex = (index: number) => (
    <div className={classes.taskIndex}>
      <Icon iconName="CircleRing" styles={{ root: classes.icon }} />
      <TextBody addClass={classes.index}>{index}</TextBody>
    </div>
  );

  const createTaskListItem = (index: number, task: string) => (
    <div className={classes.listItem} data-automation-id="task" key={`task-${index}`}>
      {createTaskIndex(index + 1)}
      <TextBody addClass={classes.message}>{task}</TextBody>
    </div>
  );

  const createTaskList = tasks.map((task: string, index: number) => {
    return createTaskListItem(index, task);
  });

  const onValidate = (value: string) => !value.includes(' ');

  const vatNumberVerificationField = (
    <div>
      <VerificationField
        buttonText={t('quote::Validate')}
        dataAutomationId="vatIdVerification"
        errorMessage={props.isError && !props.isClear ? verificationErrorMessage : ''}
        errorMessageStyle={classes.errorMessage}
        invalidStatusText={t('quote::Invalid VAT number')}
        isError={props.isError}
        isLoading={props.isLoading}
        isVerified={currentValue === props.lastVerified && !props.isError}
        lastVerified={props.lastVerified}
        showButtonWhenInvalid={false}
        spinnerDataAutomationId="vatIdSpinner"
        textboxLabel={t('quote::VAT number')}
        textboxMaxLength={250}
        textboxValue={currentValue}
        validationErrorMessage={validationErrorMessage}
        verifiedStatusText={t('quote::Valid VAT number saved')}
        onChange={props.onChange}
        onChangeDebounced={setCurrentValue}
        onValidate={onValidate}
        onVerify={props.onVerify}
      />
    </div>
  );

  return (
    <div>
      {createTaskList}
      <div className={classes.verificationField}>{vatNumberVerificationField}</div>
    </div>
  );
};

export const VatId = withStyles(vatIdStyles)(VatIdUnstyled);
