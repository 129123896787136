import moment from 'moment-timezone';

export const dateFormat = 'YYYY-MM-DD';
// TODO - remove this (replace with LocaleDateFormat)
export const dateFormatLl = 'll'; // Locale Support -> prints it in a friendly localized way, Feb 12, 2020

/**
 * Multiple Locale Support for [Moment.js]{@link https://momentjs.com/}
 * @enum {string}
 */
export enum LocaleDateFormat {
  /** Format: MMM D, YYYY */
  ll = 'll',
  lll = 'lll',
}

export const isMonthDuration = (value: string): boolean => {
  const match = value.match(/P(\d+)M/);
  return !!(match && match.length);
};

export const isDate = (value: string): boolean => moment(value).isValid();

/**
 * This use [Moment.js]{@link https://momentjs.com/} to covert date objects to string
 * in a specific date format.
 *
 * @param {Date} date
 * @param {LocaleDateFormat} format
 */
export const convertDateToFormattedString = (date: Date, format: LocaleDateFormat) =>
  moment(date).format(format);
