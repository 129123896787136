import { TextBody, TextCurrency, TextNavigationSecondarySelected } from 'components/ions';
import { getCreditLineStatus } from 'features-apollo/quote/selectors/organization';
import { CreditLineReason } from 'features-apollo/quote/types';
import { sharedStyles } from 'features/proposal/shared.styles';
import { flexSectionCreator, formatCurrency } from 'features/proposal/utils';
import { CrmLead, SoldToSimple } from 'generated/graphql';
import { Stack } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { sharedStyles as dialogSharedStyles } from '../shared.styles';
import { RequestCreditIncreaseButton, RequestCreditLineButton } from './';

const styles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  ...sharedStyles,

  safeList: {
    color: theme.palette.approveText,
  },
  pending: {
    color: theme.palette.dangerText,
  },
  rejected: {
    color: theme.palette.dangerText,
  },
  normal: {
    color: theme.palette.textTertiary,
  },
  creditLineOuterContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: 'min-content',
  },
  creditLineContainer: {
    display: 'inline-flex',
  },
  creditLineContainerLimitWidth: {
    display: 'inline-flex',
    width: 'min-content',
  },
  creditLineLabel: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    paddingRight: 16,
  },
  creditLineData: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
});

interface CreditLineProps {
  setCustomerFooterAnnualDealEstimate: (value: string) => void;
  annualDealEstimate: string;
  disableLink: boolean;
  soldTo?: SoldToSimple;
  billingCurrency: string;
  crmLead?: CrmLead;
  quoteId: string;
  etag: string;
}

type Props = CreditLineProps & WithStyles<typeof styles>;

export const CreditLineUnstyled: React.FC<Props> = props => {
  const {
    classes,
    soldTo,
    disableLink,
    billingCurrency,
    annualDealEstimate,
    crmLead,
    quoteId,
    etag,
  } = props;
  const { t } = useTranslation();

  const hasSoldTo = !!oc(soldTo).organization.accountId() || !!oc(soldTo).organization.id();
  const soldToMinimumCreditLine = oc(soldTo).monthlyCreditLimit();
  const minimumCreditLine = (soldToMinimumCreditLine && soldToMinimumCreditLine.toString()) || '';
  const creditInfo = oc(soldTo).organization.credit();
  const creditLine = oc(soldTo).organization.credit.creditLine();
  const reason = oc(soldTo).organization.credit.reason();
  const isStrategicAccount = oc(crmLead).salesAccount.isStrategic(false);

  let creditLineStatus: CreditLineReason = CreditLineReason.None;
  if (creditInfo) creditLineStatus = getCreditLineStatus(creditInfo, soldToMinimumCreditLine);
  const getStatusOrLink = () => {
    if (!creditInfo) {
      return <TextBody>{t('quote::Unknown status')}</TextBody>;
    }

    switch (creditLineStatus) {
      case CreditLineReason.SafeList:
        return <TextBody addClass={classes.safeList}>{t('quote::Safe list')}</TextBody>;

      case CreditLineReason.CreditIncrease:
        return (
          <>
            <TextNavigationSecondarySelected addClass={classes.pending}>
              {creditLine && formatCurrency(creditLine)}
            </TextNavigationSecondarySelected>
            <span className={classes.paddingRightSmall} />
            <TextCurrency addClass={classes.pending}>{billingCurrency}</TextCurrency>
          </>
        );
      case CreditLineReason.CreditLine:
        return (
          <>
            <TextBody>{creditLine && formatCurrency(creditLine)}</TextBody>
            <span className={props.classes.paddingRightSmall} />
            <TextCurrency addClass={props.classes.normal}>{props.billingCurrency}</TextCurrency>
          </>
        );
      case CreditLineReason.PendingReview:
        return <TextBody addClass={classes.pending}>{t('quote::Pending')}</TextBody>;

      case CreditLineReason.UnderReview:
        return <TextBody addClass={classes.pending}>{t('quote::Under review')}</TextBody>;

      case CreditLineReason.ReviewCancelled:
        return <TextBody addClass={classes.pending}> {t('quote::Review cancelled')}</TextBody>;

      case CreditLineReason.Rejected:
        return (
          <TextNavigationSecondarySelected addClass={classes.rejected}>
            {t('quote::Rejected')}
          </TextNavigationSecondarySelected>
        );

      default:
        return <TextBody>{t('quote::Unknown status')}</TextBody>;
    }
  };

  // This will show the Credit Line label and its calculated value.
  const creditLineComponent = (limitWidth = true) => (
    <div
      className={limitWidth ? classes.creditLineContainerLimitWidth : classes.creditLineContainer}
      data-automation-id="creditLine"
    >
      <TextNavigationSecondarySelected addClass={`${classes.normal} ${classes.creditLineLabel}`}>
        {t('quote::Credit line:')}
      </TextNavigationSecondarySelected>
      <div className={classes.creditLineData} data-automation-id="creditLineValue">
        {getStatusOrLink()}
      </div>
    </div>
  );

  const rejectedComponent = flexSectionCreator(
    classes.flexLarge,
    classes.flexXLarge,
    <Stack>
      {creditLineComponent()}
      <RequestCreditLineButton
        annualDealEstimate={(annualDealEstimate && annualDealEstimate.toString()) || ''}
        billingCurrency={billingCurrency}
        dataAutomationId="resubmitCreditLineButton"
        disabled={!soldTo || disableLink}
        displayText={t('quote::resubmit credit line request')}
        etag={etag}
        minimumCreditLine={minimumCreditLine.toString()}
        quoteId={quoteId}
        soldTo={soldTo}
      />
    </Stack>,
    classes.flexContainer
  );

  const creditLineIncreaseComponent = flexSectionCreator(
    classes.flexLarge,
    classes.flexXLarge,
    <Stack>
      <div className={classes.creditLineOuterContainer}>
        {creditLineComponent(false)}
        <div className={`${classes.paddingTopLarge} ${classes.creditLineContainer}`}>
          <TextNavigationSecondarySelected
            addClass={`${classes.normal} ${classes.creditLineLabel}`}
          >
            {t('quote::Required credit line:')}
          </TextNavigationSecondarySelected>
          <div className={classes.creditLineData}>
            <TextBody>{formatCurrency(minimumCreditLine)}</TextBody>
            <span className={classes.paddingRightSmall} />
            <TextCurrency addClass={classes.normal}>{billingCurrency}</TextCurrency>
          </div>
        </div>
      </div>
      <TextBody addClass={`${classes.paddingTopXSmall} ${classes.normal}`}>
        {t(
          `quote::The customer's credit line must be greater than the minimum required credit line. You'll need a credit increase before you can publish the quote to the customer.`
        )}
      </TextBody>
      <RequestCreditIncreaseButton
        annualDealEstimate={annualDealEstimate}
        billingCurrency={billingCurrency}
        dataAutomationId="requestCreditIncreaseButton"
        disableLink={disableLink}
        etag={etag}
        minimumCreditLine={minimumCreditLine}
        quoteId={quoteId}
        soldTo={soldTo}
      />
    </Stack>,
    classes.flexContainer
  );

  const noCreditComponent = flexSectionCreator(
    classes.flexLarge,
    classes.flexXLarge,
    <Stack>
      <TextBody addClass={classes.normal}>
        {t(
          `quote::A credit line must be established for this customer. Since it's partially based on the annual deal estimate, now would be a good time to kick it off.`
        )}
      </TextBody>
      <RequestCreditLineButton
        annualDealEstimate={annualDealEstimate}
        billingCurrency={billingCurrency}
        dataAutomationId="salesRequestCreditLine"
        disabled={!hasSoldTo || disableLink}
        displayText={t('quote::request credit line')}
        etag={etag}
        minimumCreditLine={minimumCreditLine}
        quoteId={quoteId}
        setCustomerFooterAnnualDealEstimate={props.setCustomerFooterAnnualDealEstimate}
        soldTo={soldTo}
      />
    </Stack>,
    classes.flexContainer
  );

  const mainComponent = () => {
    if (!creditLine && !reason) {
      if (isStrategicAccount) {
        return null;
      } else {
        return noCreditComponent;
      }
    }
    switch (creditLineStatus) {
      case CreditLineReason.Rejected:
        return rejectedComponent;
      case CreditLineReason.CreditIncrease:
        return creditLineIncreaseComponent;
      default:
        return creditLineComponent();
    }
  };

  return mainComponent();
};

export const CreditLine = withStyles(styles)(CreditLineUnstyled);
