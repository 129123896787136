import * as appSelectors from 'features/app/selectors';
import { getProduct } from 'features/catalog/selectors';
import { cardConstants } from 'features/proposal/components/DiscountCard/cardConstants';
import * as userActions from 'features/user/actions';
import { combineLatest, from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { SearchApprovalsParams } from 'services/approval/types';
import { getQuoteIdsFromApprovalSummaries } from 'services/approval/utils';
import { EmpowermentFilter } from 'services/empowerment/types';
import { productIds } from 'services/proposal/config';
import {
  LineItem,
  PriceAdjustmentType,
  PricingInstruction,
  PricingProposal,
  Proposal,
  ProposalHeaderPatchRequest,
  ProposalUpdateRequest,
  RequestProposal,
  SearchProposalsParams,
} from 'services/proposal/types';
import { t } from 'services/utils';
import { RootEpic } from 'store/types';
import { oc } from 'ts-optchain';
import { ActionType, isActionOf } from 'typesafe-actions';

import * as actions from '../actions';
import { getProposal } from '../selectors';
import { defaultMarket, getCurrencies, getLanguages } from '../supported-markets';
import { buildProposalRequest, createPriceMap, generateEmpowermentsKey } from '../utils';

export const searchProposals: RootEpic<ActionType<typeof actions>> = (action$, state$, { api }) =>
  merge(
    // Searches where no filter is provided and we should default to the current user's email.
    combineLatest(
      action$.pipe(
        filter(isActionOf(actions.searchProposalFragmentsAsync.request)),
        filter(action => !action.payload.filter && !action.payload.proposalIds)
      ),
      action$.pipe(filter(isActionOf(userActions.aadLoginSuccess)))
    ).pipe(
      map(actions => {
        const params: SearchProposalsParams = {
          sort: { field: actions[0].payload.sortField, order: actions[0].payload.sortOrder },
          filter: api.proposal.filterTemplates.getMsContact(actions[1].payload.account.userName),
        };
        return params;
      })
    ),

    // Searches where a filter string is provided.
    action$.pipe(
      filter(isActionOf(actions.searchProposalFragmentsAsync.request)),
      // Skip the search if invalid search terms are used in the filter.
      filter(action => !!action.payload.filter && !action.payload.filter.match(/["?()*+{}/[\]<>]/)),
      map(action => {
        const params: SearchProposalsParams = {
          sort: { field: action.payload.sortField, order: action.payload.sortOrder },
          filter: api.proposal.filterTemplates.getSearch(action.payload.filter || ''),
        };
        return params;
      })
    ),

    // Searches where a list of proposal ids are provided which are referenced by a collection
    // of approvals.
    action$.pipe(
      filter(isActionOf(actions.searchProposalFragmentsAsync.request)),
      filter(action => !!action.payload.proposalIds),
      map(action => {
        const params: SearchProposalsParams = {
          sort: { field: action.payload.sortField, order: action.payload.sortOrder },
          filter: api.proposal.filterTemplates.getSearchByIdAndStatus(
            action.payload.proposalIds || [],
            !!action.payload.pendingOnly
          ),
        };
        return params;
      })
    )
  ).pipe(
    switchMap(params =>
      from(api.proposal.searchProposals(params, state$.value.app.appConfig.proposal)).pipe(
        mergeMap(response => {
          const filterReferrals = response.filter(
            summary => summary.header.transactionType === 'UserPurchase'
          );
          return [
            actions.searchProposalFragmentsAsync.success(filterReferrals),
            ...((state$.value.proposal.views.activeId &&
              response.some(summary => summary.id === state$.value.proposal.views.activeId)) ||
            !response.length ||
            !response[0].id
              ? [actions.clearActiveQuote()]
              : [actions.loadAndHydrateLiteQuote(response[0].id)]),
          ];
        }),
        catchError(err =>
          of(
            actions.searchProposalFragmentsAsync.failure({
              message: t('error::Error searching quotes.'),
              exception: err,
            })
          )
        )
      )
    )
  );

export const searchApprovals: RootEpic<ActionType<typeof actions>> = (action$, state$, { api }) =>
  merge(
    // Searches where no filter is provided and we should default to the current user's email.
    combineLatest(
      action$.pipe(filter(isActionOf(actions.searchApprovalsAsync.request))),
      action$.pipe(filter(isActionOf(userActions.aadLoginSuccess)))
    ).pipe(
      map(actions => {
        const searchApprovalsParams: SearchApprovalsParams = {
          filter: api.approval.filterTemplates.getByApproverContact(
            actions[1].payload.account.userName
          ),
        };

        // Multiple Params: Pass forward the calculated params that will be used for the approval
        // search along with the original search params that will be used with the quotesearch
        // (following successful load of the approvals).
        return { searchApprovalsParams, approvalListSearchParams: actions[0].payload };
      })
    )
  ).pipe(
    switchMap(params =>
      from(
        api.approval.searchApprovals(
          params.searchApprovalsParams,
          state$.value.app.appConfig.approval
        )
      ).pipe(
        mergeMap(approvalSummaries => [
          actions.searchApprovalsAsync.success(approvalSummaries),

          // Load the corresponding quote summaries for a unique list of quotes derived from
          // the approvals.
          getQuoteIdsFromApprovalSummaries(
            approvalSummaries,
            state$.value.user.current.email,
            params.approvalListSearchParams.pendingOnly
          ).length
            ? actions.searchProposalFragmentsAsync.request({
                proposalIds: getQuoteIdsFromApprovalSummaries(
                  approvalSummaries,
                  state$.value.user.current.email,
                  params.approvalListSearchParams.pendingOnly
                ),
                ...params.approvalListSearchParams,
              })
            : actions.searchProposalFragmentsAsync.success([]),
        ]),
        catchError(err => [
          actions.searchProposalFragmentsAsync.failure({
            message: t('error::Error searching approvals.'),
            exception: err,
          }),
          actions.searchApprovalsAsync.failure({
            message: t('error::Error searching approvals.'),
            exception: err,
          }),
        ])
      )
    )
  );

export const approvalAction: RootEpic<ActionType<typeof actions>> = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(actions.performApprovalActionAsync.request)),
    switchMap(action =>
      from(
        api.approval.performApprovalAction(
          action.payload,
          state$.value.user.current,
          state$.value.app.appConfig.approval
        )
      ).pipe(
        mergeMap(response => [
          actions.performApprovalActionAsync.success(response),
          actions.refreshApproval(response.approvalId),
        ]),
        catchError(err =>
          of(
            actions.performApprovalActionAsync.failure({
              message: t('error::Error performing approval action.'),
              exception: err,
            })
          )
        )
      )
    )
  );

export const validateEmpowerment: RootEpic<ActionType<typeof actions>> = (
  action$,
  state$,
  { api }
) =>
  action$.pipe(
    filter(isActionOf(actions.validateEmpowermentAsync.request)),
    switchMap(action =>
      from(
        api.empowerment.validateEmpowerment(action.payload, state$.value.app.appConfig.approval)
      ).pipe(
        map(response =>
          actions.validateEmpowermentAsync.success({
            email: action.payload.email,
            marketCode: action.payload.market.marketCode,
            policy: action.payload.policy,
            empowermentLevel: action.payload.empowermentLevel,
            isEmpowered: response.isEmpowered,
          })
        ),
        catchError(err =>
          of(
            actions.validateEmpowermentAsync.failure({
              message: t('error::Error validating empowerment.'),
              exception: err,
            })
          )
        )
      )
    )
  );

export const loadPrices: RootEpic<ActionType<typeof actions>> = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(actions.loadPricesAsync.request)),
    mergeMap(action =>
      from(api.proposal.loadPrices(action.payload, state$.value.app.appConfig.proposal)).pipe(
        map((pricingProposal: PricingProposal) => {
          const priceMap = createPriceMap(pricingProposal.lineItems);
          return actions.loadPricesAsync.success(priceMap);
        }),
        catchError(err =>
          of(
            actions.loadPricesAsync.failure({
              message: t('error::Error loading prices.'),
              exception: err,
            })
          )
        )
      )
    )
  );

export const createNotification: RootEpic<ActionType<typeof actions>> = (
  action$,
  state$,
  { api }
) =>
  action$.pipe(
    filter(isActionOf(actions.createNotificationAsync.request)),
    switchMap(action =>
      from(
        api.notification.createNotification(
          action.payload,
          state$.value.app.appConfig.notifications
        )
      ).pipe(
        map(actions.createNotificationAsync.success),
        catchError(err =>
          of(
            actions.createNotificationAsync.failure({
              message: t('error::Error creating notification.'),
              exception: err,
            })
          )
        )
      )
    )
  );

export const updateProposalMarket: RootEpic<ActionType<typeof actions>> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.updateProposalMarket)),
    map(action => {
      const request: ProposalHeaderPatchRequest = {
        commands: [
          { op: 'replace', path: '/pricingContext/market', value: action.payload },
          {
            op: 'replace',
            path: '/pricingContext/billingCurrency',
            value: getCurrencies(action.payload)[0],
          },
          {
            op: 'replace',
            path: '/pricingContext/languages',
            value: getLanguages(action.payload)[0],
          },
          { op: 'remove', path: '/estimatedDealValue' },
        ],
        proposalId: action.meta.id,
        etag: action.meta.etag,
      };
      return actions.patchProposalHeaderAsync.request(request);
    })
  );

export const updateProposalLanguage: RootEpic<ActionType<typeof actions>> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.updateProposalLanguage)),
    map(action => {
      const request: ProposalHeaderPatchRequest = {
        commands: [{ op: 'replace', path: '/pricingContext/languages', value: action.payload }],
        proposalId: action.meta.id,
        etag: action.meta.etag,
      };
      return actions.patchProposalHeaderAsync.request(request);
    })
  );

export const loadMissingEmpowerments: RootEpic<ActionType<typeof actions>> = (action$, state$) => {
  const getEmpowermentFilters = (proposal: Proposal): EmpowermentFilter[] => {
    const requiredApprovalLevels = proposal.header.approval.requiredApprovalLevels;

    const empowermentsFilters: EmpowermentFilter[] = [];

    if (requiredApprovalLevels) {
      const marketCode = proposal.header.pricingContext.market || defaultMarket;
      const market = {
        hierarchyLevel: appSelectors.getApprovalHierarchyLevel(state$.value),
        marketCode: marketCode,
      };

      for (let i = 0; i < requiredApprovalLevels.length; i++) {
        const item = requiredApprovalLevels[i];
        const key = generateEmpowermentsKey({
          empowermentLevel: item.level,
          policy: item.policy,
          market: marketCode,
        });
        if (!state$.value.proposal.empowerments[key]) {
          const filter: EmpowermentFilter = {
            empowermentLevel: item.level,
            policy: item.policy,
            market,
          };

          empowermentsFilters.push(filter);
        }
      }
    }
    return empowermentsFilters;
  };

  return action$.pipe(
    filter(isActionOf(actions.loadMissingEmpowerments)),
    map(action => getProposal(state$.value, action.payload)),
    map(quote => getEmpowermentFilters(quote)),
    filter(empowermentFilters => empowermentFilters !== []),
    mergeMap(empowermentFilters => {
      return empowermentFilters.map(filter => actions.loadEmpowermentsAsync.request(filter));
    })
  );
};

export const loadEmpowerments: RootEpic<ActionType<typeof actions>> = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(actions.loadEmpowermentsAsync.request)),
    mergeMap(action =>
      from(
        api.empowerment.loadEmpowerments(action.payload, state$.value.app.appConfig.approval)
      ).pipe(
        map(response =>
          actions.loadEmpowermentsAsync.success({
            market: action.payload.market.marketCode,
            policy: action.payload.policy,
            empowermentLevel: action.payload.empowermentLevel,
            empowerments: response.empowerments,
          })
        ),
        catchError(err =>
          of(
            actions.loadEmpowermentsAsync.failure({
              message: t(`error::Error loading required approvers' empowerments.`),
              exception: err,
            })
          )
        )
      )
    )
  );

export const bulkUpdateDiscountOnProposalWithDFD: RootEpic<ActionType<typeof actions>> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.bulkUpdateDiscountOnProposalWithDFD)),
    switchMap(action =>
      action$
        .pipe(
          filter(() => {
            const dfdExists = getProduct(state$.value, productIds.discountFulfillmentDocument);
            return !!dfdExists;
          }),
          take(1)
        )
        .pipe(
          map(() => {
            const dfd = getProduct(state$.value, productIds.discountFulfillmentDocument);
            const discountLineItems: LineItem[] = action.payload.newLineItems.map(item => {
              if (dfd) {
                const hasSkus = !!dfd.DisplaySkuAvailabilities.length;
                const hasAvailabilities = !!(
                  hasSkus && dfd.DisplaySkuAvailabilities[0].Availabilities.length
                );
                const productIdentifier =
                  (item.pricingInstruction && item.pricingInstruction.productIdentifier) || {};
                const newPricingInstruction: PricingInstruction = {
                  ...item.pricingInstruction,
                  productIdentifier: {
                    ...productIdentifier,
                    ...item.productIdentifier,
                  },
                  organizations: oc(item).pricingInstruction.organizations([]),
                  priceAdjustmentType: PriceAdjustmentType.new,
                };
                return {
                  ...item,
                  productIdentifier: {
                    productId: dfd.ProductId,
                    skuId: hasSkus ? dfd.DisplaySkuAvailabilities[0].Sku.SkuId : undefined,
                    availabilityId: hasAvailabilities
                      ? dfd.DisplaySkuAvailabilities[0].Availabilities[0].AvailabilityId
                      : undefined,
                    productFamily: dfd.ProductFamily,
                    action: cardConstants.purchase,
                  },
                  pricingInstruction: newPricingInstruction,
                } as LineItem;
              }
              return item;
            });

            const proposal = action.payload.proposal;
            const updatedLineItems = proposal.lineItems.map(lineItem => {
              const matchedItem = discountLineItems.find(item => item.id === lineItem.id);
              return matchedItem ? matchedItem : lineItem;
            });

            const discountedProposal: RequestProposal = {
              ...proposal,
              lineItems: updatedLineItems,
            };
            const proposalUpdateRequest: ProposalUpdateRequest = {
              etag: action.payload.proposal.etag,
              proposal: discountedProposal,
              proposalId: action.payload.proposal.id,
            };
            return actions.updateProposalAsync.request(proposalUpdateRequest);
          })
        )
    )
  );

export const updateDiscountOnProposalWithDFD: RootEpic<ActionType<typeof actions>> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(actions.updateDiscountOnProposalWithDFD)),
    switchMap(action =>
      action$
        .pipe(
          filter(() => {
            const dfdExists = getProduct(state$.value, productIds.discountFulfillmentDocument);
            return !!dfdExists;
          }),
          take(1)
        )
        .pipe(
          map(() => {
            const dfd = getProduct(state$.value, productIds.discountFulfillmentDocument);
            const proposal = action.payload.proposal;
            const newLineItem = action.payload.newLineItem;
            const hasSkus = !!dfd.DisplaySkuAvailabilities.length;
            const hasAvailabilities = !!(
              hasSkus && dfd.DisplaySkuAvailabilities[0].Availabilities.length
            );
            const productIdentifier =
              (newLineItem.pricingInstruction &&
                newLineItem.pricingInstruction.productIdentifier) ||
              {};
            const newPricingInstruction: PricingInstruction = {
              ...newLineItem.pricingInstruction,
              productIdentifier: {
                ...productIdentifier,
                ...action.payload.newLineItem.productIdentifier,
              },
              //TODO: cameneks, do not put empty array
              organizations: oc(newLineItem).pricingInstruction.organizations([]),
              priceAdjustmentType: PriceAdjustmentType.new,
            };
            const discountedLineItem: LineItem = {
              ...action.payload.newLineItem,
              productIdentifier: {
                productId: dfd.ProductId,
                skuId: hasSkus ? dfd.DisplaySkuAvailabilities[0].Sku.SkuId : undefined,
                availabilityId: hasAvailabilities
                  ? dfd.DisplaySkuAvailabilities[0].Availabilities[0].AvailabilityId
                  : undefined,
                productFamily: dfd.ProductFamily,
                action: cardConstants.purchase,
              },
              pricingInstruction: newPricingInstruction,
            };

            const matchedLineItemIndex = proposal.lineItems.findIndex(
              (lineItem: LineItem) => lineItem.id === newLineItem.id
            );

            if (matchedLineItemIndex === -1) {
              throw new Error('Cannot find the matched line item Id');
            }

            const discountedProposal: RequestProposal = {
              ...proposal,
              lineItems: [
                ...proposal.lineItems.slice(0, matchedLineItemIndex),
                discountedLineItem,
                ...proposal.lineItems.slice(matchedLineItemIndex + 1),
              ],
            };
            const proposalUpdateRequest: ProposalUpdateRequest = {
              etag: action.payload.proposal.etag,
              proposal: discountedProposal,
              proposalId: action.payload.proposal.id,
            };
            return actions.updateProposalAsync.request(proposalUpdateRequest);
          })
        )
    )
  );

export const updateAnnualDealEstimate: RootEpic<ActionType<typeof actions>> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.updateAnnualDealEstimate)),
    map(action => {
      const currentProposal = action.meta;
      const proposal: Proposal = {
        ...currentProposal,
        header: {
          ...currentProposal.header,
          soldToCustomerLegalEntity: {
            ...currentProposal.header.soldToCustomerLegalEntity,
          },
        },
      };
      proposal.header.estimatedDealValue = action.payload.annualDealEstimate;
      if (proposal.header.soldToCustomerLegalEntity) {
        proposal.header.soldToCustomerLegalEntity.monthlyCreditLimit =
          action.payload.minimumCreditLine;
      } else {
        proposal.header.soldToCustomerLegalEntity = {
          monthlyCreditLimit: action.payload.minimumCreditLine,
        };
      }

      const proposalRequest = buildProposalRequest(proposal);
      return actions.updateProposalAsync.request(proposalRequest);
    })
  );
