import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
} from 'components';
import { loadEnrollmentAndUpdateProposal, setEnrollmentFieldView } from 'features/proposal/actions';
import { deleteProposalDialogStyles as enterEnrollmentNumberDialogStyles } from 'features/proposal/components/Dialogs/DeleteDialog/DeleteDialog.styles';
import { DialogLayout } from 'features/proposal/components/Dialogs/Shared';
import { EnrollmentFieldView } from 'features/proposal/types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { DialogContext } from 'styles';

const dispatchProps = {
  setEnrollmentFieldView: setEnrollmentFieldView,
  loadEnrollmentAndUpdateProposal: loadEnrollmentAndUpdateProposal,
};

type Props = WithStyles<typeof enterEnrollmentNumberDialogStyles> & typeof dispatchProps;

export const EnterEnrollmentNumberDialogUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = props;
  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };

  const dialogLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        dataAutomationId="enterEnrollmentNumber"
        dialogClose={closeDialog}
        headerClass={classes.customDialogHeader}
      >
        <TextTitle>{t('quote::Enter enrollment number?')}</TextTitle>
      </DialogHeader>
    ),
    body: (
      <div className={classes.customDialogBody}>
        <TextBody>
          {t(
            'quote::Any enrollment number entered will be validated to ensure that it is an Azure-backed enrollment number. Please ensure that any enrollment number you enter is tied to the customer assigned to the quote.'
          )}
        </TextBody>
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <PrimaryButton
          dataAutomationId="enterEnrollmentButton"
          text={t('quote::Enter enrollment')}
          onClick={() => {
            props.setEnrollmentFieldView(EnrollmentFieldView.TextboxStandard);
            props.loadEnrollmentAndUpdateProposal('');
            closeDialog();
          }}
        />
        <SecondaryButton
          dataAutomationId="enterEnrollmentCancelButton"
          text={t('quote::Cancel')}
          onClick={closeDialog}
        />
      </div>
    ),
  };
  return (
    <div>
      <CustomDialogBox
        bodySlot={dialogLayout.body}
        footerSlot={dialogLayout.footer}
        headerSlot={dialogLayout.header}
        height={262}
        width={442}
      />
    </div>
  );
};

export const EnterEnrollmentNumberDialog = connect(
  undefined,
  dispatchProps
)(withStyles(enterEnrollmentNumberDialogStyles)(EnterEnrollmentNumberDialogUnstyled));
