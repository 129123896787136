import { PrimaryButton, SecondaryButton, TextBody, TextTitle } from 'components/ions';
import { CustomDialogBox, DialogHeader } from 'components/ions/PopUps';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles/DialogueProvider';

import { DialogLayout } from '../Dialogs/Shared';
import { configCardConfirmationDialogStyles } from './ConfigCardConfirmationDialog.styles';

export interface ConfigCardConfirmationDialogProps {
  onApply: () => void;
  onCancel: () => void;
}

type Props = ConfigCardConfirmationDialogProps &
  WithStyles<typeof configCardConfirmationDialogStyles>;

const ConfigCardConfirmationDialogUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);

  const { classes } = props;
  const closeDialog = () => {
    dialogContext.closeDialog();
    props.onCancel();
  };
  const onApply = () => {
    dialogContext.closeDialog();
    props.onApply();
  };
  const header = (
    <DialogHeader
      closeButtonClass={classes.customDialogCloseButton}
      dataAutomationId="applyConfiguration"
      dialogClose={closeDialog}
      headerClass={classes.customDialogHeader}
    >
      <TextTitle>{t('quote::Apply Configuration?')}</TextTitle>
    </DialogHeader>
  );
  const configurationText = t(
    'quote::Changing the product configuration will remove the discount and you will need to reconfigure it.'
  );

  const body = (
    <div className={classes.confirmationDialogBody}>
      <div>
        <TextBody>{configurationText}</TextBody>
      </div>
    </div>
  );

  const footer = (
    <div className={`${classes.footer} ${classes.buttonRightAlign}`}>
      <PrimaryButton
        dataAutomationId="configCardConfirmationApplyButton"
        text={t('quote::Apply')}
        onClick={onApply}
      />
      <div className={classes.secondButton}>
        <SecondaryButton
          dataAutomationId="configCardConfirmationCancelButton"
          text={t('quote::Cancel')}
          onClick={closeDialog}
        />
      </div>
    </div>
  );

  const layout: DialogLayout = {
    header: header,
    body: body,
    footer: footer,
  };

  return (
    <CustomDialogBox
      bodySlot={layout.body}
      enterKeyCallback={onApply}
      footerSlot={layout.footer}
      headerSlot={layout.header}
      height={224}
      //TODO: cameneks, this does not get called for some reason probably have something to do with the layers
      width={428}
    />
  );
};

export const ConfigCardConfirmationDialog = withStyles(configCardConfirmationDialogStyles)(
  ConfigCardConfirmationDialogUnstyled
);
