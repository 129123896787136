import React from 'react';
import { useTranslation } from 'react-i18next';

import { InformationalTile } from './InformationalTile';

export const TenantInvalidInfoTile: React.FC<{ scenario: 'partner' | 'customer' }> = props => {
  const { t } = useTranslation();

  const description =
    props.scenario === 'partner'
      ? t(
          'quote::The tenant ID or domain name entered does match the correct pattern for a domain name or ID. Please confirm the information entered is correct for your partner.'
        )
      : t(
          'quote::The tenant ID or domain name entered does match the correct pattern for a domain name or ID. Please confirm the information entered is correct for your customer.'
        );

  return (
    <InformationalTile
      description={description}
      iconName="ProfileSearch"
      title={t('quote::Invalid search term')}
    />
  );
};
