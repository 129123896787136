import { sharedStyles, ThemeProps } from 'styles';

export const comboBoxAtomStyles = (theme: ThemeProps) => {
  const { fonts, palette } = theme;
  return {
    errorMessage: {
      fontFamily: fonts.fontFamily.fabricDefault,
      fontSize: fonts.fontSizes.small,
    },
    root: {
      ...sharedStyles(theme).roundedCorners,
      backgroundColor: palette.backgroundStandout,
      borderColor: palette.border,
    },
    rootChecked: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },
    rootDisabled: {
      backgroundColor: palette.backgroundCommon,
      borderColor: palette.border,
    },
    buttonDisabled: {
      backgroundColor: palette.backgroundCommon,
      color: palette.textDisabled,
    },
    rootError: {
      '&, &:after': {
        borderColor: palette.dangerText,
      },
    },
    input: {
      backgroundColor: palette.backgroundStandout,
      '&::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder': {
        color: palette.textTertiary,
      },
    },
    inputDisabled: {
      backgroundColor: palette.backgroundCommon,
      color: palette.text,
      '&::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder': {
        color: palette.textDisabled,
      },
    },
    callout: {
      borderStyle: 'none',
      borderRadius: '2px',
      '& .ms-Callout-main': {
        borderRadius: '2px',
      },
      '& .is-disabled.ms-ComboBox-option': {
        backgroundColor: palette.backgroundStandout,
        color: palette.textDisabled,
      },
    },
    searchButtonIcon: {
      color: palette.primary,
    },
  };
};
