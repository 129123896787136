import {
  Dialog,
  PrimaryButton,
  SecondaryButton,
  SectionSeparator,
  Spinner,
  StandardSearch,
  TextBodyLarge,
  TextBodySmall,
  XLargerIcon,
} from 'components';
import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { OrganizationSearchResultTile } from './OrganizationSearchResultTile';
import { OrganizationSearchDialogProps } from './types';
import { useScrollPadding } from './useScrollPadding';

const RESULTS_MAX_HEIGHT = 324;
const REGEX_GUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

const styles = (theme: ThemeProps) => {
  return {
    informationalTileContainer: (props: OrganizationSearchDialogProps) =>
      props.results ? undefined : { marginTop: 20 },
    resultsHeader: {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    radioBulletIcon: {
      fontSize: 9,
      color: theme.palette.backgroundDivider,
      padding: '0 6px',
    },
    resultsLabel: {
      color: theme.palette.textTertiary,
    },
    resultsContainer: {
      maxHeight: RESULTS_MAX_HEIGHT,
      overflow: 'hidden',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        marginLeft: 8,
        width: 12,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(235, 234, 232, 1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(121, 118, 113, 1)',
      },
      '& > :not(:last-child)': {
        marginBottom: 4,
      },
    },
    title: { display: 'block', marginBottom: 12, fontWeight: theme.fonts.fontWeights.semiBold },
    // TODO: jepagan - remove
    errorContainer: {
      marginTop: 8,
    },
    // TODO: jepagan - remove
    errorText: {
      display: 'block',
      color: theme.palette.dangerText,
      fontStyle: 'italic',
      textAlign: 'center',
    },
  };
};

type Props = OrganizationSearchDialogProps & WithStyles<typeof styles>;

export const OrganizationSearchDialogUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>();
  const [tenantInfoToDisplay, setTenantInfoToDisplay] = React.useState<
    'tid' | 'tname' | undefined
  >();

  const disabled = !searchQuery || !searchQuery.trim();
  const ref = useScrollPadding(RESULTS_MAX_HEIGHT);
  let content: JSX.Element | null = null;

  if (props.loading) {
    content = (
      <Spinner
        label={props.loadingLabel || t('quote::looking for billing accounts')}
        styles={{ root: { marginTop: 24 } }}
      />
    );
  } else if (props.results) {
    const results = props.results.length ? (
      <div className={classes.resultsContainer} ref={ref}>
        {props.results.map((result, index) => (
          <OrganizationSearchResultTile
            {...result}
            key={index}
            tenantInfoToDisplay={tenantInfoToDisplay}
          />
        ))}
      </div>
    ) : null;

    const createOrganizationSection = results && props.allowCreateOrganization && (
      <>
        <SectionSeparator style={{ margin: '24px 0 20px' }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <XLargerIcon
            iconName="AccountManagement"
            predefinedStyle="primary"
            styles={{ root: { marginRight: 24 } }}
          />
          <div>
            <TextBodyLarge addClass={classes.title}>
              {t('quote::Need to create a new billing account?')}
            </TextBodyLarge>
            <SecondaryButton
              text={t('quote::Create new billing account')}
              onClick={props.onCreate}
            />
          </div>
        </div>
      </>
    );

    content = (
      <>
        <div style={{ margin: '20px 0 8px' }}>
          <TextBodySmall addClass={classes.resultsHeader}>{t('Results')}</TextBodySmall>
          <Icon iconName="RadioBullet" styles={{ root: classes.radioBulletIcon }} />
          <TextBodySmall addClass={classes.resultsLabel}>
            {props.resultsLabel ||
              t('quote::{{count}} billing account found', {
                count: props.results.length,
                // eslint-disable-next-line @typescript-eslint/camelcase
                defaultValue_plural: '{{count}} billing accounts found',
              })}
          </TextBodySmall>
        </div>
        {results}
        {createOrganizationSection}
      </>
    );
  }

  // Event handlers
  const onSearch = () => {
    if (searchQuery) {
      props.onSearch(searchQuery);
      const isTenantGUID = REGEX_GUID.test(searchQuery);
      setTenantInfoToDisplay(isTenantGUID ? 'tid' : 'tname');
    }
  };

  return (
    <Dialog
      hideFooter
      minHeight={176}
      title={props.title || t('quote::Billing account search')}
      width={488}
    >
      <p style={{ marginTop: 0, marginBottom: 12 }}>
        <TextBodySmall>
          {props.description ||
            t(
              'quote::Find a billing account by searching for them using their tenant ID or domain name.'
            )}
        </TextBodySmall>
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StandardSearch
          autoFocus
          placeholder={t('quote::Search by tenant ID, or domain name')}
          styles={{
            root: { paddingLeft: 8, width: 350, height: 30 },
            iconContainer: { display: 'none' },
          }}
          value={searchQuery}
          onChange={value => setSearchQuery(value && value.trim())}
          onKeyPress={keyEvent => {
            if (!disabled && keyEvent.key === 'Enter') {
              onSearch();
            }
          }}
        />
        <PrimaryButton
          disabled={disabled}
          styles={{ root: { width: 78, height: 30 } }}
          text={t('Search')}
          onClick={() => {
            onSearch();
          }}
        />
      </div>
      {content}
      <div className={classes.informationalTileContainer}>{props.informationalTile}</div>
    </Dialog>
  );
};

export const OrganizationSearchDialog = withStyles(styles)(OrganizationSearchDialogUnstyled);
