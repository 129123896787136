import {
  IShimmerColors,
  IShimmerElement,
  IShimmerStyles,
  Shimmer,
  ShimmerElementType,
} from 'office-ui-fabric-react';
import * as React from 'react';

/**
 * Shimmer Atom props
 * @prop {IShimmerColors} shimmerColors     - Defines an object with possible colors to pass for Shimmer customization used on different backgrounds.
 * @prop {IShimmerElement} shimmerElements  - Elements to render in one line of the Shimmer.
 * @prop {string} width                     - Sets the width value of the shimmer wave wrapper.
 */
export interface ShimmerProps {
  className?: string;
  height?: number | string;
  shimmerColors?: Partial<IShimmerColors>;
  shimmerElements?: IShimmerElement[];
  styles?: Partial<IShimmerStyles>;
  width?: number | string;
}

/**
 * Shimmer from Office Fabric UI
 * @see {@link https://developer.microsoft.com/en-us/fabric#/controls/web/shimmer} for more information
 */
export const ShimmerAtom: React.FC<ShimmerProps> = props => {
  // Required for shimmerColors background to take effect (alternative: styles prop)
  const shimmerElements = props.shimmerElements || [{ type: ShimmerElementType.line }];
  return (
    <Shimmer
      className={props.className}
      height={props.height}
      shimmerColors={props.shimmerColors}
      shimmerElements={shimmerElements}
      styles={props.styles}
      width={props.width}
    />
  );
};
