import { loadAccount, loadTenantNames, loadTenantAdmins } from 'features/customer/sagas/auxiliary';
import * as actions from 'features/proposal/actions';
import { getProductIdentifier } from 'features/proposal/selectors/lineItem';
import { getCreditInfo, getProposal } from 'features/proposal/selectors/proposal';
import { buildProposalRequest, cloneProposal } from 'features/proposal/utils';
import { getUser } from 'features/user/selectors';
import { User } from 'features/user/types';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'services';
import { Account } from 'services/account/types';
import { ApprovalConfig } from 'services/approval/config';
import { Approval } from 'services/approval/types';
import { CreditConfig } from 'services/credit/config';
import { CreditInfo, CreditLookupRequest } from 'services/credit/types';
import loggerService from 'services/logger-service';
import { ProposalConfig } from 'services/proposal/config';
import {
  CreateLineItemsRequest,
  DeleteLineItemRequest,
  DeleteLineItemsRequest,
  LineItem,
  MultipleProposalActionRequest,
  Proposal,
  ProposalActionBody,
  ProposalActionRequest,
  ProposalHeaderPatchRequest,
  ProposalHeaderResponse,
  ProposalUpdateRequest,
} from 'services/proposal/types';
import { PurchaseConfig } from 'services/purchase/config';
import { PurchaseRecordSummary } from 'services/purchase/types';
import { PatchCommand } from 'services/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';
import { ProposalStatus, UserGroup } from 'services/proposal/types';

export function* loadQuote(quoteId: string) {
  if (!quoteId) {
    return;
  }
  yield put(actions.loadProposalAsync.request(quoteId));
  const quoteConfig: ProposalConfig = yield select(
    (state: RootState) => state.app.appConfig.proposal
  );
  try {
    const quote: Proposal = yield call(api.proposal.loadProposal, quoteId, quoteConfig);
    yield put(actions.loadProposalAsync.success(quote));
    return quote;
  } catch (err) {
    yield put(
      actions.loadProposalAsync.failure({
        message: t('error::Error loading quote'),
        exception: err,
      })
    );
  }
}

export function* loadApproval(approvalId: string) {
  if (!approvalId) {
    return;
  }
  yield put(actions.loadApprovalAsync.request(approvalId));
  const approvalConfig: ApprovalConfig = yield select(
    (state: RootState) => state.app.appConfig.approval
  );
  try {
    const approval: Approval = yield call(api.approval.loadApproval, approvalId, approvalConfig);
    yield put(actions.loadApprovalAsync.success(approval));
    return approval;
  } catch (err) {
    yield put(
      actions.loadApprovalAsync.failure({
        message: t('error::Error loading approval'),
        exception: err,
      })
    );
  }
}

export function* createLineItems(request: CreateLineItemsRequest) {
  yield put(actions.createLineItemsAsync.request(request));
  try {
    const quoteConfig: ProposalConfig = yield select(
      (state: RootState) => state.app.appConfig.proposal
    );
    const quote: Proposal = yield call(api.proposal.createLineItems, request, quoteConfig);
    yield put(actions.createLineItemsAsync.success(quote));
    request.lineItems.forEach(lineItem =>
      loggerService.log({
        name: `Create Line Items - product(s): '${oc(lineItem).productIdentifier.productId()}'`,
      })
    );

    return quote;
  } catch (err) {
    yield put(
      actions.createLineItemsAsync.failure({
        message: t('error::Error creating line items.'),
        exception: err,
      })
    );
  }
}

export function* deleteLineItem(request: DeleteLineItemRequest) {
  yield put(actions.deleteLineItemAsync.request(request));
  try {
    const proposal: Proposal = yield select((state: RootState) =>
      getProposal(state, request.proposalId)
    );
    const deletedProduct = proposal.lineItems.find(lineItem => lineItem.id === request.lineItemId);
    const quoteConfig: ProposalConfig = yield select(
      (state: RootState) => state.app.appConfig.proposal
    );
    const quote: Proposal = yield call(api.proposal.deleteLineItem, request, quoteConfig);
    yield put(actions.deleteLineItemAsync.success(quote));
    const deletedProductIdentifier = deletedProduct && getProductIdentifier(deletedProduct);
    loggerService.log({
      name: `Delete Line Item - product: '${oc(deletedProductIdentifier).productId()}' was deleted`,
    });
    return quote;
  } catch (err) {
    yield put(
      actions.deleteLineItemAsync.failure({
        message: t('error::Error deleting line item.'),
        exception: err,
      })
    );
  }
}

export function* deleteLineItems(request: DeleteLineItemsRequest) {
  yield put(actions.deleteLineItemsAsync.request(request));
  try {
    const proposal: Proposal = yield select((state: RootState) =>
      getProposal(state, request.proposalId)
    );
    const deletedProducts = proposal.lineItems.filter(lineItem =>
      request.lineItemIds.includes(lineItem.id)
    );
    const hasSAPTerm = deletedProducts.some(
      (lineItem: LineItem) => lineItem.productIdentifier.productType === 'SCPCommitmentToConsume'
    );
    const quoteConfig: ProposalConfig = yield select(
      (state: RootState) => state.app.appConfig.proposal
    );
    const quote: Proposal = yield call(api.proposal.deleteLineItems, request, quoteConfig);
    yield put(actions.deleteLineItemsAsync.success(quote));
    const deletedProductIdentifiers = deletedProducts
      .map(deletedProduct => oc(getProductIdentifier(deletedProduct)).productId())
      .filter(x => x);
    loggerService.log({
      name: `Delete Line Items - products ${deletedProductIdentifiers.join(', ')} deleted`,
    });
    if (hasSAPTerm) {
      const quoteToUpdate = cloneProposal(quote);
      delete quoteToUpdate.header.sapReferenceData;
      yield put(actions.updateProposalAsync.request(buildProposalRequest(quoteToUpdate)));
    }
    return quote;
  } catch (err) {
    yield put(
      actions.deleteLineItemsAsync.failure({
        message: t('error::Error deleting line items.'),
        exception: err,
      })
    );
  }
}

export function* updateQuote(request: ProposalUpdateRequest) {
  yield put(actions.updateQuoteAsync.request(request));
  try {
    const quoteConfig: ProposalConfig = yield select(
      (state: RootState) => state.app.appConfig.proposal
    );
    const quote: Proposal = yield call(api.proposal.updateProposal, request, quoteConfig);
    yield put(actions.updateQuoteAsync.success(quote));
    return quote;
  } catch (err) {
    yield put(
      actions.updateQuoteAsync.failure({
        message: t('error::Error updating quote.'),
        exception: err,
      })
    );
  }
}

export function* patchQuoteHeader(commands: PatchCommand[], quote: Proposal) {
  const request: ProposalHeaderPatchRequest = { proposalId: quote.id, etag: quote.etag, commands };
  yield put(actions.patchQuoteHeaderAsync.request(request));
  try {
    const quoteConfig: ProposalConfig = yield select(
      (state: RootState) => state.app.appConfig.proposal
    );
    if (
      oc(quote).header.assignedToGroup() === UserGroup.Field &&
      oc(quote).header.status() !== ProposalStatus.Submitted
    ) {
      const preApprovalCommand: PatchCommand = {
        op: 'replace',
        path: '/IsApprovalPreCheckRequired',
        value: true,
      };
      commands.push(preApprovalCommand);
    }
    const response: ProposalHeaderResponse = yield call(
      api.proposal.patchProposalHeader,
      request,
      quoteConfig
    );
    quote = {
      ...quote,
      etag: response.etag,
      header: response.header,
    };
    yield put(actions.patchQuoteHeaderAsync.success(quote));
    return quote;
  } catch (err) {
    yield put(
      actions.patchQuoteHeaderAsync.failure({
        message: t('error::Error patching quote header.'),
        exception: err,
      })
    );
    throw err;
  }
}

/**
 * Post quote action to givien quote object.
 *
 * @export
 * @param {ProposalActionBody} action
 * @param {Proposal} quote
 * @param {boolean} [useApprovalTestHeaderScenarios] overrides approval process
 * @returns updated quote object with new etag
 */
export function* singleQuoteAction(
  action: ProposalActionBody,
  quote: Proposal,
  useApprovalTestHeaderScenarios?: boolean
) {
  const request: ProposalActionRequest = {
    proposalId: quote.id,
    body: action,
    etag: quote.etag,
  };

  yield put(actions.performProposalActionAsync.request(request));
  const quoteConfig: ProposalConfig = yield select(
    (state: RootState) => state.app.appConfig.proposal
  );
  const user: User = yield select(getUser);

  try {
    const quote: Proposal = yield call(
      api.proposal.performProposalAction,
      request,
      { ...quoteConfig, useApprovalTestHeaderScenarios },
      user
    );
    yield put(actions.performProposalActionAsync.success(quote));
    return quote;
  } catch (err) {
    yield put(
      actions.performProposalActionAsync.failure({
        message: t('error::Error performing quote action: {action}', {
          action: request.body.action,
        }),
        exception: err,
      })
    );
    throw err;
  }
}

export function* loadTenantNamesFromAccount(accountId: string) {
  const account: Account | undefined = yield call(loadAccount, accountId);
  if (account) {
    yield call(loadTenantNames, account.externalIds);
    yield call(loadTenantAdmins, account.externalIds);
  }
}

export function* multipleQuoteActions(actionList: ProposalActionBody[], quote: Proposal) {
  if (!actionList.length) {
    return quote;
  } else if (actionList.length === 1) {
    quote = yield call(singleQuoteAction, actionList[0], quote);
    return quote;
  }
  const request: MultipleProposalActionRequest = {
    proposalId: quote.id,
    bodies: actionList,
    etag: quote.etag,
  };
  yield put(actions.performMultipleProposalActionAsync.request(request));
  const quoteConfig: ProposalConfig = yield select(
    (state: RootState) => state.app.appConfig.proposal
  );
  const user: User = yield select(getUser);
  try {
    const quote: Proposal = yield call(
      api.proposal.performMultipleProposalActions,
      request,
      quoteConfig,
      user
    );
    yield put(actions.performMultipleProposalActionAsync.success(quote));
    return quote;
  } catch (err) {
    yield put(
      actions.performMultipleProposalActionAsync.failure({
        message: t('error::Error performing quote actions: {actions}', {
          actions: request.bodies,
        }),
        exception: err,
      })
    );
  }
}

export function* loadPurchaseRecord(purchaseRecordId: string) {
  const fromState: string = yield select(
    (state: RootState) => state.proposal.purchaseSummary[purchaseRecordId]
  );
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadPurchaseRecordAsync.request(purchaseRecordId));
  try {
    const purchaseConfig: PurchaseConfig = yield select(
      (state: RootState) => state.app.appConfig.purchase
    );
    const response: PurchaseRecordSummary = yield call(
      api.purchase.getPurchaseRecord,
      purchaseRecordId,
      purchaseConfig
    );
    yield put(actions.loadPurchaseRecordAsync.success(response));
  } catch (err) {
    yield put(
      actions.loadPurchaseRecordAsync.failure({
        message: t('error::Error loading purchase record.'),
        exception: err,
      })
    );
  }
}

export function* loadCreditInfo(creditLookupRequest: CreditLookupRequest) {
  const fromState: CreditInfo = yield select(getCreditInfo);
  if (fromState && fromState.reasons.currency === creditLookupRequest.currency) {
    return fromState;
  }
  yield put(actions.loadCreditInfoAsync.request(creditLookupRequest));
  const creditServiceConfig: CreditConfig = yield select(
    (state: RootState) => state.app.appConfig.credit
  );
  try {
    const creditInfo: CreditInfo = yield call(
      api.credit.loadCreditInfo,
      creditLookupRequest,
      creditServiceConfig
    );
    yield put(
      actions.loadCreditInfoAsync.success({ creditInfo, currency: creditLookupRequest.currency })
    );
    return creditInfo;
  } catch (err) {
    yield put(
      actions.loadCreditInfoAsync.failure({
        message: t('error::Error loading credit info'),
        exception: err,
      })
    );
    // Status code 404 means Credit Information is not available.
    if (err.response.status === 404) {
      return null;
    }
  }
}
