import { ThemeProps } from 'styles';

export const productListQuantityStyles = (theme: ThemeProps) => {
  return {
    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    numberInput: {
      width: 50,
      textAlign: 'center',
      border: 'none',
      color: theme.palette.text,
      borderBottom: `1px solid ${theme.palette.text}`,
      background: theme.palette.transparent,
      '&:focus': {
        outline: 'none',
        border: `1px solid ${theme.palette.text}`,
      },
      '&::-webkit-inner-spin-button,&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      cursor: 'pointer',
    },
  };
};
