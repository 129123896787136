import { LinkAtom } from 'components/atoms';
import { ILinkStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import { FontSizes } from 'styles';

type ActionType = HTMLAnchorElement | HTMLElement | HTMLButtonElement;

export interface LinkProps {
  addClass?: string;
  ariaLabel?: string;
  displayText?: string;
  href?: string;
  id?: string;
  iconLeft?: boolean;
  target?: string;
  disabled?: boolean;
  iconName?: string;
  email?: string;
  emailSubject?: string;
  size?: keyof FontSizes;
  title?: string;
  dataAutomationId?: string;
  styles?: Partial<ILinkStyles>;
  onClick?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
  style?: React.CSSProperties | undefined;
}

const LinkInternalUnstyled: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <LinkAtom
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      displayText={props.displayText}
      href={props.href}
      iconName={props.iconName}
      id={props.id}
      target={props.target}
    />
  );
};
const LinkExternalUnstyled: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <LinkAtom
      addClass={props.addClass}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      displayText={props.displayText || props.href}
      // see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#Attributes for the reason of rel attribute
      href={props.href}
      iconName="OpenInNewWindow"
      id={props.id}
      rel="noreferrer noopener"
      size={props.size}
      style={props.style}
      target="_blank"
      onClick={props.onClick}
    />
  );
};
const LinkEmailUnstyled: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <LinkAtom
      addClass={props.addClass}
      disabled={props.disabled}
      displayText={props.displayText || props.email}
      email={props.email}
      emailSubject={props.emailSubject || ''}
      iconLeft={props.iconLeft}
      iconName={props.iconName}
      id={props.id}
      size={props.size}
      onClick={props.onClick}
    />
  );
};
const LinkButtonUnstyled: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <LinkAtom
      addClass={props.addClass}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      displayText={props.displayText}
      iconLeft={props.iconLeft}
      iconName={props.iconName}
      id={props.id}
      size={props.size}
      styles={props.styles}
      title={props.title}
      onClick={props.onClick}
    />
  );
};

export {
  LinkInternalUnstyled as LinkInternal,
  LinkExternalUnstyled as LinkExternal,
  LinkEmailUnstyled as LinkEmail,
  LinkButtonUnstyled as LinkButton,
};
