import 'tslib';
import './index.css';
import 'i18n';

import { meplaHistory } from 'createHistory';
import { getConfigByEnvironment } from 'features/app/config/configurations';
import { endpoints } from 'services/apollo/config';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import loggerService from 'services/logger-service';

import { AjaxPlugin } from '@microsoft/applicationinsights-dependencies-js';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

const reactPlugin = new ReactPlugin();
const ajaxPlugin = new AjaxPlugin();

const gqlBackendUrl = endpoints[getConfigByEnvironment().apollo.environment];

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getConfigByEnvironment().appInsightsKey,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    enableResponseHeaderTracking: true,
    enableRequestHeaderTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { meplaHistory },
      [ajaxPlugin.identifier]: { enableHeaderTracking: true },
    },
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    correlationHeaderDomains: [new URL(gqlBackendUrl).hostname],
  },
});

appInsights.loadAppInsights();

// Add telemetry initializer to remove auth token from request header tracking
appInsights.appInsights.addTelemetryInitializer(envelope => {
  if (envelope.baseType === 'RemoteDependencyData') {
    if (
      envelope.baseData &&
      envelope.baseData.properties &&
      envelope.baseData.properties.requestHeaders &&
      envelope.baseData.properties.requestHeaders.authorization
    ) {
      envelope.baseData.properties.requestHeaders.authorization = undefined;
    }
    if (
      envelope.baseData &&
      envelope.baseData.properties &&
      envelope.baseData.properties.requestHeaders &&
      envelope.baseData.properties.requestHeaders.idtoken
    ) {
      envelope.baseData.properties.requestHeaders.idtoken = undefined;
    }
  }
});

class TelemetryWrapper extends React.Component<{}> {
  public constructor(props: {}) {
    super(props);
  }
  public render() {
    return (
      <Router history={meplaHistory}>
        <App />
      </Router>
    );
  }
}

// Log current page and wire up for future page changes.
loggerService.locationChange(meplaHistory.location);
meplaHistory.listen(location => loggerService.locationChange(location));

const AppInsightsRoot = withAITracking(reactPlugin, TelemetryWrapper, 'AppInsightsRoot');

ReactDOM.render(<AppInsightsRoot />, document.getElementById('root'));

// Begin sending telemetery once appInsights is initialized.
loggerService.init(appInsights);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
