import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import { routes } from 'routes';
import WorkAreaLayout from 'layouts/WorkArea';
import Product from './Product';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params> & WithTranslation;

const Proposal: React.FC<Props> = (props: Props) => {
  const catalogId = props.match.params.id;

  return (
    <WorkAreaLayout name="Catalog">
      <Switch>
        <Route component={Product} exact path={routes.catalog.root} />
        <Route render={() => <Redirect to={routes.catalog.forId(catalogId)} />} />
      </Switch>
    </WorkAreaLayout>
  );
};

export default withTranslation('catalog')(Proposal);
