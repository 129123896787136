import { ShimmerForBackgroundStandout } from 'components/ions';
import { TextBody, TextBodySmall, TextWatermarkSmall } from 'components/ions/Text';
import { mergeClassNames } from 'components/utilities';
import {
  FieldType,
  formatRegionApollo,
  getAddressConfiguration,
} from 'components/utilities/address';
import { Address } from 'generated/graphql';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import { styles } from './Organization.styles';

export interface OrganizationProps {
  headline?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: any;
  addClass?: string;
  addCompanyNameClass?: string;
  addContainerClass?: string;
  addTradeNameClass?: string;
  address: Address;
  dataAutomationId?: string;
  id?: string;
  loading?: boolean;
  maxWidth?: number;
  organizationName?: string;
  showAccountName?: boolean;
  showCompanyName?: boolean;
  tradeName?: string | null;
  watermark?: string;
}

type Props = OrganizationProps & WithStyles<typeof styles>;

const renderText = (text?: string | null, addClass?: string, dataAutomationId?: string) => {
  if (text) {
    return (
      <TextBodySmall addClass={addClass} dataAutomationId={dataAutomationId} title={text}>
        {text}
      </TextBodySmall>
    );
  }
};

const renderTextLarge = (text?: string | null, addClass?: string) => {
  if (text) {
    return (
      <TextBody addClass={addClass} title={text}>
        {text}
      </TextBody>
    );
  }
};

export const getAddressLine4 = (address: Address) => {
  const addressConfig = getAddressConfiguration(address.country);

  let addressLine4: string[] = [];

  addressConfig.fieldGroup.forEach(fieldType => {
    if (fieldType === FieldType.City && address.city) {
      addressLine4.push(address.city);
    } else if (fieldType === FieldType.Region && addressConfig.region && address.region) {
      const region = formatRegionApollo(address);
      if (region) {
        addressLine4.push(region);
      }
    } else if (fieldType === FieldType.PostalCode && address.postalCode) {
      addressLine4.push(address.postalCode);
    }
  });

  return addressLine4.join(', ');
};

const OrganizationUnstyled: React.FC<Props> = props => {
  const { address, tradeName, classes, id, action, watermark, addClass, addContainerClass } = props;
  const addressLine4 = getAddressLine4(address);
  const textClasses = mergeClassNames([classes.addressText, addClass]);
  const addressContainerClasses = mergeClassNames([classes.addressContainer, addContainerClass]);
  const companyNameClasses = mergeClassNames([
    textClasses,
    classes.companyName,
    addClass,
    props.addCompanyNameClass,
  ]);
  const organizationNameClasses = mergeClassNames([
    textClasses,
    classes.organizationName,
    addClass,
  ]);

  const country = oc(address).marketInfo.display() || address.country;

  const addressDisplay: JSX.Element =
    props.loading || !props.address ? (
      <>
        <ShimmerForBackgroundStandout className={classes.shimmer} width="75%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="70%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="95%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="45%" />
      </>
    ) : (
      <>
        {renderText(address.addressLine1, textClasses, 'addressLine1')}
        {renderText(address.addressLine2, textClasses, 'addressLine2')}
        {renderText(address.addressLine3, textClasses, 'addressLine3')}
        {renderText(addressLine4, textClasses, 'addressLine4')}
        {renderText(country, textClasses, 'country')}
      </>
    );
  const display: JSX.Element = (
    <div>
      {props.showCompanyName &&
        address.companyName &&
        renderTextLarge(address.companyName, companyNameClasses)}
      {props.organizationName && renderTextLarge(props.organizationName, organizationNameClasses)}
      {tradeName && (
        <TextBodySmall
          addClass={mergeClassNames([classes.tradeName, props.addTradeNameClass])}
          title={tradeName}
        >{`dba ${tradeName}`}</TextBodySmall>
      )}
      {addressDisplay}
    </div>
  );

  // TODO: refactor component as it is no longer just an address component
  return (
    <div className={addressContainerClasses} data-automation-id={props.dataAutomationId} id={id}>
      {props.showAccountName && props.headline && renderText(props.headline, classes.headline)}
      {display}
      {renderText(action)}
      {watermark && (
        <TextWatermarkSmall addClass={classes.watermark}>{watermark}</TextWatermarkSmall>
      )}
    </div>
  );
};

export const Organization = withStyles(styles)(OrganizationUnstyled) as React.FC<OrganizationProps>;
