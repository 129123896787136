import { CommandButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities/mergeClassNames';
import {
  IButtonStyles,
  IContextualMenuItemStyles,
  IContextualMenuStyles,
  IMenuItemStyles,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { buildButtonMenu, ButtonMenuProps, ButtonSharedProps } from '../ButtonSharedProps';
import { iconMenuButtonStyles } from './IconMenuButton.styles';

/**
 * @property {string} menuId required to determine menu position respective to the button
 */
export interface IconMenuButtonProps extends ButtonSharedProps {
  buttonStyles?: Partial<IButtonStyles>;
  menuProps?: ButtonMenuProps[];
  removeMenuMargin?: boolean;
  menuId?: string;
  onMenuDismissed?: () => void;
  onMenuOpened?: () => void;
}

type Props = IconMenuButtonProps & WithStyles<typeof iconMenuButtonStyles>;

const IconMenuButtonUnstyled: React.FC<Props> = (props: Props) => {
  const buttonStyle: Partial<IButtonStyles> = {
    rootExpanded: props.classes.commandExpanded,
    ...props.buttonStyles,
  };
  const menuItemStyle: Partial<IContextualMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  const dropdownStyles: Partial<IContextualMenuStyles> = {
    root: props.classes.menuRoot,
    container: props.classes.menuList,
    subComponentStyles: {
      callout: {
        root: props.classes.calloutRoot,
        calloutMain: props.classes.calloutMain,
      },
      menuItem: menuItemStyle,
    },
  };
  const menuItemStyles: Partial<IMenuItemStyles> = {
    root: props.classes.menuItem,
  };
  return (
    <CommandButtonAtom
      addClass={mergeClassNames([
        props.classes.command,
        props.classes.iconButton,
        props.classes.chevron,
        props.addClass,
      ])}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: props.iconName }}
      id={props.id}
      menuProps={
        props.menuProps && props.menuId
          ? buildButtonMenu(
              props.menuProps,
              dropdownStyles,
              menuItemStyles,
              props.menuId,
              props.onMenuDismissed,
              props.onMenuOpened
            )
          : undefined
      }
      styles={buttonStyle}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
    />
  );
};

export const IconMenuButton = withStyles(iconMenuButtonStyles)(IconMenuButtonUnstyled) as React.FC<
  IconMenuButtonProps
>;
