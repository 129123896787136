import { ThemeProps } from 'styles';
import { buttonSharedStyles } from '../shared.styles';

export const borderlessButtonStyles = (theme: ThemeProps) => {
  return {
    borderless: {
      ...buttonSharedStyles(theme).secondary,
      borderStyle: 'none',
    },
  };
};
