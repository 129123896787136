import { Dialog, PrimaryButton } from 'components';
import { SearchResultProduct } from 'features/catalog';
import { Dimensions, Fail, Processing } from 'features/components/dialogs';
import {
  GET_QUOTERECOMMENDATIONS,
  GET_RECOMMENDATIONS,
  UPDATE_FAVORITEGROUP,
} from 'features-apollo/quote/components/queries';
import {
  BaseRecommendationAction,
  CatalogContext,
  Query,
  ProductRecommendationAction,
  RecommendationGroup,
  RecommendationSource,
} from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductGroup } from 'services/user-preferences/types';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';
import { useMutation } from '@apollo/react-hooks';

import { GroupNameField } from './GroupNameField';

const dimensions: Dimensions = {
  height: 220,
  width: 424,
};

export interface CreateProductGroupDialogProps {
  elementIdToFocusOnDismiss?: string;
  moveFrom?: string;
  product: SearchResultProduct;
  quoteId?: string;
  favoritesProductGroups?: Record<string, ProductGroup>;
  catalogContext?: CatalogContext;
}

type Props = CreateProductGroupDialogProps;

export const CreateProductGroupDialog: React.FC<Props> = props => {
  const { moveFrom, product } = props;
  const { t } = useTranslation();
  const [creatingGroup, setCreatingGroup] = React.useState<boolean>(false);
  const [groupName, setGroupName] = React.useState<string | undefined>();
  const context = React.useContext(DialogContext);
  const [isProductInProductGroup, setIsProductInProductGroup] = React.useState<boolean | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState<boolean | undefined>();

  const onClose = () => {
    context.closeDialog();
    if (props.elementIdToFocusOnDismiss) {
      const element = document.getElementById(props.elementIdToFocusOnDismiss);
      element && element.focus();
    }
  };

  const [addProductToFavorites, { error, loading }] = useMutation(UPDATE_FAVORITEGROUP, {
    onCompleted: data => {
      if (data && data.updateFavoriteGroup) {
        const group = data.updateFavoriteGroup.find(
          (group: { name: string }) => group.name === groupName
        );
        setIsProductInProductGroup(
          group.items.some(
            (item: { product: { id: string } }) => item.product.id === product.productId
          )
        );
      }
    },
  });

  React.useEffect(() => {
    if (loading && !isProductInProductGroup) {
      setIsLoading(true);
    } else if (!loading && isProductInProductGroup) {
      setIsLoading(false);
    }
  }, [loading, isProductInProductGroup]);

  const buildOptimisticResponse = (product: SearchResultProduct, name: string) => {
    return {
      updateFavoriteGroup: [
        {
          source: RecommendationSource.UserPreferences,
          items: [
            {
              meta: null,
              product: {
                id: product.productId,
                title: product.productName,
                productType: product.productIdentifier.productType,
                maxQuantityOnQuote: null,
                __typename: 'Product',
              },
              __typename: 'ProductRecommendationAction',
            },
          ],
          name: name,
          type: 'Product',
          __typename: 'RecommendationGroup',
        },
      ],
      __typename: 'Mutation',
    };
  };

  const isEqual = (item: BaseRecommendationAction, product: SearchResultProduct) => {
    const recommendationItem = item as ProductRecommendationAction;
    return recommendationItem.product.id === product.productId;
  };

  //TODO: Convert catalogContext to proposalId when changes are made to graphql
  const createGroup = () => {
    if (groupName) {
      addProductToFavorites({
        variables: {
          input: {
            productId: product.productId,
            addToGroup: groupName,
            removeFromGroup: moveFrom,
            catalogContext: props.catalogContext,
          },
        },
        optimisticResponse: buildOptimisticResponse(product, groupName),
        update: (useApolloClient, { data: { updateFavoriteGroup } }) => {
          // Read from cached
          const cached = props.quoteId
            ? useApolloClient.readQuery<Query>({
                query: GET_QUOTERECOMMENDATIONS,
                variables: { id: props.quoteId, input: props.catalogContext },
              })
            : useApolloClient.readQuery<Query>({
                query: GET_RECOMMENDATIONS,
                variables: { input: props.catalogContext },
              });

          // Create update object
          if (props.quoteId) {
            if (cached && cached.getQuote && cached.getQuote.recommendations) {
              let cachedRecFav = cached.getQuote.recommendations.find(
                rec => rec.name === groupName
              );
              if (!cachedRecFav) {
                cached.getQuote.recommendations.push(
                  updateFavoriteGroup.find((rec: RecommendationGroup) => rec.name === groupName)
                );
              }
              let cachedRecs = cached.getQuote.recommendations;
              let cachedRecsFav = cachedRecs.find(
                (rec: RecommendationGroup) => rec.name === moveFrom
              );
              if (cachedRecsFav) {
                const index = cachedRecsFav.items.findIndex(item => isEqual(item, product));
                cachedRecsFav.items.splice(index, 1);
              }
            }
          } else {
            if (cached && cached.getRecommendations) {
              let cachedRecFav = cached.getRecommendations.find(rec => rec.name === groupName);
              if (!cachedRecFav) {
                cached.getRecommendations.push(
                  updateFavoriteGroup.find((rec: RecommendationGroup) => rec.name === groupName)
                );
              }
              let cachedRecs = cached.getRecommendations;
              let cachedRecsFav = cachedRecs.find(
                (rec: RecommendationGroup) => rec.name === moveFrom
              );
              if (cachedRecsFav) {
                const index = cachedRecsFav.items.findIndex(item => isEqual(item, product));
                cachedRecsFav.items.splice(index, 1);
              }
            }
          }

          // Write updated clone object to cache
          props.quoteId
            ? useApolloClient.writeQuery({
                query: GET_QUOTERECOMMENDATIONS,
                variables: { id: props.quoteId, input: props.catalogContext },
                data: cached,
              })
            : useApolloClient.readQuery<Query>({
                query: GET_RECOMMENDATIONS,
                variables: { input: props.catalogContext },
              });
          setIsLoading(false);
          onClose();
        },
      });
      setCreatingGroup(true);
    }
  };

  // #region adding product states
  if (groupName && creatingGroup) {
    if (isLoading) {
      return <Processing {...dimensions} message1={t('Creating group')} />;
    } else if (error) {
      return <Fail {...dimensions} closeDialog={onClose} message={t('Unable to create group')} />;
    } else if (isProductInProductGroup) {
      onClose();
      return null;
    }
  }
  // #endregion

  return (
    <Dialog
      {...dimensions}
      closeDialog={onClose}
      footerButtons={
        <PrimaryButton
          dataAutomationId="createGroupingButton"
          disabled={!groupName}
          text={t('Create grouping')}
          onClick={createGroup}
        />
      }
      title={t('Name product grouping')}
    >
      <GroupNameField
        favoritesProductGroups={props.favoritesProductGroups}
        onChange={(userInput?: string) => {
          setGroupName(userInput);
        }}
        onEnterKeyDown={createGroup}
      />
    </Dialog>
  );
};

export const openCreateProductGroupDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: CreateProductGroupDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <CreateProductGroupDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
