import gql from 'graphql-tag';

export const AddressFieldsAccount = gql`
  fragment addressFieldsAccount on Address {
    addressLine1
    addressLine2
    addressLine3
    companyName
    email
    region
    postalCode
    city
    country
  }
`;

export const LegalEntityFragment = gql`
  fragment legalEntityFragment on LegalEntity {
    address {
      ...addressFieldsAccount
    }
    businessLocationId
    tradeName
  }
  ${AddressFieldsAccount}
`;

export const TestHeaderFragment = gql`
  fragment testHeaderFragment on TestHeader {
    scenarios
    contact
    retention
  }
`;

export const OrganizationFragment = gql`
  fragment organizationFragment on Organization {
    id
    accountId
    audience
    createdTimestamp
    cultureInfo {
      culture
      language
    }
    description
    eligibleForAccountPricing
    externalAccountReference
    legalEntity {
      ...legalEntityFragment
    }
    organizationType
    state
    updatedTimestamp
  }
  ${LegalEntityFragment}
`;
