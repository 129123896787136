import {
  bulkDiscountApply,
  closeConfigCard,
  discountApply,
  updateProposalAsync,
} from 'features/proposal/actions';
import {
  getActiveProposal,
  getSelectedLineItemsWithSwitchedDFD,
  isProposalReadOnly,
} from 'features/proposal/selectors';
import { displayInDayFormatAndUseMarketTimezone } from 'features/proposal/utils';
import {
  DirectionalHint,
  IChoiceGroupOption,
  IComboBox,
  IComboBoxOption,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import * as actions from './actions';
import * as ceilingSelectors from './ceiling.selectors';
import * as commonSelectors from './common.selectors';
import { DiscountCard } from './DiscountCard';
import {
  applyBulkFutureDiscount,
  applyFutureDiscount,
  applyOneTimeDiscount,
  applyPriceGuaranteeDiscount,
  buildBulkDiscountTypeChoices,
  buildDiscountTypeChoices,
  bulkRemoveDiscounts,
  isApplyButtonDisabled,
  removeDiscount,
} from './DiscountCardBusinessLogic';
import {
  CeilingSectionOptions,
  CeilingSectionProps,
  FutureSectionOptions,
  FutureSectionProps,
} from './DiscountCardSections';
import * as futureSelectors from './future.selectors';
import { reducer } from './reducer';
import { getInitialState } from './reducer.utils';
import { DiscountCardContainerState, DiscountCardProps, DiscountType } from './types';

export const generateCeilingProps = (
  ceilingOptions: CeilingSectionOptions,
  onPriceCeilingDateAdded: (date: Date) => void,
  onPriceCeilingSelectionChanged: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => void,
  onStartDateAdded: (date: Date) => void,
  onStartSelectionChanged: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void,
  onEndDateAdded: (date: Date) => void,
  onEndSelectionChanged: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void,
  disabled: boolean
): CeilingSectionProps => {
  return {
    disabled,
    dispatches: {
      onPriceCeilingDateAdded,
      onPriceCeilingSelectionChanged,
      onStartDateAdded,
      onStartSelectionChanged,
      onEndDateAdded,
      onEndSelectionChanged,
    },
    options: ceilingOptions,
  };
};

export const generateFutureProps = (
  futureOptions: FutureSectionOptions,
  onStartDateAdded: (date: Date) => void,
  onStartSelectionChanged: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void,
  onEndDateAdded: (date: Date) => void,
  onEndSelectionChanged: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void,
  onMeterSelectionChanged: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void,
  disabled: boolean
): FutureSectionProps => {
  return {
    disabled,
    dispatches: {
      onStartDateAdded,
      onStartSelectionChanged,
      onEndDateAdded,
      onEndSelectionChanged,
      onMeterSelectionChanged,
    },
    options: futureOptions,
  };
};

export const applySelectors = (
  state: DiscountCardContainerState
): { futureOptions: FutureSectionOptions; ceilingOptions: CeilingSectionOptions } => {
  return {
    futureOptions: {
      startOptions: futureSelectors.startOptions(state),
      startDates: futureSelectors.startDates(state),
      endOptions: futureSelectors.endOptions(state),
      endCalendarEnabled: futureSelectors.endCalendarEnabled(state),
      endDates: futureSelectors.endDates(state),
      isDayPickersVisible: commonSelectors.isDayPickersVisible(state),
      metersOptions: futureSelectors.meterOptions(state),
      showMeters: futureSelectors.showMeters(state),
    },
    ceilingOptions: {
      priceCeilingOptions: ceilingSelectors.priceCeilingOptions(state),
      priceCeilingDates: ceilingSelectors.priceCeilingDates(state),
      startOptions: ceilingSelectors.startOptions(state),
      startCalendarEnabled: ceilingSelectors.startCalendarEnabled(state),
      startDayPickerVisible: commonSelectors.isDayPickersVisible(state),
      startDates: ceilingSelectors.startDates(state),
      endOptions: ceilingSelectors.endOptions(state),
      endCalendarEnabled: ceilingSelectors.endCalendarEnabled(state),
      endDayPickerVisible: commonSelectors.isDayPickersVisible(state),
      endDates: ceilingSelectors.endDates(state),
    },
  };
};

export interface DiscountCardContainerProps {
  hydratedProducts: Product[];
  lineItemIds: string[];
  target: React.RefObject<HTMLSpanElement>;
}

export const mapStateToProps = (state: RootState, ownProps: DiscountCardContainerProps) => ({
  lineItems: getSelectedLineItemsWithSwitchedDFD(state, ownProps.lineItemIds),
  proposal: getActiveProposal(state),
  isProposalReadOnly: isProposalReadOnly(state),
});

const dispatchProps = {
  bulkDiscountApply,
  discountApply,
  onUpdateProposalRequest: updateProposalAsync.request,
  onDismiss: closeConfigCard,
};

type Props = DiscountCardContainerProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const DiscountCardContainer: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { hydratedProducts, lineItems, proposal, isProposalReadOnly, lineItemIds, target } = props;

  if (!lineItems || (lineItems && !lineItems.length)) {
    throw new Error(
      `LineItem(s) with id(s) ${lineItemIds.toString()} does not exist on proposal ${oc(
        proposal
      ).id('n/a')}`
    );
  }

  const isBulkDiscount = lineItems && lineItems.length > 1;
  const hydratedProduct = hydratedProducts[0];
  const lineItem = lineItems[0];
  const lineItemId = isBulkDiscount ? 'bulk-discount-card' : lineItemIds[0];
  const telemetryName = isBulkDiscount ? 'Bulk Discount Card ' : 'Discount Card';
  const performanceName = isBulkDiscount ? 'bulk discount card' : 'discount card';

  const shouldDisplayInDayFormatAndUseMarketTimezone = !!(
    hydratedProduct &&
    displayInDayFormatAndUseMarketTimezone(
      hydratedProduct.ProductType,
      lineItem.productIdentifier.action
    )
  );

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      hydratedProducts,
      lineItems,
      proposal,
      shouldDisplayInDayFormatAndUseMarketTimezone,
    },
    getInitialState
  );

  const _performance = performance;
  _performance.mark(`${performanceName} opened`);

  const onChangeDiscountType = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    if (option) {
      if (option.key === 'Future' || option.key === 'Ceiling' || option.key === 'One Time') {
        loggerService.log({ name: `${telemetryName} - discount type '${option.key}' selected` });
        dispatch(actions.updateDiscountType(option.key as DiscountType));
      } else {
        throw new Error(`key is not Future, Ceiling or One Time, it is ${option.key} `);
      }
    }
  };

  const onFutureStartDateAdded = (date: Date) => {
    loggerService.log({ name: `${telemetryName} - future start date added '${date.toString()}'` });
    dispatch(actions.futureStartAdd(date.toString()));
  };

  const onFutureStartSelectionChanged = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (option && typeof option.key === 'string') {
      loggerService.log({ name: `${telemetryName} - future start date selection '${option.key}'` });
      dispatch(actions.futureStartSelect(option.key));
    }
  };

  const onFutureEndDateAdded = (date: Date) => {
    loggerService.log({ name: `${telemetryName} - future end date added '${date.toString()}'` });
    dispatch(actions.futureEndAdd(date.toString()));
  };

  const onFutureEndSelectionChanged = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (option && typeof option.key === 'string') {
      loggerService.log({ name: `${telemetryName} - future end date selection '${option.key}'` });
      dispatch(actions.futureEndSelect(option.key));
    }
  };

  const onFutureMeterSelectionChanged = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (option && typeof option.key === 'string') {
      loggerService.log({ name: `${telemetryName} - meter selection '${option.key}'` });
      dispatch(actions.futureMetersSelect(option.key));
    }
  };

  const onCeilingPriceCeilingDateAdded = (date: Date) => {
    loggerService.log({ name: `${telemetryName} - price ceiling date added '${date.toString()}'` });
    dispatch(actions.ceilingPriceCeilingAdd(date.toString()));
  };

  const onCeilingPriceCeilingSelectionChanged = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (option && typeof option.key === 'string') {
      loggerService.log({ name: `${telemetryName} - price ceiling selection '${option.key}'` });
      dispatch(actions.ceilingPriceCeilingSelect(option.key));
    }
  };

  const onCeilingStartDateAdded = (date: Date) => {
    loggerService.log({
      name: `${telemetryName} - price ceiling start date added '${date.toString()}'`,
    });
    dispatch(actions.ceilingStartAdd(date.toString()));
  };

  const onCeilingStartSelectionChanged = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (option && typeof option.key === 'string') {
      loggerService.log({
        name: `${telemetryName} - price ceiling start date selection '${option.key}'`,
      });
      dispatch(actions.ceilingStartSelect(option.key));
    }
  };

  const onCeilingEndDateAdded = (date: Date) => {
    loggerService.log({
      name: `${telemetryName} - price ceiling end date added '${date.toString()}'`,
    });
    dispatch(actions.ceilingEndAdd(date.toString()));
  };

  const onCeilingEndSelectionChanged = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (option && typeof option.key === 'string') {
      loggerService.log({
        name: `${telemetryName} - price ceiling end date selection '${option.key}'`,
      });
      dispatch(actions.ceilingEndSelect(option.key));
    }
  };

  const onDiscountAmountChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(actions.discountAmountChange(value));
    }
  };

  const onDiscountErrorChange = (value?: string) => {
    if (value !== undefined) {
      dispatch(actions.discountAmountErrorChange(value));
    }
  };

  const onDiscountApply = () => {
    props.onDismiss();
    _performance.mark(`${performanceName} dismissed`);
    _performance.measure(
      `${performanceName} in use`,
      `${performanceName} opened`,
      `${performanceName} dismissed`
    );
    const measure = _performance.getEntriesByType('measure');
    const duration = measure[0].duration;
    const selectedMeterType =
      state.futureShowMeters &&
      state.futureMetersOptions.options.find(
        meter => meter.key === state.futureMetersOptions.selectedKey
      );
    const meterType = selectedMeterType ? selectedMeterType.text : undefined;
    //todo: kaderbez add event capture to telemetry
    loggerService.log({
      name: `${telemetryName} - ${state.discountType} discount was applied`,
      measurements: { duration },
    });
    performance.clearMarks();
    performance.clearMeasures();

    if (state.discountType === 'Future') {
      if (isBulkDiscount) {
        applyBulkFutureDiscount(
          lineItems,
          hydratedProducts,
          proposal,
          shouldDisplayInDayFormatAndUseMarketTimezone,
          props.bulkDiscountApply,
          state.discountAmount,
          state.futureStartOptions.selectedKey,
          state.futureEndOptions.selectedKey,
          state.defaultBulkFutureStart,
          state.defaultBulkFutureEnd
        );

        loggerService.log({
          name: `Bulk Discount Card - ${state.discountType} discount was applied`,
          measurements: { duration },
          properties: {
            totalLineItems: lineItems.length,
            configuredLineItems: state.configuredItemsCount || 0,
            unconfiguredLineItems: lineItems.length - (state.configuredItemsCount || 0),
          },
        });
      } else {
        applyFutureDiscount(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          lineItem!,
          +state.discountAmount,
          hydratedProduct,
          proposal,
          shouldDisplayInDayFormatAndUseMarketTimezone,
          props.discountApply,
          state.futureStartOptions.selectedKey,
          state.futureEndOptions.selectedKey,
          meterType
        );
      }
    } else if (state.discountType === 'One Time') {
      applyOneTimeDiscount(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lineItem!,
        +state.discountAmount,
        hydratedProduct,
        proposal,
        props.onUpdateProposalRequest
      );
    } else if (state.discountType === 'Ceiling') {
      applyPriceGuaranteeDiscount(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lineItem!,
        +state.discountAmount,
        hydratedProduct,
        proposal,
        shouldDisplayInDayFormatAndUseMarketTimezone,
        props.discountApply,
        state.ceilingStartOptions.selectedKey,
        state.ceilingEndOptions.selectedKey,
        state.ceilingPriceCeilingOptions.selectedKey
      );
    }
  };

  const onRemoveDiscount = () => {
    props.onDismiss();
    //todo: kaderbez add event capture to telemetry
    loggerService.log({
      name: `${telemetryName} - ${state.discountType} discount was removed`,
    });
    if (isBulkDiscount) {
      bulkRemoveDiscounts(lineItems, proposal, props.onUpdateProposalRequest);
    } else {
      removeDiscount(lineItem, proposal, props.onUpdateProposalRequest);
    }
  };

  const { futureOptions, ceilingOptions } = applySelectors(state);

  const ceilingProps = generateCeilingProps(
    ceilingOptions,
    onCeilingPriceCeilingDateAdded,
    onCeilingPriceCeilingSelectionChanged,
    onCeilingStartDateAdded,
    onCeilingStartSelectionChanged,
    onCeilingEndDateAdded,
    onCeilingEndSelectionChanged,
    isProposalReadOnly
  );

  const futureProps = generateFutureProps(
    futureOptions,
    onFutureStartDateAdded,
    onFutureStartSelectionChanged,
    onFutureEndDateAdded,
    onFutureEndSelectionChanged,
    onFutureMeterSelectionChanged,
    isProposalReadOnly
  );

  const applyButtonDisabled = isApplyButtonDisabled(
    state.discountAmount,
    state.futureEndOptions.selectedKey,
    state.ceilingEndOptions.selectedKey,
    t('quote::Select a duration'),
    isProposalReadOnly,
    state.discountType,
    isBulkDiscount
  );

  const discountTypeChoices = isBulkDiscount
    ? buildBulkDiscountTypeChoices(isBulkDiscount)
    : buildDiscountTypeChoices(hydratedProduct, lineItem);
  const hasNoRuleType = !lineItem.pricingInstruction || !lineItem.pricingInstruction.ruleType;
  const discountCardProps: DiscountCardProps = {
    target: target,
    lineItemId: lineItemId,
    onDismiss: props.onDismiss,
    ceilingSectionDispatches: ceilingProps.dispatches,
    ceilingSectionOptions: ceilingProps.options,
    applyButtonDisabled,
    discountAmount: state.discountAmount,
    onDiscountErrorChange,
    discountError: state.discountError && t(state.discountError),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    discountType: state.discountType!,
    discountTypeChoices,
    onRemoveDiscount,
    noDiscountExists: hasNoRuleType,
    futureSectionDispatches: futureProps.dispatches,
    futureSectionOptions: futureProps.options,
    initialDiscountAmount: 0,
    onChangeDiscountType,
    onDiscountApply,
    onDiscountAmountChange,
    isReadOnly: isProposalReadOnly,
    directionalHint: isBulkDiscount ? DirectionalHint.leftCenter : DirectionalHint.rightCenter,
    isBulkFutureDiscount: isBulkDiscount,
    showMixedValuesWaterMark: state.hasMixedValues,
  };
  return <DiscountCard {...discountCardProps} />;
};

export const ConnectedDiscountCardContainer = connect(
  mapStateToProps,
  dispatchProps
)(DiscountCardContainer);
