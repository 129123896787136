import { EditLabel } from 'components/molecules/EditLabel/EditLabel';
import { openBillingContactDialog } from 'features-apollo/quote/components/Dialogs/BillingContact';
import { BillingContactInformation } from 'features-apollo/quote/components/Dialogs/OrganizationDialogs/BillingContact';
import { TenantScenario } from 'features-apollo/quote/components/Wizards';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { organizationInformationStyles } from '../OrganizationInformation.styles';

export interface OrganizationInformationContactProps {
  accountId: string;
  organizationId: string;
  organizationEditable: boolean;
  organizationEmail?: string;
  organizationPhoneNumber?: string;
  quoteId?: string;
  /**
   * Defines a custom string per scenario.
   */
  specificScenario?: TenantScenario;
  /**
   * Whether or not phone number and email can be updated.
   */
  editable: boolean;
}

type Props = OrganizationInformationContactProps & WithStyles<typeof organizationInformationStyles>;

const OrganizationInformationContactUnstyled: React.FC<Props> = props => {
  const {
    classes,
    organizationEmail,
    organizationPhoneNumber,
    accountId,
    organizationId,
    quoteId,
  } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const openContactDialog =
    accountId && organizationId && quoteId
      ? () =>
          openBillingContactDialog(context, {
            accountId,
            organizationId,
            quoteId,
            email: organizationEmail,
            phoneNumber: organizationPhoneNumber,
          })
      : undefined;

  let contactLabel: string;
  let hideIcon = !props.editable;
  let displayContactSection =
    props.organizationEditable || (organizationEmail && organizationPhoneNumber);

  switch (props.specificScenario) {
    case TenantScenario.partner:
      contactLabel = t('quote::Partner contact');
      displayContactSection = organizationEmail && organizationPhoneNumber;
      break;
    case TenantScenario.partnerCustomer:
      contactLabel = t('quote::Customer contact');
      break;
    default:
      contactLabel = t('quote::Contact');
      break;
  }

  return displayContactSection ? (
    <div className={classes.customerAddedSection}>
      <EditLabel
        ariaLabel={t('quote::Edit the billing contact information')}
        hideIcon={hideIcon}
        label={contactLabel}
        required={!hideIcon}
        onClick={openContactDialog}
      >
        <BillingContactInformation
          dataAutomationId="addCustomerPropertyContactLink"
          email={organizationEmail}
          phoneNumber={organizationPhoneNumber}
          readOnly={hideIcon}
          onClick={openContactDialog}
        />
      </EditLabel>
    </div>
  ) : null;
};

export const OrganizationInformationContact = withStyles(organizationInformationStyles)(
  OrganizationInformationContactUnstyled
);
