import { SecondaryButton, TextBody } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { WizardDialog } from '../Shared';

interface AccountIndividualsProps {
  individuals?: string[];
  onNextButtonClick: () => void;
  onClose: () => void;
}

const styles = (theme: ThemeProps) => ({
  description: {
    color: theme.palette.textTertiary,
    display: 'block',
    marginBottom: 16,
  },
  individuals: {
    paddingLeft: 16,
    paddingTop: 4,
    '& > *': {
      display: 'block',
    },
  },
});

type Props = AccountIndividualsProps & WithStyles<typeof styles>;

const AccountIndividualsDialogUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { classes } = props;

  const description = (
    <>
      <TextBody addClass={classes.description}>
        {t(
          'quote::The tenant associated with the domain is in use by an existing modern account. The account was created as an artifact of individuals making purchases outside of the corporate sales motions.'
        )}
      </TextBody>
      <TextBody addClass={classes.description}>
        {t(
          'quote::The billing account you are creating will be created in the same account. This will not effect your deal and has the added benefit of giving the corporate relationship you are establishing, visibility into the individual relationships with Microsoft.'
        )}
      </TextBody>
    </>
  );

  const individualsList =
    props.individuals &&
    !!props.individuals.length &&
    props.individuals.map(individual => <TextBody key={individual}>{individual}</TextBody>);

  const individuals = individualsList && (
    <div>
      <TextBody>{t('quote::Individuals who have used the tenant:')}</TextBody>
      <div className={classes.individuals}>{individualsList}</div>
    </div>
  );

  const body = (
    <>
      {description}
      {individuals}
    </>
  );

  const footerButton = [
    <SecondaryButton
      dataAutomationId="accountIndividualsNextButton"
      key="next"
      text={t('quote::Next')}
      onClick={props.onNextButtonClick}
    />,
  ];

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      footer={footerButton}
      headline={t('FYI')}
      isOverviewAvailable={false}
      overviewButtonOnly={false}
      title={t('quote::Modern account found')}
    />
  );
};

export const AccountIndividualsDialog = withStyles(styles)(AccountIndividualsDialogUnstyled);
