import { LinkProps, TextWatermark } from 'components';
import { EventTrap } from 'components/utilities';
import { addSkuTitle } from 'features/proposal/actions';
import {
  ConnectedConfigCardBusinessLogic,
  ConnectedMonetaryCard,
  QualifyingSkuAvailability,
} from 'features/proposal/components';
import { ConnectedCalloutCardLink } from 'features/proposal/components/CalloutCardLink/CalloutCardLink';
import {
  buildConfigureCombinedSummary,
  isMonetary,
  isSAPTerm,
  isSimpleTerm,
} from 'features/proposal/components/ConfigCard/ConfigCardBusinessLogic';
import { FinanceTermsCard } from 'features/proposal/components/FinanceTermsCard/FinanceTermsCard';
import { ProductListLineItem } from 'features/proposal/components/List/ProductList/types';
import { TermListLineItem } from 'features/proposal/components/List/TermList';
import { buildDurationString } from 'features/proposal/components/MonetaryCard/utils';
import {
  calculateColspan,
  ProposalListColumnRenderProps,
  ProposalListRow,
  SelectionProps,
} from 'features/proposal/components/ProposalList';
import { ConnectedSAPConfigCard } from 'features/proposal/components/SAPCard';
import { ServicesConfigCard } from 'features/proposal/components/ServicesConfigCard';
import { SimpleTermsCard } from 'features/proposal/components/SimpleTermsCard/SimpleTermsCard';
import { getTermIdContent } from 'features/proposal/components/SimpleTermsCard/utils';
import { lineItemHasNotificationError } from 'features/proposal/selectors/lineItem';
import { Currency } from 'features/proposal/supported-currencies';
import { CardType, OpenCard, SkuTitleMap } from 'features/proposal/types';
import { isCardOpen, loadSkuTitlesIfNeeded } from 'features/proposal/utils';
import i18next from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import { LineItem } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { termListRowStyles } from './TermListRow.styles';

export type TermListRowProps = {
  terms: Record<string, Product>;
  termListLineItem: TermListLineItem;
  addSkuTitle: (
    qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
    productId: string
  ) => ReturnType<typeof addSkuTitle>;
  currency: Currency;
  columns: ProposalListColumnRenderProps<TermListLineItem>[];
  openCard?: OpenCard;
  lineItem?: LineItem;
  skuTitleMap?: SkuTitleMap;
  ecifEnabled: boolean;
  groupedLineItem?: LineItem;
  productListLineItems?: ProductListLineItem[];
} & SelectionProps;

const mapStateToProps = (state: RootState, ownProps: TermListRowProps) => {
  return {
    hasNotificationError:
      ownProps.lineItem && lineItemHasNotificationError(state, ownProps.lineItem.id),
  };
};

type Props = TermListRowProps &
  WithStyles<typeof termListRowStyles> &
  ReturnType<typeof mapStateToProps>;

const ecifProductId = '0RDCKN523H1P';

const mapItems = (
  columns: ProposalListColumnRenderProps<TermListLineItem>[],
  termLineItem: TermListLineItem
) => {
  return columns.map(column => {
    return (
      <div
        data-automation-id={`fr-${termLineItem.name}-${column.id}`}
        key={`fr-${termLineItem.id}-${column.id}`}
        style={{
          minWidth: column.width,
          maxWidth: column.width,
          paddingLeft: 17,
          paddingRight: 17,
          justifyContent: column.alignText,
        }}
      >
        {column.onRender(termLineItem, column)}
      </div>
    );
  });
};

const renderCard = (
  term: Product,
  termLineItem: TermListLineItem,
  watermarkText: string,
  target: React.RefObject<HTMLSpanElement>,
  t: i18next.TFunction,
  currency: Currency,
  classes: { watermark: string },
  ecifEnabled: boolean,
  openCard?: OpenCard,
  lineItem?: LineItem,
  skuTitleMap?: SkuTitleMap,
  groupedLineItem?: LineItem,
  productListLineItems?: ProductListLineItem[]
) => {
  let card;
  const configCardActive = lineItem && isCardOpen(lineItem.id, CardType.Config, openCard);

  if (term && lineItem) {
    if (isSAPTerm(term)) {
      const amount = oc(lineItem).purchaseInstruction.purchaseTermUnits();
      const goodFor = oc(lineItem).duration();

      const linkProps: LinkProps = {
        displayText:
          amount && goodFor
            ? t(`quote::{{amount}} {{currency}}, {{duration}}`, {
                amount,
                currency,
                duration: buildDurationString(goodFor, t('quote::year(s)'), t('quote::month(s)')),
              })
            : t('quote::configure'),
        dataAutomationId: 'configureSAPTerm',
      };
      card = (
        <div>
          {configCardActive && (
            <ConnectedSAPConfigCard
              hydratedProduct={term}
              lineItemId={termLineItem.id}
              target={target}
            />
          )}
          <ConnectedCalloutCardLink
            lineItemId={termLineItem.id}
            linkProps={linkProps}
            offset={{ left: 30, top: 10 }}
            reference={target}
            type={CardType.Config}
          />
        </div>
      );
    }
    //TODO: for the first configure this should be oneAskId, we don't have groups yet
    else if (
      ecifEnabled &&
      (termLineItem.ecifGroup || lineItem.productIdentifier.productId === ecifProductId)
    ) {
      //TODO: don't hardcode this
      const linkProps: LinkProps = {
        displayText: t('quote::configure'),
      };
      const productTitle =
        (term.LocalizedProperties.length && term.LocalizedProperties[0].ProductTitle) || '';
      //TODO:Remove mock stuff from here, e.g empty line items
      card = (
        <>
          {configCardActive && (
            <ServicesConfigCard
              headerText={productTitle}
              lineItemId={lineItem.id}
              lineItems={[]}
              target={target}
            />
          )}
          <ConnectedCalloutCardLink
            lineItemId={termLineItem.id}
            linkProps={linkProps}
            maxWidth={500}
            offset={{ left: 30, top: 10 }}
            reference={target}
            type={CardType.Config}
          />
        </>
      );
    } else if (isMonetary(term)) {
      const amount = oc(lineItem).purchaseInstruction.purchaseTermUnits();
      const goodFor = oc(lineItem).duration();

      const linkProps: LinkProps = {
        displayText:
          amount && goodFor
            ? t(`quote::{{amount}} {{currency}}, {{duration}}`, {
                amount,
                currency,
                duration: buildDurationString(goodFor, t('quote::year(s)'), t('quote::month(s)')),
              })
            : t('quote::configure'),
        dataAutomationId: 'configureMonetaryTerm',
      };
      card = (
        <div>
          {configCardActive && (
            <ConnectedMonetaryCard
              hydratedProduct={term}
              lineItemId={termLineItem.id}
              name={termLineItem.name || ''}
              target={target}
            />
          )}
          <ConnectedCalloutCardLink
            lineItemId={termLineItem.id}
            linkProps={linkProps}
            maxWidth={500}
            offset={{ left: 30, top: 10 }}
            reference={target}
            type={CardType.Config}
          />
        </div>
      );
    } else if (term.ProductType.toLowerCase() === 'financing') {
      const relatedIds: string[] = [];
      const marketProperties = oc(term).MarketProperties([]);
      marketProperties.forEach(marketProp => {
        marketProp.RelatedProducts &&
          marketProp.RelatedProducts.forEach(relatedProduct => {
            if (relatedProduct.RelationshipType.toLowerCase() === 'sellableby') {
              relatedIds.push(relatedProduct.RelatedProductId);
            }
          });
      });
      const termIdString = getTermIdContent(lineItem);
      let termIdContent = termIdString || t('quote::configure');
      if (termIdString && groupedLineItem && productListLineItems) {
        const groupedProduct = productListLineItems.find(
          product => product.id === groupedLineItem.id
        );
        if (groupedProduct) {
          termIdContent = `${termIdContent}, ${t('quote::Applies to')} ${groupedProduct.name}`;
        }
      }
      const linkProps: LinkProps = {
        displayText: termIdContent,
        dataAutomationId: 'configureFinancingTerm',
      };
      card = (
        <div>
          <ConnectedCalloutCardLink
            lineItemId={termLineItem.id}
            linkProps={linkProps}
            maxWidth={500}
            offset={{ left: 30, top: 10 }}
            reference={target}
            relatedIds={relatedIds}
            type={CardType.Config}
          />
          {configCardActive && (
            <FinanceTermsCard
              lineItemId={termLineItem.id}
              name={term.LocalizedProperties[0].ProductTitle}
              target={target}
            />
          )}
        </div>
      );
    } else if (isSimpleTerm(term)) {
      const termIdContent = getTermIdContent(lineItem) || t('quote::configure');
      const linkProps: LinkProps = {
        dataAutomationId: 'configureSimpleTerm',
        displayText: termIdContent,
        title: termIdContent,
      };
      const isECIF = term.ProductType === 'ECIF';
      card = (
        <div>
          <ConnectedCalloutCardLink
            lineItemId={termLineItem.id}
            linkProps={linkProps}
            maxWidth={500}
            offset={{ left: 30, top: 10 }}
            reference={target}
            type={CardType.Config}
          />
          {configCardActive && (
            <SimpleTermsCard
              isECIF={isECIF}
              lineItemId={termLineItem.id}
              name={term.LocalizedProperties[0].ProductTitle}
              target={target}
            />
          )}
        </div>
      );
    } else if (term.DisplaySkuAvailabilities.length > 1) {
      const linkProps: LinkProps = {
        displayText: buildConfigureCombinedSummary(
          lineItem,
          skuTitleMap,
          term.DisplaySkuAvailabilities
        ),
        dataAutomationId: 'configureTerms',
      };
      card = (
        <div>
          {configCardActive && (
            <ConnectedConfigCardBusinessLogic
              activeLineItemId={termLineItem.id}
              hydratedProduct={term}
              maxWidth={360}
              minWidth={360}
              target={target}
            />
          )}
          <ConnectedCalloutCardLink
            lineItemId={termLineItem.id}
            linkProps={linkProps}
            maxWidth={500}
            offset={{ left: 30, top: 10 }}
            reference={target}
            type={CardType.Config}
          />
        </div>
      );
    } else {
      return <TextWatermark addClass={classes.watermark}>{watermarkText}</TextWatermark>;
    }
    return <EventTrap>{card}</EventTrap>;
  }
};

const mapSecondRow = (
  columns: ProposalListColumnRenderProps<TermListLineItem>[],
  term: TermListLineItem,
  terms: Record<string, Product>,
  target: React.RefObject<HTMLSpanElement>,
  watermarkText: string,
  t: i18next.TFunction,
  currency: Currency,
  classes: { watermark: string },
  ecifEnabled: boolean,
  openCard?: OpenCard,
  lineItem?: LineItem,
  skuTitleMap?: SkuTitleMap,
  groupedLineItem?: LineItem,
  productListLineItems?: ProductListLineItem[]
) => {
  return (
    <div
      key={`sr-${term.id}-1`}
      style={{ minWidth: calculateColspan(columns, 4, 0), paddingLeft: 17, paddingRight: 17 }}
    >
      {terms[term.productId] &&
        renderCard(
          terms[term.productId],
          term,
          watermarkText,
          target,
          t,
          currency,
          classes,
          ecifEnabled,
          openCard,
          lineItem,
          skuTitleMap,
          groupedLineItem,
          productListLineItems
        )}
    </div>
  );
};

export const TermListRowUnStyled: React.FC<Props> = props => {
  const { columns, classes, termListLineItem, hasNotificationError, terms, currency } = props;
  const selectionProps = props as SelectionProps;
  const { t } = useTranslation();
  const target = React.useRef<HTMLSpanElement>(null);
  const watermarkText = t('quote::No Options');
  //TODO: cameneks,michmel, refactor
  const onClick = (obj: { multi: boolean; ids: string[]; lastSelectedId?: string }) => {
    const hydratedProduct = terms[termListLineItem.productId];
    loadSkuTitlesIfNeeded(
      termListLineItem.productId,
      props.addSkuTitle,
      hydratedProduct,
      props.skuTitleMap
    );
    selectionProps.onSelected && selectionProps.onSelected(obj);
  };
  const augmentedSelectionProps = { ...selectionProps, onSelected: onClick };
  const isInErrorState = augmentedSelectionProps.isInErrorState || hasNotificationError;
  return (
    <ProposalListRow
      columns={columns}
      isLoading={termListLineItem.isLoading}
      itemId={termListLineItem.id}
      {...augmentedSelectionProps}
      dataAutomationId={termListLineItem.name}
      isInErrorState={isInErrorState}
    >
      <div>
        <div className={classes.lineItemTop}>{mapItems(columns, termListLineItem)}</div>
        <div className={classes.lineItemBottom}>
          {mapSecondRow(
            columns,
            termListLineItem,
            terms,
            target,
            watermarkText,
            t,
            currency,
            { watermark: classes.watermark },
            props.ecifEnabled,
            props.openCard,
            props.lineItem,
            props.skuTitleMap,
            props.groupedLineItem,
            props.productListLineItems
          )}
        </div>
      </div>
    </ProposalListRow>
  );
};

export const TermListStyled = withStyles(termListRowStyles)(TermListRowUnStyled) as React.FC<
  SelectionProps & TermListRowProps
>;

export const TermListRow = connect(mapStateToProps)(TermListStyled);
