import { TextBodySmall, TextTitle } from 'components';
import { QuoteLineItem } from 'features-apollo/quote/components';
import {
  DetailsStyles,
  getMultiSelectLineItemDetails,
} from 'features-apollo/quote/components/DetailsPane';
import { DiscountCardLink } from 'features-apollo/quote/components/DiscountCard';
import { Quote } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

export interface MultiSelectDetailsProps {
  quote: Quote;
  selectedLineItems: QuoteLineItem[];
}

const renderSelectedProductList = (
  values: string[],
  listTitle: string,
  listClass: string,
  total: number
) => {
  return values.length ? (
    <>
      <TextBodySmall>{`${total} ${listTitle}`}</TextBodySmall>
      <ul className={listClass}>
        {values.map((text, key) => {
          return (
            <TextBodySmall key={key}>
              <li>{text}</li>
            </TextBodySmall>
          );
        })}
      </ul>
    </>
  ) : null;
};

type Props = MultiSelectDetailsProps & WithStyles<typeof DetailsStyles>;

export const MultiSelectDetailsUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { classes, selectedLineItems, quote } = props;
  const { t } = useTranslation();
  const selectedItemsCount = selectedLineItems.length;
  const sortedLineItems = getMultiSelectLineItemDetails(selectedLineItems);
  const discountCardTarget = React.useRef<HTMLSpanElement>(null);
  return (
    <>
      <div className={classes.heading}>
        <div>
          <TextTitle>
            {t('quote::{{selectedItemsCount}} line items selected', { selectedItemsCount })}
          </TextTitle>
        </div>
      </div>
      <div className={classes.sectionDetails}>
        <div>
          {renderSelectedProductList(
            sortedLineItems.discountableSummary,
            t('quote::bulk discountable line items'),
            classes.list,
            sortedLineItems.bulkDiscountableLineItems.length
          )}
          <div>
            <span ref={discountCardTarget}>
              {sortedLineItems.bulkDiscountableLineItems.length ? (
                <DiscountCardLink
                  ariaLabel={'ConfigureBulkDiscount'}
                  linkText={t('quote::bulk configure future discounts')}
                  selectedIds={sortedLineItems.bulkDiscountableLineItems.map(item => item.id)}
                  disabled={quote.readOnly || sortedLineItems.bulkDiscountableLineItems.length < 2}
                />
              ) : null}
            </span>
          </div>
        </div>
        {sortedLineItems.otherSummary.length ? (
          <div className={classes.otherProductsList}>
            {renderSelectedProductList(
              sortedLineItems.otherSummary,
              t('quote::other products'),
              classes.list,
              selectedItemsCount - sortedLineItems.bulkDiscountableLineItems.length
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export const MultiSelectDetails = withStyles(DetailsStyles)(MultiSelectDetailsUnstyled);
