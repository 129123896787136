import { Quote } from 'generated/graphql';
import { oc } from 'ts-optchain';

export const isUploadAgreementDisabled = (activeQuote?: Quote) => {
  const agreements = oc(activeQuote).agreements.edges();

  const hasParticipants = !!(
    oc(activeQuote).soldTo.organization.id() && oc(activeQuote).endCustomer.id()
  );

  return !hasParticipants || (agreements && !!agreements.length);
};
