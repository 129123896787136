import { QuoteData } from 'features-apollo/quote/types';
import {
  Account,
  Address,
  AgreementType,
  Audience,
  CatalogAction,
  CatalogContext,
  Currency,
  GqlLanguage,
  Market,
  Motion,
  NationalCloud,
  OrganizationSimple,
  OrganizationType,
  ProductAudience,
  QuoteStatus,
  TransactionModel,
  UserGroup,
  BillingGroup,
} from 'generated/graphql';

export const mockedCatalogContext: CatalogContext = {
  __typename: 'CatalogContext',
  agreementType: AgreementType.Modern,
  audience: ProductAudience.DirectCommercial,
  market: Market.Us,
  nationalCloud: NationalCloud.Global,
  languages: GqlLanguage.EnUs,
  action: CatalogAction.Details,
};

export const mockedAddress: Address = {
  __typename: 'Address',
  addressLine1: '123 Test Street',
  addressLine2: null,
  addressLine3: null,
  addressLastLine: 'Redmond, WA 98052',
  city: 'Redmond',
  companyName: 'TestAccount',
  country: 'US',
  email: null,
  marketInfo: null,
  name: 'TestAccount1',
  phoneNumber: null,
  postalCode: '98052',
  region: 'WA',
};

export const mockedAccount: Account = {
  __typename: 'Account',
  id: '123',
  description: 'MockAccount',
  primaryTenantId: 'MockTenantId',
  externalIds: [],
  tenants: [],
  assets: [],
  purchaseMotion: null,
  cloudScope: null,
};

export const mockedBillingGroupProfile: BillingGroup = {
  accountId: '123456',
  billTo: { companyName: 'name', country: 'US' },
  currency: Currency.Usd,
  id: '987456',
  purchaseOrderNumber: '654123',
  purchaseContext: 'direct',
  organizationId: '',
  roleAssignments: [],
};

export const mockedOrganizationSimple: OrganizationSimple = {
  __typename: 'OrganizationSimple',
  id: '456',
  account: mockedAccount,
  accountId: '123',
  address: mockedAddress,
  assets: [],
  lastInvoiceDate: '1-1-1111',
  name: 'MockOrganization',
  tradeName: null,
  type: OrganizationType.Organization,
  version: 1,
  agreementSigned: null,
  audience: null,
  credit: null,
  language: null,
  languageInfo: null,
  vatId: null,
  customerTag: null,
  billingGroups: { edges: [{ node: mockedBillingGroupProfile }] },
};

export const mockedQuoteNoLineItems: QuoteData = {
  __typename: 'Quote',
  id: '123',
  etag: '12345',
  agreementType: AgreementType.Modern,
  assignedTo: UserGroup.Field,
  audience: Audience.Commercial,
  motion: Motion.Direct,
  fgoe: false,
  transactOnBehalf: false,
  name: 'mockedQuote',
  productAudience: ProductAudience.DirectCommercial,
  readOnly: false,
  status: QuoteStatus.Draft,
  transactionModel: TransactionModel.Unspecified,
  market: Market.Us,
  billingCurrency: Currency.Usd,
  totalPrice: 1000,
  clouds: [NationalCloud.Global],
  exchangeRateDate: null,
  canShareLeadDiscounts: false,
  approval: null,
  crmLead: null,
  endCustomer: null,
  endCustomerInvitedUser: null,
  leadOrganization: null,
  soldTo: {
    quoteId: '123',
    dealEstimate: 1000,
    organization: mockedOrganizationSimple,
    monthlyCreditLimit: 250,
    __typename: 'SoldToSimple',
  },
  soldToInvitedUser: null,
  languageInfo: null,
  agreements: {
    __typename: 'ModernAgreementActualConnection',
    edges: [],
    pageInfo: null,
  },
  lineItems: [],
  messages: [],
  msContact: null,
  vlAgreementNumber: null,

  continuationToken: null,
  expirationDate: null,
  languages: null,
  modifiedApiVersion: null,
  modifiedDate: null,
  vlAgreement: null,
  version: '1',
  revision: 1,
  recommendations: [],
};
