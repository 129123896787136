import gql from 'graphql-tag';

export const AddressFragment = gql`
  fragment addressFragment on Address {
    companyName
    addressLine1
    addressLine2
    addressLine3
    city
    region
    country
    postalCode
    phoneNumber
    email
  }
`;

export const SalesAccountFragment = gql`
  fragment salesAccountFragment on SalesAccount {
    id
    tpid
    address {
      ...addressFragment
    }
    isStrategic
  }
  ${AddressFragment}
`;

export const CrmLeadFragment = gql`
  fragment crmLeadFragment on CrmLead {
    id
    entityId
    type
    title
    entityName
    vlAgreementNumber
    salesAccount {
      ...salesAccountFragment
    }
  }
  ${SalesAccountFragment}
`;
