import { headerButtonStyles } from 'components/ions/Buttons/HeaderButton/HeaderButton.styles';
import { buttonSharedStyles } from 'components/ions/Buttons/shared.styles';
import { ThemeProps } from 'styles';

export const navBarButtonStyles = (theme: ThemeProps) => {
  return {
    ...buttonSharedStyles(theme),
    ...headerButtonStyles(theme),
  };
};
