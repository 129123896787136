import { BarButton } from 'components/ions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAgreementPreviewProvider } from './useAgreementPreviewProvider';

export interface ViewAgreementButtonProps {
  /**
   * Identifies the quote from where the agreement will be generated
   */
  quoteId: string;
}

export const ViewAgreementButton: React.FC<ViewAgreementButtonProps> = props => {
  const { t } = useTranslation();
  const { getAgreement, loading } = useAgreementPreviewProvider(props.quoteId);

  return (
    <BarButton
      dataAutomationId="view-agreement-button"
      iconName="OpenInNewWindow"
      isLoading={loading}
      spinnerLabelPadding={6}
      spinnerLabelSize="medium"
      text={t('quote::View Agreement')}
      onClick={getAgreement}
    />
  );
};
