import { Message, MessageSource, MessageType } from 'generated/graphql';

export const isErrorMessage = (message: Message) => message.messageType === MessageType.Error;

export const isBlockingMessage = (message: Message) =>
  isErrorMessage(message) || message.messageType === MessageType.Standard;

export const isAzureDuplicatedMessage = (message: Message) =>
  message.messageSource === MessageSource.LineitemDuplicate;

export const isProductFailedMessage = (message: Message) =>
  message.messageSource === MessageSource.LineitemBroken;

export const isEcifVirtualLineItemMessage = (message: Message) =>
  message.messageSource === MessageSource.EcifLineitemAmountExceeded ||
  message.messageSource === MessageSource.EcifLineitemPastEnddate;

export const isEcifOneAskNotFoundMessage = (message: Message) =>
  message.messageSource === MessageSource.OneAskApprovalNotFound;

export const hasBlockingMessage = (messages: Message[]) => messages.some(isBlockingMessage);

export const hasErrorMessage = (messages: Message[]) => messages.some(isErrorMessage);
