import {
  CalloutCard,
  InfoButton,
  Label,
  SectionSeparator,
  TextBody,
  TextboxStandard,
} from 'components';
import { Slide } from 'components/molecules/Carousel';
import { useDebounce } from 'components/utilities/debounce';
import { CatalogItemType } from 'features-apollo/quote/types';
import { getStartConditionForDisplay } from 'features-apollo/quote/utils';
import { Carousel } from 'features/components/Carousel';
import { emailIsValid } from 'features/proposal/utils';
import {
  ApplyConfigurationSapInput,
  Currency,
  LineItem,
  MutationApplyConfigurationSapArgs,
  QuoteMutationInput,
  StartCondition,
} from 'generated/graphql';
import i18next from 'i18next';
import moment from 'moment';
import { DirectionalHint } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { getDisplayAmount } from '../utils';
import { ApplySapConfiguration } from './queries';
import { styles } from './SapConfigurationCard.styles';

export interface SapCardProps {
  readOnly?: boolean;
  boundaries: {
    amount: { min: number; max: number };
    goodForDuration: { min: number; max: number };
  };
  initialValues?: {
    amount: string;
    goodFor: string;
    firstName: string;
    lastName: string;
    email: string;
    opportunityId?: string;
  };
  lineItemId: string;
  quote: QuoteMutationInput;
  currency: Currency;
  target: React.RefObject<HTMLSpanElement>;
  productName: string;
  startCondition?: StartCondition | null;
  onDismiss: () => void;
}

// TODO: Read these from GQL
const defaults = {
  amount: '',
  minAmount: 1,
  maxAmount: 999999999999999,
  minDuration: 1,
  maxDuration: 250,
};

const MAX_OPPORTUNITY_CHAR_LENGTH = 64;
const MAX_DIGITS_ALLOWED = 15;
const HARD_UPPER_BOUND_MAX = 2147483647;
const DEBOUNCE_MILLISECONDS = 400;

const getAmountError = (amount: string, min: number, max: number, fieldTouched: boolean) => {
  const amountErrors = {
    numberOnly: i18next.t('error::Only numbers are valid'),
    empty: i18next.t('error::Please enter an amount'),
    lessThanMin: i18next.t('error::Value cannot be less than {{min}}', { min }),
    moreThanMax: i18next.t('error::Value cannot be more than {{max}}', { max }),
    noDecimals: i18next.t('error::Please enter a number with no decimals'),
  };

  const trimmedAmount = amount.trim();

  if (!trimmedAmount && fieldTouched) {
    return amountErrors.empty;
  }

  if (!trimmedAmount) {
    return;
  }

  if (isNaN(+amount)) {
    return amountErrors.numberOnly;
  } else if (amount.includes('.')) {
    return amountErrors.noDecimals;
  } else if (+amount < min) {
    return amountErrors.lessThanMin;
  } else if (+amount > max) {
    return amountErrors.moreThanMax;
  }
};

export const readBoundaries = (lineItem: LineItem) => {
  const boundaries = {
    amount: {
      min: oc(lineItem).product.amount.minimum(defaults.minAmount),
      max: oc(lineItem).product.amount.maximum(defaults.maxAmount),
    },
    goodForDuration: {
      min: oc(lineItem).sku.duration.minimum(defaults.minDuration),
      max: oc(lineItem).sku.duration.maximum(defaults.maxDuration),
    },
  };
  return boundaries;
};

const getInitialGoodForValue = (initialGoodForValue?: string) => {
  if (initialGoodForValue) {
    return moment
      .duration(initialGoodForValue)
      .asMonths()
      .toString();
  }
  return '';
};

const getEmailAddressError = (value?: string) => {
  const invalidEmail = i18next.t('error::Enter a valid email address');
  if (!value || (value && !emailIsValid.test(value))) {
    return invalidEmail;
  }
};

export const getSAPOpptyIdError = (value?: string) => {
  if (value && value.length > MAX_OPPORTUNITY_CHAR_LENGTH) {
    return i18next.t('error::ID number exceeds {{MAX_OPPORTUNITY_CHAR_LENGTH}} character limit', {
      MAX_OPPORTUNITY_CHAR_LENGTH,
    });
  }
};

const getGoodForError = (value: string, min: number, max: number, fieldTouched: boolean) => {
  const goodForErrors = {
    empty: i18next.t('error::Please enter a duration'),
    invalid: i18next.t('error::Invalid month value'),
    lessThanMin: i18next.t('error::Value cannot be less than {{min}}', { min }),
    moreThanMax: i18next.t('error::Value cannot be more than {{max}}', { max }),
    noDecimals: i18next.t('error::Duration cannot have decimals'),
  };

  if (!value.trim() && fieldTouched) {
    return goodForErrors.empty;
  }

  if (!value.trim()) {
    return;
  }

  if (isNaN(+value)) {
    return goodForErrors.invalid;
  } else if (value.includes('.')) {
    return goodForErrors.noDecimals;
  } else if (+value < min) {
    return goodForErrors.lessThanMin;
  } else if (+value > max) {
    return goodForErrors.moreThanMax;
  }
};

type Props = SapCardProps & WithStyles<typeof styles>;

export const SapCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    initialValues,
    boundaries,
    classes,
    startCondition,
    currency,
    readOnly,
    lineItemId,
    quote,
    productName,
  } = props;
  const [applyConfiguration] = useMutation<undefined, MutationApplyConfigurationSapArgs>(
    ApplySapConfiguration
  );

  const maximumAmount = Math.min(boundaries.amount.max, HARD_UPPER_BOUND_MAX);
  const amount$ = useDebounce(DEBOUNCE_MILLISECONDS);
  const goodFor$ = useDebounce(DEBOUNCE_MILLISECONDS);
  const email$ = useDebounce(DEBOUNCE_MILLISECONDS);
  const opportunityId$ = useDebounce(DEBOUNCE_MILLISECONDS);
  const [opportunityId, setOpportunityId] = React.useState(oc(initialValues).opportunityId(''));
  const [opportunityIdError, setOpportunityIdError] = React.useState(
    initialValues && initialValues.opportunityId
      ? getSAPOpptyIdError(initialValues.opportunityId)
      : undefined
  );
  const [email, setEmail] = React.useState(oc(initialValues).email(''));
  const [emailError, setEmailError] = React.useState(
    initialValues && initialValues.email ? getEmailAddressError(initialValues.email) : undefined
  );
  const [amountFieldFocused, setAmountFieldFocused] = React.useState(true);
  const { t } = useTranslation();
  const [amount, setAmount] = React.useState(oc(initialValues).amount(''));
  const [amountError, setAmountError] = React.useState(
    initialValues && initialValues.amount
      ? getAmountError(initialValues.amount, boundaries.amount.min, maximumAmount, false)
      : undefined
  );
  const [firstName, setFirstName] = React.useState(
    (initialValues && initialValues.firstName) || ''
  );
  const [lastName, setLastName] = React.useState((initialValues && initialValues.lastName) || '');

  const [goodFor, setGoodFor] = React.useState(getInitialGoodForValue(oc(initialValues).goodFor()));
  const [goodForError, setGoodForError] = React.useState(
    initialValues && initialValues.goodFor
      ? getGoodForError(
          getInitialGoodForValue(oc(initialValues).goodFor()),
          boundaries.goodForDuration.min,
          boundaries.goodForDuration.max,
          true
        )
      : undefined
  );
  const onChangeAmount = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      const shortened = value.slice(0, MAX_DIGITS_ALLOWED);
      setAmount(shortened);
      amount$.next(() =>
        setAmountError(getAmountError(value, boundaries.amount.min, maximumAmount, true))
      );
    }
  };

  const onChangeGoodFor = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      const shortened = value.slice(0, MAX_DIGITS_ALLOWED);
      setGoodFor(shortened);
      goodFor$.next(() =>
        setGoodForError(
          getGoodForError(
            value,
            boundaries.goodForDuration.min,
            boundaries.goodForDuration.max,
            true
          )
        )
      );
    }
  };

  const amountTextbox = (
    <div className={classes.amountContainer}>
      <TextboxStandard
        autoFocus
        dataAutomationId="sap-amount"
        errorMessage={amountError}
        label={t('quote::Amount ({{currency}})', { currency })}
        placeholder={t('quote::Enter an amount')}
        readOnly={readOnly}
        required
        value={getDisplayAmount(amount, amountFieldFocused)}
        onBlur={() => {
          setAmountFieldFocused(false);
        }}
        onChange={onChangeAmount}
        onFocus={() => setAmountFieldFocused(true)}
      />
    </div>
  );
  const goodForTextbox = (
    <div className={classes.goodForContainer}>
      <TextboxStandard
        ariaLabel={t('quote::Good for')}
        dataAutomationId="sap-good-for"
        errorMessage={goodForError}
        label={t('quote::Good for')}
        readOnly={readOnly}
        required
        suffix={t('quote::month(s)')}
        value={goodFor}
        onChange={onChangeGoodFor}
      />
    </div>
  );

  const startingInformation = (
    <div className={classes.startingInformation}>
      <div>
        <TextBody addClass={classes.emphasisText}>{t('quote::Starting')}</TextBody>
      </div>
      <div>
        <TextBody>
          {getStartConditionForDisplay({ catalogItemType: CatalogItemType.Term, startCondition })}
        </TextBody>
      </div>
    </div>
  );

  let stepContainerClass = classes.noError;
  if (emailError && opportunityIdError) {
    stepContainerClass = classes.twoErrors;
  } else if (emailError || opportunityIdError) {
    stepContainerClass = classes.oneError;
  }

  const firstSlide = (
    <div className={stepContainerClass}>
      {amountTextbox}
      {startingInformation}
      {goodForTextbox}
    </div>
  );

  const onChangeFirstName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      setFirstName(value);
    }
  };

  const onChangeLastName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      setLastName(value);
    }
  };

  const onChangeEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      setEmail(value);
      email$.next(() => setEmailError(getEmailAddressError(value)));
    }
  };

  const onChangeOpportunityId = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      setOpportunityId(value);
      opportunityId$.next(() => setOpportunityIdError(getSAPOpptyIdError(value)));
    }
  };

  const sapInformationText = (
    <div>
      <TextBody addClass={classes.textTertiary}>
        {t('quote::SAP global administrator information')}
      </TextBody>
    </div>
  );

  const firstNameTextbox = (
    <div className={classes.textInputContainer}>
      <TextboxStandard
        dataAutomationId="sap-first-name"
        label={t('quote::First name')}
        readOnly={readOnly}
        required
        value={firstName}
        onChange={onChangeFirstName}
      />
    </div>
  );

  const lastNameTextbox = (
    <div className={classes.textInputContainer}>
      <TextboxStandard
        dataAutomationId="sap-last-name"
        label={t('quote::Last name')}
        readOnly={readOnly}
        required
        value={lastName}
        onChange={onChangeLastName}
      />
    </div>
  );
  const renderEmailFieldLabel = () => (
    <div className={classes.customLabel}>
      <TextBody addClass={classes.emphasisText}>{t('quote::Email address ')}</TextBody>
      <TextBody addClass={classes.required}>*</TextBody>
      <InfoButton
        ariaLabel={t('quote::Open information about email address')}
        calloutProps={{
          closeButtonAriaLabel: t('quote::Close'),
          headline: t('quote::SAP global administrator email'),
          directionalHint: DirectionalHint.bottomLeftEdge,
          maxWidth: 285,
        }}
        id="sap-email"
      >
        <TextBody>
          {t(
            'quote::The email address provided will be used to send a sign up email to the global adminstrator that will have access to the SAP Cloud Platform Cockpit.'
          )}
        </TextBody>
      </InfoButton>
    </div>
  );

  const renderOpportunityFieldLabel = () => (
    <div className={classes.customLabel}>
      <Label className={classes.opportunityIdLabel}>
        {t('quote::SAP opportunity ID (optional) ')}
      </Label>
      <InfoButton
        ariaLabel={t('quote::Open information about SAP opportunity Id')}
        calloutProps={{
          closeButtonAriaLabel: t('quote::Close'),
          headline: t('quote::SAP opportunity ID'),
          directionalHint: DirectionalHint.bottomLeftEdge,
          maxWidth: 285,
        }}
        id="sap-opportunity"
      >
        <TextBody>
          {t(
            'quote::The SAP opportunity ID is approximately a nine-digit number used in cosell scenarios to reference to the SAP seller.'
          )}
        </TextBody>
      </InfoButton>
    </div>
  );

  const emailTextbox = (
    <TextboxStandard
      dataAutomationId="sap-email"
      errorMessage={emailError}
      label={t('quote::Email address')}
      placeholder="someone@example.com"
      readOnly={readOnly}
      required
      value={email}
      onChange={onChangeEmail}
      onRenderLabel={renderEmailFieldLabel}
    />
  );
  const sapOpportunityIdTextbox = (
    <div className={props.classes.opportunityIdContainer}>
      <TextboxStandard
        dataAutomationId="sap-opportunity-id"
        errorMessage={opportunityIdError}
        label={t('quote::SAP opportunity ID (optional)')}
        readOnly={readOnly}
        value={opportunityId}
        onChange={onChangeOpportunityId}
        onRenderLabel={renderOpportunityFieldLabel}
      />
    </div>
  );

  const applyButtonStrings = { text: t('quote::Apply'), key: t('quote::Apply') };

  const secondSlide = (
    <div className={stepContainerClass}>
      {sapInformationText}
      {firstNameTextbox}
      {lastNameTextbox}
      {emailTextbox}
      <SectionSeparator addClass={classes.sectionSeparator} />
      {sapOpportunityIdTextbox}
    </div>
  );

  //TODO: currently we recompute them because of the debounce, can be imporoved and made less complex too
  const isValidFirstName = !!(firstName && firstName.trim());
  const isValidLastName = !!(lastName && lastName.trim());
  const isInvalidAmount = getAmountError(amount, boundaries.amount.min, maximumAmount, true);
  const isInvalidDuration = getGoodForError(
    goodFor,
    boundaries.goodForDuration.min,
    boundaries.goodForDuration.max,
    true
  );

  const isInvalidEmail = getEmailAddressError(email);
  const isInvalidOpportunityId = getSAPOpptyIdError(opportunityId);
  const secondStepIsEdited = firstName || lastName || opportunityId || email;

  const slideErrors = {
    first: !!(amountError || goodForError),
    second: !!(
      secondStepIsEdited &&
      (emailError || opportunityIdError || !isValidFirstName || !isValidLastName)
    ),
  };

  const slides: Slide[] = [
    {
      content: firstSlide,
      id: 'first-step',
      dotDataAutomationId: 'step 1',
      hasError: slideErrors.first,
      dotAriaLabel: slideErrors.first
        ? t('quote::Step {{step}} error present', { step: '1' })
        : t('quote::Step {{step}}', { step: '1' }),
    },
    {
      content: secondSlide,
      id: 'second-step',
      dotDataAutomationId: 'step 2',
      hasError: slideErrors.second,
      dotAriaLabel: slideErrors.second
        ? t('quote::Step {{step}} error present', { step: '2' })
        : t('quote::Step {{step}}', { step: '2' }),
    },
  ];

  const disableApply = !!(
    !isValidFirstName ||
    !isValidLastName ||
    isInvalidAmount ||
    isInvalidDuration ||
    isInvalidEmail ||
    isInvalidOpportunityId
  );

  const onApply = () => {
    if (amount && goodFor && firstName && lastName && email) {
      const configuration: ApplyConfigurationSapInput = {
        amount: parseInt(amount),
        duration: `P${goodFor}M`,
        email,
        lineItemId,
        firstName,
        lastName,
        opportunityId,
      };
      applyConfiguration({ variables: { quote, configuration } });
    }
  };

  return (
    <CalloutCard
      applyButtonDisabled={disableApply}
      applyButtonStrings={applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      directionalHint={DirectionalHint.rightCenter}
      headerText={t('quote::Configure {{productName}}', { productName })}
      id="sap-card"
      isBeakVisible
      isReadOnly={!!readOnly}
      maxWidth={305}
      target={props.target}
      onApply={() => {
        onApply();
        props.onDismiss();
      }}
      onDismiss={props.onDismiss}
    >
      <div className={props.classes.container}>
        <Carousel initialSlideId="first-step" slides={slides} />
      </div>
    </CalloutCard>
  );
};

export const SapConfigurationCard = withStyles(styles)(SapCardUnstyled);
