import {
  ICalloutProps,
  ITeachingBubbleStyles,
  Target,
  TeachingBubble,
} from 'office-ui-fabric-react';
import * as React from 'react';

export interface TeachingBubbleAtomProps {
  calloutProps?: Partial<ICalloutProps>;
  closeButtonAriaLabel: string;
  dataAutomationId?: string;
  hasCondensedHeadline: boolean;
  hasCloseIcon: boolean;
  headline: string;
  styles?: Partial<ITeachingBubbleStyles>;
  target: Target;
  onDismiss: () => void;
}

export const TeachingBubbleAtom: React.FC<TeachingBubbleAtomProps> = props => {
  return (
    <TeachingBubble
      calloutProps={props.calloutProps}
      closeButtonAriaLabel={props.closeButtonAriaLabel}
      data-automation-id={props.dataAutomationId}
      hasCloseIcon={props.hasCloseIcon}
      hasCondensedHeadline={props.hasCondensedHeadline}
      headline={props.headline}
      styles={props.styles}
      target={props.target}
      onDismiss={props.onDismiss}
    >
      {props.children}
    </TeachingBubble>
  );
};
