import {
  AddressProps,
  ApprovalListItem,
  ConditionItem,
  NotificationItem,
  TextBody,
  TextTitle,
} from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { ApprovalComments, Comment } from './ApprovalComments';
import { DetailsPaneStyles } from './DetailsPane.styles';
import {
  renderAddress,
  renderApprovals,
  renderConditions,
  renderNotifications,
  renderPartners,
} from './SharedDetailsRenders';

export interface DetailsPaneProposalProps {
  id: string;
  companyName: string;
  endCompanyName?: string;
  tradeName?: string;
  endCustomerTradeName?: string;
  conditions: ConditionItem[];
  notifications: NotificationItem[];
  approvals: ApprovalListItem[];
  partners: string[];
  comments: Comment[];
  address?: AddressProps;
  endCustomerAddress?: AddressProps;
  isPartner?: boolean;
  dataAutomationId?: string;
}

type Props = DetailsPaneProposalProps & WithStyles<typeof DetailsPaneStyles>;

const ProposalDetailsUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const {
    id,
    classes,
    companyName,
    endCompanyName,
    endCustomerTradeName,
    address,
    endCustomerAddress,
    conditions,
    notifications,
    approvals,
    partners,
    comments,
    isPartner,
    dataAutomationId,
  } = props;

  const { t } = useTranslation();

  const renderCompanyInfo = (title?: string, isEndCustomer?: boolean) => {
    const name = isEndCustomer ? endCompanyName : companyName;
    const tradeName = isEndCustomer ? endCustomerTradeName : props.tradeName;

    return (
      <div className={classes.heading}>
        {title && <TextBody addClass={classes.tradeName}>{title}</TextBody>}
        <div data-automation-id="accountName">
          <TextTitle addClass={classes.companyName} title={name}>
            {name}
          </TextTitle>
        </div>
        {tradeName ? (
          <div>
            <TextBody addClass={classes.tradeName} title={tradeName}>
              {tradeName}
            </TextBody>
          </div>
        ) : null}
        <div className={classes.address}>
          {isEndCustomer
            ? renderAddress(classes, endCustomerAddress)
            : renderAddress(classes, address)}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.detailsContainer} data-automation-id={dataAutomationId} id={id}>
      {isPartner ? (
        <div>
          <div className={classes.partnerContainer}>{renderCompanyInfo(t('quote::Partner'))}</div>
          {renderCompanyInfo(t('quote::End customer'), true)}
        </div>
      ) : (
        renderCompanyInfo()
      )}
      {!isPartner && renderConditions(conditions, classes)}
      {!isPartner && renderNotifications(notifications, classes)}
      {renderApprovals(approvals, classes, t)}
      <ApprovalComments comments={comments} enableShowMore />
      {renderPartners(partners, classes)}
    </div>
  );
};

export const ProposalDetailsPane = withStyles(DetailsPaneStyles)(ProposalDetailsUnstyled);
