import {
  createErrorMessageSelector,
  createLoadingSelector,
  createProcessingSelectors,
  getFlightIsEnabled,
  getSuppressedMessages,
} from 'features/app/selectors';
import {
  getCRMLead,
  getOrganization,
  getSalesAccountAddressFromCrmId,
  getSalesAccountFromCrmId,
} from 'features/customer/selectors';
import { organizationHasInvalidCompanyName } from 'features/customer/utils';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import { defaultMarket, getMarkets, MarketType } from 'features/proposal/supported-markets';
import { hasSoldToCustomer, isOldProposalVersion } from 'features/proposal/utils';
import { getUser } from 'features/user/selectors';
import moment from 'moment';
import { createSelector } from 'reselect';
import { ApprovalStatus } from 'services/approval/types';
import { isUsersTurnToApprove } from 'services/approval/utils';
import { Audience } from 'services/catalog/config';
import { ProductFamily } from 'services/catalog/types';
import { safelistedCID } from 'services/credit/config';
import { CreditInfo } from 'services/credit/types';
import { Flight } from 'services/flights/flightList';
import { ClaimType, productIds } from 'services/proposal/config';
import { Proposal, ProposalStatus, ProposalSummary, UserGroup } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { CreditLineReason } from '../components/Dialogs/CreditDialog/types';
import { InvitedUserStatus, ProposalCombinedStatus } from '../types';
import * as utils from '../utils';

export const getProposalFragmentsOrdered = (state: RootState) =>
  state.proposal.proposals.fragments.ordered;
export const getProposalFragmentsIndexed = (state: RootState) =>
  state.proposal.proposals.fragments.indexed;

export const getProposalFragments = createSelector(
  getProposalFragmentsOrdered,
  getProposalFragmentsIndexed,
  (ordered, indexed) => ordered.map(id => indexed[id])
);

export const lineItemCreationLoading = createLoadingSelector(['@@proposal/line_items/CREATE']);

export const getActiveProposalId = (state: RootState) => state.proposal.views.activeId;

export const getProposalEntitiesIndexed = (state: RootState) =>
  state.proposal.proposals.entities.indexed;

export const getProposal = (state: RootState, proposalId: string) =>
  getProposalEntitiesIndexed(state)[proposalId];

export const getActiveProposalFragment = createSelector(
  getActiveProposalId,
  getProposalFragmentsIndexed,
  (activeId, indexed) => indexed[activeId]
);

export const getActiveProposalWithOc = createSelector(
  getActiveProposalId,
  getProposalEntitiesIndexed,
  (activeId, indexed) => oc(indexed[activeId])
);

export const getActiveProposal = createSelector(
  getActiveProposalId,
  getProposalEntitiesIndexed,
  (activeId, indexed) => indexed[activeId]
);

export const isQuotePublished = (state: RootState, quote: Proposal = getActiveProposal(state)) =>
  oc(quote).header.assignedToGroup() === UserGroup.Customer;

export const getAnnualDealEstimate = createSelector(
  getActiveProposal,
  proposal => proposal.header.estimatedDealValue && Math.floor(proposal.header.estimatedDealValue)
);

export const getLeadOrganizationId = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => oc(proposal).header.extendedProperties.userPreferences.leadOrgId();

export const getOrganizationId = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => oc(proposal).header.soldToCustomerLegalEntity.organizationId();

export const getEndCustomerOrganizationId = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => oc(proposal).header.endCustomerLegalEntity.organizationId();

export const hasOrganizationId = (state: RootState) => !!getOrganizationId(state);

export const missingOrganizationContactInfo = (state: RootState) => {
  const organizationId = getOrganizationId(state);
  const organization = organizationId && getOrganization(state, organizationId);

  if (!organization) {
    return false;
  } else {
    return !(
      organization.legalEntity.address.email && organization.legalEntity.address.phoneNumber
    );
  }
};

export const hasIncompleteOrganization = (state: RootState): boolean => {
  const organizationId = getOrganizationId(state);
  if (!organizationId) {
    return false;
  }
  const organization = getOrganization(state, organizationId);
  return !!(
    organization &&
    (!organization.legalEntity.address.addressLine1 ||
      !organization.legalEntity.address.city ||
      organizationHasInvalidCompanyName(organization))
  );
};

export const getViews = (state: RootState) => state.proposal.views;
export const getViewStateForSummaryList = createSelector(getViews, views => views.summaryList);
export const getPrices = (state: RootState) => state.proposal.views.editor.lineItemPrices;

export const getRequiredApprovalLevels = createSelector(getActiveProposal, activeProposal => {
  if (activeProposal) {
    return activeProposal.header.approval.requiredApprovalLevels;
  }
});

export const getIsApprovalRequired = createSelector(getActiveProposal, activeProposal => {
  return !['autoapproval', 'unknown'].includes(
    oc(activeProposal)
      .header.approval.workflow('')
      .toLowerCase()
  );
});

export const getEmpowerments = (state: RootState) => state.proposal.empowerments;

export const getMarket = (state: RootState) => {
  const activeProposal = getActiveProposal(state);
  return activeProposal
    ? activeProposal.header.pricingContext.market || defaultMarket
    : defaultMarket;
};

export const getSalesAccountAddressFromQuote = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state)
) => {
  if (!proposal) return;
  const crmId = utils.getCRMId(proposal);

  if (crmId) {
    return getSalesAccountAddressFromCrmId(state, crmId);
  }
};

export const isCRMIdMarketValid = (state: RootState, crmId?: string) => {
  crmId = crmId || state.proposal.views.editor.crmIdToAdd || undefined;
  if (!crmId) {
    return;
  }

  const salesAccount = getSalesAccountFromCrmId(state, crmId);
  const salesAccountCountry = salesAccount && salesAccount.Address.country;
  const proposal = getActiveProposal(state);
  const marketType = utils.isAgreementTypeLegacy(proposal) ? MarketType.legacy : MarketType.modern;
  return salesAccountCountry
    ? getMarkets(marketType).some(market => market.text === salesAccountCountry)
    : undefined; // undefined so we can delineate between no sales account and invalid market
};

export const errorAddingCRMId = createErrorMessageSelector(['@@CRM/ADD_NEW/LOAD']);
export const addingCRMId = createLoadingSelector(['@@CRM/ADD_NEW/LOAD']);

export const processingVerifyCrmIdFromDialog = createProcessingSelectors(['@@CRM/CRMID/VERIFY']);

export const processingVerifyCrmId = createProcessingSelectors([
  '@@CRM/CRMID/VERIFY',
  '@@CRMLead/LOAD',
  '@@salesaccount/LOAD',
]);

export const getEnrollmentNumber = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => oc(proposal).header.extendedProperties.vlAgreementNumber();

export const getEnrollment = (state: RootState) => state.customer.enrollment;

export const getApprovers = (key: string) =>
  createSelector(getEmpowerments, empowerments => empowerments[key] && empowerments[key].approvers);

export const isProposalReadOnly = createSelector(getActiveProposal, proposal =>
  utils.isProposalReadOnly(proposal)
);

export const proposalHasPassesProduct = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => {
  let hasPassesProducts = false;
  if (proposal.lineItems) {
    proposal.lineItems.forEach(lineItem => {
      if (lineItem.productIdentifier.productFamily === ProductFamily.Passes) {
        hasPassesProducts = true;
      }
    });
  }
  return hasPassesProducts;
};

export const proposalHasSAPTerm = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => {
  if (proposal.lineItems) {
    return proposal.lineItems.some(
      lineItem => lineItem.productIdentifier.productType === 'SCPCommitmentToConsume'
    );
  }
  return false;
};

export const proposalHasExtendedPrice = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => {
  if (proposal.lineItems) {
    return proposal.lineItems.some(lineItem => lineItem.extendedPrice > 0);
  }
  return false;
};

export const isPartnerProposal = (state: RootState) => {
  const quote = getActiveProposal(state);
  const lineItemClaims =
    quote && quote.lineItems && quote.lineItems.length && quote.lineItems[0].catalogClaims;
  const audience = lineItemClaims && lineItemClaims[ClaimType.PricingAudience];
  return !!(
    quote &&
    quote.header.assignedToGroup.toLowerCase() === UserGroup.Partner.toLowerCase() &&
    audience &&
    audience.toLowerCase() === Audience.PartnerCommercial.toLowerCase()
  );
};

export const canWithdrawProposal = createSelector(
  getActiveProposal,
  isProposalReadOnly,
  isPartnerProposal,
  (proposal, isReadOnly, isPartner) => {
    const editableFromCustomer =
      proposal.header.assignedToGroup === UserGroup.Customer &&
      (proposal.header.status === ProposalStatus.Draft ||
        proposal.header.status === ProposalStatus.Active);

    const editableFromField =
      proposal.header.assignedToGroup === UserGroup.Field &&
      proposal.header.status === ProposalStatus.Active;

    const editable = editableFromCustomer || editableFromField;

    return (
      (proposal.header.status === ProposalStatus.Rejected ||
        proposal.header.status === ProposalStatus.Submitted ||
        editable) &&
      isReadOnly &&
      !isPartner
    );
  }
);
export const checkEmailEmpowerment = (state: RootState, key: string, email: string) => {
  const validated = state.proposal.empowerments[key] && state.proposal.empowerments[key].validated;
  return validated && validated[email];
};

export const checkEmpowermentValidForNextStep = (state: RootState, approverCount: number) => {
  const level = getRequiredApprovalLevels(state) || [];
  const levelsNotAutopopulated = level.filter(approval => !approval.autoPopulate);
  return levelsNotAutopopulated.length === approverCount;
};

export const hasSoldTo = createSelector(getActiveProposal, quote => hasSoldToCustomer(quote));

const getSoldTo = (proposal: ProposalSummary | Proposal, state: RootState) => {
  const soldToId = oc(proposal).header.soldToCustomerLegalEntity.organizationId();
  return soldToId ? getOrganization(state, soldToId) : null;
};

const getEndCustomer = (proposal: ProposalSummary | Proposal, state: RootState) => {
  const soldToId = oc(proposal).header.endCustomerLegalEntity.organizationId();
  return soldToId ? getOrganization(state, soldToId) : null;
};

const getSoldToCustomerAddress = (proposal: ProposalSummary | Proposal, state: RootState) => {
  const soldTo = getSoldTo(proposal, state);
  return soldTo && soldTo.legalEntity && soldTo.legalEntity.address;
};

export const getEndCustomerAddress = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state)
) => {
  const endCostumer = getEndCustomer(proposal, state);
  return endCostumer && endCostumer.legalEntity && endCostumer.legalEntity.address;
};

const getSoldToCustomerTradeName = (proposal: ProposalSummary | Proposal, state: RootState) => {
  const soldTo = getSoldTo(proposal, state);
  return soldTo && soldTo.legalEntity && soldTo.legalEntity.tradeName;
};

const getEndCustomerLegalEntityTradeName = (
  proposal: ProposalSummary | Proposal,
  state: RootState
) => {
  const endCustomer = getEndCustomer(proposal, state);
  return endCustomer && endCustomer.legalEntity.tradeName;
};

export const getCustomerAddress = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state)
) => {
  if (!proposal) return;
  return getSoldToCustomerAddress(proposal, state);
};

export const getCustomerName = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state),
  defaultValue: string = 'Unknown Billing Account'
) => {
  if (!proposal) return defaultValue;
  const soldToAddress = getSoldToCustomerAddress(proposal, state);
  if (soldToAddress) {
    return soldToAddress.companyName;
  } else {
    return defaultValue;
  }
};

export const getEndCustomerName = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state),
  defaultValue: string = 'Unknown Billing Account'
) => {
  if (!proposal) return defaultValue;
  const endCustomerAddress = getEndCustomerAddress(state);
  if (endCustomerAddress) {
    return endCustomerAddress.companyName;
  } else {
    return defaultValue;
  }
};

export const getTradeName = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state),
  defaultValue: string = 'Unknown'
) => {
  if (!proposal) return defaultValue;
  const soldToTradeName = getSoldToCustomerTradeName(proposal, state);
  if (soldToTradeName) {
    return soldToTradeName;
  } else {
    return defaultValue;
  }
};

export const getEndCustomerTradeName = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state),
  defaultValue: string = 'Unknown'
) => {
  if (!proposal) return defaultValue;
  const endCustomerTradeName = getEndCustomerLegalEntityTradeName(proposal, state);
  if (endCustomerTradeName) {
    return endCustomerTradeName;
  } else {
    return defaultValue;
  }
};

export const getMSContactName = (state: RootState, email: string): string => {
  return state.user.msContact[email] ? state.user.msContact[email].displayName : '';
};

/*
 *Invited user has the following shapes
 * 1. Verified:  (When invited user has email, tenant and objectId) email:<email>,org:<tenantId>:<objectId>
 * 2. UnknownWorkAccount: (When invited user has email and tenant) email:<email>,org:<tenantId>
 * 3. UnknownTenant: (When invited user has email only) email:<email>
 * 4. Invalid: (When invited user tenantId does not match the tenantId associated with soldToCustomer)
 */
export const getInvitedUserStatus = (state: RootState) => {
  const proposal = getActiveProposal(state);
  if (!proposal) return InvitedUserStatus.Invalid;

  const invitedUser = proposal.header.invitedUser;
  if (!invitedUser) return InvitedUserStatus.Required;

  const soldToCustomer = getSoldTo(proposal, state);
  const invitedUserProps = invitedUser.split(',');
  if (invitedUserProps.length === 1) {
    // If there is a soldTo on the quote then there is always a tenant associated
    return soldToCustomer ? InvitedUserStatus.Invalid : InvitedUserStatus.UnknownTenant;
  } else {
    const invitedUserOrgProps = utils.getInvitedUserOrgProps(invitedUserProps[1]);
    //todo: for complex customer we need to hydrate account and validate the tenant against customercontactTid.
    // if (soldToCustomer) {
    //   const backingIdentity = soldToCustomer.backingIdentity;
    //   const soldToTenantId = backingIdentity && getTenantId(backingIdentity);
    //   const customerContactTId = invitedUserOrgProps[1];
    //   if (soldToTenantId !== customerContactTId) return InvitedUserStatus.Invalid;
    // }
    if (invitedUserOrgProps.length === 3) return InvitedUserStatus.Verified;
    else return InvitedUserStatus.UnknownWorkAccount;
  }
};

export const getAccountId = (state: RootState, proposal: Proposal = getActiveProposal(state)) =>
  oc(proposal).header.soldToCustomerLegalEntity.accountId();

export const getEndCustomerAccountId = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => oc(proposal).header.endCustomerLegalEntity.accountId();

export const getCustomerAddressForEnrollment = (state: RootState) => {
  const organizationId = getOrganizationId(state);
  if (organizationId) {
    return oc(getOrganization(state, organizationId)).legalEntity.address();
  }
};

export const getActiveApproval = (state: RootState) => {
  const proposal = getActiveProposal(state);
  if (proposal && proposal.header.approval && proposal.header.approval.id) {
    return state.proposal.approvals.entities.indexed[proposal.header.approval.id];
  }
};

export const getApprovalLastComment = createSelector(
  getActiveApproval,
  approval =>
    approval && approval.history !== [] && approval.history[approval.history.length - 1].comments
);

export const getProposalStatus = createSelector(
  getActiveProposal,
  getActiveApproval,
  getRequiredApprovalLevels,
  (proposal, approval, requiredApprovalLevels): ProposalCombinedStatus => {
    const proposalStatus = proposal && proposal.header.status;
    const approvalStatus = approval && approval.status;
    const needsApproval = requiredApprovalLevels && requiredApprovalLevels.length > 0;

    if (proposalStatus === ProposalStatus.Draft && !needsApproval) {
      return proposal.header.assignedToGroup === UserGroup.Field
        ? ProposalCombinedStatus.Draft
        : ProposalCombinedStatus.Published;
    } else if (proposalStatus === ProposalStatus.Draft && needsApproval) {
      return ProposalCombinedStatus.ApprovalRequired;
    } else if (proposalStatus === ProposalStatus.Submitted) {
      return ProposalCombinedStatus.PendingApproval;
    } else if (proposalStatus === ProposalStatus.Active) {
      return !needsApproval
        ? ProposalCombinedStatus.AutoApprovedAndActivated
        : ProposalCombinedStatus.Approved;
    } else if (
      proposalStatus === ProposalStatus.Rejected ||
      approvalStatus === ApprovalStatus.Rejected
    ) {
      return ProposalCombinedStatus.ApprovalDenied;
    } else if (proposalStatus === ProposalStatus.PurchaseInProgress) {
      return ProposalCombinedStatus.InProgress;
    } else if (proposalStatus === ProposalStatus.Completed) {
      return ProposalCombinedStatus.Completed;
    } else if (approvalStatus === ApprovalStatus.Approved) {
      return ProposalCombinedStatus.Approved;
    } else if (proposalStatus === ProposalStatus.Expired) {
      return ProposalCombinedStatus.Expired;
    } else {
      return ProposalCombinedStatus.UnknownStatus;
    }
  }
);

export const getUserApprovalLevel = createSelector(getActiveApproval, getUser, (approval, user) => {
  if (!user.email) {
    return;
  }
  const userEmail = user.email.toLowerCase();
  return (
    approval &&
    approval.approvalLevels.find(level =>
      level.approvers.some(approver => approver.emailAddress.toLowerCase() === userEmail)
    )
  );
});

export const showApproverCommands = createSelector(
  getActiveApproval,
  getActiveProposal,
  getUser,
  (approval, proposal, user) => {
    if (!approval || !user.email) {
      return false;
    }
    const isUsersTurn = isUsersTurnToApprove(approval, user.email);
    return isUsersTurn && proposal.header.status === ProposalStatus.Submitted;
  }
);

export const getProposalNextExpiryDate = (state: RootState) => {
  const date = moment()
    .add(state.app.config.proposalNextExpiryDays, 'd')
    .toDate();
  return date;
};

export const canDelete = (state: RootState, proposal?: ProposalSummary | Proposal) => {
  proposal = proposal || getActiveProposalFragment(state) || getActiveProposal(state);

  if (!proposal) return false;

  return (
    (proposal.header.status === ProposalStatus.Expired ||
      proposal.header.status === ProposalStatus.Draft) &&
    proposal.header.assignedToGroup === UserGroup.Field &&
    proposal.header.msContact === state.user.current.email
  );
};

export const getSelectedIds = (state: RootState) => state.proposal.views.editor.selectedItems;
export const getLastSelectedId = (state: RootState) => state.proposal.views.editor.lastSelectedItem;
export const proposalsLoading = createLoadingSelector(['@@proposalFragments/SEARCH']);
export const summaryListLoading = createLoadingSelector([
  '@@proposalFragments/SEARCH',
  '@@approvals/SEARCH',
]);
export const summaryListError = createErrorMessageSelector([
  '@@proposalFragments/SEARCH',
  '@@approvals/SEARCH',
]);
export const proposalLoading = createLoadingSelector(['@@proposal/LOAD']);
export const proposalActionInProgress = createLoadingSelector([
  '@@proposal/ACTION',
  '@@proposal/MULTIPLE_ACTION',
]);

export const empowermentsLoading = createLoadingSelector(['@@empowerments/LOAD']);
export const submitLoading = createLoadingSelector(['@@proposal/SUBMIT']);
export const creditLoading = createLoadingSelector(['@@credit/LOAD']);
export const requestCreditLineProcessing = createProcessingSelectors([
  '@@credit/REQUEST_CREDIT_LINE',
]);
export const requestCreditCheckLoading = createLoadingSelector(['@@credit/UPDATE']);
export const companyAddressLoading = createLoadingSelector(['@@customerAddress/LOAD']);
export const salesAccountLoading = createLoadingSelector(['@@CRMLead/LOAD', '@@salesaccount/LOAD']);
export const quoteHydrateLoading = createLoadingSelector(['@@quote/hydrateAll/LOAD']);

// Selectors for error messages
export const companyAddressErrorOnLoad = createErrorMessageSelector(['@@customerAddress/LOAD']);
export const crmIdErrorOnAdd = createErrorMessageSelector(['@@CRM/ADD_NEW/LOAD', '@@quote/UPDATE']);
export const processingAddCRMID = createProcessingSelectors([
  '@@CRM/ADD_NEW/LOAD',
  '@@quote/UPDATE',
]);
export const salesAccountErrorOnLoad = createErrorMessageSelector([
  '@@CRMLead/LOAD',
  '@@salesaccount/LOAD',
]);
export const proposalErrorOnSubmit = createErrorMessageSelector(['@@proposal/SUBMIT']);
export const approvalErrorOnAction = createErrorMessageSelector(['@@approval/ACTION']);
export const createProposalError = createErrorMessageSelector(['@@proposal/CREATE']);
export const deleteProposalError = createErrorMessageSelector(['@@proposal/DELETE']);
export const quoteSubmitProcessing = createProcessingSelectors(['@@proposal/SUBMIT']);
export const approvalActionProcessing = createProcessingSelectors(['@@approval/ACTION']);
export const empowermentsErrorOnLoad = createErrorMessageSelector(['@@empowerments/LOAD']);
export const proposalErrorOnAction = createErrorMessageSelector([
  '@@proposal/ACTION',
  '@@proposal/MULTIPLE_ACTION',
]);
export const quoteUpdateProcessing = createProcessingSelectors(['@@proposal/UPDATE']);

export const proposalErrorOnUpdate = createErrorMessageSelector(['@@proposal/UPDATE']);
export const requestCreditCheckErrorOnAction = createErrorMessageSelector(['@@credit/UPDATE']);

export const isProposalActiveOrActivable = (proposal: Proposal): boolean =>
  (proposal.header.status === ProposalStatus.Draft &&
    (!proposal.header.approval.workflow || proposal.header.approval.workflow === 'AutoApproval')) ||
  proposal.header.status === ProposalStatus.Active;

export const canPublish = createSelector(getActiveProposal, proposal => {
  const isActiveOrActivable = isProposalActiveOrActivable(proposal);
  const isLegacy = utils.isAgreementTypeLegacy(proposal);

  return proposal.header.assignedToGroup === UserGroup.Field && isActiveOrActivable && !isLegacy;
});

export const getSelectedProject = (
  state: RootState,
  proposal: Proposal = getActiveProposal(state)
) => {
  const isLegacy = utils.isAgreementTypeLegacy(proposal);
  if (isLegacy) {
    const enrollmentNumber = oc(proposal).header.extendedProperties.vlAgreementNumber();
    if (!enrollmentNumber) return;
    const projectId = state.customer.project[enrollmentNumber];
    if (!projectId) return;
    return projectId.id;
  }

  const accountId = getAccountId(state, proposal);
  if (!accountId) return;
  const footprint = state.customer.modernFootprint[accountId];
  if (!footprint) return;
  return footprint.projectId;
};

export const lineItemLoading = createLoadingSelector([
  '@@proposal/line_items/CREATE_REQUEST',
  '@@proposal/line_items/UPDATE_AND_CREATE_REQUEST',
  '@@proposal/line_items/CREATE',
  '@@quote/line_items/CREATE',
]);

export const lineItemUnsuppressedMessages = createSelector(
  getActiveProposal,
  getSuppressedMessages,
  (quote, suppressedErrors) =>
    quote.lineItems.some(
      lineItem =>
        lineItem.messages && lineItem.messages.some(msg => !suppressedErrors.includes(msg.code))
    )
);

export const disablePublish = createSelector(
  proposalLoading,
  getActiveProposal,
  canPublish,
  lineItemUnsuppressedMessages,
  (isLoading, quote, canPublish, lineItemMessages) =>
    isLoading || !canPublish || !quote.lineItems.length || lineItemMessages
);

export const lineItemMissingDiscountInLegacyError = (state: RootState) => {
  const proposal = getActiveProposal(state);
  return (
    utils.hasLineItemsThatAreNotDiscounted(proposal.lineItems) &&
    utils.isAgreementTypeLegacy(proposal)
  );
};

export const disableSubmit = createSelector(
  proposalLoading,
  getActiveProposal,
  getIsApprovalRequired,
  lineItemUnsuppressedMessages,
  lineItemMissingDiscountInLegacyError,
  (isLoading, quote, isApprovalRequired, lineItemMessages, missingDiscountInLegacy) =>
    isLoading ||
    !quote.lineItems.length ||
    utils.isProposalReadOnly(quote) ||
    quote.header.status !== 'Draft' ||
    !isApprovalRequired ||
    lineItemMessages ||
    missingDiscountInLegacy
);

export const getCRMContactProposal = (state: RootState, crmId: string) => {
  const lead = getCRMLead(state, crmId);

  if (lead && lead.contactId) {
    return state.customer.crmContacts[lead.contactId.Id];
  }
};

export const getCustomerTpid = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state)
) => {
  if (!proposal) return;
  const crmId = utils.getCRMId(proposal);
  const salesAccount = getSalesAccountFromCrmId(state, crmId);
  return salesAccount ? salesAccount.MsSalesTpId : undefined;
};

export const isProductsView = (state: RootState) =>
  state.router.location.pathname.includes('products');

export const getMinimumCreditLine = createSelector(getActiveProposal, proposal => {
  const minCreditLimit = oc(proposal).header.soldToCustomerLegalEntity.monthlyCreditLimit();
  return (minCreditLimit && minCreditLimit.toString()) || '';
});

export const getCreditInfo = (state: RootState): CreditInfo | undefined => {
  const quote = getActiveProposal(state);
  const organizationId = getOrganizationId(state, quote);
  const currency = (quote && quote.header.pricingContext.billingCurrency) || 'USD';
  const isSafelistedCreditCheckEnabled = getFlightIsEnabled(state, Flight.safelistedCreditCheck);
  const organization = isSafelistedCreditCheckEnabled
    ? safelistedCID.organizationId
    : organizationId;
  return organization
    ? state.proposal.credit[organization]
      ? state.proposal.credit[organization][currency]
      : undefined
    : undefined;
};

export const getCreditLineStatus = createSelector(
  getCreditInfo,
  getMinimumCreditLine,
  (creditInfo, minimumCreditLine) => {
    let status: CreditLineReason = CreditLineReason.None;
    if (creditInfo) {
      const reason = creditInfo.reasons.reason;
      const decision = creditInfo.decision;

      if (decision === 'approved' && reason && reason.indexOf(CreditLineReason.SafeList) >= 0) {
        status = CreditLineReason.SafeList;
      } else if (decision === 'approved' && !reason) {
        if (Number(creditInfo.reasons.credit_line) < Number(minimumCreditLine)) {
          status = CreditLineReason.CreditIncrease;
        } else {
          status = CreditLineReason.CreditLine;
        }
      } else if (reason === CreditLineReason.PendingReview) {
        status = CreditLineReason.PendingReview;
      } else if (reason === CreditLineReason.UnderReview) {
        status = CreditLineReason.UnderReview;
      } else if (reason === CreditLineReason.ReviewCancelled) {
        status = CreditLineReason.ReviewCancelled;
      } else if (reason === CreditLineReason.Rejected) {
        status = CreditLineReason.Rejected;
      }
    }
    return status;
  }
);

export const isActiveProposalOld = createSelector(
  getActiveProposal,
  proposal =>
    oc(proposal).header.createdApiVersion() &&
    isOldProposalVersion(proposal.header.createdApiVersion)
);

export const getBillingCurrency = createSelector(
  getActiveProposal,
  // TODO: michmel - log this when we dont have billing currency
  proposal => oc(proposal).header.pricingContext.billingCurrency(DEFAULT_CURRENCY)
);

export const disableShare = createSelector(
  isProposalReadOnly,
  getActiveProposal,
  (proposalReadOnly, proposal) => {
    const proposalActiveOrActivable = isProposalActiveOrActivable(proposal);

    return !(
      proposalActiveOrActivable &&
      proposalReadOnly &&
      proposal.header.assignedToGroup === UserGroup.Customer
    );
  }
);

export const getSkuTitles = (state: RootState) => state.proposal.views.editor.skuTitles;

export const getExchangeRate = createSelector(getActiveProposal, proposal => {
  const exchangeRateLineItem = proposal.lineItems.find(
    item => item.exchangeRate && item.exchangeRate > 0
  );
  return oc(exchangeRateLineItem).exchangeRate();
});

export const getExchangeDate = createSelector(getActiveProposal, proposal => {
  return oc(proposal).header.pricingContext.exchangeRateDate();
});

export const showPriceConversion = createSelector(
  getActiveProposal,
  getExchangeRate,
  getBillingCurrency,
  (proposal, exchangeRate, billingCurrency) => {
    const isForeign = billingCurrency !== DEFAULT_CURRENCY;
    return !utils.isAgreementTypeLegacy(proposal) && isForeign && exchangeRate;
  }
);

export const getAllProductIds = (proposal: Proposal) => {
  const ids = proposal.lineItems.map(item => item.productIdentifier.productId);
  const discountedIds = proposal.lineItems
    .filter(
      lineItem => lineItem.productIdentifier.productId === productIds.discountFulfillmentDocument
    )
    .map(lineItem => oc(lineItem).pricingInstruction.productIdentifier.productId(''));
  ids.push(...discountedIds);
  return ids;
};

export const hasAnyDiscount = createSelector(getActiveProposal, proposal => {
  return proposal.lineItems.some(lineItem => {
    return oc(lineItem).pricingInstruction();
  });
});

export const getCrmLeadType = (state: RootState, crmId: string) => {
  const crmLead = state.customer.CRMLead[crmId];

  if (crmLead) {
    return crmLead.type;
  }
};

export const getSoldToOrgId = (
  state: RootState,
  proposal: ProposalSummary | Proposal = getActiveProposal(state)
) => {
  if (!proposal) return;
  const soldTo = proposal.header.soldToCustomerLegalEntity;
  return soldTo ? soldTo.organizationId : undefined;
};

export const searchingWorkAccount = createLoadingSelector(['@@externaluser/LOAD']);
export const errorSearchingWorkAccount = createErrorMessageSelector(['@@externaluser/LOAD']);

export const getValidatedWorkAccounts = (state: RootState) =>
  state.proposal.views.editor.workAccountResults;

export const convertingToLegacy = createLoadingSelector(['@@proposal/legacy/MAP']);
export const errorOnConvertingToLegacy = createErrorMessageSelector(['@@proposal/legacy/MAP']);

export const loadingEmpowerments = createLoadingSelector(['@@empowerments/LOAD']);
export const upgradingQuote = createProcessingSelectors(['@@quote/UPGRADE']);

export const doesQuoteNeedUpgrade = (quote: Proposal) =>
  !utils.isAgreementTypeLegacy(quote) &&
  +quote.header.modifiedApiVersion >= +utils.minimumQuoteVersionSupported &&
  +quote.header.modifiedApiVersion < +utils.quoteVersionToUpgradeTo &&
  utils.statusWhereQuoteCanBeUpgraded.includes(quote.header.status);

export const updatingBillingContactInformation = createLoadingSelector([
  '@@organization/UPDATE_CONTACT_INFORMATION',
  '@@organization/UPDATE',
]);

export const getContactInformationFromOrganizationId = (
  state: RootState,
  organizationId: string
): { email?: string; phoneNumber?: string } => {
  const organization = organizationId && getOrganization(state, organizationId);
  return {
    email: organization && organization.legalEntity.address.email,
    phoneNumber: organization && organization.legalEntity.address.phoneNumber,
  };
};

/**
 * Returns the sales account that is linked to the currect active quote
 * @param state Redux state
 * @returns {SalesAccount | undefined}
 */
export const getSalesAccountFromActiveQuote = (state: RootState) => {
  const quote = getActiveProposal(state);
  const crmId = quote && utils.getCRMId(quote);
  return crmId ? getSalesAccountFromCrmId(state, crmId) : undefined;
};
