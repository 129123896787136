import i18next from 'i18n';
import { IComboBoxOption } from 'office-ui-fabric-react';
import { NeedsTranslation, t } from 'services/utils';

import { Market } from './supported-markets';

export type MarketWithRegions = 'AU' | 'CA' | 'ES' | 'IT' | 'NL' | 'US';
export interface RegionInfo {
  display: string;
  translation: NeedsTranslation;
}

export const regions: Record<MarketWithRegions, Record<string, RegionInfo>> = {
  AU: {
    ACT: {
      display: 'Australian Capital Territory',
      translation: t('region::Australian Capital Territory'),
    },
    JBT: { display: 'Jervis Bay Territory', translation: t('region::Jervis Bay Territory') },
    NSW: { display: 'New South Wales', translation: t('region::New South Wales') },
    NT: { display: 'Northern Territory', translation: t('region::Northern Territory') },
    QLD: { display: 'Queensland', translation: t('region::Queensland') },
    SA: { display: 'South Australia', translation: t('region::South Australia') },
    TAS: { display: 'Tasmania', translation: t('region::Tasmania') },
    VIC: { display: 'Victoria', translation: t('region::Victoria') },
    WA: { display: 'Western Australia', translation: t('region::Western Australia') },
  },
  CA: {
    AB: { display: 'Alberta', translation: t('region::Alberta') },
    BC: { display: 'British Columbia', translation: t('region::British Columbia') },
    MB: { display: 'Manitoba', translation: t('region::Manitoba') },
    NB: { display: 'New Brunswick', translation: t('region::New Brunswick') },
    NL: {
      display: 'Newfoundland and Labrador',
      translation: t('region::Newfoundland and Labrador'),
    },
    NS: { display: 'Nova Scotia', translation: t('region::Nova Scotia') },
    NT: { display: 'Northwest Territories', translation: t('region::Northwest Territories') },
    NU: { display: 'Nunavut', translation: t('region::Nunavut') },
    ON: { display: 'Ontario', translation: t('region::Ontario') },
    PE: { display: 'Prince Edward Island', translation: t('region::Prince Edward Island') },
    QC: { display: 'Quebec', translation: t('region::Quebec') },
    SK: { display: 'Saskatchewan', translation: t('region::Saskatchewan') },
    YT: { display: 'Yukon', translation: t('region::Yukon') },
  },
  ES: {
    A: { display: 'Alicante', translation: t('region::Alicante') },
    AB: { display: 'Albacete', translation: t('region::Albacete') },
    AL: { display: 'Almeria', translation: t('region::Almeria') },
    AV: { display: 'Ávila', translation: t('region::Ávila') },
    B: { display: 'Barcelona', translation: t('region::Barcelona') },
    BA: { display: 'Badajoz', translation: t('region::Badajoz') },
    BI: { display: 'Bizkaia', translation: t('region::Bizkaia') },
    BU: { display: 'Burgos', translation: t('region::Burgos') },
    CA: { display: 'Cádiz', translation: t('region::Cádiz') },
    CC: { display: 'Cáceres', translation: t('region::Cáceres') },
    CO: { display: 'Córdoba', translation: t('region::Córdoba') },
    CR: { display: 'Ciudad Real', translation: t('region::Ciudad Real') },
    CS: { display: 'Castellón', translation: t('region::Castellón') },
    CU: { display: 'Cuenca', translation: t('region::Cuenca') },
    GC: { display: 'Las Palmas', translation: t('region::Las Palmas') },
    GI: { display: 'Girona', translation: t('region::Girona') },
    GR: { display: 'Granada', translation: t('region::Granada') },
    GU: { display: 'Guadalajara', translation: t('region::Guadalajara') },
    H: { display: 'Huelva', translation: t('region::Huelva') },
    HU: { display: 'Huesca', translation: t('region::Huesca') },
    J: { display: 'Jaén', translation: t('region::Jaén') },
    L: { display: 'Lleida', translation: t('region::Lleida') },
    LE: { display: 'León', translation: t('region::León') },
    LO: { display: 'La Rioja', translation: t('region::La Rioja') },
    LU: { display: 'Lugo', translation: t('region::Lugo') },
    M: { display: 'Madrid', translation: t('region::Madrid') },
    MA: { display: 'Málaga', translation: t('region::Málaga') },
    MU: { display: 'Murcia', translation: t('region::Murcia') },
    NA: { display: 'Navarra', translation: t('region::Navarra') },
    O: { display: 'Asturias', translation: t('region::Asturias') },
    OR: { display: 'Ourense', translation: t('region::Ourense') },
    P: { display: 'Palencia', translation: t('region::Palencia') },
    PM: { display: 'Balears', translation: t('region::Balears') },
    PO: { display: 'Pontevedra', translation: t('region::Pontevedra') },
    S: { display: 'Cantabria', translation: t('region::Cantabria') },
    SA: { display: 'Salamanca', translation: t('region::Salamanca') },
    SE: { display: 'Sevilla', translation: t('region::Sevilla') },
    SG: { display: 'Segovia', translation: t('region::Segovia') },
    SO: { display: 'Soria', translation: t('region::Soria') },
    SS: { display: 'Gipuzkoa', translation: t('region::Gipuzkoa') },
    T: { display: 'Tarragona', translation: t('region::Tarragona') },
    TE: { display: 'Teruel', translation: t('region::Teruel') },
    TF: { display: 'Santa Cruz de Tenerife', translation: t('region::Santa Cruz de Tenerife') },
    TO: { display: 'Toledo', translation: t('region::Toledo') },
    V: { display: 'Valencia', translation: t('region::Valencia') },
    VA: { display: 'Valladolid', translation: t('region::Valladolid') },
    VI: { display: 'Álava', translation: t('region::Álava') },
    Z: { display: 'Zaragoza', translation: t('region::Zaragoza') },
    ZA: { display: 'Zamora', translation: t('region::Zamora') },
  },
  IT: {
    AG: { display: 'Agrigento', translation: t('region::Agrigento') },
    AL: { display: 'Alessandria', translation: t('region::Alessandria') },
    AN: { display: 'Ancona', translation: t('region::Ancona') },
    AO: { display: 'Aosta', translation: t('region::Aosta') },
    AP: { display: 'Ascoli Piceno', translation: t('region::Ascoli Piceno') },
    AQ: { display: `L'Aquila`, translation: t(`region::L'Aquila`) },
    AR: { display: 'Arezzo', translation: t('region::Arezzo') },
    AT: { display: 'Asti', translation: t('region::Asti') },
    AV: { display: 'Avellino', translation: t('region::Avellino') },
    BA: { display: 'Bari', translation: t('region::Bari') },
    BG: { display: 'Bergamo', translation: t('region::Bergamo') },
    BI: { display: 'Biella', translation: t('region::Biella') },
    BL: { display: 'Belluno', translation: t('region::Belluno') },
    BN: { display: 'Benevento', translation: t('region::Benevento') },
    BO: { display: 'Bologna', translation: t('region::Bologna') },
    BR: { display: 'Brindisi', translation: t('region::Brindisi') },
    BS: { display: 'Brescia', translation: t('region::Brescia') },
    BT: { display: 'Barletta-Andria-Trani', translation: t('region::Barletta-Andria-Trani') },
    BZ: { display: 'Bolzano', translation: t('region::Bolzano') },
    CA: { display: 'Cagliari', translation: t('region::Cagliari') },
    CB: { display: 'Campobasso', translation: t('region::Campobasso') },
    CE: { display: 'Caserta', translation: t('region::Caserta') },
    CH: { display: 'Chieti', translation: t('region::Chieti') },
    CI: { display: 'Carbonia-Iglesias', translation: t('region::Carbonia-Iglesias') },
    CL: { display: 'Caltanissetta', translation: t('region::Caltanissetta') },
    CN: { display: 'Cuneo', translation: t('region::Cuneo') },
    CO: { display: 'Como', translation: t('region::Como') },
    CR: { display: 'Cremona', translation: t('region::Cremona') },
    CS: { display: 'Cosenza', translation: t('region::Cosenza') },
    CT: { display: 'Catania', translation: t('region::Catania') },
    CZ: { display: 'Catanzaro', translation: t('region::Catanzaro') },
    EN: { display: 'Enna', translation: t('region::Enna') },
    FC: { display: 'Forli-Cesena', translation: t('region::Forli-Cesena') },
    FE: { display: 'Ferrara', translation: t('region::Ferrara') },
    FG: { display: 'Foggia', translation: t('region::Foggia') },
    FI: { display: 'Florence', translation: t('region::Florence') },
    FM: { display: 'Fermo', translation: t('region::Fermo') },
    FR: { display: 'Frosinone', translation: t('region::Frosinone') },
    GE: { display: 'Genoa', translation: t('region::Genoa') },
    GO: { display: 'Gorizia', translation: t('region::Gorizia') },
    GR: { display: 'Grosseto', translation: t('region::Grosseto') },
    IM: { display: 'Imperia', translation: t('region::Imperia') },
    IS: { display: 'Isernia', translation: t('region::Isernia') },
    KR: { display: 'Crotone', translation: t('region::Crotone') },
    LC: { display: 'Lecco', translation: t('region::Lecco') },
    LE: { display: 'Lecce', translation: t('region::Lecce') },
    LI: { display: 'Livorno', translation: t('region::Livorno') },
    LO: { display: 'Lodi', translation: t('region::Lodi') },
    LT: { display: 'Latina', translation: t('region::Latina') },
    LU: { display: 'Lucca', translation: t('region::Lucca') },
    MB: { display: 'Monza and Brianza', translation: t('region::Monza and Brianza') },
    MC: { display: 'Macerata', translation: t('region::Macerata') },
    ME: { display: 'Messina', translation: t('region::Messina') },
    MI: { display: 'Milan', translation: t('region::Milan') },
    MN: { display: 'Mantua', translation: t('region::Mantua') },
    MO: { display: 'Modena', translation: t('region::Modena') },
    MS: { display: 'Massa and Carrara', translation: t('region::Massa and Carrara') },
    MT: { display: 'Matera', translation: t('region::Matera') },
    NA: { display: 'Naples', translation: t('region::Naples') },
    NO: { display: 'Novara', translation: t('region::Novara') },
    NU: { display: 'Nuoro', translation: t('region::Nuoro') },
    OG: { display: 'Ogliastra', translation: t('region::Ogliastra') },
    OR: { display: 'Oristano', translation: t('region::Oristano') },
    OT: { display: 'Olbia-Tempio', translation: t('region::Olbia-Tempio') },
    PA: { display: 'Palermo', translation: t('region::Palermo') },
    PC: { display: 'Piacenza', translation: t('region::Piacenza') },
    PD: { display: 'Padua', translation: t('region::Padua') },
    PE: { display: 'Pescara', translation: t('region::Pescara') },
    PG: { display: 'Perugia', translation: t('region::Perugia') },
    PI: { display: 'Pisa', translation: t('region::Pisa') },
    PN: { display: 'Pordenone', translation: t('region::Pordenone') },
    PO: { display: 'Prato', translation: t('region::Prato') },
    PR: { display: 'Parma', translation: t('region::Parma') },
    PT: { display: 'Pistoia', translation: t('region::Pistoia') },
    PU: { display: 'Pesaro and Urbino', translation: t('region::Pesaro and Urbino') },
    PV: { display: 'Pavia', translation: t('region::Pavia') },
    PZ: { display: 'Potenza', translation: t('region::Potenza') },
    RA: { display: 'Ravenna', translation: t('region::Ravenna') },
    RC: { display: 'Reggio Calabria', translation: t('region::Reggio Calabria') },
    RE: { display: 'Reggio Emilia', translation: t('region::Reggio Emilia') },
    RG: { display: 'Ragusa', translation: t('region::Ragusa') },
    RI: { display: 'Rieti', translation: t('region::Rieti') },
    RM: { display: 'Rome', translation: t('region::Rome') },
    RN: { display: 'Rimini', translation: t('region::Rimini') },
    RO: { display: 'Rovigo', translation: t('region::Rovigo') },
    SA: { display: 'Salerno', translation: t('region::Salerno') },
    SI: { display: 'Siena', translation: t('region::Siena') },
    SO: { display: 'Sondrio', translation: t('region::Sondrio') },
    SP: { display: 'La Spezia', translation: t('region::La Spezia') },
    SR: { display: 'Syracuse', translation: t('region::Syracuse') },
    SS: { display: 'Sassari', translation: t('region::Sassari') },
    SV: { display: 'Savona', translation: t('region::Savona') },
    TA: { display: 'Taranto', translation: t('region::Taranto') },
    TE: { display: 'Teramo', translation: t('region::Teramo') },
    TN: { display: 'Trento', translation: t('region::Trento') },
    TO: { display: 'Turin', translation: t('region::Turin') },
    TP: { display: 'Trapani', translation: t('region::Trapani') },
    TR: { display: 'Terni', translation: t('region::Terni') },
    TS: { display: 'Trieste', translation: t('region::Trieste') },
    TV: { display: 'Treviso', translation: t('region::Treviso') },
    UD: { display: 'Udine', translation: t('region::Udine') },
    VA: { display: 'Varese', translation: t('region::Varese') },
    VB: { display: 'Verbano-Cusio-Ossola', translation: t('region::Verbano-Cusio-Ossola') },
    VC: { display: 'Vercelli', translation: t('region::Vercelli') },
    VE: { display: 'Venice', translation: t('region::Venice') },
    VI: { display: 'Vicenza', translation: t('region::Vicenza') },
    VR: { display: 'Verona', translation: t('region::Verona') },
    VS: { display: 'Medio Campidano', translation: t('region::Medio Campidano') },
    VT: { display: 'Viterbo', translation: t('region::Viterbo') },
    VV: { display: 'Vibo Valentia', translation: t('region::Vibo Valentia') },
  },
  NL: {
    DR: { display: 'Drenthe', translation: t('region::Drenthe') },
    FL: { display: 'Flevoland', translation: t('region::Flevoland') },
    FR: { display: 'Friesland', translation: t('region::Friesland') },
    GE: { display: 'Gelderland', translation: t('region::Gelderland') },
    GR: { display: 'Groningen', translation: t('region::Groningen') },
    LI: { display: 'Limburg', translation: t('region::Limburg') },
    NB: { display: 'Noord-Brabant', translation: t('region::Noord-Brabant') },
    NH: { display: 'Noord-Holland', translation: t('region::Noord-Holland') },
    OV: { display: 'Overijssel', translation: t('region::Overijssel') },
    UT: { display: 'Utrecht', translation: t('region::Utrecht') },
    ZE: { display: 'Zeeland', translation: t('region::Zeeland') },
    ZH: { display: 'Zuid-Holland', translation: t('region::Zuid-Holland') },
  },
  US: {
    AA: { display: 'Armed Forces America', translation: t('region::Armed Forces America') },
    AE: { display: 'Armed Forces Africa', translation: t('region::Armed Forces Africa') },
    AK: { display: 'Alaska', translation: t('region::Alaska') },
    AL: { display: 'Alabama', translation: t('region::Alabama') },
    AP: { display: 'Armed Forces Pacific', translation: t('region::Armed Forces Pacific') },
    AR: { display: 'Arkansas', translation: t('region::Arkansas') },
    AZ: { display: 'Arizona', translation: t('region::Arizona') },
    CA: { display: 'California', translation: t('region::California') },
    CO: { display: 'Colorado', translation: t('region::Colorado') },
    CT: { display: 'Connecticut', translation: t('region::Connecticut') },
    DC: { display: 'Washington D.C.', translation: t('region::Washington D.C.') },
    DE: { display: 'Delaware', translation: t('region::Delaware') },
    FL: { display: 'Florida', translation: t('region::Florida') },
    GA: { display: 'Georgia', translation: t('region::Georgia') },
    HI: { display: 'Hawaii', translation: t('region::Hawaii') },
    IA: { display: 'Iowa', translation: t('region::Iowa') },
    ID: { display: 'Idaho', translation: t('region::Idaho') },
    IL: { display: 'Illinois', translation: t('region::Illinois') },
    IN: { display: 'Indiana', translation: t('region::Indiana') },
    KS: { display: 'Kansas', translation: t('region::Kansas') },
    KY: { display: 'Kentucky', translation: t('region::Kentucky') },
    LA: { display: 'Louisiana', translation: t('region::Louisiana') },
    MA: { display: 'Massachusetts', translation: t('region::Massachusetts') },
    MD: { display: 'Maryland', translation: t('region::Maryland') },
    ME: { display: 'Maine', translation: t('region::Maine') },
    MI: { display: 'Michigan', translation: t('region::Michigan') },
    MN: { display: 'Minnesota', translation: t('region::Minnesota') },
    MO: { display: 'Missouri', translation: t('region::Missouri') },
    MS: { display: 'Mississippi', translation: t('region::Mississippi') },
    MT: { display: 'Montana', translation: t('region::Montana') },
    NC: { display: 'North Carolina', translation: t('region::North Carolina') },
    ND: { display: 'North Dakota', translation: t('region::North Dakota') },
    NE: { display: 'Nebraska', translation: t('region::Nebraska') },
    NH: { display: 'New Hampshire', translation: t('region::New Hampshire') },
    NJ: { display: 'New Jersey', translation: t('region::New Jersey') },
    NM: { display: 'New Mexico', translation: t('region::New Mexico') },
    NV: { display: 'Nevada', translation: t('region::Nevada') },
    NY: { display: 'New York', translation: t('region::New York') },
    OH: { display: 'Ohio', translation: t('region::Ohio') },
    OK: { display: 'Oklahoma', translation: t('region::Oklahoma') },
    OR: { display: 'Oregon', translation: t('region::Oregon') },
    PA: { display: 'Pennsylvania', translation: t('region::Pennsylvania') },
    RI: { display: 'Rhode Island', translation: t('region::Rhode Island') },
    SC: { display: 'South Carolina', translation: t('region::South Carolina') },
    SD: { display: 'South Dakota', translation: t('region::South Dakota') },
    TN: { display: 'Tennessee', translation: t('region::Tennessee') },
    TX: { display: 'Texas', translation: t('region::Texas') },
    UT: { display: 'Utah', translation: t('region::Utah') },
    VA: { display: 'Virginia', translation: t('region::Virginia') },
    VT: { display: 'Vermont', translation: t('region::Vermont') },
    WA: { display: 'Washington', translation: t('region::Washington') },
    WI: { display: 'Wisconsin', translation: t('region::Wisconsin') },
    WV: { display: 'West Virginia', translation: t('region::West Virginia') },
    WY: { display: 'Wyoming', translation: t('region::Wyoming') },
  },
};

export const getRegions = (market: MarketWithRegions): IComboBoxOption[] => {
  return Object.keys(regions[market]).map((key: string) => {
    return { key, text: regions[market][key].display, data: regions[market][key].translation };
  });
};

export const isMarketWithRegions = (market?: Market) =>
  market && (!!regions[market as MarketWithRegions] as boolean);

export const getTranslatedRegionsAlphabetically = (
  t: i18next.TFunction,
  market: MarketWithRegions
): IComboBoxOption[] => {
  const regionsList = getRegions(market);
  const sortedRegions = regionsList.sort((r1, r2) => {
    const translation1 = t(r1.data.value);
    const translation2 = t(r2.data.value);
    return translation1.localeCompare(translation2);
  });
  return sortedRegions;
};

export const getRegionCodeIfExists = (
  market: MarketWithRegions,
  regionCode?: string
): string | undefined => {
  if (!regionCode) {
    return;
  }
  const regionCodeInUpperCase = regionCode.toUpperCase();
  const match = market && regions[market][regionCodeInUpperCase];
  return match ? regionCodeInUpperCase : undefined;
};

export const getRegionCodeByRegionName = (
  market: MarketWithRegions,
  regionName?: string
): string | undefined => {
  if (!regionName) {
    return;
  }
  const marketRegions = regions[market];
  const matchedKey =
    !!market &&
    !!regionName &&
    Object.keys(marketRegions).find(
      key => marketRegions[key].display.toUpperCase() === regionName.toUpperCase()
    );
  return matchedKey || undefined;
};

export const getTranslatedRegion = (
  t: i18next.TFunction,
  market: MarketWithRegions,
  region?: string
) => {
  const match = getRegionCodeIfExists(market, region) || getRegionCodeByRegionName(market, region);
  if (match) {
    const regionInfo = regions[market][match];
    return t(regionInfo.translation.value);
  }
};

export enum RegionType {
  Province,
  State,
  Region,
}

export enum RegionDisplayType {
  Required,
  Optional,
  None,
}

export interface RegionField {
  display: RegionDisplayType;
  type: RegionType;
}

export const requiredRegion: RegionField = {
  display: RegionDisplayType.Required,
  type: RegionType.Region,
};

export const optionalRegion: RegionField = {
  display: RegionDisplayType.Optional,
  type: RegionType.Region,
};

export const noRegion: RegionField = {
  display: RegionDisplayType.None,
  type: RegionType.Region,
};

export const requiredProvince: RegionField = {
  display: RegionDisplayType.Required,
  type: RegionType.Province,
};

export const requiredState: RegionField = {
  display: RegionDisplayType.Required,
  type: RegionType.State,
};
