export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Date: any;
};

export enum AcceptanceMode {
  ClickToAccept = 'ClickToAccept',
  ESignEmbedded = 'ESignEmbedded',
  ESignOffline = 'ESignOffline',
  Offline = 'Offline',
  PhysicalSign = 'PhysicalSign'
}

export type Account = {
   __typename?: 'Account';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  purchaseMotion?: Maybe<Scalars['String']>;
  cloudScope?: Maybe<Scalars['String']>;
  primaryTenantId?: Maybe<Scalars['String']>;
  externalIds: Array<Scalars['String']>;
  tenants: Array<AccountTenant>;
  assets?: Maybe<Array<Asset>>;
};

export type AccountTenant = {
   __typename?: 'AccountTenant';
  tenantId: Scalars['String'];
  tenantName?: Maybe<Scalars['String']>;
};

export type AddModernAgreementParticipantsInput = {
  agreementId: Scalars['String'];
  quoteId: Scalars['String'];
  participants: Array<ParticipantInput>;
};

export type Address = {
   __typename?: 'Address';
  companyName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  addressLastLine?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  marketInfo?: Maybe<MarketInfo>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AddressConfiguration = {
   __typename?: 'AddressConfiguration';
  fieldGroupInOrder: Array<AddressFieldType>;
  region: AddressRegion;
};

export enum AddressFieldType {
  City = 'city',
  Region = 'region',
  PostalCode = 'postalCode'
}

export type AddressIdentifier = {
   __typename?: 'AddressIdentifier';
  accountId: Scalars['String'];
  organizationId: Scalars['String'];
  businessLocationId: Scalars['String'];
  businessLocationVersion: Scalars['Int'];
};

export type AddressInput = {
  name?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AddressRegion = {
   __typename?: 'AddressRegion';
  display: RegionDisplayType;
  type: RegionType;
};

export type AddressValidationResponse = {
   __typename?: 'AddressValidationResponse';
  status: AddressValidationStatus;
  errorFields: Array<Scalars['String']>;
  suggestedAddress?: Maybe<Address>;
};

export enum AddressValidationStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  Warning = 'Warning'
}

export type AffirmationParameter = {
   __typename?: 'AffirmationParameter';
  key: Scalars['String'];
  value: Scalars['String'];
  type: Scalars['String'];
};

/** Agreement type will be deprecated to be replaced by SimpleAgreement */
export type Agreement = AgreementBase & {
   __typename?: 'Agreement';
  id?: Maybe<Scalars['ID']>;
  tpid?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  licenseAgreementType?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  agreementNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  publicCustomerNumber?: Maybe<Scalars['String']>;
  licenseConfirmationNumber?: Maybe<Scalars['String']>;
  mpsaNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  billableAccountId?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<AgreementAddress>;
  tradeStatusName?: Maybe<Scalars['String']>;
  creditHold?: Maybe<Scalars['String']>;
  customerContacts?: Maybe<Array<Maybe<Contact>>>;
  parentAgreementNumber?: Maybe<Scalars['String']>;
  anniversaryDate?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<AgreementParticipant>>>;
  /** discount will be deprecated and replaced by discountSummary */
  discount?: Maybe<AgreementDiscount>;
  discountSummary?: Maybe<AgreementDiscount>;
  amendments?: Maybe<AmendmentConnection>;
  opportunities?: Maybe<OpportunityConnection>;
  tenants?: Maybe<TenantConnection>;
  purchaseOrders?: Maybe<PurchaseOrderConnection>;
};


/** Agreement type will be deprecated to be replaced by SimpleAgreement */
export type AgreementAmendmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


/** Agreement type will be deprecated to be replaced by SimpleAgreement */
export type AgreementOpportunitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


/** Agreement type will be deprecated to be replaced by SimpleAgreement */
export type AgreementTenantsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


/** Agreement type will be deprecated to be replaced by SimpleAgreement */
export type AgreementPurchaseOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};

export type AgreementAddress = {
   __typename?: 'AgreementAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AgreementBase = {
  id?: Maybe<Scalars['ID']>;
  tpid?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  licenseAgreementType?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  agreementNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  publicCustomerNumber?: Maybe<Scalars['String']>;
  licenseConfirmationNumber?: Maybe<Scalars['String']>;
  mpsaNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  billableAccountId?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<AgreementAddress>;
  tradeStatusName?: Maybe<Scalars['String']>;
  creditHold?: Maybe<Scalars['String']>;
  customerContacts?: Maybe<Array<Maybe<Contact>>>;
  parentAgreementNumber?: Maybe<Scalars['String']>;
  anniversaryDate?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<AgreementParticipant>>>;
  /** discount will be deprecated and replaced by discountSummary */
  discount?: Maybe<AgreementDiscount>;
  discountSummary?: Maybe<AgreementDiscount>;
};

/** To be deprecated by SimpleAgreementConnection */
export type AgreementConnection = Connection & {
   __typename?: 'AgreementConnection';
  id?: Maybe<Scalars['ID']>;
  edges?: Maybe<Array<Maybe<AgreementEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AgreementDiscount = {
   __typename?: 'AgreementDiscount';
  agreementId?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  agreementNumber?: Maybe<Scalars['String']>;
  dealCurrencyCode?: Maybe<Scalars['String']>;
  totalDealValuePriorToDiscountLocal?: Maybe<Scalars['Float']>;
  totalDealValuePostDiscountLocal?: Maybe<Scalars['Float']>;
  totalDiscountPercent?: Maybe<Scalars['Float']>;
  totalDealValuePriorToDiscountUsd?: Maybe<Scalars['Float']>;
  totalDealValuePostDiscountUsd?: Maybe<Scalars['Float']>;
  minDiscountPercent?: Maybe<Scalars['Float']>;
  minNonZeroDiscountPercent?: Maybe<Scalars['Float']>;
  maxDiscountPercent?: Maybe<Scalars['Float']>;
  distinctSkusWithDiscounts?: Maybe<Scalars['Float']>;
  distinctSkus?: Maybe<Scalars['Float']>;
};

/** To be deprecated by SimpleAgreementEdge */
export type AgreementEdge = Edge & {
   __typename?: 'AgreementEdge';
  id?: Maybe<Scalars['ID']>;
  cursor?: Maybe<Scalars['String']>;
  node: Agreement;
};

export type AgreementHeader = {
   __typename?: 'AgreementHeader';
  id?: Maybe<Scalars['ID']>;
  accountName?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['String']>;
  agreementNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isCountryMatch?: Maybe<Scalars['Boolean']>;
  isEnterpriseAgreement?: Maybe<Scalars['Boolean']>;
  isRenewed?: Maybe<Scalars['Boolean']>;
  licenseAgreementType?: Maybe<Scalars['String']>;
  priorAgreementNumber?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tpid?: Maybe<Scalars['Int']>;
  agreement?: Maybe<Agreement>;
};

export type AgreementParticipant = {
   __typename?: 'AgreementParticipant';
  name?: Maybe<Scalars['String']>;
  publicCustomerNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AgreementAddress>;
  participantContacts?: Maybe<Array<Maybe<Contact>>>;
  participantType?: Maybe<Scalars['String']>;
};

export enum AgreementType {
  Modern = 'modern',
  Legacy = 'legacy'
}

export enum AgreementTypes {
  Mca = 'MCA',
  Apt = 'APT',
  Special = 'SPECIAL'
}

export type Amendment = {
   __typename?: 'Amendment';
  referenceId?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  amendmentNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  appliedDate?: Maybe<Scalars['DateTime']>;
  isEvergreen?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  displayAttributes?: Maybe<Array<AmendmentAttribute>>;
};

export type AmendmentAttribute = {
  labelDisplay: Scalars['String'];
};

export type AmendmentConnection = Connection & {
   __typename?: 'AmendmentConnection';
  edges?: Maybe<Array<Maybe<AmendmentEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AmendmentCurrencyAttribute = AmendmentAttribute & {
   __typename?: 'AmendmentCurrencyAttribute';
  labelDisplay: Scalars['String'];
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
};

export type AmendmentEdge = Edge & {
   __typename?: 'AmendmentEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Amendment;
};

export type AmendmentStringAttribute = AmendmentAttribute & {
   __typename?: 'AmendmentStringAttribute';
  labelDisplay: Scalars['String'];
  valueDisplay: Scalars['String'];
};

export type ApplicableDiscounts = {
   __typename?: 'ApplicableDiscounts';
  types: Array<DiscountType>;
  minStartDate?: Maybe<Scalars['Date']>;
  datePrecision: DatePrecision;
};

export type ApplyConfigurationFinanceInput = {
  lineItemId: Scalars['String'];
  termId: Scalars['String'];
  associatedId: Scalars['String'];
  associatedType: FinanceAssociationIdType;
};

export type ApplyConfigurationMonetaryInput = {
  lineItemId: Scalars['String'];
  amount: Scalars['Int'];
  /** In ISO duration format: P1Y, P24M etc. */
  duration: Scalars['String'];
  reasonCode?: Maybe<Scalars['String']>;
};

export type ApplyConfigurationMultipleSkusInput = {
  lineItemId: Scalars['String'];
  action: CatalogAction;
  conditions?: Maybe<Array<ConditionInput>>;
  configureAsNew?: Maybe<Scalars['Boolean']>;
};

export type ApplyConfigurationSapInput = {
  lineItemId: Scalars['String'];
  amount: Scalars['Int'];
  /** In ISO duration format: P1Y, P24M etc. */
  duration: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  opportunityId?: Maybe<Scalars['String']>;
};

export type ApplyConfigurationSingleSkuInput = {
  lineItemId: Scalars['String'];
  skuId: Scalars['String'];
  availabilityId: Scalars['String'];
  termId?: Maybe<Scalars['String']>;
  action: CatalogAction;
  autoRenew?: Maybe<Scalars['Boolean']>;
  supplementalTermReferenceData?: Maybe<KeyValueInput>;
  configureAsNew?: Maybe<Scalars['Boolean']>;
};

export type ApplyDiscountInput = {
  lineItemIds: Array<Scalars['String']>;
  discount: DiscountInput;
};

export type Approval = {
   __typename?: 'Approval';
  id: Scalars['String'];
  quote?: Maybe<Quote>;
  status: ApprovalStatus;
  requiredApprovals?: Maybe<Array<RequiredApproval>>;
  history: Array<ApprovalHistory>;
};

export enum ApprovalActionType {
  Submit = 'SUBMIT',
  Comment = 'COMMENT',
  Approve = 'APPROVE',
  Withdraw = 'WITHDRAW',
  Expire = 'EXPIRE',
  Reject = 'REJECT'
}

export type ApprovalActor = NonUserActor | User;

export type ApprovalHistory = {
   __typename?: 'ApprovalHistory';
  action: ApprovalActionType;
  actor?: Maybe<ApprovalActor>;
  comments?: Maybe<Scalars['String']>;
  status: ApprovalStatus;
  createdBy: ApprovalActor;
  createdDate: Scalars['DateTime'];
};

export type ApprovalLevelInput = {
  approvers: Array<Scalars['String']>;
  level: Scalars['String'];
};

export enum ApprovalLevelState {
  Pending = 'pending',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Expired = 'expired'
}

export enum ApprovalStatus {
  Pending = 'pending',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Expired = 'expired',
  Withdrawn = 'withdrawn',
  PartiallyApproved = 'partiallyApproved'
}

export type ApprovalUnion = Approval | QuoteApproval;

export type Asset = {
   __typename?: 'Asset';
  id: Scalars['ID'];
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  state: Scalars['String'];
  attributes: Array<Scalars['String']>;
  accountId: Scalars['String'];
  audience?: Maybe<Scalars['String']>;
  skuId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  beneficiaryOrganization?: Maybe<BeneficiaryOrganization>;
  termInfo?: Maybe<TermInfo>;
};

export type AssetTo = {
   __typename?: 'AssetTo';
  projectId: Scalars['String'];
  accountId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type AssociateCrmIdToMcapiInput = {
  crmId: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
};

export enum Audience {
  Charity = 'charity',
  Commercial = 'commercial',
  Education = 'education',
  Government = 'government'
}

export type Availability = {
   __typename?: 'Availability';
  productId: Scalars['String'];
  skuId: Scalars['String'];
  availabilityId: Scalars['String'];
  price?: Maybe<Price>;
  catalogContext: CatalogContext;
  terms?: Maybe<Array<Term>>;
  term: Term;
  actions: Array<CatalogAction>;
  meterType?: Maybe<Scalars['String']>;
  isTrial: Scalars['Boolean'];
  isAutoRenewable: Scalars['Boolean'];
};


export type AvailabilityPriceArgs = {
  context: PriceContext;
};


export type AvailabilityTermArgs = {
  termId: Scalars['String'];
};

export enum AzureType {
  Paid = 'Paid',
  Trial = 'Trial'
}

export type BaseRecommendationAction = {
  meta?: Maybe<Array<KeyValue>>;
  messages?: Maybe<Array<Message>>;
};

export type BaseTranslatedString = {
  display: Scalars['String'];
};

export type BeneficiaryOrganization = {
   __typename?: 'BeneficiaryOrganization';
  accountId: Scalars['String'];
  id: Scalars['String'];
};

export type BillingGroup = {
   __typename?: 'BillingGroup';
  id: Scalars['ID'];
  accountId: Scalars['String'];
  organizationId: Scalars['String'];
  billTo: Address;
  currency: Currency;
  purchaseContext: Scalars['String'];
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  roleAssignments: Array<RoleAssignments>;
};

export type BillingGroupConnection = Connection & {
   __typename?: 'BillingGroupConnection';
  edges?: Maybe<Array<BillingGroupEdge>>;
  pageInfo?: Maybe<PageInfo>;
};

export type BillingGroupEdge = Edge & {
   __typename?: 'BillingGroupEdge';
  cursor?: Maybe<Scalars['String']>;
  node: BillingGroup;
};

export type BillingPlanDetail = {
   __typename?: 'BillingPlanDetail';
  cycleCount: Scalars['Int'];
  cycleDuration: Scalars['String'];
  markupMultiplier?: Maybe<Scalars['Float']>;
};

export type BillTo = {
   __typename?: 'BillTo';
  accountId: Scalars['String'];
  billingGroupId: Scalars['String'];
  isChargeOnPurchase: Scalars['Boolean'];
  externalPoNumbers?: Maybe<Array<Scalars['String']>>;
  billOnPurchase: Scalars['Boolean'];
};

export type BusinessContact = {
   __typename?: 'BusinessContact';
  id: Scalars['String'];
  version: Scalars['Int'];
  roleType: BusinessContactRoleType;
};

export enum BusinessContactRoleType {
  Primary = 'primary',
  CommerceAdministrator = 'commerceAdministrator',
  Purchaser = 'purchaser',
  Signatory = 'signatory',
  Unknown = 'Unknown'
}

export enum CatalogAction {
  Browse = 'browse',
  Consume = 'consume',
  Details = 'details',
  Purchase = 'purchase'
}

export type CatalogClaims = {
   __typename?: 'CatalogClaims';
  pricingAudience?: Maybe<Scalars['String']>;
  nationalCloud?: Maybe<Scalars['String']>;
  agreementType?: Maybe<Scalars['String']>;
};

export type CatalogContext = {
   __typename?: 'CatalogContext';
  agreementType: AgreementType;
  audience: ProductAudience;
  nationalCloud: NationalCloud;
  market: Market;
  languages: GqlLanguage;
  action: CatalogAction;
};

export type CatalogContextInput = {
  agreementType: AgreementType;
  audience: ProductAudience;
  nationalCloud: NationalCloud;
  market: Market;
  languages: GqlLanguage;
  action: CatalogAction;
};

export type CatalogSearchInput = {
  searchText: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  catalogContext: CatalogContextInput;
  productFamily?: Maybe<ProductFamily>;
  serviceFamily?: Maybe<ServiceFamily>;
};

export type Charge = {
   __typename?: 'Charge';
  targetQuantity: Scalars['Int'];
  pricingCurrency: Scalars['String'];
  exchangeRate?: Maybe<Scalars['String']>;
  listPrice: Scalars['Float'];
  priceDetails: Array<PriceDetail>;
  customPrice?: Maybe<CustomPrice>;
  futureGuaranteedPrice?: Maybe<FutureGuaranteedPrice>;
  pricingRules?: Maybe<Array<PricingRule>>;
};

export type ChargePrice = {
   __typename?: 'ChargePrice';
  proratedPrice: Scalars['Float'];
  price: Scalars['Float'];
};

export type CloseReferralInput = {
  id: Scalars['String'];
  etag: Scalars['String'];
  crmId?: Maybe<Scalars['String']>;
};

export enum CommerceAuthorizationRole {
  OrganizationOwner = 'OrganizationOwner',
  OrganizationReader = 'OrganizationReader',
  BillingGroupOwner = 'BillingGroupOwner'
}

export type Condition = {
   __typename?: 'Condition';
  name: Scalars['String'];
  type: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type ConditionInput = {
  name: Scalars['String'];
  type: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type Connection = {
  pageInfo?: Maybe<PageInfo>;
};

export type Contact = {
   __typename?: 'Contact';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  participantType?: Maybe<Scalars['String']>;
};

export type CreateAccountOrganizationInput = {
  quote: QuoteMutationInput;
  accountDescription: Scalars['String'];
  tenantId?: Maybe<Scalars['String']>;
  tradeName?: Maybe<Scalars['String']>;
  organization: OrganizationInput;
};

export type CreateClaimInput = {
  name: Scalars['String'];
  crmId?: Maybe<Scalars['String']>;
  organization: OrganizationIdentifierInput;
};

export type CreateOrganizationInput = {
  quote: QuoteMutationInput;
  organization: OrganizationInput;
  accountId: Scalars['String'];
};

export type CreateOrUpdateModernAgreementInput = {
  quoteId: Scalars['String'];
  agreementType: AgreementTypes;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  participants: Array<ParticipantInput>;
  acceptanceMode?: Maybe<AcceptanceMode>;
};

export type CreateQuoteInput = {
  name: Scalars['String'];
  market: Market;
  language: GqlLanguage;
  billingCurrency: Currency;
  vlAgreementNumberForMigration?: Maybe<Scalars['String']>;
  motion?: Maybe<Motion>;
  audience?: Maybe<Audience>;
  clouds?: Maybe<Array<NationalCloud>>;
  fgoe?: Maybe<Scalars['Boolean']>;
  transactOnBehalf?: Maybe<Scalars['Boolean']>;
  noCustomer?: Maybe<Scalars['Boolean']>;
};

export type CreateReferralInput = {
  name: Scalars['String'];
  crmId?: Maybe<Scalars['String']>;
  azureType: AzureType;
  market: Market;
};

export type Credit = {
   __typename?: 'Credit';
  currency: Currency;
  decision?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  creditLine?: Maybe<Scalars['String']>;
  remainingBalance?: Maybe<Scalars['String']>;
  creditRiskScore?: Maybe<Scalars['String']>;
};

export type CrmContact = {
   __typename?: 'CrmContact';
  id: Scalars['ID'];
  mail?: Maybe<Scalars['String']>;
};

export type CrmLead = {
  id: Scalars['ID'];
  entityId: Scalars['String'];
  type: CrmType;
  title?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  vlAgreementNumber?: Maybe<Scalars['String']>;
  salesAccount?: Maybe<SalesAccount>;
  validity: CrmValidity;
};


export type CrmLeadValidityArgs = {
  motion: Motion;
};

export type CrmLeadConnection = Connection & {
   __typename?: 'CrmLeadConnection';
  edges?: Maybe<Array<Maybe<CrmLeadEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CrmLeadEdge = Edge & {
   __typename?: 'CrmLeadEdge';
  cursor?: Maybe<Scalars['String']>;
  node: CrmLead;
};

export type CrmLeadOld = {
   __typename?: 'CrmLeadOld';
  id?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['String']>;
  crmId: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  vlAgreementNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: CrmType;
  salesAccountId?: Maybe<Scalars['ID']>;
};

export enum CrmType {
  Opportunity = 'Opportunity',
  SuccessEngagement = 'SuccessEngagement',
  FgoeOpportunity = 'FgoeOpportunity'
}

export enum CrmValidity {
  Valid = 'Valid',
  InvalidCrmId = 'InvalidCrmId',
  MissingSalesAccount = 'MissingSalesAccount',
  UnsupportedSalesAccountMarket = 'UnsupportedSalesAccountMarket'
}

export type CultureInfo = {
   __typename?: 'CultureInfo';
  culture?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export enum Currency {
  Aed = 'AED',
  Ars = 'ARS',
  Aud = 'AUD',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Dkk = 'DKK',
  Egp = 'EGP',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Jpy = 'JPY',
  Krw = 'KRW',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Rub = 'RUB',
  Sar = 'SAR',
  Sek = 'SEK',
  Try = 'TRY',
  Twd = 'TWD',
  Usd = 'USD',
  Zar = 'ZAR'
}

export type CurrencyInfo = {
   __typename?: 'CurrencyInfo';
  code: Currency;
  display: Scalars['String'];
};

export type Customer = {
   __typename?: 'Customer';
  id?: Maybe<Scalars['ID']>;
  tpid?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  agreements?: Maybe<AgreementConnection>;
  simpleAgreements?: Maybe<SimpleAgreementConnection>;
  tenants?: Maybe<TenantConnection>;
};


export type CustomerAgreementsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type CustomerSimpleAgreementsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type CustomerTenantsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};

export enum CustomerIntent {
  Unknown = 'Unknown',
  New = 'New',
  Renew = 'Renew',
  AddQuantity = 'AddQuantity',
  RemoveQuantity = 'RemoveQuantity',
  Convert = 'Convert',
  Cancel = 'Cancel',
  CancelImmediate = 'CancelImmediate',
  Reinstate = 'Reinstate',
  CustomerCredit = 'CustomerCredit',
  CycleCharge = 'CycleCharge',
  Exchange = 'Exchange',
  CustomPriceAdjustment = 'CustomPriceAdjustment',
  WriteOff = 'WriteOff',
  AddTermUnits = 'AddTermUnits'
}

export enum CustomerType {
  SoldToCustomer = 'SoldToCustomer',
  EndCustomer = 'EndCustomer'
}

export type CustomPrice = {
   __typename?: 'CustomPrice';
  id: Scalars['String'];
  version: Scalars['String'];
};


export type DateOrDuration = StartEndDates | Duration;

export type DateOrDurationInput = {
  duration?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export enum DatePrecision {
  Month = 'month',
  Day = 'day'
}


export type DealEstimateInput = {
  annualDealEstimate: Scalars['Float'];
  minimumCreditLine?: Maybe<Scalars['Float']>;
  quoteIdentifier: QuoteMutationInput;
};

export type DeleteFavoriteGroupInput = {
  groupName: Scalars['String'];
  catalogContext: CatalogContextInput;
};

export type DeleteModernAgreementInput = {
  agreementId: Scalars['String'];
  quoteId: Scalars['String'];
};

export type DeleteObjectResponse = {
   __typename?: 'DeleteObjectResponse';
  id?: Maybe<Scalars['String']>;
};

export type Discount = {
   __typename?: 'Discount';
  ruleType: DiscountRuleType;
  applicability: DiscountApplicability;
  discountPercentage?: Maybe<Scalars['Float']>;
  priceGuaranteeDate?: Maybe<Scalars['String']>;
  pricingPolicy?: Maybe<Scalars['String']>;
};

export enum DiscountApplicability {
  Unknown = 'Unknown',
  ProductFamily = 'ProductFamily',
  Product = 'Product',
  Sku = 'Sku'
}

export type DiscountInput = {
  percentage?: Maybe<Scalars['Float']>;
  type: DiscountType;
  duration?: Maybe<DateOrDurationInput>;
  priceAdjustmentType?: Maybe<PriceAdjustmentType>;
  priceGuaranteeDate?: Maybe<Scalars['DateTime']>;
  pricingPolicy?: Maybe<Scalars['String']>;
  additionalConditions?: Maybe<Array<ConditionInput>>;
};

export type DiscountLineItem = LineItem & {
   __typename?: 'DiscountLineItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  configurationSummary?: Maybe<BaseTranslatedString>;
  subtitle?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  catalogContext: CatalogContext;
  product?: Maybe<Product>;
  sku?: Maybe<Sku>;
  availability?: Maybe<Availability>;
  term?: Maybe<Term>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadyForPricing: Scalars['Boolean'];
  isConfigurable: Scalars['Boolean'];
  discount?: Maybe<DiscountSimple>;
  pricingCurrency?: Maybe<Scalars['String']>;
  applicableDiscounts: ApplicableDiscounts;
  exchangeRate?: Maybe<Scalars['Float']>;
};

export enum DiscountRuleType {
  Unknown = 'Unknown',
  OneTime = 'OneTime',
  Future = 'Future',
  PriceGuarantee = 'PriceGuarantee'
}

export type DiscountSimple = {
   __typename?: 'DiscountSimple';
  percentage?: Maybe<Scalars['Float']>;
  type?: Maybe<DiscountType>;
  duration?: Maybe<DateOrDuration>;
  priceAdjustmentType?: Maybe<PriceAdjustmentType>;
  priceGuaranteeDate?: Maybe<Scalars['DateTime']>;
  scope?: Maybe<Array<Condition>>;
  action?: Maybe<CatalogAction>;
};

export enum DiscountType {
  OneTime = 'OneTime',
  PriceGuarantee = 'PriceGuarantee',
  Future = 'Future',
  Unconfigured = 'Unconfigured'
}

export type DisplayParameter = BaseTranslatedString & {
   __typename?: 'DisplayParameter';
  display: Scalars['String'];
};

export type Duration = {
   __typename?: 'Duration';
  duration?: Maybe<Scalars['String']>;
};

export type EcifConfigInput = {
  productId: Scalars['String'];
  catalogContext: CatalogContextInput;
  addLineItems?: Maybe<Array<EcifConfigLineItemInput>>;
  removeLineItemIds?: Maybe<Array<Scalars['String']>>;
};

export type EcifConfigLineItem = {
   __typename?: 'EcifConfigLineItem';
  amount: Scalars['String'];
  sku: Sku;
  endDate: Scalars['String'];
  lineItemId: Scalars['String'];
  productFamily: Scalars['String'];
  type: Scalars['String'];
};

export type EcifConfigLineItemInput = {
  amount: Scalars['String'];
  endDate: Scalars['String'];
  productFamily: Scalars['String'];
  skuId: Scalars['String'];
  type: Scalars['String'];
};

export type EcifConfiguration = {
   __typename?: 'EcifConfiguration';
  caseId?: Maybe<Scalars['String']>;
  caseNumber?: Maybe<Scalars['String']>;
  totalApprovedAmount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  lineItems: Array<EcifConfigLineItem>;
};

export type EcifDetails = {
   __typename?: 'EcifDetails';
  RequestNumber: Scalars['String'];
  TotalApprovedAmount: Scalars['Float'];
  Currency: Currency;
  CaseId: Scalars['String'];
};

export type EcifLineItem = LineItem & {
   __typename?: 'EcifLineItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  configurationSummary?: Maybe<BaseTranslatedString>;
  subtitle?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  catalogContext: CatalogContext;
  product?: Maybe<Product>;
  sku?: Maybe<Sku>;
  availability?: Maybe<Availability>;
  term?: Maybe<Term>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadyForPricing: Scalars['Boolean'];
  isConfigurable: Scalars['Boolean'];
  duration?: Maybe<DateOrDuration>;
  supplementalTermReferenceData?: Maybe<KeyValue>;
  agreement?: Maybe<LineItemAgreement>;
  oneAskCaseNumber?: Maybe<Scalars['String']>;
  purchaseTermUnits?: Maybe<Scalars['String']>;
  ecifConfig?: Maybe<EcifConfiguration>;
};

export type Edge = {
  cursor?: Maybe<Scalars['String']>;
};

export type EmpoweredUsers = {
   __typename?: 'EmpoweredUsers';
  market: Market;
  hierarchyLevel: Scalars['String'];
  level: Scalars['String'];
  policy: Scalars['String'];
  users: Array<User>;
};

export type EnrollmentSummary = {
   __typename?: 'EnrollmentSummary';
  tpid: Scalars['String'];
  accountName?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['String']>;
  agreementNumber?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  priorAgreementNumber?: Maybe<Scalars['String']>;
  isEnterpriseAgreement?: Maybe<Scalars['String']>;
  isRenewed?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  licenseAgreementType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isCountryMatch?: Maybe<Scalars['Boolean']>;
  customerContacts: Array<Contact>;
  partnerContacts: Array<Contact>;
  partners: Array<Partner>;
  products: Array<Scalars['String']>;
};

export enum ErrorCodes {
  CannotFindAvailability = 'CANNOT_FIND_AVAILABILITY',
  CrmleadExists = 'CRMLEAD_EXISTS',
  CrmleadMissingSalesAccount = 'CRMLEAD_MISSING_SALES_ACCOUNT',
  CrmleadNotFound = 'CRMLEAD_NOT_FOUND',
  EcifCaseidNotFound = 'ECIF_CASEID_NOT_FOUND',
  EcifOneTermAllowed = 'ECIF_ONE_TERM_ALLOWED',
  EcifTermNotFound = 'ECIF_TERM_NOT_FOUND',
  EcifConfigNotFound = 'ECIF_CONFIG_NOT_FOUND',
  EcifFoundTermAndConfigLineItems = 'ECIF_FOUND_TERM_AND_CONFIG_LINE_ITEMS',
  EcifUnableToLoadTerm = 'ECIF_UNABLE_TO_LOAD_TERM',
  ExternaluserNotfound = 'EXTERNALUSER_NOTFOUND',
  FailedDocumentUpdate = 'FAILED_DOCUMENT_UPDATE',
  FailedRetrieveFilename = 'FAILED_RETRIEVE_FILENAME',
  FailedUpdateAgreement = 'FAILED_UPDATE_AGREEMENT',
  PendingAgreement = 'PENDING_AGREEMENT',
  SignedAgreement = 'SIGNED_AGREEMENT',
  MissingAgreementMarketLanguage = 'MISSING_AGREEMENT_MARKET_LANGUAGE',
  OrganizationReadonly = 'ORGANIZATION_READONLY',
  ParentAccountMismatch = 'PARENT_ACCOUNT_MISMATCH',
  ProductLoadFailed = 'PRODUCT_LOAD_FAILED',
  QuoteAlreadyPublished = 'QUOTE_ALREADY_PUBLISHED',
  QuoteFailedHydration = 'QUOTE_FAILED_HYDRATION',
  QuoteFailedUpdate = 'QUOTE_FAILED_UPDATE',
  QuoteNotDeletable = 'QUOTE_NOT_DELETABLE',
  QuoteNotWithdrawable = 'QUOTE_NOT_WITHDRAWABLE',
  QuoteHasMultipleAgreements = 'QUOTE_HAS_MULTIPLE_AGREEMENTS',
  QuoteReadOnly = 'QUOTE_READ_ONLY',
  QuoteMessagesFail = 'QUOTE_MESSAGES_FAIL',
  QuoteMissingEndcustomer = 'QUOTE_MISSING_ENDCUSTOMER',
  QuoteMissingSoldto = 'QUOTE_MISSING_SOLDTO',
  QuoteMissingSpecialAgreement = 'QUOTE_MISSING_SPECIAL_AGREEMENT',
  QuoteMissingMca = 'QUOTE_MISSING_MCA',
  QuoteMissingMinimumCreditLimit = 'QUOTE_MISSING_MINIMUM_CREDIT_LIMIT',
  QuoteLegacyOperationNotAllowed = 'QUOTE_LEGACY_OPERATION_NOT_ALLOWED',
  QuoteLeadOrgMissing = 'QUOTE_LEAD_ORG_MISSING',
  InvalidAction = 'INVALID_ACTION',
  InvalidAccount = 'INVALID_ACCOUNT',
  InvalidCv = 'INVALID_CV',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidEmailMicrosoft = 'INVALID_EMAIL_MICROSOFT',
  InvalidExternalUser = 'INVALID_EXTERNAL_USER',
  InvalidInvitedUser = 'INVALID_INVITED_USER',
  InvalidQuotecontext = 'INVALID_QUOTECONTEXT',
  InvalidLanguage = 'INVALID_LANGUAGE',
  InvalidCurrency = 'INVALID_CURRENCY',
  InvalidSoldtoTenant = 'INVALID_SOLDTO_TENANT',
  InvalidPurchaseMotion = 'INVALID_PURCHASE_MOTION',
  InvalidOrganization = 'INVALID_ORGANIZATION',
  InvalidLeadOrganization = 'INVALID_LEAD_ORGANIZATION',
  InvalidOrganizationPurchaseContext = 'INVALID_ORGANIZATION_PURCHASE_CONTEXT',
  InvalidOrganizationAudience = 'INVALID_ORGANIZATION_AUDIENCE',
  InvalidCrmMcapiId = 'INVALID_CRM_MCAPI_ID',
  InvalidNumber = 'INVALID_NUMBER',
  InvalidLegacyEnrollment = 'INVALID_LEGACY_ENROLLMENT',
  EmailNotInTenant = 'EMAIL_NOT_IN_TENANT',
  TenantConsumer = 'TENANT_CONSUMER',
  TenantViral = 'TENANT_VIRAL',
  TenantInvalid = 'TENANT_INVALID',
  TenantInvalidInTest = 'TENANT_INVALID_IN_TEST',
  TenantDoesNotExist = 'TENANT_DOES_NOT_EXIST',
  TenantKnown = 'TENANT_KNOWN',
  UnableParseFilename = 'UNABLE_PARSE_FILENAME',
  ReferralNotDeletable = 'REFERRAL_NOT_DELETABLE',
  ReferralNotClosable = 'REFERRAL_NOT_CLOSABLE',
  ReferralSingleCrm = 'REFERRAL_SINGLE_CRM',
  UnsupportedMarket = 'UNSUPPORTED_MARKET',
  SalesAccountNull = 'SALES_ACCOUNT_NULL',
  PriceContextEmpty = 'PRICE_CONTEXT_EMPTY',
  InapplicableDiscountType = 'INAPPLICABLE_DISCOUNT_TYPE',
  LineItemFailedDelete = 'LINE_ITEM_FAILED_DELETE',
  LineItemNotFound = 'LINE_ITEM_NOT_FOUND',
  LineItemNotDiscountable = 'LINE_ITEM_NOT_DISCOUNTABLE',
  MalformedLineItem = 'MALFORMED_LINE_ITEM',
  ErrorDuration = 'ERROR_DURATION',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
  ErrorPurchaseContext = 'ERROR_PURCHASE_CONTEXT',
  MissingEndCustomerProject = 'MISSING_END_CUSTOMER_PROJECT',
  MissingPartnerParticipant = 'MISSING_PARTNER_PARTICIPANT',
  MissingCustomerParticipant = 'MISSING_CUSTOMER_PARTICIPANT',
  MissingMicrosoftParticipant = 'MISSING_MICROSOFT_PARTICIPANT',
  MultipleMicrosoftParticipants = 'MULTIPLE_MICROSOFT_PARTICIPANTS',
  MultiplePendingParticipants = 'MULTIPLE_PENDING_PARTICIPANTS',
  FoundNoPendingParticipants = 'FOUND_NO_PENDING_PARTICIPANTS',
  PartnerAgreementStatusNotPendingSignature = 'PARTNER_AGREEMENT_STATUS_NOT_PENDING_SIGNATURE'
}

export type ExtendedProperties = {
   __typename?: 'ExtendedProperties';
  isMsxPrimaryQuote?: Maybe<Scalars['Boolean']>;
  vlAgreementNumbersForMigration?: Maybe<Array<Scalars['String']>>;
  soldToLegalEntityId?: Maybe<Scalars['String']>;
  userPreferences?: Maybe<UserPreferences>;
};

export type ExternalReference = {
   __typename?: 'ExternalReference';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type FavoriteProductInput = {
  productId: Scalars['String'];
  addToGroup?: Maybe<Scalars['String']>;
  removeFromGroup?: Maybe<Scalars['String']>;
  catalogContext: CatalogContextInput;
};

export type FgoeOpportunity = CrmLead & {
   __typename?: 'FgoeOpportunity';
  id: Scalars['ID'];
  entityId: Scalars['String'];
  type: CrmType;
  title?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  vlAgreementNumber?: Maybe<Scalars['String']>;
  salesAccount?: Maybe<SalesAccount>;
  validity: CrmValidity;
};


export type FgoeOpportunityValidityArgs = {
  motion: Motion;
};

export type FilteredSkus = {
   __typename?: 'FilteredSkus';
  filters: Array<KeyValue>;
  skus: Array<Sku>;
  skuCount: Scalars['Int'];
};

export enum FinanceAssociationIdType {
  LineItem = 'LineItem',
  Product = 'Product'
}

export type FinancingTermLineItem = LineItem & {
   __typename?: 'FinancingTermLineItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  configurationSummary?: Maybe<BaseTranslatedString>;
  subtitle?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  catalogContext: CatalogContext;
  product?: Maybe<Product>;
  sku?: Maybe<Sku>;
  availability?: Maybe<Availability>;
  term?: Maybe<Term>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadyForPricing: Scalars['Boolean'];
  isConfigurable: Scalars['Boolean'];
  duration?: Maybe<DateOrDuration>;
  supplementalTermReferenceData?: Maybe<KeyValue>;
  associatedLineItem?: Maybe<LineItem>;
};

export type FutureGuaranteedPrice = {
   __typename?: 'FutureGuaranteedPrice';
  type: Scalars['String'];
  value: Scalars['Float'];
};

export type GetAgreementsForQuoteInput = {
  agreementType: AgreementTypes;
  cursor?: Maybe<Scalars['String']>;
};

export type GetApprovalsOptions = {
  pendingOnly?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<OrderInput>>;
};

export type GetEmpowermentsInput = {
  market: Market;
  hierarchyLevel: Scalars['String'];
  level: Scalars['String'];
  policy: Scalars['String'];
};

export type GetQuotesOptions = {
  sort?: Maybe<Array<OrderInput>>;
};

export type GetReferralsOptions = {
  sort?: Maybe<Array<OrderInput>>;
  referralFilter: ReferralFilter;
};

export enum GqlLanguage {
  AfZa = 'af_ZA',
  ArAe = 'ar_AE',
  ArEg = 'ar_EG',
  ArIl = 'ar_IL',
  ArSa = 'ar_SA',
  BgBg = 'bg_BG',
  CaEs = 'ca_ES',
  CsCz = 'cs_CZ',
  DaDk = 'da_DK',
  DeCh = 'de_CH',
  DeDe = 'de_DE',
  DeLu = 'de_LU',
  ElGr = 'el_GR',
  EnAu = 'en_AU',
  EnCa = 'en_CA',
  EnGb = 'en_GB',
  EnIe = 'en_IE',
  EnUs = 'en_US',
  EnZa = 'en_ZA',
  EsCr = 'es_CR',
  EsEs = 'es_ES',
  EtEe = 'et_EE',
  EuEs = 'eu_ES',
  FiFi = 'fi_FI',
  FilPh = 'fil_PH',
  FrBe = 'fr_BE',
  FrCa = 'fr_CA',
  FrCh = 'fr_CH',
  FrFr = 'fr_FR',
  GlEs = 'gl_ES',
  HeIl = 'he_IL',
  HrHr = 'hr_HR',
  HuHu = 'hu_HU',
  IdId = 'id_ID',
  ItCh = 'it_CH',
  ItIt = 'it_IT',
  JaJp = 'ja_JP',
  KoKr = 'ko_KR',
  LvLv = 'lv_LV',
  MsMy = 'ms_MY',
  NbNo = 'nb_NO',
  NlBe = 'nl_BE',
  NlNl = 'nl_NL',
  NnNo = 'nn_NO',
  PlPl = 'pl_PL',
  PtBr = 'pt_BR',
  PtPt = 'pt_PT',
  RoRo = 'ro_RO',
  RuRu = 'ru_RU',
  SkSk = 'sk_SK',
  SlSi = 'sl_SI',
  SrCyrlRs = 'sr_cyrl_RS',
  SrLatnRs = 'sr_latn_RS',
  SvSe = 'sv_SE',
  ThTh = 'th_TH',
  TrTr = 'tr_TR',
  UkUa = 'uk_UA',
  ViVn = 'vi_VN',
  ZhCn = 'zh_CN',
  ZhHant = 'zh_Hant',
  ZhHk = 'zh_HK',
  ZhTw = 'zh_TW'
}

export type HierarchicalEntitlement = {
   __typename?: 'HierarchicalEntitlement';
  id: Scalars['ID'];
  billingSubscriptionUri: Scalars['String'];
  endDate: Scalars['DateTime'];
  state: Scalars['String'];
  productSkus: Array<ProductSku>;
  aadSkuId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type Images = {
   __typename?: 'Images';
  small?: Maybe<Array<Scalars['String']>>;
  medium?: Maybe<Array<Scalars['String']>>;
  logo?: Maybe<Array<Scalars['String']>>;
};

export type InvitedUser = {
   __typename?: 'InvitedUser';
  email: Scalars['String'];
  tid?: Maybe<Scalars['String']>;
  oid?: Maybe<Scalars['String']>;
};

export type InvitedUserInput = {
  quote: QuoteMutationInput;
  email: Scalars['String'];
  tenantId: Scalars['String'];
  customerType?: Maybe<CustomerType>;
};

export type InvitedUserSimple = {
   __typename?: 'InvitedUserSimple';
  email: Scalars['String'];
  oid?: Maybe<Scalars['String']>;
  tenant?: Maybe<TenantSimple>;
};

export type KeyNullableValues = {
   __typename?: 'KeyNullableValues';
  key: Scalars['String'];
  values: Array<Maybe<Scalars['String']>>;
};

export type KeyValue = {
   __typename?: 'KeyValue';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type KeyValueInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValues = {
   __typename?: 'KeyValues';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

/**
 * Represents the attributes of a single language.
 * gqlCode: GQL-compatible language tag
 * code: IETF language tag
 * twoLetterCode: code for the family of the language
 */
export type LanguageInfo = {
   __typename?: 'LanguageInfo';
  gqlCode: GqlLanguage;
  code: Scalars['String'];
  display: Scalars['String'];
  twoLetterCode: Scalars['String'];
};

export type LegacyConvertInput = {
  enrollmentNumber: Scalars['String'];
  quoteIdentifier: QuoteMutationInput;
};

export type LegacyPurchaseOrderConnection = Connection & {
   __typename?: 'LegacyPurchaseOrderConnection';
  edges?: Maybe<Array<Maybe<LegacyPurchaseOrderEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type LegacyPurchaseOrderEdge = {
   __typename?: 'LegacyPurchaseOrderEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchaseOrder;
};

export type LegalEntity = {
   __typename?: 'LegalEntity';
  address?: Maybe<Address>;
  businessLocationId?: Maybe<Scalars['String']>;
  tradeName?: Maybe<Scalars['String']>;
};

export type LineItem = {
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  configurationSummary?: Maybe<BaseTranslatedString>;
  subtitle?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  catalogContext: CatalogContext;
  product?: Maybe<Product>;
  sku?: Maybe<Sku>;
  availability?: Maybe<Availability>;
  term?: Maybe<Term>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadyForPricing: Scalars['Boolean'];
  isConfigurable: Scalars['Boolean'];
};

export type LineItemAgreement = {
   __typename?: 'LineItemAgreement';
  referenceData: Scalars['String'];
};

export type LineItemExtendedProperties = {
   __typename?: 'LineItemExtendedProperties';
  parentProductId?: Maybe<Scalars['String']>;
  oMSPromocode?: Maybe<Scalars['String']>;
  subscriptionIdsForMigration?: Maybe<Array<Maybe<Scalars['String']>>>;
  userPreferences?: Maybe<LineItemUserPreferences>;
};

export type LineItemInput = {
  quantity?: Maybe<Scalars['Int']>;
  productId: Scalars['String'];
  oneAskCaseId?: Maybe<Scalars['String']>;
  /** Optional, defaults to the default of quote */
  catalogContext?: Maybe<CatalogContextInput>;
};

export type LineItemOld = {
   __typename?: 'LineItemOld';
  id: Scalars['ID'];
  status: QuoteStatus;
  purchaseId?: Maybe<Scalars['String']>;
  extendedPrice: Scalars['Float'];
  billingPlanDetail?: Maybe<BillingPlanDetail>;
  recipient?: Maybe<Recipient>;
  refund?: Maybe<Refund>;
  productName?: Maybe<Scalars['String']>;
  productIdentifier?: Maybe<ProductIdentifier>;
  charge: Charge;
  discount?: Maybe<Discount>;
  purchaseInstruction?: Maybe<PurchaseInstruction>;
  externalReference?: Maybe<ExternalReference>;
  extendedProperties?: Maybe<LineItemExtendedProperties>;
  catalogClaims?: Maybe<CatalogClaims>;
  recurrenceProcessor: RecurrenceProcessor;
  customerIntent: CustomerIntent;
  reasonCode: Scalars['String'];
  purchaseProviderName: Scalars['String'];
  fulfillmentReferenceData?: Maybe<Scalars['String']>;
  supplementalTermReferenceData?: Maybe<SupplementalTermReferenceData>;
  isReadyForPricing: Scalars['Boolean'];
  participants?: Maybe<Array<Participant>>;
  assetTo?: Maybe<AssetTo>;
  groups?: Maybe<Array<Scalars['String']>>;
  AddressIdentifier?: Maybe<AddressIdentifier>;
  dates?: Maybe<DateOrDuration>;
};

export type LineItemUserPreferences = {
   __typename?: 'LineItemUserPreferences';
  availabilityTermId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
  skuTitle?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  conditions?: Maybe<Array<Maybe<PricingInstructionCondition>>>;
};

export enum Market {
  Ae = 'AE',
  Af = 'AF',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Ar = 'AR',
  At = 'AT',
  Au = 'AU',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bg = 'BG',
  Bh = 'BH',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cv = 'CV',
  Cw = 'CW',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dk = 'DK',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fo = 'FO',
  Fr = 'FR',
  Gb = 'GB',
  Ge = 'GE',
  Gh = 'GH',
  Gr = 'GR',
  Gt = 'GT',
  Hk = 'HK',
  Hn = 'HN',
  Hr = 'HR',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  Iq = 'IQ',
  Is = 'IS',
  It = 'IT',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kn = 'KN',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  Lb = 'LB',
  Li = 'LI',
  Lk = 'LK',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mk = 'MK',
  Mn = 'MN',
  Mo = 'MO',
  Mt = 'MT',
  Mu = 'MU',
  Mx = 'MX',
  My = 'MY',
  Na = 'NA',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Py = 'PY',
  Qa = 'QA',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Se = 'SE',
  Sg = 'SG',
  Si = 'SI',
  Sk = 'SK',
  Sn = 'SN',
  Sv = 'SV',
  Th = 'TH',
  Tj = 'TJ',
  Tm = 'TM',
  Tn = 'TN',
  Tr = 'TR',
  Tt = 'TT',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Ve = 'VE',
  Vi = 'VI',
  Vn = 'VN',
  Ye = 'YE',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

/**
 * Represents the supported attributes of a single market.
 * invoiceLanguages: List of languages the invoicing system supports for this market
 * agreementLanguages: List of languages the agreement system supports for this market
 * preferredCommunicationLanguages: List of best-guesses around the languages the end-user would want their emails to be
 * addressConfiguration: To hint the client if region is required and whats the order of fields for city, state, postal-code.
 */
export type MarketInfo = {
   __typename?: 'MarketInfo';
  code: Market;
  display: Scalars['String'];
  invoiceLanguages: Array<LanguageInfo>;
  currencies: Array<CurrencyInfo>;
  directCurrencies?: Maybe<Array<CurrencyInfo>>;
  webDirectCurrencies?: Maybe<Array<CurrencyInfo>>;
  preferredCommunicationLanguages: Array<LanguageInfo>;
  agreementLanguages: Array<LanguageInfo>;
  timezones: Timezones;
  supports: Scalars['Boolean'];
  requiresVatId: Scalars['Boolean'];
  regions: Array<RegionInfo>;
  addressConfiguration: AddressConfiguration;
};


/**
 * Represents the supported attributes of a single market.
 * invoiceLanguages: List of languages the invoicing system supports for this market
 * agreementLanguages: List of languages the agreement system supports for this market
 * preferredCommunicationLanguages: List of best-guesses around the languages the end-user would want their emails to be
 * addressConfiguration: To hint the client if region is required and whats the order of fields for city, state, postal-code.
 */
export type MarketInfoSupportsArgs = {
  motion: Motion;
};

export type Message = {
   __typename?: 'Message';
  messageType: MessageType;
  messageDisplay: TranslatedString;
  messageTitle?: Maybe<TranslatedString>;
  messageSource?: Maybe<MessageSource>;
};

export enum MessageSource {
  AccountInvalidTenant = 'ACCOUNT_INVALID_TENANT',
  BillingaccountContactMissing = 'BILLINGACCOUNT_CONTACT_MISSING',
  BillingaccountMissing = 'BILLINGACCOUNT_MISSING',
  BillingaccountPartnerContactMissing = 'BILLINGACCOUNT_PARTNER_CONTACT_MISSING',
  BillingaccountCustomerContactMissing = 'BILLINGACCOUNT_CUSTOMER_CONTACT_MISSING',
  BluelevelapprovalRequired = 'BLUELEVELAPPROVAL_REQUIRED',
  CustomerCreditlineInvalid = 'CUSTOMER_CREDITLINE_INVALID',
  CustomerCreditRejected = 'CUSTOMER_CREDIT_REJECTED',
  CustomerMissingWorkaccount = 'CUSTOMER_MISSING_WORKACCOUNT',
  EnrollmentnumberInvalid = 'ENROLLMENTNUMBER_INVALID',
  EnrollmentnumberMissing = 'ENROLLMENTNUMBER_MISSING',
  LineitemBroken = 'LINEITEM_BROKEN',
  LineitemDuplicate = 'LINEITEM_DUPLICATE',
  LineitemMissingConfiguration = 'LINEITEM_MISSING_CONFIGURATION',
  MaximumPurchaseItems = 'MAXIMUM_PURCHASE_ITEMS',
  MaximumDiscountItems = 'MAXIMUM_DISCOUNT_ITEMS',
  OneAskApprovalNotFound = 'ONE_ASK_APPROVAL_NOT_FOUND',
  OrganizationsearchInvalidsearchterm = 'ORGANIZATIONSEARCH_INVALIDSEARCHTERM',
  OrganizationsearchAccountNotfound = 'ORGANIZATIONSEARCH_ACCOUNT_NOTFOUND',
  OrganizationsearchNobillingaccount = 'ORGANIZATIONSEARCH_NOBILLINGACCOUNT',
  OrganizationsearchTenantNotfound = 'ORGANIZATIONSEARCH_TENANT_NOTFOUND',
  OrganizationsearchUnmanagedtenant = 'ORGANIZATIONSEARCH_UNMANAGEDTENANT',
  PartnerCustomerMissingSpecialagreement = 'PARTNER_CUSTOMER_MISSING_SPECIALAGREEMENT',
  PartnerMissingWorkaccount = 'PARTNER_MISSING_WORKACCOUNT',
  SapEnrollmentnumberRemoval = 'SAP_ENROLLMENTNUMBER_REMOVAL',
  QuoteheaderMissingDealestimate = 'QUOTEHEADER_MISSING_DEALESTIMATE',
  QuoteheaderMissingOpportunity = 'QUOTEHEADER_MISSING_OPPORTUNITY',
  QuoteheaderMissingParticipants = 'QUOTEHEADER_MISSING_PARTICIPANTS',
  VatNumberNotAdded = 'VAT_NUMBER_NOT_ADDED',
  EcifLineitemAmountExceeded = 'ECIF_LINEITEM_AMOUNT_EXCEEDED',
  EcifLineitemPastEnddate = 'ECIF_LINEITEM_PAST_ENDDATE'
}

export enum MessageType {
  Standard = 'Standard',
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning',
  Action = 'Action'
}

export type MinMax = {
   __typename?: 'MinMax';
  minimum?: Maybe<Scalars['Int']>;
  maximum?: Maybe<Scalars['Int']>;
};

export type ModernAgreement = {
   __typename?: 'ModernAgreement';
  id: Scalars['ID'];
  status: Scalars['String'];
  startEffectiveDate: Scalars['String'];
  endEffectiveDate: Scalars['String'];
  languageLocale?: Maybe<Scalars['String']>;
  acceptanceMode: Scalars['String'];
  documentId?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerType?: Maybe<Scalars['String']>;
  documentDisplayUri?: Maybe<Scalars['String']>;
  template?: Maybe<ModernAgreementTemplate>;
  href: Scalars['String'];
};

export type ModernAgreementActual = {
   __typename?: 'ModernAgreementActual';
  status?: Maybe<Scalars['String']>;
  startEffectiveDate?: Maybe<Scalars['DateTime']>;
  endEffectiveDate?: Maybe<Scalars['DateTime']>;
  languageLocale?: Maybe<Scalars['String']>;
  acceptanceMode?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  documentDisplayUri?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  documentAssemblyDate?: Maybe<Scalars['String']>;
  documentDownloadUri?: Maybe<Scalars['String']>;
  documentFileName?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<ModernAgreementParticipant>>>;
  links?: Maybe<Array<Maybe<ModernAgreementLink>>>;
  acceptances?: Maybe<Array<Maybe<Scalars['String']>>>;
  etag?: Maybe<Scalars['String']>;
  messages: Array<Message>;
};

export type ModernAgreementActualConnection = Connection & {
   __typename?: 'ModernAgreementActualConnection';
  edges: Array<ModernAgreementActualEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ModernAgreementActualEdge = Edge & {
   __typename?: 'ModernAgreementActualEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ModernAgreementActual;
};

export type ModernAgreementConnection = Connection & {
   __typename?: 'ModernAgreementConnection';
  edges: Array<ModernAgreementEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ModernAgreementDiscount = {
   __typename?: 'ModernAgreementDiscount';
  name: Scalars['String'];
  description: Scalars['String'];
  discount: Scalars['Float'];
  startingDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  additionalDetails?: Maybe<Scalars['String']>;
};

export type ModernAgreementDiscountConnection = Connection & {
   __typename?: 'ModernAgreementDiscountConnection';
  id?: Maybe<Scalars['ID']>;
  edges?: Maybe<Array<Maybe<ModernAgreementDiscountEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ModernAgreementDiscountEdge = Edge & {
   __typename?: 'ModernAgreementDiscountEdge';
  id?: Maybe<Scalars['ID']>;
  cursor?: Maybe<Scalars['String']>;
  node: ModernAgreementDiscount;
};

export type ModernAgreementDocument = {
   __typename?: 'ModernAgreementDocument';
  documentId?: Maybe<Scalars['ID']>;
  documentDisplayUri?: Maybe<Scalars['String']>;
  documentDownloadUri?: Maybe<Scalars['String']>;
};

export type ModernAgreementEdge = Edge & {
   __typename?: 'ModernAgreementEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ModernAgreement;
};

export type ModernAgreementLink = {
   __typename?: 'ModernAgreementLink';
  rel?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
};

export type ModernAgreementParticipant = {
   __typename?: 'ModernAgreementParticipant';
  id?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  role?: Maybe<ModernAgreementRole>;
  type?: Maybe<ModernAgreementType>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  subtype?: Maybe<ModernAgreementSubtype>;
  status?: Maybe<ModernAgreementStatus>;
  statusDate?: Maybe<Scalars['String']>;
};

export enum ModernAgreementRole {
  Signer = 'Signer',
  Reviewer = 'Reviewer',
  AutoSigner = 'AutoSigner',
  OnBehalfOf = 'OnBehalfOf',
  Lead = 'Lead'
}

export enum ModernAgreementStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Expired = 'Expired',
  Pending = 'Pending'
}

export enum ModernAgreementSubtype {
  Uso = 'USO',
  Eoc = 'EOC',
  Apoc = 'APOC',
  Kr = 'KR'
}

export type ModernAgreementTemplate = {
   __typename?: 'ModernAgreementTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  clauses: Array<ModernAgreementTemplateClause>;
};

export type ModernAgreementTemplateClause = {
   __typename?: 'ModernAgreementTemplateClause';
  name: Scalars['String'];
  group: Scalars['String'];
};

export enum ModernAgreementType {
  Customer = 'Customer',
  Partner = 'Partner',
  Microsoft = 'Microsoft'
}

export type ModernPurchaseOrder = {
   __typename?: 'ModernPurchaseOrder';
  agreementId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  quoteId?: Maybe<Scalars['String']>;
  orderTitle: Scalars['String'];
  totalPrice?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  purchaseOrderLineItems?: Maybe<Array<ModernPurchaseOrderLineItem>>;
};

export type ModernPurchaseOrderConnection = Connection & {
   __typename?: 'ModernPurchaseOrderConnection';
  edges?: Maybe<Array<Maybe<ModernPurchaseOrderEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ModernPurchaseOrderEdge = {
   __typename?: 'ModernPurchaseOrderEdge';
  cursor?: Maybe<Scalars['String']>;
  node: ModernPurchaseOrder;
};

export type ModernPurchaseOrderLineItem = {
   __typename?: 'ModernPurchaseOrderLineItem';
  discount?: Maybe<Scalars['Float']>;
  listPrice: Scalars['Float'];
  offerPrice: Scalars['Float'];
  productName?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type MonetaryLineItem = LineItem & {
   __typename?: 'MonetaryLineItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  configurationSummary?: Maybe<BaseTranslatedString>;
  subtitle?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  catalogContext: CatalogContext;
  product?: Maybe<Product>;
  sku?: Maybe<Sku>;
  availability?: Maybe<Availability>;
  term?: Maybe<Term>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadyForPricing: Scalars['Boolean'];
  amount?: Maybe<Scalars['Float']>;
  purchaseTermUnits?: Maybe<Scalars['String']>;
  duration?: Maybe<DateOrDuration>;
  reason?: Maybe<Scalars['String']>;
  isTerm: Scalars['Boolean'];
  isConfigurable: Scalars['Boolean'];
};

export enum Motion {
  Direct = 'Direct',
  Partner = 'Partner',
  PartnerReseller = 'PartnerReseller',
  WebDirect = 'WebDirect',
  Other = 'Other'
}

export type Muid = {
   __typename?: 'Muid';
  muid: Scalars['String'];
};

export type Mutation = {
   __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  addTenantToAccount: Account;
  updateAccountDescription: Account;
  performApprovalAction?: Maybe<Approval>;
  associateCrmIdToMcapiId: Scalars['String'];
  createOrUpdateModernAgreement: ModernAgreementActual;
  addModernAgreementParticipants: ModernAgreementActual;
  updateModernAgreementParticipants: ModernAgreementActual;
  deleteModernAgreement: DeleteObjectResponse;
  updateOrganizationAddress: OrganizationSimple;
  updateOrganizationContact: OrganizationSimple;
  updateTradeName: OrganizationSimple;
  updateVatId: OrganizationSimple;
  oldPublishQuote?: Maybe<QuoteOld>;
  oldWithdrawQuote?: Maybe<QuoteOld>;
  addCRMLead: Quote;
  updateCloudTypes: Quote;
  removeCRMLead: Quote;
  createQuote?: Maybe<Quote>;
  createQuoteByCRMId: Quote;
  convertQuoteToLegacy: Quote;
  deleteQuote?: Maybe<DeleteObjectResponse>;
  updateInvitedUser: Quote;
  createAccountAndOrganization: Quote;
  createOrganization: Quote;
  addOrganization: Quote;
  removeOrganization: Quote;
  updateExpiration: Quote;
  updateMarket: Quote;
  updateCurrency: Quote;
  updateLanguage: Quote;
  updateQuoteName: Quote;
  updateMsContact: Quote;
  updateCloudType: Quote;
  publishQuote: Quote;
  withdrawQuote: Quote;
  submitQuote: Quote;
  transactQuote: Quote;
  addLineItems: Quote;
  addSharedDiscounts: Quote;
  updateQuantity: Quote;
  removeLineItems: Quote;
  updateAnnualDealEstimate: Quote;
  updateOrganizationVersion: Quote;
  updateLeadOrganization: Quote;
  updateVLAgreementNumber: Quote;
  updatePricingAudience: Quote;
  applyDiscount: Quote;
  applyConfigurationMultipleSkus: Quote;
  applyConfigurationSingleSku: Quote;
  applyConfigurationFinance: Quote;
  applyConfigurationMonetary: Quote;
  applyConfigurationSap: Quote;
  applyEcifConfiguration: Quote;
  requestCreditLine: SoldToSimple;
  closeReferral: Referral;
  createClaim: Referral;
  createReferral: Referral;
  deleteReferral?: Maybe<DeleteObjectResponse>;
  repairReferral: Referral;
  updateFavoriteGroup: Array<RecommendationGroup>;
  renameFavoriteGroup: Array<RecommendationGroup>;
  deleteFavoriteGroup: Array<RecommendationGroup>;
  deletePreferences?: Maybe<Scalars['Boolean']>;
  saveNpsSurveyResponse: Scalars['Boolean'];
  saveNpsSurveyDeclined: Scalars['Boolean'];
  updateDialogViewed: UserPreferences;
  updateTheme: UserPreferences;
};


export type MutationAddTenantToAccountArgs = {
  accountId: Scalars['String'];
  tenant: TenantInput;
};


export type MutationUpdateAccountDescriptionArgs = {
  accountId: Scalars['String'];
  description: Scalars['String'];
};


export type MutationPerformApprovalActionArgs = {
  id: Scalars['String'];
  action: ApprovalActionType;
  comments?: Maybe<Scalars['String']>;
};


export type MutationAssociateCrmIdToMcapiIdArgs = {
  input: AssociateCrmIdToMcapiInput;
};


export type MutationCreateOrUpdateModernAgreementArgs = {
  agreementId?: Maybe<Scalars['String']>;
  input: CreateOrUpdateModernAgreementInput;
};


export type MutationAddModernAgreementParticipantsArgs = {
  input: AddModernAgreementParticipantsInput;
};


export type MutationUpdateModernAgreementParticipantsArgs = {
  input: UpdateModernAgreementParticipantsInput;
};


export type MutationDeleteModernAgreementArgs = {
  input: DeleteModernAgreementInput;
};


export type MutationUpdateOrganizationAddressArgs = {
  input: OrganizationAddressInput;
};


export type MutationUpdateOrganizationContactArgs = {
  input: OrganizationContactInput;
};


export type MutationUpdateTradeNameArgs = {
  input: TradeNameInput;
};


export type MutationUpdateVatIdArgs = {
  input?: Maybe<VatIdInput>;
};


export type MutationOldPublishQuoteArgs = {
  input: QuoteMutationInput;
};


export type MutationOldWithdrawQuoteArgs = {
  input: QuoteMutationInput;
};


export type MutationAddCrmLeadArgs = {
  input: QuoteMutationInput;
  crmId: Scalars['String'];
};


export type MutationUpdateCloudTypesArgs = {
  input: QuoteMutationInput;
  cloudTypesToRemove?: Maybe<Array<NationalCloud>>;
  cloudTypesToAdd?: Maybe<Array<NationalCloud>>;
};


export type MutationRemoveCrmLeadArgs = {
  input: QuoteMutationInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


export type MutationCreateQuoteByCrmIdArgs = {
  id: Scalars['String'];
};


export type MutationConvertQuoteToLegacyArgs = {
  input: LegacyConvertInput;
};


export type MutationDeleteQuoteArgs = {
  input: QuoteMutationInput;
};


export type MutationUpdateInvitedUserArgs = {
  input: InvitedUserInput;
};


export type MutationCreateAccountAndOrganizationArgs = {
  input: CreateAccountOrganizationInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationAddOrganizationArgs = {
  input: SelectOrganizationInput;
};


export type MutationRemoveOrganizationArgs = {
  input?: Maybe<RemoveOrganizationInput>;
};


export type MutationUpdateExpirationArgs = {
  input: QuoteMutationInput;
  date: Scalars['String'];
};


export type MutationUpdateMarketArgs = {
  input: QuoteMutationInput;
  market: Scalars['String'];
};


export type MutationUpdateCurrencyArgs = {
  input: QuoteMutationInput;
  currency: Scalars['String'];
};


export type MutationUpdateLanguageArgs = {
  input: QuoteMutationInput;
  language: Scalars['String'];
};


export type MutationUpdateQuoteNameArgs = {
  input: QuoteMutationInput;
  name: Scalars['String'];
};


export type MutationUpdateMsContactArgs = {
  input: QuoteMutationInput;
  msContact?: Maybe<Scalars['String']>;
};


export type MutationUpdateCloudTypeArgs = {
  input: QuoteMutationInput;
  cloudType: NationalCloud;
};


export type MutationPublishQuoteArgs = {
  input: QuoteMutationInput;
};


export type MutationWithdrawQuoteArgs = {
  input: QuoteMutationInput;
};


export type MutationSubmitQuoteArgs = {
  input: QuoteMutationInput;
  submitQuoteInput: SubmitQuoteInput;
};


export type MutationTransactQuoteArgs = {
  id: Scalars['String'];
};


export type MutationAddLineItemsArgs = {
  input: QuoteMutationInput;
  lineItems: Array<LineItemInput>;
};


export type MutationAddSharedDiscountsArgs = {
  input: QuoteMutationInput;
};


export type MutationUpdateQuantityArgs = {
  input: QuoteMutationInput;
  lineItemId: Scalars['String'];
  newQuantity: Scalars['Int'];
};


export type MutationRemoveLineItemsArgs = {
  input: QuoteMutationInput;
  lineItemIds: Array<Scalars['String']>;
};


export type MutationUpdateAnnualDealEstimateArgs = {
  input: DealEstimateInput;
};


export type MutationUpdateOrganizationVersionArgs = {
  input: QuoteMutationInput;
  customerType?: Maybe<CustomerType>;
};


export type MutationUpdateLeadOrganizationArgs = {
  input: SelectOrganizationInput;
};


export type MutationUpdateVlAgreementNumberArgs = {
  input: QuoteMutationInput;
  vlAgreementNumberForMigration?: Maybe<Scalars['String']>;
};


export type MutationUpdatePricingAudienceArgs = {
  input: PricingAudienceInput;
};


export type MutationApplyDiscountArgs = {
  quote: QuoteMutationInput;
  discount: ApplyDiscountInput;
};


export type MutationApplyConfigurationMultipleSkusArgs = {
  quote: QuoteMutationInput;
  configuration: ApplyConfigurationMultipleSkusInput;
};


export type MutationApplyConfigurationSingleSkuArgs = {
  quote: QuoteMutationInput;
  configuration: ApplyConfigurationSingleSkuInput;
};


export type MutationApplyConfigurationFinanceArgs = {
  quote: QuoteMutationInput;
  configuration: ApplyConfigurationFinanceInput;
};


export type MutationApplyConfigurationMonetaryArgs = {
  quote: QuoteMutationInput;
  configuration: ApplyConfigurationMonetaryInput;
};


export type MutationApplyConfigurationSapArgs = {
  quote: QuoteMutationInput;
  configuration?: Maybe<ApplyConfigurationSapInput>;
};


export type MutationApplyEcifConfigurationArgs = {
  quote: QuoteMutationInput;
  config: EcifConfigInput;
};


export type MutationRequestCreditLineArgs = {
  quoteId: Scalars['String'];
};


export type MutationCloseReferralArgs = {
  input: CloseReferralInput;
};


export type MutationCreateClaimArgs = {
  input: CreateClaimInput;
};


export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};


export type MutationDeleteReferralArgs = {
  input: ReferralMutationInput;
};


export type MutationRepairReferralArgs = {
  input: ReferralMutationInput;
};


export type MutationUpdateFavoriteGroupArgs = {
  input: FavoriteProductInput;
};


export type MutationRenameFavoriteGroupArgs = {
  input: RenameFavoriteGroupInput;
};


export type MutationDeleteFavoriteGroupArgs = {
  input: DeleteFavoriteGroupInput;
};


export type MutationSaveNpsSurveyResponseArgs = {
  input: SurveyResponseInput;
};


export type MutationUpdateDialogViewedArgs = {
  key: Scalars['String'];
};


export type MutationUpdateThemeArgs = {
  theme: PreferredTheme;
};

export type NameAddressInput = {
  name: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: Market;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum NationalCloud {
  UsGov = 'USGov',
  Global = 'Global',
  UsSec = 'USSec',
  UsNat = 'USNat',
  AirCapi = 'AirCapi'
}

export enum NegotiationReason {
  Compete = 'Compete',
  Aggregation = 'Aggregation',
  FrameworkAgreement = 'FrameworkAgreement',
  LicensingProgramConflict = 'LicensingProgramConflict',
  BudgetConstraints = 'BudgetConstraints',
  LocalMarketDynamics = 'LocalMarketDynamics',
  SeedingOrDeploymentRamp = 'SeedingOrDeploymentRamp',
  PriceIncreases = 'PriceIncreases',
  Unknown = 'Unknown',
  Unspecified = 'Unspecified'
}

export type NonUserActor = {
   __typename?: 'NonUserActor';
  name: Scalars['String'];
};

export type NpsSurvey = {
   __typename?: 'NpsSurvey';
  date: Scalars['DateTime'];
  responded: Scalars['Boolean'];
  score?: Maybe<Scalars['Int']>;
};

export type Opportunity = {
   __typename?: 'Opportunity';
  opportunityNumber?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['String']>;
  estimatedCloseDate?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  licensingProgram?: Maybe<Scalars['String']>;
  opportunityType?: Maybe<Scalars['String']>;
  agreementNumber?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  links?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OpportunityConnection = Connection & {
   __typename?: 'OpportunityConnection';
  edges?: Maybe<Array<Maybe<OpportunityEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type OpportunityEdge = Edge & {
   __typename?: 'OpportunityEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Opportunity;
};

export type OpportunitySimple = CrmLead & {
   __typename?: 'OpportunitySimple';
  id: Scalars['ID'];
  entityId: Scalars['String'];
  type: CrmType;
  title?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  vlAgreementNumber?: Maybe<Scalars['String']>;
  salesAccount?: Maybe<SalesAccount>;
  validity: CrmValidity;
  contact?: Maybe<CrmContact>;
};


export type OpportunitySimpleValidityArgs = {
  motion: Motion;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderInput = {
  orderDirection: OrderDirection;
  orderField: Scalars['String'];
};

export type Organization = {
   __typename?: 'Organization';
  id?: Maybe<Scalars['ID']>;
  accountId?: Maybe<Scalars['String']>;
  audience?: Maybe<Audience>;
  createdTimestamp?: Maybe<Scalars['String']>;
  cultureInfo?: Maybe<CultureInfo>;
  description?: Maybe<Scalars['String']>;
  eligibleForAccountPricing?: Maybe<Scalars['Boolean']>;
  externalAccountReference?: Maybe<Scalars['String']>;
  legalEntity?: Maybe<LegalEntity>;
  organizationType?: Maybe<OrganizationType>;
  state?: Maybe<OrganizationState>;
  updatedTimestamp?: Maybe<Scalars['String']>;
};

export type OrganizationAddressInput = {
  address: NameAddressInput;
  organizationIdentifier: OrganizationIdentifierInput;
};

export type OrganizationConnection = Connection & {
   __typename?: 'OrganizationConnection';
  edges?: Maybe<Array<Maybe<OrganizationEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrganizationContactInput = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  organizationIdentifier: OrganizationIdentifierInput;
};

export type OrganizationEdge = Edge & {
   __typename?: 'OrganizationEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Organization;
};

export type OrganizationFilterInput = {
  key: Scalars['String'];
  audience?: Maybe<Audience>;
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  onlyLead?: Maybe<Scalars['Boolean']>;
};

export type OrganizationIdentifierInput = {
  organizationId: Scalars['String'];
  accountId: Scalars['String'];
};

export type OrganizationInput = {
  address: NameAddressInput;
  language: GqlLanguage;
  organizationDescription?: Maybe<Scalars['String']>;
  tradeName?: Maybe<Scalars['String']>;
};

export type OrganizationSimple = {
   __typename?: 'OrganizationSimple';
  id: Scalars['ID'];
  account: Account;
  accountId: Scalars['String'];
  address: Address;
  agreementSigned?: Maybe<AgreementTypes>;
  assets?: Maybe<Array<Asset>>;
  audience?: Maybe<Audience>;
  billingGroups: BillingGroupConnection;
  credit?: Maybe<Credit>;
  language?: Maybe<GqlLanguage>;
  languageInfo?: Maybe<LanguageInfo>;
  lastInvoiceDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  tradeName?: Maybe<Scalars['String']>;
  type: OrganizationType;
  vatId?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
  customerTag?: Maybe<Scalars['String']>;
  roleAssignments?: Maybe<Array<RoleAssignments>>;
};


export type OrganizationSimpleBillingGroupsArgs = {
  top?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type OrganizationSimpleCreditArgs = {
  currency?: Maybe<Currency>;
};


export type OrganizationSimpleRoleAssignmentsArgs = {
  roles: Array<CommerceAuthorizationRole>;
};

export type OrganizationSimpleConnection = Connection & {
   __typename?: 'OrganizationSimpleConnection';
  edges?: Maybe<Array<OrganizationSimpleEdge>>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrganizationSimpleEdge = Edge & {
   __typename?: 'OrganizationSimpleEdge';
  cursor?: Maybe<Scalars['String']>;
  node: OrganizationSimple;
};

export enum OrganizationState {
  Active = 'active',
  Deleted = 'deleted',
  Locked = 'locked'
}

export enum OrganizationType {
  Individual = 'individual',
  PcnBackedOrganization = 'pcnBackedOrganization',
  TenantBackedOrganization = 'tenantBackedOrganization',
  Organization = 'organization'
}

export type PageInfo = {
   __typename?: 'PageInfo';
  id?: Maybe<Scalars['ID']>;
  hasNextPage: Scalars['Boolean'];
  cursor?: Maybe<Scalars['String']>;
};

export type Participant = {
   __typename?: 'Participant';
  accountId: Scalars['String'];
  profileId: Scalars['String'];
  role: ParticipantRoleType;
};

export type ParticipantInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  statusDate: Scalars['DateTime'];
  type: ModernAgreementType;
};

export enum ParticipantRoleType {
  Unknown = 'Unknown',
  IndirectReseller = 'IndirectReseller',
  AdvisoryPartner = 'AdvisoryPartner',
  ManagingPartner = 'ManagingPartner',
  TransactingPartner = 'TransactingPartner',
  MsFieldSales = 'MSFieldSales',
  MsInsideSalesCorp = 'MSInsideSalesCorp',
  MsInsideSalesDemandResponse = 'MSInsideSalesDemandResponse',
  Customer = 'Customer'
}

export type Partner = {
   __typename?: 'Partner';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** In the case of an unrecognized permission these will be ignored */
export enum Permission {
  View = 'View',
  Insider = 'Insider',
  Update = 'Update',
  QcsAccess = 'QCSAccess',
  ReferralUpdate = 'Referral_Update',
  ReferralView = 'Referral_View',
  SpecialAgreementUpload = 'SpecialAgreement_Upload',
  Csp = 'CSP',
  Government = 'Government',
  CspPartnerDeal = 'CSP_Partner_Deal'
}

export enum PermissionEnvironment {
  Prod = 'Prod',
  Test = 'Test',
  Int = 'Int',
  Dev = 'Dev'
}

export enum PreferredTheme {
  Light = 'light',
  Dark = 'dark'
}

export type Price = {
   __typename?: 'Price';
  amount: Scalars['Float'];
  currency: Currency;
};

export enum PriceAdjustmentType {
  New = 'new',
  Extend = 'extend',
  Remove = 'remove'
}

/** Exactly one of them needs to be filled. GraphQL does not have union input types. */
export type PriceContext = {
  quote?: Maybe<QuoteContext>;
  organization?: Maybe<OrganizationIdentifierInput>;
};

export type PriceDetail = {
   __typename?: 'PriceDetail';
  quantity: Scalars['Int'];
  discountedPrice: Scalars['Float'];
  chargePrice: ChargePrice;
  estimatedTotalChargePrice?: Maybe<ChargePrice>;
};

export enum PriceSource {
  Rating = 'Rating',
  BigCat = 'BigCat'
}

export type PricingAudienceInput = {
  audience: Audience;
  motion: Motion;
  quoteIdentifier: QuoteMutationInput;
};

export type PricingContext = {
   __typename?: 'PricingContext';
  catalogId?: Maybe<Scalars['Int']>;
  market?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  billingCurrency?: Maybe<Scalars['String']>;
  exchangeRateDate?: Maybe<Scalars['String']>;
};

export type PricingInstructionCondition = {
   __typename?: 'PricingInstructionCondition';
  type: Scalars['String'];
  name: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type PricingRule = {
   __typename?: 'PricingRule';
  id: Scalars['String'];
  affirmation: Scalars['String'];
  priceGuaranteed: Scalars['Boolean'];
  affirmationParameters?: Maybe<Array<AffirmationParameter>>;
};

export type PriorPurchase = {
   __typename?: 'PriorPurchase';
  purchaseRecordId?: Maybe<Scalars['String']>;
  purchaseRecordLineItem?: Maybe<PurchaseRecordLineItemId>;
};

export type Product = {
   __typename?: 'Product';
  id: Scalars['String'];
  catalogContext: CatalogContext;
  skus: Array<Sku>;
  sku?: Maybe<Sku>;
  skuCount: Scalars['Int'];
  filters: Array<KeyNullableValues>;
  filteredSkus: Array<FilteredSkus>;
  filteredSkusRaw: Scalars['String'];
  productFamily: Scalars['String'];
  productType: Scalars['String'];
  productSubtype?: Maybe<Scalars['String']>;
  maxQuantityOnQuote?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  plainTextDescription?: Maybe<Scalars['String']>;
  publisherName?: Maybe<Scalars['String']>;
  publisherUrl?: Maybe<Scalars['String']>;
  images: Images;
  relatedProducts: Array<RelatedProduct>;
  recommendationGroups: Array<RecommendationGroup>;
  categories?: Maybe<Array<ProductAttributeValue>>;
  industries?: Maybe<Array<ProductAttributeValue>>;
  amount?: Maybe<MinMax>;
  durations?: Maybe<Array<Scalars['String']>>;
  reasons?: Maybe<Array<Reason>>;
  startCondition?: Maybe<StartCondition>;
  isPotentiallyDiscountable: Scalars['Boolean'];
  inputs?: Maybe<ProductInputs>;
};


export type ProductSkuArgs = {
  skuId: Scalars['String'];
};


export type ProductFilteredSkusArgs = {
  groupBy?: Maybe<Array<Scalars['String']>>;
  filter?: Maybe<Array<KeyValueInput>>;
};


export type ProductFilteredSkusRawArgs = {
  groupBy?: Maybe<Array<Scalars['String']>>;
  filter?: Maybe<Array<KeyValueInput>>;
};


export type ProductReasonsArgs = {
  channelFilter?: Maybe<Scalars['String']>;
};

export type ProductAttributeValue = {
   __typename?: 'ProductAttributeValue';
  name?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
};

export enum ProductAudience {
  DirectCommercial = 'DirectCommercial',
  DirectGov = 'DirectGov',
  PartnerCommercial = 'PartnerCommercial',
  IndirectGov = 'IndirectGov'
}

export type ProductConnection = Connection & {
   __typename?: 'ProductConnection';
  edges: Array<ProductEdge>;
  pageInfo: PageInfo;
};

export type ProductEdge = Edge & {
   __typename?: 'ProductEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Product>;
};

export enum ProductFamily {
  Azure = 'Azure',
  Apps = 'Apps',
  Passes = 'Passes',
  Devices = 'Devices',
  Software = 'Software',
  NegotiatedTerms = 'NegotiatedTerms'
}

export type ProductIdentifier = {
   __typename?: 'ProductIdentifier';
  productId?: Maybe<Scalars['String']>;
  skuId?: Maybe<Scalars['String']>;
  availabilityId?: Maybe<Scalars['String']>;
  transactingAvailabilityId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  availabilityTermId?: Maybe<Scalars['String']>;
  productFamily?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
};

export type ProductInputs = {
   __typename?: 'ProductInputs';
  type: Array<ProductInputValue>;
  productFamily: Array<ProductInputValue>;
};

export type ProductInputValue = {
   __typename?: 'ProductInputValue';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type ProductRecommendationAction = BaseRecommendationAction & {
   __typename?: 'ProductRecommendationAction';
  product: Product;
  meta?: Maybe<Array<KeyValue>>;
  messages?: Maybe<Array<Message>>;
};

export type ProductSku = {
   __typename?: 'ProductSku';
  product: Product;
  sku: Sku;
};

export enum PurchaseContext {
  /** Buy for myself */
  Direct = 'direct',
  /** Buy for someone else */
  Indirect = 'indirect',
  /** Someone else will bill for me */
  PartnerIndirect = 'partnerIndirect',
  /** Bill for someone else */
  PartnerReseller = 'partnerReseller'
}

export type PurchaseInstruction = {
   __typename?: 'PurchaseInstruction';
  isAutoRenew?: Maybe<Scalars['Boolean']>;
  purchaseTermUnits?: Maybe<Scalars['String']>;
  termStartDate?: Maybe<Scalars['String']>;
  termEndDate?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  purchaseTargetPrice?: Maybe<Scalars['Float']>;
  purchaseTargetTermUnits?: Maybe<Scalars['Int']>;
  priorPurchase?: Maybe<PriorPurchase>;
};

export type PurchaseLineItem = LineItem & {
   __typename?: 'PurchaseLineItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  configurationSummary?: Maybe<BaseTranslatedString>;
  subtitle?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  catalogContext: CatalogContext;
  product?: Maybe<Product>;
  sku?: Maybe<Sku>;
  availability?: Maybe<Availability>;
  term?: Maybe<Term>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadyForPricing: Scalars['Boolean'];
  isConfigurable: Scalars['Boolean'];
  quantity: Scalars['Int'];
  listPrice?: Maybe<Scalars['Float']>;
  customerPrice?: Maybe<Scalars['Float']>;
  oneTimeDiscount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  pricingCurrency?: Maybe<Scalars['String']>;
  applicableDiscounts: ApplicableDiscounts;
  exchangeRate?: Maybe<Scalars['Float']>;
  isAutoRenew?: Maybe<Scalars['Boolean']>;
};

export type PurchaseOrder = {
   __typename?: 'PurchaseOrder';
  agreementId?: Maybe<Scalars['String']>;
  currentPurchaseFlag?: Maybe<Scalars['Boolean']>;
  orderId: Scalars['Float'];
  orderNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
  purchaseOrderSequence?: Maybe<Scalars['Int']>;
  proposalId?: Maybe<Scalars['String']>;
  purchaseOrderLineItems?: Maybe<PurchaseOrderLineItemConnection>;
};


export type PurchaseOrderPurchaseOrderLineItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};

/** To be deprecated by LegacyPurchaseOrderConnection */
export type PurchaseOrderConnection = Connection & {
   __typename?: 'PurchaseOrderConnection';
  edges?: Maybe<Array<Maybe<PurchaseOrderEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

/** To be deprecated by LegacyPurchaseOrderEdge */
export type PurchaseOrderEdge = {
   __typename?: 'PurchaseOrderEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchaseOrder;
};

export type PurchaseOrderLineItem = {
   __typename?: 'PurchaseOrderLineItem';
  agreementId?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  listPrice?: Maybe<Scalars['Float']>;
  offerPrice?: Maybe<Scalars['Float']>;
  offering?: Maybe<Scalars['String']>;
  offeringName?: Maybe<Scalars['String']>;
  pool?: Maybe<Scalars['String']>;
  productFamily?: Maybe<Scalars['String']>;
  productFamilyCode?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productTypeCode?: Maybe<Scalars['String']>;
  productTypeName?: Maybe<Scalars['String']>;
  purchaseOrderId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
};

export type PurchaseOrderLineItemConnection = Connection & {
   __typename?: 'PurchaseOrderLineItemConnection';
  edges?: Maybe<Array<Maybe<PurchaseOrderLineItemEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PurchaseOrderLineItemEdge = Edge & {
   __typename?: 'PurchaseOrderLineItemEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PurchaseOrderLineItem;
};

export type PurchaseRecord = {
   __typename?: 'PurchaseRecord';
  purchaseRecordId: Scalars['String'];
  purchaseRecordLineItems: Array<PurchaseRecordLineItem>;
};

export type PurchaseRecordLineItem = {
   __typename?: 'PurchaseRecordLineItem';
  id?: Maybe<PurchaseRecordLineItemId>;
  currentPrice: Scalars['Float'];
  targetPrice: Scalars['Float'];
  refundPolicy: Scalars['String'];
  remainingTermUnits: Scalars['Int'];
};

export type PurchaseRecordLineItemId = {
   __typename?: 'PurchaseRecordLineItemId';
  id: Scalars['String'];
  low: Scalars['Int'];
  high: Scalars['Int'];
};

export type Query = {
   __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  getTenant: TenantProfile;
  validateAddress: AddressValidationResponse;
  getApproval?: Maybe<Approval>;
  getRoleAssignments: Array<RoleAssignments>;
  getCRMLead: CrmLead;
  getCrmMcapiAssociationStatus: Scalars['String'];
  getEmpowerments: EmpoweredUsers;
  getEntitlements: Array<HierarchicalEntitlement>;
  agreementById?: Maybe<Agreement>;
  customerByTpid?: Maybe<Customer>;
  validateEnrollmentNumber: Scalars['Boolean'];
  validAzureEnrollments: Array<EnrollmentSummary>;
  simpleAgreementById?: Maybe<AgreementBase>;
  purchaseOrderLineItemsById?: Maybe<PurchaseOrderLineItemConnection>;
  seatsById?: Maybe<SeatConnection>;
  seatSubscriptionsById?: Maybe<SeatSubscriptionConnection>;
  getModernAgreementPreview?: Maybe<ModernAgreementDocument>;
  getModernActiveAgreements?: Maybe<ModernAgreementConnection>;
  getModernAgreements?: Maybe<ModernAgreementActualConnection>;
  getModernAgreement?: Maybe<ModernAgreementActual>;
  organizationsByAccountId: OrganizationSimpleConnection;
  leadOrganizationsByAccountId?: Maybe<OrganizationConnection>;
  getOrganization: OrganizationSimple;
  searchOrganizations: Array<OrganizationSimple>;
  searchOrganizationsForTypeA: SearchOrganizationsResponse;
  searchCustomerOrganizationsForTypeC: SearchOrganizationsResponse;
  searchPartnerOrganizationsForTypeC: SearchOrganizationsResponse;
  searchLegacyOrganization?: Maybe<OrganizationSimple>;
  getProduct?: Maybe<Product>;
  getProducts: Array<Maybe<Product>>;
  searchProducts: ProductConnection;
  getOldQuote?: Maybe<QuoteOld>;
  getQuote?: Maybe<Quote>;
  getQuotes: Array<Quote>;
  getApprovals: Array<Quote>;
  verifyInvitedUser: Scalars['Boolean'];
  getRecommendations: Array<RecommendationGroup>;
  getReferral?: Maybe<Referral>;
  getReferrals: Array<Referral>;
  getUser: User;
  searchUser: Array<User>;
  getUserPreferences: UserPreferences;
  getMarketInfo: MarketInfo;
  getAllMarkets: Array<MarketInfo>;
};


export type QueryGetTenantArgs = {
  tenant: Scalars['String'];
  isGovernmentTenant?: Maybe<Scalars['Boolean']>;
};


export type QueryValidateAddressArgs = {
  input: AddressInput;
};


export type QueryGetApprovalArgs = {
  id: Scalars['String'];
};


export type QueryGetRoleAssignmentsArgs = {
  input: RoleAssignmentsInput;
};


export type QueryGetCrmLeadArgs = {
  id: Scalars['String'];
  fgoe?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCrmMcapiAssociationStatusArgs = {
  correlationId: Scalars['String'];
};


export type QueryGetEmpowermentsArgs = {
  input: GetEmpowermentsInput;
};


export type QueryGetEntitlementsArgs = {
  tenantId: Scalars['String'];
  catalogContext: CatalogContextInput;
};


export type QueryAgreementByIdArgs = {
  id: Scalars['String'];
  tpid?: Maybe<Scalars['Int']>;
};


export type QueryCustomerByTpidArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryValidateEnrollmentNumberArgs = {
  id: Scalars['String'];
};


export type QueryValidAzureEnrollmentsArgs = {
  tpid: Scalars['String'];
};


export type QuerySimpleAgreementByIdArgs = {
  id: Scalars['String'];
  tpid?: Maybe<Scalars['Int']>;
};


export type QueryPurchaseOrderLineItemsByIdArgs = {
  agreementId: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type QuerySeatsByIdArgs = {
  tenantId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type QuerySeatSubscriptionsByIdArgs = {
  tenantId: Scalars['String'];
  productId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type QueryGetModernAgreementPreviewArgs = {
  quoteId: Scalars['String'];
};


export type QueryGetModernActiveAgreementsArgs = {
  accountId: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
  agreementType: AgreementTypes;
};


export type QueryGetModernAgreementsArgs = {
  accountId: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
  agreementType: AgreementTypes;
};


export type QueryGetModernAgreementArgs = {
  agreementId: Scalars['String'];
  accountId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryOrganizationsByAccountIdArgs = {
  input: OrganizationFilterInput;
};


export type QueryLeadOrganizationsByAccountIdArgs = {
  accountId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type QueryGetOrganizationArgs = {
  input: OrganizationIdentifierInput;
};


export type QuerySearchOrganizationsArgs = {
  query: Scalars['String'];
};


export type QuerySearchOrganizationsForTypeAArgs = {
  input: SearchOrganizationsInput;
};


export type QuerySearchCustomerOrganizationsForTypeCArgs = {
  searchInput?: Maybe<SearchOrganizationsInput>;
  input?: Maybe<SearchCustomerOrganizationsInput>;
};


export type QuerySearchPartnerOrganizationsForTypeCArgs = {
  searchInput?: Maybe<SearchOrganizationsInput>;
  input?: Maybe<SearchPartnerOrganizationsInput>;
};


export type QuerySearchLegacyOrganizationArgs = {
  enrollmentNumber?: Maybe<Scalars['String']>;
};


export type QueryGetProductArgs = {
  id: Scalars['String'];
  catalogContext: CatalogContextInput;
};


export type QueryGetProductsArgs = {
  ids: Array<Scalars['String']>;
  catalogContext: CatalogContextInput;
};


export type QuerySearchProductsArgs = {
  input: CatalogSearchInput;
};


export type QueryGetOldQuoteArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetQuoteArgs = {
  id: Scalars['String'];
};


export type QueryGetQuotesArgs = {
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<OrderInput>>;
  options?: Maybe<GetQuotesOptions>;
};


export type QueryGetApprovalsArgs = {
  query?: Maybe<Scalars['String']>;
  options?: Maybe<GetApprovalsOptions>;
};


export type QueryVerifyInvitedUserArgs = {
  input: InvitedUserInput;
};


export type QueryGetRecommendationsArgs = {
  input: CatalogContextInput;
};


export type QueryGetReferralArgs = {
  id: Scalars['String'];
};


export type QueryGetReferralsArgs = {
  query?: Maybe<Scalars['String']>;
  options: GetReferralsOptions;
};


export type QueryGetUserArgs = {
  mail: Scalars['String'];
};


export type QuerySearchUserArgs = {
  text: Scalars['String'];
};


export type QueryGetMarketInfoArgs = {
  market: Market;
};

export type QueryInput = {
  order?: Maybe<OrderInput>;
  filterField?: Maybe<Scalars['String']>;
};

export type Quote = {
   __typename?: 'Quote';
  id: Scalars['ID'];
  agreements: ModernAgreementActualConnection;
  name: Scalars['String'];
  version: Scalars['String'];
  revision: Scalars['Int'];
  continuationToken?: Maybe<Scalars['String']>;
  etag: Scalars['String'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedApiVersion?: Maybe<Scalars['String']>;
  audience: Audience;
  productAudience: ProductAudience;
  motion: Motion;
  clouds: Array<NationalCloud>;
  market: Scalars['String'];
  languages?: Maybe<GqlLanguage>;
  languageInfo?: Maybe<LanguageInfo>;
  billingCurrency: Scalars['String'];
  crmLead?: Maybe<CrmLead>;
  status: QuoteStatus;
  readOnly: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  assignedTo: UserGroup;
  expirationDate?: Maybe<Scalars['DateTime']>;
  endCustomer?: Maybe<OrganizationSimple>;
  soldTo: SoldToSimple;
  billingGroup?: Maybe<BillingGroup>;
  leadOrganization?: Maybe<OrganizationSimple>;
  recommendations: Array<RecommendationGroup>;
  lineItems: Array<LineItem>;
  invitedUser?: Maybe<InvitedUserSimple>;
  approval?: Maybe<ApprovalUnion>;
  msContact?: Maybe<User>;
  messages: Array<Message>;
  canShareLeadDiscounts: Scalars['Boolean'];
  vlAgreementNumber?: Maybe<Scalars['String']>;
  transactionModel: TransactionModel;
  vlAgreement?: Maybe<Agreement>;
  agreementType: AgreementType;
  exchangeRateDate?: Maybe<Scalars['DateTime']>;
  fgoe: Scalars['Boolean'];
  transactOnBehalf: Scalars['Boolean'];
};


export type QuoteAgreementsArgs = {
  input: GetAgreementsForQuoteInput;
};


export type QuoteRecommendationsArgs = {
  catalogContext?: Maybe<CatalogContextInput>;
};


export type QuoteInvitedUserArgs = {
  customerType?: Maybe<CustomerType>;
};

export type QuoteApproval = {
   __typename?: 'QuoteApproval';
  workflow: Scalars['String'];
  requiredApprovals?: Maybe<Array<RequiredApproval>>;
};

export type QuoteConnection = Connection & {
   __typename?: 'QuoteConnection';
  edges?: Maybe<Array<Maybe<QuoteEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type QuoteContext = {
  quoteId: Scalars['String'];
};

export type QuoteCustomer = {
   __typename?: 'QuoteCustomer';
  account?: Maybe<Account>;
  organization?: Maybe<Organization>;
  credit?: Maybe<Credit>;
  currency?: Maybe<Scalars['String']>;
};

export type QuoteEdge = Edge & {
   __typename?: 'QuoteEdge';
  id?: Maybe<Scalars['ID']>;
  cursor?: Maybe<Scalars['String']>;
  node: Quote;
};

export type QuoteHeader = {
   __typename?: 'QuoteHeader';
  priceSource?: Maybe<PriceSource>;
  status: QuoteStatus;
  assignedToGroup: UserGroup;
  readOnly: Scalars['Boolean'];
  createdApiVersion: Scalars['String'];
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  modifiedApiVersion?: Maybe<Scalars['String']>;
  modifiedBy: Scalars['String'];
  modifiedDate: Scalars['String'];
  minimumApiVersionSupported: Scalars['String'];
  submittedDate?: Maybe<Scalars['String']>;
  lastPricedDate?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  testHeaderData?: Maybe<TestHeader>;
  approvalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  msContact: Scalars['String'];
  guestUserId?: Maybe<UserId>;
  invitedUser?: Maybe<InvitedUser>;
  transactionType: TransactionType;
  minimumCreditLimit?: Maybe<Scalars['Float']>;
  estimatedDealValue: Scalars['Float'];
  totalPrice: Scalars['Float'];
  billTo?: Maybe<BillTo>;
  negotiationReason: NegotiationReason;
  businessContacts?: Maybe<Array<BusinessContact>>;
  ecifTerms?: Maybe<Array<EcifDetails>>;
  pricingContext: PricingContext;
  crmLead?: Maybe<CrmLeadOld>;
  soldToCustomer?: Maybe<QuoteCustomer>;
  endCustomer?: Maybe<QuoteCustomer>;
  extendedProperties?: Maybe<ExtendedProperties>;
};

export type QuoteMutationInput = {
  id: Scalars['String'];
  etag: Scalars['String'];
};

export type QuoteOld = {
   __typename?: 'QuoteOld';
  id: Scalars['ID'];
  version: Scalars['String'];
  revision: Scalars['Int'];
  continuationToken?: Maybe<Scalars['String']>;
  lineItems: Array<LineItemOld>;
  agreement?: Maybe<ModernAgreementDocument>;
  header: QuoteHeader;
  etag: Scalars['String'];
};

export enum QuoteStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Active = 'Active',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
  Expired = 'Expired',
  PurchaseInProgress = 'PurchaseInProgress',
  Purchased = 'Purchased',
  PurchaseFailure = 'PurchaseFailure',
  Completed = 'Completed',
  Unknown = 'Unknown'
}

export type Reason = {
   __typename?: 'Reason';
  code: Scalars['String'];
  name: Scalars['String'];
  channels: Array<Scalars['String']>;
};

export type Recipient = {
   __typename?: 'Recipient';
  accountId: Scalars['String'];
  organizationId: Scalars['String'];
  recipientId: Scalars['String'];
};

export type RecommendationGroup = {
   __typename?: 'RecommendationGroup';
  name: Scalars['String'];
  type: RecommendationType;
  source: RecommendationSource;
  items: Array<BaseRecommendationAction>;
  meta?: Maybe<Array<KeyValue>>;
  messages?: Maybe<Array<Message>>;
};

export enum RecommendationSource {
  Catalog = 'Catalog',
  Recommendations = 'Recommendations',
  UserPreferences = 'UserPreferences',
  OneAsk = 'OneAsk',
  Static = 'Static'
}

export enum RecommendationType {
  Product = 'Product',
  Term = 'Term'
}

export enum RecurrenceProcessor {
  Mcapi = 'Mcapi',
  Rirp = 'RIRP',
  Unknown = 'Unknown'
}

export type Referral = {
   __typename?: 'Referral';
  id: Scalars['ID'];
  etag: Scalars['String'];
  name: Scalars['String'];
  crmLead?: Maybe<CrmLead>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  azureType?: Maybe<AzureType>;
  referralLink?: Maybe<Scalars['String']>;
  assignedTo: UserGroup;
  status: ReferralStatus;
  organization?: Maybe<OrganizationSimple>;
  msContact: User;
  market: Scalars['String'];
  referralType: ReferralType;
  needsRepair: Scalars['Boolean'];
};

export enum ReferralFilter {
  Active = 'Active',
  Closed = 'Closed',
  Expired = 'Expired'
}

export type ReferralMutationInput = {
  id: Scalars['String'];
  etag: Scalars['String'];
};

export enum ReferralStatus {
  Draft = 'Draft',
  Transacted = 'Transacted',
  Closed = 'Closed',
  Expired = 'Expired',
  Unknown = 'Unknown'
}

export enum ReferralType {
  Referral = 'Referral',
  Claim = 'Claim'
}

export type Refund = {
   __typename?: 'Refund';
  purchaseRecordInfoForRefund: Array<PurchaseRecord>;
};

export enum RegionDisplayType {
  None = 'none',
  Optional = 'optional',
  Required = 'required'
}

export type RegionInfo = {
   __typename?: 'RegionInfo';
  code: Scalars['String'];
  display: Scalars['String'];
};

export enum RegionType {
  Province = 'province',
  Region = 'region',
  State = 'state'
}

export type RelatedProduct = {
   __typename?: 'RelatedProduct';
  relationshipType: Scalars['String'];
  product: Product;
};

export type RemoveOrganizationInput = {
  quoteIdentifier: QuoteMutationInput;
  customerType?: Maybe<CustomerType>;
};

export type RenameFavoriteGroupInput = {
  groupName: Scalars['String'];
  newGroupName: Scalars['String'];
  catalogContext: CatalogContextInput;
};

export type Renewal = {
   __typename?: 'Renewal';
  product: Product;
  sku: Sku;
  quantity: Scalars['Int'];
};

export type RequiredApproval = {
   __typename?: 'RequiredApproval';
  empoweredUsers: EmpoweredUsers;
  /** assignedUser is deprecating in favor of assignedUsers */
  assignedUser?: Maybe<Scalars['String']>;
  assignedUsers?: Maybe<Array<Scalars['String']>>;
  level: Scalars['String'];
  state?: Maybe<ApprovalLevelState>;
  order: Scalars['Int'];
  policy: Scalars['String'];
  actor?: Maybe<ApprovalActor>;
  autoPopulate: Scalars['Boolean'];
};

export type RoleAssignments = {
   __typename?: 'RoleAssignments';
  roleId: Scalars['String'];
  principalId: Scalars['String'];
  principalTenantId?: Maybe<Scalars['String']>;
  userPrincipalName?: Maybe<Scalars['String']>;
};

export type RoleAssignmentsInput = {
  roles: Array<Scalars['String']>;
  organization: OrganizationIdentifierInput;
};

export type SalesAccount = {
   __typename?: 'SalesAccount';
  id: Scalars['ID'];
  tpid?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  isStrategic: Scalars['Boolean'];
};

export type SapLineItem = LineItem & {
   __typename?: 'SapLineItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  configurationSummary?: Maybe<BaseTranslatedString>;
  subtitle?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  catalogContext: CatalogContext;
  product?: Maybe<Product>;
  sku?: Maybe<Sku>;
  availability?: Maybe<Availability>;
  term?: Maybe<Term>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadyForPricing: Scalars['Boolean'];
  purchaseTermUnits?: Maybe<Scalars['String']>;
  duration?: Maybe<DateOrDuration>;
  isTerm: Scalars['Boolean'];
  isConfigurable: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
};

/** SearchCustomerOrganizationsInput input will be deprecated to be replaced by SearchOrganizationsInput */
export type SearchCustomerOrganizationsInput = {
  query: Scalars['String'];
};

export type SearchOrganizationsInput = {
  query: Scalars['String'];
  transactionModel?: Maybe<TransactionModel>;
  audience?: Maybe<Audience>;
  isCustomer?: Maybe<Scalars['Boolean']>;
};

export type SearchOrganizationsResponse = {
   __typename?: 'SearchOrganizationsResponse';
  organizations: Array<OrganizationSimple>;
  messages: Array<Message>;
  tenant?: Maybe<TenantProfile>;
};

/** SearchPartnerOrganizationsInput input will be deprecated to be replaced by SearchOrganizationsInput */
export type SearchPartnerOrganizationsInput = {
  query: Scalars['String'];
};

export type Seat = {
   __typename?: 'Seat';
  atRiskSeats?: Maybe<Scalars['Int']>;
  tenantId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  paidSeats?: Maybe<Scalars['Int']>;
  trialSeats?: Maybe<Scalars['Int']>;
  availableSeats?: Maybe<Scalars['Int']>;
  warningSeats?: Maybe<Scalars['Int']>;
  assignedSeats?: Maybe<Scalars['Int']>;
  overageSeats?: Maybe<Scalars['Int']>;
  reservedSeats?: Maybe<Scalars['Int']>;
  links?: Maybe<Scalars['String']>;
  seatSubscriptions?: Maybe<SeatSubscriptionConnection>;
};


export type SeatSeatSubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};

export type SeatConnection = Connection & {
   __typename?: 'SeatConnection';
  edges?: Maybe<Array<Maybe<SeatEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SeatEdge = Edge & {
   __typename?: 'SeatEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Seat;
};

export type SeatSubscription = {
   __typename?: 'SeatSubscription';
  id?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  subscriptionStartDate?: Maybe<Scalars['String']>;
  subscriptionEndDate?: Maybe<Scalars['String']>;
  currentStateEndDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  includeQuantity?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  trialConversionDate?: Maybe<Scalars['String']>;
  trialEndDate?: Maybe<Scalars['String']>;
  trialQuantity?: Maybe<Scalars['Int']>;
  trialIncludedQuantity?: Maybe<Scalars['Int']>;
  trialToPaidConversionDate?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['String']>;
};

export type SeatSubscriptionConnection = {
   __typename?: 'SeatSubscriptionConnection';
  edges?: Maybe<Array<Maybe<SeatSubscriptionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SeatSubscriptionEdge = Edge & {
   __typename?: 'SeatSubscriptionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SeatSubscription;
};

export type SelectOrganizationInput = {
  quote: QuoteMutationInput;
  accountId: Scalars['String'];
  organizationId: Scalars['String'];
  /** no customerType adds both sold to and end customer */
  customerType?: Maybe<CustomerType>;
};

export enum ServiceFamily {
  Saas = 'saas',
  Compute = 'compute'
}

export type SimpleAgreementConnection = Connection & {
   __typename?: 'SimpleAgreementConnection';
  id?: Maybe<Scalars['ID']>;
  edges?: Maybe<Array<Maybe<SimpleAgreementEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SimpleAgreementEdge = Edge & {
   __typename?: 'SimpleAgreementEdge';
  id?: Maybe<Scalars['ID']>;
  cursor?: Maybe<Scalars['String']>;
  node: AgreementBase;
};

export type SimpleAgreementLegacy = AgreementBase & {
   __typename?: 'SimpleAgreementLegacy';
  id?: Maybe<Scalars['ID']>;
  tpid?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  licenseAgreementType?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  agreementNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  publicCustomerNumber?: Maybe<Scalars['String']>;
  licenseConfirmationNumber?: Maybe<Scalars['String']>;
  mpsaNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  billableAccountId?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<AgreementAddress>;
  tradeStatusName?: Maybe<Scalars['String']>;
  creditHold?: Maybe<Scalars['String']>;
  customerContacts?: Maybe<Array<Maybe<Contact>>>;
  parentAgreementNumber?: Maybe<Scalars['String']>;
  anniversaryDate?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<AgreementParticipant>>>;
  /** discount will be deprecated and replaced by discountSummary */
  discount?: Maybe<AgreementDiscount>;
  discountSummary?: Maybe<AgreementDiscount>;
  amendments?: Maybe<AmendmentConnection>;
  opportunities?: Maybe<OpportunityConnection>;
  tenants?: Maybe<TenantConnection>;
  purchaseOrders?: Maybe<LegacyPurchaseOrderConnection>;
  renewals?: Maybe<Array<Renewal>>;
};


export type SimpleAgreementLegacyAmendmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type SimpleAgreementLegacyOpportunitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type SimpleAgreementLegacyTenantsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type SimpleAgreementLegacyPurchaseOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};

export type SimpleAgreementModern = AgreementBase & {
   __typename?: 'SimpleAgreementModern';
  id?: Maybe<Scalars['ID']>;
  tpid?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  licenseAgreementType?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  agreementNumber?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  publicCustomerNumber?: Maybe<Scalars['String']>;
  licenseConfirmationNumber?: Maybe<Scalars['String']>;
  mpsaNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  billableAccountId?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<AgreementAddress>;
  tradeStatusName?: Maybe<Scalars['String']>;
  creditHold?: Maybe<Scalars['String']>;
  customerContacts?: Maybe<Array<Maybe<Contact>>>;
  parentAgreementNumber?: Maybe<Scalars['String']>;
  anniversaryDate?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<AgreementParticipant>>>;
  /** discount will be deprecated and replaced by discountSummary */
  discount?: Maybe<AgreementDiscount>;
  discountSummary?: Maybe<AgreementDiscount>;
  discounts?: Maybe<ModernAgreementDiscountConnection>;
  amendments?: Maybe<AmendmentConnection>;
  crmLeads?: Maybe<CrmLeadConnection>;
  tenants?: Maybe<Array<Scalars['String']>>;
  purchaseOrders?: Maybe<ModernPurchaseOrderConnection>;
  billingAccount?: Maybe<OrganizationSimple>;
  quotes?: Maybe<QuoteConnection>;
  leadOrg?: Maybe<OrganizationSimple>;
  renewals?: Maybe<Array<Renewal>>;
  modernAgreements?: Maybe<ModernAgreementConnection>;
};


export type SimpleAgreementModernDiscountsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type SimpleAgreementModernAmendmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Int']>;
};


export type SimpleAgreementModernCrmLeadsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};


export type SimpleAgreementModernPurchaseOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type SimpleAgreementModernQuotesArgs = {
  top?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type SimpleTermLineItem = LineItem & {
   __typename?: 'SimpleTermLineItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  configurationSummary?: Maybe<BaseTranslatedString>;
  subtitle?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  catalogContext: CatalogContext;
  product?: Maybe<Product>;
  sku?: Maybe<Sku>;
  availability?: Maybe<Availability>;
  term?: Maybe<Term>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadyForPricing: Scalars['Boolean'];
  isConfigurable: Scalars['Boolean'];
  duration?: Maybe<DateOrDuration>;
  supplementalTermReferenceData?: Maybe<KeyValue>;
};

export type Sku = {
   __typename?: 'Sku';
  productId: Scalars['String'];
  skuId: Scalars['String'];
  catalogContext: CatalogContext;
  availabilities: Array<Availability>;
  availability?: Maybe<Availability>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  quantity?: Maybe<MinMax>;
  duration?: Maybe<MinMax>;
  filters?: Maybe<Array<KeyNullableValues>>;
};


export type SkuAvailabilityArgs = {
  availabilityId: Scalars['String'];
};

export type SoldToSimple = {
   __typename?: 'SoldToSimple';
  quoteId: Scalars['ID'];
  dealEstimate?: Maybe<Scalars['Float']>;
  monthlyCreditLimit?: Maybe<Scalars['Float']>;
  organization?: Maybe<OrganizationSimple>;
};

export enum StartCondition {
  FirstOfMonth = 'FirstOfMonth',
  OnOrderAcceptance = 'OnOrderAcceptance',
  Unknown = 'Unknown'
}

export type StartEndDates = {
   __typename?: 'StartEndDates';
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type SubmitQuoteInput = {
  comment?: Maybe<Scalars['String']>;
  approvalLevels?: Maybe<Array<ApprovalLevelInput>>;
};

export type SuccessEngagement = CrmLead & {
   __typename?: 'SuccessEngagement';
  id: Scalars['ID'];
  entityId: Scalars['String'];
  type: CrmType;
  title?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  vlAgreementNumber?: Maybe<Scalars['String']>;
  salesAccount?: Maybe<SalesAccount>;
  validity: CrmValidity;
};


export type SuccessEngagementValidityArgs = {
  motion: Motion;
};

export type SupplementalTermReferenceData = {
   __typename?: 'SupplementalTermReferenceData';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SurveyResponseInput = {
  score: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
};

export type Tenant = {
   __typename?: 'Tenant';
  agreementId?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  links?: Maybe<Scalars['String']>;
  tpid?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  tenantName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  seats?: Maybe<SeatConnection>;
};


export type TenantSeatsArgs = {
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<QueryInput>;
  cursor?: Maybe<Scalars['String']>;
};

export type TenantConnection = Connection & {
   __typename?: 'TenantConnection';
  edges?: Maybe<Array<Maybe<TenantEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TenantEdge = Edge & {
   __typename?: 'TenantEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Tenant;
};

export type TenantInput = {
  tenant: Scalars['String'];
  isPrimaryTenant?: Maybe<Scalars['Boolean']>;
  isGovernmentTenant?: Maybe<Scalars['Boolean']>;
};

export type TenantProfile = {
   __typename?: 'TenantProfile';
  tenantId: Scalars['String'];
  tenantType: TenantType;
  tenantDisplayName?: Maybe<Scalars['String']>;
  organizations?: Maybe<OrganizationSimpleConnection>;
  account?: Maybe<Account>;
  tenantAdmins: Array<Scalars['String']>;
  isGovernmentTenant?: Maybe<Scalars['Boolean']>;
};


export type TenantProfileOrganizationsArgs = {
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};

export type TenantSimple = {
   __typename?: 'TenantSimple';
  id: Scalars['String'];
  isGovernmentTenant?: Maybe<Scalars['Boolean']>;
};

export enum TenantType {
  Consumer = 'Consumer',
  Managed = 'Managed',
  Viral = 'Viral'
}

export type Term = {
   __typename?: 'Term';
  productId: Scalars['String'];
  skuId: Scalars['String'];
  availabilityId: Scalars['String'];
  termId: Scalars['String'];
  catalogContext: CatalogContext;
  units: Scalars['String'];
  description: Scalars['String'];
  components?: Maybe<Array<TermComponent>>;
  price?: Maybe<Price>;
};


export type TermPriceArgs = {
  context: PriceContext;
};

export type TermBillingPlan = TermComponent & {
   __typename?: 'TermBillingPlan';
  title: Scalars['String'];
  description: Scalars['String'];
  billingPeriod: Scalars['String'];
};

export type TermComponent = {
  title: Scalars['String'];
  description: Scalars['String'];
};

export type TermDuration = TermComponent & {
   __typename?: 'TermDuration';
  title: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['String'];
};

export type TermInfo = {
   __typename?: 'TermInfo';
  startDate: Scalars['DateTime'];
};

export type TermLineItem = SimpleTermLineItem | FinancingTermLineItem | EcifLineItem;

export type TermUnknown = TermComponent & {
   __typename?: 'TermUnknown';
  title: Scalars['String'];
  description: Scalars['String'];
};

export type TestHeader = {
   __typename?: 'TestHeader';
  contact: Scalars['String'];
  retention: Scalars['String'];
  scenarios: Scalars['String'];
};

export type TidOid = {
   __typename?: 'TidOid';
  tid: Scalars['String'];
  oid: Scalars['String'];
};

export type Timezones = {
   __typename?: 'Timezones';
  first: Scalars['String'];
  last: Scalars['String'];
};

export type TradeNameInput = {
  tradeName?: Maybe<Scalars['String']>;
  organizationIdentifier: OrganizationIdentifierInput;
};

export enum TransactionModel {
  ToPartnerCustomerAsset = 'ToPartner_CustomerAsset',
  ToCustomer = 'ToCustomer',
  ToPartner = 'ToPartner',
  ToPartnerNoCustomer = 'ToPartner_NoCustomer',
  Unspecified = 'Unspecified',
  Unknown = 'Unknown'
}

export enum TransactionType {
  Unknown = 'Unknown',
  UserPurchase = 'UserPurchase',
  SystemBilling = 'SystemBilling',
  SystemPurchase = 'SystemPurchase'
}

export type TranslatedString = BaseTranslatedString & {
   __typename?: 'TranslatedString';
  key: Scalars['ID'];
  translation: Scalars['String'];
  language: GqlLanguage;
  parameters?: Maybe<Array<TranslatedStringParameter>>;
  display: Scalars['String'];
};

export type TranslatedStringParameter = {
   __typename?: 'TranslatedStringParameter';
  key?: Maybe<Scalars['ID']>;
  value: BaseTranslatedString;
};

export type UpdateModernAgreementParticipantsInput = {
  agreementId: Scalars['String'];
  accountId: Scalars['String'];
  organizationId: Scalars['String'];
  participants: Array<UpdateParticipantInput>;
};

export type UpdateParticipantInput = {
  participantId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  statusDate?: Maybe<Scalars['DateTime']>;
};

export type UploadRecommendationAction = BaseRecommendationAction & {
   __typename?: 'UploadRecommendationAction';
  title: TranslatedString;
  enabled: Scalars['Boolean'];
  meta?: Maybe<Array<KeyValue>>;
  messages?: Maybe<Array<Message>>;
};

export type User = {
   __typename?: 'User';
  mail: Scalars['ID'];
  companyName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  userPrincipalName?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Permission>>;
  preferences?: Maybe<UserPreferences>;
};


export type UserPermissionsArgs = {
  environment: PermissionEnvironment;
};

export enum UserGroup {
  Customer = 'Customer',
  Field = 'Field',
  Partner = 'Partner',
  Internal = 'Internal',
  InternalDependency = 'InternalDependency'
}

export type UserId = Muid | TidOid;

export type UserPreferences = {
   __typename?: 'UserPreferences';
  mail: Scalars['ID'];
  theme: PreferredTheme;
  dialogsViewed: Array<Scalars['String']>;
  npsSurvey?: Maybe<Array<NpsSurvey>>;
};

export type VatIdInput = {
  vatId?: Maybe<Scalars['String']>;
  organizationIdentifier: OrganizationIdentifierInput;
};
