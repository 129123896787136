import { ChoiceGroup, ErrorMessage } from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import { Address as AddressType } from 'features/customer/types';
import {
  IChoiceGroupOption,
  IChoiceGroupOptionStyleProps,
  IChoiceGroupOptionStyles,
  IChoiceGroupStyles,
} from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { Address, emptyAddress } from '../Address';
import { selectableTileStyles } from './SelectableTile.styles';

/**
 * @prop {AddressType | null | undefined} [address]
 * address : AddressType  - address found
 * address : null         - address not found
 * address : undefined    - address loading
 */
export interface SelectableOrganization {
  address?: AddressType | null;
  id: string;
  name?: string;
  doingBusinessAs?: string;
  errorMessage?: string;
}

export interface SelectableTileProps {
  loading?: boolean;
  organizationsInfo: SelectableOrganization[];
  onSelect: (organizationId: string) => void;
  dataAutomationId?: string;
  selectedKey?: string;
  label?: string;
  addFlexContainerClassName?: string;
}

type Props = SelectableTileProps & WithStyles<typeof selectableTileStyles>;

const organizationAddress = (organization: SelectableOrganization, address?: AddressType) => (
  <>
    <Address
      address={address || emptyAddress}
      dataAutomationId="organizationAddress"
      loading={!address}
      organizationName={organization.name}
      tradeName={organization.doingBusinessAs}
    />
  </>
);

const SelectableTileUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const choiceGroupStyles: IChoiceGroupStyles = {
    flexContainer: mergeClassNames([classes.flexContainer, props.addFlexContainerClassName]),
    label: classes.label,
  };

  const optionsStyles = (
    styleProps: IChoiceGroupOptionStyleProps,
    isFirstOption: boolean,
    isAddressAvailable: boolean
  ): IChoiceGroupOptionStyles => {
    const addressStyle = isAddressAvailable ? classes.dataUnavailable : classes.dataAvailable;
    const field = styleProps.checked
      ? classes.fieldChecked
      : styleProps.disabled
      ? classes.fieldDisabled
      : classes.field;
    return {
      root: isFirstOption ? classes.firstTile : classes.allTiles,
      field: mergeClassNames([addressStyle, field]),
    };
  };

  const [initialSelection] = React.useState<string | undefined>(props.selectedKey);
  const orgScrollRef = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    if (initialSelection && orgScrollRef.current) {
      orgScrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only want autoScroll to run on intial render

  const errorMessage = (message: string) => (
    <ErrorMessage addClass={classes.errorMessage} mainMessage={message} />
  );

  const options: IChoiceGroupOption[] = props.organizationsInfo.map(
    (organization, index): IChoiceGroupOption => {
      const address = organization.address;
      const disabled = !!organization.errorMessage;
      const renderAddress = () => {
        return (
          <div ref={initialSelection === organization.id ? orgScrollRef : null}>
            {address && organizationAddress(organization, address)}
            {organization.errorMessage && errorMessage(organization.errorMessage)}
          </div>
        );
      };

      return {
        key: organization.id,
        text: '',
        disabled,
        onRenderLabel: renderAddress,
        styles: (styleProps: IChoiceGroupOptionStyleProps) =>
          optionsStyles(styleProps, index === 0, address === null),
      };
    }
  );

  // Auto select when there is only one option
  if (
    props.organizationsInfo.length === 1 &&
    !props.loading &&
    !props.organizationsInfo[0].errorMessage
  ) {
    options[0].checked = true;
    props.onSelect(options[0].key);
  }

  const onChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    option && props.onSelect(option.key);
  };

  return (
    <ChoiceGroup
      dataAutomationId={props.dataAutomationId}
      label={props.label}
      options={options}
      selectedKey={props.selectedKey}
      styles={choiceGroupStyles}
      onChange={onChange}
    />
  );
};

export const SelectableTile = withStyles(selectableTileStyles)(SelectableTileUnstyled) as React.FC<
  SelectableTileProps
>;
