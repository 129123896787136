import { Border } from 'components';
import { TextBody, XXLargeIcon } from 'components/ions';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { useTheme } from 'theming';

import {
  dropZoneStyles,
  getSpacerStyles,
  hoveringOverDropZoneStyle,
  initialDropZoneStyle,
} from './QuoteListDropZone.styles';

export enum QuoteListWatermarkType {
  ProductWatermark = 'product',
  TermWatermark = 'term',
}

export interface QuoteListDropZoneProps {
  /**
   * Determines whether the dragging event ended.
   *
   * @type {boolean}
   * @memberof QuoteListDropZoneProps
   */
  isDraggingOver: boolean;
  /**
   * Displays watermark when list is empty.
   *
   * @type {boolean}
   * @memberof QuoteListDropZoneProps
   */
  isEmptyList?: boolean;
  /**
   * Determines whether a dragging event is happening.
   *
   * @type {boolean}
   * @memberof QuoteListDropZoneProps
   */
  isDragEvent?: boolean;
  /**
   * Element in the DOM where there dropzone will show over.
   *
   * @type {React.RefObject<HTMLElement>}
   * @memberof QuoteListDropZoneProps
   */
  elementRef: React.RefObject<HTMLElement>;
}

type Props = QuoteListDropZoneProps & WithStyles<typeof dropZoneStyles>;

const QuoteListDropZoneUnstyled: React.FC<Props> = (props: Props) => {
  const { isDraggingOver, isDragEvent, classes, elementRef } = props;
  const theme: ThemeProps = useTheme() as ThemeProps;
  const { t } = useTranslation();
  const elementRect =
    elementRef.current &&
    elementRef.current.parentElement &&
    elementRef.current.parentElement.getBoundingClientRect();

  /**
   * To position the icon and text for the drag zone correctly height-wise,
   * in the center, we need to offset by half the combined size of the
   * icon, padding, and text (32, 16, and 14, respectively)
   */
  const spacerOffset = 31;

  const initialDropZone = (height: number, width: number, top: number) => {
    return (
      <div style={{ ...initialDropZoneStyle(theme, height, width), top }}>
        <Border color={theme.palette.textTertiary} strokeWidth={3} x={width} y={height} />
        <div style={getSpacerStyles(height / 2 - spacerOffset, width / 2)} />
        <XXLargeIcon addClass={classes.iconAdd} iconName="CircleAdditionSolid" />
        <TextBody addClass={classes.dragText}>{t('quote::Add item(s) to quote')}</TextBody>
      </div>
    );
  };

  const hoveredOverDropZone = (height: number, width: number, top: number) => {
    return (
      <div style={{ ...hoveringOverDropZoneStyle(theme, height, width), top }}>
        <Border color={theme.palette.primary} strokeWidth={3} x={width} y={height} />
        <div style={getSpacerStyles(height / 2 - spacerOffset, width / 2)} />
        <XXLargeIcon addClass={classes.iconDown} iconName="DrillDownSolid" />
        <TextBody addClass={classes.dragText}>{t('quote::Drop to add item(s)')}</TextBody>
      </div>
    );
  };

  if (isDragEvent && elementRect) {
    return isDraggingOver
      ? hoveredOverDropZone(elementRect.height, elementRect.width, elementRect.top)
      : initialDropZone(elementRect.height, elementRect.width, elementRect.top);
  } else {
    return null;
  }
};

export const QuoteListDropZone = withStyles(dropZoneStyles)(QuoteListDropZoneUnstyled) as React.FC<
  QuoteListDropZoneProps
>;
