import { Proposal } from 'services/proposal/types';

// map publish or withdraw success response
export const mapQuotePublishSuccess = (originalProposal: Proposal, updatedProposal: any) => {
  const result: Proposal = {
    ...originalProposal,
    revision: updatedProposal.revision,
    header: {
      ...originalProposal.header,
      readOnly: updatedProposal.header.readOnly,
      status: updatedProposal.header.status,
      assignedToGroup: updatedProposal.header.assignedToGroup,
      modifiedApiVersion: updatedProposal.header.modifiedApiVersion,
      modifiedDate: updatedProposal.header.modifiedDate,
      expirationDate: updatedProposal.header.expirationDate,
      testHeaderData: {
        ...originalProposal.header.testHeaderData,
        retention: updatedProposal.header.retention,
      },
    },
    etag: updatedProposal.etag,
  };

  return result;
};
