import { ThemeProps } from 'styles';

export const workAccountStyles = (theme: ThemeProps) => ({
  secondaryText: {
    color: theme.palette.textTertiary,
  },
  input: {
    paddingTop: 18,
  },
  existingOwnersSection: {
    marginTop: 32,
    overflowY: 'auto',
    maxHeight: 286,
  },
  existingOwnersTitle: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  existingOwnersLeftColumn: {
    flex: 2,
  },
  existingOwnersRightColumn: {
    flex: 1,
    color: theme.palette.textTertiary,
  },
  existingOwnersListRow: {
    paddingBottom: 4,
    display: 'flex',
  },
  tooltipTitle: {
    display: 'inline-block',
    fontWeight: theme.fonts.fontWeights.semiBold,
    color: theme.palette.lightColor,
  },
  tooltipText: {
    display: 'inline-block',
    color: theme.palette.lightColor,
  },
  tooltipIcon: {
    paddingLeft: 4,
    paddingBottom: 2,
    verticalAlign: 'bottom',
    color: theme.palette.primary,
  },
  spinner: {
    paddingTop: 75,
  },
  partialListLoadMessage: {
    textAlign: 'center',
  },
});
