export enum DialogStatusType {
  Processing = 1,
  Fail = 2,
  Success = 3,
}

export interface DialogLayout {
  header?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
}
