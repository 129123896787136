import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { TextSharedProps } from '../TextSharedProps';
import { h4AtomStyles } from './H4Atom.styles';

type Props = TextSharedProps & WithStyles<typeof h4AtomStyles>;

const H4: React.FC<Props> = props => {
  const className = `${props.classes.headingStyles} ${props.addClass}`;
  return (
    <h4 className={className} id={props.id}>
      {props.children}
    </h4>
  );
};

export const H4Atom = withStyles(h4AtomStyles)(H4) as React.FC<TextSharedProps>;
