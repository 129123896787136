import { SecondaryButton, TextBody, TextTitle, TextTitleSecondary } from 'components';
import { openFindOrganizationDialog } from 'features-apollo/quote/components/Dialogs';
import {
  openOrganizationWizardDialog,
  OrganizationWizardFlow,
  OrganizationWizardView,
} from 'features-apollo/quote/components/Wizards';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { styles } from './GuidanceBoxStates.styles';

//#region Header
interface HeaderProps {
  addClass?: string;
  title: string;
}

type Props = HeaderProps & WithStyles<typeof styles>;

const HeaderUnStyled: React.FC<Props> = props => {
  const { classes } = props;

  return (
    <div className={props.addClass}>
      <TextTitle addClass={classes.title}>{props.title}</TextTitle>
      {props.children}
    </div>
  );
};
//#endregion

//#region Options
export interface OptionProps {
  optionNumber: number;
  readOnly?: boolean;
}

interface OptionTemplateProps {
  title: string;
  description: string;
}

type OptionTemplatePropsType = OptionTemplateProps & WithStyles<typeof styles>;

const OptionTemplateUnStyled: React.FC<OptionTemplatePropsType> = props => {
  const { classes } = props;
  return (
    <div className={classes.options}>
      <TextTitleSecondary>{props.title}</TextTitleSecondary>
      <p className={classes.paragraph}>
        <TextBody>{props.description}</TextBody>
      </p>
      {props.children}
    </div>
  );
};

export const NewOrganizationOption: React.FC<OptionProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  return (
    <OptionTemplate
      description={t(
        'quote::Establish a new billing account for the customer. Choose this option when the customer has not previously accepted an MCA.'
      )}
      title={t('quote::Option {{number}}', { number: props.optionNumber })}
    >
      <SecondaryButton
        dataAutomationId="createNewBillingAccount"
        disabled={props.readOnly}
        text={t('quote::Create new billing account')}
        onClick={() => {
          openOrganizationWizardDialog(context, {
            flow: OrganizationWizardFlow.CreateAccountAndOrganization,
            initialView: OrganizationWizardView.SearchAccount,
          });
        }}
      />
    </OptionTemplate>
  );
};

export const ExistingCustomerOption: React.FC<OptionProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  return (
    <OptionTemplate
      description={t(
        'quote::Search for the customer’s billing account. Choose this option when you know that the customer has accepted an MCA, but no billing account was linked to the sales account on the opportunity or success engagement you started with.'
      )}
      title={t('quote::Option {{number}}', { number: props.optionNumber })}
    >
      <SecondaryButton
        dataAutomationId="searchBillingAccount"
        disabled={props.readOnly}
        text={t('quote::Search')}
        onClick={() => openFindOrganizationDialog(context)}
      />
    </OptionTemplate>
  );
};
//#endregion

export const GuidanceBoxHeader = withStyles(styles)(HeaderUnStyled);
export const OptionTemplate = withStyles(styles)(OptionTemplateUnStyled);
