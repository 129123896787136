import * as React from 'react';
import { ThemeKey } from 'styles';
import { DetailsTile } from 'components';
import { CatalogContext } from 'generated/graphql';
import withStyles, { WithStyles } from 'react-jss';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { catalogSectionRowTilesStyles } from './CatalogSectionRowTiles.styles';
import {
  renderFavoriteButton,
  defaultImage,
  buildTableProps,
  buildTagsString,
} from './CatalogSectionRowTiles.utils';
import { CatalogPageProduct, CatalogPageNegotiatedTerm } from '../types';

export interface CatalogSectionRowDetailsTileProps {
  theme: ThemeKey;
  selectedProduct: CatalogPageProduct | CatalogPageNegotiatedTerm;
  detailsOnClose: () => void;
  isNegotiatedTerm: boolean;
  catalogContext?: CatalogContext;
}

type DetailsProps = CatalogSectionRowDetailsTileProps &
  WithTranslation &
  WithStyles<typeof catalogSectionRowTilesStyles>;

export const CatalogSectionRowDetailsTileUnstyled: React.FC<DetailsProps> = props => {
  const { theme, selectedProduct, detailsOnClose, isNegotiatedTerm, classes } = props;
  const { t } = useTranslation();

  let addLeftSideContent = undefined;
  const detailsTable = selectedProduct.skus && buildTableProps(selectedProduct.skus, t);
  let description = selectedProduct.description;
  let tags = {};
  if (isNegotiatedTerm) {
    if (detailsTable.tableRows.length === 1) {
      description = '';
    }
    tags = {
      [t('home::Categories')]: t('home::Negotiated Term'),
    };
  } else {
    const selectedCatalogProduct = selectedProduct as CatalogPageProduct;
    addLeftSideContent = (
      <div className={classes.detailsFavoriteButtonContainer}>
        {renderFavoriteButton(
          selectedProduct as CatalogPageProduct,
          classes,
          props.catalogContext,
          true
        )}
      </div>
    );
    tags = {
      [t('home::Categories')]:
        (selectedCatalogProduct.categories && buildTagsString(selectedCatalogProduct.categories)) ||
        '',
      [t('home::Industries')]:
        (selectedCatalogProduct.industries && buildTagsString(selectedCatalogProduct.industries)) ||
        '',
    };
  }
  const detailsTileProps = {
    addLeftSideContent,
    description,
    detailsTable,
    imageDescription: '',
    imageUrl:
      (selectedProduct.images.medium && selectedProduct.images.medium.Uri) ||
      (selectedProduct.images.logo && selectedProduct.images.logo.Uri) ||
      defaultImage(theme),
    publisher: isNegotiatedTerm ? undefined : selectedProduct.publisherName,
    tags,
    title: selectedProduct.title,
    onClickClose: () => detailsOnClose(),
  };
  return <DetailsTile {...detailsTileProps} />;
};

export const CatalogSectionRowDetailsTile = withTranslation('home')(
  withStyles(catalogSectionRowTilesStyles)(CatalogSectionRowDetailsTileUnstyled)
);
