import { ComboBox, Dialog, PrimaryButton, TextboxStandard } from 'components/ions';
import { Locale } from 'features-apollo/quote/languages';
import { formatDate, validateEmailFormat } from 'features-apollo/quote/utils';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { Notification } from 'services/notification/types';
import { ThemeProps } from 'styles';

import { sharedStyles } from '../shared.styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  comboBoxContainer: { width: '100%' },
});

export interface SelectEmailDialogProps {
  /**
   * Display in email content
   */
  assignedTo: string;
  /**
   * Display in email content
   */
  businessStoreUrl: string;
  /**
   * Identify the organization added to the quote as part of email content
   */
  customerName: string;
  /**
   * List of mail addresses available to send the email to
   */
  emailOptions: string[];
  /**
   * Display in email content
   */
  expirationDate: string | Date;
  /**
   * Display in email content
   */
  locale: Locale;
  /**
   * Display in email content
   */
  quoteId: string;
  /**
   * Display in email content
   */
  quoteName: string;
  /**
   * Display in email content
   */
  salesAgentDisplayName: string;
  /**
   * Add user to email BCC
   */
  userMail?: string;
  /**
   * Callback to send email
   */
  onSendEmail: (emailNotification: Notification) => void;
}

type Props = SelectEmailDialogProps & WithStyles<typeof styles>;

const SelectEmailDialogUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  // When listening to onChange, the entered or selected value does not stay in the ComboBox.
  // This requires to pass the user's value through the text property in the ComboBox.
  // To distinguish between just an entered email address and a valid one,the state has a valid property.
  const [emailSelected, setEmailSelected] = React.useState<{
    emailAddress: string;
    valid: boolean;
  }>();

  const disableButton = !emailSelected || !emailSelected.valid;
  const errorMessageText = props.emailOptions.length
    ? t('error::Enter valid email address or select one from dropdown.')
    : t('error::Enter valid email address.');

  const verifyEnteredEmail = (value?: string) => {
    if (!value) {
      setEmailSelected(undefined);
    } else {
      const validEmailFormat = validateEmailFormat(value);
      if (!validEmailFormat) {
        setEmailSelected({ emailAddress: value, valid: false });
        setErrorMessage(errorMessageText);
      } else {
        setEmailSelected({ emailAddress: value, valid: true });
      }
    }
  };

  const onComboBoxChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    setErrorMessage('');
    if (option) {
      setEmailSelected({ emailAddress: option.text, valid: true });
    } else {
      verifyEnteredEmail(value);
    }
  };

  const onTextBoxChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setErrorMessage('');
    verifyEnteredEmail(value);
  };

  const onClick = () => {
    if (emailSelected && emailSelected.valid) {
      const emailNotification: Notification = {
        notificationTemplate: 'ShareQuote',
        toEmailAddresses: [emailSelected.emailAddress],
        carbonCopyEmailAddresses:
          props.userMail && props.userMail !== emailSelected.emailAddress ? [props.userMail] : [],
        Body: {
          assignedTo: props.assignedTo,
          businessStoreUrl: props.businessStoreUrl,
          customerName: props.customerName,
          expirationDate: formatDate(props.expirationDate, 'YYYY-MM-DD'),
          locale: props.locale,
          quoteId: props.quoteId,
          quoteName: props.quoteName,
          salesAgentDisplayName: props.salesAgentDisplayName,
        },
      };
      props.onSendEmail(emailNotification);
    }
  };

  const content = props.emailOptions.length ? (
    <ComboBox
      allowFreeform
      autoComplete="on"
      errorMessage={errorMessage}
      id="emailAddress"
      label={t('quote::Email Address')}
      options={props.emailOptions.map(email => ({ key: email, text: email }))}
      styles={{ root: classes.comboBoxContainer }}
      text={emailSelected && emailSelected.emailAddress}
      onChange={onComboBoxChange}
    />
  ) : (
    <TextboxStandard
      dataAutomationId="emailAddress"
      errorMessage={errorMessage}
      label={t('quote::Email Address')}
      onChange={onTextBoxChange}
    />
  );

  const sendEmailButtton = (
    <PrimaryButton
      dataAutomationId="sendEmailButton"
      disabled={disableButton}
      text={t('quote::Send Email')}
      onClick={onClick}
    />
  );

  return (
    <Dialog
      footerButtons={sendEmailButtton}
      height={264}
      title={t('quote::Email Quote')}
      width={448}
    >
      {content}
    </Dialog>
  );
};

export const SelectEmailDialog = withStyles(styles)(SelectEmailDialogUnstyled);
