import 'features-apollo/user/types';

import { useGetUserPermissions } from 'features-apollo/user/hooks';
import { getUserMail } from 'features-apollo/user/selectors';
import { AsideStyles as styles } from 'features/home/components/styles/Aside.styles';
import { Permission } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { routes } from 'routes';
import { RootState } from 'store/types';

const mapStateToProps = (state: RootState) => ({
  userMail: getUserMail(state) || '',
  environment: state.app.appConfig.app,
});

type Props = WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

const HomeAside: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const { permissions } = useGetUserPermissions(props.userMail, props.environment);

  const hasReferralPermissions =
    permissions &&
    (permissions.includes(Permission.ReferralView) ||
      permissions.includes(Permission.ReferralUpdate));

  return (
    <nav className={classes.nav}>
      <NavLink
        className={classes.navLink}
        data-automation-id="quotesTab"
        to={routes.home.quotes.root}
      >
        {t('home::Quotes')}
      </NavLink>
      {hasReferralPermissions && (
        <NavLink
          className={classes.navLink}
          data-automation-id="referralsTab"
          to={routes.home.referrals.root}
        >
          {t('home::Claims')}
        </NavLink>
      )}
      <NavLink
        className={classes.navLink}
        data-automation-id="customersTab"
        to={routes.home.customers}
      >
        {t('home::Customers')}
      </NavLink>

      <NavLink className={classes.navLink} data-automation-id="catalogTab" to={routes.home.catalog}>
        {t('home::Catalog')}
      </NavLink>

      <NavLink className={classes.navLink} data-automation-id="helpTab" to={routes.home.help.root}>
        {t('home::Help')}
      </NavLink>
    </nav>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(HomeAside));
