import { meplaHistory } from 'createHistory';
import { Catalog as CatalogApollo } from 'features-apollo/catalog/components';
import { getFlightIsEnabled } from 'features/app/selectors';
import WorkArea from 'layouts/WorkArea';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

import HomeAside from './Aside';
import { Catalog } from './catalog/Catalog';
import Help from './help/Help';
import HomeCustomers from './HomeCustomers';
import HomeProposals from './HomeProposals';
import HomeReferrals from './HomeReferrals';

const mapStateToProps = (state: RootState) => ({
  fedIndirectEnabled: getFlightIsEnabled(state, Flight.graphqlPhase2),
});

type Props = WithTranslation & ReturnType<typeof mapStateToProps>;

const Home: React.FC<Props> = props => {
  const { fedIndirectEnabled } = props;
  useHotkeys('g+1', () => {
    meplaHistory.push(routes.home.quotes.root);
  });
  // We are adding /#sc to the end of the url when navigating via shortcuts to prevent autofocus on the search
  // If we don't, the 2nd key of the shortcut is typed into the autofocused search input
  useHotkeys('g+2', () => {
    meplaHistory.push(routes.home.referrals.root);
  });
  useHotkeys('g+3', () => {
    meplaHistory.push(`${routes.home.customers}/#sc`);
  });
  useHotkeys('g+4', () => {
    meplaHistory.push(`${routes.home.catalog}/#sc`);
  });
  useHotkeys('g+5', () => {
    meplaHistory.push(routes.home.help.root);
  });

  return (
    <WorkArea aside={<HomeAside />} name="Home">
      <Switch>
        <Route
          exact
          path={routes.home.root}
          render={() => <Redirect to={routes.home.quotes.root} />}
        />
        <Route component={HomeProposals} path={routes.home.quotes.root} />
        <Route component={HomeReferrals} path={routes.home.referrals.root} />
        <Route component={HomeCustomers} exact path={routes.home.customers} />
        <Route
          component={fedIndirectEnabled ? CatalogApollo : Catalog}
          exact
          path={routes.home.catalog}
        />
        <Route component={Help} path={routes.home.help.root} />
        <Route render={() => <Redirect to={routes.home.quotes.root} />} />
      </Switch>
    </WorkArea>
  );
};

export default connect(mapStateToProps)(withTranslation(['home', 'quote'])(Home));
