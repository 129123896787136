import { ThemeProps } from 'styles';

export const InfoStyles = (theme: ThemeProps) => {
  return {
    calendarContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 600,
      '& .is-disabled.ms-Button': {
        background: theme.palette.backgroundCommon,
        borderColor: theme.palette.textDisabled,
      },
    },
    calendarButtonContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    calendarWarning: {
      alignItems: 'center',
      color: theme.palette.warningText,
      display: 'flex',
      fontSize: theme.fonts.fontSizes.medium,
      marginBottom: 6,
      marginLeft: 8,
      whiteSpace: 'nowrap',

      '& > i': {
        color: theme.palette.warningText,
        marginRight: 4,
      },
    },
    container: {
      display: 'flex',
      paddingBottom: 35,
    },
    emphasisText: {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    leftContent: {
      width: 356,
    },
    leftTopContent: {
      '& > :not(:first-child)': {
        marginTop: 24,
      },
    },
    link: {
      whiteSpace: 'nowrap',
    },
    msContact: {
      flexFlow: 'row wrap',
      '& > :not(:first-child)': {
        display: 'flex',
        marginTop: 4,
      },
      '& .ms-Persona-initials, & .ms-Link': {
        fontSize: theme.fonts.fontSizes.small,
      },
    },
    cancelSearchLink: {
      paddingLeft: 8,
    },
    msContactSearchSection: {
      paddingTop: 5,
      width: 354,
      display: 'flex',
    },
    msContactSearch: {
      flex: 2,
    },
    quoteId: {
      marginLeft: 12,
    },
    textGroup: {
      paddingTop: 11,
    },
    textSubGroup: {
      display: 'block',
      marginTop: 4,
      width: 576,
    },
    textBlock: {
      display: 'block',
      marginTop: 8,
      width: 576,
    },
    rightContent: {
      paddingLeft: 180,
      paddingRight: 24,
      '& > :not(:first-child)': {
        marginTop: 44,
      },
    },
    separator: {
      marginTop: 32,
      marginBottom: 24,
      width: 700,
    },
  };
};
