import { TextAtom } from 'components/atoms/Text';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { highlightedTextStyles } from './HighlightedText.styles';

export interface HighlightedTextProps {
  addClass?: string;
  textClass?: string;
  id?: string;
  text: string;
  highlightedText?: string;
}

type Props = HighlightedTextProps & WithStyles<typeof highlightedTextStyles>;

interface IBoldProps {
  children: React.ReactNode;
  mainClass?: string;
}
const Bold = (props: IBoldProps) => (
  <TextAtom addClass={props.mainClass}>{props.children}</TextAtom>
);

// Updates highlightedCharacters with specified boolean indicating if specified word (stringToMap) should be highlighted. An
// offset can be provided in order to indicate which position the stringToMap will be in the textMap.
const updateHighlightedCharacterMap = (
  indexOffset: number,
  shouldHighlight: boolean,
  highlightedCharacters: boolean[],
  stringToMap: string
) => {
  for (let i = 0; i < stringToMap.length; i++) {
    if (highlightedCharacters.length <= i + indexOffset) {
      highlightedCharacters.push(shouldHighlight);
    } else if (!highlightedCharacters[i + indexOffset] && shouldHighlight) {
      highlightedCharacters[i + indexOffset] = true;
    }
  }
};

// Takes an array of words to be highlighted and updates the highlightedCharacters map to indicate which characters should be highlighted.
const populateHighlightedWordMap = (
  wordsToHighlight: string[],
  originalText: string,
  highlightedCharacters: boolean[]
) => {
  wordsToHighlight.forEach(highlightedWord => {
    const firstMatchIndex = originalText
      .toUpperCase()
      .indexOf((highlightedWord && highlightedWord.toUpperCase()) || '');

    const beforeHighlightString = originalText.substring(0, firstMatchIndex);
    updateHighlightedCharacterMap(0, false, highlightedCharacters, beforeHighlightString);

    const highlightString =
      firstMatchIndex !== -1
        ? originalText.substring(
            firstMatchIndex,
            firstMatchIndex + ((highlightedWord && highlightedWord.length) || 0)
          )
        : '';
    updateHighlightedCharacterMap(
      beforeHighlightString.length,
      true,
      highlightedCharacters,
      highlightString
    );

    const afterHighlightString =
      firstMatchIndex !== -1
        ? originalText.substring(
            firstMatchIndex + ((highlightedWord && highlightedWord.length) || 0)
          )
        : originalText;
    updateHighlightedCharacterMap(
      beforeHighlightString.length + highlightString.length,
      false,
      highlightedCharacters,
      afterHighlightString
    );
  });
};

// Based off of textMap, generates JSX display elements in the correct order required to display the string with
// both highlighted and non-highlighted text.
const generateDisplayElements = (highlightedCharacters: boolean[], props: Props): JSX.Element[] => {
  // to detect when bold or unbold text ends so a single string can be created
  let isPreviousCharacterHighlighted = false;
  // bold/unbold strings used to create the appropriate jsx elements
  let boldString = '';
  let unBoldString = '';
  let elementArray: JSX.Element[] = [];
  highlightedCharacters.forEach((isHighlighted, index) => {
    // initialize lastCharWasBold on first char of string
    if (index === 0) {
      isPreviousCharacterHighlighted = isHighlighted;
    }

    // append to either the unbold or bold strings while iterating
    if (isHighlighted) {
      boldString += props.text.charAt(index);
    } else {
      unBoldString += props.text.charAt(index);
    }

    const addPreviousStringAsHighlighted =
      isHighlighted !== isPreviousCharacterHighlighted && !isHighlighted;
    const addPreviousStringAsNonHighlighted =
      isHighlighted !== isPreviousCharacterHighlighted && isHighlighted;

    // push the finished jsx element into the array
    if (addPreviousStringAsHighlighted) {
      elementArray.push(
        <Bold key={index} mainClass={mergeClassNames([props.classes.bold, props.textClass])}>
          {boldString}
        </Bold>
      );
      boldString = '';
      isPreviousCharacterHighlighted = isHighlighted;
    } else if (addPreviousStringAsNonHighlighted) {
      elementArray.push(
        <TextAtom addClass={props.textClass} key={index}>
          {unBoldString}
        </TextAtom>
      );
      unBoldString = '';
      isPreviousCharacterHighlighted = isHighlighted;
    }

    // handle creating jsx element for the last bold or unbold string in the array
    if (index === highlightedCharacters.length - 1 && boldString.length) {
      elementArray.push(
        <Bold key={`1${index}`} mainClass={mergeClassNames([props.classes.bold, props.textClass])}>
          {boldString}
        </Bold>
      );
    } else if (index === highlightedCharacters.length - 1 && unBoldString.length) {
      elementArray.push(
        <TextAtom addClass={props.textClass} key={`1${index}`}>
          {unBoldString}
        </TextAtom>
      );
    }
  });
  return elementArray;
};

const HighlightedTextUnstyled: React.FC<Props> = (props: Props) => {
  // Text to be highlighted will be split up into words, each word is highlighted independent of the rest
  const textArray = (props.highlightedText || '').split(' ');

  // highlightedCharacters will map to original string and contain will track which characters should be highlighted
  let highlightedCharacters: boolean[] = [];
  populateHighlightedWordMap(textArray, props.text, highlightedCharacters);

  const elementArray: JSX.Element[] = generateDisplayElements(highlightedCharacters, props);

  return (
    <div className={props.addClass} id={props.id}>
      {elementArray}
    </div>
  );
};
export const HighlightedText = withStyles(highlightedTextStyles)(
  HighlightedTextUnstyled
) as React.FC<HighlightedTextProps>;
