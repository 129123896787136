import {
  AgreementType,
  Approval,
  NationalCloud,
  OpportunitySimple,
  OrderDirection,
  ProductAudience,
  QueryGetQuotesArgs,
  Quote,
  QuoteStatus,
  TransactionModel,
  UserGroup,
} from 'generated/graphql';
import { createSelector } from 'reselect';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { defaultLanguage, LanguageLocale } from '../languages';
import { getRemainingDays } from '../utils';

//#region GQL selectors
/**
 * Gets the approval object from ApprovalUnion in the quote
 */
export const getApproval = (quote: Quote) =>
  oc(quote).approval.__typename() === 'Approval' ? (quote.approval as Approval) : undefined;

/**
 * Gets the user group that has access to the quote
 */
export const getAssignedTo = (quote: Quote) => quote.assignedTo;

/**
 * Gets the channel selected for the quote
 */
export const getChannel = (quote: Quote) => quote.motion;

/**
 * Gets the CRM information found in the quote
 */
export const getCRMLead = (quote: Quote) => quote.crmLead;

/**
 * Gets the opportunity information from the CRM ID added to the quote.
 */
export const getCRMOpportunity = (quote: Quote) => {
  const crmLead = getCRMLead(quote);
  // @ts-ignore
  return crmLead && crmLead.__typename === 'OpportunitySimple'
    ? (crmLead as OpportunitySimple)
    : undefined;
};

/**
 * Gets the contact email of the opportunity in the quote.
 */
export const getCRMContactMail = (quote: Quote) => {
  const opportunity = getCRMOpportunity(quote);
  return oc(opportunity).contact.mail();
};

/**
 * Gets the agreement enrollment number on the quote
 */
export const getEnrollmentNumber = (quote: Quote) => quote.vlAgreementNumber;

/**
 * Gets the expiration date on the quote
 */
export const getExpirationDate = (quote: Quote): string | null | undefined => quote.expirationDate;

/**
 * Gets the date where the exchange rates in line items were valid
 */
export const getExchangeRateDate = (quote: Quote) => {
  const exchangeDateISO = quote.exchangeRateDate;
  return exchangeDateISO ? new Date(exchangeDateISO) : undefined;
};

/**
 * Gets language defined in the quote
 * @default en-US
 */
export const getLanguage = (quote: Quote) => quote.languages || defaultLanguage;

/**
 * Gets locale of the language of the quote.
 */
export const getLocale = createSelector(getLanguage, language => LanguageLocale[language]);

/**
 * Gets the line items in the quote
 */
export const getLineItems = (quote: Quote) => quote.lineItems;

/**
 * Gets the Microsoft Contact information from the quote.
 */
export const getMSContactFullName = (quote: Quote) => oc(quote).msContact.displayName();

/**
 * Gets Microsoft contact mail from the quote.
 */
export const getMSContactMail = (quote: Quote) => oc(quote).msContact.mail();

/**
 * Gets the product audience defined in the quote
 */
export const getProductAudience = (quote: Quote) => quote.productAudience;

/**
 * Gets the segment selected for the quote
 */
export const getSegment = (quote: Quote) => quote.audience;

/**
 * Gets the list of special agreements found in quote
 */
export const getSpecialAgreements = (quote: Quote) => quote.agreements;

/**
 * Gets the status of the quote
 */
export const getStatus = (quote: Quote) => quote.status;

/**
 * Gets the transaction model set in the quote
 */
export const getTransactionModel = (quote: Quote) => quote.transactionModel;

/**
 * Gets the version of the quote
 */
export const getVersion = (quote: Quote) => quote.version;

/**
 * Whether or not the quote is an High Risk Deal Desk (HRDD)
 * NOTE: These quotes are created outside Quote Center and are only use in QC for approval purposes only.
 */
export const isQuoteHRDD = (quote: Quote) => {
  const transactionModel = getTransactionModel(quote);
  const productAudience = getProductAudience(quote);
  const assignedTo = getAssignedTo(quote);

  return (
    transactionModel !== TransactionModel.ToPartnerCustomerAsset &&
    productAudience === ProductAudience.PartnerCommercial &&
    assignedTo === UserGroup.Partner
  );
};

/**
 * Whether or not the quote context is to a partner for a customer
 */
export const isQuoteIndirect = (quote: Quote) => {
  const transactionModel = getTransactionModel(quote);
  return transactionModel === TransactionModel.ToPartnerCustomerAsset;
};

/**
 * Whether or not the quote context is for JIO CSP (no customer)
 */
export const isQuoteJioCsp = (quote: Quote) => {
  const transactionModel = getTransactionModel(quote);
  const productAudience = getProductAudience(quote);
  const clouds = quote.clouds;
  return (
    transactionModel === TransactionModel.ToPartnerNoCustomer &&
    productAudience === ProductAudience.PartnerCommercial &&
    clouds.length === 1 &&
    clouds[0] === NationalCloud.Global
  );
};

/**
 * Whether or not the quote agreement type is Legacy
 */
export const isQuoteLegacy = (quote: Quote) => quote.agreementType === AgreementType.Legacy;

/**
 * Whether or not the quote has less than ten days before expiring
 */
export const isQuoteExpiringSoon = (quote: Quote) => {
  const expirationDate = getExpirationDate(quote);
  if (!expirationDate) {
    return false;
  }

  const today = new Date();
  const daysDifference = getRemainingDays(today, expirationDate);
  const status = getStatus(quote);

  return 0 < daysDifference && daysDifference < 10 && status !== QuoteStatus.Completed;
};

/**
 * Whether or not the user can recover an expired quote
 */
export const isExpirationDateExtendable = (quote: Quote) => {
  const lineItemsTotal = getLineItems(quote).length;
  const status = getStatus(quote);
  const version = getVersion(quote);

  return status === QuoteStatus.Expired && +version >= 11 && !!lineItemsTotal;
};

/**
 * Whether or not the quote has multiple participants
 */
export const hasMultipleParticipants = (quote: Quote) =>
  isQuoteIndirect(quote) || isQuoteHRDD(quote);
//#endregion

//#region Redux selectors
export const getQueryGetQuotesArgs = createSelector(
  (state: RootState) => state.proposal.views.summaryList.search,
  ({ filter, sortField, sortOrder }) => {
    const quotesSearchFilters: QueryGetQuotesArgs = {
      query: filter,
      sort: [{ orderField: sortField, orderDirection: sortOrder.toUpperCase() as OrderDirection }],
    };
    return quotesSearchFilters;
  }
);
//#endregion
