import { LinkButton, LinkEmail } from 'components';
import { ErrorMessage } from 'components/ions/CuratedText/Messages/Messages';
import { hasIncompleteOrganization } from 'features-apollo/quote/components/PropertySheets/Customer/utils';
import {
  openOrganizationWizardDialog,
  OrganizationWizardFlow,
} from 'features-apollo/quote/components/Wizards/OrganizationWizard';
import { Address } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { organizationInformationStyles } from '../OrganizationInformation.styles';

export interface OrganizationInformationErrorMessageProps {
  organizationEditable: boolean;
  organizationAddress: Address;
}

type Props = OrganizationInformationErrorMessageProps &
  WithStyles<typeof organizationInformationStyles>;

const OrganizationInformationErrorMessageUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const errorMessageData = props.organizationEditable
    ? {
        message: t(
          'quote::The billing account name or address is incorrect and must be fixed before the quote can be published.'
        ),
        link: (
          <LinkButton
            dataAutomationId="incorrectBillingAccountEditButton"
            displayText={t('quote::edit billing account')}
            onClick={() =>
              openOrganizationWizardDialog(context, { flow: OrganizationWizardFlow.Edit })
            }
          />
        ),
      }
    : {
        message: t(
          'quote::The billing account is missing information that must be completed before the quote can be published. Please contact Quote Center Support for assistance.'
        ),
        link: <LinkEmail displayText="QCSupport@microsoft.com" email="QCSupport@microsoft.com" />,
      };

  const organizationIsMissingInformation = hasIncompleteOrganization(props.organizationAddress);
  return organizationIsMissingInformation ? (
    <ErrorMessage
      addClass={classes.errorTextFonts}
      addContainerClass={classes.errorTextContainer}
      mainMessage={errorMessageData.message}
    >
      {errorMessageData.link}
    </ErrorMessage>
  ) : null;
};

export const OrganizationInformationErrorMessage = withStyles(organizationInformationStyles)(
  OrganizationInformationErrorMessageUnstyled
);
