import { Dialog, PrimaryButton, TextBody, TextBodySmall, VerificationField } from 'components';
import { Dimensions } from 'features/components/dialogs';
import { crmIDRegExp } from 'features/proposal/utils';
import { CrmLead, CrmType, CrmValidity, Motion, SalesAccount } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext, ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { useLazyQuery } from '@apollo/react-hooks';

import { ValidateCrmId } from '../queries';

const styles = (theme: ThemeProps) => ({
  salesAccount: {
    color: theme.palette.textPrimaryDisabled,
  },
  spacing: {
    paddingTop: 16,
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
  },
});

interface SelectCrmIdViewProps extends Dimensions {
  crmIdAssociated: boolean;
  motion: Motion;
  onAssociateClick: (crmId: string) => void;
  fgoe?: boolean;
}

type Props = SelectCrmIdViewProps & WithStyles<typeof styles>;

const SelectCrmIdViewUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { classes } = props;
  const context = React.useContext(DialogContext);

  const [lastCrmIdVerified, setLastCrmIdVerified] = React.useState<string | undefined>();
  const [userInput, setUserInput] = React.useState<string | undefined>();
  const [isCrmIdError, setIsCrmIdError] = React.useState<boolean>(false);
  const [errorCrmMessage, setErrorCrmMessage] = React.useState<string | undefined>();
  const [crmLeadType, setCrmLeadType] = React.useState<string | undefined>();
  const [salesAccount, setSalesAccount] = React.useState<SalesAccount | undefined>();

  const [validateCrmId, { loading, error, data }] = useLazyQuery(ValidateCrmId, {
    onCompleted: data => {
      if (data) {
        const crmLead: CrmLead = data.getCRMLead;
        if (crmLead.validity === CrmValidity.Valid) {
          const leadType =
            crmLead.type === CrmType.FgoeOpportunity || crmLead.type === CrmType.Opportunity
              ? t('quote::Opportunity')
              : t('quote::Success Engagement');
          setCrmLeadType(leadType);
          setIsCrmIdError(false);
          setErrorCrmMessage(undefined);
          if (crmLead.salesAccount) setSalesAccount(crmLead.salesAccount);
        } else if (crmLead.validity === CrmValidity.UnsupportedSalesAccountMarket) {
          setIsCrmIdError(true);
          setErrorCrmMessage(
            t(
              'quote::The customer account associated with the CRM ID is in an unsupported market. You must either select a different account for this customer or sell to them using a different sales motion.'
            )
          );
        } else {
          setIsCrmIdError(true);
          setErrorCrmMessage(t('quote::CRM ID not found'));
        }
      } else {
        setErrorCrmMessage(t('quote::We encountered a problem while verifying.'));
      }
    },
    onError: error => {
      const errorCode = oc(error).graphQLErrors[0].extensions.code('');
      if (errorCode === 'CRMLEAD_NOT_FOUND') {
        setIsCrmIdError(true);
        setErrorCrmMessage(t('quote::CRM ID not found'));
      } else {
        setErrorCrmMessage(t('quote::We encountered a problem while verifying.'));
      }
    },
  });

  const isFgoeOppty = oc(data).getCRMLead.type() === CrmType.FgoeOpportunity;

  const displayInformation = userInput === lastCrmIdVerified && !errorCrmMessage && crmLeadType;

  // Verifies if address is defined to show both information simultaneously and avoid visual delay
  const label =
    displayInformation && (salesAccount || (!salesAccount && isFgoeOppty)) && crmLeadType
      ? t('quote::CRM ID ({{crmLeadType}})', { crmLeadType })
      : t('quote::CRM ID');

  const salesAccountName = displayInformation && salesAccount && salesAccount.address && (
    <div className={classes.spacing}>
      <TextBodySmall
        addClass={`${classes.salesAccount} ${classes.sectionRow}`}
        dataAutomationId="crmIdViewSalesAccountText"
      >
        {t('quote::Sales account')}
      </TextBodySmall>
      <TextBody addClass={classes.spacing} dataAutomationId="crmIdViewsalesAccountCompany">
        {salesAccount.address.companyName}
      </TextBody>
    </div>
  );

  const onChange = (value?: string) => {
    setUserInput(value);
  };

  const onVerifyCrmId = (crmId: string) => {
    setLastCrmIdVerified(crmId);
    setIsCrmIdError(false);
    validateCrmId({
      variables: {
        id: crmId,
        motion: props.motion,
        fgoe: props.fgoe,
      },
    });
  };

  const associateCrmId = () => {
    lastCrmIdVerified && props.onAssociateClick(lastCrmIdVerified);
  };

  const button = (
    <PrimaryButton
      dataAutomationId="crmIdAssociateButton"
      disabled={(!salesAccount && !isFgoeOppty) || isCrmIdError}
      text={t('quote::Associate')}
      onClick={associateCrmId}
    />
  );

  if (props.crmIdAssociated) context.closeDialog();

  return (
    <Dialog
      dataAutomationId="selectOpportunityDialog"
      footerButtons={button}
      height={props.height}
      title={t('quote::Enter opportunity or success engagement')}
      width={props.width}
    >
      <TextBody>
        {t('quote::All quotes must be associated with an opportunity or a success engagement.')}
      </TextBody>
      <VerificationField
        buttonText={t('quote::Verify')}
        containerClassName={classes.spacing}
        dataAutomationId="crmIdVerification"
        errorMessage={errorCrmMessage}
        isError={!!error || isCrmIdError}
        isLoading={loading && !salesAccount && !isFgoeOppty && !error}
        isVerified={!loading && !error && !isCrmIdError}
        lastVerified={lastCrmIdVerified}
        showButtonWhenVerified={!isFgoeOppty}
        textboxLabel={label}
        textboxPlaceholder={t('quote::Enter an opportunity or a success engagement ID')}
        validationErrorMessage={t('quote::Enter a valid CRM ID')}
        verifiedStatusText={t('quote::Verified')}
        onChangeDebounced={onChange}
        onValidate={(value: string) => crmIDRegExp.test(value)}
        onVerify={onVerifyCrmId}
      />
      {salesAccountName}
    </Dialog>
  );
};

export const SelectCrmIdView = withStyles(styles)(SelectCrmIdViewUnstyled);
