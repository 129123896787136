import { AddressType } from 'components';
import { Address } from 'generated/graphql';
import { oc } from 'ts-optchain';

import { getValueOrUndefined } from '../../utils';

export const isInvalidCompanyName = (companyName: string) =>
  companyName.toLowerCase() === 'default directory';

export const hasIncompleteOrganization = (address: Address): boolean => {
  return (
    !address.addressLine1 ||
    !address.city ||
    !!(address.companyName && isInvalidCompanyName(address.companyName))
  );
};

export const convertAddressToAddressType = (address: Address): AddressType => {
  const country = oc(address).marketInfo.display() || address.country;

  return {
    companyName: getValueOrUndefined(address.companyName),
    addressLine1: getValueOrUndefined(address.addressLine1),
    addressLine2: getValueOrUndefined(address.addressLine2),
    addressLine3: getValueOrUndefined(address.addressLine3),
    // TODO: jejungk - uncomment when this is available in GQL Prod. It combines city/region/postalCode into correct display order based on market
    // will also need to update the Address component to use that instead of current implementation
    // addressLastLine: getValueOrUndefined(address.addressLastLine),
    city: getValueOrUndefined(address.city),
    region: getValueOrUndefined(address.region),
    country: getValueOrUndefined(country),
    postalCode: getValueOrUndefined(address.postalCode),
    phoneNumber: getValueOrUndefined(address.phoneNumber),
    email: getValueOrUndefined(address.email),
  };
};
