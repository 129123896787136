import { ThemeProps } from 'styles';

import { buttonSharedStyles } from '../shared.styles';

export const headerButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    ...buttonSharedStyles(theme),
    iconButton: {
      height: 48,
      minWidth: 48,
      width: 48,
      padding: '14px 14px 13px 14px',
      '& i': {
        fontSize: theme.fonts.fontSizes.xLarge,
      },
    },
    chevron: {
      '& .ms-Button-menuIcon': {
        display: 'none',
      },
    },
    calloutMenu: {
      backgroundColor: colors.backgroundStandout,
      borderRadius: '0px 2px 2px 2px',
    },
    expanded: {
      backgroundColor: colors.backgroundStandout,
      boxShadow: `${colors.shadowColor} 0px 0px 7px -2px`,
      color: colors.textSecondary,
      '& i': {
        color: colors.textSecondary,
      },
      '&:hover': {
        backgroundColor: colors.backgroundStandout,
      },
      '&:focus': {
        backgroundColor: colors.backgroundStandout,
      },
    },
    primary: {
      backgroundColor: colors.primary,
      color: 'white',
      '&:hover': {
        backgroundColor: colors.primaryHover,
        color: 'white',
        '& i': {
          color: 'white',
        },
      },
      '&:active': {
        backgroundColor: colors.primaryActive,
        color: 'white',
        '& i': {
          color: 'white',
        },
      },
      '&:focus': {
        backgroundColor: colors.primaryHover,
        color: 'white',
        '& i': {
          color: 'white',
        },
      },
      '&:disabled': {
        backgroundColor: colors.backgroundDivider,
        color: colors.textPrimaryDisabled,
        '& i': {
          color: colors.textPrimaryDisabled,
        },
      },
      '& i': {
        color: 'white',
      },
    },
    primaryExpanded: {
      backgroundColor: colors.backgroundStandout,
      color: colors.textSecondary,
      boxShadow: `${colors.shadowColor} 0px 0px 7px -2px`,
      '& i': {
        color: colors.textSecondary,
      },
      '&:hover': {
        backgroundColor: colors.backgroundStandout,
        color: colors.textSecondary,
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:focus': {
        backgroundColor: colors.backgroundStandout,
        color: colors.textSecondary,
        '& i': {
          color: colors.textSecondary,
        },
      },
    },
    demo: {
      backgroundColor: colors.demoColor,
      color: colors.lightColor,
      '&:hover': {
        backgroundColor: colors.darkColor,
        color: colors.lightColor,
        '& i': {
          color: colors.lightColor,
        },
      },
      '&:active': {
        backgroundColor: colors.darkColor,
        color: colors.lightColor,
        '& i': {
          color: colors.lightColor,
        },
      },
      '&:focus': {
        backgroundColor: colors.darkColor,
        color: colors.lightColor,
        '& i': {
          color: colors.lightColor,
        },
      },
      '&:disabled': {
        backgroundColor: colors.darkColor,
        color: colors.textPrimaryDisabled,
        '& i': {
          color: colors.textPrimaryDisabled,
        },
      },
      '& i': {
        color: colors.lightColor,
      },
    },
  };
};
