import { BarButton } from 'components/ions';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface AddSharedDiscountsButtonProps {
  /**
   * Identifies the quote where the line items can be found.
   *
   * @type {string}
   * @memberof AddSharedDiscountsButtonProps
   */
  quoteId: string;
  /**
   * Prevents adding discounts when quote cannot be edited.
   *
   * @type {boolean}
   * @memberof AddSharedDiscountsButtonProps
   */
  isQuoteReadOnly: boolean;
  /**
   * Disables the button
   *
   * @type {boolean}
   * @memberof AddSharedDiscountsButtonProps
   */
  disabled?: boolean;
  /**
   * Whether there are discounts available to be shared and added to the quote
   *
   * @type {boolean}
   * @memberof AddSharedDiscountsButtonProps
   */
  canShareLeadDiscounts: boolean;
  /**
   * Function to run when button is clicked
   *
   * @memberof AddSharedDiscountsButtonProps
   */
  onClick?: () => void;
}

export const AddSharedDiscountsButton: React.FC<AddSharedDiscountsButtonProps> = props => {
  const { t } = useTranslation();
  const { onClick } = props;
  // TODO: jepagan - complete when mutation is available
  // const { quoteId } = props;
  // const [addSharedDiscounts, loading] = useMutation(ADD_SHARED_DISCOUNTS);
  // React.useEffect(() => props.setLoading(!!loading), [loading]);

  return (
    <BarButton
      dataAutomationId="add-shared-discounts-button"
      disabled={props.isQuoteReadOnly || !props.canShareLeadDiscounts}
      iconName="Add"
      text={t('quote::Add shared discounts')}
      onClick={() => {
        onClick && onClick();
      }}
    />
  );
};
