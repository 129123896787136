import { Spinner, SpinnerSize } from 'components/ions/Spinner';
import { quoteCenterLatestUrl } from 'features/app/config/configurations';
import { AppEnvironment } from 'features/app/config/type';
import { HelpContent } from 'features/app/types';
import { useLoadingDebounce } from 'hooks';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { oc } from 'ts-optchain';

import { ArticleLoadError } from './ArticleLoadError';
import { Article, ArticleContext } from './HelpPanel.types';
import { getArticleGroupConfig } from './HelpPanel.utils';
import { HelpSection, HelpSectionLink } from './HelpSection';

const styles = () => ({
  spinner: {
    marginTop: 64,
  },
});

type Props = {
  appEnvironment: AppEnvironment;
  contentType: HelpContent;
  onArticleClick: (article: Article) => void;
} & WithStyles<typeof styles>;

const HelpPanelArticleGroupUnstyled: React.FC<Props> = props => {
  const { appEnvironment, classes, contentType, onArticleClick } = props;

  React.useEffect(() => {
    loggerService.log({ name: 'Help context viewed' }, { contentType });
  }, [contentType]);

  const [config, setConfig] = React.useState<ArticleContext>();
  const [hasError, setHasError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const loadingDebounce = useLoadingDebounce(setIsLoading, 500);

  React.useEffect(() => {
    const loadConfig = async () => {
      const baseUrl = appEnvironment === AppEnvironment.Local ? quoteCenterLatestUrl : '';
      const config = await loadingDebounce(() => getArticleGroupConfig(baseUrl, contentType));
      if (config) {
        setConfig(config);
      } else {
        setHasError(true);
      }
    };

    loadConfig();
  }, [contentType, setConfig, appEnvironment, loadingDebounce]);

  const articleGroups = oc(config).groups([]);

  if (hasError) {
    return <ArticleLoadError />;
  }

  if (isLoading) {
    return <Spinner addClass={classes.spinner} size={SpinnerSize.large} />;
  }

  return (
    <>
      {articleGroups.map((group, groupIndex) => {
        const links: HelpSectionLink[] = [];

        group.articles.forEach((article, articleIndex) => {
          links.push({
            key: `articleLink-${groupIndex + articleIndex}`,
            text: article.name,
            onClick: () => {
              loggerService.log(
                { name: 'Help article clicked' },
                {
                  contentType,
                  groupName: group.title,
                  articleName: article.name,
                  articleFile: article.filename,
                  articleUpdatedDate: article.updatedDate,
                }
              );
              onArticleClick(article);
            },
          });
        });

        return <HelpSection key={groupIndex} links={links} title={group.title} />;
      })}
    </>
  );
};

export const HelpPanelArticleGroup = withStyles(styles)(HelpPanelArticleGroupUnstyled);
