import { ThemeProps } from 'styles';

export const quoteListColumnHeadersStyle = (theme: ThemeProps) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.backgroundCommon,
      borderBottom: `2px solid ${theme.palette.backgroundDivider}`,
      '& > :last-child': { paddingRight: 36 },
    },
    column: {
      backgroundColor: theme.palette.backgroundCommon,
      padding: '8px 16px',
    },
  };
};

export const quoteListStyles = (theme: ThemeProps) => {
  return {
    listWrapper: {
      height: '100%',
      width: '100%',
      display: 'flex',
    },
    list: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.backgroundCommon,
      border: `1px solid ${theme.palette.backgroundDivider}`,
      boxSizing: 'border-box',
      boxShadow: '0px 6px 15px rgba(0,0,0,0.2)',
      zIndex: 1,
      position: 'relative',
      minWidth: 850,
    },
    listBody: {
      overflowY: 'overlay',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      flexGrow: 1,
      paddingTop: 22,
      minHeight: 72,
    },
    watermarkContainer: {
      textAlign: 'center',
      paddingTop: '72px',
      width: '350px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    watermarkText: {
      display: 'block',
      paddingBottom: '12px',
      lineHeight: '150%',
    },
    detailsPane: {
      height: '100%',
      overflowY: 'auto',
      width: 335,
      backgroundColor: theme.palette.backgroundStandout,
      minWidth: 335,
      borderTop: `solid 1px ${theme.palette.backgroundDivider}`,
      borderRight: `solid 1px ${theme.palette.backgroundDivider}`,
    },
  };
};
