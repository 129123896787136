import { ThemeProps } from 'styles';

export const referralSummaryDetailStyles = (theme: ThemeProps) => {
  return {
    detail: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 0,
      minHeight: 0,
      fontSize: 12,
      flexGrow: 1,
    },
    header: {
      padding: '22px 32px 22px',
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
      background: theme.palette.backgroundStandout,
      display: 'flex',
      flexDirection: 'column',

      '& h1': {
        flexGrow: 1,
      },

      '& aside': {
        textAlign: 'right',

        '& label': {
          marginTop: 6,
        },
      },
    },
    main: {
      background: theme.palette.backgroundCommon,
      height: '100%',
      padding: '0 30px 30px 30px',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    spacing: { marginTop: 24, width: '100%' },
    separator: { marginTop: 32 },
    spinner: {
      flexGrow: 0.5,
    },
    noBilling: { fontStyle: theme.fonts.fontStyles.italic },
    noCRMId: {
      maxWidth: 314,
      fontStyle: theme.fonts.fontStyles.italic,
    },
    detailContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 24,
    },
    lightText: { color: theme.palette.textTertiary },
    persona: { paddingTop: '3px', display: 'flex' },
    noContact: {
      paddingTop: 12,
      color: theme.palette.textDisabled,
      fontStyle: theme.fonts.fontStyles.italic,
      marginLeft: '-15px',
      fontSize: theme.fonts.fontSizes.medium,
    },
    divider: {
      backgroundColor: theme.palette.backgroundDivider,
      display: 'inline-block',
      height: 19,
      marginLeft: 8,
      width: 1,
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    dotsButton: {
      paddingTop: 0,
      paddingBottom: 3,
      paddingRight: 3,
      paddingLeft: 3,
      height: 27,
    },
    subscriptionInput: {
      position: 'relative',
      background: 'none',
      border: 'none',
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      fontSize: theme.fonts.fontSizes.medium,
      color: theme.palette.text,
      fontWeight: theme.fonts.fontWeights.regular,
      minWidth: 300,
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    errorMessage: {
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      fontSize: theme.fonts.fontSizes.small,
      color: theme.palette.dangerText,
    },
  };
};
