import { TextBody } from 'components/ions';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { Price } from 'features-apollo/components/Price';
import React, { CSSProperties } from 'react';

export const PLACEHOLDER_UNDEFINED = '-';

export const renderPrice = (amount: string | number | null | undefined, currency: string) => (
  <Price amount={amount} currency={currency} />
);

/**
 * Gets UX for text content in rows
 *
 * @param value text to format
 */
export const textFormatter = (value?: string | number | null) => {
  return <TextBody>{value || PLACEHOLDER_UNDEFINED}</TextBody>;
};

/**
 * Gets UX for percentage content in rows
 *
 * @param value percentage to format
 */
export const percentageFormatter = (value?: number | null) => {
  return <TextBody>{value != null ? `${value}%` : PLACEHOLDER_UNDEFINED}</TextBody>;
};

/**
 * Gets UX for product titles in rows
 *
 * @param value text to format
 */
export const titleFormatter = (value?: string | null) => {
  const leftOverflowStyle: CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    direction: 'rtl',
    textAlign: 'left',
  };

  const title = value || PLACEHOLDER_UNDEFINED;

  return (
    <div style={leftOverflowStyle}>
      <TextBody title={title}>{title}</TextBody>
    </div>
  );
};

export const getDateContent = (date?: Date | null, enableEvergreen?: boolean) => {
  let display = '';
  if (date === null && enableEvergreen) {
    display = 'Evergreen';
  } else if (date) {
    const convertedDate = convertDateToFormattedString(date, LocaleDateFormat.ll);
    const isEvergreen = convertedDate === 'Dec 31, 9999';
    display = isEvergreen && enableEvergreen ? 'Evergreen' : convertedDate;
  } else {
    display = PLACEHOLDER_UNDEFINED;
  }
  return display;
};
