import { ProgressIndicatorAtom, TextboxAtom, TextboxAtomProps } from 'components/atoms';
import { IProgressIndicatorStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => {
  return {
    container: {
      width: 294,
    },
    errorMessage: {
      width: 506,
    },
    itemProgress: {
      borderRadius: '50%',
      padding: 0,
    },
    progressBar: {
      background: theme.palette.progressBar,
    },
    progressTrack: {
      backgroundColor: 'transparent',
    },
    iconStyle: {
      display: 'inline',
    },
  };
};

export interface TextboxWithProgressProps extends TextboxAtomProps {
  progressAriaValueText?: string;
  progressDescription?: React.ReactNode;
  progressLabel?: React.ReactNode;
  progressHidden?: boolean;
  dataAutomationId?: string;
}

type Props = TextboxWithProgressProps & WithStyles<typeof styles>;

export const TextboxWithProgressUnstyled: React.FC<Props> = (props: Props) => {
  const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress: props.classes.itemProgress,
    progressBar: props.classes.progressBar,
    progressTrack: props.classes.progressTrack,
  };

  return (
    <div>
      <TextboxAtom
        addClass={props.addClass}
        ariaLabel={props.ariaLabel || props.label}
        autoFocus={props.autoFocus}
        dataAutomationId={props.dataAutomationId}
        disabled={props.disabled}
        errorMessage={props.errorMessage}
        iconProps={props.iconProps}
        id={props.id}
        label={props.label}
        labelStyle={props.labelStyle}
        maxLength={props.maxLength}
        multiline={props.multiline}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        required={props.required}
        resizable={props.resizable}
        rows={props.rows}
        suffix={props.suffix}
        title={props.title}
        value={props.value}
        onChange={props.onChange}
        onGetErrorMessage={props.onGetErrorMessage}
        onKeyDown={props.onKeyDown}
      />
      <ProgressIndicatorAtom
        ariaValueText={props.progressAriaValueText}
        description={props.progressDescription}
        label={props.progressLabel}
        progressHidden={props.progressHidden}
        styles={progressIndicatorStyles}
      />
    </div>
  );
};

export const TextboxWithProgress = withStyles(styles)(TextboxWithProgressUnstyled) as React.FC<
  TextboxWithProgressProps
>;
