import axios from 'axios';
import { TokenAuthorities } from 'services/utils';
import { PageResponse } from 'services/types';

import { createGuid, getAuthHeader, getCV, getTestHeader, validatePageRequest } from '../utils';
import { endpoints, ProjectConfig } from './config';
import { FilterType, GetProjectRequest, Project } from './types';

async function getHeaders(config: ProjectConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
    'api-version': '2019-05-31',
    'ms-cv': getCV(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function getProjects(
  request: GetProjectRequest,
  config: ProjectConfig
): Promise<PageResponse<Project>> {
  validatePageRequest(request);
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  let params, url;
  if (request.nextLink) {
    url = `${host}${request.nextLink}`;
  } else {
    url = `${host}/projects`;
    params =
      request.filter === FilterType.AccountId
        ? { accountId: request.id }
        : { externalReferenceId: `msl:${request.id}` };
  }

  const response = await axios.get<PageResponse<Project>>(url, { params, headers });
  return response.data;
}
