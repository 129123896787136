import { ThemeProps } from 'styles';

export const AgreementTermButtonStyles = (theme: ThemeProps) => ({
  container: {
    position: 'relative',
  },
  button: {
    maxWidth: 279,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:disabled': {
      color: theme.palette.textTertiary,
    },
    '&:hover:disabled': {
      color: theme.palette.textTertiary,
    },
  },
  teachingBubbleHeadline: {
    color: theme.palette.text,
    fontSize: '16px',
    paddingBottom: '16px',
  },
  teachingBubbleText: {
    color: theme.palette.text,
  },
  teachingBubbleBeak: {
    background: theme.palette.lightColor,
  },
  teachingBubbleBeakCurtain: {
    background: theme.palette.lightColor,
  },
  teachingBubbleCalloutMain: {
    background: theme.palette.lightColor,
  },
});
