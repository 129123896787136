import { isQuoteIndirect } from 'features-apollo/quote/selectors/quote';
import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import { defaultMarket } from 'features/proposal/supported-markets';
import { AgreementType, Market, Quote, UserGroup } from 'generated/graphql';
import React from 'react';
import { oc } from 'ts-optchain';

import { useQuery } from '@apollo/react-hooks';

import { GET_QUOTE_SALES_INFO } from './queries';
import { Sales, SalesProps } from './Sales';

interface SalesContainerProps {
  quoteId: string;
}

/**
 * Gets data needed for Sales Property Sheet
 *
 * @param props required information to get data
 * @returns  Sales component
 */
export const SalesContainer: React.FC<SalesContainerProps> = props => {
  const { data, loading, error } = useQuery<QueryGetQuoteData>(GET_QUOTE_SALES_INFO, {
    variables: { id: props.quoteId },
    skip: !props.quoteId.trim(),
  });

  const salesProps: SalesProps = {
    quoteId: props.quoteId,
    etag: '',
    market: defaultMarket as Market,
    billingCurrency: DEFAULT_CURRENCY,
    isQuoteLegacy: false,
    isQuoteReadOnly: false,
    crmId: undefined,
    crmLead: undefined,
    soldTo: undefined,
    salesAccountCountry: '',
    organizationId: undefined,
    assignedTo: UserGroup.Field,
    enrollmentNumber: undefined,
  };

  if (data && data.getQuote) {
    const quote: Quote = data.getQuote;
    salesProps.etag = quote.etag;
    salesProps.market = quote.market as Market;
    salesProps.billingCurrency = quote.billingCurrency;
    salesProps.isQuoteReadOnly = quote.readOnly;
    salesProps.crmId = oc(quote).crmLead.id();
    salesProps.crmLead = oc(quote).crmLead() || undefined;
    salesProps.isQuoteLegacy = quote.agreementType === AgreementType.Legacy;
    salesProps.soldTo = oc(quote).soldTo();
    salesProps.salesAccountCountry = oc(quote).crmLead.salesAccount.address.country();
    salesProps.organizationId = oc(quote).soldTo.organization.id();
    salesProps.transactionModel = quote.transactionModel;
    salesProps.assignedTo = oc(quote).assignedTo();
    const enrollmentNumber = oc(quote).vlAgreementNumber();
    salesProps.enrollmentNumber = enrollmentNumber === null ? undefined : enrollmentNumber;
    salesProps.isQuoteIndirect = isQuoteIndirect(quote);
  }

  return <Sales {...salesProps} error={error} loading={loading} />;
};
