import { ShimmerForBackgroundStandout } from 'components/ions/Progress/Shimmer';
import { TextWatermarkSmall } from 'components/ions/Text';
import { isExtendedPriceAdjustment } from 'features/proposal/selectors';
import { KeyCodes } from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ProductFamily } from 'services/catalog/types';
import { Proposal } from 'services/proposal/types';

import { ProposalListColumnProps } from '../ProposalListColumn';
import { proposalListRowStyles } from './ProposalListRow.styles';

export interface SelectionProps {
  isLoading?: boolean;
  isSelected?: boolean;
  isTermsList: boolean;
  isInWarnState?: boolean;
  isInErrorState?: boolean;
  errorText?: string;
  onSelected: (obj: { multi: boolean; ids: string[]; lastSelectedId?: string }) => void;
  onCleared: () => void;
  onDeleted: () => void;
  proposal?: Proposal;
  selectedIds?: string[];
  lastSelectedId?: string;
}
export type ProposalListRowProps = {
  columns: ProposalListColumnProps[];
  itemId: string;
  dataAutomationId?: string;
} & SelectionProps;

type Props = ProposalListRowProps & WithStyles<typeof proposalListRowStyles>;

/**
 * Accepts a mouse click of keyboard space/enter key event with/without modifiers
 * and changes selected lineItems
 */
function selectionEventHandler(
  event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
  lastSelectedId: string | undefined,
  currentSelectedId: string,
  onSelected: (obj: { multi: boolean; ids: string[]; lastSelectedId?: string | undefined }) => void,
  onCleared: () => void,
  allLineItemIds: string[] | undefined
) {
  if (event.ctrlKey) {
    onSelected({ multi: true, ids: [currentSelectedId] });
    return;
  } else if (event.shiftKey && lastSelectedId) {
    let from = allLineItemIds && allLineItemIds.indexOf(lastSelectedId);
    let to = allLineItemIds && allLineItemIds.indexOf(currentSelectedId);
    let selectedRangeOfIds = [];
    if (allLineItemIds && from !== undefined && to !== undefined) {
      if (from < to) {
        while (from !== to + 1) {
          selectedRangeOfIds.push(allLineItemIds[from]);
          from++;
        }
      } else if (from > to) {
        while (from !== to - 1) {
          selectedRangeOfIds.push(allLineItemIds[from]);
          from--;
        }
      } else {
        onCleared();
        selectedRangeOfIds.push(currentSelectedId);
      }
    }
    selectedRangeOfIds.length && onSelected({ multi: true, ids: selectedRangeOfIds });
    return;
  }

  onSelected({ multi: false, ids: [currentSelectedId] });
  return;
}

export const ProposalListRowUnStyled: React.FC<Props> = props => {
  const {
    classes,
    itemId,
    proposal,
    onCleared,
    selectedIds,
    lastSelectedId,
    isTermsList,
    onSelected,
  } = props;

  let selected = selectedIds || [];
  const proposalListRow = React.useRef<HTMLDivElement>(null);

  const lineItemClass = props.isSelected
    ? classes.lineItemSelected
    : props.isInErrorState
    ? classes.lineItemError
    : props.isInWarnState
    ? classes.lineItemWarning
    : classes.lineItemNotSelected;

  const getAllLineItemIds = (): string[] => {
    let lineItemIds: string[] = [];
    let productLineItem: string[] = [];
    let sharedDiscountLineItem: string[] = [];

    proposal &&
      proposal.lineItems.forEach(lineItem => {
        if (
          isTermsList &&
          lineItem.productIdentifier.productFamily === ProductFamily.NegotiatedTerms &&
          lineItem.id
        ) {
          lineItemIds.push(lineItem.id);
        } else if (
          !isTermsList &&
          lineItem.productIdentifier.productFamily !== ProductFamily.NegotiatedTerms &&
          lineItem.id
        ) {
          if (isExtendedPriceAdjustment(lineItem)) {
            sharedDiscountLineItem.push(lineItem.id);
          } else {
            productLineItem.push(lineItem.id);
          }
        }
      });

    if (productLineItem.length) {
      lineItemIds = lineItemIds.concat(productLineItem);
    }
    if (sharedDiscountLineItem.length) {
      lineItemIds = lineItemIds.concat(sharedDiscountLineItem);
    }
    return lineItemIds;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    let targetElement = event.target as HTMLElement;
    let selectFrom = selected.includes(targetElement.id) ? lastSelectedId : targetElement.id;
    const keyCode = event.keyCode;

    switch (keyCode) {
      // UpKey or DownKey: Vertical Keyboard Navigation
      case KeyCodes.up:
      case KeyCodes.down: {
        event.preventDefault();
        proposalListRow.current && proposalListRow.current.focus();
        targetElement = proposalListRow.current as HTMLElement;
        if (keyCode === KeyCodes.up && targetElement.previousElementSibling) {
          targetElement = targetElement.previousElementSibling as HTMLElement;
        } else if (keyCode === KeyCodes.down && targetElement.nextElementSibling) {
          targetElement = targetElement.nextElementSibling as HTMLElement;
        }
        if (event.shiftKey) {
          selectionEventHandler(
            event,
            selectFrom,
            targetElement.id,
            onSelected,
            onCleared,
            getAllLineItemIds()
          );
        }
        if (targetElement) targetElement.focus();
        return;
      }
      // CtrlKey + 'a': Select all
      case KeyCodes.a: {
        event.preventDefault();
        if (event.ctrlKey) {
          const allLineItemIds: string[] | undefined = getAllLineItemIds();
          allLineItemIds && onSelected({ multi: true, ids: allLineItemIds });
        }
        return;
      }
      // EscKey: Clear all selections all
      case KeyCodes.escape: {
        onCleared();
        return;
      }
      case KeyCodes.space:
      case KeyCodes.enter: {
        if (!targetElement.classList.contains('ms-Link')) {
          event.preventDefault();
          selectionEventHandler(
            event,
            lastSelectedId,
            itemId,
            onSelected,
            onCleared,
            getAllLineItemIds()
          );
        } else {
          onCleared();
          selectionEventHandler(
            event,
            lastSelectedId,
            itemId,
            onSelected,
            onCleared,
            getAllLineItemIds()
          );
        }
        return;
      }
      // DeleteKey: Deletes Selected
      case KeyCodes.del: {
        if (proposal && selected.length) {
          props.onDeleted();
        }
        return;
      }
    }
  };

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = event.currentTarget as HTMLElement;
    if (event.shiftKey) event.preventDefault();
    selectionEventHandler(
      event,
      lastSelectedId,
      itemId,
      onSelected,
      onCleared,
      getAllLineItemIds()
    );
    targetElement && targetElement.focus();
    return;
  };

  return (
    <div
      className={classes.lineItem}
      data-automation-id={props.dataAutomationId}
      id={props.itemId}
      key={props.itemId}
      ref={proposalListRow}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onMouseDown={handleOnClick}
    >
      <div className={`${classes.lineItemContainer} ${lineItemClass}`}>
        {props.isLoading ? (
          <div className={`${classes.loading}`}>
            <ShimmerForBackgroundStandout width="30%" />
            <ShimmerForBackgroundStandout width="20%" />
          </div>
        ) : (
          <>
            {props.children}
            <div className={classes.lineItemErrorMessage}>
              <TextWatermarkSmall addClass={classes.errorText}>
                {props.errorText}
              </TextWatermarkSmall>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const ProposalListRow = withStyles(proposalListRowStyles)(
  ProposalListRowUnStyled
) as React.FC<ProposalListRowProps>;
