import gql from 'graphql-tag';

export const GET_QUOTE_GENERAL_INFO = gql`
  query getQuote($id: String!) {
    getQuote(id: $id) {
      id
      etag
      name
      readOnly
      billingCurrency
      market
      languages
      agreementType
      expirationDate
      transactionModel
      clouds
      audience
      status
      lineItems {
        id
        catalogContext {
          nationalCloud
        }
      }
      msContact {
        mail
        displayName
        userPrincipalName
      }
      crmLead {
        id
      }
    }
  }
`;

export const UPDATE_CLOUD_FOR_GENERAL = gql`
  mutation UpdateCloudType($input: QuoteMutationInput!, $cloudType: NationalCloud!) {
    updateCloudType(input: $input, cloudType: $cloudType) {
      id
      etag
      name
      readOnly
      billingCurrency
      market
      languages
      expirationDate
      transactionModel
      clouds
      audience
      status
      lineItems {
        id
      }
      msContact {
        mail
        displayName
        userPrincipalName
      }
      crmLead {
        id
      }
    }
  }
`;

export const UPDATE_QUOTE_NAME = gql`
  mutation updateQuoteName($input: QuoteMutationInput!, $name: String!) {
    updateQuoteName(input: $input, name: $name) {
      name
    }
  }
`;

export const UPDATE_MARKET = gql`
  mutation updateMarket($input: QuoteMutationInput!, $market: String!) {
    updateMarket(input: $input, market: $market) {
      market
    }
  }
`;

export const UPDATE_CURRENCY = gql`
  mutation updateCurrency($input: QuoteMutationInput!, $currency: String!) {
    updateCurrency(input: $input, currency: $currency) {
      billingCurrency
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($input: QuoteMutationInput!, $language: String!) {
    updateLanguage(input: $input, language: $language) {
      languages
    }
  }
`;

export const UPDATE_EXPIRATION_DATE = gql`
  mutation updateExpiration($input: QuoteMutationInput!, $date: String!) {
    updateExpiration(input: $input, date: $date) {
      expirationDate
    }
  }
`;

export const UPDATE_MS_CONTACT = gql`
  mutation updateMsContact($input: QuoteMutationInput!, $msContact: String!) {
    updateMsContact(input: $input, msContact: $msContact) {
      msContact {
        mail
        displayName
        userPrincipalName
      }
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUser($text: String!) {
    searchUser(text: $text) {
      mail
      displayName
      userPrincipalName
    }
  }
`;
