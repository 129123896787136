export const contributorRoleId = '50000000-aaaa-bbbb-cccc-100000000001';
export const ownerRoleId = '50000000-aaaa-bbbb-cccc-100000000000';

export const ownerRoleIds = [ownerRoleId, contributorRoleId];

export enum LoadingErrorType {
  None,
  ErrorLoadingOwners,
  ErrorLoadingAdmins,
  ErrorLoadingEverything,
}

export enum OwnerType {
  Owner = 'Owner',
  GlobalAdmin = 'Global Admin',
  Contributor = 'Contributor',
}

export interface ExistingOwner {
  upn: string;
  roles: OwnerType[];
}
