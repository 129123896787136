import { ConditionItem, LinkExternal, NotificationItem, NotificationType } from 'components';
import { LabelListItem } from 'components/ions/CuratedText/LabelList/LabelList';
import {
  DetailsFlights,
  DiscountLineItemProps,
  PurchaseLineItemDetails,
  QuoteDetailsProps,
} from 'features-apollo/quote/components/DetailsPane';
import {
  getDiscountMeterOptions,
  isDiscountableLineItem,
  isoFormatDate,
} from 'features-apollo/quote/components/DiscountCard/utils';
import {
  calculateECIFEndDate,
  getTermRowEndDate,
} from 'features-apollo/quote/components/Lists/TermList/utils';
import { QuoteLineItem, QuoteTermLineItem } from 'features-apollo/quote/components/types';
import {
  formatCurrency,
  getValueOrUndefined,
  isTermLineItem,
  localFormatDateLong,
  localFormatPercentageValue,
  MESSAGE_TYPE_MAP,
  notificationMessages,
} from 'features-apollo/quote/components/utils';
import { getCreditLineStatus } from 'features-apollo/quote/selectors/organization';
import { CatalogItemType } from 'features-apollo/quote/types';
import {
  filteredSkusRawToFilteredSkus,
  getStartConditionForDisplay,
} from 'features-apollo/quote/utils';
import { filterAndCastMaybeArray } from 'features-apollo/utils';
import { AddOn } from 'features/proposal/components/AddOns';
import {
  getLocationInfoForDetailsPane,
  getTermStringForDetailsPane,
} from 'features/proposal/components/DetailsPane/detailsUtils';
import { CreditLineReason } from 'features/proposal/components/Dialogs';
import {
  Address,
  AgreementType,
  CatalogAction,
  Condition,
  DiscountLineItem,
  DiscountSimple,
  DiscountType,
  EcifConfigLineItem,
  EcifLineItem,
  Message,
  MessageSource,
  MonetaryLineItem,
  Product,
  ProductAudience,
  PurchaseLineItem,
  Quote,
  SapLineItem,
  TermLineItem,
  TransactionModel,
  UserGroup,
} from 'generated/graphql';
import i18next from 'i18next';
import * as React from 'react';
import sanitizeHtml from 'sanitize-html';
import { getUnique } from 'services/utils';
import { oc } from 'ts-optchain';

import {
  filterSkus,
  readDurationsWithMultipleSkus,
} from '../ConfigCards/Cards/MetersConfigurationCard/MetersConfigurationCard';
import { PLACEHOLDER_UNDEFINED } from '../Lists/Rows/utils';

export type TermOrMonetaryLineItem = QuoteTermLineItem | MonetaryLineItem;
/**
 *  Product types that will show a term ID in details pane
 */

export const TERMID_PRODUCT_TYPES = ['ecif', 'custom', 'financing'];
export const isTermIdType = (productType: string) =>
  TERMID_PRODUCT_TYPES.includes(productType.toLowerCase());

export const isMonetaryType = (lineItem: QuoteLineItem) =>
  lineItem.__typename && lineItem.__typename === 'MonetaryLineItem';

export const isSapType = (lineItem: QuoteLineItem) => lineItem.__typename === 'SapLineItem';

export const isProductType = (product: Product, productType: string) =>
  product.productType.toLowerCase() === productType.toLowerCase();

export const isPartnerCustomerQuote = (quote: Quote) => {
  const isHRDDQuote =
    quote.assignedTo === UserGroup.Partner &&
    oc(quote).productAudience() === ProductAudience.PartnerCommercial;

  return quote.transactionModel === TransactionModel.ToPartnerCustomerAsset || isHRDDQuote;
};

export const getMiniumCreditLine = (dealEsimate: number, minCreditLineDivisor: number) =>
  Math.ceil(Number(dealEsimate) / minCreditLineDivisor).toString();

export const getApprovalDaysElapsedText = (days: number, t: i18next.TFunction): string => {
  return t(`quote::{{count}} day elapsed`, {
    count: days,
    // eslint-disable-next-line @typescript-eslint/camelcase
    defaultValue_plural: `{{count}} days elapsed`,
  });
};

export const getMessageOfType = (messages: Message[], type: MessageSource) =>
  oc(messages)([]).find(msg => oc(msg).messageSource() === type);

export const hasMessageOfType = (messages: Message[], type: MessageSource) =>
  oc(messages)([]).find(msg => oc(msg).messageSource() === type) ? true : false;

export const parseMessage = (message: Message) => {
  return {
    title:
      message.messageTitle && message.messageTitle !== null
        ? oc(message).messageTitle.display('')
        : '',
    description: oc(message).messageDisplay.display(''),
    type: MESSAGE_TYPE_MAP[message.messageType],
    source: getValueOrUndefined(message.messageSource),
  };
};

export const getQuoteLevelNotifications = (quote: Quote) => {
  let results: NotificationItem[] = [];
  const messages = quote.messages;
  if (messages && messages.length) {
    results = [...messages.map(message => parseMessage(message))];
  }

  let brokenProductCount = 0;
  let brokenTermCount = 0;
  let configurationRequired = 0;
  let maxPurchases = false;
  let maxDiscounts = false;
  const labels = {
    product: i18next.t('quote::product'),
    products: i18next.t('quote::products'),
    term: i18next.t('quote::term'),
    terms: i18next.t('quote::terms'),
  };
  quote.lineItems.forEach((item: QuoteLineItem) => {
    if (hasMessageOfType(item.messages, MessageSource.LineitemBroken)) {
      if (isTermLineItem(item)) {
        brokenTermCount++;
      } else {
        brokenProductCount++;
      }
    }

    if (hasMessageOfType(item.messages, MessageSource.LineitemMissingConfiguration)) {
      configurationRequired++;
    }

    if (!maxPurchases) {
      const maxPurchaseMessge = getMessageOfType(item.messages, MessageSource.MaximumPurchaseItems);
      if (maxPurchaseMessge) {
        maxPurchases = true;
        results.push(parseMessage(maxPurchaseMessge));
      }
    } else if (!maxDiscounts) {
      const maxDiscountMessage = getMessageOfType(
        item.messages,
        MessageSource.MaximumDiscountItems
      );
      if (maxDiscountMessage) {
        maxDiscounts = true;
        results.push(parseMessage(maxDiscountMessage));
      }
    }
  });
  quote.agreements &&
    quote.agreements.edges.forEach(agreement => {
      if (hasMessageOfType(agreement.node.messages, MessageSource.LineitemMissingConfiguration)) {
        configurationRequired++;
      }
    });
  if (configurationRequired > 0) {
    results.push({
      type: NotificationType.standard,
      source: MessageSource.LineitemBroken,
      title: i18next.t('quote::Configuration required'),
      description: notificationMessages.configurationOrDiscountRequiredMessage(
        configurationRequired.toString()
      ),
    });
  }

  if (brokenProductCount > 0 && brokenTermCount > 0) {
    results.push({
      type: NotificationType.standard,
      source: MessageSource.LineitemBroken,
      title: i18next.t('quote::Broken line items'),
      description: notificationMessages.brokenLineItemsMix(
        brokenProductCount.toString(),
        brokenProductCount > 1 ? labels.products : labels.product,
        brokenTermCount.toString(),
        brokenTermCount > 1 ? labels.terms : labels.term
      ),
    });
  } else if (brokenProductCount > 0) {
    results.push({
      type: NotificationType.standard,
      source: MessageSource.LineitemBroken,
      title: i18next.t('quote::Broken products'),
      description:
        brokenProductCount > 1
          ? notificationMessages.brokenLineItemMultiple(
              brokenProductCount.toString(),
              labels.products
            )
          : notificationMessages.brokenLineItemSingle(
              brokenProductCount.toString(),
              labels.product
            ),
    });
  } else if (brokenTermCount > 0) {
    results.push({
      type: NotificationType.standard,
      source: MessageSource.LineitemBroken,
      title: i18next.t('quote::Broken terms'),
      description:
        brokenTermCount > 1
          ? notificationMessages.brokenLineItemMultiple(brokenTermCount.toString(), labels.terms)
          : notificationMessages.brokenLineItemSingle(brokenTermCount.toString(), labels.term),
    });
  }

  return results;
};

export const getLineItemNotifications = (quote: Quote, lineItemId: string) => {
  const lineItems = oc(quote).lineItems([]);
  const foundItem = lineItems.find(item => item.id === lineItemId);
  if (foundItem && foundItem.messages && foundItem.messages.length) {
    return foundItem.messages.map(message => parseMessage(message));
  }
  return [];
};

export const parseLineItemNotifications = (lineItem: QuoteLineItem) =>
  oc(lineItem)
    .messages([])
    .map((message: Message) => parseMessage(message));

export const getCreditConditions = (quote: Quote, creditDivisor: number) => {
  const conditions: ConditionItem[] = [];
  const creditCondition: ConditionItem = {
    name: i18next.t('quote::Credit line'),
    value: PLACEHOLDER_UNDEFINED,
    status: '',
    dataAutomationId: 'creditLine',
  };
  let requiredCreditLine;

  const organization = oc(quote).soldTo.organization();
  const credit = oc(organization).credit();
  const language = oc(organization).language();
  const currency = (credit && credit.currency) || '';

  const annualDealEstimateValue =
    quote.soldTo && quote.soldTo.dealEstimate
      ? `${formatCurrency(quote.soldTo.dealEstimate, 0, language)} ${currency}`
      : PLACEHOLDER_UNDEFINED;
  const creditLineValue =
    credit && credit.creditLine
      ? `${formatCurrency(Math.floor(parseInt(credit.creditLine)), 0, language)} ${currency}`
      : PLACEHOLDER_UNDEFINED;

  if (credit) {
    const minCredit =
      quote.soldTo &&
      quote.soldTo.dealEstimate &&
      formatCurrency(
        getMiniumCreditLine(quote.soldTo.dealEstimate, creditDivisor),
        0,
        oc(credit).currency()
      );
    switch (getCreditLineStatus(credit, quote.soldTo.monthlyCreditLimit)) {
      case CreditLineReason.SafeList:
        creditCondition.value = i18next.t('quote::Safe list');
        creditCondition.status = 'safe';
        break;
      case CreditLineReason.PendingReview:
        creditCondition.value = i18next.t('quote::Pending');
        creditCondition.status = 'danger';
        break;
      case CreditLineReason.Rejected:
        creditCondition.value = i18next.t('quote::Rejected');
        creditCondition.status = 'danger';
        break;
      case CreditLineReason.UnderReview:
        creditCondition.value = i18next.t('quote::Under review');
        creditCondition.status = 'danger';
        break;
      case CreditLineReason.ReviewCancelled:
        creditCondition.value = i18next.t('quote::Review cancelled');
        creditCondition.status = 'danger';
        break;
      case CreditLineReason.CreditIncrease:
        creditCondition.value = creditLineValue;
        creditCondition.status = 'danger';
        requiredCreditLine = {
          name: i18next.t('quote::Required credit line'),
          value: minCredit || PLACEHOLDER_UNDEFINED,
          status: '',
          dataAutomationId: 'required-creditLine',
        };
        break;
      case CreditLineReason.CreditLine:
        creditCondition.value = creditLineValue;
        break;
    }
  }
  conditions.push(creditCondition);
  if (requiredCreditLine) {
    conditions.push(requiredCreditLine);
  }
  conditions.push({
    name: i18next.t('quote::Annual deal estimate'),
    value: annualDealEstimateValue,
    status: '',
    dataAutomationId: 'annualDealEstimate',
  });

  return conditions;
};

export const formatTermDuration = (duration: string) => {
  const numbers = duration.match(/(\d+)/);
  if (numbers && numbers.length) {
    const isYears = duration.toLocaleLowerCase().includes('y');
    return `${numbers[0]} ${isYears ? i18next.t('quote::Year(s)') : i18next.t('quote::Month(s)')}`;
  }

  return duration.includes('placement') ? duration : PLACEHOLDER_UNDEFINED;
};

const addTermId = (lineItem: TermOrMonetaryLineItem, conditions: LabelListItem[]) => {
  if (lineItem.product && isTermIdType(lineItem.product.productType)) {
    let termid = getValueOrUndefined(lineItem.term && lineItem.term.termId);

    if (!termid && !isMonetaryType(lineItem)) {
      const refrenceData = getValueOrUndefined(
        (lineItem as TermLineItem).supplementalTermReferenceData
      );
      termid =
        oc(refrenceData)
          .key('')
          .toLowerCase() === 'termid'
          ? oc(refrenceData).value(PLACEHOLDER_UNDEFINED)
          : PLACEHOLDER_UNDEFINED;
    }
    conditions.push({
      name: i18next.t('quote::TermId'),
      value: termid ? termid : PLACEHOLDER_UNDEFINED,
      dataAutomationId: 'TermId-Details',
    });
  }
};

const addAmount = (
  lineItem: TermOrMonetaryLineItem,
  currency: string,
  conditions: LabelListItem[]
) => {
  if (lineItem.product && (isMonetaryType(lineItem) || isSapType(lineItem))) {
    const amount = getValueOrUndefined((lineItem as MonetaryLineItem).purchaseTermUnits);
    conditions.push({
      name: i18next.t('quote::Amount'),
      value: amount ? `${amount} ${currency}` : PLACEHOLDER_UNDEFINED,
      dataAutomationId: 'Amount-Details',
    });
  }
};

const addAdminName = (lineItem: TermOrMonetaryLineItem, conditions: LabelListItem[]) => {
  if (lineItem.product && isSapType(lineItem)) {
    const firstName = getValueOrUndefined((lineItem as SapLineItem).firstName);
    const lastName = getValueOrUndefined((lineItem as SapLineItem).lastName);
    conditions.push({
      name: i18next.t('quote::Admin name'),
      value: firstName && lastName ? `${firstName} ${lastName}` : PLACEHOLDER_UNDEFINED,
      dataAutomationId: 'Sap-Name-Details',
    });
  }
};

const addAdminEmail = (lineItem: TermOrMonetaryLineItem, conditions: LabelListItem[]) => {
  if (lineItem.product && isSapType(lineItem)) {
    const email = getValueOrUndefined((lineItem as SapLineItem).email);
    conditions.push({
      name: i18next.t('quote::Admin email'),
      value: email ? email : PLACEHOLDER_UNDEFINED,
      dataAutomationId: 'Sap-Email-Details',
    });
  }
};

const addOpportunityId = (lineItem: TermOrMonetaryLineItem, conditions: LabelListItem[]) => {
  if (lineItem.product && isSapType(lineItem)) {
    const opptyId = getValueOrUndefined((lineItem as SapLineItem).opportunityId);
    conditions.push({
      name: i18next.t('quote::SAP opportunity ID'),
      value: opptyId ? opptyId : PLACEHOLDER_UNDEFINED,
      dataAutomationId: 'Sap-Opportunity-Details',
    });
  }
};

const addVersion = (lineItem: TermOrMonetaryLineItem, conditions: LabelListItem[]) => {
  const productType =
    lineItem.product && getValueOrUndefined(lineItem.product.productType.toLowerCase());
  if (
    !(isTermIdType(productType) || isMonetaryType(lineItem) || isSapType(lineItem)) &&
    lineItem.isConfigurable
  ) {
    conditions.push({
      name: i18next.t('quote::Version'),
      value:
        lineItem.configurationSummary && lineItem.configurationSummary !== null
          ? lineItem.configurationSummary.display
          : PLACEHOLDER_UNDEFINED,
      dataAutomationId: 'Version-Details',
    });
  }
};

//TODO: this logic could use some clean up when time permits
const addDateOrDuration = (
  lineItem: TermOrMonetaryLineItem,
  conditions: LabelListItem[],
  isMonetaryProduct: boolean
) => {
  const startDate = getStartConditionForDisplay({
    startCondition: oc(lineItem).product.startCondition(),
    catalogItemType: isMonetaryProduct ? CatalogItemType.Product : CatalogItemType.Term,
  });
  const endDate = getTermRowEndDate(lineItem.duration, oc(lineItem).product.durations());

  conditions.push({
    name: i18next.t('quote::Start'),
    value: startDate ? startDate : PLACEHOLDER_UNDEFINED,
    dataAutomationId: 'TermStart-Details',
  });
  conditions.push({
    name: i18next.t('quote::End'),
    value: endDate ? endDate : PLACEHOLDER_UNDEFINED,
    dataAutomationId: 'TermEnd-Details',
  });
};

// TODO: Add sap conditions for display on details pane
// export const addSapConditions = (lineItem: TermOrMonetaryLineItem, conditions: LabelListItem[]) =>{
//   if(lineItem.product && isProductType(lineItem.product,"SCPCommitmentToConsume")){

//   }
// }

export const getTermConditions = (quote: Quote, lineItem: TermOrMonetaryLineItem) => {
  const results: LabelListItem[] = [];
  const product = lineItem.product && getValueOrUndefined(lineItem.product);
  if (!product) {
    return results;
  }

  const isMonetaryProduct = lineItem.__typename === 'MonetaryLineItem' && !lineItem.isTerm;
  addTermId(lineItem, results);
  addAmount(lineItem, quote.billingCurrency, results);
  addVersion(lineItem, results);
  addDateOrDuration(lineItem, results, isMonetaryProduct);
  addAdminName(lineItem, results);
  addAdminEmail(lineItem, results);
  addOpportunityId(lineItem, results);
  //todo: add sap conditions
  return results;
};

export const getAddressProps = (address: Address) => {
  const country = oc(address).marketInfo.display() || address.country;

  return {
    address: {
      companyName: getValueOrUndefined(address.companyName),
      addressLine1: getValueOrUndefined(address.addressLine1),
      addressLine2: getValueOrUndefined(address.addressLine2),
      addressLine3: getValueOrUndefined(address.addressLine3),
      city: getValueOrUndefined(address.city),
      region: getValueOrUndefined(address.region),
      country: getValueOrUndefined(country),
      postalCode: getValueOrUndefined(address.postalCode),
      phoneNumber: getValueOrUndefined(address.phoneNumber),
      email: getValueOrUndefined(address.email),
    },
  };
};

export const getSelectedLineItems = (quote: Quote, lineItemIds: string[]) => {
  const results: QuoteLineItem[] = [];
  const lineItems = oc(quote).lineItems([]);
  if (lineItems.length) {
    lineItemIds.forEach(id => {
      const found = lineItems.find(item => item.id === id);
      if (found) {
        results.push(found);
      }
    });
  }
  return results;
};

export const getDiscountLabels = () => {
  return {
    existingDiscount: i18next.t('quote::Existing discounts:'),
    dates: i18next.t('quote::Dates:'),
    lock: i18next.t('quote::Lock:'),
    discount: i18next.t('quote::Discount:'),
    duration: i18next.t('quote::Duration:'),
    meter: i18next.t('quote::Meter:'),
    oneTimeDiscount: i18next.t('quote::One time discount:'),
  };
};

export const getProductImage = (lineItem: QuoteLineItem | MonetaryLineItem) => {
  const images = getValueOrUndefined(oc(lineItem).product.images());
  if (images) {
    const small = images.small && images.small.length ? images.small[0] : undefined;
    const medium = images.medium && images.medium.length ? images.medium[0] : undefined;
    const logo = images.logo && images.logo.length ? images.logo[0] : undefined;
    return medium || small || logo || undefined;
  }
  return undefined;
};

export const getPurchaseLineItemConfigurations = (lineItem: QuoteLineItem | MonetaryLineItem) => {
  const configurationItems: LabelListItem[] = [];

  //TODO: Currently cannot retrieve configuration details need to uncomment and complete this when gql is updated.
  // if (lineItem.__typename && lineItem.__typename === 'PurchaseLineItem') {
  //   const skuTitle = getValueOrUndefined(oc(lineItem).sku.title());
  //   const location = undefined;
  //   const duration = undefined;
  //   if (skuTitle) {
  //     configurationItems.push({
  //       name: i18next.t('quote::SKU'),
  //       value: skuTitle,
  //       dataAutomationId: 'sku',
  //     });
  //   } else {
  //     if (location) {
  //       configurationItems.push({
  //         name: i18next.t('quote::Location'),
  //         value: location,
  //         dataAutomationId: 'location',
  //       });
  //     }
  //     if (duration) {
  //       configurationItems.push({
  //         name: i18next.t('quote::Duration'),
  //         dataAutomationId: 'duration',
  //         value: duration,
  //       });
  //     }
  //   }
  //TODO: Uncomment when serviceFamily property is added to line item product.
  // if(isSaaSProduct(lineItem)){
  //   configurationItems.push({
  //     name: i18next.t('quote::Publisher'),
  //     value: lineItem.product && lineItem.product.publisherName && lineItem.product.publisherName !== null ? lineItem.product.publisherName : "-"
  //   });
  // }
  // }

  return configurationItems;
};

export const getProductAddons = (lineItem: QuoteLineItem | MonetaryLineItem) => {
  const addons: AddOn[] = [];
  //TODO: When Gql is updated with DisplaySku Availabilities return any pre requisite skus from DSA here
  return addons;
};

const getPublisherLink = (lineItem: QuoteLineItem) => {
  const product = getValueOrUndefined(oc(lineItem).product());
  const publisherUrl = getValueOrUndefined(product && product.publisherUrl);
  const publisherName =
    getValueOrUndefined(product && product.publisherName) || i18next.t('quote::Publisher');
  return product && publisherUrl ? (
    <LinkExternal displayText={publisherName} href={publisherUrl} id={`${publisherName}-website`} />
  ) : (
    undefined
  );
};

export const getEcifLineItemProps = (
  lineItem: EcifLineItem,
  flights: DetailsFlights,
  quote: Quote
): PurchaseLineItemDetails => {
  let ecifData;
  if (lineItem.__typename === 'EcifLineItem') {
    const oneAskLink = oc(lineItem).ecifConfig.caseId()
      ? `https://oneask.microsoft.com/CAS/strategic/${oc(lineItem).ecifConfig.caseId()}`
      : '';
    const finalAmount = oc(lineItem)
      .ecifConfig.lineItems([])
      .map((lineItem: EcifConfigLineItem) => Number(lineItem.amount))
      .reduce((a: number, b: number) => a + b, 0);
    ecifData = {
      lineItem,
      currency: quote.billingCurrency,
      oneAskCaseNumber: oc(lineItem).ecifConfig.caseNumber(''),
      oneAskLink,
      totalAmountApproved: oc(lineItem)
        .ecifConfig.totalApprovedAmount(0)
        .toString(),
      finalAmount: finalAmount ? finalAmount.toString() : '',
      types: oc(lineItem)
        .ecifConfig.lineItems([])
        .map((lineItem: EcifConfigLineItem) => lineItem.type),
      productFamilies: oc(lineItem)
        .ecifConfig.lineItems([])
        .map((lineItem: EcifConfigLineItem) => lineItem.productFamily),
      endDate: calculateECIFEndDate(lineItem),
      services: oc(lineItem)
        .ecifConfig.lineItems([])
        .map((lineItem: EcifConfigLineItem) => {
          return {
            shortName: oc(lineItem).sku.shortDescription(''),
            amount: lineItem.amount,
          };
        }),
      notifications: parseLineItemNotifications(lineItem),
      quote,
      flights,
    };
  }

  return {
    description: sanitizeHtml(oc(lineItem).product.description('')),
    strippedDescription: oc(lineItem).product.plainTextDescription(''),
    productName: oc(lineItem).product.title(''),
    configurations: [],
    notifications: parseLineItemNotifications(lineItem),
    imageUri: getProductImage(lineItem),
    publisherLink: getPublisherLink(lineItem),
    discountLabels: getDiscountLabels(),
    ecifData,
  };
};

export const getPurchaseLineItemProps = (
  lineItem: QuoteLineItem,
  flights: DetailsFlights
): PurchaseLineItemDetails => {
  return {
    description: sanitizeHtml(oc(lineItem).product.description('')),
    strippedDescription: oc(lineItem).product.plainTextDescription(''),
    productName: oc(lineItem).product.title(''),
    configurations: [],
    notifications: parseLineItemNotifications(lineItem),
    imageUri: getProductImage(lineItem),
    publisherLink: getPublisherLink(lineItem),
    discountLabels: getDiscountLabels(),
  };
};

export const getQuoteDetailsProps = (quote: Quote, minCreditDivisor: number): QuoteDetailsProps => {
  const partnerCustomerQuote = isPartnerCustomerQuote(quote);
  const isLegacyQuote = oc(quote).agreementType() === AgreementType.Legacy;
  return {
    id: 'QuoteDetailsPane',
    conditions:
      (!partnerCustomerQuote && !isLegacyQuote && getCreditConditions(quote, minCreditDivisor)) ||
      undefined,
    notifications: getQuoteLevelNotifications(quote),
    soldToOrganization: quote.soldTo.organization || undefined,
    endCustomerOrganization: quote.endCustomer || undefined,
    transactionModel: partnerCustomerQuote
      ? TransactionModel.ToPartnerCustomerAsset
      : TransactionModel.ToCustomer,
  };
};

export const getProductSku = (lineItem: QuoteLineItem | MonetaryLineItem) =>
  lineItem.sku ? lineItem.sku.title : undefined;

export interface DiscountDetailsDates {
  startDate?: string;
  endDate?: string;
  duration?: string;
  priceGuaranteeDate?: string;
}

export const getDiscountDatesOrDuration = (
  discount?: DiscountSimple
): DiscountDetailsDates | undefined => {
  if (!discount) {
    return undefined;
  }
  let startDate, endDate, duration;

  if (
    discount.duration &&
    discount.duration !== null &&
    discount.duration.__typename === 'Duration'
  ) {
    duration =
      discount.duration.duration && discount.duration.duration !== null
        ? formatTermDuration(discount.duration.duration)
        : undefined;
  } else if (discount.duration && discount.duration.__typename === 'StartEndDates') {
    startDate = getValueOrUndefined(discount.duration.startDate);
    endDate = getValueOrUndefined(discount.duration.endDate);
  }

  return {
    startDate: startDate && localFormatDateLong(new Date(startDate)),
    endDate: endDate && localFormatDateLong(new Date(endDate)),
    duration,
    priceGuaranteeDate:
      discount.priceGuaranteeDate && isoFormatDate(new Date(discount.priceGuaranteeDate)),
  };
};

const getDiscountMeters = (lineItem: DiscountLineItem) => {
  const meters: string[] = [];
  const meterOptions = getDiscountMeterOptions(lineItem);

  oc(lineItem)
    .discount.scope([])
    .forEach((scope: Condition) => {
      if (
        oc(scope)
          .name('')
          .toLowerCase() === 'metertype' &&
        oc(scope).values([]).length
      ) {
        scope.values.forEach(value => {
          meters.push(value);
        });
      }
    });

  if (!meters.length && meterOptions.length && meterOptions.find(meter => meter.key === 'all')) {
    meters.push(i18next.t('quote::All'));
  }
  return meters;
};

export const getDiscountLineItemProps = (lineItem: DiscountLineItem): DiscountLineItemProps => {
  const dates = getDiscountDatesOrDuration(getValueOrUndefined(lineItem.discount));
  const discountPercent =
    lineItem.discount && lineItem.discount.percentage != null
      ? localFormatPercentageValue(oc(lineItem).discount.percentage(0), 0)
      : PLACEHOLDER_UNDEFINED;
  const locationConditions = oc(lineItem)
    .discount.scope([])
    .find(condition => condition.name === 'Location');
  let locations: string[] = [];
  if (locationConditions && locationConditions.values.length) {
    locations = filterAndCastMaybeArray(locationConditions.values);
  }

  const action = oc(lineItem).discount.action(CatalogAction.Purchase);
  const conditions = oc(lineItem).discount.scope([]);
  locations = getLocationInfoForDetailsPane(locations, !!lineItem.sku);
  const filteredSkus = !!lineItem.product
    ? filteredSkusRawToFilteredSkus(lineItem.product.filteredSkusRaw)
    : [];
  const skusToDisplay = filterSkus(
    oc(filteredSkus)([]),
    locations,
    action,
    readDurationsWithMultipleSkus(conditions)
  );
  const uniqueSkucount = getUnique(skusToDisplay, sku => sku.skuId).length;

  return {
    productName: oc(lineItem).product.title(''),
    description: oc(lineItem).product.description(''),
    notifications: parseLineItemNotifications(lineItem),
    imageUri: getProductImage(lineItem),
    publisherLink: getPublisherLink(lineItem),
    discountType:
      lineItem.discount && lineItem.discount.type ? lineItem.discount.type : DiscountType.Future,
    skuTitle: getProductSku(lineItem),
    commitment: oc(lineItem).sku() ? undefined : getTermStringForDetailsPane(action, conditions),
    skuCount: uniqueSkucount,
    locations,
    startDate: dates && dates.startDate,
    endDate: dates && dates.endDate,
    duration: dates && dates.duration,
    priceGuaranteeDate: dates && dates.priceGuaranteeDate,
    discountPercent,
    dataAutomationId: 'DiscountLineItem-Details',
    labels: getDiscountLabels(),
    meters: getDiscountMeters(lineItem),
  };
};

export const getTermLineItemProps = (quote: Quote, lineItem: TermOrMonetaryLineItem) => {
  return {
    id: 'TermLineItem-Details',
    description: sanitizeHtml(oc(lineItem).product.description('')),
    strippedDescription: oc(lineItem).product.plainTextDescription(''),
    productName: oc(lineItem).product.title(''),
    conditions: getTermConditions(quote, lineItem),
    notifications: parseLineItemNotifications(lineItem),
    addons: [], //todo update
    imageUri: getProductImage(lineItem),
    publisherLink: getPublisherLink(lineItem), //todo update
  };
};

export const getDiscountDatesForRender = (
  startDate?: string,
  endDate?: string,
  duration?: string
) => {
  const datesToDisplay: string[] = [];
  if (startDate && endDate) {
    datesToDisplay.push(startDate);
    datesToDisplay.push(endDate);
  } else if (duration) {
    datesToDisplay.push(duration);
  }

  return datesToDisplay;
};

export interface MultiSelectDiscountInfo {
  bulkDiscountableLineItems: (PurchaseLineItem | DiscountLineItem)[];
  discountableSummary: string[];
  otherSummary: string[];
}

export const getProductNamesWithCount = (lineItems: QuoteLineItem[]) => {
  const productTitles = lineItems.map(item => {
    return item.product && item.product.title ? item.product.title : 'other';
  });
  const uniqueNames = [...new Set(productTitles)];
  return uniqueNames.map(name => {
    const count = productTitles.filter(title => title === name).length;

    return (name = count > 1 ? `${name} (${count})` : name);
  });
};

export const getMultiSelectLineItemDetails = (
  selectedLineItems: QuoteLineItem[]
): MultiSelectDiscountInfo => {
  const bulkDiscountableLineItems: (PurchaseLineItem | DiscountLineItem)[] = [];
  const otherLineItems: QuoteLineItem[] = [];
  selectedLineItems.forEach(lineItem => {
    if (
      isDiscountableLineItem(lineItem) &&
      lineItem.applicableDiscounts.types.includes(DiscountType.Future)
    ) {
      bulkDiscountableLineItems.push(lineItem);
    } else {
      otherLineItems.push(lineItem);
    }
  });

  return {
    bulkDiscountableLineItems,
    discountableSummary: getProductNamesWithCount(bulkDiscountableLineItems),
    otherSummary: getProductNamesWithCount(otherLineItems),
  };
};
