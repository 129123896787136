import { getConfigByEnvironment } from 'features/app/config';
import { AppEnvironment } from 'features/app/config/type';
import { defaultFlightList } from 'services/flights/config';
import {
  Flight,
  FlightInformation,
  flights as flightInformations,
  isFlight,
  getFlight,
} from 'services/flights/flightList';
import * as LocalStorage from 'services/local-storage-service';

const controlTowerLocalStorageKey = 'localflights';
const flightsKey = 'setswitch';

export const getDefaultFlights = () => ({
  ...defaultFlightList[getConfigByEnvironment().flights.defaultFlightMode],
});

export const isFlightAvailableInEnvironment = (
  flight: Flight,
  env: AppEnvironment,
  flightInfos: Record<Flight, FlightInformation> = flightInformations
) => {
  const disableInEnvs = flightInfos[flight] && flightInfos[flight].disableInEnvs;
  return !(disableInEnvs && disableInEnvs.includes(env));
};

export const getFlightsFromUrl = (env: AppEnvironment): Partial<Record<Flight, boolean>> => {
  const result: Partial<Record<Flight, boolean>> = {};
  const searchParams = new URLSearchParams(window.location.search.toLowerCase());
  const flightsParam = searchParams.get(flightsKey);

  if (flightsParam) {
    const flightsList = flightsParam.split(',');

    flightsList.forEach(flight => {
      const [key, value] = flight.split(':');
      if (key && value && isFlight(key) && isFlightAvailableInEnvironment(key, env)) {
        const flight = getFlight(key);

        if (flight) {
          result[flight] = value === '1';
        }
      }
    });
  }

  return result;
};

export const applyControlTowerFlights = (flights: Record<Flight, boolean>, env: AppEnvironment) => {
  //load last api flights which was saved during the previous page load
  let controlTowerFlights = LocalStorage.get<Flight[]>(controlTowerLocalStorageKey);

  if (controlTowerFlights) {
    controlTowerFlights = controlTowerFlights.filter(flight =>
      isFlightAvailableInEnvironment(flight, env)
    );
    controlTowerFlights.forEach(flight => (flights[flight] = true));
  }
};

// First, we load the default flights.
// Next, we use flights cached from the last control tower api call.
// Last, we apply the flights from the setSwitch url param.
export const getInitialFlights = (withUrlFlights: boolean = true) => {
  const flights = getDefaultFlights();
  const env = getConfigByEnvironment().app;

  applyControlTowerFlights(flights, env);

  return {
    ...flights,
    ...(withUrlFlights ? getFlightsFromUrl(env) : {}),
  };
};

// We must save control tower flights to localStorage and read them on next page load because
// the API response can take a long time. As a result, we can't update the application with
// control tower flight state long after the page is done loading or it will interrupt the user.
export function setControlTowerFlights(flights: Flight[]) {
  LocalStorage.set<Flight[]>(controlTowerLocalStorageKey, flights);
}
