import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    icon: {
      color: palette.secondary,
      paddingTop: 3,
    },
    button: {
      cursor: 'pointer',
      border: 'none',
      background: 'transparent',
      paddingRight: 0,
      paddingLeft: 0,

      '&:active': {
        background: 'transparent',
      },

      '&:focus': {
        background: 'transparent',
      },

      '&:hover': {
        background: palette.secondaryHover,
      },
    },
    backText: {
      marginLeft: 8,
      color: palette.secondary,
      alignSelf: 'flex-end',
    },
    forwardText: {
      color: palette.secondary,
      marginRight: 8,
      alignSelf: 'flex-end',
    },
  };
};
