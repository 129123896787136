import { DetailsBase } from 'services/crm/types';
import { OrganizationSearchResponseItem } from 'services/edge-search/types';

export interface CustomerFragment {
  id: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Customer extends CustomerFragment {}

export interface SalesAccount {
  id: string;
  Address: Address;
  CID: string;
  GlobalCrmId: string;
  McApiId: string | null;
  MsSalesTpId: string | null;
}

export interface SalesAccountOrganization {
  accountId: string;
  organizationId: string;
}

export interface CRMLead {
  crmId: string;
  account: DetailsBase;
  enrollmentNumber?: string | null;
  contactId?: DetailsBase | null;
  id: string;
  entityName: string;
  type: CRMLeadType;
  title?: string;
}

export enum CRMLeadType {
  Opportunity = 'Opportunity', // t('quote::Opportunity')
  SuccessEngagement = 'Success Engagement', // t('quote::Success Engagement')
}

export interface NullableObjectWithId<T> {
  id: string;
  value: T | null;
}

export interface ObjectWithId<T> {
  id: string;
  value: T;
}

export interface CollectionWithId<T> {
  id: string;
  value: T[];
}

export interface Address {
  companyName: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  region?: string;
  country: string;
  postalCode?: string;
  phoneNumber?: string;
  email?: string;
}

export interface OrganizationWithAccountName {
  accountName: string;
  organizationItem: OrganizationSearchResponseItem;
}

export interface MatchingOrganizationQueryRequest {
  addressLine1: string;
  companyName: string;
}

export interface TenantProfile {
  tenantId?: string;
  isViral?: boolean;
  isConsumer: boolean;
}

/**
 * Data required to successfully associate a billing account to a sales account.
 *
 * @prop {string} accountId Identifier of the modern account where the billing account can be found
 * @prop {string} organizationId Identifier of the billing account that will be associate
 */
export interface AssociateOrganizationToCRMIDRequest {
  accountId: string;
  organizationId: string;
}

export interface RoleAssignments {
  roleId: string;
  principalId: string;
  principalTenantId?: string;
}
