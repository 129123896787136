import { CustomerListRowProps } from 'components';
import i18next from 'i18next';
import { routes } from 'routes';
import { Customer } from 'services/ldss/types';

export const mergeExactSearchResultToTop = (
  searchTerm: string,
  customerListToMerge?: Customer[],
  exactCustomerMatchList?: Customer[]
) => {
  if (
    exactCustomerMatchList &&
    exactCustomerMatchList.length &&
    customerListToMerge &&
    searchTerm.trim()
  ) {
    customerListToMerge.unshift(exactCustomerMatchList[0]);
    if (customerListToMerge.length > 1) {
      customerListToMerge.pop();
    }
  }
};

export const mapEntityTypeToCustomerDisplayRow = (
  customerToMap: Customer,
  searchTerm: string,
  hideEnrollmentId?: boolean
): CustomerListRowProps => {
  let customer: CustomerListRowProps = {
    customerPageUrl: routes.customer.forId(customerToMap.tpid),
    parentName: customerToMap.accountName,
    tpid: customerToMap.tpid,
    isAccountEntity: customerToMap.entityType.toUpperCase() === 'ACCOUNT',
    searchText: searchTerm || '',
    enrollmentId: customerToMap.agreement.agreementNumber || '',
  };

  switch (customerToMap.entityType.toUpperCase()) {
    case 'ACCOUNT': {
      if (
        (customerToMap.account.countryName || '').toUpperCase().indexOf(searchTerm.toUpperCase()) >
        -1
      ) {
        customer.propertyLabelText = i18next.t('home::Country name');
        customer.propertyValue = customerToMap.account.countryName;
        customer.propertyIconType = 'RecurringEvent';
      }
      break;
    }
    case 'AGREEMENT': {
      if (
        !hideEnrollmentId &&
        (customerToMap.agreement.agreementNumber || '')
          .toUpperCase()
          .indexOf(searchTerm.toUpperCase()) > -1
      ) {
        customer.propertyLabelText = i18next.t('home::Enrollment ID');
        customer.propertyValue = customerToMap.agreement.agreementNumber;
        customer.propertyIconType = 'RecurringEvent';
      } else {
        customer.propertyLabelText = i18next.t('home::Business name');
        customer.propertyValue = customerToMap.agreement.businessName;
        customer.propertyIconType = 'BusinessHoursSign';
      }
      break;
    }
    case 'TENANT': {
      if (
        (customerToMap.tenant.tenantId || '').toUpperCase().indexOf(searchTerm.toUpperCase()) > -1
      ) {
        customer.propertyLabelText = i18next.t('home::Tenant GUID');
        customer.propertyValue = customerToMap.tenant.tenantId;
        customer.propertyIconType = 'Guid';
      } else {
        customer.propertyLabelText = i18next.t('home::Tenant name');
        customer.propertyValue = customerToMap.tenant.organizationName;
        customer.propertyIconType = 'RecurringEvent';
      }
      break;
    }
    case 'PCN': {
      if (
        (customerToMap.pcn.publicCustomerNumber || '')
          .toUpperCase()
          .indexOf(searchTerm.toUpperCase()) > -1
      ) {
        customer.propertyLabelText = i18next.t('home::PCN');
        customer.propertyValue = customerToMap.pcn.publicCustomerNumber;
        customer.propertyIconType = 'CompanyDirectory';
      }
    }
  }
  return customer;
};

export const filterToDistinctCustomers = (customersToFilter?: Customer[]): Customer[] => {
  const result = [];
  const uniqueTPIDs = new Set();
  for (const customer of customersToFilter || []) {
    if (!uniqueTPIDs.has(customer.tpid)) {
      uniqueTPIDs.add(customer.tpid);
      result.push(customer);
    }
  }
  return result;
};
