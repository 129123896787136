import { LinkButton, LinkProps, NavLinkInternal } from 'components/ions';
import { meplaHistory } from 'createHistory';
import { ConnectedCalloutCardLink } from 'features/proposal/components/CalloutCardLink/CalloutCardLink';
import { RemoveEnrollmentDialog } from 'features/proposal/components/Dialogs/RemoveEnrollmentDialog/RemoveEnrollmentDialog';
import { getActiveProposalId } from 'features/proposal/selectors';
import { CardType } from 'features/proposal/types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { routes } from 'routes';
import { RootState } from 'store/types';
import { DialogProps } from 'styles';
import { DialogContext } from 'styles/DialogueProvider';

import { openSelectOpportunityDialog } from '../Dialogs/SelectOpportunityDialog/SelectOpportunityDialog';
import {
  openEditOrganizationWizardDialog,
  openTenantWizardDialog,
  TenantWizardView,
} from '../Wizards';

interface NotificationProps {
  disabled?: boolean;
}

interface LineItemNotificationProps extends NotificationProps {
  lineItemId: string;
}

const selectOrganizationMapStateToProps = (state: RootState) => ({
  proposalId: getActiveProposalId(state),
});

const SelectOrganizationNotConnected: React.FC<ReturnType<
  typeof selectOrganizationMapStateToProps
>> = props => {
  const { t } = useTranslation();
  const url = routes.quote.customerForId(props.proposalId);
  return (
    <NavLinkInternal dataAutomationId="selectBillingAccount" to={url}>
      {t('quote::select billing account')}
    </NavLinkInternal>
  );
};

export const SelectOrganization = connect(selectOrganizationMapStateToProps)(
  SelectOrganizationNotConnected
);

export const AddTenant: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  return (
    <LinkButton
      disabled={props.disabled}
      displayText={t('quote::add tenant')}
      onClick={() => {
        openTenantWizardDialog(context, TenantWizardView.Tenant);
      }}
    />
  );
};

export const AddWorkAccount: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  return (
    <LinkButton
      dataAutomationId="addCustomerWorkAccount"
      disabled={props.disabled}
      displayText={t('quote::add customer work account')}
      onClick={() => {
        openTenantWizardDialog(context, TenantWizardView.Email);
      }}
    />
  );
};

export const AddCRMId: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  return (
    <LinkButton
      dataAutomationId="enterCRMIdButton"
      disabled={props.disabled}
      displayText={t('quote::enter CRM ID')}
      onClick={() => {
        openSelectOpportunityDialog(context);
      }}
    />
  );
};

export const EditOrganization: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  return (
    <LinkButton
      dataAutomationId="editOrganizationButton"
      disabled={props.disabled}
      displayText={t('quote::edit billing account')}
      onClick={() => {
        openEditOrganizationWizardDialog(context);
      }}
    />
  );
};

export const OpenConfigCard: React.FC<LineItemNotificationProps> = props => {
  const { t } = useTranslation();
  const linkProps: LinkProps = {
    displayText: t('quote::open configuration card'),
  };
  const target = React.useRef<HTMLSpanElement>(null);
  return (
    <ConnectedCalloutCardLink
      dataAutomationId="detailsPaneOpenConfigCardButton"
      lineItemId={props.lineItemId}
      linkProps={linkProps}
      offset={{ left: 30, top: 10 }}
      reference={target}
      type={CardType.Config}
    />
  );
};

export const RemoveEnrollmentNumber: React.FC<NotificationProps> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const removeEnrollmentDialogProps: DialogProps = {
    providedDialog: <RemoveEnrollmentDialog />,
  };
  return (
    <LinkButton
      dataAutomationId="removeEnrollmentNumberButton"
      disabled={props.disabled}
      displayText={t('quote::remove enrollment number')}
      onClick={() => {
        context.openDialog(removeEnrollmentDialogProps);
      }}
    />
  );
};

const viewInSalesTabMapStateToProps = (state: RootState) => ({
  proposalId: getActiveProposalId(state),
});

const ViewInSalesNotConnected: React.FC<NotificationProps &
  ReturnType<typeof viewInSalesTabMapStateToProps>> = props => {
  const { t } = useTranslation();
  const url = routes.quote.salesForId(props.proposalId);
  return (
    <LinkButton
      dataAutomationId="viewInSalesLink"
      disabled={props.disabled}
      displayText={t('quote::view in sales property sheet')}
      onClick={() => {
        meplaHistory.push(url);
      }}
    />
  );
};

export const ViewInSales = connect(viewInSalesTabMapStateToProps)(ViewInSalesNotConnected);

const SelectExpiringEnrollmentIntentNotConnected: React.FC<ReturnType<
  typeof viewInSalesTabMapStateToProps
>> = props => {
  const { t } = useTranslation();
  const url = routes.quote.salesForId(props.proposalId);
  return (
    <NavLinkInternal dataAutomationId="selectExpiringEnrollmentLink" to={url}>
      {t('quote::select expiring enrollment intention')}
    </NavLinkInternal>
  );
};

export const SelectExpiringEnrollmentIntent = connect(viewInSalesTabMapStateToProps)(
  SelectExpiringEnrollmentIntentNotConnected
);
