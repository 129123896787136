import { CommandBar as CommandBarIon, CommandBarButton } from 'components/ions';
import * as selectors from 'features/proposal/selectors';
import { isAgreementTypeLegacy } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { routes } from 'routes';
import { Proposal, ProposalStatus } from 'services/proposal/types';
import { RootState } from 'store/types';

import {
  ApproveProposalButton,
  ApproverWithdrawButton,
  NewProposalCommandBarButton,
  PublishButton,
  RejectProposalButton,
  SellerWithdrawButton,
  SubmitForApprovalButton,
  TransactButton,
} from '../Dialogs';
import { ShareButton } from '../Dialogs/ShareDialogs/ShareButton';
import { ExtendButton } from './ExtendButton';

const styles = {
  rightSideCommand: {
    paddingLeft: 20,
  },
};

const mapStateToProps = (state: RootState) => {
  const isPartnerProposal = selectors.isPartnerProposal(state);
  return {
    canWithdrawProposal: !isPartnerProposal && selectors.canWithdrawProposal(state),
    disableShare: selectors.disableShare(state),
    isAgreementTypeLegacy: (proposal: Proposal) => isAgreementTypeLegacy(proposal),
    isProposalLoading: selectors.proposalLoading(state),
    proposal: selectors.getActiveProposal(state),
    isProposalActiveOrActivable: (proposal: Proposal) =>
      selectors.isProposalActiveOrActivable(proposal),
    proposalId: state.proposal.views.activeId,
    showApproverCommands: selectors.showApproverCommands(state),
    isPartnerProposal,
  };
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps;
type Props = ConnectedProps & WithStyles<typeof styles>;

export const CommandBarUnstyled: React.FC<Props> = props => {
  const { canWithdrawProposal, classes, history, proposal, proposalId, isPartnerProposal } = props;
  const { t } = useTranslation();

  const showTransactButton = props.isAgreementTypeLegacy(proposal);

  // Commands available for seller view ----------------
  const sellerCommandsButtons = canWithdrawProposal ? (
    <SellerWithdrawButton key="seller-withdraw-button" />
  ) : (
    <SubmitForApprovalButton key="submit-button" />
  );

  const defaultCommandButtons = [
    sellerCommandsButtons,
    <div className={classes.rightSideCommand} key="publish-button">
      {showTransactButton ? <TransactButton /> : <PublishButton />}
    </div>,
  ];

  // Commands available for approver view --------------
  const approverCommandsButtons = [
    <RejectProposalButton key="reject-button" />,
    <div className={classes.rightSideCommand} key="approve-button">
      <ApproveProposalButton />
    </div>,
  ];

  if (canWithdrawProposal)
    approverCommandsButtons.push(
      <div className={classes.rightSideCommand} key="approver-withdraw-button">
        <ApproverWithdrawButton />
      </div>
    );

  // Commands to display ------------------------------
  const rightSideCommand = () => {
    if (
      proposal.header.status === ProposalStatus.Expired &&
      +proposal.header.modifiedApiVersion >= 11 &&
      !!proposal.lineItems.length
    ) {
      return <ExtendButton quoteId={proposal.id} />;
    }
    if (props.showApproverCommands) {
      return approverCommandsButtons;
    }
    return !isPartnerProposal ? defaultCommandButtons : undefined;
  };

  let shareButton;

  if (!isPartnerProposal) {
    shareButton = <ShareButton />;
  }

  return (
    <CommandBarIon id="ProposalEditor-CommandBar" rightSideCommands={rightSideCommand()}>
      <NewProposalCommandBarButton />
      <>
        <CommandBarButton
          ariaLabel={t('quote::Navigate to properties page')}
          dataAutomationId="propertiesButton"
          iconName="DocumentManagement"
          id="properties-button"
          text={t('quote::Properties')}
          onClick={() => history.push(routes.quote.customerForId(proposalId))}
        />
        {shareButton}
      </>
    </CommandBarIon>
  );
};

export const CommandBarStyled = withStyles(styles)(CommandBarUnstyled) as React.FC<ConnectedProps>;
export const CommandBar = withRouter(connect(mapStateToProps)(CommandBarStyled));
