import {
  isEligibleForSurvey,
  npsSurveySampleRate,
} from 'features-apollo/app/components/AppContent/NetPromoterScore/utils';
import { AppEnvironment, PermissionsConfig } from 'features/app/config/type';
import { NpsSurvey } from 'generated/graphql';
import { createSelector } from 'reselect';
import { Flight } from 'services/flights/flightList';
import { UserPreferences } from 'services/user-preferences/types';
import { RootState } from 'store/types';
import { ThemeKey } from 'styles';

import {
  createLoadingSelector,
  createProcessingSelectors,
  getFlightIsEnabled,
} from '../app/selectors';
import { User } from './types';
import { dialog20200803 } from './utils';

export const getMSContact = (state: RootState) => state.user.msContact;
export const getMSContactSearchResults = (state: RootState) => state.user.msContactResults;

export const getUser = (state: RootState) => state.user.current;
export const getUserPreferences = (state: RootState): UserPreferences => {
  if (state.user.preferences !== null) {
    return state.user.preferences;
  }

  const userPreferences: UserPreferences = {
    eTag: '',
    theme: ThemeKey.light,
    viewedInfoDialogs: [],
  };
  return userPreferences;
};

export const isFirstRunViewed = (state: RootState) => {
  const userPreferences = getUserPreferences(state);
  const viewedDialogId = dialog20200803;
  return !!userPreferences.viewedInfoDialogs?.some(id => id === viewedDialogId);
};

export const isEligibleForNpsSurvey = (state: RootState): boolean => {
  const npsSurveyEnabled = getFlightIsEnabled(state, Flight.npsSurvey);

  if (!npsSurveyEnabled) {
    return false;
  }

  const userPreferences = getUserPreferences(state);

  if (!userPreferences.npsSurvey) {
    return true;
  }

  return isEligibleForSurvey(
    userPreferences.npsSurvey.map(x => {
      const history: NpsSurvey = { date: x.date, responded: x.responded };
      return history;
    })
  );
};

//this uses a memoized selector to prevent recalculating the sample eligibility on render
export const showNpsSurvey = createSelector([isEligibleForNpsSurvey], isEligible => {
  return isEligible ? Math.random() <= npsSurveySampleRate : false;
});

// User favorites selectors
export const userFavoriteProductGroups = (state: RootState) => state.user.preferences.productGroups;
export const getUserFavoriteProductsIds = createSelector(
  userFavoriteProductGroups,
  productGroups => {
    let productIds: string[] = [];

    if (productGroups) {
      for (let groupName in productGroups) {
        productGroups[groupName].products.forEach(product => productIds.push(product.productId));
      }
    }

    return productIds;
  }
);
export const getUserFavoriteProductNames = createSelector(
  userFavoriteProductGroups,
  productGroups => {
    let productNames: string[] = [];

    if (productGroups) {
      for (let groupName in productGroups) {
        productGroups[groupName].products.forEach(product =>
          productNames.push(product.productName)
        );
      }
    }

    return productNames;
  }
);

// User permission selectors
export const getUserPermissions = createSelector(
  getUser,
  (state: RootState) => state.app.appConfig.app,
  (state: RootState) => state.user.current.permissions,
  (user: User, environment: AppEnvironment): string[] => {
    const permissions = (user.permissions && user.permissions.permissions) || [];
    return permissions
      .filter(permission => permission.constraints.includes(environment))
      .map(permission => permission.name);
  }
);

export const userHasPermission = (
  state: RootState,
  permissionsConfig: PermissionsConfig
): boolean => {
  const permissions = getUserPermissions(state);

  // Ensure the user has oneOf the permissions being checked.
  if (
    permissionsConfig.oneOf &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    !permissions.some(permission => permissionsConfig.oneOf!.includes(permission))
  ) {
    return false;
  }

  // Ensure the user has allOf the permissions being checked.
  if (
    permissionsConfig.allOf &&
    !permissionsConfig.allOf.every(permission => permissions.includes(permission))
  ) {
    return false;
  }

  return true;
};

// Processing selectors
export const checkLDSSReadPermissionProcessing = createProcessingSelectors([
  '@@user/ldsspermissions/LOAD',
]);
export const processingUserPreferencesUpdate = createProcessingSelectors([
  '@@user/preferences/UPDATE',
]);

// Loading selectors
export const msContactSearchLoading = createLoadingSelector(['@@msContact/SEARCH']);
export const userPermissionsLoading = createLoadingSelector(['@@user/permissions/LOAD']);
export const userPreferencesLoading = createLoadingSelector(['@@user/preferences/LOAD']);
export const userPermissionsLoaded = createSelector(
  getUser,
  (state: RootState) => state.app.appConfig.app,
  (state: RootState) => checkLDSSReadPermissionProcessing(state),
  (state: RootState) => userPermissionsLoading(state),
  (user: User, config, ldssPermissionsProcessing, userPermissionsLoading): boolean => {
    if (user.permissions && !ldssPermissionsProcessing.loading && !userPermissionsLoading) {
      return user.permissions.permissions !== undefined;
    } else {
      return false;
    }
  }
);

export const processingMSContact = createProcessingSelectors([
  '@@msContact/LOAD',
  '@@quote/UPDATE',
]);
