import { ThemeProps, sharedStyles } from 'styles';
import { IChoiceGroupStyles } from 'office-ui-fabric-react';

export const convertAssetDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  convertDialog: {
    '& #PrimaryArea': {
      maxHeight: 402,
    },
    '& div~div:nth-child(2):not(#PrimaryArea)': {
      maxWidth: 749,
    },
    '& .ms-DetailsList': {
      marginTop: -10,
    },
    '& .ms-DetailsHeader': {
      borderBottom: `2px solid ${theme.palette.backgroundDivider}`,
      height: 23,
    },
  },
  detailsModifiableList: {
    minWidth: 703,
    borderTop: `1px solid ${theme.palette.backgroundDivider}`,
    overflowY: 'auto',
    maxHeight: 400,
    '& .ms-List-cell': {
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
    },
  },
  detailsStaticList: {
    minWidth: 703,
    borderTop: `1px solid ${theme.palette.backgroundDivider}`,
    overflowY: 'auto',
    maxHeight: 400,
    '& .ms-List-cell': {
      cursor: 'default',
    },
    '& .is-actionable, .is-actionable:hover:active:focus': {
      background: theme.palette.transparent,
    },
  },
  convertDialogTitleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 15px 23px 23px',
    marginTop: -10,
  },
  convertDialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '23px 15px 0px 23px',
  },
  convertDialogBody: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButtonNoTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '23px 15px 3px 23px',
  },
  footer: {
    ...sharedStyles(theme).customDialogFooter,
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 20,
    },
  },
  iconContainer: {
    ...sharedStyles(theme).customDialogIconContainer,
    marginBottom: 15,
  },
  convertDialogIconButtonContainer: {
    borderColor: theme.palette.backgroundDivider,
    background: theme.palette.transparent,
    height: 100,
    width: 100,
    marginTop: 23,
    marginBottom: 32,
    marginRight: 20,
    display: 'flex',
    borderRadius: 2,
    '&:hover': {
      borderColor: theme.palette.textLinkHover,
      background: theme.palette.transparent,
      '& .ms-Button-flexContainer div:first-of-type': {
        background: theme.palette.lineItemHighlight,
      },
    },
    '& .ms-Button-flexContainer': {
      flexDirection: 'column',
      alignItems: 'center',
    },
    padding: 0,
  },
  convertDialogIconContainerForDialog: {
    ...sharedStyles(theme).iconContainerForDialog,
    background: theme.palette.backgroundCommon,
    color: theme.palette.textLink,
  },
  flexAlignStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  text: {
    fontSize: theme.fonts.fontSizes.large,
  },
  convertDialogAssetDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 24,
    color: theme.palette.textPrimaryDisabled,
  },
  convertDialogAssetDetailsTitle: {
    paddingTop: 12,
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  convertActionableAssetDialogBody: {
    width: 702,
    paddingLeft: 23,
    paddingRight: 23,
    '& table': {
      borderSpacing: 0,
    },
  },
  buttonLink: {
    paddingTop: 5,
    color: theme.palette.textLink,
    '&:active, &:hover:active': {
      color: theme.palette.activeLink,
    },
    '&:visited, &:hover, &:focus': {
      color: theme.palette.textLink,
    },
    '&.is-disabled': {
      color: theme.palette.textDisabled,
    },
  },
  convertActionableAssetDetails: {
    display: 'inline-flex',
    width: '40%',
  },
  convertActionableAssetDetailsIcon: {
    display: 'inline-flex',
    width: '20%',
    verticalAlign: 'text-top',
    marginTop: 32,
  },
  placeholder: {
    color: theme.palette.textTertiary,
    fontSize: theme.fonts.fontSizes.small,
    paddingTop: 12,
    fontStyle: theme.fonts.fontStyles.italic,
  },
  assetInfo: {
    ...sharedStyles(theme).link,
    float: 'right',
    marginLeft: 6,
    marginTop: 1,
    '&:hover, &:focus': {
      cursor: 'pointer',
    },
  },
  marginRight15: {
    marginLeft: '-15px',
  },
  addSeatsText: {
    paddingBottom: 4,
  },
  addSeatsContainer: {
    maxWidth: 232,
  },
});

export const choiceGroupStyles: IChoiceGroupStyles = {
  flexContainer: {},
  root: {
    marginBottom: 32,
    marginTop: 24,
    selectors: {
      '.ms-ChoiceField': {
        paddingRight: 12,
      },
    },
  },
};
