import {
  AddressProps,
  ApprovalsList,
  LinkButton,
  NavLinkInternal,
  Persona,
  SectionSeparator,
  ShimmerForBackgroundStandout,
  Spinner,
  TextBody,
  TextBodyLarge,
  TextBodySmall,
  TextCurrency,
  TextTitle,
  TextTitleSecondary,
} from 'components';
import { mergeClassNames } from 'components/utilities';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { getApprovalDaysElapsedText } from 'features-apollo/quote/components/DetailsPane/utils';
import { GetModernAgreementPreview } from 'features-apollo/quote/components/queries';
import { getAgreementConfig, getMinCreditLineDivisor } from 'features/app/selectors';
import { getProduct } from 'features/catalog/selectors';
import { Address, AddressLarge } from 'features/components/Address';
import { CwaLink } from 'features/components/CwaLink/CwaLink';
import { CRMLeadType } from 'features/customer/types';
import {
  generateApprovals,
  getComments,
} from 'features/proposal/components/DetailsPane/ProposalDetails';
import { CreditLineReason, openAgreementPreview } from 'features/proposal/components/Dialogs';
import * as selectors from 'features/proposal/selectors';
import {
  formatCurrency,
  getAgreementsErrorTerms,
  getCRMId,
  getCRMLeadType,
  getGqlTermIdError,
  getInvalidTermsErrorMessage,
  isAgreementTypeLegacy,
  isProposalReadOnly,
  isQuoteExpiringSoon,
} from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { routes } from 'routes';
import { Approval } from 'services/approval/types';
import loggerService from 'services/logger-service';
import { ProposalStatus, ProposalSummary } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles/DialogueProvider';
import { oc } from 'ts-optchain';

import { useLazyQuery } from '@apollo/react-hooks';

import { ApprovalComments, ApprovalCommentsStyles } from '../DetailsPane';
import { summaryDetailStyles } from './SummaryDetail.styles';

const mapStateToProps = (state: RootState) => {
  const quote = selectors.getActiveProposal(state);
  const isQuoteLegacy = isAgreementTypeLegacy(quote);

  return {
    activeApproval: selectors.getActiveApproval(state),
    customerTpid: selectors.getCustomerTpid(state),
    isQuoteLegacy,
    proposalId: selectors.getActiveProposalId(state),
    proposal: quote,
    proposalFragment: selectors.getActiveProposalFragment(state),
    customerName: (proposal: ProposalSummary) =>
      selectors.getCustomerName(state, proposal, 'Unknown Billing Account'),
    soldToOrgId: selectors.getSoldToOrgId(state),
    customerAddress: selectors.getCustomerAddress(state),
    endCustomerAddress: selectors.getEndCustomerAddress(state),
    creditInfo: selectors.getCreditInfo(state),
    creditLineStatus: selectors.getCreditLineStatus(state),
    minimumCreditLine: selectors.getMinimumCreditLine(state).toString(),
    billingCurrency: selectors.getBillingCurrency(state),
    minCreditDivisor: getMinCreditLineDivisor(state),
    agreementConfig: getAgreementConfig(state),
    tradeName: selectors.getTradeName(state),
    isPartnerProposal: selectors.isPartnerProposal(state),
    getProductName: (productId: string) =>
      getProduct(state, productId).LocalizedProperties[0].ProductTitle,
  };
};

type Props = WithStyles<typeof summaryDetailStyles> & ReturnType<typeof mapStateToProps>;

const SummaryDetailUnconnected: React.FC<Props> = props => {
  const {
    activeApproval,
    customerTpid,
    classes,
    proposalId,
    proposal,
    soldToOrgId,
    proposalFragment,
    customerName,
    customerAddress,
    endCustomerAddress,
    creditInfo,
    creditLineStatus,
    minimumCreditLine,
    billingCurrency,
    minCreditDivisor,
    tradeName,
    isPartnerProposal,
    getProductName,
  } = props;
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);

  const creditDetails = {
    creditInfo,
    creditLineStatus,
    minimumCreditLine,
    billingCurrency,
    minCreditDivisor,
  };
  const creditLineValue = Math.floor(
    parseInt(oc(creditDetails).creditInfo.reasons.credit_line('0'))
  );
  const creditLineFormatted = formatCurrency(creditLineValue, 0);
  const [getAgreementPreview] = useLazyQuery(GetModernAgreementPreview, {
    onCompleted: data => {
      loggerService.log({ name: `Agreement preview generated for quoteId ${proposalId}` });
      openAgreementPreview({ id: 'agreement-preview-dialog', isError: false }, dialogContext, data);
    },
    onError: error => {
      let customError;
      const termsErrorResult = getGqlTermIdError(error);
      if (termsErrorResult.code && termsErrorResult.message) {
        const invalidTermIds = getAgreementsErrorTerms(
          termsErrorResult.code,
          termsErrorResult.message,
          proposal.lineItems
        );
        const invalidTermNames = invalidTermIds.map(invalidTermId => getProductName(invalidTermId));
        customError = getInvalidTermsErrorMessage(
          t('quote::Sorry, the "View Agreement" action failed.'),
          invalidTermNames,
          t
        );
        loggerService.error({
          error: new Error(
            `Failed to generate agreement for quoteId ${proposalId}. Invalid termId for 1 or more of the following: ${invalidTermNames}`
          ),
        });
      } else {
        loggerService.error({
          error: new Error(`Unable to generate the agreement for quote ${proposalId}`),
        });
      }

      openAgreementPreview(
        { id: 'agreement-preview-error-dialog', isError: true, customMessage: customError },
        dialogContext
      );
    },
    fetchPolicy: 'cache-and-network',
  });
  const getCreditLineOrStatus = () => {
    switch (creditLineStatus) {
      case CreditLineReason.CreditIncrease:
      case CreditLineReason.CreditLine:
        return (
          <>
            <TextBody addClass={classes.paddingTopXSmall}>{creditLineFormatted}</TextBody>
            <TextCurrency>&nbsp;{creditDetails.billingCurrency}</TextCurrency>
          </>
        );

      case CreditLineReason.SafeList:
        return <TextBody addClass={classes.safeList}>{t('quote::Safe list')}</TextBody>;

      case CreditLineReason.PendingReview:
        return <TextBody addClass={classes.pending}>{t('quote::Pending')}</TextBody>;

      case CreditLineReason.UnderReview:
        return <TextBody addClass={classes.pending}>{t('quote::Under review')}</TextBody>;

      case CreditLineReason.ReviewCancelled:
        return <TextBody addClass={classes.pending}> {t('quote::Review cancelled')}</TextBody>;

      case CreditLineReason.Rejected:
        return <TextBody addClass={classes.rejected}>{t('quote::Rejected')}</TextBody>;

      default:
        return <TextBody>{t('quote::Unknown status')}</TextBody>;
    }
  };

  const commentItems = getComments(activeApproval);
  const approvalItems = generateApprovals(
    oc(proposal).header.approval() as Partial<Approval>,
    activeApproval
  );

  const viewCustomerLink = customerTpid && (
    <CwaLink
      dataAutomationId="viewCustomer"
      fontSize="medium"
      linkText={t('quote::view customer')}
      tpid={customerTpid}
    />
  );

  const viewQuotesForThisCustomerLink = soldToOrgId && (
    <div>
      <NavLinkInternal
        dataAutomationId="viewQuotesForThisCustomer"
        fontSize="medium"
        to={routes.home.quotes.mySearch(soldToOrgId)}
      >
        {t('quote::view quotes for this customer')}
      </NavLinkInternal>
    </div>
  );

  const showCustomerActions = customerTpid || soldToOrgId;

  const customerActions = showCustomerActions && (
    <div style={{ paddingTop: 5 }}>
      {viewCustomerLink}
      {viewQuotesForThisCustomerLink}
    </div>
  );

  const hasLeadOrg = !!oc(proposal).header.extendedProperties.userPreferences.leadOrgId();

  const agreementButtonProps = {
    displayText: t('quote::View Agreement'),
    ariaLabel: t('quote::View Agreement'),
    iconName: 'OpenInNewWindow',
    iconLeft: true,
    id: 'viewAgreementButton',
    onClick: () => {
      getAgreementPreview({
        variables: {
          quoteId: proposal.id,
        },
      });
    },
    dataAutomationId: 'viewAgreementButton',
  };
  const agreementButton =
    proposal && proposal.header.status !== ProposalStatus.Completed && !props.isQuoteLegacy ? (
      <LinkButton {...agreementButtonProps} />
    ) : (
      undefined
    );
  const addressProps: AddressProps | undefined | null = customerAddress && {
    address: customerAddress,
    action: customerActions,
    dataAutomationId: 'customerAddress',
  };
  const endCustomerAddressProps: AddressProps | null = endCustomerAddress && {
    address: endCustomerAddress,
    action: customerActions,
    dataAutomationId: 'endCustomerAddress',
  };
  const crmId = getCRMId(proposal);

  let crmLabelText = t('home::CRM ID');
  if (crmId) {
    switch (getCRMLeadType(proposal)) {
      case CRMLeadType.Opportunity:
        crmLabelText = t('home::CRM ID (Opportunity)');
        break;
      case CRMLeadType.SuccessEngagement:
        crmLabelText = t('home::CRM ID (Success Engagement)');
        break;
    }
  }
  const dealEstimation = formatCurrency(oc(proposal).header.estimatedDealValue(0), 0);

  const hasApprovalItems = approvalItems && approvalItems.length > 0;

  const customerDetailsWithMargin = mergeClassNames([classes.customerDetails, classes.marginTop10]);
  const quoteDetailsSectionRow = mergeClassNames([classes.sectionRow, classes.quoteDetails]);

  const getExpirationDateFragment = () => {
    const expirationDate = new Date(proposal.header.expirationDate);
    const allowExtendExpiration =
      isQuoteExpiringSoon(proposal.header.expirationDate) && !isProposalReadOnly(proposal);

    return (
      <div className={classes.expirationDateContainer}>
        <TextBodySmall addClass={classes.lightText}>{t('quote::Good until')}</TextBodySmall>
        <TextBody addClass={classes.expirationDateRow} dataAutomationId="goodUntil">
          <div className={allowExtendExpiration ? classes.expirationDateColor : ''}>
            {convertDateToFormattedString(expirationDate, LocaleDateFormat.lll)}
          </div>
          {allowExtendExpiration && (
            <div className={classes.extendDateText}>
              <NavLinkInternal fontSize="medium" to={routes.quote.infoForId(proposal.id)}>
                {t('quote::extend date')}
              </NavLinkInternal>
            </div>
          )}
        </TextBody>
      </div>
    );
  };

  const commentsStyles: ApprovalCommentsStyles = {
    author: classes.commentAuthor,
    commentSection: classes.commentSection,
    container: classes.commentContainer,
    text: classes.commentText,
    textContainer: classes.commentTextContainer,
  };

  const renderPartnerDetails = () => {
    return (
      <>
        <div className={customerDetailsWithMargin}>
          <div className={classes.partnerCol}>
            <TextBodySmall addClass={mergeClassNames([classes.sectionRow, classes.lightText])}>
              {t('home::Partner')}
            </TextBodySmall>
            {addressProps && (
              <TextTitleSecondary>
                {addressProps.address.companyName || t('quote::Unknown')}
              </TextTitleSecondary>
            )}
            {addressProps && <Address {...addressProps} dataAutomationId="partnerAddress" />}
          </div>
          <div className={classes.partnerCol}>
            <TextBodySmall addClass={mergeClassNames([classes.sectionRow, classes.lightText])}>
              {t('home::End customer')}
            </TextBodySmall>
            {endCustomerAddressProps && (
              <TextTitleSecondary>
                {endCustomerAddressProps.address.companyName || t('quote::Unknown')}
              </TextTitleSecondary>
            )}
            {endCustomerAddressProps && (
              <Address {...endCustomerAddressProps} dataAutomationId="endCustomerAddress" />
            )}
          </div>
        </div>
      </>
    );
  };

  const renderCustomerDetails = () => (
    <>
      <div className={customerDetailsWithMargin}>
        <div className={classes.customerCol}>
          <TextBodySmall addClass={`${classes.sectionRow} ${classes.lightText}`}>
            {crmLabelText}
          </TextBodySmall>
          {crmId ? (
            <TextBody addClass={classes.paddingTopXSmall}>{crmId && crmId.toUpperCase()}</TextBody>
          ) : (
            <div className={`${classes.paddingTopXSmall} ${classes.maxWidth}`}>
              <TextBody addClass={classes.italic}>
                {t('home::No opportunity has been added for this quote')}
              </TextBody>
            </div>
          )}
        </div>
        {creditInfo ? (
          <div
            className={`${classes.customerCol} ${classes.creditMargin}`}
            data-automation-id="creditLine"
          >
            <TextBodySmall
              addClass={`${classes.sectionRow} ${classes.lightText}`}
              dataAutomationId="creditLineLabel"
            >
              {t('home::Credit line')}
            </TextBodySmall>
            <div className={classes.paddingTopXSmall} data-automation-id="creditLineValue">
              {getCreditLineOrStatus()}
            </div>
          </div>
        ) : null}
      </div>
      <div className={customerDetailsWithMargin}>
        <div className={classes.address}>
          <TextBodySmall addClass={`${classes.sectionRow} ${classes.lightText}`}>
            {t('home::Billing account address')}
          </TextBodySmall>
          <div className={`${classes.paddingTopXSmall} ${classes.darkText}`}>
            {addressProps ? (
              <AddressLarge {...addressProps} />
            ) : (
              <div className={classes.maxWidth}>
                <TextBody addClass={classes.italic}>
                  {t('home::Billing account not yet configured')}
                </TextBody>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  const renderApprovalDetails = () => (
    <>
      {hasApprovalItems ? (
        <article>
          <div className={mergeClassNames([classes.marginTop12, classes.innerRow])}>
            {!isPartnerProposal && (
              <TextBodySmall addClass={classes.lightText}>{t('home::Approvals')}</TextBodySmall>
            )}
            <div className={mergeClassNames([classes.approvalList, classes.darkText])}>
              {approvalItems.map((approval, key) => (
                <ApprovalsList
                  approval={approval}
                  dataAutomationId={`detailsApprovalsList-${key}`}
                  getApprovalDaysElapsedText={(days: number) => getApprovalDaysElapsedText(days, t)}
                  id={`details-approvals-list-${key}`}
                  key={key}
                  largerText={true}
                />
              ))}
            </div>
          </div>
        </article>
      ) : (
        <div className={classes.marginTop10} />
      )}

      {activeApproval && commentItems && commentItems.length > 0 ? (
        <div className={classes.approvalContainer}>
          <ApprovalComments
            approvalId={activeApproval.id}
            comments={commentItems}
            largerText
            styles={commentsStyles}
          />
          <div className={classes.quoteDetails} />
        </div>
      ) : hasApprovalItems ? (
        <div className={classes.quoteDetails} />
      ) : null}
    </>
  );

  const renderApprovalDetailsSection = () => (
    <>
      <article>
        <div className={classes.spacing} />
        <TextTitleSecondary>{t('home::Approval Details')}</TextTitleSecondary>
        {renderApprovalDetails()}
        <SectionSeparator />
      </article>
    </>
  );

  const organizationName = customerName(proposalFragment);
  const quoteName = oc(proposalFragment).header.name();

  return (
    (!proposalId && <></>) ||
    (!proposalFragment && <Spinner className={classes.spinner} />) || (
      <aside className={classes.detail}>
        <header className={classes.header}>
          <TextTitle addClass={classes.namesContainer}>
            <span
              className={mergeClassNames([classes.darkText, classes.textOverflow])}
              data-automation-id="organizationName"
              title={organizationName}
            >
              {organizationName || (
                <span className={classes.shimmer}>
                  <ShimmerForBackgroundStandout height={26} width={200} />
                </span>
              )}
            </span>
            {tradeName && tradeName !== 'Unknown' && (
              <small
                className={mergeClassNames([classes.lightText, classes.textOverflow])}
                data-automation-id="tradeName"
                title={t('quote::Doing business as {{tradeName}}', { tradeName })}
              >
                {`dba ${tradeName}`}
              </small>
            )}
            {quoteName && (
              <small
                className={mergeClassNames([classes.darkText, classes.textOverflow])}
                data-automation-id="quoteName"
                title={quoteName}
              >
                {quoteName}
              </small>
            )}
          </TextTitle>
          {!isPartnerProposal && (
            <div style={{ textAlign: 'right' }}>
              <div className={classes.h1}>
                <TextTitle dataAutomationId="annualDealEstimateValue">
                  <span className={classes.estimate}>
                    {dealEstimation}
                    &nbsp;
                    <TextBodyLarge addClass={classes.semiBold}>
                      ({oc(proposal).header.pricingContext.billingCurrency(t('quote::Unknown'))})
                    </TextBodyLarge>
                  </span>
                </TextTitle>
              </div>

              <label
                className={mergeClassNames([classes.right, classes.lightText, classes.annualDeal])}
                data-automation-id="annualDealEstimate"
              >
                {t('quote::Annual deal estimate')}
              </label>
            </div>
          )}
        </header>
        <section className={classes.main}>
          <article data-automation-id="customerDetails">
            <div className={classes.spacing} />
            <TextTitleSecondary>{t('home::Customer details')}</TextTitleSecondary>
            {isPartnerProposal ? renderPartnerDetails() : renderCustomerDetails()}
            <SectionSeparator addClass={classes.marginTop21} />
          </article>
          {isPartnerProposal ? renderApprovalDetailsSection() : undefined}
          <article data-automation-id="quoteDetails">
            <div className={classes.spacing} />
            <TextTitleSecondary>{t('home::Quote Details')}</TextTitleSecondary>
            {proposal && getExpirationDateFragment()}
            <div className={classes.sectionRow}>
              {isPartnerProposal ? <div className={classes.spacing} /> : renderApprovalDetails()}
              {agreementButton ? (
                <div className={quoteDetailsSectionRow}>
                  <TextBodySmall addClass={classes.lightText}>
                    {t('home::Required Terms')}
                  </TextBodySmall>
                  <TextBody addClass={classes.paddingTopXSmall}>
                    {hasLeadOrg
                      ? t('home::Customer Affiliate Purchase Terms')
                      : t('home::Microsoft Customer Agreement')}
                  </TextBody>
                  <div className={classes.paddingTopXSmall}>{agreementButton}</div>
                </div>
              ) : null}

              <div className={classes.sectionRow}>
                <TextBodySmall addClass={classes.lightText}>
                  {t('home::Microsoft contact')}
                </TextBodySmall>
                <div className={mergeClassNames([classes.paddingTopXSmall, classes.flex])}>
                  <Persona
                    dataAutomationId="msContact"
                    id="msContact"
                    name={oc(proposalFragment).header.msContact('')}
                  />
                  {!proposalFragment.header.msContact && (
                    <TextBodySmall addClass={classes.noContact}>
                      {t('home::no contact')}
                    </TextBodySmall>
                  )}
                </div>
              </div>
            </div>
          </article>
        </section>
      </aside>
    )
  );
};

export const SummaryDetail = connect(mapStateToProps)(
  withStyles(summaryDetailStyles)(SummaryDetailUnconnected)
);
