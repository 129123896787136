import { CalloutCalendar, CalloutCalendarProps, ComboBox } from 'components/ions';
import {
  IComboBox,
  IComboBoxOption,
  IOnRenderComboBoxLabelProps,
  IRenderFunction,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { calendarDropdownStyles } from './CalendarDropdown.styles';

/**
 * Props for the CalendarDropdown
 * @prop {CalloutCalendarProps} calloutCalendarProps - Props for the calloutCalendar
 * @prop {boolean} [dropdownDisabled] - Whether Dropdown is disabled .
 * @prop {ICombobox[]} dropdownOptions - Dropdown Options;
 * @prop {string} [id] - Id of the component
 * @prop {string} label - Label for the dropdown control
 * @prop {Selection} selected - The selected Dropdown Item
 * @prop {function} onDropdownSelect - function that would run when dropdown item is selected
 */

export interface CalendarDropdownProps {
  calloutCalendarProps: CalloutCalendarProps;
  dropdownDisabled?: boolean;
  dropdownOptions: IComboBoxOption[];
  id?: string;
  label?: string;
  selected?: string;
  required?: boolean;
  onDropdownSelect: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  dataAutomationId?: string;
  onRenderLabel?: IRenderFunction<IOnRenderComboBoxLabelProps>;
  dateErrorMessage?: string;
}

type Props = CalendarDropdownProps & WithStyles<typeof calendarDropdownStyles>;

export const CalendarDropdownUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, onDropdownSelect, dropdownOptions, label, selected, required } = props;
  const comboBoxAutomationId = props.dataAutomationId
    ? `${props.dataAutomationId}-comboBox`
    : 'cal-comboBox';
  return (
    <div className={classes.dropdownCalendarContainer} id={props.id}>
      <div className={classes.dropdownFlexItem}>
        <ComboBox
          dataAutomationId={comboBoxAutomationId}
          disabled={props.dropdownDisabled}
          errorMessage={props.dateErrorMessage}
          id={props.id}
          label={label}
          maxHeight={320}
          options={dropdownOptions}
          required={required}
          selectedKey={selected}
          onChange={onDropdownSelect}
          onRenderLabel={props.onRenderLabel}
        />
      </div>
      <div className={classes.flexSpacer} />
      <div className={classes.calendarButtonContainer}>
        <CalloutCalendar
          {...props.calloutCalendarProps}
          dataAutomationId={props.dataAutomationId}
        />
      </div>
    </div>
  );
};

export const CalendarDropdown = withStyles(calendarDropdownStyles)(
  CalendarDropdownUnstyled
) as React.FC<CalendarDropdownProps>;
