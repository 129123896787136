import { MediumIcon } from 'components/ions/Icons';
import { TextBody, TextBodySmall } from 'components/ions/Text';
import { mergeClassNames } from 'components/utilities';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  container: {
    display: 'flex',
  },
  description: {
    color: theme.palette.textTertiary,
  },
  icon: {
    paddingTop: 2.4,
  },
  text: {
    paddingLeft: 8,
    '& > *': {
      display: 'block',
    },
  },
});
const warningStyles = (theme: ThemeProps) => ({
  warning: {
    color: theme.palette.warningText,
  },
});
const errorStyles = (theme: ThemeProps) => ({
  error: {
    color: theme.palette.dangerText,
  },
});
const approveStyles = (theme: ThemeProps) => ({
  approve: {
    color: theme.palette.approveText,
  },
});

interface MessagesProps {
  addClass?: string;
  addContainerClass?: string;
  mainMessage: string;
  dataAutomationId?: string;
}

interface MessageProps extends MessagesProps {
  iconName: string;
}

type Props = MessageProps & WithStyles<typeof styles>;
const MessageUnstyled: React.FC<Props> = props => {
  const { classes } = props;

  return (
    <span
      className={mergeClassNames([classes.container, props.addContainerClass])}
      data-automation-id={props.dataAutomationId}
    >
      <MediumIcon addClass={`${classes.icon} ${props.addClass}`} iconName={props.iconName} />
      <span className={classes.text}>
        <TextBody addClass={props.addClass}>{props.mainMessage}</TextBody>
        <TextBodySmall addClass={classes.description}>{props.children}</TextBodySmall>
      </span>
    </span>
  );
};
const Message = withStyles(styles)(MessageUnstyled) as React.FC<MessageProps>;

type WarningMessageProps = MessagesProps & WithStyles<typeof warningStyles>;
const WarningMessageUnstyled: React.FC<WarningMessageProps> = props => {
  const { classes, addClass, ...rest } = props;
  return <Message addClass={`${classes.warning} ${addClass}`} iconName="Warning" {...rest} />;
};

type ErrorMessageProps = MessagesProps & WithStyles<typeof errorStyles>;
const ErrorMessageUnstyled: React.FC<ErrorMessageProps> = props => {
  const { classes, addClass, ...rest } = props;
  return <Message addClass={`${classes.error} ${addClass}`} iconName="ErrorBadge" {...rest} />;
};

type ApproveMessageProps = MessagesProps & WithStyles<typeof approveStyles>;
const ApproveMessageUnstyled: React.FC<ApproveMessageProps> = props => {
  const { classes, addClass, ...rest } = props;
  return <Message addClass={`${classes.approve} ${addClass}`} iconName="CheckMark" {...rest} />;
};

export const WarningMessage = withStyles(warningStyles)(WarningMessageUnstyled) as React.FC<
  MessagesProps
>;
export const ErrorMessage = withStyles(errorStyles)(ErrorMessageUnstyled) as React.FC<
  MessagesProps
>;
export const ApproveMessage = withStyles(approveStyles)(ApproveMessageUnstyled) as React.FC<
  MessagesProps
>;
