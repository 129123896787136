import { PanelAtom } from 'components/atoms/Panel';
import { IPanelProps } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => {
  return {
    panel: {
      background: theme.palette.backgroundStandout,
    },
  };
};

type Props = IPanelProps & WithStyles<typeof styles>;

export const PanelUnstyled = (props: Props) => {
  const { classes, ...panelProps } = props;
  return <PanelAtom styles={{ main: classes.panel }} {...panelProps} />;
};

export const Panel = withStyles(styles)(PanelUnstyled) as React.FC<IPanelProps>;
