import gql from 'graphql-tag';

import { AddressFragment } from '../../fragments';

/** Query to search for tenant for Tenant dialog */
export const GetTenant = gql`
  query getTenant($tenant: String!) {
    getTenant(tenant: $tenant) {
      tenantId
      tenantType
      tenantDisplayName
      account {
        id
        description
        primaryTenantId
        tenants {
          tenantId
          tenantName
        }
        assets {
          subscriptionId
        }
      }
      organizations {
        edges {
          node {
            id
            address {
              ...addressFragment
            }
            lastInvoiceDate
            name
            tradeName
            type
            version
          }
        }
      }
    }
  }
  ${AddressFragment}
`;

/** Query to verify invited user is valid for WorkAccount and SignupEmail dialogs */
export const VerifyInvitedUser = gql`
  query verifyInvitedUser($input: InvitedUserInput!) {
    verifyInvitedUser(input: $input)
  }
`;

/** Query to validate address for Address dialog */
export const ValidateAddress = gql`
  query validateAddress($input: AddressInput!) {
    validateAddress(input: $input) {
      status
      errorFields
      suggestedAddress {
        ...addressFragment
      }
    }
  }
  ${AddressFragment}
`;

/** Query to get Market Info for Address and Invoice Language dialogs */
export const GetMarketInfoForOrgWizard = gql`
  query getMarketInfoForOrgWizard($market: Market!) {
    getMarketInfo(market: $market) {
      code
      display
      invoiceLanguages {
        gqlCode
        code
        display
        twoLetterCode
      }
      regions {
        code
        display
      }
      addressConfiguration {
        fieldGroupInOrder
        region {
          display
          type
        }
      }
    }
  }
`;
