import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { getExchangeRateDate } from 'features-apollo/quote/selectors/quote';
import { isQuoteHRDD } from 'features-apollo/selectors';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import {
  DiscountLineItem,
  MonetaryLineItem,
  NationalCloud,
  PriceAdjustmentType,
  PurchaseLineItem,
} from 'generated/graphql';
import React, { useContext } from 'react';
import { oc } from 'ts-optchain';

import { isQuoteLegacy } from '../../PropertySheets/Customer/selectors';
import { ProductListConnected, ProductListProps } from './ProductList';
import { getExchangeRate } from './utils';

interface ProductListContainerProps {
  /**
   * Use to get quote's line items
   */
  quoteId: string;
  /**
   * Detects is draggind event is happening.
   * TODO: jepagan - check if this can be detect independently from list component.
   */
  isDragEvent?: boolean;
  nationalCloud?: NationalCloud;
}

/**
 * Gets data needed for Product List
 *
 * @param props required information to get data
 * @returns Product List component
 */
export const ProductListContainer: React.FC<ProductListContainerProps> = props => {
  const { activeQuote: quote, loading, availableMutations, availableMutationsStatus } = useContext(
    ActiveQuoteContext
  );

  const productListProps: ProductListProps = {
    productsLineItems: [],
    sharedDiscountsLineItems: [],
    quoteId: props.quoteId,
    billingCurrency: DEFAULT_CURRENCY,
    totalPrice: 0,
    isQuoteReadOnly: false,
    isQuoteHRDD: false,
    isQuoteLegacy: false,
    loading,
    canShareLeadDiscounts: quote ? quote.canShareLeadDiscounts : false,
    parentOrganizationName:
      quote && quote.leadOrganization ? quote.leadOrganization.name : undefined,
    onDelete: availableMutations && availableMutations.removeLineItems,
    updateQuantity: availableMutations && availableMutations.updateQuantity,
    onAddSharedDiscounts: availableMutations && availableMutations.addSharedDiscounts,
    addLineItemStatus: availableMutationsStatus && availableMutationsStatus.addLineItems,
  };

  if (quote) {
    productListProps.billingCurrency = quote.billingCurrency;
    productListProps.totalPrice = quote.totalPrice;
    productListProps.isQuoteReadOnly = quote.readOnly;

    productListProps.exchangeDate = getExchangeRateDate(quote);
    productListProps.exchangeRate = getExchangeRate(quote.lineItems);
    productListProps.isQuoteLegacy = isQuoteLegacy(quote);
    productListProps.isQuoteHRDD = isQuoteHRDD(quote);

    // TODO: jepagan - revisit when data is available
    // productListProps.sharedDiscountsCount = quote.sharedDiscounts.length;

    if (quote.lineItems) {
      const filteredLineItems = props.nationalCloud
        ? quote.lineItems.filter(
            lineItem => lineItem.catalogContext.nationalCloud === props.nationalCloud
          )
        : quote.lineItems;
      filteredLineItems.forEach(lineItem => {
        // Work-around to access typename field on interface type
        // more information: https://github.com/dotansimha/graphql-code-generator/issues/3417
        // @ts-ignore
        switch (lineItem.__typename) {
          case 'PurchaseLineItem':
            productListProps.productsLineItems.push(lineItem as PurchaseLineItem);
            break;
          case 'MonetaryLineItem':
            if (!(lineItem as MonetaryLineItem).isTerm)
              productListProps.productsLineItems.push(lineItem as PurchaseLineItem);
            break;
          case 'DiscountLineItem':
            const discountLineItem = lineItem as DiscountLineItem;

            if (
              oc(discountLineItem).discount.priceAdjustmentType() === PriceAdjustmentType.Extend
            ) {
              productListProps.sharedDiscountsLineItems.push(discountLineItem);
            } else {
              productListProps.productsLineItems.push(discountLineItem);
            }
            break;
        }
      });
    }
  }

  return <ProductListConnected {...productListProps} dndEnabled isDragEvent={props.isDragEvent} />;
};
