import { useDebounce } from 'components/utilities/debounce';
import React from 'react';

export const useLoadingDebounce = (
  onLoadingChanged: (isLoading: boolean) => void,
  loadingDebounceDelay?: number
) => {
  const done = React.useRef<boolean>();
  const loadingDebounce$ = useDebounce(loadingDebounceDelay || 0);

  const callback = React.useCallback(
    async <T>(promiseWrapper: () => Promise<T>) => {
      loadingDebounce$.next(() => {
        if (!done.current) {
          onLoadingChanged(true);
        }
      });

      try {
        return await promiseWrapper();
      } finally {
        done.current = true;
        onLoadingChanged(false);
      }
    },
    [loadingDebounce$, onLoadingChanged]
  );

  return callback;
};
