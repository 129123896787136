import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { AddOrganization } from 'features-apollo/quote/components/PropertySheets/Customer/queries';
import {
  CustomerLoading,
  NoCrmLead,
  NoOrganization,
  OrganizationNotFound,
  OrganizationInformation,
} from 'features-apollo/quote/components/PropertySheets/Customer/states';
import { PropertySheetLayout } from 'features-apollo/quote/components/PropertySheets/Layout';
import {
  InvitedUserSimple,
  OrganizationSimple,
  QuoteMutationInput,
  SelectOrganizationInput,
} from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useMutation } from '@apollo/react-hooks';

export interface CustomerForDirectProps {
  quoteMutationInput: QuoteMutationInput;
  isQuoteLegacy: boolean;
  loading: boolean;
  readOnly: boolean;
  crmID?: string;
  invitedUser?: InvitedUserSimple | null;
  leadOrganizationName?: string;
  organization?: OrganizationSimple;
  salesAccountTPID?: string;
  salesAccountName?: string;
  /**
   * Whether or not customer's phone number and email can be updated.
   */
  contactInformationEditable?: boolean;
}

const mapStateToProps = () => {
  return {};
};

type Props = CustomerForDirectProps & ReturnType<typeof mapStateToProps>;

export const CustomerForDirectDisconnected: React.FC<Props> = props => {
  const { quoteMutationInput } = props;
  const { t } = useTranslation();

  const [selectOrganization] = useMutation(AddOrganization, {
    update(cache, { data: { addOrganization } }) {
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: quoteMutationInput.id },
        data: { getQuote: addOrganization },
      });
    },
  });

  // Customer-Commercial motion
  const onClickSelectOrganization = (organizationId: string, accountId: string) => {
    const request: SelectOrganizationInput = {
      quote: quoteMutationInput,
      accountId,
      organizationId,
    };
    selectOrganization({
      variables: { input: request },
    });
  };

  let content;
  const organizationNotFoundErrorMessage = <OrganizationNotFound />;

  const organizationInfo = props.organization && (
    <OrganizationInformation
      contactInformationEditable={props.contactInformationEditable}
      invitedUser={props.invitedUser}
      isQuoteLegacy={props.isQuoteLegacy}
      leadOrganizationName={props.leadOrganizationName}
      organization={props.organization}
      quoteMutationInput={quoteMutationInput}
      readOnly={props.readOnly}
      salesAccountTPID={props.salesAccountTPID}
    />
  );
  //TODO:kaderbez if needsFullyLoadedCustomerProperties show
  // <OrganizationInformationWithBillingProfiles
  //     contactInformationEditable={props.contactInformationEditable}
  //     invitedUser={props.invitedUser}
  //     isQuoteLegacy={props.isQuoteLegacy}
  //     leadOrganizationName={props.leadOrganizationName}
  //     organization={props.organization}
  //     quoteMutationInput={quoteMutationInput}
  //     readOnly={props.readOnly}
  //     salesAccountTPID={props.salesAccountTPID}
  //     specificScenario={TenantScenario.billingAccountProfile}
  //   />

  if (props.loading) {
    content = <CustomerLoading />;
  } else if (props.isQuoteLegacy) {
    content = organizationInfo || organizationNotFoundErrorMessage;
  } else if (organizationInfo) {
    content = organizationInfo;
  } else if (!props.crmID) {
    content = <NoCrmLead quoteId={quoteMutationInput.id} readOnly={props.readOnly} />;
  } else {
    content = (
      <NoOrganization
        customerName={props.salesAccountName}
        readOnly={props.readOnly}
        salesAccountName={props.salesAccountName}
        onSelectOrganization={onClickSelectOrganization}
      />
    );
  }

  return (
    <PropertySheetLayout
      contentList={[
        {
          leftPaneTitle: t('quote::Customer'),
          content,
        },
      ]}
    />
  );
};

export const CustomerForDirect = connect(mapStateToProps)(CustomerForDirectDisconnected);
