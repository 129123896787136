import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => ({
  addAdditionalSignatory: {
    color: theme.palette.secondary,
    paddingLeft: 0,
    '& i': {
      marginLeft: 0,
      color: theme.palette.secondary,
    },
  },
  calendar: {
    '& button': {
      width: '100%',
      position: 'relative',
      top: 8.5,
      '& .ms-Button-textContainer': {
        textAlign: 'left',
      },
    },
  },
  calendarNoDate: {
    '& button': {
      width: '100%',
      position: 'relative',
      top: 8.5,
      '& .ms-Button-textContainer': {
        textAlign: 'left',
      },
      '& .ms-Button-label': {
        fontStyle: theme.fonts.fontStyles.italic,
        color: theme.palette.textTertiary,
      },
    },
  },
  dateSigned: {
    top: 6,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dateSignedText: {
    fontWeight: theme.fonts.fontWeights.semiBold,
    '& + span': {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
  },

  signatoryHeading: {
    fontWeight: theme.fonts.fontWeights.regular,
    color: theme.palette.textTertiary,
  },
  signatoryInfoSection: {
    display: 'flex',
  },
  signatorySection: {
    paddingTop: 20,
  },
  removeSignatory: {
    paddingLeft: 14,
    fontSize: theme.fonts.fontSizes.medium,
  },
  textInputContainer: {
    marginRight: 20,
    height: 67,
    flex: 1,
    '& label': {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
  },
  textInputContainerEmail: {
    marginRight: 20,
    height: 67,
    flex: 2,
    '& label': {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
  },
});
