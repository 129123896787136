import { ThemeProps } from 'styles/theme/themes';

export const finderListStyles = (theme: ThemeProps) => ({
  groupMenu: {
    height: 12,
    minWidth: 'unset',
    padding: '11px 6px 19px',
    width: 'auto',
    '& i': {
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondaryActive,
    },
  },
  header: {
    userSelect: 'none',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 8,
  },
  headerTitle: {
    flex: 1,
  },
  iconButton: {
    backgroundColor: theme.palette.backgroundStandout,
    border: `1px solid ${theme.palette.backgroundStandout}`,
    borderRadius: 2,
    '& i': {
      color: theme.palette.textTertiary,
      fontSize: theme.fonts.fontSizes.medium,
    },
    '&:hover': {
      borderColor: theme.palette.primary,
    },
    '&:focus, &:hover': {
      backgroundColor: theme.palette.backgroundStandout,
    },
  },
  itemsContainer: {
    display: 'flex',
    position: 'relative',
    marginBottom: 3,
    '&:focus': {
      outlineColor: theme.palette.text,
    },
  },
  chevron: {
    paddingTop: 3,
  },
  messages: {
    fontStyle: 'italic',
  },
  secondaryMessage: {
    color: theme.palette.textTertiary,
    display: 'block',
    paddingTop: 18,
  },
  favoritesIconStyle: {
    '&, &:hover, &:focus': {
      '& i ': { color: theme.palette.lightColor },
    },
  },
  watermark: {
    fontStyle: 'italic',
    color: theme.palette.textTertiary,
    display: 'block',
    paddingBottom: 18,
  },
});

export const getListStyle = (theme: ThemeProps) => {
  return {
    background: theme.palette.transparent,
    width: '100%',
  };
};
