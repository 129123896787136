import { TestHeader } from 'services/types';

export interface Tenant {
  externalId: string;
  isPrimaryTenant?: boolean;
}

export interface AddTenantRequest {
  accountId: string;
  tenant: Tenant;
}

export interface Account {
  id: string;
  description?: string;
  externalIds: string[];
  purchaseMotion: PurchaseMotion | null;
  cloudScope: string;
  testHeader: TestHeader | null;
  primaryTenantId: string;
  primaryOrganizationId: string;
  selfUrl: string;
  version: number;
  createdTimestamp: Date;
  updatedTimestamp: Date;
}

export enum PurchaseMotion {
  CustomerCommerce = 'customer_commerce',
  PartnerCommerce = 'partner_commerce',
}
