import { useEffect } from 'react';

export const useKey = (keys: string[] | string, callback: (key?: string) => void) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const currentKey = event.key;
      if (Array.isArray(keys)) {
        if (keys.find(key => key === currentKey)) {
          callback(currentKey);
        }
      }
      if (keys === currentKey) {
        callback(currentKey);
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [keys, callback]);
};
