import { ThemeProps } from 'styles';

export const DetailsStyles = (theme: ThemeProps) => {
  return {
    address: { marginTop: 5 },
    approvalContainer: { marginTop: 32 },
    approvalHeader: { fontWeight: theme.fonts.fontWeights.semiBold },
    description: {
      paddingTop: 8,
    },
    agreementDescription: {
      paddingTop: 8,
      display: 'block',
    },
    animateIn: {
      maxWidth: 0,
      opacity: 0,
      overflow: 'hidden',
      transition: 'all 600ms',
      whiteSpace: 'normal',
      '&.has-content': {
        maxWidth: 400,
        opacity: 100,
      },
    },
    detailsContainer: {
      padding: '32px 24px',
    },
    semiBoldText: {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    partnerContainer: { marginBottom: 25 },
    ecifLabels: { color: theme.palette.textTertiary },
    heading: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    doubleHeading: {
      '& > :not(:first-child)': {
        marginTop: 24,
      },
    },
    sectionRow: { display: 'flex', flexDirection: 'column', width: '100%', marginTop: 25 },
    detailsPaneSection: { display: 'flex', flexDirection: 'column', width: '100%', marginTop: 16 },
    innerRow: { display: 'flex', flexDirection: 'column', marginTop: 4 },
    discountRow: { display: 'flex', width: '100%', marginTop: 12 },
    flexCol: { flexDirection: 'column' },
    label: { minWidth: 50, marginRight: 20, lineHeight: 1.1 },
    listRow: { display: 'flex', flexDirection: 'column', lineHeight: 1.6 },
    shortText: { flex: 1 },
    link: { '& *': { fontSize: theme.fonts.fontSizes.small } },
    table: {
      borderSpacing: 0,
      '& tr > td:first-of-type': {
        paddingRight: 12,
      },
      '& tr > td:last-of-type': {
        paddingRight: 0,
      },
      '& td': {
        minWidth: 75,
        paddingTop: 0,
        paddingBottom: 4,
        paddingLeft: 0,
        verticalAlign: 'text-top',
      },
    },
    companyName: {
      overflowWrap: 'break-word',
    },
    locationsContainer: { display: 'flex', flexDirection: 'row', width: '100%', marginTop: 8 },

    servicesNone: { fontStyle: 'italic' },
    sectionDetails: { paddingTop: 8 },
    sections: { paddingTop: 12 },
    servicesListTable: {
      width: 248,
      borderSpacing: 0,
      '& *': {
        borderSpacing: 0,
      },
      // styling table rows and cells
      '& tr:not(:last-child) td:last-child': { textAlign: 'right', width: 64 },
      '& tr:not(:nth-last-child(2)):not(:last-child)': {
        '& td:first-child': { padding: 0 },
        '& td:last-child': { padding: '0 22px 0 0' },
      },
      '& tr:nth-last-child(2)': {
        '& td:first-child': { padding: '0 0 12px 0' },
        '& td:last-child': { padding: '0 22px 12px 0' },
      },
      '& tr:last-child td:last-child': {
        borderTop: `1px solid ${theme.palette.border}`,
        padding: '2px 0 0 0',
        textAlign: 'left',
        whiteSpace: 'nowrap',
      },
    },
    list: {
      paddingLeft: 16,
      marginTop: 3,
      marginBottom: 8,
    },
    otherProductsList: {
      marginTop: 16,
    },
    bulkDiscountLink: {
      marginTop: 4,
    },
    dateSection: {
      paddingTop: 12,
    },
    dateSubsection: {
      display: 'flex',
      paddingTop: 4,
    },
    participantInfo: {
      display: 'block',
      width: 135,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    participantSection: {
      display: 'flex',
      paddingTop: 16,
    },
    participantLabel: {
      flex: 1,
      color: theme.palette.textTertiary,
    },
    participantField: {
      flex: 1,
      width: 135,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };
};
