import { sharedStyles, ThemeProps } from 'styles';

export const enrollmentSetStartDateDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  textPadding: {
    paddingTop: 8,
    paddingBottom: 15,
    display: 'block',
  },
  actionTextPadding: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'block',
  },
});
