import { ButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { infoCalloutButtonStyles } from './InfoCalloutButton.styles';

type Props = ButtonSharedProps & WithStyles<typeof infoCalloutButtonStyles>;

const InfoCalloutButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <ButtonAtom
      addClass={mergeClassNames([props.classes.button, props.addClass])}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={props.iconName ? { iconName: props.iconName, id: props.iconId } : undefined}
      styles={{ icon: props.classes.icon, ...props.styles }}
      onClick={props.onClick}
    />
  );
};

export const InfoCalloutButton = withStyles(infoCalloutButtonStyles)(
  InfoCalloutButtonUnstyled
) as React.FC<ButtonSharedProps>;
