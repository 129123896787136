import { LinkButton } from 'components/ions';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { filteredSkusRawToFilteredSkus } from 'features-apollo/quote/utils';
import { filterAndCastMaybeArray } from 'features-apollo/utils';
import { getFlightIsEnabled } from 'features/app/selectors';
import { setConfigCardOpenGQL } from 'features/proposal/actions';
import {
  AgreementType,
  CatalogAction,
  Currency,
  DiscountLineItem,
  KeyNullableValues,
  MonetaryLineItem,
  PriceAdjustmentType,
  ProductFamily,
  PurchaseLineItem,
  Sku,
} from 'generated/graphql';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { configCardIsOpen } from '../../Lists/ProductList/selectors';
import { FallbackConfigurationCard } from '../Cards/FallbackConfigurationCard/FallbackConfigurationCard';
import {
  getDurationFiltersThatAreNotAssociatedWithConsume,
  MetersConfigurationCard,
  readInitialValues,
} from '../Cards/MetersConfigurationCard/MetersConfigurationCard';
import { getSummaryTextForMultipleSkus } from '../Cards/MetersConfigurationCard/utils';
import { ModernOfficeConfigurationCard } from '../Cards/ModernOfficeConfigurationCard/ModernOfficeConfigurationCard';
// import { ModernOfficeConfigurationCard } from '../Cards/ModernOfficeConfigurationCard/ModernOfficeConfigurationCard';
import { SimpleSkuPickerConfigurationCard } from '../Cards/SimpleSkuPickerConfigurationCard/SimpleSkuPickerConfigurationCard';
import {
  standardSupportSku,
  TermsConfigurationCard,
} from '../Cards/TermsConfigurationCard/TermsConfigurationCard';
import { FilterNames } from '../Cards/types';
import {
  MonetaryConfigurationCard,
  readBoundaries,
} from '../MonetaryConfigurationCard/MonetaryConfigurationCard';
import { ConfigureCardCommonButtonProps } from './types';
import { filteredSkuToSkus, getLinkButtonDisplayText } from './utils';

export enum CardType {
  Fallback,
  Meters,
  ModernOffice,
  ModernOfficeOld,
  SimpleSkuPicker,
  Terms,
  Monetary,
  Sap,
}

export enum AgreementCardType {
  UploadAgreement,
  AddSignatories,
}

export interface ConfigCardButtonProps extends ConfigureCardCommonButtonProps {
  /**
   * Identifies line item in the quote's line items' list.
   *
   * @type {string}
   * @memberof ConfigCardButtonProps
   */
  lineItem: PurchaseLineItem | MonetaryLineItem | DiscountLineItem;
  /**
   *  Enables the configure button
   */
  isConfigurable?: boolean;
  /**
   *  Tells if the line item has a discount.
   *
   * @type {boolean}
   * @memberof ConfigCardButtonProps
   */
  alreadyHasDiscount?: boolean;
}

const styles = (theme: ThemeProps) => ({
  callout: {
    position: 'absolute',
    left: 30,
    top: 10,
  },
  container: {
    position: 'relative',
  },
  button: {
    // maxWidth: (props: CalloutCardLinkProps) => props.maxWidth,
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:disabled': {
      color: `${theme.palette.textTertiary} !important`,
    },
    '&:hover:disabled': {
      color: theme.palette.textTertiary,
    },
  },
});

const mapStateToProps = (state: RootState) => {
  return {
    allowUnspecifiedOptionInLocationFilters: getFlightIsEnabled(
      state,
      Flight.allowUnspecifiedOptionInLocationFilters
    ),

    isModernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
    productConfigCardIsOpen: configCardIsOpen(state),
  };
};

const dispatchProps = {
  setProductCardOpen: setConfigCardOpenGQL,
};

const getCardType = (
  skus: Sku[],
  productType: string,
  productFamily: string,
  __typename?: string,
  hasFilters?: boolean,
  isModernOfficeEnabled?: boolean
) => {
  const hasTerms = skus.some(sku =>
    sku.availabilities.some(availability => availability.terms && availability.terms.length)
  );
  const hasMeters = skus.some(sku =>
    sku.availabilities.some(availability => !!availability.meterType)
  );

  const isSupportOffer = productType.toLowerCase() === standardSupportSku.productType;
  if (isSupportOffer) {
    return CardType.Terms;
  }

  if (__typename === 'MonetaryLineItem') {
    return CardType.Monetary;
  }

  if (productFamily === ProductFamily.Passes) {
    return isModernOfficeEnabled ? CardType.ModernOffice : CardType.ModernOfficeOld;
  }

  if (hasMeters && hasFilters) {
    return CardType.Meters;
  }

  if (hasTerms) {
    return CardType.Terms;
  }

  if (!hasTerms) {
    return CardType.SimpleSkuPicker;
  }

  return CardType.Fallback;
};
const getFilterValues = (key: string, filters?: KeyNullableValues[]): string[] => {
  if (!filters) {
    return [];
  }
  const searchedFilter = filters.find(filter => filter.key === key);
  const values = searchedFilter ? searchedFilter.values : [];
  return filterAndCastMaybeArray(values) || [];
};

type Props = ConfigCardButtonProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

// TODO: jepagan - complete button implementation
const ConfigCardButtonUnstyled: React.FC<Props> = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { lineItem, alreadyHasDiscount, isModernOfficeEnabled } = props;
  // TODO; consider refactor, as the button should be able to display the current configuration even if active quote context fails.
  // If active quote context fails, the button should just be disabled and still display the current configuration
  const { activeQuote } = React.useContext(ActiveQuoteContext);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const configuration = props.configuration && props.configuration.trim();
  if (!lineItem.product || !activeQuote) {
    return null;
  }

  const skus = lineItem.product
    ? filteredSkuToSkus(filteredSkusRawToFilteredSkus(lineItem.product.filteredSkusRaw))
    : [];
  const filters = oc(lineItem).product.filters([]);
  const cardType = getCardType(
    skus,
    lineItem.product.productType,
    lineItem.product.productFamily,
    lineItem.__typename,
    !!filters.length,
    isModernOfficeEnabled
  );
  const etag = activeQuote.etag;
  const autoRenew = !!(lineItem.__typename === 'PurchaseLineItem' && lineItem.isAutoRenew);
  //FIXME:  remove this when the gql types this as enum
  const currency = activeQuote.billingCurrency as Currency;
  const renderCard = () => {
    if (!lineItem.product || !activeQuote) {
      return null;
    }
    const closeCard = () => {
      setIsOpen(false);
      props.setProductCardOpen(false);
    };
    const product = lineItem.product;
    switch (cardType) {
      case CardType.SimpleSkuPicker: {
        const skuId = oc(lineItem).sku.skuId();
        const initialValues = skuId ? { skuId, autoRenew } : undefined;
        return (
          <SimpleSkuPickerConfigurationCard
            alreadyHasDiscount={alreadyHasDiscount}
            catalogContext={lineItem.catalogContext}
            currency={currency}
            initialValues={initialValues}
            lineItemId={lineItem.id}
            productId={lineItem.product.id}
            productTitle={product.title}
            quoteMutationInput={{ etag, id: props.quoteId }}
            readOnly={activeQuote.readOnly}
            skus={skus}
            target={containerRef}
            onDismiss={closeCard}
          />
        );
      }
      case CardType.Terms: {
        const skuId = oc(lineItem).sku.skuId();
        const termId = oc(lineItem).term.termId();
        const initialValues = skuId && termId ? { skuId, termId, autoRenew } : undefined;
        return (
          <TermsConfigurationCard
            alreadyHasDiscount={alreadyHasDiscount}
            catalogContext={lineItem.catalogContext}
            currency={currency}
            initialValues={initialValues}
            lineItemId={lineItem.id}
            productId={lineItem.product.id}
            productTitle={product.title}
            productType={product.productType}
            quote={{ etag, id: props.quoteId }}
            readOnly={activeQuote.readOnly}
            skus={skus}
            target={containerRef}
            onDismiss={closeCard}
          />
        );
      }

      case CardType.Monetary: {
        const duration =
          lineItem.__typename === 'MonetaryLineItem' &&
          lineItem.duration &&
          lineItem.duration.__typename === 'Duration' &&
          lineItem.duration.duration;
        const amount = lineItem.__typename === 'MonetaryLineItem' && lineItem.purchaseTermUnits;
        const reason = lineItem.__typename === 'MonetaryLineItem' && lineItem.reason;
        const reasons = product.reasons && product.reasons.length ? product.reasons : undefined;
        return (
          <MonetaryConfigurationCard
            boundaries={readBoundaries(lineItem)}
            currency={currency}
            durations={lineItem.product.durations || []}
            initialValues={{
              amount: amount || undefined,
              goodFor: duration || undefined,
              reason: reason || undefined,
            }}
            isReadOnly={activeQuote.readOnly}
            isTerm={false}
            lineItemId={lineItem.id}
            productTitle={product.title}
            quote={{ etag, id: props.quoteId }}
            reasons={reasons}
            startCondition={product.startCondition}
            target={containerRef}
            onDismiss={closeCard}
          />
        );
      }

      case CardType.ModernOffice: {
        const skuId = oc(lineItem).sku.skuId();
        const termId = oc(lineItem).term.termId();
        //FIXME: add the start date
        const initialValues = skuId && termId ? { skuId, autoRenew, termId: termId } : undefined;
        return (
          <ModernOfficeConfigurationCard
            alreadyHasDiscount={alreadyHasDiscount}
            catalogContext={lineItem.catalogContext}
            currency={currency}
            initialValues={initialValues}
            lineItemId={lineItem.id}
            productId={product.id}
            productTitle={product.title}
            quote={{ etag, id: props.quoteId }}
            readOnly={activeQuote.readOnly}
            skus={skus}
            target={containerRef}
            onDismiss={closeCard}
          />
        );
      }

      case CardType.ModernOfficeOld: {
        const skuId = oc(lineItem).sku.skuId();
        const initialValues = skuId ? { skuId, autoRenew } : undefined;
        return (
          <SimpleSkuPickerConfigurationCard
            alreadyHasDiscount={alreadyHasDiscount}
            catalogContext={lineItem.catalogContext}
            currency={currency}
            initialValues={initialValues}
            //TODO eventually take this out when we enable modern office
            isModernOffice={true}
            isTerm={false}
            lineItemId={lineItem.id}
            productId={lineItem.product.id}
            productTitle={product.title}
            quoteMutationInput={{ etag, id: props.quoteId }}
            readOnly={activeQuote.readOnly}
            skus={skus}
            target={containerRef}
            onDismiss={closeCard}
          />
        );
      }

      case CardType.Meters: {
        if (!lineItem.product) {
          return null;
        }
        //TODO: This part is getting crowded a bit and we don't directly test this, I should consider a configcardButton test
        // It seems like it has enough logic to warrant a test file by itself
        const actionFilters = getFilterValues(FilterNames.Action, lineItem.product.filters);
        let locationFilters = getFilterValues(FilterNames.Location, lineItem.product.filters);
        locationFilters = props.allowUnspecifiedOptionInLocationFilters
          ? locationFilters
          : locationFilters.filter(location => !!location);

        const durationFilters = getDurationFiltersThatAreNotAssociatedWithConsume(product);
        const appliedFilters = oc(lineItem).sku.filters([]);
        const conditions =
          lineItem.__typename === 'DiscountLineItem' && oc(lineItem).discount.scope();
        const action = lineItem.__typename === 'DiscountLineItem' && oc(lineItem).discount.action();
        // We need configuration for initial values, we shouldnt care about initial values when there is no configuration
        const initialFilterValues = configuration
          ? readInitialValues(
              !!lineItem.sku,
              appliedFilters,
              conditions || undefined,
              action || undefined
            )
          : undefined;

        let initialValues = undefined;
        if (initialFilterValues && initialFilterValues.locations) {
          initialValues = {
            locations: initialFilterValues.locations,
            duration: initialFilterValues.duration,
            skuId: oc(lineItem).sku.skuId(),
          };
        }
        return (
          <MetersConfigurationCard
            actionFilters={actionFilters}
            allowUnspecifiedOptionInLocationFilters={props.allowUnspecifiedOptionInLocationFilters}
            alreadyHasDiscount={alreadyHasDiscount}
            catalogContext={lineItem.catalogContext}
            currency={currency}
            durationFilters={durationFilters}
            filteredSkus={
              lineItem.product
                ? filteredSkusRawToFilteredSkus(lineItem.product.filteredSkusRaw)
                : []
            }
            initialValues={initialValues}
            isLegacy={activeQuote.agreementType === AgreementType.Legacy}
            lineItemId={lineItem.id}
            locationFilters={locationFilters}
            productId={lineItem.product.id}
            productTitle={product.title}
            quote={{ etag, id: props.quoteId }}
            readOnly={activeQuote.readOnly}
            target={containerRef}
            onDismiss={closeCard}
          />
        );
      }

      default: {
        return (
          <FallbackConfigurationCard
            alreadyHasDiscount={alreadyHasDiscount}
            catalogContext={lineItem.catalogContext}
            currency={currency}
            lineItemId={lineItem.id}
            productId={lineItem.product.id}
            productTitle={product.title}
            quote={{ etag, id: props.quoteId }}
            readOnly={activeQuote.readOnly}
            skus={skus}
            target={containerRef}
            onDismiss={closeCard}
          />
        );
      }
    }
  };
  let buttonText = getLinkButtonDisplayText(
    configuration,
    !!props.isConfigurable,
    lineItem.product.productType.toLowerCase() === 'azureaccesspass',
    lineItem.__typename === 'DiscountLineItem' &&
      oc(lineItem).discount.priceAdjustmentType() === PriceAdjustmentType.Extend
  );
  // This is for the multi sku selections
  if (
    !lineItem.sku &&
    cardType === CardType.Meters &&
    lineItem.__typename === 'DiscountLineItem' &&
    lineItem.discount
  ) {
    const action = lineItem.discount.action || CatalogAction.Purchase;
    buttonText.displayText = getSummaryTextForMultipleSkus(action, lineItem.discount.scope || []);
  }

  //FIXME: think more deeply about the initial approach, we have private SKU's etc we should handle that too
  return (
    <div className={props.classes.container}>
      <LinkButton
        addClass={props.classes.button}
        dataAutomationId="config-card-button"
        disabled={props.disabled || !props.isConfigurable}
        displayText={buttonText.displayText}
        title={buttonText.title}
        onClick={() => {
          setIsOpen(!isOpen);
          props.setProductCardOpen(!isOpen);
        }}
      />
      <span className={props.classes.callout} ref={containerRef} />
      {isOpen && renderCard()}
    </div>
  );
};

const ConfigCardButtonUnconnected = withStyles(styles)(ConfigCardButtonUnstyled) as React.FC<
  ConfigCardButtonProps
>;

export const ConfigCardButton = connect(
  mapStateToProps,
  dispatchProps
)(ConfigCardButtonUnconnected);
