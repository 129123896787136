import { ChoiceGroupAtom, ChoiceGroupAtomProps } from 'components/atoms';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

export interface ChoiceGroupProps extends ChoiceGroupAtomProps {
  /**
   * Align the options vertically or horizontally
   */
  orientation?: 'vertical' | 'horizontal';
  readOnly?: boolean;
  dataAutomationId?: string;
}

const styles = {
  orientation: (props: ChoiceGroupProps) =>
    props.orientation === 'horizontal'
      ? {
          display: 'flex',
          flexDirection: 'row',
          '& > *': {
            marginRight: 20,
          },
        }
      : undefined,
};

type Props = ChoiceGroupProps & WithStyles<typeof styles>;

const ChoiceGroupUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const choiceGroupProps = props as ChoiceGroupAtomProps;

  return <ChoiceGroupAtom styles={{ flexContainer: classes.orientation }} {...choiceGroupProps} />;
};

export const ChoiceGroup = withStyles(styles)(ChoiceGroupUnstyled);
