/* eslint no-useless-computed-key: 0 */
import { RouterState } from 'connected-react-router';
import { Environment as CWAEnvironment } from 'features/app/config/CwaConfig';
import { AppEnvironment } from 'features/app/config/type';
import appReducer from 'features/app/reducer';
import { HelpContent } from 'features/app/types';
import catalogReducer from 'features/catalog/reducer';
import customerReducer from 'features/customer/reducer';
import { Address, CRMLeadType } from 'features/customer/types';
import ldssCustomerReducer from 'features/ldsscustomer/reducer';
import proposalReducer from 'features/proposal/reducer';
import { Currency } from 'features/proposal/supported-currencies';
import { Language } from 'features/proposal/supported-languages';
import { defaultMarket, Market } from 'features/proposal/supported-markets';
import { EnrollmentFieldView } from 'features/proposal/types';
import userReducer from 'features/user/reducer';
import * as React from 'react';
import { Provider } from 'react-redux';
import { MemoryRouter, Route } from 'react-router-dom';
import configureStore from 'redux-mock-store';
import {
  Approval,
  ApprovalLevel,
  ApprovalLevelState,
  ApprovalStatus,
} from 'services/approval/types';
import { Environment } from 'services/billinggroup/config';
import { FieldsTemplate } from 'services/catalog/api';
import { Availability, LocalizedProperty, Product, Sku } from 'services/catalog/types';
import { Opportunity } from 'services/crm/types';
import {
  Address as OrganizationAddress,
  CultureInfo,
  LegalEntity,
  OrganizationState,
  OrganizationSummary,
  OrganizationType,
} from 'services/customer/types';
import { OrganizationSearchResponseItem } from 'services/edge-search/types';
import { Asset } from 'services/edge/types';
import { DefaultFlightMode } from 'services/flights/config';
import { User } from 'services/graph/types';
import {
  LineItem,
  PriceAdjustmentType,
  Proposal,
  ProposalStatus,
  SearchProposalsSortField,
  SearchSortOrder,
  UserGroup,
} from 'services/proposal/types';
import { AadEnvironment } from 'services/utils';
import { AsyncState, RootState } from 'store/types';
import { ThemeKey } from 'styles';

export const USER_EMAIL = 'mockedUserEmail';

// Backstreet Boys: https://en.wikipedia.org/wiki/Backstreet_Boys
export const mockMSContact1 = {
  name: 'Nick Carter',
};
export const mockMSContact2 = {
  name: 'AJ McLean',
};
export const mockMSContact3 = {
  name: 'Kevin Richardson',
};
export const mockMSContact4 = {
  name: 'Brian Littrell',
};
export const mockMSContact5 = {
  name: 'Howie Dorough',
};
export const mockMSContacts = [
  mockMSContact1,
  mockMSContact2,
  mockMSContact3,
  mockMSContact4,
  mockMSContact5,
];
export const mockOpp: Opportunity = {
  AccountId: {
    DisplayValue: 'abc',
    TypeName: 'string',
    Id: 'abc',
  },
  Id: 'abc',
  EnrollmentNumber: '123456789',
  Topic: 'abc',
  ContactId: {
    DisplayValue: 'abc',
    TypeName: 'string',
    Id: 'abc',
  },
  GlobalCrmId: '123',
};
export const mockOppIDOptions = ['abc', '123'];
export const mockMarket: Market = defaultMarket;
export const mockCurrency: Currency = 'USD';
export const mockLanguage: Language = 'en-US';

// https://en.wikipedia.org/wiki/Contoso
export const mockCompany = {
  companyName: 'Contoso',
  streetAddress: '123 Contoso Ave',
  city: 'Fakecity',
  state: 'AB',
  zipCode: '12345',
  country: 'ABC',
  vmLocation: 'US West',
};

export const mockUser = {
  name: 'Ellen Adams',
};

export const literals = {
  a: 'a',
  AzureAccessPass: 'AzureAccessPass',
  AzureSupport: 'AzureSupport',
  AzureMonetaryCredit: 'AzureMonetaryCredit',
  AzureMonetaryCommit: 'AzureMonetaryCommit',
  AzureFamilyDiscount: 'AzureFamilyDiscount',
  Action: 'Action',
  AllLocations: 'All Locations',
  AllReservations: 'All Reservations',
  b: 'b',
  c: 'c',
  Consume: 'Consume',
  description1: 'description 1',
  description2: 'description 2',
  description3: 'description 3',
  Duration: 'Duration',
  Entitlement: 'Entitlement',
  Filter: 'Filter',
  global: 'global',
  isPrivateMarketPlace: 'isPrivateMarketPlace',
  Location: 'Location',
  LocationId: 'LocationId',
  location1: 'location 1',
  location2: 'location 2',
  location3: 'location3',
  NonExisting: 'Non-Existing',
  oneYear: '1 year',
  oneMonth: '1 month',
  Paygo: 'Pay go',
  Price: 'Price',
  Purchase: 'Purchase',
  starSubscription: '* subscription',
  subscription: 'subscription',
  term1: 'term 1',
  Term1: 'Term1',
  term2: 'term 2',
  Term2: 'Term2',
  term3: 'term 3',
  Term3: 'Term3',
  TermDescription: 'TermDescription',
  Test: 'Test',
  test: 'test',
  test1: 'test1',
  test2: 'test2',
  test3: 'test3',
  Test2: 'Test2',
  testa: 'testa',
  testb: 'testb',
  testc: 'testc',
  TestFilter: 'TestFilter',
  Title: 'Title',
  title1: 'title1',
  unit1: 'unit1',
  unit2: 'unit2',
  unit3: 'unit3',
  unit4: 'unit4',
};

export const mockProposal: Proposal = {
  etag: 'abc',
  header: {
    approval: {
      approvalLevels: [],
    },
    soldToCustomerLegalEntity: {
      accountId: '123',
      organizationId: '123',
    },
    assignedToGroup: UserGroup.Field,
    createdApiVersion: '10',
    createdBy: 'mockCreatedBy',
    createdDate: new Date(),
    extendedProperties: {},
    invitedUser: 'mockUser',
    messages: [],
    modifiedApiVersion: '10',
    modifiedBy: 'mockModifiedBy',
    modifiedDate: new Date(),
    expirationDate: new Date(),
    name: 'mockName',
    opportunityId: '123',
    pricingContext: {
      market: 'US',
    },
    readOnly: false,
    status: ProposalStatus.Draft,
    supportedApiVersions: [],
    negotiationReason: null,
    testHeaderData: {
      contact: 'mockContact',
      retention: new Date(),
      scenarios: 'mockScenarios',
    },
    totalPrice: 1,
    transactionType: 'mockTransactionType',
    version: '123',
  },
  id: '123',
  lineItems: [],
  revision: 1,
};

const mockApprover: { emailAddress: string; firstName: string; lastName: string } = {
  emailAddress: 'test@contoso.com',
  firstName: 'firstname',
  lastName: 'lastname',
};

export const mockApprovalLevels: ApprovalLevel[] = [
  {
    policy: 'test',
    level: 'test',
    approvers: [mockApprover],
    order: 1,
    state: ApprovalLevelState.approved,
    autoPopulate: false,
  },
  {
    policy: 'test',
    level: 'test',
    approvers: [mockApprover],
    order: 2,
    state: ApprovalLevelState.rejected,
    autoPopulate: false,
  },
  {
    policy: 'test',
    level: 'test',
    approvers: [mockApprover],
    order: 3,
    state: ApprovalLevelState.submitted,
    autoPopulate: false,
  },
];

export const mockApproval: Approval = {
  id: '123',
  context: {
    contextType: 'mockContextType',
    contextId: '123',
    rulesVersion: 'mockRulesVersion',
    market: {
      hierarchyLevel: 'mockHierarchyLevel',
      marketCode: 'mockMarketCode',
      marketId: '123',
    },
    segment: 'mockSegment',
    header: {
      name: 'mockName',
      quoteId: '123',
    },
  },
  submitter: 'mockSubmitter',
  requiredApprovalLevels: [],
  approvalLevels: [],
  submittedDate: new Date(),
  status: ApprovalStatus.Submitted,
  workFlow: 'mockWorkFlow',
  possibleActions: [],
  expirationDateTimeUtc: new Date(),
  createdDate: new Date(),
  createdBy: 'mockCreatedBy',
  updatedDate: new Date(),
  updatedBy: 'mockUpdatedBy',
  history: [],
};

export const mockAppState: ReturnType<typeof appReducer> = {
  config: {
    approvalHierarchyLevel: 'Country',
    proposalNextExpiryDays: 1,
    suppressedMessages: [] as string[],
    minCreditLineDivisor: 4,
    wholeNumberRegex: /^(\s*|\d+)$/,
  },
  errors: {},
  isCachedFlightsEnabled: false,
  flights: {
    allmarketsforclaims: false,
    allowunspecifiedoptioninlocationfilters: false,
    approvaloverride: false,
    assetmanagement: false,
    azureplannotifications: true,
    breadcrumbs: false,
    consumecataloglistofterms: true,
    csp: false,
    customterms: false,
    cwarenewals: false,
    delaycreditcheck: false,
    demomode: false,
    directfgoe: false,
    directgov: true,
    disablebillingaccountassociation: false,
    disableproductinclusionfilter: false,
    ecif: true,
    enableblendedautosuggest: false,
    enrollmentassembly: false,
    graphqlphase2: false,
    indirectfgoe: false,
    ironcustomterms: false,
    jioascsp: false,
    modernoffice: false,
    msxppe: false,
    noadditionaltenant: false,
    notestheadertesting: false,
    npssurvey: false,
    obfuscatetranslations: false,
    physicalsignature: false,
    pidsearch: false,
    qctransact: false,
    requiredcredit: true,
    safelistedcreditcheck: false,
    showaddons: false,
    showdemomodebutton: false,
    termsFinderSearch: false,
    testflight1: true,
    usnat: false,
  },
  loading: { LOAD_PROPOSAL: false },
  appConfig: {
    aad: AadEnvironment.Prod,
    appInsightsKey: '',
    account: { environment: Environment.Prod, useTestHeader: true },
    accountExtensions: { environment: Environment.Prod, useTestHeader: true },
    addressValidation: {
      environment: Environment.Int,
    },
    agreement: { environment: Environment.Prod, useTestHeader: true },
    app: AppEnvironment.Local,
    approval: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    bd360: {
      environment: Environment.Int,
    },
    billinggroup: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    businessStore: Environment.Int,
    catalog: {
      actionFilter: FieldsTemplate.Details,
      environment: Environment.Prod,
    },
    ccf: {
      environment: Environment.Prod,
    },
    constraints: {
      environment: Environment.Prod,
      useTestHeader: false,
    },
    credit: {
      environment: Environment.Prod,
    },
    crm: {
      environment: Environment.Prod,
    },
    customer: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    cwa: {
      environment: CWAEnvironment.Int,
    },
    edge: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    edgeSearch: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    empowerment: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    externaluser: {
      environment: Environment.Prod,
    },
    flights: {
      environment: Environment.Prod,
      defaultFlightMode: DefaultFlightMode.Prod,
    },
    ldss: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    notifications: {
      environment: Environment.Prod,
    },
    permissions: {
      allOf: [],
      oneOf: ['View', 'Update'],
    },
    pricingscope: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    project: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    proposal: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    purchase: {
      environment: Environment.Prod,
      useTestHeader: true,
    },
    signUp: {
      environment: Environment.Prod,
    },
    translation: {
      pseudoLoc: true,
    },
    userPreferences: { environment: Environment.Prod },
    apollo: { environment: Environment.Prod, useTestHeader: true, useEnvironmentOverrides: false },
  },
  helpContentType: HelpContent.Default,
  isHelpPanelOpen: false,
  helpPanelWidth: 0,
};

export const mockProposalState: ReturnType<typeof proposalReducer> = {
  approvals: {
    entities: {
      indexed: { mockApprovalId: mockApproval },
    },
  },
  empowerments: {},
  credit: {},
  proposals: {
    fragments: {
      indexed: {},
      ordered: [],
    },
    entities: {
      indexed: { '123': mockProposal },
      ordered: [],
    },
  },
  purchaseSummary: {},
  views: {
    openCard: null,
    enrollmentFieldView: EnrollmentFieldView.ComboBox,
    activeId: '123',
    deleteProposal: AsyncState.Unset,
    editor: {
      lineItemPrices: {},
      skuTitles: {},
      productCardIsOpenGQL: false,
      sections: {},
      selectedItems: [],
      lastSelectedItem: '',
      crmIdToAdd: '123',
      sendingEmailNotification: '',
      transactingProposal: '',
      workAccountResults: [],
      addedLineItemCount: 0,
    },
    newProposal: AsyncState.Unset,
    summaryList: {
      search: {
        filter: 'mockFilter',
        sortField: SearchProposalsSortField.AssignedToGroup,
        sortOrder: SearchSortOrder.Ascending,
      },
    },
  },
};

export const mockRouterState: RouterState = {
  action: 'PUSH',
  location: {
    hash: 'mockHash',
    pathname: 'mockPathname',
    search: 'mockSearch',
    state: 'mockState',
  },
};

export const mockGraphUser: User = {
  objectId: '123',
  companyName: 'Company Name',
  displayName: 'Display Name',
  givenName: 'Given Name',
  mail: 'test@contoso.com',
  surname: 'Surname',
  userPrincipalName: 'Principal Name',
};

export const mockUserState: ReturnType<typeof userReducer> = {
  roles: {},
  current: { email: USER_EMAIL },
  msContact: {},
  msContactResults: [mockGraphUser],
  preferences: { eTag: '', theme: ThemeKey.light, viewedInfoDialogs: [] },
};

export const mockProduct: Product = {
  DisplaySkuAvailabilities: [],
  LocalizedProperties: [
    {
      Images: [],
      PublisherName: 'Publisher Name',
      Language: '',
      ProductDescription:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mollis iaculis tortor nec pharetra. Quisque sed massa vel urna venenatis auctor nec vulputate urna. Phasellus finibus nisi in dignissim maximus. Curabitur volutpat sed elit ac dapibus. Nullam sit amet bibendum ligula. Morbi tincidunt quam id massa volutpat, at mollis tortor facilisis. Integer eu mi rutrum, mattis lorem et, placerat nunc. Etiam non condimentum leo, vitae auctor urna. Vestibulum ultricies lobortis neque, sit amet condimentum nisl rhoncus nec. Quisque blandit a quam nec commodo. Suspendisse at egestas sapien.',
      StrippedDescription:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mollis iaculis tortor nec pharetra. Quisque sed massa vel urna venenatis auctor nec vulputate urna. Phasellus finibus nisi in dignissim maximus. Curabitur volutpat sed elit ac dapibus. Nullam sit amet bibendum ligula. Morbi tincidunt quam id massa volutpat, at mollis tortor facilisis. Integer eu mi rutrum, mattis lorem et, placerat nunc. Etiam non condimentum leo, vitae auctor urna. Vestibulum ultricies lobortis neque, sit amet condimentum nisl rhoncus nec. Quisque blandit a quam nec commodo. Suspendisse at egestas sapien.',
      ProductTitle: 'End Customer Investment Funding Terms',
      Markets: [],
    },
  ],
  MarketProperties: [],
  ProductBSchema: '',
  ProductFamily: '',
  ProductId: '123',
  ProductKind: '',
  LastModifiedDate: '',
  ProductASchema: '',
  AlternateIds: [],
  IngestionSource: '',
  IsMicrosoftProduct: false,
  ProductType: '',
  SchemaVersion: '',
  Properties: {},
};

export const mockSku: Sku = {
  LocalizedProperties: [
    {
      SkuTitle: 'Example SKU',
      SkuDescription: '',
      Markets: [],
      Language: '',
    },
  ],
  SkuId: '0001',
  MarketProperties: [{ Markets: ['US'] }],
  ProductId: '',
  Properties: {},
  SkuASchema: '',
  SkuBSchema: '',
  SkuType: '',
  RecurrencePolicy: null,
  LastModifiedDate: '',
};

export const mockAvailability: Availability = {
  Actions: [''],
  AvailabilityASchema: '',
  AvailabilityBSchema: '',
  AvailabilityId: '',
  Conditions: { EndDate: '', StartDate: '', IsPermanent: false, ResourceSetIds: [''] },
  LastModifiedDate: '',
  Markets: [''],
  Properties: {},
  SkuId: '',
  AlternateIds: [''],
  PricingRuleIds: [''],
  RemediationRequired: false,
  DisplayRank: 0,
  ConsumptionUnitType: '',
};

export const mockLocalizedProperties: LocalizedProperty[] = [
  {
    Images: [],
    PublisherName: 'Publisher Name',
    Language: '',
    ProductDescription: '',
    StrippedDescription: '',
    Markets: [],
    ProductTitle: 'Microsoft 365 E1',
  },
];

export const mockAssetProducts: Product[] = [
  {
    ...mockProduct,
    ProductId: '1234',
    DisplaySkuAvailabilities: [
      {
        Sku: {
          ...mockSku,
          LocalizedProperties: {
            ...mockSku.LocalizedProperties,
            [0]: {
              ...mockSku.LocalizedProperties[0],
              SkuTitle: 'Microsoft 365 E1 without Calling Plan',
            },
          },
          Properties: {
            ...mockSku.Properties,
            ClientTransferInstructions: [
              {
                CurrentTermIds: 'abc',
                TransferType: '',
                TransferOptions: [
                  {
                    TransferToBigId: '1234a/123',
                    Rank: 0,
                    TermTransfer: [
                      {
                        TransferToTermUnit: [''],
                        TransferToBillingPeriod: [''],
                        TransferTiming: '',
                      },
                    ],
                  },
                  {
                    TransferToBigId: '1234b/123',
                    Rank: 0,
                    TermTransfer: [
                      {
                        TransferToTermUnit: [''],
                        TransferToBillingPeriod: [''],
                        TransferTiming: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
        Availabilities: [mockAvailability],
      },
    ],
    LocalizedProperties: mockLocalizedProperties,
  },
  {
    ...mockProduct,
    ProductId: '12345',
    DisplaySkuAvailabilities: [
      {
        Sku: mockSku,
        Availabilities: [mockAvailability],
      },
    ],
    LocalizedProperties: {
      ...mockLocalizedProperties,
      [0]: {
        ...mockLocalizedProperties[0],
        ProductTitle: 'Quantifiable Product',
      },
    },
  },
  {
    ...mockProduct,
    ProductId: '123456',
    DisplaySkuAvailabilities: [
      {
        Sku: mockSku,
        Availabilities: [mockAvailability],
      },
    ],
    LocalizedProperties: {
      ...mockLocalizedProperties,
      [0]: {
        ...mockLocalizedProperties[0],
        ProductTitle: 'Static Product',
      },
    },
  },
  {
    ...mockProduct,
    ProductId: '1234a',
    DisplaySkuAvailabilities: [
      {
        Sku: mockSku,
        Availabilities: [mockAvailability],
      },
    ],
    LocalizedProperties: {
      ...mockLocalizedProperties,
      [0]: {
        ...mockLocalizedProperties[0],
        ProductTitle: 'Microsoft 365 E3',
      },
    },
  },
  {
    ...mockProduct,
    ProductId: '1234b',
    DisplaySkuAvailabilities: [
      {
        Sku: mockSku,
        Availabilities: [mockAvailability],
      },
    ],
    LocalizedProperties: {
      ...mockLocalizedProperties,
      [0]: {
        ...mockLocalizedProperties[0],
        ProductTitle: 'Microsoft 365 E5',
      },
    },
  },
];

export const mockCatalogState: ReturnType<typeof catalogReducer> = {
  products: {
    failed: {},
    fragments: {
      indexed: {
        '|agreementType:modern|audience:Direct-Commercial|languages:en-US|market:US|nationalCloud:Global|template:details': {
          '1234': mockAssetProducts[0],
          '12345': mockAssetProducts[1],
          '123456': mockAssetProducts[2],
          '1234a': mockAssetProducts[3],
          '1234b': mockAssetProducts[4],
        },
      },
      ordered: {},
    },
    entities: {
      indexed: {
        '|agreementType:modern|audience:Direct-Commercial|languages:en-US|market:US|nationalCloud:Global|template:details': {
          '123': mockProduct,
        },
      },
      ordered: {},
    },
  },
  recommendations: {
    byChannel: {},
    channelsByType: {},
  },
  search: {
    results: {
      nextSkip: 0,
      products: [],
      productFamiliesWithMoreResults: [],
    },
  },
  filteredFavorites: {
    products: [],
  },
  catalogPage: {
    featured: {},
    negotiatedTerms: {
      Agregations: [],
      HasMorePages: false,
      ProductIds: [],
      Products: [],
      TotalResultCount: 0,
    },
    searchResults: {},
  },
  validations: {
    constraintsResults: {
      productConstraintResults: [
        {
          product: { productId: 'abcd', skuId: '100' },
          constraintResults: [
            {
              maximumQuantity: 0,
              minimumQuantity: 0,
              purchasedQuantity: 0,
              remainingQuantity: 0,
              constraintType: '',
              isValid: false,
              message: '',
            },
          ],
        },
      ],
    },
  },
};

export const mockCustomerAddress: Address = {
  companyName: 'Hidden Spaces Inc.',
  addressLine1: '123 Somewhere Ln',
  addressLine2: '',
  addressLine3: '',
  city: 'Big City',
  region: 'WA',
  postalCode: '98055',
  country: 'United States',
};

export const mockCompanyAddress: OrganizationAddress = {
  ...mockCustomerAddress,
  addressType: OrganizationType.organization,
  region: 'test region',
  postalCode: '12345',
};

export const mockSalesAccountAddress: Address = {
  ...mockCustomerAddress,
};

export const mockOrganizationResponseItem: OrganizationSearchResponseItem = {
  account: {
    id: '1',
    tenants: [],
    version: '1',
  },
  invoice: {
    id: '2',
    lastInvoiceCreationDateTime: '12345',
    version: '2',
  },
  organization: {
    id: '1',
    legalEntity: {
      businessLocation: {
        address: {
          ...mockCustomerAddress,
          phoneNumber: '111111111111',
          postalCode: '12345',
          region: 'test region',
        },
        id: '1',
        name: 'test',
      },
      name: 'test',
      tradeName: 'test',
    },
    organizationType: 'test',
    version: '1',
  },
  purchase: {
    id: '1',
    lastPurchaseCreatedDateTime: '1',
    version: '1',
  },
};

export const mockCultureInfo: CultureInfo = {
  culture: 'en',
  language: 'us',
};

export const mockLegalEntity: LegalEntity = {
  address: {
    ...mockCustomerAddress,
    addressType: OrganizationType.organization,
    country: 'US',
    region: 'test region',
    postalCode: '12345',
  },
  businessLocationId: '123',
  tradeName: undefined,
};

export const mockOrganizationSummary: OrganizationSummary = {
  accountId: '123',
  cultureInfo: mockCultureInfo,
  description: 'This is a description',
  eligibleForAccountPricing: false,
  id: '123',
  legalEntity: mockLegalEntity,
  organizationType: OrganizationType.organization,
  state: OrganizationState.active,
  version: 1,
};

export const mockLdssCustomerState: ReturnType<typeof ldssCustomerReducer> = {
  customerSearch: {
    searchTerm: '',
    exactResponse: [],
    wildcardResponse: [],
    totalResults: 0,
  },
  selectedCustomer: '',
};

export const mockCustomerState: ReturnType<typeof customerReducer> = {
  oneAskResults: [],
  crmContacts: {},
  customPrices: {},
  leadOrganization: {},
  organization: {
    [mockOrganizationSummary.id]: mockOrganizationSummary,
  },
  requireVatId: false,
  accountOrganizations: {},
  customers: {
    fragments: {
      indexed: {},
      ordered: [],
    },
    entities: {
      indexed: {},
      ordered: [],
    },
  },
  organizationSearch: {
    dialogResults: { items: [], totalItems: 0 },
    propertySheetSearchResults: {},
    similarOrganizationResult: null,
  },
  CRMLead: {
    '123': {
      crmId: '123',
      account: {
        DisplayValue: 'test',
        TypeName: 'test',
        Id: '123',
      },
      id: '123',
      entityName: 'test',
      type: CRMLeadType.Opportunity,
      title: 'test',
    },
  },
  salesAccount: {
    '123': {
      MsSalesTpId: '12345',
      id: '',
      Address: mockCustomerAddress,
      CID: '',
      GlobalCrmId: '',
      McApiId: '',
    },
  },
  isStrategicAccount: false,
  account: {},
  enrollment: {},
  project: {},
  modernFootprint: {},
  legacyFootprint: {},
  tenant: {},
  tenantAccounts: {},
  tenantNames: {},
  tenantAdmins: {
    '123': ['abc'],
  },
  tenantRoleAssignedUpns: [],
  signedMCA: {},
  assets: {},
  addressValidated: null,
  hasAvsApiFailure: false,
  finishedUpload: false,
};

export const mockState: RootState = {
  app: mockAppState,
  catalog: mockCatalogState,
  customer: mockCustomerState,
  proposal: mockProposalState,
  router: mockRouterState,
  user: mockUserState,
  ldsscustomer: mockLdssCustomerState,
};

export const mockLineItem: LineItem = {
  id: '123',
  customerIntent: '',
  quantity: 0,
  productIdentifier: {
    productId: '123',
  },
  status: '',
  pricingCurrency: 'USD',
  listPrice: 0,
  discountedPrice: 0,
  chargePrice: {
    proratedPrice: 0,
    price: 0,
  },
  extendedPrice: 0,
  pricingInstruction: {
    productIdentifier: {
      productId: '123',
    },
    organizations: [],
    priceAdjustmentType: PriceAdjustmentType.new,
  },
};

export const mockECIFLineItem: LineItem = {
  ...mockLineItem,
  productIdentifier: {
    productId: '123',
    productType: 'ECIF',
  },
};

export const mockAssetList: Asset[] = [
  {
    accountId: '123',
    assetData: {
      assetType: 'direct',
      futureBillingInfo: {
        billingGroup: {
          accountId: '123',
          id: '',
          organizationId: '',
        },
      },
      productInfo: {
        agreementType: 'modern',
        audience: 'Direct-Commercial',
        availabilityId: '789',
        catalogId: '4',
        languages: 'en-US',
        market: 'US',
        productFamily: 'Azure',
        productId: '1234',
        skuId: '0001',
      },
      quantity: 1,
      startDate: new Date('2020-01-21T00:00:00+00:00'),
      stateInfo: {
        state: 'active',
      },
      termInfo: {
        autoRenew: true,
        endDate: new Date('2020-02-07T00:00:00+00:00'),
        startDate: new Date('2020-01-08T00:00:00+00:00'),
        termId: 'abc',
      },
    },
    attributes: ['futureBilling', 'conversion', 'quantifiable', 'termBased'],
    beneficiaryOrganization: {
      accountId: '123',
      id: '',
    },
    id: '567',
    organizationId: '',
    projectId: '',
    recipientId: '',
  },
  {
    accountId: '123',
    assetData: {
      assetType: '',
      futureBillingInfo: {
        billingGroup: {
          accountId: '12345',
          id: '',
          organizationId: '',
        },
      },
      productInfo: {
        agreementType: 'modern',
        audience: 'Direct-Commercial',
        availabilityId: '123',
        catalogId: '4',
        languages: 'en-US',
        market: 'US',
        productFamily: 'Azure',
        productId: '12345',
        skuId: '0001',
      },
      quantity: 30,
      startDate: new Date('2020-01-08T00:00:00+00:00'),
      stateInfo: {
        state: 'active',
      },
      termInfo: {
        autoRenew: true,
        endDate: new Date('2020-02-07T00:00:00+00:00'),
        startDate: new Date('2019-12-18T00:00:00+00:00'),
        termId: 'abc',
      },
    },
    attributes: ['termBased', 'futureBilling', 'quantifiable'],
    beneficiaryOrganization: {
      accountId: '123',
      id: '',
    },
    id: '345',
    organizationId: '',
    projectId: '',
    recipientId: '',
  },
  {
    accountId: '123',
    assetData: {
      assetType: 'direct',
      futureBillingInfo: {
        billingGroup: {
          accountId: '123',
          id: '',
          organizationId: '',
        },
      },
      productInfo: {
        agreementType: 'modern',
        audience: 'Direct-Commercial',
        availabilityId: '123456',
        catalogId: '4',
        languages: 'en-US',
        market: 'US',
        productFamily: 'Azure',
        productId: '123456',
        skuId: '0001',
      },
      quantity: 1,
      startDate: new Date('2020-01-21T00:00:00+00:00'),
      stateInfo: {
        state: 'active',
      },
    },
    attributes: ['futureBilling', 'ratable'],
    beneficiaryOrganization: {
      accountId: '123',
      id: '',
    },
    id: '123',
    organizationId: '',
    projectId: '',
    recipientId: '',
  },
];

/**
 * Mocks a redux store from given state
 */
export const mockStore = configureStore();

/**
 * MEPLA redux store mocked
 */
export const mockedStore = mockStore(mockState);

export const containerWithRouter = (
  container: JSX.Element,
  store = mockStore(mockState),
  pathname: string = '/',
  pathToMatch: string = '/',
  initialEntries: string[] = [pathname],
  initialIndex: number = 0
) => {
  return (
    <MemoryRouter initialEntries={initialEntries} initialIndex={initialIndex}>
      <Provider store={store}>
        <Route path={pathToMatch} render={() => container} />
      </Provider>
    </MemoryRouter>
  );
};
