import {
  CalloutCard,
  ComboBox,
  InfoButton,
  Label,
  LinkExternal,
  TextBody,
  TextboxStandard,
} from 'components';
import {
  closeConfigCard,
  createLineItemGroupingAsync,
  updateProposalAsync,
} from 'features/proposal/actions';
import {
  ApplyAndAdd,
  ApplyFinanceToLineItem,
  getApplyOptions,
  validApplyTo,
  validTermId,
} from 'features/proposal/components/FinanceTermsCard/FinanceCardBusinessLogic';
import { financingCardStyles } from 'features/proposal/components/FinanceTermsCard/FinancingTermsCard.styles';
import { getTermIdContent } from 'features/proposal/components/SimpleTermsCard/utils';
import {
  getActiveProposal,
  getAllGroupedLineItems,
  getFinanceableLineItems,
  getFinanceApplicableProducts,
  getGroupedLineItems,
  getLineItem,
  getSelectedProject,
  getSkuTitles,
} from 'features/proposal/selectors';
import { isProposalReadOnly, validGuid } from 'features/proposal/utils';
import { DirectionalHint, IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import loggerService from 'services/logger-service';
import { endpoints as proposalServiceEndpoints } from 'services/proposal/config';
import { CreateLineItemGroupRequest, ProposalUpdateRequest } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

export interface FinanceTermsCardProps {
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  name: string;
}

const mapStateToProps = (state: RootState, ownProps: FinanceTermsCardProps) => {
  const proposal = getActiveProposal(state);

  return {
    proposal,
    lineItem: getLineItem(state, ownProps.lineItemId),
    financeProducts: getFinanceApplicableProducts(state, ownProps.lineItemId),
    skuTitleMap: getSkuTitles(state),
    proposalServiceEndpoint: proposalServiceEndpoints[state.app.appConfig.proposal.environment],
    currentSelectedProject: getSelectedProject(state),
    allGroupedLineItems: getAllGroupedLineItems(state),
    quoteReadOnly: isProposalReadOnly(proposal),
  };
};

const dispatchProps = {
  onUpdateProposal: (proposalUpdateRequest: ProposalUpdateRequest) => {
    return updateProposalAsync.request(proposalUpdateRequest);
  },
  onDismiss: closeConfigCard,
  addLineItem: (request: CreateLineItemGroupRequest) =>
    createLineItemGroupingAsync.request(request),
};

type Props = FinanceTermsCardProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  WithStyles<typeof financingCardStyles>;

export const FinanceTermsCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    classes,
    onDismiss,
    lineItem,
    lineItemId,
    proposal,
    onUpdateProposal,
    name,
    target,
    financeProducts,
    skuTitleMap,
    addLineItem,
    currentSelectedProject,
    allGroupedLineItems,
  } = props;
  if (!lineItem) {
    throw new Error('Simple Terms Card has no lineItem');
  }

  const { t } = useTranslation();
  const termIdContent = getTermIdContent(lineItem);
  const groupedLineItems = getGroupedLineItems(
    allGroupedLineItems,
    oc(lineItem).groups[0](''),
    lineItem.id
  );
  const [termId, setTermId] = React.useState(termIdContent || '');
  const [relatedProductId, setRelatedProductId] = React.useState(oc(groupedLineItems[0]).id(''));

  const applicableLineItems = getFinanceableLineItems(proposal.lineItems, financeProducts);
  const isApplyToExisting = applicableLineItems.length > 0;
  const dropDownLabel = isApplyToExisting ? t('quote::Applies to') : t('quote::Apply and add');

  const onChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newTermId?: string
  ) => {
    if (newTermId !== undefined) {
      setTermId(newTermId);
    }
  };

  const termIdError = termId && !validGuid.test(termId);
  const termIdErrorMessage = termIdError ? t('quote::Term ID is in an invalid format') : undefined;

  const onProductSelect = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    option && setRelatedProductId(option.key.toString());
  };

  const applyButtonDisabled =
    !termId.trim() || !validTermId(termId) || !validApplyTo(relatedProductId) || !!termIdError;
  const onApply = () => {
    onDismiss();
    if (lineItem) {
      if (isApplyToExisting) {
        // apply finance grouping and update proposal
        const proposalUpdateRequest = ApplyFinanceToLineItem(
          proposal,
          relatedProductId,
          lineItem.id,
          termId
        );
        onUpdateProposal(proposalUpdateRequest);
      } else {
        //create new line item apply finance grouping and update proposal
        const request = ApplyAndAdd(
          proposal,
          lineItem.id,
          relatedProductId,
          financeProducts,
          termId,
          currentSelectedProject
        );
        if (request) {
          addLineItem(request);
        }
      }
    }
  };

  const termIdInputLabel = () => (
    <div className={classes.termIdLabel}>
      <Label className={classes.label} htmlFor="financeTermsTermId" required>
        {t('quote::Term ID')}
      </Label>
      <InfoButton
        ariaLabel={t('quote::Open information about the term id')}
        calloutProps={{
          closeButtonAriaLabel: t('Close'),
          headline: t('quote::Term ID'),
          maxWidth: 343,
        }}
        id="term-id-info-button"
      >
        <TextBody>
          {t('quote::A Term ID is used to associate a specific amendment to this quote.')}
        </TextBody>
        <TextBody>
          {t(
            'quote::You can contact your finance desk manager to request a Term ID specific for Financing Terms.'
          )}
        </TextBody>
        <TextBody>
          <Trans ns="quote">
            Visit{' '}
            <LinkExternal
              addClass={classes.infoLink}
              dataAutomationId="financingDeskContactsLink"
              displayText="aka.ms/FinancingDeskContacts"
              href="https://aka.ms/FinancingDeskContacts"
              size="medium"
              onClick={() =>
                loggerService.log({
                  name: 'FinanceTermsCard - FinancingDeskContacts link is clicked',
                })
              }
            />{' '}
            to learn more.
          </Trans>
        </TextBody>
      </InfoButton>
    </div>
  );

  const applyToOptions = getApplyOptions(applicableLineItems, financeProducts, skuTitleMap, t);

  return (
    <CalloutCard
      applyButtonDisabled={applyButtonDisabled}
      applyButtonStrings={{ ariaLabel: t('quote::Apply'), text: t('quote::Apply') }}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      dataAutomationId="financeTerms"
      directionalHint={DirectionalHint.rightCenter}
      headerText={name}
      id={lineItemId}
      isBeakVisible={true}
      isReadOnly={props.quoteReadOnly}
      maxHeight={750}
      maxWidth={400}
      minWidth={325}
      target={target}
      onApply={onApply}
      onDismiss={onDismiss}
    >
      <div className={classes.termIdContainer}>
        <TextboxStandard
          ariaLabel={t('quote::Term ID')}
          dataAutomationId="financeTermsTermId"
          errorMessage={termIdErrorMessage}
          id="financeTermsTermId"
          readOnly={props.quoteReadOnly}
          value={termId}
          onChange={onChange}
          onRenderLabel={termIdInputLabel}
        />
      </div>
      <div className={classes.inputContainer}>
        {' '}
        <ComboBox
          disabled={props.quoteReadOnly}
          id="applyTo"
          label={dropDownLabel}
          maxHeight={750}
          options={applyToOptions}
          required={true}
          selectedKey={relatedProductId}
          onChange={onProductSelect}
        />
      </div>
      <LinkExternal
        addClass={classes.link}
        dataAutomationId="learnAboutFinancingLink"
        displayText={t('quote::Learn more about Financing Terms')}
        href={
          'https://msit.microsoftstream.com/embed/video/d865413a-c8ca-47eb-bb83-15572b5bb312?autoplay=false&amp;showinfo=true'
        }
      />
    </CalloutCard>
  );
};

export const FinanceTermsCard = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(financingCardStyles)(FinanceTermsCardUnstyled));
