import { Dialog, PrimaryButton, TextBodySmall } from 'components';
import {
  ExistingOwner,
  ExistingOwnersList,
  LoadingErrorType,
} from 'features-apollo/quote/components/ExistingOwners';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { existingOwnersDialogStyles } from './ExistingOwnersDialog.styles';

export interface ExistingOwnersDialogProps {
  list: ExistingOwner[];
  error: LoadingErrorType;
}

type Props = ExistingOwnersDialogProps & WithStyles<typeof existingOwnersDialogStyles>;

const ExistingOwnersDialogUnstyled: React.FC<Props> = ({ classes, list, error }: Props) => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  const closeDialog = () => {
    context.closeDialog();
  };

  const description = (
    <TextBodySmall addClass={classes.description}>
      {t(
        'quote::The owners listed here provide visibility in the cases where the person accepting the quote does not have the appropriate authorization. Guested users are denoted with an icon.'
      )}
    </TextBodySmall>
  );

  const footerButtons = [
    <PrimaryButton
      dataAutomationId="viewExistingOwnersCloseButton"
      key="viewExistingOwnersCloseButton"
      text={t('quote::Close')}
      onClick={closeDialog}
    />,
  ];

  return (
    <div>
      <Dialog
        bodyAddClass={classes.dialogBody}
        footerButtons={footerButtons}
        height={363}
        title={t('quote::Owners in this tenant')}
        width={560}
      >
        {description}
        <ExistingOwnersList error={error} list={list} />
      </Dialog>
    </div>
  );
};

export const ExistingOwnersDialog = withStyles(existingOwnersDialogStyles)(
  ExistingOwnersDialogUnstyled
);
