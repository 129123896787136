import { CalloutAtom } from 'components/atoms/Callouts';
import { IconButton } from 'components/ions/Buttons';
import { TextBody, TextTitle, TextTitleSecondary } from 'components/ions/Text';
import { Market, marketInfo } from 'features/proposal/supported-markets';
import { formatCurrency } from 'features/proposal/utils';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { DirectionalHint } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { productListFooterStyles } from './ProductListFooter.styles';

/**
 *
 *
 * @export
 * @interface ProductListFooterProps
 * @param caption
 * @param totalPrice
 */
export interface ProductListFooterProps {
  /**
   * caption that will be displayed next to the price
   *
   * @type {string}
   * @memberof ProductListFooterProps
   */
  caption: string;
  /**
   * currency code to display
   *
   * @type {string}
   * @memberof ProductListFooterProps
   */
  currency: string;
  /**
   * total price to display
   *
   * @type {string}
   * @memberof ProductListFooterProps
   */
  totalPrice: number;
  /**
   *
   *
   * @type {bool}
   * @memberof ProductListFooterProps
   */
  isForeign: boolean;
  exchangeRate?: number;
  exchangeDate?: Date;
  market: Market;
}

type Props = ProductListFooterProps & WithStyles<typeof productListFooterStyles>;

const UNDEFINED_PLACEHOLDER = '--';

const ProductListFooterUnStyled: React.FC<Props> = props => {
  const {
    classes,
    caption,
    currency,
    totalPrice,
    isForeign,
    exchangeRate,
    exchangeDate,
    market,
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  const [visible, setVisible] = React.useState(false);
  const { t } = useTranslation();

  let conversionIcon = null;
  if (isForeign) {
    const exchangeRateDisplay = exchangeRate
      ? exchangeRate.toLocaleString(i18next.language, { minimumFractionDigits: 2 })
      : UNDEFINED_PLACEHOLDER;

    const dateFormat = 'YYYY-MM-DD';
    const exchangeDateDisplay = exchangeDate
      ? moment.tz(exchangeDate, marketInfo[market].timeZones.first).format(dateFormat)
      : UNDEFINED_PLACEHOLDER;
    conversionIcon = (
      <>
        <div ref={ref} style={{ height: 'min-content' }}>
          <IconButton
            ariaLabel={t('quote::Price Conversion')}
            dataAutomationId="priceConversion"
            iconName="info"
            onClick={() => setVisible(!visible)}
          />
        </div>
        <CalloutAtom
          calloutMaxWidth={320}
          directionalHint={DirectionalHint.topCenter}
          hidden={!visible}
          isBeakVisible={false}
          preventDismissOnLostFocus={false}
          styles={{ calloutMain: { padding: 14 } }}
          target={ref.current}
          trapFocus={false}
          onDismiss={() => {
            setVisible(false);
          }}
        >
          <TextTitle>{t('quote::Price Conversion')}</TextTitle>
          <TextBody>
            {t(
              'quote::Settlement currency for Azure products is an estimate based on an exchange rate of {{exchangeRateDisplay}} per 1 USD as of {{exchangeDateDisplay}}.',
              {
                exchangeRateDisplay,
                exchangeDateDisplay,
              }
            )}
          </TextBody>
        </CalloutAtom>
      </>
    );
  }

  return (
    <div className={classes.root} data-automation-id="productListFooter">
      {conversionIcon}
      <div className={classes.price}>
        <TextBody>{`${caption} (${currency}):`}</TextBody>
        <TextTitleSecondary dataAutomationId="totalPrice">
          {formatCurrency(totalPrice)}
        </TextTitleSecondary>
      </div>
    </div>
  );
};

export const ProductListFooter = withStyles(productListFooterStyles)(
  ProductListFooterUnStyled
) as React.FC<ProductListFooterProps>;
