import i18next from 'i18next';
import { ThemeKey } from 'styles';
import { FavoriteButtonCatalog } from 'features-apollo/components/Favorites';
import { CatalogContext } from 'generated/graphql';
import { CatalogPageProduct, SkuLocalizedProperty, ProductAttributeValue } from '../types';
import * as React from 'react';
import { getUnique } from 'services/utils';

export const buildTagsString = (tags: ProductAttributeValue[]) =>
  tags.map(attr => attr.Name).join(', ');

export const buildTableProps = (skus: SkuLocalizedProperty[], t: i18next.TFunction) => {
  // This filter is to remove duplicate skus. Shouldn't happen in prod but can happen in test env.
  const uniqueSkus = getUnique<SkuLocalizedProperty>(skus, sku => sku.SkuTitle);
  return {
    firstColumnName: t('home::Version'),
    secondColumnName: t('home::Description'),
    tableRows: uniqueSkus.map(sku => {
      return {
        name: sku.SkuTitle,
        description: sku.SkuDescription,
      };
    }),
  };
};

export const renderFavoriteButton = (
  product: CatalogPageProduct,
  classes: Record<string, string>,
  catalogContext?: CatalogContext,
  displayLabel?: boolean
) => (
  <FavoriteButtonCatalog
    addButtonClass={classes.favoriteButton}
    addContainerClass={classes.favoriteButtonContainer}
    catalogContext={catalogContext}
    displayLabel={displayLabel}
    product={{
      productId: product.id,
      productName: product.title,
      productIdentifier: product.identifier,
    }}
    stopPropagation
  />
);

export const defaultImage = (theme: ThemeKey) =>
  theme === ThemeKey.light ? '/images/ImageNotFound_Light' : '/images/ImageNotFound_Dark';
