import { Dropdown } from 'components/ions';
import { Sku } from 'generated/graphql';
import { IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { placeholderKey, placeholderOptions } from './utils';

export interface ModernOfficeDropdownsProps {
  sku?: Sku;
  selected: { termDuration?: string; termId?: string };
  dropdownOptions: { termDuration: IDropdownOption[]; billingPlans: IDropdownOption[] };
  readOnly?: boolean;
  select: { termDuration: (termId: string) => void; billingPlan: (termId: string) => void };
}

const styles = {
  termDuration: {
    marginBottom: 10,
  },
  billingPlan: {
    margintTop: 8,
  },
};

type Props = ModernOfficeDropdownsProps & WithStyles<typeof styles>;

const ModernOfficeDropdownsUnstyled = (props: Props) => {
  const { sku, selected, dropdownOptions, select, classes, readOnly } = props;
  const { t } = useTranslation();

  const TermDurationDropdown = (
    <div className={classes.termDuration}>
      <Dropdown
        data-automation-id="termDuration"
        disabled={readOnly}
        label={t('quote::Commitment')}
        options={sku ? dropdownOptions.termDuration : placeholderOptions(t)}
        selectedKey={sku ? selected.termDuration : placeholderKey}
        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
          if (option) {
            select.termDuration(option.key.toString());
          }
        }}
      />
    </div>
  );

  const BillingPlanDropdown = (
    <div className={classes.billingPlan}>
      <Dropdown
        data-automation-id="billingPlan"
        disabled={readOnly}
        label={t('quote::Billing Plan')}
        options={sku ? dropdownOptions.billingPlans : placeholderOptions(t)}
        selectedKey={sku ? selected.termId : placeholderKey}
        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
          if (option) {
            select.billingPlan(option.key.toString());
          }
        }}
      />
    </div>
  );

  return (
    <div>
      <div>{TermDurationDropdown}</div>
      <div style={{ marginTop: 8 }}>{BillingPlanDropdown}</div>
    </div>
  );
};

export const ModernOfficeDropdowns = withStyles(styles)(ModernOfficeDropdownsUnstyled);
