import { getConfigByEnvironment } from 'features/app/config';
import { Flight, flightList, flights } from 'services/flights/flightList';
import {
  applyControlTowerFlights,
  getDefaultFlights,
  getInitialFlights,
} from 'services/flights/utils';

import { shallowCompare } from '@uifabric/utilities';

import { FlightDisplay } from './types';

export const buildFlightsQueryString = (currentFlightState: Record<Flight, boolean>) => {
  //query string = diff between current state and (default flights + flights api)

  const flights = getDefaultFlights();
  const env = getConfigByEnvironment().app;

  applyControlTowerFlights(flights, env);

  const setSwitchParamValues: string[] = [];

  flightList.forEach(flightKey => {
    const currentFlightValue = currentFlightState[flightKey];

    if (currentFlightValue != null && currentFlightValue !== flights[flightKey]) {
      setSwitchParamValues.push(`${flightKey}:${currentFlightValue ? 1 : 0}`);
    }
  });

  return setSwitchParamValues.join(',');
};

export const sortFlightsByDisplayName = (togglableFlights: Flight[]) => {
  const flightsList = togglableFlights.map((flight: Flight) => {
    const flightDisplay: FlightDisplay = {
      key: flight,
      display: flights[flight] ? flights[flight].display : 'N/A',
      description: flights[flight] ? flights[flight].description : 'N/A',
      expirationDate: flights[flight] && flights[flight].expirationDate,
    };

    return flightDisplay;
  });

  const sortedFlightsList = flightsList.sort((flight1, flight2) =>
    flight1.display.localeCompare(flight2.display)
  );

  return sortedFlightsList;
};

export const hasInitialFlights = (flights: Record<Flight, boolean>) => {
  return shallowCompare(flights, getInitialFlights(false));
};

export const reloadPageWithoutUrlFlights = () => {
  const urlParams = new URLSearchParams(window.location.search.toLowerCase());
  urlParams.delete('setswitch');

  let newUrl = window.location.pathname;

  const params = urlParams.toString();

  if (params.length) {
    newUrl += `?${params}`;
  }

  window.location.href = newUrl;
};
