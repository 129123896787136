import {
  CalloutCard,
  Checkbox,
  DetailsList,
  SectionSeparator,
  ShimmerForBackgroundCommon,
  TextBody,
} from 'components/ions';
import { applyConfigurationCardWithDialog } from 'features-apollo/quote/components/ConfigCards/utils';
import { formatCurrency } from 'features-apollo/quote/components/utils';
import { removeTypeName } from 'features-apollo/utils';
import {
  ApplyConfigurationSingleSkuInput,
  CatalogAction,
  QuoteMutationInput,
  Sku,
} from 'generated/graphql';
import { useFabricSelectionSimple } from 'hooks/useFabricSelection/useFabricSelection';
import i18next from 'i18next';
import {
  CheckboxVisibility,
  ColumnActionsMode,
  DirectionalHint,
  IColumn,
  Selection,
  SelectionMode,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { AddAsNewLineItemButton } from '../AddAsNewLineItemButton';
import { calloutCardDimensions } from '../common';
import { ApplyConfigurationSingleSku } from '../queries';
import { ConfigurationCardCommonProps, SkuConfigurationCardCommonProps, View } from '../types';
import { filterOutSkus, isAutoRenewable } from '../utils';
import { GetSimpleProduct } from './queries';
import { styles } from './SimpleSkuPickerConfigurationCard.styles';

export interface SimpleSkuPickerConfigurationCardProps
  extends ConfigurationCardCommonProps,
    SkuConfigurationCardCommonProps {
  productId: string;
  lineItemId: string;
  skus: Sku[];
  readOnly?: boolean;
  quoteMutationInput: QuoteMutationInput;
  target: React.RefObject<HTMLSpanElement>;
  isTerm?: boolean;
  isModernOffice?: boolean;
  initialValues?: { skuId: string; autoRenew: boolean };
  onDismiss: () => void;
}

type Props = SimpleSkuPickerConfigurationCardProps & WithStyles<typeof styles>;

const getPriceOfTheFirstAvailability = (sku: Sku) => {
  const price = oc(sku).availabilities[0].price(null);
  return price !== null ? formatCurrency(price.amount) : '-';
};

const getMatchedAvailability = (skuId: string, skus: Sku[]) => {
  const matchedSku = skus.find(sku => sku.skuId === skuId);
  if (!matchedSku) {
    throw new Error('no sku matched');
  }
  const availabilities = matchedSku.availabilities;
  if (availabilities.length === 0) {
    loggerService.error({
      exception: {
        name: 'Simple Sku Picker Configuration Card - No Availability on Apply',
        message: 'No Availability is found',
      },
    });
  } else if (availabilities.length > 1) {
    loggerService.error({
      exception: {
        name: 'Simple Sku Picker Configuration Card  - Multiple Availability on Apply',
        message: `${availabilities.length} is found`,
      },
    });
  } else {
    return availabilities[0];
  }
};

const SimpleSkuPickerConfigurationCardUnstyled: React.FC<Props> = (props: Props) => {
  const {
    target,
    lineItemId,
    quoteMutationInput,
    productId,
    isTerm,
    classes,
    initialValues,
    catalogContext,
    productTitle,
    isModernOffice,
    currency,
    onDismiss,
    readOnly,
    alreadyHasDiscount,
  } = props;
  const [skuId, setSkuId] = React.useState<string | undefined>(
    initialValues && initialValues.skuId
  );
  const [applyConfiguration, { loading: applyLoading }] = useMutation(ApplyConfigurationSingleSku);
  const [view, setView] = React.useState<View>(View.CardContent);
  const dialogContext = React.useContext(DialogContext);
  const isTermOrModernOffice = isTerm || isModernOffice;
  const { data, loading } = useQuery<{ getProduct: { skus: Sku[] } }>(GetSimpleProduct, {
    variables: {
      id: productId,
      catalogContext: removeTypeName(catalogContext),
      quoteId: quoteMutationInput.id,
    },
    skip: isTermOrModernOffice,
  });

  let skusToProcess = filterOutSkus(oc(data).getProduct.skus(props.skus));
  const autoRenewableProduct = isAutoRenewable(skusToProcess as Sku[]);
  const [autoRenew, setAutoRenew] = React.useState<boolean>(
    initialValues ? initialValues.autoRenew : autoRenewableProduct
  );

  const { t } = useTranslation();

  const onSelectionChanged = (selection: Selection) => {
    const newSelection = selection && selection.getSelection()[0];
    const skuId = newSelection && newSelection.key;
    if (skuId) {
      setSkuId(skuId.toString());
    } else {
      setSkuId(undefined);
    }
  };

  const selection = useFabricSelectionSimple(onSelectionChanged);

  React.useEffect(() => {
    if (selection && skuId) {
      const items = skusToProcess.map((sku: Sku) => {
        return { ...sku, key: sku.skuId, selectable: true };
      });
      selection.setItems(items);
      selection.setKeySelected(skuId, true, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let columns: IColumn[] = [
    // TODO: Ask this to Ben this might have unintended consequences to have blank name there
    {
      key: 'title',
      name: '',
      fieldName: 'title',
      minWidth: 0,
      isRowHeader: true,
      isResizable: false,
      isSorted: false,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
  if (!isTermOrModernOffice) {
    columns.push({
      key: t('quote::Price ({{currency}})', { currency }),
      name: t('quote::Price ({{currency}})', { currency }),
      fieldName: 'price',
      minWidth: 0,
      onRender: item => {
        if (loading) {
          return <ShimmerForBackgroundCommon width={50} />;
        }
        return item.price;
      },
    });
  }
  const onApply = (skuId: string, skus: Sku[], configureAsNew: boolean) => {
    const availability = getMatchedAvailability(skuId, skus);
    if (!availability) {
      return;
    }
    const configuration: ApplyConfigurationSingleSkuInput = {
      action: CatalogAction.Purchase,
      skuId,
      availabilityId: availability.availabilityId,
      lineItemId,
      autoRenew,
      configureAsNew,
    };
    if (configureAsNew) {
      applyConfiguration({ variables: { quote: quoteMutationInput, configuration } });
    } else if (alreadyHasDiscount) {
      setView(View.ConfirmationDialog);
      applyConfigurationCardWithDialog(
        () => {
          applyConfiguration({ variables: { quote: quoteMutationInput, configuration } });
          onDismiss();
        },
        () => setView(View.CardContent),
        dialogContext
      );
    } else {
      applyConfiguration({ variables: { quote: quoteMutationInput, configuration } });
      onDismiss();
    }
  };

  const items = skusToProcess.map(sku => {
    const price = getPriceOfTheFirstAvailability(sku);
    return { ...sku, price, selectable: true, key: sku.skuId };
  });

  const skuList = (
    <div className={props.classes.detailsListContainer} data-automation-id="skuList">
      <DetailsList
        addClass={classes.detailsListStyles}
        checkboxVisibility={CheckboxVisibility.always}
        columns={columns}
        compact={true}
        isHeaderVisible={!isTerm}
        items={items}
        selection={selection}
        selectionMode={readOnly ? SelectionMode.none : SelectionMode.single}
        shouldResizeAfterFirstRender={true}
      />
    </div>
  );

  const recurringBillingCheckbox = (
    <div className={classes.reccuringBillingCheckboxContainer}>
      <Checkbox
        ariaLabel={i18next.t('quote::Recurring Billing')}
        checked={autoRenew}
        disabled={readOnly}
        id="recurringBillingCheckbox"
        label={i18next.t('quote::Recurring Billing')}
        onChange={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
          checked?: boolean | undefined
        ) => {
          setAutoRenew(!!checked);
        }}
      />
    </div>
  );

  let content = (
    <>
      {isTerm && (
        <div>
          <TextBody>{t('quote::Please select the option to apply to this term')}:</TextBody>{' '}
          <SectionSeparator addClass={classes.sectionSeparatorTopMargin} />
        </div>
      )}
      {skuList}
      <SectionSeparator />
      {autoRenewableProduct && recurringBillingCheckbox}
    </>
  );
  //TODO: move this logic to gql probably and not return sku's if all of their availabilities are missing actions
  const noSkusAfterLoadingIsFinished = !skusToProcess.length && !loading;
  if (noSkusAfterLoadingIsFinished) {
    content = (
      <TextBody>
        {t('quote::No SKUs are available, please remove the product from the quote')}
      </TextBody>
    );
  }

  return (
    <CalloutCard
      {...calloutCardDimensions}
      applyButtonDisabled={!skuId}
      applyButtonStrings={{ text: t('quote::Apply'), ariaLabel: t('quote::Apply') }}
      closeButtonAriaLabel={t('quote::Close')}
      directionalHint={DirectionalHint.rightCenter}
      headerText={productTitle}
      hidden={view === View.ConfirmationDialog}
      id="simple-sku-picker"
      isBeakVisible={true}
      isReadOnly={!!readOnly}
      otherFooterButtons={
        !isTerm ? (
          <AddAsNewLineItemButton
            disabled={!skuId || readOnly}
            loading={applyLoading}
            onClick={() => {
              skuId && onApply(skuId, skusToProcess, true);
            }}
          />
        ) : (
          undefined
        )
      }
      target={target}
      onApply={() => {
        skuId && onApply(skuId, skusToProcess, false);
      }}
      onDismiss={onDismiss}
    >
      {content}
    </CalloutCard>
  );
};

export const SimpleSkuPickerConfigurationCard = withStyles(styles)(
  SimpleSkuPickerConfigurationCardUnstyled
);
