import { quoteCenterLatestUrl } from 'features/app/config/configurations';
import { AppEnvironment } from 'features/app/config/type';
import React from 'react';

import { Article } from './HelpPanel.types';
import { MarkdownRenderer } from './MarkdownRenderer';

interface Props {
  appEnvironment: AppEnvironment;
  article: Article;
}

export const HelpArticle: React.FC<Props> = props => {
  const { appEnvironment, article } = props;

  const baseUrl = appEnvironment === AppEnvironment.Local ? quoteCenterLatestUrl : '';
  const filename = article.filename.toLowerCase();
  const articlePath = `${baseUrl}/articles/help/articles/${filename.replace('.md', '')}`;
  const markdownUrl = `${articlePath}/${filename}`;

  return (
    <MarkdownRenderer
      imageBasePath={articlePath}
      lasUpdatedDate={new Date(article.updatedDate)}
      markdownUrl={markdownUrl}
      title={article.name}
    />
  );
};
