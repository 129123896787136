import { TextAtom } from 'components/atoms';
import { HighlightedText, NavLinkInternal, SmallIcon } from 'components/ions';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { customerListRowStyles } from './CustomerListRow.styles';

export interface CustomerListRowProps {
  /**
   * Provides link to redirect the user to the customer page
   */
  customerPageUrl: string;
  parentName: string;
  propertyLabelText?: string;
  tpid: string;
  propertyValue?: string;
  isAccountEntity: boolean;
  searchText?: string;
  propertyIconType?: string;
  addClass?: string;
  enrollmentId?: string;
  displayEnrollmentId?: boolean;
  enrollmentLabel?: string;
  onSelect?: (customer: string) => void;
}

type Props = CustomerListRowProps & WithStyles<typeof customerListRowStyles>;

const CustomerListRowUnstyled: React.FC<Props> = props => {
  let accountName, tpid, enrollmentInfo, propertyValue;

  const onSelected = () => {
    if (props.onSelect) {
      props.onSelect(props.parentName);
    }
  };

  if (!props.isAccountEntity) {
    accountName = <TextAtom addClass={props.classes.accountName}>{props.parentName}</TextAtom>;
  } else {
    accountName = (
      <HighlightedText
        highlightedText={props.searchText}
        text={props.parentName}
        textClass={props.classes.accountName}
      />
    );
  }

  if (!props.isAccountEntity) {
    tpid = <TextAtom>{props.tpid}</TextAtom>;
  } else {
    tpid = (
      <HighlightedText
        addClass={props.classes.inlineProperty}
        highlightedText={props.searchText}
        text={props.tpid}
      />
    );
  }

  if (!props.isAccountEntity) {
    propertyValue = (
      <HighlightedText
        addClass={props.classes.inlineProperty}
        highlightedText={props.searchText}
        text={props.propertyValue || ''}
      />
    );
  } else {
    propertyValue = <TextAtom>{props.propertyValue}</TextAtom>;
  }

  if (!!props.enrollmentId && !!props.displayEnrollmentId) {
    enrollmentInfo = (
      <div>
        <SmallIcon iconName="RecurringEvent"></SmallIcon>
        <TextAtom>{props.enrollmentLabel}</TextAtom>
        <HighlightedText
          addClass={props.classes.inlineProperty}
          highlightedText={props.searchText}
          text={props.enrollmentId}
        />
      </div>
    );
  }

  const customerLink = (
    <NavLinkInternal fontSize="medium" to={props.customerPageUrl} onClick={onSelected}>
      {accountName}
    </NavLinkInternal>
  );

  return (
    <li className={props.classes.rowItem}>
      {customerLink}
      <div>
        <SmallIcon iconName="Permissions"></SmallIcon>
        <TextAtom> TPID: </TextAtom>
        {tpid}
      </div>
      {enrollmentInfo}
      {!props.isAccountEntity ? (
        <div>
          <SmallIcon iconName={props.propertyIconType || ''}></SmallIcon>
          <TextAtom>
            {` ${props.propertyLabelText +
              (props.propertyLabelText && props.propertyLabelText.length !== 0 ? ': ' : '')}`}
          </TextAtom>
          {propertyValue}
        </div>
      ) : (
        ''
      )}
    </li>
  );
};

export const CustomerListRow = withStyles(customerListRowStyles)(
  CustomerListRowUnstyled
) as React.FC<CustomerListRowProps>;
