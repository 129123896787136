import {
  ActionBar,
  BarButton,
  BarSearch,
  BarSearchProps,
  MediumIcon,
  ProgressIndicator,
  SmallIcon,
  StatusIndicatorApproved,
  StatusIndicatorAwaiting,
  StatusIndicatorDeleted,
  StatusIndicatorDraft,
  StatusIndicatorExpired,
  StatusIndicatorPublished,
  StatusIndicatorPurchaseFailed,
  StatusIndicatorPurchasing,
  StatusIndicatorRejected,
  StatusIndicatorUnknown,
  TextWatermark,
} from 'components';
import { ChoiceGroup, TextBodySmall } from 'components/ions';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { meplaHistory } from 'createHistory';
import { getFlightIsEnabled } from 'features/app/selectors';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import {
  clearActiveQuote,
  loadAndHydrateLiteQuote,
  searchApprovalsAsync,
  searchProposalFragmentsAsync,
} from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import { ApprovalListSearchParams, SummaryListSearchParams } from 'features/proposal/types';
import { getCRMId, isQuoteExpiringSoon } from 'features/proposal/utils';
import { getPathWithQueryParamsRemoved } from 'features/utils/deepLinkUtils';
import i18next from 'i18n';
import { IChoiceGroupOption, ISearchBox } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { Flight } from 'services/flights/flightList';
import {
  ProposalStatus,
  ProposalSummary,
  SearchProposalsSortField,
  SearchSortOrder,
  UserGroup,
} from 'services/proposal/types';
import { RootState } from 'store/types';

import { KeyCodes } from '@uifabric/utilities';

import { DeleteProposalBarButton, NewProposalBarButton } from '../Dialogs';
import { SummaryDetail } from './SummaryDetail';
import { summaryListStyles } from './SummaryList.styles';

const mapStateToProps = (state: RootState) => ({
  proposalFragments: selectors.getProposalFragments(state),
  isLoading: selectors.summaryListLoading(state),
  error: selectors.summaryListError(state),
  activeProposalId: selectors.getActiveProposalId(state),
  viewState: selectors.getViewStateForSummaryList(state),
  customerName: (proposal: ProposalSummary) => selectors.getCustomerName(state, proposal, ''),
  canDelete: selectors.canDelete(state),
  fedIndirectEnabled: getFlightIsEnabled(state, Flight.graphqlPhase2),
});

const dispatchProps = {
  getProposalFragments: (params: SummaryListSearchParams) =>
    searchProposalFragmentsAsync.request(params),
  getProposal: (id: string) => loadAndHydrateLiteQuote(id),
  getApprovals: (params: ApprovalListSearchParams) => searchApprovalsAsync.request(params),
  clearPanel: clearActiveQuote,
};

interface SummaryListProps {
  filter: string;
  searchComponentRef?: React.RefObject<ISearchBox>;
}

type Props = SummaryListProps &
  WithStyles<typeof summaryListStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const renderStatus = (
  assignedToGroup: UserGroup,
  isExpiringSoon: boolean,
  state: string
): JSX.Element => {
  // Conditionally set as pending purchase when no approval is required...
  if (assignedToGroup === UserGroup.Customer && state === ProposalStatus.Draft)
    state = ProposalStatus.Published;

  // TODO: Handle states when we don't know if it is approved.
  switch (state) {
    case ProposalStatus.Active:
    case ProposalStatus.Completed:
    case ProposalStatus.Purchased:
      return <StatusIndicatorApproved text={i18next.t(state)} />;
    case ProposalStatus.Deleted:
      return <StatusIndicatorDeleted text={i18next.t(state)} />;
    case ProposalStatus.Rejected:
      return <StatusIndicatorRejected text={i18next.t(state)} />;
    case ProposalStatus.Expired:
      return <StatusIndicatorExpired text={i18next.t(state)} />;
    case ProposalStatus.Draft:
      return <StatusIndicatorDraft isWarning={isExpiringSoon} text={i18next.t(state)} />;
    case ProposalStatus.Published:
      return (
        <StatusIndicatorPublished>
          <Trans ns="home">Published</Trans>
        </StatusIndicatorPublished>
      );
    case ProposalStatus.PurchaseFailure:
      return <StatusIndicatorPurchaseFailed text={i18next.t(state)} />;
    case ProposalStatus.PurchaseInProgress:
      return (
        <StatusIndicatorPurchasing>
          <Trans ns="home">In progress</Trans>
        </StatusIndicatorPurchasing>
      );
    case ProposalStatus.Submitted:
      return (
        <StatusIndicatorAwaiting>
          <Trans ns="home">Pending</Trans>
        </StatusIndicatorAwaiting>
      );
    case ProposalStatus.Unknown:
    default:
      return (
        <StatusIndicatorUnknown>
          <Trans ns="home">Unknown</Trans>
        </StatusIndicatorUnknown>
      );
  }
};

let handleDelete = () => {
  return;
};

const setHandleDelete = (callback: () => void) => {
  handleDelete = callback;
};

const SummaryListUnconnected: React.FC<Props> = props => {
  const {
    getProposalFragments: getProposals,
    getProposal,
    getApprovals,
    filter,
    isLoading,
    classes,
    proposalFragments: proposals,
    activeProposalId,
    canDelete,
    viewState: editor,
    error,
    clearPanel,
    searchComponentRef,
  } = props;

  useHelpContent(
    filter === 'my'
      ? HelpContent.MyQuotes
      : filter === 'my-approvals'
      ? HelpContent.NeedsMyApproval
      : HelpContent.Default
  );
  const [approvalFilterKey, setApprovalFilterKey] = useState('pending');

  const onGetApprovals = (pendingOnly: boolean) => {
    getApprovals({
      ...editor.search,
      pendingOnly,
    });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSearchQueryExistsInUrl = !!searchParams.get('search');

  useEffect(() => {
    if (filter === 'my') {
      !isSearchQueryExistsInUrl && getProposals(editor.search);
    } else if (filter === 'my-approvals') {
      onGetApprovals(approvalFilterKey === 'pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, isSearchQueryExistsInUrl]);

  let proposalsToDisplay =
    filter === 'my-approvals' && approvalFilterKey === 'pending'
      ? proposals.filter(summary => summary.header.status === ProposalStatus.Submitted)
      : proposals;

  proposalsToDisplay = proposalsToDisplay.filter(
    proposal => proposal.header.status !== ProposalStatus.Deleted
  );

  useEffect(() => {
    if (!proposalsToDisplay.length && activeProposalId !== '') {
      clearPanel();
    }
  }, [proposalsToDisplay, activeProposalId, clearPanel]);

  const { t } = useTranslation('quote');

  const onKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    const target = e.target as HTMLElement;

    switch (e.keyCode) {
      case KeyCodes.up:
        e.preventDefault();
        const previousSibling = target.previousElementSibling as HTMLElement;
        if (previousSibling) previousSibling.focus();
        else {
          const lastSibling = (target.parentElement as HTMLElement).lastChild as HTMLElement;
          if (lastSibling) lastSibling.focus();
        }
        break;
      case KeyCodes.down:
        e.preventDefault();
        const nextSibling = target.nextElementSibling as HTMLElement;
        if (nextSibling) nextSibling.focus();
        else {
          const firstSibling = (target.parentElement as HTMLElement).firstChild as HTMLElement;
          if (firstSibling) firstSibling.focus();
        }
        break;
      case KeyCodes.space:
        e.preventDefault();
        target.click();
        break;
      case KeyCodes.enter:
        const link = target.querySelector('a');
        if (link) link.click();
        break;
      case KeyCodes.del:
        handleDelete();
        break;
    }
  };

  const onSearch = (value?: string) => {
    const newValue = value ? value.replace(/'/g, '') : '';
    getProposals({
      ...editor.search,
      filter: newValue,
    });
  };

  const onClearSearch = () => {
    getProposals({ ...editor.search, filter: '' });
  };

  const onChangePendingOnly = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    if (option) {
      setApprovalFilterKey(option.key);
      onGetApprovals(option.key === 'pending');
    }
  };

  const handleSort = (field: SearchProposalsSortField) => {
    return () => {
      const params = {
        ...editor.search,
        sortField: field,
        sortOrder:
          editor.search.sortField === field
            ? editor.search.sortOrder === SearchSortOrder.Ascending
              ? SearchSortOrder.Descending
              : SearchSortOrder.Ascending
            : SearchSortOrder.Ascending,
      };

      if (filter === 'my') getProposals({ ...params, filter: params.filter.replace(/'/g, '') });
      else if (filter === 'my-approvals')
        getApprovals({
          pendingOnly: approvalFilterKey === 'pending',
          ...params,
        });
    };
  };

  const emptyMessage =
    filter === 'my'
      ? editor.search.filter
        ? t('quote::No quotes found. Please try another search or try modifying your search term.')
        : t('quote::No quotes found. Create a new quote or search for one.')
      : t('quote::There are currently no quotes awaiting your approval.');

  const errorMessage =
    filter === 'my' ? t('quote::Unable to load quotes') : t('quote::Unable to load approvals');

  const getSortIcon = (sortField: SearchProposalsSortField) => {
    if (editor.search.sortField !== sortField) {
      return <SmallIcon iconName="Sort" />;
    }

    return editor.search.sortOrder === SearchSortOrder.Ascending ? (
      <SmallIcon iconName="SortUp" />
    ) : (
      <SmallIcon iconName="SortDown" />
    );
  };

  const errorWatermark = (
    <>
      <SmallIcon addClass={classes.errorBadge} iconName="StatusErrorFull" />
      <TextBodySmall>{errorMessage}</TextBodySmall>
    </>
  );
  const emptyWatermark = <TextWatermark>{emptyMessage}</TextWatermark>;

  const listWatermark = (
    <tr className="no-results">
      <td colSpan={4}>{error ? errorWatermark : emptyWatermark}</td>
    </tr>
  );

  const tableBodyWithContents =
    !!proposalsToDisplay.length &&
    proposalsToDisplay.map(summary => {
      const expirationDate = new Date(summary.header.expirationDate);
      const isExpiringSoon = isQuoteExpiringSoon(expirationDate);
      const crmId = getCRMId(summary);
      return (
        <tr
          className={activeProposalId === summary.id ? 'active' : ''}
          data-automation-id={summary.id}
          key={summary.id}
          tabIndex={activeProposalId === summary.id ? 0 : -1}
          onClick={() => getProposal(summary.id)}
          onDoubleClick={() => meplaHistory.push(`${routes.quote.forId(summary.id)}`)}
          onKeyDown={onKeyDown}
        >
          <td>
            <NavLink
              data-automation-id="proposalRow"
              tabIndex={-1}
              title={summary.header.name}
              to={routes.quote.forId(summary.id)}
              onClick={event => event.stopPropagation()}
            >
              {summary.header.name}
            </NavLink>
          </td>
          <td>{crmId && crmId.toUpperCase()}</td>
          <td className={classes.expirationDateCell}>
            {convertDateToFormattedString(expirationDate, LocaleDateFormat.ll)}
            {isExpiringSoon && summary.header.status !== ProposalStatus.Completed && (
              <div className={classes.warningIconContainer}>
                <MediumIcon iconName="Warning" />
              </div>
            )}
          </td>
          <td>
            {renderStatus(summary.header.assignedToGroup, isExpiringSoon, summary.header.status)}
          </td>
        </tr>
      );
    });

  const finishedAndHaveContent = !isLoading && !error && !!proposalsToDisplay.length;
  const finishedEmpty = !isLoading && !proposalsToDisplay.length;
  const finishedWithErrors = !isLoading && error;

  let tableBody = undefined;
  if (finishedAndHaveContent) {
    tableBody = tableBodyWithContents;
  } else if (finishedEmpty || finishedWithErrors) {
    tableBody = listWatermark;
  }

  return (
    <div className={classes.list}>
      <div className={classes.table}>
        <div className={classes.bar}>
          <ActionBar grow={true} hideSeparator={true}>
            <NewProposalBarButton />
            <BarButton
              ariaLabel={t('quote::Edit Quote')}
              dataAutomationId="editProposalButton"
              disabled={!activeProposalId}
              iconName="edit"
              showNewIconColor={props.fedIndirectEnabled}
              text={t('quote::Edit')}
              onClick={() => meplaHistory.push(`${routes.quote.forId(activeProposalId)}`)}
            />
            <DeleteProposalBarButton
              dataAutomationId="deleteProposalButton"
              disabled={!canDelete}
              fedIndirectEnabled={props.fedIndirectEnabled}
              proposalId={activeProposalId}
              provideCallback={setHandleDelete}
            />
            {filter === 'my' ? (
              <SummaryListBarSearch
                ariaLabel={t('quote::Search for {{item}}', {
                  item: t('quote::Quotes').toLocaleLowerCase(),
                })}
                componentRef={searchComponentRef}
                dataAutomationId="summaryListBarSearch"
                defaultValue={editor.search.filter}
                focusPlaceholder={t(
                  'quote::Search for Quotes by name, CRM ID, MS contact, and more...'
                )}
                placeholder={t('quote::Search')}
                onChangeDebounced={onSearch}
                onClear={onClearSearch}
                onSearch={onSearch}
              />
            ) : (
              <ChoiceGroup
                className={classes.choiceGroup}
                data-automation-id="pendingApprovalChoiceGroup"
                options={[
                  {
                    key: 'pending',
                    text: t('quote::Pending my approval'),
                  },
                  {
                    key: 'all',
                    text: t('quote::All'),
                  },
                ]}
                selectedKey={approvalFilterKey}
                onChange={onChangePendingOnly}
              />
            )}
          </ActionBar>
        </div>

        <table>
          <thead>
            <tr>
              <th>{t('quote::Name')}</th>
              <th>{t('quote::CRM ID')}</th>
              <th
                className={`sortable ${editor.search.sortField ===
                  SearchProposalsSortField.ExpirationDate && 'sorted'}`}
                onClick={handleSort(SearchProposalsSortField.ExpirationDate)}
              >
                {t('quote::Good until')}
                {getSortIcon(SearchProposalsSortField.ExpirationDate)}
              </th>
              <th>{t('quote::Status')}</th>

              {/* 
              TODO: Re-enable this column once Quote Service (search) fixes the bug - only sort-by modified working as of 7/2019 -bf
              
              <th
                className={`sortable ${editor.search.sortField ===
                  SearchProposalsSortField.Status && 'sorted'}`}
                onClick={handleSort(SearchProposalsSortField.Status)}
              >
                Status
                <SmallIcon iconName="sort" />
              </th> */}
            </tr>
            <tr>
              <td className="progress" colSpan={4}>
                {isLoading && <ProgressIndicator />}
              </td>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>

        {/* <pre className={classes.pre}>{JSON.stringify(proposals)}</pre> */}
      </div>

      <div className={`${classes.detail} ${activeProposalId ? classes.detailActive : ''}`}>
        <SummaryDetail />
      </div>
    </div>
  );
};

export const SummaryList = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(summaryListStyles)(SummaryListUnconnected));

// TODO:This is a hacky optimization, The main reason for this is if setState is in the SummaryList, summarylist renders on every keypress and that makes the input laggy
// With putting here only this component re-renders and not the whole list on every keypress, it get rids of a noticeable lag
interface SummaryListBarSearchProps extends BarSearchProps {
  defaultValue: string;
}

const SummaryListBarSearch = (props: SummaryListBarSearchProps) => {
  const [search, setSearch] = React.useState<string | undefined>(props.defaultValue);
  const onChange = (value?: string) => {
    const newValue = value ? value.replace(/'/g, '') : '';
    setSearch(newValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { defaultValue, ...rest } = props;
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    // Use the search term(s) from the query string if they are given. This will result
    // in the search being applied from the search term when this component is initialized.
    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get('search');
    if (searchParam && props.onSearch) {
      props.onSearch(searchParam);
      setSearch(searchParam);
      history.replace(getPathWithQueryParamsRemoved(window.location.href, ['search']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, history]);

  const onClear = () => {
    setSearch(undefined);
    props.onClear && props.onClear();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return <BarSearch {...rest} value={search} onChange={onChange} onClear={onClear} />;
};
