import { Dialog, PrimaryButton, SecondaryButton, TextBody } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import { TenantScenario } from '../../Wizards';

export interface RemoveParticipantDialogProps {
  /**
   * The strings are defined based on the participant
   * @default 'partner-customer'
   */
  specificScenario?: TenantScenario;
  /**
   * Callback for the 'Remove' button
   */
  onRemove: () => void;
}

type Props = RemoveParticipantDialogProps;

export const RemoveParticipantDialog: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };
  const handleRemoveParticipant = () => {
    props.onRemove();
    closeDialog();
  };

  let title;
  let message;
  let buttonLabel;

  switch (props.specificScenario) {
    case TenantScenario.partner:
      title = t('quote::Remove partner?');
      message = t(
        'quote::Changing the partner on the quote will delete the uploaded agreement. Upon adding a partner you will need to re-upload the agreement and configure. Would you like to remove the partner on the quote?'
      );
      buttonLabel = t('quote::Remove partner');
      break;
    case TenantScenario.partnerCustomer:
    default:
      title = t('quote::Remove customer?');
      message = t(
        'quote::Changing the customer on the quote will delete the uploaded agreement. Upon adding a customer you will need to re-upload the agreement and configure. Would you like to remove the customer on the quote?'
      );
      buttonLabel = t('quote::Remove customer');
      break;
  }

  const footerButtons = [
    <PrimaryButton
      dataAutomationId="removeParticipantConfirmButton"
      key="removeParticipantConfirmButton"
      text={buttonLabel}
      onClick={handleRemoveParticipant}
    />,
    <SecondaryButton
      dataAutomationId="removeParticipantCancelButton"
      key="removeParticipantCancelButton"
      text={t('Cancel')}
      onClick={closeDialog}
    />,
  ];

  return (
    <Dialog footerButtons={footerButtons} height={240} title={title} width={400}>
      <TextBody>{message}</TextBody>
    </Dialog>
  );
};

export const openRemoveParticipantDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: RemoveParticipantDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <RemoveParticipantDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
