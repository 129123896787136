import { ThemeProps } from 'styles';
import { ImageDialogProps } from './ImageDialog';

const selectButtonColor = (theme: ThemeProps, selectionProps: ImageDialogProps) => {
  const { palette } = theme;
  return selectionProps.darkXButton
    ? {
        '& i': {
          color: palette.darkColor,
        },
      }
    : {
        '& i': {
          color: palette.lightColor,
        },
      };
};

export const ImageDialogStyles = (theme: ThemeProps) => {
  return {
    media: {
      height: 338,
      width: 600,
      overflow: 'hidden',
    },
    image: {
      objectFit: 'cover',
    },
    bodyOverrideHeader: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: -78,
      overflow: 'hidden',
      textAlign: 'center',
      position: 'relative',
      minHeight: 511,
    },
    body: {
      paddingTop: 20,
      paddingLeft: 80,
      paddingRight: 80,
      paddingBottom: 40,
    },
    bodyLink: {
      paddingTop: 12,
    },
    title: {
      paddingBottom: 8,
    },
    header: {
      backgroundColor: 'transparent',
      zIndex: 1,
    },
    closeButton: (props: ImageDialogProps) => selectButtonColor(theme, props),
  };
};
