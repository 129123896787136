import * as React from 'react';
import { DialogProps } from 'styles';

import { ChangeCustomerDialog } from '../Dialogs/ChangeCustomerDialog';
import {
  OrganizationWizardContainer,
  OrganizationWizardFlow,
  OrganizationWizardView,
} from './OrganizationWizard';

enum View {
  ChangeCustomerWarning,
  CreateOrganization,
}

export const ChangeCustomerWizard: React.FC = () => {
  const [view, setView] = React.useState<View>(View.ChangeCustomerWarning);

  const onChange = () => setView(View.CreateOrganization);

  switch (view) {
    case View.CreateOrganization:
      return (
        <OrganizationWizardContainer
          flow={OrganizationWizardFlow.CreateOrganization}
          initialView={OrganizationWizardView.OrganizationName}
        />
      );
    case View.ChangeCustomerWarning:
      return <ChangeCustomerDialog onChange={onChange} />;
  }
};

export const openChangeCustomerWizard = (context: {
  openDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}) => {
  const dialogProps: DialogProps = {
    providedDialog: <ChangeCustomerWizard />,
  };
  context.openDialog(dialogProps);
};
