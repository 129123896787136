import { ButtonSharedProps, PrimaryButton, TextBody, TextBodyLarge, XLargerIcon } from 'components';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export interface InformationalTileProps {
  /**
   * Display as header
   */
  title?: string;
  /**
   * Information text
   */
  description: string | React.ReactElement;
  /**
   * Icon representation on the right side
   */
  iconName?: string;
  /**
   * Primary button to provide action based on information.
   */
  buttonProps?: ButtonSharedProps;
  /**
   * Displays at the bottom of the tile
   */
  additionalContent?: React.ReactNode;
  /**
   * Select from predefined styles without changing icon component
   * @default 'primary'
   */
  iconPredefinedStyle?: 'primary' | 'error';
}

const styles = (theme: ThemeProps) => {
  return {
    container: {
      display: 'flex',
      width: 400,
      height: 'fit-content',
      border: `1px solid ${theme.palette.backgroundDivider}`,
      borderRadius: 2,
      padding: '16px 20px',
    },
    title: { display: 'block', marginBottom: 2, fontWeight: theme.fonts.fontWeights.semiBold },
  };
};

type Props = InformationalTileProps & WithStyles<typeof styles>;

const InformationalTileUnstyled: React.FC<Props> = props => {
  const { classes } = props;

  // Conditional elements
  const icon = props.iconName ? (
    <XLargerIcon
      iconName={props.iconName}
      predefinedStyle={props.iconPredefinedStyle || 'primary'}
      styles={{ root: { marginRight: 16 } }}
    />
  ) : null;

  const title = props.title ? (
    <TextBodyLarge addClass={classes.title}>{props.title}</TextBodyLarge>
  ) : null;

  const button = props.buttonProps && (
    <PrimaryButton styles={{ root: { marginTop: 12 } }} {...props.buttonProps} />
  );

  return (
    <div className={classes.container}>
      {icon}
      <div>
        {title}
        <p style={{ margin: 0 }}>
          <TextBody>{props.description}</TextBody>
        </p>
        {button}
        {props.additionalContent}
      </div>
    </div>
  );
};

export const InformationalTile = withStyles(styles)(InformationalTileUnstyled);
InformationalTile.displayName = 'InformationalTile';
