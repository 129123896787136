import axios from 'axios';
import { TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader } from '../utils';
import { endpoints, NotificationConfig } from './config';
import { Notification } from './types';

async function getHeaders() {
  return {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
  };
}

export async function createNotification(
  request: Notification,
  config: NotificationConfig
): Promise<boolean> {
  const headers = await getHeaders();
  const url = endpoints[config.environment];
  const response = await axios.post(url, request, { headers });
  return !!response.data;
}
