import { ThemeProps } from 'styles';

export const AsideStyles = (theme: ThemeProps) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginTop: 30,
  },
  navLink: {
    minWidth: 145,
    textDecoration: 'none',
    padding: '7px 15px',
    color: theme.palette.text,

    '&.active': {
      color: theme.palette.text,
      backgroundColor: theme.palette.secondaryActive,
      borderLeft: `3px solid ${theme.palette.primary}`,
      paddingLeft: 12,
      fontWeight: 600,
    },

    '& i': {
      marginLeft: 6,
    },
  },
  heading: {
    minWidth: 145,
    textDecoration: 'none',
    padding: '7px 15px',
    color: theme.palette.text,
    marginTop: 15,
    fontWeight: 600,
  },
  azureLink: {
    width: 120,
    fontWeight: theme.fonts.fontWeights.regular,
    paddingLeft: 15,
    paddingRight: 20,
  },
  linkStyle: {
    fontWeight: theme.fonts.fontWeights.regular,
    fontSize: theme.fonts.fontSizes.medium,
  },
});
