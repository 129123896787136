import { BarButton, CommandBarButton } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import { DeleteProposalDialog } from './DeleteDialog';

export interface DeleteProposalButtonProps {
  disabled?: boolean;
  proposalId: string;
  provideCallback?: (callbackToProvide: () => void) => void;
  dataAutomationId?: string;
  fedIndirectEnabled?: boolean;
}

export const DeleteProposalCommandBarButton: React.FC<DeleteProposalButtonProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation('quote');

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <DeleteProposalDialog proposalId="deleteProposalDialog" />,
  };

  const handleOnClick = () => {
    if (!props.disabled) {
      context.openDialog(dialogProps);
    }
  };

  return (
    <CommandBarButton
      ariaLabel={t('quote::Delete quote')}
      dataAutomationId="deleteProposalButton"
      disabled={props.disabled}
      iconName="Delete"
      id="deleteProposalButton"
      text={t('quote::Delete')}
      onClick={handleOnClick}
    />
  );
};

export const DeleteProposalBarButton: React.FC<DeleteProposalButtonProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <DeleteProposalDialog proposalId={props.proposalId} />,
  };

  const handleOnClick = () => {
    if (!props.disabled) {
      context.openDialog(dialogProps);
    }
  };

  if (props.provideCallback) {
    props.provideCallback(handleOnClick);
  }

  return (
    <BarButton
      ariaLabel={t('quote::Delete quote')}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconName="Delete"
      id="deleteProposalButton"
      showNewIconColor={props.fedIndirectEnabled}
      text={t('quote::Delete')}
      onClick={handleOnClick}
    />
  );
};
