import { Dialog, LinkButton, PrimaryButton, TextBody, TextWatermarkSmall } from 'components/ions';
import { Autosuggest, AutosuggestFooter, Suggestion } from 'components/molecules';
import { NotFound } from 'components/molecules/Autosuggest/NotFound';
import { OrganizationInfo } from 'features-apollo/quote/components/OrganizationInfo';
import { GetSuggestedOrganizations } from 'features-apollo/quote/components/PropertySheets/Customer/queries';
import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import { OrganizationIdentifierInput, OrganizationSimple } from 'generated/graphql';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { useLazyQuery } from '@apollo/react-hooks';

import { AddressSuggestion, AddressSuggestionRow } from './AddressSuggestionRow';
import { dialogDimensions } from './FindOrganizationDialog';
import { findOrganizationViewStyles } from './FindOrganizationView.styles';

const SEARCH_ORGS_MAX_DISPLAY_LENGTH = 10;

export interface FindOrganizationViewProps {
  onApply: (values: OrganizationIdentifierInput) => void;
}

type Props = FindOrganizationViewProps & WithStyles<typeof findOrganizationViewStyles>;

const createSuggestion = (organization: OrganizationSimple) => {
  const suggestion: Suggestion<AddressSuggestion> = {
    ariaLabel: organization.name,
    key: organization.id,
    value: {
      accountId: organization.accountId,
      address: organization.address,
      lastPurchaseDateTime: organization.lastInvoiceDate,
      name: organization.name,
      organizationId: organization.id,
      tradeName: getValueOrUndefined(organization.tradeName),
    },
  };
  return suggestion;
};

export const findOrganizationDialogTitle = i18next.t('quote::Find Billing Account');

export const FindOrganizationViewUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const [selected, setSelected] = React.useState<AddressSuggestion | undefined>();
  const { t } = useTranslation();
  const onRenderRow = (suggestion: Suggestion<AddressSuggestion>) => {
    return <AddressSuggestionRow suggestion={suggestion} />;
  };

  const [
    searchOrgs,
    { loading: searchOrgsLoading, error: searchOrgsError, data: searchOrgsData },
  ] = useLazyQuery(GetSuggestedOrganizations);

  const onSelect = (suggestion: Suggestion<AddressSuggestion>) => {
    const { value } = suggestion;
    setSelected(value);
  };
  const onSearch = (query: string) => {
    searchOrgs({
      variables: {
        query,
      },
    });
  };
  const resetSelection = () => {
    setSelected(undefined);
  };

  const hasMoreFooter = !!(
    searchOrgsData && searchOrgsData.searchOrganizations.length > SEARCH_ORGS_MAX_DISPLAY_LENGTH
  ) ? (
    <AutosuggestFooter
      primaryText={t('Only {{searchOrganizationMaxLength}} results are shown.', {
        searchOrganizationMaxLength: SEARCH_ORGS_MAX_DISPLAY_LENGTH,
      })}
      secondaryText={t('Refine your results by adding more characters to your search term.')}
    />
  ) : (
    undefined
  );

  const suggestions: Suggestion<AddressSuggestion>[] = searchOrgsData
    ? searchOrgsData.searchOrganizations.map((organization: OrganizationSimple) =>
        createSuggestion(organization)
      )
    : [];

  const cap = Math.min(suggestions.length, SEARCH_ORGS_MAX_DISPLAY_LENGTH);
  const cappedSuggestions = suggestions.slice(0, cap);

  const searchTips = (
    <div className={props.classes.searchTipsContainer}>
      <TextWatermarkSmall addClass={props.classes.searchTips}>
        {t('quote::Search tips:')}
        <div />
        {t('quote::To return results beginning with your search term, add an "*" at the end.')}
        <div />
        {t('quote::To return results that contain your search term, add an "*" to both sides.')}
      </TextWatermarkSmall>
    </div>
  );

  let dialogContent;
  if (selected) {
    dialogContent = <OrganizationInfo {...selected} />;
  } else {
    dialogContent = (
      <>
        <Autosuggest
          autoFocus
          dataAutomationId="billingAccountLookup"
          error={searchOrgsError && t('quote::Sorry, we ran into an error searching')}
          icon={{ iconName: 'Search' }}
          isLoading={searchOrgsLoading}
          listFooter={hasMoreFooter}
          notFound={
            <NotFound primaryText={t('quote::No results found')} secondaryText={searchTips} />
          }
          placeholder={t('quote::Search for a billing account by name')}
          required={true}
          showButton
          strings={{
            listAriaLabel: t('quote::Find billing account'),
            loading: t('quote::Loading'),
            notFound: t('quote::No results found'),
          }}
          suggestions={cappedSuggestions}
          textboxLabel={t('quote::Billing account look up')}
          onRenderRow={onRenderRow}
          onSearch={onSearch}
          onSelect={onSelect}
        />
        {searchTips}
      </>
    );
  }
  const onApply = () => {
    selected &&
      props.onApply({
        accountId: selected.accountId,
        organizationId: selected.organizationId,
      });
  };

  const findOrganizationDialogFooterButtons = [
    <PrimaryButton
      dataAutomationId="findOrganizationDialogApply"
      disabled={!selected}
      key="apply-button"
      text={t('quote::Apply')}
      onClick={onApply}
    />,
  ];

  return (
    <Dialog
      {...dialogDimensions}
      dataAutomationId="findBillingAccountDialog"
      footerButtons={findOrganizationDialogFooterButtons}
      title={t('quote::Find billing account')}
    >
      <div className={classes.container}>
        <div className={classes.message}>
          <TextBody>
            {t('quote::Look for a billing account by searching using their name.')}
          </TextBody>
        </div>
        {dialogContent}
        {selected && (
          <LinkButton
            addClass={classes.changeOrganization}
            displayText={t('quote::change billing account')}
            onClick={resetSelection}
          />
        )}
      </div>
    </Dialog>
  );
};

export const FindOrganizationView = withStyles(findOrganizationViewStyles)(
  FindOrganizationViewUnstyled
);
