import React from 'react';
import { borderStyles } from './Border.styles';
import withStyles, { WithStyles } from 'react-jss';

/**
 * Creates a dashed border rectangle around the drop zone.
 * x and y are points used to draw the rectangle and set the width and height
 * of the space the rectangle is drawn within, respectively.
 * strokeWidth sets the thickness of each dash and is also used to offset for
 * the bottom of the rectangle's y-coordinates.
 * An offset is necessary so that the dash will be fully displayed. All other
 * points are offset by 1 for this reason.
 */

export interface BorderProps {
  color: string;
  x: number;
  y: number;
  strokeWidth: number;
}

type Props = BorderProps & WithStyles<typeof borderStyles>;

export const BorderUnstyled: React.FC<Props> = props => {
  const { color, x, y, strokeWidth, classes } = props;
  const offset = 1;
  return (
    <svg className={classes.canvas} height={y} width={x}>
      <line
        stroke={color}
        strokeDasharray="8,11"
        strokeWidth={strokeWidth}
        x1={offset}
        x2={x - offset}
        y1={offset}
        y2={offset}
      />
      <line
        stroke={color}
        strokeDasharray="8,11"
        strokeWidth={strokeWidth}
        x1={x - offset}
        x2={x - offset}
        y1={offset}
        y2={y - strokeWidth}
      />
      <line
        stroke={color}
        strokeDasharray="8,11"
        strokeWidth={strokeWidth}
        x1={offset}
        x2={x - offset}
        y1={y - strokeWidth}
        y2={y - strokeWidth}
      />
      <line
        stroke={color}
        strokeDasharray="8,11"
        strokeWidth={strokeWidth}
        x1={offset}
        x2={offset}
        y1={offset}
        y2={y - strokeWidth}
      />
    </svg>
  );
};

export const Border = withStyles(borderStyles)(BorderUnstyled);
