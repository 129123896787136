import { ApproveMessage, TextBodySmall, VerificationField } from 'components';
import { usingAccountExtensionsTestHeaders } from 'features/app/selectors';
import * as actions from 'features/customer/actions';
import {
  getOrganizations,
  getTenant as getTenantSelector,
  getTenantAccount as getTenantAccountSelector,
  searchingTenantFootprint,
} from 'features/customer/selectors';
import { TenantProfile } from 'features/customer/types';
import { domainTestEnvironmentIncludes, validGuid } from 'features/proposal/utils';
import i18next from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Account } from 'services/account/types';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { ViralTenantMessage } from '../Partials';
import {
  invalidTenantFormatMessage,
  invalidTestTenantFormatMessage,
  onValidateTenant,
} from '../shared';

const styles = (theme: ThemeProps) => ({
  description: { color: theme.palette.textTertiary, display: 'block', marginBottom: 24 },
  noFoundMessage: { paddingTop: 12, maxWidth: 400 },
  viralTenantMessage: { paddingTop: 12 },
});

const mapStateToProps = (state: RootState) => {
  const searchingTenantAccount = searchingTenantFootprint(state);
  const getTenant = (tenant: string) => getTenantSelector(state, tenant);
  const getTenantAccount = (tenant: string) => getTenantAccountSelector(state, tenant);
  const getBillingAccounts = (accountId: string) => getOrganizations(state, accountId);
  return {
    searchingTenantAccount,
    getTenant,
    getTenantAccount,
    getBillingAccounts,
    usingTestHeaders: usingAccountExtensionsTestHeaders(state),
  };
};

const dispatchProps = {
  searchForAccount: actions.searchTenantFootprintAsync.request,
};

interface SearchAccountProps {
  onValid: (result?: TenantProfile | Account) => void;
  onInvalid: () => void;
}

type Props = SearchAccountProps &
  WithStyles<typeof styles> &
  typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

const SearchAccountBodyUnstyled: React.FC<Props> = props => {
  const {
    classes,
    getBillingAccounts,
    getTenant,
    getTenantAccount,
    onInvalid,
    onValid,
    searchForAccount,
    searchingTenantAccount,
    usingTestHeaders,
  } = props;
  const { t } = useTranslation();
  const [lastVerified, setLastVerified] = React.useState<string>('');
  const [currentValue, setCurrentValue] = React.useState<string | undefined>();

  const searchSuccessful =
    !searchingTenantAccount.loading && !searchingTenantAccount.error && !!lastVerified;
  const tenant = getTenant(lastVerified);
  const account = getTenantAccount(lastVerified);
  const tenantAccountFound = !!(lastVerified && account);
  const accountNotFound = searchSuccessful && !tenantAccountFound;
  const isDifferent = lastVerified !== currentValue;
  const containsTestTest =
    oc(currentValue)('')
      .toLowerCase()
      .includes(domainTestEnvironmentIncludes) && !validGuid.test(oc(currentValue)(''));
  const validDomain = usingTestHeaders ? containsTestTest : true;

  const isViral = tenant && tenant.isViral;

  React.useEffect(() => {
    if (!searchSuccessful || isViral || isDifferent || !validDomain) {
      onInvalid();
    } else {
      onValid(account || tenant || undefined);
    }
  }, [searchSuccessful, isViral, isDifferent, account, tenant, onValid, onInvalid, validDomain]);

  const description = (
    <TextBodySmall addClass={classes.description}>
      {t(
        "quote::Before creating a new modern account and billing account, let's make sure the customer does not already have an existing account."
      )}
    </TextBodySmall>
  );

  const onSearch = (tenant: string) => {
    setLastVerified(tenant);
    searchForAccount(tenant);
  };

  const errorMessage = t('quote::We encountered a problem while searching. Please try again.');
  const invalidDomainMessage = validDomain
    ? invalidTenantFormatMessage()
    : invalidTestTenantFormatMessage();

  const validateInput = (value: string) => {
    return onValidateTenant(value, usingTestHeaders);
  };

  const searchInput = (
    <VerificationField
      allowTryAgain={!!(searchingTenantAccount.error && currentValue)} //Only enable try again when there is something in the field
      buttonText={t('Search')}
      dataAutomationId="domainNameOrTenantId"
      errorMessage={errorMessage}
      isError={!!searchingTenantAccount.error}
      isLoading={!!searchingTenantAccount.loading}
      isVerified={validDomain}
      lastVerified={lastVerified} // This feature does not required to verify input but this is required to be able to display error messages
      showButtonWhenVerified
      textboxLabel={t('quote::Domain name or tenant ID')}
      textboxPlaceholder={t('quote::e.g. contoso.com')}
      validationErrorMessage={invalidDomainMessage}
      onChangeDebounced={(value?: string) => setCurrentValue(value)}
      onValidate={validateInput}
      onVerify={onSearch}
    />
  );

  const noAccountFoundMessage = accountNotFound && (
    <div className={classes.noFoundMessage}>
      <ApproveMessage mainMessage={t('quote::No account found')}>
        {t(
          'quote::We did not find a modern account associated with the specified domain or tenant. Please proceed with creating a new account and billing account.'
        )}
      </ApproveMessage>
    </div>
  );

  const billingAccounts = account && getBillingAccounts(account.id);
  const noOrganizationFoundMessage = billingAccounts && !billingAccounts.length && (
    <div className={classes.noFoundMessage}>
      <ApproveMessage mainMessage={t('quote::No billing account found')}>
        {t(
          'quote::We did not find a billing account on the account of the specified domain or tenant. Please proceed with creating a new billing account.'
        )}
      </ApproveMessage>
    </div>
  );

  const viralTenantErrorMessage = isViral ? (
    <div className={classes.viralTenantMessage}>
      <ViralTenantMessage />
    </div>
  ) : null;

  return (
    <>
      {description}
      {searchInput}
      {!isDifferent &&
        (viralTenantErrorMessage || noAccountFoundMessage || noOrganizationFoundMessage)}
    </>
  );
};

// #region Exports
// Title to be use for Find Organization With Tenant Dialog
export const searchAccountTitle = () => i18next.t('quote::Before we start...');
export const SearchAccountBodyStyled = withStyles(styles)(SearchAccountBodyUnstyled) as React.FC<
  SearchAccountProps
>;
export const SearchAccountBody = connect(mapStateToProps, dispatchProps)(SearchAccountBodyStyled);
// #endregion
