import { LinkButton, TextBody } from 'components/ions';
import * as React from 'react';

export interface ShowMoreProps {
  previewMessage: React.ReactElement;
  moreMessage: React.ReactElement;
  initialLinkText: string;
  changeLinkText: string;
  id: string;
  textId: string;
  linkId: string;
  linkClass?: string;
}

export const ShowMore: React.FC<ShowMoreProps> = props => {
  const [showMore, setShowMore] = React.useState(false);

  return (
    <div id={props.id}>
      <TextBody id={props.textId}>
        {props.previewMessage}
        {showMore && props.moreMessage}
      </TextBody>
      <div />
      <LinkButton
        displayText={showMore ? props.changeLinkText : props.initialLinkText}
        id={props.linkId}
        size="medium"
        onClick={() => setShowMore(!showMore)}
        addClass={props.linkClass}
      />
    </div>
  );
};
