import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CreatePurchaseRecordRequest } from 'services/purchase/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { Success } from '../Shared';
import { WarningView } from './WarningView';

enum DialogSteps {
  WarningView,
  Processing,
  Success,
  Fail,
}

const dialogDimenstions = {
  height: 264,
  width: 448,
};

// Props ----------------------------------------------------------------
const mapStateToProps = (state: RootState) => ({
  market: selectors.getMarket(state),
  proposal: selectors.getActiveProposal(state),
  transacting: state.proposal.views.editor.transactingProposal,
});

const dispatchProps = {
  createPurchaseRecord: (request: CreatePurchaseRecordRequest) =>
    actions.createPurchaseRecordAsync.request(request),
  clear: () => actions.clearTransactingProposal(),
};

export type TransactProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const TransactDialogFeature: React.FC<TransactProps> = props => {
  const { proposal } = props;
  const [currentStep, setCurrentStep] = React.useState<DialogSteps>(DialogSteps.WarningView);
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    props.clear();
    context.closeDialog();
  };

  const transactProposal = () => {
    const request: CreatePurchaseRecordRequest = {
      market: props.market,
      quoteId: proposal.id,
    };

    props.createPurchaseRecord(request);
    setCurrentStep(DialogSteps.Processing);
  };

  // Verify proposal transact status ------------------------------------------
  React.useEffect(() => {
    if (props.transacting === 'failure') {
      setCurrentStep(DialogSteps.Fail);
    } else if (props.transacting === 'success') {
      setCurrentStep(DialogSteps.Success);
    }
  }, [props.transacting, setCurrentStep]);

  // Switch views -----------------------------------------------------------
  const getCurrentView = (step: DialogSteps) => {
    switch (step) {
      case DialogSteps.Processing:
        return (
          <Processing
            {...dialogDimenstions}
            message1={t('quote::The quote is being transacted.')}
          />
        );
      case DialogSteps.Fail:
        return (
          <Fail
            {...dialogDimenstions}
            closeDialog={closeDialog}
            dataAutomationId="transactFail"
            message={t('quote::Sorry, the "Transact" action failed.')}
            onTryAgainClick={transactProposal}
          />
        );
      case DialogSteps.Success:
        return (
          <Success
            {...dialogDimenstions}
            closeDialog={closeDialog}
            dataAutomationId="transactSuccess"
            message={t('quote::The quote has been transacted.')}
          />
        );
      case DialogSteps.WarningView:
      default:
        return (
          <WarningView dispatchAction={transactProposal} proposalName={proposal.header.name} />
        );
    }
  };

  return getCurrentView(currentStep);
};

export const TransactDialog = connect(mapStateToProps, dispatchProps)(TransactDialogFeature);
