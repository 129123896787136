import { ErrorMessage, LinkButton, Spinner, SpinnerSize } from 'components';
import { RoleAssignmentsQuery } from 'features-apollo/quote/components/queries';
import { loadTenantUpnsAsync } from 'features/customer/actions';
import {
  getTenantAdmins,
  getTenantContributors,
  getTenantOwners,
  tenantAdminsProcessing,
  tenantUpnsProcessing,
} from 'features/customer/selectors';
import { RoleAssignments } from 'features/customer/types';
import {
  createCompleteList,
  createPartialList,
  ExistingOwner,
  LoadingErrorType,
  ownerRoleIds,
  OwnerType,
} from 'features/proposal/components/ExistingOwners';
import { getAccountId, getActiveProposal, getOrganizationId } from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { GetTenantUpns } from 'services/externaluser/types';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { useQuery } from '@apollo/react-hooks';

import { ExistingOwnersDialog } from './ExistingOwnersDialog';
import { existingOwnersDialogStyles } from './ExistingOwnersDialog.styles';

export const LOADING_TEST_IDENTIFIER = 'existing-owners-link-loading';

export interface ExistingOwnersLinkButtonProps {
  id: string;
  tenantId: string;
  invitedUser?: string;
}

const mapStateToProps = (state: RootState, ownProps: ExistingOwnersLinkButtonProps) => {
  const proposal = getActiveProposal(state);
  return {
    tenantAdmins: getTenantAdmins(state, ownProps.tenantId),
    tenantOwners: getTenantOwners(state),
    tenantContributors: getTenantContributors(state),
    accountId: getAccountId(state, proposal),
    organizationId: getOrganizationId(state),
    errorLoadingAdmins: tenantAdminsProcessing(state).error,
    errorLoadingUpns: tenantUpnsProcessing(state).error,
    loadingTenantAdmins: tenantAdminsProcessing(state).loading,
    loadingTenantUpns: tenantUpnsProcessing(state).loading,
  };
};

const dispatchProps = {
  loadTenantUpns: loadTenantUpnsAsync.request,
};

export type ExistingOwnersLinkButtonStateProps = ExistingOwnersLinkButtonProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

type Props = ExistingOwnersLinkButtonStateProps & WithStyles<typeof existingOwnersDialogStyles>;

export const ExistingOwnersLinkButtonUnstyled: React.FC<Props> = ({
  classes,
  id,
  tenantAdmins,
  tenantOwners,
  tenantContributors,
  accountId,
  organizationId,
  errorLoadingAdmins,
  errorLoadingUpns,
  loadingTenantAdmins,
  loadTenantUpns,
  loadingTenantUpns,
  invitedUser,
}: Props) => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  const [errorLoadingOwners, setErrorLoadingOwners] = React.useState<boolean>(false);
  const [ownersExist, setOwnersExist] = React.useState<boolean>(false);
  const [loadingError, setLoadingError] = React.useState<LoadingErrorType>(LoadingErrorType.None);
  const [displayList, setDisplayList] = React.useState<ExistingOwner[]>([]);
  const [isInvitedUserOnlyOwner, setIsInvitedUserOnlyOwner] = React.useState<boolean>(false);

  const { loading: loadingTenantRoles, error, data } = useQuery(RoleAssignmentsQuery, {
    variables: {
      input: {
        roles: ownerRoleIds,
        organization: {
          accountId,
          organizationId,
        },
      },
    },
  });

  React.useEffect(() => {
    if (!loadingTenantRoles) {
      if (error) {
        setErrorLoadingOwners(true);
      } else if (data) {
        const roleAssignments = data.getRoleAssignments;
        const request: GetTenantUpns[] = [];
        roleAssignments.forEach((roleAssignment: RoleAssignments) => {
          if (roleAssignment.principalTenantId) {
            request.push({
              roleId: roleAssignment.roleId,
              tenantId: roleAssignment.principalTenantId,
              objectId: roleAssignment.principalId,
            });
          }
        });
        loadTenantUpns(request);
      }
    }
  }, [loadingTenantRoles, data, loadTenantUpns, error]);

  React.useEffect(() => {
    let list: ExistingOwner[] = [];
    let error: LoadingErrorType = LoadingErrorType.None;
    const ownerError: boolean = errorLoadingOwners || errorLoadingUpns;
    if (ownerError && errorLoadingAdmins) {
      error = LoadingErrorType.ErrorLoadingEverything;
    } else if (ownerError) {
      list = createPartialList(tenantAdmins, OwnerType.GlobalAdmin);
      error = LoadingErrorType.ErrorLoadingOwners;
    } else if (errorLoadingAdmins) {
      const ownerList = createPartialList(tenantOwners, OwnerType.Owner);
      const contributorList = createPartialList(tenantContributors, OwnerType.Contributor);
      list = ownerList.concat(contributorList);
      error = LoadingErrorType.ErrorLoadingAdmins;
    } else {
      list = createCompleteList(tenantOwners, tenantContributors, tenantAdmins);

      if (list.length && !ownersExist) {
        setOwnersExist(true);
      } else if (!list.length && ownersExist) {
        setOwnersExist(false);
      }
    }

    const upnList = list.map(item => item.upn);
    if (invitedUser && upnList.includes(invitedUser)) {
      upnList.length === 1 && setIsInvitedUserOnlyOwner(true);
      list = list.filter(item => item.upn !== invitedUser);
    }
    setDisplayList(list);
    setLoadingError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    errorLoadingOwners,
    errorLoadingUpns,
    errorLoadingAdmins,
    setDisplayList,
    setLoadingError,
    tenantAdmins,
    tenantOwners,
  ]);

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <ExistingOwnersDialog error={loadingError} list={displayList} />,
  };

  const handleOnClick = () => {
    context.openDialog(dialogProps);
    loggerService.log({
      name: 'Existing Owners - user has viewed existing owners after quote is transacted',
    });
  };

  const linkButton = (
    <LinkButton
      addClass={classes.link}
      displayText={t('quote::view other owners in this tenant')}
      id={id}
      size="medium"
      onClick={handleOnClick}
    />
  );

  const spinner = (
    <Spinner
      addClass={classes.spinner}
      dataAutomationId={LOADING_TEST_IDENTIFIER}
      id="existing-owners-loading-spinner"
      size={SpinnerSize.medium}
    />
  );
  const showSpinner = loadingTenantRoles || loadingTenantAdmins || loadingTenantUpns;
  const showLink =
    !isInvitedUserOnlyOwner &&
    (ownersExist || (!ownersExist && loadingError === LoadingErrorType.ErrorLoadingEverything));

  if (showSpinner) {
    return spinner;
  } else if (loadingError === LoadingErrorType.ErrorLoadingEverything) {
    return (
      <ErrorMessage
        mainMessage={t('quote::Sorry, we failed to return other owners on the tenant')}
      />
    );
  } else {
    return showLink ? linkButton : null;
  }
};

export const ExistingOwnersLinkButtonUnconnected = withStyles(existingOwnersDialogStyles)(
  ExistingOwnersLinkButtonUnstyled
);

export const ExistingOwnersLinkButton = connect(
  mapStateToProps,
  dispatchProps
)(ExistingOwnersLinkButtonUnconnected);
