import {
  Column,
  ColumnMap,
  FinderItem,
  ItemMap,
  Entities,
} from 'features/proposal/components/Finder';
import { buildProductIdentifier } from 'features/proposal/utils';
import {
  BaseRecommendationAction,
  CatalogContext,
  ProductRecommendationAction,
  Quote,
} from 'generated/graphql';
import { Product } from 'services/catalog/types';
import { FavoriteMenuButton } from 'features-apollo/components/Favorites';
import { OneAskResult } from 'services/proposal/types';
import { RecoChannelProducts, SearchResultProduct, ProductType } from 'features/catalog';
import { ProductGroups } from 'services/user-preferences/types';
import React from 'react';
import { oc } from 'ts-optchain';

export const emptyEntities: Entities = {
  columnOrder: [],
  columns: {},
  items: {},
};

export const getEntities = (
  quote: Quote,
  isReadOnly: boolean,
  recoProducts: BaseRecommendationAction[] | undefined,
  showECIF: boolean,
  ecifProduct: RecoChannelProducts[],
  oneAskResults: OneAskResult[],
  isFavoriteProductDisabled: (product: SearchResultProduct) => boolean,
  userFavoriteProductGroups?: ProductGroups,
  searchResults?: SearchResultProduct[],
  catalogContext?: CatalogContext
) => {
  const items: FinderItem[][] = [];
  const columns: Column[] = [];
  const groupNames: string[] = [];

  let channelItems: FinderItem[];
  if (recoProducts) {
    if (showECIF) {
      channelItems = recoProducts
        .filter(item => {
          const productAction = item as ProductRecommendationAction;
          return productAction.product.productType !== ProductType.ECIF;
        })
        .map(item => {
          const productAction = item as ProductRecommendationAction;
          const isProductAtMaxQuantity =
            !!productAction.product.maxQuantityOnQuote &&
            quote.lineItems.filter(
              lineItem => lineItem.product && lineItem.product.id === productAction.product.id
            ).length >= productAction.product.maxQuantityOnQuote;
          return {
            id: productAction.product.id,
            productIdentifier: {
              productId: productAction.product.id,
              productType: productAction.product.productType,
            },
            itemText: productAction.product.title, // TODO: need to get ProductTitle from the LocalizedProperties element with Language === current language
            disabled: isProductAtMaxQuantity || isReadOnly,
            maxQuantityOnProposal: productAction.product.maxQuantityOnQuote || 0,
          };
        });
    } else {
      channelItems = recoProducts.map(item => {
        const productAction = item as ProductRecommendationAction;
        const isProductAtMaxQuantity =
          !!productAction.product.maxQuantityOnQuote &&
          quote.lineItems.filter(
            lineItem => lineItem.product && lineItem.product.id === productAction.product.id
          ).length >= productAction.product.maxQuantityOnQuote;
        return {
          id: productAction.product.id,
          productIdentifier: {
            productId: productAction.product.id,
            productType: productAction.product.productType,
          },
          itemText: productAction.product.title, // TODO: need to get ProductTitle from the LocalizedProperties element with Language === current language
          disabled: isProductAtMaxQuantity || isReadOnly,
          maxQuantityOnProposal: productAction.product.maxQuantityOnQuote || 0,
        };
      });
    }

    items.push(channelItems);
    columns.push({
      id: 'finderList',
      itemIds: channelItems.map((item: FinderItem): string => item.id),
    });
  }

  ecifProduct.forEach((channel: RecoChannelProducts) => {
    const isValidChannel = !!oc(channel).products[0].LocalizedProperties[0].ProductTitle();

    if (isValidChannel) {
      const channelItems: FinderItem[] = channel.products
        .map((product: Product) => {
          return oneAskResults.map(result => {
            const isProductAtMaxQuantity =
              !!product.Properties.MaxQuantityOnProposal &&
              quote.lineItems.filter(item => item.product && item.product.id === product.ProductId)
                .length >= product.Properties.MaxQuantityOnProposal;
            return {
              id: result.RequestNumber,
              productIdentifier: buildProductIdentifier(product),
              itemText: product.LocalizedProperties[0].ProductTitle, // TODO: need to get ProductTitle from the LocalizedProperties element with Language === current language
              disabled: isProductAtMaxQuantity,
              maxQuantityOnProposal: product.Properties.MaxQuantityOnProposal,
            };
          });
        })
        .reduce((acc, val) => acc.concat(val), []);

      items.push(channelItems);
      columns.push({
        id: 'ecifList',
        itemIds: channelItems.map((item: FinderItem): string => item.id),
      });
    }
  });
  if (userFavoriteProductGroups) {
    for (let groupName in userFavoriteProductGroups) {
      groupNames.push(groupName);
      items.push(
        userFavoriteProductGroups[groupName].products.map((product: SearchResultProduct) => {
          return {
            id: `${groupName}-${product.productId}`,
            productIdentifier: product.productIdentifier,
            itemText: product.productName,
            favoriteButton: (
              <FavoriteMenuButton
                catalogContext={catalogContext}
                favoritesProductGroups={userFavoriteProductGroups}
                groupName={groupName}
                product={product}
                quoteId={quote.id}
              />
            ),
            disabled: isFavoriteProductDisabled(product) || isReadOnly,
          };
        })
      );
      columns.push({
        id: `favoritesList-${groupName}`,
        itemIds: userFavoriteProductGroups[groupName].products.map(
          (product: SearchResultProduct) => `${groupName}-${product.productId}`
        ),
      });
    }
  }

  if (searchResults) {
    items.push(
      searchResults.map((product: SearchResultProduct) => ({
        id: `SearchResults-${product.productId}`,
        productIdentifier: product.productIdentifier,
        itemText: product.productName,
      }))
    );
    columns.push({
      id: 'searchResultsList',
      itemIds: searchResults.map(
        (product: SearchResultProduct) => `SearchResults-${product.productId}`
      ),
    });
  }

  const lineItemsList: Column = {
    id: 'lineItemsList',
    itemIds: [],
  };

  const flattenedItems: FinderItem[] = [];
  if (columns) {
    const itemMap: ItemMap = flattenedItems
      .concat(...items)
      .reduce((previous: ItemMap, current: FinderItem): ItemMap => {
        if (current) {
          previous[current.id] = current;
        }
        return previous;
      }, {});
    const finderColumnMap: ColumnMap =
      columns &&
      columns.reduce((previous: ColumnMap, currentValue: Column) => {
        previous[currentValue.id] = currentValue;
        return previous;
      }, {});
    const intitialEntities: Entities = {
      columnOrder: [
        'finderList',
        ...groupNames.map((groupName: string) => `favoritesList-${groupName}`),
        'searchResultsList',
        'lineItemsList',
        'ecifList',
      ],
      columns: {
        ...finderColumnMap,
        [lineItemsList.id]: lineItemsList,
      },
      items: itemMap,
    };
    return intitialEntities;
  }
  return emptyEntities;
};
