import { LinkExternal, NotificationItem, TextBodySmall } from 'components';
import {
  DetailsFlights,
  DetailsStyles,
  renderNotifications,
} from 'features-apollo/quote/components/DetailsPane';
import { formatCurrency } from 'features-apollo/quote/components/utils';
import { CatalogItemType } from 'features-apollo/quote/types';
import { formatDate, getStartConditionForDisplay } from 'features-apollo/quote/utils';
import { isPreApprovedEcif } from 'features-apollo/selectors';
import { EcifLineItem, Quote } from 'generated/graphql';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

interface ECIFService {
  shortName: string;
  amount: string;
}

export interface EcifDetailsProps {
  currency: string;
  oneAskCaseNumber: string;
  oneAskLink: string;
  totalAmountApproved: string;
  finalAmount?: string;
  types?: string[];
  productFamilies?: string[];
  endDate?: string;
  lineItem: EcifLineItem;
  services?: ECIFService[];
  notifications: NotificationItem[];
  quote?: Quote;
  flights?: DetailsFlights;
}

type Props = EcifDetailsProps & WithStyles<typeof DetailsStyles>;

const Amount: React.FC<{ amount: string; currency?: string }> = props => {
  return (
    <TextBodySmall>
      {formatCurrency(props.amount, 0)}
      {props.currency && <small>{` ${props.currency}`}</small>}
    </TextBodySmall>
  );
};

const ServicesList: React.FC<{
  services: ECIFService[];
  total: string;
  addClass: string;
  currency: string;
}> = props => {
  return (
    <>
      <table className={props.addClass}>
        <tbody>
          {props.services.map(({ shortName, amount }, index) => (
            <tr key={index}>
              <td>
                <TextBodySmall>{shortName}</TextBodySmall>
              </td>
              <td>
                <Amount amount={amount} />
              </td>
            </tr>
          ))}
          <tr>
            <td />
            <td colSpan={2}>
              <TextBodySmall>
                <Trans ns="quote">
                  Total: <Amount amount={props.total} currency={props.currency} />
                </Trans>
              </TextBodySmall>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const EcifLineItemDetailsUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    currency,
    oneAskCaseNumber,
    oneAskLink,
    totalAmountApproved,
    finalAmount,
    types,
    productFamilies,
    endDate,
    services,
    notifications,
    quote,
    lineItem,
    flights,
  } = props;
  const { t } = useTranslation();
  const createTypesList = () => {
    if (types && types.length) {
      const uniqueTypes = [...new Set(types)];
      return uniqueTypes.join(` ${t('quote::and')} `);
    }
    return '-';
  };
  return flights && flights.enableECIFUpdates && isPreApprovedEcif(lineItem as EcifLineItem) ? (
    <>
      <div className={classes.sectionDetails}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>OneAsk ID:</TextBodySmall>
              </td>
              <td>
                {oneAskLink ? (
                  <LinkExternal
                    addClass={classes.link}
                    displayText={oneAskCaseNumber}
                    href={oneAskLink}
                  />
                ) : (
                  '-'
                )}
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::Approved for:')}
                </TextBodySmall>
              </td>
              <td>
                <Amount amount={totalAmountApproved} currency={currency} />
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::Final amount:')}
                </TextBodySmall>
              </td>
              <td>
                {finalAmount ? (
                  <Amount amount={finalAmount} currency={finalAmount && currency} />
                ) : (
                  <TextBodySmall>-</TextBodySmall>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>{t('quote::Type:')}</TextBodySmall>
              </td>
              <td>
                <TextBodySmall>{createTypesList()}</TextBodySmall>
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::Product family:')}
                </TextBodySmall>
              </td>
              <td>
                {productFamilies && productFamilies.length ? (
                  [...new Set(productFamilies)].map(productFamily => (
                    <TextBodySmall block key={productFamily}>
                      {productFamily}
                    </TextBodySmall>
                  ))
                ) : (
                  <TextBodySmall>-</TextBodySmall>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>{t('quote::Start:')}</TextBodySmall>
              </td>
              <td>
                <TextBodySmall>
                  {getStartConditionForDisplay({ catalogItemType: CatalogItemType.Ecif })}
                </TextBodySmall>
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>{t('quote::End:')}</TextBodySmall>
              </td>
              <td>
                <TextBodySmall>
                  {endDate && endDate !== '-'
                    ? formatDate(new Date(endDate), 'MMMM DD, YYYY')
                    : '-'}
                </TextBodySmall>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {renderNotifications(notifications, classes, quote, lineItem)}
      <div className={classes.sections}>
        <TextBodySmall addClass={classes.ecifLabels}>
          {t('quote::Consulting and product support services')}
        </TextBodySmall>
        {services && services.length && finalAmount ? (
          <ServicesList
            addClass={classes.servicesListTable}
            currency={props.currency}
            services={services}
            total={finalAmount}
          />
        ) : (
          <TextBodySmall addClass={classes.servicesNone} block>
            {t('quote::none')}
          </TextBodySmall>
        )}
      </div>
    </>
  ) : (
    <div className={classes.sectionDetails}>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td>
              <TextBodySmall addClass={classes.ecifLabels}>{t('quote::TermId:')}</TextBodySmall>
            </td>
            <td>
              <TextBodySmall>{oc(lineItem).supplementalTermReferenceData.value('-')}</TextBodySmall>
            </td>
          </tr>
          <tr>
            <td>
              <TextBodySmall addClass={classes.ecifLabels}>{t('quote::Start:')}</TextBodySmall>
            </td>
            <td>
              <TextBodySmall>
                {getStartConditionForDisplay({ catalogItemType: CatalogItemType.Ecif })}
              </TextBodySmall>
            </td>
          </tr>
          <tr>
            <td>
              <TextBodySmall addClass={classes.ecifLabels}>{t('quote::End:')}</TextBodySmall>
            </td>
            <td>
              <TextBodySmall>
                {endDate && endDate !== '-' ? formatDate(new Date(endDate), 'MMMM DD, YYYY') : '-'}
              </TextBodySmall>
            </td>
          </tr>
        </tbody>
      </table>
      {renderNotifications(notifications, classes, quote)}
    </div>
  );
};

export const EcifLineItemDetails = withStyles(DetailsStyles)(EcifLineItemDetailsUnstyled);
