/* eslint-disable @typescript-eslint/camelcase */
import { LinkEmail, NotificationItem, notificationOrigin, NotificationType } from 'components/ions';
import { getFlightIsEnabled } from 'features/app/selectors';
import { getProduct, getProductsFailed } from 'features/catalog/selectors';
import * as customerSelectors from 'features/customer/selectors';
import { VatIdLinkButton } from 'features/proposal/components';
import { AddBillingContactButton } from 'features/proposal/components/Buttons';
import { isMonetary } from 'features/proposal/components/ConfigCard/ConfigCardBusinessLogic';
import {
  CreditLineReason,
  RequestCreditIncreaseButton,
  RequestCreditLineButton,
  RequestCreditLineButtonProps,
} from 'features/proposal/components/Dialogs/CreditDialog';
import {
  getDuplicateAzurePlan,
  hasCRMId,
  isAgreementTypeLegacy,
  isProposalReadOnly,
  lineItemStartDateInPast,
} from 'features/proposal/utils';
import i18next from 'i18next';
import * as React from 'react';
import { createSelector } from 'reselect';
import { ProductFamily, ServiceFamily, Sku } from 'services/catalog/types';
import { Asset } from 'services/edge/types';
import { Flight } from 'services/flights/flightList';
import {
  ClaimType,
  maxDiscountableLineItems,
  maxPurchasableLineItems,
  productIds,
  totalAzurePlansAvailable,
} from 'services/proposal/config';
import { LineItem, Proposal, ProposalStatus } from 'services/proposal/types';
import { PurchaseRecordSummary } from 'services/purchase/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { FontSizes } from 'styles';
import { oc } from 'ts-optchain';

import {
  AddCRMId,
  AddTenant,
  AddWorkAccount,
  EditOrganization,
  OpenConfigCard,
  RemoveEnrollmentNumber,
  SelectExpiringEnrollmentIntent,
  SelectOrganization,
  ViewInSales,
} from '../components/Notifications/Notifications';
import { DuplicateType } from '../types';
import {
  getLineItemProduct,
  getLineItemProductOrSku,
  getProductIdentifier,
  isDiscounted,
  isDiscountFulfillmentDocument,
  needsConfiguration,
  needsDiscount,
} from './lineItem';
import {
  canPublish,
  getAccountId,
  getActiveProposal,
  getAnnualDealEstimate,
  getCreditLineStatus,
  getEnrollmentNumber,
  getMarket,
  getMinimumCreditLine,
  getOrganizationId,
  getPrices,
  hasIncompleteOrganization,
  hasSoldTo,
  isQuotePublished,
  missingOrganizationContactInfo,
  proposalHasExtendedPrice,
} from './proposal';
import { createSaasRenewalPriceInfoNotification } from './utils';

export const validationTitles = {
  brokenLineItems: t('quote::Broken line items'),
  brokenProduct: t('quote::Broken products'),
  brokenTerms: t('quote::Broken terms'),
  removeLineItem: t('quote::Remove'),
  maxDiscount: t('quote::Max discounts'),
  maxPurchase: t('quote::Max purchases'),
  quantity: t('quote::Quantity'),
  discountRequired: t('quote::Discount required'),
  duplicateAzurePlan: t('quote::Duplicate Azure Plans'),
  configurationRequired: t('quote::Configuration required'),
  missingAzurePlan: t('quote::Missing Azure plan'),
  missingCustomerTenant: t('quote::Missing customer tenant'),
  missingCRMId: t('quote::Missing CRM ID'),
  missingOrganization: t('quote::Missing Billing Account'),
  missingOrganizationContact: t('quote::Missing billing account contact'),
  missingWorkAccount: t('quote::Missing customer work account'),
  missingDealEstimate: t('quote::Missing annual deal estimate'),
  invalidWorkAccount: t('quote::Invalid customer work account'),
  noCustomerCredit: t('quote::No credit line'),
  insufficientCredit: t('quote::Insufficient credit'),
  pendingCredit: t('quote::Pending credit review'),
  rejectedCredit: t('quote::Credit rejected'),
  monetaryCredit: t('quote::Azure credit terms'),
  actionRequired: t('quote::Action required'),
  transactFailed: t('quote::Transact failed'),
  transactRetry: t('quote::Transact attempt failed'),
  invalidEnrollmentNumber: t('quote::Invalid enrollment number'),
  applicableLaw: t('quote::Applicable law'),
  applicableLawApprovalLevel: t('quote::Approval required'),
  missingDependency: t('quote::Missing dependency'),
  financed: t('quote::Financed'),
  incompleteBillingAccount: t('quote::Incomplete billing account'),
  pastStartDate: t('quote::Start date occurs in the past'),
  configRequiredTermId: (term: string) => t('quote::Configure {{term}} ID', { term }),
  sapTermAndEnrollmentNumber: t('quote::Migration to modern'),
  saasTrailRenewal: t('quote::Trial'),
  skuMismatch: t('quote::Product/SKU no longer available'),
  expiringEnrollmentIntent: t('quote::Expiring enrollment intent'),
  vatIdNotAdded: t('quote::VAT number not added'),
};

export const validationMessages = {
  brokenLineItems: (productsCount: number, termsCount: number) => {
    const products = i18next.t('quote::{{count}} product', {
      count: productsCount,
      defaultValue_plural: '{{count}} products',
    });
    const terms = i18next.t('quote::{{count}} term', {
      count: termsCount,
      defaultValue_plural: '{{count}} terms',
    });

    return t('quote::{{products}} and {{terms}} have errors and need your attention.', {
      products,
      terms,
    });
  },
  brokenProduct: (count: number) =>
    t('quote::{{count}} product has errors and needs your attention.', {
      count,
      defaultValue_plural: '{{count}} products have errors and needs your attention.',
    }),
  brokenTerm: (count: number) =>
    t('quote::{{count}} term has errors and needs your attention.', {
      count,
      defaultValue_plural: '{{count}} terms have errors and needs your attention.',
    }),
  errorInvalidLineItem: t(
    "quote::Please delete this line item as it's no longer available. If this is not as expected contact Quote Center Support."
  ),
  maxDiscountsMessage: (maxDiscounts: string) =>
    t(
      'quote::A quote may only have {{maxDiscounts}} discount items. If you need more than {{maxDiscounts}}, please split the order across multiple quotes.',
      { maxDiscounts }
    ),
  maxPurchasesMessage: (maxPurchases: string) =>
    t(
      'quote::A quote may only have {{maxPurchases}} purchasable items. If you need more than {{maxPurchases}}, please split the order across multiple quotes.',
      { maxPurchases }
    ),
  quantityError: (min: string, max: string) =>
    t('Quantity must be between {{min}} and {{max}}.', { min, max }),
  discountRequiredMessage: t(
    'quote::This item may only be configured as a discount. To add a discount, click directly on the discount field.'
  ),
  configurationRequiredMessage: t('quote::This item has not been configured yet.'),
  customTermConfigurationRequiredMessage: t(
    'quote::The term must be authored, approved and published before it can be linked to the quote. Whoever created the term should be able to give you the ID.'
  ),
  configurationOrDiscountRequiredOneMessage: t(
    'quote::There is 1 line item which needs to be configured and/or discounted. Line items that require further action are indicated with a yellow border.'
  ),
  configurationOrDiscountRequiredMessage: (total: string) =>
    t(
      'There are {{total}} line items which need to be configured and/or discounted. Line items that require further action are indicated with a yellow border.',
      { total }
    ),
  missingAzurePlan: t(
    'quote::Customers renewing from EA to MCA need both Azure Plan and Azure Plan for Devtest. Please make sure both are added to the quote.'
  ),
  missingCustomerTenant: t('quote::This account does not have a valid tenant'),
  missingSignupEmailAddress: t(
    'quote:: You must define the email address customer will use to login and create the new account.'
  ),
  missingOrganization: t('quote::A billing account is required on a proposal'),
  missingOrganizationContact: t('quote::Contact information is required for the billing account.'),
  missingWorkAccount: t(
    'quote::A customer contact must be associated with the quote before it can be approved or published.'
  ),
  invalidWorkAccount: t("quote::Contact's email address does not belong to the customer's tenant."),
  noCustomerCredit: t('quote::This customer does not have a valid credit line.'),
  requestCredit: t('quote::request credit line'),
  resubmitCreditLineRequest: t('quote::resubmit credit line request'),
  skuMismatch: t(
    'quote::The Product/SKU selected is no longer available. Please delete and add back. If this is unexpected please reach out to Quote Center Support.'
  ),
  creditIncrease: t('quote::request credit increase'),
  insufficientCredit: t(
    "quote::The customer's credit line must be greater than the minimum required credit line. You will need a credit increase before you can publish."
  ),
  monetaryCredit: t(
    'quote::Customer may have to sign supplemental Azure Credit terms at time of purchase.'
  ),
  missingCRMId: t('quote::An opportunity or success engagement is required on the quote.'),
  missingDealEstimate: t(
    'quote::Annual deal estimate must be provided with the quote before it can be approved or published.'
  ),
  configRequired: t('quote::This amendment will supersede the existing term.'),
  //todo:Shawn to handle the mail to links
  transactFailed: t(
    'quote::Your previous action to transact the quote failed. Please contact support for assistance.'
  ),
  transactRetry: t(
    'quote::Your previous action to transact the quote failed. Please try again or contact support for assistance.'
  ),
  invalidEnrollmentNumber: t('quote::Enrollment Number must be a 7 or 8 character number'),
  pendingCredit: t("quote::This customer's credit line is currently pending or under review."),
  rejectedCredit: t("quote::This customer's credit line has been rejected"),
  applicableLaw: t('quote::This amendment will supersede the existing term.'),
  missingFinanceDependency: t(
    'quote::The quote does not contain any products which are eligible for financing.'
  ),
  financedLineItem: t('quote::This line item has been marked to receive financing.'),
  billingAccountError: t('quote::Billing account error'),
  pastStartDateMessage: t('quote::Please update the start date in the product configuration card.'),
  sapTermAndEnrollmentNumberMessage: t(
    `quote::There is currently an enrollment number on the quote. This may result in your customer's Azure services being migrated from legacy to modern. If this is not intended, please remove the enrollment number.`
  ),
  saasRenewalMessage: (skuTitle?: string, price?: string, currency?: string, term?: string) =>
    t(
      'quote::The trial is for the {{skuTitle}} version. When the trial expires and it converts to pay go, the customer will be charged {{price}} {{currency}} /user/{{term}}.',
      {
        skuTitle,
        price,
        currency,
        term,
      }
    ),
  expiringEnrollmentIntent: t(
    'quote::Please select enrollment intent for this quote. No enrollment may be needed, or if your customer has an Enterprise Agreement that should be migrated, selecting the appropriate enrollment will properly migrate services.'
  ),
  missingVatIdMessage: t(
    'quote::The country indicated on the billing account exists in a value added tax system. Your customer most likely has a VAT number that will ensure correct invoicing.'
  ),
};

export const QCsupportEmailLink = (
  <LinkEmail displayText="QCSupport@microsoft.com" email="QCSupport@microsoft.com" />
);

const isReadOnly = (state: RootState, proposal: Proposal): boolean => isProposalReadOnly(proposal);

const missingCRMId = (state: RootState, proposal: Proposal): boolean => !hasCRMId(proposal);

const missingVatId = (state: RootState): boolean => {
  const isRequired = state.customer.requireVatId;
  const isExists = !!customerSelectors.getVatId(state);
  return isRequired && !isExists;
};

const missingDealEstimate = (state: RootState, proposal: Proposal): boolean =>
  proposal.header.estimatedDealValue === undefined;

export const hasTenants = (state: RootState) => {
  const proposal = getActiveProposal(state);
  const accountId = oc(proposal).header.soldToCustomerLegalEntity.accountId();
  if (!accountId || !state.customer.account[accountId]) {
    return false;
  }
  return !!state.customer.account[accountId].externalIds.length;
};

const getAssets = (state: RootState): Asset[] => {
  const proposal = getActiveProposal(state);
  const accountId = oc(proposal).header.soldToCustomerLegalEntity.accountId();
  return accountId ? customerSelectors.getFilteredAssetsByAccountId(state, accountId) : [];
};

const missingAzurePlans = createSelector(
  getActiveProposal,
  getAssets,
  getOrganizationId,
  (proposal, assets) => {
    const isLegacy = isAgreementTypeLegacy(proposal);
    if (isLegacy || !oc(proposal).header.extendedProperties.vlAgreementNumber()) return 0;

    let azurePlans = proposal.lineItems
      .filter(
        item =>
          item.productIdentifier.productId === productIds.azurePlan && item.productIdentifier.skuId
      )
      .map(item => item.productIdentifier.skuId);

    const assetAzurePlans = assets
      ? assets
          .filter(
            asset =>
              asset.assetData.productInfo.productId === productIds.azurePlan &&
              asset.assetData.productInfo.skuId
          )
          .map(asset => asset.assetData.productInfo.skuId)
      : [];

    azurePlans = azurePlans.concat(assetAzurePlans);
    const uniqueAzurePlans = [...new Set(azurePlans)];
    return totalAzurePlansAvailable - uniqueAzurePlans.length;
  }
);

export const hasInvitedUser = (state: RootState) => {
  const proposal = getActiveProposal(state);
  return !!proposal.header.invitedUser;
};

export const currentOrganizationIsEditable = (state: RootState) => {
  const organizationId = getOrganizationId(state);
  return !!(organizationId && customerSelectors.canEditOrganization(state, organizationId));
};

export const showMissingTenantsNotification = (
  isMissingCRMId: boolean,
  hasOrgId: boolean,
  customerHasTenants: boolean,
  hasInvitedUser: boolean
) => {
  if (isMissingCRMId || !hasOrgId) {
    return false;
  }

  return !customerHasTenants && !hasInvitedUser;
};

export const showMissingWorkAccountNotification = (
  isMissingCRMId: boolean,
  hasOrgId: boolean,
  customerHasTenants: boolean,
  hasInvitedUser: boolean
) => {
  if (isMissingCRMId || !hasOrgId) {
    return false;
  }
  return customerHasTenants && !hasInvitedUser;
};

export const getPurchaseSummary = (
  state: RootState,
  quote: Proposal
): PurchaseRecordSummary | undefined => {
  const purchaseId = quote && quote.lineItems.length && quote.lineItems[0].purchaseId;
  return purchaseId ? state.proposal.purchaseSummary[purchaseId] : undefined;
};

const getTransactStatus = createSelector(getPurchaseSummary, purchaseSummary => {
  if (purchaseSummary && purchaseSummary.events) {
    const completedEvent = purchaseSummary.events.find(event => {
      return event.eventType.toLowerCase() === 'creationoperationcompleted';
    });
    if (completedEvent) {
      const item = completedEvent.lineItems.reverse().find(item => {
        return item.result.toLowerCase() !== 'success';
      });
      if (item) {
        return item.result;
      }
    }
  }
});

const fulfillmentError = (state: RootState, proposal: Proposal): boolean => {
  const transactStatus = getTransactStatus(state, proposal);

  return (
    isAgreementTypeLegacy(proposal) &&
    proposal.header.status === ProposalStatus.Active &&
    !!transactStatus &&
    transactStatus.toLocaleLowerCase() === 'fulfillmenterror'
  );
};

const fulfillmentTransientError = (state: RootState, proposal: Proposal): boolean => {
  const transactStatus = getTransactStatus(state, proposal);

  return (
    isAgreementTypeLegacy(proposal) &&
    proposal.header.status === ProposalStatus.Active &&
    !!transactStatus &&
    transactStatus.toLocaleLowerCase() === 'fulfillmenttransienterror'
  );
};

const isLegacy = (state: RootState, proposal: Proposal): boolean => isAgreementTypeLegacy(proposal);

const isCreditRequired = (state: RootState): boolean =>
  state.app.flights[Flight.requiredCredit] && canPublish(state);

const modernOfficeEnabled = (state: RootState): boolean => state.app.flights[Flight.modernOffice];
const delayCreditCheckEnabled = (state: RootState): boolean =>
  state.app.flights[Flight.delayCreditCheck];
const enrollmentAssemblyEnabled = (state: RootState): boolean =>
  state.app.flights[Flight.enrollmentAssembly];

const isStrategicAccount = (state: RootState): boolean =>
  customerSelectors.getIsStrategicAccount(state);

const validEnrollmentNumber = createSelector(getEnrollmentNumber, enrollmentNumber => {
  const enrollmentNumberValidation = new RegExp('^([0-9]{7,8})?$');
  return enrollmentNumber ? enrollmentNumberValidation.test(enrollmentNumber) : true;
});

const missingEnrollmentNumber = createSelector(getEnrollmentNumber, enrollmentNumber => {
  return enrollmentNumber === undefined;
});

const isQuoteExpired = (state: RootState, proposal: Proposal): boolean =>
  proposal.header.status === ProposalStatus.Expired;

const getCreditNotifications = createSelector(
  isQuotePublished,
  getCreditLineStatus,
  missingCRMId,
  getMinimumCreditLine,
  hasSoldTo,
  isCreditRequired,
  proposalHasExtendedPrice,
  delayCreditCheckEnabled,
  isStrategicAccount,
  getAnnualDealEstimate,
  isQuoteExpired,
  (
    isQuotePublished,
    creditLineStatus,
    missingCRMId,
    minimumCredit: string,
    hasSoldTo: boolean,
    isCreditRequired: boolean,
    proposalHasExtendedPrice: boolean,
    delayCreditCheckEnabled: boolean,
    isStrategicAccount: boolean,
    annualDealEstimate?: number,
    quoteExpired?: boolean
  ): NotificationItem[] => {
    const creditNotifications: NotificationItem[] = [];

    const type: NotificationType =
      isCreditRequired &&
      (!delayCreditCheckEnabled || (delayCreditCheckEnabled && proposalHasExtendedPrice))
        ? NotificationType.standard
        : NotificationType.warning;

    const creditLineButtonProps: RequestCreditLineButtonProps = {
      displayText: '',
      annualDealEstimate: annualDealEstimate ? annualDealEstimate.toString() : '',
      minimumCreditLine: minimumCredit,
      size: 'small' as keyof FontSizes,
      disabled: isQuotePublished || quoteExpired,
    };

    switch (creditLineStatus) {
      case CreditLineReason.SafeList:
        break;
      case CreditLineReason.Rejected:
        const resubmitCreditLink = (
          <RequestCreditLineButton
            {...creditLineButtonProps}
            displayText={validationMessages.resubmitCreditLineRequest}
          />
        );
        creditNotifications.push({
          title: validationTitles.rejectedCredit,
          description: validationMessages.rejectedCredit,
          type,
          action: resubmitCreditLink,
          origins: [notificationOrigin.sales],
        });
        break;
      case CreditLineReason.PendingReview:
      case CreditLineReason.UnderReview:
        creditNotifications.push({
          title: validationTitles.pendingCredit,
          description: validationMessages.pendingCredit,
          type,
        });
        break;
      case CreditLineReason.CreditIncrease:
        const creditIncreaseLink = (
          <RequestCreditIncreaseButton
            dataAutomationId="requestCreditIncreaseButton"
            disableLink={isQuotePublished || quoteExpired}
            size="small"
          />
        );
        creditNotifications.push({
          title: validationTitles.insufficientCredit,
          description: validationMessages.insufficientCredit,
          type,
          action: creditIncreaseLink,
          origins: [notificationOrigin.sales],
        });
        break;
      case CreditLineReason.None:
        if (!missingCRMId && hasSoldTo && !isStrategicAccount) {
          const creditLink = (
            <RequestCreditLineButton
              {...creditLineButtonProps}
              dataAutomationId="requestCreditLineButton"
              displayText={validationMessages.requestCredit}
            />
          );

          creditNotifications.push({
            title: validationTitles.noCustomerCredit,
            description: validationMessages.noCustomerCredit,
            type,
            action: creditLink,
          });
        }
        break;
    }

    return creditNotifications;
  }
);

const getOrganizationNotifications = createSelector(
  getAccountId,
  getOrganizationId,
  hasTenants,
  hasIncompleteOrganization,
  currentOrganizationIsEditable,
  missingOrganizationContactInfo,
  (
    accountId,
    organizationId,
    customerHasTenants,
    hasIncompleteOrg,
    canEditOrganization,
    missingContactInformation
  ) => ({
    accountId,
    organizationId,
    customerHasTenants,
    hasIncompleteOrg,
    canEditOrganization,
    missingContactInformation,
  })
);

const getMissingInformationNotifications = createSelector(
  missingCRMId,
  missingVatId,
  missingDealEstimate,
  missingAzurePlans,
  missingEnrollmentNumber,
  (
    isMissingCRMId,
    isMissingVatId,
    isMissingDealEstimate,
    missingAzurePlansCount,
    isMissingEnrollmentNumber
  ) => ({
    isMissingCRMId,
    isMissingVatId,
    isMissingDealEstimate,
    missingAzurePlansCount,
    isMissingEnrollmentNumber,
  })
);

const getHeaderNotifications = createSelector(
  getCreditNotifications,
  hasInvitedUser,
  fulfillmentError,
  fulfillmentTransientError,
  isLegacy,
  validEnrollmentNumber,
  getOrganizationNotifications,
  isReadOnly,
  enrollmentAssemblyEnabled,
  getMissingInformationNotifications,
  (state: RootState) => getFlightIsEnabled(state, Flight.azurePlanNotifications),
  (
    creditNotifications: NotificationItem[],
    hasInvitedUser,
    isFulfillmentError,
    isFulfillmentTransientError,
    isLegacy,
    validEnrollmentNumber,
    organizationNotifications,

    isReadOnly,
    enrollmentAssemblyEnabled,
    getMissingInformationNotifications,
    azurePlanNotificationEnabled
  ): NotificationItem[] => {
    let headerNotifications: NotificationItem[] = [];
    const {
      isMissingCRMId,
      isMissingVatId,
      isMissingDealEstimate,
      missingAzurePlansCount,
      isMissingEnrollmentNumber,
    } = getMissingInformationNotifications;
    //legacy
    if (isLegacy) {
      if (isFulfillmentError) {
        headerNotifications.push({
          title: validationTitles.transactFailed,
          description: validationMessages.transactFailed,
          type: NotificationType.error,
          action: QCsupportEmailLink,
        });
      } else if (isFulfillmentTransientError) {
        headerNotifications.push({
          title: validationTitles.transactRetry,
          description: validationMessages.transactRetry,
          type: NotificationType.warning,
          action: QCsupportEmailLink,
        });
      }
      return headerNotifications;
    }
    if (!isMissingCRMId && organizationNotifications.hasIncompleteOrg) {
      if (organizationNotifications.canEditOrganization) {
        headerNotifications.push({
          action: <EditOrganization disabled={isReadOnly} />,
          title: validationMessages.billingAccountError,
          description: t('quote::The billing account has incorrect or missing information.'),
          type: NotificationType.error,
          origins: [notificationOrigin.customer],
        });
      } else {
        headerNotifications.push({
          action: QCsupportEmailLink,
          title: validationMessages.billingAccountError,
          description: t(
            'The billing account is missing information that must be completed before the quote can be published. Please contact Quote Center Support for assistance.'
          ),
          type: NotificationType.error,
          origins: [notificationOrigin.customer],
        });
      }
    }

    headerNotifications = headerNotifications.concat(creditNotifications);
    const { accountId, organizationId } = organizationNotifications;

    if (isMissingCRMId) {
      headerNotifications.push({
        title: validationTitles.missingCRMId,
        description: validationMessages.missingCRMId,
        type: NotificationType.standard,
        action: <AddCRMId disabled={isReadOnly} />,
        origins: [notificationOrigin.customer, notificationOrigin.sales],
      });
    } else if (!organizationId) {
      headerNotifications.push({
        title: validationTitles.missingOrganization,
        description: validationMessages.missingOrganization,
        action: <SelectOrganization />,
        type: NotificationType.standard,
        origins: [notificationOrigin.customer],
      });
    } else {
      if (!hasInvitedUser) {
        if (organizationNotifications.customerHasTenants) {
          headerNotifications.push({
            title: validationTitles.missingWorkAccount,
            description: validationMessages.missingWorkAccount,
            action: <AddWorkAccount disabled={isReadOnly} />,
            type: NotificationType.standard,
            origins: [notificationOrigin.customer],
          });
        } else {
          headerNotifications.push({
            title: validationTitles.missingCustomerTenant,
            description: validationMessages.missingCustomerTenant,
            action: <AddTenant disabled={isReadOnly} />,
            type: NotificationType.standard,
            origins: [notificationOrigin.customer],
          });
        }
      }
      if (enrollmentAssemblyEnabled && !isMissingCRMId && isMissingEnrollmentNumber) {
        headerNotifications.push({
          title: validationTitles.expiringEnrollmentIntent,
          description: validationMessages.expiringEnrollmentIntent,
          action: <SelectExpiringEnrollmentIntent />,
          type: NotificationType.standard,
          origins: [notificationOrigin.sales],
        });
      }
      if (organizationNotifications.missingContactInformation) {
        headerNotifications.push({
          title: validationTitles.missingOrganizationContact,
          description: validationMessages.missingOrganizationContact,
          action: accountId && (
            <AddBillingContactButton
              accountId={accountId}
              organizationId={organizationId}
              readOnly={isReadOnly}
            />
          ),
          type: NotificationType.standard,
          origins: [notificationOrigin.customer],
        });
      }
    }
    if (isMissingVatId) {
      headerNotifications.push({
        title: validationTitles.vatIdNotAdded,
        description: validationMessages.missingVatIdMessage,
        type: NotificationType.warning,
        origins: [notificationOrigin.customer],
        action: accountId && !isReadOnly && (
          <VatIdLinkButton accountId={accountId} organizationId={organizationId} size="small" />
        ),
      });
    }
    if (isMissingDealEstimate) {
      headerNotifications.push({
        title: validationTitles.missingDealEstimate,
        description: validationMessages.missingDealEstimate,
        type: NotificationType.standard,
        origins: [notificationOrigin.sales],
        action: <ViewInSales disabled={isReadOnly} />,
      });
    }
    if (!validEnrollmentNumber) {
      headerNotifications.push({
        title: validationTitles.invalidEnrollmentNumber,
        description: validationMessages.invalidEnrollmentNumber,
        type: NotificationType.error,
        origins: [notificationOrigin.sales],
      });
    }
    if (missingAzurePlansCount && azurePlanNotificationEnabled) {
      headerNotifications.push({
        title: validationTitles.missingAzurePlan,
        description: validationMessages.missingAzurePlan,
        type: NotificationType.error,
        dataAutomationId: 'missingAzurePlanMessage',
      });
    }
    return headerNotifications;
  }
);

const emptyList: LineItem[] = [];
const getLineItems = (proposal: Proposal) => proposal.lineItems || emptyList;

const isDiscountableItem = (state: RootState, lineItem: LineItem): boolean =>
  lineItem.productIdentifier.productFamily === ProductFamily.NegotiatedTerms ||
  needsDiscount(state, lineItem) ||
  isDiscounted(lineItem);

const isPurchasableItem = (state: RootState, lineItem: LineItem): boolean =>
  !isDiscountableItem(state, lineItem);

const tooManyPurchaseItems = (state: RootState, proposal: Proposal) => {
  const lineItems = getLineItems(proposal);
  const filtered = lineItems.filter(lineItem => isPurchasableItem(state, lineItem));
  return filtered.length > maxPurchasableLineItems;
};

const tooManyDiscountItems = (state: RootState, proposal: Proposal) => {
  const lineItems = getLineItems(proposal);
  return (
    lineItems.filter(lineItem => isDiscountableItem(state, lineItem)).length >
    maxDiscountableLineItems
  );
};

const needsConfiguring = (state: RootState, proposal: Proposal, lineItem: LineItem): boolean =>
  !!needsConfiguration(state, lineItem);

const needsDiscounting = (state: RootState, proposal: Proposal, lineItem: LineItem): boolean =>
  !isDiscounted(lineItem) && needsDiscount(state, lineItem);

const needsConfigOrDiscountCount = (state: RootState, proposal: Proposal) =>
  getLineItems(proposal).filter(
    lineItem =>
      needsConfiguring(state, proposal, lineItem) || needsDiscounting(state, proposal, lineItem)
  ).length;

const hasMalformedClaims = (state: RootState, proposal: Proposal, lineItem: LineItem): boolean => {
  // TODO: michmel - This is to handle the claim change from string to array and prevent blowup on gql phase2
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headerUserPreferences: any = oc(
    proposal
  ).header.extendedProperties.userPreferences.nationalCloud('');
  if (typeof headerUserPreferences === 'string') {
    return (
      !!oc(proposal).header.extendedProperties.userPreferences.nationalCloud() &&
      oc(lineItem)
        .catalogClaims[ClaimType.NationalCloud]('')
        .toLowerCase() !==
        oc(proposal)
          .header.extendedProperties.userPreferences.nationalCloud('')
          .toLowerCase()
    );
  }
  return false;
};

const cannotHydrate = (state: RootState, lineItem: LineItem): boolean => {
  const productIdentifier = getProductIdentifier(lineItem);
  const productId = (productIdentifier && productIdentifier.productId) || '';
  if (!productId) {
    return false;
  }

  const failedProducts = getProductsFailed(state);
  return !!failedProducts[productId];
};

const hasSkuMismatch = (state: RootState, proposal: Proposal, lineItem: LineItem) => {
  const product = getProduct(state, lineItem.productIdentifier.productId);
  if (!lineItem.productIdentifier.skuId) {
    return false;
  }
  let skuMismatch = true;
  if (product && lineItem.productIdentifier) {
    product.DisplaySkuAvailabilities.forEach(displaySkuAvailability => {
      if (displaySkuAvailability.Sku.SkuId === lineItem.productIdentifier.skuId) {
        skuMismatch = false;
      }
    });
  }
  return skuMismatch;
};

const isLineItemBroken = (state: RootState, proposal: Proposal, lineItem: LineItem) =>
  hasMalformedClaims(state, proposal, lineItem) || cannotHydrate(state, lineItem);

/**
 * Gets the count of line items from the given quote where the line item or its configuration is not longer valid.
 *
 * @param state contains the product information of line items
 * @param proposal the quote containing the line items
 *
 * @returns the count of broken line items divided by products and terms
 */
const getBrokenLineItemsCount = (
  state: RootState,
  proposal: Proposal
): { productsCount: number; termsCount: number } | undefined => {
  const productLineItems: LineItem[] = [];
  const termLineItems: LineItem[] = [];

  getLineItems(proposal).forEach(lineItem => {
    if (isLineItemBroken(state, proposal, lineItem) || hasSkuMismatch(state, proposal, lineItem)) {
      switch (lineItem.productIdentifier.productFamily) {
        case ProductFamily.NegotiatedTerms:
          termLineItems.push(lineItem);
          break;
        default:
          productLineItems.push(lineItem);
          break;
      }
    }
  });

  if (!productLineItems.length && !termLineItems.length) {
    return;
  } else {
    return { productsCount: productLineItems.length, termsCount: termLineItems.length };
  }
};

const getLineItemsNotifications = createSelector(
  tooManyPurchaseItems,
  tooManyDiscountItems,
  needsConfigOrDiscountCount,
  getBrokenLineItemsCount,
  (hasTooManyPurchaseItems, hasTooManyDiscountItems, configOrDiscountCount, brokenLineItems) => {
    const productNotifications: NotificationItem[] = [];
    if (hasTooManyPurchaseItems) {
      productNotifications.push({
        title: validationTitles.maxPurchase,
        description: validationMessages.maxPurchasesMessage(maxPurchasableLineItems.toString()),
        type: NotificationType.error,
      });
    }
    if (hasTooManyDiscountItems) {
      productNotifications.push({
        title: validationTitles.maxDiscount,
        description: validationMessages.maxDiscountsMessage(maxDiscountableLineItems.toString()),
        type: NotificationType.error,
      });
    }
    if (configOrDiscountCount > 0) {
      productNotifications.push({
        title: validationTitles.configurationRequired,
        description:
          configOrDiscountCount === 1
            ? validationMessages.configurationOrDiscountRequiredOneMessage
            : validationMessages.configurationOrDiscountRequiredMessage(
                configOrDiscountCount.toString()
              ),
        type: NotificationType.standard,
        dataAutomationId: 'configurationRequiredMessage',
      });
    }
    if (brokenLineItems) {
      const { productsCount, termsCount } = brokenLineItems;
      if (productsCount && termsCount) {
        productNotifications.push({
          title: validationTitles.brokenLineItems,
          description: validationMessages.brokenLineItems(productsCount, termsCount),
          type: NotificationType.error,
        });
      } else if (productsCount) {
        productNotifications.push({
          title: validationTitles.brokenProduct,
          description: validationMessages.brokenProduct(productsCount),
          type: NotificationType.error,
        });
      } else {
        productNotifications.push({
          title: validationTitles.brokenTerms,
          description: validationMessages.brokenTerm(termsCount),
          type: NotificationType.error,
        });
      }
    }
    return productNotifications;
  }
);

/**
 * Get all proposal notifications
 * @param {RootState} state - Redux store state
 * @param {Proposal} proposal - Normally will be the active proposal
 */
export const getNotifications = createSelector(
  getHeaderNotifications,
  getLineItemsNotifications,
  (list1, list2) => [...list1, ...list2]
);

const isCustomTerm = (state: RootState, proposal: Proposal, lineItem: LineItem): boolean => {
  const product = getLineItemProduct(state, lineItem);
  return (
    !!product &&
    product.ProductFamily === ProductFamily.NegotiatedTerms &&
    !!product.Properties.TermId
  );
};

const isMissingCustomTermId = (
  state: RootState,
  proposal: Proposal,
  lineItem: LineItem
): boolean => {
  const product = getLineItemProduct(state, lineItem);
  const hasTermId = oc(lineItem)
    .supplementalTermReferenceData('')
    .toLowerCase()
    .includes('termid');

  return (
    !!product &&
    product.ProductFamily === ProductFamily.NegotiatedTerms &&
    !!product.Properties.TermId &&
    !hasTermId
  );
};

const oneOfTooManyDiscountItems = (
  state: RootState,
  proposal: Proposal,
  lineItem: LineItem
): boolean => tooManyDiscountItems(state, proposal) && isDiscountableItem(state, lineItem);

const oneOfTooManyPurchaseItems = (
  state: RootState,
  proposal: Proposal,
  lineItem: LineItem
): boolean => tooManyPurchaseItems(state, proposal) && isPurchasableItem(state, lineItem);

const hasInvalidQuantity = (state: RootState, proposal: Proposal, lineItem: LineItem): string => {
  // if it needs configuration quantity is not editable
  if (isDiscountFulfillmentDocument(lineItem) || needsConfiguration(state, lineItem)) {
    return '';
  }
  const productOrSku = getLineItemProductOrSku(state, lineItem);
  if (!productOrSku || !(productOrSku as Sku).SkuId) {
    return ''; //If there's no corresponding Sku, it's not a quantity problem
  }

  const currentSku = productOrSku as Sku; //asserted above
  const max =
    currentSku.Properties.MaximumPurchaseQuantity ||
    currentSku.Properties.MaxOrderQuantity ||
    currentSku.MaximumPurchaseQuantity ||
    currentSku.MaxOrderQuantity;
  const min =
    currentSku.Properties.MinimumPurchaseQuantity ||
    currentSku.Properties.MinOrderQuantity ||
    currentSku.MinimumPurchaseQuantity ||
    currentSku.MinOrderQuantity;
  const isValid =
    (min ? lineItem.quantity >= min : true) || (max ? max >= lineItem.quantity : true);
  if (!isValid) {
    //unwrapping and rewrapping to get memoization benefits
    return validationMessages.quantityError(`${min || 1}`, `${max || 999999}`).value;
  }
  return '';
};

const getDuplicateAzurePlanSelector = (
  state: RootState,
  proposal: Proposal,
  lineItem: LineItem
) => {
  const assets = getAssets(state);
  const isConfigured = !needsConfiguration(state, lineItem);
  const product = getProduct(state, lineItem.productIdentifier.productId);
  const organizationId = getOrganizationId(state);
  const isDemoMode = getFlightIsEnabled(state, Flight.demoMode);
  return !isDemoMode
    ? product &&
        getDuplicateAzurePlan(proposal, product, lineItem, assets, isConfigured, organizationId)
    : undefined;
};

const isSupplementalTermsNeeded = (
  state: RootState,
  proposal: Proposal,
  lineItem: LineItem
): boolean => {
  const product = getLineItemProduct(state, lineItem);
  return (
    !!product &&
    isMonetary({
      ProductType: product.ProductType,
      ProductFamily: product.ProductFamily,
      LocalizedProperties: product.LocalizedProperties,
      ProductId: product.ProductId,
    }) &&
    oc(product)
      .Properties.SupplementalTermsNeeded([])
      .includes('AzureMonetaryCredit')
  );
};

const isNegotiatedSelector = (state: RootState, proposal: Proposal, lineItem: LineItem) => {
  const product = getLineItemProduct(state, lineItem);
  return !!product && product.ProductFamily === ProductFamily.NegotiatedTerms;
};

const isPassesSelector = (state: RootState, proposal: Proposal, lineItem: LineItem) => {
  const product = getLineItemProduct(state, lineItem);
  return !!product && product.ProductFamily === ProductFamily.Passes;
};

const isApplicableLawSelector = (state: RootState, proposal: Proposal, lineItem: LineItem) => {
  const product = getLineItemProduct(state, lineItem);
  return !!product && product.ProductType === 'ApplicableLaw';
};

const isFinancedProduct = (state: RootState, proposal: Proposal, lineItem: LineItem): boolean => {
  return oc(lineItem).groups([]).length > 0;
};

const hasPastStartDate = (state: RootState, proposal: Proposal, lineItem: LineItem) => {
  const enrollmentNumber = getEnrollmentNumber(state);
  const startDate = oc(lineItem).purchaseInstruction.termStartDate();
  if (!startDate) {
    return false;
  }
  return (
    modernOfficeEnabled(state) &&
    isPassesSelector(state, proposal, lineItem) &&
    !enrollmentNumber &&
    lineItemStartDateInPast(startDate)
  );
};

const isSAPTermAndHasEnrollmentNumber = (
  state: RootState,
  proposal: Proposal,
  lineItem: LineItem
) => {
  const enrollmentNumber = getEnrollmentNumber(state);
  const productType = oc(lineItem).productIdentifier.productType();
  const productIsSAPTerm = !!productType && productType.toLowerCase() === 'scpcommitmenttoconsume';
  return productIsSAPTerm && enrollmentNumber !== undefined;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isECIFUpdatesEnable = (state: RootState, proposal: Proposal, lineItem: LineItem): boolean =>
  getFlightIsEnabled(state, Flight.ECIF);

const showRenewalSaasPriceNotification = (
  state: RootState,
  proposal: Proposal,
  lineItem: LineItem
) => {
  const product = getLineItemProduct(state, lineItem);
  const priceMap = getPrices(state);
  const market = getMarket(state);
  const isSaas =
    oc(product)
      .Properties.ServiceFamily('')
      .toLowerCase() === ServiceFamily.SaaS;
  const needsConfiguration = needsConfiguring(state, proposal, lineItem);
  const saasNotification =
    product && createSaasRenewalPriceInfoNotification(product, lineItem, market);
  if (isSaas && !needsConfiguration && saasNotification && priceMap[saasNotification.key]) {
    const price = priceMap[saasNotification.key].price || 0;
    const trialNotification: NotificationItem = {
      title: validationTitles.saasTrailRenewal,
      description: validationMessages.saasRenewalMessage(
        saasNotification.skuTitle,
        price.toString(),
        saasNotification.currency,
        saasNotification.term
      ),
      type: NotificationType.info,
    };
    return trialNotification;
  }
};

const getLineItemsConfigurationNotificationsSelector = (lineItem: LineItem) =>
  createSelector(
    needsConfiguring,
    isCustomTerm,
    hasSkuMismatch,
    needsDiscounting,
    isMissingCustomTermId,
    isECIFUpdatesEnable,
    isSAPTermAndHasEnrollmentNumber,
    showRenewalSaasPriceNotification,
    (
      configureThis: boolean,
      isCustomTerm: boolean,
      isSkuMismatch: boolean,
      discountThis: boolean,
      isMissingTermId: boolean,
      ecifUpdatesEnable: boolean,
      sapTermAndEnrollmentNumber: boolean,
      renewalSaasPriceNotification?: NotificationItem
    ) => {
      const lineItemsConfigurationNotifications: NotificationItem[] = [];

      if (configureThis) {
        const isTermNotification = !ecifUpdatesEnable && isCustomTerm && isMissingTermId;
        lineItemsConfigurationNotifications.push({
          title: isTermNotification
            ? validationTitles.configRequiredTermId(
                oc(lineItem).productIdentifier.productType('Term')
              )
            : validationTitles.configurationRequired,
          description: isTermNotification
            ? validationMessages.customTermConfigurationRequiredMessage
            : validationMessages.configurationRequiredMessage,
          type: NotificationType.standard,
        });
      }
      if (discountThis) {
        lineItemsConfigurationNotifications.push({
          title: validationTitles.discountRequired,
          description: validationMessages.discountRequiredMessage,
          type: NotificationType.standard,
          dataAutomationId: 'discountRequiredMessage',
        });
      }
      if (sapTermAndEnrollmentNumber) {
        lineItemsConfigurationNotifications.push({
          title: validationTitles.sapTermAndEnrollmentNumber,
          description: validationMessages.sapTermAndEnrollmentNumberMessage,
          action: <RemoveEnrollmentNumber />,
          type: NotificationType.warning,
        });
      }
      if (isSkuMismatch) {
        lineItemsConfigurationNotifications.push({
          title: validationTitles.skuMismatch,
          description: validationMessages.skuMismatch,
          type: NotificationType.error,
          dataAutomationId: 'skuMismatchMessage',
        });
      }
      if (renewalSaasPriceNotification) {
        lineItemsConfigurationNotifications.push(renewalSaasPriceNotification);
      }
      return lineItemsConfigurationNotifications;
    }
  );

const lineItemNotificationSelectorCreator = (lineItem: LineItem) =>
  createSelector(
    isLineItemBroken,
    oneOfTooManyDiscountItems,
    oneOfTooManyPurchaseItems,
    hasInvalidQuantity,
    isSupplementalTermsNeeded,
    isNegotiatedSelector,
    isApplicableLawSelector,
    isFinancedProduct,
    hasPastStartDate,
    getLineItemsConfigurationNotificationsSelector(lineItem),
    (state: RootState) => getFlightIsEnabled(state, Flight.azurePlanNotifications),
    getDuplicateAzurePlanSelector,
    (
      showRemoveLineItem: boolean,
      removeDiscountable: boolean,
      removePurchasable: boolean,
      wrongQuantity: string,
      isSupplementalTermsNeeded: boolean,
      isNegotiated: boolean,
      isApplicableLaw: boolean,
      isFinancedProduct: boolean,
      pastStartDate: boolean,
      lineItemsConfigurationNotifications: NotificationItem[],
      azurePlanNotificationEnabled: boolean,
      duplicateAzurePlanOptions?: { type: DuplicateType; sku?: string }
    ) => {
      const lineItemNotifications: NotificationItem[] = [];
      if (showRemoveLineItem) {
        lineItemNotifications.push({
          title: validationTitles.removeLineItem,
          description: validationMessages.errorInvalidLineItem,
          type: NotificationType.error,
        });
      }
      if (removeDiscountable) {
        lineItemNotifications.push({
          title: validationTitles.maxDiscount,
          description: validationMessages.maxDiscountsMessage(maxDiscountableLineItems.toString()),
          type: NotificationType.error,
        });
      }
      if (removePurchasable) {
        lineItemNotifications.push({
          title: validationTitles.maxPurchase,
          description: validationMessages.maxPurchasesMessage(maxPurchasableLineItems.toString()),
          type: NotificationType.error,
        });
      }
      if (wrongQuantity) {
        lineItemNotifications.push({
          title: validationTitles.quantity,
          description: t(wrongQuantity),
          type: NotificationType.error,
        });
      }
      if (isSupplementalTermsNeeded) {
        lineItemNotifications.push({
          title: validationTitles.monetaryCredit,
          description: validationMessages.monetaryCredit,
          type: NotificationType.info,
          dataAutomationId: 'monetaryCreditMessage',
        });
      }
      if (isFinancedProduct && !isNegotiated) {
        lineItemNotifications.push({
          title: validationTitles.financed,
          description: validationMessages.financedLineItem,
          type: NotificationType.info,
        });
      }
      if (isNegotiated && isApplicableLaw) {
        lineItemNotifications.push({
          title: validationTitles.applicableLaw,
          description: validationMessages.applicableLaw,
          type: NotificationType.info,
        });
      }
      if (duplicateAzurePlanOptions && azurePlanNotificationEnabled) {
        let description = t(
          'quote::This is a duplicate line item. Only one {{duplicateAzurePlan}} is allowed on the quote. Delete or reconfigure as appropriate.',
          { duplicateAzurePlan: duplicateAzurePlanOptions.sku }
        );
        if (duplicateAzurePlanOptions.type === DuplicateType.fromAssets) {
          description = t(
            'quote::The customer already has {{duplicateAzurePlan}} which cannot be purchased again. Please remove the line item from the quote.',
            { duplicateAzurePlan: duplicateAzurePlanOptions.sku }
          );
        }
        lineItemNotifications.push({
          title: validationTitles.duplicateAzurePlan,
          description: description,
          type: NotificationType.error,
        });
      }
      if (pastStartDate) {
        lineItemNotifications.push({
          title: validationTitles.pastStartDate,
          description: validationMessages.pastStartDateMessage,
          action: <OpenConfigCard lineItemId={lineItem.id} />,
          type: NotificationType.error,
        });
      }
      return [...lineItemsConfigurationNotifications, ...lineItemNotifications];
    }
  );

let notificationMemoizer: Record<
  string,
  (state: RootState, proposal: Proposal, lineItem: LineItem) => NotificationItem[]
> = {};
let proposalIdMemoizationHint = '';
export const getLineItemNotifications = (
  state: RootState,
  proposal: Proposal,
  lineItem: LineItem
) => {
  const proposalId = proposal.id;
  const lineItemId = lineItem.id;
  if (proposalId !== proposalIdMemoizationHint) {
    proposalIdMemoizationHint = proposalId;
    notificationMemoizer = {};
  }
  if (!notificationMemoizer[lineItemId]) {
    notificationMemoizer[lineItemId] = lineItemNotificationSelectorCreator(lineItem);
  }
  return notificationMemoizer[lineItemId](state, proposal, lineItem);
};

export const hasBlockingLineItem = (state: RootState, proposal: Proposal) => {
  let blocking;
  if (proposal && proposal.id && proposal.lineItems) {
    const lineItems = proposal.lineItems;
    if (lineItems.length > 0) {
      lineItems.forEach(lineItem => {
        const notifications = getLineItemNotifications(state, proposal, lineItem);
        if (notifications && notifications.length > 0) {
          blocking = notifications.some(
            notification =>
              notification.type === NotificationType.error ||
              notification.type === NotificationType.standard
          );
        }
      });
    }
  }
  return blocking;
};

export const hasProposalLevelBlockingNotification = createSelector(
  getNotifications,
  notifications => {
    const hasblockingNotification = notifications.some(
      notification =>
        notification.type === NotificationType.error ||
        notification.type === NotificationType.standard
    );
    return hasblockingNotification;
  }
);

export const hasAnyBlockingNotifications = createSelector(
  hasBlockingLineItem,
  hasProposalLevelBlockingNotification,
  (lineItem, ProposalLevel) => !!lineItem || ProposalLevel
);

// Way to mock/spyOn function being call inside another function
// when both functions live on the same file. Open to other suggestions.
const proposalValidations = {
  getNotifications,
};

export const isAnyNotificationOriginatedFrom = (
  state: RootState,
  quote: Proposal,
  expectedOrigin: notificationOrigin
) => {
  const notifications = proposalValidations.getNotifications(state, quote);

  return notifications.some(
    notification =>
      notification.origins && notification.origins.some(origin => origin === expectedOrigin)
  );
};

export default proposalValidations;
