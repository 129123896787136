import { mergeClassNames } from 'components';
import { ButtonMenuProps, ButtonSharedProps, IconMenuButton } from 'components/ions/Buttons';
import { SearchResultProduct } from 'features/catalog';
import { favoriteButtonKeyboardNavigation } from 'features/proposal/components/Finder/keyboardNavigation';
import { addProductToFavorites, removeProductFromFavorites } from 'features/user/actions';
import { getUserFavoriteProductsIds } from 'features/user/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';

import { FavoritesCallout } from '../FavoritesCallout';
import { favoriteButtonsSharedStyles } from '../shared.styles';

/**
 * Props for Favorite Menu Button
 * @prop {string} groupName - To know where the product may be move from and be remove from that group
 * @prop {string} product - Product from where the callout was open
 */
export interface FavoriteMenuButtonProps extends ButtonSharedProps {
  addContainerClass?: string;
  groupName: string;
  product: SearchResultProduct;
}

const dispatchProps = {
  addProductToFavorites: addProductToFavorites.request,
  removeProductFromFavorites: removeProductFromFavorites.request,
};

const mapStateToProps = (state: RootState, ownProps: FavoriteMenuButtonProps) => {
  const userFavoriteProductsIds = getUserFavoriteProductsIds(state);

  return {
    isFavorited: userFavoriteProductsIds.includes(ownProps.product.productId),
  };
};

type Props = FavoriteMenuButtonProps &
  WithStyles<typeof favoriteButtonsSharedStyles> &
  typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

const FavoriteMenuButtonUnStyled: React.FC<Props> = (props: Props) => {
  const [copyToDifferentGroupCalloutDismissed, setCopyToCalloutDismissed] = React.useState(true);
  const [moveToDifferentGroupCalloutDismissed, setMoveToCalloutDismissed] = React.useState(true);

  const { t } = useTranslation();
  const { groupName, product } = props;
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);

  const elementIdToRefocus = `favorite-button-${groupName}-${product.productId}`;

  const menuOptions: ButtonMenuProps[] = [
    {
      key: 'unfavorite',
      text: t('Unfavorite'),
      icon: 'Heart',
      dataAutomationId: 'unfavoriteMenuButton',
      onClick: () => {
        props.removeProductFromFavorites({ groupName, product });
      },
    },
    {
      key: 'move',
      text: t('Move to different group'),
      icon: 'FabricMovetoFolder',
      dataAutomationId: 'moveToDifferentGroupMenuButton',
      onClick: () => setMoveToCalloutDismissed(false),
    },
    {
      key: 'copy',
      text: t('Copy to different group'),
      dataAutomationId: 'CopyToDifferentGroupMenuButton',
      icon: 'FabricNewFolder',
      onClick: () => setCopyToCalloutDismissed(false),
    },
  ];

  const copyToDifferentGroupCallout = (
    <FavoritesCallout
      description={t('Which group would you like to copy this product to?')}
      elementIdToFocusOnDismiss={elementIdToRefocus}
      excludeGroupName={groupName}
      hidden={copyToDifferentGroupCalloutDismissed}
      product={props.product}
      target={buttonContainerRef.current}
      title={t('Copy to...')}
      onDismiss={() => setCopyToCalloutDismissed(true)}
    />
  );

  const moveToDifferentGroupCallout = (
    <FavoritesCallout
      description={t('Which group would you like to move this product to?')}
      elementIdToFocusOnDismiss={elementIdToRefocus}
      excludeGroupName={groupName}
      hidden={moveToDifferentGroupCalloutDismissed}
      moveFrom={groupName}
      product={props.product}
      target={buttonContainerRef.current}
      title={t('Move to...')}
      onDismiss={() => {
        setMoveToCalloutDismissed(true);
      }}
    />
  );

  return (
    <>
      <div
        className={mergeClassNames([props.classes.buttonContainer, props.addContainerClass])}
        ref={buttonContainerRef}
        onKeyDown={favoriteButtonKeyboardNavigation}
      >
        <IconMenuButton
          addClass={props.classes.button}
          ariaLabel={t('Product menu')}
          dataAutomationId={elementIdToRefocus}
          iconName="More"
          id={elementIdToRefocus}
          menuId={`favorite-product-menu-${product.productId}`}
          menuProps={menuOptions}
          title={props.title}
        />
      </div>
      {copyToDifferentGroupCallout}
      {moveToDifferentGroupCallout}
    </>
  );
};

export const FavoriteMenuButtonStyled = withStyles(favoriteButtonsSharedStyles)(
  FavoriteMenuButtonUnStyled
) as React.FC<FavoriteMenuButtonProps>;

export const FavoriteMenuButton = connect(mapStateToProps, dispatchProps)(FavoriteMenuButtonStyled);
