export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface AddressValidationConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Prod]:
    'https://quotecenterservice.l2o.microsoft.com/v1/addressvalidation/api/address/v1/validate',
  [Environment.Int]:
    'https://quotecenterservice.int.l2o.microsoft.com/v1/addressvalidation/api/address/v1/validate',
};
