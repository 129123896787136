import { getConfigByEnvironment } from 'features/app/config/configurations';
import { defaultLanguage } from 'features/proposal/supported-languages';
import i18next from 'i18next';
import languagedetector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import moment from 'moment-timezone';
import { initReactI18next } from 'react-i18next';
import loggerService from 'services/logger-service';

let obfuscateTranslations = false; //when enabled, this helps us distinguish between translated and non-translated text

const getFallbackLanguage = () => (obfuscateTranslations ? 'lorem-ipsum' : 'en-US');

const addLanguageDetector = () => {
  i18next.use((languagedetector as unknown) as i18next.LanguageDetectorModule);
};

const removeLanguageDetector = () => {
  i18next.modules.languageDetector = undefined;
  i18next.services.languageDetector = undefined;
};

export const toggleObfuscation = (enabled: boolean) => {
  obfuscateTranslations = enabled;

  if (obfuscateTranslations) {
    removeLanguageDetector();
  } else {
    addLanguageDetector();
  }

  i18next.init({ fallbackLng: getFallbackLanguage() }); //safe to call init n-times
};

addLanguageDetector(); //enable language detector by default

// TODO: discussion to consider type-safe
//TODO: Fix the types when i18next fixes them
i18next
  .use((backend as unknown) as i18next.BackendModule) // lazy loading resources
  .use((initReactI18next as unknown) as i18next.BackendModule) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    debug: false,
    fallbackLng: getFallbackLanguage(),
    whitelist: ['en-US'],
    defaultNS: 'common',
    ns: ['common', 'error', 'market', 'region'],
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      // order from where user language should be detected
      order: ['navigator'],
    },
    nsSeparator: '::',
    keySeparator: false,
    //TODO: Consider using pseuodoloc library
    parseMissingKeyHandler: getConfigByEnvironment().translation.pseudoLoc
      ? (key: string) => {
          return key.replace(/\S/g, '$');
        }
      : undefined,
  });

const loadMomentLocale = async (language: string) => {
  try {
    await import(`moment/locale/${language}`);
    moment.locale(language);
  } catch (e) {
    if (language !== defaultLanguage) {
      loggerService.error(e);
    }
    moment.locale(defaultLanguage);
  }
};

let queue = Promise.resolve();
// languageChanged is run once automatically in the initial load
i18next.on('languageChanged', async (language: string) => {
  queue = queue.then(() => loadMomentLocale(language));
  return queue;
});

export default i18next;
