import { ThemeProps } from 'styles';

export const selectableTileStyles = (theme: ThemeProps) => {
  const field = {
    width: 204,
    border: `1px solid ${theme.palette.backgroundDivider}`,
    borderRadius: 2,
    '&::before': {
      height: 16,
      left: 'auto',
      marginTop: 12,
      right: 12,
      width: 16,
    },
    '&, &:hover': {
      '&:after': {
        left: 'auto',
        right: 16,
        width: 8,
        height: 8,
        marginTop: 11,
        borderWidth: 4,
      },
    },
  };

  return {
    allTiles: {
      marginLeft: 2,
    },
    dataUnavailable: {
      padding: '8px 30px 16px 16px',
    },
    dataAvailable: {
      padding: '14px 30px 16px 16px',
    },
    field: {
      ...field,
      '&:before': {
        borderColor: theme.palette.textTertiary,
      },
      '&:hover:after': {
        backgroundColor: theme.palette.backgroundDivider,
      },
    },
    fieldChecked: {
      ...field,
      backgroundColor: theme.palette.lineItemHighlight,
      '&:before, &:after': {
        borderColor: theme.palette.secondary,
      },
      '&:hover:before, &:hover:after': {
        borderColor: theme.palette.textLinkHover,
      },
    },
    fieldDisabled: {
      ...field,
      '&::before': {
        display: 'none',
      },
    },
    firstTile: {
      marginTop: 2,
      marginLeft: 2,
    },
    flexContainer: {
      height: 274,
      marginTop: 2,
      marginLeft: -2,
      paddingRight: 24,
      overflowY: 'auto',
    },
    label: {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
    errorMessage: {
      fontSize: 12,
      marginTop: 8,
    },
  };
};
