import gql from 'graphql-tag';

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    companyName
    addressLine1
    addressLine2
    addressLine3
    addressLastLine
    city
    region
    country
    marketInfo {
      display
    }
    postalCode
  }
`;

/**
 * DO NOT define credit property in this fragment.
 *
 * End customer always returns with credit as null, the credit information for soldTo will get re-write as null in cache.
 * Unsure why this happens for credit but not for address.
 */
export const ORGANIZATION_SIMPLE_FRAGMENT = gql`
  fragment OrganizationSimpleFragment on OrganizationSimple {
    id
    tradeName
    address {
      ...AddressFragment
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const REQUIRED_APPROVAL_FRAGMENT = gql`
  fragment SummaryRequiredApprovalFragment on RequiredApproval {
    order
    level
    policy
    state
    assignedUser
    assignedUsers
    actor {
      __typename
      ... on User {
        mail
      }
    }
  }
`;

export const GET_QUOTE = gql`
  query getQuote($id: String!) {
    getModernAgreementPreview(quoteId: $id) {
      documentDisplayUri
      documentDownloadUri
    }
    getQuote(id: $id) {
      id
      etag
      agreementType
      assignedTo
      audience
      billingCurrency
      expirationDate
      languages
      market
      motion
      name
      productAudience
      revision
      status
      transactionModel
      version
      vlAgreementNumber
      agreements(input: { agreementType: SPECIAL }) {
        edges {
          node {
            documentDisplayUri
            documentDownloadUri
          }
        }
      }
      crmLead {
        id
        salesAccount {
          address {
            companyName
          }
          id
          tpid
        }
        type
      }
      leadOrganization {
        ...OrganizationSimpleFragment
      }
      msContact {
        displayName
        mail
      }
      soldTo {
        dealEstimate
        monthlyCreditLimit
        organization {
          ...OrganizationSimpleFragment
          name
          accountId
          credit {
            decision
            reason
            currency
            creditLine
          }
        }
      }
      endCustomer {
        ...OrganizationSimpleFragment
      }
      approval {
        ... on Approval {
          id
          status
          history {
            action
            actor {
              ... on User {
                mail
              }
              ... on NonUserActor {
                name
              }
            }
            comments
            createdBy {
              ... on User {
                mail
              }
              ... on NonUserActor {
                name
              }
            }
            createdDate
            status
          }
          requiredApprovals {
            ...SummaryRequiredApprovalFragment
          }
        }
        ... on QuoteApproval {
          requiredApprovals {
            ...SummaryRequiredApprovalFragment
          }
        }
      }
      lineItems {
        id
        product {
          id
          productFamily
        }
      }
    }
  }
  ${ORGANIZATION_SIMPLE_FRAGMENT}
  ${REQUIRED_APPROVAL_FRAGMENT}
`;
