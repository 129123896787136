import * as appActions from 'features/app/actions';
import { AppEnvironment } from 'features/app/config/type';
import * as actions from 'features/user/actions';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'services';
import { ComplianceControlConfig } from 'services/ccf/config';
import { UserPermissions } from 'services/ccf/types';
import { FlightsConfig } from 'services/flights/config';
import { Flight, flightList } from 'services/flights/flightList';
import { setControlTowerFlights } from 'services/flights/utils';
import { LDSSConfig } from 'services/ldss/config';
import loggerService from 'services/logger-service';
import { UserPreferencesConfig } from 'services/user-preferences/config';
import { UserPreferences } from 'services/user-preferences/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { ThemeKey } from 'styles';

export function* loadUserPhoto(email: string) {
  try {
    yield put(actions.loadPhotoAsync.request(email));
    const photo: string = yield call(api.graph.getPhoto, email);
    yield put(actions.loadPhotoAsync.success(photo));
  } catch (err) {
    yield put(
      actions.loadPhotoAsync.failure({
        message: t('error::Error getting photo of user'),
        exception: err,
      })
    );
  }
}

export function* loadLDSSReadPermission() {
  const environment: AppEnvironment = yield select((state: RootState) => state.app.appConfig.app);
  try {
    yield put(actions.checkLDSSReadPermissionAsync.request());
    const ldssConfig: LDSSConfig = yield select((state: RootState) => state.app.appConfig.ldss);
    yield call(api.ldss.getLDSSHealthCheck, ldssConfig);
    yield put(
      actions.checkLDSSReadPermissionAsync.success({
        hasPermission: true,
        environment: environment,
      })
    );
  } catch (err) {
    if (err.response && (err.response.status === 403 || err.response.status === 401)) {
      yield put(
        actions.checkLDSSReadPermissionAsync.success({
          hasPermission: false,
          environment: environment,
        })
      );
    } else {
      yield put(
        actions.checkLDSSReadPermissionAsync.failure({
          message: t('error::Error checking LDSS read permission.'),
          exception: err,
        })
      );
    }
  }
}

export function* loadUserPermissions(email: string) {
  try {
    yield put(actions.loadUserPermissionsAsync.request(email));
    const config: ComplianceControlConfig = yield select(
      (state: RootState) => state.app.appConfig.ccf
    );
    const perms: UserPermissions = yield call(api.ccf.loadUserPermissions, email, config);
    yield put(actions.loadUserPermissionsAsync.success(perms));
  } catch (err) {
    yield put(
      actions.loadUserPermissionsAsync.failure({
        message: t('error::Error loading user permissions.'),
        exception: err,
      })
    );
  }
}

export function* loadFlights(email: string) {
  try {
    const request = { alias: email, flightList };
    yield put(appActions.loadFlightsAsync.request(request));
    const config: FlightsConfig = yield select((state: RootState) => state.app.appConfig.flights);
    const flights: Flight[] = yield call(api.flights.getFlights, request, config);
    setControlTowerFlights(flights);
    yield put(appActions.loadFlightsAsync.success(flights));
  } catch (err) {
    yield put(
      appActions.loadFlightsAsync.failure({
        message: t('error::Error getting flights.'),
        exception: err,
      })
    );
  }
}

export function* loadUserRoles(email: string) {
  const fromState: string[] | undefined = yield select(
    (state: RootState) => state.user.roles[email]
  );
  if (fromState) {
    return fromState;
  }
  try {
    yield put(actions.loadUserRoles.request(email));
    const config: ComplianceControlConfig = yield select(
      (state: RootState) => state.app.appConfig.ccf
    );
    const roles: string[] = yield call(api.ccf.loadUserRoles, email, config);
    yield put(actions.loadUserRoles.success({ email, roles }));
    return roles;
  } catch (err) {
    yield put(
      actions.loadUserRoles.failure({
        message: t('error::Error loading user roles.'),
        exception: err,
      })
    );
  }
}

export function* loadUserPreferences(email: string) {
  try {
    yield put(actions.loadUserPreferencesAsync.request(email));
    const config: UserPreferencesConfig = yield select(
      (state: RootState) => state.app.appConfig.userPreferences
    );
    const preferences: UserPreferences = yield call(
      api.userPreferences.loadUserPreferences,
      email,
      config
    );
    api.userPreferences.setLocalPreferences(preferences);
    yield put(actions.loadUserPreferencesAsync.success(preferences));
    return preferences;
  } catch (err) {
    //TODO: cameneks, use optional chaining operator for typescript when available.
    if (err && err.response && err.response.status === 404) {
      yield put(
        actions.loadUserPreferencesAsync.success({
          eTag: '',
          theme: ThemeKey.light,
          viewedInfoDialogs: [],
        })
      );
      return;
    }
    yield put(
      actions.loadUserPreferencesAsync.failure({
        message: t('error::Error loading user preference.'),
        exception: err,
      })
    );
  }
}

export function* updateUserPreferences(userPreferences: UserPreferences, email: string) {
  try {
    if (!email) {
      loggerService.error({
        error: new Error('Email can not be null or undefined.'),
      });
      return;
    }
    const config: UserPreferencesConfig = yield select(
      (state: RootState) => state.app.appConfig.userPreferences
    );
    yield put(actions.updateUserPreferencesAsync.request(userPreferences));
    const preferences: UserPreferences = yield call(
      api.userPreferences.updateUserPreferences,
      email,
      userPreferences,
      config
    );
    api.userPreferences.setLocalPreferences(preferences);
    yield put(actions.updateUserPreferencesAsync.success(preferences));
  } catch (err) {
    yield put(
      actions.updateUserPreferencesAsync.failure({
        message: t('error::Error updating user preference.'),
        exception: err,
      })
    );
    // Reload userPreference if we get 412 error.
    if (err.response.status === 412) {
      yield call(loadUserPreferences, email);
    }
  }
}

export function* deleteUserPreferences(email: string) {
  try {
    const config: UserPreferencesConfig = yield select(
      (state: RootState) => state.app.appConfig.userPreferences
    );
    yield call(api.userPreferences.deleteUserPreferences, email, config);
    // Remove from local store.
    api.userPreferences.removeLocalPreferences();
    yield put(actions.deleteUserPreferencesAsync.success());
  } catch (err) {
    yield put(
      actions.deleteUserPreferencesAsync.failure({
        message: t('error::Error deleting user preference.'),
        exception: err,
      })
    );
  }
}
