import { SkuRow, CreateLineItemProperties } from 'features/proposal/components/ConfigCard';
import { createStandardAction } from 'typesafe-actions';

import { ConfigCardBusinessLogicState } from './reducer';

export const locationSelection = createStandardAction('LOCATION_SELECTION')<string>();
export const durationSelection = createStandardAction('DURATION_SELECTION')<string>();
export const termsSelection = createStandardAction('TERMS_SELECTION')<string>();
export const listSelection = createStandardAction('LIST_SELECTION')<{
  key: string;
  skuRow: SkuRow;
}>();
export const addLineItems = createStandardAction('ADD_LINE_ITEMS')<CreateLineItemProperties[]>();
export const clearListSelection = createStandardAction('CLEAR_LIST_SELECTION')<{}>();
export const clearTermSelection = createStandardAction('CLEAR_TERM_SELECTION')<{}>();
export const clearTermDurationSelection = createStandardAction(
  'CLEAR_TERM_DURATION_SELECTION'
)<{}>();
export const recurringCheckboxToggled = createStandardAction('RECURRING_CHECKBOX_TOGGLED')<{}>();
export const startDateInfoToggled = createStandardAction('START_DATE_INFO_TOGGLED')<{}>();
export const startDateInfoClosed = createStandardAction('START_DATE_INFO_CLOSED')<{}>();
export const applyConfiguration = createStandardAction('APPLY_CONFIGURATION')();
export const cancelPopup = createStandardAction('CANCEL_POPUP')();
export const startDateAdded = createStandardAction('START_DATE_ADDED')<string>();
export const startDateSelected = createStandardAction('START_DATE_SELECTED')<string>();
export const TermDurationSelection = createStandardAction('TERM_DURATION_SELECTION')<string>();
export const reset = createStandardAction('RESET_STATE')<ConfigCardBusinessLogicState>();
