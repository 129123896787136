import { TextBody } from 'components/ions';
import { NumberInput } from 'components/ions/NumberInput';
import { EventTrap } from 'components/utilities';
import { Currency } from 'features/proposal/supported-currencies';
import { formatCurrency } from 'features/proposal/utils';
import i18next from 'i18next';
import React from 'react';

import { TitleFormatter } from './TitleFormatter';

const PLACEHOLDER_UNDEFINED = '-';

export const priceFormatter = (t: i18next.TFunction, currency: Currency, value?: number) => {
  return (
    <TextBody>
      {value !== undefined
        ? t('{{amount}} {{currency}}', { amount: formatCurrency(value), currency })
        : PLACEHOLDER_UNDEFINED}
    </TextBody>
  );
};

export const percentageFormatter = (value?: number, maximumFractionDigits = 2) => {
  return (
    <TextBody>
      {((value || value === 0) &&
        value.toLocaleString(i18next.language, { style: 'percent', maximumFractionDigits })) ||
        PLACEHOLDER_UNDEFINED}
    </TextBody>
  );
};

export const textFormatter = (value?: string) => {
  return <TextBody>{value || PLACEHOLDER_UNDEFINED}</TextBody>;
};

export const quantityFormatter = (
  width: number,
  value?: number,
  onUpdate?: (value: number) => void,
  min?: number,
  max?: number,
  minMessage?: string,
  maxMessage?: string,
  disableEdit?: boolean,
  title?: string
) => {
  return !disableEdit && value ? (
    <EventTrap>
      <NumberInput
        max={max}
        maxMessage={maxMessage}
        min={min || 1}
        minMessage={minMessage}
        value={value}
        width={width}
        onBlur={onUpdate}
      />
    </EventTrap>
  ) : (
    <TextBody title={title}>{value || PLACEHOLDER_UNDEFINED}</TextBody>
  );
};

export const titleFormatter = (columnWidth: number, value?: string) => {
  return <TitleFormatter value={value || PLACEHOLDER_UNDEFINED} width={columnWidth} />;
};
