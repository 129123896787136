//TODO: found a better way

import {
  ApplyConfigurationSingleSkuInput,
  CatalogContext,
  Currency,
  QuoteMutationInput,
} from 'generated/graphql';

export enum TermComponentType {
  BillingPlan = 'BillingPlan',
  TermDuration = 'TermDuration',
}

export enum ProductFamily {
  Azure = 'Azure',
  Entitlement = 'Entitlement',
  AzureSupport = 'AzureSupport',
}

export enum FilterNames {
  Action = 'Action',
  Duration = 'TermDescription',
  Location = 'Location',
}

export enum LocationOptions {
  AllLocations = 'All Locations',
}

export enum DurationOptions {
  AllReservations = 'All Reservations',
  Paygo = 'Pay go',
}

export interface ConfigurationCardCommonProps {
  productTitle: string;
  currency: Currency;
}

export interface SkuConfigurationCardCommonProps {
  catalogContext: CatalogContext;
  alreadyHasDiscount?: boolean;
}

export interface ApplySingleSkuVariables {
  quote: QuoteMutationInput;
  configuration: ApplyConfigurationSingleSkuInput;
}

export enum View {
  CardContent = 'Card Content',
  ConfirmationDialog = 'Confirmation Dialog',
}
