import { SecondaryButton, TextBodySmall, TextboxStandard } from 'components';
import { ErrorType } from 'features-apollo/quote/components/Wizards/shared';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { OrgWizardSharedProps, WizardDialog } from '../Shared';
import { accountNameDialogStyles } from './AccountNameDialog.styles';

export interface AccountNameDialogProps extends OrgWizardSharedProps<string> {
  onNextButtonClick: (accountName: string) => void;
}

type Props = AccountNameDialogProps & WithStyles<typeof accountNameDialogStyles>;

const AccountNameDialogUnstyled: React.FC<Props> = props => {
  const { classes, initialData } = props;
  const { t } = useTranslation();

  const [accountName, setAccountName] = React.useState<string>(initialData || '');
  const [isValid, setIsValid] = React.useState<boolean>(!!initialData);
  const [errorType, setErrorType] = React.useState<ErrorType | undefined>(undefined);

  const onClickNext = () => {
    props.onNextButtonClick(accountName);
  };

  const onClickOverview = () => {
    accountName && !errorType ? props.onOverviewClick(accountName) : props.onOverviewClick();
  };

  const body = (
    <div>
      <TextBodySmall addClass={classes.secondaryText}>
        {t(
          'quote::A customer Account may have multiple Billing Accounts. Since you are creating the first Billing Account, you need to give a name to the Account, all up. For example, if Microsoft Corporation was our customer, they might need different Billing Accounts for the different countries they operate in, e.g., Microsoft Japan, Microsoft India, etc. Collectively, you would call the Account, Microsoft.'
        )}
      </TextBodySmall>
      <TextboxStandard
        addClass={classes.nameStyles}
        dataAutomationId="accountName"
        label={t('quote::Account name')}
        required
        value={accountName}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value = newValue || '';
          setAccountName(value);
          if (value && value.trim()) {
            setIsValid(true);
            setErrorType(undefined);
          } else {
            setIsValid(false);
            setErrorType(ErrorType.missingInformation);
          }
        }}
      />
    </div>
  );

  const footerButtons = [
    <SecondaryButton
      dataAutomationId="accountNameDialogNext"
      disabled={!isValid}
      key="next"
      text={i18next.t('quote::Next')}
      onClick={onClickNext}
    />,
  ];

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      errorType={errorType}
      footer={footerButtons}
      headline={props.headline}
      isOverviewAvailable={props.isOverviewAvailable}
      overviewButtonOnly={props.isOverviewAvailable}
      title={t('quote::Account name')}
      onOverviewClick={onClickOverview}
    />
  );
};

export const AccountNameDialog = withStyles(accountNameDialogStyles)(AccountNameDialogUnstyled);
