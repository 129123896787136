import cloneDeep from 'clone-deep';
import { loadProducts } from 'features/catalog/sagas/auxiliary';
import { getNewAvailability, getNewTermId } from 'features/catalog/selectors';
import { getAllProductIds, getProposalNextExpiryDate } from 'features/proposal/selectors';
import { getCurrencies, getLanguages, Market } from 'features/proposal/supported-markets';
import { call, select } from 'redux-saga/effects';
import { ExternalUser } from 'services/externaluser/types';
import { convertToString } from 'services/externaluser/utils';
import {
  LineItem,
  Proposal,
  ProposalAction,
  ProposalStatus,
  UserGroup,
} from 'services/proposal/types';
import { PatchCommand } from 'services/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { updateQuote } from './serviceCalls';

export function* ensureLineItemAvailabilities(quote: Proposal) {
  if (!quote.lineItems.length) {
    return quote;
  }
  yield call(loadProducts, getAllProductIds(quote));

  const state: RootState = yield select((state: RootState) => state);
  let needsChange = false;
  const lineItems = quote.lineItems.map(lineItem => {
    let newLineItem: LineItem | undefined;
    const productIdentifier = lineItem.productIdentifier;
    const newAvailability = getNewAvailability(state, productIdentifier);
    const newTermId =
      newAvailability && getNewTermId(newAvailability, productIdentifier.availabilityTermId);
    if (
      newAvailability &&
      (newAvailability.AvailabilityId !== productIdentifier.availabilityId ||
        (newTermId && newTermId !== productIdentifier.availabilityTermId))
    ) {
      newLineItem = cloneDeep(lineItem);
      newLineItem.productIdentifier.availabilityId = newAvailability.AvailabilityId;
      newLineItem.productIdentifier.availabilityTermId = newTermId;

      const existingExtendedProperties = oc(lineItem).extendedProperties({});
      const existingUserPreferences = oc(lineItem).extendedProperties.userPreferences({});
      const newUserPrefsTerm = oc(lineItem).extendedProperties.userPreferences.term() && newTermId;
      const newUserPrefsAvailabilityId =
        oc(lineItem).extendedProperties.userPreferences.availabilityId() &&
        newAvailability.AvailabilityId;

      let newUserPrefsTermDurationKey = undefined;

      //TODO: check the modern office stuff here
      //FIXME: this might break with modern office stuff handle that also tracking on bug id 25530719
      // We can only confidently assign the term value to term duration key if they are already the same
      // since they can be different (termDurationKey would refer to the first dropdowns selection and term would be the second dropdowns in the modern office scenario)
      if (
        oc(lineItem).extendedProperties.userPreferences.termDurationKey() &&
        oc(lineItem).extendedProperties.userPreferences.term() ===
          oc(lineItem).extendedProperties.userPreferences.termDurationKey()
      ) {
        newUserPrefsTermDurationKey = newTermId;
      }

      newLineItem.extendedProperties = {
        ...existingExtendedProperties,
        userPreferences: {
          ...existingUserPreferences,
          availabilityId: newUserPrefsAvailabilityId,
          availabilityTermId: newTermId,
          term: newUserPrefsTerm,
          termDurationKey: newUserPrefsTermDurationKey,
        },
      };
    }
    const discountedItem = oc(lineItem).pricingInstruction.productIdentifier();
    if (discountedItem && discountedItem.availabilityId) {
      const newDiscountedAvailability = getNewAvailability(state, discountedItem);
      if (
        newDiscountedAvailability &&
        newDiscountedAvailability.AvailabilityId !== discountedItem.availabilityId
      ) {
        newLineItem = newLineItem || cloneDeep(lineItem);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const identifier = newLineItem.pricingInstruction!.productIdentifier!;
        identifier.availabilityId = newDiscountedAvailability.AvailabilityId;
      }
    }
    if (discountedItem && !lineItem.fulfillmentReferenceData) {
      newLineItem = newLineItem || cloneDeep(lineItem);
      newLineItem.fulfillmentReferenceData = `quoteId:${quote.id},lineItemId:${newLineItem.id}`;
    }
    if (newLineItem) {
      needsChange = true;
      return newLineItem;
    }
    return lineItem;
  });
  if (!needsChange) {
    return;
  }
  let newQuote: Proposal = { ...quote, lineItems };
  newQuote = yield call(updateQuote, {
    etag: quote.etag,
    proposalId: quote.id,
    proposal: newQuote,
  });
  return newQuote;
}

export function changeMarketPatchCommands(market: Market) {
  const commands: PatchCommand[] = [
    { op: 'replace', path: '/pricingContext/market', value: market },
    {
      op: 'replace',
      path: '/pricingContext/billingCurrency',
      value: getCurrencies(market)[0],
    },
    {
      op: 'replace',
      path: '/pricingContext/languages',
      value: getLanguages(market)[0],
    },
    { op: 'remove', path: '/estimatedDealValue' },
  ];
  return commands;
}

export function changeInvitedUserPatchCommand(user: ExternalUser) {
  const invitedUser = convertToString(user);
  const command: PatchCommand = {
    op: 'add',
    path: '/invitedUser',
    value: invitedUser,
  };
  return command;
}

export function* changeExpirationDatePatchCommand() {
  const nextExpiration = yield select((state: RootState) => getProposalNextExpiryDate(state));
  const command: PatchCommand = {
    op: 'replace',
    path: '/expirationDate',
    value: nextExpiration,
  };
  return command;
}
const withdrawableStatuses = [
  ProposalStatus.Submitted,
  ProposalStatus.Active,
  ProposalStatus.Rejected,
  ProposalStatus.Expired,
];
export function getWithdrawActions(quote: Proposal) {
  const bodies = [];
  if (quote.header.assignedToGroup === UserGroup.Customer) {
    bodies.push({
      action: ProposalAction.AssignToUserGroup,
      assignedToGroup: UserGroup.Field,
    });
  }
  if (withdrawableStatuses.includes(quote.header.status)) {
    bodies.push({ action: ProposalAction.Withdraw });
  }
  return bodies;
}
