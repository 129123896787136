import { SectionSeparator } from 'components';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { PropertySheetSection } from './PropertySheetSection';

export interface PropertySheetContent {
  leftPaneTitle: string;
  content: React.ReactNode;
}

export interface PropertySheetLayoutProps {
  contentList: PropertySheetContent[];
}

export const propertySheetLayoutStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    columnFlexMainContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: palette.backgroundCommon,
      overflowY: 'auto',
      paddingBottom: 40,
    },
    separator: {
      display: 'inline-table',
      backgroundColor: theme.palette.textDisabled,
      width: 470,
      marginTop: 34,
      marginLeft: 168,
    },
  };
};

type Props = PropertySheetLayoutProps & WithStyles<typeof propertySheetLayoutStyles>;

export const PropertySheetLayoutUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, contentList } = props;
  const content = [...contentList].slice(0, -1);
  const lastContent = contentList[contentList.length - 1];

  const sections = content.map(({ leftPaneTitle, content }) => (
    <React.Fragment key={leftPaneTitle}>
      <PropertySheetSection leftPaneTitle={leftPaneTitle}>{content}</PropertySheetSection>
      <SectionSeparator addClass={props.classes.separator} />
    </React.Fragment>
  ));

  return (
    <div className={classes.columnFlexMainContent}>
      {sections}
      {lastContent && (
        <PropertySheetSection leftPaneTitle={lastContent.leftPaneTitle}>
          {lastContent.content}
        </PropertySheetSection>
      )}
    </div>
  );
};

export const PropertySheetLayout = withStyles(propertySheetLayoutStyles)(
  PropertySheetLayoutUnstyled
);
