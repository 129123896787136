import { PrimaryButton, SecondaryButton, TextBody, TextTitle, XLargeIcon } from 'components';
import { Dialog, DialogImageProps, headerHeight } from 'components/ions/PopUps/Dialogs/Dialog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext, sharedStyles, ThemeProps } from 'styles';

import { Dimensions } from '../types';

export interface FailProps extends Dimensions {
  footerButtons?: React.ReactNode;
  message?: string | React.ReactElement;
  onTryAgainClick?: () => void;
  title?: string;
  isLongText?: boolean; // TODO: Shawn - break this into an alternate component with different styling for fail dialogs with longer text. Centered text is hard to read when it spans multiple lines
  dataAutomationId?: string;
  hideTryAgainMessage?: boolean;
  imageProps?: DialogImageProps;
  /**
   * Callback for any close button. It does not override the close action.
   */
  onClose?: () => void;
}

const styles = (theme: ThemeProps) => {
  return {
    icon: { ...sharedStyles(theme).iconForDialog },
    iconContainer: {
      ...sharedStyles(theme).iconContainerForDialog,
      backgroundColor: theme.palette.dangerText,
      '& i': {
        color: theme.palette.backgroundStandout,
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: (props: FailProps) => props.height / 4 - headerHeight,
    },
    message2: {
      color: theme.palette.textTertiary,
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& > :not(:first-child)': {
        paddingTop: 16,
      },
      maxWidth: 360,
      textAlign: 'center',
    },
    leftAlignWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& > :not(:first-child)': {
        paddingTop: 16,
      },

      textAlign: 'left',
    },
    longTextContainer: {
      display: 'flex',
      paddingLeft: 16,
      paddingRight: 16,
      flexDirect: 'row',
    },
    longTextTitle: {
      marginBottom: 8,
    },
    longTextIcon: {
      ...sharedStyles(theme).iconContainerForDialog,
      backgroundColor: theme.palette.dangerText,
      '& i': {
        color: theme.palette.backgroundStandout,
      },
      marginRight: 20,
      minWidth: 50,
      minHeight: 50,
    },
    longText: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
  };
};

type Props = FailProps & WithStyles<typeof styles>;
const FailUnStyled: React.FC<Props> = props => {
  const { classes, title } = props;
  const { t } = useTranslation();

  const context = React.useContext(DialogContext);

  const onClose = () => {
    props.onClose && props.onClose();
    context.closeDialog();
  };

  const buttons =
    props.footerButtons ||
    (props.onTryAgainClick ? (
      [
        <PrimaryButton
          dataAutomationId={`${props.dataAutomationId}tryAgainButton`}
          key="try-again-btn"
          text={t('Try Again')}
          onClick={props.onTryAgainClick}
        />,
        <SecondaryButton
          dataAutomationId={`${props.dataAutomationId}CloseButton`}
          key="close-btn"
          text={t('Close')}
          onClick={onClose}
        />,
      ]
    ) : (
      <PrimaryButton
        dataAutomationId={`${props.dataAutomationId}CloseButton`}
        text={t('Close')}
        onClick={onClose}
      />
    ));

  const message = () => {
    if (props.message) {
      if (typeof props.message === 'string') {
        return <TextBody>{props.message}</TextBody>;
      } else {
        return props.message;
      }
    }
    return <TextBody>{t('Something went wrong.')}</TextBody>;
  };

  return (
    <Dialog
      closeDialog={onClose}
      dataAutomationId={props.dataAutomationId}
      footerButtons={buttons}
      height={props.height}
      imageProps={props.imageProps}
      title={!props.isLongText ? props.title : undefined}
      width={props.width}
    >
      {props.isLongText ? (
        <div className={classes.longTextContainer}>
          <div className={classes.longTextIcon}>
            <XLargeIcon addClass={classes.icon} iconName="Cancel" />
          </div>
          <div className={classes.longText}>
            {title && (
              <div className={classes.longTextTitle}>
                <TextTitle>{title}</TextTitle>
              </div>
            )}
            {message()}
          </div>
          {props.onTryAgainClick && !props.hideTryAgainMessage && (
            <TextBody>{t('Please try again.')}</TextBody>
          )}
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes.iconContainer}>
              <XLargeIcon addClass={classes.icon} iconName="Cancel" />
            </div>
            <div>{message()}</div>
            {props.onTryAgainClick && !props.hideTryAgainMessage && (
              <TextBody>{t('Please try again.')}</TextBody>
            )}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export const Fail = withStyles(styles)(FailUnStyled);
