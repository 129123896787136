import { ThemeProps } from 'styles';

export const listContainerDivStyles = (height: string, width: string) => {
  return { height, width, position: 'relative' };
};

export const stickyHeaderGroupedStyles = (theme: ThemeProps) => {
  return {
    header: {
      '& .ms-FocusZone.ms-DetailsHeader': {
        background: theme.palette.backgroundCommon,
        lineHeight: 1.5,
        paddingBottoms: 7,
        width: '100%',
      },
      '& .ms-DetailsHeader-cellName': {
        whiteSpace: 'normal',
        lineHeight: 1.5,
      },
      '& .ms-DetailsHeader-cellTitle': {
        paddingTop: 6,
      },
      '& .ms-DetailsHeader-collapseButton': {
        cursor: 'pointer',
      },
      '& .is-selected': {
        background: theme.palette.lineItemHighlight,
        color: theme.palette.text,
      },
      '& .is-selected .ms-DetailsRow-cell': {
        color: theme.palette.text,
      },
      '& .ms-GroupHeader-expand:hover': {
        backgroundColor: theme.palette.backgroundCommon,
      },
      '& .ms-GroupHeader-expand:active': {
        backgroundColor: theme.palette.backgroundCommon,
      },
      '& .ms-GroupHeader.is-selected': {
        backgroundColor: theme.palette.backgroundCommon,
        border: 'none',
      },
      '& .is-selected.ms-DetailsRow:hover': {
        background: theme.palette.lineItemHighlight,
      },
    },

    removeRowSeparators: {
      '& .ms-FocusZone.ms-DetailsRow': {
        borderBottom: 0,
      },
    },
    row: {
      '& .ms-DetailsRow': {
        width: '100%',
      },
      '& .is-selected': {
        background: theme.palette.lineItemHighlight,
        color: theme.palette.text,
      },
    },
  };
};
