import { H2Atom, TextSharedProps } from 'components/atoms/Text';
import * as React from 'react';

type Props = TextSharedProps;

export const TextTitleSecondary: React.FC<Props> = props => {
  return (
    <H2Atom addClass={props.addClass} dataAutomationId={props.dataAutomationId} id={props.id}>
      {props.children}
    </H2Atom>
  );
};
