/* eslint-disable @typescript-eslint/camelcase */
import { ComboBox, LinkButton, PrimaryButton, SecondaryButton, TextboxStandard } from 'components';
import {
  FieldType,
  getAddressConfiguration,
  getAddressForValidation,
  getNewAddressForCountry,
  hasFieldValidationError,
  hasValidAddressFields,
  isPuertoRico,
  trimAddressFields,
} from 'components/utilities/address';
import { useDebounce } from 'components/utilities/debounce';
import { emptyAddress } from 'features/components';
import { Address } from 'features/customer/types';
import { BodyProps, ErrorType } from 'features/proposal/components/Wizards/shared';
import {
  getTranslatedMarketsAlphabetically,
  isModernMarketCountry,
  ModernMarket,
} from 'features/proposal/supported-markets';
import {
  getTranslatedRegionsAlphabetically,
  MarketWithRegions,
  RegionDisplayType,
  RegionField,
  regions,
  RegionType,
} from 'features/proposal/supported-regions';
import i18next from 'i18n';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { Address as ValidationAddress, SuggestedAddress } from 'services/address-validation/types';
import loggerService from 'services/logger-service';

import { addressDialogStyles } from './AddressDialog.styles';
import {
  AddressDialogHeader,
  AddressHeaderMessage,
  HeaderMessageSeverity,
} from './AddressDialogHeader';

/**
 * Props for AddressDialogProps component
 *
 * @prop {boolean} isEditFlow changes the experience of the user where valid defined Country cannot be edited
 */
interface AddressDialogProps {
  hasAddressValidationResponse: boolean;
  hasAvsApiFailure?: boolean;
  hasValidAddressFromService: boolean;
  isEditFlow?: boolean;
  isSalesAccount: boolean;
  showInitialErrorMessage: boolean;
  validationErrors: string[];
  validationOriginalAddress?: ValidationAddress;
  validationSuggestedAddress?: SuggestedAddress;
  resetAddressValidation: () => void;
  validateAddress: (address: ValidationAddress) => void;
}

type ActionType = HTMLAnchorElement | HTMLElement | HTMLButtonElement;
type AddressProps = AddressDialogProps &
  BodyProps<Address> &
  WithStyles<typeof addressDialogStyles>;

const addressCharacterLimit = 128;

export const AddressHeaderString = () => i18next.t('quote::Address');

export const getInitialAddress = (address: Address): Address => {
  let initialAddress = {
    ...address,
  };

  if (isPuertoRico(initialAddress.country)) {
    initialAddress.region = 'PR';
  } else {
    const addressConfig = getAddressConfiguration(address.country);

    if (addressConfig.region.display === RegionDisplayType.None) {
      initialAddress.region = '';
    }
  }

  return initialAddress;
};

export const AddressBodyUnstyled: React.FC<AddressProps> = props => {
  const {
    classes,
    hasAddressValidationResponse,
    hasAvsApiFailure,
    hasValidAddressFromService,
    initialData,
    isSalesAccount,
    onInvalid,
    onValid,
    showInitialErrorMessage,
    validateAddress,
    validationErrors,
    validationOriginalAddress,
    validationSuggestedAddress,
  } = props;
  const { t } = useTranslation();

  const hasCountry = initialData && !!initialData.country;
  const isModernMarket = initialData && isModernMarketCountry(initialData.country);
  const countryReadOnly = props.isEditFlow && hasCountry && isModernMarket;

  const [address, setAddress] = React.useState<Address>(
    isModernMarket && initialData ? getInitialAddress(initialData) : emptyAddress
  );

  const [isAddLineClicked, setIsAddLineClicked] = React.useState(!!address.addressLine3);

  const onClickAddLine = (event: React.MouseEvent<ActionType, MouseEvent>) => {
    if (event) setIsAddLineClicked(true);
  };

  const addressStyles = `${classes.addressWidthStyles} ${classes.addressPaddingStyles}`;

  const autoFocusCountryCallback = React.useCallback(component => {
    component && component.focus(false, true);
  }, []);

  const [touched, setTouched] = React.useState<Partial<Record<FieldType, boolean>>>({});

  const [isPrepopulatedDataInvalid, setIsPrepopulatedDataInvalid] = React.useState(
    hasCountry && isModernMarket && showInitialErrorMessage && !hasValidAddressFields(address)
  );

  //this effect updates the ui with the address suggested by AVS, based on user input
  React.useEffect(() => {
    if (
      //prevent race condition: make sure the AVS response is what the user last typed
      validationOriginalAddress &&
      validationOriginalAddress.postal_code === address.postalCode &&
      //prevent address update loop: only call setAddress if the postal code isn't the suggested value
      validationSuggestedAddress &&
      validationSuggestedAddress.postal_code &&
      validationSuggestedAddress.postal_code !== address.postalCode
    ) {
      setAddress({
        ...address,
        postalCode: validationSuggestedAddress.postal_code,
      });
    }
  }, [address, validationOriginalAddress, validationSuggestedAddress]);

  const maxComboBoxHeight = 250;
  const addressDebounce$ = useDebounce(500);

  React.useEffect(() => {
    if (hasValidAddressFields(address)) {
      props.resetAddressValidation();
      onInvalid(ErrorType.invalidField);

      //make the call to AVS
      addressDebounce$.next(() => {
        const addressForValidation = getAddressForValidation(address);
        validateAddress(addressForValidation);
      });
    } else {
      props.resetAddressValidation();
      onInvalid(ErrorType.missingInformation);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  React.useEffect(() => {
    if (hasValidAddressFields(address) && !validationErrors.length) {
      setIsPrepopulatedDataInvalid(false);
      onValid(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidAddressFields(address), validationErrors]);

  const getRegionLabel = (regionType: RegionType): string => {
    if (regionType === RegionType.Province) {
      return t('quote::Province');
    }

    if (regionType === RegionType.State) {
      return t('quote::State');
    }

    return t('quote::Region');
  };

  const getFieldErrorMessage = (field: FieldType, required: boolean): string | undefined => {
    if (required) {
      const trimmedAddress = trimAddressFields(address);

      if (
        (field === FieldType.AddressLine1 && !trimmedAddress.addressLine1) ||
        (field === FieldType.City && !trimmedAddress.city) ||
        (field === FieldType.Region && !trimmedAddress.region) ||
        (field === FieldType.PostalCode && !trimmedAddress.postalCode)
      ) {
        return touched[field] ? t('quote::Field is required') : undefined;
      }
    }

    if (hasFieldValidationError(field, validationErrors)) {
      onInvalid(ErrorType.invalidField);
      return t('quote::Incorrect value');
    }
  };

  const addressConfig = getAddressConfiguration(address.country);
  const accountType = isSalesAccount ? t('quote::sales account') : t('quote::enrollment');
  const showAvsGenericErrorMessage =
    hasAddressValidationResponse && !hasValidAddressFromService && !validationErrors.length;

  const getAddressLine1 = () => (
    <TextboxStandard
      addClass={addressStyles}
      dataAutomationId="addressDialogAddressLine1"
      errorMessage={getFieldErrorMessage(FieldType.AddressLine1, true)}
      label={t('quote::Address line 1')}
      maxLength={addressCharacterLimit}
      placeholder={t('quote::i.e. Street and number or P.O. Box #')}
      required
      value={address.addressLine1 || ''}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        const value: Address = {
          ...address,
          addressLine1: newValue || '',
        };
        setAddress(value);
        setTouched({
          ...touched,
          [FieldType.AddressLine1]: true,
        });
      }}
    />
  );

  const getAddressLine2 = () => (
    <span className={classes.addressContainerInline}>
      <TextboxStandard
        addClass={addressStyles}
        dataAutomationId="addressDialogAddressLine2"
        label={t('quote::Address line 2')}
        maxLength={addressCharacterLimit}
        placeholder={t('quote::i.e. Unit #, Suite, Floor')}
        value={address.addressLine2 || ''}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value: Address = {
            ...address,
            addressLine2: newValue || undefined,
          };
          setAddress(value);
          setTouched({
            ...touched,
            [FieldType.AddressLine2]: true,
          });
        }}
      />
      {!isAddLineClicked && !address.addressLine3 && (
        <LinkButton
          addClass={classes.addLinePadding}
          dataAutomationId="addressDialogAddLine"
          displayText={t('quote::add line')}
          iconLeft={true}
          iconName="AddTo"
          onClick={onClickAddLine}
        />
      )}
    </span>
  );

  const getAddressLine3 = () => (
    <TextboxStandard
      addClass={addressStyles}
      dataAutomationId="addressDialogAddressLine3"
      label={t('quote::Address line 3')}
      maxLength={addressCharacterLimit}
      value={address.addressLine3 || ''}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        const value: Address = {
          ...address,
          addressLine3: newValue || undefined,
        };
        setAddress(value);
        setTouched({
          ...touched,
          [FieldType.AddressLine3]: true,
        });
      }}
    />
  );

  const getCity = () => (
    <TextboxStandard
      addClass={addressStyles}
      dataAutomationId="addressDialogCity"
      errorMessage={getFieldErrorMessage(FieldType.City, true)}
      key="city"
      label={t('quote::City')}
      maxLength={addressCharacterLimit}
      placeholder={t('quote::e.g. Redmond')}
      required
      value={address.city || ''}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        const value: Address = {
          ...address,
          city: newValue || '',
        };
        setAddress(value);
        setTouched({
          ...touched,
          [FieldType.City]: true,
        });
      }}
    />
  );

  const getRegion = (props: { region: RegionField }) => {
    const { region } = props;

    if (regions[address.country as MarketWithRegions]) {
      let regionOptions = getTranslatedRegionsAlphabetically(
        t,
        address.country as MarketWithRegions
      );
      regionOptions.unshift({
        key: 'select-region',
        text: t('quote::Select a {{regionType}}', { regionType: getRegionLabel(region.type) }),
      });
      const getDefaultRegion = () => {
        const regionKey = regionOptions.find(
          option =>
            option.key === (address.region && address.region.toUpperCase()) ||
            option.text.toUpperCase() === (address.region && address.region.toUpperCase())
        );
        return regionKey ? regionKey.key.toString() : 'select-region';
      };
      return (
        <div className={addressStyles} key="region">
          <ComboBox
            errorMessage={getFieldErrorMessage(FieldType.Region, true)}
            id="region"
            label={getRegionLabel(region.type)}
            maxHeight={maxComboBoxHeight}
            options={regionOptions}
            required
            selectedKey={getDefaultRegion()}
            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
              if (option) {
                const value: Address = {
                  ...address,
                  region: option.key === 'select-region' ? '' : option.key.toString(),
                };
                setAddress(value);
                setTouched({
                  ...touched,
                  [FieldType.Region]: true,
                });
              }
            }}
          />
        </div>
      );
    } else {
      const required = region.display === RegionDisplayType.Required;
      return (
        <TextboxStandard
          addClass={addressStyles}
          dataAutomationId="addressDialogRegion"
          errorMessage={
            required || address.region
              ? getFieldErrorMessage(FieldType.Region, required)
              : undefined
          }
          key="region"
          label={getRegionLabel(region.type)}
          placeholder={t('quote::e.g. Washington')}
          required={required}
          value={address.region || ''}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) => {
            const value: Address = {
              ...address,
              region: newValue || undefined,
            };
            setAddress(value);
            setTouched({
              ...touched,
              [FieldType.Region]: true,
            });
          }}
        />
      );
    }
  };

  const getPostalCode = () => {
    return (
      <TextboxStandard
        addClass={addressStyles}
        dataAutomationId="addressDialogPostalCode"
        errorMessage={getFieldErrorMessage(FieldType.PostalCode, true)}
        key="postalCode"
        label={t('quote::Postal Code')}
        maxLength={addressCharacterLimit}
        placeholder={t('quote::e.g. 98052')}
        required
        value={address.postalCode || ''}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value: Address = {
            ...address,
            postalCode: newValue || undefined,
          };
          setAddress(value);
          setTouched({
            ...touched,
            [FieldType.PostalCode]: true,
          });
        }}
      />
    );
  };

  const getCountry = () => {
    return (
      <div className={addressStyles}>
        <ComboBox
          componentRef={autoFocusCountryCallback}
          disabled={countryReadOnly}
          id="country"
          label={t('quote::Country')}
          maxHeight={maxComboBoxHeight}
          options={getTranslatedMarketsAlphabetically(t)}
          required={!countryReadOnly}
          selectedKey={address.country}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            const country = option ? option.key.toString() : 'US';
            const newAddress = getNewAddressForCountry(address, country as ModernMarket);
            setAddress(newAddress);
            setTouched({
              ...touched,
              [FieldType.Country]: true,
            });
          }}
        />
      </div>
    );
  };

  const getHeaderMessage = (): AddressHeaderMessage | null => {
    let headerMessage = null;

    if (hasCountry && !isModernMarket) {
      headerMessage = {
        text: t(
          `quote::We tried to populate the address based on the {{accountType}}. Unfortunately, the customer resides in a country not currently eligible for the Microsoft Customer Agreement.`,
          { accountType }
        ),
        severity: HeaderMessageSeverity.Error,
      };
    }

    if (isPrepopulatedDataInvalid) {
      headerMessage = {
        text: t(
          `quote::We've populated the address based on the {{accountType}}. Unfortunately, there are a few errors in the data that need correcting. See below.`,
          { accountType }
        ),
        severity: HeaderMessageSeverity.Error,
      };
    }

    if (hasAvsApiFailure) {
      headerMessage = {
        text: t(
          'quote::We were unable to validate the address provided due to a service error. If you are confident the address is correct, you may still proceed.'
        ),
        severity: HeaderMessageSeverity.Warning,
      };
    }

    if (showAvsGenericErrorMessage) {
      headerMessage = {
        text: t(
          'quote::We were unable to confirm the address you provided. Often this occurs because there’s a mismatch between two fields like the city and the postal code. Please double check the address. If you are confident it’s correct, you may still proceed.'
        ),
        severity: HeaderMessageSeverity.Warning,
      };
    }

    return headerMessage;
  };

  const headerErrorMessage = getHeaderMessage();

  return (
    <div className={classes.addressContainerStyles}>
      {headerErrorMessage && <AddressDialogHeader message={headerErrorMessage} />}
      {getCountry()}
      {getAddressLine1()}
      {getAddressLine2()}
      {(isAddLineClicked || address.addressLine3) && getAddressLine3()}
      {addressConfig.fieldGroup.map((fieldType, index) => {
        if (fieldType === FieldType.City) {
          return getCity();
        }

        if (fieldType === FieldType.Region) {
          return getRegion({ region: addressConfig.region });
        }

        if (fieldType === FieldType.PostalCode) {
          return getPostalCode();
        }

        return <React.Fragment key={index} />;
      })}
    </div>
  );
};

export const AddressBody = withStyles(addressDialogStyles)(AddressBodyUnstyled);

export enum PrimaryButtonType {
  Save = 'Save',
  Next = 'Next',
  ProceedAnyway = 'ProceedAnyway',
}

export const AddressFooterButton = (
  primaryButtonType: PrimaryButtonType,
  onPrimaryButtonClick: () => void,
  onSecondaryButtonClick: () => void,
  isValid?: boolean
) => {
  const backButton = (
    <SecondaryButton
      dataAutomationId="addressDialogBack"
      key="Back"
      text={i18next.t('quote::Back')}
      onClick={onSecondaryButtonClick}
    />
  );

  const getTranslation = (buttonType: PrimaryButtonType) => {
    if (buttonType === PrimaryButtonType.Save) {
      return i18next.t(`quote::Save`);
    }

    if (buttonType === PrimaryButtonType.ProceedAnyway) {
      return i18next.t(`quote::Proceed Anyway`);
    }

    return i18next.t(`quote::Next`);
  };

  const onClick = () => {
    onPrimaryButtonClick();

    if (primaryButtonType === PrimaryButtonType.ProceedAnyway) {
      loggerService.log({
        name: 'Billing address updated with invalid address (Proceed anyway scenario)',
      });
    }
  };

  return [
    backButton,
    <PrimaryButton
      dataAutomationId={`addressDialog${primaryButtonType}`}
      disabled={!isValid}
      key={primaryButtonType}
      text={getTranslation(primaryButtonType)}
      onClick={onClick}
    />,
  ];
};
