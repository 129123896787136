import {
  CalloutCard,
  ChoiceGroup,
  LinkButton,
  SectionSeparator,
  TextboxStandard,
  TextWatermark,
} from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import { useDebounce } from 'components/utilities/debounce';
import { DirectionalHint } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { choiceGroupStyles, discountCardStyles } from './DiscountCard.styles';
import { DiscountCardCeilingSection, DiscountCardFutureSection } from './DiscountCardSections';
import { DiscountCardProps, DiscountChoiceGroupProps, DiscountSectionProps } from './types';

const DiscountChoiceGroup: React.FC<DiscountChoiceGroupProps> = (
  props: DiscountChoiceGroupProps
) => {
  return (
    <ChoiceGroup
      dataAutomationId="discountChoiceGroup"
      disabled={props.isReadOnly}
      options={props.discountTypeChoices}
      selectedKey={props.discountChoice}
      styles={choiceGroupStyles}
      onChange={props.onChangeDiscountType}
    />
  );
};

interface DiscountInputProps {
  value: string;
  onDiscountAmountChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  ariaLabel: string;
  errorMessage?: string;
  errorMessageClass: string;
  discountInputContainerClass: string;
  isReadOnly: boolean;
}

type Props = DiscountCardProps & WithStyles<typeof discountCardStyles>;

const DiscountInput: React.FC<DiscountInputProps> = (props: DiscountInputProps) => {
  return (
    <div className={props.discountInputContainerClass}>
      <TextboxStandard
        ariaLabel={props.ariaLabel}
        autoFocus
        dataAutomationId="discountTextbox"
        disabled={props.isReadOnly}
        errorMessage={props.errorMessage}
        errorMessageStyle={props.errorMessageClass}
        selectOnFocus
        //TODO: cameneks,kaderbez make it prefix too depending on the culture
        suffix="%"
        value={props.value}
        onChange={props.onDiscountAmountChange}
      />
    </div>
  );
};

const DiscountSection: React.FC<DiscountSectionProps> = (props: DiscountSectionProps) => {
  switch (props.currentDiscountType) {
    case 'Future': {
      return (
        <DiscountCardFutureSection
          disabled={props.isReadOnly}
          dispatches={props.futureSectionDispatches}
          options={props.futureSectionOptions}
        />
      );
    }
    case 'Ceiling': {
      return (
        <DiscountCardCeilingSection
          disabled={props.isReadOnly}
          dispatches={props.ceilingSectionDispatches}
          options={props.ceilingSectionOptions}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const inputDebounceMilliseconds = 400;

export const limitDecimalPoint = (value: string) => {
  const matches = /(.*?[0-9]*\.[0-9]{0,2})/.exec(value);
  if (matches && matches.length >= 1) {
    return matches[1];
  }
  return value;
};

const DiscountCardUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const {
    futureSectionDispatches,
    futureSectionOptions,
    ceilingSectionDispatches,
    ceilingSectionOptions,
    discountType,
    onChangeDiscountType,
    discountTypeChoices,
    directionalHint,
    discountAmount,
    showMixedValuesWaterMark,
  } = props;
  const applyButtonStrings = {
    ariaLabel: t('quote::Apply'),
    text: t('quote::Apply'),
  };
  const discount$ = useDebounce(inputDebounceMilliseconds);

  const onDiscountChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      const cleanedValue = limitDecimalPoint(value);
      discount$.next(() => props.onDiscountErrorChange(cleanedValue));
      props.onDiscountAmountChange(event, cleanedValue);
    }
  };
  const discountExist = !props.noDiscountExists;

  return (
    <CalloutCard
      applyButtonDisabled={props.applyButtonDisabled}
      applyButtonStrings={applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Discount Card')}
      dataAutomationId="discountCard"
      directionalHint={directionalHint || DirectionalHint.rightCenter}
      headerText={t('quote::Discount')}
      id={props.lineItemId}
      isBeakVisible
      isReadOnly={props.isReadOnly}
      maxHeight={550}
      minWidth={200}
      target={props.target}
      onApply={props.onDiscountApply}
      onDismiss={props.onDismiss}
    >
      <>
        {showMixedValuesWaterMark && (
          <div className={props.classes.mixedValues}>
            <TextWatermark>
              {t('quote::Updates will only be made to modified fields')}
            </TextWatermark>
          </div>
        )}
        <DiscountInput
          ariaLabel={t('quote::Modify discount')}
          discountInputContainerClass={
            discountAmount === '(mixed)'
              ? mergeClassNames([props.classes.discountInputContainer, props.classes.itallicStyle])
              : props.classes.discountInputContainer
          }
          errorMessage={props.discountError}
          errorMessageClass={props.classes.errorMessageStyle}
          isReadOnly={props.isReadOnly}
          value={discountAmount}
          onDiscountAmountChange={onDiscountChange}
        />
        <SectionSeparator />
        <DiscountChoiceGroup
          discountChoice={discountType}
          discountTypeChoices={discountTypeChoices}
          isReadOnly={props.isReadOnly}
          onChangeDiscountType={onChangeDiscountType}
        />
        <DiscountSection
          ceilingSectionDispatches={ceilingSectionDispatches}
          ceilingSectionOptions={ceilingSectionOptions}
          currentDiscountType={discountType}
          futureSectionDispatches={futureSectionDispatches}
          futureSectionOptions={futureSectionOptions}
          isReadOnly={props.isReadOnly}
        />
        {discountExist && !props.isReadOnly && (
          <div className={props.classes.removeDiscount}>
            <LinkButton
              dataAutomationId="dicountCardRemoveDiscount"
              displayText={t('quote::remove discount')}
              onClick={props.onRemoveDiscount}
            />
          </div>
        )}
      </>
    </CalloutCard>
  );
};

export const DiscountCard = withStyles(discountCardStyles)(DiscountCardUnstyled);
