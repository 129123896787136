import { TextTitle } from 'components/ions/Text';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const propertySheetLayoutStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    flexPageContent: {
      backgroundColor: palette.backgroundCommon,
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 20,
      paddingTop: 32,
      width: '100%',
    },
    flexPageMultipleContent: {
      backgroundColor: palette.backgroundCommon,
      display: 'flex',
      flexDirection: 'row',
      overflowY: 'auto',
      paddingLeft: 0,
      paddingTop: 0,
      width: '100%',
    },
    leftPane: {
      backgroundColor: palette.backgroundCommon,
      flexShrink: 0,
      flexBasis: 148,
      height: '100%',
      overflow: 'hidden',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    columnFlex: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: palette.backgroundCommon,
    },
    rowFlex: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
    propertySheetTitleHeader: {
      paddingRight: 8,
      paddingTop: 32,
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
    },
    content: {
      width: '100%',
      flex: 5,
      display: 'flex',
      flexDirection: 'row',
    },
    flexMainContent: {
      flexShrink: 0,
      maxWidth: 743,
    },
    flexRightEmptySpace: {
      flexShrink: 1,
    },
  };
};

export interface PropertySheetSectionProps {
  leftPaneTitle: string;
}

type Props = PropertySheetSectionProps & WithStyles<typeof propertySheetLayoutStyles>;

export const PropertySheetSectionUnstyled: React.FC<Props> = props => {
  const { classes } = props;

  return (
    <div className={classes.columnFlex}>
      <div className={classes.rowFlex}>
        <div className={classes.leftPane}>
          <TextTitle addClass={classes.propertySheetTitleHeader}>{props.leftPaneTitle}</TextTitle>
        </div>
        <div className={classes.flexPageContent}>
          <div className={classes.content}>
            <div className={classes.flexMainContent}>{props.children}</div>
            <div className={classes.flexRightEmptySpace} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PropertySheetSection = withStyles(propertySheetLayoutStyles)(
  PropertySheetSectionUnstyled
);
