import { ThemeProps } from 'styles';

export const primaryButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    primary: {
      color: 'white',
      fontWeight: theme.fonts.fontWeights.semiBold,
      '& .ms-Button-label': {
        fontWeight: theme.fonts.fontWeights.semiBold,
      },
      '&:hover, &:active': {
        color: 'white',
      },
      '&:disabled': {
        backgroundColor: colors.backgroundDivider,
        color: colors.textPrimaryDisabled,
        '& i': {
          color: colors.textPrimaryDisabled,
        },
      },
      '& i': {
        color: 'white',
      },
    },
    primaryIcon: {
      color: 'white',
      '&:hover, &:active': {
        color: 'white',
      },
      '&:disabled': {
        backgroundColor: colors.backgroundDivider,
        color: colors.textPrimaryDisabled,
        '& i': {
          color: colors.textPrimaryDisabled,
        },
      },
      '& i': {
        color: 'white',
      },
    },
  };
};
