import { Error } from 'features/app/types';
import { FavoriteProductRequest, ProductGroupActionRequest } from 'features/catalog';
import { User } from 'features/user/types';
import { IAccountInfo } from 'react-aad-msal';
import { LDSSReadPermission, UserPermissions } from 'services/ccf/types';
import { User as graphUser } from 'services/graph/types';
import { UserPreferences } from 'services/user-preferences/types';
import { createAsyncAction } from 'store/utils';
import { ThemeKey } from 'styles/theme';
import { createStandardAction } from 'typesafe-actions';

export const setUser = createStandardAction('@@user/SET')<User>();

export const aadLoginSuccess = createStandardAction('AAD_LOGIN_SUCCESS')<IAccountInfo>();
export const aadLogoutSuccess = createStandardAction('AAD_LOGOUT_SUCCESS')<string>();

export const loadUserPermissionsAsync = createAsyncAction(
  '@@user/permissions/LOAD_REQUEST',
  '@@user/permissions/LOAD_SUCCESS',
  '@@user/permissions/LOAD_FAILURE'
)<string, UserPermissions, Error>();

export const checkLDSSReadPermissionAsync = createAsyncAction(
  '@@user/ldsspermissions/LOAD_REQUEST',
  '@@user/ldsspermissions/LOAD_SUCCESS',
  '@@user/ldsspermissions/LOAD_FAILURE'
)<never, LDSSReadPermission, Error>();

export const loadMSContactAsync = createAsyncAction(
  '@@msContact/LOAD_REQUEST',
  '@@msContact/LOAD_SUCCESS',
  '@@msContact/LOAD_FAILURE'
)<string | undefined, graphUser[], Error>();

export const searchMSContactsAsync = createAsyncAction(
  '@@msContact/SEARCH_REQUEST',
  '@@msContact/SEARCH_SUCCESS',
  '@@msContact/SEARCH_FAILURE'
)<string | undefined, graphUser[], Error>();

export const clearSearchMsContacts = createStandardAction('@@msContact/SEARCH_CLEAR')<void>();

export const loadPhotoAsync = createAsyncAction(
  '@@photo/LOAD_REQUEST',
  '@@photo/LOAD_SUCCESS',
  '@@photo/LOAD_FAILURE'
)<string, string, Error>();

export const loadUserRoles = createAsyncAction(
  '@@user/roles/LOAD_REQUEST',
  '@@user/roles/LOAD_SUCCESS',
  '@@user/roles/LOAD_FAILURE'
)<string, { email: string; roles: string[] }, Error>();

// #region User Preference Actions

export const loadUserPreferencesAsync = createAsyncAction(
  '@@user/preferences/LOAD_REQUEST',
  '@@user/preferences/LOAD_SUCCESS',
  '@@user/preferences/LOAD_FAILURE'
)<string, UserPreferences, Error>();

export const updateUserPreferencesAsync = createAsyncAction(
  '@@user/preferences/UPDATE_REQUEST',
  '@@user/preferences/UPDATE_SUCCESS',
  '@@user/preferences/UPDATE_FAILURE'
)<UserPreferences, UserPreferences, Error>();

export const deleteUserPreferencesAsync = createAsyncAction(
  '@@user/preferences/DELETE_REQUEST',
  '@@user/preferences/DELETE_SUCCESS',
  '@@user/preferences/DELETE_FAILURE'
)<never, void, Error>();

export const setUserTheme = createStandardAction('@@user/preferences/UPDATE_THEME')<ThemeKey>();
export const infoDialogViewed = createStandardAction('@@user/preferences/INFO_DIALOG_VIEWED')<
  string
>();

export const addProductToFavorites = createAsyncAction(
  '@@user/ADD_FAVORITE_PRODUCT_REQUEST',
  '@@user/ADD_FAVORITE_PRODUCT_SUCCESS',
  '@@user/ADD_FAVORITE_PRODUCT_FAILURE'
)<FavoriteProductRequest, UserPreferences, Error>();

export const removeProductFromFavorites = createAsyncAction(
  '@@user/REMOVE_FAVORITE_PRODUCT_REQUEST',
  '@@user/REMOVE_FAVORITE_PRODUCT_SUCCESS',
  '@@user/REMOVE_FAVORITE_PRODUCT_FAILURE'
)<FavoriteProductRequest, UserPreferences, Error>();

export const renameProductGroup = createAsyncAction(
  '@@user/RENAME_PRODUCT_GROUP_REQUEST',
  '@@user/RENAME_PRODUCT_GROUP_SUCCESS',
  '@@user/RENAME_PRODUCT_GROUP_FAILURE'
)<ProductGroupActionRequest, UserPreferences, Error>();

export const deleteProductGroup = createAsyncAction(
  '@@user/DELETE_PRODUCT_GROUP_REQUEST',
  '@@user/DELETE_PRODUCT_GROUP_SUCCESS',
  '@@user/DELETE_PRODUCT_GROUP_FAILURE'
)<ProductGroupActionRequest, UserPreferences, Error>();

// #endregion
