import { DocumentCard } from 'office-ui-fabric-react';
import * as React from 'react';

export interface TileAtomProps {
  onClick?: (ev?: React.SyntheticEvent<HTMLElement>) => void;
  addClass?: string;
  id?: string;
  dataAutomationId?: string;
}

export const TileAtom: React.FC<TileAtomProps> = props => {
  return (
    <DocumentCard
      className={props.addClass}
      data-automation-id={props.dataAutomationId}
      id={props.id}
      onClick={props.onClick}
    >
      {props.children}
    </DocumentCard>
  );
};
