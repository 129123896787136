import { sharedStyles as dialogSharedStyles } from 'features/proposal/components/Dialogs/shared.styles';
import { IChoiceGroupOptionStyles, IChoiceGroupStyles } from 'office-ui-fabric-react';
import { sharedStyles, ThemeProps } from 'styles';

export const tenantStyles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  ...sharedStyles,
  errorMessage: {
    maxWidth: 374,
    paddingTop: 12,
    position: 'relative',
  },
  viralTenantError: {
    paddingTop: 12,
    position: 'relative',
  },
  secondaryText: {
    color: theme.palette.textTertiary,
  },
  info: {
    display: 'flex',
    marginTop: 8,
    width: 374,
  },
  infoIcon: {
    color: theme.palette.primary,
    paddingRight: 6,
    paddingTop: 2,
  },
  warning: {
    display: 'flex',
    marginTop: 'auto',
    marginBottom: 24,
  },
  warningIcon: {
    color: theme.palette.warningText,
    paddingRight: 8,
    paddingTop: 2,
  },
  warningText: {
    display: 'inline-flex',
  },
  paragraph: {
    paddingBottom: 20,
  },
  paragraph2: {
    paddingBottom: 8,
  },
  choiceGroupNew: {
    paddingTop: 8,
  },
  existingTenantHeader: {
    paddingTop: 24,
    marginLeft: 26,
  },
  existingTenantHeaderSpacer: {
    paddingLeft: 58,
  },
  existingTenantLabel: {
    marginLeft: 26,
    display: 'flex',
  },
  existingTenantSpacerLabel: {
    paddingLeft: 24,
  },
  existingTenantIDLabel: {
    minWidth: 260,
  },
  pickerVerification: {
    marginTop: 5,
    marginLeft: 27,
    marginBottom: 13,
  },
  existingListHeight: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  verifiedListContainer: {
    paddingTop: 8,
  },
  verifiedHeaderSpacer: {
    paddingLeft: '200px',
  },
  verifiedListSpacer: {
    paddingLeft: '36px',
  },
  listRow: {
    display: 'flex',
  },
});

export const choiceGroupStyles: IChoiceGroupStyles = {
  flexContainer: {},
  root: {
    marginBottom: 20,
    selectors: {
      '.ms-ChoiceField': {
        paddingRight: 12,
      },
    },
  },
};

export const otherOptionStyles: IChoiceGroupOptionStyles = {
  field: {
    marginTop: 3,
  },
};
