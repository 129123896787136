import {
  IPersonaProps,
  IPersonaSharedProps,
  IPersonaStyles as IPersonaBaseStyles,
  IRenderFunction,
  Persona,
  PersonaInitialsColor,
  PersonaPresence as PersonaPresenceBase,
  PersonaSize,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { personaStyles } from './Persona.styles';

/**
 * Exposing properties from the Office Fabric ComboBox component
 * @see {@link https://developer.microsoft.com/en-us/fabric#/components/ComboBox} for more information
 */
export interface PersonaAtomProps extends IPersonaProps {
  hidePersonaDetails?: boolean;
  id?: string;
  initialsColor?: PersonaInitialsColor | string;
  name?: string;
  addClass?: string;
  status?: PersonaPresence;
  size?: PersonaSize;
  onRenderPrimaryText?: (props?: IPersonaProps) => JSX.Element;
  imageUrl?: string;
  imageAlt?: string;
  showInitialsUntilImageLoads?: boolean;
  onRenderInitials?: IRenderFunction<IPersonaSharedProps>;
  styles?: Partial<IPersonaBaseStyles>;
  dataAutomationId?: string;
}

type Props = PersonaAtomProps & WithStyles<typeof personaStyles>;

export enum PersonaPresence {
  online = 'online',
  away = 'away',
  busy = 'busy',
  dnd = 'dnd',
  blocked = 'blocked',
  unknown = 'unknown',
  callForwarding = 'callForwarding',
  availableOnMobile = 'availableOnMobile',
  offline = 'offline',
}

const getPersonaStyleClassName = (props: Props): Partial<IPersonaBaseStyles> => {
  const { classes } = props;
  const index = props.status ? (props.status as PersonaPresence) : 'unknown';
  return {
    root: [classes[index]],
    ...props.styles,
  };
};

const PersonaUnstyled: React.FC<Props> = (props: Props) => {
  const { id, classes, name, hidePersonaDetails, size, initialsColor, status } = props;
  return (
    <Persona
      className={`${classes.coin} ${props.addClass}`}
      data-automation-id={props.dataAutomationId}
      hidePersonaDetails={hidePersonaDetails}
      id={id}
      imageAlt={props.imageAlt}
      imageUrl={props.imageUrl}
      initialsColor={initialsColor}
      presence={status && PersonaPresenceBase.online}
      showInitialsUntilImageLoads={props.showInitialsUntilImageLoads}
      size={size}
      styles={getPersonaStyleClassName(props)}
      text={name}
      onRenderInitials={props.onRenderInitials}
    />
  );
};

export const PersonaAtom = withStyles(personaStyles)(PersonaUnstyled) as React.FC<PersonaAtomProps>;
