import { ThemeProps } from 'styles';

import { AnimationStyles } from '@uifabric/styling';

export const configCardBodyStyles = (theme: ThemeProps) => {
  const { warningText } = theme.palette;
  return {
    labelDisabled: {
      color: theme.palette.textDisabled,
    },
    messageArea: {
      marginTop: 6,
      marginBottom: 14,
      maxWidth: 450,
    },

    billingPlansDropdown: {
      width: 232,
      marginTop: 16,
    },

    additionalOptionsArea: {
      marginTop: 14,
    },
    termsContainerFlexbox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    termsDropdown: {
      width: 300,
    },
    reccuringBillingCheckboxContainer: {
      marginTop: 24,
    },
    startDateField: {
      marginTop: 24,
      marginBottom: 12,
    },
    dropdown: {
      width: 232,
    },

    priceSeparator: {
      marginTop: 24,
    },
    infoButton: {
      verticalAlign: 'bottom',
    },

    priceContainer: {
      marginTop: 12,
      display: 'flex',
      justifyContent: 'flex-end',
    },

    labelContainer: {
      display: 'flex',
    },

    priceLabel: {
      paddingRight: 7,
    },

    price: {
      paddingRight: 4,
    },

    currency: {
      paddingTop: 7,
    },

    watermarkContainer: {
      display: 'flex',
      marginTop: 20,
      height: 56,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '0px 26px',
    },
    detailsListContainer: {
      overflowY: 'auto',
      maxHeight: 400,
    },
    futureStartDateLabel: {
      display: 'block',
      paddingBottom: 5,
      paddingTop: 5,
    },
    infoButtonContainer: { width: 16, height: 16, display: 'inline-block' },
    startDateNotification: {
      ...AnimationStyles.slideDownIn20,
      animationDelay: '100ms',
      marginTop: 6,
      display: 'flex',
    },
    warningIcon: { color: warningText, marginTop: 2 },
    warningText: {
      marginLeft: 6,
      color: theme.palette.textTertiary,
    },
    detailsList: {
      overflowX: 'visible',
      '& .ms-DetailsHeader': {
        paddingBottom: 8,
      },
      '& .ms-DetailsHeader-cell': {
        display: 'inline-flex',
      },
      '& .ms-DetailsHeader-cellTitle': {
        paddingLeft: 0,
      },
      '& .ms-DetailsHeader-cell span': {
        display: 'flex',
        alignItems: 'flex-end',
      },
      '& .ms-FocusZone.ms-DetailsRow': {
        borderBottom: 0,
      },
    },
    termsHeading: {
      verticalAlign: 10,
    },
  };
};
