import { ThemeProps } from 'styles';

export const pivotItemAtomStyles = (theme: ThemeProps) => {
  const { palette, fonts } = theme;
  return {
    button: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      maxHeight: 19,
      padding: '0 4px',
      '&:focus': {
        outline: `${palette.activeLink} solid 1px`,
      },
    },
    buttonContainer: {
      paddingRight: 24,
    },
    required: {
      color: palette.dangerText,
    },
    selected: {
      fontWeight: fonts.fontWeights.semiBold,
    },
    text: {
      fontSize: fonts.fontSizes.medium,
    },
    subtitle: {
      color: palette.textTertiary,
      fontSize: fonts.fontSizes.small,
      paddingLeft: 4,
      '& i': {
        marginLeft: 4,
        verticalAlign: 'sub',
      },
    },
    subtitleText: { marginLeft: 4 },
  };
};
