import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  TextBody,
  TextboxStandard,
  TextTitle,
  XLargeIcon,
} from 'components';
import { meplaHistory } from 'createHistory';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import { getCurrencies, getLanguages, Market } from 'features/proposal/supported-markets';
import { isProposalNameInvalid } from 'features/proposal/utils';
import * as userSelectors from 'features/user/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { routes } from 'routes';
import * as LocalStorage from 'services/local-storage-service';
import { proposalNameLengthLimit } from 'services/proposal/config';
import { RequestProposal } from 'services/proposal/types';
import { AsyncState, RootState } from 'store/types';
import { DialogContext } from 'styles';

import { DialogLayout } from '../Shared';
import { newProposalDialogStyles } from './NewDialog.styles';

interface NewProposalDialogProps {
  id?: string;
}

const mapStateToProps = (state: RootState) => ({
  user: userSelectors.getUser(state).email || '',
  dialogState: selectors.getViews(state).newProposal,
  createProposalError: selectors.createProposalError(state),
  quoteExpirationDate: selectors.getProposalNextExpiryDate(state),
  getActiveProposalId: selectors.getActiveProposalId(state),
});

const dispatchProps = {
  createProposal: (request: RequestProposal) => actions.createProposalAsync.request(request),
  resetDialog: () => actions.resetNewProposalDialog(),
};

type Props = NewProposalDialogProps &
  WithStyles<typeof newProposalDialogStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const NewProposalDialogUnstyled: React.FC<Props> = ({
  classes,
  id,
  user,
  dialogState,
  createProposal,
  resetDialog,
  quoteExpirationDate,
  getActiveProposalId,
}) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [textValue, setTextValue] = React.useState<{ value: string }>({ value: '' });

  const context = React.useContext(DialogContext);
  const { t } = useTranslation('quote');

  React.useEffect(() => {
    if (dialogState === AsyncState.Success && getActiveProposalId) {
      meplaHistory.push(routes.quote.forId(getActiveProposalId));
      resetDialog();
      context.closeDialog();
    }
  }, [getActiveProposalId, dialogState, resetDialog, context]);

  const isValid = () => {
    return !!textValue.value && !errorMessage;
  };

  const closeDialog = () => {
    resetDialog();
    context.closeDialog();
  };

  const handleOnChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setErrorMessage('');

    if (value) {
      const text =
        value.length <= proposalNameLengthLimit
          ? value
          : value.substring(0, proposalNameLengthLimit);

      if (isProposalNameInvalid(text))
        setErrorMessage(
          t('quote::The following characters are not allowed: ! @ # % ^ [ ] . / < >')
        );
      setTextValue({ value: text });
    } else {
      setTextValue({ value: '' });
    }
  };

  const userMarket = LocalStorage.get<Market>(LocalStorage.localMarketKey) || 'US';
  const userCurrency = getCurrencies(userMarket)[0];
  const userLanguage = getLanguages(userMarket)[0];

  const handleCreateProposal = () => {
    if (!isValid()) return;

    const request: RequestProposal = {
      header: {
        name: textValue.value,
        msContact: user,
        transactionType: 'UserPurchase',
        isApprovalPreCheckRequired: true,
        expirationDate: quoteExpirationDate,
        pricingContext: {
          billingCurrency: userCurrency,
          catalogId: '4',
          languages: userLanguage,
          market: userMarket,
        },
        extendedProperties: {
          isMsxPrimaryQuote: false,
          userPreferences: {
            agreementType: 'modern',
            nationalCloud: 'Global',
            recipientId: null,
          },
        },
        //TODO: cameneks quotev11 that is probably not always null
        negotiationReason: null,
      },
      lineItems: [],
    };
    createProposal(request);
  };

  const createLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        dataAutomationId="newQuote"
        dialogClose={closeDialog}
        headerClass={classes.customDialogHeader}
      >
        <TextTitle>{t('quote::New quote')}</TextTitle>
      </DialogHeader>
    ),
    body: (
      <div className={classes.customDialogBody}>
        <TextboxStandard
          autoFocus
          dataAutomationId="newProposalName"
          errorMessage={errorMessage}
          id="newProposalName"
          label={t('quote::Name')}
          placeholder={t('quote::Enter a name for the quote to proceed')}
          required
          value={textValue.value}
          onChange={handleOnChange}
        />
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <PrimaryButton
          dataAutomationId="createQuoteButton"
          disabled={!isValid()}
          text={t('quote::Create Quote')}
          onClick={handleCreateProposal}
        />
      </div>
    ),
  };

  const processingLayout: DialogLayout = {
    body: (
      <div className={classes.customDialogContainer}>
        <div className={`${classes.iconContainer} ${classes.customDialogProcessingIcon}`}>
          <XLargeIcon addClass={classes.customDialogIcon} iconName="Processing" />
        </div>
        <div>
          <TextBody addClass={classes.text}>{t('quote::Creating...')}</TextBody>
        </div>
      </div>
    ),
  };

  const errorLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        closeButtonContainerClass={classes.closeButtonNoTitle}
        dataAutomationId="failedCreateQuote"
        dialogClose={closeDialog}
      />
    ),
    body: (
      <div className={classes.customDialogContainer}>
        <div className={`${classes.iconContainer} ${classes.customDialogFailIcon}`}>
          <XLargeIcon addClass={classes.customDialogIcon} iconName="Cancel" />
        </div>
        <div>
          <TextBody addClass={classes.text}>
            {t('quote::Failed to create quote. Please try again.')}
          </TextBody>
        </div>
      </div>
    ),
    footer: (
      <div className={classes.errorFooter}>
        <PrimaryButton
          dataAutomationId="tryAgainButton"
          disabled={false}
          text={t('quote::Try again')}
          onClick={handleCreateProposal}
        />
      </div>
    ),
  };

  const getLayout = () => {
    switch (dialogState) {
      case AsyncState.Request:
        return processingLayout;
      case AsyncState.Failure:
        return errorLayout;
      default:
        return createLayout;
    }
  };

  const currentLayout = getLayout();

  return (
    <div>
      <CustomDialogBox
        bodySlot={currentLayout.body}
        enterKeyCallback={handleCreateProposal}
        footerSlot={currentLayout.footer}
        headerSlot={currentLayout.header}
        height={224}
        id={id}
        width={428}
      />
    </div>
  );
};

export const NewProposalDialogUnconnected = withStyles(newProposalDialogStyles)(
  NewProposalDialogUnstyled
);

export const NewProposalDialog = connect(
  mapStateToProps,
  dispatchProps
)(NewProposalDialogUnconnected);
