import { CalloutCard, InfoButton, LinkExternal, TextBody, TextboxStandard } from 'components';
import { closeConfigCard, updateProposalAsync } from 'features/proposal/actions';
import {
  getActiveProposal,
  getLineItemWithSwitchedDFD,
  isProposalReadOnly,
} from 'features/proposal/selectors';
import { createProposalUpdateRequest, validGuid } from 'features/proposal/utils';
import { DirectionalHint, Label } from 'office-ui-fabric-react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import loggerService from 'services/logger-service';
import { LineItem, ProposalUpdateRequest } from 'services/proposal/types';
import { RootState } from 'store/types';

import { simpleTermsCardStyles } from './SimpleTermsCard.styles';
import { getTermIdContent } from './utils';

export interface SimpleTermsCardProps {
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  name: string;
  isECIF?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: SimpleTermsCardProps) => {
  return {
    lineItem: getLineItemWithSwitchedDFD(state, ownProps.lineItemId),
    proposal: getActiveProposal(state),
    quoteReadOnly: isProposalReadOnly(state),
  };
};

const dispatchProps = {
  onUpdateProposal: (proposalUpdateRequest: ProposalUpdateRequest) => {
    return updateProposalAsync.request(proposalUpdateRequest);
  },
  onDismiss: closeConfigCard,
};

type Props = SimpleTermsCardProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  WithStyles<typeof simpleTermsCardStyles>;

export const SimpleTermsCardUnstyled: React.FC<Props> = (props: Props) => {
  if (!props.lineItem) {
    throw new Error('Simple Terms Card has no lineItem');
  }
  //TODO: kaderbez,cameneks, To change when catalog updates the schema
  const termIdContent = getTermIdContent(props.lineItem);
  const [termId, setTermId] = React.useState(termIdContent || '');

  const { t } = useTranslation();

  const onChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newTermId?: string
  ) => {
    if (newTermId !== undefined) {
      setTermId(newTermId);
    }
  };

  const termIdError = termId && !validGuid.test(termId);
  const termIdErrorMessage = termIdError ? t('quote::Term ID is in an invalid format') : undefined;

  const applyButtonDisabled = !!termIdError || !termId.trim();
  const onApply = () => {
    props.onDismiss();
    if (props.lineItem) {
      const newLineItem: LineItem = {
        ...props.lineItem,
        isReadyForPricing: true,
        supplementalTermReferenceData: `TermId:${termId}`,
      };
      const proposalUpdateRequest = createProposalUpdateRequest(newLineItem, props.proposal);
      props.onUpdateProposal(proposalUpdateRequest);
    }
  };

  const termIdInputLabel = () => (
    <div className={props.classes.termIdLabel}>
      <Label className={props.classes.label} htmlFor="financeTermsTermId" required>
        {t('quote::Term ID')}
      </Label>
      {props.isECIF && (
        <InfoButton
          ariaLabel={t('quote::Open term information')}
          calloutProps={{
            closeButtonAriaLabel: t('Close'),
            headline: t('quote::Term ID'),
            maxWidth: 343,
          }}
          id="term-id-info-button"
        >
          <TextBody>
            {t('quote::A Term ID is used to associate a specific amendment to this quote.')}
          </TextBody>
          <TextBody>
            <Trans ns="quote">
              To request ECIF for your customer, please visit{' '}
              <LinkExternal
                addClass={props.classes.infoLink}
                displayText="aka.ms/OneAsk"
                href="https://aka.ms/OneAsk"
                size="medium"
                onClick={() =>
                  loggerService.log({
                    name: 'SimpleTermsCard - OneAsk link is clicked',
                  })
                }
              />
              .
            </Trans>
          </TextBody>
          <TextBody>
            {t(
              `quote::Upon approval you will receive a Term ID. To check that ECIF has been correctly configured, select 'View agreement' from within Quote Center.`
            )}
          </TextBody>
        </InfoButton>
      )}
    </div>
  );

  return (
    <CalloutCard
      applyButtonDisabled={applyButtonDisabled}
      applyButtonStrings={{ ariaLabel: t('quote::Apply'), text: t('quote::Apply') }}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      dataAutomationId="simpleTerms"
      directionalHint={DirectionalHint.rightCenter}
      headerText={props.name}
      id={props.lineItemId}
      isBeakVisible={true}
      isReadOnly={props.quoteReadOnly}
      maxHeight={750}
      maxWidth={400}
      minWidth={325}
      target={props.target}
      onApply={onApply}
      onDismiss={props.onDismiss}
    >
      <TextboxStandard
        ariaLabel={t('quote::Term ID')}
        dataAutomationId="simpleTermsCardTermId"
        errorMessage={termIdErrorMessage}
        readOnly={props.quoteReadOnly}
        value={termId}
        onChange={onChange}
        onRenderLabel={termIdInputLabel}
      />
      {props.isECIF && (
        <LinkExternal
          addClass={props.classes.link}
          dataAutomationId="learnAboutECIFLink"
          displayText={t('quote::Learn more about ECIF')}
          href={
            'https://msit.microsoftstream.com/embed/video/6daa0770-1e29-48e7-b918-03f3ab772702?autoplay=false&amp;showinfo=true'
          }
        />
      )}
    </CalloutCard>
  );
};

export const SimpleTermsCard = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(simpleTermsCardStyles)(SimpleTermsCardUnstyled));
