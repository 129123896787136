import { TextboxAtom, TextboxAtomProps } from 'components/atoms';
import * as React from 'react';
import { ThemeProps } from 'styles';

export const TextBoxSyles = (theme: ThemeProps) => {
  return {
    waterMark: {
      '& .ms-TextField-field::-webkit-input-placeholder': {
        fontStyle: theme.fonts.fontStyles.italic,
      },
      '& .ms-TextField-field::placeholder': {
        fontStyle: theme.fonts.fontStyles.italic,
      },
    },
  };
};

export const TextboxStandard: React.FC<TextboxAtomProps> = props => {
  const isReadOnly = !!(props.disabled && props.value) || props.readOnly;

  const textfieldProps = {
    ...props,
    disabled: isReadOnly ? false : props.disabled,
    readOnly: isReadOnly,
  };

  return <TextboxAtom {...textfieldProps} />;
};
