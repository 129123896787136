import { IconButton, MediumIcon, TextBodySmall } from 'components/ions';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { animated, useSpring } from 'react-spring';
import { sharedStyles, ThemeProps } from 'styles';

const TIMEOUT = 2000;
export interface CopyButtonProps {
  ariaLabel: string;
  textWidth: number;
  text: string;
  copiedText?: string;
}

const styles = (theme: ThemeProps) => {
  return {
    button: {
      height: 'fit-content',
      minWidth: 0,
      padding: `2px 0 0 0`,
      width: 'fit-content',
      '&:active, &:hover:active, &:hover, &:focus, &:visited': {
        backgroundColor: 'transparent',
      },
      '& i.ms-Button-icon': {
        ...sharedStyles(theme).link,
        fontSize: theme.fonts.fontSizes.small,
      },
      alignItems: 'flex-start',
      '@supports (-ms-ime-align:auto)': {
        position: 'relative',
        bottom: 6,
      },
    },
    input: {
      position: 'relative',

      background: 'none',
      border: 'none',
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      fontSize: theme.fonts.fontSizes.small,
      color: theme.palette.textTertiary,

      width: (props: CopyButtonProps) => props.textWidth,
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    container: {
      height: 24,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    animation: {
      color: theme.palette.approveText,
      height: 30,
      position: 'relative',
      alignItems: 'center',
      display: 'flex',
      '& i': {
        color: theme.palette.approveText,
        marginRight: 5,
      },
    },
  };
};

type Props = CopyButtonProps & WithStyles<typeof styles>;

const CopyButtonUnstyled: React.FC<Props> = props => {
  const { classes, copiedText } = props;

  const inputRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);
  const [showCopied, setCopied] = React.useState(false);
  const spring = useSpring({ opacity: showCopied ? 1 : 0, width: showCopied ? 1 : 0 });

  let timer: NodeJS.Timeout;

  const handleCopyClick = () => {
    clearTimeout(timer);
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
    setCopied(!showCopied);
    timer = setTimeout(() => {
      setCopied(false);
    }, TIMEOUT);
  };

  return (
    <div className={classes.container}>
      <input className={classes.input} readOnly ref={inputRef} type="text" value={props.text} />
      <IconButton
        addClass={classes.button}
        ariaLabel={props.ariaLabel}
        iconName="Copy"
        onClick={handleCopyClick}
      />
      <animated.div className={classes.animation} style={{ ...spring }}>
        <MediumIcon iconName="Checkmark" />
        <TextBodySmall addClass={classes.animation}>{copiedText}</TextBodySmall>
      </animated.div>
    </div>
  );
};

export const CopyButton = withStyles(styles)(CopyButtonUnstyled) as React.FC<CopyButtonProps>;
