import {
  CalloutHeader,
  LinkExternal,
  MenuItemButton,
  SectionSeparator,
  TextBody,
} from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { routes } from 'routes';
import loggerService from 'services/logger-service';

import { quoteCenterProdUrl } from '../config/configurations';
import { navBarStyles } from './NavBar.styles';

export const getDemoModeHelpArticleUrl = () =>
  encodeURI(
    `${routes.content.articlePopout}?article={"name":"Demo Mode","filename":"demo mode.md","updatedDate":"Aug 19, 2020"}`
  );

interface DemoModeButtonProps {
  isDemoModeEnabled: boolean;
}

type Props = DemoModeButtonProps & WithStyles<typeof navBarStyles>;

export const DemoModeButtonUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();

  const demoModeMenu = (
    <div className={props.classes.demoMenuContainer}>
      <TextBody>
        {t(
          'The demo mode is available to provide you with a safe environment to experience the full journey of a proposal from creation to completion.'
        )}
      </TextBody>
      <LinkExternal
        addClass={props.classes.demoLink}
        displayText={t('Learn more')}
        href={getDemoModeHelpArticleUrl()}
        onClick={() => {
          loggerService.log({ name: 'Demo mode - Learn more link clicked' });
        }}
      ></LinkExternal>
      <SectionSeparator addClass={props.classes.demoSeparator} />
      <MenuItemButton
        addClass={props.classes.leaveDemoButton}
        iconName="TestBeakerSolid"
        text={t('Leave demo mode')}
        onClick={() => {
          loggerService.log({ name: 'Demo mode menu - Leaving demo mode' });
          window.location.replace(quoteCenterProdUrl);
        }}
      ></MenuItemButton>
    </div>
  );
  return (
    <CalloutHeader
      ariaLabel={t('Demo mode')}
      buttonCustomStyle={props.classes.demoButton}
      calloutCustomStyle={props.classes.demoCallout}
      dataAutomationId="demoModeCalloutHeader"
      iconName="TestBeaker"
      isDemoMode={props.isDemoModeEnabled}
      key="demo-mode-callout-button"
      primaryColor
      text={t('Demo mode')}
      title={t('Demo mode')}
    >
      {demoModeMenu}
    </CalloutHeader>
  );
};

export const DemoModeButton = withStyles(navBarStyles)(DemoModeButtonUnstyled);
