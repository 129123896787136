import { CalloutAtom } from 'components/atoms/Callouts/CalloutAtom';
import {
  CloseIconButton,
  PrimaryButton,
  TextBody,
  TextTitleSecondary,
  Spinner,
  SpinnerSize,
} from 'components/ions';
import {
  calloutCardStyles,
  calloutFabricStyles,
} from 'components/ions/Callouts/CalloutCard/CalloutCard.styles';
import { DirectionalHint, Layer } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

export interface CalloutCardProps {
  applyButtonDisabled?: boolean;
  directionalHint: DirectionalHint;
  hasError?: boolean;
  id: string;
  isBeakVisible: boolean;
  hidden?: boolean;
  minWidth?: number;
  otherFooterButtons?: React.ReactNode;
  maxWidth?: number;
  maxHeight?: number;
  headerText: string;
  headerSubText?: React.ReactNode;
  children?: React.ReactNode;
  target: React.RefObject<HTMLSpanElement>;
  applyButtonStrings: { text: string; ariaLabel?: string };
  applyLoading?: boolean;
  applyError?: boolean;
  errorMessage?: JSX.Element;
  closeButtonAriaLabel: string;
  isReadOnly: boolean;
  onDismiss: (id: string) => void;
  onApply: () => void;
  dataAutomationId?: string;
}

const renderCloseButton = (
  closeButtonAriaLabel: string,
  onDismiss: () => void,
  classes: { closeButton?: string },
  dataAutomationId?: string
) => {
  const closeIconProps = {
    ariaLabel: closeButtonAriaLabel,
    onClick: onDismiss,
    dataAutomationId: `${dataAutomationId}CloseButton`,
  };
  return (
    <div className={classes.closeButton}>
      <CloseIconButton {...closeIconProps} />
    </div>
  );
};

const renderFooter = (
  applyButtonStrings: { text: string; ariaLabel?: string },
  onApply: () => void,
  classes: {
    footerStyles?: string;
    buttonBarStyles?: string;
    buttonSpacerStyles?: string;
    spinnerStyles?: string;
  },
  otherButtons?: React.ReactNode,
  applyButtonDisabled?: boolean,
  dataAutomationId?: string,
  applyLoading?: boolean
) => {
  const applyButtonProps = {
    text: applyButtonStrings.text,
    disabled: applyButtonDisabled,
    ariaLabel: applyButtonStrings.ariaLabel || applyButtonStrings.text,
    onClick: onApply,
    dataAutomationId: `apply-config-button`,
  };
  const spinner = (
    <Spinner addClass={classes.spinnerStyles} id="apply-button-spinner" size={SpinnerSize.medium} />
  );
  return (
    <div className={classes.footerStyles}>
      <div className={classes.buttonBarStyles}>
        {otherButtons}
        {applyLoading ? spinner : <PrimaryButton {...applyButtonProps} />}
        <span className={classes.buttonSpacerStyles} />
      </div>
    </div>
  );
};

const renderHeader = (
  headerText: string,
  closeButtonAriaLabel: string,
  onDismiss: () => void,
  classes: {
    headerFlexContainer?: string;
    headerStyles?: string;
    closeButton?: string;
    subHeaderStyles?: string;
  },
  dataAutomationId?: string,
  headerSubText?: React.ReactNode
) => {
  let displayHeaderSubText;
  if (headerSubText) {
    displayHeaderSubText = (
      <div className={classes.subHeaderStyles}>
        <TextBody>{headerSubText}</TextBody>
      </div>
    );
  }
  return (
    <div>
      <div className={classes.headerFlexContainer}>
        <div className={classes.headerStyles}>
          <TextTitleSecondary>{headerText}</TextTitleSecondary>
        </div>
        <div>
          {renderCloseButton(
            closeButtonAriaLabel,
            onDismiss,
            {
              closeButton: classes.closeButton,
            },
            dataAutomationId
          )}
        </div>
      </div>
      {displayHeaderSubText}
    </div>
  );
};

type Props = CalloutCardProps & WithStyles<typeof calloutCardStyles>;

const CalloutCardUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, otherFooterButtons } = props;
  const onDismiss = () => {
    props.onDismiss && props.onDismiss(props.id);
  };
  const onApply = () => {
    props.onApply && props.onApply();
  };
  const header = renderHeader(
    props.headerText,
    props.closeButtonAriaLabel,
    onDismiss,
    {
      closeButton: classes.closeButtonStyles,
      headerFlexContainer: classes.headerFlexContainer,
      headerStyles: classes.headerStyles,
      subHeaderStyles: classes.subHeaderStyles,
    },
    props.dataAutomationId,
    props.headerSubText
  );
  const footer = props.hasError
    ? null
    : renderFooter(
        props.applyButtonStrings,
        onApply,
        {
          buttonBarStyles: classes.buttonBarStyles,
          footerStyles: classes.footerStyles,
          buttonSpacerStyles: classes.buttonSpacerStyles,
          spinnerStyles: classes.spinner,
        },
        otherFooterButtons,
        props.applyButtonDisabled || props.isReadOnly,
        props.dataAutomationId,
        props.applyLoading
      );
  const styles = props.minWidth !== undefined ? calloutFabricStyles(props.minWidth) : undefined;
  const callout = (
    <CalloutAtom
      calloutMaxHeight={props.maxHeight}
      calloutMaxWidth={props.maxWidth}
      directionalHint={props.directionalHint}
      doNotLayer={true}
      hidden={props.hidden}
      id={props.id}
      isBeakVisible={props.isBeakVisible}
      preventDismissOnLostFocus={true}
      styles={styles}
      target={props.target.current}
      trapFocus={true}
      onDismiss={onDismiss}
    >
      <div>
        <div className={props.classes.calloutPaddingStyles}>
          {header}
          <div>{props.children}</div>
        </div>
        {props.applyError && props.errorMessage}
        {footer}
      </div>
    </CalloutAtom>
  );
  const layeredCallout = (
    <Layer>
      <div className={props.classes.layer}></div>
      {callout}
    </Layer>
  );
  return props.hidden ? callout : layeredCallout;
};

export const CalloutCard = withStyles(calloutCardStyles)(CalloutCardUnstyled) as React.FC<
  CalloutCardProps
>;
