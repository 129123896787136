import { ThemeProps } from 'styles/theme';

export const systemErrorStyles = (theme: ThemeProps) => {
  return {
    iconContainer: {
      paddingRight: 8,
      verticalAlign: 'top',
      paddingTop: 12,
    },
    icon: {
      color: theme.palette.dangerText,
    },
    container: {
      maxWidth: 450,
      backgroundColor: theme.palette.dangerHighlight,
      padding: 12,
    },
    mainMessage: {
      verticalAlign: 'top',
    },
    column: {
      display: 'flex',
    },
  };
};
