import gql from 'graphql-tag';

export const GetProductWithTerms = gql`
  query GetProduct($id: String!, $catalogContext: CatalogContextInput!, $quoteId: String!) {
    getProduct(id: $id, catalogContext: $catalogContext) {
      skus {
        skuId
        availabilities {
          isAutoRenewable
          actions
          availabilityId
          isTrial
          terms {
            termId
            price(context: { quote: { quoteId: $quoteId } }) {
              currency
              amount
            }
            description
          }
        }
        title
      }
    }
  }
`;
