import { H3Atom, TextSharedProps } from 'components/atoms/Text';
import * as React from 'react';

export const TextSectionHeading: React.FC<TextSharedProps> = props => {
  return (
    <H3Atom addClass={props.addClass} id={props.id}>
      {props.children}
    </H3Atom>
  );
};
