import { TeachingBubble, TextBody } from 'components';
import { DirectionalHint, Target } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { SeatsCardStyles } from './SeatsCard.styles';

export interface SeatsCardInfoCalloutProps {
  target: Target;
  onDismiss: () => void;
  message: string;
  headline: string;
}

type Props = SeatsCardInfoCalloutProps & WithStyles<typeof SeatsCardStyles>;

export const SeatsCardInfoCalloutUnstyled: React.FC<Props> = props => {
  const { classes } = props;

  return (
    <TeachingBubble
      directionalHint={DirectionalHint.bottomLeftEdge}
      headline={props.headline}
      target={props.target}
      onDismiss={props.onDismiss}
      maxWidth={250}
      closeButtonAriaLabel="Close"
    >
      <TextBody addClass={classes.textColor}>{props.message}</TextBody>
    </TeachingBubble>
  );
};

export const SeatsCardInfoCallout = withStyles(SeatsCardStyles)(SeatsCardInfoCalloutUnstyled);
