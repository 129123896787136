import { ActionButton } from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

interface ChevronDirection {
  /**
   * @default 'ChevronRight'
   */
  closed?: 'ChevronUp' | 'ChevronDown' | 'ChevronLeft' | 'ChevronRight';
  /**
   * @default 'ChevronDown'
   */
  opened?: 'ChevronUp' | 'ChevronDown' | 'ChevronLeft' | 'ChevronRight';
}

interface CollapsibleSectionCustomStyles {
  /**
   * Classname for the element wrapping the button and the content
   */
  root?: string;
  /**
   * Classname for the button element in the DOM
   */
  buttonRoot?: string;
  /**
   * Classname for the icon element in the DOM
   */
  iconRoot?: string;
}

export interface CollapsibleSectionProps {
  /**
   * Button label
   */
  label: string;
  /**
   * Set the initial state of the section
   */
  openedByDefault?: boolean;
  /**
   * Defines the chevron icon when the section is collapsed or expanded
   */
  chevronDirection?: ChevronDirection;
  /**
   * Custom classnames for individual elements within the section DOM.
   */
  styles?: CollapsibleSectionCustomStyles;
}

const styles = (theme: ThemeProps) => {
  return {
    buttonRoot: { '& *': { fontSize: theme.fonts.fontSizes.small } },
    buttonLabel: { color: theme.palette.text },
    root: { display: 'flex', flexDirection: 'column' },
  };
};

type Props = CollapsibleSectionProps & WithStyles<typeof styles>;

const CollapsibleSectionUnstyled: React.FC<Props> = props => {
  const { classes, styles } = props;
  const [isSectionOpen, setIsSectionOpen] = React.useState<boolean>(!!props.openedByDefault);

  const onClick = () => {
    setIsSectionOpen(!isSectionOpen);
  };

  const chevronClose = oc(props).chevronDirection.closed() || 'ChevronRight';
  const chevronOpen = oc(props).chevronDirection.opened() || 'ChevronDown';

  return (
    <div className={mergeClassNames([classes.root, oc(styles).root()])}>
      <ActionButton
        iconProps={{
          iconName: isSectionOpen ? chevronOpen : chevronClose,
          styles: { root: oc(styles).iconRoot() },
        }}
        styles={{
          root: mergeClassNames([classes.buttonRoot, oc(styles).buttonRoot()]),
          label: classes.buttonLabel,
        }}
        onClick={onClick}
      >
        {props.label}
      </ActionButton>
      <div>{isSectionOpen && props.children}</div>
    </div>
  );
};

export const CollapsibleSection = withStyles(styles)(CollapsibleSectionUnstyled);
