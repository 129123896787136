import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { Success } from 'features-apollo/components/dialogs/StatusDialogs';
import { GET_QUOTE_ETAG } from 'features-apollo/quote/components/PropertySheets/Sales/queries';
import * as appSelectors from 'features/app/selectors';
import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/proposal/actions';
import { getMinimumCredit } from 'features/proposal/utils';
import { Query, SoldToSimple, Message, MessageSource } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { RequestCreditLine, RequestCreditLineView } from './';
import { REQUEST_CREDIT_LINE, REQUEST_CREDIT_LINE_UPDATE_DEAL_ESTIMATE } from './queries';

const dialogDimension = {
  height: 424,
  width: 506,
};

const mapStateToProps = (state: RootState) => ({
  minCreditLineDivisor: appSelectors.getMinCreditLineDivisor(state),
});

const dispatchProps = {
  requestCreditLine: (request: RequestCreditLine) =>
    actions.requestCreditLineAsync.request(request),
};

interface RequestCreditLineDialogProps {
  annualDealEstimate: string;
  minimumCreditLine: string;
  setCustomerFooterAnnualDealEstimate?: (value: string) => void;
  soldTo?: SoldToSimple;
  quoteId: string;
  billingCurrency: string;
  etag: string;
}

type Props = RequestCreditLineDialogProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

export const RequestCreditLineDialogFeature: React.FC<Props> = props => {
  const { soldTo, quoteId, billingCurrency, etag } = props;
  const organizationId = oc(soldTo).organization.id();
  const accountId = oc(soldTo).organization.accountId();
  const { t } = useTranslation();
  const [requested, setRequested] = React.useState<boolean>(false);
  const [annualDealEstimate, setAnnualDealEstimate] = React.useState<string>(
    props.annualDealEstimate
  );
  const [minimumCreditLine, setMinimumCreditLine] = React.useState<string>(props.minimumCreditLine);

  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const [requestCreditLineGQL, { loading: isCreditLineLoading, error }] = useMutation(
    REQUEST_CREDIT_LINE,
    {
      refetchQueries: [{ query: GET_QUOTE_ETAG, variables: { id: quoteId } }],
      update: (useApolloClient, data) => {
        const cachedActiveQuote = useApolloClient.readQuery<Query>({
          query: GET_QUOTE,
          variables: { id: quoteId },
        });

        const messages = oc(cachedActiveQuote).getQuote.messages() as Message[];
        const newMessages: Message[] = [];
        messages.forEach((message: Message) => {
          if (message.messageSource !== MessageSource.CustomerCreditlineInvalid) {
            newMessages.push({ ...message, __typename: 'Message' });
          }
        });
        const cachedCopyQuote = {
          getQuote: {
            ...oc(cachedActiveQuote).getQuote(),
            soldTo: {
              ...oc(data).data.requestCreditLine(),
              __typename: 'SoldToSimple',
              organization: {
                ...oc(cachedActiveQuote).getQuote.soldTo.organization(),
                dealEstimate: oc(data).data.requestCreditLine.organization.dealEstimate(),
                credit: {
                  ...oc(data).data.requestCreditLine.organization.credit(),
                  __typename: 'Credit',
                },
              },
            },
            messages: newMessages,
          },
        };

        useApolloClient.writeQuery({
          query: GET_QUOTE,
          variables: { id: quoteId },
          data: cachedCopyQuote,
        });
      },
    }
  );

  const [
    requestCreditLineAndUpdateEstimateGQL,
    { loading: isCreditAndAnnualDealEstimateLoading, error: creditAndAnnualDealEstimateError },
  ] = useMutation(REQUEST_CREDIT_LINE_UPDATE_DEAL_ESTIMATE, {
    refetchQueries: [{ query: GET_QUOTE_ETAG, variables: { id: quoteId } }],
    update: (useApolloClient, data) => {
      const cachedActiveQuote = useApolloClient.readQuery<Query>({
        query: GET_QUOTE,
        variables: { id: quoteId },
      });

      const messages = oc(cachedActiveQuote).getQuote.messages() as Message[];
      const newMessages: Message[] = [];
      messages.forEach((message: Message) => {
        if (message.messageSource !== MessageSource.CustomerCreditlineInvalid) {
          newMessages.push({ ...message, __typename: 'Message' });
        }
      });
      const cachedCopyQuote = {
        getQuote: {
          ...oc(cachedActiveQuote).getQuote(),
          soldTo: {
            ...oc(data).data.requestCreditLine(),
            __typename: 'SoldToSimple',
            organization: {
              ...oc(cachedActiveQuote).getQuote.soldTo.organization(),
              dealEstimate: oc(data).data.requestCreditLine.organization.dealEstimate(),
              credit: {
                ...oc(data).data.requestCreditLine.organization.credit(),
                __typename: 'Credit',
              },
            },
          },
          messages: newMessages,
        },
      };

      useApolloClient.writeQuery({
        query: GET_QUOTE,
        variables: { id: quoteId },
        data: cachedCopyQuote,
      });
    },
  });

  const requestCreditLine = () =>
    requestCreditLineGQL({
      variables: {
        quoteId: quoteId,
      },
    });

  const requestCreditLineAndpdateDealEstimate = (
    annualDealEstimate: string,
    minimumCreditLine: string
  ) => {
    requestCreditLineAndUpdateEstimateGQL({
      variables: {
        quoteId: quoteId,
        input: {
          quoteIdentifier: {
            id: quoteId,
            etag: etag,
          },
          annualDealEstimate: Number(annualDealEstimate),
          minimumCreditLine: Number(minimumCreditLine),
        },
      },
    });
  };

  const dispatchRequestCreditLine = (annualDealEstimate: string, minimumCreditLine: string) => {
    if (props.setCustomerFooterAnnualDealEstimate) {
      props.setCustomerFooterAnnualDealEstimate(annualDealEstimate);
    }

    setAnnualDealEstimate(annualDealEstimate);
    setMinimumCreditLine(minimumCreditLine);

    if (organizationId && accountId) {
      if (
        annualDealEstimate !== props.annualDealEstimate ||
        minimumCreditLine !== props.minimumCreditLine
      ) {
        requestCreditLineAndpdateDealEstimate(annualDealEstimate, minimumCreditLine);
      } else {
        requestCreditLine();
      }
    } else {
      loggerService.error({
        error: new Error('Missing organizationId or accountId or both that is not expected.'),
      });
    }
    setRequested(true);
  };

  if (!requested) {
    const isDealEstimateAdded = !!+annualDealEstimate;
    let minCreditLine = minimumCreditLine;
    if (isDealEstimateAdded && (!minimumCreditLine || minimumCreditLine === '0')) {
      minCreditLine = getMinimumCredit(Number(annualDealEstimate), props.minCreditLineDivisor);
    }
    return (
      <RequestCreditLineView
        {...dialogDimension}
        annualDealEstimate={annualDealEstimate}
        billingCurrency={billingCurrency}
        minimumCreditLine={minCreditLine}
        onRequestCreditLine={dispatchRequestCreditLine}
      />
    );
  } else if (isCreditAndAnnualDealEstimateLoading || isCreditLineLoading) {
    return (
      <Processing {...dialogDimension} message1={t('quote::The credit line is being requested.')} />
    );
  } else if (error || creditAndAnnualDealEstimateError) {
    return (
      <Fail
        {...dialogDimension}
        closeDialog={closeDialog}
        dataAutomationId="requestCreditLineFail"
        message={t('quote::Sorry, the "Request Credit" action failed.')}
        onTryAgainClick={() => dispatchRequestCreditLine(annualDealEstimate, minimumCreditLine)}
      />
    );
  } else {
    return (
      <Success
        message={t('quote::You have successfully requested a credit line.')}
        {...dialogDimension}
        dataAutomationId="requestCreditLineSuccess"
      />
    );
  }
};

export const RequestCreditLineDialog = connect(
  mapStateToProps,
  dispatchProps
)(RequestCreditLineDialogFeature);
