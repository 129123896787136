import { ButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { meControlButtonStyles } from '../MeControlButton/MeControlButton.styles';

const menuItemButtonStyles = (theme: ThemeProps) => ({
  button: {
    ...meControlButtonStyles(theme).menuItem,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.backgroundCommon,
    },
  },
  buttonFlexContainer: {
    justifyContent: 'left',
  },
  buttonIcon: {
    ...meControlButtonStyles(theme).icon,
    fontSize: theme.fonts.fontSizes.medium,
    color: theme.palette.text,
  },
  buttonLabel: {
    fontWeight: theme.fonts.fontWeights.regular,
  },
  buttonTextContainer: {
    flexGrow: 'unset',
    marginRight: 4,
  },
});

type Props = ButtonSharedProps & WithStyles<typeof menuItemButtonStyles>;

const MenuItemButtonUnstyled: React.FC<Props> = props => {
  const { classes } = props;

  return (
    <ButtonAtom
      addClass={mergeClassNames([classes.button, props.addClass])}
      data-automation-id={props.dataAutomationId}
      iconProps={{ iconName: props.iconName }}
      styles={{
        flexContainer: classes.buttonFlexContainer,
        icon: classes.buttonIcon,
        label: classes.buttonLabel,
        textContainer: classes.buttonTextContainer,
      }}
      text={props.text}
      onClick={props.onClick}
    >
      {props.children}
    </ButtonAtom>
  );
};

export const MenuItemButton = withStyles(menuItemButtonStyles)(MenuItemButtonUnstyled) as React.FC<
  ButtonSharedProps
>;
