import { Error, HelpContent } from 'features/app/types';
import { Flight } from 'services/flights/flightList';
import { GetFlights } from 'services/flights/types';
import { createAsyncAction } from 'store/utils';
import { createStandardAction } from 'typesafe-actions';

import { AppConfig, Config } from './config/type';

export const loadFlightsAsync = createAsyncAction(
  '@@flights/LOAD_REQUEST',
  '@@flights/LOAD_SUCCESS',
  '@@flights/LOAD_FAILURE'
)<GetFlights, string[], Error>();

export const updateServiceConfig = createStandardAction('@@serviceConfig/UPDATE')<AppConfig>();
export const updateConfig = createStandardAction('@@config/UPDATE')<Partial<Config>>();
export const setFlightValue = createStandardAction('@@config/SET_FLIGHT_VALUE')<{
  key: Flight;
  value: boolean;
}>();
export const setFlights = createStandardAction('@@config/SET_FLIGHTS')<
  Partial<Record<Flight, boolean>>
>();
export const setCacheFlights = createStandardAction('@@config/SET_CACHE_FLIGHTS')<boolean>();

export const setHelpContentType = createStandardAction('@@app/SET_HELP_CONTENT_TYPE')<
  HelpContent
>();

export const setIsHelpPanelOpen = createStandardAction('@@config/SET_HELP_PANEL_OPEN')<boolean>();
export const setHelpPanelWidth = createStandardAction('@@config/SET_HELP_PANEL_WIDTH')<number>();
