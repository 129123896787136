import { ThemeProps } from 'styles';

export const parentAccountDialogStyles = (theme: ThemeProps) => ({
  description: {
    paddingBottom: 20,
  },
  flexContainer: {
    height: 370,
  },
  options: {
    width: 284,
    paddingRight: 20,
  },
  body: {
    display: 'flex',
  },
  notifications: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 48,
  },
  infoHeader: {
    display: 'flex',
  },
  infoIcon: {
    color: theme.palette.textSecondary,
    paddingRight: 5,
    marginTop: 2,
  },
  infoHeaderText: {
    color: theme.palette.textSecondary,
  },
  infoBody: {
    paddingLeft: 20,
    paddingBottom: 20,
  },
  infoText: {
    color: theme.palette.textTertiary,
  },
  infoSpacing: {
    paddingTop: 8,
  },
});
