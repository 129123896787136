import * as selectors from 'features/user/selectors';
import { loadTheme } from 'office-ui-fabric-react';
import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import GlobalStyles from 'styles/GlobalStyles';
import { ThemeProvider as JssThemeProvider } from 'theming';

import { getMeplaTheme, supportedThemes } from './';
import { ThemeKey } from './themes';

const mapStateToProps = (state: RootState) => ({
  userPreferences: selectors.getUserPreferences(state),
});

type Props = {} & ReturnType<typeof mapStateToProps>;

const ThemeProvider: React.FC<Props> = ({
  userPreferences,
  children,
}: PropsWithChildren<Props>) => {
  const theme = userPreferences === null ? ThemeKey.light : userPreferences.theme;
  const rawtheme =
    supportedThemes.find(supportedTheme => theme === supportedTheme.key) || supportedThemes[0];
  const meplaTheme = getMeplaTheme(rawtheme);

  React.useEffect(() => {
    loadTheme(meplaTheme.FabricTheme);
  }, [meplaTheme]);

  return (
    <JssThemeProvider theme={meplaTheme}>
      <GlobalStyles />
      {children}
    </JssThemeProvider>
  );
};

export default connect(mapStateToProps)(ThemeProvider);
