import {
  AddressProps,
  ApprovalItem,
  ApprovalListItem,
  ApprovalStatus,
  ConditionItem,
  NotificationItem,
} from 'components';
import { getMinCreditLineDivisor } from 'features/app/selectors';
import { CwaLink } from 'features/components/CwaLink/CwaLink';
import { requireVatId } from 'features/customer/actions';
import { getOrganization } from 'features/customer/selectors';
import { CreditLineReason } from 'features/proposal/components/Dialogs/CreditDialog';
import { GetMarketInfo } from 'features/proposal/components/VatId/Queries';
import {
  getActiveApproval,
  getActiveProposal,
  getAnnualDealEstimate,
  getBillingCurrency,
  getCreditInfo,
  getCreditLineStatus,
  getCustomerAddress,
  getCustomerName,
  getCustomerTpid,
  getEndCustomerAddress,
  getEndCustomerName,
  getEndCustomerTradeName,
  getMinimumCreditLine,
  getOrganizationId,
  getTradeName,
  isPartnerProposal,
  quoteHydrateLoading,
} from 'features/proposal/selectors';
import { defaultMarket, Market } from 'features/proposal/supported-markets';
import { formatCurrency, isAgreementTypeLegacy } from 'features/proposal/utils';
import { MarketInfo } from 'generated/graphql';
import * as moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Approval,
  ApprovalActionType,
  ApprovalLevel,
  ApprovalLevelState,
} from 'services/approval/types';
import { CreditInfo } from 'services/credit/types';
import { LineItem, Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { useQuery } from '@apollo/react-hooks';

import { Comment } from './ApprovalComments';
import { ProposalDetailsPane } from './ProposalDetailsPane';

interface ProposalDetails {
  proposal: Proposal;
  lineItems: LineItem[];
  notificationList: NotificationItem[];
}

export interface CreditDetails {
  creditInfo?: CreditInfo;
  creditLineStatus: CreditLineReason;
  minimumCreditLine: string;
  billingCurrency: string;
  annualDealEstimate?: number;
  minCreditlineDivisor?: number;
}

function calculateDays(level: ApprovalLevel, approval: Partial<Approval>) {
  const now = moment.utc();
  let duration;
  if (level.state === 'submitted') {
    const found =
      approval.history && approval.history.find(action => action.status === 'submitted');
    const submittedDate = found ? moment.utc(found.createdDate) : '';
    duration = moment.duration(now.diff(submittedDate));
    return duration.days().toString();
  }
  const previousLevel =
    approval.approvalLevels &&
    approval.approvalLevels.find(approvalLevel => approvalLevel.order === level.order - 1);
  const previousLevelEmail = oc(previousLevel).approvers[0].emailAddress('-');
  const history =
    approval.history &&
    approval.history.find(
      history =>
        history.actor === previousLevelEmail && history.action === ApprovalActionType.Approve
    );
  const end = history ? moment.utc(history.createdDate) : '-';
  return end !== '-'
    ? moment
        .duration(now.diff(end))
        .days()
        .toString()
    : '-';
}

function getStatus(status: string) {
  switch (status) {
    case 'approved':
      return ApprovalStatus.accepted;
    case 'rejected':
      return ApprovalStatus.rejected;
    default:
      return ApprovalStatus.waiting;
  }
}

export const getComments = (approval?: Partial<Approval>) => {
  let comments: Comment[] = [];
  if (approval && approval.history && approval.history.length) {
    const sorted = approval.history.sort((left, right) =>
      moment.utc(left.createdDate).diff(moment.utc(right.createdDate))
    );

    sorted.forEach((comment, index) => {
      if (comment.comments) {
        const date = new Date(comment.createdDate).toLocaleDateString();
        const detailsComment: Comment = {
          id: index.toString(),
          action: comment.action,
          author: comment.actor || comment.createdBy,
          date,
          text: comment.comments,
        };
        comments.push(detailsComment);
      }
    });
  }
  return comments;
};

export const generateApprovals = (
  requiredApproval?: Partial<Approval>,
  activeApproval?: Partial<Approval>
) => {
  let approvalResults: ApprovalListItem[] = [];
  let history = activeApproval && activeApproval.history;
  if (
    requiredApproval &&
    requiredApproval.requiredApprovalLevels &&
    requiredApproval.requiredApprovalLevels.length > 0
  ) {
    let requiredSorted = requiredApproval.requiredApprovalLevels.sort((a, b) => a.order - b.order);
    requiredSorted.forEach(requiredLevel => {
      const currentApprovalLevel =
        activeApproval &&
        activeApproval.approvalLevels &&
        activeApproval.approvalLevels.find(level => level.policy === requiredLevel.policy);
      if (activeApproval && history && currentApprovalLevel) {
        const approvers = currentApprovalLevel.approvers;
        const hasOnlyOneApprover = approvers.length === 1;
        const statusHistory =
          currentApprovalLevel.state !== 'submitted' &&
          history.find(approval => {
            const status = approval.status && approval.status.toLowerCase();
            const currentState =
              currentApprovalLevel.state && currentApprovalLevel.state.toLowerCase();
            return (
              approvers.some(approver => approver.emailAddress === approval.actor) &&
              (approval.action === ApprovalActionType.TestApprovalOverride ||
                status === currentState ||
                (status === ApprovalLevelState.partiallyApproved.toLowerCase() &&
                  currentState === ApprovalLevelState.approved.toLowerCase()))
            );
          });
        history = history.filter(history => history !== statusHistory);
        const actor = statusHistory && statusHistory.actor;
        const approvalItems: ApprovalItem[] = approvers.map(approver => {
          const approverName = approver.emailAddress || '';
          const days =
            currentApprovalLevel.state === 'submitted'
              ? `${calculateDays(currentApprovalLevel, activeApproval)}`
              : '-';
          const displayDays =
            hasOnlyOneApprover || (currentApprovalLevel.state === 'submitted' && days) ? days : '';
          const displayName = approverName ? approverName.split('@')[0] : '-';
          const state =
            statusHistory && statusHistory.action === ApprovalActionType.TestApprovalOverride
              ? statusHistory.status || ''
              : currentApprovalLevel.state;
          const displayStatus =
            actor === approverName || hasOnlyOneApprover
              ? getStatus(state)
              : ApprovalStatus.waiting;
          return {
            status: displayStatus,
            position: currentApprovalLevel.level,
            name: displayName,
            days: displayDays,
          };
        });
        approvalResults.push({ policy: currentApprovalLevel.policy, approvalItems });
      } else {
        approvalResults.push({
          policy: requiredLevel.policy,
          approvalItems: [
            {
              position: requiredLevel.level,
              name: '',
              days: '',
            },
          ],
        });
      }
    });
  }
  return approvalResults;
};

export const getCreditDetails = (credit: CreditDetails, t: (key: string) => string) => {
  const conditions: ConditionItem[] = [];
  const creditCondition: ConditionItem = {
    name: t('quote::Credit line'),
    value: '-',
    status: '',
    dataAutomationId: 'creditLine',
  };
  let requiredCreditLine;
  const annualDealEstimateValue = credit.annualDealEstimate
    ? `${formatCurrency(credit.annualDealEstimate, 0)} ${credit.billingCurrency}`
    : '-';
  const creditLineValue =
    credit.creditInfo && credit.creditInfo.reasons && credit.creditInfo.reasons.credit_line
      ? `${formatCurrency(Math.floor(parseInt(credit.creditInfo.reasons.credit_line)), 0)} ${
          credit.billingCurrency
        }`
      : '-';

  switch (credit.creditLineStatus) {
    case CreditLineReason.SafeList:
      creditCondition.value = t('quote::Safe list');
      creditCondition.status = 'safe';
      break;
    case CreditLineReason.PendingReview:
      creditCondition.value = t('quote::Pending');
      creditCondition.status = 'danger';
      break;
    case CreditLineReason.Rejected:
      creditCondition.value = t('quote::Rejected');
      creditCondition.status = 'danger';
      break;
    case CreditLineReason.UnderReview:
      creditCondition.value = t('quote::Under review');
      creditCondition.status = 'danger';
      break;
    case CreditLineReason.ReviewCancelled:
      creditCondition.value = t('quote::Review cancelled');
      creditCondition.status = 'danger';
      break;
    case CreditLineReason.CreditIncrease:
      creditCondition.value = creditLineValue;
      creditCondition.status = 'danger';
      requiredCreditLine = {
        name: t('quote::Required credit line'),
        value: `${formatCurrency(credit.minimumCreditLine, 0)} ${credit.billingCurrency}`,
        status: '',
        dataAutomationId: 'required-creditLine',
      };
      break;
    case CreditLineReason.CreditLine:
      creditCondition.value = creditLineValue;
      break;
  }
  conditions.push(creditCondition);
  if (requiredCreditLine) {
    conditions.push(requiredCreditLine);
  }
  conditions.push({
    name: t('quote::Annual deal estimate'),
    value: annualDealEstimateValue,
    status: '',
    dataAutomationId: 'annualDealEstimate',
  });

  return conditions;
};

const mapStateToProps = (state: RootState) => {
  const proposal = getActiveProposal(state);
  const organizationId = getOrganizationId(state);
  const organization = organizationId ? getOrganization(state, organizationId) : undefined;
  return {
    proposal,
    organization,
    customerName: getCustomerName(state),
    endCustomerName: getEndCustomerName(state),
    tradeName: getTradeName(state),
    endCustomerTradeName: getEndCustomerTradeName(state),
    customerAddress: getCustomerAddress(state),
    endCustomerAddress: getEndCustomerAddress(state),
    customerTpid: getCustomerTpid(state),
    activeApproval: getActiveApproval(state),
    quoteHydrateIsLoading: quoteHydrateLoading(state),
    creditInfo: {
      creditInfo: getCreditInfo(state),
      creditLineStatus: getCreditLineStatus(state),
      minimumCreditLine: getMinimumCreditLine(state).toString(),
      billingCurrency: getBillingCurrency(state),
      annualDealEstimate: getAnnualDealEstimate(state),
      minCreditDivisor: getMinCreditLineDivisor(state),
    },
    isPartnerProposal: isPartnerProposal(state),
  };
};

const dispatchProps = {
  requireVatId: requireVatId.success,
};

type Props = ProposalDetails & ReturnType<typeof mapStateToProps> & typeof dispatchProps;
const ProposalDetails = (props: Props) => {
  const {
    activeApproval,
    customerAddress,
    endCustomerAddress,
    customerName,
    endCustomerName,
    tradeName,
    endCustomerTradeName,
    creditInfo,
    notificationList,
    proposal,
    customerTpid,
    quoteHydrateIsLoading,
    requireVatId,
    organization,
  } = props;
  const { t } = useTranslation();
  const creditConditions = isAgreementTypeLegacy(proposal) ? [] : getCreditDetails(creditInfo, t);
  const approvalItems = generateApprovals(
    proposal.header.approval as Partial<Approval>,
    activeApproval
  );
  const commentItems = getComments(activeApproval);
  const cwaLink = customerTpid && (
    <div style={{ paddingTop: 5 }}>
      <CwaLink
        dataAutomationId="viewCustomer"
        linkText={t('quote::view customer')}
        tpid={customerTpid}
      />
    </div>
  );

  const displayAddress: AddressProps | undefined = customerAddress
    ? {
        address: customerAddress,
        action: cwaLink || undefined,
        dataAutomationId: 'customerAddress',
      }
    : undefined;
  const customerDisplayName =
    customerName === 'Unknown' ? t('quote::Unknown Customer') : customerName;
  const customerDisplayTradeName = tradeName !== 'Unknown' ? `dba ${tradeName}` : undefined;
  const endCustomerDisplayAddress: AddressProps | undefined = endCustomerAddress
    ? {
        address: endCustomerAddress,
        action: cwaLink || undefined,
        dataAutomationId: 'endCustomerAddress',
      }
    : undefined;
  const endCustomerDisplayName =
    endCustomerName === 'Unknown' ? t('quote::Unknown Customer') : endCustomerName;
  const endCustomerDisplayTradeName =
    endCustomerTradeName !== 'Unknown' ? `dba ${endCustomerTradeName}` : undefined;

  const market: Market = (customerAddress && (customerAddress.country as Market)) || defaultMarket;
  const { data } = useQuery<{ getMarketInfo: MarketInfo }>(GetMarketInfo, {
    variables: { market },
  });

  React.useEffect(() => {
    if (organization && !organization.backingIdentity && data && data.getMarketInfo.requiresVatId) {
      requireVatId(true);
    } else {
      requireVatId(false);
    }
  }, [data, organization, requireVatId]);

  return (
    <ProposalDetailsPane
      address={displayAddress}
      approvals={approvalItems}
      comments={commentItems}
      companyName={customerDisplayName}
      conditions={creditConditions}
      dataAutomationId="Quote-DetailsPane"
      endCompanyName={endCustomerDisplayName}
      endCustomerAddress={endCustomerDisplayAddress}
      endCustomerTradeName={endCustomerDisplayTradeName}
      id="Proposal-DetailsPane"
      isPartner={!!props.isPartnerProposal}
      notifications={quoteHydrateIsLoading ? [] : notificationList}
      partners={[]}
      tradeName={customerDisplayTradeName}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(ProposalDetails);
