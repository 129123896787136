import { TextBodySmall } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';

import { numberInputAtomStyles } from './NumberInputAtom.styles';

export interface NumberInputAtomProps {
  value: number;
  width?: number;
  ariaLabel?: string;
  disabled?: boolean;
  id?: string;
  min?: number;
  max?: number;
  minMessage?: string;
  maxMessage?: string;
  onBlur?: (setValue: number) => void;
  onFocus?: () => void;
}

type Props = NumberInputAtomProps & WithStyles<typeof numberInputAtomStyles>;

const NumberInput: React.FC<Props> = (props: Props) => {
  const { classes, disabled, value, min, max, minMessage, maxMessage } = props;
  if (min && max && min > max) {
    const e = `Min value of ${min} is greater then the max value of ${max}.`;
    const error = new Error(e);
    loggerService.error({ error });
    throw error;
  }
  const [tempValue, setTempValue] = React.useState<string>(value.toString());
  const [message, setMessage] = React.useState<string>('');
  React.useEffect(() => {
    setTempValue(value.toString());
  }, [value]);
  const element = React.useRef<HTMLInputElement | null>(null);

  const onFocus = () => {
    element.current && element.current.select();
    props.onFocus && props.onFocus();
  };

  const onBlur = () => {
    let newVal = parseInt(tempValue) || 1;
    if (max && newVal > max) {
      newVal = max;
      maxMessage && setMessage(maxMessage);
    } else if (min && newVal < min) {
      newVal = min;
      minMessage && setMessage(minMessage);
    } else {
      setMessage('');
    }
    setTempValue(newVal.toString());
    props.onBlur && props.onBlur(newVal);
  };

  return (
    <div className={classes.container}>
      <input
        className={classes.numberInput}
        disabled={disabled}
        max={max}
        min={min}
        ref={element}
        value={tempValue}
        onBlur={onBlur}
        onChange={event => {
          const val = event.target.value;
          const int = parseInt(val);
          if (isNaN(int)) {
            setTempValue('');
          } else {
            setTempValue(int.toString());
          }
        }}
        onFocus={onFocus}
      />
      <div className={classes.message}>
        <TextBodySmall>{message}</TextBodySmall>
      </div>
    </div>
  );
};

export const NumberInputAtom = withStyles(numberInputAtomStyles)(NumberInput) as React.FC<
  NumberInputAtomProps
>;
