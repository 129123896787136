import * as React from 'react';
import { mergeClassNames } from 'components';
import { instantFeedbackButtonsStyles } from './InstantFeedbackButtons.styles';
import withStyles, { WithStyles } from 'react-jss';
import { CommandButtonAtom } from 'components/atoms';

export interface InstantFeedbackButtonsProps {
  actions: {
    onThumbsUpClick: (isReset: boolean) => void;
    onThumbsDownClick: (isReset: boolean) => void;
  };
  ariaLabel?: string;
  dataAutomationId?: string;
  className?: string;
  disabled?: boolean;
}

type Props = InstantFeedbackButtonsProps & WithStyles<typeof instantFeedbackButtonsStyles>;

export const InstantFeedbackButtonsUnstyled: React.FC<Props> = props => {
  const [liked, setLiked] = React.useState(false);
  const [disliked, setDisliked] = React.useState(false);

  const onLike = () => {
    props.actions.onThumbsUpClick(liked);
    setLiked(!liked);
    setDisliked(false);
  };

  const onDislike = () => {
    props.actions.onThumbsDownClick(disliked);
    setDisliked(!disliked);
    setLiked(false);
  };

  const buttonClass = mergeClassNames([props.classes.button, props.classes.icon]);
  const activeButtonClass = mergeClassNames([
    props.classes.button,
    props.classes.icon,
    props.classes.iconSolid,
  ]);

  return (
    <div className={props.className}>
      <CommandButtonAtom
        addClass={liked ? activeButtonClass : buttonClass}
        ariaLabel={props.ariaLabel}
        dataAutomationId={props.dataAutomationId}
        disabled={props.disabled}
        iconProps={{ iconName: liked ? 'LikeSolid' : 'Like' }}
        title="Helpful"
        onClick={onLike}
      />
      <CommandButtonAtom
        addClass={disliked ? activeButtonClass : buttonClass}
        ariaLabel={props.ariaLabel}
        dataAutomationId={props.dataAutomationId}
        disabled={props.disabled}
        iconProps={{ iconName: disliked ? 'DislikeSolid' : 'Dislike' }}
        title="Not helpful"
        onClick={onDislike}
      />
    </div>
  );
};
export const InstantFeedbackButtons = withStyles(instantFeedbackButtonsStyles)(
  InstantFeedbackButtonsUnstyled
);
