import axios from 'axios';
import { TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader } from '../utils';
import { applicationId, ComplianceControlConfig, endpoints } from './config';
import { Application, UserPermissions } from './types';
import { getUserPermissions } from './utils';

/**
 * Gets header with user token and correlation id
 */
const getHeaders = async (): Promise<Record<string, string>> => {
  return {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
  };
};

/**
 * Get parameters including user email, application id, and fields value defined
 * @param {Object} config includes the environment to use
 * @param {string} userEmail
 */
const getParams = (config: ComplianceControlConfig, userEmail: string): Record<string, string> => {
  const { environment } = config;
  return {
    applicationId: applicationId[environment],
    subjectUpn: userEmail,
  };
};

export async function loadUserPermissions(
  userEmail: string,
  config: ComplianceControlConfig
): Promise<UserPermissions> {
  const { environment } = config;
  const url = endpoints[environment];
  const headers = await getHeaders();
  const params = getParams(config, userEmail);
  params.fields = 'All';
  const response = await axios.get<Application[]>(url, { headers, params });
  const normalizedResponse = getUserPermissions(response.data);
  return normalizedResponse;
}

export async function loadUserRoles(
  userEmail: string,
  config: ComplianceControlConfig
): Promise<string[]> {
  const { environment } = config;
  const url = endpoints[environment];
  const headers = await getHeaders();
  const params = getParams(config, userEmail);
  params.fields = 'Roles';
  const response = await axios.get<Application[]>(url, { headers, params });
  return response.data.map(application => application.Namespace);
}
