import * as React from 'react';
import { TextBody } from 'components/ions';

const leftOverflowStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  direction: 'rtl',
  textAlign: 'left',
};

export const TitleFormatter: React.FC<{ width: number; value: string }> = props => {
  const { value, width } = props;
  const textWrapper = React.useRef<HTMLDivElement | null>(null);
  const [style, setStyle] = React.useState({});
  React.useEffect(() => {
    if (textWrapper.current !== null) {
      if (textWrapper.current.clientWidth >= width) {
        setStyle(leftOverflowStyle);
      } else {
        setStyle({});
      }
    }
  }, [textWrapper, value, width]);
  return (
    <div ref={textWrapper} style={style}>
      <TextBody title={value}>{value}</TextBody>
    </div>
  );
};
