import { IPartialTheme } from 'office-ui-fabric-react';

export interface FontSizes {
  tiny: string;
  smaller: string;
  small: string;
  medium: string;
  large: string;
  larger: string;
  xLarge: string;
  xLarger: string;
  xXLarge: string;
}

export interface FontWeights {
  regular: number;
  semiBold: number;
  bold: number;
}

export interface FontFamily {
  fabricDefault: string;
}

export interface FontStyles {
  italic: string;
}

export interface Fonts {
  fontSizes: FontSizes;
  fontWeights: FontWeights;
  fontStyles: FontStyles;
  fontFamily: FontFamily;
}

export interface ZIndex {
  navBar: number;
  sideBar: number;
}

export interface ThemeProps {
  palette: ThemeColors;
  fonts: Fonts;
  FabricTheme: IPartialTheme;
  zIndex: ZIndex;
}
/**
 * @property {string} activeLink link color in active state
 * @property {string} backgroundStandout standout area "focus area"
 * @property {string} backgroundMain main body background
 * @property {string} backgroundDivider typically used for dividers and line colors as well as disabled states
 * @property {string} backgroundDividerFocus typically used for focus state in dividers and line colors as well as disabled states
 * @property {string} backgroundDividerHover typically used for hover state in dividers and line colors as well as disabled states
 * @property {string} backgroundCommon typically used to style common windows
 * @property {string} backgroundBaseGradient full page background behind everything
 * @property {string} backgroundPanel used for left nav panel
 * @property {string} border color used for borders
 * @property {string} meControlPersonaBackground used as a background color for the Persona in the MeControlButton
 * @property {string} progressBar used on the progress bar for the progress indicator
 * @property {string} shimmerWave used on shimmer
 * @property {string} shimmerWaveBackgroundCommon used on shimmer for when using backgroundCommon
 * @property {string} text color typically contrasts main background
 * @property {string} textLink color used for links
 * @property {string} textLinkHover color used when hovering on links
 * @property {string} textSecondary alternate color for text
 * @property {string} textTertiary alternate color for text
 * @property {string} textDisabled color used for text on disabled elements
 * @property {string} textPrimaryDisabled color used for text on primary disabled elements
 * @property {string} textLink color used for links
 * @property {string} textLinkHover color used when hovering on links
 * @property {string} tileDisabled color used for tile backgrounds when they are disabled
 * @property {string} titleBarPrimarySeparator color used for dividers in the header bar for primary theme
 * @property {string} titleBarPrimaryText color used for text in the header bar for primary theme
 * @property {string} approve main color to indicate approved/success used for backgrounds
 * @property {string} approveHover darker version of approve for use on hovered backgrounds
 * @property {string} approveActive darkest version of approve for use on active (pressed) backgrounds
 * @property {string} approveText used for text in success/approved situations
 * @property {string} warning used for info highlighting potentially warnings
 * @property {string} warningHighlight used for info highlighting potentially warnings 10% alpha
 * @property {string} warningHover darker version of warning for use on hovered backgrounds
 * @property {string} warningActive darkest version of warning for use on active (pressed) backgrounds
 * @property {string} warningText used for text in warning/info situations
 * @property {string} danger used for error or failure backgrounds
 * @property {string} dangerHighlight used for error or failure backgrounds with 10% alpha
 * @property {string} dangerHover darker version of danger for use on hovered backgrounds
 * @property {string} dangerActive darkest version of danger for use on active (pressed) backgrounds
 * @property {string} dangerText used for text in error/failure situations
 * @property {string} primary  high contrast color used for primary buttons, call outs, selected highlighting, etc...
 * @property {string} primaryHover darker version of primary for use on hovered backgrounds
 * @property {string} primaryActive darkest version of primary for use on active (pressed) backgrounds
 * @property {string} secondary used for normal non primary buttons, links, etc...
 * @property {string} secondaryHover darker version of secondary for use on hovered backgrounds
 * @property {string} secondaryActive darkest version of secondary for use on active (pressed) backgrounds
 * @property {string} lineItemHighlight used to highlight selected row in table
 * @property {string} navigation used for navigation dropdown background
 * @property {string} shadowColor used for drop shadows on elements
 * @property {string} transparent alias for 'transparent'
 * @property {string} lightColor alias for 'white'/light color in themes
 * @property {string} darkColor alias for 'black'/dark color in themes
 * @property {string} demoColor alias for 'grey'/demo color in themes
 */

export interface ThemeColors {
  [key: string]: string;
  activeLink: string;
  backgroundStandout: string;
  backgroundStandoutOverlay: string;
  backgroundMain: string;
  backgroundDivider: string;
  backgroundDividerFocus: string;
  backgroundDividerHover: string;
  backgroundCommon: string;
  backgroundBaseGradient: string;
  backgroundPanel: string;
  backgroundOverlay: string;
  border: string;
  meControlPersonaBackground: string;
  progressBar: string;
  shimmerWave: string;
  shimmerWaveBackgroundCommon: string;
  text: string;
  textLink: string;
  textLinkHover: string;
  textSecondary: string;
  textTertiary: string;
  textDisabled: string;
  textPrimaryDisabled: string;
  tileDisabled: string;
  titleBarPrimarySeparator: string;
  titleBarPrimaryText: string;
  approve: string;
  approveHover: string;
  approveActive: string;
  approveText: string;
  warning: string;
  warningHighlight: string;
  warningHover: string;
  warningActive: string;
  warningText: string;
  danger: string;
  dangerHighlight: string;
  dangerHover: string;
  dangerActive: string;
  dangerText: string;
  primary: string;
  primaryHover: string;
  primaryActive: string;
  lineItemHighlight: string;
  secondary: string;
  secondaryHover: string;
  secondaryActive: string;
  navigation: string;
  shadowColor: string;
  transparent: string;
  lightColor: string;
  darkColor: string;
  demoColor: string;
}

const darkColors: ThemeColors = {
  activeLink: 'rgb(255,255,255)',
  backgroundStandout: 'rgb(26,27,40)',
  backgroundStandoutOverlay: 'rgba(26,27,40,0.2)',
  backgroundMain: 'rgb(58,59,78)',
  backgroundDivider: 'rgb(92,93,118)',
  backgroundDividerFocus: 'rgb(0,120,212)',
  backgroundDividerHover: 'rgb(209,209,209)',
  backgroundCommon: 'rgb(43,44,59)',
  backgroundBaseGradient: 'linear-gradient(to right, rgb(58,59,78), rgb(54,55,70))',
  backgroundPanel: 'rgb(43,44,59)',
  backgroundOverlay: 'rgba(26,27,40,0.4)',
  border: 'rgb(58,59,78)',
  meControlPersonaBackground: 'rgb(166,166,166)',
  progressBar: 'linear-gradient(to right, transparent 0%, rgb(2, 115, 202) 50%, transparent 100%)',
  shimmerWave: 'rgb(53,54,69)',
  shimmerWaveBackgroundCommon: 'rgb(63,64,79)',
  text: 'rgb(255,255,255)',
  textLink: 'rgb(135,200,250)',
  textLinkHover: 'rgb(210,235,255)',
  textSecondary: 'rgb(135,200,250)',
  textTertiary: 'rgb(244,244,244)',
  textDisabled: 'rgb(92,93,118)',
  textPrimaryDisabled: 'rgb(180,181,206)',
  tileDisabled: 'rgb(58,59,78)',
  titleBarPrimarySeparator: 'rgb(255, 255, 255)',
  titleBarPrimaryText: 'rgb(255,255,255)',
  approve: 'rgb(136,243,175)',
  approveHover: 'rgb(116,232,158)',
  approveActive: 'rgb(87,202,129)',
  approveText: 'rgb(136,243,175)',
  warning: 'rgb(255,233,164)',
  warningHighlight: 'rgba(255,233,164,.1)',
  warningHover: 'rgb(250,225,146)',
  warningActive: 'rgb(246,216,124)',
  warningText: 'rgb(255,233,164)',
  danger: 'rgb(251,129,153)',
  dangerHighlight: 'rgba(251,129,153,.1)',
  dangerHover: 'rgb(219,97,121)',
  dangerActive: 'rgb(208,72,99)',
  dangerText: 'rgb(251,129,153)',
  primary: 'rgb(2,115,202)',
  primaryHover: 'rgb(7,100,172)',
  primaryActive: 'rgb(7,82,140)',
  lineItemHighlight: 'rgb(20,50,82)',
  secondary: 'rgb(135,200,250)',
  secondaryHover: 'rgba(2,115,202,.1)',
  secondaryActive: 'rgba(2,115,202,.2)',
  navigation: 'rgb(92,93,118)',
  shadowColor: 'rgba(0,0,0,0.35)',
  transparent: 'transparent',
  lightColor: 'rgb(255,255,255)',
  darkColor: 'rgb(51,51,51)',
  demoColor: 'rgb(102,102,102)',
};

const lightColors: ThemeColors = {
  activeLink: 'rgb(2,76,129)',
  backgroundStandout: 'rgb(255,255,255)',
  backgroundStandoutOverlay: 'rgba(255,255,255,0.2)',
  backgroundMain: 'rgb(230,238,244)',
  backgroundDivider: 'rgb(209,209,209)',
  backgroundDividerFocus: 'rgb(135,200,250)',
  backgroundDividerHover: 'rgb(51,51,51)',
  backgroundCommon: 'rgb(244,244,244)',
  backgroundBaseGradient: 'linear-gradient(to right, rgb(230,238,244), rgb(224,233,238))',
  backgroundPanel: 'rgb(230,238,244)',
  backgroundOverlay: 'rgba(51,51,51,0.2)',
  border: 'rgba(166,166,166,1)',
  meControlPersonaBackground: 'rgb(166,166,166)',
  progressBar: 'linear-gradient(to right, transparent 0%, rgb(2, 115, 202) 50%, transparent 100%)',
  shimmerWave: 'rgb(234,234,234)',
  shimmerWaveBackgroundCommon: 'rgb(224,224,224)',
  text: 'rgb(51,51,51)',
  textLink: 'rgb(2,107,187)',
  textLinkHover: 'rgb(5,95,160)',
  textSecondary: 'rgb(2,107,187)',
  textTertiary: 'rgb(102,102,102)',
  textDisabled: 'rgb(209,209,209)',
  textPrimaryDisabled: 'rgb(102,102,102)',
  tileDisabled: 'rgb(244,244,244)',
  titleBarPrimarySeparator: 'rgb(255, 255, 255)',
  titleBarPrimaryText: 'rgb(255,255,255)',
  approve: 'rgb(136,243,175)',
  approveHover: 'rgb(116,232,158)',
  approveActive: 'rgb(87,202,129)',
  approveText: 'rgb(39,119,70)',
  warning: 'rgb(246,216,124)',
  warningHighlight: 'rgba(255,233,164,.1)',
  warningHover: 'rgb(239,208,113)',
  warningActive: 'rgb(227,194,93)',
  warningText: 'rgb(126,104,38)',
  danger: 'rgb(251,129,153)',
  dangerHighlight: 'rgba(251,129,153,.1)',
  dangerHover: 'rgb(219,97,121)',
  dangerActive: 'rgb(208,72,99)',
  dangerText: 'rgb(166,40,65)',
  primary: 'rgb(2,115,202)',
  primaryHover: 'rgb(7,100,172)',
  primaryActive: 'rgb(7,82,140)',
  lineItemHighlight: 'rgb(232,243,250)',
  secondary: 'rgb(2,115,202)',
  secondaryHover: 'rgba(2,115,202,.1)',
  secondaryActive: 'rgba(2,115,202,.2)',
  navigation: 'rgb(255,255,255)',
  shadowColor: 'rgba(0,0,0,0.2)',
  transparent: 'transparent',
  lightColor: 'rgb(255,255,255)',
  darkColor: 'rgb(51,51,51)',
  demoColor: 'rgb(102,102,102)',
};

export const fonts: Fonts = {
  fontSizes: {
    tiny: '9px',
    smaller: '11px',
    small: '12px',
    medium: '14px',
    large: '16px',
    larger: '18px',
    xLarge: '20px',
    xLarger: '24px',
    xXLarge: '32px',
  },
  fontWeights: {
    regular: 400,
    semiBold: 600,
    bold: 800,
  },
  fontStyles: {
    italic: 'italic',
  },
  fontFamily: {
    fabricDefault:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;',
  },
};

export const zIndex: ZIndex = {
  navBar: 1,
  sideBar: 10,
};

export enum ThemeKey {
  light = 'light',
  dark = 'dark',
}

export interface SupportedTheme {
  name: string;
  palette: ThemeColors;
  fonts: Fonts;
  key: ThemeKey;
  zIndex: ZIndex;
}
export const supportedThemes: SupportedTheme[] = [
  {
    key: ThemeKey.light,
    name: 'light',
    palette: lightColors,
    fonts,
    zIndex,
  },
  {
    key: ThemeKey.dark,
    name: 'dark',
    palette: darkColors,
    fonts,
    zIndex,
  },
];

function generateFabricPalette(colors: ThemeColors): IPartialTheme {
  return {
    palette: {
      themePrimary: colors.primary,
      themeLighterAlt: colors.primary,
      themeLighter: colors.primaryHover,
      themeLight: colors.primaryActive,
      themeTertiary: colors.primaryActive,
      themeSecondary: colors.secondary,
      themeDarkAlt: colors.primaryHover,
      themeDark: colors.primaryActive,
      themeDarker: colors.secondaryActive,
      // backgrounds
      neutralLighterAlt: colors.backgroundMain,
      neutralLighter: colors.backgroundCommon,
      neutralLight: colors.shimmerWave,
      neutralQuaternaryAlt: colors.secondary,
      neutralQuaternary: colors.backgroundCommon,
      neutralTertiaryAlt: colors.backgroundCommon,
      // text & foregrounds
      neutralTertiary: colors.textTertiary,
      neutralSecondary: colors.textTertiary,
      neutralSecondaryAlt: colors.textSecondary,
      neutralPrimaryAlt: colors.textSecondary,
      neutralPrimary: colors.text,
      neutralDark: colors.text,
      black: colors.text,
      white: colors.backgroundStandout,
    },
    semanticColors: {
      // Text Semantics
      bodyText: colors.text,
      bodyTextChecked: colors.text,
      bodySubtext: colors.text,
      disabledText: colors.textDisabled,
      disabledBodyText: colors.textDisabled,
      disabledSubtext: colors.textDisabled,
      disabledBodySubtext: colors.textDisabled,
      errorText: colors.dangerText,
      warningText: colors.warningText,
      inputText: colors.text,
      inputTextHovered: colors.text,
      inputPlaceholderText: colors.textDisabled,
      buttonText: colors.text,
      buttonTextHovered: colors.text,
      buttonTextPressed: colors.text,
      buttonTextDisabled: colors.textDisabled,
      buttonTextChecked: colors.text,
      buttonTextCheckedHovered: colors.text,
      primaryButtonText: 'white',
      primaryButtonTextHovered: 'white',
      primaryButtonTextPressed: 'white',

      // Element Semantics
      // body
      bodyBackground: colors.backgroundBaseGradient,
      bodyStandoutBackground: colors.backgroundStandout,
      bodyFrameBackground: colors.backgroundCommon,
      bodyFrameDivider: colors.backgroundDivider,
      bodyDivider: colors.backgroundDivider,
      disabledBackground: colors.backgroundDivider,
      focusBorder: colors.primary,
      variantBorder: colors.border,
      variantBorderHovered: colors.text,
      defaultStateBackground: colors.backgroundCommon,
      errorBackground: colors.danger,
      blockingBackground: colors.danger,
      warningBackground: colors.warning,
      warningHighlight: colors.warningActive,
      successBackground: colors.approve,
      // input controls
      inputBorder: colors.border,
      smallInputBorder: colors.border,
      inputBorderHovered: colors.text,
      inputBackground: colors.backgroundStandout,
      inputBackgroundChecked: colors.primary,
      inputBackgroundCheckedHovered: colors.primaryHover,
      inputForegroundChecked: colors.text,
      inputFocusBorderAlt: colors.primary,
      // button controls
      buttonBackground: colors.backgroundMain,
      buttonBackgroundChecked: colors.secondaryActive,
      buttonBackgroundHovered: colors.secondaryHover,
      buttonBackgroundCheckedHovered: colors.secondaryHover,
      buttonBackgroundDisabled: colors.backgroundDivider,
      buttonBorder: colors.secondary,
      buttonBackgroundPressed: colors.secondaryActive,
      primaryButtonBackground: colors.primary,
      primaryButtonBackgroundHovered: colors.primaryHover,
      primaryButtonBackgroundPressed: colors.primaryActive,
      primaryButtonBackgroundDisabled: colors.backgroundDivider,
      primaryButtonBorder: colors.primary,
      // menu controls
      menuBackground: colors.backgroundStandout,
      menuDivider: colors.backgroundDivider,
      menuIcon: colors.text,
      menuHeader: colors.text,
      menuItemBackgroundHovered: colors.backgroundCommon,
      menuItemBackgroundPressed: colors.backgroundCommon,
      menuItemText: colors.text,
      menuItemTextHovered: colors.text,
      menuItemBackgroundChecked: colors.primary,
      // list control
      listBackground: colors.backgroundStandout,
      listText: colors.text,
      listItemBackgroundHovered: colors.backgroundCommon,
      listItemBackgroundChecked: colors.backgroundDivider,
      listItemBackgroundCheckedHovered: colors.secondary,
    },
  };
}

export function getMeplaTheme(theme: SupportedTheme): ThemeProps {
  return {
    palette: theme.palette,
    FabricTheme: generateFabricPalette(theme.palette),
    fonts: theme.fonts,
    zIndex: theme.zIndex,
  };
}
