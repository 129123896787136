import { BarButton, CommandBarButton } from 'components';
import { QuoteContextWizardContainer } from 'features-apollo/quote/components/Wizards';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

export const NewQuoteBarButton: React.FC = () => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <QuoteContextWizardContainer />,
  };

  const handleOnClick = () => {
    context.openDialog(dialogProps);
  };

  return (
    <BarButton
      dataAutomationId="createQuoteButton"
      iconName="Add"
      showNewIconColor
      text={t('quote::New Quote')}
      onClick={handleOnClick}
    />
  );
};

export const NewQuoteCommandBarButton: React.FC = () => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <QuoteContextWizardContainer />,
  };

  const handleOnClick = () => {
    context.openDialog(dialogProps);
  };

  return (
    <CommandBarButton
      ariaLabel={t('quote::Create new quote')}
      dataAutomationId="createQuoteButton"
      iconName="Add"
      text={t('quote::New')}
      onClick={handleOnClick}
    />
  );
};
