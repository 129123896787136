import { CalloutAtom } from 'components/atoms';
import { PrimaryButton, TextboxWithProgress } from 'components/ions';
import { useDebounce } from 'components/utilities/debounce';
import { DirectionalHint, ITextField, KeyCodes } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { autoSuggestStyles, calloutFabricStyles } from './Autosuggest.styles';
import { AutosuggestList } from './AutosuggestList';

export interface Suggestion<T> {
  value: T;
  key: string;
  ariaLabel: string;
}

export interface AutosuggestProps<T> {
  suggestions: Suggestion<T>[];
  listFooter?: JSX.Element;
  notFound?: JSX.Element;
  error?: JSX.Element;
  onSelect: (suggestion: Suggestion<T>) => void;
  onRenderRow: (suggestion: Suggestion<T>) => JSX.Element;
  onSearch: (query: string) => void;
  doNotSwitchToLoadingStateImmediately?: boolean;
  showButton?: boolean;
  textboxLabel?: string;
  placeholder: string;
  strings: { loading: string; notFound: string; listAriaLabel: string };
  calloutMaxHeight?: number;
  onExecuteSearchWithoutDebounce?: () => void;
  isLoading?: boolean;
  icon?: { iconName: string };
  required?: boolean;
  dataAutomationId?: string;
  showProgressBar?: boolean;
  autoFocus?: boolean;
  addClass?: string;
}

type Props<T> = AutosuggestProps<T> & WithStyles<typeof autoSuggestStyles>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AutosuggestUnstyled = <T extends any>(props: Props<T>) => {
  const target = React.useRef<HTMLDivElement>(null);
  const refToFirstChild = React.useRef<HTMLButtonElement>(null);
  const refToLastChild = React.useRef<HTMLButtonElement>(null);
  const textboxRef = React.useRef<ITextField>(null);
  const { calloutMaxHeight } = props;

  const [listOpen, setListOpen] = React.useState(false);
  const [searched, setSearched] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const search$ = useDebounce(500);
  // isLocalLoading sets the loading to true immediately when a user types (it is NOT debounced, if it is debounced it is too late to give the feedback to the user),
  // we set it back to false when we know that the server loading(in our case props.isLoading) is done
  const [isLocalLoading, setIsLocalLoading] = React.useState(false);
  React.useEffect(() => {
    if (!props.isLoading && !props.doNotSwitchToLoadingStateImmediately) {
      setIsLocalLoading(false);
    }
  }, [props.isLoading, props.doNotSwitchToLoadingStateImmediately]);

  const loading = isLocalLoading || props.isLoading;
  const onChangeText = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value) {
      search$.next(() => props.onSearch(value));
    }
    setListOpen(!!value);
    props.onExecuteSearchWithoutDebounce && props.onExecuteSearchWithoutDebounce();
    !props.doNotSwitchToLoadingStateImmediately && setIsLocalLoading(true);
    setSearched(value || '');
  };

  const searchWhenClicked = () => {
    if (searched) {
      search$.next(() => props.onSearch(searched));
    }
    setListOpen(!!searched);
    props.onExecuteSearchWithoutDebounce && props.onExecuteSearchWithoutDebounce();
  };

  const onFocus = () => {
    if (searched && props.suggestions.length) {
      setListOpen(true);
    }
  };

  const onSelect = (suggestion: Suggestion<T>) => {
    setListOpen(false);
    props.onSelect(suggestion);
  };
  const width = (target.current && target.current.offsetWidth) || 375;
  const calloutStyles = calloutFabricStyles(width);
  const onKeyDownTextBox = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.keyCode === KeyCodes.down && props.suggestions.length) {
      event.preventDefault();
      refToFirstChild && refToFirstChild.current && refToFirstChild.current.focus();
      setSelectedIndex(0);
    } else if (event.keyCode === KeyCodes.up && props.suggestions.length) {
      event.preventDefault();
      refToLastChild && refToLastChild.current && refToLastChild.current.focus();
      setSelectedIndex(props.suggestions.length - 1);
    } else if (event.keyCode === KeyCodes.enter) {
      searchWhenClicked();
    } else if (event.keyCode === KeyCodes.escape) {
      if (listOpen) {
        event.stopPropagation();
        setListOpen(false);
      }
    }
  };
  const close = () => {
    setListOpen(false);
  };

  return (
    <div className={props.addClass || ''}>
      <div className={props.classes.container}>
        <div className={props.classes.textbox} ref={target}>
          <TextboxWithProgress
            addClass={props.icon ? props.classes.searchIconStyles : undefined}
            ariaLabel={props.textboxLabel}
            autoFocus={props.autoFocus}
            componentRef={textboxRef}
            dataAutomationId={`${props.dataAutomationId}Textbox`}
            iconProps={props.icon}
            label={props.textboxLabel}
            placeholder={props.placeholder}
            progressHidden={!props.showProgressBar}
            required={props.required}
            onChange={onChangeText}
            onFocus={onFocus}
            onKeyDown={onKeyDownTextBox}
          />
        </div>
        {props.showButton && (
          <div className={props.classes.searchButton}>
            <PrimaryButton
              dataAutomationId={`${props.dataAutomationId}Button`}
              text="Search"
              onClick={searchWhenClicked}
            />
          </div>
        )}
      </div>
      {listOpen && (
        <CalloutAtom
          alignTargetEdge={true}
          calloutMaxHeight={calloutMaxHeight || 350}
          directionalHint={DirectionalHint.bottomCenter}
          dismissOnResize={true}
          hidden={false}
          isBeakVisible={false}
          setInitialFocus={false}
          styles={calloutStyles}
          target={target.current}
          trapFocus={false}
          onDismiss={close}
        >
          <AutosuggestList
            error={props.error}
            isLoading={loading}
            key="AutoSuggest"
            listFooter={props.listFooter}
            notFound={props.notFound}
            refToFirstChild={refToFirstChild}
            refToLastChild={refToLastChild}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            strings={props.strings}
            suggestions={props.suggestions}
            textboxRef={textboxRef}
            onRenderRow={props.onRenderRow}
            onSelect={onSelect}
          />
        </CalloutAtom>
      )}
    </div>
  );
};

export const Autosuggest = withStyles(autoSuggestStyles)(AutosuggestUnstyled) as <T>(
  props: AutosuggestProps<T>
) => JSX.Element;
