import axios from 'axios';
import { User } from 'features/user/types';
import { createGuid, getAuthHeader, getCV, TokenAuthorities } from 'services/utils';

import {
  AgreementType,
  CatalogCallConfig,
  DefaultProductFamilies,
  endpoints,
  searchProductPageSize,
} from './config';
import {
  NegotiatedTermResponse,
  NegotiatedTermsRequest,
  Product,
  ProductFamily,
  ProductResult,
  Products,
  ProductSearchByFamilyRequest,
  ProductSearchByFamilyResponse,
  ProductSearchByFamilyResults,
  ProductSearchRequest,
  ProductSearchResponse,
  ProductSearchResults,
  TermSearchRequest,
} from './types';
import {
  filterLegacyProducts,
  filterModernProducts,
  getStringifiedKeyFromConfig,
  InPlaceSanitizeProduct,
} from './utils';

export * from './config';

function getParams(config: CatalogCallConfig): Record<string, string> {
  return {
    agreementType: config.agreementType,
    market: config.market,
    languages: 'en-US', //TODO: consider using config.languages
    catalogIds: '4',
    actionFilter: config.actionFilter,
    fieldsTemplate: config.template,
  };
}

function getBlendedSearchParams(config: CatalogCallConfig): Record<string, string> {
  return {
    market: config.market,
    languages: 'en-US',
    catalogIds: '4',
    internalSearchStrategy: 'autosuggestblended',
    actionFilter: config.actionFilter,
    fieldsTemplate: config.template,
    productFamilyNames: 'Azure,Passes,Software',
  };
}

async function getHeaders(
  config: CatalogCallConfig,
  tenantId?: string,
  objectId?: string
): Promise<Record<string, string>> {
  const dcatHeader = [
    { ClaimType: 'agreementtype', ClaimValue: config.agreementType },
    { ClaimType: 'pricingaudience', ClaimValue: config.audience },
    {
      ClaimType: 'nationalcloud',
      ClaimValue:
        config.nationalCloud[0].length > 1 ? config.nationalCloud[0] : config.nationalCloud,
    },
    { ClaimType: 'supportedcontentversion', ClaimValue: '2018.08.31.0' },
    { ClaimType: 'tenantid', ClaimValue: tenantId },
    { ClaimType: 'userid', ClaimValue: objectId },
  ];
  return {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'MS-DCat-Auth': JSON.stringify(dcatHeader),
    'x-ms-correlation-id': createGuid(),
    'MS-CV': getCV(),
  };
}

export async function loadProducts(
  ids: string[],
  user: User,
  config: CatalogCallConfig
): Promise<ProductResult> {
  const maxHydrateItems = 25;
  const { environment } = config;
  const headers = await getHeaders(config, user.tid, user.oid);
  const url = `${endpoints[environment]}/products`;
  let params = getParams(config);
  const promises: Promise<void>[] = [];
  const aggregatedResults: Product[] = [];
  for (let i = 0; i < ids.length; i += maxHydrateItems) {
    params = { ...params, bigIds: ids.slice(i, i + maxHydrateItems).toString() };
    const promise = axios
      .get<Products>(url, { params, headers })
      .then(result => {
        result.data.Products.forEach(product => {
          InPlaceSanitizeProduct(product);
          aggregatedResults.push(product);
        });
      });
    promises.push(promise);
  }
  await Promise.all(promises);

  const resultIds = new Set(aggregatedResults.map(product => product.ProductId));
  const failedIds = ids.filter(id => !resultIds.has(id));

  return {
    products: aggregatedResults,
    productKey: getStringifiedKeyFromConfig(config),
    failed: failedIds,
  };
}

export async function getNegotiatedTerms(
  request: NegotiatedTermsRequest,
  user: User,
  config: CatalogCallConfig
): Promise<NegotiatedTermResponse> {
  const { environment } = config;
  const headers = await getHeaders(config, user.tid, user.oid);
  const productFamily = ProductFamily.NegotiatedTerms.toLocaleLowerCase();
  const url = `${endpoints[environment]}/productFamilies/${productFamily}/products`;
  let params = getParams(config);
  params.$top = request.top.toString();
  const response = await axios.get<NegotiatedTermResponse>(url, { params, headers });
  return response.data;
}

export async function searchTerms(
  request: TermSearchRequest,
  user: User,
  config: CatalogCallConfig
): Promise<ProductSearchResponse> {
  const { environment } = config;
  const headers = await getHeaders(config, user.tid, user.oid);
  const productFamily = ProductFamily.NegotiatedTerms.toLocaleLowerCase();
  const url = `${endpoints[environment]}/productFamilies/${productFamily}/products`;
  const params = getParams(config);
  params.query = request.query;
  params.$top = request.top.toString();

  const response = await axios.get<ProductSearchByFamilyResults>(url, { params, headers });
  const terms = response.data.Products;
  const productFamiliesWithMoreResults: ProductFamily[] = [];
  if (response.data.HasMorePages) {
    productFamiliesWithMoreResults.push(ProductFamily.NegotiatedTerms);
  }

  return {
    products: terms,
    productFamiliesWithMoreResults,
  };
}

export async function searchProducts(
  request: ProductSearchRequest,
  user: User,
  config: CatalogCallConfig,
  disableProductInclusionFilter: boolean
): Promise<ProductSearchResponse> {
  const { environment } = config;
  const headers = await getHeaders(config, user.tid, user.oid);
  const url = `${endpoints[environment]}/productFamilies/products`;
  const params = getParams(config);
  const productFamilies = (request.productFamilyNames || DefaultProductFamilies).toString();
  params.productFamilyNames = productFamilies;
  params.query = request.query;
  const response = await axios.get<ProductSearchResults>(url, { params, headers });
  const aggregatedResults: Product[] = [];
  const productFamiliesWithMoreResults: ProductFamily[] = [];

  response.data.Results.forEach(result => {
    aggregatedResults.push(...result.Products);
    if (result.TotalProductCount > searchProductPageSize) {
      productFamiliesWithMoreResults.push(result.ProductFamilyName);
    }
  });

  const searchResults: ProductSearchResponse = {
    products: disableProductInclusionFilter
      ? aggregatedResults
      : config.agreementType === AgreementType.Modern
      ? filterModernProducts(aggregatedResults)
      : filterLegacyProducts(aggregatedResults),
    productFamiliesWithMoreResults,
  };

  return searchResults;
}

export async function searchProductsByFamily(
  request: ProductSearchByFamilyRequest,
  user: User,
  config: CatalogCallConfig,
  disableProductInclusionFilter: boolean,
  enableBlendedAutosuggest: boolean
): Promise<ProductSearchByFamilyResponse> {
  const { environment } = config;
  const headers = await getHeaders(config, user.tid, user.oid);
  const url = `${endpoints[environment]}/productFamilies/${
    enableBlendedAutosuggest ? 'all' : request.productFamilyName
  }/products`;
  const params = enableBlendedAutosuggest ? getBlendedSearchParams(config) : getParams(config);
  params.query = request.query;
  params.$skip = request.skip.toString();
  params.$top = request.top.toString();

  if (request.serviceFamily) params.serviceFamily = request.serviceFamily;

  const response = await axios.get<ProductSearchByFamilyResults>(url, { params, headers });
  const products = response.data.Products;
  return {
    hasMorePages: response.data.HasMorePages,
    products: disableProductInclusionFilter
      ? products
      : config.agreementType === AgreementType.Modern
      ? filterModernProducts(products)
      : filterLegacyProducts(products),
    productFamilyName: request.productFamilyName,
  };
}

export async function searchBlendedProducts(
  request: ProductSearchRequest,
  user: User,
  config: CatalogCallConfig,
  disableProductInclusionFilter: boolean
): Promise<ProductSearchResponse> {
  const { environment } = config;
  const headers = await getHeaders(config, user.tid, user.oid);
  const url = `${endpoints[environment]}/productFamilies/all/products`;
  const params = getBlendedSearchParams(config);
  params.query = request.query;
  params.$top = '25';

  const response = await axios.get<ProductSearchByFamilyResults>(url, { params, headers });
  const products = response.data.Products;
  const hasMorePages = response.data.HasMorePages;
  const searchResults: ProductSearchResponse = {
    products: disableProductInclusionFilter
      ? products
      : config.agreementType === AgreementType.Modern
      ? filterModernProducts(products)
      : filterLegacyProducts(products),
    productFamiliesWithMoreResults: hasMorePages ? [ProductFamily.All] : [],
  };

  return searchResults;
}
