import { PageRequest, ExternalReference } from 'services/types';

export interface GetProjectRequest extends PageRequest {
  filter: FilterType;
}

export enum FilterType {
  ExternalReferenceId = 'externalReferenceId',
  AccountId = 'accountId',
}

export interface Project {
  id: string;
  accountId: string;
  createdTimestamp: string;
  description: string | null;
  externalReference: ExternalReference;
  selfUrl: string;
  updatedTimestamp: string;
  version: number;
}
