import { ThemeProps } from 'styles';

export const favoritesCalloutStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    calloutMain: {
      borderRadius: '0px 0px 2px 2px',
    },
    closeButton: {
      height: 20,
      minWidth: 'unset',
      padding: 0,
      width: 20,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 16px',
      width: 236,
      '& > *:not(:first-child)': {
        marginTop: 16,
      },
    },
    reduceBottomPadding: {
      paddingBottom: 4,
    },
    createGroupButton: {
      color: colors.text,
      paddingBottom: 20,
      paddingLeft: 5,
      paddingTop: 20,
      '& i, &:hover, &:hover i, &:focus, &:focus i': {
        color: colors.textLink,
      },
    },
    groupButton: {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      minHeight: 40,
      padding: '0 8px',
      width: '100%',
      '&.is-disabled': {
        color: theme.palette.textDisabled,
      },
      '&, &:hover': {
        color: colors.textLink,
      },
      '&:hover': {
        backgroundColor: colors.backgroundCommon,
        border: `1px solid ${colors.backgroundCommon}`,
      },
      '&:after': {
        background: `linear-gradient(90deg, transparent 128px, ${colors.backgroundStandout} 152px)`,
        content: '""',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      },
      '&:hover:after': {
        background: `linear-gradient(90deg, transparent 128px, ${colors.backgroundCommon} 152px)`,
      },
    },
    groupButtonFlexContainer: {
      justifyContent: 'flex-start',
    },
    groupButtonLabel: {
      fontWeight: theme.fonts.fontWeights.regular,
      margin: 0,
      maxWidth: 148,
      overflow: 'hidden',
      paddingBottom: 4,
    },
    groupButtonSecondaryText: {
      alignItems: 'flex-end',
      display: 'flex',
      paddingBottom: 4,
      paddingLeft: 8,
      whiteSpace: 'nowrap',
      zIndex: 2,
      '&, &:hover': {
        color: colors.textTertiary,
      },
    },
    groupButtonTextContainer: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
    },
    groupButtons: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 128,
      left: -5,
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: 5,
      position: 'relative',
      width: '100%',
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    root: {
      borderRadius: '0px 0px 2px 2px',
      borderStyle: 'none',
      boxShadow: 'rgba(0,0,0,0.35) 0px 2px 18px -1px',
    },
    separator: {
      marginTop: 12,
    },
    title: {
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
  };
};
