export const calendarDropdownStyles = {
  dropdownCalendarContainer: {
    display: 'flex',
  },
  dropdownFlexItem: {
    flexGrow: 2,
  },
  calendarButtonContainer: {
    paddingTop: '29px',
    paddingBottom: 1,
  },
  flexSpacer: {
    flex: '1 0 8px',
    maxWidth: '8px',
  },
};
