import { ApolloError } from 'apollo-client';
import { NotificationType, TextBody } from 'components';
import { FailReasonBody } from 'features-apollo/components/dialogs/StatusDialogs';
import { QuoteLineItem } from 'features-apollo/quote/components/types';
import { defaultLanguage } from 'features/proposal/supported-languages';
import {
  ApprovalActionType,
  ApprovalActor,
  MessageType,
  ModernAgreementType,
  NonUserActor,
  Product,
  Quote,
  User,
} from 'generated/graphql';
import i18next from 'i18n';
import { Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { ApprovalActionType as ApprovalActionTypeOld } from 'services/approval/types';
import { oc } from 'ts-optchain';

export const DAY_IN_MILISECONDS = 1000 * 60 * 60 * 24;
export const MS_EMAIL = '@microsoft.com';
export const getBrowserLanguage = () => navigator.language || defaultLanguage;

// Inclusion-listed tenants used for Demo scenarios. We do NOT allow creation of new billing accounts on these tenants as they have billed usage
export const DEMO_TENANTS = ['42627cf1-45a4-41ab-98d6-ef176574b84d'];

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export const notificationMessages = {
  configurationOrDiscountRequiredMessage: (total: string) =>
    i18next.t(
      'There are {{total}} line items which need to be configured and/or discounted. Line items that require further action are indicated with a yellow border.',
      { total }
    ),
  brokenLineItemMultiple: (total: string, type: string) =>
    i18next.t('quote::{{total}} {{type}} have errors and need your attention.', { total, type }),
  brokenLineItemSingle: (total: string, type: string) =>
    i18next.t('quote::{{total}} {{type}} has errors and needs your attention.', { total, type }),
  brokenLineItemsMix: (
    itemACount: string,
    itemAName: string,
    itemBCount: string,
    itemBName: string
  ) =>
    i18next.t(
      'quote::{{itemACount}} {{itemAName}} and {{itemBCount}} {{itemBName}} have errors and need your attention.',
      { itemACount, itemAName, itemBCount, itemBName }
    ),
};

export const validGuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

export const APPROVAL_ACTION_MAP: EnumDictionary<ApprovalActionType, ApprovalActionTypeOld> = {
  [ApprovalActionType.Comment]: ApprovalActionTypeOld.Comment,
  [ApprovalActionType.Approve]: ApprovalActionTypeOld.Approve,
  [ApprovalActionType.Withdraw]: ApprovalActionTypeOld.Withdraw,
  [ApprovalActionType.Reject]: ApprovalActionTypeOld.Reject,
  [ApprovalActionType.Expire]: ApprovalActionTypeOld.Expire,
  [ApprovalActionType.Submit]: ApprovalActionTypeOld.Submitted,
};

export const MESSAGE_TYPE_MAP: EnumDictionary<MessageType, NotificationType> = {
  [MessageType.Standard]: NotificationType.standard,
  [MessageType.Info]: NotificationType.info,
  [MessageType.Error]: NotificationType.error,
  [MessageType.Warning]: NotificationType.warning,
  [MessageType.Action]: NotificationType.action,
};

export const getSelectedLineItem = (
  quote: Quote,
  lineItemId: string
): QuoteLineItem | undefined => {
  return quote.lineItems && quote.lineItems.find(item => item.id === lineItemId);
};

export const formatCurrency = (
  amount: string | number,
  minimumFractionDigits: number = 2,
  language?: string | null
) => {
  if (isNaN(+amount)) {
    return;
  }
  if (typeof amount === 'string') {
    amount = +amount;
  }
  const formattingOptions = { minimumFractionDigits };
  const lang = language && language !== null ? language.replace('_', '-') : defaultLanguage;
  return amount.toLocaleString(lang, formattingOptions);
};

export const removeEmailDomain = (email: string) => email.split('@')[0];
export const extractDomainFromEmail = (email?: string) => email && email.split('@')[1];

export const getValueOrUndefined = (value?: any) => {
  return value && value !== null ? value : undefined;
};

export const localFormatDateLong = (date: Date) => {
  return date.toLocaleDateString(getBrowserLanguage(), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const localFormatDateMonthYear = (date: Date) => {
  return date.toLocaleDateString(getBrowserLanguage(), {
    year: 'numeric',
    month: 'long',
  });
};

export const isPasses = (product: Product) =>
  oc(product)
    .productFamily('')
    .toLowerCase() === 'passes';

export const localFormatPercentageValue = (
  value: string | number,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
) => {
  if (typeof value === 'string') {
    value = +value;
  }

  const formattingOptions = { minimumFractionDigits, maximumFractionDigits, style: 'percent' };
  return Number(value / 100).toLocaleString(getBrowserLanguage(), formattingOptions);
};

export const isTermLineItem = (lineItem: QuoteLineItem) => {
  switch (lineItem.__typename) {
    case 'SimpleTermLineItem':
    case 'SapLineItem':
    case 'FinancingTermLineItem':
      return true;
    case 'MonetaryLineItem':
      return lineItem.isTerm;
    case 'EcifLineItem':
      return true;
    default:
      return false;
  }
};

/**
 * virtual line items are of the format 'agreement-{id}'
 * to obtain the actual agreement id without the identifier, we extract
 **/
export const getAgreementId = (agreementId: string) => {
  return agreementId.substring(agreementId.indexOf('-') + 1);
};

export enum gqlTermIdErrorCodes {
  InvalidTermIdFormat = '4049', // termId provided is not a guid
  TermIdDoesNotExist = '4508', // termId provided is a guid, but does not exist in agreements
  InvalidTermId = '4516', // termId provided is a guid, and exists in agreements, but not for this agreement type (APT vs MCA)
}

interface CustomTermsLineItem {
  productId: string;
  productName: string;
  termId: string;
}

// Extracts the Agreements service error from the GQL error
export const getGqlTermIdError = (error: ApolloError) => {
  const errorCode = oc(error).graphQLErrors[0].extensions.code('');
  const termErrorCode = oc(error).graphQLErrors[0].extensions.exception.errorData.code(
    ''
  ) as gqlTermIdErrorCodes;
  if (errorCode.toLowerCase() === 'bad request' && termErrorCode) {
    const termErrorMessage = oc(error).graphQLErrors[0].extensions.exception.errorData.message('');
    return {
      code: termErrorCode,
      message: termErrorMessage,
    };
  }
  return {};
};

// Returns the line item(s) that have a termId that matches the termId from the error
export const getInvalidTermNames = (customTerms: CustomTermsLineItem[], termId: string) => {
  const invalidTerms = customTerms.filter(lineItem => lineItem.termId === termId);
  const invalidTermIds: string[] = invalidTerms.map(invalidTerm => invalidTerm.productName);
  return invalidTermIds;
};

// Returns all custom term line items on the quote
export const getCustomTerms = (lineItems: QuoteLineItem[]) => {
  const customTerms: CustomTermsLineItem[] = [];
  lineItems.forEach((lineItem: QuoteLineItem) => {
    if (
      (lineItem.__typename === 'SimpleTermLineItem' ||
        lineItem.__typename === 'FinancingTermLineItem' ||
        lineItem.__typename === 'EcifLineItem') &&
      lineItem.product
    ) {
      customTerms.push({
        productId: lineItem.product.id,
        productName: lineItem.product.title,
        termId: oc(lineItem).supplementalTermReferenceData.value(''),
      });
    }
  });
  return customTerms;
};

// Finds the invalid Terms based on the Agreements service error
export const getAgreementsErrorTerms = (
  errorCode: gqlTermIdErrorCodes,
  message: string,
  lineItems: QuoteLineItem[]
) => {
  const customTerms = getCustomTerms(lineItems);
  let invalidTermNames: string[] = [];

  // There is almost never more than 1 custom term on a quote, so we can just return the single custom term on the quote
  if (customTerms.length === 1) {
    invalidTermNames.push(customTerms[0].productName);
    return invalidTermNames;
  }

  switch (errorCode) {
    case gqlTermIdErrorCodes.InvalidTermId: {
      // the validGuid regExpression from up top with the ^ and $ does not work here as that checks if the entire string is a guid, not if the string includes a guid
      const guidReg = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/;
      const regTermArray = guidReg.exec(message);
      if (regTermArray) {
        const termId = regTermArray[0];
        invalidTermNames = getInvalidTermNames(customTerms, termId);
      } else {
        invalidTermNames = customTerms.map(customTerm => customTerm.productName);
      }
      break;
    }
    case gqlTermIdErrorCodes.TermIdDoesNotExist: {
      // cannot use getInvalidTerms for this error as agreements does not return the incorrect termId for this error
      // so if there is more than 1 custom term, we cannot identify which one in this case
      invalidTermNames = customTerms.map(customTerm => customTerm.productName);
      break;
    }
    case gqlTermIdErrorCodes.InvalidTermIdFormat: {
      // invalid termId for this error is placed inside single quotes
      const firstQuote = message.indexOf(`'`);
      const secondQuote = message.indexOf(`'`, firstQuote + 1);
      const termId = message.slice(firstQuote + 1, secondQuote);

      invalidTermNames = getInvalidTermNames(customTerms, termId);
      if (!invalidTermNames.length) {
        invalidTermNames = customTerms.map(customTerm => customTerm.productName);
      }
      break;
    }
  }

  // This will almost always be an array of 1, but there is a very small chance that it is longer if there are multiple custom terms on the quote
  return invalidTermNames;
};

// Returns the error message to display when we fail to get the Agreement (Preview) due to an invalid TermId
export const getInvalidTermsErrorMessage = (
  title: string,
  errorCode: gqlTermIdErrorCodes,
  errorMessage: string,
  lineItems: QuoteLineItem[],
  t: i18next.TFunction
) => {
  const invalidTermNames: string[] = getAgreementsErrorTerms(errorCode, errorMessage, lineItems);
  let failReasons: string[] = [];
  if (invalidTermNames.length === 1) {
    failReasons.push(
      t(`error::The TermID added to the negotiated term "{{invalidTerm}}" is invalid.`, {
        invalidTerm: invalidTermNames[0],
      })
    );
  } else if (invalidTermNames.length === 2) {
    failReasons.push(
      t(
        `error::The TermID added for either the negotiated term "{{invalidTerm1}}" or the negotiated term "{{invalidTerm2}}" is invalid.`,
        {
          invalidTerm1: invalidTermNames[0],
          invalidTerm2: invalidTermNames[1],
        }
      )
    );
  } else {
    failReasons.push(t(`error::The TermID added for one of the negotiated terms is invalid.`));
  }

  return <FailReasonBody failedActionTitle={title} failReasons={failReasons} />;
};

type actor = NonUserActor | User | null | undefined;
export const makeAuthor = (actor: actor) => {
  if (actor) {
    if (actor.__typename === 'User') {
      return actor.mail ? removeEmailDomain(actor.mail) : undefined;
    }
    if (actor.__typename === 'NonUserActor') {
      return actor.name;
    }
  }
};

export const makeCreatedBy = (actor: ApprovalActor) => {
  if (actor) {
    if (actor.__typename === 'User') {
      return removeEmailDomain(actor.mail);
    }
    if (actor.__typename === 'NonUserActor') {
      return actor.name;
    }
  }
  return '';
};

// Determine if provided tenant is in demo list to prevent billing account creation and edit
export const isDemoTenant = (tenantId?: string) => {
  return !!DEMO_TENANTS.some(tenant => tenant === tenantId);
};

export const createDirectionsList = (directions: string[], classes: Record<string, string>) =>
  directions.map((direction: string, index: number) => {
    return (
      <div className={classes.listItem} data-automation-id="direction" key={`direction-${index}`}>
        <div className={classes.directionIndex}>
          <Icon iconName="CircleRing" styles={{ root: classes.icon }} />
          <TextBody addClass={classes.index}>{index + 1}</TextBody>
        </div>
        <TextBody addClass={classes.message}>{direction}</TextBody>
      </div>
    );
  });
/**
 * Gets the label to identify the signatory type in config card and/or details pane
 */
export const getAgreementSignatoryLabel = (type: ModernAgreementType, index?: number) => {
  let label;

  switch (type) {
    case ModernAgreementType.Customer:
      label = i18next.t('quote::{{type}} contact', { type });
      break;
    default:
      label = i18next.t('quote::{{type}} signatory', { type });
  }

  return index !== undefined ? `${label} ${index}` : label;
};
