import { Dialog, PrimaryButton, SecondaryButton, TextBody } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { ChangeFulfillmentCloudDialogStyles } from './ChangeFulfillmentCloudDialog.styles';

export interface ChangeFulfillmentCloudDialogProps {
  closeDialog: () => void;
  updateCloud: () => void;
}

type Props = ChangeFulfillmentCloudDialogProps &
  WithStyles<typeof ChangeFulfillmentCloudDialogStyles>;

const ChangeFulfillmentCloudDialogUnstyled: React.FC<Props> = ({
  classes,
  closeDialog,
  updateCloud,
}: Props) => {
  const { t } = useTranslation();

  const footerButtons = [
    <PrimaryButton
      dataAutomationId="ChangeFulfillmentCloudDialogConfirmButton"
      key="confirm-button"
      text={t('quote::Confirm')}
      onClick={updateCloud}
    />,
    <SecondaryButton
      dataAutomationId="ChangeFulfillmentCloudDialogCancelButton"
      key="cancel-button"
      text={t('quote::Cancel')}
      onClick={closeDialog}
    />,
  ];

  return (
    <Dialog
      closeDialog={closeDialog}
      dataAutomationId="ChangeFulfillmentCloudDialog"
      footerButtons={footerButtons}
      height={220}
      title={t('quote::Change fulfillment cloud?')}
      width={400}
    >
      <div>
        <TextBody addClass={classes.actionTextPadding}>
          {t(
            'quote::Changing the fulfillment cloud on this quote will delete the line items added on the quote. Do you want to change the fulfillment cloud?'
          )}
        </TextBody>
      </div>
    </Dialog>
  );
};

export const ChangeFulfillmentCloudDialog = withStyles(ChangeFulfillmentCloudDialogStyles)(
  ChangeFulfillmentCloudDialogUnstyled
);
