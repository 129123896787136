import { ApolloQueryResult } from 'apollo-client';
import { meplaHistory } from 'createHistory';
import { SortQuery } from 'features/proposal/components';
import { RepairReferral } from 'features/proposal/components/ReferralSummaryList/Queries';
import { getMarket } from 'features/proposal/selectors';
import {
  defaultMarket,
  getMarketByCountryCode,
  getMarketByCountryName,
  isReferralMarket,
  Market,
} from 'features/proposal/supported-markets';
import { CrmValidity } from 'features/proposal/types';
import { crmIDRegExp, isProposalNameInvalid } from 'features/proposal/utils';
import { AzureType, Referral, UserGroup } from 'generated/graphql';
import i18next from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { routes } from 'routes';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';
import { oc } from 'ts-optchain';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { Fail } from '../Fail';
import { Processing } from '../Processing';
import { CopyReferral } from './CopyReferral/CopyReferral';
import { CreateReferralView, ReferralOrganization } from './CreateReferralView';
import { CreateClaim, CreateReferral, GetCrmForReferralDialog } from './Queries';

export const dialogDimensions = {
  height: 535,
  width: 395,
};

export interface RefetchReferrals {
  query: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    variables?: Record<string, any> | undefined
  ) => Promise<
    ApolloQueryResult<{
      getReferrals: Referral[];
    }>
  >;
  variables: {
    query: string;
    options: { referralFilter: string; sort: SortQuery };
  };
}

export interface CreateReferralDialogProps {
  market?: Market;
  initialCrmId?: string;
  refetchReferrals?: RefetchReferrals;
}

export enum ItemType {
  referral = 'referral',
  claim = 'claim',
}
enum DialogState {
  processing = 'processing',
  creating = 'creating',
  copying = 'copying',
  tryAgain = 'tryAgain',
  fail = 'fail',
}

//TODO:Delete this when we can get market somewhere outside of redux
const mapStateToProps = (state: RootState) => {
  return {
    market: getMarket(state),
  };
};

export type Props = CreateReferralDialogProps & ReturnType<typeof mapStateToProps>;

export interface ReferralDialogSalesAccount {
  id: string;
  address: { country: string };
}

export interface ReferralDialogCrmLead {
  validity: CrmValidity;
  title: string | null;
  salesAccount?: ReferralDialogSalesAccount;
}

export const getCrmError = (
  t: i18next.TFunction,
  crmLead?: ReferralDialogCrmLead,
  crmCallFailed?: boolean
) => {
  if (crmCallFailed) {
    return t('A network error occured, please try again');
  }
  if (!crmLead) {
    return t('CRM ID not found');
  }
  if (crmLead.validity === CrmValidity.MissingSalesAccount) {
    return t('Missing sales account, please update in CRM');
  }
  if (crmLead.validity === CrmValidity.InvalidCrmId) {
    return t('Invalid CRM ID');
  }
};

export const CreateReferralDialog: React.FC<Props> = (props: Props) => {
  const [itemType, setItemType] = React.useState(ItemType.referral);
  const [name, setName] = React.useState('');
  const [crmId, setCrmId] = React.useState<undefined | string>(props.initialCrmId || '');
  const [lastCrmIdVerified, setLastCrmIdVerified] = React.useState<string | undefined>();
  const [selectedReferralType, setSelectedReferralType] = React.useState<AzureType>(
    AzureType.Trial
  );
  const [selectedMarket, setSelectedMarket] = React.useState<Market | undefined>(() => {
    if (!isReferralMarket(props.market)) {
      return defaultMarket;
    }
    return props.market;
  });
  const [userChangedTheMarket, setUserChangedTheMarket] = React.useState(false);
  const [nameIsAutoPopulated, setNameIsAutoPopulated] = React.useState(false);

  const [unsupportedMarketError, setUnsupportedMarketError] = React.useState(false);
  const [selectedOrganization, setSelectedOrganization] = React.useState<
    ReferralOrganization | undefined
  >(undefined);
  const [dialogState, setDialogState] = React.useState(DialogState.creating);

  const [
    createReferral,
    { loading: createReferralLoading, data: createReferralResponse, error: createReferralError },
  ] = useMutation<{
    createReferral: { id: string; etag: string; referralLink: string; assignedTo: UserGroup };
  }>(CreateReferral);

  const [
    createClaim,
    { loading: createClaimLoading, data: createClaimResponse, error: createClaimError },
  ] = useMutation<{
    createClaim: { id: string };
  }>(CreateClaim);

  const [
    getCrmId,
    { loading: crmLoading, error: crmCallFailed, data: crmLeadResponse },
  ] = useLazyQuery<{
    getCRMLead: ReferralDialogCrmLead;
  }>(GetCrmForReferralDialog);

  const [repairReferral, { loading: isRepairProcessing }] = useMutation<{
    repairReferral: { id: string; etag: string };
  }>(RepairReferral);

  const { initialCrmId, refetchReferrals } = props;
  const { t } = useTranslation();

  const createReferralData = oc(createReferralResponse).createReferral();
  const createClaimData = oc(createClaimResponse).createClaim();
  const crmLeadData = oc(crmLeadResponse).getCRMLead();
  const context = React.useContext(DialogContext);
  const isReferral = itemType === ItemType.referral;
  const isClaim = itemType === ItemType.claim;
  let createLoading = createReferralLoading;
  let createError = createReferralError;
  let createdDataExists = !!createReferralData;
  if (isClaim) {
    createLoading = createClaimLoading;
    createError = createClaimError;
    createdDataExists = !!createClaimData;
  }

  const successfullyCreated =
    !createLoading &&
    !createError &&
    !!createdDataExists &&
    (dialogState === DialogState.processing || dialogState === DialogState.copying);

  // We have to wait a second for quote to update the search index to get the new referral
  // we created in the search results
  React.useEffect(() => {
    const refetchTimer = setTimeout(() => {
      if (successfullyCreated && !!refetchReferrals) {
        refetchReferrals.query({ variables: refetchReferrals.variables });
        meplaHistory.push(routes.home.referrals.active);
        isClaim && context.closeDialog();
      }
    }, 1000);
    if (successfullyCreated && !refetchReferrals && isClaim) {
      context.closeDialog();
    } else if (successfullyCreated && isReferral) {
      setDialogState(DialogState.copying);
    }
    return () => clearTimeout(refetchTimer);
  }, [context, successfullyCreated, refetchReferrals, isClaim, isReferral]);

  React.useEffect(() => {
    if (initialCrmId && crmIDRegExp.test(initialCrmId)) {
      setLastCrmIdVerified(initialCrmId);
      getCrmId({ variables: { id: initialCrmId.trim() } });
    }
  }, [initialCrmId, getCrmId]);

  const crmLeadCountry = oc(crmLeadData).salesAccount.address.country();
  const nameFromCrm = oc(crmLeadData).title();

  const lastVerifiedIsSameAsCurrent = crmId === lastCrmIdVerified;
  const shouldBeCheckedForErrors = lastVerifiedIsSameAsCurrent && !crmLoading;
  let crmError = shouldBeCheckedForErrors
    ? getCrmError(t, crmLeadData, !!crmCallFailed)
    : undefined;

  const isValidCrmId = !!(
    !crmLoading &&
    !crmCallFailed &&
    !crmError &&
    selectedMarket &&
    crmId &&
    crmLeadData &&
    lastVerifiedIsSameAsCurrent &&
    crmIDRegExp.test(crmId)
  );
  // We want to use the sales account country as a hint to pick the market
  // user's choice would always override it.
  React.useEffect(() => {
    if (crmLeadCountry && isValidCrmId) {
      const market =
        getMarketByCountryName(crmLeadCountry) || getMarketByCountryCode(crmLeadCountry);
      if (
        !userChangedTheMarket &&
        market !== selectedMarket &&
        isReferralMarket(market as Market)
      ) {
        setSelectedMarket(market as Market);
      }
    }
  }, [crmLeadCountry, userChangedTheMarket, selectedMarket, isValidCrmId]);

  React.useEffect(
    () => {
      if (nameFromCrm && isValidCrmId && (!name.trim() || nameIsAutoPopulated)) {
        setName(nameFromCrm);
        setNameIsAutoPopulated(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nameFromCrm, isValidCrmId, crmId]
  );

  //We want to show unsupported market error only if the user did not selected the market itself
  React.useEffect(() => {
    if (crmLeadData && crmId) {
      if (crmLeadData.validity === CrmValidity.UnsupportedSalesAccountMarket) {
        !userChangedTheMarket && setUnsupportedMarketError(true);
      } else {
        setUnsupportedMarketError(false);
      }
    }
  }, [crmLeadData, userChangedTheMarket, crmId, isReferral]);

  const onVerifyCrmId = () => {
    if (crmId) {
      getCrmId({ variables: { id: crmId.trim() } });
      setLastCrmIdVerified(crmId);
    }
  };

  const create = () => {
    if (isClaim && !!selectedOrganization) {
      loggerService.log({ name: 'Dialog - create claim' });
      createClaim({
        variables: {
          input: {
            name: name.trim(),
            crmId: lastCrmIdVerified && lastCrmIdVerified.trim(),
            organization: {
              organizationId: selectedOrganization.orgId,
              accountId: selectedOrganization.accountId,
            },
          },
        },
      });
    } else if (isReferral) {
      loggerService.log({ name: 'Dialog - create referral' });
      createReferral({
        variables: {
          input: {
            name: name.trim(),
            crmId: lastCrmIdVerified && lastCrmIdVerified.trim(),
            azureType: selectedReferralType,
            market: selectedMarket,
          },
        },
      });
    }
    setDialogState(DialogState.processing);
  };

  let canCreate =
    (isValidCrmId || !crmId) &&
    name.trim() &&
    !isProposalNameInvalid(name) &&
    !!selectedReferralType;

  if (isReferral) {
    canCreate = canCreate && !unsupportedMarketError;
  }

  if (isClaim) {
    canCreate = canCreate && !!selectedOrganization;
  }

  const errorIsFromCatalog =
    createError &&
    createError.graphQLErrors.find(
      error => error && error.extensions && error.extensions.code === 'CANNOT_FIND_AVAILABILITY'
    );
  const error = !createLoading && !!createError && dialogState === DialogState.processing;
  if (error) {
    if (errorIsFromCatalog) {
      setDialogState(DialogState.fail);
    } else setDialogState(DialogState.tryAgain);
  }

  const creation = (
    <CreateReferralView
      canCreateReferral={!!canCreate}
      create={create}
      crmIdField={{
        set: setCrmId,
        errorMessage: crmError,
        onVerifyCrmId: onVerifyCrmId,
        isVerified: isValidCrmId,
        lastVerified: lastCrmIdVerified,
        value: crmId,
      }}
      crmLead={{ loading: crmLoading, callFailed: !!crmCallFailed, data: crmLeadData }}
      itemTypeField={{ set: setItemType, value: itemType }}
      marketField={{
        setMarket: setSelectedMarket,
        setUnsupportedMarketError: setUnsupportedMarketError,
        setUserChangedTheMarket: setUserChangedTheMarket,
        unsupportedMarketError: unsupportedMarketError,
        userChangedTheMarket: userChangedTheMarket,
        value: selectedMarket,
      }}
      nameField={{
        set: setName,
        value: name,
        setNameIsAutoPopulated,
        errorMessage:
          name && isProposalNameInvalid(name)
            ? t('The following characters are not allowed: ! @ # % ^ [ ] . / < >')
            : undefined,
      }}
      organizationSearch={{
        resetSelectedOrganizations: () => setSelectedOrganization(undefined),
        setSelectedOrganization,
        selectedOrganization,
      }}
      referralTypeField={{ value: selectedReferralType, set: setSelectedReferralType }}
    />
  );

  const onRetry = () => {
    repairReferral({
      variables: {
        input: {
          id: oc(createReferralData).id(''),
          etag: oc(createReferralData).etag(''),
        },
      },
    });
  };

  const copyReferral = (
    <CopyReferral
      error={oc(createReferralData).assignedTo() !== UserGroup.Customer}
      processing={isRepairProcessing}
      referralLink={oc(createReferralData).referralLink('')}
      referralName={name.trim()}
      onRetry={onRetry}
    />
  );
  let currentStep;
  switch (dialogState) {
    case DialogState.processing:
      currentStep = <Processing height={267} message1={t('Processing')} width={426} />;
      break;
    case DialogState.creating:
      currentStep = creation;
      break;
    case DialogState.copying:
      currentStep = copyReferral;
      break;
    case DialogState.tryAgain:
      currentStep = (
        <Fail
          closeDialog={context.closeDialog}
          height={267}
          message={isReferral ? t('Unable to create referral') : t('Unable to create claim')}
          width={426}
          onTryAgainClick={() => create()}
        />
      );
      break;
    case DialogState.fail:
      currentStep = (
        <Fail
          closeDialog={context.closeDialog}
          height={267}
          message={isReferral ? t('Unable to create referral') : t('Unable to create claim')}
          width={426}
        />
      );
      break;
  }

  return currentStep;
};
export const CreateReferralDialogConnected = connect(mapStateToProps)(CreateReferralDialog);

export const openCreateReferralDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  refetchReferrals?: RefetchReferrals,
  crmIdFromLink?: string
) => {
  const dialogProps: DialogProps = {
    providedDialog: (
      <CreateReferralDialogConnected
        initialCrmId={crmIdFromLink}
        refetchReferrals={refetchReferrals}
      />
    ),
  };
  context.openDialog(dialogProps);
};
