import {
  ComboBoxWithProgress,
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  TextboxStandard,
  TextTitle,
} from 'components/ions';
import { sharedStyles } from 'features/proposal/components/Dialogs/shared.styles';
import { validateEmailFormat } from 'features/proposal/utils';
import { User } from 'features/user/types';
import moment from 'moment';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { Contact } from 'services/crm/types';
import { Notification } from 'services/notification/types';
import { Proposal } from 'services/proposal/types';
import { DialogContext, ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  comboBoxContainer: { width: '100%' },
});

interface OwnProps {
  assignedTo: string;
  crmContact?: Contact;
  customerName: string;
  displayName: string;
  proposal: Proposal;
  shareLinkBusinessStore: string;
  user: User;
  sendEmail: (emailNotification: Notification) => void;
  hasCRMContact: boolean;
}

type Props = OwnProps & WithStyles<typeof styles>;

const SelectEmailViewFeature: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  // When listening to onChange, the entered or selected value does not stay in the ComboBox.
  // This requires to pass the user's value through the text property in the ComboBox.
  // To distinguish between just an entered email address and a valid one,the state has a valid property.
  const [userSelection, setUserSelection] = React.useState<{
    emailAddress: string;
    valid: boolean;
  }>();

  const disableButton = !userSelection || !userSelection.valid;
  const errorMessageText = props.hasCRMContact
    ? t('error::Enter valid email address or select one from dropdown.')
    : t('error::Enter valid email address.');

  const verifyEnteredEmail = (value?: string) => {
    if (!value) {
      setUserSelection(undefined);
    } else {
      const validEmailFormat = validateEmailFormat(value);
      if (!validEmailFormat) {
        setUserSelection({ emailAddress: value, valid: false });
        setErrorMessage(errorMessageText);
      } else {
        setUserSelection({ emailAddress: value, valid: true });
      }
    }
  };

  const onComboBoxChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    setErrorMessage('');
    if (option) {
      setUserSelection({ emailAddress: option.text, valid: true });
    } else {
      verifyEnteredEmail(value);
    }
  };

  const onTextBoxChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setErrorMessage('');
    verifyEnteredEmail(value);
  };

  const businessStoreUrl = props.shareLinkBusinessStore.replace('{quoteId}', props.proposal.id);

  const onClick = () => {
    if (
      userSelection &&
      userSelection.valid &&
      props.customerName &&
      props.proposal.header.expirationDate
    ) {
      const emailNotification: Notification = {
        notificationTemplate: 'ShareQuote',
        toEmailAddresses: [userSelection.emailAddress],
        carbonCopyEmailAddresses:
          props.user.email && props.user.email !== userSelection.emailAddress
            ? [props.user.email]
            : [],
        Body: {
          assignedTo: props.assignedTo,
          businessStoreUrl,
          customerName: props.customerName,
          expirationDate: moment(props.proposal.header.expirationDate).format('YYYY-MM-DD'),
          locale: props.proposal.header.pricingContext.languages || 'en-US',
          quoteId: props.proposal.id,
          quoteName: props.proposal.header.name,
          salesAgentDisplayName: props.displayName,
        },
      };
      props.sendEmail(emailNotification);
    }
  };

  const Header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="selectEmailDialog"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Email Quote')}</TextTitle>
    </DialogHeader>
  );

  const Body = (
    <div className={classes.body}>
      {props.hasCRMContact ? (
        <ComboBoxWithProgress
          allowFreeform
          autoComplete="on"
          containerClass={classes.comboBoxContainer}
          errorMessage={errorMessage}
          id="emailAddress"
          label={t('quote::Email Address')}
          options={
            props.crmContact
              ? [{ key: props.crmContact.Id, text: props.crmContact.PrimaryEmail }]
              : []
          }
          progressHidden={!!props.crmContact}
          text={userSelection && userSelection.emailAddress}
          onChange={onComboBoxChange}
        />
      ) : (
        <TextboxStandard
          dataAutomationId="emailAddress"
          errorMessage={errorMessage}
          label={t('quote::Email Address')}
          onChange={onTextBoxChange}
        />
      )}
    </div>
  );

  const Footer = (
    <div className={`${classes.footer} ${classes.buttonRightAlign}`}>
      <PrimaryButton
        dataAutomationId="sendEmailButton"
        disabled={disableButton}
        text={t('quote::Send Email')}
        onClick={onClick}
      />
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={264}
      width={448}
    />
  );
};

export const SelectEmailView = withStyles(styles)(SelectEmailViewFeature);
