import { ThemeProps } from 'styles';

export const withdrawButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    withdraw: {
      backgroundColor: colors.warning,
      color: 'black',
      '&:hover': {
        backgroundColor: colors.warningHover,
        color: 'black',
      },
      '&:active': {
        backgroundColor: colors.warningActive,
        color: 'black',
      },
      '& i': {
        color: 'black',
      },
      '&:disabled': {
        backgroundColor: colors.backgroundDivider,
        color: colors.textPrimaryDisabled,
        '& i': {
          color: colors.textPrimaryDisabled,
        },
      },
    },
  };
};
