import { mergeClassNames } from 'components';
import { ActionType, IconButtonError, LinkEmail, TeachingBubble, TextBody } from 'components/ions';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { FinderItem, FinderItemType } from 'features-apollo/quote/components/Finder/DragAndDrop';
import { isUploadAgreementDisabled } from 'features-apollo/quote/components/Finder/utils';
import { IconNames } from 'icons/src';
import { CommandBarButton, DirectionalHint, Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Position,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ProductFamily } from 'services/catalog/types';
import { ThemeProps } from 'styles';
import { useTheme } from 'theming';
import { oc } from 'ts-optchain';

import { KeyCodes } from '@uifabric/utilities';

import {
  additionalDragStyles,
  buttonContainerStyle,
  buttonStyle,
  combinedItems,
  draggableButtonStyles,
  dropStyle,
} from './DraggableButton.styles';

export interface DraggableProps {
  isSelected?: boolean;
  isDragging?: boolean;
  isGhosting?: boolean;
  item: FinderItem;
  isFavoritesButton?: boolean;
  disabled?: boolean;
}

/**
 * Draggable Button Props
 *
 * Basic button attributes:
 * @prop {string} ariaLabel - aria label for button
 * @prop {FinderItem} item - finder item displayed in button
 * @prop {number} index - index of button in list
 *
 * Style class:
 * @prop {string} mainClass - additional style class applied to command button
 *
 * Ids:
 * @prop {string} id - id of button
 * @prop {string} destinationId - id of list button can drag to
 * @prop {string} sourceId - id of list button is in
 * @prop {string} dataAutomationId - automation test id
 *
 * Button states:
 * @prop {boolean} disabled - button is disabled if proposal is readonly or product is at max quantity
 * @prop {boolean} isFavoritesButton - button is in the favorites or search list
 * @prop {boolean} preventKeyClick - mutliple buttons have been selected so we don't allow add to proposal by spacebar/enter key
 *
 * Drag and drop:
 * @prop {boolean} isSelected - is the button selected for drag
 * @prop {boolean} isGhosting - is the button ghosting
 * @prop {number} selectionCount - how many button have we selected
 * @prop {Position} dropCoordinates - where does the button go when it is dropped
 * @prop {boolean} isDragEvent - indicates when a drag is occurring (not necessarily on this specific button, but in general)
 *
 * Interactions:
 *  @prop {func} toggleSelection - change selection in list
 *  @prop {func} toggleSelectionInGroup - change selection in selection list
 *  @prop {func} multiSelectTo - select multipl buttons
 *  @prop {func} onKeyDown - keyboard control behavior
 *  @prop {func} onClick - button keyboard/mouse click behavior (add item to list)
 */

export interface DraggableButtonProps {
  ariaLabel?: string;
  item: FinderItem;
  index: number;
  mainClass?: string;
  id?: string;
  destinationId?: string;
  sourceId?: string;
  dataAutomationId?: string;
  disabled?: boolean;
  isFavoritesButton?: boolean;
  preventKeyClick: boolean;
  isSelected: boolean;
  isGhosting: boolean;
  selectionCount: number;
  dropCoordinates?: Position;
  isDragEvent?: boolean;
  toggleSelection?: (taskId: string) => void;
  toggleSelectionInGroup?: (taskId: string) => void;
  multiSelectTo?: (taskId: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<ActionType>) => void;
  onClick: (
    event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>
  ) => boolean | void;
  error?: { errorTitle: string; errorPrimary: string; errorSecondary: string };
  linkText?: string;
  link?: string;
  target?: string;
}

const primaryButton = 0;

type Props = DraggableButtonProps & WithStyles<typeof draggableButtonStyles>;

const DraggableButtonUnstyled: React.FC<Props> = (props: Props) => {
  const [infoOpen, setInfoOpen] = React.useState(false);

  const {
    ariaLabel,
    item,
    index,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
    onClick,
    onKeyDown,
    isFavoritesButton,
    disabled,
    preventKeyClick,
    dropCoordinates,
    isDragEvent,
    isSelected,
    isGhosting,
    selectionCount,
    id,
    destinationId,
    dataAutomationId,
    mainClass,
    classes,
  } = props;
  const theme: ThemeProps = useTheme() as ThemeProps;
  const { t } = useTranslation();

  const { activeQuote } = React.useContext(ActiveQuoteContext);

  const isUploadAgreementItem = props.item.type === FinderItemType.UploadItem;

  let isTermInList =
    activeQuote &&
    activeQuote.lineItems.some(
      lineItem =>
        item.productIdentifier && oc(lineItem).product.id('') === item.productIdentifier.productId
    );

  if (isUploadAgreementItem) {
    isTermInList = !isUploadAgreementDisabled(activeQuote);
  }

  const isTerm =
    (item.productIdentifier && item.productIdentifier.productFamily) ===
      ProductFamily.NegotiatedTerms || isUploadAgreementItem;

  const makeTermDisabled = !isTermInList && isTerm;

  const [isTermDragDisabled, setIsTermDragDisabled] = React.useState(
    (isTermInList && isTerm) || disabled
  );

  React.useEffect(() => {
    makeTermDisabled && setIsTermDragDisabled(false);
  }, [makeTermDisabled]);

  // Determines if the platform specific toggle selection in group key was used
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const wasToggleInSelectionGroupKeyUsed = (event: any) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (event: React.KeyboardEvent<ActionType>) => event.shiftKey;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const performAction = (event: any) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup && toggleSelectionInGroup(item.id);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo && multiSelectTo(item.id);
      return;
    }

    toggleSelection && toggleSelection(item.id);
  };

  // Using onClick as it will be correctly
  // preventing if there was a drag
  const onClickWrapper = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.defaultPrevented || event.button !== primaryButton || disabled) {
      return;
    }

    // marking the event as used
    event.preventDefault();

    if (event.shiftKey || event.ctrlKey) {
      performAction(event);
    } else {
      setIsTermDragDisabled(isTerm);
      onClick(event);
    }
  };

  const onKeyDownWrapper = (event: React.KeyboardEvent<ActionType>) => {
    if (event.keyCode === KeyCodes.enter || event.keyCode === KeyCodes.space) {
      if (preventKeyClick) {
        event.preventDefault();
        return;
      }
      setIsTermDragDisabled(isTerm);
      onClick(event);
    } else {
      onKeyDown && onKeyDown(event);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTouchEnd = (event: any) => {
    if (event.defaultPrevented) {
      return;
    }

    // marking the event as used
    event.preventDefault();
    toggleSelectionInGroup && toggleSelectionInGroup(item.id);
  };

  const stackedItemIllusion = () => {
    const maxItemsStackable = Math.min(selectionCount, 4);
    const left = 4;
    const top = -18;
    const items = [];
    for (let i = 1; i < maxItemsStackable; i++) {
      const zIndex = selectionCount - i;
      items.push(
        <div key={zIndex} style={combinedItems(left * i, top * i - 2 * i, zIndex, theme)} />
      );
    }
    return items;
  };

  const uploadAgreementIcon = (isPlaceholder: boolean) => {
    return (
      isUploadAgreementItem && (
        <Icon
          className={isPlaceholder ? classes.placeholderIcon : classes.icon}
          iconName={IconNames.CloudUpload}
        />
      )
    );
  };

  const placeholder = (containerClassName: string, textClassName: string) => (
    <div className={containerClassName}>
      {uploadAgreementIcon(true)}
      <TextBody addClass={textClassName}> {item.itemText} </TextBody>
    </div>
  );

  const errorPlaceholder = (containerClassName: string, textClassName: string) => (
    <div className={containerClassName}>
      <TextBody addClass={textClassName}> {item.itemText} </TextBody>
      <div className={classes.errorBadgeContainer}>
        <IconButtonError
          iconName="errorBadge"
          id={props.target}
          onClick={() => setInfoOpen(!infoOpen)}
        />
      </div>
    </div>
  );
  const isDragDisabled = isTermDragDisabled || disabled;
  if (props.target) {
    return (
      <Draggable
        disableInteractiveElementBlocking={true}
        draggableId={item.id}
        index={index}
        isDragDisabled={isDragDisabled}
        key={item.id}
      >
        {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => {
          return (
            <div
              ref={draggableProvided.innerRef}
              style={buttonContainerStyle({
                isDragging: draggableSnapshot.isDragging,
                isSelected,
                isGhosting,
                item,
                isFavoritesButton,
                disabled,
              })}
              {...draggableProvided.dragHandleProps}
              onClick={onClickWrapper}
              onKeyDown={onKeyDownWrapper}
              onTouchEnd={onTouchEnd}
            >
              {!isDragEvent || (isDragEvent && draggableSnapshot.isDragging) ? (
                <div
                  {...draggableProvided.draggableProps}
                  className={mergeClassNames([classes.focus, classes.nonInteractiveErrorContainer])}
                  style={dropStyle(
                    {
                      ...draggableProvided.draggableProps.style,
                    },
                    draggableSnapshot,
                    dropCoordinates,
                    destinationId
                  )}
                >
                  {uploadAgreementIcon(false)}
                  <CommandBarButton
                    ariaDescription={item.itemText}
                    ariaLabel={ariaLabel}
                    className={mergeClassNames([classes.main, mainClass])}
                    data-automation-id={dataAutomationId}
                    disabled={disabled}
                    id={id}
                    style={buttonStyle(
                      {
                        isDragging: draggableSnapshot.isDragging,
                        isSelected,
                        isGhosting,
                        item,
                        isFavoritesButton,
                        disabled,
                      },
                      theme
                    )}
                    styles={draggableSnapshot.isDragging ? additionalDragStyles : undefined}
                    text={item.itemText}
                  />
                  <div className={classes.errorBadgeContainer}>
                    <IconButtonError
                      iconName="errorBadge"
                      id={props.target}
                      onClick={() => setInfoOpen(!infoOpen)}
                    />
                    {infoOpen && (
                      <TeachingBubble
                        closeButtonAriaLabel={t('quote::Close')}
                        directionalHint={DirectionalHint.rightCenter}
                        error={true}
                        headline={(props.error && props.error.errorTitle) || ''}
                        maxWidth={325}
                        target={`#${props.target}`}
                        onDismiss={() => setInfoOpen(false)}
                      >
                        <TextBody>{props.error && props.error.errorPrimary}</TextBody>
                        <TextBody>
                          {props.error && props.error.errorSecondary}
                          <LinkEmail
                            addClass={classes.teachingBubbleLinkText}
                            displayText={props.linkText}
                            email={props.link}
                          />
                          .
                        </TextBody>
                      </TeachingBubble>
                    )}
                  </div>
                </div>
              ) : (
                errorPlaceholder(classes.nonInteractiveErrorContainer, classes.nonInteractiveText)
              )}
              {draggableSnapshot.isDragging &&
                errorPlaceholder(classes.placeholderContainer, classes.placeholderText)}
            </div>
          );
        }}
      </Draggable>
    );
  }

  return (
    <Draggable
      disableInteractiveElementBlocking={true}
      draggableId={item.id}
      index={index}
      isDragDisabled={isDragDisabled}
      key={item.id}
    >
      {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => {
        const shouldShowSelection: boolean = draggableSnapshot.isDragging && selectionCount > 1;
        return (
          <div
            ref={draggableProvided.innerRef}
            style={buttonContainerStyle({
              isDragging: draggableSnapshot.isDragging,
              isSelected,
              isGhosting,
              item,
              isFavoritesButton,
              disabled,
            })}
            {...draggableProvided.dragHandleProps}
            onClick={onClickWrapper}
            onKeyDown={onKeyDownWrapper}
            onTouchEnd={onTouchEnd}
          >
            {!isDragEvent || (isDragEvent && draggableSnapshot.isDragging) ? (
              <div
                {...draggableProvided.draggableProps}
                className={classes.focus}
                style={dropStyle(
                  {
                    ...draggableProvided.draggableProps.style,
                  },
                  draggableSnapshot,
                  dropCoordinates,
                  destinationId
                )}
              >
                {uploadAgreementIcon(false)}
                <CommandBarButton
                  ariaDescription={item.itemText}
                  ariaLabel={ariaLabel}
                  className={mergeClassNames([classes.main, mainClass])}
                  data-automation-id={dataAutomationId}
                  disabled={disabled}
                  id={id}
                  style={buttonStyle(
                    {
                      isDragging: draggableSnapshot.isDragging,
                      isSelected,
                      isGhosting,
                      item,
                      isFavoritesButton,
                      disabled,
                    },
                    theme
                  )}
                  styles={draggableSnapshot.isDragging ? additionalDragStyles : undefined}
                  text={item.itemText}
                />
                {shouldShowSelection && (
                  <div>
                    {stackedItemIllusion()}
                    <div className={classes.selectionCount}>{selectionCount}</div>
                  </div>
                )}
              </div>
            ) : (
              placeholder(classes.nonInteractiveContainer, classes.nonInteractiveText)
            )}
            {draggableSnapshot.isDragging &&
              placeholder(classes.placeholderContainer, classes.placeholderText)}
          </div>
        );
      }}
    </Draggable>
  );
};
export const DraggableButton = withStyles(draggableButtonStyles)(
  DraggableButtonUnstyled
) as React.FC<DraggableButtonProps>;
