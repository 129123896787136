import gql from 'graphql-tag';

export const ParticipantsFragment = gql`
  fragment participantsFragment on ModernAgreementParticipant {
    id
    type
    firstName
    lastName
    email
    statusDate
  }
`;

export const UpdateModernAgreementParticipants = gql`
  mutation UpdateModernAgreementParticipants(
    $agreementId: String!
    $accountId: String!
    $organizationId: String!
    $participants: [UpdateParticipantInput!]!
  ) {
    updateModernAgreementParticipants(
      input: {
        agreementId: $agreementId
        accountId: $accountId
        organizationId: $organizationId
        participants: $participants
      }
    ) {
      participants {
        ...participantsFragment
      }
    }
  }
  ${ParticipantsFragment}
`;

export const AddModernAgreementParticipants = gql`
  mutation AddModernAgreementParticipants(
    $agreementId: String!
    $quoteId: String!
    $participants: [ParticipantInput!]!
  ) {
    addModernAgreementParticipants(
      input: { agreementId: $agreementId, quoteId: $quoteId, participants: $participants }
    ) {
      participants {
        ...participantsFragment
      }
    }
  }
  ${ParticipantsFragment}
`;

export const ModernAgreementForQuoteFragment = gql`
  fragment modernAgreementForQuote on ModernAgreementActual {
    id
    startEffectiveDate
    endDateEffectiveDate
    documentFileName
    documentDisplayUri
    documentFileName
    customerId
    participants {
      ...participantsFragment
    }
  }
  ${ParticipantsFragment}
`;

export const PageInfoFragment = gql`
  fragment pageInfo on PageInfo {
    __typename
    hasNextPage
    cursor
  }
`;

/**
 * Query to get agreements
 */
export const GET_MODERN_AGREEMENTS = gql`
  query getModernAgreements($accountId: String!, $agreementType: AgreementTypes!) {
    getModernAgreements(accountId: $accountId, agreementType: $agreementType) {
      edges {
        cursor
        node {
          ...modernAgreementForQuote
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${ModernAgreementForQuoteFragment}
  ${PageInfoFragment}
`;

/**
 * Query to get active agreements
 */
export const GET_MODERN_ACTIVE_AGREEMENTS = gql`
  query getModernActiveAgreements($accountId: String!, $agreementType: AgreementTypes!) {
    getModernActiveAgreements(accountId: $accountId, agreementType: $agreementType) {
      edges {
        cursor
        node {
          ...modernAgreementForQuote
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${ModernAgreementForQuoteFragment}
  ${PageInfoFragment}
`;

export const CreateModernAgreement = gql`
  mutation CreateModernAgreement($input: CreateModernAgreementInput!) {
    createModernAgreement(input: $input) {
      number
      customerId
      startEffectiveDate
      endEffectiveDate
      documentDisplayUri
      documentFileName
    }
  }
`;

export const CreateOrUpdateModernAgreement = gql`
  mutation CreateOrUpdateModernAgreement(
    $agreementId: String
    $input: CreateOrUpdateModernAgreementInput!
  ) {
    createOrUpdateModernAgreement(agreementId: $agreementId, input: $input) {
      number
      customerId
      startEffectiveDate
      endEffectiveDate
      documentDisplayUri
      documentFileName
    }
  }
`;

/* Add when GQL goes to prod
  filters {
              key
              values
            }

*/
