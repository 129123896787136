import { ActionType, ButtonSharedProps, IconButton } from 'components/ions/Buttons';
import { mergeClassNames } from 'components/utilities/mergeClassNames';
import { SearchResultProduct } from 'features/catalog';
import { favoriteButtonKeyboardNavigation } from 'features/proposal/components/Finder/keyboardNavigation';
import {
  GET_RECOMMENDATIONS,
  UPDATE_FAVORITEGROUP,
} from 'features-apollo/quote/components/queries';
import {
  BaseRecommendationAction,
  CatalogContext,
  Query,
  ProductRecommendationAction,
  RecommendationGroup,
} from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { ProductGroup, ProductGroups } from 'services/user-preferences/types';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { FavoritesCalloutCatalog } from '../FavoritesCallout';
import { favoriteButtonStyles } from './FavoriteButton.styles';
import { buildOptimisticResponse } from '../utils';

export interface FavoriteButtonCatalogProps extends ButtonSharedProps {
  addButtonClass?: string;
  addContainerClass?: string;
  displayLabel?: boolean;
  product: SearchResultProduct;
  stopPropagation?: boolean;
  quoteId?: string;
  catalogContext?: CatalogContext;
}

type Props = FavoriteButtonCatalogProps & WithStyles<typeof favoriteButtonStyles>;

const defaultGroupName = 'Favorites';
const FavoriteButtonCatalogUnStyled: React.FC<Props> = props => {
  const [groupName, setGroupName] = React.useState('Favorites');
  const [isCalloutDismissed, setIsCalloutDismissed] = React.useState(true);
  const [userFavoriteProductGroups, setFavoriteGroup] = React.useState<ProductGroups | undefined>(
    undefined
  );
  const [isFavorited, setIsFavorited] = React.useState<boolean | undefined>(undefined);

  const [recoGroups, setRecoGroups] = React.useState<RecommendationGroup[] | undefined>(undefined);

  const { data } = useQuery(GET_RECOMMENDATIONS, {
    variables: { input: props.catalogContext },
    onCompleted: data => {
      setRecoGroups(data.getRecommendations);
    },
  });

  React.useEffect(() => {
    if (data && data.getRecommendations) {
      setRecoGroups(data.getRecommendations);
    }
  }, [data]);

  React.useEffect(() => {
    if (recoGroups) {
      const favorites = recoGroups.filter(
        recoGroup => recoGroup.type === 'Product' && recoGroup.name !== 'Azure Essentials'
      );
      const productGroups: ProductGroups = {};

      favorites &&
        favorites.length &&
        favorites.forEach(favorite => {
          const groupName = favorite.name;
          const products = favorite.items.map(item => {
            const recommendationItem = item as ProductRecommendationAction;
            if (recommendationItem.product.id === props.product.productId) {
              setGroupName(groupName);
            }

            return {
              productId: recommendationItem.product.id,
              productIdentifier: {
                productId: recommendationItem.product.id,
              },
              productName: recommendationItem.product.title,
            };
          });
          productGroups[favorite.name] = { products: products };
        });
      setFavoriteGroup(productGroups);
    }
  }, [recoGroups, props.product.productId]);

  const { t } = useTranslation();
  const { product } = props;

  const getUserFavoriteProductsIds = (productGroups: Record<string, ProductGroup>) => {
    let productIds: string[] = [];

    if (productGroups) {
      for (let groupName in productGroups) {
        productGroups[groupName].products.forEach(product => productIds.push(product.productId));
      }
    }

    return productIds;
  };

  React.useEffect(() => {
    let userFavoriteProductsIds = userFavoriteProductGroups
      ? getUserFavoriteProductsIds(userFavoriteProductGroups)
      : userFavoriteProductGroups && getUserFavoriteProductsIds(userFavoriteProductGroups);

    userFavoriteProductsIds &&
      setIsFavorited(userFavoriteProductsIds.includes(props.product.productId));
  }, [userFavoriteProductGroups, props.product.productId]);

  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const [buttonLabel, setButtonLabel] = React.useState<string>();

  React.useLayoutEffect(() => {
    isFavorited ? setButtonLabel(t('Favorited')) : setButtonLabel(t('Favorite'));

    // disabling eslint to avoid adding the translation function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorited]);

  const elementIdToRefocus = `favorite-button-${product.productId}`;

  const [updateToFavorites] = useMutation(UPDATE_FAVORITEGROUP, {
    onCompleted: data => {
      const favorites = data.updateFavoriteGroup.filter(
        (recoGroup: RecommendationGroup) =>
          recoGroup.type === 'Product' && recoGroup.name !== 'Azure Essentials'
      );
      const productGroups: ProductGroups = {};

      favorites.forEach((favGroup: RecommendationGroup) => {
        const products = favGroup.items.map(item => {
          const recommendationItem = item as ProductRecommendationAction;
          return {
            productId: recommendationItem.product.id,
            productIdentifier: {
              productId: recommendationItem.product.id,
            },
            productName: recommendationItem.product.title,
          };
        });
        productGroups[favGroup.name] = { products: products };
      });

      setFavoriteGroup(productGroups);
    },
  });

  const updateLocalFavoriteGroup = (cached: Query | null) => {
    if (cached && cached.getRecommendations) {
      // update FavoriteGroup
      const favorites = cached.getRecommendations.filter(
        recoGroup => recoGroup.type === 'Product' && recoGroup.name !== 'Azure Essentials'
      );
      const productGroups: ProductGroups = {};
      favorites.forEach((favGroup: RecommendationGroup) => {
        const products = favGroup.items.map(item => {
          const recommendationItem = item as ProductRecommendationAction;
          return {
            productId: recommendationItem.product.id,
            productIdentifier: {
              productId: recommendationItem.product.id,
            },
            productName: recommendationItem.product.title,
          };
        });
        productGroups[favGroup.name] = { products: products };
      });

      setFavoriteGroup(productGroups);
    }
  };

  const isNotEqual = (item: BaseRecommendationAction, product: SearchResultProduct) => {
    const recommendationItem = item as ProductRecommendationAction;
    return recommendationItem.product.id !== product.productId;
  };

  // Add product to user's favorites product group only
  const favoriteProduct = (
    event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>
  ) => {
    if (event && props.stopPropagation) event.stopPropagation();
    updateToFavorites({
      variables: {
        input: {
          productId: product.productId,
          addToGroup: defaultGroupName,
          catalogContext: props.catalogContext,
        },
      },
      optimisticResponse: buildOptimisticResponse(product, defaultGroupName),
      update: (useApolloClient, { data: { updateFavoriteGroup } }) => {
        // Read from cached
        const cached = useApolloClient.readQuery<Query>({
          query: GET_RECOMMENDATIONS,
          variables: { input: props.catalogContext },
        });

        // Create update object
        if (cached && cached.getRecommendations) {
          let cachedRecFav = cached.getRecommendations.find(rec => rec.name === defaultGroupName);
          if (cachedRecFav) {
            if (
              updateFavoriteGroup.find((rec: RecommendationGroup) => rec.name === defaultGroupName)
                .items.length > 1
            ) {
              // response has MORE THAN one item
              // overwrite everything (OR do nothing)
              cachedRecFav.items = updateFavoriteGroup.find(
                (rec: RecommendationGroup) => rec.name === defaultGroupName
              ).items;
            } else if (
              cachedRecFav &&
              cachedRecFav.items &&
              (cachedRecFav.items.length === 0 ||
                cachedRecFav.items.some(item => isNotEqual(item, product)))
            ) {
              // response has ONLY one item
              // and cache has 0 items OR cache already contains same item
              cachedRecFav.items = cachedRecFav.items.concat(
                updateFavoriteGroup.find(
                  (rec: RecommendationGroup) => rec.name === defaultGroupName
                ).items
              );
            }

            let filteredRecs = cached.getRecommendations.filter(
              rec => rec.name !== defaultGroupName
            );
            filteredRecs.push(cachedRecFav);
          }
        }

        // Write updated clone object to cache
        useApolloClient.writeQuery({
          query: GET_RECOMMENDATIONS,
          variables: { input: props.catalogContext },
          data: cached,
        });

        updateLocalFavoriteGroup(cached);
      },
    });
    setIsCalloutDismissed(!isCalloutDismissed);

    loggerService.log({
      name: `Product favorited`,
      properties: {
        productId: product.productId,
        productName: product.productName,
      },
    });
  };

  const isEqual = (item: BaseRecommendationAction, product: SearchResultProduct) => {
    const recommendationItem = item as ProductRecommendationAction;
    return recommendationItem.product.id === product.productId;
  };

  // Remove from all user's product groups
  const unfavoriteProduct = (
    event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>
  ) => {
    if (event && props.stopPropagation) event.stopPropagation();

    updateToFavorites({
      variables: {
        input: {
          productId: product.productId,
          removeFromGroup: groupName,
          catalogContext: props.catalogContext,
        },
      },
      optimisticResponse: buildOptimisticResponse(product, groupName),
      update: (useApolloClient, { data: { updateFavoriteGroup } }) => {
        // Read from cached
        const cached = useApolloClient.readQuery<Query>({
          query: GET_RECOMMENDATIONS,
          variables: { input: props.catalogContext },
        });

        // Create Update object
        if (updateFavoriteGroup.find((rec: RecommendationGroup) => rec.name === groupName).items) {
          if (cached && cached.getRecommendations) {
            let recGroups = cached.getRecommendations;
            let favRecGroup = recGroups.find(rec => rec.name === groupName);
            if (favRecGroup) {
              const index = favRecGroup.items.findIndex(item => isEqual(item, product));
              favRecGroup.items.splice(index, 1);
            }
          }
        }

        // Write updated clone object to cache
        useApolloClient.writeQuery({
          query: GET_RECOMMENDATIONS,
          variables: { input: props.catalogContext },
          data: cached,
        });

        updateLocalFavoriteGroup(cached);
      },
    });
    setIsCalloutDismissed(true);
  };

  const handleDismissCallout = () => {
    setIsCalloutDismissed(true);
  };

  const onMouseEnter = () => {
    if (isFavorited && isCalloutDismissed) setButtonLabel(t('Unfavorite?'));
  };

  const onMouseLeave = () => {
    if (isFavorited && isCalloutDismissed) setButtonLabel(t('Favorited'));
  };

  return (
    <>
      <div
        className={mergeClassNames([props.classes.buttonContainer, props.addContainerClass])}
        ref={buttonContainerRef}
        onKeyDown={favoriteButtonKeyboardNavigation}
      >
        <IconButton
          addClass={mergeClassNames([
            props.classes.button,
            isFavorited ? props.classes.heartFillIcon : props.classes.heartIcon,
            props.addButtonClass,
          ])}
          ariaLabel={props.ariaLabel}
          dataAutomationId={elementIdToRefocus}
          iconName={isFavorited ? 'HeartFill' : 'Heart'}
          id={elementIdToRefocus}
          text={props.displayLabel ? buttonLabel : undefined}
          onClick={isFavorited ? unfavoriteProduct : favoriteProduct}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </div>
      <FavoritesCalloutCatalog
        catalogContext={props.catalogContext}
        description={t('Would you like to save it in a new group instead?')}
        descriptionForGroups={t('Would you like to save it somewhere else instead?')}
        elementIdToFocusOnDismiss={elementIdToRefocus}
        excludeGroupName={groupName}
        hidden={isCalloutDismissed}
        isSave={true}
        moveFrom={groupName}
        product={props.product}
        quoteId={props.quoteId}
        target={buttonContainerRef.current}
        title={t('Saved to Favorites')}
        onDismiss={handleDismissCallout}
      />
    </>
  );
};

export const FavoriteButtonCatalog = withStyles(favoriteButtonStyles)(
  FavoriteButtonCatalogUnStyled
) as React.FC<FavoriteButtonCatalogProps>;
