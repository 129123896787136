import { AddButton, Dialog, PrimaryButton, RemoveButton, TextBody } from 'components';
import { meplaHistory } from 'createHistory';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { isTermLineItem } from 'features-apollo/quote/components/utils';
import { getFulfillmentCloudsBySegment } from 'features-apollo/quote/supported-clouds';
import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { Dimensions } from 'features/components';
import { Audience, NationalCloud } from 'generated/graphql';
import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { useRouteMatch } from 'react-router-dom';
import { routes } from 'routes';
import { DialogContext, DialogProps } from 'styles/DialogueProvider';
import { oc } from 'ts-optchain';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { ManageFulfillmentCloudsStyles } from './ManageFulfillmentClouds.styles';

const dimensions: Dimensions = {
  height: 387,
  width: 418,
};

export interface CloudDialogItem {
  name: NationalCloud;
  numberOfProducts: number;
  numberOfTerms?: number;
}

export interface ManageFulfillmentCloudsDialogProps {
  quoteId: string;
  isUSNatEnabled?: boolean;
}

type Props = ManageFulfillmentCloudsDialogProps & WithStyles<typeof ManageFulfillmentCloudsStyles>;

export const getQuoteCloudsAndLineItems = gql`
  query getQuoteClouds($id: String!) {
    getQuote(id: $id) {
      id
      etag
      clouds
      transactionModel
      lineItems {
        ... on DiscountLineItem {
          catalogContext {
            nationalCloud
          }
        }
        ... on TermLineItem {
          catalogContext {
            nationalCloud
          }
        }
        ... on PurchaseLineItem {
          catalogContext {
            nationalCloud
          }
        }
        ... on MonetaryLineItem {
          catalogContext {
            nationalCloud
          }
        }
      }
    }
  }
`;

export const updateCloudTypes = gql`
  mutation updateCloudTypes(
    $input: QuoteMutationInput!
    $cloudTypesToRemove: [NationalCloud!]
    $cloudTypesToAdd: [NationalCloud!]
  ) {
    updateCloudTypes(
      input: $input
      cloudTypesToRemove: $cloudTypesToRemove
      cloudTypesToAdd: $cloudTypesToAdd
    ) {
      id
      clouds
      etag
    }
  }
`;

export const ManageCloudsDialog: React.FC<Props> = props => {
  const { classes, quoteId } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const [
    updateClouds,
    { loading: loadingUpdateClouds, error, data: updateCloudsSuccesful },
  ] = useMutation(updateCloudTypes);

  const [cloudsOnQuote, setCloudsOnQuote] = React.useState<CloudDialogItem[] | undefined>();
  const [currentCloudsList, setCurrentCloudsList] = React.useState<CloudDialogItem[] | undefined>();

  const pivotSelection = useRouteMatch(routes.quote.products.cloud);
  const cloudTab = pivotSelection && (pivotSelection.params as { id: string; cloud: string });
  const defaultCloudTab = (cloudTab && cloudTab.cloud) || (cloudsOnQuote && cloudsOnQuote[0].name);

  const calculateLineItemsPerCloud = (lineItems: any, clouds: NationalCloud[]) => {
    const terms = lineItems.filter((lineItem: any) => isTermLineItem(lineItem));
    const products = lineItems.filter((lineItem: any) => !isTermLineItem(lineItem));
    const list = clouds.map(cloud => {
      const productsForCloud =
        products &&
        products.length &&
        products.filter((product: any) => oc(product).catalogContext.nationalCloud() === cloud);
      const termsForCloud =
        terms &&
        terms.length &&
        terms.filter((term: any) => oc(term).catalogContext.nationalCloud() === cloud);
      const item: CloudDialogItem = {
        name: cloud,
        numberOfProducts: productsForCloud && productsForCloud.length,
        numberOfTerms: (!!termsForCloud && termsForCloud.length) || undefined,
      };
      return item;
    });
    setCloudsOnQuote(list);
    setCurrentCloudsList(list);
  };

  const { data } = useQuery<QueryGetQuoteData>(getQuoteCloudsAndLineItems, {
    variables: {
      id: quoteId,
    },
    onCompleted: data => {
      const clouds = oc(data).getQuote.clouds([]) as NationalCloud[];
      const lineItems = oc(data).getQuote.lineItems();
      calculateLineItemsPerCloud(lineItems, clouds);
    },
  });

  const onAddCloud = (cloud: CloudDialogItem) => {
    const newList = currentCloudsList && currentCloudsList.concat(cloud);
    setCurrentCloudsList(newList);
  };

  const onRemoveCloud = (cloud: CloudDialogItem) => {
    const newList = currentCloudsList && currentCloudsList.filter(cl => cl.name !== cloud.name);
    setCurrentCloudsList(newList);
  };

  const onApply = () => {
    if (cloudsOnQuote && currentCloudsList) {
      const cloudsToRemove = cloudsOnQuote.filter(cloud => !currentCloudsList.includes(cloud));
      const cloudsToAdd = currentCloudsList.filter(cloud => !cloudsOnQuote.includes(cloud));
      const removeCloudsFlatList = cloudsToRemove.length
        ? cloudsToRemove.map(cloud => cloud.name)
        : undefined;

      const addCloudsFlatList = cloudsToAdd.length
        ? cloudsToAdd.map(cloud => cloud.name)
        : undefined;

      if (removeCloudsFlatList || addCloudsFlatList) {
        updateClouds({
          variables: {
            input: { id: quoteId, etag: oc(data).getQuote.etag() },
            cloudTypesToRemove: removeCloudsFlatList,
            cloudTypesToAdd: addCloudsFlatList,
          },
          refetchQueries: () => [{ query: GET_QUOTE, variables: { id: quoteId } }],
        });
      } else {
        context.closeDialog();
      }
    }
  };

  React.useEffect(() => {
    if (data && !loadingUpdateClouds && !error && updateCloudsSuccesful) {
      const clouds = oc(updateCloudsSuccesful).updateCloudTypes.clouds();
      if (clouds && clouds.length && !clouds.includes(defaultCloudTab)) {
        //if the cloud that the tab pivot is on gets deleted go to the next available cloud tab
        meplaHistory.push(`${routes.quote.productsForId(quoteId)}/${clouds[0]}`);
      }
      context.closeDialog();
    }
  }, [loadingUpdateClouds, updateCloudsSuccesful, error, context, data, defaultCloudTab, quoteId]);

  const cloudsAvailable = getFulfillmentCloudsBySegment(Audience.Government, props.isUSNatEnabled);
  const renderAvailableClouds = cloudsAvailable.map(cloud => {
    const hasCurrentCloud =
      currentCloudsList && currentCloudsList.find(currentCloud => currentCloud.name === cloud.key);
    const productsToBeRemoved =
      cloudsOnQuote && cloudsOnQuote.find(currentCloud => currentCloud.name === cloud.key);
    const numberOfProductsToBeRemoved = productsToBeRemoved && productsToBeRemoved.numberOfProducts;
    const numberOfTermsToBeRemoved = productsToBeRemoved && productsToBeRemoved.numberOfTerms;
    const cloudNameToRemove = hasCurrentCloud && hasCurrentCloud.name;

    const productString =
      hasCurrentCloud &&
      t('quote::{{count}} product', {
        count: hasCurrentCloud.numberOfProducts,
        // eslint-disable-next-line @typescript-eslint/camelcase
        defaultValue_plural: '{{count}} products',
      });
    //TODO: kaderbez, remove terms string when gql manages cloud change there
    const termString =
      hasCurrentCloud &&
      hasCurrentCloud.numberOfTerms &&
      t('quote::, {{count}} term', {
        count: hasCurrentCloud.numberOfTerms,
        // eslint-disable-next-line @typescript-eslint/camelcase
        defaultValue_plural: ', {{count}} terms',
      });
    const productsToRemoveString = t('quote::{{count}} product', {
      count: numberOfProductsToBeRemoved,
      // eslint-disable-next-line @typescript-eslint/camelcase
      defaultValue_plural: '{{count}} products',
    });
    //TODO: kaderbez, remove terms string when gql manages cloud change there
    const termsToRemoveString =
      numberOfTermsToBeRemoved &&
      t('quote::, {{count}} term', {
        count: numberOfTermsToBeRemoved,
        // eslint-disable-next-line @typescript-eslint/camelcase
        defaultValue_plural: ', {{count}} terms',
      });

    // enum for national cloud looks like USGOV so we turn it to US Gov instead
    const displayName = cloud.text;

    return (
      <div className={classes.cloudRow} key={cloud.key}>
        <TextBody
          addClass={
            hasCurrentCloud || numberOfProductsToBeRemoved || numberOfTermsToBeRemoved
              ? classes.cloudName
              : classes.cloudNameLong
          }
        >
          {displayName}
        </TextBody>

        {hasCurrentCloud ? (
          <TextBody addClass={classes.cloudProducts}>
            {productString}
            {
              //TODO: kaderbez, remove terms string when gql manages cloud change
              termString
            }
          </TextBody>
        ) : numberOfProductsToBeRemoved || numberOfTermsToBeRemoved ? (
          <TextBody addClass={classes.cloudProductsToBeDeleted}>
            {
              //TODO: kaderbez, remove terms string when gql manages cloud change there and make 1 string "# product(s) will be deleted"
            }
            {t('quote::{{productsToRemoveString}}{{termsToRemoveString}} will be deleted', {
              productsToRemoveString,
              termsToRemoveString,
            })}
          </TextBody>
        ) : (
          ''
        )}

        {currentCloudsList ? (
          hasCurrentCloud ? (
            <RemoveButton
              addClass={classes.cloudButton}
              dataAutomationId={`remove-${cloudNameToRemove}-cloud`}
              disabled={currentCloudsList && currentCloudsList.length === 1}
              iconName="Blocked2"
              id={`remove-${cloudNameToRemove}-cloudButton`}
              text={t('quote::Remove')}
              onClick={() => onRemoveCloud(hasCurrentCloud)}
            />
          ) : (
            <AddButton
              addClass={classes.cloudButton}
              dataAutomationId={`add-${cloud.key}-cloud`}
              id={`add-${cloud.key}-cloudButton`}
              predefinedIconName="AddTo"
              text={t('quote::Add to quote')}
              onClick={() =>
                onAddCloud({
                  name: cloud.key as NationalCloud,
                  numberOfProducts: numberOfProductsToBeRemoved || 0,
                  numberOfTerms: numberOfTermsToBeRemoved,
                })
              }
            />
          )
        ) : (
          ''
        )}
      </div>
    );
  });

  const body = (
    <div className={classes.container}>
      <TextBody addClass={classes.text}>
        {t(
          'quote::Select which fulfillment clouds you would like to add to the quote. This will add them as an additional tab where separate products and separate favorite groupings specific to that fulfillment cloud. Removing a fulfillment cloud will remove any products on it.'
        )}
      </TextBody>
      <TextBody addClass={classes.subtitle}>{t('quote::Fulfillment clouds')}</TextBody>
      {renderAvailableClouds}
      {error && (
        <TextBody addClass={classes.errorMessage}>
          {t('quote::We were unable to update the quote. Please try again.')}
        </TextBody>
      )}
    </div>
  );

  //TODO: kaderbez, apply logic
  return (
    <Dialog
      {...dimensions}
      footerButtons={[
        <PrimaryButton
          dataAutomationId="apply-update-clouds-button"
          key="apply-clouds"
          text={t('Apply')}
          onClick={() => onApply()}
        />,
      ]}
      title={t('quote::Add fulfillment cloud')}
    >
      {body}
    </Dialog>
  );
};

export const ManageFulfillmentCloudsDialog = withStyles(ManageFulfillmentCloudsStyles)(
  ManageCloudsDialog
);

export const openManageFulfillmentCloudsDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: ManageFulfillmentCloudsDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <ManageFulfillmentCloudsDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
