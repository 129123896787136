import { LinkButton, LinkExternal, SystemError, TextBodySmall, TextWatermark } from 'components';
import { SearchResultStatusStyles as styles } from 'features/ldsscustomer/components/SearchResultStatus.styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

export interface SearchResultStatusProps {
  addClass?: string;
  isLoading: boolean;
  hasError: boolean;
  hasLDSSPermission: boolean;
  numResults: number;
  hasBeenSearched: boolean;
  searchQuery?: string;
  errorRetry?: () => void;
  clearSearch?: () => void;
}

type Props = WithStyles<typeof styles> & SearchResultStatusProps;

const SearchResultStatusUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const loadingMessage = (
    <div>
      <TextBodySmall>{t('home::Searching...')}</TextBodySmall>
    </div>
  );
  const noResultsMessage = (
    <div className={props.classes.noResultsMessage}>
      <div>
        <TextWatermark>
          {t(`home::Sorry, we couldn't find any results matching "{{query}}."`, {
            query: props.searchQuery,
          })}
        </TextWatermark>
      </div>
      <div>
        <TextWatermark>
          {t('home::Try a different search term to find your customer.')}
        </TextWatermark>
      </div>
    </div>
  );
  const initialMessage = (
    <div>
      <TextWatermark>{t('home::Search for a customer above to view results')}</TextWatermark>
    </div>
  );
  const hasResultsMessage = (
    <div>
      <TextBodySmall>{`${props.numResults} ${t('home::results found')}`}</TextBodySmall>
      <TextBodySmall addClass={props.classes.clearSearchButton}>
        <LinkButton displayText={t('home::clear search')} onClick={props.clearSearch} />
      </TextBodySmall>
    </div>
  );
  const errorMessage = (
    <SystemError
      actionLabel={t('home::retry search')}
      mainMessage={t(
        'home::We ran into an error and were unable to complete your search for the term "{{query}}."',
        { query: props.searchQuery }
      )}
      onClick={props.errorRetry}
    />
  );

  const permissionErrorMessage = (
    <SystemError
      addClass={props.classes.noPermissionMessage}
      mainMessage={t(
        'home::You do not have the appropriate permissions to access customer search.'
      )}
    >
      {t('home::You can request permissions through the ')}
      <LinkExternal
        displayText={t('home::Tools Management Portal.')}
        href="https://tools.cp.microsoft.com/ManagementPortal/AppAccessReqPage?appName=DecisionSupportServices&groupName=LDSSMSBusinessUser&roleName=DataReader"
      />
    </SystemError>
  );

  let renderedStatusMessage;

  if (!props.hasLDSSPermission) {
    renderedStatusMessage = permissionErrorMessage;
  } else if (props.isLoading) {
    renderedStatusMessage = loadingMessage;
  } else if (props.hasError) {
    renderedStatusMessage = errorMessage;
  } else if (props.numResults && props.numResults > 0) {
    renderedStatusMessage = hasResultsMessage;
  } else if (!props.hasBeenSearched) {
    renderedStatusMessage = initialMessage;
  } else if (props.numResults === 0 && props.hasBeenSearched) {
    renderedStatusMessage = (
      <div>
        <div>{hasResultsMessage}</div>
        <div>{noResultsMessage}</div>
      </div>
    );
  }

  return <div className={props.addClass}>{renderedStatusMessage}</div>;
};

export const SearchResultStatus = withStyles(styles)(SearchResultStatusUnstyled);
