export const catalogSectionRowTilesStyles = {
  detailsFavoriteButtonContainer: {
    paddingTop: 12,
  },
  favoriteButton: {
    height: 'fit-content',
    width: 'fit-content',
    minHeight: 20,
    minWidth: 20,
  },
  favoriteButtonContainer: {
    height: 'fit-content',
    width: 'fit-content',
    position: 'unset',
  },
};
