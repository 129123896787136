import { ThemeProps } from 'styles';
import { sharedStyles } from 'styles/shared.styles';

export const standardSearchStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    border: {
      ...sharedStyles(theme).roundedCorners,
      borderColor: palette.border,
    },
    error: {
      color: palette.dangerActive,
    },
  };
};
