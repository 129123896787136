import { ErrorMessage, LinkButton, TextBody, TextTitleSecondary } from 'components';
import { EditLabel } from 'components/molecules/EditLabel/EditLabel';
import { ExistingOwnersLinkButton } from 'features-apollo/quote/components/Dialogs/ExistingOwnersDialog';
import {
  openTenantWizardDialog,
  TenantScenario,
  TenantWizardView,
} from 'features-apollo/quote/components/Wizards';
import { getFlightIsEnabled } from 'features/app/selectors';
import { AccountTenant, InvitedUserSimple, QuoteMutationInput } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { organizationInformationStyles } from '../OrganizationInformation.styles';
import { TenantRequired } from './TenantRequired';

export interface OrganizationInformationTenantProps {
  /**
   * Required to update the quote object.
   */
  quoteMutationInput?: QuoteMutationInput;
  /**
   * Defines the tenant flow scenario specific for partner or partner-customer
   */
  specificScenario?: TenantScenario;
  /**
   * Identifies the account of the tenant
   */
  organization: { id: string; accountId: string };
  /**
   * Use for default tenant if there is none found in the quote.
   */
  accountTenants: AccountTenant[];
  invitedUser?: InvitedUserSimple | null;
  readOnly?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    disableEditTenant: getFlightIsEnabled(state, Flight.noAdditionalTenant),
  };
};

type Props = OrganizationInformationTenantProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof organizationInformationStyles>;

const OrganizationInformationTenantUnstyled: React.FC<Props> = props => {
  const {
    classes,
    invitedUser,
    accountTenants,
    quoteMutationInput,
    specificScenario,
    organization,
  } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const hideIcon = props.readOnly || !quoteMutationInput;

  let tenantSelected: AccountTenant | undefined | null;
  if (accountTenants.length) {
    const invitedUserTenant = accountTenants.find(
      tenant => tenant.tenantId === oc(invitedUser).tenant.id()
    );
    if (invitedUserTenant) {
      tenantSelected = invitedUserTenant;
    } else if (accountTenants.length === 1) {
      tenantSelected = accountTenants[0];
    }
  }

  const onUpdateWorkAccount = () => {
    if (quoteMutationInput) {
      openTenantWizardDialog(context, {
        initialView: TenantWizardView.Email,
        quoteMutationInput,
        specificScenario,
        organization,
        accountTenants,
        invitedUser,
      });
    }
  };

  const onUpdateTenant = () => {
    if (quoteMutationInput) {
      openTenantWizardDialog(context, {
        initialView: TenantWizardView.Tenant,
        quoteMutationInput,
        specificScenario,
        organization,
        accountTenants,
        invitedUser,
      });
    }
  };

  // Translated strings per scenario
  let workAccountAriaLabel: string;
  let tenantTitle: string;
  let tenantIdAriaLabel: string;
  let tenantNameAriaLabel: string;

  switch (props.specificScenario) {
    case TenantScenario.partner:
      workAccountAriaLabel = t('quote::Add or edit partner work account');
      tenantTitle = t('quote::Acceptance');
      tenantIdAriaLabel = t('quote::Edit partner tenant ID');
      tenantNameAriaLabel = t('quote::Edit partner tenant name');
      break;
    case TenantScenario.partnerCustomer:
      workAccountAriaLabel = t('quote::Add or edit customer work account');
      tenantTitle = t('quote::Recipient');
      tenantIdAriaLabel = t('quote::Edit customer tenant ID');
      tenantNameAriaLabel = t('quote::Edit customer tenant name');
      break;
    default:
      workAccountAriaLabel = t('quote::Add or edit work account');
      tenantTitle = t('quote::Person accepting the quote');
      tenantIdAriaLabel = t('quote::Edit tenant ID');
      tenantNameAriaLabel = t('quote::Edit tenant name');
      break;
  }

  const invitedUserEmail = oc(invitedUser).email();

  const workAccount =
    !props.readOnly || (props.readOnly && invitedUserEmail) ? (
      <>
        <EditLabel
          ariaLabel={workAccountAriaLabel}
          dataAutomationId="addOrEditWorkAccount"
          hideIcon={hideIcon}
          label={t('quote::Work account')}
          required
          onClick={onUpdateWorkAccount}
        />
        {invitedUserEmail ? (
          <TextBody>{invitedUserEmail}</TextBody>
        ) : (
          <LinkButton
            addClass={classes.font}
            dataAutomationId="addWorkAccountLink"
            disabled={props.readOnly}
            displayText={t('quote::add work account')}
            onClick={onUpdateWorkAccount}
          />
        )}
      </>
    ) : null;

  const selectedTenantInfo = tenantSelected ? (
    <>
      {workAccount}
      {props.readOnly && !(props.specificScenario === TenantScenario.partnerCustomer) && (
        <ExistingOwnersLinkButton
          invitedUser={invitedUserEmail}
          isGovernmentTenant={!!props.specificScenario}
          organizationIdentifier={{
            organizationId: organization.id,
            accountId: organization.accountId,
          }}
          tenantId={tenantSelected.tenantId}
        />
      )}
      <table className={`${classes.table} ${classes.tenantList}`}>
        <thead>
          <tr>
            <th>
              <EditLabel
                ariaLabel={tenantIdAriaLabel}
                dataAutomationId="tenantIdEditLabel"
                hideIcon={hideIcon || props.disableEditTenant}
                label={t('quote::Tenant ID')}
                onClick={onUpdateTenant}
              />
            </th>
            {tenantSelected.tenantName && (
              <th>
                <EditLabel
                  ariaLabel={tenantNameAriaLabel}
                  dataAutomationId="tenantNameEditLabel"
                  hideIcon
                  label={t('quote::Tenant name')}
                  onClick={onUpdateTenant}
                />
              </th>
            )}
          </tr>
        </thead>
        <tbody data-automation-id="tenantInfo">
          <tr>
            <td>
              <TextBody>{tenantSelected.tenantId}</TextBody>
            </td>
            {tenantSelected.tenantName && (
              <td>
                <TextBody>{tenantSelected.tenantName}</TextBody>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </>
  ) : null;

  const setUpTenant =
    !props.readOnly || (props.readOnly && invitedUserEmail) ? (
      <>
        <EditLabel
          ariaLabel={t('quote::Add or edit the sign up email address')}
          dataAutomationId="addOrEditSignUpEmailAddress"
          hideIcon={hideIcon}
          label={t('quote::Sign up email address')}
          required
          onClick={onUpdateWorkAccount}
        />
        <TextBody>{invitedUserEmail}</TextBody>
        <div className={classes.tenantList}>
          <EditLabel
            ariaLabel={t('quote::Add tenant')}
            dataAutomationId="addTenantEditLabel"
            hideIcon={hideIcon}
            label={t('quote::Tenant')}
            onClick={onUpdateTenant}
          />
          <TextBody>{t('quote::To be created on check out')}</TextBody>
        </div>
      </>
    ) : null;

  if (tenantSelected || invitedUser) {
    return (
      <div>
        <TextTitleSecondary addClass={classes.tenantTitle}>{tenantTitle}</TextTitleSecondary>
        {tenantSelected ? selectedTenantInfo : setUpTenant}
      </div>
    );
  } else if (!quoteMutationInput) {
    return <ErrorMessage mainMessage={t('quote::Unable to load data')} />;
  } else {
    return (
      <TenantRequired
        accountTenants={accountTenants}
        invitedUser={invitedUser}
        organization={organization}
        quoteMutationInput={quoteMutationInput}
        specificScenario={specificScenario}
        title={tenantTitle}
      />
    );
  }
};
export const OrganizationInformationTenant = connect(mapStateToProps)(
  withStyles(organizationInformationStyles)(OrganizationInformationTenantUnstyled)
);
