import {
  Autosuggest,
  CalloutCalendar,
  ComboBox,
  Label,
  LinkButton,
  MediumIcon,
  Persona,
  SectionSeparator,
  Suggestion,
  TextBody,
  TextboxStandard,
} from 'components';
import { PersonaAtomProps } from 'components/atoms';
import { ContactSuggestion, ContactSuggestionRow } from 'components/molecules/Autosuggest';
import { calendarStrings } from 'components/utilities/calendarStrings';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import {
  patchProposalHeaderAsync,
  updateMsContact,
  updateProposalAsync,
  updateProposalCurrency,
  updateProposalLanguage,
  updateProposalMarket,
} from 'features/proposal/actions';
import { LegacyDialog } from 'features/proposal/components/Dialogs';
import { getActiveProposal, getMarket, getMSContactName } from 'features/proposal/selectors';
import { Currency, getComboBoxCurrencies } from 'features/proposal/supported-currencies';
import { getComboBoxLanguages, Language } from 'features/proposal/supported-languages';
import {
  getCurrencies,
  getLanguages,
  getTranslatedMarketsAlphabetically,
  Market,
  modernMarkets,
} from 'features/proposal/supported-markets';
import { getComboBoxProposalTypes } from 'features/proposal/supported-proposal-types';
import {
  createProposalGlobalCloudChannelOrSegmentRequest,
  hasCRMId,
  isAgreementTypeLegacy,
  isProposalNameInvalid,
  isProposalReadOnly,
  isQuoteExpiringSoon,
} from 'features/proposal/utils';
import { loadMSContactAsync, searchMSContactsAsync } from 'features/user/actions';
import {
  getMSContactSearchResults,
  msContactSearchLoading,
  processingMSContact,
} from 'features/user/selectors';
import moment from 'moment';
import { IComboBox, IComboBoxOption, Icon, PersonaSize } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { NationalCloud } from 'services/catalog/config';
import * as LocalStorage from 'services/local-storage-service';
import { ClaimType, proposalNameLengthLimit } from 'services/proposal/config';
import { Proposal, ProposalStatus, UserGroup } from 'services/proposal/types';
import { PatchCommand } from 'services/types';
import { RootState } from 'store/types';
import { DialogContext, DialogProps, ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { KeyCodes } from '@uifabric/utilities';

import { InfoStyles } from './Info.styles';

// #region MSContactPersona component
const msContactPersonaStyles = (theme: ThemeProps) => ({
  primaryText: { color: theme.palette.textTertiary, fontStyle: 'italic' },
});

type MSContactPersonaProps = { msContactName?: string } & WithStyles<typeof msContactPersonaStyles>;

const renderContactIcon = () => <Icon iconName="Contact" />;

const MSContactPersonaUnstyled: React.FC<MSContactPersonaProps> = props => {
  const { t } = useTranslation();
  const personaProps: PersonaAtomProps = {
    id: 'ms-contact',
    name: props.msContactName,
    size: PersonaSize.size32,
  };

  if (!props.msContactName) {
    personaProps.name = t('quote::no contact');
    personaProps.styles = { primaryText: props.classes.primaryText };
    personaProps.onRenderInitials = renderContactIcon;
  }

  return <Persona {...personaProps} />;
};

const MSContactPersona = withStyles(msContactPersonaStyles)(MSContactPersonaUnstyled);
// #endregion MSContactPersona component

const mapStateToProps = (state: RootState) => {
  const quote = getActiveProposal(state);
  const lineItemClaims = oc(quote).lineItems[0].catalogClaims();
  const audience = oc(lineItemClaims)[ClaimType.PricingAudience]();

  return {
    audience,
    isLegacy: isAgreementTypeLegacy(quote),
    isMsContactSearchLoading: msContactSearchLoading(state),
    market: getMarket(state),
    msContactEmail: quote.header.msContact,
    msContactName: getMSContactName(state, quote.header.msContact || ''),
    msContactSearchResults: getMSContactSearchResults(state),
    processingMSContact: processingMSContact(state),
    quote,
  };
};

const dispatchProps = {
  loadNewContact: loadMSContactAsync.request,
  patchProposalHeader: patchProposalHeaderAsync.request,
  searchMsContacts: searchMSContactsAsync.request,
  updateCurrency: updateProposalCurrency,
  updateGlobalCloudChannelOrSegment: updateProposalAsync.request,
  updateLanguage: updateProposalLanguage,
  updateMarket: updateProposalMarket,
  updateMsContact: updateMsContact,
};

export type InfoProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
type Props = InfoProps & WithStyles<typeof InfoStyles>;

const InfoUnStyled: React.FC<Props> = props => {
  const { classes, msContactEmail, quote } = props;
  const { t } = useTranslation();
  useHelpContent(HelpContent.InfoPropertySheet);

  const isQuoteReadOnly = isProposalReadOnly(quote);
  const userMarket = LocalStorage.get<Market>(LocalStorage.localMarketKey) || 'US';

  const message: JSX.Element = (
    <TextBody addClass={classes.textBlock} dataAutomationId="infoMessage">
      {!isQuoteReadOnly &&
        (props.isLegacy
          ? t(
              'quote::Any changes to the national cloud, market or currency can result in the pricing on line items to become invalid. Please confirm any line item changes on the quote.'
            )
          : t(
              'quote::Any changes to the market or currency can result in the pricing on line items to become invalid. Please confirm any line item changes on the quote.'
            ))}
    </TextBody>
  );

  //#region Quote Name
  const [displayQuoteName, setDisplayQuoteName] = React.useState<{ value: string }>({
    value: quote.header.name,
  });
  const [errorInvalidQuoteName, setErrorInvalidQuoteName] = React.useState<string>('');

  const handleQuoteNameBlur = () => {
    if (!errorInvalidQuoteName && displayQuoteName.value !== quote.header.name) {
      props.patchProposalHeader({
        etag: quote.etag,
        proposalId: quote.id,
        commands: [{ op: 'replace', path: '/name', value: displayQuoteName.value }],
      });
    }
  };

  const handleQuoteNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setErrorInvalidQuoteName('');

    if (value) {
      const text =
        value.length <= proposalNameLengthLimit
          ? value
          : value.substring(0, proposalNameLengthLimit);
      setDisplayQuoteName({ value: text });

      if (isProposalNameInvalid(text))
        setErrorInvalidQuoteName(
          t('quote::The following characters are not allowed: ! @ # % ^ [ ] . / < >')
        );
    } else {
      setDisplayQuoteName({ value: '' });
    }
  };

  const handleQuoteNameEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter) {
      handleQuoteNameBlur();
    }
  };

  const quoteName: JSX.Element = (
    <TextboxStandard
      dataAutomationId="infoQuoteName"
      errorMessage={errorInvalidQuoteName}
      label={t('quote::Quote name')}
      readOnly={isQuoteReadOnly}
      value={displayQuoteName.value}
      onBlur={handleQuoteNameBlur}
      onChange={handleQuoteNameChange}
      onKeyDown={handleQuoteNameEnter}
    />
  );

  React.useEffect(() => {
    setDisplayQuoteName({ value: quote.header.name });
  }, [quote.header.name]);
  //#endregion

  //#region Market
  const setMarket = (request: Market, proposal: Proposal) => {
    props.updateMarket(request, proposal);
    // only update localMarketKey if market is on modern list
    if (modernMarkets.includes(request)) {
      LocalStorage.set<Market>(LocalStorage.localMarketKey, request);
    }
  };
  const maxMarketMenuHeight = 250;
  const maxMarketMenuWidth = 356;

  const market: JSX.Element = (
    <ComboBox
      allowFreeform={true}
      autoComplete="on"
      disabled={hasCRMId(quote) || isQuoteReadOnly || props.isLegacy}
      dropDownWidth={maxMarketMenuWidth}
      id="Market"
      label={t('quote::Market')}
      maxHeight={maxMarketMenuHeight}
      options={getTranslatedMarketsAlphabetically(t)}
      selectedKey={props.market.toString()}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
          setMarket(option.key as Market, quote);
        }
      }}
    />
  );
  //#endregion

  //#region Currency
  const userCurrency = getCurrencies(userMarket)[0];
  const currencyComboBoxOptions = getComboBoxCurrencies(getCurrencies(props.market));

  const currency: JSX.Element = (
    <ComboBox
      disabled={isQuoteReadOnly}
      dropDownWidth={maxMarketMenuWidth}
      id="Currency"
      label={t('quote::Currency')}
      options={currencyComboBoxOptions}
      selectedKey={quote.header.pricingContext.billingCurrency || userCurrency}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
          props.updateCurrency(option.key as Currency, quote);
        }
      }}
    />
  );
  //#endregion

  //#region Quote Language
  const userLanguage = getLanguages(userMarket)[0];
  const languageComboBoxOptions = getComboBoxLanguages(getLanguages(props.market));

  const quoteLanguage: JSX.Element = (
    <ComboBox
      disabled={isQuoteReadOnly}
      dropDownWidth={maxMarketMenuWidth}
      id="QuoteLanguage"
      label={t('quote::Quote language')}
      options={languageComboBoxOptions}
      selectedKey={quote.header.pricingContext.languages || userLanguage}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
          props.updateLanguage(option.key as Language, quote);
        }
      }}
    />
  );
  //#endregion

  //#region Channel and segment
  const quoteSegment = props.audience && (
    <div className={classes.textGroup}>
      <Label>{t('quote::Channel and segment')}</Label>
      <TextBody addClass={classes.textSubGroup}>{props.audience}</TextBody>
    </div>
  );
  //#endregion

  //#region Quote Duration
  const onQuoteExpirationDateSelect = (date: Date) => {
    const commands: PatchCommand[] = [{ op: 'replace', path: '/expirationDate', value: date }];
    if (!quote.header.extendedProperties) {
      commands.push({ op: 'add', path: '/extendedProperties', value: {} });
    }
    const userPreferences = oc(quote).header.extendedProperties.userPreferences({});
    userPreferences.setExpiration = true;
    commands.push({
      op: 'add',
      path: '/extendedProperties/userPreferences',
      //TODO: Make this pretty
      value: JSON.stringify(userPreferences),
    });

    props.patchProposalHeader({
      etag: quote.etag,
      proposalId: quote.id,
      commands,
    });
  };

  const quoteDuration = () => {
    let expirationUnits = moment(quote.header.expirationDate).diff(moment(), 'days');
    let unitLabel = expirationUnits === 1 ? t('common::day') : t('common::days');

    if (!expirationUnits) {
      expirationUnits = moment(quote.header.expirationDate).diff(moment(), 'hours');
      unitLabel = expirationUnits === 1 ? t('common::hour') : t('common::hours');

      if (!expirationUnits) {
        expirationUnits = moment(quote.header.expirationDate).diff(moment(), 'minutes');
        unitLabel = expirationUnits === 1 ? t('common::minute') : t('common::minutes');
      }
    }

    return (
      <div className={classes.calendarContainer}>
        <Label>{t('quote::Good until')}</Label>
        <div className={classes.calendarButtonContainer}>
          <CalloutCalendar
            buttonProps={{
              id: 'expiration-date-calendar-button',
              disabled: isQuoteReadOnly,
              text: convertDateToFormattedString(quote.header.expirationDate, LocaleDateFormat.ll),
            }}
            calendarStrings={calendarStrings}
            dataAutomationId="infoGoodUntil"
            defaultDate={new Date()}
            isDayPickerVisible
            maxDate={moment()
              .add(12, 'months')
              .toDate()}
            onSelectDate={onQuoteExpirationDateSelect}
          />
          {isQuoteExpiringSoon(quote.header.expirationDate) && (
            <TextBody addClass={classes.calendarWarning} dataAutomationId="quoteExpiresWarning">
              <MediumIcon iconName="Warning" />
              {t('quote::Expires in {{expirationUnits}} {{unitLabel}}', {
                expirationUnits,
                unitLabel,
              })}
            </TextBody>
          )}
        </div>
      </div>
    );
  };

  //#endregion

  //#region National Cloud
  const nationalCloud: JSX.Element = (
    <ComboBox
      dataAutomationId="nationalCloud"
      disabled={isQuoteReadOnly}
      dropDownWidth={maxMarketMenuWidth}
      id="nationalCloud"
      label={t('quote::Fulfillment cloud')}
      maxHeight={maxMarketMenuHeight}
      options={[
        { key: NationalCloud.Global, text: t(`quote::${NationalCloud.Global}`) },
        { key: NationalCloud.USGov, text: t(`quote::${NationalCloud.USGov}`) },
        { key: NationalCloud.USNat, text: t(`quote::${NationalCloud.USNat}`) },
        { key: NationalCloud.USSec, text: t(`quote::${NationalCloud.USSec}`) },
      ]}
      selectedKey={oc(quote).header.extendedProperties.userPreferences.nationalCloud(
        NationalCloud.Global
      )}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
          const proposalUpdateRequest = createProposalGlobalCloudChannelOrSegmentRequest(
            quote,
            option.key.toString()
          );
          props.updateGlobalCloudChannelOrSegment(proposalUpdateRequest);
        }
      }}
    />
  );
  //#endregion

  //#region Microsoft Contact
  const [displaySearch, setDisplaySearch] = React.useState<boolean>(false);
  const [msContactSelected, setMSContactSelected] = React.useState<string>('');
  const [showProgressBar, setShowProgressBar] = React.useState<boolean>(false);

  if (displaySearch && (msContactSelected === msContactEmail || props.processingMSContact.error)) {
    setDisplaySearch(false);
    setMSContactSelected('');
    setShowProgressBar(false);
  }

  const msContactLabel = (
    <TextBody addClass={classes.emphasisText}>{t('quote::Microsoft contact')}</TextBody>
  );
  const msContactsResults: Suggestion<ContactSuggestion>[] = props.msContactSearchResults.map(
    contact => {
      const contactSuggestion: ContactSuggestion = {
        text: contact.displayName,
        secondaryText: contact.mail,
      };
      const ariaLabel = contact.mail
        ? `${t('quote::Name:')} ${contact.displayName}, ${t('quote::email:')} ${contact.mail}`
        : `${t('quote::Name:')} ${contact.displayName}`;
      return {
        key: contact.userPrincipalName,
        value: contactSuggestion,
        ariaLabel,
      };
    }
  );

  const handleUpdateContact = (contact: Suggestion<ContactSuggestion>) => {
    props.loadNewContact(contact.key);
    props.updateMsContact({ msContact: contact.key, quoteId: quote.id });
    setMSContactSelected(contact.key);
    setShowProgressBar(true);
  };

  const onRenderContactsRow = (suggestion: Suggestion<ContactSuggestion>) => {
    return <ContactSuggestionRow suggestion={suggestion} />;
  };

  const cancelSearchLink = (
    <LinkButton
      addClass={classes.cancelSearchLink}
      displayText={t('quote::cancel')}
      onClick={() => {
        setDisplaySearch(false);
      }}
    />
  );

  const msContactSearch: JSX.Element = (
    <div className={classes.msContactSearchSection}>
      <Autosuggest
        addClass={classes.msContactSearch}
        autoFocus
        dataAutomationId="contactSearch"
        icon={{ iconName: 'Search' }}
        isLoading={props.isMsContactSearchLoading}
        placeholder={t('quote::Search')}
        required={false}
        showProgressBar={showProgressBar}
        strings={{
          loading: t('quote::Loading'),
          notFound: t('quote::Cannot find Microsoft contact'),
          listAriaLabel: t('quote::Search Results'),
        }}
        suggestions={msContactsResults}
        onRenderRow={onRenderContactsRow}
        onSearch={props.searchMsContacts}
        onSelect={handleUpdateContact}
      />
      {displaySearch && cancelSearchLink}
    </div>
  );

  const viewMSContactSearch: JSX.Element = (
    <div data-automation-id="viewMSContactSearch">
      {msContactLabel}
      {msContactSearch}
    </div>
  );

  const viewMSContact: JSX.Element = (
    <div className={classes.msContact} data-automation-id="viewMSContact">
      {msContactLabel}
      <div>
        <MSContactPersona msContactName={props.msContactName} />
        {(!isQuoteReadOnly ||
          (quote.header.status === ProposalStatus.Active &&
            quote.header.assignedToGroup !== UserGroup.Customer)) && (
          <LinkButton
            addClass={classes.link}
            dataAutomationId="infoChangeContact"
            displayText={t('quote::change contact')}
            onClick={() => {
              setDisplaySearch(true);
            }}
          />
        )}
      </div>
    </div>
  );

  const msContactSectionView: JSX.Element = displaySearch ? viewMSContactSearch : viewMSContact;
  //#endregion

  //#region Quote Id
  const quoteId: JSX.Element = (
    <div>
      <TextBody addClass={classes.emphasisText} dataAutomationId="quoteIdLabel">
        {t('quote::Quote ID')}
      </TextBody>
      :
      <TextBody addClass={classes.quoteId} dataAutomationId="quoteIdValue">
        {quote.id}
      </TextBody>
    </div>
  );
  //#endregion

  //#region Legacy Quote
  const context = React.useContext(DialogContext);
  const dialogProps: DialogProps = {
    providedDialog: <LegacyDialog proposal={quote} />,
  };
  const handleOnLegacyConvertClick = () => {
    context.openDialog(dialogProps);
  };
  const proposalTypeComboBoxOptions = getComboBoxProposalTypes();

  const quoteProposalType: JSX.Element = (
    <ComboBox
      dataAutomationId="proposalType"
      disabled={isQuoteReadOnly || props.isLegacy}
      dropDownWidth={maxMarketMenuWidth}
      id="ProposalType"
      label={t('quote::Proposal type')}
      options={proposalTypeComboBoxOptions}
      selectedKey={props.isLegacy ? 'Legacy' : 'Standard'}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option && option.key === 'Legacy') {
          handleOnLegacyConvertClick();
        }
      }}
    />
  );

  const legacyQuote: JSX.Element = (
    <>
      {quoteProposalType}
      <div className={classes.textGroup}>
        <TextBody addClass={classes.emphasisText}>{t('quote::Standard')}</TextBody>
        <TextBody addClass={classes.textSubGroup}>
          {t(
            'quote::The regular everyday quote you will use most of the time and never have to change.'
          )}
        </TextBody>
      </div>
      <div className={classes.textGroup}>
        <TextBody addClass={classes.emphasisText}>{t('quote::Legacy')}</TextBody>
        <TextBody addClass={classes.textSubGroup}>
          {t(
            'quote::The quote should only be converted to legacy when attempting to route a discount for use on an EA Enrollment.'
          )}
        </TextBody>
      </div>
    </>
  );
  //#endregion

  return (
    <div className={classes.container}>
      <div className={classes.leftContent}>
        <div className={classes.leftTopContent}>
          {quoteName}
          {market}
          {currency}
          {quoteLanguage}
          {quoteSegment}
          {quoteDuration()}
          {props.isLegacy && nationalCloud}
          {message}
        </div>
        <SectionSeparator addClass={classes.separator} />
        {legacyQuote}
      </div>
      <div className={classes.rightContent}>
        {msContactSectionView}
        {quoteId}
      </div>
    </div>
  );
};

export const InfoStyled = withStyles(InfoStyles)(InfoUnStyled) as React.FC<InfoProps>;
export const Info = connect(mapStateToProps, dispatchProps)(InfoStyled);
