export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface CreditConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Prod]: 'https://quotecenterservice.l2o.microsoft.com/v1/credit/creditservice-api',
  [Environment.Int]: 'https://quotecenterservice.int.l2o.microsoft.com/v1/credit/creditservice-api',
};

export const safelistedCID = {
  accountId: '335d9744-db3d-458d-b647-5c58348e02b5',
  organizationId: '34d347f3-d7c3-4781-9f23-25fb4c44c0ef_2019-05-31',
};
