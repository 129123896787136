import { linkStyles } from 'components/atoms/Links/Link.styles';
import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => {
  return {
    header: {
      marginBottom: 24,
    },
    link: {
      ...linkStyles(theme).link,
      textDecoration: 'none',
    },
    paragraph: {
      marginTop: 8,
    },
    options: {
      marginTop: 32,
    },
    title: {
      marginBottom: 8,
    },
  };
};
