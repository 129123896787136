import { LinkExternal } from 'components/ions';
import React from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './Resources.styles';
import * as ResourcesContent from './ResourcesContent';

type Props = {} & WithTranslation & WithStyles<typeof styles>;

const Resources: React.FC<Props> = props => {
  const { t } = useTranslation();
  const resourceSection: JSX.Element[] = ResourcesContent.Resources(t).map(element => {
    return (
      <div className={props.classes.wrapperStyle} id="wrapper" key={element.id}>
        <LinkExternal displayText={element.header} href={element.link} iconName="OpenInNewWindow" />
        <div> {element.description} </div>
      </div>
    );
  });

  return <div className={props.classes.container}>{resourceSection}</div>;
};

export default withTranslation('help')(withStyles(styles)(Resources));
