import { CalloutCard } from 'components/ions';
import { closeConfigCard } from 'features/proposal/actions';
import { getLineItem, isProposalReadOnly } from 'features/proposal/selectors';
import { DirectionalHint } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';

import { ServicesConfigCardFields } from './ServicesConfigCardFields';
import { ServicesConfigCardList } from './ServicesConfigCardList';

export interface ServicesConfigCardProps {
  hidden?: boolean;
  headerText: string;
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  // TODO(jek): sync with @cameneks for the structure of services line items
  lineItems: any[];
}

const mapStateToProps = (state: RootState, ownProps: ServicesConfigCardProps) => {
  return {
    lineItem: getLineItem(state, ownProps.lineItemId),
    isProposalReadOnly: isProposalReadOnly(state),
  };
};

const dispatchProps = {
  onDismiss: closeConfigCard,
};

type Props = ServicesConfigCardProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const ServicesConfigCardUnconnected: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const applyButtonStrings = { text: t('quote::Apply'), key: 'Apply' };
  const [lineItemsList, setLineItemsList] = React.useState<any[]>(props.lineItems);

  const onAddClicked = (lineItem: any) => {
    setLineItemsList(lineItemsList => [...lineItemsList, lineItem]);
  };

  const onDeleteClicked = (index: number) => {
    let newLineItemList = lineItemsList;
    newLineItemList.splice(index, 1);
    setLineItemsList([...newLineItemList]);
  };

  const content = (
    <div>
      <ServicesConfigCardFields onAddClicked={onAddClicked}></ServicesConfigCardFields>
      <ServicesConfigCardList
        lineItems={lineItemsList}
        onDeleteClicked={onDeleteClicked}
      ></ServicesConfigCardList>
    </div>
  );

  return (
    <CalloutCard
      applyButtonDisabled={false}
      applyButtonStrings={applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      dataAutomationId="ServicesConfigCard"
      directionalHint={DirectionalHint.rightCenter}
      headerSubText={t('quote::Pre-approved for:')}
      headerText={props.headerText}
      hidden={props.hidden}
      id={props.lineItemId}
      isBeakVisible
      isReadOnly={props.isProposalReadOnly}
      target={props.target}
      onApply={props.onDismiss}
      onDismiss={props.onDismiss}
    >
      {content}
    </CalloutCard>
  );
};

export const ServicesConfigCardStyled = ServicesConfigCardUnconnected as React.FC<
  ServicesConfigCardProps
>;

export const ServicesConfigCard = connect(mapStateToProps, dispatchProps)(ServicesConfigCardStyled);
