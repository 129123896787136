import { ErrorMessage } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

export const styles = {
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25,
  },
};

interface OrganizationNotFoundProps {
  /**
   * Customize message
   * @default 'Currently we are unable to show Billing Account information. Please contact support.''
   */
  customMessage?: string;
}

export type Props = OrganizationNotFoundProps & WithStyles<typeof styles>;

/**
 * Error state for when organization information is not available but expected
 */
export const OrganizationNotFoundUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <div className={props.classes.error}>
      <ErrorMessage
        mainMessage={
          props.customMessage ||
          t(
            'quote::Currently we are unable to show Billing Account information. Please contact support.'
          )
        }
      />
    </div>
  );
};

export const OrganizationNotFound = withStyles(styles)(OrganizationNotFoundUnstyled);
