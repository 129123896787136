import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextboxStandard,
  TextTitle,
} from 'components/ions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { commentLengthLimit } from 'services/approval/config';
import { DialogContext } from 'styles';

import { sharedStyles } from '../shared.styles';

interface OwnProps {
  dispatchAction: (justification?: string) => void;
  height: number;
  width: number;
}

type Props = WithStyles<typeof sharedStyles> & OwnProps;

const JustificationViewFeature: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();
  const [justification, setJustification] = React.useState<{ value: string }>({ value: '' });

  const handleJustificationChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue) {
      setJustification({
        value:
          newValue.length <= commentLengthLimit
            ? newValue
            : newValue.substring(0, commentLengthLimit),
      });
    } else {
      setJustification({ value: '' });
    }
  };

  const handleApproveClick = () => props.dispatchAction(justification.value);

  const Header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="rejectQuote"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Reject quote?')}</TextTitle>
    </DialogHeader>
  );

  const Body = (
    <div className={classes.body}>
      <TextboxStandard
        dataAutomationId="reasonForRejecting"
        label={t('quote::Reason for rejecting')}
        multiline={true}
        resizable={false}
        rows={12}
        value={justification.value}
        onChange={handleJustificationChange}
      />
    </div>
  );

  const Footer = (
    <div className={`${classes.footer} ${classes.buttonRightAlign}`}>
      <PrimaryButton
        dataAutomationId="rejectQuoteButton"
        text={t('quote::Reject')}
        onClick={handleApproveClick}
      />
      <div className={classes.secondButton}>
        <SecondaryButton
          dataAutomationId="rejectQuoteCancelButton"
          text={t('quote::Cancel')}
          onClick={context.closeDialog}
        />
      </div>
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={props.height}
      width={props.width}
    />
  );
};

export const JustificationView = withStyles(sharedStyles)(JustificationViewFeature);
