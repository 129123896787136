import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import { Quote } from 'generated/graphql';
import gql from 'graphql-tag';

export interface QuoteUpdatedData {
  addOrganization: Quote;
}

export const ADD_ORGANIZATION = gql`
  mutation AddOrganization($input: SelectOrganizationInput!) {
    addOrganization(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;
