import { NavLinkAtom } from 'components/atoms/Links/NavLinkAtom';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { NavLinkProps } from 'react-router-dom';
import { FontSizes, sharedStyles, ThemeProps } from 'styles';

export interface NavLinkInternalProps extends NavLinkProps {
  fontSize?: keyof FontSizes;
  dataAutomationId?: string;
}

const styles = (theme: ThemeProps) => {
  return {
    linkStyles: {
      ...sharedStyles(theme).link,
      textDecoration: 'none',
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      fontSize: (props: NavLinkInternalProps) =>
        (props.fontSize && theme.fonts.fontSizes[props.fontSize]) || theme.fonts.fontSizes.small,
      '&, &:visited': {
        color: `${theme.palette.textSecondary}`,
      },
      '&:hover, &:focus': {
        color: `${theme.palette.textLinkHover}`,
      },
    },
  };
};

type Props = NavLinkInternalProps & WithStyles<typeof styles>;

const NavLinkInternalUnstyled: React.FC<Props> = (props: Props) => {
  const { dataAutomationId, ...remainingProps } = props;
  return (
    <NavLinkAtom
      {...remainingProps}
      className={mergeClassNames([props.classes.linkStyles, props.className])}
      data-automation-id={dataAutomationId}
    >
      {props.children}
    </NavLinkAtom>
  );
};

export const NavLinkInternal = withStyles(styles)(NavLinkInternalUnstyled);
