import { useDebounce } from 'components/utilities/debounce';
import {
  BaseButton,
  Button,
  ISearchBox,
  ISearchBoxStyleProps,
  ISearchBoxStyles,
  SearchBox,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { searchAtomStyles } from './SearchAtom.styles';

type ActionType =
  | HTMLDivElement
  | HTMLSpanElement
  | HTMLButtonElement
  | HTMLAnchorElement
  | BaseButton
  | Button;

export interface SearchAtomProps {
  ariaLabel?: string;
  disabled?: boolean;
  id?: string;
  underlined?: boolean;
  placeholder: string;
  value?: string;
  focusPlaceholder?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onSearch?: (input: string) => void;
  onChange?: (input?: string) => void;
  onChangeDebounced?: (input?: string) => void;
  onClear?: (event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  mainClass: string;
  focusClass?: string;
  componentRef?: React.RefObject<ISearchBox>;
  debounce?: boolean;
  dataAutomationId?: string;
  styles?: Partial<ISearchBoxStyles>;
}

type Props = SearchAtomProps & WithStyles<typeof searchAtomStyles>;

const SearchUnstyled: React.FC<Props> = props => {
  const {
    ariaLabel,
    disabled,
    id,
    underlined,
    placeholder,
    focusPlaceholder,
    value,
    onFocus,
    onBlur,
    onSearch,
    onChange,
    onClear,
    autoFocus,
    mainClass,
    focusClass,
    styles,
    classes,
    dataAutomationId,
    componentRef: intrinsicComponentRef,
    debounce,
    onChangeDebounced,
  } = props;

  const [displayPlaceholder, setDisplayPlaceholder] = React.useState(placeholder);
  const [isFocused, setIsFocused] = React.useState(false);

  const search$ = useDebounce(250);
  const handleFocus = () => {
    setIsFocused(true);
    setDisplayPlaceholder(focusPlaceholder || placeholder);
    onFocus && onFocus();
  };
  const handleBlur = () => {
    setIsFocused(false);
    setDisplayPlaceholder(placeholder);
    onBlur && onBlur();
  };

  const handleOnChange = (
    event?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string | undefined
  ) => {
    debounce && search$.next(() => onChangeDebounced && onChangeDebounced(newValue));
    onChange && onChange(newValue);
  };

  const currentClass = (isFocused && focusClass) || mainClass;

  const searchStyles = (stylesProps: ISearchBoxStyleProps): ISearchBoxStyles => ({
    iconContainer: classes.iconContainer,
    field: stylesProps.hasInput ? undefined : classes.placeholder,
    ...styles,
  });

  return (
    <div id={id}>
      <SearchBox
        ariaLabel={ariaLabel}
        autoComplete="off"
        autoFocus={autoFocus}
        className={`${classes.search} ${currentClass}`}
        componentRef={intrinsicComponentRef}
        data-automation-id={dataAutomationId}
        data-testid={id}
        disabled={disabled}
        id={`searchbox-${id}`}
        placeholder={displayPlaceholder}
        styles={searchStyles}
        underlined={underlined}
        value={value}
        onBlur={handleBlur}
        onChange={handleOnChange}
        onClear={onClear}
        onFocus={handleFocus}
        onKeyPress={props.onKeyPress}
        onSearch={onSearch}
      />
    </div>
  );
};

SearchUnstyled.defaultProps = {
  underlined: false,
  disabled: false,
  debounce: true,
};

export const SearchAtom = withStyles(searchAtomStyles)(SearchUnstyled) as React.FC<SearchAtomProps>;
