import { NormalPeoplePicker, IPersonaProps, PersonaInitialsColor } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';
import withStyles, { WithStyles } from 'react-jss';
import { peoplePickerStyles } from './PeoplePicker.styles';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { User } from 'generated/graphql';

const SEARCH_USER = gql`
  query searchUser($text: String!) {
    searchUser(text: $text) {
      mail
      displayName
    }
  }
`;

export interface PeoplePickerProps {
  addClass?: string;
  dataAutomationId?: string;
  searchQueryType: 'mail' | 'displayName';
  defaultSelected?: string[];
  setSelected?: React.Dispatch<React.SetStateAction<string[]>>;
}

type Props = PeoplePickerProps & WithStyles<typeof peoplePickerStyles>;

const PeoplePickerUnstyled: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const [queryString, setQueryString] = React.useState<string>('');
  const { data } = useQuery(SEARCH_USER, {
    variables: { text: queryString },
    skip: !queryString.trim(),
  });

  const personas: IPersonaProps[] = oc(props)
    .defaultSelected([])
    .map((selected: string) => {
      return {
        text: selected,
        initialsColor: PersonaInitialsColor.blue,
        coinSize: 24,
        showSecondaryText: false,
      };
    })
    .concat([
      ...oc(data)
        .searchUser([])
        .map((user: User) => {
          return {
            text: user[props.searchQueryType],
            initialsColor: PersonaInitialsColor.blue,
            coinSize: 24,
            showSecondaryText: false,
          };
        }),
    ]);
  const [selectedPersonas, setSelectedPersonas] = React.useState<IPersonaProps[]>(personas);

  const listContainsPersona = (persona: IPersonaProps, personas: IPersonaProps[]) => {
    if (personas?.length <= 0) {
      return false;
    }
    return personas.filter(item => item.text === persona.text).length > 0;
  };
  const removeDuplicates = (personas: IPersonaProps[], possibleDupes: IPersonaProps[]) => {
    return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
  };
  const onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (currentPersonas) {
      const filteredPersonas = removeDuplicates(selectedPersonas, currentPersonas);
      return filteredPersonas;
    }
    return [] as IPersonaProps[];
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = selectedPersonas.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = selectedPersonas
        .slice(0, indexPeopleList)
        .concat(selectedPersonas.slice(indexPeopleList + 1));
      setSelectedPersonas(newPeople);
    }
  };
  const getTextFromItem = (persona: IPersonaProps): string => {
    return persona.text as string;
  };
  const onInputChange = (input: string) => {
    setQueryString(input);
    return input;
  };
  const onChange = (items?: IPersonaProps[]) => {
    if (items && props.setSelected) {
      const names = items.map((item: IPersonaProps) => item.text as string);
      props.setSelected(names);
    }
  };
  return (
    <NormalPeoplePicker
      className={props.addClass}
      data-automation-id={props.dataAutomationId}
      getTextFromItem={getTextFromItem}
      pickerSuggestionsProps={{
        suggestionsHeaderText: t('quote::Suggested People'),
        noResultsFoundText: t('quote::No results found'),
        loadingText: t('quote::Loading'),
        showRemoveButtons: true,
        suggestionsContainerAriaLabel: t('quote::Suggested People'),
      }}
      selectedItems={selectedPersonas}
      onChange={onChange}
      onInputChange={onInputChange}
      onRemoveSuggestion={onRemoveSuggestion}
      onResolveSuggestions={onFilterChanged}
    />
  );
};

export const PeoplePicker = withStyles(peoplePickerStyles)(PeoplePickerUnstyled);
