import { ThemeProps } from 'styles/theme';

export const customerListStyles = (theme: ThemeProps) => {
  // const { fonts, palette } = theme;
  return {
    container: {
      maxWidth: 510,
      paddingRight: 100,
    },
    list: {
      listStyleType: 'none',
      paddingInlineStart: 0,
      paddingLeft: 0,
      marginTop: 0,
    },
    bottomStatusMessage: {
      paddingTop: 18,
    },
    shimmerRowItem: {
      paddingTop: 20,
      paddingBottom: 20,
    },
    shimmerRowLine: {
      paddingTop: 8,
      maxWidth: 550,
    },
  };
};
