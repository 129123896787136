import gql from 'graphql-tag';

export const GetValidEnrollments = gql`
  query GetValidEnrollments($tpid: String!) {
    validAzureEnrollments(tpid: $tpid) {
      agreementNumber
      endDate
    }
  }
`;
