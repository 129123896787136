import { ApolloError } from 'apollo-client';
import { ErrorMessage } from 'components';
import { SectionSeparator } from 'components/ions';
import { getFlightIsEnabled } from 'features/app/selectors';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { CrmLead, Market, SoldToSimple, TransactionModel, UserGroup } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

import { SalesBody } from './SalesBody';
import { SalesFooter } from './SalesFooter';
import { SalesHeader } from './SalesHeader';
import { SalesLoading } from './SalesLoading';

const styles = {
  layout: {
    '& > :nth-child(2)': {
      marginTop: 14,
    },
  },
  errorMessage: {
    marginTop: 7,
  },
  separatorMargin: {
    marginTop: 32,
    marginBottom: 24,
  },
};

export interface SalesProps {
  quoteId: string;
  isQuoteLegacy: boolean;
  crmId?: string;
  isQuoteReadOnly: boolean;
  market: Market;
  crmLead?: CrmLead;
  soldTo?: SoldToSimple;
  billingCurrency: string;
  etag: string;
  salesAccountCountry?: string;
  organizationId?: string;
  transactionModel?: TransactionModel;
  assignedTo?: UserGroup;
  enrollmentNumber?: string;
  loading?: boolean;
  error?: ApolloError | undefined;
  /**
   * EnrollmentAssembly feature not available for indirect quotes
   */
  isQuoteIndirect?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  modernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
});

export type SalesStateProps = SalesProps & ReturnType<typeof mapStateToProps>;
type Props = SalesStateProps & WithStyles<typeof styles>;

export const SalesUnStyled: React.FC<Props> = props => {
  const {
    quoteId,
    crmId,
    isQuoteLegacy,
    classes,
    crmLead,
    isQuoteReadOnly,
    market,
    soldTo,
    billingCurrency,
    etag,
    salesAccountCountry,
    organizationId,
    enrollmentNumber,
    assignedTo,
  } = props;
  useHelpContent(HelpContent.SalesPropertySheet);
  const { t } = useTranslation();

  if (props.loading) {
    return <SalesLoading />;
  }
  if (props.error) {
    return (
      <ErrorMessage
        mainMessage={t('quote::Currently, we are unable to display the sales property sheet.')}
      />
    );
  }

  const contentForLegacy = (
    <SalesBody
      crmLead={crmLead}
      enrollmentNumber={enrollmentNumber}
      etag={etag}
      isLegacy={isQuoteLegacy}
      isQuoteReadOnly={isQuoteReadOnly}
      modernOfficeEnabled={props.modernOfficeEnabled}
      quoteId={quoteId}
    />
  );

  const dealEstimateCreditSections = (
    <>
      <SectionSeparator addClass={classes.separatorMargin} />
      <SalesFooter
        assignedTo={assignedTo}
        billingCurrency={billingCurrency}
        crmLead={crmLead}
        etag={etag}
        isQuoteReadOnly={isQuoteReadOnly}
        quoteId={quoteId}
        soldTo={soldTo}
      />
    </>
  );

  const contentForModern = (
    <>
      <SalesHeader
        crmId={crmId}
        crmLead={crmLead}
        etag={etag}
        isLegacy={isQuoteLegacy}
        isQuoteReadOnly={isQuoteReadOnly}
        market={market}
        organizationId={organizationId}
        quoteId={quoteId}
        salesAccountCountry={salesAccountCountry}
      />
      <SalesBody
        crmLead={crmLead}
        enrollmentNumber={enrollmentNumber}
        etag={etag}
        isLegacy={isQuoteLegacy}
        isQuoteIndirect={props.isQuoteIndirect}
        isQuoteReadOnly={isQuoteReadOnly}
        modernOfficeEnabled={props.modernOfficeEnabled}
        quoteId={quoteId}
      />
      {props.transactionModel !== TransactionModel.ToPartnerCustomerAsset &&
        dealEstimateCreditSections}
    </>
  );

  return (
    <div className={classes.layout}>{isQuoteLegacy ? contentForLegacy : contentForModern}</div>
  );
};

export const SalesDisconnected = withStyles(styles)(SalesUnStyled);
export const Sales = connect(mapStateToProps)(SalesDisconnected);
