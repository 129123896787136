import { ThemeProps, sharedStyles } from 'styles';

export const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  iconContainer: {
    ...sharedStyles(theme).customDialogIconContainer,
    marginBottom: 20,
  },
  primaryButton: {
    paddingLeft: 20,
  },
  text: {
    fontSize: 20,
  },
});
