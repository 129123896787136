import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { TextSharedProps } from '../TextSharedProps';
import { h3AtomStyles } from './H3Atom.styles';

type Props = TextSharedProps & WithStyles<typeof h3AtomStyles>;

export const H3: React.FC<Props> = props => {
  const className = `${props.classes.headingStyles} ${props.addClass}`;
  return (
    <h3 className={className} id={props.id}>
      {props.children}
    </h3>
  );
};

export const H3Atom = withStyles(h3AtomStyles)(H3) as React.FC<TextSharedProps>;
