import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { openOrganizationWizardDialog } from 'features-apollo/quote/components/Wizards';
import {
  CustomerType,
  MutationAddOrganizationArgs,
  QuerySearchCustomerOrganizationsForTypeCArgs,
} from 'generated/graphql';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import {
  OrganizationSearchDialog,
  OrganizationSearchDialogProps,
  OrganizationSearchResultTileProps,
} from '../OrganizationSearchDialog';
import { ADD_ORGANIZATION, QuoteUpdatedData } from '../queries';
import { SEARCH_CUSTOMER_ORGS, SearchCustomerResultsData } from './queries';
import { useAssociateCRMID } from './useAssociateCRMID';
import { getInformationalTile } from './utils';

export interface CustomerSearchDialogProps {
  /**
   * Required to associate the orgn
   */
  crmId: string;
}

export const CustomerSearchDialog: React.FC<CustomerSearchDialogProps> = props => {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);

  // TODO: a design that allows to define the onComplete option on a mutation from ActiveQuoteContext
  // To get the quote id and etag for mutation.
  const { activeQuote: quote } = React.useContext(ActiveQuoteContext);

  const associateCRMID = useAssociateCRMID(props.crmId);
  const [organizationIdSelected, setOrganizationIdSelected] = useState<string | undefined>();

  const [searchCustomerOrganizations, { loading, error, data, called }] = useLazyQuery<
    SearchCustomerResultsData,
    QuerySearchCustomerOrganizationsForTypeCArgs
  >(SEARCH_CUSTOMER_ORGS);

  const [addOrganization, { loading: addingCustomer, error: erroredAddingCustomer }] = useMutation<
    QuoteUpdatedData,
    MutationAddOrganizationArgs
  >(ADD_ORGANIZATION, {
    errorPolicy: 'all',
    onCompleted: () => dialogContext.closeDialog(),
    onError: () => {},
  });

  const messages = data && data.searchCustomerOrganizationsForTypeC.messages;
  const tenant = oc(data).searchCustomerOrganizationsForTypeC.tenant();

  const onSearch = (query: string) =>
    searchCustomerOrganizations({
      variables: {
        searchInput: {
          query,
          audience: quote?.audience,
        },
      },
    });

  const onAdd = (accountId: string, organizationId: string) => {
    quote &&
      addOrganization({
        variables: {
          input: {
            quote: { id: quote.id, etag: quote.etag },
            accountId,
            organizationId,
            customerType: CustomerType.EndCustomer,
          },
        },
      });
    associateCRMID(accountId, organizationId);
    setOrganizationIdSelected(organizationId);
  };

  const onCreate = () => openOrganizationWizardDialog(dialogContext, { tenant });

  let results: OrganizationSearchResultTileProps[] | undefined;
  if (data) {
    const { organizations } = data.searchCustomerOrganizationsForTypeC;

    results = tenant
      ? organizations.map<OrganizationSearchResultTileProps>((organization, index) => ({
          id: `search-result-organization-${index}`,
          address: organization.address,
          tradeName: organization.tradeName,
          lastPurchaseDate: organization.lastInvoiceDate,
          tenantId: tenant.tenantId,
          tenantName: tenant.tenantDisplayName,
          loading: organizationIdSelected === organization.id && addingCustomer,
          errored: organizationIdSelected === organization.id && !!erroredAddingCustomer,
          buttonLabel: t('quote::Add customer'),
          loadingLabel: t('quote::adding customer'),
          errorMessage: t('quote::Unable to add the customer.'),
          onAdd: () => onAdd(organization.accountId, organization.id),
        }))
      : [];
  } else if (called && !loading && !error) {
    results = [];
  }

  const informationalTile = getInformationalTile(error, messages, results, onCreate);

  const title = t('quote::Customer search');
  const loadingLabel = t('quote::looking for customers');
  const description = t(
    'quote::Find a government-qualified customer by searching for them using their tenant ID or domain name.'
  );
  const resultsLabel =
    results &&
    t('quote::{{count}} customer found', {
      count: results.length,
      defaultValue_plural: '{{count}} customers found', // eslint-disable-line @typescript-eslint/camelcase
    });

  const dialogProps: OrganizationSearchDialogProps = {
    title,
    description,
    loading,
    loadingLabel,
    resultsLabel,
    allowCreateOrganization: true,
    results,
    informationalTile,
    onSearch,
    onCreate,
  };

  return <OrganizationSearchDialog {...dialogProps} />;
};
