import { Dialog, LinkExternal, TextBody, TextTitle } from 'components';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ImageDialogStyles } from './ImageDialog.styles';
import { DialogProps } from 'styles';

export interface ImageDialogLearnMoreProps {
  url: string;
  displayText: string;
}

export interface ImageDialogProps {
  height: number;
  footerButtons?: JSX.Element[];
  onCloseDialog: () => void;
  id: string;
  image: {
    mediaUrl: string;
    mediaDescription: string;
  };
  headline?: string;
  bodyText: string;
  darkXButton?: boolean;
  learnMore?: ImageDialogLearnMoreProps;
}

type Props = ImageDialogProps & WithStyles<typeof ImageDialogStyles>;

const ImageDialogUnstyled: React.FC<Props> = props => {
  const media = (
    <div className={props.classes.media}>
      <img
        alt={props.image.mediaDescription}
        className={props.classes.image}
        height={338}
        src={props.image.mediaUrl}
        width={600}
      />
    </div>
  );

  const body = (
    <div className={props.classes.body} id={props.id}>
      <TextTitle addClass={props.classes.title}>{props.headline}</TextTitle>
      <TextBody>{props.bodyText}</TextBody>
      {props.learnMore && (
        <div className={props.classes.bodyLink}>
          <LinkExternal
            displayText={props.learnMore && props.learnMore.displayText}
            href={props.learnMore && props.learnMore.url}
            size="medium"
          />
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      bodyAddClass={props.classes.bodyOverrideHeader}
      closeButtonAddClass={props.classes.closeButton}
      closeDialog={props.onCloseDialog}
      footerButtons={props.footerButtons}
      headerAddClass={props.classes.header}
      height={props.height}
      hideHeader={true}
      width={600}
    >
      {media}
      {body}
    </Dialog>
  );
};

export const ImageDialog = withStyles(ImageDialogStyles)(ImageDialogUnstyled);

export const openImageDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: ImageDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <ImageDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
