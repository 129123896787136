import { IconAtom } from 'components/atoms';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { iconStyles } from './Icon.styles';
import { IconProps } from './types';

type Props = IconProps & WithStyles<typeof iconStyles>;

const SmallIconUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <IconAtom
      dataAutomationId={props.dataAutomationId}
      iconName={props.iconName}
      id={props.id}
      mainClass={`${props.classes.iconSmallStyles} ${props.addClass}`}
      title={props.title}
    />
  );
};

const MediumIconUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <IconAtom
      iconName={props.iconName}
      id={props.id}
      mainClass={`${props.classes.iconMediumStyles} ${props.addClass}`}
      title={props.title}
    />
  );
};

const LargeIconUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <IconAtom
      iconName={props.iconName}
      id={props.id}
      mainClass={`${props.classes.iconLargeStyles} ${props.addClass}`}
      title={props.title}
    />
  );
};

const LargerIconUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <IconAtom
      iconName={props.iconName}
      id={props.id}
      mainClass={`${props.classes.iconLargerStyles} ${props.addClass}`}
      title={props.title}
    />
  );
};

const XLargeIconUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <IconAtom
      iconName={props.iconName}
      id={props.id}
      mainClass={`${props.classes.iconXLargeStyles} ${props.addClass}`}
      title={props.title}
    />
  );
};

const XLargerIconUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <IconAtom
      iconName={props.iconName}
      id={props.id}
      mainClass={`${props.classes.iconXLargerStyles} ${props.addClass}`}
      styles={props.styles}
      title={props.title}
    />
  );
};

const XXLargeIconUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <IconAtom
      iconName={props.iconName}
      id={props.id}
      mainClass={`${props.classes.iconXXLargeStyles} ${props.addClass}`}
      title={props.title}
    />
  );
};

export const SmallIcon = withStyles(iconStyles)(SmallIconUnstyled) as React.FC<IconProps>;
export const MediumIcon = withStyles(iconStyles)(MediumIconUnstyled) as React.FC<IconProps>;
export const LargeIcon = withStyles(iconStyles)(LargeIconUnstyled) as React.FC<IconProps>;
export const LargerIcon = withStyles(iconStyles)(LargerIconUnstyled) as React.FC<IconProps>;
export const XLargeIcon = withStyles(iconStyles)(XLargeIconUnstyled) as React.FC<IconProps>;
export const XLargerIcon = withStyles(iconStyles)(XLargerIconUnstyled) as React.FC<IconProps>;
export const XXLargeIcon = withStyles(iconStyles)(XXLargeIconUnstyled) as React.FC<IconProps>;
