import axios, { AxiosError } from 'axios';
import { createGuid, getAuthHeader, getCV, getTestHeader, TokenAuthorities } from 'services/utils';

import { AccountConfig, endpoints } from './config';
import { Account, AddTenantRequest } from './types';

async function getHeaders(config: AccountConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'api-version': '2019-05-31',
    'ms-cv': getCV(),
    'x-ms-correlation-id': createGuid(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function addTenant(
  request: AddTenantRequest,
  config: AccountConfig
): Promise<Account> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];

  const url = `${host}/${request.accountId}/externalId`;

  const response = await axios.post(url, request.tenant, {
    headers,
  });

  return response && response.data;
}

export async function getAccount(accountId: string, config: AccountConfig): Promise<Account> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];

  const url = `${host}/${accountId}`;

  const response = await axios.get(url, {
    headers,
  });
  return response && response.data;
}

export async function getAccountsByTenantId(
  tenantId: string,
  config: AccountConfig
): Promise<Account[]> {
  const headers = await getHeaders(config);
  const url = endpoints[config.environment];

  const params = {
    externalId: tenantId,
  };
  try {
    const response = await axios.get<Account[]>(url, { params, headers });
    return response && response.data;
  } catch (exception) {
    const error = exception as AxiosError;
    if (error.response && error.response.status === 404) {
      return [];
    }
    throw exception;
  }
}

export async function updateAccountDescription(
  accountId: string,
  description: string,
  config: AccountConfig
): Promise<Account> {
  const headers = await getHeaders(config);
  const url = `${endpoints[config.environment]}/${accountId}/updateDescription`;

  const request = {
    description,
  };

  const response = await axios.post<Account>(url, request, { headers });
  return response && response.data;
}
