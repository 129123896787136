import { loadProducts } from 'features/catalog/sagas/auxiliary';
import * as actions from 'features/proposal/actions';
import {
  getRemoveLineItemsGroupRequest,
  getUpdateLineItemRequest,
} from 'features/proposal/components/FinanceTermsCard/FinanceCardBusinessLogic';
import { loadUserRoles } from 'features/user/sagas/auxiliary';
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { getParticipants } from 'services/ccf/utils';
import loggerService from 'services/logger-service';
import { productIds } from 'services/proposal/config';
import { Proposal, ProposalUpdateRequest } from 'services/proposal/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';
import { getType } from 'typesafe-actions';

import { getProposal } from '../selectors';
import { createLineItems, deleteLineItem, deleteLineItems, updateQuote } from './auxiliary';

export function* createAndHydrateLineItems() {
  const relevantAction = actions.createProposalLineItemsAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const ids = action.payload.lineItems.map(item => oc(item).productIdentifier.productId(''));
      yield spawn(loadProducts, ids);
      let quote: Proposal | undefined = yield select((state: RootState) =>
        getProposal(state, action.payload.proposalId)
      );
      if (quote && quote.header.msContact) {
        const roles: string[] = yield call(loadUserRoles, quote.header.msContact);
        const participants = getParticipants(roles);
        if (participants.length) {
          action.payload.lineItems.forEach(lineItem => (lineItem.participants = participants));
        }
      }
      yield put(actions.updateAddedLineItemCount.success(action.payload.lineItems.length));
      quote = yield call(createLineItems, action.payload);
      if (!quote) {
        throw new Error('Cannot proceed, createLineItems resulted in an invalid quote.');
      }
      yield put(actions.createProposalLineItemsAsync.success(quote));
    } catch (err) {
      yield put(
        actions.createProposalLineItemsAsync.failure({
          message: t('error::Error creating line items.'),
          exception: err,
        })
      );
    }
  });
}

export function* updateAndCreateLineItems() {
  const relevantAction = actions.updateAndCreateProposalLineItems.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      // edit specified line item
      let quote: Proposal | undefined = yield select((state: RootState) =>
        getProposal(state, action.payload.proposalId)
      );
      if (!quote) {
        throw new Error('Cannot proceed, could not get proposal.');
      }
      const updateQuoteRequest: ProposalUpdateRequest = {
        proposal: {
          header: quote.header,
          lineItems: quote.lineItems.map(item => {
            if (item.id === action.payload.updatedLineItem.id) {
              return action.payload.updatedLineItem;
            }
            return item;
          }),
        },
        proposalId: action.payload.proposalId,
        etag: action.payload.etag,
      };
      quote = yield call(updateQuote, updateQuoteRequest);
      if (!quote) {
        throw new Error('Cannot proceed, could not update quote.');
      }

      let updatedQuote: Proposal | undefined;

      // create new line items
      if (action.payload.lineItems.length) {
        const ids = action.payload.lineItems.map(item => oc(item).productIdentifier.productId(''));
        yield spawn(loadProducts, ids);
        updatedQuote = yield select((state: RootState) =>
          getProposal(state, action.payload.proposalId)
        );
        if (updatedQuote && quote.header.msContact) {
          const roles: string[] = yield call(loadUserRoles, quote.header.msContact);
          const participants = getParticipants(roles);
          if (participants.length) {
            action.payload.lineItems.forEach(lineItem => (lineItem.participants = participants));
          }
        }
        if (!updatedQuote) {
          throw new Error('Cannot proceed, could not retrieve updated quote.');
        }
        updatedQuote = yield call(createLineItems, { ...action.payload, etag: updatedQuote.etag });
        if (!updatedQuote) {
          throw new Error('Cannot proceed, createLineItems resulted in an invalid quote.');
        }
      }
      yield put(actions.updateAndCreateProposalLineItems.success(updatedQuote || quote));
    } catch (err) {
      yield put(
        actions.updateAndCreateProposalLineItems.failure({
          message: t('error::Error creating and updating line items.'),
          exception: err,
        })
      );
    }
  });
}

export function* updateAddedLineItemCount() {
  const relevantAction = actions.updateAddedLineItemCount.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      yield put(actions.updateAddedLineItemCount.success(action.payload));
    } catch (err) {
      loggerService.error(err);
    }
  });
}

export function* deleteQuoteLineItems() {
  const relevantAction = actions.deleteProposalLineItemsAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const quote: Proposal = yield call(deleteLineItems, action.payload);
      yield put(actions.deleteProposalLineItemsAsync.success(quote));
    } catch (err) {
      yield put(
        actions.deleteProposalLineItemsAsync.failure({
          message: t('error::Error deleting line items.'),
          exception: err,
        })
      );
    }
  });
}

export function* createLineItemGrouping() {
  const relevantAction = actions.createLineItemGroupingAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const request = action.payload.createLineItems;
      const { guid, updateLineItemId, termId } = action.payload;
      const ids = request.lineItems.map(item => oc(item).productIdentifier.productId(''));
      yield spawn(loadProducts, ids);
      let quote: Proposal = yield call(createLineItems, request);
      const updateRequest = getUpdateLineItemRequest(quote, guid, updateLineItemId, termId);
      quote = yield call(updateQuote, updateRequest);
      yield put(actions.createLineItemGroupingAsync.success(quote));
    } catch (err) {
      yield put(
        actions.createLineItemGroupingAsync.failure({
          message: t('error::Error creating line item grouping.'),
          exception: err,
        })
      );
    }
  });
}

export function* deleteLineItemGrouping() {
  const relevantAction = actions.removeLineItemGroupingAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const { guids, deleteRequest } = action.payload;
      let quote: Proposal = yield call(deleteLineItem, deleteRequest);
      const updateRequest = getRemoveLineItemsGroupRequest(quote, guids);
      quote = yield call(updateQuote, updateRequest);
      yield put(actions.removeLineItemGroupingAsync.success(quote));
    } catch (err) {
      yield put(
        actions.removeLineItemGroupingAsync.failure({
          message: t('error::Error deleting line item grouping.'),
          exception: err,
        })
      );
    }
  });
}

export function* loadRelatedProducts() {
  const relevantAction = actions.openConfigCard;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    const relatedIds = oc(action).payload.relatedIds([]);

    if (relatedIds.length > 0) {
      yield call(loadProducts, relatedIds);
    }
  });
}

export function* loadDFDProduct() {
  const relevantAction = actions.loadDFDProduct;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    yield call(loadProducts, [productIds.discountFulfillmentDocument]);
  });
}
