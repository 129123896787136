import gql from 'graphql-tag';

export const SEARCH_LEGACY_ORGANIZATION = gql`
  query searchLegacyOrganization($enrollmentNumber: String!) {
    searchLegacyOrganization(enrollmentNumber: $enrollmentNumber) {
      id
      address {
        companyName
        name
        addressLine1
        addressLine2
        addressLine3
        city
        region
        country
        postalCode
        phoneNumber
        email
      }
    }
  }
`;

export const CONVERT_QUOTE_TO_LEGACY = gql`
  mutation convertQuoteToLegacy($input: LegacyConvertInput!) {
    convertQuoteToLegacy(input: $input) {
      id
    }
  }
`;
