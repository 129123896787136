import { IconButton, TextBodySmall } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => {
  return {
    chevron: {
      minWidth: 11,
      width: 11,
      height: 11,
      padding: 9,
      '& i': {
        fontSize: theme.fonts.fontSizes.small,
      },
    },
    chevronText: {
      marginLeft: 4,
    },
    listItem: {
      listStyleType: 'none',
    },
    collapsibleContainer: {
      marginTop: -4,
      marginLeft: 2,
      paddingLeft: 12,
    },
    listSpacing: {
      marginTop: 4,
      marginLeft: 2,
      paddingLeft: 30,
    },
    listSpacingWithoutCollapse: {
      marginTop: -4,
      paddingLeft: 17,
    },
    container: {
      display: 'inline-block',
    },
  };
};

interface LocationRendererProps {
  addClass?: string;
  locations: string[];
  collapseProps?: { expandAtStart?: boolean; collapseAfter: number };
}

type Props = LocationRendererProps & WithStyles<typeof styles>;

const LocationsRendererUnstyled: React.FC<Props> = props => {
  const { locations, classes, addClass } = props;
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<boolean>(
    !!(props.collapseProps && props.collapseProps.expandAtStart)
  );
  const chevronIconName = expanded ? 'ChevronDown' : 'ChevronRight';

  const locationItems = locations.map(location => (
    <li className={classes.listItem} key={location}>
      <TextBodySmall>{location}</TextBodySmall>
    </li>
  ));
  const showCollapse =
    !!props.collapseProps && locations.length > props.collapseProps.collapseAfter;

  const collapsible = (
    <IconButton
      addClass={classes.chevron}
      iconName={chevronIconName}
      onClick={() => {
        setExpanded(!expanded);
      }}
    />
  );

  const collapsedTitle = (
    <TextBodySmall addClass={classes.chevronText}>
      {t('{{locationCount}} locations', { locationCount: locations.length })}
    </TextBodySmall>
  );

  const collapsibleContainer = (
    <div className={classes.collapsibleContainer}>
      {collapsible}
      {collapsedTitle}
    </div>
  );

  return (
    <div className={addClass}>
      {showCollapse && collapsibleContainer}
      {(expanded || !showCollapse) && (
        <ul
          className={
            expanded && showCollapse ? classes.listSpacing : classes.listSpacingWithoutCollapse
          }
        >
          {locationItems}
        </ul>
      )}
    </div>
  );
};

export const LocationsRenderer = withStyles(styles)(LocationsRendererUnstyled);
