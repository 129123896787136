import { toggleObfuscation } from 'i18n';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';

const mapState = (state: RootState) => ({
  obfuscateTranslations: state.app.flights[Flight.obfuscateTranslations],
});

const connector = connect(mapState);

type Props = {
  children: React.ReactElement;
} & ConnectedProps<typeof connector>;

const _FlightsProvider: React.FC<Props> = (props: Props) => {
  const { children, obfuscateTranslations } = props;

  React.useEffect(() => {
    toggleObfuscation(obfuscateTranslations);
  }, [obfuscateTranslations]);

  return children;
};

export const FlightsProvider = connector(_FlightsProvider);
