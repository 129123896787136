import { ButtonAtom } from 'components/atoms';
import { TextTitleSecondary } from 'components/ions';
import { IButtonStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './Collapsible.styles';

export interface CollapsibleProps {
  text: string;
  headerTextClass?: string;
  headerWrapperClass?: string;
  anchor?: string;
}

type Props = CollapsibleProps & WithStyles<typeof styles>;

const buttonStyles: Partial<IButtonStyles> = {};

const CollapsibleUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  buttonStyles.root = classes.buttonStyle;
  const [openState, setOpenState] = React.useState(false);
  const onToggle = () => {
    setOpenState(!openState);
  };
  const toRender = openState ? (
    <div className={classes.paddingForChildrenStyles}>{props.children}</div>
  ) : null;
  const renderHeader = () => {
    return (
      <div className="CollapsibleQuestion">
        <span>
          <h3 className={classes.headingStyles} id={props.anchor}>
            <span className={classes.valueAlignment}>
              <ButtonAtom
                iconProps={{ iconName: openState ? 'ChevronDown' : 'ChevronRight' }}
                styles={buttonStyles}
                onClick={onToggle}
              />
            </span>
            <p className={classes.textStyle} onClick={onToggle}>
              {props.text}
            </p>
          </h3>
        </span>
      </div>
    );
  };
  return (
    <div className={classes.hoveredAreaStyle}>
      {renderHeader()}
      <div className={classes.collapsibleAnswer}>{toRender}</div>
    </div>
  );
};

const CollapsibleListSectionUnstyled: React.FC<Props> = props => {
  const { classes, text, headerWrapperClass } = props;
  buttonStyles.root = classes.buttonStyle;
  const [isOpen, setOpenState] = React.useState(true);
  const onToggle = () => {
    setOpenState(!isOpen);
  };
  const toRender = isOpen ? <div>{props.children}</div> : null;

  return (
    <div>
      <div className={headerWrapperClass || 'CollapsibleQuestion'}>
        <div className={classes.headingStyles}>
          <span className={classes.valueAlignment}>
            <ButtonAtom
              iconProps={{ iconName: isOpen ? 'ChevronDown' : 'ChevronRight' }}
              styles={buttonStyles}
              onClick={onToggle}
            />
          </span>
          <span onClick={onToggle}>
            <TextTitleSecondary>{text}</TextTitleSecondary>
          </span>
          <br />
        </div>
      </div>
      {toRender}
    </div>
  );
};

export const Collapsible = withStyles(styles)(CollapsibleUnstyled) as React.FC<CollapsibleProps>;
export const CollapsibleListSection = withStyles(styles)(
  CollapsibleListSectionUnstyled
) as React.FC<CollapsibleProps>;
