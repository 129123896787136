import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import gql from 'graphql-tag';

import { AddressFragment } from '../fragments';

/** Mutation to add WorkAccount/SignupEmail to Quote */
export const UpdateInvitedUser = gql`
  mutation updateInvitedUser($input: InvitedUserInput!) {
    updateInvitedUser(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

/** Mutation to add Tenant to MCAPI Account */
export const AddTenantToAccount = gql`
  mutation addTenantToAccount($accountId: String!, $tenant: TenantInput!) {
    addTenantToAccount(accountId: $accountId, tenant: $tenant) {
      id
      tenants {
        tenantId
        tenantName
      }
      assets {
        subscriptionId
      }
    }
  }
`;
//#endregion

//#region OrganizationWizard
/** Query to get Organization for initial data */
export const GetOrganization = gql`
  query getOrganization($input: OrganizationIdentifierInput) {
    getOrganization(input: $input) {
      id
      account {
        id
        description
      }
      address {
        ...addressFragment
      }
      agreementSigned
      language
      name
      tradeName
      version
    }
  }
  ${AddressFragment}
`;

/** Query to check if MCAPI Account exists for Tenant */
export const GetTenantAccount = gql`
  query getTenantAccount($tenant: String) {
    getTenant(tenant: $tenant) {
      tenantId
      tenantType
      account {
        id
        description
        primaryTenantId
        tenants {
          tenantId
          tenantName
        }
        assets {
          subscriptionId
        }
      }
    }
  }
`;

/** Query to get eligible Parent Organizations */
export const GetEligibleLeadOrgs = gql`
  query getEligibleLeadOrgs($input: OrganizationFilterInput!) {
    organizationsByAccountId(input: $input) {
      edges {
        node {
          id
          name
          tradeName
          lastInvoiceDate
          address {
            ...addressFragment
          }
        }
      }
    }
  }
  ${AddressFragment}
`;

/** Mutation to create MCAPI Account and Organization */
export const CreateAccountAndOrganization = gql`
  mutation createAccountAndOrganization($input: CreateAccountOrganizationInput!) {
    createAccountAndOrganization(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

/** Mutation to create Organization in existing MCAPI Account */
export const CreateOrganization = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

/** Mutation to update Organization Address and name */
export const UpdateOrgAddress = gql`
  mutation updateOrgAddress($input: OrganizationAddressInput!) {
    updateOrganizationAddress(input: $input) {
      id
      accountId
      name
      tradeName
      version
    }
  }
`;

/** Mutation to update Organization TradeName */
export const UpdateTradeName = gql`
  mutation updateTradeName($input: TradeNameInput!) {
    updateTradeName(input: $input) {
      id
      accountId
      name
      tradeName
      version
    }
  }
`;

/** Mutation to update Lead Organization */
export const UpdateLeadOrg = gql`
  mutation updateLeadOrg($input: SelectOrganizationInput!) {
    updateLeadOrganization(input: $input) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

/** Mutation to update Organization version on Quote */
export const UpdateOrgVersion = gql`
  mutation updateOrgVersion($input: QuoteMutationInput!, $customerType: CustomerType) {
    updateOrganizationVersion(input: $input, customerType: $customerType) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

//#endregion
