import { GuidanceBox } from 'components/ions';
import * as React from 'react';

import { ExistingCustomerOption, NewOrganizationOption, NoOrganizationHeaderProps } from './shared';

export const NoOrganization: React.FC<NoOrganizationHeaderProps> = props => {
  return (
    <GuidanceBox>
      <NewOrganizationOption optionNumber={1} readOnly={props.readOnly} />
      <ExistingCustomerOption optionNumber={2} readOnly={props.readOnly} />
    </GuidanceBox>
  );
};
