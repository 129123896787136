import gql from 'graphql-tag';

export const REQUEST_CREDIT_LINE = gql`
  mutation requestCreditLine($quoteId: String!) {
    requestCreditLine(quoteId: $quoteId) {
      quoteId
      organization {
        id
        credit {
          currency
          remainingBalance
          creditRiskScore
          decision
          reason
          creditLine
        }
      }
    }
  }
`;

export const REQUEST_CREDIT_LINE_UPDATE_DEAL_ESTIMATE = gql`
  mutation requestCreditLineUpdateDealEstimate($quoteId: String!, $input: DealEstimateInput!) {
    updateAnnualDealEstimate(input: $input) {
      id
      etag
      soldTo {
        dealEstimate
        monthlyCreditLimit
      }
    }
    requestCreditLine(quoteId: $quoteId) {
      quoteId
      dealEstimate
      monthlyCreditLimit
      organization {
        id
        credit {
          currency
          remainingBalance
          creditRiskScore
          decision
          reason
          creditLine
        }
      }
    }
  }
`;
