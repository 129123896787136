import React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';

import { QuoteListDropZone } from './QuoteListDropZone';

export interface QuoteListDnDProps {
  /**
   * Hides drop zone
   *
   * @type {boolean}
   * @memberof QuoteListDnDProps
   */
  disabled?: boolean;
  /**
   * Use to resize dropzone with quote list dimensions
   *
   * @type {React.RefObject<HTMLDivElement>}
   * @memberof QuoteListDnDProps
   */
  rowsContainerRef: React.RefObject<HTMLDivElement>;
  /**
   * Shows watermark when list is empty.
   *
   * @type {boolean}
   * @memberof QuoteListDnDProps
   */
  isEmptyList?: boolean;
  /**
   * Determines when a dragging event is happening
   *
   * @type {boolean}
   * @memberof QuoteListDnDProps
   */
  isDragEvent?: boolean;
  /**
   * Whether any configuration card is open, using to avoid scrolling the list when it's open
   *
   * @type {boolean}
   * @memberof QuoteListDnDProps
   */
  configurationCardIsOpen: boolean;
}

/**
 * Container that enables drag-and-drop feature in quote list.
 *
 * @param {QuoteListDnDProps} props
 */
export const QuoteListDnD: React.FC<QuoteListDnDProps> = props => {
  const { isEmptyList, isDragEvent, rowsContainerRef } = props;

  const scrollHeight =
    rowsContainerRef && rowsContainerRef.current && rowsContainerRef.current.scrollHeight;

  React.useEffect(() => {
    if (
      rowsContainerRef &&
      rowsContainerRef.current &&
      scrollHeight &&
      !props.configurationCardIsOpen
    ) {
      rowsContainerRef.current.scrollTop = scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollHeight]);

  const dropZone = (droppableSnapshot: DroppableStateSnapshot) => {
    return (
      !props.disabled && (
        <QuoteListDropZone
          elementRef={rowsContainerRef}
          isDragEvent={isDragEvent}
          isDraggingOver={droppableSnapshot.isDraggingOver}
          isEmptyList={isEmptyList}
        />
      )
    );
  };

  return (
    <Droppable droppableId="lineItemsList">
      {(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => (
        <div
          ref={droppableProvided.innerRef}
          style={{ height: '100%' }}
          {...droppableProvided.droppableProps}
        >
          {dropZone(droppableSnapshot)}
          {props.children}
        </div>
      )}
    </Droppable>
  );
};
