import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { CommandButtonAtom } from 'components/atoms';
import { ThemeProps } from 'styles';
import { mergeClassNames } from 'components/utilities';

const styles = (theme: ThemeProps) => ({
  text: {
    color: theme.palette.dangerText,
    paddingTop: 15,
    paddingBottom: 15,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.dangerHover,
      '& i': {
        color: theme.palette.dangerHover,
      },
    },
    '&:focus': {
      backgroundColor: 'transparent',
      color: theme.palette.dangerHover,
      '& i': {
        color: theme.palette.dangerHover,
      },
    },
    '&:disabled': {
      color: theme.palette.textDisabled,
    },
    '& i': {
      color: theme.palette.dangerText,
      fontSize: theme.fonts.fontSizes.larger,
    },
  },
});

type Props = ButtonSharedProps & WithStyles<typeof styles>;

const RemoveButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <CommandButtonAtom
      addClass={mergeClassNames([props.classes.text, props.addClass])}
      ariaLabel={props.ariaLabel}
      componentRef={props.componentRef}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{
        iconName: props.iconName || 'Remove',
      }}
      id={props.id}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const RemoveButton = withStyles(styles)(RemoveButtonUnstyled) as React.FC<ButtonSharedProps>;
