import { CalendarDropdown, InfoButton, Label, TextCurrency, TextPrice } from 'components';
import {
  CalloutCalendarProps,
  Checkbox,
  ComboBox,
  DetailsList,
  SectionSeparator,
  SmallIcon,
  TextBody,
  TextBodySmall,
  TextWatermark,
} from 'components/ions';
import { calendarStrings } from 'components/utilities/calendarStrings';
import { dateFormat } from 'components/utilities/dates';
import {
  ConfigCardDropdownOptions,
  CreateLineItemProperties,
} from 'features/proposal/components/ConfigCard';
import { convertDateToUTC, formatCurrency, lineItemStartDateInPast } from 'features/proposal/utils';
import i18next from 'i18n';
import moment from 'moment-timezone';
import {
  CheckboxVisibility,
  IColumn,
  IComboBox,
  IComboBoxOption,
  IDropdownOption,
  IObjectWithKey,
  Selection as FabricSelection,
  SelectionMode,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { configCardBodyStyles } from './ConfigCardBody.styles';

export enum ConfigCardMainBodyContent {
  LIST = 'LIST',
  WATERMARK_MESSAGE = 'WATERMARK_MESSAGE',
}

const placeholderSelectSkuOptionsSelectedKey = 'Select SKU to view options';
const placeholderNoSkuOptionsSelectedKey = 'No options';

export interface ConfigCardBodyProps {
  disableSelectionZone?: boolean;
  mainBodyContentChoice: ConfigCardMainBodyContent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listItems: any[];
  createLineItems: CreateLineItemProperties[];
  initialSelectedListKey?: string;
  showTerms?: boolean;
  showRecurringBilling?: boolean;
  showMessage?: boolean;
  recurringBillingEnabled?: boolean;
  modernOfficeEnabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onListSelection: (key: string, skuRow: any) => void;
  onClearListSelection: () => void;
  onRecurringCheckboxToggle: () => void;
  onTermSelection: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  onStartDateAdded: (date: Date) => void;
  onStartSelectionChanged: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  onBillingPlanSelection: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => void;
  isNegotiatedTerm: boolean;
  isPasses: boolean;
  columns?: IColumn[];
  message: JSX.Element;
  productHasBillingPlans: boolean;
  termsDropdownOptions: ConfigCardDropdownOptions;
  billingPlanDropdownOptions: ConfigCardDropdownOptions;
  startDateDropdownOptions: ConfigCardDropdownOptions;
  defaultMinStartDate: Date;
  watermarkMessage: string;
  enrollmentNumber?: string;
  price?: string;
  currency: string;
}

type Props = ConfigCardBodyProps & WithStyles<typeof configCardBodyStyles>;

export const prepareDropdownOptionsForDisplay = (
  skuIsSelected: boolean,
  dropdownOptions: IDropdownOption[],
  placeholderSelectSkuOptions: IDropdownOption[],
  placeholderNoSkuOptions: IDropdownOption[],
  selectedKey?: string
) => {
  let key: string | undefined = selectedKey;
  let options = dropdownOptions;
  if (!skuIsSelected) {
    key = placeholderSelectSkuOptionsSelectedKey;
    options = placeholderSelectSkuOptions;
  } else if (skuIsSelected && !dropdownOptions.length) {
    key = placeholderNoSkuOptionsSelectedKey;
    options = placeholderNoSkuOptions;
  }
  return { key, options };
};

export class ConfigCardBodyUnstyled extends React.PureComponent<Props> {
  public readonly selection = new FabricSelection({
    onSelectionChanged: this.onSelection.bind(this),
    canSelectItem: (item: IObjectWithKey) => {
      return (item as { selectable: boolean }).selectable;
    },
  });

  public componentDidMount(): void {
    if (!this.selection.getSelectedCount()) {
      this.selection.setItems(this.props.listItems as IObjectWithKey[]);
      if (this.props.initialSelectedListKey) {
        this.selection.setKeySelected(this.props.initialSelectedListKey, true, false);
      }
    }
  }

  public onSelection(): void {
    const selection = this.selection.getSelection();
    if (selection && selection.length) {
      const sel = selection[0] as IObjectWithKey;
      if (sel.key) {
        this.props.onListSelection(sel.key.toString(), sel);
      }
    } else {
      this.props.onClearListSelection();
    }
  }

  public render() {
    const {
      classes,
      mainBodyContentChoice,
      columns,
      termsDropdownOptions,
      startDateDropdownOptions,
      defaultMinStartDate,
      recurringBillingEnabled,
      modernOfficeEnabled,
      showTerms,
      listItems,
      showMessage,
      isNegotiatedTerm,
      isPasses,
      message,
      onTermSelection,
      productHasBillingPlans,
      onStartDateAdded,
      onStartSelectionChanged,
      showRecurringBilling,
      onRecurringCheckboxToggle,
      watermarkMessage,
      enrollmentNumber,
      billingPlanDropdownOptions,
    } = this.props;
    const skuIsSelected = !!this.selection.getSelection().length;
    const placeholderSelectSkuOptions = [
      {
        key: placeholderSelectSkuOptionsSelectedKey,
        text: i18next.t('quote::Select SKU to view options'),
      },
    ];
    const placeholderNoSkuOptions = [
      {
        key: placeholderNoSkuOptionsSelectedKey,
        text: i18next.t('quote::No options'),
      },
    ];

    let termOptions = termsDropdownOptions.options;
    let termSelectedKey = termsDropdownOptions.initialSelectedKey;
    let billingPlanOptions = billingPlanDropdownOptions.options;
    let billingPlanSelectedKey = billingPlanDropdownOptions.initialSelectedKey;

    const billingPlanDisplayOptions = prepareDropdownOptionsForDisplay(
      skuIsSelected,
      billingPlanOptions,
      placeholderSelectSkuOptions,
      placeholderNoSkuOptions,
      billingPlanSelectedKey
    );
    billingPlanSelectedKey = billingPlanDisplayOptions.key;
    billingPlanOptions = billingPlanDisplayOptions.options;
    const termDisplayOptions = prepareDropdownOptionsForDisplay(
      skuIsSelected,
      termOptions,
      placeholderSelectSkuOptions,
      placeholderNoSkuOptions,
      termSelectedKey
    );
    termSelectedKey = termDisplayOptions.key || '';
    termOptions = termDisplayOptions.options;

    const terms = (
      <div className={classes.termsContainerFlexbox}>
        <div className={showTerms && !isPasses ? classes.termsDropdown : classes.dropdown}>
          <ComboBox
            disabled={termsDropdownOptions.disabled || !skuIsSelected}
            id="terms"
            label={
              showTerms && !isPasses
                ? i18next.t('quote::Option(s)')
                : i18next.t('quote::Commitment')
            }
            maxHeight={350}
            options={termOptions}
            selectedKey={termSelectedKey}
            onChange={onTermSelection}
          />
        </div>
      </div>
    );

    const recurringBillingCheckbox = (
      <div className={classes.reccuringBillingCheckboxContainer}>
        <Checkbox
          ariaLabel={i18next.t('quote::Recurring Billing')}
          checked={recurringBillingEnabled}
          id="recurringBillingCheckbox"
          label={i18next.t('quote::Recurring Billing')}
          onChange={onRecurringCheckboxToggle}
        />
      </div>
    );

    const today = convertDateToUTC(new Date());
    let defaultDate;
    if (startDateDropdownOptions.initialSelectedKey !== 'order placement') {
      defaultDate = moment(startDateDropdownOptions.initialSelectedKey, dateFormat).toDate();
    } else {
      defaultDate = defaultMinStartDate;
    }
    const renderStartDateCustomLabel = () => (
      <div className={classes.labelContainer}>
        <Label
          className={enrollmentNumber ? undefined : classes.futureStartDateLabel}
          htmlFor="start-date-calendar-dropdown"
        >
          {i18next.t('quote::Starting')}
        </Label>
        {enrollmentNumber && (
          <InfoButton
            ariaLabel={i18next.t('quote::Open information about enrollment dates')}
            calloutProps={{
              closeButtonAriaLabel: i18next.t('Close'),
              headline: i18next.t('quote::Enrollment-set start dates'),
              maxWidth: 288,
            }}
            id="start-date-info-button"
          >
            <TextBody>
              {i18next.t(
                'quote::The date in the "Starting" field has been initially set based on the end date of the enrollment number on the quote plus one day to ensure continuing service for the customer. You can set a future date, but please confirm that your customer will not be out of service.'
              )}
            </TextBody>
          </InfoButton>
        )}
      </div>
    );
    const startingCalendarProps: CalloutCalendarProps = {
      buttonProps: {
        ariaLabel: i18next.t('quote::Open Calendar'),
        disabled: false,
      },
      calendarStrings,
      today,
      defaultDate,
      minDate: defaultMinStartDate,
      showTodayButton: true,
      onSelectDate: onStartDateAdded,
      isDayPickerVisible: true,
    };
    // TODO - jek - implement onRenderLabel property for ComboBoxAtom
    const selectedStartDate = startDateDropdownOptions.initialSelectedKey
      ? new Date(startDateDropdownOptions.initialSelectedKey)
      : undefined;
    const startDate = (
      <div className={classes.startDateField}>
        <div className={classes.dropdown}>
          <CalendarDropdown
            calloutCalendarProps={startingCalendarProps}
            dataAutomationId="startDateCalendar"
            dateErrorMessage={
              !enrollmentNumber && selectedStartDate && lineItemStartDateInPast(selectedStartDate)
                ? i18next.t('quote::Start date cannot occur in the past')
                : undefined
            }
            dropdownDisabled={false}
            dropdownOptions={startDateDropdownOptions.options}
            id="start-date-calendar-dropdown"
            selected={startDateDropdownOptions.initialSelectedKey}
            onDropdownSelect={onStartSelectionChanged}
            onRenderLabel={renderStartDateCustomLabel}
          />
        </div>
        {enrollmentNumber &&
          // Do not need moment.utc() because the defaultMinStartDate already represents the UTC date.
          startDateDropdownOptions.initialSelectedKey !==
            moment(defaultMinStartDate, dateFormat).format(dateFormat) && (
            <div className={classes.startDateNotification}>
              <SmallIcon addClass={classes.warningIcon} iconName="Warning" />
              <TextBodySmall addClass={classes.warningText}>
                {i18next.t(
                  "quote::The initial date was set based off of the customer's enrollment."
                )}
                <div />
                {i18next.t('quote::Please ensure that the customer will not be out of service.')}
              </TextBodySmall>
            </div>
          )}
      </div>
    );

    const billingPlans = (
      <div className={classes.billingPlansDropdown}>
        <ComboBox
          dataAutomationId="billingPlansComboBox"
          disabled={!skuIsSelected || billingPlanSelectedKey === placeholderNoSkuOptionsSelectedKey}
          label={i18next.t('quote::Billing Plan')}
          options={billingPlanOptions}
          selectedKey={billingPlanSelectedKey}
          onChange={this.props.onBillingPlanSelection}
        />
      </div>
    );

    const priceDisplay = (
      <div>
        <SectionSeparator addClass={classes.priceSeparator} />
        <div className={classes.priceContainer}>
          <TextBody addClass={classes.priceLabel}>
            {i18next.t('quote::Subscription price (per seat):')}
          </TextBody>
          <TextPrice addClass={classes.price}>
            {this.props.price ? formatCurrency(this.props.price) : '-'}
          </TextPrice>
          <TextCurrency addClass={classes.currency}>{this.props.currency}</TextCurrency>
        </div>
      </div>
    );

    const additionalOptionsArea = (
      <div className={this.props.classes.additionalOptionsArea}>
        {showTerms && !isPasses && terms}
        {modernOfficeEnabled && showTerms && isPasses && startDate}
        {modernOfficeEnabled && showTerms && isPasses && terms}
        {modernOfficeEnabled && productHasBillingPlans && billingPlans}
        {showRecurringBilling && recurringBillingCheckbox}
      </div>
    );

    const messageArea = (
      <div className={classes.messageArea} data-automation-id="configCardBodyMessage">
        {message}
      </div>
    );

    const listBody = (
      <div>
        {showMessage && messageArea}
        {isNegotiatedTerm && (
          <div>
            <TextBody addClass={this.props.classes.termsHeading}>
              {`${i18next.t('quote::Please select the option to apply to this term')}:`}
            </TextBody>
            <SectionSeparator />
          </div>
        )}
        <div className={classes.detailsListContainer}>
          <DetailsList
            addClass={this.props.classes.detailsList}
            checkboxVisibility={CheckboxVisibility.always}
            columns={columns}
            compact={true}
            disableSelectionZone={this.props.disableSelectionZone}
            isHeaderVisible={!isNegotiatedTerm}
            items={listItems}
            selection={this.selection}
            selectionMode={SelectionMode.single}
            shouldResizeAfterFirstRender={true}
          />
        </div>
        <SectionSeparator />
        {additionalOptionsArea}
        {isPasses && modernOfficeEnabled && priceDisplay}
      </div>
    );

    const watermark = (
      <div className={this.props.classes.watermarkContainer}>
        <TextWatermark dataAutomationId="configCardWaterMark">{watermarkMessage}</TextWatermark>
      </div>
    );

    const mainBodyContent =
      mainBodyContentChoice === ConfigCardMainBodyContent.LIST ? listBody : watermark;

    return <div>{mainBodyContent}</div>;
  }
}

export const ConfigCardBody = withStyles(configCardBodyStyles)(ConfigCardBodyUnstyled);
