import { TextTitle } from 'components/ions/Text';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { propertySheetLayoutStyles } from './styles';

export interface PropertySheetLayoutProps {
  mainContent: { leftPaneTitle: string; mainContent: React.ReactNode }[];
}

type Props = PropertySheetLayoutProps & WithStyles<typeof propertySheetLayoutStyles>;

export const PropertySheetLayoutUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, mainContent } = props;

  const contents =
    mainContent &&
    mainContent.map(({ leftPaneTitle, mainContent }) => (
      <div className={classes.columnFlex} key={leftPaneTitle}>
        <div className={classes.rowFlex}>
          <div className={classes.leftPane}>
            <TextTitle addClass={classes.propertySheetTitleHeader}>{leftPaneTitle}</TextTitle>
          </div>
          <div className={classes.flexPageContent}>
            <div className={classes.mainContent}>
              <div className={classes.flexMainContent}>{mainContent}</div>
              <div className={classes.flexRightEmptySpace} />
            </div>
          </div>
        </div>
      </div>
    ));

  return <div className={classes.columnFlexMainContent}>{contents}</div>;
};

export const PropertySheetLayout = withStyles(propertySheetLayoutStyles)(
  PropertySheetLayoutUnstyled
);
