import React, { PropsWithChildren } from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { RenderIfExists } from '../components';
import { ThemeProps } from 'styles';

const WithCommandRowStyles = (theme: ThemeProps) => {
  return {
    bodyCommandBar: {
      width: '100%',
      boxShadow: `0 -2px 18px ${theme.palette.shadowColor}`,
    },
    contentRow: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      width: '100%',
      overflowY: 'hidden',
      overflowX: 'auto',
    },
  };
};

interface Props extends WithStyles<typeof WithCommandRowStyles> {
  commandBar?: React.ReactNode;
}

const WithCommandRow: React.FC<Props> = ({
  classes,
  commandBar,
  children,
}: PropsWithChildren<Props>) => (
  <React.Fragment>
    <RenderIfExists wrapperClass={classes.bodyCommandBar}>{commandBar}</RenderIfExists>
    <div className={classes.contentRow}>{children}</div>
  </React.Fragment>
);

export default withStyles(WithCommandRowStyles)(WithCommandRow);
