import { ThemeProps } from 'styles';
import { UploadFileProps, UploadError } from './UploadFile';

export const uploadFileStyles = (theme: ThemeProps) => ({
  attachIcon: {
    color: theme.palette.text,
  },
  completeFileName: {
    color: theme.palette.text,
    position: 'absolute',
    left: 36,
    top: 10,
    maxWidth: 375,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  completeView: (props: UploadFileProps) => {
    const { width } = props;
    return {
      position: 'relative',
      height: 40,
      width,
      borderRadius: 2,
      border: `1px solid ${theme.palette.backgroundDivider}`,
      '& i': {
        color: theme.palette.textTertiary,
      },
    };
  },
  downIcon: {
    color: theme.palette.primary,
    verticalAlign: 'text-bottom',
  },
  errorFileName: (props: UploadFileProps) => {
    const { error } = props;
    const isUploadError = error === UploadError.UploadFailed;
    return {
      position: 'absolute',
      left: 36,
      top: 10,
      maxWidth: isUploadError ? 250 : 150,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      backgroundImage: `linear-gradient(to right, ${theme.palette.text} 0px, transparent ${
        isUploadError ? '250px' : '150px'
      })`,
      color: 'transparent',
      '-webkit-background-clip': 'text',
      backgroundClip: 'text',
    };
  },
  errorMessage: {
    color: theme.palette.dangerText,
    position: 'absolute',
    right: 40,
    top: 10,
  },
  errorView: (props: UploadFileProps) => {
    const { width } = props;
    return {
      position: 'relative',
      height: 40,
      width,
      background: theme.palette.dangerHighlight,
      borderRadius: 2,
      border: `1px solid ${theme.palette.dangerText}`,
      '& i': {
        color: theme.palette.dangerText,
      },
    };
  },
  initialContent: {
    position: 'absolute',
    left: 84,
    top: 15,
  },
  initialText: {
    color: theme.palette.text,
    paddingLeft: 8,
  },
  initialView: (props: UploadFileProps) => {
    const { width, height } = props;
    return {
      position: 'relative',
      height,
      width,
      textAlign: 'center',
      borderRadius: 2,
    };
  },
  initialContentActive: {
    color: theme.palette.primary,
    position: 'absolute',
    left: 190,
    top: 20,
  },
  initialTextActive: {
    color: theme.palette.primary,
    paddingLeft: 8,
  },
  initialViewActive: (props: UploadFileProps) => {
    const { width, height } = props;
    return {
      position: 'relative',
      height,
      width,
      textAlign: 'center',
      background: theme.palette.backgroundDivider,
      border: `1px solid ${theme.palette.primary}`,
      borderRadius: 2,
      opacity: 0.7,
    };
  },
  itemProgress: {
    borderRadius: '50%',
    padding: 0,
  },
  selectFile: {
    color: theme.palette.text,
    paddingLeft: 16,
    paddingRight: 16,
  },
  leftActionIcon: {
    color: theme.palette.textTertiary,
    position: 'absolute',
    right: 38,
  },
  loadingContainer: (props: UploadFileProps) => {
    const { width } = props;
    return {
      width,
    };
  },
  loadingFileName: {
    color: theme.palette.textTertiary,
    position: 'absolute',
    left: 36,
    top: 10,
    maxWidth: 410,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  loadingView: (props: UploadFileProps) => {
    const { width } = props;
    return {
      position: 'relative',
      height: 40,
      width,
      borderRadius: 2,
      border: `1px solid ${theme.palette.backgroundDivider}`,
    };
  },
  openFileIcon: {
    color: theme.palette.textTertiary,
    position: 'absolute',
    top: 13,
    left: 12,
  },
  progressBar: {
    background: theme.palette.progressBar,
  },
  progressTrack: {
    backgroundColor: 'transparent',
  },
  rightActionIcon: {
    color: theme.palette.textTertiary,
    position: 'absolute',
    right: 6,
  },
});
