export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface SignUpConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Prod]:
    'https://quotecenterservice.l2o.microsoft.com/v1/signup/api/signupservice/usersignup',
  [Environment.Int]:
    'https://quotecenterservice.int.l2o.microsoft.com/v1/signup/api/signupservice/usersignup',
};
