import { IComboBoxOption } from 'office-ui-fabric-react';

const proposalTypeComboBoxOptions: IComboBoxOption[] = [
  {
    key: 'Standard',
    text: 'Standard',
  },
  {
    key: 'Legacy',
    text: 'Legacy',
  },
];

export const getComboBoxProposalTypes = (): IComboBoxOption[] => {
  return proposalTypeComboBoxOptions;
};
