import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { TextBody, XLargeIcon } from 'components';
import { ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  articleLoadError: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 32,
    '& > :not(:first-child)': {
      paddingTop: 16,
    },
  },
  iconContainer: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
    height: 50,
    width: 50,
    backgroundColor: theme.palette.dangerText,
    '& i': {
      color: theme.palette.backgroundStandout,
    },
  },
  icon: {
    fontSize: theme.fonts.fontSizes.xLarger,
  },
});

type Props = {} & WithStyles<typeof styles>;

const ArticleLoadErrorUnStyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.articleLoadError}>
      <div className={classes.iconContainer}>
        <XLargeIcon addClass={classes.icon} iconName="Cancel" />
      </div>
      <TextBody>{t('Content failed to load.')}</TextBody>
      <TextBody>{t('Please try again.')}</TextBody>
    </div>
  );
};

export const ArticleLoadError = withStyles(styles)(ArticleLoadErrorUnStyled);
