import { IChoiceGroupStyles } from 'office-ui-fabric-react';
import { ThemeProps } from 'styles';

export const discountCardStyles = {
  discountInputContainer: {
    width: 105,
    height: 60,
  },
  itallicStyle: {
    '& .ms-TextField-field': {
      fontStyle: 'italic',
    },
  },
  calendarButtonContainer: {
    paddingTop: 30,
  },
  flexSpacer: {
    flex: '1 0 16px',
    maxWidth: '16px',
  },
  gapBetweenDiscountInputAndSeparator: {
    marginTop: 18,
  },
  gapBetweenSeparatorAndSections: {
    marginTop: 12,
  },
  removeDiscount: {
    marginTop: 20,
  },
  errorMessageStyle: {
    width: 216,
  },
  mixedValues: {
    marginTop: 10,
    marginBottom: 8,
    maxWidth: 200,
  },
};

export const linkStyles = (theme: ThemeProps) => ({
  button: {
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:disabled': {
      color: `${theme.palette.textTertiary} !important`,
    },
    '&:hover:disabled': {
      color: theme.palette.textTertiary,
    },
  },
  relativePositionStyles: {
    position: 'relative',
  },
});

export const choiceGroupStyles: IChoiceGroupStyles = {
  flexContainer: {},
  root: {
    marginTop: 14,
    selectors: {
      '.ms-ChoiceField': {
        display: 'inline-block',
        paddingRight: 12,
      },
    },
  },
};

export const discountCardFutureSectionStyles = {
  bottomMargin: {
    marginBottom: 0,
    marginTop: 16,
    width: '222px',
  },
  metersDropdown: {
    width: 174,
  },
  calendarDropdownMargin: {
    marginBottom: 12,
  },
  itallicComboboxInputStyle: {
    '& .ms-ComboBox-Input': {
      fontStyle: 'italic',
    },
  },
};

export const discountCardCeilingStyles = {
  calendarDropdownMargin: {
    marginBottom: 12,
  },
  contentContainer: {
    width: 222,
    marginTop: 16,
  },
};
