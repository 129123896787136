import * as actions from 'features/ldsscustomer/actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { LDSSConfig } from 'services/ldss/config';
import { Customer, CustomerSearchResponse } from 'services/ldss/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

const addTpidToCustomer = (response: CustomerSearchResponse): Customer[] => {
  return response.value.value.map((value: Customer) => {
    return { ...value, tpid: value.tpid.toString() };
  });
};

export function* searchCustomers() {
  const relevantAction = actions.searchCustomersAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const config: LDSSConfig = yield select((state: RootState) => state.app.appConfig.ldss);

      let exactResponse: Customer[], wildcardResponse: Customer[], totalResults;
      if (!action.payload.skipItems) {
        const [firstResponse, secondResponse] = yield all([
          call(api.ldss.searchCustomers, action.payload, false, config),
          call(api.ldss.searchCustomers, action.payload, true, config),
        ]);
        wildcardResponse = addTpidToCustomer(secondResponse);
        exactResponse = addTpidToCustomer(firstResponse);
        totalResults = secondResponse.value['@odata.count'];
      } else {
        const response = yield call(api.ldss.searchCustomers, action.payload, true, config);
        wildcardResponse = addTpidToCustomer(response);
        exactResponse = [];
        totalResults = response.value['@odata.count'];
      }

      yield put(
        actions.searchCustomersAsync.success({
          searchTerm: action.payload.searchTerm,
          exactResponse: exactResponse,
          wildcardResponse: wildcardResponse,
          totalResults: totalResults,
          skipItems: action.payload.skipItems,
        })
      );
    } catch (err) {
      yield put(
        actions.searchCustomersAsync.failure({
          message: t('error::Error performing ldss customer search'),
          exception: err,
        })
      );
    }
  });
}
