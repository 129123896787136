import { dateFormat, isDate } from 'components/utilities/dates';
import { cardConstants } from 'features/proposal/components/DiscountCard/cardConstants';
import { Market } from 'features/proposal/supported-markets';
import {
  computeFirstAndLastTimezone,
  displayInDayFormatAndUseMarketTimezone,
} from 'features/proposal/utils';
import moment from 'moment-timezone';
import { Product } from 'services/catalog/types';
import { LineItem, Proposal } from 'services/proposal/types';
import { oc } from 'ts-optchain';

import {
  buildDiscountTypeChoices,
  buildMeterDropdownOptions,
  getDiscountType as getDiscountTypeFromLineItem,
} from './DiscountCardBusinessLogic';
import {
  DiscountCardContainerState,
  DiscountCardDropdownOptions,
  DiscountType,
  MonthPoint,
} from './types';
import {
  addAndSelectDateOption,
  addAndSelectTheKey,
  adjustEndDate,
  computeInitialStartAndEndDates,
  computePriceCeilingDate,
  createMomentObjectFromFabricCalendar,
  formatDropdownDates,
  resetEndDate,
  resetStartDate,
  selectOption,
} from './utils';

const monthsOfYearChoices = [];
for (let i = 1; i <= 11; i++) {
  const suffix = i === 1 ? 'month' : 'months';
  const month = `${i} ${suffix}`;
  monthsOfYearChoices.push({ text: month, key: `P${i}M` });
}

const mixedValues = { text: '(mixed)', key: 'mixed' };

const endDurations = [
  ...monthsOfYearChoices,
  { text: '1 year', key: 'P12M' },
  { text: '18 months', key: 'P18M' },
  { text: '2 years', key: 'P24M' },
  { text: '3 years', key: 'P36M' },
];

const generateStartOptions = (dayPicker: boolean): DiscountCardDropdownOptions => {
  return {
    options: dayPicker
      ? [{ text: 'At order placement', key: 'order placement' }]
      : [{ text: 'Acceptance month', key: 'order placement' }],
    selectedKey: 'order placement',
  };
};

const endOptions: DiscountCardDropdownOptions = {
  options: endDurations,
  selectedKey: 'P18M',
};

const defaultPriceCeilingOptions: DiscountCardDropdownOptions = {
  options: [{ text: 'At order placement', key: 'At order placement' }],
  selectedKey: 'At order placement',
};

const generateStateDefaultOptions = (dayPicker: boolean) => ({
  futureStartOptions: generateStartOptions(dayPicker),
  futureEndOptions: endOptions,
  ceilingPriceCeilingOptions: defaultPriceCeilingOptions,
  ceilingStartOptions: generateStartOptions(dayPicker),
  ceilingEndOptions: endOptions,
});

const generateStateDefaults = (dayPicker: boolean): DiscountCardContainerState => {
  return {
    ...generateStateDefaultOptions(dayPicker),
    discountTypeChoices: [],
    discountType: 'Future',
    discountAmount: '0',
    futureShowMeters: false,
    startAndEndDatesAreDayPickers: true,
    futureMetersOptions: {
      options: [{ text: 'Meter 1', key: 'Meter 1' }],
      selectedKey: 'Meter 1',
    },
  };
};

export const computeInitialDiscountOptions = (
  productType: string,
  discountType: DiscountType,
  market: Market,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  startDate?: Date,
  endDate?: Date,
  duration?: string,
  action?: string,
  ceilingDate?: Date
): {
  futureStartOptions: DiscountCardDropdownOptions;
  futureEndOptions: DiscountCardDropdownOptions;
  ceilingPriceCeilingOptions: DiscountCardDropdownOptions;
  ceilingStartOptions: DiscountCardDropdownOptions;
  ceilingEndOptions: DiscountCardDropdownOptions;
} => {
  const timezones = computeFirstAndLastTimezone(
    market,
    shouldDisplayInDayFormatAndUseMarketTimezone
  );
  if (discountType === 'One Time') {
    return generateStateDefaultOptions(shouldDisplayInDayFormatAndUseMarketTimezone);
  }
  if (!startDate && !endDate && !duration) {
    return generateStateDefaultOptions(shouldDisplayInDayFormatAndUseMarketTimezone);
  }
  const defaultOptions = generateStateDefaults(shouldDisplayInDayFormatAndUseMarketTimezone);
  let startOptions = defaultOptions.ceilingStartOptions;
  let endOptions = defaultOptions.futureEndOptions;

  let ceilingPriceCeilingOptions = defaultOptions.ceilingPriceCeilingOptions;
  let ceilingStartOptions = defaultOptions.ceilingStartOptions;
  let ceilingEndOptions = defaultOptions.futureEndOptions;
  let futureStartOptions = defaultOptions.ceilingStartOptions;
  let futureEndOptions = defaultOptions.futureEndOptions;

  if (startDate && endDate) {
    const { start, end } = computeInitialStartAndEndDates(
      startDate.toString(),
      endDate.toString(),
      timezones,
      productType,
      action
    );
    startOptions = addAndSelectDateOption(start, defaultOptions.ceilingStartOptions);
    endOptions = addAndSelectDateOption(end, defaultOptions.futureEndOptions);
  }

  if (discountType === 'Ceiling') {
    ceilingStartOptions = startOptions;
    ceilingEndOptions = endOptions;
    const priceCeilingDate = computePriceCeilingDate(
      timezones,
      ceilingDate && ceilingDate.toString()
    );

    if (duration && !priceCeilingDate) {
      ceilingEndOptions = selectOption(duration, defaultOptions.futureEndOptions.options);
    }
    if (priceCeilingDate) {
      ceilingPriceCeilingOptions = addAndSelectDateOption(
        priceCeilingDate,
        defaultOptions.ceilingPriceCeilingOptions
      );
    }
  } else {
    futureStartOptions = startOptions;
    futureEndOptions = endOptions;
    if (duration) {
      futureEndOptions = selectOption(duration, defaultOptions.futureEndOptions.options);
    }
  }
  return {
    futureStartOptions,
    futureEndOptions,
    ceilingPriceCeilingOptions,
    ceilingStartOptions,
    ceilingEndOptions,
  };
};

export const generateInitialMultiDiscountFutureState = (initial: {
  lineItems: LineItem[];
}): DiscountCardContainerState => {
  const { lineItems } = initial;
  let anyStartDatesConfigured = false;
  let anyEndDatesConfigured = false;
  let anyDiscountApplied = false;

  let configuredCount = 0;
  lineItems.forEach(item => {
    let hasConfiguredOptions = false;

    if (item.startDate) {
      anyStartDatesConfigured = true;
      hasConfiguredOptions = true;
    }
    if (item.endDate || item.duration) {
      anyEndDatesConfigured = true;
      hasConfiguredOptions = true;
    }

    if (oc(item).pricingInstruction.discountPercentage(0) > 0) {
      anyDiscountApplied = true;
      hasConfiguredOptions = true;
    }

    if (hasConfiguredOptions) {
      configuredCount++;
    }
  });

  const discountTypeChoices = [{ key: cardConstants.future, text: cardConstants.future }];
  const discountType = 'Future';
  let discountOptions = generateStateDefaultOptions(false);
  const defaultStart = discountOptions.futureStartOptions.selectedKey;
  const defaultEnd = discountOptions.futureEndOptions.selectedKey;
  if (anyStartDatesConfigured) {
    discountOptions.futureStartOptions = addAndSelectTheKey(
      mixedValues.key,
      mixedValues.text,
      discountOptions.futureStartOptions
    );
  }
  if (anyEndDatesConfigured) {
    discountOptions.futureEndOptions = addAndSelectTheKey(
      mixedValues.key,
      mixedValues.text,
      discountOptions.futureEndOptions
    );
  }

  const state: DiscountCardContainerState = {
    ...generateStateDefaults,
    defaultBulkFutureEnd: defaultEnd,
    defaultBulkFutureStart: defaultStart,
    futureMetersOptions: { options: [], selectedKey: '' },
    futureStartOptions: discountOptions.futureStartOptions,
    futureEndOptions: discountOptions.futureEndOptions,
    futureShowMeters: false,
    ceilingPriceCeilingOptions: discountOptions.ceilingPriceCeilingOptions,
    ceilingStartOptions: discountOptions.ceilingStartOptions,
    ceilingEndOptions: discountOptions.ceilingEndOptions,
    discountAmount: anyDiscountApplied ? mixedValues.text : '0',
    discountTypeChoices,
    startAndEndDatesAreDayPickers: false,
    discountType,
    hasMixedValues: anyDiscountApplied || anyEndDatesConfigured || anyStartDatesConfigured,
    configuredItemsCount: configuredCount,
  };
  return state;
};

export const generateInitialState = (initial: {
  hydratedProduct: Product;
  lineItem: LineItem;
  proposal: Proposal;
}): DiscountCardContainerState => {
  const { lineItem, hydratedProduct, proposal } = initial;
  const shouldDisplayInDayFormatAndUseMarketTimezone = displayInDayFormatAndUseMarketTimezone(
    hydratedProduct.ProductType,
    lineItem.productIdentifier.action
  );
  const discountTypeChoices = buildDiscountTypeChoices(hydratedProduct, lineItem);
  const discountType = getDiscountTypeFromLineItem(lineItem, discountTypeChoices);
  let discountOptions = generateStateDefaultOptions(shouldDisplayInDayFormatAndUseMarketTimezone);
  if (lineItem.pricingInstruction && lineItem.pricingInstruction.ruleType) {
    discountOptions = computeInitialDiscountOptions(
      hydratedProduct.ProductType,
      discountType,
      proposal.header.pricingContext.market,
      shouldDisplayInDayFormatAndUseMarketTimezone,
      lineItem.startDate,
      lineItem.endDate,
      lineItem.duration,
      lineItem.productIdentifier.action,
      lineItem.pricingInstruction.priceGuaranteeDate
    );
  }
  const options = buildMeterDropdownOptions(lineItem, hydratedProduct);
  const hasSelectedMeter =
    lineItem.pricingInstruction &&
    lineItem.pricingInstruction.conditions &&
    lineItem.pricingInstruction.conditions.find(condition => condition.name === 'MeterType');
  const selectedMeter =
    (hasSelectedMeter && hasSelectedMeter.value[0]) ||
    (options.length && options[0].key.toString());
  const selectedMeterOption = options.find(meter => meter.text === selectedMeter);
  const meterDropdownOptions: DiscountCardDropdownOptions = {
    options,
    selectedKey: selectedMeterOption ? selectedMeterOption.key.toString() : selectedMeter || '',
  };
  const state: DiscountCardContainerState = {
    ...generateStateDefaults,
    futureStartOptions: discountOptions.futureStartOptions,
    futureEndOptions: discountOptions.futureEndOptions,
    futureMetersOptions: meterDropdownOptions,
    futureShowMeters: !!buildMeterDropdownOptions(lineItem, hydratedProduct).length,
    ceilingPriceCeilingOptions: discountOptions.ceilingPriceCeilingOptions,
    ceilingStartOptions: discountOptions.ceilingStartOptions,
    ceilingEndOptions: discountOptions.ceilingEndOptions,
    discountAmount: oc(lineItem)
      .pricingInstruction.discountPercentage(0)
      .toString(),
    discountTypeChoices,
    startAndEndDatesAreDayPickers: shouldDisplayInDayFormatAndUseMarketTimezone,
    discountType,
  };
  return state;
};

export const getInitialState = (initial: {
  hydratedProducts: Product[];
  lineItems: LineItem[];
  proposal: Proposal;
}): DiscountCardContainerState => {
  const { lineItems, hydratedProducts, proposal } = initial;
  if (lineItems && lineItems.length > 1) {
    return generateInitialMultiDiscountFutureState({
      lineItems,
    });
  }
  return generateInitialState({
    hydratedProduct: hydratedProducts[0],
    lineItem: lineItems[0],
    proposal,
  });
};

export const startAdd = (
  selectedDate: string,
  isDay: boolean,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions
) => {
  const start = createMomentObjectFromFabricCalendar(selectedDate, isDay, MonthPoint.BEGINNING);
  const newStartOptions = addAndSelectDateOption(start, startOptions);
  const newEndOptions = adjustEndDate(start, endOptions, !isDay);
  return { newStartOptions, newEndOptions };
};

export const startSelect = (
  selectedDate: string,
  isDay: boolean,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions
) => {
  if (!isDate(selectedDate)) {
    const newStartOptions = selectOption(selectedDate, startOptions.options);
    const newEndOptions = resetEndDate(endOptions);
    return { newStartOptions, newEndOptions };
  }
  const start = createMomentObjectFromFabricCalendar(selectedDate, isDay, MonthPoint.BEGINNING);

  const { key } = formatDropdownDates(start, dateFormat);
  const newEndOptions = adjustEndDate(start, endOptions, !isDay);
  const newStartOptions = selectOption(key, startOptions.options);
  return { newStartOptions, newEndOptions: newEndOptions };
};

export const endAdd = (
  selectedDate: string,
  isDay: boolean,
  endOptions: DiscountCardDropdownOptions
) => {
  const end = createMomentObjectFromFabricCalendar(selectedDate, isDay, MonthPoint.END);
  const newEndOptions = addAndSelectDateOption(end, endOptions);
  return newEndOptions;
};

export const endSelect = (
  selected: string,
  isDay: boolean,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions
) => {
  //When end date is a date we just put it
  if (isDate(selected)) {
    const end = createMomentObjectFromFabricCalendar(selected, isDay, MonthPoint.END);
    const { key } = formatDropdownDates(end, dateFormat);
    return selectOption(key, endOptions.options);
  }
  //When both of them are duration just return
  else if (!isDate(selected) && !isDate(startOptions.selectedKey)) {
    return selectOption(selected, endOptions.options);
  }
  // When only end date is a duration update the end date with a date
  const key = selected;
  let newEndOptions = selectOption(key, endOptions.options);
  newEndOptions = adjustEndDate(moment(startOptions.selectedKey), newEndOptions, !isDay);
  return newEndOptions;
};

export const priceCeilingAdd = (
  selectedDate: string,
  priceCeilingOptions: DiscountCardDropdownOptions,
  ceilingStartOptions: DiscountCardDropdownOptions,
  ceilingEndOptions: DiscountCardDropdownOptions
) => {
  const priceCeiling = createMomentObjectFromFabricCalendar(
    selectedDate,
    true,
    MonthPoint.BEGINNING
  );
  const ceiling = createMomentObjectFromFabricCalendar(selectedDate, true, MonthPoint.BEGINNING);
  const start = moment(ceilingStartOptions.selectedKey);
  if (ceiling.isAfter(start) && start.month() !== ceiling.month()) {
    return {
      newPriceCeilingOptions: addAndSelectDateOption(priceCeiling, priceCeilingOptions),
      newStartCeilingOptions: resetStartDate(ceilingStartOptions),
      newEndCeilingOptions: resetEndDate(ceilingEndOptions),
    };
  }
  return {
    newPriceCeilingOptions: addAndSelectDateOption(priceCeiling, priceCeilingOptions),
    newStartCeilingOptions: ceilingStartOptions,
    newEndCeilingOptions: ceilingEndOptions,
  };
};

export const priceCeilingSelect = (
  selected: string,
  priceCeilingOptions: DiscountCardDropdownOptions,
  ceilingStartOptions: DiscountCardDropdownOptions,
  ceilingEndOptions: DiscountCardDropdownOptions
) => {
  if (!isDate(selected)) {
    const newPriceCeilingOptions = selectOption(selected, priceCeilingOptions.options);
    const newCeilingStartOptions = resetStartDate(ceilingStartOptions);
    const newCeilingEndOptions = resetEndDate(ceilingEndOptions);
    return {
      newPriceCeilingOptions,
      newCeilingStartOptions,
      newCeilingEndOptions,
    };
  }

  const priceCeiling = createMomentObjectFromFabricCalendar(selected, true, MonthPoint.BEGINNING);
  const start = moment(ceilingStartOptions.selectedKey);
  const { key } = formatDropdownDates(priceCeiling, dateFormat);
  const newPriceCeilingOptions = selectOption(key, priceCeilingOptions.options);
  if (priceCeiling.isAfter(start) && start.month() !== priceCeiling.month()) {
    return {
      newPriceCeilingOptions,
      newCeilingStartOptions: resetStartDate(ceilingStartOptions),
      newCeilingEndOptions: resetEndDate(ceilingEndOptions),
    };
  }

  return {
    newPriceCeilingOptions,
    newCeilingStartOptions: ceilingStartOptions,
    newCeilingEndOptions: ceilingEndOptions,
  };
};
