import { ThemeProps } from 'styles';

import { buttonSharedStyles } from '../shared.styles';

export const barButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    ...buttonSharedStyles(theme),
    bar: {
      paddingBottom: 0,
      paddingTop: 0,
      '.ms-Button-label': {
        paddingBottom: 1,
      },
      '&:active': {
        color: colors.backgroundMain,
        backgroundColor: colors.secondary,
        '& i': {
          color: colors.backgroundMain,
        },
      },
    },
    productListBar: {
      '& .ms-Button-icon': {
        color: colors.textLink,
      },
    },
    loading: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 5,
      marginLeft: 10,
      fontSize: theme.fonts.fontSizes.medium,
    },
    loadText: { marginLeft: 10, color: colors.textLink },
  };
};
