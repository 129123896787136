import { CalloutCard } from 'components/ions';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { Price } from 'features-apollo/components/Price';
import {
  Currency,
  EcifConfigLineItem,
  EcifLineItem,
  Product,
  QuoteMutationInput,
} from 'generated/graphql';
import { DirectionalHint } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { ApplyEcifConfiguration } from './queries';
import { ServicesConfigCardFields } from './ServicesConfigCardFields';
import { ServicesConfigCardList } from './ServicesConfigCardList';

export interface ServicesConfigCardProps {
  hidden?: boolean;
  headerText: string;
  isProposalReadOnly: boolean;
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  lineItem: EcifLineItem | undefined | null;
  onDismiss: () => void;
  product: Product;
  quote: QuoteMutationInput;
}

export interface EcifLineItemValues {
  type: string;
  productFamily: string;
  skuId: string;
  description: string;
  endDate: Date | string;
  amount: string;
  lineItemId?: string;
}

type Props = ServicesConfigCardProps;

const ServicesConfigCardUnconnected: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const applyButtonStrings = { text: t('quote::Apply'), key: 'Apply' };
  const ecifLineItems = oc(props)
    .lineItem.ecifConfig.lineItems([])
    .map((ecifLineItem: EcifConfigLineItem) => ({
      type: ecifLineItem.type,
      productFamily: ecifLineItem.productFamily,
      skuId: ecifLineItem.sku.skuId,
      description: ecifLineItem.sku.title,
      endDate: ecifLineItem.endDate,
      amount: ecifLineItem.amount,
      lineItemId: ecifLineItem.lineItemId,
    }));
  const [lineItemsList, setLineItemsList] = React.useState<EcifLineItemValues[]>(ecifLineItems);
  let initialLineItemsAmount = 0;
  lineItemsList.forEach(item => {
    initialLineItemsAmount += Number(item.amount);
  });
  const [totalLineItemAmount, setTotalLineItemAmount] = React.useState<number>(
    initialLineItemsAmount
  );
  const [removeLineItemIds, setRemoveLineItemIds] = React.useState<string[]>([]);
  const [applyEcifConfiguration] = useMutation(ApplyEcifConfiguration, {
    refetchQueries: () => [{ query: GET_QUOTE, variables: { id: props.quote.id } }],
  });
  const onClickApply = () => {
    const lineItemToAdd: EcifLineItemValues[] = [];
    lineItemsList.forEach(lineItem => {
      if (!lineItem.lineItemId) {
        lineItemToAdd.push(lineItem);
      }
    });
    applyEcifConfiguration({
      variables: {
        quote: {
          id: props.quote.id,
          etag: props.quote.etag,
        },
        config: {
          productId: props.product.id,
          catalogContext: props.lineItem && {
            ...props.lineItem.catalogContext,
            __typename: undefined,
          },
          addLineItems: lineItemToAdd.length
            ? lineItemToAdd.map(lineItem => ({
                amount: lineItem.amount.toString(),
                endDate: lineItem.endDate,
                productFamily: lineItem.productFamily,
                skuId: lineItem.skuId,
                type: lineItem.type,
              }))
            : [],
          removeLineItemIds,
        },
      },
    });
    props.onDismiss();
  };

  const calculateTotalLineItemsAmount = () => {
    let totalAmount = 0;
    lineItemsList.forEach(item => {
      totalAmount += Number(item.amount);
    });
    return totalAmount;
  };

  const onAddClicked = (lineItem: EcifLineItemValues) => {
    let newLineItems = lineItemsList;
    newLineItems.push(lineItem);
    setLineItemsList([...newLineItems]);
    setTotalLineItemAmount(calculateTotalLineItemsAmount());
  };

  const onDeleteClicked = (index: number) => {
    let newLineItemList = lineItemsList;
    const lineItemId = newLineItemList[index].lineItemId;
    newLineItemList.splice(index, 1);
    setLineItemsList([...newLineItemList]);
    setTotalLineItemAmount(calculateTotalLineItemsAmount());
    if (lineItemId) {
      const existingRemovedLineItemIds = removeLineItemIds;
      existingRemovedLineItemIds.push(lineItemId);
      setRemoveLineItemIds(existingRemovedLineItemIds);
    }
  };

  const content = (
    <div>
      <ServicesConfigCardFields
        ecifConfig={oc(props).lineItem.ecifConfig({ lineItems: [] })}
        product={props.product}
        totalLineItemAmount={totalLineItemAmount}
        onAddClicked={onAddClicked}
      ></ServicesConfigCardFields>
      <ServicesConfigCardList
        ecifConfig={oc(props).lineItem.ecifConfig({ lineItems: [] })}
        lineItems={lineItemsList}
        totalLineItemAmount={totalLineItemAmount}
        onDeleteClicked={onDeleteClicked}
      ></ServicesConfigCardList>
    </div>
  );
  const currency = oc(props).lineItem.ecifConfig.currency(Currency.Usd);
  const amountAsSubHeader = (
    <Price
      amount={oc(props).lineItem.ecifConfig.totalApprovedAmount(0)}
      currency={currency}
      format={{ currency }}
      label={t('quote::Pre-approved for: ')}
    />
  );
  return (
    <CalloutCard
      applyButtonDisabled={false}
      applyButtonStrings={applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      directionalHint={DirectionalHint.rightCenter}
      headerSubText={amountAsSubHeader}
      headerText={props.headerText}
      hidden={props.hidden}
      id={props.lineItemId}
      isBeakVisible
      isReadOnly={props.isProposalReadOnly || (!lineItemsList.length && !removeLineItemIds.length)}
      target={props.target}
      onApply={onClickApply}
      onDismiss={props.onDismiss}
    >
      {content}
    </CalloutCard>
  );
};

export const ServicesConfigCard = ServicesConfigCardUnconnected as React.FC<
  ServicesConfigCardProps
>;
