import { mergeClassNames } from 'components/utilities';
import {
  BaseButton,
  Button,
  CommandBarButton,
  IButton,
  IButtonStyles,
  IContextualMenuProps,
  IIconProps,
  RefObject,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { commandButtonAtomStyles } from './CommandButtonAtom.styles';

type ActionType =
  | HTMLDivElement
  | HTMLSpanElement
  | HTMLButtonElement
  | HTMLAnchorElement
  | BaseButton
  | Button;
export interface CommandButtonAtomProps {
  addClass?: string;
  ariaLabel?: string;
  componentRef?:
    | React.RefObject<IButton>
    | RefObject<IButton>
    | ((ref: IButton | null) => void)
    | undefined;
  disabled?: boolean;
  id?: string;
  text?: string;
  onClick?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
  iconProps?: IIconProps;
  menuProps?: IContextualMenuProps;
  styles?: Partial<IButtonStyles>;
  split?: boolean;
  title?: string;
  dataAutomationId?: string;
  onKeyDown?: (event: React.KeyboardEvent<ActionType>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

type Props = CommandButtonAtomProps & WithStyles<typeof commandButtonAtomStyles>;

const CommandButtonUnstyled: React.FC<Props> = (props: Props) => {
  // TODO jepagan : request Fabric to enable the user of classes to modify styles properties for icon
  const styles: Partial<IButtonStyles> = {
    iconHovered: { color: '' },
    iconPressed: { color: '' },
    ...props.styles,
  };

  return (
    <CommandBarButton
      ariaDescription={props.text}
      ariaLabel={props.ariaLabel}
      className={mergeClassNames([props.classes.command, props.addClass])}
      componentRef={props.componentRef}
      data-automation-id={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={props.iconProps}
      id={props.id}
      menuProps={props.menuProps}
      split={props.split}
      styles={styles}
      text={props.text}
      title={props.title}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    />
  );
};

CommandButtonUnstyled.defaultProps = {
  disabled: false,
  split: false,
};

export const CommandButtonAtom = withStyles(commandButtonAtomStyles)(
  CommandButtonUnstyled
) as React.FC<CommandButtonAtomProps>;
