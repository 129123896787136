import { TestHeaderConfig } from 'services/types';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface AccountExtensionsConfig extends TestHeaderConfig {
  environment: Environment;
}

export const endpoints = {
  [Environment.Prod]: 'https://quotecenterservice.l2o.microsoft.com/v1/accountextensions',
  [Environment.Int]: 'https://quotecenterservice.int.l2o.microsoft.com/v1/accountextensions',
};
