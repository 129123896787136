import {
  Address as AddressIon,
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  TextBody,
  TextTitle,
  VerificationField,
} from 'components';
import { Fail, Processing } from 'features/components/dialogs';
import * as customerActions from 'features/customer/actions';
import * as customerSelectors from 'features/customer/selectors';
import * as actions from 'features/proposal/actions';
import { Success } from 'features/proposal/components/Dialogs/Shared';
import {
  convertingToLegacy,
  errorOnConvertingToLegacy,
  getActiveProposal,
} from 'features/proposal/selectors';
import { enrollmentNumberRegExp, isAgreementTypeLegacy } from 'features/proposal/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Address } from 'services/customer/types';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { LegacyDialogStyles } from './LegacyDialog.styles';

export enum DialogSteps {
  EnterEnrollment = 1,
  Convert = 2,
  Processing = 3,
}

const dialogDimension = {
  height: 340,
  width: 382,
};

export interface LegacyDialogProps {
  proposal: Proposal;
}

const mapStateToProps = (state: RootState) => ({
  processingEnrollment: customerSelectors.processingEnrollment(state),
  organizations: state.customer.organization,
  footprints: state.customer.legacyFootprint,
  convertingToLegacy: convertingToLegacy(state),
  errorOnConvertingToLegacy: errorOnConvertingToLegacy(state),
  isQuoteLegacy: isAgreementTypeLegacy(getActiveProposal(state)),
});

const dispatchProps = {
  searchEnrollment: (request: string) => customerActions.searchEnrollmentNumber(request),
  convertToLegacyAsync: (enrollmentId: string) =>
    actions.convertToLegacyAsync.request(enrollmentId),
};

export type LegacyDialogStateProps = typeof dispatchProps &
  ReturnType<typeof mapStateToProps> &
  LegacyDialogProps;

type Props = LegacyDialogStateProps & WithStyles<typeof LegacyDialogStyles>;

const LegacyDialogUnstyled: React.FC<Props> = props => {
  const {
    classes,
    convertToLegacyAsync,
    footprints,
    organizations,
    processingEnrollment,
    proposal,
    searchEnrollment,
  } = props;
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState<DialogSteps>(DialogSteps.EnterEnrollment);
  const [lastVerified, setLastVerified] = React.useState<string | undefined>();
  const [currentValue, setCurrentValue] = React.useState<string | undefined>();
  const differentOrEmpty = lastVerified !== currentValue || !currentValue;
  const nextDisabled =
    processingEnrollment.loading || processingEnrollment.error || differentOrEmpty;

  const organizationId =
    !nextDisabled &&
    lastVerified &&
    footprints[lastVerified] &&
    footprints[lastVerified].organizationId;
  const address = organizationId && organizations[organizationId].legalEntity.address;

  const isError =
    !differentOrEmpty &&
    !processingEnrollment.loading &&
    (processingEnrollment.error || address === null);

  const context = React.useContext(DialogContext);
  const closeDialog = () => {
    context.closeDialog();
  };

  const createAddressContent = (address?: Address) => {
    return (
      <div style={{ paddingTop: 25 }}>
        {address ? (
          <AddressIon
            address={address}
            dataAutomationId="organizationAddress"
            showCompanyName={true}
          />
        ) : (
          <TextBody>{t('quote::Customer address not found')}</TextBody>
        )}
      </div>
    );
  };

  const getHeader = (titleText: string) => (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="legacyDialog"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{titleText}</TextTitle>
    </DialogHeader>
  );

  const getFooter = (
    footerText: string,
    onButtonClick: () => void,
    disabled: boolean,
    dataAutomationId?: string
  ) => (
    <div className={`${classes.footer} ${classes.flexAlignRight}`}>
      <PrimaryButton
        dataAutomationId={dataAutomationId}
        disabled={disabled}
        text={footerText}
        onClick={onButtonClick}
      />
    </div>
  );

  const EnrollmentBody = (
    <div className={classes.body}>
      <VerificationField
        buttonText={t('quote::Verify')}
        dataAutomationId="enrollmentNumberVerification"
        debounceValue={500}
        errorMessage={t('quote::Enrollment number not found')}
        isError={isError}
        isLoading={processingEnrollment.loading}
        isVerified={!processingEnrollment.error}
        lastVerified={lastVerified}
        textboxAutoFocus={true}
        textboxClassName={classes.enrollmentInput}
        textboxLabel={t('quote::Customer lookup')}
        textboxMaxLength={8}
        textboxPlaceholder={t('quote::Enter enrollment number')}
        textboxRequired={true}
        textboxTitle={t('quote::Enter a 7-8 digit enrollment number')}
        validationErrorMessage={t('quote::Enter a valid enrollment number')}
        verifiedStatusText={t('quote::Verified')}
        onChangeDebounced={(value?: string) => setCurrentValue(value)}
        onValidate={(value: string) => enrollmentNumberRegExp.test(value)}
        onVerify={(value: string) => {
          searchEnrollment(value);
          setLastVerified(value);
        }}
      />
      {address && createAddressContent(address)}
    </div>
  );

  const Enrollment = (
    <CustomDialogBox
      bodySlot={EnrollmentBody}
      dataAutomationId="convertToLegacyEnrollment"
      footerSlot={getFooter(
        t('quote::Next'),
        () => {
          setCurrentStep(DialogSteps.Convert);
        },
        nextDisabled || !address,
        'convertToLegacyEnrollmentNext'
      )}
      headerSlot={getHeader(t('quote::Convert to legacy'))}
      {...dialogDimension}
    />
  );

  const ConvertBody: JSX.Element = (
    <div className={classes.body}>
      <TextBody>
        {t(
          'quote::Products will be restricted to reserved instances and future discounts only. Products must be added after converting the Quote to Legacy. Remove all products to proceed.'
        )}
      </TextBody>
    </div>
  );

  const convert = () => {
    lastVerified && convertToLegacyAsync(lastVerified);
    setCurrentStep(DialogSteps.Processing);
  };

  const ConvertView = (
    <CustomDialogBox
      bodySlot={ConvertBody}
      dataAutomationId="convertToLegacy"
      footerSlot={getFooter(
        t('quote::Convert'),
        convert,
        !!proposal.lineItems.length,
        'convertToLegacyEnrollmentConvert'
      )}
      headerSlot={getHeader(t('quote::Convert to legacy quote?'))}
      {...dialogDimension}
    />
  );

  switch (currentStep) {
    case DialogSteps.Processing:
      if (props.errorOnConvertingToLegacy) {
        return (
          <Fail
            {...dialogDimension}
            closeDialog={closeDialog}
            dataAutomationId="convertLegacyFail"
            message={t('quote::Sorry, the "Convert" action failed.')}
            onTryAgainClick={convert}
          />
        );
      } else if (props.isQuoteLegacy) {
        return (
          <Success
            message={t('quote::Successfully converted to legacy quote')}
            {...dialogDimension}
            closeDialog={closeDialog}
            dataAutomationId="convertLegacySuccess"
          />
        );
      } else {
        return (
          <Processing {...dialogDimension} message2={t('quote::Converting quote to legacy...')} />
        );
      }
    case DialogSteps.Convert:
      return ConvertView;
    case DialogSteps.EnterEnrollment:
    default:
      return Enrollment;
  }
};

const LegacyDialogStyled = withStyles(LegacyDialogStyles)(LegacyDialogUnstyled);

export const LegacyDialog = connect(mapStateToProps, dispatchProps)(LegacyDialogStyled);
