import { ShimmerForBackgroundCommon } from 'components';
import { ShimmerElementType } from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { salesStyles } from './Sales.styles';

type Props = WithStyles<typeof salesStyles>;

const SalesLoadingUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  return (
    <div className={classes.shimmerContainer}>
      <div className={classes.shimmerSection}>
        <ShimmerForBackgroundCommon
          className={classes.shimmer}
          shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]}
          width={110}
        />
        <ShimmerForBackgroundCommon
          className={classes.shimmer}
          shimmerElements={[{ type: ShimmerElementType.line, height: 30 }]}
          width={355}
        />
      </div>
      <div className={classes.shimmerSection}>
        <ShimmerForBackgroundCommon
          className={classes.shimmer}
          shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]}
          width={180}
        />
        <ShimmerForBackgroundCommon
          className={classes.shimmer}
          shimmerElements={[{ type: ShimmerElementType.line, height: 30 }]}
          width={355}
        />
      </div>
    </div>
  );
};

export const SalesLoading = withStyles(salesStyles)(SalesLoadingUnstyled);
