import { CommandButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { closeIconButtonStyles } from './CloseIconButton.styles';

type Props = ButtonSharedProps & WithStyles<typeof closeIconButtonStyles>;

const CloseIconButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <CommandButtonAtom
      addClass={mergeClassNames([props.classes.command, props.classes.icon, props.addClass])}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{ iconName: 'Cancel' }}
      id={props.id}
      onClick={props.onClick}
    />
  );
};

export const CloseIconButton = withStyles(closeIconButtonStyles)(
  CloseIconButtonUnstyled
) as React.FC<ButtonSharedProps>;
