import { Address, TextBodySmall, TextBodyXLarge } from 'components';
import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import { OrganizationSimple } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { getAddressProps } from '../utils';

export interface OrganizationDetailsProps {
  headline?: string;
  organization?: OrganizationSimple;
  /**
   * Displays when organization information is not found
   * @default 'Unknown billing account'
   */
  customUnknwonLabel?: string;
}

const styles = (theme: ThemeProps) => ({
  headline: {
    color: theme.palette.textTertiary,
  },
  name: {
    fontSize: theme.fonts.fontSizes.xLarge,
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  tradeNameClass: {
    color: theme.palette.textTertiary,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 5,
    marginTop: -3,
    fontSize: theme.fonts.fontSizes.medium,
  },
});

type Props = OrganizationDetailsProps & WithStyles<typeof styles>;

export const OrganizationDetailsUnstyled: React.FC<Props> = props => {
  const { classes, organization } = props;
  const { t } = useTranslation();

  return organization ? (
    <Address
      {...getAddressProps(organization.address)}
      addCompanyNameClass={classes.name}
      headline={props.headline}
      showAccountName
      showCompanyName
      tradeName={getValueOrUndefined(organization.tradeName)}
      addTradeNameClass={classes.tradeNameClass}
    />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {props.headline && (
        <TextBodySmall addClass={classes.headline}>{props.headline}</TextBodySmall>
      )}
      <TextBodyXLarge addClass={classes.name}>
        {props.customUnknwonLabel || t('quote::Unknown billing account')}
      </TextBodyXLarge>
    </div>
  );
};

export const OrganizationDetails = withStyles(styles)(OrganizationDetailsUnstyled);
