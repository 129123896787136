import * as actions from 'features/customer/actions';
import { getTenant } from 'features/customer/selectors';
import { TenantProfile } from 'features/customer/types';
import { validGuid } from 'features/proposal/utils';
import { call, put, select } from 'redux-saga/effects';
import { api } from 'services';
import { AccountConfig } from 'services/account/config';
import { Account } from 'services/account/types';
import { ExternalUserConfig } from 'services/externaluser/config';
import {
  Tenant as ExternalUserTenant,
  TenantName,
  TenantType,
  TenantAdminResponse,
} from 'services/externaluser/types';
import { SignUpConfig } from 'services/sign-up/config';
import { Tenant as SignUpTenant } from 'services/sign-up/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';

export function* addTenantToAccount(accountId: string, tenantId: string) {
  const request = { accountId, tenant: { externalId: tenantId } };
  yield put(actions.addTenantToAccountAsync.request(request));
  try {
    const accountConfig: AccountConfig = yield select(
      (state: RootState) => state.app.appConfig.account
    );
    const account: Account = yield call(api.account.addTenant, request, accountConfig);
    yield put(actions.addTenantToAccountAsync.success(account));
    return account;
  } catch (err) {
    yield put(
      actions.addTenantToAccountAsync.failure({
        message: t('error::Error adding tenant to account'),
        exception: err,
      })
    );
  }
}

export function* loadAccountsByTenantId(tenantId: string) {
  const fromState: Account = yield select(
    (state: RootState) => state.customer.tenantAccounts[tenantId]
  );
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadAccountsByTenantIdAsync.request(tenantId));
  try {
    const accountConfig: AccountConfig = yield select(
      (state: RootState) => state.app.appConfig.account
    );
    const accounts: Account[] | undefined = yield call(
      api.account.getAccountsByTenantId,
      tenantId,
      accountConfig
    );
    yield put(actions.loadAccountsByTenantIdAsync.success({ id: tenantId, value: accounts || [] }));
    return accounts;
  } catch (err) {
    yield put(
      actions.loadAccountsByTenantIdAsync.failure({
        message: t('error::Error loading accounts by tenantId'),
        exception: err,
      })
    );
  }
}

export function* loadTenant(tenant: string) {
  const fromState: TenantProfile = yield select((state: RootState) => getTenant(state, tenant));
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadTenantAsync.request(tenant));
  try {
    const isGuid = new RegExp(validGuid);
    let tenantProfile: TenantProfile;

    if (isGuid.test(tenant)) {
      const config: ExternalUserConfig = yield select(
        (state: RootState) => state.app.appConfig.externaluser
      );
      const result: ExternalUserTenant | undefined = yield call(
        api.externalUser.getTenantProfile,
        tenant,
        config
      );
      tenantProfile = {
        tenantId: result && result.tenantId,
        isViral: result && result.tenantType === TenantType.viral,
        isConsumer: false, // For TID lookup we are currently assuming that its always false until the api provides this value.
      };
    } else {
      const config: SignUpConfig = yield select((state: RootState) => state.app.appConfig.signUp);
      const result: SignUpTenant = yield call(api.signUp.getTenant, tenant, config);
      tenantProfile = {
        tenantId: result.tenantId,
        isViral: result.isEmailVerifiedTenant,
        isConsumer: result.responseCode === 'ConsumerDomainNotAllowed',
      };
    }

    yield put(
      actions.loadTenantAsync.success({
        id: tenant,
        value: tenantProfile,
      })
    );
    return tenantProfile;
  } catch (err) {
    yield put(
      actions.loadTenantAsync.failure({
        message: t('error::Error loading tenant'),
        exception: err,
      })
    );
  }
}

export function* loadTenantNames(tenantIds: string[]) {
  const fromState: Record<string, string> = yield select(
    (state: RootState) => state.customer.tenantNames
  );
  if (fromState) {
    tenantIds = tenantIds.filter(id => !fromState[id]);
  }

  if (!tenantIds.length) {
    return;
  }

  yield put(actions.loadTenantNamesAsync.request(tenantIds));
  try {
    const config: ExternalUserConfig = yield select(
      (state: RootState) => state.app.appConfig.externaluser
    );
    const tenantNames: TenantName[] = yield call(
      api.externalUser.getTenantNames,
      { tenantIds },
      config
    );

    yield put(actions.loadTenantNamesAsync.success(tenantNames));
    return tenantNames;
  } catch (err) {
    yield put(
      actions.loadTenantNamesAsync.failure({
        message: t('error::Error loading tenant names'),
        exception: err,
      })
    );
  }
}

export function* loadTenantAdmins(tenantIds: string[]) {
  const fromState: Record<string, string[]> = yield select(
    (state: RootState) => state.customer.tenantAdmins
  );
  if (fromState) {
    tenantIds = tenantIds.filter(id => !fromState[id]);
  }

  if (!tenantIds.length) {
    return;
  }

  yield put(actions.loadTenantAdminsAsync.request(tenantIds));
  try {
    const config: ExternalUserConfig = yield select(
      (state: RootState) => state.app.appConfig.externaluser
    );
    const tenantAdmins: TenantAdminResponse[] = yield call(
      api.externalUser.getTenantAdmins,
      { tenantIds },
      config
    );
    yield put(actions.loadTenantAdminsAsync.success(tenantAdmins));
    return tenantAdmins;
  } catch (err) {
    yield put(
      actions.loadTenantAdminsAsync.failure({
        message: t('error::Error loading tenant admins'),
        exception: err,
      })
    );
  }
}
