import { Stack } from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { InfoButton } from './Buttons';
import { Label } from './Label';

export interface TeachingBubbleLabelProps {
  label?: string;
  required?: boolean;
  infoButtonProps?: {
    id: string;
    ariaLabel: string;
    headline: string;
    content: React.ReactNode;
    closeButtonAriaLabel: string;
    calloutWidth?: number | string;
  };
}

const styles = (theme: ThemeProps) => ({
  infoButtonRoot: { padding: 0, height: 28 },
  infoButtonFlexContainer: { height: 28 },
  infoButtonIcon: {
    fontSize: theme.fonts.fontSizes.small,
    paddingLeft: 11,
  },
  infoButtonCalloutRoot: (props: TeachingBubbleLabelProps) =>
    props.infoButtonProps ? { width: props.infoButtonProps.calloutWidth } : undefined,
  labelRoot: (props: TeachingBubbleLabelProps) =>
    props.required ? { '&::after': { paddingRight: 0 } } : undefined,
});

type Props = TeachingBubbleLabelProps & WithStyles<typeof styles>;

export const TeachingBubbleLabelUnstyled: React.FC<Props> = props => {
  const { classes } = props;

  return (
    <Stack horizontal verticalAlign="center">
      {props.label && (
        <Label required={props.required} styles={{ root: classes.labelRoot }}>
          {props.label}
        </Label>
      )}
      {props.infoButtonProps && (
        <InfoButton
          ariaLabel={props.infoButtonProps.ariaLabel}
          calloutProps={{
            headline: props.infoButtonProps.headline,
            closeButtonAriaLabel: props.infoButtonProps.closeButtonAriaLabel,
            styles: {
              root: classes.infoButtonCalloutRoot,
              subComponentStyles: {
                callout: {
                  beak: { height: 14, width: 14, top: '-6px !important' },
                  root: { marginLeft: -9 },
                },
              },
            },
          }}
          id={props.infoButtonProps.id}
          styles={{
            root: classes.infoButtonRoot,
            flexContainer: classes.infoButtonFlexContainer,
            icon: classes.infoButtonIcon,
          }}
        >
          {props.infoButtonProps.content}
        </InfoButton>
      )}
    </Stack>
  );
};

export const TeachingBubbleLabel = withStyles(styles)(TeachingBubbleLabelUnstyled);
