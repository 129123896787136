import { CatalogPageNegotiatedTerm, CatalogPageProduct } from './types';
import { Product } from 'generated/graphql';
import { oc } from 'ts-optchain';

export const convertToCatalogPageProduct = (
  product: Product | undefined | null
): CatalogPageProduct => ({
  id: oc(product).id(''),
  categories: oc(product).categories()
    ? oc(product)
        .categories([])
        .map(category => ({
          Name: category.name || '',
        }))
    : [],
  description: oc(product).description(''),
  identifier: {
    productId: oc(product).id(''),
    productType: oc(product).productType(''),
  },
  industries: oc(product).industries()
    ? oc(product)
        .industries([])
        .map(industry => ({
          Name: industry.name || '',
        }))
    : [],
  publisherName: oc(product).publisherName(''),
  images: {
    small: {
      Uri: oc(product).images.small[0](''),
    },
    medium: {
      Uri: oc(product).images.medium[0](''),
    },
    logo: {
      Uri: oc(product).images.logo[0](''),
    },
  },
  skus: oc(product).skus()
    ? oc(product)
        .skus([])
        .map(sku => ({
          SkuTitle: sku.title,
          SkuDescription: sku.description || '',
        }))
    : [],
  title: oc(product).title(''),
});

export const convertToCatalogPageNegotiatedTerm = (
  product: Product | undefined | null
): CatalogPageNegotiatedTerm => ({
  description: oc(product).description(''),
  id: oc(product).id(''),
  publisherName: oc(product).publisherName(''),
  images: {
    small: {
      Uri: oc(product).images.small[0](''),
    },
    medium: {
      Uri: oc(product).images.medium[0](''),
    },
    logo: {
      Uri: oc(product).images.logo[0](''),
    },
  },
  skus: oc(product).skus()
    ? oc(product)
        .skus([])
        .map(sku => ({
          SkuTitle: sku.title,
          SkuDescription: sku.description || '',
        }))
    : [],
  title: oc(product).title(''),
});
