import { createStandardAction } from 'typesafe-actions';

import { DiscountType } from './types';

export const updateDiscountType = createStandardAction('UPDATE_DISCOUNT_TYPE')<DiscountType>();
export const futureStartAdd = createStandardAction('FUTURE_START_ADD')<string>();
export const futureStartSelect = createStandardAction('FUTURE_START_SELECT')<string>();
export const futureEndAdd = createStandardAction('FUTURE_END_ADD')<string>();
export const futureEndSelect = createStandardAction('FUTURE_END_SELECT')<string>();
export const futureMetersSelect = createStandardAction('FUTURE_METERS_SELECT')<string>();
export const ceilingPriceCeilingAdd = createStandardAction('CEILING_PRICE_CEILING_ADD')<string>();
export const ceilingPriceCeilingSelect = createStandardAction('CEILING_PRICE_CEILING_SELECT')<
  string
>();
export const ceilingStartAdd = createStandardAction('CEILING_START_ADD')<string>();
export const ceilingStartSelect = createStandardAction('CEILING_START_SELECT')<string>();
export const ceilingEndAdd = createStandardAction('CEILING_END_ADD')<string>();
export const ceilingEndSelect = createStandardAction('CEILING_END_SELECT')<string>();
export const discountAmountChange = createStandardAction('DISCOUNT_AMOUNT_CHANGE')<string>();
export const discountAmountErrorChange = createStandardAction('DISCOUNT_AMOUNT_ERROR')<string>();
