import { User } from 'features/user/types';
import { select, takeEvery } from 'redux-saga/effects';
import * as LocalStorage from 'services/local-storage-service';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

import * as userActions from '../user/actions';

export function* setLocalStorageUser() {
  const relevantActions = [userActions.setUser, userActions.loadUserPermissionsAsync.success];
  yield takeEvery(relevantActions.map(getType), function*() {
    const currentUser: User = yield select((state: RootState) => state.user.current);
    LocalStorage.set<User>(LocalStorage.userKey, currentUser);
  });
}
