import {
  BorderlessButton,
  CalloutCalendar,
  ComboBox,
  Label,
  MediumIcon,
  SectionSeparator,
  TextBody,
  TextboxStandard,
} from 'components';
import { calendarStrings } from 'components/utilities/calendarStrings';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { LegacyDialog } from 'features-apollo/components/dialogs/LegacyDialog';
import { openManageFulfillmentCloudsDialog } from 'features-apollo/components/dialogs/ManageCloudsDialog';
import { getAllFulfillmentClouds } from 'features-apollo/quote/supported-clouds';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { Currency, getComboBoxCurrencies } from 'features/proposal/supported-currencies';
import { getComboBoxLanguages } from 'features/proposal/supported-languages';
import {
  defaultMarket,
  getCurrencies,
  getLanguages,
  getMarketForCountry,
  getTranslatedMarketsAlphabetically,
  Market,
} from 'features/proposal/supported-markets';
import { getComboBoxProposalTypes } from 'features/proposal/supported-proposal-types';
import { isProposalNameInvalid, isQuoteExpiringSoon } from 'features/proposal/utils';
import {
  Audience as Segment,
  GqlLanguage,
  Motion as Channel,
  NationalCloud,
  QuoteStatus,
  TransactionModel,
  User,
  UserGroup,
} from 'generated/graphql';
import moment from 'moment';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { proposalNameLengthLimit } from 'services/proposal/config';
import { DialogContext, DialogProps } from 'styles';

import { useMutation } from '@apollo/react-hooks';
import { KeyCodes } from '@uifabric/utilities';

import { GeneralStyles } from './General.styles';
import { MsContact } from './MsContact';
import {
  GET_QUOTE_GENERAL_INFO,
  UPDATE_CURRENCY,
  UPDATE_EXPIRATION_DATE,
  UPDATE_LANGUAGE,
  UPDATE_MARKET,
  UPDATE_QUOTE_NAME,
} from './queries';

export interface GeneralProps {
  quoteId: string;
  name: string;
  market: string;
  language?: GqlLanguage | null;
  expirationDate: Date;
  msContact?: User | null;
  billingCurrency: string;
  isQuoteReadOnly: boolean;
  isQuoteLegacy: boolean;
  loading?: boolean;
  quoteChannel?: Channel;
  quoteClouds: NationalCloud[];
  quoteSegment: Segment;
  transactionModel: TransactionModel;
  etag: string;
  crmId?: string;
  hasLineItems: boolean;
  status: QuoteStatus;
  assignedTo: UserGroup;
  isUSNatEnabled?: boolean;
  /**
   * Callback to update cloud in legacy quotes
   */
  onUpdateCloud: (cloudType: NationalCloud) => void;
}

const COMBOBOX_MENU_MAX_HEIGHT = 250;
const COMBOBOX_MENU_MAX_WIDTH = 356;
const CALLOUT_WIDTH = 268;
const FULFILLMENT_CLOUDS_DATA_ID = 'fufillment-clouds';

type Props = GeneralProps & WithStyles<typeof GeneralStyles>;

const GeneralUnStyled: React.FC<Props> = props => {
  const {
    classes,
    quoteId,
    name,
    market,
    language,
    expirationDate,
    msContact,
    billingCurrency,
    isQuoteReadOnly,
    isQuoteLegacy,
    etag,
    crmId,
    hasLineItems,
    status,
    assignedTo,
  } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  useHelpContent(HelpContent.InfoPropertySheet);

  const userMarket: Market = getMarketForCountry(market) || defaultMarket;

  //#region Quote Name
  const [displayQuoteName, setDisplayQuoteName] = React.useState<{ value: string }>({
    value: name,
  });
  const [errorInvalidQuoteName, setErrorInvalidQuoteName] = React.useState<string>('');

  // TODO (jek): update so it only needs to use active quote context
  const [updateQuoteNameGQL] = useMutation(UPDATE_QUOTE_NAME, {
    refetchQueries: () => [{ query: GET_QUOTE_GENERAL_INFO, variables: { id: quoteId } }],
  });

  const [updateMarketGQL] = useMutation(UPDATE_MARKET, {
    refetchQueries: () => [
      { query: GET_QUOTE_GENERAL_INFO, variables: { id: quoteId } },
      { query: GET_QUOTE, variables: { id: quoteId } },
    ],
  });

  const [updateCurrencyGQL] = useMutation(UPDATE_CURRENCY, {
    refetchQueries: () => [
      { query: GET_QUOTE_GENERAL_INFO, variables: { id: quoteId } },
      { query: GET_QUOTE, variables: { id: quoteId } },
    ],
  });

  const [updateLanguageGQL] = useMutation(UPDATE_LANGUAGE, {
    refetchQueries: () => [
      { query: GET_QUOTE_GENERAL_INFO, variables: { id: quoteId } },
      { query: GET_QUOTE, variables: { id: quoteId } },
    ],
  });

  const [updateExpirationDateGQL] = useMutation(UPDATE_EXPIRATION_DATE, {
    refetchQueries: () => [
      { query: GET_QUOTE_GENERAL_INFO, variables: { id: quoteId } },
      { query: GET_QUOTE, variables: { id: quoteId } },
    ],
  });

  const handleQuoteNameBlur = () => {
    if (!errorInvalidQuoteName && displayQuoteName.value !== name) {
      updateQuoteNameGQL({
        variables: {
          input: {
            id: quoteId,
            etag,
          },
          name: displayQuoteName.value,
        },
      });
    }
  };

  const handleQuoteNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setErrorInvalidQuoteName('');

    if (value) {
      const text =
        value.length <= proposalNameLengthLimit
          ? value
          : value.substring(0, proposalNameLengthLimit);
      setDisplayQuoteName({ value: text });

      if (isProposalNameInvalid(text))
        setErrorInvalidQuoteName(
          t('quote::The following characters are not allowed: ! @ # % ^ [ ] . / < >')
        );
    } else {
      setDisplayQuoteName({ value: '' });
    }
  };

  const handleQuoteNameEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter) {
      handleQuoteNameBlur();
    }
  };

  const quoteName: JSX.Element = (
    <TextboxStandard
      dataAutomationId="infoQuoteName"
      errorMessage={errorInvalidQuoteName}
      label={t('quote::Quote name')}
      readOnly={isQuoteReadOnly}
      value={displayQuoteName.value}
      onBlur={handleQuoteNameBlur}
      onChange={handleQuoteNameChange}
      onKeyDown={handleQuoteNameEnter}
    />
  );

  React.useEffect(() => {
    setDisplayQuoteName({ value: name });
  }, [name]);
  //#endregion

  //#region Channel and Segment
  const channelSegment = (
    <div className={classes.textGroup}>
      <TextboxStandard
        disabled
        label={t('quote::Channel and segment')}
        value={`${props.quoteChannel} - ${props.quoteSegment[0].toUpperCase() +
          props.quoteSegment.slice(1)}`}
      />
    </div>
  );
  //#endregion

  //#region Fulfillment cloud
  const label = t('quote::Fulfillment cloud');

  let fulfillmentCloud;

  if (props.transactionModel === TransactionModel.ToPartnerCustomerAsset) {
    const options = getAllFulfillmentClouds(props.isUSNatEnabled);
    fulfillmentCloud = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ComboBox
          dataAutomationId={FULFILLMENT_CLOUDS_DATA_ID}
          disabled
          dropDownWidth={COMBOBOX_MENU_MAX_WIDTH}
          label={label}
          maxHeight={COMBOBOX_MENU_MAX_HEIGHT}
          multiSelect
          options={options}
          selectedKeys={props.quoteClouds}
        />
        <BorderlessButton
          disabled={isQuoteReadOnly}
          styles={{ root: classes.manageCloudButton }}
          text={t('quote::manage fulfillment clouds')}
          onClick={() =>
            openManageFulfillmentCloudsDialog(context, {
              quoteId,
              isUSNatEnabled: props.isUSNatEnabled,
            })
          }
        />
      </div>
    );
  } else if (props.isQuoteLegacy) {
    const currentCloud = props.quoteClouds[0] || NationalCloud.Global;

    fulfillmentCloud = (
      <ComboBox
        dataAutomationId={FULFILLMENT_CLOUDS_DATA_ID}
        disabled={isQuoteReadOnly}
        dropDownWidth={COMBOBOX_MENU_MAX_WIDTH}
        label={t('quote::Fulfillment cloud')}
        maxHeight={COMBOBOX_MENU_MAX_HEIGHT}
        options={[
          { key: NationalCloud.Global, text: t(`quote::Global`), ariaLabel: t(`quote::Global`) },
          { key: NationalCloud.UsGov, text: t(`quote::US Gov`), ariaLabel: t(`quote::US Gov`) },
        ]}
        selectedKey={currentCloud}
        onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
          if (option) {
            const newCloud = option.key as NationalCloud;
            newCloud !== currentCloud && props.onUpdateCloud(option.key as NationalCloud);
          }
        }}
      />
    );
  }
  //#endregion

  //#region Market
  const setMarket = (request: Market) => {
    updateMarketGQL({
      variables: {
        input: {
          id: quoteId,
          etag,
        },
        market: request.toString(),
      },
    });
  };

  const quoteMarket: JSX.Element = (
    <ComboBox
      autoComplete="on"
      disabled={!!crmId || isQuoteReadOnly || isQuoteLegacy}
      dropDownWidth={COMBOBOX_MENU_MAX_WIDTH}
      id="Market"
      infoButtonProps={{
        ariaLabel: t('quote::Open information about changing the market'),
        closeButtonAriaLabel: t('Close'),
        content: t(
          'quote::Given that product availability differs between markets, making a change can result in invalid line items. Please review changes to the line items after changing the market.'
        ),
        headline: t('quote::Changing the market'),
        id: 'market-info-button',
        calloutWidth: CALLOUT_WIDTH,
      }}
      label={t('quote::Market')}
      maxHeight={COMBOBOX_MENU_MAX_HEIGHT}
      options={getTranslatedMarketsAlphabetically(t)}
      selectedKey={market.toString()}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
          setMarket(option.key as Market);
        }
      }}
    />
  );
  //#endregion

  //#region Currency
  const setCurrency = (currency: Currency) => {
    updateCurrencyGQL({
      variables: {
        input: {
          id: quoteId,
          etag,
        },
        currency: currency.toString(),
      },
    });
  };

  const quoteCurrency: JSX.Element = (
    <ComboBox
      disabled={isQuoteReadOnly}
      dropDownWidth={COMBOBOX_MENU_MAX_WIDTH}
      id="Currency"
      infoButtonProps={{
        ariaLabel: t('quote::Open information about changing the currency'),
        closeButtonAriaLabel: t('quote::Close'),
        headline: t('quote::Changing the currency'),
        content: t(
          'quote::Given that pricing differs between currencies, making a change can result in invalid line items. Please review changes to the line items after changing the currency.'
        ),
        id: 'currency-info-button',
        calloutWidth: CALLOUT_WIDTH,
      }}
      label={t('quote::Currency')}
      options={getComboBoxCurrencies(getCurrencies(market as Market))}
      selectedKey={billingCurrency || getCurrencies(userMarket)[0]}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
          setCurrency(option.key as Currency);
        }
      }}
    />
  );
  //#endregion

  //#region Quote Language
  const userLanguage = getLanguages(userMarket)[0];
  const languageComboBoxOptions = getComboBoxLanguages(getLanguages(market as Market));
  // TODO (jek): create a util function
  const convertedLanguage = language ? language.replace('_', '-') : '';

  const setLanguage = (language: GqlLanguage) => {
    updateLanguageGQL({
      variables: {
        input: {
          id: quoteId,
          etag,
        },
        language,
      },
    });
  };

  const quoteLanguage: JSX.Element = (
    <ComboBox
      disabled={isQuoteReadOnly}
      dropDownWidth={COMBOBOX_MENU_MAX_WIDTH}
      id="QuoteLanguage"
      label={t('quote::Quote language')}
      options={languageComboBoxOptions}
      selectedKey={convertedLanguage || userLanguage}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
          setLanguage(option.key as GqlLanguage);
        }
      }}
    />
  );
  //#endregion

  //#region Quote Type
  const dialogProps: DialogProps = {
    providedDialog: <LegacyDialog etag={etag} hasLineItems={hasLineItems} quoteId={quoteId} />,
  };
  const handleOnLegacyConvertClick = () => {
    context.openDialog(dialogProps);
  };
  const proposalTypeComboBoxOptions = getComboBoxProposalTypes();

  const editQuoteTypeDisabled =
    isQuoteReadOnly ||
    isQuoteLegacy ||
    props.transactionModel === TransactionModel.ToPartnerCustomerAsset;

  const quoteProposalType: JSX.Element = (
    <ComboBox
      dataAutomationId="proposalType"
      disabled={editQuoteTypeDisabled}
      dropDownWidth={COMBOBOX_MENU_MAX_WIDTH}
      id="ProposalType"
      label={t('quote::Proposal type')}
      options={proposalTypeComboBoxOptions}
      selectedKey={isQuoteLegacy ? 'Legacy' : 'Standard'}
      onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option && option.key === 'Legacy') {
          handleOnLegacyConvertClick();
        }
      }}
    />
  );

  const legacyQuote: JSX.Element = editQuoteTypeDisabled ? (
    quoteProposalType
  ) : (
    <>
      {quoteProposalType}
      <div className={classes.textGroup}>
        <TextBody addClass={classes.emphasisTextSmall}>{t('quote::Standard')}</TextBody>
        <TextBody addClass={classes.textSubGroup}>
          {t(
            'quote::The regular everyday quote you will use most of the time and never have to change.'
          )}
        </TextBody>
      </div>
      <div className={classes.textGroup}>
        <TextBody addClass={classes.emphasisTextSmall}>{t('quote::Legacy')}</TextBody>
        <TextBody addClass={classes.textSubGroup}>
          {t(
            'quote::The quote should only be converted to legacy when attempting to route a discount for use on an EA Enrollment.'
          )}
        </TextBody>
      </div>
    </>
  );
  //#endregion

  const msContactSectionView: JSX.Element = (
    <MsContact
      assignedTo={assignedTo}
      etag={etag}
      isQuoteReadOnly={isQuoteReadOnly}
      msContact={msContact}
      quoteId={quoteId}
      status={status}
    ></MsContact>
  );

  //#region Quote Id
  const quoteIdRegion: JSX.Element = (
    <div>
      <TextBody addClass={classes.emphasisText} dataAutomationId="quoteIdLabel">
        {t('quote::Quote ID')}
      </TextBody>
      :
      <TextBody addClass={classes.quoteId} dataAutomationId="quoteIdValue">
        {quoteId}
      </TextBody>
    </div>
  );
  //#endregion

  //#region Quote Duration
  const onQuoteExpirationDateSelect = (date: Date) => {
    updateExpirationDateGQL({
      variables: {
        input: {
          id: quoteId,
          etag,
        },
        date: date.toISOString(),
      },
    });
  };

  const quoteDuration = () => {
    let expirationUnits = moment(expirationDate).diff(moment(), 'days');
    let unitLabel = expirationUnits === 1 ? t('common::day') : t('common::days');

    if (!expirationUnits) {
      expirationUnits = moment(expirationDate).diff(moment(), 'hours');
      unitLabel = expirationUnits === 1 ? t('common::hour') : t('common::hours');

      if (!expirationUnits) {
        expirationUnits = moment(expirationDate).diff(moment(), 'minutes');
        unitLabel = expirationUnits === 1 ? t('common::minute') : t('common::minutes');
      }
    }

    return (
      <div className={classes.calendarContainer}>
        <Label>{t('quote::Good until')}</Label>
        <div className={classes.calendarButtonContainer}>
          <CalloutCalendar
            buttonProps={{
              id: 'expiration-date-calendar-button',
              disabled: isQuoteReadOnly,
              text: convertDateToFormattedString(expirationDate, LocaleDateFormat.ll),
            }}
            calendarStrings={calendarStrings}
            dataAutomationId="generalGoodUntil"
            defaultDate={expirationDate}
            isDayPickerVisible
            maxDate={moment()
              .add(12, 'months')
              .toDate()}
            onSelectDate={onQuoteExpirationDateSelect}
          />
          {isQuoteExpiringSoon(expirationDate) && (
            <TextBody addClass={classes.calendarWarning} dataAutomationId="quoteExpiresWarning">
              <MediumIcon iconName="Warning" />
              {t('quote::Expires in {{expirationUnits}} {{unitLabel}}', {
                expirationUnits,
                unitLabel,
              })}
            </TextBody>
          )}
        </div>
      </div>
    );
  };
  //#endregion

  return (
    <div className={classes.container}>
      <div className={classes.leftContent}>
        <div className={classes.leftTopContent}>
          {quoteName}
          {channelSegment}
          {fulfillmentCloud}
        </div>
        <SectionSeparator addClass={classes.separator} />
        <div className={classes.leftTopContent}>
          {quoteMarket}
          {quoteCurrency}
          {quoteLanguage}
        </div>
        <div className={classes.leftLegacyContent}>
          <SectionSeparator addClass={classes.separator} />
          {legacyQuote}
        </div>
      </div>
      <div className={classes.rightContent}>
        {msContactSectionView}
        {quoteIdRegion}
        {quoteDuration()}
      </div>
    </div>
  );
};

export const General = withStyles(GeneralStyles)(GeneralUnStyled) as React.FC<GeneralProps>;
