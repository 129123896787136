import { ButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import { IButtonStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ActionType, ButtonSharedProps } from '../ButtonSharedProps';
import { secondaryButtonStyles } from './SecondaryButton.styles';

interface SecondaryButtonProps extends ButtonSharedProps {
  onBlur?: () => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
}

type Props = SecondaryButtonProps & WithStyles<typeof secondaryButtonStyles>;

const SecondaryButtonUnstyled: React.FC<Props> = (props: Props) => {
  const currentClass = props.iconName ? props.classes.secondaryIcon : props.classes.secondary;
  const styles: Partial<IButtonStyles> = {
    label: props.classes.label,
  };
  return (
    <ButtonAtom
      addClass={mergeClassNames([currentClass, props.addClass])}
      ariaLabel={props.ariaLabel}
      autoFocus={props.autoFocus}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={props.iconName ? { iconName: props.iconName } : undefined}
      id={props.id}
      styles={styles}
      text={props.text}
      title={props.title}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    />
  );
};

export const SecondaryButton = withStyles(secondaryButtonStyles)(
  SecondaryButtonUnstyled
) as React.FC<SecondaryButtonProps>;
