import { GuidanceBox, PrimaryButton, TextBody } from 'components';
import { openCrmIdDialog } from 'features-apollo/quote/components/Dialogs/CrmIdDialog';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { DialogContext } from 'styles';

import { styles } from './GuidanceBoxStates.styles';
import { GuidanceBoxHeader } from './shared';

type Props = { quoteId: string; readOnly?: boolean } & WithStyles<typeof styles>;

const NoCrmLeadUnstyled: React.FC<Props> = props => {
  const { classes, readOnly } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  return (
    <GuidanceBox>
      <GuidanceBoxHeader addClass={classes.header} title={t('quote::A CRM ID is required...')}>
        <p className={classes.paragraph}>
          <TextBody>
            {t(
              'quote::Before you can add a billing account to the quote you will need to associate an Opportunity or Success Engagement.'
            )}
          </TextBody>
        </p>
        <TextBody>
          <Trans ns="quote">
            Normally you would do that under{' '}
            <Link
              className={classes.link}
              data-automation-id="salesLink"
              to={routes.quote.salesForId(props.quoteId)}
            >
              Sales
            </Link>{' '}
            or you can click the Add CRM ID button and do it now.
          </Trans>
        </TextBody>
      </GuidanceBoxHeader>
      <PrimaryButton
        dataAutomationId="addCRMId"
        disabled={readOnly}
        text={t('quote::Add CRM ID')}
        onClick={() => openCrmIdDialog(context)}
      />
    </GuidanceBox>
  );
};

export const NoCrmLead = withStyles(styles)(NoCrmLeadUnstyled);
