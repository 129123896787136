export const catalogStyles = {
  catalog: {
    minWidth: 1217,
    width: '100%',
  },
  productSection: {
    paddingLeft: 40,
  },
  productSectionDimmed: {
    paddingLeft: 40,
    opacity: 0.5,
  },
  filters: {
    display: 'inline-flex',
    paddingTop: 24,
    paddingLeft: 40,
    paddingBottom: 30,
  },
  filterText: {
    alignSelf: 'center',
    paddingRight: 16,
    marginTop: 25,
  },
  combobox: {
    width: 250,
    paddingRight: 20,
  },
};

export const featuredSectionStyles = {
  featuredSection: {
    paddingBottom: 32,
    width: 1136,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 16,
    paddingRight: 5,
  },
  headerLink: {
    alignSelf: 'flex-end',
  },
  loadMore: {
    paddingTop: 40,
    paddingLeft: 486,
  },
  spinner: {
    paddingTop: 250,
  },
};

export const searchSectionStyles = {
  searchSection: {
    paddingBottom: 150,
    width: 1136,
  },
  header: {
    display: 'inline-flex',
    paddingBottom: 16,
  },
  clearSearchLink: {
    paddingLeft: 16,
  },
  expandSearch: {
    paddingTop: 40,
    paddingLeft: 486,
  },
  spinner: {
    paddingTop: 250,
  },
};

export const catalogSectionRowStyles = {
  details: {
    paddingTop: 20,
    paddingBottom: 32,
  },
  tileGrid: {
    display: 'grid',
    gridTemplateColumns: '260px 260px 260px 260px',
    gridGap: 32,
    paddingBottom: 32,
  },
  tileGridDetails: {
    display: 'grid',
    gridTemplateColumns: '260px 260px 260px 260px',
    gridGap: 32,
  },
};
