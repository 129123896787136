import { ThemeProps, sharedStyles } from 'styles';

export const existingOwnersDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  footer: {
    ...sharedStyles(theme).customDialogFooter,
  },
  existingOwnersLeftColumn: {
    flex: 2,
  },
  description: {
    color: theme.palette.textTertiary,
  },
  dialogBody: {
    overflow: 'hidden',
  },
  link: {
    display: 'block',
  },
  spinner: {
    paddingTop: 16,
    alignItems: 'end',
  },
});
