import { ThemeProps } from 'styles';
// TODO: michmel - jss bug in edge is not applying
// import { NumberInputAtomProps } from './NumberInputAtom';

export const numberInputAtomStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    numberInput: {
      // TODO: michmel - jss bug in edge is not applying
      // width: (props: NumberInputAtomProps) => {
      //   return props.width || '100%';
      // },
      width: 50,
      textAlign: 'center',
      border: 'none',
      color: palette.text,
      borderBottom: `1px solid ${palette.text}`,
      background: palette.transparent,
      '&:focus': {
        outline: 'none',
        border: `1px solid ${palette.text}`,
      },
      '&::-webkit-inner-spin-button,&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    message: {
      textAlign: 'center',
    },
  };
};
