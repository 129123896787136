import { LinkButton, NotificationItem, TextBodySmall, TextTitle } from 'components/ions';
import { RuleType } from 'features/app/config/type';
import {
  ExistingDiscount,
  ExistingDiscountLabels,
} from 'features/proposal/components/ExistingDiscountsList';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { DetailsPaneStyles } from './DetailsPane.styles';
import { renderDiscounts, renderLabeledList, renderNotifications } from './SharedDetailsRenders';

export interface DiscountDetailsPaneProps {
  id: string;
  description: string;
  labels: ExistingDiscountLabels;
  discountType?: string;
  productName: string;
  startDate?: string;
  endDate?: string;
  notifications: NotificationItem[];
  discounts?: ExistingDiscount[];
  imageUri?: string | null;
  publisherLink?: React.ReactNode;
  skus: string[];
  discountPercent: number;
  meterName?: string[];
  lockDate?: string;
  duration?: string;
  dataAutomationId?: string;
}

type DiscountPropsType = DiscountDetailsPaneProps & WithStyles<typeof DetailsPaneStyles>;

const RenderFutureDiscount: React.FC<DiscountPropsType> = props => {
  const { t } = useTranslation();
  const { discountPercent, startDate, endDate, classes, meterName, duration } = props;
  const discountLabel = `${t('quote::Discount')}:`;
  const meterLabel = `${t('quote::Meter')}:`;
  const discountDisplay = `${discountPercent.toString()}%`;
  const durationLabel = `${t('quote::Duration')}:`;

  const datesToDisplay = [];
  if (startDate && endDate) {
    datesToDisplay.push(startDate);
    datesToDisplay.push(endDate);
  } else if (duration) {
    datesToDisplay.push(duration);
  }

  return (
    <div className={classes.flexCol}>
      {meterName && renderLabeledList(meterLabel, meterName, classes)}
      {renderLabeledList(discountLabel, [discountDisplay], classes)}
      {!!datesToDisplay.length && renderLabeledList(durationLabel, datesToDisplay, classes)}{' '}
    </div>
  );
};

const RenderCeilingDiscount: React.FunctionComponent<DiscountPropsType> = props => {
  const { t } = useTranslation();
  const { discountPercent, startDate, endDate, classes, lockDate, duration } = props;
  const lockLabel = `${t('quote::Lock')}:`;
  const discountLabel = `${t('quote::Discount')}:`;
  const durationLabel = `${t('quote::Duration')}:`;
  const discountDisplay = `${oc(discountPercent)(0).toString()}%`;

  const datesToDisplay = [];
  if (startDate && endDate) {
    datesToDisplay.push(startDate);
    datesToDisplay.push(endDate);
  } else if (duration) {
    datesToDisplay.push(duration);
  }
  return (
    <div className={classes.flexCol}>
      {renderLabeledList(discountLabel, [discountDisplay], classes)}
      {lockDate && renderLabeledList(lockLabel, [lockDate], classes)}
      {!!datesToDisplay.length && renderLabeledList(durationLabel, datesToDisplay, classes)}
    </div>
  );
};

const getDiscountBlock: React.FunctionComponent<DiscountPropsType> = props => {
  return props.discountType &&
    props.discountType.toLowerCase() === RuleType.priceGuarantee.toLowerCase() ? (
    <RenderCeilingDiscount {...props} />
  ) : (
    <RenderFutureDiscount {...props} />
  );
};

const DiscountDetailsPaneUnstyled: React.FunctionComponent<DiscountPropsType> = props => {
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);

  const {
    id,
    classes,
    productName,
    labels,
    description,
    notifications,
    discounts,
    imageUri,
    publisherLink,
    discountType,
    skus,
  } = props;

  const dialogProps = {
    props: {
      id: 'Description-dialog-box',
      dialogClose: dialogContext.closeDialog,
      headingText: productName,
      bodyText: description,
      imageUrl: imageUri,
      actionLink: publisherLink,
      dataAutomationId: 'DescriptionDialogBox',
    },
    hasImage: imageUri ? true : false,
    isCustom: false,
  };

  let title = t('quote::Discount');
  if (discountType) {
    const type =
      discountType.toLowerCase() === RuleType.future.toLowerCase()
        ? t('quote::Future')
        : t('quote::Ceiling');
    title = `${type} ${title}`;
  }
  const skulabel = `${t('quote::SKUs')}:`;
  const openDialog = () => {
    dialogContext.openDialog(dialogProps);
  };

  return (
    <div className={classes.detailsContainer} data-automation-id={props.dataAutomationId} id={id}>
      <div className={classes.heading}>
        <div>
          <TextTitle>{title}</TextTitle>
        </div>
      </div>
      <div className={classes.discountRow}>
        <div className={classes.label}>
          <TextBodySmall>
            <span>{`${t('quote::Product')}:`}</span>
          </TextBodySmall>
        </div>
        <div className={classes.shortText}>
          <TextBodySmall>
            <LinkButton
              dataAutomationId="productDescriptionLink"
              displayText={productName}
              id="product-description-link"
              onClick={openDialog}
            />
          </TextBodySmall>
        </div>
      </div>
      {renderLabeledList(skulabel, skus, classes)}
      {discountType && getDiscountBlock(props)}
      {renderDiscounts(classes, labels, discounts)}
      {renderNotifications(notifications, classes)}
    </div>
  );
};

export const DiscountDetailsPane = withStyles(DetailsPaneStyles)(DiscountDetailsPaneUnstyled);
