import { LinkProps, TextWatermark } from 'components/ions';
import { EventTrap } from 'components/utilities';
import { getFlightIsEnabled } from 'features/app/selectors';
import { addSkuTitle } from 'features/proposal/actions';
import { buildMonetaryCardSummaryStrings } from 'features/proposal/components//MonetaryCard/utils';
import { ConnectedCalloutCardLink } from 'features/proposal/components/CalloutCardLink/CalloutCardLink';
import {
  ConnectedConfigCardBusinessLogic,
  QualifyingSkuAvailability,
} from 'features/proposal/components/ConfigCard';
import {
  buildConfigureCombinedSummary,
  isMonetary,
  isPasses,
} from 'features/proposal/components/ConfigCard/ConfigCardBusinessLogic';
import { cardConstants } from 'features/proposal/components/DiscountCard/cardConstants';
import {
  buildDiscountConfigureSummary,
  buildDiscountTypeChoices,
} from 'features/proposal/components/DiscountCard/DiscountCardBusinessLogic';
import { ConnectedDiscountCardContainer } from 'features/proposal/components/DiscountCard/DiscountCardContainer';
import { ProductListLineItem } from 'features/proposal/components/List/ProductList/types';
import { ConnectedMonetaryCard } from 'features/proposal/components/MonetaryCard';
import {
  calculateColspan,
  ProposalListColumnProps,
  ProposalListColumnRenderProps,
  ProposalListRow,
  SelectionProps,
} from 'features/proposal/components/ProposalList';
import {
  getActiveProposal,
  getEnrollment,
  getEnrollmentNumber,
  lineItemHasNotificationError,
} from 'features/proposal/selectors';
import { Currency } from 'features/proposal/supported-currencies';
import { Market } from 'features/proposal/supported-markets';
import { CardType, OpenCard, SkuTitleMap } from 'features/proposal/types';
import {
  displayInDayFormatAndUseMarketTimezone,
  isCardOpen,
  lineItemStartDateInPast,
  loadSkuTitlesIfNeeded,
} from 'features/proposal/utils';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product, ProductType } from 'services/catalog/types';
import { Flight } from 'services/flights/flightList';
import { LineItem, PriceAdjustmentType } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { productListRowStyles } from './ProductListRow.styles';

const notDiscountableFamilies = [cardConstants.apps];
const notDiscountableProductTypes = [
  cardConstants.accessPass,
  cardConstants.monetaryCommitment,
  cardConstants.monetaryCredit,
];

export type ProductListRowProps = {
  products: Record<string, Product>;
  productLineItem: ProductListLineItem;
  addSkuTitle: (
    qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
    productId: string
  ) => ReturnType<typeof addSkuTitle>;
  isReadOnly: boolean;
  lineItem?: LineItem;
  market: Market;
  currency: Currency;
  openCard?: OpenCard;
  columns: ProposalListColumnRenderProps<ProductListLineItem>[];
  skuTitleMap?: SkuTitleMap;
} & SelectionProps;

const mapStateToProps = (state: RootState, ownProps: ProductListRowProps) => {
  return {
    hasNotificationError:
      ownProps.lineItem && lineItemHasNotificationError(state, ownProps.lineItem.id),
    enrollment: getEnrollment(state),
    enrollmentNumber: getEnrollmentNumber(state),
    proposal: getActiveProposal(state),
    modernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
  };
};

type Props = ProductListRowProps &
  WithStyles<typeof productListRowStyles> &
  ReturnType<typeof mapStateToProps>;

const mapItems = (
  columns: ProposalListColumnRenderProps<ProductListLineItem>[],
  product: ProductListLineItem
) => {
  return columns.map(column => {
    return (
      <div
        data-automation-id={`fr-${product.name}-${column.id}`}
        key={`fr-${product.id}-${column.id}`}
        style={{
          display: 'flex',
          minWidth: column.width,
          maxWidth: column.width,
          paddingLeft: 17,
          paddingRight: 17,
          justifyContent: column.alignText,
        }}
      >
        {column.onRender(product, column)}
      </div>
    );
  });
};

const renderCard = (
  product: Product,
  productLineItem: ProductListLineItem,
  watermarkText: string,
  target: React.RefObject<HTMLSpanElement>,
  t: i18next.TFunction,
  isReadOnly: boolean,
  currency: Currency,
  classes: { configuration: string; watermark: string },
  openCard?: OpenCard,
  isSelected?: boolean,
  lineItem?: LineItem,
  skuTitleMap?: SkuTitleMap,
  isSharedDiscount?: boolean
) => {
  let card;
  if (product && lineItem) {
    const configCardActive = isCardOpen(lineItem.id, CardType.Config, openCard);
    if (isMonetary(product)) {
      const amount = oc(lineItem).purchaseInstruction.purchaseTermUnits();
      const goodFor = oc(lineItem).duration();
      const linkProps: LinkProps = {
        displayText:
          amount && goodFor
            ? t(
                `quote::{{amount}} {{currency}}, {{startCondition}}, {{duration}}`,
                buildMonetaryCardSummaryStrings(
                  amount,
                  goodFor,
                  currency,
                  t('quote::year(s)'),
                  t('quote::month(s)'),
                  t('quote::At order placement')
                )
              )
            : t('quote::configure'),
        dataAutomationId: 'configureMonetaryProduct',
      };
      const reasons = oc(product).Properties.ReasonCodes();
      card = (
        <div className={classes.configuration}>
          {configCardActive && (
            <ConnectedMonetaryCard
              hydratedProduct={product}
              lineItemId={productLineItem.id}
              name={productLineItem.name || ''}
              reasons={reasons}
              target={target}
            />
          )}
          <ConnectedCalloutCardLink
            isReadOnly={isReadOnly}
            lineItemId={productLineItem.id}
            linkProps={linkProps}
            maxWidth={500}
            offset={{ left: 30, top: 10 }}
            reference={target}
            type={CardType.Config}
          />
        </div>
      );
    } else if (
      product.DisplaySkuAvailabilities.length >= 1 &&
      product.ProductType !== ProductType.AzureFamilyDiscount &&
      product.ProductType !== ProductType.SAP
    ) {
      const linkProps: LinkProps = {
        displayText: buildConfigureCombinedSummary(
          lineItem,
          skuTitleMap,
          product.DisplaySkuAvailabilities
        ),
        dataAutomationId: 'configureProduct',
      };
      card = (
        <div className={classes.configuration}>
          {configCardActive && !isSharedDiscount && (
            <ConnectedConfigCardBusinessLogic
              activeLineItemId={productLineItem.id}
              hydratedProduct={product}
              target={target}
            />
          )}
          <ConnectedCalloutCardLink
            isReadOnly={isReadOnly}
            lineItemId={productLineItem.id}
            linkProps={linkProps}
            maxWidth={500}
            offset={{ left: 30, top: 10 }}
            reference={target}
            type={CardType.Config}
          />
        </div>
      );
    } else {
      return <TextWatermark addClass={classes.watermark}>{watermarkText}</TextWatermark>;
    }
    return <EventTrap condition={() => !!isSelected}>{card}</EventTrap>;
  }
};

export const isDiscountable = (
  productFamily: string,
  productType: string,
  hydratedProduct: Product,
  lineItem?: LineItem
): boolean => {
  const hasChoices = lineItem && buildDiscountTypeChoices(hydratedProduct, lineItem);
  return !!(
    !notDiscountableFamilies.includes(productFamily) &&
    !notDiscountableProductTypes.includes(productType) &&
    hasChoices &&
    hasChoices.length
  );
};

export const showDiscountLink = (product: Product): boolean => {
  return (
    !notDiscountableFamilies.includes(product.ProductFamily) &&
    !notDiscountableProductTypes.includes(product.ProductType)
  );
};

const mapSecondRow = (
  columns: ProposalListColumnProps[],
  product: ProductListLineItem,
  products: Record<string, Product>,
  watermarkText: string,
  market: Market,
  configCardTarget: React.RefObject<HTMLSpanElement>,
  discountCardTarget: React.RefObject<HTMLSpanElement>,
  t: i18next.TFunction,
  isReadOnly: boolean,
  currency: Currency,
  classes: { configuration: string; watermark: string },
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  openCard?: OpenCard,
  isSelected?: boolean,
  lineItem?: LineItem,
  skuTitleMap?: SkuTitleMap,
  isSharedDiscount?: boolean
) => {
  const currentProduct = products[product.productId];
  const discountCardActive = lineItem && isCardOpen(lineItem.id, CardType.Discount, openCard);
  const linkProps: LinkProps = {
    dataAutomationId: 'discountConfigureLink',
    displayText:
      (lineItem &&
        buildDiscountConfigureSummary(
          lineItem,
          market,
          shouldDisplayInDayFormatAndUseMarketTimezone
        )) ||
      '',
  };
  return (
    <>
      <div
        key={`sr-${product.id}-1`}
        style={{ flexBasis: calculateColspan(columns, 4, 0), flexGrow: 0, paddingLeft: 17 }}
      >
        {products[product.productId] &&
          renderCard(
            products[product.productId],
            product,
            watermarkText,
            configCardTarget,
            t,
            isReadOnly,
            currency,
            classes,
            openCard,
            isSelected,
            lineItem,
            skuTitleMap,
            isSharedDiscount
          )}
      </div>
      <div
        key={`sr-${product.id}-2`}
        // TODO: michmel - This is horrible but I need to do a bunch of refactoring to get this the right way
        style={{ minWidth: calculateColspan(columns, 2, 4), paddingLeft: 17 * 8 }}
      >
        {currentProduct && showDiscountLink(currentProduct) && (
          <div>
            {discountCardActive && (
              <ConnectedDiscountCardContainer
                hydratedProducts={[currentProduct]}
                lineItemIds={[product.id]}
                target={discountCardTarget}
              />
            )}
            <ConnectedCalloutCardLink
              isReadOnly={
                isReadOnly ||
                !isDiscountable(
                  currentProduct.ProductFamily,
                  currentProduct.ProductType,
                  currentProduct,
                  lineItem
                )
              }
              lineItemId={product.id}
              linkProps={linkProps}
              maxWidth={200}
              offset={{ left: 30, top: 10 }}
              reference={discountCardTarget}
              title={linkProps.displayText}
              type={CardType.Discount}
            />
          </div>
        )}
      </div>
    </>
  );
};

export const ProductListRowUnStyled: React.FC<Props> = props => {
  const {
    columns,
    classes,
    productLineItem: product,
    products,
    lineItem,
    isReadOnly,
    market,
    modernOfficeEnabled,
  } = props;
  const discountCardTarget = React.useRef<HTMLSpanElement>(null);
  const configCardTarget = React.useRef<HTMLSpanElement>(null);
  const { t } = useTranslation();

  const watermarkText = t('quote::No Options');
  const selectionProps = props as SelectionProps;
  const hydratedProduct = products[product.productId];

  const shouldDisplayInDayFormatAndUseMarketTimezone =
    !!hydratedProduct &&
    displayInDayFormatAndUseMarketTimezone(
      hydratedProduct.ProductType,
      oc(lineItem).productIdentifier.action()
    );

  //TODO: cameneks,michmel, refactor
  const onClick = (obj: { multi: boolean; ids: string[]; lastSelectedId?: string }) => {
    const hydratedProduct = products[product.productId];
    loadSkuTitlesIfNeeded(product.productId, props.addSkuTitle, hydratedProduct, props.skuTitleMap);
    selectionProps.onSelected && selectionProps.onSelected(obj);
  };
  const showError =
    (product.hasServiceValidationErrors || props.hasNotificationError) && !product.isLoading;
  const augmentedSelectionProps: SelectionProps = {
    ...selectionProps,
    onSelected: onClick,
    isInErrorState: showError,
  };
  if (modernOfficeEnabled && hydratedProduct && isPasses(hydratedProduct) && lineItem) {
    const enrollmentNumber = props.enrollmentNumber;
    if (enrollmentNumber) {
      const enrollmentContents = props.enrollment[enrollmentNumber];
      if (!lineItem.extendedProperties && enrollmentContents) {
        const startDate = moment
          .utc(enrollmentContents.endDate)
          .add('1', 'minutes')
          .toDate();
        lineItem.purchaseInstruction = {
          ...lineItem.purchaseInstruction,
          termStartDate: startDate,
        };
      }
    } else if (
      lineItem.purchaseInstruction &&
      lineItem.purchaseInstruction.termStartDate &&
      lineItemStartDateInPast(lineItem.purchaseInstruction.termStartDate)
    ) {
      // TODO - find better way to hook up this notification to display pane notifications
      augmentedSelectionProps.isInErrorState = true;
      augmentedSelectionProps.errorText = t(
        'quote::Configured start date cannot occur in the past'
      );
    }
  }
  const isSharedDiscount =
    lineItem &&
    lineItem.pricingInstruction &&
    lineItem.pricingInstruction.priceAdjustmentType === PriceAdjustmentType.extend;

  return (
    <ProposalListRow
      columns={columns}
      isLoading={product.isLoading}
      itemId={product.id}
      {...augmentedSelectionProps}
      dataAutomationId={product.name}
    >
      <div>
        <div className={classes.lineItemTop}>{mapItems(columns, product)}</div>
        <div className={classes.lineItemBottom}>
          {mapSecondRow(
            columns,
            product,
            products,
            watermarkText,
            market,
            configCardTarget,
            discountCardTarget,
            t,
            isReadOnly,
            props.currency,
            { configuration: classes.configuration, watermark: classes.watermark },
            shouldDisplayInDayFormatAndUseMarketTimezone,
            props.openCard,
            selectionProps.isSelected,
            lineItem,
            props.skuTitleMap,
            isSharedDiscount
          )}
        </div>
      </div>
    </ProposalListRow>
  );
};

export interface ProductListOwnProps {
  lineItemId: string;
}

export const ProductListRowUnconnected = withStyles(productListRowStyles)(
  ProductListRowUnStyled
) as React.FC<SelectionProps & ProductListRowProps>;

export const ProductListRow = connect(mapStateToProps)(ProductListRowUnconnected);
