import { routerMiddleware } from 'connected-react-router';
import { meplaHistory } from 'createHistory';
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import createSagaMiddleware from 'redux-saga';
import { Services } from 'services/types';
import { RootState } from 'store/types';
import { getDevInitialState, isDevBuild } from 'store/utils';

import services from '../services';
import { appInsightsMiddleware } from './middleware/appInsightsMiddleware';
import { localStorageMiddleware } from './middleware/localStorageMiddleware';
import rootEpic from './root-epic';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';
import { composeEnhancers } from './utils';

// TODO: RootAction used to be passed in as the first type parameters.  Is <any, any, ... a good alternative?
//       This change was made to improve perf or typesafe-actions.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const epicMiddleware = createEpicMiddleware<any, any, RootState, Services>({
  dependencies: services,
});
export const sagaMiddleware = createSagaMiddleware();

// Configure middlewares
const middlewares = [
  routerMiddleware(meplaHistory),
  sagaMiddleware,
  epicMiddleware,
  appInsightsMiddleware,
  localStorageMiddleware,
];

// Compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// Rehydrate state on app start
const initialState = isDevBuild ? getDevInitialState() : {};

// Create store
const store = createStore(rootReducer(meplaHistory), initialState, enhancer);

sagaMiddleware.run(rootSaga);
epicMiddleware.run(rootEpic);

// Export store singleton instance
export default store;
