import gql from 'graphql-tag';

export const GetValidEnrollments = gql`
  query GetValidEnrollments($tpid: String!) {
    validAzureEnrollments(tpid: $tpid) {
      agreementNumber
      endDate
    }
  }
`;

export const GET_QUOTE_SALES_INFO = gql`
  query getQuote($id: String!) {
    getQuote(id: $id) {
      id
      etag
      readOnly
      transactionModel
      market
      billingCurrency
      assignedTo
      vlAgreementNumber
      agreementType
      soldTo {
        dealEstimate
        monthlyCreditLimit
        organization {
          id
          accountId
          credit {
            decision
            reason
            creditLine
          }
        }
      }
      crmLead {
        id
        entityName
        entityId
        vlAgreementNumber
        type
        salesAccount {
          id
          tpid
          address {
            country
            companyName
          }
        }
      }
    }
  }
`;

export const GET_QUOTE_ETAG = gql`
  query getQuote($id: String!) {
    getQuote(id: $id) {
      id
      etag
    }
  }
`;

export const ADD_CRMID = gql`
  mutation addCRMLead($input: QuoteMutationInput!, $crmId: String!) {
    addCRMLead(input: $input, crmId: $crmId) {
      id
      crmLead {
        id
      }
      languageInfo {
        gqlCode
      }
      market
    }
  }
`;

export const UPDATE_ANNUAL_DEAL_ESTIMATE = gql`
  mutation updateAnnualDealEstimate($input: DealEstimateInput!) {
    updateAnnualDealEstimate(input: $input) {
      id
      soldTo {
        dealEstimate
        monthlyCreditLimit
      }
    }
  }
`;

export const UPDATE_ENROLLMENT_NUMBER = gql`
  mutation updateVLAgreementNumber(
    $input: QuoteMutationInput!
    $vlAgreementNumberForMigration: String
  ) {
    updateVLAgreementNumber(
      input: $input
      vlAgreementNumberForMigration: $vlAgreementNumberForMigration
    ) {
      id
      vlAgreementNumber
    }
  }
`;

export const VALIDATE_ENROLLMENT_NUMBER_AND_REFETCH_QUOTE = gql`
  query validateEnrollmentNumberAndRefetchQuote($id: String!, $quoteId: String!) {
    validateEnrollmentNumber(id: $id)

    getQuote(id: $quoteId) {
      id
      etag
    }
  }
`;
