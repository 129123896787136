import { ExternalUser } from './types';

//email:${UPN},org:${TenantId}:${ObjectId}`;
export const convertToExternalUser = (invitedUser?: string) => {
  if (!invitedUser) {
    return;
  }
  const parts = invitedUser.split(',');
  const upn = parts[0].split(':');
  if (!upn[1]) {
    return;
  }
  const user: ExternalUser = { UPN: upn[1] };
  if (parts[1]) {
    const org = parts[1].split(':');
    if (org[1]) {
      user.TenantId = org[1];
    }
    if (org[2]) {
      user.ObjectId = org[2];
    }
  }
  return user;
};

const msaFirstPartyTenants: string[] = [
  '9cd80435-793b-4f48-844b-6b3f37d1c1f3',
  'f8cdef31-a31e-4b4a-93e4-5f571e91255a',
];

export const convertToString = (externalUser: ExternalUser) => {
  let invitedUser;
  if (externalUser.TenantId && externalUser.ObjectId)
    invitedUser = `email:${externalUser.UPN},org:${externalUser.TenantId}:${externalUser.ObjectId}`;
  else if (externalUser.TenantId && !msaFirstPartyTenants.includes(externalUser.TenantId))
    invitedUser = `email:${externalUser.UPN},org:${externalUser.TenantId}`;
  else invitedUser = `email:${externalUser.UPN}`;
  return invitedUser;
};
