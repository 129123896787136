import { ThemeProps } from 'styles';

export const summaryListStyles = (theme: ThemeProps) => {
  return {
    list: {
      flexGrow: 1,
      display: 'flex',
      flexBasis: 0,
      minHeight: 0,
      background: theme.palette.backgroundMain,
    },
    errorBadge: {
      color: theme.palette.danger,
      marginRight: 8,
    },

    table: {
      width: 780,
      borderRight: `1px solid ${theme.palette.backgroundDivider}`,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.backgroundCommon,
      zIndex: 1,
      boxShadow: `-2px 9px 9px ${theme.palette.shadowColor}`,

      '& a': {
        color: theme.palette.text,
        textDecoration: 'none',

        '&:hover': {
          color: theme.palette.text,
          textDecoration: 'underline',
        },
      },

      '& table': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        fontSize: 14,

        '& thead': {
          borderTop: `1px solid ${theme.palette.backgroundDivider}`,
          borderBottom: `1px solid ${theme.palette.backgroundDivider}`,

          '& .sortable': {
            cursor: 'pointer',

            '&.sorted': {
              fontWeight: 'bold',
            },

            '&:hover, &:focus': {
              backgroundColor: theme.palette.backgroundDivider,
            },

            '& i': {
              marginLeft: 6,
            },
          },

          '& .progress': {
            marginLeft: -16,
            padding: 0,
            position: 'relative',
            overflow: 'visible',

            '& .ms-ProgressIndicator': {
              position: 'absolute',
              width: '100%',
              top: -1,
            },

            '& .ms-ProgressIndicator-itemProgress': {
              padding: 0,
            },
          },
        },

        '& tbody': {
          overflowY: 'overlay',
          fallbacks: { overflowY: 'auto' },
          paddingBottom: 50,
          backgroundColor: 'transparent',

          '& tr': {
            position: 'relative',
            borderBottom: `1px solid ${theme.palette.backgroundDivider}`,

            '&:hover, &:focus': {
              outline: 'none',
            },

            '&.shimmer:hover, &.shimmer:focus': {
              backgroundColor: theme.palette.backgroundStandout,
            },

            '&.active': {
              backgroundColor: theme.palette.lineItemHighlight,
              borderLeft: `2px solid ${theme.palette.primary}`,
              paddingLeft: 14,
            },

            '&.no-results': {
              border: 'none',
              animation: '$fade-in 200ms ease-in',
              backgroundColor: theme.palette.backgroundCommon,

              '& td': {
                textAlign: 'center',
                padding: '100px 0 20px 0',
              },
            },

            '&.no-results:after': {
              content: 'none',
            },

            '&:after': {
              backgroundColor: theme.palette.backgroundStandout,
              zIndex: -1,
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
            },

            '&:hover:after, &:focus:after': {
              backgroundColor: theme.palette.backgroundCommon,
              outline: 'none',
            },
          },
        },

        '& tr': {
          display: 'flex',
          paddingLeft: 16,
          cursor: 'default',
        },

        '& td, & th': {
          flex: 1,
          textAlign: 'left',
          overflow: 'hidden',
          overflowWrap: 'break-word',
        },

        '& th': {
          fontSize: 12,
          fontWeight: 400,
          padding: '6px 10px 6px 12px',
        },

        '& td': {
          padding: '12px 10px 12px 12px',
        },

        '& td:first-child, & th:first-child': {
          flexBasis: '44%',
        },

        '& td:nth-child(2), & th:nth-child(2)': {
          flexBasis: '19%',
        },

        '& td:nth-child(3), & th:nth-child(3)': {
          flexBasis: '17%',
        },

        '& td:nth-child(4), & th:nth-child(4)': {
          flexBasis: '20%',
        },
      },
    },

    bar: {
      '& > div:first-child': {
        marginLeft: 12,

        '& section > div > div': {
          '&:nth-child(2)': {
            display: 'none',
          },

          '&:nth-child(4)': {
            display: 'none',
          },
        },
      },
    },

    detail: {
      width: 572,
      backgroundColor: theme.palette.backgroundStandout,
      borderRight: `1px solid ${theme.palette.backgroundDivider}`,
      display: 'flex',
      flexDirection: 'column',

      opacity: 0,
      transition: '400ms ease-in',
    },

    detailActive: {
      opacity: 1,
    },

    pre: {
      whiteSpace: 'pre-wrap',
    },

    link: {
      color: theme.palette.textLink,

      '&:hover': {
        color: theme.palette.lineItemHighlight,
      },
    },

    choiceGroup: {
      paddingBottom: 1,

      '& .ms-ChoiceFieldGroup-flexContainer': {
        display: 'flex',

        '& .ms-ChoiceField': {
          paddingLeft: 16,
        },
      },
    },

    '@keyframes slide-in': {
      from: {
        opacity: 0,
        transform: 'translateY(20px)',
        maxHeight: 0,
      },

      to: {
        opacity: 1,
        transform: 'translateY(0)',
        maxHeight: 100,
      },
    },

    '@keyframes fade-in': {
      from: {
        opacity: 0,
      },

      to: {
        opacity: 1,
      },
    },

    expirationDateCell: {
      display: 'flex',
      alignItems: 'flexStart',
    },
    warningIconContainer: {
      alignItems: 'baseline',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 8,
      marginTop: 1,
      '& > i': {
        color: theme.palette.warningText,
      },
    },
  };
};
