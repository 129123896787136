import { TextBody } from 'components';
import { ConnectedSeatsCardContainer as SeatsInput } from 'features/proposal/components/SeatsCard';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { AssetListItem } from 'services/edge/types';
import { RootState } from 'store/types';

import { convertAssetDialogStyles } from './ConvertAssetDialog.styles';

export interface ConvertAssetSeatsDialogProps {
  selectedAssetListItem: AssetListItem;
  setEnableAddSeats: (enable: boolean) => void;
  setSeatsQuantity: (quantity: number) => void;
}

const mapStateToProps = (state: RootState) => {
  const proposal = selectors.getActiveProposal(state);
  return {
    proposal,
  };
};

type Props = ConvertAssetSeatsDialogProps &
  WithStyles<typeof convertAssetDialogStyles> &
  ReturnType<typeof mapStateToProps>;

const ConvertAssetSeatsDialogUnstyled: React.FC<Props> = props => {
  const { classes, selectedAssetListItem, setEnableAddSeats, setSeatsQuantity } = props;
  const { t } = useTranslation();

  const onQuantityChange = (quantity: number, shouldEnable: boolean) => {
    setEnableAddSeats(shouldEnable);
    shouldEnable && setSeatsQuantity(quantity);
  };

  return (
    <div className={classes.convertActionableAssetDialogBody}>
      <TextBody addClass={classes.addSeatsText}>
        {t('quote::Enter the number of seats to add to the existing {{productName}} asset.', {
          productName: selectedAssetListItem.product,
        })}
      </TextBody>
      <div className={classes.addSeatsContainer}>
        <SeatsInput
          productId={selectedAssetListItem.productId}
          quantity={(selectedAssetListItem.seats && selectedAssetListItem.seats.toString()) || ''}
          onQuantityChange={onQuantityChange}
        />
      </div>
    </div>
  );
};

export const ConvertAssetSeatsDialogUnconnected = withStyles(convertAssetDialogStyles)(
  ConvertAssetSeatsDialogUnstyled
);

export const ConvertAssetSeatsDialog = connect(mapStateToProps)(ConvertAssetSeatsDialogUnconnected);
