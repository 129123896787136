import {
  Checkbox,
  MediumIcon,
  SecondaryButton,
  TextBody,
  TextBodySmall,
  TextSubsectionHeading,
} from 'components';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { OrgWizardSharedProps, WizardDialog } from '../Shared';
import { affiliateDialogStyles } from './AffiliateDialog.styles';

export interface AffiliateDialogProps extends OrgWizardSharedProps<boolean> {
  hasLeadOrgId: boolean;
  hasSharedDiscounts: boolean;
  onNextButtonClick: (isAffiliate: boolean) => void;
}

type Props = AffiliateDialogProps & WithStyles<typeof affiliateDialogStyles>;

export const AffiliateDialogUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const [isAffiliate, setIsAffiliate] = React.useState<boolean>(props.initialData || false);

  const handleCheckboxToggle = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setIsAffiliate(!!checked);
  };

  const onClickNext = () => {
    props.onNextButtonClick(isAffiliate);
  };

  const onClickOverview = () => {
    props.onOverviewClick(isAffiliate);
  };

  const removeDiscountsMessage = () => {
    return (
      <div data-automation-id="affiliateDialogRemoveDiscountsMessage">
        <div className={classes.infoHeader}>
          <MediumIcon addClass={classes.infoIcon} iconName="Info" />
          <TextBody addClass={classes.infoHeaderText}>{t('quote::Please note')}</TextBody>
        </div>
        <div className={classes.infoBody}>
          <TextBodySmall addClass={classes.infoText}>
            {t(
              'quote::The shared discount(s) which you previously added to the quote are not applicable for non-affiliate accounts, and will be removed from the quote when you save your changes.'
            )}
          </TextBodySmall>
        </div>
      </div>
    );
  };

  const body = (
    <div>
      <TextBody>
        {t('quote::Is this billing account an affiliate of an existing billing account?')}
      </TextBody>
      <div className={classes.checkbox}>
        <Checkbox
          ariaLabel={t('quote::Yes, this is an affiliate account.')}
          checked={isAffiliate}
          dataAutomationId="affiliateAccountCheckbox"
          id="affiliateAccountCheckbox"
          label={t('quote::Yes, this is an affiliate account.')}
          onChange={handleCheckboxToggle}
        />
      </div>
      {props.hasSharedDiscounts && !isAffiliate && removeDiscountsMessage()}
      <div className={classes.definition}>
        <TextSubsectionHeading addClass={classes.definitionHeader}>
          {t('quote::Affiliate definition')}
        </TextSubsectionHeading>
        <div>
          <TextBodySmall addClass={classes.definitionText}>
            {t(
              'quote::An affiliate account is associated with a parent account which has previously signed the Microsoft Customer Agreement (MCA). The affiliate will sign a slimmed down version of the MCA called the Customer Affiliate Purchase Terms (CAPT).'
            )}
          </TextBodySmall>
          <div className={classes.definitionSpacing}>
            <TextBodySmall addClass={classes.definitionText}>
              {t(
                "quote::Additionally, they'll be able to take advantage of discounts negotiated by the parent account while maintaining control over their own purchases and invoicing."
              )}
            </TextBodySmall>
          </div>
        </div>
      </div>
    </div>
  );

  const footerButtons = [
    <SecondaryButton
      dataAutomationId="affiliateDialogNext"
      disabled={!isAffiliate}
      key="Next"
      text={i18next.t('Next')}
      onClick={onClickNext}
    />,
    <SecondaryButton
      dataAutomationId="affiliateDialogBackToOverview"
      disabled={isAffiliate && !props.hasLeadOrgId}
      key="overview"
      text={i18next.t('quote::Back to overview')}
      onClick={onClickOverview}
    />,
  ];

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      footer={footerButtons}
      headline={props.headline}
      isOverviewAvailable={props.isOverviewAvailable}
      overviewButtonOnly={false}
      title={t('quote::Affiliate account')}
      onOverviewClick={onClickOverview}
    />
  );
};

export const AffiliateDialog = withStyles(affiliateDialogStyles)(AffiliateDialogUnstyled);
