import { SmallIcon, TextBodySmall } from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import { DefaultButton } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './NavigationButton.styles';

export enum IconPosition {
  left,
  right,
}

export interface NavigationProps {
  onClick: () => void;
  text: string;
  addClass?: string;
  iconName?: string;
  ariaLabel?: string;
  disabled?: boolean;
  dataAutomationId?: string;
}

type Props = NavigationProps & WithStyles<typeof styles>;

export const NavigationBackButtonkUnstyled = (props: Props) => {
  const {
    classes,
    text,
    iconName,
    ariaLabel,
    addClass,
    onClick,
    disabled,
    dataAutomationId,
  } = props;
  return (
    <DefaultButton
      ariaLabel={ariaLabel}
      className={mergeClassNames([classes.button, addClass])}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={classes.flexContainer}>
        <SmallIcon addClass={classes.icon} iconName={iconName || 'Back'} />
        <TextBodySmall addClass={classes.backText} data-automation-id={dataAutomationId}>
          {text}
        </TextBodySmall>
      </div>
    </DefaultButton>
  );
};

export const NavigationForwardButtonUnstyled = (props: Props) => {
  const {
    classes,
    text,
    iconName,
    ariaLabel,
    addClass,
    onClick,
    disabled,
    dataAutomationId,
  } = props;
  return (
    <DefaultButton
      ariaLabel={ariaLabel}
      className={mergeClassNames([classes.button, addClass])}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={classes.flexContainer}>
        <TextBodySmall addClass={classes.forwardText} data-automation-id={dataAutomationId}>
          {text}
        </TextBodySmall>
        <SmallIcon addClass={classes.icon} iconName={iconName || 'Forward'} />
      </div>
    </DefaultButton>
  );
};

export const NavigationBackButton = withStyles(styles)(NavigationBackButtonkUnstyled);
export const NavigationForwardButton = withStyles(styles)(NavigationForwardButtonUnstyled);
