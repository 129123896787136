import { getRemainingDays } from 'features-apollo/quote/utils';
import { Approval, ApprovalActionType, ApprovalActor, ApprovalStatus } from 'generated/graphql';

import { ApprovalHistoryComment } from '../types';

/**
 * Gets the email from the User object on the ApprovalActor union
 */
export const getActorEmail = (actor: ApprovalActor) =>
  actor.__typename === 'User' ? actor.mail : undefined;

/**
 * Gets the last history log related to the given approval action
 */
export const getActionLastHistoryLog = (approval: Approval, action: ApprovalActionType) => {
  const { history } = approval;
  const actionHistoryLogs = history.filter(historyLog => historyLog.action === action);

  return actionHistoryLogs.pop();
};

/**
 * Gets all history logs related related to the Comment approval action
 */
export const getCommentHistoryLogs = (approval: Approval): ApprovalHistoryComment[] => {
  const { history } = approval;
  return history.filter(({ comments }) => !!comments) as ApprovalHistoryComment[];
};

/**
 * Gets number of days since the last submit or approve action found in the history logs
 */
export const getDaysSinceLastSubmitApprove = (approval: Approval) => {
  const { status } = approval;
  const todayDate = new Date();

  switch (status) {
    case ApprovalStatus.Submitted:
      const submitLastHistoryLog = getActionLastHistoryLog(approval, ApprovalActionType.Submit);
      const submittedDate = submitLastHistoryLog && new Date(submitLastHistoryLog.createdDate);
      return submittedDate && getRemainingDays(submittedDate, todayDate);
    case ApprovalStatus.PartiallyApproved:
      const approveLastHistoryLog = getActionLastHistoryLog(approval, ApprovalActionType.Approve);
      const approvedDate = approveLastHistoryLog && new Date(approveLastHistoryLog.createdDate);
      return approvedDate && getRemainingDays(approvedDate, todayDate);
    default:
      return;
  }
};
