import { NotificationItem, ShowMoreDialog, TextTitle } from 'components';
import { LabelListItem } from 'components/ions/CuratedText/LabelList/LabelList';
import {
  DetailsStyles,
  renderAddOns,
  renderLabelList,
  renderNotifications,
} from 'features-apollo/quote/components/DetailsPane';
import { AddOn } from 'features/proposal/components/AddOns';
import { Quote } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

export interface TermLineItemDetailsProps {
  description: string;
  strippedDescription: string;
  productName: string;
  conditions: LabelListItem[];
  notifications: NotificationItem[];
  addOns?: AddOn[];
  imageUri: string;
  publisherLink?: React.ReactNode;
  quote?: Quote;
}

type Props = TermLineItemDetailsProps & WithStyles<typeof DetailsStyles>;

const TermLineItemDetailsUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    conditions,
    productName,
    description,
    imageUri,
    publisherLink,
    strippedDescription,
    notifications,
    addOns,
    quote,
  } = props;
  const { t } = useTranslation();

  const dialogProps = {
    props: {
      id: 'description-dialog-box',
      dialogClose: React.useContext(DialogContext).closeDialog,
      headingText: productName,
      bodyText: description,
      imageUrl: imageUri,
      actionLink: publisherLink,
      dataAutomationId: 'descriptionDialogBox',
    },
    hasImage: imageUri ? true : false,
    isCustom: false,
  };

  let bodyContent = (
    <>
      {addOns && renderAddOns(classes, addOns)}
      {renderLabelList(conditions, classes.detailsPaneSection)}
      {renderNotifications(notifications, classes, quote)}
    </>
  );

  return (
    <>
      <div className={classes.heading}>
        <div>
          <TextTitle>{productName}</TextTitle>
        </div>
        <div className={classes.description} data-automation-id="productDescription">
          <ShowMoreDialog
            dataAutomationId="fullDescriptionLink"
            dialogProps={dialogProps}
            id="Description"
            linkId="full-description-link"
            linkText={t('quote::full description')}
            maxLines={5}
            text={strippedDescription}
          />
        </div>
      </div>
      <div className={classes.sectionDetails}>{bodyContent}</div>
    </>
  );
};

export const TermLineItemDetails = withStyles(DetailsStyles)(TermLineItemDetailsUnstyled);
