import { ThemeProps } from 'styles';

import { buttonSharedStyles } from '../shared.styles';

export const secondaryButtonStyles = (theme: ThemeProps) => {
  return {
    label: {
      ...buttonSharedStyles(theme).label,
    },
    secondary: {
      ...buttonSharedStyles(theme).secondary,
      fontWeight: theme.fonts.fontWeights.semiBold,
      '& .ms-Button-label': {
        fontWeight: theme.fonts.fontWeights.semiBold,
      },
    },
    secondaryIcon: buttonSharedStyles(theme).secondary,
  };
};
