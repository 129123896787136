import {
  ActionBar,
  BarButton,
  BarSearch,
  BarSearchProps,
  MediumIcon,
  ProgressIndicator,
  SmallIcon,
  StatusIndicatorApproved,
  StatusIndicatorAwaiting,
  StatusIndicatorDeleted,
  StatusIndicatorDraft,
  StatusIndicatorExpired,
  StatusIndicatorPublished,
  StatusIndicatorPurchaseFailed,
  StatusIndicatorPurchasing,
  StatusIndicatorRejected,
  StatusIndicatorUnknown,
  TextWatermark,
} from 'components';
import { ChoiceGroup, TextBodySmall } from 'components/ions';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { meplaHistory } from 'createHistory';
import { GET_APPROVALS, GET_QUOTES } from 'features-apollo/quote/components/queries';
import { QueryGetQuoteData, QueryGetQuotesData } from 'features-apollo/quote/types';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { setQuoteSearchTerm } from 'features/proposal/actions';
import { getViewStateForSummaryList } from 'features/proposal/selectors';
import { isQuoteExpiringSoon } from 'features/proposal/utils';
import {
  ModernAgreementDocument,
  OrderDirection,
  OrderInput,
  QueryGetQuotesArgs,
  Quote,
  QuoteStatus,
  UserGroup,
} from 'generated/graphql';
import i18next from 'i18n';
import { IChoiceGroupOption, ISearchBox } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { SearchProposalsSortField, SearchSortOrder } from 'services/proposal/types';
import { RootState } from 'store/types';

import { useLazyQuery } from '@apollo/react-hooks';
import { KeyCodes } from '@uifabric/utilities';

import { DeleteProposalBarButton } from '../Dialogs';
import { NewQuoteBarButton } from '../Wizards/QuoteContextWizard/NewQuoteButtons';
import { GET_QUOTE } from './queries';
import { SummaryDetailsPane } from './SummaryDetailsPane';
import { summaryListStyles } from './SummaryList.styles';

const mapStateToProps = (state: RootState) => ({
  currentUserMail: state.user.current.email,
  viewState: getViewStateForSummaryList(state),
});

const dispatchProps = {
  setQuoteSearchTerm: setQuoteSearchTerm,
};

interface SummaryListProps {
  filter: string;
  searchComponentRef?: React.RefObject<ISearchBox>;
}

type Props = SummaryListProps &
  WithStyles<typeof summaryListStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const renderStatus = (
  assignedToGroup: UserGroup,
  isExpiringSoon: boolean,
  state: string
): JSX.Element => {
  // Conditionally set as pending purchase when no approval is required...
  if (assignedToGroup === UserGroup.Customer && state === QuoteStatus.Draft) {
    return (
      <StatusIndicatorPublished>
        <Trans ns="home">Published</Trans>
      </StatusIndicatorPublished>
    );
  }

  // TODO: Handle states when we don't know if it is approved.
  switch (state) {
    case QuoteStatus.Active:
    case QuoteStatus.Completed:
    case QuoteStatus.Purchased:
      return <StatusIndicatorApproved text={i18next.t(state)} />;
    case QuoteStatus.Deleted:
      return <StatusIndicatorDeleted text={i18next.t(state)} />;
    case QuoteStatus.Rejected:
      return <StatusIndicatorRejected text={i18next.t(state)} />;
    case QuoteStatus.Expired:
      return <StatusIndicatorExpired text={i18next.t(state)} />;
    case QuoteStatus.Draft:
      return <StatusIndicatorDraft isWarning={isExpiringSoon} text={i18next.t(state)} />;

    case QuoteStatus.PurchaseFailure:
      return <StatusIndicatorPurchaseFailed text={i18next.t(state)} />;
    case QuoteStatus.PurchaseInProgress:
      return (
        <StatusIndicatorPurchasing>
          <Trans ns="home">In progress</Trans>
        </StatusIndicatorPurchasing>
      );
    case QuoteStatus.Submitted:
      return (
        <StatusIndicatorAwaiting>
          <Trans ns="home">Pending</Trans>
        </StatusIndicatorAwaiting>
      );
    case QuoteStatus.Unknown:
    default:
      return (
        <StatusIndicatorUnknown>
          <Trans ns="home">Unknown</Trans>
        </StatusIndicatorUnknown>
      );
  }
};

let handleDelete = () => {
  return;
};

// TODO: [Stretch goal] Wire up delete
const setHandleDelete = (callback: () => void) => {
  handleDelete = callback;
};

interface QueryGetModernAgreementPreviewGetQuoteData extends QueryGetQuoteData {
  getModernAgreementPreview?: ModernAgreementDocument;
}

const SummaryListUnconnected: React.FC<Props> = props => {
  const { filter, classes, currentUserMail, searchComponentRef, viewState } = props;

  useHelpContent(
    filter === 'my'
      ? HelpContent.MyQuotes
      : filter === 'my-approvals'
      ? HelpContent.NeedsMyApproval
      : HelpContent.Default
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSearchQueryExistsInUrl = !!searchParams.get('search');

  const [approvalFilterKey, setApprovalFilterKey] = useState('pending');
  const [searchTerm, setSearchTerm] = useState(viewState.search.filter || '');
  const [sortQuery, setSortQuery] = useState<OrderInput>({
    orderField: SearchProposalsSortField.ModifiedDate,
    orderDirection: SearchSortOrder.Descending.toUpperCase() as OrderDirection,
  });

  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [approvals, setApprovals] = useState<Quote[]>([]);
  const [quote, setQuote] = useState<Quote | undefined>(undefined);

  const activeProposalId = (quote && quote.id) || '';
  const activeProposalEtag = (quote && quote.etag) || '';

  const [
    getQuotes,
    { data: quotesData, loading: quotesLoading, error: quotesError },
  ] = useLazyQuery<QueryGetQuotesData, QueryGetQuotesArgs>(GET_QUOTES, {
    onCompleted: data => {
      setQuotes(data.getQuotes);
    },
  });

  useEffect(() => {
    if (quotesData) {
      setQuotes(quotesData.getQuotes);
    }
  }, [quotesData]);

  const [getApprovals, { loading: approvalsLoading, error: approvalsError }] = useLazyQuery(
    GET_APPROVALS,
    {
      onCompleted: data => {
        setApprovals(data.getApprovals);
      },
    }
  );

  const [getQuote, { data: quoteData, loading: gettingQuote }] = useLazyQuery<
    QueryGetModernAgreementPreviewGetQuoteData
  >(GET_QUOTE, {
    errorPolicy: 'all',
    onCompleted: data => {
      if (data && data.getQuote) {
        setQuote(data.getQuote);
      }
    },
  });

  const modernAgreementDocument: ModernAgreementDocument | undefined | null =
    quoteData && quoteData.getModernAgreementPreview;

  useEffect(() => {
    if (quoteData && quoteData.getQuote) {
      setQuote(quoteData.getQuote);
    }
  }, [quoteData]);

  const isLoading = quotesLoading || approvalsLoading;
  const error = quotesError || approvalsError;
  if (quote && error) {
    setQuote(undefined);
  }

  const onGetApprovals = (pendingOnly: boolean) => {
    getApprovals({
      variables: { options: { pendingOnly: pendingOnly, sort: sortQuery } },
    });
  };

  useEffect(() => {
    if (quotes && quotes.length) {
      getQuote({ variables: { id: quotes[0].id } });
    } else if (quote) {
      setQuote(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes]);
  useEffect(() => {
    if (approvals && approvals.length) {
      getQuote({ variables: { id: approvals[0].id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvals]);
  useEffect(() => {
    if (filter === 'my') {
      !isSearchQueryExistsInUrl &&
        getQuotes({ variables: { query: searchTerm, sort: [sortQuery] } });
    } else if (filter === 'my-approvals') {
      onGetApprovals(approvalFilterKey === 'pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, isSearchQueryExistsInUrl]);

  let proposalsToDisplay =
    filter === 'my'
      ? quotes
      : filter === 'my-approvals' && approvalFilterKey === 'pending'
      ? approvals.filter(summary => summary.status === QuoteStatus.Submitted)
      : approvals;

  proposalsToDisplay = proposalsToDisplay.filter(
    proposal => proposal.status !== QuoteStatus.Deleted
  );

  const { t } = useTranslation('quote');

  const onKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    const target = e.target as HTMLElement;

    switch (e.keyCode) {
      case KeyCodes.up:
        e.preventDefault();
        const previousSibling = target.previousElementSibling as HTMLElement;
        if (previousSibling) previousSibling.focus();
        else {
          const lastSibling = (target.parentElement as HTMLElement).lastChild as HTMLElement;
          if (lastSibling) lastSibling.focus();
        }
        break;
      case KeyCodes.down:
        e.preventDefault();
        const nextSibling = target.nextElementSibling as HTMLElement;
        if (nextSibling) nextSibling.focus();
        else {
          const firstSibling = (target.parentElement as HTMLElement).firstChild as HTMLElement;
          if (firstSibling) firstSibling.focus();
        }
        break;
      case KeyCodes.space:
        e.preventDefault();
        target.click();
        break;
      case KeyCodes.enter:
        const link = target.querySelector('a');
        if (link) link.click();
        break;
      case KeyCodes.del:
        handleDelete();
        break;
    }
  };

  const onSearch = (value?: string) => {
    if (value === undefined) {
      return;
    }
    const newValue = value ? value.replace(/'/g, '') : '';
    setSearchTerm(newValue);
    props.setQuoteSearchTerm(newValue);
    getQuotes({
      variables: { query: newValue, sort: [sortQuery] },
    });
  };

  const onClearSearch = () => {
    setSearchTerm('');
    props.setQuoteSearchTerm('');
    getQuotes({
      variables: { query: '', sort: [sortQuery] },
    });
  };

  const onChangePendingOnly = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    if (option) {
      setApprovalFilterKey(option.key);
      onGetApprovals(option.key === 'pending');
    }
  };

  const handleSort = (field: SearchProposalsSortField) => {
    return () => {
      let direction = sortQuery.orderDirection;
      if (
        direction === (SearchSortOrder.Ascending.toUpperCase() as OrderDirection) ||
        field === SearchProposalsSortField.ModifiedDate
      ) {
        direction = SearchSortOrder.Descending.toUpperCase() as OrderDirection;
        setSortQuery({
          orderDirection: direction,
          orderField: field,
        });
      } else {
        direction = SearchSortOrder.Ascending.toUpperCase() as OrderDirection;
        setSortQuery({
          orderDirection: direction,
          orderField: field,
        });
      }

      if (filter === 'my') {
        getQuotes({
          variables: {
            query: searchTerm,
            sort: [
              {
                orderField: field,
                orderDirection: direction,
              },
            ],
          },
        });
      } else if (filter === 'my-approvals') {
        getApprovals({
          variables: {
            options: {
              pendingOnly: approvalFilterKey === 'pending',
              sort: {
                orderField: field,
                orderDirection: direction,
              },
            },
          },
        });
      }
    };
  };

  const emptyMessage =
    filter === 'my'
      ? searchTerm
        ? t('quote::No quotes found. Please try another search or try modifying your search term.')
        : t('quote::No quotes found. Create a new quote or search for one.')
      : t('quote::There are currently no quotes awaiting your approval.');

  const errorMessage =
    filter === 'my' ? t('quote::Unable to load quotes') : t('quote::Unable to load approvals');

  const getSortIcon = (sortField: SearchProposalsSortField) => {
    if (sortQuery.orderField !== sortField) {
      return <SmallIcon iconName="Sort" />;
    }

    if (sortQuery.orderDirection === (SearchSortOrder.Ascending.toUpperCase() as OrderDirection)) {
      return <SmallIcon iconName="SortUp" />;
    } else {
      return <SmallIcon iconName="SortDown" />;
    }
  };

  const errorWatermark = (
    <>
      <SmallIcon addClass={classes.errorBadge} iconName="StatusErrorFull" />
      <TextBodySmall>{errorMessage}</TextBodySmall>
    </>
  );
  const emptyWatermark = <TextWatermark>{emptyMessage}</TextWatermark>;

  const listWatermark = (
    <tr className="no-results">
      <td colSpan={4}>{error ? errorWatermark : emptyWatermark}</td>
    </tr>
  );

  const tableBodyWithContents =
    !!proposalsToDisplay.length &&
    proposalsToDisplay.map(summary => {
      const expirationDate = new Date(summary.expirationDate);
      const isExpiringSoon = isQuoteExpiringSoon(expirationDate);
      const crmId = summary.crmLead && summary.crmLead.id;
      return (
        <tr
          className={activeProposalId === summary.id ? 'active' : ''}
          data-automation-id={summary.id}
          key={summary.id}
          tabIndex={activeProposalId === summary.id ? 0 : -1}
          onClick={() => {
            getQuote({ variables: { id: summary.id } });
          }}
          onDoubleClick={() => meplaHistory.push(`${routes.quote.forId(summary.id)}`)}
          onKeyDown={onKeyDown}
        >
          <td>
            <NavLink
              data-automation-id="proposalRow"
              tabIndex={-1}
              title={summary.name}
              to={routes.quote.forId(summary.id)}
              onClick={event => event.stopPropagation()}
            >
              {summary.name}
            </NavLink>
          </td>
          <td>{crmId && crmId.toUpperCase()}</td>
          <td className={classes.expirationDateCell}>
            {convertDateToFormattedString(expirationDate, LocaleDateFormat.ll)}
            {isExpiringSoon && summary.status !== QuoteStatus.Completed && (
              <div className={classes.warningIconContainer}>
                <MediumIcon iconName="Warning" />
              </div>
            )}
          </td>
          <td>{renderStatus(summary.assignedTo, isExpiringSoon, summary.status)}</td>
        </tr>
      );
    });

  const finishedAndHaveContent = !isLoading && !error && !!proposalsToDisplay.length;
  const finishedEmpty = !isLoading && !proposalsToDisplay.length;
  const finishedWithErrors = !isLoading && error;

  let tableBody = undefined;
  if (finishedAndHaveContent) {
    tableBody = tableBodyWithContents;
  } else if (finishedEmpty || finishedWithErrors) {
    tableBody = listWatermark;
  }

  const canDelete =
    quote &&
    (quote.status === QuoteStatus.Expired || quote.status === QuoteStatus.Draft) &&
    quote.assignedTo === UserGroup.Field &&
    quote.msContact &&
    quote.msContact.mail === currentUserMail;

  const summaryDetailsPane = (
    <SummaryDetailsPane
      loading={gettingQuote}
      modernAgreementDocument={modernAgreementDocument}
      quote={quote}
    />
  );

  return (
    <div className={classes.list}>
      <div className={classes.table}>
        <div className={classes.bar}>
          <ActionBar grow={true} hideSeparator={true}>
            <NewQuoteBarButton />
            <BarButton
              ariaLabel={t('quote::Edit Quote')}
              dataAutomationId="editProposalButton"
              disabled={!activeProposalId}
              iconName="edit"
              showNewIconColor
              text={t('quote::Edit')}
              onClick={() => meplaHistory.push(`${routes.quote.forId(activeProposalId)}`)}
            />
            <DeleteProposalBarButton
              dataAutomationId="deleteProposalButton"
              disabled={!canDelete}
              fedIndirectEnabled
              proposalEtag={activeProposalEtag}
              proposalId={activeProposalId}
              provideCallback={setHandleDelete}
              searchTerm={searchTerm}
              sortQuery={sortQuery}
            />
            {filter === 'my' ? (
              <SummaryListBarSearch
                ariaLabel={t('quote::Search for {{item}}', {
                  item: t('quote::Quotes').toLocaleLowerCase(),
                })}
                componentRef={searchComponentRef}
                dataAutomationId="summaryListBarSearch"
                defaultValue={searchTerm}
                focusPlaceholder={t(
                  'quote::Search for Quotes by name, CRM ID, MS contact, and more...'
                )}
                placeholder={t('quote::Search')}
                onChangeDebounced={onSearch}
                onClear={onClearSearch}
                onSearch={onSearch}
              />
            ) : (
              <ChoiceGroup
                className={classes.choiceGroup}
                data-automation-id="pendingApprovalChoiceGroup"
                options={[
                  {
                    key: 'pending',
                    text: t('quote::Pending my approval'),
                  },
                  {
                    key: 'all',
                    text: t('quote::All'),
                  },
                ]}
                selectedKey={approvalFilterKey}
                onChange={onChangePendingOnly}
              />
            )}
          </ActionBar>
        </div>

        <table>
          <thead>
            <tr>
              <th>{t('quote::Name')}</th>
              <th>{t('quote::CRM ID')}</th>
              <th
                className={`sortable ${sortQuery.orderField ===
                  SearchProposalsSortField.ExpirationDate && 'sorted'}`}
                onClick={handleSort(SearchProposalsSortField.ExpirationDate)}
              >
                {t('quote::Good until')}
                {getSortIcon(SearchProposalsSortField.ExpirationDate)}
              </th>
              <th>{t('quote::Status')}</th>

              {/* 
              TODO: Re-enable this column once Quote Service (search) fixes the bug - only sort-by modified working as of 7/2019 -bf
              
              <th
                className={`sortable ${editor.search.sortField ===
                  SearchProposalsSortField.Status && 'sorted'}`}
                onClick={handleSort(SearchProposalsSortField.Status)}
              >
                Status
                <SmallIcon iconName="sort" />
              </th> */}
            </tr>
            <tr>
              <td className="progress" colSpan={4}>
                {isLoading && <ProgressIndicator />}
              </td>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>

        {/* <pre className={classes.pre}>{JSON.stringify(proposals)}</pre> */}
      </div>

      <div className={`${classes.detail} ${activeProposalId ? classes.detailActive : ''}`}>
        {summaryDetailsPane}
      </div>
    </div>
  );
};

export const SummaryList = connect(
  mapStateToProps,
  dispatchProps
)(withStyles(summaryListStyles)(SummaryListUnconnected));

// TODO:This is a hacky optimization, The main reason for this is if setState is in the SummaryList, summarylist renders on every keypress and that makes the input laggy
// With putting here only this component re-renders and not the whole list on every keypress, it get rids of a noticeable lag
interface SummaryListBarSearchProps extends BarSearchProps {
  defaultValue: string;
}

const SummaryListBarSearch = (props: SummaryListBarSearchProps) => {
  const [search, setSearch] = React.useState<string | undefined>(props.defaultValue);
  const onChange = (value?: string) => {
    const newValue = value ? value.replace(/'/g, '') : '';
    setSearch(newValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { defaultValue, ...rest } = props;
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    // Use the search term(s) from the query string if they are given. This will result
    // in the search being applied from the search term when this component is initialized.
    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get('search');
    if (searchParam && props.onSearch) {
      props.onSearch(searchParam);
      setSearch(searchParam);
      // Rewrite the url to exclude the search term(s). If they are not removed, subsequent
      // searches will not replace the url param and a "back" navigation will use the url
      // param rather than the last actual search term from 'editor.search.filter'.
      const urlWithQueryRemoved = new URL(window.location.href);
      urlWithQueryRemoved.searchParams.delete('search');
      history.replace(
        decodeURIComponent(`${urlWithQueryRemoved.pathname}${urlWithQueryRemoved.search}`)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, history]);

  const onClear = () => {
    setSearch(undefined);
    props.onClear && props.onClear();
  };

  return <BarSearch {...rest} value={search} onChange={onChange} onClear={onClear} />;
};
