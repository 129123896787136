import React, { PropsWithChildren } from 'react';

interface Props {
  wrapperClass: string;
}
/**
 *  Given a class name and children this function will wrap the child element
 *  in a div with the given class. If children are not provided it will simply return null
 *
 */
export const RenderIfExists: React.FC<Props> = ({
  children,
  wrapperClass,
}: PropsWithChildren<Props>) => {
  if (children) {
    return <div className={wrapperClass}>{children}</div>;
  }
  return null;
};

/**
 *  Given a class name and children this function will wrap the child element
 *  in a span with the given class. If children are not provided it will simply return null
 *
 */
export const RenderIfExistsSpan: React.FC<Props> = ({
  children,
  wrapperClass,
}: PropsWithChildren<Props>) => {
  if (children) {
    return <span className={wrapperClass}>{children}</span>;
  }
  return null;
};
