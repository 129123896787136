import { NavLinkInternal } from 'components/ions/Links';
import React from 'react';
import { routes } from 'routes';
import { FontSizes } from 'styles';

export interface CwaLinkProps {
  tpid: string;
  dataAutomationId: string;
  fontSize?: keyof FontSizes;
  linkText: string;
  className?: string;
}
export const CwaLink = (props: CwaLinkProps) => {
  const { tpid, linkText, ...linkProps } = props;
  return (
    <NavLinkInternal {...linkProps} to={routes.customer.agreementsForId(tpid)}>
      {linkText}
    </NavLinkInternal>
  );
};
