import { CreditRequest } from 'services/credit/types';
import { Proposal } from 'services/proposal/types';

export interface RequestCreditLine {
  quote: Proposal;
  creditRequest: CreditRequest;
  annualDealEstimate: string;
  minimumCreditLine: string;
}

export interface RequestCreditLineState {
  annualDealEstimate: string;
  minimumCreditLine: string;
  annualDealEstimateError: string;
  minimumCreditLineError: string;
}

export const CreditConstant = {
  maxDealEstimateLength: 15,
};

export interface AnnualDealEstimatePayload {
  annualDealEstimate: string;
  minCreditLineDivisor: number;
}
