import { ToolBarAtom } from 'components/atoms';
import { SectionSeparator } from 'components/ions';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { actionBarStyles } from './ActionBar.styles';

export interface ActionBarProps {
  id?: string;
  children?: React.ReactNode[];
  rightSideCommands?: React.ReactElement | React.ReactElement[];
  hideSeparator?: boolean;
  grow?: boolean;
}

type Props = ActionBarProps & WithStyles<typeof actionBarStyles>;

const ActionBarUnStyled: React.FC<Props> = (props: Props) => {
  return (
    <div>
      <ToolBarAtom
        grow={props.grow}
        id={props.id}
        mainClass={props.classes.commandBarStyle}
        rightSideCommands={props.rightSideCommands}
      >
        {props.children}
      </ToolBarAtom>
      {!props.hideSeparator && <SectionSeparator />}
    </div>
  );
};

export const ActionBar = withStyles(actionBarStyles)(ActionBarUnStyled) as React.FC<ActionBarProps>;
