import { Spinner, SpinnerSize } from 'components';
import { meplaHistory } from 'createHistory';
import { isFirstRunViewed } from 'features/user/selectors';
import { PropertySheetLayout } from 'layouts/PropertySheet';
import WithCommandRow from 'layouts/WithCommandRow';
import React, { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import * as actions from '../actions';
import * as selectors from '../selectors';
import BackToProposalBar from './BackToProposalBar';
import { CommandBar } from './CommandBar';
import { openIncompatibilityDialog } from './Dialogs/IncompatibilityDialog/IncompatibilityDialog';
import { Editor } from './Editor';
import { Customer, Info, Sales } from './PropertySheets';

const styles = {
  spinner: {
    flexGrow: 1,
  },
};

const mapStateToProps = (state: RootState) => {
  const proposal = selectors.getActiveProposal(state);
  const quoteNeedsUpgrade = proposal && selectors.doesQuoteNeedUpgrade(proposal);
  return {
    proposal,
    quoteNeedsUpgrade,
    isLoading: selectors.proposalLoading(state),
    quoteId: proposal && proposal.id,
    viewedFirstRun: isFirstRunViewed(state),
    isPartnerProposal: selectors.isPartnerProposal(state),
  };
};

const dispatchProps = {
  getProposal: (proposalId: string) => actions.loadAndHydrateAllQuote(proposalId),
  clearProposal: actions.clearProposal,
  clearItemSelection: actions.clearItemSelection,
};

interface Params {
  id: string;
}

type Props = {} & ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  RouteComponentProps<Params> &
  WithTranslation &
  WithStyles<typeof styles>;

const getCommandBar = () => {
  return (
    <Switch>
      <Route component={BackToProposalBar} exact path={routes.quote.customer} />
      <Route component={BackToProposalBar} exact path={routes.quote.sales} />
      <Route component={BackToProposalBar} exact path={routes.quote.info} />
      <Route component={CommandBar} />
    </Switch>
  );
};

const Quote: React.FC<Props> = props => {
  const {
    match,
    getProposal,
    clearProposal,
    clearItemSelection,
    isLoading,
    quoteId,
    proposal,
    classes,
    quoteNeedsUpgrade,
    viewedFirstRun,
    isPartnerProposal,
  } = props;
  const context = React.useContext(DialogContext);

  useEffect(() => {
    getProposal(match.params.id);
    return () => {
      clearProposal();
      clearItemSelection();
    };
  }, [match.params.id, getProposal, clearProposal, clearItemSelection]);

  React.useEffect(() => {
    if (match.params.id === quoteId && quoteNeedsUpgrade && viewedFirstRun) {
      openIncompatibilityDialog(context);
    }
  }, [context, match.params.id, quoteId, quoteNeedsUpgrade, viewedFirstRun]);

  const { t } = useTranslation();

  useHotkeys('g+t', () => {
    meplaHistory.push(routes.quote.termsForId(match.params.id));
  });
  useHotkeys('g+p', () => {
    meplaHistory.push(routes.quote.productsForId(match.params.id));
  });
  useHotkeys('g+s', () => {
    meplaHistory.push(routes.quote.salesForId(match.params.id));
  });
  useHotkeys('g+c', () => {
    meplaHistory.push(routes.quote.customerForId(match.params.id));
  });
  useHotkeys('g+i', () => {
    meplaHistory.push(routes.quote.infoForId(match.params.id));
  });

  const commandBar = getCommandBar();

  const renderCustomer = () => {
    if (isPartnerProposal) {
      return (
        <PropertySheetLayout
          mainContent={[
            {
              mainContent: <Customer />,
              leftPaneTitle: t('quote::Partner'),
            },
            {
              mainContent: <Customer isPartnerCustomer />,
              leftPaneTitle: t('quote::Customer'),
            },
          ]}
        />
      );
    } else {
      return (
        <PropertySheetLayout
          mainContent={[{ mainContent: <Customer />, leftPaneTitle: t('quote::Customer') }]}
        />
      );
    }
  };

  const propertySheetRoutes = [
    <Route
      exact
      key={routes.quote.customer}
      path={routes.quote.customer}
      render={renderCustomer}
    />,
    <Route
      exact
      key={routes.quote.sales}
      path={routes.quote.sales}
      render={() => (
        <PropertySheetLayout
          mainContent={[
            {
              mainContent: <Sales quote={proposal} />,
              leftPaneTitle: t('quote::Sales'),
            },
          ]}
        />
      )}
    />,
    <Route
      exact
      key={routes.quote.info}
      path={routes.quote.info}
      render={() => (
        <PropertySheetLayout
          mainContent={[
            {
              mainContent: <Info />,
              leftPaneTitle: t('quote::Info'),
            },
          ]}
        />
      )}
    />,
  ];

  return (
    <WithCommandRow commandBar={proposal && commandBar}>
      {!proposal && isLoading && <Spinner className={classes.spinner} size={SpinnerSize.large} />}
      {proposal && (
        <Switch>
          {propertySheetRoutes}
          <Route
            exact
            path={routes.quote.root}
            render={() => <Redirect to={routes.quote.productsForId(match.params.id)} />}
          />
          <Route path={routes.quote.root} render={() => <Editor proposal={proposal} />} />
          <Route render={() => <Redirect to={routes.quote.forId(match.params.id)} />} />
        </Switch>
      )}
    </WithCommandRow>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(withTranslation('quote')(withStyles(styles)(Quote)));
