import { BarButton, CommandBarButton } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import { NewProposalDialog } from './NewDialog';

export const NewProposalCommandBarButton: React.FC = () => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation('quote');

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <NewProposalDialog id="newProposalDialog" />,
  };

  const handleOnClick = () => {
    context.openDialog(dialogProps);
  };

  return (
    <CommandBarButton
      ariaLabel={t('quote::Create new quote')}
      dataAutomationId="createNewProposalCommandBarButton"
      iconName="Add"
      id="createNewProposalButton"
      text={t('quote::New')}
      onClick={handleOnClick}
    />
  );
};

export const NewProposalBarButton: React.FC = () => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  // Dialog configuration
  const dialogProps: DialogProps = {
    providedDialog: <NewProposalDialog id="newProposalDialog" />,
  };

  const handleOnClick = () => {
    context.openDialog(dialogProps);
  };

  return (
    <BarButton
      dataAutomationId="createNewProposalBarButton"
      iconName="Add"
      id="createNewProposalButton"
      text={t('home::New')}
      onClick={handleOnClick}
    />
  );
};
