import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => {
  const { fonts, palette } = theme;
  return {
    scroll: {
      display: 'flex',
      overflowY: 'scroll',
      flexGrow: 1,
      flexDirection: 'column',
      padding: 32,
    },
    list: {
      padding: 'unset',
      marginTop: 0,
      marginBottom: 0,
    },
    sectionHeader: {
      color: palette.primary,
      fontWeight: fonts.fontWeights.regular,
      fontSize: fonts.fontSizes.xLarge,
      marginTop: 0,
    },
    search: {
      paddingBottom: 32,
      maxWidth: 700,
    },
    label: {
      paddingBottom: 10,
    },
  };
};
