import * as actions from 'features/proposal/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { PurchaseConfig } from 'services/purchase/config';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';

export function* createPurchaseRecord() {
  const relevantAction = actions.createPurchaseRecordAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const purchaseConfig: PurchaseConfig = yield select(
        (state: RootState) => state.app.appConfig.purchase
      );
      const response: boolean = yield call(
        api.purchase.createPurchaseRecord,
        action.payload,
        purchaseConfig
      );
      yield put(actions.createPurchaseRecordAsync.success(response));
      yield put(actions.loadAndHydrateLiteQuote(action.payload.quoteId));
    } catch (err) {
      yield put(
        actions.createPurchaseRecordAsync.failure({
          message: t('error::Error creating purchase record.'),
          exception: err,
        })
      );
    }
  });
}
