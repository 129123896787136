import moment from 'moment';
import { NpsSurvey } from 'generated/graphql';

export const npsSurveySampleRate = 1; //100% chance to see the survey

export const isEligibleForSurvey = (surveyHistory: NpsSurvey[]) => {
  let isEligible = false;

  if (!surveyHistory.length) {
    isEligible = true;
  } else if (surveyHistory.find(x => x.responded)) {
    isEligible = false;
  } else if (surveyHistory.length === 1) {
    const lastSeen = surveyHistory[0];
    isEligible = moment().diff(moment(lastSeen.date), 'days') >= 30;
  }

  return isEligible;
};
