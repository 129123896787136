import { Product, ServiceFamily } from 'services/catalog/types';
import { Market, getCurrencies } from '../supported-markets';
import { ProductIdentifier, LineItem } from 'services/proposal/types';
import { oc } from 'ts-optchain';
import { isMonthDuration } from 'components/utilities/dates';
import { generateProductIdentifierKey } from '../utils';

export const createSaasRenewalPriceInfoNotification = (
  product: Product,
  lineItem: LineItem,
  market: Market
):
  | {
      productIdentifier: ProductIdentifier;
      key: string;
      skuTitle?: string;
      currency?: string;
      term?: string;
    }
  | undefined => {
  const isSaasTrialProd =
    oc(product)
      .Properties.ServiceFamily('')
      .toLowerCase() === ServiceFamily.SaaS;
  if (isSaasTrialProd) {
    const skuAvailability =
      product &&
      product.DisplaySkuAvailabilities.find(
        skuAvailability => skuAvailability.Sku.SkuId === lineItem.productIdentifier.skuId
      );
    const transferInstruction =
      skuAvailability &&
      skuAvailability.Availabilities[0].AssetsData &&
      skuAvailability.Availabilities[0].AssetsData.TransferInstructions &&
      skuAvailability.Availabilities[0].AssetsData.TransferInstructions.find(
        transferInstruction =>
          lineItem.productIdentifier.availabilityTermId &&
          transferInstruction.CurrentTermIds.includes(lineItem.productIdentifier.availabilityTermId)
      );
    const termId = oc(transferInstruction).TransferOptions[0].RenewalData.RenewToTermId();
    const renewAvailability =
      skuAvailability &&
      skuAvailability.Availabilities.find(avail =>
        avail.Actions.some((action: string) => action.toUpperCase() === 'RENEW')
      );
    const renewAvailabilityId = renewAvailability && renewAvailability.AvailabilityId;
    const renewTerm =
      renewAvailability &&
      renewAvailability.Terms &&
      renewAvailability.Terms.find(term => term.TermId === termId);
    const renewTermDesc = renewTerm && isMonthDuration(renewTerm.TermUnits) ? 'month' : 'year';
    const productIdentifier: ProductIdentifier = {
      productId: lineItem.productIdentifier.productId,
      skuId: lineItem.productIdentifier.skuId,
      availabilityId: renewAvailabilityId,
      availabilityTermId: termId,
      action: lineItem.productIdentifier.action,
    };
    const key = generateProductIdentifierKey(productIdentifier);
    const currency = getCurrencies(market)[0];

    return {
      productIdentifier,
      key,
      skuTitle: oc(lineItem).extendedProperties.userPreferences.skuTitle(),
      currency,
      term: renewTermDesc,
    };
  }
};
