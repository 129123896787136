import { VerificationFieldProps } from './VerificationField';

export const verificationFieldStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  textbox: (props: VerificationFieldProps) => {
    return {
      width: props.textboxMaxLength || '374px',
      paddingRight: 8,
    };
  },
  button: {
    paddingTop: 30,
  },
  buttonNoLabel: {
    paddingTop: 1,
  },
  spinner: {
    paddingTop: 28,
    paddingLeft: 28,
  },
  spinnerNoLabel: {
    alignSelf: 'center',
    paddingLeft: 28,
  },
  status: {
    paddingTop: 35,
  },
  statusNoLabel: {
    alignSelf: 'center',
  },
};
