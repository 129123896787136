import { AnimateIn, TextNavBarTitlePrimary } from 'components';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

interface NavBarTitleProps {
  /**
   * Content
   */
  title: string;
  /**
   * Adds animation to the text
   */
  animated?: boolean;
  /**
   * Prevents errors when added to an array
   */
  key?: string;
  /**
   * Removes boldness on text
   */
  regular?: boolean;
  /**
   * Shows animation when content is done loading
   */
  loading?: boolean;
  /**
   * Element ID for Automation Testing
   */
  dataAutomationId?: string;
}

const styles = (theme: ThemeProps) => ({
  title: {
    fontWeight: theme.fonts.fontWeights.semiBold,
    color: theme.palette.titleBarPrimaryText,
  },
});

type Props = NavBarTitleProps & WithStyles<typeof styles>;

const NavBarTitleUnstyled: React.FC<Props> = props => {
  const { title, classes, dataAutomationId } = props;

  return (
    <TextNavBarTitlePrimary
      addClass={props.regular ? undefined : classes.title}
      dataAutomationId={dataAutomationId}
      key={props.key}
      title={title}
    >
      {props.animated ? <AnimateIn hasContent={!props.loading}>{title}</AnimateIn> : title}
    </TextNavBarTitlePrimary>
  );
};

export const NavBarTitle = withStyles(styles)(NavBarTitleUnstyled);
