import { ThemeProps } from 'styles';

export const infoCalloutButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    button: {
      fontSize: theme.fonts.fontSizes.large,
      fontWeight: theme.fonts.fontWeights.regular,
      padding: '7px 8px',
      minWidth: 16,
      height: 32,
      verticalAlign: 'middle',
      backgroundColor: 'transparent',
      color: colors.textLink,
      '&:hover': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.textLinkHover,
        },
      },
      '&:active': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.textLink,
        },
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.textDisabled,
        },
      },
    },
    icon: {
      color: colors.textLink,
      fontSize: theme.fonts.fontSizes.large,
      margin: 0,
    },
  };
};
