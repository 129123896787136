import { TextBody, TextBodySmall } from 'components/ions/Text';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { conditionsListStyles } from './ConditionsList.styles';

export interface ConditionItem {
  name: string;
  value: string;
  status: string;
  dataAutomationId?: string;
}
export interface ConditionsListProps {
  id?: string;
  conditions: ConditionItem[];
  largerText?: boolean;
}

type Props = ConditionsListProps & WithStyles<typeof conditionsListStyles>;

const ConditionsListUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, id, conditions, largerText } = props;
  const renderIfPresent = (text: string, status: string) => {
    if (text) {
      if (status === 'safe') {
        return <span className={classes.safe}>{text}</span>;
      } else if (status === 'danger') {
        return <span className={classes.danger}>{text}</span>;
      }
      return <span>{text}</span>;
    }
    return '-';
  };

  return (
    <div className={classes.listContainer} id={id}>
      {conditions.map((condition, key) => (
        <div className={classes.row} key={key}>
          <div className={classes.column}>
            {largerText ? (
              <TextBody dataAutomationId={condition.dataAutomationId + 'Label'}>
                {renderIfPresent(condition.name, '')}:
              </TextBody>
            ) : (
              <TextBodySmall dataAutomationId={condition.dataAutomationId + 'Label'}>
                {renderIfPresent(condition.name, '')}:
              </TextBodySmall>
            )}
          </div>
          <div className={`${classes.column} ${classes.rightAlign}`}>
            <TextBodySmall dataAutomationId={condition.dataAutomationId + 'Value'}>
              {renderIfPresent(condition.value, condition.status)}
            </TextBodySmall>
          </div>
        </div>
      ))}
    </div>
  );
};

export const ConditionsList = withStyles(conditionsListStyles)(ConditionsListUnstyled) as React.FC<
  ConditionsListProps
>;
