import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { TextSharedProps } from '../TextSharedProps';
import { h1AtomStyles } from './H1Atom.styles';

type Props = TextSharedProps & WithStyles<typeof h1AtomStyles>;

const H1: React.FC<Props> = props => {
  const className = `${props.classes.headingStyles} ${props.addClass}`;
  return (
    <h1
      className={className}
      data-automation-id={props.dataAutomationId}
      id={props.id}
      title={props.title}
    >
      {props.children}
    </h1>
  );
};

export const H1Atom = withStyles(h1AtomStyles)(H1) as React.FC<TextSharedProps>;
