import { Audience as Segment, NationalCloud } from 'generated/graphql';
import i18next from 'i18n';
import { ISelectableOption } from 'office-ui-fabric-react';

const getGlobalCloudOption = (): ISelectableOption => ({
  key: NationalCloud.Global,
  text: i18next.t('quote::Global'),
  ariaLabel: i18next.t('quote::Global'),
});
const getUsGovCloudOption = (): ISelectableOption => ({
  key: NationalCloud.UsGov,
  text: i18next.t('quote::US Gov'),
  ariaLabel: i18next.t('quote::US Gov'),
});
const getUsSecCloudOption = (): ISelectableOption => ({
  key: NationalCloud.UsSec,
  text: i18next.t('quote::US Sec'),
  ariaLabel: i18next.t('quote::US Sec'),
});
const getUsNatCloudOption = (): ISelectableOption => ({
  key: NationalCloud.UsNat,
  text: i18next.t('quote::US Nat'),
  ariaLabel: i18next.t('quote::US Nat'),
});

export const getFulfillmentCloudsBySegment = (
  segment: Segment,
  usNatEnabled: boolean = false
): ISelectableOption[] => {
  const clouds: ISelectableOption[] = [];
  if (segment === Segment.Government) {
    clouds.push(getUsGovCloudOption());
    clouds.push(getUsSecCloudOption());
    usNatEnabled && clouds.push(getUsNatCloudOption());
  } else if (segment === Segment.Commercial) {
    clouds.push(getGlobalCloudOption());
  }
  return clouds;
};

export const getAllFulfillmentClouds = (usNatEnabled: boolean = false): ISelectableOption[] => {
  const clouds = [getGlobalCloudOption(), getUsGovCloudOption(), getUsSecCloudOption()];
  usNatEnabled && clouds.push(getUsNatCloudOption());
  return clouds;
};
