import { ThemeProps } from 'styles';

export const h1AtomStyles = (theme: ThemeProps) => {
  return {
    headingStyles: {
      fontSize: theme.fonts.fontSizes.xLarge,
      fontWeight: theme.fonts.fontWeights.semiBold,
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      color: theme.palette.text,
      margin: 0,
    },
  };
};
