import { CalloutCalendarProps, ComboBox } from 'components/ions';
import { CalendarDropdown } from 'components/molecules';
import { mergeClassNames } from 'components/utilities';
import { calendarStrings } from 'components/utilities/calendarStrings';
import {
  CeilingDateOptions,
  FutureDateOptions,
} from 'features-apollo/quote/components/DiscountCard';
import {
  addEndDate,
  addPriceCeilingDate,
  addStartDate,
  endSelected,
  isValidDate,
  priceCeilingSelect,
  startSelected,
  createDateFromKey,
} from 'features-apollo/quote/components/DiscountCard/utils';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { discountCardCeilingStyles, discountCardFutureSectionStyles } from './DiscountCard.styles';

type CeilingProps = CeilingDateOptions & WithStyles<typeof discountCardCeilingStyles>;

const DiscountCardCeilingUnstyled: React.FunctionComponent<CeilingProps> = (
  props: CeilingProps
) => {
  const { t } = useTranslation();
  const {
    classes,
    disabled,
    startOptions,
    endOptions,
    useDayPicker,
    setDateState,
    dateRanges,
    priceCeilingOptions,
  } = props;

  const addCeiling = (date: Date, selectedDateRange?: Date[]) => {
    const newDates = addPriceCeilingDate(date, priceCeilingOptions, startOptions, endOptions);

    setDateState &&
      setDateState({
        startOptions: newDates.newStartOptions,
        endOptions: newDates.newEndOptions,
        ceilingOptions: newDates.newPriceCeilingOptions,
      });
  };

  const selectCeiling = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option && typeof option.key === 'string') {
      const newDates = priceCeilingSelect(
        option.key,
        priceCeilingOptions,
        startOptions,
        endOptions
      );
      setDateState &&
        setDateState({
          startOptions: newDates.newStartOptions,
          endOptions: newDates.newEndOptions,
          ceilingOptions: newDates.newPriceCeilingOptions,
        });
    }
  };

  const addStart = (date: Date, selectedDateRange?: Date[]) => {
    const newDates = addStartDate(date, useDayPicker, startOptions, endOptions, dateRanges);
    setDateState &&
      setDateState({
        startOptions: newDates.newStartOptions,
        endOptions: newDates.newEndOptions,
        dateRanges: newDates.newRanges,
      });
  };

  const selectStart = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option && typeof option.key === 'string') {
      const newDates = startSelected(
        option.key,
        useDayPicker,
        startOptions,
        endOptions,
        dateRanges
      );
      setDateState &&
        setDateState({
          startOptions: newDates.newStartOptions,
          endOptions: newDates.newEndOptions,
          dateRanges: newDates.newRanges,
        });
    }
  };

  const addEnd = (date: Date, selectedDateRange?: Date[]) => {
    const newDates = addEndDate(date, useDayPicker, startOptions, endOptions, dateRanges);
    setDateState &&
      setDateState({
        startOptions: newDates.newStartOptions,
        endOptions: newDates.newEndOptions,
        dateRanges: newDates.newRanges,
      });
  };

  const selectEnd = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option && typeof option.key === 'string') {
      const newDates = endSelected(option.key, useDayPicker, startOptions, endOptions, dateRanges);
      setDateState &&
        setDateState({
          startOptions: newDates.newStartOptions,
          endOptions: newDates.newEndOptions,
          dateRanges: newDates.newRanges,
        });
    }
  };

  const today = new Date();
  const enableStartCalendar = isValidDate(priceCeilingOptions.selectedKey);
  const enableEndCalendar = isValidDate(startOptions.selectedKey);

  const priceCeilingCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'price-ceiling-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: disabled,
    },
    calendarStrings,
    isDayPickerVisible: true,
    defaultDate: dateRanges.ceilingDate.selected,
    minDate: dateRanges.ceilingDate.minDate,
    maxDate: dateRanges.ceilingDate.maxDate,
    onSelectDate: addCeiling,
    today,
    showTodayButton: true,
  };
  const startFromCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'start-ceiling-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: !enableStartCalendar || disabled,
    },
    calendarStrings,
    isDayPickerVisible: useDayPicker,
    defaultDate: dateRanges.startDate.selected,
    minDate: dateRanges.startDate.minDate,
    maxDate: dateRanges.startDate.maxDate,
    today,
    onSelectDate: addStart,
    showTodayButton: useDayPicker,
  };
  const goodForCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'good-for-ceiling-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: !enableEndCalendar || disabled,
    },
    calendarStrings,
    isDayPickerVisible: useDayPicker,
    defaultDate: dateRanges.endDate.selected,
    minDate: dateRanges.endDate.minDate,
    maxDate: dateRanges.endDate.maxDate,
    today,
    onSelectDate: addEnd,
    showTodayButton: false,
  };

  return (
    <div className={classes.contentContainer}>
      <div className={classes.calendarDropdownMargin}>
        <CalendarDropdown
          calloutCalendarProps={priceCeilingCalendarProps}
          dataAutomationId="priceCeilingCalendar"
          dropdownDisabled={disabled}
          dropdownOptions={priceCeilingOptions.options}
          id="PriceCeilingCalendar"
          label={t('quote::Price Ceiling')}
          selected={priceCeilingOptions.selectedKey}
          onDropdownSelect={selectCeiling}
        />
      </div>
      <div className={classes.calendarDropdownMargin}>
        <CalendarDropdown
          calloutCalendarProps={startFromCalendarProps}
          dataAutomationId="priceCeilingStartFromCalendar"
          dropdownDisabled={disabled}
          dropdownOptions={startOptions.options}
          label={t('quote::Start From')}
          required={true}
          selected={startOptions.selectedKey}
          onDropdownSelect={selectStart}
        />
      </div>
      <CalendarDropdown
        calloutCalendarProps={goodForCalendarProps}
        dataAutomationId="priceCeilingGoodForCalendar"
        dropdownDisabled={disabled}
        dropdownOptions={endOptions.options}
        label={t('quote::Good For')}
        required={true}
        selected={endOptions.selectedKey}
        onDropdownSelect={selectEnd}
      />
    </div>
  );
};

type FutureProps = FutureDateOptions & WithStyles<typeof discountCardFutureSectionStyles>;

const DiscountCardFutureSectionUnstyled: React.FunctionComponent<FutureProps> = (
  props: FutureProps
) => {
  const { t } = useTranslation();
  const {
    classes,
    disabled,
    startOptions,
    endOptions,
    useDayPicker,
    meterOptions,
    setMeter,
    showMeters,
    setDateState,
    dateRanges,
    meterErrorMessage,
  } = props;

  const addStart = (date: Date, selectedDateRange?: Date[]) => {
    const newDates = addStartDate(date, useDayPicker, startOptions, endOptions, dateRanges);
    setDateState &&
      setDateState({
        startOptions: newDates.newStartOptions,
        endOptions: newDates.newEndOptions,
        dateRanges: newDates.newRanges,
      });
  };

  const selectStart = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option && typeof option.key === 'string') {
      const newDates = startSelected(
        option.key,
        useDayPicker,
        startOptions,
        endOptions,
        dateRanges
      );
      setDateState &&
        setDateState({
          startOptions: newDates.newStartOptions,
          endOptions: newDates.newEndOptions,
          dateRanges: newDates.newRanges,
        });
    }
  };

  const selectEnd = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    if (option && typeof option.key === 'string') {
      const newDates = endSelected(option.key, useDayPicker, startOptions, endOptions, dateRanges);
      setDateState &&
        setDateState({
          startOptions: newDates.newStartOptions,
          endOptions: newDates.newEndOptions,
          dateRanges: newDates.newRanges,
        });
    }
  };

  const addEnd = (date: Date, selectedDateRange?: Date[]) => {
    const newDates = addEndDate(date, useDayPicker, startOptions, endOptions, dateRanges);
    setDateState &&
      setDateState({
        startOptions: newDates.newStartOptions,
        endOptions: newDates.newEndOptions,
        dateRanges: newDates.newRanges,
      });
  };

  const enableEndCalendar = isValidDate(startOptions.selectedKey);
  const today = new Date();

  const startingCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'starting-future-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: disabled,
    },
    calendarStrings,
    today,
    defaultDate: createDateFromKey(startOptions.selectedKey) || dateRanges.startDate.selected,
    minDate: dateRanges.startDate.minDate,
    maxDate: dateRanges.startDate.maxDate,
    showTodayButton: useDayPicker,
    onSelectDate: addStart,
    isDayPickerVisible: useDayPicker,
  };

  const goodForCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'good-for-future-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: disabled || !enableEndCalendar,
    },
    calendarStrings,
    today,
    defaultDate: createDateFromKey(endOptions.selectedKey) || dateRanges.endDate.selected,
    minDate: dateRanges.endDate.minDate,
    onSelectDate: addEnd,
    showTodayButton: false,
    isDayPickerVisible: useDayPicker,
  };

  let metersDropdown;
  if (showMeters && meterOptions && setMeter) {
    metersDropdown = (
      <ComboBox
        disabled={disabled}
        errorMessage={meterErrorMessage}
        id="meters"
        label={t('quote::Meters')}
        options={meterOptions.options}
        selectedKey={meterOptions.selectedKey}
        onChange={setMeter}
      />
    );
  }
  const calendarMergedClasses = mergeClassNames([
    classes.calendarDropdownMargin,
    classes.itallicComboboxInputStyle,
  ]);
  return (
    <div className={classes.bottomMargin}>
      <div
        className={
          startOptions.selectedKey === 'mixed'
            ? calendarMergedClasses
            : classes.calendarDropdownMargin
        }
      >
        <CalendarDropdown
          calloutCalendarProps={startingCalendarProps}
          dataAutomationId="discountFutureStartingCalendar"
          dropdownDisabled={disabled}
          dropdownOptions={startOptions.options}
          label={t('quote::Starting')}
          required={true}
          selected={startOptions.selectedKey}
          onDropdownSelect={selectStart}
        />
      </div>
      <div
        className={
          endOptions.selectedKey === 'mixed'
            ? calendarMergedClasses
            : classes.calendarDropdownMargin
        }
      >
        <CalendarDropdown
          calloutCalendarProps={goodForCalendarProps}
          dataAutomationId="discountFutureGoodForCalendar"
          dropdownDisabled={disabled}
          dropdownOptions={endOptions.options}
          label={t('quote::Good For')}
          required={true}
          selected={endOptions.selectedKey}
          onDropdownSelect={selectEnd}
        />
      </div>
      <div className={classes.metersDropdown}>{metersDropdown}</div>
    </div>
  );
};

export const DiscountCardFutureSection = withStyles(discountCardFutureSectionStyles)(
  DiscountCardFutureSectionUnstyled
);

export const DiscountCardCeilingSection = withStyles(discountCardCeilingStyles)(
  DiscountCardCeilingUnstyled
);
