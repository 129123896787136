import { ErrorMessage, Spinner, SpinnerSize } from 'components';
import { QuoteLineItem } from 'features-apollo/quote/components';
import {
  DetailsFlights,
  DiscountLineItemDetails,
  getDiscountLineItemProps,
  getEcifLineItemProps,
  getPurchaseLineItemProps,
  MultiSelectDetails,
  PurchaseLineItemDetails,
  TermLineItemDetails,
  TermOrMonetaryLineItem,
} from 'features-apollo/quote/components/DetailsPane';
import { getProduct } from 'features/catalog/selectors';
import { getCustomPrices } from 'features/customer/selectors';
import { getDiscounts } from 'features/proposal/components/DetailsPane/detailsUtils';
import { getMarket, getSkuTitles } from 'features/proposal/selectors';
import { defaultMarket, Market } from 'features/proposal/supported-markets';
import { SkuTitleMap } from 'features/proposal/types';
import { displayInDayFormatAndUseMarketTimezone } from 'features/proposal/utils';
import { DiscountLineItem, DiscountType, EcifLineItem, Quote } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import { CustomPrice } from 'services/pricingscope/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { isQuoteLegacy } from '../../PropertySheets/Customer/selectors';
import { getTermLineItemProps } from '../utils';

const getDiscountTemplateType = (lineItem: DiscountLineItem) =>
  lineItem.discount &&
  lineItem.discount.type &&
  lineItem.discount.type !== DiscountType.Unconfigured
    ? lineItem.__typename
    : 'UnconfiguredDiscount';
export interface LineItemDetailsProps {
  quote: Quote;
  selectedLineItems: QuoteLineItem[];
  flights: DetailsFlights;
}

interface PropsFromState {
  existingDiscounts: CustomPrice[];
  useDayFormat?: boolean;
  skuTitles?: SkuTitleMap;
  market?: Market;
  productOld?: Product;
}
const mapStateToProps = (state: RootState, ownProps: LineItemDetailsProps): PropsFromState => {
  const { quote, selectedLineItems } = ownProps;
  const skuTitles = getSkuTitles(state);
  if (!quote || (!selectedLineItems.length && selectedLineItems.length! > 1)) {
    return {
      existingDiscounts: [],
    };
  }
  const lineItem = selectedLineItems[0];
  const orgId = oc(quote).soldTo.organization.id();
  const productOld = getProduct(state, oc(lineItem).product.id(''));
  let action;
  if (lineItem && lineItem.__typename === 'DiscountLineItem' && lineItem.discount) {
    action = lineItem.discount.action !== null ? lineItem.discount.action : undefined;
  }
  const discounts =
    orgId && productOld
      ? getCustomPrices(state, orgId, productOld.ProductType, productOld.ProductId)
      : [];

  const useDayFormat =
    productOld && displayInDayFormatAndUseMarketTimezone(productOld.ProductType, action);

  return {
    useDayFormat,
    existingDiscounts: discounts || [],
    skuTitles,
    market: getMarket(state),
    productOld,
  };
};

type Props = LineItemDetailsProps & ReturnType<typeof mapStateToProps>;

export const LineItemDetailsUnconnected: React.FunctionComponent<Props> = (props: Props) => {
  const {
    selectedLineItems,
    flights,
    quote,
    existingDiscounts,
    useDayFormat,
    market,
    skuTitles,
    productOld,
  } = props;
  const { t } = useTranslation();

  let LineItemDetails = <Spinner size={SpinnerSize.large} />;

  if (selectedLineItems.length > 1) {
    LineItemDetails = <MultiSelectDetails quote={quote} selectedLineItems={selectedLineItems} />;
  } else {
    const lineItem = selectedLineItems.length && selectedLineItems[0];
    if (lineItem) {
      const displayExistingDiscounts =
        !isQuoteLegacy(quote) && productOld && skuTitles
          ? getDiscounts(
              t,
              skuTitles,
              market || defaultMarket,
              !!useDayFormat,
              productOld,
              existingDiscounts
            )
          : undefined;

      const templateType =
        lineItem.__typename === 'DiscountLineItem'
          ? getDiscountTemplateType(lineItem)
          : lineItem.__typename;

      switch (templateType) {
        case 'DiscountLineItem':
          LineItemDetails = (
            <DiscountLineItemDetails
              {...getDiscountLineItemProps(lineItem as DiscountLineItem)}
              discounts={displayExistingDiscounts}
              quote={quote}
            />
          );
          break;
        case 'FinancingTermLineItem':
        case 'SimpleTermLineItem':
        case 'MonetaryLineItem':
        case 'SapLineItem':
          LineItemDetails = (
            <TermLineItemDetails
              {...getTermLineItemProps(quote, lineItem as TermOrMonetaryLineItem)}
              quote={quote}
            />
          );
          break;
        case 'EcifLineItem':
          LineItemDetails = (
            <PurchaseLineItemDetails
              {...getEcifLineItemProps(lineItem as EcifLineItem, flights, quote)}
            />
          );
          break;
        default:
          LineItemDetails = (
            <PurchaseLineItemDetails
              {...getPurchaseLineItemProps(lineItem, flights)}
              discounts={displayExistingDiscounts}
              quote={quote}
            />
          );
          break;
      }
    } else {
      LineItemDetails = (
        <>
          <ErrorMessage mainMessage={t('quote::Unable to load data')} />
        </>
      );
    }
  }

  return <>{LineItemDetails}</>;
};

export const LineItemDetails = connect(mapStateToProps)(LineItemDetailsUnconnected);
