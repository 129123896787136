//TODO:kaderbez,cameneks translation
export const cardConstants = {
  All: 'All',
  accessPass: 'AzureAccessPass',
  atOrderPlacement: 'At order placement',
  apps: 'Apps',
  azure: 'Azure',
  Entitlement: 'Entitlement',
  azureFamilyDiscount: 'AzureFamilyDiscount',
  SAP: 'SAP',
  azureSupport: 'AzureSupport',
  ceiling: 'Ceiling',
  configure: 'configure',
  consume: 'consume',
  devices: 'Devices',
  discount: 'discount',
  fieldOverrideRule: 'FieldOverrideRule',
  future: 'Future',
  MeterType: 'MeterType',
  monetaryCommitment: 'AzureMonetaryCommit',
  monetaryCredit: 'AzureMonetaryCredit',
  onASpecificDate: 'On a specific date',
  oneTime: 'One Time',
  onetime: 'oneTime',
  oneTimeSummary: 'One-time',
  passes: 'Passes',
  perpetual: 'Perpetual',
  priceGuarantee: 'priceguarantee',
  product: 'product',
  productFamily: 'productFamily',
  purchase: 'purchase',
  sku: 'sku',
  software: 'Software',
};
