import { CalloutCard } from 'components/ions';
import { DurationUnit, getSingleDuration } from 'components/utilities/duration';
import { closeConfigCard, updateProposalAsync } from 'features/proposal/actions';
import {
  getActiveProposal,
  getBillingCurrency,
  getLineItem,
  isProposalReadOnly,
} from 'features/proposal/selectors';
import { Currency } from 'features/proposal/supported-currencies';
import { createProposalUpdateRequest, emailIsValid } from 'features/proposal/utils';
import i18next from 'i18next';
import moment from 'moment';
import { DirectionalHint } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import { LineItem, RequestProposalHeader } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';

import { startConditionConstants } from '../DetailsPane/detailsUtils';
import { SAPCardStyles } from './SAPCard.styles';
import { SAPCardProductFields } from './SAPCardProductFields';

export enum DialogState {
  FirstStep,
  SecondStep,
}

export interface SAPConfigCardProps {
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
  disableApplyButton: boolean;
  readOnly: boolean;
  title: string;
  currency: Currency;
  applyButtonStrings: { text: string; key: string };
  onDismiss: () => void;
  onApply: () => void;
  values: {
    amountValue: string;
    goodForValue: string;
    firstNameValue?: string;
    lastNameValue?: string;
    emailAddressValue?: string;
    SAPOpptyIdValue?: string;
    startConditionValue?: string;
  };
  inputActions: {
    onAmountChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onGoodForChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onEmailAddressChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onSAPOpptyIdChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onFirstNameChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
    onLastNameChange: (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => void;
  };
  inputErrorActions: {
    onAmountErrorChange: (value?: string) => void;
    onGoodForErrorChange: (value?: string) => void;
    onEmailAddressErrorChange: (value?: string) => void;
    onSAPOpptyIdErrorChange: (value?: string) => void;
  };
  errorMessages: {
    amountErrorMessage?: string;
    goodForErrorMessage?: string;
    sapOpptyIdErrorMessage?: string;
    emailAddressErrorMessage?: string;
  };
}

export interface SAPConfigCardContainerProps {
  hydratedProduct: Product;
  lineItemId: string;
  target: React.RefObject<HTMLSpanElement>;
}

const mapStateToProps = (state: RootState, ownProps: SAPConfigCardContainerProps) => {
  return {
    proposal: getActiveProposal(state),
    lineItem: getLineItem(state, ownProps.lineItemId),
    isProposalReadOnly: isProposalReadOnly(state),
    currency: getBillingCurrency(state),
  };
};

const dispatchProps = {
  closeCard: closeConfigCard,
  onUpdateProposal: updateProposalAsync.request,
};

type Props = SAPConfigCardProps & WithStyles<typeof SAPCardStyles>;

export interface SAPCardContainerState {
  amount: string;
  amountError?: string;
  goodFor: string;
  goodForError?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  emailAddressError?: string;
  SAPOpptyId?: string;
  SAPOpptyIdError?: string;
}

type ContainerProps = SAPConfigCardContainerProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const defaultAmount = '';
const defaultMinimumAmount = 1;
const defaultMaximumAmount = 999999999999999;
const defaultMinimumDuration = 1;
const defaultMaximumDuration = 250;
const maxOpptyCharLength = 64;

export const SAPCardContainerActions = {
  onAmountChange: createStandardAction('AMOUNT_CHANGE')<string>(),
  onAmountError: createStandardAction('AMOUNT_ERROR')<{
    min: number;
    max: number;
    value: string;
  }>(),
  onGoodForChange: createStandardAction('GOOD_FOR')<string>(),
  onGoodForError: createStandardAction('GOOD_FOR_ERROR')<{
    min: number;
    max: number;
    value: string;
  }>(),
  onEmailAddressChange: createStandardAction('EMAIL_ADDRESS')<string>(),
  onEmailAddressError: createStandardAction('EMAIL_ADDRESS_ERROR')<{
    value: string;
  }>(),
  onSAPOpptyIdChange: createStandardAction('SAP_OPPTYID')<string>(),
  onSAPOpptyIdError: createStandardAction('SAP_OPPTYID_ERROR')<{
    value: string;
  }>(),
  onFirstNameChange: createStandardAction('FIRST_NAME')<string>(),
  onLastNameChange: createStandardAction('LAST_NAME')<string>(),
  onApply: createStandardAction('APPLY')<string>(),
};

const errors = {
  numberOnly: i18next.t('error::Only numbers are valid.'),
  numberGreaterThanOne: i18next.t('error::Value has to be greater than 1.'),
  numberGreaterThanMax: (maxAmount: number) =>
    i18next.t('error::Value cannot be more than {{maxAmount}}.', { maxAmount }),
  numberLessThanMin: (minAmount: number) =>
    i18next.t('error::Value cannot be less than {{minAmount}}.', { minAmount }),
  invalidMonthValue: i18next.t('error::Invalid month value.'),
  lessMonth: (min: number) =>
    i18next.t('error::Value cannot be less than {{min}} months.', { min }),
  moreMonth: (max: number) =>
    i18next.t('error::Value cannot be more than {{max}} months.', { max }),
  invalidEmail: i18next.t('error::Enter a valid email address'),
  invalidSAPOpptyId: (limit: number) =>
    i18next.t('error::ID number exceeds {{limit}} character limit', { limit }),
};

export const getAmountError = (min: number, max: number, value?: string) => {
  if (!value) {
    return errors.numberOnly;
  }
  if (+value < min) {
    return errors.numberLessThanMin(min);
  }
  if (+value > max) {
    return errors.numberGreaterThanMax(max);
  }
};

export const getGoodForError = (min: number, max: number, value?: string) => {
  if (!value) {
    return errors.invalidMonthValue;
  }
  if (+value < min) {
    return errors.lessMonth(min);
  }
  if (+value > max) {
    return errors.moreMonth(max);
  }
};

export const getEmailAddressError = (value?: string) => {
  if (!value || (value && !emailIsValid.test(value))) {
    return errors.invalidEmail;
  }
};

export const getSAPOpptyIdError = (value?: string) => {
  if (value && value.length > maxOpptyCharLength) {
    return errors.invalidSAPOpptyId(maxOpptyCharLength);
  }
};

export const SAPCardReducer = (
  state: SAPCardContainerState,
  action: ActionType<typeof SAPCardContainerActions>
): SAPCardContainerState => {
  switch (action.type) {
    case getType(SAPCardContainerActions.onAmountChange): {
      return { ...state, amount: action.payload };
    }
    case getType(SAPCardContainerActions.onGoodForChange): {
      return { ...state, goodFor: action.payload };
    }
    case getType(SAPCardContainerActions.onEmailAddressChange): {
      return { ...state, emailAddress: action.payload };
    }
    case getType(SAPCardContainerActions.onSAPOpptyIdChange): {
      return { ...state, SAPOpptyId: action.payload };
    }
    case getType(SAPCardContainerActions.onFirstNameChange): {
      return { ...state, firstName: action.payload };
    }
    case getType(SAPCardContainerActions.onLastNameChange): {
      return { ...state, lastName: action.payload };
    }
    case getType(SAPCardContainerActions.onAmountError): {
      const error = getAmountError(action.payload.min, action.payload.max, action.payload.value);
      return { ...state, amountError: error };
    }
    case getType(SAPCardContainerActions.onGoodForError): {
      const error = getGoodForError(action.payload.min, action.payload.max, action.payload.value);
      return { ...state, goodForError: error };
    }
    case getType(SAPCardContainerActions.onEmailAddressError): {
      const error = getEmailAddressError(action.payload.value);
      return { ...state, emailAddressError: error };
    }
    case getType(SAPCardContainerActions.onSAPOpptyIdError): {
      const error = getSAPOpptyIdError(action.payload.value);
      return { ...state, SAPOpptyIdError: error };
    }
    default:
      return state;
  }
};

const generateInitialState = (initials: {
  initialAmount?: string;
  initialGoodFor?: string;
  initialFirstName?: string;
  initialLastName?: string;
  initialSAPOpptyId?: string;
  initialEmailAddress?: string;
}): SAPCardContainerState => {
  const goodFor = initials.initialGoodFor;
  const goodForMonths = (goodFor && moment.duration(goodFor).asMonths()) || '';
  return {
    amount: initials.initialAmount || defaultAmount,
    goodFor: goodForMonths.toString(),
    firstName: initials.initialFirstName,
    lastName: initials.initialLastName,
    emailAddress: initials.initialEmailAddress,
    SAPOpptyId: initials.initialSAPOpptyId,
  };
};

const SAPConfigCardUnconnected: React.FC<ContainerProps> = (props: ContainerProps) => {
  const {
    proposal,
    hydratedProduct,
    lineItem,
    currency,
    isProposalReadOnly,
    onUpdateProposal,
  } = props;
  const { t } = useTranslation();

  const minimumDuration = defaultMinimumDuration; //TODO kaderbez: change to property in PCD when available
  const maximumDuration = defaultMaximumDuration; //TODO kaderbez: change to property in PCD when available
  const minimumAmount = oc(hydratedProduct).Properties.Amount.Min(defaultMinimumAmount);
  //TODO: cameneks remove this once the downstream service accepts higher numbers and not overflows
  const hardUpperBoundMax = 2147483647;

  const maximumAmount = Math.min(
    oc(hydratedProduct).Properties.Amount.Max(defaultMaximumAmount),
    hardUpperBoundMax
  );
  const initialPurchaseTargetTermUnits = oc(lineItem).purchaseInstruction.purchaseTermUnits();

  const [state, dispatch] = React.useReducer(
    SAPCardReducer,
    {
      initialAmount: initialPurchaseTargetTermUnits,
      initialGoodFor: oc(lineItem).duration(minimumDuration.toString()),
      initialFirstName: oc(proposal).header.sapReferenceData.contact.firstName(),
      initialLastName: oc(proposal).header.sapReferenceData.contact.lastName(),
      initialEmailAddress: oc(proposal).header.sapReferenceData.contact.emailAddress(),
      initialSAPOpptyId: oc(proposal).header.sapReferenceData.opportunityId(),
    },
    generateInitialState
  );
  const applyButtonStrings = { text: t('quote::Apply'), key: 'Apply' };

  const startCondition = oc(hydratedProduct).Properties.StartCondition();

  const onAmountChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(SAPCardContainerActions.onAmountChange(value));
    }
  };

  const onGoodForChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(SAPCardContainerActions.onGoodForChange(value));
    }
  };

  const onEmailAddressChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(SAPCardContainerActions.onEmailAddressChange(value));
    }
  };

  const onSAPOpptyIdChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(SAPCardContainerActions.onSAPOpptyIdChange(value));
    }
  };

  const onFirstNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(SAPCardContainerActions.onFirstNameChange(value));
    }
  };

  const onLastNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value !== undefined) {
      dispatch(SAPCardContainerActions.onLastNameChange(value));
    }
  };

  const onAmountErrorChange = (value?: string) => {
    if (value !== undefined) {
      dispatch(
        SAPCardContainerActions.onAmountError({
          min: minimumAmount,
          max: maximumAmount,
          value,
        })
      );
    }
  };

  const onGoodForErrorChange = (value?: string) => {
    if (value !== undefined) {
      dispatch(
        SAPCardContainerActions.onGoodForError({
          min: minimumDuration,
          max: maximumDuration,
          value,
        })
      );
    }
  };

  const onEmailAddressErrorChange = (value?: string) => {
    if (value !== undefined) {
      dispatch(
        SAPCardContainerActions.onEmailAddressError({
          value,
        })
      );
    }
  };

  const onSAPOpptyIdErrorChange = (value?: string) => {
    if (value !== undefined) {
      dispatch(
        SAPCardContainerActions.onSAPOpptyIdError({
          value,
        })
      );
    }
  };

  const onApply = () => {
    if (!state.goodFor || isNaN(+state.goodFor) || state.goodFor.trim() === '') {
      throw new Error(`goodFor is not a number, it is , ${state.goodFor}`);
    }
    const displaySkuAvailabilities = hydratedProduct.DisplaySkuAvailabilities;
    if (!displaySkuAvailabilities.length || !displaySkuAvailabilities[0].Availabilities.length) {
      throw new Error('display sku availabilities are empty');
    }
    if (!props.lineItem || !state.firstName || !state.lastName || !state.emailAddress) {
      throw new Error('Properties for config card apply missing');
    }

    props.closeCard();

    const newHeader: RequestProposalHeader = {
      ...props.proposal.header,
      sapReferenceData: {
        opportunityId: state.SAPOpptyId,
        contact: {
          firstName: state.firstName,
          lastName: state.lastName,
          emailAddress: state.emailAddress,
        },
      },
    };

    const firstSkuAvailability = displaySkuAvailabilities[0];
    const singleDuration = getSingleDuration(parseInt(state.goodFor), DurationUnit.months);
    const firstAvailability = firstSkuAvailability.Availabilities[0];

    const newLineItem: LineItem = {
      ...props.lineItem,
      purchaseInstruction: {
        ...props.lineItem.purchaseInstruction,
        purchaseTermUnits: state.amount,
      },
      productIdentifier: {
        ...props.lineItem.productIdentifier,
        skuId: firstSkuAvailability.Sku.SkuId,
        availabilityId: firstAvailability.AvailabilityId,
        availabilityTermId: firstAvailability.Terms && firstAvailability.Terms[0].TermId,
        action: 'purchase',
      },
      isReadyForPricing: true,
      duration: singleDuration,
      isStartOnFirstOfMonth: !!(
        startCondition && startCondition.toLowerCase() === startConditionConstants.firstOfThisMonth
      ),
    };

    const proposalUpdateRequest = createProposalUpdateRequest(newLineItem, proposal, newHeader);
    onUpdateProposal(proposalUpdateRequest);
  };

  const goodForNotValid = !!getGoodForError(+minimumDuration, +maximumDuration, state.goodFor);
  const amountNotValid = !!getAmountError(
    +minimumAmount,
    maximumAmount && +maximumAmount,
    state.amount
  );
  const emailNotValid = !!getEmailAddressError(state.emailAddress);
  const SAPOpptyIdNotValid = !!state.SAPOpptyIdError;
  const firstNameNotValid = !state.firstName || !(state.firstName && state.firstName.trim());
  const lastNameNotValid = !state.lastName || !(state.lastName && state.lastName.trim());
  const disableNextButton = amountNotValid || goodForNotValid;
  const disableApplyButton =
    disableNextButton ||
    emailNotValid ||
    SAPOpptyIdNotValid ||
    firstNameNotValid ||
    lastNameNotValid;

  const title = t('quote::Configure {{productTitle}}', {
    productTitle: oc(hydratedProduct).LocalizedProperties[0].ProductTitle(),
  });

  const SAPCardProps: SAPConfigCardProps = {
    lineItemId: props.lineItemId,
    target: props.target,
    disableApplyButton,
    readOnly: !!isProposalReadOnly,
    title,
    currency,
    applyButtonStrings,
    onDismiss: props.closeCard,
    onApply,
    values: {
      amountValue: state.amount,
      goodForValue: state.goodFor,
      firstNameValue: state.firstName,
      lastNameValue: state.lastName,
      emailAddressValue: state.emailAddress,
      SAPOpptyIdValue: state.SAPOpptyId,
      startConditionValue: startCondition,
    },
    inputActions: {
      onAmountChange,
      onGoodForChange,
      onEmailAddressChange,
      onSAPOpptyIdChange,
      onFirstNameChange,
      onLastNameChange,
    },
    inputErrorActions: {
      onAmountErrorChange,
      onGoodForErrorChange,
      onEmailAddressErrorChange,
      onSAPOpptyIdErrorChange,
    },
    errorMessages: {
      amountErrorMessage: state.amountError,
      goodForErrorMessage: state.goodForError,
      sapOpptyIdErrorMessage: state.SAPOpptyIdError,
      emailAddressErrorMessage: state.emailAddressError,
    },
  };

  return <SAPConfigCard {...SAPCardProps} />;
};

export const SAPCardUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <CalloutCard
      applyButtonDisabled={props.disableApplyButton}
      applyButtonStrings={props.applyButtonStrings}
      closeButtonAriaLabel={t('quote::Close Configuration Card')}
      dataAutomationId="SAPConfigCard"
      directionalHint={DirectionalHint.rightCenter}
      headerText={props.title}
      id={props.lineItemId}
      isBeakVisible
      isReadOnly={props.readOnly}
      maxWidth={305}
      target={props.target}
      onApply={props.onApply}
      onDismiss={props.onDismiss}
    >
      <SAPCardProductFields
        currency={props.currency}
        errorMessages={props.errorMessages}
        inputActions={props.inputActions}
        inputErrorActions={props.inputErrorActions}
        readOnly={props.readOnly}
        values={props.values}
        onApply={props.onApply}
        onDismiss={props.onDismiss}
      />
    </CalloutCard>
  );
};

export const SAPConfigCard = withStyles(SAPCardStyles)(SAPCardUnstyled);

export const ConnectedSAPConfigCard = connect(
  mapStateToProps,
  dispatchProps
)(SAPConfigCardUnconnected);
