/* eslint-disable @typescript-eslint/camelcase */
import { GqlLanguage } from 'generated/graphql';

export const defaultLanguage = GqlLanguage.EnUs;

export type Locale =
  | 'af-ZA'
  | 'ar-AE'
  | 'ar-EG'
  | 'ar-IL'
  | 'ar-SA'
  | 'bg-BG'
  | 'ca-ES'
  | 'cs-CZ'
  | 'da-DK'
  | 'de-CH'
  | 'de-DE'
  | 'de-LU'
  | 'el-GR'
  | 'en-AU'
  | 'en-CA'
  | 'en-GB'
  | 'en-IE'
  | 'en-US'
  | 'en-ZA'
  | 'es-CR'
  | 'es-ES'
  | 'et-EE'
  | 'eu-ES'
  | 'fi-FI'
  | 'fil-PH'
  | 'fr-BE'
  | 'fr-CA'
  | 'fr-CH'
  | 'fr-FR'
  | 'gl-ES'
  | 'he-IL'
  | 'hr-HR'
  | 'hu-HU'
  | 'id-ID'
  | 'it-CH'
  | 'it-IT'
  | 'ja-JP'
  | 'ko-KR'
  | 'lv-LV'
  | 'ms-MY'
  | 'nb-NO'
  | 'nl-BE'
  | 'nl-NL'
  | 'nn-NO'
  | 'pl-PL'
  | 'pt-BR'
  | 'pt-PT'
  | 'ro-RO'
  | 'ru-RU'
  | 'sk-SK'
  | 'sl-SI'
  | 'sr-cyrl-RS'
  | 'sr-latn-RS'
  | 'sv-SE'
  | 'th-TH'
  | 'tr-TR'
  | 'uk-UA'
  | 'vi-VN'
  | 'zh-CN'
  | 'zh-Hant'
  | 'zh-HK'
  | 'zh-TW';

/**
 * Gets the locale format equivalent to the Gql Languages options
 */
export const LanguageLocale: Record<GqlLanguage, Locale> = {
  af_ZA: 'af-ZA',
  ar_AE: 'ar-AE',
  ar_EG: 'ar-EG',
  ar_IL: 'ar-IL',
  ar_SA: 'ar-SA',
  bg_BG: 'bg-BG',
  ca_ES: 'ca-ES',
  cs_CZ: 'cs-CZ',
  da_DK: 'da-DK',
  de_CH: 'de-CH',
  de_DE: 'de-DE',
  de_LU: 'de-LU',
  el_GR: 'el-GR',
  en_AU: 'en-AU',
  en_CA: 'en-CA',
  en_GB: 'en-GB',
  en_IE: 'en-IE',
  en_US: 'en-US',
  en_ZA: 'en-ZA',
  es_CR: 'es-CR',
  es_ES: 'es-ES',
  et_EE: 'et-EE',
  eu_ES: 'eu-ES',
  fi_FI: 'fi-FI',
  fil_PH: 'fil-PH',
  fr_BE: 'fr-BE',
  fr_CA: 'fr-CA',
  fr_CH: 'fr-CH',
  fr_FR: 'fr-FR',
  gl_ES: 'gl-ES',
  he_IL: 'he-IL',
  hr_HR: 'hr-HR',
  hu_HU: 'hu-HU',
  id_ID: 'id-ID',
  it_CH: 'it-CH',
  it_IT: 'it-IT',
  ja_JP: 'ja-JP',
  ko_KR: 'ko-KR',
  lv_LV: 'lv-LV',
  ms_MY: 'ms-MY',
  nb_NO: 'nb-NO',
  nl_BE: 'nl-BE',
  nl_NL: 'nl-NL',
  nn_NO: 'nn-NO',
  pl_PL: 'pl-PL',
  pt_BR: 'pt-BR',
  pt_PT: 'pt-PT',
  ro_RO: 'ro-RO',
  ru_RU: 'ru-RU',
  sk_SK: 'sk-SK',
  sl_SI: 'sl-SI',
  sr_cyrl_RS: 'sr-cyrl-RS',
  sr_latn_RS: 'sr-latn-RS',
  sv_SE: 'sv-SE',
  th_TH: 'th-TH',
  tr_TR: 'tr-TR',
  uk_UA: 'uk-UA',
  vi_VN: 'vi-VN',
  zh_CN: 'zh-CN',
  zh_Hant: 'zh-Hant',
  zh_HK: 'zh-HK',
  zh_TW: 'zh-TW',
};
