import { ThemeProps } from 'styles';

import { buttonSharedStyles } from '../shared.styles';

export const meControlButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  const rootStyle = {
    border: 'none',
    borderRadius: 0,
    height: 48,
    paddingLeft: 8,
    paddingRight: 8,
  };

  return {
    ...buttonSharedStyles(theme),
    calloutMenu: {
      backgroundColor: colors.backgroundStandout,
      borderRadius: '0 0 2px 2px',
    },
    contextualCalloutRoot: {
      ...buttonSharedStyles(theme).calloutRoot,
      right: '0px !important',
    },
    initialsColor: {
      backgroundColor: colors.border,
    },
    initialsColorPrimary: {
      backgroundColor: colors.meControlPersonaBackground,
    },
    menuIcon: {
      display: 'none',
    },
    menuItem: {
      paddingLeft: 12,
      '&:active, &:active:focus': {
        backgroundColor: colors.primary,
        color: 'white',
        '& i': {
          color: 'white',
        },
      },
      '&:focus': {
        backgroundColor: colors.backgroundCommon,
      },
    },
    root: {
      ...rootStyle,
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    rootExpanded: {
      backgroundColor: colors.backgroundStandout,
      boxShadow: `${colors.shadowColor} 0px 0px 7px -2px`,
      color: colors.textSecondary,
      height: 48,
      '& i': {
        color: colors.textSecondary,
      },
      '&:hover': {
        backgroundColor: colors.backgroundStandout,
      },
      '&:focus': {
        backgroundColor: colors.backgroundStandout,
      },
    },
    rootExpandedPrimary: {
      backgroundColor: colors.backgroundStandout,
      color: colors.textSecondary,
      boxShadow: `${colors.shadowColor} 0px 0px 7px -2px`,
      '& i': {
        color: colors.textSecondary,
      },
      '&:hover': {
        backgroundColor: colors.backgroundStandout,
        color: colors.textSecondary,
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:focus': {
        backgroundColor: colors.backgroundStandout,
        color: colors.textSecondary,
        '& i': {
          color: colors.textSecondary,
        },
      },
    },
    rootPrimary: {
      ...rootStyle,
      '&:active, &:active:hover': {
        backgroundColor: colors.primaryActive,
      },
      '&:focus, &:hover': {
        backgroundColor: colors.primaryHover,
      },
    },
    rootDemo: {
      ...rootStyle,
      '&:active, &:active:hover': {
        backgroundColor: colors.demoColor,
      },
      '&:focus, &:hover': {
        backgroundColor: colors.darkColor,
      },
    },
  };
};
