import {
  Checkbox,
  MediumIcon,
  SecondaryButton,
  TextBody,
  TextBodySmall,
  TextSubsectionHeading,
} from 'components';
import { BodyProps } from 'features/proposal/components/Wizards/shared';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { affiliateDialogStyles } from './AffiliateDialog.styles';

export const AffiliateHeaderString = () => i18next.t('quote::Affiliate account');

export interface AffiliateBodyProps {
  showInfoMessage: boolean;
}

type Props = AffiliateBodyProps & BodyProps<boolean> & WithStyles<typeof affiliateDialogStyles>;

export const AffiliateBodyUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const [isAffiliate, setIsAffiliate] = React.useState<boolean>(props.initialData || false);
  const { t } = useTranslation();

  const handleCheckboxToggle = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setIsAffiliate(!!checked);
    checked ? props.onInvalid() : props.onValid(false);
  };

  const removeDiscountsMessage = () => {
    return (
      <div data-automation-id="affiliateDialogRemoveDiscountsMessage">
        <div className={classes.infoHeader}>
          <MediumIcon addClass={classes.infoIcon} iconName="Info" />
          <TextBody addClass={classes.infoHeaderText}>{t('quote::Please note')}</TextBody>
        </div>
        <div className={classes.infoBody}>
          <TextBodySmall addClass={classes.infoText}>
            {t(
              'quote::The shared discount(s) which you previously added to the quote are not applicable for non-affiliate accounts, and will be removed from the quote when you save your changes.'
            )}
          </TextBodySmall>
        </div>
      </div>
    );
  };

  return (
    <div>
      <TextBody>
        {t('quote::Is this billing account an affiliate of an existing billing account?')}
      </TextBody>
      <div className={classes.checkbox}>
        <Checkbox
          ariaLabel={t('quote::Yes, this is an affiliate account.')}
          checked={isAffiliate}
          dataAutomationId="affiliateAccountCheckbox"
          id="affiliateAccountCheckbox"
          label={t('quote::Yes, this is an affiliate account.')}
          onChange={handleCheckboxToggle}
        />
      </div>
      {props.showInfoMessage && removeDiscountsMessage()}
      <div className={classes.definition}>
        <TextSubsectionHeading addClass={classes.definitionHeader}>
          {t('quote::Affiliate definition')}
        </TextSubsectionHeading>
        <div>
          <TextBodySmall addClass={classes.definitionText}>
            {t(
              'quote::An affiliate account is associated with a parent account which has previously signed the Microsoft Customer Agreement (MCA). The affiliate will sign a slimmed down version of the MCA called the Customer Affiliate Purchase Terms (CAPT).'
            )}
          </TextBodySmall>
          <div className={classes.definitionSpacing}>
            <TextBodySmall addClass={classes.definitionText}>
              {t(
                "quote::Additionally, they'll be able to take advantage of discounts negotiated by the parent account while maintaining control over their own purchases and invoicing."
              )}
            </TextBodySmall>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AffiliateBody = withStyles(affiliateDialogStyles)(AffiliateBodyUnstyled);

export const AffiliateFooterButtons = (
  isValid: boolean,
  nextEnabled: boolean,
  onOverviewButtonClick: () => void,
  onNextButtonClick: () => void
) => {
  return [
    <SecondaryButton
      dataAutomationId="affiliateDialogNext"
      disabled={!nextEnabled}
      key="Next"
      text={i18next.t('quote::Next')}
      onClick={onNextButtonClick}
    />,
    <SecondaryButton
      dataAutomationId="affiliateDialogBackToOverview"
      disabled={!isValid}
      key="overview"
      text={i18next.t('quote::Back to overview')}
      onClick={onOverviewButtonClick}
    />,
  ];
};
