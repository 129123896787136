/* eslint-disable @typescript-eslint/camelcase */
import { SectionSeparator, Spinner } from 'components';
import { getCustomerOrParticipantsNames } from 'features-apollo/quote/selectors/organization';
import { getMSContactFullName } from 'features-apollo/quote/selectors/quote';
import { getQuoteDisplayStatus } from 'features-apollo/utils';
import { ModernAgreementDocument, Quote } from 'generated/graphql';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { getCommentsDetails, getRequiredApprovalsPerPolicy } from '../../Approval';
import { summaryDetailsPaneStyles } from './SummaryDetailsPane.styles';
import { SummaryDetailsPaneApproval } from './SummaryDetailsPaneApproval';
import { SummaryDetailsPaneGeneral } from './SummaryDetailsPaneGeneral';
import { SummaryDetailsPaneHeader } from './SummaryDetailsPaneHeader';
import { SummaryDetailsPaneParticipants } from './SummaryDetailsPaneParticipants';
import {
  getAgreementDetails,
  getAnnualDealEstimate,
  getChannelAndSegmentText,
  getCreditInformation,
  getCRMInformation,
  getCustomerInformation,
  getEnrollmentNumberDetail,
  getExpirationDetails,
  getPartnerInformation,
  getQuoteLabel,
} from './utils';

export interface SummaryDetailsPaneProps {
  loading: boolean;
  quote?: Quote;
  modernAgreementDocument?: ModernAgreementDocument | null;
}

type Props = SummaryDetailsPaneProps & WithStyles<typeof summaryDetailsPaneStyles>;

const SummaryDetailsPaneUnconnected: React.FC<Props> = props => {
  const { classes, quote } = props;
  let content;

  if (props.loading) {
    content = <Spinner className={classes.spinner} />;
  } else if (quote) {
    const divider = <SectionSeparator addClass={classes.sectionSeparator} />;

    // Data
    const customerOrparticipantsNames = getCustomerOrParticipantsNames(quote);
    const annualDealEstimateAmount = getAnnualDealEstimate(quote);
    const quoteLabel = getQuoteLabel(quote);

    const customerInformation = getCustomerInformation(quote);
    const partnerInformation = getPartnerInformation(quote);

    const crmInformation = getCRMInformation(quote);
    const enrollmentNumber = getEnrollmentNumberDetail(quote);
    const creditInformation = getCreditInformation(quote);

    const requiredApprovals = getRequiredApprovalsPerPolicy(quote);
    const commentsDetails = getCommentsDetails(quote);

    const expirationDetails = getExpirationDetails(quote);
    const channelAndSegment = getChannelAndSegmentText(quote);
    const agreementDetails = getAgreementDetails(quote, props.modernAgreementDocument);
    const msContactFullName = getMSContactFullName(quote);
    const quoteStatus = getQuoteDisplayStatus(quote);

    content = (
      <>
        <SummaryDetailsPaneHeader
          annualDealEstimate={
            typeof annualDealEstimateAmount === 'number'
              ? {
                  amount: annualDealEstimateAmount,
                  currency: quote.billingCurrency,
                }
              : undefined
          }
          customerOrparticipantsNames={customerOrparticipantsNames}
          quoteLabel={quoteLabel}
          quoteName={quote.name}
          styles={{ container: classes.headerContainer }}
        />
        <div className={classes.contentContainer}>
          <SummaryDetailsPaneParticipants
            creditInformation={creditInformation}
            crmInformation={crmInformation}
            customerInformation={customerInformation}
            enrollmentNumber={enrollmentNumber}
            partnerInformation={partnerInformation}
          />
          {requiredApprovals && (
            <>
              {divider}
              <SummaryDetailsPaneApproval
                commentsDetails={commentsDetails}
                requiredApprovals={requiredApprovals}
              />
            </>
          )}
          {divider}
          <SummaryDetailsPaneGeneral
            agreementDetails={agreementDetails}
            channelAndSegment={channelAndSegment}
            expirationDetails={expirationDetails}
            msContactFullName={msContactFullName}
            quoteStatus={quoteStatus}
          />
        </div>
      </>
    );
  }

  return <aside className={classes.container}>{content}</aside>;
};

export const SummaryDetailsPane = withStyles(summaryDetailsPaneStyles)(
  SummaryDetailsPaneUnconnected
);
