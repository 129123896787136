import { BarButton } from 'components/ions';
import { useDeleteLineItems } from 'features-apollo/quote/components/Lists/hooks/useDeleteLineItems';
import { getSelectedIds } from 'features/proposal/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';

interface DeleteLineItemsButtonProps {
  /**
   * Identifies the quote where the line items can be found.
   *
   * @type {string}
   * @memberof DeleteLineItemsButtonProps
   */
  quoteId: string;
  /**
   * Prevents deleting line item when quote cannot be edited.
   *
   * @type {boolean}
   * @memberof DeleteLineItemsButtonProps
   */
  isQuoteReadOnly: boolean;
  /**
   * Function to run with button is clicked
   *
   * @memberof DeleteLineItemsButtonProps
   */
  onClick?: (ids: string[]) => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedIds: getSelectedIds(state),
  };
};

export type Props = DeleteLineItemsButtonProps & ReturnType<typeof mapStateToProps>;

export const DeleteLineItemsButtonDisconnected: React.FC<Props> = props => {
  const { t } = useTranslation();
  const deleteLineItems = useDeleteLineItems(props.quoteId, props.selectedIds);

  return (
    <BarButton
      dataAutomationId="delete-line-items-button"
      disabled={props.isQuoteReadOnly || !props.selectedIds.length}
      iconName="DeleteRows"
      text={t('Delete')}
      onClick={() => {
        props.onClick && props.onClick(props.selectedIds.filter(id => !id.includes('agreement')));
        deleteLineItems();
      }}
    />
  );
};

export const DeleteLineItemsButton = connect(mapStateToProps)(DeleteLineItemsButtonDisconnected);
