import { DetailsListAtom, DetailsListAtomProps } from 'components/atoms/DetailsList';
import { useResizeAfterFirstRender } from 'hooks';
import { DetailsListLayoutMode } from 'office-ui-fabric-react';
import * as React from 'react';

/**
 * Props for DetailsList Component
 *
 * @prop {string} [id] - id.
 * @prop {maxHeight} [maxHeight] - maximum height of the List, after that it is going to scroll.
 */
export interface DetailsListProps extends DetailsListAtomProps {
  addClass?: string;
  isHeaderVisible?: boolean;
  shouldResizeAfterFirstRender?: boolean;
  fetchMore?: () => React.ReactNode;
}

export const DetailsList: React.FC<DetailsListProps> = (props: DetailsListProps) => {
  useResizeAfterFirstRender(props.shouldResizeAfterFirstRender);

  return (
    <DetailsListAtom
      ariaLabelForSelectionColumn={props.ariaLabelForSelectionColumn}
      checkboxVisibility={props.checkboxVisibility}
      columns={props.columns}
      compact={props.compact}
      disableSelectionZone={props.disableSelectionZone}
      isHeaderVisible={props.isHeaderVisible}
      items={props.items}
      layoutMode={DetailsListLayoutMode.justified}
      mainClass={props.addClass}
      selection={props.selection}
      selectionMode={props.selectionMode}
      onRenderDetailsFooter={props.onRenderDetailsFooter}
      onRenderItemColumn={props.onRenderItemColumn}
      onRenderMissingItem={props.fetchMore}
      onRenderRow={props.onRenderRow}
    />
  );
};
