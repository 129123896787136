import loggerService from 'services/logger-service';

export enum DurationUnit {
  years,
  months,
  weeks,
  days,
  hours,
  minutes,
  seconds,
}
//For pseudo-ISO 8601, doesn't allow multiple different values.
export function getSingleDuration(duration: number, unit: DurationUnit): string {
  let prefix = 'P';
  let suffix = '';
  switch (unit) {
    case DurationUnit.years:
      suffix = 'Y';
      break;
    case DurationUnit.months:
      suffix = 'M';
      break;
    case DurationUnit.weeks:
      suffix = 'W';
      break;
    case DurationUnit.days:
      suffix = 'D';
      break;
    case DurationUnit.hours:
      prefix = 'PT';
      suffix = 'H';
      break;
    case DurationUnit.minutes:
      prefix = 'PT';
      suffix = 'M';
      break;
    case DurationUnit.seconds:
      prefix = 'PT';
      suffix = 'S';
      break;
    default:
      loggerService.error({ error: new Error('DurationUnit is not valid') });
  }
  return `${prefix}${duration}${suffix}`;
}
