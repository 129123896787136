import { TextSectionHeading } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import {
  ApprovalCommentsDetails,
  ApprovalDetails,
  RequiredApprovalsPerPolicy,
} from '../../Approval';

interface SummaryDetailsPaneApprovalProps {
  /**
   * List of props used for the RequiredApproval component
   */
  requiredApprovals: RequiredApprovalsPerPolicy[];
  /**
   * Displays after required approval details
   */
  commentsDetails?: ApprovalCommentsDetails;
}

const styles = (theme: ThemeProps) => ({
  commentsContainer: { width: 350 },
  title: { fontSize: theme.fonts.fontSizes.large, fontWeight: theme.fonts.fontWeights.semiBold },
});

type Props = SummaryDetailsPaneApprovalProps & WithStyles<typeof styles>;

const SummaryDetailsPaneApprovalUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <section>
      <TextSectionHeading addClass={classes.title}>
        {t('quote::Approval details')}
      </TextSectionHeading>
      <ApprovalDetails
        commentsDetails={props.commentsDetails}
        requiredApprovals={props.requiredApprovals}
        styles={{ commentContainer: classes.commentsContainer }}
      />
    </section>
  );
};

export const SummaryDetailsPaneApproval = withStyles(styles)(SummaryDetailsPaneApprovalUnstyled);
