import { ILinkStyles as IFabricLinkStyles, Link as LinkBase } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { skipToMainStyles } from './SkipToMainAtom.styles';

type ActionType = HTMLAnchorElement | HTMLElement | HTMLButtonElement;

export interface SkipToMainProps {
  ariaLabel?: string;
  displayText?: string;
  href?: string;
  id?: string;
  mainClass?: string;
  size?: string;
  onClick?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
}

type Props = SkipToMainProps & WithStyles<typeof skipToMainStyles>;

const SkipToMainUnstyled: React.FC<Props> = (props: Props) => {
  const skipToMainStyles: IFabricLinkStyles = {
    root: {
      fontSize: props.size,
    },
  };
  return (
    <LinkBase
      aria-label={props.ariaLabel}
      className={`${props.classes.link} ${props.mainClass}`}
      href={props.href}
      id={props.id}
      styles={skipToMainStyles}
      onClick={props.onClick}
    >
        <div>
          {props.displayText}
        </div>
    </LinkBase>
  );
};

export const SkipToMainAtom = withStyles(skipToMainStyles)(SkipToMainUnstyled) as React.FC<SkipToMainProps>;
