import { Language } from 'features/proposal/supported-languages';
import { Market } from 'features/proposal/supported-markets';
import {
  Image,
  Product,
  ProductAttributeValue,
  ProductFamily,
  ServiceFamily,
  SkuLocalizedProperty,
} from 'services/catalog/types';
import { ProductIdentifier } from 'services/proposal/types';

export enum ProductType {
  Product = 'Product', //t('quote::Product')
  Term = 'Term', //t('quote::Term')
  ECIF = 'ECIF',
}

export interface RecoChannelProducts {
  name: string;
  title: string;
  products: Product[];
}

export interface SearchResultProduct {
  productId: string;
  productIdentifier: ProductIdentifier;
  productName: string;
  maxQuantityOnQuote?: number;
}

export interface ProductSearch {
  nextSkip: number;
  products: SearchResultProduct[];
  productFamiliesWithMoreResults: ProductFamily[];
}

export interface ProductFamilySearchRequest {
  productFamilyNames: ProductFamily[];
  query: string;
  serviceFamily?: ServiceFamily;
}

export interface CatalogPageRequest {
  productFamilyName: ProductFamily;
  query?: string;
  serviceFamily: ServiceFamily;
  resultsToSkip?: number;
  market?: Market;
  language?: Language;
}

export interface CatalogPageResponse {
  products: Record<string, CatalogPageProduct>;
  serviceFamily: ServiceFamily;
  hasMorePages: boolean;
  resultsToSkip: number;
  catalogKey: string;
  query?: string;
}

export interface CatalogPageProduct {
  categories?: ProductAttributeValue[];
  description: string;
  id: string;
  identifier: ProductIdentifier;
  industries?: ProductAttributeValue[];
  publisherName: string;
  images: {
    small?: Image;
    medium?: Image;
    logo?: Image;
  };
  skus: SkuLocalizedProperty[];
  title: string;
}

export interface CatalogPageNegotiatedTerm {
  description: string;
  id: string;
  images: {
    small?: Image;
    medium?: Image;
    logo?: Image;
  };
  skus: SkuLocalizedProperty[];
  title: string;
  publisherName: string;
}

export interface CatalogPageProducts {
  products: Record<string, CatalogPageProduct>;
  serviceFamily: ServiceFamily;
  hasMorePages: boolean;
  resultsToSkip: number;
}

export interface FavoriteProductRequest {
  product: SearchResultProduct;
  groupName?: string;
  moveFrom?: string;
}

export interface ProductGroupActionRequest {
  groupName: string;
  newGroupName?: string;
}
