import { combineReducers } from 'redux';
import { defaultCustomerSearchPageSize } from 'services/ldss/config';
import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './actions';
import { CustomerSearchWithWildcard } from './types';

const reducer = combineReducers({
  customerSearch: createReducer<CustomerSearchWithWildcard, ActionType<typeof actions>>({
    searchTerm: '',
    exactResponse: [],
    wildcardResponse: [],
    totalResults: 0,
  })
    .handleAction(actions.searchCustomersAsync.request, (state, action) => {
      if (action.payload.skipItems === 0) {
        return {
          searchTerm: '',
          exactResponse: [],
          wildcardResponse: [],
          totalResults: 0,
        };
      } else {
        return state;
      }
    })
    .handleAction(actions.searchCustomersAsync.success, (state, action) => {
      const payload: CustomerSearchWithWildcard = { ...action.payload };
      if (payload.skipItems === 0) {
        payload.topResults = payload.wildcardResponse || [];
        payload.wildcardResponse = payload.wildcardResponse.slice(0, defaultCustomerSearchPageSize);
      } else {
        payload.topResults = state.topResults;
        payload.wildcardResponse = state.wildcardResponse.concat(action.payload.wildcardResponse);
      }

      return payload;
    }),
  selectedCustomer: createReducer<string, ActionType<typeof actions.selectedCustomer>>(
    ''
  ).handleAction(actions.selectedCustomer, (state, action) => {
    return action.payload;
  }),
});
export default reducer;
