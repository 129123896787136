import axios from 'axios';
import { OfferType } from './config';
import { CatalogRecoProduct, CatalogRecoResult } from './types';

export async function loadChannel(offerType: OfferType): Promise<CatalogRecoProduct[]> {
  const url = 'https://catalogapi.azure.com/products';
  const params = {
    'api-version': '2018-08-01-beta',
    $filter: `offerType eq '${offerType}'`,
    lang: 'en',
    $select: ['displayName', 'bigId'].toString(),
  };
  const response = await axios.get<CatalogRecoResult>(url, { params });
  return response.data.items;
}
