import { ButtonMenuProps, CommandBarButton } from 'components/ions';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { CopyLinkDialog } from './CopyLinkDialog';
import { SendEmailDialog } from './SendEmailDialog';

const mapStateToProps = (state: RootState) => {
  const quote = selectors.getActiveProposal(state);
  return {
    disableShare: selectors.disableShare(state),
    quoteId: quote.id,
  };
};

const ShareButtonFeature: React.FC<ReturnType<typeof mapStateToProps>> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  // SendEmail dialog -----------------------------------------------------
  const sendEmailDialogProps: DialogProps = {
    providedDialog: <SendEmailDialog />,
  };

  const handleSendEmailClick = () => context.openDialog(sendEmailDialogProps);

  // CopyLink dialog ------------------------------------------------------
  const copyLinkDialogProps: DialogProps = {
    providedDialog: <CopyLinkDialog />,
  };

  const handleCopyLinkClick = () => context.openDialog(copyLinkDialogProps);

  // Share button menu ----------------------------------------------------
  const menuProps: ButtonMenuProps[] = [
    {
      key: 'email',
      text: t('quote::Send email'),
      onClick: handleSendEmailClick,
      dataAutomationId: 'sendEmailMenu',
    },
    {
      key: 'link',
      text: t('quote::Copy link'),
      onClick: handleCopyLinkClick,
      dataAutomationId: 'copyLinkMenu',
    },
  ];

  return (
    <CommandBarButton
      ariaLabel={t('quote::Share quote options')}
      dataAutomationId="shareMenuProposalButton"
      disabled={props.disableShare}
      iconName="Share"
      id="ShareProposalButton"
      menuId="ShareMenuProposalButton"
      menuProps={menuProps}
      text={t('quote::Share')}
    />
  );
};

export const ShareButton = connect(mapStateToProps)(ShareButtonFeature);
