import { AxiosResponse } from 'axios';
import { languageInfo } from 'features/proposal/supported-languages';
import { Market, marketInfo } from 'features/proposal/supported-markets';

import {
  LineItemUserPreferences,
  Proposal,
  ProposalHeader,
  ProposalHeaderResponse,
  ProposalUserPreferences,
} from './types';
import { RequestLineItem, RequestProposal } from './types/requestTypes';

/*
This file has some bad practices in it. They were intentionally done to avoid having multiple additional models and deep copies.
UserPreferences coming back and going out as a string when it should be a property bag is the root cause, this should be fixed when that issue is resolved.
*/

/** Serializes the userpreferences objects into strings. */
export function requestifyProposal(proposal: RequestProposal): RequestProposal {
  const newProposal: RequestProposal = {
    ...proposal,
    header: { ...proposal.header, isApprovalPreCheckRequired: true },
  };
  if (proposal.header.extendedProperties && proposal.header.extendedProperties.userPreferences) {
    newProposal.header.extendedProperties = { ...newProposal.header.extendedProperties };
    newProposal.header.extendedProperties.userPreferences = (JSON.stringify(
      proposal.header.extendedProperties.userPreferences
    ) as unknown) as ProposalUserPreferences;
  }
  if (proposal.lineItems && proposal.lineItems.length) {
    newProposal.lineItems = proposal.lineItems.map(lineItem => {
      if (lineItem.extendedProperties && lineItem.extendedProperties.userPreferences) {
        const newLineItem = { ...lineItem, extendedProperties: { ...lineItem.extendedProperties } };
        newLineItem.extendedProperties.userPreferences = (JSON.stringify(
          lineItem.extendedProperties.userPreferences
        ) as unknown) as LineItemUserPreferences;
        return newLineItem;
      }
      return lineItem;
    });
  } else {
    //Can't send empty array of lineItems or service implodes
    newProposal.lineItems = (undefined as unknown) as RequestLineItem[];
  }
  return newProposal;
}

function appifyProposalHeader(header: ProposalHeader): ProposalHeader {
  if (header.extendedProperties && header.extendedProperties.userPreferences) {
    try {
      header.extendedProperties.userPreferences = JSON.parse((header.extendedProperties
        .userPreferences as unknown) as string);
    } catch (err) {
      delete header.extendedProperties.userPreferences;
    }
  }
  header.pricingContext.market = header.pricingContext.market.toUpperCase() as Market;
  if (header.pricingContext.languages) {
    //do we have this language as a valid possibility?
    const isValidLanguage = Object.keys(languageInfo).includes(header.pricingContext.languages);
    //If not, default to the first one in the market
    if (!isValidLanguage) {
      const validMarket = marketInfo[header.pricingContext.market];
      const defaultLanguageForMarket = validMarket.languages[0];
      header.pricingContext.languages = defaultLanguageForMarket;
    }
  }
  return header;
}

export function createProposalHeaderResponse(
  response: AxiosResponse<ProposalHeader>,
  id: string
): ProposalHeaderResponse {
  return { id, etag: response.headers.etag, header: appifyProposalHeader(response.data) };
}

/** Takes the quote response from Axios, stamps etag and normalizes the userpreferences strings into objects. */
export function appifyProposal(response: AxiosResponse<Proposal>): Proposal {
  const proposal = response.data;
  const etag = response.headers.etag;
  //Service defines lineItems as a non-optional [], but sends undefined if none exist. Alleviate that problem.
  if (proposal.lineItems === undefined) {
    proposal.lineItems = [];
  }
  response.data.etag = etag;
  proposal.header = appifyProposalHeader(proposal.header);
  if (proposal.lineItems) {
    proposal.lineItems.forEach(lineItem => {
      if (lineItem.extendedProperties && lineItem.extendedProperties.userPreferences) {
        try {
          lineItem.extendedProperties.userPreferences = JSON.parse((lineItem.extendedProperties
            .userPreferences as unknown) as string);
        } catch (err) {
          delete lineItem.extendedProperties.userPreferences;
        }
      }
    });
  }
  return proposal;
}
