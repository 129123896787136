import { Row } from 'components/ions';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { quoteListRowStyles } from './QuoteListRow.styles';

export type LoadingRowProps = {
  /**
   * For testing purposes
   */
  dataAutomationId?: string;
  /**
   * id of row
   */
  id: string;
};

type Props = LoadingRowProps & WithStyles<typeof quoteListRowStyles>;

export const LoadingRowUnStyled: React.FC<Props> = props => {
  const { classes, dataAutomationId, id } = props;

  return (
    <div
      data-automation-id={dataAutomationId}
      id={id}
      key={id}
      role="button"
      tabIndex={0}
      className={classes.row}
    >
      <Row loading className={classes.loadingRow} />
    </div>
  );
};

export const LoadingRow = withStyles(quoteListRowStyles)(LoadingRowUnStyled) as React.FC<
  LoadingRowProps
>;
