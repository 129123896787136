import { sharedStyles as dialogSharedStyles } from 'features-apollo/quote/components/Dialogs/shared.styles';
import { ThemeProps } from 'styles';

export const invoiceLanguageStyles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  secondaryText: {
    color: theme.palette.textTertiary,
  },
  textSpacer: {
    padding: 8,
  },
  comboBoxStyles: {
    width: 402,
    paddingTop: 24,
  },
});
