import { sharedStyles as dialogSharedStyles } from 'features-apollo/quote/components/Dialogs/shared.styles';
import { sharedStyles, ThemeProps } from 'styles';

export const organizationWizardStyles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  textOverflow: {
    ...sharedStyles(theme).textOverflow,
    display: 'block',
  },
  shimmerSpacing: {
    paddingTop: 4,
  },
  bold: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footerErrorMessage: {
    color: theme.palette.dangerText,
    height: 20,
    textAlign: 'right',
  },
  mainContent: {
    height: 465,
    overflowY: 'auto',
  },
});
