import { AppEnvironment } from 'features-apollo/app/config/types';
import store from 'store';

/**
 * Monkey patch to override react-beautiful-dnd keydown event listener
 * TODO: masivaia - remove when react-beautiful-dnd pushes ability to access and mutate their sensors
 */
export const addDnDEventListener = () => {
  const browserDefault = window.addEventListener;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.addEventListener = (type: string, listener: any, options?: any) => {
    const environment = store.getState().app.appConfig.app;
    const stringifiedListener = listener.toString();
    let isBadListenerCalled: boolean;

    // need to be able to redetect the bad listener, even when we have navigated away from the
    // quote so we reset logReactDndListenerCalls here

    // detect react-beautiful-dnd's keydown listener
    if (environment === AppEnvironment.Local) {
      isBadListenerCalled = stringifiedListener.includes(
        'Cannot stop capturing a keyboard drag when not capturing'
      );
    } else {
      isBadListenerCalled =
        stringifiedListener.includes('findClosestDraggableId(n)') ||
        stringifiedListener.includes('Wr[r.keyCode]');
    }

    if (!isBadListenerCalled) {
      return browserDefault(type, listener, options);
    }
  };
};
