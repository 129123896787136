export enum ApprovalStep {
  SelectApprover,
  Justification,
  Processing,
  ReadOnlyJustification,
}

export interface Layout {
  header?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
}

export interface SelectedApprovers {
  [key: string]: {
    order: number;
    emails: string[];
    isSelectedOption: boolean;
    errorTypes: ApproverErrorType[];
    autoPopulate: boolean;
  };
}

export interface ApproverSet {
  key: string;
  emails: string[];
  isSelectedOption: boolean;
  errorTypes: ApproverErrorType[];
  order: number;
  autoPopulate: boolean;
}

export enum ApproverErrorType {
  DuplicateApprover = 'DuplicateApprover',
  EmailNotEmpowered = 'EmailNotEmpowered',
  EmpowermentUnknown = 'EmpowermentUnknown',
  InvalidEmail = 'InvalidEmail',
}
