import { ThemeProps } from 'styles';

export const financingCardStyles = (theme: ThemeProps) => {
  return {
    label: {
      marginRight: -10,
    },
    infoLink: {
      color: theme.palette.lightColor,
      paddingRight: 5,
      textDecoration: 'underline',
      '&:active, &:hover:active': {
        color: theme.palette.lightColor,
      },
      '&:visited, &:hover, &:focus': {
        color: theme.palette.lightColor,
      },
      '&.is-disabled': {
        color: theme.palette.textDisabled,
      },
    },
    termIdLabel: {
      display: 'flex',
      flexDirection: 'row',
    },
    termIdContainer: {
      marginTop: 8,
      marginBottom: 12,
      width: 300,
    },
    inputContainer: {
      marginTop: 8,
      marginBottom: 12,
      width: 240,
    },
    link: {
      paddingTop: 12,
    },
    textColor: {
      color: theme.palette.lightColor,
    },
  };
};
