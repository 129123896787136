import {
  CopyLinkDialog,
  PublishDialog,
  SendEmailDialog,
} from 'features/proposal/components/Dialogs';
import { getActiveProposal } from 'features/proposal/selectors';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogProps } from 'styles';

export enum Step {
  COPY = 'COPY',
  EMAIL = 'EMAIL',
  PUBLISH = 'PUBLISH',
}

const mapStateToProps = (state: RootState) => {
  const quote = getActiveProposal(state);
  return {
    quoteId: quote.id,
  };
};

export const PublishWizard: React.FC<ReturnType<typeof mapStateToProps>> = () => {
  const [currentStep, setCurrentStep] = React.useState(Step.PUBLISH);
  const onClickCopy = () => setCurrentStep(Step.COPY);
  const onClickEmail = () => setCurrentStep(Step.EMAIL);

  switch (currentStep) {
    case Step.PUBLISH:
      return <PublishDialog onClickEmail={onClickEmail} onCopyLink={onClickCopy} />;
    case Step.COPY:
      return <CopyLinkDialog />;
    case Step.EMAIL:
      return <SendEmailDialog />;
  }
};

export const PublishWizardConnected = connect(mapStateToProps)(PublishWizard);

export const openPublishWizardDialog = (context: {
  openDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}) => {
  const dialogProps: DialogProps = {
    providedDialog: <PublishWizardConnected />,
  };
  context.openDialog(dialogProps);
};
