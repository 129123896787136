import { CalloutCard } from 'components/ions';
import { createProposalUpdateRequest } from 'features/proposal/utils';
import { DirectionalHint } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles from 'react-jss';
import { ValidateQuantity } from 'services/constraints/types';
import { LineItem } from 'services/proposal/types';

import { SeatsCardStyles } from './SeatsCard.styles';
import { SeatsCardBody } from './SeatsCardBody';
import { Props, SeatsCardState } from './types';
import { validateQuantity } from './validate';

export const SeatsCardUnstyled: React.FC<Props> = props => {
  const { onQuantityChange } = props;
  const { t } = useTranslation();

  const applyButtonStrings = {
    ariaLabel: t('quote::Apply'),
    text: t('quote::Apply'),
  };

  const totalSeatsValue =
    Number(props.quantity) + props.purchaseAtStart + props.sumOfQuantityExceptCurrentItem;

  const defaultState: SeatsCardState = {
    quantity: props.quantity,
    isApplyButtonDisabled: false,
    errorMessage: '',
    totalSeats: totalSeatsValue < 0 ? 0 : totalSeatsValue, // To ensure no negative quantity is displayed
    isWarning: false,
  };

  const [seatsCardState, setSeatsCardState] = React.useState(defaultState);

  const onSeatsAmountChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    const valueAsNum = Number(value);
    if (!value || value.trim() === '' || valueAsNum < 0) {
      setSeatsCardState({
        ...seatsCardState,
        errorMessage: t('quote::Please enter a valid quantity'),
        isApplyButtonDisabled: true,
        quantity: value || '',
        isWarning: false,
        // To ensure no negative quantity is displayed, this happened only in one case and it could not be repro'ed. But adding a check to be sure.
        totalSeats:
          props.sumOfQuantityExceptCurrentItem > 0 ? props.sumOfQuantityExceptCurrentItem : 0,
      });
    } else {
      if ((valueAsNum && valueAsNum !== Number(seatsCardState.quantity)) || valueAsNum === 0) {
        setSeatsCardState({
          ...seatsCardState,
          ...validateQuantity(
            valueAsNum,
            props.maxSeats,
            props.minSeats,
            props.purchaseAtStart,
            props.sumOfQuantityExceptCurrentItem,
            t
          ),
          quantity: valueAsNum.toString(),
          totalSeats: valueAsNum + props.purchaseAtStart + props.sumOfQuantityExceptCurrentItem,
        });
      } else {
        setSeatsCardState({
          ...seatsCardState,
          errorMessage: t('quote::Quantity can contain only numbers'),
          isApplyButtonDisabled: true,
          quantity: value,
          isWarning: false,
        });
      }
    }
  };
  const applyQuantity = () => {
    if (props.lineItem) {
      const newLineItem: LineItem = {
        ...props.lineItem,
        quantity: Number(seatsCardState.quantity),
      };
      const request = createProposalUpdateRequest(newLineItem, props.proposal);
      props.onUpdateQuantity(request);
      props.onDismiss();
    }
  };
  const {
    quantity,
    accountId,
    organizationId,
    productId,
    skuId,
    availabilityId,
    startDate,
    onLoad,
  } = props;

  React.useEffect(() => {
    if (accountId && organizationId) {
      const payload: ValidateQuantity = {
        endCustomerAccountId: accountId,
        endCustomerOrganizationId: organizationId,
        constrainedProducts: [
          {
            productID: productId,
            skuID: skuId,
            availabilityId,
            quantity: Number(quantity),
            startDate,
            customerIntent: 'new',
          },
        ],
      };
      onLoad(payload);
    }
  }, [accountId, organizationId, productId, skuId, availabilityId, quantity, startDate, onLoad]);

  React.useEffect(() => {
    if (onQuantityChange && seatsCardState.totalSeats)
      onQuantityChange(Number(seatsCardState.totalSeats), !seatsCardState.isApplyButtonDisabled);
  }, [onQuantityChange, seatsCardState.totalSeats, seatsCardState.isApplyButtonDisabled]);

  return (
    <>
      {props.target ? (
        <CalloutCard
          applyButtonDisabled={seatsCardState.isApplyButtonDisabled}
          applyButtonStrings={applyButtonStrings}
          closeButtonAriaLabel="Close"
          directionalHint={DirectionalHint.rightCenter}
          headerText={t('quote::Quantity')}
          id="quantity-card"
          isBeakVisible={true}
          isReadOnly={false}
          target={props.target}
          onApply={applyQuantity}
          onDismiss={props.onDismiss}
        >
          <SeatsCardBody
            maxSeats={props.maxSeats}
            minSeats={props.minSeats}
            purchaseAtStart={props.purchaseAtStart}
            seatsCardState={seatsCardState}
            sumOfQuantityExceptCurrentItem={props.sumOfQuantityExceptCurrentItem}
            onSeatsAmountChange={onSeatsAmountChange}
          />
        </CalloutCard>
      ) : (
        <SeatsCardBody
          maxSeats={props.maxSeats}
          minSeats={props.minSeats}
          purchaseAtStart={props.purchaseAtStart}
          seatsCardState={seatsCardState}
          sumOfQuantityExceptCurrentItem={props.sumOfQuantityExceptCurrentItem}
          onSeatsAmountChange={onSeatsAmountChange}
        />
      )}
    </>
  );
};
export const SeatsCard = withStyles(SeatsCardStyles)(SeatsCardUnstyled);
