import { TextBody } from 'components/ions';
import { formatPrice } from 'features-apollo/formatters';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import React from 'react';
import { Trans } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { ConversionButton } from './ConversionButton';

export interface ProductListFooterProps {
  /**
   * Currency of total price
   */
  currency: string;
  /**
   * Quote's total pice
   */
  totalPrice: number;
  /**
   * Use to determine whether conversion button is available
   */
  isQuoteLegacy: boolean;
  /**
   * Use for conversion information
   */
  exchangeRate?: number;
  /**
   * Use for conversion information
   */
  exchangeDate?: Date;
}

export const styles = (theme: ThemeProps) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '24px 0',
      height: 'min-content',
      '& > i': {
        color: theme.palette.textSecondary,
      },
    },
    totalContainer: {
      alignItems: 'baseline',
      paddingTop: 2,
      display: 'flex',
    },
    price: {
      fontSize: theme.fonts.fontSizes.large,
      paddingLeft: 8,
      paddingRight: 20,
      fontWeight: theme.fonts.fontWeights.semiBold,
    },
  };
};

type Props = ProductListFooterProps & WithStyles<typeof styles>;

const ProductListFooterUnstyled: React.FC<Props> = props => {
  const { currency } = props;

  const conversionButton =
    !props.isQuoteLegacy && currency !== DEFAULT_CURRENCY && props.exchangeRate ? (
      <ConversionButton exchangeDate={props.exchangeDate} exchangeRate={props.exchangeRate} />
    ) : null;

  const price = formatPrice(props.totalPrice, props.currency);

  return (
    <div className={props.classes.root} data-automation-id="product-list-footer">
      {conversionButton}
      <div className={props.classes.totalContainer}>
        <TextBody>
          <Trans ns="quote">
            Total ({{ currency }}):<TextBody addClass={props.classes.price}>{{ price }}</TextBody>
          </Trans>
        </TextBody>
      </div>
    </div>
  );
};

export const ProductListFooter = withStyles(styles)(ProductListFooterUnstyled) as React.FC<
  ProductListFooterProps
>;
