import { Currency } from 'features/proposal/supported-currencies';
import { Language } from 'features/proposal/supported-languages';
import { Market } from 'features/proposal/supported-markets';
import { Filter } from 'services/types';

import { BaseLineItem, BaseProposalHeader } from './baseTypes';

export enum ProposalStatus {
  Draft = 'Draft', // t('Draft')
  Submitted = 'Submitted', // t('Submitted')
  Active = 'Active', // t('Active')
  Rejected = 'Rejected', // t('Rejected')
  Deleted = 'Deleted', // t('Deleted')
  Expired = 'Expired', // t('Expired')
  Published = 'Published', //t('Published')
  PurchaseInProgress = 'PurchaseInProgress', // t('PurchaseInProgress')
  Purchased = 'Purchased', // t('Purchased')
  PurchaseFailure = 'PurchaseFailure', // t('PurchaseFailure')
  Completed = 'Completed', // t('Completed')
  Unknown = 'Unknown', // t('Unknown')
}

export enum NegotiationReason {
  Compete = 'Compete',
  Aggregation = 'Aggregation',
  FrameworkAgreement = 'FrameworkAgreement',
  LicensingProgramConflict = 'LicensingProgramConflict',
  BudgetConstraints = 'BudgetConstraints',
  LocalMarketDynamics = 'LocalMarketDynamics',
  SeedingOrDeploymentRamp = 'SeedingOrDeploymentRamp',
  PriceIncreases = 'PriceIncreases',
  Unknown = 'Unknown',
}

export interface ProposalHeaderResponse {
  etag: string;
  id: string;
  header: ProposalHeader;
}

export interface Proposal {
  etag: string;
  id: string;
  revision: number;
  continuationToken?: string;
  header: ProposalHeader;
  lineItems: LineItem[];
}

export interface LineItem extends BaseLineItem {
  status: string;
  purchaseId?: string;
  pricingCurrency: Currency;
  listPrice: number;
  discountedPrice: number;
  chargePrice: Price;
  extendedPrice: number;
  billingPlanDetail?: {
    cycleCount: number;
    cycleDuration: string;
    estimatedPrice: Price;
    markupMultiplier?: number;
  };
  exchangeRate?: number;
  customPrice?: {
    id: string;
    version: string;
    pricingScopeId: string;
  };
  pricingRules?: PricingRule[];
  messages?: Message[];
  refundDetails?: {
    purchaseRecordInfoForRefund: {
      purchaseRecordId: string;
      purchaseRecordLineItems: {
        id: {
          id: string;
          low: number;
          high: number;
        };
        currentPrice: number;
        targetPrice: number;
        refundPolicy: string;
        remainingTermUnits: number;
      }[];
    }[];
  };
  futureGuaranteedPrice?: {
    type: string;
    value: number;
  };
}

export enum UserGroup {
  Customer = 'Customer',
  Field = 'Field',
  Partner = 'Partner',
}

export interface ProposalHeader extends BaseProposalHeader {
  version: string;
  priceSource?: string;
  status: ProposalStatus;
  readOnly: boolean;
  totalPrice: number;
  assignedToGroup: UserGroup;
  createdApiVersion: string;
  modifiedApiVersion: string;

  createdBy: string;
  createdDate: Date;
  modifiedBy: string;
  modifiedDate: Date;
  submittedDate?: Date;
  expirationDate: Date;
  supportedApiVersions: string[];
  messages?: Message[];
  testHeaderData: {
    scenarios: string;
    contact: string;
    retention: Date;
  };
  approval: {
    id?: string;
    status?: string;
    workflow?: string;
    approvalLevels: ApprovalLevel[];
    requiredApprovalLevels?: {
      order: number;
      level: string;
      policy: string;
      autoPopulate: boolean;
    }[];
  };
}
export interface ApprovalLevel {
  level: string;
  order: number;
  policy: string;
  approvers: {
    firstName?: string;
    lastName?: string;
    emailAddress: string;
  }[];
}

export interface ApprovalLevel {
  level: string;
  order: number;
  policy: string;
  approvers: {
    firstName?: string;
    lastName?: string;
    emailAddress: string;
  }[];
}

export interface AddressIdentifier {
  accountId: string;
  organizationId: string;
  businessLocationId: string;
  businessLocationVersion: number;
}

export interface LineItemSummary {
  id: string;
  recipient: {
    accountId: string;
    recipientId: string;
  };
  assetTo: {
    projectId: string;
    accountId: string;
    organizationId: string;
  };
  externalReference: {
    id: string;
  };
}

export interface ProposalSummary {
  id: string;
  revision: number;
  header: {
    name: string;
    opportunityId: string;
    engagementId: string;
    totalPrice: number;
    msContact: string;
    assignedToGroup: UserGroup;
    status: ProposalStatus;
    createdBy: string;
    createdDate: Date;
    modifiedDate: Date;
    expirationDate: Date;
    version: string;
    pricingContext: {
      billingCurrency: Currency;
    };
    soldToCustomerLegalEntity: Customer;
    endCustomerLegalEntity?: Customer;
    billTo: {
      accountId: string;
    };
    transactionType: string;
    guestUserId: string;
  };
  lineItems: LineItemSummary[];
}

export interface ProposalSummaryList {
  pageSize: number;
  nextPageUri: string;
  totalSize: number;
  results: ProposalSummary[];
}

export interface ProposalUserPreferences {
  nationalCloud?: string;
  recipientId?: string | null;
  agreementType?: string;
  duration?: number;
  // If setExpiration is true that means Quote expiration set by User.
  setExpiration?: boolean;
  leadOrgId?: string;
  channel?: string;
  segment?: string;
}

export interface LineItemUserPreferences {
  availabilityTermId?: string;
  duration?: string;
  location?: string;
  skuCount?: number;
  term?: string;
  termDurationKey?: string;
  skuTitle?: string;
  conditions?: PricingInstructionCondition[];
  availabilityId?: string;
  productType?: string;
}

export interface Customer {
  accountId?: string;
  organizationId?: string;
  organizationVersion?: number | null;
  monthlyCreditLimit?: number;
}

export interface Message {
  code: string;
  content: string;
  context: string;
  targetProperty: string;
  targetPropertyId: string;
  sourceService: string;
  severity: number;
}

export interface Price {
  proratedPrice: number;
  price: number;
}

export interface ProductIdentifier {
  productId: string;
  skuId?: string;
  availabilityId?: string;
  transactingAvailabilityId?: string;
  action?: string;
  availabilityTermId?: string;
  productFamily?: string;
  productType?: string;
}

export interface PricingInstructionCondition {
  type: string;
  name: string;
  value: string[];
}

export interface CustomPriceScope {
  type: string;
  value: string;
}

export interface OrganizationIdentifier {
  accountId: string;
  organizationId: string;
}

export enum PriceAdjustmentType {
  extend = 'extend',
  remove = 'remove',
  new = 'new',
}

export interface PricingInstruction {
  ruleType?: string;
  applicability?: string;
  discountPercentage?: number;
  priceGuaranteeDate?: Date;
  pricingPolicy?: string;
  conditions?: PricingInstructionCondition[];
  // TODO: cameneks,kaderbez all of them are optional ??
  productIdentifier?: {
    productFamily?: string;
    productId?: string;
    availabilityId?: string;
    skuId?: string;
    action?: string;
  };
  customPriceScope?: CustomPriceScope;
  organizations: OrganizationIdentifier[];
  customPriceReferenceTokens?: string[];
  priceAdjustmentType: PriceAdjustmentType;
}

export interface PricingRule {
  id: string;
  affirmation: string;
  priceGuaranteed: boolean;
  affirmationParameters?: {
    key: string;
    value: string;
    type: string;
  }[];
}

export interface LineItemSummary {
  id: string;
  recipient: {
    accountId: string;
    recipientId: string;
  };
  assetTo: {
    projectId: string;
    accountId: string;
    organizationId: string;
  };
  externalReference: {
    id: string;
  };
}

export interface PricingContext {
  catalogId?: string;
  market: Market;
  languages?: Language;
  billingCurrency?: Currency;
  exchangeRateDate?: Date;
}

export interface PricingProposal {
  totalPrice: number;
  pricingContext: PricingContext;
  lineItems: LineItem[];
  messages: Message[];
}

export interface SubmittingProposal {
  approvalLevels: ApprovalLevel[];
  proposal: Proposal;
  comments?: string;
  negotiationReason: NegotiationReason;
}

export interface ProposalSubmitActionRequest {
  proposal: Proposal;
  comments?: string;
}

export interface Submission {
  approvers?: string[];
  justification?: string;
}

export enum SearchProposalsFilterField {
  Id = 'Id',
  MsContact = 'msContact',
  Name = 'name',
  OpportunityId = 'opportunityId',
  Status = 'status',
  EngagementId = 'engagementId',
  SoldToOrgId = 'soldToCustomerLegalEntity.organizationId',
}

export enum SearchProposalsSortField {
  AssignedToGroup = 'assignedToGroup',
  ExpirationDate = 'expirationDate',
  ModifiedDate = 'modifiedDate',
  Status = 'status',
}

export enum SearchSortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface ProposalSort {
  order: SearchSortOrder;
  field: SearchProposalsSortField;
}

export interface SearchProposalsParams {
  filter?: Filter<SearchProposalsFilterField>;
  sort?: ProposalSort;
}

export enum BusinessContactRoleType {
  primary = 'primary',
  commerceAdministrator = 'commerceAdministrator',
  purchaser = 'purchaser',
  signatory = 'signatory',
  Unknown = 'Unknown',
}

export interface BusinessContact {
  id: string;
  version: number;
  roleType: BusinessContactRoleType;
}

export interface OneAskResult {
  RequestNumber: string;
  Currency: string;
  TotalApprovedAmount: number;
}

export interface SapContact {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface SapReferenceData {
  opportunityId?: string;
  contact?: SapContact;
}
