import gql from 'graphql-tag';

export const GetCrmLead = gql`
  query GetCrmLead($id: String!) {
    getCRMLead(id: $id) {
      salesAccount {
        isStrategic
      }
    }
  }
`;
