import { IButtonStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import {
  DraggableStateSnapshot,
  DraggingStyle,
  DropAnimation,
  NotDraggingStyle,
  Position,
} from 'react-beautiful-dnd';
import { maxZIndex, sharedStyles, ThemeProps } from 'styles';
import { FinderItemType } from 'features-apollo/quote/components/Finder/DragAndDrop';

import { DraggableProps } from './DraggableButton';

// #region helper functions to set container and text colors
const getColor = (
  { isDragging, isSelected, isGhosting, disabled }: DraggableProps,
  theme: ThemeProps
): string => {
  if (disabled) {
    return theme.palette.backgroundDivider;
  }
  if (isGhosting || isSelected || isDragging) {
    return theme.palette.lightColor;
  }
  return theme.palette.text;
};

const getBackgroundColor = (
  { isDragging, isSelected, isGhosting, disabled }: DraggableProps,
  theme: ThemeProps
): string => {
  if (disabled) {
    return theme.palette.tileDisabled;
  }
  if (isGhosting || isSelected || isDragging) {
    return theme.palette.primary;
  }

  return theme.palette.backgroundStandout;
};
// #endregion

export const draggableButtonStyles = (theme: ThemeProps) => {
  return {
    main: (props: DraggableProps) => {
      return {
        ...sharedStyles(theme).roundedCorners,
        fontSize: theme.fonts.fontSizes.medium,
        fontWeight: theme.fonts.fontWeights.regular,
        wordWrap: 'anywhere',
        userSelect: 'none',
        width: '100%',
        minHeight: '24px',
        /* needed for SelectionCount */
        position: 'relative',
        '& .ms-Button-label': {
          fontWeight: theme.fonts.fontWeights.regular,
          margin: 0,
          textAlign: 'left',
          lineHeight: '18px',
          paddingLeft: props.item.type === FinderItemType.UploadItem ? 25 : 0,
        },
        '&:disabled': {
          backgroundColor: theme.palette.backgroundMain,
          borderColor: theme.palette.backgroundMain,
          color: theme.palette.backgroundDivider,
        },
        '&:hover': {
          color: props.isSelected && theme.palette.lightColor,
          backgroundColor: theme.palette.backgroundStandout,
          border: `1px solid ${theme.palette.primary}`,
          boxShadow: `${theme.palette.shadowColor} 0px 2px 30px 0px`,
        },
      };
    },
    selectionCount: {
      color: theme.palette.lightColor,
      background: `${theme.palette.dangerActive} 0% 0% no-repeat padding-box`,
      borderRadius: '50%',
      height: '24px',
      width: '24px',
      lineHeight: '22px',
      position: 'absolute',
      left: '-12px',
      top: '20px',
      textAlign: 'center',
      font: 'bold 14px Segoe UI',
      zIndex: maxZIndex,
    },
    placeholderText: (props: DraggableProps) => {
      let padding = '2px 4px 4px 7px';
      if (props.isFavoritesButton) {
        padding = '2px 32px 4px 7px';
      } else if (props.item.type === FinderItemType.UploadItem) {
        padding = '2px 4px 4px 32px';
      }
      return {
        color: theme.palette.lightColor,
        display: 'block',
        padding,
      };
    },
    nonInteractiveText: (props: DraggableProps) => {
      let padding = '2px 4px 4px 7px';
      if (props.isFavoritesButton) {
        padding = '2px 32px 4px 7px';
      } else if (props.item.type === FinderItemType.UploadItem) {
        padding = '2px 4px 4px 32px';
      }
      return {
        color: getColor({ ...props }, theme),
        display: 'block',
        padding,
      };
    },
    placeholderContainer: {
      backgroundColor: theme.palette.primary,
      border: `1px solid ${theme.palette.primary}`,
      minHeight: '24px',
      borderRadius: '2px',
      opacity: 0.5,
    },
    nonInteractiveContainer: (props: DraggableProps) => {
      return {
        backgroundColor: getBackgroundColor({ ...props }, theme),
        border: `1px solid ${getBackgroundColor({ ...props }, theme)}`,
        minHeight: '24px',
        borderRadius: '2px',
        opacity: props.isGhosting ? 0.5 : 1,
      };
    },
    nonInteractiveErrorContainer: (props: DraggableProps) => {
      return {
        backgroundColor: getBackgroundColor({ ...props }, theme),
        border: `1px solid ${theme.palette.dangerActive}`,
        minHeight: '24px',
        borderRadius: '2px',
        opacity: props.isGhosting ? 0.5 : 1,
      };
    },
    focus: (props: DraggableProps) => {
      return {
        '&:focus': {
          outline: props.isDragging ? 'none' : `1px solid ${theme.palette.text}`,
        },
        display: 'flex',
        position: 'relative',
      };
    },
    buttonContainer: {
      width: '100%',
    },
    teachingBubbleLinkText: {
      color: theme.palette.lightColor,
      textDecoration: 'underline',
      '&:hover, &:focus, &:active, &:visited, &:hover:active': {
        color: theme.palette.lightColor,
      },
    },
    errorBadgeContainer: {
      backgroundColor: 'transparent',
      height: '100%',
      position: 'absolute',
      right: '2px',
      top: '3px',
    },
    buttonError: {
      color: theme.palette.textDisabled,
      border: `1px solid ${theme.palette.dangerActive}`,
      backgroundColor: theme.palette.backgroundCommon,
      '&:hover': {
        color: theme.palette.textDisabled,
        border: `1px solid ${theme.palette.dangerActive}`,
        backgroundColor: theme.palette.backgroundCommon,
      },
    },
    icon: (props: DraggableProps) => {
      return {
        position: 'absolute',
        zIndex: 10,
        paddingLeft: 7,
        paddingTop: 4,
        color: props.disabled ? theme.palette.textDisabled : theme.palette.secondary,
      };
    },
    placeholderIcon: {
      position: 'absolute',
      zIndex: 10,
      paddingLeft: 7,
      paddingTop: 4,
      color: theme.palette.lightColor,
    },
  };
};

// styles the button container
export const buttonContainerStyle = ({ isDragging }: DraggableProps): React.CSSProperties => {
  return isDragging
    ? {
        width: '100%',
        outline: 'none',
      }
    : {
        width: '100%',
      };
};

// styles the inner command button
export const buttonStyle = (
  { isDragging, isSelected, isGhosting, item, isFavoritesButton, disabled }: DraggableProps,
  theme: ThemeProps
): React.CSSProperties => {
  const buttonColor = getBackgroundColor(
    {
      isDragging,
      isSelected,
      isGhosting,
      item,
      disabled,
    },
    theme
  );
  return {
    backgroundColor: buttonColor,
    padding: isFavoritesButton ? '2px 32px 4px 7px' : '2px 4px 4px 7px',
    color: getColor({ isDragging, isSelected, isGhosting, item, disabled }, theme),
    border: `1px solid ${buttonColor}`,
    borderRadius: isDragging ? '4px' : '2px',
    opacity: isGhosting ? 0.5 : 1,
    zIndex: isDragging ? maxZIndex - 1 : 0,
  };
};

// styles the button during a drag
export const additionalDragStyles: IButtonStyles = {
  textContainer: {
    width: '10px',
    whiteSpace: 'nowrap',
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

// styles the stack ed button illusion when multi ple  bu tton s  are selected
export const combinedItems = (
  left: number,
  top: number,
  zIndex: number,
  theme: ThemeProps
): React.CSSProperties => {
  return {
    background: `${theme.palette.backgroundPanel} 0% 0% no-repeat padding-box`,
    border: `2px solid ${theme.palette.primary}`,
    minHeight: '21px',
    borderRadius: '4px',
    zIndex,
    position: 'relative',
    left,
    top,
  };
};

// styles the button's behavior when it is dropped
export const dropStyle = (
  style: DraggingStyle | NotDraggingStyle,
  snapshot: DraggableStateSnapshot,
  dropCoordinates?: Position,
  destinationId?: string
): Record<string, any> | undefined => {
  const dropping: DropAnimation | undefined = snapshot.dropAnimation;
  if (!dropping) {
    return style;
  }

  const { curve, duration, moveTo } = dropping;
  const hasReachedDestination = destinationId === snapshot.draggingOver;
  const offsetX = dropCoordinates ? dropCoordinates.x : 0;
  const offsetY = dropCoordinates ? dropCoordinates.y : 0;
  const translate = hasReachedDestination
    ? `translate(${moveTo.x + offsetX}px, ${moveTo.y + offsetY}px)`
    : '';
  return {
    ...style,
    transform: `${translate}`,
    opacity: 0,
    // slowing down the drop
    transition: `all ${curve} ${hasReachedDestination ? duration : 0.001}s`,
  };
};
