import { TextBody, XLargeIcon } from 'components/ions';
import { WaitingIndicator } from 'components/ions/Progress/WaitingIndicator';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './DialogStatus.styles';
import { DialogStatusType } from './types';

interface Props extends WithStyles<typeof styles> {
  addClass?: string;
  text?: string;
  status: DialogStatusType;
}

const DialogStatusBodyUnstyled: React.FC<Props> = ({ addClass, classes, text, status }) => {
  let icon = <div />;
  let iconType = null;

  switch (status) {
    case DialogStatusType.Success:
      icon = <XLargeIcon addClass={classes.customDialogIcon} iconName="Accept" />;
      iconType = classes.customDialogSuccessIcon;
      break;

    case DialogStatusType.Processing:
      icon = <WaitingIndicator />;
      iconType = classes.customDialogProcessingIcon;
      break;

    case DialogStatusType.Fail:
      icon = <XLargeIcon addClass={classes.customDialogIcon} iconName="Cancel" />;
      iconType = classes.customDialogFailIcon;
      break;
  }

  return (
    <div className={classes.customDialogBody}>
      <div className={`${classes.customDialogContainer} ${addClass}`}>
        <div className={`${classes.iconContainer} ${iconType}`}>{icon}</div>
        <div>
          <TextBody addClass={classes.text}>{text}</TextBody>
        </div>
      </div>
    </div>
  );
};

export const DialogStatusBody = withStyles(styles)(DialogStatusBodyUnstyled);
