import { ThemeProps } from 'styles';

import { DialogProps } from './Dialog/Dialog';
import { TextDialogBoxProps } from './TextDialog';

export const sharedDialogStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    heading: {
      display: 'flex',
      flexDirection: 'row',
      placeItems: 'flex-start flex-start',
    },
    title: { width: '100%' },
    imageTitle: { width: '100%', marginTop: 5 },
    actionLink: { marginLeft: 2 },
    contentBody: {
      paddingTop: 10,
      '& a': {
        textDecoration: 'none',
        color: theme.palette.textLink,
        '&:hover': {
          color: theme.palette.textLinkHover,
        },
      },
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 382,
      minHeight: 206,
      maxWidth: 600,
      maxHeight: 600,
      padding: 20,
      border: `1px solid ${palette.backgroundDivider}`,
      backgroundColor: `${palette.backgroundCommon}`,
      boxShadow: `0 0 25px 0 ${palette.shadowColor}`,
      zIndex: 100,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    closeButton: {
      width: '10%',
    },
    image: {
      objectFit: 'contain',
      width: 67,
      height: 67,
    },
    imageContainer: {
      width: 100,
      marginRight: 10,
    },
    size: (props: TextDialogBoxProps) => {
      return { height: props.height, width: props.width };
    },
  };
};

export const customDialogStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${palette.backgroundDivider}`,
      backgroundColor: `${palette.backgroundStandout}`,
      boxShadow: `0 0 25px 0 ${palette.shadowColor}`,
      zIndex: 100,
      overflowY: 'auto',
      overflowX: 'hidden',
      minWidth: 382,
      minHeight: 206,
      maxWidth: 600,
      maxHeight: 600,
      justifyContent: 'center',
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'column',
    },
    bodyRow: {
      display: 'flex',
      height: '100%',
    },
    footerRow: {
      display: 'flex',
      flexDirection: 'column',
      flexStart: 'flex-end',
    },
    size: (props: TextDialogBoxProps) => {
      return { height: props.height, width: props.width };
    },
  };
};

export const dialogStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  const sidePaddings = {
    paddingLeft: 24,
    paddingRight: 24,
  };

  return {
    helpButton: {
      '& i': {
        fontSize: theme.fonts.fontSizes.small,
      },
    },
    body: { ...sidePaddings, height: '100%', overflowY: 'auto' },
    closeButton: {
      left: 8,
      '& i': {
        fontSize: theme.fonts.fontSizes.xLarge,
      },
    },
    dialog: (props: DialogProps) => ({
      display: props.imageProps ? 'flex' : undefined,
      backgroundColor: palette.backgroundStandout,
      border: `1px solid ${palette.backgroundDivider}`,
      boxShadow: `0 0 25px 0 ${palette.shadowColor}`,
      minHeight: props.minHeight,
      height: props.height,
      width: props.width,
    }),
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      paddingBottom: (props: DialogProps) => (props.hideFooter ? 24 : undefined),
    },
    footer: {
      ...sidePaddings,
      alignItems: 'center',
      backgroundColor: palette.backgroundCommon,
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'flex-end',
      minHeight: 46,
      width: '100%',
      '& > :not(:first-child)': {
        marginLeft: 20,
      },
    },
    header: {
      ...sidePaddings,
      alignItems: 'center',
      display: 'flex',
      paddingBottom: 24,
      paddingTop: 24,
    },
    headline: {
      color: palette.textTertiary,
    },
    noTitle: {
      justifyContent: 'flex-end',
    },
    sideImage: (props: DialogProps) =>
      props.imageProps
        ? {
            height: '100vh',
            width: '100vw',
            maxHeight: props.height,
            maxWidth: props.imageProps.width || props.width / 2,
            backgroundImage: `url(${props.imageProps.url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }
        : undefined,
    title: {
      justifyContent: 'space-between',
    },
  };
};
