import * as React from 'react';

import { MockedDiscountCard } from './';

const DiscountCardGQL = (props: any) => {
  return <MockedDiscountCard {...props} />;
};

export default {
  component: DiscountCardGQL,
  name: 'DiscountCard-GQL',
  props: {
    mockMultiSelect: false,
    mockUnconfigured: true,
    linkText: 'Click Me.',
  },
};
