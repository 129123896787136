import { Address, AddressProps } from 'components/ions';
import { TextBodySmall } from 'components/ions/Text';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { OrganizationAddress } from 'services/account-extensions/types';
import { ThemeProps } from 'styles';

/**
 * Properties that provided the organization information that will be display
 *
 * @prop {OrganizationAddress} address  the business address of the organization
 * @prop {string | undefined} lastPurchaseDateTime date of last purchase
 * @prop {number} maxWidth limit the width of the address before text wraps
 * @prop {string} name the company name of the organization
 * @prop {string} tradeName the name the organization use to do business as
 */
export interface OrganizationInfoProps {
  address: OrganizationAddress;
  lastPurchaseDateTime?: string;
  maxWidth?: number;
  name: string;
  tradeName?: string;
}

const styles = (theme: ThemeProps) => ({
  lastPurchaseDate: {
    color: theme.palette.textTertiary,
  },
});

type Props = OrganizationInfoProps & WithStyles<typeof styles>;

export const OrganizationInfoUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();

  const addressProps: AddressProps = {
    address: { ...props.address, companyName: props.name },
    dataAutomationId: 'organizationAddress',
    maxWidth: props.maxWidth,
    showCompanyName: true,
    tradeName: props.tradeName,
  };

  //TODO:cameneks, set the moment locale for translation, also maybe different date format like(yyyy-mm-dd)
  const lastPurchaseDate = props.lastPurchaseDateTime
    ? t('Last Purchase {{date}}', {
        date: moment(props.lastPurchaseDateTime).format('MMM D, YYYY '),
      })
    : t('not purchased yet');

  return (
    <>
      <Address {...addressProps} />
      <TextBodySmall addClass={props.classes.lastPurchaseDate}>{lastPurchaseDate}</TextBodySmall>
    </>
  );
};

export const OrganizationInfo = withStyles(styles)(OrganizationInfoUnstyled);
