import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import cloneDeep from 'clone-deep';
import { ActiveQuoteContext, GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { ActiveQuoteProvider } from 'features-apollo/ActiveQuoteProvider';
import { quoteData } from 'features-apollo/quote/mocks';
import introspectionQueryResultData from 'generated/fragmentTypes.json';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLError } from 'graphql';
import { createMemoryHistory, MemoryHistory } from 'history';
import { mockedStore, mockState } from 'mocks';
import { loadTheme } from 'office-ui-fabric-react';
import React from 'react';
import { Provider } from 'react-redux';
import { MemoryRouter, Route } from 'react-router-dom';
import { render } from 'react-testing-library';
import { AnyAction, createStore, Store } from 'redux';
import rootReducer from 'store/root-reducer';
import { RootState } from 'store/types';
import { DialogContext, DialogProvider } from 'styles';
import GlobalStyles from 'styles/GlobalStyles';
import { getMeplaTheme, supportedThemes } from 'styles/theme/themes';
import { ThemeProvider as JssThemeProvider } from 'theming';

import { MockedProvider, MockedResponse } from '@apollo/react-testing';

export const getClonedMockState = () => cloneDeep(mockState);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export const mockedCache = new InMemoryCache({ fragmentMatcher });

export const createMockStoreWithRootReducer = (
  state: RootState = getClonedMockState(),
  history: MemoryHistory = createMemoryHistory()
) => createStore(rootReducer(history), state);

export interface ComponentWithMockedProvidersProps {
  pathToMatch?: string;
  initialEntries?: string[];
  initialIndex?: number;
  store?: Store<any, AnyAction>;
  mocks?: MockedResponse[];
  addTypename?: boolean;
  quoteId?: string;
  fragmentMatcher?: boolean;
  waitForQuoteContextQuery?: boolean;
  /**
   * Renders the component as a dialog.
   * Allows to close dialog and remove it from DOM.
   */
  useDialogProvider?: boolean;
  /**
   * Option to turn off for fixture use
   * @default true
   */
  useThemeProvider?: boolean;
}

export const renderWithProviders = (
  content: React.ReactElement,
  props?: ComponentWithMockedProvidersProps
) => {
  const component = <MockedProviders {...props}>{content}</MockedProviders>;
  return render(component);
};

export const renderWithTheme = (children: React.ReactElement, darkTheme?: boolean) => {
  const theme = supportedThemes[darkTheme ? 1 : 0];
  const meplaTheme = getMeplaTheme(theme);

  loadTheme(meplaTheme.FabricTheme);

  const component = (
    <JssThemeProvider theme={meplaTheme}>
      <GlobalStyles />
      {children}
    </JssThemeProvider>
  );

  return render(component);
};

export const wait = (amount = 0) => new Promise(resolve => setTimeout(resolve, amount));

export const createGqlError = (
  message: string,
  extensions?: {
    [key: string]: any;
  }
) => {
  return new GraphQLError(
    message,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    extensions
  );
};

/**
 * A helper to set the quote id on the ActiveQuoteContext in case the component been tested has a
 * dependency on that.
 *
 * @param quoteId
 */
export const SetActiveQuoteContext: React.FC<{ quoteId?: string }> = props => {
  const { quoteId } = props;
  const activeQuote = React.useContext(ActiveQuoteContext);
  activeQuote.updateQuoteId(quoteId);

  return <div>{props.children}</div>;
};

const QuoteLoadedDetector: React.FC<{ waitForQuoteContextQuery?: boolean }> = props => {
  const quoteContext = React.useContext(ActiveQuoteContext);
  if (!props.waitForQuoteContextQuery || !quoteContext.activeQuote) {
    return null;
  }
  return <div>Quote Context Query Loaded!!!!</div>;
};

/**
 * Gets a mocked active quote context response
 */
export const getMockedActiveQuoteContextResponse = (id: string): MockedResponse => ({
  request: {
    query: GET_QUOTE,
    variables: { id },
  },
  result: {
    data: {
      getQuote: quoteData,
    },
  },
});

export const MockedDialog: React.FC = props => {
  const { openDialog } = React.useContext(DialogContext);
  const onClick = () => openDialog({ providedDialog: props.children });
  return <button onClick={onClick}>opendialog</button>;
};

/**
 * A wrapper that includes all current providers mocked with default values. Any default value
 * can be replaced/modified through the provided props.
 *
 * Providers: Apollo, ReduxStore, ActiveQuote, Theme, Route
 */
export const MockedProviders: React.FC<ComponentWithMockedProvidersProps> = props => {
  const {
    pathToMatch = '/',
    initialEntries = ['/'],
    initialIndex = 0,
    mocks = [],
    store = mockedStore,
    waitForQuoteContextQuery = false,
    useThemeProvider = true,
  } = props;

  const component = props.useDialogProvider ? (
    <MockedDialog>{props.children}</MockedDialog>
  ) : (
    props.children
  );

  const providers = (
    <MockedProvider
      addTypename={!!props.addTypename}
      cache={props.fragmentMatcher ? new InMemoryCache({ fragmentMatcher }) : undefined}
      mocks={mocks}
    >
      <Provider store={store}>
        <ActiveQuoteProvider>
          <SetActiveQuoteContext quoteId={props.quoteId}>
            <DialogProvider>
              <MemoryRouter initialEntries={initialEntries} initialIndex={initialIndex}>
                <Route
                  path={pathToMatch}
                  render={() => (
                    <>
                      <QuoteLoadedDetector waitForQuoteContextQuery={waitForQuoteContextQuery} />
                      {component}
                    </>
                  )}
                />
              </MemoryRouter>
            </DialogProvider>
          </SetActiveQuoteContext>
        </ActiveQuoteProvider>
      </Provider>
    </MockedProvider>
  );

  if (useThemeProvider) {
    const theme = supportedThemes[0];
    const meplaTheme = getMeplaTheme(theme);
    loadTheme(meplaTheme.FabricTheme);

    return (
      <JssThemeProvider theme={meplaTheme}>
        <GlobalStyles />
        {providers}
      </JssThemeProvider>
    );
  } else {
    return providers;
  }
};

export const renderWithMockedProvider = async (
  children: React.ReactElement,
  waitForQuoteContextQuery?: boolean,
  overrideProps?: ComponentWithMockedProvidersProps
) => {
  const rendered = render(
    <MockedProviders {...overrideProps} waitForQuoteContextQuery={waitForQuoteContextQuery}>
      {children}
    </MockedProviders>
  );
  if (waitForQuoteContextQuery) {
    await rendered.findByText('Quote Context Query Loaded!!!!');
    return { ...rendered };
  }
  return { ...rendered };
};

/**
 * Shuffles array content
 *
 * @param array
 */
export const shuffleArray = (array: any[]) => {
  const shuffledArray = [...array];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

export const dialogProviderProps = {
  isHelpPanelFlightEnabled: false,
  isHelpPanelOpen: false,
  helpPanelWidth: 0,
  dispatch: (() => {}) as any,
};
