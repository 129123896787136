import {
  ChoiceGroup,
  IChoiceGroupOptionStyleProps,
  IChoiceGroupOptionStyles,
  IChoiceGroupProps,
} from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export interface ChoiceGroupAtomProps extends IChoiceGroupProps {
  // TODO: jepagan - rename to addClass
  className?: string;
  dataAutomationId?: string;
}

const styles = (theme: ThemeProps) => {
  return {
    field: {
      '&:before': {
        borderColor: theme.palette.textTertiary,
      },
      '&:hover:after': {
        backgroundColor: theme.palette.backgroundDivider,
      },
    },
    fieldChecked: {
      '&:before, &:after': {
        borderColor: theme.palette.secondary,
      },
      '&:hover:before, &:hover:after': {
        borderColor: theme.palette.textLinkHover,
      },
    },
    fieldDisabled: {
      '&:before': {
        borderColor: theme.palette.backgroundDivider,
      },
      '&:hover:after': {
        backgroundColor: 'transparent',
      },
    },
    fieldReadOnly: {
      '&:before, &:after': {
        borderColor: theme.palette.text,
        color: theme.palette.text,
      },
      '&:hover:before, &:hover:after': {
        borderColor: theme.palette.text,
        color: theme.palette.text,
      },
      '& .ms-ChoiceFieldLabel': {
        color: theme.palette.text,
      },
    },
    labelDisabled: {
      '& .ms-Label': {
        color: theme.palette.text,
      },
    },
  };
};

type Props = ChoiceGroupAtomProps & WithStyles<typeof styles>;

const ChoiceGroupAtomUnstyled: React.FC<Props> = props => {
  const { dataAutomationId, classes, ...remainingProps } = props;
  const optionStyles = (stylesProps: IChoiceGroupOptionStyleProps): IChoiceGroupOptionStyles => ({
    field:
      stylesProps.checked && !stylesProps.disabled && !remainingProps.readOnly
        ? classes.fieldChecked
        : stylesProps.disabled && !remainingProps.readOnly
        ? classes.fieldDisabled
        : remainingProps.readOnly
        ? classes.fieldReadOnly
        : classes.field,
  });

  const optionsStyled =
    props.options &&
    props.options.map(option => ({
      ...option,
      styles: (stylesProps: IChoiceGroupOptionStyleProps) => {
        switch (typeof option.styles) {
          case 'function':
            return { ...optionStyles(stylesProps), ...option.styles(stylesProps) };
          case 'object':
            return { ...optionStyles(stylesProps), ...option.styles };
          case 'undefined':
          default:
            return optionStyles(stylesProps);
        }
      },
    }));
  if (remainingProps.readOnly) {
    return (
      <ChoiceGroup
        data-automation-id={dataAutomationId}
        {...remainingProps}
        options={optionsStyled}
        styles={{ applicationRole: classes.labelDisabled }}
      />
    );
  }
  return (
    <ChoiceGroup
      data-automation-id={dataAutomationId}
      styles={{ applicationRole: classes.labelDisabled }}
      {...remainingProps}
      options={optionsStyled}
    />
  );
};

export const ChoiceGroupAtom = withStyles(styles)(ChoiceGroupAtomUnstyled);
