import { ThemeProps } from 'styles';

export const affiliateDialogStyles = (theme: ThemeProps) => ({
  checkbox: {
    paddingTop: 8,
  },
  definition: {
    display: 'flex',
    flexDirection: 'column',
  },
  definitionHeader: {
    color: theme.palette.textTertiary,
    fontWeight: theme.fonts.fontWeights.semiBold,
    paddingBottom: 3,
    paddingTop: 30,
  },
  definitionText: {
    color: theme.palette.textTertiary,
    paddingRight: 94,
  },
  definitionSpacing: {
    paddingTop: 8,
  },
  infoHeader: {
    display: 'flex',
    paddingTop: 12,
  },
  infoIcon: {
    color: theme.palette.textSecondary,
    paddingRight: 5,
    marginTop: 2,
  },
  infoHeaderText: {
    color: theme.palette.textSecondary,
  },
  infoBody: {
    paddingLeft: 20,
    paddingRight: 94,
  },
  infoText: {
    color: theme.palette.textTertiary,
  },
});
