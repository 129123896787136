import { mergeClassNames } from 'components/utilities';
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsHeaderProps,
  IDetailsListProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IGroup,
  IGroupRenderProps,
  IRenderFunction,
  Selection as FabricSelection,
  SelectionMode,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { detailsListStyles } from './DetailsListAtom.styles';

/**
 * Props for DetailsList Atom Component
 *
 * @prop {string} [ariaLabelForSelectionColumn] - An ARIA label for the name of the selection column, for localization.
 * @prop {string} [ariaLabelForSelectionAllCheckbox] - An ARIA label for the name of the select all checkbox for a grouped list.
 * @prop {string} [ariaLabelForSelectionAllCheckbox] - An ARIA label for the checkbox selection of a grouped list.
 * @prop {CheckboxVisibility}  checkboxVisibility - Controls the visibility of selection check box.
 * @prop {IColumn[]} columns - Given column definitions. If none are provided, default columns will be created based on the item's properties.
 * @prop {ConstrainMode} [constrainMode] - Controls how list controls overflow constrains.
 * @prop {boolean} [disableSelectionZone] - Whether or not to disable the built-in SelectionZone, so the host component can provide its own.
 * @prop {any[]} items -  The items to render.
 * @prop {DetailsListLayoutMode} [layoutMode] - Controls how the columns are adjusted.
 * @prop {string} [mainClass] - Optional class name for override that would be applied to the root element.
 * @prop {Selection} [selection] - Optional selection model to track selection state.
 * @prop {SelectionMode}  selectionMode - Controls how/if the details list manages selection. Options include none, single, multiple.
 * @prop {function} [onRenderDetailsFooter] - An Override For DetailsFooter, useful for showing Errors or Shimmer.
 * @prop {function} [onRenderDetailsHeader] - An Override For DetailsHeader, useful for Sticky Headers.
 * @prop {IGroup[]} groups - used to group items together in the list
 * @prop {groupProps} groupProps - group render properties needed when using a grouped list
 * @prop {function} [onRenderItemColumn] - function to define how columns are rendered.
 */

export interface DetailsListAtomProps {
  ariaLabelForSelectionColumn?: string;
  ariaLabelForSelectAllCheckbox?: string;
  ariaLabelForCheckBox?: string;
  checkboxVisibility: CheckboxVisibility;
  columns?: IColumn[];
  compact?: boolean;
  constrainMode?: ConstrainMode;
  disableSelectionZone?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  layoutMode?: DetailsListLayoutMode;
  mainClass?: string;
  selection?: FabricSelection;
  selectionMode: SelectionMode;
  isHeaderVisible?: boolean;
  onRenderDetailsFooter?: () => JSX.Element | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRenderItemColumn?: (item?: any, index?: number, column?: IColumn) => React.ReactNode;
  onRenderDetailsHeader?: (
    props?: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ) => JSX.Element | null;
  groups?: IGroup[];
  groupProps?: IGroupRenderProps;
  onRenderMissingItem?: () => React.ReactNode;
  onRenderRow?: IRenderFunction<IDetailsRowProps>;
}

type Props = DetailsListAtomProps & WithStyles<typeof detailsListStyles>;

const DetailsListAtomUnstyled: React.FC<Props> = (props: Props) => {
  const mergedClassName = mergeClassNames([props.classes.listStyles, props.mainClass || '']);
  const groupedMergeClassName = mergeClassNames([
    mergedClassName,
    props.classes.groupedListStyles,
    props.classes.listCellStyles,
  ]);
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const columns =
    props.columns &&
    props.columns.map(column => ({
      ...column,
      styles: { ...column.styles, cellName: props.classes.cellName },
    }));

  const onRenderRow: IDetailsListProps['onRenderRow'] = detailsRowProps => {
    const customStyles: Partial<IDetailsRowStyles> = { root: props.classes.detailsRowRoot };

    if (detailsRowProps) {
      return <DetailsRow {...detailsRowProps} styles={customStyles} />;
    }
    return null;
  };

  return (
    <DetailsList
      ariaLabelForSelectAllCheckbox={props.ariaLabelForSelectAllCheckbox}
      ariaLabelForSelectionColumn={props.ariaLabelForSelectionColumn}
      checkboxVisibility={props.checkboxVisibility}
      checkButtonAriaLabel={props.ariaLabelForCheckBox}
      className={props.groups ? groupedMergeClassName : mergedClassName}
      columns={columns}
      compact={props.compact}
      constrainMode={props.constrainMode}
      disableSelectionZone={props.disableSelectionZone}
      enterModalSelectionOnTouch
      groupProps={props.groupProps}
      groups={props.groups}
      isHeaderVisible={props.isHeaderVisible !== false}
      items={props.items}
      layoutMode={props.layoutMode}
      selection={props.selection}
      //TODO: pass this as a prop
      selectionMode={props.selectionMode}
      selectionPreservedOnEmptyClick
      setKey="list"
      skipViewportMeasures={isChrome}
      onRenderDetailsFooter={props.onRenderDetailsFooter}
      onRenderDetailsHeader={props.onRenderDetailsHeader}
      onRenderItemColumn={props.onRenderItemColumn}
      onRenderMissingItem={props.onRenderMissingItem}
      onRenderRow={props.onRenderRow || onRenderRow}
    />
  );
};

export const DetailsListAtom = withStyles(detailsListStyles)(DetailsListAtomUnstyled) as React.FC<
  DetailsListAtomProps
>;
