import * as customerActions from 'features/customer/actions';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import { combineReducers } from 'redux';
import { Approval } from 'services/approval/types';
import { CreditCheckEventType, CreditInfo } from 'services/credit/types';
import { Empowerments } from 'services/empowerment/types';
import { ExternalUser } from 'services/externaluser/types';
import {
  PricingLineItem,
  Proposal,
  ProposalSummary,
  SearchProposalsSortField,
  SearchSortOrder,
} from 'services/proposal/types';
import { PurchaseRecordSummary } from 'services/purchase/types';
import { getUnique } from 'services/utils';
import { AsyncState } from 'store/types';
import { ActionType, createReducer, getType } from 'typesafe-actions';

import * as actions from './actions';
import { QualifyingSkuAvailability } from './components';
import * as initial from './initial';
import {
  EditorSummaryList,
  EmpowermentsList,
  EnrollmentFieldView,
  OpenCard,
  PriceMap,
  SkuTitleMap,
} from './types';
import { generateEmpowermentsKey, generateProductIdentifierKey } from './utils';

export const reducer = combineReducers({
  proposals: combineReducers({
    fragments: combineReducers({
      indexed: createReducer<Record<string, ProposalSummary>, ActionType<typeof actions>>({})
        .handleAction(actions.searchProposalFragmentsAsync.success, (state, action) => {
          const updatedEntities = action.payload.reduce(
            (proposals: { [id: string]: ProposalSummary }, proposal: ProposalSummary) => {
              if (
                !proposals[proposal.id] ||
                proposals[proposal.id].header.modifiedDate !== proposal.header.modifiedDate
              )
                proposals[proposal.id] = proposal;
              return proposals;
            },
            {}
          );

          return Object.keys(updatedEntities).length
            ? {
                ...state,
                ...updatedEntities,
              }
            : state;
        })
        .handleAction(actions.deleteProposalAsync.success, (state, action) => {
          const { [action.payload.id]: deleted, ...stateWithoutDeleted } = state;
          return stateWithoutDeleted;
        }),

      ordered: createReducer<string[], ActionType<typeof actions>>([])
        .handleAction(actions.searchProposalFragmentsAsync.success, (state, action) =>
          action.payload.map(proposal => proposal.id)
        )
        .handleAction(actions.deleteProposalAsync.success, (state, action) =>
          state.filter(id => id !== action.payload.id)
        )
        .handleAction(actions.searchProposalFragmentsAsync.request, () => []),
    }),
    entities: combineReducers({
      indexed: createReducer<Record<string, Proposal>, ActionType<typeof actions>>({})
        .handleAction(
          [
            actions.createLineItemsAsync.success,
            actions.deleteLineItemAsync.success,
            actions.deleteLineItemsAsync.success,
            actions.loadProposalAsync.success,
            actions.performProposalActionAsync.success,
            actions.updateQuoteAsync.success,
            actions.performMultipleProposalActionAsync.success,
            actions.patchQuoteHeaderAsync.success,
          ],
          (state, action) =>
            !state[action.payload.id] || state[action.payload.id].etag !== action.payload.etag
              ? {
                  ...state,
                  [action.payload.id]: action.payload,
                }
              : state
        )
        .handleAction(actions.deleteProposalAsync.success, (state, action) => {
          const { [action.payload.id]: deleted, ...stateWithoutDeleted } = state;
          return stateWithoutDeleted;
        }),

      ordered: createReducer<string[], ActionType<typeof actions>>([])
        .handleAction(actions.loadProposalAsync.success, (state, action) => [
          ...state,
          action.payload.id,
        ])
        .handleAction(actions.deleteProposalAsync.success, (state, action) =>
          state.filter(id => id !== action.payload.id)
        ),
    }),
  }),
  approvals: combineReducers({
    entities: combineReducers({
      indexed: createReducer<Record<string, Approval>, ActionType<typeof actions>>({}).handleAction(
        actions.loadApprovalAsync.success,
        (state, action) => {
          if (
            !state[action.payload.id] ||
            state[action.payload.id].updatedDate !== action.payload.updatedDate
          ) {
            return { ...state, [action.payload.id]: action.payload };
          }
          return state;
        }
      ),
    }),
  }),
  views: combineReducers({
    openCard: createReducer<OpenCard | null, ActionType<typeof actions>>(null)
      .handleAction(actions.openConfigCard, (state, action) => action.payload)
      .handleAction(actions.closeConfigCard, () => null),
    enrollmentFieldView: createReducer<EnrollmentFieldView, ActionType<typeof actions>>(
      EnrollmentFieldView.ComboBox
    ).handleAction(actions.setEnrollmentFieldView, (state, action) => action.payload),
    activeId: createReducer<string, ActionType<typeof actions>>('')
      .handleAction(actions.loadProposalAsync.request, (state, action) => action.payload)
      .handleAction(actions.createProposalAsync.success, (state, action) => action.payload.id)
      .handleAction(actions.deleteProposalAsync.success, (state, action) =>
        state === action.payload.id ? '' : state
      )
      .handleAction(actions.clearActiveQuote, () => ''),
    editor: combineReducers({
      //TODO: needs tests
      skuTitles: createReducer<SkuTitleMap, ActionType<typeof actions>>({}).handleAction(
        actions.addSkuTitle,
        (state, action) => {
          const skuTitleMap: SkuTitleMap = {};
          const skuAvailability = action.payload.QualifyingSkuAvailabilities;
          skuTitleMap[action.payload.ProductId] = skuAvailability.map(
            (skuAvailability: QualifyingSkuAvailability) => {
              return { sku: skuAvailability.Sku.SkuId, title: skuAvailability.Title };
            }
          );
          return {
            ...state,
            ...skuTitleMap,
          };
        }
      ),
      productCardIsOpenGQL: createReducer<boolean, ActionType<typeof actions>>(false).handleAction(
        actions.setConfigCardOpenGQL,
        (state, action) => action.payload
      ),
      lineItemPrices: createReducer<PriceMap, ActionType<typeof actions>>({})
        .handleAction(actions.loadPricesAsync.request, (state, action) => {
          const priceMap: PriceMap = {};
          const lineItemsProductIdentifiers = action.payload.lineItems;
          lineItemsProductIdentifiers.forEach((lineItem: PricingLineItem) => {
            const key = generateProductIdentifierKey(lineItem.productIdentifier);
            priceMap[key] = { isLoading: true };
          });
          return {
            ...state,
            ...priceMap,
          };
        })
        .handleAction(actions.loadPricesAsync.success, (state, action) => ({
          ...state,
          ...action.payload,
        })),
      addedLineItemCount: createReducer<number, ActionType<typeof actions>>(0).handleAction(
        actions.updateAddedLineItemCount.success,
        (state, action) => action.payload
      ),
      sections: createReducer<Record<string, boolean>, ActionType<typeof actions>>(initial.sections)
        .handleAction(actions.addProposalSection, (state, action) => ({
          ...state,
          [action.payload]: true,
        }))
        .handleAction(actions.removeProposalSection, (state, action) => ({
          ...state,
          [action.payload]: false,
        })),
      selectedItems: createReducer<string[], ActionType<typeof actions>>([])
        .handleAction(actions.toggleItemSelection, (state, action) => {
          if (action.payload.ids.length === 1) {
            const firstId = action.payload.ids[0];
            const containsId = state.includes(firstId);
            if (action.payload.multi) {
              return containsId ? state.filter(el => el !== firstId) : [...state, firstId];
            }
            return containsId ? [] : [firstId];
          } else {
            const newIds: string[] = [];
            action.payload.ids.forEach(id => {
              return newIds.push(id);
            });
            return newIds;
          }
        })
        .handleAction(actions.clearItemSelection, () => {
          return [];
        }),
      lastSelectedItem: createReducer<string, ActionType<typeof actions>>('')
        .handleAction(actions.toggleItemSelection, (state, action) => {
          return action.payload.ids[0];
        })
        .handleAction(actions.clearItemSelection, () => {
          return '';
        }),
      crmIdToAdd: createReducer<string | null, ActionType<typeof actions>>(null)
        .handleAction(actions.verifyCrmId.request, (state, action) => action.payload)
        .handleAction(actions.addNewCrmId.request, (state, action) => action.payload.crmId)
        .handleAction(actions.removeCRMId, () => null),

      sendingEmailNotification: createReducer<string, ActionType<typeof actions>>('')
        .handleAction(actions.createNotificationAsync.failure, () => 'failure')
        .handleAction(actions.createNotificationAsync.request, () => 'request')
        .handleAction(actions.createNotificationAsync.success, () => 'success')
        .handleAction(actions.clearSendingEmail, () => ''),

      transactingProposal: createReducer<string, ActionType<typeof actions>>('')
        .handleAction(actions.clearTransactingProposal, () => '')
        .handleAction(actions.createPurchaseRecordAsync.failure, () => 'failure')
        .handleAction(actions.createPurchaseRecordAsync.request, () => 'request')
        .handleAction(actions.createPurchaseRecordAsync.success, () => 'success'),

      workAccountResults: createReducer<ExternalUser[], ActionType<typeof customerActions>>([])
        .handleAction(
          customerActions.validateWorkAccountAsync.success,
          (state, action) => action.payload
        )
        .handleAction(customerActions.validateWorkAccountAsync.request, () => []),
    }),

    newProposal: createReducer<AsyncState, ActionType<typeof actions>>(AsyncState.Unset)
      .handleAction(actions.createProposalAsync.success, () => AsyncState.Success)
      .handleAction(actions.createProposalAsync.failure, () => AsyncState.Failure)
      .handleAction(actions.createProposalAsync.request, () => AsyncState.Request)
      .handleAction(actions.resetNewProposalDialog, () => AsyncState.Unset),

    deleteProposal: createReducer<AsyncState, ActionType<typeof actions>>(AsyncState.Unset)
      .handleAction(actions.deleteProposalAsync.failure, () => AsyncState.Failure)
      .handleAction(actions.deleteProposalAsync.request, () => AsyncState.Request)
      .handleAction(actions.deleteProposalAsync.success, () => AsyncState.Success)
      .handleAction(actions.resetDeleteProposalDialog, () => AsyncState.Unset),

    summaryList: createReducer<EditorSummaryList, ActionType<typeof actions>>({
      search: {
        filter: '',
        sortField: SearchProposalsSortField.ModifiedDate,
        sortOrder: SearchSortOrder.Descending,
      },
    })
      .handleAction(actions.searchProposalFragmentsAsync.request, (state, action) =>
        action.payload
          ? {
              ...state,
              search: {
                filter: action.payload.filter === undefined ? '' : action.payload.filter,
                sortField: action.payload.sortField,
                sortOrder: action.payload.sortOrder,
              },
            }
          : state
      )
      .handleAction(actions.setQuoteSearchTerm, (state, action) => {
        return {
          ...state,
          search: {
            ...state.search,
            filter: action.payload || '',
          },
        };
      }),
  }),
  empowerments: createReducer<EmpowermentsList, ActionType<typeof actions>>({})
    .handleAction(actions.loadEmpowermentsAsync.success, (state, action) => {
      const key = generateEmpowermentsKey(action.payload);

      const empowerments = action.payload.empowerments.map<Empowerments>(item => {
        return { id: item.id, email: item.email };
      });
      const approvers = getUnique(empowerments, element => element.email);

      // Avoid overwritting already validated emails
      return state[key]
        ? { ...state, [key]: { ...state[key], approvers } }
        : { ...state, [key]: { approvers } };
    })
    .handleAction(actions.validateEmpowermentAsync.success, (state, action) => {
      const key = generateEmpowermentsKey({
        market: action.payload.marketCode,
        policy: action.payload.policy,
        empowermentLevel: action.payload.empowermentLevel,
      });

      const newState = { ...state };

      if (state[key] === undefined) {
        newState[key] = {
          validated: { [action.payload.email]: action.payload.isEmpowered },
          approvers: [],
        };
        return newState;
      }

      newState[key] = newState[key] ? { ...newState[key] } : { approvers: [] };
      newState[key].validated = newState[key].validated ? { ...newState[key].validated } : {};
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newState[key].validated![action.payload.email] = action.payload.isEmpowered;
      return newState;
    }),

  // [OrganizationId]: {[Currency]:CreditInfo}
  credit: createReducer<Record<string, Record<string, CreditInfo>>, ActionType<typeof actions>>(
    {}
  ).handleAction(
    [
      actions.loadCreditInfoAsync.success,
      actions.requestCreditLineAsync.success,
      actions.requestCreditIncreaseAsync.success,
    ],
    (state, action) => {
      let newState = { ...state };

      if (action.type === getType(actions.loadCreditInfoAsync.success)) {
        newState = {
          ...state,
          [action.payload.creditInfo.organizationId]: {
            ...state[action.payload.creditInfo.organizationId],
            [action.payload.currency || DEFAULT_CURRENCY]: action.payload.creditInfo,
          },
        };
      } else {
        // Optimistically update credit info to pending as immediate call to credit check will not return processed info
        // It returns no_pie_on_file.
        newState = {
          ...state,
          [action.payload.creditInfo.organizationId]: {
            ...state[action.payload.creditInfo.organizationId],
            [action.payload.currency || DEFAULT_CURRENCY]: {
              organizationId: action.payload.creditInfo.organizationId,
              // eslint-disable-next-line @typescript-eslint/camelcase
              event_type: CreditCheckEventType.CreditLookUp,
              decision: 'rejected',
              reasons: { reason: 'pendingreview' },
            },
          },
        };
      }

      return newState;
    }
  ),
  purchaseSummary: createReducer<Record<string, PurchaseRecordSummary>, ActionType<typeof actions>>(
    {}
  ).handleAction(actions.loadPurchaseRecordAsync.success, (state, action) => ({
    ...state,
    [action.payload.id]: action.payload,
  })),
});

export default reducer;
