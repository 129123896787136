import {
  ComboBox,
  IButtonStyles,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  IComboBoxStyles,
  IIconStyles,
  IRefObject,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { comboBoxAtomStyles } from './ComboBoxAtom.styles';

/**
 * Exposing properties from the Office Fabric ComboBox component
 * @see {@link https://developer.microsoft.com/en-us/fabric#/components/ComboBox} for more information
 * @prop {boolean} [defaultSelectedKey] - Default Selected Key for the ComboBox, do not use it together with selectedKey
 * @prop {boolean} [selectedKey] - selected key for the ComboBox typically used in controlled components, do not use it together with defaultSelectedKey
 * @prop {IRefObject<IComboBox>} [componentRef] - callback to access the IComboBox interface. Use this instead of ref for accessing the public methods and properties of the component.
 */
export interface ComboBoxAtomProps extends IComboBoxProps {
  allowFreeform?: boolean;
  ariaLabel?: string;
  autoComplete?: 'on' | 'off';
  dataAutomationId?: string;
  disabled?: boolean;
  defaultSelectedKey?: string;
  componentRef?: IRefObject<IComboBox>;
  errorMessage?: string;
  id?: string;
  isSearch?: boolean;
  label?: string;
  dropDownWidth?: number;
  maxHeight?: number;
  placeholder?: string;
  required?: boolean;
  styleContainer?: string;
  styleErrorMessage?: string;
  styles?: Partial<IComboBoxStyles>;
  text?: string;
  selectedKey?: string;
  selectedKeys?: string[];
  onItemClick?: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => void;
  onChange?: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => void;
}

type Props = ComboBoxAtomProps & WithStyles<typeof comboBoxAtomStyles>;

const ComboBoxUnstyled: React.FC<Props> = props => {
  const { classes, dataAutomationId, ...remainingProps } = props;
  const comboBoxStyles: Partial<IComboBoxStyles> = {
    container: props.styleContainer,
    errorMessage: `${classes.errorMessage} ${props.styleErrorMessage}`,
    callout: classes.callout,
    rootError: classes.rootError,
    input: classes.input,
    inputDisabled: classes.inputDisabled,
    optionsContainer: { maxHeight: props.maxHeight || 'none' },
    root: classes.root,
    rootDisabled: classes.rootDisabled,
    ...props.styles,
  };

  const buttonStyles: Partial<IButtonStyles> = {
    rootChecked: classes.rootChecked,
    rootDisabled: classes.buttonDisabled,
    root: {
      display: props.disabled ? 'none' : undefined,
    },
  };

  const buttonIconPropsStyles: IIconStyles = {
    root: classes.searchButtonIcon,
  };

  const dropdownWidthProps = props.dropDownWidth
    ? {
        dropdownWidth: props.dropDownWidth,
      }
    : {
        useComboBoxAsMenuWidth: true,
      };

  return (
    <ComboBox
      {...remainingProps}
      {...dropdownWidthProps}
      ariaLabel={props.ariaLabel || props.label}
      buttonIconProps={
        props.isSearch ? { iconName: 'Search', styles: buttonIconPropsStyles } : undefined
      }
      caretDownButtonStyles={buttonStyles}
      data-automation-id={dataAutomationId}
      selectedKey={props.multiSelect ? props.selectedKeys : props.selectedKey}
      shouldRestoreFocus
      styles={comboBoxStyles}
    />
  );
};

/**
 * Exports the ComboBox component from Office Fabric
 * @see {@link https://developer.microsoft.com/en-us/fabric#/components/ComboBox} for more information
 * @param props
 */
export const ComboBoxAtom = withStyles(comboBoxAtomStyles)(ComboBoxUnstyled) as React.FC<
  ComboBoxAtomProps
>;
