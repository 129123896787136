import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { isTermLineItem } from 'features-apollo/quote/components/utils';
import { isQuoteHRDD as isHRDD } from 'features-apollo/quote/selectors/quote';
import { DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import { ProductAudience, TermLineItem } from 'generated/graphql';
import React, { useContext } from 'react';

import { TermListConnected, TermListProps } from './TermList';

interface TermListContainerProps {
  /**
   * Use to get quote's line items
   */
  quoteId: string;
  /**
   * Detects is drag event is happening.
   * TODO: jepagan - check if this can be detect independently from list component.
   */
  isDragEvent?: boolean;
}

/**
 * Gets data needed for Term List
 *
 * @param props required information to get data
 * @returns Term List component
 */
export const TermListContainer: React.FC<TermListContainerProps> = props => {
  const { activeQuote, loading, availableMutations, availableMutationsStatus } = useContext(
    ActiveQuoteContext
  );
  const isQuoteHRDD = activeQuote ? isHRDD(activeQuote) : false;

  const termListProps: TermListProps = {
    quoteId: props.quoteId,
    billingCurrency: DEFAULT_CURRENCY,
    loading: loading,
    termLineItems: [],
    agreementTermLineItems: [],
    isQuoteReadOnly: false,
    isQuoteHRDD,
    onDelete: availableMutations && availableMutations.removeLineItems,
    addLineItemStatus: availableMutationsStatus && availableMutationsStatus.addLineItems,
  };
  const govAudience = [ProductAudience.DirectGov, ProductAudience.IndirectGov];

  if (activeQuote) {
    termListProps.isQuoteReadOnly = activeQuote.readOnly;
    termListProps.billingCurrency = activeQuote.billingCurrency;
    termListProps.isGovSegmentQuote = govAudience.includes(activeQuote.productAudience);

    if (activeQuote.lineItems.length) {
      activeQuote.lineItems.forEach(lineItem => {
        // Work-around to access typename field on interface type
        // more information: https://github.com/dotansimha/graphql-code-generator/issues/3417
        // @ts-ignore
        if (isTermLineItem(lineItem)) {
          termListProps.termLineItems.push(lineItem as TermLineItem);
        }
      });
    }
    if (activeQuote.agreements && activeQuote.agreements.edges.length) {
      activeQuote.agreements.edges.forEach(edge => {
        const agreement = edge.node;
        const agreementTermLineItem = {
          id: `agreement-${agreement.number}`,
          startDate: agreement.startEffectiveDate,
          endDate: agreement.endEffectiveDate,
          documentDisplayUri: agreement.documentDisplayUri,
          customerId: agreement.customerId,
          documentFileName: agreement.documentFileName,
          participants: agreement.participants,
          type: 'Upload',
        };
        termListProps.agreementTermLineItems.push(agreementTermLineItem);
      });
    }
  }

  return <TermListConnected {...termListProps} dndEnabled={true} isDragEvent={props.isDragEvent} />;
};
