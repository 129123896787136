import { setUserTheme, loadPhotoAsync } from 'features/user/actions';
import { Action, Middleware } from 'redux';
import { isActionOf } from 'typesafe-actions';
import loggerService from 'services/logger-service';

// actions that we want to ignore while logging errors and failures.
const excludedActionFailures = [loadPhotoAsync.failure];

export const appInsightsMiddleware: Middleware = () => next => (action: Action<string>) => {
  if (
    (action.type.includes('_ERROR') || action.type.includes('_FAILURE')) &&
    !isActionOf(excludedActionFailures, action)
  ) {
    const error = new Error(`async action failure - ${action.type}.`);
    loggerService.error({
      error,
    });
  }
  if (isActionOf(setUserTheme, action)) {
    loggerService.log({
      name: 'user set theme',
      properties: { theme: action.payload },
    });
  }
  next(action);
};
