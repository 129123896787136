import { CalloutCalendarProps } from 'components/ions';
import { CalendarDropdown } from 'components/molecules';
import { calendarStrings } from 'components/utilities/calendarStrings';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { CeilingSectionProps } from './';
import { discountCardCeilingStyles } from './DiscountCardCeilingSection.styles';

type Props = CeilingSectionProps & WithStyles<typeof discountCardCeilingStyles>;

const DiscountCardCeilingUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { dispatches: sectionDispatches, options: sectionOptions } = props;
  const today = props.today || new Date();

  const priceCeilingCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'price-ceiling-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: props.disabled,
    },
    calendarStrings,
    isDayPickerVisible: true,
    defaultDate: sectionOptions.priceCeilingDates.selected,
    minDate: sectionOptions.priceCeilingDates.minDate,
    maxDate: sectionOptions.priceCeilingDates.maxDate,
    onSelectDate: props.dispatches.onPriceCeilingDateAdded,
    today,
    showTodayButton: true,
  };
  const startFromCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'start-ceiling-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: !props.options.startCalendarEnabled || props.disabled,
    },
    calendarStrings,
    isDayPickerVisible: sectionOptions.startDayPickerVisible,
    defaultDate: sectionOptions.startDates.selected,
    minDate: sectionOptions.startDates.minDate,
    maxDate: sectionOptions.startDates.maxDate,
    today,
    onSelectDate: props.dispatches.onStartDateAdded,
    showTodayButton: sectionOptions.startDayPickerVisible,
  };
  const goodForCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'good-for-ceiling-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: !props.options.endCalendarEnabled || props.disabled,
    },
    calendarStrings,
    isDayPickerVisible: sectionOptions.endDayPickerVisible,
    defaultDate: sectionOptions.endDates.selected,
    minDate: sectionOptions.endDates.minDate,
    maxDate: sectionOptions.endDates.maxDate,
    today,
    onSelectDate: props.dispatches.onEndDateAdded,
    showTodayButton: false,
  };

  return (
    <div className={props.classes.contentContainer}>
      <div className={props.classes.calendarDropdownMargin}>
        <CalendarDropdown
          calloutCalendarProps={priceCeilingCalendarProps}
          dataAutomationId="priceCeilingCalendar"
          dropdownDisabled={props.disabled}
          dropdownOptions={sectionOptions.priceCeilingOptions.options}
          id="PriceCeilingCalendar"
          label={t('quote::Price Ceiling')}
          selected={sectionOptions.priceCeilingOptions.selectedKey}
          onDropdownSelect={sectionDispatches.onPriceCeilingSelectionChanged}
        />
      </div>
      <div className={props.classes.calendarDropdownMargin}>
        <CalendarDropdown
          calloutCalendarProps={startFromCalendarProps}
          dataAutomationId="priceCeilingStartFromCalendar"
          dropdownDisabled={props.disabled}
          dropdownOptions={sectionOptions.startOptions.options}
          label={t('quote::Start From')}
          required={true}
          selected={sectionOptions.startOptions.selectedKey}
          onDropdownSelect={sectionDispatches.onStartSelectionChanged}
        />
      </div>
      <CalendarDropdown
        calloutCalendarProps={goodForCalendarProps}
        dataAutomationId="priceCeilingGoodForCalendar"
        dropdownDisabled={props.disabled}
        dropdownOptions={sectionOptions.endOptions.options}
        label={t('quote::Good For')}
        required={true}
        selected={sectionOptions.endOptions.selectedKey}
        onDropdownSelect={sectionDispatches.onEndSelectionChanged}
      />
    </div>
  );
};

export const DiscountCardCeilingSection = withStyles(discountCardCeilingStyles)(
  DiscountCardCeilingUnstyled
);
