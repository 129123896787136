import { Dialog, LinkButton, PrimaryButton } from 'components';
import { addVatIdAsync, removeVatIdAsync } from 'features/customer/actions';
import { getOrganization, getVatId, processingAddVatId } from 'features/customer/selectors';
import { VatId } from 'features/proposal/components';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddTaxId, RemoveTaxId, RemoveTaxIdRequest } from 'services/customer/types';
import { RootState } from 'store/types';
import { DialogContext, DialogProps as DialogStyleProps, FontSizes } from 'styles';

export const VatIdHeaderString = () => i18next.t('quote::Value added tax identification number');

export interface VatIdDialogProps {
  accountId?: string;
  organizationId?: string;
  initialVatId?: string;
}

const mapStateToProps = (state: RootState, ownProps: VatIdDialogProps) => {
  const organization = ownProps.organizationId && getOrganization(state, ownProps.organizationId);
  const address = organization && organization.legalEntity.address;
  return {
    vatId: getVatId(state),
    organization,
    address,
    errorAddingVatId: processingAddVatId(state).error,
    loadingAddingVatId: processingAddVatId(state).loading,
  };
};

const dispatchProps = {
  removeVatId: removeVatIdAsync.request,
  addVatId: addVatIdAsync.request,
};

type DialogProps = VatIdDialogProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export interface VatIdBodyProps extends DialogProps {
  isSaveVatIdEnabled: boolean;
  enableSaveVatId: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VatIdBodyUnconnected: React.FC<VatIdBodyProps> = props => {
  const {
    initialVatId,
    errorAddingVatId,
    loadingAddingVatId,
    isSaveVatIdEnabled,
    removeVatId,
    addVatId,
    enableSaveVatId,
    accountId,
    organizationId,
    vatId,
    organization,
    address,
  } = props;

  const [lastVerified, setLastVerified] = React.useState<string | undefined>();
  const [isClear, setIsClear] = React.useState<boolean>(!!(isSaveVatIdEnabled && lastVerified));

  React.useEffect(() => {
    if (errorAddingVatId) {
      enableSaveVatId(false);
    } else {
      enableSaveVatId(true);
    }
  }, [errorAddingVatId, enableSaveVatId]);

  const onVerify = (value: string) => {
    if (accountId && organizationId && organization && address) {
      const addId: AddTaxId = {
        accountId,
        organizationId,
        organization,
        request: {
          id: value,
          country: address.country,
        },
      };
      addVatId(addId);
    }
    setLastVerified(value);
  };

  const onChange = (newValue?: string) => {
    if (!newValue) {
      enableSaveVatId(true);
      setIsClear(true);
      if (vatId && accountId && organizationId) {
        const request: RemoveTaxIdRequest = {
          taxId: vatId.id,
          taxIdReference: vatId.taxIdReference,
          endDate: new Date(),
        };
        const removeId: RemoveTaxId = {
          accountId,
          organizationId,
          request,
        };
        removeVatId(removeId);
      }
    }
    setIsClear(false);
  };

  return (
    <VatId
      isClear={isClear}
      isError={errorAddingVatId}
      isLoading={loadingAddingVatId}
      lastVerified={lastVerified}
      vatNumber={initialVatId}
      onChange={onChange}
      onVerify={onVerify}
    />
  );
};

export const VatIdBody = connect(mapStateToProps, dispatchProps)(VatIdBodyUnconnected);

const VatIdDialogUnconnected: React.FC<VatIdDialogProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const [isSaveVatIdEnabled, setIsSaveVatIdEnabled] = React.useState<boolean>(false);

  const closeDialog = () => {
    context.closeDialog();
  };

  const saveButton = [
    <PrimaryButton
      dataAutomationId="vatIdSaveButton"
      disabled={!isSaveVatIdEnabled}
      key="vatIdSaveButton"
      text={t('quote::Save')}
      onClick={closeDialog}
    />,
  ];

  return (
    <Dialog
      dataAutomationId="vatIdDialog"
      footerButtons={saveButton}
      height={isSaveVatIdEnabled ? 363 : 403}
      title={t('quote::Value added tax identification number')}
      width={600}
    >
      <VatIdBody {...props} enableSaveVatId={setIsSaveVatIdEnabled} isSaveVatIdEnabled />
    </Dialog>
  );
};

export const VatIdDialog = connect(mapStateToProps, dispatchProps)(VatIdDialogUnconnected);

export const openVatIdDialog = (
  context: {
    openDialog: (dialogProps: DialogStyleProps) => void;
    closeDialog: () => void;
  },
  props: VatIdDialogProps
) => {
  const dialogProps: DialogStyleProps = {
    providedDialog: <VatIdDialog {...props} />,
  };
  context.openDialog(dialogProps);
};

export interface VatIdLinkButtonProps extends VatIdDialogProps {
  size: keyof FontSizes;
}

export const VatIdLinkButton: React.FC<VatIdLinkButtonProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();
  const { size, ...dialogProps } = props;
  return (
    <LinkButton
      displayText={t('quote::add VAT number')}
      size={size}
      onClick={() => openVatIdDialog(context, dialogProps)}
    />
  );
};
