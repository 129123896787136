import { ShimmerAtom, ShimmerProps } from 'components/atoms';
import React from 'react';
import { ThemeProps } from 'styles';
import { useTheme } from 'theming';

export const ShimmerForBackgroundCommon: React.FC<ShimmerProps> = props => {
  // On theme changes, the recreation of the classnames is causing the rerender.
  const theme: ThemeProps = useTheme() as ThemeProps;

  return (
    <ShimmerAtom
      className={props.className}
      height={props.height}
      shimmerColors={{
        shimmer: theme.palette.shimmerWave,
        shimmerWave: theme.palette.shimmerWaveBackgroundCommon,
        background: theme.palette.backgroundCommon,
        ...props.shimmerColors,
      }}
      shimmerElements={props.shimmerElements}
      width={props.width}
    />
  );
};

export const ShimmerForBackgroundStandout: React.FC<ShimmerProps> = props => {
  // On theme changes, the recreation of the classnames is causing the rerender.
  const theme: ThemeProps = useTheme() as ThemeProps;

  return (
    <ShimmerAtom
      className={props.className}
      height={props.height}
      shimmerColors={{
        shimmer: theme.palette.backgroundCommon,
        shimmerWave: theme.palette.shimmerWave,
        background: theme.palette.backgroundStandout,
        ...props.shimmerColors,
      }}
      styles={props.styles}
      shimmerElements={props.shimmerElements}
      width={props.width}
    />
  );
};
