import { ApolloQueryResult } from 'apollo-client';
import { BarButton } from 'components';
import { Referral } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext, DialogProps } from 'styles';

import { SortQuery } from '../../ReferralSummaryList/ReferralSummaryList';
import { DeleteReferralDialog } from './DeleteReferralDialog';

export interface DeleteReferralButtonProps {
  referral?: Referral;
  disabled?: boolean;
  provideCallback?: (callbackToProvide: () => void) => void;
  dataAutomationId?: string;
  getReferralsVariables: {
    query: string;
    options: { referralFilter: string; sort: SortQuery };
  };
  refetchReferrals: (
    variables?: Record<string, any> | undefined
  ) => Promise<
    ApolloQueryResult<{
      getReferrals: Referral[];
    }>
  >;
}

export const DeleteReferralBarButton: React.FC<DeleteReferralButtonProps> = props => {
  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  const dialogProps: DialogProps = {
    providedDialog: (
      <DeleteReferralDialog
        getReferralsVariables={props.getReferralsVariables}
        referral={props.referral}
        refetchReferrals={props.refetchReferrals}
      />
    ),
  };

  const handleOnClick = () => {
    if (!props.disabled) {
      context.openDialog(dialogProps);
    }
  };

  if (props.provideCallback) {
    props.provideCallback(handleOnClick);
  }

  return (
    <BarButton
      ariaLabel={t('quote::Delete referral')}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconName="Delete"
      id="deleteReferralButton"
      text={t('quote::Delete')}
      onClick={handleOnClick}
    />
  );
};
