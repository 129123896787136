import {
  BorderlessButton,
  BorderlessSearch,
  LinkButton,
  RenderIfExistsSpan,
  Spinner,
  TextBodyLarge,
  TextTitleSecondary,
  TextWatermark,
} from 'components';
import { ProgressIndicatorAtom } from 'components/atoms';
import { SectionSeparator } from 'components/ions/SectionSeparator';
import { ActiveQuoteContext, GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import {
  openDeleteProductGroupDialog,
  openRenameProductGroupDialog,
} from 'features-apollo/components/dialogs/FavoriteProductDialogs';
import { FavoriteButton } from 'features-apollo/components/Favorites';
import {
  Entities,
  ExtendedDragDropProps,
  FinderItem,
  FinderItemType,
  ItemMap,
} from 'features-apollo/quote/components/Finder/DragAndDrop';
import { FinderList } from 'features-apollo/quote/components/Finder/FinderList';
import { CreateOrUpdateModernAgreement } from 'features-apollo/quote/components/Lists/queries';
import {
  GET_QUOTERECOMMENDATIONS,
  SEARCH_PRODUCTS_FINDER,
} from 'features-apollo/quote/components/queries';
import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { getFlightIsEnabled } from 'features/app/selectors';
import { ProductType } from 'features/catalog';
import { SearchResultProduct } from 'features/catalog/types';
import * as customerSelectors from 'features/customer/selectors';
import { ConvertAssetDialog } from 'features/proposal/components/Dialogs';
import { productButtonKeyboardNavigation } from 'features/proposal/components/Finder/keyboardNavigation';
import * as selectors from 'features/proposal/selectors';
import {
  AgreementTypes,
  BaseRecommendationAction,
  CatalogContext,
  NationalCloud,
  ProductConnection,
  ProductFamily as ProductFamilyGql,
  ProductRecommendationAction,
  RecommendationGroup,
  UploadRecommendationAction,
} from 'generated/graphql';
import { Icon, IProgressIndicatorStyles, ISearchBox } from 'office-ui-fabric-react';
import React, { useCallback, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { ProductIdentifier } from 'services/proposal/types';
import { ProductGroups } from 'services/user-preferences/types';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';
import { oc } from 'ts-optchain';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';

import { finderStyles } from './Finder.styles';
import { FinderError } from './FinderError';
import { isUploadAgreementDisabled } from './utils';

/**
 * Props for the Finder feature
 * @prop {ProductType} productType - Type of 'Product' in context (Product or Term)
 * @prop {ExtendedDragDropProps} finderListProps - passes the necessary functions and props created in the DragDropContext (Editor) to the child components
 */

export interface FinderProps {
  productType: ProductType;
  finderListProps: ExtendedDragDropProps;
  isDragEvent: boolean;
  preventKeyClick: boolean;
  catalogContext: CatalogContext;
  isLegacyQuote?: boolean;
  searchComponentRef?: React.RefObject<ISearchBox>;
  displayFinderList?: boolean;
  showCloudContext?: boolean;
  addingLineItemsFromDrag?: boolean;
}

const mapStateToProps = (state: RootState) => {
  const activeProposal = selectors.getActiveProposal(state);
  const accountId = selectors.getAccountId(state, activeProposal);

  return {
    assets: accountId ? customerSelectors.getAssetsByAccountId(state, accountId) : undefined,
    assetManagementFlightEnabled: getFlightIsEnabled(state, Flight.assetManagement),
    showECIF: getFlightIsEnabled(state, Flight.ECIF),
    isTermsSearchEnabled: getFlightIsEnabled(state, Flight.termsFinderSearch),
  };
};

type Props = FinderProps & WithStyles<typeof finderStyles> & ReturnType<typeof mapStateToProps>;

const loadingIndicatorStyles: Partial<IProgressIndicatorStyles> = {};
const FinderUnstyled: React.FC<Props> = ({
  productType,
  classes,
  finderListProps,
  assets,
  assetManagementFlightEnabled,
  isDragEvent,
  isLegacyQuote,
  preventKeyClick,
  showECIF,
  searchComponentRef,
  displayFinderList,
  catalogContext,
  isTermsSearchEnabled,
  showCloudContext,
}: Props) => {
  // Recommendations
  const [recoGroups, setRecoGroups] = React.useState<RecommendationGroup[] | undefined>(undefined);
  const [recoProductGroup, setRecoProductGroup] = React.useState<RecommendationGroup | undefined>(
    undefined
  );
  const [recoTermGroup, setRecoTermGroup] = React.useState<RecommendationGroup | undefined>(
    undefined
  );
  const [userFavoriteProductGroups, setFavoriteGroup] = React.useState<ProductGroups | undefined>(
    undefined
  );
  const [recoUploadGroup, setRecoUploadGroup] = React.useState<RecommendationGroup | undefined>(
    undefined
  );

  const activeQuote = useContext(ActiveQuoteContext);
  const isQuoteReadOnly = !!activeQuote.activeQuote?.readOnly;

  const addLineItemStatus = activeQuote.availableMutationsStatus.addLineItems;
  //TODO: refactor if there is a better way to pass in cloud
  const { data, loading: isRecoLoading } = useQuery<QueryGetQuoteData>(GET_QUOTERECOMMENDATIONS, {
    variables: {
      id: activeQuote.quoteId,
      input: catalogContext,
    },
    onCompleted: data => {
      data.getQuote && setRecoGroups(data.getQuote.recommendations);
    },
  });

  useEffect(() => {
    if (data && data.getQuote && data.getQuote.recommendations) {
      setRecoGroups(data.getQuote.recommendations);
    }
  }, [data, catalogContext.nationalCloud]);

  useEffect(() => {
    if (!isLegacyQuote) {
      setRecoProductGroup(
        recoGroups &&
          recoGroups.find(
            recoGroup => recoGroup.type === 'Product' && recoGroup.name === 'Azure Essentials'
          )
      );
    }
    setRecoTermGroup(
      recoGroups &&
        recoGroups.find(recoGroup => recoGroup.type === 'Term' && recoGroup.name === 'Pre-approved')
    );
    setRecoUploadGroup(
      recoGroups &&
        recoGroups.find(
          recoGroup => recoGroup.type === 'Term' && recoGroup.name === 'Agreement types'
        )
    );

    if (recoGroups) {
      const favorites = recoGroups.filter(
        recoGroup => recoGroup.type === 'Product' && recoGroup.name !== 'Azure Essentials'
      );
      const productGroups: ProductGroups = {};

      favorites &&
        favorites.forEach(favorite => {
          const products = favorite.items.map(item => {
            const recommendationItem = item as ProductRecommendationAction;
            return {
              productId: recommendationItem.product.id,
              productIdentifier: {
                productId: recommendationItem.product.id,
              },
              productName: recommendationItem.product.title,
            };
          });
          productGroups[favorite.name] = { products: products };
        });
      setFavoriteGroup(productGroups);
    }
  }, [isLegacyQuote, recoGroups]);

  // Finder Search
  const [searchValue, setSearchValue] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<SearchResultProduct[]>([]);
  const [cursor, setCursor] = React.useState<string>('');
  const [expandSearchAvailable, setExpandSearchAvailable] = React.useState<boolean>(false);
  const [firstExpandSearch, setFirstExpandSearch] = React.useState<boolean>(true);
  const [isSearchLoading, setIsSearchLoading] = React.useState<boolean>(false);
  const [expandSearchProcessing, setExpandSearchProcessing] = React.useState<boolean>(false);
  const [showAgreementError, setShowAgreementError] = React.useState(false);
  const [isAgreementDisabled, setIsAgreementDisabled] = React.useState(
    isUploadAgreementDisabled(oc(activeQuote).activeQuote())
  );

  const { t } = useTranslation();

  const [createOrUpdateModernAgreement, { data: agreementData }] = useMutation(
    CreateOrUpdateModernAgreement,
    {
      refetchQueries: () => [{ query: GET_QUOTE, variables: { id: activeQuote.quoteId } }],
      onError: () => {
        setShowAgreementError(true);
        setIsAgreementDisabled(false);
      },
    }
  );

  useEffect(() => {
    if (agreementData && agreementData.createOrUpdateModernAgreement) {
      setShowAgreementError(false);
    }
  }, [agreementData]);

  useEffect(() => {
    setIsAgreementDisabled(isUploadAgreementDisabled(oc(activeQuote).activeQuote()));
  }, [activeQuote]);

  const [searchProductsFinder, { loading: isSearchProductsLoading }] = useLazyQuery(
    SEARCH_PRODUCTS_FINDER,
    {
      onCompleted: data => {
        const searchProduct: ProductConnection = data.searchProducts;
        const results: SearchResultProduct[] = searchProduct
          ? searchProduct.edges.map(edge => {
              const product = {
                productId: oc(edge).node.id(''),
                productIdentifier: {
                  productId: oc(edge).node.id(''),
                  productType: oc(edge).node.productType(''),
                },
                productName: oc(edge).node.title(''),
              };

              return product;
            })
          : [];

        if (expandSearchAvailable && searchResults.length > 0) {
          const mergedResults = searchResults.concat(results);
          setSearchResults(mergedResults);
        } else {
          setSearchResults(results);
        }

        if (data.searchProducts.pageInfo.cursor) {
          setCursor(data.searchProducts.pageInfo.cursor);
          setExpandSearchAvailable(true);
        } else {
          setExpandSearchAvailable(false);
        }
      },
    }
  );

  useEffect(() => {
    if (firstExpandSearch) {
      setIsSearchLoading(isSearchProductsLoading);
    } else {
      setExpandSearchProcessing(isSearchProductsLoading);
    }
  }, [isSearchProductsLoading, firstExpandSearch]);

  const onClearSearch = () => {
    setSearchResults([]);
    setCursor('');
    setExpandSearchAvailable(false);
    setFirstExpandSearch(true);
  };

  useEffect(() => {
    onClearSearch();
  }, [activeQuote.quoteId, catalogContext, productType, searchValue]);

  loadingIndicatorStyles.root = classes.progressBar;

  const topRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (topRef.current && topRef.current.scrollTo) {
      topRef.current.scrollTo(0, 0);
    }
  }, [topRef, searchResults]);

  const context = React.useContext(DialogContext);

  const openAssetsDialog = () => {
    const dialogProps: DialogProps = {
      providedDialog: <ConvertAssetDialog />,
    };
    context.openDialog(dialogProps);
  };

  const onSearch = useCallback(
    (value?: string) => {
      const newValue = value || '';
      setSearchValue(newValue);
      if (productType === ProductType.Product) {
        if (value) {
          searchProductsFinder({
            variables: {
              input: {
                searchText: value,
                catalogContext: catalogContext,
                first: 25,
              },
            },
          });
        }
        setFirstExpandSearch(true);
      } else if (productType === ProductType.Term && isTermsSearchEnabled) {
        if (value) {
          searchProductsFinder({
            variables: {
              input: {
                searchText: value,
                catalogContext: catalogContext,
                productFamily: ProductFamilyGql.NegotiatedTerms,
                first: 25,
              },
            },
          });
        }
      }
    },
    [catalogContext, isTermsSearchEnabled, productType, searchProductsFinder]
  );

  useEffect(() => {
    if (searchValue) {
      onSearch(searchValue);
    }
  }, [catalogContext, searchValue, onSearch]);

  // Telemetry
  const onToggleList = (listName: string, listOpened: boolean) =>
    loggerService.log({
      name: `Finder List ${listOpened ? 'expanded' : 'collapsed'}`,
      properties: {
        listName,
      },
    });

  const onItemSelect = (
    productIdentifier?: ProductIdentifier,
    type?: FinderItemType,
    itemText?: string,
    oneAskCaseId?: string
  ) => {
    if (type === FinderItemType.UploadItem) {
      createOrUpdateModernAgreement({
        variables: {
          input: {
            quoteId: activeQuote.quoteId,
            agreementType: AgreementTypes.Special,
            participants: [],
          },
        },
      });
      setIsAgreementDisabled(true);
    } else {
      productIdentifier &&
        activeQuote.availableMutations &&
        activeQuote.availableMutations.addLineItems(
          [productIdentifier.productId],
          catalogContext,
          oneAskCaseId
        );
    }
    //const ITEM_SELECTION_COUNT = 1;
    // Using to analyze how users prefer to add items to list (click vs. drag)
    loggerService.log({ name: 'Finder Dnd - User has added item to list via click.' });
    //TODO: michmel - remove
    // createLineItem(
    //   isQuoteReadOnly,
    //   proposal,
    //   proposalServiceEndpoint,
    //   productIdentifier,
    //   addLineItem,
    //   selectedProject
    // );

    //updateAddedLineItemCount(ITEM_SELECTION_COUNT);
  };

  const displayList = !isRecoLoading && !!oc(recoProductGroup).items.length();

  const getItems = (entities: Entities, columnId: string): FinderItem[] => {
    return (
      entities.columns[columnId] &&
      entities.columns[columnId].itemIds.map(
        (itemId: string): FinderItem => {
          return {
            ...entities.items[itemId],
            addClass: isQuoteReadOnly ? classes.buttonReadonly : '',
          };
        }
      )
    );
  };

  const getUploadTermsFinderItems = (items: BaseRecommendationAction[]): FinderItem[] => {
    const finderitems = items.map((item, key) => {
      const uploadAction = item as UploadRecommendationAction;
      return {
        id: `UploadAgreementTerm-${key}`,
        productIdentifier: {
          productId: `UploadAgreementTerm-${key}`,
          productFamily: ProductFamilyGql.NegotiatedTerms,
        },
        itemText: uploadAction.title.display,
        disabled: isAgreementDisabled || isQuoteReadOnly,
        type: FinderItemType.UploadItem,
      };
    });
    return finderitems;
  };

  const getECIFOnlyFinderItems = (
    items: BaseRecommendationAction[],
    alreadyAdded: boolean
  ): FinderItem[] => {
    const finderItems: FinderItem[] = [];
    items.forEach(item => {
      if (item.meta) {
        // ECIF finder items are always of type ProductRecommendationAction
        const productAction = item as ProductRecommendationAction;
        const currencyMeta = item.meta.find(result => result.key === 'Currency');
        const currency = oc(currencyMeta).value();
        const oneAskCaseIdMeta = item.meta.find(result => result.key === 'CaseId');
        const oneAskCaseId = oc(oneAskCaseIdMeta).value();
        const requestNumberMeta = item.meta.find(result => result.key === 'RequestNumber');
        const requestNumber = oc(requestNumberMeta).value();

        if (oneAskCaseId && currency) {
          const currencyError =
            !isQuoteReadOnly &&
            item.meta &&
            oc(activeQuote).activeQuote.billingCurrency() !== currency;
          finderItems.push({
            id: '0RDCKN5233F1',
            productIdentifier: { productId: '0RDCKN5233F1' },
            itemText: items.length > 1 ? `ECIF - ${requestNumber}` : productAction.product.title,
            disabled: alreadyAdded || currencyError,
            oneAskId: oneAskCaseId,
            error: currencyError
              ? {
                  errorTitle: t('quote::Currency mismatch'),
                  errorPrimary: t(
                    'quote::The currency in the OneAsk request ({{preapprovedCurrency}}) does not match the currency on the quote ({{quoteCurrency}}). ',
                    {
                      preapprovedCurrency: currency,
                      quoteCurrency: oc(activeQuote).activeQuote.billingCurrency(),
                    }
                  ),
                  errorSecondary: t('quote::For assistance, please contact '),
                }
              : undefined,
            addClass: isQuoteReadOnly ? classes.buttonReadonly : '',
          });
        }
      }
    });
    return finderItems;
  };

  const recoLists = recoGroups && finderListProps && (
    <div className={classes.list} key="finder-list">
      <FinderList
        addLineItemBusy={addLineItemStatus.loading}
        dataAutomationId={productType === ProductType.Term ? 'StandardTerms' : 'AzureEssentials'}
        displayFinderList={displayFinderList}
        draggingItemId={finderListProps.draggingItemId}
        droppableId="finderList"
        finderItems={getItems(finderListProps.entities, 'finderList')}
        id={productType === ProductType.Term ? 'Standard terms' : 'Azure Essentials'}
        isDragEvent={isDragEvent}
        isTerms={productType === ProductType.Term}
        listHeader={productType === ProductType.Term ? 'Standard terms' : 'Azure Essentials'}
        multiSelectTo={finderListProps.multiSelectTo}
        preventKeyClick={preventKeyClick}
        selectedItemIds={finderListProps.selectedItemIds}
        toggleSelection={finderListProps.toggleSelection}
        toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
        onItemClick={onItemSelect}
        onProductKeyDown={productButtonKeyboardNavigation}
        onToggleList={onToggleList}
      />
    </div>
  );

  const lineItems = oc(activeQuote).activeQuote.lineItems();
  const alreadyAdded =
    lineItems && lineItems.some(item => oc(item).product.productType() === ProductType.ECIF);
  const crmId = oc(activeQuote).activeQuote.crmLead.id();
  const crmIdUpper = crmId && crmId.toUpperCase();

  const ecifLists = showECIF && displayList && productType === ProductType.Term && (
    <div className={classes.list} key="ECIF">
      <FinderList
        addLineItemBusy={addLineItemStatus.loading}
        crmId={crmIdUpper}
        displayFinderList={displayFinderList}
        draggingItemId={finderListProps.draggingItemId}
        droppableId="ecifList"
        finderItems={
          recoTermGroup ? getECIFOnlyFinderItems(recoTermGroup.items, !!alreadyAdded) : []
        }
        id="ECIF"
        isDragEvent={isDragEvent}
        isECIF={true}
        isTerms={productType === ProductType.Term}
        listHeader={t('quote::Pre-approved')}
        multiSelectTo={finderListProps.multiSelectTo}
        preapprovedPrimary={t(
          'quote::Some terms require pre-approval in other tools before they can be added to the proposal.'
        )}
        preapprovedSecondary={
          crmIdUpper
            ? t(
                'quote::All terms which have been pre-approved for the CRM ID listed on the quote, {{CRMId}}, will appear here.',
                { CRMId: crmIdUpper }
              )
            : t(
                'quote::All terms which have been pre-approved for the CRM ID listed on the quote will appear here.'
              )
        }
        preventKeyClick={preventKeyClick}
        selectedItemIds={finderListProps.selectedItemIds}
        toggleSelection={finderListProps.toggleSelection}
        toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
        onItemClick={onItemSelect}
        onProductKeyDown={productButtonKeyboardNavigation}
        onToggleList={onToggleList}
      />
    </div>
  );

  let uploadList;
  if (recoUploadGroup) {
    uploadList = recoUploadGroup && productType === ProductType.Term && (
      <div className={classes.list} key="UploadTerm">
        <FinderList
          addLineItemBusy={addLineItemStatus.loading}
          crmId={crmIdUpper}
          displayFinderList={displayFinderList}
          draggingItemId={finderListProps.draggingItemId}
          droppableId="uploadTerm"
          finderItems={getUploadTermsFinderItems(recoUploadGroup.items)}
          id="uploadTerm"
          isDragEvent={isDragEvent}
          isECIF={false}
          isTerms={productType === ProductType.Term}
          listHeader={recoUploadGroup.name}
          multiSelectTo={finderListProps.multiSelectTo}
          preventKeyClick={preventKeyClick}
          selectedItemIds={finderListProps.selectedItemIds}
          toggleSelection={finderListProps.toggleSelection}
          toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
          onItemClick={onItemSelect}
          onProductKeyDown={productButtonKeyboardNavigation}
          onToggleList={onToggleList}
        />
        {showAgreementError && <FinderError setShowAgreementError={setShowAgreementError} />}
      </div>
    );
  }
  const displaySearchBar =
    (productType === ProductType.Product && !isQuoteReadOnly) ||
    (productType === ProductType.Term && isTermsSearchEnabled && !isQuoteReadOnly);

  const displaySearchResults = !isSearchLoading && searchResults && searchValue !== '';

  const noSearchResults = displaySearchResults && searchResults.length === 0;

  const displaySearchResultsList =
    productType === ProductType.Product ||
    (productType === ProductType.Term && isTermsSearchEnabled);

  const expandSearchLinkButton =
    expandSearchAvailable &&
    (expandSearchProcessing ? (
      <Spinner className={classes.spinner} />
    ) : (
      <LinkButton
        addClass={classes.linkButton}
        ariaLabel={t('quote::Get more results from {{query}} search', { query: searchValue })}
        displayText={
          firstExpandSearch ? t('quote::expand search') : t('quote::expand search further')
        }
        onClick={() => {
          searchProductsFinder({
            variables: {
              input: {
                searchText: searchValue,
                catalogContext: catalogContext,
                first: 25,
                cursor: cursor ? cursor : '',
              },
            },
          });
          firstExpandSearch && setFirstExpandSearch(false);
        }}
      />
    ));

  const dialogContext = React.useContext(DialogContext);

  if (displaySearchResults && searchResults.length) {
    const items: FinderItem[][] = [];
    if (finderListProps.entities.columns['searchResultsList']) {
      finderListProps.entities.columns['searchResultsList'].itemIds = searchResults.map(
        (product: SearchResultProduct) => `SearchResults-${product.productId}`
      );
    }

    const searchItems = searchResults.map((product: SearchResultProduct) => ({
      id: `SearchResults-${product.productId}`,
      productIdentifier: product.productIdentifier,
      itemText: product.productName,
    }));

    items.push(searchItems);
    const flattenedItems: FinderItem[] = [];
    const flat = flattenedItems
      .concat(...items)
      .reduce((previous: ItemMap, current: FinderItem): ItemMap => {
        if (current) {
          previous[current.id] = current;
        }
        return previous;
      }, {});

    finderListProps.entities.items = { ...finderListProps.entities.items, ...flat };
  }

  const searchResultsList = displaySearchResults && !noSearchResults && finderListProps && (
    <div className={classes.list}>
      <FinderList
        addLineItemBusy={addLineItemStatus.loading}
        dataAutomationId="searchResults"
        displayFinderList={displayFinderList}
        draggingItemId={finderListProps.draggingItemId}
        droppableId="searchResultsList"
        finderItems={searchResults.map(product => ({
          addClass: classes.productButton,
          id: `SearchResults-${product.productId}`,
          productIdentifier: product.productIdentifier,
          itemText: product.productName,
          favoriteButton: productType === ProductType.Product && (
            <FavoriteButton
              catalogContext={catalogContext}
              product={product}
              quoteId={activeQuote.quoteId}
            />
          ),
          disabled:
            (!!product.maxQuantityOnQuote &&
              lineItems &&
              lineItems.filter(item => oc(item).product.id() === product.productId).length >=
                product.maxQuantityOnQuote) ||
            oc(finderListProps).entities.items[product.productId].disabled(false),
        }))}
        id="SearchResults"
        isDragEvent={isDragEvent}
        listHeader={t('quote::{{count}} search result', {
          count: searchResults.length,
          defaultValue_plural: '{{count}} search results', // eslint-disable-line @typescript-eslint/camelcase
        })}
        multiSelectTo={finderListProps.multiSelectTo}
        preventKeyClick={preventKeyClick}
        selectedItemIds={finderListProps.selectedItemIds}
        toggleSelection={finderListProps.toggleSelection}
        toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
        onItemClick={onItemSelect}
        onProductKeyDown={productButtonKeyboardNavigation}
      />
      {expandSearchLinkButton}
    </div>
  );
  // #endregion

  const noSearchResultsSection = (
    <div className={classes.noResults}>
      <TextBodyLarge>{t('quote::Search results')}</TextBodyLarge>

      <TextWatermark>
        {t('quote::No {{item}}s found.', {
          item: t(`quote::${productType.toString()}`).toLocaleLowerCase(),
        })}
      </TextWatermark>

      {expandSearchLinkButton}
    </div>
  );

  //#region User Favorite Product Groups Lists
  const userProductGroupsLists: JSX.Element[] = [];

  const instructionsTranslation: JSX.Element = (
    <Trans ns="quote">
      To save a product, navigate to and select the favorite button, &quot;{' '}
      <Icon iconName="Heart" /> &quot;, on a product tile. You can also create your own groupings of
      products.
    </Trans>
  );

  if (!isRecoLoading && !userFavoriteProductGroups) {
    userProductGroupsLists.push(
      <div className={classes.list} key="favorites-list">
        <FinderList
          addLineItemBusy={addLineItemStatus.loading}
          displayFinderList={displayFinderList}
          droppableId="favoritesList"
          finderItems={[]}
          isDragEvent={isDragEvent}
          listHeader={t('quote::Favorites')}
          preventKeyClick={preventKeyClick}
          primaryMessage={t('quote::Your favorite products will live here.')}
          secondaryMessage={instructionsTranslation}
        />
      </div>
    );
  } else {
    for (const groupName in userFavoriteProductGroups) {
      const isFavoritesGroup = groupName === 'Favorites';
      const favoriteItems = getItems(finderListProps.entities, `favoritesList-${groupName}`);
      const groupProducts: string[] = [];
      favoriteItems &&
        favoriteItems.length &&
        favoriteItems.forEach((item: FinderItem) => {
          if (!item.disabled && item.productIdentifier) {
            groupProducts.push(item.productIdentifier.productId);
          }
        });

      const groupMenuOptions =
        groupProducts.length && activeQuote
          ? [
              {
                icon: 'AddToShoppingList',
                dataAutomationId: 'addGroupToQuote',
                key: 'add-group-to-quote',
                text: t('quote::Add group to quote'),
                onClick: () => {
                  activeQuote.availableMutations &&
                    activeQuote.availableMutations.addLineItems(groupProducts, catalogContext);
                },
              },
            ]
          : [];

      if (!isFavoritesGroup) {
        // Rename group option
        groupMenuOptions.push({
          icon: 'Rename',
          key: 'rename-group',
          dataAutomationId: 'renameGroup',
          text: t('quote::Rename group'),
          onClick: () =>
            openRenameProductGroupDialog(dialogContext, {
              currentGroupName: groupName,
              elementIdToFocusOnDismiss: `${groupName}-menu-button`,
              quoteId: activeQuote.quoteId,
              favoritesProductGroups: userFavoriteProductGroups,
              catalogContext: catalogContext,
            }),
        });

        // Remove group option
        groupMenuOptions.push({
          icon: 'Delete',
          key: 'delete-group',
          dataAutomationId: 'deleteGroup',
          text: t('quote::Delete group'),
          onClick: () =>
            openDeleteProductGroupDialog(dialogContext, {
              groupName,
              elementIdToFocusOnDismiss: `${groupName}-menu-button`,
              quoteId: activeQuote.quoteId,
              favoritesProductGroups: userFavoriteProductGroups,
              catalogContext: catalogContext,
            }),
        });
      }

      userProductGroupsLists.push(
        <div className={classes.list} key={`${groupName}-list`}>
          <FinderList
            addLineItemBusy={addLineItemStatus.loading}
            dataAutomationId="favorites"
            displayFinderList={displayFinderList}
            draggingItemId={finderListProps.draggingItemId}
            droppableId={`favoritesList-${groupName}`}
            finderItems={favoriteItems}
            id="FavoritesList"
            isDragEvent={isDragEvent}
            listHeader={groupName}
            menuButtonId={`${groupName}-menu-button`}
            menuProps={groupMenuOptions.length ? groupMenuOptions : undefined}
            multiSelectTo={finderListProps.multiSelectTo}
            preventKeyClick={preventKeyClick}
            primaryMessage={
              isFavoritesGroup && !userFavoriteProductGroups[groupName].products.length
                ? t('quote::Your favorite products will live here.')
                : undefined
            }
            secondaryMessage={
              isFavoritesGroup && !userFavoriteProductGroups[groupName].products.length
                ? instructionsTranslation
                : undefined
            }
            selectedItemIds={finderListProps.selectedItemIds}
            toggleSelection={finderListProps.toggleSelection}
            toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
            onItemClick={onItemSelect}
            onProductKeyDown={productButtonKeyboardNavigation}
            onToggleList={isFavoritesGroup ? onToggleList : undefined}
          />
        </div>
      );
    }
  }
  //#endregion

  //#region Asset management button
  const assetsManagementButton = (
    <BorderlessButton
      iconName="ProductList"
      styles={{ root: classes.assetsButtonRoot, label: classes.assetsButtonLabel }}
      text={t('quote::Asset management')}
      onClick={openAssetsDialog}
    />
  );
  //#endregion
  let productTitle = t(`quote::${productType.toString()}`);
  const cloud = catalogContext.nationalCloud;
  if (showCloudContext && cloud && productType === ProductType.Product) {
    const displayCloudName =
      cloud === NationalCloud.Global ? cloud : cloud.slice(0, 2) + ' ' + cloud.slice(2);
    productTitle = `${displayCloudName} ${t(`quote::product`)}`;
  }

  return (
    <>
      <TextTitleSecondary addClass={classes.title}>
        {t('quote::{{title}} finder', { title: productTitle })}
      </TextTitleSecondary>
      {assetManagementFlightEnabled &&
        assets &&
        !!assets.length &&
        !isQuoteReadOnly &&
        productType === ProductType.Product && (
          <div>
            {assetsManagementButton}
            <div className={classes.assestsSeparator}>
              <SectionSeparator />
            </div>
          </div>
        )}
      {displaySearchBar && (
        <BorderlessSearch
          ariaLabel={t('quote::Search for {{item}}', {
            item: t(`quote::${productType.toString()}`).toLocaleLowerCase(),
          })}
          componentRef={searchComponentRef}
          dataAutomationId="productSearch"
          focusPlaceholder={t('quote::Find {{item}}s by name', {
            item: t(`quote::${productType.toString()}`).toLocaleLowerCase(),
          })}
          id={`searchInput-${productType.toString()}Search`}
          placeholder={t('quote::Search')}
          onChangeDebounced={onSearch}
          onClear={onClearSearch}
          onSearch={onSearch}
        />
      )}
      <div className={classes.listContainer} ref={topRef}>
        <div className={classes.progressBarContainer}>
          <ProgressIndicatorAtom
            progressHidden={!isSearchLoading}
            styles={loadingIndicatorStyles}
          />
          <div className={classes.underline}>
            <SectionSeparator />
          </div>
        </div>
        {noSearchResults && displaySearchResultsList && noSearchResultsSection}
        {displaySearchResultsList && (
          <RenderIfExistsSpan wrapperClass="">{searchResultsList}</RenderIfExistsSpan>
        )}
        {productType === ProductType.Product && userProductGroupsLists}
        {uploadList}
        {displayList && recoLists}
        {ecifLists}
      </div>
    </>
  );
};

export const FinderStyled = withStyles(finderStyles)(FinderUnstyled);
export const mapStateToPropsFinder = mapStateToProps;
export const Finder = connect(mapStateToProps)(FinderStyled);
