import { SectionSeparator } from 'components/ions';
import { getFlightIsEnabled } from 'features/app/selectors';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { getCRMId, isAgreementTypeLegacy } from 'features/proposal/utils';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';

import { SalesBody } from './SalesBody';
import { SalesFooter } from './SalesFooter';
import { SalesHeader } from './SalesHeader';

const styles = {
  layout: {
    '& > :nth-child(2)': {
      marginTop: 14,
    },
  },
  separatorMargin: {
    marginTop: 32,
    marginBottom: 24,
  },
};

export interface SalesProps {
  quote: Proposal;
}

const mapStateToProps = (state: RootState, ownProps: SalesProps) => ({
  isLegacy: isAgreementTypeLegacy(ownProps.quote),
  modernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
});

export type SalesStateProps = SalesProps & ReturnType<typeof mapStateToProps>;
type Props = SalesStateProps & WithStyles<typeof styles>;

export const SalesUnStyled: React.FC<Props> = props => {
  const { quote, isLegacy, classes } = props;
  useHelpContent(HelpContent.SalesPropertySheet);

  const contentForLegacy = <SalesBody isLegacy={isLegacy} quote={quote} />;
  const contentForModern = (
    <>
      <SalesHeader crmId={getCRMId(quote)} proposal={quote} />
      <SalesBody
        isLegacy={isLegacy}
        modernOfficeEnabled={props.modernOfficeEnabled}
        quote={quote}
      />
      <SectionSeparator addClass={classes.separatorMargin} />
      <SalesFooter quote={quote} />
    </>
  );

  return <div className={classes.layout}>{isLegacy ? contentForLegacy : contentForModern}</div>;
};

export const SalesDisconnected = withStyles(styles)(SalesUnStyled);
export const Sales = connect(mapStateToProps)(SalesDisconnected);
