import { NumberInput } from 'components/ions/NumberInput/NumberInput';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { textFormatter } from '../utils';

export interface QuantityProps {
  quantity: number;
  quantifiable: boolean;
  lineItemId: string;
  updateQuantity?: (newQuantity: number, lineItemId: string) => void;
  quantityBoundaries?: { min: number; max: number };
  failedToLoad?: boolean;
}

export const Quantity: React.FC<QuantityProps> = (props: QuantityProps) => {
  const {
    quantityBoundaries,
    failedToLoad,
    quantifiable,
    updateQuantity,
    quantity,
    lineItemId,
  } = props;
  const { t } = useTranslation();
  const minQuantity = quantityBoundaries != null ? quantityBoundaries.min : 1;
  const maxQuantity = quantityBoundaries != null ? quantityBoundaries.max : 1;
  return failedToLoad ? (
    textFormatter()
  ) : quantifiable ? (
    <NumberInput
      disabled={minQuantity === maxQuantity}
      max={maxQuantity}
      maxMessage={t('quote::Max Qty')}
      min={minQuantity}
      minMessage={t('quote::Min Qty')}
      value={quantity}
      onBlur={(newQuantity: number) => {
        updateQuantity && updateQuantity(newQuantity, lineItemId);
      }}
    />
  ) : (
    textFormatter(quantity)
  );
};
