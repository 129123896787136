import { TestHeaderConfig } from 'services/types';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export const endpoints = {
  [Environment.Prod]:
    'https://quotecenterservice.l2o.microsoft.com/v1/edgesearch/search/v1.0/elastic/search/organizations',
  [Environment.Int]:
    'https://quotecenterservice.int.l2o.microsoft.com/v1/edgesearch/search/v1.0/elastic/search/organizations',
};

export interface EdgeSearchConfig extends TestHeaderConfig {
  environment: Environment;
}
