import { getFlightIsEnabled } from 'features/app/selectors';
import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { safelistedCID } from 'services/credit/config';
import { CreditCheckEventType, CreditRequest } from 'services/credit/types';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { Success } from '../Shared';
import { RequestCreditIncreaseView } from './';

enum DialogSteps {
  MainForm = 'MainForm',
  Processing = 'Processing',
  Success = 'Success',
  Fail = 'Fail',
}

const dialogDimension = {
  height: 272,
  width: 440,
};

const mapStateToProps = (state: RootState) => ({
  proposal: selectors.getActiveProposal(state),
  error: selectors.requestCreditCheckErrorOnAction(state),
  organizationId: selectors.getOrganizationId(state),
  accountId: selectors.getAccountId(state),
  isCreditCheckLoading: selectors.requestCreditCheckLoading(state),
  billingCurrency: selectors.getBillingCurrency(state),
  minimumCreditLine: selectors.getMinimumCreditLine(state),
  isSafelistedCreditCheckEnabled: getFlightIsEnabled(state, Flight.safelistedCreditCheck),
});

const dispatchProps = {
  requestCreditIncrease: (request: CreditRequest) =>
    actions.requestCreditIncreaseAsync.request(request),
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export const RequestCreditIncreaseDialogFeature: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState<DialogSteps>(DialogSteps.MainForm);

  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  if (props.error) {
    setCurrentStep(DialogSteps.Fail);
  } else if (!props.isCreditCheckLoading && currentStep === DialogSteps.Processing) {
    setCurrentStep(DialogSteps.Success);
  }

  const onSubmitClick = () => {
    setCurrentStep(DialogSteps.Processing);
    if (props.organizationId && props.accountId) {
      const request: CreditRequest = {
        legalEntity: {
          organizationId: props.isSafelistedCreditCheckEnabled
            ? safelistedCID.organizationId
            : props.organizationId,
          accountId: props.isSafelistedCreditCheckEnabled
            ? safelistedCID.accountId
            : props.accountId,
        },
        currency: props.billingCurrency,
        /* eslint-disable @typescript-eslint/camelcase */
        event_type: CreditCheckEventType.CreditIncrease,
        quote_id: props.proposal.id,
        quote_detail: {
          purchase_value: Number(props.minimumCreditLine),
        },
        /* eslint-enable */
      };
      props.requestCreditIncrease(request);
    } else {
      setCurrentStep(DialogSteps.Fail);
      loggerService.error({
        error: new Error('Missing organizationId or accountId or both that is not expected.'),
      });
    }
  };

  const getCurrentView = (step: DialogSteps) => {
    switch (step) {
      case DialogSteps.Processing:
        return (
          <Processing
            {...dialogDimension}
            message1={t('quote::The credit increase is being requested.')}
          />
        );
      case DialogSteps.Success:
        return (
          <Success
            message={t('quote::You have successfully requested a credit increase.')}
            {...dialogDimension}
            closeDialog={closeDialog}
            dataAutomationId="creditIncreaseSuccess"
          />
        );
      case DialogSteps.Fail:
        return (
          <Fail
            {...dialogDimension}
            closeDialog={closeDialog}
            dataAutomationId="creditIncreaseFail"
            message={t('quote::Sorry, the "Credit Increase" action failed.')}
            onTryAgainClick={() => onSubmitClick()}
          />
        );
      case DialogSteps.MainForm:
      default:
        return <RequestCreditIncreaseView {...dialogDimension} onSubmitClick={onSubmitClick} />;
    }
  };
  return getCurrentView(currentStep);
};

export const RequestCreditIncreaseDialog = connect(
  mapStateToProps,
  dispatchProps
)(RequestCreditIncreaseDialogFeature);
