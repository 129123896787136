import {
  ActionBar,
  BarButton,
  ButtonSharedProps,
  TextBody,
  TextTitleSecondary,
} from 'components/ions';
import { EventTrap } from 'components/utilities';
import { OpenCard } from 'features/proposal/types';
import { useClearSelectionOnUnmount } from 'hooks';
import { FocusZone } from 'office-ui-fabric-react';
import React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { DetailsPane } from '../DetailsPane';
import { proposalListStyles } from './ProposalList.styles';
import { ProposalListColumnProps } from './ProposalListColumn';
import { ProposalListDropZone, ProposalListWatermarkType } from './ProposalListDropZone';
import { ProposalListHeader } from './ProposalListHeader';

/**
 * Calculates the width for a element to span the set number of columns
 *
 * @param {ProposalListColumnProps[]} columns The columns to calculate colspan against.
 * @param {number} colspan The number of columns to span.
 * @param {number} startIndex the index of the column you want to start the span.
 * @returns
 */
export const calculateColspan = (
  columns: ProposalListColumnProps[],
  colspan: number,
  startIndex: number
) => {
  const colspanCols: ProposalListColumnProps[] = columns.slice(startIndex, colspan);
  const result = colspanCols.reduce((a, b) => {
    return a + b.width;
  }, 0);
  return result;
};

export interface ProposalListProps {
  watermarkType: ProposalListWatermarkType;
  actionBarButtons: ButtonSharedProps[];
  isLoading?: boolean;
  openCard?: OpenCard;
  columns: ProposalListColumnProps[];
  footer?: React.ReactElement;
  sharedDiscounts?: React.ReactElement[];
  parentBillingAccountName?: string;
  onClearSelection: () => void;
  isEmptyList?: boolean;
  isProposalReadOnly?: boolean;
  isDragEvent?: boolean;
  isPartnerProposal?: boolean;
  isLineItemsLoading?: boolean;
  isLoadingSharedDiscounts?: boolean;
  shimmeredRows?: React.ReactElement | React.ReactElement[];
}

type Props = ProposalListProps & WithStyles<typeof proposalListStyles>;

export const ProposalListUnStyled: React.FC<Props> = props => {
  const {
    columns,
    classes,
    onClearSelection,
    isEmptyList,
    openCard,
    isProposalReadOnly,
    isDragEvent,
    watermarkType,
    isLineItemsLoading,
    isLoadingSharedDiscounts,
    shimmeredRows,
  } = props;
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const scrollHeight = scrollRef && scrollRef.current && scrollRef.current.scrollHeight;
  // ref used to help calculate the height and width of the drag zone
  const listBodyHeight = scrollRef && scrollRef.current && scrollRef.current.offsetHeight;
  const footerRef = React.useRef<HTMLDivElement>(null);
  const footerHeight = footerRef && footerRef.current && footerRef.current.offsetHeight;

  React.useEffect(() => {
    if (scrollRef && scrollRef.current && scrollHeight && !openCard) {
      scrollRef.current.scrollTop = scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollHeight]);
  useClearSelectionOnUnmount(onClearSelection);
  const { t } = useTranslation();
  if (props.isLoading) {
    return null;
  }

  const buttons = props.actionBarButtons.map(buttonProps => {
    return <BarButton key={buttonProps.id} {...buttonProps} />;
  });
  const listWidth = footerRef && footerRef.current ? footerRef.current.offsetWidth : 845;
  const listHeight = listBodyHeight && footerHeight ? listBodyHeight + footerHeight : 138;

  const dropZone = (droppableSnapshot: DroppableStateSnapshot) => {
    const displayDropZone = !isProposalReadOnly && !isLineItemsLoading;
    return (
      displayDropZone && (
        <ProposalListDropZone
          height={listHeight}
          isDragEvent={isDragEvent}
          isDraggingOver={droppableSnapshot.isDraggingOver}
          isEmptyList={isEmptyList}
          watermarkType={watermarkType}
          width={listWidth}
        />
      )
    );
  };

  const shimmerLineItems = isLineItemsLoading && !isLoadingSharedDiscounts && shimmeredRows;
  const shimmerSharedDiscounts = isLineItemsLoading && isLoadingSharedDiscounts && shimmeredRows;
  const sharedDiscounts = props.sharedDiscounts && !!props.sharedDiscounts.length && (
    <div className={classes.listBody}>
      <div className={classes.sharedDiscounts} data-automation-id="sharedDiscountsHeader">
        <TextTitleSecondary addClass={classes.title}>
          {t('quote::Shared discounts')}
        </TextTitleSecondary>
        <TextBody addClass={classes.text}>
          {t(
            'quote::The shared discounts below were previously negotiated by the parent account, {{parentBillingAccountName}}, and the expiration date cannot be adjusted. To offer these shared discounts to the affiliate, or to apply additional discounts, there should be alignment with the Microsoft account team for the parent account, including email support from the commercial executive for any discounts being offered to the affiliate.',
            { parentBillingAccountName: props.parentBillingAccountName }
          )}
        </TextBody>
      </div>
      <div data-automation-id="sharedDiscountsContents">{props.sharedDiscounts}</div>
      {shimmerSharedDiscounts}
    </div>
  );
  return (
    <Droppable droppableId="lineItemsList">
      {(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => (
        <div
          className={classes.listFill}
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          <EventTrap fullWidth={true}>
            <div className={classes.listWrapper} role="grid">
              <div className={classes.list}>
                {!props.isPartnerProposal && <ActionBar>{buttons}</ActionBar>}
                <ProposalListHeader columns={columns} />
                <div
                  className={classes.listBody}
                  data-automation-id="proposalListBody"
                  ref={scrollRef}
                >
                  <FocusZone>
                    {dropZone(droppableSnapshot)}
                    {props.children}
                    {shimmerLineItems}
                  </FocusZone>
                  {sharedDiscounts}
                </div>
                <div
                  className={classes.footer}
                  ref={footerRef}
                  onClick={() => {
                    onClearSelection();
                  }}
                >
                  {props.footer || null}
                </div>
              </div>
              <div className={classes.detailsPane}>
                <DetailsPane />
              </div>
            </div>
          </EventTrap>
        </div>
      )}
    </Droppable>
  );
};

export const ProposalList = withStyles(proposalListStyles)(ProposalListUnStyled) as React.FC<
  ProposalListProps
>;
