import { ThemeProps } from 'styles';
import { sharedStyles } from 'styles/shared.styles';
import { DetailsTileProps } from './DetailsTile';

export const smallTileStyles = (theme: ThemeProps) => {
  const documentCardStyle = {
    height: 144,
    width: 228,
  };

  return {
    documentCard: {
      ...sharedStyles(theme).roundedCorners,
      ...documentCardStyle,
      padding: 16,
      boxShadow: `0px 2px 10px ${theme.palette.shadowColor}`,
      border: `1px solid ${theme.palette.backgroundDivider}`,
      userSelect: 'text',
      '&:hover': {
        border: `2px solid ${theme.palette.backgroundDivider}`,
        padding: 15,
      },
    },
    documentCardSelected: {
      ...sharedStyles(theme).roundedCorners,
      ...documentCardStyle,
      padding: 15,
      boxShadow: 'none',
      border: `2px solid ${theme.palette.secondary}`,
      userSelect: 'text',
      '&:hover': {
        border: `2px solid ${theme.palette.secondary}`,
        boxShadow: `0px 2px 10px ${theme.palette.shadowColor}`,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    image: {
      ...sharedStyles(theme).roundedCorners,
      objectFit: 'contain',
      objectPosition: 'left',
    },
    title: {
      paddingTop: 20,
      height: 42,
      marginBottom: 11,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    tags: {
      color: theme.palette.textTertiary,
      height: 16,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
    },
  };
};

export const detailsTileStyles = (theme: ThemeProps) => {
  return {
    documentCard: {
      ...sharedStyles(theme).roundedCorners,
      padding: '32px 70px 32px 20px',
      minHeight: 100,
      maxHeight: 700,
      minWidth: 1046,
      maxWidth: 1046,
      boxShadow: `0px 2px 10px ${theme.palette.shadowColor}`,
      borderTop: `2px solid ${theme.palette.secondary}`,
      display: 'flex',
      position: 'relative',
      userSelect: 'text',
    },
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      width: 307,
    },
    heading: (props: DetailsTileProps) => {
      return {
        display: 'flex',
        paddingBottom: props.publisher ? 24 : 0,
      };
    },
    closeButton: {
      position: 'absolute',
      top: 20,
      right: 20,
    },
    image: {
      ...sharedStyles(theme).roundedCorners,
      objectFit: 'contain',
      objectPosition: 'top',
    },
    title: {
      width: 222,
      height: 65,
      paddingLeft: 20,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    publisher: {
      paddingBottom: 14,
    },
    tags: {
      color: theme.palette.textTertiary,
      display: 'block',
    },
    tagsContainer: {
      paddingBottom: 12,
    },
    spacer: {
      width: 38,
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      width: 701,
      overflowY: 'auto',
    },
    description: {
      paddingLeft: 16,
      paddingBottom: 24,
      fontSize: theme.fonts.fontSizes.small,
      fontWeight: theme.fonts.fontWeights.regular,
      '& > p:first-child': {
        marginTop: 0,
      },
      '& > p:last-child': {
        marginBottom: 0,
      },
      '& a': {
        textDecoration: 'none',
        color: theme.palette.textLink,
        '&:hover': {
          color: theme.palette.textLinkHover,
        },
      },
    },
    table: {
      borderCollapse: 'collapse',
    },
    tableLeftColumn: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    tableRightColumn: {
      paddingRight: 16,
    },
    tableDescription: {
      fontSize: theme.fonts.fontSizes.medium,
      fontWeight: theme.fonts.fontWeights.regular,
      '& > p:first-child': {
        marginTop: 0,
      },
      '& > p:last-child': {
        marginBottom: 0,
      },
      '& a': {
        textDecoration: 'none !important',
        color: theme.palette.textLink,
        '&:hover': {
          color: theme.palette.textLinkHover,
        },
      },
    },
    tableHeader: {
      textAlign: 'left',
      '& > th ': {
        borderBottom: `2px solid ${theme.palette.backgroundDivider}`,
        paddingBottom: 12,
        '& > span': {
          color: theme.palette.textTertiary,
        },
      },
    },
    tableRow: {
      '& > td': {
        borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
        verticalAlign: 'top',
        paddingTop: 10,
        paddingBottom: 15,
      },
    },
  };
};
