import { UserPreferencesObjectIdFragment } from 'features-apollo/user/fragments';
import { QueryGetUserPreferencesData } from 'features-apollo/user/types';
import { getFlightIsEnabled } from 'features/app/selectors';
import gql from 'graphql-tag';
import React from 'react';
import { useSelector } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useQuery } from '@apollo/react-hooks';

import { hasFirstRunToShow, openFirstRunDialog } from './FirstRun/utils';
import { NetPromoterScore } from './NetPromoterScore/NetPromoterScore';
import { isEligibleForSurvey, npsSurveySampleRate } from './NetPromoterScore/utils';

const GET_USER_PREFERENCES = gql`
  query getUserPreferences {
    getUserPreferences {
      ...UserPreferencesObjectId
      dialogsViewed
      npsSurvey {
        date
        responded
      }
      theme
    }
  }
  ${UserPreferencesObjectIdFragment}
`;

export const useStartDialogs = () => {
  const context = React.useContext(DialogContext);
  const isSurveyFlightEnabled = useSelector((state: RootState) =>
    getFlightIsEnabled(state, Flight.npsSurvey)
  );

  useQuery<QueryGetUserPreferencesData>(GET_USER_PREFERENCES, {
    // Using '#info-dialog' at the end of QC urls to prevent reopening the dialog in the new tab/window
    skip: window.location.href.includes('#info-dialog'),
    onCompleted: data => {
      const userPreferences = data.getUserPreferences;

      if (hasFirstRunToShow(userPreferences)) {
        openFirstRunDialog(context);
      } else if (isSurveyFlightEnabled) {
        const isSampled = Math.random() <= npsSurveySampleRate;
        const isUserEligible = isEligibleForSurvey(oc(userPreferences).npsSurvey([]));

        const showNpsSurvey = isSampled && isUserEligible;

        if (!showNpsSurvey) {
          return;
        }

        context.openDialog({
          providedDialog: <NetPromoterScore />,
        });
      }
    },
  });
};
