import { TextBodySmall } from 'components/ions/Text';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { LabelListStyles } from './LabelList.styles';

export interface LabelListItem {
  name: string;
  value: string;
  dataAutomationId?: string;
}

export interface LabelListProps {
  items: LabelListItem[];
}

type Props = LabelListProps & WithStyles<typeof LabelListStyles>;

const LabelListUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, items } = props;

  return (
    <table data-automation-id="LabelList">
      <tbody>
        {items.map((item, key) => {
          return (
            <tr key={key}>
              <th className={classes.name} data-automation-id={`${item.dataAutomationId}Label`}>
                <TextBodySmall addClass={classes.nameText}>{`${item.name}:`}</TextBodySmall>
              </th>
              <td className={classes.value} data-automation-id={`${item.dataAutomationId}Value`}>
                <TextBodySmall>{item.value || '-'}</TextBodySmall>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export const LabelList = withStyles(LabelListStyles)(LabelListUnstyled) as React.FC<LabelListProps>;
