import { ActionType, ButtonSharedProps, IconButton } from 'components/ions/Buttons';
import { mergeClassNames } from 'components/utilities/mergeClassNames';
import { SearchResultProduct } from 'features/catalog';
import { favoriteButtonKeyboardNavigation } from 'features/proposal/components/Finder/keyboardNavigation';
import { addProductToFavorites, removeProductFromFavorites } from 'features/user/actions';
import { getUserFavoriteProductsIds } from 'features/user/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';

import { FavoritesCallout } from '../FavoritesCallout';
import { favoriteButtonStyles } from './FavoriteButton.styles';

export interface FavoriteButtonProps extends ButtonSharedProps {
  addButtonClass?: string;
  addContainerClass?: string;
  displayLabel?: boolean;
  product: SearchResultProduct;
  stopPropagation?: boolean;
}

const dispatchProps = {
  addProductToFavorites: addProductToFavorites.request,
  removeProductFromFavorites: removeProductFromFavorites.request,
};

const mapStateToProps = (state: RootState, ownProps: FavoriteButtonProps) => {
  const userFavoriteProductsIds = getUserFavoriteProductsIds(state);

  return {
    isFavorited: userFavoriteProductsIds.includes(ownProps.product.productId),
  };
};

type Props = FavoriteButtonProps &
  WithStyles<typeof favoriteButtonStyles> &
  typeof dispatchProps &
  ReturnType<typeof mapStateToProps>;

const defaultGroupName = 'Favorites';

const FavoriteButtonUnStyled: React.FC<Props> = props => {
  const [isCalloutDismissed, setIsCalloutDismissed] = React.useState(true);
  const { t } = useTranslation();
  const { isFavorited, product } = props;
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const [buttonLabel, setButtonLabel] = React.useState<string>();

  React.useLayoutEffect(() => {
    isFavorited ? setButtonLabel(t('Favorited')) : setButtonLabel(t('Favorite'));

    // disabling eslint to avoid adding the translation function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorited]);

  const elementIdToRefocus = `favorite-button-${product.productId}`;

  // Add product to user's favorites product group only
  const favoriteProduct = (
    event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>
  ) => {
    if (event && props.stopPropagation) event.stopPropagation();

    props.addProductToFavorites({ groupName: defaultGroupName, product });
    setIsCalloutDismissed(!isCalloutDismissed);

    loggerService.log({
      name: `Product favorited`,
      properties: {
        productId: product.productId,
        productName: product.productName,
      },
    });
  };

  // Remove from all user's product groups
  const unfavoriteProduct = (
    event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>
  ) => {
    if (event && props.stopPropagation) event.stopPropagation();

    props.removeProductFromFavorites({ product });
    setIsCalloutDismissed(true);
  };

  const handleDismissCallout = () => {
    setIsCalloutDismissed(true);
  };

  const onMouseEnter = () => {
    if (props.isFavorited && isCalloutDismissed) setButtonLabel(t('Unfavorite?'));
  };

  const onMouseLeave = () => {
    if (props.isFavorited && isCalloutDismissed) setButtonLabel(t('Favorited'));
  };

  return (
    <>
      <div
        className={mergeClassNames([props.classes.buttonContainer, props.addContainerClass])}
        ref={buttonContainerRef}
        onKeyDown={favoriteButtonKeyboardNavigation}
      >
        <IconButton
          addClass={mergeClassNames([
            props.classes.button,
            props.isFavorited ? props.classes.heartFillIcon : props.classes.heartIcon,
            props.addButtonClass,
          ])}
          ariaLabel={props.ariaLabel}
          dataAutomationId={elementIdToRefocus}
          iconName={props.isFavorited ? 'HeartFill' : 'Heart'}
          id={elementIdToRefocus}
          text={props.displayLabel ? buttonLabel : undefined}
          onClick={props.isFavorited ? unfavoriteProduct : favoriteProduct}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </div>
      <FavoritesCallout
        description={t('Would you like to save it in a new group instead?')}
        descriptionForGroups={t('Would you like to save it somewhere else instead?')}
        elementIdToFocusOnDismiss={elementIdToRefocus}
        excludeGroupName={defaultGroupName}
        hidden={isCalloutDismissed}
        moveFrom={defaultGroupName}
        product={props.product}
        target={buttonContainerRef.current}
        title={t('Saved to Favorites')}
        onDismiss={handleDismissCallout}
      />
    </>
  );
};

export const FavoriteButtonStyled = withStyles(favoriteButtonStyles)(
  FavoriteButtonUnStyled
) as React.FC<FavoriteButtonProps>;

export const FavoriteButton = connect(mapStateToProps, dispatchProps)(FavoriteButtonStyled);
