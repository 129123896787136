import axios from 'axios';
import { User } from 'features/user/types';
import { Filter } from 'services/types';
import {
  createGuid,
  getAuthHeader,
  getCV,
  getFilterString,
  getTestHeader,
  TokenAuthorities,
} from 'services/utils';

import { ApprovalConfig, endpoints } from './config';
import {
  Approval,
  ApprovalAction,
  ApprovalActionRequest,
  ApprovalActionType,
  Approvals,
  ApprovalSummary,
  SearchApprovalsFilterField,
  SearchApprovalsParams,
} from './types';

async function getHeaders(config: ApprovalConfig): Promise<Record<string, string>> {
  const token = await getAuthHeader(TokenAuthorities.L2O);
  const headers: Record<string, string> = {
    authorization: token,
    'x-ms-correlation-id': createGuid(),
    'MS-CV': getCV(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

async function getPostHeaders(
  config: ApprovalConfig,
  user: User,
  addCorrelationContext?: boolean
): Promise<Record<string, string>> {
  let headers = await getHeaders(config);

  headers['x-ms-actor-email'] = user.email || '';
  headers['Content-Type'] = 'application/json; charset=utf-8';
  if (addCorrelationContext) {
    headers['correlation-context'] =
      'v=1,ms.a.app.id=QuoteCenter,ms.b.tel.partner=commerce,ms.b.tel.scenario=commerce.quote.approveconcession.1';
  }
  return headers;
}

export async function loadApproval(id: string, config: ApprovalConfig): Promise<Approval> {
  const headers = await getHeaders(config);
  const url = `${endpoints[config.environment]}/${id}`;

  const response = await axios.get<Approval>(url, { headers });
  return response.data;
}

export const filterTemplates = {
  getByApproverContact: (user: string): Filter<SearchApprovalsFilterField> => ({
    filters: [
      {
        filterField: SearchApprovalsFilterField.EmailAddresss,
        operation: 'matches',
        value: `/.*${user}.*/`,
      },
      {
        filterField: SearchApprovalsFilterField.Submitter,
        operation: 'matches',
        value: `/.*${user}.*/`,
      },
    ],
    operation: 'or',
  }),
  getByAllContacts: (user: string): Filter<SearchApprovalsFilterField> => ({
    filters: [
      {
        filterField: SearchApprovalsFilterField.ContextType,
        operation: '=',
        value: `Quote`,
      },
      {
        filters: [
          {
            filterField: SearchApprovalsFilterField.EmailAddresss,
            operation: 'matches',
            value: `/.*${user}.*/`,
          },
          {
            filterField: SearchApprovalsFilterField.Submitter,
            operation: 'matches',
            value: `/.*${user}.*/`,
          },
        ],
        operation: 'or',
      },
    ],
    operation: 'and',
  }),
  getSearch: (query: string): Filter<SearchApprovalsFilterField> => ({
    filters: [
      {
        filterField: SearchApprovalsFilterField.ContextType,
        operation: '=',
        value: `Quote`,
      },
      {
        filters: [
          {
            filterField: SearchApprovalsFilterField.EmailAddresss,
            operation: 'matches',
            value: `/.*${query}.*/`,
          },
          {
            filterField: SearchApprovalsFilterField.Submitter,
            operation: 'matches',
            value: `/.*${query}.*/`,
          },
          {
            filterField: SearchApprovalsFilterField.ContextId,
            operation: 'matches',
            value: `/.*${query}.*/`,
          },
        ],
        operation: 'or',
      },
    ],
    operation: 'and',
  }),
};

export async function searchApprovals(
  parameters: SearchApprovalsParams,
  config: ApprovalConfig
): Promise<ApprovalSummary[]> {
  const { filter } = parameters;
  const { environment } = config;

  const url = endpoints[environment];
  const params = {
    filters: filter && getFilterString<SearchApprovalsFilterField>(filter),
  };
  const headers = await getHeaders(config);
  let response = await axios.get<Approvals>(url, { params, headers });
  let approvals: ApprovalSummary[] = [];
  const maxCallCount = 10;
  let currentCallCount = 0;
  approvals = [...approvals, ...response.data.results];
  let alreadyQueried = new Set(url);
  while (
    currentCallCount < maxCallCount &&
    response.data.nextPageUri &&
    !alreadyQueried.has(response.data.nextPageUri)
  ) {
    alreadyQueried.add(response.data.nextPageUri);
    response = await axios.get<Approvals>(response.data.nextPageUri, { params, headers });
    approvals = [...approvals, ...response.data.results];
    currentCallCount += 1;
  }
  return approvals;
}

export async function performApprovalAction(
  action: ApprovalActionRequest,
  user: User,
  config: ApprovalConfig
): Promise<ApprovalAction> {
  const isApproveAction = action.action === ApprovalActionType.Approve;
  let headers = await getPostHeaders(config, user, isApproveAction);

  const url = `${endpoints[config.environment]}/${action.approvalId}/actions`;

  const response = await axios.post<ApprovalAction>(url, action, { headers });
  return response.data;
}
