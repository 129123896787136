import { Dialog, PrimaryButton } from 'components';
import { Processing } from 'features/components/dialogs';
import { updateOrganizationContactInformationAsync } from 'features/customer/actions';
import {
  getContactInformationFromOrganizationId,
  updatingBillingContactInformation,
} from 'features/proposal/selectors';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { BillingContactBodyContent } from './BillingContactBodyContent';

interface BillingContactDialogProps {
  accountId: string;
  organizationId: string;
}

enum DialogViews {
  BillingContact,
  Processing,
}

const dimensions = {
  height: 612,
  width: 600,
};

const mapStateToProps = (state: RootState, ownProps: BillingContactDialogProps) => {
  const { email, phoneNumber } = getContactInformationFromOrganizationId(
    state,
    ownProps.organizationId
  );
  return {
    email,
    phoneNumber,
    processing: updatingBillingContactInformation(state),
  };
};

const dispatchProps = {
  updateOrganizationContactInformation: updateOrganizationContactInformationAsync.request,
};

type Props = BillingContactDialogProps & typeof dispatchProps & ReturnType<typeof mapStateToProps>;

export const BillingContactDialogUnconnected: React.FC<Props> = props => {
  const dialog = useContext(DialogContext);
  const { accountId, organizationId } = props;
  const [dialogState, setDialogState] = React.useState<DialogViews>(DialogViews.BillingContact);
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<{ address: string | undefined; invalid?: boolean }>({
    address: props.email,
  });
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(props.phoneNumber);

  const title = t('quote::Billing account contact information');

  const updateBillingContact = () => {
    if (email.address && phoneNumber) {
      setDialogState(DialogViews.Processing);

      props.updateOrganizationContactInformation(
        { email: email.address, phoneNumber },
        { accountId, organizationId }
      );
    }
  };

  const saveButton = (
    <PrimaryButton
      disabled={!email.address || !phoneNumber || email.invalid}
      text={t('Save')}
      onClick={updateBillingContact}
    />
  );

  const billingContactDialogView = (
    <Dialog footerButtons={saveButton} height={612} title={title} width={600}>
      <BillingContactBodyContent
        email={email.address}
        phoneNumber={phoneNumber}
        setEmail={setEmail}
        setPhoneNumber={setPhoneNumber}
      />
    </Dialog>
  );

  switch (dialogState) {
    case DialogViews.Processing:
      if (props.processing) {
        return (
          <Processing {...dimensions} message1={t('quote::Updating billing contact information')} />
        );
      } else {
        dialog.closeDialog();
        return null;
      }
    case DialogViews.BillingContact:
    default:
      return billingContactDialogView;
  }
};

export const BillingContactDialog = connect(
  mapStateToProps,
  dispatchProps
)(BillingContactDialogUnconnected);

export const openBillingContactDialog = (
  context: {
    openDialog: (dialogProps: DialogProps) => void;
    closeDialog: () => void;
  },
  props: BillingContactDialogProps
) => {
  const dialogProps: DialogProps = {
    providedDialog: <BillingContactDialog {...props} />,
  };
  context.openDialog(dialogProps);
};
