import { sharedStyles } from 'features/proposal/shared.styles';
import { ThemeProps } from 'styles';

export const salesBodyStyles = (theme: ThemeProps) => {
  return {
    ...sharedStyles,
    contactEmailVerifyMessageStyle: {
      paddingTop: '32px',
      paddingLeft: '12px',
    },
    crmIdRemove: {
      paddingTop: 34.55,
    },
    errorIcon: {
      color: theme.palette.dangerActive,
      paddingRight: 4,
    },
    fullFlex: {
      flex: '100%',
    },
    messageSpacing: {
      lineHeight: '1.5',
      letterSpacing: '.25px',
    },
    newline: {
      display: 'block',
    },
    shimmer: {
      marginBottom: 4,
    },
    enrollmentSeparator: {
      paddingBottom: '20px',
      backgroundColor: theme.palette.backgroundCommon,
    },
    companyName: {
      fontWeight: theme.fonts.fontWeights.regular,
    },
  };
};
