import gql from 'graphql-tag';

export const GetReferral = gql`
  query getReferral($id: String!) {
    getReferral(id: $id) {
      id
      etag
      name
      crmLead {
        id
        salesAccount {
          address {
            companyName
          }
          id
          tpid
        }
        type
      }
      expirationDate
      modifiedDate
      azureType
      status
      organization {
        name
        type
        assets {
          subscriptionId
        }
      }
      msContact {
        mail
      }
      market
      referralLink
      referralType
      assignedTo
    }
  }
`;

export const GetReferrals = gql`
  query getReferrals($query: String!, $options: GetReferralsOptions!) {
    getReferrals(query: $query, options: $options) {
      id
      name
      expirationDate
      modifiedDate
      status
      crmLead {
        id
      }
      needsRepair
    }
  }
`;

export const DeleteReferral = gql`
  mutation deleteReferral($input: ReferralMutationInput!) {
    deleteReferral(input: $input) {
      id
    }
  }
`;

export const CloseReferral = gql`
  mutation closeReferral($input: CloseReferralInput!) {
    closeReferral(input: $input) {
      id
    }
  }
`;

export const RepairReferral = gql`
  mutation repairReferral($input: ReferralMutationInput!) {
    repairReferral(input: $input) {
      id
      assignedTo
    }
  }
`;
