import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  TextBody,
  TextCurrency,
  TextTitle,
} from 'components';
import * as selectors from 'features/proposal/selectors';
import { sharedStyles as proposalSharedStyle } from 'features/proposal/shared.styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, ThemeProps } from 'styles';

import { sharedStyles } from '../shared.styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  ...proposalSharedStyle,

  information: {
    color: theme.palette.textTertiary,
  },
  secondText: {
    paddingTop: 16,
  },
});

interface RequestCreditIncreaseViewProps {
  width: number;
  height: number;
  onSubmitClick: () => void;
}

const mapStateToProps = (state: RootState) => ({
  creditInfo: selectors.getCreditInfo(state),
  minimumCreditLine: selectors.getMinimumCreditLine(state).toString(),
  billingCurrency: selectors.getBillingCurrency(state),
});

type Props = RequestCreditIncreaseViewProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof styles>;

export const RequestCreditIncreaseViewUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const Header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="requestCreditIncrease"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Request credit increase')}</TextTitle>
    </DialogHeader>
  );

  const Body = (
    <div className={classes.body}>
      <div>
        <TextBody addClass={classes.information}>
          {t(
            'quote::A request will be sent to increase the credit line to satisfy the minimum credit line required for this quote.'
          )}
        </TextBody>
      </div>
      <div className={classes.secondText}>
        <div>
          <TextBody addClass={classes.information}>{t('quote::Current credit line: ')}</TextBody>
          <TextBody>
            {props.creditInfo ? Number(props.creditInfo.reasons.credit_line).toLocaleString() : 0}
          </TextBody>
          <span className={props.classes.paddingRightSmall} />
          <TextCurrency>{props.billingCurrency}</TextCurrency>
        </div>
        <div>
          <TextBody addClass={classes.information}>
            {t('quote::Minimum required credit: ')}
          </TextBody>
          <TextBody>{Number(props.minimumCreditLine || '0').toLocaleString()}</TextBody>
          <span className={props.classes.paddingRightSmall} />
          <TextCurrency>{props.billingCurrency}</TextCurrency>
        </div>
      </div>
    </div>
  );

  const onSubmitClick = () => {
    props.onSubmitClick();
  };

  const Footer = (
    <div className={`${classes.footer} ${classes.flexAlignRight}`}>
      <PrimaryButton
        dataAutomationId="requestCreditIncreaseSubmitButton"
        text={t('quote::Submit')}
        onClick={onSubmitClick}
      />
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={Footer}
      headerSlot={Header}
      height={props.height}
      width={props.width}
    />
  );
};

export const RequestCreditIncreaseView = connect(mapStateToProps)(
  withStyles(styles)(RequestCreditIncreaseViewUnstyled)
);
