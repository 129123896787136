import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => {
  return {
    spinner: {
      paddingTop: 28,
      paddingLeft: 28,
    },
    verified: {
      paddingTop: 30,
    },
    marketDropdown: {
      marginTop: 16,
    },
    crmIdContainer: {
      marginTop: 16,
    },
    crmIdWidth: {
      width: 140,
    },
    crmIdErrorStyle: {
      width: 345,
    },
    referalType: {
      marginTop: 16,
    },
    marketLabel: {
      display: 'inline',
    },
    referralChoiceGroupFlexContainer: {
      flexDirection: 'row',
      display: 'flex',
      width: 130,
      justifyContent: 'space-between',
    },
    billingAccount: {
      marginTop: 16,
      marginBottom: 24,
    },

    itemTypeFlexContainer: {
      flexDirection: 'row',
      display: 'flex',
      width: 164,
      justifyContent: 'space-between',
    },
    loadedBillingAccount: {
      marginTop: 16,
    },
    tertiaryText: {
      color: theme.palette.textTertiary,
    },
    sectionSeparator: {
      marginTop: 24,
      marginBottom: 20,
    },
    systemError: {
      '& div div': {
        paddingTop: 0,
      },
    },
  };
};
