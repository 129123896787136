import { ButtonMenuProps, MenuItemButton, PrimaryButton, SecondaryButton } from 'components/ions';
import { NavBar as NavBarMolecule } from 'components/molecules';
import { openImageDialog } from 'components/molecules/ImageDialog/ImageDialog';
import { meplaHistory } from 'createHistory';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { KeyboardShortcutsPanel } from 'features-apollo/app/components/AppContent/NavBar/KeyboardShortcuts';
import { DemoModeButton, getDemoModeHelpArticleUrl } from 'features/app/components/DemoModeButton';
import { FlightsPanel } from 'features/app/components/Flights';
import { ResetFlightsButton } from 'features/app/components/Flights/ResetFlightsButton';
import {
  hasInitialFlights,
  reloadPageWithoutUrlFlights,
} from 'features/app/components/Flights/utils';
import { HelpButton } from 'features/app/components/HelpButton';
import {
  hasDangerousConfig,
  quoteCenterDemoUrl,
  quoteCenterProdUrl,
} from 'features/app/config/configurations';
import { AppEnvironment } from 'features/app/config/type';
import { getAllFlights, getFlightIsEnabled } from 'features/app/selectors';
import { getLdssCustomerName } from 'features/ldsscustomer/selectors';
import * as userSelectors from 'features/user/selectors';
import { PreferredTheme } from 'generated/graphql';
import i18next from 'i18n';
import { ContextualMenuItemType, Icon } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { routes } from 'routes';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { authProvider } from 'services/utils/authProvider';
import { clearFlightsFromStorage } from 'store/middleware/localStorageMiddleware';
import { RootState } from 'store/types';
import { isDevBuild } from 'store/utils';
import { DialogContext } from 'styles/DialogueProvider';
import { oc } from 'ts-optchain';

import { navBarStyles } from './NavBar.styles';
import { NavBarTitle } from './NavBarTitle';
import { renderNavBarQuoteSummary } from './utils';

//#region NavBar
export enum PageRoute {
  home = '/home',
  quote = '/quote/',
  customer = '/customer/',
}

// Connecting to Store
const mapStateToProps = (state: RootState) => ({
  user: userSelectors.getUser(state),
  appEnvironment: state.app.appConfig.app,
  showTestBeakerIcon: !hasDangerousConfig(state.app.appConfig),
  ldssCustomerName: getLdssCustomerName(state),
  flights: getAllFlights(state),
  breadcrumbsEnabled: getFlightIsEnabled(state, Flight.breadcrumbs),
  isDemoModeEnabled: getFlightIsEnabled(state, Flight.demoMode),
  iShowDemoModeButtonEnabled: getFlightIsEnabled(state, Flight.showDemoModeButton),
});

// NavBar Molecule Props
interface NavBarMoleculeProps {
  title: string;
  displayHomeButton: boolean;
  primaryColor: boolean;
  children?: React.ReactNode;
  rightSideItems?: React.ReactElement | React.ReactElement[];
}

export interface NavBarProps {
  /**
   * Determines the theme option to display
   */
  userThemePreference?: PreferredTheme;
  /**
   * Callback to reset all user preferences data to default values
   */
  onDeleteUserPreferences: () => void;
  /**
   * Callback to set the theme in the user preferences information
   */
  onUpdateThemePreference: (theme: PreferredTheme) => void;
}

// Component
type Props = NavBarProps &
  RouteComponentProps<{ id: string }> &
  WithStyles<typeof navBarStyles> &
  ReturnType<typeof mapStateToProps>;

/* 
Breadcrumbs are created between navigation of Home to Quote Editor and Customer.
Once navigation is 2 levels deep, breadcrumbs will display.
    Example: Home->Quote 1->Customer would have breadcrumbs of [Quote 1, Home].

When navigating to a breadcrumb, all previous breadcrumbs are popped off.
    Example: There are breadcrumbs of [Customer, Quote 1, Home] and the current  
    page is the Quote Editor page of Quote 2. 
    If navigate to Customer breadcrumb, new breadcrumbs are [Quote 1, Home].
    If navigate to Quote 1, there are no breadcrumbs because the only option
    left is Home, and Home can be represented as a button instead of breadcrumbs.
*/
const emptyArray: ButtonMenuProps[] = [];
export const getBreadcrumbOptions = (
  previousLocationPath: string,
  nextLocationPath: string,
  breadcrumbOptions: ButtonMenuProps[],
  proposalName: string,
  customerNameText: string
) => {
  let newBreadcrumbOptions: ButtonMenuProps[] = [];
  const existingBreadcrumbIndex = breadcrumbOptions.findIndex(
    (breadcrumbOption: ButtonMenuProps) => breadcrumbOption.key === nextLocationPath
  );
  if (existingBreadcrumbIndex > -1) {
    newBreadcrumbOptions = breadcrumbOptions.slice(existingBreadcrumbIndex + 1);
    if (newBreadcrumbOptions.length === 1) return emptyArray;
    else return newBreadcrumbOptions;
  } else {
    // TODO (jek): Update when more work areas need to be supported
    const navigatedFromQuoteToCustomer =
      previousLocationPath.includes(PageRoute.quote) &&
      nextLocationPath.includes(PageRoute.customer);
    const navigatedFromCustomerToQuote =
      previousLocationPath.includes(PageRoute.customer) &&
      nextLocationPath.includes(PageRoute.quote);
    if (navigatedFromQuoteToCustomer || navigatedFromCustomerToQuote) {
      const menuText = navigatedFromQuoteToCustomer
        ? i18next.t('Quote Editor - {{proposalName}}', { proposalName })
        : i18next.t('Customer - {{customerNameText}}', { customerNameText });

      let newBreadcrumbOption: ButtonMenuProps = {
        key: previousLocationPath,
        text: menuText,
        onClick: () => {
          meplaHistory.push(previousLocationPath);
        },
      };
      if (breadcrumbOptions.length === 0) {
        newBreadcrumbOptions = [
          newBreadcrumbOption,
          {
            key: 'home',
            text: i18next.t('Home'),
            onClick: () => {
              meplaHistory.push(routes.home.root);
            },
          },
        ];
      } else {
        newBreadcrumbOptions = [newBreadcrumbOption, ...breadcrumbOptions];
      }
      return newBreadcrumbOptions;
    }
  }
};

const NavBarUnstyled: React.FC<Props> = props => {
  const { appEnvironment, classes, flights, history, ldssCustomerName, match, user } = props;
  const { t } = useTranslation();
  const { activeQuote, loading } = React.useContext(ActiveQuoteContext);

  // #region Breadcrumbs logic - this required refactor
  // const ldssCustomerMatch = useRouteMatch(routes.customer.root);
  // const proposalName = proposal.header.name;
  // const soldToOrLdssCustomerName =
  //   ldssCustomerMatch && !!ldssCustomerName ? ldssCustomerName : soldToCustomerName;
  // const nameText =
  //   isPartnerProposal && endCustomerName
  //     ? ('{{soldToOrLdssCustomerName}} for {{endCustomerName}}', {
  //         soldToOrLdssCustomerName: soldToOrLdssCustomerName,
  //         endCustomerName: endCustomerName,
  //       })
  //     : soldToOrLdssCustomerName;
  // const customerNameText = nameText || t('Unknown Customer');

  // const [breadcrumbOptions, setBreadcrumbOptions] = React.useState<ButtonMenuProps[]>([]);
  // const [previousLocationPath, setPreviousLocationPath] = React.useState<string>(location.pathname);
  const [isShortcutsOpen, setIsShortcutsOpen] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   if (location.pathname.includes(PageRoute.home)) {
  //     setBreadcrumbOptions(emptyArray);
  //   } else {
  //     const newBreadcrumbOptions = getBreadcrumbOptions(
  //       previousLocationPath,
  //       location.pathname,
  //       breadcrumbOptions,
  //       proposalName,
  //       customerNameText
  //     );
  //     newBreadcrumbOptions && setBreadcrumbOptions(newBreadcrumbOptions);
  //   }
  //   setPreviousLocationPath(location.pathname);
  // }, [location, customerNameText, breadcrumbOptions, previousLocationPath, proposalName]);
  // #endregion

  const dialog = React.useContext(DialogContext);

  const rightSideItems = [];
  props.isDemoModeEnabled &&
    rightSideItems.push(<DemoModeButton isDemoModeEnabled={props.isDemoModeEnabled} />);
  if (appEnvironment !== AppEnvironment.Prod && !props.isDemoModeEnabled) {
    if (!hasInitialFlights(flights) && isDevBuild) {
      rightSideItems.push(
        <ResetFlightsButton
          isDemoMode={props.isDemoModeEnabled}
          key="resetFlights"
          primaryColor
          onClick={() => {
            clearFlightsFromStorage();
            reloadPageWithoutUrlFlights();
          }}
        />
      );
    }

    rightSideItems.push(
      <FlightsPanel isDemoMode={props.isDemoModeEnabled} key="flightPicker" primaryColor />
    );
    rightSideItems.push(
      <KeyboardShortcutsPanel
        isOpen={isShortcutsOpen}
        key="shortcutsPanel"
        location={window.location.pathname}
        onDismiss={() => setIsShortcutsOpen(false)}
        onOpen={() => setIsShortcutsOpen(true)}
      />
    );
  }

  rightSideItems.push(
    <HelpButton
      isDemoMode={props.isDemoModeEnabled}
      key="help-button"
      panelStyles={{ root: classes.helpPanelRoot }}
    />
  );

  /**
   * Switch props values de
   *  on the main routes currently available: home, proposal, customer.
   */
  let moleculeProps: NavBarMoleculeProps = {
    title: 'Work Area',
    displayHomeButton: false,
    primaryColor: true,
    rightSideItems,
  };

  switch (match.path) {
    case routes.home.root:
      moleculeProps.title = t('Quote Center');
      moleculeProps.displayHomeButton = false;
      moleculeProps.children = <NavBarTitle title={t('Home')} />;
      break;
    case routes.quote.root:
      moleculeProps.title = t('Quote Editor');
      moleculeProps.displayHomeButton = true;
      moleculeProps.children = activeQuote
        ? renderNavBarQuoteSummary(activeQuote, loading)
        : undefined;
      break;
    case routes.customer.root:
      moleculeProps.title = t('Customer');
      moleculeProps.displayHomeButton = true;
      moleculeProps.children = (
        <NavBarTitle title={ldssCustomerName || t('quote::Unknown customer')} />
      );
      break;
    case routes.catalog.root:
      moleculeProps.title = t('Catalog');
      moleculeProps.displayHomeButton = true;
      moleculeProps.children = null;
      break;
    default:
      moleculeProps.title = t('Work Area');
      moleculeProps.displayHomeButton = true;
      moleculeProps.children = null;
      break;
  }

  const deletePreferencesMenuItem = {
    icon: 'Delete',
    key: 'DeletePreferences',
    text: t('Delete user preferences'),
    dataAutomationId: 'deleteUserPreferences',
    onClick: props.onDeleteUserPreferences,
  };
  let meControlMenuItems: ButtonMenuProps[] = [];

  const shortcutsButton = {
    icon: 'KeyboardClassic',
    key: 'KeyboardShortcuts',
    text: t('Keyboard shortcuts'),
    dataAutomationId: 'keyboardShortcuts',
    onClick: () => setIsShortcutsOpen(true),
  };

  const themeButton =
    props.userThemePreference === PreferredTheme.Light
      ? {
          icon: 'ClearNight',
          key: 'DarkThemeButton',
          text: t('Go dark'),
          dataAutomationId: 'goDarkOption',
          onClick: () => props.onUpdateThemePreference(PreferredTheme.Dark),
        }
      : {
          icon: 'Sunny',
          key: 'LightThemeButton',
          text: t('Go light'),
          dataAutomationId: 'goLightOption',
          onClick: () => props.onUpdateThemePreference(PreferredTheme.Light),
        };

  meControlMenuItems.push(shortcutsButton, themeButton);

  if (props.iShowDemoModeButtonEnabled) {
    const image = {
      mediaUrl: '/images/demoMode.png',
      mediaDescription: t('Scientist looking at beaker'),
    };
    const dialogFooterButtons = [
      <PrimaryButton
        dataAutomationId="dialogEnterDemoModeButton"
        key="enter-demo-mode-btn"
        text={t('Enter demo mode')}
        onClick={() => {
          loggerService.log({ name: 'Demo mode dialog - Entering demo mode' });
          window.location.replace(quoteCenterDemoUrl);
        }}
      />,
      <SecondaryButton
        dataAutomationId="dialogCloseDemoModeButton"
        key="close-demo-mode-btn"
        text={t('Close')}
        onClick={() => {
          dialog.closeDialog();
        }}
      />,
    ];
    const demoButton = props.isDemoModeEnabled
      ? {
          icon: 'TestBeakerSolid',
          key: 'demoModeButton',
          text: t('Leave demo mode'),
          onClick: () => {
            loggerService.log({ name: 'Demo mode button - Leaving demo mode' });
            window.location.replace(quoteCenterProdUrl);
          },
        }
      : {
          icon: 'TestBeaker',
          key: 'demoModeButton',
          text: t('Enter demo mode'),
          onClick: () => {
            loggerService.log({ name: 'Demo mode button - Open demo mode dialog' });
            openImageDialog(dialog, {
              bodyText: t(
                `By entering into demo mode you can experience the full proposal journey all without impacting a customer's footprint or data. Any quotes created in demo mode won't be able to be transacted.`
              ),
              headline: t('Would you like to enter demo mode?'),
              height: 480,
              id: 'demoModeDialog',
              image,
              onCloseDialog: () => {
                dialog.closeDialog();
              },
              footerButtons: dialogFooterButtons,
              learnMore: {
                url: getDemoModeHelpArticleUrl(),
                displayText: t('learn more about demo mode'),
              },
            });
          },
        };
    meControlMenuItems.push(demoButton);
  }

  if (appEnvironment !== AppEnvironment.Prod) {
    meControlMenuItems.push(deletePreferencesMenuItem);
  }

  meControlMenuItems.push({
    icon: 'SignOut',
    key: 'LogOutButton',
    text: t('Logout'),
    dataAutomationId: 'logoutOption',
    onClick: oc(authProvider).logout(),
  });

  const renderPrivacyPolicyButton = () => (
    <MenuItemButton
      dataAutomationId="privacyPolicy"
      iconName="Shield"
      text={t('Privacy Policy')}
      onClick={() => {
        window.open('https://privacy.microsoft.com/en-us/data-privacy-notice', '_blank');
      }}
    >
      <Icon className={classes.externalSourceIcon} iconName="OpenInNewWindow" />
    </MenuItemButton>
  );

  meControlMenuItems.push({
    key: 'PrivacyPolicySection',
    itemType: ContextualMenuItemType.Section,
    sectionProps: {
      items: [
        {
          key: 'PrivacyPolicy',
          onRender: renderPrivacyPolicyButton,
        },
      ],
      topDivider: true,
    },
  });

  return (
    <NavBarMolecule
      displayHomeButton={moleculeProps.displayHomeButton}
      homeButton={{
        ariaLabel: t('Go back to home page'),
        // breadcrumbOptions: breadcrumbOptions,
        onClick: () => {
          dialog.closeDialog();
          const pathname = history.location.pathname;
          if (pathname.includes('customer') && !pathname.includes('quote')) {
            history.push(routes.home.customers);
          } else {
            history.push(routes.home.root);
          }
        },
        breadcrumbsEnabled: props.breadcrumbsEnabled,
        dataAutomationId: 'goBackHomeButton',
      }}
      isDemoMode={props.isDemoModeEnabled}
      meControl={{
        ariaLabel: user.name ? t("{{name}}'s account", { name: user.name }) : t('Account'),
        menuItems: meControlMenuItems,
        personaImageAlt: t('User profile picture'),
        personaImageUrl: user.photo,
        personaName: user.name,
        title: t('User options'),
        dataAutomationId: 'AccountMenuButton',
      }}
      primaryColor={moleculeProps.primaryColor}
      rightSideItems={moleculeProps.rightSideItems}
      showTestBeakerIcon={props.showTestBeakerIcon && !props.isDemoModeEnabled}
      title={moleculeProps.title}
    >
      {moleculeProps.children}
    </NavBarMolecule>
  );
};

export const NavBar = withRouter(
  connect(mapStateToProps)(withStyles(navBarStyles)(NavBarUnstyled))
);
//#endregion
