import { SectionSeparator, TextBody, TextBodySmall } from 'components/ions';
import { Address } from 'features/customer/types';
import * as selectors from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';

import { EnrollmentNumberField } from './EnrollmentNumberField';
import { salesBodyStyles } from './SalesBody.styles';

export interface SalesBodyProps {
  isLegacy: boolean;
  modernOfficeEnabled?: boolean;
  quote: Proposal;
}

export interface SalesBodyStateProps extends SalesBodyProps {
  salesAccount?: Address | null;
  salesAccountLoading: boolean;
  salesAccountErrorOnLoad: any; //eslint-disable-line @typescript-eslint/no-explicit-any
}

const mapStateToProps = (state: RootState, ownProps: SalesBodyProps) => ({
  salesAccount: selectors.getSalesAccountAddressFromQuote(state, ownProps.quote),
  salesAccountLoading: selectors.salesAccountLoading(state),
  salesAccountErrorOnLoad: selectors.salesAccountErrorOnLoad(state),
});

type Props = SalesBodyStateProps & WithStyles<typeof salesBodyStyles>;

const SalesBodyUnstyled: React.FC<Props> = props => {
  const { quote, salesAccount, isLegacy, classes } = props;
  const { t } = useTranslation();

  const salesBlock: JSX.Element = (
    <div>
      <TextBodySmall dataAutomationId="salesBodySalesAccountText">
        {t('quote::Sales account')}
      </TextBodySmall>
      {salesAccount ? (
        <TextBody
          addClass={`${classes.newline} ${classes.companyName}`}
          dataAutomationId="salesAccountCompany"
        >
          {salesAccount.companyName}
        </TextBody>
      ) : (
        <TextBody addClass={classes.newline}>{t('quote::Set CRM ID to retrieve')}</TextBody>
      )}
    </div>
  );

  const enrollmentNumberSearch = (
    <EnrollmentNumberField
      isLegacy={isLegacy}
      modernOfficeVersion={props.modernOfficeEnabled}
      quote={quote}
    />
  );

  let customerBody: JSX.Element;
  if (isLegacy) {
    customerBody = enrollmentNumberSearch;
  } else {
    customerBody = salesAccount ? (
      <>
        {salesBlock}
        <SectionSeparator addClass={classes.enrollmentSeparator} />
        {enrollmentNumberSearch}
      </>
    ) : (
      <> {enrollmentNumberSearch} </>
    );
  }

  return <div>{customerBody}</div>;
};

const SalesBodyStyled = withStyles(salesBodyStyles)(SalesBodyUnstyled);
export const SalesBody = connect(mapStateToProps)(SalesBodyStyled);
