import { sharedStyles, ThemeProps } from 'styles';

export const ChangeFulfillmentCloudDialogStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  actionTextPadding: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'block',
  },
});
