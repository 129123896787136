import { Dialog, Spinner } from 'components';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { getActiveQuoteId } from 'features-apollo/quote/selectors';
import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { getFlightIsEnabled } from 'features/app/selectors';
import { Dimensions, Fail, Processing } from 'features/components/dialogs';
import {
  LineItem,
  QuoteMutationInput,
  AgreementType,
  ProductAudience,
  NationalCloud,
  CatalogAction,
  GqlLanguage,
  Market,
} from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_QUOTECONTEXT } from 'features-apollo/quote/components/Editor';
import { GET_QUOTERECOMMENDATIONS } from 'features-apollo/quote/components/queries';
import { GET_QUOTE_SALES_INFO } from '../../PropertySheets/Sales/queries';
import { EnrollmentSetStartDateDialog } from '../EnrollmentSetStartDateDialog';
import { AssociateCrmId, GetQuoteForCrmDialog } from '../queries';
import { SelectCrmIdView } from './SelectCrmIdView';
import { oc } from 'ts-optchain';

const dimensions: Dimensions = {
  height: 312,
  width: 548,
};

const styles = {
  spacing: {
    paddingTop: 16,
  },
};

const mapStateToProps = (state: RootState) => {
  return {
    quoteId: getActiveQuoteId(state),
    modernOfficeEnabled: getFlightIsEnabled(state, Flight.modernOffice),
  };
};

type Props = ReturnType<typeof mapStateToProps> & WithStyles<typeof styles>;

const CrmIdDialogUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const [showEnrollmentSetStartDateDialog, setShowEnrollmentSetStartDateDialog] = React.useState<
    boolean
  >(false);
  const [request, setRequest] = React.useState<
    { input: QuoteMutationInput; crmId: string } | undefined
  >();
  const [isCrmIdAssociated, setIsCrmIdAssociated] = React.useState<boolean>(false);
  const { data: contextData } = useQuery<QueryGetQuoteData>(GET_QUOTECONTEXT, {
    variables: { id: props.quoteId },
  });
  const catalogContext = {
    agreementType: oc(contextData).getQuote.agreementType(AgreementType.Modern),
    audience: oc(contextData).getQuote.productAudience(ProductAudience.DirectCommercial),
    nationalCloud: oc(contextData).getQuote.clouds([NationalCloud.Global])[0],
    market: oc(contextData).getQuote.market(Market.Us) as Market,
    languages: oc(contextData).getQuote.languageInfo.gqlCode(GqlLanguage.EnUs),
    action: CatalogAction.Details,
  };

  const [addCrmId, { loading, error, data }] = useMutation(AssociateCrmId, {
    update(cache, { data: { addCRMLead } }) {
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: addCRMLead.id },
        data: { getQuote: addCRMLead },
      });
    },
    refetchQueries: () => [
      { query: GET_QUOTE_SALES_INFO, variables: { id: props.quoteId } },
      { query: GET_QUOTERECOMMENDATIONS, variables: { id: props.quoteId, input: catalogContext } },
    ],
    onCompleted: data => {
      const quote = data.addCRMLead;
      const crmLead = quote.crmLead;
      setIsCrmIdAssociated(!!crmLead);
      const passesProduct = !!quote.lineItems.some(
        (lineItem: LineItem) =>
          lineItem.product && lineItem.product.productFamily.toLowerCase() === 'passes'
      );
      if (props.modernOfficeEnabled && quote.vlAgreementNumber && passesProduct) {
        // TODO - jek - Don't show dialog if nothing was actually updated.
        setShowEnrollmentSetStartDateDialog(true);
      } else {
        context.closeDialog();
      }
    },
  });

  const { loading: queryLoading, data: queryData } = useQuery<QueryGetQuoteData>(
    GetQuoteForCrmDialog,
    {
      variables: { quoteId: props.quoteId },
    }
  );

  const associateCrmId = (request: { input: QuoteMutationInput; crmId: string }) => {
    addCrmId({
      variables: request,
    });
  };

  if (queryLoading) {
    return (
      <Dialog {...dimensions} closeDialog={context.closeDialog}>
        <Spinner />
      </Dialog>
    );
  } else if (!(queryData && queryData.getQuote)) {
    return (
      <Fail
        {...dimensions}
        closeDialog={context.closeDialog}
        dataAutomationId="crmIdDialogLoadFail"
        message={t('quote::Sorry, we ran into an error. Please close and try again.')}
      />
    );
  }

  // #region map gql data
  const crmDialogInfo = queryData.getQuote;
  const quoteMutationInput = { id: props.quoteId, etag: crmDialogInfo.etag };
  const motion = crmDialogInfo.motion;
  const fgoe = crmDialogInfo.fgoe;
  //#endregion

  const onAssociateClick = (crmId: string) => {
    // build request
    const newRequest = { input: quoteMutationInput, crmId };
    setRequest(newRequest);
    associateCrmId(newRequest);
  };

  if (loading) {
    return (
      <Processing {...dimensions} message1={t('quote::The CRM ID is being associated to quote.')} />
    );
  } else if (!!error && !data) {
    return (
      <Fail
        {...dimensions}
        closeDialog={context.closeDialog}
        dataAutomationId="crmIdAssociationFail"
        message={t(
          'quote::We are sorry but the association to quote ran into an error and CRM ID could not be associated.'
        )}
        onTryAgainClick={() => request && associateCrmId(request)}
      />
    );
  } else if (showEnrollmentSetStartDateDialog) {
    return <EnrollmentSetStartDateDialog closeDialog={() => context.closeDialog()} />;
  } else {
    return (
      <SelectCrmIdView
        {...dimensions}
        crmIdAssociated={isCrmIdAssociated}
        fgoe={fgoe}
        motion={motion}
        onAssociateClick={onAssociateClick}
      />
    );
  }
};

export const CrmIdDialogStyled = withStyles(styles)(CrmIdDialogUnstyled);
export const CrmIdDialog = connect(mapStateToProps)(CrmIdDialogStyled);

export const openCrmIdDialog = (context: {
  openDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}) => {
  const dialogProps: DialogProps = {
    providedDialog: <CrmIdDialog />,
  };
  context.openDialog(dialogProps);
};
