import { ReferralStatus } from 'features/proposal/components/ReferralSummaryList/types';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import loggerService from 'services/logger-service';
import { oc } from 'ts-optchain';

import { useQuery } from '@apollo/react-hooks';

import { LoadingScreen } from './LoadingScreen';
import { GET_REFERRAL_FOR_REDIRECT } from './Queries';

export const getQuoteRedirect = (params: { crmId: string; id: string }) => {
  const { crmId, id } = params;
  if (id) {
    return routes.quote.forId(id);
  }
  if (crmId) {
    return routes.crmId.forId(crmId);
  } else {
    return routes.home.quotes.root;
  }
};

export const getReferralRedirect = (params: {
  crmId: string;
  id: string;
  searchQuery: string;
  referralStatus?: ReferralStatus;
  hasError?: boolean;
}) => {
  const { crmId, id, searchQuery, referralStatus, hasError: error } = params;
  if (error) {
    return routes.home.referrals.root;
  }

  if (!!crmId && !!id) {
    return routes.home.referrals.root;
  }
  if (id) {
    switch (referralStatus) {
      case ReferralStatus.Transacted:
      case ReferralStatus.Draft:
        return routes.home.referrals.activeForQuery(searchQuery);
      case ReferralStatus.Closed:
        return routes.home.referrals.closedForQuery(searchQuery);
      case ReferralStatus.Expired:
        return routes.home.referrals.expiredForQuery(searchQuery);
      default:
        return routes.home.referrals.root;
    }
  }
  if (crmId) {
    return routes.home.referrals.activeForQuery(searchQuery);
  }
  return routes.home.referrals.root;
};

export const External: React.FC = () => {
  const search = useLocation().search;
  let searchParams = new URLSearchParams(search);

  const linkParam = searchParams.get('link') || 'undefined';

  const crmId = searchParams.get('crmId') || '';
  const id = searchParams.get('id') || '';

  const { data, loading, error } = useQuery<{ getReferral: { status: ReferralStatus } }>(
    GET_REFERRAL_FOR_REDIRECT,
    {
      skip: !id || linkParam !== 'referral',
      variables: { id },
    }
  );

  const referral = oc(data).getReferral();
  const referrerParam = searchParams.get('referrer') || 'undefined';
  let redirectTo = routes.home.root;

  switch (linkParam) {
    case 'quote':
      redirectTo = getQuoteRedirect({ crmId, id });
      break;
    case 'customer':
      redirectTo = routes.customer.forId(id);
      break;
    case 'referral':
      if (error) {
        redirectTo = routes.home.referrals.root;
      } else {
        redirectTo = getReferralRedirect({
          crmId,
          id,
          searchQuery: search,
          referralStatus: oc(referral).status(),
          hasError: !!error,
        });
      }
      break;
    case 'agreements':
      const agreementId = searchParams.get('agreementId') || '';
      if (agreementId) {
        redirectTo = routes.customer.agreementById(id, agreementId);
      } else {
        redirectTo = routes.customer.agreementsForId(id);
      }
      break;
  }

  const isReferralLoaded = linkParam === 'referral' && !loading;
  const isReferralLoadedWithErrors = isReferralLoaded && !!error;
  const isReferralLoadedSuccesfully = isReferralLoaded && !!data;
  const shouldRedirect =
    linkParam !== 'referral' || isReferralLoadedSuccesfully || isReferralLoadedWithErrors || !id;
  if (loading) {
    return <LoadingScreen />;
  }
  if (shouldRedirect) {
    loggerService.pageLoad(`/external from ${referrerParam}`, '/external');
    return <Redirect to={redirectTo} />;
  }
  return null;
};
