import { ComboBox, SearchHeader, TextBody } from 'components';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import { getComboBoxLanguages, Language } from 'features/proposal/supported-languages';
import {
  getLanguages,
  getTranslatedMarketsAlphabetically,
} from 'features/proposal/supported-markets';
import {
  AgreementType,
  CatalogAction,
  GqlLanguage,
  Market,
  NationalCloud,
  ProductAudience,
  ProductConnection,
  Query,
  QuerySearchProductsArgs,
} from 'generated/graphql';
import { IComboBox, IComboBoxOption, ISearchBox } from 'office-ui-fabric-react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import * as LocalStorage from 'services/local-storage-service';

import { useLazyQuery } from '@apollo/react-hooks';

import { CatalogSection } from './CatalogSection';
import { GET_NEGOTIATED_TERMS, SEARCH_CATALOG_PRODUCTS } from './queries';
import { SearchSection } from './SearchSection';
import { catalogStyles } from './shared.styles';
import {
  CatalogPageNegotiatedTerm,
  CatalogPageProduct,
  ProductFamily,
  ServiceFamily,
} from './types';
import { getGqlLanguagefromLanguage, getLanguagefromGqlLanguage } from './utils';
import { convertToCatalogPageProduct, convertToCatalogPageNegotiatedTerm } from './mappers';

const mapStateToProps = () => {
  return {
    market: LocalStorage.get<Market>(LocalStorage.localMarketKey) || 'US',
  };
};

type Props = WithStyles<typeof catalogStyles> & ReturnType<typeof mapStateToProps>;

const CatalogUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();
  useHelpContent(HelpContent.Catalog);

  const [lastSearched, setLastSearched] = React.useState<string>('');
  const [currentValue, setCurrentValue] = React.useState<string>('');
  const [market, setMarket] = React.useState<Market>(props.market as Market);
  const [language, setLanguage] = React.useState<GqlLanguage>(
    getGqlLanguagefromLanguage(getLanguages(market)[0])
  );
  const [searchFocus, setSearchFocus] = React.useState<boolean>(false);

  const [catalogProducts, setCatalogProducts] = React.useState<CatalogPageProduct[]>([]);
  const [negotiatedTerms, setNegotiatedTerms] = React.useState<CatalogPageNegotiatedTerm[]>([]);
  const [expandSearchAvailable, setExpandSearchAvailable] = React.useState<boolean>(false);
  const [cursor, setCursor] = React.useState<string>('');
  const [isSearchCleared, setIsSearchCleared] = React.useState<boolean>(false);
  const [isCatalogProductsLoading, setCatalogProductsLoading] = React.useState<boolean>(false);
  const [isNegotiatedTermsLoading, setNegotiatedTermsLoading] = React.useState<boolean>(false);
  const [clickedOnLoadMore, setClickedOnLoadMore] = React.useState<boolean>(false);

  const [searchCatalogProducts, { loading: catalogProductsLoading }] = useLazyQuery<
    Query,
    QuerySearchProductsArgs
  >(SEARCH_CATALOG_PRODUCTS, {
    errorPolicy: 'ignore',
    onCompleted: data => {
      const searchProduct: ProductConnection = data.searchProducts;
      const results: CatalogPageProduct[] = searchProduct
        ? searchProduct.edges
            .filter(edge => !!edge.node)
            .map(edge => convertToCatalogPageProduct(edge.node))
        : [];

      if (expandSearchAvailable && catalogProducts.length > 0 && clickedOnLoadMore) {
        const mergedResults = catalogProducts.concat(results);
        setCatalogProducts(mergedResults);
      } else {
        setCatalogProducts(results);
      }

      if (searchProduct.pageInfo.cursor) {
        setCursor(searchProduct.pageInfo.cursor);
      }
      setExpandSearchAvailable(searchProduct.pageInfo.hasNextPage);
      setIsSearchCleared(false);
    },
  });

  const [getNegotiatedTerms, { loading: negotiatedTermsLoading }] = useLazyQuery<
    Query,
    QuerySearchProductsArgs
  >(GET_NEGOTIATED_TERMS, {
    onCompleted: data => {
      const searchNegotiatedTerms: ProductConnection = data.searchProducts;
      const results: CatalogPageNegotiatedTerm[] = searchNegotiatedTerms
        ? searchNegotiatedTerms.edges.map(edge => convertToCatalogPageNegotiatedTerm(edge.node))
        : [];
      setNegotiatedTerms(results);
      setIsSearchCleared(false);
    },
  });

  const maxMarketMenuHeight = 250;
  const languageComboBoxOptions = getComboBoxLanguages(getLanguages(market));

  const catalogContextDetails = {
    agreementType: AgreementType.Modern,
    audience: ProductAudience.DirectCommercial,
    nationalCloud: NationalCloud.Global,
    market,
    languages: language || GqlLanguage.EnUs,
    action: CatalogAction.Details,
  };

  React.useEffect(() => {
    searchCatalogProducts({
      variables: {
        input: {
          searchText: '',
          productFamily: ProductFamily.Azure,
          serviceFamily: ServiceFamily.Saas,
          catalogContext: {
            agreementType: AgreementType.Modern,
            audience: ProductAudience.DirectCommercial,
            nationalCloud: NationalCloud.Global,
            market,
            languages: language || GqlLanguage.EnUs,
            action: CatalogAction.Purchase,
          },
          first: 25,
        },
      },
    });
    getNegotiatedTerms({
      variables: {
        input: {
          searchText: '*',
          productFamily: ProductFamily.NegotiatedTerms,
          catalogContext: {
            agreementType: AgreementType.Modern,
            audience: ProductAudience.DirectCommercial,
            nationalCloud: NationalCloud.Global,
            market,
            languages: language || GqlLanguage.EnUs,
            action: CatalogAction.Browse,
          },
          first: 25,
        },
      },
    });
    setClickedOnLoadMore(false);
  }, [searchCatalogProducts, getNegotiatedTerms, market, language, isSearchCleared]);

  React.useEffect(() => {
    if (lastSearched) {
      searchCatalogProducts({
        variables: {
          input: {
            searchText: lastSearched,
            productFamily: ProductFamily.Azure,
            serviceFamily: ServiceFamily.Saas,
            catalogContext: {
              agreementType: AgreementType.Modern,
              audience: ProductAudience.DirectCommercial,
              nationalCloud: NationalCloud.Global,
              market,
              languages: language || GqlLanguage.EnUs,
              action: CatalogAction.Purchase,
            },
            first: 25,
          },
        },
      });
      setClickedOnLoadMore(false);
    }
  }, [searchCatalogProducts, lastSearched, market, language]);

  React.useEffect(() => {
    setCatalogProductsLoading(catalogProductsLoading);
    setNegotiatedTermsLoading(negotiatedTermsLoading);
  }, [catalogProductsLoading, negotiatedTermsLoading]);

  const searchFocusRef = React.useRef<ISearchBox>(null);
  useHotkeys('ctrl+/', () => {
    searchFocusRef.current && searchFocusRef.current.focus();
  });

  const handleOnLoadMore = () => {
    searchCatalogProducts({
      variables: {
        input: {
          searchText: lastSearched,
          cursor,
          productFamily: ProductFamily.Azure,
          serviceFamily: ServiceFamily.Saas,
          catalogContext: {
            agreementType: AgreementType.Modern,
            audience: ProductAudience.DirectCommercial,
            nationalCloud: NationalCloud.Global,
            market,
            languages: language || GqlLanguage.EnUs,
            action: CatalogAction.Purchase,
          },
          first: 25,
        },
      },
    });
    setClickedOnLoadMore(true);
  };

  const handleOnClearSearch = () => {
    setCatalogProducts([]);
    setLastSearched('');
    setCurrentValue('');
    setCursor('');
    setIsSearchCleared(true);
    setClickedOnLoadMore(false);
  };

  const handleOnBlur = () => {
    setSearchFocus(false);
  };

  return (
    <div className={props.classes.catalog}>
      <SearchHeader
        buttonText={t('home::Search')}
        searchAutoFocus={!window.location.href.includes('#sc')} // prevent autofocus with shortcut nav to avoid shortcut key being typed in
        searchComponentRef={searchFocusRef}
        searchDebounce={false}
        searchPlaceholder={t('home::Search by SaaS product name')}
        searchValue={currentValue}
        title={t('home::SaaS product search')}
        onBlur={handleOnBlur}
        onChange={(input: string) => {
          setCurrentValue(input);
          setSearchFocus(true);
        }}
        onClearSearch={handleOnClearSearch}
        onSearch={() => {
          setLastSearched(currentValue);
          setSearchFocus(false);
        }}
      />
      <div className={props.classes.filters}>
        <div className={props.classes.filterText}>
          <TextBody>{t('home::Show products available in the following:')}</TextBody>
        </div>
        <div className={props.classes.combobox}>
          <ComboBox
            allowFreeform={true}
            autoComplete="on"
            id="Market"
            label={t('home::Market')}
            maxHeight={maxMarketMenuHeight}
            options={getTranslatedMarketsAlphabetically(t)}
            selectedKey={market}
            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
              if (option) {
                setMarket(option.key as Market);
                setCurrentValue(lastSearched);
              }
            }}
          />
        </div>
        <div className={props.classes.combobox}>
          <ComboBox
            id="Language"
            label={t('home::Language')}
            options={languageComboBoxOptions}
            selectedKey={
              languageComboBoxOptions.find(
                option => option.key === getLanguagefromGqlLanguage(language)
              )
                ? getLanguagefromGqlLanguage(language)
                : (languageComboBoxOptions[0].key as Language)
            }
            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
              if (option) {
                setLanguage(getGqlLanguagefromLanguage(option.key as Language));
                setCurrentValue(lastSearched);
              }
            }}
          />
        </div>
      </div>
      <div
        className={searchFocus ? props.classes.productSectionDimmed : props.classes.productSection}
      >
        {lastSearched ? (
          <SearchSection
            catalogContext={catalogContextDetails}
            moreResultsAvailable={expandSearchAvailable}
            productFamily={ProductFamily.Azure}
            products={catalogProducts}
            queryString={lastSearched}
            searchingCatalogProducts={isCatalogProductsLoading}
            serviceFamily={ServiceFamily.Saas}
            onClearSearch={handleOnClearSearch}
            onExpandSearch={handleOnLoadMore}
          />
        ) : (
          <div>
            <CatalogSection
              catalogContext={catalogContextDetails}
              productFamily={ProductFamily.NegotiatedTerms}
              products={negotiatedTerms}
              searchingCatalogProducts={isNegotiatedTermsLoading}
              title={t('home::Negotiated Terms')}
              onLoadCatalogProducts={handleOnLoadMore}
            />
            <CatalogSection
              catalogContext={catalogContextDetails}
              linkText={t('home::Take me to AppSource Solution Finder')}
              linkUrl="https://appsource.microsoft.com/en-us/marketplace/co-sell?solutionType=ip&page=1"
              moreResultsAvailable={expandSearchAvailable}
              productFamily={ProductFamily.Azure}
              products={catalogProducts}
              searchingCatalogProducts={isCatalogProductsLoading}
              title={t('home::Featured marketplace SaaS')}
              onLoadCatalogProducts={handleOnLoadMore}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const Catalog = connect(mapStateToProps)(withStyles(catalogStyles)(CatalogUnstyled));
