import { getCRMConfig } from 'features/app/selectors';
import * as actions from 'features/customer/actions';
import { getCRMLead, getSalesAccount } from 'features/customer/selectors';
import { CRMLead, CRMLeadType, SalesAccount } from 'features/customer/types';
import { getMarketForCountry, isModernMarketCountry } from 'features/proposal/supported-markets';
import { all, call, put, select } from 'redux-saga/effects';
import { api } from 'services';
import { CRMConfig } from 'services/crm/config';
import {
  CRMSearchResult,
  Engagement,
  Opportunity,
  SalesAccount as SalesAccountService,
} from 'services/crm/types';
import loggerService from 'services/logger-service';
import { t } from 'services/utils';
import { RootState } from 'store/types';

function* loadCRMLead(crmId: string) {
  if (!crmId) {
    return;
  }
  const fromState: CRMLead | null = yield select((state: RootState) => getCRMLead(state, crmId));
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadCRMLeadAsync.request(crmId));

  try {
    const crmConfig: CRMConfig = yield select(getCRMConfig);
    const opportunityCall = call(api.crm.getOpportunity, crmId, crmConfig);
    const engagementCall = call(api.crm.getEngagement, crmId, crmConfig);
    const {
      opportunityCallResults,
      engagementCallResults,
    }: {
      opportunityCallResults: CRMSearchResult<Opportunity> | null;
      engagementCallResults: CRMSearchResult<Engagement> | null;
    } = yield all({
      opportunityCallResults: opportunityCall,
      engagementCallResults: engagementCall,
    });
    let lead: CRMLead;
    let opportunity;
    if (opportunityCallResults) {
      opportunity = opportunityCallResults.Results[0];
    }
    let engagement;
    if (engagementCallResults) {
      engagement = engagementCallResults.Results[0];
    }
    if (opportunityCallResults && opportunity && opportunity.AccountId) {
      lead = {
        crmId,
        id: opportunity.Id,
        entityName: opportunityCallResults.EntityName,
        account: opportunity.AccountId,
        contactId: opportunity.ContactId,
        enrollmentNumber: opportunity.EnrollmentNumber,
        type: CRMLeadType.Opportunity,
        title: opportunity.Topic,
      };
    } else if (engagementCallResults && engagement && engagement.Account) {
      lead = {
        account: engagement.Account,
        id: engagement.Id,
        entityName: engagementCallResults.EntityName,
        crmId,
        type: CRMLeadType.SuccessEngagement,
        title: engagement.Name,
      };
    } else {
      yield put(
        actions.loadCRMLeadAsync.failure(
          {
            message: t('error::CrmId not found'),
            exception: null,
          },
          { crmId, error: false }
        )
      );
      return;
    }
    yield put(actions.loadCRMLeadAsync.success(lead));
    return lead;
  } catch (err) {
    if (err) {
      if (err.toString().includes('Network')) {
        loggerService.error({ exception: { message: err.toString(), name: 'Crm Network Error' } });
      } else {
        loggerService.error({
          exception: {
            message: 'Crm call failed with an unknown error',
            name: 'Crm Unknown Error',
          },
        });
      }
    }
    yield put(
      actions.loadCRMLeadAsync.failure(
        {
          message: t('error::Error finding crm id'),
          exception: null,
        },
        { crmId, error: true }
      )
    );
  }
}

function* loadSalesAccount(salesAccountId: string) {
  if (!salesAccountId) {
    return;
  }
  const fromState: SalesAccount = yield select((state: RootState) =>
    getSalesAccount(state, salesAccountId)
  );
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadSalesAccountAsync.request(salesAccountId));

  const crmConfig: CRMConfig = yield select(getCRMConfig);
  try {
    const response: SalesAccountService = yield call(
      api.crm.getSalesAccount,
      salesAccountId,
      crmConfig
    );
    const salesAccount: SalesAccount = {
      id: response.Id,
      GlobalCrmId: response.GlobalCrmId,
      MsSalesTpId: response.MsSalesTpId,
      Address: {
        companyName: response.Name,
        addressLine1: response.Address1Line1,
        addressLine2: response.Address1Line2 || undefined,
        addressLine3: response.Address1Line3 || undefined,
        city: response.Address1City,
        region: response.Address1StateOrProvince || undefined,
        country: response.Address1Country,
        postalCode: response.Address1PostalCode || undefined,
      },
      CID: response.CID,
      McApiId: response.McApiId,
    };
    yield put(actions.loadSalesAccountAsync.success(salesAccount));
    const market = getMarketForCountry(response.Address1Country);
    if (market && !isModernMarketCountry(market)) {
      loggerService.error({
        exception: { message: 'Crm id has an unsupported market', name: 'Crm unsupported market' },
      });
    }
    return salesAccount;
  } catch (err) {
    yield put(
      actions.loadSalesAccountAsync.failure({
        message: t('error::Error getting sales account'),
        exception: err,
      })
    );
  }
}

export function* hydrateLite(crmId: string) {
  yield put(actions.hydrateCrmLite.request(crmId));
  try {
    const crmLead: CRMLead = yield call(loadCRMLead, crmId);
    const salesAccount: SalesAccount = yield call(loadSalesAccount, crmLead.account.Id);
    yield put(actions.hydrateCrmLite.success(crmId));
    return salesAccount;
  } catch (err) {
    yield put(actions.hydrateCrmLite.failure(crmId));
  }
}
