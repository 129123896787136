import { ThemeProps } from 'styles';
import { buttonSharedStyles } from '../shared.styles';
import { IconMenuButtonProps } from './IconMenuButton';

export const iconMenuButtonStyles = (theme: ThemeProps) => {
  return {
    ...buttonSharedStyles(theme),
    calloutMain: {
      ...buttonSharedStyles(theme).calloutMain,
      marginLeft: (props: IconMenuButtonProps) => (props.removeMenuMargin ? -8 : undefined),
    },
    iconButton: {
      minWidth: 32,
      width: 32,
    },
    chevron: {
      '& .ms-Button-menuIcon': {
        display: 'none',
      },
    },
  };
};
