import { LineItem } from 'generated/graphql';

import { hasBlockingMessage, hasErrorMessage, isProductFailedMessage } from './general';

/**
 * Gets line item exchange rate, if any.
 */
export const getLineItemExchangeRate = (lineItem: LineItem): number | undefined | null =>
  // @ts-ignore
  lineItem.exchangeRate;

/**
 * Gets the messages related to the line item
 */
export const getLineItemMessages = (lineItem: LineItem) => lineItem.messages;

/**
 * Whether or not the line item constains any message error or standard type
 */
export const hasAnyLineItemBlockingMessage = (lineItem: LineItem) => {
  const messages = getLineItemMessages(lineItem);
  return hasBlockingMessage(messages);
};

/**
 * Whether or not the line item constains any error message
 */
export const hasAnyLineItemErrorMessage = (lineItem: LineItem) => {
  const messages = getLineItemMessages(lineItem);
  return hasErrorMessage(messages);
};

/**
 * Whether or not GQL failed to load the product associated to the line item
 */
export const isLineItemProductFailedToLoad = (lineItem: LineItem) => {
  const messages = getLineItemMessages(lineItem);
  return messages.some(isProductFailedMessage);
};

/**
 * Gets a list of unique exchange rates found in the quote's line items
 */
export const getExchangeRates = (lineItems: LineItem[]) => {
  const lineItemsExchangeRates: number[] = [];

  lineItems.forEach(lineItem => {
    const lineItemExchangeRate = getLineItemExchangeRate(lineItem);

    if (lineItemExchangeRate) {
      lineItemsExchangeRates.push(lineItemExchangeRate);
    }
  });

  return Array.from(new Set(lineItemsExchangeRates));
};
