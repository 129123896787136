import axios from 'axios';
import { getCV, TokenAuthorities } from 'services/utils';

import { createGuid, getAuthHeader, getTestHeader } from '../utils';
import { endpoints, PurchaseConfig } from './config';
import { CreatePurchaseRecordRequest, PurchaseRecord, PurchaseRecordSummary } from './types';

async function getHeaders(config: PurchaseConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(TokenAuthorities.L2O),
    'x-ms-correlation-id': createGuid(),
    'ms-cv': getCV(),
    'api-version': '2019-07-29',
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

function createPurchaseRecordObject(params: CreatePurchaseRecordRequest): PurchaseRecord {
  const purchaseRecord: PurchaseRecord = {
    client: { name: 'Quotecenter', marketplaceCountry: params.market },
    externalReference: { id: params.quoteId, url: `quote/${params.quoteId}` },
    paymentSessionId: createGuid(),
    quoteId: params.quoteId,
    soldToIpAddress: '127.0.0.1',
  };
  return purchaseRecord;
}

export async function createPurchaseRecord(
  request: CreatePurchaseRecordRequest,
  config: PurchaseConfig
): Promise<boolean> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/purchase-records`;
  const purchaseRecord = createPurchaseRecordObject(request);
  const response = await axios.post(url, purchaseRecord, { headers });
  return !!response.data;
}

export async function getPurchaseRecord(
  id: string,
  config: PurchaseConfig
): Promise<PurchaseRecordSummary> {
  const headers = await getHeaders(config);
  const host = endpoints[config.environment];
  const url = `${host}/purchase-records/${id}`;
  const response = await axios.get<PurchaseRecordSummary>(url, { headers });
  return response.data;
}
