import { ThemeProps } from 'styles';

export const videoPlayerStyles = (theme: ThemeProps) => {
  const { fonts, palette } = theme;

  return {
    videoTitle: {
      fontSize: theme.fonts.fontSizes.xLarge,
      fontWeight: fonts.fontWeights.regular,
      margin: 0,
      paddingBottom: 20,
    },
    video: {
      border: `1px solid ${palette.border}`,
      height: 336,
      width: 598,
    },
    videoDescription: {
      margin: 0,
      paddingBottom: 40,
      width: 598,
    },
    videoPlaceholder: {
      height: 336,
      width: 598,
    },
  };
};
