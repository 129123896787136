import { ThemeProps } from 'styles';

export const productListFooterStyles = (theme: ThemeProps) => {
  return {
    root: {
      display: 'flex',
      height: 'min-content',
      '& > i': {
        color: theme.palette.textSecondary,
      },
    },
    price: {
      alignItems: 'baseline',
      paddingTop: 2,
      display: 'flex',
      '& > span': {
        paddingRight: 8,
      },
      '& > h2': {
        paddingRight: 21,
      },
    },
  };
};
