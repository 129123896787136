import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { ButtonSharedProps, PrimaryButton } from '../Buttons';
import { TextTitle } from '../Text';

const styles = (theme: ThemeProps) => {
  return {
    container: {
      backgroundColor: theme.palette.backgroundStandout,
      border: `2px dashed ${theme.palette.backgroundDivider}`,
      height: 'fit-content',
      maxWidth: 422,
      padding: 24,
      '& :first-child': {
        marginTop: 0,
      },
    },
  };
};

export interface GuidanceBoxProps {
  title?: string;
  buttonProps?: ButtonSharedProps;
}

type Props = GuidanceBoxProps & WithStyles<typeof styles>;

const GuidanceBoxUnStyled: React.FC<Props> = props => {
  const title = props.title && <TextTitle style={{ marginBottom: 8 }}>{props.title}</TextTitle>;
  const button = props.buttonProps && (
    <PrimaryButton {...props.buttonProps} styles={{ root: { marginTop: 24 } }} />
  );

  return (
    <div className={props.classes.container}>
      {title}
      {props.children}
      {button}
    </div>
  );
};

export const GuidanceBox = withStyles(styles)(GuidanceBoxUnStyled);
