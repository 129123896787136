import { buildMonetaryCardSummaryStrings } from 'features/proposal/components//MonetaryCard/utils';
import {
  buildConfigureCombinedSummary,
  isMonetary,
} from 'features/proposal/components/ConfigCard/ConfigCardBusinessLogic';
import { SkuTitleMap } from 'features/proposal/types';
import {
  buildProductIdentifier,
  getPricingAudienceClaim,
  hasSoldToCustomer,
  isAgreementTypeLegacy,
} from 'features/proposal/utils';
import i18next from 'i18next';
import { Product, ProductFamily, ProductType } from 'services/catalog/types';
import { ClaimType, endpoints as proposalServiceEndpoints } from 'services/proposal/config';
import {
  LineItem,
  Proposal,
  ProposalUpdateRequest,
  RequestProposal,
  RequestProposalHeader,
} from 'services/proposal/types';
import { createGuid } from 'services/utils';
import { oc } from 'ts-optchain';

export const isLineItemFinanceTerm = (lineItem?: LineItem) => {
  return (
    oc(lineItem)
      .productIdentifier.productType('')
      .toLocaleLowerCase() === 'financing'
  );
};

const getTermConfigText = (
  lineItem: LineItem,
  product: Product,
  skuTitleMap: SkuTitleMap,
  t: i18next.TFunction
) => {
  let configText = '';

  if (isMonetary(product)) {
    const amount = oc(lineItem).purchaseInstruction.purchaseTermUnits();
    const goodFor = oc(lineItem).duration();
    configText =
      amount && goodFor
        ? t(
            `quote::{{amount}} {{currency}}, {{startCondition}}, {{duration}}`,
            buildMonetaryCardSummaryStrings(
              amount,
              goodFor,
              lineItem.pricingCurrency,
              t('quote::year(s)'),
              t('quote::month(s)'),
              t('quote::At order placement')
            )
          )
        : t('quote::configure');
  } else if (
    product.DisplaySkuAvailabilities.length >= 1 &&
    product.ProductType !== ProductType.AzureFamilyDiscount &&
    product.ProductType !== ProductType.SAP
  ) {
    configText = buildConfigureCombinedSummary(lineItem, skuTitleMap) || '';
  }

  return configText;
};

export const validTermId = (amount: string) => {
  return amount.length >= 1 && amount.length <= 100;
};

export const validApplyTo = (key: string) => {
  return key.length >= 1;
};

export const getConnectedLineItemId = (groupId: string, lineItems: LineItem[]) => {
  const connectedLineItem = lineItems.find(item =>
    oc(item)
      .groups([])
      .find(group => {
        return group === groupId;
      })
  );
  return connectedLineItem ? connectedLineItem.id : '';
};

export const getApplyOptions = (
  financeLineItems: LineItem[],
  financeProducts: Product[],
  skuTitleMap: SkuTitleMap,
  t: i18next.TFunction
) => {
  const results: { key: string; text: string }[] = [];
  if (financeLineItems.length > 0) {
    financeLineItems.forEach(lineitem => {
      const productId = oc(lineitem).productIdentifier.productId('');
      const product = financeProducts.find(product => product.ProductId === productId);
      const productTitle = oc(product).LocalizedProperties[0].ProductTitle('');
      const text = product
        ? `${productTitle} (${getTermConfigText(lineitem, product, skuTitleMap, t)})`
        : oc(lineitem).productIdentifier.productType('');
      const key = lineitem.id;
      results.push({ key, text });
    });
  } else if (financeProducts.length > 0) {
    financeProducts.forEach(product => {
      const key = product.ProductId;
      const text = oc(product).LocalizedProperties[0].ProductTitle('');
      results.push({ key, text });
    });
  }
  return results;
};

// make a copy of line items and add the selected id to the identifier group
export const addLineItemsToGroup = (
  lineItems: LineItem[],
  lineItemId: string,
  identifier: string,
  termId: string
) => {
  const updatedLineItems = lineItems.map(lineItem => {
    const newLineItem = { ...lineItem };

    if (lineItem.id === lineItemId) {
      if (oc(newLineItem).groups([]).length === 0) {
        newLineItem.groups = [identifier];
      } else {
        newLineItem.groups && newLineItem.groups.push(identifier);
      }
    } else if (lineItem.id === identifier) {
      newLineItem.isReadyForPricing = true;
      newLineItem.supplementalTermReferenceData = `TermId:${termId}`;
      newLineItem.groups = [identifier];
    }

    return newLineItem;
  });

  return updatedLineItems;
};

export const createNewGroupedLineItem = (
  proposal: Proposal,
  guid: string,
  productId: string,
  financeableProducts: Product[],
  currentSelectedProject?: string
) => {
  const product = financeableProducts.find(product => product.ProductId === productId);
  if (product) {
    const productIdentifier = buildProductIdentifier(product);
    const claims: Partial<Record<ClaimType, string>> = {
      [ClaimType.PricingAudience]: getPricingAudienceClaim(proposal),
    };
    const userPreferences = oc(proposal).header.extendedProperties.userPreferences({});

    if (userPreferences.agreementType) {
      claims[ClaimType.AgreementType] = userPreferences.agreementType;
    }
    if (userPreferences.nationalCloud) {
      claims[ClaimType.NationalCloud] = userPreferences.nationalCloud;
    }

    let externalReference = null;
    let recipient = null;

    if (
      isAgreementTypeLegacy(proposal) &&
      userPreferences.recipientId &&
      hasSoldToCustomer(proposal)
    ) {
      externalReference = {
        id: proposal.id,
        url: `${proposalServiceEndpoints}/${proposal.id}`,
      };
      recipient = {
        recipientId: userPreferences.recipientId,
        accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
        organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
      };
    }
    const request = {
      etag: proposal.etag,
      proposalId: proposal.id,
      lineItems: [
        {
          customerIntent: 'New',
          productIdentifier,
          quantity: 1,
          //todo:can,karina to fix this-set isReadyForPricing only if the item is not configurable and not discountable
          isReadyForPricing: productIdentifier.productFamily === ProductFamily.NegotiatedTerms,
          assetTo:
            hasSoldToCustomer(proposal) && currentSelectedProject
              ? {
                  projectId: currentSelectedProject,
                  accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
                  organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
                }
              : null,
          catalogClaims: claims,
          externalReference,
          recipient,
          groups: [`${guid}`],
        },
      ],
    };

    return request;
  }
  return undefined;
};

export const getListOfIds = (lineItems: LineItem[]) => {
  return lineItems.map(item => {
    return item.id;
  });
};

export const getNewLineItemId = (oldIds: string[], newIds: string[]) => {
  const missing = newIds.filter(item => oldIds.indexOf(item) < 0);
  if (missing.length > 0) {
    return missing[0];
  }
  return undefined;
};

const addLineItemToGroup = (
  lineItems: LineItem[],
  groupId: string,
  targetId: string,
  termId?: string
) => {
  const index = lineItems.findIndex((lineItem: LineItem) => lineItem.id === targetId);

  if (index === -1) {
    throw new Error(`Selected line item does not exist,  id:  ${targetId}`);
  }

  const newLineItem = { ...lineItems[index] };
  if (oc(newLineItem).groups([]).length === 0) {
    newLineItem.groups = [groupId];
  } else {
    newLineItem.groups && newLineItem.groups.push(groupId);
  }

  if (termId) {
    newLineItem.isReadyForPricing = true;
    newLineItem.supplementalTermReferenceData = `TermId:${termId}`;
    newLineItem.groups = [`${groupId}`];
  }
  const newLineItems = [...lineItems.slice(0, index), newLineItem, ...lineItems.slice(index + 1)];

  return newLineItems;
};

export const ApplyFinanceToLineItem = (
  proposal: Proposal,
  relatedLineItemId: string,
  termLineItemId: string,
  termId: string
) => {
  const guid = createGuid();
  const requestProposalHeader: RequestProposalHeader = proposal.header;
  //add product line item to group
  let newLineItems = addLineItemToGroup(proposal.lineItems, guid, relatedLineItemId);
  //add term line item to group
  newLineItems = addLineItemToGroup(newLineItems, guid, termLineItemId, termId);

  const requestProposal: RequestProposal = {
    header: requestProposalHeader,
    lineItems: newLineItems,
  };
  const proposalUpdateRequest: ProposalUpdateRequest = {
    etag: proposal.etag,
    proposalId: proposal.id,
    proposal: requestProposal,
  };

  return proposalUpdateRequest;
};

export const ApplyAndAdd = (
  proposal: Proposal,
  termLineItemId: string,
  productId: string,
  financeableProducts: Product[],
  termId: string,
  currentSelectedProject?: string
) => {
  const guid = createGuid();
  const ids = getListOfIds(proposal.lineItems);
  const createLineItemRequest = createNewGroupedLineItem(
    proposal,
    guid,
    productId,
    financeableProducts,
    currentSelectedProject
  );
  if (!createLineItemRequest) {
    throw new Error('unable to create new line item request');
  }
  return {
    etag: proposal.etag,
    proposalId: proposal.id,
    updateLineItemId: termLineItemId,
    createLineItems: createLineItemRequest,
    originalLineItemIds: ids,
    guid: guid,
    termId: termId,
  };
};

export const getUpdateLineItemRequest = (
  proposal: Proposal,
  groupId: string,
  targetId: string,
  termId?: string
) => {
  const requestProposalHeader: RequestProposalHeader = proposal.header;
  const newLineItems = addLineItemToGroup(proposal.lineItems, groupId, targetId, termId);

  const requestProposal: RequestProposal = {
    header: requestProposalHeader,
    lineItems: newLineItems,
  };

  const proposalUpdateRequest: ProposalUpdateRequest = {
    etag: proposal.etag,
    proposalId: proposal.id,
    proposal: requestProposal,
  };

  return proposalUpdateRequest;
};

export const getRemoveLineItemsGroupRequest = (proposal: Proposal, guids: string[]) => {
  const requestProposalHeader: RequestProposalHeader = proposal.header;
  proposal.lineItems.forEach(lineItem => {
    if (oc(lineItem).groups([]).length > 0) {
      const cleanGroups = oc(lineItem)
        .groups([])
        .filter(grp => guids.indexOf(grp) === -1);
      lineItem.groups = cleanGroups;
      if (!cleanGroups.length && isLineItemFinanceTerm(lineItem)) {
        lineItem.isReadyForPricing = false;
      }
    }
  });
  const requestProposal: RequestProposal = {
    header: requestProposalHeader,
    lineItems: proposal.lineItems,
  };
  const proposalUpdateRequest: ProposalUpdateRequest = {
    etag: proposal.etag,
    proposalId: proposal.id,
    proposal: requestProposal,
  };

  return proposalUpdateRequest;
};

export const getGroupingDeleteRequest = (proposal: Proposal, selectedId: string) => {
  const targetLineItem = proposal.lineItems.find(lineItem => lineItem.id === selectedId);
  if (targetLineItem && oc(targetLineItem).groups([]).length > 0) {
    return {
      etag: proposal.etag,
      proposal: { header: proposal.header, lineItems: proposal.lineItems },
      deleteRequest: {
        etag: proposal.etag,
        lineItemId: selectedId,
        proposalId: proposal.id,
      },
      guids: oc(targetLineItem).groups([]),
    };
  }
  return undefined;
};
