import { DropdownAtom, DropdownAtomProps } from 'components/atoms';
import { dropdownAtomStyles } from 'components/atoms/Dropdown/DropdownAtom.styles';
import { IDropdownStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { DeepPartial } from 'redux';
import { ThemeProps } from 'styles';

import { TeachingBubbleLabel, TeachingBubbleLabelProps } from '../TeachingBubbleLabel';

const dropdownStyles = (theme: ThemeProps) => ({
  titleReadOnly: {
    ...dropdownAtomStyles(theme).titleDisabled,
    color: theme.palette.text,
  },
  labelReadOnly: {
    color: theme.palette.text,
  },
  infoButtonRoot: { padding: 0, height: 28 },
  infoButtonFlexContainer: { height: 28 },
  infoButtonIcon: {
    fontSize: theme.fonts.fontSizes.small,
    paddingLeft: 11,
  },
});

interface DropdownProps extends DropdownAtomProps, TeachingBubbleLabelProps {}

type Props = DropdownProps & WithStyles<typeof dropdownStyles>;

const DropdownUnstyled: React.FC<Props> = props => {
  const { classes, ...remainingProps } = props;

  // Custom read-only state
  const isKeySelected = (key: string | number) => {
    switch (key) {
      case props.defaultSelectedKey:
      case props.selectedKey:
        return true;
      default:
        if (props.multiSelect) {
          const defaultsSelected =
            props.defaultSelectedKeys &&
            props.defaultSelectedKeys.some((selectedKey: string | number) => selectedKey === key);
          const multipleSelected =
            props.selectedKeys &&
            props.selectedKeys.some((selectedKey: string | number) => selectedKey === key);
          return defaultsSelected || multipleSelected;
        } else {
          return false;
        }
    }
  };

  const optionSelected = !!(
    props.options.length && props.options.some(({ key }) => isKeySelected(key))
  );

  const isReadOnly = optionSelected && (props.disabled || props.options.length === 1);

  const styles: DeepPartial<IDropdownStyles> = { ...props.styles };
  if (isReadOnly) {
    styles.title = classes.titleReadOnly;
    styles.label = classes.labelReadOnly;
  }

  const onRenderLabel = () => {
    return (
      <TeachingBubbleLabel
        infoButtonProps={props.infoButtonProps}
        label={props.label}
        required={props.required}
      />
    );
  };

  return (
    <DropdownAtom
      {...remainingProps}
      disabled={!props.options.length || isReadOnly || props.disabled}
      styles={styles}
      onRenderLabel={props.onRenderLabel || onRenderLabel}
    />
  );
};

export const Dropdown = withStyles(dropdownStyles)(DropdownUnstyled);
