import {
  ErrorMessage,
  PrimaryButton,
  SecondaryButton,
  TextBodySmall,
  VerificationField,
} from 'components';
import { RoleAssignmentsQuery } from 'features-apollo/quote/components/queries';
import * as customerActions from 'features/customer/actions';
import {
  getTenantAdmins,
  getTenantContributors,
  getTenantOwners,
  tenantAdminsProcessing,
  tenantUpnsProcessing,
} from 'features/customer/selectors';
import { RoleAssignments } from 'features/customer/types';
import {
  createCompleteList,
  createPartialList,
  ExistingOwner,
  ExistingOwnersList,
  LoadingErrorType,
  ownerRoleIds,
  OwnerType,
} from 'features/proposal/components/ExistingOwners';
import { BodyProps as WizardBodyProps } from 'features/proposal/components/Wizards/shared';
import {
  errorSearchingWorkAccount,
  getAccountId,
  getActiveProposal,
  getOrganizationId,
  getValidatedWorkAccounts,
  searchingWorkAccount,
} from 'features/proposal/selectors';
import { emailIsValid } from 'features/proposal/utils';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { ExternalUser, GetTenantUpns, VerifyUserRequest } from 'services/externaluser/types';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';

import { useQuery } from '@apollo/react-hooks';

import { workAccountStyles } from './WorkAccount.styles';

export const WorkAccountHeaderString = (hasInitialData: boolean) => {
  if (hasInitialData) {
    return i18next.t('quote::Edit work account');
  } else {
    return i18next.t('quote::Add work account');
  }
};

export interface WorkAccountBodyProps extends WizardBodyProps<ExternalUser | undefined> {
  tenantId: string;
  isRequired: boolean;
  setIsWorkAccountUnauthorized?: React.Dispatch<React.SetStateAction<boolean>>;
}

const mapStateToProps = (state: RootState, ownProps: WorkAccountBodyProps) => {
  const proposal = getActiveProposal(state);
  return {
    tenantAdmins: getTenantAdmins(state, ownProps.tenantId),
    tenantOwners: getTenantOwners(state),
    tenantContributors: getTenantContributors(state),
    accountId: getAccountId(state, proposal),
    organizationId: getOrganizationId(state),
    errorLoadingAdmins: tenantAdminsProcessing(state).error,
    errorLoadingUpns: tenantUpnsProcessing(state).error,
    loadingTenantAdmins: tenantAdminsProcessing(state).loading,
    loadingTenantUpns: tenantUpnsProcessing(state).loading,
    isSearchingWorkAccount: searchingWorkAccount(state),
    errorSearchingWorkAccount: errorSearchingWorkAccount(state),
    validatedWorkAccounts: getValidatedWorkAccounts(state),
  };
};

const dispatchProps = {
  searchWorkAccount: (request: VerifyUserRequest) =>
    customerActions.validateWorkAccountAsync.request(request),
  loadTenantUpns: customerActions.loadTenantUpnsAsync.request,
};

export type WorkAccountBodyStateProps = typeof dispatchProps &
  ReturnType<typeof mapStateToProps> &
  WorkAccountBodyProps;

type BodyProps = WorkAccountBodyStateProps & WithStyles<typeof workAccountStyles>;

const WorkAccountBodyUnstyled: React.FC<BodyProps> = props => {
  const {
    tenantId,
    isRequired,
    classes,
    isSearchingWorkAccount,
    errorSearchingWorkAccount,
    validatedWorkAccounts,
    searchWorkAccount,
    initialData,
    onValid,
    onInvalid,
    tenantAdmins,
    loadTenantUpns,
    tenantOwners,
    tenantContributors,
    accountId,
    organizationId,
    errorLoadingAdmins,
    errorLoadingUpns,
    loadingTenantAdmins,
    loadingTenantUpns,
    setIsWorkAccountUnauthorized,
  } = props;
  const { t } = useTranslation();

  const msEmail = '@microsoft.com';

  const regexError = t('quote::Enter a valid email address');
  const msEmailError = t('quote::You cannot use a Microsoft email address for a customer');
  const verifyError: JSX.Element | string = (
    <ErrorMessage mainMessage={t('quote::Identity does not exist on this tenant')}>
      {t(
        'quote::The work account you have entered does not exist on the tenant specified for this customer. Please enter a valid work account or work with one of the global admins or owners identified below to provide them with an identity that would allow them to take commerce actions such as signing, purchasing, and establishing the details of their billing account.'
      )}
    </ErrorMessage>
  );

  // TODO: masivaia - uncomment when Jason's deliverable with new requirements is ready
  // const warningMessage = (
  //   <WarningMessage
  //     mainMessage={t('quote::Identity exists but does not have proper authorization')}
  //   >
  //     {t(
  //       'quote::This identity does not have proper authorization to take commerce actions such as signing, purchasing, and establishing the details of their billing account. Please work with your contact to help them identify a global admin who can provide them with proper authorization.'
  //     )}
  //   </WarningMessage>
  // );

  const [lastVerified, setLastVerified] = React.useState<string | undefined>();
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(
    (initialData && initialData.UPN) || ''
  );
  const [errorMessage, setErrorMessage] = React.useState<string | JSX.Element | undefined>();
  const differentOrEmpty = lastVerified !== currentValue || !currentValue;
  const isError = !differentOrEmpty && !isSearchingWorkAccount && errorSearchingWorkAccount;
  // TODO: masivaia - uncomment when Jason's deliverable with new requirements is ready
  // const [showWarningMessage, setShowWarningMessage] = React.useState<boolean>(false);
  const [errorLoadingOwners, setErrorLoadingOwners] = React.useState<boolean>(false);
  const [ownersExist, setOwnersExist] = React.useState<boolean>(false);
  const [loadingError, setLoadingError] = React.useState<LoadingErrorType>(LoadingErrorType.None);
  const [displayList, setDisplayList] = React.useState<ExistingOwner[]>([]);

  const { loading: loadingTenantRoles, error, data } = useQuery(RoleAssignmentsQuery, {
    variables: {
      input: {
        roles: ownerRoleIds,
        organization: {
          accountId,
          organizationId,
        },
      },
    },
  });

  React.useEffect(() => {
    if (!loadingTenantRoles) {
      if (error) {
        setErrorLoadingOwners(true);
      } else if (data && data.getRoleAssignments.length) {
        const roleAssignments = data.getRoleAssignments;
        const request: GetTenantUpns[] = [];
        roleAssignments.forEach((roleAssignment: RoleAssignments) => {
          if (roleAssignment.principalTenantId) {
            request.push({
              roleId: roleAssignment.roleId,
              tenantId: roleAssignment.principalTenantId,
              objectId: roleAssignment.principalId,
            });
          }
        });
        loadTenantUpns(request);
      }
    }
  }, [loadingTenantRoles, data, loadTenantUpns, error]);

  React.useEffect(() => {
    // TODO: masivaia - uncomment when Jason's deliverable with new requirements is ready
    // setShowWarningMessage(false);
    if (!currentValue) {
      setErrorMessage(undefined);

      if (isRequired) {
        onInvalid();
      } else {
        onValid(undefined);
      }
    } else if (currentValue.endsWith(msEmail)) {
      setErrorMessage(msEmailError);
      onInvalid();
    } else if (!emailIsValid.test(currentValue)) {
      setErrorMessage(regexError);
      onInvalid();
    } else {
      const cwaResult =
        validatedWorkAccounts && validatedWorkAccounts.find(obj => obj.TenantId === tenantId);
      if (isSearchingWorkAccount || currentValue !== lastVerified) {
        setErrorMessage(undefined);
        onInvalid();
      } else if (cwaResult && cwaResult.ObjectId) {
        setErrorMessage(undefined);
        onValid(cwaResult);
        const upns: string[] = ([] as string[]).concat(
          tenantOwners,
          tenantContributors,
          tenantAdmins
        );
        if (!upns.length || !upns.includes(cwaResult.UPN)) {
          // TODO: masivaia - uncomment when Jason's deliverable with new requirements is ready
          // setShowWarningMessage(true);
          loggerService.log({
            name: 'Existing Owners - work account is unauthorized',
          });
          setIsWorkAccountUnauthorized && setIsWorkAccountUnauthorized(true);
        }
      } else {
        setErrorMessage(verifyError);
        onInvalid();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tenantAdmins,
    tenantOwners,
    isRequired,
    isSearchingWorkAccount,
    currentValue,
    tenantId,
    validatedWorkAccounts,
    lastVerified,
    onValid,
    onInvalid,
    msEmailError,
    regexError,
  ]);

  React.useEffect(() => {
    let list: ExistingOwner[] = [];
    let error: LoadingErrorType = LoadingErrorType.None;
    const ownerError = errorLoadingOwners || errorLoadingUpns;
    if (ownerError && errorLoadingAdmins) {
      error = LoadingErrorType.ErrorLoadingEverything;
    } else if (ownerError) {
      list = createPartialList(tenantAdmins, OwnerType.GlobalAdmin);
      error = LoadingErrorType.ErrorLoadingOwners;
    } else if (errorLoadingAdmins) {
      const ownerList = createPartialList(tenantOwners, OwnerType.Owner);
      const contributorList = createPartialList(tenantContributors, OwnerType.Contributor);
      list = ownerList.concat(contributorList);
      error = LoadingErrorType.ErrorLoadingAdmins;
    } else {
      list = createCompleteList(tenantOwners, tenantContributors, tenantAdmins);
      if (list.length && !ownersExist) {
        setOwnersExist(true);
      } else if (!list.length && ownersExist) {
        setOwnersExist(false);
      }
    }
    setDisplayList(list);
    setLoadingError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errorLoadingOwners,
    errorLoadingUpns,
    errorLoadingAdmins,
    setDisplayList,
    setLoadingError,
    tenantAdmins,
    tenantOwners,
  ]);

  const showSpinner = loadingTenantRoles || loadingTenantAdmins || loadingTenantUpns;
  // TODO: masivaia - add back showWarning when Jason's deliverable with new requirements is ready
  const showMessage = !!errorMessage || isError ? errorMessage : '';

  return (
    <div>
      <TextBodySmall addClass={classes.secondaryText}>
        {t(
          'quote::A work account is an Active Directory, administrative account, within the previously specified tenant. The work account for this quote needs to be verified that it exists on the tenant before it can be added to the quote.'
        )}
      </TextBodySmall>
      <VerificationField
        buttonText={t('quote::Verify')}
        containerClassName={classes.input}
        dataAutomationId="workAccountVerification"
        errorMessage={showMessage}
        id="work-account-input"
        invalidStatusText={t('quote::Invalid')}
        isError={!!errorMessage || isError} // TODO: masivaia - add back showWarning when Jason's deliverable with new requirements is ready
        isLoading={isSearchingWorkAccount}
        isVerified={currentValue === lastVerified && !errorMessage}
        lastVerified={lastVerified}
        showButtonWhenInvalid={false}
        textboxLabel={t('quote::Work account')}
        textboxPlaceholder={t('quote::AD work account on an Azure or O365 tenant')}
        textboxRequired={true}
        textboxValue={currentValue}
        validationErrorMessage={regexError}
        verifiedStatusText={t('quote::Valid')}
        onChangeDebounced={setCurrentValue}
        onValidate={(value: string) => emailIsValid.test(value)}
        onVerify={(value: string) => {
          onInvalid();
          searchWorkAccount({
            email: value,
            tenantIds: [tenantId],
          });
          setLastVerified(value);
        }}
      />
      {ownersExist && (
        <ExistingOwnersList
          error={loadingError}
          list={displayList}
          loading={showSpinner}
          maxHeight={286}
          showTooltip={true}
        />
      )}
    </div>
  );
};

export const WorkAccountFooterButtons = (
  isFromTenant: boolean,
  buttonDisabled: boolean,
  onButtonClick: () => void,
  onBackButtonClick?: () => void,
  isWorkAccountUnauthorized?: boolean
) => {
  const saveWorkAccount = () => {
    isWorkAccountUnauthorized &&
      loggerService.log({
        name: 'Existing Owners - user proceeds with unauthorized work account',
      });
    onButtonClick();
  };
  if (isFromTenant) {
    return [
      <SecondaryButton
        dataAutomationId="backToTenantButton"
        key="back-to-tenant"
        text={i18next.t('quote::Back')}
        onClick={onBackButtonClick}
      />,
      <PrimaryButton
        dataAutomationId="saveEmailButton"
        disabled={buttonDisabled}
        key="save-email"
        text={i18next.t('quote::Add work account')}
        onClick={saveWorkAccount}
      />,
    ];
  } else {
    return [
      <PrimaryButton
        dataAutomationId="saveWorkAccountButton"
        disabled={buttonDisabled}
        key="save-work-account"
        text={i18next.t('quote::Save work account')}
        onClick={saveWorkAccount}
      />,
    ];
  }
};

export const WorkAccountBodyStyled = withStyles(workAccountStyles)(WorkAccountBodyUnstyled);
export const WorkAccountBody = connect(mapStateToProps, dispatchProps)(WorkAccountBodyStyled);
