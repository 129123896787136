import { AuthError } from 'msal/lib-commonjs/error/AuthError';
import { Configuration, LoginType, MsalAuthProvider } from 'react-aad-msal';
import loggerService from 'services/logger-service';

export enum TokenAuthorities {
  Agreements = 'Agreements',
  CRM = 'CRM',
  Graph = 'Graph',
  L2O = 'L2O',
  LDSSInt = 'LDSSInt',
  LDSSProd = 'LDSSProd',
  QuoteInt = 'QuoteInt',
  QuoteProd = 'QuoteProd',
  UserPreferenceInt = 'UserPreferenceInt',
  UserPreferenceProd = 'UserPreferenceProd',
}

export enum AadEnvironment {
  Int = 'int',
  Pilot = 'pilot',
  Prod = 'prod',
}

export const AppId: Record<AadEnvironment, string> = {
  [AadEnvironment.Int]: '83d54472-73cb-4e3d-b4b6-c7c96eb1ad4c',
  [AadEnvironment.Pilot]: '3371a066-ea32-4ea8-ba2b-eeb2713cd448',
  [AadEnvironment.Prod]: '361bba98-ec25-4ee4-8fb0-946fe4068e1e',
};

const protectedResourceMap = new Map<string, string[]>();

protectedResourceMap.set(TokenAuthorities.QuoteProd, [
  'https://microsoft.onmicrosoft.com/766afa55-3d43-413c-a7b3-267b2647000a/user_impersonation',
]);
protectedResourceMap.set(TokenAuthorities.QuoteInt, [
  'https://microsoft.onmicrosoft.com/f6940281-6539-4556-b07f-51fec0f97285/user_impersonation',
]);
protectedResourceMap.set(TokenAuthorities.L2O, [
  'https://microsoft.onmicrosoft.com/L2O/user_impersonation',
]);
protectedResourceMap.set(TokenAuthorities.LDSSProd, [
  'https://L2OCommerceInsightsProd/user_impersonation',
]);
protectedResourceMap.set(TokenAuthorities.LDSSInt, [
  'https://L2OCommerceInsights/user_impersonation',
]);
protectedResourceMap.set(TokenAuthorities.CRM, [
  'https://msitsalesds-us-prod.cloudapp.net//user_impersonation',
]);
protectedResourceMap.set(TokenAuthorities.Graph, ['https://graph.windows.net/User.Read']);
protectedResourceMap.set(TokenAuthorities.Agreements, [
  'https://microsoft.onmicrosoft.com/agreementservice/user_impersonation',
]);
protectedResourceMap.set(TokenAuthorities.UserPreferenceInt, [
  'ed7e25ee-a3f9-4a8a-8505-55fb497385a7/L2OUIConfigurationsINT2',
]);
protectedResourceMap.set(TokenAuthorities.UserPreferenceProd, [
  'db925cc9-90c6-4ddc-8ffa-f8bf0911dbbb/L2OUIConfigurationsProd',
]);

const config: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/microsoft.onmicrosoft.com',
    clientId: '',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  framework: {
    protectedResourceMap,
  },
};

const authenticationParameters = {
  scopes: ['https://microsoft.onmicrosoft.com/L2O/user_impersonation'],
};

export let authProvider: MsalAuthProvider;

// See readme.md [Issue-01]
const logRedirectCallbackErrors = (error: AuthError) => {
  if (error && error.name === 'ClientAuthError') {
    // Clear session to remove "login in process" error
    sessionStorage.clear();
    localStorage.clear();
    authProvider.loginRedirect(authenticationParameters);
    loggerService.error({
      error: new Error(
        `${error.name || ''} :: ${error.errorCode || ''} :: ${error.errorMessage || ''}`
      ),
    });
  }
};

export const initAuthProvider = (clientId: string) => {
  authProvider = new MsalAuthProvider(
    { ...config, auth: { ...config.auth, clientId } },
    authenticationParameters,
    LoginType.Redirect
  );

  authProvider.handleRedirectCallback(logRedirectCallbackErrors);

  return authProvider;
};
