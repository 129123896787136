export enum ProductFamily {
  Azure = 'Azure',
  Apps = 'Apps',
  Passes = 'Passes',
  Devices = 'Devices',
  Software = 'Software',
  NegotiatedTerms = 'NegotiatedTerms',
}

export enum ServiceFamily {
  Saas = 'saas',
  Compute = 'compute',
}

export interface ProductAttributeValue {
  Name: string;
}

export interface Image {
  Uri: string;
}

export interface SkuLocalizedProperty {
  SkuTitle: string;
  SkuDescription: string;
}

export interface ProductIdentifier {
  productId: string;
  skuId?: string;
  availabilityId?: string;
  transactingAvailabilityId?: string;
  action?: string;
  availabilityTermId?: string;
  productFamily?: string;
  productType?: string;
}

export interface CatalogPageProduct {
  categories?: ProductAttributeValue[];
  description: string;
  id: string;
  identifier: ProductIdentifier;
  industries?: ProductAttributeValue[];
  publisherName: string;
  images: {
    small?: Image;
    medium?: Image;
    logo?: Image;
  };
  skus: SkuLocalizedProperty[];
  title: string;
}

export interface CatalogPageNegotiatedTerm {
  description: string;
  id: string;
  images: {
    small?: Image;
    medium?: Image;
    logo?: Image;
  };
  skus: SkuLocalizedProperty[];
  title: string;
  publisherName: string;
}
