import { authProvider } from 'services/utils';

import cv from '../cv';
import { Filter, PageRequest, TestHeader } from '../types';

function randomHexDigit() {
  return Math.floor(Math.random() * 16).toString(16);
}

export function isNeedsTranslation(arg: unknown): arg is NeedsTranslation {
  return (arg as NeedsTranslation).type === 'NeedsTranslation';
}

export interface NeedsTranslation {
  type: 'NeedsTranslation';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
  value: string;
}

export function getCV(): string {
  if (!cv.isInit()) {
    cv.init();
  }
  const val = cv.getValue();
  cv.increment();
  return val || '';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function t(value: string, options?: any): NeedsTranslation {
  return { type: 'NeedsTranslation', value, options };
}

export function createGuid(): string {
  const guidPattern = 'xxxxxxxx-xxxx-4xxx-Rxxx-xxxxxxxxxxxx';
  const result = guidPattern.replace(/x/g, randomHexDigit);

  // update remaining 2 bits of first digit of the clock_seq_hi_and_reserved:
  return result.replace('R', (8 | Math.floor(Math.random() * 3)).toString(16));
}

export const approvalOverrideScenarios = [
  'quoteCenterTest',
  'TestApprovalOverride',
  'TestRejectionOverride',
];

export const getTestHeader = function(scenarios: string[] = ['quoteCenterTest']): string {
  // TODO: Test header info should be configurable and should go to single configuration file.
  let retentionDate = new Date();
  retentionDate.setDate(retentionDate.getDate() + 14);
  const testHeader: TestHeader = {
    contact: 'qcen@microsoft.com',
    retention: retentionDate,
    scenarios: scenarios.join(','),
  };
  return JSON.stringify(testHeader);
};

export async function getAuthHeader(endpoint: string): Promise<string> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const msal: any = window.msal;
  const scopes = msal.getScopesForEndpoint(endpoint);
  const token = await authProvider.getAccessToken({ scopes });

  return `Bearer ${token.accessToken}`;
}

export function validatePageRequest(request: PageRequest) {
  if (!request.id && !request.nextLink) {
    throw new Error('invalid request');
  }
}

export function getFilterString<T>(filter: Filter<T>): string {
  if ('filters' in filter) {
    const strings = filter.filters.map(filter => getFilterString(filter));
    const joined = strings.join(` ${filter.operation} `);
    return `(${joined})`;
  } else {
    const value = filter.operation === 'matches' ? filter.value.toLowerCase() : filter.value;
    return `${filter.filterField} ${filter.operation} ${value}`;
  }
}

export function sleepCreator(ms: number) {
  const innerFunction = (resolve: () => void) => setTimeout(resolve, ms);
  return () => new Promise(innerFunction);
}

export function getUnique<T>(arr: T[], getId: (element: T) => string): T[] {
  const set: Set<string> = new Set();
  return arr.filter(element => {
    const key = getId(element);
    return !set.has(key) && !!set.add(key);
  });
}
