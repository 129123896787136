import { AxiosResponse } from 'axios';
import { GetPreapprovedECIFs } from 'features-apollo/quote/components/queries';
import * as actions from 'features/customer/actions';
import {
  getAssetsByAccountId,
  getLeadOrganization,
  getOrganization,
  getOrganizations,
  getOrganizationsByType,
} from 'features/customer/selectors';
import { getActiveProposal } from 'features/proposal/selectors';
import { defaultLanguage } from 'features/proposal/supported-languages';
import { ModernFootprint } from 'features/proposal/types';
import { isAgreementTypeLegacy } from 'features/proposal/utils';
import { all, call, put, select } from 'redux-saga/effects';
import { api } from 'services';
import { AccountConfig } from 'services/account/config';
import { Account } from 'services/account/types';
import { AgreementConfig, mcaTemplateIds } from 'services/agreement';
import {
  Agreement,
  AgreementPreviewRequest,
  AgreementPreviewResponse,
  GetAgreementRequest,
} from 'services/agreement/types';
import { apolloClient } from 'services/apollo/apolloClient';
import { ProductFamily } from 'services/catalog/types';
import { CustomerConfig } from 'services/customer/config';
import {
  GetOrganizationRequest,
  LeadOrganizationSummary,
  OrganizationSummary,
  OrganizationType,
} from 'services/customer/types';
import {
  OrganizationSearchResponse,
  OrganizationSearchResponseItem,
} from 'services/edge-search/types';
import { EdgeConfig } from 'services/edge/config';
import { Asset } from 'services/edge/types';
import { LDSSConfig } from 'services/ldss/config';
import { Agreement as Enrollment } from 'services/ldss/types';
import { ProjectConfig } from 'services/project/config';
import { FilterType, Project } from 'services/project/types';
import { OneAskResult, Proposal } from 'services/proposal/types';
import { PageResponse } from 'services/types';
import { t } from 'services/utils';
import store from 'store';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { searchOrganizations } from './searchOrganizations';

export function* loadAssetsByOrganization(accountId: string) {
  const isLegacy: boolean = yield select((state: RootState) => {
    const quote = getActiveProposal(state);
    return isAgreementTypeLegacy(quote);
  });
  if (isLegacy) {
    return;
  }
  const fromState: Asset = yield select((state: RootState) =>
    getAssetsByAccountId(state, accountId)
  );
  if (fromState) {
    return fromState;
  }
  yield put(
    actions.getAssetsByOrganizationAsync.request({
      accountId,
    })
  );
  const edgeConfig: EdgeConfig = yield select((state: RootState) => state.app.appConfig.edge);
  try {
    const response: PageResponse<Asset> = yield call(
      api.edge.getAssetsByOrganization,
      {
        accountId: accountId,
      },
      edgeConfig
    );
    const filteredByStateAndOrganizationId = response.value.filter((unfiltered: Asset) => {
      const assetState = unfiltered.assetData.stateInfo.state;
      return assetState === 'active' || assetState === 'warning';
    });
    yield put(
      actions.getAssetsByOrganizationAsync.success({
        id: accountId,
        value: filteredByStateAndOrganizationId,
      })
    );
    return filteredByStateAndOrganizationId;
  } catch (err) {
    yield put(
      actions.getAssetsByOrganizationAsync.failure({
        message: t('error::Error loading assets by organization'),
        exception: err,
      })
    );
  }
}

function mapLeadOrganizationSummary(organization: OrganizationSummary): LeadOrganizationSummary {
  return {
    id: organization.id,
    accountId: organization.accountId,
    legalEntity: organization.legalEntity,
  };
}

export function* loadLeadOrganization(request: GetOrganizationRequest, rethrow: boolean = true) {
  const { id } = request;

  const fromState: OrganizationSummary = yield select((state: RootState) =>
    getLeadOrganization(state, id)
  );
  if (fromState) {
    return mapLeadOrganizationSummary(fromState);
  }

  yield put(actions.loadLeadOrganizationAsync.request(request));

  const customerConfig: CustomerConfig = yield select(
    (state: RootState) => state.app.appConfig.customer
  );
  try {
    const customer: OrganizationSummary = yield call(
      api.customer.getOrganization,
      request,
      customerConfig
    );
    const result = mapLeadOrganizationSummary(customer);
    yield put(actions.loadLeadOrganizationAsync.success({ id, value: result }));
    return result;
  } catch (err) {
    yield put(
      actions.loadLeadOrganizationAsync.failure({
        message: t('error::Error loading leadOrg billing account'),
        exception: err,
      })
    );
    if (rethrow) {
      throw err;
    }
  }
}

export function* loadOrganization(request: GetOrganizationRequest, rethrow: boolean = true) {
  const { id } = request;
  const fromState: OrganizationSummary = yield select((state: RootState) =>
    getOrganization(state, id)
  );
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadOrganizationAsync.request(request));

  const customerConfig: CustomerConfig = yield select(
    (state: RootState) => state.app.appConfig.customer
  );
  try {
    const customer: OrganizationSummary = yield call(
      api.customer.getOrganization,
      request,
      customerConfig
    );
    yield put(actions.loadOrganizationAsync.success({ id, value: customer }));
    return customer;
  } catch (err) {
    yield put(
      actions.loadOrganizationAsync.failure({
        message: t('error::Error loading lead org billing account'),
        exception: err,
      })
    );
    if (rethrow) {
      throw err;
    }
  }
}

export function* loadOrganizations(accountId: string) {
  const fromState: OrganizationSummary = yield select((state: RootState) =>
    getOrganizations(state, accountId)
  );
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadOrganizationsAsync.request(accountId));

  const customerConfig: CustomerConfig = yield select(
    (state: RootState) => state.app.appConfig.customer
  );
  try {
    const response: PageResponse<OrganizationSummary> = yield call(
      api.customer.getOrganizations,
      { id: accountId },
      customerConfig
    );
    yield put(actions.loadOrganizationsAsync.success({ id: accountId, value: response.value }));
    return response.value;
  } catch (err) {
    yield put(
      actions.loadOrganizationsAsync.failure({
        message: t('error::Error loading billing accounts by account'),
        exception: err,
      })
    );
  }
}

export function* loadOrganizationsWithAddress(accountId: string) {
  const suggestedOrganizations: OrganizationSummary[] = yield select((state: RootState) =>
    getOrganizationsByType(state, accountId, OrganizationType.organization)
  );
  if (!suggestedOrganizations || !suggestedOrganizations.length) {
    return;
  }

  yield put(actions.loadOrganizationsWithAddressAsync.request(accountId));

  try {
    yield all(
      suggestedOrganizations.map(organization =>
        call(loadOrganization, { accountId, id: organization.id }, false)
      )
    );
    yield put(actions.loadOrganizationsWithAddressAsync.success());
  } catch (err) {
    yield put(
      actions.loadOrganizationsWithAddressAsync.failure({
        message: t('error::Error loading suggested billing account address'),
        exception: err,
      })
    );
  }
}

export function* loadAccount(accountId: string) {
  const fromState: Account = yield select((state: RootState) => state.customer.account[accountId]);
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadAccountAsync.request(accountId));
  const accountConfig: AccountConfig = yield select(
    (state: RootState) => state.app.appConfig.account
  );
  try {
    const account: Account = yield call(api.account.getAccount, accountId, accountConfig);
    yield put(actions.loadAccountAsync.success(account));
    return account;
  } catch (err) {
    yield put(
      actions.loadAccountAsync.failure({
        message: t('error::Error loading account'),
        exception: err,
      })
    );
  }
}

export function* loadModernFootprint(
  accountId: string,
  organizationId: string,
  forceReload?: true
) {
  if (!accountId) {
    return;
  }

  if (!forceReload) {
    const fromState: ModernFootprint | undefined = yield select(
      (state: RootState) => state.customer.modernFootprint[accountId]
    );
    if (fromState && fromState.organization.id === organizationId) {
      return fromState;
    }
  }

  yield put(actions.loadModernFootprintByAccountAsync.request(accountId));
  const projectConfig: ProjectConfig = yield select(
    (state: RootState) => state.app.appConfig.project
  );

  try {
    const projectCall = call(
      api.project.getProjects,
      { filter: FilterType.AccountId, id: accountId },
      projectConfig
    );
    const organizationCall = call(loadOrganization, { accountId, id: organizationId });
    const accountCall = call(loadAccount, accountId);
    const {
      project,
      organization,
    }: {
      project: PageResponse<Project>;
      organization: OrganizationSummary;
    } = yield all({
      project: projectCall,
      organization: organizationCall,
      accountCall: accountCall,
    });

    const projects = project.value;
    if (organization) {
      const footprint = {
        accountId,
        organization,
        projectId: projects[0] && projects[0].id,
      };
      yield put(actions.loadModernFootprintByAccountAsync.success(footprint));
      return footprint;
    } else {
      yield put(
        actions.loadModernFootprintByAccountAsync.failure({
          message: t('error::Cannot proceed billing account not found'),
          exception: new Error(),
        })
      );
    }
  } catch (err) {
    yield put(
      actions.loadModernFootprintByAccountAsync.failure({
        message: t('error::Error loading footprint for account'),
        exception: err,
      })
    );
  }
}

export function* searchOrganizationsPropertySheet(query: string) {
  const fromState: OrganizationSearchResponseItem[] | null = yield select(
    (state: RootState) => state.customer.organizationSearch.propertySheetSearchResults[query]
  );
  if (fromState) {
    return fromState;
  }

  yield put(actions.searchOrganizationsForPropertySheetAsync.request(query));
  try {
    const response: OrganizationSearchResponse | undefined = yield call(searchOrganizations, query);
    if (!response) {
      throw new Error(
        `Cannot proceed, search billing accounts resulted in an invalid response. searchQuery: ${query}`
      );
    }
    yield put(
      actions.searchOrganizationsForPropertySheetAsync.success({ id: query, value: response.items })
    );
    return response.items;
  } catch (err) {
    yield put(
      actions.searchOrganizationsForPropertySheetAsync.failure({
        message: t('error::Error searching billing accounts'),
        exception: err,
      })
    );
  }
}

export function* loadAgreements(accountId: string, organizationId: string) {
  const request: GetAgreementRequest = { accountId, organizationId };

  const fromState: boolean | undefined = yield select(
    (state: RootState) => state.customer.signedMCA[organizationId]
  );
  //false implies MCA not signed. undefined implies we haven't determined signed state yet
  if (fromState !== undefined) {
    return fromState;
  }

  yield put(actions.loadAgreementsAsync.request(request));
  const agreementConfig: AgreementConfig = yield select(
    (state: RootState) => state.app.appConfig.agreement
  );
  try {
    const result: Agreement[] = yield call(api.agreement.getAgreements, request, agreementConfig);
    yield put(
      actions.loadAgreementsAsync.success({ id: request.organizationId, value: !!result.length })
    );
    return !!result.length;
  } catch (err) {
    yield put(
      actions.loadAgreementsAsync.failure({
        message: t('error::Error loading agreements for billing account'),
        exception: err,
      })
    );
  }
}

export function* loadAgreementPreview(proposal: Proposal) {
  const agreementConfig: AgreementConfig = yield select(
    (state: RootState) => state.app.appConfig.agreement
  );
  const language = proposal.header.pricingContext.languages || defaultLanguage;
  const request: AgreementPreviewRequest = {
    TemplateId: mcaTemplateIds[agreementConfig.environment],
    LanguageLocale: language,
    Constraints: {
      Tenant: 'Public',
      Segment: 'Commercial',
      Country: proposal.header.pricingContext.market,
    },
    AssemblyOptions: {
      Watermark: true,
    },
  };

  const hasNegotiatedTerms = proposal.lineItems.some(
    lineItem => lineItem.productIdentifier.productFamily === ProductFamily.NegotiatedTerms
  );
  if (hasNegotiatedTerms) {
    request.ExternalReference = {
      ExternalReferenceType: 'Quote',
      Id: proposal.id,
    };
  }
  const customerIdParts = oc(proposal).header.soldToCustomerLegalEntity();
  if (customerIdParts && customerIdParts.accountId && customerIdParts.organizationId) {
    request.CustomerId = `${customerIdParts.accountId}_${customerIdParts.organizationId}`;
  }
  try {
    yield put(actions.loadAgreementPreviewAsync.request(request));
    const result: AxiosResponse<AgreementPreviewResponse> = yield call(
      api.agreement.getAgreementPreview,
      request,
      agreementConfig
    );
    if (result.status !== 204 && result.status !== 200) {
      throw new Error('Received response other than 200 or 204 from agreement service.');
    }
    const response: AgreementPreviewResponse = {
      documentId: result.data.documentId,
      documentDisplayUri: result.data.documentDisplayUri,
      documentDownloadUri: result.data.documentDownloadUri,
    };
    yield put(actions.loadAgreementPreviewAsync.success(response));
    return response;
  } catch (err) {
    yield put(
      actions.loadAgreementPreviewAsync.failure({
        message: t('error::Error loading agreement preview.'),
        exception: err,
      })
    );
  }
}

export function* loadEnrollment(enrollmentNumber: string) {
  const fromState: Enrollment = yield select(
    (state: RootState) => state.customer.enrollment[enrollmentNumber]
  );
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadEnrollmentAsync.request(enrollmentNumber));
  const ldssConfig: LDSSConfig = yield select((state: RootState) => state.app.appConfig.ldss);
  try {
    const result: Enrollment = yield call(api.ldss.getAgreement, enrollmentNumber, ldssConfig);
    yield put(actions.loadEnrollmentAsync.success({ id: enrollmentNumber, value: result }));
    return result;
  } catch (err) {
    yield put(
      actions.loadEnrollmentAsync.failure({
        message: t('error::Error loading ldss agreement'),
        exception: err,
      })
    );
  }
}

export function* loadProjectFromEnrollment(enrollmentNumber: string) {
  const fromState: Enrollment = yield select(
    (state: RootState) => state.customer.project[enrollmentNumber]
  );
  if (fromState) {
    return fromState;
  }

  yield put(actions.loadProjectFromEnrollmentAsync.request(enrollmentNumber));
  const projectConfig: ProjectConfig = yield select(
    (state: RootState) => state.app.appConfig.project
  );
  try {
    const response: PageResponse<Project> = yield call(
      api.project.getProjects,
      { id: enrollmentNumber, filter: FilterType.ExternalReferenceId },
      projectConfig
    );
    yield put(
      actions.loadProjectFromEnrollmentAsync.success({
        id: enrollmentNumber,
        value: response.value[0] || null,
      })
    );
    //TODO: Remove when migrating legacy
    yield put(actions.mapAccountToProjectAsync.request(response.value, enrollmentNumber));
    return response;
  } catch (err) {
    yield put(
      actions.loadProjectFromEnrollmentAsync.failure({
        message: t('error::Error loading project from enrollment'),
        exception: err,
      })
    );
  }
}

async function getPreapprovedECIFs(quoteId: string) {
  const { data, errors } = await apolloClient(store).query({
    variables: {
      quoteId,
    },
    fetchPolicy: 'no-cache',
    query: GetPreapprovedECIFs,
  });
  if (errors) {
    return errors;
  }
  return data.getQuote.header.ecifTerms;
}

export function* loadPreapprovedECIFs(quoteId: string) {
  yield put(actions.loadPreapprovedECIFsAsync.request(quoteId));

  try {
    let results: OneAskResult[] = [];
    if (quoteId) {
      // make call to GQL
      try {
        results = yield call(getPreapprovedECIFs, quoteId);
      } catch (errors) {
        yield put(
          actions.loadPreapprovedECIFsAsync.failure({
            message: t('error::Error loading ECIFs'),
            exception: errors,
          })
        );
        return;
      }
    }
    results = !results ? [] : results;
    yield put(actions.loadPreapprovedECIFsAsync.success(results));
    return results;
  } catch (err) {
    yield put(
      actions.loadPreapprovedECIFsAsync.failure({
        message: t('error::Error loading ECIFs'),
        exception: err,
      })
    );
  }
}
