import { ISpinnerStyles, Spinner as FabricSpinner, SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { FontSizes, ThemeProps } from 'styles';

export { SpinnerSize };

const styles = (theme: ThemeProps) => ({
  circle: {
    borderColor: `${theme.palette.textLink} ${theme.palette.backgroundDivider} ${theme.palette.backgroundDivider}`,
    color: theme.palette.textLink,
  },
  label: {
    color: theme.palette.textLink,
    paddingLeft: (props: SpinnerProps) => props.labelPadding,
    fontSize: (props: SpinnerProps) => props.labelSize && theme.fonts.fontSizes[props.labelSize],
  },
});

export interface SpinnerProps {
  addClass?: string;
  ariaLabel?: string;
  dataAutomationId?: string;
  id?: string;
  size?: SpinnerSize;
  className?: string;
  label?: string;
  labelSize?: keyof FontSizes;
  labelPadding?: number;
  labelPosition?: 'bottom' | 'left' | 'right' | 'top' | undefined;
  styles?: Partial<ISpinnerStyles>;
}

type Props = SpinnerProps & WithStyles<typeof styles>;

export const SpinnerUnstyled: React.FC<Props> = props => {
  const { id, className, classes, label, labelPosition, dataAutomationId } = props;
  let size = props.size || SpinnerSize.large;
  const spinnerStyle: Partial<ISpinnerStyles> = {
    label: classes.label,
    circle: classes.circle,
    ...props.styles,
  };

  return (
    <FabricSpinner
      ariaLabel={props.ariaLabel}
      className={`${className} ${props.addClass}`}
      data-automation-id={dataAutomationId}
      id={id}
      label={label}
      labelPosition={labelPosition}
      size={size}
      styles={spinnerStyle}
    />
  );
};

export const Spinner = withStyles(styles)(SpinnerUnstyled);
