import {
  ComboBox,
  CustomDialogBox,
  DialogHeader,
  SecondaryButton,
  TextboxStandard,
  TextTitle,
} from 'components/ions';
import { IComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/components/ComboBox';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { commentLengthLimit } from 'services/approval/config';
import { NegotiationReason } from 'services/proposal/types';
import { sharedStyles, ThemeProps } from 'styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),

  businessJustification: {
    width: 300,
    paddingBottom: 16,
  },
});

// Props ------------------------------------------------------------------

export interface JustificationViewProps {
  defaultExplanation?: string;
  defaultJustification?: NegotiationReason;
  title: string;
  height: number;
  width: number;
  hideButtons?: boolean;
  hasAnyDiscount?: boolean;
  closeDialog: () => void;
  onNextClick?: (justification: NegotiationReason, explanation?: string) => void;
}

type Props = WithStyles<typeof styles> & JustificationViewProps;

// Component ---------------------------------------------------------------------

const JustificationViewUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const [explanation, setExplanation] = React.useState<{ value: string }>({
    value: props.defaultExplanation || '',
  });
  const [justification, setJustification] = React.useState<NegotiationReason>(
    props.defaultJustification || NegotiationReason.Unknown
  );

  const handleExplanationChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue) {
      setExplanation({
        value:
          newValue.length <= commentLengthLimit
            ? newValue
            : newValue.substring(0, commentLengthLimit),
      });
    } else {
      setExplanation({ value: '' });
    }
  };

  const handleNextClick = () =>
    props.onNextClick && props.onNextClick(justification, explanation.value);

  const Header = (
    <DialogHeader
      closeButtonClass={classes.customDialogCloseButton}
      dataAutomationId="submitForApproval"
      dialogClose={props.closeDialog}
      headerClass={classes.customDialogHeader}
    >
      <TextTitle>{props.title}</TextTitle>
    </DialogHeader>
  );

  const onJustificationSelect = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option) {
      setJustification(option.key as NegotiationReason);
    }
  };

  const justificationOptions: IComboBoxOption[] = [
    { key: NegotiationReason.Compete.toString(), text: t('quote::Compete') },
    { key: NegotiationReason.Aggregation.toString(), text: t('quote::Aggregation') },
    {
      key: NegotiationReason.FrameworkAgreement.toString(),
      text: t('quote::Framework Agreement'),
    },
    {
      key: NegotiationReason.LicensingProgramConflict.toString(),
      text: t('quote::Licensing Program Conflict'),
    },
    {
      key: NegotiationReason.BudgetConstraints.toString(),
      text: t('quote::Budget Constraints'),
    },
    {
      key: NegotiationReason.LocalMarketDynamics.toString(),
      text: t('quote::Local Market Dynamics'),
    },
    {
      key: NegotiationReason.SeedingOrDeploymentRamp.toString(),
      text: t('quote::Seeding or Deployment Ramp'),
    },
    { key: NegotiationReason.PriceIncreases.toString(), text: t('quote::Price Increases') },
  ];

  const businessJustification = () => {
    if (props.hasAnyDiscount) {
      return (
        <div className={classes.businessJustification}>
          <ComboBox
            id="businessJustificationDropdown"
            label={t('quote::Business justification')}
            options={justificationOptions}
            placeholder={t('quote::Select the primary reason')}
            required={true}
            selectedKey={justification}
            onChange={onJustificationSelect}
          />
        </div>
      );
    }
  };

  const Body = (
    <div className={classes.customDialogBody}>
      {businessJustification()}
      <TextboxStandard
        dataAutomationId="detailedExplanation"
        label={t('quote::Detailed explanation')}
        multiline={true}
        resizable={false}
        rows={props.hasAnyDiscount ? 10 : 14}
        value={explanation.value}
        onChange={handleExplanationChange}
      />
    </div>
  );

  const Footer = (
    <div className={`${classes.customDialogFooter} ${classes.flexAlignRight}`}>
      <SecondaryButton
        dataAutomationId="submitForApprovalNext"
        disabled={props.hasAnyDiscount && justification === NegotiationReason.Unknown}
        id="submitForApprovalNext"
        text={t('quote::Next')}
        onClick={handleNextClick}
      />
    </div>
  );

  return (
    <CustomDialogBox
      bodySlot={Body}
      footerSlot={!props.hideButtons && Footer}
      headerSlot={Header}
      height={props.height}
      width={props.width}
    />
  );
};

export const JustificationView = withStyles(styles)(JustificationViewUnstyled);
