import { PrimaryButton, SecondaryButton, TextBodySmall, VerificationField } from 'components';
import { extractDomainFromEmail, MS_EMAIL } from 'features-apollo/quote/components/utils';
import { WizardBodyProps } from 'features-apollo/quote/components/Wizards/shared';
import { emailIsValid } from 'features/proposal/utils';
import { QuoteMutationInput } from 'generated/graphql';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import loggerService from 'services/logger-service';
import { oc } from 'ts-optchain';

import { useLazyQuery } from '@apollo/react-hooks';

import { VerifyInvitedUser } from '../queries';
import { GqlVerifyInvitedUserErrors, ViralTenantMessage } from '../Shared';
import { signupEmailStyles } from './SignupEmail.styles';
import { TenantExists } from './TenantExists';

export const SignupEmailHeaderString = (hasInitialData: boolean) => {
  if (hasInitialData) {
    return i18next.t('quote::Edit sign up email address');
  } else {
    return i18next.t('quote::Add sign up email address');
  }
};

const initializeLastVerified = (initialData?: string) => {
  if (initialData) {
    const domain = extractDomainFromEmail(initialData);
    if (!domain) {
      loggerService.error({
        error: new Error('We have an email without @ coming as the initial data'),
      });
    }
    return initialData;
  }
};

export interface SignupEmailBodyProps extends WizardBodyProps<string | undefined> {
  quoteMutationInput: QuoteMutationInput;
}

type Props = SignupEmailBodyProps & WithStyles<typeof signupEmailStyles>;

const SignupEmailBodyUnstyled: React.FC<Props> = props => {
  const { classes, initialData, quoteMutationInput, onInvalid, onValid } = props;
  const { t } = useTranslation();

  //#region State
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(initialData);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [isTenantViral, setIsTenantViral] = React.useState<boolean>(false);
  const [tenantExists, setTenantExists] = React.useState<boolean>(false);
  const [lastVerified, setLastVerified] = React.useState<string | undefined>(() => {
    return initializeLastVerified(initialData);
  });
  //#endregion

  const lastVerifiedSameAsCurrent = lastVerified === currentValue;

  //#region Messages
  const regexError = t('quote::Enter a valid email address');
  const msEmailError = t('quote::You cannot use a Microsoft email address for a customer');
  const loadingError = t('error::We encountered a problem while verifying. Please try again.');
  const viralTenantMessage = isTenantViral && lastVerifiedSameAsCurrent && (
    <div className={classes.messages}>
      <ViralTenantMessage />
    </div>
  );
  const tenantExistsMessage = tenantExists && lastVerifiedSameAsCurrent && (
    <div className={classes.messages}>
      <TenantExists />
    </div>
  );
  //#endregion

  const [verifyEmail, { loading, error }] = useLazyQuery(VerifyInvitedUser, {
    onCompleted: data => {
      if (data.verifyInvitedUser === true) {
        onValid(lastVerified);
      } else {
        onInvalid();
      }
    },
    onError: error => {
      const errorCode = oc(error).graphQLErrors[0].extensions.code('');
      if (errorCode === GqlVerifyInvitedUserErrors.ViralTenant) {
        setIsTenantViral(true);
        onInvalid();
      } else if (errorCode === GqlVerifyInvitedUserErrors.TenantKnown) {
        setTenantExists(true);
        onInvalid();
      } else if (errorCode === GqlVerifyInvitedUserErrors.MsEmail) {
        setErrorMessage(msEmailError);
        onInvalid();
      } else if (errorCode === GqlVerifyInvitedUserErrors.InvalidEmail) {
        setErrorMessage(regexError);
        onInvalid();
      } else {
        setErrorMessage(loadingError);
        onInvalid();
      }
    },
  });

  const onValidateEmail = (value?: string) =>
    !!(value && emailIsValid.test(value) && !value.endsWith(MS_EMAIL));

  const onSignUpEmailChange = (value?: string) => {
    setIsTenantViral(false);
    setCurrentValue(value);
    if (!value) {
      setErrorMessage(undefined);
    } else if (value.endsWith(MS_EMAIL)) {
      setErrorMessage(msEmailError);
    } else if (!emailIsValid.test(value)) {
      setErrorMessage(regexError);
    } else {
      setErrorMessage(undefined);
    }
  };

  const onSearch = (value: string) => {
    verifyEmail({
      variables: { input: { quote: quoteMutationInput, email: value } },
    });
    setCurrentValue(value);
    setLastVerified(value);
  };

  const isIdleWithoutErrors = !loading && !error;

  let message = null;

  if (isIdleWithoutErrors && lastVerified) {
    message = viralTenantMessage || tenantExistsMessage;
  }

  const isVerified = !!lastVerified && !message && lastVerifiedSameAsCurrent;

  return (
    <div>
      <TextBodySmall addClass={classes.secondaryText}>
        {t(
          'quote::You have indicated that there is no existing tenant for this customer. Please provide the email address for the customer representative that will receive the signup instructions to create their company’s Azure account. This representative will be defined as the Commerce Admin, and will be able to create additional accounts for the rest of the members on their team.'
        )}
      </TextBodySmall>
      <VerificationField
        allowTryAgain={error && !!currentValue}
        buttonText={t('Verify')}
        dataAutomationId="emailVerification"
        errorMessage={errorMessage}
        isError={!!error || !!errorMessage}
        isLoading={loading}
        isVerified={isVerified}
        lastVerified={lastVerified}
        textboxLabel={t('quote::Email address')}
        textboxPlaceholder={t('quote::Administrative email address')}
        textboxRequired={true}
        textboxValue={currentValue}
        validationErrorMessage={errorMessage}
        verifiedStatusText={t('quote::Verified')}
        onChangeDebounced={onSignUpEmailChange}
        onValidate={onValidateEmail}
        onVerify={onSearch}
      />
      {message}
    </div>
  );
};

export const SignupEmailFooterButtons = (
  isFromTenant: boolean,
  buttonDisabled: boolean,
  onButtonClick: () => void,
  onBackButtonClick?: () => void
) => {
  if (isFromTenant) {
    return [
      <SecondaryButton
        dataAutomationId="signUpEmailBackButton"
        key="back-to-tenant"
        text={i18next.t('quote::Back')}
        onClick={onBackButtonClick}
      />,
      <PrimaryButton
        dataAutomationId="signUpEmailSaveButton"
        disabled={buttonDisabled}
        key="save-email"
        text={i18next.t('quote::Save')}
        onClick={onButtonClick}
      />,
    ];
  } else {
    return [
      <PrimaryButton
        dataAutomationId="saveEmailAddressButton"
        disabled={buttonDisabled}
        key="save-email"
        text={i18next.t('quote::Save email address')}
        onClick={onButtonClick}
      />,
    ];
  }
};

export const SignupEmailBody = withStyles(signupEmailStyles)(SignupEmailBodyUnstyled);
