import {
  BorderlessButton,
  BorderlessSearch,
  LinkButton,
  RenderIfExistsSpan,
  Spinner,
  TextBodyLarge,
  TextTitleSecondary,
  TextWatermark,
} from 'components';
import { ProgressIndicatorAtom } from 'components/atoms';
import { SectionSeparator } from 'components/ions/SectionSeparator';
import { getFlightIsEnabled } from 'features/app/selectors';
import { ProductType } from 'features/catalog';
import * as catalogActions from 'features/catalog/actions';
import * as catalogSelectors from 'features/catalog/selectors';
import {
  openDeleteProductGroupDialog,
  openRenameProductGroupDialog,
} from 'features/components/dialogs/FavoriteProductDialogs';
import { FavoriteButton } from 'features/components/Favorites';
import * as customerSelectors from 'features/customer/selectors';
import * as actions from 'features/proposal/actions';
import * as selectors from 'features/proposal/selectors';
import {
  buildProductIdentifier,
  createLineItem,
  createLineItems,
  getCRMId,
  isAgreementTypeLegacy,
} from 'features/proposal/utils';
import { addProductToFavorites, removeProductFromFavorites } from 'features/user/actions';
import { Icon, IProgressIndicatorStyles, ISearchBox } from 'office-ui-fabric-react';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import {
  Product,
  ProductFamily,
  ProductSearchRequest,
  TermSearchRequest,
} from 'services/catalog/types';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { endpoints as proposalServiceEndpoints } from 'services/proposal/config';
import { ProductIdentifier } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles/DialogueProvider/DialogProvider';
import { oc } from 'ts-optchain';

import { ConvertAssetDialog } from '../Dialogs';
import { CreateLineItemArgs, Entities, ExtendedDragDropProps, FinderItem } from './DragAndDrop';
import { finderStyles } from './Finder.styles';
import { FinderList } from './FinderList';
import { productButtonKeyboardNavigation } from './keyboardNavigation';

/**
 * Props for the Finder feature
 * @prop {ProductType} productType - Type of 'Product' in context (Product or Term)
 * @prop {ExtendedDragDropProps} finderListProps - passes the necessary functions and props created in the DragDropContext (Editor) to the child components
 */

export interface FinderProps {
  productType: ProductType;
  finderListProps: ExtendedDragDropProps;
  isDragEvent: boolean;
  preventKeyClick: boolean;
  searchComponentRef?: React.RefObject<ISearchBox>;
  displayFinderList?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: FinderProps) => {
  const activeProposal = selectors.getActiveProposal(state);
  const isLegacy = isAgreementTypeLegacy(activeProposal);
  const { products, productFamiliesWithMoreResults } = state.catalog.search.results;
  const expandSearchProcessingSelector = catalogSelectors.expandSearchProcessing(state);
  const accountId = selectors.getAccountId(state, activeProposal);

  return {
    expandSearchAvailable: !!productFamiliesWithMoreResults.length,
    expandSearchError: !!expandSearchProcessingSelector.error,
    expandSearchProcessing: expandSearchProcessingSelector.loading,
    proposal: activeProposal,
    isLegacy,
    isProposalLoading: selectors.proposalLoading(state),
    isProposalReadonly: selectors.isProposalReadOnly(state),
    recoProducts: catalogSelectors.getRecoChannels(state, ownProps.productType),
    ecifProduct: catalogSelectors.getRecoChannels(state, ProductType.ECIF),
    isRecoLoading: catalogSelectors.recoLoading(state),
    isSearchLoading: catalogSelectors.searchLoading(state),
    searchResults: products,
    stringifiedProductKey: catalogSelectors.getStringifiedProductKey(activeProposal),
    userFavoriteProductGroups: state.user.preferences.productGroups,
    assets: accountId ? customerSelectors.getAssetsByAccountId(state, accountId) : undefined,
    assetManagementFlightEnabled: getFlightIsEnabled(state, Flight.assetManagement),
    proposalServiceEndpoint: proposalServiceEndpoints[state.app.appConfig.proposal.environment],
    selectedProject: selectors.getSelectedProject(state),
    oneAskResults: customerSelectors.getOneAskResponse(state),
    showECIF: getFlightIsEnabled(state, Flight.ECIF),
    isTermsSearchEnabled: getFlightIsEnabled(state, Flight.termsFinderSearch),
    isTermSearchLoading: catalogSelectors.termSearchLoading(state),
  };
};

const dispatchProps = {
  addProductToFavorites: addProductToFavorites.request,
  addLineItem: actions.createProposalLineItemsAsync.request,
  removeProductFromFavorites: removeProductFromFavorites.request,
  expandSearch: (searchedValue: string) =>
    catalogActions.expandProductSearchAsync.request(searchedValue),
  onSearchProducts: (request: ProductSearchRequest) =>
    catalogActions.searchProductsAsync.request(request),
  onSearchTerms: (request: TermSearchRequest) => catalogActions.searchTermsAsync.request(request),
  onClearSearch: () => catalogActions.clearSearchProducts(),
  updateAddedLineItemCount: (count: number) => actions.updateAddedLineItemCount.request(count),
};

type Props = FinderProps &
  WithStyles<typeof finderStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const loadingIndicatorStyles: Partial<IProgressIndicatorStyles> = {};
const FinderUnstyled: React.FC<Props> = ({
  expandSearch,
  expandSearchAvailable,
  expandSearchError,
  expandSearchProcessing,
  isLegacy,
  productType,
  isRecoLoading,
  classes,
  onSearchProducts,
  onClearSearch,
  proposal,
  recoProducts,
  ecifProduct,
  searchResults,
  isSearchLoading,
  stringifiedProductKey,
  isProposalReadonly,
  finderListProps,
  proposalServiceEndpoint,
  addLineItem,
  selectedProject,
  userFavoriteProductGroups,
  assets,
  assetManagementFlightEnabled,
  isDragEvent,
  preventKeyClick,
  oneAskResults,
  showECIF,
  searchComponentRef,
  updateAddedLineItemCount,
  displayFinderList,
  isTermsSearchEnabled,
  onSearchTerms,
  isTermSearchLoading,
}: Props) => {
  const [firstExpandSearch, setFirstExpandSearch] = React.useState<boolean>(true);
  const [resetFirstExpandSearch, setResetFirstExpandSearch] = React.useState<boolean>(true);

  if (!firstExpandSearch && expandSearchError && resetFirstExpandSearch) {
    setFirstExpandSearch(true);
  }

  useEffect(() => {
    onClearSearch();
  }, [proposal.id, stringifiedProductKey, productType, onClearSearch]);

  loadingIndicatorStyles.root = classes.progressBar;

  const topRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (topRef.current && topRef.current.scrollTo) {
      topRef.current.scrollTo(0, 0);
    }
  }, [topRef, searchResults]);

  const [searchValue, setSearchValue] = React.useState('');
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);

  const openAssetsDialog = () => {
    const dialogProps: DialogProps = {
      providedDialog: <ConvertAssetDialog />,
    };
    context.openDialog(dialogProps);
  };

  const onSearch = (value?: string) => {
    const newValue = value || '';
    setSearchValue(newValue);
    if (productType === ProductType.Product) {
      if (value) {
        const request = {
          query: value,
          productFamilyNames: isLegacy ? [ProductFamily.Azure] : undefined,
        };
        onSearchProducts(request);
      }
      setFirstExpandSearch(true);
    } else if (productType === ProductType.Term && isTermsSearchEnabled) {
      if (value) {
        const request = {
          query: value,
          top: 12,
        };
        onSearchTerms(request);
      }
    }
  };

  // Telemetry
  const onToggleList = (listName: string, listOpened: boolean) =>
    loggerService.log({
      name: `Finder List ${listOpened ? 'expanded' : 'collapsed'}`,
      properties: {
        listName,
      },
    });

  const onItemSelect = (productIdentifier: ProductIdentifier) => {
    const ITEM_SELECTION_COUNT = 1;
    // Using to analyze how users prefer to add items to list (click vs. drag)
    loggerService.log({ name: 'Finder Dnd - User has added item to list via click.' });

    createLineItem(
      isProposalReadonly,
      proposal,
      proposalServiceEndpoint,
      productIdentifier,
      addLineItem,
      selectedProject
    );

    updateAddedLineItemCount(ITEM_SELECTION_COUNT);
  };

  const displayList = !isRecoLoading && !!oc(recoProducts).length();
  const displayECIF = !isRecoLoading && !isProposalReadonly;

  const getItems = (entities: Entities, columnId: string): FinderItem[] => {
    return (
      entities.columns[columnId] &&
      entities.columns[columnId].itemIds.map(
        (itemId: string): FinderItem => {
          return {
            ...entities.items[itemId],
            addClass: isProposalReadonly ? classes.buttonReadonly : '',
          };
        }
      )
    );
  };

  const getECIFOnlyFinderItems = (products: Product[], alreadyAdded: boolean): FinderItem[] => {
    return products
      .map(product => {
        return oneAskResults.map(result => {
          const currencyError =
            !isProposalReadonly &&
            proposal.header.pricingContext.billingCurrency !== result.Currency;
          return {
            id: result.RequestNumber,
            productIdentifier: buildProductIdentifier(product),
            itemText:
              oneAskResults.length > 1
                ? product.LocalizedProperties[0].ProductTitle + ' - ' + result.RequestNumber
                : product.LocalizedProperties[0].ProductTitle,
            disabled: alreadyAdded || currencyError,
            error: currencyError
              ? {
                  errorTitle: t('quote::Currency mismatch'),
                  errorPrimary: t(
                    'quote::The currency in the OneAsk request ({{preapprovedCurrency}}) does not match the currency on the quote ({{quoteCurrency}}). ',
                    {
                      preapprovedCurrency: result.Currency,
                      quoteCurrency: proposal.header.pricingContext.billingCurrency,
                    }
                  ),
                  errorSecondary: t('quote::For assistance, please contact '),
                }
              : undefined,
            addClass: isProposalReadonly ? classes.buttonReadonly : '',
          };
        });
      })
      .reduce((acc, val) => acc.concat(val), []);
  };

  const recoLists =
    displayList &&
    recoProducts.map(channel => {
      const isValidChannel = !!oc(channel).products[0].LocalizedProperties[0].ProductTitle();
      return (
        isValidChannel &&
        finderListProps && (
          <div className={classes.list} key="finder-list">
            <FinderList
              displayFinderList={displayFinderList}
              draggingItemId={finderListProps.draggingItemId}
              droppableId="finderList"
              finderItems={getItems(finderListProps.entities, 'finderList')}
              id={channel.title}
              isDragEvent={isDragEvent}
              isTerms={productType === ProductType.Term}
              listHeader={channel.title}
              multiSelectTo={finderListProps.multiSelectTo}
              preventKeyClick={preventKeyClick}
              selectedItemIds={finderListProps.selectedItemIds}
              toggleSelection={finderListProps.toggleSelection}
              toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
              onItemClick={onItemSelect}
              onProductKeyDown={productButtonKeyboardNavigation}
              onToggleList={onToggleList}
            />
          </div>
        )
      );
    });

  const ecifLists =
    showECIF &&
    displayECIF &&
    productType === ProductType.Term &&
    oneAskResults &&
    ecifProduct.map(channel => {
      const isValidChannel = !!oc(channel).products[0].LocalizedProperties[0].ProductTitle();
      const alreadyAdded = proposal.lineItems.some(
        item => item.productIdentifier.productId === '0RDCKN523H1P'
      );
      const crmId = getCRMId(proposal);
      const crmIdUpper = crmId && crmId.toUpperCase();
      return (
        isValidChannel && (
          <div className={classes.list} key="ECIF">
            <FinderList
              crmId={crmIdUpper}
              displayFinderList={displayFinderList}
              draggingItemId={finderListProps.draggingItemId}
              droppableId="ecifList"
              finderItems={getECIFOnlyFinderItems(channel.products, alreadyAdded)}
              id="ECIF"
              isDragEvent={isDragEvent}
              isECIF={true}
              isTerms={productType === ProductType.Term}
              listHeader={t('quote::Pre-approved')}
              multiSelectTo={finderListProps.multiSelectTo}
              preapprovedPrimary={t(
                'quote::Some terms require pre-approval in other tools before they can be added to the proposal.'
              )}
              preapprovedSecondary={
                crmIdUpper
                  ? t(
                      'quote::All terms which have been pre-approved for the CRM ID listed on the quote, {{CRMId}}, will appear here.',
                      { CRMId: crmIdUpper }
                    )
                  : t(
                      'quote::All terms which have been pre-approved for the CRM ID listed on the quote will appear here.'
                    )
              }
              preventKeyClick={preventKeyClick}
              selectedItemIds={finderListProps.selectedItemIds}
              toggleSelection={finderListProps.toggleSelection}
              toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
              onItemClick={onItemSelect}
              onProductKeyDown={productButtonKeyboardNavigation}
              onToggleList={onToggleList}
            />
          </div>
        )
      );
    });

  const displaySearchBar =
    (productType === ProductType.Product && !isProposalReadonly) ||
    (productType === ProductType.Term && isTermsSearchEnabled && !isProposalReadonly);

  const displaySearchResults =
    !isSearchLoading && !isTermSearchLoading && !!oc(searchResults).length() && searchValue !== '';

  const noSearchResults =
    displaySearchResults && searchResults.length === 1 && searchResults[0].productId === '0';

  const displaySearchResultsList =
    productType === ProductType.Product ||
    (productType === ProductType.Term && isTermsSearchEnabled);

  const expandSearchLinkButton =
    expandSearchAvailable &&
    (expandSearchProcessing ? (
      <Spinner className={classes.spinner} />
    ) : (
      <LinkButton
        addClass={classes.linkButton}
        ariaLabel={t('quote::Get more results from {{query}} search', { query: searchValue })}
        displayText={
          firstExpandSearch ? t('quote::expand search') : t('quote::expand search further')
        }
        onClick={() => {
          expandSearch(searchValue);
          firstExpandSearch ? setFirstExpandSearch(false) : setResetFirstExpandSearch(false);
        }}
      />
    ));

  const dialogContext = React.useContext(DialogContext);

  const searchResultsList = displaySearchResults && !noSearchResults && finderListProps && (
    <div className={classes.list}>
      <FinderList
        dataAutomationId="searchResults"
        displayFinderList={displayFinderList}
        draggingItemId={finderListProps.draggingItemId}
        droppableId="searchResultsList"
        finderItems={searchResults.map(product => ({
          addClass: classes.productButton,
          id: `SearchResults-${product.productId}`,
          productIdentifier: product.productIdentifier,
          itemText: product.productName,
          favoriteButton: productType === ProductType.Product && (
            <FavoriteButton product={product} />
          ),
          disabled:
            !!product.maxQuantityOnQuote &&
            proposal.lineItems.filter(
              item => item.productIdentifier.productId === product.productId
            ).length >= product.maxQuantityOnQuote,
        }))}
        id="SearchResults"
        isDragEvent={isDragEvent}
        listHeader={t('quote::{{count}} search result', {
          count: searchResults.length,
          defaultValue_plural: '{{count}} search results', // eslint-disable-line @typescript-eslint/camelcase
        })}
        multiSelectTo={finderListProps.multiSelectTo}
        preventKeyClick={preventKeyClick}
        selectedItemIds={finderListProps.selectedItemIds}
        toggleSelection={finderListProps.toggleSelection}
        toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
        onItemClick={onItemSelect}
        onProductKeyDown={productButtonKeyboardNavigation}
      />
      {expandSearchLinkButton}
    </div>
  );
  // #endregion

  const noSearchResultsSection = (
    <div className={classes.noResults}>
      <TextBodyLarge>{t('quote::Search results')}</TextBodyLarge>
      {(!expandSearchAvailable || !expandSearchProcessing) && (
        <TextWatermark>
          {t('quote::No {{item}}s found.', {
            item: t(`quote::${productType.toString()}`).toLocaleLowerCase(),
          })}
        </TextWatermark>
      )}
      {expandSearchLinkButton}
    </div>
  );

  //#region User Favorite Product Groups Lists
  let userProductGroupsLists: JSX.Element[] = [];

  if (!userFavoriteProductGroups) {
    const instructionsTranslation: JSX.Element = (
      <Trans ns="quote">
        To save a product, navigate to and select the favorite button, &quot;{' '}
        <Icon iconName="Heart" /> &quot;, on a product tile. You can also create your own groupings
        of products.
      </Trans>
    );

    userProductGroupsLists.push(
      <div className={classes.list} key="favorites-list">
        <FinderList
          dataAutomationId="favorites"
          displayFinderList={displayFinderList}
          droppableId="favoritesList"
          finderItems={[]}
          isDragEvent={isDragEvent}
          listHeader={t('quote::Favorites')}
          preventKeyClick={preventKeyClick}
          primaryMessage={t('quote::Your favorite products will live here.')}
          secondaryMessage={instructionsTranslation}
        />
      </div>
    );
  } else {
    for (let groupName in userFavoriteProductGroups) {
      const isFavoritesGroup = groupName === 'Favorites';
      const favoriteItems = getItems(finderListProps.entities, `favoritesList-${groupName}`);
      const groupProducts: CreateLineItemArgs[] = [];
      favoriteItems &&
        favoriteItems.length &&
        favoriteItems.forEach((item: FinderItem) => {
          if (!item.disabled) {
            groupProducts.push({
              productIdentifier: item.productIdentifier,
              selectedProject,
            });
          }
        });

      const groupMenuOptions =
        groupProducts.length && proposal
          ? [
              {
                icon: 'AddToShoppingList',
                key: 'add-group-to-quote',
                dataAutomationId: 'addGroupToQuote',
                text: t('quote::Add group to quote'),
                onClick: () => {
                  createLineItems(
                    isProposalReadonly,
                    proposal,
                    proposalServiceEndpoint,
                    groupProducts,
                    addLineItem
                  );
                },
              },
            ]
          : [];

      if (!isFavoritesGroup) {
        // Rename group option
        groupMenuOptions.push({
          icon: 'Rename',
          key: 'rename-group',
          dataAutomationId: 'renameGroup',
          text: t('quote::Rename group'),
          onClick: () =>
            openRenameProductGroupDialog(dialogContext, {
              currentGroupName: groupName,
              elementIdToFocusOnDismiss: `${groupName}-menu-button`,
            }),
        });

        // Remove group option
        groupMenuOptions.push({
          icon: 'Delete',
          key: 'delete-group',
          dataAutomationId: 'deleteGroup',
          text: t('quote::Delete group'),
          onClick: () =>
            openDeleteProductGroupDialog(dialogContext, {
              groupName,
              elementIdToFocusOnDismiss: `${groupName}-menu-button`,
            }),
        });
      }

      userProductGroupsLists.push(
        <div className={classes.list} key={`${groupName}-list`}>
          <FinderList
            dataAutomationId="finderList"
            displayFinderList={displayFinderList}
            draggingItemId={finderListProps.draggingItemId}
            droppableId={`favoritesList-${groupName}`}
            finderItems={favoriteItems}
            id="FavoritesList"
            isDragEvent={isDragEvent}
            listHeader={groupName}
            menuButtonId={`${groupName}-menu-button`}
            menuProps={groupMenuOptions.length ? groupMenuOptions : undefined}
            multiSelectTo={finderListProps.multiSelectTo}
            preventKeyClick={preventKeyClick}
            primaryMessage={
              isFavoritesGroup && !userFavoriteProductGroups[groupName].products.length
                ? t('quote::Your favorite products will live here.')
                : undefined
            }
            selectedItemIds={finderListProps.selectedItemIds}
            toggleSelection={finderListProps.toggleSelection}
            toggleSelectionInGroup={finderListProps.toggleSelectionInGroup}
            onItemClick={onItemSelect}
            onProductKeyDown={productButtonKeyboardNavigation}
            onToggleList={isFavoritesGroup ? onToggleList : undefined}
          />
        </div>
      );
    }
  }
  //#endregion

  //#region Asset management button
  const assetsManagementButton = (
    <BorderlessButton
      iconName="ProductList"
      styles={{ root: classes.assetsButtonRoot, label: classes.assetsButtonLabel }}
      text={t('quote::Asset management')}
      onClick={openAssetsDialog}
    />
  );
  //#endregion

  return (
    <>
      <TextTitleSecondary addClass={classes.title}>
        {t('quote::{{title}} finder', { title: t(`quote::${productType.toString()}`) })}
      </TextTitleSecondary>
      {assetManagementFlightEnabled &&
        assets &&
        !!assets.length &&
        !isProposalReadonly &&
        productType === ProductType.Product && (
          <div>
            {assetsManagementButton}
            <div className={classes.assestsSeparator}>
              <SectionSeparator />
            </div>
          </div>
        )}
      {displaySearchBar && (
        <BorderlessSearch
          ariaLabel={t('quote::Search for {{item}}', {
            item: t(`quote::${productType.toString()}`).toLocaleLowerCase(),
          })}
          componentRef={searchComponentRef}
          dataAutomationId="productSearch"
          focusPlaceholder={t('quote::Find {{item}}s by name', {
            item: t(`quote::${productType.toString()}`).toLocaleLowerCase(),
          })}
          id={`searchInput-${productType.toString()}Search`}
          placeholder={t('quote::Search')}
          onChangeDebounced={onSearch}
          onClear={onClearSearch}
          onSearch={onSearch}
        />
      )}
      <div className={classes.listContainer} ref={topRef}>
        <div className={classes.progressBarContainer}>
          <ProgressIndicatorAtom
            progressHidden={!isSearchLoading && !isTermSearchLoading}
            styles={loadingIndicatorStyles}
          />
          <div className={classes.underline}>
            <SectionSeparator />
          </div>
        </div>
        {noSearchResults && displaySearchResultsList && noSearchResultsSection}
        {displaySearchResultsList && (
          <RenderIfExistsSpan wrapperClass="">{searchResultsList}</RenderIfExistsSpan>
        )}
        {productType === ProductType.Product && userProductGroupsLists}
        {displayList && recoLists}
        {ecifLists}
      </div>
    </>
  );
};

export const FinderStyled = withStyles(finderStyles)(FinderUnstyled);
export const mapStateToPropsFinder = mapStateToProps;
export const dispatchPropsFinder = dispatchProps;
export const Finder = connect(mapStateToProps, dispatchProps)(FinderStyled);
