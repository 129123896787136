import { ShimmerForBackgroundCommon } from 'components';
import { ShimmerElementType } from 'office-ui-fabric-react';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { organizationInformationStyles } from './OrganizationInformation/OrganizationInformation.styles';

type Props = WithStyles<typeof organizationInformationStyles>;

const CustomerLoadingUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  return (
    <div className={classes.shimmerContainer}>
      <div className={classes.header}>
        <ShimmerForBackgroundCommon
          className={classes.shimmer}
          shimmerElements={[{ type: ShimmerElementType.line, height: 24 }]}
          width={144}
        />
      </div>
      <ShimmerForBackgroundCommon
        className={classes.shimmer}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        width={140}
      />
      <ShimmerForBackgroundCommon
        className={classes.shimmer}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        width={100}
      />
      <ShimmerForBackgroundCommon
        className={classes.shimmer}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        width={120}
      />
      <ShimmerForBackgroundCommon
        className={classes.shimmer}
        shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]}
        width={80}
      />
    </div>
  );
};

export const CustomerLoading = withStyles(organizationInformationStyles)(CustomerLoadingUnstyled);
