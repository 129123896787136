import { ThemeProps } from 'styles';

import { buttonSharedStyles } from '../shared.styles';

export const iconButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    ...buttonSharedStyles(theme),
    icon: {
      minWidth: 32,
      width: 32,
      backgroundColor: 'transparent',
      borderRadius: 0,
      padding: '7px 8px',
      '& i': {
        color: colors.textSecondary,
        padding: 0,
        margin: 0,
      },
      '&:hover': {
        backgroundColor: colors.secondaryActive,
        '& i': {
          color: colors.textSecondary,
        },
      },
      '&:active': {
        backgroundColor: colors.textSecondary,
        color: colors.backgroundCommon,
        '& i': {
          color: colors.backgroundCommon,
        },
      },
      '&:active:hover': {
        backgroundColor: colors.textSecondary,
        color: colors.backgroundCommon,
        '& i': {
          color: colors.backgroundCommon,
        },
      },
      '& i:disabled': {
        color: colors.textDisabled,
      },
    },
    errorIcon: {
      position: 'relative',
      zIndex: 1,
      minWidth: 20,
      width: 20,
      minHeight: 20,
      height: 20,
      padding: '2px 0',
      border: 0,
      color: colors.dangerActive,
      backgroundColor: 'transparent',
      '& i': {
        color: colors.dangerActive,
        fontSize: theme.fonts.fontSizes.small,
        margin: 0,
      },
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
        '& i': {
          color: colors.dangerActive,
          fontSize: theme.fonts.fontSizes.small,
        },
      },
    },
  };
};
