import { Currency } from 'features/proposal/supported-currencies';
import { computeDuration, formatCurrency } from 'features/proposal/utils';
import loggerService from 'services/logger-service';

export const computeAmount = (amount: string) => {
  if (isNaN(+amount)) {
    loggerService.error({
      error: new Error(`Amount for is not a valid number it is: ${amount}`),
    });
  }
  return amount;
};

export const buildMonetaryCardSummaryStrings = (
  amount: string,
  goodForDuration: string,
  currency: Currency,
  years: string,
  months: string,
  startCondition: string
) => {
  if (startCondition) {
    return {
      amount: formatCurrency(computeAmount(amount), 0),
      currency,
      startCondition,
      duration: computeDuration(goodForDuration, years, months),
    };
  }
};

export const buildDurationString = (goodForDuration: string, years: string, months: string) => {
  return computeDuration(goodForDuration, years, months);
};
