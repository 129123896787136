import gql from 'graphql-tag';

export const GetFallbackProduct = gql`
  query GetProduct($id: String!, $catalogContext: CatalogContextInput!, $quoteId: String!) {
    getProduct(id: $id, catalogContext: $catalogContext) {
      skus {
        skuId
        availabilities {
          actions
          price(context: { quote: { quoteId: $quoteId } }) {
            currency
            amount
          }
          availabilityId
        }
        title
      }
    }
  }
`;
