import axios, { AxiosResponse } from 'axios';
import * as LocalStorage from 'services/local-storage-service';
import { createGuid, getAuthHeader } from 'services/utils';
import { ThemeKey } from 'styles';

import { endpoints, getTokenAuthority, UserPreferencesConfig } from './config';
import {
  ApiPreferences,
  UserPreferences,
  UserPreferencesRequest,
  UserPreferencesResponse,
} from './types';

const preferencesKey = 'userpreferences';

function getUserAppPreference(response: AxiosResponse<UserPreferencesResponse>): UserPreferences {
  const apiPreference: ApiPreferences = response.data.data;
  const userPreference: UserPreferences = {
    eTag: response.headers.etag,
    ...apiPreference,
  };
  return userPreference;
}

async function getHeaders(config: UserPreferencesConfig): Promise<Record<string, string>> {
  return {
    authorization: await getAuthHeader(getTokenAuthority(config.environment)),
    'x-ms-correlation-id': createGuid(),
  };
}

export async function loadUserPreferences(
  key: string,
  config: UserPreferencesConfig
): Promise<UserPreferences> {
  const headers = await getHeaders(config);
  const url = `${endpoints[config.environment]}/${key}`;
  const response = await axios.get<UserPreferencesResponse>(url, { headers });
  const userAppPreference = getUserAppPreference(response);
  return userAppPreference;
}

export async function updateUserPreferences(
  key: string,
  userPreferences: UserPreferences,
  config: UserPreferencesConfig
): Promise<UserPreferences> {
  const headers = await getHeaders(config);
  headers['If-Match'] = userPreferences.eTag;
  const url = `${endpoints[config.environment]}/${key}`;
  const update = !!userPreferences.eTag;
  delete userPreferences.eTag;
  const userPreferenceRequest: UserPreferencesRequest = {
    data: userPreferences,
    version: '1.0',
  };
  let response = undefined;
  // No eTag, so its time to add User Preference.
  if (update) {
    response = await axios.put<UserPreferencesResponse>(url, userPreferenceRequest, { headers });
  } else {
    response = await axios.post<UserPreferencesResponse>(url, userPreferenceRequest, { headers });
  }

  const updatedUserAppPreference = getUserAppPreference(response);
  return updatedUserAppPreference;
}

export async function deleteUserPreferences(key: string, config: UserPreferencesConfig) {
  const headers = await getHeaders(config);
  const url = `${endpoints[config.environment]}/${key}`;
  await axios.delete(url, { headers });
}

export function getLocalPreferences(): UserPreferences {
  return (
    LocalStorage.get<UserPreferences>(preferencesKey) || {
      eTag: '',
      theme: ThemeKey.light,
      viewedInfoDialogs: [],
    }
  );
}

export function setLocalPreferences(preferences: UserPreferences): void {
  LocalStorage.set<UserPreferences>(preferencesKey, preferences);
}

export function removeLocalPreferences(): void {
  LocalStorage.remove(preferencesKey);
}
