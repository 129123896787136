import { ThemeProps } from 'styles';

export const indicatorStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    approved: {
      '&, & > i': {
        color: colors.approveText,
      },
    },
    danger: {
      '&, & > i': {
        color: colors.dangerText,
      },
    },
    warning: {
      '&, & > i': {
        color: colors.warningText,
      },
    },
    unknown: {
      '&, & > i': {
        color: colors.textSecondary,
      },
    },
    text: {
      '&, & > i': {
        color: colors.text,
      },
    },
    icon: {
      position: 'relative',
      top: '2px',
      marginRight: '10px',
    },
  };
};
