import { ThemeProps } from 'styles';

export const ManageFulfillmentCloudsStyles = (theme: ThemeProps) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      fontSize: theme.fonts.fontSizes.small,
      color: theme.palette.textTertiary,
    },
    subtitle: {
      marginTop: 16,
      marginBottom: 6,
      fontWeight: theme.fonts.fontWeights.semiBold,
      fontSize: theme.fonts.fontSizes.medium,
    },
    cloudRow: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 8,
      display: 'flex',
      flexFlow: 'row',
      width: ' 100%',
      borderBottom: `1px solid ${theme.palette.backgroundDivider}`,

      '&:last-of-type': { borderBottom: 'none' },
    },
    cloudName: { width: '18%' },
    cloudNameLong: { width: '67%' },
    cloudButton: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 0,
      paddingLeft: 0,
      '& .ms-Icon': {
        fontSize: theme.fonts.fontSizes.medium,
      },
    },
    cloudProducts: {
      fontSize: theme.fonts.fontSizes.small,
      color: theme.palette.textTertiary,
      width: '49%',
      paddingTop: 3,
    },
    cloudProductsToBeDeleted: {
      fontSize: theme.fonts.fontSizes.small,
      color: theme.palette.dangerText,
      width: '49%',
      paddingTop: 3,
    },
    errorMessage: {
      color: theme.palette.dangerText,
      textAlign: 'right',
      fontStyle: theme.fonts.fontStyles.italic,
      fontSize: theme.fonts.fontSizes.small,
      paddingTop: 15,
    },
  };
};
