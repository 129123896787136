import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { RenderIfExists } from '../components';
import { editorLayoutStyles, editorListStyles } from './styles';

export interface EditorLayoutProps {
  leftPane?: React.ReactElement;
  mainContent: React.ReactElement;
  onContainerAreaClick: () => void;
}

type Props = EditorLayoutProps & WithStyles<typeof editorLayoutStyles>;

export const EditorLayoutUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, leftPane, mainContent, onContainerAreaClick } = props;
  return (
    <React.Fragment>
      <RenderIfExists wrapperClass={classes.leftPane}>{leftPane}</RenderIfExists>
      <div
        className={leftPane ? classes.mainContent : classes.mainContentNoLeftPane}
        onClick={onContainerAreaClick}
      >
        {mainContent}
      </div>
    </React.Fragment>
  );
};

interface ContainerProps extends WithStyles<typeof editorListStyles> {
  content: React.ReactNode;
  pivotNav: React.ReactNode;
}

const PivotContainerUnstyled: React.FC<ContainerProps> = props => {
  const { pivotNav, content, classes } = props;
  return (
    <div className={classes.quoteWrapper} id="PrimaryArea">
      <div className={classes.pivotRow}>{pivotNav}</div>
      <div className={classes.quoteContainer}>{content}</div>
    </div>
  );
};

interface ContentProps extends WithStyles<typeof editorListStyles> {
  actionBar: React.ReactNode;
  list: React.ReactNode;
  detailsPane: React.ReactNode;

  footer: React.ReactNode;
}

const EditorContentUnstyled: React.FC<ContentProps> = props => {
  const { actionBar, list, detailsPane, footer, classes } = props;
  return (
    <React.Fragment>
      <div className={classes.quoteContent}>
        <div className={classes.actionBarRow}>{actionBar}</div>
        <div className={classes.listContainer}>{list}</div>
        <div className={classes.quoteFooter}>{footer}</div>
      </div>
      <div className={classes.quoteDetailsPane}>{detailsPane}</div>
    </React.Fragment>
  );
};

export const PivotContainer = withStyles(editorListStyles)(PivotContainerUnstyled);
export const EditorContent = withStyles(editorListStyles)(EditorContentUnstyled);

export const EditorLayout = withStyles(editorLayoutStyles)(EditorLayoutUnstyled) as React.FC<
  EditorLayoutProps
>;
