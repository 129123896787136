import i18next from 'i18next';

export const Videos = (t: i18next.TFunction) => {
  return [
    {
      id: 'V1',
      videoTitle: t('help::How do I create a Quote?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/80a02488-0589-4768-93cf-d20aa616978e?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will cover the creation of a quote, starting with locating an opportunity in MSX Dynamics 365, opening Quote Center from the opportunity, adding products, running a credit check, and sending it to the customer.'
      ),
      image: 'QC_E2E_Process.PNG',
    },
    {
      id: 'V2',
      videoTitle: t('help::How do I process Monetary Credit for a customer?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/9e507d33-6468-41ac-a590-98bede1bfbbe?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will demonstrate what steps are needed when creating a quote with Azure Monetary Credit. Key focus will be spent on what needs to be provided to the approver before the quote is sent to the customer.'
      ),
      image: 'Azure_MC_QC.PNG',
    },
    {
      id: 'V3',
      videoTitle: t('help::How do I configure a future discount for a customer?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/6bd8dd17-f4c9-46a2-b637-c065d5312798?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::View this video to understand the steps to create a quote with a future discount.'
      ),
      image: 'Add_Future_Disc_Prop_QC.PNG',
    },
    {
      id: 'V5',
      videoTitle: t('help::How do I submit a quote for approval?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/9c7a1912-20f8-4ce1-b0b5-ba64c7067eb6?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::View this video to understand the steps to send a quote with a concession to an approver.'
      ),
      image: 'Sub_Prop_Approval_QC.PNG',
    },
    {
      id: 'V6',
      videoTitle: t('help::How do I withdraw a quote after submitting it for approval?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/90715da1-fa6a-4c33-8321-2b72719658ee?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will demonstrate how to withdraw a quote that has been sent to either an approver (for approving a discount) or a customer.'
      ),
      image: 'With_Prop_QC.PNG',
    },
    {
      id: 'V7',
      videoTitle: t('help::How do I approve a quote?'),
      videoUrl: 'https://msit.microsoftstream.com/embed/video/4fceb6a4-7de6-4c32-a0f3-518923548d1d',
      videoDescription: t(
        'help::View this video to understand the steps that an approver takes to approve a quote with a concession.'
      ),
      image: 'QC_Approve_Proposal.PNG',
    },
    {
      id: 'V8',
      videoTitle: t('help::How do I establish a line of credit for a customer?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/7c1767d0-12c1-4df4-81c0-603f1ef27c5e?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will cover what steps are needed for a customer that does not have a line of credit.'
      ),
      image: 'CC_QC.PNG',
    },
    {
      id: 'V9',
      videoTitle: t('help::How do I add a standard amendment?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/85eb3a59-db53-4e53-82c9-cba080d1960e?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::Concessions available in August: HIPPA (in agreement), DPA (Data Processing - in agreement), Applicable Law, Publicity, FSI (excludes Compliance Program), GDPR, Australian data privacy'
      ),
      image: 'Add_Std_Amds_QC.PNG',
    },
    {
      id: 'V10',
      videoTitle: t(
        'help::How do I add the End Customer Investment Funding Amendment for a customer?'
      ),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/6daa0770-1e29-48e7-b918-03f3ab772702?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will demonstrate how you can add the End Customer Investment Funding Amendment for a customer.'
      ),
      image: 'Apply_ECIF_Trms_QC.PNG',
    },
    {
      id: 'V11',
      videoTitle: t('help::How do I add financing terms for a customer?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/d865413a-c8ca-47eb-bb83-15572b5bb312?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will demonstrate the steps to add financing terms for a customer.'
      ),
      image: 'Apply_Fin_Trms_QC.PNG',
    },
    {
      id: 'V12',
      videoTitle: t('help::How do I add Azure Prepayment for a customer?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/79d40761-356b-4d0c-aa4d-f672e2f644a7?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will demonstrate the steps to add Azure Prepayment (formerly Monetary Commitment) for a customer.'
      ),
      image: 'Add_Azure_PrePay_Prop_QC.PNG',
    },
    {
      id: 'V13',
      videoTitle: t(
        'help::How do I add the Microsoft Azure Commitment to Consume terms for a customer?'
      ),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/2469db65-c3f7-4d27-89c7-068cb520be88?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will demonstrate the steps to add the Microsoft Azure Commitment to Consume terms for a customer.'
      ),
      image: 'MSFT_Azure_Cons_Commit_QC.PNG',
    },
    {
      id: 'V14',
      videoTitle: t('help::How do I add the new Billing Account for a customer?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/cfa6f44d-fec5-460b-bb15-42d93e7c3015?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video will demonstrate the steps to add the Billing Account for a customer.'
      ),
      image: 'Bill_Accts_QC.PNG',
    },
    {
      id: 'V15',
      videoTitle: t('help::How do I create an Affiliate?'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/22a7fe86-a534-4fc8-988d-f785b625c939?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::This video demonstrates the steps to configure a quote for Affiliate customers who sign Customer Affiliate Purchase Terms (CAPT).  CAPT enables independent purchasing without the need for an affiliate to sign a separate MCA.'
      ),
    },
    {
      id: 'V16',
      videoTitle: t('help::Customer experience walkthrough of Azure Portal'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/1f00a4ff-0400-86e8-3516-f1ea7811a5f3?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        'help::Watch this video to learn about what your customer sees in the Cost Management & Billing section of Azure Portal.'
      ),
    },
    {
      id: 'V17',
      videoTitle: t('help::Customer experience walkthrough of Admin Center'),
      videoUrl:
        'https://msit.microsoftstream.com/embed/video/30f7a3ff-0400-86e8-e042-f1ea7812cb9c?autoplay=false&amp;showinfo=true',
      videoDescription: t(
        "help::Watch this video to understand the Microsoft Admin Center from your customer's perspective."
      ),
    },
  ];
};
