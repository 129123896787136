import { ThemeProps } from 'styles';

export const navBarStyles = (theme: ThemeProps) => {
  const { fonts, palette } = theme;
  const leftSideItems = {
    alignItems: 'center',
    fontWeight: fonts.fontWeights.semiBold,
    overflow: 'hidden',
    padding: 0,
    paddingRight: 4,
  };

  return {
    breadcrumbButton: {
      width: 49,
      height: 'auto',
      '& i': {
        padding: 5,
        fontSize: fonts.fontSizes.xLarge,
        color: palette.titleBarPrimaryText,
      },
      '&:focus:hover, &:active:hover, &:hover:not(:active):not(:focus)': {
        backgroundColor: theme.palette.primaryHover,
        '& i': {
          color: palette.titleBarPrimaryText,
        },
      },
      '&:focus:not(:active):not(:hover), &:active:focus': {
        backgroundColor: theme.palette.primary,
        '& i': {
          color: palette.titleBarPrimaryText,
        },
      },
    },
    defaultDivider: {
      borderRight: `1px solid ${palette.backgroundDivider}`,
    },
    defaultTheme: {
      backgroundColor: palette.backgroundStandout,
      borderColor: palette.backgroundDivider,
      color: palette.text,
    },
    defaultTitleDivider: {
      color: palette.backgroundDivider,
      fontSize: 13,
      paddingTop: 2,
    },
    header: {
      display: 'flex',
      fontSize: fonts.fontSizes.larger,
      minWidth: 0,
    },
    leftContent: {
      display: 'flex',
      fontSize: fonts.fontSizes.larger,
      minWidth: 676,
    },
    leftSideItems,
    navbar: {
      display: 'flex',
      fontFamily: fonts.fontFamily.fabricDefault,
      height: 48,
      justifyContent: 'space-between',
      minWidth: 0,
      position: 'relative',
    },
    noShrink: {
      flexShrink: 0,
    },
    persona: {
      display: 'flex',
      width: 48,
      overflow: 'hidden',
      '& button': {
        backgroundColor: 'transparent',
        border: 'none',
        width: '100%',
      },
    },
    primaryDivider: {
      borderRight: `1px solid ${palette.titleBarPrimarySeparator}`,
    },
    primaryTheme: {
      backgroundColor: palette.primary,
      color: palette.titleBarPrimaryText,
      '& > *': {
        backgroundColor: palette.primary,
      },
    },
    demoTheme: {
      backgroundColor: palette.demoColor,
      color: palette.titleBarPrimaryText,
      '&#HomeLink:hover': {
        backgroundColor: palette.darkColor,
      },
      '&#HomeLink:active': {
        backgroundColor: palette.darkColor,
      },
      '&#HomeLink:focus': {
        backgroundColor: palette.darkColor,
      },
    },
    primaryTitleDivider: {
      color: palette.titleBarPrimarySeparator,
      fontSize: 13,
      paddingTop: 1,
    },
    rightContent: {
      display: 'flex',
      fontSize: fonts.fontSizes.larger,
      minWidth: 0,
      flexShrink: 0,
    },
    rootExpanded: {
      '& .ms-Button-icon': {
        color: palette.text,
      },
      backgroundColor: palette.backgroundStandout,
    },
    state: {
      padding: 0,
      paddingRight: 10,
    },
    secondSubtitle: {
      minWidth: 0,
      textOverflow: 'ellipsis',
    },
    subtitles: {
      alignItems: 'center',
      display: 'flex',
      fontWeight: fonts.fontWeights.semiBold,
      minWidth: 0,
      padding: 0,
      paddingRight: 40,
    },
    testIconContainer: {
      alignItems: 'baseline',
      cursor: 'default',
      display: 'flex',
      flexDirection: 'column',
    },
    testIcon: {
      color: palette.titleBarPrimaryText,
      marginLeft: 8,
      marginTop: 2,
    },
    title: {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap',
    },
    titleContainer: {
      marginBottom: 12,
      marginRight: 24,
      marginTop: 12,
      padding: '0 24px',
    },
    titleDivider: {
      ...leftSideItems,
      flexShrink: 0,
    },
  };
};
