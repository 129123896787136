import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { SupportedTheme } from './theme';

const styles = (theme: SupportedTheme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.backgroundMain,
      color: theme.palette.text,
    },
    ':any-link': {
      color: theme.palette.textLink,
    },
    ':any-link.active': {
      color: theme.palette.textLinkHover,
    },
    ':any-link:hover': {
      color: theme.palette.textLinkHover,
    },
  },
});

const GlobalStyles: React.FC<WithStyles<typeof styles>> = (props: WithStyles<typeof styles>) =>
  null;

export default withStyles(styles)(GlobalStyles);
