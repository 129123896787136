import { TextboxAtomProps } from 'components/atoms';
import { LinkExternal, TextboxStandard, TextboxWithProgress } from 'components/ions';
import { getCRMConfig } from 'features/app/selectors';
import { sharedStyles } from 'features/proposal/shared.styles';
import { modernMarkets } from 'features/proposal/supported-markets';
import { flexSectionCreator } from 'features/proposal/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { externalLinkMSX } from 'services/crm/config';
import * as LocalStorage from 'services/local-storage-service';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles/theme';

import { getMarkets, MarketType } from 'features/proposal/supported-markets';
import { KeyCodes } from '@uifabric/utilities';
import {
  CrmLead,
  Market,
  AgreementType,
  ProductAudience,
  NationalCloud,
  CatalogAction,
  GqlLanguage,
  CrmType,
} from 'generated/graphql';

import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { RemoveCrmIdButton } from 'features-apollo/components/dialogs/RemoveCrmIdDialog/RemoveCrmIdButton';
import { salesStyles } from './Sales.styles';
import { GET_QUOTECONTEXT } from 'features-apollo/quote/components/Editor';
import { GET_QUOTERECOMMENDATIONS } from 'features-apollo/quote/components/queries';
import { ADD_CRMID, GET_QUOTE_SALES_INFO } from './queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { oc } from 'ts-optchain';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles,
  ...salesStyles(theme),
});

export enum CrmIdSteps {
  Search,
  Processing,
  Success,
}

export enum CrmIdErrorStates {
  InvalidMarket,
  CrmIdNotFound,
  InvalidCrmId,
  AddingCrmIdError,
  Valid,
}

export interface SalesHeaderProps {
  crmId?: string;
  crmLead?: CrmLead;
  isQuoteReadOnly: boolean;
  quoteId: string;
  market: Market;
  etag: string;
  salesAccountCountry?: string;
  isLegacy: boolean;
  organizationId?: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    crmConfig: getCRMConfig(state),
  };
};

type Props = SalesHeaderProps & ReturnType<typeof mapStateToProps> & WithStyles<typeof styles>;

const SalesHeaderUnstyled: React.FC<Props> = ({
  crmId,
  classes,
  quoteId,
  market,
  isQuoteReadOnly,
  crmLead,
  etag,
  crmConfig,
  salesAccountCountry,
  isLegacy,
  organizationId,
}: Props) => {
  const { t } = useTranslation();
  const { data: contextData } = useQuery<QueryGetQuoteData>(GET_QUOTECONTEXT, {
    variables: { id: quoteId },
  });
  const catalogContext = {
    agreementType: oc(contextData).getQuote.agreementType(AgreementType.Modern),
    audience: oc(contextData).getQuote.productAudience(ProductAudience.DirectCommercial),
    nationalCloud: oc(contextData).getQuote.clouds([NationalCloud.Global])[0],
    market: oc(contextData).getQuote.market(Market.Us) as Market,
    languages: oc(contextData).getQuote.languageInfo.gqlCode(GqlLanguage.EnUs),
    action: CatalogAction.Details,
  };
  const [addCRMIdGQL, { loading, error }] = useMutation(ADD_CRMID, {
    refetchQueries: () => [
      { query: GET_QUOTE_SALES_INFO, variables: { id: quoteId } },
      { query: GET_QUOTE, variables: { id: quoteId } },
      { query: GET_QUOTERECOMMENDATIONS, variables: { id: quoteId, input: catalogContext } },
    ],
  });

  const [currentStep, setCurrentStep] = React.useState<CrmIdSteps>(
    crmId ? CrmIdSteps.Success : CrmIdSteps.Search
  );
  const [cleanedCrmId, setCleanedCrmId] = React.useState(crmId || '');
  const [isError, setIsError] = React.useState(CrmIdErrorStates.Valid);
  const [displayAnimatedViewInMSXLink, setDisplayAnimatedViewInMSXLink] = React.useState(false);
  const isCRMIdMarketValid = salesAccountCountry
    ? getMarkets(isLegacy ? MarketType.legacy : MarketType.modern).some(market => {
        return market.key === salesAccountCountry;
      })
    : undefined;
  const dispatchVerificationAction = (input: string) => {
    setCurrentStep(CrmIdSteps.Processing);
    addCRMIdGQL({
      variables: {
        input: {
          id: quoteId,
          etag: etag,
        },
        crmId: input.toUpperCase(),
      },
    });
    setDisplayAnimatedViewInMSXLink(true);
  };
  // Verify success or fail of action
  React.useEffect(() => {
    if (crmId) {
      setCleanedCrmId(crmId);
      setCurrentStep(CrmIdSteps.Success);
      setIsError(CrmIdErrorStates.Valid);
      if (market !== undefined && modernMarkets.includes(market)) {
        // only update localMarketKey if market is on modern list
        LocalStorage.set<Market>(LocalStorage.localMarketKey, market);
      }
    } else if (
      ((!isCRMIdMarketValid && !loading) || error) &&
      currentStep === CrmIdSteps.Processing
    ) {
      if (isCRMIdMarketValid === false) {
        setIsError(CrmIdErrorStates.InvalidMarket);
      } else if (error) {
        isCRMIdMarketValid === undefined
          ? setIsError(CrmIdErrorStates.CrmIdNotFound)
          : setIsError(CrmIdErrorStates.AddingCrmIdError);
      }
      setCurrentStep(CrmIdSteps.Search);
    } else if (!crmId && !loading) {
      setCleanedCrmId('');
      setCurrentStep(CrmIdSteps.Search);
    }
  }, [crmId, isCRMIdMarketValid, loading, setIsError, currentStep, market, error]);
  //#endregion

  const getErrorMessage = (): string => {
    switch (isError) {
      case CrmIdErrorStates.CrmIdNotFound:
        return t('quote::CRM ID not found');
      case CrmIdErrorStates.InvalidMarket:
        return t(
          'quote::The customer account associated with the CRM ID is in an unsupported market. You must either select a different account for this customer or sell to them using a different sales motion.'
        );
      case CrmIdErrorStates.InvalidCrmId:
        return t('quote::Enter a valid CRM ID');
      case CrmIdErrorStates.AddingCrmIdError:
        return t('quote::An error occurred while adding the CRM ID');
      case CrmIdErrorStates.Valid:
      default:
        return '';
    }
  };

  const handleCrmEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter) {
      if (cleanedCrmId && isError !== CrmIdErrorStates.InvalidCrmId) {
        dispatchVerificationAction(cleanedCrmId);
      }
    }
  };

  const validCrmIdMinLength = 7;

  const sharedTexboxProps: Partial<TextboxAtomProps> = {
    addClass: classes.field,
    label: t('quote::CRM ID'),
    required: true,
    dataAutomationId: 'crmId',
  };

  const viewCrmIdSearch = flexSectionCreator(
    classes.flexXLarge,
    classes.flexXLarge,
    <TextboxStandard
      {...sharedTexboxProps}
      ariaLabel={t('quote::Enter or search for a CRM ID')}
      dataAutomationId="salesCRMId"
      disabled={isQuoteReadOnly}
      errorMessage={getErrorMessage()}
      placeholder={t('quote::Enter or search for a CRM ID')}
      value={cleanedCrmId}
      onBlur={() => {
        if (cleanedCrmId && isError !== CrmIdErrorStates.InvalidCrmId) {
          dispatchVerificationAction(cleanedCrmId);
        }
      }}
      onChange={(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        value?: string
      ) => {
        const clean = value ? value.replace(/ /g, '') : '';
        setCleanedCrmId(clean);
        if (!!clean.length && clean.length < validCrmIdMinLength)
          return setIsError(CrmIdErrorStates.InvalidCrmId);
        if (clean !== crmId) setIsError(CrmIdErrorStates.Valid);
      }}
      onKeyDown={handleCrmEnter}
    />,
    classes.flexContainer
  );
  const viewCrmIdLoading = flexSectionCreator(
    classes.flexXLarge,
    classes.flexXLarge,
    <span>
      <TextboxWithProgress {...sharedTexboxProps} disabled readOnly value={cleanedCrmId} />
    </span>,
    classes.flexContainer
  );

  const onRemove = () => {
    setCurrentStep(CrmIdSteps.Search);
    setCleanedCrmId('');
  };
  const msxBaseUrl = externalLinkMSX[crmConfig.environment];
  let msxUrl;
  if (crmLead) {
    msxUrl = msxBaseUrl.replace('{entityName}', crmLead.entityName);
    msxUrl = msxUrl.replace('{id}', crmLead.entityId);
  }
  const isFgoeOppty = crmLead && crmLead.type === CrmType.FgoeOpportunity;
  const viewCrmId = flexSectionCreator(
    classes.flexXLarge,
    null,
    <span>
      <TextboxStandard {...sharedTexboxProps} readOnly value={cleanedCrmId} />
      {crmLead && !isFgoeOppty && (
        <div
          className={displayAnimatedViewInMSXLink ? classes.crmIdLinkAnimate : classes.crmIdLink}
        >
          <LinkExternal
            dataAutomationId="viewInMSXLink"
            displayText={t('quote::view in MSX')}
            href={msxUrl}
            size="medium"
          />
        </div>
      )}
    </span>,
    classes.flexContainer,
    classes.flexXLarge,
    <div>
      <span className={classes.paddingRightMedium} />
      {!isQuoteReadOnly && (
        <RemoveCrmIdButton
          addClass={classes.crmIdRemove}
          etag={etag}
          organizationId={organizationId}
          quoteId={quoteId}
          onRemoveClick={onRemove}
        />
      )}
    </div>
  );
  const getCurrentStep = (step: CrmIdSteps) => {
    switch (step) {
      case CrmIdSteps.Search:
        return viewCrmIdSearch;
      case CrmIdSteps.Processing:
        return viewCrmIdLoading;
      case CrmIdSteps.Success:
        return viewCrmId;
    }
  };

  const crmIdSection: JSX.Element = getCurrentStep(currentStep);

  return crmIdSection;
};

const SalesHeaderStyled = withStyles(styles)(SalesHeaderUnstyled);
export const SalesHeader = connect(mapStateToProps)(SalesHeaderStyled);
