import { CalloutCalendar, TextboxStandard } from 'components/ions';
import { convertDateToFormattedString, LocaleDateFormat } from 'components/utilities/dates';
import { KeyCodes } from 'office-ui-fabric-react';
import { ICalendarStrings } from 'office-ui-fabric-react/lib/Calendar';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { calendarInputStyles } from './CalendarInput.styles';

/**
 * Props for the CalendarInput
 * @prop {CalloutCalendarProps} calloutCalendarProps - Props for the calloutCalendar
 * @prop {string} [id] - Id of the component
 * @prop {string} label - Label for the input control
 */

export interface CalendarInputProps {
  ariaLabelCalendarButton?: string;
  calendarStrings: ICalendarStrings;
  defaultDate: Date;
  disabled?: boolean;
  onSelectDate: (date: Date, selectedDateRangeArray?: Date[]) => void;
  label: string;
  inputId: string;
  calendarId: string;
  maxWidth?: number;
  stretch?: boolean;
  value: Date;
  maxDate?: Date;
  dataAutomationId?: string;
}

type Props = CalendarInputProps & WithStyles<typeof calendarInputStyles>;

export const CalendarInputUnstyled: React.FC<Props> = (props: Props) => {
  const [inputValue, setInputValue] = React.useState(
    convertDateToFormattedString(props.value, LocaleDateFormat.ll)
  );
  const [showCalendar, setShowCalendar] = React.useState(false);

  const onSelectDate = (date: Date, selectedDateRangeArray?: Date[]) => {
    props.onSelectDate(date, selectedDateRangeArray);
    setInputValue(convertDateToFormattedString(date, LocaleDateFormat.ll));
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.keyCode === KeyCodes.enter) {
      setShowCalendar(!showCalendar);
    }
  };

  const onClick = () => {
    setShowCalendar(!showCalendar);
  };

  const resetShowCalendar = () => {
    setShowCalendar(false);
  };

  const { classes, label } = props;
  return (
    <div className={classes.calendarContainer}>
      <div className={classes.flexItem}>
        <TextboxStandard
          dataAutomationId={`${props.dataAutomationId}TextBox`}
          disabled={props.disabled}
          id={props.inputId}
          label={label}
          readOnly={true}
          value={inputValue}
          onClick={onClick}
          onKeyDown={onKeyDown}
        />
      </div>
      <div className={classes.flexSpacer} />
      <div className={classes.calendarButtonContainer}>
        <CalloutCalendar
          buttonProps={{ disabled: props.disabled }}
          calendarStrings={props.calendarStrings}
          dataAutomationId={`${props.dataAutomationId}CalloutCalendar`}
          defaultDate={props.defaultDate}
          id={props.calendarId}
          isDayPickerVisible={true}
          maxDate={props.maxDate}
          resetShowCalender={resetShowCalendar}
          showCalendar={showCalendar}
          onSelectDate={onSelectDate}
        />
      </div>
    </div>
  );
};

export const CalendarInput = withStyles(calendarInputStyles)(CalendarInputUnstyled) as React.FC<
  CalendarInputProps
>;
