import i18next from 'i18n';

/**
 * Formats the given amount based based on given currency and user's browser language.
 * If no format options are not provided, it will be default to display at least two fraction digits.
 *
 * @param amount value to format
 * @param currency used to define the format
 * @param formatOptions number format options
 */
export const formatPrice = (
  amount: string | number,
  currency: string,
  formatOptions?: Intl.NumberFormatOptions
) => {
  if (typeof amount === 'string') {
    amount = +amount;
  }
  const formattingOptions: Intl.NumberFormatOptions = formatOptions || {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return amount.toLocaleString(i18next.language, formattingOptions);
};
