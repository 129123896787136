import { SearchAtom } from 'components/atoms';
import { ActionType } from 'components/ions/index';
import { ISearchBox } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { barSearchStyles } from './BarSearch.styles';

/**
 * Props for the bar search
 * @prop {string} ariaLabel - ariaLabel for the Search
 * @prop {string} id - Id for the Search
 * @prop {string} placeholder - Placeholder text to be displayed in the Searchbox
 * @prop {string} focusPlaceholder - Optional different placeholder text to be displayed when the Searchbox is focussed/active
 */
export interface BarSearchProps {
  ariaLabel?: string;
  dataAutomationId?: string;
  componentRef?: React.RefObject<ISearchBox>;
  id?: string;
  placeholder: string;
  focusPlaceholder?: string;
  value?: string;
  onClear?: (event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>) => void;
  onChange?: (input?: string) => void;
  onChangeDebounced?: (input?: string) => void;
  onSearch?: (input: string) => void;
}

type Props = BarSearchProps & WithStyles<typeof barSearchStyles>;

const BarSearchUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <div className={props.classes.div}>
      <SearchAtom
        ariaLabel={props.ariaLabel}
        componentRef={props.componentRef}
        dataAutomationId={props.dataAutomationId}
        focusClass={props.classes.barActive}
        focusPlaceholder={props.focusPlaceholder}
        id={props.id}
        mainClass={props.classes.bar}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onChangeDebounced={props.onChangeDebounced}
        onClear={props.onClear}
        onSearch={props.onSearch}
      />
    </div>
  );
};

export const BarSearch = withStyles(barSearchStyles)(BarSearchUnstyled) as React.FC<BarSearchProps>;
