import { ThemeProps } from 'styles';

export const rejectButtonStyles = (theme: ThemeProps) => {
  const colors = theme.palette;
  return {
    reject: {
      backgroundColor: colors.danger,
      color: 'black',
      '&:hover': {
        backgroundColor: colors.dangerHover,
        color: 'black',
      },
      '&:active': {
        backgroundColor: colors.dangerActive,
        color: 'black',
      },
      '& i': {
        color: 'black',
      },
      '&:disabled': {
        backgroundColor: colors.backgroundDivider,
        color: colors.textPrimaryDisabled,
        '& i': {
          color: colors.textPrimaryDisabled,
        },
      },
    },
  };
};
