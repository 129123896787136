import { BlurrableAtom as Blurrable } from 'components/atoms/Overlays'; // When importing blurrable from ions it causes error to be thrown in cosmos preventing render.
import {
  CustomDialogBox,
  CustomDialogBoxProps,
  ImageDialogBox,
  ImageDialogBoxProps,
  TextDialogBox,
  TextDialogBoxProps,
} from 'components/ions';
import { NavBarHeight } from 'features/app/components/NavBar.styles';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'store/types';

const mapState = (state: RootState) => ({
  isHelpPanelOpen: state.app.isHelpPanelOpen,
  helpPanelWidth: state.app.helpPanelWidth,
});

const connector = connect(mapState);

export type DialogProviderProps = {
  id?: string;
  children: React.ReactNode;
} & ConnectedProps<typeof connector>;

export interface DialogProps {
  props?: ImageDialogBoxProps | TextDialogBoxProps | CustomDialogBoxProps;
  providedDialog?: React.ReactNode;
  hasImage?: boolean;
  isCustom?: boolean;
  isDialogProvided?: boolean;
}

export interface DialogContextType {
  isDialogOpened: boolean;
  openDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}

const defaultDialogProps: TextDialogBoxProps = {
  id: 'Dialog-Box',
  dialogClose: () => {},
  headingText: '',
  bodyText: '',
  height: 300,
  width: 400,
};

const setDialogBlur = (setBlur: React.Dispatch<React.SetStateAction<boolean>>) => setBlur(true);

const updateThenOpenDialog = (
  props: DialogProps,
  setDialogProps: React.Dispatch<React.SetStateAction<DialogProps>>,
  setBlur: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setDialogProps(props);
  setDialogBlur(setBlur);
};

/**
 *  To open a dialog call open dialog and pass in DialogProps.
 * Dialog props consists of props: ImageDialogBoxProps | DialogBoxProps | CustomDialogBoxProps,
 * optional flag hasImage, and optional flag isCustom.
 * hasImage should be true for a dialog with image icon.
 * isCustom should be true when rendering a CustomDialogBox.
 */
export const DialogContext = React.createContext<DialogContextType>({
  isDialogOpened: false,
  openDialog: (dialogProps: DialogProps): void => {},
  closeDialog: (): void => {},
});

export const DialogProviderDisconnected: React.FC<DialogProviderProps> = props => {
  const { children, isHelpPanelOpen, helpPanelWidth } = props;
  const [isBlurred, setBlur] = React.useState(false);
  const [dialogProps, setDialogProps] = React.useState<DialogProps>({
    props: defaultDialogProps,
    providedDialog: undefined,
    hasImage: false,
    isCustom: false,
  });

  /**
   * @property {DialogProps} dialogProps object consisting of {props: ImageDialogBox or DialogBox properties, hasImage: boolean flag to indicate if dialog will have an image}
   */
  const openDialog = (dialogProps?: DialogProps): void => {
    if (dialogProps) updateThenOpenDialog(dialogProps, setDialogProps, setBlur);
    else setDialogBlur(setBlur);
  };

  // generate dialogbox
  let Dialog = null;
  if (dialogProps.props) {
    if (dialogProps.hasImage) {
      Dialog = <ImageDialogBox {...(dialogProps.props as ImageDialogBoxProps)} />;
    } else if (dialogProps.isCustom) {
      Dialog = <CustomDialogBox {...(dialogProps.props as CustomDialogBoxProps)} />;
    } else {
      Dialog = <TextDialogBox {...(dialogProps.props as TextDialogBoxProps)} />;
    }
  } else if (dialogProps.providedDialog) {
    Dialog = dialogProps.providedDialog;
  }

  /**
   * close dialog and remove blur
   */
  const closeDialog = () => {
    setBlur(false);
    Dialog = null;
  };

  //set context's close dialog.
  defaultDialogProps.dialogClose = closeDialog;

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog, isDialogOpened: isBlurred }}>
      <Blurrable
        blurPosition={{
          top: NavBarHeight,
          right: isHelpPanelOpen ? helpPanelWidth / 2 : 0,
        }}
        id="Blurrable"
        isBlurred={isBlurred}
        onTopContent={Dialog}
      >
        {children}
      </Blurrable>
    </DialogContext.Provider>
  );
};

export const DialogProvider = connector(DialogProviderDisconnected);
