import { Success } from 'features-apollo/components/dialogs/StatusDialogs';
import { GET_QUOTE_SALES_INFO } from 'features-apollo/quote/components/PropertySheets/Sales/queries';
import { Fail, Processing } from 'features/components/dialogs';
import { Query } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { REQUEST_CREDIT_LINE } from './queries';
import { RequestCreditIncreaseView } from './RequestCreditIncreaseView';

enum DialogSteps {
  MainForm = 'MainForm',
  Processing = 'Processing',
  Success = 'Success',
  Fail = 'Fail',
}

const dialogDimension = {
  height: 272,
  width: 440,
};

interface RequestCreditIncreaseDialogProps {
  billingCurrency: string;
  quoteId: string;
}

export const RequestCreditIncreaseDialog: React.FC<RequestCreditIncreaseDialogProps> = props => {
  const { quoteId } = props;
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState<DialogSteps>(DialogSteps.MainForm);
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const [requestCreditLineGQL, { loading: isCreditCheckLoading, error, data }] = useMutation(
    REQUEST_CREDIT_LINE
  );

  const requestCreditLine = () => {
    requestCreditLineGQL({
      variables: {
        quoteId: quoteId,
      },
      update: useApolloClient => {
        const cached = useApolloClient.readQuery<Query>({
          query: GET_QUOTE_SALES_INFO,
          variables: { id: quoteId },
        });
        const cachedCopy = {
          getQuote: {
            ...oc(cached).getQuote(),
            soldTo: {
              ...oc(cached).getQuote.soldTo(),
              organization: {
                ...oc(cached).getQuote.soldTo.organization(),
                credit: {
                  ...oc(cached).getQuote.soldTo.organization.credit(),
                  reason: 'pendingreview',
                },
              },
            },
          },
        };

        useApolloClient.writeQuery({
          query: GET_QUOTE_SALES_INFO,
          variables: { id: quoteId },
          data: cachedCopy,
        });
      },
    });
  };

  if (error) {
    setCurrentStep(DialogSteps.Fail);
  } else if (!isCreditCheckLoading && currentStep === DialogSteps.Processing && data) {
    setCurrentStep(DialogSteps.Success);
  }

  const onSubmitClick = () => {
    setCurrentStep(DialogSteps.Processing);
    requestCreditLine();
  };

  const getCurrentView = (step: DialogSteps) => {
    switch (step) {
      case DialogSteps.Processing:
        return (
          <Processing
            {...dialogDimension}
            message1={t('quote::The credit increase is being requested.')}
          />
        );
      case DialogSteps.Success:
        return (
          <Success
            message={t('quote::You have successfully requested a credit increase.')}
            {...dialogDimension}
            dataAutomationId="creditIncreaseSuccess"
          />
        );
      case DialogSteps.Fail:
        return (
          <Fail
            {...dialogDimension}
            closeDialog={closeDialog}
            dataAutomationId="creditIncreaseFail"
            message={t('quote::Sorry, the "Credit Increase" action failed.')}
            onTryAgainClick={() => onSubmitClick()}
          />
        );
      case DialogSteps.MainForm:
      default:
        return <RequestCreditIncreaseView {...dialogDimension} onSubmitClick={onSubmitClick} />;
    }
  };
  return getCurrentView(currentStep);
};
