import { CommandButtonAtom } from 'components/atoms';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ActionType } from '../ButtonSharedProps';
import { navBarButtonStyles } from './NavBarButton.styles';

/**
 * Props for Callout use in the navigation bar
 * @prop {string} ariaLabel - Describes button's action
 * @prop {string} id - Identifies button's container
 * @prop {boolean} primaryColor - Use primary color defined in theme as background color
 * @prop {boolean} showActiveStyle - Set the color to an active style when callouts and panels are opened from navigation bar
 */
export interface NavBarButtonProps {
  ariaLabel: string;
  dataAutomationId?: string;
  iconName: string;
  id?: string;
  isDemoMode?: boolean;
  primaryColor?: boolean;
  showActiveStyle?: boolean;
  title?: string;
  onClick?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
}

type Props = NavBarButtonProps & WithStyles<typeof navBarButtonStyles>;

const NavBarButtonUnStyled: React.FC<Props> = (props: Props) => {
  const { classes, isDemoMode, iconName, primaryColor, showActiveStyle, ...buttonProps } = props;

  const getButtonStyles = () => {
    if (isDemoMode) {
      return { defaultStyle: classes.demo, activeStyle: classes.demo };
    }

    return primaryColor
      ? { defaultStyle: classes.primary, activeStyle: classes.primaryExpanded }
      : { defaultStyle: classes.command, activeStyle: classes.expanded };
  };

  const buttonStyles: { defaultStyle: string; activeStyle: string } = getButtonStyles();

  const buttonCurrentStyle = showActiveStyle ? buttonStyles.activeStyle : buttonStyles.defaultStyle;

  return (
    <CommandButtonAtom
      {...buttonProps}
      addClass={`${buttonCurrentStyle} ${classes.iconButton}`}
      iconProps={{ iconName }}
    />
  );
};

export const NavBarButton = withStyles(navBarButtonStyles)(NavBarButtonUnStyled) as React.FC<
  NavBarButtonProps
>;
