import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import {
  ProposalListColumn,
  ProposalListColumnProps,
} from '../ProposalListColumn/ProposalListColumn';
import { proposalListHeaderStyles } from './ProposalListHeader.styles';

export interface ProposalListHeaderProps {
  columns: ProposalListColumnProps[];
}

type Props = ProposalListHeaderProps & WithStyles<typeof proposalListHeaderStyles>;

export const ProposalListHeaderUnStyled: React.FC<Props> = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      {props.columns.map(col => {
        return <ProposalListColumn key={col.id} {...col} />;
      })}
    </div>
  );
};

export const ProposalListHeader = withStyles(proposalListHeaderStyles)(
  ProposalListHeaderUnStyled
) as React.FC<ProposalListHeaderProps>;
