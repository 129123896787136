import {
  Dialog,
  LinkExternal,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
} from 'components';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { infoDialogStyles } from './InfoDialog.styles';

export interface InfoDialogLearnMoreProps {
  url: string;
  displayText: string;
}

export interface InfoDialogSlideProps {
  id: string;
  mediaUrl: string;
  mediaDescription: string;
  headline?: string;
  bodyText: string;
  darkXButton?: boolean;
  learnMore?: InfoDialogLearnMoreProps;
  enabled: boolean;
}

export interface InfoDialogProps {
  height?: number;
  isMandatory: boolean;
  backButtonText: string;
  nextButtonText: string;
  closeButtonText: string;
  slides: InfoDialogSlideProps[];
  onCloseDialog: () => void;
  generateCarousel: (currentSlide: number) => JSX.Element;
}

type Props = InfoDialogProps & WithStyles<typeof infoDialogStyles>;

const InfoDialogUnstyled: React.FC<Props> = props => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slideCount = props.slides.length;
  const isLastSlide = currentSlide + 1 === slideCount;

  const onClickNext = () => {
    if (!isLastSlide) {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const onClickBack = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const currentSlideProps = props.slides[currentSlide];

  const media = (
    <div className={props.classes.media}>
      <img
        alt={currentSlideProps.mediaDescription}
        className={props.classes.image}
        height={338}
        src={currentSlideProps.mediaUrl}
        width={600}
      />
    </div>
  );

  const currentSlideLearnMore = currentSlideProps.learnMore;

  const carousel = props.slides.length > 1 ? props.generateCarousel(currentSlide) : null;

  const body = (
    <div className={props.classes.body} id={currentSlideProps.id}>
      <TextTitle addClass={props.classes.title}>{currentSlideProps.headline}</TextTitle>
      <TextBody>{currentSlideProps.bodyText}</TextBody>
      {currentSlideLearnMore && (
        <div className={props.classes.bodyLink}>
          <LinkExternal
            displayText={currentSlideLearnMore && currentSlideLearnMore.displayText}
            href={currentSlideLearnMore && currentSlideLearnMore.url}
            size="medium"
          />
        </div>
      )}
    </div>
  );

  const xButtonStyle = currentSlideProps.darkXButton
    ? props.classes.darkCloseButton
    : props.classes.lightCloseButton;

  const backButton = (
    <SecondaryButton
      dataAutomationId="infoDialogBackButton"
      disabled={currentSlide === 0}
      key={`info-dialog-${props.backButtonText}`}
      text={props.backButtonText}
      onClick={onClickBack}
    />
  );

  const nextButton = (
    <SecondaryButton
      dataAutomationId="infoDialogNextButton"
      key={`info-dialog-${props.nextButtonText}`}
      text={props.nextButtonText}
      onClick={onClickNext}
    />
  );

  const closeButton = (
    <PrimaryButton
      dataAutomationId="infoDialogCloseButton"
      key={`info-dialog-${props.closeButtonText}`}
      text={props.closeButtonText}
      onClick={props.onCloseDialog}
    />
  );

  const getFooterContent = () => {
    if (slideCount === 1) {
      return [closeButton];
    } else if (!isLastSlide) {
      return [backButton, nextButton];
    } else {
      return [backButton, closeButton];
    }
  };

  return (
    <Dialog
      bodyAddClass={
        props.isMandatory && !isLastSlide
          ? props.classes.bodyOverride
          : props.classes.bodyOverrideHeader
      }
      closeButtonAddClass={xButtonStyle}
      closeDialog={props.onCloseDialog}
      footerButtons={getFooterContent()}
      headerAddClass={props.classes.header}
      height={props.height}
      hideHeader={props.isMandatory && !isLastSlide}
      width={600}
    >
      {media}
      {body}
      {carousel}
    </Dialog>
  );
};

export const InfoDialog = withStyles(infoDialogStyles)(InfoDialogUnstyled);
