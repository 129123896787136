import { TextBodySmall } from 'components/ions/Text';
import { RuleType } from 'features/app/config/type';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { existingDiscountsListStyles } from './ExistingDiscountsList.styles';

const placeHolder = '-';

/**
 * @property {string} discountType type of discount (ceiling, future, standard).
 * @property {string} productSku sku or name of discount to show.
 * @property {string} dates date range where discount is effective
 * @property {string} discountPercent percentage of the discount.
 * @property {string} priceGuaranteeDate price ceiling date discount.
 * @property {string} meter future discount on a specific meter.
 * @property {string} ruleType future discount on a specific meter.
 * @property {string} pricingPolicy future discount on a specific meter.
 */
export interface ExistingDiscount {
  discountType: string;
  productSku?: string;
  dates: string;
  discountPercent?: string;
  priceGuaranteeDate?: string;
  ruleType?: string;
  pricingPolicy?: string;
  meter?: string;
}

/**
 * @property {string} existingDiscount translated string for 'Existing Discount' label.
 * @property {string} dates translated string for 'Dates' label.
 * @property {string} lock translated string for 'Lock' label.
 * @property {string} discount translated string for 'Discount' label.
 * @property {string} duration translated string for 'Duration' label.
 * @property {string} meter translated string for 'Meter' label.
 */
export interface ExistingDiscountLabels {
  existingDiscount: string;
  dates: string;
  lock: string;
  discount: string;
  duration: string;
  meter: string;
  oneTimeDiscount: string;
}

/**
 * @property {string} id optional Id tag for the list container
 * @property {ExistingDiscount} discounts array of Existing discount items to render.
 * @property {ExistingDiscountLabels} labels array of Existing discount label strings to render.
 */
export interface ExistingDiscountsListProps {
  id?: string;
  discounts: ExistingDiscount[];
  labels: ExistingDiscountLabels;
}

type Props = ExistingDiscountsListProps & WithStyles<typeof existingDiscountsListStyles>;

const renderIfPresent = (text?: string | number) => {
  if (text) {
    return text;
  }
  return placeHolder;
};

const renderCeiling = (
  discounts: ExistingDiscount[],
  labels: ExistingDiscountLabels,
  classes: Record<'rowLabel' | 'group' | 'row', string>
) => {
  const ceilingDiscounts = discounts.filter(
    discount => discount.discountType.toLowerCase() === RuleType.priceGuarantee.toLowerCase()
  );
  return (
    <div>
      {ceilingDiscounts.map((discount, index) => (
        <div className={classes.group} key={index}>
          {!!discount.productSku && (
            <div className={classes.row}>
              <TextBodySmall>{discount.productSku}</TextBodySmall>
            </div>
          )}
          <div className={classes.row}>
            <TextBodySmall>
              <span className={classes.rowLabel}>{labels.lock}</span>
              <span>{renderIfPresent(discount.priceGuaranteeDate)}</span>
            </TextBodySmall>
          </div>

          <div className={classes.row}>
            <TextBodySmall>
              <span className={classes.rowLabel}>{labels.discount}</span>
              <span>{renderIfPresent(discount.discountPercent)}%</span>
            </TextBodySmall>
          </div>
          <div className={classes.row}>
            <TextBodySmall>
              <span className={classes.rowLabel}>{labels.duration}</span>
              <span>{renderIfPresent(discount.dates)}</span>
            </TextBodySmall>
          </div>
        </div>
      ))}
    </div>
  );
};

const renderFuture = (
  discounts: ExistingDiscount[],
  labels: ExistingDiscountLabels,
  classes: Record<'rowLabel' | 'group' | 'row', string>
) => {
  const futureDiscounts = discounts.filter(
    discount => discount.discountType.toLowerCase() === RuleType.future.toLowerCase()
  );
  return (
    <div>
      {futureDiscounts.map((discount, index) => (
        <div className={classes.group} key={index}>
          {!!discount.productSku && (
            <div className={classes.row}>
              <TextBodySmall>{discount.productSku}</TextBodySmall>
            </div>
          )}
          <div className={classes.row}>
            <TextBodySmall>
              <span className={classes.rowLabel}>{labels.discount}</span>
              <span>{renderIfPresent(discount.discountPercent)}%</span>
            </TextBodySmall>
          </div>

          <div className={classes.row}>
            <TextBodySmall>
              <span className={classes.rowLabel}>{labels.duration}</span>
              <span>{renderIfPresent(discount.dates)}</span>
            </TextBodySmall>
          </div>

          {!!discount.meter && (
            <div className={classes.row}>
              <TextBodySmall>
                <span className={classes.rowLabel}>{labels.meter}</span>
                <span>{discount.meter}</span>
              </TextBodySmall>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const ExistingDiscountsListUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, id, discounts, labels } = props;
  return (
    <div className={classes.listContainer} id={id}>
      <div className={classes.ceilingFutureGroup}>
        <div>{renderCeiling(discounts, labels, classes)}</div>
        <div>{renderFuture(discounts, labels, classes)}</div>
      </div>
    </div>
  );
};

export const ExistingDiscountsList = withStyles(existingDiscountsListStyles)(
  ExistingDiscountsListUnstyled
) as React.FC<ExistingDiscountsListProps>;
