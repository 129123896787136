import { TestHeader } from 'services/types';

export enum OrganizationState {
  active = 'active',
  deleted = 'deleted',
  locked = 'locked',
}

export interface Address {
  addressType: OrganizationType;
  companyName: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  region?: string;
  country: string;
  postalCode?: string;
  phoneNumber?: string;
  email?: string;
}

export interface LegalEntity {
  address: Address;
  businessLocationId: string;
  tradeName?: string;
  taxIds?: TaxId[];
}

export interface TaxId {
  type: string;
  id: string;
  country: string;
  scope: string;
  startDate: Date;
  endDate: Date | null;
  taxIdReference: string;
}

export interface OrganizationSummary {
  id: string;
  accountId: string;
  backingIdentity?: string;
  version: number;
  cultureInfo: CultureInfo;
  description: string;
  eligibleForAccountPricing: boolean;
  legalEntity: LegalEntity;
  organizationType: OrganizationType;
  testHeader?: TestHeader | null;
  state: OrganizationState;
}

export interface LeadOrganizationSummary {
  id: string;
  accountId: string;
  legalEntity: LegalEntity;
}

export interface EdgeOrganization extends OrganizationSummary {
  audience: string;
  createdTimestamp: string;
  externalAccountReference: string;
  sandbox: string;
  selfUrl: string;
  updatedTimestamp: string;
}

export interface CultureInfo {
  culture: string;
  language: string;
}

export enum OrganizationType {
  organization = 'organization',
  individual = 'individual',
}

export interface RecipientSummary {
  id: string;
  accountId: string;
  description: string;
  fulfillmentIdentities: Record<string, string>;
}

export interface GetOrganizationRequest {
  accountId: string;
  id: string;
}

export interface UpdateTradeName {
  tradeName?: string;
  effectiveDate: Date;
}

export interface UpdateOrganizationAddress {
  address: Address;
  effectiveDate: Date;
}

export interface UpdateOrganizationRequest<T> {
  accountId: string;
  id: string;
  request: T;
}

export interface AddTaxIdRequest {
  id: string;
  country: string;
}

export interface AddTaxId {
  accountId: string;
  organizationId: string;
  organization: OrganizationSummary;
  request: AddTaxIdRequest;
}

export interface RemoveTaxIdRequest {
  taxId: string;
  taxIdReference: string;
  endDate: Date;
}

export interface RemoveTaxId {
  accountId: string;
  organizationId: string;
  request: RemoveTaxIdRequest;
}
