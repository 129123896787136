import { ThemeProps } from 'styles';

export const actionBarStyles = (theme: ThemeProps) => {
  return {
    commandBarStyle: {
      height: 30,
      justifyContent: 'space-between',
    },
  };
};
