import {
  demoFlights,
  Flight,
  insiderFlights,
  latestFlights,
  productionFlights,
  stableFlights,
} from './flightList';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export const endpoints = {
  [Environment.Prod]: 'https://quotecenterservice.l2o.microsoft.com/v1/flights',
  [Environment.Int]: 'https://quotecenterservice.int.l2o.microsoft.com/v1/flights',
};

export enum DefaultFlightMode {
  Latest = 'latest',
  Stable = 'stable',
  Insider = 'insider',
  Prod = 'production',
  Demo = 'demo',
}

export const defaultFlightList: Record<DefaultFlightMode, Record<Flight, boolean>> = {
  [DefaultFlightMode.Latest]: latestFlights,
  [DefaultFlightMode.Stable]: stableFlights,
  [DefaultFlightMode.Insider]: insiderFlights,
  [DefaultFlightMode.Prod]: productionFlights,
  [DefaultFlightMode.Demo]: demoFlights,
};

export interface FlightsConfig {
  environment: Environment;
  defaultFlightMode: DefaultFlightMode;
}
