import { getAppEnvironment, isFlightEnabled } from 'features-apollo/app/selectors';
import { QuoteObjectIdFragment } from 'features-apollo/quote/fragments';
import { getQueryGetQuotesArgs } from 'features-apollo/quote/selectors/quote';
import { MutationCreateQuoteData } from 'features-apollo/quote/types';
import { useGetUserPermissions } from 'features-apollo/user/hooks';
import { getUserMail } from 'features-apollo/user/selectors';
import { CreateQuoteInput, MutationCreateQuoteArgs } from 'generated/graphql';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';

import { useMutation } from '@apollo/react-hooks';

import { GET_QUOTES } from '../../queries';
import { QuoteContextWizard } from './QuoteContextWizard';

export const CREATE_QUOTE = gql`
  mutation CreateQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      ...QuoteObjectId
      audience
      clouds
      name
      status
      transactionModel
      lineItems {
        id
      }
    }
  }
  ${QuoteObjectIdFragment}
`;

const mapStateToProps = (state: RootState) => {
  return {
    environment: getAppEnvironment(state),
    quotesSearchFilters: getQueryGetQuotesArgs(state),
    userMail: getUserMail(state) || '',
    flights: {
      cspEnabled: isFlightEnabled(state, Flight.csp),
      directFGOEEnabled: isFlightEnabled(state, Flight.directFGOE),
      directGovEnabled: isFlightEnabled(state, Flight.directGov),
      indirectFGOEEnabled: isFlightEnabled(state, Flight.indirectFGOE),
      jioAsCspEnabled: isFlightEnabled(state, Flight.jioAsCSP),
      qcTransactEnabled: isFlightEnabled(state, Flight.qcTransact),
      usNatEnabled: isFlightEnabled(state, Flight.usNat),
    },
  };
};

type Props = ReturnType<typeof mapStateToProps>;

const QuoteContextWizardContainerDisconnected: React.FC<Props> = props => {
  const context = useContext(DialogContext);
  const history = useHistory();

  // --- Local state
  const [errored, setErroredState] = useState<boolean>(false);
  const onError = () => setErroredState(true);

  // --- Queries
  const { permissions } = useGetUserPermissions(props.userMail, props.environment);

  const [createQuote, { loading }] = useMutation<MutationCreateQuoteData, MutationCreateQuoteArgs>(
    CREATE_QUOTE,
    {
      errorPolicy: 'all',
      onCompleted: data => {
        if (data.createQuote) {
          history.push(routes.quote.forId(data.createQuote.id));
          context.closeDialog();
        } else {
          onError();
        }
      },
      onError,
      refetchQueries: [{ query: GET_QUOTES, variables: props.quotesSearchFilters }],
    }
  );

  // --- Event handlers
  const onCreate = (input: CreateQuoteInput) => createQuote({ variables: { input } });

  return (
    <QuoteContextWizard
      errored={errored}
      flights={props.flights}
      loading={loading}
      permissions={permissions || []}
      onCreate={onCreate}
    />
  );
};

export const QuoteContextWizardContainer = connect(mapStateToProps)(
  QuoteContextWizardContainerDisconnected
);
