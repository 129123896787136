import { dateFormat } from 'components/utilities/dates';
import { cardConstants } from 'features/proposal/components/DiscountCard/cardConstants';
import { Market } from 'features/proposal/supported-markets';
import { BulkDiscountApplyRequest } from 'features/proposal/types';
import {
  computeFirstAndLastTimezone,
  createProposalUpdateRequest,
  hasSoldToCustomer,
  isAgreementTypeLegacy,
} from 'features/proposal/utils';
import i18next from 'i18n';
import moment from 'moment-timezone';
import { IChoiceGroupOption, IComboBoxOption } from 'office-ui-fabric-react';
import { Availability, DisplaySkuAvailability, Product } from 'services/catalog/types';
import { productIds } from 'services/proposal/config';
import {
  LineItem,
  PriceAdjustmentType,
  PricingInstructionCondition,
  Proposal,
  ProposalUpdateRequest,
} from 'services/proposal/types';
import { oc } from 'ts-optchain';

import { isFamilyDiscount, isPasses, isSAPProduct } from '../ConfigCard/ConfigCardBusinessLogic';
import { DiscountApplyRequest, DiscountType } from './types';
import { getDiscountError, ruleTypeToDiscountType } from './utils';

export const availableDiscountType = {
  oneTime: {
    family: [
      cardConstants.devices,
      cardConstants.perpetual,
      cardConstants.software,
      cardConstants.passes,
    ],
    type: [cardConstants.azureSupport],
  },
  future: {
    family: [cardConstants.azure, cardConstants.software, cardConstants.passes],
    type: [cardConstants.azureSupport, cardConstants.SAP],
    actions: [cardConstants.purchase, cardConstants.consume],
  },
  priceGuarantee: {
    family: [cardConstants.azure],
    type: [cardConstants.azureFamilyDiscount],
    action: [cardConstants.consume],
  },
};

export const getSelectedSkuAvailabilities = (
  displayAvailabilities: DisplaySkuAvailability[],
  skuId?: string
) => {
  if (!displayAvailabilities.length) {
    throw new Error('No available skus');
  }
  return (
    displayAvailabilities.length &&
    displayAvailabilities.find(
      (displaySkuAvailability: DisplaySkuAvailability) => displaySkuAvailability.Sku.SkuId === skuId
    )
  );
};

export const hasFieldOverrideRuleForSku = (
  displayAvailabilities: DisplaySkuAvailability[],
  skuId: string
): boolean => {
  const matchedSku = getSelectedSkuAvailabilities(displayAvailabilities, skuId);
  return (
    !!matchedSku &&
    matchedSku.Availabilities.some(
      (availability: Availability) =>
        !!availability.PricingRuleIds &&
        availability.PricingRuleIds.some(s => s.includes(cardConstants.fieldOverrideRule))
    )
  );
};

export const isOneTimeOption = (hydratedProduct: Product, lineItem: LineItem): boolean => {
  const familyHasOnetimeDiscount = availableDiscountType.oneTime.family.includes(
    hydratedProduct.ProductFamily
  );
  const typeHasOnetimeDiscount = availableDiscountType.oneTime.type.includes(
    hydratedProduct.ProductType
  );
  const hasFieldOverride =
    !!lineItem.productIdentifier.skuId &&
    hasFieldOverrideRuleForSku(
      hydratedProduct.DisplaySkuAvailabilities,
      lineItem.productIdentifier.skuId
    );
  const hasAction = !!lineItem.productIdentifier.action;
  return hasFieldOverride && hasAction && (familyHasOnetimeDiscount || typeHasOnetimeDiscount);
};

export const isFutureOption = (hydratedProduct: Product, lineItem: LineItem): boolean => {
  const hasFieldOverride =
    !!lineItem.productIdentifier.skuId &&
    hasFieldOverrideRuleForSku(
      hydratedProduct.DisplaySkuAvailabilities,
      lineItem.productIdentifier.skuId
    );
  const familyIncludesFuture = availableDiscountType.future.family.includes(
    hydratedProduct.ProductFamily
  );
  const hasValidAction =
    !!lineItem.productIdentifier.action &&
    availableDiscountType.future.actions.includes(lineItem.productIdentifier.action);
  const productTypeIsSupportOffer = hydratedProduct.ProductType === cardConstants.azureSupport;
  const validSkuForFuture = (familyIncludesFuture || productTypeIsSupportOffer) && hasFieldOverride;
  const isAzureButNotSupportOffer =
    hydratedProduct.ProductFamily === cardConstants.azure &&
    !productTypeIsSupportOffer &&
    !lineItem.productIdentifier.skuId;
  const isAzureFamilyDiscount = hydratedProduct.ProductType === cardConstants.azureFamilyDiscount;
  const isSAPFamilyDiscount = hydratedProduct.ProductType === cardConstants.SAP;
  return (
    ((validSkuForFuture || isAzureButNotSupportOffer) && hasValidAction) ||
    isAzureFamilyDiscount ||
    isSAPFamilyDiscount
  );
};

export const isPriceGuaranteeOption = (hydratedProduct: Product, lineItem: LineItem): boolean => {
  const includesPriceGuaranteeFamily = availableDiscountType.priceGuarantee.family.includes(
    hydratedProduct.ProductFamily
  );
  const includesPriceGuaranteeType = availableDiscountType.priceGuarantee.type.includes(
    hydratedProduct.ProductType
  );
  const hasValidAction =
    !!lineItem.productIdentifier.action &&
    lineItem.productIdentifier.action === cardConstants.consume;
  const isProductLevel =
    !lineItem.productIdentifier.skuId &&
    oc(lineItem).extendedProperties.userPreferences.location('All Locations') === 'All Locations';
  const hasPriceGuarantee = !!hydratedProduct.Properties.HasPriceGuarantee;
  return (
    (isProductLevel && includesPriceGuaranteeFamily && hasPriceGuarantee && hasValidAction) ||
    includesPriceGuaranteeType
  );
};

export const createOrganizationsIfExist = (proposal: Proposal) => {
  if (!hasSoldToCustomer(proposal)) {
    return [];
  }
  return [
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      accountId: proposal.header.soldToCustomerLegalEntity!.accountId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organizationId: proposal.header.soldToCustomerLegalEntity!.organizationId!,
    },
  ];
};

export const createCustomPriceScope = (proposal: Proposal) => {
  const isLegacy = isAgreementTypeLegacy(proposal);
  const accountId = oc(proposal).header.soldToCustomerLegalEntity.accountId('');
  const enrollmentId = oc(proposal).header.extendedProperties.vlAgreementNumber('');
  return accountId
    ? {
        type: isLegacy ? 'eaEnrollment' : 'account',
        value: isLegacy ? enrollmentId : accountId,
      }
    : undefined;
};

//currently bulk discounting only supports Future discounts
export const buildBulkDiscountTypeChoices = (isFutureOnly?: boolean) => {
  return [{ key: cardConstants.future, text: cardConstants.future }];
};

export const buildDiscountTypeChoices = (
  hydratedProduct: Product,
  lineItem: LineItem
): IChoiceGroupOption[] => {
  let choices: IChoiceGroupOption[] = [];

  if (isOneTimeOption(hydratedProduct, lineItem)) {
    choices.push({ key: cardConstants.oneTime, text: cardConstants.oneTime });
  }
  if (isFutureOption(hydratedProduct, lineItem)) {
    choices.push({ key: cardConstants.future, text: cardConstants.future });
  }
  if (isPriceGuaranteeOption(hydratedProduct, lineItem)) {
    choices.push({ key: cardConstants.ceiling, text: cardConstants.ceiling });
  }
  return choices;
};

export const getDiscountType = (lineItem: LineItem, discountTypeChoices: { key: string }[]) => {
  const savedDiscountType = oc(lineItem).pricingInstruction.ruleType();
  let discountType: DiscountType = 'Future';
  if (savedDiscountType) {
    if (!lineItem.duration && !lineItem.startDate && !lineItem.endDate) {
      discountType = ruleTypeToDiscountType.onetime;
    } else {
      discountType = ruleTypeToDiscountType[savedDiscountType.toLowerCase()];
    }
  } else {
    const firstKey = discountTypeChoices.length && discountTypeChoices[0].key;
    discountType = (firstKey as DiscountType) || 'Future';
  }
  return discountType;
};

export const isApplyButtonDisabled = (
  discountAmount: string,
  selectedFutureEndDate: string,
  selectedCeilingEndDate: string,
  selectADuration: string,
  isProposalReadOnly: boolean,
  discountType?: DiscountType,
  isBulkDiscount?: boolean
) => {
  if (!discountType || isProposalReadOnly) {
    return true;
  }
  const invalidDiscount = !!getDiscountError(discountAmount, discountType, isBulkDiscount);
  if (discountType === 'One Time') {
    return invalidDiscount;
  } else if (discountType === 'Ceiling') {
    return selectedCeilingEndDate === selectADuration || invalidDiscount;
  } else {
    return selectedFutureEndDate === selectADuration || invalidDiscount;
  }
};

export const getPricingInstructionApplicability = (
  lineItem: LineItem,
  hydratedProduct: Product
) => {
  const isFamily = hydratedProduct.ProductType === cardConstants.azureFamilyDiscount;
  const isSku = !!lineItem.productIdentifier.skuId;
  if (isFamily) {
    return cardConstants.productFamily;
  } else if (isSku) {
    return cardConstants.sku;
  }
  return cardConstants.product;
};

export const applyOneTimeDiscount = (
  lineItem: LineItem,
  discountPercentage: number,
  hydratedProduct: Product,
  proposal: Proposal,
  updateProposal: (request: ProposalUpdateRequest) => void
) => {
  const customPriceScope = createCustomPriceScope(proposal);
  const newLineItem: LineItem = {
    ...lineItem,
    fulfillmentReferenceData: undefined,
    startDate: undefined,
    endDate: undefined,
    duration: undefined,
    extendedProperties: {
      ...lineItem.extendedProperties,
    },
    productIdentifier: {
      ...lineItem.productIdentifier,
      action: cardConstants.purchase,
      productFamily: hydratedProduct.ProductFamily,
    },
    pricingInstruction: {
      applicability: getPricingInstructionApplicability(lineItem, hydratedProduct),
      ruleType: cardConstants.discount,
      discountPercentage: discountPercentage,
      customPriceScope,
      productIdentifier: {
        productId: lineItem.productIdentifier.productId,
        availabilityId: lineItem.productIdentifier.availabilityId,
        action: cardConstants.purchase,
        skuId: lineItem.productIdentifier.skuId,
        productFamily: hydratedProduct.ProductFamily,
      },
      organizations: createOrganizationsIfExist(proposal),
      //TODO: cameneks, priceAdjustmentType what is the default?
      priceAdjustmentType: oc(lineItem).pricingInstruction.priceAdjustmentType(
        PriceAdjustmentType.new
      ),
    },
  };
  const proposalRequest = createProposalUpdateRequest(newLineItem, proposal);
  updateProposal(proposalRequest);
};

export const setMeterConditions = (
  lineItem: LineItem,
  meter?: string,
  isPassesProduct?: boolean,
  market?: string,
  currency?: string
) => {
  let conditions = oc(lineItem).extendedProperties.userPreferences.conditions([]);
  conditions = conditions.filter(
    (condition: PricingInstructionCondition) => condition.name !== cardConstants.MeterType
  );
  if (isPassesProduct) {
    market && conditions.push({ name: 'Market', type: 'equalAny', value: [market] });
    currency && conditions.push({ name: 'Currency', type: 'equalAny', value: [currency] });
  }
  if (meter && meter !== cardConstants.All) {
    conditions.push({ name: 'MeterType', type: 'equalAny', value: [meter] });
    return conditions;
  }
  return conditions && conditions.length ? conditions : undefined;
};

export const buildMeterDropdownOptions = (
  lineItem: LineItem,
  hydratedProduct: Product
): IComboBoxOption[] => {
  const matchedSku = getSelectedSkuAvailabilities(
    hydratedProduct.DisplaySkuAvailabilities,
    lineItem.productIdentifier.skuId
  );
  const meters: IComboBoxOption[] = [];
  if (matchedSku && matchedSku.Availabilities.length > 1) {
    matchedSku.Availabilities.forEach(Availability => {
      if (Availability.Properties.MeterType && Availability.Terms && Availability.Terms.length)
        meters.push({ text: Availability.Properties.MeterType, key: Availability.Terms[0].TermId });
    });
  }
  if (meters.length > 1) {
    return [{ text: cardConstants.All, key: cardConstants.All }, ...meters];
  }
  return meters;
};

export const computeDates = (
  startDate: string,
  endDate: string,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  market: Market,
  ceilingDate?: string
): { startDate?: string; endDate?: string; duration?: string; priceGuaranteeDate?: string } => {
  const timezones = computeFirstAndLastTimezone(
    market,
    shouldDisplayInDayFormatAndUseMarketTimezone
  );
  let dates;
  if (moment(ceilingDate).isValid()) {
    dates = {
      priceGuaranteeDate: moment
        .tz(ceilingDate, timezones.first)
        .utc()
        .format(),
    };
  } else {
    dates = {
      priceGuaranteeDate: undefined,
    };
  }
  if (startDate === 'order placement') {
    return { ...dates, duration: endDate };
  } else {
    if (moment(endDate).isValid()) {
      const dayOrMonth = shouldDisplayInDayFormatAndUseMarketTimezone ? 'day' : 'month';
      return {
        ...dates,
        startDate: moment
          .tz(startDate, timezones.first)
          .utc()
          .format(),
        endDate: moment
          .tz(endDate, timezones.last)
          .endOf(dayOrMonth)
          .utc()
          .format(),
      };
    } else {
      const momentStartDate = moment.tz(startDate, timezones.first);
      const durationInMonths = moment.duration(endDate).asMonths();
      if (shouldDisplayInDayFormatAndUseMarketTimezone) {
        const ret = {
          ...dates,
          startDate,
          endDate: momentStartDate
            .clone()
            .add(durationInMonths, 'months')
            .endOf('day')
            .utc()
            .format(),
        };
        return ret;
      } else {
        //TODO: cameneks endof month logic
        return {
          ...dates,
          startDate,
          endDate: momentStartDate
            .clone()
            .add(durationInMonths - 1, 'months')
            .endOf('month')
            .utc()
            .format(),
        };
      }
    }
  }
};

export const buildDiscountConfigureSummary = (
  lineItem: LineItem,
  market: Market,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  hydratedProduct?: Product
) => {
  if (hydratedProduct) {
    const options = buildMeterDropdownOptions(lineItem, hydratedProduct);
    const savedMeter =
      lineItem.pricingInstruction &&
      lineItem.pricingInstruction.conditions &&
      lineItem.pricingInstruction.conditions.find(condition => condition.name === 'MeterType');
    const meterValue = savedMeter && savedMeter.value[0];
    const matchingOption = options.find(option => option.key === meterValue);
    if (meterValue) {
      return matchingOption ? matchingOption.text : meterValue;
    }
  }
  const timezones = computeFirstAndLastTimezone(
    market,
    shouldDisplayInDayFormatAndUseMarketTimezone
  );
  const ruleType =
    lineItem.pricingInstruction &&
    lineItem.pricingInstruction.ruleType &&
    lineItem.pricingInstruction.ruleType.toLowerCase();

  const monthsIso = lineItem.duration;
  const months = moment.duration(monthsIso).asMonths();
  const yearString = Math.ceil(months / 12) > 1 ? 'years' : 'year';
  const monthString = months > 1 ? 'months' : 'month';
  const duration = months % 12 === 0 ? `${months / 12} ${yearString}` : `${months} ${monthString}`;

  const priceGuaranteeDate =
    lineItem.pricingInstruction && lineItem.pricingInstruction.priceGuaranteeDate;

  // Ceiling
  if (ruleType === cardConstants.priceGuarantee) {
    if (priceGuaranteeDate && lineItem.endDate) {
      const date = moment.tz(lineItem.endDate, timezones.last).format(dateFormat);
      return i18next.t('quote::Ceiling, ends {{date}}', { date });
    } else if (monthsIso) {
      return i18next.t('quote::Ceiling, {{duration}}', { duration });
    } else {
      return i18next.t('quote::Ceiling');
    }
  }
  // Future
  else if (monthsIso) {
    return i18next.t('quote::Future, {{duration}}', { duration });
  } else if (lineItem.endDate && moment(lineItem.endDate).isValid()) {
    const date = moment.tz(lineItem.endDate, timezones.last).format(dateFormat);
    return i18next.t('quote::Future, ends {{date}}', { date });
  }
  // One-Time
  else if (lineItem.pricingInstruction && lineItem.pricingInstruction.discountPercentage) {
    return cardConstants.oneTimeSummary;
  }
  // Needs configuration
  else {
    return cardConstants.configure;
  }
};

export const removeDiscount = (
  lineItem: LineItem,
  proposal: Proposal,
  updateProposal: (request: ProposalUpdateRequest) => void
) => {
  const newLineItem: LineItem = {
    ...lineItem,
    startDate: undefined,
    endDate: undefined,
    duration: undefined,
    fulfillmentReferenceData: undefined,
    extendedProperties: {
      ...lineItem.extendedProperties,
      userPreferences: {
        ...(lineItem.extendedProperties && lineItem.extendedProperties.userPreferences),
        availabilityTermId: lineItem.productIdentifier.availabilityTermId,
      },
    },
    pricingInstruction: undefined,
  };
  const request = createProposalUpdateRequest(newLineItem, proposal);
  updateProposal(request);
};

export const bulkRemoveDiscounts = (
  lineItems: LineItem[],
  proposal: Proposal,
  updateProposal: (request: ProposalUpdateRequest) => void
) => {
  const updatedLineItems: LineItem[] = lineItems.map(item => {
    return {
      ...item,
      startDate: undefined,
      endDate: undefined,
      duration: undefined,
      fulfillmentReferenceData: undefined,
      extendedProperties: {
        ...item.extendedProperties,
        userPreferences: {
          ...(item.extendedProperties && item.extendedProperties.userPreferences),
          availabilityTermId: item.productIdentifier.availabilityTermId,
        },
      },
      pricingInstruction: undefined,
    };
  });

  const newLineItems: LineItem[] = proposal.lineItems.map(item => {
    const match = updatedLineItems.find(newItem => newItem.id === item.id);
    return match ? match : item;
  });

  const requestProposal = {
    header: proposal.header,
    lineItems: newLineItems,
  };

  const request = {
    etag: proposal.etag,
    proposalId: proposal.id,
    proposal: requestProposal,
  };
  updateProposal(request);
};

const getBulkFutureDates = (
  lineItem: LineItem,
  market: Market,
  defaultStart: string,
  defaultEnd: string,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  startDate?: string,
  endDate?: string
): { startDate?: string; endDate?: string; duration?: string } => {
  const applyStartDate = startDate && startDate !== 'mixed';
  const applyEndDate = endDate && endDate !== 'mixed';

  if (!applyStartDate && !applyEndDate) {
    if (lineItem.duration) {
      return { duration: lineItem.duration };
    } else if (lineItem.endDate && moment(lineItem.endDate).isValid()) {
      let dates;
      dates = {
        endDate: lineItem.endDate.toString(),
      };
      if (lineItem.startDate && moment(lineItem.startDate).isValid()) {
        dates = {
          ...dates,
          startDate: lineItem.startDate.toString(),
        };
      }
      return dates;
    } else {
      return computeDates(
        defaultStart,
        defaultEnd,
        shouldDisplayInDayFormatAndUseMarketTimezone,
        market
      );
    }
  }

  let startToUse = startDate || defaultStart;
  let endToUse = endDate || defaultEnd;

  if (!applyStartDate) {
    startToUse = lineItem.startDate ? lineItem.startDate.toString() : defaultStart;
  }
  if (!applyEndDate) {
    if (lineItem.duration) {
      endToUse = lineItem.duration ? lineItem.duration.toString() : defaultEnd;
    } else {
      endToUse = lineItem.endDate ? lineItem.endDate.toString() : defaultEnd;
    }
  }
  return computeDates(startToUse, endToUse, shouldDisplayInDayFormatAndUseMarketTimezone, market);
};

export const applyBulkFutureDiscount = (
  lineItems: LineItem[],
  hydratedProducts: Product[],
  proposal: Proposal,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  onBulkDiscountApply: (bulkDiscountApplyRequest: BulkDiscountApplyRequest) => void,
  discountPercentage?: number | string,
  startDate?: string,
  endDate?: string,
  defaultStart?: string,
  defaultEnd?: string
) => {
  const market = proposal.header.pricingContext.market;
  const currency = proposal.header.pricingContext.billingCurrency;
  const customPriceScope = createCustomPriceScope(proposal);

  const newLineItems: LineItem[] = [];
  lineItems.forEach(item => {
    const discountAmount =
      discountPercentage && discountPercentage !== '(mixed)'
        ? +discountPercentage
        : oc(item).pricingInstruction.discountPercentage();
    const product = hydratedProducts.find(
      product => product.ProductId === oc(item).productIdentifier.productId('')
    );
    if (product) {
      const dates = getBulkFutureDates(
        item,
        market,
        defaultStart || 'order placement',
        defaultEnd || 'P18M',
        shouldDisplayInDayFormatAndUseMarketTimezone,
        startDate,
        endDate
      );
      const meterOptions = buildMeterDropdownOptions(item, product);
      const hasSelectedMeter = oc(item)
        .pricingInstruction.conditions([])
        .find(condition => condition.name === 'MeterType');
      const selectedMeter =
        (hasSelectedMeter && hasSelectedMeter.value[0]) ||
        (meterOptions.length && meterOptions[0].key.toString());

      newLineItems.push({
        ...item,
        fulfillmentReferenceData: `quoteId:${proposal.id},lineItemId:${item.id}`,
        startDate: dates.startDate ? moment(dates.startDate).toDate() : undefined,
        endDate: dates.endDate ? moment(dates.endDate).toDate() : undefined,
        duration: dates.duration,
        extendedProperties: {
          ...item.extendedProperties,
          userPreferences: {
            ...(item.extendedProperties && item.extendedProperties.userPreferences),
            availabilityTermId: item.productIdentifier.availabilityTermId,
            availabilityId: item.productIdentifier.availabilityId,
            productType: item.productIdentifier.productType,
          },
        },
        quantity: 1,
        isReadyForPricing: true,
        pricingInstruction: {
          applicability: getPricingInstructionApplicability(item, product),
          ruleType: cardConstants.discount,
          discountPercentage: discountAmount,
          conditions: setMeterConditions(
            item,
            selectedMeter || '',
            isPasses(product),
            market,
            currency
          ),
          customPriceScope,
          productIdentifier: {
            productId: item.productIdentifier.productId,
            availabilityId: item.productIdentifier.availabilityId,
            action:
              isFamilyDiscount(product) || isSAPProduct(product)
                ? cardConstants.consume
                : item.productIdentifier.action || cardConstants.purchase,
            skuId: item.productIdentifier.skuId,
            productFamily: product.ProductFamily,
          },
          organizations: createOrganizationsIfExist(proposal),
          priceAdjustmentType: PriceAdjustmentType.new,
        },
      });
    }
  });
  const discountApplyRequest: BulkDiscountApplyRequest = {
    productIds: hydratedProducts.map(product => product.ProductId),
    newLineItems,
    proposal,
  };
  onBulkDiscountApply(discountApplyRequest);
};

export const applyFutureDiscount = (
  lineItem: LineItem,
  discountPercentage: number,
  hydratedProduct: Product,
  proposal: Proposal,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  onDiscountApply: (discountApplyRequest: DiscountApplyRequest) => void,
  startDate: string,
  endDate: string,
  meter?: string
) => {
  const isPassesProduct = isPasses(hydratedProduct);
  const market = proposal.header.pricingContext.market;
  const currency = proposal.header.pricingContext.billingCurrency;

  const datesToApply = computeDates(
    startDate,
    endDate,
    shouldDisplayInDayFormatAndUseMarketTimezone,
    market
  );
  const customPriceScope = createCustomPriceScope(proposal);
  const isFamilyDiscountProduct = isFamilyDiscount(hydratedProduct);
  const isSAPproduct = isSAPProduct(hydratedProduct);
  const defaultAction = lineItem.productIdentifier.action || cardConstants.purchase;
  const newLineItem: LineItem = {
    ...lineItem,
    fulfillmentReferenceData: `quoteId:${proposal.id},lineItemId:${lineItem.id}`,
    startDate: datesToApply.startDate ? moment(datesToApply.startDate).toDate() : undefined,
    endDate: datesToApply.endDate ? moment(datesToApply.endDate).toDate() : undefined,
    duration: datesToApply.duration,
    extendedProperties: {
      ...lineItem.extendedProperties,
      userPreferences: {
        ...(lineItem.extendedProperties && lineItem.extendedProperties.userPreferences),
        availabilityTermId: lineItem.productIdentifier.availabilityTermId,
        availabilityId: lineItem.productIdentifier.availabilityId,
        productType: lineItem.productIdentifier.productType,
      },
    },
    quantity: 1,
    isReadyForPricing: true,
    pricingInstruction: {
      applicability: getPricingInstructionApplicability(lineItem, hydratedProduct),
      ruleType: cardConstants.discount,
      discountPercentage,
      conditions: setMeterConditions(lineItem, meter, isPassesProduct, market, currency),
      customPriceScope,
      productIdentifier: {
        productId: lineItem.productIdentifier.productId,
        availabilityId: lineItem.productIdentifier.availabilityId,
        action: isFamilyDiscountProduct || isSAPproduct ? cardConstants.consume : defaultAction,
        skuId: lineItem.productIdentifier.skuId,
        productFamily: hydratedProduct.ProductFamily,
      },
      organizations: createOrganizationsIfExist(proposal),
      priceAdjustmentType: PriceAdjustmentType.new,
    },
  };
  const discountApplyRequest: DiscountApplyRequest = {
    id: productIds.discountFulfillmentDocument,
    newLineItem,
    proposal,
  };
  onDiscountApply(discountApplyRequest);
};

export const applyPriceGuaranteeDiscount = (
  lineItem: LineItem,
  discountPercentage: number,
  hydratedProduct: Product,
  proposal: Proposal,
  shouldDisplayInDayFormatAndUseMarketTimezone: boolean,
  onDiscountApply: (discountApplyRequest: DiscountApplyRequest) => void,
  startDate: string,
  endDate: string,
  ceilingDate: string
) => {
  const market = proposal.header.pricingContext.market;
  const datesToApply = computeDates(
    startDate,
    endDate,
    shouldDisplayInDayFormatAndUseMarketTimezone,
    market,
    ceilingDate
  );

  const customPriceScope = createCustomPriceScope(proposal);
  const isFamilyDiscountProduct = isFamilyDiscount(hydratedProduct);
  const defaultAction = lineItem.productIdentifier.action || cardConstants.purchase;
  const newLineItem: LineItem = {
    ...lineItem,
    fulfillmentReferenceData: `quoteId:${proposal.id},lineItemId:${lineItem.id}`,
    startDate: datesToApply.startDate ? moment(datesToApply.startDate).toDate() : undefined,
    endDate: datesToApply.endDate ? moment(datesToApply.endDate).toDate() : undefined,
    duration: datesToApply.duration,
    extendedProperties: {
      ...lineItem.extendedProperties,
      userPreferences: {
        ...(lineItem.extendedProperties && lineItem.extendedProperties.userPreferences),
        availabilityTermId: lineItem.productIdentifier.availabilityTermId,
        availabilityId: lineItem.productIdentifier.availabilityId,
        productType: lineItem.productIdentifier.productType,
      },
    },
    quantity: 1,
    isReadyForPricing: true,
    pricingInstruction: {
      ...lineItem.pricingInstruction,
      conditions: oc(lineItem).extendedProperties.userPreferences.conditions(),
      applicability: getPricingInstructionApplicability(lineItem, hydratedProduct),
      ruleType: cardConstants.priceGuarantee,
      discountPercentage,
      priceGuaranteeDate: datesToApply.priceGuaranteeDate
        ? moment(datesToApply.priceGuaranteeDate).toDate()
        : undefined,
      pricingPolicy: 'protected',
      customPriceScope,
      productIdentifier: {
        productId: lineItem.productIdentifier.productId,
        availabilityId: lineItem.productIdentifier.availabilityId,
        action: isFamilyDiscountProduct ? cardConstants.consume : defaultAction,
        skuId: lineItem.productIdentifier.skuId,
        productFamily: hydratedProduct.ProductFamily,
      },
      organizations: createOrganizationsIfExist(proposal),
      priceAdjustmentType: PriceAdjustmentType.new,
    },
  };
  const discountApplyRequest: DiscountApplyRequest = {
    id: productIds.discountFulfillmentDocument,
    newLineItem,
    proposal,
  };
  onDiscountApply(discountApplyRequest);
};
