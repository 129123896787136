/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Address,
  ConditionItem,
  ConditionsList,
  DetailsNotificationsList,
  NotificationItem,
  NotificationType,
  TextBody,
  TextBodySmall,
  TextTitleSecondary,
} from 'components';
import { LabelList, LabelListItem } from 'components/ions/CuratedText/LabelList/LabelList';
import { NotificationActionLink } from 'features-apollo/components';
import {
  ExistingDiscount,
  ExistingDiscountLabels,
  ExistingDiscountsList,
} from 'features-apollo/quote/components';
import { AddOn, AddOns } from 'features/proposal/components/AddOns';
import { Address as GqlAddress, Quote, EcifLineItem } from 'generated/graphql';
import * as React from 'react';

import { getAddressProps } from '../utils';
import { LocationsRenderer } from './LocationsRenderer';

export const renderConditions = (
  conditions: ConditionItem[],
  classes: any,
  largerText?: boolean
) => {
  if (conditions && conditions.length > 0) {
    return (
      <div className={classes.sectionRow}>
        <ConditionsList
          conditions={conditions}
          id="DetailsPane-Conditions"
          largerText={largerText}
        />
      </div>
    );
  }
  return null;
};

export const renderLabelList = (items: LabelListItem[], rowClassName: string) => {
  if (items && items.length) {
    return (
      <div className={rowClassName}>
        <LabelList items={items} />
      </div>
    );
  }
};

/**
 * Displays the notifications sorted in the following order:
 * - errors
 * - standard
 * - warning
 * - info
 *
 * @param notifications notification list
 * @param classes styling
 * @returns DetailsNotifications component | undefined when list is empty
 */
export const renderNotifications = (
  notifications: NotificationItem[],
  classes: any,
  quote?: Quote,
  lineItem?: EcifLineItem,
) => {
  if (!notifications.length) {
    return;
  }

  const errorNotifications: NotificationItem[] = [];
  const standardNotifications: NotificationItem[] = [];
  const warningNotifications: NotificationItem[] = [];
  const infoNotifications: NotificationItem[] = [];

  notifications.forEach(notification => {
    let newNotification = {
      ...notification,
    };
    if (notification.source && notification.source !== null && quote) {
      newNotification = {
        ...notification,
        action: <NotificationActionLink notification={notification} quote={quote} />,
      };
    }
    switch (notification.type) {
      case NotificationType.error:
        if (lineItem?.__typename !== 'EcifLineItem') {
          errorNotifications.push(newNotification);
        }
        break;
      case NotificationType.standard:
        standardNotifications.push(newNotification);
        break;
      case NotificationType.warning:
        warningNotifications.push(newNotification);
        break;
      case NotificationType.info:
        infoNotifications.push(newNotification);
        break;
    }
  });

  const sortedNotifications = errorNotifications.concat(
    standardNotifications,
    warningNotifications,
    infoNotifications
  );

  return (
    <div className={classes.sectionRow} data-automation-id="Details-Notifications">
      <DetailsNotificationsList
        dataAutomationId="detailsNotificationList"
        id="details-notification-list"
        notifications={sortedNotifications}
      />
    </div>
  );
};

export const renderPartners = (partners: string[], classes: any) => {
  if (partners && partners.length > 0) {
    return (
      <div className={classes.sectionRow} data-automation-id="Details-Partners">
        <div>
          <TextBody>
            <span className={classes.semiBold}>Partners</span>
          </TextBody>
        </div>
        <div className={classes.innerRow}>
          {partners.map((name, index) => (
            <span className={classes.innerRow} key={name + index}>
              <TextBodySmall>{name}</TextBodySmall>
            </span>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export const renderLocations = (
  locations: string[],
  labelTitle: string,
  classes: { locationsContainer: string }
) => {
  return (
    <div className={classes.locationsContainer}>
      <TextBodySmall>{labelTitle}</TextBodySmall>
      <LocationsRenderer collapseProps={{ collapseAfter: 5 }} locations={locations} />
    </div>
  );
};

export const renderDiscounts = (
  classes: Record<string, string>,
  labels: ExistingDiscountLabels,
  discounts?: ExistingDiscount[]
) => {
  if (discounts && discounts.length > 0) {
    return (
      <div className={classes.sectionRow} data-automation-id="Details-Discounts">
        <TextTitleSecondary>{labels.existingDiscount}</TextTitleSecondary>
        <div className={classes.innerRow}>
          <ExistingDiscountsList discounts={discounts} labels={labels} />
        </div>
      </div>
    );
  }
  return null;
};

export const renderAddOns = (classes: any, addOns?: AddOn[]) => {
  if (addOns && addOns.length) {
    return (
      <div className={classes.detailsPaneSection} data-automation-id="Details-Addons">
        <AddOns addOns={addOns} />
      </div>
    );
  }
  return null;
};

export const renderAddress = (classes: any, address: GqlAddress) => {
  return (
    <div className={classes.address}>
      <Address {...getAddressProps(address)} />
    </div>
  );
};

export const renderLabeledList = (label: string, values: string[], classes: any) => {
  if (values.length > 0) {
    return (
      <div className={classes.discountRow}>
        <div className={classes.label}>
          <TextBodySmall>
            <span>{label}</span>
          </TextBodySmall>
        </div>
        <div className={classes.shortText}>
          {values.map((name, index) => (
            <span className={classes.listRow} key={name + index}>
              <TextBodySmall dataAutomationId={name}>{name}</TextBodySmall>
            </span>
          ))}
        </div>
      </div>
    );
  }
  return null;
};
