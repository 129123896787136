import {
  AgreementTypes,
  ModernAgreementActualConnection,
  ModernAgreementDocument,
  Quote,
} from 'generated/graphql';

import { getLeadOrganization } from './organization';
import { isQuoteIndirect } from './quote';

// -- Extract agreement data -------------------------------------------

/**
 * Gets link to display modern agreement.
 * It will return a link to download if the link to display is not available.
 */
export const getModernAgreementPreviewLink = (agreement: ModernAgreementDocument) => {
  const { documentDisplayUri, documentDownloadUri } = agreement;
  return documentDisplayUri || documentDownloadUri;
};

/**
 * Gets link to display special agreement.
 * It will return a link to download if the link to display is not available.
 */
export const getSpecialAgreementPreviewLink = (agreements: ModernAgreementActualConnection) => {
  // We only expect one special agreement related to the quote
  const specialAgreement = [...agreements.edges].pop();
  if (!specialAgreement) {
    return;
  }

  const { documentDisplayUri, documentDownloadUri } = specialAgreement.node;
  return documentDisplayUri || documentDownloadUri;
};

// -- Evaluates agreement data in the quote -------------------------------------------

/**
 * Determines the modern agreement type based on information in the quote
 */
export const getModernAgreementType = (quote: Quote) => {
  const quoteIsIndirect = isQuoteIndirect(quote);
  const leadOrganization = getLeadOrganization(quote);

  if (quoteIsIndirect) {
    return AgreementTypes.Special;
  } else if (leadOrganization) {
    return AgreementTypes.Apt;
  } else {
    return AgreementTypes.Mca;
  }
};
