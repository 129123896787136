import { Spinner, SpinnerSize } from 'components/ions';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { quoteBody } from 'features-apollo/ActiveQuoteContext.queries';
import { Fail, Processing } from 'features-apollo/components/dialogs';
import { Success } from 'features-apollo/quote/components/Dialogs/Shared';
import { Quote } from 'generated/graphql';
import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { TransactWarningView } from './TransactWarning';

enum DialogSteps {
  WarningView,
  Processing,
  Success,
  Fail,
}

const dialogDimenstions = {
  height: 264,
  width: 448,
};

export const TRANSACT_QUOTE = gql`
  mutation TransactQuote($id: String!) {
    transactQuote(id: $id) {
      ...QuoteBody
    }
  }
  ${quoteBody}
`;

export const TransactDialog: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState<DialogSteps>(DialogSteps.WarningView);
  const { t } = useTranslation();
  const { activeQuote, loading } = React.useContext(ActiveQuoteContext);

  const [transact] = useMutation<{ transactQuote: Quote }, { id: string }>(TRANSACT_QUOTE, {
    onCompleted: () => {
      setCurrentStep(DialogSteps.Success);
    },
    onError: () => {
      setCurrentStep(DialogSteps.Fail);
    },
  });

  const transactQuote = () => {
    if (
      !!oc(activeQuote)
        .id('')
        .trim()
    ) {
      transact({ variables: { id: oc(activeQuote).id('') } });
      setCurrentStep(DialogSteps.Processing);
    }
  };

  // Switch views -----------------------------------------------------------
  const getCurrentView = (step: DialogSteps) => {
    switch (step) {
      case DialogSteps.Processing:
        return (
          <Processing
            {...dialogDimenstions}
            message1={t('quote::The quote is being transacted.')}
          />
        );
      case DialogSteps.Fail:
        return (
          <Fail
            {...dialogDimenstions}
            dataAutomationId="transactFail"
            message={t('quote::Sorry, the "Transact" action failed.')}
            onTryAgainClick={transactQuote}
          />
        );
      case DialogSteps.Success:
        return (
          <Success
            {...dialogDimenstions}
            dataAutomationId="transactSuccess"
            message={t('quote::The quote has been transacted.')}
          />
        );
      case DialogSteps.WarningView:
      default:
        return <TransactWarningView transactQuote={transactQuote} />;
    }
  };

  return loading ? <Spinner size={SpinnerSize.medium} /> : getCurrentView(currentStep);
};
