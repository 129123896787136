import { ThemeProps } from 'styles';

export const maxZIndex = 2147483647;

export const sharedStyles = (theme: ThemeProps) => ({
  roundedCorners: {
    borderRadius: '2px',
  },

  width100: {
    width: '100%',
  },

  padLeft20: {
    paddingLeft: 20,
  },
  marginLeft20: {
    marginLeft: 20,
  },

  flexAlignCenter: {
    justifyContent: 'center',
  },

  flexAlignRight: {
    justifyContent: 'flex-end',
  },
  padLeft50Percent: {
    paddingLeft: '50%',
  },
  padRight50Percent: {
    paddingRight: '50%',
  },
  customDialogBody: {
    paddingLeft: 23,
    paddingRight: 23,
    width: '100%',
  },

  customDialogCloseButton: {
    background: 'transparent',
    '& i': {
      fontSize: theme.fonts.fontSizes.xLarge,
    },
  },

  customDialogCloseButtonNoTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '23px 15px 17px 23px',
  },

  customDialogContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },

  customDialogFailIcon: {
    backgroundColor: theme.palette.dangerText,
  },

  customDialogFooter: {
    backgroundColor: theme.palette.backgroundCommon,
    display: 'flex',
    marginTop: 24,
    padding: '8px 23px 8px 23px',
  },

  customDialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '23px 15px 17px 23px',
  },

  customDialogIcon: {
    color: 'white',
    paddingTop: 15,
  },

  customDialogIconContainer: {
    borderRadius: '50%',
    height: 50,
    textAlign: 'Center',
    width: 50,
  },

  customDialogProcessingIcon: {
    backgroundColor: theme.palette.warningActive,
  },

  customDialogSuccessIcon: {
    backgroundColor: theme.palette.approveText,
  },
  iconContainerForDialog: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
    height: 50,
    width: 50,
  },
  iconForDialog: {
    fontSize: 24,
  },
  link: {
    color: theme.palette.textLink,
    '&:active, &:hover:active': {
      color: theme.palette.activeLink,
    },
    '&:visited, &:hover, &:focus': {
      color: theme.palette.textLinkHover,
    },
    '&.is-disabled': {
      color: theme.palette.textDisabled,
    },
  },
  textOverflow: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});
