import { Spinner, TextBody } from 'components/ions';
import { ITextField, SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { KeyCodes } from '@uifabric/utilities';

import { Suggestion } from './Autosuggest';
import { AutosuggestListRow } from './AutosuggestListRow';

const autoSuggestListStyles = (theme: ThemeProps) => {
  return {
    indicator: {
      height: 60,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loading: {
      paddingTop: 5,
    },
    loadingTextColor: {
      color: theme.palette.textSecondary,
    },
  };
};

export interface AutosuggestListProps<T> {
  refToFirstChild: React.RefObject<HTMLButtonElement>;
  refToLastChild: React.RefObject<HTMLButtonElement>;
  listFooter?: JSX.Element;
  notFound?: JSX.Element;
  textboxRef: React.RefObject<ITextField>;
  suggestions: Suggestion<T>[];
  setSelectedIndex: (index: number) => void;
  selectedIndex: number;
  strings: { loading: string; notFound: string; listAriaLabel: string };
  onSelect: (suggestion: Suggestion<T>) => void;
  onRenderRow: (suggestion: Suggestion<T>) => JSX.Element;
  isLoading?: boolean;
  error?: JSX.Element;
}

type Props<T> = AutosuggestListProps<T> & WithStyles<typeof autoSuggestListStyles>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AutosuggestListUnstyled = <T extends any>(props: Props<T>) => {
  const listContainerRef = React.useRef<HTMLDivElement>(null);
  const onClickHandler = (index: number) => {
    const selected = props.suggestions[index];
    props.onSelect(selected);
  };

  const focusOnTextBox = (textboxRef: React.RefObject<ITextField>) => {
    if (textboxRef.current) {
      const length = textboxRef.current.value && textboxRef.current.value.length;
      textboxRef.current.focus();
      length && textboxRef.current.setSelectionRange(length, length);
    }
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLElement;
    const lastIndex = props.suggestions.length - 1;
    switch (e.keyCode) {
      case KeyCodes.up:
        e.preventDefault();
        const previousSibling = target.previousElementSibling as HTMLElement;
        if (previousSibling) {
          previousSibling.focus();
          props.setSelectedIndex(props.selectedIndex - 1);
        } else {
          focusOnTextBox(props.textboxRef);
        }
        break;
      case KeyCodes.down:
        e.preventDefault();
        const nextSibling = target.nextElementSibling as HTMLElement;
        if (nextSibling && props.selectedIndex !== lastIndex) {
          props.setSelectedIndex(props.selectedIndex + 1);
          nextSibling.focus();
        } else {
          if (listContainerRef.current) {
            listContainerRef.current.scrollIntoView(false);
          }
          focusOnTextBox(props.textboxRef);
        }

        break;
      case KeyCodes.enter:
        onClickHandler(props.selectedIndex);
        break;
    }
  };

  const loading = (
    <div className={props.classes.indicator} data-testid="loading">
      <Spinner size={SpinnerSize.medium} />
      <div className={props.classes.loading}>
        <TextBody addClass={props.classes.loadingTextColor}>{props.strings.loading}</TextBody>
      </div>
    </div>
  );

  const notFound = props.notFound || (
    <div className={props.classes.indicator} data-testid="notfound">
      <TextBody>{props.strings.notFound}</TextBody>
    </div>
  );
  const firstIndex = 0;
  const lastIndex = props.suggestions.length - 1;
  let items = props.suggestions.map((suggestion, index: number) => {
    const tabIndex = props.selectedIndex === index ? 0 : -1;
    let componentRef;
    if (index === firstIndex) {
      componentRef = props.refToFirstChild;
    } else if (index === lastIndex) {
      componentRef = props.refToLastChild;
    }
    return (
      <AutosuggestListRow
        componentRef={componentRef}
        dataAutomationId={'autoSuggestListRow-' + index}
        index={index}
        key={suggestion.key}
        suggestion={suggestion}
        tabIndex={tabIndex}
        onClickHandler={onClickHandler}
        onKeyDown={onKeyDown}
        onRenderRow={props.onRenderRow}
      />
    );
  });

  if (props.listFooter) {
    items = [
      ...items,
      <React.Fragment key="_Autosuggest_React_Component_Footer">{props.listFooter}</React.Fragment>,
    ];
  }
  let toRender: JSX.Element[] | JSX.Element = items;
  if (props.isLoading) {
    toRender = loading;
  } else if (props.error) {
    toRender = props.error;
  } else if (props.suggestions.length === 0) {
    toRender = notFound;
  }

  return (
    <div aria-label={props.strings.listAriaLabel} ref={listContainerRef}>
      {toRender}
    </div>
  );
};

export const AutosuggestList = withStyles(autoSuggestListStyles)(AutosuggestListUnstyled) as <T>(
  props: AutosuggestListProps<T>
) => JSX.Element;
