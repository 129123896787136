import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
} from 'components';
import { Fail, Processing } from 'features/components/dialogs';
import { processingAddSharedDiscounts } from 'features/proposal/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { DialogContext, ThemeProps } from 'styles';

import { Success } from '../Shared';
import { sharedStyles } from '../shared.styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  semiBoldFont: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  marginTop14: {
    marginTop: 14,
  },
});

const dialogDimensions = {
  width: 550,
  height: 305,
};

enum SharedDiscountsSteps {
  Overview,
  Processing,
}

interface SharedDiscountsDialogProps {
  parentAccountName?: string;
  affiliateAccountName: string;
  extendDiscounts: () => void;
}

const mapStateToProps = (state: RootState) => ({
  processingDiscounts: processingAddSharedDiscounts(state),
});

type Props = SharedDiscountsDialogProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof styles>;

const ShardedDiscountsDialogUnstyled: React.FC<Props> = props => {
  const {
    classes,
    parentAccountName,
    affiliateAccountName,
    extendDiscounts,
    processingDiscounts,
  } = props;
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = React.useState<SharedDiscountsSteps>(
    SharedDiscountsSteps.Overview
  );
  const context = React.useContext(DialogContext);
  const closeDialog = () => context.closeDialog();

  const handleExtendDiscounts = () => {
    extendDiscounts();
    setCurrentStep(SharedDiscountsSteps.Processing);
  };

  const Header = (
    <DialogHeader
      closeButtonClass={classes.closeButton}
      dataAutomationId="sharedDiscountsDialog"
      dialogClose={closeDialog}
      headerClass={classes.header}
    >
      <TextTitle>{t('quote::Shared discounts')}</TextTitle>
    </DialogHeader>
  );

  const Body = (
    <div className={classes.body}>
      <div>
        <TextBody>
          {parentAccountName
            ? t(
                'quote::The parent account, {{parentAccountName}}, has previously negotiated discounts. Consult with their Microsoft account team to understand if these discounts should be given to the affiliate account, {{affiliateAccountName}}.',
                { parentAccountName, affiliateAccountName }
              )
            : t(
                'quote::The parent account has previously negotiated discounts. Consult with their Microsoft account team to understand if these discounts should be given to the affiliate account, {{affiliateAccountName}}.',
                { affiliateAccountName }
              )}
        </TextBody>
      </div>
      <div className={classes.marginTop14}>
        <TextBody>
          {t(
            'quote::To extend the discounts to {{affiliateAccountName}}, you must add them to the quote.',
            { affiliateAccountName }
          )}
        </TextBody>
      </div>
    </div>
  );

  const Footer = (
    <div className={`${classes.footer} ${classes.buttonRightAlign}`}>
      <PrimaryButton
        dataAutomationId="shareDiscountsDialogButton"
        text={t('quote::Add discounts')}
        onClick={handleExtendDiscounts}
      />
      <div className={classes.secondButton}>
        <SecondaryButton
          dataAutomationId="shareDiscountsDialogDismissButton"
          text={t('quote::Not now')}
          onClick={context.closeDialog}
        />
      </div>
    </div>
  );

  switch (currentStep) {
    case SharedDiscountsSteps.Processing: {
      if (processingDiscounts.loading) {
        return <Processing {...dialogDimensions} key="Processing" />;
      } else if (processingDiscounts.error) {
        return (
          <Fail
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="addSharedDiscountsFail"
            message={t(
              `quote::Sorry, we couldn't add the discounts to the quote. Try again later using the Add Shared Discounts button on the quote page.`
            )}
          />
        );
      } else {
        return (
          <Success
            message={t('quote::Discounts have been successfully added to the quote.')}
            {...dialogDimensions}
            closeDialog={closeDialog}
            dataAutomationId="addSharedDiscountsSuccess"
          />
        );
      }
    }
    case SharedDiscountsSteps.Overview:
    default: {
      return (
        <CustomDialogBox
          {...dialogDimensions}
          bodySlot={Body}
          footerSlot={Footer}
          headerSlot={Header}
        />
      );
    }
  }
};

export const SharedDiscountsDialog = connect(mapStateToProps)(
  withStyles(styles)(ShardedDiscountsDialogUnstyled)
);
