import { ThemeProps } from 'styles';

export const proposalListHeaderStyles = (theme: ThemeProps) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.backgroundCommon,
      borderBottom: `2px solid ${theme.palette.backgroundDivider}`,
      paddingLeft: 8,
      paddingRight: 8,
    },
  };
};
