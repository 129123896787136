import { QuoteLineItem } from 'features-apollo/quote/components';
import { formatTermDuration } from 'features-apollo/quote/components/DetailsPane/utils';
import { DiscountCardState } from 'features-apollo/quote/components/DiscountCard';
import { getValueOrUndefined } from 'features-apollo/quote/components/utils';
import {
  Condition,
  DateOrDuration,
  DatePrecision,
  DiscountLineItem,
  DiscountType,
  KeyNullableValues,
  PurchaseLineItem,
} from 'generated/graphql';
import i18next from 'i18n';
import { IChoiceGroupOption, IComboBoxOption } from 'office-ui-fabric-react';
import { PriceAdjustmentType } from 'services/proposal/types';
import { oc } from 'ts-optchain';

import {
  CeilingDateOptions,
  DiscountCardDateRanges,
  DiscountCardDropdownOptions,
  FutureDateOptions,
} from './types';

export const isDiscountableLineItem = (item: any): item is PurchaseLineItem | DiscountLineItem =>
  item.applicableDiscounts &&
  item.applicableDiscounts.types &&
  item.applicableDiscounts.types !== null &&
  item.applicableDiscounts.types.length;

export const getSelectedDiscountableLineItems = (
  lineItems?: QuoteLineItem[],
  selectedIds?: string[]
) => {
  const results: (PurchaseLineItem | DiscountLineItem)[] = [];
  if (selectedIds && selectedIds.length && lineItems && lineItems.length) {
    selectedIds.forEach((id: string) => {
      const found: any = lineItems.find(
        (item: QuoteLineItem) => item.id === id && isDiscountableLineItem(item)
      );
      if (found && oc(found).applicableDiscounts.types([]).length) {
        results.push(found);
      }
    });
  }
  return results;
};

export const translateDiscountOptionText = (type: DiscountType) => {
  let result = '';
  switch (type) {
    case DiscountType.Future:
      result = i18next.t('quote::Future');
      break;
    case DiscountType.PriceGuarantee:
      result = i18next.t('quote::Ceiling');
      break;
    case DiscountType.OneTime:
      result = i18next.t('quote::One Time');
      break;
    default:
      result = type;
      break;
  }
  return result;
};

export const getDiscountMeterOptions = (selectedItem: DiscountLineItem | PurchaseLineItem) => {
  const results: IComboBoxOption[] = [];
  const sku = getValueOrUndefined(selectedItem.sku);

  if (sku && sku.filters && sku.filters !== null) {
    sku.filters.forEach((filter: KeyNullableValues) => {
      if (filter.key.toLowerCase() === 'metertype') {
        filter.values.forEach(meter => {
          if (meter !== null && !!meter.trim()) {
            results.push({ key: meter, text: meter });
          }
        });
      }
    });
  }
  if (results.length > 1) {
    results.unshift({ key: 'all', text: 'All' });
  }
  return results;
};

export const getDiscountOptions = (selectedItems?: (DiscountLineItem | PurchaseLineItem)[]) => {
  const results: IChoiceGroupOption[] = [];
  if (!selectedItems || (selectedItems && !selectedItems.length)) {
    return results;
  }

  if (selectedItems.length > 1) {
    // Bulk Discount -Future Only
    results.push({
      key: DiscountType.Future,
      text: translateDiscountOptionText(DiscountType.Future),
    });
  } else {
    const lineItem = selectedItems[0];
    const applicableDiscounts = oc(lineItem).applicableDiscounts.types([]);

    if (applicableDiscounts.length) {
      applicableDiscounts.forEach(discountType => {
        results.push({
          key: discountType,
          text: translateDiscountOptionText(discountType),
        });
      });
    }
  }

  return results;
};

const getDefaultSelectedDiscountType = (options: IChoiceGroupOption[]) => {
  if (!options.length) {
    return DiscountType.Future;
  }
  // return future option if applicable else take first applicable
  return options.find(option => option.key === DiscountType.Future)
    ? DiscountType.Future
    : options[0].key;
};

export const isValidDiscountType = (key?: string) => {
  if (key && key in DiscountType) {
    return true;
  }
  return false;
};

export const discountAmountErrors = {
  smallerThanMin: i18next.t('error::Has to be greater than 0'),
  ceilingSmallerThanMin: i18next.t('error::Cannot be negative'),
  valueIsNotANumber: i18next.t('error::Only numbers are valid'),
  largerThanMax: i18next.t('error::Cannot be greater than 100'),
  isEmpty: i18next.t('error::Cannot be empty'),
};

const hasNonNumbers = (discountAmount: string, isBulkDiscount?: boolean) => {
  if (!isBulkDiscount && isNaN(+discountAmount)) {
    return discountAmountErrors.valueIsNotANumber;
  }
};

const isEmpty = (discountAmount: string) => {
  if (!discountAmount.trim()) {
    return discountAmountErrors.isEmpty;
  }
};

const largerThanMax = (discountAmount: string) => {
  if (+discountAmount > 100) {
    return discountAmountErrors.largerThanMax;
  }
};

const smallerThanMin = (discountAmount: string, discountType: string) => {
  if (
    (discountType.toLowerCase() === 'onetime' || discountType.toLowerCase() === 'future') &&
    +discountAmount <= 0
  ) {
    return discountAmountErrors.smallerThanMin;
  } else if (+discountAmount < 0) {
    return discountAmountErrors.ceilingSmallerThanMin;
  }
};

const hasScientificNotationSymbol = (discountAmount: string) => {
  if (discountAmount.toLowerCase().includes('e')) {
    return discountAmountErrors.valueIsNotANumber;
  }
};

export const validateDiscountAmount = (
  discountAmount: string,
  discountType: string,
  isBulkDiscount?: boolean,
  isBulkUnconfigured?: boolean
): string | undefined => {
  if (isBulkDiscount && !isBulkUnconfigured && discountAmount.toLowerCase() === '(mixed)') {
    return undefined;
  }

  return (
    isEmpty(discountAmount) ||
    hasNonNumbers(discountAmount) ||
    hasScientificNotationSymbol(discountAmount) ||
    smallerThanMin(discountAmount, discountType) ||
    largerThanMax(discountAmount)
  );
};

export const limitDecimalPoint = (value: string) => {
  const matches = /(.*?[0-9]*\.[0-9]{0,2})/.exec(value);
  if (matches && matches.length >= 1) {
    return matches[1];
  }
  return value;
};

const monthsOfYearChoices = [];
for (let i = 1; i <= 11; i++) {
  const suffix = i === 1 ? 'month' : 'months';
  const month = `${i} ${suffix}`;
  monthsOfYearChoices.push({ text: month, key: `P${i}M` });
}

export const mixedValues = { text: '(mixed)', key: 'mixed' };

export const endDurations = [
  ...monthsOfYearChoices,
  { text: '1 year', key: 'P12M' },
  { text: '18 months', key: 'P18M' },
  { text: '2 years', key: 'P24M' },
  { text: '3 years', key: 'P36M' },
];

export const endOptions: DiscountCardDropdownOptions = {
  options: endDurations,
  selectedKey: 'P18M',
};

export const defaultPriceCeilingOptions: DiscountCardDropdownOptions = {
  options: [{ text: 'At order placement', key: 'At order placement' }],
  selectedKey: 'At order placement',
};

export const generateStartOptions = (dayPicker: boolean): DiscountCardDropdownOptions => {
  return {
    options: dayPicker
      ? [{ text: 'At order placement', key: 'order placement' }]
      : [{ text: 'Acceptance month', key: 'order placement' }],
    selectedKey: 'order placement',
  };
};

export const monthDayYearFormat = (date: Date) => {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/');
};

export const isoFormatDate = (date: Date) => {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const createDateFromKey = (dateString: string) => {
  return new Date(dateString.replace(/-/g, '/'));
};

export const defaultFutureMeterOptions = {
  options: [{ text: 'Meter 1', key: 'Meter 1' }],
  selectedKey: 'Meter 1',
};

export const isValidDate = (value: string) => {
  const date = new Date(value);
  return date instanceof Date && !isNaN(date.valueOf());
};

export const addDropdownItem = (
  key: string,
  text: string,
  dropdownOptions: DiscountCardDropdownOptions
) => {
  const itemToAdd: IComboBoxOption = { text, key };
  if (key === 'mixed') {
    itemToAdd.disabled = true;
  }
  const newOptions = dropdownOptions.options.concat(itemToAdd);

  return newOptions;
};

export const addAndSelectKey = (
  key: string,
  text: string,
  dropdownOptions: DiscountCardDropdownOptions
) => {
  const alreadyExists: boolean = dropdownOptions.options.some(
    (option: IComboBoxOption) => option.key === key
  );
  const newOptions = alreadyExists
    ? dropdownOptions.options
    : addDropdownItem(key, text, dropdownOptions);

  return { ...dropdownOptions, options: newOptions, selectedKey: key };
};

export const addAndSelectDate = (date: Date, dropdownOptions: DiscountCardDropdownOptions) => {
  const formattedDate = isoFormatDate(date);
  return addAndSelectKey(formattedDate, formattedDate, dropdownOptions);
};

export const selectOption = (
  key: string,
  options: IComboBoxOption[]
): DiscountCardDropdownOptions => ({
  options,
  selectedKey: key,
});

export const getDefaultDateOptions = (dayPicker: boolean, minDate: Date) => {
  const startOptions = generateStartOptions(dayPicker);
  let maxDate = new Date(minDate.getTime());
  maxDate.setMonth(maxDate.getMonth() + 3);

  const dateRanges = {
    startDate: {
      minDate,
      maxDate,
      selected: minDate,
    },
    endDate: {
      minDate,
      selected: maxDate,
    },
    ceilingDate: {
      minDate,
      maxDate,
      selected: minDate,
    },
  };

  const futureOptions = {
    startOptions: startOptions,
    dateRanges,
    endOptions: endOptions,
    useDayPicker: dayPicker,
    showMeters: false,
  } as FutureDateOptions;

  const ceilingOptions = {
    ...futureOptions,
    dateRanges,
    priceCeilingOptions: defaultPriceCeilingOptions,
    useDayPicker: dayPicker,
  } as CeilingDateOptions;

  return {
    ceilingOptions: ceilingOptions,
    futureOptions: futureOptions,
  };
};

export const getInitialDiscountState = (selectedItems: (DiscountLineItem | PurchaseLineItem)[]) => {
  const lineItem = selectedItems[0];
  const applicableOptions = getDiscountOptions(lineItem ? [lineItem] : []);

  const useDayPicker =
    oc(lineItem).applicableDiscounts.datePrecision(DatePrecision.Day) === DatePrecision.Day;
  const minStartDate = oc(lineItem).applicableDiscounts.minStartDate(new Date());

  const defaultDateOptions = getDefaultDateOptions(useDayPicker, minStartDate);

  const initial = {
    discountAmount: '0',
    discountType: getDefaultSelectedDiscountType(applicableOptions),
    futureOptions: defaultDateOptions.futureOptions,
    ceilingOptions: defaultDateOptions.ceilingOptions,
    errorMessage: undefined,
    isBulkDiscount: false,
    isBulkUnconfigured: false,
    enableRemoveDiscount: false,
    showMixedValuesWaterMark: false,
    amountError: undefined,
    meters: undefined,
  } as DiscountCardState;

  if (!selectedItems || (selectedItems && !selectedItems.length)) {
    return initial;
  }

  let anyStartDatesConfigured = false;
  let anyEndDatesConfigured = false;
  let anyDiscountAmount = false;

  if (selectedItems.length > 1) {
    //Bulk Discount
    initial.isBulkDiscount = true;
    selectedItems.forEach(item => {
      if (item && item.__typename && item.__typename === 'DiscountLineItem') {
        initial.enableRemoveDiscount = lineItem.isReadyForPricing;

        if (item && item.discount) {
          const discountDetails = item.discount;

          if (discountDetails.duration && discountDetails.duration.__typename === 'StartEndDates') {
            if (discountDetails.duration.startDate) {
              anyStartDatesConfigured = true;
              initial.showMixedValuesWaterMark = true;
            }
            if (discountDetails.duration.endDate) {
              anyEndDatesConfigured = true;
              initial.showMixedValuesWaterMark = true;
            }
          } else if (
            discountDetails.duration &&
            discountDetails.duration.__typename === 'Duration'
          ) {
            anyEndDatesConfigured = true;
            anyStartDatesConfigured = true;
            initial.showMixedValuesWaterMark = true;
          }

          if (discountDetails.percentage) {
            anyDiscountAmount = true;
          }
        }
      } else {
        initial.isBulkUnconfigured = true;
      }
    });

    //If any values are configured starting values get set to mixed if no unconfigured line items are present
    if (initial.showMixedValuesWaterMark && !initial.isBulkUnconfigured) {
      let newFutureOptions = initial.futureOptions;
      if (anyDiscountAmount) {
        initial.discountAmount = '(mixed)';
      }
      if (anyStartDatesConfigured) {
        newFutureOptions.startOptions = addAndSelectKey(
          mixedValues.key,
          mixedValues.text,
          newFutureOptions.startOptions
        );
        newFutureOptions.endEnabled = true;
      }

      if (anyEndDatesConfigured) {
        newFutureOptions.endOptions = addAndSelectKey(
          mixedValues.key,
          mixedValues.text,
          newFutureOptions.endOptions
        );
      }

      initial.futureOptions = newFutureOptions;
    }
  } else {
    const lineItem = selectedItems[0];

    const meterOptions = getDiscountMeterOptions(lineItem);
    if (meterOptions.length) {
      initial.meters = {
        options: meterOptions,
        selectedKey: meterOptions[0].key.toString(),
      };
      initial.futureOptions.showMeters = meterOptions.length > 1;
    }

    if (lineItem && lineItem.__typename && lineItem.__typename === 'DiscountLineItem') {
      initial.enableRemoveDiscount = lineItem.isReadyForPricing;
      const discount = oc(lineItem).discount();

      if (discount) {
        let startOptions = defaultDateOptions.ceilingOptions.startOptions;
        let endOptions = defaultDateOptions.futureOptions.endOptions;

        const duration = oc(lineItem).discount.duration();
        initial.discountAmount = oc(discount)
          .percentage(0)
          .toString();
        initial.discountType =
          discount.type && discount.type !== DiscountType.Unconfigured
            ? discount.type
            : DiscountType.Future;

        if (
          duration &&
          duration.__typename === 'StartEndDates' &&
          duration.startDate &&
          duration.endDate
        ) {
          startOptions = addAndSelectDate(new Date(duration.startDate), startOptions);
          endOptions = addAndSelectDate(new Date(duration.endDate), endOptions);
        } else if (duration && duration.__typename === 'Duration' && duration.duration) {
          endOptions = selectOption(duration.duration, endOptions.options);
        }

        if (discount.type === DiscountType.Future) {
          initial.futureOptions.startOptions = startOptions;
          initial.futureOptions.endOptions = endOptions;
        } else if (discount.type === DiscountType.PriceGuarantee) {
          initial.ceilingOptions.startOptions = startOptions;
          initial.ceilingOptions.endOptions = endOptions;
          const ceilingDate = oc(lineItem).discount.priceGuaranteeDate('');
          if (ceilingDate && isValidDate(ceilingDate)) {
            initial.ceilingOptions.priceCeilingOptions = addAndSelectDate(
              new Date(ceilingDate),
              initial.ceilingOptions.priceCeilingOptions
            );
          }
        }

        if (meterOptions.length && discount.scope && discount.scope !== null) {
          const meterType = discount.scope.find(
            (scope: Condition) => scope.name.toLowerCase() === 'metertype'
          );
          if (meterType && meterType.name.toLowerCase() === 'metertype' && initial.meters) {
            initial.meters.selectedKey = meterType.values.length ? meterType.values[0] : 'all';
          }
        }
      }
    } else if (
      lineItem &&
      lineItem.__typename &&
      lineItem.__typename === 'PurchaseLineItem' &&
      oc(lineItem).oneTimeDiscount(0) > 0
    ) {
      initial.discountAmount = oc(lineItem)
        .oneTimeDiscount(0)
        .toString();
      initial.discountType = DiscountType.OneTime;
      initial.enableRemoveDiscount = true;
    }
  }

  return initial;
};

export const getFirstDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const resetStartDate = (startDateOptions: DiscountCardDropdownOptions) => {
  return selectOption('order placement', startDateOptions.options);
};

export const resetEndDate = (endDateOptions: DiscountCardDropdownOptions) =>
  selectOption('P18M', endDateOptions.options);

export const removeAllDates = (
  dropdownOptions: DiscountCardDropdownOptions
): DiscountCardDropdownOptions => {
  const removedOptions = dropdownOptions.options.filter(
    (option: IComboBoxOption) => typeof option.key === 'string' && !isValidDate(option.key)
  );
  return { options: removedOptions, selectedKey: dropdownOptions.selectedKey };
};

const isDuration = (value: string): boolean => !!(value.match(/P(\d+)M/) && value.match.length);

export const adjustEndDate = (
  startDate: Date,
  endDateDropdownOptions: DiscountCardDropdownOptions,
  isMonth: boolean
) => {
  const currentEnd = endDateDropdownOptions.selectedKey;
  const selectDuration = i18next.t('quote::Select a duration');

  if (isDuration(currentEnd)) {
    const months = currentEnd.match(/\d+/);
    if (months && months.length) {
      let newEnd = new Date(startDate.getTime());
      newEnd.setMonth(newEnd.getMonth() + +months[0]);
      if (isMonth) {
        newEnd.setMonth(newEnd.getMonth() - 1);
        newEnd = getLastDayOfMonth(newEnd);
      }
      return addAndSelectDate(newEnd, endDateDropdownOptions);
    }
    return addAndSelectKey(selectDuration, selectDuration, endDateDropdownOptions);
  }
  if (isValidDate(currentEnd)) {
    const compareDate = new Date(currentEnd);
    if (startDate >= compareDate) {
      return addAndSelectKey(selectDuration, selectDuration, endDateDropdownOptions);
    }
  }
  return endDateDropdownOptions;
};

export const disableInvalidOptions = (
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions,
  dateRanges: DiscountCardDateRanges
) => {
  if (isValidDate(startOptions.selectedKey)) {
    const start = createDateFromKey(startOptions.selectedKey);
    const selectedEnd = isValidDate(endOptions.selectedKey)
      ? createDateFromKey(endOptions.selectedKey)
      : new Date();
    let newRanges = {
      ...dateRanges,
      endDate: { ...dateRanges.endDate, minDate: start, selected: selectedEnd },
    };

    const disabledEndOptions = endOptions.options.map((option: IComboBoxOption) => {
      if (isValidDate(option.key.toString())) {
        const optionDate = createDateFromKey(option.key.toString());
        if (optionDate < start) {
          return { ...option, disabled: true };
        }
      }
      return option;
    });

    return {
      newStartOptions: startOptions,
      newEndOptions: { options: disabledEndOptions, selectedKey: endOptions.selectedKey },
      newRanges,
    };
  }

  const newEndOptions = removeAllDates(endOptions);
  const newRanges = {
    ...dateRanges,
    endDate: { ...dateRanges.endDate, minDate: dateRanges.startDate.minDate },
  };
  return { newStartOptions: startOptions, newEndOptions, newRanges };
};

export const addPriceCeilingDate = (
  selectedDate: Date,
  priceCeilingOptions: DiscountCardDropdownOptions,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions
) => {
  if (isValidDate(startOptions.selectedKey)) {
    const startDate = createDateFromKey(startOptions.selectedKey);
    if (selectedDate > startDate && startDate.getMonth() !== selectedDate.getMonth()) {
      return {
        newPriceCeilingOptions: addAndSelectDate(selectedDate, priceCeilingOptions),
        newStartOptions: resetStartDate(startOptions),
        newEndOptions: resetEndDate(endOptions),
      };
    }
  }

  return {
    newPriceCeilingOptions: addAndSelectDate(selectedDate, priceCeilingOptions),
    newStartOptions: startOptions,
    newEndOptions: endOptions,
  };
};

export const priceCeilingSelect = (
  selected: string,
  priceCeilingOptions: DiscountCardDropdownOptions,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions
) => {
  if (!isValidDate(selected)) {
    const newPriceCeilingOptions = selectOption(selected, priceCeilingOptions.options);
    const newStartOptions = resetStartDate(startOptions);
    const newEndOptions = resetEndDate(endOptions);

    return {
      newPriceCeilingOptions,
      newEndOptions,
      newStartOptions,
    };
  }

  const priceCeilingDate = createDateFromKey(selected);
  const startDate = createDateFromKey(startOptions.selectedKey);
  const newPriceCeilingOptions = selectOption(selected, priceCeilingOptions.options);

  if (priceCeilingDate > startDate && startDate.getMonth() !== priceCeilingDate.getMonth()) {
    return {
      newPriceCeilingOptions,
      newStartOptions: resetStartDate(startOptions),
      newEndOptions: resetEndDate(endOptions),
    };
  }

  return {
    newPriceCeilingOptions,
    newStartOptions: startOptions,
    newEndOptions: endOptions,
  };
};

export const addStartDate = (
  selectedDate: Date,
  useDayPicker: boolean,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions,
  dateRanges: DiscountCardDateRanges
) => {
  const newStart = useDayPicker ? selectedDate : getFirstDayOfMonth(selectedDate);
  const newStartOptions = addAndSelectDate(newStart, startOptions);
  let newEndOptions = adjustEndDate(newStart, endOptions, !useDayPicker);
  if (endOptions.selectedKey === 'mixed') {
    newEndOptions = resetEndDate(endOptions);
  }

  const results = disableInvalidOptions(newStartOptions, newEndOptions, dateRanges);

  return {
    newStartOptions: results.newStartOptions,
    newEndOptions: results.newEndOptions,
    newRanges: results.newRanges,
  };
};

export const startSelected = (
  selected: string,
  useDayPicker: boolean,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions,
  dateRanges: DiscountCardDateRanges
) => {
  if (!isValidDate(selected)) {
    const newStartOptions = selectOption(selected, startOptions.options);
    const newEndOptions = resetEndDate(endOptions);
    return { newStartOptions, newEndOptions };
  }
  const newStart = useDayPicker
    ? createDateFromKey(selected)
    : getFirstDayOfMonth(createDateFromKey(selected));
  const newEndOptions = adjustEndDate(newStart, endOptions, !useDayPicker);
  const newStartOptions = selectOption(isoFormatDate(newStart), startOptions.options);
  const results = disableInvalidOptions(newStartOptions, newEndOptions, dateRanges);
  return {
    newStartOptions: results.newStartOptions,
    newEndOptions: results.newEndOptions,
    newRanges: results.newRanges,
  };
};

const adjustEndRangeSelected = (
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions,
  dateRanges: DiscountCardDateRanges,
  useDayPicker: boolean
) => {
  if (isValidDate(endOptions.selectedKey)) {
    return createDateFromKey(endOptions.selectedKey);
  } else {
    return isValidDate(startOptions.selectedKey)
      ? createDateFromKey(
          adjustEndDate(new Date(startOptions.selectedKey), endOptions, !useDayPicker).selectedKey
        )
      : dateRanges.endDate.selected;
  }
};

export const addEndDate = (
  selectedDate: Date,
  useDayPicker: boolean,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions,
  dateRanges: DiscountCardDateRanges
) => {
  const newEnd = useDayPicker ? selectedDate : getLastDayOfMonth(selectedDate);
  const newEndOptions = addAndSelectDate(newEnd, endOptions);
  const newRanges = dateRanges;
  newRanges.endDate.selected = adjustEndRangeSelected(
    startOptions,
    endOptions,
    dateRanges,
    useDayPicker
  );
  return { newStartOptions: startOptions, newEndOptions, newRanges };
};

export const endSelected = (
  selected: string,
  useDayPicker: boolean,
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions,
  dateRanges: DiscountCardDateRanges
) => {
  const newRanges = dateRanges;
  if (isValidDate(selected)) {
    const formatted = isoFormatDate(createDateFromKey(selected));
    const newEndOptions = selectOption(formatted, endOptions.options);
    return { newStartOptions: startOptions, newEndOptions };
  } else if (!isValidDate(selected) && !isValidDate(startOptions.selectedKey)) {
    const newEndOptions = selectOption(selected, endOptions.options);
    newRanges.endDate.selected = adjustEndRangeSelected(
      startOptions,
      newEndOptions,
      dateRanges,
      useDayPicker
    );
    return { newStartOptions: startOptions, newEndOptions, newRanges };
  }

  let newEndOptions = selectOption(selected, endOptions.options);
  newEndOptions = adjustEndDate(
    createDateFromKey(startOptions.selectedKey),
    newEndOptions,
    !useDayPicker
  );

  newRanges.endDate.selected = adjustEndRangeSelected(
    startOptions,
    newEndOptions,
    dateRanges,
    useDayPicker
  );
  return { newStartOptions: startOptions, newEndOptions, newRanges };
};

export const isApplyButtonDisabled = (
  discountAmount: string,
  selectedFutureEndDate: string,
  selectedCeilingEndDate: string,
  selectADuration: string,
  isProposalReadOnly: boolean,
  discountType?: string,
  isBulkDiscount?: boolean
) => {
  if (
    !discountType ||
    isProposalReadOnly ||
    (discountType && discountType === DiscountType.Unconfigured)
  ) {
    return true;
  }
  const invalidDiscount = !!validateDiscountAmount(discountAmount, discountType, isBulkDiscount);

  if (discountType === 'OneTime') {
    return invalidDiscount;
  } else if (discountType === DiscountType.PriceGuarantee) {
    return selectedCeilingEndDate === selectADuration || invalidDiscount;
  } else {
    return selectedFutureEndDate === selectADuration || invalidDiscount;
  }
};

export const getDateOrDuration = (startDate: string, endDate: string) => {
  if (startDate.toLowerCase() === 'order placement' || !isValidDate(endDate)) {
    return {
      duration: endDate,
    };
  }
  return {
    startDate: createDateFromKey(startDate),
    endDate: createDateFromKey(endDate),
  };
};

export const getDiscountInput = (state: DiscountCardState) => {
  const ceilingDate =
    state.ceilingOptions.priceCeilingOptions.selectedKey &&
    isValidDate(state.ceilingOptions.priceCeilingOptions.selectedKey)
      ? createDateFromKey(state.ceilingOptions.priceCeilingOptions.selectedKey)
      : null;
  const input = {
    percentage: +state.discountAmount,
    type: state.discountType,
    priceAdjustmentType: PriceAdjustmentType.new,
    /** We don't want to put conditions on the meters without
       explicit action from user, and if we dont show the dropdown
       they can't make an explicit action, so we do not put conditions
       Basically: 
       If user picks 'all' option -> no limitation/conditions on the meter, set undefined
       else if user picks specific meter option -> limit the condition to that specific picked one, set to that specific one
       otherwise that means user did not explicitly picked anything, and we do not show the dropdown -> no limitation/conditions on the meter, set undefined
    **/
    additionalConditions:
      state.meters &&
      state.meters.selectedKey &&
      state.meters.selectedKey !== 'all' &&
      state.futureOptions.showMeters
        ? [
            {
              name: 'MeterType',
              type: 'equalAny',
              values: [state.meters.selectedKey],
            },
          ]
        : undefined,
  };
  switch (state.discountType) {
    case 'Future':
      return {
        ...input,
        duration: getDateOrDuration(
          state.futureOptions.startOptions.selectedKey,
          state.futureOptions.endOptions.selectedKey
        ),
      };
    case DiscountType.PriceGuarantee:
      return {
        ...input,
        priceGuaranteeDate: ceilingDate,
        duration: getDateOrDuration(
          state.ceilingOptions.startOptions.selectedKey,
          state.ceilingOptions.endOptions.selectedKey
        ),
      };
    default:
      return {
        ...input,
        type: DiscountType.OneTime,
      };
  }
};

export const getBulkInput = (state: DiscountCardState) => {
  return {
    type: DiscountType.Future,
    priceAdjustmentType: PriceAdjustmentType.new,
    percentage:
      state.discountAmount && state.discountAmount.toLowerCase().includes('(mixed)')
        ? undefined
        : +state.discountAmount,
    duration:
      state.futureOptions.startOptions.selectedKey &&
      state.futureOptions.startOptions.selectedKey.toLowerCase().includes('mixed')
        ? undefined
        : getDateOrDuration(
            state.futureOptions.startOptions.selectedKey,
            state.futureOptions.endOptions.selectedKey
          ),
  };
};

export const getRemoveDiscountInput = (state: DiscountCardState) => ({
  percentage: 0,
  type:
    state.discountType === DiscountType.OneTime ? DiscountType.OneTime : DiscountType.Unconfigured,
  priceAdjustmentType: PriceAdjustmentType.new,
});

export const isDiscountConfigured = (lineItem: QuoteLineItem) => {
  if (!isDiscountableLineItem(lineItem)) {
    return;
  }

  if (lineItem.__typename === 'PurchaseLineItem') {
    return (
      lineItem.oneTimeDiscount && lineItem.oneTimeDiscount !== null && lineItem.oneTimeDiscount > 0
    );
  } else if (lineItem.__typename === 'DiscountLineItem') {
    return lineItem.discount && lineItem.discount.duration && lineItem.discount.duration !== null;
  }
};

export const getDiscountSummaryShort = (lineItem: QuoteLineItem) => {
  if (isDiscountConfigured(lineItem)) {
    if (lineItem.__typename === 'DiscountLineItem') {
      const discount =
        oc(lineItem).__typename() === 'DiscountLineItem' &&
        getValueOrUndefined((lineItem as DiscountLineItem).discount);
      const type = translateDiscountOptionText(getValueOrUndefined(discount.type) || 'discount');
      const dateOrDuration = getValueOrUndefined(discount.duration) as DateOrDuration | undefined;
      if (dateOrDuration) {
        if (
          dateOrDuration.__typename === 'Duration' &&
          dateOrDuration.duration &&
          dateOrDuration.duration !== null
        ) {
          return `${type}, ${formatTermDuration(dateOrDuration.duration)}`;
        } else if (
          dateOrDuration.__typename === 'StartEndDates' &&
          dateOrDuration.endDate &&
          dateOrDuration.endDate !== null
        ) {
          return `${type}, ends ${isoFormatDate(new Date(dateOrDuration.endDate))}`;
        }
      }

      return type;
    } else if (lineItem.__typename === 'PurchaseLineItem') {
      return i18next.t('quote::One Time');
    }
  }
  return i18next.t('quote::configure');
};
