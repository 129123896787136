import {
  Address as AddressIon,
  AddressLarge as AddressLargeIon,
  AddressProps,
} from 'components/ions';
import { Address as AddressType } from 'features/customer/types';
import {
  getMarketByCountryCode,
  getMarketByCountryName,
  getTranslatedCountry,
  Market,
} from 'features/proposal/supported-markets';
import {
  getTranslatedRegion,
  isMarketWithRegions,
  MarketWithRegions,
} from 'features/proposal/supported-regions';
import i18next from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const getRegionFullName = (addressProps: AddressProps, t: i18next.TFunction) => {
  const market =
    getMarketByCountryCode(addressProps.address.country) ||
    getMarketByCountryName(addressProps.address.country);
  const marketHasRegion = isMarketWithRegions(market as Market);
  const fullNameOfTheRegion =
    marketHasRegion &&
    getTranslatedRegion(t, market as MarketWithRegions, addressProps.address.region);
  return fullNameOfTheRegion || undefined;
};

const translateAddressProps = (t: i18next.TFunction, addressProps: AddressProps): AddressProps => {
  if (addressProps.loading) {
    return addressProps;
  }

  const translatedRegion = getRegionFullName(addressProps, t);
  const translatedCountry = getTranslatedCountry(addressProps.address.country, t);

  const translatedAddressProps: AddressProps = {
    ...addressProps,
    address: {
      ...addressProps.address,
      country: translatedCountry,
      region: translatedRegion || addressProps.address.region,
    },
  };
  return translatedAddressProps;
};

export const Address = (props: AddressProps) => {
  const { t } = useTranslation();
  const translated = translateAddressProps(t, props);

  return <AddressIon {...translated} />;
};

export const AddressLarge = (props: AddressProps) => {
  const { t } = useTranslation();
  const translated = translateAddressProps(t, props);
  return <AddressLargeIon {...translated} />;
};

export const emptyAddress: AddressType = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  companyName: '',
  country: '',
  postalCode: '',
  region: '',
};
