import { Dialog, PrimaryButton, SecondaryButton } from 'components';
import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { Fail, Processing } from 'features-apollo/components/dialogs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';

import { useMutation } from '@apollo/react-hooks';

import { RemoveEnrollmentNumber } from '../queries';
import { SubmitQuoteDialog } from '../SubmitQuoteDialog';

export interface RemoveEnrollmentDialogProps {
  submittingForApproval?: boolean;
  quoteId: string;
  quoteEtag: string;
}

export enum RemoveEnrollmentStep {
  Dialog,
  Processing,
}

const dialogDimensions = {
  height: 290,
  width: 565,
};

export const RemoveEnrollmentDialog: React.FC<RemoveEnrollmentDialogProps> = props => {
  const { quoteId, quoteEtag, submittingForApproval } = props;
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);
  const [currentStep, setCurrentStep] = React.useState<RemoveEnrollmentStep>(
    RemoveEnrollmentStep.Dialog
  );

  const [removeEnrollmentNumber, { loading, error }] = useMutation(RemoveEnrollmentNumber, {
    update(cache, { data: { updateVLAgreementNumber } }) {
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: updateVLAgreementNumber.id },
        data: { getQuote: updateVLAgreementNumber },
      });
    },
  });

  const removeEnrollment = () => {
    setCurrentStep(RemoveEnrollmentStep.Processing);
    removeEnrollmentNumber({
      variables: {
        input: {
          id: quoteId,
          etag: quoteEtag,
        },
      },
    });
  };

  switch (currentStep) {
    case RemoveEnrollmentStep.Processing: {
      if (loading) {
        return <Processing {...dialogDimensions} />;
      } else if (error) {
        return (
          <Fail
            {...dialogDimensions}
            dataAutomationId="removeEnrollmentFail"
            message={t(
              'quote::We are sorry, but we were not able to remove the enrollment number from the quote.'
            )}
            title={t('quote::Remove enrollment number?')}
            onTryAgainClick={removeEnrollment}
          />
        );
      } else {
        if (submittingForApproval) {
          return <SubmitQuoteDialog />;
        } else {
          dialogContext.closeDialog();
          return null;
        }
      }
    }
    case RemoveEnrollmentStep.Dialog:
    default: {
      return (
        <Dialog
          {...dialogDimensions}
          footerButtons={[
            <PrimaryButton
              key="remove-enrollment"
              text={t('quote::Remove enrollment')}
              onClick={removeEnrollment}
            />,
            <SecondaryButton
              key="continue"
              text={submittingForApproval ? t('quote::Continue to submission') : t('quote::Cancel')}
              onClick={() => {
                dialogContext.closeDialog();
                if (submittingForApproval) {
                  dialogContext.openDialog({
                    providedDialog: <SubmitQuoteDialog />,
                  });
                }
              }}
            />,
          ]}
          title={t('quote::Remove enrollment number?')}
        >
          {t(
            `quote::There is currently an enrollment number on the quote which will result in the migration of your customer's Azure services from legacy to modern. This could result in the loss of any existing concessions. Given the existence of the SCP Commitment term on the quote, you may not want to migrate your customer's services. Would you like to remove the enrollment number?`
          )}
        </Dialog>
      );
    }
  }
};
