import { Product } from 'services/catalog/types';
import { OrganizationSummary } from 'services/customer/types';
import {
  LineItem,
  Proposal,
  SearchProposalsSortField,
  SearchSortOrder,
} from 'services/proposal/types';

export interface ExternalReference {
  id: string;
  url: string;
}

export interface Recipient {
  recipientId: string;
  accountId: string;
  organizationId: string;
}

export interface PriceMap {
  [key: string]: { price?: number; isLoading: boolean };
}
export interface SkuTitleMap {
  [key: string]: { sku: string; title: string }[];
}
export interface EmpowermentsList {
  [key: string]: {
    approvers: { id: string; email: string }[];
    validated?: Record<string, boolean>;
  };
}

export interface SummaryListSearchParams {
  filter?: string;
  proposalIds?: string[];
  pendingOnly?: boolean;
  sortField: SearchProposalsSortField;
  sortOrder: SearchSortOrder;
}

export interface ApprovalListSearchParams extends SummaryListSearchParams {
  pendingOnly: boolean;
}

export interface EditorSummaryList {
  search: {
    filter: string;
    sortField: SearchProposalsSortField;
    sortOrder: SearchSortOrder;
  };
}

export enum ProposalCombinedStatus {
  ApprovalDenied = 'Approval denied', //t('quote::Approval denied')
  ApprovalRequired = 'Approval required', //t('quote::Approval required')
  Approved = 'Approved', //t('quote::Approved')
  AutoApprovedAndActivated = 'Auto-approved & activated', //t('quote::Auto-approved & activated')
  Completed = 'Completed', //t('quote::Completed')
  Draft = 'Draft', //t('quote::Draft')
  Expired = 'Expired', //t('quote::Expired')
  InProgress = 'In progress', //t('quote::In progress')
  PendingApproval = 'Pending approval', //t('quote::Pending approval')
  Published = 'Published', //t('quote::Published')
  UnknownStatus = 'Unknown status', //t('quote::Unknown status')
}

export enum InvitedUserStatus {
  Invalid,
  Verified,
  UnknownTenant,
  UnknownWorkAccount,
  Required,
}

export interface DiscountApplyRequest {
  id: string;
  newLineItem: LineItem;
  proposal: Proposal;
}

export interface BulkDiscountApplyRequest {
  productIds: string[];
  newLineItems: LineItem[];
  proposal: Proposal;
}

export interface ModernFootprint {
  accountId: string;
  organization: OrganizationSummary;
  projectId?: string;
}
export interface LegacyFootprint {
  accountId: string;
  organizationId: string;
  projectId: string;
  recipientId: string;
  billingGroupId: string;
  enrollment: string;
}

export enum HydrateType {
  all = 'all',
  lite = 'lite',
}

export interface OpenCard {
  lineItemId: string;
  type: CardType;
  relatedIds?: string[];
}

export enum EnrollmentFieldView {
  TextboxStandard = 'TextboxStandard',
  ComboBox = 'ComboBox',
}

export enum CardType {
  Discount = 'Discount',
  Config = 'Config',
  Quantity = 'Quantity',
}

export interface Timezones {
  first: string;
  last: string;
}

export enum DuplicateType {
  fromQuote = 'fromQuote',
  fromAssets = 'fromAssets',
}

export interface ProposalListLineItem extends LineItem {
  ecifGroup?: LineItem[];
}

export interface SelectedRowItem {
  lineItem?: LineItem;
  product?: Product;
  productTitle: string;
}

export enum CrmValidity {
  Valid = 'Valid',
  InvalidCrmId = 'InvalidCrmId',
  MissingSalesAccount = 'MissingSalesAccount',
  UnsupportedSalesAccountMarket = 'UnsupportedSalesAccountMarket',
}
