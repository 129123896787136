import { ThemeProps } from 'styles';

export const MarkdownRendererStyles = (theme: ThemeProps) => {
  return {
    container: {
      fontFamily: theme.fonts.fontFamily.fabricDefault,
      backgroundColor: theme.palette.backgroundStandout,
      '& h1': {
        fontSize: theme.fonts.fontSizes.xLarger,
        lineHeight: theme.fonts.fontSizes.xXLarge,
        fontWeight: theme.fonts.fontWeights.semiBold,
        color: theme.palette.text,
      },
      '& h2': {
        fontSize: theme.fonts.fontSizes.xLarge,
        fontWeight: theme.fonts.fontWeights.semiBold,
        color: theme.palette.text,
      },
      '& h3': {
        fontSize: theme.fonts.fontSizes.larger,
        fontWeight: theme.fonts.fontWeights.semiBold,
        color: theme.palette.textLink,
      },
      '& h4': {
        fontSize: theme.fonts.fontSizes.larger,
        fontWeight: theme.fonts.fontWeights.regular,
        color: theme.palette.text,
      },
      '& h5': {
        fontSize: theme.fonts.fontSizes.large,
        fontWeight: theme.fonts.fontWeights.semiBold,
        color: theme.palette.text,
      },
      '& h6': {
        fontSize: theme.fonts.fontSizes.large,
        fontWeight: theme.fonts.fontWeights.regular,
        color: theme.palette.textLink,
      },
      '& p': {
        fontSize: theme.fonts.fontSizes.medium,
        fontWeight: theme.fonts.fontWeights.regular,
        color: theme.palette.text,
        lineHeight: theme.fonts.fontSizes.xLarge,
      },
      '& strong': {
        fontWeight: theme.fonts.fontWeights.semiBold,
      },
      '& em': {
        fontStyle: theme.fonts.fontStyles.italic,
      },
      '& a': {
        display: 'inline',
        fontSize: theme.fonts.fontSizes.medium,
        fontWeight: theme.fonts.fontWeights.regular,
        color: theme.palette.textLink,
      },
      '& span': {
        //fix icon spacing
        '& i[data-icon-name]': {
          position: 'static',
          marginLeft: 4,
        },
        '& i:not([data-icon-name])': {
          display: 'none',
        },
      },
      '& table': {
        marginTop: 16,
        marginBottom: 24,
        borderTop: `1px solid ${theme.palette.backgroundDivider}`,
        borderRight: `1px solid ${theme.palette.backgroundDivider}`,
        borderSpacing: 0,
        borderRadius: 2,
        fontSize: theme.fonts.fontSizes.medium,
        fontWeight: theme.fonts.fontWeights.regular,
        color: theme.palette.text,
        lineHeight: theme.fonts.fontSizes.xLarge,
        '& td': {
          padding: 12,
          borderBottom: `1px solid ${theme.palette.backgroundDivider}`,
          borderLeft: `1px solid ${theme.palette.backgroundDivider}`,
        },
        '& th': {
          borderBottom: `2px solid ${theme.palette.backgroundDivider}`,
          borderLeft: `1px solid ${theme.palette.backgroundDivider}`,
        },
      },
      '& blockquote': {
        fontSize: theme.fonts.fontSizes.medium,
        fontWeight: theme.fonts.fontWeights.regular,
        color: theme.palette.text,
        background: theme.palette.lineItemHighlight,
        borderRadius: 2,
        padding: 16,
        margin: 16,
        marginBottom: 24,
        '& > :first-child:not(table)': {
          borderLeft: `2px solid ${theme.palette.secondary}`,
          paddingLeft: 12,
          margin: 0,
        },
        '& a': {
          display: 'flex',
          flexFlow: 'column',
        },
        '& table': {
          border: 'none',
          margin: 0,
        },
        '& table th': {
          fontSize: theme.fonts.fontSizes.small,
          border: 'none',
          textAlign: 'left',
          paddingBottom: 4,
        },
        '& table td': {
          border: 'none',
          textAlign: 'left',
          padding: 0,
          paddingBottom: 8,
          paddingRight: 32,
          verticalAlign: 'baseline',
        },
      },
      '& hr': {
        margin: 0,
        marginTop: 32,
        marginBottom: 24,
        border: `1px solid ${theme.palette.backgroundDivider}`,
      },
      '& code': {
        background: theme.palette.lineItemHighlight,
      },
      '& img': {
        display: 'block',
        width: '100%',
        paddingBottom: 0,
      },
      '& ol': {
        paddingLeft: 12,
        marginLeft: 16,
        counterReset: 'li',
        listStyle: 'none',
        marginTop: 8,
      },
      '& ul': {
        paddingLeft: 12,
        marginLeft: 16,
        listStyle: 'none',
        marginTop: 8,
      },
      '& li': {
        fontSize: theme.fonts.fontSizes.medium,
        fontWeight: theme.fonts.fontWeights.regular,
        color: theme.palette.text,
        counterIncrement: 'li',
        marginLeft: 12,
        marginBottom: 8,
        '& p': { display: 'inline' },
      },
      '& ul li': { counterIncrement: 'counter -1', '& a': { verticalAlign: 'top' } },
      '& ol li::before': {
        content: `'.'counter(li)`,
        color: theme.palette.textLink,
        display: 'inline-block',
        width: 4,
        marginLeft: '-16px',
        paddingRight: 12,
        textAlign: 'right',
        direction: 'rtl',
      },
      '& ul li::before': {
        content: `'•'`,
        color: theme.palette.text,
        display: 'inline-block',
        width: 4,
        marginLeft: '-16px',
        paddingRight: 12,
        textAlign: 'right',
        direction: 'rtl',
      },
      '& img+em': {
        display: 'block',
        background: theme.palette.backgroundCommon,
        padding: 8,
        marginRight: 16,
        marginLeft: 16,
        marginBottom: 24,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleBlock: {
      display: 'flex',
      flexFlow: 'column',
      marginBottom: 8,
    },
    title: { paddingBottom: 4 },
    date: { color: theme.palette.textTertiary },
    feedbackButtons: {
      paddingTop: 5,
      flexShrink: 0,
    },
  };
};
