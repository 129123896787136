import { CommandButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { actionButtonStyles } from '../shared.styles';

const styles = (theme: ThemeProps) => ({
  root: {
    ...actionButtonStyles(theme).text,
    color: theme.palette.secondary,
    '& i': {
      color: theme.palette.secondary,
      fontSize: theme.fonts.fontSizes.larger,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.textLinkHover,
      '& i': {
        color: theme.palette.textLinkHover,
      },
    },
  },
});

interface AddButtonProps extends ButtonSharedProps {
  predefinedIconName: 'Add' | 'AddTo';
}

type Props = AddButtonProps & WithStyles<typeof styles>;

const AddButtonUnstyled: React.FC<Props> = (props: Props) => {
  return (
    <CommandButtonAtom
      addClass={mergeClassNames([props.addClass])}
      ariaLabel={props.ariaLabel}
      componentRef={props.componentRef}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={{
        iconName: props.predefinedIconName,
      }}
      id={props.id}
      styles={{ root: props.classes.root }}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const AddButton = withStyles(styles)(AddButtonUnstyled) as React.FC<AddButtonProps>;
