import { SeatsCard } from './SeatsCard';
import { connect } from 'react-redux';
import { closeConfigCard, updateProposalAsync } from 'features/proposal/actions';
import { validateQuantityConstraintAsync } from 'features/catalog/actions';
import { RootState } from 'store/types';
import { SeatsCardProps } from './types';
import { ValidateQuantity } from 'services/constraints/types';
import * as selectors from 'features/proposal/selectors';
import { oc } from 'ts-optchain';
import { Proposal, ProposalUpdateRequest } from 'services/proposal/types';
import { getProduct, getOwnershipConstraintsForProduct } from 'features/catalog/selectors';

const mapStateToProps = (state: RootState, ownprops: SeatsCardProps) => {
  const proposal: Proposal = selectors.getActiveProposal(state);
  const lineItems = selectors.getLineItemsSwitchingDFD(state);
  const targetLineItem =
    lineItems && lineItems.find(lineItem => lineItem.id === ownprops.lineItemId);
  const startDateObj = oc(targetLineItem).purchaseInstruction.termStartDate();
  const startDate = startDateObj ? startDateObj.toString() : '';
  const lineItemsWithSameProduct =
    lineItems &&
    lineItems.filter(lineItem => lineItem.productIdentifier.productId === ownprops.productId);
  const totalSum =
    (lineItemsWithSameProduct &&
      lineItemsWithSameProduct.reduce((sum, lineItem) => (sum += lineItem.quantity), 0)) ||
    0;
  const sumOfQuantityExceptCurrentItem = totalSum - oc(targetLineItem).quantity(0);
  const productId = oc(targetLineItem).productIdentifier.productId('');
  const skuId = oc(targetLineItem).productIdentifier.skuId();
  const product = productId ? getProduct(state, productId) : undefined;
  const skuAvail = product && product.DisplaySkuAvailabilities.find(sku => sku.Sku.SkuId === skuId);
  const minQuantity = oc(skuAvail).Sku.Properties.MinOrderQuantity(1);
  const maxQuantity = oc(skuAvail).Sku.Properties.MaxOrderQuantity(1);
  const constraintForProduct = getOwnershipConstraintsForProduct(state, ownprops.productId);
  return {
    purchaseAtStart: oc(constraintForProduct).constraintResults[0].purchasedQuantity(0),
    minSeats: oc(constraintForProduct).constraintResults[0].minimumQuantity(minQuantity),
    maxSeats: oc(constraintForProduct).constraintResults[0].maximumQuantity(maxQuantity),
    organizationId: oc(proposal).header.soldToCustomerLegalEntity.organizationId(''),
    accountId: oc(proposal).header.soldToCustomerLegalEntity.accountId(''),
    skuId: oc(targetLineItem).productIdentifier.skuId(''),
    availabilityId: oc(targetLineItem).productIdentifier.availabilityId(''),
    lineItem: targetLineItem,
    proposal,
    startDate,
    sumOfQuantityExceptCurrentItem,
  };
};

const dispatchProps = {
  onDismiss: closeConfigCard,
  onUpdateQuantity: (p: ProposalUpdateRequest) => updateProposalAsync.request(p),
  onLoad: (v: ValidateQuantity) => validateQuantityConstraintAsync.request(v),
};

export const ConnectedSeatsCardContainer = connect(
  mapStateToProps,
  dispatchProps
)(SeatsCard);
