import { ActionType, EditLabel, TextBody } from 'components';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { sharedStyles, ThemeProps } from 'styles';

const overviewRowStyles = (theme: ThemeProps) => {
  return {
    rowStyles: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: (props: OverviewRowProps) => props.maxWidth,
    },
    headerColor: {
      color: theme.palette.textTertiary,
    },
    overflow: (props: OverviewRowProps) => {
      const overflowStyle = props.disabled
        ? { ...sharedStyles(theme).textOverflow, color: theme.palette.textDisabled }
        : sharedStyles(theme).textOverflow;
      return overflowStyle;
    },
  };
};

export interface OverviewRowProps {
  body?: string | null;
  ariaLabel?: string;
  hideIcon?: boolean;
  iconName?: string;
  buttonId?: string;
  label: string;
  maxWidth?: number;
  disabled?: boolean;
  required?: boolean;
  onRenderBody?: () => JSX.Element;
  onClick?: (
    event?: React.MouseEvent<ActionType, MouseEvent> | React.KeyboardEvent<ActionType>
  ) => void;
  dataAutomationId?: string;
}

type Props = OverviewRowProps & WithStyles<typeof overviewRowStyles>;

export const OverviewRowUnstyled: React.FC<Props> = props => {
  const {
    label,
    body,
    ariaLabel,
    onClick,
    hideIcon,
    iconName,
    disabled,
    dataAutomationId,
    buttonId,
  } = props;
  const render = (props.onRenderBody && props.onRenderBody()) || (
    <TextBody
      addClass={props.classes.overflow}
      dataAutomationId={props.dataAutomationId + 'Value'}
      title={props.body || undefined}
    >
      {body}
    </TextBody>
  );
  const editLabelDataAutomationId = iconName
    ? `${dataAutomationId}${iconName}`
    : `${dataAutomationId}EditLabel`;
  return (
    <div className={props.classes.rowStyles}>
      <EditLabel
        ariaLabel={ariaLabel || label}
        buttonId={buttonId}
        dataAutomationId={editLabelDataAutomationId}
        disabled={disabled}
        hideIcon={hideIcon}
        iconName={iconName}
        label={label}
        onClick={onClick}
      />
      {render}
    </div>
  );
};

export const OverviewRow = withStyles(overviewRowStyles)(OverviewRowUnstyled) as React.FC<
  OverviewRowProps
>;
