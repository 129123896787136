import { GuidanceBox } from 'components/ions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationSearchResponseItem } from 'services/edge-search/types';

import { SelectOrganization } from './SelectOrganization';
import {
  ExistingCustomerOption,
  NewOrganizationOption,
  NoOrganizationHeaderProps,
  OptionProps,
  OptionTemplate,
} from './shared';

export interface NoOrganizationWithSuggestionsProps extends NoOrganizationHeaderProps {
  organizationSuggestions: OrganizationSearchResponseItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectOrganization: (organizationId: string, accountId: string) => any;
}

interface SelectOrganizationOptionProps extends OptionProps {
  organizationSuggestions: OrganizationSearchResponseItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectOrganization: (organizationId: string, accountId: string) => any;
}

const SelectOrganizationOption: React.FC<SelectOrganizationOptionProps> = props => {
  const { t } = useTranslation();
  const title = t('quote::Option {{number}}', { number: props.optionNumber });
  return (
    <OptionTemplate
      description={t(
        'quote::Reuse one of the billing accounts below matching the name of the sales account on the opportunity or success engagement you started with. Choose this option when the customer may have previously accepted an MCA.'
      )}
      title={title}
    >
      <SelectOrganization
        limitTotalToDisplay={5}
        organizationSuggestions={props.organizationSuggestions}
        readOnly={props.readOnly}
        onSelectOrganization={props.onSelectOrganization}
      />
    </OptionTemplate>
  );
};

export const NoOrganizationWithSuggestions: React.FC<NoOrganizationWithSuggestionsProps> = props => {
  return (
    <GuidanceBox>
      <SelectOrganizationOption
        optionNumber={1}
        organizationSuggestions={props.organizationSuggestions}
        readOnly={props.readOnly}
        onSelectOrganization={props.onSelectOrganization}
      />
      <NewOrganizationOption optionNumber={2} readOnly={props.readOnly} />
      <ExistingCustomerOption optionNumber={3} readOnly={props.readOnly} />
    </GuidanceBox>
  );
};
