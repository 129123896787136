import axios, { AxiosError } from 'axios';
import { User } from 'features/user/types';
import { Filter, PatchCommand } from 'services/types';
import {
  approvalOverrideScenarios,
  createGuid,
  getAuthHeader,
  getCV,
  getFilterString,
  getTestHeader,
} from 'services/utils';

import { endpoints, getTokenAuthority, ProposalConfig } from './config';
import {
  CreateLineItemsRequest,
  DeleteLineItemRequest,
  DeleteLineItemsRequest,
  MultipleProposalActionRequest,
  PricingProposal,
  Proposal,
  ProposalAction,
  ProposalActionRequest,
  ProposalDeleteRequest,
  ProposalHeader,
  ProposalHeaderPatchRequest,
  ProposalHeaderResponse,
  ProposalPricingRequest,
  ProposalSort,
  ProposalSummary,
  ProposalSummaryList,
  ProposalUpdateRequest,
  RequestProposal,
  SearchProposalsFilterField,
  SearchProposalsParams,
  SearchProposalsSortField,
  SearchSortOrder,
} from './types';
import { appifyProposal, createProposalHeaderResponse, requestifyProposal } from './utils';

async function getHeaders(
  config: ProposalConfig,
  correlationContext?: string
): Promise<Record<string, string>> {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(getTokenAuthority(config.environment)),
    'x-ms-correlation-id': createGuid(),
    'x-ms-tracking-id': createGuid(),
    'x-ms-authenticationType': 'aad',
    'MS-CV': getCV(),
  };
  if (config.useTestHeader) {
    const approvalScenarios = config.useApprovalTestHeaderScenarios
      ? approvalOverrideScenarios
      : undefined;

    headers['x-ms-test'] = getTestHeader(approvalScenarios);
  }
  if (correlationContext) {
    headers[
      'correlation-context'
    ] = `v=1,ms.a.app.id=QuoteCenter,ms.b.tel.partner=commerce,ms.b.tel.scenario=commerce.quote.${correlationContext}.1`;
  }
  return headers;
}

async function getHeadersWithEtag(
  config: ProposalConfig,
  etag: string,
  correlationContext?: string
): Promise<Record<string, string>> {
  const headers = await getHeaders(config, correlationContext);
  headers['If-Match'] = etag;
  return headers;
}

export function getSortString(
  sort: ProposalSort = {
    field: SearchProposalsSortField.ModifiedDate,
    order: SearchSortOrder.Descending,
  }
) {
  const { field, order } = sort;
  return `${field} ${order}`;
}

/**
 * All equality filters should be added before any 'matches' filter. Order matters.
 */
export const filterTemplates = {
  getMsContact: (user: string) => {
    const filter: Filter<SearchProposalsFilterField> = { filters: [], operation: 'and' };
    filter.filters.push({
      filterField: SearchProposalsFilterField.Status,
      operation: '!=',
      value: '"Deleted"',
    });
    filter.filters.push({
      filterField: SearchProposalsFilterField.MsContact,
      operation: 'matches',
      value: `/.*${user}.*/`,
    });
    return filter;
  },
  getSearch: (query: string) => {
    const filter: Filter<SearchProposalsFilterField> = { filters: [], operation: 'and' };
    filter.filters.push({
      filterField: SearchProposalsFilterField.Status,
      operation: '!=',
      value: '"Deleted"',
    });
    const shouldNotTrim: SearchProposalsFilterField[] = [SearchProposalsFilterField.Name];
    const innerFilter: Filter<SearchProposalsFilterField> = { filters: [], operation: 'or' };
    (Object.values(SearchProposalsFilterField) as SearchProposalsFilterField[]).forEach(value => {
      const searchText = shouldNotTrim.includes(value) ? query : query.trim();
      innerFilter.filters.push({
        filterField: value,
        operation: 'matches',
        value: `/.*${searchText}.*/`,
      });
    });
    filter.filters.push(innerFilter);
    return filter;
  },
  getSearchByIdAndStatus: (proposalIds: string[], pendingOnly: boolean) => {
    const filter: Filter<SearchProposalsFilterField> = {
      filters: [],
      operation: 'and',
    };

    if (pendingOnly) {
      filter.filters.push({
        filterField: SearchProposalsFilterField.Status,
        operation: '=',
        value: '"Submitted"',
      });
    }

    const innerFilter: Filter<SearchProposalsFilterField> = { filters: [], operation: 'or' };
    proposalIds.forEach(id => {
      innerFilter.filters.push({
        filterField: SearchProposalsFilterField.Id,
        operation: '=',
        value: `"${id}"`,
      });
    });
    filter.filters.push(innerFilter);

    return filter;
  },
  getIds: (ids: string[]) => {
    const filter: Filter<SearchProposalsFilterField> = { filters: [], operation: 'or' };
    ids.forEach(id =>
      filter.filters.push({
        filterField: SearchProposalsFilterField.Id,
        operation: 'matches',
        value: `/${id}/`,
      })
    );
    return filter;
  },
};

export async function searchProposals(
  parameters: SearchProposalsParams,
  config: ProposalConfig
): Promise<ProposalSummary[]> {
  const { filter, sort } = parameters;
  const { environment } = config;

  const url = `${endpoints[environment]}/quotes/`;
  const params = {
    filters: filter && getFilterString<SearchProposalsFilterField>(filter),
    orderBy: getSortString(sort),
  };
  const headers = await getHeaders(config);
  const response = await axios.get<ProposalSummaryList>(url, { params, headers });

  return response.data.results;
}

export async function loadProposal(id: string, config: ProposalConfig): Promise<Proposal> {
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes/${id}`;
  const headers = await getHeaders(config);
  const response = await axios.get<Proposal>(url, { headers });
  return appifyProposal(response);
}

export async function deleteProposal(
  request: ProposalDeleteRequest,
  config: ProposalConfig
): Promise<string> {
  const { id, etag } = request;
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes/${id}`;
  const headers = await getHeadersWithEtag(config, etag);
  try {
    await axios.delete(url, { headers });
    return id;
  } catch (exception) {
    const e = exception as AxiosError;
    if (e.response && e.response.status === 404) {
      return id;
    }
    throw exception;
  }
}

export async function patchProposalHeader(
  request: ProposalHeaderPatchRequest,
  config: ProposalConfig
): Promise<ProposalHeaderResponse> {
  const { proposalId, etag, commands } = request;
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes/${proposalId}/header`;
  const headers = await getHeadersWithEtag(config, etag);
  headers['Content-Type'] = 'application/json-patch+json';
  const response = await axios.patch<ProposalHeader>(url, commands, { headers });
  return createProposalHeaderResponse(response, proposalId);
}

export async function updateProposal(
  request: ProposalUpdateRequest,
  config: ProposalConfig
): Promise<Proposal> {
  const { proposalId, etag, proposal } = request;
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes/${proposalId}`;
  const headers = await getHeadersWithEtag(config, etag);
  const response = await axios.put<Proposal>(url, requestifyProposal(proposal), { headers });
  return appifyProposal(response);
}

export async function createProposal(
  proposal: RequestProposal,
  config: ProposalConfig
): Promise<Proposal> {
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes`;
  const headers = await getHeaders(config, 'createquote');
  const response = await axios.post<Proposal>(url, requestifyProposal(proposal), { headers });
  return appifyProposal(response);
}

export async function createLineItems(
  request: CreateLineItemsRequest,
  config: ProposalConfig
): Promise<Proposal> {
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes/${request.proposalId}/lineItems`;
  const headers = await getHeadersWithEtag(config, request.etag, 'addlineitem');
  const response = await axios.post<Proposal>(url, { lineItems: request.lineItems }, { headers });
  return appifyProposal(response);
}

export async function deleteLineItem(
  request: DeleteLineItemRequest,
  config: ProposalConfig
): Promise<Proposal> {
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes/${request.proposalId}/lineItems/${request.lineItemId}`;
  const headers = await getHeadersWithEtag(config, request.etag);
  await axios.delete(url, { headers });
  return await loadProposal(request.proposalId, config);
}

export async function deleteLineItems(
  request: DeleteLineItemsRequest,
  config: ProposalConfig
): Promise<Proposal> {
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes/${request.proposalId}/lineItems`;
  const headers = await getHeadersWithEtag(config, request.etag);
  const body: PatchCommand[] = request.lineItemIds.map(lineItemId => {
    return { op: 'remove', path: `/${lineItemId}` };
  });
  await axios.patch(url, body, {
    headers: { ...headers, 'content-type': 'application/json-patch+json' },
  });
  return await loadProposal(request.proposalId, config);
}

export async function performProposalAction(
  request: ProposalActionRequest,
  config: ProposalConfig,
  user: User
): Promise<Proposal> {
  const { environment } = config;
  const url = `${endpoints[environment]}/quotes/${request.proposalId}/actions`;
  const correlationContext = request.body.action === ProposalAction.Submit ? 'submitquote' : '';
  const headers = await getHeadersWithEtag(config, request.etag, correlationContext);
  if (
    user.email &&
    (request.body.action === ProposalAction.Submit ||
      request.body.action === ProposalAction.Withdraw)
  ) {
    // For withdraw this gets mapped to x-ms-actor-email in quote service
    headers['x-ms-submitter'] = user.email;
  }
  const response = await axios.post<Proposal>(url, request.body, { headers });
  return appifyProposal(response);
}

export async function performMultipleProposalActions(
  request: MultipleProposalActionRequest,
  config: ProposalConfig,
  user: User
): Promise<Proposal> {
  let { etag, proposalId } = request;

  if (request.bodies.length < 1) {
    throw new Error('No actions available to do');
  }

  let newProposal: Proposal;
  let i = 0;
  do {
    newProposal = await performProposalAction(
      { etag, proposalId, body: request.bodies[i] },
      config,
      user
    );
    etag = newProposal.etag;
  } while (++i < request.bodies.length);

  return newProposal;
}

export async function loadPrices(
  request: ProposalPricingRequest,
  config: ProposalConfig
): Promise<PricingProposal> {
  const maxPricingItems = 10;
  const { environment } = config;
  const url = `${endpoints[environment]}/prices`;
  const headers = await getHeaders(config);
  const items = request.lineItems;
  const promises: Promise<{ data: PricingProposal }>[] = [];
  for (let i = 0; i < items.length; i += maxPricingItems) {
    const newRequest = { ...request, lineItems: items.slice(i, i + maxPricingItems) };
    const promise = axios.post<PricingProposal>(url, newRequest, { headers });
    promises.push(promise);
  }
  const responses = await Promise.all(promises);
  const aggregatedResults = {
    data: {
      lineItems: [],
      messages: [],
      pricingContext: request.pricingContext,
      totalPrice: 0,
    },
  };
  responses.reduce((acc, current) => {
    acc.data.lineItems.push(...current.data.lineItems);
    acc.data.messages.push(...current.data.messages);
    acc.data.totalPrice += current.data.totalPrice;
    return acc;
  }, aggregatedResults);
  return aggregatedResults.data;
}
