import { LinkProps, NotificationItem, TextBodySmall, TextTitle } from 'components';
import { getProduct } from 'features/catalog/selectors';
import * as actions from 'features/customer/actions';
import { ConnectedCalloutCardLink } from 'features/proposal/components/CalloutCardLink/CalloutCardLink';
import {
  getDiscountableProductInfo,
  MultiSelectDiscountInfo,
} from 'features/proposal/components/DetailsPane/detailsUtils';
import ProposalDetails from 'features/proposal/components/DetailsPane/ProposalDetails';
import { RowItemDetails } from 'features/proposal/components/DetailsPane/RowItemDetails';
import { ConnectedDiscountCardContainer } from 'features/proposal/components/DiscountCard/DiscountCardContainer';
import { getActiveProposal, getSelectedIds, isProductsView } from 'features/proposal/selectors';
import {
  getAllGroupedLineItems,
  getDetailsLineItemNotifications,
  getDetailsNotifications,
  getGroupedLineItems,
  getProductLineItems,
  getSelectedLineItemsWithSwitchedDFD,
  getSharedDiscountLineItemsNoSwitchedDfd,
  getTermLineItems,
  isDiscounted,
  isDiscountFulfillmentDocument,
  switchDFD,
} from 'features/proposal/selectors/lineItem';
import { getOpenCard } from 'features/proposal/selectors/views';
import { CardType, OpenCard, SelectedRowItem } from 'features/proposal/types';
import { getCRMId, isCardOpen } from 'features/proposal/utils';
import i18next from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import { LineItem, Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { useQuery } from '@apollo/react-hooks';

import { GetCrmLead } from './Queries';

const DetailsContainerStyles = {
  detailsContainer: {
    padding: '32px 24px',
  },
  heading: { display: 'flex', flexDirection: 'column', width: '100%' },
  description: {
    paddingTop: 8,
  },
  list: {
    paddingLeft: 16,
    marginTop: 3,
  },
  otherProductsList: {
    marginTop: 16,
  },
  bulkDiscountLink: {
    marginTop: 4,
  },
};

const mapStateToProps = (state: RootState) => {
  const selectedIds = getSelectedIds(state);
  const isProducts = isProductsView(state);
  const proposal = getActiveProposal(state);
  let notificationList: NotificationItem[];
  if (selectedIds.length === 0) {
    notificationList = getDetailsNotifications(state);
  } else if (selectedIds.length === 1) {
    notificationList = getDetailsLineItemNotifications(state, selectedIds[0]);
  } else {
    notificationList = [];
  }

  const lineItems = isProducts
    ? [...getProductLineItems(state), ...getSharedDiscountLineItemsNoSwitchedDfd(state)]
    : getTermLineItems(state);

  const selectedLineItems = getSelectedLineItemsWithSwitchedDFD(state, selectedIds);

  return {
    selectedIds,
    isProductsView: isProducts,
    lineItems,
    notificationList,
    groupedLineItems: getAllGroupedLineItems(state),
    selectedItems: selectedLineItems.map(lineItem => {
      const product =
        !!oc(lineItem).productIdentifier.productId &&
        getProduct(state, lineItem.productIdentifier.productId);
      return {
        productTitle: oc(product).LocalizedProperties[0].ProductTitle(''),
        lineItem,
        product,
      };
    }),
    openCard: getOpenCard(state),
    proposal,
    crmId: getCRMId(proposal),
  };
};

const dispatchProps = {
  setIsStrategicAccount: actions.setIsStrategicAccount,
};

export interface DetailsPaneProps {
  selectedIds: string[];
  isProductsView: boolean;
  lineItems: LineItem[];
  notificationList: NotificationItem[];
  groupedLineItems?: LineItem[];
  openCard?: OpenCard | undefined;
  proposal?: Proposal;
}

const renderSelectedProductList = (values: string[], listTitle: string, listClass: string) => {
  return values.length ? (
    <>
      <TextBodySmall>{`${values.length} ${listTitle}`}</TextBodySmall>
      <ul className={listClass}>
        {values.map((text, key) => {
          return (
            <TextBodySmall key={key}>
              <li>{text}</li>
            </TextBodySmall>
          );
        })}
      </ul>
    </>
  ) : null;
};

const renderMultiSelectedItemNames = (
  selected: SelectedRowItem[],
  classes: Record<string, string>,
  t: i18next.TFunction,
  isReadOnly: boolean,
  discountCardTarget: React.RefObject<HTMLDivElement>,
  openCard?: OpenCard
) => {
  const selectedInfo: MultiSelectDiscountInfo = getDiscountableProductInfo(selected);
  let selectedDiscountableIds: string[] = [];
  let selectedDiscountableProducts: Product[] = [];
  selectedInfo.discountableProducts.forEach(item => {
    if (item.lineItem && item.product) {
      selectedDiscountableIds.push(item.lineItem.id);
      selectedDiscountableProducts.push(item.product);
    }
  });
  const discountCardActive =
    selectedInfo &&
    selectedInfo.discountableProducts.length &&
    isCardOpen('bulkDiscount', CardType.Discount, openCard);

  const linkProps: LinkProps = {
    dataAutomationId: 'bulkDiscountConfigureLink',
    displayText: t('quote::Configure future discounts'),
  };
  const readOnly = isReadOnly || selectedDiscountableProducts.length < 2;
  if (selectedInfo.discountableProductsText.length) {
    return (
      <>
        <div>
          {renderSelectedProductList(
            selectedInfo.discountableProductsText,
            t('quote::bulk discountable products'),
            classes.list
          )}
          <div className={classes.bulkDiscountLink} ref={discountCardTarget}>
            {discountCardActive && (
              <ConnectedDiscountCardContainer
                hydratedProducts={selectedDiscountableProducts}
                lineItemIds={selectedDiscountableIds}
                target={discountCardTarget}
              />
            )}
            <ConnectedCalloutCardLink
              isReadOnly={readOnly}
              lineItemId="bulkDiscount"
              linkProps={linkProps}
              maxWidth={200}
              offset={{ left: 30, top: 10 }}
              reference={discountCardTarget}
              title={linkProps.displayText}
              type={CardType.Discount}
            />
          </div>
        </div>
        {selectedInfo.otherProductsDisplay.length ? (
          <div className={classes.otherProductsList}>
            {renderSelectedProductList(
              selectedInfo.otherProductsDisplay,
              t('quote::other products'),
              classes.list
            )}
          </div>
        ) : null}
      </>
    );
  } else if (selectedInfo.otherProductsDisplay.length) {
    return renderSelectedProductList(
      selectedInfo.otherProductsDisplay,
      t('quote::Selected line items contain the following products:'),
      classes.list
    );
  }
  return null;
};

type Props = DetailsPaneProps &
  WithStyles<typeof DetailsContainerStyles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const DetailsPaneUnstyled: React.FC<Props> = props => {
  const {
    selectedIds,
    classes,
    selectedItems,
    lineItems,
    groupedLineItems,
    openCard,
    proposal,
    crmId,
    setIsStrategicAccount,
  } = props;

  const selectedItemsCount = selectedIds.length;
  const { t } = useTranslation();
  const selectedLineItems: LineItem[] = lineItems.filter((item: LineItem) =>
    selectedIds.includes(item.id)
  );
  const discountCardTarget = React.useRef<HTMLDivElement>(null);

  const { data } = useQuery(GetCrmLead, {
    variables: {
      id: crmId,
    },
    skip: !crmId,
  });
  const crmLead = oc(data).getCRMLead();
  if (crmLead && crmLead.salesAccount) {
    const isStrategicAccount = crmLead.salesAccount.isStrategic;
    setIsStrategicAccount(isStrategicAccount);
  }

  if (selectedItemsCount > 1) {
    return (
      <div className={classes.detailsContainer}>
        <div className={classes.heading}>
          <div>
            <TextTitle>
              {t('quote::{{selectedItemsCount}} line items selected', { selectedItemsCount })}
            </TextTitle>
          </div>
        </div>
        <div className={classes.description}>
          {renderMultiSelectedItemNames(
            selectedItems,
            classes,
            t,
            oc(proposal).header.readOnly(false),
            discountCardTarget,
            openCard
          )}
        </div>
      </div>
    );
  } else if (selectedItemsCount === 1 && selectedLineItems.length) {
    let allGroupedLineItems;
    let selectedItem = selectedLineItems[0];
    let isDiscount = isDiscounted(selectedItem); //check if item is discount before dfd gets switched
    if (isDiscountFulfillmentDocument(selectedItem)) {
      isDiscount = true;
      selectedItem = switchDFD(selectedItem);
    }
    const groupGuid = oc(selectedItem).groups[0]('');

    if (
      oc(selectedItem)
        .productIdentifier.productType('')
        .toLowerCase() === 'financing'
    ) {
      allGroupedLineItems = getGroupedLineItems(groupedLineItems || [], groupGuid, selectedItem.id);
    }

    return (
      <RowItemDetails
        groupedLineItem={oc(allGroupedLineItems)[0]()}
        isDiscountItem={isDiscount}
        lineItem={selectedItem}
        notificationList={props.notificationList}
      />
    );
  } else {
    return <ProposalDetails lineItems={lineItems} notificationList={props.notificationList} />;
  }
};

export const DetailsPaneUnconnected = withStyles(DetailsContainerStyles)(
  DetailsPaneUnstyled
) as React.FC<DetailsPaneProps>;

export const DetailsPane = connect(mapStateToProps, dispatchProps)(DetailsPaneUnconnected);
