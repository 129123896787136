import {
  CopyInputButton,
  Dialog,
  PrimaryButton,
  TextNavigationSecondarySelected,
  LinkButton,
} from 'components';
import { TextBody } from 'components/ions';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext, ThemeProps } from 'styles';

export interface CopyReferralProps {
  referralName: string;
  referralLink: string;
  error?: boolean;
  onRetry?: () => void;
  processing?: boolean;
}

export const dialogDimensions = {
  height: 267,
  width: 426,
};

const styles = (theme: ThemeProps) => ({
  margin: {
    marginTop: 16,
  },
  errorMessage: {
    fontFamily: theme.fonts.fontFamily.fabricDefault,
    fontSize: theme.fonts.fontSizes.small,
    color: theme.palette.dangerText,
  },
});

type Props = CopyReferralProps & WithStyles<typeof styles>;

export const CopyReferralUnstyled: React.FC<Props> = (props: Props) => {
  const { referralName, referralLink, classes } = props;
  const context = React.useContext(DialogContext);

  const { t } = useTranslation();

  const closeButton = (
    <PrimaryButton
      dataAutomationId="CopyReferralCloseButton"
      text={t('Close')}
      onClick={() => {
        context.closeDialog();
      }}
    />
  );

  const errorMessage =
    (!!props.error && (
      <TextBody addClass={classes.errorMessage}>
        {t('The referral link is not usable to your customer.')}{' '}
        <LinkButton displayText={t('retry?')} onClick={props.onRetry} />
      </TextBody>
    )) ||
    undefined;
  return (
    <Dialog {...dialogDimensions} footerButtons={closeButton} title={t('Copy referral link')}>
      <div>
        <Trans ns="common">
          <TextBody>
            Copy the referral link for{' '}
            <TextNavigationSecondarySelected>{{ referralName }}</TextNavigationSecondarySelected>,
            to share with your customer. Get access to this link at anytime by finding this referral
            in your referral list.
          </TextBody>
        </Trans>
        <div className={classes.margin}>
          <CopyInputButton
            buttonText={t('Copy')}
            copiedText={t('Copied')}
            dataAutomationId="copyReferralInputButton"
            errorMessage={errorMessage}
            inputValue={referralLink}
            processing={props.processing}
            titleText={t('Copy link')}
            useSecondaryButton={true}
          />
        </div>
      </div>
    </Dialog>
  );
};

export const CopyReferral = withStyles(styles)(CopyReferralUnstyled);
