import { PrimaryButton } from 'components/ions/Buttons/PrimaryButton';
import { TextBody } from 'components/ions/Text';
import { getFlightIsEnabled, getMinCreditLineDivisor } from 'features/app/selectors';
import { getProduct } from 'features/catalog/selectors';
import { Fail, Processing } from 'features/components/dialogs';
import * as actions from 'features/proposal/actions';
import { PublishQuoteMutation } from 'features/proposal/components';
import { CreditLineReason } from 'features/proposal/components/Dialogs/CreditDialog/types';
import { mapQuotePublishSuccess } from 'features/proposal/fragments';
import * as selectors from 'features/proposal/selectors';
import {
  buildCreditRequest,
  getAgreementsErrorTerms,
  getGqlTermIdError,
  getInvalidTermsErrorMessage,
} from 'features/proposal/utils';
import i18next from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import loggerService from 'services/logger-service';
import { Proposal, UserGroup } from 'services/proposal/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useMutation } from '@apollo/react-hooks';

import { RequestCreditLine } from '../CreditDialog/types';
import { Success } from '../Shared';
import { WarningView } from './WarningView';

export enum gqlPublishErrorCodes {
  PENDING_AGREEMENT = 'PENDING_AGREEMENT',
  SIGNED_AGREEMENT = 'SIGNED_AGREEMENT',
  PARENT_ACCOUNT_MISMATCH = 'PARENT_ACCOUNT_MISMATCH',
  QUOTE_ALREADY_PUBLISHED = 'QUOTE_ALREADY_PUBLISHED',
}

const getAgreementType = (agreementType: string, t: i18next.TFunction) => {
  switch (agreementType) {
    case 'APT': // This is APT instead of CAPT to align with what is returned by GQL
      return t('quote::Customer Affiliate Purchase Terms');
    default: {
      return t('quote::Microsoft Customer Agreement');
    }
  }
};

const getErrorMessage = (
  error: string,
  t: i18next.TFunction,
  classes: Record<string, string>,
  leadOrgId?: string,
  agreementText?: string
) => {
  const parentText = t('quote::a parent');
  const affiliateText = t('quote::an affiliate');

  switch (error) {
    case gqlPublishErrorCodes.PENDING_AGREEMENT: {
      return (
        <div data-automation-id="publishDialogPendingAgreement">
          <div className={classes.marginBottom}>
            <TextBody>
              {t(
                'quote::There is another quote that has been published to your customer and is being used to establish a new agreement with them. Unfortunately, the billing account types do not match. '
              )}
              {t(
                `quote::On this quote they are defined as {{type1}}, and on the other quote they are defined as {{type2}}.`,
                {
                  type1: leadOrgId ? affiliateText : parentText,
                  type2: leadOrgId ? parentText : affiliateText,
                }
              )}
            </TextBody>
          </div>
          <div className={classes.marginBottom}>
            <TextBody>
              {t(
                'quote::Please determine which billing account is incorrect and edit it to match the correct one. If the incorrect quote is the one that is already published, you will have to withdraw it before editing.'
              )}
            </TextBody>
          </div>
        </div>
      );
    }
    case gqlPublishErrorCodes.SIGNED_AGREEMENT: {
      return t(
        'quote::Your customer has already signed the {{agreementType}}, and the billing account needs to be updated on this quote. Please remove and re-add the billing account, and then publish again.',
        { agreementType: agreementText }
      );
    }
    case gqlPublishErrorCodes.QUOTE_ALREADY_PUBLISHED: {
      return t('quote::This quote is already in a published state.');
    }
    case gqlPublishErrorCodes.PARENT_ACCOUNT_MISMATCH: {
      return (
        <div data-automation-id="publishDialogParentAccountMismatch">
          <div className={classes.marginBottom}>
            <TextBody>
              {t(
                'quote::There is another quote that has been published to your customer and is being used to establish a new agreement with them. Unfortunately, the billing account types do not match. '
              )}
              {t('quote::The parent billing accounts are different.')}
            </TextBody>
          </div>
          <div className={classes.marginBottom}>
            <TextBody>
              {t(
                'quote::Please determine which billing account is incorrect and edit it to match the correct one. If the incorrect quote is the one that is already published, you will have to withdraw it before editing.'
              )}
            </TextBody>
          </div>
        </div>
      );
    }
    default: {
      return t('quote::Sorry, the "Publish" action failed.');
    }
  }
};

const dialogDimensions = {
  height: 300,
  width: 485,
};

const alternateDialogDimensions = {
  height: 400,
  width: 525,
};

const termErrorDialogDimensions = {
  height: 612,
  width: 600,
};

const styles = {
  footerSuccess: {
    justifyContent: 'center',
  },
  buttonsMargin: {
    marginTop: 24,
  },
  marginBottom: {
    marginBottom: 10,
  },
};

export interface PublishDialogProps {
  onCopyLink?: () => void;
  onClickEmail?: () => void;
}

// Props ----------------------------------------------------------------
const mapStateToProps = (state: RootState) => ({
  proposal: selectors.getActiveProposal(state),
  delayCreditCheckFlightEnabled: getFlightIsEnabled(state, Flight.delayCreditCheck),
  organizationId: selectors.getOrganizationId(state),
  accountId: selectors.getAccountId(state),
  minCreditLineDivisor: getMinCreditLineDivisor(state),
  annualDealEstimate: selectors.getAnnualDealEstimate(state),
  creditLineStatus: selectors.getCreditLineStatus(state),
  getProductName: (productId: string) =>
    getProduct(state, productId).LocalizedProperties[0].ProductTitle,
  isSafelistedCreditCheckEnabled: getFlightIsEnabled(state, Flight.safelistedCreditCheck),
});

const dispatchProps = {
  publishProposalSuccess: (proposal: Proposal) => actions.publishQuoteGQLSuccess(proposal),
  requestCreditLine: (request: RequestCreditLine) =>
    actions.requestCreditLineAsync.request(request),
};

export type Props = WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  PublishDialogProps;

const PublishDialogFeatureUnstyled: React.FC<Props> = props => {
  const {
    proposal,
    classes,
    delayCreditCheckFlightEnabled,
    annualDealEstimate,
    minCreditLineDivisor,
    organizationId,
    accountId,
    requestCreditLine,
    creditLineStatus,
    isSafelistedCreditCheckEnabled,
  } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const [publishQuoteGQL, { error, data }] = useMutation(PublishQuoteMutation, {
    onCompleted: () => {
      if (
        delayCreditCheckFlightEnabled &&
        creditLineStatus === CreditLineReason.None &&
        organizationId &&
        accountId &&
        annualDealEstimate
      ) {
        const request: RequestCreditLine = buildCreditRequest(
          proposal,
          organizationId,
          accountId,
          minCreditLineDivisor,
          annualDealEstimate,
          undefined,
          isSafelistedCreditCheckEnabled
        );
        requestCreditLine(request);
      }
    },
  });

  const [triedPublishing, setTriedPublishing] = React.useState<boolean>(false);
  const closeDialog = () => context.closeDialog();

  const publishProposal = () => {
    setTriedPublishing(true);
    publishQuoteGQL({
      variables: {
        input: {
          id: proposal.id,
          etag: proposal.etag,
        },
      },
    });
  };

  if (data) {
    const result = oc(data).oldPublishQuote();
    if (proposal && oc(result).etag() !== proposal.etag) {
      const updatedProposal = mapQuotePublishSuccess(proposal, result);
      props.publishProposalSuccess(updatedProposal);
    }
  }

  if (!triedPublishing) {
    return <WarningView dispatchAction={publishProposal} proposalName={proposal.header.name} />;
  } else if (error) {
    let errorMessage;
    let isLongText = false;
    let dialogSize = dialogDimensions;
    let title;
    const errorCode = oc(error).graphQLErrors[0].extensions.code('');
    const termsErrorResult = getGqlTermIdError(error);
    if (termsErrorResult.code && termsErrorResult.message) {
      const invalidTermIds = getAgreementsErrorTerms(
        termsErrorResult.code,
        termsErrorResult.message,
        proposal.lineItems
      );
      const invalidTermNames = invalidTermIds.map(invalidTermId =>
        props.getProductName(invalidTermId)
      );
      errorMessage = getInvalidTermsErrorMessage(
        t('quote::Sorry, the "Publish" action failed.'),
        invalidTermNames,
        t
      );
      dialogSize = termErrorDialogDimensions;
      loggerService.error({
        error: new Error(
          `Failed to publish quoteId ${proposal.id}. Invalid termId for 1 or more of the following: ${invalidTermNames}`
        ),
      });
    } else {
      const leadorgId = oc(proposal).header.extendedProperties.userPreferences.leadOrgId('');
      const agreementType = getAgreementType(
        oc(error).graphQLErrors[0].extensions.exception.agreementType(''),
        t
      );
      errorMessage = getErrorMessage(errorCode, t, classes, leadorgId, agreementType);
      if (React.isValidElement(errorMessage)) {
        dialogSize = alternateDialogDimensions;
        isLongText = true;
        title = t('quote::Publish Action Failed');
      }
      loggerService.error({
        error: new Error(`Failed to publish quoteId ${proposal.id}. Error code: ${errorCode}`),
      });
    }

    return (
      <Fail
        {...dialogSize}
        closeDialog={closeDialog}
        dataAutomationId="generatingAgreementFail"
        isLongText={isLongText}
        message={errorMessage}
        title={title}
      />
    );
  } else if (proposal.header.assignedToGroup === UserGroup.Customer) {
    const leadOrgId = oc(proposal).header.extendedProperties.userPreferences.leadOrgId('');
    const agreementType = leadOrgId ? 'CAPT' : 'MCA';
    loggerService.log({
      name: `Successfully published quoteId ${proposal.id} with ${agreementType}`,
    });
    let message: string | React.ReactElement = t('quote::The quote was successfully published.');
    if (props.onCopyLink && props.onClickEmail) {
      message = (
        <div>
          <TextBody>{t('quote::The quote was successfully published.')}</TextBody>
          <div className={classes.buttonsMargin}>
            <PrimaryButton
              dataAutomationId="quotePublishedEmailButton"
              text={t('quote::Email')}
              onClick={props.onClickEmail}
            />
          </div>
          <div className={classes.buttonsMargin}>
            <PrimaryButton
              dataAutomationId="quotePublishedCopyLinkButton"
              text={t('quote::Copy link')}
              onClick={props.onCopyLink}
            />
          </div>
        </div>
      );
    }
    return (
      <Success
        closeDialog={closeDialog}
        containerPaddingTop={0}
        dataAutomationId="publishSuccess"
        footerAddClass={classes.footerSuccess}
        height={379}
        message={message}
        useSecondaryButton={true}
        width={450}
      />
    );
  } else {
    return (
      <Processing {...dialogDimensions} message1={t('quote::The quote is being published.')} />
    );
  }
};

export const PublishDialogFeature = withStyles(styles)(PublishDialogFeatureUnstyled);

export const PublishDialog = connect(mapStateToProps, dispatchProps)(PublishDialogFeature);
