import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { getGqlTermIdError, getInvalidTermsErrorMessage } from 'features-apollo/quote/components';
import {
  openAgreementPreview,
  openAgreementPreviewError,
} from 'features-apollo/quote/components/Dialogs/ViewAgreementErrorDialog';
import { ModernAgreementDocument, QueryGetModernAgreementPreviewArgs } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContext } from 'styles';

import { useLazyQuery } from '@apollo/react-hooks';

import { GET_MODERN_AGREEMENT_PREVIEW } from './queries';

/**
 * Provides the user experience of getting the agreement preview.
 * - Currently use for term list and summary details pane.
 *
 * @param {string} quoteId Identifies the quote from where the agreement will be generated.
 * @returns {{ getAgreement: () => void; loading: boolean }} callback to get agreement from an event and loading state
 */
export const useAgreementPreviewProvider = (
  quoteId: string
): { getAgreement: () => void; loading: boolean } => {
  const dialogContext = React.useContext(DialogContext);
  const { activeQuote } = React.useContext(ActiveQuoteContext);
  const { t } = useTranslation();
  const [getAgreementPreview, { loading }] = useLazyQuery<
    { getModernAgreementPreview?: ModernAgreementDocument },
    QueryGetModernAgreementPreviewArgs
  >(GET_MODERN_AGREEMENT_PREVIEW, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      openAgreementPreview(dialogContext, data);
    },
    // TODO: jepagan - there is new business logic added after this implementation. will required review
    // Implemented based on business logic pre-GQLMigration. This logic will move to GQL in the future
    onError: error => {
      let customError;
      const termsErrorResult = getGqlTermIdError(error);
      const lineItems = activeQuote ? activeQuote.lineItems : [];
      if (lineItems.length && termsErrorResult.code && termsErrorResult.message) {
        customError = getInvalidTermsErrorMessage(
          t('quote::Sorry, the "View Agreement" action failed.'),
          termsErrorResult.code,
          termsErrorResult.message,
          lineItems,
          t
        );
      }

      openAgreementPreviewError(dialogContext, quoteId, customError);
    },
  });

  const getAgreement = () => {
    //always get latest agreement from gql
    getAgreementPreview({
      variables: { quoteId },
    });
  };

  return { getAgreement, loading };
};
