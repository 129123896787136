import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import withStyles, { WithStyles } from 'react-jss';
import { MarkdownRendererStyles } from './MarkdownRenderer.styles';
import {
  LinkExternal,
  LinkInternal,
  TextTitle,
  InstantFeedbackButtons,
  TextBodySmall,
  LinkEmail,
} from 'components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { dateFormatLl } from 'components/utilities/dates';
import loggerService from 'services/logger-service';
import { useLoadingDebounce } from 'hooks';
import { Spinner, SpinnerSize } from 'components/ions/Spinner';
import { ArticleLoadError } from './ArticleLoadError';

export interface MarkdownRendererProps {
  lasUpdatedDate?: Date;
  markdownUrl: string;
  title: string;
  imageBasePath: string;
}

type Props = MarkdownRendererProps & WithStyles<typeof MarkdownRendererStyles>;

const MarkdownRendererUnstyled: React.FC<Props> = props => {
  const { imageBasePath, markdownUrl, classes, title, lasUpdatedDate } = props;
  const [markdown, setMarkdown] = React.useState<string | undefined>();
  const { t } = useTranslation();
  const [hasError, setHasError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const loadingDebounce = useLoadingDebounce(setIsLoading, 500);

  React.useEffect(() => {
    loadingDebounce(() => fetch(markdownUrl))
      .then(res => res.text())
      .then(md => {
        setMarkdown(md);
      })
      .catch(() => {
        setHasError(true);
        loggerService.log({
          name: `Error downloading article ${markdownUrl}`,
        });
      });
  }, [markdownUrl, setMarkdown, loadingDebounce]);

  const link = (props: any) => {
    if (props.href.match('http')) {
      if (props.href.match('video')) {
        return <LinkInternal displayText={props.children} href={props.href} target="_blank" />;
      }
      return <LinkExternal displayText={props.children} href={props.href} />;
    } else if (props.href.startsWith('mailto:')) {
      return <LinkEmail displayText={props.children} email={props.href.substring(7)} />;
    }
    return <LinkInternal displayText={props.children} href={props.href} />;
  };

  const flatten = (text: any, child: any): string => {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text);
  };

  const heading = (props: any) => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, '');
    const slug = text
      .toString()
      .toLowerCase()
      .replace(/\W/g, '-');
    return React.createElement('h' + props.level, { id: slug }, props.children);
  };

  const lastUpdatedDateText = t('Last updated: {{date}}', {
    date: moment(lasUpdatedDate).format(dateFormatLl),
  });

  const getContent = () => {
    if (hasError) {
      return <ArticleLoadError />;
    }

    if (isLoading) {
      return <Spinner size={SpinnerSize.large} />;
    }

    return (
      <ReactMarkdown
        includeNodeIndex={true}
        renderers={{
          link,
          heading,
        }}
        source={markdown}
        transformImageUri={uri =>
          uri.startsWith('http') || !imageBasePath ? uri : `${imageBasePath}/${uri}`
        }
      />
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleBlock}>
          <TextTitle addClass={classes.title} title={title}>
            {title}
          </TextTitle>
          {lasUpdatedDate && (
            <TextBodySmall addClass={classes.date} title={lastUpdatedDateText}>
              {lastUpdatedDateText}
            </TextBodySmall>
          )}
        </div>
        <InstantFeedbackButtons
          actions={{
            onThumbsUpClick: isReset => {
              loggerService.log(
                { name: `Help article ${isReset ? 'like reset' : 'liked'}` },
                { title }
              );
            },
            onThumbsDownClick: isReset => {
              loggerService.log(
                { name: `Help article ${isReset ? 'dislike reset' : 'disliked'}` },
                { title }
              );
            },
          }}
          className={classes.feedbackButtons}
        />
      </div>
      {getContent()}
    </div>
  );
};

export const MarkdownRenderer = withStyles(MarkdownRendererStyles)(MarkdownRendererUnstyled);
