import { dateFormat, isDate } from 'components/utilities/dates';
import { SkuTitleMap } from 'features/proposal/types';
import { displayInDayFormatAndUseMarketTimezone } from 'features/proposal/utils';
import moment from 'moment-timezone';
import { IComboBoxOption } from 'office-ui-fabric-react';
import { LineItem } from 'services/proposal/types';
import { t } from 'services/utils';
import { oc } from 'ts-optchain';

import { DiscountCardDropdownOptions, DiscountType, MonthPoint, Timezones } from './types';

export const selectOption = (
  key: string,
  options: IComboBoxOption[]
): DiscountCardDropdownOptions => ({
  options,
  selectedKey: key,
});

export const addDropdownItem = (
  key: string,
  text: string,
  dropdownOptions: DiscountCardDropdownOptions
) => {
  const itemToAdd: IComboBoxOption = { text, key };
  const newOptions = dropdownOptions.options.concat(itemToAdd);

  return newOptions;
};

export const addAndSelectTheKey = (
  key: string,
  text: string,
  dropdownOptions: DiscountCardDropdownOptions
) => {
  const alreadyExists: boolean = dropdownOptions.options.some(
    (option: IComboBoxOption) => option.key === key
  );
  const newOptions = alreadyExists
    ? dropdownOptions.options
    : addDropdownItem(key, text, dropdownOptions);
  return { ...dropdownOptions, options: newOptions, selectedKey: key };
};

export const formatDropdownDates = (date: moment.Moment, dateFormat: string) => {
  return { key: date.format(dateFormat), text: date.format(dateFormat) };
};

export const addAndSelectDateOption = (
  date: moment.Moment,
  dropdownOptions: DiscountCardDropdownOptions
) => {
  const { key, text } = formatDropdownDates(date, dateFormat);
  return addAndSelectTheKey(key, text, dropdownOptions);
};

export const isDuration = (value: string): boolean =>
  !!(value.match(/P(\d+)M/) && value.match.length);

export const ruleTypeToDiscountType: Record<string, DiscountType> = {
  onetime: 'One Time',
  discount: 'Future',
  priceguarantee: 'Ceiling',
};

export const createMomentObjectFromFabricCalendar = (
  value: string,
  dayPicker: boolean,
  monthPoint: MonthPoint
) => {
  if (dayPicker) {
    return moment(value);
  }
  return monthPoint === MonthPoint.BEGINNING
    ? moment(value).startOf('month')
    : moment(value).endOf('month');
};

const adjustEndDateWhenEndDateIsDuration = (
  startDate: moment.Moment,
  duration: string,
  endDateDropdownOptions: DiscountCardDropdownOptions,
  isMonth: boolean
): DiscountCardDropdownOptions => {
  const momentDuration = moment.duration(duration);
  if (isMonth) {
    const endOfMonth = startDate
      .clone()
      .add(momentDuration.asMonths() - 1, 'months')
      .endOf('months');
    return addAndSelectDateOption(endOfMonth, endDateDropdownOptions);
  } else {
    const newSelectedEndDate = startDate.clone().add(momentDuration.asMonths(), 'months');
    return addAndSelectDateOption(newSelectedEndDate, endDateDropdownOptions);
  }
};

export const disableDatesBefore = (
  discountCardDropdownOptions: DiscountCardDropdownOptions,
  thresholdDate: moment.Moment
) => {
  const disabledOptions = discountCardDropdownOptions.options.map(
    (comboboxOption: IComboBoxOption) => {
      const key = comboboxOption.key as string;
      if (isDate(key)) {
        const optionDate = moment(key);
        if (optionDate.isBefore(thresholdDate)) {
          return { ...comboboxOption, disabled: true };
        }
      }
      return comboboxOption;
    }
  );
  return {
    options: disabledOptions,
    selectedKey: discountCardDropdownOptions.selectedKey,
  };
};

const adjustEndDateWhenStartDateIsSameOrAfterEndDate = (
  endDateDropdownOptions: DiscountCardDropdownOptions
) => {
  return addAndSelectTheKey('Select a duration', 'Select a duration', endDateDropdownOptions);
};

export const adjustEndDate = (
  startDate: moment.Moment,
  endDateDropdownOptions: DiscountCardDropdownOptions,
  isMonth: boolean
) => {
  const currentEnd = endDateDropdownOptions.selectedKey;

  if (isDuration(currentEnd)) {
    return adjustEndDateWhenEndDateIsDuration(
      startDate,
      currentEnd,
      endDateDropdownOptions,
      isMonth
    );
  }
  if (isDate(currentEnd)) {
    const endDate = moment(currentEnd);
    if (startDate.isSameOrAfter(endDate)) {
      return adjustEndDateWhenStartDateIsSameOrAfterEndDate(endDateDropdownOptions);
    }
  }
  return endDateDropdownOptions;
};

export const resetStartDate = (startDateOptions: DiscountCardDropdownOptions) => {
  return selectOption('order placement', startDateOptions.options);
};

export const resetEndDate = (endDateOptions: DiscountCardDropdownOptions) =>
  selectOption('P18M', endDateOptions.options);
export const removeAllDates = (
  dropdownOptions: DiscountCardDropdownOptions
): DiscountCardDropdownOptions => {
  const removedOptions = dropdownOptions.options.filter(
    (option: IComboBoxOption) => typeof option.key === 'string' && !isDate(option.key)
  );
  return { options: removedOptions, selectedKey: dropdownOptions.selectedKey };
};

export const computeEndDatesForCalendar = (
  startOptions: DiscountCardDropdownOptions,
  endOptions: DiscountCardDropdownOptions
) => {
  const startDate = startOptions.selectedKey;
  if (!isDate(startDate)) {
    return { selected: moment().toDate() };
  }
  const momentStartDate = moment(startDate);
  const momentMinDate = momentStartDate.clone().add(1, 'days');
  return {
    minDate: momentMinDate.toDate(),
    selected: moment(endOptions.selectedKey).toDate(),
  };
};

export const disableDatesAfter = (
  discountCardDropdownOptions: DiscountCardDropdownOptions,
  thresholdDate: moment.Moment
): DiscountCardDropdownOptions => {
  const disabledOptions = discountCardDropdownOptions.options.map(
    (comboboxOption: IComboBoxOption) => {
      const key = comboboxOption.key as string;
      if (isDate(key)) {
        const optionDate = moment(key);
        if (optionDate.isAfter(thresholdDate)) {
          return { ...comboboxOption, disabled: true };
        }
      }
      return comboboxOption;
    }
  );
  return {
    options: disabledOptions,
    selectedKey: discountCardDropdownOptions.selectedKey,
  };
};

export const computeEndOptions = (start: string, endOptions: DiscountCardDropdownOptions) => {
  if (isDate(start)) {
    const startDate = moment(start);
    const newEndOptions = disableDatesBefore(endOptions, startDate.clone().add(1, 'days'));
    return newEndOptions;
  } else {
    return removeAllDates(endOptions);
  }
};

export const sortDropdownOptions = (
  dropdownOptions: DiscountCardDropdownOptions
): DiscountCardDropdownOptions => {
  const durations = dropdownOptions.options.filter((comboboxOption: IComboBoxOption) => {
    const option = comboboxOption.key as string;
    return isDuration(option);
  });

  const dates = dropdownOptions.options.filter((comboboxOption: IComboBoxOption) => {
    const option = comboboxOption.key as string;
    return isDate(option);
  });

  const rest = dropdownOptions.options.filter((comboboxOption: IComboBoxOption) => {
    const option = comboboxOption.key as string;
    return !isDuration(option) && !isDate(option);
  });
  dates.sort((option1: IComboBoxOption, option2: IComboBoxOption) =>
    option1.text.localeCompare(option2.text)
  );
  const newOptions = [...rest, ...durations, ...dates];
  return {
    options: newOptions,
    selectedKey: dropdownOptions.selectedKey,
  };
};

export const computeInitialStartAndEndDates = (
  startDate: string,
  endDate: string,
  timezones: Timezones,
  productType: string,
  action?: string
): { start: moment.Moment; end: moment.Moment } => {
  if (isDate(endDate)) {
    const newStartDate = moment.tz(startDate, timezones.first);
    const newEndDate = moment.tz(endDate, timezones.last);
    return { start: newStartDate, end: newEndDate };
  } else {
    const momentStartDate = moment.tz(startDate, timezones.first);
    const durationInMonths = moment.duration(endDate).asMonths();
    if (displayInDayFormatAndUseMarketTimezone(productType, action)) {
      const newEndDate = momentStartDate
        .clone()
        .add(durationInMonths, 'months')
        .endOf('day')
        .utc();
      return { start: momentStartDate, end: newEndDate };
    } else {
      const newEndDate = momentStartDate
        .clone()
        .add(durationInMonths - 1, 'months')
        .endOf('month')
        .utc();
      return { start: moment(startDate), end: newEndDate };
    }
  }
};

export const computePriceCeilingDate = (timezones: Timezones, ceilingDate?: string) => {
  if (ceilingDate && isDate(ceilingDate)) {
    return moment.tz(ceilingDate, timezones.first);
  }
};

export const errors = {
  smallerThanMin: t('error::Has to be greater than 0'),
  ceilingSmallerThanMin: t('error::Cannot be negative'),
  valueIsNotANumber: t('error::Only numbers are valid'),
  largerThanMax: t('error::Cannot be greater than 100'),
  isEmpty: t('error::Cannot be empty'),
};

const smallerThanMin = (discountAmount: string, discountType: DiscountType) => {
  if ((discountType === 'One Time' || discountType === 'Future') && +discountAmount <= 0) {
    return errors.smallerThanMin;
  } else if (+discountAmount < 0) {
    return errors.ceilingSmallerThanMin;
  }
};

const largerThanMax = (discountAmount: string) => {
  if (+discountAmount > 100) {
    return errors.largerThanMax;
  }
};

const hasScientificNotationSymbol = (discountAmount: string) => {
  if (discountAmount.toLowerCase().includes('e')) {
    return errors.valueIsNotANumber;
  }
};

const isEmpty = (discountAmount: string) => {
  if (!discountAmount.trim()) {
    return errors.isEmpty;
  }
};

const hasNonNumbers = (discountAmount: string, isBulkDiscount?: boolean) => {
  if (!isBulkDiscount && isNaN(+discountAmount)) {
    return errors.valueIsNotANumber;
  }
};

export const getDiscountError = (
  discountAmount: string,
  discountType: DiscountType,
  isBulkDiscount?: boolean
): string | undefined => {
  const error =
    isBulkDiscount && discountAmount === '(mixed)'
      ? undefined
      : isEmpty(discountAmount) ||
        hasNonNumbers(discountAmount) ||
        hasScientificNotationSymbol(discountAmount) ||
        smallerThanMin(discountAmount, discountType) ||
        largerThanMax(discountAmount);
  return error && error.value;
};

const buildSharedDiscountConditions = (
  skuStrings: string[],
  lineItem: LineItem,
  skuTitles: SkuTitleMap,
  discountProductId: string,
  discountSkuId?: string
) => {
  const action = oc(lineItem).pricingInstruction.productIdentifier.action();
  const conditions = oc(lineItem).pricingInstruction.conditions();
  if (conditions && action) {
    const locationCondition = conditions.find(
      condition =>
        oc(condition)
          .name('')
          .toLocaleLowerCase() === 'location'
    );
    const durationCondition = conditions.find(
      condition =>
        oc(condition)
          .name('')
          .toLocaleLowerCase() === 'termunits'
    );
    const location = (locationCondition && locationCondition.value.toString()) || 'All Locations';
    const duration =
      (durationCondition && moment.duration(durationCondition.value.toString()).humanize()) ||
      action === 'purchase'
        ? 'All Reservations'
        : 'Pay go';
    skuStrings.push(location);
    skuStrings.push(duration);
  }
  if (discountSkuId) {
    skuTitles[discountProductId] &&
      skuTitles[discountProductId].forEach(sku => {
        if (sku.sku === discountSkuId) skuStrings.push(sku.title);
      });
  }
  return skuStrings;
};

export const buildSkuStrings = (lineItem: LineItem, skuTitles: SkuTitleMap) => {
  const skuStrings: string[] = [];
  const discountProductId = oc(lineItem).pricingInstruction.productIdentifier.productId();
  const discountSkuId = oc(lineItem).pricingInstruction.productIdentifier.skuId();
  const isSharedDiscount = oc(lineItem).pricingInstruction.priceAdjustmentType() === 'extend';
  const isProductFamily = oc(lineItem).pricingInstruction.applicability() === 'productFamily';
  if (discountProductId && !isProductFamily) {
    if (isSharedDiscount) {
      return buildSharedDiscountConditions(
        skuStrings,
        lineItem,
        skuTitles,
        discountProductId,
        discountSkuId
      );
    }
    if (discountSkuId) {
      skuTitles[discountProductId] &&
        skuTitles[discountProductId].forEach(sku => {
          sku.sku === discountSkuId && skuStrings.push(sku.title);
        });
    } else {
      const userPreferencesDuration = oc(lineItem).extendedProperties.userPreferences.duration();
      const userPreferencesLocation = oc(lineItem).extendedProperties.userPreferences.location();
      const userPreferencesSkuCount = oc(lineItem).extendedProperties.userPreferences.skuCount();

      if (userPreferencesDuration) {
        skuStrings.push(userPreferencesDuration);
      }
      if (userPreferencesLocation) {
        skuStrings.push(userPreferencesLocation);
      }
      if (userPreferencesSkuCount) {
        skuStrings.push(`${userPreferencesSkuCount} Skus`);
      }
    }
  }
  return skuStrings;
};
