import React from 'react';

/**
 * Add right-side padding when scrollbar is present.
 *
 * @param maxHeight height before the scrollbar starts to show
 */
export const useScrollPadding = (maxHeight: number) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  React.useLayoutEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollHeight > maxHeight
        ? (ref.current.style.paddingRight = '12px')
        : (ref.current.style.paddingRight = 'unset');
    }
  }, [ref, maxHeight]);

  return ref;
};
