import { ThemeProps } from 'styles';

export const vatIdStyles = (theme: ThemeProps) => {
  return {
    listItem: {
      display: 'flex',
      paddingBottom: 16,
    },
    icon: {
      color: theme.palette.secondary,
      position: 'absolute',
      fontSize: '26px',
    },
    index: {
      color: theme.palette.secondary,
      position: 'absolute',
      left: 10,
      top: 3,
    },
    taskIndex: {
      position: 'relative',
    },
    errorMessage: {
      width: 440,
    },
    message: {
      paddingLeft: 38,
      verticalAlign: 'sub',
      color: theme.palette.textTertiary,
    },
    verificationField: {
      paddingLeft: 38,
    },
  };
};
