import React from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'routes';
import { ThemeProps } from 'styles';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';

import FAQ from './FAQ';
import New from './New';
import Resources from './Resources';
import Start from './Start';
import Videos from './Videos';

const styles = (theme: ThemeProps) => ({
  div: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    paddingTop: 30,
    paddingLeft: 10,
    borderBottom: `2px solid ${theme.palette.backgroundDivider}`,
    zIndex: 1,
    boxShadow: `0 -2px 9px ${theme.palette.shadowColor}`,
  },
  navLink: {
    display: 'inline-block',
    textDecoration: 'none',
    position: 'relative',
    padding: '8px 16px',
    color: theme.palette.textTertiary,
    fontSize: theme.fonts.fontSizes.medium,

    '&.active, &:hover': {
      color: theme.palette.text,
    },
    '&.active': {
      fontWeight: 'bold',
    },

    '&:after': {
      content: '""',
      backgroundColor: theme.palette.textLink,
      height: 2,
      position: 'absolute',
      bottom: 0,
      transition: 'all 0.2s ease-in-out 0s',

      left: '100%',
      right: 0,
    },

    '&.active ~ a:after': {
      left: 0,
      right: '100%',
    },

    '&.active:after': {
      left: 16,
      right: 16,
      transition: 'all 0.2s ease-in-out 0.2s',
    },
  },
});

type Props = {} & WithTranslation & WithStyles<typeof styles>;

const Help: React.FC<Props> = ({ classes }: Props) => {
  useHelpContent(HelpContent.Help);
  const { t } = useTranslation();
  return (
    <div className={classes.div}>
      <nav className={classes.nav}>
        <NavLink className={classes.navLink} to={routes.home.help.new}>
          {t("help::What's New")}
        </NavLink>
        <NavLink className={classes.navLink} to={routes.home.help.start}>
          {t('help::Getting Started')}
        </NavLink>
        <NavLink className={classes.navLink} to={routes.home.help.videos}>
          {t('help::Videos')}
        </NavLink>
        <NavLink className={classes.navLink} to={routes.home.help.faq}>
          {t('help::FAQ')}
        </NavLink>
        <NavLink className={classes.navLink} to={routes.home.help.resources}>
          {t('help::Other Resources')}
        </NavLink>
      </nav>

      <Switch>
        <Route
          exact
          path={routes.home.help.root}
          render={() => <Redirect to={routes.home.help.new} />}
        />
        <Route component={New} exact path={routes.home.help.new} />
        <Route component={Start} exact path={routes.home.help.start} />
        <Route component={Videos} exact path={routes.home.help.videos} />
        <Route component={FAQ} exact path={routes.home.help.faq} />
        <Route component={Resources} exact path={routes.home.help.resources} />
        <Route render={() => <Redirect to={routes.home.help.root} />} />
      </Switch>
    </div>
  );
};

export default withTranslation('help')(withStyles(styles)(Help));
