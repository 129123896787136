import {
  mergeClassNames,
  TextBodyLarge,
  TextBodySmall,
  TextBodyXLarge,
  TextTitleSecondary,
} from 'components';
import { formatPrice } from 'features-apollo/formatters';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { sharedStyles, ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

export interface SummaryDetailsPaneHeaderProps {
  /**
   * Customer's annual deal estimate set in the quote.
   */
  annualDealEstimate?: {
    amount: number;
    currency: string;
  };
  /**
   * The optional way to identify the type of quote. Will only display if annual estimate is undefined.
   */
  quoteLabel?: string;
  /**
   * Quote's name
   */
  quoteName: string;
  /**
   * Name of the customer or participants in the quote
   */
  customerOrparticipantsNames: string;
  /**
   * Custom styling
   */
  styles?: {
    container?: string;
  };
}

const styles = (theme: ThemeProps) => {
  return {
    container: {
      padding: '20px 30px',
      background: theme.palette.backgroundStandout,
      display: 'flex',
      justifyContent: 'space-between',
    },
    quoteLabel: { color: theme.palette.textTertiary },
    quoteName: {
      ...sharedStyles(theme).textOverflow,
      fontSize: theme.fonts.fontSizes.xLarge,
      whiteSpace: 'nowrap',
    },
    customerOrparticipantsNames: {
      ...sharedStyles(theme).textOverflow,
      paddingTop: 4,
    },
    annualDealEstimateAmountContainer: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'flex-end',
    },
    annualDealEstimateAmount: {
      paddingRight: 4,
      color: theme.palette.approveText,
    },
    annualDealEstimateLabel: {
      paddingTop: 4,
      color: theme.palette.textTertiary,
    },
    namesContainer: (props: SummaryDetailsPaneHeaderProps) => ({
      maxWidth: props.annualDealEstimate ? 344 : 480,
    }),
  };
};

type Props = SummaryDetailsPaneHeaderProps & WithStyles<typeof styles>;

const SummaryDetailsPaneHeaderUnstyled: React.FC<Props> = props => {
  const { classes, styles } = props;
  const { t } = useTranslation();

  const annualDealEstimate = props.annualDealEstimate ? (
    <div>
      <div className={classes.annualDealEstimateAmountContainer}>
        <TextBodyXLarge addClass={classes.annualDealEstimateAmount} emphasized>
          {formatPrice(props.annualDealEstimate.amount, props.annualDealEstimate.currency, {
            minimumFractionDigits: 0,
          })}
        </TextBodyXLarge>
        <TextBodyLarge emphasized>({props.annualDealEstimate.currency})</TextBodyLarge>
      </div>
      <TextBodySmall addClass={classes.annualDealEstimateLabel}>
        {t('quote::Annual deal estimate')}
      </TextBodySmall>
    </div>
  ) : null;

  const quoteLabel = props.quoteLabel && (
    <TextBodyXLarge addClass={classes.quoteLabel}>{props.quoteLabel}</TextBodyXLarge>
  );

  return (
    <header className={mergeClassNames([classes.container, oc(styles).container()])}>
      <div className={classes.namesContainer}>
        <TextTitleSecondary addClass={classes.quoteName}>{props.quoteName}</TextTitleSecondary>
        <TextBodySmall addClass={classes.customerOrparticipantsNames}>
          {props.customerOrparticipantsNames}
        </TextBodySmall>
      </div>
      {annualDealEstimate || quoteLabel}
    </header>
  );
};

export const SummaryDetailsPaneHeader = withStyles(styles)(SummaryDetailsPaneHeaderUnstyled);
