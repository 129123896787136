import { PrimaryButton, SecondaryButton, TextBody, Tile, Tiles } from 'components';
import { OrgWizardAddress } from 'features-apollo/quote/components/Wizards/OrganizationWizard';
import { Address as AddressComponent } from 'features/components/Address';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { WizardDialog } from '../Shared';
import { similarOrgFoundDialogStyles } from './SimilarOrgFoundDialog.styles';

const tileProps = {
  id: 'Tile-Found',
  maxWidth: '100%',
  tileWidth: '250px',
};

export interface SimilarOrgFoundDialogProps {
  createdOrganization: {
    address: OrgWizardAddress;
    accountName: string;
    orgName: string;
    tradeName?: string;
  };
  foundOrganization: {
    address: OrgWizardAddress;
    accountName: string;
    orgName: string;
    tradeName?: string;
  };
  headline: string;
  onClose: () => void;
  onCreateClick: () => void;
  onSelectClick: () => void;
}

type Props = SimilarOrgFoundDialogProps & WithStyles<typeof similarOrgFoundDialogStyles>;

const SimilarOrgFoundDialogUnstyled: React.FC<Props> = props => {
  const { classes, createdOrganization, foundOrganization } = props;
  const { t } = useTranslation();

  const foundContent: React.ReactElement = (
    <div className={classes.tileContent}>
      <AddressComponent
        address={{ ...foundOrganization.address, companyName: foundOrganization.orgName }}
        addTradeNameClass={classes.tradeName}
        dataAutomationId="foundOrganizationAddress"
        headline={foundOrganization.accountName}
        showAccountName
        showCompanyName
        tradeName={foundOrganization.tradeName}
      />
    </div>
  );

  const createdContent: React.ReactElement = (
    <div className={classes.tileContent}>
      <AddressComponent
        address={{ ...createdOrganization.address, companyName: createdOrganization.orgName }}
        addTradeNameClass={classes.tradeName}
        dataAutomationId="createdOrganizationAddress"
        headline={createdOrganization.accountName}
        showAccountName
        showCompanyName
        tradeName={createdOrganization.tradeName}
      />
    </div>
  );

  const foundTile: Tile[] = [{ title: '', content: foundContent }];
  const createdTile: Tile[] = [{ title: '', content: createdContent }];

  const body = (
    <div>
      <TextBody addClass={classes.text}>
        {t(
          'quote::Based on the information entered, we found a billing account that looks similar to the new billing account you are creating. Please verify whether the billing account we found is correct before creating a new billing account.'
        )}
      </TextBody>
      <div className={classes.spacerLarge} />
      <div className={classes.containerFlexTiles}>
        <TextBody addClass={classes.emphasize}>{t('quote::Billing account we found:')}</TextBody>
        <TextBody addClass={classes.tileRightHeadingOffset}>
          {t('quote::Billing account you are creating:')}
        </TextBody>
      </div>
      <div className={classes.spacerMedium} />
      <div className={classes.containerFlexTiles}>
        <Tiles
          {...tileProps}
          addClass={`${classes.tileBackground} ${classes.itemsFlexTiles}`}
          hasBorder
          tile={foundTile}
        />
        <div className={classes.spaceFlex} />
        <div className={classes.separatorFlex} />
        <Tiles {...tileProps} addClass={classes.itemsFlexTiles} hasBorder tile={createdTile} />
      </div>
    </div>
  );

  const footerButtons = [
    <SecondaryButton
      dataAutomationId="similarOrgFoundCreateNewBillingAccount"
      key="CreateNewOrg"
      text={i18next.t('quote::Create new billing account')}
      onClick={props.onCreateClick}
    />,
    <PrimaryButton
      dataAutomationId="similarOrgFoundUseExistingBillingAccount"
      key="UseExistingOrg"
      text={i18next.t('quote::Use existing billing account')}
      onClick={props.onSelectClick}
    />,
  ];

  return (
    <WizardDialog
      body={body}
      closeDialog={props.onClose}
      footer={footerButtons}
      headline={props.headline}
      isOverviewAvailable={true}
      overviewButtonOnly={false}
      title={t('quote::Similar billing account found')}
    />
  );
};

export const SimilarOrgFoundDialog = withStyles(similarOrgFoundDialogStyles)(
  SimilarOrgFoundDialogUnstyled
);
