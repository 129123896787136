import { ThemeProps } from 'styles';

export const styles = (theme: ThemeProps) => {
  const { fonts } = theme;
  return {
    wrapperStyle: {
      paddingBottom: 12,
      maxWidth: 591,
      fontSize: fonts.fontSizes.medium,
      textAlign: 'left',
    },
    iconStyles: {
      paddingLeft: 4,
      fontSize: fonts.fontSizes.large,
      transform: 'translateY(3px)',
    },
    container: {
      padding: '32px',
    },
  };
};
