import { Carousel as CarouselMolecule, Slide } from 'components/molecules/Carousel';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface CarouselProps {
  slides: Slide[];
  initialSlideId?: string;
  sequencedNavigation?: boolean;
}

export const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const { slides, initialSlideId, sequencedNavigation } = props;
  const { t } = useTranslation();
  const [slideIndex, setSlideIndex] = React.useState<number | undefined>(() => {
    return slides.findIndex(slide => slide.id === initialSlideId);
  });
  const stepText = t('Step {{currentSlideNumber}} of {{totalSlideCount}}', {
    currentSlideNumber: slideIndex !== undefined ? slideIndex + 1 : -1,
    totalSlideCount: slides.length,
  });

  const onSlideNavigation = (index: number) => {
    setSlideIndex(index);
  };

  return (
    <CarouselMolecule
      initialSlideId={initialSlideId}
      sequencedNavigation={sequencedNavigation}
      slides={slides}
      strings={{
        backButton: t('back'),
        nextButton: t('next'),
        stepInformation: stepText,
        backButtonAriaLabel: t('Previous step'),
        nextButtonAriaLabel: t('Next step'),
      }}
      onSlideNavigate={onSlideNavigation}
    />
  );
};
