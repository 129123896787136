import { convertAddressToAddressType } from 'features-apollo/quote/components/PropertySheets/Customer/utils';
import { TenantScenario } from 'features-apollo/quote/components/Wizards';
import { Address as AddressIon } from 'features/components/Address';
import { OrganizationSimple, QuoteMutationInput } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { oc } from 'ts-optchain';

import {
  OrganizationInformationErrorMessage,
  OrganizationInformationHeader,
  OrganizationInformationLanguage,
} from '../../../states/OrganizationInformation/content';
import { organizationInformationForJioCspStyles } from '../OrganizationInformationForJioCsp/OrganizationInformationForJioCsp.styles';
import { OrganizationInformationTenantForJioCsp } from './OrganizationInformationTenantForJioCsp';

export interface OrganizationInformationForJioCspProps {
  quoteMutationInput?: QuoteMutationInput;
  organization: OrganizationSimple;
  onRemove?: () => void;
}

type Props = OrganizationInformationForJioCspProps &
  WithStyles<typeof organizationInformationForJioCspStyles>;

const OrganizationInformationForJioCspUnstyled: React.FC<Props> = props => {
  const { classes, organization, quoteMutationInput } = props;
  const { t } = useTranslation();
  const { address } = organization;
  const header = (
    <OrganizationInformationHeader
      companyName={oc(address).companyName()}
      customLabels={{ remove: t('quote::Remove partner') }}
      isQuoteLegacy={false}
      menuButtonAriaLabel={t(`quote::Opens menu options for partner's billing account`)}
      menuOptions={['remove']}
      organization={organization}
      organizationEditable={false}
      specificScenario={TenantScenario.partner}
      onRemove={props.onRemove}
    />
  );

  const errorMessage = (
    <OrganizationInformationErrorMessage
      organizationAddress={address}
      organizationEditable={false}
    />
  );

  const invoiceLanguage = (
    <div className={classes.sectionSpacing}>
      <OrganizationInformationLanguage organizationLanguage={organization.languageInfo} />
    </div>
  );

  const tenantInformation = (
    <div className={classes.sectionSpacing}>
      <OrganizationInformationTenantForJioCsp
        accountTenants={organization.account.tenants}
        customerTag={organization.customerTag}
        quoteMutationInput={quoteMutationInput}
      />
    </div>
  );

  return (
    <>
      {header}
      {errorMessage}
      <AddressIon
        addClass={classes.font}
        address={convertAddressToAddressType(address)}
        dataAutomationId="organizationAddress"
      />

      {invoiceLanguage}
      {tenantInformation}
    </>
  );
};

export const OrganizationInformationForJioCsp = withStyles(organizationInformationForJioCspStyles)(
  OrganizationInformationForJioCspUnstyled
);
