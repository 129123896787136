import { mergeClassNames } from 'components/utilities';
import { Toggle } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export interface ToggleAtomProps {
  addClass?: string;
  checked?: boolean;
  dataAutomationId?: string;
  inlineLabel: boolean;
  label: string | JSX.Element;
  title?: string;
  onChange: (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => void;
}

// TODO: some of the readonly states are not styled yet, we don't have a use case for them too currently, we can add when we need
const styles = (theme: ThemeProps) => {
  const palette = theme.palette;
  return {
    checkedPill: {
      background: palette.primary,
    },
    uncheckedPill: {
      background: 'transparent',
      borderColor: palette.textTertiary,
    },
    checkedThumb: {
      background: palette.lightColor,
    },
    uncheckedThumb: {
      background: palette.textTertiary,
    },
    rootUnchecked: {
      '&:hover .ms-Toggle-thumb': {
        background: palette.text,
      },
    },
    toggle: {
      '& label:hover': {
        cursor: 'pointer',
      },
    },
  };
};

export type Props = ToggleAtomProps & WithStyles<typeof styles>;

export const ToggleAtomUnstyled = (props: Props) => {
  const { classes } = props;

  return (
    <Toggle
      {...props}
      className={mergeClassNames([classes.toggle, props.addClass])}
      data-automation-id={props.dataAutomationId}
      styles={{
        pill: props.checked ? classes.checkedPill : classes.uncheckedPill,
        thumb: props.checked ? classes.checkedThumb : classes.uncheckedThumb,
        root: props.checked ? undefined : classes.rootUnchecked,
      }}
    />
  );
};

export const ToggleAtom = withStyles(styles)(ToggleAtomUnstyled) as React.FC<ToggleAtomProps>;
