import { ThemeProps } from 'styles';

export const commandButtonAtomStyles = (theme: ThemeProps) => {
  return {
    command: {
      backgroundColor: 'transparent',
      borderStyle: 'none',
      height: '100%',
      fontSize: theme.fonts.fontSizes.medium,
      fontWeight: theme.fonts.fontWeights.regular,
      paddingLeft: 12,
      paddingRight: 12,
      '& i': {
        fontSize: theme.fonts.fontSizes.large,
      },
      '& .ms-Button-label': {
        fontWeight: theme.fonts.fontWeights.regular,
      },
      '&:disabled': {
        '& i': {
          color: theme.palette.textDisabled,
        },
      },
    },
  };
};
