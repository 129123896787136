import {
  Dialog,
  LinkEmail,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextBodyXLarge,
} from 'components';
import { meplaHistory } from 'createHistory';
import { Fail, Processing } from 'features/components/dialogs';
import { upgradeQuoteAsync } from 'features/proposal/actions';
import { getActiveProposal, upgradingQuote } from 'features/proposal/selectors';
import { quoteVersionToUpgradeTo } from 'features/proposal/utils';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { routes } from 'routes';
import loggerService from 'services/logger-service';
import { RootState } from 'store/types';
import { DialogContext, DialogProps, ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { NewProposalDialog } from '../NewDialog';

const styles = (theme: ThemeProps) => ({
  errorHeader: {
    display: 'block',
    fontWeight: theme.fonts.fontWeights.semiBold,
    paddingBottom: 16,
  },
  message: { '& p:first-of-type': { marginTop: 0 } },
});

const dimensions = {
  height: 301,
  width: 457,
};

const mapStateToProps = (state: RootState) => {
  const quote = getActiveProposal(state);

  return {
    quoteId: oc(quote).id(''),
    quoteLatestVersion: oc(quote).header.modifiedApiVersion(''),
    upgrading: upgradingQuote(state),
  };
};

const dispatchProps = {
  upgradeQuote: upgradeQuoteAsync.request,
};

export type IncompatibilityDialogProps = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps & { fixtureMode?: boolean };

type Props = IncompatibilityDialogProps & WithStyles<typeof styles>;

export const IncompatibilityDialogUnstyled: React.FC<Props> = props => {
  const { classes, quoteLatestVersion } = props;
  const { t } = useTranslation();
  const context = React.useContext(DialogContext);
  const [createNewQuote, setCreateNewDialog] = React.useState<boolean>(false);
  const [upgradingQuote, setUpgradingQuote] = React.useState<boolean>(false); // This prevent to show error from state if is the first render

  const onBackHomeClick = () => {
    meplaHistory.push(routes.home.root);
    context.closeDialog();
  };

  const returnHomeButtonProps = {
    text: t('quote::Return home'),
    onClick: onBackHomeClick,
  };

  const footerButtons = [
    <PrimaryButton
      dataAutomationId="accessQuoteButton"
      key="upgrade-quote"
      text={t('quote::Access quote')}
      onClick={() => {
        props.upgradeQuote(props.quoteId);
        setUpgradingQuote(true);
        loggerService.log({
          name: `Upgrading quote: ${props.quoteId}. From version ${quoteLatestVersion} to version ${quoteVersionToUpgradeTo}`,
        });
      }}
    />,
    <SecondaryButton
      {...returnHomeButtonProps}
      dataAutomationId="returnHomePageButton"
      key="return-home-page"
    />,
  ];

  React.useEffect(() => {
    if (quoteLatestVersion === quoteVersionToUpgradeTo) {
      context.closeDialog();
    }
  }, [context, quoteLatestVersion]);

  if (createNewQuote) {
    return <NewProposalDialog />;
  } else if (props.upgrading.loading) {
    return <Processing {...dimensions} message1={t('quote::Upgrading quote...')} />;
  } else if (props.upgrading.error && upgradingQuote) {
    const footerButtons = [
      <PrimaryButton
        {...returnHomeButtonProps}
        dataAutomationId="returnHomeButton"
        key="return-home-page"
      />,
      <SecondaryButton
        dataAutomationId="newQuoteButton"
        key="create-new-quote"
        text={t('quote::New quote')}
        onClick={() => setCreateNewDialog(true)}
      />,
    ];

    const message = (
      <>
        <TextBodyXLarge addClass={classes.errorHeader}>
          {t('quote::Error during quote migration')}
        </TextBodyXLarge>
        <TextBody>
          <Trans ns="quote">
            We are sorry, but our service ran into an error during the migration to our new service.
            You can reach out for support at <LinkEmail email="QCSupport@microsoft.com" />, or
            recreate the quote from new.
          </Trans>
        </TextBody>
      </>
    );

    return (
      <Fail
        closeDialog={onBackHomeClick}
        footerButtons={footerButtons}
        height={344}
        message={message}
        width={450}
      />
    );
  }

  return (
    <Dialog
      {...dimensions}
      dataAutomationId="incompatibilityDialog"
      disableFocusTrapZone={props.fixtureMode}
      footerButtons={footerButtons}
      hideCloseButton
      title={t('quote::More customer information needed')}
    >
      <div className={classes.message}>
        <p>
          <TextBody>
            {t(
              'quote::Because this quote was created before the recent updates, additional information is needed. This quote needs to have a billing account created, a credit line re-established and will need to go through the approval process again.'
            )}
          </TextBody>
        </p>
        <TextBody>
          <Trans ns="quote">
            If you need any help to expedite this process, please reach out to{' '}
            <LinkEmail email="QCSupport@microsoft.com" />.
          </Trans>
        </TextBody>
      </div>
    </Dialog>
  );
};

export const IncompatibilityDialogStyled = withStyles(styles)(
  IncompatibilityDialogUnstyled
) as React.FC<IncompatibilityDialogProps>;

export const IncompatibilityDialog = connect(
  mapStateToProps,
  dispatchProps
)(IncompatibilityDialogStyled);

export const openIncompatibilityDialog = (context: {
  openDialog: (dialogProps: DialogProps) => void;
  closeDialog: () => void;
}) => {
  const dialogProps: DialogProps = {
    providedDialog: <IncompatibilityDialog />,
  };
  context.openDialog(dialogProps);
};
