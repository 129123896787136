import { NavBarButton } from 'components';
import i18next from 'i18next';
import { IPanelStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'store/types';

import { setIsHelpPanelOpen } from '../actions';
import { HelpPanel } from './HelpPanel/HelpPanel';

const mapState = (state: RootState) => ({
  isHelpPanelOpen: state.app.isHelpPanelOpen,
});

const mapDispatch = {
  setPanelOpen: setIsHelpPanelOpen,
};

const connector = connect(mapState, mapDispatch);

type Props = {
  panelStyles?: Partial<IPanelStyles>;
  isDemoMode?: boolean;
} & ConnectedProps<typeof connector>;

const _HelpButton: React.FC<Props> = props => {
  const { isHelpPanelOpen, setPanelOpen } = props;

  useHotkeys(
    'shift+/',
    () => {
      setPanelOpen(!isHelpPanelOpen);
    },
    [isHelpPanelOpen, setPanelOpen]
  );

  const toggleHelpPanelVisibility = () => setPanelOpen(!isHelpPanelOpen);
  return (
    <React.Fragment key="help-panel-button">
      <NavBarButton
        ariaLabel={i18next.t('Open help panel')}
        dataAutomationId="navBar-help-button"
        iconName="Help"
        isDemoMode={props.isDemoMode}
        primaryColor
        showActiveStyle={isHelpPanelOpen}
        title={i18next.t('Help or feedback')}
        onClick={toggleHelpPanelVisibility}
      />
      <HelpPanel
        isOpen={isHelpPanelOpen}
        styles={props.panelStyles}
        onDismiss={toggleHelpPanelVisibility}
      />
    </React.Fragment>
  );
};

export const HelpButton = connector(_HelpButton);
