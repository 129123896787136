import { ActionButtonAtom, ActionButtonAtomProps } from 'components/atoms';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { actionButtonStyles } from '../shared.styles';

export type ActionButtonProps = ActionButtonAtomProps;
type Props = ActionButtonProps & WithStyles<typeof actionButtonStyles>;

const ActionButtonUnstyled: React.FC<Props> = props => {
  const { children, classes, ...rest } = props;
  return (
    <ActionButtonAtom mainClass={classes.text} {...rest}>
      {children}
    </ActionButtonAtom>
  );
};

export const ActionButton = withStyles(actionButtonStyles)(ActionButtonUnstyled);
