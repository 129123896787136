import axios, { AxiosResponse } from 'axios';

import { createGuid, getAuthHeader, getCV, getTestHeader } from '../utils';
import { defaultCustomerSearchPageSize, endpoints, getTokenAuthority, LDSSConfig } from './config';
import {
  Agreement,
  CustomerSearchRequest,
  CustomerSearchResponse,
  LDSSHealthCheckResponse,
} from './types';

async function getHeaders(config: LDSSConfig) {
  const headers: Record<string, string> = {
    authorization: await getAuthHeader(getTokenAuthority(config.environment)),
    'x-ms-correlation-id': createGuid(),
    'MS-CV': getCV(),
  };
  if (config.useTestHeader) {
    headers['x-ms-test'] = getTestHeader();
  }
  return headers;
}

export async function getAgreement(
  agreementNumber: string,
  config: LDSSConfig
): Promise<Agreement> {
  const headers = await getHeaders(config);
  const url = endpoints[config.environment] + `/v1/agreements/vl:${agreementNumber}`;
  const response = await axios.get<Agreement>(url, { headers });
  return response.data;
}

export async function searchCustomers(
  query: CustomerSearchRequest,
  wildCard: boolean,
  config: LDSSConfig
): Promise<CustomerSearchResponse> {
  const searchString = `${query.searchTerm}${wildCard ? '*' : ''}`;

  // An empty search can cause an overload to server, to prevent this making sure the
  // query is not null/undefined/empty/whitespace
  if (searchString && searchString.length && searchString.trim() && searchString !== '*') {
    const headers = await getHeaders(config);
    const params = {
      search: searchString,
      $top: query.top || defaultCustomerSearchPageSize,
      $skip: query.skipItems,
    };
    const url = `${endpoints[config.environment]}/v1/search`;
    const response = await axios.get<CustomerSearchResponse>(url, { headers, params });
    return response.data;
  }
  return {
    value: {
      '@odata.count': 0,
      value: [],
    },
  };
}

export async function getLDSSHealthCheck(
  config: LDSSConfig
): Promise<AxiosResponse<LDSSHealthCheckResponse>> {
  const headers = await getHeaders(config);
  const url = `${endpoints[config.environment]}/health/index`;
  const response = await axios.get<LDSSHealthCheckResponse>(url, { headers });
  return response;
}
