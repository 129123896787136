import { ThemeProps } from 'styles/theme';

export const styles = (theme: ThemeProps) => {
  const { fonts, palette } = theme;
  return {
    hoveredAreaStyle: {
      paddingBottom: 20,
    },

    textStyle: {
      fontSize: fonts.fontSizes.medium,
      fontWeight: fonts.fontWeights.semiBold,
      fontColor: palette.text,
      paddingBottom: 0,
      margin: 0,
      overflow: 'hidden',
      cursor: 'pointer',
    },

    buttonStyle: {
      backgroundColor: palette.transparent,
      display: 'flex',
      '& i.ms-Button-icon': {
        fontSize: fonts.fontSizes.small,
        paddingTop: 0,
      },
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 10,
    },

    paddingForChildrenStyles: {
      paddingBottom: 12,
      paddingTop: 6,
      maxWidth: 622,
      fontSize: fonts.fontSizes.medium,
      color: palette.text,
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
    },

    hover: {
      cursor: 'pointer',
    },

    iconStyles: {
      fontSize: fonts.fontSizes.small,
      paddingBottom: 3,
    },

    headingStyles: {
      margin: 0,
      padding: 0,
    },

    valueAlignment: {
      float: 'left',
      '& button:hover': {
        backgroundColor: palette.transparent,
      },
      display: 'flex',
      width: 20,
      '& i': {
        color: palette.textTertiary,
      },
    },

    collapsibleAnswer: {
      paddingLeft: 20,
    },
  };
};
