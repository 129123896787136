import React from 'react';

const styleFull = { display: 'flex', height: '100%' };
const styleMin = { display: 'inline-block' };

export interface Props {
  fullWidth?: boolean;
  condition?: () => boolean;
}

export const EventTrap: React.FC<Props> = props => {
  const { condition, fullWidth } = props;
  const clickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!condition || condition()) {
      event.stopPropagation();
    }
  };
  return (
    <div style={fullWidth ? styleFull : styleMin} onClick={clickHandler}>
      {props.children}
    </div>
  );
};
