import { ThemeProps, sharedStyles } from 'styles';
import { ExistingOwnersListProps } from './ExistingOwnersList';

export const existingOwnersStyles = (theme: ThemeProps) => ({
  ...sharedStyles(theme),
  existingOwnersSection: {
    paddingTop: 24,
  },
  existingOwnersList: (props: ExistingOwnersListProps) => {
    return {
      overflowY: 'auto',
      maxHeight: props.maxHeight || 110,
    };
  },
  existingOwnersTitle: {
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
  existingOwnersLeftColumn: {
    flex: 2,
  },
  existingOwnersRightColumn: {
    flex: 1,
    color: theme.palette.textTertiary,
  },
  existingOwnersListRow: {
    paddingBottom: 2,
    display: 'flex',
  },
  guestedUserIcon: {
    paddingLeft: 8,
    color: theme.palette.textTertiary,
  },
  partialListLoadMessage: {
    textAlign: 'center',
  },
  upn: {
    verticalAlign: 'top',
  },
  spinner: {
    paddingTop: 75,
  },
});
