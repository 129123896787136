import { fonts } from 'styles/theme';

export const sharedStyles = {
  roundedCorners: {
    borderRadius: '2px',
  },
  emphasisText: {
    fontWeight: fonts.fontWeights.semiBold,
  },
  marginBottom14px: {
    marginBottom: 14,
  },
  marginTopBottom32px: {
    margin: '32px 0',
  },
  paddingTopXSmall: {
    paddingTop: '2px',
  },
  paddingTopSmall: {
    paddingTop: '4px',
  },
  paddingTopMedium: {
    paddingTop: '8px',
  },
  paddingRightMedium: {
    paddingRight: '8px',
  },
  paddingRightSmall: {
    paddingRight: '4px',
  },
  paddingTopLarge: {
    paddingTop: '12px',
  },
  paddingRightLarge: {
    paddingRight: '12px',
  },
  paddingRightXLarge: {
    paddingRight: '16px',
  },
  paddingBottomXLarge: {
    paddingBottom: '100px',
  },
  paddingBottom8px: {
    paddingBottom: 8,
  },
  paddingBottom24px: {
    paddingBottom: 24,
  },
  flexSmall: {
    flex: 1,
  },
  flexMedium: {
    flex: 4,
  },
  flexLarge: {
    flex: 10,
  },
  flexXLarge: {
    flex: 11,
  },
  flexLeft: {
    flex: 2,
  },
  flexRight: {
    flex: 3,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};
