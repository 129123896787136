import * as actions from 'features/customer/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Account } from 'services/account/types';
import { OrganizationSearchResponse } from 'services/edge-search/types';
import { t } from 'services/utils';
import { getType } from 'typesafe-actions';

import { loadAccount, searchOrganizations } from './auxiliary';

export function* searchOrganizationsForDialog() {
  const relevantAction = actions.searchOrganizationsForDialogAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const response = yield call(searchOrganizations, action.payload, 25);
      yield put(actions.searchOrganizationsForDialogAsync.success(response));
    } catch (err) {
      yield put(
        actions.searchOrganizationsForDialogAsync.failure({
          message: t('error::Error searching billing accounts'),
          exception: err,
        })
      );
    }
  });
}

export function* searchOrganizationsForSimilarOrganizations() {
  const relevantAction = actions.searchOrganizationForSimilarOrganizationsAsync.request;
  yield takeLatest(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const response: OrganizationSearchResponse = yield call(
        searchOrganizations,
        action.payload.companyName
      );
      const similarOrganization = response.items.find(item => {
        const legalEntity = item.organization.legalEntity;
        return (
          legalEntity.name === action.payload.companyName ||
          legalEntity.businessLocation.address.addressLine1 === action.payload.addressLine1
        );
      });
      if (similarOrganization) {
        const account: Account = yield call(loadAccount, similarOrganization.account.id);
        yield put(
          actions.searchOrganizationForSimilarOrganizationsAsync.success({
            accountName: account.description || similarOrganization.account.id,
            organizationItem: similarOrganization,
          })
        );
      } else {
        yield put(actions.searchOrganizationForSimilarOrganizationsAsync.success(null));
      }
    } catch (err) {
      yield put(
        actions.searchOrganizationForSimilarOrganizationsAsync.failure({
          message: t('error::Error searching billing accounts'),
          exception: err,
        })
      );
    }
  });
}
