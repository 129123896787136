import { SearchOrganizationsResponse } from 'generated/graphql';
import gql from 'graphql-tag';

export interface SearchPartnerResultsData {
  searchPartnerOrganizationsForTypeC: SearchOrganizationsResponse;
}

export const SEARCH_PARTNER_ORGS = gql`
  query SearchPartnerOrganizationsForTypeC($searchInput: SearchOrganizationsInput!) {
    searchPartnerOrganizationsForTypeC(searchInput: $searchInput) {
      organizations {
        accountId
        id
        name
        address {
          companyName
          addressLine1
          addressLine2
          addressLine3
          addressLastLine
          city
          region
          country
          marketInfo {
            display
          }
          postalCode
        }
        lastInvoiceDate
      }
      tenant {
        tenantId
        tenantDisplayName
      }
      messages {
        messageSource
      }
    }
  }
`;
