import { NeedsTranslation } from 'services/utils';

export interface AadUser {
  displayableId: string;
  name: string;
  identityProvider: string;
  userIdentifier: string;
  idToken: IdToken;
}

// AAD IdToken and claims, see: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens
export interface IdToken {
  aud: string;
  iss: string;
  iat: number;
  idp: string; // Doesn't show up for our tokens.
  nbf: number;
  exp: number;
  aio: string;
  preferred_username: string;
  email: string;
  name: string;
  nonce: string;
  oid: string;
  roles: string[];
  sub: string;
  tid: string;
  unique_name: string; // Doesn't show up for our tokens.
  uti: string;
  ver: string;
}

export interface Error {
  message: NeedsTranslation;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exception: any;
}

//the enum strings are used for telemetry
export enum HelpContent {
  Catalog = 'Catalog',
  Claims = 'Claims',
  CustomerPropertySheet = 'CustomerPropertySheet',
  CwaAgreements = 'CwaAgreements',
  CwaSubscriptions = 'CwaSubscriptions',
  Default = 'Default',
  Help = 'Help',
  InfoPropertySheet = 'InfoPropertySheet',
  MyQuotes = 'MyQuotes',
  NeedsMyApproval = 'NeedsMyApproval',
  QuoteEditorProducts = 'QuoteEditorProducts',
  QuoteEditorTerms = 'QuoteEditorTerms',
  SalesPropertySheet = 'SalesPropertySheet',
}
