import {
  CustomerListRow,
  CustomerListRowProps,
  SectionSeparator,
  ShimmerForBackgroundCommon,
} from 'components/ions';
import { ShimmerElementType } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { useTheme, WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { customerListStyles } from './CustomerList.styles';

/**
 * Props for an the customer list
 * @prop {string} items - Customer list row properties representing each customer returned in results
 * @prop {string} searchText - Search text which will determine which parts of lines are bolded
 * @prop {string} addClass
 */

export interface CustomerListProps {
  customers?: CustomerListRowProps[];
  searchText: string;
  addClass?: string;
  loading?: boolean;
  displayEnrollmentId?: boolean;
  enrollmentLabel?: string;
  onSelect?: (customer: string) => void;
}

const shimmerRows = 5;
const shimmerLinesPerRow = 3;

type Props = CustomerListProps & WithStyles<typeof customerListStyles>;

const CustomerListUnstyled: React.FC<Props> = (props: Props) => {
  const theme: ThemeProps = useTheme() as ThemeProps;

  const createShimmer = () => {
    let shimmerList = [];
    for (let i = 0; i < shimmerRows; i++) {
      let shimmerRows = [];
      for (let j = 0; j < shimmerLinesPerRow; j++) {
        shimmerRows.push(
          <div
            className={props.classes.shimmerRowLine}
            key={`${i}-${j}`}
            style={{ opacity: 1 - 0.2 * i }}
          >
            <ShimmerForBackgroundCommon
              shimmerColors={{ background: theme.palette.backgroundMain }}
              shimmerElements={[{ type: ShimmerElementType.line, height: 14 }]}
              width={144 + (j % 2) * 15}
            ></ShimmerForBackgroundCommon>
          </div>
        );
      }
      shimmerList.push(
        <div key={i} style={{ opacity: 1 - 0.2 * i }}>
          <li className={props.classes.shimmerRowItem}>{shimmerRows}</li>
          <SectionSeparator />
        </div>
      );
    }
    return (
      <div className={props.classes.container}>
        <ul className={props.classes.list}>{shimmerList}</ul>
      </div>
    );
  };

  return (
    <div className={props.classes.container}>
      {props.loading ? (
        createShimmer()
      ) : (
        <ul className={props.classes.list}>
          {props &&
            props.customers &&
            props.customers.map((customer, i) => {
              return (
                <div key={i}>
                  <CustomerListRow
                    customerPageUrl={customer.customerPageUrl}
                    displayEnrollmentId={props.displayEnrollmentId}
                    enrollmentId={customer.enrollmentId}
                    enrollmentLabel={props.enrollmentLabel}
                    isAccountEntity={customer.isAccountEntity}
                    parentName={customer.parentName}
                    propertyIconType={customer.propertyIconType}
                    propertyLabelText={customer.propertyLabelText}
                    propertyValue={customer.propertyValue}
                    searchText={props.searchText}
                    tpid={customer.tpid}
                    onSelect={props.onSelect}
                  />
                  <SectionSeparator />
                </div>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export const CustomerList = withStyles(customerListStyles)(CustomerListUnstyled) as React.FC<
  CustomerListProps
>;
