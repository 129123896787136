import { TermConfigureButton } from 'features-apollo/quote/components/ConfigCards/Buttons/TermConfigureButton';
import { QuoteListColumn } from 'features-apollo/quote/components/Lists/types';
import { MonetaryLineItem, SapLineItem, TermLineItem } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { oc } from 'ts-optchain';

import { QuoteListRow, SelectionProps } from '../QuoteListRow';
import { LineItemRowProps } from '../types';
import { textFormatter, titleFormatter } from '../utils';

export interface TermRowProps extends LineItemRowProps, SelectionProps {
  /**
   * Identifies the line item on the quote line items list.
   */
  lineItem: TermLineItem | MonetaryLineItem | SapLineItem;

  /**
   * Determines the start date of the term.
   */
  startDate?: string | null;
  /**
   * Determines the end date of the term.
   */
  endDate?: string | null;
  /**
   * Defines a custom flex CSS rule per content.
   */
  flexContent?: {
    name?: number;
    startDate?: number;
    endDate?: number;
    type?: number;
    configCardButton?: number;
  };
}

export const TermRow: React.FC<TermRowProps> = props => {
  const { t } = useTranslation();
  const termType = t('quote::Negotiated');

  const topColumns: QuoteListColumn[] = [
    {
      id: 'listrow-name',
      content: titleFormatter(props.productTitle),
      flex: oc(props).flexContent.name(),
    },
    {
      id: 'listrow-start-date',
      content: textFormatter(props.startDate),
      flex: oc(props).flexContent.startDate(),
    },
    {
      id: 'listrow-end-date',
      content: textFormatter(props.endDate),
      flex: oc(props).flexContent.endDate(),
    },
    {
      id: 'listrow-type',
      content: textFormatter(termType),
      flex: oc(props).flexContent.type(),
    },
  ];

  const bottomColumns: QuoteListColumn[] = props.productUnavailable
    ? []
    : [
        {
          id: 'term-config-card-button',
          content: (
            <TermConfigureButton
              configuration={props.configuration}
              disabled={props.readOnly}
              isConfigurable={props.configurable}
              lineItem={props.lineItem}
              quoteId={props.quoteId}
            />
          ),
          flex: oc(props).flexContent.configCardButton(),
        },
      ];

  return (
    <QuoteListRow
      {...props}
      bottomColumns={bottomColumns}
      isInErrorState={props.errored}
      isInWarnState={!props.configuration && props.configurable}
      isSelected={props.selectedIds.includes(props.lineItem.id)}
      topColumns={topColumns}
    />
  );
};
