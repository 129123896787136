import { PrimaryButton } from 'components/ions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { styles } from './DialogStatus.styles';
import { DialogStatusType } from './types';

interface Props extends WithStyles<typeof styles> {
  text?: string;
  status: DialogStatusType;
  addClass?: string;
  closeDialog?: () => void;
  onTryAgainClick?: () => void;
}

const DialogStatusFooterUnstyled: React.FC<Props> = ({
  addClass,
  classes,
  status,
  closeDialog,
  onTryAgainClick,
}) => {
  const { t } = useTranslation();
  switch (status) {
    case DialogStatusType.Success:
      return (
        <div className={`${classes.customDialogFooter} ${classes.flexAlignCenter} ${addClass}`}>
          <PrimaryButton text={t('quote::Close')} onClick={closeDialog} />
        </div>
      );

    case DialogStatusType.Fail:
      return (
        <div className={`${classes.customDialogFooter} ${classes.flexAlignCenter} ${addClass}`}>
          {onTryAgainClick ? (
            <PrimaryButton text={t('quote::Try again')} onClick={onTryAgainClick} />
          ) : (
            <PrimaryButton text={t('quote::Close')} onClick={closeDialog} />
          )}
        </div>
      );

    default:
      return null;
  }
};

export const DialogStatusFooter = withStyles(styles)(DialogStatusFooterUnstyled);
