import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextBody,
  XLargeIcon,
} from 'components';
import { meplaHistory } from 'createHistory';
import { getCRMLeads } from 'features/customer/selectors';
import * as userSelectors from 'features/user/selectors';
import WorkArea from 'layouts/WorkArea';
import React from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from 'routes';
import * as LocalStorage from 'services/local-storage-service';
import { Proposal, ProposalUpdateRequest, RequestProposal } from 'services/proposal/types';
import { AsyncState, RootState } from 'store/types';
import { DialogContext, DialogProps } from 'styles';
import { oc } from 'ts-optchain';

import * as actions from '../actions';
import * as selectors from '../selectors';
import { defaultMarket, getCurrencies, getLanguages, Market } from '../supported-markets';
import { getCRMId, quoteNameInvalidCharacters } from '../utils';
import { newProposalDialogStyles } from './Dialogs/NewDialog/NewDialog.styles';
import { DialogLayout } from './Dialogs/Shared';

interface Params {
  id: string;
}

const mapStateToProps = (state: RootState) => ({
  user: userSelectors.getUser(state).email || '',
  createProposalError: selectors.createProposalError(state),
  quoteExpirationDate: selectors.getProposalNextExpiryDate(state),
  loadingCrmId: selectors.addingCRMId(state),
  errorAddingCrmId: selectors.crmIdErrorOnAdd(state),
  errorVerifyingCrmId: selectors.processingVerifyCrmId(state).error,
  requestState: selectors.getViews(state).newProposal,
  loadingVerifyCrmId: selectors.processingVerifyCrmId(state).loading,
  activeQuoteId: selectors.getActiveProposalId(state),
  proposal: selectors.getActiveProposal(state),
  crmLeads: getCRMLeads(state),
});

const dispatchProps = {
  getProposal: (proposalId: string) => actions.loadAndHydrateAllQuote(proposalId),
  createProposal: (request: RequestProposal) => actions.createProposalAsync.request(request),
  onSearchCrmId: (request: string, id: string) =>
    actions.addNewCrmId.request({ crmId: request, proposalId: id }),
  updateProposal: (proposalRequest: ProposalUpdateRequest) =>
    actions.updateProposalAsync.request(proposalRequest),
  verifyCrmId: (request: string) => actions.verifyCrmId.request(request),
  resetNewProposal: () => actions.resetNewProposalDialog(),
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  RouteComponentProps<Params> &
  WithTranslation &
  WithStyles<typeof newProposalDialogStyles>;

const CrmId: React.FC<Props> = ({
  getProposal,
  createProposal,
  createProposalError,
  match,
  user,
  quoteExpirationDate,
  classes,
  verifyCrmId,
  onSearchCrmId,
  updateProposal,
  requestState,
  loadingVerifyCrmId,
  activeQuoteId,
  loadingCrmId,
  proposal,
  errorAddingCrmId,
  errorVerifyingCrmId,
  crmLeads,
  resetNewProposal,
}) => {
  React.useEffect(() => {
    activeQuoteId && getProposal(activeQuoteId);
  }, [activeQuoteId, getProposal]);

  const { t } = useTranslation('quote');

  const dialogContext = React.useContext(DialogContext);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isDialogUpdated, setIsDialogUpdated] = React.useState(false);

  const userMarket = LocalStorage.get<Market>(LocalStorage.localMarketKey) || defaultMarket;
  const userCurrency = getCurrencies(userMarket)[0];
  const userLanguage = getLanguages(userMarket)[0];

  const crmid = proposal && getCRMId(proposal);
  const crmLead = crmLeads[match.params.id];
  const noExistingProposal = requestState === AsyncState.Unset && !activeQuoteId;

  const crmLeadTitle = oc(crmLead)
    .title('')
    .replace(quoteNameInvalidCharacters, '')
    .trim();
  const quoteName = crmLeadTitle || t('CRM quote');

  const request: RequestProposal = {
    header: {
      name: quoteName,
      msContact: user,
      transactionType: 'UserPurchase',
      isApprovalPreCheckRequired: true,
      expirationDate: quoteExpirationDate,
      pricingContext: {
        billingCurrency: userCurrency,
        catalogId: '4',
        languages: userLanguage,
        market: userMarket,
      },
      extendedProperties: {
        isMsxPrimaryQuote: false,
        userPreferences: {
          agreementType: 'modern',
          nationalCloud: 'Global',
          recipientId: null,
        },
      },
      //TODO: cameneks quotev11 that is probably not always null
      negotiationReason: null,
    },
    lineItems: [],
  };

  if (
    !loadingVerifyCrmId &&
    requestState === AsyncState.Unset &&
    !crmLead &&
    !errorVerifyingCrmId
  ) {
    verifyCrmId(match.params.id);
  }

  const showError =
    (!loadingVerifyCrmId && errorVerifyingCrmId) ||
    createProposalError ||
    (proposal && errorAddingCrmId);

  if (!loadingVerifyCrmId && noExistingProposal && !showError && crmLead) {
    createProposal(request);
  }

  const proposalIsLoaded = requestState === AsyncState.Success && proposal;
  if (proposalIsLoaded && !loadingCrmId && !crmid) {
    resetNewProposal();
    onSearchCrmId(match.params.id, proposal.id);
  }

  if (crmid === match.params.id && !errorAddingCrmId && crmLead) {
    const name = crmLeadTitle || proposal.header.name;
    const requestProposal: Proposal = {
      ...proposal,
      header: { ...proposal.header, name },
    };
    const proposalUpdateRequest: ProposalUpdateRequest = {
      etag: proposal.etag,
      proposalId: proposal.id,
      proposal: requestProposal,
    };
    //TODO kaderbez: make only one call for creating proposal with crm info
    updateProposal(proposalUpdateRequest);
    meplaHistory.push(routes.quote.customerForId(proposal.id));
    resetNewProposal();
    dialogContext.closeDialog();
  }

  const closeDialog = () => {
    meplaHistory.push(routes.home.quotes.my);
    dialogContext.closeDialog();
  };

  const processingLayout: DialogLayout = {
    body: (
      <div className={classes.customDialogContainer}>
        <div className={`${classes.iconContainer} ${classes.customDialogProcessingIcon}`}>
          <XLargeIcon addClass={classes.customDialogIcon} iconName="Processing" />
        </div>
        <div>
          <TextBody addClass={classes.text}>{t('quote::Creating...')}</TextBody>
        </div>
      </div>
    ),
  };

  const errorLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        closeButtonContainerClass={classes.closeButtonNoTitle}
        dataAutomationId="errorCrmId"
        dialogClose={closeDialog}
      />
    ),
    body: (
      <div className={classes.customDialogContainer}>
        <div className={`${classes.iconContainer} ${classes.customDialogFailIcon}`}>
          <XLargeIcon addClass={classes.customDialogIcon} iconName="Cancel" />
        </div>
        <div>
          <TextBody addClass={classes.text}>
            {t(
              'quote::Failed to create quote. CRM ID does not exist or is missing some required properties.'
            )}
          </TextBody>
        </div>
      </div>
    ),
    footer: (
      <div className={classes.errorFooter}>
        <PrimaryButton
          dataAutomationId="goHomeButton"
          disabled={false}
          text={t('quote::Go Home')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  const currentLayout = !showError ? processingLayout : errorLayout;

  const dialog = (
    <CustomDialogBox
      bodySlot={currentLayout.body}
      enterKeyCallback={closeDialog}
      footerSlot={currentLayout.footer}
      headerSlot={currentLayout.header}
      height={280}
      id="crmId"
      width={382}
    />
  );

  const dialogProps: DialogProps = {
    providedDialog: dialog,
  };

  const openDialog = () => {
    dialogContext.openDialog(dialogProps);
    setIsDialogOpen(true);
  };

  const updateDialog = () => {
    dialogContext.closeDialog();
    dialogContext.openDialog(dialogProps);
    setIsDialogUpdated(true);
  };

  !isDialogOpen && !showError && openDialog();
  showError && !isDialogUpdated && updateDialog();

  return (
    <WorkArea name="CrmId">
      {!proposal && !showError && (
        <Spinner
          className={`${classes.padRight50Percent} ${classes.padLeft50Percent}`}
          size={SpinnerSize.large}
        />
      )}
    </WorkArea>
  );
};

export default connect(mapStateToProps, dispatchProps)(withStyles(newProposalDialogStyles)(CrmId));
