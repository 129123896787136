import { ICalloutContentStyles } from 'office-ui-fabric-react';
import { ThemeProps } from 'styles';

export const calloutFabricStyles = (minWidth: number): Partial<ICalloutContentStyles> => {
  return {
    calloutMain: {
      minWidth,
      maxWidth: minWidth,
    },
    root: {
      borderWidth: 0,
    },
  };
};

export const autoSuggestStyles = (theme: ThemeProps) => ({
  textbox: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    width: '100%',
  },
  searchButton: {
    paddingLeft: 16,
    paddingTop: 30,
  },
  searchIconStyles: {
    '& .ms-TextField-fieldGroup': {
      flexDirection: 'row-reverse',
    },
    '& .ms-TextField-fieldGroup i': {
      position: 'static',
      paddingTop: 5,
      paddingLeft: 9,
      paddingRight: 2,
      color: theme.palette.textSecondary,
    },
  },
});
