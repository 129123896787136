import { LinkEmail, ShimmerForBackgroundStandout } from 'components/ions';
import { TextBody, TextBodySmall, TextWatermarkSmall } from 'components/ions/Text';
import { mergeClassNames } from 'components/utilities';
import { FieldType, formatRegion, getAddressConfiguration } from 'components/utilities/address';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { addressStyles } from './Address.styles';

export interface AddressType {
  companyName: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  region?: string;
  country: string;
  postalCode?: string;
  phoneNumber?: string;
  email?: string;
}

export interface AddressProps {
  headline?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: any;
  addClass?: string;
  addCompanyNameClass?: string;
  addContainerClass?: string;
  addTradeNameClass?: string;
  address: AddressType;
  dataAutomationId?: string;
  id?: string;
  loading?: boolean;
  maxWidth?: number;
  organizationName?: string;
  showAccountName?: boolean;
  showCompanyName?: boolean;
  tradeName?: string;
  watermark?: string;
}

export interface AddressCompanyProps extends AddressProps {
  companyType?: string;
  pcn?: string;
}

export interface AddressCompanyContactProps extends AddressCompanyProps {
  contact?: string;
  contactEmail?: string;
}

type Props = AddressProps & WithStyles<typeof addressStyles>;
type CompanyProps = AddressCompanyProps & WithStyles<typeof addressStyles>;
type CompanyContactProps = AddressCompanyContactProps & WithStyles<typeof addressStyles>;

const renderText = (text?: string, addClass?: string, dataAutomationId?: string) => {
  if (text) {
    return (
      <TextBodySmall addClass={addClass} dataAutomationId={dataAutomationId} title={text}>
        {text}
      </TextBodySmall>
    );
  }
};

const renderTextLarge = (text?: string, addClass?: string, dataAutomationId?: string) => {
  if (text) {
    return (
      <TextBody addClass={addClass} dataAutomationId={dataAutomationId} title={text}>
        {text}
      </TextBody>
    );
  }
};

const renderLink = (text?: string, addClass?: string) => {
  if (text) {
    return <LinkEmail addClass={addClass} email={text} />;
  }
};

export const getAddressLine4 = (address: AddressType) => {
  const addressConfig = getAddressConfiguration(address.country);

  let addressLine4: string[] = [];

  addressConfig.fieldGroup.forEach(fieldType => {
    if (fieldType === FieldType.City && address.city) {
      addressLine4.push(address.city);
    } else if (fieldType === FieldType.Region && addressConfig.region && address.region) {
      const region = formatRegion(address);
      if (region) {
        addressLine4.push(region);
      }
    } else if (fieldType === FieldType.PostalCode && address.postalCode) {
      addressLine4.push(address.postalCode);
    }
  });

  return addressLine4.join(', ');
};

const AddressUnstyled: React.FC<Props> = props => {
  const { address, tradeName, classes, id, action, watermark, addClass, addContainerClass } = props;
  const addressLine4 = getAddressLine4(address);
  const textClasses = mergeClassNames([classes.addressText, addClass]);
  const addressContainerClasses = mergeClassNames([classes.addressContainer, addContainerClass]);
  const companyNameClasses = mergeClassNames([
    textClasses,
    classes.companyName,
    addClass,
    props.addCompanyNameClass,
  ]);
  const organizationNameClasses = mergeClassNames([
    textClasses,
    classes.organizationName,
    addClass,
  ]);
  const addressDisplay: JSX.Element =
    props.loading || !props.address ? (
      <>
        <ShimmerForBackgroundStandout className={classes.shimmer} width="75%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="70%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="95%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="45%" />
      </>
    ) : (
      <>
        {renderText(address.addressLine1, textClasses, 'addressLine1')}
        {renderText(address.addressLine2, textClasses, 'addressLine2')}
        {renderText(address.addressLine3, textClasses, 'addressLine3')}
        {renderText(addressLine4, textClasses, 'addressLine4')}
        {renderText(address.country, textClasses, 'country')}
      </>
    );
  const display: JSX.Element = (
    <div>
      {props.showCompanyName &&
        address.companyName &&
        renderTextLarge(address.companyName, companyNameClasses, 'companyName')}
      {props.organizationName &&
        renderTextLarge(props.organizationName, organizationNameClasses, 'organizationName')}
      {tradeName && (
        <TextBodySmall
          addClass={mergeClassNames([classes.tradeName, props.addTradeNameClass])}
          title={tradeName}
        >{`dba ${tradeName}`}</TextBodySmall>
      )}
      {addressDisplay}
    </div>
  );

  // TODO: refactor component as it is no longer just an address component
  return (
    <div className={addressContainerClasses} data-automation-id={props.dataAutomationId} id={id}>
      {props.showAccountName && props.headline && renderText(props.headline, classes.headline)}
      {display}
      {renderText(action)}
      {watermark && (
        <TextWatermarkSmall addClass={classes.watermark}>{watermark}</TextWatermarkSmall>
      )}
    </div>
  );
};

const AddressCompanyUnstyled: React.FC<CompanyProps> = props => {
  const {
    address,
    classes,
    id,
    action,
    watermark,
    addClass,
    addContainerClass,
    companyType,
    pcn,
  } = props;
  const addressLine4 = getAddressLine4(address);
  const textClasses = mergeClassNames([classes.addressText, addClass]);
  const pcnTextClasses = mergeClassNames([classes.pcnText, textClasses]);
  const addressContainerClasses = mergeClassNames([classes.addressContainer, addContainerClass]);
  const companyNameBoldClasses = mergeClassNames([textClasses, classes.companyNameBold, addClass]);

  const addressDisplay: JSX.Element =
    props.loading || !props.address ? (
      <>
        <ShimmerForBackgroundStandout className={classes.shimmer} width="75%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="70%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="95%" />
        <ShimmerForBackgroundStandout className={classes.shimmer} width="45%" />
      </>
    ) : (
      <>
        {renderText(address.addressLine1, textClasses)}
        {renderText(address.addressLine2, textClasses)}
        {renderText(address.addressLine3, textClasses)}
        {renderText(addressLine4, textClasses)}
        {renderText(address.country, textClasses)}
      </>
    );
  const customerDisplay: JSX.Element = (
    <div>
      {props.showCompanyName &&
        address.companyName &&
        renderText(address.companyName, companyNameBoldClasses)}
      {renderText(companyType || '-', textClasses)}
      {renderText(`PCN: ${pcn || '-'}`, pcnTextClasses)}
      {addressDisplay}
    </div>
  );

  return (
    <div className={addressContainerClasses} data-automation-id={props.dataAutomationId} id={id}>
      {props.showAccountName && props.headline && renderText(props.headline, classes.headline)}
      {customerDisplay}
      {renderText(action)}
      {watermark && (
        <TextWatermarkSmall addClass={classes.watermark}>{watermark}</TextWatermarkSmall>
      )}
    </div>
  );
};

const AddressCompanyContactUnstyled: React.FC<CompanyContactProps> = props => {
  const {
    address,
    classes,
    id,
    action,
    watermark,
    addClass,
    addContainerClass,
    companyType,
    pcn,
    contact,
    contactEmail,
  } = props;
  const addressLine4 = getAddressLine4(address);
  const textClasses = mergeClassNames([classes.addressText, addClass]);
  const pcnTextClasses = mergeClassNames([classes.pcnText, textClasses]);
  const contactTextClasses = mergeClassNames([classes.contact, textClasses]);
  const contactEmailClasses = mergeClassNames([classes.contactEmail, textClasses]);
  const addressContainerClasses = mergeClassNames([classes.addressContainer, addContainerClass]);
  const companyNameBoldClasses = mergeClassNames([textClasses, classes.companyNameBold, addClass]);

  const addressDisplay: JSX.Element = props.loading ? (
    <>
      <ShimmerForBackgroundStandout className={classes.shimmer} width="75%" />
      <ShimmerForBackgroundStandout className={classes.shimmer} width="70%" />
      <ShimmerForBackgroundStandout className={classes.shimmer} width="95%" />
      <ShimmerForBackgroundStandout className={classes.shimmer} width="45%" />
    </>
  ) : (
    <>
      {renderText(address.addressLine1, textClasses)}
      {renderText(address.addressLine2, textClasses)}
      {renderText(address.addressLine3, textClasses)}
      {renderText(addressLine4, textClasses)}
      {renderText(address.country, textClasses)}
    </>
  );
  const customerDisplay: JSX.Element = (
    <div>
      {props.showCompanyName &&
        address.companyName &&
        renderText(address.companyName, companyNameBoldClasses)}
      {renderText(companyType || '-', textClasses)}
      {renderText(`PCN: ${pcn || '-'}`, pcnTextClasses)}
      {addressDisplay}
    </div>
  );
  const contactDisplay: JSX.Element = (
    <div>
      {customerDisplay}
      {renderText(contact || '-', contactTextClasses)}
      {renderLink(contactEmail || '-', contactEmailClasses)}
    </div>
  );

  return (
    <div className={addressContainerClasses} data-automation-id={props.dataAutomationId} id={id}>
      {props.showAccountName && props.headline && renderText(props.headline, classes.headline)}
      {contactDisplay}
      {renderText(action)}
      {watermark && (
        <TextWatermarkSmall addClass={classes.watermark}>{watermark}</TextWatermarkSmall>
      )}
    </div>
  );
};

const AddressUnstyledLarge: React.FC<Props> = props => {
  const { address, tradeName, classes, id, action, watermark, addClass, addContainerClass } = props;
  const addressLine4 = getAddressLine4(address);
  const addressTextClasses = mergeClassNames([classes.addressText, addClass]);
  const addressContainerClasses = mergeClassNames([classes.addressContainer, addContainerClass]);
  const companyNameClasses = mergeClassNames([
    addressTextClasses,
    classes.companyName,
    addClass,
    props.addCompanyNameClass,
  ]);

  return (
    <div className={addressContainerClasses} data-automation-id={props.dataAutomationId} id={id}>
      {props.showAccountName && props.headline && (
        <TextBodySmall addClass={classes.headline} title={props.headline}>
          {props.headline}
        </TextBodySmall>
      )}
      {props.showCompanyName && !!address.companyName && (
        <TextBody
          addClass={companyNameClasses}
          dataAutomationId="AddressLineCompanyName"
          title={address.companyName}
        >
          {address.companyName}
        </TextBody>
      )}
      {tradeName && (
        <TextBodySmall
          addClass={mergeClassNames([classes.tradeName, props.addTradeNameClass])}
        >{`dba ${tradeName}`}</TextBodySmall>
      )}
      {renderTextLarge(address.addressLine1, addressTextClasses, 'addressLine1')}
      {renderTextLarge(address.addressLine2, addressTextClasses, 'addressLine2')}
      {renderTextLarge(address.addressLine3, addressTextClasses, 'addressLine3')}
      {renderTextLarge(addressLine4, addressTextClasses, 'addressLine4')}
      {renderTextLarge(address.country, addressTextClasses, 'country')}
      {renderTextLarge(action)}
      {watermark && (
        <TextWatermarkSmall addClass={classes.watermark}>{watermark}</TextWatermarkSmall>
      )}
    </div>
  );
};

export const Address = withStyles(addressStyles)(AddressUnstyled) as React.FC<AddressProps>;
export const AddressLarge = withStyles(addressStyles)(AddressUnstyledLarge) as React.FC<
  AddressProps
>;

// TODO: jepagan - no referenced anywhere, delete?
export const AddressCompany = withStyles(addressStyles)(AddressCompanyUnstyled) as React.FC<
  AddressCompanyProps
>;

// TODO: jepagan - no referenced anywhere, delete?
export const AddressCompanyContact = withStyles(addressStyles)(
  AddressCompanyContactUnstyled
) as React.FC<AddressCompanyContactProps>;
