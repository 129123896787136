import { mergeClassNames } from 'components/utilities';
import { Text } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { TextSharedProps } from '../TextSharedProps';
import { textAtomStyles } from './TextAtom.styles';

type Props = TextSharedProps & WithStyles<typeof textAtomStyles>;

export const TextUnstyledAtom: React.FC<Props> = props => {
  const className = mergeClassNames([props.classes.textStyles, props.addClass]);
  return (
    <Text {...props} className={className} data-automation-id={props.dataAutomationId}>
      {props.children}
    </Text>
  );
};
export const TextAtom = withStyles(textAtomStyles)(TextUnstyledAtom) as React.FC<TextSharedProps>;
