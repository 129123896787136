import { SectionSeparator, TextBody, TextTitleSecondary } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

import { KeyBox } from './KeyBox';

const shortcutsSectionStyles = (theme: ThemeProps) => ({
  keys: {
    display: 'flex',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineTitle: {
    color: theme.palette.textTertiary,
    paddingTop: 6,
  },
  separator: {
    marginTop: 4,
    marginBottom: 8,
  },
  title: {
    paddingBottom: 8,
    paddingTop: 24,
  },
});

export interface ShortcutLineProps {
  lineTitle: string;
  shortcutKeys: string[];
}

export interface ShortcutsSectionProps {
  sectionTitle: string;
  shortcutLines: ShortcutLineProps[];
}

type LineProps = ShortcutLineProps & WithStyles<typeof shortcutsSectionStyles>;
type Props = ShortcutsSectionProps & WithStyles<typeof shortcutsSectionStyles>;

const ShortcutLineUnstyled: React.FC<LineProps> = props => {
  return (
    <>
      <div className={props.classes.line}>
        <TextBody
          addClass={props.classes.lineTitle}
          dataAutomationId={`shortcutLine-${props.lineTitle}`}
        >
          {props.lineTitle}
        </TextBody>
        <div className={props.classes.keys}>
          {props.shortcutKeys.map((key: string) => {
            return <KeyBox displayText={key} key={key} />;
          })}
        </div>
      </div>
      <SectionSeparator addClass={props.classes.separator} />
    </>
  );
};

export const ShortcutsSectionUnstyled: React.FC<Props> = props => {
  return (
    <>
      <TextTitleSecondary
        addClass={props.classes.title}
        dataAutomationId={`shortcutsSection-${props.sectionTitle}`}
      >
        {props.sectionTitle}
      </TextTitleSecondary>
      {props.shortcutLines.map((line: ShortcutLineProps) => {
        return (
          <ShortcutLine
            key={line.lineTitle}
            lineTitle={line.lineTitle}
            shortcutKeys={line.shortcutKeys}
          />
        );
      })}
    </>
  );
};

export const ShortcutLine = withStyles(shortcutsSectionStyles)(ShortcutLineUnstyled);
export const ShortcutsSection = withStyles(shortcutsSectionStyles)(ShortcutsSectionUnstyled);

export const GlobalShortcutsSection: React.FC<{}> = () => {
  const { t } = useTranslation();
  const linesProps = [
    {
      lineTitle: t('common::Show shortcuts'),
      shortcutKeys: ['Ctrl', '.'],
    },
    {
      lineTitle: t('common::Go Home'),
      shortcutKeys: ['g', 'h'],
    },
    {
      lineTitle: t('common::Open help menu'),
      shortcutKeys: ['?'],
    },
  ];
  const sectionProps = {
    sectionTitle: t('common::Global'),
    shortcutLines: linesProps,
  };

  return <ShortcutsSection {...sectionProps} />;
};

export const HomeShortcutsSection: React.FC = () => {
  const { t } = useTranslation();

  const linesProps = [
    {
      lineTitle: t('common::Activate primary search'),
      shortcutKeys: ['Ctrl', '/'],
    },
    {
      lineTitle: t('common::Go to Quotes'),
      shortcutKeys: ['g', '1'],
    },
    {
      lineTitle: t('common::Go to Claims'),
      shortcutKeys: ['g', '2'],
    },
    {
      lineTitle: t('common::Go to Customers'),
      shortcutKeys: ['g', '3'],
    },
    {
      lineTitle: t('common::Go to Catalog'),
      shortcutKeys: ['g', '4'],
    },
    {
      lineTitle: t('common::Go to Help'),
      shortcutKeys: ['g', '5'],
    },
  ];
  const sectionProps = {
    sectionTitle: t('common::Home'),
    shortcutLines: linesProps,
  };

  return <ShortcutsSection {...sectionProps} />;
};

export const QuoteShortcutsSection: React.FC<{}> = () => {
  const { t } = useTranslation();
  const linesProps = [
    {
      lineTitle: t('common::Go to terms'),
      shortcutKeys: ['g', 't'],
    },
    {
      lineTitle: t('common::Go to products'),
      shortcutKeys: ['g', 'p'],
    },
    {
      lineTitle: t('common::Search for product'),
      shortcutKeys: ['Ctrl', '/'],
    },
    {
      lineTitle: t('common::Go to sales properties'),
      shortcutKeys: ['g', 's'],
    },
    {
      lineTitle: t('common::Go to customer properties'),
      shortcutKeys: ['g', 'c'],
    },
    {
      lineTitle: t('common::Go to info properties'),
      shortcutKeys: ['g', 'i'],
    },
  ];
  const sectionProps = {
    sectionTitle: t('common::Quote Editor'),
    shortcutLines: linesProps,
  };

  return <ShortcutsSection {...sectionProps} />;
};

export const CustomerShortcutsSection: React.FC<{}> = () => {
  const { t } = useTranslation();
  const linesProps = [
    {
      lineTitle: t('common::Go to Agreements'),
      shortcutKeys: ['g', '1'],
    },
    {
      lineTitle: t('common::Go to Subscriptions'),
      shortcutKeys: ['g', '2'],
    },
  ];
  const sectionProps = {
    sectionTitle: t('common::Customer'),
    shortcutLines: linesProps,
  };

  return <ShortcutsSection {...sectionProps} />;
};
