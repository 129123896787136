import { ShowMoreDialog } from 'components';
import { LinkExternal, NotificationItem, TextBodySmall, TextTitle } from 'components/ions';
import { LabelListItem } from 'components/ions/CuratedText/LabelList/LabelList';
import { dateFormatLl } from 'components/utilities/dates';
import { ShowMoreText } from 'features/components/ShowMoreText';
import { AddOn } from 'features/proposal/components/AddOns';
import {
  ExistingDiscount,
  ExistingDiscountLabels,
} from 'features/proposal/components/ExistingDiscountsList';
import { formatCurrency } from 'features/proposal/utils';
import moment from 'moment';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

import { DetailsPaneStyles } from './DetailsPane.styles';
import {
  renderAddOns,
  renderDetailsPaneLabelList,
  renderDiscounts,
  renderNotifications,
} from './SharedDetailsRenders';

// #region LineItemInfo
interface LineItemInfo {
  productName: string;
  description: string;
  imageUri: string;
  publisherLink?: React.ReactNode;
  strippedDescription: string;
}

const lineItemInfoStyles = {
  heading: { display: 'flex', flexDirection: 'column', width: '100%' },
  description: { paddingTop: 8 },
  detailsContainer: {
    margin: '32px 24px',
  },
};

type Props = LineItemInfo & WithStyles<typeof lineItemInfoStyles>;

const LineItemInfo: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { classes } = props;

  const dialogProps = {
    props: {
      id: 'description-dialog-box',
      dialogClose: React.useContext(DialogContext).closeDialog,
      headingText: props.productName,
      bodyText: props.description,
      imageUrl: props.imageUri,
      actionLink: props.publisherLink,
      dataAutomationId: 'descriptionDialogBox',
    },
    hasImage: props.imageUri ? true : false,
    isCustom: false,
  };

  return (
    <div className={classes.heading}>
      <div>
        <TextTitle>{props.productName}</TextTitle>
      </div>
      <div className={classes.description} data-automation-id="productDescription">
        <ShowMoreDialog
          dataAutomationId="fullDescriptionLink"
          dialogProps={dialogProps}
          id="Description"
          linkId="full-description-link"
          linkText={t('quote::full description')}
          maxLines={5}
          text={props.strippedDescription}
        />
      </div>
    </div>
  );
};

// #endregion

// #region LineItemDetails

interface ECIFService {
  shortName: string;
  amount: string;
}

export interface LineItemDetailsProps extends WithStyles<typeof DetailsPaneStyles> {
  id: string;
  description: string;
  labels: ExistingDiscountLabels;
  strippedDescription: string;
  productName: string;
  conditions: LabelListItem[];
  configurations: LabelListItem[];
  notifications: NotificationItem[];
  discounts?: ExistingDiscount[];
  addOns?: AddOn[];
  imageUri: string;
  publisherLink?: React.ReactNode;
  showAddons: boolean;
  ecifData?: {
    currency: string;
    oneAskId: string;
    oneAskLink: string;
    totalAmountApproved: string;
    summary: string;
    finalAmount?: string;
    types?: string[];
    productFamilies?: string[];
    endDate?: string;
    services?: ECIFService[];
  };
}

const Amount: React.FC<{ amount: string; currency?: string }> = props => {
  return (
    <TextBodySmall>
      {formatCurrency(props.amount, 0)}
      {props.currency && <small>{` ${props.currency}`}</small>}
    </TextBodySmall>
  );
};

const ServicesList: React.FC<{ services: ECIFService[]; addClass: string }> = props => {
  return (
    <>
      <table className={props.addClass}>
        <tbody>
          {props.services.map(({ shortName, amount }, index) => (
            <tr key={index}>
              <td>
                <TextBodySmall>{shortName}</TextBodySmall>
              </td>
              <td>
                <Amount amount={amount} />
              </td>
            </tr>
          ))}
          <tr>
            <td />
            <td colSpan={2}>
              <TextBodySmall>
                <Trans ns="quote">
                  Total: <Amount amount="2000" currency="USD" />
                </Trans>
              </TextBodySmall>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const LineItemDetailsUnstyled: React.FunctionComponent<LineItemDetailsProps> = props => {
  const {
    id,
    classes,
    labels,
    conditions,
    notifications,
    discounts,
    addOns,
    configurations,
    showAddons,
  } = props;

  const { t } = useTranslation();

  const defaultDetails = (
    <>
      {renderDetailsPaneLabelList(configurations, classes.detailsPaneSection)}
      {renderDiscounts(classes, labels, discounts)}
      {showAddons ? renderAddOns(classes, addOns) : null}
      {renderDetailsPaneLabelList(conditions, classes.detailsPaneSection)}
      {renderNotifications(notifications, classes)}
    </>
  );

  const ecifDetails = props.ecifData ? (
    <>
      <div className={classes.sectionDetails}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>OneAsk ID:</TextBodySmall>
              </td>
              <td>
                <LinkExternal
                  addClass={classes.link}
                  displayText={props.ecifData.oneAskId}
                  href={props.ecifData.oneAskLink}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::Approved for:{{totalAmount}}')}
                </TextBodySmall>
              </td>
              <td>
                <Amount
                  amount={props.ecifData.totalAmountApproved}
                  currency={props.ecifData.currency}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::Final amount:{{finalAmount}}')}
                </TextBodySmall>
              </td>
              <td>
                {props.ecifData.finalAmount ? (
                  <Amount
                    amount={props.ecifData.finalAmount}
                    currency={props.ecifData.finalAmount && props.ecifData.currency}
                  />
                ) : (
                  <TextBodySmall>-</TextBodySmall>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::Type:{{type}}')}
                </TextBodySmall>
              </td>
              <td>
                <TextBodySmall>
                  {props.ecifData.types && props.ecifData.types.length
                    ? props.ecifData.types.join(', ')
                    : '-'}
                </TextBodySmall>
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::Product Family:{{productFamily}}')}
                </TextBodySmall>
              </td>
              <td>
                {props.ecifData.productFamilies && props.ecifData.productFamilies.length ? (
                  props.ecifData.productFamilies.map(productFamily => (
                    <TextBodySmall block key={productFamily}>
                      {productFamily}
                    </TextBodySmall>
                  ))
                ) : (
                  <TextBodySmall>-</TextBodySmall>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::Start:{{startDate}}')}
                </TextBodySmall>
              </td>
              <td>
                <TextBodySmall>{t('quote::At order acceptance')}</TextBodySmall>
              </td>
            </tr>
            <tr>
              <td>
                <TextBodySmall addClass={classes.ecifLabels}>
                  {t('quote::End:{{endDate}}')}
                </TextBodySmall>
              </td>
              <td>
                <TextBodySmall>
                  {props.ecifData.endDate
                    ? moment(props.ecifData.endDate).format(dateFormatLl)
                    : '-'}
                </TextBodySmall>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {renderNotifications(notifications, classes)}
      <div className={classes.sections}>
        <TextBodySmall addClass={classes.ecifLabels}>{t('quote::Summary')}</TextBodySmall>
        <ShowMoreText maxLine={4} text={props.ecifData.summary} />
      </div>
      <div className={classes.sections}>
        <TextBodySmall addClass={classes.ecifLabels}>
          {t('quote::Consulting and product support services')}
        </TextBodySmall>
        {props.ecifData.services && props.ecifData.services.length ? (
          <ServicesList addClass={classes.servicesListTable} services={props.ecifData.services} />
        ) : (
          <TextBodySmall addClass={classes.servicesNone} block>
            {t('quote::none')}
          </TextBodySmall>
        )}
      </div>
    </>
  ) : (
    undefined
  );

  return (
    <div className={classes.detailsContainer} id={id}>
      <LineItemInfo {...props} />
      {ecifDetails || defaultDetails}
    </div>
  );
};

export const LineItemDetails = withStyles(DetailsPaneStyles)(LineItemDetailsUnstyled);
// #endregion
