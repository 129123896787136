import { QuoteData } from 'features-apollo/quote/types';
import { user } from 'features-apollo/user/mocks';
import {
  Account,
  Address,
  AgreementType,
  ApplicableDiscounts,
  Approval,
  ApprovalStatus,
  Audience,
  BillingGroupConnection,
  CatalogAction,
  CatalogContext,
  CrmLead,
  CrmType,
  CrmValidity,
  DatePrecision,
  DiscountLineItem,
  EmpoweredUsers,
  GqlLanguage,
  Images,
  InvitedUserSimple,
  LineItem,
  Market,
  ModernAgreementActual,
  ModernAgreementActualConnection,
  ModernAgreementActualEdge,
  ModernAgreementParticipant,
  MonetaryLineItem,
  Motion,
  NationalCloud,
  OpportunitySimple,
  OrganizationSimple,
  OrganizationType,
  Product,
  ProductAudience,
  PurchaseLineItem,
  Quote,
  QuoteApproval,
  QuoteStatus,
  RequiredApproval,
  SoldToSimple,
  TransactionModel,
  UserGroup,
} from 'generated/graphql';

export const QUOTE_ID = '123';

export const address: Address = {
  __typename: 'Address',
  country: 'US',
  addressLastLine: null,
  addressLine1: null,
  addressLine2: null,
  addressLine3: null,
  city: null,
  companyName: null,
  email: null,
  marketInfo: null,
  name: null,
  phoneNumber: null,
  postalCode: null,
  region: null,
};

export const crmLead: CrmLead = {
  entityId: '',
  entityName: '',
  id: '',
  type: CrmType.Opportunity,
  validity: CrmValidity.Valid,
  salesAccount: null,
  title: null,
  vlAgreementNumber: null,
};

export const opportunitySimple: OpportunitySimple = {
  ...crmLead,
  __typename: 'OpportunitySimple',
  type: CrmType.Opportunity,
  contact: null,
};

export const images: Images = {
  __typename: 'Images',
  logo: null,
  medium: null,
  small: null,
};

//#region Agreement
export const modernAgreementParticipant: ModernAgreementParticipant = {
  __typename: 'ModernAgreementParticipant',
  id: null,
  companyId: null,
  email: null,
  firstName: null,
  lastName: null,
  role: null,
  status: null,
  statusDate: null,
  subtype: null,
  type: null,
};

export const modernAgreementActual: ModernAgreementActual = {
  __typename: 'ModernAgreementActual',
  messages: [],
  acceptanceMode: null,
  acceptances: null,
  customerId: null,
  customerType: null,
  documentAssemblyDate: null,
  documentDisplayUri: null,
  documentDownloadUri: null,
  documentFileName: null,
  documentId: null,
  endEffectiveDate: null,
  etag: null,
  languageLocale: null,
  links: null,
  number: null,
  participants: null,
  redirectUri: null,
  startEffectiveDate: null,
  status: null,
};

export const modernAgreementActualEdge: ModernAgreementActualEdge = {
  __typename: 'ModernAgreementActualEdge',
  node: modernAgreementActual,
  cursor: null,
};

export const modernAgreementActualConnection: ModernAgreementActualConnection = {
  __typename: 'ModernAgreementActualConnection',
  edges: [],
  pageInfo: null,
};
//#endregion

//#region Products
export const catalogContext: CatalogContext = {
  __typename: 'CatalogContext',
  action: CatalogAction.Browse,
  agreementType: AgreementType.Modern,
  audience: ProductAudience.DirectCommercial,
  languages: GqlLanguage.EnUs,
  market: Market.Us,
  nationalCloud: NationalCloud.Global,
};

export const product: Product = {
  __typename: 'Product',
  id: '',
  catalogContext,
  filteredSkus: [],
  filteredSkusRaw: '[]',
  filters: [],
  images,
  isPotentiallyDiscountable: false,
  productFamily: '',
  productType: '',
  recommendationGroups: [],
  relatedProducts: [],
  skuCount: 0,
  skus: [],
  title: '',
  amount: null,
  categories: null,
  description: null,
  durations: null,
  industries: null,
  inputs: null,
  maxQuantityOnQuote: null,
  plainTextDescription: null,
  productSubtype: null,
  publisherName: null,
  publisherUrl: null,
  reasons: null,
  sku: null,
  startCondition: null,
};

export const azureAccessPassProduct: Product = {
  ...product,
  isPotentiallyDiscountable: true,
  productFamily: 'Azure',
  productType: 'AzureAccessPass',
};

//#endregion

//#region LineItems
export const lineItem: LineItem = {
  id: '',
  catalogContext,
  isConfigurable: false,
  isReadyForPricing: false,
  messages: [],
  availability: null,
  configurationSummary: null,
  groups: null,
  product: null,
  sku: null,
  subtitle: null,
  term: null,
  title: null,
};

export const applicableDiscounts: ApplicableDiscounts = {
  __typename: 'ApplicableDiscounts',
  datePrecision: DatePrecision.Day,
  types: [],
  minStartDate: null,
};

export const discountLineItem: DiscountLineItem = {
  ...lineItem,
  __typename: 'DiscountLineItem',
  applicableDiscounts,
  discount: null,
  exchangeRate: null,
  pricingCurrency: null,
};

export const monetaryLineItem: MonetaryLineItem = {
  ...lineItem,
  __typename: 'MonetaryLineItem',
  isTerm: false,
  duration: null,
  purchaseTermUnits: null,
  reason: null,
};

export const purchaseLineItem: PurchaseLineItem = {
  ...lineItem,
  __typename: 'PurchaseLineItem',
  applicableDiscounts,
  quantity: 0,
  amount: null,
  customerPrice: null,
  exchangeRate: null,
  isAutoRenew: null,
  listPrice: null,
  oneTimeDiscount: null,
  pricingCurrency: null,
};
//#endregion

//#region Approval
export const empoweredUsers: EmpoweredUsers = {
  __typename: 'EmpoweredUsers',
  hierarchyLevel: '',
  level: '',
  market: Market.Us,
  policy: '',
  users: [user],
};

export const requiredApproval: RequiredApproval = {
  __typename: 'RequiredApproval',
  autoPopulate: false,
  empoweredUsers,
  level: '',
  order: 1,
  policy: '',
  actor: null,
  assignedUser: null,
  assignedUsers: null,
  state: null,
};

export const approval: Approval = {
  __typename: 'Approval',
  id: '',
  history: [],
  status: ApprovalStatus.Pending,
  quote: null,
  requiredApprovals: null,
};

export const quoteApproval: QuoteApproval = {
  __typename: 'QuoteApproval',
  workflow: '',
  requiredApprovals: null,
};
//#endregion

//#region Organization
export const invitedUserSimple: InvitedUserSimple = {
  __typename: 'InvitedUserSimple',
  email: 'email',
  oid: null,
  tenant: null,
};

export const account: Account = {
  __typename: 'Account',
  id: 'id',
  assets: [],
  externalIds: [],
  tenants: [],
  cloudScope: null,
  description: null,
  primaryTenantId: null,
  purchaseMotion: null,
};

export const billingGroupConnection: BillingGroupConnection = {
  __typename: 'BillingGroupConnection',
  edges: null,
  pageInfo: null,
};

export const organizationSimple: OrganizationSimple = {
  __typename: 'OrganizationSimple',
  id: 'id',
  account,
  assets: [],
  accountId: 'accountId',
  address,
  billingGroups: billingGroupConnection,
  name: 'name',
  type: OrganizationType.Organization,
  version: 1,
  agreementSigned: null,
  audience: null,
  credit: null,
  customerTag: null,
  language: null,
  languageInfo: null,
  lastInvoiceDate: null,
  roleAssignments: null,
  tradeName: null,
  vatId: null,
};

export const soldToSimple: SoldToSimple = {
  __typename: 'SoldToSimple',
  quoteId: QUOTE_ID,
  dealEstimate: null,
  monthlyCreditLimit: null,
  organization: null,
};
//#endregion

export const quote: Quote = {
  __typename: 'Quote',
  agreementType: AgreementType.Modern,
  agreements: modernAgreementActualConnection,
  assignedTo: UserGroup.Field,
  audience: Audience.Commercial,
  billingCurrency: '',
  canShareLeadDiscounts: false,
  clouds: [],
  etag: '',
  fgoe: false,
  id: QUOTE_ID,
  lineItems: [],
  market: '',
  messages: [],
  motion: Motion.Direct,
  name: '',
  productAudience: ProductAudience.DirectCommercial,
  readOnly: false,
  recommendations: [],
  revision: 1,
  soldTo: soldToSimple,
  status: QuoteStatus.Draft,
  totalPrice: 0,
  transactOnBehalf: false,
  transactionModel: TransactionModel.ToCustomer,
  version: '',
  approval: null,
  continuationToken: null,
  crmLead: null,
  endCustomer: null,
  exchangeRateDate: null,
  expirationDate: null,
  invitedUser: null,
  languageInfo: null,
  languages: null,
  leadOrganization: null,
  modifiedApiVersion: null,
  modifiedDate: null,
  msContact: null,
  vlAgreement: null,
  vlAgreementNumber: null,
};

export const quoteData: QuoteData = {
  ...quote,
  endCustomerInvitedUser: null,
  soldToInvitedUser: null,
};
