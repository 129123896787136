import { ThemeProps } from 'styles';

export const addOnsListStyles = (theme: ThemeProps) => {
  return {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    list: {
      marginBottom: '15px',
    },
    group: {
      marginTop: '8px',
    },
    rowFlex: {
      display: 'flex',
    },
    column: {
      flex: 1,
      lineHeight: 0.9,
    },
    rightAlign: {
      textAlign: 'right',
      marginRight: 10,
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 4,
      color: theme.palette.text,
      fontSize: theme.fonts.fontSizes.small,
    },
    subtitle: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 4,
      color: theme.palette.textTertiary,
    },
    button: {
      fontSize: theme.fonts.fontSizes.small,
    },
  };
};
