import * as React from 'react';
import {
  CheckboxVisibility,
  SelectionMode,
  IColumn,
  DetailsRow,
  IDetailsRowProps,
  IRenderFunction,
} from 'office-ui-fabric-react';
import withStyles, { WithStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import i18next from 'i18n';
import {
  DetailsList,
  IconButton,
  TextBody,
  TextWatermark,
  SectionSeparator,
  TextWatermarkSmall,
} from 'components/ions';
import { servicesConfigCardListStyles } from './ServicesConfigCard.styles';
import moment from 'moment';
import { useRef } from 'react';
import { dateFormatLl } from 'components/utilities/dates';
import { EcifConfiguration } from 'generated/graphql';
import { EcifLineItemValues } from './ServicesConfigCard';
import { mergeClassNames } from 'components';

const lineItemColumns = [
  {
    key: 'type',
    minWidth: 100,
    name: i18next.t('quote::Type'),
    fieldName: 'type',
    maxWidth: 100,
  },
  {
    key: 'productFamily',
    minWidth: 160,
    name: i18next.t('quote::Product Family'),
    fieldName: 'productFamily',
    maxWidth: 160,
  },
  {
    key: 'description',
    minWidth: 230,
    name: i18next.t('quote::Description'),
    fieldName: 'description',
    maxWidth: 230,
  },
  {
    key: 'endDate',
    minWidth: 120,
    name: i18next.t('quote::End Date'),
    fieldName: 'endDate',
    maxWidth: 120,
  },
  {
    key: 'amount',
    minWidth: 80,
    name: i18next.t('quote::Amount'),
    fieldName: 'amount',
    maxWidth: 80,
  },
  {
    key: 'delete',
    minWidth: 30,
    name: i18next.t('quote::Delete'),
    fieldName: 'delete',
    maxWidth: 30,
  },
];

export interface ServicesConfigCardListProps {
  lineItems: EcifLineItemValues[];
  onDeleteClicked: (lineItem: any) => void;
  ecifConfig?: EcifConfiguration;
  totalLineItemAmount: number;
}

type Props = ServicesConfigCardListProps & WithStyles<typeof servicesConfigCardListStyles>;

const ServicesConfigCardListUnstyled: React.FC<Props> = (props: Props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const scrollPaneRef = useRef<HTMLDivElement>(null);
  const [numLineItems, setNumLineItems] = React.useState<number>(props.lineItems.length);

  React.useLayoutEffect(() => {
    const lineItemsAdded = props.lineItems.length > numLineItems;
    if (scrollPaneRef.current && lineItemsAdded) {
      scrollPaneRef.current.scrollTop = scrollPaneRef.current.scrollHeight;
    }
    setNumLineItems(props.lineItems.length);
  }, [props.lineItems, numLineItems]);

  const renderItemColumn = (item?: EcifLineItemValues, index?: number, column?: IColumn) => {
    if (column) {
      const fieldContent = item && (item[column.fieldName as keyof EcifLineItemValues] as string);
      switch (column.key) {
        case 'description':
          return <TextBody title={fieldContent}>{fieldContent}</TextBody>;
        case 'endDate':
          return <TextBody>{moment(fieldContent).format(dateFormatLl)}</TextBody>;
        case 'amount':
          const formattedAmount = Number(fieldContent).toLocaleString();
          return (
            <TextBody addClass={classes.amountText} title={formattedAmount}>
              {formattedAmount}
            </TextBody>
          );
        case 'delete':
          return (
            <IconButton
              addClass={classes.deleteButton}
              iconName="Clear"
              onClick={() => {
                if (index !== undefined) props.onDeleteClicked(index);
              }}
            />
          );
        default:
          return <TextBody>{fieldContent}</TextBody>;
      }
    }
    return;
  };

  const renderRow: IRenderFunction<IDetailsRowProps> = props => {
    if (!props) {
      return null;
    }
    if (props.item) {
      let currentDate = new Date();
      let lineItemEndDate = new Date(props.item.endDate);
      // Using setHours() to allow line items to have today's date
      if (lineItemEndDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
        return (
          <>
            <DetailsRow {...props} styles={{ root: classes.errorStyle }} />
            <TextWatermarkSmall
              addClass={mergeClassNames([classes.lineItemMessage, classes.errorStyle])}
            >
              {t('quote::End date must be in the future.')}
            </TextWatermarkSmall>
          </>
        );
      }
    }
    return <DetailsRow {...props} styles={{ root: classes.detailsRowStyle }} />;
  };

  const lineItemsAddedView = (
    <div>
      <div className={classes.scrollablePaneWrapper} ref={scrollPaneRef}>
        <DetailsList
          addClass={classes.lineItemsList}
          checkboxVisibility={CheckboxVisibility.hidden}
          columns={lineItemColumns}
          compact
          isHeaderVisible={false}
          items={props.lineItems}
          selectionMode={SelectionMode.none}
          onRenderItemColumn={renderItemColumn}
          onRenderRow={renderRow}
        />
      </div>
      <SectionSeparator addClass={classes.sectionSeparator} />
      <div className={classes.totalAmount}>
        <TextBody>{props.totalLineItemAmount.toLocaleString()}</TextBody>
      </div>
    </div>
  );

  return (
    <div className={classes.listSectionWrapper}>
      {props.lineItems.length ? (
        lineItemsAddedView
      ) : (
        <div className={classes.watermarkContainer}>
          <TextWatermark>
            {t('quote::To define services, fill in all the fields then click the Add button.')}
          </TextWatermark>
        </div>
      )}
    </div>
  );
};

export const ServicesConfigCardList = withStyles(servicesConfigCardListStyles)(
  ServicesConfigCardListUnstyled
);
