import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { OverviewRow, OverviewRowProps } from './OverviewRow';

const overviewListStyles = {
  listItemPaddings: {
    '& >div': {
      paddingBottom: 24,
    },
    '& >div:last-child': {
      paddingBottom: 0,
    },
  },
};

export interface OverviewListProps {
  rowProps: OverviewRowProps[];
}

type Props = OverviewListProps & WithStyles<typeof overviewListStyles>;

export const OverviewListUnstyled: React.FC<Props> = (props: Props) => {
  const rows = props.rowProps.map((overviewRowProps: OverviewRowProps, index: number) => {
    return (
      <div key={index}>
        <OverviewRow {...overviewRowProps} />
      </div>
    );
  });
  return <div className={props.classes.listItemPaddings}>{rows}</div>;
};

export const OverviewList = withStyles(overviewListStyles)(OverviewListUnstyled);
