import { LinkButton, NotificationItem, TextBodySmall, TextTitle } from 'components';
import { LocationOptions } from 'features-apollo/quote/components/ConfigCards/Cards/types';
import {
  DetailsStyles,
  RenderCeilingDiscount,
  renderDiscounts,
  RenderFutureDiscount,
  renderLabeledList,
  renderLocations,
  renderNotifications,
} from 'features-apollo/quote/components/DetailsPane';
import {
  ExistingDiscount,
  ExistingDiscountLabels,
} from 'features-apollo/quote/components/ExistingDiscountsList';
import { DiscountType, Quote } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { DialogContext } from 'styles';

/**
 *
 *
 * @export
 * @interface DiscountLineItemProps
 *
 * description: lineItem's product description
 * productName: lineItem's product name
 * labels: translated label strings (see getDiscountLabels)
 * startDate: discount start date
 * endDate: discount end date
 * notifications: parsed messages array from the lineItem
 * discounts: existing discounts from custom pricing scope
 * imageUri: product image for display
 * publisherLink: link to the publishers website
 * skus: list of product sku titles
 * discountPercent: localized percentage value of the discount
 * meters: product meters discount applies to
 * priceGuaranteeDate: the price ceiling date
 * duration: discount duration
 * dataAutomationId: test id
 */
export interface DiscountLineItemProps {
  description: string;
  labels: ExistingDiscountLabels;
  discountType?: string;
  productName: string;
  startDate?: string;
  endDate?: string;
  notifications: NotificationItem[];
  discounts?: ExistingDiscount[];
  imageUri?: string | null;
  publisherLink?: React.ReactNode;
  skuTitle?: string;
  skuCount?: number;
  locations: string[];
  commitment?: string;
  discountPercent: string;
  meters?: string[];
  priceGuaranteeDate?: string;
  duration?: string;
  dataAutomationId?: string;
  quote?: Quote;
}

type Props = DiscountLineItemProps & WithStyles<typeof DetailsStyles>;

const getDiscountBlock: React.FunctionComponent<Props> = props => {
  const {
    startDate,
    endDate,
    duration,
    labels,
    classes,
    discountPercent,
    meters,
    priceGuaranteeDate,
  } = props;
  const sharedProps = {
    startDate,
    endDate,
    duration,
    labels,
    classes,
    discountPercent,
  };
  return props.discountType && props.discountType === DiscountType.PriceGuarantee ? (
    <RenderCeilingDiscount {...sharedProps} priceGuaranteeDate={priceGuaranteeDate} />
  ) : (
    <RenderFutureDiscount {...sharedProps} meters={meters} />
  );
};

const DiscountLineItemDetailsUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const {
    classes,
    commitment,
    productName,
    labels,
    description,
    notifications,
    imageUri,
    publisherLink,
    discountType,
    skuTitle,
    skuCount,
    locations,
    discounts,
    quote,
  } = props;

  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);
  const futureText = t('quote::Future');
  const ceilingText = t('quote::Ceiling');

  const dialogProps = {
    props: {
      id: 'Description-dialog-box',
      dialogClose: dialogContext.closeDialog,
      headingText: productName,
      bodyText: description,
      imageUrl: imageUri,
      actionLink: publisherLink,
      dataAutomationId: 'DescriptionDialogBox',
    },
    hasImage: imageUri ? true : false,
    isCustom: false,
  };

  let title = t('quote::Discount');
  if (discountType) {
    title = `${discountType === DiscountType.Future ? futureText : ceilingText} ${title}`;
  }
  const skulabel = `${t('quote::SKUs')}:`;
  const openDialog = () => {
    dialogContext.openDialog(dialogProps);
  };

  let multiSkuSelectionInformation = null;
  if (locations && !!locations.length && skuCount != null) {
    const isAllLocations = locations[0] === LocationOptions.AllLocations;
    let skuLabels = [t('quote:: {{skuCount}} SKUs', { skuCount: skuCount.toString() })];
    if (commitment) {
      skuLabels = [commitment, ...skuLabels];
    }

    multiSkuSelectionInformation = (
      <div>
        {renderLabeledList(t('quote::SKU:'), skuLabels, classes)}
        {isAllLocations
          ? renderLocations([t('quote::All locations')], t('quote::Locations:'), {
              locationsContainer: classes.locationsContainer,
            })
          : renderLocations(locations, t('Locations:'), {
              locationsContainer: classes.locationsContainer,
            })}
      </div>
    );
  }
  return (
    <>
      <div className={classes.heading}>
        <div>
          <TextTitle>{title}</TextTitle>
        </div>
      </div>
      <div className={classes.discountRow}>
        <div className={classes.label}>
          <TextBodySmall>
            <span>{`${t('quote::Product')}:`}</span>
          </TextBodySmall>
        </div>
        <div className={classes.shortText}>
          <TextBodySmall>
            <LinkButton
              dataAutomationId="productDescriptionLink"
              displayText={productName}
              id="product-description-link"
              onClick={openDialog}
            />
          </TextBodySmall>
        </div>
      </div>
      {skuTitle && renderLabeledList(skulabel, [skuTitle], classes)}
      {multiSkuSelectionInformation}
      {discountType && getDiscountBlock(props)}
      {discounts && discounts.length ? renderDiscounts(classes, labels, discounts) : null}
      {renderNotifications(notifications, classes, quote)}
    </>
  );
};

export const DiscountLineItemDetails = withStyles(DetailsStyles)(DiscountLineItemDetailsUnstyled);
