import { mergeClassNames } from 'components/utilities';
import { Icon, ILinkStyles, Link as LinkBase } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { FontSizes } from 'styles';

import { linkStyles } from './Link.styles';

type ActionType = HTMLAnchorElement | HTMLElement | HTMLButtonElement;

export interface LinkAtomProps {
  addClass?: string;
  ariaLabel?: string;
  displayText?: string;
  href?: string;
  id?: string;
  rel?: string;
  iconLeft?: boolean;
  target?: string;
  disabled?: boolean;
  iconName?: string;
  email?: string;
  emailSubject?: string;
  size?: keyof FontSizes;
  title?: string;
  dataAutomationId?: string;
  styles?: Partial<ILinkStyles>;
  onClick?: (event: React.MouseEvent<ActionType, MouseEvent>) => void;
  style?: React.CSSProperties | undefined;
}

type Props = LinkAtomProps & WithStyles<typeof linkStyles>;

const LinkUnstyled: React.FC<Props> = (props: Props) => {
  const linkStyles: ILinkStyles = {
    root: props.classes.root,
    ...props.styles,
  };
  return (
    <LinkBase
      aria-label={props.ariaLabel}
      className={mergeClassNames([props.classes.link, props.addClass])}
      data-automation-id={props.dataAutomationId}
      disabled={props.disabled}
      href={(props.email && `mailto:${props.email}?Subject=${props.emailSubject}`) || props.href}
      id={props.id}
      rel={props.rel}
      style={props.style}
      styles={linkStyles}
      target={(props.email && '_top') || props.target || '_self'}
      title={props.title}
      onClick={props.onClick}
    >
      {props.iconLeft ? (
        <span>
          <Icon className={props.classes.headerIcon} iconName={props.iconName} />
          {props.displayText && (
            <span className={props.classes.linkAlignToIcon}>{props.displayText}</span>
          )}
        </span>
      ) : (
        <span>
          {props.displayText}
          <Icon className={props.classes.iconStyle} iconName={props.iconName} />
        </span>
      )}
    </LinkBase>
  );
};

export const LinkAtom = withStyles(linkStyles)(LinkUnstyled) as React.FC<LinkAtomProps>;
