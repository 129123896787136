import gql from 'graphql-tag';

export const LanguageInfo = gql`
  fragment languageInfo on LanguageInfo {
    gqlCode
    code
    display
    twoLetterCode
  }
`;

export const CurrencyInfo = gql`
  fragment currencyInfo on CurrencyInfo {
    code
    display
  }
`;

export const GetMarketInfo = gql`
  query getMarketInfo($market: Market!) {
    getMarketInfo(market: $market) {
      code
      display
      invoiceLanguages {
        ...languageInfo
      }
      currencies {
        ...currencyInfo
      }
      directCurrencies {
        ...currencyInfo
      }
      webDirectCurrencies {
        ...currencyInfo
      }
      preferredCommunicationLanguages {
        ...languageInfo
      }
      agreementLanguages {
        ...languageInfo
      }
      timezones {
        first
        last
      }
      supports(motion: WebDirect)
      requiresVatId
    }
  }
  ${LanguageInfo}
  ${CurrencyInfo}
`;
