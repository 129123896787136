import {
  AddButton,
  Callout,
  CalloutProps,
  CloseIconButton,
  CompoundButton,
  SectionSeparator,
  TextBody,
  TextBodyLarge,
} from 'components/ions';
import { openCreateProductGroupDialog } from 'features-apollo/components/dialogs/FavoriteProductDialogs';
import {
  GET_QUOTERECOMMENDATIONS,
  UPDATE_FAVORITEGROUP,
} from 'features-apollo/quote/components/queries';
import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { SearchResultProduct } from 'features/catalog';
import {
  BaseRecommendationAction,
  CatalogContext,
  ProductRecommendationAction,
  Query,
  RecommendationGroup,
} from 'generated/graphql';
import {
  DirectionalHint,
  FocusZone,
  FocusZoneDirection,
  IButtonStyles,
  ICalloutContentStyles,
  IFocusTrapZoneProps,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ProductGroups } from 'services/user-preferences/types';
import { DialogContext } from 'styles';

import { useMutation, useQuery } from '@apollo/react-hooks';

import { favoritesCalloutStyles } from './FavoritesCallout.styles';
import { buildOptimisticResponse } from '../utils';

/**
 * Props to FavoritesCallout component content and behavior
 * @prop {string} description - callout description
 * @prop {string} descriptionForGroups - description to display when groups are available (will overwrite description prop)
 * @prop {string} elementIdToFocusOnDismiss - Element id to refocus on when the callout dismiss
 * @prop {string} excludeGroupName - group to exclude from the list
 * @prop {boolean} excludeFavoriteGroup - do not display default group
 * @prop {boolean} hidden - Hides the callout
 * @prop {string} moveFrom - Group name from where the product should be move
 * @prop {string} product - Product from where the callout was open
 * @prop {HTMLDivElement | HTMLSpanElement | null} target - Element use as reference to determine position of the callout
 * @prop {string} title - Text for the header
 * @prop {function} onDismiss - Callback for when callout tries to close.
 */
export interface FavoritesCalloutProps {
  description: string;
  descriptionForGroups?: string;
  elementIdToFocusOnDismiss?: string;
  excludeGroupName?: string;
  hidden: boolean;
  moveFrom?: string;
  product: SearchResultProduct;
  target: HTMLDivElement | HTMLSpanElement | null;
  title: string;
  onDismiss: () => void;
  quoteId?: string;
  catalogContext?: CatalogContext;
  isSave?: boolean;
}

type Props = FavoritesCalloutProps & WithStyles<typeof favoritesCalloutStyles>;

const FavoritesCalloutUnStyled: React.FC<Props> = props => {
  const {
    classes,
    elementIdToFocusOnDismiss,
    hidden,
    isSave,
    moveFrom,
    onDismiss,
    product,
    target,
    quoteId,
  } = props;

  // #region Hooks
  const { t } = useTranslation();
  const dialogContext = React.useContext(DialogContext);
  // #endregion

  // #region Props
  const calloutStyles: Partial<ICalloutContentStyles> = {
    calloutMain: props.classes.calloutMain,
    root: props.classes.root,
  };

  const classNameForFocus = 'focusable';
  const focusTrapProps: IFocusTrapZoneProps = {
    firstFocusableSelector: classNameForFocus,
    forceFocusInsideTrap: false,
  };

  const calloutProps: CalloutProps = {
    alignTargetEdge: true,
    directionalHint: DirectionalHint.rightCenter,
    focusTrapProps,
    hidden,
    isBeakVisible: true,
    onDismiss,
    preventDismissOnLostFocus: false,
    preventDismissOnScroll: false,
    target,
    trapFocus: true,
    setInitialFocus: false,
    styles: calloutStyles,
  };
  // #endregion

  const [userFavoriteProductGroups, setFavoriteGroup] = React.useState<ProductGroups | undefined>(
    undefined
  );

  const [recoGroups, setRecoGroups] = React.useState<RecommendationGroup[] | undefined>(undefined);

  const { data } = useQuery<QueryGetQuoteData>(GET_QUOTERECOMMENDATIONS, {
    variables: { id: props.quoteId, input: props.catalogContext },
    onCompleted: data => {
      data.getQuote && setRecoGroups(data.getQuote.recommendations);
    },
  });

  React.useEffect(() => {
    if (data && data.getQuote && data.getQuote.recommendations) {
      setRecoGroups(data.getQuote.recommendations);
    }
  }, [data]);

  React.useEffect(() => {
    if (recoGroups) {
      const favorites = recoGroups.filter(
        recoGroup => recoGroup.type === 'Product' && recoGroup.name !== 'Azure Essentials'
      );
      const productGroups: ProductGroups = {};

      favorites &&
        favorites.length &&
        favorites.forEach(favorite => {
          const products = favorite.items.map(item => {
            const recommendationItem = item as ProductRecommendationAction;

            return {
              productId: recommendationItem.product.id,
              productIdentifier: {
                productId: recommendationItem.product.id,
              },
              productName: recommendationItem.product.title,
            };
          });
          productGroups[favorite.name] = { products: products };
        });
      setFavoriteGroup(productGroups);
    }
  }, [recoGroups, props.product.productId]);

  const productGroups = userFavoriteProductGroups;
  const productGroupsAvailable = productGroups && { ...productGroups };

  // #region Elements
  const compoundButtonStyles: IButtonStyles = {
    description: classes.groupButtonSecondaryText,
    flexContainer: classes.groupButtonFlexContainer,
    label: classes.groupButtonLabel,
    textContainer: classes.groupButtonTextContainer,
  };
  const [updateToFavorites] = useMutation(UPDATE_FAVORITEGROUP);

  const isEqual = (item: BaseRecommendationAction, product: SearchResultProduct) => {
    const recommendationItem = item as ProductRecommendationAction;
    return recommendationItem.product.id === product.productId;
  };

  const existsInGroup: string[] = [];
  if (productGroupsAvailable) {
    Object.keys(productGroupsAvailable).forEach(group => {
      productGroupsAvailable[group].products.forEach(prod => {
        if (prod.productId === product.productId) {
          existsInGroup.push(group);
        }
      });
    });
  }

  const existingGroupsButtons: React.ReactNode[] | undefined =
    productGroupsAvailable &&
    Object.keys(productGroupsAvailable).map(groupName => (
      <React.Fragment key={`add-product-to-group-${groupName}`}>
        <CompoundButton
          addClass={`${classNameForFocus} ${classes.groupButton}`}
          disabled={existsInGroup.includes(groupName)}
          secondaryText={t('{{count}} product', {
            count: productGroupsAvailable[groupName].products.length,
            defaultValue_plural: '{{count}} products', // eslint-disable-line @typescript-eslint/camelcase
          })}
          styles={compoundButtonStyles}
          onClick={() => {
            updateToFavorites({
              variables: {
                input: {
                  productId: product.productId,
                  addToGroup: groupName,
                  removeFromGroup: isSave ? (moveFrom === 'Favorites' ? moveFrom : null) : moveFrom,
                  catalogContext: props.catalogContext,
                },
              },
              optimisticResponse: buildOptimisticResponse(product, groupName),
              update: (useApolloClient, { data: { updateFavoriteGroup } }) => {
                // Read from cached
                const cached = useApolloClient.readQuery<Query>({
                  query: GET_QUOTERECOMMENDATIONS,
                  variables: { id: props.quoteId, input: props.catalogContext },
                });

                // Create update object
                if (cached && cached.getQuote && cached.getQuote.recommendations) {
                  let cachedRecFav = cached.getQuote.recommendations.find(
                    rec => rec.name === groupName
                  );
                  if (cachedRecFav) {
                    if (
                      updateFavoriteGroup.find((rec: RecommendationGroup) => rec.name === groupName)
                        .items.length > 1
                    ) {
                      // response has MORE THAN one item
                      // overwrite everything (OR do nothing)
                      cachedRecFav.items = updateFavoriteGroup.find(
                        (rec: RecommendationGroup) => rec.name === groupName
                      ).items;
                    } else if (
                      cachedRecFav &&
                      (cachedRecFav.items.length === 0 ||
                        !cachedRecFav.items.some(item => isEqual(item, product)))
                    ) {
                      // response has ONLY one item
                      // and cache has 0 items OR cache doesn't already contains same item

                      // concat response to cached object
                      cachedRecFav.items = cachedRecFav.items.concat(
                        updateFavoriteGroup.find(
                          (rec: RecommendationGroup) => rec.name === groupName
                        ).items
                      );
                    }

                    let cachedRecsFiltered = cached.getQuote.recommendations.filter(
                      rec => rec.name !== groupName
                    );
                    cachedRecsFiltered.push(cachedRecFav);

                    let cachedRecs = cached.getQuote.recommendations;
                    if (!isSave || moveFrom === 'Favorites') {
                      let cachedRecsFav = cachedRecs.find(
                        (rec: RecommendationGroup) => rec.name === moveFrom
                      );
                      if (cachedRecsFav) {
                        const index = cachedRecsFav.items.findIndex(item => isEqual(item, product));
                        cachedRecsFav.items.splice(index, 1);
                      }
                    }
                  }
                }

                // Write updated clone object to cache
                useApolloClient.writeQuery({
                  query: GET_QUOTERECOMMENDATIONS,
                  variables: { id: props.quoteId, input: props.catalogContext },
                  data: cached,
                });
              },
            });
          }}
        >
          {groupName}
        </CompoundButton>
      </React.Fragment>
    ));

  const existingGroups = existingGroupsButtons && !!existingGroupsButtons.length && (
    <FocusZone direction={FocusZoneDirection.vertical}>
      <div className={classes.groupButtons}>{existingGroupsButtons}</div>
      <SectionSeparator addClass={classes.separator} />
    </FocusZone>
  );

  const closeButton = onDismiss && (
    <CloseIconButton
      addClass={classes.closeButton}
      ariaLabel={t('Close')}
      dataAutomationId="closeButton"
      onClick={onDismiss}
    />
  );
  // #endregion

  return (
    <Callout {...calloutProps}>
      <div
        className={
          existingGroups ? `${classes.content} ${classes.reduceBottomPadding}` : classes.content
        }
      >
        <div className={classes.header}>
          <TextBodyLarge addClass={classes.title}>{props.title}</TextBodyLarge>
          {closeButton}
        </div>
        <TextBody>
          {existingGroups && props.descriptionForGroups
            ? props.descriptionForGroups
            : props.description}
        </TextBody>
        <div>
          {existingGroups}
          <AddButton
            addClass={`focusable ${classes.createGroupButton}`}
            dataAutomationId="createGroup"
            predefinedIconName="Add"
            text={t('Create new group')}
            onClick={() =>
              openCreateProductGroupDialog(dialogContext, {
                elementIdToFocusOnDismiss,
                moveFrom,
                product,
                quoteId: quoteId,
                favoritesProductGroups: userFavoriteProductGroups,
                catalogContext: props.catalogContext,
              })
            }
          />
        </div>
      </div>
    </Callout>
  );
};

export const FavoritesCallout = withStyles(favoritesCalloutStyles)(
  FavoritesCalloutUnStyled
) as React.FC<FavoritesCalloutProps>;
