import { TeachingBubbleAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import {
  DirectionalHint,
  ICalloutProps,
  ITeachingBubbleStyles,
  Target,
} from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles';

export interface TeachingBubbleProps {
  addClassName?: string;
  closeButtonAriaLabel: string;
  dataAutomationId?: string;
  directionalHint?: DirectionalHint;
  error?: boolean;
  headline: string;
  maxWidth?: number;
  target: Target;
  styles?: Partial<ITeachingBubbleStyles>;
  onDismiss: () => void;
}

export const teachingBubbleStyles = (theme: ThemeProps) => {
  return {
    closeButton: {
      marginTop: 0,
      marginRight: 0,
      '& i': {
        fontSize: theme.fonts.fontSizes.small,
      },
    },
    content: {
      '& .ms-TeachingBubble-body': {
        marginBottom: 0,
      },
      '& button:hover': {
        color: theme.palette.titleBarPrimaryText,
        '& i': {
          color: theme.palette.titleBarPrimaryText,
        },
      },
    },
    maxWidth: {
      maxWidth: (props: TeachingBubbleProps) => {
        return props.maxWidth;
      },
    },
    textColor: {
      color: theme.palette.titleBarPrimaryText,
    },
    errorcalloutMain: {
      background: theme.palette.dangerActive,
      top: '40px',
    },
    errorBeak: {
      background: theme.palette.dangerActive,
    },
    errorBeakCurtain: {
      background: theme.palette.dangerActive,
      top: '40px',
    },
    textBody: {
      color: theme.palette.lightColor,
      '& *': {
        color: `${theme.palette.lightColor} !important`,
      },
      '& > *:not(:last-child)': {
        display: 'block',
        marginBottom: 8,
      },
    },
  };
};

type Props = TeachingBubbleProps & WithStyles<typeof teachingBubbleStyles>;

const TeachingBubbleUnstyled: React.FC<Props> = props => {
  const { classes } = props;
  const teachingBubbleStyles: Partial<ITeachingBubbleStyles> = {
    closeButton: mergeClassNames([classes.closeButton, classes.textColor]),
    content: mergeClassNames([classes.maxWidth, classes.content]),
    headline: classes.textColor,
    header: {
      marginBottom: 2,
    },
    bodyContent: {
      padding: 20,
    },
    subComponentStyles: {
      callout: {
        root: classes.maxWidth,
        calloutMain: props.error && classes.errorcalloutMain,
        beak: props.error && classes.errorBeak,
        beakCurtain: props.error && classes.errorBeakCurtain,
      },
    },
    ...props.styles,
  };

  const calloutProps: ICalloutProps = {
    calloutWidth: props.maxWidth,
    directionalHint: props.directionalHint || DirectionalHint.bottomLeftEdge,
    className: props.addClassName,
  };

  return (
    <TeachingBubbleAtom
      calloutProps={calloutProps}
      closeButtonAriaLabel={props.closeButtonAriaLabel}
      dataAutomationId={props.dataAutomationId}
      hasCloseIcon
      hasCondensedHeadline
      headline={props.headline}
      styles={teachingBubbleStyles}
      target={props.target}
      onDismiss={props.onDismiss}
    >
      <div className={classes.textBody}>{props.children}</div>
    </TeachingBubbleAtom>
  );
};

export const TeachingBubble = withStyles(teachingBubbleStyles)(TeachingBubbleUnstyled);
