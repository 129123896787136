import { TextboxStandard } from 'components/ions';
import { useDebounce } from 'components/utilities/debounce';
import * as appSelectors from 'features/app/selectors';
import * as actions from 'features/proposal/actions';
import {
  AnnualDealEstimate,
  CreditConstant,
  CreditLine,
} from 'features/proposal/components/Dialogs/CreditDialog';
import * as selectors from 'features/proposal/selectors';
import { sharedStyles } from 'features/proposal/shared.styles';
import { Language } from 'features/proposal/supported-languages';
import {
  flexSectionCreator,
  formatCurrency,
  isProposalReadOnly,
  pageSectionCreator,
} from 'features/proposal/utils';
import { KeyCodes } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';

import { salesStyles } from './Sales.styles';

const styles = (theme: ThemeProps) => ({
  ...sharedStyles,
  ...salesStyles(theme),
});

export interface SalesFooterProps {
  quote: Proposal;
}

interface SalesFooterState {
  annualDealEstimate: string;
  annualDealEstimateErrorMessage: string;
}

const localActions = {
  updateAnnualDealEstimate: createStandardAction('ANNUALDEALESTIMATE_UPDATE')<string>(),
  updateAnnualDealEstimateError: createStandardAction('ANNUALDEALESTIMATE_ERROR')<string>(),
};

const reducer = (
  state: SalesFooterState,
  action: ActionType<typeof localActions>
): SalesFooterState => {
  const newState = { ...state };

  switch (action.type) {
    case getType(localActions.updateAnnualDealEstimate):
      newState.annualDealEstimate = action.payload;
      return newState;

    case getType(localActions.updateAnnualDealEstimateError):
      newState.annualDealEstimateErrorMessage = action.payload;
      return newState;

    default:
      return state;
  }
};

const getInitialState = (params: { annualDealEstimate: string }) => {
  return {
    annualDealEstimate: params.annualDealEstimate,
    annualDealEstimateErrorMessage: '',
  };
};

const mapStateToProps = (state: RootState, ownProps: SalesFooterProps) => ({
  isQuotePublished: selectors.isQuotePublished(state),
  enrollmentNumber: selectors.getEnrollmentNumber(state),
  quote: ownProps.quote,
  billingCurrency: selectors.getBillingCurrency(state),
  minCreditLineDivisor: appSelectors.getMinCreditLineDivisor(state),
  wholeNumberRegex: appSelectors.getWholeNumberRegex(state),
});

const dispatchProps = {
  updateLanguage: (request: Language, proposal: Proposal) =>
    actions.updateProposalLanguage(request, proposal),
  updateAnnualDealEstimate: (request: AnnualDealEstimate, proposal: Proposal) =>
    actions.updateAnnualDealEstimate(request, proposal),
};

type Props = SalesFooterProps &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const SalesFooterUnstyled = (props: Props) => {
  const { quote, classes, billingCurrency, updateAnnualDealEstimate } = props;
  const annualDealEstimate$ = useDebounce(400);
  const annualDealEstimate =
    props.quote.header.estimatedDealValue === undefined
      ? ''
      : props.quote.header.estimatedDealValue.toString();
  const { t } = useTranslation();
  const isQuoteReadOnly: boolean = isProposalReadOnly(quote);
  const [annualDealEstimateFocused, setAnnualDealEstimateFocused] = React.useState(false);
  const [state, dispatch] = React.useReducer(
    reducer,
    { annualDealEstimate: annualDealEstimate, minCreditLineDivisor: props.minCreditLineDivisor },
    getInitialState
  );

  React.useEffect(() => {
    dispatch(
      localActions.updateAnnualDealEstimate(
        quote.header.estimatedDealValue ? quote.header.estimatedDealValue.toString() : ''
      )
    );
  }, [quote.header.estimatedDealValue]);

  const annualDealEstimateValue = annualDealEstimateFocused
    ? state.annualDealEstimate
    : !state.annualDealEstimateErrorMessage && state.annualDealEstimate
    ? formatCurrency(state.annualDealEstimate, 0) || ''
    : state.annualDealEstimate;

  const onAnnualDealEstimateChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    if (value && /\s/.test(value)) return;

    dispatch(localActions.updateAnnualDealEstimate(value || ''));
    annualDealEstimate$.next(() => {
      if (value) {
        if (isNaN(+value)) {
          // If not a number dispatch error message.
          dispatch(localActions.updateAnnualDealEstimateError(t('error::Only numbers are valid.')));
        } else if (+value < 0) {
          // If negative number dispatch error message.
          dispatch(
            localActions.updateAnnualDealEstimateError(
              t('error::Must be greater than or equal to 0.')
            )
          );
        } else {
          // If amount is valid remove error if any.
          dispatch(localActions.updateAnnualDealEstimateError(''));
        }
      } else {
        // If amount is valid remove error if any.
        dispatch(localActions.updateAnnualDealEstimateError(''));
      }
    });
  };

  const onAnnualDealEstimateBlur = () => {
    setAnnualDealEstimateFocused(false);
    if (!state.annualDealEstimateErrorMessage && annualDealEstimate !== state.annualDealEstimate) {
      if (state.annualDealEstimate.trim() === '') {
        updateAnnualDealEstimate(
          { annualDealEstimate: undefined, minimumCreditLine: undefined },
          quote
        );
      } else {
        const annualDealEstimate = Number(state.annualDealEstimate);
        const minimumCreditLine = Math.ceil(annualDealEstimate / props.minCreditLineDivisor);
        updateAnnualDealEstimate({ annualDealEstimate, minimumCreditLine }, quote);
      }
    }
  };

  const onAnnualDealEstimateFocus = () => {
    setAnnualDealEstimateFocused(true);
  };

  const setActualAnnualDealEstimate = (value: string) => {
    dispatch(localActions.updateAnnualDealEstimate(value));
  };

  const handleEstimateEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === KeyCodes.enter) {
      setAnnualDealEstimateFocused(false);
      if (
        !state.annualDealEstimateErrorMessage &&
        annualDealEstimate !== state.annualDealEstimate
      ) {
        if (state.annualDealEstimate.trim() === '') {
          updateAnnualDealEstimate(
            { annualDealEstimate: undefined, minimumCreditLine: undefined },
            quote
          );
        } else {
          const annualDealEstimate = Number(state.annualDealEstimate);
          const minimumCreditLine = Math.ceil(annualDealEstimate / props.minCreditLineDivisor);
          updateAnnualDealEstimate({ annualDealEstimate, minimumCreditLine }, quote);
        }
      }
    }
  };

  const annualDealEstimateElement: JSX.Element = flexSectionCreator(
    classes.flexLarge,
    classes.flexXLarge,
    <TextboxStandard
      addClass={`${classes.field} ${classes.paddingBottom8px}`}
      dataAutomationId="salesAnnualDealEstimate"
      disabled={isQuoteReadOnly}
      errorMessage={state.annualDealEstimateErrorMessage}
      id="annualDealEstimateTextbox"
      label={t('quote::Annual deal estimate ({{currency}})', { currency: billingCurrency })}
      maxLength={CreditConstant.maxDealEstimateLength}
      placeholder={t('quote::Estimated value of deal')}
      required={!isQuoteReadOnly}
      value={annualDealEstimateValue}
      onBlur={onAnnualDealEstimateBlur}
      onChange={onAnnualDealEstimateChange}
      onFocus={!isQuoteReadOnly ? onAnnualDealEstimateFocus : undefined}
      onKeyDown={handleEstimateEnter}
    />,
    classes.flexContainer
  );

  const customerFooter = pageSectionCreator(
    [
      annualDealEstimateElement,
      <CreditLine
        annualDealEstimate={annualDealEstimate}
        disableLink={props.isQuotePublished}
        key="creditLine"
        setCustomerFooterAnnualDealEstimate={setActualAnnualDealEstimate}
      />,
    ],
    classes.paddingBottom24px
  );

  return <div>{customerFooter}</div>;
};

const SalesFooterStyled = withStyles(styles)(SalesFooterUnstyled);
export const SalesFooter = connect(mapStateToProps, dispatchProps)(SalesFooterStyled);
