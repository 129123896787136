import { AppEnvironment } from 'features/app/config/type';

export enum Flight {
  /**
   * Owner/Requester: Jason
   * Good Until: Unknown
   */
  allMarketsForClaims = 'allmarketsforclaims',
  /**
   * Allows user to select skus with unspecified locations in production.
   *
   * Owner/Requester: Julie
   * Good Until: Unknown
   */
  allowUnspecifiedOptionInLocationFilters = 'allowunspecifiedoptioninlocationfilters',
  /**
   * Allows to auto-approve quote after been submitted.
   *
   * Owner/Requester: Julie
   * Good Until: Permanent
   * For Testing/Demo Only
   */
  approvalOverride = 'approvaloverride',
  /**
   * Allows to transact quotes with large deals without azure plans.
   *
   * Owner/Requester: Julie
   * Good Until: Permanent
   */
  azurePlanNotifications = 'azureplannotifications',
  /**
   * Feature intent rejected by Treasury, but keeping it in case someday that changes...
   *
   * Owner/Requester: Jason
   * Good Until: Permanent
   */
  delayCreditCheck = 'delaycreditcheck',
  /**
   * Prevents adding an organization to the quote when adding a CRMID, for testing purposes only.
   *
   * Owner/Requester: Jessica
   * Good Until: Permanent
   * For Testing Only
   */
  disableBillingAccountAssociation = 'disablebillingaccountassociation',
  /**
   * Modern office feature
   *
   * Owner/Requester: Julie
   * Good Until: Unknown
   */
  assetManagement = 'assetmanagement',
  /**
   * Owner/Requester: Jennie
   * Good Until: A month after release - 09/01 (with FedGov)
   */
  breadcrumbs = 'breadcrumbs',
  /**
   * Owner/Requester: Megan
   * Good Until: A month after release - 10/15
   */
  csp = 'csp',
  /**
   * Owner/Requester: Megan
   * Good Until: A month after release - 11/10 (tentative)
   */
  customTerms = 'customterms',
  /**
   * Modern office feature
   *
   * Owner/Requester: Julie
   * Good Until: Unknown
   */
  cwaRenewals = 'cwarenewals',
  /**
   * Owner/Requester: Jason/Karina
   * Good Until: A month after release - 09/01
   */
  demoMode = 'demomode',
  /**
   * Owner/Requester: Jason
   * Good Until: A month after release - 10/01
   */
  directFGOE = 'directfgoe',
  /**
   * Owner/Requester: Jason
   * Good Until: A month after release - 10/01
   */
  directGov = 'directgov',
  /**
   * Allows to search for Office products
   *
   * Owner/Requester: Julie
   * Good Until: Permanent
   */
  disableProductInclusionFilter = 'disableproductinclusionfilter',
  /**
   * Owner/Requester: Julie/Neha
   * Good Until: A month after release - 10/15
   */
  ECIF = 'ecif',
  /**
   * Owner/Requester: Jason/Jennie
   * Good Until: A month after release - 09/01
   */
  enrollmentAssembly = 'enrollmentassembly',
  /**
   * Owner/Requester: Julie/Karina
   * Good Until: 4/1
   */
  enableBlendedAutosuggest = 'enableblendedautosuggest',

  /**
   * Owner/Requester: Jason/Jessica
   * Good Until: A month after release - 09/01
   */
  graphqlPhase2 = 'graphqlphase2',
  /**
   * Owner/Requester: Jason
   * Good Until: A month after release - 10/01
   */
  indirectFGOE = 'indirectfgoe',
  /**
   * Owner/Requester: Megan/Jenn
   * Good Until: A month after release - 12/01
   */
  ironCustomTerms = 'ironcustomterms',
  /**
   * Owner/Requester: Megan
   * Good Until: A month after release - 10/15
   */
  jioAsCSP = 'jioascsp',
  /**
   * Modern office feature
   *
   * Owner/Requester: Julie
   * Good Until: Unknown
   */
  modernOffice = 'modernoffice',
  /**
   * Access to CRMID in PreProd
   *
   * Owner/Requester: Jason/Sowmya
   * Good Until: Unknown - Missing migration work
   */
  MSXPPE = 'msxppe',
  /**
   * Hides the feature to allow adding tenants to an account
   *
   * Owner/Requester: Jason
   * Good Until: Unknown
   */
  noAdditionalTenant = 'noadditionaltenant',
  /**
   * Owner/Requester: Mark
   * Good Until: Permanent
   * For Testing Only
   */
  noTestHeaderTesting = 'notestheadertesting',
  /**
   * Owner/Requester: Julie/Jason (Dev)
   * Good Until: 11/15
   */
  npsSurvey = 'npssurvey',
  /**
   * Identifies translated strings where translation is not available.
   *
   * Owner/Requester: Ozgun
   * Good Until: Permanent
   * For Testing Only
   */
  obfuscateTranslations = 'obfuscatetranslations',
  /**
   * Enables UX for Physical Signature for Direct-Commercial quotes.
   *
   * Owner/Requester: Manisha
   * Good Until: A month after release - 10/05
   */
  physicalSignature = 'physicalsignature',
  /**
   * Enables search for a product based on just the PID
   *
   * Owner/Requester: iabowers
   * Good Until: Permanent
   * For Testing Only
   */
  pidSearch = 'pidsearch',
  /**
   * Owner/Requester: Jason
   * Good Until: A month after release - 10/01
   */
  qcTransact = 'qctransact',
  /**
   * Credit needs to be available to publish a quote
   *
   * Owner/Requester: Rakesh
   * Good Until: Permanent
   * For Testing Only
   */
  requiredCredit = 'requiredcredit',
  /**
   * Owner/Requester: Jason
   * Good Until: Replace with test headers
   */
  safelistedCreditCheck = 'safelistedcreditcheck',
  /**
   * Modern office feature
   *
   * Owner/Requester: Julie
   * Good Until: Unknown
   */
  showAddons = 'showaddons',
  /**
   * Owner/Requester: Jason/Karina
   * Good Until: A month after release - 09/01
   */
  showDemoModeButton = 'showdemomodebutton',
  /**
   * Owner/Requester: Julie
   * Good Until: Unknown
   */
  termsFinderSearch = 'termsFinderSearch',
  /**
   * Console log flights
   *
   * Owner/Requester: Ozgun
   * Good Until: Permanent
   * For Testing Only
   */
  TestFlight1 = 'testflight1',
  /**
   * Changes term list to use catalog ones
   *
   * Owner/Requester: Can/Julie/Mark
   * Good Until: 10/1
   *
   */
  consumeCatalogListOfTerms = 'consumecataloglistofterms',
  /* US Nat Cloud
   *
   * Owner/Requester: Jason
   * Release date: Unknown
   */
  usNat = 'usnat',
}

// Any flights enabled here will override control tower
export const productionFlights: Record<Flight, boolean> = {
  // --- Permanent Flights: DO NOT REMOVE ---------------------
  [Flight.approvalOverride]: false,
  [Flight.azurePlanNotifications]: true,
  [Flight.disableBillingAccountAssociation]: false,
  // leave this(noTestHeaderTesting) flight at false since we only use the flag from control tower for a number of specific aliases.
  // See configurations.ts for more info if you need.
  [Flight.noTestHeaderTesting]: false,
  [Flight.obfuscateTranslations]: false,
  [Flight.pidSearch]: false,
  [Flight.requiredCredit]: true,
  [Flight.consumeCatalogListOfTerms]: false,
  [Flight.TestFlight1]: false,
  // --- Temporary Flights: Remove after a month in Production ---------
  [Flight.allMarketsForClaims]: false,
  [Flight.allowUnspecifiedOptionInLocationFilters]: false,
  [Flight.assetManagement]: false,
  [Flight.breadcrumbs]: false,
  [Flight.csp]: false,
  [Flight.customTerms]: false,
  [Flight.cwaRenewals]: false,
  [Flight.ECIF]: false,
  [Flight.enrollmentAssembly]: true,
  [Flight.delayCreditCheck]: false,
  [Flight.demoMode]: false,
  [Flight.directFGOE]: false,
  [Flight.directGov]: false,
  [Flight.disableProductInclusionFilter]: false,
  [Flight.enableBlendedAutosuggest]: true,
  [Flight.graphqlPhase2]: true,
  [Flight.indirectFGOE]: false,
  [Flight.ironCustomTerms]: false,
  [Flight.jioAsCSP]: false,
  [Flight.modernOffice]: false,
  [Flight.MSXPPE]: false,
  [Flight.noAdditionalTenant]: true,
  [Flight.npsSurvey]: false,
  [Flight.physicalSignature]: false,
  [Flight.qcTransact]: false,
  [Flight.safelistedCreditCheck]: false,
  [Flight.showAddons]: false,
  [Flight.showDemoModeButton]: false,
  [Flight.termsFinderSearch]: false,
  [Flight.usNat]: false,
};

export const latestFlights: Record<Flight, boolean> = {
  ...productionFlights,
  [Flight.TestFlight1]: true,
  [Flight.safelistedCreditCheck]: true,
};

export const stableFlights: Record<Flight, boolean> = {
  ...productionFlights,
  [Flight.TestFlight1]: true,
  [Flight.safelistedCreditCheck]: true,
};

export const insiderFlights: Record<Flight, boolean> = {
  ...productionFlights,
  [Flight.TestFlight1]: true,
};

export const demoFlights: Record<Flight, boolean> = {
  ...productionFlights,
  [Flight.approvalOverride]: true,
  [Flight.demoMode]: true,
  [Flight.requiredCredit]: false,
  [Flight.safelistedCreditCheck]: true,
  [Flight.showDemoModeButton]: true,
  [Flight.TestFlight1]: true,
};

export const flightList = Object.keys(productionFlights) as Flight[];

export interface FlightInformation {
  display: string;
  description: string;
  disableInEnvs?: AppEnvironment[];
  expirationDate?: Date;
}

export const flights: Record<Flight, FlightInformation> = {
  [Flight.allMarketsForClaims]: {
    display: 'Allow All Markets For Claims',
    description: 'Allows any country to be selected for a claim or referral',
  },
  [Flight.allowUnspecifiedOptionInLocationFilters]: {
    display: 'Allow Unspecified option',
    description: 'Users can pick the Unspecified option in config cards for location',
  },
  [Flight.approvalOverride]: {
    display: 'Approval Override',
    description: 'Auto approve/reject a quote when submitted',
    disableInEnvs: [AppEnvironment.Prod, AppEnvironment.Test, AppEnvironment.Stable],
  },
  [Flight.azurePlanNotifications]: {
    display: 'Azure plan notification',
    description: 'Show Azure plan notifications',
    disableInEnvs: [AppEnvironment.Test, AppEnvironment.Stable],
  },
  [Flight.assetManagement]: {
    display: 'Asset management',
    description: 'Allows the management of assets',
  },
  [Flight.breadcrumbs]: {
    display: 'Breadcrumbs',
    description: 'Enables breadcrumbs in the Navigation Bar.',
    expirationDate: new Date('October 1 2020'),
  },
  [Flight.csp]: {
    display: 'CSP',
    description: 'Allows CSP quotes to be created',
    expirationDate: new Date('November 15 2020'),
  },
  [Flight.customTerms]: {
    display: 'Custom terms',
    description: 'enable to display custom terms in quote editor',
    expirationDate: new Date('December 10 2020'),
  },
  [Flight.cwaRenewals]: {
    display: 'Enable renewals tab in cwa',
    description: 'Enable renewals tab in cwa as well as quote creation feature',
  },
  [Flight.delayCreditCheck]: {
    display: 'Delay Credit Check',
    description: 'Allows delaying of credit check',
    disableInEnvs: [
      AppEnvironment.Latest,
      AppEnvironment.Local,
      AppEnvironment.Prod,
      AppEnvironment.Stable,
      AppEnvironment.Test,
    ],
  },
  [Flight.demoMode]: {
    display: 'Demo mode theme',
    description: 'Enables Demo mode theme',
    expirationDate: new Date('October 1 2020'),
    disableInEnvs: [AppEnvironment.Prod],
  },
  [Flight.directFGOE]: {
    display: 'Direct FGOE',
    description: 'Allows selection of fgoe on a direct-goverment quote',
    expirationDate: new Date('November 1 2020'),
  },
  [Flight.directGov]: {
    display: 'Direct-Gov',
    description: 'Allows direct-gov quotes to be created',
    expirationDate: new Date('November 1 2020'),
  },
  [Flight.disableBillingAccountAssociation]: {
    display: 'Disable Billing Account Association',
    description: 'Prevents associated billing account to be added to the quote',
    disableInEnvs: [AppEnvironment.Prod, AppEnvironment.Test, AppEnvironment.Stable],
  },
  [Flight.disableProductInclusionFilter]: {
    display: 'Search all products',
    description: 'disable filters applied after product search',
  },
  [Flight.ECIF]: {
    display: 'ECIF',
    description: 'End Customer Investment Fund (ECIF) compliance updates',
    disableInEnvs: [AppEnvironment.Prod],
    expirationDate: new Date('November 15 2020'),
  },
  [Flight.enrollmentAssembly]: {
    display: 'Enrollment Assembly',
    description: 'Allows selection of pre-verified enrollment numbers',
    expirationDate: new Date('October 1 2020'),
  },
  [Flight.enableBlendedAutosuggest]: {
    display: 'Enable blended autosuggest',
    description: 'Enables the uses of the blended autosuggest catalog api endpoint',
    expirationDate: new Date('April 1 2021'),
  },
  [Flight.graphqlPhase2]: {
    display: 'GraphQLPhase2',
    description: 'Apollo client migration and Fed Indirect work',
    expirationDate: new Date('October 1 2020'),
  },

  [Flight.indirectFGOE]: {
    display: 'Indirect FGOE',
    description: 'Allows selection of fgoe on a indirect-goverment quote',
    expirationDate: new Date('November 1 2020'),
  },
  [Flight.ironCustomTerms]: {
    display: 'Iron Custom Terms',
    description: 'Enables requesting and creating Custom Terms on a Quote',
    expirationDate: new Date('January 1 2021'),
  },
  [Flight.jioAsCSP]: {
    display: 'JIO as CSP',
    description: 'Allows JIO as CSP quotes to be created',
    expirationDate: new Date('November 15 2020'),
  },
  [Flight.modernOffice]: {
    display: 'Modern Office',
    description: 'Enable modern office features',
  },
  [Flight.MSXPPE]: {
    display: 'MSX PPE',
    description: 'enable crm requests to be routed to pre-prod',
  },
  [Flight.noAdditionalTenant]: {
    display: 'No additional tenant',
    description: 'Prevent associate additional tenant to account',
  },
  [Flight.noTestHeaderTesting]: {
    display: 'Do not turn this on',
    description: 'Seriously, please do not turn this on',
    disableInEnvs: [
      AppEnvironment.Prod,
      AppEnvironment.Latest,
      AppEnvironment.Local,
      AppEnvironment.Test,
    ],
  },
  [Flight.npsSurvey]: {
    display: 'NPS survey',
    description: 'Net Promoter Score survey',
    expirationDate: new Date('November 15 2020'),
  },
  [Flight.obfuscateTranslations]: {
    display: 'Obfuscate translations',
    description: 'Makes $$$ out of all translations',
  },
  [Flight.physicalSignature]: {
    display: 'Physical Signature',
    description: 'Enables UX for Physical Signature for Direct-Commercial quotes',
  },
  [Flight.pidSearch]: {
    display: 'Product search by PID',
    description: 'Enables search for a product in finder using only product id',
  },
  [Flight.qcTransact]: {
    display: 'QC Transact',
    description: 'Allows JIO as Direct quotes to be created',
    expirationDate: new Date('November 1 2020'),
  },
  [Flight.requiredCredit]: {
    display: 'Required credit',
    description:
      'enable to make credit check mandatory for Publish/Submit, if set false credit will be optional.',
  },
  [Flight.safelistedCreditCheck]: {
    display: 'Safelisted Credit Check',
    description: 'Credit check uses a safelisted organization',
    disableInEnvs: [AppEnvironment.Prod],
  },
  [Flight.showAddons]: {
    display: 'Addons',
    description: 'Displays Addons available to be added to quote on details pane',
  },
  [Flight.consumeCatalogListOfTerms]: {
    description: 'Displays the catalogs dynamic term list in the term',
    display: 'Catalog term list',
    expirationDate: new Date('October 1 2020'),
  },
  [Flight.showDemoModeButton]: {
    display: 'Demo mode',
    description: 'Enables Demo mode button',
    expirationDate: new Date('October 1 2020'),
  },
  [Flight.termsFinderSearch]: {
    display: 'Terms Finder Search',
    description: 'Allows searching of negotiated terms in the Finder',
  },
  [Flight.TestFlight1]: {
    display: 'Test Flight',
    description: 'Console log response from flight service',
  },
  [Flight.usNat]: {
    description: 'Allows US Nat cloud to be selected',
    display: 'US-Nat',
  },
};

export function getFlight(value: string): Flight | undefined {
  return flightList.find(flight => flight.toLowerCase() === value.toLowerCase());
}

export function isFlight(value: string): value is Flight {
  return !!getFlight(value);
}
