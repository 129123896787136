import gql from 'graphql-tag';

import { quoteBodyWithoutProductDetails } from '../queries';

export const GetMeterProduct = gql`
  query GetProduct(
    $id: String!
    $catalogContext: CatalogContextInput!
    $quoteId: String!
    $filter: [KeyValueInput!]
  ) {
    getProduct(id: $id, catalogContext: $catalogContext) {
      filteredSkus(groupBy: ["Location", "TermDescription", "Action"], filter: $filter) {
        filters {
          key
          value
        }
        skus {
          skuId
          title
          availabilities {
            actions
            availabilityId
            meterType
            terms {
              termId
              units
              price(context: { quote: { quoteId: $quoteId } }) {
                currency
                amount
              }
              description
            }
          }
        }
      }
    }
  }
`;
export const ApplyConfigurationMultipleSkus = gql`
  mutation ApplyConfigurationMultipleSkus(
    $quote: QuoteMutationInput!
    $configuration: ApplyConfigurationMultipleSkusInput!
  ) {
    applyConfigurationMultipleSkus(quote: $quote, configuration: $configuration) {
      ...QuoteBodyWithoutProductDetails
    }
  }
  ${quoteBodyWithoutProductDetails}
`;
