import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { currencyMapStyles } from './CurrencyMap.styles';

export interface CurrencyMapProps {
  currencies?: ICurrencyLayout[];
}

export interface ICurrencyLayout {
  id: string;
  code: string;
  currency: string;
}

type Props = CurrencyMapProps & WithStyles<typeof currencyMapStyles>;

const CurrencyMapUnstyled: React.FC<Props> = props => {
  const { classes, currencies } = props;
  let currencyMap = currencies || [];
  const currenciesToDisplay = currencyMap.map(currencyElement => (
    <div className={classes.innerCurrencies} key={currencyElement.code}>
      <div>{currencyElement.code}</div>
      <div />
      <div>{currencyElement.currency}</div>
    </div>
  ));
  return (
    <div id="CurrencyMap">
      <div className={classes.currencies}>{currenciesToDisplay}</div>
    </div>
  );
};

export const CurrencyMap = withStyles(currencyMapStyles)(CurrencyMapUnstyled) as React.FC<
  CurrencyMapProps
>;
