import { ThemeProps } from 'styles';
import { sharedStyles } from 'styles/shared.styles';

export const buttonAtomStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    main: {
      ...sharedStyles(theme).roundedCorners,
      borderColor: 'transparent',
      fontSize: theme.fonts.fontSizes.medium,
      fontWeight: theme.fonts.fontWeights.regular,
      height: 30,
      paddingRight: 18,
      paddingLeft: 18,
      '& .ms-Button-label': {
        fontWeight: theme.fonts.fontWeights.regular,
      },
      '&:disabled': {
        '& i': {
          color: palette.textDisabled,
        },
      },
    },
    icon: {
      color: theme.palette.text,
      fontSize: theme.fonts.fontSizes.large,
      marginLeft: 0,
    },
  };
};
