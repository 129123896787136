import {
  CustomDialogBox,
  DialogHeader,
  PrimaryButton,
  SecondaryButton,
  TextBody,
  TextTitle,
  XLargeIcon,
} from 'components';
import { DELETE_QUOTE, GET_QUOTES } from 'features-apollo/quote/components/queries';
import * as actions from 'features/proposal/actions';
import { OrderInput, Query } from 'generated/graphql';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { AsyncState } from 'store/types';
import { DialogContext } from 'styles';

import { useMutation } from '@apollo/react-hooks';

import { DialogLayout, DialogStatusBody, DialogStatusType } from '../Shared';
import { deleteProposalDialogStyles } from './DeleteDialog.styles';

export interface DeleteProposalDialogProps {
  id?: string;
  proposalId: string;
  proposalEtag?: string;
  searchTerm?: string;
  sortQuery?: OrderInput;
}

const dispatchProps = {
  resetDialog: () => actions.resetDeleteProposalDialog(),
};

type Props = DeleteProposalDialogProps &
  typeof dispatchProps &
  WithStyles<typeof deleteProposalDialogStyles>;

const DeleteProposalDialogUnstyled: React.FC<Props> = ({
  classes,
  id,
  resetDialog,
  proposalId,
  proposalEtag,
  searchTerm,
  sortQuery,
}: Props) => {
  const [dialogState, setDialogState] = React.useState<AsyncState>(AsyncState.Unset);
  const [deleteQuote, { error, loading }] = useMutation(DELETE_QUOTE, {
    onCompleted: data => {
      if (data && data.deleteQuote) {
        setDialogState(AsyncState.Success);
      }
    },
  });

  React.useEffect(() => {
    if (loading) {
      setDialogState(AsyncState.Request);
    }
    if (error) {
      setDialogState(AsyncState.Failure);
    }
  }, [loading, error]);

  const context = React.useContext(DialogContext);
  const { t } = useTranslation();

  const closeDialog = () => {
    resetDialog();
    context.closeDialog();
  };

  const handleDeleteProposal = () => {
    deleteQuote({
      variables: {
        input: {
          id: proposalId,
          etag: proposalEtag || '',
        },
      },
      update: (cache, { data: { deleteQuote } }) => {
        // Read from cached
        const cached = cache.readQuery<Query>({
          query: GET_QUOTES,
          variables: {
            query: searchTerm || '',
            sort: [
              {
                orderDirection: (sortQuery && sortQuery.orderDirection) || '',
                orderField: (sortQuery && sortQuery.orderField) || '',
              },
            ],
          },
        });

        // Create update object
        if (cached?.getQuotes && deleteQuote) {
          const updatedList = cached.getQuotes.filter(quote => quote.id !== proposalId);

          cache.writeQuery({
            query: GET_QUOTES,
            variables: {
              query: '',
              sort: [
                {
                  orderDirection: sortQuery ? sortQuery.orderDirection : '',
                  orderField: sortQuery ? sortQuery.orderField : '',
                },
              ],
            },
            data: { getQuotes: updatedList },
          });
        }
      },
    });
  };

  const warningLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        dataAutomationId="deleteQuote"
        dialogClose={closeDialog}
        headerClass={classes.customDialogHeader}
      >
        <TextTitle>{t('quote::Delete quote?')}</TextTitle>
      </DialogHeader>
    ),
    body: (
      <div className={classes.customDialogBody}>
        <TextBody>
          {t(
            'quote::Deleting a quote will make it unretrievable and permanently removed from Quote Center.'
          )}
        </TextBody>
      </div>
    ),
    footer: (
      <div className={classes.footer}>
        <PrimaryButton
          autoFocus={true}
          dataAutomationId="quoteDeleteButton"
          text={t('quote::Delete')}
          onClick={handleDeleteProposal}
        />
        <SecondaryButton
          dataAutomationId="quoteDeleteCancelButton"
          text={t('quote::Cancel')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  const processingLayout: DialogLayout = {
    body: (
      <div className={classes.customDialogContainer}>
        <DialogStatusBody
          status={DialogStatusType.Processing}
          text={t('quote::Deleting quote...')}
        />
      </div>
    ),
  };

  const successLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        closeButtonContainerClass={classes.closeButtonNoTitle}
        dataAutomationId="successDeleteQuote"
        dialogClose={closeDialog}
      />
    ),
    body: (
      <div className={classes.customDialogContainer}>
        <DialogStatusBody
          status={DialogStatusType.Success}
          text={t('quote::The quote has been deleted.')}
        />
      </div>
    ),
    footer: (
      <div className={classes.errorFooter}>
        <PrimaryButton
          dataAutomationId="successDeleteQuoteCloseButton"
          text={t('quote::Close')}
          onClick={closeDialog}
        />
      </div>
    ),
  };

  const errorLayout: DialogLayout = {
    header: (
      <DialogHeader
        closeButtonClass={classes.customDialogCloseButton}
        closeButtonContainerClass={classes.closeButtonNoTitle}
        dataAutomationId="failedDeleteQuote"
        dialogClose={closeDialog}
      />
    ),
    body: (
      <div className={classes.customDialogContainer}>
        <div className={`${classes.iconContainer} ${classes.customDialogFailIcon}`}>
          <XLargeIcon addClass={classes.customDialogIcon} iconName="Cancel" />
        </div>
        <div>
          <TextBody addClass={classes.text}>Failed to delete quote. Please try again.</TextBody>
        </div>
      </div>
    ),
    footer: (
      <div className={classes.errorFooter}>
        <PrimaryButton
          dataAutomationId="quoteDeleteTryAgainButton"
          disabled={false}
          text={t('quote::Try again')}
          onClick={handleDeleteProposal}
        />
      </div>
    ),
  };

  const getLayout = () => {
    switch (dialogState) {
      case AsyncState.Request:
        return processingLayout;
      case AsyncState.Success:
        return successLayout;
      case AsyncState.Failure:
        return errorLayout;
      default:
        return warningLayout;
    }
  };

  const currentLayout = getLayout();

  return (
    <div>
      <CustomDialogBox
        bodySlot={currentLayout.body}
        footerSlot={currentLayout.footer}
        headerSlot={currentLayout.header}
        height={224}
        id={id}
        width={338}
      />
    </div>
  );
};

export const DeleteProposalDialogUnconnected = withStyles(deleteProposalDialogStyles)(
  DeleteProposalDialogUnstyled
);

export const DeleteProposalDialog = connect(null, dispatchProps)(DeleteProposalDialogUnconnected);
