import { ThemeProps } from 'styles/theme/themes';

export const finderErrorStyles = (theme: ThemeProps) => ({
  body: {
    color: theme.palette.dangerText,
    fontSize: theme.fonts.fontSizes.small,
  },
  cancel: {
    padding: '2px 0px 0px 75px',
    '&:hover': {
      color: theme.palette.text,
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: theme.palette.text,
      backgroundColor: 'transparent',
    },
    '&:active:hover': {
      color: theme.palette.text,
      backgroundColor: 'transparent',
    },
    '& i': {
      color: theme.palette.text,
      fontSize: theme.fonts.fontSizes.small,
    },
  },
  header: {
    display: 'flex',
  },
  title: {
    color: theme.palette.dangerText,
    fontSize: theme.fonts.fontSizes.small,
    fontWeight: theme.fonts.fontWeights.semiBold,
  },
});
