import { InfoCalloutButton, TextBodySmall, TextSubsectionHeading } from 'components';
import { SectionSeparator, TextboxStandard } from 'components/ions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { SeatsCardStyles } from './SeatsCard.styles';
import { SeatsCardInfoCallout } from './SeatsInfoCallout';
import { SeatsCardState } from './types';

export interface SeatsCardBodyProps {
  seatsCardState: SeatsCardState;
  minSeats: number;
  maxSeats: number;
  sumOfQuantityExceptCurrentItem: number;
  purchaseAtStart: number;
  onSeatsAmountChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
}

type Props = SeatsCardBodyProps & WithStyles<typeof SeatsCardStyles>;

export const SeatsCardBodyUnstyled: React.FC<Props> = (props: Props) => {
  const {
    classes,
    seatsCardState,
    minSeats,
    maxSeats,
    onSeatsAmountChange,
    sumOfQuantityExceptCurrentItem,
    purchaseAtStart,
  } = props;
  const { t } = useTranslation();

  const [infoOpen, setInfoOpen] = React.useState<boolean>(false);

  const [sumOnQuoteInfo, setSumOnQuoteInfo] = React.useState<boolean>(false);
  const [purchaseAtStartInfo, setPurchaseAtStartInfo] = React.useState<boolean>(false);

  const errorMessageStyles = seatsCardState.isWarning
    ? classes.warningMessageStyle
    : classes.errorMessageStyle;

  const renderInfoButton = () => {
    return (
      <>
        <div className={props.classes.infoButtonContainer} id="info-button-container">
          <InfoCalloutButton
            addClass={props.classes.infoButton}
            iconName="Info"
            id="seats-card-info-button"
            onClick={() => setInfoOpen(!infoOpen)}
          />
        </div>
        {infoOpen && (
          <SeatsCardInfoCallout
            headline={t('quote::Configured')}
            message={t(
              'quote::This represents the number of seats you currently have indicated you want for this product.'
            )}
            target="#info-button-container"
            onDismiss={() => setInfoOpen(false)}
          />
        )}
      </>
    );
  };

  const renderSumOnQuoteInfoButton = () => {
    return (
      <>
        <div className={classes.infoButtonContainer} id="sum-on-quote-info-button-container">
          <InfoCalloutButton
            addClass={classes.infoButton}
            iconName="Info"
            id="seats-card-info-button"
            onClick={() => setSumOnQuoteInfo(!sumOnQuoteInfo)}
          />
        </div>
        {sumOnQuoteInfo && (
          <SeatsCardInfoCallout
            headline={t('quote::Sum on quote')}
            message={t(
              `quote::This represents the number of seats of other line items on this quote that impact this line item's seat constraints.`
            )}
            target="#sum-on-quote-info-button-container"
            onDismiss={() => setSumOnQuoteInfo(false)}
          />
        )}
      </>
    );
  };

  const renderSumOnQuote = () => {
    if (sumOfQuantityExceptCurrentItem > 0) {
      return (
        <>
          <TextBodySmall addClass={classes.sumOnQuoteLabel}>
            {t('quote::Sum on Quote')}
            {renderSumOnQuoteInfoButton()}
          </TextBodySmall>
          <TextBodySmall addClass={classes.sumOnQuoteValue}>
            {sumOfQuantityExceptCurrentItem}
          </TextBodySmall>
        </>
      );
    }
  };

  const renderPurchaseInfoButton = () => {
    return (
      <>
        <div className={classes.infoButtonContainer} id="purchase-info-button-container">
          <InfoCalloutButton
            addClass={classes.infoButton}
            iconName="Info"
            id="seats-card-info-button"
            onClick={() => setPurchaseAtStartInfo(!purchaseAtStartInfo)}
          />
        </div>
        {purchaseAtStartInfo && (
          <SeatsCardInfoCallout
            headline={t('quote::Purchased at start')}
            message={t(
              `quote::This represents the number of seats of your customer's existing active subscription at this line item's start date.`
            )}
            target="#purchase-info-button-container"
            onDismiss={() => setPurchaseAtStartInfo(false)}
          />
        )}
      </>
    );
  };

  const renderPurchasedAtStart = () => {
    if (purchaseAtStart > 0) {
      return (
        <>
          <TextBodySmall addClass={classes.purchasedAtStartLabel}>
            {t('quote::Purchased seats')}
            {renderPurchaseInfoButton()}
          </TextBodySmall>
          <TextBodySmall addClass={classes.purchasedAtStartValue}>{purchaseAtStart}</TextBodySmall>
        </>
      );
    }
  };

  return (
    <>
      <div className={classes.textBoxDivMargin}>
        {seatsCardState.isWarning ? (
          <TextboxStandard
            addClass={classes.seatsInputContainerClass}
            ariaLabel={t('quote::Seats')}
            autoFocus
            dataAutomationId="seatsTextbox"
            errorMessageStyle={errorMessageStyles}
            selectOnFocus
            suffix={t('quote::Seat(s)')}
            value={seatsCardState.quantity.toString()}
            warningMessage={seatsCardState.errorMessage}
            onChange={onSeatsAmountChange}
          />
        ) : (
          <TextboxStandard
            addClass={classes.seatsInputContainerClass}
            ariaLabel={t('quote::Seats')}
            autoFocus
            dataAutomationId="seatsTextbox"
            errorMessage={seatsCardState.errorMessage}
            errorMessageStyle={errorMessageStyles}
            selectOnFocus
            suffix={t('quote::Seat(s)')}
            value={seatsCardState.quantity.toString()}
            onChange={onSeatsAmountChange}
          />
        )}
      </div>
      <SectionSeparator />
      <div className={classes.gridContainer}>
        <TextSubsectionHeading addClass={props.classes.seatConstraints}>
          {t('quote::Seat Constraints')}
        </TextSubsectionHeading>
        <div className={classes.flexRow}>{renderSumOnQuote()}</div>
        <div className={classes.flexRow}>{renderPurchasedAtStart()}</div>
        <div className={classes.flexRow}>
          <TextBodySmall addClass={classes.label}>
            {t('quote::Configured')}
            {renderInfoButton()}
          </TextBodySmall>
          <TextBodySmall addClass={classes.value}>{seatsCardState.quantity}</TextBodySmall>
        </div>
        <SectionSeparator addClass={classes.separatorClass} />

        <div className={classes.flexRow}>
          <TextSubsectionHeading addClass={classes.totalSeatsLabel}>
            {t('quote::Total Seats')}
          </TextSubsectionHeading>
          <TextSubsectionHeading addClass={classes.totalSeatsValue}>
            {seatsCardState.totalSeats}
          </TextSubsectionHeading>
        </div>
        <div className={classes.flexRow}>
          <TextBodySmall addClass={classes.minimumLabel}>{t('quote::Minimum')}</TextBodySmall>
          <TextBodySmall addClass={classes.minimumValue}>{minSeats}</TextBodySmall>
        </div>
        <div className={classes.flexRow}>
          <TextBodySmall addClass={classes.maximumLabel}>{t('quote::Maximum')}</TextBodySmall>
          <TextBodySmall addClass={classes.maximumValue}>{maxSeats}</TextBodySmall>
        </div>
      </div>
    </>
  );
};

export const SeatsCardBody = withStyles(SeatsCardStyles)(SeatsCardBodyUnstyled);
