import { SecondaryButton, TextboxStandard } from 'components';
import { isInvalidCompanyName } from 'features/customer/utils';
import { Organization } from 'features/proposal/components/organization-types';
import { BodyProps, ErrorType } from 'features/proposal/components/Wizards/shared';
import i18next from 'i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { organizationDialogStyles } from './OrganizationDialog.styles';

export const OrganizationHeaderString = () => i18next.t('quote::Billing account name');

const ORGANIZATION_NAME_MAX_CHARACTERS = 64;
const DBA_NAME_MAX_CHARACTERS = 128;

type OrgProps = BodyProps<Organization> & WithStyles<typeof organizationDialogStyles>;

const OrganizationBodyUnstyled: React.FC<OrgProps> = ({
  classes,
  initialData,
  onValid,
  onInvalid,
}) => {
  const emptyOrg = {
    name: '',
  };
  const { t } = useTranslation();
  const [organization, setOrganization] = React.useState<Organization>(initialData || emptyOrg);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [dbaErrorMessage, setDbaErrorMessage] = React.useState<string | undefined>();

  React.useEffect(() => {
    const name = organization.name.trim();
    const dba = organization.doingBusinessAs ? organization.doingBusinessAs.trim() : undefined;
    let errorText: string | undefined;
    let dbaErrorText: string | undefined;

    if (dba && dba.length > DBA_NAME_MAX_CHARACTERS) {
      dbaErrorText = t('quote::Please enter a name that is less than 128 characters');
    }

    if (!name) {
      onInvalid(ErrorType.missingInformation);
    } else if (isInvalidCompanyName(name)) {
      onInvalid(ErrorType.invalidField);
      errorText = t('quote::"{{name}}" is not a valid legal name', { name });
    } else if (name.length > ORGANIZATION_NAME_MAX_CHARACTERS) {
      onInvalid(ErrorType.invalidField);
      errorText = t('quote::Please enter a name that is less than 64 characters');
    } else if (dbaErrorText) {
      onInvalid(ErrorType.invalidField);
    } else {
      onValid(organization);
    }

    setErrorMessage(errorText);
    setDbaErrorMessage(dbaErrorText);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  return (
    <div>
      <TextboxStandard
        addClass={classes.nameStyles}
        autoFocus
        dataAutomationId="legalName"
        disabled={organization.locked}
        errorMessage={errorMessage}
        label={t('quote::Legal name')}
        placeholder={t('quote::The name they put on legal documents')}
        required
        value={organization.name}
        onBlur={() => {
          const value: Organization = {
            ...organization,
            name: organization.name.trim(),
          };
          setOrganization(value);
        }}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value: Organization = {
            ...organization,
            name: newValue || '',
          };
          setOrganization(value);
        }}
      />
      <TextboxStandard
        addClass={classes.nameStyles}
        dataAutomationId="doingBusinessAs"
        disabled={organization.locked}
        errorMessage={dbaErrorMessage}
        label={t('quote::Doing business as')}
        value={organization.doingBusinessAs || ''}
        onBlur={() => {
          if (organization.doingBusinessAs) {
            const value: Organization = {
              ...organization,
              doingBusinessAs: organization.doingBusinessAs.trim(),
            };
            setOrganization(value);
          }
        }}
        onChange={(
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          const value: Organization = {
            ...organization,
            doingBusinessAs: newValue || undefined,
          };
          setOrganization(value);
        }}
      />
    </div>
  );
};

export const OrganizationFooterButton = (onClick: () => void, isValid: boolean) => {
  return [
    <SecondaryButton
      dataAutomationId="organizationDialogNext"
      disabled={!isValid}
      key="next"
      text={i18next.t('quote::Next')}
      onClick={onClick}
    />,
  ];
};

export const OrganizationBody = withStyles(organizationDialogStyles)(OrganizationBodyUnstyled);
