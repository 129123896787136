import { LinkAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import { ILinkStyles } from 'office-ui-fabric-react';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { linkHeaderStyles } from './LinkHeader.styles';

export interface LinkHeaderProps {
  addClass?: string;
  ariaLabel?: string;
  displayText?: string;
  href?: string;
  id?: string;
  iconName?: string;
  primary?: boolean;
  onClick?: () => void;
  dataAutomationId?: string;
}

type Props = LinkHeaderProps & WithStyles<typeof linkHeaderStyles>;

export const LinkHeaderUnstyled: React.FC<Props> = (props: Props) => {
  const styles: Partial<ILinkStyles> = {
    root: props.classes.root,
  };

  return (
    <LinkAtom
      addClass={mergeClassNames([
        props.primary ? props.classes.headerPrimaryStyle : props.classes.headerDefaultStyle,
        props.addClass,
      ])}
      ariaLabel={props.ariaLabel}
      dataAutomationId={props.dataAutomationId}
      displayText={props.displayText}
      href={props.href}
      iconLeft={!!props.iconName}
      iconName={props.iconName}
      id={props.id}
      styles={styles}
      onClick={props.onClick}
    />
  );
};

export const LinkHeader = withStyles(linkHeaderStyles)(LinkHeaderUnstyled) as React.FC<
  LinkHeaderProps
>;
