import { ThemeProps } from 'styles';

export const LabelListStyles = (theme: ThemeProps) => {
  return {
    table: {
      borderSpacing: '0px 2px',
    },
    name: {
      width: '10%',
      textAlign: 'left',
      verticalAlign: 'initial',
      whiteSpace: 'nowrap',
    },
    value: {
      width: '90%',
      paddingLeft: 12,
      wordBreak: 'break-all',
    },
    nameText: {
      color: theme.palette.textTertiary,
    },
  };
};
