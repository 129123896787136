import { isDate } from 'components/utilities/dates';
import moment from 'moment-timezone';

import { DiscountCardContainerState, DiscountCardDates } from './types';
import {
  computeEndDatesForCalendar,
  computeEndOptions,
  disableDatesBefore,
  removeAllDates,
  sortDropdownOptions,
} from './utils';

export const priceCeilingOptions = (state: DiscountCardContainerState) =>
  sortDropdownOptions(state.ceilingPriceCeilingOptions);
export const priceCeilingDates = (state: DiscountCardContainerState): DiscountCardDates => {
  const momentMinDate = moment();
  const momentMaxDate = momentMinDate.clone().add(3, 'months');
  if (!isDate(state.ceilingPriceCeilingOptions.selectedKey)) {
    return {
      minDate: momentMinDate.toDate(),
      selected: momentMinDate.toDate(),
      maxDate: momentMaxDate.toDate(),
    };
  }
  return {
    minDate: momentMinDate.toDate(),
    maxDate: momentMaxDate.toDate(),
    selected: moment(state.ceilingPriceCeilingOptions.selectedKey).toDate(),
  };
};

export const startOptions = (state: DiscountCardContainerState) => {
  const selectedPriceCeiling = state.ceilingPriceCeilingOptions.selectedKey;
  if (isDate(selectedPriceCeiling)) {
    const priceCeilingDate = moment(selectedPriceCeiling);
    return sortDropdownOptions(
      disableDatesBefore(state.ceilingStartOptions, priceCeilingDate.startOf('month'))
    );
  }
  return sortDropdownOptions(removeAllDates(state.ceilingStartOptions));
};
export const startCalendarEnabled = (state: DiscountCardContainerState) =>
  isDate(state.ceilingPriceCeilingOptions.selectedKey);
export const startDates = (state: DiscountCardContainerState): DiscountCardDates => {
  if (!isDate(state.ceilingPriceCeilingOptions.selectedKey)) {
    return { selected: moment().toDate() };
  }
  const momentMinDate = moment(state.ceilingPriceCeilingOptions.selectedKey).startOf('month');
  const momentMaxDate = moment()
    .clone()
    .add(3, 'months');

  return {
    minDate: momentMinDate.toDate(),
    selected: moment(state.ceilingStartOptions.selectedKey).toDate(),
    maxDate: momentMaxDate.toDate(),
  };
};

export const endOptions = (state: DiscountCardContainerState) =>
  sortDropdownOptions(
    computeEndOptions(state.ceilingStartOptions.selectedKey, state.ceilingEndOptions)
  );
export const endCalendarEnabled = (state: DiscountCardContainerState) =>
  isDate(state.ceilingStartOptions.selectedKey);
export const endDates = (state: DiscountCardContainerState): DiscountCardDates =>
  computeEndDatesForCalendar(state.ceilingStartOptions, state.ceilingEndOptions);
