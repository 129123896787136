import { ThemeProps } from 'styles';

export const NavBarHeight = 48;

export const navBarStyles = (theme: ThemeProps) => {
  return {
    externalSourceIcon: {
      color: theme.palette.textSecondary,
    },
    horizontalRule: {
      backgroundColor: theme.palette.text,
      border: 'none',
      height: 2,
      margin: '15px 0',
      width: '100%',
    },
    helpContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 220,
      '& > *': {
        marginBottom: 10,
      },
      '& > *:nth-child(2)': {
        marginBottom: 0,
      },
    },
    helpPanelRoot: {
      top: NavBarHeight,
    },
    demoButton: {
      width: 165,
      fontSize: theme.fonts.fontSizes.larger,
      '& i': {
        fontSize: theme.fonts.fontSizes.large,
        margin: 0,
      },
    },
    demoMenuContainer: {
      width: 200,
    },
    demoLink: {
      marginTop: 8,
    },
    demoSeparator: {
      marginTop: 12,
      marginBottom: 6,
    },
    leaveDemoButton: {
      padding: 0,
    },
    demoCallout: {
      '&> div': {
        padding: 8,
      },
    },
  };
};
