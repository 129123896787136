import { TestHeaderConfig } from 'services/types';
import { TokenAuthorities } from 'services/utils';

export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export interface ProposalConfig extends TestHeaderConfig {
  environment: Environment;
}

export const totalAzurePlansAvailable = 2;

export const productIds = {
  azurePlan: 'DZH318Z0BPS6',
  discountFulfillmentDocument: 'DZH318Z0BWV5',
  azureFamilyDiscount: 'DZH318Z0BWV6',
};
export const maxDiscountableLineItems = 140;
export const maxPurchasableLineItems = 10;

export const endpoints = {
  [Environment.Prod]: 'https://quote.l2o.microsoft.com/token/v11',
  [Environment.Int]: 'https://quote.int.l2o.microsoft.com/token/v11',
};

export const shareLinkBusinessStore = {
  [Environment.Prod]:
    'https://businessstore.microsoft.com/store/quotelanding?QuoteID={quoteId}&flagset=quote',
  [Environment.Int]:
    'https://businessstore.microsoft.com/en-us/store/organizationreview?QuoteID={quoteId}?fromquotelanding=true&flagset=quote&setflight=wsfb_testheader',
};

export function getBusinessStoreLink(env: Environment) {
  let url = shareLinkBusinessStore[env];
  return url;
}

export interface ProposalConfig extends TestHeaderConfig {
  environment: Environment;
  useApprovalTestHeaderScenarios?: boolean;
}

export const proposalNameLengthLimit = 100;

export const supportOfferUrl =
  'https://azure.microsoft.com/en-us/offers/enterprise-agreement-support/';

export enum ClaimType {
  PricingAudience = 'http://schemas.microsoft.com/marketplace/2016/12/claims/pricingaudience',
  NationalCloud = 'http://schemas.microsoft.com/marketplace/2018/07/claims/nationalcloud',
  AgreementType = 'agreementType',
}

export function getTokenAuthority(environment: Environment) {
  return environment === Environment.Prod ? TokenAuthorities.QuoteProd : TokenAuthorities.QuoteInt;
}
