import * as actions from 'features/proposal/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { CreditConfig } from 'services/credit/config';
import loggerService from 'services/logger-service';
import { PatchCommand } from 'services/types';
import { t } from 'services/utils';
import { RootState } from 'store/types';
import { getType } from 'typesafe-actions';
import { oc } from 'ts-optchain';
import { ProposalStatus, UserGroup } from 'services/proposal/types';

import { patchQuoteHeader } from './auxiliary';

export function* requestCreditLine() {
  const relevantAction = actions.requestCreditLineAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      // Update Quote minimumCreditLine and Annual deal estimate.
      const assignedToGroup = oc(action).payload.quote.header.assignedToGroup();
      const quoteStatus = oc(action).payload.quote.header.status();
      if (assignedToGroup !== UserGroup.Field && quoteStatus !== ProposalStatus.Submitted) {
        const annualDealEstimate = +action.payload.annualDealEstimate;
        const commands: PatchCommand[] = [];

        if (action.payload.quote.header.estimatedDealValue !== annualDealEstimate) {
          commands.push({
            op: 'replace',
            path: '/estimatedDealValue',
            value: annualDealEstimate,
          });
        }
        commands.push({
          op: 'replace',
          path: '/soldToCustomerLegalEntity/monthlyCreditLimit',
          value: +action.payload.minimumCreditLine,
        });
        yield call(patchQuoteHeader, commands, action.payload.quote);
      }
      // Perform credit check.
      const creditConfig: CreditConfig = yield select(
        (state: RootState) => state.app.appConfig.credit
      );
      const creditResponse = yield call(
        api.credit.performCreditCheck,
        action.payload.creditRequest,
        creditConfig
      );
      yield put(
        actions.requestCreditLineAsync.success({
          creditInfo: creditResponse,
          currency: action.payload.creditRequest.currency,
        })
      );
    } catch (err) {
      loggerService.error(err);
      yield put(
        actions.requestCreditLineAsync.failure({
          message: t('error::Error requesting credit line.'),
          exception: err,
        })
      );
    }
  });
}

export function* requestCreditIncrease() {
  const relevantAction = actions.requestCreditIncreaseAsync.request;
  yield takeEvery(getType(relevantAction), function*(action: ReturnType<typeof relevantAction>) {
    try {
      const creditConfig: CreditConfig = yield select(
        (state: RootState) => state.app.appConfig.credit
      );
      const creditResponse = yield call(
        api.credit.performCreditCheck,
        action.payload,
        creditConfig
      );
      yield put(
        actions.requestCreditIncreaseAsync.success({
          creditInfo: creditResponse,
          currency: action.payload.currency,
        })
      );
    } catch (err) {
      loggerService.error(err);
      yield put(
        actions.requestCreditIncreaseAsync.failure({
          message: t('error::Error requesting credit increase.'),
          exception: err,
        })
      );
    }
  });
}
