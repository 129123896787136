import { ButtonMenuProps, IconMenuButton, Pivot } from 'components';
import { PivotAtomProps } from 'components/atoms';
import { meplaHistory } from 'createHistory';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import {
  ManageFulfillmentCloudsDialogProps,
  openManageFulfillmentCloudsDialog,
} from 'features-apollo/components/dialogs/ManageCloudsDialog';
import { Finder } from 'features-apollo/quote/components';
import { ProductListContainer, TermListContainer } from 'features-apollo/quote/components/Lists';
import { GET_QUOTERECOMMENDATIONS } from 'features-apollo/quote/components/queries';
import { QueryGetQuoteData } from 'features-apollo/quote/types';
import { getFlightIsEnabled } from 'features/app/selectors';
import { ProductType, RecoChannelProducts } from 'features/catalog';
import * as catalogSelectors from 'features/catalog/selectors';
import { SearchResultProduct } from 'features/catalog/types';
import * as customerSelectors from 'features/customer/selectors';
import * as actions from 'features/proposal/actions';
import {
  Entities,
  ExtendedDragDropProps,
  ItemMap,
  multiSelectTo as multiSelect,
} from 'features/proposal/components/Finder';
import * as proposalSelectors from 'features/proposal/selectors';
import {
  AgreementType,
  CatalogAction,
  CatalogContext,
  GqlLanguage,
  Market,
  NationalCloud,
  ProductAudience,
  ProductRecommendationAction,
  RecommendationGroup,
  RecommendationSource,
} from 'generated/graphql';
import gql from 'graphql-tag';
import { EditorLayout, PivotContainer } from 'layouts';
import { ISearchBox } from 'office-ui-fabric-react';
import React, { useContext } from 'react';
import { DragDropContext, DraggableLocation, DragStart, DropResult } from 'react-beautiful-dnd';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from 'routes';
import { Flight } from 'services/flights/flightList';
import { ProductGroups } from 'services/user-preferences/types';
import { RootState } from 'store/types';
import { DialogContext } from 'styles';
import { oc } from 'ts-optchain';

import { useQuery } from '@apollo/react-hooks';
import { KeyCodes } from '@uifabric/utilities';

import { gqlAddLineItemsWithDrag } from '../Finder/DragAndDrop';
import { emptyEntities, getEntities } from './utils';

interface EditorProps {
  quoteId: string;
  quoteLineItems: string[];
}

export const GET_QUOTECONTEXT = gql`
  query getQuoteContext($id: String!) {
    getQuote(id: $id) {
      id
      productAudience
      agreementType
      clouds
      market
      languageInfo {
        gqlCode
      }
      transactionModel
    }
  }
`;

const mapStateToProps = (state: RootState) => {
  return {
    isPartnerProposal: !!proposalSelectors.isPartnerProposal(state),
    getProductFailed: (id: string) => catalogSelectors.getProductFailed(state, id),
    isFavoriteProductForLegacyDisabled: (product: SearchResultProduct) =>
      catalogSelectors.isFavoriteProductForLegacyDisabled(state, product),
    getEcifProduct: () => catalogSelectors.getRecoChannels(state, ProductType.ECIF),
    searchResults: state.catalog.search.results.products,
    oneAskResults: customerSelectors.getOneAskResponse(state),
    showECIF: getFlightIsEnabled(state, Flight.ECIF),
    consumeCatalogListOfTerms: getFlightIsEnabled(state, Flight.consumeCatalogListOfTerms),
    isUSNatEnabled: getFlightIsEnabled(state, Flight.usNat),
  };
};

const dispatchProps = {
  clearConfigurationCard: actions.closeConfigCard,
  clearItemSelection: actions.clearItemSelection,
  updateAddedLineItemCount: actions.updateAddedLineItemCount.request,
};

const styles = {
  iconMenu: {
    padding: '0px',
    paddingBottom: '6px',
    '& i': { fontSize: '14px', paddingBottom: '4px', paddingTop: '4px' },
  },
};

type Props = EditorProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  WithStyles<typeof styles>;

const getBodyContent = (id: string, isDragging: boolean, cloud?: NationalCloud) => {
  return (
    <Switch>
      <Route
        path={routes.quote.products.root || routes.quote.products.cloud}
        render={() => (
          <ProductListContainer isDragEvent={isDragging} nationalCloud={cloud} quoteId={id} />
        )}
      />
      <Route
        exact
        path={routes.quote.terms}
        render={() => <TermListContainer isDragEvent={isDragging} quoteId={id} />}
      />
      <Route render={() => <Redirect to={routes.quote.forId(id)} />} />
    </Switch>
  );
};

const getTermEntities = (entities: Entities, showECIF: boolean) => {
  const items = entities.items;

  const keys = Object.keys(items).filter(key => !key.includes('-'));
  const termsKeys = Object.keys(items).filter(
    key => items[key].productIdentifier.productType !== ProductType.ECIF && !key.includes('-')
  );

  const ecifKeys = Object.keys(items).filter(
    key => items[key].productIdentifier.productType === ProductType.ECIF
  );

  const sortedKeys = (showECIF ? termsKeys : keys).sort((prev, curr) =>
    items[prev].itemText.localeCompare(items[curr].itemText)
  );
  const sortedItems: ItemMap = {};
  sortedKeys.forEach(key => {
    sortedItems[key] = items[key];
  });
  const columnOrder = ['finderList', 'lineItemsList'];
  const columns = {
    finderList: {
      id: 'finderList',
      itemIds: sortedKeys,
    },
    lineItemsList: {
      id: 'lineItemsList',
      itemIds: [],
    },
  };
  const sortedEntities = {
    columnOrder: showECIF ? [...columnOrder, 'ecifList'] : columnOrder,
    columns: showECIF
      ? {
          ...columns,
          ecifList: {
            id: 'ecifList',
            itemIds: ecifKeys,
          },
        }
      : columns,
    items: sortedItems,
  };
  return sortedEntities;
};

const EditorUnconnected: React.FC<Props> = props => {
  const {
    clearConfigurationCard,
    clearItemSelection,
    getEcifProduct,
    searchResults,
    isPartnerProposal,
    showECIF,
    oneAskResults,
    isFavoriteProductForLegacyDisabled,
    getProductFailed,
    classes,
    consumeCatalogListOfTerms,
    updateAddedLineItemCount,
  } = props;

  const activeQuote = useContext(ActiveQuoteContext);
  const isLegacyQuote =
    activeQuote.activeQuote && activeQuote.activeQuote.agreementType === AgreementType.Legacy;
  const isReadOnly = activeQuote.activeQuote && activeQuote.activeQuote.readOnly;

  const [userFavoriteProductGroups, setFavoriteGroup] = React.useState<ProductGroups | undefined>(
    undefined
  );
  const [displayFinderProducts, setDisplayFinderProducts] = React.useState<boolean>(false);
  const [recoProductGroup, setRecoProductGroup] = React.useState<RecommendationGroup | undefined>(
    undefined
  );
  const [recoTermGroup, setRecoTermGroup] = React.useState<RecommendationGroup | undefined>(
    undefined
  );
  const [catalogContext, setCatalogContext] = React.useState<CatalogContext>();
  const { data: contextData } = useQuery<QueryGetQuoteData>(GET_QUOTECONTEXT, {
    variables: { id: activeQuote.quoteId },
    onCompleted: data => {
      const clouds = oc(data).getQuote.clouds([NationalCloud.Global]);
      const catalogContext: CatalogContext = {
        agreementType: oc(data).getQuote.agreementType(AgreementType.Modern),
        audience: oc(data).getQuote.productAudience(ProductAudience.DirectCommercial),
        nationalCloud: clouds[0],
        market: oc(data).getQuote.market(Market.Us) as Market,
        languages: oc(data).getQuote.languageInfo.gqlCode(GqlLanguage.EnUs),
        action: CatalogAction.Details,
      };
      setCatalogContext(catalogContext);
    },
  });

  const cloudsInQuoteContext = oc(contextData).getQuote.clouds([
    NationalCloud.Global,
  ]) as NationalCloud[];
  const catalogContextInitial: CatalogContext = {
    agreementType: oc(contextData).getQuote.agreementType(AgreementType.Modern),
    audience: oc(contextData).getQuote.productAudience(ProductAudience.DirectCommercial),
    nationalCloud: cloudsInQuoteContext[0],
    market: oc(contextData).getQuote.market(Market.Us) as Market,
    languages: oc(contextData).getQuote.languageInfo.gqlCode(GqlLanguage.EnUs),
    action: CatalogAction.Details,
  };

  const { data, loading: isRecoLoading, refetch } = useQuery<QueryGetQuoteData>(
    GET_QUOTERECOMMENDATIONS,
    {
      variables: { id: activeQuote.quoteId, input: catalogContextInitial },
    }
  );

  const recoGroups: RecommendationGroup[] | undefined = oc(data).getQuote.recommendations();
  const audience = oc(contextData).getQuote.productAudience(ProductAudience.DirectCommercial);
  const govAudiences = [ProductAudience.DirectGov, ProductAudience.IndirectGov];
  const isGovSegmentQuote = govAudiences.includes(audience);
  const termsView = useRouteMatch(routes.quote.terms);
  const pivotSelection = useRouteMatch(routes.quote.products.cloud);
  const cloudTab = pivotSelection && (pivotSelection.params as { id: string; cloud: string });

  const defaultCloudTab =
    (cloudTab && cloudTab.cloud) || (cloudsInQuoteContext && cloudsInQuoteContext[0]);
  const selectedCloud = isGovSegmentQuote ? defaultCloudTab : undefined;
  const isTermsView = termsView ? termsView.isExact : false;

  React.useEffect(() => {
    if (!isRecoLoading && selectedCloud && contextData) {
      const context = oc(contextData).getQuote();
      const catalogContextUpdated: CatalogContext = {
        agreementType: oc(context).agreementType(AgreementType.Modern),
        audience: oc(context).productAudience(ProductAudience.DirectCommercial),
        nationalCloud: isTermsView
          ? (defaultCloudTab as NationalCloud)
          : (selectedCloud as NationalCloud) || oc(context).clouds([NationalCloud.Global])[0],
        market: oc(context).market(Market.Us) as Market,
        languages: oc(context).languageInfo.gqlCode(GqlLanguage.EnUs),
        action: CatalogAction.Details,
      };
      refetch({ id: activeQuote.quoteId, input: catalogContextUpdated });
      setCatalogContext(catalogContextUpdated);
    }
  }, [
    activeQuote.quoteId,
    selectedCloud,
    isTermsView,
    defaultCloudTab,
    isRecoLoading,
    refetch,
    contextData,
  ]);

  React.useEffect(() => {
    if (recoGroups) {
      !isLegacyQuote &&
        setRecoProductGroup(
          recoGroups.find(
            recoGroup => recoGroup.type === 'Product' && recoGroup.name === 'Azure Essentials'
          )
        );
      setRecoTermGroup(
        recoGroups.find(
          recoGroup =>
            recoGroup.type === 'Term' &&
            recoGroup.name === 'Standard terms' &&
            (consumeCatalogListOfTerms
              ? recoGroup.source === RecommendationSource.Catalog
              : recoGroup.source === RecommendationSource.Static)
        )
      );
      const favorites = recoGroups.filter(
        recoGroup => recoGroup.type === 'Product' && recoGroup.name !== 'Azure Essentials'
      );
      const productGroups: ProductGroups = {};

      favorites &&
        favorites.forEach(favorite => {
          const products = favorite.items.map(item => {
            const recommendationItem = item as ProductRecommendationAction;
            return {
              productId: recommendationItem.product.id,
              productIdentifier: {
                productId: recommendationItem.product.id,
                productType: recommendationItem.product.productType,
              },
              productName: recommendationItem.product.title,
            };
          });
          productGroups[favorite.name] = { products: products };
        });

      setFavoriteGroup(productGroups);

      setDisplayFinderProducts(true);
    }
  }, [recoGroups, isLegacyQuote, consumeCatalogListOfTerms]);

  const productType = isTermsView ? ProductType.Term : ProductType.Product;
  const recoProducts =
    productType === ProductType.Product ? oc(recoProductGroup).items() : oc(recoTermGroup).items();
  const ecifProduct: RecoChannelProducts[] = getEcifProduct();
  const lineItems = oc(activeQuote).activeQuote.lineItems();

  // states for dnd and multiselect
  const [entities, setEntities] = React.useState<Entities>(emptyEntities);
  const [selectedItemIds, setSelectedItemIds] = React.useState<string[]>([]);
  const [draggingItemId, setDraggingItemId] = React.useState<string | undefined>(undefined);
  const selectedRoute = isTermsView
    ? 'terms'
    : selectedCloud
    ? `products/${selectedCloud}`
    : 'products';
  // other states
  const [selected, setSelected] = React.useState(selectedRoute);

  React.useEffect(() => {
    setSelected(selectedRoute);
  }, [selectedRoute]);

  const searchFocusRef = React.useRef<ISearchBox>(null);
  useHotkeys('ctrl+/', () => {
    searchFocusRef.current && searchFocusRef.current.focus();
  });

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.keyCode === KeyCodes.escape) {
      setSelectedItemIds([]);
    }
  };

  const onWindowClick = (event: Event) => {
    if (event.defaultPrevented) {
      return;
    }
    setSelectedItemIds([]);
  };

  const onWindowTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    setSelectedItemIds([]);
  };

  const productsLength =
    productType === ProductType.Product
      ? oc(recoProductGroup).items.length()
      : oc(recoTermGroup).items.length();

  const productLength = oc(ecifProduct)[0].products.length();

  React.useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown, true);
    window.addEventListener('touchend', onWindowTouchEnd);
    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, []);

  const isFavoriteProductFailed = (product: SearchResultProduct) => {
    return isLegacyQuote
      ? isFavoriteProductForLegacyDisabled(product)
      : getProductFailed(product.productId);
  };

  React.useEffect(() => {
    if (displayFinderProducts) {
      const newEntities =
        activeQuote.activeQuote &&
        getEntities(
          activeQuote.activeQuote,
          !!isReadOnly,
          recoProducts,
          showECIF,
          ecifProduct,
          oneAskResults,
          isFavoriteProductFailed,
          userFavoriteProductGroups,
          searchResults,
          catalogContext
        );
      if (isTermsView) {
        newEntities && setEntities(getTermEntities(newEntities, showECIF));
      } else {
        newEntities && setEntities(newEntities);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTermsView,
    isRecoLoading,
    displayFinderProducts,
    productsLength,
    showECIF,
    productLength,
    oneAskResults,
    userFavoriteProductGroups,
    searchResults,
    lineItems,
    isReadOnly,
  ]);

  // finder list - drag brehavior
  const unselectAll = () => {
    setSelectedItemIds([]);
  };
  const onDragStart = (start: DragStart) => {
    const id: string = start.draggableId;
    const selected: string | undefined = selectedItemIds.find(
      (itemId: string): boolean => itemId === id
    );

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }
    setDraggingItemId(start.draggableId);
  };
  const onDragEnd = (result: DropResult) => {
    const destination: DraggableLocation | undefined = result.destination;
    const source: DraggableLocation = result.source;

    // user has 'let go' of button so no action is taken
    if (!destination || result.reason === 'CANCEL' || destination.droppableId !== 'lineItemsList') {
      setDraggingItemId(undefined);
      return;
    }

    activeQuote.availableMutations &&
      catalogContext &&
      gqlAddLineItemsWithDrag(
        {
          entities,
          selectedItemIds,
          source,
          destination,
        },
        catalogContext,
        activeQuote.availableMutations.addLineItems
      );

    updateAddedLineItemCount(selectedItemIds.length);
    setDraggingItemId(undefined);
    unselectAll();
  };

  const toggleSelection = (itemId: string) => {
    const copySelectedItemIds: string[] = selectedItemIds;
    const wasSelected: boolean = copySelectedItemIds.includes(itemId);

    const newItemIds: string[] = (() => {
      // Item was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [itemId];
      }

      // Item was part of a selected group
      // will now become the only selected item
      if (copySelectedItemIds.length > 1) {
        return [itemId];
      }

      // Item was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setSelectedItemIds(newItemIds);
  };

  const toggleSelectionInGroup = (itemId: string) => {
    const copySelectedItemIds: string[] = selectedItemIds;
    const index: number = copySelectedItemIds.indexOf(itemId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setSelectedItemIds([...copySelectedItemIds, itemId]);
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow: string[] = [...copySelectedItemIds];
    shallow.splice(index, 1);
    setSelectedItemIds(shallow);
  };

  // This behaviour matches the Windows finder selection
  const multiSelectTo = (newItemId: string) => {
    const updated: string[] | undefined = multiSelect(entities, selectedItemIds, newItemId);

    if (!updated) {
      return;
    }

    setSelectedItemIds(updated);
  };

  const clearTransientUIStates = () => {
    clearItemSelection();
    clearConfigurationCard();
  };
  const pivotSelected = (id: string) => {
    setSelected(id);
    activeQuote.quoteId && meplaHistory.push(`${routes.quote.forId(activeQuote.quoteId)}/${id}`);
    clearTransientUIStates();
    return id;
  };
  const { t } = useTranslation();

  const context = React.useContext(DialogContext);
  const manageCloudsDialogProps: ManageFulfillmentCloudsDialogProps = {
    quoteId: oc(activeQuote).quoteId(''),
    isUSNatEnabled: props.isUSNatEnabled,
  };
  const dropdownMenuProps: ButtonMenuProps[] = [
    {
      key: 'manageCloud',
      text: t('quote::Manage fulfillment clouds'),
      onClick: () => {
        manageCloudsDialogProps &&
          openManageFulfillmentCloudsDialog(context, manageCloudsDialogProps);
      },
    },
  ];
  const cloudButton = (
    <IconMenuButton
      addClass={classes.iconMenu}
      ariaLabel={t('quote::open manage clouds dialog')}
      disabled={oc(activeQuote).activeQuote.readOnly()}
      iconName="Add"
      id="manageCloud"
      menuId="manage-clouds-menu"
      menuProps={dropdownMenuProps}
    />
  );
  const showCloudContext = isGovSegmentQuote;
  const pivProps: PivotAtomProps = {
    items: [],
    onSelectItem: pivotSelected,
    defaultItemId: selected,
    rightElement: (showCloudContext && cloudButton) || undefined,
  };
  if (showCloudContext) {
    //for each cloud in quote context push a pivot.
    cloudsInQuoteContext &&
      cloudsInQuoteContext.forEach(cloud => {
        const displayName =
          cloud === NationalCloud.Global ? cloud : cloud.slice(0, 2) + ' ' + cloud.slice(2);

        pivProps.items.push({
          id: `products/${cloud}`,
          text: t('quote::Products'),
          subtitle: displayName,
          subtitleIconName: 'Cloud',
          dataAutomationId: `${cloud}Tab`,
        });
      });
  } else {
    pivProps.items.push({
      id: 'products',
      text: t('quote::Products'),
      dataAutomationId: 'productsTab',
    });
  }
  if (!isLegacyQuote) {
    pivProps.items.push({ id: 'terms', text: t('quote::Terms'), dataAutomationId: 'termsTab' });
  }

  const finderProducts: ExtendedDragDropProps = {
    column: entities.columns['finderList'],
    entities,
    selectedItemIds,
    draggingItemId,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
    droppableId: 'finderList',
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <EditorLayout
          leftPane={
            !isPartnerProposal ? (
              <React.Fragment>
                <Route
                  path={routes.quote.products.root || routes.quote.products.cloud}
                  render={() => (
                    <>
                      {catalogContext && (
                        <Finder
                          catalogContext={catalogContext}
                          displayFinderList={displayFinderProducts}
                          finderListProps={finderProducts}
                          isDragEvent={!!draggingItemId}
                          isLegacyQuote={isLegacyQuote}
                          preventKeyClick={selectedItemIds.length > 1}
                          productType={ProductType.Product}
                          searchComponentRef={searchFocusRef}
                          showCloudContext={showCloudContext}
                        />
                      )}
                    </>
                  )}
                />
                <Route
                  exact
                  path={routes.quote.terms}
                  render={() => (
                    <>
                      {catalogContext && (
                        <Finder
                          catalogContext={catalogContext}
                          displayFinderList={displayFinderProducts}
                          finderListProps={finderProducts}
                          isDragEvent={!!draggingItemId}
                          preventKeyClick={selectedItemIds.length > 1}
                          productType={ProductType.Term}
                          searchComponentRef={searchFocusRef}
                          showCloudContext={showCloudContext}
                        />
                      )}
                    </>
                  )}
                />
              </React.Fragment>
            ) : (
              undefined
            )
          }
          mainContent={
            <PivotContainer
              content={getBodyContent(
                oc(activeQuote).quoteId(''),
                !!draggingItemId,
                catalogContext && catalogContext.nationalCloud
              )}
              pivotNav={pivProps.items.length > 1 ? <Pivot {...pivProps} /> : null}
            />
          }
          onContainerAreaClick={() => {
            clearItemSelection();
          }}
        />
      </DragDropContext>
    </React.Fragment>
  );
};

export { EditorUnconnected };

export const Editor = withStyles(styles)(
  connect(mapStateToProps, dispatchProps)(EditorUnconnected)
);
