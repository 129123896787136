import gql from 'graphql-tag';

export const SEARCH_CATALOG_PRODUCTS = gql`
  query searchCatalogProducts($input: CatalogSearchInput!) {
    searchProducts(input: $input) {
      edges {
        node {
          id
          title
          skus {
            title
            description
          }
          description
          publisherName
          images {
            small
            medium
            logo
          }
          categories {
            name
          }
          industries {
            name
          }
        }
      }
      pageInfo {
        cursor
        hasNextPage
      }
    }
  }
`;

export const GET_NEGOTIATED_TERMS = gql`
  query getNegotiatedTerms($input: CatalogSearchInput!) {
    searchProducts(input: $input) {
      edges {
        node {
          id
          title
          skus {
            title
            description
          }
          description
          publisherName
          images {
            small
            medium
            logo
          }
        }
      }
      pageInfo {
        cursor
        hasNextPage
      }
    }
  }
`;
