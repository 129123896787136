import { detailsListStyles } from '../styles';

export const styles = {
  dropdown: {
    width: 262,
  },
  detailsListContainer: {
    overflowY: 'auto',
    maxHeight: 400,
  },
  reccuringBillingCheckboxContainer: {
    marginTop: 24,
  },
  detailsListStyles,
};
