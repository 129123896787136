import { TextBody, TextCurrency, TextNavigationSecondarySelected } from 'components/ions';
import * as customerSelectors from 'features/customer/selectors';
import * as selectors from 'features/proposal/selectors';
import { sharedStyles } from 'features/proposal/shared.styles';
import { flexSectionCreator, formatCurrency } from 'features/proposal/utils';
import { Stack } from 'office-ui-fabric-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';

import { sharedStyles as dialogSharedStyles } from '../shared.styles';
import { RequestCreditIncreaseButton, RequestCreditLineButton } from './';
import { CreditLineReason } from './types';

const styles = (theme: ThemeProps) => ({
  ...dialogSharedStyles(theme),
  ...sharedStyles,

  safeList: {
    color: theme.palette.approveText,
  },
  pending: {
    color: theme.palette.dangerText,
  },
  rejected: {
    color: theme.palette.dangerText,
  },
  normal: {
    color: theme.palette.textTertiary,
  },
  creditLineOuterContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: 'min-content',
  },
  creditLineContainer: {
    display: 'inline-flex',
  },
  creditLineContainerLimitWidth: {
    display: 'inline-flex',
    width: 'min-content',
  },
  creditLineLabel: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    paddingRight: 16,
  },
  creditLineData: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
});

interface CreditLineProps {
  setCustomerFooterAnnualDealEstimate: (value: string) => void;
  annualDealEstimate: string;
  disableLink: boolean;
}

const mapStateToProps = (state: RootState) => ({
  creditInfo: selectors.getCreditInfo(state),
  creditLineStatus: selectors.getCreditLineStatus(state),
  minimumCreditLine: selectors.getMinimumCreditLine(state).toString(),
  billingCurrency: selectors.getBillingCurrency(state),
  hasSoldTo: selectors.hasSoldTo(state),
  isStrategicAccount: customerSelectors.getIsStrategicAccount(state),
});

type Props = CreditLineProps & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>;

export const CreditLineUnstyled: React.FC<Props> = props => {
  const { t } = useTranslation();

  const getStatusOrLink = () => {
    if (!props.creditInfo) {
      return <TextBody>{t('quote::Unknown status')}</TextBody>;
    }

    switch (props.creditLineStatus) {
      case CreditLineReason.SafeList:
        return <TextBody addClass={props.classes.safeList}>{t('quote::Safe list')}</TextBody>;

      case CreditLineReason.CreditIncrease:
        return (
          <>
            <TextNavigationSecondarySelected addClass={props.classes.pending}>
              {props.creditInfo.reasons.credit_line &&
                formatCurrency(props.creditInfo.reasons.credit_line)}
            </TextNavigationSecondarySelected>
            <span className={props.classes.paddingRightSmall} />
            <TextCurrency addClass={props.classes.pending}>{props.billingCurrency}</TextCurrency>
          </>
        );

      case CreditLineReason.CreditLine:
        return (
          <>
            <TextBody>
              {props.creditInfo.reasons.credit_line &&
                formatCurrency(props.creditInfo.reasons.credit_line)}
            </TextBody>
            <span className={props.classes.paddingRightSmall} />
            <TextCurrency addClass={props.classes.normal}>{props.billingCurrency}</TextCurrency>
          </>
        );

      case CreditLineReason.PendingReview:
        return <TextBody addClass={props.classes.pending}>{t('quote::Pending')}</TextBody>;

      case CreditLineReason.UnderReview:
        return <TextBody addClass={props.classes.pending}>{t('quote::Under review')}</TextBody>;

      case CreditLineReason.ReviewCancelled:
        return (
          <TextBody addClass={props.classes.pending}> {t('quote::Review cancelled')}</TextBody>
        );

      case CreditLineReason.Rejected:
        return (
          <TextNavigationSecondarySelected addClass={props.classes.rejected}>
            {t('quote::Rejected')}
          </TextNavigationSecondarySelected>
        );

      default:
        return <TextBody>{t('quote::Unknown status')}</TextBody>;
    }
  };

  // This will show the Credit Line label and its calculated value.
  const creditLineComponent = (limitWidth: boolean = true) => (
    <div
      className={
        limitWidth ? props.classes.creditLineContainerLimitWidth : props.classes.creditLineContainer
      }
      data-automation-id="creditLine"
    >
      <TextNavigationSecondarySelected
        addClass={`${props.classes.normal} ${props.classes.creditLineLabel}`}
      >
        {t('quote::Credit line:')}
      </TextNavigationSecondarySelected>
      <div className={props.classes.creditLineData} data-automation-id="creditLineValue">
        {getStatusOrLink()}
      </div>
    </div>
  );

  const rejectedComponent = flexSectionCreator(
    props.classes.flexLarge,
    props.classes.flexXLarge,
    <Stack>
      {creditLineComponent()}
      <RequestCreditLineButton
        annualDealEstimate={props.annualDealEstimate}
        dataAutomationId="resubmitCreditLineButton"
        disabled={!props.hasSoldTo || props.disableLink}
        displayText={t('quote::resubmit credit line request')}
        minimumCreditLine={props.minimumCreditLine}
      />
    </Stack>,
    props.classes.flexContainer
  );

  const creditLineIncreaseComponent = flexSectionCreator(
    props.classes.flexLarge,
    props.classes.flexXLarge,
    <Stack>
      <div className={props.classes.creditLineOuterContainer}>
        {creditLineComponent(false)}
        <div className={`${props.classes.paddingTopLarge} ${props.classes.creditLineContainer}`}>
          <TextNavigationSecondarySelected
            addClass={`${props.classes.normal} ${props.classes.creditLineLabel}`}
          >
            {t('quote::Required credit line:')}
          </TextNavigationSecondarySelected>
          <div className={props.classes.creditLineData}>
            <TextBody>{formatCurrency(props.minimumCreditLine)}</TextBody>
            <span className={props.classes.paddingRightSmall} />
            <TextCurrency addClass={props.classes.normal}>{props.billingCurrency}</TextCurrency>
          </div>
        </div>
      </div>
      <TextBody addClass={`${props.classes.paddingTopXSmall} ${props.classes.normal}`}>
        {t(
          `quote::The customer's credit line must be greater than the minimum required credit line. You'll need a credit increase before you can publish the quote to the customer.`
        )}
      </TextBody>
      <RequestCreditIncreaseButton disableLink={props.disableLink} />
    </Stack>,
    props.classes.flexContainer
  );

  const noCreditComponent = flexSectionCreator(
    props.classes.flexLarge,
    props.classes.flexXLarge,
    <Stack>
      <TextBody addClass={props.classes.normal}>
        {t(
          `quote::A credit line must be established for this customer. Since it's partially based on the annual deal estimate, now would be a good time to kick it off.`
        )}
      </TextBody>
      <RequestCreditLineButton
        annualDealEstimate={props.annualDealEstimate}
        dataAutomationId="salesRequestCreditLine"
        disabled={!props.hasSoldTo || props.disableLink}
        displayText={t('quote::request credit line')}
        minimumCreditLine={props.minimumCreditLine}
        setCustomerFooterAnnualDealEstimate={props.setCustomerFooterAnnualDealEstimate}
      />
    </Stack>,
    props.classes.flexContainer
  );

  const mainComponent = () => {
    switch (props.creditLineStatus) {
      case CreditLineReason.Rejected:
        return rejectedComponent;

      case CreditLineReason.CreditIncrease:
        return creditLineIncreaseComponent;

      case CreditLineReason.None:
        if (props.isStrategicAccount) {
          return null;
        } else {
          return noCreditComponent;
        }

      default:
        return creditLineComponent();
    }
  };

  return mainComponent();
};

export const CreditLine = connect(mapStateToProps)(withStyles(styles)(CreditLineUnstyled));
