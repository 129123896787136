import { LinkButton } from 'components/ions';
import { ActiveQuoteContext } from 'features-apollo/ActiveQuoteContext';
import { ServicesConfigCard } from 'features-apollo/quote/components/ConfigCards/ServicesConfigCard';
import { SimpleTermsCard } from 'features-apollo/quote/components/ConfigCards/SimpleTermsCard';
import { getSimpleTermId } from 'features-apollo/quote/components/ConfigCards/SimpleTermsCard/utils';
import { filteredSkusRawToFilteredSkus } from 'features-apollo/quote/utils';
import { isPreApprovedEcif } from 'features-apollo/selectors';
import { getFlightIsEnabled } from 'features/app/selectors';
import {
  Currency,
  EcifLineItem,
  FinancingTermLineItem,
  MonetaryLineItem,
  SapLineItem,
  TermLineItem,
} from 'generated/graphql';
import React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Flight } from 'services/flights/flightList';
import { RootState } from 'store/types';
import { ThemeProps } from 'styles';
import { oc } from 'ts-optchain';

import { QuoteTermLineItem } from '../../types';
import { getValueOrUndefined } from '../../utils';
import {
  FinanceTermCard,
  getAssociatedLineItemConfiguration,
} from '../Cards/FinanceTermConfigurationCard';
import { SimpleSkuPickerConfigurationCard } from '../Cards/SimpleSkuPickerConfigurationCard/SimpleSkuPickerConfigurationCard';
import {
  MonetaryConfigurationCard,
  readBoundaries as readMonetaryConfigurationCardBoundaries,
} from '../MonetaryConfigurationCard/MonetaryConfigurationCard';
import {
  readBoundaries as readSapCardConfigurationCardBoundaries,
  SapConfigurationCard,
} from '../SapCard/SapConfigurationCard';
import { ConfigureCardCommonButtonProps } from './types';
import { filteredSkuToSkus, getLinkButtonDisplayText } from './utils';

export enum TermCardType {
  Monetary,
  Sap,
  SimpleSkuPicker,
  SimpleTerm,
  Financing,
  EcifTerm,
}

export interface TermConfigureButtonProps extends ConfigureCardCommonButtonProps {
  /**
   * Identifies line item in the quote's line items' list.
   */
  lineItem: TermLineItem | MonetaryLineItem | SapLineItem;
  /**
   * Disables button
   */
  isConfigurable: boolean;
}

const styles = (theme: ThemeProps) => ({
  callout: {
    position: 'absolute',
    left: 30,
  },
  container: {
    position: 'relative',
  },
  button: (props: TermConfigureButtonProps) => ({
    fontStyle: !props.configuration && !props.isConfigurable ? 'italic' : undefined,
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:disabled': {
      color: theme.palette.textTertiary,
    },
    '&:hover:disabled': {
      color: theme.palette.textTertiary,
    },
  }),
});

export const mapStateToProps = (state: RootState) => {
  return {
    ecifEnabled: getFlightIsEnabled(state, Flight.ECIF),
  };
};

type Props = TermConfigureButtonProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof styles>;

const getCardType = (
  lineItem: QuoteTermLineItem | MonetaryLineItem | EcifLineItem,
  IsECIFEnabled?: boolean
): TermCardType => {
  const product = getValueOrUndefined(lineItem.product);

  switch (lineItem.__typename) {
    case 'FinancingTermLineItem':
      return TermCardType.Financing;
    case 'MonetaryLineItem':
      return TermCardType.Monetary;
    case 'SapLineItem':
      return TermCardType.Sap;
    case 'EcifLineItem':
      if (IsECIFEnabled && isPreApprovedEcif(lineItem)) {
        return TermCardType.EcifTerm;
      }
      return TermCardType.SimpleTerm;
    default:
      return product &&
        (product.productType.toLowerCase() === 'ecif' ||
          product.productType.toLowerCase() === 'custom')
        ? TermCardType.SimpleTerm
        : TermCardType.SimpleSkuPicker;
  }
};

const TermConfigureButtonUnstyled: React.FC<Props> = props => {
  const { lineItem, isConfigurable, ecifEnabled } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { activeQuote } = React.useContext(ActiveQuoteContext);
  const containerRef = React.useRef<HTMLDivElement>(null);
  if (!lineItem.product || !activeQuote) {
    return null;
  }
  const filteredSkus = filteredSkusRawToFilteredSkus(lineItem.product.filteredSkusRaw);
  const etag = activeQuote.etag;
  const skus =
    lineItem.product && filteredSkus && filteredSkus !== null
      ? filteredSkuToSkus(filteredSkus)
      : [];
  const catalogContext = lineItem.catalogContext;
  const currency = oc(activeQuote).billingCurrency('') as Currency;
  const cardType = getCardType(lineItem, ecifEnabled);
  let configuration = props.configuration && props.configuration.trim();
  if (cardType) {
    switch (cardType) {
      case TermCardType.Financing:
        let termid = getSimpleTermId(lineItem);
        termid = !!termid.trim() ? termid + ', ' : '';
        configuration = `${termid}${getAssociatedLineItemConfiguration(
          currency,
          getValueOrUndefined((lineItem as FinancingTermLineItem).associatedLineItem)
        )}`;

        break;
      case TermCardType.SimpleTerm:
        configuration = getSimpleTermId(lineItem);
        break;
    }
  }

  const renderCard = () => {
    if (!lineItem.product || !activeQuote) {
      return null;
    }
    const product = lineItem.product;

    switch (cardType) {
      case TermCardType.Monetary: {
        const duration =
          lineItem.__typename === 'MonetaryLineItem' &&
          lineItem.duration &&
          lineItem.duration.__typename === 'Duration' &&
          lineItem.duration.duration;
        const amount = lineItem.__typename === 'MonetaryLineItem' && lineItem.purchaseTermUnits;
        const reason = lineItem.__typename === 'MonetaryLineItem' && lineItem.reason;
        const reasons = product.reasons && product.reasons.length ? product.reasons : undefined;
        return (
          <MonetaryConfigurationCard
            boundaries={readMonetaryConfigurationCardBoundaries(lineItem)}
            currency={currency}
            durations={lineItem.product.durations || []}
            initialValues={{
              amount: amount || undefined,
              goodFor: duration || undefined,
              reason: reason || undefined,
            }}
            isReadOnly={activeQuote.readOnly}
            isTerm={true}
            lineItemId={lineItem.id}
            productTitle={product.title}
            quote={{ etag, id: props.quoteId }}
            reasons={reasons}
            startCondition={product.startCondition}
            target={containerRef}
            onDismiss={() => setIsOpen(false)}
          />
        );
      }
      case TermCardType.Financing: {
        return (
          <FinanceTermCard
            hidden={!isOpen}
            lineItemId={lineItem.id}
            target={containerRef}
            title={getValueOrUndefined(lineItem.title)}
            onDismiss={() => setIsOpen(false)}
          />
        );
      }
      case TermCardType.EcifTerm: {
        return (
          <ServicesConfigCard
            headerText={product.title}
            isProposalReadOnly={activeQuote.readOnly}
            lineItem={lineItem as EcifLineItem}
            lineItemId={lineItem.id}
            product={lineItem.product}
            quote={{ etag, id: props.quoteId }}
            target={containerRef}
            onDismiss={() => setIsOpen(false)}
          />
        );
      }
      case TermCardType.SimpleTerm: {
        return (
          <SimpleTermsCard
            hidden={!isOpen}
            lineItemId={lineItem.id}
            name={getValueOrUndefined(lineItem.product.title)}
            target={containerRef}
            onDismiss={() => setIsOpen(false)}
          />
        );
      }

      case TermCardType.Sap: {
        if (lineItem.__typename === 'SapLineItem') {
          const sapLineItem = lineItem as SapLineItem;
          const duration =
            sapLineItem.duration &&
            sapLineItem.duration.__typename === 'Duration' &&
            sapLineItem.duration.duration;
          const { email, firstName, lastName, opportunityId } = sapLineItem;
          const amount = sapLineItem.purchaseTermUnits;

          return (
            <SapConfigurationCard
              boundaries={readSapCardConfigurationCardBoundaries(sapLineItem)}
              currency={currency}
              initialValues={{
                amount: amount || '',
                goodFor: duration || '',
                email: email || '',
                firstName: firstName || '',
                lastName: lastName || '',
                opportunityId: opportunityId || '',
              }}
              lineItemId={sapLineItem.id}
              productName={product.title}
              quote={{ etag, id: props.quoteId }}
              readOnly={activeQuote.readOnly}
              startCondition={product.startCondition}
              target={containerRef}
              onDismiss={() => setIsOpen(false)}
            />
          );
        }
        return null;
      }

      case TermCardType.SimpleSkuPicker: {
        const skuId = oc(lineItem).sku.skuId();
        const initialValues = skuId ? { skuId, autoRenew: false } : undefined;
        return (
          <SimpleSkuPickerConfigurationCard
            catalogContext={catalogContext}
            currency={currency}
            initialValues={initialValues}
            isTerm={true}
            lineItemId={lineItem.id}
            productId={lineItem.product.id}
            productTitle={product.title}
            quoteMutationInput={{ etag, id: props.quoteId }}
            readOnly={activeQuote.readOnly}
            skus={skus}
            target={containerRef}
            onDismiss={() => setIsOpen(false)}
          />
        );
      }
    }
  };

  const buttonsText = getLinkButtonDisplayText(configuration, !!isConfigurable);
  // FIXME: do not make button enabled always
  return (
    <div className={props.classes.container}>
      <LinkButton
        addClass={props.classes.button}
        dataAutomationId="config-card-button"
        disabled={props.disabled || !isConfigurable}
        displayText={buttonsText.displayText}
        title={buttonsText.title}
        onClick={() => setIsOpen(!isOpen)}
      />
      <span className={props.classes.callout} ref={containerRef} />
      {isOpen && renderCard()}
    </div>
  );
};

export const TermConfigureButtonStyled = withStyles(styles)(
  TermConfigureButtonUnstyled
) as React.FC<TermConfigureButtonProps>;

export const TermConfigureButton = connect(mapStateToProps)(TermConfigureButtonStyled);
