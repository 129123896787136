import { mergeClassNames } from 'components';
import { CalloutCalendarProps, ComboBox } from 'components/ions';
import { CalendarDropdown } from 'components/molecules';
import { calendarStrings } from 'components/utilities/calendarStrings';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';

import { discountCardFutureSectionStyles } from './DiscountCardFutureSection.styles';
import { FutureSectionProps } from './types';

type Props = FutureSectionProps & WithStyles<typeof discountCardFutureSectionStyles>;

const DiscountCardFutureSectionUnstyled: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { dispatches: sectionDispatches, options: sectionOptions } = props;
  const today = new Date();
  const startingCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'starting-future-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: props.disabled,
    },
    calendarStrings,
    today,
    defaultDate: sectionOptions.startDates.selected,
    minDate: sectionOptions.startDates.minDate,
    maxDate: sectionOptions.startDates.maxDate,
    showTodayButton: sectionOptions.isDayPickersVisible,
    onSelectDate: sectionDispatches.onStartDateAdded,
    isDayPickerVisible: sectionOptions.isDayPickersVisible,
  };

  const goodForCalendarProps: CalloutCalendarProps = {
    buttonProps: {
      id: 'good-for-future-discount-calendar-button',
      ariaLabel: t('quote::Open Calendar'),
      disabled: !props.options.endCalendarEnabled || props.disabled,
    },
    calendarStrings,
    today,
    defaultDate: sectionOptions.endDates.selected,
    minDate: sectionOptions.endDates.minDate,
    maxDate: sectionOptions.endDates.maxDate,
    onSelectDate: props.dispatches.onEndDateAdded,
    showTodayButton: false,
    isDayPickerVisible: sectionOptions.isDayPickersVisible,
  };

  let metersDropdown;
  if (sectionOptions.showMeters && sectionOptions.metersOptions) {
    metersDropdown = (
      <ComboBox
        disabled={props.disabled}
        id="meters"
        label={t('quote::Meters')}
        options={sectionOptions.metersOptions.options}
        selectedKey={sectionOptions.metersOptions.selectedKey}
        onChange={sectionDispatches.onMeterSelectionChanged}
      />
    );
  }
  const calendarMergedClasses = mergeClassNames([
    props.classes.calendarDropdownMargin,
    props.classes.itallicComboboxInputStyle,
  ]);
  return (
    <div className={props.classes.bottomMargin}>
      <div
        className={
          sectionOptions.startOptions.selectedKey === 'mixed'
            ? calendarMergedClasses
            : props.classes.calendarDropdownMargin
        }
      >
        <CalendarDropdown
          calloutCalendarProps={startingCalendarProps}
          dataAutomationId="discountFutureStartingCalendar"
          dropdownDisabled={props.disabled}
          dropdownOptions={sectionOptions.startOptions.options}
          label={t('quote::Starting')}
          required={true}
          selected={sectionOptions.startOptions.selectedKey}
          onDropdownSelect={sectionDispatches.onStartSelectionChanged}
        />
      </div>
      <div
        className={
          sectionOptions.endOptions.selectedKey === 'mixed'
            ? calendarMergedClasses
            : props.classes.calendarDropdownMargin
        }
      >
        <CalendarDropdown
          calloutCalendarProps={goodForCalendarProps}
          dataAutomationId="discountFutureGoodForCalendar"
          dropdownDisabled={props.disabled}
          dropdownOptions={sectionOptions.endOptions.options}
          label={t('quote::Good For')}
          required={true}
          selected={sectionOptions.endOptions.selectedKey}
          onDropdownSelect={sectionDispatches.onEndSelectionChanged}
        />
      </div>
      <div className={props.classes.metersDropdown}>{metersDropdown}</div>
    </div>
  );
};

export const DiscountCardFutureSection = withStyles(discountCardFutureSectionStyles)(
  DiscountCardFutureSectionUnstyled
);
