import { LinkButton, TextBodySmall } from 'components/ions';
import { mergeClassNames } from 'components/utilities';
import { ApprovalActionType } from 'generated/graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { ThemeProps } from 'styles/theme';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';

export interface ApprovalCommentsStyles {
  author?: string;
  commentSection?: string;
  container?: string;
  text?: string;
  textContainer?: string;
}

export interface Comment {
  id: string;
  action: ApprovalActionType;
  text: string;
  date: string;
  author?: string;
}

export interface ApprovalCommentsDetails {
  approvalId: string;
  comments: Comment[];
}

export interface ApprovalCommentsProp extends ApprovalCommentsDetails {
  enableShowMore?: boolean;
}

const defaultStyles = (theme: ThemeProps) => {
  return {
    action: {
      display: 'flex',
      fontWeight: theme.fonts.fontWeights.semiBold,
      justifyContent: 'flex-start',
      paddingBottom: 4,
    },
    author: {
      display: 'flex',
      fontWeight: theme.fonts.fontWeights.semiBold,
      justifyContent: 'flex-start',
      paddingTop: 4,
    },
    commentSection: {
      paddingBottom: 24,
    },
    container: { display: 'flex', flexDirection: 'column', width: '100%', marginTop: 25 },
    date: {
      color: theme.palette.textTertiary,
      paddingTop: 4,
    },
    footer: { display: 'flex', justifyContent: 'space-between' },
    showMoreButton: { fontSize: theme.fonts.fontSizes.small },
    text: {
      position: 'relative',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transitionProperty: 'max-height',
      transitionDuration: '.5s',
      transitionTimingFunction: 'cubic-bezier(0, 1, .5, 1)',
    },
    textFade: {
      '&:after': {
        background: `linear-gradient(to bottom, transparent 52px, ${theme.palette.backgroundStandout})`,
        content: '""',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      },
    },
    textFadeRemoved: {
      '&:after': {
        opacity: 0,
        transitionProperty: 'opacity',
        transitionDuration: '1s',
        transitionTimingFunction: 'ease-out',
      },
    },
    textContainer: { display: 'flex', flexDirection: 'column' },
  };
};

const setStateAction = createStandardAction('SET_STATE')<Comment[]>();
const showButtonAction = createStandardAction('SHOW_BUTTON')<{ id: string; height: number }>();
const textShowLessAction = createStandardAction('SHOW_LESS')<string>();
const showMoreAction = createStandardAction('SHOW_MORE')<string>();
const clearCommentsAction = createStandardAction('CLEAR_COMMENTS')();

const setStateActionType = getType(setStateAction);
const showButtonActionType = getType(showButtonAction);
const textShowLessActionType = getType(textShowLessAction);
const showMoreActionType = getType(showMoreAction);
const clearCommentsActionType = getType(clearCommentsAction);

interface ApprovalComment extends Comment {
  elementHeight: number;
  elementRef: React.RefObject<HTMLDivElement>;
  showButton: boolean;
  showComment: boolean;
}

type ApprovalCommentsState = Record<string, ApprovalComment>;
type ApprovalCommentsActions =
  | typeof showMoreAction
  | typeof textShowLessAction
  | typeof setStateAction
  | typeof showButtonAction
  | typeof clearCommentsAction;

/**
 * Add new comments to the given state.
 *
 * @param state current state
 * @param comments list of comments
 * @returns updated state
 */
const addCommentsToState = (state: ApprovalCommentsState, comments: Comment[]) => {
  const stateUpdated: ApprovalCommentsState = comments.length ? { ...state } : {};

  comments.forEach(comment => {
    stateUpdated[comment.id] = {
      ...comment,
      elementHeight: 0,
      elementRef: React.createRef<HTMLDivElement>(),
      showButton: false,
      showComment: false,
    };
  });
  return stateUpdated;
};

const reducer = (
  state: ApprovalCommentsState,
  action: ActionType<ApprovalCommentsActions>
): ApprovalCommentsState => {
  switch (action.type) {
    case setStateActionType:
      return addCommentsToState(state, action.payload);
    case showButtonActionType:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          elementHeight: action.payload.height,
          showButton: true,
        },
      };
    case showMoreActionType:
      return { ...state, [action.payload]: { ...state[action.payload], showComment: true } };
    case textShowLessActionType:
      return { ...state, [action.payload]: { ...state[action.payload], showComment: false } };
    case clearCommentsActionType:
      return {};
  }
};

type Props = ApprovalCommentsProp & WithStyles<typeof defaultStyles>;

const ApprovalCommentsUnstyled: React.FC<Props> = props => {
  const { classes, comments, approvalId } = props;
  const { t } = useTranslation();
  const [localState, dispatch] = React.useReducer(reducer, {});
  const [commentsForApprovalId, setApprovalId] = React.useState('');
  const commentsLength = comments.length;

  const approvalStyles: ApprovalCommentsStyles = {
    author: classes.author,
    commentSection: classes.commentSection,
    text: classes.text,
    textContainer: classes.textContainer,
    container: classes.container,
  };

  const renderType = (action: ApprovalActionType) => {
    switch (action) {
      case ApprovalActionType.Approve:
        return <TextBodySmall addClass={classes.action}>{t('quote::Approval')}</TextBodySmall>;
      case ApprovalActionType.Reject:
        return <TextBodySmall addClass={classes.action}>{t('quote::Rejection')}</TextBodySmall>;
      case ApprovalActionType.Comment:
        return <TextBodySmall addClass={classes.action}>{t('quote::Submission')}</TextBodySmall>;
    }
  };

  // Updates local state with new comments
  React.useEffect(() => {
    if (approvalId && commentsForApprovalId === approvalId) {
      dispatch({
        type: setStateActionType,
        payload: comments,
      });
    } else {
      dispatch({
        type: clearCommentsActionType,
      });
      dispatch({
        type: setStateActionType,
        payload: comments,
      });
      setApprovalId(approvalId);
    }
  }, [approvalId, comments, commentsForApprovalId, commentsLength]);

  // Enable show more button in new buttons if comment is too height
  React.useEffect(() => {
    for (let id in localState) {
      const current = localState[id].elementRef && localState[id].elementRef.current;

      if (!localState[id].showButton && current && current.offsetHeight >= 80) {
        dispatch({
          type: showButtonActionType,
          payload: { id, height: current.offsetHeight },
        });
      }
    }
  }, [localState]);

  const commentsElements = [];
  if (Object.keys(localState).length) {
    for (let commentId in localState) {
      const comment = localState[commentId];

      // Enable animation for collapsing or expanding the comment
      const current =
        comment.elementRef &&
        comment.elementRef.current &&
        (comment.elementRef.current.firstChild as HTMLDivElement);

      if (props.enableShowMore && comment.showButton && current) {
        if (comment.showComment) {
          current.style.maxHeight = comment.elementHeight + 'px';
        } else {
          current.style.maxHeight = '80px';
        }
      }

      const textFadeStyle =
        props.enableShowMore && comment.showButton ? classes.textFade : undefined;

      const commentTextStyle = mergeClassNames([
        approvalStyles.text,
        textFadeStyle,
        !!textFadeStyle && comment.showComment ? classes.textFadeRemoved : undefined,
      ]);

      commentsElements.push(
        <div className={approvalStyles.commentSection} key={commentId}>
          {comment.action && renderType(comment.action)}
          <div className={approvalStyles.textContainer} ref={comment.elementRef}>
            <TextBodySmall addClass={commentTextStyle}>{comment.text}</TextBodySmall>
            {props.enableShowMore && comment.showButton && (
              <LinkButton
                addClass={classes.showMoreButton}
                dataAutomationId={
                  comment.showComment ? 'approvalCommentsShowLess' : 'approvalCommentsShowMore'
                }
                displayText={comment.showComment ? t('quote::show less') : t('quote::show more')}
                onClick={() =>
                  dispatch({
                    type: comment.showComment ? textShowLessActionType : showMoreActionType,
                    payload: comment.id,
                  })
                }
              />
            )}
          </div>
          <div className={classes.footer}>
            <TextBodySmall addClass={approvalStyles.author}>{comment.author}</TextBodySmall>
            {comment.date && <TextBodySmall addClass={classes.date}>{comment.date}</TextBodySmall>}
          </div>
        </div>
      );
    }
  }

  if (commentsElements.length) {
    return (
      <div className={approvalStyles.container} data-automation-id="Details-Comments">
        {commentsElements}
      </div>
    );
  } else {
    return null;
  }
};

export const ApprovalComments = withStyles(defaultStyles)(ApprovalCommentsUnstyled);
