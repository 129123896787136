import { TextBody, TextCurrency } from 'components';
import { formatPrice } from 'features-apollo/formatters';
import React from 'react';
const PLACEHOLDER_UNDEFINED = '-';

export interface PriceProps {
  /**
   * Displays only a dash when amount is undefined
   * NOTE: undefined is not set as '?' to make this prop to be explicitely defined.
   */
  amount: string | number | null | undefined;
  /**
   * Used to determine the price format and it is displayed next to it.
   */
  currency: string;
  /**
   * Label for the Amount and the Currency
   */
  label?: string;
  /**
   * Gets an object in which amount should be formatted based on
   */
  format?: Intl.NumberFormatOptions;
}

export const Price: React.FC<PriceProps> = props => {
  const { amount, currency, label, format } = props;
  const formmattedPrice = amount != null && formatPrice(amount, currency, format);

  if (formmattedPrice) {
    let priceLabel;
    if (label) {
      priceLabel = <TextBody>{label}</TextBody>;
    }
    return (
      <div>
        {priceLabel}
        <TextBody>{formmattedPrice}</TextBody>
        <TextCurrency>{` ${currency}`}</TextCurrency>
      </div>
    );
  } else {
    return <TextBody>{PLACEHOLDER_UNDEFINED}</TextBody>;
  }
};
