import { Account, Address, GqlLanguage, LanguageInfo, OrganizationSimple } from 'generated/graphql';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';

import { getValueOrUndefined } from '../../utils';
import { OrganizationWizardFlow } from './OrganizationWizard';

export enum OrgWizardDataSource {
  SalesAccount,
  Enrollment,
  Edit,
  Empty,
}

export interface OrgWizardAccount {
  accountId?: string | null;
  accountName?: string | null;
}

export interface OrgWizardOrganization {
  organizationId?: string;
  organizationName: string;
  tradeName?: string;
}

export interface OrgWizardAddress {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  region?: string;
  country: string;
  postalCode?: string;
}

export interface OrgWizardLeadOrg {
  leadOrgId?: string;
  leadOrgName?: string | null;
}

export interface OrgWizardBillingContact {
  email?: string;
  phoneNumber?: string;
}

export interface OrganizationWizardState {
  account: OrgWizardAccount;
  organization: OrgWizardOrganization;
  address: OrgWizardAddress;
  isAddressValidated: boolean;
  isAffiliate: boolean;
  leadOrg: OrgWizardLeadOrg;
  billingContact: OrgWizardBillingContact;
  invoiceLanguage: GqlLanguage;
  initialDataSource: OrgWizardDataSource;
}

export const defaultGqlLanguage: GqlLanguage = GqlLanguage.EnUs;
export const defaultLanguageInfo: LanguageInfo = {
  gqlCode: GqlLanguage.EnUs,
  display: 'English (US)',
  code: 'en-Us',
  twoLetterCode: 'en',
};

export const emptyOrgWizardAddress = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  region: '',
  country: '',
  postalCode: '',
};

export const OrganizationWizardActions = {
  setAccountId: createStandardAction('SET_ACCOUNT_ID')<string | null | undefined>(),
  setAccountName: createStandardAction('SET_ACCOUNT_NAME')<string | null | undefined>(),
  setOrganization: createStandardAction('ORGANIZATION_SET')<OrgWizardOrganization>(),
  setAddress: createStandardAction('ADDRESS_SET')<OrgWizardAddress>(),
  setIsAddressValidated: createStandardAction('ADDRESS_VALIDATED_CHANGE')<boolean>(),
  setIsAffiliate: createStandardAction('AFFILIATE_SET')<boolean>(),
  setLeadOrg: createStandardAction('LEAD_ORG_SET')<OrgWizardLeadOrg>(),
  setBillingContact: createStandardAction('CONTACT_SET')<OrgWizardBillingContact>(),
  setInvoiceLanguage: createStandardAction('LANGUAGE_SET')<GqlLanguage>(),
};

export const organizationWizardReducer = (
  state: OrganizationWizardState,
  action: ActionType<typeof OrganizationWizardActions>
): OrganizationWizardState => {
  switch (action.type) {
    case getType(OrganizationWizardActions.setAccountId):
      return { ...state, account: { ...state.account, accountId: action.payload } };
    case getType(OrganizationWizardActions.setAccountName):
      return { ...state, account: { ...state.account, accountName: action.payload } };
    case getType(OrganizationWizardActions.setOrganization):
      return { ...state, organization: action.payload };
    case getType(OrganizationWizardActions.setAddress):
      return { ...state, address: action.payload };
    case getType(OrganizationWizardActions.setIsAddressValidated):
      return { ...state, isAddressValidated: action.payload };
    case getType(OrganizationWizardActions.setIsAffiliate):
      return { ...state, isAffiliate: action.payload };
    case getType(OrganizationWizardActions.setLeadOrg):
      return { ...state, leadOrg: action.payload };
    case getType(OrganizationWizardActions.setBillingContact):
      return {
        ...state,
        billingContact: action.payload,
      };
    case getType(OrganizationWizardActions.setInvoiceLanguage):
      return { ...state, invoiceLanguage: action.payload };
    default:
      return state;
  }
};

export interface InitialState {
  salesAccountAddress?: Address | null;
  initialAccount?: Account | null;
  initialOrganization?: OrganizationSimple | null;
  initialLeadOrg?: OrganizationSimple | null;
  flow: OrganizationWizardFlow;
}

export const generateInitialState = (initials: InitialState): OrganizationWizardState => {
  const isEmptyCreate =
    initials.flow !== OrganizationWizardFlow.Edit && !!initials.initialOrganization;
  const isEditFlow = initials.flow === OrganizationWizardFlow.Edit;
  const salesAccountAddress = getValueOrUndefined(initials.salesAccountAddress);
  const initialAccount = getValueOrUndefined(initials.initialAccount);
  const initialOrganization = getValueOrUndefined(initials.initialOrganization);
  const initialLeadOrg = getValueOrUndefined(initials.initialLeadOrg);

  let initialAddress: OrgWizardAddress;
  let initialContact: OrgWizardBillingContact = {
    email: undefined,
    phoneNumber: undefined,
  };
  let initialName: string;
  let isAddressValid = false;
  let initialDataSource: OrgWizardDataSource;
  // if editing org, use address from org on quote
  // if creating new org and there is an org on the quote, start with emptyAddress
  // if creating new org and there is no org on the quote, use agreement/sales account info to try to populate address
  // if none of the above, default to emptyAddress
  if (isEditFlow && initialOrganization) {
    initialAddress = {
      addressLine1: initialOrganization.address.addressLine1,
      addressLine2: initialOrganization.address.addressLine2,
      addressLine3: initialOrganization.address.addressLine3,
      city: initialOrganization.address.city,
      region: initialOrganization.address.region,
      country: initialOrganization.address.country,
      postalCode: initialOrganization.address.postalCode,
    };
    initialContact = {
      email: initialOrganization.address.email,
      phoneNumber: initialOrganization.address.phoneNumber,
    };
    initialName = initialOrganization.address.companyName;
    isAddressValid = true;
    initialDataSource = OrgWizardDataSource.Edit;
  } else if (isEmptyCreate) {
    initialAddress = emptyOrgWizardAddress;
    initialName = '';
    initialDataSource = OrgWizardDataSource.Empty;
    // TODO: jejungk - uncomment when available on crmLead
    // } else if (enrollmentAddress) {
    //   initialAddress = {
    //     addressLine1: enrollmentAddress.addressLine1,
    //     addressLine2: enrollmentAddress.addressLine2,
    //     addressLine3: enrollmentAddress.addressLine3,
    //     city: enrollmentAddress.city,
    //     region: enrollmentAddress.region,
    //     country: enrollmentAddress.country,
    //     postalCode: enrollmentAddress.postalCode,
    //   };
    //   initialContact = {
    //     email: enrollmentAddress.email,
    //     phoneNumber: enrollmentAddress.phoneNumber
    //   };
    //   initialName = enrollmentAddress.name;
    //   initialDataSource = OrgWizardDataSource.Enrollment;
  } else if (salesAccountAddress) {
    initialAddress = {
      addressLine1: salesAccountAddress.addressLine1,
      addressLine2: salesAccountAddress.addressLine2,
      addressLine3: salesAccountAddress.addressLine3,
      city: salesAccountAddress.city,
      region: salesAccountAddress.region,
      country: salesAccountAddress.country,
      postalCode: salesAccountAddress.postalCode,
    };
    initialContact = {
      email: salesAccountAddress.email,
      phoneNumber: salesAccountAddress.phoneNumber,
    };
    initialName = salesAccountAddress.companyName;
    initialDataSource = OrgWizardDataSource.SalesAccount;
  } else {
    initialAddress = emptyOrgWizardAddress;
    initialName = '';
    initialDataSource = OrgWizardDataSource.Empty;
  }
  const initialAccountName =
    initials.flow !== OrganizationWizardFlow.CreateAccountAndOrganization
      ? getValueOrUndefined(initialAccount.description)
      : isEmptyCreate
      ? ''
      : initialName;

  return {
    account: {
      accountId:
        initials.flow !== OrganizationWizardFlow.CreateAccountAndOrganization
          ? getValueOrUndefined(initialAccount.id)
          : undefined,
      accountName: initialAccountName,
    },
    organization: {
      organizationId: isEditFlow ? initialOrganization.id : undefined,
      organizationName: initialName,
      tradeName: isEditFlow ? initialOrganization.tradeName : undefined,
    },
    address: initialAddress,
    isAddressValidated: isAddressValid,
    isAffiliate: isEditFlow ? !!initialLeadOrg : false,
    leadOrg: {
      leadOrgId: isEditFlow && initialLeadOrg ? initialLeadOrg.id : undefined,
      leadOrgName: isEditFlow && initialLeadOrg ? initialLeadOrg.name : undefined,
    },
    billingContact: {
      email: initialContact.email,
      phoneNumber: initialContact.phoneNumber,
    },
    invoiceLanguage: isEditFlow ? initialOrganization.language : defaultGqlLanguage,
    initialDataSource: initialDataSource,
  };
};
