import { ShowMoreText as ShowMoreTextReusable } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ShowMoreTextProps {
  maxLine?: number;
  text: string;
}

export const ShowMoreText: React.FC<ShowMoreTextProps> = props => {
  const { t } = useTranslation();
  return (
    <ShowMoreTextReusable
      {...props}
      showLessButtonText={t('show less')}
      showMoreButtonText={t('show more')}
    />
  );
};
