import { DirectionalHint, IChoiceGroupOption, IComboBoxOption } from 'office-ui-fabric-react';
import { LineItem, Proposal } from 'services/proposal/types';

import {
  CeilingSectionDispatches,
  CeilingSectionOptions,
  FutureSectionDispatches,
  FutureSectionOptions,
} from './DiscountCardSections';

export interface DiscountCardDropdownOptions {
  options: IComboBoxOption[];
  selectedKey: string;
}

export type DiscountType = 'Future' | 'Ceiling' | 'One Time';

export interface DiscountCardDates {
  minDate?: Date;
  maxDate?: Date;
  selected: Date;
}

export interface DiscountChoiceGroupProps {
  discountTypeChoices: IChoiceGroupOption[];
  discountChoice: DiscountType;
  onChangeDiscountType: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => void;
  isReadOnly: boolean;
}

export interface DiscountSectionProps {
  currentDiscountType: DiscountType;
  futureSectionDispatches: FutureSectionDispatches;
  futureSectionOptions: FutureSectionOptions;
  ceilingSectionDispatches: CeilingSectionDispatches;
  ceilingSectionOptions: CeilingSectionOptions;
  isReadOnly: boolean;
}

export interface DiscountApplyRequest {
  id: string;
  newLineItem: LineItem;
  proposal: Proposal;
}

export interface DiscountCardProps {
  discountType: DiscountType;
  discountAmount: string;
  discountError?: string;
  applyButtonDisabled: boolean;
  onDismiss: () => void;
  onChangeDiscountType: (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => void;
  onDiscountApply: () => void;
  onDiscountAmountChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => void;
  onDiscountErrorChange: (value?: string) => void;
  onRemoveDiscount?: () => void;
  lineItemId: string;
  initialDiscountAmount: number;
  noDiscountExists?: boolean;
  target: React.RefObject<HTMLSpanElement>;
  discountTypeChoices: IChoiceGroupOption[];
  futureSectionOptions: FutureSectionOptions;
  ceilingSectionOptions: CeilingSectionOptions;
  futureSectionDispatches: FutureSectionDispatches;
  ceilingSectionDispatches: CeilingSectionDispatches;
  isReadOnly: boolean;
  directionalHint?: DirectionalHint;
  isBulkFutureDiscount?: boolean;
  showMixedValuesWaterMark?: boolean;
}

export interface DiscountCardContainerState {
  ceilingEndOptions: DiscountCardDropdownOptions;
  ceilingPriceCeilingOptions: DiscountCardDropdownOptions;
  ceilingStartOptions: DiscountCardDropdownOptions;
  startAndEndDatesAreDayPickers: boolean;
  discountAmount: string;
  discountError?: string;
  discountFieldEdited?: boolean;
  discountType: DiscountType;
  discountTypeChoices: IChoiceGroupOption[];
  futureEndOptions: DiscountCardDropdownOptions;
  futureMetersOptions: DiscountCardDropdownOptions;
  futureShowMeters?: boolean;
  futureStartOptions: DiscountCardDropdownOptions;
  hasMixedValues?: boolean;
  configuredItemsCount?: number;
  defaultBulkFutureStart?: string;
  defaultBulkFutureEnd?: string;
}

export interface Timezones {
  first: string;
  last: string;
}

export enum MonthPoint {
  BEGINNING = 'BEGINNING',
  END = 'END',
}
