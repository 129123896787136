import { ThemeProps } from 'styles';

export const personaStyles = (theme: ThemeProps) => {
  const { palette } = theme;
  return {
    coin: {
      color: palette.titleBarPrimaryText,
      '& span': {
        color: palette.titleBarPrimaryText,
      },

      '& .ms-Persona-presence': {
        right: '0px',
        bottom: '0px',
      },
      '& .ms-Persona-presenceIcon': {
        display: 'none',
      },
    },
    online: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.approveActive,
      },
    },
    away: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.warning,
      },
    },
    busy: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.dangerActive,
      },
    },
    dnd: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.dangerActive,
      },
    },
    blocked: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.dangerActive,
      },
    },
    unknown: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.backgroundDivider,
      },
    },
    callForwarding: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.approveActive,
      },
    },
    availableOnMobile: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.approveActive,
      },
    },
    offline: {
      '& .ms-Persona-presence': {
        backgroundColor: palette.backgroundDivider,
      },
    },
  };
};
