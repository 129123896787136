import { IComboBoxOption } from 'office-ui-fabric-react';

export type Currency =
  | 'AED'
  | 'ARS'
  | 'AUD'
  | 'BRL'
  | 'CAD'
  | 'CHF'
  | 'DKK'
  | 'EGP'
  | 'EUR'
  | 'GBP'
  | 'HKD'
  | 'IDR'
  | 'ILS'
  | 'INR'
  | 'JPY'
  | 'KRW'
  | 'MXN'
  | 'MYR'
  | 'NOK'
  | 'NZD'
  | 'PLN'
  | 'RUB'
  | 'SAR'
  | 'SEK'
  | 'TRY'
  | 'TWD'
  | 'USD'
  | 'ZAR';

export const currencyInfo: Record<Currency, string> = {
  AED: 'UAE Dirham',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  BRL: 'Brazilian Real',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  DKK: 'Danish Krone',
  EGP: 'Egyptian Pound',
  EUR: 'Euro',
  GBP: 'British Pound',
  HKD: 'Hong Kong Dollar',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli New Shekel',
  INR: 'Indian Rupee',
  JPY: 'Japanese Yen',
  KRW: 'South Korean Won',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  NOK: 'Norwegian Krone',
  NZD: 'New Zealand Dollar',
  PLN: 'Polish Zloty',
  RUB: 'Russian Ruble',
  SAR: 'Saudi Riyal',
  SEK: 'Swedish Krona',
  TRY: 'Turkish Lira',
  TWD: 'New Taiwan Dollar',
  USD: 'US Dollar',
  ZAR: 'South African Rand',
};

export const DEFAULT_CURRENCY: Currency = 'USD';

export const getComboBoxCurrencies = (acceptedCurrencies: Currency[]): IComboBoxOption[] => {
  return Object.keys(acceptedCurrencies).map((value: string, index: number) => {
    return {
      key: acceptedCurrencies[index],
      text: currencyInfo[acceptedCurrencies[index] as Currency],
    };
  });
};
