import { GET_QUOTE } from 'features-apollo/ActiveQuoteContext';
import { DELETE_AGREEMENT } from 'features-apollo/quote/components/Lists/ActionBarButtons//DeleteLineItemsButton/queries';
import { getAgreementId } from 'features-apollo/quote/components/utils';
import { clearItemSelection } from 'features/proposal/actions';
import store from 'store';

import { useMutation } from '@apollo/react-hooks';

const buildOptimisticResponse = () => {
  return {
    deleteModernAgreement: {
      id: '',
      __typename: 'DeleteObjectResponse',
    },
    __typename: 'Mutation',
  };
};

/**
 * Handles the process of deleting line items
 * - Reusable function for term and product list delete buttons and selection feature (DEL key event)
 *
 * @param quoteId identifies the quote where the line items can be found
 * @param lineItemsIds identifies the line items that will be deleted
 */
export const useDeleteLineItems = (quoteId: string, lineItemsIds: string[]): (() => void) => {
  const [deleteModernAgreement] = useMutation(DELETE_AGREEMENT, {
    refetchQueries: () => [{ query: GET_QUOTE, variables: { id: quoteId } }],
  });

  const onDelete = () => {
    const agreementId = lineItemsIds.find((lineItemId: string) => lineItemId.includes('agreement'));
    if (agreementId) {
      deleteModernAgreement({
        variables: {
          input: {
            quoteId,
            agreementId: getAgreementId(agreementId),
          },
        },
        optimisticResponse: buildOptimisticResponse(),
      });
    }

    store.dispatch(clearItemSelection());
  };

  return onDelete;
};
