import { LinkExternal } from 'components';
import { CwaLink } from 'features-apollo/quote/components/CwaLink';
import { TenantScenario } from 'features-apollo/quote/components/Wizards';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { routes } from 'routes';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
};

export interface OrganizationInformationLinksProps {
  /**
   * Use for redirect link to Summary List with a search results related to the organization id
   */
  organizationId: string;
  /**
   * Use for redirect link to Custoemr Work Area (CWA)
   */
  salesAccountTPID?: string;
  /**
   * Determines the links available for partner or partner-customer
   */
  specificScenario?: TenantScenario;
}

type Props = OrganizationInformationLinksProps & WithStyles<typeof styles>;

export const OrganizationInformationLinksUnstyled: React.FC<Props> = props => {
  const { salesAccountTPID } = props;
  const { t } = useTranslation();

  const viewCustomerLink = props.specificScenario !== TenantScenario.partner &&
    salesAccountTPID && <CwaLink fontSize="medium" tpid={salesAccountTPID} />;

  const viewQuotesForThisCustomerLink = props.specificScenario !==
    TenantScenario.partnerCustomer && (
    <LinkExternal
      dataAutomationId="viewQuotesForThisCustomer"
      displayText={t('quote::view quotes for this billing account')}
      href={routes.home.quotes.mySearch(props.organizationId)}
      size="medium"
    />
  );

  const customerActionsAvailable = viewCustomerLink || viewQuotesForThisCustomerLink;
  return customerActionsAvailable ? (
    <div className={props.classes.container}>
      {viewCustomerLink}
      {viewQuotesForThisCustomerLink}
    </div>
  ) : null;
};

export const OrganizationInformationLinks = withStyles(styles)(
  OrganizationInformationLinksUnstyled
);
