import { LinkHeader, MeControlButton, MediumIcon } from 'components/ions';
import { ButtonMenuProps, IconMenuButton } from 'components/ions/Buttons';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { navBarStyles } from './NavBar.styles';

export interface NavBarProps {
  displayHomeButton: boolean;
  id?: string;
  primaryColor?: boolean;
  rightSideItems?: React.ReactElement | React.ReactElement[];
  title: string;
  homeButton: {
    ariaLabel: string;
    onClick?: () => void;
    dataAutomationId?: string;
    breadcrumbOptions?: ButtonMenuProps[];
    breadcrumbsEnabled: boolean;
  };
  showTestBeakerIcon: boolean;
  meControl: {
    ariaLabel: string;
    menuItems: ButtonMenuProps[];
    personaImageAlt?: string;
    personaImageUrl?: string;
    personaName?: string;
    title?: string;
    dataAutomationId?: string;
  };
  isDemoMode?: boolean;
}

type Props = NavBarProps & WithStyles<typeof navBarStyles>;

const NavBarUnstyled: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
  const { classes, homeButton, isDemoMode } = props;
  const divider: string = props.primaryColor ? classes.primaryDivider : classes.defaultDivider;
  const titleDivider: string = props.primaryColor
    ? classes.primaryTitleDivider
    : classes.defaultTitleDivider;

  const TitleDivider = () => {
    return (
      <div className={classes.titleDivider}>
        <MediumIcon addClass={titleDivider} iconName="RadioBullet" />
      </div>
    );
  };

  const headerContent = React.Children.map(props.children, (item, i) => {
    if (i === 0) {
      return (
        <div className={classes.leftSideItems} style={{ flexShrink: 1, textOverflow: 'ellipsis' }}>
          {item}
        </div>
      );
    }
    if (i === 1) {
      return (
        <div className={classes.rightContent} style={{ flexShrink: 1, minWidth: 0 }}>
          <TitleDivider />
          <div className={`${classes.leftSideItems} ${classes.secondSubtitle}`}>{item}</div>
        </div>
      );
    }
    return (
      <div className={classes.rightContent}>
        <TitleDivider />
        <div className={`${classes.leftSideItems} ${classes.noShrink}`}>{item}</div>
      </div>
    );
  });

  const HomeButton = () => {
    return homeButton.breadcrumbsEnabled &&
      homeButton.breadcrumbOptions &&
      homeButton.breadcrumbOptions.length ? (
      <IconMenuButton
        addClass={mergeClassNames([divider, classes.breadcrumbButton])}
        ariaLabel={homeButton.ariaLabel}
        buttonStyles={{
          rootExpanded: classes.rootExpanded,
        }}
        dataAutomationId={homeButton.dataAutomationId}
        iconName="HomeDropdown"
        menuId="breadcrumbMenu"
        menuProps={homeButton.breadcrumbOptions}
        removeMenuMargin
      />
    ) : (
      <LinkHeader
        addClass={isDemoMode ? mergeClassNames([divider, classes.demoTheme]) : divider}
        ariaLabel={homeButton.ariaLabel}
        dataAutomationId={homeButton.dataAutomationId}
        iconName="Home"
        id="HomeLink"
        primary={props.primaryColor}
        onClick={homeButton.onClick}
      />
    );
  };

  const Title = () => {
    return (
      <div className={`${classes.titleContainer} ${props.children && divider}`} id="NavBarTitle">
        <span className={classes.title}>
          {props.title}
          {props.showTestBeakerIcon && (
            <div className={classes.testIconContainer}>
              <MediumIcon addClass={classes.testIcon} iconName="TestBeakerSolid" />
            </div>
          )}
        </span>
      </div>
    );
  };
  const navBarTheme = isDemoMode
    ? `${classes.navbar} ${classes.demoTheme}`
    : props.primaryColor
    ? `${classes.navbar} ${classes.primaryTheme}`
    : `${classes.navbar} ${classes.defaultTheme}`;
  return (
    <nav className={navBarTheme} id={props.id}>
      <div className={classes.leftContent}>
        {props.displayHomeButton && <HomeButton />}
        <div className={classes.header}>
          <Title />
          <div className={classes.subtitles}>{headerContent}</div>
        </div>
      </div>
      <div className={classes.rightContent}>
        {props.rightSideItems}
        <MeControlButton
          ariaLabel={props.meControl.ariaLabel}
          dataAutomationId={props.meControl.dataAutomationId}
          isDemoMode={props.isDemoMode}
          menuItems={props.meControl.menuItems}
          personaImageAlt={props.meControl.personaImageAlt}
          personaImageUrl={props.meControl.personaImageUrl}
          personaName={props.meControl.personaName}
          primary={props.primaryColor}
          title={props.meControl.title}
        />
      </div>
    </nav>
  );
};

export const NavBar = withStyles(navBarStyles)(NavBarUnstyled) as React.FC<NavBarProps>;
