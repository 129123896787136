import { ButtonAtom } from 'components/atoms';
import { mergeClassNames } from 'components/utilities';
import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';

import { ButtonSharedProps } from '../ButtonSharedProps';
import { primaryButtonStyles } from './PrimaryButton.styles';

type Props = ButtonSharedProps & WithStyles<typeof primaryButtonStyles>;

const PrimaryButtonUnstyled: React.FC<Props> = (props: Props) => {
  const currentClass = props.iconName ? props.classes.primaryIcon : props.classes.primary;
  return (
    <ButtonAtom
      addClass={mergeClassNames([currentClass, props.addClass])}
      ariaLabel={props.ariaLabel}
      autoFocus={props.autoFocus}
      componentRef={props.componentRef}
      dataAutomationId={props.dataAutomationId}
      disabled={props.disabled}
      iconProps={props.iconName ? { iconName: props.iconName } : undefined}
      id={props.id}
      primary
      styles={props.styles}
      text={props.text}
      onClick={props.onClick}
    />
  );
};

export const PrimaryButton = withStyles(primaryButtonStyles)(PrimaryButtonUnstyled) as React.FC<
  ButtonSharedProps
>;
