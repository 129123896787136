import { SupportedTheme } from 'styles/theme';

export const HomeProposalStyles = (theme: SupportedTheme) => ({
  div: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    paddingTop: 30,
    paddingLeft: 10,
    borderBottom: `2px solid ${theme.palette.backgroundDivider}`,
    zIndex: 1,
  },
  navLink: {
    display: 'inline-block',
    textDecoration: 'none',
    position: 'relative',
    padding: '8px 16px',
    color: theme.palette.textTertiary,
    fontSize: 14,

    '&.active, &:hover': {
      color: theme.palette.text,
    },
    '&.active': {
      fontWeight: 'bold',
    },

    '&:after': {
      content: '""',
      backgroundColor: theme.palette.textLink,
      height: 2,
      position: 'absolute',
      bottom: 0,
      transition: 'all 0.2s ease-in-out 0s',

      left: '100%',
      right: 0,
    },

    '&.active ~ a:after': {
      left: 0,
      right: '100%',
    },

    '&.active:after': {
      left: 16,
      right: 16,
      transition: 'all 0.2s ease-in-out 0.2s',
    },
  },
});
