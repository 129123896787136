export const detailsListStyles = {
  overflowX: 'visible',
  '& .ms-DetailsHeader': {
    paddingBottom: 8,
  },
  '& .ms-DetailsHeader-cell': {
    display: 'inline-flex',
  },
  '& .ms-DetailsHeader-cellTitle': {
    paddingLeft: 0,
  },
  '& .ms-DetailsHeader-cell span': {
    display: 'flex',
    alignItems: 'flex-end',
  },
  '& .ms-FocusZone.ms-DetailsRow': {
    borderBottom: 0,
  },
};
