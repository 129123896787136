import * as React from 'react';
import { IComboBoxOption, IComboBox } from 'office-ui-fabric-react';
import withStyles, { WithStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import {
  ComboBox,
  DatePicker,
  TextBodyAsterisk,
  TextboxStandard,
  SecondaryButton,
} from 'components/ions';
import { servicesConfigCardFieldsStyles } from './ServicesConfigCard.styles';
import moment from 'moment';
import { removeNonNumericalCharacters } from 'features/proposal/utils';
import { dateFormatLl } from 'components/utilities/dates';
import { Product, EcifConfiguration } from 'generated/graphql';
import { oc } from 'ts-optchain';
import { mergeClassNames } from 'components';

export interface ServicesConfigCardFieldsProps {
  onAddClicked: (lineItem: any) => void;
  ecifConfig?: EcifConfiguration;
  product?: Product;
  totalLineItemAmount: number;
}

type Props = ServicesConfigCardFieldsProps & WithStyles<typeof servicesConfigCardFieldsStyles>;

const ServicesConfigCardFieldsUnstyled: React.FC<Props> = (props: Props) => {
  const { classes, product, totalLineItemAmount, ecifConfig } = props;
  const { t } = useTranslation();
  let typeOptions: IComboBoxOption[] = [];
  let productFamilyOptions: IComboBoxOption[] = [];
  let descriptionOptions: IComboBoxOption[] = [];

  const [type, setType] = React.useState<string>('');
  const [productFamily, setProductFamily] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [skuId, setSkuId] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<Date | undefined>(undefined);
  const [amount, setAmount] = React.useState<string>('');
  const [remainingAmount, setRemainingAmount] = React.useState<number>(0);
  /* Forces amount field to rerender and display the cleaned number 
     after user inputs invalid characters.*/
  const [, setRerender] = React.useState<boolean>(false);
  const totalApprovedAmount = oc(ecifConfig).totalApprovedAmount(0);
  React.useEffect(() => {
    const remainingAmountForLineItems =
      Number(totalApprovedAmount.toFixed(3)) - Number(totalLineItemAmount.toFixed(3));
    setRemainingAmount(Number(remainingAmountForLineItems.toFixed(3)));
    if (!remainingAmountForLineItems) {
      setType('');
      setProductFamily('');
    }
  }, [totalLineItemAmount, ecifConfig, totalApprovedAmount]);
  const allFieldsFilled =
    type &&
    description &&
    productFamily &&
    endDate &&
    Number(amount) !== 0 &&
    remainingAmount - Number(amount) >= 0;

  oc(product)
    .inputs.type([])
    .forEach(inputType =>
      typeOptions.push({
        id: inputType.id,
        text: t('quote::{{inputTypeValue}}', { inputTypeValue: inputType.value }),
        key: inputType.value,
      })
    );
  oc(product)
    .inputs.productFamily([])
    .forEach(inputProductFamily =>
      productFamilyOptions.push({
        id: inputProductFamily.id,
        text: t('quote::{{inputProductFamilyValue}}', {
          inputProductFamilyValue: inputProductFamily.value,
        }),
        key: inputProductFamily.value,
      })
    );
  oc(product)
    .skus([])
    .forEach(sku =>
      descriptionOptions.push({
        id: sku.skuId,
        text: t('quote::{{skuTitle}}', { skuTitle: sku.title }),
        key: sku.title,
      })
    );

  const addClicked = () => {
    setDescription('');
    setEndDate(undefined);
    setAmount('');
    props.onAddClicked({
      type,
      productFamily,
      skuId,
      description,
      endDate,
      amount: Number(amount),
      lineItemId: undefined,
    });
  };

  const onAmountChange = (value: string | undefined) => {
    value = value?.replace('-', '');
    if (value && isNaN(Number(value))) {
      setAmount(removeNonNumericalCharacters(value));
    } else {
      setAmount(value || '');
    }
    setRerender(s => !s);
  };

  return (
    <div className={classes.contentBody}>
      <div>
        <TextBodyAsterisk addClass={classes.servicesConfigRowTitle} required>
          {t('quote::Consulting and product support services')}
        </TextBodyAsterisk>
      </div>
      <div className={classes.configurationRow}>
        <ComboBox
          disabled={!remainingAmount}
          label={t('quote::Type')}
          options={typeOptions}
          selectedKey={type}
          styleContainer={classes.typeComboBox}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            if (option) setType(option.key.toString());
          }}
        ></ComboBox>
        <ComboBox
          disabled={!remainingAmount}
          label={t('quote::Product Family')}
          options={productFamilyOptions}
          selectedKey={productFamily}
          styleContainer={classes.productFamilyComboBox}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            if (option) setProductFamily(option.key.toString());
          }}
        ></ComboBox>
        <ComboBox
          disabled={!remainingAmount}
          label={t('quote::Description')}
          options={descriptionOptions}
          selectedKey={description}
          styleContainer={classes.descriptionComboBox}
          onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            if (option) {
              setDescription(option.key.toString());
              setSkuId(oc(option).id(''));
            }
          }}
        ></ComboBox>
        <DatePicker
          className={classes.endsDatePicker}
          disabled={!remainingAmount}
          formatDate={(date?: Date) => {
            return moment(date).format(dateFormatLl);
          }}
          label={t('quote::Ends')}
          minDate={new Date()}
          value={endDate}
          onSelectDate={(date?: Date | null) => {
            if (date) setEndDate(date);
          }}
        ></DatePicker>
        <TextboxStandard
          addClass={
            !remainingAmount
              ? mergeClassNames([classes.amountTextBox, classes.disabledTextBoxStyle])
              : classes.amountTextBox
          }
          disabled={!remainingAmount}
          errorMessageStyle={classes.errorMessageStyle}
          label={t('quote::Amount ({{currency}})', {
            currency: ecifConfig && ecifConfig.currency ? ecifConfig.currency.toString() : '',
          })}
          placeholder={remainingAmount.toLocaleString()}
          value={amount ? amount.toLocaleString() : ''}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            value?: string
          ) => {
            onAmountChange(value);
          }}
          onGetErrorMessage={() => {
            return Number(remainingAmount.toFixed(3)) - Number(amount) < 0
              ? t('quote::Available amount exceeded.')
              : '';
          }}
        ></TextboxStandard>
        <SecondaryButton
          addClass={classes.addButton}
          disabled={!allFieldsFilled}
          text={t('quote::Add')}
          onClick={addClicked}
        ></SecondaryButton>
      </div>
    </div>
  );
};

export const ServicesConfigCardFields = withStyles(servicesConfigCardFieldsStyles)(
  ServicesConfigCardFieldsUnstyled
);
