import { ChoiceGroup, LinkInternal, PrimaryButton, TextBody, TextTitle } from 'components';
import { AppEnvironment } from 'features-apollo/app/config/types';
import i18next from 'i18next';
import { IChoiceGroupOption } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { RootState } from 'store/types';

import { AccessDeniedPageStyles } from './AccessDeniedPage.styles';

enum AccessType {
  MicrosoftFieldSales = 'Microsoft Field Sales', //t('Microsoft Field Sales')
  MicrosoftInsideSalesCorporation = 'Microsoft Inside Sales Corporation', //t('Microsoft Inside Sales Corporation')
  MicrosoftInsideSalesDemandResponse = 'Microsoft Inside Sales Demand Response', //t('Microsoft Inside Sales Demand Response')
  Engineering = 'Engineering', //t('Engineering')
  LicensingExecutive = 'Licensing Executive', //t('Licensing Executive')
  CSSSales = 'CSS Sales', //t('CSS Sales')
}

const getChoiceOptions = (
  environment: AppEnvironment,
  isInsiderRequest: boolean,
  t: i18next.TFunction
) => {
  if (environment === AppEnvironment.Prod && !isInsiderRequest) {
    return [
      {
        key: t(AccessType.MicrosoftFieldSales),
        text: t(AccessType.MicrosoftFieldSales),
      },
      {
        key: t(AccessType.MicrosoftInsideSalesCorporation),
        text: t(AccessType.MicrosoftInsideSalesCorporation),
      },
      {
        key: t(AccessType.MicrosoftInsideSalesDemandResponse),
        text: t(AccessType.MicrosoftInsideSalesDemandResponse),
      },
      {
        key: t(AccessType.LicensingExecutive),
        text: t(AccessType.LicensingExecutive),
      },
      {
        key: t(AccessType.CSSSales),
        text: t(AccessType.CSSSales),
      },
    ];
  }
  return [
    {
      key: t(AccessType.MicrosoftFieldSales),
      text: t(AccessType.MicrosoftFieldSales),
    },
    {
      key: t(AccessType.Engineering),
      text: t(AccessType.Engineering),
    },
  ];
};

const mapStateToProps = (state: RootState) => ({
  environment: state.app.appConfig.app,
});

type AccessDeniedPageProps = WithStyles<typeof AccessDeniedPageStyles> &
  ReturnType<typeof mapStateToProps>;
const AccessDeniedPageUnstyled: React.FC<AccessDeniedPageProps> = props => {
  const { t } = useTranslation();
  const { environment, classes } = props;
  const [requestType, setRequestType] = React.useState(AccessType.MicrosoftFieldSales);
  const isInsiderEnvironment = window.location.hostname === 'insider.quotecenter.microsoft.com';
  const selectAccessType = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    setRequestType((option && (option.text as AccessType)) || AccessType.MicrosoftFieldSales);
  };

  const title = isInsiderEnvironment ? t('Quote Center Insider access') : t('Quote Center access');
  const options = getChoiceOptions(environment, isInsiderEnvironment, t);
  let messageText = t(
    "You don't have access to Pre-Production Quote Center. Please select the appropriate role below to request access:"
  );

  if (environment === AppEnvironment.Prod) {
    messageText = isInsiderEnvironment
      ? t(
          'You do not have the appropriate permissions to access Quote Center Insider. Select your role below to request access:'
        )
      : t(
          'You do not have the appropriate permissions to access Quote Center. Select your role below to request access:'
        );
  }

  return (
    <div className={classes.colContainer}>
      <div className={classes.backgroundContainer}>
        <div className={classes.background} />
        <div className={classes.backgroundOverlay} />
      </div>
      <div className={classes.messageContainer}>
        <div className={classes.innerMessage}>
          <div className={classes.content}>
            <div className={classes.titleContainer}>
              <TextTitle>{title}</TextTitle>
            </div>
            <div>
              <TextBody>{messageText}</TextBody>
              <ChoiceGroup
                className={classes.choiceGroup}
                dataAutomationId="roleChoiceGroup"
                defaultSelectedKey={requestType}
                options={options}
                onChange={selectAccessType}
              />
            </div>
            <PrimaryButton
              addClass={classes.button}
              dataAutomationId="requestAccessButton"
              text={t('Request access')}
              onClick={() => {
                const url = new URL(
                  'https://tools.cp.microsoft.com/ManagementPortal/AppAccessReqPage?appName=QuoteCenter'
                );
                let groupName = 'Microsoft_Field_Sales';
                let roleName = isInsiderEnvironment ? 'Field-Sales-Insider' : 'Field-Sales-User';

                switch (requestType) {
                  case AccessType.MicrosoftInsideSalesCorporation: {
                    groupName = 'Microsoft_Inside_Sales_Corporation';
                    roleName = 'Inside-Sales-Corporation-User';
                    break;
                  }
                  case AccessType.MicrosoftInsideSalesDemandResponse: {
                    groupName = 'Microsoft_Inside_Sales_Demand_Response';
                    roleName = 'Inside-Sales-Corporation-User';
                    break;
                  }
                  case AccessType.LicensingExecutive: {
                    groupName = 'Microsoft_Field_Sales';
                    roleName = 'Licensing-Executive';
                    break;
                  }
                  case AccessType.CSSSales: {
                    groupName = 'CSS_Sales';
                    roleName = 'CSS-Sales-User';
                    break;
                  }
                  case AccessType.Engineering: {
                    groupName = 'Engineering';
                    roleName = isInsiderEnvironment
                      ? 'Engineering-Insider'
                      : 'Engineering-User-PreProd';
                    break;
                  }
                  case AccessType.MicrosoftFieldSales:
                  default: {
                    groupName = 'Microsoft_Field_Sales';
                    roleName =
                      environment === AppEnvironment.Prod ? roleName : 'Field-Sales-User-PreProd';
                    break;
                  }
                }
                url.searchParams.set('groupName', groupName);
                url.searchParams.set('roleName', roleName);
                window.location.replace(url.toString());
              }}
            />
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.footerLinks}>
            <LinkInternal
              displayText={t('Terms of use')}
              href="https://www.microsoft.com/en-US/servicesagreement/"
              id="ftrTerms"
            />
            <LinkInternal
              displayText={t('Privacy & cookies')}
              href="https://privacy.microsoft.com/en-US/privacystatement"
              id="ftrPrivacy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export const AccessDeniedPageStyled = withStyles(AccessDeniedPageStyles)(AccessDeniedPageUnstyled);
export const AccessDeniedPage = connect(mapStateToProps)(AccessDeniedPageStyled);
