import * as React from 'react';
import withStyles, { WithStyles } from 'react-jss';
import { DatePicker as FabricDatePicker } from 'office-ui-fabric-react';
import { calendarStrings } from 'components/utilities/calendarStrings';
import { calloutCalendarStyles } from '../Calendar/Calendar.styles';
import { mergeClassNames } from 'components/utilities';

export interface DatePickerProps {
  className?: string;
  defaultDate?: Date;
  formatDate?: (date?: Date) => string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  onSelectDate: (date?: Date | null) => void;
  showGoToToday?: boolean;
  value?: Date;
  disabled?: boolean;
}

type Props = DatePickerProps & WithStyles<typeof calloutCalendarStyles>;

const DatePickerUnstyled: React.FC<Props> = (props: Props) => {
  const mergedClass = mergeClassNames([
    props.classes.goToToday,
    props.classes.days,
    props.classes.yearPicker,
    props.classes.selectedDay,
    props.classes.selectedYear,
    props.classes.todayDate,
    props.classes.todayIsSelected,
    props.classes.monthPicker,
    props.classes.arrows,
  ]);

  const calendarProps = {
    className: mergedClass,
    strings: calendarStrings,
  };

  return (
    <FabricDatePicker
      {...props}
      calendarProps={calendarProps}
      highlightSelectedMonth
      minDate={props.minDate || new Date()}
    />
  );
};

export const DatePicker = withStyles(calloutCalendarStyles)(DatePickerUnstyled) as React.FC<
  DatePickerProps
>;
