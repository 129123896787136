import React from 'react';
import { useTranslation } from 'react-i18next';

import { InformationalTile } from './InformationalTile';

export const TenantNotFoundInfoTile: React.FC<{ scenario: 'partner' | 'customer' }> = props => {
  const { t } = useTranslation();

  const description =
    props.scenario === 'partner'
      ? t(
          'quote::We were not able to find a valid tenant with the tenant ID or domain name provided. Please ensure that the information was entered correctly or confirm with the partner you wish to add.'
        )
      : t(
          'quote::We were not able to find a valid tenant with the tenant ID or domain name provided. Please ensure that the information was entered correctly or confirm with the customer you wish to add.'
        );

  return (
    <InformationalTile
      description={description}
      iconName="ProfileSearch"
      title={t('quote::Tenant not found')}
    />
  );
};
