import { ButtonSharedProps } from 'components/ions';
import { ShimmerForBackgroundStandout } from 'components/ions/Progress/Shimmer';
import { HelpContent } from 'features/app/types';
import { useHelpContent } from 'features/app/useHelpContent';
import * as catalogSelectors from 'features/catalog/selectors';
import { getLeadOrganizationName, getSharedCustomPrices } from 'features/customer/selectors';
import {
  addSkuTitle,
  clearItemSelection,
  createProposalLineItemsAsync,
  deleteProposalLineItemsAsync,
  loadDFDProduct,
  loadSharedDiscountProducts,
  removeLineItemGroupingAsync,
  toggleItemSelection,
  updateAddedLineItemCount,
} from 'features/proposal/actions';
import {
  ProductAndQualifyingSkusTitles,
  QualifyingSkuAvailability,
} from 'features/proposal/components/ConfigCard';
import { getGroupingDeleteRequest } from 'features/proposal/components/FinanceTermsCard/FinanceCardBusinessLogic';
import { ProductListQuantity } from 'features/proposal/components/ProductList/ProductListColumns/ProductListQuantity';
import {
  percentageFormatter,
  priceFormatter,
  ProposalList,
  ProposalListColumnRenderProps,
  ProposalListWatermarkType,
  titleFormatter,
} from 'features/proposal/components/ProposalList';
import {
  getActiveProposal,
  getBillingCurrency,
  getExchangeDate,
  getExchangeRate,
  getLastSelectedId,
  getLeadOrganizationId,
  getLineItemsSwitchingDFD,
  getMarket,
  getProductListLineItems,
  getSelectedIds,
  getSelectedProject,
  getSharedDiscountLineItems,
  getSharedDiscountListLineItems,
  getSkuTitles,
  isPartnerProposal,
  isProposalReadOnly,
  lineItemLoading,
  showPriceConversion,
} from 'features/proposal/selectors';
import { getAddedLineItemCount } from 'features/proposal/selectors/lineItem';
import { getOpenCard } from 'features/proposal/selectors/views';
import { Currency, DEFAULT_CURRENCY } from 'features/proposal/supported-currencies';
import { Market } from 'features/proposal/supported-markets';
import { OpenCard, SkuTitleMap } from 'features/proposal/types';
import { createSharedDiscountLineItems } from 'features/proposal/utils';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import withStyles, { WithStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Product } from 'services/catalog/types';
import { CustomPrice } from 'services/pricingscope/types';
import { endpoints as proposalServiceEndpoints, productIds } from 'services/proposal/config';
import { CreateLineItemsRequest, LineItem, Proposal } from 'services/proposal/types';
import { RootState } from 'store/types';
import { oc } from 'ts-optchain';

import { listStyles } from '../List.styles';
import { ProductListFooter, ProductListLineItem, ProductListRow } from './';

export interface ProductListProps {
  isDragEvent?: boolean;
  addSkuTitle: (
    qualifyingSkuAvailabilities: QualifyingSkuAvailability[],
    productId: string
  ) => ReturnType<typeof addSkuTitle>;
  products: Record<string, Product>;
  productListLineItems?: ProductListLineItem[];
  sharedDiscountListLineItems?: ProductListLineItem[];
  selectedIds: string[];
  lastSelectedId: string;
  onClearSelection: () => void;
  currency: Currency;
  openCard?: OpenCard;
  skuTitleMap?: SkuTitleMap;
  proposal?: Proposal;
  isProposalReadOnly?: boolean;
  lineItems?: LineItem[];
  sharedDiscountLineItems?: LineItem[];
  market: Market;
  isForeign: boolean;
  exchangeDate?: Date;
  exchangeRate?: number;
  sharedDiscounts?: CustomPrice[];
  leadOrganizationName?: string;
  proposalServiceEndpoint: string;
  selectedProject?: string;
  addLineItem: (request: CreateLineItemsRequest) => void;
  isPartnerProposal?: boolean;
  isLineItemsLoading?: boolean;
  addedLineItemCount?: number;
}

const dispatchProps = {
  addLineItem: createProposalLineItemsAsync.request,
  onSelection: toggleItemSelection,
  onClearSelection: clearItemSelection,
  onDelete: deleteProposalLineItemsAsync.request,
  onDeleteWithGrouping: removeLineItemGroupingAsync.request,
  loadSharedDiscountProducts,
  loadDFDProduct,
  updateAddedLineItemCount: updateAddedLineItemCount.request,
};

type Props = ProductListProps & WithStyles<typeof listStyles> & typeof dispatchProps;

export const ProductListUnstyled: React.FC<Props> = props => {
  const {
    productListLineItems: products,
    proposal,
    onDeleteWithGrouping,
    sharedDiscounts,
    sharedDiscountLineItems,
    addLineItem,
    proposalServiceEndpoint,
    selectedProject,
    sharedDiscountListLineItems,
    loadSharedDiscountProducts,
    loadDFDProduct,
    openCard,
    isProposalReadOnly,
    isLineItemsLoading,
    addedLineItemCount,
    updateAddedLineItemCount,
    classes,
  } = props;
  useHelpContent(HelpContent.QuoteEditorProducts);

  const [isLoadingSharedDiscounts, setIsLoadingSharedDiscounts] = React.useState<boolean>(false);
  const dfdProduct = props.products[productIds.discountFulfillmentDocument];

  React.useEffect(() => {
    if (!dfdProduct) {
      loadDFDProduct();
    }
  }, [loadDFDProduct, dfdProduct]);

  const sharedDiscountsLength = (sharedDiscounts && sharedDiscounts.length) || undefined;

  React.useEffect(() => {
    const discounts =
      sharedDiscounts &&
      sharedDiscounts.map(
        discount => discount.pricingInstructions.product || productIds.azureFamilyDiscount
      );
    discounts && loadSharedDiscountProducts(discounts);
    // the selector for sharedDiscounts runs a filter causing the useEffect to loop.
    // instead, we are just looking to see if the length changed to trigger the useEffect
    // the only time the length would change without navigating away and back is when the api calls complete after loading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSharedDiscountProducts, sharedDiscountsLength]);

  const sharedDiscountLineItemsLength =
    (sharedDiscountLineItems && sharedDiscountLineItems.length) || undefined;
  React.useEffect(() => {
    setIsLoadingSharedDiscounts(false);
  }, [sharedDiscountLineItemsLength]);
  const { t } = useTranslation();

  const onDeleteLineItems = () => {
    if (proposal && props.selectedIds.length && !isProposalReadOnly) {
      const groupingDeleteRequest = getGroupingDeleteRequest(proposal, props.selectedIds[0]);
      if (groupingDeleteRequest) {
        onDeleteWithGrouping(groupingDeleteRequest);
      } else if (props.selectedIds.length) {
        props.onDelete({
          etag: proposal.etag,
          lineItemIds: props.selectedIds,
          proposalId: proposal.id,
        });
      }
      props.onClearSelection();
    }
  };

  const isEmptyList = !!(props.productListLineItems && !props.productListLineItems.length);

  const deleteButton: ButtonSharedProps = {
    text: t('quote::Delete'),
    ariaLabel: t('quote::Delete'),
    iconName: 'DeleteRows',
    dataAutomationId: 'productListDeleteButton',
    id: 'deleteButton',
    disabled: !props.selectedIds.length || isProposalReadOnly,
    onClick: onDeleteLineItems,
  };

  const addSharedDiscountsButton: ButtonSharedProps = {
    text: t('quote::Add shared discounts'),
    ariaLabel: t('quote::Add shared discounts'),
    iconName: 'Add',
    id: 'addSharedDiscountsButton',
    dataAutomationId: 'addSharedDiscountsButton',
    disabled:
      props.isProposalReadOnly ||
      (sharedDiscounts &&
        sharedDiscountLineItems &&
        sharedDiscounts.length === sharedDiscountLineItems.length),
    onClick: () => {
      const dfdProduct = props.products[productIds.discountFulfillmentDocument];
      if (sharedDiscounts && dfdProduct && sharedDiscountLineItems && proposal) {
        createSharedDiscountLineItems(
          sharedDiscounts,
          sharedDiscountLineItems,
          dfdProduct,
          proposal,
          proposalServiceEndpoint,
          addLineItem,
          selectedProject
        );
        setIsLoadingSharedDiscounts(true);
        const addedLineItemsCount = sharedDiscounts.length - sharedDiscountLineItems.length;
        updateAddedLineItemCount(addedLineItemsCount);
      }
    },
  };

  const actionBarButtons: ButtonSharedProps[] =
    sharedDiscounts && sharedDiscounts.length
      ? [deleteButton, addSharedDiscountsButton]
      : [deleteButton];
  const billingCurrency = oc(proposal).header.pricingContext.billingCurrency(DEFAULT_CURRENCY);
  const renderProductListQuantity = (item: ProductListLineItem) => (
    <ProductListQuantity item={item} />
  );
  const columns: ProposalListColumnRenderProps<ProductListLineItem>[] = [
    {
      id: 'name',
      columnLabel: t('quote::Name'),
      width: 230,
      onRender: (item, columnProps) => {
        return titleFormatter(columnProps.width, item.name);
      },
    },
    {
      id: 'quantity',
      columnLabel: t('quote::Quantity'),
      width: 60,
      alignText: 'center',
      onRender: renderProductListQuantity,
    },

    {
      id: 'list',
      columnLabel: t('quote::List Price'),
      width: 90,
      alignText: 'flex-end',
      onRender: item => {
        return priceFormatter(t, item.pricingCurrency || billingCurrency, item.list);
      },
    },
    {
      id: 'customer',
      columnLabel: t('quote::Customer'),
      width: 90,
      alignText: 'flex-end',
      onRender: item => {
        return priceFormatter(t, billingCurrency, item.customer);
      },
    },
    {
      id: 'discount',
      columnLabel: t('quote::Discount'),
      width: 50,
      onRender: item => {
        return percentageFormatter(item.discount);
      },
    },
    {
      id: 'amount',
      columnLabel: t('quote::Amount'),
      width: 90,
      alignText: 'flex-end',
      onRender: item => {
        return priceFormatter(t, billingCurrency, item.amount);
      },
    },
  ];

  const rows =
    products &&
    products.map(product => {
      return (
        <ProductListRow
          addSkuTitle={props.addSkuTitle}
          columns={columns}
          currency={props.currency}
          errorText={
            product.loadFailure
              ? t(
                  'quote::This product failed to load. This could have been caused by a change in market, national cloud, or product availability'
                )
              : product.messages && product.messages[0]
          }
          isInWarnState={product.needsConfiguration}
          isReadOnly={oc(props.proposal).header.readOnly(false)}
          isSelected={props.selectedIds.includes(product.id)}
          isTermsList={false}
          key={product.id}
          lastSelectedId={props.lastSelectedId}
          lineItem={props.lineItems && props.lineItems.find(lineItem => lineItem.id === product.id)}
          market={props.market}
          openCard={props.openCard}
          productLineItem={product}
          products={props.products}
          proposal={props.proposal}
          selectedIds={props.selectedIds}
          skuTitleMap={props.skuTitleMap}
          onCleared={props.onClearSelection}
          onDeleted={onDeleteLineItems}
          onSelected={props.onSelection}
        />
      );
    });

  const sharedDiscountList =
    sharedDiscountListLineItems &&
    sharedDiscountListLineItems.map(product => {
      return (
        <ProductListRow
          addSkuTitle={props.addSkuTitle}
          columns={columns}
          currency={props.currency}
          isInWarnState={false}
          isReadOnly={true}
          isSelected={props.selectedIds.includes(product.id)}
          isTermsList={false}
          key={product.id}
          lastSelectedId={props.lastSelectedId}
          lineItem={
            sharedDiscountLineItems &&
            sharedDiscountLineItems.find(lineItem => lineItem.id === product.id)
          }
          market={props.market}
          openCard={props.openCard}
          productLineItem={product}
          products={props.products}
          proposal={props.proposal}
          selectedIds={props.selectedIds}
          skuTitleMap={props.skuTitleMap}
          onCleared={props.onClearSelection}
          onDeleted={onDeleteLineItems}
          onSelected={props.onSelection}
        />
      );
    });

  const footer = !isEmptyList ? (
    <ProductListFooter
      caption={t('quote::Total')}
      currency={
        props.proposal && props.proposal.header.pricingContext.billingCurrency
          ? props.proposal.header.pricingContext.billingCurrency
          : 'USD'
      }
      exchangeDate={props.exchangeDate}
      exchangeRate={props.exchangeRate}
      isForeign={props.isForeign}
      market={props.market}
      totalPrice={props.proposal && props.proposal.header ? props.proposal.header.totalPrice : 0}
    />
  ) : (
    undefined
  );

  const displayShimmeredRows = () => {
    const shimmeredRow = (
      <div className={`${classes.lineItem} ${classes.lineItemContainer} ${classes.loading}`}>
        <ShimmerForBackgroundStandout width="30%" />
        <ShimmerForBackgroundStandout width="20%" />
      </div>
    );
    if (addedLineItemCount) {
      const shimmeredRows = [];
      for (let i = 0; i < addedLineItemCount; i++) {
        shimmeredRows.push(shimmeredRow);
      }
      return shimmeredRows;
    }
    return shimmeredRow;
  };

  return (
    <ProposalList
      actionBarButtons={actionBarButtons}
      columns={columns}
      footer={footer}
      isDragEvent={props.isDragEvent}
      isEmptyList={isEmptyList}
      isLineItemsLoading={isLineItemsLoading}
      isLoadingSharedDiscounts={isLoadingSharedDiscounts}
      isPartnerProposal={props.isPartnerProposal}
      isProposalReadOnly={props.isProposalReadOnly}
      openCard={openCard}
      parentBillingAccountName={props.leadOrganizationName}
      sharedDiscounts={sharedDiscountList}
      shimmeredRows={displayShimmeredRows()}
      watermarkType={ProposalListWatermarkType.ProductWatermark}
      onClearSelection={props.onClearSelection}
    >
      {rows}
    </ProposalList>
  );
};

export const mapStateToProps = (state: RootState) => {
  const leadOrganization = getLeadOrganizationId(state);
  const leadOrganizationName = leadOrganization && getLeadOrganizationName(state, leadOrganization);
  return {
    products: catalogSelectors.getProductFragmentsIndexed(state),
    productListLineItems: getProductListLineItems(state),
    proposal: getActiveProposal(state),
    isProposalReadOnly: isProposalReadOnly(state),
    lineItems: getLineItemsSwitchingDFD(state),
    selectedIds: getSelectedIds(state),
    lastSelectedId: getLastSelectedId(state),
    currency: getBillingCurrency(state),
    market: getMarket(state),
    openCard: getOpenCard(state),
    skuTitleMap: getSkuTitles(state),
    isForeign: !!showPriceConversion(state),
    exchangeDate: getExchangeDate(state),
    exchangeRate: getExchangeRate(state),
    sharedDiscountLineItems: getSharedDiscountLineItems(state),
    sharedDiscountListLineItems: getSharedDiscountListLineItems(state),
    sharedDiscounts: getSharedCustomPrices(state),
    proposalServiceEndpoint: proposalServiceEndpoints[state.app.appConfig.proposal.environment],
    selectedProject: getSelectedProject(state),
    leadOrganizationName,
    isPartnerProposal: !!isPartnerProposal(state),
    isLineItemsLoading: lineItemLoading(state),
    addedLineItemCount: getAddedLineItemCount(state),
  };
};

const ProductListStyled = withStyles(listStyles)(ProductListUnstyled) as React.FC<ProductListProps>;

export const ProductList = connect(mapStateToProps, {
  addSkuTitle: (qualifyingSkuAvailabilities: QualifyingSkuAvailability[], productId: string) => {
    const newSkuAvailProduct: ProductAndQualifyingSkusTitles = {
      QualifyingSkuAvailabilities: qualifyingSkuAvailabilities.slice(),
      ProductId: productId,
    };
    return addSkuTitle(newSkuAvailProduct);
  },
  ...dispatchProps,
})(ProductListStyled);
