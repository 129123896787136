export enum Environment {
  Prod = 'prod',
  Int = 'int',
}

export const environmentConfiguration = {
  [Environment.Prod]: {
    endpoint: 'https://msitsalesapi.azure-api.net',
    opportunitySubscriptionKey: '729401c4131343509f2cca53322825b7',
    salesaccountSubscriptionKey: 'fd47d490a3a9448bbbeee025938ff2d8',
  },
  [Environment.Int]: {
    endpoint: 'https://msitsalesapiuat.azure-api.net',
    opportunitySubscriptionKey: 'fdc9989723cd47c784b6e6c898f6d4a7',
    salesaccountSubscriptionKey: 'cee7fbd7ee814632b0fbbaf666c18493',
  },
};

export const externalIntegrationConfiguration = {
  [Environment.Prod]: {
    endpoint: 'https://planning.azure-api.net/externalintegration/CustomerSalesAccount',
    subscriptionKey: '4dabd22139774985a27fa3b9e2efa11a',
  },
  [Environment.Int]: {
    endpoint: 'https://planninguat.azure-api.net/externalintegration/CustomerSalesAccount',
    subscriptionKey: 'c98ccc73bb7849be9a054b1391c7f34c',
  },
};

export const externalLinkMSX = {
  [Environment.Prod]:
    'https://microsoftsales.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn={entityName}&id={id}',
  [Environment.Int]:
    'https://msxuat.crm.dynamics.com/main.aspx?pagetype=entityrecord&etn={entityName}&id={id}',
};

export const timeout = 10000;
export interface CRMConfig {
  environment: Environment;
}
