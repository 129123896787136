import {
  BaseButton,
  Button,
  ContextualMenuItemType,
  IButton,
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuProps,
  IContextualMenuSection,
  IContextualMenuStyles,
  IMenuItemStyles,
  RefObject,
} from 'office-ui-fabric-react';

export type ActionType =
  | HTMLDivElement
  | HTMLSpanElement
  | HTMLButtonElement
  | HTMLAnchorElement
  | BaseButton
  | Button;

export interface ButtonSharedProps {
  addClass?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  componentRef?:
    | React.RefObject<IButton>
    | RefObject<IButton>
    | ((ref: IButton | null) => void)
    | undefined;
  dataAutomationId?: string;
  disabled?: boolean;
  iconName?: string;
  iconId?: string;
  id?: string;
  isLoading?: boolean;
  styles?: Partial<IButtonStyles>;
  text?: string;
  title?: string;
  onClick?: (
    event?: React.MouseEvent<ActionType> | React.KeyboardEvent<ActionType>,
    item?: IButton
  ) => boolean | void;
  onKeyDown?: (event: React.KeyboardEvent<ActionType>) => void;
  onKeyPress?: (event: React.KeyboardEvent<ActionType>) => void;
}

export interface ButtonMenuProps {
  key: string;
  text?: string;
  icon?: string;
  ariaLabel?: string;
  dataAutomationId?: string;
  onClick?: (
    event?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
  ) => boolean | void;
  itemType?: ContextualMenuItemType;
  sectionProps?: IContextualMenuSection;
}

export const buildButtonMenu = (
  menuProps: ButtonMenuProps[],
  dropdownStyles: Partial<IContextualMenuStyles>,
  menuItemStyles: Partial<IMenuItemStyles>,
  menuId?: string,
  onMenuDismissed?: () => void,
  onMenuOpened?: () => void
): IContextualMenuProps => {
  return {
    isBeakVisible: false,
    styles: dropdownStyles,
    id: menuId ? menuId : 'contextualMenu',
    items:
      menuProps &&
      menuProps.map(item => {
        const menuItem: IContextualMenuItem = {
          key: item.key,
          text: item.text,
          itemType: item.itemType,
          ariaLabel: item.ariaLabel ? item.ariaLabel : item.text,
          onClick: item.onClick,
          iconProps: item.icon ? { iconName: item.icon } : undefined,
          itemProps: { styles: menuItemStyles },
          'data-automation-id': item.dataAutomationId,
        };
        if (item.sectionProps && item.itemType === ContextualMenuItemType.Section) {
          menuItem.sectionProps = item.sectionProps;
        }

        return menuItem;
      }),
    onMenuDismissed,
    onMenuOpened,
  };
};
