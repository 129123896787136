import { ThemeProps } from 'styles';
import { summaryListStyles } from 'features/proposal/components/SummaryList/SummaryList.styles';

export const referralSummaryListStyles = (theme: ThemeProps) => {
  return {
    ...summaryListStyles(theme),
    table: {
      ...summaryListStyles(theme).table,
      '& table': {
        ...summaryListStyles(theme).table['& table'],
        '& td:first-child, & th:first-child': {
          flexBasis: '44%',
        },
        '& td:nth-child(2), & th:nth-child(2)': {
          flexBasis: '19%',
        },
        '& td:nth-child(3), & th:nth-child(3)': {
          flexBasis: '17%',
        },
        '& td:nth-child(4), & th:nth-child(4)': {
          flexBasis: '20%',
        },
      },
    },
  };
};
